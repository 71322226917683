import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '../../../commons/components';

const IssuedDateFilter = memo(({ activeFilter, handler, clearHandler }) => {
  return (
    <DatePicker
      name="issuedDate"
      value={activeFilter}
      label="Fecha solicitud"
      onChange={handler}
      onClear={clearHandler}
    />
  );
});

IssuedDateFilter.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
};

export default IssuedDateFilter;
