import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { DATE_FORMAT } from '../../../../../helpers/Constants';
import { dateFormatter } from '../../../../../helpers/DateUtils';
import verifyDecimals from '../../../../../helpers/validation/decimals';
import verifyRut from '../../../../../helpers/validation/rut';
import CheckButton from '../../../../../components/elements/CheckButton';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import DataList from '../../../../../components/elements/DataList';
import Section from '../../../../../components/elements/Section';
import BaseDialog from '../../../../../components/dialogs/BaseDialog';
import { useStyles } from './styles';
import useNewSimulationDialog from './useNewSimulationDialog';
import { Text } from '../../../../commons/formatters';

const NewSimulationDialog = ({
  open,
  order,
  orderSummary,
  handleCloseDialog,
}) => {
  const country = useSelector(state => state.config.country);
  const {
    fees,
    checked,
    formData,
    validationRates,
    countrySettings,
    currentIssuedDate,
    fetchOrderFeesLoading,
    validationOperationCost,
    isDisabledBaseRateByRole,
    isDisabledOperationCostByRole,
    fetchRestrictiveDataNewSimulationIsLoading,
    setChecked,
    handleSubmit,
    handleInputChange,
    handleDateChange,
  } = useNewSimulationDialog({
    order,
    country,
    orderSummary,
    handleCloseDialog,
  });
  const classes = useStyles();

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidRut', value => verifyRut(value));
    ValidatorForm.addValidationRule('isPositiveOrZero', value => value >= 0);
    ValidatorForm.addValidationRule('maxDecimals', value =>
      verifyDecimals(value)
    );
    ValidatorForm.addValidationRule('maxBaseRate', value => {
      return Number(value) <= countrySettings.maxBaseRate;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    ValidatorForm.addValidationRule('minBaseRate', value => {
      return Number(value) >= validationRates.minRate;
    });
    ValidatorForm.addValidationRule('minOperationCost', value => {
      return Number(value) >= validationOperationCost.minOperationCost;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationRates, validationOperationCost]);

  const getBaseRateValidators = (type, isDisabled) => {
    if (isDisabled) {
      return [];
    }
    const validatorsDictionary = {
      baseRate: {
        validators: ['required', 'minBaseRate', 'maxBaseRate'],
        errorMessages: [
          'Campo requerido',
          `El valor mínimo debe ser ${validationRates.minRate}`,
          `El valor máximo debe ser ${countrySettings.maxBaseRate}`,
        ],
      },
      operationCost: {
        validators: ['required', 'minOperationCost'],
        errorMessages: [
          'Campo requerido',
          `El valor mínimo debe ser ${validationOperationCost.minOperationCost}`,
        ],
      },
    };
    return validatorsDictionary[type];
  };

  const detailData = [
    {
      label: 'Anticipo:',
      data: (
        <CountryFormatHelper
          value={fees?.advance}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Intereses:',
      data: (
        <CountryFormatHelper
          value={fees?.interest}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Descuentos:',
      data: (
        <CountryFormatHelper
          value={fees?.discount}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Monto transferencia:',
      data: (
        <CountryFormatHelper
          value={fees?.transfer}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Fecha creación:',
      data: dateFormatter(fees?.issuedDate) || '-',
    },
  ];

  return (
    <BaseDialog
      isOpen={open}
      size="lg"
      handleClose={handleCloseDialog}
      title="Nueva simulación de Payments"
      description="Llena los siguientes campos para generar la simulación"
    >
      <ValidatorForm onSubmit={handleSubmit} className={classes.rootForm}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Tasa base (%)"
              disabled={
                fetchOrderFeesLoading ||
                isDisabledBaseRateByRole ||
                fetchRestrictiveDataNewSimulationIsLoading
              }
              inputProps={{
                min: 0,
                max: countrySettings.maxBaseRate,
                step: 0.001,
              }}
              type="number"
              name="tasaBase"
              value={formData?.tasaBase}
              onChange={e => handleInputChange(e)}
              validators={
                getBaseRateValidators('baseRate', isDisabledBaseRateByRole)
                  .validators
              }
              errorMessages={
                getBaseRateValidators('baseRate', isDisabledBaseRateByRole)
                  .errorMessages
              }
            />
            {validationRates.minRate !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Tasa base minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">
                    {validationRates.minRate}%
                  </Box>
                </Typography>
              </Box>
            )}
            <TextValidator
              fullWidth
              disabled
              variant="outlined"
              label="Descuentos ($)"
              type="number"
              id="discount"
              name="discount"
              aria-describedby="discount"
              value={formData?.discount}
            />
            <TextValidator
              fullWidth
              disabled={
                fetchOrderFeesLoading ||
                isDisabledOperationCostByRole ||
                fetchRestrictiveDataNewSimulationIsLoading
              }
              variant="outlined"
              label="Costo de operación ($)"
              type="number"
              id="operationCost"
              name="operationCost"
              aria-describedby="operationCost"
              value={formData.operationCost}
              onChange={e => handleInputChange(e)}
              validators={
                getBaseRateValidators(
                  'operationCost',
                  isDisabledOperationCostByRole
                ).validators
              }
              errorMessages={
                getBaseRateValidators(
                  'operationCost',
                  isDisabledOperationCostByRole
                ).errorMessages
              }
            />
            {validationOperationCost.minOperationCost !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Asesoría digital minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">
                    {validationOperationCost.minOperationCost}
                  </Box>
                </Typography>
              </Box>
            )}
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                fullWidth
                label="Fecha creación"
                name="issuedDate"
                format={DATE_FORMAT}
                value={formData.issuedDate}
                onChange={date => handleDateChange('issuedDate', date)}
                disabled={fetchOrderFeesLoading}
                autoOk
              />
            </MuiPickersUtilsProvider>
            <Box
              display="flex"
              alignItems="center"
              className={classes.containerIssuedDateLabel}
            >
              <Text className={classes.labelMargin}>
                Fecha creación actual de la OP:{' '}
              </Text>
              <Text fontWeight="bold">{currentIssuedDate.current}</Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              className={classes.issuedDateLabel}
            >
              <Text className={classes.labelMargin}>
                Fecha creación con nueva simulación:{' '}
              </Text>
              <Text fontWeight="bold">
                {dateFormatter(formData.issuedDate)}
              </Text>
            </Box>
          </Grid>

          <Grid
            item
            xs={6}
            align="center"
            className={classes.containerDetailInformation}
          >
            <Section card className={classes.resume}>
              <Container>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.operationResumeTitle}
                >
                  <Box fontWeight="fontWeightBold">Resumen de la operación</Box>
                </Typography>
                <DataList
                  wide
                  displayInDialog
                  isLoading={fetchOrderFeesLoading}
                  data={detailData}
                  justify="flex-start"
                />
              </Container>
            </Section>
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.containerButton}>
          <CheckButton
            disabled={
              fetchOrderFeesLoading ||
              fetchRestrictiveDataNewSimulationIsLoading
            }
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

NewSimulationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  order: PropTypes.objectOf(Object).isRequired,
  orderSummary: PropTypes.objectOf(Object).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default NewSimulationDialog;
