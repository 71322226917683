import {
  Box,
  styled,
  Typography,
  Tab as MaterialTab,
  Tabs,
} from '@material-ui/core';
import { palette } from '../../../../theme/palette';

export const ContainerRootFilters = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const ContainerTabsStatusFilters = styled(Box)({
  borderBottom: '1px solid #CDCAD8',
});

export const ContainerSectionAllFilters = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: 25,
  borderBottom: '1px solid #CDCAD8',
});

export const TitleSectionFilters = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
});

export const Tab = styled(MaterialTab)(
  ({ selected = false }: { selected?: boolean }) => ({
    fontWeight: selected ? 700 : 400,
    color: selected ? palette.primary.main : palette.payments.grey2,
    minWidth: 'auto',
    padding: '0 16px',
  })
);

export const CustomTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    width: 0,
  },
});
