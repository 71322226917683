import axios from 'axios';

const fetchBusinessCriticalStatusInfo = async businessId => {
  try {
    return await axios.get(
      `/api/payment/business/${businessId}/criticalstatusinfo`
    );
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchBusinessCriticalStatusInfo };
