import React, { FC, PropsWithChildren } from 'react';
import { IconButton, DrawerProps } from '@material-ui/core';
import { Drawer, ContainerRoot, HeaderContainer, Title } from './styles';
import CloseIcon from '../../icons/CloseIcon';

interface Props extends DrawerProps, PropsWithChildren {
  isOpen: boolean;
  title: string;
  containerRootWidth?: number;
  handleCloseDrawer: () => void;
}

const BaseDrawer: FC<Props> = ({
  isOpen,
  title,
  children,
  containerRootWidth,
  handleCloseDrawer,
  ...rest
}) => {
  return (
    <Drawer {...rest} open={isOpen} onClose={handleCloseDrawer}>
      <ContainerRoot containerRootWidth={containerRootWidth}>
        <HeaderContainer>
          <Title>{title}</Title>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon color="#312F37" />
          </IconButton>
        </HeaderContainer>
        {children}
      </ContainerRoot>
    </Drawer>
  );
};

export default BaseDrawer;
