import React, { FC } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import AddCircleIcon from '../../../../icons/AddCircleIcon';
import { CommentAttachment } from '../../interfaces';
import useDownloadOrderCommentAttachment from '../../infrastructure/store/useDownloadOrderCommentAttachment';
import useFetchOrderCommentAttachment from '../../infrastructure/store/useFetchOrderCommentAttachment';
import { ImgContainer, useStyles } from './styles';

interface Props {
  isOpen: boolean;
  commentAttachment: CommentAttachment | null;
  handleCloseDialog: () => void;
}

const ImgVisualizer: FC<Props> = ({
  isOpen,
  commentAttachment,
  handleCloseDialog,
}) => {
  const classes = useStyles();
  const { isLoading: fetchOrderCommentAttachmentIsLoading, data } =
    useFetchOrderCommentAttachment(Number(commentAttachment?.id));
  const {
    isLoading: downloadOrderCommentAttachmentIsLoading,
    downloadOrderCommentAttachment,
  } = useDownloadOrderCommentAttachment();

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={isOpen}
      maxWidth={'xl'}
      PaperProps={{
        className: classes.root,
      }}
    >
      <DialogContent>
        <ImgContainer>
          {fetchOrderCommentAttachmentIsLoading ? (
            <CircularProgress size={60} />
          ) : (
            <img
              alt="img-previsualizer"
              src={data?.file}
              className={classes.img}
            />
          )}
        </ImgContainer>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.downloadButton}
          variant="contained"
          color="primary"
          startIcon={
            downloadOrderCommentAttachmentIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <AddCircleIcon color="#fff" />
            )
          }
          disabled={downloadOrderCommentAttachmentIsLoading}
          onClick={() =>
            downloadOrderCommentAttachment({
              fileName: commentAttachment?.fileName || '',
              attachmentId: commentAttachment?.id || 0,
            })
          }
        >
          Descargar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImgVisualizer;
