import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useStatuses from '../../../hooks/useStatuses';
import { STATUSES } from '../../../helpers/Constants';

import { fetchCollectionPayers } from '../../../actions/collectionActions';
import { selectPriority } from '../../commons/utils/constants';

const ALL = 'ALL';
const OPERATION_TYPES_STATUS = 'OPERATION_TYPES_STATUS';

interface searchProps {
  field: string,
  value: string,
}

interface searchOneProps {
  field: string,
}

const useFiltersSection = (activeTab = ALL) => {
  const dispatch = useDispatch();
  const { getStatuses } = useStatuses();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [field, setField] = useState<string | undefined>(undefined);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [
    selectedOperationTypesStatusFilter,
    setSelectedOperationTypesStatusFilter,
  ] = useState<string[]>(
    getStatuses('operationTypes').map(status => status.key)
  );
  const [selectedPriority, setSelectedPriority] = useState<string>(
    selectPriority[3].value
  );

  const getFilters = () => {
    const filter: { field: string; value: string[] | string | undefined }[] = [
      {
        field: 'operationType',
        value: selectedOperationTypesStatusFilter,
      },
      {
        field: 'priority',
        value: selectedPriority,
      },
    ];

    const ToSearch = search ? search : null
    if (ToSearch) {
      filter.push({
        field: String(field),
        value: search,
      });
    }

    return filter;
  };

  const handleSearch = () => {
    const segment: string | null = activeTab === ALL ? null : activeTab;
    dispatch(
      fetchCollectionPayers({
        page,
        size,
        filters: getFilters(),
      })
    );
  };

  const handleChangeSelectedStatuses = (statuses: string[], source: string) => {
    if (source === OPERATION_TYPES_STATUS) {
      setSelectedOperationTypesStatusFilter(statuses);
    }
  };

  const handleDeleteSelectedStatuses = (
    selectedStatus: string,
    source: string
  ) => {
    if (source === OPERATION_TYPES_STATUS) {
      setSelectedOperationTypesStatusFilter(
        selectedOperationTypesStatusFilter.filter(
          status => status !== selectedStatus
        )
      );
    }
  };

  const handleChangePage = (rawPage: number, rawSize: number) => {
    setPage(rawPage);
    setSize(rawSize);
  };

  const handleChangeSearchInput = ( field : searchProps | searchOneProps) => {
    setField(field.field);
    if ('value' in field) setSearch(field.value);
    setPage(1);
  };

  const handleChangePriority = (value: string) => {
    setSelectedPriority(value);
  };

  return {
    search,
    field,
    size,
    page,
    rowsPerPage,
    selectedOperationTypesStatusFilter,
    selectedPriority,
    handleChangeSelectedStatuses,
    handleDeleteSelectedStatuses,
    handleSearch,
    handleChangePage,
    handleChangeSearchInput,
    handleChangePriority,
    setRowsPerPage,
    getFilters,
  };
};

export default useFiltersSection;
