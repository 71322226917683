import {
  DOWNLOAD_TGR_CERTIFICATE_FAILURE,
  DOWNLOAD_TGR_CERTIFICATE_START,
  DOWNLOAD_TGR_CERTIFICATE_SUCCESS,
  FETCH_BUSINESSES_ENROLLED_FAILURE,
  FETCH_BUSINESSES_ENROLLED_START,
  FETCH_BUSINESSES_ENROLLED_SUCCESS,
  FETCH_BUSINESSES_FAILURE,
  FETCH_BUSINESSES_START,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_START,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_USERS_FAILURE,
  FETCH_BUSINESS_USERS_START,
  FETCH_BUSINESS_USERS_SUCCESS,
  FETCH_BUSINESS_COMPLIANCE_START,
  FETCH_BUSINESS_COMPLIANCE_SUCCESS,
  FETCH_BUSINESS_COMPLIANCE_FAILURE,
  FETCH_BUSINESS_NAME_FAILURE,
  FETCH_BUSINESS_NAME_START,
  FETCH_BUSINESS_NAME_SUCCESS,
  CONSULT_BUSINESS_COMPLIANCE_FAILURE,
  CONSULT_BUSINESS_COMPLIANCE_START,
  CONSULT_BUSINESS_COMPLIANCE_SUCCESS,
  RESET_BUSINESS_COMPLIANCE,
  FETCH_TGR_AGREEMENTS_FAILURE,
  FETCH_TGR_AGREEMENTS_START,
  FETCH_TGR_AGREEMENTS_SUCCESS,
  FETCH_TGR_DEBT_FAILURE,
  FETCH_TGR_DEBT_START,
  FETCH_TGR_DEBT_SUCCESS,
  HANDLE_RESET_PFX,
  REGISTER_PFX_FAILURE,
  REGISTER_PFX_START,
  REGISTER_PFX_SUCCESS,
  REQUEST_DEBT_SUPER_BY_RUT_FAILURE,
  REQUEST_DEBT_SUPER_BY_RUT_SUCCESS,
  RESET_BUSINESSES,
  FETCH_COMPLIANCE_STAKEHOLDER_START,
  FETCH_COMPLIANCE_STAKEHOLDER_SUCCESS,
  FETCH_COMPLIANCE_STAKEHOLDER_FAILURE,
  FETCH_COMPLIANCE_PERSON_START,
  FETCH_COMPLIANCE_PERSON_SUCCESS,
  FETCH_COMPLIANCE_PERSON_FAILURE,
  RESET_BUSINESS_FEES_FAILURE,
  RESET_BUSINESS_FEES_START,
  RESET_BUSINESS_FEES_SUCCESS,
  RESET_DOWNLOAD_TGR_CERTIFICATE,
  RESET_FEES,
  RESET_UPDATED_BUSINESS_PROP,
  UPDATE_BUSINESS_CONFIRMING_STATUS_FAILURE,
  UPDATE_BUSINESS_CONFIRMING_STATUS_START,
  UPDATE_BUSINESS_CONFIRMING_STATUS_SUCCESS,
  UPDATE_BUSINESS_FEES_FAILURE,
  UPDATE_BUSINESS_FEES_START,
  UPDATE_BUSINESS_FEES_SUCCESS,
  UPDATE_BUSINESS_AUTORATE_START,
  UPDATE_BUSINESS_AUTORATE_SUCCESS,
  UPDATE_BUSINESS_AUTORATE_ERROR,
  UPDATE_SELECTED_BUSINESS_START,
  UPDATE_SELECTED_BUSINESS_SUCCESS,
  UPDATE_SELECTED_BUSINESS_FAILURE,
  FETCH_BUSINESS_PAYER_START,
  FETCH_BUSINESS_PAYER_SUCCESS,
  FETCH_BUSINESS_PAYER_FAILURE,
  FETCH_BUSINESS_INVOICES_START,
  FETCH_BUSINESS_INVOICES_SUCCESS,
  FETCH_BUSINESS_INVOICES_FAILURE,
  FETCH_BUSINESS_FINDER_INVOICES_START,
  FETCH_BUSINESS_FINDER_INVOICES_SUCCESS,
  FETCH_BUSINESS_FINDER_INVOICES_FAILURE,
  CALCULATE_BUSINESS_FEES_START,
  CALCULATE_BUSINESS_FEES_SUCCESS,
  CALCULATE_BUSINESS_FEES_FAILURE,
  CALCULATE_FEES_BUSINESS_NOT_ENROLLED_START,
  CALCULATE_FEES_BUSINESS_NOT_ENROLLED_SUCCESS,
  CALCULATE_FEES_BUSINESS_NOT_ENROLLED_FAILURE,
  UPDATE_BUSINESS_NAME_START,
  UPDATE_BUSINESS_NAME_SUCCESS,
  UPDATE_BUSINESS_NAME_FAILURE,
  FETCH_NOT_ENROLLED_BUSINESS_INVOICES_START,
  FETCH_NOT_ENROLLED_BUSINESS_INVOICES_SUCCESS,
  FETCH_NOT_ENROLLED_BUSINESS_INVOICES_FAILURE,
  FETCH_BUSINESS_BY_IDENTIFIER_START,
  FETCH_BUSINESS_BY_IDENTIFIER_SUCCESS,
  FETCH_BUSINESS_BY_IDENTIFIER_FAILURE,
  RESET_BUSINESS,
  RESET_CREATE_OPERATION_DATA,
  FETCH_CRITIAL_STATUS_INFO_START,
  FETCH_CRITIAL_STATUS_INFO_SUCCESS,
  FETCH_CRITIAL_STATUS_INFO_FAILURE,
  FETCH_API_CORPS_AMOUNT_START,
  FETCH_API_CORPS_AMOUNT_SUCCESS,
  FETCH_API_CORPS_AMOUNT_FAILURE,
} from '../actions/types';
import { settings } from '../config/settings';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../helpers/Constants';

const initialState = {
  loadingBusiness: false,
  updatingBusiness: false,
  savedBusiness: false,
  businesses: [],
  business: {},
  errors: '',
  uploadingPfx: false,
  pfxSuccess: false,
  updatedBusiness: {},
  taxFolder: null,
  loadingTaxFolder: false,
  loadingTaxFolderPDF: false,
  saving: false,
  tgrCertificateSuccess: false,
  tgrCertificate: null,
  fetchingTGRCertificate: undefined,
  msgSuccess: '',
  msgError: '',

  tgrAgreements: [],
  tgrAgreementsPagination: { page: 0, pageSize: 20, totalData: 0 },
  loadingTgrAgreements: false,

  fetchTotalTgrDebtLoading: false,
  totalTgrDebt: null,
  fetchTotalTgrDebtError: null,

  loadingBusinesses: false,
  businessesEnrolled: [],
  businessesEnrolledError: null,

  fetchBusinessPayerLoading: false,
  businessPayers: [],
  fetchBusinessPayerError: null,

  fetchBusinessInvoicesLoading: false,
  businessInvoices: [],
  fetchBusinessInvoicesError: null,

  fetchBusinessFinderInvoicesLoading: false,
  businessFinderInvoices: [],
  fetchBusinessFinderInvoicesError: null,
  finderInvoicesPagination: { page: 0, pageSize: 20, totalData: 0 },

  calculateBusinessFeesLoading: false,
  businessFees: {},
  calculateBusinessFeesError: null,

  businessIdOwnerOfUsers: null,
  businessUsers: [],
  businessUsersLoading: false,
  businessUsersError: null,

  consultBusinessComplianceIsLoading: false,
  consultBusinessComplianceError: null,
  businessComplianceConsulted: false,

  complianceIsDone: false,
  stakeholderCompliance: {},
  complianceError: null,
  complianceLoading: false,

  fetchBusinessComplianceIsLoading: false,
  businessCompliance: { complianceDetail: null, lastRequest: null },
  fetchBusinessComplianceError: false,

  updateFeesLoading: false,
  updateFeesDone: false,
  updateFeesError: null,

  pagination: { page: 0, pageSize: 20, totalData: 0 },
  loadingConfirmingStatus: false,

  updateAutoRateIsLoading: false,
  businessUpdated: false,

  uploadPfxIsLoading: false,
  pfxWasUploaded: false,
  pfxErrors: null,

  businessName: null,
  fetchingBusinessName: false,

  criticalStatusInfo: {},
  criticalStatusInfoIsLoading: false,

  fetchApiCorpsIsLoading: false,
  apiCorpsData: null,
};

const fetchBusinessUsersStart = (state, payload) => {
  return {
    ...state,
    businessIdOwnerOfUsers: payload.businessId,
    businessUsers: [],
    businessUsersLoading: true,
    businessUsersError: null,
  };
};

const fetchBusinessUsersSuccess = (state, payload) => {
  return {
    ...state,
    businessUsers: payload.businessUsers,
    businessUsersLoading: false,
  };
};

const fetchBusinessUsersFailure = (state, payload) => {
  return {
    ...state,
    businessUsersLoading: false,
    businessUsersError: payload.error,
  };
};

const setLoadingConfirmingStatus = (state, loadingConfirmingStatus = false) => {
  return {
    ...state,
    loadingConfirmingStatus,
  };
};

const updateBusinessConfirmingStatusSuccess = (state, payload) => {
  const business = { ...state.business };
  business.hasConfirmingEnabled = payload.business?.hasConfirmingEnabled;
  return {
    ...state,
    business,
    loadingConfirmingStatus: false,
  };
};

const updateSelectedBusinessStart = state => {
  return { ...state, attachmentAdded: false };
};

const updateSelectedBusinessSuccess = (state, payload) => {
  const { type, status } = payload;
  const newBusiness = { ...state.business };
  if (type === 'CONTRACT') {
    newBusiness.contract = status;
  } else {
    newBusiness.legalReport = status;
  }
  return { ...state, attachmentAdded: true, business: newBusiness };
};

const updateSelectedBusinessFailure = state => {
  return { ...state, attachmentAdded: false };
};

const updateAutoRateStart = state => {
  return {
    ...state,
    updateAutoRateIsLoading: true,
  };
};

const updateAutoRateSuccess = (state, payload) => {
  const { business } = state;
  const { autorate } = payload;
  return {
    ...state,
    business: { ...business, autorate },
    updateAutoRateIsLoading: false,
  };
};

const updateAutoRateError = state => {
  return {
    ...state,
    updateAutoRateIsLoading: false,
  };
};

const fetchComplianceStakeholderStart = (state, payload) => {
  const { identifier } = payload;
  const { complianceList = {} } = state;

  const updatedComplianceList = {
    ...complianceList,
    [identifier]: { loading: true },
  };
  return {
    ...state,
    complianceError: null,
    businessComplianceConsulted: false,

    complianceIsDone: false,
    complianceLoading: true,
    complianceList: { ...updatedComplianceList },
  };
};

const fetchComplianceStakeholderSuccess = (state, payload) => {
  const { identifier } = payload;
  const { complianceList = {} } = state;
  const updatedComplianceList = {
    ...complianceList,
    [identifier]: { ...payload, loading: false },
  };

  return {
    ...state,
    businessComplianceConsulted: false,

    complianceIsDone: true,
    complianceLoading: false,
    complianceList: { ...updatedComplianceList },
  };
};

const fetchComplianceStakeholderFailure = (state, payload) => {
  const { identifier } = payload;
  const { complianceList = {} } = state;
  const updatedComplianceList = {
    ...complianceList,
    [identifier]: { loading: false },
  };

  return {
    ...state,
    complianceError: payload.errorCode,
    complianceLoading: false,
    complianceList: { ...updatedComplianceList },
  };
};

const fetchBusinessComplianceStart = state => {
  return {
    ...state,
    fetchBusinessComplianceIsLoading: true,
    fetchBusinessComplianceError: false,
  };
};

// TODO: REFACTOR THIS AFTER REFACTORING GLOBAL SERVER RESPONSE( global server currently returns an array if there's any registry and an object {} if there are no records)
const fetchBusinessComplianceSuccess = (state, payload) => {
  const { lastRequest = null, ComplianceDetail: complianceDetail = null } =
    payload;

  const businessCompliance = {
    complianceDetail,
    lastConsultCompliance: lastRequest,
  };

  return {
    ...state,
    businessCompliance,
    fetchBusinessComplianceIsLoading: false,
    fetchBusinessComplianceError: false,
  };
};

const fetchBusinessComplianceFailure = (state, payload) => {
  return {
    ...state,
    fetchBusinessComplianceIsLoading: false,
    fetchBusinessComplianceError: payload.errorCode,
  };
};

const resetBusinessCompliance = state => {
  return {
    ...state,
    businessCompliance: { complianceDetail: null, lastRequest: null },
  };
};

const consultBusinessComplianceStart = state => {
  return {
    ...state,
    consultBusinessComplianceError: null,
    consultBusinessComplianceIsLoading: true,
    businessComplianceConsulted: false,
  };
};

const consultBusinessComplianceSuccess = (state, payload) => {
  const { countryId } = payload;
  const { isApiGlobal } = settings[COUNTRY_CODE_CL];

  if (isApiGlobal || countryId === COUNTRY_CODE_MX) {
    const { lastRequest = null, ComplianceDetail: complianceDetail = null } =
      payload;
    const businessCompliance = {
      complianceDetail,
      lastConsultCompliance: lastRequest,
    };

    return {
      ...state,
      businessCompliance,
      consultBusinessComplianceIsLoading: false,
      businessComplianceConsulted: true,
    };
  }
  const { data, complianceType, updatedAt } = payload;
  const { business } = state;

  if (data?.complianceInfo) {
    const { complianceInfo } = data;
    business.ComplianceBusinesses = complianceInfo;
  }
  if (complianceType || updatedAt) {
    const types = complianceType.split(',');
    business.ComplianceBusinesses = {
      lastConsult: updatedAt,
      types,
    };
  }
  return {
    ...state,
    business,
    complianceLoading: false,
    businessComplianceConsulted: true,
  };
};

const consultBusinessComplianceFailure = (state, payload) => {
  return {
    ...state,
    consultBusinessComplianceIsLoading: false,
    consultBusinessComplianceError: payload.errorCode,
  };
};

const fetchBusinessesEnrolledStart = state => {
  return {
    ...state,
    loadingBusinesses: true,
    businessesEnrolledError: null,
  };
};

const fetchBusinessesEnrolledSuccess = (state, payload) => {
  return {
    ...state,
    loadingBusinesses: false,
    businessesEnrolled: payload,
  };
};

const fetchBusinessesEnrolledFailure = (state, payload) => {
  return {
    ...state,
    loadingBusinesses: false,
    businessesEnrolledError: payload.errorCode,
  };
};

const fetchBusinessPayerStart = state => {
  return {
    ...state,
    businessPayers: [],
    fetchBusinessPayerLoading: true,
    fetchBusinessPayerError: null,
  };
};

const fetchBusinessPayerSuccess = (state, payload) => {
  return {
    ...state,
    fetchBusinessPayerLoading: false,
    businessPayers: payload,
  };
};

const fetchBusinessPayerFailure = (state, payload) => {
  return {
    ...state,
    fetchBusinessPayerLoading: false,
    fetchBusinessPayerError: payload.errorCode,
  };
};

const fetchBusinessInvoicesStart = state => {
  return {
    ...state,
    fetchBusinessInvoicesLoading: true,
    fetchBusinessInvoicesError: null,
  };
};

const fetchBusinessInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    fetchBusinessInvoicesLoading: false,
    businessInvoices: payload,
  };
};

const fetchBusinessInvoicesFailure = (state, payload) => {
  return {
    ...state,
    fetchBusinessInvoicesLoading: false,
    fetchBusinessInvoicesError: payload.errorCode,
  };
};
const fetchBusinessFinderInvoicesStart = state => {
  return {
    ...state,
    fetchBusinessFinderInvoicesLoading: true,
    fetchBusinessFinderInvoicesError: null,
  };
};

const fetchBusinessFinderInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    fetchBusinessFinderInvoicesLoading: false,
    businessFinderInvoices: payload.data,
    finderInvoicesPagination: {
      totalData: payload.pagination.totalData,
      page: payload.pagination.page,
      pageSize: payload.pagination.pageSize,
    },
  };
};

const fetchBusinessFinderInvoicesFailure = (state, payload) => {
  return {
    ...state,
    fetchBusinessFinderInvoicesLoading: false,
    fetchBusinessFinderInvoicesError: payload.errorCode,
  };
};

const calculateBusinessFeesStart = state => {
  return {
    ...state,
    calculateBusinessFeesLoading: true,
    calculateBusinessFeesError: null,
  };
};

const calculateBusinessFeesSuccess = (state, payload) => {
  return {
    ...state,
    calculateBusinessFeesLoading: false,
    businessFees: payload,
  };
};

const calculateBusinessFeesFailure = (state, payload) => {
  return {
    ...state,
    calculateBusinessFeesLoading: false,
    calculateBusinessFeesError: payload.errorCode,
  };
};

const fetchTgrAgreementsStart = state => {
  return {
    ...state,
    loadingTgrAgreements: true,
  };
};

const fetchTgrAgreementsSuccess = (state, payload) => {
  return {
    ...state,
    tgrAgreements: payload.tgrAgreements,
    tgrAgreementsPagination: {
      ...state.pagination,
      ...payload.pagination,
    },
    loadingTgrAgreements: false,
  };
};

const fetchTgrAgreementsFailure = (state, payload) => {
  return {
    ...state,
    msgError: payload.errorCode,
    loadingTgrAgreements: false,
  };
};

const fetchTgrDebtStart = state => {
  return {
    ...state,
    fetchTotalTgrDebtLoading: true,
  };
};

const fetchTgrDebtSuccess = (state, payload) => {
  return {
    ...state,
    fetchTotalTgrDebtLoading: false,
    totalTgrDebt: payload.totalTGRDebt,
    fetchTotalTgrDebtError: null,
  };
};

const fetchTgrDebtFailure = (state, payload) => {
  return {
    ...state,
    fetchTotalTgrDebtLoading: false,
    fetchTotalTgrDebtError: payload.errorCode,
  };
};

const updateBusinessNameStart = state => {
  return {
    ...state,
    updatingBusiness: true,
    businessUpdated: false,
  };
};

const updateBusinessNameSuccess = (state, payload) => {
  const { business } = state;
  return {
    ...state,
    updatingBusiness: true,
    businessUpdated: true,
    business: {
      ...business,
      ...(payload?.name && { name: payload.name }),
    },
  };
};

const updateBusinessNameFailure = (state, payload) => {
  return {
    ...state,
    updatingBusiness: true,
    businessUpdated: false,
    msgError: payload.errorCode,
  };
};

const fetchNotEnrolledBusinessInvoicesStart = state => {
  return {
    ...state,
    fetchBusinessInvoicesLoading: true,
    fetchBusinessInvoicesError: null,
  };
};

const fetchNotEnrolledBusinessInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    fetchBusinessInvoicesLoading: false,
    businessInvoices: payload,
  };
};

const fetchNotEnrolledBusinessInvoicesFailure = (state, payload) => {
  return {
    ...state,
    fetchBusinessInvoicesLoading: false,
    fetchBusinessInvoicesError: payload.errorCode,
  };
};

const fetchBusinessNameStart = state => {
  return {
    ...state,
    fetchingBusinessName: true,
  };
};

const fetchBusinessNameSuccess = (state, payload) => {
  return {
    ...state,
    fetchingBusinessName: false,
    businessName: payload?.name || '',
  };
};

const fetchBusinessNameFailure = state => {
  return {
    ...state,
    fetchingBusinessName: false,
  };
};

const fetchBusinessByIdentifierStart = state => {
  return {
    ...state,
    loadingBusiness: true,
  };
};

const fetchBusinessByIdentifierSuccess = (state, payload) => {
  return {
    ...state,
    loadingBusiness: false,
    business: payload,
  };
};

const fetchBusinessByIdentifierFailure = state => {
  return {
    ...state,
    loadingBusiness: false,
  };
};

const resetBusiness = state => {
  return {
    ...state,
    business: {},
    businessName: null,
  };
};

const resetCreateOperationData = state => ({
  ...state,
  businessPayers: [],
  businessInvoices: [],
  businessFees: {},
});

const fetchCriticalStatusInfoStart = state => ({
  ...state,
  criticalStatusInfo: {},
  criticalStatusInfoIsLoading: true,
});
const fetchCriticalStatusInfoSuccess = (state, payload) => ({
  ...state,
  criticalStatusInfo: payload.criticalStatusInfo,
  criticalStatusInfoIsLoading: false,
});
const fetchCriticalStatusInfoFailure = state => ({
  ...state,
  criticalStatusInfoIsLoading: false,
});

const fetchApiCorpsStart = state => ({
  ...state,
  fetchApiCorpsIsLoading: true,
  apiCorpsData: null,
});
const fetchApiCorpsSuccess = (state, payload) => ({
  ...state,
  fetchApiCorpsIsLoading: false,
  apiCorpsData: payload,
});
const fetchApiCorpsFailure = state => ({
  ...state,
  fetchApiCorpsIsLoading: false,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BUSINESS_USERS_START:
      return fetchBusinessUsersStart(state, payload);
    case FETCH_BUSINESS_USERS_SUCCESS:
      return fetchBusinessUsersSuccess(state, payload);
    case FETCH_BUSINESS_USERS_FAILURE:
      return fetchBusinessUsersFailure(state, payload);
    case FETCH_BUSINESSES_START:
      return {
        ...state,
        loadingBusinesses: true,
      };
    case FETCH_BUSINESSES_SUCCESS:
      return {
        ...state,
        loadingBusinesses: false,
        businesses: payload.data,
        pagination: {
          totalData: payload.pagination.totalData,
          page: payload.pagination.page,
          pageSize: payload.pagination.pageSize,
        },
      };
    case FETCH_BUSINESSES_FAILURE:
      return {
        ...state,
        loadingBusiness: false,
        business: initialState.business,
        errors: payload.errorCode,
      };
    case RESET_BUSINESSES: {
      return { ...state, businesses: [] };
    }
    case FETCH_BUSINESS_START:
      return {
        ...state,
        loadingBusiness: true,
      };
    case FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        loadingBusiness: false,
        business: payload.business,
        saving: false,
      };
    case FETCH_BUSINESS_FAILURE:
      return {
        ...state,
        loadingBusinesses: false,
        errors: payload.errorCode,
        saving: false,
      };

    case RESET_FEES:
      return {
        ...state,
        updateFeesError: null,
      };
    case UPDATE_BUSINESS_FEES_START:
    case RESET_BUSINESS_FEES_START:
      return {
        ...state,
        updateFeesLoading: true,
        updateFeesDone: false,
        updateFeesError: null,
      };
    case UPDATE_BUSINESS_FEES_SUCCESS:
    case RESET_BUSINESS_FEES_SUCCESS: {
      const businessUpdated = { ...state.business, ...payload };
      return {
        ...state,
        business: businessUpdated,
        updateFeesLoading: false,
        updateFeesDone: true,
      };
    }
    case UPDATE_BUSINESS_FEES_FAILURE:
    case RESET_BUSINESS_FEES_FAILURE:
      return {
        ...state,
        updateFeesLoading: false,
        updateFeesError: payload.errorCode,
      };

    case REGISTER_PFX_START:
      return {
        ...state,
        uploadPfxIsLoading: true,
        pfxWasUploaded: false,
        pfxErrors: null,
        // delete after migration
        uploadingPfx: true,
      };
    case REGISTER_PFX_SUCCESS:
      return {
        ...state,
        uploadPfxIsLoading: false,
        pfxWasUploaded: true,
        business: {
          ...state.business,
          hasPfxCertificate: true,
          // delete after migration
          pfxCertificate: true,
        },
        // delete after migration
        uploadingPfx: false,
        pfxSuccess: true,
        savedBusiness: true,
        updatedBusiness: payload,
      };
    case REGISTER_PFX_FAILURE:
      return {
        ...state,
        uploadPfxIsLoading: false,
        pfxErrors: payload.errorCode,
        // delete after migration
        uploadingPfx: false,
      };

    case RESET_UPDATED_BUSINESS_PROP:
      return {
        ...state,
        updatedBusiness: {},
      };

    case HANDLE_RESET_PFX:
      return {
        ...state,
        uploadingPfx: false,
        pfxSuccess: false,
        savedBusiness: false,
        pfxErrors: null,
      };

    case DOWNLOAD_TGR_CERTIFICATE_START:
      return {
        ...state,
        tgrCertificateSuccess: false,
        fetchingTGRCertificate: true,
      };
    case DOWNLOAD_TGR_CERTIFICATE_SUCCESS:
      return {
        ...state,
        tgrCertificateSuccess: true,
        tgrCertificate: payload.pdf,
        fetchingTGRCertificate: false,
      };
    case DOWNLOAD_TGR_CERTIFICATE_FAILURE:
      return {
        ...state,
        tgrCertificateSuccess: false,
        msgError: payload.errorCode,
        fetchingTGRCertificate: false,
      };
    case RESET_DOWNLOAD_TGR_CERTIFICATE:
      return {
        ...state,
        tgrCertificateSuccess: false,
        fetchingTGRCertificate: false,
        msgError: '',
      };
    case FETCH_TGR_AGREEMENTS_START:
      return fetchTgrAgreementsStart(state);
    case FETCH_TGR_AGREEMENTS_SUCCESS:
      return fetchTgrAgreementsSuccess(state, payload);
    case FETCH_TGR_AGREEMENTS_FAILURE:
      return fetchTgrAgreementsFailure(state, payload);
    case FETCH_TGR_DEBT_START:
      return fetchTgrDebtStart(state);
    case FETCH_TGR_DEBT_SUCCESS:
      return fetchTgrDebtSuccess(state, payload);
    case FETCH_TGR_DEBT_FAILURE:
      return fetchTgrDebtFailure(state, payload);
    case CONSULT_BUSINESS_COMPLIANCE_START:
      return consultBusinessComplianceStart(state);
    case CONSULT_BUSINESS_COMPLIANCE_SUCCESS:
      return consultBusinessComplianceSuccess(state, payload);
    case CONSULT_BUSINESS_COMPLIANCE_FAILURE:
      return consultBusinessComplianceFailure(state, payload);
    case FETCH_BUSINESS_COMPLIANCE_START:
      return fetchBusinessComplianceStart(state);
    case FETCH_BUSINESS_COMPLIANCE_SUCCESS:
      return fetchBusinessComplianceSuccess(state, payload);
    case FETCH_BUSINESS_COMPLIANCE_FAILURE:
      return fetchBusinessComplianceFailure(state, payload);
    case RESET_BUSINESS_COMPLIANCE:
      return resetBusinessCompliance(state);

    case FETCH_COMPLIANCE_STAKEHOLDER_START:
      return fetchComplianceStakeholderStart(state, payload);

    case FETCH_COMPLIANCE_STAKEHOLDER_SUCCESS:
      return fetchComplianceStakeholderSuccess(state, payload);

    case FETCH_COMPLIANCE_STAKEHOLDER_FAILURE:
      return fetchComplianceStakeholderFailure(state, payload);

    case FETCH_COMPLIANCE_PERSON_START:
      return fetchComplianceStakeholderStart(state, payload);

    case FETCH_COMPLIANCE_PERSON_SUCCESS:
      return fetchComplianceStakeholderSuccess(state, payload);

    case FETCH_COMPLIANCE_PERSON_FAILURE:
      return fetchComplianceStakeholderFailure(state, payload);

    case REQUEST_DEBT_SUPER_BY_RUT_SUCCESS: {
      const { lastConsultDebtSuper } = payload;
      let { business } = state;
      business = { ...business, lastConsultDebtSuper };
      return {
        ...state,
        business,
      };
    }

    case REQUEST_DEBT_SUPER_BY_RUT_FAILURE:
      return {
        ...state,
      };

    case FETCH_BUSINESSES_ENROLLED_START:
      return fetchBusinessesEnrolledStart(state);
    case FETCH_BUSINESSES_ENROLLED_SUCCESS:
      return fetchBusinessesEnrolledSuccess(state, payload);
    case FETCH_BUSINESSES_ENROLLED_FAILURE:
      return fetchBusinessesEnrolledFailure(state, payload);
    case UPDATE_BUSINESS_CONFIRMING_STATUS_START:
      return setLoadingConfirmingStatus(state, true);
    case UPDATE_BUSINESS_CONFIRMING_STATUS_SUCCESS:
      return updateBusinessConfirmingStatusSuccess(state, payload);
    case UPDATE_BUSINESS_CONFIRMING_STATUS_FAILURE:
      return setLoadingConfirmingStatus(state);
    case UPDATE_SELECTED_BUSINESS_START:
      return updateSelectedBusinessStart(state);
    case UPDATE_SELECTED_BUSINESS_SUCCESS:
      return updateSelectedBusinessSuccess(state, payload);
    case UPDATE_SELECTED_BUSINESS_FAILURE:
      return updateSelectedBusinessFailure(state);
    case UPDATE_BUSINESS_AUTORATE_START:
      return updateAutoRateStart(state);
    case UPDATE_BUSINESS_AUTORATE_SUCCESS:
      return updateAutoRateSuccess(state, payload);
    case UPDATE_BUSINESS_AUTORATE_ERROR:
      return updateAutoRateError(state);
    case FETCH_BUSINESS_PAYER_START:
      return fetchBusinessPayerStart(state);
    case FETCH_BUSINESS_PAYER_SUCCESS:
      return fetchBusinessPayerSuccess(state, payload);
    case FETCH_BUSINESS_PAYER_FAILURE:
      return fetchBusinessPayerFailure(state, payload);
    case FETCH_BUSINESS_INVOICES_START:
      return fetchBusinessInvoicesStart(state);
    case FETCH_BUSINESS_INVOICES_SUCCESS:
      return fetchBusinessInvoicesSuccess(state, payload);
    case FETCH_BUSINESS_INVOICES_FAILURE:
      return fetchBusinessInvoicesFailure(state, payload);
    case FETCH_BUSINESS_FINDER_INVOICES_START:
      return fetchBusinessFinderInvoicesStart(state);
    case FETCH_BUSINESS_FINDER_INVOICES_SUCCESS:
      return fetchBusinessFinderInvoicesSuccess(state, payload);
    case FETCH_BUSINESS_FINDER_INVOICES_FAILURE:
      return fetchBusinessFinderInvoicesFailure(state, payload);
    case CALCULATE_BUSINESS_FEES_START:
      return calculateBusinessFeesStart(state);
    case CALCULATE_BUSINESS_FEES_SUCCESS:
      return calculateBusinessFeesSuccess(state, payload);
    case CALCULATE_BUSINESS_FEES_FAILURE:
      return calculateBusinessFeesFailure(state, payload);
    case CALCULATE_FEES_BUSINESS_NOT_ENROLLED_START:
      return calculateBusinessFeesStart(state);
    case CALCULATE_FEES_BUSINESS_NOT_ENROLLED_SUCCESS:
      return calculateBusinessFeesSuccess(state, payload);
    case CALCULATE_FEES_BUSINESS_NOT_ENROLLED_FAILURE:
      return calculateBusinessFeesFailure(state, payload);
    case UPDATE_BUSINESS_NAME_START:
      return updateBusinessNameStart(state);
    case UPDATE_BUSINESS_NAME_SUCCESS:
      return updateBusinessNameSuccess(state, payload);
    case UPDATE_BUSINESS_NAME_FAILURE:
      return updateBusinessNameFailure(state, payload);
    case FETCH_NOT_ENROLLED_BUSINESS_INVOICES_START:
      return fetchNotEnrolledBusinessInvoicesStart(state);
    case FETCH_NOT_ENROLLED_BUSINESS_INVOICES_SUCCESS:
      return fetchNotEnrolledBusinessInvoicesSuccess(state, payload);
    case FETCH_NOT_ENROLLED_BUSINESS_INVOICES_FAILURE:
      return fetchNotEnrolledBusinessInvoicesFailure(state, payload);
    case FETCH_BUSINESS_NAME_START:
      return fetchBusinessNameStart(state);
    case FETCH_BUSINESS_NAME_SUCCESS:
      return fetchBusinessNameSuccess(state, payload);
    case FETCH_BUSINESS_NAME_FAILURE:
      return fetchBusinessNameFailure(state);
    case FETCH_BUSINESS_BY_IDENTIFIER_START:
      return fetchBusinessByIdentifierStart(state);
    case FETCH_BUSINESS_BY_IDENTIFIER_SUCCESS:
      return fetchBusinessByIdentifierSuccess(state, payload);
    case FETCH_BUSINESS_BY_IDENTIFIER_FAILURE:
      return fetchBusinessByIdentifierFailure(state);
    case RESET_BUSINESS:
      return resetBusiness(state);
    case RESET_CREATE_OPERATION_DATA:
      return resetCreateOperationData();
    case FETCH_CRITIAL_STATUS_INFO_START:
      return fetchCriticalStatusInfoStart(state);
    case FETCH_CRITIAL_STATUS_INFO_SUCCESS:
      return fetchCriticalStatusInfoSuccess(state, payload);
    case FETCH_CRITIAL_STATUS_INFO_FAILURE:
      return fetchCriticalStatusInfoFailure(state);
    case FETCH_API_CORPS_AMOUNT_START:
      return fetchApiCorpsStart(state);
    case FETCH_API_CORPS_AMOUNT_SUCCESS:
      return fetchApiCorpsSuccess(state, payload);
    case FETCH_API_CORPS_AMOUNT_FAILURE:
      return fetchApiCorpsFailure(state);
    default:
      return state;
  }
};
