export enum NotificationStatus {
  New = 'NEW',
  Checked = 'CHECKED',
}

export enum NotificationType {
  Comments = 'COMMENTS',
}

export interface IUserNotifications {
  id: string | number;
  status: NotificationStatus;
  subscriptions: INotification[];
}

export interface INotification {
  id: string;
  createdAt: string;
  subscriptionType: NotificationType;
  status: NotificationStatus;
  payload: INotificationPayload;
}

export interface INotificationPayload {
  title: string;
  description: string;
  isNew: boolean;
  isChecked: boolean;
  href: string;
}

export interface INotificationParsed {
  notificationId: string;
  createdAt: string;
  subscriptionType: NotificationType;
  status: NotificationStatus;
  title: string;
  description: string;
  isNew: boolean;
  isChecked: boolean;
  href: string;
}
