import { useQuery } from 'react-query';
import fetchOrderComments from '../services/fetchOrderComments';

const useFetchOrderComments = (operationId: number) => {
  const { isLoading, isRefetching, data } = useQuery(
    ['fetchOrderComments', operationId],
    () => fetchOrderComments(operationId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 0,
      retry: false,
    }
  );

  const orderComments = data || [];

  return {
    orderComments,
    isLoading: isLoading || isRefetching,
  };
};

export default useFetchOrderComments;
