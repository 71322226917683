import { Box, styled, Card } from '@material-ui/core';

export const ContainerRoot = styled(Box)({
  padding: 0,
});

export const ContainerData = styled(Box)({
  padding: '30px 20px',
});

export const ContainerHeader = styled(Box)({
  marginBottom: 30,
});

export const CardContainer = styled(Card)({
  background: '#fff',
  borderRadius: 16,
  boxShadow: 'none',
});
