import axios from 'axios';

export const orderBulkUpdateStateService = async ({
  orderIds,
  status,
  statusReason = null,
}) => {
  try {
    const { data } = await axios.post('/api/orders/bulk-status', {
      orderIds,
      status,
      statusReason,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default orderBulkUpdateStateService;
