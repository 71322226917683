export const hasValue = v => ![NaN, null, undefined, ''].includes(v);

export const isObject = value =>
  Object.prototype.toString.call(value) === '[object Object]';

export const isEmptyObj = obj => {
  if (!isObject(obj)) {
    return true;
  }

  return !Object.keys(obj).length;
};
