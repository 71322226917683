export const addReceiverUseCase = ({
  currentSelectorState,
  prevStateTable,
  selectedRows,
}) => {
  const selectedCurrentIds = selectedRows.map(r => r.receiverIdentifier);
  const newReceiversToAdd = selectedCurrentIds.filter(
    id => !Object.keys(currentSelectorState).includes(id)
  );

  if (newReceiversToAdd.length) {
    const newState = newReceiversToAdd.reduce((acc, receiverIdentifier) => {
      const receiverToAdd = selectedRows.find(
        r => r.receiverIdentifier === receiverIdentifier
      );
      const allIsSelected =
        receiverToAdd.totalOpportunityDocuments ===
        receiverToAdd.documentsNumber;

      return {
        ...acc,
        [receiverIdentifier]: {
          amount: receiverToAdd.amount,
          name: receiverToAdd.name,
          selected: allIsSelected,
          value: receiverToAdd.documentsIds,
        },
      };
    }, currentSelectorState);

    return { newState };
  }

  const selectedPrevIds = prevStateTable.reduce((acc, row) => {
    if (row.tableData.checked) {
      acc.push(row.receiverIdentifier);
    }
    return acc;
  }, []);
  const selectedIdsAgain = selectedCurrentIds.filter(
    id => !selectedPrevIds.includes(id)
  );
  const removedReceiverIds = selectedPrevIds.filter(
    r => !selectedCurrentIds.includes(r)
  );

  const newStateSelector = Object.keys(currentSelectorState).reduce(
    (acc, receiverIdentifier) => {
      const currentSelectedReceiver = currentSelectorState[receiverIdentifier];

      if (removedReceiverIds.includes(receiverIdentifier)) {
        const removedReceiver = prevStateTable.find(
          row => row.receiverIdentifier === receiverIdentifier
        );
        const allDeleted =
          currentSelectedReceiver.value.length ===
          removedReceiver.documentsNumber;

        if (allDeleted) {
          delete acc[receiverIdentifier];

          return acc;
        }

        return {
          ...acc,
          [receiverIdentifier]: {
            amount: currentSelectedReceiver.amount - removedReceiver.amount,
            name: currentSelectedReceiver.name,
            selected: false,
            value: currentSelectedReceiver.value.filter(
              doc =>
                !removedReceiver.documentsIds.find(docu => docu.id === doc.id)
            ),
          },
        };
      }

      if (selectedIdsAgain.includes(receiverIdentifier)) {
        const receiverToAdd = selectedRows.find(
          r => r.receiverIdentifier === receiverIdentifier
        );
        const allIsSelected =
          receiverToAdd.totalOpportunityDocuments ===
          receiverToAdd.documentsNumber;

        if (allIsSelected) {
          return {
            ...acc,
            [receiverIdentifier]: {
              amount: receiverToAdd.amount,
              name: receiverToAdd.name,
              selected: allIsSelected,
              value: receiverToAdd.documentsIds,
            },
          };
        }

        const newDocToAdd = receiverToAdd.documentsIds.reduce(
          (newDocs, docu) => {
            if (
              !currentSelectedReceiver.value.find(doc => doc.id === docu.id)
            ) {
              newDocs.push(docu);
            }

            return newDocs;
          },
          []
        );

        const docsToAdd = [...currentSelectedReceiver.value, ...newDocToAdd];
        const selected =
          docsToAdd.length === receiverToAdd.totalOpportunityDocuments;

        return {
          ...acc,
          [receiverIdentifier]: {
            amount: currentSelectedReceiver.amount + receiverToAdd.amount,
            name: receiverToAdd.name,
            selected,
            value: docsToAdd,
          },
        };
      }

      return {
        ...acc,
        [receiverIdentifier]: currentSelectorState[receiverIdentifier],
      };
    },
    {}
  );

  return { newState: newStateSelector, removedReceiverIds };
};
