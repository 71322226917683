import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormHelperText,
  IconButton,
  makeStyles,
  Button,
} from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import DeleteIcon from '@material-ui/icons/Delete';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { settings } from '../../config/settings';

const useStyles = makeStyles({
  inputContainer: {
    maxWidth: 'calc(50% - 10px)',
    flexBasis: 'calc(50% - 10px)',
  },
  buttonDelete: {
    marginTop: 4,
  },
  errorLabel: {
    marginTop: -10,
    marginBottom: 15,
  },
});

const RatesForm = props => {
  const { rates, handleChangeRates, validationListener, isLoading, disabled } =
    props;
  const classes = useStyles();
  const [rateForm, setRatesForm] = useState(rates);
  const [touchedRates, setTouchedRates] = useState([false]);

  const { country } = useSelector(state => state.config);

  const disableAddDevengoRate =
    !rateForm[rateForm.length - 1]?.days ||
    !rateForm[rateForm.length - 1]?.rate;
  const {
    rate: { minRate, maxRate },
  } = settings[country];

  useEffect(() => {
    if (!isLoading) {
      setRatesForm(rates);
      setTouchedRates(Array(rates.length).fill(false));
    }
  }, [isLoading, rates]);

  const validateIncompleteRateAndTouched = (businessRate, index) => {
    const { days, rate } = businessRate;
    return (
      (((!days && rate) || (days && !rate)) && touchedRates[index]) ||
      (!days &&
        !rate &&
        touchedRates[index] &&
        index !== touchedRates.length - 1)
    );
  };

  useEffect(() => {
    handleChangeRates(rateForm);
    validationListener(!rateForm.some(validateIncompleteRateAndTouched));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateForm]);

  const handleSetDevengoRate = (e, index, type) => {
    const {
      target: { value },
    } = e;
    e.stopPropagation();
    if (type === 'days') {
      rateForm[index].days = value;
    } else {
      rateForm[index].rate = value;
    }
    setRatesForm([...rateForm]);
    const newTouched = [...touchedRates];
    newTouched[index] = true;
    setTouchedRates(newTouched);
  };

  const handleAddDevengoRate = () => {
    setRatesForm([...rateForm, { days: '', rate: '' }]);
    const newTouched = [...touchedRates];
    newTouched.push(false);
    setTouchedRates(newTouched);
  };

  const handleDeleteDevengoRate = devengoRate => {
    const idxRate = rateForm.indexOf(devengoRate);
    const rates = rateForm.filter(({ days }) => days !== devengoRate.days);
    setRatesForm(rates);

    const newTouched = [...touchedRates];
    newTouched.splice(idxRate, 1);
    setTouchedRates(newTouched);
  };

  return (
    <>
      <Grid container justify="space-between">
        {rateForm.map((rate, index) => {
          const isValidRow = !validateIncompleteRateAndTouched(rate, index);
          return (
            <Grid item xs={12} container direction="column">
              <Grid item xs={12} container direction="row" spacing={2}>
                <Grid item xs={6}>
                  {isLoading ? (
                    <Skeleton variant="rect" width={98} height={20} />
                  ) : (
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label="Tasa mora (%)"
                      type="number"
                      inputProps={{ min: minRate, step: 0.0001, max: maxRate }}
                      id={`debtRate-${index}`}
                      name={`debtRate-${index}`}
                      aria-describedby="debtRate"
                      value={rate.rate}
                      onChange={e => handleSetDevengoRate(e, index, 'rate')}
                      error={!isValidRow}
                      disabled={disabled}
                    />
                  )}
                </Grid>
                <Grid item xs={5}>
                  {isLoading ? (
                    <Skeleton variant="rect" width={98} height={20} />
                  ) : (
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label="Días inicio mora"
                      type="number"
                      InputProps={{ inputProps: { min: 0, step: 1 } }}
                      id={`debtDays-${index}`}
                      name={`debtDays-${index}`}
                      aria-describedby="debtDays"
                      value={rate.days}
                      onChange={e => handleSetDevengoRate(e, index, 'days')}
                      error={!isValidRow}
                      disabled={disabled}
                    />
                  )}
                </Grid>
                <Grid item xs={1}>
                  {index > 0 && (
                    <IconButton
                      onClick={() => {
                        handleDeleteDevengoRate(rate);
                      }}
                      className={classes.buttonDelete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              {!isValidRow && (
                <Grid item xs={12} className={classes.errorLabel}>
                  <FormHelperText id="component-error-text" error>
                    Ambos datos son obligatorios
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
      {!disabled && (
        <Grid item xs={6} className={classes.inputContainer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            fullWidth
            onClick={handleAddDevengoRate}
            disabled={disableAddDevengoRate || isLoading}
            startIcon={<AddRoundedIcon />}
          >
            Agregar tasa mora
          </Button>
        </Grid>
      )}
    </>
  );
};

RatesForm.defaultProps = {
  validationListener: () => {},
  isLoading: false,
  rates: [],
  disabled: false,
  handleChangeRates: () => {},
};

RatesForm.propTypes = {
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      days: PropTypes.number,
      rate: PropTypes.number,
    })
  ),
  handleChangeRates: PropTypes.func,
  validationListener: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RatesForm;
