import { useQuery } from 'react-query';
import { Pagination } from '../../../commons/interfaces/global';
import {
  GetManagementsFilters,
  ManagementsFetchedData,
} from '../../../commons/interfaces/managements';
import { fetchManagements as fetchManagementsService } from '../services/fetchManagements';

type fetchManagementsProps = {
  debtsIds?: number[];
  identifiers?: string[];
  order: string;
  filter?: GetManagementsFilters;
  pagination: Pagination;
  useQueryId?: string;
};
const useFetchManagements = (props: fetchManagementsProps) => {
  const { isLoading, data, refetch, status, error, isError, isFetching } =
    useQuery<ManagementsFetchedData>(
      props.useQueryId || 'getManagements',
      async () => fetchManagementsService(props),
      {
        enabled: !!props.debtsIds,
        keepPreviousData: true,
      }
    );
  return {
    data: data || ([] as unknown as ManagementsFetchedData),
    isLoading: isLoading || isFetching,
    refetch,
    status,
    error,
    isError,
  };
};

export default useFetchManagements;
