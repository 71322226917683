import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const uploadBulkDiscountsService = async ({ formData, orderId }) => {
  try {
    return await axios.post(
      `/api/payment/order/${orderId}/bulkdiscount`,
      formData
    );
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { uploadBulkDiscountsService };
