
//RISK STATE ID
export const objectionRiskId = 'b19fe325-9ed1-4229-893a-d34bddcbbe95';
export const ldcRiskId= 'd3950f69-291a-4e94-8e2b-4bf73dc736f1';

//LEGAL STATE ID
export const declinedDocLegalId = '9310b502-f475-4910-bf23-d82e9ec2324b';
export const requestMoreDocsLegalId = 'babfb5c9-05fa-4ea1-8d7c-479b78ce35a3';

//OPERATIONS STATE ID
export const declinedDocOperationsId = '12d3932d-be5e-4e05-9e83-2754156494d0';
export const requestMoreDocsOperationsId = 'c7b2e6aa-6b93-441a-9724-c62506cd6677';
