import axios from 'axios';
import { COUNTRY_CODE_CL } from '../helpers/Constants';
import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  IMPERSONATE_USERS_START,
  IMPERSONATE_USERS_SUCCESS,
  EDIT_USER_START,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  FETCH_PERFORM_START,
  FETCH_PERFORM_SUCCESS,
  FETCH_PERFORM_FAILURE,
  REGISTER_OR_UPDATE_ROLE_START,
  REGISTER_OR_UPDATE_ROLE_SUCCESS,
  REGISTER_OR_UPDATE_ROLE_FAILURE,
  DISABLE_ROLE_START,
  DISABLE_ROLE_SUCCESS,
  DISABLE_ROLE_FAILURE,
  SELECT_ROLE,
  RESET_USER_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { settings } from '../config/settings';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

const {
  REACT_APP_CLIENT_URL,
  REACT_APP_CLIENT_GLOBAL_URL,
  REACT_APP_CLIENT_GLOBAL_CL_URL,
} = process.env;

export const fetchUsers =
  ({ searchInput, field, limit, page }) =>
  dispatch => {
    dispatch({ type: FETCH_USERS_START });
    axios
      .get(`/api/user/all`, {
        params: {
          searchInput,
          field,
          limit,
          page,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_USERS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_USERS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const registerUser = user => dispatch => {
  dispatch({ type: REGISTER_USER_START });
  axios
    .post(`/api/user/register`, user)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.USER_CREATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_USER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateUser = user => dispatch => {
  dispatch({ type: EDIT_USER_START });
  axios
    .put(`/api/user/${user.id}`, user)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.USER_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: EDIT_USER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const impersonate = (userId, country) => dispatch => {
  dispatch({ type: IMPERSONATE_USERS_START });
  axios
    .post('/api/user/impersonate', { id: userId })
    .then(res => {
      const resData = res.data;
      dispatch({ type: IMPERSONATE_USERS_SUCCESS, payload: resData });
      let url = REACT_APP_CLIENT_GLOBAL_URL;

      if (settings[country].isApiGlobal && country === COUNTRY_CODE_CL) {
        url = REACT_APP_CLIENT_GLOBAL_CL_URL;
      }

      if (!settings[country].isApiGlobal && country === COUNTRY_CODE_CL) {
        url = REACT_APP_CLIENT_URL;
      }

      window.open(`${url}/impersonate/${resData.fileName}`, '_blank');
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.IMPERSONATE_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchPerforms = () => dispatch => {
  dispatch({ type: FETCH_PERFORM_START });
  axios
    .get('/api/user/performs')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_PERFORM_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_PERFORM_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_PERFORM_FAILURE,
        payload: { errorCode },
      });
    });
};

export const registerOrUpdateRole = roleData => dispatch => {
  dispatch({ type: REGISTER_OR_UPDATE_ROLE_START });
  axios
    .post('/api/user/role', roleData)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ROLE_CREATED_OR_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({ type: REGISTER_OR_UPDATE_ROLE_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_OR_UPDATE_ROLE_FAILURE,
        payload: { errorCode },
      });
    });
};

export const deleteRole = id => dispatch => {
  dispatch({ type: DISABLE_ROLE_START });
  axios
    .put(`/api/user/role/${id}`, { active: false })
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ROLE_DELETED, {
          variant: 'success',
        })
      );
      dispatch({ type: DISABLE_ROLE_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: DISABLE_ROLE_FAILURE, payload: { errorCode } });
    });
};

export const editUser = (updatedUser, userId) => dispatch => {
  dispatch({ type: UPDATE_USER_START });

  axios
    .put(`/api/user/${userId}/business`, updatedUser)
    .then(({ data }) => {
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.USER_UPDATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: UPDATE_USER_FAILURE, payload: { errorCode } });
    });
};

export const selectRole = role => dispatch => {
  dispatch({ type: SELECT_ROLE, payload: { role } });
};

export const resetUserError = role => dispatch => {
  dispatch({ type: RESET_USER_ERROR, payload: { role } });
};
