import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  containerSummary: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto auto 20px',
    maxWidth: '330px',
  },
  itemBox: {
    marginBottom: '10px',
  },
  itemRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sendButton: {
    marginBottom: '15px',
    maxWidth: '285px',
  },
  spinner: {
    marginRight: '15px',
  },
  xlsxDownloadLink: {
    color: '#1A49E7',
    fontSize: 13,
    lineHeight: '150%',
    textDecoration: 'underline',
    marginTop: 15,
    '& > svg': {
      marginBottom: -2,
      marginRight: 4,
    },
    '&.disabled': {
      color: '#C1BDCE',
      userSelect: 'none',
      cursor: 'not-allowed',
      '& > svg path': {
        fill: '#C1BDCE',
      },
    },
  },
}));
