export const getPayrollSelector = ({ fundOpportunities: { payroll } }) =>
  payroll?.payrollSelector;

export const getDraftReceivers = ({ fundOpportunities: { payroll } }) => {
  if (!payroll?.payrollSelector) {
    return [];
  }

  return Object.entries(payroll?.payrollSelector).map(([key, value]) => ({
    receiverIdentifier: key,
    documentsNumber: value.value?.length,
    ...value,
  }));
};

export const getDraftPayrollSelector = ({ fundOpportunities: { payroll } }) =>
  payroll?.draftPayrollSelector;

export const getSelectedFund = ({ fundOpportunities: { payroll } }) =>
  payroll?.selectedFund;

export const getSimulation = ({ fundOpportunities: { payroll } }) =>
  payroll?.simulation;
