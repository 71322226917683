import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const downloadAECService = async invoices => {
  try {
    return await axios.post(
      '/api/cession/aec',
      { invoices },
      { responseType: 'blob' }
    );
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default downloadAECService;
