import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  infoDateButton: { color: theme.palette.info.main },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconParallelBar: {
    marginRight: 11,
  },
  pymeTypography: {
    marginBottom: 15,
  },
  fundTypography: {
    marginTop: 15,
    marginBottom: 15,
  },
  alertForm: {
    marginTop: 15,
  },
}));

export default styles;
