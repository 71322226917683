import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, styled } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { CloudDownload } from '@material-ui/icons';
import Dropzone from '../../../../components/elements/Dropzone/Dropzone';
import BaseDialog from '../../../../components/dialogs/BaseDialog';
import { Button, Select } from '../../../commons/components';
import { usePayrollHeader } from '../../hooks';

const SAVING = false;
const CSV_TEMPLATE = [];

const BulkInvoicesDiscountDialog = ({ open, handleClose }) => {
  const [reason, setReason] = useState('');
  const { invoiceDiscountReasons } = usePayrollHeader();

  const DropzoneGrid = styled(Grid)({
    marginTop: 24,
  });

  // const AlertGrid = styled(Grid)({
  //   marginTop: 24,
  // });

  const TemplateGrid = styled(Grid)({
    marginTop: 30,
  });

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      size="md"
      title="Cargar descuento a facturas"
      description="Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)"
    >
      <Grid container direction="column">
        <Grid item xs={12}>
          <Select
            options={invoiceDiscountReasons}
            name="reason"
            label="Razón del descuento"
            value={reason}
            onChange={value => setReason(value)}
          />
        </Grid>
        {/* {errorsCsv?.length > 0 && (
          <AlertGrid item xs={12}>
            <AlertMessage
              onClear={resetDataAndErrors}
              messageComponent={alertMessageComponent}
            />
          </AlertGrid>
        )} */}
        <DropzoneGrid item xs={12}>
          {SAVING ? (
            <CircularProgress color="primary" size={60} />
          ) : (
            <Dropzone
              handleSubmit={() => undefined}
              maxFiles={1}
              accept=".csv"
              buttonText="Subir archivo"
              isLoading={SAVING}
            />
          )}
        </DropzoneGrid>
        <TemplateGrid item container justifyContent="center">
          <Button
            startIcon={<CloudDownload color="primary" />}
            color="primary"
            disabled={SAVING}
            style={{ border: 'none' }}
          >
            <CSVLink
              data={CSV_TEMPLATE}
              separator=","
              filename="data_template"
              asyncOnClick
              onClick={(event, done) => {
                done();
              }}
            >
              Descargar planilla
            </CSVLink>
          </Button>
        </TemplateGrid>
      </Grid>
    </BaseDialog>
  );
};

BulkInvoicesDiscountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BulkInvoicesDiscountDialog;
