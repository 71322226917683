import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { enqueueSnackbar } from '../../actions/notificationAction';
import { checkAuth } from '../../helpers/validation/auth';
import { t13s } from '../../translationKeys';
import { setAuthToken } from '../../helpers/axiosUtils';
import { fetchRules } from '../../actions/authentication';

export default function (ComposedComponent, performComponent) {
  const Authentication = () => {
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { authState } = useOktaAuth();

    const auth = useSelector(state => state.auth);
    const { rules, roles } = auth;

    const redirectTo = path => {
      history.push(path);
    };

    useEffect(() => {
      if (!authState.isAuthenticated) {
        const redirectUrl = `/login?redirect_to=${history.location.pathname}${history.location.hash}`;
        redirectTo(redirectUrl);
        window.location.reload();
      } else if (performComponent) {
        const isAuth = checkAuth(roles, performComponent, rules);
        if (!isAuth) {
          redirectTo('/orders');
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.ACCESS_DENIED, {
              variant: 'warning',
            })
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);

    useEffect(() => {
      if (authState.isAuthenticated) {
        const token = authState.accessToken.accessToken;
        setAuthToken(token);
        dispatch(fetchRules({}));
        setReady(true);
      }
    }, [authState]);

    return ready ? <ComposedComponent /> : null;
  };

  return Authentication;
}
