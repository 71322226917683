import React, { useEffect, useState } from 'react';
import NotificationsNone from '@material-ui/icons/NotificationsNone';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import NotificationsDrawer from '../../../drawers/NotificationsDrawer';
import useNotifications from '../hooks/useNotifications';
import { NotificationStatus } from '../interfaces';

const useStyles = makeStyles({
  container: {
    marginLeft: '1em',
    marginRight: '2em',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      scale: 1.1,
    },
  },
  notificationIcon: {
    color: '#fff',
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
  notificationBadge: {
    backgroundColor: '#E0584F',
    height: 10,
    width: 10,
    borderRadius: '50%',
    position: 'relative',
    top: -18,
    left: 32,
    display: 'inline-block',
    border: '1px solid #1A85E7',
  },
});

const BellNotification = () => {
  const classes = useStyles();

  const [showNotificationsDrawer, setShowNotificationsDrawer] =
    useState<boolean>(false);

  const {
    bellStatus,
    getUserNotificationsIsLoading,
    getUserNotifications,
    updateBellStatus,
  } = useNotifications();

  useEffect(() => {
    getUserNotifications();
  }, []);

  useEffect(() => {
    if (showNotificationsDrawer && bellStatus === NotificationStatus.New) {
      updateBellStatus();
    }
  }, [showNotificationsDrawer]);

  return (
    <Grid className={classes.container}>
      {!getUserNotificationsIsLoading &&
        bellStatus &&
        bellStatus === NotificationStatus.New && (
          <Box className={classes.notificationBadge} />
        )}

      <NotificationsNone
        className={classes.notificationIcon}
        onClick={() => setShowNotificationsDrawer(true)}
      />

      {showNotificationsDrawer && (
        <NotificationsDrawer
          isOpen={showNotificationsDrawer}
          handleCloseDrawer={() => setShowNotificationsDrawer(false)}
        />
      )}
    </Grid>
  );
};

export default BellNotification;
