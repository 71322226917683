import React, { FC } from 'react';
import { styled, Button, ButtonProps } from '@material-ui/core';
import { palette } from '../../../../theme/palette';

const CustomButton = styled(Button)({
  border: `1px solid ${palette.primary.main}`,
  color: palette.primary.main,
  fontSize: 14,
  padding: '10px 24px',
  fontWeight: 700,
});

interface Props extends ButtonProps {}

const SelectedOperationsButton: FC<Props> = ({ children, ...rest }) => {
  return <CustomButton {...rest}>{children}</CustomButton>;
};

export default SelectedOperationsButton;
