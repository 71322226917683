import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchUsersService = async () => {
  try {
    const { data } = await axios.get('/api/user/all/simple');
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchUsersService };
