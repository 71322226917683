import axios from 'axios';
import {
  FETCH_STAKEHOLDERS_FAILURE,
  FETCH_STAKEHOLDERS_START,
  FETCH_STAKEHOLDERS_SUCCESS,
  UPDATE_STAKEHOLDER_START,
  UPDATE_STAKEHOLDER_SUCCESS,
  UPDATE_STAKEHOLDER_FAILURE,
  DELETE_STAKEHOLDER_START,
  DELETE_STAKEHOLDER_SUCCESS,
  DELETE_STAKEHOLDER_FAILURE,
  CREATE_STAKEHOLDER_START,
  CREATE_STAKEHOLDER_SUCCESS,
  CREATE_STAKEHOLDER_FAILURE,
  RESET_STAKEHOLDER_ERRORS,
} from './types';
import { resetDocumentsVerify } from './documentActions';
import { enqueueSnackbar } from './notificationAction';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys';

const entityName = 'stakeholder';

export const fetchStakeholders = businessId => dispatch => {
  dispatch({ type: FETCH_STAKEHOLDERS_START, payload: { businessId } });
  dispatch(resetDocumentsVerify(entityName));
  axios
    .get(`/api/business/${businessId}/stakeholders`)
    .then(res => {
      const stakeholders = res.data;
      dispatch({
        type: FETCH_STAKEHOLDERS_SUCCESS,
        payload: { stakeholders },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_STAKEHOLDERS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_STAKEHOLDERS_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateStakeholder = stakeholder => dispatch => {
  dispatch({ type: UPDATE_STAKEHOLDER_START });
  const { stakeholderId, businessId, ...rest } = stakeholder;
  axios
    .put(`/api/business/${businessId}/stakeholders/${stakeholderId}`, {
      ...rest,
    })
    .then(({ data: updateStakeholder }) => {
      dispatch({
        type: UPDATE_STAKEHOLDER_SUCCESS,
        payload: { updateStakeholder },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.STAKEHOLDER_UPDATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPDATE_STAKEHOLDER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const deleteStakeholder = stakeholder => dispatch => {
  dispatch({ type: DELETE_STAKEHOLDER_START });
  const { stakeholderId, businessId } = stakeholder;
  axios
    .delete(`/api/business/${businessId}/stakeholders/${stakeholderId}`)
    .then(() => {
      dispatch({
        type: DELETE_STAKEHOLDER_SUCCESS,
        payload: { stakeholderId },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.STAKEHOLDER_DELETED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: DELETE_STAKEHOLDER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const createStakeholder = (businessId, stakeholder) => dispatch => {
  dispatch({ type: CREATE_STAKEHOLDER_START });
  axios
    .post(`/api/business/${businessId}/stakeholders`, stakeholder)
    .then(res => {
      dispatch({
        type: CREATE_STAKEHOLDER_SUCCESS,
        payload: { stakeholder: res.data },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.STAKEHOLDER_CREATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: CREATE_STAKEHOLDER_FAILURE, payload: { errorCode } });
    });
};

export const resetStakeholderErrors = () => dispatch => {
  dispatch({ type: RESET_STAKEHOLDER_ERRORS });
};
