import {
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistoryLogs } from '../../actions/logsActions';
import {
  ORDER_TYPES_LABEL,
  LOG_STATUS,
  STATUSES_VERIFIED,
  BLOCKLIST_REASONS,
  BLOCKLIST_REASONS_CL,
  DISCOUNTS,
} from '../../helpers/Constants';
import { dateFormatter, datetimeFormatter } from '../../helpers/DateUtils';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import {
  CHANGES_DETAILS_GLOBAL,
  LOGS_MESSAGES,
  SUBCONTEXT_WITH_LABELS,
} from '../../helpers/userLogsUtils';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import PanelResumenInformation from '../elements/PanelResumenInformation';
import BaseDialog from './BaseDialog';
import useStatuses from '../../hooks/useStatuses';

const useStyles = makeStyles(theme => ({
  headerTabsTab: {
    minWidth: '69px',
    textTransform: 'none',
  },
  root: {
    margin: convertSpacingToCss('0 -xl -xl'),
  },
  copyButton: {
    margin: '20px 20px 20px 30px',
  },
  panels: {
    padding: '0px 20px 20px 20px',
  },
  tabScore: {
    background: theme.palette.grey[100],
  },
  breakline: {
    display: 'block',
  },
}));

const HistoryLogsDialog = ({
  open,
  handleCloseDialog,
  contextId,
  contextType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getStatuses } = useStatuses();

  const [stateTab, setStateTab] = useState('changes');
  const [scoreVariablesData, setScoreVariablesData] = useState([]);

  const { historyLogs: arrayLogs, historyLogsIsLoading } = useSelector(
    state => state.logs
  );
  const { country } = useSelector(state => state.config);

  const scoreVars = [
    { label: 'Pyme', type: 'pyme' },
    { label: 'Pagador', type: 'payer' },
    { label: 'Factura', type: 'invoice' },
    { label: 'Relación', type: 'relationship' },
  ];

  useEffect(() => {
    dispatch(fetchHistoryLogs(contextId, contextType));
  }, [contextId, contextType, dispatch]);

  useEffect(() => {
    const scoreLog = arrayLogs.filter(
      change => change?.data?.custom?.scoreVariables
    );
    const lastScoreVariables = scoreLog.sort(
      (a, b) => Date.parse(b.actionDate) - Date.parse(a.actionDate)
    );
    const scoreVariables = lastScoreVariables.map(
      score => score.data?.custom?.scoreVariables
    );

    setScoreVariablesData(scoreVariables);
  }, [arrayLogs]);

  const translateStates = (status, type = 'ORDER') => {
    const factura = getStatuses('factura');
    const order = getStatuses('order');
    const logStatuses = LOG_STATUS;
    let newOrder = [...order];

    let statusFound = '-';
    if (type === 'ORDER') {
      if (status === 'BLOCKED') {
        newOrder = [...order, ...logStatuses];
      }
      statusFound = newOrder.find(element => element.key === status);
    }

    if (type === 'ORDER_FACTURAS') {
      statusFound = factura.find(element => element.key === status);
    }
    return statusFound?.label || '-';
  };

  const transformInfoStatusVerified = (status, type = 'ORDER_FACTURAS') => {
    let statusFound = 'Sin verificar';
    if (type === 'ORDER_FACTURAS') {
      statusFound = STATUSES_VERIFIED.find(element => element.key === status);
    }
    // Si entrega un null es un "Sin verificar", la bd no existe sin verificar, solo null
    return statusFound?.label || 'Sin verificar';
  };

  const transformInfoAction = rowData => {
    const {
      action,
      data: { changed },
      contextType,
      subContextType,
    } = rowData;
    const {
      CREATE: actionCreate,
      UPDATE: actionUpdate,
      DESTROY: actionDestroy,
    } = LOGS_MESSAGES;
    let data = '-';

    if (action === 'CREATE') {
      data = (
        <Typography color="textPrimary" variant="body2">
          {actionCreate[subContextType ?? contextType]}
        </Typography>
      );
    } else if (action === 'UPDATE' && changed) {
      const keysChanged = Object.keys(changed);
      if (!keysChanged) return data;
      const buildMessageActions = keysChanged?.map(element => {
        const elementFound = subContextType
          ? actionUpdate?.[subContextType]?.[element]
          : actionUpdate[element];
        if (!elementFound) return null;
        return (
          <Typography color="textPrimary" variant="body2" key={elementFound}>
            {elementFound}
          </Typography>
        );
      });

      data = buildMessageActions.length ? buildMessageActions : '-';
    } else if (action === 'DESTROY') {
      data = (
        <Typography color="textPrimary" variant="body2">
          {actionDestroy[subContextType ?? contextType]}
        </Typography>
      );
    }

    return data;
  };

  const getEventValue = event => {
    switch (Number(event)) {
      case 0:
        return 'Cedida';
      case 1:
        return 'Reclamada';
      case 2:
        return 'Acuse Recibo';
      case 3:
        return 'Contado/Gratuito';
      case 7:
        return 'Anulada';
      default:
        return '';
    }
  };

  const getEventPublisherValue = event => {
    switch (Number(event)) {
      case 1:
        return 'Contado/Gratuito';
      case 2:
        return 'Reclamada';
      case 3:
        return 'Cedida';
      case 4:
        return 'Anulada';
      default:
        return '';
    }
  };

  const transformDate = infoDate => {
    return infoDate ? datetimeFormatter(infoDate) : '-';
  };

  const translateReasons = (context, value) => {
    let reasonStr = '';
    switch (context) {
      case 'DISCOUNT':
        reasonStr =
          DISCOUNTS.find(
            r =>
              r.type === 'BASE_DISCOUNT' &&
              r.country.includes(country) &&
              r.value === value
          )?.label ?? '-';
        break;
      case 'PAYER_BLOCKLIST':
        reasonStr =
          [...BLOCKLIST_REASONS, ...BLOCKLIST_REASONS_CL].find(
            r => r.value === value
          )?.label ?? '-';
        break;
      default:
        break;
    }
    return reasonStr;
  };

  const userLogFunctions = (
    name,
    elementValue,
    contextType,
    subContextType
  ) => {
    const arrayFunction = {
      transformDate: transformDate(elementValue),
      translateStates: translateStates(elementValue, contextType),
      transformInfoStatusVerified: transformInfoStatusVerified(elementValue),
      type: ORDER_TYPES_LABEL[elementValue],
      getEventValue: getEventValue(elementValue),
      getEventPublisherValue: getEventPublisherValue(elementValue),
      translateReasons: translateReasons(
        subContextType ?? contextType,
        elementValue
      ),
      validNumber: typeof elementValue === 'number' ? elementValue : '-',
    };
    return arrayFunction[name];
  };

  const transformInfoStatesInvoices = (rowData, beforeOrAfter) => {
    const { data, contextType, subContextType, action } = rowData;
    if (subContextType !== 'ORDER_DETAILS') return '-';
    const { changed } = data;
    const dataBeforeAfter = data[beforeOrAfter];
    if (!changed) return '-';
    if (contextType === 'AGREEMENT' && subContextType === 'AGREEMENT_RATE') {
      const { rate, days } = dataBeforeAfter;
      if (
        (action === 'CREATE' && beforeOrAfter === 'before') ||
        (action === 'DESTROY' && beforeOrAfter === 'after')
      ) {
        return '-';
      }
      delete changed.id;
      return (
        <>
          <Typography color="textPrimary" variant="body2">
            Tasa:&nbsp;
            <CountryFormatHelper
              value={rate || '-'}
              countryId={country}
              variant="percentage"
            />
          </Typography>
          <Typography color="textPrimary" variant="body2">
            Día(s): {days}
          </Typography>
        </>
      );
    }
    const finalData = Object.keys(changed).map(element => {
      let value = '';
      let formated;
      const elementValue = dataBeforeAfter[element];
      const detailType = CHANGES_DETAILS_GLOBAL.find(({ key }) =>
        typeof key == 'string' ? key === element : key.includes(element)
      );
      if (elementValue !== null && elementValue !== undefined && detailType) {
        if (SUBCONTEXT_WITH_LABELS.find(e => e === subContextType)) {
          value = `- ${detailType.label}`;
        }
        switch (detailType.type) {
          case 'text':
            value = `- ${detailType.label} ${elementValue}
            `;
            break;
          case 'bool':
            value += ` ${
              elementValue || elementValue === 1 ? 'Activo' : 'Inactivo'
            }
            `;
            if (
              ['AGREEMENT_ACTIVATED', 'AGREEMENT_PAUSED'].includes(
                subContextType
              )
            ) {
              value = '-';
            }
            break;
          case 'sino': {
            if (elementValue !== null && elementValue !== undefined) {
              value = elementValue ? 'Si' : 'No';
            }
            break;
          }
          case 'percentage':
          case 'currency': {
            formated = (
              <CountryFormatHelper
                value={elementValue}
                countryId={country}
                variant={detailType.type}
              />
            );
            break;
          }
          case 'stringArray': {
            value =
              typeof elementValue === 'string'
                ? elementValue.split(',')
                : elementValue;
            return (
              <>
                {detailType.helpHeader && (
                  <Typography
                    color="textPrimary"
                    variant="body2"
                    className={classes.breakline}
                  >
                    {detailType.helpHeader}
                  </Typography>
                )}
                {value.map(elem => (
                  <Typography
                    key={elem}
                    color="textPrimary"
                    variant="body2"
                    className={classes.breakline}
                  >
                    {elem}
                  </Typography>
                ))}
              </>
            );
          }
          default: {
            formated = ` ${userLogFunctions(
              detailType.type,
              elementValue,
              contextType,
              subContextType
            )}`;
            break;
          }
        }
      } else {
        value = '';
      }

      return (
        <Typography key={element} color="textPrimary" variant="body2">
          {value} {formated || null}
        </Typography>
      );
    });
    return finalData.length ? finalData : '-';
  };

  const buildColumnsTableHistoryLogs = () => {
    return [
      {
        title: 'Nombre',
        field: 'firstName',
        type: 'string',
        width: '130px',
        render: ({
          user: { firstName = 'Sistema', lastName = '', name = null },
        }) => (
          <Typography color="textPrimary" variant="body2">
            {`${name || firstName} ${lastName}`}
          </Typography>
        ),
      },
      {
        title: 'Fecha',
        field: 'actionDate',
        type: 'date',
        width: '150px',
        defaultSort: 'asc',
        render: ({ actionDate }) => (
          <Typography color="textPrimary" variant="body2">
            {transformDate(actionDate)}
          </Typography>
        ),
      },
      {
        title: 'Cambio',
        field: 'action',
        type: 'string',
        width: '130px',
        render: transformInfoAction,
      },
      {
        title: 'Estado Anterior',
        field: 'status',
        type: 'string',
        render: rowData => transformInfoStatesInvoices(rowData, 'before'),
      },
      {
        title: 'Estado Nuevo',
        field: 'status',
        type: 'string',
        render: rowData => transformInfoStatesInvoices(rowData, 'after'),
      },
    ];
  };

  const getScoreInfo = type => {
    const [{ pymeData, payerData, invoiceData, relationshipData } = {}] =
      scoreVariablesData;

    switch (type) {
      case 'pyme':
        return [
          {
            title: 'VentasPromedioPyme',
            content: `${pymeData?.monthlyAverageSalesInCLP}`,
          },
          {
            title: 'AñosActivo',
            content: `${pymeData?.activeYears}`,
          },
          {
            title: 'DicomPyme',
            content: `${pymeData?.pymeDicom || ''}`,
          },
          {
            title: 'TuvoPP',
            content: `${pymeData?.operationsPP}`,
          },
          {
            title: 'DeudaTGRPorcentaje',
            content: `${pymeData?.tgr?.debt}`,
          },
          {
            title: 'DeudaTGRConvenio',
            content: `${pymeData?.tgr?.pAgreement}`,
          },
          {
            title: 'DeudaTGRCuotas',
            content: `${pymeData?.tgr?.unpaidFees}`,
          },
        ];
      case 'payer':
        return [
          {
            title: 'RankingPagador',
            content: `${payerData?.payerRanking}`,
          },
          {
            title: 'ConvenioPP',
            content: `${payerData?.hasPayerAnActivePPAgreement}`,
          },
          {
            title: 'VentasPagador',
            content: `${payerData?.salesPayer}`,
          },
          {
            title: 'DicomPagador',
            content: `${payerData?.payerDicom || ''}`,
          },
        ];
      case 'invoice':
        return [
          {
            title: 'DiasEmision',
            content: `${invoiceData?.daysFromIssue}`,
          },
          {
            title: 'MontoFactura',
            content: `${invoiceData?.totalAmount}`,
          },
          {
            title: 'FechaEmision',
            content: invoiceData.issueDate
              ? dateFormatter(invoiceData?.issueDate)
              : '',
          },
          {
            title: 'Merito',
            content: `${invoiceData?.hasTheInvoiceMerit}`,
          },
          {
            title: 'MontoSobreVentas',
            content: `${invoiceData?.percentageOfAmountOverMonthlySales}`,
          },
        ];
      case 'relationship':
        return [
          {
            title: 'DiasRelacion',
            content: `${relationshipData?.daysOfRelationshipBetweenThePYMEAndThePayer}`,
          },
          {
            title: 'NCRelacion',
            content: `${relationshipData?.percentageOfCreditNotesOnAverageMonthlySales}`,
          },
          {
            title: 'CesionesRelacion',
            content: `${relationshipData?.percentageOfCessions}`,
          },
          {
            title: 'VentasPromedioRelacion',
            content: `${relationshipData?.averageMonthlySalesRatio}`,
          },
          {
            title: 'Recurrencia',
            content: `${relationshipData?.recurrence}`,
          },
          {
            title: 'NoPrimeraOperacion',
            content: `${relationshipData?.notFirstOperation}`,
          },
          {
            title: 'TendenciaVentas',
            content: `${relationshipData?.salesTrend}`,
          },
        ];
      default:
        return [];
    }
  };

  return (
    <BaseDialog
      title="Historial de cambios"
      isOpen={open}
      handleClose={handleCloseDialog}
      size="xl"
    >
      {scoreVariablesData.length > 0 && (
        <Grid item xs={12}>
          <Tabs
            value={stateTab}
            TabIndicatorProps={{
              style: {
                height: '3px',
                borderRadius: '10px',
              },
            }}
            onChange={(e, valueTab) => setStateTab(valueTab)}
          >
            <Tab
              label="Cambios"
              value="changes"
              key="changes"
              className={classes.headerTabsTab}
            />
            <Tab
              label="Variables de riesgo"
              value="score"
              key="score"
              className={classes.headerTabsTab}
            />
          </Tabs>
        </Grid>
      )}
      <Grid item className={classes.root}>
        <Grid
          role="tabpanel"
          hidden={stateTab !== 'changes'}
          id={`wrapped-tabpanel-${stateTab}`}
          aria-labelledby={`wrapped-tab-${stateTab}`}
          item
        >
          <MaterialTable
            columns={buildColumnsTableHistoryLogs()}
            data={arrayLogs}
            isLoading={historyLogsIsLoading}
            components={{
              Container: props => (
                <Paper
                  elevation={0}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              ),
            }}
            options={{
              detailPanelType: 'single',
              pageSize: 5,
              pageSizeOptions: [5, 10, 15],
              emptyRowsWhenPaging: false,
              toolbar: false,
              maxBodyHeight: 400,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No se tienen registros para mostrar',
              },
              pagination: {
                labelRowsSelect: 'Registros',
                labelDisplayedRows: '{count} registros totaltes',
                firstTooltip: 'Primera pagina',
                previousTooltip: 'Pagina anterior',
                nextTooltip: 'Siguiente pagina',
                lastTooltip: 'Ultima pagina',
              },
            }}
          />
        </Grid>
        {scoreVariablesData.length > 0 && (
          <Grid
            role="tabpanel"
            hidden={stateTab !== 'score'}
            id={`wrapped-tabpanel-${stateTab}`}
            aria-labelledby={`wrapped-tab-${stateTab}`}
            item
            className={classes.tabScore}
          >
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => copy(JSON.stringify(scoreVariablesData[0]))}
                color="primary"
                variant="text"
                startIcon={<FileCopyIcon />}
                className={classes.copyButton}
              >
                Copiar variables
              </Button>
            </Box>

            <Grid container item xs={12} className={classes.panels}>
              {scoreVars.map(({ label, type }) => (
                <PanelResumenInformation
                  key={type}
                  title={label}
                  informationDetail={getScoreInfo(type)}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </BaseDialog>
  );
};

HistoryLogsDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  contextId: PropTypes.number.isRequired,
  contextType: PropTypes.string.isRequired,
};

export default HistoryLogsDialog;
