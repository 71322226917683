import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { COUNTRY_CODE_CL } from '../../../../../../helpers/Constants';
import { t13s } from '../../../../../../translationKeys';

import useColumns from '../columns/useColumns';

const useTableColumns = () => {
  const { t } = useTranslation();
  const country = useSelector(state => state.config.country);
  const issuerIdentifierTitle = t(t13s.LABEL.BUSINESS_IDENTIFIER);

  const {
    folio,
    attributes,
    issueDate,
    expirationDate,
    amount,
    funds,
    status,
    invoiceDates,
    totalOrdersTransfered,
    issuerName,
    issuerIdentifier,
    xtreeStatus,
  } = useColumns();

  const attributesClProps = country === COUNTRY_CODE_CL && {
    field: 'cessionStatus',
    lookup: {
      SUCCESS: 'Cedidas',
    },
  };

  const columns = [
    folio,
    attributes({ attributesClProps }),
    issuerName,
    issuerIdentifier({ issuerIdentifierTitle }),
    issueDate,
    expirationDate,
    amount,
    funds,
    status,
    xtreeStatus,
    invoiceDates,
  ];

  if (country === COUNTRY_CODE_CL) {
    columns.splice(1, 0, totalOrdersTransfered);
  }

  return columns;
};

export default useTableColumns;
