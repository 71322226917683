import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as Actions from './document.actions';
import * as Selects from './document.selectors';

export const useGetDocumentForm = () =>
  useSelector(Selects.getDocumentForm, shallowEqual);

export const useDocumentActions = () => {
  const dispatch = useDispatch();

  const saveDocumentForm = useCallback(
    form => Actions.saveDocumentForm(form)(dispatch),
    [dispatch]
  );
  const cleanDocumentForm = useCallback(
    () => Actions.cleanDocumentForm()(dispatch),
    [dispatch]
  );

  return {
    cleanDocumentForm,
    saveDocumentForm,
  };
};
