/* eslint-disable no-undef */
import { Drawer, IconButton } from '@material-ui/core';
import React from 'react';
import { Close } from '../../icons';
import {
  useStyles,
  ContainerRoot,
  ContainerHeader,
  ContainerTitle,
  ContainerTitleIcon,
  Title,
  Divider,
} from './styles';

interface Props {
  isOpen: boolean;
  title: string;
  iconTitle?: JSX.Element | JSX.Element[] | React.ReactNode;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  width?: number;
  handleCloseDrawer: () => void;
}

const BaseDrawer = ({
  isOpen,
  title,
  iconTitle,
  children,
  width,
  handleCloseDrawer,
}: Props) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      classes={{
        paper: classes.rootDrawer,
      }}
    >
      <ContainerRoot
        style={{
          width,
        }}
      >
        <ContainerHeader>
          <ContainerTitle>
            {iconTitle && <ContainerTitleIcon>{iconTitle}</ContainerTitleIcon>}
            <Title>{title}</Title>
          </ContainerTitle>
          <IconButton onClick={handleCloseDrawer}>
            <Close />
          </IconButton>
        </ContainerHeader>

        <Divider />

        {children}
      </ContainerRoot>
    </Drawer>
  );
};

export default BaseDrawer;