import React from 'react';
import { Fade } from '@material-ui/core';
import loader from '../../assets/loader.svg';

const Loader = () => {
  return (
    <Fade timeout={400} in>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={loader} alt="loading" />
      </div>
    </Fade>
  );
};

export default Loader;
