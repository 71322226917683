import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  btnContainer: {
    padding: '30px 20px 0',
  },
  containerTextMainAreaBold: {
    marginRight: 20,
  },
  textMainAreaBold: {
    marginRight: 5,
  },
  containerFundPayerInvoicesTable: {
    padding: 20,
    paddingBottom: 100,
  },
  actionBarButton: {
    fontSize: 14,
    marginLeft: 15,
  },
  shoppingCartRow: {
    fontSize: 19,
  },
});
