import * as Types from './document.types';

const initialState = {
  documentForm: {},
};

const documentForm = (state = initialState.documentForm, action) => {
  switch (action.type) {
    case Types.SAVE_DOCUMENT_FORM.SUCCESS.type: {
      return action.payload;
    }
    case Types.CLEAN_DOCUMENT_FORM.SUCCESS.type: {
      return initialState.documentForm;
    }
    default: {
      return state;
    }
  }
};

export const documentReducer = {
  name: 'document',
  reducers: {
    documentForm,
  },
};
