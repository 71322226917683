import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { createComment, resetComments } from '../../../actions/commentsActions';
import { Comment } from '../../commons/interfaces/collection';

const useHomeCollectionBody = () => {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();

  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [showListCommentDrawer, setShowListCommentDrawer] = useState(false);
  const [showResponsiblesDrawer, setShowResponsiblesDrawer] = useState(false);

  const handleShowCommentDialog = (state: boolean) => {
    setShowCommentDialog(state);
    if (!state) {
      dispatch(resetComments());
    }
  };

  const handleShowListCommentDrawer = (state: boolean) => {
    setShowListCommentDrawer(state);
  };

  const handleShowResponsiblesDrawer = (state: boolean) => {
    setShowResponsiblesDrawer(state);
  };

  const handleSubmitCommentDialog = (
    sourceType: string,
    sourceid: number,
    commentData: Comment
  ) => {
    dispatch(
      createComment(sourceType, sourceid, {
        ...commentData,
        username: authState?.idToken?.claims.name || '',
      })
    );
  };

  return {
    showCommentDialog,
    showListCommentDrawer,
    showResponsiblesDrawer,
    setShowCommentDialog,
    setShowListCommentDrawer,
    setShowResponsiblesDrawer,
    handleShowCommentDialog,
    handleShowListCommentDrawer,
    handleShowResponsiblesDrawer,
    handleSubmitCommentDialog,
  };
};

export default useHomeCollectionBody;