import { useCallback, useEffect, useState } from 'react';
import useBulkCreateExtensions from '../infrastructure/store/useBulkCreateExtensions';
import useBulkCreateManagements from '../infrastructure/store/useBulkCreateManagements';
import useBulkCreatePaymentPromises from '../infrastructure/store/useBulkCreatePaymentPromises';

interface Props {
  selectedInvoices: number[];
  setSelectedInvoices: (arg: number[]) => void;
  setSelectedFolios: (arg: string[]) => void;
  allChecked: boolean;
  setAllChecked: (arg: boolean) => void;
}

const useSelectedInvoices = ({
  selectedInvoices,
  setSelectedInvoices,
  setSelectedFolios,
  allChecked,
  setAllChecked,
}: Props) => {
  const [showRenegotiationDrawer, setShowRenegotiationDrawer] = useState(false);
  const [showPromiseDrawer, setShowPromiseDrawer] = useState(false);
  const [showManagementDrawer, setShowManagementDrawer] = useState(false);

  const clearSelectedInvoices = () => {
    if (allChecked) setAllChecked(false);
    setSelectedInvoices([]);
    setSelectedFolios([]);
  };
  const totalSelectedInvoices = selectedInvoices.length;

  const handleClearSelectedInvoices = useCallback(() => {
    clearSelectedInvoices();
  }, [clearSelectedInvoices]);

  const handleShowRenegotiationDrawer = useCallback(() => {
    setShowRenegotiationDrawer(true);
  }, [setShowRenegotiationDrawer]);

  const handleHideRenegotiationDrawer = useCallback(() => {
    setShowRenegotiationDrawer(false);
  }, [setShowRenegotiationDrawer]);

  const handleShowPromiseDrawer = useCallback(() => {
    setShowPromiseDrawer(true);
  }, [setShowPromiseDrawer]);

  const handleHidePromiseDrawer = useCallback(() => {
    setShowPromiseDrawer(false);
  }, [setShowPromiseDrawer]);

  const handleBulkCreateExtensions = (
    type: string,
    expirationDate: Date | undefined,
    feeAmount: number | undefined,
    startDate: Date | undefined,
    baseRate: number | undefined,
    justification: string,
    linkDocuments: string
  ) => {
    createExtensions({
      orderInvoices: selectedInvoices,
      type,
      expirationDate,
      feeAmount,
      startDate,
      baseRate,
      justification,
      linkDocuments,
    });
  };

  const handleBulkCreatePaymentPromises = (
    type: string,
    expirationDate: Date | undefined,
    justification: string) => {
    createPaymentPromises({orderInvoices: selectedInvoices, type, expirationDate, justification})
  };

  const handleBulkCreateManagements = (
    type: string,
    subType: string,
    result: string,
    appliedOn: string,
    justification: string) => {
    createManagements({orderInvoices: selectedInvoices, type, subType, result, appliedOn, justification})
  };

  const onCreateExtensionSuccess = () => {
    handleHideRenegotiationDrawer();
    handleClearSelectedInvoices();
  };

  const onCreatePaymentPromisesSuccess = () => {
    handleHidePromiseDrawer();
    handleClearSelectedInvoices();
  };

  const onCreateManagementsSuccess = () => {
    setShowManagementDrawer(false);
    handleClearSelectedInvoices();
  };

  const { isLoading: bulkCreateExtensionsIsLoading, createExtensions } =
    useBulkCreateExtensions(onCreateExtensionSuccess);

  const { isLoading: bulkCreatePaymentPromisesIsLoading, createPaymentPromises } =
    useBulkCreatePaymentPromises(onCreatePaymentPromisesSuccess);

  const { isLoading: bulkCreateManagementsIsLoading, createManagements } =
  useBulkCreateManagements(onCreateManagementsSuccess);

  return {
    totalSelectedInvoices,
    showRenegotiationDrawer,
    showPromiseDrawer,
    showManagementDrawer,
    bulkCreateExtensionsIsLoading,
    bulkCreatePaymentPromisesIsLoading,
    bulkCreateManagementsIsLoading,
    handleClearSelectedInvoices,
    handleShowRenegotiationDrawer,
    handleHideRenegotiationDrawer,
    handleShowPromiseDrawer,
    handleHidePromiseDrawer,
    setShowManagementDrawer,
    handleBulkCreateExtensions,
    handleBulkCreatePaymentPromises,
    handleBulkCreateManagements,
  };
};

export default useSelectedInvoices;
