import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  rootContainer: {
    padding: 20,
  },
  headerPanel: {
    marginBottom: '15px',
  },
  filterButton: {
    marginLeft: 10,
  },
  leftSection: {
    borderRight: '1px solid #E8E6EF',
  },
  rightSection: {
    display: 'flex',
  },
  filterSelect: {
    minWidth: '200px',
  },
  card: {
    background: '#fff',
    border: '1px solid #E8E6EF',
    borderRadius: '10px',
    padding: 15,
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  linkDetails: {
    display: 'block',
    margin: '10px 0 15px',
    textDecoration: 'underline',
  },
  label: {
    color: '#828795',
    marginBottom: '10px',
  },
  amount: {
    marginBottom: '10px',
  },
  field: {
    '&:not(:first-child)': {
      paddingLeft: '15px',
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #E8E6EF',
      paddingRight: '15px',
    },
  },
  amountBox: {
    padding: '0 15px',
  },
  mbCard: {
    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  mr15: {
    marginRight: '15px',
  },
});
