export * from './GetStateDto';

export type Country = 'CL' | 'MX';

export enum Role {
  adminId = 1,
  commercial = 2,
  risk = 3,
  operation = 4,
  pymesClKamId = 15,
  pymesClLeaderId = 16,
  pymesClManagement = 17,
  corpsClKam = 19,
  corpsClSalesOps = 20,
  corpsClLeader = 21,
  revenueMxKamEnrollment = 22,
  revenueMxKamActivation = 23,
  revenueMxKamRecurrence = 24,
  revenueMxLeader = 25,
  revenueMxManagement = 26,
  riskManager = 27,
  operationManager = 32,
  legal = 33,
  operationMx = 34,
}

export type Icon = 'danger' | 'checked' | 'error';

export type Business = {
  id: string;
  identifier: string;
};

export type User = {
  roles: RoleInput[];
};

export type BusinessSelector = { business?: Business };
export type AuthSelector = { user?: User };

export type RoleInput = {
  id: number;
  rolename: string;
};

export enum TransitionType {
  appealed = 'APPEALED',
  normal = 'NORMAL',
  prontoPagoAllowed = 'PRONTO_PAGO_ALLOWED',
}
