import { Typography, Box, makeStyles, styled } from '@material-ui/core';

const useStyles = makeStyles({
  inputMultiLine: {
    '& fieldset': {
      borderRadius: 17,
    },
  },
  rootDrawer: {
    background: '#fff',
  },
  footerButtons: {
    width: 200,
    height: 40,
    margin: '0 8px',
  },
});

const ContainerRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  width: 814,
});

const ContainerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 10px',
  marginBottom: 10,
});
const ContainerTitle = styled(Box)({
  display: 'flex',
});
const Title = styled(Typography)({
  marginLeft: 7,
  fontSize: 20,
  fontWeight: 'bold',
});

const ContainerBody = styled(Box)({
  margin: '20px 45px 0 45px',
  display: 'flex',
  flexDirection: 'column',
});

const Description = styled(Typography)({
  fontSize: 14,
  marginBottom: 25,
});

const ContainerInformation = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: 'calc(100vh - 336px)',
  marginBottom: 25,
});

const ContainerLeft = styled(Box)({
  width: 345,
});
const ContainerHeaderCollapse = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 15,
});
const ContainerOpsInformation = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});
const ContainerIconCollapse = styled(Box)({
  marginRight: 15,
});
const TextDiscountBold = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 13,
});
const TextCollapse = styled(Typography)({
  fontSize: 14,
});
const ContainerAlertFormTop = styled(Box)({
  marginBottom: 15,
});
const ContainerSelectorInvoices = styled(Box)({
  marginBottom: 15,
});

const ContainerRight = styled(Box)({
  width: 325,
});
const CardPrimary = styled(Box)({
  borderRadius: 16,
  background: '#F5F5F6',
  padding: '24px 24px 14px',
  marginBottom: 25,
});
const CardSecondary = styled(Box)({
  borderRadius: 8,
  padding: '16px 16px 2px',
  marginBottom: 25,
  border: '1px solid #DAD9DB',
});
const CardTertiary = styled(Box)({
  borderRadius: 8,
  padding: '16px 16px 2px',
  border: '1px solid #DAD9DB',
  marginBottom: 10,
});
const TitleCardPrimary = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 20,
  color: '#8D8A96',
  textAlign: 'center',
});
const BodyCardRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 10,
});
const BodyCardRowLabel = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#8D8A96',
});
const BodyCardRowValue = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#312F37',
});
const BodyCardRowValueGreen = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#119B41',
});
const ContainerComment = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
});
const LabelComment = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 5,
});

const ContainerButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 25,
});
const ContainerDeleteButton = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const TextDescriptionDelete = styled(Typography)({
  fontSize: 14,
  color: '#C1BDCE',
  marginBottom: 10,
  textAlign: 'center',
});

export {
  useStyles,
  ContainerRoot,
  ContainerHeader,
  ContainerTitle,
  Title,
  ContainerBody,
  Description,
  ContainerInformation,
  ContainerLeft,
  ContainerHeaderCollapse,
  ContainerOpsInformation,
  ContainerIconCollapse,
  TextDiscountBold,
  TextCollapse,
  ContainerAlertFormTop,
  ContainerSelectorInvoices,
  ContainerRight,
  CardPrimary,
  CardSecondary,
  CardTertiary,
  TitleCardPrimary,
  BodyCardRow,
  BodyCardRowLabel,
  BodyCardRowValue,
  BodyCardRowValueGreen,
  ContainerComment,
  LabelComment,
  ContainerButtons,
  TextDescriptionDelete,
  ContainerDeleteButton,
};
