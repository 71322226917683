import { SET_REMOTE_VERSION } from '../actions/types';

const { REACT_APP_VERSION } = process.env;

const initialState = {
  remoteVersion: REACT_APP_VERSION,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REMOTE_VERSION:
      return { ...state, remoteVersion: payload.remoteVersion };
    default:
      return state;
  }
};
