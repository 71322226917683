import React from 'react';
import { Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StatusFilter from '../../../../../components/elements/StatusFilter';
import ShowSelectedStatuses from '../../../../../components/elements/ShowSelectedStatuses';
import { STATUSES } from '../../../../../helpers/Constants';
import { t13s } from '../../../../../translationKeys/index';
import { selectPriority } from '../../../../commons/utils/constants';
import useStatuses from '../../../../../hooks/useStatuses';
import SearchBar from '../../../../commons/components/SearchBar/SearchBar';

const OPERATION_TYPES_STATUS = 'OPERATION_TYPES_STATUS';

interface Props {
  selectedOperationTypesStatusFilter: string[];
  selectedPriority: string;
  handleChangeSearchInput: (value: { value: string; field: string } | { field: string }) => void;
  handleChangeSelectedPriority: (value: string) => void;
  handleChangeSelectedStatuses: (
    statuses: string[],
    sourceType: string
  ) => void;
  handleDeleteSelectedStatuses: (
    selectedStatus: string,
    source: string
  ) => void;
}

const FiltersSection = ({
  selectedOperationTypesStatusFilter,
  selectedPriority,
  handleChangeSearchInput,
  handleChangeSelectedPriority,
  handleChangeSelectedStatuses,
  handleDeleteSelectedStatuses,
}: Props) => {
  const { t } = useTranslation();
  const { getStatuses } = useStatuses();

  return (
    <Box>
      <Grid container spacing={5} justify="flex-end">
        <Grid item>
          <SearchBar
            placeholder="Buscar..."
            handleChangeInput={value => handleChangeSearchInput(value)}
            selectedOption={{
              title: 'Razón social',
              field: 'payerName',
              type: 'string',
            }}
            fields={[
              {
                title: 'Razón social',
                field: 'payerName',
                type: 'string',
              },
              {
                title: `${t(
                  t13s.LABEL.BUSINESS_IDENTIFIER
                )} Responsable de pago`,
                field: `${t(t13s.LABEL.BUSINESS_IDENTIFIER).toUpperCase()}`,
                type: 'string',
              },
            ]}
          />
        </Grid>

        {false && (
          <Grid item>
            <StatusFilter
              selectedStatuses={selectedOperationTypesStatusFilter}
              onChangeSelection={statuses =>
                handleChangeSelectedStatuses(statuses, OPERATION_TYPES_STATUS)
              }
              statuses={getStatuses('operationTypes')}
              label="Ver por"
              placeholder="Ver por"
              entityType="operationType"
            />
          </Grid>
        )}
        <Grid item>
          <FormControl variant="outlined" fullWidth style={{width: 200}}>
          <InputLabel>Ordenar por</InputLabel>
          <Select
            value={selectedPriority}
            onChange={e => handleChangeSelectedPriority(e.target.value as string)}
            label="Ordenar por"
          >
            {selectPriority.map(option => (
                    <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
                  ))}
          </Select>
        </FormControl>
        </Grid>
        {false && (
          <Grid item>
            <StatusFilter
              selectedStatuses={selectedOperationTypesStatusFilter}
              onChangeSelection={statuses =>
                handleChangeSelectedStatuses(statuses, OPERATION_TYPES_STATUS)
              }
              statuses={getStatuses('operationTypes')}
              label="Filtrar por cobrador"
              placeholder="Filtrar por cobrador"
              entityType="operationType"
            />
          </Grid>
        )}
      </Grid>
      {false && (
        <Grid item xs={12} justify="flex-end">
          <ShowSelectedStatuses
            selectedStatuses={selectedOperationTypesStatusFilter}
            handleDeleteStatus={status =>
              handleDeleteSelectedStatuses(status, OPERATION_TYPES_STATUS)
            }
            entityType="operationType"
          />
        </Grid>
      )}
    </Box>
  );
};

export default FiltersSection;
