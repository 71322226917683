import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { ArrowBack } from '@material-ui/icons';

import { useParams } from 'react-router-dom';
import {
  HalfItem,
  SubtitleBox,
  SubtitleText,
  EditAccountButton,
} from './styles';
import { t13s } from '../../../../../translationKeys';
import { BaseDrawer } from '../../../../commons/components';
import {
  useOrder,
  useFetchBanks,
  useFetchTransferAccounts,
} from '../../../infrastructure/store';
import useEditTransferAccountDrawer from './useEditTransferAccountDrawer';
import {
  ACCOUNT_TYPES,
  COUNTRY_CODE_CL,
} from '../../../../../helpers/Constants';
import { validateIdentifier } from '../../../../../helpers/validation/businessIdentifier';
import useRoles from '../../../../commons/hooks/useRoles';

const EditTransferAccountDrawer = ({
  isOpen,
  selectedBankAccount,
  handleEditBackButton,
  handleCloseEditBankAccountDrawer,
}) => {
  const { t } = useTranslation();
  const { banks } = useFetchBanks();
  const { country } = useSelector(state => state.config);
  const { isAdmin, isTreasurySupervisor } = useRoles();
  const canEditIdentifierAndName = isAdmin || isTreasurySupervisor;
  const { id: orderId } = useParams();
  const {
    order: { businessId },
  } = useOrder(orderId);
  const { refetchTransferAccounts } = useFetchTransferAccounts(orderId);

  const FIELD_REQUIRED_MESSAGE = 'Campo requerido';

  const {
    currencies,
    bankAccountData,
    handleInputChange,
    handleEditTransferAccount,
    isEditingTransferAccounts,
  } = useEditTransferAccountDrawer(
    orderId,
    businessId,
    selectedBankAccount,
    refetchTransferAccounts,
    handleCloseEditBankAccountDrawer
  );

  const handleSubmit = () => {
    const { accountId, accountNumber: originalAccount } = selectedBankAccount;

    handleEditTransferAccount({
      bankAccountData,
      accountId,
      businessId,
      originalAccount,
      orderId,
    });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('validateIdentifier', value => {
      return validateIdentifier(value, country);
    });

    ValidatorForm.addValidationRule('validateAccountNumber', value => {
      if (country === COUNTRY_CODE_CL) return true;
      const regex = /^([0-9]{18})$/;
      return regex.test(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return selectedBankAccount ? (
    <BaseDrawer
      open={isOpen}
      title="Editar cuenta"
      width={458}
      titleButton={<ArrowBack />}
      handleTitleButton={handleEditBackButton}
      handleClose={handleCloseEditBankAccountDrawer}
    >
      <>
        <SubtitleBox display="flex" alignItems="left">
          <SubtitleText>
            Realiza los cambios que sean necesarios. La cuenta deberá estar con
            el {t(t13s.LABEL.BUSINESS_IDENTIFIER)} del proveedor.
          </SubtitleText>
        </SubtitleBox>

        <Grid container direction="column">
          <ValidatorForm onSubmit={handleSubmit}>
            <SelectValidator
              fullWidth
              variant="outlined"
              label="Banco"
              type="text"
              name="bankId"
              value={bankAccountData.bankId || ''}
              onChange={e => handleInputChange(e.target.name, e.target.value)}
              validators={['required']}
              errorMessages={[FIELD_REQUIRED_MESSAGE]}
            >
              {banks?.map(bank => (
                <MenuItem value={bank.id} key={bank.name}>
                  {bank.name}
                </MenuItem>
              ))}
            </SelectValidator>
            <Grid container spacing={2}>
              <HalfItem item>
                <SelectValidator
                  fullWidth
                  variant="outlined"
                  label="Tipo de cuenta"
                  type="text"
                  name="accountType"
                  value={bankAccountData.accountType || ''}
                  onChange={e =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  validators={['required']}
                  errorMessages={[FIELD_REQUIRED_MESSAGE]}
                >
                  {ACCOUNT_TYPES[country].map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </HalfItem>
              <HalfItem item>
                <SelectValidator
                  fullWidth
                  variant="outlined"
                  label="Divisa"
                  type="text"
                  name="currency"
                  value={bankAccountData.currency || ''}
                  onChange={e =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  validators={['required']}
                  errorMessages={[FIELD_REQUIRED_MESSAGE]}
                >
                  {currencies.map(currency => (
                    <MenuItem key={currency.label} value={currency.label}>
                      {currency.label}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </HalfItem>
            </Grid>
            <TextValidator
              fullWidth
              variant="outlined"
              label={t(t13s.LABEL.BANKACCOUNT_NUMBER)}
              type="text"
              name="accountNumber"
              value={bankAccountData.accountNumber || ''}
              onChange={e => handleInputChange(e.target.name, e.target.value)}
              validators={['required', 'validateAccountNumber']}
              errorMessages={[
                FIELD_REQUIRED_MESSAGE,
                t(t13s.INPUT_ERROR.ACCOUNT_NUMBER),
              ]}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Proveedor"
              type="text"
              name="accountHolder"
              value={bankAccountData.accountHolder || ''}
              disabled={!canEditIdentifierAndName}
              onChange={e => handleInputChange(e.target.name, e.target.value)}
              validators={['required']}
              errorMessages={[FIELD_REQUIRED_MESSAGE]}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
              type="text"
              name="sourceIdentifier"
              value={bankAccountData.sourceIdentifier || ''}
              disabled={!canEditIdentifierAndName}
              onChange={e => handleInputChange(e.target.name, e.target.value)}
              validators={['validateIdentifier']}
              errorMessages={[t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER)]}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Correo electrónico"
              type="text"
              name="accountEmail"
              validators={['required']}
              value={bankAccountData.accountEmail || ''}
              onChange={e => handleInputChange(e.target.name, e.target.value)}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Alias (opcional)"
              type="text"
              name="alias"
              value={bankAccountData.alias || ''}
              onChange={e => handleInputChange(e.target.name, e.target.value)}
            />

            <EditAccountButton
              variant="contained"
              color="primary"
              isLoading={isEditingTransferAccounts}
              disabled={isEditingTransferAccounts}
              type="submit"
            >
              Guardar
            </EditAccountButton>
          </ValidatorForm>
        </Grid>
      </>
    </BaseDrawer>
  ) : null;
};

EditTransferAccountDrawer.propTypes = {
  selectedBankAccount: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string,
    accountHolder: PropTypes.string,
    identifier: PropTypes.string,
    amount: PropTypes.number,
    bankName: PropTypes.string,
    countryId: PropTypes.string,
    accountEmail: PropTypes.string,
    alias: PropTypes.string,
    accountType: PropTypes.string,
    tableData: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleEditBackButton: PropTypes.func.isRequired,
  handleCloseEditBankAccountDrawer: PropTypes.func.isRequired,
};

export default EditTransferAccountDrawer;
