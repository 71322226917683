import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MailInProcess1 = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.44351 3.6853C8.08771 2.58649 10.0208 2 11.9982 2C14.6499 2 17.193 3.05357 19.068 4.92893C20.9431 6.80429 21.9964 9.34783 21.9964 12C21.9964 13.9778 21.41 15.9112 20.3114 17.5557C19.2128 19.2002 17.6513 20.4819 15.8244 21.2388C13.9974 21.9957 11.9871 22.1937 10.0477 21.8078C8.1082 21.422 6.32669 20.4696 4.92841 19.0711C3.53014 17.6725 2.5779 15.8907 2.19212 13.9509C1.80633 12.0111 2.00433 10.0004 2.76107 8.17316C3.51781 6.3459 4.79931 4.78412 6.44351 3.6853ZM14.7318 10.9918C14.7813 10.9 14.8094 10.7981 14.814 10.6939L14.8957 7.30612C14.896 7.12895 14.8285 6.95838 14.7071 6.82936C14.5857 6.70035 14.4195 6.62267 14.2427 6.61224H9.83534C9.73883 6.61746 9.64429 6.64167 9.55715 6.6835C9.47001 6.72532 9.39198 6.78394 9.32753 6.85598C9.26308 6.92802 9.21348 7.01208 9.18157 7.10333C9.14966 7.19458 9.13608 7.29124 9.14159 7.38775V10.6939C9.13299 10.8163 9.15906 10.9387 9.2168 11.047C9.27454 11.1552 9.36162 11.2451 9.46806 11.3061L10.7739 12.2041L9.46806 13.102C9.37375 13.1563 9.29449 13.2332 9.23748 13.3259C9.18046 13.4186 9.1475 13.524 9.14159 13.6327V16.9388C9.15138 17.1196 9.22762 17.2905 9.35566 17.4185C9.4837 17.5466 9.65453 17.6229 9.83534 17.6326H14.1611C14.3379 17.6222 14.5041 17.5445 14.6255 17.4155C14.7469 17.2865 14.8144 17.1159 14.814 16.9388V13.551C14.8094 13.4468 14.7813 13.3449 14.7318 13.2531C14.6824 13.1612 14.6128 13.0817 14.5284 13.0204L13.2225 12.1224L14.5284 11.2245C14.6128 11.1632 14.6824 11.0837 14.7318 10.9918ZM10.4883 16.2857V13.9184L11.9982 13.0204L13.4673 14V16.2857H10.4883ZM13.4673 10.4079V8.04053H10.4883V10.4079L11.9982 11.3875L13.4673 10.4079Z"
      />
    </SvgIcon>
  );
};

export default MailInProcess1;
