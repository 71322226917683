import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { verifyRemoteVersion } from './actions/uiStateActions';
import Layout from './components/hoc/Layout';
import noRequireAuth from './components/hoc/no_require_auth';
import Login from './components/Login';
import configureStore from './modules/store';
import { theme } from './theme/theme';
import RoutesCL from './routes/RoutesCL';
import RoutesMX from './routes/RoutesMX';
import Notifier from './components/Notifier';
import { enqueueSnackbar } from './actions/notificationAction';
import { t13s } from './translationKeys';
import { setAuthToken } from './helpers/axiosUtils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

const { store, persistor } = configureStore();

const appTheme = createMuiTheme(theme);

axios.interceptors.response.use(
  response => response,
  error => {
    const errorCode = t13s.ERROR.ERROR_ORDER_IS_LOCKED;
    const errorResponse = error?.response?.data?.errorCode;
    if (errorResponse === errorCode) {
      store.dispatch(
        enqueueSnackbar(errorCode, {
          variant: 'error',
        })
      );
    }
    return Promise.reject(error);
  }
);

const App = () => {
  const [intervalCheckVersion, setIntervalCheckVersion] = useState(null);

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_DOMAIN,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
  });

  useEffect(() => {
    // CHECK BO VERSION INTERVAL
    if (!intervalCheckVersion) {
      setIntervalCheckVersion(
        setInterval(() => {
          store.dispatch(verifyRemoteVersion());
        }, 30000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      if (await oktaAuth.isAuthenticated()) {
        const token = oktaAuth.getAccessToken();

        setAuthToken(token);
      }
    };

    checkToken();
  }, [oktaAuth]);

  return (
    <MuiThemeProvider theme={appTheme}>
      <SnackbarProvider maxSnack={8}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <Layout>
                  <Notifier />
                  <Switch>
                    {/* NO AUTHS PATHS */}
                    <Route
                      exact
                      path="/login"
                      component={noRequireAuth(Login)}
                    />

                    <SecureRoute
                      exact
                      path="/"
                      render={() => {
                        return <Redirect to="/cl/direct_financing" />;
                      }}
                    />
                    <SecureRoute
                      exact
                      path="/direct_financing"
                      render={() => {
                        return <Redirect to="/cl/direct_financing" />;
                      }}
                    />
                    <SecureRoute
                      exact
                      path="/orders/:id"
                      render={({ match }) => {
                        return (
                          <Redirect to={`/cl/orders/${match.params.id}`} />
                        );
                      }}
                    />

                    <SecureRoute path="/:country(cl)" component={RoutesCL} />
                    <SecureRoute path="/:country(mx)" component={RoutesMX} />
                    <Route path="/login/callback" component={LoginCallback} />
                  </Switch>
                </Layout>
              </QueryClientProvider>
            </PersistGate>
          </Provider>
        </Security>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default App;
