import React from 'react';
import { SecureRoute } from '@okta/okta-react';

import { FUNDS_SECTION_PERFORM } from '../../../helpers/performsType';
import { PayrollList } from '../../inProgress/ui/PayrollList';
import requireAuth from '../../../components/hoc/require_okta_auth';
import { OpportunitiesRouter } from '../../opportunities/ui/Opportunities/OpportunitiesRouter';
import { Funds } from '../../Maintainer/ui/Funds';

export const HomeRoutes = basePath => {
  const baseOpportunitiesPath = `${basePath}/opportunities`;

  return [
    <SecureRoute key={1} path={baseOpportunitiesPath}>
      <OpportunitiesRouter basePath={baseOpportunitiesPath} />
    </SecureRoute>,
    <SecureRoute
      key={2}
      exact
      path={`${basePath}/in-process`}
      component={requireAuth(PayrollList, FUNDS_SECTION_PERFORM)}
    />,
    <SecureRoute
      key={3}
      exact
      path={`${basePath}/configurations`}
      component={requireAuth(Funds, FUNDS_SECTION_PERFORM)}
    />,
  ];
};
