import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { resetError } from '../../actions/agreementActions';

import { phoneValidation } from '../../helpers/validation/phoneValidation';
import AlertForm from '../elements/AlertForm';
import LoadingButton from '../elements/LoadingButton';
import BaseDialog from './BaseDialog';

const FormAgreementContactDialog = ({
  open,
  handleClose,
  type,
  sourceId,
  originalContact,
  handleSubmit,
  loading,
  error,
}) => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState({
    name: originalContact?.name || '',
    position: originalContact?.position || '',
    email: originalContact?.email || '',
    phone: originalContact?.phone || '',
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isPhoneValid', value => {
      return phoneValidation(value);
    });

    dispatch(resetError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    setContact({
      name: '',
      position: '',
      email: '',
      phone: '',
    });
  };

  const closeDialog = () => {
    resetForm();
    handleClose();
  };

  const handleInputChange = e => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = () => {
    if (!originalContact) {
      handleSubmit(sourceId, { ...contact, type });
    } else {
      handleSubmit(contact);
    }
  };

  const { name, email, position, phone } = contact;

  return (
    <BaseDialog
      title={originalContact ? 'Editar contacto' : 'Agregar contacto'}
      isOpen={open}
      handleClose={closeDialog}
    >
      <ValidatorForm onSubmit={handleSubmitForm}>
        <TextValidator
          fullWidth
          variant="outlined"
          name="name"
          value={name}
          label="Nombre"
          validators={['required', 'maxStringLength:130']}
          errorMessages={['Campo requerido', 'Máximo 130 caracteres']}
          onChange={handleInputChange}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          name="position"
          value={position}
          label="Cargo"
          validators={['required', 'maxStringLength:50']}
          errorMessages={['Campo requerido', 'Máximo 50 caracteres']}
          onChange={handleInputChange}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          name="email"
          value={email}
          label="Mail"
          validators={['required', 'maxStringLength:50', 'isEmail']}
          errorMessages={[
            'Campo requerido',
            'Máximo 50 caracteres',
            'Email incorrecto',
          ]}
          onChange={handleInputChange}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          name="phone"
          value={phone}
          label="Teléfono"
          validators={['required', 'isPhoneValid', 'maxStringLength:15']}
          errorMessages={[
            'Campo requerido',
            'telefono invalido',
            'Máximo 15 caracteres',
          ]}
          onChange={handleInputChange}
        />

        {error && <AlertForm message={error} variant="error" />}

        <LoadingButton
          isLoading={loading}
          fullWidth
          color="primary"
          type="submit"
          variant="contained"
        >
          Guardar
        </LoadingButton>
      </ValidatorForm>
    </BaseDialog>
  );
};

FormAgreementContactDialog.defaultProps = {
  originalContact: null,
  loading: false,
  error: null,
};

FormAgreementContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sourceId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  originalContact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default FormAgreementContactDialog;
