import MomentUtils from '@date-io/moment';
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
  styled,
  CircularProgress,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSelector, useDispatch } from 'react-redux';
import {
  DATE_FORMAT,
  DF_LABEL,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
  EP_LABEL,
  ORDER_TYPES_LABEL,
  SIN_DESCUENTO,
  CONFIRMING,
  COUNTRY_CODE_CL,
  DISCOUNTS,
  REPLACEMENT_OPERATION_REASONS_DISCOUNTS,
  STATUS_APPROVED,
  STATUS_APPEALED,
  CURRENCY,
  COUNTRY_CODE_MX,
  STATUS_PV_APPROVED,
} from '../../helpers/Constants';
import { dateFormatter } from '../../helpers/DateUtils';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { features } from '../../config/features';
import { settings } from '../../config/settings';
import verifyDecimals from '../../helpers/validation/decimals';
import verifyRut from '../../helpers/validation/rut';
import BaseDialog from '../dialogs/BaseDialog';
import CheckButton from './CheckButton';
import CountryFormatHelper from './CountryFormatHelper';
import DataList from './DataList';
import Section from './Section';
import {
  ORDER_RATES_PERCENTAGE_CHANGE,
  OPERATION_COST_PERCENTAGE_CHANGE,
} from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';
import { fixNumber } from '../../helpers/mathUtils';
import {
  resetOrderFees,
  getRestrictiveInformationNewSimulation,
} from '../../actions/orderActions';
import AlertForm from './AlertForm';
import calculateFees from '../../AR/inbox/infrastructure/services/calculateFees';

const useStyles = makeStyles({
  input: {
    justifyContent: 'left',
    width: '100%',
  },
  containerDetailInformation: {
    padding: '0px 10px',
    textAlign: 'start',
  },
  containerButton: {
    marginTop: 30,
  },
  customWidth: {
    maxWidth: 180,
  },
  help: {
    marginLeft: 10,
  },
  autorate: {
    marginLeft: 5,
  },
  resume: {
    background: '#F7F5FA',
    borderRadius: 17,
    padding: 20,
  },
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  containerInformationText: {
    marginLeft: 10,
    marginTop: -8,
    marginBottom: 10,
  },
  spacingInformationText: {
    margin: '0 4px',
  },
  operationResumeTitle: {
    marginBottom: 20,
  },
  containerIssuedDateLabel: {
    marginTop: -10,
  },
  issuedDateLabel: {
    marginBottom: 10,
  },
  containerDiscountInformation: {
    marginTop: -15,
    marginBottom: 15,
    marginLeft: 5,
  },
});

const Label = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 12,
  marginRight: 5,
}));
const LabelBold = styled(Typography)(theme => ({
  ...theme.props,
  fontWeight: 'bold',
  fontSize: 12,
}));

const operationTypes = {
  [DIRECT_FINANCING]: 'directFinancing',
  [EARLY_PAYMENT]: 'earlyPayment',
  [CONFIRMING]: 'confirming',
};

const roleAllowedToChangeBaseRate = [
  'admin',
  'Pymes CL - Líder',
  'Corps CL - Líder',
];

const NewSimulationDialog = ({
  open,
  handleCloseDialog,
  handleFeesChange,
  handleSubmitSimulation,
  order,
  operationType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    fees,
    loadingFees,
    fetchRestrictiveDataNewSimulationIsLoading,
    restrictiveDataNewSimulation,
  } = useSelector(state => state.order);
  const { country } = useSelector(state => state.config);
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const [formData, setFormData] = useState({
    orderId: '',
    businessId: '',
    retentionPct: 0,
    tasaBase: 0,
    issuedDate: null,
    operationCost: 0,
    discount: 0,
    reason: null,
    operation: operationType,
  });
  const [bounce, setBounce] = useState(null);
  const [autoRate, setAutoRate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [validationRates, setValidationRates] = useState({
    minRate: 0,
  });
  const [validationOperationCost, setValidationOperationCost] = useState({
    minOperationCost: 0,
  });
  const [defaultBaseRate, setDefaultBaseRate] = useState(0);
  const [defaultOperationCost, setDefaultOperationCost] = useState(0);
  const [isDisabledIssuedDate, setIsDisabledIssuedDate] = useState(
    operationType !== CONFIRMING && country === COUNTRY_CODE_CL
  );
  const [isDisabledBaseRateByRole, setIsDisabledBaseRateByRole] =
    useState(true);
  const [isDisabledOperationCostByRole, setIsDisabledOperationCostByRole] =
    useState(true);
  const [isCalculatingFees, setChangeIsCalculatingFees] = useState(false);
  const currentIssuedDate = useRef();
  const countryFeatures = features[country];
  const countrySettings = settings[country];
  const selectOperation = operationTypes[operationType];

  const handleValidationRate = restrictiveBaseRate => {
    ORDER_RATES_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const rawNewMinRate = fixNumber(restrictiveBaseRate - percentageChange);
        const minBaseRateSettings = countrySettings.minBaseRateCreateOperation;
        const newMinRate = Math.max(rawNewMinRate, minBaseRateSettings);
        setValidationRates({
          minRate: newMinRate,
        });
      }
    });
  };

  const handleValidationOperationCost = restrictiveOperationcost => {
    OPERATION_COST_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const valueChange = fixNumber(
          restrictiveOperationcost * (percentageChange / 100)
        );
        const minOperationCost = fixNumber(
          restrictiveOperationcost - valueChange
        );
        setValidationOperationCost({
          minOperationCost,
        });
      }
    });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidRut', value => verifyRut(value));
    ValidatorForm.addValidationRule('isPositiveOrZero', value => value >= 0);
    ValidatorForm.addValidationRule('maxDecimals', value =>
      verifyDecimals(value)
    );
    ValidatorForm.addValidationRule('maxBaseRate', value => {
      return Number(value) <= countrySettings.maxBaseRate;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    ValidatorForm.addValidationRule('minBaseRate', value => {
      if (autoRate) return true;
      return Number(value) >= validationRates.minRate;
    });
    ValidatorForm.addValidationRule('minOperationCost', value => {
      return Number(value) >= validationOperationCost.minOperationCost;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationRates, validationOperationCost, autoRate]);

  useEffect(() => {
    if (order?.id) {
      dispatch(
        getRestrictiveInformationNewSimulation(
          operationType,
          order?.id,
          order?.businessId
        )
      );
    }
  }, [order]);

  useEffect(() => {
    if (order) {
      const {
        OrderDetails: [orderDetail],
        id: orderId,
        advancePercentage,
        transferDiscount,
        businessId,
        transferDiscountReason: reason = SIN_DESCUENTO,
      } = order;
      const {
        issuedDate,
        operationCost,
        discount = transferDiscount,
        tasaBase,
      } = orderDetail;
      const retentionPct = 100 - advancePercentage;
      const orderDiscount = discount ? Math.abs(discount) : discount;
      currentIssuedDate.current = dateFormatter(issuedDate);
      const selectIssuedDate =
        operationType === CONFIRMING ? issuedDate : Date.now();
      setFormData({
        ...formData,
        orderId,
        businessId,
        retentionPct,
        issuedDate: selectIssuedDate,
        reason,
        discount: orderDiscount,
        operation: operationType,
        tasaBase,
        operationCost,
      });
      handleFeesChange({
        orderId,
        businessId,
        retentionPct,
        operation: operationType,
        issuedDate: selectIssuedDate,
        discount: orderDiscount,
        operationCost,
        autoRate,
        tasaBase,
      });
    }
  }, [order]);

  useEffect(() => {
    if (Object.keys(fees).length) {
      setFormData({
        ...formData,
        operationCost: fees?.operationCost,
        tasaBase: fees?.baseRate,
      });
    }
  }, [fees]);

  useEffect(() => {
    if (restrictiveDataNewSimulation && order) {
      const {
        OrderDetails: [orderDetail],
      } = order;
      let userRoleAllowed = false;
      roleAllowedToChangeBaseRate.forEach(roleAllowed => {
        if (roles.some(role => role.rolename === roleAllowed)) {
          userRoleAllowed = true;
        }
      });

      if (userRoleAllowed) {
        setIsDisabledBaseRateByRole(false);
        setIsDisabledOperationCostByRole(false);
        setIsDisabledIssuedDate(false);
      } else {
        setIsDisabledBaseRateByRole(
          orderDetail?.tasaBase < validationRates?.minRate
        );
        setIsDisabledOperationCostByRole(
          orderDetail?.operationCost < validationOperationCost?.minOperationCost
        );
      }
    }
  }, [
    restrictiveDataNewSimulation,
    order,
    validationRates,
    validationOperationCost,
  ]);

  useEffect(() => {
    if (restrictiveDataNewSimulation) {
      if (!defaultBaseRate) {
        setDefaultBaseRate(restrictiveDataNewSimulation?.baseRate);
        handleValidationRate(restrictiveDataNewSimulation?.baseRate);
      }
      if (!defaultOperationCost) {
        setDefaultOperationCost(restrictiveDataNewSimulation?.operationCost);
        handleValidationOperationCost(
          restrictiveDataNewSimulation?.operationCost
        );
      }
    }
  }, [restrictiveDataNewSimulation]);

  const handleSubmit = () => {
    handleSubmitSimulation({
      ...fees,
      orderId: formData?.orderId,
      orderType: formData?.operation,
      discount: formData?.discount,
      operationCost: formData?.operationCost,
      issuedDate: formData?.issuedDate,
      reason: formData?.reason,
      autoRate,
    });
    handleCloseDialog();
    dispatch(resetOrderFees());
  };

  const transformInvoices = invoices => {
    return invoices.map(({ invoiceId, expirationDate, Invoice }) => ({
      invoiceId,
      expirationDate,
      amount: Invoice?.amount,
      payerIdentifier: Invoice?.invoiceStakeholderIdentifier,
    }));
  };

  const handleInputChange = async e => {
    const hasChangePPToFD =
      e.target.name === 'operation' &&
      e.target.value === 'DIRECT_FINANCING' &&
      formData.operation === 'EARLY_PAYMENT';

    const newData = {
      ...formData,
      [e.target.name]: e.target.value,
    };

    if (hasChangePPToFD) {
      setChangeIsCalculatingFees(true);
      const formattedInvoices = transformInvoices(order.OrderInvoices);

      try {
        const newFees = await calculateFees(
          order.Business.id,
          e.target.value,
          formattedInvoices
        );

        setChangeIsCalculatingFees(false);

        setFormData({ ...newData, tasaBase: newFees.baseRate });
        handleFeesChange({ ...newData, tasaBase: newFees.baseRate });
        return;
      } catch (error) {
        setChangeIsCalculatingFees(false);
        return;
      }
    }

    setFormData(newData);
    clearTimeout(bounce);
    setBounce(
      setTimeout(() => {
        handleFeesChange({
          ...newData,
          autoRate,
        });
      }, 500)
    );
  };

  const handleDateChange = (stateKey, date) => {
    const newData = {
      ...formData,
      [stateKey]: date,
    };
    setFormData(newData);
    clearTimeout(bounce);
    setBounce(
      setTimeout(() => {
        handleFeesChange({
          ...newData,
          autoRate,
        });
      }, 500)
    );
  };

  const handleCheckButton = () => {
    setAutoRate(!autoRate);
    handleFeesChange({
      ...formData,
      autoRate: !autoRate,
    });
  };

  const getBaseRateValidators = (type, isDisabled) => {
    if (isDisabled) {
      return [];
    }
    const validatorsDictionary = {
      baseRate: {
        validators: ['required', 'minBaseRate', 'maxBaseRate'],
        errorMessages: [
          'Campo requerido',
          `El valor mínimo debe ser ${validationRates.minRate}`,
          `El valor máximo debe ser ${countrySettings.maxBaseRate}`,
        ],
      },
      operationCost: {
        validators: ['required', 'minOperationCost'],
        errorMessages: [
          'Campo requerido',
          `El valor mínimo debe ser ${validationOperationCost.minOperationCost}`,
        ],
      },
    };
    return validatorsDictionary[type];
  };

  const detailData = [
    {
      label: 'Anticipo:',
      data: (
        <CountryFormatHelper
          value={fees?.advance}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Retención:',
      data: (
        <CountryFormatHelper
          value={fees?.retention}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Intereses:',
      data: (
        <CountryFormatHelper
          value={fees?.interest}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Descuentos:',
      data: (
        <CountryFormatHelper
          value={fees?.discount}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Monto transferencia:',
      data: (
        <CountryFormatHelper
          value={fees?.transfer}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Fecha creación:',
      data: dateFormatter(fees?.issuedDate) || '-',
    },
  ];

  return (
    <BaseDialog
      isOpen={open}
      size="lg"
      handleClose={handleCloseDialog}
      title={`Nueva simulación de ${ORDER_TYPES_LABEL[operationType]}`}
      description="Ingresa el % de retención y la tasa base para la simulación"
    >
      <ValidatorForm onSubmit={handleSubmit} className={classes.rootForm}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={loadingFees}
              variant="outlined"
              label="% Retención"
              inputProps={{ min: 0, max: 100, step: 0.001 }}
              type="number"
              id="retentionPct"
              name="retentionPct"
              aria-describedby="retentionPct"
              value={formData?.retentionPct}
              onChange={e => handleInputChange(e)}
              validators={['required', 'minNumber:0', 'maxNumber:100']}
              errorMessages={[
                'Campo requerido',
                'El valor mínimo debe ser 0',
                'El valor máximo debe ser 100',
              ]}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Tasa base (%)"
              disabled={
                autoRate ||
                loadingFees ||
                fetchRestrictiveDataNewSimulationIsLoading ||
                isDisabledBaseRateByRole ||
                isCalculatingFees
              }
              inputProps={{
                min: 0,
                max: countrySettings.maxBaseRate,
                step: 0.001,
              }}
              type="number"
              name="tasaBase"
              value={formData?.tasaBase}
              onChange={e => handleInputChange(e)}
              validators={
                getBaseRateValidators('baseRate', isDisabledBaseRateByRole)
                  .validators
              }
              errorMessages={
                getBaseRateValidators('baseRate', isDisabledBaseRateByRole)
                  .errorMessages
              }
            />
            {fetchRestrictiveDataNewSimulationIsLoading && (
              <Box
                display="flex"
                alignItems="center"
                className={classes.containerInformationText}
              >
                <CircularProgress size={15} />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginLeft: 5 }}
                >
                  Cargando restricciones...
                </Typography>
              </Box>
            )}
            {validationRates.minRate !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Tasa base minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">
                    {validationRates.minRate}%
                  </Box>
                </Typography>
              </Box>
            )}
            <TextValidator
              fullWidth
              disabled={
                loadingFees ||
                REPLACEMENT_OPERATION_REASONS_DISCOUNTS.includes(
                  formData.reason
                )
              }
              variant="outlined"
              label="Descuentos ($)"
              type="number"
              id="discount"
              name="discount"
              aria-describedby="discount"
              value={formData?.discount}
              onChange={e => handleInputChange(e)}
              validators={['isPositiveOrZero']}
              errorMessages={['Valor mayor o igual a 0']}
              isPositiveOrZero
            />
            {REPLACEMENT_OPERATION_REASONS_DISCOUNTS.includes(
              formData.reason
            ) && (
              <Box className={classes.containerDiscountInformation}>
                <Typography variant="caption" color="textPrimary">
                  Operación con un descuento de tipo reemplazo, para hacer
                  cambios en el descuento, puedes modificarlo en el resumen de
                  la operación.
                </Typography>
              </Box>
            )}

            {formData.discount > 0 &&
              countryFeatures.orderActions.showReasonDiscount &&
              !REPLACEMENT_OPERATION_REASONS_DISCOUNTS.includes(
                formData.reason
              ) && (
                <SelectValidator
                  disabled={loadingFees || isCalculatingFees}
                  variant="outlined"
                  label="Razón de descuento"
                  name="reason"
                  fullWidth
                  value={formData.reason}
                  onChange={e => handleInputChange(e)}
                  validators={formData.discount > 0 ? ['required'] : []}
                  errorMessages={
                    formData.discount > 0 ? ['Campo requerido'] : []
                  }
                >
                  {DISCOUNTS.filter(
                    reason =>
                      reason.type === 'BASE_DISCOUNT' &&
                      reason.country.includes(country)
                  ).map(item => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectValidator>
              )}
            <TextValidator
              fullWidth
              disabled={
                loadingFees ||
                fetchRestrictiveDataNewSimulationIsLoading ||
                isDisabledOperationCostByRole ||
                (order.currency === CURRENCY.US && country === COUNTRY_CODE_MX)
              }
              variant="outlined"
              label="Costo de operación ($)"
              type="number"
              id="operationCost"
              name="operationCost"
              aria-describedby="operationCost"
              value={formData.operationCost}
              onChange={e => handleInputChange(e)}
              validators={
                getBaseRateValidators(
                  'operationCost',
                  isDisabledOperationCostByRole
                ).validators
              }
              errorMessages={
                getBaseRateValidators(
                  'operationCost',
                  isDisabledOperationCostByRole
                ).errorMessages
              }
            />
            {fetchRestrictiveDataNewSimulationIsLoading && (
              <Box
                display="flex"
                alignItems="center"
                className={classes.containerInformationText}
              >
                <CircularProgress size={15} />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginLeft: 5 }}
                >
                  Cargando restricciones...
                </Typography>
              </Box>
            )}

            {validationOperationCost.minOperationCost !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Asesoría digital minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">
                    {validationOperationCost.minOperationCost}
                  </Box>
                </Typography>
              </Box>
            )}
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                fullWidth
                label="Fecha creación"
                name="issuedDate"
                format={DATE_FORMAT}
                value={formData.issuedDate}
                onChange={date => handleDateChange('issuedDate', date)}
                disabled={isDisabledIssuedDate || loadingFees}
                autoOk
              />
            </MuiPickersUtilsProvider>
            <Box
              display="flex"
              alignItems="center"
              className={classes.containerIssuedDateLabel}
            >
              <Label>Fecha creación actual de la OP: </Label>
              <LabelBold>{currentIssuedDate.current}</LabelBold>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              className={classes.issuedDateLabel}
            >
              <Label>Fecha creación con nueva simulación: </Label>
              <LabelBold>{dateFormatter(formData.issuedDate)}</LabelBold>
            </Box>
            {countryFeatures.orderActions.changeOperationType &&
              (operationType === EARLY_PAYMENT ||
                (operationType === DIRECT_FINANCING &&
                  fees?.allInvoicesWithAgreements)) && (
                <>
                  <SelectValidator
                    disabled={loadingFees}
                    fullWidth
                    variant="outlined"
                    id="operation"
                    label="Tipo Operación"
                    value={formData.operation}
                    name="operation"
                    onChange={e => handleInputChange(e)}
                  >
                    <MenuItem value={DIRECT_FINANCING}>{DF_LABEL}</MenuItem>
                    <MenuItem value={EARLY_PAYMENT}>{EP_LABEL}</MenuItem>
                  </SelectValidator>
                  {[
                    STATUS_APPROVED,
                    STATUS_APPEALED,
                    STATUS_PV_APPROVED,
                  ].includes(order.status) && (
                    <AlertForm
                      variant="info"
                      message="Al cambiar el tipo de operación, esta será nuevamente revisada por riesgo."
                    />
                  )}
                </>
              )}
          </Grid>

          <Grid
            item
            xs={6}
            align="center"
            className={classes.containerDetailInformation}
          >
            <Section card className={classes.resume}>
              <Container>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.operationResumeTitle}
                >
                  <Box fontWeight="fontWeightBold">Resumen de la operación</Box>
                </Typography>
                <DataList
                  wide
                  displayInDialog
                  isLoading={loadingFees || isCalculatingFees}
                  data={detailData}
                  justify="flex-start"
                />
                {countryFeatures.showAutoRateNewSimulationButton[
                  selectOperation
                ] && (
                  <Grid
                    container
                    direction="row"
                    justify="left"
                    alignItems="center"
                  >
                    <Switch
                      disabled={loadingFees}
                      checked={autoRate}
                      onChange={handleCheckButton}
                      color="primary"
                    />
                    <Typography
                      variant="subtitle2"
                      className={classes.autorate}
                      color={autoRate ? 'primary' : 'textPrimary'}
                      component="div"
                    >
                      <Box fontWeight="fontWeightBold">Autotasas</Box>
                    </Typography>
                    <Tooltip
                      title="Al activar esta opción esta operación aplicará el modelo de tasas basado en el nivel de riesgo de cada factura."
                      classes={{ tooltip: classes.customWidth }}
                    >
                      <HelpOutlineIcon
                        fontSize="inherit"
                        className={classes.help}
                      />
                    </Tooltip>
                  </Grid>
                )}
              </Container>
            </Section>
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.containerButton}>
          <CheckButton
            disabled={loadingFees || fetchRestrictiveDataNewSimulationIsLoading}
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

NewSimulationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  order: PropTypes.objectOf(Object).isRequired,
  handleFeesChange: PropTypes.func.isRequired,
  operationType: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmitSimulation: PropTypes.func.isRequired,
};

export default NewSimulationDialog;
