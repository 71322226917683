import { useEffect } from 'react';
import { useQuery } from 'react-query';
import getCreditLineService from '../services/getCreditLineService';
import { creditLineRiskCardTab } from '../constants';
import { Icon, Country } from '../schemas';
import { getCurrencyFormat } from '../utils';

type GetCreditLineError = {
  name: string;
};

type GetCreditLineData = {
  data: {
    amount: number;
  } | null;
};

const useGetCreditLine = (country: Country, businessId?: string) => {
  const { error, data, remove, ...rest } = useQuery<GetCreditLineData, GetCreditLineError>(
    'getCreditLineService',
    async () => getCreditLineService(country, businessId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(businessId),
    },
  );

  useEffect(() => remove, []);

  const customError = error ? error.name : null;
  const amount = data?.data?.amount;
  let typeIcon: Icon = 'danger';
  let creditLine = creditLineRiskCardTab.message;

  if (customError !== creditLineRiskCardTab.error && amount && amount > 0) {
    creditLine = getCurrencyFormat(amount, country);
    typeIcon = 'checked';
  }

  if (amount === 0) {
    typeIcon = 'error';
    creditLine = getCurrencyFormat(0, country);
  }

  return {
    ...rest,
    data,
    error: customError,
    typeIcon,
    amount,
    creditLine,
  };
};

export default useGetCreditLine;
