import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';

import { useStyles } from './styles';
import BaseDialog from '../../../../../components/dialogs/BaseDialog';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { percentageFormat } from '../../../../commons/utils';

const CessionNotificationDialogComponent = ({
  isOpen,
  handleCloseDialog,
  title,
  country,
  description,
  summary: { name, count, rate, amount, interest },
  handleSubmit,
}) => {
  const classes = useStyles();

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
    >
      <Grid container className={classes.infoContainer} direction="column">
        <Grid item>
          <span>Fondo</span> <strong>{name}</strong>
        </Grid>
        <Grid item>
          <span>Nº de documentos</span> <strong>{count}</strong>
        </Grid>
        <Grid item>
          <span>Tasa ponderada</span>
          <strong>{percentageFormat(rate)}</strong>
        </Grid>
        <Grid item>
          <span>Interés</span>
          <strong>
            <CountryFormatHelper
              value={interest}
              countryId={country}
              variant="currency"
            />
          </strong>
        </Grid>
        <Grid item>
          <span>Monto total a girar</span>
          <strong>
            <CountryFormatHelper
              value={amount}
              countryId={country}
              variant="currency"
            />
          </strong>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submitBtn}
          onClick={handleSubmit}
        >
          Notificar
        </Button>
      </Grid>
    </BaseDialog>
  );
};

CessionNotificationDialogComponent.defaultProps = {
  description: '',
  isOpen: false,
};

CessionNotificationDialogComponent.propTypes = {
  isOpen: PropTypes.bool,
  country: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    amount: PropTypes.number,
    count: PropTypes.number,
    name: PropTypes.string,
    rate: PropTypes.number,
    interest: PropTypes.number,
  }).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export const CessionNotificationDialog = memo(
  CessionNotificationDialogComponent
);
