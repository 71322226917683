import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { TextValidator } from 'react-material-ui-form-validator';
import {
  DatePicker as MaterialDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { DATE_FORMAT } from '../../../../helpers/Constants';
import * as CommonUtils from '../../utils';
import * as Utils from './utils';

const TODAY = new Date();

const DatePickerComponent = ({
  baseDate,
  form,
  label,
  onChange,
  shouldDisableDate,
  validators,
}) => {
  const [formState, setFormState] = useState(form);
  const defaultDays = CommonUtils.getRangeOfDays(baseDate);
  const defaultDate = CommonUtils.getYYYYMMDD(baseDate);

  const onChangeForm = frm => {
    setFormState(frm);
    onChange(frm);
  };

  const onChangeDatePicker = ev => {
    const { name: inputName, value } = ev.target;
    const days = CommonUtils.getRangeOfDays(ev.target.value, TODAY);
    onChangeForm({ [inputName]: value, inDays: days });
  };

  const onChangeInputText = ev => {
    const inputValue = ev.target.value === '' ? 1 : +ev.target.value;
    const dateToValidate = CommonUtils.dateDBFormatter(
      CommonUtils.addDays(inputValue, TODAY)
    );
    const daysToAdd = Utils.daysToAddByHolidays(dateToValidate);
    const totalDaysToAdd = inputValue + daysToAdd;
    const newDate = CommonUtils.dateDBFormatter(
      CommonUtils.addDays(totalDaysToAdd, TODAY)
    );

    onChangeForm({
      inDate: newDate,
      inDays: totalDaysToAdd,
    });
  };

  return (
    <>
      <TextValidator
        fullWidth
        label={label.inDays}
        name="inDays"
        inputProps={{ step: 1 }}
        onChange={onChangeInputText}
        type="number"
        value={formState.inDays || defaultDays}
        variant="outlined"
        validators={validators.rules}
        errorMessages={validators.errorMsg}
      />
      <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
        <MaterialDatePicker
          autoOk
          format={DATE_FORMAT}
          fullWidth
          inputVariant="outlined"
          label={label.inDate}
          name="inDate"
          shouldDisableDate={shouldDisableDate}
          value={formState.inDate || defaultDate}
          variant="inline"
          onChange={date => {
            onChangeDatePicker({
              target: {
                name: 'inDate',
                // eslint-disable-next-line no-underscore-dangle
                value: CommonUtils.dateDBFormatter(date._d),
              },
            });
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

DatePickerComponent.defaultProps = {
  form: {
    inDate: '',
    inDays: '',
  },
  label: {
    inDate: 'Fecha vencimiento',
    inDays: 'Plazo (días)',
  },
  baseDate: new Date(),
  validators: {
    rules: [],
    errorMsg: [],
  },
  shouldDisableDate: Utils.disableWeekends,
};

DatePickerComponent.propTypes = {
  form: PropTypes.shape({
    inDate: PropTypes.string,
    inDays: PropTypes.string,
  }),
  label: PropTypes.shape({
    inDate: PropTypes.string,
    inDays: PropTypes.string,
  }),
  validators: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string),
    errorMsg: PropTypes.arrayOf(PropTypes.string),
  }),
  baseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  shouldDisableDate: PropTypes.func,
};

export const DatePicker = memo(DatePickerComponent);
