import React, { memo } from 'react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Delete } from '../../../../components/icons';
import Chip from '../../../../components/elements/Chip';
import { useStyles } from './styles';
import { purgeNestedObj } from '../../utils';
import { countAppliedFilters } from '../../utils/filter';

const FormSummaryComponent = ({ summary, resetForm }) => {
  const css = useStyles();
  const appliedFilters = countAppliedFilters(purgeNestedObj(summary));

  return appliedFilters === 0 ? null : (
    <Grid item xs={12} className={css.mb15}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography
              variant="body1"
              color="textPrimary"
              component="div"
              className={css.mb15}
            >
              <Box fontWeight="fontWeightBold">Filtros aplicados</Box>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              className={css.cleanFilters}
            >
              <Grid item className={css.icon}>
                <Delete />
              </Grid>
              <Grid item>
                <Link
                  className={css.cleanFilters}
                  component="button"
                  variant="body1"
                  align="left"
                  onClick={() => resetForm()}
                >
                  Limpiar filtros
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {Object.keys(summary).reduce((acc, k) => {
          if (summary[k]) {
            if (Array.isArray(summary[k])) {
              return [
                ...acc,
                ...summary[k].map((subk, index) => {
                  return (
                    <Chip
                      className={css.chip}
                      ellipsisWidth="19rem"
                      key={k + String(index)}
                      label={subk}
                      onDelete={() => {
                        resetForm(k, index);
                      }}
                    />
                  );
                }),
              ];
            }
            return [
              ...acc,
              <Chip
                className={css.chip}
                ellipsisWidth="19rem"
                key={k}
                label={summary[k]}
                onDelete={() => {
                  resetForm(k);
                }}
              />,
            ];
          }
          return acc;
        }, [])}
      </Grid>
    </Grid>
  );
};

FormSummaryComponent.propTypes = {
  summary: PropTypes.objectOf(Object).isRequired,
  resetForm: PropTypes.func.isRequired,
};

export const FormSummary = memo(FormSummaryComponent);
