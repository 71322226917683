import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, CircularProgress } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import {
  Box,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { t13s } from '../../../../translationKeys';
import { payerCategorizationType } from '../../../commons/utils/payerCategorizationOptions';
import { PayerCategorization } from '../../../commons/interfaces/payerCategorization';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleClose: (arg: boolean) => void;
  handleClick: (arg: PayerCategorization[]) => void;
  recap: string;
}

const RecapModal = ({
  open,
  isLoading,
  handleClose,
  handleClick,
  recap,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [detail, setDetail] = useState<string>(recap);
  const [click, setClick] = useState<boolean>(false);

  const validate = () => {
    if (detail) setClick(true);
    else setClick(false);
  };

  useEffect(() => {
    validate();
  }, [detail]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(!open)}
      classes={{
        paper: classes.paper,
        paperWidthMd: classes.paperWidthMd,
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          data-cy="baseDialogCloseButton"
          onClick={() => handleClose(!open)}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          color="textPrimary"
          align="center"
          component="div"
          className={classes.itemTitle}
        >
          {t(t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES.RECAP)}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          align="center"
          component="div"
          className={classes.item}
        >
          {t(t13s.LABEL.COLLECTIONS.RECAP_DESCRIPTION)}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Grid container item xs={12} justifyContent="center">
            <TextField
              variant="outlined"
              multiline
              minRows={3}
              className={classes.roundCorners}
              style={{ width: 350 }}
              value={detail}
              onChange={e => setDetail(e.target.value)}
              inputProps={{ maxLength: 140 }}
              helperText={`${140 - detail.length} caracteres restantes.`}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.spacingAll}
          >
            <Button
              variant="outlined"
              style={{ width: 150 }}
              onClick={() => handleClose(!open)}
            >
              {t(t13s.LABEL.COLLECTIONS.CANCEL)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!click || isLoading}
              style={{ width: 150 }}
              onClick={() =>
                handleClick([
                  { type: payerCategorizationType.RECAP, result: '', detail },
                ])
              }
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `${t(t13s.LABEL.COLLECTIONS.CONFIRM)}`
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RecapModal;
