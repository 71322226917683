import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updatePartialPaymentService = async (
  sourceType,
  sourceId,
  partialPaymentId,
  { amount, paymentDate, comment }
) => {
  try {
    const data = await axios.put(
      `/api/${sourceType}/${sourceId}/partialpay/${partialPaymentId}`,
      {
        amount,
        paymentDate,
        comment,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

export default updatePartialPaymentService;
