import axios from 'axios';

export const unlockOrderService = async ({ orderId }) => {
  try {
    return await axios.put(`/api/orders/order/${orderId}/unlock`);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default unlockOrderService;
