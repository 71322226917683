import { Grid, Link, styled } from '@material-ui/core';

const OrderLockGrid = styled(Grid)({
  padding: '20px 20px 0 20px',
});

const AlertUnlockLink = styled(Link)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontSize: 14,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export { OrderLockGrid, AlertUnlockLink };
