import React, { FC, memo } from 'react';
import {
  Header,
  Filters,
  SelectedOperations,
  OperationCards,
} from '../../inbox/ui';
import {
  ContainerRoot,
  ContainerData,
  ContainerHeader,
  CardContainer,
} from './styles';
import OperationTabs from '../../../components/elements/OperationTabs';
import Pagination from '../../commons/components/Pagination';
import useInbox from '../../inbox/hooks/useInbox';

const Inbox: FC = memo(() => {
  const {
    page,
    itemsPerPage,
    country,
    operationType,
    operations,
    operationsPagination,
    fetchOperationIsLoading,
    handleTabChange,
    handleChangeItemsPerPage,
    handleChangePage,
  } = useInbox();

  return (
    <ContainerRoot>
      <OperationTabs
        value={operationType}
        country={country}
        onChange={handleTabChange}
      />
      <ContainerData>
        <ContainerHeader>
          <Header />
        </ContainerHeader>
        <CardContainer>
          <Filters />
          <SelectedOperations />
          <OperationCards
            operations={operations}
            fetchOperationIsLoading={fetchOperationIsLoading}
          />
          <Pagination
            page={page}
            itemsPerPage={itemsPerPage}
            dataPagination={operationsPagination}
            fetchIsLoading={fetchOperationIsLoading}
            handleChangePage={handleChangePage}
            handleChangeItemsPerPage={handleChangeItemsPerPage}
          />
        </CardContainer>
      </ContainerData>
    </ContainerRoot>
  );
});

export default Inbox;
