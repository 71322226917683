import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import { Typography, Button, Box, makeStyles } from '@material-ui/core';
import { fetchBusinessSiiFacturas } from '../../actions/invoiceAction';
import { fetchFinderInvoicesBy } from '../../actions/businessAction';
import {
  checkLastTaxScrapping,
  statusUpdateData,
  startManualScrappe,
} from '../../actions/siiAction';
import SearchBar from './SearchBar';
import Container from './Container';
import FacturasTableBusiness from './FacturasTableBusiness';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import Panel from './Panel';
import { COUNTRY_CODE_MX } from '../../helpers/Constants';
import { features } from '../../config/features';
import { settings } from '../../config/settings';

const useStyles = makeStyles({
  containerRoot: {
    padding: convertSpacingToCss('lg'),
  },
  reloadButton: {
    marginLeft: 20,
  },
  containerActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  containerMessageEmpty: {
    paddingTop: 30,
    paddingBottom: 40,
  },
});

const InvoicesFinder = ({ id: idBusiness, tab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const siiFacturas = useSelector(state => state.invoice.siiFacturas);
  const {
    businessFinderInvoices: invoices,
    finderInvoicesPagination: pagination,
    fetchBusinessFinderInvoicesLoading,
  } = useSelector(state => state.business);
  const { jobProgress, jobState } = useSelector(state => state.tax);
  const {
    loadPercentage,
    loadStatus,
    businessId: wsBusinessId,
    jobId,
  } = useSelector(state => state.ws);
  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];
  const rut = useSelector(state => state.business.business.rut);
  const businessId = useSelector(state => state.business.business.id);
  const [facturas, setFacturas] = useState(siiFacturas || invoices || []);
  const [progress, setProgress] = useState(0);
  const [showReload, setShowReload] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [search, setSearch] = useState(null);
  const [field, setField] = useState(null);
  const [page, setPage] = useState(1);
  const cleanStringFolios = folio => {
    return folio ? folio.split(' ').join('') : '';
  };
  const searchBarOptions =
    country === COUNTRY_CODE_MX
      ? [
          { title: 'UUID', field: 'uuid' },
          {
            title: 'Folio',
            field: 'folio',
          },
        ]
      : [
          {
            title: 'Folio',
            field: 'folio',
          },
        ];
  const defaultSearchBarOption =
    country === COUNTRY_CODE_MX
      ? { title: 'UUID', field: 'uuid' }
      : {
          title: 'Folio',
          field: 'folio',
        };
  useEffect(() => {
    setFacturas(siiFacturas);
  }, [siiFacturas]);

  useEffect(() => {
    setFacturas(invoices);
  }, [invoices]);

  useEffect(() => {
    // Limpia las facturas
    setFacturas([]);
  }, [idBusiness]);

  useEffect(() => {
    if (wsBusinessId === idBusiness) {
      setProgress(loadPercentage);
      if (loadStatus === 'SUCCESS' || loadStatus === 'FAILED') {
        if (tab === 'invoicesFinder') {
          const statusUpdate = loadStatus === 'SUCCESS';
          dispatch(statusUpdateData(statusUpdate));
        }
        setTimeout(() => {
          setShowReload(false);
          setEnableButton(false);
          setProgress(0);
        }, 1000);
      } else {
        setEnableButton(true);
        setShowReload(true);
      }
    }
    // eslint-disable-next-line
  }, [loadPercentage, loadStatus, jobId]);

  useEffect(() => {
    setProgress(jobProgress);
    if (jobState === 'SUCCESS' || jobState === 'FAILED') {
      if (jobState === 'FAILED' && tab === 'invoicesFinder') {
        dispatch(statusUpdateData(false));
      }
      setShowReload(false);
      setEnableButton(false);
      setProgress(0);
    } else {
      setEnableButton(true);
      setShowReload(true);
    }
  }, [jobProgress, jobState, tab, dispatch]);

  useEffect(() => {
    dispatch(checkLastTaxScrapping(idBusiness));
  }, [idBusiness, tab, dispatch]);

  const handleStartManualScrapping = () => {
    setEnableButton(true);
    dispatch(startManualScrappe(rut, idBusiness));
    setShowReload(true);
    if (jobState === 'SUCCESS') {
      setShowReload(false);
      setEnableButton(false);
      setProgress(0);
    }
  };

  const searcher = ({ page, searchInput, field, limit }) => {
    dispatch(
      fetchFinderInvoicesBy({
        page,
        searchInput,
        field,
        limit,
        businessId,
      })
    );
  };

  const handleChangePage = (page, limit) => {
    setPage(page);
    searcher({
      page,
      searchInput: search,
      field,
      limit,
    });
  };

  const changeSearchInput = ({ value, field }) => {
    const valueClean = cleanStringFolios(value);
    setField(field);
    setSearch(valueClean);
    if (valueClean) {
      if (isApiGlobal) {
        searcher({
          page,
          searchInput: valueClean,
          field,
        });
      } else {
        dispatch(fetchBusinessSiiFacturas(idBusiness, valueClean));
      }
    }
  };
  const countryFeatures = features[country];

  return (
    <Container className={classes.containerRoot}>
      <Panel
        title="Lista de facturas"
        variant="section"
        titlePadding="lg"
        contentPadding="zero"
        actions={
          <Box className={classes.containerActions}>
            <SearchBar
              placeholder="Buscar factura..."
              handleChangeInput={value => changeSearchInput(value)}
              selectedOption={defaultSearchBarOption}
              fields={searchBarOptions}
            />
            {countryFeatures.businessDetailsSection
              .showInvoicesFinderScrappingButton && (
              <Button
                variant="contained"
                color="primary"
                className={classes.reloadButton}
                startIcon={!enableButton ? <SyncIcon /> : <SyncDisabledIcon />}
                onClick={handleStartManualScrapping}
                disabled={enableButton}
              >
                Cargar Facturas
              </Button>
            )}
            {showReload && (
              <CircularProgressWithLabel
                value={progress}
                size={38}
                thickness={5}
                variant="determinate"
              />
            )}
          </Box>
        }
      >
        {facturas.length ? (
          <FacturasTableBusiness
            facturas={facturas}
            loading={fetchBusinessFinderInvoicesLoading}
            handleChangePage={handleChangePage}
            pagination={pagination}
          />
        ) : (
          <Box className={classes.containerMessageEmpty}>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              align="center"
            >
              Ingresa uno o más valores para iniciar la búsqueda
            </Typography>
          </Box>
        )}
      </Panel>
    </Container>
  );
};

InvoicesFinder.propTypes = {
  id: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default InvoicesFinder;
