import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { deleteOrderInvoicePdfService } from '../services';

export const useDeleteOrderInvoicePdf = () => {
  const dispatch = useDispatch();
  const {
    isLoading: deleteOrderInvoicePdfLoading,
    isSuccess: deleteOrderInvoicePdfSuccess,
    mutate: deleteOrderInvoicePdf,
  } = useMutation(
    ({ invoiceId, pdfId }) => {
      return deleteOrderInvoicePdfService(invoiceId, pdfId);
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FILE_DELETED, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.ERROR.DELETE_INVOICE_PDF, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    deleteOrderInvoicePdfLoading,
    deleteOrderInvoicePdfSuccess,
    deleteOrderInvoicePdf,
  };
};

export default useDeleteOrderInvoicePdf;
