import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  DATE_DB_FORMAT,
  DATETIME_FORMAT_NO_SECONDS,
} from './Constants';

export const setHourString = value => {
  let date = moment(value)
    .utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  date = date.format();
  return date;
};

export const dateFormatter = date => {
  return date ? moment.utc(date).format(DATE_FORMAT) : '';
};

export const dateDBFormatter = date => {
  return date ? moment.utc(date).format(DATE_DB_FORMAT) : '';
};

export const dateWithoutFormatter = date => {
  return date ? moment(date).utc() : null;
};

export const dateWithoutFormatterStartOfDay = date => {
  return date ? moment(date).utc().startOf('day') : null;
};

export const datetimeFormatter = date => {
  return date ? moment.utc(date).format(DATETIME_FORMAT) : '';
};

export const datetimeFormatterWithTimezone = (date, timezone = 'UTC') => {
  return date
    ? momentTz.utc(date).tz(timezone).format(DATETIME_FORMAT_NO_SECONDS)
    : '';
};

export const differenceBetweenDate = (dateA, dateB, unit = 'days') => {
  const a = moment(dateA).utc();
  const b = moment(dateB).utc();
  return b.diff(a, unit);
};

export const getFirstBusinessDayFrom = (startDate, holidays = []) => {
  if (!startDate) return startDate;

  const dateToEvaluate = moment(startDate);
  const isHoliday = holidays.some(h =>
    dateToEvaluate.isSame(moment(h.date), 'day')
  );
  if (
    dateToEvaluate.isoWeekday() !== 6 &&
    dateToEvaluate.isoWeekday() !== 7 &&
    !isHoliday
  ) {
    return startDate;
  }
  return getFirstBusinessDayFrom(
    dateToEvaluate.add(1, 'day').format('YYYY-MM-DD')
  );
};

export const isWeekend = date => {
  const dayOfWeek = date.format('E');
  return dayOfWeek === '6' || dayOfWeek === '7';
};

export const getDay = date => {
  return moment(date).format('DD');
};

export const timeSinceCalculated = date => {
  const dateSeconds = Math.floor((new Date() - date) / 1000);

  const intervals = [
    { seconds: 31536000, label: 'año' },
    { seconds: 2592000, label: 'mes' },
    { seconds: 86400, label: 'día' },
    { seconds: 3600, label: 'hora' },
  ];

  const intervalFound = intervals.find(
    interval => dateSeconds / interval.seconds > 1
  );

  if (intervalFound) {
    const { seconds, label } = intervalFound;

    const calcDiff = Math.floor(dateSeconds / seconds);

    let dateLabel = label;

    if (calcDiff > 1) {
      if (label === 'mes') {
        dateLabel = `${label}es`;
      } else {
        dateLabel = `${label}s`;
      }
    }

    return `Calculado hace ${calcDiff} ${dateLabel}`;
  }

  return 'Calculado hace menos de 1 hora';
};
