import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Link, makeStyles } from '@material-ui/core';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import imgCheckbox from '../../../../../../assets/check.svg';

const useStyles = makeStyles({
  sendButton: {
    marginBottom: 15,
  },
});

export const FundSuccessPayrollDialog = ({
  isOpen,
  handleCloseDialog,
  handleCloseManageInvoice,
}) => {
  const classes = useStyles();

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseManageInvoice}
      title="Nómina enviada"
      description="Si deseas enviar la misma nómina a otro fondo haz click en Reenviar"
      topIcon={imgCheckbox}
      topIconWidth={41}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseDialog}
          className={classes.sendButton}
          fullWidth
        >
          Reenviar
        </Button>
        <Link
          component="button"
          variant="body1"
          color="primary"
          onClick={handleCloseManageInvoice}
        >
          <Box fontWeight="fontWeightBold">No, gracias</Box>
        </Link>
      </Box>
    </BaseDialog>
  );
};

FundSuccessPayrollDialog.defaultProps = {
  isOpen: false,
};

FundSuccessPayrollDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  handleCloseManageInvoice: PropTypes.func.isRequired,
};
