import { useState, useCallback } from 'react';

import { purgeNestedObj } from '../../utils';
import { countAppliedFilters } from '../../utils/filter';

export const useFormSummary = ({ onSubmit, form }) => {
  const [formState, setFormState] = useState(form);
  const [summary, setSummary] = useState(form);

  const handleSetFormState = e => {
    const { name: inputName, value } = e.target;
    setFormState({ ...formState, [inputName]: value });
  };

  const resetForm = (fieldName, index = null) => {
    if (fieldName) {
      if (index !== null) {
        setFormState({
          ...formState,
          [fieldName]: formState[fieldName].filter((_, idx) => idx !== index),
        });
        setSummary({
          ...summary,
          [fieldName]: summary[fieldName].filter((_, idx) => idx !== index),
        });

        return;
      }
      setFormState({ ...formState, [fieldName]: '' });
      setSummary({ ...summary, [fieldName]: '' });

      return;
    }

    const emptyFormState = Object.keys(formState).reduce((acc, field) => {
      acc[field] = Array.isArray(formState[field]) ? undefined : '';

      return acc;
    }, {});

    setSummary({});
    setFormState(emptyFormState);
  };

  const handleSubmit = useCallback(() => {
    const purgeFrm = purgeNestedObj(formState);
    Object.keys(purgeFrm).forEach(key => {
      if (Array.isArray(purgeFrm[key])) {
        purgeFrm[key] = purgeFrm[key].filter(value => value !== '');
      }
    });

    onSubmit({ form: purgeFrm, appliedFilters: countAppliedFilters(purgeFrm) });
  }, [formState, onSubmit]);

  return {
    formState,
    handleSetFormState,
    handleSubmit,
    resetForm,
    summary,
  };
};
