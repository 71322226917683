import {
  Box,
  Drawer as DrawerMui,
  Typography,
  styled,
} from '@material-ui/core';

export const Drawer = styled(DrawerMui)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});

export const ContainerRoot = styled(Box)({
  width: 566,
});

export const ContainerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #CDCAD8',
  margin: '36px 36px 20px',
});

export const Title = styled(Typography)({
  fontSize: 19.5,
  fontWeight: 'bold',
  color: '#44414C',
});

export const ContainerBody = styled(Box)({
  height: 'calc(100vh - 193px)',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 193px)',
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#44414C',
    borderRadius: 4,
  },
});

export const ContainerFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: 36,
});

export const EmptyText = styled(Typography)({
  fontSize: 14,
  textAlign: 'center',
});
