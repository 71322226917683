
/* eslint-disable @typescript-eslint/naming-convention */
import { Country } from '../../schemas';

export const getLinkPymeEncoded = (country: Country, identifier: string) => {
  let url = '';
  let params = {};

  if (country === 'MX') {
    params = {
      'ds27.rfcCliente': identifier,
      'ds26.rfcCliente': identifier,
      'ds0.rfcCliente': identifier,
      'ds35.rfcPagador': '',
      'ds123.rfcCliente': identifier,
      'ds2.rfcCliente': identifier,
      'ds33.rfcCliente': identifier,
      'ds33.rfcPagador': '',
      'ds1.orderId': '',
      'ds1.rfcCliente': identifier,
      'ds34.rfcPagador': '',
      'ds168.rfcCliente': identifier,
      'ds169.rfcCliente': identifier,
      'ds167.rfcCliente': identifier,
      'ds167.rfcPagador': '',
    };

    const paramsAsString = JSON.stringify(params);
    const encodedParams = encodeURIComponent(paramsAsString);

    url = 'https://datastudio.google.com/u/0/reporting/fda05245-3aa3-4d35-a743-97f0eb648710/page/p_w51c0hcivc?params=' + encodedParams;
  }

  if (country === 'CL') {
    params = {
      'ds46.RutCliente': identifier,
      'ds70.RutCliente': identifier,
      'ds69.RutCliente': identifier,
      'ds84.RutCliente': identifier,
      'ds5.RutCliente': identifier,
      'ds25.RutCliente': identifier,
      'ds13.RutCliente': identifier,
      'ds49.RutCliente': identifier,
      'ds73.RutCliente': identifier,
      'ds40.RutCliente': identifier,
      'ds87.RutCliente': identifier,
      'ds20.RutCliente': identifier,
      'ds33.RutCliente': identifier,
      'ds78.RutCliente': identifier,
      'ds1.RutCliente': identifier,
      'ds38.RutCliente': identifier,
      'ds35.RutCliente': identifier,
      'ds42.RutCliente': identifier,
    };

    const paramsAsString = JSON.stringify(params);
    const encodedParams = encodeURIComponent(paramsAsString);

    url = 'https://datastudio.google.com/u/0/reporting/ee31219f-e777-4b16-8dd1-4870fbdeb6a7/page/TsqjC?params=' + encodedParams;
  }

  return url;
};
