import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  makeStyles,
  IconButton,
  Button,
  Menu,
  Checkbox,
  FormControlLabel,
  Switch,
  Tooltip,
  CircularProgress,
  Badge,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  downloadDocument,
  updateDocument,
  updateSignedDocument,
  cancelMiFiel,
  resetDocumentErrors,
  updateElectronicSignatureDocument,
  fetchStatusSignatories,
} from '../../actions/documentActions';
import { downloadInvoiceDocuments } from '../../actions/orderActions';
import UploadFileDialog from '../dialogs/UploadFileDialog';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import ReviewDialog from '../dialogs/ReviewDialog';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { toBase64 } from '../../helpers/fileToBase64';
import MenuItem from '../elements/MenuItem';
import LoadingButton from '../elements/LoadingButton';
import {
  Warning as WarningIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  More as MenuIcon,
  EyeOpen as EyeIcon,
  Check as DoneIcon,
  Timer as TimerIcon,
  CircleUnchecked as CircleUncheckedIcon,
  CircleChecked as CircleCheckedIcon,
  Pdf as PdfIcon,
  Down as DownIcon,
  File as FileIcon,
} from '../icons';
import VisualizePdfDialog from '../dialogs/VisualizePdfDialog';
import {
  SIGNATURE_TYPES,
  UPLOAD,
  SIGN,
  MIFIEL_ON,
  MIFIEL_OFF,
  DOWNLOAD,
  MIFIEL,
  BACKUP,
  MIMETYPE_IMG_DOC_XLSX_XML,
  REJECTED_DOC_REASONS,
  SIGNED_DOCUMENT,
  REVIEW_DOCUMENT,
  SIGNABLE_DOCUMENT,
  ELECTRONIC_SIGNATURE_DOCUMENT,
  INVOICE_DOCUMENT,
  COUNTRY_CODE_CL,
  CYA_CREDIT_INVOICE_DOCUMENT,
} from '../../helpers/Constants';
import { features } from '../../config/features';
import UploadElectronicSignatureDrawer from '../drawers/UploadElectronicSignatureDrawer';
import StatusSignatoriesDrawer from '../drawers/StatusSignatoriesDrawer';
import { isLegalDocument } from '../../helpers/validation/documents';
import { downloadCYACreditInvoiceDocuments } from '../../actions/creditsActions';

const BUSINESS = 'business';
const ORDER = 'order';
const STAKEHOLDER = 'stakeholder';
const CREDIT = 'credit';

const useStyles = makeStyles(theme => ({
  docTemplateItem: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%',
    padding: convertSpacingToCss('sm zero'),
  },
  actionButton: {
    marginLeft: 5,
  },
  docRoot: {
    alignItems: 'center',
  },
  icon: {
    padding: 5,
  },
  containerMenuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  switchEfirmaRoot: {
    margin: 0,
  },
  switchEfirma: {
    marginLeft: -4,
  },
  list: {
    marginTop: 0,
    paddingLeft: 20,
    marginBottom: 0,
  },
  containerButtons: {
    marginBottom: 10,
  },
  loadingButton: {
    marginRight: 15,
  },
  badgeStatusSuccess: {
    backgroundColor: theme.palette.success.main,
    left: 0,
  },
  badgeStatusPending: {
    backgroundColor: theme.palette.warning.main,
    left: 0,
  },
  iconButtonSignatureType: {
    marginRight: 5,
  },
}));

const DocumentsTable = ({
  documents,
  documentsAreLoading,
  documentableType,
  documentableId,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    fileDocumentWasUpdate,
    updateFileDocumentsIsLoading,
    updateFileDocumentsError,
    updateFileDocumentId,
    updateSignedFileDocumentIsLoading,
    signedfileDocumentWasUpdate,
    documentDownloadKey,
    downloadDocumentsIsLoading,
    documentWasApproveReject,
    approveRejectDocumentIsLoading,
    approveRejectDocumentError,
    documentWasCancel,
    cancelMifielDocumentIsLoading,
    cancelMifielDocumentError,
    downloadDocumentsError,
    updateElectronicSignatureDocumentIsLoading,
    electronicSignatureDocumentWasUpdate,
    updateElectronicSignatureDocumentError,
    electronicSignatureSignatories,
    fetchElectronicSignatureSignatoriesIsLoading,
  } = useSelector(state => state.documents);
  const { country } = useSelector(state => state.config);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSignatureType, setAnchorElSignatureType] = useState(null);
  const [documentSelected, setDocumentSelected] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCancelEfirmaDialog, setShowCancelEfirmaDialog] = useState(false);
  const [showUploadDocumentDialog, setShowUploadDocumentDialog] =
    useState(false);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [showVisualizePdfDialog, setShowVisualizePdfDialog] = useState(false);
  const [showDrawerElectronicSignature, setShowDrawerElectronicSignature] =
    useState(false);
  const [showDrawerSignatories, setShowDrawerSignatories] = useState(false);

  const countryFeatures = features[country];

  const [uploadType, setUploadType] = useState(null);

  useEffect(() => {
    if (documentWasApproveReject) {
      setShowReviewDialog(false);
    }
  }, [documentWasApproveReject]);

  useEffect(() => {
    if (documentWasCancel && fileDocumentWasUpdate) {
      setShowCancelEfirmaDialog(false);
    }
  }, [documentWasCancel, fileDocumentWasUpdate]);

  useEffect(() => {
    if (signedfileDocumentWasUpdate) {
      setShowUploadDocumentDialog(false);
    }
  }, [signedfileDocumentWasUpdate]);

  useEffect(() => {
    if (fileDocumentWasUpdate) {
      setShowConfirmDialog(false);
    }
  }, [fileDocumentWasUpdate]);

  useEffect(() => {
    if (electronicSignatureDocumentWasUpdate) {
      setShowDrawerElectronicSignature(false);
    }
  }, [electronicSignatureDocumentWasUpdate]);

  const handleChangeStatusDocuments = document => {
    const { id: documentId, active } = document;
    dispatch(
      updateDocument(documentableId, documentableType, documentId, {
        active: !active,
      })
    );
  };

  const handleDownloadFile = (
    document,
    { type = 'signed', fileExtension } = {}
  ) => {
    setDocumentSelected(document);
    if (document.documentableType === INVOICE_DOCUMENT) {
      dispatch(
        downloadInvoiceDocuments(
          documentableId,
          document.documentableId,
          fileExtension
        )
      );
    } else if (document.documentableType === CYA_CREDIT_INVOICE_DOCUMENT) {
      dispatch(
        downloadCYACreditInvoiceDocuments(
          document.documentableId,
          fileExtension
        )
      );
    } else {
      dispatch(
        downloadDocument(documentableId, documentableType, document, type)
      );
    }
  };

  const handleSubmitUploadDocument = async (
    files,
    { type = SIGNED_DOCUMENT, signatories } = {}
  ) => {
    const fileBase64 = await toBase64(files[0].file);

    switch (type) {
      case SIGNABLE_DOCUMENT:
        dispatch(
          updateDocument(
            documentableId,
            documentableType,
            documentSelected.id,
            {
              file: fileBase64,
            }
          )
        );
        break;
      case SIGNED_DOCUMENT:
        dispatch(
          updateSignedDocument(
            documentableId,
            documentableType,
            documentSelected.id,
            fileBase64,
            true
          )
        );
        break;
      case ELECTRONIC_SIGNATURE_DOCUMENT:
        dispatch(
          updateElectronicSignatureDocument(
            documentableId,
            documentableType,
            documentSelected.id,
            {
              file: fileBase64,
            },
            signatories
          )
        );
        break;

      case REVIEW_DOCUMENT:
        dispatch(
          updateSignedDocument(
            documentableId,
            documentableType,
            documentSelected.id,
            fileBase64,
            false
          )
        );
        break;

      default:
        break;
    }
  };

  const handleChangeSwitchAction = ({ target }, documentId) => {
    const { checked } = target;
    const action = checked ? MIFIEL_ON : MIFIEL_OFF;
    dispatch(
      updateDocument(documentableId, documentableType, documentId, {
        action,
      })
    );
  };

  const itemSelected = (e, rowData = {}) => {
    setDocumentSelected(rowData);
    setAnchorEl(e.currentTarget);
  };

  const resetErrors = () => {
    dispatch(resetDocumentErrors(documentableType));
  };

  const isValidExpirationDate = date => {
    if (!date) return true;
    return !moment().isAfter(date);
  };

  const getClassNameByStatusSignatory = documentData => {
    if (documentData.approved && documentData.signedDocument) {
      return classes.badgeStatusSuccess;
    }
    if (documentData.eDocument) {
      return classes.badgeStatusPending;
    }
    return null;
  };

  const documentMiField = document => {
    const { action, eDocument } = document;

    if (action === MIFIEL_ON && eDocument) {
      return (
        <Badge
          classes={{
            badge: getClassNameByStatusSignatory(document),
          }}
          invisible={false}
        />
      );
    }

    return null;
  };

  const documentAction = documentForAction => {
    const {
      documentTypeId,
      action,
      active,
      eDocument,
      signedDocument,
      approved,
      id,
    } = documentForAction;

    if (active) {
      if (approved) {
        return (
          <Button
            disabled={downloadDocumentsIsLoading}
            color="inherit"
            startIcon={
              downloadDocumentsIsLoading &&
              id === documentSelected.id &&
              documentDownloadKey === `${documentableType}${documentableId}` ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <DownloadIcon />
              )
            }
            onClick={() => handleDownloadFile(documentForAction)}
          >
            Descargar documento
          </Button>
        );
      }
      if (signedDocument) {
        return (
          <Button
            color="primary"
            startIcon={<EyeIcon />}
            onClick={() => {
              setDocumentSelected({
                ...documentForAction,
                isLegal: isLegalDocument(country, documentTypeId),
              });
              setShowReviewDialog(true);
            }}
          >
            Revisar documento
          </Button>
        );
      }
      if (eDocument) {
        return (
          <Button color="inherit" disabled startIcon={<TimerIcon />}>
            {action === UPLOAD ? 'Esperando documento' : 'Esperando E-firma'}
          </Button>
        );
      }

      // default states
      switch (action) {
        case UPLOAD:
          return (
            <Button color="inherit" startIcon={<TimerIcon />} disabled>
              Esperando documento
            </Button>
          );
        case MIFIEL_ON:
          return (
            <Button
              color="primary"
              startIcon={<UploadIcon />}
              onClick={() => {
                setDocumentSelected(documentForAction);
                setShowDrawerElectronicSignature(true);
              }}
            >
              Subir para E-firma
            </Button>
          );
        case MIFIEL_OFF:
        case SIGN:
        case DOWNLOAD:
        case BACKUP:
          return (
            <Button
              color="primary"
              startIcon={<UploadIcon />}
              onClick={() => {
                setDocumentSelected(documentForAction);
                setShowUploadDocumentDialog(true);
                setUploadType(REVIEW_DOCUMENT);
              }}
            >
              Subir documento final
            </Button>
          );
        default:
          break;
      }
    }

    return null;
  };

  const documentType = documentForType => {
    const { action, active, id, approved, eDocument, signedDocument } =
      documentForType;

    if (action === BACKUP) {
      return (
        <Typography variant="body2" color="textPrimary">
          -
        </Typography>
      );
    }
    if (action === UPLOAD) {
      return (
        <Typography variant="body2" color="textPrimary">
          Solo subida
        </Typography>
      );
    }
    if (action === DOWNLOAD) {
      return (
        <Typography variant="body2" color="textPrimary">
          Solo bajada
        </Typography>
      );
    }
    if (action.startsWith('MIFIEL_')) {
      return (
        <Tooltip
          title={
            action === MIFIEL_ON && eDocument && !signedDocument && active
              ? 'Debes cancelar la E-firma'
              : ''
          }
        >
          <FormControlLabel
            disabled={approved || (action === MIFIEL_ON && eDocument)}
            control={
              <Switch
                checked={action.includes('ON')}
                onChange={e => handleChangeSwitchAction(e, id)}
                size="small"
                color="primary"
                name="checkedTipo"
                className={classes.switchEfirma}
              />
            }
            label="E-firma"
            className={classes.switchEfirmaRoot}
          />
        </Tooltip>
      );
    }
    if (action === SIGN) {
      return (
        <FormControlLabel
          disabled
          control={
            <Switch
              size="small"
              checked={false}
              name="checkedTipo"
              className={classes.switchEfirma}
            />
          }
          label="E-firma"
          className={classes.switchEfirmaRoot}
        />
      );
    }
    return null;
  };

  const CUSTOM_DOCUMENTS = [
    {
      documentableType: 'business',
      title: 'Comprobante de domicilio de la empresa',
      customDescription: (
        <Typography variant="body2" color="textPrimary">
          A nombre de la empresa, no mayor a 3 meses y domicilio completo.
          <ul className={classes.list}>
            <li>
              <Box fontWeight="bold" display="inline ">
                Comprobantes aceptados:{' '}
              </Box>
              Recibo de luz CFE, recibo de telefonía celular, recibo de Gas
              Natural, teléfono fijo o internet (Izzi, Axtel, Telmex, Totalplay,
              etc.), boleta predial y recibo de agua.
            </li>
            <li>
              <Box fontWeight="bold" display="block">
                Documentos NO aceptados:{' '}
              </Box>
              Estados de cuenta bancarios, documentos no legibles y facturas.
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      documentableType: 'stakeholder',
      title: 'Comprobante Domicilio',
      customDescription: (
        <Typography variant="body2" color="textPrimary">
          A nombre del representante legal, no mayor a 3 meses y domicilio
          completo.
          <ul className={classes.list}>
            <li>
              <Box fontWeight="bold" display="inline ">
                Comprobantes aceptados:{' '}
              </Box>
              Recibo de luz CFE, recibo de telefonía celular, recibo de Gas
              Natural, teléfono fijo o internet (Izzi, Axtel, Telmex, Totalplay,
              etc.), boleta predial y recibo de agua.
            </li>
            <li>
              <Box fontWeight="bold" display="block">
                Documentos NO aceptados:{' '}
              </Box>
              Estados de cuenta bancarios, documentos no legibles y facturas.
            </li>
          </ul>
        </Typography>
      ),
    },
  ];

  const getIcon = document => {
    if (!isValidExpirationDate(document.expirationDate)) {
      return (
        <Tooltip
          title="El documento ha expirado, por favor intente de nuevo con un documento vigente."
          placement="top"
          arrow
        >
          <span>
            <WarningIcon fontSize="large" />
          </span>
        </Tooltip>
      );
    }

    if (document.approved) {
      return (
        <Box color="success.main" display="inline-flex">
          <DoneIcon color="inherit" />
        </Box>
      );
    }

    if (!document.signedDocument && document.descriptionRejected) {
      const reasonDict = REJECTED_DOC_REASONS[country].find(
        o => o.value === document.descriptionRejected
      );
      return (
        <Tooltip
          title={reasonDict ? reasonDict.label : document.descriptionRejected}
          placement="top"
          arrow
        >
          <span>
            <WarningIcon fontSize="large" />
          </span>
        </Tooltip>
      );
    }
    return null;
  };

  const documentExpirationDays = document => {
    if (document.expirationDays) {
      if (document.expirationDays > 1) {
        return (
          <Typography
            variant="body2"
            color="textPrimary"
          >{`${document.expirationDays} Días`}</Typography>
        );
      }
      if (document.expirationDays === 1) {
        return (
          <Typography
            variant="body2"
            color="textPrimary"
          >{`${document.expirationDays} Día`}</Typography>
        );
      }
    }
    if (document.expirationDays === 0) {
      return <Typography color="error">Vencido</Typography>;
    }

    return '-';
  };

  const columns = [
    {
      title: 'Habilitar',
      field: 'active',
      type: 'boolean',
      align: 'left',
      width: '50px',
      render: document => {
        const {
          active,
          action,
          fileDocument,
          signedDocument,
          hiddenToggleEnableDocument,
        } = document;
        return (
          <Checkbox
            icon={<CircleUncheckedIcon />}
            checkedIcon={<CircleCheckedIcon />}
            indeterminateIcon={<CircleUncheckedIcon />}
            checked={active}
            disabled={
              (action === MIFIEL_ON && fileDocument && !signedDocument) ||
              hiddenToggleEnableDocument
            }
            onChange={() => {
              setDocumentSelected(document);
              setShowConfirmDialog(true);
            }}
          />
        );
      },
    },
    {
      title: 'Documento',
      field: 'title',
      width: '290px',
      render: document => (
        <Typography variant="body2" color="textPrimary">
          {document.title}
        </Typography>
      ),
    },
    {
      title: 'Descripción',
      field: 'description',
      width: '400px',
      render: document => {
        const customDocument = CUSTOM_DOCUMENTS.find(
          customDocument =>
            customDocument.documentableType === document.documentableType &&
            customDocument.title === document.title
        );
        let description = document.description ?? '-';

        if (customDocument) {
          description = customDocument.customDescription;
        }
        return (
          <Typography variant="body2" color="textPrimary">
            {description}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo de firma',
      type: 'string',
      width: '150px',
      render: document => {
        const signatureTypeFound = SIGNATURE_TYPES[country].find(
          type => type.value === document?.signatureType
        );
        return (
          <Box display="flex" alignItems="center">
            <IconButton
              disabled={
                !document.active ||
                updateFileDocumentsIsLoading ||
                document.documentableType === INVOICE_DOCUMENT ||
                document.documentableType === CYA_CREDIT_INVOICE_DOCUMENT
              }
              onClick={e => {
                setDocumentSelected(document);
                setAnchorElSignatureType(e.currentTarget);
              }}
              className={classes.iconButtonSignatureType}
            >
              <DownIcon />
            </IconButton>

            {updateFileDocumentId === document.id &&
            updateFileDocumentsIsLoading ? (
              <Skeleton variant="rect" width={35} height={18} />
            ) : (
              <Typography variant="body2" color="textPrimary">
                {signatureTypeFound?.label || '-'}
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      width: '43px',
      sorting: false,
      hidden: country === COUNTRY_CODE_CL,
      render: document => documentMiField(document),
    },
    {
      title: 'Tipo',
      field: 'action',
      type: 'string',
      width: '150px',
      render: document => documentType(document),
    },
    {
      width: '240px',
      sorting: false,
      render: document => documentAction(document),
    },
    {
      title: 'Revisado',
      field: 'approved',
      type: 'boolean',
      width: '80px',
      sorting: false,
      render: document => getIcon(document),
    },
    {
      title: 'Vence en',
      field: 'expirationDays',
      type: 'string',
      width: '112px',
      sorting: false,
      render: document => documentExpirationDays(document),
    },
    {
      sorting: false,
      width: '62px',
      align: 'right',
      render: document =>
        document.active && (
          <IconButton onClick={e => itemSelected(e, document)}>
            <MenuIcon />
          </IconButton>
        ),
    },
  ];

  return (
    <>
      <MaterialTable
        style={{ width: '100%', boxShadow: 'none' }}
        columns={columns}
        data={documents}
        isLoading={documentsAreLoading}
        options={{
          emptyRowsWhenPaging: false,
          sorting: false,
          pageSize: 100,
        }}
        components={{
          Toolbar: () => null,
          Pagination: () => null,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No hay documentos para mostrar.',
          },
        }}
      />
      <Menu
        id="long-menu-documents"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {documentSelected.action !== MIFIEL_ON ? (
          <MenuItem
            key="uploadreview"
            icon={<UploadIcon />}
            onClick={() => {
              setShowUploadDocumentDialog(true);
              setAnchorEl(null);
              setUploadType(REVIEW_DOCUMENT);
            }}
            disabled={
              documentSelected?.documentableType === INVOICE_DOCUMENT ||
              documentSelected?.documentableType === CYA_CREDIT_INVOICE_DOCUMENT
            }
          >
            Subir documento para revisión
          </MenuItem>
        ) : (
          <MenuItem key="textInformationMifielOn" disabled>
            Desactiva la e-firma para subir el archivo final
          </MenuItem>
        )}

        {documentSelected.action === MIFIEL_ON &&
          documentSelected.active &&
          documentSelected.eDocument && (
            <MenuItem
              key="showSignatoriesDrawer"
              onClick={() => {
                setShowDrawerSignatories(true);
                dispatch(fetchStatusSignatories(documentSelected?.id));
                setAnchorEl(null);
              }}
              icon={<FileIcon />}
            >
              Verificar estado
            </MenuItem>
          )}

        {documentSelected.action === MIFIEL_ON &&
          documentSelected.active &&
          documentSelected.eDocument &&
          !documentSelected.signedDocument && (
            <MenuItem
              key="cancelmifiel"
              onClick={() => {
                setShowCancelEfirmaDialog(true);
                setAnchorEl(null);
              }}
              icon={<EyeIcon />}
            >
              Cancelar E-firma
            </MenuItem>
          )}

        {documentSelected.action === UPLOAD &&
          documentSelected.signedDocument &&
          documentSelected.signedDocument?.includes('.pdf') && (
            <MenuItem
              key="visulizePdf"
              icon={<PdfIcon />}
              onClick={() => {
                setShowVisualizePdfDialog(true);
                setAnchorEl(null);
              }}
            >
              Ver Documento
            </MenuItem>
          )}
        {documentSelected?.documentableType === INVOICE_DOCUMENT &&
          countryFeatures.documentActions.downloadInvoiceDocumentXML && (
            <MenuItem
              key="downloadInvoiceDocumentXML"
              onClick={() => {
                handleDownloadFile(documentSelected, { fileExtension: 'xml' });
                setAnchorEl(null);
              }}
              icon={<DownloadIcon />}
            >
              Descargar XML
            </MenuItem>
          )}
        {documentSelected?.documentableType === CYA_CREDIT_INVOICE_DOCUMENT &&
          countryFeatures.documentActions.downloadInvoiceDocumentXML && (
            <MenuItem
              key="downloadInvoiceDocumentXML"
              onClick={() => {
                handleDownloadFile(documentSelected, { fileExtension: 'xml' });
                setAnchorEl(null);
              }}
              icon={<DownloadIcon />}
            >
              Descargar XML
            </MenuItem>
          )}
      </Menu>

      <Menu
        id="menu-signature-type"
        anchorEl={anchorElSignatureType}
        keepMounted
        open={Boolean(anchorElSignatureType)}
        onClose={() => setAnchorElSignatureType(null)}
      >
        {SIGNATURE_TYPES[country].map(signatureType => (
          <MenuItem
            key={signatureType.value}
            onClick={() => {
              dispatch(
                updateDocument(
                  documentableId,
                  documentableType,
                  documentSelected?.id,
                  {
                    signatureType: signatureType.value,
                  }
                )
              );
              setAnchorElSignatureType(null);
            }}
          >
            {signatureType.label}
          </MenuItem>
        ))}
      </Menu>

      {showConfirmDialog && (
        <ConfirmDialog
          isOpen={showConfirmDialog}
          handleClose={() => setShowConfirmDialog(false)}
          title={
            !documentSelected.active
              ? 'Habilitar documento'
              : 'Deshabilitar documento'
          }
          subtitle={`¿Estas seguro de querer ${
            !documentSelected.active ? 'habilitar' : 'deshabilitar'
          } el documento?`}
          buttonOnAccept={() => handleChangeStatusDocuments(documentSelected)}
          buttonLabel="Continuar"
          loading={updateFileDocumentsIsLoading}
          error={updateFileDocumentsError}
          resetErrors={resetErrors}
        />
      )}

      {showUploadDocumentDialog && (
        <UploadFileDialog
          open={showUploadDocumentDialog}
          handleCloseDialog={() => setShowUploadDocumentDialog(false)}
          title="Subir documento para revisión"
          description="Al subir un documento nuevo reemplazarás el ya existente"
          handleSubmit={handleSubmitUploadDocument}
          isLoading={
            updateFileDocumentsIsLoading || updateSignedFileDocumentIsLoading
          }
          accept={MIMETYPE_IMG_DOC_XLSX_XML}
          uploadType={uploadType}
          error={updateFileDocumentsError}
          resetErrors={resetErrors}
        />
      )}
      {showCancelEfirmaDialog && (
        <ConfirmDialog
          isOpen={showCancelEfirmaDialog}
          handleClose={() => setShowCancelEfirmaDialog(false)}
          title="Cancelar E-firma"
          subtitle="¿Estas seguro de querer cancelar el proceso de E-firma?"
          buttonOnAccept={() =>
            dispatch(
              cancelMiFiel(
                documentableId,
                documentableType,
                documentSelected.id
              )
            )
          }
          buttonLabel="Cancelar E-firma"
          loading={
            cancelMifielDocumentIsLoading || updateFileDocumentsIsLoading
          }
          error={cancelMifielDocumentError}
          resetErrors={resetErrors}
        />
      )}
      {showReviewDialog && (
        <ReviewDialog
          open={showReviewDialog}
          handleCloseDialog={() => setShowReviewDialog(false)}
          handleDownloadDocument={handleDownloadFile}
          document={documentSelected}
          isLoading={approveRejectDocumentIsLoading}
          error={approveRejectDocumentError}
          resetErrors={resetErrors}
        />
      )}
      {showVisualizePdfDialog && (
        <VisualizePdfDialog
          isOpen={showVisualizePdfDialog}
          handleCloseDialog={() => setShowVisualizePdfDialog(false)}
          document={documentSelected}
          documentableType={documentSelected?.documentableType}
          actions={
            <Box display="flex" className={classes.containerButtons}>
              <LoadingButton
                className={classes.loadingButton}
                variant="text"
                color="inherit"
                isLoading={downloadDocumentsIsLoading}
                disabled={downloadDocumentsIsLoading}
                startIcon={!downloadDocumentsIsLoading && <DownloadIcon />}
                onClick={() =>
                  dispatch(
                    downloadDocument(
                      documentSelected?.documentableId,
                      documentSelected?.documentableType,
                      documentSelected
                    )
                  )
                }
              >
                Descargar documento
              </LoadingButton>
              {!documentSelected.approved && (
                <Button
                  variant="text"
                  color="inherit"
                  startIcon={<EyeIcon />}
                  onClick={() => {
                    setShowVisualizePdfDialog(false);
                    setShowReviewDialog(true);
                  }}
                >
                  Aprobar/Rechazar documento
                </Button>
              )}
            </Box>
          }
          errorActions={downloadDocumentsError}
        />
      )}
      {showDrawerElectronicSignature && (
        <UploadElectronicSignatureDrawer
          isOpen={showDrawerElectronicSignature}
          handleCloseDrawer={() => setShowDrawerElectronicSignature(false)}
          isLoading={updateElectronicSignatureDocumentIsLoading}
          error={updateElectronicSignatureDocumentError}
          handleSubmitUploadDocument={handleSubmitUploadDocument}
          electronicSignatureType={MIFIEL}
          document={documentSelected}
        />
      )}
      {showDrawerSignatories && (
        <StatusSignatoriesDrawer
          isOpen={showDrawerSignatories}
          handleCloseDrawer={() => setShowDrawerSignatories(false)}
          isLoading={fetchElectronicSignatureSignatoriesIsLoading}
          electronicSignatureType={MIFIEL}
          signatories={electronicSignatureSignatories}
        />
      )}
    </>
  );
};

DocumentsTable.propTypes = {
  documentableType: PropTypes.oneOf([BUSINESS, STAKEHOLDER, ORDER, CREDIT])
    .isRequired,
  documentableId: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf({
    action: PropTypes.string,
    actionDate: PropTypes.string,
    active: PropTypes.bool,
    approved: PropTypes.bool,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    descriptionRejected: PropTypes.string,
    document: PropTypes.string,
    documentableId: PropTypes.number,
    documentableType: PropTypes.string,
    eDocument: PropTypes.string,
    expirationDate: PropTypes.string,
    id: PropTypes.number,
    sequence: PropTypes.number,
    signedDocument: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  documentsAreLoading: PropTypes.bool.isRequired,
};

export default DocumentsTable;
