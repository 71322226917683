import { useQuery } from 'react-query';
import { fetchAgreements } from '../services';

export const useAgreements = () => {
  const { isLoading, data, refetch } = useQuery(
    'getAgreements',
    () => fetchAgreements(),
    { refetchOnWindowFocus: false }
  );

  const { data: agreements } = data || { data: [] };

  return {
    agreements,
    isLoading,
    refetch,
  };
};

export default useAgreements;
