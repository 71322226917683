import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { orderBulkUpdateStateService } from '../services';

export const useOrderBulkUpdateState = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    data,
    mutate: changeState,
    reset: resetDataAndErrors,
  } = useMutation(
    params => {
      return orderBulkUpdateStateService(params);
    },
    {
      onMutate: async params => {
        const { orderIds, status } = params;
        await queryClient.cancelQueries('getPayrolls', {
          exact: false,
        });

        const prevData = queryClient.getQueryData('getPayrolls', {
          exact: false,
        });

        const updatedData = produce(prevData, draft => {
          orderIds.forEach(order => {
            const index = draft.data.data.findIndex(
              invoice => order === invoice.id
            );
            // eslint-disable-next-line no-param-reassign
            if (index !== -1) draft.data.data[index].status = status;
          });
        });

        queryClient.setQueryData('getPayrolls', updatedData);

        return prevData;
      },
      onError: (_err, _variables, previousValue) => {
        dispatch(
          enqueueSnackbar(t13s.ERROR.ERROR_ORDER_BULK_UPDATE_STATUS, {
            variant: 'error',
          })
        );
        return queryClient.setQueryData('getPayrolls', previousValue);
      },
      onSettled: () => {
        queryClient.invalidateQueries('getPayrolls', {
          exact: false,
        });
      },
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_UPDATED, {
            variant: 'success',
          })
        );
      },
    }
  );

  return {
    data,
    isLoading,
    isError,
    changeState,
    resetDataAndErrors,
  };
};

export default useOrderBulkUpdateState;
