export enum StateType {
  legalDeclinedDocs = 'LEGAL_DECLINED_DOCS',
  legalSatLink = 'LEGAL_SAT_LINK',
  legalUploadingDocs = 'LEGAL_UPLOADING_DOCS',
  legalDocsInReview = 'LEGAL_DOCS_IN_REVIEW',
  legalRequestMoreDocuments = 'LEGAL_REQUEST_MORE_DOCUMENTS',
  legalApprovedDocuments = 'LEGAL_APPROVED_DOCUMENTS',
  operationSatLink = 'OPERATION_SAT_LINK',
  operationBuroAuthorizing = 'OPERATION_BURO_AUTHORIZING',
  operationBuroAsk = 'OPERATION_BURO_ASK',
  operationUploadingDocs = 'OPERATION_UPLOADING_DOCS',
  operationDocsInReview = 'OPERATION_DOCS_IN_REVIEW',
  operationRequestMoreDocuments = 'OPERATION_REQUEST_MORE_DOCUMENTS',
  operationApprovedDocuments = 'OPERATION_APPROVED_DOCUMENTS',
  operationDeclinedDocs = 'OPERATION_DECLINED_DOCS',
  riskSatLink = 'RISK_SAT_LINK',
  riskWaiting = 'RISK_WAITING',
  riskReviewing = 'RISK_REVIEWING',
  riskObjection = 'RISK_OBJECTION',
  riskLdcAssigned = 'RISK_LDC_ASSIGNED',
  notDefined = 'NOT_DEFINED',
}

export type GetStateDto = {
  type: StateType;
};
