import axios from 'axios';
import {
  DIRECT_FINANCING,
  INIT_PAGE,
  PAGE_SIZE_INIT,
  RESET_FEES,
  FORCED,
  NORMAL,
  NONE,
  EARLY_PAYMENT,
} from '../helpers/Constants';
import { b64toBlob, download } from '../helpers/fileHandling';
import { getErrorCode } from '../helpers/handleErrors';
import trackOrderStarted from '../helpers/segment/tracking/orderStarted';
import { t13s } from '../translationKeys';
import { enqueueSnackbar } from './notificationAction';
import { updateBusinessRates } from './ratesActions';
import {
  CALCULATE_BUSINESS_FEES_FAILURE,
  CALCULATE_BUSINESS_FEES_START,
  CALCULATE_BUSINESS_FEES_SUCCESS,
  CALCULATE_FEES_BUSINESS_NOT_ENROLLED_START,
  CALCULATE_FEES_BUSINESS_NOT_ENROLLED_SUCCESS,
  CALCULATE_FEES_BUSINESS_NOT_ENROLLED_FAILURE,
  DOWNLOAD_TGR_CERTIFICATE_FAILURE,
  DOWNLOAD_TGR_CERTIFICATE_START,
  DOWNLOAD_TGR_CERTIFICATE_SUCCESS,
  FETCH_BUSINESSES_ENROLLED_FAILURE,
  FETCH_BUSINESSES_ENROLLED_START,
  FETCH_BUSINESSES_ENROLLED_SUCCESS,
  FETCH_BUSINESSES_FAILURE,
  FETCH_BUSINESSES_START,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_INVOICES_FAILURE,
  FETCH_BUSINESS_INVOICES_START,
  FETCH_BUSINESS_INVOICES_SUCCESS,
  FETCH_BUSINESS_PAYER_FAILURE,
  FETCH_BUSINESS_PAYER_START,
  FETCH_BUSINESS_PAYER_SUCCESS,
  FETCH_BUSINESS_START,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_USERS_FAILURE,
  FETCH_BUSINESS_USERS_START,
  FETCH_BUSINESS_USERS_SUCCESS,
  FETCH_BUSINESS_FINDER_INVOICES_START,
  FETCH_BUSINESS_FINDER_INVOICES_SUCCESS,
  FETCH_BUSINESS_FINDER_INVOICES_FAILURE,
  FETCH_BUSINESS_COMPLIANCE_START,
  FETCH_BUSINESS_COMPLIANCE_SUCCESS,
  FETCH_BUSINESS_COMPLIANCE_FAILURE,
  FETCH_BUSINESS_NAME_FAILURE,
  FETCH_BUSINESS_NAME_START,
  FETCH_BUSINESS_NAME_SUCCESS,
  CONSULT_BUSINESS_COMPLIANCE_START,
  CONSULT_BUSINESS_COMPLIANCE_SUCCESS,
  CONSULT_BUSINESS_COMPLIANCE_FAILURE,
  RESET_BUSINESS_COMPLIANCE,
  FETCH_COMPLIANCE_PERSON_FAILURE,
  FETCH_COMPLIANCE_PERSON_START,
  FETCH_COMPLIANCE_PERSON_SUCCESS,
  FETCH_COMPLIANCE_STAKEHOLDER_FAILURE,
  FETCH_COMPLIANCE_STAKEHOLDER_START,
  FETCH_COMPLIANCE_STAKEHOLDER_SUCCESS,
  FETCH_TGR_AGREEMENTS_FAILURE,
  FETCH_TGR_AGREEMENTS_START,
  FETCH_TGR_AGREEMENTS_SUCCESS,
  FETCH_TGR_DEBT_FAILURE,
  FETCH_TGR_DEBT_START,
  FETCH_TGR_DEBT_SUCCESS,
  HANDLE_RESET_PFX,
  REGISTER_PFX_FAILURE,
  REGISTER_PFX_START,
  REGISTER_PFX_SUCCESS,
  REQUEST_DEBT_SUPER_BY_RUT_FAILURE,
  REQUEST_DEBT_SUPER_BY_RUT_SUCCESS,
  RESET_BUSINESSES,
  RESET_BUSINESS_FEES_FAILURE,
  RESET_BUSINESS_FEES_START,
  RESET_BUSINESS_FEES_SUCCESS,
  RESET_DOWNLOAD_TGR_CERTIFICATE,
  UPDATE_BUSINESS_AUTORATE_ERROR,
  UPDATE_BUSINESS_AUTORATE_START,
  UPDATE_BUSINESS_AUTORATE_SUCCESS,
  UPDATE_BUSINESS_CONFIRMING_STATUS_START,
  UPDATE_BUSINESS_CONFIRMING_STATUS_SUCCESS,
  UPDATE_BUSINESS_FEES_FAILURE,
  UPDATE_BUSINESS_FEES_START,
  UPDATE_BUSINESS_FEES_SUCCESS,
  UPDATE_BUSINESS_NAME_START,
  UPDATE_BUSINESS_NAME_SUCCESS,
  UPDATE_BUSINESS_NAME_FAILURE,
  FETCH_NOT_ENROLLED_BUSINESS_INVOICES_START,
  FETCH_NOT_ENROLLED_BUSINESS_INVOICES_SUCCESS,
  FETCH_NOT_ENROLLED_BUSINESS_INVOICES_FAILURE,
  FETCH_BUSINESS_BY_IDENTIFIER_START,
  FETCH_BUSINESS_BY_IDENTIFIER_SUCCESS,
  FETCH_BUSINESS_BY_IDENTIFIER_FAILURE,
  RESET_BUSINESS,
  RESET_CREATE_OPERATION_DATA,
  FETCH_CRITIAL_STATUS_INFO_START,
  FETCH_CRITIAL_STATUS_INFO_SUCCESS,
  FETCH_CRITIAL_STATUS_INFO_FAILURE,
  FETCH_API_CORPS_AMOUNT_START,
  FETCH_API_CORPS_AMOUNT_SUCCESS,
  FETCH_API_CORPS_AMOUNT_FAILURE,
} from './types';

export const fetchApiCorpsAmount = (businessId, folio) => dispatch => {
  dispatch({ type: FETCH_API_CORPS_AMOUNT_START });
  axios
    .get(`/api/business/${businessId}/confirmedinvoice/${folio}`)
    .then(({ data }) => {
      dispatch({ type: FETCH_API_CORPS_AMOUNT_SUCCESS, payload: data });
    })
    .catch(() => {
      dispatch({
        type: FETCH_API_CORPS_AMOUNT_FAILURE,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_API_CORPS_AMOUNT_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchBusinessUsers = businessId => dispatch => {
  dispatch({ type: FETCH_BUSINESS_USERS_START, payload: { businessId } });
  axios
    .get(`/api/business/${businessId}/users`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_BUSINESS_USERS_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_BUSINESS_USERS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESS_USERS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchBusinesses =
  ({
    siiUser = false,
    page = INIT_PAGE,
    limit = PAGE_SIZE_INIT,
    searchInput,
    field,
    taxService = true,
    useInTable = true,
    segment = null,
  } = {}) =>
  dispatch => {
    dispatch({ type: FETCH_BUSINESSES_START });
    axios
      .get(`/api/business`, {
        params: {
          user: true,
          pfx: true,
          tgrDebt: true,
          siiUser,
          page,
          limit,
          searchInput,
          field,
          taxService,
          useInTable,
          segment,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({ type: FETCH_BUSINESSES_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESSES_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESSES_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const resetBusinesses = () => dispatch => {
  dispatch({ type: RESET_BUSINESSES });
};

export const fetchBusiness =
  (businessId, details = false) =>
  dispatch => {
    dispatch({ type: FETCH_BUSINESS_START });
    axios
      .get(`/api/business/${businessId}`, { params: { details } })
      .then(res => {
        const resData = res?.data?.data ?? res?.data;
        dispatch({
          type: FETCH_BUSINESS_SUCCESS,
          payload: { business: resData?.business ?? resData },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESS_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESS_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const resetBusinessFees = () => dispatch => {
  dispatch({ type: RESET_FEES });
};

export const updateBusinessFees =
  ({
    businessId,
    rate = null,
    retention = null,
    confirmingRate = 0,
    type = 'update',
    devengoRates = [],
  }) =>
  dispatch => {
    dispatch({
      type:
        type === 'update'
          ? UPDATE_BUSINESS_FEES_START
          : RESET_BUSINESS_FEES_START,
    });
    axios
      .put(`/api/business/${businessId}/fees`, {
        rate,
        retention,
        confirmingRate,
        businessRates: devengoRates,
      })
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BUSINESS_FEES_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type:
            type === 'update'
              ? UPDATE_BUSINESS_FEES_SUCCESS
              : RESET_BUSINESS_FEES_SUCCESS,
          payload: resData,
        });
        if (resData?.BusinessRates) {
          dispatch(updateBusinessRates(resData.BusinessRates));
        }
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type:
            type === 'update'
              ? UPDATE_BUSINESS_FEES_FAILURE
              : RESET_BUSINESS_FEES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const registerPfx =
  ({ pfx, password, businessId }) =>
  dispatch => {
    dispatch({ type: REGISTER_PFX_START });
    const formData = new FormData();
    formData.append('pfx', pfx, pfx.name);
    formData.append('password', password);
    formData.append('businessId', businessId);
    axios
      .post(`api/pfx/certificate`, formData)
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PFX_UPLOADED, {
            variant: 'success',
          })
        );
        dispatch({ type: REGISTER_PFX_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: REGISTER_PFX_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const downloadTGRCertificate = (businessId, path) => dispatch => {
  dispatch({ type: DOWNLOAD_TGR_CERTIFICATE_START });
  axios
    .post(`/api/business/${businessId}/download-tgr-certificate`, { path })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: DOWNLOAD_TGR_CERTIFICATE_SUCCESS,
        payload: resData,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.TGR_CERTIFICATE_DOWNLOADED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: DOWNLOAD_TGR_CERTIFICATE_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchTgrAgreements =
  ({ businessId, page = 1, limit = PAGE_SIZE_INIT }) =>
  dispatch => {
    dispatch({ type: FETCH_TGR_AGREEMENTS_START });
    axios
      .get(`/api/business/${businessId}/tgr-agreement`, {
        params: { page, size: limit },
      })
      .then(res => {
        const { data } = res;

        const payload = {
          ...data,
          // Check if tgrAgreements key exist in data object
          tgrAgreements:
            'tgrAgreements' in data ? data.tgrAgreements : data.data,
        };

        dispatch({
          type: FETCH_TGR_AGREEMENTS_SUCCESS,
          payload,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_TGR_AGREEMENTS_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_TGR_AGREEMENTS_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchTgrDebt = businessId => dispatch => {
  dispatch({ type: FETCH_TGR_DEBT_START });
  axios
    .get(`/api/business/${businessId}/tgr-debt`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_TGR_DEBT_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_TGR_DEBT_FAILURE, payload: { errorCode } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_TGR_DEBT_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const requestBusinessDebtSuperByRut = rut => dispatch => {
  axios
    .post(`api/debt-super/${rut}`)
    .then(res => {
      const { data } = res;
      dispatch({
        type: REQUEST_DEBT_SUPER_BY_RUT_SUCCESS,
        payload: data,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DEBT_SUPER_CONSULTED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REQUEST_DEBT_SUPER_BY_RUT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const resetPfxDialog = () => dispatch => {
  dispatch({ type: HANDLE_RESET_PFX });
};

export const resetBusinessFessFailure = () => dispatch => {
  dispatch({ type: RESET_BUSINESS_FEES_FAILURE });
};

export const resetDownloadTgrCertificate = () => dispatch => {
  dispatch({ type: RESET_DOWNLOAD_TGR_CERTIFICATE });
};

export const fetchBusinessCompliance =
  (identifier, businessId = null) =>
  dispatch => {
    dispatch({
      type: FETCH_BUSINESS_COMPLIANCE_START,
      payload: { identifier, businessId },
    });
    axios
      .get(`/api/compliance/${identifier}`)
      .then(res => {
        dispatch({
          type: FETCH_BUSINESS_COMPLIANCE_SUCCESS,
          payload: res.data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESS_COMPLIANCE_FAILURE,
          payload: { errorCode },
        });
        if (errorCode !== 'ERROR_COMPLIANCE_NOT_FOUND') {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.FETCH_COMPLIANCE_FAILURE, {
              variant: 'error',
            })
          );
        }
      });
  };

export const consultBusinessCompliance =
  (identifier, { businessId = null, businessName = null }) =>
  dispatch => {
    const body = {
      identifier,
      businessId,
      businessName,
    };
    dispatch({ type: CONSULT_BUSINESS_COMPLIANCE_START });
    axios
      .post(`/api/compliance/`, body)
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.COMPLIANCE_CONSULTED, {
            variant: 'success',
          })
        );
        dispatch({
          type: CONSULT_BUSINESS_COMPLIANCE_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CONSULT_BUSINESS_COMPLIANCE_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchBusinessComplianceFile = (businessId, type) => dispatch => {
  axios
    .get(`api/compliance/${businessId}/file`, {
      params: {
        type,
      },
    })
    .then(res => {
      const { file, name } = res.data;
      const blob = b64toBlob(file, 'application/pdf');
      download(blob, name ?? type.toLowerCase());
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const resetBusinessCompliance = () => dispatch =>
  dispatch({ type: RESET_BUSINESS_COMPLIANCE });

export const fetchComplianceStakeholder =
  (identifier, businessId = null) =>
  dispatch => {
    dispatch({
      type: FETCH_COMPLIANCE_STAKEHOLDER_START,
      payload: { identifier, businessId },
    });
    axios
      .get(`/api/compliance/${identifier}`)
      .then(res => {
        dispatch({
          type: FETCH_COMPLIANCE_STAKEHOLDER_SUCCESS,
          payload: { businessId, identifier, ...res.data },
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_COMPLIANCE_STAKEHOLDER_FAILURE,
          payload: { identifier, businessId },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_COMPLIANCE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchCompliancePerson =
  (country, identifier, name, fatherLastName, motherLastName, businessId) =>
  dispatch => {
    dispatch({
      type: FETCH_COMPLIANCE_PERSON_START,
      payload: { identifier },
    });
    axios
      .post(`/api/compliance/person`, {
        country,
        identifier,
        name,
        fatherLastName,
        motherLastName,
        businessId,
      })
      .then(res => {
        dispatch({
          type: FETCH_COMPLIANCE_PERSON_SUCCESS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_COMPLIANCE_PERSON_FAILURE,
          payload: { identifier },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_COMPLIANCE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const updateBusinessConfirmingStatus =
  (id, hasConfirmingEnabled) => dispatch => {
    dispatch({ type: UPDATE_BUSINESS_CONFIRMING_STATUS_START });
    axios
      .put(`/api/business/${id}/confirming`, { hasConfirmingEnabled })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: UPDATE_BUSINESS_CONFIRMING_STATUS_SUCCESS,
          payload: resData,
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ENABLE_CONFIRMING_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const updateBusinessAutoRate = (id, autorate) => dispatch => {
  dispatch({ type: UPDATE_BUSINESS_AUTORATE_START });
  axios
    .put(`/api/business/${id}/autorate`, { autorate })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: UPDATE_BUSINESS_AUTORATE_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPDATE_BUSINESS_AUTORATE_ERROR,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ENABLE_AUTO_RATE_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchBusinessesEnrolled =
  (isConfirming = false) =>
  dispatch => {
    dispatch({ type: FETCH_BUSINESSES_ENROLLED_START });
    axios
      .get(`/api/business/enrolled`, { params: { isConfirming } })
      .then(res => {
        const resData = res.data;
        dispatch({ type: FETCH_BUSINESSES_ENROLLED_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESSES_ENROLLED_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_ENROLLED_BUSINESS_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchBusinessPayers = (orderType, businessId) => dispatch => {
  dispatch({ type: FETCH_BUSINESS_PAYER_START });
  axios
    .get(`/api/business/${businessId}/payers`, {
      params: {
        orderType,
      },
    })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_BUSINESS_PAYER_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_BUSINESS_PAYER_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESS_PAYERS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchBusinessInvoices =
  (businessId, payerIdentifier) => dispatch => {
    dispatch({ type: FETCH_BUSINESS_INVOICES_START });
    axios
      .get(`/api/business/${businessId}/invoices`, {
        params: {
          payerIdentifier,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_BUSINESS_INVOICES_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESS_INVOICES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchFinderInvoicesBy =
  ({ page = 1, limit = PAGE_SIZE_INIT, searchInput, field, businessId }) =>
  dispatch => {
    dispatch({ type: FETCH_BUSINESS_FINDER_INVOICES_START });
    axios
      .get(`/api/business/${businessId}/findInvoices`, {
        params: { filters: searchInput, page, limit, field },
      })
      .then(res => {
        const resData = res.data;

        dispatch({
          type: FETCH_BUSINESS_FINDER_INVOICES_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESS_FINDER_INVOICES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const calculateBusinessFees =
  (
    businessId,
    rawInvoices,
    transformedInvoices,
    availableInvoicesCount,
    availableInvoicesAmount,
    feesData = {},
    orderType = DIRECT_FINANCING,
    autoRate = false,
    payerIdentifier
  ) =>
  (dispatch, getState) => {
    dispatch({ type: CALCULATE_BUSINESS_FEES_START });
    let typeAutoRate = NONE;

    if (autoRate) {
      const getAutoRate = {
        [DIRECT_FINANCING]: FORCED,
        [EARLY_PAYMENT]: NORMAL,
      };
      typeAutoRate = getAutoRate[orderType] || NONE;
    }

    axios
      .post(
        `/api/business/${businessId}/calculateFees?orderType=${orderType}`,
        {
          orderInvoices: transformedInvoices,
          feesData,
          autoRate: typeAutoRate,
          payerIdentifier,
        }
      )
      .then(({ data }) => {
        dispatch({
          type: CALCULATE_BUSINESS_FEES_SUCCESS,
          payload: data,
        });

        trackOrderStarted(
          businessId,
          orderType,
          rawInvoices,
          data,
          availableInvoicesCount,
          availableInvoicesAmount,
          getState().auth.user.email
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CALCULATE_BUSINESS_FEES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const calculateFeesBusinessNotEnrolled =
  (
    orderType = DIRECT_FINANCING,
    orderInvoices,
    feesData,
    businessId,
    operationPaymentType
  ) =>
  dispatch => {
    dispatch({
      type: CALCULATE_FEES_BUSINESS_NOT_ENROLLED_START,
    });
    axios
      .post(
        `/api/fees/calculate/not-enrolled`,
        {
          orderInvoices,
          feesData,
          businessId,
          operationPaymentType,
        },
        { params: { orderType } }
      )
      .then(res => {
        const payload = res.data;
        dispatch({
          type: CALCULATE_FEES_BUSINESS_NOT_ENROLLED_SUCCESS,
          payload,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CALCULATE_FEES_BUSINESS_NOT_ENROLLED_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchPayersBusinessNotEnrolled =
  (type, clientIdentifier) => dispatch => {
    dispatch({ type: FETCH_BUSINESS_PAYER_START });
    axios
      .get(`/api/business/not-enrolled/payers`, {
        params: {
          type,
          clientIdentifier,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_BUSINESS_PAYER_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_BUSINESS_PAYER_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const editBusiness =
  ({ businessId, ...rest }) =>
  dispatch => {
    dispatch({ type: UPDATE_BUSINESS_NAME_START });
    axios
      .put(`/api/business/${businessId}`, { ...rest })
      .then(res => {
        const resData = res?.data?.data ?? res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BUSINESS_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: UPDATE_BUSINESS_NAME_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_BUSINESS_NAME_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchNotEnrolledBusinessInvoices =
  (clientIdentifier, payerIdentifier) => dispatch => {
    dispatch({ type: FETCH_NOT_ENROLLED_BUSINESS_INVOICES_START });
    axios
      .get(`/api/business/not-enrolled/invoices`, {
        params: {
          payerIdentifier,
          clientIdentifier,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_NOT_ENROLLED_BUSINESS_INVOICES_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_NOT_ENROLLED_BUSINESS_INVOICES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchBusinessName = identifier => dispatch => {
  dispatch({
    type: FETCH_BUSINESS_NAME_START,
  });
  axios
    .get(`/api/taxuser/business/name`, { params: { identifier } })
    .then(res => {
      dispatch({
        type: FETCH_BUSINESS_NAME_SUCCESS,
        payload: res.data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_BUSINESS_NAME_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchBusinessByIdentifier = identifier => dispatch => {
  dispatch({
    type: FETCH_BUSINESS_BY_IDENTIFIER_START,
  });
  axios
    .get(`/api/business/${identifier}`)
    .then(res => {
      dispatch({
        type: FETCH_BUSINESS_BY_IDENTIFIER_SUCCESS,
        payload: res.data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_BUSINESS_BY_IDENTIFIER_FAILURE,
        payload: { errorCode },
      });
      dispatch(fetchBusinessName(identifier));
    });
};

export const fetchCriticalStatusInfo = businessId => dispatch => {
  dispatch({
    type: FETCH_CRITIAL_STATUS_INFO_START,
  });
  axios
    .get(`/api/business/${businessId}/criticalstatusinfo`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_CRITIAL_STATUS_INFO_SUCCESS,
        payload: { criticalStatusInfo: data },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_CRITIAL_STATUS_INFO_FAILURE,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CRITIAL_STATUS_INFO_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const resetBusiness = () => dispatch => {
  dispatch({
    type: RESET_BUSINESS,
  });
};

export const resetCreateOperationData = () => dispatch => {
  dispatch({ type: RESET_CREATE_OPERATION_DATA });
};

export const getTGRCertificate = businessId => dispatch => {
  dispatch({ type: DOWNLOAD_TGR_CERTIFICATE_START });
  axios
    .get(`/api/business/${businessId}/tgr-certificate-url`)
    .then(async res => {
      const { debtCertificateUrl } = res.data;
      window.open(debtCertificateUrl, '_blank');
      dispatch({
        type: DOWNLOAD_TGR_CERTIFICATE_SUCCESS,
        payload: res.data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.ERROR.TGR_CERTIFICATE_NOT_FOUND, {
          variant: 'error',
        })
      );
      dispatch({
        type: DOWNLOAD_TGR_CERTIFICATE_FAILURE,
        payload: { errorCode },
      });
    });
};
