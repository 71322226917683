import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateInvoiceDiscountService } from '../services';

export const useUpdateInvoiceDiscount = () => {
  const dispatch = useDispatch();
  const {
    isLoading: updateInvoiceDiscountLoading,
    mutate: updateInvoiceDiscount,
  } = useMutation(
    ({ sourceId, sourceType, body }) => {
      return updateInvoiceDiscountService({ sourceId, sourceType, body });
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DISCOUNT_UPDATED, {
            variant: 'success',
          })
        );
        onSuccessCallback();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar('error al actualizar descuento', {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    updateInvoiceDiscountLoading,
    updateInvoiceDiscount,
  };
};

export default useUpdateInvoiceDiscount;
