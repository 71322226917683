import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchTransferAccountsService = async (orderId, supplierIdentifier) => {
  try {
    const { data } = await axios.get(
      `api/payment/order/${orderId}/transfer-accounts`,
      {
        params: {
          supplierIdentifier,
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message) ?? UNKNOWN_ERROR;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchTransferAccountsService };
