import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { features } from '../../../config/features';
import { DISCOUNTS, STATUS_RENEGOTIATION } from '../../../helpers/Constants';
import {
  useFetchCommentsCounter,
  useFetchOrderResposiblesCounter,
  useOrder,
  useOrderCredit,
} from '../infrastructure/store';

const invoiceDiscountReasons = DISCOUNTS.filter(
  globalReason => globalReason.type === 'BASE_DISCOUNT'
);

const paymentTypeOptions = ['Caja propia', 'Financiamiento'];

const usePayrollHeader = () => {
  const [showInvoicesDiscountDialog, setShowInvoicesDiscountDialog] =
    useState(false);
  const [showCreditFormDialog, setShowCreditFormDialog] = useState(false);
  const [showLockOperationDialog, setShowLockOperationDialog] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [showOrderCommentsDrawer, setShowOrderCommentsDrawer] = useState(false);
  const [showOrderResponsiblesDrawer, setShowOrderResponsiblesDrawer] =
    useState(false);

  const history = useHistory();

  const { country } = useSelector(state => state.config);
  const countryFeatures = features[country];
  const isShowResponsiblesEnabled =
    countryFeatures.orderActions.showResponsibles;
  const { authState } = useOktaAuth();
  const { id } = useParams();
  const { order } = useOrder(id);
  const { orderCredit } = useOrderCredit(id);
  const userEmail = authState?.idToken?.claims?.email || '-';
  const { status } = order;
  const {
    commentsCount,
    isLoading: commentsCounterIsLoading,
    hasPendingComments,
  } = useFetchCommentsCounter(order?.id, userEmail);

  const { responsiblesCount, isLoading: responsiblesCounterIsLoading } =
    useFetchOrderResposiblesCounter(order?.businessIdentifier);

  const handleShowHistoryDialog = () => {
    setShowHistoryDialog(true);
  };

  const handleShowCreditFormDialog = () => {
    if (status !== STATUS_RENEGOTIATION) {
      setShowCreditFormDialog(true);
    } else if (orderCredit && Object.entries(orderCredit).length) {
      history.push(`/${country.toLowerCase()}/credits/${orderCredit.id}`);
    }
  };

  const handleCloseInvoicesDiscountDialog = () => {
    setShowInvoicesDiscountDialog(false);
  };

  const handleCloseCreditFormDialog = () => {
    setShowCreditFormDialog(false);
  };

  const handleCloseHistoryDialog = () => {
    setShowHistoryDialog(false);
  };

  const handleCloseLockOperationDialog = () => {
    setShowLockOperationDialog(false);
  };

  const menuActions = [
    {
      itemText:
        status === STATUS_RENEGOTIATION ? 'Ver crédito' : 'Repactar orden',
      itemHandler: handleShowCreditFormDialog,
      permission: countryFeatures.orderActions.repactOrder,
    },
    {
      itemText: 'Bloquear operación',
      itemHandler: () => setShowLockOperationDialog(true),
      permission: true,
    },
  ];

  return {
    menuActions,
    invoiceDiscountReasons,
    paymentTypeOptions,
    showInvoicesDiscountDialog,
    showCreditFormDialog,
    showHistoryDialog,
    showLockOperationDialog,
    showOrderCommentsDrawer,
    commentsCount,
    commentsCounterIsLoading,
    hasPendingComments,
    isShowResponsiblesEnabled,
    responsiblesCount,
    responsiblesCounterIsLoading,
    showOrderResponsiblesDrawer,
    handleShowHistoryDialog,
    handleCloseInvoicesDiscountDialog,
    handleCloseCreditFormDialog,
    handleCloseHistoryDialog,
    handleCloseLockOperationDialog,
    setShowOrderCommentsDrawer,
    setShowOrderResponsiblesDrawer,
  };
};

export default usePayrollHeader;
