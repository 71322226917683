export const addDraftDocumentUseCase = ({
  selectedRows,
  draftPayrollSelectorState,
  selectedReceiver,
  prevStateTable,
}) => {
  const currentSelectorState = draftPayrollSelectorState || {};
  const {
    documentsIds,
    documentsNumber,
    name,
    receiverIdentifier,
    value: allDocuments,
  } = selectedReceiver;
  const currentSelectedReceiver = currentSelectorState[receiverIdentifier];
  const selectedPrevIds = prevStateTable.reduce((acc, row) => {
    if (row.tableData.checked) {
      acc.push(row.id);
    }

    return acc;
  }, []);

  if (selectedRows.length === prevStateTable.length) {
    const newDocsToAdd = prevStateTable.reduce((acc, row) => {
      if (!selectedPrevIds.includes(row.id)) {
        acc.push({ id: row.id });
      }

      return acc;
    }, []);
    const docsToAdd = [
      ...(currentSelectedReceiver?.value || []),
      ...newDocsToAdd,
    ];
    const selected = docsToAdd.length === allDocuments.length;

    const newState = {
      ...currentSelectorState,
      [receiverIdentifier]: {
        amount:
          (currentSelectedReceiver?.amount ?? 0) +
          newDocsToAdd.reduce(
            (acc, doc) =>
              acc + selectedRows.find(row => row.id === doc.id).amount,
            0
          ),
        name,
        selected,
        value: docsToAdd,
      },
    };

    return { newState };
  }

  if (selectedRows.length === documentsNumber) {
    const newDocsToAdd = documentsIds.filter(
      doc => !selectedPrevIds.includes(doc.id)
    );
    const docsToAdd = [
      ...(currentSelectedReceiver?.value || []),
      ...newDocsToAdd,
    ];
    const selected = docsToAdd.length === allDocuments.length;

    const newState = {
      ...currentSelectorState,
      [receiverIdentifier]: {
        amount:
          (currentSelectedReceiver?.amount || 0) +
          newDocsToAdd.reduce(
            (acc, doc) => acc + selectedRows.find(r => r.id === doc.id).amount,
            0
          ),
        name,
        selected,
        value: docsToAdd,
      },
    };

    return { newState };
  }

  const selectedCurrentIds = selectedRows.map(row => row.id);
  const selectedIdAgain = selectedCurrentIds.find(
    id => !selectedPrevIds.includes(id)
  );
  const documentToAdd = selectedRows.find(r => r.id === selectedIdAgain);

  if (documentToAdd) {
    const docsToAdd = [
      ...(currentSelectedReceiver?.value || []),
      { id: selectedIdAgain },
    ];
    const selected = docsToAdd.length === allDocuments.length;

    const newState = {
      ...currentSelectorState,
      [receiverIdentifier]: {
        amount: (currentSelectedReceiver?.amount || 0) + documentToAdd.amount,
        name,
        selected,
        value: docsToAdd,
      },
    };

    return { newState };
  }

  const idsToRemove = selectedPrevIds.filter(
    id => !selectedCurrentIds.includes(id)
  );
  const value = currentSelectedReceiver.value.filter(
    doc => !idsToRemove.includes(doc.id)
  );

  if (!value.length) {
    const newState = Object.keys(currentSelectorState).reduce(
      (acc, curreceiverIdentifier) => {
        if (curreceiverIdentifier !== receiverIdentifier) {
          return {
            ...acc,
            [curreceiverIdentifier]:
              currentSelectorState[curreceiverIdentifier],
          };
        }

        return acc;
      },
      {}
    );

    return { newState, removedReceiverId: receiverIdentifier };
  }

  const newStateSelector = {
    ...currentSelectorState,
    [receiverIdentifier]: {
      name,
      selected: false,
      value,
      amount:
        currentSelectedReceiver.amount -
        idsToRemove.reduce(
          (acc, idDoc) => acc + prevStateTable.find(r => r.id === idDoc).amount,
          0
        ),
    },
  };

  return { newState: newStateSelector };
};
