import axios from 'axios';
import {
  FETCH_BUSINESS_PAYERS_START,
  FETCH_BUSINESS_PAYERS_SUCCESS,
  FETCH_BUSINESS_PAYERS_FAILURE,
  FETCH_PAYER_INVOICES_START,
  FETCH_PAYER_INVOICES_SUCCESS,
  FETCH_PAYER_INVOICES_FAILURE,
  FETCH_AVAILABLE_INVOICES_START,
  FETCH_AVAILABLE_INVOICES_SUCCESS,
  FETCH_AVAILABLE_INVOICES_FAILURE,
  FETCH_SII_INVOICES_SUCCESS,
  RESET_BUSINESS_PAYERS,
  UPLOAD_INVOICES_CSV_TO_ORDER_START,
  UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS,
  UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE,
  RESET_CLIENT_NAME_NOT_ENROLLED,
  RESET_INVOICES_CSV_TO_ORDER,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { arrayToURL } from '../helpers/url';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';
import { getFirstBusinessDayFrom } from '../helpers/DateUtils';

// eslint-disable-next-line import/prefer-default-export
export const fetchBusinessPayers = (operation, businessId) => dispatch => {
  dispatch({ type: FETCH_BUSINESS_PAYERS_START });
  axios
    .get(`/api/siifacturas/business/${businessId}?operation=${operation}`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_BUSINESS_PAYERS_SUCCESS,
        payload: resData,
      });
      return resData;
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESS_PAYERS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_BUSINESS_PAYERS_FAILURE,
      });
    });
};

export const fetchPayerInvoices = (businessId, payerRut) => dispatch => {
  dispatch({ type: FETCH_PAYER_INVOICES_START });
  axios
    .get(`/api/siifacturas/business/${businessId}/payer?payerRut=${payerRut}`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_PAYER_INVOICES_SUCCESS,
        payload: resData,
      });
      return resData;
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYERS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_PAYER_INVOICES_FAILURE,
      });
    });
};

export const uploadInvoiceCsvToOrder = (invoicesCsv, business) => dispatch => {
  const { id: businessId, rut, identifier = rut } = business;
  dispatch({ type: UPLOAD_INVOICES_CSV_TO_ORDER_START });
  axios
    .post(`/api/orders/invoices-csv`, {
      invoicesCsv,
      business: {
        identifier,
        businessId,
      },
    })
    .then(res => {
      const resData = res.data?.data ?? res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.INVOICES_CSV_UPLOADED, {
          variant: 'success',
        })
      );
      dispatch({
        type: UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const uploadInvoiceCsvConfirming = (invoicesCsv, client) => dispatch => {
  const { id = null, identifier, name } = client;
  dispatch({ type: UPLOAD_INVOICES_CSV_TO_ORDER_START });
  const formData = new FormData();
  formData.append('csv', invoicesCsv);
  formData.append('business', JSON.stringify({ id, identifier, name }));
  axios
    .post(`/api/invoice/confirming-csv`, formData)
    .then(res => {
      const resData = res?.data;
      if (!resData.errorLines.length) {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.INVOICES_CSV_UPLOADED, {
            variant: 'success',
          })
        );
      }
      dispatch({
        type: UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const uploadInvoiceCsvDirectFinance =
  (invoicesCsv, orderType, client) => dispatch => {
    const { identifier } = client;
    dispatch({ type: UPLOAD_INVOICES_CSV_TO_ORDER_START });
    const formData = new FormData();
    formData.append('csv', invoicesCsv);
    formData.append('identifier', identifier);
    formData.append('orderType', orderType);
    axios
      .post(`api/invoice/csv`, formData)
      .then(res => {
        const resData = res?.data;
        if (!resData.errorLines.length) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.INVOICES_CSV_UPLOADED, {
              variant: 'success',
            })
          );
        }
        dispatch({
          type: UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchNotEnrolledBusinessPayers =
  (operation, clientRut) => dispatch => {
    dispatch({ type: FETCH_BUSINESS_PAYERS_START });
    axios
      .get(`/api/siifacturas/not-enrolled/business`, {
        params: {
          operation,
          clientRut,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_BUSINESS_PAYERS_SUCCESS,
          payload: resData,
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESS_PAYERS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_BUSINESS_PAYERS_FAILURE,
        });
      });
  };

export const fetchNotEnrolledPayerInvoices =
  (clientRut, payerRut) => dispatch => {
    dispatch({ type: FETCH_PAYER_INVOICES_START });
    axios
      .get(`/api/siifacturas/not-enrolled/payer`, {
        params: {
          clientRut,
          payerRut,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_PAYER_INVOICES_SUCCESS,
          payload: resData,
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYERS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_PAYER_INVOICES_FAILURE,
        });
      });
  };

export const fetchBusinessSiiFacturas = (idBusiness, folio) => dispatch => {
  axios
    .get(`/api/siifacturas/business/${idBusiness}`, {
      params: {
        folio,
      },
    })
    .then(res => {
      const { facturas: siiFacturas = [] } = res.data;
      dispatch({
        type: FETCH_SII_INVOICES_SUCCESS,
        payload: { siiFacturas },
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_INVOICES_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchAvailableBusinessInvoices =
  (
    businessId,
    holidays,
    { filters, operationType, includeRate = false, defaultRate }
  ) =>
  dispatch => {
    dispatch({ type: FETCH_AVAILABLE_INVOICES_START });
    axios
      .get(`/api/business/${businessId}/available-invoices`, {
        params: {
          filters: arrayToURL(filters),
          includeRate,
          operationType,
          defaultRate,
          page: 1,
          limit: 50,
        },
      })
      .then(res => {
        const preAvailableInvoices = res.data.data;
        const availableInvoices = preAvailableInvoices?.map(invoice => {
          return {
            ...invoice,
            expirationDate: getFirstBusinessDayFrom(
              invoice.expirationDate,
              holidays
            ),
          };
        });

        dispatch({
          type: FETCH_AVAILABLE_INVOICES_SUCCESS,
          payload: { availableInvoices },
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_AVAILABLE_INVOICES_FAILURE,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_INVOICES_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const resetBusinessPayers = () => dispatch =>
  dispatch({
    type: RESET_BUSINESS_PAYERS,
  });

export const resetClientNameNotEnrolled = () => dispatch =>
  dispatch({
    type: RESET_CLIENT_NAME_NOT_ENROLLED,
  });

export const resetInvoicesCsvToOrder = () => dispatch =>
  dispatch({
    type: RESET_INVOICES_CSV_TO_ORDER,
  });
