import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useGraphQlQuery } from '../../../hooks/useGraphQlQuery';
import { query, Query } from './query';
import { noop } from '../../../utils';

type Props = {
  businessIdentifier: string;
  enableRefetch: boolean;
  onExceedRefetchLimit?: () => void;
  onRefetch?: () => void;
};

export const useGetWorkflowSlackThread = ({
  businessIdentifier,
  enableRefetch,
  onExceedRefetchLimit = noop,
  onRefetch = noop,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [refetchCounter, setRefetchCounter] = useState(0);

  const variables = {
    dto: {
      refId: businessIdentifier,
    },
  };

  const refetchIntervalHandler = (data: Query | undefined) => {
    if (data?.slackThread?.link) {
      return false;
    }

    return 5000;
  };

  const {
    loading,
    data,
    error,
    isSuccess,
    refetch,
    isRefetching,
  } = useGraphQlQuery<Query>(
    'workflowsWithSlackTrheadID',
    {
      query,
      variables,
      enabled: Boolean(businessIdentifier),
      refetchInterval: enableRefetch ? refetchIntervalHandler : false,
    },
  );

  useEffect(() => {
    if (isRefetching) {
      if (refetchCounter < 5) {
        setRefetchCounter(counter => counter + 1);
      } else {
        onExceedRefetchLimit();
        setRefetchCounter(0);
      }

      onRefetch();
    }
  }, [isRefetching]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Surgió un error al obtener el link de slack', {
        variant: 'error',
      });
    }
  }, [error]);

  const slackLink = data?.slackThread?.link;

  return {
    loading,
    error,
    isSuccess,
    slackLink,
    refetch,
    isRefetching,
  };
};
