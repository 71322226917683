import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import notavailable from '../assets/notavailable.svg';

const useStyles = makeStyles({
  text: {
    marginTop: 30,
  },
});

const SectionNotAvailable = () => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <img src={notavailable} alt="no disponible" width={300} />
      <Typography variant="h5" color="textPrimary" className={classes.text}>
        Sección no disponible para el país seleccionado.
      </Typography>
    </Grid>
  );
};

export default SectionNotAvailable;
