import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Bank as BankIcon } from '../../../../../components/icons';

import {
  CopyToClipboardButton,
  CopyIcon,
  BorderTopGrid,
  LastGrid,
  ItemGrid,
  AmountText,
  SecondaryText,
  SubtitleBox,
  SubtitleText,
  FirstGrid,
  EditButton,
  TitleGrid,
  CopyText,
} from './styles';
import { Text } from '../../../../commons/formatters';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { t13s } from '../../../../../translationKeys';
import { settings } from '../../../../../config/settings';
import { useUtils } from '../../PayrollInvoices/InvoicesTable/hooks';
import { BaseDrawer } from '../../../../commons/components';

const TransferAccountsDrawer = ({
  isOpen,
  selectedBankAccount,
  handleOpenBackButton,
  handleCopyBankAccount,
  handleCloseBankAccountDrawer,
}) => {
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { copyToClipboard } = useUtils();

  const {
    alias,
    amount,
    bankName,
    countryId,
    sourceIdentifier,
    accountEmail,
    accountNumber,
    accountHolder,
  } = selectedBankAccount || {};

  return (
    <BaseDrawer
      open={isOpen}
      title="Cuentas a transferir"
      width={458}
      handleClose={handleCloseBankAccountDrawer}
      titleIcon={<BankIcon />}
    >
      <>
        <SubtitleBox display="flex" alignItems="left">
          <SubtitleText>
            Datos del proveedor donde se realizará la transferencia de la
            operación.
          </SubtitleText>
        </SubtitleBox>

        <Grid container direction="column">
          <FirstGrid item container justifyContent="space-between">
            <TitleGrid item>
              <SecondaryText variant="subtitle1">
                Total a transferir
              </SecondaryText>
            </TitleGrid>
            <ItemGrid item>
              <AmountText fontWeight="bold">
                {amount && countryId ? (
                  <CountryFormatHelper
                    countryId={countryId}
                    variant="currency"
                    value={amount}
                  />
                ) : null}
              </AmountText>
            </ItemGrid>
          </FirstGrid>
          <BorderTopGrid item container justifyContent="space-between">
            <TitleGrid item>
              <SecondaryText variant="subtitle1">Razón social</SecondaryText>
            </TitleGrid>
            <ItemGrid item>
              <Text>{accountHolder}</Text>
            </ItemGrid>
          </BorderTopGrid>
          <BorderTopGrid item container justifyContent="space-between">
            <TitleGrid item>
              <SecondaryText variant="subtitle1">
                {t(t13s.LABEL.BUSINESS_IDENTIFIER)}
              </SecondaryText>
            </TitleGrid>
            <ItemGrid item>
              <Text>{sourceIdentifier}</Text>
            </ItemGrid>
          </BorderTopGrid>
          <BorderTopGrid item container justifyContent="space-between">
            <TitleGrid item>
              <SecondaryText variant="subtitle1">Banco</SecondaryText>
            </TitleGrid>
            <ItemGrid item>
              <Text>{bankName}</Text>
            </ItemGrid>
          </BorderTopGrid>
          <BorderTopGrid item container justifyContent="space-between">
            <TitleGrid item>
              <SecondaryText variant="subtitle1">
                {t(t13s.LABEL.PAYMENTS_BANK_ACCOUNT_IDENTIFIER)}
              </SecondaryText>
            </TitleGrid>
            <ItemGrid item>
              <Text>{accountNumber}</Text>
              &nbsp;
              <Tooltip
                title={`Copiar ${t(
                  t13s.LABEL.PAYMENTS_BANK_ACCOUNT_IDENTIFIER
                )}`}
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    copyToClipboard(accountNumber);
                  }}
                >
                  &nbsp;
                  <CopyIcon style={{ fontSize: '14px' }} />
                </Link>
              </Tooltip>
            </ItemGrid>
          </BorderTopGrid>
          {alias ? (
            <BorderTopGrid item container justifyContent="space-between">
              <TitleGrid item>
                <SecondaryText variant="subtitle1">Alias</SecondaryText>
              </TitleGrid>
              <ItemGrid item>
                <Text>{alias}</Text>
              </ItemGrid>
            </BorderTopGrid>
          ) : null}
          <BorderTopGrid item container justifyContent="space-between">
            <Grid item>
              <SecondaryText variant="subtitle1">Divisa</SecondaryText>
            </Grid>
            <ItemGrid item>{settings[country].currencyCode}</ItemGrid>
          </BorderTopGrid>
          <LastGrid item container justifyContent="space-between">
            <TitleGrid item>
              <SecondaryText variant="subtitle1">Correo</SecondaryText>
            </TitleGrid>
            <ItemGrid item>
              <Text>{accountEmail}</Text>
              &nbsp;
              <Tooltip title="Copiar correo">
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    copyToClipboard(accountEmail);
                  }}
                >
                  &nbsp;
                  <CopyIcon style={{ fontSize: 14 }} />
                </Link>
              </Tooltip>
            </ItemGrid>
          </LastGrid>
        </Grid>

        <CopyToClipboardButton onClick={handleCopyBankAccount}>
          <CopyIcon style={{ marginRight: 10 }} />
          <CopyText>Copiar datos bancarios</CopyText>
        </CopyToClipboardButton>

        <EditButton
          onClick={handleOpenBackButton}
          variant="contained"
          color="primary"
        >
          Editar cuenta
        </EditButton>
      </>
    </BaseDrawer>
  );
};

TransferAccountsDrawer.propTypes = {
  selectedBankAccount: PropTypes.shape({
    accountNumber: PropTypes.string,
    accountHolder: PropTypes.string,
    identifier: PropTypes.string,
    amount: PropTypes.number,
    bankName: PropTypes.string,
    countryId: PropTypes.string,
    accountEmail: PropTypes.string,
    alias: PropTypes.string,
    accountType: PropTypes.string,
    tableData: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpenBackButton: PropTypes.func.isRequired,
  handleCopyBankAccount: PropTypes.func.isRequired,
  handleCloseBankAccountDrawer: PropTypes.func.isRequired,
};

export default TransferAccountsDrawer;
