import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useDownloadSimulationPdf,
  useUpdateOrderStatus,
} from '../infrastructure/store';

const usePayrollSummary = () => {
  const { id: orderId } = useParams();
  const {
    updateOrderStatus,
    orderStatusIsUpdating,
    updateOrderStatusIsSuccess,
    updateOrderStatusError,
    resetOrderStatusUpdateData,
  } = useUpdateOrderStatus();

  const [showSimulationDialog, setShowSimulationDialog] = useState(false);
  const [showOrderStatusDialog, setShowOrderStatusDialog] = useState(false);

  const handleShowSimulationDialog = useCallback(() => {
    setShowSimulationDialog(true);
  }, [setShowSimulationDialog]);
  const handleCloseSimulationDialog = useCallback(() => {
    setShowSimulationDialog(false);
  }, [setShowSimulationDialog]);
  const handleShowOrderStatusDialog = useCallback(() => {
    setShowOrderStatusDialog(true);
  }, [setShowOrderStatusDialog]);
  const handleCloseOrderStatusDialog = useCallback(() => {
    setShowOrderStatusDialog(false);
    resetOrderStatusUpdateData();
  }, [setShowOrderStatusDialog, resetOrderStatusUpdateData]);

  const { downloadSimulationPdf } = useDownloadSimulationPdf();

  const handleDownloadSimulationPdf = useCallback(() => {
    downloadSimulationPdf(orderId);
  });

  const handleUpdateOrderStatus = (status, statusReason) => {
    updateOrderStatus({ orderId, status, statusReason });
  };

  useEffect(() => {
    if (updateOrderStatusIsSuccess) handleCloseOrderStatusDialog();
  }, [updateOrderStatusIsSuccess]);

  return {
    showSimulationDialog,
    showOrderStatusDialog,
    orderStatusIsUpdating,
    updateOrderStatusError,
    handleShowSimulationDialog,
    handleCloseSimulationDialog,
    handleShowOrderStatusDialog,
    handleCloseOrderStatusDialog,
    handleUpdateOrderStatus,
    handleDownloadSimulationPdf,
  };
};

export default usePayrollSummary;
