import { makeStyles } from '@material-ui/core';
import {
  ALT_BORDER_COLOR,
  DRAWER_SECONDARY,
} from '../../../../../../theme/otherColors';

const useStyles = makeStyles({
  rootDrawer: {
    background: '#fff',
  },
  rootContainer: {
    width: 450,
    padding: 20,
  },
  titleContainer: {
    marginBottom: 10,
  },
  title: {
    marginLeft: 10,
  },
  divider: {
    marginBottom: 30,
  },
  dataList: {
    marginBottom: 30,
  },
  dataListRow: {
    marginBottom: 10,
  },
  tab: {
    marginBottom: 30,
  },
  dataListLastRow: {
    marginBottom: 30,
  },
  drawerPaper: {
    width: '100%',
    background: DRAWER_SECONDARY.backgroundColor,
  },
  appBar: {
    padding: '19px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.headerBackgroundColor,
  },
  navTitle: {
    flex: '1',
  },
  container: {
    width: '90%',
    padding: '36px 0',
    margin: '0 auto',
  },
  companyContainer: {
    padding: '20px',
  },
  panelContainer: {
    columnGap: '20px',
  },
  emptyResumeContainer: {
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    borderRadius: '15px',
    padding: '20px',
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
    textAlign: 'center',
  },
  bottomContainer: { columnGap: '10px' },
  emptyResumeIcon: {
    margin: '35px auto 26px auto',
  },
  labelInfo: {
    fontSize: 14,
    marginRight: 5,
    textTransform: 'none',
  },
  info: {
    marginRight: 30,
    fontSize: 14,
  },
  rootInfo: {
    display: 'flex',
  },
  headerClass: {
    background: 'none',
    boxShadow: 'none',
  },
  marginBottom20: {
    marginBottom: 20,
  },
});

export default useStyles;
