import axios from 'axios';
import {
  FETCH_FUNDS_START,
  FETCH_FUNDS_SUCCESS,
  FETCH_FUNDS_FAILURE,
  EDIT_FUNDS_START,
  EDIT_FUNDS_SUCCESS,
  EDIT_FUNDS_FAILURE,
  REGISTER_FUNDS_START,
  REGISTER_FUNDS_SUCCESS,
  REGISTER_FUNDS_FAILURE,
  FETCH_FUND_RATES_START,
  FETCH_FUND_RATES_SUCCESS,
  FETCH_FUND_RATES_FAILURE,
  EDIT_FUND_RATES_START,
  EDIT_FUND_RATES_SUCCESS,
  EDIT_FUND_RATES_FAILURE,
  REGISTER_FUND_RATES_START,
  REGISTER_FUND_RATES_SUCCESS,
  REGISTER_FUND_RATES_FAILURE,
  DELETE_FUND_RATE_START,
  DELETE_FUND_RATE_SUCCESS,
  DELETE_FUND_RATE_FAILURE,
  FETCH_FUND_START,
  FETCH_FUND_SUCCESS,
  FETCH_FUND_FAILURE,
  FETCH_FUNDRATES_BYFUND_START,
  FETCH_FUNDRATES_BYFUND_SUCCESS,
  FETCH_ORDER_INVOICE_FUND_START,
  FETCH_ORDER_INVOICE_FUND_SUCCESS,
  FETCH_ORDER_INVOICE_FUND_FAILURE,
  FETCH_ORDER_INVOICE_FUND_RATES_START,
  FETCH_ORDER_INVOICE_FUND_RATES_SUCCESS,
  FETCH_ORDER_INVOICE_FUND_RATES_FAILURE,
  UPDATE_ORDER_INVOICE_FUND2_START,
  UPDATE_ORDER_INVOICE_FUND2_SUCCESS,
  UPDATE_ORDER_INVOICE_FUND2_FAILURE,
  RESET_FUND_PAYROLL,
  FETCH_FUNDRATES_BYFUND_FAILURE,
  VERIFY_ORDER_FULLY_FINANCED_START,
  VERIFY_ORDER_FULLY_FINANCED_SUCCESS,
  VERIFY_ORDER_FULLY_FINANCED_FAILURE,
  UPDATE_SELECTED_FUND,
  RESET_FUND_FORM_DIALOG,
  UPLOAD_CSV_FUNDS_START,
  UPLOAD_CSV_FUNDS_SUCCESS,
  UPLOAD_CSV_FUNDS_FAILURE,
  UPDATE_SELECTED_FUND_RATE,
  RESET_MESSAGES_RESPONSE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { DIRECT_FINANCING } from '../helpers/Constants';

// API esto no provoca bugs, hay que eliminar el llamado desde orderActions
// eslint-disable-next-line import/no-cycle
import {
  fetchSelectedOrder,
  fetchOperationSummary,
  fetchFacturas,
} from './orderActions';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchFunds = (page, limit) => dispatch => {
  dispatch({ type: FETCH_FUNDS_START });
  axios
    .get(`/api/funds`, {
      params: {
        page,
        limit,
      },
    })
    .then(({ data: { data, pagination } }) => {
      dispatch({
        type: FETCH_FUNDS_SUCCESS,
        payload: {
          data,
          ...(pagination ? { pagination } : {}),
        },
      });
    })
    .catch(err => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_FUNDS_FAILURE, {
          variant: 'error',
        })
      );
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_FUNDS_FAILURE, payload: { errorCode } });
    });
};

export const fetchActiveFunds = (page, limit) => dispatch => {
  dispatch({ type: FETCH_FUNDS_START });
  axios
    .get(`/api/funds/active`, {
      params: {
        page,
        limit,
      },
    })
    .then(res => {
      const data = res?.data?.data ?? res.data;
      dispatch({
        type: FETCH_FUNDS_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_FUNDS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: FETCH_FUNDS_FAILURE, payload: { errorCode } });
    });
};

export const fetchFundRatesByFund = (fundId, country) => dispatch => {
  dispatch({ type: FETCH_FUNDRATES_BYFUND_START });
  axios
    .get(`/api/funds/${fundId}/rates`)
    .then(res => {
      const resData = res.data?.data ?? res.data;
      dispatch({
        type: FETCH_FUNDRATES_BYFUND_SUCCESS,
        payload: { [`${fundId}${country}`]: resData },
      });
    })
    .catch(err => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_FUND_RATES_FAILURE, {
          variant: 'error',
        })
      );
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_FUNDRATES_BYFUND_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchOrderInvoicesFund = orderId => dispatch => {
  dispatch({ type: FETCH_ORDER_INVOICE_FUND_START, payload: { orderId } });
  axios
    .get(`/api/orderinvoicefund`, {
      params: {
        orderId,
      },
    })
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_ORDER_INVOICE_FUND_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_FUND_RATES_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_ORDER_INVOICE_FUND_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchOrderInvoiceFundRates = orderInvoiceFundId => dispatch => {
  dispatch({
    type: FETCH_ORDER_INVOICE_FUND_RATES_START,
    payload: { ownerId: orderInvoiceFundId },
  });
  axios
    .get(`/api/orderinvoicefund/${orderInvoiceFundId}/rate`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_ORDER_INVOICE_FUND_RATES_SUCCESS,
        payload: resData?.sort((a, b) => a.days - b.days),
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_RATES_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_ORDER_INVOICE_FUND_RATES_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateOrderInvoiceFund =
  (orderId, orderInvoiceFundId, fundFinancing) => dispatch => {
    dispatch({ type: UPDATE_ORDER_INVOICE_FUND2_START });
    axios
      .put(`/api/orderinvoicefund/${orderInvoiceFundId}/rates`, {
        ...fundFinancing,
      })
      .then(res => {
        const { data } = res;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_INVOICE_FUND_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: UPDATE_ORDER_INVOICE_FUND2_SUCCESS,
          payload: data,
        });
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_INVOICE_FUND_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: UPDATE_ORDER_INVOICE_FUND2_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const editFund = (fundId, fundData) => dispatch => {
  const { ContactDetail, ...rest } = fundData;
  dispatch({ type: EDIT_FUNDS_START });
  axios
    .put(`/api/funds/${fundId}`, { ...rest })
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FUND_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: EDIT_FUNDS_SUCCESS,
        payload: { ...resData, ...(ContactDetail ? { ContactDetail } : {}) },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: EDIT_FUNDS_FAILURE, payload: { errorCode } });
    });
};

export const registerFund = fundData => dispatch => {
  dispatch({ type: REGISTER_FUNDS_START });
  axios
    .post('/api/funds', fundData)
    .then(({ data }) => {
      const resData = data?.data || data;
      dispatch({ type: REGISTER_FUNDS_SUCCESS, payload: { data: resData } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FUND_CREATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: REGISTER_FUNDS_FAILURE, payload: { errorCode } });
    });
};

export const fetchFundRates =
  (fundId, orderType = DIRECT_FINANCING, active = true) =>
  dispatch => {
    dispatch({ type: FETCH_FUND_RATES_START });
    axios
      .get(`/api/funds/${fundId}/rates`, { params: { orderType, active } })
      .then(res => {
        const resData = res?.data?.data || res.data;
        dispatch({ type: FETCH_FUND_RATES_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_FUND_RATES_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({ type: FETCH_FUND_RATES_FAILURE, payload: { errorCode } });
      });
  };

export const registerFundRate = fundRateData => dispatch => {
  const { fundId } = fundRateData;
  dispatch({ type: REGISTER_FUND_RATES_START });
  axios
    .post(`/api/funds/${fundId}/rate`, fundRateData)
    .then(res => {
      const resData = res.data?.data || res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FUND_RATES_CREATED, {
          variant: 'success',
        })
      );
      dispatch({ type: REGISTER_FUND_RATES_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: REGISTER_FUND_RATES_FAILURE, payload: { errorCode } });
    });
};

export const editFundRate = fundRateData => dispatch => {
  const { fundId, id, ...rest } = fundRateData;
  dispatch({ type: EDIT_FUND_RATES_START });
  axios
    .put(`/api/funds/${fundId}/rate/${id}`, { ...rest })
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FUND_RATES_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({ type: EDIT_FUND_RATES_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: EDIT_FUND_RATES_FAILURE, payload: { errorCode } });
    });
};

export const deleteFundRate = fundRate => dispatch => {
  const { id, fundId, operationType } = fundRate;
  dispatch({ type: DELETE_FUND_RATE_START });
  axios
    .delete(`/api/funds/${fundId}/rate/${id}?operation=${operationType}`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: DELETE_FUND_RATE_SUCCESS, payload: resData });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FUND_RATE_DELETED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: DELETE_FUND_RATE_FAILURE, payload: { errorCode } });
    });
};

export const fetchFund = fundId => dispatch => {
  dispatch({ type: FETCH_FUND_START });
  axios
    .get(`/api/funds/${fundId}`)
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_FUND_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_FUNDS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_FUND_FAILURE,
        payload: { errorCode },
      });
    });
};

export const resetFundPayroll = () => dispatch =>
  dispatch({ type: RESET_FUND_PAYROLL });

export const verifyIfOrderIsFullyFinanced = orderId => dispatch => {
  dispatch({
    type: VERIFY_ORDER_FULLY_FINANCED_START,
    payload: { orderId },
  });
  axios
    .get(`/api/orders/${orderId}/checkfunds`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: VERIFY_ORDER_FULLY_FINANCED_SUCCESS,
        payload: { orderIsFullyFinanced: resData.completedOrderInvoice },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: VERIFY_ORDER_FULLY_FINANCED_FAILURE,
        payload: { errorCode },
      });
    });
};

export const verifyIfOrderIsFullyFinancedMIGRACIONCL = order => dispatch => {
  dispatch({
    type: VERIFY_ORDER_FULLY_FINANCED_START,
    payload: { orderId: order.id },
  });
  dispatch({
    type: VERIFY_ORDER_FULLY_FINANCED_SUCCESS,
    payload: { orderIsFullyFinanced: order.hasFundFinancing },
  });
};

export const updateSelectedFund =
  (fundId, selectedFund = null, operationSelected = '') =>
  dispatch => {
    dispatch({
      type: UPDATE_SELECTED_FUND,
      payload: { fundId, selectedFund, operationSelected },
    });
  };

export const resetFundFormDialog = () => dispatch => {
  dispatch({
    type: RESET_FUND_FORM_DIALOG,
  });
};

export const updateSelectedFundRate = selectedFundRate => dispatch => {
  dispatch({
    type: UPDATE_SELECTED_FUND_RATE,
    payload: { selectedFundRate },
  });
};

export const resetMessageResponseAndLoaders = () => dispatch => {
  dispatch({ type: RESET_MESSAGES_RESPONSE });
};

export const uploadCsvFunds = csvFile => dispatch => {
  dispatch({ type: UPLOAD_CSV_FUNDS_START });
  const formData = new FormData();
  formData.append('csv', csvFile, csvFile.name);
  axios
    .post(`/api/orderinvoicefund/csv`, formData)
    .then(res => {
      const { errorLines } = res.data;
      if (errorLines.length > 0) {
        dispatch({
          type: UPLOAD_CSV_FUNDS_FAILURE,
          payload: { errorLines },
        });
      } else {
        dispatch({ type: UPLOAD_CSV_FUNDS_SUCCESS });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CSV_FUNDS_UPLOADED, {
            variant: 'success',
          })
        );
      }
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPLOAD_CSV_FUNDS_FAILURE,
        payload: { errorCode },
      });
    });
};
