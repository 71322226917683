import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Link } from '@material-ui/core';

import { useStyles } from './styles';
import BaseDialog from '../../../../../components/dialogs/BaseDialog';

const ClosePayrollDialogComponent = ({
  isOpen,
  handleCloseDialog,
  title,
  description,
  handleSubmit,
}) => {
  const classes = useStyles();

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
    >
      <Grid container className={classes.infoContainer} direction="column">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submitBtn}
          onClick={handleSubmit}
        >
          Sí, cerrar nómina
        </Button>
        <Link
          component="button"
          variant="contained"
          underline="always"
          className={classes.backLink}
          onClick={handleCloseDialog}
        >
          No, volver atrás
        </Link>
      </Grid>
    </BaseDialog>
  );
};

ClosePayrollDialogComponent.defaultProps = {
  description: '',
  isOpen: false,
};

ClosePayrollDialogComponent.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export const ClosePayrollDialog = memo(ClosePayrollDialogComponent);
