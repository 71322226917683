import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Typography,
  TextareaAutosize,
  Button,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { convertSpacingToCss as spacing } from '../../../../../helpers/stylesHelpers';
import { useGetTransitionActions } from './useGetTransitionActions';
import SkeletonDataHistory from './SkeletonWorkflowSummarySelected';

const EmptyState = () => (
  <Box
    height={354}
    display= "flex"
    alignItems= "center"
    justifyContent= "center"
  >
    <Typography style={{
      fontWeight: 700,
      fontSize: 18,
    }}>
        No existe resumen de procesos
    </Typography>
  </Box>
);

type Props = {
  businessIdentifier: string;
  workFlowId: string;
};

const WorkflowSummarySelected = ({
  businessIdentifier,
  workFlowId,
}: Props) => {
  const { loading, isRefetching, transitionActions, error }
  = useGetTransitionActions({ businessIdentifier, workFlowId, enabled: false});

  return (
    <Card style={{ borderRadius: 16, boxShadow: 'unset', marginLeft: 20 }}>
      {loading || isRefetching ? (
        <SkeletonDataHistory/>
      )
        : (
          <Grid>
            {error || !transitionActions.length ? (
              <EmptyState/>
            )
              : (
                <Box>
                  <Box m={5} display="flex" justifyContent="space-between">
                    <Typography style={{
                      fontWeight: 700,
                      fontSize: 18,
                      paddingTop: 2,
                    }}>
                      Proceso de {transitionActions[0].workflow.name || ''}
                    </Typography>
                    <Button
                      disabled
                      style={transitionActions[0].buttonStyle}
                    >
                      {transitionActions[0].target}
                    </Button>
                  </Box>
                  <Grid item xs={12}>
                    <Box m={4} style={{ overflow: 'scroll'}}>
                      <Accordion elevation={0}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          style={{ display: 'inline-flex'}}
                        >
                          <Typography>{'Último Comentario'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextareaAutosize
                            disabled
                            minRows={6}
                            value={transitionActions[0].comment ? transitionActions[0].comment : 'No hay comentarios'}
                            aria-label="bold"
                            style={{
                              borderRadius: 20,
                              padding: spacing('sm lg') as string,
                              width: '100%',
                              borderColor: '#000',
                              resize: 'none',
                              overflow: 'auto',
                              fontSize: 18,
                              fontWeight: 700,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        aria-expanded={true}
                        elevation={0} style={{margin: 4}}
                        defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          id="summarySection"
                          style={{
                            flexDirection: 'row-reverse',
                            marginLeft: -10,
                          }}
                        >
                          <Typography style={{
                            marginLeft: 19,
                            fontWeight: 700,
                            fontSize: 18,
                          }}>{'Resumen del proceso'}</Typography>
                        </AccordionSummary>
                        <Box height={350}>
                          {transitionActions.map(item => (
                            <>
                              <Accordion elevation={0}>
                                <Box display="flex" justifyContent="space-between">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    style={{ display: 'inline-flex'}}
                                  >
                                    <Typography style={{
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}>
                                      {item.target}
                                    </Typography>
                                  </AccordionSummary>
                                  <Typography
                                    style={{
                                      paddingTop: 15,
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}
                                  >
                                    {item.createdAt}
                                  </Typography>
                                </Box>
                                <Box>
                                  <AccordionDetails style={{ display: 'block'}}>
                                    <Typography style={{ marginBottom: 10 }}>
                                      Modificado por: {item.modifiedByUserRef}
                                    </Typography>
                                    <TextareaAutosize
                                      disabled
                                      minRows={3}
                                      value={item.comment ? item.comment : 'No hay comentarios'}
                                      aria-label="bold"
                                      style={{
                                        borderRadius: 20,
                                        padding: spacing('sm lg') as string,
                                        width: '90%',
                                        borderColor: '#000',
                                        resize: 'none',
                                        overflow: 'auto',
                                      }}
                                    />
                                  </AccordionDetails>
                                </Box>
                              </Accordion>
                            </>
                          ))}
                        </Box>
                      </Accordion>
                    </Box>
                  </Grid>
                </Box>
              )}
          </Grid>
        )
      }
    </Card>
  );
};

export default WorkflowSummarySelected;
