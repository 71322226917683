import InboxZustandStore from './InboxZustandStore';

export const usePage = () => InboxZustandStore(state => state.page);
export const useItemsPerPage = () =>
  InboxZustandStore(state => state.itemsPerPage);
export const useGetSelectedOperations = () =>
  InboxZustandStore(state => state.selectedOperations);
export const useFilters = () => InboxZustandStore(state => state.filters);

export const useSetPage = () => InboxZustandStore(state => state.setPage);
export const useGoNextPage = () => InboxZustandStore(state => state.goNextPage);
export const useGoPrevPage = () => InboxZustandStore(state => state.goPrevPage);
export const useSetItemsPerPage = () =>
  InboxZustandStore(state => state.setItemsPerPage);
export const useSetSelectedOperations = () =>
  InboxZustandStore(state => state.setSelectedOperations);
export const useAppendSelectedOperation = () =>
  InboxZustandStore(state => state.appendSelectedOperation);
export const useRemoveSelectedOperation = () =>
  InboxZustandStore(state => state.removeSelectedOperation);
export const useClearSelectedOperations = () =>
  InboxZustandStore(state => state.clearSelectedOperations);
export const useSetStatusFilter = () =>
  InboxZustandStore(state => state.setStatusFilter);
export const useSetFieldFilter = () =>
  InboxZustandStore(state => state.setFieldFilter);
export const useSetOperationFilter = () =>
  InboxZustandStore(state => state.setOperationFilter);
export const useGetFilters = () => InboxZustandStore(state => state.getFilters);
export const useSetClearFilters = () =>
  InboxZustandStore(state => state.setClearFilters);

export const useStatusFilter = () =>
  InboxZustandStore(state => state.filters.status);
export const useFieldFilter = () =>
  InboxZustandStore(state => state.filters.field);
export const useOperationFilter = () =>
  InboxZustandStore(state => state.filters.operation);
