import { useSelector, RootStateOrAny } from 'react-redux';
import { Countries } from '../interfaces/globalInterfaces';

const useGetCountry = () => {
  const country: Countries = useSelector(
    (state: RootStateOrAny) => state.config.country
  );
  return country;
};

export default useGetCountry;
