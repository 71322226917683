import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const addPartialPaymentService = async (
  sourceType,
  sourceId,
  { amount, paymentDate, comment }
) => {
  try {
    const data = await axios.post(`/api/${sourceType}/${sourceId}/partialpay`, {
      amount,
      paymentDate,
      comment,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

export default addPartialPaymentService;
