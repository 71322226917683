import { makeStyles } from '@material-ui/core';
import { convertSpacingToCss } from '../../../../../../../helpers/stylesHelpers';

const useStyles = makeStyles(theme => ({
  headerTabsTab: {
    minWidth: '69px',
    textTransform: 'none',
  },
  root: {
    margin: convertSpacingToCss('0 -xl -xl'),
  },
  copyButton: {
    margin: '20px 20px 20px 30px',
  },
  panels: {
    padding: '0px 20px 20px 20px',
  },
  tabScore: {
    background: theme.palette.grey[100],
  },
  breakline: {
    display: 'block',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { useStyles };
