import axios from 'axios';
import {
  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_START,
  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_SUCCESS,
  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE,
  UPDATE_PAYMENT_ORDER_DETAILS_START,
  UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS,
  UPDATE_PAYMENT_ORDER_DETAILS_FAILURE,
  RESET_PAYROLL_DETAILS_STORE,
} from './types';

import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';
import { getErrorCode } from '../helpers/handleErrors';

export const getOrCreatePaymentOrderDetails = orderId => dispatch => {
  dispatch({ type: GET_OR_CREATE_PAYMENT_ORDER_DETAILS_START });
  axios
    .get(`/api/payment/order/${orderId}/detail`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: GET_OR_CREATE_PAYMENT_ORDER_DETAILS_SUCCESS,
        payload: resData,
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE,
          { variant: 'error' }
        )
      );
      dispatch({
        type: GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE,
      });
    });
};

export const updatePaymentOrderDetails =
  (orderId, paymentOrderDetails) => dispatch => {
    dispatch({ type: UPDATE_PAYMENT_ORDER_DETAILS_START });
    axios
      .patch(`/api/payment/order/${orderId}/detail`, paymentOrderDetails)
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS,
            { variant: 'success' }
          )
        );
        dispatch({
          type: UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_PAYMENT_ORDER_DETAILS_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.UPDATE_PAYMENT_ORDER_DETAILS_FAILURE,
            { variant: 'error' }
          )
        );
      });
  };

export const resetPayrollDetailsReduxStore = () => dispatch => {
  dispatch({ type: RESET_PAYROLL_DETAILS_STORE });
};
