import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import AlertForm from '../elements/AlertForm';
import {
  DATE_FORMAT,
  LABEL_MUST_SELECT_ORDER,
  LABEL_NO_ORDERS_REPLACE,
  GLOBAL_REASONS,
  STATUS_HARD_COLLECTION,
  STATUS_PAID,
  STATUS_POST_VERIFIED,
  STATUS_REJECTED,
  STATUS_REPLACED,
  STATUS_VERIFIED,
  COUNTRY_CODE_MX,
  STATUS_DEFAULT,
  STATUS_COMPLETE,
  STATUS_APPEALED,
  STATUS_IN_REVIEW,
  STATUS_IN_CONCILIATION,
  DIRECT_FINANCING,
  CONFIRMING,
  PAYMENTS,
} from '../../helpers/Constants';
import { checkStatusAuth } from '../../helpers/validation/auth';
import {
  fetchOrdersIdByPayerRut,
  resetStateFetchOrdersIdByPayerRut,
} from '../../actions/orderActions';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import useStatuses from '../../hooks/useStatuses';
import {
  dateWithoutFormatterStartOfDay,
  dateWithoutFormatter,
} from '../../helpers/DateUtils';
import { fetchCriticalStatusInfo } from '../../actions/businessAction';
import Skeleton from '../elements/Skeleton';

const STATUSES_AND_DEFAULT_REASONS = {
  [STATUS_REJECTED]: 'HIGH_RISK',
  [STATUS_HARD_COLLECTION]: 'CREDIT_NOTE',
};

const useStyles = makeStyles({
  selectInput: {
    marginBottom: 10,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    margin: convertSpacingToCss('sm'),
  },
  spacingLeft: {
    marginLeft: 15,
  },
});

const ChangeStatusInvoiceDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  invoice,
  title,
  error,
  isLoading,
  handleFetchInvoiceSelectedOrder,
  invoicesReplaced,
  orderIdsToReplace,
}) => {
  const classes = useStyles();
  const { getStatuses } = useStatuses();
  const { rules } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { loadingOrders, updatedOrder } = useSelector(state => state.order);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  const [showPaidDateInput, setShowPaidDateInput] = useState(false);
  const [showDefaultDateInput, setShowDefaultDateInput] = useState(false);
  const [showConciliationDateInput, setShowConciliationDateInput] =
    useState(false);
  const [showOrdersIdToReplaceSelector, setShowOrdersIdToReplaceSelector] =
    useState(false);
  const [disabledCheckButton, setDisabledCheckButton] = useState(false);
  const [showStatusReason, setShowStatusReason] = useState(false);
  const [fundPaymentDate, setFundPaymentDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [statusReason, setStatusReason] = useState(null);
  const [foliosReplacedInvoices, setFoliosReplacedInvoices] = useState([]);
  const [destinationOrderId, setDestinationOrderId] = useState('');
  const [destinationInvoiceFolio, setDestinationInvoiceFolio] = useState('');
  const [oldStatus, setOldStatus] = useState();
  const [notCalculateSurplus, setNotCalculateSurplus] = useState(false);

  const {
    business: { id: businessId },
    criticalStatusInfoIsLoading,
    criticalStatusInfo,
  } = useSelector(state => state.business);

  const [hasDebt, setHasDebt] = useState(false);

  const handleShowStatusReason = status => {
    const showSelector =
      status === STATUS_HARD_COLLECTION ||
      status === STATUS_VERIFIED ||
      status === STATUS_POST_VERIFIED ||
      status === STATUS_REJECTED ||
      status === STATUS_APPEALED;
    setShowStatusReason(showSelector);
  };

  const getReason = () => {
    let reason;
    if (invoice.StatusReasons?.length) {
      ({
        StatusReasons: [{ reason }],
      } = invoice);
    } else if (invoice.statusReason?.length) {
      reason = invoice.statusReason;
    }
    return reason;
  };

  useEffect(() => {
    const { status } = invoice;
    handleShowStatusReason(status);
    setStatusSelected(status);
    setStatusReason(getReason());
    switch (status) {
      case STATUS_REPLACED:
        // eslint-disable-next-line no-case-declarations
        const { Relationships: { orderId = '', folio = '' } = {} } = invoice;
        setDestinationOrderId(orderId.toString());
        setDestinationInvoiceFolio(folio.toString());
        setShowOrdersIdToReplaceSelector(true);
        break;
      case STATUS_PAID:
        setShowPaidDateInput(true);
        setFundPaymentDate(
          invoice.fundPaymentDate || dateWithoutFormatterStartOfDay(Date.now())
        );
        break;
      case STATUS_DEFAULT:
        setShowDefaultDateInput(true);
        setDefaultDate(invoice.defaultDate || dateWithoutFormatter(Date.now()));
        break;
      case STATUS_IN_CONCILIATION:
        setShowConciliationDateInput(true);
        setPaymentDate(invoice.paymentDate || dateWithoutFormatter(Date.now()));
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  useEffect(() => {
    if (error && statusSelected === STATUS_REPLACED) {
      setDisabledCheckButton(true);
    }
  }, [statusSelected, error]);

  useEffect(() => {
    if (invoicesReplaced?.length) {
      const folios = invoicesReplaced.map(({ folio }) => folio);
      setFoliosReplacedInvoices(folios);
    }
  }, [invoicesReplaced]);

  useEffect(() => {
    if (open) {
      setOldStatus(invoice.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchCriticalStatusInfo(businessId));
    }
  }, [businessId]);

  useEffect(() => {
    if (criticalStatusInfo) {
      setHasDebt(
        (criticalStatusInfo.debtAmountPaymentsOver5DaysInterest > 0 ||
          criticalStatusInfo.debtAmountDirectFinancingOver5Days > 0 ||
          criticalStatusInfo.debtAmountDirectFinancingOver60Days > 0 ||
          criticalStatusInfo.criticalStateAmount > 0 ||
          criticalStatusInfo.accountBalance < 0) ??
          false
      );
    }
  }, [criticalStatusInfo]);

  const handleStatusReason = status => {
    if (
      [STATUS_REJECTED, STATUS_HARD_COLLECTION, STATUS_APPEALED].includes(
        status
      )
    ) {
      const reason = STATUSES_AND_DEFAULT_REASONS[status] || '';
      setStatusReason(reason);
    }
  };

  const handleStateChange = e => {
    const {
      target: { value },
    } = e;
    setStatusSelected(value);
    if (value === STATUS_REPLACED) {
      dispatch(
        fetchOrdersIdByPayerRut(
          invoice?.payerIdentifier,
          invoice?.amount,
          updatedOrder?.orderType
        )
      );
    } else {
      dispatch(resetStateFetchOrdersIdByPayerRut());
      setDisabledCheckButton(false);
    }
    setShowPaidDateInput(value === STATUS_PAID);
    setFundPaymentDate(
      invoice?.fundPaymentDate || dateWithoutFormatterStartOfDay(Date.now())
    );
    setShowDefaultDateInput(value === STATUS_DEFAULT);
    setDefaultDate(invoice?.defaultDate || dateWithoutFormatter(Date.now()));
    setShowConciliationDateInput(value === STATUS_IN_CONCILIATION);
    setPaymentDate(invoice?.paymentDate || dateWithoutFormatter(Date.now()));
    handleShowStatusReason(value);
    handleStatusReason(value);
    setShowOrdersIdToReplaceSelector(value === STATUS_REPLACED);
  };

  const handleSubmitDialog = () => {
    const invoiceReplaced =
      invoicesReplaced.find(({ folio }) => folio === destinationInvoiceFolio) ||
      {};
    handleSubmit({
      status: statusSelected,
      statusReason,
      fundPaymentDate,
      defaultDate,
      paymentDate,
      destinationId: invoiceReplaced.id,
      oldStatus,
      notCalculateSurplus,
    });
  };

  const handleSelectDestinationOrder = data => {
    let orderId = data;
    if (data?.target) {
      ({ value: orderId } = data.target);
    }
    orderId = orderId.replace(/[^0-9]/g, '');
    setDestinationOrderId(orderId);
    handleFetchInvoiceSelectedOrder(orderId);
  };

  const handleSelectDestinationInvoice = folio => {
    const folioValue =
      folio && typeof folio === 'object' ? folio.target.value : folio || '';
    if (folioValue !== '' && destinationInvoiceFolio !== folioValue) {
      setDestinationInvoiceFolio(folioValue);
    }
  };

  const getTextValidator = props => (
    <TextValidator
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      validators={['required']}
      errorMessages={['Campo requerido']}
    />
  );

  const handleSelectDebtMessage = (operationType = DIRECT_FINANCING) => {
    const operation =
      operationType === CONFIRMING || operationType === PAYMENTS
        ? PAYMENTS
        : operationType;
    const values = {
      CL: {
        DIRECT_FINANCING:
          '¡Recuerda revisar el estado cartera del cliente!, si hay wallet negativa, estados críticos, mora sobre 5 días en Payments, o mora sobre 60 días en FD, riesgo hará un descuento',
        EARLY_PAYMENT:
          '¡Recuerda revisar el estado cartera del cliente!, si hay wallet negativa, estados críticos, mora sobre 5 días en Payments, o mora sobre 60 días en FD, riesgo hará un descuento',
        PAYMENTS:
          '¡Recuerda revisar el estado cartera del cliente!, si hay wallet negativa, estados críticos, mora sobre 5 días en Payments, o mora sobre 60 días en FD, se le cobrará junto al aporte pagador',
      },
      MX: {
        DIRECT_FINANCING:
          '¡Recuerda revisar el estado cartera del cliente!, si hay estados críticos, o mora sobre 5 días en FD o mora sobre 5 días en Payments, riesgo hará un descuento',
        EARLY_PAYMENT:
          '¡Recuerda revisar el estado cartera del cliente!, si hay estados críticos, o mora sobre 5 días en FD o mora sobre 5 días en Payments, riesgo hará un descuento',
        PAYMENTS:
          '¡Recuerda revisar el estado cartera del cliente!, si hay estados críticos, o mora sobre 5 días en FD o mora sobre 5 días en Payments, se le cobrará junto al aporte pagador',
      },
    };
    return values[country][operation];
  };

  const notAvailableHoldStatuses = ['ACTIVE', 'TO_DEPOSIT'];

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      data-cy="changeStatusInvoiceDialogContainer"
    >
      <ValidatorForm onSubmit={handleSubmitDialog}>
        <Grid container>
          <Grid item xs={12} className={classes.selectInput}>
            <SelectValidator
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={handleStateChange}
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
              data-cy="changeStatusInvoiceDialogSelectInput"
            >
              {/* CONSIDERAR REFACTOR DE ESTADOS POR PAIS */}
              {getStatuses('factura')
                .filter(
                  status =>
                    !(
                      status.key === STATUS_REPLACED &&
                      country === COUNTRY_CODE_MX
                    )
                )
                .map(({ key, label }) => (
                  <MenuItem
                    key={key}
                    value={key}
                    disabled={
                      !checkStatusAuth(key, 'INVOICE', rules) ||
                      (invoice?.isHold &&
                        notAvailableHoldStatuses.includes(key))
                    }
                    data-cy={`changeStatusInvoiceDialogSelectInputValue_${key}`}
                  >
                    {invoice?.isHold &&
                    notAvailableHoldStatuses.includes(key) ? (
                      <Typography variant="body2" color="textPrimary">
                        {label}{' '}
                        <span style={{ fontStyle: 'italic' }}>
                          (orden seleccionada con facturas temporales)
                        </span>
                      </Typography>
                    ) : (
                      <span>{label}</span>
                    )}
                  </MenuItem>
                ))}
            </SelectValidator>
          </Grid>

          {statusSelected === STATUS_IN_REVIEW && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom={2}
            >
              <Skeleton
                variant="rect"
                width={400}
                height={70}
                isLoading={criticalStatusInfoIsLoading}
              >
                {hasDebt && (
                  <AlertForm
                    variant="warning"
                    message={handleSelectDebtMessage(updatedOrder?.orderType)}
                  />
                )}
              </Skeleton>
            </Box>
          )}

          {showOrdersIdToReplaceSelector &&
            (!loadingOrders ? (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="auto-complete-destination-order"
                    fullWidth
                    value={destinationOrderId}
                    options={orderIdsToReplace}
                    onChange={(_event_, newValue) =>
                      handleSelectDestinationOrder(newValue)
                    }
                    getOptionLabel={option => option}
                    name="destinationOrderId"
                    type="string"
                    noOptionsText={LABEL_NO_ORDERS_REPLACE}
                    renderInput={params => (
                      <TextValidator
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        value={destinationOrderId}
                        variant="outlined"
                        label="Orden de destino"
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="auto-complete-destination-factura"
                    fullWidth
                    value={destinationInvoiceFolio}
                    options={foliosReplacedInvoices}
                    onChange={(_event_, newValue) =>
                      handleSelectDestinationInvoice(newValue)
                    }
                    getOptionLabel={option => option}
                    name="destinationInvoiceFolio"
                    type="string"
                    noOptionsText={LABEL_MUST_SELECT_ORDER}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        value={destinationInvoiceFolio}
                        variant="outlined"
                        label="Factura de destino"
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} className={classes.loader}>
                <CircularProgress />
              </Grid>
            ))}
          {showStatusReason && (
            <Grid item xs={12} className={classes.selectInput}>
              <SelectValidator
                fullWidth
                variant="outlined"
                name="operationReason"
                id="operation-reason-select"
                value={statusReason}
                onChange={e => setStatusReason(e.target.value)}
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
                data-cy="changeStatusInvoiceDialogSelectInputReason"
              >
                {GLOBAL_REASONS.filter(
                  r =>
                    r.type === `INVOICE_${statusSelected}` &&
                    r.country.includes(country)
                ).map(reason => (
                  <MenuItem
                    key={reason.value}
                    value={reason.value}
                    data-cy={`changeStatusInvoiceDialogSelectInputReason_${reason.value}`}
                  >
                    <Typography variant="body1">{reason.label}</Typography>
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}
          {showPaidDateInput && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de pago fondo"
                  name="fundPaymentDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(fundPaymentDate)}
                  onChange={date => setFundPaymentDate(date)}
                  autoOk
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {showDefaultDateInput && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de estado en default"
                  name="defaultDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(defaultDate)}
                  onChange={date => setDefaultDate(date)}
                  autoOk
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {showConciliationDateInput && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de pago pyme"
                  name="paymentDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(paymentDate)}
                  onChange={date => setPaymentDate(date)}
                  minDateMessage="Fecha inválida"
                  maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
                  autoOk
                  disableFuture
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {[STATUS_COMPLETE, STATUS_PAID, STATUS_IN_CONCILIATION].includes(
            statusSelected
          ) && (
            <Box className={classes.spacingLeft}>
              <Typography variant="body1">
                <Box fontWeight="fontWeightBold">Excepción</Box>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notCalculateSurplus}
                    onChange={() =>
                      setNotCalculateSurplus(!notCalculateSurplus)
                    }
                    name="notCalculateSurplus"
                    color="primary"
                    data-cy="changeStatusInvoiceDialogException"
                  />
                }
                label="No calcular los excedentes"
              />
            </Box>
          )}
          {!loadingOrders && error && (
            <Grid item xs={12} align="center">
              <AlertForm variant="error" message={error} />
            </Grid>
          )}
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
            disabled={disabledCheckButton}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeStatusInvoiceDialog.defaultProps = {
  error: null,
  orderIdsToReplace: [],
  invoicesReplaced: [],
  isLoading: false,
  handleFetchInvoiceSelectedOrder: () => null,
};

ChangeStatusInvoiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  handleFetchInvoiceSelectedOrder: PropTypes.func,
  error: PropTypes.bool,
  orderIdsToReplace: PropTypes.arrayOf(Number),
  invoice: PropTypes.shape({
    name: PropTypes.string,
    VerificationReasons: PropTypes.arrayOf(
      PropTypes.shape({ reason: PropTypes.string })
    ),
    StatusReasons: PropTypes.arrayOf(
      PropTypes.shape({ reason: PropTypes.string })
    ),
    Relationships: PropTypes.shape({
      orderId: PropTypes.string,
      folio: PropTypes.string,
    }),
    status: PropTypes.string,
    paymentDate: PropTypes.string,
    payerIdentifier: PropTypes.string,
    rutReceptor: PropTypes.string,
    mntTotal: PropTypes.number,
    amount: PropTypes.number,
    statusReason: PropTypes.string,
    defaultDate: PropTypes.string,
    fundPaymentDate: PropTypes.string,
    isHold: PropTypes.bool,
  }).isRequired,
  invoicesReplaced: PropTypes.arrayOf(Object),
};

export default ChangeStatusInvoiceDialog;
