import {
  IconButton,
  Menu,
  Typography,
  Box,
  CircularProgress,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  updateStakeholder,
  deleteStakeholder,
  resetStakeholderErrors,
} from '../../actions/stakeholderActions';
import { fetchCompliancePerson } from '../../actions/businessAction';
import { consultDebtServiceByIdentifier } from '../../actions/debtServiceActions';
import {
  BUSINESS_BURO_PERFORM,
  BUSINESS_COMPLIANCE_PERFORM,
  STAKEHOLDERS_DELETE_PERFORM,
} from '../../helpers/performsType';
import StakeholderDialogMX from '../dialogs/StakeholderDialogMX';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../../helpers/Constants';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import MenuItem from '../elements/MenuItem';
import { EyeOpen, Edit, Reload } from '../icons';
import StakeholderDocuments from '../StakeholderDocuments';
import ComplianceDialog from '../dialogs/ComplianceDialog';
import DebtServiceDialog from '../dialogs/DebtServiceDialog';
import Can from '../Can';
import Delete from '../icons/Delete';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { features } from '../../config/features';
import StakeholderDialogCL from '../dialogs/StakeholderDialogCL';
import { t13s } from '../../translationKeys';

const { REACT_APP_BURO_PF_METABASE_URL } = process.env;

const complianceData = compliance => {
  let labelCompliance;
  const types = compliance?.ComplianceDetail?.types;

  if (compliance?.lastRequest) {
    labelCompliance = 'Sin coincidencias';
  }
  if (types) {
    labelCompliance = compliance?.ComplianceDetail?.types;
  }

  if (labelCompliance && types) {
    return (
      <>
        <Typography variant="body1" color="textSecondary">
          Coincidencias en
        </Typography>

        <Typography variant="body1" color="textSecondary" component="div">
          <Box fontWeight="fontWeightBold">{labelCompliance}</Box>
        </Typography>
      </>
    );
  }

  return (
    <Typography variant="body1" color="textSecondary">
      {labelCompliance || '-'}
    </Typography>
  );
};

const BusinessStakeholdersTable = ({
  selectedStakeholder,
  setSelectedStakeholder,
  country,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countryFeatures = features[country];

  const [anchorEl, setAnchorEl] = useState(null);
  const [stakeholderSelected, setStakeholderSelected] = useState({});
  const [showCreateStakeholderDialog, setShowCreateStakeholderDialog] =
    useState(false);
  const [editStakeholder, setEditStakeholder] = useState(false);
  const [showComplianceDialog, setShowComplianceDialog] = useState(false);
  const [showDebtDialog, setDebtDialog] = useState(false);
  const [showConfirmDeleteStakeholder, setShowConfirmDeleteStakeholder] =
    useState(false);

  const {
    businessStakeholders,
    businessStakeholdersIsLoading,
    deleteStakeholderIsLoading,
    deleteStakeholderIsDone,
    deleteStakeholderError,
    updateStakeholderIsLoading,
    updateStakeholderIsDone,
    updateStakeholderError,
  } = useSelector(state => state.stakeholders);
  const { stakeholderVerifyDocuments } = useSelector(state => state.documents);
  const {
    complianceIsDone,
    complianceLoading,
    complianceList = {},
  } = useSelector(state => state.business);
  const {
    business: { id: businessId },
  } = useSelector(state => state.business);
  const { debtList, consulting, consulted, debtServiceError } = useSelector(
    state => state.debtService
  );

  useEffect(() => {
    if (updateStakeholderIsDone) {
      setEditStakeholder(false);
      setShowCreateStakeholderDialog(false);
    }
  }, [updateStakeholderIsDone]);

  useEffect(() => {
    if (complianceIsDone) {
      setShowComplianceDialog(false);
    }
  }, [complianceIsDone]);

  useEffect(() => {
    if (consulted) {
      setDebtDialog(false);
    }
  }, [consulted]);

  useEffect(() => {
    if (deleteStakeholderIsDone) {
      setAnchorEl(null);
      setShowConfirmDeleteStakeholder(false);
    }
  }, [deleteStakeholderIsDone]);

  const itemSelected = (e, rowData = {}) => {
    setStakeholderSelected(rowData);
    setAnchorEl(e.currentTarget);
  };

  const editStakeholderCallback = stakeholder => {
    setSelectedStakeholder(stakeholder);
    setShowCreateStakeholderDialog(true);
    setEditStakeholder(true);
  };

  const viewStakeholderCallback = stakeholder => {
    setSelectedStakeholder(stakeholder);
    setShowCreateStakeholderDialog(true);
  };

  const handleStakeholderSubmit = stakeholder => {
    const formatedStakeholder = { ...stakeholder };
    delete formatedStakeholder.id;
    delete formatedStakeholder.createdAt;
    delete formatedStakeholder.updatedAt;
    delete formatedStakeholder.tableData;
    dispatch(updateStakeholder({ ...formatedStakeholder }));
  };

  const complianceStakeholderCallback = stakeholder => {
    setSelectedStakeholder(stakeholder);
    setShowComplianceDialog(true);
  };

  const fetchComplianceOnClick = () => {
    // we do this just to not change the dialog, sorry not sorry
    const {
      types: compliance = [],
      lastRequest: lastConsultCompliance,
      ComplianceDetail,
    } = complianceList[stakeholderSelected.identifier];

    complianceStakeholderCallback({
      ...stakeholderSelected,
      compliance: {
        compliance,
        lastConsultCompliance,
        name: `${stakeholderSelected.name} ${stakeholderSelected.fatherLastName} ${stakeholderSelected.motherLastName}`,
        complianceDetail: ComplianceDetail,
      },
    });
    setAnchorEl(null);
  };

  const submitStakeholderCompliance = () => {
    const { identifier, name, fatherLastName, motherLastName } =
      selectedStakeholder;
    dispatch(
      fetchCompliancePerson(
        country,
        identifier,
        name,
        fatherLastName,
        motherLastName,
        businessId
      )
    );
  };

  const columns = [
    {
      title: 'Nombres y apellidos',
      field: 'name',
      width: '400px',
      render: stakeholder => {
        const { name, fatherLastName, motherLastName } = stakeholder;
        return (
          <Typography variant="body1" color="textPrimary">
            {`${name} ${fatherLastName} ${motherLastName}`}
          </Typography>
        );
      },
    },
    {
      title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)}`,
      field: 'identifier',
      width: '230px',
      render: stakeholder => (
        <Typography variant="body1" color="textPrimary">
          {stakeholder.identifier}
        </Typography>
      ),
    },
    {
      title: 'Nº teléfono',
      field: 'phone',
      width: '230px',
      render: stakeholder => (
        <Typography variant="body1" color="textPrimary">
          {stakeholder.phone}
        </Typography>
      ),
    },
    {
      title: 'Email',
      field: 'email',
      width: '400px',
      render: stakeholder => (
        <Typography variant="body1" color="textPrimary">
          {stakeholder.email}
        </Typography>
      ),
    },
    {
      title: 'Compliance',
      field: 'compliance',
      width: '150px',
      render: stakeholder => {
        const compliance = complianceList[stakeholder.identifier];

        return compliance?.loading || !compliance ? (
          <CircularProgress size={22} />
        ) : (
          complianceData(compliance)
        );
      },
    },
    {
      title: t(t13s.LABEL.DEBT_SERVICE_NAME),
      field: 'tax',
      width: '200px',
      render: stakeholder => {
        const buro =
          debtList?.stakeholder && debtList?.stakeholder[stakeholder?.id]
            ? debtList?.stakeholder[stakeholder?.id]
            : {};
        const { loading, debtService } = buro;
        return (
          <>
            {loading ? (
              <CircularProgress size={22} />
            ) : (
              <Typography variant="body1" color="textPrimary">
                <CountryFormatHelper
                  value={debtService?.totalAmount}
                  countryId={country}
                  variant="currency"
                />
              </Typography>
            )}
          </>
        );
      },
    },
    {
      hidden:
        !countryFeatures.businessDetailsSection.legal.stakeholdersHasDocuments,
      title: 'Docs.',
      width: '50px',
      render: rowData => {
        const stakeholder =
          stakeholderVerifyDocuments[rowData.stakeholderId] || {};
        return (
          <>
            {stakeholder.verifyIsLoading ? (
              <CircularProgress size={22} />
            ) : (
              <Box
                color={
                  stakeholder.allDocumentsCompleted
                    ? 'success.main'
                    : 'error.main'
                }
              >
                {stakeholder.allDocumentsCompleted ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}
              </Box>
            )}
          </>
        );
      },
    },
    {
      sorting: false,
      width: '62px',
      align: 'right',
      render: stakeholder => (
        <IconButton onClick={e => itemSelected(e, stakeholder)}>
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {showCreateStakeholderDialog && country === COUNTRY_CODE_MX && (
        <StakeholderDialogMX
          isOpen={showCreateStakeholderDialog}
          handleClose={() => {
            setEditStakeholder(false);
            setShowCreateStakeholderDialog(false);
            dispatch(resetStakeholderErrors());
          }}
          loading={updateStakeholderIsLoading}
          selectedStakeholder={selectedStakeholder}
          editStakeholder={editStakeholder}
          onSubmit={handleStakeholderSubmit}
          error={updateStakeholderError}
        />
      )}
      {showCreateStakeholderDialog && country === COUNTRY_CODE_CL && (
        <StakeholderDialogCL
          isOpen={showCreateStakeholderDialog}
          handleClose={() => {
            setEditStakeholder(false);
            setShowCreateStakeholderDialog(false);
            dispatch(resetStakeholderErrors());
          }}
          loading={updateStakeholderIsLoading}
          selectedStakeholder={selectedStakeholder}
          disableSave={!editStakeholder}
          onSubmit={handleStakeholderSubmit}
          error={updateStakeholderError}
        />
      )}
      {showDebtDialog && (
        <DebtServiceDialog
          saving={consulting}
          open={showDebtDialog}
          handleCloseDialog={() => setDebtDialog(false)}
          handleSubmit={() =>
            dispatch(
              consultDebtServiceByIdentifier(
                stakeholderSelected.stakeholderId,
                'stakeholder',
                stakeholderSelected.identifier
              )
            )
          }
          error={debtServiceError}
          debtServiceData={{
            name: `${stakeholderSelected.name} ${stakeholderSelected.fatherLastName} ${stakeholderSelected.motherLastName}`,
            reloadId: stakeholderSelected.identifier,
            ...debtList?.stakeholder[stakeholderSelected?.id].debtService,
          }}
        />
      )}
      {showComplianceDialog && (
        <ComplianceDialog
          open={showComplianceDialog}
          handleCloseDialog={() => setShowComplianceDialog(false)}
          handleSubmit={submitStakeholderCompliance}
          complianceInformation={selectedStakeholder?.compliance}
          loading={complianceLoading}
        />
      )}
      {showConfirmDeleteStakeholder && (
        <ConfirmDialog
          isOpen={showConfirmDeleteStakeholder}
          handleClose={() => {
            setShowConfirmDeleteStakeholder(false);
            dispatch(resetStakeholderErrors());
          }}
          buttonLabel="Eliminar"
          loading={deleteStakeholderIsLoading}
          buttonOnAccept={() =>
            dispatch(deleteStakeholder(stakeholderSelected))
          }
          title="Eliminar representante legal"
          message={`¿Deseas eliminar al representante ${stakeholderSelected.name} ${stakeholderSelected.fatherLastName} ${stakeholderSelected.motherLastName}?`}
          error={deleteStakeholderError}
        />
      )}
      <MaterialTable
        style={{ width: '100%', boxShadow: 'none' }}
        columns={columns}
        data={businessStakeholders}
        isLoading={businessStakeholdersIsLoading}
        options={{
          emptyRowsWhenPaging: false,
          sorting: true,
          pageSize: 100,
          detailPanelType: 'single',
        }}
        components={{
          Toolbar: () => null,
          Pagination: () => null,
        }}
        localization={{
          pagination: {
            labelRowsSelect: 'representantes',
            labelRowsPerPage: 'Representantes por página',
            labelDisplayedRows: '{count} representantes totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay representantes para mostrar',
          },
        }}
        // if in the country the stakeholders haven't documents, we don't pass detailPanel to table
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(countryFeatures.businessDetailsSection.legal
          .stakeholdersHasDocuments
          ? {
              detailPanel: ({ id }) => (
                <StakeholderDocuments stakeholderId={id} />
              ),
            }
          : {})}
      />

      <Menu
        id="long-menu-documents"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          key="ver"
          icon={<EyeOpen />}
          onClick={() => {
            viewStakeholderCallback(stakeholderSelected);
            setAnchorEl(null);
          }}
        >
          Ver toda la información
        </MenuItem>
        <MenuItem
          key="editar"
          icon={<Edit />}
          onClick={() => {
            editStakeholderCallback(stakeholderSelected);
            setAnchorEl(null);
          }}
        >
          Editar Representante Legal
        </MenuItem>
        <Can
          perform={BUSINESS_COMPLIANCE_PERFORM}
          yes={() => (
            <MenuItem
              key="compliance"
              icon={<Reload />}
              onClick={fetchComplianceOnClick}
            >
              Consultar Compliance
            </MenuItem>
          )}
        />
        {stakeholderSelected &&
          debtList?.stakeholder &&
          debtList?.stakeholder[stakeholderSelected?.id]?.canConsult && (
            <Can
              perform={BUSINESS_BURO_PERFORM}
              yes={() => (
                <>
                  <MenuItem
                    key="buro"
                    icon={<Reload />}
                    onClick={() => {
                      setAnchorEl(null);
                      setDebtDialog(true);
                    }}
                  >
                    Consultar {t(t13s.LABEL.DEBT_SERVICE_NAME)}
                  </MenuItem>
                  {country === COUNTRY_CODE_MX && (
                    <MenuItem
                      key="buroInfo"
                      icon={<EyeOpen />}
                      onClick={() => {
                        setAnchorEl(null);
                        window.open(
                          `${REACT_APP_BURO_PF_METABASE_URL}?rfc_stakeholder=${stakeholderSelected.identifier}`
                        );
                      }}
                    >
                      Ver Buró
                    </MenuItem>
                  )}
                </>
              )}
            />
          )}
        <Can
          perform={STAKEHOLDERS_DELETE_PERFORM}
          yes={() => (
            <MenuItem
              key="deleteStakeholder"
              icon={<Delete />}
              onClick={() => setShowConfirmDeleteStakeholder(true)}
            >
              Eliminar representante
            </MenuItem>
          )}
        />
      </Menu>
    </>
  );
};

BusinessStakeholdersTable.propTypes = {
  selectedStakeholder: PropTypes.shape({
    compliance: PropTypes.shape({
      compliance: PropTypes.arrayOf(PropTypes.string),
      lastConsultCompliance: PropTypes.string,
      name: PropTypes.string,
    }),
    businessId: PropTypes.number,
    identifier: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    fatherLastName: PropTypes.string,
    motherLastName: PropTypes.string,
  }).isRequired,
  setSelectedStakeholder: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
};

export default BusinessStakeholdersTable;
