import React from 'react';
import PropTypes from 'prop-types';
import { alpha, Chip, styled } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  COUNTRY_CODE_CL,
  INVOICE_STATUS_CL,
  INVOICE_STATUS_MX,
} from '../../../helpers/Constants';

const STATUSES = ['ACTIVE', 'IN_REVIEW', 'COMPLETE'];

const CustomChip = styled(Chip)(({ theme, status, country }) => {
  const { stateText1, stateText4 } = theme.palette.payments;
  const {
    complete,
    approved,
    approvedFund,
    pvApproved,
    rejected,
    renegotiation,
    default: defaultStateColor,
  } = theme.palette.payments.statusColors;
  const approvedFundColor =
    country === COUNTRY_CODE_CL ? approved : approvedFund;

  const defaultColor = {
    color: stateText4,
    background: alpha(stateText4, 0.24),
  };

  const statusColor = {
    ACTIVE: {
      color: stateText4,
      background: alpha(stateText4, 0.24),
    },
    PAID: {
      color: stateText4,
      background: alpha(stateText4, 0.24),
    },
    IN_REVIEW: {
      color: stateText1,
      background: alpha(stateText1, 0.24),
    },
    REVIEWED: {
      color: stateText1,
      background: alpha(stateText1, 0.24),
    },
    APPEALED: {
      color: stateText1,
      background: alpha(stateText1, 0.24),
    },
    COMPLETE: {
      color: complete,
      background: alpha(complete, 0.24),
    },
    APPROVED: {
      color: approved,
      background: alpha(approved, 0.24),
    },
    APPROVED_FUND: {
      color: approvedFundColor,
      background: alpha(approvedFundColor, 0.24),
    },
    PV_APPROVED: {
      color: pvApproved,
      background: alpha(pvApproved, 0.24),
    },
    TO_DEPOSIT: {
      color: pvApproved,
      background: alpha(pvApproved, 0.24),
    },
    REJECTED: {
      color: rejected,
      background: alpha(rejected, 0.24),
    },
    RENEGOTIATION: {
      color: renegotiation,
      background: alpha(renegotiation, 0.24),
    },
    DEFAULT: {
      color: defaultStateColor,
      background: alpha(defaultStateColor, 0.24),
    },
  };

  return {
    fontSize: 12,
    height: 24,
    ...(statusColor[status] ?? defaultColor),
  };
});

const getStatusByCountry = ({ status, country }) => {
  const invoicesStatus =
    country === COUNTRY_CODE_CL ? INVOICE_STATUS_CL : INVOICE_STATUS_MX;
  return invoicesStatus[status];
};

const StatusChip = ({ status }) => {
  const country = useSelector(state => state.config.country);
  const label = getStatusByCountry({ status, country }) ?? '-';
  return <CustomChip label={label} status={status} country={country} />;
};

StatusChip.defaultProps = {
  status: 'active',
};

StatusChip.propTypes = {
  status: PropTypes.oneOf(STATUSES),
};

export default StatusChip;
