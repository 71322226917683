import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import AgreementContactColumn from './AgreementContactColumn';
import {
  fetchAgreementsContacts,
  deleteAgreementsContacts,
  registerAgreementsContacts,
  editAgreementsContacts,
} from '../../actions/agreementActions';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import FormAgreementContactDialog from '../dialogs/FormAgreementContactDialog';

const useStyles = makeStyles({
  window: {
    padding: '30px',
    justifyContent: 'space-around',
  },
  main: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  columnContact: {
    maxWidth: 350,
  },
});

const AGREEMENT_CONTACT_TYPES = ['GENERAL', 'FINANZAS', 'ABASTECIMIENTO'];

const AgreementContactSection = ({ agreementId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.agreement.contacts);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactTypeCreation, setContactTypeCreation] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const {
    loadingContacts,
    savingContacts,
    savedContacts,
    editingContacts,
    editedContacts,
    deletingContacts,
    deletedContacts,
    contactsError,
  } = useSelector(state => state.agreement);

  useEffect(() => {
    dispatch(fetchAgreementsContacts(agreementId));
  }, [agreementId, dispatch]);

  useEffect(() => {
    if (savedContacts) {
      setOpenCreateDialog(false);
    }
  }, [savedContacts]);

  useEffect(() => {
    if (editedContacts) {
      setOpenEditDialog(false);
    }
  }, [editedContacts]);

  useEffect(() => {
    if (deletedContacts) {
      setOpenDeleteDialog(false);
    }
  }, [deletedContacts]);

  const handleDelete = () => {
    dispatch(deleteAgreementsContacts(agreementId, selectedContact.id));
  };

  const handleOpenCreateDialog = type => {
    setContactTypeCreation(type);
    setOpenCreateDialog(true);
  };

  const handleOpenEditDialog = selectedContact => {
    setSelectedContact(selectedContact);
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = selectedContact => {
    setSelectedContact(selectedContact);
    setOpenDeleteDialog(true);
  };

  const handleCreateContact = (sourceId, contact) => {
    dispatch(registerAgreementsContacts(sourceId, contact));
  };

  const handleEditContact = contact => {
    dispatch(editAgreementsContacts(agreementId, selectedContact.id, contact));
  };

  return (
    <>
      {openDeleteDialog && (
        <ConfirmDialog
          isOpen={openDeleteDialog}
          handleClose={() => setOpenDeleteDialog(false)}
          title="Eliminar contacto"
          subtitle={
            <>
              Si eliminas este contacto no podrás recuperar sus datos.
              <br />
              ¿Quieres eliminar este contacto?
            </>
          }
          buttonOnAccept={handleDelete}
          loading={deletingContacts}
          buttonLabel="Eliminar"
        />
      )}
      {openCreateDialog && (
        <FormAgreementContactDialog
          open={openCreateDialog}
          handleClose={() => setOpenCreateDialog(false)}
          type={contactTypeCreation}
          sourceId={agreementId}
          handleSubmit={handleCreateContact}
          loading={savingContacts}
          error={contactsError}
        />
      )}

      {openEditDialog && (
        <FormAgreementContactDialog
          open={openEditDialog}
          handleClose={() => setOpenEditDialog(false)}
          type={contactTypeCreation}
          sourceId={agreementId}
          originalContact={selectedContact}
          handleSubmit={handleEditContact}
          loading={editingContacts}
          error={contactsError}
        />
      )}

      <Grid container direction="row" className={classes.window} spacing={2}>
        {loadingContacts ? (
          <CircularProgress />
        ) : (
          AGREEMENT_CONTACT_TYPES.map(type => (
            <Grid item xs={4} className={classes.columnContact}>
              <AgreementContactColumn
                type={type}
                contacts={contacts.filter(contact => contact.type === type)}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                handleOpenCreateDialog={handleOpenCreateDialog}
                handleOpenEditDialog={handleOpenEditDialog}
              />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

AgreementContactSection.propTypes = {
  agreementId: PropTypes.number.isRequired,
};

export default AgreementContactSection;
