import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';
import { Comment } from '../../interfaces';

const fetchOrderComments = async (operationId: number) => {
  try {
    const { data } = await axios.get<Comment[]>(
      `/api/order/${operationId}/comment`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fetchOrderComments;
