import React, { FC } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { features } from '../../../../config/features/index';
import {
  Countries,
  InvoicesBulkUpdateType,
} from '../../../commons/interfaces/ARInterfaces';

interface Props {
  country: Countries;
  anchorEl: any;
  handleChangeAnchorEl: (x: any) => void;
  handleShowInvoicesBulkUpdateDialog: (
    invoicesBulkUpdateType: InvoicesBulkUpdateType
  ) => void;
  handleShowPartialPaymentsBulkUpdateDialog: () => void;
  handleShowFundsBulkUpdateDialog: () => void;
}

const HeaderMenu: FC<Props> = ({
  country,
  anchorEl,
  handleChangeAnchorEl,
  handleShowInvoicesBulkUpdateDialog,
  handleShowPartialPaymentsBulkUpdateDialog,
  handleShowFundsBulkUpdateDialog,
}) => {
  const countryFeatures = features[country];

  return (
    <Menu
      id="bulkUpdate"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleChangeAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {countryFeatures.orderActions.bulkInvoicesUpdates && (
        <MenuItem
          onClick={() => {
            handleShowInvoicesBulkUpdateDialog('DATE');
            handleChangeAnchorEl(null);
          }}
        >
          Actualización masiva de fechas de facturas
        </MenuItem>
      )}

      {countryFeatures.orderActions.bulkInvoicesUpdates && (
        <MenuItem
          onClick={() => {
            handleShowInvoicesBulkUpdateDialog('STATUS');
            handleChangeAnchorEl(null);
          }}
        >
          Actualización masiva de estado de facturas
        </MenuItem>
      )}

      {countryFeatures.orderActions.bulkPartialPaymentsRegister && (
        <MenuItem
          onClick={() => {
            handleShowPartialPaymentsBulkUpdateDialog();
            handleChangeAnchorEl(null);
          }}
        >
          Ingreso masivo de Pagos Parciales
        </MenuItem>
      )}

      {countryFeatures.orderActions.bulkFundsUpdates && (
        <MenuItem
          onClick={() => {
            handleShowFundsBulkUpdateDialog();
            handleChangeAnchorEl(null);
          }}
        >
          Actualización masiva de fondos
        </MenuItem>
      )}
    </Menu>
  );
};

export default HeaderMenu;
