import moment from 'moment';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { DATE_LOCALE } from '../../../../helpers/Constants';
import { download } from '../../../../helpers/fileHandling';
import { t13s } from '../../../../translationKeys';
import { downloadAECService } from '../services';

export const useDownloadAEC = ({ orderId, invoices }) => {
  const dispatch = useDispatch();
  const { mutate: downloadAEC } = useMutation(
    () => downloadAECService(invoices),
    {
      onSuccess: res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FILE_DOWNLOADED, {
            variant: 'success',
          })
        );
        const today = moment()
          .locale(DATE_LOCALE)
          .format('DD-MM-YYYY_HH:mm:ss');
        return download(resData, `AEC_${orderId}_${today}.zip`);
      },
    },
    {
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return { downloadAEC };
};

export default useDownloadAEC;
