import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { useInvoiceTableReloadRef } from '../../ui/PayrollInvoices/InvoicesTable/store';
import updateOrderStatusService from '../services/updateOrderStatusService';

export const useUpdateOrderStatus = () => {
  const dispatch = useDispatch();
  const invoiceTableReloadRef = useInvoiceTableReloadRef();
  const queryClient = useQueryClient();
  const {
    isLoading: orderStatusIsUpdating,
    isSuccess: updateOrderStatusIsSuccess,
    error: updateOrderStatusError,
    mutate: updateOrderStatus,
    reset: resetOrderStatusUpdateData,
  } = useMutation(
    ({ orderId, status, statusReason }) => {
      return updateOrderStatusService({ orderId, status, statusReason });
    },
    {
      onSuccess: () => {
        invoiceTableReloadRef();
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_STATUS_SUCCESS, {
            variant: 'success',
          })
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries('getOrder', {
          exact: false,
        });
      },
    }
  );

  return {
    orderStatusIsUpdating,
    updateOrderStatusIsSuccess,
    updateOrderStatusError,
    updateOrderStatus,
    resetOrderStatusUpdateData,
  };
};

export default useUpdateOrderStatus;
