import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import UpdateUserDialog from '../dialogs/UpdateUserDialog';
import UpdatePasswordDialog from '../dialogs/UpdatePasswordDialog';
import Panel from '../elements/Panel';
import { fetchBusinessUsers } from '../../actions/businessAction';
import { editUser, impersonate } from '../../actions/userActions';
import ChangePassword from '../icons/ChangePassword';
import { features } from '../../config/features';

const useStyles = makeStyles({
  containerMenuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemIcon: {
    marginRight: 10,
  },
});

const TableBusinessUsers = ({ businessId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.config);
  const { businessUsers, businessUsersLoading, businessIdOwnerOfUsers } =
    useSelector(state => state.business);
  const { updateUserIsLoading, userWasUpdated } = useSelector(
    state => state.user
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [infoUsers, setInfoUsers] = useState([]);
  const [infoUserSelected, setInfoUserSelected] = useState({});
  const [showUpdateUserDialog, setShowUpdateUserDialog] = useState(false);
  const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] =
    useState(false);
  const countryFeatures = features[country];

  useEffect(() => {
    if (
      (countryFeatures.businessUserInfo &&
        businessId !== businessIdOwnerOfUsers) ||
      userWasUpdated
    )
      dispatch(fetchBusinessUsers(businessId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, country, userWasUpdated, dispatch]);

  useEffect(() => {
    if (countryFeatures.businessUserInfo) setInfoUsers(businessUsers);
  }, [businessUsers, countryFeatures.businessUserInfo]);

  useEffect(() => {
    if (userWasUpdated) {
      setShowUpdateUserDialog(false);
      setShowUpdatePasswordDialog(false);
    }
  }, [userWasUpdated]);

  const handleClickMenu = (event, user) => {
    setInfoUserSelected(user);
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = (userData, userId) => {
    setAnchorEl(null);
    dispatch(editUser(userData, userId));
  };

  const userOption = [
    {
      title: 'Editar información',
      action: () => setShowUpdateUserDialog(true),
      body: (
        <Typography variant="subtitle1" color="textPrimary" component="div">
          <Box className={classes.containerMenuItem}>
            <CreateOutlinedIcon
              fontSize="small"
              className={classes.menuItemIcon}
            />
            Editar información
          </Box>
        </Typography>
      ),
    },
    {
      title: 'Cambiar contraseña',
      action: () => setShowUpdatePasswordDialog(true),
      body: (
        <Typography variant="subtitle1" color="textPrimary" component="div">
          <Box className={classes.containerMenuItem}>
            <ChangePassword fontSize="small" className={classes.menuItemIcon} />
            Cambiar contraseña
          </Box>
        </Typography>
      ),
    },
    {
      title: 'Personificar',
      action: () => dispatch(impersonate(infoUserSelected.id, country)),
      body: (
        <Typography variant="subtitle1" color="textPrimary" component="div">
          <Box className={classes.containerMenuItem}>
            <ExitToAppIcon fontSize="small" className={classes.menuItemIcon} />
            Personificar
          </Box>
        </Typography>
      ),
    },
  ];

  const buildTableText = (text, align = 'center', bold = true) => {
    const tableText = text || '-';
    return (
      <Typography
        align={align}
        variant="body2"
        color="textPrimary"
        component="div"
      >
        {bold ? <Box fontWeight="fontWeightBold">{tableText}</Box> : tableText}
      </Typography>
    );
  };

  return (
    <>
      {showUpdateUserDialog && (
        <UpdateUserDialog
          open={showUpdateUserDialog}
          handleCloseDialog={() => setShowUpdateUserDialog(false)}
          userInformation={infoUserSelected}
          handleSubmit={handleSubmit}
        />
      )}

      {showUpdatePasswordDialog && (
        <UpdatePasswordDialog
          open={showUpdatePasswordDialog}
          handleCloseDialog={() => setShowUpdatePasswordDialog(false)}
          userInformation={infoUserSelected}
          handleSubmit={handleSubmit}
        />
      )}

      <Grid item xs={12}>
        <Panel
          variant="section"
          title="Información de usuario"
          titlePadding="lg"
          contentPadding="zero zero lg zero"
        >
          {infoUsers?.length &&
          !businessUsersLoading &&
          !updateUserIsLoading ? (
            <Table className={classes.table} aria-label="enhanced table">
              <TableHead>
                <TableRow className={classes.tableHead}>
                  <TableCell>{buildTableText('Nombre', 'left')}</TableCell>
                  <TableCell>{buildTableText('Nº teléfono', 'left')}</TableCell>
                  <TableCell>{buildTableText('Email', 'left')}</TableCell>
                  <TableCell>{buildTableText('Contraseña')}</TableCell>
                  <TableCell>{buildTableText('Acciones')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {infoUsers.map(user => {
                  const { name, email, phone } = user;

                  return (
                    <TableRow key={email} className="MuiTableRow-hover">
                      <TableCell className={classes.tableBodyFirstChild}>
                        {buildTableText(name, 'left', false)}
                      </TableCell>
                      <TableCell>
                        {buildTableText(phone, 'left', false)}
                      </TableCell>
                      <TableCell>
                        {buildTableText(email, 'left', false)}
                      </TableCell>
                      <TableCell>
                        {buildTableText('*****', 'center', false)}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={event => handleClickMenu(event, user)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
        </Panel>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {userOption.map(option => {
            const { title, action, body } = option;
            return (
              <MenuItem key={title} onClick={action}>
                {body}
              </MenuItem>
            );
          })}
        </Menu>
      </Grid>
    </>
  );
};

TableBusinessUsers.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default TableBusinessUsers;
