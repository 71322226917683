import {
  FETCH_ENTITY_FEATURES_START,
  FETCH_ENTITY_FEATURES_SUCCESS,
  FETCH_ENTITY_FEATURES_FAILURE,
  CREATE_ENTITY_FEATURE_START,
  CREATE_ENTITY_FEATURE_SUCCESS,
  CREATE_ENTITY_FEATURE_FAILURE,
  UPDATE_ENTITY_FEATURE_START,
  UPDATE_ENTITY_FEATURE_SUCCESS,
  UPDATE_ENTITY_FEATURE_FAILURE,
} from '../actions/types';

const initialState = {
  fetchEntityFeaturesIsLoading: false,
  entityFeatures: null,
  createdEntityFeature: false,
  updatedEntityFeature: false,
  updatedEntityFeatureIsLoading: false,
};

const fetchEntityFeaturesStart = state => {
  return {
    ...state,
    fetchEntityFeaturesIsLoading: true,
  };
};

const fetchEntityFeaturesSuccess = (state, payload) => {
  return {
    ...state,
    fetchEntityFeaturesIsLoading: false,
    entityFeatures: payload,
  };
};

const fetchEntityFeaturesFailure = state => {
  return {
    ...state,
    fetchEntityFeaturesIsLoading: false,
  };
};

const createEntityFeatureStart = state => {
  return {
    ...state,
    createdEntityFeature: false,
  };
};

const createEntityFeatureSuccess = (state, payload) => {
  return {
    ...state,
    createdEntityFeature: true,
    entityFeatures: [...state.entityFeatures, payload],
  };
};

const createEntityFeatureFailure = state => {
  return {
    ...state,
    createdEntityFeature: false,
  };
};

const updateEntityFeatureStart = state => {
  return {
    ...state,
    updatedEntityFeature: false,
    updatedEntityFeatureIsLoading: true,
  };
};

const updateEntityFeatureSuccess = (state, payload) => {
  const entityFeatures = state.entityFeatures.map(entity => {
    if (entity.id === payload.id) {
      return payload;
    }
    return entity;
  });
  return {
    ...state,
    updatedEntityFeature: true,
    updatedEntityFeatureIsLoading: false,
    entityFeatures,
  };
};

const updateEntityFeatureFailure = state => {
  return {
    ...state,
    updatedEntityFeature: false,
    updatedEntityFeatureIsLoading: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ENTITY_FEATURES_START:
      return fetchEntityFeaturesStart(state);
    case FETCH_ENTITY_FEATURES_SUCCESS:
      return fetchEntityFeaturesSuccess(state, payload);
    case FETCH_ENTITY_FEATURES_FAILURE:
      return fetchEntityFeaturesFailure(state);
    case CREATE_ENTITY_FEATURE_START:
      return createEntityFeatureStart(state);
    case CREATE_ENTITY_FEATURE_SUCCESS:
      return createEntityFeatureSuccess(state, payload);
    case CREATE_ENTITY_FEATURE_FAILURE:
      return createEntityFeatureFailure(state);
    case UPDATE_ENTITY_FEATURE_START:
      return updateEntityFeatureStart(state);
    case UPDATE_ENTITY_FEATURE_SUCCESS:
      return updateEntityFeatureSuccess(state, payload);
    case UPDATE_ENTITY_FEATURE_FAILURE:
      return updateEntityFeatureFailure(state);
    default:
      return state;
  }
};
