import React, { FC } from 'react';
import { IconButton, Box, CircularProgress, Tooltip } from '@material-ui/core';
import {
  ContainerRoot,
  RequirementContainerTitle,
  RequirementTitle,
  RequirementStatus,
  RequirementContainerData,
  RequirementDataTitle,
  RequirementDataRow,
  RequirementDataRowText,
} from './styles';
import InvoicePendingRequirementsDrawer from './../InvoicePendingRequirementsDrawer';
import RequirementSkeleton from './ui/RequirementSkeleton';
import useRequirementsTab from './hooks/useRequirementsTab';
import RefreshIcon from '../../icons/RefreshIcon';
import ReadMoreIcon from '../../icons/ReadMoreIcon';

const RequirementsTab: FC = () => {
  const {
    requirements,
    fetchRequirementsIsLoading,
    invoiceRequirementSelected,
    showInvoicePendingRequirementsDrawer,
    sectionData,
    operationType,
    operationStatus,
    verifyRequirementsIsLoading,
    handleShowInvoicePendingRequirementsDrawer,
    handleCloseInvoicePendingRequirementsDrawer,
    handleGetRequirementData,
    handleGetRequirementIcon,
    handleGetLinearProgress,
    handleGetCountRequirements,
    handleVerifyRequirements,
  } = useRequirementsTab();

  const { totalRequirements, successRequirements } = handleGetCountRequirements(
    {
      section: 'all',
      rawRequirementData: requirements,
      operationType,
      operationStatus,
    }
  );

  return (
    <ContainerRoot>
      <RequirementSkeleton isLoading={fetchRequirementsIsLoading}>
        <RequirementContainerTitle mb={2}>
          <RequirementTitle>
            Progreso de requisitos para cambio de estado
          </RequirementTitle>
          <RequirementStatus>{`${successRequirements}/${totalRequirements}`}</RequirementStatus>
        </RequirementContainerTitle>

        <Box mb={5}>
          {handleGetLinearProgress(successRequirements, totalRequirements)}
        </Box>

        <Box display="flex" justifyContent="space-between">
          <>
            {sectionData.map(section => {
              const requirementData = handleGetRequirementData({
                section: section.key,
                rawRequirementData: requirements,
                operationType,
                operationStatus,
              });
              if (!requirementData.length) return null;
              return (
                <RequirementContainerData key={section.key}>
                  <Box display="flex" alignItems="center" height={33}>
                    <RequirementDataTitle>{section.label}</RequirementDataTitle>
                    {section.key === 'invoices' && (
                      <>
                        {verifyRequirementsIsLoading ? (
                          <CircularProgress color="primary" size={22} />
                        ) : (
                          <Tooltip title="Actualizar datos" arrow>
                            <IconButton onClick={handleVerifyRequirements}>
                              <RefreshIcon color="#63606D" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Box>
                  {requirementData.map(requirementData => (
                    <RequirementDataRow>
                      <Box display="flex">
                        {handleGetRequirementIcon(
                          requirementData.keyValue,
                          requirementData.status
                        )}
                        <RequirementDataRowText>
                          {requirementData.label}
                        </RequirementDataRowText>
                      </Box>
                      {requirementData.showDetail && (
                        <IconButton
                          onClick={() =>
                            handleShowInvoicePendingRequirementsDrawer(
                              requirementData
                            )
                          }
                        >
                          <ReadMoreIcon color="#63606D" />
                        </IconButton>
                      )}
                    </RequirementDataRow>
                  ))}
                </RequirementContainerData>
              );
            })}
          </>
        </Box>
      </RequirementSkeleton>

      {showInvoicePendingRequirementsDrawer && (
        <InvoicePendingRequirementsDrawer
          isOpen={showInvoicePendingRequirementsDrawer}
          requirement={invoiceRequirementSelected}
          handleCloseDrawer={handleCloseInvoicePendingRequirementsDrawer}
        />
      )}
    </ContainerRoot>
  );
};

export default RequirementsTab;
