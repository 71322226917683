import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from '@material-table/core';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { defaultValue, getTermInDays } from '../../../../commons/utils';
import EditRateDialog from '../../../../../components/dialogs/EditRateDialog';
import EditTermDialog from '../../../../../components/dialogs/EditTermDialog';
import {
  usePayrollActions,
  useSelectedReceiver,
} from '../../../adapters/store/payroll';
import { TABLE_OPTIONS } from '../../../../commons/constants/ui';
import { useStyles } from './InvoicesTable.styles';
import { GET_COLUMNS, GET_RECEIVER_SUMMARY } from './constants';
import Panel from '../../../../../components/elements/Panel';
import { fetchFundPayerDetailInfo } from '../../../../../actions/fundInvoicesAction';
import { usePayrollDetailContext } from '../../../context';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import {
  useFetchInvoices,
  useUpdateInvoice,
} from '../../../domains/invoice/services';

export const InvoicesTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const css = useStyles();
  const [showEditRateDialog, setShowEditRateDialog] = useState(false);
  const [showEditEffectiveFundRateDialog, setShowEditEffectiveFundRateDialog] =
    useState(false);
  const [showEditTermDialog, setShowEditTermDialog] = useState(false);
  const [editedInvoice, setEditedInvoice] = useState();
  const payrollDetailCtx = usePayrollDetailContext();
  const selectedReceiver = useSelectedReceiver();
  const { updatePayrollSummary } = usePayrollActions();
  const { addInvoice } = usePayrollSelectorController();
  const { invoices, isFetching, refetch } = useFetchInvoices({
    ...{ businessStatus: payrollDetailCtx.receiverForm?.businessStatus },
    ...payrollDetailCtx.invoiceForm,
  });
  const { mutateAsync: updateInvoice, isLoading: isUpdating } =
    useUpdateInvoice({
      onSuccess: res => {
        updatePayrollSummary(res?.payrollSummary);
        refetch();
      },
      onError: () => {
        payrollDetailCtx.setIsBulkUpdating(false);
      },
    });

  const columns = GET_COLUMNS({
    country: payrollDetailCtx.country,
    handleOpenInvoiceDetail: payrollDetailCtx.handleOpenInvoiceDetail,
    onEditRate: invoice => {
      setEditedInvoice(invoice);
      setShowEditRateDialog(true);
    },
    onEditEffectiveFundRate: invoice => {
      setEditedInvoice(invoice);
      setShowEditEffectiveFundRateDialog(true);
    },
    onEditTerm: invoice => {
      setEditedInvoice(invoice);
      setShowEditTermDialog(true);
    },
    t,
  });

  const submitTerm = res => {
    updatePayrollSummary();
    updateInvoice({
      payrollId: payrollDetailCtx.payrollId,
      invoices: [
        {
          advancePercentage: editedInvoice.advancePercentage,
          amount: editedInvoice.invoice.amount,
          payrollInvoiceId: editedInvoice.id,
          rate: editedInvoice.rate,
          effectiveFundCostRate: editedInvoice.effectiveFundCostRate,
          term: res.inDays,
        },
      ],
    });
    setShowEditTermDialog(false);
  };

  const submitRate = res => {
    updatePayrollSummary();
    updateInvoice({
      payrollId: payrollDetailCtx.payrollId,
      invoices: [
        {
          advancePercentage: editedInvoice.advancePercentage,
          amount: editedInvoice.invoice.amount,
          payrollInvoiceId: editedInvoice.id,
          rate: res,
          effectiveFundCostRate: editedInvoice.effectiveFundCostRate,
          term: getTermInDays(editedInvoice.term),
        },
      ],
    });
    setShowEditRateDialog(false);
  };

  const submitEffectiveFundRate = res => {
    updatePayrollSummary();
    updateInvoice({
      payrollId: payrollDetailCtx.payrollId,
      invoices: [
        {
          advancePercentage: editedInvoice.advancePercentage,
          amount: editedInvoice.invoice.amount,
          payrollInvoiceId: editedInvoice.id,
          rate: editedInvoice.rate,
          effectiveFundCostRate: res,
          term: getTermInDays(editedInvoice.term),
        },
      ],
    });
    setShowEditEffectiveFundRateDialog(false);
  };

  const handleOpenDebtorDetail = () => {
    dispatch(
      fetchFundPayerDetailInfo({
        receiverIdentifier: selectedReceiver?.receiverId,
      })
    );
    payrollDetailCtx.setIsDebtorDetailVisible(true);
  };

  return (
    <>
      <Grid item xs={12} className={css.tableContainer}>
        <Panel titlePadding="zero" contentPadding="lg lg zero" title="">
          <Link
            className={css.returnLink}
            component="button"
            variant="body1"
            align="left"
            onClick={() => {
              payrollDetailCtx.changeToReceiverView();
              payrollDetailCtx.filter().setAppliedFilters(0);
              payrollDetailCtx.filter().setFormFilled({});
            }}
          >
            <Box display="flex">
              <ArrowBackIcon />
              <Typography component="span" className={css.returnTxt}>
                Volver
              </Typography>
            </Box>
          </Link>
          <Typography variant="h5" className={css.mb15}>
            <Box fontWeight="fontWeightBold">{selectedReceiver?.receiver}</Box>
          </Typography>
          <Box display="flex" className={css.mb30}>
            {selectedReceiver &&
              GET_RECEIVER_SUMMARY({
                receiver: selectedReceiver,
                t,
                country: payrollDetailCtx.country,
              }).map(({ label, value }, idx) => (
                <Box
                  display="flex"
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  className={css.containerTextMainAreaBold}
                >
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={css.textMainAreaBold}
                  >
                    <Box fontWeight="fontWeightBold">{label}</Box>
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {defaultValue(value)}
                  </Typography>
                </Box>
              ))}
            <Link
              component="button"
              variant="body1"
              align="left"
              onClick={() => {
                handleOpenDebtorDetail();
              }}
            >
              Ver más detalle
            </Link>
          </Box>
          <MaterialTable
            columns={columns}
            data={invoices}
            style={{ width: '100%', boxShadow: 'none' }}
            isLoading={
              isFetching ||
              isUpdating ||
              payrollDetailCtx.isDeleting ||
              payrollDetailCtx.isBulkUpdating
            }
            components={{
              Pagination: () => null,
              Toolbar: () => null,
            }}
            onSelectionChange={rowsSelected =>
              addInvoice(rowsSelected, invoices)
            }
            options={TABLE_OPTIONS}
            localization={{
              pagination: {
                labelRowsSelect: 'Facturas',
                labelRowsPerPage: 'Facturas por página',
                labelDisplayedRows: '{count} facturas en total',
              },
              body: {
                emptyDataSourceMessage: 'No hay facturas para mostrar',
              },
            }}
          />
          {showEditRateDialog && (
            <EditRateDialog
              open={showEditRateDialog}
              subTitle={() => (
                <>
                  Editará la tasa para la factura
                  <Box fontWeight="fontWeightBold">
                    Factura folio {editedInvoice?.invoice?.folio}.
                  </Box>
                </>
              )}
              title="Editar Tasa Fondo"
              value={+editedInvoice?.rate}
              handleSubmit={submitRate}
              handleReset={submitRate}
              handleCloseDialog={() => {
                setShowEditRateDialog(false);
              }}
              loading={false}
            />
          )}
          {showEditEffectiveFundRateDialog && (
            <EditRateDialog
              open={showEditEffectiveFundRateDialog}
              subTitle={() => (
                <>
                  Editará la tasa para la factura
                  <Box fontWeight="fontWeightBold">
                    Factura folio {editedInvoice?.invoice?.folio}.
                  </Box>
                </>
              )}
              title="Editar Tasa Fondo Efectiva"
              value={+editedInvoice?.effectiveFundCostRate}
              handleSubmit={submitEffectiveFundRate}
              handleReset={submitEffectiveFundRate}
              handleCloseDialog={() => {
                setShowEditEffectiveFundRateDialog(false);
              }}
              loading={false}
            />
          )}
          {showEditTermDialog && (
            <EditTermDialog
              open={showEditTermDialog}
              subTitle={() => (
                <Box fontWeight="fontWeightBold">
                  Factura folio {editedInvoice?.invoice?.folio}.
                </Box>
              )}
              value={editedInvoice?.term}
              handleSubmit={submitTerm}
              handleReset={submitTerm}
              handleCloseDialog={() => {
                setShowEditTermDialog(false);
              }}
              loading={false}
            />
          )}
        </Panel>
      </Grid>
    </>
  );
};
