import axios from 'axios';
import { PayerCategorization } from '../../../commons/interfaces/payerCategorization';

interface Props {
  payersIdentifiers: string[];
  categorizations: PayerCategorization[];
}

export const bulkCreatePayerCategorizations = async ({
  payersIdentifiers,
  categorizations,
}: Props) => {
  try {
    const payerCategorizations = categorizations.map(payerCategorization => {
      return axios.post('/api/collection/payers/categorizations/bulk-create', {
        payersIdentifiers: payersIdentifiers,
        type: payerCategorization.type,
        result: payerCategorization.result,
        detail: payerCategorization.detail,
      });
    });
    await Promise.all(payerCategorizations);
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
