import { useQuery } from 'react-query';
import { checkPfxService } from '../services';

const useCheckPfx = businessId => {
  const { data } = useQuery('checkPfx', () => checkPfxService(businessId), {
    refetchOnWindowFocus: false,
  });

  return {
    data,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useCheckPfx };
