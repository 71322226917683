/* eslint-disable react/jsx-props-no-spreading */
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchSelectedOrder,
  resetStateFetchOrdersIdByPayerRut,
  resetStateUpdateInvoice,
  resetStateUploadingInvoice,
  checkOrderInvoiceAttachments,
  resetUploadOrderInvoicePdfStatus,
  fetchAmountInfoByOrderInvoiceIds,
} from '../../../../../actions/orderActions';
import {
  bulkFetchDebtService,
  bulkFetchDebtSuper,
} from '../../../../../actions/debtServiceActions';

import { wsConnect } from '../../../../../actions/websocket';
import { features } from '../../../../../config/features';
import {
  COUNTRY_CODE_CL,
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
} from '../../../../../helpers/Constants';
import Container from '../../../../../components/elements/Container';
import InvoiceDetail from '../../../../../components/tables/invoice/InvoiceDetail';
import PartialPaymentsDrawer from './components/PartialPaymentsDrawer';

import {
  useTableColumns,
  useInvoiceTable,
  useHandleHistoryLogDialog,
} from './hooks';
import styles from './styles';

import {
  useShowInvoiceStatusDialog,
  useSelectedInvoice,
  useShowDiscountFormDialog,
  useSetShowFundDialog,
  useSetShowBillDatesDialog,
  useSetShowInvoiceStatusDialog,
  useSetSelectedInvoice,
  useSetShowDiscountFormDialog,
  useSetAnchorEl,
  useShowPartialPaymentDrawer,
  useSetShowPartialPaymentDrawer,
  useShowEditInvoiceDialog,
  useSetShowEditInvoiceDialog,
  useSetShowUploadDocumentDialog,
  useSelectedInvoices,
  useSetSelectedInvoices,
  useSetOrderIdsToReplace,
  useSetEnableManageOrderInvoice,
  useShowActionBar,
  useSetShowActionBar,
  useSetInvoiceTableReloadRef,
} from './store';
import TableMenu from './TableMenu';
import TableDialogs from './TableDialogs';
import TableManageOrderInvoices from './TableManageOrderInvoices';
import { HistoryLogsDialog } from './dialogs';
import {
  useFetchOrderInvoicesFunds,
  useCheckPfx,
  useOrder,
  useOrderAttributes,
  useFetchOrderInvoicesFund,
} from '../../../infrastructure/store';
import { verifyIfOrderIsFullyFinanced } from '../../../../../actions/fundsActions';
import useStatusesDetail from '../../../hooks/useStatusesDetail';
import Pagination from './components/Pagination';

const InvoicesTable = memo(
  ({
    invoices,
    isLoading,
    handleShowLoader,
    handleRowSelection,
    handleRemoteData,
    rowsPerPage,
    count,
    page,
    tableRef,
  }) => {
    const { business } = useSelector(state => state.business);
    const { getStatuses } = useStatusesDetail();
    const { id: orderId } = useParams();
    const classes = styles;
    const tableColumns = useTableColumns();
    const dispatch = useDispatch();
    const showInvoiceStatusDialog = useShowInvoiceStatusDialog();
    const selectedInvoice = useSelectedInvoice();
    const showDiscountFormDialog = useShowDiscountFormDialog();
    const setShowFundDialog = useSetShowFundDialog();
    const setShowBillDatesDialog = useSetShowBillDatesDialog();
    const setShowInvoiceStatusDialog = useSetShowInvoiceStatusDialog();
    const setSelectedInvoice = useSetSelectedInvoice();
    const setShowDiscountFormDialog = useSetShowDiscountFormDialog();
    const setAnchorEl = useSetAnchorEl();
    const showPartialPaymentDrawer = useShowPartialPaymentDrawer();
    const setShowPartialPaymentDrawer = useSetShowPartialPaymentDrawer();
    const showEditInvoiceDialog = useShowEditInvoiceDialog();
    const setShowEditInvoiceDialog = useSetShowEditInvoiceDialog();
    const setShowUploadDocumentDialog = useSetShowUploadDocumentDialog();
    const selectedInvoices = useSelectedInvoices();
    const setSelectedInvoices = useSetSelectedInvoices();
    const setOrderIdsToReplace = useSetOrderIdsToReplace();
    const setEnableManageOrderInvoice = useSetEnableManageOrderInvoice();
    const showActionBar = useShowActionBar();
    const setShowActionBar = useSetShowActionBar();
    const setInvoiceTableReloadRef = useSetInvoiceTableReloadRef();

    useEffect(() => {
      setInvoiceTableReloadRef(tableRef.current.onQueryChange);
    }, []);

    const paginationComponent = MTpaginationProps => (
      <Pagination
        {...MTpaginationProps}
        rowsPerPage={rowsPerPage}
        count={count}
        page={page}
        labelRowsPerPage="Número de facturas"
      />
    );
    const { refetch: fetchOrderInvoicesFunds } =
      useFetchOrderInvoicesFunds(orderId);
    const { refetch: fetchOrderInvoicesAttributes } =
      useOrderAttributes(orderId);

    const {
      loadingFacturas,
      updatingInvoice,
      ordersIdToReplace: orderIds,
      invoiceWasUpdated,
      orderInvoiceWasCreate,
      invoiceWasUploaded,
      orderInvoicePdfWasUploaded,
    } = useSelector(state => state.order);

    const { order } = useOrder(orderId);
    const { businessId } = order;
    const { tableOptions } = useInvoiceTable(tableRef, rowsPerPage);
    const wsconnected = useSelector(state => state.ws.wsconnected);
    const country = useSelector(state => state.config.country);

    const {
      orderInvoiceFundWasCreated,
      orderInvoiceFundWasUpdated,
      orderOwnerInvoicesFund,
      invoicesBulkWasUpdated,
    } = useSelector(state => state.funds);

    const {
      contextId,
      contextType,
      showHistoryLogDialog,
      handleCloseHistoryLogDialog,
      handleHistoryLogDialog,
    } = useHandleHistoryLogDialog();

    const { orderInvoicesFund } = useFetchOrderInvoicesFund(orderId);

    const countryFeatures = features[country];

    const { data: checkPfxCertificate } = useCheckPfx(businessId);

    const businessHasPfx = checkPfxCertificate?.hasPfxCertificate;

    const orderStatus = [
      ...getStatuses('order')
        .filter(
          status =>
            status.group === STATUS_IN_REVIEW ||
            status.group === STATUS_APPROVED
        )
        .map(status => status.key),
    ];

    useEffect(() => {
      if (
        orderId &&
        invoices?.length &&
        (orderOwnerInvoicesFund !== orderId ||
          orderInvoiceWasCreate ||
          invoicesBulkWasUpdated ||
          orderInvoiceFundWasUpdated)
      ) {
        fetchOrderInvoicesFunds(orderId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices, orderInvoiceFundWasUpdated, orderId]);

    useEffect(() => {
      if (invoices?.length && orderId) {
        fetchOrderInvoicesAttributes(orderId);

        if (country === COUNTRY_CODE_CL) {
          const orderInvoiceIds = invoices?.map(({ id }) => id);
          dispatch(fetchAmountInfoByOrderInvoiceIds(orderInvoiceIds));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices?.length, loadingFacturas, country, orderId]);

    useEffect(() => {
      if (orderInvoiceFundWasCreated) {
        setShowFundDialog(false);
        dispatch(verifyIfOrderIsFullyFinanced(orderId));
        if (orderId) {
          dispatch(fetchSelectedOrder(orderId));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInvoiceFundWasCreated]);

    useEffect(() => {
      if (orderId && orderInvoiceWasCreate) {
        fetchOrderInvoicesFunds(orderId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInvoiceWasCreate]);

    useEffect(() => {
      if (orderId && invoices?.length && !businessHasPfx) {
        dispatch(checkOrderInvoiceAttachments(orderId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, invoices?.length, businessHasPfx]);

    const handleCloseEditDialog = () => {
      setShowEditInvoiceDialog(false);
      setShowBillDatesDialog(false);
      setSelectedInvoice(undefined);
      setAnchorEl(null);
      dispatch(resetStateUpdateInvoice());
    };

    const handleCloseInvoiceStatusDialog = () => {
      dispatch(resetStateFetchOrdersIdByPayerRut());
      dispatch(resetStateUpdateInvoice());
      setShowInvoiceStatusDialog(false);
    };

    useEffect(() => {
      setSelectedInvoices([]);
    }, []);

    useEffect(() => {
      if (invoiceWasUploaded) {
        setShowUploadDocumentDialog(false);
        dispatch(resetStateUploadingInvoice());
      }
    }, [invoiceWasUploaded, dispatch]);

    useEffect(() => {
      if (invoiceWasUpdated) {
        if (showEditInvoiceDialog) {
          handleCloseEditDialog();
          // APICL eliminar cuando se apague la api de cl
        } else if (showDiscountFormDialog) {
          setShowDiscountFormDialog(false);
        } else if (showInvoiceStatusDialog) {
          handleCloseInvoiceStatusDialog();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceWasUpdated]);

    useEffect(() => {
      let filteredOrderIds = [];
      if (selectedInvoice && orderIds?.length) {
        filteredOrderIds = orderIds.filter(oid => {
          return parseInt(oid, 10) !== parseInt(selectedInvoice.orderId, 10);
        });
        setOrderIdsToReplace(filteredOrderIds);
      }
    }, [selectedInvoice, orderIds]);

    useEffect(() => {
      if (!wsconnected) {
        dispatch(wsConnect());
      }
    }, [wsconnected, loadingFacturas, dispatch]);

    useEffect(() => {
      if (!showActionBar) {
        setSelectedInvoices([]);
      }
    }, [showActionBar]);

    useEffect(() => {
      setEnableManageOrderInvoice(
        selectedInvoices.every(i => orderStatus.includes(i.status))
      );
      if (selectedInvoices.length >= 1) {
        setShowActionBar(true);
      } else {
        setShowActionBar(false);
      }
    }, [selectedInvoices]);

    useEffect(() => {
      if (orderInvoicePdfWasUploaded) {
        setShowUploadDocumentDialog(false);
        dispatch(resetUploadOrderInvoicePdfStatus());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInvoicePdfWasUploaded]);

    useEffect(() => {
      if (invoices?.length && country === COUNTRY_CODE_CL && businessId) {
        const identifiers = invoices?.map(
          ({ payerIdentifier }) => payerIdentifier
        );
        // Remove duplicates
        const uniqueIdentifiers = [...new Set(identifiers)];
        dispatch(bulkFetchDebtSuper(uniqueIdentifiers));
        dispatch(
          bulkFetchDebtService(businessId, 'business', uniqueIdentifiers)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFacturas]);

    return (
      <>
        {contextId && (
          <HistoryLogsDialog
            open={showHistoryLogDialog}
            handleCloseDialog={handleCloseHistoryLogDialog}
            contextId={contextId}
            contextType={contextType}
          />
        )}

        <TableManageOrderInvoices
          handleRowSelection={handleRowSelection}
          tableRef={tableRef}
        />

        <Container className={classes.tableContainer}>
          <TableDialogs
            handleShowLoader={handleShowLoader}
            business={business}
            orderInvoicesFund={orderInvoicesFund}
          />

          <TableMenu handleHistoryLogDialog={handleHistoryLogDialog} />

          <MaterialTable
            tableRef={tableRef}
            columns={tableColumns}
            data={handleRemoteData}
            isLoading={loadingFacturas || updatingInvoice || isLoading}
            onSelectionChange={handleRowSelection}
            components={{
              Toolbar: () => null,
              Pagination: paginationComponent,
            }}
            detailPanel={[
              orderInvoice => {
                const orderInvoiceFund = orderInvoicesFund.find(
                  oif => oif.orderInvoiceId === orderInvoice.id
                );
                return {
                  tooltip: 'Ver detalle',
                  disabled:
                    !countryFeatures.orderActions.showInvoiceDetail &&
                    !orderInvoiceFund,
                  render: () => (
                    <InvoiceDetail
                      orderInvoice={orderInvoice}
                      orderInvoiceFund={orderInvoiceFund}
                      handleShowLoader={handleShowLoader}
                    />
                  ),
                };
              },
            ]}
            {...tableOptions}
          />
          <PartialPaymentsDrawer
            isOpen={showPartialPaymentDrawer}
            handleCloseDrawer={() => setShowPartialPaymentDrawer(false)}
            selectedInvoice={selectedInvoice}
          />
        </Container>
      </>
    );
  }
);

InvoicesTable.propTypes = {
  business: PropTypes.objectOf(Object).isRequired,
  handleShowLoader: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  paginationProps: PropTypes.objectOf(Object).isRequired,
  handleRowSelection: PropTypes.func.isRequired,
  handleRemoteData: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableRef: PropTypes.any.isRequired,
};

export default InvoicesTable;
