import {
  FETCH_COLLECTION_INVOICES_START,
  FETCH_COLLECTION_INVOICES_SUCCESS,
  FETCH_COLLECTION_INVOICES_FAILURE,
  FETCH_COLLECTION_INVOICE_DETAIL_START,
  FETCH_COLLECTION_INVOICE_DETAIL_SUCCESS,
  FETCH_COLLECTION_INVOICE_DETAIL_FAILURE,
  BULK_UPDATE_COLLECTION_INVOICES_START,
  BULK_UPDATE_COLLECTION_INVOICES_SUCCESS,
  BULK_UPDATE_COLLECTION_INVOICES_FAILURE,
  FETCH_COLLECTION_PAYERS_START,
  FETCH_COLLECTION_PAYERS_SUCCESS,
  FETCH_COLLECTION_PAYERS_FAILURE,
} from '../actions/types';

const initialState = {
  collectionInvoices: [],
  pagination: {
    page: 1,
    totalData: 0,
    pageSize: 20,
  },
  fetchCollectionInvoicesIsLoading: false,

  invoiceDetail: null,
  fetchInvoiceDetailIsLoading: false,

  updateCollectionInvoicesIsLoading: false,
  collectionInvoicesWasUpdated: false,
  updateCollectionInvoicesError: null,

  collectionPayers: [],
  collectionsPayersFilteredBy: undefined,
  collectionPayerPagination: {
    page: 1,
    totalData: 0,
    pageSize: 20,
  },
  fetchCollectionPayersIsLoading: false,
};

const fetchCollectionInvoicesStart = state => ({
  ...state,
  fetchCollectionInvoicesIsLoading: true,
});
const fetchCollectionInvoicesSuccess = (state, payload) => ({
  ...state,
  fetchCollectionInvoicesIsLoading: false,
  collectionInvoices: payload.collectionInvoices,
  pagination: payload.pagination,
});
const fetchCollectionInvoicesFailure = state => ({
  ...state,
  collectionInvoices: [],
  pagination: initialState.pagination,
  fetchCollectionInvoicesIsLoading: false,
});

const fetchInvoiceDetailStart = state => ({
  ...state,
  fetchInvoiceDetailIsLoading: true,
});
const fetchInvoiceDetailSuccess = (state, payload) => ({
  ...state,
  fetchInvoiceDetailIsLoading: false,
  invoiceDetail: payload.invoiceDetail,
});
const fetchInvoiceDetailFailure = state => ({
  ...state,
  invoiceDetail: null,
  fetchInvoiceDetailIsLoading: false,
});

const bulkUpdateCollectionInvoicesStart = state => ({
  ...state,
  updateCollectionInvoicesIsLoading: true,
  collectionInvoicesWasUpdated: false,
  updateCollectionInvoicesError: null,
});
const bulkUpdateCollectionInvoicesSuccess = state => ({
  ...state,
  updateCollectionInvoicesIsLoading: false,
  collectionInvoicesWasUpdated: true,
});
const bulkUpdateCollectionInvoicesError = (state, payload) => ({
  ...state,
  updateCollectionInvoicesIsLoading: false,
  updateCollectionInvoicesError: payload.errorCode,
});
const fetchCollectonPayersStart = state => ({
  ...state,
  collectionPayers: [],
  collectionPayerPagination: initialState.collectionPayerPagination,
  collectionsPayersFilteredBy: initialState.collectionsPayersFilteredBy,
  fetchCollectionPayersIsLoading: true,
});
const fetchCollectonPayersSuccess = (state, payload) => ({
  state,
  collectionsPayersFilteredBy: payload.collectionPayers.payersFilter,
  collectionPayers: payload.collectionPayers.payers,
  collectionPayerPagination: payload.collectionPayerPagination,
  fetchCollectionPayersIsLoading: false,
});
const fetchCollectonPayersFailure = state => ({
  ...state,
  collectionPayers: [],
  collectionsPayersFilteredBy: undefined,
  collectionPayerPagination: initialState.collectionPayerPagination,
  fetchCollectionPayersIsLoading: false,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COLLECTION_INVOICES_START:
      return fetchCollectionInvoicesStart(state);
    case FETCH_COLLECTION_INVOICES_SUCCESS:
      return fetchCollectionInvoicesSuccess(state, payload);
    case FETCH_COLLECTION_INVOICES_FAILURE:
      return fetchCollectionInvoicesFailure(state);
    case FETCH_COLLECTION_INVOICE_DETAIL_START:
      return fetchInvoiceDetailStart(state);
    case FETCH_COLLECTION_INVOICE_DETAIL_SUCCESS:
      return fetchInvoiceDetailSuccess(state, payload);
    case FETCH_COLLECTION_INVOICE_DETAIL_FAILURE:
      return fetchInvoiceDetailFailure(state);
    case BULK_UPDATE_COLLECTION_INVOICES_START:
      return bulkUpdateCollectionInvoicesStart(state);
    case BULK_UPDATE_COLLECTION_INVOICES_SUCCESS:
      return bulkUpdateCollectionInvoicesSuccess(state);
    case BULK_UPDATE_COLLECTION_INVOICES_FAILURE:
      return bulkUpdateCollectionInvoicesError(state, payload);
    case FETCH_COLLECTION_PAYERS_START:
      return fetchCollectonPayersStart(state);
    case FETCH_COLLECTION_PAYERS_SUCCESS:
      return fetchCollectonPayersSuccess(state, payload);
    case FETCH_COLLECTION_PAYERS_FAILURE:
      return fetchCollectonPayersFailure(state);
    default:
      return state;
  }
};
