/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import LoadingButton from '../elements/LoadingButton';
import AlertForm from '../elements/AlertForm';
import verifyDecimals from '../../helpers/validation/decimals';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  btnSpacing: {
    marginTop: convertSpacingToCss('xs'),
  },
});

const RateDialog = ({
  open,
  handleCloseDialog,
  selectedRate,
  handleSubmit,
  handleDelete,
  isLoading,
  error,
}) => {
  const classes = useStyles();
  const [fundRateForm, setFundRateForm] = useState({
    id: undefined,
    rate: 0,
    days: 0,
    ...selectedRate,
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('verifyMaxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  const handleInputChange = event => {
    event.persist();
    const {
      target: { value: valueInput, name: nameInput },
    } = event;

    const value = ['rate', 'days'].includes(nameInput)
      ? Number(valueInput)
      : valueInput;

    setFundRateForm({
      ...fundRateForm,
      [nameInput]: value,
    });
  };

  const handleOnSubmit = () => {
    handleSubmit(fundRateForm);
  };

  const handleOnDelete = () => {
    handleDelete(fundRateForm);
  };

  const showDeleteButton = selectedRate?.days > 0;
  const { rate, days } = fundRateForm;
  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Plazos y tasas"
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={handleOnSubmit}>
          <TextValidator
            variant="outlined"
            fullWidth
            label="Tasa aplicada"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="rate"
            value={rate}
            aria-describedby="Tasa"
            onChange={e => handleInputChange(e)}
            validators={['required', 'isPositive', 'verifyMaxDecimals']}
            errorMessages={[
              'Campo requerido',
              'Valor debe ser mayor a 0',
              'Valor puede contener 4 decimales',
            ]}
          />
          {(!selectedRate || selectedRate?.days !== 0) && (
            <TextValidator
              fullWidth
              variant="outlined"
              label="Días"
              type="number"
              name="days"
              value={days}
              aria-describedby="Días"
              onChange={handleInputChange}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
          )}

          {error && <AlertForm message={error} variant="error" />}

          <Grid className={classes.justifyCenter}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              isLoading={isLoading}
            >
              Guardar
            </LoadingButton>
          </Grid>
          {showDeleteButton ? (
            <LoadingButton
              fullWidth
              variant="text"
              color="default"
              onClick={handleOnDelete}
              className={classes.btnSpacing}
              isLoading={isLoading}
            >
              Eliminar
            </LoadingButton>
          ) : null}
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

RateDialog.defaultProps = {
  selectedRate: {},
  open: false,
  isLoading: false,
  error: null,
};

RateDialog.propTypes = {
  selectedRate: PropTypes.objectOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default RateDialog;
