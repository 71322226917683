export const SORT_BY = [
  {
    isDefault: true,
    label: 'Más recientes',
    value: 'createdAt:desc',
  },
  {
    label: 'Más antiguos',
    value: 'createdAt:asc',
  },
];
