import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { t13s } from '../../../../translationKeys';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
  })
);

interface Props {
  showDrawer: boolean;
  showModal: boolean;
  toggleDrawer: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
}

const ActionMenu = ({showDrawer, showModal, toggleDrawer, toggleModal}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const handleClickPayerCategorization = () => {
    setAnchorEl(null);
    setOpen(!open);
    toggleDrawer(!showDrawer)
  };

  const handleClickRecap = () => {
    setAnchorEl(null);
    setOpen(!open);
    toggleModal(!showModal)
  };

  return (
    <div>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.root}
      >
        {t(t13s.LABEL.COLLECTIONS.MORE_ACTIONS)}
        <ExpandMore style={{ marginLeft: 10 }} />
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickPayerCategorization}>
          {t(t13s.LABEL.COLLECTIONS.UPDATE_PAYER_CATEGORIZATION)}
        </MenuItem>
        <MenuItem onClick={handleClickRecap}>
          {t(t13s.LABEL.COLLECTIONS.UPDATE_RECAP)}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;
