import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { createContact } from '../../actions/contactsActions';
import BaseDialog from '../dialogs/BaseDialog';
import { CONTACT_TYPE } from '../../helpers/Constants';

const CONTACTABLE_TYPE = 'payer';

const PayerContactForm = ({ open, close, type, payerId }) => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    type,
  });

  useEffect(() => {
    ValidatorForm.addValidationRule(
      'email validator with steroids',
      emailValue => {
        const regexEmail =
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regexEmail.test(emailValue);
      }
    );
    ValidatorForm.addValidationRule('phone with simbol or nah', phoneValue => {
      const regexPhone = /^\+?\d+?[0-9]*$/;
      return regexPhone.test(phoneValue);
    });
  }, [payerId]);

  const resetForm = () => {
    setContact({
      ...contact,
      name: '',
      email: '',
      phone: '',
    });
  };

  const handleClose = () => {
    close();
    setTimeout(() => {
      resetForm();
    }, 500);
  };

  const handleInputChange = e => {
    e.preventDefault();
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (CONTACTABLE_TYPE, payerId, contact) => {
    dispatch(createContact(CONTACTABLE_TYPE, payerId, contact));
    handleClose();
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      title={`Agregar contacto
        ${
          type === CONTACT_TYPE.CONFIRMATION ? ' de confirmación' : ' de pago'
        }`}
    >
      <ValidatorForm
        onSubmit={() => handleSubmit(CONTACTABLE_TYPE, payerId, contact)}
      >
        <TextValidator
          fullWidth
          variant="outlined"
          type="string"
          name="name"
          autoFocus
          label="Nombre"
          aria-describedby="Nombre de contacto"
          value={contact.name}
          onChange={handleInputChange}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          type="string"
          name="email"
          label="Email"
          value={contact.email}
          onChange={handleInputChange}
          validators={['required', 'email validator with steroids']}
          errorMessages={['Campo requerido', 'Email inválido']}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          type="string"
          name="phone"
          label="Teléfono"
          value={contact.phone}
          onChange={handleInputChange}
          validators={[
            'required',
            'maxStringLength:16',
            'minStringLength:1',
            'phone with simbol or nah',
          ]}
          errorMessages={[
            'Campo requerido',
            'Máximo 16 caracteres',
            'Solo números y símbolos telefónicos',
          ]}
        />

        <Button fullWidth type="submit" color="primary" variant="contained">
          Guardar
        </Button>
      </ValidatorForm>
    </BaseDialog>
  );
};

PayerContactForm.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default PayerContactForm;
