import React from 'react';
import PropTypes from 'prop-types';
import DocumentsTable from '../../../../../components/tables/DocumentsTable';

const ORDER = 'order';

const OrderDocumentsTable = ({
  documents,
  documentTableIsLoading,
  orderId,
}) => {
  return (
    <DocumentsTable
      documentableType={ORDER}
      documentableId={orderId}
      documents={documents}
      documentsAreLoading={documentTableIsLoading}
    />
  );
};

OrderDocumentsTable.defaultProps = {
  documentTableIsLoading: false,
};

OrderDocumentsTable.propTypes = {
  documents: PropTypes.arrayOf({}).isRequired,
  documentTableIsLoading: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
};

export default OrderDocumentsTable;
