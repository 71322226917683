import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography, Tooltip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Close } from '../icons';

import { ACTION_BAR } from '../../theme/otherColors';

const useStyles = makeStyles(() => ({
  actionBarContainer: {
    backgroundColor: ACTION_BAR.backgroundColor,
    width: '96%',
    minWidth: '1278px',
    padding: '16px',
    borderRadius: '7px',
    position: 'fixed',
    left: '50%',
    marginLeft: '-48%',
    bottom: '20px',
    zIndex: '10000',
    color: ACTION_BAR.textColor,
  },
  iconColor: {
    color: ACTION_BAR.iconColor,
  },
  closeIcon: {
    marginLeft: '20px',
  },
  actionContainer: {
    textAlign: 'right',
  },
  message: {
    marginRight: 25,
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ActionBar = ({
  message,
  customMessage,
  resetSelectedRows,
  children,
  show,
}) => {
  const classes = useStyles();
  return (
    show && (
      <Grid
        className={classes.actionBarContainer}
        container
        direction="row"
        alignItems="center"
        alignContent="space-between"
      >
        <Grid item xs={6} className={classes.messageContainer}>
          {message && (
            <Typography
              variant="body1"
              color="inherit"
              className={classes.message}
            >
              {message}
            </Typography>
          )}
          {customMessage}
        </Grid>
        <Grid item xs={6} className={classes.actionContainer}>
          {children}
          <Tooltip title="quitar seleccion" className={classes.closeIcon}>
            <IconButton size="medium" onClick={resetSelectedRows}>
              <Close className={classes.iconColor} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    )
  );
};

ActionBar.defaultProps = {
  show: true,
  message: '',
  customMessage: null,
};

ActionBar.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  customMessage: PropTypes.element,
  resetSelectedRows: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
};

export default ActionBar;
