import { useCallback } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { NotificationStatus } from '../../../elements/Notifications/interfaces';
import useNotifications from '../../../elements/Notifications/hooks/useNotifications';
import { es } from 'date-fns/locale';
import { useOktaAuth } from '@okta/okta-react';

const useNotification = (notificationId: string | number) => {
  const { authState } = useOktaAuth();
  const {
    getUserNotificationsIsLoading,
    updateNotificationStatus,
    getUserNotifications,
  } = useNotifications();

  const formatTimeAgo = useCallback(
    (date: string) =>
      `hace ${formatDistanceStrict(new Date(date), new Date(), {
        locale: es,
      })}`,
    []
  );

  const updateNotification = useCallback(
    async (status: NotificationStatus) => {
      if (status === NotificationStatus.New) {
        updateNotificationStatus({
          variables: {
            subscriptionId: authState?.idToken?.claims?.email,
            messageId: notificationId,
          },
        }).then(() => getUserNotifications());
      }
    },
    [updateNotificationStatus, getUserNotifications, authState, notificationId]
  );
  return {
    getUserNotificationsIsLoading,
    formatTimeAgo,
    updateNotification,
  };
};

export default useNotification;
