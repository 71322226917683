import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateOrderCommentsTagsService } from '../services';

import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';

const useUpdateOrderCommentsTags = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: updateOrderCommentsTags,
  } = useMutation(
    ({ commentIds, userEmail }) =>
      updateOrderCommentsTagsService(commentIds, userEmail),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getCommentsCounter', { exact: false });
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_COMMENT_TAGS_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    updateOrderCommentsTags,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useUpdateOrderCommentsTags };
