export const getDraftReceiversUseCase = ({
  receivers,
  draftPayrollSelectorState,
}) => {
  const payrollSelector = draftPayrollSelectorState || {};
  const isChecked = receiver => {
    if (!payrollSelector[receiver.receiverIdentifier]) {
      return false;
    }

    return receiver.value.every(docu =>
      payrollSelector[receiver.receiverIdentifier].value.find(
        doc => doc.id === docu.id
      )
    );
  };

  const isIndeterminate = receiver => {
    if (!payrollSelector[receiver.receiverIdentifier]) {
      return false;
    }

    return receiver.value.some(docu =>
      payrollSelector[receiver.receiverIdentifier].value.find(
        doc => doc.id === docu.id
      )
    );
  };

  return receivers?.map(receiver => {
    const checked = isChecked(receiver);
    const indeterminate = checked ? false : isIndeterminate(receiver);

    return {
      ...receiver,
      tableData: {
        ...receiver.tableData,
        checked,
        indeterminate,
      },
    };
  });
};
