import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const RefreshIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      style={style}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/CustomSvgIcon"
    >
      <path
        d="M4.08047 3.05191C5.77569 1.59505 7.94406 0.806455 10.1791 0.833947C12.4142 0.861439 14.5625 1.70313 16.2214 3.20125C17.8803 4.69937 18.9358 6.75106 19.1902 8.97175C19.4445 11.1924 18.8802 13.4297 17.6031 15.2641L14.675 9.99992H17.4235C17.4231 8.5606 17 7.15308 16.2065 5.9522C15.4131 4.75131 14.2844 3.80997 12.9605 3.24508C11.6367 2.68019 10.176 2.51665 8.76003 2.77476C7.34403 3.03288 6.03505 3.70129 4.99567 4.69696L4.08047 3.05191ZM16.0859 16.9479C14.3907 18.4048 12.2223 19.1934 9.98722 19.1659C7.75215 19.1384 5.60384 18.2967 3.94496 16.7986C2.28609 15.3005 1.23054 13.2488 0.976181 11.0281C0.72182 8.80739 1.28611 6.57017 2.56327 4.73576L5.49853 9.99992H2.73712C2.73694 11.4401 3.16013 12.8486 3.95406 14.0503C4.74799 15.2519 5.87764 16.1937 7.20252 16.7584C8.52741 17.3232 9.98909 17.4861 11.4058 17.2268C12.8225 16.9675 14.1317 16.2974 15.1707 15.3L16.0859 16.9479Z"
        fill="#1A49E7"
      />
    </CustomSvgIcon>
  );
};

RefreshIcon.defaultProps = {
  style: {},
};

RefreshIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default RefreshIcon;
