import React from 'react';
import { Typography, Link, Box } from '@material-ui/core';

import {
  defaultValue,
  getDaysOfIssueInDays,
  percentageFormat,
} from '../../../../../commons/utils';
import { t13s } from '../../../../../../translationKeys';
import { OPERATION_TYPE } from '../../../../../commons/constants/operations';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import StatusChip from '../../../../../../components/elements/chips/StatusChip';

export const GET_COLUMNS = ({ country, t, toggleDrawer }) => [
  {
    title: 'Folio',
    align: 'left',
    field: 'folio',
    type: 'numeric',
    width: '80px',
    customSort: () => 0,
    render: row => (
      <Link
        component="button"
        variant="body1"
        align="left"
        onClick={() => toggleDrawer(row?.id)}
      >
        <Box fontWeight="fontWeightBold">{defaultValue(row?.folio)}</Box>
      </Link>
    ),
  },
  {
    title: 'Emisor',
    align: 'left',
    field: 'issuer.name',
    type: 'string',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.issuer?.name)}
      </Typography>
    ),
  },
  {
    title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Emisor`,
    field: 'issuer.identifier',
    type: 'string',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.issuer?.identifier)}
      </Typography>
    ),
  },
  {
    title: 'Plazo',
    align: 'left',
    field: 'expirationDate',
    type: 'date',
    width: '100px',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row.expirationDate)}
      </Typography>
    ),
  },
  {
    title: 'Días de Emisión',
    align: 'left',
    field: 'issuedDate',
    type: 'date',
    width: '100px',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {row?.issuedDate && defaultValue(getDaysOfIssueInDays(row.issuedDate))}
      </Typography>
    ),
  },
  {
    title: 'Tipo Operación',
    align: 'left',
    field: 'operationType',
    type: 'string',
    width: '100px',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(OPERATION_TYPE[row?.operationType]?.label)}
      </Typography>
    ),
  },
  {
    title: 'Monto bruto',
    align: 'left',
    field: 'amount',
    type: 'numeric',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        <CountryFormatHelper
          value={row?.amount}
          countryId={country}
          variant="currency"
        />
      </Typography>
    ),
  },
  {
    title: 'Cesionario',
    align: 'left',
    field: 'invoiceTaxProviders.assignee',
    type: 'string',
    width: '200px',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.invoiceTaxProviders?.assignee)}
      </Typography>
    ),
  },
  {
    title: 'Nombre Cesionario',
    align: 'left',
    field: 'invoiceTaxProviders.assigneeName',
    type: 'string',
    width: '200px',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.invoiceTaxProviders?.assigneeName)}
      </Typography>
    ),
  },
  {
    title: 'Tasa Originación',
    align: 'left',
    field: 'baseRate',
    type: 'numeric',
    width: '100px',
    customSort: () => 0,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {percentageFormat(row?.baseRate)}
      </Typography>
    ),
  },
  {
    title: 'Order ID',
    align: 'left',
    field: 'orderId',
    type: 'numeric',
    width: '120px',
    customSort: () => 0,
    render: row =>
      row?.orderId ? (
        <Link
          target="_blank"
          href={`/${country?.toLowerCase()}/orders/${row?.orderId}`}
          variant="body1"
          align="left"
        >
          <Box fontWeight="fontWeightBold">{defaultValue(row?.orderId)}</Box>
        </Link>
      ) : (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(row?.orderId)}
        </Typography>
      ),
  },
  {
    title: 'Estado BO',
    align: 'left',
    field: 'status',
    type: 'string',
    customSort: () => 0,
    render: row => (
      <StatusChip status={row?.status} entityType="invoice" size="small" />
    ),
  },
];
