import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  infoContainer: {
    color: '#8D8A96',
    fontWeight: 'bold',
    '& strong': {
      color: '#312F37',
      float: 'right',
    },
    '& .MuiGrid-item': {
      marginBottom: 8,
      padding: '0 10px',
    },
  },
  submitBtn: {
    marginTop: 18,
  },
});
