import * as Types from './receiver.types';

const initialState = {
  receiverForm: {},
  selectedDraftReceiver: null,
  selectedReceiver: null,
};

const selectedReceiver = (state = initialState.selectedReceiver, action) => {
  switch (action.type) {
    case Types.SELECT_RECEIVER.SUCCESS.type: {
      return action.payload;
    }
    case Types.CLEAN_SELECTED_RECEIVER.SUCCESS.type: {
      return initialState.selectedReceiver;
    }
    default:
      return state;
  }
};

const selectedDraftReceiver = (
  state = initialState.selectedDraftReceiver,
  action
) => {
  switch (action.type) {
    case Types.SELECT_DRAFT_RECEIVER.SUCCESS.type: {
      return action.payload;
    }
    case Types.UPDATE_DRAFT_RECEIVER.SUCCESS.type: {
      return { ...state, ...action.payload };
    }
    case Types.CLEAN_SELECTED_DRAFT_RECEIVER.SUCCESS.type: {
      return initialState.selectedDraftReceiver;
    }
    default:
      return state;
  }
};

const receiverForm = (state = initialState.receiverForm, action) => {
  switch (action.type) {
    case Types.SAVE_RECEIVER_FORM.SUCCESS.type: {
      return action.payload;
    }
    case Types.CLEAN_RECEIVER_FORM.SUCCESS.type: {
      return initialState.receiverForm;
    }
    default: {
      return state;
    }
  }
};

export const receiverReducer = {
  name: 'receiver',
  reducers: {
    receiverForm,
    selectedDraftReceiver,
    selectedReceiver,
  },
};
