import axios from 'axios';
import {
  SET_WALLET_ACTIVE_FILTERS,
  FETCH_WALLETS_START,
  FETCH_WALLETS_SUCCESS,
  FETCH_WALLETS_FAILURE,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_PENDING_REQUESTS_START,
  FETCH_ALL_REQUESTS_START,
  FETCH_REQUESTS_SUCCESS,
  FETCH_REQUESTS_FAILURE,
  FETCH_HTML_REPORT,
  FETCH_HTML_REPORT_SUCCESS,
  FETCH_HTML_REPORT_FAILURE,
  UPDATE_WALLET_STATUS_START,
  UPDATE_WALLET_STATUS_SUCCESS,
  UPDATE_WALLET_STATUS_FAILURE,
  UPDATE_REQUEST_STATUS_START,
  UPDATE_REQUEST_STATUS_SUCCESS,
  UPDATE_REQUEST_STATUS_FAILURE,
  CREATE_TRANSACTION_START,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  RESET_REQUESTS,
} from './types';
import { arrayToURL } from '../helpers/url';
import { enqueueSnackbar } from './notificationAction';
import { PAGE_SIZE_INIT, INIT_PAGE } from '../helpers/Constants';
import { b64toBlob, download } from '../helpers/fileHandling';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchWallets =
  ({ page = INIT_PAGE, limit = PAGE_SIZE_INIT, filters = null } = {}) =>
  dispatch => {
    dispatch({ type: FETCH_WALLETS_START });
    const stringFilters = arrayToURL(filters);
    axios
      .get(`api/wallet?page=${page}&limit=${limit}&filters=${stringFilters}`)
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_WALLETS_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_WALLETS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_WALLETS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updateWalletStatus = (status, selectedWallet) => dispatch => {
  dispatch({ type: UPDATE_WALLET_STATUS_START });
  axios
    .put(`api/wallet/${selectedWallet}`, {
      status,
    })
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.WALLET_STATUS_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: UPDATE_WALLET_STATUS_SUCCESS,
        payload: {
          status,
          profileId: selectedWallet,
        },
      });
      return resData;
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPDATE_WALLET_STATUS_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateRequestStatus =
  (updatedRequest, selectedRequest) => dispatch => {
    dispatch({ type: UPDATE_REQUEST_STATUS_START });
    axios
      .put(`api/wallet/request/${selectedRequest}`, updatedRequest)
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.WALLET_REQUEST_STATUS_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: UPDATE_REQUEST_STATUS_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_REQUEST_STATUS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const createTransaction = (transactionData, profileId) => dispatch => {
  dispatch({ type: CREATE_TRANSACTION_START });
  axios
    .post(`api/wallet/${profileId}/transaction`, transactionData)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.WALLET_TRANSACTION_CREATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: CREATE_TRANSACTION_SUCCESS,
        payload: {
          newActivity: resData.newActivity,
          profileId,
        },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: CREATE_TRANSACTION_FAILURE,
        payload: { errorCode },
      });
    });
};

export const setWalletActiveFilters = activeFilters => dispatch => {
  dispatch({
    type: SET_WALLET_ACTIVE_FILTERS,
    payload: { walletActiveFilters: activeFilters },
  });
};

export const fetchTransactions = profileId => dispatch => {
  dispatch({ type: FETCH_TRANSACTIONS_START });
  axios
    .get(`api/wallet/${profileId}/transaction`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_TRANSACTIONS_SUCCESS,
        payload: {
          transactions: resData.activities,
          profileId,
        },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_WALLET_TRANSACTIONS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_TRANSACTIONS_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchRequests =
  (identifier, status = null) =>
  dispatch => {
    dispatch({
      type: status ? FETCH_PENDING_REQUESTS_START : FETCH_ALL_REQUESTS_START,
    });

    let url = `api/wallet/${identifier}/request`;
    if (status) {
      url = `${url}?status=${status}`;
    }

    axios
      .get(url)
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_REQUESTS_SUCCESS,
          payload: { requests: resData.walletRequests, identifier, status },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_WALLET_REQUEST_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_REQUESTS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchHtmlReport = (identifier, status, profileId) => dispatch => {
  dispatch({
    type: FETCH_HTML_REPORT,
  });

  axios
    .post('api/wallet/report', { identifier, status, profileId })
    .then(res => {
      const resData = res.data;
      const blob = b64toBlob(resData.htmlReport, 'text/html');
      download(blob, 'report.html');

      dispatch({
        type: FETCH_HTML_REPORT_SUCCESS,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_WALLET_HTML_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_HTML_REPORT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const resetRequests = () => dispatch => {
  dispatch({ type: RESET_REQUESTS });
};
