import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  COUNTRY_CODE_CL,
  INVOICE_STATUS_CL,
  INVOICE_STATUS_MX,
} from '../../../../../../helpers/Constants';
import Folio from './Folio';
import Attributes from './Attributes';
import {
  EndDateChip,
  IssueDateChip,
} from '../../../../../../components/elements/ChipComponents';
import { dateFormatter } from '../../../../../../helpers/DateUtils';
import Amount from './Amount';
import Funds from './Funds';
import Status from './Status';
import InvoiceDates from './InvoiceDates';
import TotalOrdersTransfered from './TotalOrdersTransfered';
import XtreeStatus from './Xtree';

const useColumns = () => {
  const country = useSelector(state => state.config.country);

  const folio = {
    title: 'Folio',
    field: 'folio',
    sorting: false,
    render: rowData => {
      return <Folio rowData={rowData} />;
    },
  };

  const attributes = ({ attributesClProps }) => ({
    title: 'Atributos',
    align: 'center',
    type: 'boolean',
    sorting: false,
    ...attributesClProps,
    render: rowData => <Attributes rowData={rowData} />,
  });

  const issueDate = {
    title: 'Fecha emisión',
    field: 'issueDate',
    align: 'center',
    sorting: false,
    customFilterAndSearch: (term, rowData) =>
      String(dateFormatter(rowData.issueDate)).includes(term),
    render: rowData => <IssueDateChip data={rowData} />,
  };

  const expirationDate = {
    title: 'Fecha Vencimiento',
    field: 'expirationDate',
    align: 'center',
    sorting: false,
    customFilterAndSearch: (term, rowData) =>
      String(dateFormatter(rowData.expirationDate)).includes(term),
    render: rowData => <EndDateChip data={rowData} />,
  };

  const amount = {
    title: 'Monto total',
    field: 'amount',
    align: 'center',
    sorting: false,
    render: rowData => <Amount rowData={rowData} />,
  };

  const funds = {
    title: 'Fondos',
    type: 'string',
    align: 'center',
    sorting: false,
    render: rowData => <Funds rowData={rowData} />,
  };

  const status = {
    title: 'Estado factura',
    field: 'status',
    type: 'string',
    align: 'center',
    sorting: false,
    lookup: country === COUNTRY_CODE_CL ? INVOICE_STATUS_CL : INVOICE_STATUS_MX,
    render: rowData => <Status rowData={rowData} />,
  };

  const xtreeStatus = {
    title: 'X-tree',
    field: 'xtreeStatus',
    type: 'string',
    align: 'center',
    sorting: false,
    render: rowData => <XtreeStatus rowData={rowData} />,
  };

  const invoiceDates = {
    title: 'Fechas factura',
    align: 'center',
    sorting: false,
    render: rowData => <InvoiceDates rowData={rowData} />,
  };

  const totalOrdersTransfered = {
    title: 'Operaciones',
    field: 'totalOrdersTransferred',
    type: 'numeric',
    width: '30px',
    align: 'center',
    sorting: false,
    render: rowData => <TotalOrdersTransfered rowData={rowData} />,
  };

  const issuerName = {
    title: 'Proveedor',
    field: 'issuerName',
    type: 'string',
    align: 'center',
    sorting: false,
    render: rowData => {
      const { issuerName: name } = rowData;
      return (
        <Typography variant="body2" color="textPrimary">
          {name}
        </Typography>
      );
    },
  };

  const issuerIdentifier = ({ issuerIdentifierTitle }) => ({
    title: `${issuerIdentifierTitle} proveedor`,
    field: 'issuerIdentifier',
    align: 'center',
    sorting: false,
    render: rowData => {
      const { issuerIdentifier: identifier } = rowData;
      return (
        <Typography align="center" variant="body2" color="textPrimary">
          {identifier}
        </Typography>
      );
    },
  });

  return {
    folio,
    attributes,
    issueDate,
    expirationDate,
    amount,
    funds,
    status,
    invoiceDates,
    totalOrdersTransfered,
    issuerName,
    issuerIdentifier,
    xtreeStatus,
  };
};

export default useColumns;
