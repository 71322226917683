import { useQuery } from 'react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const usePayroll = ({ filters, getFilters, page, itemsPerPage }) => {
  const { country } = useSelector(state => state.config);

  const { isLoading, isRefetching, data } = useQuery(
    ['getPayrolls', filters, country, page, itemsPerPage],
    () =>
      axios.get('/api/payment/order', {
        params: {
          ...getFilters(),
          size: itemsPerPage,
          page,
        },
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const {
    data: { data: payrolls, pagination },
  } = data || { data: {}, pagination: {} };

  return {
    payrolls,
    pagination,
    isLoading: isLoading || isRefetching,
  };
};

export default usePayroll;
