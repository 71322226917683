import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import StatusChip from '../../../../components/elements/chips/StatusChip';
import Scroll from '../../../../components/elements/Scroll';
import { defaultValue } from '../../../commons/utils';
import { BtnFilter } from '../../../commons/components/BtnFilter';
import { PayrollFilter } from './PayrollFilter';
import { useFormWithSummary } from '../../../commons/hooks/useFormWithSummary';
import { usePayrollActions } from '../../adapters/store/payroll';
import { useStyles } from './styles';
import { SORT_BY } from './constants';
import { getDescription, defaultSortBy, getSortLabel } from './utils';
import { useFetchPayrolls } from '../../domains/payroll/services';
import { MainLayout } from '../../../MainLayout';

const renderList = ({
  isLoading,
  classes,
  country,
  data,
  selectPayroll,
  cleanPayrollSelector,
}) => {
  if (isLoading) {
    return Array.from(new Array(3)).map((_, idx) => (
      <Skeleton
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        variant="rect"
        height={140}
        className={classes.mbCard}
      />
    ));
  }

  return data.map(payroll => {
    return (
      <Grid container key={payroll.id} className={classes.card}>
        <Grid item xs={9} className={classes.leftSection}>
          <Typography variant="h6">{payroll.fund}</Typography>
          <Link
            className={classes.linkDetails}
            component={RouterLink}
            disabled
            onClick={() => {
              selectPayroll(payroll);
              cleanPayrollSelector();
            }}
            to={`/${country.toLowerCase()}/funds/payroll/${payroll?.id}`}
          >
            Ver detalle
          </Link>
          <Scroll>
            {getDescription(payroll).map((description, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={idx} className={classes.field}>
                <Typography variant="subtitle1" className={classes.label}>
                  {description.label}
                </Typography>
                <Typography variant="subtitle1">
                  {defaultValue(description.value)}
                </Typography>
              </Grid>
            ))}
          </Scroll>
        </Grid>

        <Grid item xs={3} className={classes.rightSection}>
          <Grid
            container
            className={classes.amountBox}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Typography variant="subtitle1" className={classes.label}>
                Monto Total
              </Typography>
              <Typography variant="h4" className={classes.amount}>
                <CountryFormatHelper
                  value={payroll.amount}
                  countryId={country}
                  variant="currency"
                  size="small"
                />
              </Typography>
              <StatusChip
                key={payroll.payrollStatus}
                status={payroll.payrollStatus}
                entityType="payrollFunds"
                size="small"
              />
            </Grid>
            <Grid item xs={1}>
              <Grid container justifyContent="center" alignItems="center">
                <MoreVertIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });
};

export const PayrollList = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState({
    orderBy: { createdAt: 'createdAt:desc' },
  });
  const [sortBy, setSortBy] = useState(defaultSortBy.value);
  const { country } = useSelector(state => state.config, shallowEqual);
  const { cleanPayrollSelector, selectPayroll } = usePayrollActions();
  const { payrolls, isLoading } = useFetchPayrolls(filters, {
    refetchOnMount: false,
  });
  const {
    appliedFilters,
    formFilled,
    handleSubmit,
    setShowFilter,
    showFilter,
  } = useFormWithSummary();

  const onSubmit = handleSubmit(form => {
    setFilters({ ...form, orderBy: { createdAt: sortBy } });
  });

  return (
    <MainLayout>
      <Box className={classes.rootContainer}>
        <Grid container className={classes.headerPanel}>
          <Grid item xs={3}>
            <Typography variant="h5">Nóminas</Typography>
          </Grid>
          <Grid item xs={9}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Box className={classes.mr15}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    variant="outlined"
                    name="statuses"
                    value={sortBy}
                    onChange={e => {
                      const sort = e.target.value;
                      setSortBy(sort);
                      setFilters({
                        ...formFilled,
                        orderBy: { createdAt: sort },
                      });
                    }}
                    className={classes.filterSelect}
                    renderValue={() => (
                      <Box color="text.secondary">
                        <span>Ordenar por: {getSortLabel(sortBy)}</span>
                      </Box>
                    )}
                    fullWidth
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {SORT_BY.map(s => (
                      <MenuItem key={s.value} value={s.value}>
                        {s.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <BtnFilter
                  appliedFilters={appliedFilters}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {renderList({
          isLoading,
          classes,
          country,
          data: payrolls,
          selectPayroll,
          cleanPayrollSelector,
        })}
      </Box>
      <PayrollFilter
        form={formFilled}
        onClose={() => setShowFilter(false)}
        onSubmit={onSubmit}
        open={showFilter}
      />
    </MainLayout>
  );
};
