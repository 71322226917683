import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Text from '../../../commons/components/Text';
import { useStyles } from './styles';
import { t13s } from '../../../../translationKeys';
import { useTranslation } from 'react-i18next';
import {
  payerCategorizationMenu,
  payerCategorizationType,
  predetermined,
} from '../../../commons/utils/payerCategorizationOptions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  PayerCategorization,
  PayerCategorizationSummary,
} from '../../../commons/interfaces/payerCategorization';

const MANAGEMENTS_RESULTS_LABES = t13s.LABEL.COLLECTIONS.MANAGEMENTS_RESULTS;

interface Props {
  open: boolean;
  isLoading: boolean;
  closeDrawer: (arg: boolean) => void;
  payerCategorization: PayerCategorizationSummary | undefined;
  handleClick: (categorizations: PayerCategorization[]) => void;
}

const PayerCategorizationDrawer = ({
  open,
  isLoading,
  closeDrawer,
  payerCategorization,
  handleClick,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isFirstTime = () => {
    if (payerCategorization) {
      if (
        Object.keys(payerCategorization).length === 0 ||
        (Object.keys(payerCategorization).length === 1 &&
          Object.keys(payerCategorization).includes(
            payerCategorizationType.RECAP
          ))
      ) {
        return true;
      }
    }
    return false;
  };

  const getValues = (): PayerCategorizationSummary => {
    if (!isFirstTime()) {
      return payerCategorization as PayerCategorizationSummary;
    }
    return {
      [payerCategorizationType.PAYMENT_INTENTION]: {
        result: predetermined,
        detail: '',
      },
      [payerCategorizationType.PAYMENT_CAPACITY]: {
        result: predetermined,
        detail: '',
      },
      [payerCategorizationType.CONTACTABILITY]: {
        result: predetermined,
        detail: '',
      },
    };
  };
  const values = getValues();
  const [contactabilty, setContactability] = useState<string>(
    values.CONTACTABILITY.result
  );
  const [paymentCapacity, setPaymentCapacity] = useState<string>(
    values.PAYMENT_CAPACITY.result
  );
  const [paymentIntention, setPaymentIntention] = useState<string>(
    values.PAYMENT_INTENTION.result
  );
  const [contactabiltyComment, setContactabiltyComment] = useState<string>(
    values.CONTACTABILITY.detail
  );
  const [paymentCapacityComment, setPaymentCapacityComment] = useState<string>(
    values.PAYMENT_CAPACITY.detail
  );
  const [paymentIntentionComment, setPaymentIntentionComment] =
    useState<string>(values.PAYMENT_INTENTION.detail);
  const [click, setClick] = useState(false);

  const check = (values: string[], firstTime: boolean) => {
    if (values.includes(predetermined)) return false;
    if (values.every(value => value === '')) return false;
    if (firstTime && values.includes('')) return false;
    return true;
  };

  const validate = () => {
    if (
      check(
        [contactabilty, paymentCapacity, paymentIntention] as string[],
        isFirstTime()
      )
    )
      setClick(true);
    else setClick(false);
  };

  const getCategorizations = (): PayerCategorization[] => {
    const categorizations = [];
    if (!isFirstTime() && payerCategorization) {
      const lastContactability =
        payerCategorization[payerCategorizationType.CONTACTABILITY] ||
        predetermined;
      if (
        contactabilty !== lastContactability.result ||
        contactabiltyComment !== lastContactability.detail
      ) {
        categorizations.push({
          type: payerCategorizationType.CONTACTABILITY,
          result: contactabilty,
          detail: contactabiltyComment,
        });
      }
      const lastPaymentIntention =
        payerCategorization[payerCategorizationType.PAYMENT_INTENTION] ||
        predetermined;
      if (
        paymentIntention !== lastPaymentIntention.result ||
        paymentIntentionComment !== lastPaymentIntention.detail
      ) {
        categorizations.push({
          type: payerCategorizationType.PAYMENT_INTENTION,
          result: paymentIntention,
          detail: paymentIntentionComment,
        });
      }
      const lastPaymentCapacity =
        payerCategorization[payerCategorizationType.PAYMENT_CAPACITY] ||
        predetermined;
      if (
        paymentCapacity !== lastPaymentCapacity.result ||
        paymentCapacityComment !== lastPaymentCapacity.detail
      ) {
        categorizations.push({
          type: payerCategorizationType.PAYMENT_CAPACITY,
          result: paymentCapacity,
          detail: paymentCapacityComment,
        });
      }
    } else if (isFirstTime()) {
      categorizations.push({
        type: payerCategorizationType.CONTACTABILITY,
        result: contactabilty,
        detail: contactabiltyComment,
      });
      categorizations.push({
        type: payerCategorizationType.PAYMENT_INTENTION,
        result: paymentIntention,
        detail: paymentIntentionComment,
      });
      categorizations.push({
        type: payerCategorizationType.PAYMENT_CAPACITY,
        result: paymentCapacity,
        detail: paymentCapacityComment,
      });
    }
    return categorizations;
  };

  useEffect(() => {
    validate();
  }, [contactabilty, paymentCapacity, paymentIntention]);

  return (
    <Drawer anchor="right" open={open} onClose={() => closeDrawer(!open)}>
      <div style={{ maxWidth: '350px', padding: '20px' }}>
        <Box my={1} ml={1}>
          <div style={{ display: 'flex' }}>
            <Text variant="h5" align="center" fontWeight="bold">
              {t(t13s.LABEL.COLLECTIONS.PAYER_CATEGORIZATION)}
            </Text>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => closeDrawer(!open)}
            >
              X
            </Button>
          </div>
          <Divider />
        </Box>
        <Box mt={2} mb={1} ml={1}>
          <Box mt={2}>
            <ul style={{ margin: 0, padding: 0 }}>
              <li
                style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}
              >
                <Text fontWeight="bold">
                  {t(t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES.CONTACTABILITY)}
                </Text>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t(t13s.LABEL.COLLECTIONS.TOOLTIP_CONTACTABILITY)}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </li>
              <li style={{ display: 'flex' }}>
                <Text>
                  {t(t13s.LABEL.COLLECTIONS.CONTACTABILITY_DESCRIPTION)}
                </Text>
              </li>
              <li style={{ listStyle: 'none', marginTop: 20 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: 330 }}
                >
                  <InputLabel>{t(t13s.LABEL.COLLECTIONS.LEVEL)}</InputLabel>
                  <Select
                    value={contactabilty}
                    onChange={e => setContactability(e.target.value as string)}
                    label={`${t(t13s.LABEL.COLLECTIONS.LEVEL)}`}
                  >
                    {payerCategorizationMenu[
                      payerCategorizationType.CONTACTABILITY
                    ].map(option => {
                      if (!isFirstTime() && option.value !== predetermined) {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            disabled={option.value === predetermined}
                          >
                            {t(
                              MANAGEMENTS_RESULTS_LABES[
                                option.label as unknown as keyof object
                              ]
                            )}
                          </MenuItem>
                        );
                      } else if (isFirstTime()) {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            disabled={option.value === predetermined}
                          >
                            {t(
                              MANAGEMENTS_RESULTS_LABES[
                                option.label as unknown as keyof object
                              ]
                            )}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </li>
              <li style={{ display: 'flex', marginTop: 20 }}>
                <Text fontWeight="bold">
                  {t(t13s.LABEL.COLLECTIONS.COMMENTARY)}
                </Text>
                <Text className={classes.spacingLeft}>
                  {t(t13s.LABEL.COLLECTIONS.OPTIONAL)}
                </Text>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  variant="outlined"
                  multiline
                  minRows={2}
                  className={classes.roundCorners}
                  style={{ width: 330 }}
                  value={contactabiltyComment}
                  onChange={e => setContactabiltyComment(e.target.value)}
                  inputProps={{ maxLength: 140 }}
                  helperText={`${
                    140 - contactabiltyComment.length
                  } caracteres restantes.`}
                />
              </li>
              <li style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontWeight="bold">
                  {t(t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES.PAYMENT_CAPACITY)}
                </Text>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t(t13s.LABEL.COLLECTIONS.TOOLTIP_PAYMENT_CAPACITY)}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </li>
              <li style={{ display: 'flex' }}>
                <Text>
                  {t(t13s.LABEL.COLLECTIONS.PAYMENT_CAPACITY_DESCRIPTION)}
                </Text>
              </li>
              <li style={{ listStyle: 'none', marginTop: 20 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: 330 }}
                >
                  <InputLabel>{t(t13s.LABEL.COLLECTIONS.LEVEL)}</InputLabel>
                  <Select
                    value={paymentCapacity}
                    onChange={e => setPaymentCapacity(e.target.value as string)}
                    label={`${t(t13s.LABEL.COLLECTIONS.LEVEL)}`}
                  >
                    {payerCategorizationMenu[
                      payerCategorizationType.PAYMENT_CAPACITY
                    ].map(option => {
                      if (!isFirstTime() && option.value !== predetermined) {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            disabled={option.value === predetermined}
                          >
                            {t(
                              MANAGEMENTS_RESULTS_LABES[
                                option.label as unknown as keyof object
                              ]
                            )}
                          </MenuItem>
                        );
                      } else if (isFirstTime()) {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            disabled={option.value === predetermined}
                          >
                            {t(
                              MANAGEMENTS_RESULTS_LABES[
                                option.label as unknown as keyof object
                              ]
                            )}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </li>
              <li style={{ display: 'flex', marginTop: 20 }}>
                <Text fontWeight="bold">
                  {t(t13s.LABEL.COLLECTIONS.COMMENTARY)}
                </Text>
                <Text className={classes.spacingLeft}>
                  {t(t13s.LABEL.COLLECTIONS.OPTIONAL)}
                </Text>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  variant="outlined"
                  multiline
                  minRows={2}
                  className={classes.roundCorners}
                  style={{ width: 330 }}
                  value={paymentCapacityComment}
                  onChange={e => setPaymentCapacityComment(e.target.value)}
                  inputProps={{ maxLength: 140 }}
                  helperText={`${
                    140 - paymentCapacityComment.length
                  } caracteres restantes.`}
                />
              </li>
              <li style={{ display: 'flex', alignItems: 'center' }}>
                <Text fontWeight="bold">
                  {t(
                    t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES.PAYMENT_INTENTION
                  )}
                </Text>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t(t13s.LABEL.COLLECTIONS.TOOLTIP_PAYMENT_INTENTION)}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </li>
              <li style={{ display: 'flex' }}>
                <Text>
                  {t(t13s.LABEL.COLLECTIONS.PAYMENT_INTENTION_DESCRIPTION)}
                </Text>
              </li>
              <li style={{ listStyle: 'none', marginTop: 20 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: 330 }}
                >
                  <InputLabel>{t(t13s.LABEL.COLLECTIONS.LEVEL)}</InputLabel>
                  <Select
                    value={paymentIntention}
                    onChange={e =>
                      setPaymentIntention(e.target.value as string)
                    }
                    label={`${t(t13s.LABEL.COLLECTIONS.LEVEL)}`}
                  >
                    {payerCategorizationMenu[
                      payerCategorizationType.PAYMENT_INTENTION
                    ].map(option => {
                      if (!isFirstTime() && option.value !== predetermined) {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            disabled={option.value === predetermined}
                          >
                            {t(
                              MANAGEMENTS_RESULTS_LABES[
                                option.label as unknown as keyof object
                              ]
                            )}
                          </MenuItem>
                        );
                      } else if (isFirstTime()) {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.value}
                            disabled={option.value === predetermined}
                          >
                            {t(
                              MANAGEMENTS_RESULTS_LABES[
                                option.label as unknown as keyof object
                              ]
                            )}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </li>
              <li style={{ display: 'flex', marginTop: 20 }}>
                <Text fontWeight="bold">
                  {t(t13s.LABEL.COLLECTIONS.COMMENTARY)}
                </Text>
                <Text className={classes.spacingLeft}>
                  {t(t13s.LABEL.COLLECTIONS.OPTIONAL)}
                </Text>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  variant="outlined"
                  multiline
                  minRows={2}
                  className={classes.roundCorners}
                  style={{ width: 330 }}
                  value={paymentIntentionComment}
                  onChange={e => setPaymentIntentionComment(e.target.value)}
                  inputProps={{ maxLength: 140 }}
                  helperText={`${
                    140 - paymentIntentionComment.length
                  } caracteres restantes.`}
                />
              </li>
              <li className={classes.spacingAll}>
                <Button
                  variant="outlined"
                  style={{ width: 150 }}
                  onClick={() => closeDrawer(!open)}
                >
                  {t(t13s.LABEL.COLLECTIONS.CANCEL)}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!click || isLoading}
                  style={{ width: 150 }}
                  onClick={() => handleClick(getCategorizations())}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    `${t(t13s.LABEL.COLLECTIONS.CONFIRM)}`
                  )}
                </Button>
              </li>
            </ul>
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default PayerCategorizationDrawer;
