import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { verifyDocumentsStatusService } from '../services';

const useVerifyDocumentsStatus = (documentableType, documentableId) => {
  const dispatch = useDispatch;

  const {
    data: documentsStatus,
    refetch: verifyDocumentsStatus,
    isLoading: verifyDocumentsStatusIsLoading,
    isError: verifyDocumentsStatusError,
  } = useQuery(
    ['verifyDocumentsStatus', documentableType, documentableId],
    () => verifyDocumentsStatusService(documentableType, documentableId),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.VERIFY_DOCUMENTS_STATUS_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    documentsStatus,
    verifyDocumentsStatus,
    verifyDocumentsStatusIsLoading,
    verifyDocumentsStatusError,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useVerifyDocumentsStatus };
