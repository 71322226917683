import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Select } from './Select';
import { SECOND_WHITE_COLOR } from '../../helpers/Constants';
import loading from '../../assets/loading.gif';
import AlertForm from './AlertForm';

const useStyles = makeStyles({
  dialog: {
    background: SECOND_WHITE_COLOR,
    borderRadius: '17px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogTitle: {
    marginBottom: 10,
  },
  dialogSubtitle: {
    marginBottom: 20,
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    padding: '10px 50px',
  },
  copyButton: {
    marginLeft: 20,
  },
  buttonSubmit: {
    marginBottom: 10,
  },
});

const ReportFormDialog = ({
  open,
  handleCloseDialog,
  handleSubmitForm,
  report,
  showLoader,
}) => {
  const {
    name: nameReport,
    description: descriptionReport,
    repository: repositoryReport = 'xepelin_global',
    query: queryReport,
  } = report || {};
  const { reportError } = useSelector(state => state.report);
  const [name, setName] = useState(nameReport);
  const [description, setDescription] = useState(descriptionReport);
  const [repository, setRepository] = useState(repositoryReport);
  const [query, setQuery] = useState(queryReport);

  const handleInputChange = (event, setState) => {
    const {
      target: { value },
    } = event;
    // eslint-disable-next-line no-unused-expressions
    value.trim().length ? setState(value) : setState(null);
  };

  const getInputs = () => {
    return (
      <>
        <TextValidator
          fullWidth
          variant="outlined"
          label="Nombre"
          type="string"
          name="name"
          placeholder="Reporte XXXX"
          aria-describedby="Nombre"
          value={name}
          onChange={e => handleInputChange(e, setName)}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Descripción"
          name="description"
          placeholder="El siguiente reporte fue generado por el siguiente motivo: XXXX"
          aria-describedby="Descripción"
          value={description}
          onChange={e => handleInputChange(e, setDescription)}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Query"
          name="query"
          placeholder="call sp_reporte_XXX | call sp_reporte_XXX @desde @hasta"
          aria-describedby="Query"
          value={query}
          onChange={e => handleInputChange(e, setQuery)}
          validators={['required']}
          errorMessages={['Campo requerido', 'Query incorrecto']}
        />
        <Select
          label="Repositorio"
          value={repository}
          name="repository"
          onChange={e => setRepository(e.target.value)}
          options={[{ label: 'xepelin_global', value: 'xepelin_global' }]}
        />
      </>
    );
  };

  const handleSubmit = () => {
    const { id } = report || {};
    const data = {
      id,
      name,
      description,
      repository,
      query,
    };
    handleSubmitForm(data);
  };

  const handleClose = () => {
    handleCloseDialog();
  };
  const classes = useStyles();
  let title = 'Nuevo reporte';
  if (report) {
    title = 'Editar reporte';
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        className: classes.dialog,
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container direction="column" align="center" justify="center">
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h5"
            color="textPrimary"
            component="div"
            className={classes.dialogTitle}
          >
            <Box fontWeight="fontWeightBold">{!showLoader && title}</Box>
          </Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid item xs={12}>
          {!showLoader && (
            <ValidatorForm onSubmit={() => handleSubmit()}>
              {getInputs()}
              {reportError && (
                <AlertForm message={reportError} variant="error" />
              )}
              <Grid className={classes.justifyCenter}>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Guardar
                </Button>
              </Grid>
            </ValidatorForm>
          )}
          {showLoader && (
            <Grid item xs={12} className={classes.justifyCenter}>
              <img src={loading} alt="loader" />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ReportFormDialog.defaultProps = {
  report: null,
};

ReportFormDialog.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  report: PropTypes.objectOf(Object),
  showLoader: PropTypes.bool.isRequired,
};

export default ReportFormDialog;
