/* eslint-disable radix */
import moment from 'moment';
import { DATE_FORMAT } from './Constants';

const getErrorMessage = (type, headerName, rowNumber) => {
  let message = '';
  switch (type) {
    case 'validateError':
      message = `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
      break;
    case 'requiredError':
      message = `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
      break;
    case 'headerError':
      message = `CSV: ${headerName} no es un header válido`;
      break;
    default:
      break;
  }
  return message;
};

const validateEmail = emailInput => {
  const emailToLowerCase = emailInput.trim().toLowerCase();
  const regexEmail =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  const secondRegexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/;
  const emailValidator = emailToLowerCase.toLowerCase().includes('@')
    ? emailToLowerCase.split('@')
    : emailToLowerCase.split('.');
  const urlEmailValidator = emailValidator[1]?.split('.');
  if (
    !regexEmail.test(emailToLowerCase) ||
    !secondRegexEmail.test(emailToLowerCase) ||
    urlEmailValidator[0]?.length > 40 ||
    emailValidator[2]?.length > 3 ||
    urlEmailValidator[1]?.length > 20 ||
    urlEmailValidator.length > 3 ||
    emailValidator.length > 3
  ) {
    return false;
  }
  return true;
};

export const PAYERS_BLOCKLIST_CSV_CONFIG = {
  headers: [
    {
      name: 'payerName',
      inputName: 'payerName',
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'payerIdentifier',
      inputName: 'payerIdentifier',
      required: true,
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'reason',
      inputName: 'reason',
      required: true,
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
  ],
};

export const ORDER_INVOICE_CSV_CONFIG = {
  headers: [
    {
      name: 'name',
      inputName: 'name',
      required: true,
      validate(name) {
        return name.trim().length > 0;
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'identifier',
      inputName: 'identifier',
      required: true,
      validate(value) {
        const identifier = value.trim().replace(/[^kK0-9]/g, '');
        return identifier.length >= 7;
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'folio',
      inputName: 'folio',
      required: true,
      validate(folio) {
        return folio.trim().length > 0 && Number(folio);
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'issuedDate',
      inputName: 'issuedDate',
      validate(value) {
        if (!value) return true;
        return moment(value.trim(), DATE_FORMAT, true).isValid();
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'expirationDate',
      inputName: 'expirationDate',
      validate(value) {
        if (!value) return true;
        return moment(value.trim(), DATE_FORMAT, true).isValid();
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'amount',
      inputName: 'amount',
      required: true,
      validate(amount) {
        return parseInt(amount, 0) >= 0;
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'email',
      inputName: 'email',
      optional: true,
      validate(email) {
        return validateEmail(email);
      },
      validateError(headerName, rowNumber) {
        return getErrorMessage('validateError', headerName, rowNumber);
      },
      requiredError(headerName, rowNumber) {
        return getErrorMessage('requiredError', headerName, rowNumber);
      },
      headerError(headerName) {
        return getErrorMessage('headerError', headerName);
      },
    },
    {
      name: 'accountType',
      inputName: 'accountType',
      optional: true,
    },
    {
      name: 'bankAccount',
      inputName: 'bankAccount',
      optional: true,
    },
    {
      name: 'bankCode',
      inputName: 'bankCode',
      optional: true,
    },
  ],
};

export const CSV_CONFIG_CONTACTS = {
  headers: [
    {
      name: 'rut',
      inputName: 'rut',
      required: true,
      unique: false,
      validate(rutInput) {
        const rut = rutInput.trim().replace(/[^kK0-9]/g, '');
        if (rut.length < 7) return false;
        return true;
      },
      validateError(headerName, rowNumber) {
        return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
      },
      requiredError(headerName, rowNumber) {
        return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
      },
    },
    {
      name: 'type',
      inputName: 'type',
      required: true,
      unique: false,
      validate(typeInput) {
        if (
          typeInput.includes('PAYMENT') ||
          typeInput.includes('CONFIRMATION')
        ) {
          return true;
        }
        return false;
      },
      validateError(headerName, rowNumber) {
        return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
      },
      requiredError(headerName, rowNumber) {
        return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
      },
    },
    {
      name: 'name',
      inputName: 'name',
      required: true,
      unique: false,
      requiredError(headerName, rowNumber) {
        return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
      },
    },
    {
      name: 'email',
      inputName: 'email',
      required: true,
      unique: false,
      validate(emailInput) {
        return validateEmail(emailInput);
      },
      validateError(headerName, rowNumber) {
        return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
      },
      requiredError(headerName, rowNumber) {
        return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
      },
    },
    {
      name: 'phone',
      inputName: 'phone',
      required: true,
      unique: false,
      validate(phoneInput) {
        if (phoneInput.length <= 25) {
          return true;
        }
        return false;
      },
      validateError(headerName, rowNumber) {
        return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
      },
      requiredError(headerName, rowNumber) {
        return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
      },
    },
  ],
  isHeaderNameOptional: true,
};
