import React from 'react';
import { Card as MaterialCard, styled } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomCard = styled(MaterialCard)({
  background: '#fff',
  borderRadius: 16,
  boxShadow: 'none',
});

const Card = ({ children }) => {
  return <CustomCard>{children}</CustomCard>;
};

Card.defaultProps = {
  children: null,
};

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
