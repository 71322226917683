/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, MenuItem } from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import InfoIcon from '@material-ui/icons/Info';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import { BUSINESS_STATUS } from '../../../../../commons/constants/main';
import {
  usePayrollSelector,
  useSelectedPayroll,
} from '../../../../adapters/store/payroll';
import { useStyles } from './ChangeStatusPayrollInvoiceDialog.styles';

export const ChangeStatusPayrollInvoiceDialog = ({
  open,
  handleCloseDialog,
  title,
  handleSubmit,
}) => {
  const css = useStyles();
  const [statusSelected, setStatusSelected] = useState(null);
  const payrollSelector = usePayrollSelector();
  const selectedPayroll = useSelectedPayroll();
  const isClosedPayroll = selectedPayroll.closedAt;
  const showWarningForPaidStatus =
    !isClosedPayroll && statusSelected === BUSINESS_STATUS.PAID.value;
  const hasCompleteSelection = Object.keys(payrollSelector).every(
    receiverId => payrollSelector[receiverId].selected
  );

  return (
    <BaseDialog isOpen={open} handleClose={handleCloseDialog} title={title}>
      <ValidatorForm onSubmit={() => handleSubmit(statusSelected)}>
        <Grid container justifyContent="center">
          <Grid item xs={12} className={css.selectInput}>
            {hasCompleteSelection && (
              <div className={css.receiverText}>
                Al realizar esta acción, modificarás los estados de todas las
                facturas seleccionadas.
              </div>
            )}
            <SelectValidator
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={e => setStatusSelected(e.target.value)}
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
            >
              {Object.keys(BUSINESS_STATUS)
                .filter(key => key !== 'RENEGOTIATION')
                .map(key => {
                  return (
                    <MenuItem key={key} value={key}>
                      {BUSINESS_STATUS[key].label}
                    </MenuItem>
                  );
                })}
            </SelectValidator>
          </Grid>

          {showWarningForPaidStatus && (
            <div className={css.feedback}>
              <div className={css.iconBox}>
                <InfoIcon />
              </div>
              <div className={css.msgBox}>
                Cierra la nómina para cambiar el estado de las facturas a
                "pagada".
              </div>
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={!statusSelected || showWarningForPaidStatus}
          >
            Guardar cambios
          </Button>
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeStatusPayrollInvoiceDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
