import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  TextField,
} from '@material-ui/core';
import Text from '../../../commons/components/Text';
import { renegotiationTypes } from '../../../commons/utils/constants';
import { useStyles } from './styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';
import endCalendar from '../../../../assets/calendar-placeholder.svg';

interface Props {
  selectedFolios: string[];
  open: boolean;
  closeDrawer: () => void;
  handleOnClick: (
    type: string,
    expirationDate: Date | undefined,
    justification: string,
  ) => void;
  isLoading: boolean;
}

const PaymentPromisesDrawer = ({
  selectedFolios,
  open,
  closeDrawer,
  handleOnClick,
  isLoading,
}: Props) => {
  const classes = useStyles();
  const loading = false;
  const selectedRenegotiation = renegotiationTypes[1].value;
  const [paymentPromiseDate, setPaymentPromiseDate] = useState<Moment | null>(
    null
  );
  const [justification, setJustification] = useState('');
  const [click, setClick] = useState(false);

  const validate = () => {
    if (
      justification &&
      paymentPromiseDate
    ) {
      setClick(true);
    } else {
      setClick(false);
    }
  };

  useEffect(() => {
    validate();
  }, [
    justification,
    paymentPromiseDate,
  ]);

  return (
    <Drawer anchor="right" open={open} onClose={() => closeDrawer()}>
      <div style={{ maxWidth: '350px', padding: '20px' }}>
        <Box my={1} ml={1}>
          <div style={{ display: 'flex' }}>
            <Text
              isLoading={loading}
              variant="h5"
              align="center"
              fontWeight="bold"
            >
              Fecha de pago prometida
            </Text>
            <Button
              style={{ marginLeft: '30px' }}
              onClick={() => closeDrawer()}
            >
              X
            </Button>
          </div>
          <Divider />
        </Box>
        <Box mt={2} mb={1}>
          <Box mt={2}>
            <ul style={{ margin: 0, padding: 0 }}>
              <li className={classes.spacingVertical}>
                <Text isLoading={loading} fontWeight="bold">
                  Folios seleccionados:
                </Text>
              </li>
              <li className={classes.spacingBottom}>
                <Text isLoading={loading} className={classes.label}>
                  {selectedFolios.join('; ')}
                </Text>
              </li>
              <li className={classes.spacingVertical}>
                <Text isLoading={loading} fontWeight="bold">
                  Selecciona la fecha de pago prometida
                </Text>
              </li>
              <li className={classes.spacingVerticalDif}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    style={{ width: 330 }}
                    label="Fecha"
                    format={'DD-MM-YYYY'}
                    value={paymentPromiseDate}
                    onChange={date => setPaymentPromiseDate(date)}
                    minDateMessage="Fecha inválida"
                    autoOk
                    InputProps={{
                      endAdornment: <img src={endCalendar} />
                    }}
                  />
                </MuiPickersUtilsProvider>
              </li>
              <li style={{ display: 'flex' }}>
                <Text isLoading={loading} fontWeight="bold">
                  Motivo
                </Text>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={2}
                  className={classes.roundCorners}
                  style={{ width: 330 }}
                  value={justification}
                  onChange={e => setJustification(e.target.value)}
                />
              </li>
              <li className={classes.spacingAll}>
                <Button
                  variant="outlined"
                  style={{ width: 150 }}
                  onClick={closeDrawer}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!click || isLoading}
                  style={{ width: 150 }}
                  onClick={() =>
                    handleOnClick(
                      selectedRenegotiation,
                      paymentPromiseDate?.toDate(),
                      justification
                    )
                  }
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Confirmar'
                  )}
                </Button>
              </li>
            </ul>
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default PaymentPromisesDrawer;