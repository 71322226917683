import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';

const updateOrderCommentsTags = async (
  commentIds: number[],
  userEmail: string
) => {
  try {
    const { data } = await axios.put(`/api/commenttag/viewed`, {
      commentIds,
      userEmail,
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};
export default updateOrderCommentsTags;
