import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';

const verifyRequirements = async (operationId: number) => {
  try {
    const { data } = await axios.post<number[]>(
      `/api/requirement/orderinvoice/verify/${operationId}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default verifyRequirements;
