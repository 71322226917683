import React, { useEffect } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import Panel from '../../../../components/elements/Panel';
import TabSection from './TabSection';
import useTabSection from '../../hooks/useTabSection';
import FiltersSection from './FiltersSection';
import PayerCard from './PayerCard';
import ListCommentDrawer from '../../../commons/components/ListCommentDrawer';
import CommentDialog from '../../../commons/components/CommentDialog';
import ResponsiblesDrawer from '../../../commons/components/ResponsiblesDrawer/ResponsiblesDrawer';
import useHomeCollectionBody from '../../hooks/useHomeCollectionBody';
import ContainerPagination from './styles';
import PaginationMaterialTable from '../../../../components/elements/PaginationMaterialTable';
import useFiltersSection from '../../hooks/useFiltersSection';
import Loader from '../../../../components/elements/Loader';
import { fetchCollectionPayers } from '../../../../actions/collectionActions';
import { CollectionPayer } from '../../../commons/interfaces/collection';

const HomeCollectionBody = () => {
  const { activeTab, arrayTabs, handleBusinessSegmentTabChange } =
    useTabSection();
  const {
    search,
    size,
    page,
    selectedOperationTypesStatusFilter,
    selectedPriority,
    rowsPerPage,
    handleChangeSelectedStatuses,
    handleDeleteSelectedStatuses,
    handleSearch,
    handleChangePage,
    handleChangeSearchInput,
    handleChangePriority,
    setRowsPerPage,
  } = useFiltersSection(activeTab);
  const {
    showCommentDialog,
    showListCommentDrawer,
    showResponsiblesDrawer,
    handleShowCommentDialog,
    handleShowListCommentDrawer,
    handleShowResponsiblesDrawer,
    handleSubmitCommentDialog,
  } = useHomeCollectionBody();
  const {
    collectionPayers,
    collectionPayerPagination,
    fetchCollectionPayersIsLoading,
  } = useSelector((state: RootStateOrAny) => state.collection);
  const {
    comments,
    fetchCommentsIsLoading,
    createCommentIsLoading,
    commentWasCreated,
    createCommentError,
  } = useSelector((state: RootStateOrAny) => state.comments);
  const dispatch = useDispatch();

  useEffect(() => {
    handleSearch();
  }, [
    search,
    size,
    page,
    selectedOperationTypesStatusFilter,
    selectedPriority,
  ]);

  useEffect(() => {
    if (commentWasCreated) {
      handleShowCommentDialog(false);
    }
  }, [commentWasCreated]);

  return (
    <Panel
      contentPadding="zero"
      preTitle={
        <TabSection
          activeTab={activeTab}
          handleTabChange={valueTab =>
            handleBusinessSegmentTabChange(valueTab, 'businessSegment')
          }
          tabs={arrayTabs}
        />
      }
      filters={
        <FiltersSection
          selectedOperationTypesStatusFilter={
            selectedOperationTypesStatusFilter
          }
          selectedPriority={selectedPriority}
          handleChangeSearchInput={handleChangeSearchInput}
          handleChangeSelectedPriority={handleChangePriority}
          handleChangeSelectedStatuses={handleChangeSelectedStatuses}
          handleDeleteSelectedStatuses={handleDeleteSelectedStatuses}
        />
      }
    >
      {fetchCollectionPayersIsLoading ? (
        <Loader />
      ) : (
        <>
          {collectionPayers?.map((collectionPayer: CollectionPayer) => (
            <PayerCard
              key={collectionPayer.collectionPayerIndentifier}
              collectionPayer={collectionPayer}
            />
          ))}

          <ContainerPagination>
            <PaginationMaterialTable
              page={collectionPayerPagination.page - 1}
              count={
                collectionPayers.length < rowsPerPage
                  ? collectionPayerPagination.page * rowsPerPage
                  : (collectionPayerPagination.page + 1) * rowsPerPage
              }
              onChangePage={(_e_, currentPage) => {
                handleChangePage(currentPage + 1, rowsPerPage);
              }}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              onChangeRowsPerPage={e => {
                dispatch(
                  fetchCollectionPayers({
                    page,
                    size: e.target.value,
                  })
                );
                setRowsPerPage(e.target.value);
              }}
              labelRowsPerPage="Registros por página"
              labelDisplayedRows={() => ``}
            />
          </ContainerPagination>
        </>
      )}

      {showCommentDialog && (
        <CommentDialog
          isOpen={showCommentDialog}
          isLoading={createCommentIsLoading}
          error={createCommentError}
          sourceId={1}
          sourceType="payer"
          handleCloseDialog={() => handleShowCommentDialog(false)}
          handleSubmitDialog={handleSubmitCommentDialog}
        />
      )}

      {showListCommentDrawer && (
        <ListCommentDrawer
          isOpen={showListCommentDrawer}
          isLoading={fetchCommentsIsLoading}
          sourceId={1}
          sourceType="payer"
          comments={comments}
          handleCloseDrawer={() => handleShowListCommentDrawer(false)}
        />
      )}
      {showResponsiblesDrawer && (
        <ResponsiblesDrawer
          isOpen={showResponsiblesDrawer}
          sourceId={1}
          sourceType="payer"
          handleCloseDrawer={() => handleShowResponsiblesDrawer(false)}
        />
      )}
    </Panel>
  );
};

export default HomeCollectionBody;
