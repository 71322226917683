import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  CircularProgress,
  styled,
  Typography,
  Box,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Dropzone from '../../elements/Dropzone/Dropzone';
import BaseDialog from '../BaseDialog';
import AlertForm from '../../elements/AlertForm';
import AlertMessage from '../../elements/AlertMessage';
import { getCsvTemplate, onDrop } from './FileUploadUtils';

const CustomOuterGrid = styled(Grid)({
  marginBottom: 20,
});

const CustomInnerGrid = styled(Grid)({
  marginBottom: 24,
});

const CustomButton = styled(Button)({
  paddingLeft: 0,
});

const FileUploadDialog = ({
  open,
  handleCloseDialog,
  saving,
  handleLoad,
  title,
  description,
  actionText,
  errorsCsv,
  serverError,
  resetDataAndErrors,
  source,
  fileExtension = '.csv',
}) => {
  const errorMessage = `El archivo cargado presenta ${errorsCsv?.length}
  error${errorsCsv?.length > 1 ? 'es' : ''}`;

  const alertMessageComponent = (
    <>
      <Grid item>
        <Typography variant="body1" component="div">
          <Box fontWeight="bold">{errorMessage}</Box>
        </Typography>
      </Grid>
      <Grid item>
        <CustomButton color="primary" variant="text">
          <CSVLink
            data={errorsCsv}
            separator=","
            filename="errores"
            asyncOnClick
            onClick={(_event_, done) => {
              done();
            }}
          >
            Descargar el detalle
          </CSVLink>
        </CustomButton>
      </Grid>
    </>
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
      size="md"
    >
      <Grid container>
        <CustomOuterGrid container item xs={12} justifyContent="center">
          {errorsCsv?.length > 0 && (
            <CustomInnerGrid item xs={12}>
              <AlertMessage
                onClear={resetDataAndErrors}
                messageComponent={alertMessageComponent}
              />
            </CustomInnerGrid>
          )}

          {serverError && (
            <Grid item xs={12}>
              <AlertForm message={serverError} variant="error" />
            </Grid>
          )}

          {saving ? (
            <CircularProgress color="primary" size={60} />
          ) : (
            <Dropzone
              handleSubmit={files => handleLoad(onDrop(files, source))}
              maxFiles={1}
              accept={fileExtension}
              buttonText={actionText}
              isLoading={saving}
            />
          )}
        </CustomOuterGrid>

        <Grid container item xs={12} justifyContent="center">
          <Button
            startIcon={<CloudDownloadIcon color="primary" />}
            color="primary"
            disabled={saving}
          >
            <CSVLink
              data={[getCsvTemplate(source)]}
              separator=","
              filename="data_template"
              asyncOnClick
              onClick={(_event_, done) => {
                done();
              }}
            >
              Descargar planilla
            </CSVLink>
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

FileUploadDialog.defaultProps = {
  errorsCsv: null,
  serverError: null,
  description: '',
  resetDataAndErrors: null,
};

FileUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  handleLoad: PropTypes.func.isRequired,
  errorsCsv: PropTypes.arrayOf(Object),
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  description: PropTypes.string,
  serverError: PropTypes.string,
  resetDataAndErrors: PropTypes.func,
  source: PropTypes.string.isRequired,
};

export default FileUploadDialog;
