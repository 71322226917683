import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AlertUnlockLink, OrderLockGrid } from './styles';
import { Alert } from '../../../commons/components';
import { checkAuth } from '../../../../helpers/validation/auth';
import { ORDERS_LOCK_PERFORM } from '../../../../helpers/performsType';

const LockedOrderAlert = ({ handleOpenIsLockDialog }) => {
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const canLockOrder = checkAuth(roles, ORDERS_LOCK_PERFORM, rules);

  return (
    <OrderLockGrid container>
      <Container>
        <Alert
          type="warning"
          title="Operación bloqueada"
          message="Las acciones de esta operación están inhabilitadas en Backoffice."
        >
          {canLockOrder && (
            <AlertUnlockLink onClick={handleOpenIsLockDialog}>
              Desbloquear
            </AlertUnlockLink>
          )}
        </Alert>
      </Container>
    </OrderLockGrid>
  );
};

LockedOrderAlert.propTypes = {
  handleOpenIsLockDialog: PropTypes.func.isRequired,
};

export default LockedOrderAlert;
