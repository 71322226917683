import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '../../../../commons/formatters';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import PaginationMaterialTable from '../../../../../components/elements/PaginationMaterialTable';
import { t13s } from '../../../../../translationKeys';
import ActionsMenu from './ActionsMenu';
import { BankAccountVerificationIcon } from '../../../../commons/components';

const TransferAccountsTable = ({
  handleShowEditBankAccountDrawer,
  handleShowBankAccountSelectorDrawer,
  handleShowBankAccountDrawer,
  bankAccounts,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);

  const COLUMNS = [
    {
      title: 'Proveedor',
      type: 'string',
      name: 'accountHolder',
      render: rowData => {
        const name = rowData?.accountHolder;
        return (
          <Text variant="body2" color="textPrimary">
            {name}
          </Text>
        );
      },
    },
    {
      title: t(t13s.LABEL.PAYMENTS_BANK_ACCOUNT_IDENTIFIER),
      type: 'numeric',
      name: 'accountNumber',
      render: rowData => {
        const accountNumber = rowData?.accountNumber;
        return (
          <Text variant="body2" color="textPrimary">
            {accountNumber}
          </Text>
        );
      },
    },
    ...(country === 'MX'
      ? [
          {
            name: 'verificationStatus',
            align: 'left',
            render: rowData => {
              const verificationStatus = rowData?.verificationStatus;
              return (
                <BankAccountVerificationIcon
                  verificationStatus={verificationStatus}
                />
              );
            },
          },
        ]
      : []),
    {
      title: 'Monto',
      type: 'numeric',
      name: 'amount',
      render: rowData => {
        const amount = rowData?.amount;
        return (
          <Text variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={amount}
              countryId={rowData?.countryId}
              variant="currency"
            />
          </Text>
        );
      },
    },
    {
      name: 'actions',
      align: 'right',
      render: rowData => {
        return (
          <ActionsMenu
            rowData={rowData}
            handleShowBankAccountDrawer={handleShowBankAccountDrawer}
            handleShowEditBankAccountDrawer={handleShowEditBankAccountDrawer}
            handleShowBankAccountSelectorDrawer={
              handleShowBankAccountSelectorDrawer
            }
          />
        );
      },
    },
  ];

  return (
    <MaterialTable
      columns={COLUMNS}
      style={{ width: '100%', boxShadow: 'none' }}
      isLoading={isLoading}
      data={bankAccounts}
      components={{
        Toolbar: () => null,
        Pagination: props => (
          <PaginationMaterialTable
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            hasRowsSelector={false}
            hasLabelRowsPerPage={false}
            labelRowsPerPage=""
            rowsPerPage={5}
          />
        ),
      }}
      options={{
        emptyRowsWhenPaging: false,
        filtering: false,
        search: false,
      }}
    />
  );
};

const bankAccountsShape = PropTypes.shape({
  accountHolder: PropTypes.string,
  accountNumber: PropTypes.string,
  amount: PropTypes.number,
  bankName: PropTypes.string,
  countryId: PropTypes.string,
  identifier: PropTypes.string,
  verificationStatus: PropTypes.string,
  alias: PropTypes.string,
  accountEmail: PropTypes.string,
  tableData: PropTypes.shape({ id: PropTypes.number }),
});

TransferAccountsTable.propTypes = {
  handleShowEditBankAccountDrawer: PropTypes.func.isRequired,
  handleShowBankAccountDrawer: PropTypes.func.isRequired,
  handleShowBankAccountSelectorDrawer: PropTypes.func.isRequired,
  bankAccounts: PropTypes.arrayOf(PropTypes.shape(bankAccountsShape))
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TransferAccountsTable;
