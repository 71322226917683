/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CreateOutlined } from '@material-ui/icons';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Section from './Section';
import Container from './Container';
import ExpansionPanelResumeOperation from './ExpansionPanelResumeOperation';
import Can from '../Can';
import { CREDITS_EDIT_PERFORM } from '../../helpers/performsType';
import { dateFormatter } from '../../helpers/DateUtils';
import StatusChip from './chips/StatusChip';
import ChangeSimpleStatusDialog from '../dialogs/ChangeSimpleStatusDialog';
import FundDialog from '../dialogs/FundDialog';
import { updateCredit } from '../../actions/creditsActions';
import {
  fetchLinkFundRates,
  fetchLinkFundSummary,
  registerLinkFund,
  updateLinkFund,
} from '../../actions/linkFundActions';
import { ITEM_TYPE_CREDIT } from '../../helpers/Constants';
import { CLEAR_LINK_FUND_RATES } from '../../actions/types';

const useStyles = makeStyles(theme => ({
  containerButton: {
    padding: 10,
  },
  primaryButtonLabel: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  containerCard: {
    border: '1px solid rgb(231, 229, 236)',
    borderRadius: 16,
  },
  transferAmount: {
    color: theme.palette.error.main,
  },
}));

const CreditResume = ({
  creditSummary,
  onClickUpdate,
  creditSummaryIsLoading,
}) => {
  const classes = useStyles();
  const [fundData, setFundData] = useState(null);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [showLinkFundDialog, setShowLinkFundDialog] = useState(false);

  const { updateCreditIsLoading, updateCreditError, creditWasUpdated } =
    useSelector(state => state.credits);
  const {
    fundRates,
    fundSummary,
    fund,
    updateFundIsLoading,
    fundUpdated,
    fundRegistered,
    registerFundIsLoading,
    updateFundError,
    registerFundError,
    fetchFundRatesIsLoading,
  } = useSelector(state => state.linkFund);

  const dispatch = useDispatch();

  useEffect(() => {
    if (creditWasUpdated) {
      setShowChangeStatusDialog(false);
    }
  }, [creditWasUpdated]);

  useEffect(() => {
    if (fundSummary) {
      const { linkFundId } = fundSummary;
      dispatch(fetchLinkFundRates(linkFundId));
    } else {
      dispatch({ type: CLEAR_LINK_FUND_RATES });
    }
  }, [fundSummary, dispatch]);

  useEffect(() => {
    const [base = {}, debt = {}] = fundRates || [];
    setFundData(
      fund && {
        ...fund,
        baseRate: base?.rate,
        debtDays: debt?.days,
        debtRate: debt?.rate,
      }
    );
  }, [fund, fundRates]);

  useEffect(() => {
    if (fund) {
      dispatch(
        fetchLinkFundSummary({
          sourceId: creditSummary.id,
          sourceType: ITEM_TYPE_CREDIT,
        })
      );
    }
  }, [fund, creditSummary.id, dispatch]);

  useEffect(() => {
    if (fundUpdated) {
      setShowLinkFundDialog(false);
    }
  }, [fundUpdated]);

  useEffect(() => {
    if (fundRegistered) {
      setShowLinkFundDialog(false);
    }
  }, [fundRegistered]);

  const handleChangeStatusCredit = newStatus => {
    dispatch(updateCredit(creditSummary.id, { status: newStatus }));
  };

  const createLinkFund = fund => {
    const { baseRate, debtRate, debtDays, interest, ...rest } = fund;
    const linkFund = {
      ...rest,
      interest: Number(interest),
    };
    const rates = [
      {
        rate: Number(baseRate),
        days: 0,
      },
      {
        rate: Number(debtRate),
        days: Number(debtDays),
      },
    ];

    return { linkFund, rates };
  };

  const handleSubmitFundDialog = fund => {
    if (fundData) {
      dispatch(
        updateLinkFund(
          { sourceId: creditSummary.id, sourceType: ITEM_TYPE_CREDIT },
          { fund: createLinkFund(fund) }
        )
      );
    } else {
      dispatch(
        registerLinkFund(
          { sourceId: creditSummary.id, sourceType: ITEM_TYPE_CREDIT },
          { fund: createLinkFund(fund) }
        )
      );
    }
  };

  const getFundLabel = label => {
    return (
      <Typography
        variant="body2"
        color="textPrimary"
        component="div"
        onClick={() => setShowLinkFundDialog(true)}
      >
        <Box fontWeight="fontWeightBold" className={classes.primaryButtonLabel}>
          {label}
        </Box>
      </Typography>
    );
  };

  const translatePeriod = period => {
    let periocity;

    switch (period) {
      case 'BIANNUAL':
        periocity = 'Semestral';
        break;
      case 'QUARTERLY':
        periocity = 'Trimestral';
        break;
      case 'MONTHLY':
        periocity = 'Mensual';
        break;
      default:
        periocity = 'Quincenal';
    }

    return periocity;
  };

  const creditAttr = credit => {
    return [
      {
        label: 'Monto',
        placeholder: 'Monto',
        type: 'currency',
        fontWeight: '900',
        data: credit.amount,
      },
      {
        label: 'Nº de cuotas:',
        placeholder: 'Nº de cuotas',
        data: credit.fees,
      },
      {
        label: 'Tasa',
        placeholder: 'Tasa',
        data: credit.rate,
        type: 'percentage',
      },
      {
        label: 'Periocidad de pago',
        placeholder: 'Periocidad',
        data: translatePeriod(credit.period),
      },
      {
        label: 'Fecha incio crédito',
        placeholder: 'Fecha incio crédito',
        data: credit.startDate ? dateFormatter(credit.startDate) : '',
      },
      {
        label: 'Asesoría digital',
        placeholder: 'Asesoría digital',
        type: 'currency',
        data: credit?.operationCost,
      },
      {
        label: 'Monto de transferencia',
        placeholder: 'Monto de transferencia',
        type: 'currency',
        data: credit.amountToTransfer,
        className: classes.transferAmount,
      },
    ];
  };

  const linkFundAttr = fundSummary => {
    return [
      {
        label: getFundLabel('Fondo'),
        placeholder: 'Fondo',
        fontWeight: '900',
        data: fundSummary?.fundName,
      },
      {
        label: 'Monto total',
        placeholder: 'Monto total',
        type: 'currency',
        data: fundSummary?.amount,
      },
      {
        label: 'Tasa base',
        placeholder: 'Tasa base',
        data: fundSummary?.baseRate,
        type: 'percentage',
      },
      {
        label: 'Costo fondo',
        placeholder: 'Costo fondo',
        data: fundSummary?.interest,
      },
    ];
  };

  const items = [
    {
      title: 'Resumen del crédito',
      data: creditAttr(creditSummary),
      type: 'operation',
      isLoading: creditSummaryIsLoading,
      button: (
        <StatusChip
          entityType="credit"
          status={creditSummary.status}
          onClick={() => setShowChangeStatusDialog(true)}
        />
      ),
    },
    {
      title: 'Resumen Financiamiento Fondo',
      data: fundSummary && linkFundAttr(fundSummary),
      message: !fundSummary && 'No hay fondos vinculados a un crédito',
      type: 'fund',
      isLoading: fetchFundRatesIsLoading,
      button: !fundSummary && (
        <IconButton onClick={() => setShowLinkFundDialog(true)} size="medium">
          <AttachmentOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Section card padding={1} className={classes.containerCard}>
        <Container gridGap={15}>
          <ExpansionPanelResumeOperation
            wide
            items={items}
            justify="flex-end"
            requestTransferAmount={false}
          />
          <Container
            className={classes.containerButton}
            gridGap={5}
            gridTemplate="1fr"
            justify="center"
          >
            <Can
              perform={CREDITS_EDIT_PERFORM}
              yes={() => (
                <Button
                  disabled={creditSummaryIsLoading}
                  onClick={onClickUpdate}
                  variant="text"
                  color="primary"
                  startIcon={<CreateOutlined />}
                >
                  Editar crédito
                </Button>
              )}
            />
          </Container>
        </Container>
      </Section>

      {showChangeStatusDialog && (
        <ChangeSimpleStatusDialog
          open={showChangeStatusDialog}
          status={creditSummary.status}
          title="Cambiar estado del crédito"
          entityType="credit"
          handleCloseDialog={() => {
            setShowChangeStatusDialog(false);
          }}
          handleSubmit={handleChangeStatusCredit}
          isLoading={updateCreditIsLoading}
          error={updateCreditError}
        />
      )}

      {showLinkFundDialog && (
        <FundDialog
          linkFundData={fundData}
          linkFundType="CREDIT"
          linkFundRates={fundRates}
          open={showLinkFundDialog}
          error={updateFundError || registerFundError}
          isLoading={updateFundIsLoading || registerFundIsLoading}
          handleCloseDialog={() => {
            setShowLinkFundDialog(false);
          }}
          handleSubmit={handleSubmitFundDialog}
        />
      )}
    </>
  );
};

CreditResume.defaultProps = {
  creditSummaryIsLoading: false,
};

CreditResume.propTypes = {
  creditSummary: PropTypes.object.isRequired,
  onClickUpdate: PropTypes.func.isRequired,
  creditSummaryIsLoading: PropTypes.bool,
};

export default CreditResume;
