import { styled, Button as MaterialButton } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, ...args }) => {
  const CustomButton = styled(MaterialButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: 14,
    padding: '10px 24px',
    fontWeight: 700,
  }));

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CustomButton {...args}>{children}</CustomButton>;
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
