import React from 'react';
import PropTypes from 'prop-types';
import { Badge, CircularProgress, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styles from '../styles';

const TotalOrdersTransfered = ({ rowData }) => {
  const classes = styles;
  const { totalOrdersTransferred } = rowData;

  const { loadingFacturas, loadingTotalOrdersTransferred } = useSelector(
    state => state.order
  );

  if (loadingFacturas || loadingTotalOrdersTransferred)
    return <CircularProgress size={25} />;

  return (
    <Tooltip title="Operaciones Transferidas">
      <Badge
        classes={
          totalOrdersTransferred === 0
            ? { badge: classes.noTransferredOrders }
            : {}
        }
        badgeContent={
          totalOrdersTransferred ? totalOrdersTransferred.toString() : '0'
        }
        color="primary"
      />
    </Tooltip>
  );
};

TotalOrdersTransfered.propTypes = {
  rowData: PropTypes.shape({
    totalOrdersTransferred: PropTypes.number.isRequired,
  }).isRequired,
};

export default TotalOrdersTransfered;
