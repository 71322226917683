import {
  Box,
  styled,
  Typography,
  Divider as DividerMUI,
  makeStyles,
} from '@material-ui/core';
import { palette } from '../../../../../theme/palette';

export const useStyles = makeStyles({
  buttonDetailOperation: {
    width: 230,
    height: 30,
  },
});

export const ContainerCard = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid #CDCAD8`,
  '&:first-child': {
    borderTop: '0',
  },
  padding: '32px',
});

export const ContainerSelector = styled(Box)({
  marginRight: 24,
});

export const ContainerTitle = styled(Box)({
  display: 'flex',
  marginBottom: 15,
});
export const TotalOrdersTransferred = styled(Box)(
  ({ totalOrdersTransferred }: { totalOrdersTransferred: number }) => ({
    width: totalOrdersTransferred > 99 ? 32 : 24,
    height: totalOrdersTransferred > 99 ? 20 : 24,
    background:
      totalOrdersTransferred < 2
        ? palette.payments.statusColors.approved
        : palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',
    marginRight: 8,
    borderRadius: 20,
  })
);
export const TitleLeft = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
  color: palette.payments.grey1,
});

export const LabelSubTitle = styled(Typography)({
  fontSize: 16,
  color: palette.payments.grey2,
  marginRight: 5,
});
export const ValueSubTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  color: palette.primary.main,
});
export const ValueSubTitleUnderline = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  color: palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const ContainerDetailInformation = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 30,
  minWidth: 140,
});
export const LabelDetailInformation = styled(Typography)({
  fontSize: 14,
  color: palette.payments.grey3,
});

export const ValueDetailInformation = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
  color: palette.payments.grey1,
});

export const ButtonCollapse = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  background: palette.payments.softBlue,
  borderRadius: 4,
  marginLeft: 24,
  cursor: 'pointer',
});

export const Divider = styled(DividerMUI)({
  margin: '0px 24px',
});

export const TitleRight = styled(Typography)({
  fontSize: 16,
  marginBottom: 5,
});
export const SubTitleRight = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
});

export const RequirementText = styled(Typography)({
  color: '#312F37',
  fontSize: 14,
  fontWeight: 'bold',
});
