import React from 'react';
import { Breadcrumbs, Link, styled } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Text } from '../../../commons/formatters';

const CustomLink = styled(Link)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.grey[500],
  fontSize: 12,
}));

const SummaryBreadcrumbs = () => {
  const { country } = useSelector(state => state.config);
  const history = useHistory();
  const lowerCaseCountry = country.toLowerCase();

  const breadcrumbs = [
    <CustomLink
      underline="hover"
      key="1"
      href="/"
      onClick={() => history.push(`/${lowerCaseCountry}/payments`)}
    >
      Operaciones
    </CustomLink>,
    <Text color="textPrimary" fontWeight="bold" variant="body2">
      Detalle de operación
    </Text>,
  ];

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {breadcrumbs}
    </Breadcrumbs>
  );
};

SummaryBreadcrumbs.propTypes = {};

export default SummaryBreadcrumbs;
