import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { UNKNOWN_ERROR_CODE } from '../../../../helpers/Constants';
import { t13s } from '../../../../translationKeys';

import { editTransferAccountsService } from '../services';

const useEditTransferAccounts = () => {
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    mutate: editTransferAccounts,
  } = useMutation(
    ({ bankAccountData, accountId, businessId, originalAccount, orderId }) => {
      return editTransferAccountsService(
        bankAccountData,
        accountId,
        businessId,
        originalAccount,
        orderId
      );
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.PAYMENTS_BANK_ACCOUNT_UPDATE_SUCCESS,
            {
              variant: 'success',
            }
          )
        );
        onSuccessCallback();
      },
      onError: error => {
        const errorCode = error?.message ?? UNKNOWN_ERROR_CODE;
        dispatch(
          enqueueSnackbar(t13s.ERROR[errorCode], {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    data,
    isLoading,
    editTransferAccounts,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useEditTransferAccounts };
