import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumbs as BreadcrumbsMui,
  Typography,
  Link,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as LinkRouterDom } from 'react-router-dom';

const Breadcrumbs = props => {
  const { dataBreadCrumbs, ...otherProps } = props;
  return (
    <BreadcrumbsMui
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {dataBreadCrumbs.map((breadCrumb, index) => {
        const { title, link } = breadCrumb;
        if (link && index !== dataBreadCrumbs.length - 1) {
          return (
            <Link
              variant="subtitle2"
              color="textSecondary"
              key={title}
              to={link}
              component={LinkRouterDom}
            >
              {title}
            </Link>
          );
        }
        return (
          <Typography
            variant="subtitle2"
            color="textPrimary"
            key={link || title}
          >
            {title}
          </Typography>
        );
      })}
    </BreadcrumbsMui>
  );
};

Breadcrumbs.propTypes = {
  dataBreadCrumbs: PropTypes.arrayOf(PropTypes.object),
};

Breadcrumbs.defaultProps = {
  dataBreadCrumbs: [],
};

export default Breadcrumbs;
