import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  useDraftPayrollSelector,
  usePayrollSelector,
} from '../../payroll/state';
import * as Repo from './document.repo';
import { getDocumentsUseCase, getDraftDocumentsUseCase } from '../useCases';

export const FETCH_DOCUMENTS_KEY = 'opportunities:useFetchDocuments';
const FETCH_DOCUMENT_KEY = 'opportunities:useFetchDocument';
const FETCH_DOCUMENTS_FOR_DRAFT_KEY = 'opportunities:useFetchDocument';
const FETCH_ASSIGNEES_KEY = 'opportunities:useFetchAssignees';

export const useFetchDocuments = (filter, opts) => {
  const { receiverIdentifier } = useParams();
  const payrollSelector = usePayrollSelector();
  const req = { receiverIdentifier, ...filter };

  const { data, ...rest } = useQuery(
    [FETCH_DOCUMENTS_KEY, req],
    () => Repo.fetchDocumentsByReceiver(req),
    opts
  );

  const memoizedDocuments = useMemo(
    () =>
      getDocumentsUseCase({
        documents: data?.records,
        payrollSelector,
        receiverIdentifier,
      }),
    [data?.records, payrollSelector, receiverIdentifier]
  );

  return {
    ...rest,
    documents: memoizedDocuments,
    pagination: data?.pagination,
  };
};

export const useFetchDocument = (id, opts) => {
  return useQuery(
    [FETCH_DOCUMENT_KEY, id],
    () => Repo.fetchDocumentById(id),
    opts
  );
};

export const useFetchAssignees = (filters, opts) => {
  return useQuery(
    [FETCH_ASSIGNEES_KEY, filters],
    () => Repo.fetchAssignees(filters),
    opts
  );
};

export const useFetchDraftDocuments = (filter, opts) => {
  // NOTE: se necesitan todos los documentos, por eso el limit alto
  const req = { ...filter, limit: 999999 };
  const { receiverIdentifier } = req;
  const payrollSelectorState = usePayrollSelector();
  const draftPayrollSelector = useDraftPayrollSelector();
  const {
    data: allDocuments,
    isFetching,
    refetch,
  } = useQuery(
    [FETCH_DOCUMENTS_FOR_DRAFT_KEY, req],
    () => Repo.fetchDocumentsByReceiver(req),
    opts
  );

  const documents = getDraftDocumentsUseCase({
    draftDocuments: payrollSelectorState?.[receiverIdentifier]?.value,
    draftPayrollSelector,
    receiverIdentifier,
    allDocuments: allDocuments?.records,
  });

  return { documents, isFetching, refetch };
};
