import React from 'react';
import { Box, Breadcrumbs, Grid, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Text from '../../../commons/components/Text';
import { getCurrencyFormat } from '../../../../features/credit-line/utils';
import { PayerDebts } from '../../../commons/interfaces/payerDebts';
import { t13s } from '../../../../translationKeys';
import { StatusChip } from '../../../../components/elements/chips';
import ActionMenu from './ActionMenu';
import PayerCategorizationDrawer from '../PayerCategorizationDrawer';
import useFetchPayerCategorizationSummary from '../../infrastructure/store/useFetchPayerCategorizationSummary';
import { payerCategorizationType } from '../../../commons/utils/payerCategorizationOptions';
import RecapModal from '../RecapModal';
import useRecap from '../../hooks/useRecap';
import { RootStateOrAny, useSelector } from 'react-redux';
import { collectionRoles } from '../../../commons/utils/roles';
import { Role } from '../../../commons/interfaces/global';

const Wrapper = styled(Grid)({
  margin: '30px 0',
  padding: '24px 24px 0 24px',
  background: '#FFFF',
});
type CollectionByPayerHeaderProps = { isLoading: boolean; data: PayerDebts };
const CollectionByPayerHeader = ({
  isLoading,
  data,
}: CollectionByPayerHeaderProps) => {
  const { t } = useTranslation();
  const {
    showRecapModal,
    showPayerCategorizationDrawer,
    createPayerCategorizationsIsLoading,
    toggleModal,
    toggleDrawer,
    handleBulkCreatePayerCategorizations,
  } = useRecap({ payerIdentifier: data.payerIdentifier });
  const { data: payerCategorization, isLoading: loadingPayerCategorization } =
    useFetchPayerCategorizationSummary(data.payerIdentifier);

  const { roles } = useSelector((state: RootStateOrAny) => state.auth.user);
  const isCollectionRole = roles.some((role: Role) =>
    collectionRoles.includes(role.rolename)
  );

  return (
    <Wrapper container>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <Text fontWeight="bold" aria-current="page">
            Cobranza
          </Text>
          <Text fontWeight="bold">Detalle Responsable de pago</Text>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: '12px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Text fontWeight="bold" aria-current="page" isLoading={isLoading}>
                {data?.payerName}
              </Text>
              <Grid item>
                <Text fontWeight="bold">
                  {t(t13s.LABEL.BUSINESS_IDENTIFIER)}:{' '}
                  <span style={{ fontWeight: '500' }}>
                    {data?.payerIdentifier}
                  </span>
                </Text>
              </Grid>
              <Text fontWeight="medium" isLoading={isLoading}>
                {`Monto total: ${getCurrencyFormat(
                  Math.ceil(data?.totalAmount),
                  'CL'
                )}`}
              </Text>
            </Grid>
          </Grid>

          <Box sx={{ my: '24px' }}>
            <Grid>
              <Grid
                item
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex' }}>
                  {payerCategorization && (
                    <Text
                      fontWeight="bold"
                      isLoading={loadingPayerCategorization}
                    >
                      {t(
                        t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES.CONTACTABILITY
                      )}
                      :{' '}
                      <span style={{ marginRight: 20 }}>
                        <StatusChip
                          status={
                            payerCategorization[
                              payerCategorizationType.CONTACTABILITY
                            ]
                              ? `${
                                  payerCategorization[
                                    payerCategorizationType.CONTACTABILITY
                                  ].result
                                }_CONTACTABILITY`
                              : 'PENDING'
                          }
                          entityType="payerCategorization"
                        />
                      </span>
                    </Text>
                  )}

                  {payerCategorization && (
                    <Text
                      fontWeight="bold"
                      isLoading={loadingPayerCategorization}
                    >
                      {t(
                        t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES
                          .PAYMENT_CAPACITY
                      )}
                      :{' '}
                      <span style={{ marginRight: 20 }}>
                        <StatusChip
                          status={
                            payerCategorization[
                              payerCategorizationType.PAYMENT_CAPACITY
                            ]
                              ? `${
                                  payerCategorization[
                                    payerCategorizationType.PAYMENT_CAPACITY
                                  ].result
                                }_PAYMENT_CAPACITY`
                              : 'PENDING'
                          }
                          entityType="payerCategorization"
                        />
                      </span>
                    </Text>
                  )}
                  {payerCategorization && (
                    <Text
                      fontWeight="bold"
                      isLoading={loadingPayerCategorization}
                    >
                      {t(
                        t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES
                          .PAYMENT_INTENTION
                      )}
                      :{' '}
                      <span style={{ marginRight: 20 }}>
                        <StatusChip
                          status={
                            payerCategorization[
                              payerCategorizationType.PAYMENT_INTENTION
                            ]
                              ? `${
                                  payerCategorization[
                                    payerCategorizationType.PAYMENT_INTENTION
                                  ].result
                                }_PAYMENT_INTENTION`
                              : 'PENDING'
                          }
                          entityType="payerCategorization"
                        />
                      </span>
                    </Text>
                  )}
                </div>
                {isCollectionRole && (
                  <ActionMenu
                    showDrawer={showPayerCategorizationDrawer}
                    showModal={showRecapModal}
                    toggleDrawer={toggleDrawer}
                    toggleModal={toggleModal}
                  />
                )}
              </Grid>
              <Grid item>
                {payerCategorization && (
                  <Text
                    fontWeight="bold"
                    isLoading={loadingPayerCategorization}
                  >
                    {t(t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES.RECAP)}:{' '}
                    <span style={{ fontWeight: '500' }}>
                      {payerCategorization[payerCategorizationType.RECAP]
                        ? `${
                            payerCategorization[payerCategorizationType.RECAP]
                              .detail
                          }`
                        : 'Pendiente'}
                    </span>
                  </Text>
                )}
              </Grid>
              <Grid item>
                <Text fontWeight="bold" isLoading={isLoading}>
                  Facturas con mora:{' '}
                  <span style={{ fontWeight: '500' }}>
                    {data?.debtsQuantity || 0}
                  </span>
                </Text>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      {showPayerCategorizationDrawer && (
        <PayerCategorizationDrawer
          open={showPayerCategorizationDrawer}
          isLoading={createPayerCategorizationsIsLoading}
          closeDrawer={toggleDrawer}
          payerCategorization={payerCategorization}
          handleClick={handleBulkCreatePayerCategorizations}
        />
      )}
      {showRecapModal && (
        <RecapModal
          open={showRecapModal}
          isLoading={createPayerCategorizationsIsLoading}
          handleClose={toggleModal}
          handleClick={handleBulkCreatePayerCategorizations}
          recap={
            payerCategorization &&
            payerCategorization[payerCategorizationType.RECAP]
              ? payerCategorization[payerCategorizationType.RECAP].detail
              : ''
          }
        />
      )}
    </Wrapper>
  );
};

export default CollectionByPayerHeader;
