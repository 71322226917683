import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';
import {
  CommentDataToCreate,
  CommentDataToCreateResponseApi,
} from '../../interfaces';

const addOrderComment = async (
  operationId: number,
  commentDataToCreate: CommentDataToCreate
) => {
  try {
    const { data } = await axios.post<CommentDataToCreateResponseApi>(
      `/api/order/${operationId}/comment`,
      commentDataToCreate
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default addOrderComment;
