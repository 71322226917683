import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Text from '../../../commons/components/Text';
import { useStyles } from './styles';
import {
  appliedOnOptions,
  menuManagement,
} from '../../../commons/utils/managementOptions';
import { RootStateOrAny, useSelector } from 'react-redux';

interface Props {
  selectedFolios: string[];
  open: boolean;
  closeDrawer: () => void;
  handleOnClick: (
    type: string,
    subType: string,
    result: string,
    appliedOn: string,
    justification: string
  ) => void;
  isLoading: boolean;
}

const ManagementDrawer = ({
  open,
  closeDrawer,
  handleOnClick,
  isLoading,
}: Props) => {
  const classes = useStyles();
  const { country } = useSelector((state: RootStateOrAny) => state.config);
  const [type, setType] = useState<string>('');
  const [subType, setSubType] = useState<string>('');
  const [result, setResult] = useState<string>('');
  const [subTypeOptions, setSubTypeOptions] = useState([
    { value: '', label: '' },
  ]);
  const [resultOptions, setResultOptions] = useState([
    { value: '', label: '' },
  ]);
  const [showSubTypes, setShowSubTypes] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [justification, setJustification] = useState('');
  const [appliedOn, setAppliedOn] = useState('');
  const [click, setClick] = useState(false);

  const validate = () => {
    if (showSubTypes && showResults && type && subType && result && appliedOn)
      setClick(true);
    else if (type && !showSubTypes && !showResults && appliedOn) setClick(true);
    else if (type && showResults && result && appliedOn) setClick(true);
    else setClick(false);
  };

  useEffect(() => {
    validate();
  }, [type, subType, result, appliedOn]);

  const handleChangeType = (e: ChangeEvent<{value: unknown}>) => {
    const newType = e.target.value as string
    setType(newType);
    setSubType('');
    setResult('');
    const options = menuManagement[country][newType].subType;
    const results = menuManagement[country][newType].result;
    setSubTypeOptions(options);
    if (options.length > 0) setShowSubTypes(true);
    else setShowSubTypes(false);
    if (Object.keys(results).length === 0) setShowResults(false);
    else setShowResults(true)
    if (newType === 'PAYMENT_PLAN') setResultOptions(results[newType])
  }

  return (
    <Drawer anchor="right" open={open} onClose={() => closeDrawer()}>
      <div style={{ maxWidth: '350px', padding: '20px' }}>
        <Box my={1} ml={1}>
          <div style={{ display: 'flex' }}>
            <Text
              isLoading={false}
              variant="h5"
              align="center"
              fontWeight="bold"
            >
              Agregar gestión de cobranza
            </Text>
            <Button style={{ marginLeft: 10 }} onClick={() => closeDrawer()}>
              X
            </Button>
          </div>
          <Divider />
        </Box>
        <Box mt={2} mb={1} ml={1}>
          <Box mt={2}>
            <ul style={{ margin: 0, padding: 0 }}>
              <li className={classes.spacingVertical}>
                <Text>Ingresa la gestión realizada o información clave que desees reportar</Text>
              </li>
              <li style={{ listStyle: 'none', marginTop: 20 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: 330 }}
                >
                  <InputLabel>Tipo de gestión o reporte</InputLabel>
                  <Select
                    value={type}
                    onChange={handleChangeType}
                    label="Tipo de gestión o reporte"
                  >
                    {Object.values(menuManagement[country]).map(option => (
                      <MenuItem key={option.type.label} value={option.type.value}>
                        {option.type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
              {showSubTypes && (
                <li style={{ listStyle: 'none', marginTop: 20 }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ width: 330 }}
                  >
                    <InputLabel>Canal</InputLabel>
                    <Select
                      disabled={type === ''}
                      value={subType}
                      onChange={e => {
                        const newSubType = e.target.value as string;
                        setSubType(newSubType);
                        setResult('');
                        const options = menuManagement[country][type].result[newSubType]
                        setResultOptions(options);
                        if (options.length > 0) setShowResults(true);
                        else setShowResults(false);
                      }}
                      label="Canal"
                    >
                      {subTypeOptions.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </li>
              )}
              {showResults && (
                <li style={{ listStyle: 'none', marginTop: 20 }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ width: 330 }}
                  >
                    <InputLabel>Resultado</InputLabel>
                    <Select
                      disabled={showSubTypes && subType === ''}
                      value={result}
                      onChange={e => setResult(e.target.value as string)}
                      label="Resultado"
                    >
                      {resultOptions.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </li>
              )}

              <li style={{ listStyle: 'none', marginTop: 20 }}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: 330 }}
                >
                  <InputLabel>Realizada a</InputLabel>
                  <Select
                    value={appliedOn}
                    onChange={e => setAppliedOn(e.target.value as string)}
                    label="Realizada a"
                  >
                    {appliedOnOptions.map(option => (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
              <li style={{ display: 'flex', marginTop: 20 }}>
                <Text fontWeight="bold">Comentario</Text>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  variant="outlined"
                  multiline
                  minRows={2}
                  className={classes.roundCorners}
                  style={{ width: 330 }}
                  value={justification}
                  onChange={e => setJustification(e.target.value)}
                />
              </li>
              <li className={classes.spacingAll}>
                <Button
                  variant="outlined"
                  style={{ width: 150 }}
                  onClick={closeDrawer}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!click || isLoading}
                  style={{ width: 150 }}
                  onClick={() => 
                      handleOnClick(
                        type,
                        subType,
                        result,
                        appliedOn,
                        justification
                      )}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Confirmar'
                  )}
                </Button>
              </li>
            </ul>
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default ManagementDrawer;
