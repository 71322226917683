import React, {
  useState,
  useCallback,
  useEffect,
  FC,
  ChangeEvent,
} from 'react';
import {
  Paper,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import debounce from 'lodash/debounce';
import { useStyles } from './styles';
import { SelectFilter, FilterField } from '../../interfaces/ARInterfaces';

const DEFAULT_DEBOUNCE = 500;

interface Props {
  placeholder: string;
  disabled?: boolean;
  options: SelectFilter[];
  selectValue: SelectFilter;
  inputValue?: string | number;
  debounceTime?: number;
  handleChangeInput: (value: FilterField) => void;
}

const SearchFilter: FC<Props> = ({
  placeholder,
  disabled = false,
  options,
  selectValue,
  inputValue = '',
  debounceTime,
  handleChangeInput,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(inputValue);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const clearInputComponent = () => setValue('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce(handleChangeInput, debounceTime ?? DEFAULT_DEBOUNCE),
    []
  );

  const changeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputText } = event.target;
    setValue(inputText);
    debouncedHandler({
      select: selectValue,
      input: inputText,
    });
  };

  const handleClearInputField = () => {
    clearInputComponent();

    handleChangeInput({
      select: selectValue,
      input: '',
    });
  };

  const handleChangeField = (field: SelectFilter) => {
    clearInputComponent();
    setAnchorEl(null);

    handleChangeInput({ select: field, input: '' });
  };

  return (
    <Paper elevation={0} className={classes.root}>
      {options.length > 1 ? (
        <Button
          onClick={e => setAnchorEl(e.currentTarget)}
          className={classes.iconButton}
          aria-label="search"
        >
          <Typography variant="subtitle2">{selectValue?.title}</Typography>
          <ExpandMoreIcon />
        </Button>
      ) : (
        <Typography variant="subtitle2" className={classes.textIconAlone}>
          {selectValue?.title}
        </Typography>
      )}

      {options.length > 1 && (
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
        >
          {options &&
            options.map(field => (
              <MenuItem
                key={field.title}
                onClick={() => handleChangeField(field)}
              >
                <Typography variant="caption" color="textSecondary">
                  {field.title}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}

      <InputBase
        type="string"
        disabled={disabled}
        className={classes.input}
        placeholder={placeholder || 'Buscar'}
        onChange={changeInput}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        }
        value={value}
      />

      {inputValue && (
        <IconButton
          onClick={handleClearInputField}
          className={classes.iconButton}
          aria-label="search"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchFilter;
