import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import { Schedule as ScheduleIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import CountryFormatHelper from './CountryFormatHelper';
import { timeSinceCalculated } from '../../helpers/DateUtils';
import { DATASTUDIO_URL, reportingByCountry } from '../../helpers/Constants';
import SignalTooltip from './SignalTooltip';

const InvoiceSignalScore = ({
  score,
  latestScoreUpdate,
  orderId,
  hasInvoiceHistory,
}) => {
  let componentRisk = '';
  const country = useSelector(state => state.config.country);

  const dataStudioURLParams = `{"${reportingByCountry.score[country].field_key}":"${orderId}"}`;
  const dataStudioURL = `${DATASTUDIO_URL}/reporting/${reportingByCountry.score[country].reporting}/page/oONtC?params=${dataStudioURLParams}`;

  const waitingForInfo = (
    <SignalTooltip title="Esperando mayor información para mostrar un resultado.">
      <ScheduleIcon style={{ color: '#8D8A96' }} />
    </SignalTooltip>
  );

  if (!Number.isNaN(score) && latestScoreUpdate) {
    componentRisk = (
      <SignalTooltip
        title={timeSinceCalculated(new Date(latestScoreUpdate))}
        placement="bottom"
      >
        <Link
          href={dataStudioURL}
          variant="body2"
          underline="always"
          target="_blank"
          rel="noreferrer"
        >
          <CountryFormatHelper
            value={score}
            variant="numeric"
            countryId={country}
          />
        </Link>
      </SignalTooltip>
    );
  } else if (!hasInvoiceHistory || !score) {
    componentRisk = waitingForInfo;
  } else {
    componentRisk = waitingForInfo;
  }

  return componentRisk;
};

InvoiceSignalScore.propTypes = {
  score: PropTypes.number.isRequired,
  latestScoreUpdate: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  hasInvoiceHistory: PropTypes.bool.isRequired,
};

export default InvoiceSignalScore;
