import { useQuery } from 'react-query';
import fetchUsers from '../services/fetchUsers';

const useFetchUsers = () => {
  const { isLoading, isRefetching, data } = useQuery(
    ['fetchUsers'],
    () => fetchUsers(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 3600000, // 1h in milliseconds
      retry: false,
    }
  );

  const users = data || [];

  return {
    users,
    isLoading: isLoading || isRefetching,
  };
};

export default useFetchUsers;
