import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Right = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M10 6L8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6Z" />
    </SvgIcon>
  );
};

export default Right;
