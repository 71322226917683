export const getPayrollFeature = ({ fundPayroll }) => fundPayroll;

export const getReceivers = ({ fundPayroll }) => fundPayroll.receivers;

export const getInvoices = ({ fundPayroll }) => fundPayroll.invoices;

export const getSelectedReceiver = ({ fundPayroll }) =>
  fundPayroll.selectedReceiver;

export const getPayrollSelector = ({ fundPayroll }) =>
  fundPayroll.payrollSelector;

export const getSelectedPayroll = ({ fundPayroll }) =>
  fundPayroll.selectedPayroll;
