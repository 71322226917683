import { Grid, styled } from '@material-ui/core';
import { Text } from '../../../commons/formatters';

const RootContainer = styled(Grid)({
  padding: '30px 24px',
});

const IconContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 8,
});

const TextContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  '& :first-child': {
    marginBottom: 16,
  },
});

const BigText = styled(Text)({
  fontSize: 20,
});

const SmallText = styled(Text)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.payments.grey6,
}));

export { RootContainer, TextContainer, IconContainer, BigText, SmallText };
