import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  CommercialClassificationData,
  InputParams,
  getCommercialClassification,
  CommercialClassificationError,
} from '../services/getCommercialClassification';

export const useGetCommercialClassification = ({ businessId, country }: InputParams) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: commercialClassification,
    isLoading: commercialClassificationLoading,
  } = useQuery<CommercialClassificationData, CommercialClassificationError>(
    useGetCommercialClassification.name,
    async () => getCommercialClassification({ businessId, country }),
    {
      enabled: Boolean(businessId),
      onError(error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  return {
    commercialClassification,
    commercialClassificationLoading,
  };
};
