import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrashBin = props => {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5833 5.49992H20.1666V7.33325H18.3333V19.2499C18.3333 19.493 18.2367 19.7262 18.0648 19.8981C17.8929 20.07 17.6597 20.1666 17.4166 20.1666H4.58325C4.34014 20.1666 4.10698 20.07 3.93507 19.8981C3.76316 19.7262 3.66659 19.493 3.66659 19.2499V7.33325H1.83325V5.49992H6.41659V2.74992C6.41659 2.5068 6.51316 2.27365 6.68507 2.10174C6.85698 1.92983 7.09014 1.83325 7.33325 1.83325H14.6666C14.9097 1.83325 15.1429 1.92983 15.3148 2.10174C15.4867 2.27365 15.5833 2.5068 15.5833 2.74992V5.49992ZM16.4999 7.33325H5.49992V18.3333H16.4999V7.33325ZM8.24992 10.0833H10.0833V15.5833H8.24992V10.0833ZM11.9166 10.0833H13.7499V15.5833H11.9166V10.0833ZM8.24992 3.66659V5.49992H13.7499V3.66659H8.24992Z"
        fill="#44414C"
      />
    </SvgIcon>
  );
};

export default TrashBin;
