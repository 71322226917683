import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  DATE_FORMAT,
  BLOCKED_FILTERS,
  VERIFICATION_MENU_FILTER,
  MORA_RANGE_FILTER,
  SEARCHBAR_TIMEOUT,
  KEY_CODES,
} from '../../../../../../helpers/Constants';
import loadingIcon from '../../../../../../assets/loading.gif';

import {
  Delete,
  Add,
  Search as SearchIcon,
  Close as CloseIcon,
  Down as ExpandMoreIcon,
} from '../../../../../../components/icons';
import Container from '../../../../../../components/elements/Container';
import { dateDBFormatter } from '../../../../../../helpers/DateUtils';
import useStyles from './searchBarStyles';

const SearchBarPanel = ({
  handleChangeInput,
  placeholder,
  disabled,
  fields,
  refresh,
  selectedOption,
  panelOptions,
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const cleanFields =
    fields?.length && Array.isArray(fields)
      ? fields.filter(e => !BLOCKED_FILTERS.includes(e.field))
      : [];
  const initFields = cleanFields.length ? cleanFields[0] : {};
  const [field, setField] = useState(initFields);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorValue, setAnchorValue] = useState(false);
  const [value, setValue] = useState(null);
  const [valueInput, setValueInput] = useState('');
  const [timer, setTimer] = useState(null);
  const [openPanel, setOpenPanel] = useState(false);

  const {
    useDataPanel,
    data,
    handleData,
    loadingData,
    handleSelectedData,
    selectedData,
    tableColumns,
  } = panelOptions;

  useEffect(() => {
    setOpenPanel(useDataPanel);
  }, [useDataPanel]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = '';
    setValueInput('');
    setValue(null);
    setField(selectedOption);
    // eslint-disable-next-line
  }, [refresh]);

  const cleanInput = () => {
    setValueInput('');
  };

  const onKeyDown = e => {
    switch (e.keyCode) {
      case KEY_CODES.ESCAPE:
        setOpenPanel(false);
        break;
      case KEY_CODES.DELETE:
        cleanInput();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearInput = () => {
    if (inputRef.current) inputRef.current.value = '';
    setValueInput('');
    setValue(null);
    handleChangeInput({ field: field.field });
    handleData([]);
  };

  const changeInput = e => {
    const {
      target: { value },
    } = e;
    clearTimeout(timer);
    setValue(null);
    setValueInput(value);
    const targetValue = value.trim();

    setTimer(
      setTimeout(() => {
        if (targetValue !== '' || value === '') {
          setOpenPanel(true);
          handleChangeInput({
            value: targetValue,
            field: field.field,
          });
        }
      }, SEARCHBAR_TIMEOUT)
    );
  };

  const handleChangeField = value => {
    setAnchorEl(null);
    setField(value);
    clearInput();
  };

  const handleChangeDate = date => {
    setValue(date);
    setValueInput('');

    if (date.isValid()) {
      handleChangeInput({
        value: dateDBFormatter(date),
        field: field.field,
      });
    }
  };

  const handleChangeMenuItem = item => {
    setAnchorValue(null);
    setValue(item);
    handleChangeInput({
      value: item,
      field: field.field,
    });
  };

  const getMenu = (data, text) => {
    let inputValue = value || text;
    if (value && typeof value === 'object') {
      inputValue = value.label;
    }
    return (
      <Container align="start">
        <IconButton
          onClick={e => setAnchorValue(e.currentTarget)}
          className={classes.iconButton}
          aria-label={field.field}
        >
          <Typography
            color={value ? 'textPrimary' : 'textSecondary'}
            variant="caption"
            className={classes.input}
          >
            {inputValue}
          </Typography>
        </IconButton>
        <Menu
          id={field.field}
          anchorEl={anchorValue}
          open={Boolean(anchorValue)}
          onClose={() => setAnchorValue(null)}
          getContentAnchorEl={null}
          className={classes.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
        >
          {data.map(item => {
            return (
              <MenuItem
                className={classes.menuItem}
                key={typeof item === 'object' ? item.id : item}
                onClick={() => {
                  handleChangeMenuItem(item);
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  {item?.label ?? item}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Container>
    );
  };

  const getInput = () => {
    if (field.type === 'date') {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <KeyboardDatePicker
            name="date"
            format={DATE_FORMAT}
            placeholder={DATE_FORMAT}
            invalidDateMessage={null}
            value={value}
            onChange={date => handleChangeDate(date)}
            autoOk
            fullWidth
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      );
    }

    switch (field.field) {
      case 'paymentConfirmed':
      case 'verificationReasons': {
        return getMenu(VERIFICATION_MENU_FILTER, 'Selecciona una opción');
      }
      case 'latePaymentRange':
        return getMenu(MORA_RANGE_FILTER, 'Selecciona un rango');
      default: {
        let type = 'string';
        if (field.field === 'latePaymentDays') {
          type = 'number';
        }
        return (
          <InputBase
            type={type}
            disabled={disabled}
            variant="outlined"
            value={valueInput}
            className={classes.input}
            placeholder={placeholder || 'Buscar'}
            onChange={changeInput}
            inputProps={{
              'aria-label': 'Buscar',
            }}
          />
        );
      }
    }
  };

  const handleOnSubmitPaper = e => {
    e.preventDefault();
    clearTimeout(timer);
    const targetValue = valueInput.trim();
    if (handleChangeInput)
      handleChangeInput({
        value: targetValue,
        field: field.field,
      });
  };

  const manageData = (id, exists) => {
    return data.map(d => {
      if (d.id === id) {
        return {
          ...d,
          exists,
        };
      }
      return d;
    });
  };

  const buildDataColumns = [
    {
      title: '',
      type: 'boolean',
      width: '30px',
      align: 'center',
      render: rowData => {
        const { id, exists } = rowData;

        const handleDelete = () => {
          handleData(manageData(id, false));
          const newData = selectedData.filter(d => d.id !== id);
          handleSelectedData(newData);
        };

        const handleAdd = () => {
          handleData(manageData(id, true));
          const newData = [...selectedData];
          newData.push(rowData);
          handleSelectedData(newData);
        };

        return exists ? (
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        ) : (
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleAdd}
          >
            <Add />
          </IconButton>
        );
      },
    },
    ...tableColumns,
  ];

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Paper
          component="form"
          onSubmit={handleOnSubmitPaper}
          elevation={0}
          className={[
            classes.root,
            (openPanel && data?.length) || loadingData ? classes.rootData : '',
          ]}
        >
          {cleanFields.length > 1 ? (
            <Button
              onClick={e => setAnchorEl(e.currentTarget)}
              className={classes.iconButton}
              aria-label="search"
            >
              <Typography variant="subtitle2">{field.title}</Typography>
              <ExpandMoreIcon />
            </Button>
          ) : (
            <Typography variant="subtitle2" className={classes.textIconAlone}>
              {field.title}
            </Typography>
          )}

          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          {cleanFields.length > 1 && (
            <Menu
              id="fields-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
            >
              {cleanFields &&
                cleanFields.map(field => (
                  <MenuItem
                    key={field.title}
                    onClick={() => handleChangeField(field)}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {field.title}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          )}

          {getInput()}

          <IconButton
            onClick={clearInput}
            className={classes.iconButton}
            aria-label="search"
          >
            <CloseIcon />
          </IconButton>
        </Paper>
      </Grid>
      {loadingData ? (
        <Grid
          container
          justify="center"
          className={classes.displayInfoContainer}
        >
          <img src={loadingIcon} alt="loader" />
        </Grid>
      ) : openPanel && data?.length ? (
        <Grid container className={classes.displayInfoContainer}>
          <Grid direction="row" alignItems="center" item container>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              gutterBottom
              className={classes.hintContent}
              component="div"
            >
              <Box component="div" className={classes.buttonIcon}>
                ESC
              </Box>
              Para cerrar.
              <Box component="div" className={classes.buttonIcon}>
                DEL
              </Box>
              Para borrar.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <MaterialTable
              columns={buildDataColumns}
              data={data}
              options={{
                emptyRowsWhenPaging: false,
                search: false,
                toolbar: false,
                draggable: false,
                showTitle: false,
                sorting: false,
                paging: false,
                header: false,
              }}
              localization={{
                body: {
                  emptyDataSourceMessage:
                    'Ingresa uno o más folios para iniciar la búsqueda',
                },
              }}
            />
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

SearchBarPanel.defaultProps = {
  disabled: false,
  refresh: false,
  panelOptions: {
    useDataPanel: false,
    data: [],
    handleData: () => {},
    loadingData: false,
    selectedData: [],
    handleSelectedData: () => {},
    tableColumns: [],
  },
};

SearchBarPanel.propTypes = {
  handleChangeInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ).isRequired,
  refresh: PropTypes.bool,
  selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
  panelOptions: PropTypes.shape({
    useDataPanel: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string,
        exists: PropTypes.bool,
      })
    ),
    handleData: PropTypes.func,
    loadingData: PropTypes.bool,
    selectedData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
    handleSelectedData: PropTypes.func,
    tableColumns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        field: PropTypes.string,
        defaultSort: PropTypes.string,
        type: PropTypes.string,
        align: PropTypes.string,
        render: PropTypes.func,
      })
    ),
  }),
};
export default SearchBarPanel;
