import {
  Box,
  Typography,
  Divider as DividerMui,
  styled,
  Drawer as DrawerMui,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  textField: {
    '& fieldset': {
      borderRadius: 8,
    },
  },
  inputPadding: {
    '& .MuiInputBase-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  autoCompleteInputRoot: {
    borderRadius: '8px !important',
  },
});

const Drawer = styled(DrawerMui)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});
const ContainerRoot = styled(Box)({
  width: 573,
  padding: '25px 32px',
});

const TitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const Title = styled(Typography)({
  fontSize: '19.5px',
  fontWeight: 'bold',
});

const Divider = styled(DividerMui)({
  marginBottom: 20,
});

const ContainerData = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 156px)',
});

const ContainerInputs = styled(Box)({
  display: 'flex',
  marginBottom: 25,
});
const CommentInput = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 507,
  padding: 8,
  borderRadius: 8,
  border: '1px solid #CDCAD8',
  marginBottom: 15,
});
const CommentInputActions = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const TitleInputs = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 14,
});

const ContainerScroll = styled(Box)({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 213px)',
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#44414C',
    borderRadius: 4,
  },
});

const SubTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  marginBottom: 8,
});

const ContainerComments = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
const Comments = styled(Box)({
  padding: 16,
  marginBottom: 16,
});
const ContainerCommentsTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  AlignItems: 'center',
  marginBottom: 8,
});
const CommentsTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 14,
  marginLeft: 10,
  marginRight: 10,
});
const CommentsTitleDate = styled(Typography)({
  fontSize: 12,
  color: '#8D8A96',
});
const ContainerCommentsBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 32,
});
const CommentsBodyText = styled(Typography)({
  fontSize: 14,
  marginBottom: 8,
});
const CommentsTagTitle = styled(Typography)({
  fontSize: 12,
  marginBottom: 8,
  color: '#8D8A96',
});
const Tags = styled(Box)({
  width: 140,
  fontSize: 12,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  color: '#95929F',
  background: '#E8E6EF',
  borderRadius: 8,
  alignItems: 'center',
  marginRight: 16,
  marginBottom: 8,
  padding: '5px 10px',
});
const CommentsAttachmentsTitle = styled(Typography)({
  fontSize: 12,
  marginRight: 8,
  color: '#8D8A96',
});
const CommentsAttachmentsBody = styled(Box)({
  marginRight: 4,
});

const TextNotFound = styled(Typography)({
  fontSize: 14,
  textAlign: 'center',
});

const CommentsBodyTextEliminated = styled(Typography)({
  fontSize: 14,
  fontStyle: 'italic',
});

const colors = {
  pressedIcon: '#A7A4B2',
  standardIcon: '#44414C',
};

export {
  useStyles,
  Drawer,
  ContainerRoot,
  TitleContainer,
  Title,
  Divider,
  ContainerData,
  ContainerInputs,
  CommentInput,
  CommentInputActions,
  TitleInputs,
  ContainerScroll,
  SubTitle,
  ContainerComments,
  Comments,
  ContainerCommentsTitle,
  CommentsTitle,
  CommentsTitleDate,
  ContainerCommentsBody,
  CommentsBodyText,
  CommentsTagTitle,
  Tags,
  CommentsAttachmentsTitle,
  CommentsAttachmentsBody,
  TextNotFound,
  CommentsBodyTextEliminated,
  colors,
};
