import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

interface Props {
  orderIds: number[];
  status: string;
  statusReason: string | null;
}

const orderBulkUpdateStateService = async ({
  orderIds,
  status,
  statusReason = null,
}: Props) => {
  try {
    const { data } = await axios.post('/api/orders/bulk-status', {
      orderIds,
      status,
      statusReason,
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default orderBulkUpdateStateService;
