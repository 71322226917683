import { gql } from 'graphql-request';

export const query = gql`
  query ContactOwnerHistory($dto: GetContactOwnerHistoryDTO!) {
    contactOwnerHistory(dto: $dto) {
      id
      refId
      assignedAt
      owner {
        id
        userId
        email
        firstName
        lastName
        createdAt
        updatedAt
        archived
        teams {
          id
          name
          primary
        }
      }
    }
  }
`;

export type ContactOwnerHistory = {
  refId: string;
  id: string;
  assignedAt: string;
  owner: {
    id: string;
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    updatedAt: string;
    archived: string;
  };
};

export type Query = {
  contactOwnerHistory: ContactOwnerHistory[];
};
