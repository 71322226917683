import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import esCL from './CL.json';
import esMX from './MX.json';
import es from './es.json';

const i18n = createInstance({
  fallbackLng: {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    CL: ['es'],
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    MX: ['es'],
  },
  debug: true,

  interpolation: {
    escapeValue: false, // Not needed for react as it escapes by default
  },

  resources: {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    CL: {
      translation: esCL,
    },
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    MX: {
      translation: esMX,
    },
    es: {
      translation: es,
    },
  },
});

/* eslint-disable-next-line @typescript-eslint/no-floating-promises */
i18n.use(initReactI18next).init();

export default i18n;
