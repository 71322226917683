import React, { ReactNode } from 'react';
import { SvgIcon } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const AddIcon = ({ color = palette.primary.main, style }: Props) => {
  return (
    <SvgIcon
      style={{
        color,
        ...style,
      }}
      viewBox="0 0 28 28"
    >
        <path d="M14 7.33268C13.2667 7.33268 12.6667 7.93268 12.6667 8.66602V12.666H8.66667C7.93333 12.666 7.33333 13.266 7.33333 13.9993C7.33333 14.7327 7.93333 15.3327 8.66667 15.3327H12.6667V19.3327C12.6667 20.066 13.2667 20.666 14 20.666C14.7333 20.666 15.3333 20.066 15.3333 19.3327V15.3327H19.3333C20.0667 15.3327 20.6667 14.7327 20.6667 13.9993C20.6667 13.266 20.0667 12.666 19.3333 12.666H15.3333V8.66602C15.3333 7.93268 14.7333 7.33268 14 7.33268ZM14 0.666016C6.64 0.666016 0.666668 6.63935 0.666668 13.9993C0.666668 21.3594 6.64 27.3327 14 27.3327C21.36 27.3327 27.3333 21.3594 27.3333 13.9993C27.3333 6.63935 21.36 0.666016 14 0.666016ZM14 24.666C8.12 24.666 3.33333 19.8793 3.33333 13.9993C3.33333 8.11935 8.12 3.33268 14 3.33268C19.88 3.33268 24.6667 8.11935 24.6667 13.9993C24.6667 19.8793 19.88 24.666 14 24.666Z" fill="#1A49E7"/>
    </SvgIcon>
  );
};

export default AddIcon;