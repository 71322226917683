import axios from 'axios';
import {
  FETCH_CONTACTS_START,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  CREATE_CONTACT_START,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  UPDATE_CONTACT_START,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  DELETE_CONTACT_START,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  FETCH_CONTACTS_CSV_START,
  FETCH_CONTACTS_CSV_SUCCESS,
  FETCH_CONTACTS_CSV_FAILURE,
  FETCH_CONTACTS_CSV_MIGRACIONCL_START,
  FETCH_CONTACTS_CSV_MIGRACIONCL_SUCCESS,
  FETCH_CONTACTS_CSV_MIGRACIONCL_FAILURE,
  LOAD_CONTACTS_CSV_START,
  LOAD_CONTACTS_CSV_SUCCESS,
  LOAD_CONTACTS_CSV_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESET_CONTACTS_CSV_ERROR,
  RESET_PAYER_CONTACTS_CSV_DATA,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys';

const verifyContactEmail = (email, contactId, contactableType) => dispatch => {
  dispatch({
    type: VERIFY_EMAIL_START,
    payload: { contactId, contactableType },
  });
  axios
    .post('/api/verify-email/', {
      email,
    })
    .then(res => {
      if (res.data.error) {
        dispatch({
          type: VERIFY_EMAIL_FAILURE,
          payload: { contactId, contactableType, probability: null },
        });
      }

      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
        payload: {
          contactId,
          contactableType,
          probability: res.data.probability,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: VERIFY_EMAIL_FAILURE,
        payload: { contactId, contactableType, probability: null },
      });
    });
};

export const fetchContacts = (contactableType, contactableId) => dispatch => {
  dispatch({
    type: FETCH_CONTACTS_START,
    payload: { contactableType, contactableId },
  });
  axios
    .get(`/api/${contactableType}/${contactableId}/contactdetail`)
    .then(res => {
      const resData = res.data.contactData || res.data;

      const contacts = resData.map(contact => {
        dispatch(
          verifyContactEmail(contact.email, contact.id, contactableType)
        );
        return {
          ...contact,
          probability: null,
          emailVerifyIsLoading: true,
          emailVerifyError: null,
        };
      });

      dispatch({
        type: FETCH_CONTACTS_SUCCESS,
        payload: { contactableType, contacts },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_CONTACTS_FAILURE,
        payload: { contactableType, errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CONTACTS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const loadContactsCsv =
  (contactableType, contactsFormData) => dispatch => {
    dispatch({ type: LOAD_CONTACTS_CSV_START, payload: { contactableType } });
    axios
      .post(`/api/${contactableType}/contactdetail/csv`, contactsFormData)
      .then(res => {
        const { contacts, invalidContacts } = res.data;
        dispatch({
          type: LOAD_CONTACTS_CSV_SUCCESS,
          payload: { contacts, invalidContacts, contactableType },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONTACT_CSV_UPLOADED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        let errorCode = getErrorCode(err);
        const payload = { contactableType };
        if (err?.response?.data?.invalidContacts) {
          payload.invalidContacts = err?.response?.data?.invalidContacts;
        } else {
          errorCode = t13s.ERROR.INVALID_CONTACT_CVS;
        }
        dispatch({
          type: LOAD_CONTACTS_CSV_FAILURE,
          payload: { ...payload, errorCode },
        });
      });
  };

// isApiGlobal: DELETE AFTER MIGRATION
export const loadContactsCsvMIGRACIONCL =
  (contactableType, contactsData) => dispatch => {
    dispatch({ type: LOAD_CONTACTS_CSV_START, payload: { contactableType } });
    axios
      .post('/api/payer/contactdetail/csv', { contactsData })
      .then(res => {
        const resData = res.data.contacts || res.data;
        dispatch({
          type: LOAD_CONTACTS_CSV_SUCCESS,
          payload: { contacts: resData, contactableType },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONTACT_CSV_UPLOADED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: LOAD_CONTACTS_CSV_FAILURE,
          payload: { errorCode, contactableType },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPLOAD_CONTACT_CSV_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchContactsCsv = contactableType => dispatch => {
  dispatch({ type: FETCH_CONTACTS_CSV_START, payload: { contactableType } });
  axios
    .get(`/api/${contactableType}/contactdetail/csv`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_CONTACTS_CSV_SUCCESS,
        payload: { contactsCsv: resData, contactableType },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_CONTACTS_CSV_FAILURE,
        payload: { errorCode, contactableType },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CONTACTS_CSV_FAILURE, {
          variant: 'error',
        })
      );
    });
};

//  isApiGlobal: Delete this after migration. contact entity has its own actions/reducer
export const fetchContactsCsvMIGRACIONCL = () => dispatch => {
  dispatch({ type: FETCH_CONTACTS_CSV_MIGRACIONCL_START });
  axios
    .get('api/payer/contactdetail/csv')
    .then(res => {
      const resData = res.data;

      dispatch({
        type: FETCH_CONTACTS_CSV_MIGRACIONCL_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_CONTACTS_CSV_MIGRACIONCL_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CONTACTS_CSV_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const createContact =
  (contactableType, contactableId, data) => dispatch => {
    dispatch({ type: CREATE_CONTACT_START, payload: { contactableType } });
    axios
      .post(`/api/${contactableType}/${contactableId}/contactdetail`, data)
      .then(res => {
        const resData = res.data.contactData || res.data;
        dispatch(
          verifyContactEmail(resData.email, resData.id, contactableType)
        );
        const contact = {
          ...resData,
          probability: null,
          emailVerifyIsLoading: true,
          emailVerifyError: null,
        };
        dispatch({
          type: CREATE_CONTACT_SUCCESS,
          payload: { contact, contactableType, contactableId },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONTACT_CREATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_CONTACT_FAILURE,
          payload: { errorCode, contactableType },
        });
      });
  };

export const updateContact =
  (contactableType, contactableId, contactId, contactInfo) => dispatch => {
    dispatch({ type: UPDATE_CONTACT_START, payload: { contactableType } });
    axios
      .put(
        `/api/${contactableType}/${contactableId}/contactdetail/${contactId}`,
        contactInfo
      )
      .then(() => {
        dispatch(
          verifyContactEmail(contactInfo.email, contactId, contactableType)
        );
        const updatedContactInfo = {
          ...contactInfo,
          probability: null,
          emailVerifyIsLoading: true,
          emailVerifyError: null,
        };

        dispatch({
          type: UPDATE_CONTACT_SUCCESS,
          payload: {
            contactableType,
            contactableId,
            contactId,
            contactInfo: updatedContactInfo,
          },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONTACT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_CONTACT_FAILURE,
          payload: { errorCode, contactableType },
        });
      });
  };

export const deleteContact =
  (contactableType, contactableId, contactId) => dispatch => {
    dispatch({ type: DELETE_CONTACT_START, payload: { contactableType } });
    axios
      .delete(
        `/api/${contactableType}/${contactableId}/contactdetail/${contactId}`
      )
      .then(() => {
        dispatch({
          type: DELETE_CONTACT_SUCCESS,
          payload: { contactableType, contactableId, contactId },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONTACT_DELETED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: DELETE_CONTACT_FAILURE,
          payload: { errorCode, contactableType },
        });
      });
  };

export const resetContactsCsvError = contactableType => dispatch => {
  dispatch({
    type: RESET_CONTACTS_CSV_ERROR,
    payload: { contactableType },
  });
};

export const resetPayerContactsCsvData = () => dispatch => {
  dispatch({ type: RESET_PAYER_CONTACTS_CSV_DATA });
};
