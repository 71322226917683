import { useState } from 'react';
import { PayerCategorization } from '../../commons/interfaces/payerCategorization';
import useBulkCreatePayerCategorizations from '../infrastructure/store/useBulkCreatePayerCategorizations';

interface Props {
  payerIdentifier: string;
}

const useRecap = ({
  payerIdentifier,
}: Props) => {
  const [showRecapModal, setShowRecapModal] = useState(false);
  const [showPayerCategorizationDrawer, setShowPayerCategorizationDrawer] =
    useState(false);

  const handleBulkCreatePayerCategorizations = (
    categorizations: PayerCategorization[]
    ) => {
    createPayerCategorizations({payersIdentifiers: [payerIdentifier], categorizations})
  };

  const onCreatePayerCategorizationsSuccess = () => {
    setShowRecapModal(false);
    setShowPayerCategorizationDrawer(false)
  };

  const toggleDrawer = (open: boolean) => {
    setShowPayerCategorizationDrawer(open);
  };

  const toggleModal = (open: boolean) => {
    setShowRecapModal(open);
  };

  const { isLoading: createPayerCategorizationsIsLoading, createPayerCategorizations } =
    useBulkCreatePayerCategorizations(onCreatePayerCategorizationsSuccess);


  return {
    showRecapModal,
    showPayerCategorizationDrawer,
    createPayerCategorizationsIsLoading,
    toggleDrawer,
    toggleModal,
    handleBulkCreatePayerCategorizations,
  };
};

export default useRecap;
