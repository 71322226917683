import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer,
  Typography,
  Box,
  makeStyles,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Collapse,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '../icons';
import { MIFIEL } from '../../helpers/Constants';
import Dropzone from '../elements/Dropzone/Dropzone';
import { t13s } from '../../translationKeys';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import Loader from '../elements/Loader';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import { fetchStakeholders } from '../../actions/stakeholderActions';

const useStyles = makeStyles({
  rootDrawer: {
    background: '#fff',
  },
  rootContainer: {
    width: 570,
    padding: 20,
  },
  titleContainer: {
    marginBottom: 10,
  },
  divider: {
    marginBottom: 25,
  },
  subtitles: {
    marginBottom: 15,
  },
  containerUploadDocument: {
    marginBottom: 25,
  },
  containerTableSignatories: {
    marginBottom: 25,
  },
  spacingActionButton: {
    marginRight: 10,
  },
  containerForm: {
    marginBottom: 30,
  },
  buttonAddSignatory: {
    marginBottom: 30,
    textDecoration: 'underline',
  },
  containerInputsIdentifierAndEmail: {
    display: 'flex',
  },
  containerInputIdentifier: {
    paddingRight: 10,
  },
  containerInputEmail: {
    paddingLeft: 10,
  },
  confirmButton: {
    marginRight: 20,
  },
  alertError: {
    marginBottom: 20,
  },
  cancelButton: {
    textDecoration: 'underline',
  },
});

const initialStateSignatory = {
  name: '',
  identifier: '',
  email: '',
};

const ELECTRONIC_SIGNATURE_DOCUMENT = 'ELECTRONIC_SIGNATURE_DOCUMENT';
const FACTORING_CONFIRMATION_ID = 15;
const FACTORING_NOFITICATION_ID = 16;
const PAGARE_ID = 17;
const DOCUMENT_SIGNING_REQUERIMENTS = [
  {
    documentTypeId: 9,
    title: 'Contrato de factoraje',
    requeriments: ['stakeholder', 'xepelinSign'],
  },
  {
    documentTypeId: 11,
    title: 'Domiciliación',
    requeriments: ['stakeholder'],
  },
  {
    documentTypeId: FACTORING_CONFIRMATION_ID,
    title: 'Confirmación de factoraje',
    requeriments: ['stakeholder', 'xepelinSign'],
  },
  {
    documentTypeId: FACTORING_NOFITICATION_ID,
    title: 'Notificación de factoraje',
    requeriments: ['stakeholder'],
  },
  {
    documentTypeId: PAGARE_ID,
    title: 'Pagaré',
    requeriments: ['stakeholder'],
  },
  {
    documentTypeId: 33,
    title: 'Domiciliación',
    requeriments: ['stakeholder'],
  },
];

const XEPELIN_LEGAL_OWNER = {
  id: process.env.REACT_APP_XEPELIN_SIGNER_IDENTIFIER,
  name: process.env.REACT_APP_XEPELIN_SIGNER_NAME,
  email: process.env.REACT_APP_XEPELIN_SIGNER_EMAIL,
  identifier: process.env.REACT_APP_XEPELIN_SIGNER_IDENTIFIER,
};

const UploadElectronicSignatureDrawer = ({
  isOpen,
  handleCloseDrawer,
  error,
  isLoading,
  handleSubmitUploadDocument,
  electronicSignatureType,
  document,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { documentTypeId } = document;
  const { business } = useSelector(state => state.business);
  const { id, identifier } = business;
  const PFAE_LENGTH = 13;
  const { businessStakeholders, businessStakeholdersIsLoading } = useSelector(
    state => state.stakeholders
  );
  const { country } = useSelector(state => state.config);

  const [file, setFile] = useState(null);
  const [checkButton, setCheckButton] = useState(false);
  const [showFormCollapse, setShowFormCollapse] = useState(false);
  const [showDropzoneCollapse, setshowDropzoneCollapse] = useState(true);
  const [signatories, setSignatories] = useState([]);
  const [signatorySelected, setSignatorySelected] = useState(null);
  const [signatory, setSignatory] = useState(initialStateSignatory);
  const [virtualId, setVirtualId] = useState(1);
  let stakeholdersDocument = [];

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value => {
      return validateIdentifier(value, country);
    });
  }, [country]);

  useEffect(() => {
    if (id) {
      dispatch(fetchStakeholders(id));
    }
  }, [id]);

  const isPFAE = identifier?.length === PFAE_LENGTH;

  useEffect(() => {
    const documentSigningRequeriments = DOCUMENT_SIGNING_REQUERIMENTS.find(
      signingDocument => signingDocument.documentTypeId === documentTypeId
    );

    if (!documentSigningRequeriments) return;

    const { requeriments } = documentSigningRequeriments;
    if (requeriments.includes('stakeholder') || isPFAE) {
      stakeholdersDocument = [...businessStakeholders];
    }
    if (documentSigningRequeriments.documentTypeId === PAGARE_ID && !isPFAE) {
      stakeholdersDocument = [...businessStakeholders, ...businessStakeholders];
    }
    if (requeriments.includes('xepelinSign')) {
      stakeholdersDocument = [XEPELIN_LEGAL_OWNER, ...stakeholdersDocument];
    }
    let key = 0;
    const uniqueSigners = stakeholdersDocument.map(stakeholder => {
      return { key: key++, ...stakeholder };
    });

    setSignatories([...uniqueSigners]);
  }, [businessStakeholdersIsLoading]);

  const handleSubmitData = () => {
    const signatoriesWithoutId = signatories.map(signatoryData => {
      const { name, identifier, email } = signatoryData;
      return {
        name,
        identifier,
        email,
      };
    });
    handleSubmitUploadDocument(file, {
      type: ELECTRONIC_SIGNATURE_DOCUMENT,
      signatories: signatoriesWithoutId,
    });
  };

  const getTitleByElectronicSignature = () => {
    if (electronicSignatureType === MIFIEL) {
      return MIFIEL;
    }
    return null;
  };

  const handleChangeStatusDropzone = (fileData, status) => {
    if (status === 'done') {
      setFile([fileData]);
    } else if (status === 'removed') {
      setFile(null);
      setCheckButton(false);
    }
  };

  const handleAddSignatory = () => {
    const newSignatory = { id: virtualId, ...signatory };
    setSignatories([...signatories, newSignatory]);
    setShowFormCollapse(false);
    setshowDropzoneCollapse(true);
    setSignatory(initialStateSignatory);
    setVirtualId(virtualId + 1);
  };

  const handleUpdateSignatory = () => {
    const signatoriesUpdated = signatories.map(signatoryData => {
      if (signatoryData.key === signatorySelected?.key) {
        return { key: signatorySelected?.key, ...signatory };
      }
      return signatoryData;
    });
    setSignatories(signatoriesUpdated);
    setShowFormCollapse(false);
    setSignatorySelected(null);
    setshowDropzoneCollapse(true);
    setSignatory(initialStateSignatory);
  };

  const handleDeleteSignatory = signatoryId => {
    const newArraySignatories = [...signatories];
    const indexToRemove = newArraySignatories
      .map(signatory => signatory.id)
      .indexOf(signatoryId);
    newArraySignatories.splice(indexToRemove, 1);
    setSignatories(newArraySignatories);
  };

  const handleChangeInput = ({ target }) => {
    setSignatory({ ...signatory, [target.name]: target.value });
  };

  const transformWords = (word, maxLength) => {
    if (word?.length >= maxLength) {
      return `${word.slice(0, maxLength)}...`;
    }
    return word;
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      classes={{
        paper: classes.rootDrawer,
      }}
    >
      <Box className={classes.rootContainer}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h5" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">
                Subir documento {getTitleByElectronicSignature()}
              </Box>
            </Typography>
          </Box>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />

        <Box
          display="flex"
          flexDirection="column"
          className={classes.containerUploadDocument}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            component="div"
            className={classes.subtitles}
          >
            <Box fontWeight="fontWeightBold">Subir</Box>
          </Typography>
          <Collapse in={showDropzoneCollapse} timeout="auto">
            <Dropzone
              maxFiles={1}
              minSize={100}
              isLoading={false}
              onChangeStatus={handleChangeStatusDropzone}
              SubmitButtonComponent={null}
              accept=".pdf"
            />
          </Collapse>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          className={classes.containerTableSignatories}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            component="div"
            className={classes.subtitles}
          >
            <Box fontWeight="fontWeightBold">Lista de firmantes</Box>
          </Typography>

          <Table>
            <TableBody>
              {businessStakeholdersIsLoading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fullWidth
                >
                  <Loader />
                </Box>
              )}
              {signatories?.map(signatory => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={signatory.key}>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="div"
                    >
                      <Box fontWeight="fontWeightBold">
                        {transformWords(signatory.name, 20)}
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{signatory.identifier}</TableCell>
                  <TableCell align="left">
                    {transformWords(signatory.email, 20)}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="space-between">
                      <IconButton
                        disabled={showFormCollapse}
                        onClick={() => {
                          setSignatorySelected(signatory);
                          setSignatory(signatory);
                          setShowFormCollapse(true);
                          if (signatories.length >= 5) {
                            setshowDropzoneCollapse(false);
                          }
                        }}
                        className={classes.spacingActionButton}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        disabled={showFormCollapse}
                        onClick={() => handleDeleteSignatory(signatory.key)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.buttonAddSignatory}
          onClick={() => {
            if (signatories.length >= 5) {
              setshowDropzoneCollapse(false);
            }
            setShowFormCollapse(true);
          }}
          disabled={showFormCollapse || isLoading}
        >
          Agregar firmante
        </Button>

        <Collapse in={showFormCollapse} timeout="auto" unmountOnExit>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.containerForm}
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              component="div"
              className={classes.subtitles}
            >
              <Box fontWeight="fontWeightBold">
                {signatorySelected ? 'Editar' : 'Agregar'} firmante
              </Box>
            </Typography>

            <ValidatorForm
              onSubmit={
                signatorySelected ? handleUpdateSignatory : handleAddSignatory
              }
            >
              <TextValidator
                fullWidth
                variant="outlined"
                label="Nombre completo"
                name="name"
                type="string"
                value={signatory.name}
                onChange={e => handleChangeInput(e)}
                validators={['required']}
                errorMessages={['Campo requerido']}
              />

              <Grid
                item
                xs={12}
                className={classes.containerInputsIdentifierAndEmail}
              >
                <Grid item xs={6}>
                  <Box className={classes.containerInputIdentifier}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
                      name="identifier"
                      type="string"
                      value={signatory.identifier}
                      onChange={e => handleChangeInput(e)}
                      validators={['required', 'isValidIdentifier']}
                      errorMessages={[
                        'Campo requerido',
                        t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
                      ]}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.containerInputEmail}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      label="Email"
                      name="email"
                      type="string"
                      value={signatory.email}
                      onChange={e => handleChangeInput(e)}
                      validators={['required', 'isEmail']}
                      errorMessages={['Campo requerido', 'Email inválido']}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.confirmButton}
                >
                  Confirmar
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    setShowFormCollapse(false);
                    setSignatory(initialStateSignatory);
                    setshowDropzoneCollapse(true);
                  }}
                  className={classes.cancelButton}
                >
                  Cancelar
                </Button>
              </Box>
            </ValidatorForm>
          </Box>
        </Collapse>

        {error && (
          <AlertForm
            variant="error"
            message={error}
            className={classes.alertError}
          />
        )}

        {file && signatories.length >= 1 && (
          <Box display="flex" justifyContent="center" mt={4}>
            <CheckButton
              check={checkButton}
              handleCheck={() => setCheckButton(!checkButton)}
              labelButton="Enviar"
              loading={isLoading}
              disabled={isLoading}
              buttonType="button"
              onClickAction={handleSubmitData}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

UploadElectronicSignatureDrawer.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  handleSubmitUploadDocument: PropTypes.func.isRequired,
  electronicSignatureType: PropTypes.string.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
    documentId: PropTypes.string,
    documentTypeId: PropTypes.string,
  }).isRequired,
};

export default UploadElectronicSignatureDrawer;
