import axios from 'axios';
import {
  FETCH_BUSINESS_RATES_START,
  FETCH_BUSINESS_RATES_SUCCESS,
  FETCH_BUSINESS_RATES_FAILURE,
  FETCH_AGREEMENT_RATES_START,
  FETCH_AGREEMENT_RATES_SUCCESS,
  FETCH_AGREEMENT_RATES_FAILURE,
  FETCH_ORDER_INVOICE_BUSINESS_RATES_START,
  FETCH_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  ADD_ORDER_INVOICE_BUSINESS_RATES_START,
  ADD_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  ADD_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  UPDATE_ORDER_INVOICE_BUSINESS_RATES_START,
  UPDATE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  UPDATE_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  DELETE_ORDER_INVOICE_BUSINESS_RATES_START,
  DELETE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  DELETE_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  RESET_RATES_ACTIONS,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import {
  fetchSelectedOrder,
  fetchOperationSummary,
  fetchFacturas,
} from './orderActions';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchBusinessRates = businessId => dispatch => {
  dispatch({ type: FETCH_BUSINESS_RATES_START });
  axios
    .get(`/api/business/${businessId}/rate`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_BUSINESS_RATES_SUCCESS,
        payload: { businessRates: resData },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_BUSINESS_RATES_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_BUSINESS_RATES_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateBusinessRates = rates => dispatch => {
  dispatch({
    type: FETCH_BUSINESS_RATES_SUCCESS,
    payload: { businessRates: rates },
  });
};

export const fetchAgreementRates = agreementId => dispatch => {
  dispatch({ type: FETCH_AGREEMENT_RATES_START });
  axios
    .get(`/api/agreement/${agreementId}/rate`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_AGREEMENT_RATES_SUCCESS,
        payload: { agreementRates: resData },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_AGREEMENT_RATES_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_AGREEMENT_RATES_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateAgreementRates = rates => dispatch => {
  dispatch({
    type: FETCH_AGREEMENT_RATES_SUCCESS,
    payload: { agreementRates: rates },
  });
};

export const fetchOrderInvoiceBusinessRates = orderInvoiceId => dispatch => {
  dispatch({ type: FETCH_ORDER_INVOICE_BUSINESS_RATES_START });
  axios
    .get(`/api/orderinvoicebusiness/${orderInvoiceId}/rate`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
        payload: { orderInvoiceBusinessRates: resData },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
          {
            variant: 'error',
          }
        )
      );
      dispatch({
        type: FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
        payload: { errorCode },
      });
    });
};

export const addOrderInvoiceBusinessRate =
  (orderInvoiceId, orderId, orderInvoiceBusinessRate) => dispatch => {
    dispatch({ type: ADD_ORDER_INVOICE_BUSINESS_RATES_START });
    axios
      .post(
        `/api/orderinvoicebusiness/${orderInvoiceId}/rate`,
        orderInvoiceBusinessRate
      )
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.ORDER_INVOICE_BUSINESS_RATES_CREATED,
            {
              variant: 'success',
            }
          )
        );
        dispatch({
          type: ADD_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
          payload: { orderInvoiceBusinessRate: resData },
        });
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: ADD_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const deleteOrderInvoiceBusinessRate =
  (id, orderInvoiceId, orderId) => dispatch => {
    dispatch({ type: DELETE_ORDER_INVOICE_BUSINESS_RATES_START });
    axios
      .delete(`/api/orderinvoicebusiness/${orderInvoiceId}/rate/${id}`)
      .then(() => {
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.ORDER_INVOICE_BUSINESS_RATES_DELETED,
            {
              variant: 'success',
            }
          )
        );
        dispatch({
          type: DELETE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
          payload: { id },
        });
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: DELETE_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updateOrderInvoiceBusinessRate =
  (orderInvoiceId, orderId, orderInvoiceBusinessRate) => dispatch => {
    const { id, days, rate } = orderInvoiceBusinessRate;
    dispatch({ type: UPDATE_ORDER_INVOICE_BUSINESS_RATES_START });
    axios
      .put(`/api/orderinvoicebusiness/${orderInvoiceId}/rate/${id}`, {
        days,
        rate,
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: UPDATE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
          payload: { orderInvoiceBusinessRate: resData },
        });
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.ORDER_INVOICE_BUSINESS_RATES_UPDATED,
            {
              variant: 'success',
            }
          )
        );
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const resetRatesActions = () => dispatch => {
  dispatch({
    type: RESET_RATES_ACTIONS,
  });
};
