/* eslint-disable react/jsx-props-no-spreading */
import { Chip, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Visibility, VpnKey } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { lightenColor } from '../../helpers/Colors';
import { DAYS_MONTH, NO_INFO, STATUS_REJECTED } from '../../helpers/Constants';
import { dateFormatter } from '../../helpers/DateUtils';
import capitalizeFirstLetter from '../../helpers/FontUtils';
import CountryFormatHelper from './CountryFormatHelper';

const useStyles = makeStyles({
  chipRejected: {
    fontWeight: 'bold',
    color: '#D92F24',
    background: '#F8C7C4',
  },
  chipGreen: {
    fontWeight: 'bold',
    color: '#75B801',
    background: '#E6F8C5',
  },
  chipYellow: {
    fontWeight: 'bold',
    color: '#E29D02',
    background: '#FFEFC9',
  },
  chipOrange: {
    fontWeight: 'bold',
    color: '#E25F02',
    background: '#ffc799',
  },
});
export const MontoRetentionChip = ({ smallSize, data }) => {
  const classes = useStyles();
  const { amountRetention = null, percentageRetention = 0, status = '' } = data;
  if (!amountRetention) return NO_INFO;
  const componentNumber = (
    <CountryFormatHelper
      value={amountRetention}
      variant="currency"
      countryId="CL"
    />
  );
  let classColor;
  if (percentageRetention === 100) {
    classColor = classes.chipGreen;
  } else if (percentageRetention >= 75) {
    classColor = classes.chipYellow;
  } else if (percentageRetention >= 50) {
    classColor = classes.chipOrange;
  } else {
    classColor = classes.chipRejected;
  }
  const title = (
    <Typography variant="body1">
      <CountryFormatHelper
        value={percentageRetention}
        countryId="CL"
        variant="percentage"
      />{' '}
      disponible de retención
    </Typography>
  );
  const mntRetentionChip =
    status !== STATUS_REJECTED ? (
      <Tooltip component="span" title={title}>
        <Chip
          className={classColor}
          size={smallSize ? 'small' : 'medium'}
          label={componentNumber}
        />
      </Tooltip>
    ) : (
      <Chip className={classColor} label={componentNumber} />
    );
  return mntRetentionChip;
};

export const EndDateChip = ({ smallSize, data }) => {
  const classes = useStyles();
  const { fechaVencimiento, daysDebt } = data;
  const { expirationDate = fechaVencimiento } = data;
  let classColor;

  if (daysDebt <= -8) {
    classColor = classes.chipGreen;
  } else if (daysDebt >= -8 && daysDebt < 0) {
    classColor = classes.chipYellow;
  } else if (daysDebt > 0 && daysDebt <= 30) {
    classColor = classes.chipOrange;
  } else {
    classColor = classes.chipRejected;
  }

  const getComponentTitleAndEndDate = () => {
    const endDate = expirationDate ? dateFormatter(expirationDate) : null;
    const title =
      daysDebt || daysDebt === 0 ? (
        <Typography component="span">{`${daysDebt} días de mora`}</Typography>
      ) : (
        NO_INFO
      );
    return { endDate, title };
  };

  const { title, endDate } = getComponentTitleAndEndDate();
  const componentDate = endDate ? (
    <Chip
      className={classColor}
      size={smallSize ? 'small' : 'medium'}
      label={endDate}
    />
  ) : (
    NO_INFO
  );
  return (
    <Tooltip title={title}>
      <span>{componentDate}</span>
    </Tooltip>
  );
};

export const IssueDateChip = ({ data }) => {
  const classes = useStyles();
  const getComponentTitleAndIssueDate = data => {
    const { fechaEmision, daysIssued } = data;
    let { issueDate = fechaEmision } = data;
    issueDate = issueDate ? dateFormatter(issueDate) : null;
    let labelType = daysIssued === 1 ? 'día' : 'días';
    let labelIssued = daysIssued;
    if (daysIssued >= DAYS_MONTH) {
      labelIssued = Math.round(daysIssued / DAYS_MONTH);
      labelType = labelIssued === 1 ? 'mes' : 'meses';
    }
    const title =
      daysIssued || daysIssued === 0 ? (
        <Typography component="span">{`hace ${labelIssued} ${labelType}`}</Typography>
      ) : (
        NO_INFO
      );
    return { issueDate, title };
  };

  const { title, issueDate } = getComponentTitleAndIssueDate(data);
  const componentDate = issueDate ? (
    <Chip className={classes.chipGreen} label={issueDate} />
  ) : (
    NO_INFO
  );
  return (
    <Tooltip title={title}>
      <span>{componentDate}</span>
    </Tooltip>
  );
};

export const RetentiontDaysChip = ({ data }) => {
  const classes = useStyles();
  const { availableDays } = data;
  let classColor;
  const label = availableDays ? `${availableDays} días` : NO_INFO;
  if (!availableDays) return NO_INFO;
  if (data) {
    if (availableDays >= 8) {
      classColor = classes.chipGreen;
    } else if (availableDays <= 8 && availableDays > 0) {
      classColor = classes.chipYellow;
    } else if (availableDays < 0 && availableDays >= -30) {
      classColor = classes.chipOrange;
    } else {
      classColor = classes.chipRejected;
    }
    return <Chip className={classColor} label={label} />;
  }
  return availableDays;
};

const CustomChip = withStyles({
  root: {
    fontWeight: 'bold',
    fontFamily: 'Muli',
    fontSize: '12px',
    height: '25px',
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
})(Chip);

export const RoleChip = props => {
  const classes = useStyles();
  const { role, supervisor, color } = props;
  let className = [classes.chipMargin];
  const labelPrefix = supervisor ? 'Superv. ' : '';

  const icon = supervisor ? (
    <Visibility fontSize="small" />
  ) : role === 'admin' ? (
    <VpnKey fontSize="small" />
  ) : null;
  const name = `${labelPrefix}${role}`;
  const label = capitalizeFirstLetter(name);
  const rgbColor = color ? lightenColor(color, 70) : null;
  className = [...className];
  const style = { background: rgbColor, color };
  const propsChip = { ...props, icon, label, className, style };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CustomChip {...propsChip} />;
};

RoleChip.defaultProps = {
  supervisor: null,
};

RoleChip.propTypes = {
  role: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  supervisor: PropTypes.bool,
};

MontoRetentionChip.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  smallSize: PropTypes.bool,
};

MontoRetentionChip.defaultProps = {
  smallSize: false,
};

EndDateChip.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  smallSize: PropTypes.bool,
};

EndDateChip.defaultProps = {
  smallSize: false,
};

IssueDateChip.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};

RetentiontDaysChip.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
};
