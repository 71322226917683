import { addDays, dateDBFormatter } from '../../../../commons/utils';

export const payrollMap = {
  toDomain: (payroll = {}) => ({
    activePercentage: payroll.activePercentage,
    amount: payroll.amount,
    avgRate: payroll.avgRate,
    createdAt: payroll.createdAt,
    fund: payroll.fund,
    id: payroll.id,
    interest: payroll.interest,
    interestFormula: payroll.interestFormula,
    invoicesNumber: payroll.invoicesNumber,
    notActiveAssignedPercentage: payroll.notActiveAssignedPercentage,
    notAssignedPercentage: payroll.notAssignedPercentage,
    payrollInvoiceAmount: payroll.payrollInvoiceAmount,
    payrollStatus: payroll.payrollStatus,
    receiversNumber: payroll.receiversNumber,
    type: payroll.type,
    debtServiceAmount: payroll.debtServiceAmount,
    closedAt: payroll.closedAt,
  }),
  toPersistence: (payroll = {}) => ({
    createdAt: payroll.createdAt,
  }),
};

export const payrollFilterMap = {
  toPersistence: (filter = {}) => ({
    createdAtMax: dateDBFormatter(addDays(1, filter.creationDate)),
    createdAtMin: filter.creationDate,
    fund: filter.fundName,
    amountMax: filter.maxAmount,
    amountMin: filter.minAmount,
    orderBy: filter.orderBy.createdAt,
    type: filter.type,
    payrollStatus: filter.status,
  }),
};

export const cessionSummaryMap = {
  toDomain: (summary = {}) => ({
    name: summary.fundName,
    count: summary.documentsNumber,
    rate: summary.weightedAvgRate,
    amount: summary.payrollInvoiceAmount,
    interest: summary.interest,
  }),
};
