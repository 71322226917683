import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Grid,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
  Box,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ReactComponent as IconCl } from '../../assets/FlagCl.svg';
import { ReactComponent as IconMx } from '../../assets/FlagMx.svg';
import capitalizeFirstLetter from '../../helpers/FontUtils';
import { ACTIVE_COUNTRIES_LIST } from '../../helpers/Constants';

const useStyles = makeStyles({
  selectCountry: {
    '& .MuiSelect-select:focus': {
      borderRadius: 'unset',
      background: 'transparent',
    },
    '& .MuiSelect-icon': {
      color: 'unset',
    },
    marginLeft: 'auto',
    marginRight: 20,
    height: 40,
    width: 200,
    color: '#fff',
    borderRadius: 17,
    boxShadow: '0px 5px 8px 1px rgba(0,0,0,0.14)',
    background: 'rgba(255, 255, 255, 0.27)',
  },
  logo: {
    width: 30,
    transform: 'scale(0.9)',
  },
  logoMenuItem: {
    width: 25,
    height: 24,
    marginRight: 13,
  },
  iconCountry: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItemIcon: {
    minWidth: 40,
  },
});

const SelectCountry = ({ country, handleChangeCountry }) => {
  const classes = useStyles();

  const getIconCountry = (countryId, menutItem = false) => {
    const className = menutItem ? classes.logoMenuItem : classes.logo;
    const icons = {
      CL: <SvgIcon component={IconCl} className={className} />,
      MX: <SvgIcon component={IconMx} className={className} />,
    };
    return icons[countryId];
  };
  const renderValue = value => {
    const icon = getIconCountry(value);
    const { name, currency } = ACTIVE_COUNTRIES_LIST.find(
      ({ key }) => key === value
    );
    const label = `${name} - ${currency}`;
    return (
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item xs={3} className={classes.iconCountry}>
          {icon}
        </Grid>
        <Grid item xs={9}>
          <Typography
            className={classes.countrySelected}
            variant="subtitle1"
            component="div"
          >
            <Box fontWeight="fontWeightBold">
              {capitalizeFirstLetter(label)}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Select
      id="country"
      label="País"
      value={country}
      name="country"
      disableUnderline
      onChange={handleChangeCountry}
      className={classes.selectCountry}
      renderValue={renderValue}
    >
      {ACTIVE_COUNTRIES_LIST.map(({ key, name, currency }) => (
        <MenuItem key={key} value={key}>
          <ListItemIcon className={classes.listItemIcon}>
            {getIconCountry(key, true)}
          </ListItemIcon>
          <ListItemText>{`${name} - ${currency}`}</ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};

SelectCountry.propTypes = {
  country: PropTypes.string.isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
};

export default SelectCountry;
