import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../../helpers/Constants';

export const LEFT_TABS = [
  {
    label: 'Oportunidades',
    value: 'opportunities',
    countries: [COUNTRY_CODE_CL],
  },
  {
    label: 'Nóminas',
    value: 'in-process',
    countries: [COUNTRY_CODE_CL],
  },
];

export const RIGHT_TABS = [
  {
    label: 'Configuraciones',
    value: 'configurations',
    countries: [COUNTRY_CODE_CL, COUNTRY_CODE_MX],
  },
];

export const MX_TABS = [...LEFT_TABS, ...RIGHT_TABS].filter(tab =>
  tab.countries.includes(COUNTRY_CODE_MX)
);
