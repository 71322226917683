import React, { memo } from 'react';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useFormSummary } from '../../../../../commons/hooks/useFormSummary';
import { FormSummary } from '../../../../../commons/components/FormSummary';
import { useStyles } from './styles';
import { payrollTypeOpts, payrollStatusOpts } from '../constants';
import { MAX_RANGE } from '../../../../../commons/constants/main';
import { BarFilter } from '../../../../../commons/components/BarFilter';
import {
  PAYROLL_STATUS,
  PAYROLL_TYPE,
} from '../../../../../commons/constants/payroll';
import { Select } from '../../../../../../components/elements/Select';
import { DATE_FORMAT } from '../../../../../../helpers/Constants';
import { dateDBFormatter } from '../../../../../commons/utils';

const PayrollFormComponent = ({ onClose, onSubmit, form }) => {
  const classes = useStyles();
  const { formState, handleSetFormState, handleSubmit, summary, resetForm } =
    useFormSummary({ onSubmit, form });
  const mappedSummary = {
    ...summary,
    type: PAYROLL_TYPE[summary.type]?.label,
    status: PAYROLL_STATUS[summary.status]?.label,
  };

  return (
    <>
      <BarFilter handleCloseDrawer={onClose} />
      <Grid container className={classes.container}>
        <FormSummary summary={mappedSummary} resetForm={resetForm} />
        <Grid item xs={12}>
          <Box
            alignItems="flex-start"
            className={classes.w100}
            display="flex"
            flexDirection="column"
          >
            <ValidatorForm className={classes.w100} onSubmit={handleSubmit}>
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Fondo</Box>
              </Typography>
              <TextValidator
                className={classes.w100}
                label="Nombre"
                name="fundName"
                onChange={handleSetFormState}
                type="text"
                value={formState.fundName}
                variant="outlined"
              />
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Nómina</Box>
              </Typography>
              <Select
                label="Tipo"
                value={formState.type}
                name="type"
                onChange={handleSetFormState}
                options={payrollTypeOpts}
              />
              <Select
                label="Estado"
                value={formState.status}
                name="status"
                onChange={handleSetFormState}
                options={payrollStatusOpts}
              />
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Monto total</Box>
              </Typography>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="$ Mínimo"
                    name="minAmount"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.minAmount}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `maxNumber:${formState.maxAmount || MAX_RANGE}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser mayor al Max.',
                    ]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.hyphen} />
                </Grid>
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="$ Máximo"
                    name="maxAmount"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.maxAmount}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `minNumber:${formState.minAmount || 0}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser menor al Min.',
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Fecha creación</Box>
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  fullWidth
                  name="creationDate"
                  format={DATE_FORMAT}
                  value={formState.creationDate || null}
                  onChange={date => {
                    handleSetFormState({
                      target: {
                        name: 'creationDate',
                        // eslint-disable-next-line no-underscore-dangle
                        value: dateDBFormatter(date._d),
                      },
                    });
                  }}
                  autoOk
                />
              </MuiPickersUtilsProvider>
              <Grid item xs={12} className={classes.btnBox}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  Aplicar filtros
                </Button>
              </Grid>
            </ValidatorForm>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

PayrollFormComponent.propTypes = {
  form: PropTypes.objectOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const PayrollForm = memo(PayrollFormComponent);
