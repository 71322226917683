const getOrderBy = ({ field, direction } = {}) => {
  if (!direction) {
    return undefined;
  }

  const by = {
    acknowledgementPercentage: 'acknowledgementPercentage',
    amount: 'amount',
    documentsNumber: 'invoicesNumber',
    issuersNumber: 'issuersNumber',
    name: 'name',
    receiverIdentifier: 'receiverIdentifier',
    score: 'score',
    term: 'avgTerm',
  };

  return `${by[field]}:${direction}`;
};

export const receiverFilterMap = {
  toDomain: res => ({
    records: res.data.map(receiver => ({
      acknowledgementPercentage: receiver.acknowledgementPercentage,
      amount: receiver.amount,
      debtServiceAmount: receiver.debtServiceAmount,
      documentsNumber: receiver.invoicesNumber,
      documentsIds: receiver.invoiceIds.map(id => ({ id })),
      issuersNumber: receiver.issuersNumber,
      name: receiver.name,
      receiverIdentifier: receiver.receiverIdentifier,
      score: receiver.score,
      term: receiver.avgTerm,
      totalOpportunityDocuments: receiver.totalOpportunityDocuments,
    })),
    pagination: {
      page: res.pagination.page,
      pageSize: res.pagination.pageSize,
      totalRecords: res.pagination.totalData,
      totalPages: res.pagination.totalPages,
    },
  }),
  toPersistence: (filter = {}) => ({
    amountMax: filter.maxAmount,
    amountMin: filter.minAmount,
    assigneeIdentifier: filter?.assignee?.identifier,
    assigneeName: filter?.assignee?.name,
    discounts: filter.discount,
    folio: filter.folio,
    identifier: filter.payerIdentifier,
    issuerIdentifier: filter.issuerId,
    issuerName: filter.issuerName,
    limit: filter.limit,
    name: filter.name,
    operationType: filter.operationType,
    orderBy: getOrderBy(filter.orderBy),
    page: filter.page,
    rateMax: filter.maxOriginationRate,
    rateMin: filter.minOriginationRate,
    status: filter.status,
    orderIds: filter?.orderIds?.length
      ? encodeURIComponent(
          JSON.stringify(
            filter.orderIds.reduce(
              (acc, orderId) =>
                orderId ? (acc ? [...acc, +orderId] : [+orderId]) : acc,
              null
            )
          )
        )
      : null,
  }),
};

export const receiverMap = {
  toDomain: res => ({
    acknowledgementPercentage: res.acknowledgementPercentage,
    activeAmount: res.activeAmount,
    amount: res.amount,
    directFinancing: res.directFinancing,
    documentsIds: res.invoiceIds.map(id => ({ id })),
    documentsNumber: res.invoicesNumber,
    earlyPayment: res.earlyPayment,
    issuersNumber: res.issuersNumber,
    maxTerm: res.maxTerm,
    minTerm: res.minTerm,
    name: res.name,
    noActiveAssignee: res.noActiveAssignee,
    noActiveNoAssignee: res.noActiveNoAssignee,
    paymentsWithCession: res.paymentsWithCession,
    paymentsWithoutCession: res.paymentsWithoutCession,
    receiverIdentifier: res.receiverIdentifier,
    score: res.score,
    term: res.avgTerm,
    totalOpportunityDocuments: res.totalOpportunityDocuments,
  }),
};
