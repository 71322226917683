import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootButton: {
    color: '#fff',
  },
  disabledButton: {
    color: '#fff',
    opacity: '40%',
  },
  badge: {
    '& .MuiBadge-badge': {
      top: 16,
      backgroundColor: '#D92F24',
    },
  },
}));

const BadgeWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
};

const ActionBarIcon = ({
  tooltipMsg,
  onClick,
  disabled,
  IconComponent,
  counter = 0,
  ...props
}) => {
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip title={tooltipMsg} {...props}>
      <span>
        <BadgeWrapper
          condition={counter}
          wrapper={children => (
            <Badge
              badgeContent={counter}
              color="primary"
              className={classes.badge}
            >
              {children}
            </Badge>
          )}
        >
          <>
            <IconButton
              size="medium"
              onClick={onClick}
              disabled={disabled}
              classes={{
                root: classes.rootButton,
                disabled: classes.disabledButton,
              }}
            >
              <IconComponent color="inherit" />
            </IconButton>
          </>
        </BadgeWrapper>
      </span>
    </Tooltip>
  );
};

ActionBarIcon.defaultProps = {
  tooltipMsg: '',
  disabled: false,
  counter: null,
};

ActionBarIcon.propTypes = {
  tooltipMsg: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.element.isRequired,
  counter: PropTypes.number,
};

export default ActionBarIcon;
