import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchBusinesses, resetBusinesses } from '../actions/businessAction';
import BusinessesTable from './tables/BusinessesTable';
import {
  INIT_PAGE,
  PAGE_SIZE_INIT,
  BUSINESS_SEGMENT,
} from '../helpers/Constants';
import SearchBar from './elements/SearchBar';
import Panel from './elements/Panel';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { t13s } from '../translationKeys';
import { getMultiplesHash } from '../helpers/tools';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: convertSpacingToCss('lg'),
  },
  containerTab: {
    borderBottom: `1px solid ${theme.palette.payments.grey4}`,
  },
  headerTabsTab: {
    minWidth: 130,
  },
  containerTitle: {
    marginBottom: 20,
  },
}));

const ALL = 'ALL';

const arrayTabs = [
  {
    label: 'Todos',
    value: ALL,
  },
  ...BUSINESS_SEGMENT,
];

const HomeBusinesses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState(null);
  const [field, setField] = useState(null);
  const [page, setPage] = useState(INIT_PAGE);
  const [limit, setLimit] = useState(PAGE_SIZE_INIT);
  const hash = location.hash.replace('#', '');
  const cleanHash = getMultiplesHash(hash);
  const existTab = BUSINESS_SEGMENT.some(
    ({ value }) => value === cleanHash?.businessSegment
  );
  const [stateTab, setStateTab] = useState(
    existTab ? cleanHash?.businessSegment : ALL
  );

  const { country } = useSelector(state => state.config);
  const { loadingBusinesses, businesses, pagination } = useSelector(
    state => state.business
  );

  useEffect(() => {
    dispatch(resetBusinesses());
  }, [country, dispatch]);

  const searcher = () => {
    const segment = stateTab === ALL ? null : stateTab;
    dispatch(
      fetchBusinesses({
        page,
        searchInput,
        field,
        limit,
        segment,
      })
    );
  };

  useEffect(() => {
    searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, page, limit, stateTab]);

  const handleChangePage = (pageValue, limitValue) => {
    setPage(pageValue);
    setLimit(limitValue);
  };

  const handleChangeSearchInput = data => {
    setField(data.field);
    setSearchInput(data.value);
    setPage(INIT_PAGE);
  };

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = valueTab => {
    history.push(`${location.pathname}#businessSegment=${valueTab}`);
    setStateTab(valueTab);
  };

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Grid item xs={12} className={classes.containerTitle}>
        <Typography variant="h5" component="div">
          <Box fontWeight="fontWeightBold">Lista de empresas</Box>
        </Typography>
      </Grid>
      <Panel
        titlePadding="xl xl md"
        contentPadding="zero zero sm"
        preTitle={
          <>
            <Tabs
              value={stateTab}
              onChange={(_e_, valueTab) => handleTabChange(valueTab)}
              className={classes.containerTab}
              TabIndicatorProps={{
                style: {
                  height: '2px',
                  borderRadius: '10px',
                },
              }}
            >
              {arrayTabs.map(({ label, value }) => (
                <Tab
                  label={label}
                  value={value}
                  key={label}
                  className={classes.headerTabsTab}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...a11yProps(value)}
                />
              ))}
            </Tabs>
          </>
        }
        actions={
          <SearchBar
            placeholder="Buscar empresa..."
            handleChangeInput={value => handleChangeSearchInput(value)}
            selectedOption={{ title: 'Razon Social', field: 'name' }}
            fields={[
              { title: 'ID', field: 'id' },
              { title: 'Razon Social', field: 'name' },
              {
                title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
                field: 'identifier',
              },
            ]}
          />
        }
      >
        <BusinessesTable
          tableData={businesses}
          loadingBusinesses={loadingBusinesses}
          handleChangePage={handleChangePage}
          pagination={pagination}
        />
      </Panel>
    </Grid>
  );
};

export default HomeBusinesses;
