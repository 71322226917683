import React, { memo, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TABLE_OPTIONS } from '../../../../../commons/constants/ui';
import {
  useGetSelectedFund,
  useGetSimulation,
} from '../../../../domains/payroll/state';
import { GET_COLUMNS } from './constants';
import { useGetCountry } from '../../../../../adapters/store';
import { useSelectedDraftReceiver } from '../../../../domains/receiver/state';
import { useFetchDraftDocuments } from '../../../../domains/document/services';
import { FEATURES } from '../../../../../commons/constants/features';
import { useDraftPayrollSelectorController } from '../../../../domains/payroll/controllers/useDraftPayrollSelectorController';

const DocumentsListComponent = ({
  changeToReceiverView,
  openDocumentDrawer,
  setOpenEditDialog,
  setSelectedDocumentToEdit,
}) => {
  const simulation = useGetSimulation();
  const { t } = useTranslation();
  const country = useGetCountry();
  const selectedFund = useGetSelectedFund();
  const { addDocument } = useDraftPayrollSelectorController();
  const columns = GET_COLUMNS({
    country,
    openDocumentDrawer,
    selectedFund,
    setOpenEditDialog,
    setSelectedDocumentToEdit,
    showHasPdf: !!selectedFund?.entityFeatures?.find(
      feature => feature.name === FEATURES.INVOICE_HAS_PDF
    ),
    t,
  });
  const { receiverIdentifier } = useSelectedDraftReceiver();
  const { documents, isFetching } = useFetchDraftDocuments(
    {
      receiverIdentifier,
    },
    {
      refetchOnMount: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (!documents?.length && !isFetching) {
      changeToReceiverView();
    }
  }, [documents]);

  return (
    <MaterialTable
      columns={columns}
      data={documents}
      isLoading={isFetching || simulation?.isLoading}
      style={{
        boxShadow: 'none',
        overflow: 'hidden',
        width: '100%',
      }}
      onSelectionChange={selectedRows => addDocument(selectedRows, documents)}
      components={{
        Toolbar: () => null,
      }}
      options={{
        ...TABLE_OPTIONS,
        initialPage: 0,
        numberOfPagesAround: 2,
        paginationType: 'stepped',
        paging: true,
        pageSize: 999999,
        pageSizeOptions: [999999],
        selectionProps: sp => {
          return {
            indeterminate: sp.tableData.indeterminate,
          };
        },
      }}
    />
  );
};

DocumentsListComponent.propTypes = {
  changeToReceiverView: PropTypes.func.isRequired,
  openDocumentDrawer: PropTypes.func.isRequired,
  setOpenEditDialog: PropTypes.func.isRequired,
  setSelectedDocumentToEdit: PropTypes.func.isRequired,
};

export const DocumentsList = memo(DocumentsListComponent);
