import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paper: {
    width: '380px',
    background: 'white',
    '& p': {
      margin: 0,
      paddingTop: 12,
      paddingBottom: 12,
      '&:hover': {
        backgroundColor: '#E8E6EF',
      },
    },
  },
}));
