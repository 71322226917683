import {
  FETCH_STAKEHOLDERS_START,
  FETCH_STAKEHOLDERS_SUCCESS,
  FETCH_STAKEHOLDERS_FAILURE,
  UPDATE_STAKEHOLDER_START,
  UPDATE_STAKEHOLDER_SUCCESS,
  UPDATE_STAKEHOLDER_FAILURE,
  DELETE_STAKEHOLDER_START,
  DELETE_STAKEHOLDER_SUCCESS,
  DELETE_STAKEHOLDER_FAILURE,
  CREATE_STAKEHOLDER_START,
  CREATE_STAKEHOLDER_SUCCESS,
  CREATE_STAKEHOLDER_FAILURE,
  RESET_STAKEHOLDER_ERRORS,
} from '../actions/types';

const initialState = {
  businessIdStakeholderOwner: null,
  businessStakeholders: [],
  businessStakeholdersIsLoading: false,
  businessStakeholdersError: null,

  updateStakeholderIsLoading: false,
  updateStakeholderIsDone: false,
  updateStakeholderError: null,

  deleteStakeholderIsLoading: false,
  deleteStakeholderIsDone: false,
  deleteStakeholderError: null,

  createStakeholderIsLoading: false,
  createStakeholderError: null,
  stakeholderCreated: false,
};

const fetchStakeholdersStart = (state, payload) => {
  return {
    ...state,
    businessIdStakeholderOwner: payload.businessId,
    businessStakeholdersIsLoading: true,
    businessStakeholdersError: null,
  };
};

const fetchStakeholdersSuccess = (state, payload) => {
  return {
    ...state,
    businessStakeholders: payload.stakeholders,
    businessStakeholdersIsLoading: false,
  };
};

const fetchStakeholdersFailure = (state, payload) => {
  return {
    ...state,
    businessStakeholdersIsLoading: false,
    businessStakeholdersError: payload.errorCode,
  };
};

const updateStakeholderStart = state => {
  return {
    ...state,
    updateStakeholderIsLoading: true,
    updateStakeholderIsDone: false,
    updateStakeholderError: null,
  };
};

const updateStakeholderSuccess = (state, payload) => {
  const { businessStakeholders } = state;
  const { updateStakeholder } = payload;
  const updatedStakeholdersList = businessStakeholders.map(s =>
    s.id === updateStakeholder.id ? updateStakeholder : s
  );

  return {
    ...state,
    businessStakeholders: [...updatedStakeholdersList],
    updateStakeholderIsLoading: false,
    updateStakeholderIsDone: true,
  };
};

const updateStakeholderFailure = (state, payload) => {
  return {
    ...state,
    updateStakeholderIsLoading: false,
    updateStakeholderIsDone: false,
    updateStakeholderError: payload.errorCode,
  };
};

const deleteStakeholderStart = state => {
  return {
    ...state,
    deleteStakeholderIsLoading: true,
    deleteStakeholderIsDone: false,
    deleteStakeholderError: null,
  };
};

const deleteStakeholderSuccess = (state, payload) => {
  const { businessStakeholders } = state;
  const stakeholdersFiltered = businessStakeholders.filter(
    stakeholder => stakeholder.stakeholderId !== payload.stakeholderId
  );

  return {
    ...state,
    businessStakeholders: stakeholdersFiltered,
    deleteStakeholderIsLoading: false,
    deleteStakeholderIsDone: true,
  };
};

const deleteStakeholderFailure = (state, payload) => {
  return {
    ...state,
    deleteStakeholderIsLoading: false,
    deleteStakeholderError: payload.errorCode,
  };
};

const createStakeholderStart = state => {
  return {
    ...state,
    createStakeholderIsLoading: true,
    stakeholderCreated: false,
    createStakeholderError: null,
  };
};

const createStakeholderSuccess = (state, payload) => {
  const { businessStakeholders: stakeholders } = state;
  const { stakeholder } = payload;
  return {
    ...state,
    businessStakeholders: [...stakeholders, stakeholder],
    createStakeholderIsLoading: false,
    stakeholderCreated: true,
  };
};

const createStakeholderFailure = (state, payload) => {
  return {
    ...state,
    createStakeholderIsLoading: false,
    createStakeholderError: payload.errorCode,
  };
};

const resetErrors = state => {
  return {
    ...state,
    businessStakeholdersError: null,
    updateStakeholderError: null,
    deleteStakeholderError: null,
    createStakeholderError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STAKEHOLDERS_START:
      return fetchStakeholdersStart(state, payload);
    case FETCH_STAKEHOLDERS_SUCCESS:
      return fetchStakeholdersSuccess(state, payload);
    case FETCH_STAKEHOLDERS_FAILURE:
      return fetchStakeholdersFailure(state, payload);
    case UPDATE_STAKEHOLDER_START:
      return updateStakeholderStart(state);
    case UPDATE_STAKEHOLDER_SUCCESS:
      return updateStakeholderSuccess(state, payload);
    case UPDATE_STAKEHOLDER_FAILURE:
      return updateStakeholderFailure(state, payload);
    case DELETE_STAKEHOLDER_START:
      return deleteStakeholderStart(state);
    case DELETE_STAKEHOLDER_SUCCESS:
      return deleteStakeholderSuccess(state, payload);
    case DELETE_STAKEHOLDER_FAILURE:
      return deleteStakeholderFailure(state, payload);
    case CREATE_STAKEHOLDER_START:
      return createStakeholderStart(state);
    case CREATE_STAKEHOLDER_SUCCESS:
      return createStakeholderSuccess(state, payload);
    case CREATE_STAKEHOLDER_FAILURE:
      return createStakeholderFailure(state, payload);
    case RESET_STAKEHOLDER_ERRORS:
      return resetErrors(state);
    default:
      return state;
  }
};
