import React, { memo, useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useFetchDocuments } from '../../../../domains/document/services';
import { TABLE_OPTIONS } from '../../../../../commons/constants/ui';
import { GET_COLUMNS } from './constants';
import PaginationMaterialTable from '../../../../../../components/elements/PaginationMaterialTable';
import { usePayrollSelectorController } from '../../../../domains/payroll/controllers';
import { useGetCountry } from '../../../../../adapters/store';
import { useOpportunitiesContext } from '../../../../context/OpportunitiesContext';

const DocumentListComponent = ({ filters }) => {
  const { t } = useTranslation();
  const country = useGetCountry();
  const { addDocument } = usePayrollSelectorController();
  const opportunitiesCtx = useOpportunitiesContext();
  const [reqFilters, setReqFilters] = useState({
    ...filters,
    limit: TABLE_OPTIONS.pageSizeOptions[0],
  });

  const { isFetching, documents, pagination } = useFetchDocuments(reqFilters, {
    refetchOnMount: true,
  });

  const toggleDrawer = documentId => {
    opportunitiesCtx.setOpenDocumentDrawer({
      open: true,
      documentId,
    });
  };

  const columns = GET_COLUMNS({ country, t, toggleDrawer });

  useEffect(() => {
    setReqFilters(st => ({ ...filters, limit: st.limit, orderBy: st.orderBy }));
  }, [filters]);

  return (
    <>
      <MaterialTable
        columns={columns}
        data={documents}
        isLoading={isFetching}
        style={{ width: '100%', boxShadow: 'none' }}
        onSelectionChange={selectedRows => addDocument(selectedRows, documents)}
        onOrderChange={(orderedColumnId, orderDirection) => {
          setReqFilters(st => ({
            ...st,
            orderBy: {
              field: columns[orderedColumnId]?.field,
              direction: orderDirection,
            },
          }));
        }}
        components={{
          Toolbar: () => null,
          Pagination: props => (
            <PaginationMaterialTable
              {...props}
              rowsPerPage={reqFilters.limit}
              count={pagination?.totalRecords}
              page={pagination?.page - 1}
              onChangeRowsPerPage={(_, meta) => {
                setReqFilters(st => ({
                  ...st,
                  limit: meta.props.value,
                  page: 1,
                }));
              }}
              onChangePage={(_, page) => {
                setReqFilters(st => ({
                  ...st,
                  page: page + 1,
                }));
              }}
            />
          ),
        }}
        options={TABLE_OPTIONS}
        localization={{
          body: {
            emptyDataSourceMessage: 'No hay facturas para mostrar',
          },
          pagination: {
            labelRowsPerPage: 'Facturas por página',
            labelRowsSelect: 'facturas',
            labelDisplayedRows: '{count} facturas totales',
          },
        }}
      />
    </>
  );
};

DocumentListComponent.defaultProps = {
  filters: {},
};

DocumentListComponent.propTypes = {
  filters: PropTypes.objectOf(Object),
};

export const DocumentList = memo(DocumentListComponent);
