import { useSelector } from 'react-redux';
import { STATUSES_PAYMENT } from '../../commons/constants/status';

const useStatusesInbox = () => {
  const { country } = useSelector(state => state.config);

  const getStatuses = entityType => {
    return STATUSES_PAYMENT[entityType].filter(status =>
      status.country.includes(country)
    );
  };
  return { getStatuses };
};

export default useStatusesInbox;
