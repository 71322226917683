import { CircularProgress, Menu } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import MenuItem from '../../../../../components/elements/MenuItem';
import { features } from '../../../../../config/features';
import {
  useAnchorEl,
  useSelectedInvoice,
  useSetAnchorEl,
  useSetSelectedInvoice,
  useSetShowPartialPaymentDrawer,
  useSetShowUploadDocumentDialog,
} from './store';
import {
  Money as MoneyIcon,
  Delete as DeleteIcon,
} from '../../../../../components/icons';
import { INVOICES_DOWNLOAD_PDF_PERFORM } from '../../../../../helpers/performsType';
import { CONFIRMING, COUNTRY_CODE_MX } from '../../../../../helpers/Constants';
import Can from '../../../../../components/Can';
import { CONTEXT_TYPES } from '../../../../../helpers/userLogsUtils';
import {
  useFetchGlobalInvoicePdf,
  useFetchInvoicePdf,
  useFetchInvoiceAttachmentsInfo,
  useDeleteOrderInvoicePdf,
} from '../../../infrastructure/store';

const TableMenu = ({ handleHistoryLogDialog }) => {
  const setShowUploadDocumentDialog = useSetShowUploadDocumentDialog();
  const setShowPartialPaymentDrawer = useSetShowPartialPaymentDrawer();
  const selectedInvoice = useSelectedInvoice();
  const setSelectedInvoice = useSetSelectedInvoice();
  const anchorEl = useAnchorEl();
  const setAnchorEl = useSetAnchorEl();
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];
  const { id: orderId } = useParams();

  const { deleteOrderInvoicePdf, deleteOrderInvoicePdfLoading } =
    useDeleteOrderInvoicePdf();

  const {
    invoiceAttachmentsInfo: orderInvoicesAttachments,
    refetch: refetchInvoiceAttachmentsInfo,
    isFetching: invoiceAttachmentsInfoIsFetching,
  } = useFetchInvoiceAttachmentsInfo(orderId);

  const handleShowHistoryDialog = () => {
    setAnchorEl(null);
    handleHistoryLogDialog({
      contextId: selectedInvoice?.id,
      contextType: CONTEXT_TYPES.orderFacturas,
    });
  };

  const handleDeleteInvoicePdf = () => {
    const { id: invoiceId } = selectedInvoice;
    const { id: pdfId } = orderInvoicesAttachments.find(
      attachment => attachment.orderInvoiceId === invoiceId
    );
    deleteOrderInvoicePdf({ invoiceId, pdfId });
    refetchInvoiceAttachmentsInfo();
  };

  const handleUpdloadInvoicePdf = () => {
    setAnchorEl(null);
    setShowUploadDocumentDialog(true);
  };

  const {
    id,
    folio,
    issuerIdentifier,
    OrderFacturaAttachments: orderFacturaAttachments,
  } = selectedInvoice || {};

  const fileName = `${country}_${orderId}_${folio}`;

  const { refetchInvoicePdf, fetchInvoicePDFIsLoading } = useFetchInvoicePdf({
    fileName,
    orderType: CONFIRMING,
    identifier: issuerIdentifier,
    country,
    id,
  });

  const { refetchPdfGlobalCl, fetchPdfGlobalClIsLoading } =
    useFetchGlobalInvoicePdf(id);

  const getPdfActionButtonMx = () => {
    const hasAttachments =
      (orderFacturaAttachments && orderFacturaAttachments?.length) ||
      orderInvoicesAttachments?.find(
        attachment =>
          attachment?.orderInvoiceId === selectedInvoice?.id &&
          attachment?.hasAttachment
      );

    if (hasAttachments) {
      return (
        <>
          <MenuItem
            size="small"
            onClick={() => refetchPdfGlobalCl()}
            icon={
              fetchPdfGlobalClIsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <CloudDownloadIcon />
              )
            }
          >
            Descargar PDF de la factura Mimetus
          </MenuItem>

          <MenuItem
            size="small"
            icon={
              deleteOrderInvoicePdfLoading ||
              invoiceAttachmentsInfoIsFetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <DeleteIcon />
              )
            }
            onClick={handleDeleteInvoicePdf}
          >
            Eliminar PDF de la factura
          </MenuItem>
        </>
      );
    }

    return (
      <>
        <MenuItem icon={<CloudUploadIcon />} onClick={handleUpdloadInvoicePdf}>
          Subir PDF de la factura
        </MenuItem>
        <MenuItem
          size="small"
          onClick={() => refetchInvoicePdf()}
          icon={
            fetchInvoicePDFIsLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <CloudDownloadIcon />
            )
          }
        >
          Descargar PDF de la factura
        </MenuItem>
      </>
    );
  };

  const getPdfActionButton = () => {
    if (
      !selectedInvoice?.issuerHasMarketInvoicer &&
      selectedInvoice?.issuerHasPfxCertificate
    ) {
      return (
        <MenuItem
          size="small"
          onClick={() => refetchInvoicePdf()}
          icon={
            fetchInvoicePDFIsLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <CloudDownloadIcon />
            )
          }
        >
          Descargar PDF de la factura
        </MenuItem>
      );
    }

    let actionButtons = (
      <MenuItem icon={<CloudUploadIcon />} onClick={handleUpdloadInvoicePdf}>
        Subir PDF de la factura
      </MenuItem>
    );

    if (
      (orderFacturaAttachments && orderFacturaAttachments?.length) ||
      orderInvoicesAttachments?.find(
        attachment =>
          attachment?.orderInvoiceId === selectedInvoice?.id &&
          attachment?.hasAttachment
      )
    ) {
      actionButtons = (
        <>
          <MenuItem
            size="small"
            onClick={() => refetchPdfGlobalCl()}
            icon={
              fetchPdfGlobalClIsLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <CloudDownloadIcon />
              )
            }
          >
            Descargar PDF de la factura
          </MenuItem>

          <MenuItem
            size="small"
            icon={
              deleteOrderInvoicePdfLoading ||
              invoiceAttachmentsInfoIsFetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <DeleteIcon />
              )
            }
            onClick={handleDeleteInvoicePdf}
          >
            Eliminar PDF de la factura
          </MenuItem>
        </>
      );
    }

    return actionButtons;
  };

  const handleCloseMenu = event => {
    event.persist();
    setAnchorEl(null);
    setSelectedInvoice(undefined);
  };

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem
        icon={<MoneyIcon />}
        onClick={() => setShowPartialPaymentDrawer(true)}
      >
        Pagos parciales
      </MenuItem>
      {countryFeatures.orderActions.showDownloadPdfInvoiceButton && (
        <Can
          perform={INVOICES_DOWNLOAD_PDF_PERFORM}
          yes={() =>
            country === COUNTRY_CODE_MX
              ? getPdfActionButtonMx()
              : getPdfActionButton()
          }
        />
      )}
      {countryFeatures.orderActions.showInvoiceHistoryChangeButton && (
        <MenuItem icon={<HistoryIcon />} onClick={handleShowHistoryDialog}>
          Historial de cambios
        </MenuItem>
      )}
    </Menu>
  );
};

TableMenu.propTypes = {
  handleHistoryLogDialog: PropTypes.func.isRequired,
};

export default TableMenu;
