export const addInvoiceUseCase = ({
  rowsSelected,
  currentSelectorState,
  selectedReceiver,
  prevStateTable,
}) => {
  const { totalDocuments, receiverId, payrollInvoicesId } = selectedReceiver;
  const currentSelectedReceiver = currentSelectorState[receiverId];
  const selectedPrevIds = prevStateTable
    .filter(row => row.tableData.checked)
    .map(r => r.id);
  const selectedCurrentIds = rowsSelected.map(d => d.id);
  const idsToRemove = selectedPrevIds.filter(
    d => !selectedCurrentIds.includes(d)
  );
  const selectedIdAgain = selectedCurrentIds.filter(
    id => !selectedPrevIds.includes(id)
  )[0];

  if (rowsSelected.length === prevStateTable.length) {
    const newIdsDocToAdd = prevStateTable
      .map(d => d.id)
      .filter(d => !selectedPrevIds.includes(d));
    const idsDocsToAdd = [
      ...(currentSelectedReceiver?.value || []),
      ...newIdsDocToAdd,
    ];
    const selected = idsDocsToAdd.length === totalDocuments;

    return {
      ...currentSelectorState,
      [receiverId]: {
        amount:
          (currentSelectedReceiver?.amount || 0) +
          newIdsDocToAdd.reduce(
            (acc, idDoc) =>
              acc + rowsSelected.find(d => d.id === idDoc).invoice.amount,
            0
          ),
        selected,
        value: idsDocsToAdd,
      },
    };
  }

  if (rowsSelected.length === payrollInvoicesId.length) {
    const newIdsDocToAdd = payrollInvoicesId.filter(
      d => !selectedPrevIds.includes(d)
    );
    const idsDocsToAdd = [
      ...(currentSelectedReceiver?.value || []),
      ...newIdsDocToAdd,
    ];
    const selected = idsDocsToAdd.length === totalDocuments;

    return {
      ...currentSelectorState,
      [receiverId]: {
        amount:
          (currentSelectedReceiver?.amount || 0) +
          newIdsDocToAdd.reduce(
            (acc, idDoc) =>
              acc + rowsSelected.find(d => d.id === idDoc).invoice.amount,
            0
          ),
        selected,
        value: idsDocsToAdd,
      },
    };
  }

  const invoiceToAdd = rowsSelected.find(r => r.id === selectedIdAgain);

  if (invoiceToAdd) {
    const idsDocsToAdd = [
      ...(currentSelectedReceiver?.value || []),
      selectedIdAgain,
    ];
    const selected = idsDocsToAdd.length === totalDocuments;

    return {
      ...currentSelectorState,
      [receiverId]: {
        selected,
        value: idsDocsToAdd,
        amount:
          (currentSelectedReceiver?.amount || 0) + invoiceToAdd.invoice.amount,
      },
    };
  }

  const value = currentSelectedReceiver.value.filter(
    d => !idsToRemove.includes(d)
  );

  if (!value.length) {
    return Object.keys(currentSelectorState).reduce((acc, curReceiverId) => {
      if (curReceiverId !== receiverId) {
        return { ...acc, [curReceiverId]: currentSelectorState[curReceiverId] };
      }

      return acc;
    }, {});
  }

  return {
    ...currentSelectorState,
    [receiverId]: {
      selected: false,
      value,
      amount:
        currentSelectedReceiver.amount -
        idsToRemove.reduce(
          (acc, idDoc) =>
            acc + prevStateTable.find(d => d.id === idDoc).invoice.amount,
          0
        ),
    },
  };
};
