import React, { ReactNode, FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const WarningIcon: FC<Props> = ({ color = palette.primary.main, style }) => {
  return (
    <SvgIcon
      style={{
        color,
        ...style,
      }}
      viewBox="0 0 24 24"
    >
      <path d="M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2z" />
      <path d="M13 16h-2v2h2zm0-6h-2v5h2z" />
    </SvgIcon>
  );
};

export default WarningIcon;
