import React from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';
import { FormControl, Grid, MenuItem, Select, styled } from '@material-ui/core';
import { Text } from '../formatters';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20];

const PaginationWrapper = styled(Grid)({
  gap: 35,
  alignItems: 'center',
  justifyContent: 'center',
  margin: '37px 0 58px 0',
});

const RowsPerPageWrapper = styled(Grid)({
  gap: 10,
  alignItems: 'center',
});

interface PaginationProps {
  page: number;
  itemsPerPage: number;
  handleChangePage: (newPage: number) => void;
  handleChangeItemsPerPage: () => void;
  payrollsPagination: {
    page: number;
    pageSize: number;
    totalData: number;
    totalPages: number;
  };
}

const defaultPayrollsPagination = {
  pageSize: undefined,
  totalData: undefined,
  totalPage: undefined,
};

const Pagination = ({
  page,
  itemsPerPage,
  payrollsPagination,
  handleChangePage,
  handleChangeItemsPerPage,
}: PaginationProps) => {
  const { totalData, totalPages } =
    payrollsPagination ?? defaultPayrollsPagination;
  const from = page * itemsPerPage - (itemsPerPage - 1);
  const to = Math.min(page * itemsPerPage, totalData) || 1;

  return (
    <PaginationWrapper container>
      <Grid item>
        <RowsPerPageWrapper container>
          <Grid item>
            <Text variant="body1" color="textPrimary">
              Registros por página
            </Text>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page"
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
              >
                {ROWS_PER_PAGE_OPTIONS.map(option => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected={option === itemsPerPage}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </RowsPerPageWrapper>
      </Grid>
      <Grid item>
        <MaterialPagination
          count={totalPages}
          page={page}
          onChange={(_evt, newPage) => handleChangePage(newPage)}
          size="small"
          color="secondary"
        />
      </Grid>
      <Grid item>
        <Text variant="body1" color="textPrimary">
          {from}-{to} de {totalData}
        </Text>
      </Grid>
    </PaginationWrapper>
  );
};

export default Pagination;
