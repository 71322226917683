import React, { FC } from 'react';
import { Box, Collapse, IconButton } from '@material-ui/core';
import { OperationList } from '../../../../commons/interfaces/ARInterfaces';
import {
  ContainerDetailInformation,
  LabelDetailInformation,
  ValueDetailInformation,
} from './style';
import useOperationCard from '../../../hooks/useOperationCard';
import { format } from 'date-fns';
import useGetCountry from './../../../../../hooks/useGetCountry';
import CountryFormatHelper from './../../../../../components/elements/CountryFormatHelper';
import { RequirementData } from '../../../../commons/components/RequirementsTab/interfaces';
import useRequirements from '../../../../commons/components/RequirementsTab/hooks/useRequirements';
import RequirementsDrawer from '../../../../commons/components/RequirementsDrawer';
import InvoicePendingRequirementsDrawer from '../../../../commons/components/InvoicePendingRequirementsDrawer';
import ArrowRightIcon from '../../../../commons/icons/ArrowRightIcon';

interface Props {
  operation: OperationList;
  isExpanded: boolean;
}

const OperationCardAttributes: FC<Props> = ({ operation, isExpanded }) => {
  const country = useGetCountry();
  const { handleGetRequirementData, transformRequirementData } =
    useRequirements();
  const {
    showRequirementsDrawer,
    showInvoicePendingRequirementsDrawer,
    invoiceRequirementSelected,
    getShowAttributes,
    handleGetInvoiceRequirementIcon,
    handleGetLegelDocumentRequerimientIcon,
    handleOpenRequirementsDrawer,
    handleCloseRequirementsDrawer,
    handleOpenInvoicePendingRequirementsDrawer,
    handleCloseInvoicePendingRequirementsDrawer,
  } = useOperationCard();

  const requirementData = transformRequirementData(operation.requirements);

  const invoiceRequerimentData: RequirementData[] = handleGetRequirementData({
    section: 'invoices',
    rawRequirementData: requirementData,
    operationType: operation.type,
    operationStatus: operation.status,
  });

  const legalDocumentsRequerimentData: RequirementData[] =
    handleGetRequirementData({
      section: 'legalDocuments',
      rawRequirementData: requirementData,
      operationType: operation.type,
      operationStatus: operation.status,
    });

  return (
    <Collapse in={isExpanded} timeout="auto">
      <Box display="flex" mt={5}>
        {getShowAttributes('invoiceRequirement', operation) &&
          invoiceRequerimentData?.length > 0 && (
            <ContainerDetailInformation>
              <Box display="flex" alignItems="center" mb={1}>
                <LabelDetailInformation>Requisitos</LabelDetailInformation>
                <Box ml={1}>
                  <IconButton
                    size="small"
                    onClick={handleOpenRequirementsDrawer}
                  >
                    <ArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex">
                {invoiceRequerimentData?.map(({ keyValue, status }) => (
                  <Box mr={3}>
                    {handleGetInvoiceRequirementIcon({ keyValue, status })}
                  </Box>
                ))}
              </Box>
            </ContainerDetailInformation>
          )}

        {getShowAttributes('legalDocumentRequirement', operation) &&
          legalDocumentsRequerimentData?.length > 0 && (
            <ContainerDetailInformation>
              <Box display="flex" alignItems="center" mb={1}>
                <LabelDetailInformation>Docs Legal</LabelDetailInformation>
                <Box ml={1}>
                  <IconButton
                    size="small"
                    onClick={handleOpenRequirementsDrawer}
                  >
                    <ArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex">
                {handleGetLegelDocumentRequerimientIcon(
                  legalDocumentsRequerimentData
                )}
              </Box>
            </ContainerDetailInformation>
          )}

        {getShowAttributes('issuedDate', operation) && (
          <ContainerDetailInformation>
            <Box mb={1}>
              <LabelDetailInformation>Solicitud</LabelDetailInformation>
            </Box>
            <ValueDetailInformation>
              {format(new Date(operation?.issuedDate), 'dd-MM-yyyy')}
            </ValueDetailInformation>
          </ContainerDetailInformation>
        )}

        {getShowAttributes('baseRate', operation) && (
          <ContainerDetailInformation>
            <Box mb={1}>
              <LabelDetailInformation>Tasa Base</LabelDetailInformation>
            </Box>
            <ValueDetailInformation>
              {operation?.baseRate}%
            </ValueDetailInformation>
          </ContainerDetailInformation>
        )}

        {getShowAttributes('interest', operation) && (
          <ContainerDetailInformation>
            <Box mb={1}>
              <LabelDetailInformation>Intereses</LabelDetailInformation>
            </Box>
            <ValueDetailInformation>
              {/* @ts-ignore */}
              <CountryFormatHelper
                value={operation?.interest}
                variant="currency"
                countryId={country}
              />
            </ValueDetailInformation>
          </ContainerDetailInformation>
        )}

        {getShowAttributes('clientType', operation) && (
          <ContainerDetailInformation>
            <Box mb={1}>
              <LabelDetailInformation>Cliente</LabelDetailInformation>
            </Box>
            <ValueDetailInformation>
              {operation?.totalOrdersTransferred < 2 ? 'Nuevo' : 'Recurrente'}
            </ValueDetailInformation>
          </ContainerDetailInformation>
        )}
      </Box>

      {showRequirementsDrawer && (
        <RequirementsDrawer
          isOpen={showRequirementsDrawer}
          operation={operation}
          handleCloseDrawer={handleCloseRequirementsDrawer}
          handleOpenInvoicePendingRequirementsDrawer={
            handleOpenInvoicePendingRequirementsDrawer
          }
        />
      )}

      {showInvoicePendingRequirementsDrawer && (
        <InvoicePendingRequirementsDrawer
          isOpen={showInvoicePendingRequirementsDrawer}
          requirement={invoiceRequirementSelected}
          operationId={operation.id}
          handleCloseDrawer={() => {
            handleCloseInvoicePendingRequirementsDrawer();
            handleOpenRequirementsDrawer();
          }}
        />
      )}
    </Collapse>
  );
};

export default OperationCardAttributes;
