import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  tableContainer: {
    padding: '20px 20px 100px 20px',
  },
  mb15: {
    marginBottom: '15px',
  },
  returnLink: {
    margin: '15px 0 20px',
    color: '#8D8A96',
  },
  mb30: {
    marginBottom: '30px',
  },
  containerTextMainAreaBold: {
    marginRight: 20,
  },
  textMainAreaBold: {
    marginRight: 5,
  },
});
