import { useQuery } from 'react-query';

import { fetchOrderInvoicesFundService } from '../services';

const useFetchOrderInvoicesFund = orderId => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    'getOrderInvoicesFund',
    () => fetchOrderInvoicesFundService(orderId),
    { refetchOnWindowFocus: false }
  );

  const { data: orderInvoicesFund } = data || { data: [] };
  return {
    orderInvoicesFund,
    isLoadingFunds: isLoading || isFetching,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchOrderInvoicesFund };
