import React, { FC } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import UserIcon from '../../icons/UserIcon';

interface Props {
  responsiblesCount: number;
  isLoading: boolean;
  handleOpenDrawer: () => void;
}

const useStyles = makeStyles({
  containerIcon: {
    marginRight: 5,
    height: 22,
  },
  orderResponsiblesIconContainer: {
    width: 51,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 16,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  orderResponsiblesLoadingContainers: {
    width: 51,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const OrderResponsiblesIcon: FC<Props> = ({
  responsiblesCount,
  isLoading,
  handleOpenDrawer,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" mx={2}>
      {isLoading ? (
        <Box className={classes.orderResponsiblesLoadingContainers}>
          <CircularProgress size={26} />
        </Box>
      ) : (
        <Tooltip title="Ver responsables" arrow>
          <Box
            className={classes.orderResponsiblesIconContainer}
            data-cy="orderResponsiblesShowResponsiblesButton"
            onClick={handleOpenDrawer}
          >
            <Box className={classes.containerIcon}>
              <UserIcon />
            </Box>
            {responsiblesCount > 0 && (
              <Typography
                variant="body1"
                color="textPrimary"
                data-cy="orderResponsiblesCountResponsibles"
              >
                {responsiblesCount}
              </Typography>
            )}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default OrderResponsiblesIcon;
