import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import DocumentsTable from './tables/DocumentsTable';
import { fetchDocuments } from '../actions/documentActions';

const StakeholderDocuments = ({ stakeholderId }) => {
  const dispatch = useDispatch();

  const {
    stakeholderDocuments,
    stakeholderDocumentsIsLoading,
    stakeholderIdDocumentsOwner,
  } = useSelector(state => state.documents);

  useEffect(() => {
    if (stakeholderId !== stakeholderIdDocumentsOwner) {
      dispatch(fetchDocuments(stakeholderId, 'stakeholder'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeholderId, dispatch]);

  return (
    <Grid item container xs={12}>
      <DocumentsTable
        documents={stakeholderDocuments}
        documentsAreLoading={stakeholderDocumentsIsLoading}
        documentableType="stakeholder"
        documentableId={stakeholderId}
      />
    </Grid>
  );
};

StakeholderDocuments.propTypes = {
  stakeholderId: PropTypes.number.isRequired,
};

export default StakeholderDocuments;
