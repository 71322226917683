import { useState, useCallback } from 'react';
import { DebtExtensionDrawer } from '../../commons/interfaces/debtExtension';

const useShowExtensionDetal = () => {
  const [showExtensionDetail, setShowExtensionDetail] = useState(false);
  const [extensionDetail, setExtensionDetail] = useState<
    DebtExtensionDrawer | undefined
  >(undefined);

  const toggleShowExtensionDetail = () => {
    setShowExtensionDetail(!showExtensionDetail);
  };

  const openDrawerWithDetail = (data: DebtExtensionDrawer | undefined) => {
    setExtensionDetail(data);
    setShowExtensionDetail(true);
  };

  return {
    showExtensionDetail,
    setShowExtensionDetail,
    toggleShowExtensionDetail,
    extensionDetail,
    openDrawerWithDetail,
  };
};

export default useShowExtensionDetal;
