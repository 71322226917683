import {
  FETCH_SUBRUBRO_START,
  FETCH_SUBRUBRO_SUCCESS,
  FETCH_SUBRUBRO_FAILURE,
} from '../actions/types';

const initialState = {
  fetchingSubRubros: false,
  subRubros: [],
  subRubrosError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SUBRUBRO_START:
      return {
        ...state,
        fetchingSubRubros: true,
      };

    case FETCH_SUBRUBRO_SUCCESS:
      return {
        ...state,
        subRubros: payload.subRubros || payload,
        fetchingSubRubros: false,
      };

    case FETCH_SUBRUBRO_FAILURE:
      return {
        ...state,
        subRubrosError: payload.errorCode,
        fetchingSubRubros: false,
      };

    default:
      return state;
  }
};
