import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateInvoiceFundService } from '../services';

export const useUpdateInvoiceFund = () => {
  const dispatch = useDispatch();
  const {
    isLoading: updateInvoiceFundIsLoading,
    isSuccess: invoiceFundWasUpdated,
    error: updateInvoiceFundError,
    mutate: updateInvoiceFund,
  } = useMutation(
    ({ orderInvoiceFundId, orderInvoiceFund }) => {
      return updateInvoiceFundService({ orderInvoiceFundId, orderInvoiceFund });
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.INVOICE_FUND_UPDATED, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar('Error al actualizar el fondo de la factura', {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    updateInvoiceFund,
    updateInvoiceFundIsLoading,
    invoiceFundWasUpdated,
    updateInvoiceFundError,
  };
};

export default useUpdateInvoiceFund;
