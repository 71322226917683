import React from 'react';
import { TextField as MaterialTextField, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import { Clear } from '@material-ui/icons';

const CustomTextField = styled(MaterialTextField)({
  margin: 0,
});

const TextField = props => {
  const { value, label, onChange, onClear } = props;

  return (
    <CustomTextField
      value={value}
      label={label}
      onChange={onChange}
      variant="outlined"
      InputProps={{
        endAdornment:
          value && onClear ? (
            <IconButton
              onClick={onClear}
              aria-label="clear"
              edge="end"
              size="small"
            >
              <Clear />
            </IconButton>
          ) : null,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

TextField.defaultProps = {
  onClear: () => undefined,
};

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default TextField;
