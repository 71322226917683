import React from 'react';
import { Box, Link, Tooltip, Typography } from '@material-ui/core';

import { Warning } from '@material-ui/icons';
import { t13s } from '../../../../../translationKeys';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import {
  fixDecimals,
  roundTerms,
  defaultValue,
  percentageFormat,
} from '../../../../commons/utils';
import { SORT_NULL_LAST } from '../../../../commons/constants/ui';

export const DIALOG_TYPES = {
  RATE: 'RATE',
  DEBT_SERVICE_AMOUNT: 'DEBT_SERVICE_AMOUNT',
};

export const GET_COLUMNS = ({
  changeToDocumentView,
  country,
  css,
  selectDraftReceiver,
  selectedFund,
  setOpenEditDialog,
  setSelectedReceiverToEdit,
  showPDFStatus,
  t,
}) => [
  {
    title: 'Pagador',
    align: 'left',
    field: 'name',
    type: 'string',
    width: '320px',
    render: row => (
      <Link
        component="button"
        variant="body1"
        align="left"
        title={row?.name}
        className={`${css.payerLink} ${css.hasEllipsis}`}
        onClick={() => {
          selectDraftReceiver(row);
          changeToDocumentView();
        }}
      >
        {defaultValue(row?.name)}
      </Link>
    ),
  },
  {
    title: 'Nº Emisores',
    align: 'left',
    field: 'issuersNumber',
    type: 'numeric',
    width: '80px',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.issuersNumber)}
      </Typography>
    ),
  },
  {
    title: 'Nº Documentos',
    align: 'left',
    field: 'documentsNumber',
    type: 'numeric',
    width: '60px',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.documentsNumber)}
        {showPDFStatus && row.hasPdf === false && (
          <Tooltip
            title="Hay facturas sin PDF"
            style={{ float: 'right', marginRight: 48 }}
          >
            <Box display="inline-block" alignContent="right">
              <Warning
                style={{
                  fill: '#F4B231',
                  marginBottom: -5,
                  marginLeft: 2,
                }}
              />
            </Box>
          </Tooltip>
        )}
      </Typography>
    ),
  },
  {
    title: 'Monto bruto',
    align: 'left',
    field: 'amount',
    type: 'numeric',
    width: '200px',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        <CountryFormatHelper
          value={row?.amount}
          countryId={country}
          variant="currency"
        />
      </Typography>
    ),
  },
  {
    title: 'Plazo ponderado',
    align: 'left',
    field: 'term',
    type: 'numeric',
    width: '150px',
    customSort: SORT_NULL_LAST('term'),
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(roundTerms(row?.term))}
      </Typography>
    ),
  },
  {
    title: 'Score ponderado',
    align: 'left',
    field: 'score',
    type: 'numeric',
    width: '150px',
    customSort: SORT_NULL_LAST('score'),
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(fixDecimals(row?.score))}
      </Typography>
    ),
  },
  {
    title: 'Tasa Fondo',
    align: 'left',
    field: 'rate',
    type: 'numeric',
    width: '120px',
    render: row =>
      selectedFund ? (
        <Link
          component="button"
          variant="body1"
          align="left"
          title={row?.name}
          className={css.payerLink}
          onClick={() => {
            setSelectedReceiverToEdit(row);
            setOpenEditDialog(DIALOG_TYPES.RATE);
          }}
        >
          {percentageFormat(row?.rate)}
        </Link>
      ) : (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(row?.rate)}
        </Typography>
      ),
  },
  {
    title: `${t(t13s.LABEL.DEBT_SERVICE_NAME)} pagador`,
    align: 'left',
    field: 'receiverDebtServiceAmount',
    type: 'numeric',
    width: '200px',
    customSort: SORT_NULL_LAST('receiverDebtServiceAmount'),
    render: row =>
      !selectedFund ? (
        <Typography variant="body1" color="textPrimary">
          <CountryFormatHelper
            value={row?.receiverDebtServiceAmount}
            countryId={country}
            variant="currency"
          />
        </Typography>
      ) : (
        <Link
          component="button"
          variant="body1"
          align="left"
          title={row?.name}
          className={css.payerLink}
          onClick={() => {
            setSelectedReceiverToEdit(row);
            setOpenEditDialog(DIALOG_TYPES.DEBT_SERVICE_AMOUNT);
          }}
        >
          <CountryFormatHelper
            value={row?.receiverDebtServiceAmount}
            countryId={country}
            variant="currency"
          />
        </Link>
      ),
  },
  {
    title: '% AR',
    align: 'left',
    field: 'acknowledgementPercentage',
    type: 'numeric',
    width: '200px',
    customSort: SORT_NULL_LAST('acknowledgementPercentage'),
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {percentageFormat(row?.acknowledgementPercentage)}
      </Typography>
    ),
  },
];
