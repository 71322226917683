import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { useParams } from 'react-router-dom';
import { useSetSelectedInvoice, useSetShowFundDialog } from '../store';
import {
  useFetchActiveFunds,
  useFetchOrderInvoicesFund,
} from '../../../../infrastructure/store';

const Funds = ({ rowData }) => {
  const { activeFunds: funds } = useFetchActiveFunds();

  const { id: orderId } = useParams();
  const { orderInvoicesFund: orderInvoicesFundData, isLoadingFunds } =
    useFetchOrderInvoicesFund(orderId);

  const orderInvoiceFund = orderInvoicesFundData.find(
    dataFund => dataFund.orderInvoiceId === rowData.id
  );

  const fund = orderInvoiceFund
    ? funds.find(data => data.id === orderInvoiceFund.fundId)
    : null;

  const color = orderInvoiceFund ? 'primary' : 'disabled';

  const fundName =
    fund?.name?.length > 8 ? `${fund?.name?.substring(0, 9)}...` : fund?.name;

  const setShowFundDialog = useSetShowFundDialog();
  const setSelectedInvoice = useSetSelectedInvoice();

  const handleShowFundDialog = invoice => {
    setSelectedInvoice({
      ...invoice,
      tableData: {
        ...invoice.tableData,
      },
    });
    setShowFundDialog(true);
  };

  if (isLoadingFunds) return <CircularProgress color="primary" size={25} />;

  if (orderInvoiceFund)
    return (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleShowFundDialog(rowData)}
      >
        {fundName}
      </Button>
    );

  return (
    <IconButton color={color} onClick={() => handleShowFundDialog(rowData)}>
      <LinkIcon />
    </IconButton>
  );
};

Funds.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default Funds;
