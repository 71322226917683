import { useCallback, useMemo } from 'react';

import {
  useSelectedReceiver,
  usePayrollActions,
  usePayrollSelector,
} from '../../../adapters/store/payroll';
import {
  addInvoiceUseCase,
  addReceiverUseCase,
  getSelectedSummaryUseCase,
} from '../useCases';

export const usePayrollSelectorController = () => {
  const { pickReceiver, cleanPayrollSelector } = usePayrollActions();
  const payrollSelector = usePayrollSelector();
  const selectedReceiver = useSelectedReceiver();
  const currentSelectorState = useMemo(
    () => payrollSelector || {},
    [payrollSelector]
  );
  const summary = useMemo(
    () => getSelectedSummaryUseCase({ currentSelectorState }),
    [currentSelectorState]
  );

  const addReceiver = useCallback(
    (rowsSelected, prevStateTable) => {
      const newState = addReceiverUseCase({
        currentSelectorState,
        rowsSelected,
        prevStateTable,
      });

      pickReceiver(newState);
    },
    [currentSelectorState, pickReceiver]
  );

  const addInvoice = useCallback(
    (rowsSelected, prevStateTable) => {
      const newState = addInvoiceUseCase({
        rowsSelected,
        currentSelectorState,
        selectedReceiver,
        prevStateTable,
      });

      pickReceiver(newState);
    },
    [currentSelectorState, selectedReceiver, pickReceiver]
  );

  return {
    addInvoice,
    addReceiver,
    cleanPayrollSelector,
    summary,
  };
};
