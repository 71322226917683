import { useQuery } from 'react-query';
import { fetchOrdersService } from '../services';

const useFetchOrders = ({
  operation,
  page = 1,
  limit = 20,
  filters = null,
}) => {
  const { isLoading, data, refetch } = useQuery(
    'fetchOrders',
    () =>
      fetchOrdersService({
        operation,
        page,
        limit,
        filters,
      }),
    { refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchOrders };
