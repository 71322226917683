import React, { useState, useCallback } from 'react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Panel from '../../../../../components/elements/Panel';
import { DocumentsList } from './DocumentsList';
import * as St from './DocumentsView.styles';
import { DIALOG_TYPES, GET_RECEIVER_SUMMARY } from './constants';
import { useGetCountry } from '../../../../adapters/store';
import { useSelectedDraftReceiver } from '../../../domains/receiver/state';
import { useOpportunitiesContext } from '../../../context/OpportunitiesContext';
import { useGetSimulation } from '../../../domains/payroll/state';
import { UpdateFundAdvancePercentageDialog } from './UpdateFundAdvancePercentageDialog';
import { UpdateFundRateDialog } from '../UpdateFundRateDialog';
import { UpdateFundInvoiceIssuerDebtDialog } from './UpdateFundInvoiceIssuerDebtDialog';
import { UpdateFundInvoiceTermDialog } from './UpdateFundInvoiceTermDialog';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import { getTermDate, getTermInDays } from '../../../../commons/utils';

export const DocumentsView = () => {
  const css = St.useStyles();
  const country = useGetCountry();
  const [openEditDialog, setOpenEditDialog] = useState();
  const [selectedDocumentToEdit, setSelectedDocumentToEdit] = useState();
  const selectedReceiver = useSelectedDraftReceiver();
  const opportunitiesCtx = useOpportunitiesContext();
  const simulation = useGetSimulation();
  const { editDocument } = usePayrollSelectorController();
  const fields = GET_RECEIVER_SUMMARY({
    country,
    selectedReceiver,
    simulation,
  });

  const onOpenDocumentDrawer = useCallback(documentId => {
    opportunitiesCtx.setOpenDocumentDrawer({
      open: true,
      documentId,
    });
  }, []);

  return (
    <>
      <Grid item xs={12} className={css.tableContainer}>
        <Panel title="" titlePadding="lg" contentPadding="zero lg zero">
          <Link
            onClick={() => opportunitiesCtx.changeToReceiverView()}
            className={css.returnLink}
          >
            <Box display="flex">
              <ArrowBackIcon />
              <Typography component="span">Volver</Typography>
            </Box>
          </Link>
          <h3>{selectedReceiver.name}</h3>
          <Box display="flex" style={{ marginBottom: 40 }}>
            {fields.map(({ title, value }, idx) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                display="flex"
                className={css.containerTextMainAreaBold}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={css.textMainAreaBold}
                >
                  <Box fontWeight="fontWeightBold">{title}</Box>
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {value}
                </Typography>
              </Box>
            ))}
            <Link
              component="button"
              variant="body1"
              align="left"
              onClick={() => {
                opportunitiesCtx.setOpenReceiverDrawer({
                  open: true,
                  receiverIdentifier: selectedReceiver.receiverIdentifier,
                });
              }}
            >
              Ver más detalle
            </Link>
          </Box>
          <DocumentsList
            changeToReceiverView={opportunitiesCtx.changeToReceiverView}
            setOpenEditDialog={setOpenEditDialog}
            setSelectedDocumentToEdit={setSelectedDocumentToEdit}
            openDocumentDrawer={onOpenDocumentDrawer}
          />
        </Panel>
      </Grid>

      <UpdateFundRateDialog
        isOpen={openEditDialog === DIALOG_TYPES.RATE}
        handleCloseDialog={() => {
          setOpenEditDialog(null);
        }}
        title="Editar tasa"
        label="Tasa Fondo (%)"
        description={`Factura folio ${selectedDocumentToEdit?.folio}`}
        selectedRate={{ rate: selectedDocumentToEdit?.rate }}
        handleSubmit={newValue => {
          editDocument(
            newValue,
            selectedReceiver.receiverIdentifier,
            selectedDocumentToEdit.id
          );
          setOpenEditDialog(null);
        }}
      />
      <UpdateFundInvoiceIssuerDebtDialog
        isOpen={openEditDialog === DIALOG_TYPES.DEBT_SERVICE_AMOUNT}
        handleCloseDialog={() => {
          setOpenEditDialog(null);
        }}
        description={`Factura folio ${selectedDocumentToEdit?.folio}`}
        selectedDebt={{
          issuerDebt: selectedDocumentToEdit?.issuer?.debtServiceAmount,
        }}
        handleSubmit={newValue => {
          editDocument(
            newValue,
            selectedReceiver.receiverIdentifier,
            selectedDocumentToEdit.id
          );
          setOpenEditDialog(null);
        }}
      />
      <UpdateFundAdvancePercentageDialog
        isOpen={openEditDialog === DIALOG_TYPES.ADVANCE}
        handleCloseDialog={() => {
          setOpenEditDialog(null);
        }}
        title="Editar porcentaje de avance"
        description={`Factura folio ${selectedDocumentToEdit?.folio}`}
        selectedAdvance={{
          advancePercentage: selectedDocumentToEdit?.advancePercentage,
        }}
        handleSubmit={newValue => {
          editDocument(
            newValue,
            selectedReceiver.receiverIdentifier,
            selectedDocumentToEdit.id
          );
          setOpenEditDialog(null);
        }}
      />
      <UpdateFundInvoiceTermDialog
        isOpen={openEditDialog === DIALOG_TYPES.TERM}
        handleCloseDialog={() => {
          setOpenEditDialog(null);
        }}
        title="Editar plazo"
        description={`Factura folio ${selectedDocumentToEdit?.folio}.
          Modifica el plazo colocando la fecha de vencimiento,
          se calcularán automáticamente los días de plazo.
        `}
        selectedTerm={{
          expirationDate: getTermDate(selectedDocumentToEdit?.expirationDate),
        }}
        handleSubmit={({ expirationDate }) => {
          editDocument(
            { expirationDate: getTermInDays(expirationDate) },
            selectedReceiver.receiverIdentifier,
            selectedDocumentToEdit.id
          );
          setOpenEditDialog(null);
        }}
      />
    </>
  );
};
