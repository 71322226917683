/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';

import {
  defaultValue,
  fixDecimals,
  getDaysOfIssueInDays,
  getTermInDays,
  percentageFormat,
} from '../../../../commons/utils';
import { t13s } from '../../../../../translationKeys';
import { BUSINESS_STATUS } from '../../../../commons/constants/main';
import { OPERATION_TYPE } from '../../../../commons/constants/operations';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import StatusChip from '../../../../../components/elements/chips/StatusChip';
import { useSelectedPayroll } from '../../../adapters/store/payroll';

export const GET_COLUMNS = ({
  country,
  handleOpenInvoiceDetail,
  onEditRate,
  onEditEffectiveFundRate,
  onEditTerm,
  t,
}) => {
  const selectedPayroll = useSelectedPayroll();
  const disableEdition = () => !!selectedPayroll?.closedAt;

  return [
    {
      title: 'Folio',
      align: 'left',
      field: 'invoice.folio',
      width: '150px',
      type: 'numeric',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          align="left"
          onClick={() => handleOpenInvoiceDetail(rowData?.id)}
        >
          <Box fontWeight="fontWeightBold">
            {defaultValue(rowData?.invoice?.folio)}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Emisor',
      align: 'left',
      field: 'invoice.issuer',
      type: 'string',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(rowData?.invoice?.issuer)}
        </Typography>
      ),
    },
    {
      title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Emisor`,
      align: 'left',
      field: 'invoice.issuerId',
      width: '230px',
      type: 'string',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(rowData?.invoice?.issuerId)}
        </Typography>
      ),
    },
    {
      title: 'Plazo',
      align: 'left',
      field: 'term',
      type: 'date',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          color={disableEdition() ? 'textPrimary' : 'secondary'}
          disabled={disableEdition()}
          align="left"
          onClick={() => {
            onEditTerm(rowData);
          }}
        >
          <Box
            fontWeight={
              disableEdition() ? 'fontWeightRegular' : 'fontWeightBold'
            }
          >
            {defaultValue(getTermInDays(rowData?.term))}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Días de Emisión',
      align: 'left',
      field: 'invoice.daysOfIssue',
      type: 'date',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(getDaysOfIssueInDays(rowData?.invoice?.daysOfIssue))}
        </Typography>
      ),
    },
    {
      title: 'Tipo Operación',
      align: 'left',
      field: 'invoice.operationType',
      type: 'string',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(OPERATION_TYPE[rowData?.invoice?.operationType]?.label)}
        </Typography>
      ),
    },
    {
      title: 'Monto bruto',
      align: 'left',
      field: 'invoice.amount',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          <CountryFormatHelper
            value={rowData?.invoice?.amount}
            countryId={country}
            variant="currency"
          />
        </Typography>
      ),
    },
    {
      title: 'Sector',
      align: 'left',
      field: 'invoice.businessActivity',
      type: 'string',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(rowData?.invoice?.businessActivity)}
        </Typography>
      ),
    },
    {
      title: 'Tasa Originación',
      align: 'left',
      field: 'invoice.originationRate',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(fixDecimals(rowData?.invoice?.originationRate))}
        </Typography>
      ),
    },
    {
      title: 'Tasa Fondo',
      align: 'left',
      field: 'rate',
      type: 'numeric',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          color={disableEdition() ? 'textPrimary' : 'secondary'}
          disabled={disableEdition()}
          align="left"
          onClick={() => onEditRate(rowData)}
        >
          <Box
            fontWeight={
              disableEdition() ? 'fontWeightRegular' : 'fontWeightBold'
            }
          >
            {defaultValue(fixDecimals(rowData?.rate))}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Intereses Fondo',
      align: 'left',
      field: 'rate',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          <CountryFormatHelper
            value={rowData?.interest}
            countryId={country}
            variant="currency"
          />
        </Typography>
      ),
    },
    {
      title: 'Tasa Costo Fondo Efectiva',
      align: 'left',
      field: 'rate',
      type: 'numeric',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          color={disableEdition() ? 'textPrimary' : 'secondary'}
          disabled={disableEdition()}
          align="left"
          onClick={() => onEditEffectiveFundRate(rowData)}
        >
          <Box
            fontWeight={
              disableEdition() ? 'fontWeightRegular' : 'fontWeightBold'
            }
          >
            {defaultValue(fixDecimals(rowData?.effectiveFundCostRate))}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Tasa Costo Capital Xepelin',
      align: 'left',
      field: 'rate',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(fixDecimals(rowData?.capitalCostRate))}
        </Typography>
      ),
    },
    {
      title: 'Dicom',
      align: 'left',
      field: 'issuerDebt',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          <CountryFormatHelper
            value={rowData?.issuerDebt}
            countryId={country}
            variant="currency"
          />
        </Typography>
      ),
    },
    {
      title: 'Order ID',
      align: 'left',
      field: 'invoice.orderId',
      type: 'numeric',
      render: rowData => (
        <Link
          target="_blank"
          href={`/${country?.toLowerCase()}/orders/${
            rowData?.invoice?.orderId
          }`}
          variant="body1"
          align="left"
        >
          <Box fontWeight="fontWeightBold">
            {defaultValue(rowData?.invoice?.orderId)}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Estado BO',
      align: 'left',
      field: 'invoice.status',
      type: 'string',
      render: rowData => (
        <StatusChip
          status={rowData?.invoice?.status}
          entityType="invoice"
          size="small"
        />
      ),
    },
    {
      title: 'Estado negocio',
      align: 'left',
      field: 'status',
      type: 'string',
      render: rowData => (
        <Typography component="div" color="textPrimary">
          <Box fontWeight="fontWeightBold">
            {BUSINESS_STATUS[rowData?.status]?.label}
          </Box>
        </Typography>
      ),
    },
  ];
};

export const GET_RECEIVER_SUMMARY = ({ receiver, t, country }) => [
  {
    label: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)}: `,
    value: receiver?.receiverId,
  },
  {
    label: 'Nº Emisores:',
    value: receiver?.issuersNumber,
  },
  {
    label: 'N° Facturas:',
    value: receiver?.invoicesNumber,
  },
  {
    label: 'Monto bruto',
    value: (
      <CountryFormatHelper
        value={receiver?.amount}
        countryId={country}
        variant="currency"
      />
    ),
  },
  {
    label: 'Plazo Ponderado:',
    value: fixDecimals(receiver?.averageTerm),
  },
  {
    label: 'Score Ponderado:',
    value: fixDecimals(receiver?.averageScore),
  },
  {
    label: 'Tasa Fondo Ponderada:',
    value: percentageFormat(receiver?.averageFundRate),
  },
];
