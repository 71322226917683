import React from 'react';
import PropTypes from 'prop-types';
import { Grid, styled, Link, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FileCopyOutlined } from '@material-ui/icons';
import { Text } from '../../../commons/formatters';
import useOrderDetails from '../../../pages/PaymentsDetails/useOrderDetails';
import { useUtils } from '../PayrollInvoices/InvoicesTable/hooks';
import { t13s } from '../../../../translationKeys';
import { ORDER_PAYMENT_TYPE } from '../../../commons/constants/order';

const CustomGridItem = styled(Grid)({
  marginRight: 16,
  display: 'flex',
});

const CustomLink = styled(Link)({
  cursor: 'pointer',
});

const SummaryDetails = ({ countryId, orderId, businessId, paymentType }) => {
  const history = useHistory();
  const { order } = useOrderDetails();
  const { copyToClipboard } = useUtils();
  const { t } = useTranslation();

  return (
    <Grid container>
      <CustomGridItem item xs>
        <Text fontWeight="bold" variant="subtitle1">
          ID Operación:&nbsp;
        </Text>
        <Text variant="subtitle1">{orderId}</Text>
      </CustomGridItem>
      <CustomGridItem item>
        <Text fontWeight="bold" variant="subtitle1">
          Tipo de pago:&nbsp;
        </Text>
        <Text variant="subtitle1">{ORDER_PAYMENT_TYPE[paymentType]}</Text>
      </CustomGridItem>
      <CustomGridItem item>
        <Text fontWeight="bold" variant="subtitle1">
          ID empresa:&nbsp;
        </Text>
        <Text component="div" variant="subtitle1">
          <CustomLink
            onClick={() =>
              history.push(`/${countryId.toLowerCase()}/business/${businessId}`)
            }
          >
            {businessId}
          </CustomLink>
        </Text>
      </CustomGridItem>
      <CustomGridItem item>
        <Text fontWeight="bold" variant="subtitle1">
          {t(t13s.LABEL.BUSINESS_IDENTIFIER)}:&nbsp;
        </Text>
        <Text variant="subtitle1">{order.businessIdentifier}</Text>
        <Tooltip title={`Copiar ${t(t13s.LABEL.BUSINESS_IDENTIFIER)}`}>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              copyToClipboard(order.businessIdentifier);
            }}
          >
            &nbsp;
            <FileCopyOutlined style={{ fontSize: 14 }} />
          </Link>
        </Tooltip>
      </CustomGridItem>
    </Grid>
  );
};

SummaryDetails.propTypes = {
  countryId: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  paymentType: PropTypes.string.isRequired,
  businessId: PropTypes.number.isRequired,
};

export default SummaryDetails;
