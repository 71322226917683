import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { CalculateFees } from '../../../commons/interfaces/ARInterfaces';

const calculateFees = async (
  businessId: number,
  orderType: string,
  orderInvoices: number[]
) => {
  try {
    const { data } = await axios.post<CalculateFees>(
      `/api/business/${businessId}/calculateFees?orderType=${orderType}`,
      {
        orderInvoices,
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default calculateFees;
