import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, styled } from '@material-ui/core';
import { CustomSwitch, CustomText, TitleGrid } from './styles';
import { Text } from '../../../../../commons/formatters';

const CustomFormControlLabel = styled(FormControlLabel)({
  marginBottom: 0,
});

const AttributeSwitch = ({ title, value, handler }) => {
  const getSwitchLabel = () => {
    if (title === 'Tipo de payment') {
      return value ? 'Con cesión' : 'Sin cesión';
    }
    return value ? 'Activo' : 'Inactivo';
  };

  return (
    <Grid container direction="column">
      <TitleGrid item>
        <Text fontWeight="bold" variant="subtitle1">
          {title}
        </Text>
      </TitleGrid>

      <Grid item container>
        <CustomFormControlLabel
          control={
            <CustomSwitch checked={value} onClick={handler} size="small" />
          }
          label={<CustomText isActive={value}>{getSwitchLabel()}</CustomText>}
        />
      </Grid>
    </Grid>
  );
};

AttributeSwitch.propTypes = {
  switchLabels: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handler: PropTypes.func.isRequired,
};

export default AttributeSwitch;
