import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const XtreeDefault = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_1153_27257)">
        <path d="M18.7 18.9L18 19.6L17.7 19.9L13.8 16H13.3V19C13.3 19.5 12.8 20 12.3 20C11.8 20 11.3 19.6 11.3 19V16H7.30002C6.50002 16 5.80002 15.7 5.20002 15.2C4.60002 14.6 4.20002 13.8 4.30002 13C4.30002 12.4 4.50002 11.8 4.80002 11.4C5.10002 10.9 5.60002 10.5 6.10002 10.3C5.80002 9.8 5.70002 9.3 5.70002 8.8C5.70002 8.6 5.80002 8.4 5.90002 8.2L3.80002 6L4.30002 5.5L4.80002 5L6.30002 6.5L6.80002 7L18.7 18.9Z" />
        <path d="M4.20001 5.5L4.10001 5.7L4.20001 5.5Z" />
        <path d="M20.3 13C20.3 13.8 19.9 14.6 19.3 15.2C18.9 15.6 18.4 15.8 17.8 15.9L8.40002 6.49999C8.70002 6.49999 9.00002 6.59999 9.30002 6.69999C9.30002 5.99999 9.70002 5.29999 10.3 4.79999C11.4 3.79999 13.2 3.79999 14.3 4.79999C14.8 5.29999 15.2 5.99999 15.3 6.69999C15.8 6.49999 16.3 6.39999 16.8 6.59999C17.3 6.69999 17.7 6.99999 18.1 7.39999C18.4 7.79999 18.7 8.19999 18.7 8.79999C18.8 9.29999 18.7 9.89999 18.4 10.3C18.9 10.5 19.4 10.9 19.7 11.4C20.1 11.9 20.3 12.4 20.3 13Z" />
        <path d="M6.80001 7L4.80001 5L4.20001 5.5L4.80001 5L6.80001 7Z" />
        <path d="M18.7 18.9L15.8 16L18.7 18.9Z" />
      </g>
      <defs>
        <clipPath id="clip0_1153_27257">
          <rect
            width="16.6"
            height="16"
            fill="white"
            transform="translate(3.70001 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default XtreeDefault;
