/* eslint-disable no-unused-vars */
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { getErrorCode } from '../../../../helpers/handleErrors';
import { newSimulationService } from '../services';

const useNewSimulation = () => {
  const dispatch = useDispatch();
  const {
    mutate: newSimulation,
    data: orderSimulation,
    isLoading: orderSimulationIsLoading,
  } = useMutation(
    ({ orderId, body }) => newSimulationService({ orderId, body }),
    {
      onError: error => {
        const errorCode = getErrorCode(error);
        dispatch(enqueueSnackbar(errorCode, { variant: 'error' }));
      },
    }
  );

  return {
    newSimulation,
    orderSimulation,
    orderSimulationIsLoading,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useNewSimulation };
