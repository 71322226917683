import React, { ReactNode, FC } from 'react';
import { Skeleton as SkeletonMui, SkeletonProps } from '@material-ui/lab';

interface Props extends SkeletonProps {
  isLoading?: boolean;
  children: JSX.Element[] | JSX.Element | ReactNode;
}

const Skeleton: FC<Props> = ({ isLoading = false, children, ...rest }) => {
  return <>{isLoading ? <SkeletonMui {...rest} /> : children}</>;
};

export default Skeleton;
