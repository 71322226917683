import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { InputAdornment, IconButton, Button } from '@material-ui/core';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import BaseDrawer from '../../../../components/drawers/BaseDrawer';
import { Delete } from '../../../../components/icons';
import {
  LabelInput,
  ContainerInput,
  Divider,
  ContainerListResponsibles,
  TextBold,
  ContainerSection,
  TextSubTitle,
  ContainerRow,
  RowText,
  TextSmall,
} from './style';
import { Responsible } from '../../interfaces/collection';
import Search from '../../../../components/icons/Search';
import Add from '../../../../components/icons/Add';
import AlertForm from '../../../../components/elements/AlertForm';
import Skeleton from '../Skeleton';
import {
  deleteResponsible,
  fetchResponsibles,
  fetchAllResponsibles,
  createResponsible,
} from '../../../../actions/responsiblesActions';

interface ResponsibleToList {
  section: string;
  listResponsibles: Responsible[];
}

interface Props {
  isOpen: boolean;
  sourceId: number;
  sourceType: string;
  handleCloseDrawer: () => void;
}

const ResponsiblesDrawer = ({
  isOpen,
  sourceId,
  sourceType,
  handleCloseDrawer,
}: Props) => {
  const dispatch = useDispatch();
  const [responsibleSelected, setResponsibleSelected] =
    useState<Responsible | null>(null);
  const [newResponsibles, setNewResponsibles] = useState<ResponsibleToList[]>(
    []
  );

  const {
    allResponsibles,
    fetchAllResponsiblesIsLoading,
    responsibles,
    fetchResponsiblesIsLoading,
    createResponsibleIsLoading,
    createResponsibleError,
    deleteResponsibleIsLoading,
    deleteResponsibleError,
  } = useSelector((state: RootStateOrAny) => state.responsibles);

  useEffect(() => {
    if (sourceId && sourceType) {
      dispatch(fetchAllResponsibles());
      dispatch(fetchResponsibles(sourceId, sourceType));
    }
  }, []);
  const tranformValuesToList = (values: Responsible[]) => {
    const getSectionValues = values.map(value => value.role.join(', '));
    const sectionValues = Array.from(new Set(getSectionValues));
    return sectionValues.map(value => ({
      section: value,
      listResponsibles: values.filter(x => x.role.join(', ') === value),
    }));
  };

  useEffect(() => {
    if (responsibles?.length) {
      const getNewResponsibles: ResponsibleToList[] =
        tranformValuesToList(responsibles);
      setNewResponsibles(getNewResponsibles);
    } else {
      setNewResponsibles([]);
    }
  }, [responsibles]);

  return (
    <BaseDrawer
      isOpen={isOpen}
      title="Lista de responsables"
      handleCloseDrawer={handleCloseDrawer}
    >
      <ValidatorForm
        onSubmit={() =>
          dispatch(createResponsible(sourceId, sourceType, responsibleSelected))
        }
      >
        <LabelInput>Agregar responsable</LabelInput>
        <ContainerInput>
          <Skeleton
            isLoading={fetchAllResponsiblesIsLoading}
            variant="rect"
            width={440}
            height={35}
            style={{
              marginBottom: 15,
              borderRadius: 16,
            }}
          >
            <Autocomplete
              fullWidth
              disabled={
                deleteResponsibleIsLoading || createResponsibleIsLoading
              }
              options={allResponsibles}
              getOptionLabel={(option: Responsible) =>
                option?.fullname
                  ? `${option?.fullname} - ${option?.role.join(', ')}`
                  : ''
              }
              value={responsibleSelected}
              onChange={(_e_, value) => {
                setResponsibleSelected(value);
              }}
              renderInput={params => (
                // @ts-ignore
                <TextValidator
                  variant="outlined"
                  {...params}
                  value={responsibleSelected?.id}
                  label="Ingrese la persona responsable"
                  placeholder="Ingrese la persona responsable"
                  type="string"
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  InputProps={{
                    ...params.InputProps,
                    style: { padding: '0px 0px 0px 5px' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Search fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Skeleton>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            fullWidth
            type="submit"
            disabled={
              fetchAllResponsiblesIsLoading ||
              createResponsibleIsLoading ||
              deleteResponsibleIsLoading
            }
          >
            Agregar Responsable
          </Button>
        </ContainerInput>
      </ValidatorForm>
      <Divider />
      <ContainerListResponsibles>
        <TextBold>Lista de responsables</TextBold>
        <Skeleton
          isLoading={fetchResponsiblesIsLoading}
          variant="rect"
          width={440}
          height={70}
        >
          {newResponsibles?.map(({ section, listResponsibles }) => (
            <ContainerSection key={section}>
              <TextSubTitle>{section.toUpperCase()}</TextSubTitle>
              {listResponsibles.map(({ id, fullname }) => (
                <ContainerRow key={id}>
                  <RowText>{fullname}</RowText>
                  <IconButton onClick={() => dispatch(deleteResponsible(id))}>
                    <Delete />
                  </IconButton>
                </ContainerRow>
              ))}
            </ContainerSection>
          ))}
        </Skeleton>
      </ContainerListResponsibles>

      {(createResponsibleError || deleteResponsibleError) && (
        <AlertForm
          message={createResponsibleError || deleteResponsibleError}
          variant="error"
        />
      )}

      <TextSmall>
        Estos son todas las personas registradas el día de hoy
      </TextSmall>
    </BaseDrawer>
  );
};

export default ResponsiblesDrawer;
