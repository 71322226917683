import React, { FC, ChangeEvent, ReactNode } from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@material-ui/core';
import { Pagination as IPagination } from '../interfaces/ARInterfaces';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20];

const PaginationWrapper = styled(Grid)({
  gap: 35,
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px 0',
});

const RowsPerPageWrapper = styled(Grid)({
  gap: 10,
  alignItems: 'center',
});

interface Props {
  page: number;
  itemsPerPage: number;
  handleChangePage: (newPage: number) => void;
  handleChangeItemsPerPage: (
    event: ChangeEvent<{
      name?: string;
      value: any;
    }>,
    child: ReactNode
  ) => void;
  dataPagination: IPagination;
  fetchIsLoading: boolean;
}

const defaultDataPagination = {
  pageSize: 10,
  totalData: 0,
  totalPage: 0,
};

const Pagination: FC<Props> = ({
  page,
  itemsPerPage,
  dataPagination = {},
  fetchIsLoading = false,
  handleChangePage,
  handleChangeItemsPerPage,
}) => {
  const { totalData, totalPages } = dataPagination ?? defaultDataPagination;
  const from = page * itemsPerPage - (itemsPerPage - 1);
  const to = Math.min(page * itemsPerPage, totalData || 0) || 1;

  return (
    <PaginationWrapper container>
      <Grid item>
        <RowsPerPageWrapper container>
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Registros por página
            </Typography>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page"
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
                disabled={fetchIsLoading}
              >
                {ROWS_PER_PAGE_OPTIONS.map(option => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected={option === itemsPerPage}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </RowsPerPageWrapper>
      </Grid>
      <Grid item>
        <MaterialPagination
          disabled={fetchIsLoading}
          count={totalPages}
          page={page}
          onChange={(_evt, newPage) => handleChangePage(newPage)}
          size="small"
          color="secondary"
        />
      </Grid>
      <Grid item>
        <Typography variant="body1" color="textPrimary">
          {from}-{to} de {totalData}
        </Typography>
      </Grid>
    </PaginationWrapper>
  );
};

export default Pagination;
