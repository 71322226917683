import { useParams } from 'react-router-dom';
import {
  useFetchInvoices,
  useOrder,
  useOrderAttributes,
  useOrderSummary,
} from '../../details/infrastructure/store';

const useOrderDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { order, isLoading: orderIsLoading } = useOrder(id);
  const { orderSummary, isLoading: orderSummaryIsLoading } =
    useOrderSummary(id);
  const { isLoading: orderAttributesIsLoading } = useOrderAttributes(id);
  useFetchInvoices(id);

  const isLoading =
    orderIsLoading || orderSummaryIsLoading || orderAttributesIsLoading;

  return {
    order,
    isLoading,
    orderSummary,
  };
};

export default useOrderDetails;
