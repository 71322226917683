import { makeStyles } from '@material-ui/core';

import {
  ALT_BORDER_COLOR,
  DRAWER_SECONDARY,
} from '../../../../../../theme/otherColors';

export const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '380px',
    background: 'white',
    '& p': {
      margin: 0,
      paddingTop: 12,
      paddingBottom: 12,
      '&:hover': {
        backgroundColor: '#E8E6EF',
      },
    },
  },
  appBar: {
    padding: '20px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    position: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      bottom: 0,
      left: 20,
      width: 'calc(100% - 40px)',
      height: 1,
      borderBottom: '1px solid #CDCAD8',
    },
  },
  toolBar: {
    minHeight: 'auto',
    padding: 0,
  },
  navTitle: {
    flex: '1',
  },
  drawerTitle: {
    fontSize: 18,
  },
  container: {
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 700,
    padding: '25px 20px',
    backgroundColor: 'white',
    '& p span': {
      float: 'right',
      color: '#312F37',
      textAlign: 'right',
    },
    '& p': {
      color: '#8D8A96',
    },
  },
  subTitle: {
    display: 'block',
    fontSize: 16,
    fontWeight: 400,
    color: '#8D8A96',
    marginTop: 0,
    marginBottom: 5,
  },
  companyName: {
    fontSize: 20,
    fontWeight: 700,
    color: '#312F37',
    marginTop: 0,
    marginBottom: 5,
  },
  companyIdentifier: {
    marginTop: 0,
    marginBottom: 30,
    fontSize: 16,
  },
  within: {
    paddingLeft: '12px',
    '&::before': {
      content: '"-"',
    },
  },
  containerSearchBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  containerBody: {
    display: 'flex',
  },
  containerOperationResume: {
    padding: '30px 20px',
    background: '#fff',
    borderRadius: 10,
    width: 300,
    minWidth: 260,
    height: 345,
    border: `1px solid ${ALT_BORDER_COLOR}`,
    marginRight: 15,
  },
  titleOperationResume: {
    marginBottom: 20,
  },
  selectFundOperationResume: {
    marginBottom: 10,
    width: '100%',
  },
  informationFundOperationResume: {
    marginBottom: 20,
    width: '100%',
  },
  informationFundContainerValues: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  spacingBottom: {
    marginBottom: 8,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  totalAmountContainer: {
    marginBottom: 25,
    background: '#1C1732',
    borderRadius: 7,
    padding: '5px 0px',
    width: '100%',
  },
  containerTables: {
    width: '100%',
  },
  hasEllipsis: {
    width: 230,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
