import React, { FC, useEffect, useRef } from 'react';
import { IconButton, Button } from '@material-ui/core';
import {
  Drawer,
  ContainerRoot,
  ContainerHeader,
  Title,
  ContainerBody,
  ContainerFooter,
  EmptyText,
} from './styles';
import useNotifications from '../../elements/Notifications/hooks/useNotifications';
import { Close } from '../../../components/icons';
import Notification from './ui/Notification/Notification';
import { INotification } from '../../elements/Notifications/interfaces';
import NotificationsDrawerSkeleton from './ui/NotificationsDrawerSkeleton';

interface Props {
  isOpen: boolean;
  handleCloseDrawer: () => void;
}

const NotificationsDrawer: FC<Props> = ({ isOpen, handleCloseDrawer }) => {
  const firstNotification = useRef<HTMLDivElement | null>(null);

  const {
    getUserNotificationsIsLoading,
    notifications,
    getUserNotifications,
    parseNotificationData,
  } = useNotifications();

  useEffect(() => {
    getUserNotifications();
  }, [getUserNotifications]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
      <ContainerRoot>
        <ContainerHeader>
          <Title>Notificaciones</Title>
          <IconButton onClick={handleCloseDrawer}>
            <Close />
          </IconButton>
        </ContainerHeader>
        <NotificationsDrawerSkeleton isLoading={getUserNotificationsIsLoading}>
          <>
            {notifications.length === 0 && (
              <EmptyText>No tienes notificaciones en este momento</EmptyText>
            )}

            {notifications?.length > 0 && (
              <>
                <ContainerBody>
                  {notifications?.map(
                    (notification: INotification, index: number) => (
                      <div
                        key={notification.id}
                        ref={index === 0 ? firstNotification : null}
                      >
                        <Notification
                          notification={parseNotificationData(notification)}
                        />
                      </div>
                    )
                  )}
                </ContainerBody>
                <ContainerFooter>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() =>
                      firstNotification?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                  >
                    Volver a la última notificación
                  </Button>
                </ContainerFooter>
              </>
            )}
          </>
        </NotificationsDrawerSkeleton>
      </ContainerRoot>
    </Drawer>
  );
};

export default NotificationsDrawer;
