import BankAccountSelectorDrawerStore from './BankAccountSelectorDrawerStore';

const useIsShownBankAccountSelectorDrawer = () =>
  BankAccountSelectorDrawerStore(state => state.isShown);

const useShowBankAccountSelectorDrawer = () =>
  BankAccountSelectorDrawerStore(state => state.show);

const useHideBankAccountSelectorDrawer = () =>
  BankAccountSelectorDrawerStore(state => state.hide);

export {
  useIsShownBankAccountSelectorDrawer,
  useShowBankAccountSelectorDrawer,
  useHideBankAccountSelectorDrawer,
};
