import { Typography, Box, makeStyles, styled } from '@material-ui/core';

const useStyles = makeStyles({
  inputMultiLine: {
    '& fieldset': {
      borderRadius: 17,
    },
  },
  rootDrawer: {
    background: '#fff',
  },
  textSubheading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  textSubheadingBold: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

const customColumnHeader = {
  fontSize: 12,
};

const ContainerRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  width: 393,
});

const ContainerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 10px',
  marginBottom: 10,
});
const ContainerTitle = styled(Box)({
  display: 'flex',
});
const Title = styled(Typography)({
  fontSize: 20,
  fontWeight: 'bold',
});

const ContainerBody = styled(Box)({
  margin: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
});

const ContainerExclamation = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  background: '#F3D2D0',
  color: '#D92F24',
  borderRadius: '50%',
  marginRight: 8,
});

export {
  useStyles,
  ContainerRoot,
  ContainerHeader,
  ContainerTitle,
  Title,
  ContainerBody,
  ContainerExclamation,
  customColumnHeader,
};
