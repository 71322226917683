import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useState } from 'react';
import {
  GET_USER_NOTIFICATIONS,
  NOTIFICATIONS_SUSCRIPTION,
  UPDATE_BELL_STATUS,
  UPDATE_NOTIFICATION_STATUS,
} from '../infrastructure/service/notificationQuerys';
import {
  INotification,
  NotificationStatus,
  INotificationPayload,
  INotificationParsed,
} from '../interfaces';
import { useOktaAuth } from '@okta/okta-react';

const useNotifications = () => {
  const { authState } = useOktaAuth();

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<string>('');
  const [bellStatus, setBellStatus] = useState<NotificationStatus>(
    NotificationStatus.Checked
  );

  // USER SUBSCRIBE TO LISTEN NOTIFICATIONS
  const { loading: subscribeIsLoading } = useSubscription(
    NOTIFICATIONS_SUSCRIPTION,
    {
      variables: { id: authState?.idToken?.claims?.email },
      onSubscriptionData({ subscriptionData }) {
        setBellStatus(
          subscriptionData?.data?.onNotificationCreated?.status ||
            NotificationStatus.Checked
        );
        setNotifications(
          subscriptionData?.data?.onNotificationCreated?.subscriptions || []
        );
      },
    }
  );

  // FETCH NOTIFICATIONS FROM SUBSCRIPTION (REALTIME)
  const [getUserNotifications, { loading: getUserNotificationsIsLoading }] =
    useLazyQuery(GET_USER_NOTIFICATIONS, {
      variables: { subscriptionId: authState?.idToken?.claims?.email },
      onCompleted(data) {
        setBellStatus(
          data?.notificationsById?.status || NotificationStatus.Checked
        );
        setNotifications(data?.notificationsById?.subscriptions || []);
      },
    });

  // UPDATE BELL STATUS
  const [updateBellStatus, { loading: updateBellStatusIsLoading }] =
    useMutation(UPDATE_BELL_STATUS, {
      variables: { subscriptionId: authState?.idToken?.claims?.email },
      onCompleted() {
        setBellStatus(NotificationStatus.Checked);
      },
    });

  // UPDATE NOTIFICATION STATUS
  const [updateNotificationStatus, { loading: updateNotificationIsLoading }] =
    useMutation(UPDATE_NOTIFICATION_STATUS);

  const parseNotificationData = (
    notification: INotification
  ): INotificationParsed => {
    const notificationPayload: INotificationPayload = JSON.parse(
      String(notification.payload)
    );

    return {
      notificationId: notification.id,
      createdAt: notification.createdAt,
      status: notification.status,
      subscriptionType: notification.subscriptionType,
      ...notificationPayload,
    };
  };

  return {
    bellStatus,
    notifications,
    selectedNotification,
    subscribeIsLoading,
    getUserNotificationsIsLoading,
    updateBellStatusIsLoading,
    updateNotificationIsLoading,
    updateBellStatus,
    getUserNotifications,
    setSelectedNotification,
    updateNotificationStatus,
    parseNotificationData,
  };
};

export default useNotifications;
