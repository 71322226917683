import { useState, useCallback } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import XLSX from 'xlsx';
import useStatuses from '../../../hooks/useStatuses';
import { format } from 'date-fns';
import { getDiffDays } from '../../commons/utils/dates';
import { getCurrencyFormat } from '../../../features/credit-line/utils';
import { DEBT_EXTENSION } from '../../../helpers/Constants';
import { operationTypes } from '../../commons/utils/constants';
import { PayerDebts } from '../../commons/interfaces/payerDebts';

const useExportPayersInXLS = () => {
  const { getStatuses } = useStatuses();
  const { country } = useSelector((state: RootStateOrAny) => state.config);

  const handleExportXLS = (data: PayerDebts) => {
    const wb = XLSX.utils.book_new();
    let excelReport;
    const prevExcelReport = data.payerDebts.map(item => ({
      'ID Ops': item.orderInovice.orderID,
      folio: item.folio,
      'Estado Factura': getStatuses('factura').find(
        st => st.key === item.orderInovice.status
      )?.label,
      Renegociacion:
        item.extensions &&
        item.extensions.type === 'OFFICIAL' &&
        getStatuses('debt').find(st => st.key === DEBT_EXTENSION)?.label,
      'Monto Factura': getCurrencyFormat(item.amount, country),
      'Fecha Emision': format(
        new Date(item.issueDate),
        'dd-MM-yyyy'
      ).toString(),
      'RUT/RFC Emisor': item.issuer || '-',
      Emisor: item.issuing,
      Fondo: item.fund.name || '-',
      Custodio: item.custodian.name || '-',
      'Fecha Vencimiento': format(
        new Date(item.orderInovice.expirationDate),
        'dd-MM-yyyy'
      ).toString(),
      'Fecha promesa pago': item.extensions?.newExpirationDate
        ? format(new Date(item.extensions.newExpirationDate), 'dd-MM-yyyy')
        : 'Sin fecha',
      'Dias de mora': getDiffDays(item.orderInovice.expirationDate),
      'Tipo de Ops': operationTypes[item.order.orderType],
    }));

    excelReport = prevExcelReport;

    if (country === 'MX') {
      excelReport = prevExcelReport.map(item => {
        const { Custodio, ...newItem } = item;
        return newItem;
      });
    }

    const ws = XLSX.utils.json_to_sheet(excelReport);
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    XLSX.writeFile(wb, 'MyExcel.xlsx');
  };
  return { handleExportXLS };
};

export default useExportPayersInXLS;
