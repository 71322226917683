import axios from 'axios';
import {
  FETCH_AGREEMENTS_START,
  FETCH_AGREEMENTS_SUCCESS,
  FETCH_AGREEMENTS_FAILURE,
  REGISTER_AGREEMENT_START,
  REGISTER_AGREEMENT_SUCCESS,
  REGISTER_AGREEMENT_FAILURE,
  EDIT_AGREEMENT_START,
  EDIT_AGREEMENT_SUCCESS,
  EDIT_AGREEMENT_FAILURE,
  FETCH_AGREEMENTS_GROUPS_START,
  FETCH_AGREEMENTS_GROUPS_SUCCESS,
  FETCH_AGREEMENTS_GROUPS_FAILURE,
  FETCH_AGREEMENTS_CONTACTS_START,
  FETCH_AGREEMENTS_CONTACTS_SUCCESS,
  FETCH_AGREEMENTS_CONTACTS_FAILURE,
  REGISTER_AGREEMENTS_CONTACTS_START,
  REGISTER_AGREEMENTS_CONTACTS_SUCCESS,
  REGISTER_AGREEMENTS_CONTACTS_FAILURE,
  DELETE_AGREEMENTS_CONTACTS_START,
  DELETE_AGREEMENTS_CONTACTS_SUCCESS,
  DELETE_AGREEMENTS_CONTACTS_FAILURE,
  EDIT_AGREEMENTS_CONTACTS_START,
  EDIT_AGREEMENTS_CONTACTS_SUCCESS,
  EDIT_AGREEMENTS_CONTACTS_FAILURE,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys';

export const fetchAgreements =
  ({ page, limit, field, searchInput }) =>
  dispatch => {
    dispatch({ type: FETCH_AGREEMENTS_START });
    axios
      .get(`/api/agreement`, {
        params: {
          page,
          limit,
          field,
          searchInput,
        },
      })
      .then(({ data: { data, pagination, agreementData } }) => {
        const resData = { agreementData: agreementData || data, pagination };
        dispatch({
          type: FETCH_AGREEMENTS_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_AGREEMENTS_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_AGREEMENTS_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchAgreementsGroups = () => dispatch => {
  dispatch({ type: FETCH_AGREEMENTS_GROUPS_START });
  axios
    .get('/api/agreementgroup')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_AGREEMENTS_GROUPS_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_AGREEMENTS_GROUPS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_AGREEMENT_GROUPS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const registerAgreement = agreementData => dispatch => {
  dispatch({ type: REGISTER_AGREEMENT_START });
  axios
    .post('/api/agreement', agreementData)
    .then(res => {
      const resData = res.data;
      dispatch({ type: REGISTER_AGREEMENT_SUCCESS, payload: resData });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.AGREEMENT_CREATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_AGREEMENT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateAgreementById = agreementData => dispatch => {
  dispatch({ type: EDIT_AGREEMENT_START });
  const { id, ...rest } = agreementData;
  axios
    .put(`/api/agreement/${id}`, { ...rest })
    .then(res => {
      const resData = res.data;
      dispatch({ type: EDIT_AGREEMENT_SUCCESS, payload: resData });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.AGREEMENT_UPDATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: EDIT_AGREEMENT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateSelectedAgreement = selectedAgreement => dispatch => {
  dispatch({
    type: 'UPDATE_SELECTED_AGREEMENT',
    payload: { selectedAgreement },
  });
};

export const resetAgreementFormDialog = () => dispatch => {
  dispatch({
    type: 'RESET_AGREEMENT_FORM_DIALOG',
  });
};

export const resetError = () => dispatch => {
  dispatch({
    type: 'RESET_AGREEMENT_ERROR',
  });
};

export const fetchAgreementsContacts = id => dispatch => {
  dispatch({ type: FETCH_AGREEMENTS_CONTACTS_START });
  axios
    .get(`/api/agreement/${id}/contactdetail`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_AGREEMENTS_CONTACTS_SUCCESS,
        payload: data?.contactData || data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_AGREEMENTS_CONTACTS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_AGREEMENT_CONTACTS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const registerAgreementsContacts =
  (sourceId, newContact) => dispatch => {
    dispatch({ type: REGISTER_AGREEMENTS_CONTACTS_START });
    axios
      .post(`/api/agreement/${sourceId}/contactdetail`, newContact)
      .then(({ data }) => {
        dispatch({
          type: REGISTER_AGREEMENTS_CONTACTS_SUCCESS,
          payload: data?.contact || data,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.AGREEMENT_CONTACT_CREATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: REGISTER_AGREEMENTS_CONTACTS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const editAgreementsContacts =
  (sourceId, id, agreementData) => dispatch => {
    dispatch({ type: EDIT_AGREEMENTS_CONTACTS_START });
    axios
      .put(`api/agreement/${sourceId}/contactdetail/${id}`, agreementData)
      .then(() => {
        dispatch({
          type: EDIT_AGREEMENTS_CONTACTS_SUCCESS,
          payload: { id, ...agreementData },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.AGREEMENT_CONTACT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: EDIT_AGREEMENTS_CONTACTS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const deleteAgreementsContacts = (agreementId, id) => dispatch => {
  dispatch({ type: DELETE_AGREEMENTS_CONTACTS_START });
  axios
    .delete(`/api/agreement/${agreementId}/contactdetail/${id}`)
    .then(() => {
      dispatch({
        type: DELETE_AGREEMENTS_CONTACTS_SUCCESS,
        payload: id,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.AGREEMENT_CONTACT_DELETED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: DELETE_AGREEMENTS_CONTACTS_FAILURE,
        payload: { errorCode },
      });
    });
};
