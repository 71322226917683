import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { usePayrollSelector } from '../../../adapters/store/payroll';
import * as Repo from './receiver.repo';
import { getReceiversUseCase } from '../useCases';

export const FETCH_RECEIVERS_KEY = 'GetPayrollReceiversByStatus';

export const useFetchReceivers = (filter, opts) => {
  const { payrollId } = useParams();
  const req = { payrollId, ...filter };
  const { data: receivers, ...rest } = useQuery(
    [FETCH_RECEIVERS_KEY, req],
    () => Repo.fetchReceivers(req),
    opts
  );
  const payrollSelector = usePayrollSelector();
  const memoizedReceivers = useMemo(
    () => getReceiversUseCase({ receivers, payrollSelector }),
    [receivers, payrollSelector]
  );

  return {
    ...rest,
    receivers: memoizedReceivers,
  };
};

export const useUpdateReceivers = opts => {
  const { payrollId } = useParams();

  return useMutation(req => Repo.updateReceivers({ payrollId, ...req }), opts);
};
