export const getReceiversUseCase = ({ receivers, payrollSelector }) => {
  const isChecked = receiver => {
    if (!payrollSelector?.[receiver.receiverIdentifier]) {
      return false;
    }

    return receiver.documentsIds.every(docu =>
      payrollSelector?.[receiver.receiverIdentifier].value.find(
        doc => doc.id === docu.id
      )
    );
  };

  const isIndeterminate = receiver => {
    if (!payrollSelector?.[receiver.receiverIdentifier]) {
      return false;
    }

    return receiver.documentsIds.some(docu =>
      payrollSelector?.[receiver.receiverIdentifier].value.find(
        doc => doc.id === docu.id
      )
    );
  };

  return receivers?.map(receiver => {
    const checked = isChecked(receiver);
    const indeterminate = checked ? false : isIndeterminate(receiver);

    return {
      ...receiver,
      tableData: {
        ...receiver.tableData,
        checked,
        indeterminate,
      },
    };
  });
};
