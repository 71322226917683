import { gql } from 'graphql-request';
import { GetStateDto as ClientGetStateDto } from '../../schemas';

export const getStatesQuery = gql`
  query States(
    $opsRequestMoreDocumentsDto: GetStateDTO!,
    $opsDeclinedDocsDto: GetStateDTO!,
    $legalRequestMoreDocumentsDto: GetStateDTO!,
    $legalDeclinedDocsDto: GetStateDTO!,
    $opsDocsInReviewDto: GetStateDTO!,
    $legalDocsInReviewDto: GetStateDTO!,
    $riskObjectionDto: GetStateDTO!,
    $riskLdcAssignedDto: GetStateDTO!,
    $riskReviewingDto: GetStateDTO!,
  ) {
    opsRequestMoreDocuments: state(dto: $opsRequestMoreDocumentsDto) {
      name
      workflowId
      id
    }
    opsDeclinedDocs: state(dto: $opsDeclinedDocsDto) {
      name
      workflowId
      id
    }
    legalRequestMoreDocuments: state(dto: $legalRequestMoreDocumentsDto) {
      name
      workflowId
      id
    }
    legalDeclinedDocs: state(dto: $legalDeclinedDocsDto) {
      name
      workflowId
      id
    }
    opsDocsInReview: state(dto: $opsDocsInReviewDto) {
      name
      workflowId
      id
    }
    legalDocsInReview: state(dto: $legalDocsInReviewDto) {
      name
      workflowId
      id
    }
    riskObjection: state(dto: $riskObjectionDto) {
      name
      workflowId
      id
    }
    riskLdcAssigned: state(dto: $riskLdcAssignedDto) {
      name
      workflowId
      id
    }
    riskReviewing: state(dto: $riskReviewingDto) {
      name
      workflowId
      id
    }
  }
`;

type State = {
  name: string;
  workflowId: string;
  id: string;
};

export type GetStatesQuery = {
  opsRequestMoreDocuments: State | null;
  opsDeclinedDocs: State | null;
  legalRequestMoreDocuments: State | null;
  legalDeclinedDocs: State | null;
  opsDocsInReview: State | null;
  legalDocsInReview: State | null;
  riskObjection: State | null;
  riskLdcAssigned: State | null;
  riskReviewing: State | null;
};

export type GetStatesDto = {
  opsRequestMoreDocumentsDto: ClientGetStateDto;
  opsDeclinedDocsDto: ClientGetStateDto;
  legalRequestMoreDocumentsDto: ClientGetStateDto;
  legalDeclinedDocsDto: ClientGetStateDto;
  opsDocsInReviewDto: ClientGetStateDto;
  legalDocsInReviewDto: ClientGetStateDto;
  riskObjectionDto: ClientGetStateDto;
  riskLdcAssignedDto: ClientGetStateDto;
  riskReviewingDto: ClientGetStateDto;
};
