import React from 'react';
import PropTypes from 'prop-types';
import XtreeBadge from '../../../../../../components/elements/XtreeBadge';

const XtreeStatus = ({ rowData }) => {
  return <XtreeBadge status={rowData.xtreeStatus} />;
};

XtreeStatus.propTypes = {
  rowData: PropTypes.shape({
    xtreeStatus: PropTypes.string,
  }).isRequired,
};

export default XtreeStatus;
