import { useQuery } from 'react-query';
import { OrderInvoice } from '../../interfaces';
import fetchCriticalOrderInvoicesInformation from '../service/fetchCriticalOrderInvoicesInformation';

interface Props {
  orderInvoiceIds: number[];
}

interface Response {
  criticalOrderInvoicesInformation: OrderInvoice[];
  isLoading: boolean;
}

const useFetchCriticalOrderInvoicesInformation = ({
  orderInvoiceIds,
}: Props): Response => {
  const { isLoading, isRefetching, data } = useQuery(
    ['fetchCriticalOrderInvoicesInformation', orderInvoiceIds],
    () => fetchCriticalOrderInvoicesInformation(orderInvoiceIds),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 0,
      retry: false,
    }
  );

  return {
    criticalOrderInvoicesInformation: data || [],
    isLoading: isLoading || isRefetching,
  };
};

export default useFetchCriticalOrderInvoicesInformation;
