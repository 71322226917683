import { Checkbox, Tooltip, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SuccessIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Clear';
import MaterialTable from 'material-table';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { settings } from '../../config/settings';
import { WALLET_ACTIVITIES } from '../../helpers/Constants';
import { dateFormatter } from '../../helpers/DateUtils';
import CountryFormatHelper from './CountryFormatHelper';

const useStyles = makeStyles({
  errorIcon: {
    fontSize: 'large',
    marginRight: 25,
  },
  successIcon: {
    fontSize: 'large',
    marginRight: 25,
  },
  cellSpacing: {
    marginRight: 30,
  },
});

const WalletRequestsTable = ({
  handleShowRequestStatusDialog,
  requests = [],
  loading,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const requestColumns = [
    {
      title: 'Fecha',
      field: 'date',
      type: 'date',
      render: row => {
        const { createdAt } = row;
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(createdAt)}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo',
      field: 'activity',
      type: 'string',
      render: row => {
        const { activity } = row;
        return (
          <Typography variant="body2" color="textPrimary">
            {WALLET_ACTIVITIES[activity] || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Monto',
      field: 'amount',
      render: row => {
        const { amount = 0 } = row;
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={amount}
              countryId="CL"
              variant="currency"
            />
          </Typography>
        );
      },
    },
    {
      title: 'Validador',
      align: 'center',
      render: rowData => {
        const { hasDebt, hasHardCollection, validator = null } = rowData;
        if (isApiGlobal) {
          const isBoolean = value => typeof value === 'boolean';
          let validatorMessage = 'Sin error';
          if (hasDebt || hasHardCollection) {
            validatorMessage = hasDebt
              ? 'Cliente tiene mora'
              : 'Tiene facturas en Estado Crítico';
          }

          if (!isBoolean(hasDebt) || !isBoolean(hasHardCollection)) {
            return (
              <Typography
                className={classes.cellSpacing}
                variant="body2"
                color="textPrimary"
              >
                -
              </Typography>
            );
          }
          return (
            <Tooltip title={validatorMessage} placement="bottom">
              {hasDebt || hasHardCollection ? (
                <ErrorIcon color="error" className={classes.errorIcon} />
              ) : (
                <Box color="success.main">
                  <SuccessIcon className={classes.successIcon} />
                </Box>
              )}
            </Tooltip>
          );
        }
        if (validator) {
          return (
            <Tooltip title={validator} placement="bottom">
              {validator === 'Sin error' ? (
                <Box color="success.main">
                  <SuccessIcon className={classes.successIcon} />
                </Box>
              ) : (
                <ErrorIcon color="error" className={classes.errorIcon} />
              )}
            </Tooltip>
          );
        }
        return (
          <Typography
            className={classes.cellSpacing}
            variant="body2"
            color="textPrimary"
          >
            -
          </Typography>
        );
      },
    },
    {
      title: 'Solicitud vigente',
      field: 'status',
      align: 'center',
      render: walletRequest => {
        return (
          <Checkbox
            className={classes.cellSpacing}
            checked
            onClick={() => handleShowRequestStatusDialog(walletRequest)}
          />
        );
      },
    },
  ];

  return (
    <MaterialTable
      columns={requestColumns}
      data={requests}
      isLoading={loading}
      style={{
        overflow: 'hidden',
        boxShadow: 'none',
        width: '100%',
        borderRadius: 17,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No hay solicitudes para mostrar',
        },
      }}
      components={{
        Toolbar: () => null,
      }}
      options={{
        paging: false,
        filtering: false,
        search: false,
        sorting: true,
      }}
    />
  );
};

WalletRequestsTable.propTypes = {
  handleShowRequestStatusDialog: PropTypes.func.isRequired,
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      activity: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      comment: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      validator: PropTypes.string.isRequired,
    }).isRequired
  ),
  loading: PropTypes.bool.isRequired,
};

WalletRequestsTable.defaultProps = {
  requests: [],
};

export default WalletRequestsTable;
