export const PAYROLL_TYPE = {
  sales: {
    value: 'sales',
    label: 'Venta',
  },
};

export const PAYROLL_STATUS = {
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'En Proceso',
  },
  TO_PAY: {
    value: 'TO_PAY',
    label: 'Por Pagar',
  },
  PAID: {
    value: 'PAID',
    label: 'Pagada',
  },
  PAYMENT_VERIFIED: {
    value: 'PAYMENT_VERIFIED',
    label: 'Pago Confirmado',
  },
  REJECTED: {
    value: 'REJECTED',
    label: 'Rechazada',
  },
};

export const DEFAULT_CAPITAL_COST_RATE = 1.66;
