import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Radio,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import MenuMoreOptions from './MenuMoreOptions';
import { TRANSFORMED_ACCOUNT_TYPES } from '../../helpers/Constants';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { Copy, Delete } from '../icons';
import Can from '../Can';
import {
  BUSINESS_DELETE_BANK_ACCOUNT_PERFORM,
  BUSINESS_EDIT_BANK_ACCOUNT_PERFORM,
} from '../../helpers/performsType';
import MenuItem from './MenuItem';

const useStyles = makeStyles({
  container: {
    padding: convertSpacingToCss('lg'),
    '&:hover': {
      background: '#F7F5FA',
    },
  },
  holder: {
    justifyContent: 'space-between',
  },
  holderText: {
    paddingTop: 10,
    marginBottom: 5,
  },
  menuButton: {
    alignSelf: 'center',
  },
});

const BankAccountItem = ({
  bankAccount,
  handleShowDeleteDialog,
  handleShowDefaultAccountDialog,
  handleShowEditDialog,
  handleCopyData,
  disableRadio,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    Bank,
    accountNumber,
    accountHolder,
    alias,
    isDefault,
    id,
    sourceIdentifier,
    accountType,
    accountEmail,
    isXepelin,
  } = bankAccount;
  const classes = useStyles();

  const copyDataButton = (
    <Tooltip title="Copiar datos" placement="top">
      <IconButton onClick={() => handleCopyData(bankAccount)}>
        <Copy />
      </IconButton>
    </Tooltip>
  );

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const getMenuOptions = () => {
    const options = [];
    if (handleShowEditDialog) {
      const editAccountOption = (
        <Can
          perform={BUSINESS_EDIT_BANK_ACCOUNT_PERFORM}
          yes={() => (
            <MenuItem
              key="editaccount"
              onClick={e => {
                handleShowEditDialog(id);
                setAnchorEl(null);
                e.stopPropagation();
              }}
              icon={<EditIcon fontSize="small" />}
            >
              Editar cuenta
            </MenuItem>
          )}
          no={() => null}
        />
      );
      options.push(editAccountOption);
    }
    if (!isDefault) {
      const deleteAccountOption = (
        <Can
          perform={BUSINESS_DELETE_BANK_ACCOUNT_PERFORM}
          yes={() => (
            <MenuItem
              key="deleteaccount"
              onClick={e => {
                handleShowDeleteDialog(id);
                setAnchorEl(null);
                e.stopPropagation();
              }}
              icon={<Delete fontSize="small" />}
            >
              Eliminar cuenta
            </MenuItem>
          )}
          no={() => null}
        />
      );
      options.push(deleteAccountOption);
    }

    return options;
  };

  const options = getMenuOptions();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.container}
    >
      <Grid container item direction="row" justify="center" xs={1}>
        <Can
          perform={BUSINESS_EDIT_BANK_ACCOUNT_PERFORM}
          yes={() => (
            <Tooltip
              title={
                isDefault
                  ? 'Cuenta predeterminada'
                  : 'Usar como cuenta predeterminada'
              }
              placement="top"
            >
              <div>
                <Radio
                  color="primary"
                  checked={isDefault}
                  disabled={disableRadio}
                  onChange={() => handleShowDefaultAccountDialog(id)}
                />
              </div>
            </Tooltip>
          )}
          no={() => null}
        />
      </Grid>
      <Grid
        container
        item
        xs={10}
        direction="row"
        alignItems="center"
        className={classes.holder}
      >
        <Typography
          variant="body1"
          className={classes.holderText}
          color="textPrimary"
          component="div"
        >
          <Box fontWeight="fontWeightBold">{`${Bank?.name} ${
            alias && `· ${alias}`
          }`}</Box>
        </Typography>
        <Grid item container alignItems="center">
          <Typography component="div" color="textPrimary" variant="subtitle1">
            <Box
              fontWeight={isDefault ? 'fontWeightBold' : 'fontWeightRegular'}
              color={!isXepelin && !isDefault && '#8D8A96'}
              alignItems="center"
              display="flex"
            >
              {TRANSFORMED_ACCOUNT_TYPES[accountType]} {accountNumber}{' '}
              {handleCopyData && copyDataButton}
            </Box>
            <Box
              fontWeight={isDefault ? 'fontWeightBold' : 'fontWeightRegular'}
              color={!isXepelin && '#8D8A96'}
            >
              {`  ${accountHolder} · ${sourceIdentifier} · ${accountEmail}`}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Can
        perform={BUSINESS_EDIT_BANK_ACCOUNT_PERFORM}
        yes={() => (
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            xs={1}
            className={classes.menuButton}
          >
            {options.length > 0 ? (
              <MenuMoreOptions
                options={options}
                id="simple-MenuMoreOptions"
                anchorEl={anchorEl}
                handleCloseMenu={() => setAnchorEl(null)}
                handleClickMenu={handleClickMenu}
              />
            ) : null}
          </Grid>
        )}
        no={() => null}
      />
    </Grid>
  );
};

BankAccountItem.defaultProps = {
  disableRadio: false,
};

BankAccountItem.propTypes = {
  bankAccount: PropTypes.objectOf(Object).isRequired,
  handleShowDefaultAccountDialog: PropTypes.func.isRequired,
  handleShowDeleteDialog: PropTypes.func.isRequired,
  handleShowEditDialog: PropTypes.func.isRequired,
  handleCopyData: PropTypes.func.isRequired,
  disableRadio: PropTypes.bool,
};

export default BankAccountItem;
