/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { settings } from '../../../../../config/settings';
import { CONFIRMING, NONE } from '../../../../../helpers/Constants';
import { dateFormatter } from '../../../../../helpers/DateUtils';
import { fixNumber } from '../../../../../helpers/mathUtils';
import {
  OPERATION_COST_PERCENTAGE_CHANGE,
  ORDER_RATES_PERCENTAGE_CHANGE,
} from '../../../../../helpers/performsType';
import { checkAuth } from '../../../../../helpers/validation/auth';
import {
  useFetchOrderFees,
  useGetRestrictiveInformationNewSimulation,
  useUpdateOrderDetailsSummary,
} from '../../../infrastructure/store';

const roleAllowedToChangeBaseRate = [
  'admin',
  'Pymes CL - Líder',
  'Corps CL - Líder',
];

const useNewSimulationDialog = ({
  order,
  country,
  orderSummary,
  handleCloseDialog,
}) => {
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const operationType = CONFIRMING;
  const reason = NONE;
  const [formData, setFormData] = useState({
    orderId: '',
    businessId: '',
    retentionPct: 0,
    tasaBase: 0,
    issuedDate: null,
    operationCost: 0,
    discount: 0,
    reason,
    operation: operationType,
  });
  const [bounce, setBounce] = useState(null);
  const autoRate = NONE;
  const [checked, setChecked] = useState(false);
  const [validationRates, setValidationRates] = useState({
    minRate: 0,
  });
  const [validationOperationCost, setValidationOperationCost] = useState({
    minOperationCost: 0,
  });
  const [defaultBaseRate, setDefaultBaseRate] = useState(0);
  const [defaultOperationCost, setDefaultOperationCost] = useState(0);
  const [isDisabledBaseRateByRole, setIsDisabledBaseRateByRole] =
    useState(true);
  const [isDisabledOperationCostByRole, setIsDisabledOperationCostByRole] =
    useState(true);
  const currentIssuedDate = useRef();
  const countrySettings = settings[country];

  const mappedOrder = {
    OrderDetails: [
      {
        operationCost: orderSummary?.operation.operationCost,
        tasaBase: orderSummary?.operation.baseRate,
        issuedDate: orderSummary?.supplierPaymentDate,
        transferDiscount: orderSummary?.operation.transferDiscount,
      },
    ],
    advancePercentage: 100,
    businessId: order?.businessId,
  };

  const handleValidationRate = restrictiveBaseRate => {
    ORDER_RATES_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const newMinRate = fixNumber(restrictiveBaseRate - percentageChange);
        setValidationRates({
          minRate: newMinRate,
        });
      }
    });
  };

  const handleValidationOperationCost = restrictiveOperationcost => {
    OPERATION_COST_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const valueChange = fixNumber(
          restrictiveOperationcost * (percentageChange / 100)
        );
        const minOperationCost = fixNumber(
          restrictiveOperationcost - valueChange
        );
        setValidationOperationCost({
          minOperationCost,
        });
      }
    });
  };

  const {
    data: restrictiveDataNewSimulation,
    mutate: getRestrictiveInformationNewSimulation,
    isLoading: fetchRestrictiveDataNewSimulationIsLoading,
  } = useGetRestrictiveInformationNewSimulation();

  const { fees, getOrderFees, fetchOrderFeesLoading } = useFetchOrderFees();

  useEffect(() => {
    if (order?.id) {
      getRestrictiveInformationNewSimulation({
        operationType: CONFIRMING,
        operationId: order?.id,
        businessId: order?.businessId,
      });
    }
  }, [order]);

  useEffect(() => {
    if (orderSummary) {
      const { id: orderId, businessId } = order;
      const operationCost = orderSummary?.operation.operationCost;
      const tasaBase = orderSummary?.operation.baseRate;
      const issuedDate = orderSummary?.supplierPaymentDate;
      const transferDiscount = orderSummary?.operation.transferDiscount;
      const orderDiscount = transferDiscount
        ? Math.abs(transferDiscount)
        : transferDiscount;
      const retentionPct = 0;
      currentIssuedDate.current = dateFormatter(issuedDate);
      setFormData({
        ...formData,
        orderId,
        businessId,
        operation: operationType,
        operationCost,
        tasaBase,
        issuedDate,
        discount: orderDiscount,
        retentionPct,
      });
      getOrderFees({
        orderId,
        businessId,
        operation: operationType,
        operationCost,
        tasaBase,
        issuedDate,
        retentionPct,
        discount: orderDiscount,
        autoRate,
      });
    }
  }, [orderSummary]);

  useEffect(() => {
    if (Object.keys(fees).length) {
      setFormData({
        ...formData,
        operationCost: fees?.operationCost,
        tasaBase: fees?.baseRate,
      });
    }
  }, [fees]);

  useEffect(() => {
    if (restrictiveDataNewSimulation && order) {
      const {
        OrderDetails: [orderDetail],
      } = mappedOrder;
      let userRoleAllowed = false;
      roleAllowedToChangeBaseRate.forEach(roleAllowed => {
        if (roles.some(role => role.rolename === roleAllowed)) {
          userRoleAllowed = true;
        }
      });

      if (userRoleAllowed) {
        setIsDisabledBaseRateByRole(false);
        setIsDisabledOperationCostByRole(false);
      } else {
        setIsDisabledBaseRateByRole(
          orderDetail?.tasaBase < validationRates?.minRate
        );
        setIsDisabledOperationCostByRole(
          orderDetail?.operationCost < validationOperationCost?.minOperationCost
        );
      }
    }
  }, [
    order,
    validationRates,
    validationOperationCost,
    restrictiveDataNewSimulation,
  ]);

  useEffect(() => {
    if (restrictiveDataNewSimulation) {
      if (!defaultBaseRate) {
        setDefaultBaseRate(restrictiveDataNewSimulation?.fees?.baseRate ?? 0);
        handleValidationRate(restrictiveDataNewSimulation?.fees?.baseRate ?? 0);
      }
      if (!defaultOperationCost) {
        setDefaultOperationCost(
          restrictiveDataNewSimulation?.fees?.operationCost ?? 0
        );
        handleValidationOperationCost(
          restrictiveDataNewSimulation?.fees?.operationCost ?? 0
        );
      }
    }
  }, [restrictiveDataNewSimulation]);

  const { updateOrderDetails } = useUpdateOrderDetailsSummary();

  const handleSubmitSimulation = orderData => {
    updateOrderDetails({ ...orderData, businessId: order?.businessId });
  };

  const handleSubmit = () => {
    handleSubmitSimulation({
      ...fees,
      orderId: formData?.orderId,
      orderType: formData?.operation,
      discount: formData?.discount,
      operationCost: formData?.operationCost,
      issuedDate: formData?.issuedDate,
      reason,
      autoRate,
    });
    handleCloseDialog();
  };

  const handleInputChange = e => {
    const updatedData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(updatedData);
    clearTimeout(bounce);
    setBounce(
      setTimeout(() => {
        getOrderFees({
          ...updatedData,
          autoRate,
        });
      }, 500)
    );
  };

  const handleDateChange = (stateKey, date) => {
    const updatedData = {
      ...formData,
      [stateKey]: date,
    };
    setFormData(updatedData);
    clearTimeout(bounce);
    setBounce(
      setTimeout(() => {
        getOrderFees({
          ...updatedData,
          autoRate,
        });
      }, 500)
    );
  };

  return {
    fees,
    checked,
    formData,
    validationRates,
    countrySettings,
    currentIssuedDate,
    fetchOrderFeesLoading,
    validationOperationCost,
    isDisabledBaseRateByRole,
    isDisabledOperationCostByRole,
    fetchRestrictiveDataNewSimulationIsLoading,
    setChecked,
    handleSubmit,
    handleDateChange,
    handleInputChange,
  };
};

export default useNewSimulationDialog;
