import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { b64toBlob, download } from '../../../../helpers/fileHandling';
import { t13s } from '../../../../translationKeys';
import { fetchInvoicePdfService } from '../services';

export const useFetchInvoicePdf = ({
  id,
  fileName,
  orderType,
  identifier,
  country,
}) => {
  const dispatch = useDispatch();
  const { refetch: refetchInvoicePdf, isLoading: fetchInvoicePDFIsLoading } =
    useQuery(
      ['getOrderPdf', id, identifier],
      () =>
        fetchInvoicePdfService({
          id,
          fileName,
          orderType,
          identifier,
          country,
        }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: response => {
          const { pdf } = response.data;
          const blob = b64toBlob(pdf, 'application/pdf');
          return download(blob, `${fileName}.pdf`);
        },
        onError: () => {
          dispatch(
            enqueueSnackbar(t13s.ERROR.INVOICE_PDF_NOT_FOUND, {
              variant: 'error',
            })
          );
        },
      }
    );

  return {
    refetchInvoicePdf,
    fetchInvoicePDFIsLoading,
  };
};

export default useFetchInvoicePdf;
