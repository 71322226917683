import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { getErrorCode } from '../../../../helpers/handleErrors';

export const updateOrderInvoiceIsHoldService = async ({ orderInvoiceId }) => {
  try {
    const { data } = await axios.patch(
      `/api/orderinvoice/${orderInvoiceId}/isHold`
    );
    return data;
  } catch (error) {
    const errorCode = getErrorCode(error);
    throw new Error(errorCode ?? UNKNOWN_ERROR);
  }
};

export default updateOrderInvoiceIsHoldService;
