import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Avatar from '../../../../../AR/commons/components/Avatar';
import {
  INotificationParsed,
  NotificationStatus,
  NotificationType,
} from '../../../../elements/Notifications/interfaces';
import useNotification from '../../hooks/useNotification';
import NotificationSkeleton from '../NotificationSkeleton';
import {
  RootContainer,
  CheckedDot,
  Title,
  SpanTitle,
  DescriptionChecked,
  DescriptionNew,
  SpacerDot,
  TitleContainer,
} from './styles';

interface Props {
  notification: INotificationParsed;
}

const Notification: FC<Props> = ({ notification }) => {
  const {
    notificationId,
    subscriptionType,
    status,
    createdAt,
    title,
    description,
    href,
  } = notification;

  const { getUserNotificationsIsLoading, formatTimeAgo, updateNotification } =
    useNotification(notificationId);

  return (
    <NotificationSkeleton isLoading={getUserNotificationsIsLoading}>
      <RootContainer
        onClick={() => {
          updateNotification(status);
          window.open(`${window.location.origin}${href}`, '_blank');
        }}
      >
        <Box mr={2}>
          <Box display="flex" alignItems="center">
            {status === NotificationStatus.New ? <CheckedDot /> : <SpacerDot />}
            {subscriptionType === NotificationType.Comments && (
              <Avatar name={title} />
            )}
          </Box>
        </Box>
        <Box>
          <TitleContainer>
            <Title>{title}</Title>
            <SpanTitle>{formatTimeAgo(createdAt)}</SpanTitle>
          </TitleContainer>

          {status === NotificationStatus.Checked ? (
            <DescriptionChecked>{description}</DescriptionChecked>
          ) : (
            <DescriptionNew>{description}</DescriptionNew>
          )}
        </Box>
      </RootContainer>
    </NotificationSkeleton>
  );
};

export default Notification;
