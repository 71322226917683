import React, { FC, PropsWithChildren } from 'react';
import { Box, styled } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface Props extends PropsWithChildren {
  isLoading?: boolean;
}

const RequirementContainerSkeleton = styled(Box)({
  width: 400,
  marginRight: 55,
  '&:last-Child': {
    marginRight: 0,
  },
});

const RequirementSkeleton: FC<Props> = ({ isLoading = false, children }) => {
  return (
    <>
      {!isLoading ? (
        children
      ) : (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Skeleton animation="wave" variant="text" width={350} height={21} />
            <Skeleton animation="wave" variant="text" width={25} height={21} />
          </Box>
          <Box mb={5}>
            <Skeleton
              animation="wave"
              variant="text"
              width={'100%'}
              height={14}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {[1, 2].map(() => (
              <RequirementContainerSkeleton>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={130}
                  height={30}
                />
                {[1, 2, 3, 4, 5].map(() => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={2}
                    mb={1}
                  >
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width={22}
                          height={22}
                        />
                      </Box>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={300}
                        height={22}
                      />
                    </Box>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={22}
                      height={22}
                    />
                  </Box>
                ))}
              </RequirementContainerSkeleton>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default RequirementSkeleton;
