import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchOrderInvoiceFundRatesService = async orderInvoiceFundId => {
  try {
    return await axios.get(`/api/orderinvoicefund/${orderInvoiceFundId}/rate`);
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrderInvoiceFundRatesService };
