import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { fetchAvailableBusinessInvoicesService } from '../services';

const useFetchAvailableBusinessInvoices = ({
  businessId,
  config: { filters, operationType, includeRate = false, defaultRate },
}) => {
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useQuery(
    ['getAvailableInvoices', filters, businessId, defaultRate],
    () =>
      fetchAvailableBusinessInvoicesService({
        businessId,
        config: {
          filters,
          operationType,
          includeRate,
          defaultRate,
        },
      }),
    {
      refetchOnWindowFocus: false,
      select: data => data.data.data,
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_INVOICES_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    availableInvoices: data,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchAvailableBusinessInvoices };
