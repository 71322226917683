export const deleteReceiversUseCase = ({
  currentSelectorState,
  receiversToRemove,
}) => {
  return Object.keys(currentSelectorState)
    .filter(receiverId => !receiversToRemove.includes(receiverId))
    .reduce((acc, cur) => {
      acc[cur] = currentSelectorState[cur];

      return acc;
    }, {});
};
