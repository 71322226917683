export const getReceiversUseCase = ({ receivers, payrollSelector }) => {
  const isChecked = receiver => {
    if (!payrollSelector[receiver.receiverId]) {
      return false;
    }

    return receiver.payrollInvoicesId.every(r =>
      payrollSelector[receiver.receiverId].value.includes(r)
    );
  };

  const isIndeterminate = receiver => {
    if (!payrollSelector[receiver.receiverId]) {
      return false;
    }

    return receiver.payrollInvoicesId.some(r =>
      payrollSelector[receiver.receiverId].value.includes(r)
    );
  };

  return receivers?.map(receiver => {
    const checked = isChecked(receiver);
    const indeterminate = checked ? false : isIndeterminate(receiver);

    return {
      ...receiver,
      tableData: {
        ...receiver.tableData,
        checked,
        indeterminate,
      },
    };
  });
};
