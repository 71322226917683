import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MailNotValid = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.80429 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM16.8571 15.4286C16.9548 15.5202 17.0326 15.6308 17.0857 15.7537C17.1389 15.8765 17.1664 16.009 17.1664 16.1429C17.1664 16.2767 17.1389 16.4092 17.0857 16.532C17.0326 16.6549 16.9548 16.7655 16.8571 16.8571C16.7632 16.9585 16.6496 17.0397 16.5233 17.0958C16.397 17.1519 16.2606 17.1818 16.1225 17.1837C15.8452 17.1712 15.5828 17.0546 15.3878 16.8571L11.9592 13.4286L8.53062 16.8571C8.35058 17.0521 8.10169 17.1692 7.83674 17.1837C7.69855 17.1818 7.56217 17.1519 7.43588 17.0958C7.30958 17.0397 7.19601 16.9585 7.10204 16.8571C7.00443 16.7655 6.92663 16.6549 6.87345 16.532C6.82026 16.4092 6.79282 16.2767 6.79282 16.1429C6.79282 16.009 6.82026 15.8765 6.87345 15.7537C6.92663 15.6308 7.00443 15.5202 7.10204 15.4286L10.5306 12L7.10204 8.57143C7.00329 8.47624 6.92473 8.36212 6.87108 8.23589C6.81743 8.10965 6.78977 7.9739 6.78977 7.83673C6.78977 7.69957 6.81743 7.56382 6.87108 7.43758C6.92473 7.31135 7.00329 7.19723 7.10204 7.10204C7.29744 6.92421 7.55213 6.82567 7.81633 6.82567C8.08053 6.82567 8.33522 6.92421 8.53062 7.10204L11.9592 10.5306L15.3878 7.10204C15.5898 6.92132 15.8514 6.82141 16.1225 6.82141C16.3935 6.82141 16.6551 6.92132 16.8571 7.10204C16.9559 7.19723 17.0345 7.31135 17.0881 7.43758C17.1418 7.56382 17.1694 7.69957 17.1694 7.83673C17.1694 7.9739 17.1418 8.10965 17.0881 8.23589C17.0345 8.36212 16.9559 8.47624 16.8571 8.57143L13.4286 12L16.8571 15.4286Z" />
    </SvgIcon>
  );
};

export default MailNotValid;
