import {
  FETCH_BUSINESS_PAYERS_START,
  FETCH_BUSINESS_PAYERS_SUCCESS,
  FETCH_BUSINESS_PAYERS_FAILURE,
  RESET_BUSINESS_PAYERS,
  FETCH_PAYER_INVOICES_START,
  FETCH_PAYER_INVOICES_SUCCESS,
  FETCH_PAYER_INVOICES_FAILURE,
  RESET_CLIENT_NAME_NOT_ENROLLED,
  FETCH_SII_INVOICES_SUCCESS,
  FETCH_AVAILABLE_INVOICES_START,
  FETCH_AVAILABLE_INVOICES_SUCCESS,
  FETCH_AVAILABLE_INVOICES_FAILURE,
  UPLOAD_INVOICES_CSV_TO_ORDER_START,
  UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS,
  UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE,
  RESET_INVOICES_CSV_TO_ORDER,
} from '../actions/types';

const uploadInvoiceCsvToOrderSetLoading = (state, isLoading = false) => {
  return {
    ...state,
    uploadingInvoiceCsvToOrder: isLoading,
    uploadedInvoiceCsvToOrder: false,
  };
};

const uploadInvoiceCsvToOrderSuccess = (state, payload) => {
  const errors =
    payload.errors ??
    payload.errorLines?.map(e => {
      delete e.errors;
      return e;
    });
  return {
    ...state,
    uploadingInvoiceCsvToOrder: false,
    uploadedInvoiceCsvToOrder: true,
    invoices: payload.invoices,
    issuingBusinesses: payload.businesses,
    csvInvoiceToOrderErrors: errors,
    bankAccounts: payload.bankAccounts,
  };
};

const resetInvoiceCsvToOrder = state => {
  return {
    ...state,
    uploadingInvoiceCsvToOrder: false,
    uploadedInvoiceCsvToOrder: false,
    csvInvoiceToOrderErrors: [],
    invoices: [],
    issuingBusinesses: [],
  };
};

const initialState = {
  fetchingPayers: false,
  payers: [],
  fetchingInvoices: false,
  invoices: [],
  issuingBusinesses: null,
  clientName: '',
  siiFacturas: [],
  availableInvoices: [],
  fetchingAvailableInvoices: false,
  csvInvoiceToOrderErrors: [],
  uploadingInvoiceCsvToOrder: false,
  uploadedInvoiceCsvToOrder: false,
  bankAccounts: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BUSINESS_PAYERS_START:
      return {
        ...state,
        fetchingPayers: true,
      };

    case FETCH_BUSINESS_PAYERS_SUCCESS: {
      const { payers, clientName } = payload;
      return {
        ...state,
        payers,
        clientName,
        fetchingPayers: false,
      };
    }
    case FETCH_BUSINESS_PAYERS_FAILURE:
      return {
        ...state,
        fetchingPayers: false,
      };
    case RESET_BUSINESS_PAYERS:
      return {
        ...state,
        payers: [],
        invoices: [],
        clientName: '',
      };
    case RESET_CLIENT_NAME_NOT_ENROLLED:
      return {
        ...state,
        clientName: '',
      };
    case FETCH_PAYER_INVOICES_START:
      return {
        ...state,
        fetchingInvoices: true,
      };
    case FETCH_PAYER_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: payload.invoices,
        fetchingInvoices: false,
      };
    case FETCH_PAYER_INVOICES_FAILURE:
      return {
        ...state,
        fetchingInvoices: false,
      };
    case FETCH_AVAILABLE_INVOICES_START:
      return {
        ...state,
        fetchingAvailableInvoices: true,
      };
    case FETCH_AVAILABLE_INVOICES_SUCCESS: {
      const { availableInvoices } = payload;

      return {
        ...state,
        availableInvoices,
        fetchingAvailableInvoices: false,
      };
    }
    case FETCH_AVAILABLE_INVOICES_FAILURE: {
      return {
        ...state,
        fetchingAvailableInvoices: false,
      };
    }
    case FETCH_SII_INVOICES_SUCCESS: {
      const { siiFacturas } = payload;
      return {
        ...state,
        siiFacturas,
      };
    }
    case UPLOAD_INVOICES_CSV_TO_ORDER_START:
      return uploadInvoiceCsvToOrderSetLoading(state, true);
    case UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS:
      return uploadInvoiceCsvToOrderSuccess(state, payload);
    case UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE:
      return uploadInvoiceCsvToOrderSetLoading(state);
    case RESET_INVOICES_CSV_TO_ORDER:
      return resetInvoiceCsvToOrder(state);
    default:
      return state;
  }
};
