import PayerCollection from '../assets/carterapagador-icon.png';
import ActiveCollection from '../assets/cobranzaact-icon.png';
import ConfirmCesionIcon from '../assets/confirmar-cesion-icon.png';
import ConfirmDateIcon from '../assets/confirmarfecha-icon.png';
import DicomIcon from '../assets/dicom-icon.png';
import CriticalState from '../assets/estadoCritico.svg';
import PreDicomIcon from '../assets/predicom-icon.png';
import { PAYROLL_STATUS } from '../funds/commons/constants/payroll';

// BUSINESS SEGMENT
export const BUSINESS_SEGMENT = [
  {
    value: 'EMERGENT',
    label: 'Pyme Emergentes',
  },
  {
    value: 'GROWTH',
    label: 'Pyme Crecimiento',
  },
  {
    value: 'CONSOLIDATED',
    label: 'Pyme Consolidada',
  },
  {
    value: 'MID_CORP',
    label: 'Mid Corp',
  },
  {
    value: 'CORP',
    label: 'Corporativo',
  },
];

export const BUSINESS_SEGMENT_DICTIONARY = {
  EMERGENT: 'Pyme Emergentes',
  GROWTH: 'Pyme Crecimiento',
  CONSOLIDATED: 'Pyme Consolidada',
  MID_CORP: 'Mid Corp',
  CORP: 'Corporativo',
};

// TYPES
export const DIRECT_FINANCING = 'DIRECT_FINANCING';
export const EARLY_PAYMENT = 'EARLY_PAYMENT';
export const CONFIRMING = 'CONFIRMING';
export const PAYMENTS = 'PAYMENTS';
export const DF_LABEL = 'Financiamiento Directo';
export const EP_LABEL = 'Pronto Pago';
export const CO_LABEL = 'Payments';
export const ORDER_TYPES_LABEL = {
  DIRECT_FINANCING: DF_LABEL,
  EARLY_PAYMENT: EP_LABEL,
  CONFIRMING: CO_LABEL,
};

export const AR_PRODUCTS = [DIRECT_FINANCING, EARLY_PAYMENT];

// DISCOUNTS
export const REPLACEMENT_OPERATION_REASONS_DISCOUNTS = ['INVOICE_REPLACEMENT'];
export const DISCOUNTS = [
  {
    type: 'REPLACEMENT_OPERATION_DISCOUNT',
    value: 'INVOICE_REPLACEMENT',
    label: 'Reemplazo facturas',
    sourceType: ['order'],
    operationType: [EARLY_PAYMENT, DIRECT_FINANCING],
    country: ['CL'],
  },
  {
    type: 'REPLACEMENT_OPERATION_DISCOUNT',
    value: 'INVOICE_REPLACEMENT',
    label: 'Reemplazo facturas',
    sourceType: ['order'],
    operationType: [EARLY_PAYMENT],
    country: ['MX'],
  },
  {
    type: 'CHECK_INVOICE_DISCOUNT',
    value: 'DIFFERENCE_API_CORP',
    label: 'Diferencia API CORP',
    sourceType: ['orderinvoice'],
    operationType: [EARLY_PAYMENT],
    country: ['CL'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'CREDIT_NOTE',
    label: 'Nota de Crédito',
    country: ['CL', 'MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'DEBT_WALLET',
    label: 'Mora cartera',
    country: ['CL', 'MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'SUPPLIER_PARTIAL_ADVANCE',
    label: 'Adelanto parcial proveedor',
    country: ['CL', 'MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'LIFE_INSURANCE_PF',
    label: 'Seguro de Vida - PF',
    country: ['MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'TRANSFER_ERROR',
    label: 'Error en Giro',
    country: ['MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'FINANCING',
    label: 'Re-financiamiento',
    country: ['MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'PARTIAL_PAYMENT',
    label: 'Pago parcial',
    country: ['MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'CUSTOMER_PROMOTION',
    label: 'Promoción a Cliente',
    country: ['MX'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'PAGO_FACTURA',
    label: 'Pago de factura',
    country: ['CL'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'PAGO_CREDITO',
    label: 'Pago de credito',
    country: ['CL'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'PAGO_CXP',
    label: 'Pago de CxP',
    country: ['CL'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'CAMBIO_FACTURA',
    label: 'Cambio de factura',
    country: ['CL'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'ERROR_GIRO',
    label: 'Error en el giro',
    country: ['CL'],
  },
  {
    type: 'BASE_DISCOUNT',
    value: 'ANULACION',
    label: 'Anulación',
    country: ['CL'],
  },
];

// TYPES AUTORATE
export const NONE = 'NONE';
export const NORMAL = 'NORMAL';
export const FORCED = 'FORCED';

// PAGINATION
export const PAGE_SIZE_INIT = 20;
export const INIT_PAGE = 0;

// PATH
export const ORDER_PATH = {
  root: '/',
  DIRECT_FINANCING: '/orders',
  EARLY_PAYMENT: '/pronto-pago',
  CONFIRMING: '/confirming',
};

// DATES
export const DATE_LOCALE = 'es';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_DB_FORMAT = 'YYYY-MM-DD'; // DEPRECATED DON'T USE
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT_NO_SECONDS = 'YYYY-MM-DD HH:mm';

// TIMEZONE
export const TIME_ZONE = {
  CL: 'America/Santiago',
  MX: 'America/Mexico_City',
};

// TIMEOUT
export const SEARCH_BAR_TIMEOUT = 500;

// SINGLE STATUS
export const STATUS_IN_REVIEW = 'IN_REVIEW';
export const STATUS_REVIEWED = 'REVIEWED';
export const STATUS_APPEALED = 'APPEALED';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_APPROVED_FUND = 'APPROVED_FUND';
export const STATUS_TO_DEPOSIT = 'TO_DEPOSIT';
export const STATUS_REPLACED = 'REPLACED';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_DEFAULT = 'DEFAULT';
export const STATUS_PAID = 'PAID';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_COMPLETE = 'COMPLETE';
export const STATUS_PV_APPROVED = 'PV_APPROVED';
export const STATUS_RENEGOTIATION = 'RENEGOTIATION';
export const STATUS_VERIFICATION_WITHOUT_AMOUNT = 'VERIFICATION_WITHOUT_AMOUNT';
export const STATUS_VERIFIED = 'VERIFIED';
export const STATUS_UNVERIFIED = 'UNVERIFIED';
export const STATUS_POST_VERIFIED = 'POST_VERIFIED';
export const STATUS_TRANSFERRED = 'TRANSFERRED';
export const STATUS_CEDE = 'CEDE';
export const STATUS_PRIORITY_PAYMENT = 'PRIORITY_PAYMENT';
export const STATUS_DELINQUENT = 'DELINQUENT';
export const STATUS_SOFT_DELINQUENCY = 'SOFT_DELINQUENCY';
export const STATUS_HARD_DELINQUENCY = 'HARD_DELINQUENCY';
export const STATUS_HARD_COLLECTION = 'HARD_COLLECTION';
export const STATUS_PENDING = 'PENDING';
export const STATUS_GROUP_CREDITS = 'CREDITS';
export const DEH_DESCRIPTION = 'Acuse Recibo';
export const STATUS_CEDED = 'Cedida';
export const STATUS_CLAIMED = 'Reclamada';
export const STATUS_CANCELED = 'Anulada';
export const STATUS_INCASH_OR_FREE = 'Contado/Gratuito';
export const STATUS_PAUSED = 'PAUSED';
export const STATUS_IN_CONCILIATION = 'IN_CONCILIATION';
export const OPERATION_TYPES = 'OPERATION_TYPES';
export const DEBT_EXTENSION = 'DEBT_EXTENSION';
export const LOW_CONTACTABILITY = 'LOW_CONTACTABILITY';
export const MEDIUM_CONTACTABILITY = 'MEDIUM_CONTACTABILITY';
export const HIGH_CONTACTABILITY = 'HIGH_CONTACTABILITY';
export const UNKNOWN_CONTACTABILITY = 'UNKNOWN_CONTACTABILITY';
export const LOW_PAYMENT_INTENTION = 'LOW_PAYMENT_INTENTION';
export const MEDIUM_PAYMENT_INTENTION = 'MEDIUM_PAYMENT_INTENTION';
export const HIGH_PAYMENT_INTENTION = 'HIGH_PAYMENT_INTENTION';
export const UNKNOWN_PAYMENT_INTENTION = 'UNKNOWN_PAYMENT_INTENTION';
export const LOW_PAYMENT_CAPACITY = 'LOW_PAYMENT_CAPACITY';
export const MEDIUM_PAYMENT_CAPACITY = 'MEDIUM_PAYMENT_CAPACITY';
export const HIGH_PAYMENT_CAPACITY = 'HIGH_PAYMENT_CAPACITY';
export const UNKNOWN_PAYMENT_CAPACITY = 'UNKNOWN_PAYMENT_CAPACITY';
export const PENDING = 'PENDING';
export const ONLY_SENT_DEBT_MANAGEMENT = 'ONLY_SENT_DEBT_MANAGEMENT';
export const WITH_CONTACT_DEBT_MANAGEMENT = 'WITH_CONTACT_DEBT_MANAGEMENT';
export const CONTACT_WITH_OTHERS_DEBT_MANAGEMENT =
  'CONTACT_WITH_OTHERS_DEBT_MANAGEMENT';
export const NO_CONTACT_DEBT_MANAGEMENT = 'NO_CONTACT_DEBT_MANAGEMENT';
export const INVALID_ADDRESS_DEBT_MANAGEMENT =
  'INVALID_ADDRESS_DEBT_MANAGEMENT';
export const POSTED_DEBT_MANAGEMENT = 'POSTED_DEBT_MANAGEMENT';
export const UNPOSTED_DEBT_MANAGEMENT = 'UNPOSTED_DEBT_MANAGEMENT';
export const TO_POST_DEBT_MANAGEMENT = 'TO_POST_DEBT_MANAGEMENT';
export const IN_PROCESS_DEBT_MANAGEMENT = 'IN_PROCESS_DEBT_MANAGEMENT';
export const COMPLIANCE_DEBT_MANAGEMENT = 'COMPLIANCE_DEBT_MANAGEMENT';
export const NO_COMPLIANCE_DEBT_MANAGEMENT = 'NO_COMPLIANCE_DEBT_MANAGEMENT';
export const CREDIT_NOTE_DEBT_MANAGEMENT = 'CREDIT_NOTE_DEBT_MANAGEMENT';
export const ANULATION_DEBT_MANAGEMENT = 'ANULATION_DEBT_MANAGEMENT';
export const CLAIMED_DEBT_MANAGEMENT = 'CLAIMED_DEBT_MANAGEMENT';
export const DISCOUNT_DEBT_MANAGEMENT = 'DISCOUNT_DEBT_MANAGEMENT';
export const PAYED_TO_STAKEHOLDER_DEBT_MANAGEMENT =
  'PAYED_TO_STAKEHOLDER_DEBT_MANAGEMENT';
export const SOLVED_DEBT_MANAGEMENT = 'SOLVED_DEBT_MANAGEMENT';
export const TO_BE_SENT_DEBT_MANAGEMENT = 'TO_BE_SENT_DEBT_MANAGEMENT';
export const SEND_AND_KEEP_DEBT_MANAGEMENT = 'SEND_AND_KEEP_DEBT_MANAGEMENT';
export const SEND_AND_TAKE_DEBT_MANAGEMENT = 'SEND_AND_TAKE_DEBT_MANAGEMENT';

// SETTINGS STATUS
export const STATUS_ORDER_TYPE = 'ORDER';
export const STATUS_INVOICE_TYPE = 'INVOICE';
export const STATUS_CREDIT_TYPE = 'CREDIT';
export const STATUS_FEE_TYPE = 'FEE';
export const ACTION_VIEW_TYPE = 'VIEW';
export const ACTION_CHANGE_TYPE = 'CHANGE';
export const STATUS_FAILED = 'FAILED';
export const STATUS_SUCCESS = 'SUCCESS';
export const IN_REVIEW = 'En Revisión';
export const REVIEWED = 'Revisado';
export const APPEALED = 'Apelado';
export const APPROVED = 'Aprobado';
export const APPROVED_FUND = 'Aprobado Fondo';
export const PENDING_SIGNATURE = 'Pendiente Firma';
export const POST_VERIFIED = 'Post-Verificado';
export const PV_APPROVED = 'PV Aprobado';
export const VERIFIED = 'Verificado';
export const TO_DEPOSIT = 'Por Depositar';
export const ACTIVE = 'Activo';
export const DEFAULT = 'Default Contable';
export const PAID = 'Pagado';
export const COMPLETE = 'Completo';
export const REJECTED = 'Rechazado';
export const REPLACED = 'Reemplazado';
export const TRANSFERRED = 'Transferido';
export const SOFT_DELINQUENCY = 'Mora Blanda';
export const HARD_DELINQUENCY = 'Default Interno';
export const DELINQUENT = 'Mora';
export const HARD_COLLECTION = 'Estado Crítico';
export const CEDE = 'Cedido';
export const IN_CONCILIATION = 'En conciliación';
export const RENEGOTIATION = 'Renegociado';

export const CRITICAL_INVOICES_STATUS_GROUP = [
  'IN_REVIEW',
  'APPROVED',
  'TRANSFERRED',
];

export const STATUS_IN_BLOCK = 'IN_BLOCK';
export const STATUS_BLOCKED = 'BLOCKED';

export const LOG_STATUS = [
  { key: STATUS_BLOCKED, label: 'Bloqueado', group: STATUS_IN_BLOCK },
];

export const DEFAULT_STATUS = [
  // IN REVIEW
  {
    key: STATUS_IN_REVIEW,
    label: 'En Revisión',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_REVIEWED,
    label: 'Revisado',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_APPEALED,
    label: 'Apelable',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_APPROVED,
    label: 'Aprobado',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  // APPROVED
  {
    key: STATUS_APPROVED_FUND,
    label: 'Aprobado Fondo',
    group: STATUS_APPROVED,
    country: ['CL'],
  },
  {
    key: STATUS_APPROVED_FUND,
    label: 'Pendiente Firma',
    group: STATUS_APPROVED,
    country: ['MX'],
  },
  {
    key: STATUS_TO_DEPOSIT,
    label: 'Por Depositar',
    group: STATUS_APPROVED,
    country: ['CL', 'MX'],
  },
  // TRANSFERRED
  {
    key: STATUS_ACTIVE,
    label: 'Activo',
    group: STATUS_TRANSFERRED,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_DEFAULT,
    label: DEFAULT,
    group: STATUS_TRANSFERRED,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_IN_CONCILIATION,
    label: 'En conciliación',
    group: STATUS_IN_CONCILIATION,
    country: ['CL', 'MX'],
  },
  // PAID
  {
    key: STATUS_PAID,
    label: 'Pagado',
    group: STATUS_PAID,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_COMPLETE,
    label: 'Completo',
    group: STATUS_PAID,
    country: ['CL', 'MX'],
  },
  // REJECTED
  {
    key: STATUS_REJECTED,
    label: 'Rechazado',
    group: STATUS_REJECTED,
    country: ['CL', 'MX'],
  },
];

export const STATUSES_VERIFIED = [
  {
    key: STATUS_POST_VERIFIED,
    label: 'Post-Verificado',
  },
  { key: STATUS_VERIFIED, label: 'Verificado' },
  { key: STATUS_UNVERIFIED, label: 'Sin verificar' },
];

export const INVOICE_STATUSES_GROUP = {
  [STATUS_IN_REVIEW]: {
    key: STATUS_IN_REVIEW,
    label: 'En revisión',
  },
  [STATUS_APPROVED]: {
    key: STATUS_APPROVED,
    label: 'Aprobado',
  },

  [STATUS_TRANSFERRED]: {
    key: STATUS_TRANSFERRED,
    label: 'Transferido',
  },
  [STATUS_PAID]: {
    key: STATUS_PAID,
    label: 'Pagado',
  },
  [STATUS_REJECTED]: {
    key: STATUS_REJECTED,
    label: 'Rechazado',
  },
  [STATUS_REPLACED]: {
    key: STATUS_REPLACED,
    label: 'Reemplazado',
  },
};

export const ORDER_STATUSES_GROUP = {
  [STATUS_IN_REVIEW]: {
    key: STATUS_IN_REVIEW,
    label: 'En revisión',
  },
  [STATUS_APPROVED]: {
    key: STATUS_APPROVED,
    label: 'Aprobado',
  },

  [STATUS_TRANSFERRED]: {
    key: STATUS_TRANSFERRED,
    label: 'Transferido',
  },
  [STATUS_PAID]: {
    key: STATUS_PAID,
    label: 'Pagado',
  },
  [STATUS_REJECTED]: {
    key: STATUS_REJECTED,
    label: 'Rechazado',
  },
  [STATUS_REPLACED]: {
    key: STATUS_REPLACED,
    label: 'Reemplazado',
  },
  [STATUS_RENEGOTIATION]: {
    key: STATUS_RENEGOTIATION,
    label: 'Renegociado',
  },
};

export const CREDIT_STATUSES_GROUP = {
  [STATUS_GROUP_CREDITS]: {
    key: STATUS_GROUP_CREDITS,
    label: 'Créditos',
  },
};

export const OPERATION_TYPES_STATUSES_GROUP = {
  [OPERATION_TYPES]: {
    key: OPERATION_TYPES,
    label: 'Tipo de operaciones',
  },
};

export const STATUSES = {
  factura: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_CEDE,
      label: 'Cedido',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_PRIORITY_PAYMENT,
      label: 'Pago Prioritario',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_SOFT_DELINQUENCY,
      label: 'Mora Blanda',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_DELINQUENCY,
      label: 'Default Interno',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_DELINQUENT,
      label: 'Mora',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_COLLECTION,
      label: 'Estado Crítico',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_REPLACED,
      label: 'Reemplazado',
      group: STATUS_REPLACED,
      country: ['CL', 'MX'],
    },
  ],
  facturasBulk: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_CEDE,
      label: 'Cedido',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_SOFT_DELINQUENCY,
      label: 'Mora Blanda',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_DELINQUENCY,
      label: 'Default Interno',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_DELINQUENT,
      label: 'Mora',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_COLLECTION,
      label: 'Estado Crítico',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
  ],
  order: [
    ...DEFAULT_STATUS.filter(status => status.key !== STATUS_IN_CONCILIATION),
    {
      key: STATUS_PV_APPROVED,
      label: 'PV Aprobado',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_RENEGOTIATION,
      label: 'Renegociado',
      group: STATUS_REJECTED,
      country: ['CL', 'MX'],
    },
  ],
  fee: [
    {
      key: STATUS_ACTIVE,
      label: 'Activo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_PAID,
      label: 'Pagado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_REJECTED,
      label: 'Rechazado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
  ],
  credit: [
    {
      key: STATUS_PENDING,
      label: 'Pendiente',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_APPROVED,
      label: 'Aprobado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_APPROVED_FUND,
      label: 'Aprobado Fondo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL'],
    },
    {
      key: STATUS_APPROVED_FUND,
      label: 'Pendiente Firma',
      group: STATUS_GROUP_CREDITS,
      country: ['MX'],
    },
    {
      key: STATUS_TO_DEPOSIT,
      label: 'Por Depositar',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_ACTIVE,
      label: 'Activo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_PAID,
      label: 'Pagado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_COMPLETE,
      label: 'Completo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_REJECTED,
      label: 'Rechazado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
  ],
  payrollFunds: Object.keys(PAYROLL_STATUS).map(status => {
    const { value, label } = PAYROLL_STATUS[status];
    return {
      key: value,
      label,
    };
  }),
  checkInvoice: STATUSES_VERIFIED,
  operationTypes: [
    {
      key: EARLY_PAYMENT,
      label: EP_LABEL,
      group: OPERATION_TYPES,
      country: ['CL', 'MX'],
    },
    {
      key: DIRECT_FINANCING,
      label: DF_LABEL,
      group: OPERATION_TYPES,
      country: ['CL', 'MX'],
    },
    {
      key: CONFIRMING,
      label: CO_LABEL,
      group: OPERATION_TYPES,
      country: ['CL', 'MX'],
    },
  ],
  debt: [
    {
      key: DEBT_EXTENSION,
      label: 'Prórroga',
      group: DEBT_EXTENSION,
      country: ['CL', 'MX'],
    },
  ],
  payerCategorization: [
    {
      key: LOW_CONTACTABILITY,
      label: 'No ubicable',
      group: LOW_CONTACTABILITY,
      country: ['CL', 'MX'],
    },
    {
      key: MEDIUM_CONTACTABILITY,
      label: 'Ubicable medio',
      group: MEDIUM_CONTACTABILITY,
      country: ['CL', 'MX'],
    },
    {
      key: HIGH_CONTACTABILITY,
      label: 'Ubicable alto',
      group: HIGH_CONTACTABILITY,
      country: ['CL', 'MX'],
    },
    {
      key: LOW_PAYMENT_INTENTION,
      label: 'Mala actitud',
      group: LOW_PAYMENT_INTENTION,
      country: ['CL', 'MX'],
    },
    {
      key: MEDIUM_PAYMENT_INTENTION,
      label: 'Renuente',
      group: MEDIUM_PAYMENT_INTENTION,
      country: ['CL', 'MX'],
    },
    {
      key: HIGH_PAYMENT_INTENTION,
      label: 'Colaborativa',
      group: HIGH_PAYMENT_INTENTION,
      country: ['CL', 'MX'],
    },
    {
      key: LOW_PAYMENT_CAPACITY,
      label: 'Sin capacidad de pago',
      group: LOW_PAYMENT_CAPACITY,
      country: ['CL', 'MX'],
    },
    {
      key: MEDIUM_PAYMENT_CAPACITY,
      label: 'Capacidad de pago parcial',
      group: MEDIUM_PAYMENT_CAPACITY,
      country: ['CL', 'MX'],
    },
    {
      key: HIGH_PAYMENT_CAPACITY,
      label: 'Con capacidad total',
      group: HIGH_PAYMENT_CAPACITY,
      country: ['CL', 'MX'],
    },
    {
      key: UNKNOWN_CONTACTABILITY,
      label: 'Desconocida',
      group: UNKNOWN_CONTACTABILITY,
      country: ['CL', 'MX'],
    },
    {
      key: UNKNOWN_PAYMENT_INTENTION,
      label: 'Desconocida',
      group: UNKNOWN_PAYMENT_INTENTION,
      country: ['CL', 'MX'],
    },
    {
      key: UNKNOWN_PAYMENT_CAPACITY,
      label: 'Desconocida',
      group: UNKNOWN_PAYMENT_CAPACITY,
      country: ['CL', 'MX'],
    },
    {
      key: PENDING,
      label: 'Pendiente',
      group: PENDING,
      country: ['CL', 'MX'],
    },
  ],
};
export const SORTED_STATUS_GROUP = [
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  STATUS_TRANSFERRED,
  STATUS_PAID,
  STATUS_REJECTED,
];

export const GLOBAL_REASONS = [
  {
    type: 'INVOICE_HARD_COLLECTION',
    label: 'Factura Cancelada',
    value: 'CANCELED_INVOICE',
    country: 'MX',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    label: 'Factura Reemplazada',
    value: 'REPLACED_INVOICE',
    country: 'MX',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    label: 'Nota de Crédito',
    value: 'CREDIT_NOTES',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    label: 'Cliente en SAT69',
    value: 'SAT69',
    country: 'MX',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'DEFAULT',
    label: 'Mora',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'JUDICIARY_COLLECTION',
    label: 'Cobranza Judicial',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'NORMALIZATION',
    label: 'Normalización',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'REPORTED_IN_DICOM',
    label: 'Reportado en Dicom',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'REJECTED',
    label: 'Rechazada',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'CANCELED',
    label: 'Anulada',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'UNCONTACTABLE_PAYER',
    label: 'Pagador Incontactable',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'PAID_TO_CLIENT',
    label: 'Pagado a Cliente',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'CLAIMED_WITHOUT_MERIT',
    label: 'Reclamada Sin merito',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'CLAIMED',
    label: 'Reclamada',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'DOES_NOT_ACCEPT_FACTORING',
    label: 'No Acepta Factoring',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'PAYMENT_VV_CLIENT',
    label: 'Pago VV Cliente',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'INVOICE_IN_PAYMENT_PLAN',
    label: 'Factura en plan de pago',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'PAYER_WITH_PAYMENT_PROBLEMS',
    label: 'Pagador con problemas de pago',
  },
  {
    type: 'INVOICE_HARD_COLLECTION',
    country: 'CL',
    value: 'INVOICE_WITHOUT_ASSIGMENT',
    label: 'Factura sin cesión',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Operación riesgosa',
    value: 'HIGH_RISK',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Mora',
    value: 'MORA',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Riesgo alto deudor (Rojo Riesgo pagador)',
    value: 'HIGH_RISK_PAYER',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Riesgo alto cliente (Rojo Riesgo cliente)',
    value: 'HIGH_RISK_CLIENT',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Riesgo relación (Rojo Riesgo relación)',
    value: 'HIGH_RISK_RELATION',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Concentración Cliente (matriz)',
    value: 'CLIENT_CONCENTRATION',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Concentración Pagador (matriz)',
    value: 'PAYER_CONCENTRATION',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Concentración Fondo (matriz)',
    value: 'FUND_CONCENTRATION',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Rojo Cobranza pagador',
    value: 'RED_COLLECTION_PAYER',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Rojo Cobranza cliente',
    value: 'RED_COLLECTION_CLIENT',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Portfolio',
    value: 'PORTFOLIO',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'No se logró confirmar',
    value: 'NOT_BE_CONFIRMED',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'No cumple reglas PP',
    value: 'NOT_RULES_PP',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Estado Critico (NC, Anulada, Reclamada)',
    value: 'CRITICAL_STATE',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Dicom Financiero',
    value: 'DICOM',
    country: 'CL',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Sin fondo de salida',
    value: 'WITHOUT_FUND',
    country: 'CL',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Confirmada pero pagador pagará a Cliente',
    value: 'CONFIRMED_BUT_PAYER_WILL_PAY_CUSTOMER',
    country: 'CL',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Confirmada pero Pagador pagó a Cliente',
    value: 'CONFIRMED_BUT_PAYER_PAID_CUSTOMER',
    country: 'CL',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Buró de crédito',
    value: 'BURO',
    country: 'MX',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Rechazo por mora',
    value: 'REJECTED_BY_MORA',
    country: 'MX',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Sin línea de crédito disponible',
    value: 'WITHOUT_CREDIT_LINE',
    country: 'MX',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Exceso de concentración en fondo',
    value: 'EXCESS_CONCENTRATION_FUND',
    country: 'MX',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'No cumple con criterio de elegibilidad',
    value: 'NOT_ELIGIBILITY_CRITERIA',
    country: 'MX',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Cliente no firma documentos legales',
    value: 'CLIENT_NOT_SIGN_LEGAL_DOCUMENTS',
    country: 'MX',
  },
  {
    type: 'INVOICE_REJECTED',
    label: 'Documentos falsos',
    value: 'FAKE_DOCUMENTS',
    country: 'MX',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'PRODUCTS_AND_OR_SERVICES_OK',
    label: 'Productos y/o servicios OK',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'PAYMENT_DATE',
    label: 'Con fecha y/o días estimados de pago',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'ACCOUNTED',
    label: 'Contabilizada',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['MX'],
    value: 'BY_PHONE',
    label: 'Por teléfono',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL'],
    value: 'BY_PHONE_WITH_PAYMENT_DATE',
    label: 'Telefónica - Productos y/o servicios ok + días o fecha de pago',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL'],
    value: 'BY_PHONE_WITHOUT_PAYMENT_DATE',
    label: 'Telefónica- Productos y/o servicios ok sin días o fecha de pago',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL'],
    value: 'BY_PHONE_ACCOUNTED',
    label: 'Telefónica- Contabilizada',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'FOR_IRREVOCABILITY',
    label: 'Irrevocabilidad',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'BY_ELECTRONIC_ACKNOWLEDGMENT',
    label: 'Acuse de recibo electrónico',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'PROTOCOL_PAYER',
    label: 'Protocolo Pagador',
  },
  {
    type: 'INVOICE_VERIFIED',
    country: ['CL', 'MX'],
    value: 'BY_API',
    label: 'Vía API',
  },
  {
    type: 'INVOICE_VERIFICATION_WITHOUT_AMOUNT',
    country: ['CL', 'MX'],
    value: 'PAYMENT_DATE',
    label: 'Fecha de pago',
  },
  {
    type: 'INVOICE_POST_VERIFIED',
    country: ['CL', 'MX'],
    value: 'RECURRING_RELATIONSHIP',
    label: 'Relación recurrente',
  },
  {
    type: 'INVOICE_POST_VERIFIED',
    country: ['CL', 'MX'],
    value: 'NOT_ANSWER_SENT_MAIL',
    label: 'No contestan correo enviado',
  },
  {
    type: 'INVOICE_DELETED',
    value: 'CLIENT_NOT_ACCEPT_RATE',
    label: 'Cliente no aceptó tasa',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_DELETED',
    value: 'CLIENT_CANCELED_OPERATION',
    label: 'Cliente anuló por velocidad',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_DELETED',
    value: 'UNCONTACTABLE_CUSTOMER',
    label: 'Cliente no contactable',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_DELETED',
    value: 'CESSION_PFX',
    label: 'Cesión PFX',
    country: 'CL',
  },
  {
    type: 'INVOICE_DELETED',
    value: 'PARALLEL_OPERATIONS',
    label: 'Operaciones paralelas',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_DELETED',
    value: 'OTHER',
    label: 'Otra',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_APPEALED',
    value: 'CONFIRMATION',
    label: 'Confirmación',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_APPEALED',
    value: 'COMMERCIAL',
    label: 'Comercial',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_APPEALED',
    value: 'COLLECTION',
    label: 'Cobranza',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_APPEALED',
    value: 'DEBT',
    label: 'Mora',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_APPEALED',
    value: 'LINE_UNAVAILABLE',
    label: 'Línea no disponible',
    country: ['CL', 'MX'],
  },
  {
    type: 'INVOICE_APPEALED',
    value: 'BGC',
    label: 'BGC',
    country: ['CL', 'MX'],
  },
];

export const REASON_XTREE = {
  type: 'INVOICE_POST_VERIFIED',
  value: 'X_TREE',
  label: 'X-tree',
  country: ['CL'],
};

export const GLOBAL_REASONS_WITH_XTREE = [...GLOBAL_REASONS, REASON_XTREE];

// COLLECTION COMMENTS
export const COMMENT_TYPES = [
  {
    type: 'Fondo',
    key: 'FUND',
    style: {
      background: '#A8EFBB',
      color: '#119B41',
      font: 'Bold 11px/33px Muli',
    },
  },
  {
    type: 'Pagador',
    key: 'PAYER',
    style: {
      background: '#D3DDFD',
      color: '#1A49E7',
      font: 'Bold 11px/33px Muli',
    },
  },
  {
    type: 'Xepelin',
    key: 'XEPELIN',
    style: {
      background: '#E0D3FD',
      color: '#900CF9',
      font: 'Bold 11px/33px Muli',
    },
  },
  {
    type: 'Cliente',
    key: 'CLIENT',
    style: {
      background: '#FFD2AC',
      color: '#E66404',
      font: 'Bold 11px/33px Muli',
    },
  },
];

// EMAILS
export const COLLECTION_EMAIL_ICONS = [
  {
    image: PayerCollection,
    text: 'Cobranza Pagador',
    name: 'payerCollection',
  },
  {
    image: ActiveCollection,
    text: 'Cobranza activa',
    name: 'activeCollection',
  },
  { image: PreDicomIcon, text: 'Pre-Dicom', name: 'preDicom' },
  { image: DicomIcon, text: 'Dicom', name: 'dicom' },
  {
    image: ConfirmDateIcon,
    text: 'Confirmar Fecha',
    name: 'confirmDate',
  },
  {
    image: ConfirmCesionIcon,
    text: 'Confirmar Cesión ',
    name: 'confirmCesion',
  },
  {
    image: CriticalState,
    text: 'Estado Crítico',
    name: 'criticalState',
  },
];

// COLORS  ===NO USAR===
export const WHITE_BORDER_COLOR = '#EEE';
export const SECOND_WHITE_COLOR = '#F3F2F5';
export const GRAY_COLOR = '#575360';
export const WHITE_PURE_COLOR = '#FFFF';
export const GRAY_WHITE_COLOR = '#eff0f1';
export const RED_ROW_COLOR = '#ff00000d';
export const BLUE_ROW_COLOR = '#F5F7FF';
export const GRAY_PURPLE_COLOR = '#6C6776';
export const MANATEE_COLOR = '#A8A4B3';
export const GRAY_BORDER_COLOR = '#E4E1E9';

// TYPES STATUS CHANGE DIALOG
export const ITEM_TYPE_CHECK_INVOICE = 'checkInvoice';
export const ITEM_TYPE_ORDER = 'order';
export const ITEM_TYPE_INVOICE = 'factura';
export const ITEM_TYPE_BULK_INVOICE = 'facturasBulk';
export const ITEM_TYPE_CREDIT = 'credit';
export const ITEM_TYPE_NEW_CREDIT = 'newCredit';
export const ITEM_TYPE_FEE = 'fee';
export const DIALOG_TYPES = {
  ACCOUNTING: 'accounting',
  INVOICE: 'invoice',
  BULK_FUND_INVOICE: 'fundInvoice',
  FACTURASBULK: ITEM_TYPE_BULK_INVOICE,
  ORDERCREDIT: 'orderCredit',
  ORDER: ITEM_TYPE_ORDER,
  TRANSFERENCIA: 'transferencia',
};

// SETTINGS
export const DECIMAL_PRECISION = 2;
export const MAX_DECIMALS = 4;
export const DAYS_MONTH = 30;

// METABASE URL
export const METABASE_URL = 'https://analytics.xepelin.com';

// DATASTUDIO URL
export const DATASTUDIO_URL = 'https://datastudio.google.com';

// TYPE FILES
export const CONTRACT = 'CONTRACT';
export const CONTRACT_LABEL = 'Contrato';
export const LEGAL_REPORT = 'LEGAL_REPORT';
export const LEGAL_REPORT_LABEL = 'Informe legal';
export const COMPLIANCE = 'COMPLIANCE';
export const COMPLIANCE_DOWNLOAD = 'COMPLIANCE_DOWNLOAD';
export const COMPLIANCE_LABEL = 'Compliance';
export const SUPER_DEBT = 'Deuda Financiera';

export const TYPE_FILES = {
  [CONTRACT]: {
    label: CONTRACT_LABEL,
  },
  [LEGAL_REPORT]: {
    label: LEGAL_REPORT_LABEL,
  },
  [COMPLIANCE]: {
    label: COMPLIANCE_LABEL,
  },
};

// VARIANT NOTIFICATIONS
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';

// CESSIONARIES
export const XCAPITAL_CESSIONARY = 'X CAPITAL SPA';

// MESSAGES
export const NO_INFO = 'Sin Información';
export const NO_DATA = 'No hay información disponible';
export const EMAILS_PLACEHOLDER = 'Ingrese los emails...';
export const UNKNOWN_ERROR = 'Error desconocido';
export const UNKNOWN_ERROR_CODE = 'UNKNOWN_ERROR';
export const CHECKBOX_MESSAGE = 'Confirmo que deseo continuar';
export const LABEL_NO_ORDERS_REPLACE = 'No hay ordenes para reemplazar';
export const LABEL_MUST_SELECT_ORDER = 'Debe seleccionar una orden';
export const LABEL_TOTAL_AMOUNT_CREDIT_NOTES = 'Monto total notas de Crédito';
export const ADD_PAYER = 'Pagador Agregado!';
export const ERROR_ADD_PAYER = 'Error al registrar pagador';
export const ERROR_DELETE_CONTACT_PAYER = 'Error al eliminar un contacto';
export const ERROR_CREATE_CONTACT_PAYER = 'Error al crear un contacto';
export const ERROR_EDIT_CONTACT_PAYER = 'Error al editar un contacto';
export const ADD_FEES = 'Fees Guardado';
export const RESET_FEES = 'Fees Reseteado';
export const ERROR_ADD = 'Error al Guardar';
export const ERROR_RESET = 'Error al Actualizar';
export const ERROR_ITEM_SELECTED = 'Debe seleccionar al menos un rol';
export const MESSAGE_FIELD_NOT_EMPTY = 'El campo no puede estar vacío';

// ROLES
export const ROLE_COLLECTION = 'ROLE_COLLECTION';

// FILTERS
export const BLOCKED_FILTERS = [
  'status',
  'Business.totalOrdersTransferred',
  'montoTransferencia',
  'intereses',
  'tasaBase',
  'fechas',
  'detMntTotal',
  'assignor',
  'transfer',
  'interest',
  'baseRate',
];
export const VERIFICATION_MENU_FILTER = ['Si', 'No'];
export const MORA_RANGE_FILTER = ['Vigente', '0-30', '30-60', '60-90', '>90'];

export const TGR = 'TGR';
export const PREVIRED = 'PREVIRED';

export const ADMIN_ROLE = 'admin';
export const COMMERCIAL_ROLE = 'comercial';
export const COMMERCIAL_SUPERVISOR_ROLE = 'comercialSupervisor';
export const RISK_ROLE = 'riesgo';
export const RISK_SUPERVISOR_ROLE = 'riesgoSupervisor';
export const OPERATIONS_ROLE = 'operaciones';
export const OPERATIONS_SUPERVISOR_ROLE = 'operacionesSupervisor';
export const TREASURY_ROLE = 'tesoreria';
export const TREASURY_SUPERVISOR_ROLE = 'tesoreriaSupervisor';
export const COLLECTION_ROLE = 'cobranza';
export const COLLECTION_SUPERVISOR_ROLE = 'cobranzaSupervisor';
export const COUNTER_ROLE = 'contabilidad';
export const VIEWER_ROLE = 'lector';

// SECTIONS
export const SECTIONS_ARRAY = [
  { key: 'orders', label: 'Permisos de operaciones' },
  { key: 'invoices', label: 'Permisos de facturas' },
  { key: 'credits', label: 'Permisos de creditos' },
  { key: 'business', label: 'Permisos de empresa' },
  { key: 'collection', label: 'Permisos de cobranza' },
  { key: 'criticalStates', label: 'Permisos de estados críticos' },
  { key: 'funds', label: 'Permisos de fondos' },
  { key: 'blocklist', label: 'Permisos de blocklist' },
  { key: 'payers', label: 'Permisos de pagadores' },
  { key: 'agreements', label: 'Permisos de convenios' },
  { key: 'users', label: 'Permisos de usuarios' },
  { key: 'wallets', label: 'Permisos de wallets' },
  { key: 'stakeholders', label: 'Permisos de stakeholders' },
  { key: 'orderRates', label: 'Permisos sobre tasa de interés' },
  { key: 'operationCost', label: 'Permisos sobre asesoría legal' },
  { key: 'reports', label: 'Permisos de reportes' },
];
export const STATUS_PERMISSION_ARRAY = {
  ORDER: {
    label: 'ordenes',
    statuses: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPEALED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
      STATUS_TO_DEPOSIT,
      STATUS_ACTIVE,
      STATUS_DEFAULT,
      STATUS_PAID,
      STATUS_REJECTED,
      STATUS_COMPLETE,
      STATUS_PV_APPROVED,
      STATUS_RENEGOTIATION,
    ],
  },
  INVOICE: {
    label: 'facturas',
    statuses: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPEALED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
      STATUS_TO_DEPOSIT,
      STATUS_ACTIVE,
      STATUS_DEFAULT,
      STATUS_PAID,
      STATUS_REJECTED,
      STATUS_COMPLETE,
      STATUS_SOFT_DELINQUENCY,
      STATUS_HARD_DELINQUENCY,
      STATUS_DELINQUENT,
      STATUS_HARD_COLLECTION,
      STATUS_REPLACED,
      STATUS_CEDE,
      STATUS_IN_CONCILIATION,
    ],
  },
  CREDIT: {
    label: 'creditos',
    statuses: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
      STATUS_TO_DEPOSIT,
      STATUS_PENDING,
      STATUS_ACTIVE,
      STATUS_PAID,
      STATUS_COMPLETE,
      STATUS_REJECTED,
    ],
  },
  FEE: {
    label: 'cuotas',
    statuses: [STATUS_ACTIVE, STATUS_PAID, STATUS_REJECTED],
  },
};

// KEY_CODES
export const KEY_CODES = {
  ESCAPE: 27,
  DELETE: 46,
  DOWN_ARROW: 40,
};

// TITLES
export const TITLE_INVOICE_CHECK = 'Verificar factura';
export const TITLE_DIALOG_CHANGE_STATUS =
  'Selecciona el nuevo estado, confirma y guarda';
export const BULK_LOAD_ACCOUNTING = 'Carga masiva Contabilidad';
export const MASS_UPDATE_INVOICES = 'Actualización masiva de Facturas';
export const MASS_UPDATE_INVOICES_FUNDS = 'Actualización masiva de Fondos';
export const SEND_SIMULATIN_EMAIL = 'Enviar simulación por email';
export const ENTER_NECESSARY_DATA = 'Ingrese los datos necesarios';
export const ENTER_DISCOUNT_AMOUNT_CONFIRM_AND_SAVE =
  'Ingrese un monto y razón de descuento, confirme y guarde';
export const TITLES_NOT_FOUND = {
  CONTENT_NOT_EXIST: 'El contenido que buscas no existe',
  CHECK_IF_LINK_IS_CORRECT:
    'Verifica si el enlace es correcto o busca nuevamente en el listado de operaciones.',
};
// COUNTRIES
export const COUNTRIES_SETTING_LIST = ['CL', 'MX', 'pe', 'co', 'ar', 'br'];
export const COUNTRIES_PHONE_LIST = ['cl', 'cl', 'pe', 'co', 'ar', 'br'];
export const DEFAULT_PHONE_COUNTRY = 'cl';
export const DEFAULT_COUNTRY = 'CL';
export const ACTIVE_COUNTRIES_LIST = [
  {
    key: 'CL',
    name: 'Chile',
    currency: 'CLP',
  },
  {
    key: 'MX',
    name: 'México',
    currency: 'MXN',
  },
];
export const CURRENCY = {
  CL: 'CLP',
  MX: 'MXN',
  US: 'USD',
};

// CONTACT TYPE
export const CONTACT_TYPE = {
  CONFIRMATION: 'CONFIRMATION',
  PAYMENT: 'PAYMENT',
};

export const SIN_DESCUENTO = 'SIN_DESCUENTO';

// ACCOUNT TYPE TRANSFORM

export const TRANSFORMED_ACCOUNT_TYPES = {
  CUENTA_CORRIENTE: 'Cuenta corriente',
  CUENTA_VISTA: 'Cuenta vista',
  CUENTA_AHORRO: 'Cuenta ahorro',
  CUENTA_CLABE: 'Cuenta CLABE',
};

// ACCOUNT TYPES

export const ACCOUNT_TYPES = {
  CL: [
    { value: 'CUENTA_CORRIENTE', label: 'Cuenta corriente' },
    { value: 'CUENTA_VISTA', label: 'Cuenta vista' },
    { value: 'CUENTA_AHORRO', label: 'Cuenta ahorro' },
  ],
  MX: [{ value: 'CUENTA_CLABE', label: 'Cuenta CLABE' }],
};

// WALLET

export const WALLET_STATUS = [
  'REQUESTED',
  'WALLET_ACTIVE',
  'TO_DEPOSIT',
  'WALLET_INACTIVE',
  'WALLET_ACCEPTED',
];

export const WALLET_STATUS_SELECT = [
  { value: 'REQUESTED', label: 'Solicitado' },
  { value: 'WALLET_ACCEPTED', label: 'Aceptado' },
  { value: 'TO_DEPOSIT', label: 'Por depositar' },
  { value: 'WALLET_ACTIVE', label: 'Activo' },
  { value: 'WALLET_INACTIVE', label: 'Inactivo' },
];

export const WALLET_TRANSACTION_TYPES = [
  { value: 'EXCEDENTE', label: 'Excedentes' },
  { value: 'PAGO_EXCEDENTE', label: 'Pago de excedentes' },
  { value: 'CXC', label: 'Cuentas por cobrar' },
  { value: 'PAGO_CXC', label: 'Pago de cuentas por cobrar' },
  { value: 'DEUDA_PENDIENTE', label: 'Deuda pendiente' },
  { value: 'PAGO_DEUDA_PENDIENTE', label: 'Pago de deuda pendiente' },
  { value: 'CONDONACION', label: 'Condonación' },
];

export const WALLET_REQUEST_COMMENTS = [
  'Facturas con Notas de crédito',
  'Factura pagada a cliente',
  'Cliente debe intereses',
  'Deuda Pendiente',
];

export const WALLET_REQUEST_STATUS = [
  { value: 'PENDING', label: 'Vigente' },
  { value: 'ACCOMPLISHED', label: 'Realizado' },
  { value: 'REJECTED', label: 'Rechazado' },
  { value: 'ACCEPTED', label: 'Aprobado' },
];

export const WALLET_ACTIVITIES = {
  EXCEDENTE: 'Excedentes',
  PAGO_EXCEDENTE: 'Pago de excedentes',
  DEUDA_PENDIENTE: 'Deuda pendiente',
  PAGO_DEUDA_PENDIENTE: 'Pago de deuda pendiente',
  CXC: 'Cuentas por cobrar',
  PAGO_CXC: 'Pago de cuentas por cobrar',
  CONDONACION: 'Condonación',
};

export const WALLET_ACCOUNT_TYPES = {
  BUSINESS: 'Pyme',
  FUND: 'Fondo',
  PAYER: 'Corporativo',
};

export const REQUEST_STATUSES = {
  ACCOMPLISHED: 'Realizada',
  REJECTED: 'Rechazada',
  PENDING: 'Vigente',
};

export const TRANSACTION_AMOUNT_TYPES = [
  { value: 'CARGO', label: 'Cargo' },
  { value: 'ABONO', label: 'Abono' },
];

export const COUNTRY_CODE_MX = 'MX';
export const COUNTRY_CODE_CL = 'CL';

export const AVAILABLE_COUNTRIES = [COUNTRY_CODE_CL, COUNTRY_CODE_MX];

// STEPPER VARIANT

export const STEPPER_PRIMARY = 'primary';
export const STEPPER_SUCCESS = 'success';

// CREDITS

export const CREDIT_PERIODS = {
  BIANNUAL: 'Semestral',
  QUARTERLY: 'Trimestral',
  MONTHLY: 'Mensual',
  BIWEEKLY: 'Quincenal',
};

export const CREDIT_TYPES = {
  NORMAL: 'Normal',
  RENEGOTIATION: 'Refinanciamiento',
};

export const INVOICE_STATUS_CL = {
  IN_REVIEW,
  REVIEWED,
  APPEALED,
  APPROVED,
  APPROVED_FUND,
  TO_DEPOSIT,
  ACTIVE,
  DEFAULT,
  PAID,
  REJECTED,
  COMPLETE,
  SOFT_DELINQUENCY,
  HARD_DELINQUENCY,
  DELINQUENT,
  HARD_COLLECTION,
  REPLACED,
  CEDE,
  IN_CONCILIATION,
  PV_APPROVED,
  RENEGOTIATION,
};
export const INVOICE_STATUS_MX = {
  IN_REVIEW,
  REVIEWED,
  APPEALED,
  APPROVED,
  APPROVED_FUND: PENDING_SIGNATURE,
  TO_DEPOSIT,
  ACTIVE,
  DEFAULT,
  PAID,
  REJECTED,
  COMPLETE,
  SOFT_DELINQUENCY,
  HARD_DELINQUENCY,
  DELINQUENT,
  HARD_COLLECTION,
  REPLACED,
  CEDE,
  IN_CONCILIATION,
  PV_APPROVED,
  RENEGOTIATION,
};

export const INVOICE_DESCRIPTIONS_DETAILS = {
  paymentMethod: {
    'PPD-CCA': 'Con complemento de pago y con adeudo',
    'PPD-CSA': 'Con complemento de pago y sin adeudo',
  },
  voucherType: {
    E: 'Egreso',
    N: 'Nómina',
    T: 'Traslado',
    P: 'Pago',
  },
  useCFDI: {
    G02: 'Devoluciones, descuentos o bonificaciones',
    D10: 'Pagos por servicios educativos (colegiaturas)',
    D09: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
    D08: 'Gastos de transportación escolar obligatoria',
    D07: 'Primas por seguros de gastos médicos',
    D06: 'Aportaciones voluntarias al SAR',
    D05: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
    D04: 'Donativos',
    D03: 'Gastos funerales',
    D02: 'Gastos médicos por incapacidad o discapacidad',
  },
  paymentType: {
    '01': 'Efectivo',
    '02': 'Cheque nominativo',
    30: 'Aplicación de anticipos',
    29: 'Tarjeta de servicios',
    28: 'Tarjeta de débito',
    27: 'A satisfacción del acreedor',
    26: 'Prescripción o caducidad',
    25: 'Remisión de deuda',
    24: 'Confusión',
    23: 'Novación',
    17: 'Compensación',
    15: 'Condonación',
    14: 'Pago por consignación',
    13: 'Pago por subrogación',
    12: 'Dación en pago',
    '08': 'Vales de despensa',
  },
  taxRegime: {
    603: 'Personas Morales con Fines no Lucrativos',
    605: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
    606: 'Arrendamiento',
    608: 'Demás ingresos',
    609: 'Consolidación',
    610: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
    611: 'Ingresos por Dividendos (socios y accionistas)',
    614: 'Ingresos por intereses',
    615: 'Régimen de los ingresos por obtención de premios',
    616: 'Sin obligaciones fiscales',
    620: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    621: 'Incorporación Fiscal',
    622: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    623: 'Opcional para Grupos de Sociedades',
    624: 'Coordinados',
    625: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    628: 'Hidrocarburos',
    607: 'Régimen de Enajenación o Adquisición de Bienes',
    629: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    630: 'Enajenación de acciones en bolsa de valores',
  },
};

// SEARCHBAR COMPONENT
export const SEARCHBAR_TIMEOUT = 1400;

export const CIVILSTATUS = [
  { country: 'CL', value: 'SOLTERO', label: 'Soltero/a' },
  {
    country: 'CL',
    value: 'CASADO_CONYUGAL',
    label: 'Casado/a con sociedad conyugal',
  },
  { country: 'CL', value: 'CASADO', label: 'Casado/a' },
  { country: 'CL', value: 'DIVORCIADO', label: 'Divorciado/a' },
  { country: 'CL', value: 'SEPARADO', label: 'Separado/a' },
  { country: 'CL', value: 'VIUDO', label: 'Viudo/a' },
  { country: 'MX', value: 'SOLTERO', label: 'Soltero/a' },
  { country: 'MX', value: 'CASADO', label: 'Casado/a' },
  { country: 'MX', value: 'VIUDO', label: 'Viudo/a' },
  {
    country: 'MX',
    value: 'SEPARACION_EN_PROCESO_JUDICIAL',
    label: 'Separación en Proceso Judicial',
  },
  { country: 'MX', value: 'DIVORCIADO', label: 'Divorciado/a' },
  { country: 'MX', value: 'CONCUBINATO', label: 'Concubinato' },
];

// MIMETYPES
export const MIMETYPE_IMG_DOC_XLSX_XML =
  '.pdf, image/jpeg, image/png, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xml, .doc, .docx';

// DOCUMENT TYPES
export const SIGNATURE_TYPES = {
  CL: [
    { value: null, label: '-' },
    { value: 'TOC', label: 'TOC' },
    { value: 'PHYSICAL', label: 'Física' },
  ],
  MX: [
    { value: null, label: '-' },
    { value: 'TRATO', label: 'Trato' },
    { value: 'MIFIEL', label: 'Fiel' },
    { value: 'PHYSICAL', label: 'Física' },
  ],
};

export const SIGNED_DOCUMENT = 'SIGNED_DOCUMENT';
export const REVIEW_DOCUMENT = 'REVIEW_DOCUMENT';
export const SIGNABLE_DOCUMENT = 'SIGNABLE_DOCUMENT';
export const ELECTRONIC_SIGNATURE_DOCUMENT = 'ELECTRONIC_SIGNATURE_DOCUMENT';
export const INVOICE_DOCUMENT = 'INVOICE_DOCUMENT';
export const CYA_ORDER_INVOICE_DOCUMENT = 'CYA_ORDER_INVOICE_DOCUMENT';
export const CYA_CREDIT_INVOICE_DOCUMENT = 'CYA_CREDIT_INVOICE_DOCUMENT';

// ELECTRONIC SIGNATURE
export const MIFIEL = 'MiFiel';

// DOCUMENTS
// DOCUMENTS ACTIONS
export const MIFIEL_ON = 'MIFIEL_ON';
export const MIFIEL_OFF = 'MIFIEL_OFF';
export const UPLOAD = 'UPLOAD';
export const SIGN = 'SIGN';
export const DOWNLOAD = 'DOWNLOAD';
export const BACKUP = 'BACKUP';

export const BLOCKLIST_REASONS = [
  {
    label: 'Default Interno',
    value: 'HARD_DELINQUENCY',
  },
  {
    label: 'Default',
    value: 'DEFAULT',
  },
  {
    label: 'Concentración',
    value: 'CONCENTRATION',
  },
  {
    label: 'Concentración Riesgosa',
    value: 'RISKY_CONCENTRATION',
  },
  {
    label: 'Fraude',
    value: 'FRAUD',
  },
  {
    label: 'Carga Masiva',
    value: 'BULK_UPLOAD',
  },
  {
    label: 'Probable Fraude',
    value: 'SUSPECTED_FRAUD',
  },
  {
    label: 'Despacho de cobranza',
    value: 'COLLECTION_OFFICE',
  },
  {
    label: 'Colaborador Conflicto de Interés',
    value: 'COLLABORATOR_CONFLICT_OF_INTEREST',
  },
];

export const BLOCKLIST_REASONS_CL = [
  {
    label: 'Aumento Dicom',
    value: 'DICOM_INCREASE',
  },
];

export const CESSION_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
};

export const RISK = {
  HIGH: 'Alto',
  MEDIUM: 'Medio',
  LOW: 'Bajo',
};

export const REJECTED_DOC_REASONS = {
  CL: [
    {
      label: 'Documento sin firma de Representante Legal',
      value: 'DOC_WITHOUT_SIGN_LEGAL_REPRESENTATIVE',
    },
    {
      label: 'Documento sin fecha',
      value: 'DOC_WITHOUT_DATE',
    },
    {
      label: 'Documento sin firma',
      value: 'DOC_WITHOUT_SIGN',
    },
    {
      label: 'Dirección incompleta',
      value: 'INCOMPLETE_ADDRESS',
    },
    {
      label: 'Razón social o Nombre de representante incompleto',
      value: 'BUSINESS_OR_REPRESENTATIVE_INCOMPLETE',
    },
    {
      label: 'Otra',
      value: 'OTHER',
    },
  ],
  MX: [
    {
      label: 'Pendiente boleta de inscripción RPC',
      value: 'RPC_PENDING_REGISTRATION',
    },
    {
      label:
        'Representante legal sin poder de firmar títulos ni operaciones de crédito',
      value: 'LEGAL_REPRESENTATIVE_WITHOUT_TITLES_SIGN_OR_CREDIT_OPERATIONS',
    },
    {
      label: 'Representante legal sin poder administrativo',
      value: 'LEGAL_REPRESENTATIVE_WITHOUT_ADM_POWER',
    },
    {
      label: 'Representante legal sin ningún poder',
      value: 'LEGAL_REPRESENTATIVE_WITHOUT_POWER',
    },
    {
      label: 'Otra',
      value: 'OTHER',
    },
  ],
};

export const reportingByCountry = {
  risk: {
    CL: {
      reporting: '8bdcbf1a-1a29-4d03-b962-605b088a0f3a',
      field_key: 'ds0.invoiceid_param',
    },
    MX: {
      reporting: 'a760a8e1-a389-4218-8f50-6b65b0c26bdd',
      field_key: 'ds0.invoiceid_param',
    },
  },
  score: {
    CL: {
      reporting: 'a22108c7-878d-46b4-b48a-e39ea5dc562c',
      field_key: 'ds82.orderid',
    },
    MX: {
      reporting: '99002a35-4a77-4918-90d4-2391e5159474',
      field_key: 'ds129.orderid',
    },
  },
};

export const XtreeStatuses = {
  PENDING: {
    label: 'Sin procesar',
    key: 'PENDING',
  },
  DEFAULT: {
    label: 'No aplica',
    key: 'DEFAULT',
  },
  SUCCESS: {
    label: 'Procesado',
    key: 'SUCCESS',
  },
};

export const defaultFundCapitalCostRate = '1.66';

export const rateTypesObj = {
  accounting: 'ACCOUNTING',
  effectiveFundCost: 'EFFECTIVE_FUND_COST',
  capitalCost: 'CAPITAL_COST',
};
