import { Typography, styled, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  spacingLeft: {
    marginLeft: 5,
  },
  roundCorners: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  spacingVertical5: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
  },
  spacingAll: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '25px 8px 0px 8px',
  },
  tooltip: {
    whiteSpace: 'pre-wrap',
    minWidth: 350,
  },
})

export {
  useStyles,
};
