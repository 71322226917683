import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateInvoiceFundService = async ({
  orderInvoiceFundId,
  orderInvoiceFund,
}) => {
  try {
    const { data } = await axios.put(
      `/api/orderinvoicefund/${orderInvoiceFundId}`,
      orderInvoiceFund
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateInvoiceFundService;
