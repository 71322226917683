import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateOrderCommentService } from '../services';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';

const useUpdateOrderComment = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: updateOrderComment,
  } = useMutation(
    ({ orderId, commentId, commentDataToUpdate }) =>
      updateOrderCommentService(orderId, commentId, commentDataToUpdate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getComments'], { exact: true });
        queryClient.invalidateQueries(['getCommentsCounter'], { exact: true });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_COMMENT_UPDATED, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_COMMENT_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    updateOrderComment,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useUpdateOrderComment };
