import axios from 'axios';

interface Props {
  orderInvoices: number[];
  type: string;
  expirationDate: Date | undefined;
  justification: string;
}

const bulkCreatePaymentPromises = async ({
  orderInvoices,
  type,
  expirationDate,
  justification,
}: Props) => {
  try {
    const { data } = await axios.post('/api/collection/payers/extensions', {
      orderInvoices,
      newExpirationDate: expirationDate,
      justification,
      type,
    });
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export default bulkCreatePaymentPromises;