import React from 'react';
import PropTypes from 'prop-types';
import AttributeSwitch from './AttributeSwitch';
import AttributeIcon from './AttributeIcon';
import AttributeCustomValue from './AttributeCustomValue';

const AttributeElement = ({
  title,
  value,
  flagType,
  isEditable,
  handleEdit,
}) => {
  const flagTypeElement = {
    switch: (
      <AttributeSwitch title={title} value={value} handler={handleEdit} />
    ),
    icon: <AttributeIcon title={title} value={value} />,
    custom: (
      <AttributeCustomValue
        title={title}
        value={value}
        isEditable={isEditable}
        handler={handleEdit}
      />
    ),
  };

  return flagTypeElement[flagType];
};

AttributeElement.defaultProps = {
  customValue: undefined,
  handleEdit: () => undefined,
  isEditable: false,
  value: false,
  flagType: 'custom',
};

AttributeElement.propTypes = {
  title: PropTypes.string.isRequired,
  flagType: PropTypes.oneOf(['icon', 'switch', 'custom']),
  value: PropTypes.bool,
  handleEdit: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default AttributeElement;
