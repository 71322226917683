import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import verifyDecimals from '../../../../../../helpers/validation/decimals';
import CheckButton from '../../../../../../components/elements/CheckButton';

export const UpdateFundAdvancePercentageDialog = ({
  isOpen,
  handleCloseDialog,
  title,
  description,
  selectedAdvance,
  handleSubmit,
  isLoading,
}) => {
  const [advancePercentage, setAdvancePercentage] = useState({});
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('verifyMaxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  useEffect(() => {
    setAdvancePercentage(selectedAdvance);
  }, [selectedAdvance]);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={() => {
        handleCloseDialog();
        setChecked(false);
      }}
      title={title}
      description={description}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm
          onSubmit={() => {
            handleSubmit(advancePercentage);
            setChecked(false);
          }}
        >
          <TextValidator
            variant="outlined"
            fullWidth
            label="Porcentaje de avance (%)"
            inputProps={{ min: 0, step: 0.0001, max: 100 }}
            type="number"
            name="advancePercentage"
            value={advancePercentage.advancePercentage}
            aria-describedby="Tasa"
            onChange={e =>
              setAdvancePercentage({
                ...advancePercentage,
                advancePercentage: Number(e.target.value),
              })
            }
            validators={['required', 'isPositive', 'verifyMaxDecimals']}
            errorMessages={[
              'Campo requerido',
              'Valor debe ser mayor a 0',
              'Valor puede contener 4 decimales',
            ]}
          />
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UpdateFundAdvancePercentageDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  description: '',
};

UpdateFundAdvancePercentageDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // Ver bien lo que se pasara para hacer un shape
  selectedAdvance: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
