import { useQuery } from 'react-query';
import { fetchUsersService } from '../services';

const useFetchUsers = () => {
  const { isLoading, isRefetching, data } = useQuery(
    ['fetchUsers'],
    () => fetchUsersService(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const users = data || [];

  return {
    users,
    isLoading: isLoading || isRefetching,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchUsers };
