import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { Grid, MenuItem, styled } from '@material-ui/core';
import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import LoadingButton from '../../../../../../components/elements/LoadingButton';
import usePaymentCessionDialog from './usePaymentCessionDialog';

const ButtonGrid = styled(Grid)({
  padding: '24px 24px 0px 24px',
});

const PaymentCessionDialog = ({ open, handleClose }) => {
  const {
    menuItems,
    handleSubmit,
    handleSelect,
    formHasCession,
    orderAttributeIsUpdating,
    updateOrderAttributeIsSuccess,
    resetOrderAttributeUpdateData,
  } = usePaymentCessionDialog();

  useEffect(() => {
    if (updateOrderAttributeIsSuccess) {
      handleClose();
      resetOrderAttributeUpdateData();
    }
  }, [updateOrderAttributeIsSuccess]);

  return (
    <BaseDialog
      title="Editar tipo payments"
      isOpen={open}
      handleClose={handleClose}
      size="md"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <SelectValidator
          label="Tipo de pago"
          onChange={handleSelect}
          value={formHasCession}
          variant="outlined"
          fullWidth
          style={{ marginBottom: 0 }}
        >
          {menuItems.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SelectValidator>
        <ButtonGrid container justifyContent="center">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={orderAttributeIsUpdating}
            isLoading={orderAttributeIsUpdating}
          >
            Guardar cambios
          </LoadingButton>
        </ButtonGrid>
      </ValidatorForm>
    </BaseDialog>
  );
};

PaymentCessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PaymentCessionDialog;
