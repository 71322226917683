// @ts-nocheck
import React, { ChangeEvent, FC } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  TextField,
  Typography,
  Chip,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOffRounded';
import clsx from 'clsx';
import Skeleton from '../../Skeleton';
import { TitleInputs, useStyles } from '../styles';
import ChipSelector from '../../ChipSelector';
import { User } from '../interfaces';

interface Props {
  isCollapse: boolean;
  users: User[];
  selectedUsers: User[];
  isLoading: boolean;
  isDisabled: boolean;
  handleChangeTagSelector: (
    _e: ChangeEvent<HTMLInputElement>,
    values: User[]
  ) => void;
}

const ChipTag = React.forwardRef(
  ({ label, ...otherProps }: { label: string }, ref) => {
    return (
      <Chip
        component="span"
        deleteIcon={
          <HighlightOffIcon
            style={{ color: '#95929F' }}
            data-cy="orderCommentsTagsDeleteTag"
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        label={
          <Box fontWeight="fontWeightBold" component="span">
            {label}
          </Box>
        }
        ref={ref}
        variant="default"
        style={{
          background: '#E8E6EF',
          color: '#95929F',
          border: 0,
          borderRadius: 8,
          minWidth: 160,
        }}
      />
    );
  }
);

export const OrderCommentsTags: FC<Props> = ({
  isCollapse,
  users,
  selectedUsers,
  isLoading,
  isDisabled,
  handleChangeTagSelector,
}) => {
  const classes = useStyles();
  return (
    <Collapse in={isCollapse} timeout="auto">
      <Box mb={3}>
        <Box display="flex" mb={2}>
          <TitleInputs>Notificar</TitleInputs>
        </Box>
        <Skeleton
          isLoading={isLoading}
          variant="rect"
          width={525}
          height={36}
          animation="wave"
        >
          <ChipSelector
            data-cy="orderCommentsTagsInput"
            values={selectedUsers}
            options={users}
            disabled={isDisabled}
            // @ts-ignore
            onChange={handleChangeTagSelector}
            clearOnBlur
            classes={{ inputRoot: classes.autoCompleteInputRoot }}
            filterOptions={(
              options: User[],
              params: { inputValue: string }
            ) => {
              let filteredOptions = [...options];
              if (params?.inputValue) {
                filteredOptions = options.filter((option: User) =>
                  option.userName
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                );
              }
              return filteredOptions;
            }}
            disableCloseOnSelect
            renderOption={({ userName, email }: User) => (
              <>
                <Checkbox
                  checked={selectedUsers?.some(user => user.email === email)}
                  data-cy="orderCommentsTagsCheckbox"
                />
                <Typography
                  color="textPrimary"
                  variant="body1"
                  data-cy="orderCommentsTagsUserName"
                >
                  {userName}
                </Typography>
              </>
            )}
            renderInput={(params: any) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                data-cy="orderCommentsTagsTextField"
                label="Escribe el nombre de la persona"
                variant="outlined"
                color="primary"
                InputProps={{
                  ...params.InputProps,
                }}
                classes={{
                  root: clsx(
                    classes.textField,
                    selectedUsers?.length ? classes.inputPadding : null
                  ),
                }}
              />
            )}
            renderTags={(tagValue: User[], getTagProps: any) => {
              return tagValue.map((option: User, index: number) => {
                return (
                  <ChipTag
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getTagProps({ index })}
                    label={option.userName}
                  />
                );
              });
            }}
          />
        </Skeleton>
      </Box>
    </Collapse>
  );
};

export default OrderCommentsTags;
