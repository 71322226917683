import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { INVOICES_EDIT_PERFORM } from '../../../../../../helpers/performsType';
import Can from '../../../../../../components/Can';
import StatusBadge from '../../../../../../components/elements/StatusBadge';
import {
  fetchFacturasIdByOrderId,
  fetchOrdersIdByPayerRut,
} from '../../../../../../actions/orderActions';
import { STATUS_REPLACED } from '../../../../../../helpers/Constants';
import { useSetSelectedInvoice, useSetShowInvoiceStatusDialog } from '../store';

const Status = ({ rowData }) => {
  const dispatch = useDispatch();
  const setShowInvoiceStatusDialog = useSetShowInvoiceStatusDialog();
  const setSelectedInvoice = useSetSelectedInvoice();

  const handleShowInvoiceStatusDialog = invoice => {
    const { status, Relationships: relationship } = invoice;
    if (status === STATUS_REPLACED && relationship) {
      const { rutReceptor, mntTotal } = invoice;
      const { orderId } = relationship;
      dispatch(fetchOrdersIdByPayerRut(rutReceptor, mntTotal));
      dispatch(fetchFacturasIdByOrderId(orderId, mntTotal));
    }
    setSelectedInvoice({
      ...invoice,
      tableData: {
        ...invoice.tableData,
      },
    });
    setShowInvoiceStatusDialog(true);
  };

  return (
    <Can
      perform={INVOICES_EDIT_PERFORM}
      yes={() => (
        <StatusBadge
          status={rowData.status}
          handleClick={() => handleShowInvoiceStatusDialog(rowData)}
        />
      )}
      no={() => <StatusBadge status={rowData.status} />}
    />
  );
};

Status.propTypes = {
  rowData: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default Status;
