export const getSelectedSummaryUseCase = ({ currentSelectorState }) => {
  const getTotalSelectedInvoices = () =>
    Object.keys(currentSelectorState).reduce(
      (acc, id) => acc + (currentSelectorState[id]?.value.length || 0),
      0
    );

  const getAmount = () =>
    Object.keys(currentSelectorState).reduce(
      (acc, id) => acc + currentSelectorState[id].amount,
      0
    );

  return {
    amount: getAmount(),
    totalInvoices: getTotalSelectedInvoices(),
    totalReceivers: Object.keys(currentSelectorState).length,
  };
};
