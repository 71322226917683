import { makeStyles } from '@material-ui/core';
import { convertSpacingToCss } from '../../../../../helpers/stylesHelpers';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles(theme => ({
  input: {
    justifyContent: 'left',
    width: '100%',
  },
  containerDetailInformation: {
    padding: '0px 10px',
    textAlign: 'start',
  },
  containerButton: {
    marginTop: 30,
  },
  customWidth: {
    maxWidth: 180,
  },
  help: {
    marginLeft: 10,
  },
  autorate: {
    marginLeft: 5,
  },
  resume: {
    background: theme.palette.grey[100],
    borderRadius: 17,
    padding: 20,
  },
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  containerInformationText: {
    marginLeft: 10,
    marginTop: -8,
    marginBottom: 8,
  },
  spacingInformationText: {
    margin: '0 4px',
  },
  operationResumeTitle: {
    marginBottom: 20,
  },
  containerIssuedDateLabel: {
    marginTop: -10,
  },
  issuedDateLabel: {
    marginBottom: 10,
  },
  labelMargin: {
    marginRight: 5,
  },
}));
