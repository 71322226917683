import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, MenuItem, TextField, styled } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const Dropdown = styled(TextField)(() => ({
  width: '40%',
  marginBottom: 0,
  '& fieldset': {
    borderRadius: '40px 0 0 40px',
    height: '40px',
  },
}));

const SearchBox = styled(TextField)(() => ({
  width: '60%',
  marginBottom: 0,
  '& fieldset': {
    borderRadius: '0 40px 40px 0',
    height: '40px',
  },
}));

const SearchField = ({
  searchKey,
  dropdown,
  dropDownFilters,
  handleSearch,
  handleDropdown,
}) => {
  return (
    <>
      <Dropdown
        select
        variant="outlined"
        value={dropdown}
        onChange={handleDropdown}
      >
        {dropDownFilters.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Dropdown>
      <SearchBox
        variant="outlined"
        value={searchKey}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

SearchField.propTypes = {
  searchKey: PropTypes.string.isRequired,
  dropdown: PropTypes.string.isRequired,
  dropDownFilters: PropTypes.arrayOf(Object).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDropdown: PropTypes.func.isRequired,
};

export default SearchField;
