import { useTranslation } from 'react-i18next';
import {
  DebtManagementTypes,
  PayerCategorizationTypes,
} from '../../../../../commons/interfaces/managements';
import { t13s } from '../../../../../../translationKeys';

const LABELS = t13s.LABEL.COLLECTIONS.MANAGEMENTS_TYPES;

export const useGetManagementTypeLabel = () => {
  const { t } = useTranslation();
  const getTypeLabel = (
    type: DebtManagementTypes | PayerCategorizationTypes
  ): string => {
    const translationKey = LABELS[type as unknown as keyof Object];
    return translationKey ? t(`${translationKey}`) : type;
  };

  return { getTypeLabel };
};
