import * as React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

import {
  BorderColorOutlined,
  ErrorOutlined,
  MoreVertOutlined,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { canEditStateToAppeal } from '../../utils/canEditStateToAppeal';
import { RootState } from '../../../../reducers/rootReducer';
import AppealStateDialog, { RadioSelected } from '../dialogs/AppealStateDialog';
import { GetStatesQuery, GetStatesDto, getStatesQuery } from './query';
import { useGraphQlQuery } from '../../hooks/useGraphQlQuery';
import { useCreateTransitionAction } from '../../hooks/useCreateTransitionAction';
import { noop } from '../../utils';
import { AuthSelector, StateType, TransitionType } from '../../schemas';

type ManageAppealStateProps = {
  businessIdentifier: string;
  workflowId: string;
  currentStateId: string;
  actionEnabled: boolean;
  onSuccess?: (entityId: string, workflowId: string) => void;
};

const ManageAppealState = ({
  businessIdentifier,
  workflowId,
  currentStateId,
  actionEnabled,
  onSuccess = noop,
}: ManageAppealStateProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useSelector((state: RootState) => state.auth as AuthSelector);

  const statesDto: GetStatesDto = {
    legalDeclinedDocsDto: { type: StateType.legalDeclinedDocs },
    legalDocsInReviewDto: { type: StateType.legalDocsInReview },
    legalRequestMoreDocumentsDto: { type: StateType.legalRequestMoreDocuments },
    opsDeclinedDocsDto: { type: StateType.operationDeclinedDocs },
    opsDocsInReviewDto: { type: StateType.operationDocsInReview },
    opsRequestMoreDocumentsDto: { type: StateType.operationRequestMoreDocuments },
    riskLdcAssignedDto: { type: StateType.riskLdcAssigned },
    riskObjectionDto: { type: StateType.riskObjection },
    riskReviewingDto: { type: StateType.riskReviewing },
  };

  const { data: workflowStates } = useGraphQlQuery<GetStatesQuery>('getWorkflowStates', {
    query: getStatesQuery,
    variables: statesDto,
    enabled: openDialog,
  });

  const { create, loading } = useCreateTransitionAction({
    onSuccess(entityId, workflowId) {
      onSuccess(entityId, workflowId);
      setOpenDialog(false);
    },
  });

  const submitHandler = (comment: string) => {
    if (workflowStates) {
      let targetId: string | undefined;
      if (currentStateId === workflowStates.riskObjection?.id) {
        targetId = workflowStates.riskReviewing?.id;
      }

      if (currentStateId === workflowStates.riskLdcAssigned?.id) {
        targetId = workflowStates.riskReviewing?.id;
      }

      if (currentStateId === workflowStates.opsRequestMoreDocuments?.id) {
        targetId = workflowStates.opsDocsInReview?.id;
      }

      if (currentStateId === workflowStates.opsDeclinedDocs?.id) {
        targetId = workflowStates.opsDocsInReview?.id;
      }

      if (currentStateId === workflowStates.legalRequestMoreDocuments?.id) {
        targetId = workflowStates.legalDocsInReview?.id;
      }

      if (currentStateId === workflowStates.legalDeclinedDocs?.id) {
        targetId = workflowStates.legalDocsInReview?.id;
      }

      if (targetId) {
        create({
          businessIdentifier,
          comment,
          currentStateId,
          targetId,
          workflowId,
          type: TransitionType.appealed,
        });
      }
    }
  };

  const handleClickIconButton = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const appealHandler = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        disabled={actionEnabled || !canEditStateToAppeal(user?.roles)}
        onClick={handleClickIconButton}
      >
        <Tooltip title="Más acciones" placement="left">
          <MoreVertOutlined />
        </Tooltip>
      </IconButton>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={appealHandler}>
          <IconButton>
            <ErrorOutlined fontSize="small" />
          </IconButton>
          Apelar estado
        </MenuItem>
        <MenuItem disabled>
          <IconButton>
            <BorderColorOutlined fontSize="small" />
          </IconButton>
          Solicitud de aumento LDC
        </MenuItem>
      </Menu>
      {openDialog && (
        <AppealStateDialog
          loading={loading}
          title="Apelación de estado"
          description="Esta apelación notificará al equipo responsable para que vuelva a revisar el caso"
          onClose={setOpenDialog}
          onSubmit={submitHandler}
        >
          <RadioSelected
            label="Apelación"
            title="Razón del cambio"
          />
        </AppealStateDialog>
      )}
    </React.Fragment>
  );
};

export default ManageAppealState;
