import axios from 'axios';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';
import {
  FETCH_DISCOUNT_START,
  FETCH_DISCOUNT_SUCCESS,
  FETCH_DISCOUNT_FAILURE,
  CREATE_DISCOUNT_START,
  CREATE_DISCOUNT_SUCCESS,
  CREATE_DISCOUNT_FAILURE,
  UPDATE_DISCOUNT_START,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAILURE,
  DELETE_DISCOUNT_START,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_FAILURE,
  FETCH_REPLACEMENT_INVOICES_START,
  FETCH_REPLACEMENT_INVOICES_SUCCESS,
  FETCH_REPLACEMENT_INVOICES_FAILURE,
  RESET_DISCOUNTS,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';

export const resetDiscount = () => dispatch => {
  dispatch({
    type: RESET_DISCOUNTS,
  });
};

export const fetchDiscount = (sourceId, sourceType) => dispatch => {
  dispatch({
    type: FETCH_DISCOUNT_START,
    payload: { sourceTypeDiscount: sourceType },
  });
  axios
    .get(`/api/${sourceType}/${sourceId}/discount`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_DISCOUNT_SUCCESS,
        payload: { discount: data, sourceTypeDiscount: sourceType },
      });
    })
    .catch(() => {
      enqueueSnackbar(t13s.NOTIFICATION.FETCH_DISCOUNT_FAILURE, {
        variant: 'success',
      });
      dispatch({
        type: FETCH_DISCOUNT_FAILURE,
        payload: { sourceTypeDiscount: sourceType },
      });
    });
};

export const fetchReplacementInvoices =
  (operationId, selectorInvoicesId) => dispatch => {
    dispatch({
      type: FETCH_REPLACEMENT_INVOICES_START,
      payload: { operationId, selectorInvoicesId },
    });
    axios
      .get(`api/orders/${operationId}/replacementinvoices`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_REPLACEMENT_INVOICES_SUCCESS,
          payload: {
            operationId,
            selectorInvoicesId,
            replacementInvoices: data,
          },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_REPLACEMENT_INVOICES_FAILURE,
          payload: {
            operationId,
            selectorInvoicesId,
            errorCode,
          },
        });
      });
  };

export const createDiscount =
  (sourceId, sourceType, discountData) => dispatch => {
    dispatch({
      type: CREATE_DISCOUNT_START,
      payload: { sourceTypeDiscount: sourceType },
    });
    axios
      .post(`/api/${sourceType}/${sourceId}/discount`, discountData)
      .then(({ data }) => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DISCOUNT_CREATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: CREATE_DISCOUNT_SUCCESS,
          payload: { discount: data, sourceTypeDiscount: sourceType },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_DISCOUNT_FAILURE,
          payload: { errorCode, sourceTypeDiscount: sourceType },
        });
      });
  };

export const updateDiscount =
  (sourceId, sourceType, discountData) => dispatch => {
    dispatch({
      type: UPDATE_DISCOUNT_START,
      payload: { sourceTypeDiscount: sourceType },
    });
    axios
      .put(`/api/${sourceType}/${sourceId}/discount`, discountData)
      .then(({ data }) => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DISCOUNT_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: UPDATE_DISCOUNT_SUCCESS,
          payload: { discount: data, sourceTypeDiscount: sourceType },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_DISCOUNT_FAILURE,
          payload: { errorCode, sourceTypeDiscount: sourceType },
        });
      });
  };

export const deleteDiscount = (sourceId, sourceType) => dispatch => {
  dispatch({
    type: DELETE_DISCOUNT_START,
    payload: { sourceTypeDiscount: sourceType },
  });
  axios
    .delete(`/api/${sourceType}/${sourceId}/discount`)
    .then(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DISCOUNT_DELETED, {
          variant: 'success',
        })
      );
      dispatch({
        type: DELETE_DISCOUNT_SUCCESS,
        payload: { sourceTypeDiscount: sourceType },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: DELETE_DISCOUNT_FAILURE,
        payload: { errorCode },
      });
    });
};
