import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const XepelinGreen = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="22" height="23" viewBox="0 0 22 23" fill="none">
      <path
        d="M21.9447 8.53471V8.48278L21.932 8.47204C21.864 8.21951 21.7667 7.97557 21.6422 7.74504C21.568 7.60716 21.4992 7.49793 21.4412 7.4084C21.3833 7.31887 21.2963 7.20069 21.2112 7.09683L21.1877 7.07714C20.101 5.73774 17.9966 5 15.289 5C12.5459 5.02517 9.84679 5.68437 7.40731 6.92493C6.34477 7.48378 5.35881 8.17459 4.4734 8.98058C4.35719 8.90602 4.2362 8.83903 4.11119 8.78003C3.77351 8.61453 3.41177 8.50216 3.03904 8.44697C2.87839 8.42016 2.71433 8.42016 2.55368 8.44697L0.83317 8.73526C0.683453 8.76027 0.541465 8.81867 0.41798 8.90603C0.294496 8.99338 0.192759 9.10741 0.120492 9.23944C0.0482245 9.37147 0.00732477 9.51804 0.000896419 9.66803C-0.00553193 9.81803 0.0226801 9.96749 0.0833915 10.1051L1.33483 12.9182L1.3131 12.9576C1.24247 13.0811 1.17365 13.2029 1.11026 13.3157C1.05416 13.4203 1.03997 13.542 1.07051 13.6564C1.10105 13.7709 1.17408 13.8698 1.27507 13.9335L1.60468 14.1322L1.91437 14.3113L2.15705 17.1047C2.18166 17.349 2.29686 17.5756 2.48044 17.741C2.66403 17.9063 2.90301 17.9986 3.15132 18C3.26293 17.9993 3.37366 17.9806 3.47913 17.9445L5.13081 17.3751C5.28462 17.3231 5.42894 17.2469 5.55822 17.1494C5.85721 16.9259 6.11984 16.6583 6.33698 16.3562C6.39131 16.2774 6.44564 16.195 6.49635 16.1091C7.37062 16.3098 8.26188 16.4297 9.15861 16.4672L9.23467 16.5657C9.58388 17.0069 10.0557 17.3378 10.5923 17.5178C11.1288 17.6978 11.7069 17.7192 12.2555 17.5792L15.1079 16.8504C15.558 16.734 15.9587 16.4786 16.2519 16.1215C16.545 15.7643 16.7153 15.324 16.7379 14.8646C19.3838 13.6577 21.2366 12.0354 21.8251 10.4077C21.8251 10.3988 21.8251 10.3898 21.8251 10.3826C21.9106 10.106 21.9641 9.82065 21.9845 9.53209C22.0144 9.19931 22.001 8.86412 21.9447 8.53471Z"
        fill="white"
      />
      <path
        d="M1.69887 13.1885C1.62824 13.3103 1.56304 13.4285 1.50146 13.5467L21.4503 8.60988C21.3917 8.38588 21.3066 8.1695 21.1967 7.96525C21.1261 7.83632 21.0609 7.73426 21.0156 7.66442C20.9521 7.56816 20.8832 7.4755 20.8092 7.38688C16.7977 6.5417 10.6401 7.60354 6.07077 9.80065C4.79486 10.4143 3.58186 11.1482 2.44865 11.9924C2.36715 12.1124 2.28746 12.2287 2.21321 12.3505C2.02305 12.637 1.851 12.9199 1.69887 13.1885Z"
        fill="#93B000"
      />
      <path
        d="M20.8091 7.38691C20.81 7.38277 20.81 7.37851 20.8091 7.37437C18.5598 4.64187 11.982 5.0376 7.60471 7.33677C5.52772 8.449 3.75803 10.0475 2.45044 11.9924C3.58365 11.1483 4.79665 10.4143 6.07256 9.80068C10.64 7.60357 16.7976 6.54173 20.8091 7.38691Z"
        fill="#B8CB37"
      />
      <path
        d="M7.45805 14.6998C6.07479 14.7636 4.68852 14.7037 3.31616 14.5207C5.20675 15.426 7.26912 15.9269 9.36872 15.9909L9.58424 16.2612C9.87672 16.6322 10.2728 16.9103 10.7234 17.0612C11.174 17.212 11.6594 17.229 12.1197 17.11L14.9721 16.3812C15.3718 16.2792 15.7206 16.0374 15.9537 15.7005C16.1868 15.3637 16.2885 14.9547 16.2399 14.5494C18.7337 13.4661 20.7675 11.8885 21.3652 10.2322C18.1361 12.6549 12.3805 14.4652 7.45805 14.6998Z"
        fill="#477900"
      />
      <path
        d="M4.49151 9.59107C4.30303 9.43802 4.09768 9.30647 3.87937 9.19892C3.58424 9.05513 3.26841 8.95729 2.94306 8.90884C2.83204 8.89171 2.71902 8.89171 2.60801 8.90884L0.883881 9.19713C0.805578 9.21021 0.73131 9.24072 0.666701 9.28638C0.602093 9.33203 0.548838 9.39162 0.510971 9.46064C0.473103 9.52965 0.451615 9.60628 0.448135 9.68472C0.444654 9.76316 0.459272 9.84136 0.490882 9.91339L1.83288 12.9324C1.93611 12.7623 2.04477 12.5868 2.16068 12.4077C2.82244 11.3812 3.60483 10.4357 4.49151 9.59107Z"
        fill="#003300"
      />
      <path
        d="M2.60261 17.0653C2.60948 17.1438 2.6344 17.2198 2.67547 17.2873C2.71654 17.3549 2.77267 17.4123 2.83958 17.4551C2.90649 17.4979 2.98241 17.5251 3.06155 17.5345C3.1407 17.5439 3.22097 17.5353 3.29624 17.5094L4.94793 16.94C5.05672 16.9048 5.1589 16.8522 5.25038 16.7842C5.51135 16.5869 5.74037 16.3514 5.92952 16.0858C6.0483 15.9173 6.14963 15.7373 6.23197 15.5487C4.87429 15.2086 3.56719 14.6951 2.34363 14.0212C2.42935 15.0383 2.51568 16.053 2.60261 17.0653Z"
        fill="#003300"
      />
      <path
        d="M21.3561 10.2411C18.136 12.6549 12.3805 14.4652 7.45804 14.6998C6.07477 14.7636 4.6885 14.7037 3.31615 14.5207C2.91409 14.3309 2.54463 14.1393 2.20959 13.9513C2.07376 13.8761 1.94517 13.8027 1.82021 13.7293C1.69525 13.6559 1.60288 13.5986 1.50146 13.5341L21.4503 8.60986C21.5038 8.90034 21.5203 9.19633 21.4992 9.49085C21.4796 9.7454 21.4316 9.99702 21.3561 10.2411Z"
        fill="#679000"
      />
    </SvgIcon>
  );
};

export default XepelinGreen;
