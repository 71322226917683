import { Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { DISCOUNTS } from '../../../../../../helpers/Constants';
import CheckButton from '../../../../../../components/elements/CheckButton';
import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import AlertForm from '../../../../../../components/elements/AlertForm';

const useStyles = makeStyles({
  gridButton: {
    marginBottom: 15,
  },
});

const ORDER_INVOICE = 'orderinvoice';
const ORDER = 'order';

const DiscountDialog = ({
  open,
  handleCloseDialog,
  title,
  subtitle,
  data,
  sourceType,
  sourceId,
  isLoading,
  handleCreateInvoiceDiscount,
  handleUpdateInvoiceDiscount,
  handleDeleteInvoiceDiscount,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [checkedDelete, setCheckedDelete] = useState(false);
  const [discount, setDiscount] = useState(data?.discount);
  const [reason, setReason] = useState(data?.discountReason);

  const { country } = useSelector(state => state.config);

  const {
    updateDiscountError,
    deleteDiscountError,
    createDiscountError,
    updatingInvoice,
  } = useSelector(state => state.order);

  const handleCheckboxChange = () => {
    setChecked(!checked);
    setCheckedDelete(false);
  };

  const handleCheckboxDeleteChange = () => {
    setChecked(false);
    setCheckedDelete(!checkedDelete);
  };

  const handleValueChange = e => {
    setDiscount(e.target.value);
  };

  const handleSubmit = () => {
    const id = !data?.id;
    const body = { discount, discountReason: reason, id };
    if (id) {
      handleCreateInvoiceDiscount({ sourceId, sourceType, body });
    } else {
      handleUpdateInvoiceDiscount({ sourceId, sourceType, body });
    }
  };

  const handleDelete = () => {
    handleDeleteInvoiceDiscount({ sourceId, sourceType });
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={subtitle}
    >
      <ValidatorForm onSubmit={checked ? handleSubmit : handleDelete}>
        <TextValidator
          fullWidth
          variant="outlined"
          id="invoice-discount-amount"
          value={discount}
          onChange={e => handleValueChange(e)}
          label="Monto de descuento"
          name="discount"
        />
        <SelectValidator
          fullWidth
          variant="outlined"
          label="Razón de descuento"
          name="reason"
          value={reason}
          onChange={e => setReason(e.target.value)}
          validators={['required']}
          errorMessages={['Campo requerido']}
        >
          {DISCOUNTS.filter(
            r => r.type === 'BASE_DISCOUNT' && r.country.includes(country)
          ).map(item => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </SelectValidator>
        {(createDiscountError ||
          updateDiscountError ||
          deleteDiscountError) && (
          <AlertForm
            message={
              createDiscountError || updateDiscountError || deleteDiscountError
            }
            variant="error"
          />
        )}

        <Grid item xs={12} className={classes.gridButton}>
          <CheckButton
            check={checked}
            handleCheck={handleCheckboxChange}
            loading={isLoading || updatingInvoice}
            labelButton="Guardar"
          />
        </Grid>
        <Grid item xs={12}>
          <CheckButton
            check={checkedDelete}
            handleCheck={handleCheckboxDeleteChange}
            loading={isLoading || updatingInvoice}
            labelButton="Eliminar descuento"
            variant="error"
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

DiscountDialog.defaultProps = {
  isLoading: false,
  data: null,
};

DiscountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  sourceType: PropTypes.oneOf([ORDER, ORDER_INVOICE]).isRequired,
  sourceId: PropTypes.number.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    discount: PropTypes.number.isRequired,
    discountReason: PropTypes.string.isRequired,
  }),
  isLoading: PropTypes.bool,
  handleCreateInvoiceDiscount: PropTypes.func.isRequired,
  handleUpdateInvoiceDiscount: PropTypes.func.isRequired,
  handleDeleteInvoiceDiscount: PropTypes.func.isRequired,
};

export default DiscountDialog;
