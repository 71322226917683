import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Grid, Box, Tooltip, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import emptyBrowserIcon from '../../assets/icons/emptyBrowserIcon.svg';
import InvoiceOperationResume from './InvoiceOperationResume';
import SearchBarPanel from './SearchBarPanel';
import CountryFormatHelper from './CountryFormatHelper';
import SectionHeader from './SectionHeader';
import InvoicesTableMinified from '../tables/InvoicesTableMinified';
import { ORDER_TYPES_LABEL, COUNTRY_CODE_MX } from '../../helpers/Constants';
import { dateFormatter } from '../../helpers/DateUtils';
import { fetchAvailableBusinessInvoices } from '../../actions/invoiceAction';
import { DRAWER_SECONDARY, ALT_BORDER_COLOR } from '../../theme/otherColors';
import { t13s } from '../../translationKeys';
import Chip from './chips/Chip';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '100%',
    background: DRAWER_SECONDARY.backgroundColor,
  },
  appBar: {
    padding: '19px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.headerBackgroundColor,
  },
  navTitle: {
    flex: '1',
  },
  container: {
    width: '90%',
    padding: '36px 0',
    margin: '0 auto',
  },
  companyContainer: {
    padding: '20px',
  },
  panelContainer: {
    columnGap: '20px',
  },
  emptyResumeContainer: {
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    borderRadius: '15px',
    padding: '20px',
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
    textAlign: 'center',
  },
  emptyResumeIcon: {
    margin: '35px auto 26px auto',
  },
  labelInfo: {
    fontSize: 14,
    marginRight: 5,
    textTransform: 'none',
  },
  info: {
    marginRight: 30,
    fontSize: 14,
  },
  rootInfo: {
    display: 'flex',
  },
  headerClass: {
    background: 'none',
    boxShadow: 'none',
  },
  marginBottom20: {
    marginBottom: 20,
  },
}));

const AddInvoicesDrawer = ({
  show,
  manageSelectedInvoices,
  order,
  manageOrderInvoicesIsLoading,
}) => {
  const { orderSummary, orderSummaryWasLoaded } = useSelector(
    state => state.order
  );
  const { availableInvoices, fetchingAvailableInvoices } = useSelector(
    state => state.invoice
  );

  const { type, orderType = type, createdAt: orderCreatedAt } = order;
  const { baseRate: orderRate } = orderSummary;

  const { business } = useSelector(state => state.business);
  const { country } = useSelector(state => state.config);
  const { holidays } = useSelector(state => state.holidays);
  const countryHolidays = holidays?.[country];

  const {
    id: businessId,
    name: businessName,
    totalOrdersTransferred,
  } = business;

  const [search, setSearch] = useState('');
  const [field, setField] = useState(null);
  const [invoicesData, setInvoicesData] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSelectedData = (data = []) => {
    setSelectedData(data);
  };

  const handleSelectedInvoices = (data = []) => {
    setSelectedInvoices(data);
  };

  const changeSearchInput = ({ value = '', field }) => {
    setField(field);
    setSearch(value);
  };

  const translateTypeOrder = type => {
    return ORDER_TYPES_LABEL[type];
  };

  const getInvoiceFilters = () => {
    const filter = [];
    const tSearch = search;
    if (tSearch) {
      filter.push({
        field,
        value: tSearch,
        cmp: '=',
      });
    }

    return filter;
  };

  const searcher = () => {
    const value = search;
    if (!value.length) {
      setSelectedInvoices([]);
      return;
    }
    dispatch(
      fetchAvailableBusinessInvoices(businessId, countryHolidays, {
        filters: getInvoiceFilters(),
        operationType: orderType,
        includeRate: true,
        defaultRate: orderRate,
      })
    );
  };

  useEffect(() => {
    if (show) searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    let invoicesAmount = 0;
    const invoices = [];
    const invoicesSize = selectedData.length;

    const selectedInvoicesUpdated = selectedInvoices.map(si => {
      const exists = selectedData.find(d => d.id === si.id);
      return {
        ...si,
        exists: !!exists,
      };
    });

    setSelectedInvoices(selectedInvoicesUpdated);

    if (invoicesSize) {
      invoicesAmount = selectedData.reduce(
        (a, b) => a + (parseFloat(b.amount) || 0),
        0
      );
      selectedData.forEach(({ id, rate, baseRate = rate, expirationDate }) => {
        invoices.push({
          id,
          baseRate,
          expirationDate,
        });
      });
    }
    setInvoicesData({
      invoicesAmount,
      invoices,
      invoicesSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  useEffect(() => {
    const updatedList = availableInvoices.map(invoice => {
      return {
        ...invoice,
        exists: selectedData.some(d => d.id === invoice.id),
      };
    });

    setSelectedInvoices(updatedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableInvoices]);

  useEffect(() => {
    setSearch('');
    setInvoicesData({});
    setSelectedInvoices([]);
    setSelectedData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const tableColumns = [
    {
      title: '',
      field: 'folio',
      type: 'numeric',
      defaultSort: 'asc',
      align: 'left',
      width: 100,
      render: rowData => {
        const { folio = '-' } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {folio}
          </Typography>
        );
      },
    },
    {
      title: '',
      field: 'rznSocRecep',
      render: rowData => {
        const { payerName = '-', blackList } = rowData;
        if (!blackList) {
          return (
            <Typography variant="body2" color="textPrimary">
              {payerName}
            </Typography>
          );
        }
        return (
          <Tooltip title="Blocklist">
            <Chip label={payerName} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      field: 'rutReceptor',
      render: rowData => {
        const { payerIdentifier = '-', blackList = false } = rowData;

        if (!blackList) {
          return (
            <Typography variant="body2" color="textPrimary">
              {payerIdentifier}
            </Typography>
          );
        }
        return (
          <Tooltip title="Blocklist">
            <Chip label={payerIdentifier} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      field: 'mntTotal',
      type: 'numeric',
      align: 'left',

      render: rowData => {
        const { finalAmount = 0 } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={finalAmount}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
  ];

  const getFilterByCountry = () => {
    const filters = [
      {
        title: 'Folio',
        field: 'folio',
      },
      {
        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        field: 'identifier',
      },
    ];

    if (country === COUNTRY_CODE_MX) {
      filters.push({
        title: 'UUID',
        field: 'uuid',
      });
    }

    return filters;
  };

  return (
    <>
      <SectionHeader
        padding="lg"
        title={businessName}
        transparentBg
        mainArea={
          <Grid container xs={12} direction="row">
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">ID:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {businessId}
              </Typography>
            </Grid>
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">Tipo de operación:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {translateTypeOrder(orderType)}
              </Typography>
            </Grid>
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">Fecha de solicitud:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {dateFormatter(orderCreatedAt)}
              </Typography>
            </Grid>
          </Grid>
        }
        preTitleAction={
          <Tooltip title="Operaciones Transferidas">
            <Badge
              classes={
                totalOrdersTransferred < 2
                  ? { badge: classes.firstOrderBadge }
                  : ''
              }
              badgeContent={totalOrdersTransferred}
              color="primary"
              invisible={false}
            />
          </Tooltip>
        }
        breadCrumbs={[]}
        showBreadCrumbs={false}
      />
      <Grid container item spacing={2}>
        <Grid item xs={8}>
          <Grid className={classes.marginBottom20}>
            <SearchBarPanel
              placeholder="Buscar facturas..."
              handleChangeInput={value => changeSearchInput(value)}
              panelOptions={{
                useDataPanel: true,
                data: selectedInvoices,
                handleData: handleSelectedInvoices,
                loadingData: fetchingAvailableInvoices,
                handleSelectedData,
                tableColumns,
                selectedData,
              }}
              selectedOption={getFilterByCountry()[0]}
              fields={getFilterByCountry()}
            />
          </Grid>
          <Grid container xs={12}>
            <InvoicesTableMinified
              invoices={selectedData}
              setInvoices={handleSelectedData}
              emptyMessage="Busca una o más facturas para ver su información aquí y editar las tasas bases."
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {order ? (
            <InvoiceOperationResume
              order={order}
              orderSummary={orderSummary}
              orderSummaryWasLoaded={orderSummaryWasLoaded}
              invoicesData={invoicesData}
              manageSelectedInvoices={() =>
                manageSelectedInvoices(selectedData)
              }
              buttonText="Agregar Facturas"
              action="add"
              buttonDisabled={
                selectedData.length === 0 || manageOrderInvoicesIsLoading
              }
              buttonIcon={<AddCircleOutlineOutlinedIcon />}
              buttonIsLoading={manageOrderInvoicesIsLoading}
              showHint
            />
          ) : (
            <Grid alignItems="center" className={classes.emptyResumeContainer}>
              <img
                src={emptyBrowserIcon}
                className={classes.emptyResumeIcon}
                alt="Top"
              />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
                noWrap
              >
                Selecciona una operación para ver su información acá.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

AddInvoicesDrawer.defaultProps = {
  show: false,
  manageOrderInvoicesIsLoading: false,
};

AddInvoicesDrawer.propTypes = {
  show: PropTypes.bool,
  manageSelectedInvoices: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    orderType: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  manageOrderInvoicesIsLoading: PropTypes.bool,
};

export default AddInvoicesDrawer;
