import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contenedorInput: {
    paddingRight: 44,
  },
  withoutMarginBottom: {
    marginBottom: 0,
    width: 230,
  },
  nombreData: {
    marginLeft: 5,
    overflow: 'auto',
  },
  datosData: {
    marginLeft: 5,
    overflow: 'auto',
  },
  divider: {
    border: '0.5px solid #E6E2ED',
    margin: '5px 0',
  },
  attributeArea: {
    marginBottom: 5,
  },
});

const AgreementContactColumn = props => {
  const {
    type,
    contacts,
    handleOpenCreateDialog,
    handleOpenDeleteDialog,
    handleOpenEditDialog,
  } = props;

  const classes = useStyles();
  const getAttributeArea = (name, label, contact) => {
    return (
      <Grid
        container
        className={clsx(classes.container, classes.attributeArea)}
      >
        <Box display="inline-flex">
          <Typography variant="caption" color="textSecondary" component="div">
            <Box fontWeight="fontWeightBold">{label}</Box>
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            component="div"
            className={classes.datosData}
          >
            {contact[name]}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid item direction="column" className={classes.columna}>
      <Grid className={classes.container}>
        <Box display="inline-flex" alignItems="center">
          <Typography variant="subtitle1" color="primary" component="div">
            <Box fontWeight="fontWeightBold">CONTACTO {type}</Box>
          </Typography>
          <IconButton onClick={() => handleOpenCreateDialog(type)}>
            <AddCircleOutlineIcon color="primary" />
          </IconButton>
        </Box>
      </Grid>
      {contacts.map(contact => (
        <Grid item xs={12}>
          <Grid container className={classes.container} direction="row">
            <Typography
              color="primary"
              variant="body2"
              className={classes.withoutMarginBottom}
            >
              {contact.name}
            </Typography>

            <div>
              <Tooltip title="Editar">
                <IconButton
                  size="small"
                  onClick={() => handleOpenEditDialog(contact)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton
                  size="small"
                  onClick={() => handleOpenDeleteDialog(contact)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Divider className={classes.divider} />
          {getAttributeArea('position', 'Cargo:', contact)}
          {getAttributeArea('email', 'Email:', contact)}
          {getAttributeArea('phone', 'Telefono:', contact)}
          <br />
        </Grid>
      ))}
    </Grid>
  );
};

AgreementContactColumn.propTypes = {
  contacts: PropTypes.isRequired,
  type: PropTypes.string.isRequired,
  handleOpenCreateDialog: PropTypes.func.isRequired,
  handleOpenDeleteDialog: PropTypes.func.isRequired,
  handleOpenEditDialog: PropTypes.func.isRequired,
};

export default AgreementContactColumn;
