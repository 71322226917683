import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import loading from '../../assets/loading.gif';

const useStyles = makeStyles({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 5,
  },
  dialog: {
    background: '#F3F2F5',
    borderRadius: '17px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: '0px 90px 50px 90px',
  },
});

const LoaderComponent = ({ open, handleCloseDialog }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} PaperProps={{ className: classes.dialog }}>
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={() => handleCloseDialog()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.justifyCenter}>
            <img src={loading} alt="loader" />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

LoaderComponent.defaultProps = {
  handleCloseDialog: () => undefined,
};

LoaderComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func,
};

export default LoaderComponent;
