import React, { FC } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import StatusChip from '../../../../commons/components/StatusChip';
import { OperationList } from '../../../../commons/interfaces/ARInterfaces';
import { LabelSubTitle, ValueSubTitle, ValueSubTitleUnderline } from './style';
import { t13s } from '../../../../../translationKeys/index';
import useGetCountry from '../../../../../hooks/useGetCountry';

interface Props {
  operation: OperationList;
  copyToClipboard: (value: string) => void;
}

const OperationCardStats: FC<Props> = ({ operation, copyToClipboard }) => {
  const { t } = useTranslation();
  const country = useGetCountry();
  const ORDER_DETAILS_PATH = `/${country.toLowerCase()}/orders/${operation.id}`;

  return (
    <Box display="flex">
      <Box display="flex" mr={5}>
        <LabelSubTitle>Estado:</LabelSubTitle>
        <ValueSubTitle>
          <StatusChip status={operation?.status} />
        </ValueSubTitle>
      </Box>
      <Box display="flex" mr={5}>
        <LabelSubTitle>ID Operación:</LabelSubTitle>
        <ValueSubTitle>
          <RouterLink
            to={ORDER_DETAILS_PATH}
            data-cy="operationCardOperationId"
          >
            {operation?.id}
          </RouterLink>
        </ValueSubTitle>
      </Box>
      <Box display="flex">
        <LabelSubTitle>{t(t13s.LABEL.BUSINESS_IDENTIFIER)}:</LabelSubTitle>
        <Tooltip title="Copiar al portapapeles" arrow>
          <ValueSubTitleUnderline
            data-cy="operationCardClientIdentifier"
            onClick={() => copyToClipboard(operation?.identifier)}
          >
            {operation?.identifier}
          </ValueSubTitleUnderline>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default OperationCardStats;
