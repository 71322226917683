import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import {
  Typography,
  FormControlLabel,
  Switch,
  makeStyles,
  IconButton,
  Menu,
} from '@material-ui/core';
import { ChangePassword, Edit, More } from '../icons';
import { RoleChip } from '../elements/ChipComponents';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import Can from '../Can';
import {
  USERS_EDIT_PERFORM,
  USERS_STATUS_PERFORM,
} from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';
import { updateUser } from '../../actions/userActions';
import MenuItem from '../elements/MenuItem';
import { dateFormatter } from '../../helpers/DateUtils';

const useStyles = makeStyles({
  switch: {
    margin: 0,
  },
});

const UsersTable = ({
  tableData,
  loadingUsers,
  handleChangePage,
  pagination,
  handleEditUserDialog,
  handleChangePassword,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user, rules } = useSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const checkIsSupervisorToRole = ({ roles }) => {
    const { roles: rolesUser } = user;
    let isSupervisor = false;
    const adminRole = rolesUser.some(role => role.rolename === 'admin');
    if (adminRole) {
      return true;
    }
    if (roles) {
      // Is supervisor of the area in common with the user to edit.
      isSupervisor = rolesUser.some(userLoggedRole =>
        roles.some(
          role =>
            role.rolename === userLoggedRole.rolename &&
            userLoggedRole.isSupervisor
        )
      );
    }
    return isSupervisor;
  };

  const handleUpdateStatusUser = ({
    id,
    firstName,
    lastName,
    email,
    birthdate,
    password,
    confirmPassword,
    active,
    roles,
  }) => {
    const userData = {
      id,
      firstName,
      lastName,
      email,
      birthdate,
      password,
      confirmPassword,
      active: !active,
      roles,
    };
    dispatch(updateUser(userData));
  };

  const columns = [
    {
      title: 'Nombre',
      field: 'name',
      width: '250px',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.name}
          </Typography>
        );
      },
    },
    {
      title: 'Email',
      field: 'email',
      width: '150px',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.email}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha de nacimiento',
      width: '50px',
      field: 'birthdate',
      type: 'date',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.birthdate ? dateFormatter(rowData?.birthdate) : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Roles',
      field: 'roles',
      render: rowData =>
        rowData?.roles?.map(role => (
          <RoleChip
            key={role.id}
            role={role.rolename}
            color={role.color}
            supervisor={role.isSupervisor}
          />
        )),
    },
    {
      width: '120px',
      render: rowData => {
        const verifyAuth = checkAuth(user?.roles, USERS_STATUS_PERFORM, rules);
        const isSupervisor = checkIsSupervisorToRole(rowData);
        return (
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                disabled={!isSupervisor || !verifyAuth}
                size="small"
                checked={rowData?.active}
                onClick={() => handleUpdateStatusUser(rowData)}
              />
            }
            label={rowData?.active ? 'Activo' : 'Inactivo'}
          />
        );
      },
    },
    {
      width: '100px',
      render: rowData => {
        const isSupervisor = checkIsSupervisorToRole(rowData);
        return (
          <>
            {isSupervisor ? (
              <IconButton
                onClick={e => {
                  setUserSelected(rowData);
                  handleClick(e);
                }}
              >
                <More />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <MaterialTable
        columns={columns}
        data={tableData}
        isLoading={loadingUsers}
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(0, rowsPerPage)}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination?.totalData}
              page={pagination?.page - 1}
              onChangePage={(e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page + 1, props?.rowsPerPage)
              }
            />
          ),
          Toolbar: () => null,
        }}
        localization={{
          pagination: {
            labelRowsSelect: 'usuarios',
            labelRowsPerPage: 'Usuarios por página',
            labelDisplayedRows: '{count} usuarios totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay usuarios para mostrar',
          },
        }}
        options={{
          showTitle: false,
          emptyRowsWhenPaging: false,
          filtering: false,
          search: false,
          pageSize: pagination?.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Can
          perform={USERS_EDIT_PERFORM}
          yes={() => (
            <MenuItem
              icon={<Edit />}
              onClick={() => {
                handleEditUserDialog(userSelected);
                setAnchorEl(null);
              }}
            >
              Editar usuario
            </MenuItem>
          )}
        />
        <MenuItem
          icon={<ChangePassword />}
          onClick={() => {
            handleChangePassword(userSelected);
            setAnchorEl(null);
          }}
        >
          Cambiar contraseña
        </MenuItem>
      </Menu>
    </>
  );
};

UsersTable.propTypes = {
  tableData: PropTypes.arrayOf(Object).isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalData: PropTypes.number,
  }).isRequired,
  handleEditUserDialog: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
};

export default UsersTable;
