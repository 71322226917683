/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from '@material-ui/core';

import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { Skeleton } from '../../../../commons/components/Skeleton';
import {
  defaultValue,
  hasValue,
  percentageFormat,
  pluralize,
  roundToInt,
} from '../../../../commons/utils';

export const GET_SUMMARY = ({
  country,
  summary,
  simulation,
  isLoading,
  setOpenEditRateDialog,
  setOpenEditEffectiveFundRateDialog,
  setOpenEditCapitalCostRateDialog,
}) => [
  {
    label: 'Monto bruto:',
    value: (
      <CountryFormatHelper
        value={summary?.amount}
        countryId={country}
        variant="currency"
      />
    ),
  },
  {
    label: 'Nº de documentos:',
    value: summary?.totalInvoices,
  },
  {
    label: 'Tasa Fondo Ponderada:',
    value: (
      <Skeleton variant="rect" height={13} width={100} isLoading={isLoading}>
        {!hasValue(simulation?.averageRate) ? (
          '-'
        ) : (
          <Link
            component="button"
            variant="body1"
            onClick={() => setOpenEditRateDialog(true)}
            style={{ fontWeight: 'bold' }}
          >
            {percentageFormat(simulation?.averageRate)}
          </Link>
        )}
      </Skeleton>
    ),
  },
  {
    label: 'Tasa Costo Fondo Efectiva:',
    value: (
      <Skeleton variant="rect" height={13} width={100} isLoading={isLoading}>
        {!hasValue(simulation?.averageEffectiveFundCostRate) ? (
          '-'
        ) : (
          <Link
            component="button"
            variant="body1"
            onClick={() => setOpenEditEffectiveFundRateDialog(true)}
            style={{ fontWeight: 'bold' }}
          >
            {percentageFormat(simulation?.averageEffectiveFundCostRate)}
          </Link>
        )}
      </Skeleton>
    ),
  },
  {
    label: 'Interés Tasa Costo Fondo Efectiva',
    value: (
      <Skeleton variant="rect" height={13} width={100} isLoading={isLoading}>
        <CountryFormatHelper
          value={simulation?.effectiveFundCostInterest}
          countryId={country}
          variant="currency"
        />
      </Skeleton>
    ),
  },
  {
    label: 'Tasa Costo Capital Xepelin:',
    value: (
      <Skeleton variant="rect" height={13} width={100} isLoading={isLoading}>
        {!hasValue(simulation?.averageCapitalCostRate) ? (
          '-'
        ) : (
          <Link
            component="button"
            variant="body1"
            onClick={() => setOpenEditCapitalCostRateDialog(true)}
            style={{ fontWeight: 'bold' }}
          >
            {percentageFormat(simulation?.averageCapitalCostRate)}
          </Link>
        )}
      </Skeleton>
    ),
  },
  {
    label: 'Plazo Ponderado:',
    value: (
      <Skeleton variant="rect" height={13} width={100} isLoading={isLoading}>
        {defaultValue(
          pluralize(roundToInt(simulation?.averageTerm), 'día', 'días')
        )}
      </Skeleton>
    ),
  },
  {
    label: 'Interés',
    value: (
      <Skeleton variant="rect" height={13} width={100} isLoading={isLoading}>
        <CountryFormatHelper
          value={simulation?.interest}
          countryId={country}
          variant="currency"
        />
      </Skeleton>
    ),
  },
];
