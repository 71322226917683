import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../translationKeys';
import verifyRequirementsService from '../service/verifyRequirements';

interface Variables {
  operationId: number;
  fetchRequirements?: boolean;
}

const useVerifyRequirements = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: verifyRequirements,
  } = useMutation(
    ({ operationId, fetchRequirements = false }: Variables) =>
      verifyRequirementsService(operationId),
    {
      onSuccess: (_successData, { fetchRequirements }) => {
        if (fetchRequirements) {
          queryClient.invalidateQueries('getRequirements', {
            exact: false,
          });
        }
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.VERIFY_REQUIREMENTS_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return { isLoading, isError, data, error, isSuccess, verifyRequirements };
};

export default useVerifyRequirements;
