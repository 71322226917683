import React from 'react';
import {
  withStyles,
  styled,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const StyledMenu = withStyles(({ palette }) => ({
  paper: {
    boxShadow: `0px 0px 12px ${palette.payments.whiteShadow}`,
  },
}))(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: 10,
});

const MenuSelector = ({ actions, menuText, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlerAction = handler => {
    handler();
    handleClose();
  };

  return (
    <div>
      <Button
        disabled={disabled}
        aria-controls="menu-selector"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {menuText}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map(
          ({
            permission = true,
            itemIcon = undefined,
            itemText,
            itemHandler = () => undefined,
          }) =>
            permission && (
              <MenuItem
                onClick={() => handlerAction(itemHandler)}
                key={itemText}
              >
                {itemIcon && (
                  <CustomListItemIcon>{itemIcon}</CustomListItemIcon>
                )}
                <ListItemText primary={itemText} />
              </MenuItem>
            )
        )}
      </StyledMenu>
    </div>
  );
};

MenuSelector.defaultProps = {
  disabled: false,
};

MenuSelector.propTypes = {
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(Object).isRequired,
  menuText: PropTypes.string.isRequired,
};

export default MenuSelector;
