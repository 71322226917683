import React, { useState } from 'react';
import { Grid, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { LEFT_TABS, RIGHT_TABS } from './constants';
import * as St from './MainLayout.styles';
import { COUNTRY_CODE_CL } from '../../helpers/Constants';
import { useGetCountry } from '../adapters/store';

const renderTab = (tabs, calculateProps) => {
  return tabs.map(({ label, value }, idx) => {
    return (
      <Tab label={label} value={value} key={label} {...calculateProps(idx)} />
    );
  });
};

export const MainLayout = ({ children }) => {
  const css = St.useStyles();
  const history = useHistory();
  const location = useLocation();
  const country = useGetCountry();
  const locationPath = location.pathname.split('/');
  const baseFundsPath = locationPath[2];
  const tab = locationPath[3];
  const [currentTab, setCurrentTab] = useState(tab || LEFT_TABS[0].value);
  const handleTabChange = valueTab => {
    history.push(`/${country.toLowerCase()}/${baseFundsPath}/${valueTab}`);
    setCurrentTab(valueTab);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={css.containerTabs}>
          <Tabs
            value={currentTab}
            onChange={(_, valueTab) => handleTabChange(valueTab)}
            css={{ indicator: css.indicator }}
          >
            {country === COUNTRY_CODE_CL &&
              renderTab(LEFT_TABS, () => ({
                className: css.headerTabsTab,
              }))}
            {renderTab(RIGHT_TABS, idx => ({
              className: idx === 0 ? css.rightTabs : null,
            }))}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
