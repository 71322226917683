import MomentUtils from '@date-io/moment';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { resetError } from '../../actions/agreementActions';
import {
  DATE_FORMAT,
  COUNTRY_CODE_CL,
  STATUS_ACTIVE,
  STATUS_PAUSED,
} from '../../helpers/Constants';
import { features } from '../../config/features';
import verifyDecimals from '../../helpers/validation/decimals';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';
import HorizontalSeparator from '../elements/HorizontalSeparator';
import RatesForm from '../elements/RatesForm';
import { fetchAgreementRates } from '../../actions/ratesActions';
import Can from '../Can';
import { AGREEMENTS_EDIT_RATES_PERFORM } from '../../helpers/performsType';
import { settings } from '../../config/settings/index';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  resetSwitch: {
    margin: '0px',
  },
  help: {
    marginLeft: 10,
  },
  containerSwitchAutoRate: {
    marginBottom: 10,
  },
  group: {
    marginTop: 15,
    marginBottom: 25,
  },
  checkbox: {
    marginBottom: 0,
  },
});

const AgreementFormDialog = props => {
  const {
    error,
    open,
    saving,
    editar,
    handleSubmitForm,
    selectedAgreement,
    handleCloseDialog,
    agreementsGroups,
    country = COUNTRY_CODE_CL,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const countryFeatures = features[country];
  const { isApiGlobal } = settings[country];

  const { fetchAgreementRatesIsLoading, agreementRates } = useSelector(
    state => state.rates
  );

  const [devengoRates, setDevengoRates] = useState([{ days: '', rate: '' }]);
  const [isRatesFormValid, setIsRatesFormValid] = useState(true);
  const [agreement, setAgreement] = useState({});
  const [autoRate, setAutoRate] = useState();

  useEffect(() => {
    const agreementStatus = !isApiGlobal
      ? selectedAgreement?.status === STATUS_ACTIVE
      : selectedAgreement?.active;
    const selectStatus = agreementStatus ? STATUS_ACTIVE : STATUS_PAUSED;

    const payerIdentifierSelected = !isApiGlobal
      ? selectedAgreement
        ? `${selectedAgreement?.payerRut}-${selectedAgreement?.payerRutDv}`
        : ''
      : selectedAgreement?.payerIdentifier || '';

    setAgreement({
      payerName: selectedAgreement?.payerName || '',
      payerIdentifier: payerIdentifierSelected,
      rate: selectedAgreement?.rate || 1.4,
      advancePercentage: selectedAgreement?.advancePercentage || 100,
      startDate: selectedAgreement?.startDate || moment().utc(),
      endDate: selectedAgreement?.endDate || moment().utc().add(1, 'year'),
      status: editar ? selectStatus : STATUS_ACTIVE,
      operationCost: selectedAgreement?.operationCost || 0,
      initialSpread: selectedAgreement?.initialSpread || 0,
      checkedSave: selectedAgreement?.checkedSave || false,
      checkedSpread: selectedAgreement?.operationCost === 0,
      AgreementsGroup:
        selectedAgreement?.AgreementsGroup ||
        agreementsGroups?.find(
          e => e.id === selectedAgreement?.agreementGroupId
        ) ||
        {},
    });
    setAutoRate(selectedAgreement?.flexible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgreement]);

  useEffect(() => {
    dispatch(resetError());

    ValidatorForm.addValidationRule('isIdentifierValid', value => {
      const validation = validateIdentifier(value, country);
      if (validation) return true;
      return false;
    });

    ValidatorForm.addValidationRule('isValidCost', value => {
      if (value >= 0) return true;
      return false;
    });
    ValidatorForm.addValidationRule('maxDecimals', value =>
      verifyDecimals(value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (selectedAgreement) {
      dispatch(fetchAgreementRates(selectedAgreement.id));
    } else {
      setDevengoRates([{ days: '', rate: '' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgreement]);

  useEffect(() => {
    if (selectedAgreement && agreementRates?.length) {
      setDevengoRates(agreementRates);
    } else {
      setDevengoRates([{ days: '', rate: '' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementRates]);

  const handleInputChange = event => {
    event.persist();
    setAgreement({
      ...agreement,
      [event.target.name]: event.target.value,
    });
  };

  const handleStartDateChange = date => {
    setAgreement({
      ...agreement,
      startDate: date,
    });
  };

  const handleEndDateChange = date => {
    setAgreement({
      ...agreement,
      endDate: date,
    });
  };

  const handleSubmit = () => {
    const {
      payerName,
      payerIdentifier,
      rate,
      advancePercentage,
      startDate,
      endDate,
      status,
      operationCost,
      initialSpread,
      AgreementsGroup,
    } = agreement;
    const agreementRates = devengoRates
      .filter(({ days, rate }) => days && rate)
      .map(({ id, days, rate }) => ({ id, days, rate }));
    const agreementData = {
      id: props.selectedAgreement ? props.selectedAgreement.id : null,
      payerName,
      payerIdentifier,
      rate,
      advancePercentage,
      startDate,
      endDate,
      status,
      operationCost: !agreement?.checkedSpread ? operationCost : 0,
      initialSpread: agreement?.checkedSpread ? initialSpread : 0,
      AgreementsGroup,
      autoRate,
      agreementRates,
    };

    handleSubmitForm(agreementData);
  };

  const getOptionLabel = option => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.name;
  };

  const handleAutocompleteChange = value => {
    setAgreement({
      ...agreement,
      AgreementsGroup: { name: value },
    });
  };

  const handleCheckboxSaveChange = () => {
    setAgreement({ ...agreement, checkedSave: !agreement.checkedSave });
  };

  const handleCheckSpread = () => {
    setAgreement({
      ...agreement,
      checkedSpread: !agreement?.checkedSpread,
    });
  };

  const handleAutoRate = () => {
    setAutoRate(!autoRate);
  };

  const {
    payerName,
    payerIdentifier,
    rate,
    advancePercentage,
    startDate,
    endDate,
    status,
    operationCost,
    checkedSave,
    checkedSpread,
    initialSpread,
    AgreementsGroup,
  } = agreement;

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={!editar ? 'Agregar convenio nuevo' : 'Editar convenio'}
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          fullWidth
          variant="outlined"
          label="Nombre pagador"
          type="string"
          name="payerName"
          aria-describedby="Nombre pagador"
          value={payerName}
          onChange={handleInputChange}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador`}
          type="string"
          name="payerIdentifier"
          aria-describedby="Identifier pagador"
          disabled={editar}
          value={payerIdentifier}
          onChange={!editar && handleInputChange}
          validators={['required', 'isIdentifierValid']}
          errorMessages={[
            'Campo requerido',
            t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
          ]}
        />

        <Autocomplete
          id="combo-box-grupo"
          options={agreementsGroups}
          freeSolo
          autoSelect
          value={AgreementsGroup?.name ?? null}
          getOptionLabel={getOptionLabel}
          onChange={(event, value) => handleAutocompleteChange(value)}
          renderInput={params => (
            <TextValidator
              variant="outlined"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Grupo Convenio"
              placeholder="Grupo Convenio"
              type="string"
              name="AgreementsGroup"
              aria-describedby="AgreementsGroup"
              value={AgreementsGroup?.name ?? ''}
              validators={['required']}
              errorMessages={['Campo requerido']}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Grid container direction="row" align="center" spacing={1}>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              label="Tasa"
              fullWidth
              inputProps={{ min: 0, step: 0.0001 }}
              type="number"
              name="rate"
              aria-describedby="Tasa"
              value={rate || 1.3}
              onChange={handleInputChange}
              validators={['required', 'isPositive', 'maxDecimals']}
              errorMessages={[
                'Campo requerido',
                'Valor debe ser mayor a 0',
                'Valor puede contener 4 decimales',
              ]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="% anticipo"
              inputProps={{ max: 100 }}
              type="number"
              name="advancePercentage"
              aria-describedby="Porcentaje anticipo"
              value={advancePercentage || 100}
              onChange={handleInputChange}
              validators={['required', 'isPositive']}
              errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
            />
          </Grid>
        </Grid>

        {countryFeatures.orderActions.showBusinessRates && (
          <>
            <HorizontalSeparator margin="xs zero md" />
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              component="div"
              className={classes.group}
            >
              <Box fontWeight="fontWeightBold">Devengo</Box>
            </Typography>

            <Can
              perform={AGREEMENTS_EDIT_RATES_PERFORM}
              yes={() => (
                <RatesForm
                  rates={devengoRates}
                  handleChangeRates={setDevengoRates}
                  validationListener={setIsRatesFormValid}
                  isLoading={fetchAgreementRatesIsLoading}
                />
              )}
              no={() => <RatesForm rates={devengoRates} disabled />}
            />

            <HorizontalSeparator margin="lg zero" />
          </>
        )}

        <Grid direction="row" container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <Typography
              color={!checkedSpread ? 'primary' : 'textSecondary'}
              variant="subtitle2"
              align="right"
            >
              <Box fontWeight="fontWeightBold">Costo fijo</Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Switch
                  color="primary"
                  checked={checkedSpread}
                  onChange={handleCheckSpread}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Typography
              color={!checkedSpread ? 'textSecondary' : 'primary'}
              variant="subtitle2"
              align="center"
            >
              <Box fontWeight="fontWeightBold">Spread inicial</Box>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              fullWidth
              label={checkedSpread ? 'Spread inicial (%)' : 'Costo fijo ($)'}
              inputProps={
                checkedSpread ? { min: 0.0, step: 0.0001 } : { min: 0 }
              }
              type="number"
              name={checkedSpread ? 'initialSpread' : 'operationCost'}
              aria-describedby={
                checkedSpread ? 'Spread inicial (%)' : 'Costo fijo ($)'
              }
              value={checkedSpread ? initialSpread : operationCost}
              onChange={handleInputChange}
              validators={['required', 'isValidCost']}
              errorMessages={[
                'Campo requerido',
                'Valor debe ser mayor o igual a 0',
              ]}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" align="center" spacing={2}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                label="Fecha inicio"
                name="startDate"
                format={DATE_FORMAT}
                margin="normal"
                value={startDate}
                onChange={handleStartDateChange}
                autoOk
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                label="Fecha término"
                name="endDate"
                format={DATE_FORMAT}
                margin="normal"
                value={endDate}
                onChange={handleEndDateChange}
                minDateMessage="Fecha inválida"
                autoOk
                disablePast
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <SelectValidator
          variant="outlined"
          id="status"
          label="Estado"
          value={status}
          name="status"
          onChange={handleInputChange}
          fullWidth
        >
          <MenuItem value="ACTIVE">Activo</MenuItem>
          <MenuItem value="PAUSED">Pausado</MenuItem>
        </SelectValidator>
        <Grid
          container
          alignItems="center"
          className={classes.containerSwitchAutoRate}
        >
          <FormControlLabel
            className={classes.resetSwitch}
            control={
              <Switch
                color="primary"
                checked={autoRate}
                onChange={handleAutoRate}
              />
            }
          />
          <Typography
            color={autoRate ? 'primary' : 'textPrimary'}
            variant="subtitle2"
            component="div"
          >
            <Box fontWeight="fontWeightBold">Auto Tasa</Box>
          </Typography>
          <Tooltip
            title="Al activar esta opción esta operación aplicará el modelo de tasas basado en el nivel de riesgo de cada factura."
            classes={{ tooltip: classes.customWidth }}
          >
            <HelpOutlineIcon fontSize="inherit" className={classes.help} />
          </Tooltip>
        </Grid>

        {error && <AlertForm message={error} variant="error" />}

        <Grid item xs={12} align="center" className={classes.justifyCenter}>
          <CheckButton
            check={checkedSave}
            handleCheck={handleCheckboxSaveChange}
            labelButton="Guardar"
            loading={saving}
            disabled={!isRatesFormValid}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

AgreementFormDialog.defaultProps = {
  country: 'CL',
  selectedAgreement: null,
};

AgreementFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  selectedAgreement: PropTypes.objectOf(Object),
  handleSubmitForm: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  editar: PropTypes.bool.isRequired,
  agreementsGroups: PropTypes.arrayOf(Object).isRequired,
  country: PropTypes.string,
};

export default AgreementFormDialog;
