import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { settings } from '../../config/settings';
import { features } from '../../config/features';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';
import HorizontalSeparator from '../elements/HorizontalSeparator';
import RatesForm from '../elements/RatesForm';
import Can from '../Can';
import {
  BUSINESS_EDIT_RATES_PERFORM,
  ORDER_RATES_PERCENTAGE_CHANGE,
} from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';
import { fixNumber } from '../../helpers/mathUtils';

const getMinRate = ({ percentage, base }) => {
  return fixNumber(base - percentage);
};

const getValidationRates = ({
  roles,
  rules,
  baseRate,
  minBaseRate,
  country,
}) => {
  const isAdmin = roles.some(role => role.rolename === 'admin');
  const { maxBaseRate } = settings[country];
  if (isAdmin) {
    return { minRate: 0, maxRate: maxBaseRate };
  }
  return ORDER_RATES_PERCENTAGE_CHANGE.reduce(
    (defaultRates, perform) => {
      const percentageChange = Number(perform.split('_')[1]);
      const minRate = getMinRate({
        percentage: percentageChange,
        base: baseRate,
      });
      return checkAuth(roles, perform, rules)
        ? {
            minRate: Math.max(minRate, minBaseRate),
            maxRate: maxBaseRate,
          }
        : defaultRates;
    },
    { minRate: baseRate, maxRate: maxBaseRate }
  );
};

const useStyles = makeStyles({
  validatorFormPaddingTop: {
    paddingTop: 5,
  },
  group: {
    marginTop: 15,
    marginBottom: 25,
  },
  containerInformationText: {
    marginLeft: 10,
    marginTop: -8,
    marginBottom: 15,
  },
  spacingInformationText: {
    margin: '0 4px',
  },
});

const EditRatesDialog = ({
  open,
  business,
  handleCloseDialog,
  handleSubmit: handleSave,
  handleReset,
  loading,
  error,
}) => {
  const classes = useStyles();
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { businessRates } = useSelector(state => state.rates);
  const countryFeatures = features[country];
  const {
    isApiGlobal,
    retention: { minRetention, maxRetention },
  } = settings[country];

  const {
    rate: rateBusineess = null,
    retention: retentionBusiness = null,
    hasConfirmingEnabled,
  } = business;
  const confirmingRateBusiness = isApiGlobal
    ? business.defaultConfirmingRate
    : business.confirmingRate || null;
  const { minBaseRateCreateOperation: minBaseRate, baseRateOrder } =
    settings[country];
  const { minRate, maxRate } = getValidationRates({
    roles,
    rules,
    minBaseRate,
    baseRate: baseRateOrder,
    country,
  });
  const [checked, setChecked] = useState(false);
  const [checkedReset, setCheckedReset] = useState(false);
  const [rate, setRate] = useState(rateBusineess);
  const [isRatesFormValid, setIsRatesFormValid] = useState(true);
  const [confirmingRate, setConfirmingRate] = useState(confirmingRateBusiness);
  const [retention, setRetention] = useState(retentionBusiness);
  const [devengoRates, setDevengoRates] = useState([{ days: '', rate: '' }]);
  const [validations, setValidations] = useState({
    rateValidator: ['required', `minNumber:${minRate}`, `maxNumber:${maxRate}`],
    rateErrorMessage: [
      'Campo requerido',
      `Tasa mínima ${minRate}%`,
      `Tasa máxima ${maxRate}%`,
    ],
    retentionValidator: [
      'required',
      `minNumber:${minRetention}`,
      `maxNumber:${maxRetention}`,
    ],
    retentionErrorMessage: [
      'Campo requerido',
      `Retención mínima ${minRetention}%`,
      `Retención máxima ${maxRetention}`,
    ],
  });

  useEffect(() => {
    setChecked(false);
    setCheckedReset(false);
  }, [setChecked, setCheckedReset]);

  useEffect(() => {
    if (!open) {
      setChecked(false);
      setCheckedReset(false);
    }
  }, [open]);

  useEffect(() => {
    if (businessRates?.length) {
      setDevengoRates(businessRates);
    }
  }, [businessRates]);

  useEffect(() => {
    ValidatorForm.addValidationRule('verifyThatDayDoesNotRepeatIt', value => {
      return (
        !devengoRates.length ||
        devengoRates.filter(({ days }) => days === value).length === 1
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRate(rateBusineess);
    setConfirmingRate(confirmingRateBusiness);
    setRetention(retentionBusiness);
  }, [rateBusineess, retentionBusiness, confirmingRateBusiness]);

  const handleSaveCheckbox = () => {
    setChecked(!checked);
    setCheckedReset(false);
    setValidations({
      rateValidator: [
        'required',
        `minNumber:${minRate}`,
        `maxNumber:${maxRate}`,
      ],
      rateErrorMessage: [
        'Campo requerido',
        `Tasa mínima ${minRate}%`,
        `Tasa máxima ${maxRate}%`,
      ],
      retentionValidator: [
        'required',
        `minNumber:${minRetention}`,
        `maxNumber:${maxRetention}`,
      ],
      retentionErrorMessage: [
        'Campo requerido',
        `Retención mínima ${minRetention}%`,
        `Retención máxima ${maxRetention}`,
      ],
    });
  };

  const handleResetCheckbox = () => {
    setCheckedReset(!checkedReset);
    setChecked(false);
    setValidations({
      rateValidator: [],
      rateErrorMessage: [],
      retentionValidator: [],
      retentionErrorMessage: [],
    });
  };

  const handleSubmitEditRatesDialog = () => {
    const rates = devengoRates
      .filter(({ days, rate }) => days && rate)
      .map(({ days, rate }) => ({ days, rate }));
    return checked
      ? handleSave(rate, confirmingRate, retention, rates)
      : handleReset();
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Editar fees"
    >
      <ValidatorForm
        onSubmit={handleSubmitEditRatesDialog}
        className={classes.validatorFormPaddingTop}
      >
        <TextValidator
          fullWidth
          variant="outlined"
          label="Tasa base (%)"
          type="number"
          inputProps={{ min: minRate, step: 0.0001, max: maxRate }}
          id="rate"
          name="rate"
          aria-describedby="tasaBase"
          value={rate}
          onChange={e => {
            setRate(e.target.value);
          }}
          validators={validations.rateValidator}
          errorMessages={validations.rateErrorMessage}
        />
        {minRate !== 0 && (
          <Box display="flex" className={classes.containerInformationText}>
            <Typography variant="body1" color="textSecondary">
              Tasa base minima:
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              component="div"
              className={classes.spacingInformationText}
            >
              <Box fontWeight="fontWeightBold">{minRate}%</Box>
            </Typography>
          </Box>
        )}
        {hasConfirmingEnabled && (
          <TextValidator
            fullWidth
            variant="outlined"
            label="Tasa confirming (%)"
            type="number"
            inputProps={{ min: minRate, step: 0.0001, max: maxRate }}
            id="confirmingRate"
            name="confirmingRate"
            aria-describedby="confirmingRate"
            value={confirmingRate}
            onChange={e => {
              setConfirmingRate(e.target.value);
            }}
            validators={validations.rateValidator}
            errorMessages={validations.rateErrorMessage}
          />
        )}

        <TextValidator
          fullWidth
          variant="outlined"
          label="Retención (%)"
          type="number"
          inputProps={{
            min: minRetention,
            step: 0.0001,
            max: maxRetention,
          }}
          id="retention"
          name="retention"
          aria-describedby="retention"
          value={retention}
          onChange={e => {
            setRetention(e.target.value);
          }}
          validators={validations.retentionValidator}
          errorMessages={validations.retentionErrorMessage}
        />

        {countryFeatures.orderActions.showBusinessRates && (
          <>
            <HorizontalSeparator margin="xs zero md" />
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              component="div"
              className={classes.group}
            >
              <Box fontWeight="fontWeightBold">Devengo</Box>
            </Typography>

            <Can
              perform={BUSINESS_EDIT_RATES_PERFORM}
              yes={() => (
                <RatesForm
                  rates={devengoRates}
                  handleChangeRates={setDevengoRates}
                  validationListener={setIsRatesFormValid}
                />
              )}
              no={() => <RatesForm rates={devengoRates} disabled />}
            />

            <HorizontalSeparator margin="lg zero" />
          </>
        )}
        {error && <AlertForm message={error} variant="error" />}

        <Box mb={2}>
          <CheckButton
            check={checked}
            handleCheck={handleSaveCheckbox}
            labelButton="Guardar"
            loading={loading}
            disabled={!isRatesFormValid}
          />
        </Box>

        <Box>
          <CheckButton
            check={checkedReset}
            handleCheck={handleResetCheckbox}
            labelButton="Resetear"
            loading={loading}
          />
        </Box>
      </ValidatorForm>
    </BaseDialog>
  );
};

EditRatesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  business: PropTypes.objectOf(Object).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default EditRatesDialog;
