import React, { FC, PropsWithChildren } from 'react';
import { Box, styled, Typography } from '@material-ui/core';
import ExpandLessIcon from '../../../icons/ExpandLessIcon';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';

const ContainerHeaderCollapse = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const TitleText = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
});

interface Props extends PropsWithChildren {
  showInformation: boolean;
  handlerClick: () => void;
}

const HeaderPanelCollapse: FC<Props> = ({
  children,
  showInformation,
  handlerClick,
}) => {
  return (
    <ContainerHeaderCollapse onClick={handlerClick}>
      {showInformation ? (
        <ArrowDownIcon color="#312F37" />
      ) : (
        <ExpandLessIcon color="#312F37" />
      )}

      <TitleText>{children}</TitleText>
    </ContainerHeaderCollapse>
  );
};

export default HeaderPanelCollapse;
