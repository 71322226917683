import { useQuery } from 'react-query';
import useGetCountry from '../../../../hooks/useGetCountry';
import {
  Filters,
  OperationList,
  Pagination,
} from '../../../commons/interfaces/ARInterfaces';
import fetchOperationsService from '../services/fetchOperationsService';

interface Props {
  filters: Filters;
  getFilters: () => Filters;
  page: number;
  itemsPerPage: number;
}

interface Response {
  operations: OperationList[];
  pagination: Pagination;
  isLoading: boolean;
}

const useOperations = ({
  filters,
  getFilters,
  page,
  itemsPerPage,
}: Props): Response => {
  const country = useGetCountry();

  const { isLoading, isRefetching, data } = useQuery(
    ['getOperations', filters, country, page, itemsPerPage],
    () =>
      fetchOperationsService({
        filters: getFilters(),
        size: itemsPerPage,
        page,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 0,
      retry: false,
    }
  );

  return {
    operations: data?.data || [],
    pagination: data?.pagination || {},
    isLoading: isLoading || isRefetching,
  };
};

export default useOperations;
