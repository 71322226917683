import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CARD_VARIANTS } from '../../theme/otherColors';
import { COUNTRY_CODE_MX } from '../../helpers/Constants';
import DetailBusinessInformation from './DetailBusinessInformation';
import Panel from './Panel';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles({
  infoBusinessPaper: {
    background: '#fff',
    padding: convertSpacingToCss('lg'),
    borderRadius: '17px',
    border: `1px solid ${CARD_VARIANTS.white.border}`,
  },
  infoBusinessTitle: {
    margin: convertSpacingToCss('zero sm lg sm'),
  },
  infoBusinessContainerInfo: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  infoBusinessButtons: {
    marginLeft: '10px',
  },
  infoBusinessPaperCollapse: {
    background: '#fff',
    borderRadius: '17px',
    border: `1px solid ${CARD_VARIANTS.white.border}`,
    padding: 0,
  },
  flexContainer: {
    display: 'flex',
  },
  arrowForwardIosIcon: {
    width: 18,
    fontSize: 11,
    marginLeft: 7,
    marginTop: 4,
  },
  expandMoreIcon: {
    fontSize: 20,
    marginLeft: '5px',
  },
  infoBusinessTitleCollapse: {
    margin: convertSpacingToCss('zero sm lg xs'),
  },
  accordionDetails: {
    padding: convertSpacingToCss('zero lg'),
  },
  infoBusinessButtonsCollapse: {
    marginLeft: 10,
    paddingBottom: 23,
  },
});

const stylesComponentForced = {
  accordion: {
    boxShadow: 'none',
    borderRadius: '17px',
  },
  accordionSummary: {
    padding: convertSpacingToCss('md lg zero lg'),
  },
};

const PanelResumenInformation = ({
  informationDetail = [],
  buttons = [],
  title,
  collapsable,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config);
  const [collapse, setCollapse] = useState(COUNTRY_CODE_MX === country);
  const [objectsCollapsibles, setObjectsCollapsibles] = useState([]);
  const [objectsNonCollapsibles, setObjectsNonCollapsibles] = useState([]);

  useEffect(() => {
    if (collapsable && informationDetail?.length) {
      setObjectsNonCollapsibles(
        informationDetail.filter(item => !item.collapse)
      );
      setObjectsCollapsibles(informationDetail.filter(item => item.collapse));
    }
    // eslint-disable-next-line
  }, [informationDetail, collapsable]);

  return (
    <>
      {collapsable ? (
        <Grid
          container
          item
          xs={12}
          className={classes.infoBusinessPaperCollapse}
        >
          <Accordion
            style={stylesComponentForced.accordion}
            expanded={collapse}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={stylesComponentForced.accordionSummary}
              onClick={() => setCollapse(!collapse)}
            >
              <Grid container>
                <Grid item xs={12} className={classes.flexContainer}>
                  {!collapse ? (
                    <ArrowForwardIosIcon
                      className={classes.arrowForwardIosIcon}
                    />
                  ) : (
                    <ExpandMoreIcon className={classes.expandMoreIcon} />
                  )}

                  <Typography
                    color="textPrimary"
                    className={classes.infoBusinessTitleCollapse}
                    variant="subtitle1"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">{title}</Box>
                  </Typography>
                </Grid>

                {objectsNonCollapsibles?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    className={classes.infoBusinessContainerInfo}
                  >
                    {objectsNonCollapsibles.map(info => (
                      <DetailBusinessInformation
                        data={info}
                        classes={classes}
                      />
                    ))}
                  </Grid>
                )}
              </Grid>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              <Grid container>
                {objectsCollapsibles?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    className={classes.infoBusinessContainerInfo}
                  >
                    {objectsCollapsibles.map((info, index) => (
                      <DetailBusinessInformation
                        data={info}
                        classes={classes}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      />
                    ))}
                  </Grid>
                )}

                {buttons?.length > 0 && (
                  <Grid
                    container
                    className={classes.infoBusinessButtonsCollapse}
                  >
                    {buttons}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ) : (
        <Panel
          title={title}
          variant="section"
          titlePadding="lg"
          contentPadding="zero lg lg"
        >
          {informationDetail?.length > 0 && (
            <Grid item xs={12} className={classes.infoBusinessContainerInfo}>
              {informationDetail.map(info => (
                <DetailBusinessInformation data={info} classes={classes} />
              ))}
            </Grid>
          )}

          {buttons?.length > 0 && (
            <Grid container className={classes.infoBusinessButtons}>
              {buttons}
            </Grid>
          )}
        </Panel>
      )}
    </>
  );
};

PanelResumenInformation.propTypes = {
  informationDetail: PropTypes.objectOf(Array).isRequired,
  buttons: PropTypes.objectOf(Array).isRequired,
  title: PropTypes.string.isRequired,
  collapsable: PropTypes.bool,
};

PanelResumenInformation.defaultProps = {
  collapsable: false,
};

export default PanelResumenInformation;
