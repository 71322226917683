export const delinquencyRangeConverter = (collectionStatus: number) => {
    switch (collectionStatus) {
        case 1:
            return '0 días'
        case 2:
            return '1-30 días'
        case 3:
            return '31-180 días'
        case 4:
            return '181+ días'
        default:
            return '0 días'
        
    }
  };
