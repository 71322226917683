import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Container, FormGroup } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import AlertForm from '../elements/AlertForm';
import LoadingButton from '../elements/LoadingButton';
import Dropzone from '../elements/Dropzone/Dropzone';
import { settings } from '../../config/settings';

const useStyles = makeStyles({
  error: {
    marginTop: 20,
  },
  dropzone: {
    marginBottom: 15,
  },
});

const PfxUploadDialog = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const { uploadingPfx, pfxSuccess, pfxErrors, uploadPfxIsLoading } =
    useSelector(state => state.business);
  const { country } = useSelector(state => state.config);

  const [password, setPassword] = useState('');
  const [pfxFile, setPfxFile] = useState(null);
  const { isApiGlobal } = settings[country];

  const handleChangeStatus = (file, status) => {
    if (status === 'done') {
      setPfxFile(file.file);
    } else if (status === 'removed') {
      setPfxFile(null);
    }
  };

  const handleClick = () => {
    handleSubmit({ pfx: pfxFile, password });
  };

  return (
    <BaseDialog
      size="lg"
      isOpen={open}
      handleClose={handleClose}
      title="Carga tu certificado digital"
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={handleClick}>
          <Container justify="center" width="100%" className={classes.dropzone}>
            <Dropzone
              accept=".pfx"
              maxFiles={1}
              minSize={100}
              isLoading={uploadPfxIsLoading || uploadingPfx}
              onChangeStatus={handleChangeStatus}
              SubmitButtonComponent={null}
            />
          </Container>
          <Container justify="center">
            <FormGroup className={classes.root}>
              <TextValidator
                fullWidth
                variant="outlined"
                label="Clave certificado"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                validators={['required']}
                errorMessages={['Campo requerido']}
              />
            </FormGroup>
          </Container>

          {isApiGlobal && pfxErrors && (
            <Grid item xs={12} align="center" className={classes.error}>
              <AlertForm variant="error" message={pfxErrors} />
            </Grid>
          )}

          {!isApiGlobal && !uploadingPfx && !pfxSuccess && pfxErrors && (
            <Grid item xs={12} align="center" className={classes.error}>
              <AlertForm variant="error" message={pfxErrors} />
            </Grid>
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              !password || !pfxFile || uploadPfxIsLoading || uploadingPfx
            }
            isLoading={uploadPfxIsLoading || uploadingPfx}
          >
            Subir
          </LoadingButton>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

PfxUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PfxUploadDialog;
