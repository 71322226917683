// eslint-disable-next-line import/prefer-default-export
export const palette = {
  default: {
    light: '#8d8c96',
    main: '#575360',
    dark: '#323038',
    contrastText: 'white',
  },
  primary: {
    light: '#476DEB',
    main: '#1A49E7',
    dark: '#1233A1',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#476DEB',
    main: '#1A49E7',
    dark: '#1233A1',
    contrastText: '#FFF',
  },
  error: {
    light: '#e0584f',
    main: '#D92F24',
    dark: '#972019',
    contrastText: '#FFF',
  },
  warning: {
    light: '#ffc033',
    main: '#FFB100',
    dark: '#b27b00',
    contrastText: '#FFF',
  },
  info: {
    light: '#478feb',
    main: '#1A73E7',
    dark: '#1250a1',
    contrastText: '#FFF',
  },
  success: {
    light: '#90c633',
    main: '#75B801',
    dark: '#518000',
    contrastText: '#FFF',
  },
  grey: {
    50: '#FEFCFF',
    100: '#F7F5FA',
    200: '#F1EFF5',
    300: '#E9E6F0',
    400: '#CECAD9',
    500: '#C1BDCE',
    600: '#A8A4B3',
    700: '#9692A0',
    800: '#645F6E',
    900: '#45414D',
  },
  text: {
    primary: 'rgba(69, 65, 77, 1)',
    secondary: 'rgba(141, 138, 150, 1)',
    disabled: 'rgba(193, 189, 206, 1)',
    contrast1: 'rgba(117, 184, 1, 1)',
    contrast2: 'rgba(26, 115, 231, 1)',
  },
  transparent: 'transparent',
  payments: {
    grey1: '#44414C',
    grey2: '#605C6B',
    grey3: '#828795',
    grey4: '#DAD9DB',
    grey5: '#DDE6F6',
    grey6: '#8D8A96',
    backgroundGrey: '#F5F5F6',
    softBlue: '#E6F0FF',
    stateText1: '#E29D02',
    stateText4: '#0093FF',
    stateText7: '#901AE7',
    white: '#FFFFFF',
    whiteShadow: '#E0E0E0',
    primary: {
      main: '#5555EE',
    },
    secondary: '#4F5A84',
    error: {
      light: '#FEE3E3',
      main: '#DD1515',
    },
    warning: {
      light: '#FCF0DB',
      dark: '#C3900C',
    },
    inactive: '#312F37',
    statusColors: {
      complete: '#901ae7',
      approved: '#75b801',
      approvedFund: '#F87348',
      pvApproved: '#119b41',
      rejected: '#d92f24',
      renegotiation: '#b5b2b7',
      default: '#0529f0',
    },
  },
};
