import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Info = props => (
  <SvgIcon
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_430_4993)">
      <path
        d="M10.75 2.5C5.92 2.5 2 6.42 2 11.25C2 16.08 5.92 20 10.75 20C15.58 20 19.5 16.08 19.5 11.25C19.5 6.42 15.58 2.5 10.75 2.5ZM11.625 15.625H9.875V10.375H11.625V15.625ZM11.625 8.625H9.875V6.875H11.625V8.625Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_430_4993">
        <rect
          width="21"
          height="21"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Info;
