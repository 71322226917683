import React from 'react';
import { Grid, styled } from '@material-ui/core';
import HeaderTitle from './HeaderTitle';
import NewPaymentButton from './NewPaymentButton';
import BulkUpdatePaymentMenu from './BulkUpdatePaymentMenu';
import InvoicesBulkUpdateModal from './InvoicesBulkUpdateModal';
import FundsBulkUpdateModal from './FundsBulkUpdateModal';
import usePaymentHeader from '../../hooks/usePaymentHeader';
import CreateOrderDialog from '../../../commons/components/CreateOrderDialog';
import PartialPaymentsBulkUpdateModal from './PartialPaymentsBulkUpdateModal';
import InvoicesBulkStatusUpdateModal from './InvoicesBulkStatusUpdateModal';

const Wrapper = styled(Grid)({
  margin: '30px 0',
});

const ButtonsWrapper = styled(Grid)({
  marginLeft: 'auto',
  display: 'flex',
  gap: 38,
});

const PaymentHeader = () => {
  const {
    showInvoicesBulkUpdateDialog,
    handleCloseInvoicesBulkUpdateDialog,
    showFundsBulkUpdateDialog,
    handleCloseFundsBulkUpdateDialog,
    bulkUpdateMenuItems,
    showCreateOrderDialog,
    handleCloseCreateOrderDialog,
    handleShowCreateOrderDialog,
    handleSubmitCreateOperation,
    createOrderIsLoading,
    createOrderError,
    showPartialPaymentsBulkUpdateDialog,
    handleClosePartialPaymentsBulkUpdateDialog,
    showInvoicesBulkStatusUpdateDialog,
    handleCloseInvoicesBulkStatusUpdateDialog,
  } = usePaymentHeader();

  return (
    <Wrapper container>
      <Grid item>
        <HeaderTitle />
      </Grid>
      <ButtonsWrapper item>
        <BulkUpdatePaymentMenu menuItems={bulkUpdateMenuItems} />
        <InvoicesBulkUpdateModal
          open={showInvoicesBulkUpdateDialog}
          handleClose={handleCloseInvoicesBulkUpdateDialog}
        />
        {showInvoicesBulkUpdateDialog && (
          <InvoicesBulkUpdateModal
            open={showInvoicesBulkUpdateDialog}
            handleClose={handleCloseInvoicesBulkUpdateDialog}
          />
        )}
        <FundsBulkUpdateModal
          open={showFundsBulkUpdateDialog}
          handleClose={handleCloseFundsBulkUpdateDialog}
        />
        {showFundsBulkUpdateDialog && (
          <FundsBulkUpdateModal
            open={showFundsBulkUpdateDialog}
            handleClose={handleCloseFundsBulkUpdateDialog}
          />
        )}
        <PartialPaymentsBulkUpdateModal
          open={showPartialPaymentsBulkUpdateDialog}
          handleClose={handleClosePartialPaymentsBulkUpdateDialog}
        />
        {showPartialPaymentsBulkUpdateDialog && (
          <PartialPaymentsBulkUpdateModal
            open={showPartialPaymentsBulkUpdateDialog}
            handleClose={handleClosePartialPaymentsBulkUpdateDialog}
          />
        )}
        <InvoicesBulkStatusUpdateModal
          open={showInvoicesBulkStatusUpdateDialog}
          handleClose={handleCloseInvoicesBulkStatusUpdateDialog}
        />
        {showInvoicesBulkStatusUpdateDialog && (
          <InvoicesBulkStatusUpdateModal
            open={showInvoicesBulkStatusUpdateDialog}
            handleClose={handleCloseInvoicesBulkStatusUpdateDialog}
          />
        )}
        <NewPaymentButton handler={handleShowCreateOrderDialog} />
      </ButtonsWrapper>
      {showCreateOrderDialog && (
        <CreateOrderDialog
          open={showCreateOrderDialog}
          handleSubmit={handleSubmitCreateOperation}
          handleCloseDialog={handleCloseCreateOrderDialog}
          isLoading={createOrderIsLoading}
          submitError={createOrderError}
        />
      )}
    </Wrapper>
  );
};

export default PaymentHeader;
