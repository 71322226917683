import * as PayrollMap from '../../payroll/services/payroll.map';

export const invoiceMap = {
  toDomain: payrollInvoice => {
    const invoice = payrollInvoice?.Invoice;
    const issuer = invoice.Issuer || {};

    return {
      invoice: {
        amount: invoice.amount,
        businessActivity: invoice.businessActivity,
        daysOfIssue: invoice.issuedDate,
        folio: invoice.folio,
        id: invoice.id,
        issuer: issuer.name,
        issuerId: issuer.identifier,
        operationType: invoice.operationType,
        orderId: invoice.orderId,
        originationRate: invoice.baseRate,
        status: invoice.status,
      },
      advancePercentage: payrollInvoice?.advancePercentage,
      amount: payrollInvoice?.amount,
      id: payrollInvoice?.id,
      interest: payrollInvoice?.interest,
      issuerDebt: payrollInvoice?.issuerDebtServiceAmount,
      rate: payrollInvoice?.rate,
      effectiveFundCostRate: payrollInvoice?.effectiveFundCostRate,
      capitalCostRate: payrollInvoice?.capitalCostRate,
      status: payrollInvoice?.status,
      term: payrollInvoice?.expirationDate,
    };
  },
};

export const invoiceEditionMap = {
  toDomain: res => ({
    invoices: res?.payrollInvoices?.map(i => ({
      id: i?.id,
      rate: i?.rate,
      effectiveFundCostRate: i.effectiveFundCostRate,
      term: i?.expirationDate,
      amount: i?.amount,
      interest: i?.interest,
    })),
    payrollSummary: PayrollMap.payrollMap.toDomain(res?.payrollSummary),
  }),
  toPersistence: (form = {}) => ({
    payrollId: form.payrollId,
    payrollInvoices: form.invoices?.map(i => ({
      id: i.payrollInvoiceId,
      rate: i.rate,
      effectiveFundCostRate: i.effectiveFundCostRate,
      term: i.term,
      invoiceAmount: i.amount,
      advancePercentage: i.advancePercentage,
    })),
  }),
};

export const invoiceByIdMap = {
  toDomain: payrollInvoice => {
    const invoice = payrollInvoice?.Invoice;
    const issuer = invoice.Issuer || {};
    const receiver = invoice.Receiver || {};
    const invoiceTaxProviders = invoice.InvoiceTaxProviders || {};

    return {
      invoice: {
        amount: invoice.amount,
        businessActivity: invoice.businessActivity,
        countryId: invoice.countryId,
        createdAt: invoice.createdAt,
        issuedDate: invoice.issuedDate,
        discounts: invoice.discounts,
        term: invoice.expirationDate,
        folio: invoice.folio,
        id: invoice.id,
        identifier: invoice.identifier,
        operationType: invoice.operationType,
        orderId: invoice.orderId,
        orderInvoiceId: invoice.orderInvoiceId,
        originationRate: invoice.baseRate,
        repurchase: invoice.repurchase,
        score: invoice.score,
        status: invoice.status,
        totalAmountCreditNotes: invoice.totalAmountCreditNotes,
        updatedAt: invoice.updatedAt,
        verificationStatus: invoice.verificationStatus,
      },
      issuer: {
        debt: issuer.debtServiceAmount,
        id: issuer.identifier,
        name: issuer.name,
      },
      receiver: {
        debt: receiver.debtServiceAmount,
        id: receiver.identifier,
        name: receiver.name,
      },
      invoiceTaxProviders: {
        acknowledgementOfReceipt:
          invoiceTaxProviders.metadata?.acknowledgementOfReceipt,
        assignee: invoiceTaxProviders.metadata?.assignee,
        taxService: invoiceTaxProviders.taxService,
      },
      advancePercentage: payrollInvoice.advancePercentage,
      amount: payrollInvoice.amount,
      interest: payrollInvoice.interest,
      rate: payrollInvoice.rate,
    };
  },
  toPersistence: (filter = {}) => ({
    payrollInvoiceId: filter.payrollInvoiceId,
  }),
};

const payrollSelectorReq = ({ state }) => {
  const receiverIds = Object.keys(state);

  return receiverIds.reduce(
    (acc, id) => {
      if (state[id].selected) {
        acc.receivers.push(id);

        return acc;
      }
      acc.payrollInvoices.push(...state[id].value);

      return acc;
    },
    {
      payrollInvoices: [],
      receivers: [],
    }
  );
};

export const invoiceDeleteMap = {
  toPersistence: ({ payrollId, state }) => {
    const req = payrollSelectorReq({ state });

    return {
      payrollId: +payrollId,
      payrollInvoiceIds: req.payrollInvoices,
      receiverIdentifiers: req.receivers,
    };
  },
};

export const payrollInvoicesUpdateStatusMap = {
  toDomain: res => ({
    payrollInvoices: res?.payrollInvoices?.map(i => ({
      id: i?.id,
      status: i?.status,
    })),
  }),
  toPersistence: ({ payrollId, state, payrollInvoiceStatus }) => ({
    ...payrollSelectorReq({ state }),
    payrollId: +payrollId,
    payrollInvoiceStatus,
  }),
};

export const invoiceFilterMap = {
  toPersistence: (filter = {}) => {
    return {
      assigneeIdentifier: filter?.assignee?.identifier,
      assigneeName: filter?.assignee?.name,
      baseRateMax: filter.maxOriginationRate,
      baseRateMin: filter.minOriginationRate,
      folio: filter.folio,
      id: filter.payrollId,
      invoiceMaxAmount: filter.maxAmount,
      invoiceMinAmount: filter.minAmount,
      invoiceStatus: filter.status,
      issuedMax: filter.maxDaysOfIssue,
      issuedMin: filter.minDaysOfIssue,
      issuerIdentifier: filter.issuerId,
      issuerName: filter.issuerName,
      operationType: filter.operationType,
      rateMax: filter.maxFundRate,
      rateMin: filter.minFundRate,
      receiverIdentifier: filter.receiverId,
      status: filter.businessStatus,
      termMax: filter.maxTerm,
      termMin: filter.minTerm,
      discounts: {
        // NOTE: dont use falsy
        _eq: [0, false].includes(filter.discount) ? 0 : undefined,
        _gt: filter.discount ? 0 : undefined,
      },
    };
  },
};
