/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useTranslation } from 'react-i18next';
import CSVFileValidator from 'csv-file-validator';
import { CSVDownload } from 'react-csv';
import PayerFormDialog from './dialogs/PayerFormDialog';
import PayersTable from './elements/PayersTable';
import { fetchSubRubros } from '../actions/rubroAction';
import { enqueueSnackbar } from '../actions/notificationAction';
import {
  fetchPayers,
  fetchInfoPayersToCSV,
  registerPayer,
  loadPayersCsvMIGRACIONCL,
  loadPayersCsv,
  resetPayerError,
  resetBulkCsvPayersDialog,
  resetPayerCsvData,
} from '../actions/payerAction';
import {
  fetchContactsCsv,
  loadContactsCsv,
  loadContactsCsvMIGRACIONCL,
  resetContactsCsvError,
  fetchContactsCsvMIGRACIONCL,
  resetPayerContactsCsvData,
} from '../actions/contactsActions';
import Can from './Can';
import {
  PAYERS_ADD_NEW_PERFORM,
  PAYERS_DOWNLOAD_LIST_PERFORM,
  PAYERS_UPLOAD_CSV_PERFORM,
} from '../helpers/performsType';
import {
  KEY_CODES,
  INIT_PAGE,
  SEARCH_BAR_TIMEOUT,
  COUNTRY_CODE_CL,
} from '../helpers/Constants';
import { CSV_CONFIG_CONTACTS } from '../helpers/CsvConfig';
import LoaderComponent from './elements/LoaderComponent';
import Panel from './elements/Panel';
import SearchBar from './elements/SearchBar';
import { fetchEconomicGroups } from '../actions/economicGroupAction';
import { validateIdentifier } from '../helpers/validation/businessIdentifier';
import User from './icons/User';
import { settings } from '../config/settings';
import { t13s } from '../translationKeys';
import FileUploadDialog from './dialogs/FileUploadDialog';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
    background: '#F2F5F8',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  addPayerCsvButton: {
    marginRight: 20,
  },
  classTooltip: {
    marginRight: 10,
  },
  circularProgressStyle: {
    marginRight: 15,
  },
  gridButtonsStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const CONTACTABLE_TYPE = 'payer';
const DESCRIPTION =
  'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

const HomePayers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showFormDialog, setShowFormDialog] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const [showContactsDropzone, setShowContactsDropzone] = useState(false);
  const [csvPayersData, setCsvPayersData] = useState([]);
  const [csvContactsData, setCsvContactsData] = useState([]);
  const [search, setSearch] = useState(null);
  const [field, setField] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElContacts, setAnchorElContacts] = useState(null);
  const [showLoaderComponent, setShowLoaderComponent] = useState(false);
  const [csvLocalError, setCsvLocalError] = useState(null);

  const {
    payers,
    savingPayer,
    savedPayer,
    payerError,
    loadedPayerCsv,
    savingCsv,
    pagination,
    payersToCSV,
    payerCsvError,
    invalidPayers = [],
  } = useSelector(state => state.payer);
  const {
    payerContactsCsv,
    payerContactsCsvError,
    payerContactsCsvIsLoading,
    payerContactsCsvIsLoaded,
    contactsToCSVMIGRACIONCL,
    invalidContacts = [],
  } = useSelector(state => state.contacts);
  const country = useSelector(state => state.config.country);
  const { isApiGlobal } = settings[country];
  const { subRubros } = useSelector(state => state.rubro);
  const { economicGroups } = useSelector(state => state.economicGroup);

  const handleDownloadContactsCsv = () => {
    if (
      contactsToCSVMIGRACIONCL === null ||
      contactsToCSVMIGRACIONCL?.length === 0
    ) {
      return;
    }
    const newContactsInfo = [];
    contactsToCSVMIGRACIONCL.forEach(uniqueContact => {
      const { rut, contacts = [] } = uniqueContact;
      contacts.forEach(contact => {
        const { type, name, email, phone } = contact;
        newContactsInfo.push({ rut, type, name, email, phone });
      });
    });
    const contactsCvsArray = newContactsInfo.map(contactData => {
      const { rut, type, name, email, phone } = contactData;

      //  This object is manually built to keep the order of the columns in the PDF.
      let contactDataObj = {};
      if (rut) contactDataObj.rut = rut;
      contactDataObj = { ...contactDataObj, type, name, email, phone };

      return contactDataObj;
    });
    setCsvContactsData(contactsCvsArray);
  };

  const handleDownloadPayersCsv = () => {
    if (payersToCSV.length === 0) {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.NO_EXPORTABLE_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }
    const payersToCsvArray = payersToCSV.map(payer => {
      const {
        identifier,
        rut,
        dv,
        ranking,
        name,
        subRubroId,
        taxSubSectorId,
        economicGroupId,
      } = payer;

      if (country === COUNTRY_CODE_CL && !isApiGlobal) {
        return {
          rut,
          dv,
          ranking,
          name,
          subRubroId,
          economicGroupId,
        };
      }
      return {
        identifier,
        ranking,
        name,
        taxSubSectorId,
        economicGroupId,
      };
    });

    setCsvPayersData(payersToCsvArray);
  };

  const handleDownloadContactsCsvGLOBAL = () => {
    if (!payerContactsCsv.length) {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.NO_EXPORTABLE_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }

    const payerContactsArray = [];

    payerContactsCsv.forEach(payerContact => {
      const { identifier, ContactDetails: contacts } = payerContact;
      return contacts.forEach(contact => {
        const { name, email, phone, type } = contact;
        payerContactsArray.push({ identifier, name, email, phone, type });
      });
    });
    setCsvContactsData(payerContactsArray);
  };

  // This useEffect is to avoid triggering downloads when switching between countries
  useEffect(() => {
    if (csvPayersData.length) {
      dispatch(resetPayerCsvData());
      setCsvPayersData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvPayersData]);

  // This useEffect is to avoid triggering downloads when switching between countries
  useEffect(() => {
    if (csvContactsData.length) {
      dispatch(resetPayerContactsCsvData());
      setCsvContactsData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvContactsData]);

  useEffect(() => {
    if (csvLocalError) {
      dispatch(
        enqueueSnackbar(csvLocalError, {
          variant: 'error',
        })
      );
      setCsvLocalError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvLocalError]);

  // MIGRACIONCL: Delete this useEffect after migration. We will be using the one below
  useEffect(() => {
    if (contactsToCSVMIGRACIONCL?.length) {
      handleDownloadContactsCsv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsToCSVMIGRACIONCL]);

  useEffect(() => {
    if (payerContactsCsv?.length) {
      handleDownloadContactsCsvGLOBAL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerContactsCsv]);

  useEffect(() => {
    if (payersToCSV?.length) {
      handleDownloadPayersCsv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payersToCSV]);

  useEffect(() => {
    if (savingCsv) {
      setShowLoaderComponent(false);
    }
  }, [savingCsv]);

  useEffect(() => {
    if (loadedPayerCsv) {
      dispatch(
        enqueueSnackbar(`${payers.length} pagadores agregados`, {
          variant: 'success',
        })
      );

      if (!invalidPayers?.length) {
        setShowDropzone(false);
      }
      dispatch(resetPayerCsvData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPayerCsv]);

  useEffect(() => {
    if (payerContactsCsvIsLoaded) {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.CONTACTS_ADDED, {
          variant: 'success',
        })
      );
      if (!invalidContacts?.length) {
        setShowDropzone(false);
      }

      dispatch(resetPayerContactsCsvData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerContactsCsvIsLoaded]);

  useEffect(() => {
    if (savedPayer) {
      setShowFormDialog(false);
      const shouldFetchEconomicGroup = payers.some(
        payer => !economicGroups.find(ec => ec.id === payer.economicGroupId)
      );

      if (shouldFetchEconomicGroup) {
        dispatch(fetchEconomicGroups());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedPayer]);

  const handleEscapeButton = event => {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      setShowDropzone(false);
      setShowFormDialog(false);
    }
  };

  useEffect(() => {
    dispatch(fetchPayers({}));
    dispatch(fetchEconomicGroups());
    dispatch(fetchSubRubros());
    document.addEventListener('keydown', handleEscapeButton);
  }, [dispatch]);

  const allCsvValidator = (data, errors) => {
    if (!data?.length === 0) {
      setCsvLocalError('CSV sin datos');
      return false;
    }
    if (errors.length) {
      errors.forEach(message => {
        setCsvLocalError(message);
      });
      return false;
    }
    return true;
  };

  const handleSearch = ({ page, searchInput, field, limit }) => {
    dispatch(
      fetchPayers({
        page,
        searchInput,
        field,
        limit,
      })
    );
  };

  const handleChangePage = (page, limit) => {
    handleSearch({
      page,
      searchInput: search,
      field,
      limit,
    });
  };

  const handleSearchChange = ({ value = '', field } = {}) => {
    const searchInput = value;
    setSearch(searchInput);
    setField(field);
    handleSearch({
      page: INIT_PAGE,
      searchInput,
      field,
      limit: pagination.limit,
    });
  };

  const changeSearchInput = ({ value, field }) => {
    if (value?.length >= 2) {
      setTimeout(() => {
        handleSearchChange({ value, field });
      }, SEARCH_BAR_TIMEOUT);
    } else if (!value || value?.length <= 2) {
      setTimeout(() => {
        handleSearchChange();
      }, SEARCH_BAR_TIMEOUT);
    }
  };

  const handlerDownloadPayersCsv = () => {
    if (csvPayersData?.length > 0) {
      handleDownloadPayersCsv();
    }
    dispatch(fetchInfoPayersToCSV());
  };

  const handlerDownloadContactsCsv = () => {
    if (csvContactsData?.length > 0) {
      handleDownloadContactsCsv();
    }

    if (country === COUNTRY_CODE_CL && !isApiGlobal) {
      dispatch(fetchContactsCsvMIGRACIONCL());
    } else {
      dispatch(fetchContactsCsv(CONTACTABLE_TYPE));
    }
  };

  const handleShowPayerDropzone = () => {
    setShowDropzone(true);
    setAnchorEl(null);
  };

  const handleShowContactsDropzone = () => {
    setShowContactsDropzone(true);
    setAnchorElContacts(null);
  };

  const handleFormatContactsCSV = contactsCSV => {
    const cleanContacts = contactsCSV.filter(
      item => Object.keys(item).length === 5
    );
    dispatch(loadContactsCsvMIGRACIONCL('payer', cleanContacts));
    setShowLoaderComponent(true);
  };

  const handleSubmitNewPayer = payerData => {
    dispatch(registerPayer({ ...payerData, countryId: country }));
  };

  const handleLoadContacts = files => {
    if (!files) {
      setCsvLocalError('Debe seleccionar un archivo antes');
      return;
    }

    if (isApiGlobal) {
      const data = new FormData();
      data.append('file', files[0].file, files[0].file.name);
      dispatch(loadContactsCsv(CONTACTABLE_TYPE, data));
      return;
    }

    const file = files.length ? files[0].file : [];
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;
      const newFileData = fileData.replace(/;/g, ',');

      if (!newFileData.includes(',')) {
        setCsvLocalError(
          `Delimitador erroneo. Delimitador esperado: "," (coma).`
        );
        return;
      }

      CSVFileValidator(file, CSV_CONFIG_CONTACTS)
        .then(csv => {
          if (allCsvValidator(csv.data, csv.inValidMessages)) {
            setShowContactsDropzone(false);
            handleFormatContactsCSV(csv.data);
          }
        })
        .catch(err => {
          setCsvLocalError(`CSVFileValidator: ${err}`);
        });
    };
    reader.readAsBinaryString(file);
  };

  const csvConfigPayer = () => {
    return {
      headers: [
        {
          name: 'rut',
          inputName: 'rut',
          required: true,
          unique: true,
          dependentValidate(rut, row) {
            const identifier = `${rut}-${row.dv}`;
            return validateIdentifier(identifier, country);
          },
          requiredError(headerName, rowNumber) {
            return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
          },
          validateError(headerName, rowNumber) {
            return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
          },
          uniqueError(headerName) {
            return `CSV: Existen ${headerName} repetidos`;
          },
        },
        {
          name: 'dv',
          inputName: 'dv',
          required: true,
          unique: true,
          requiredError(headerName, rowNumber) {
            return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
          },
          validateError(headerName, rowNumber) {
            return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
          },
          uniqueError(headerName) {
            return `CSV: Existen ${headerName} repetidos`;
          },
        },
        {
          name: 'ranking',
          inputName: 'ranking',
          optional: true,
          validate(rankingInput) {
            const regexText = /^[A-Za-z]+$/;
            if (!rankingInput.match(regexText)) {
              return true;
            }
            return false;
          },
          validateError(headerName, rowNumber) {
            return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
          },
        },
        {
          name: 'name',
          inputName: 'name',
          required: true,
          requiredError(headerName, rowNumber) {
            return `CSV: ${headerName} es requerido en la fila ${rowNumber}`;
          },
        },
        {
          name: 'subRubroId',
          inputName: 'subRubroId',
          validate(subRubroIdInput) {
            const regexNumbers = /^[0-9]*$/;
            if (subRubroIdInput.match(regexNumbers) || !subRubroIdInput) {
              return true;
            }
            return false;
          },
          validateError(headerName, rowNumber) {
            return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
          },
        },
        {
          name: 'economicGroupId',
          inputName: 'economicGroupId',
          validate(economicGroupIdInput) {
            const regexNumbers = /^[0-9]*$/;
            if (
              economicGroupIdInput.match(regexNumbers) ||
              !economicGroupIdInput
            ) {
              return true;
            }
            return false;
          },
          validateError(headerName, rowNumber) {
            return `CSV: ${headerName} no es válido en la fila ${rowNumber}`;
          },
        },
      ],
      isHeaderNameOptional: true,
    };
  };

  const handleLoadPayers = files => {
    if (!files) {
      setCsvLocalError('Debe seleccionar un archivo antes');
      return;
    }

    if (isApiGlobal) {
      const data = new FormData();
      data.append('file', files[0].file, files[0].file.name);
      dispatch(loadPayersCsv(data));
      return;
    }

    const file = files.length ? files[0].file : [];
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;
      const newFileData = fileData.replace(/;/g, ',');
      if (!newFileData.includes(',')) {
        setCsvLocalError(
          `Delimitador erroneo. Delimitador esperado: "," (coma).`
        );
        return;
      }
      CSVFileValidator(file, csvConfigPayer())
        .then(csv => {
          if (allCsvValidator(csv.data, csv.inValidMessages)) {
            setShowDropzone(false);
            dispatch(loadPayersCsvMIGRACIONCL(csv.data));
            setShowLoaderComponent(true);
          }
        })
        .catch(err => {
          setCsvLocalError(`CSVFileValidator: ${err}`);
        });
    };
    reader.readAsBinaryString(file);
  };

  const handleCloseBulkCsvDialog = () => {
    dispatch(resetBulkCsvPayersDialog());
    dispatch(resetContactsCsvError(CONTACTABLE_TYPE));
    setShowDropzone(false);
    setShowContactsDropzone(false);
  };

  return (
    <>
      {showLoaderComponent && (
        <LoaderComponent
          open={showLoaderComponent}
          handleCloseDialog={setShowLoaderComponent(false)}
        />
      )}

      {csvPayersData?.length > 0 && (
        <CSVDownload separator="," target="_self" data={csvPayersData} />
      )}

      {csvContactsData?.length > 0 && (
        <CSVDownload separator="," target="_self" data={csvContactsData} />
      )}

      <Grid container className={classes.mainContainer}>
        {showDropzone && (
          <FileUploadDialog
            open={showDropzone}
            handleCloseDialog={handleCloseBulkCsvDialog}
            saving={savingCsv}
            handleLoad={csvFile => handleLoadPayers(csvFile)}
            title="Carga de pagadores"
            description={DESCRIPTION}
            actionText="IMPORTANTE: Se eliminarán los pagadores previamente cargados."
            errorsCsv={invalidPayers}
            resetDataAndErrors={() => dispatch(resetBulkCsvPayersDialog())}
            serverError={payerCsvError}
            source="payer"
          />
        )}

        {showContactsDropzone && (
          <FileUploadDialog
            open={showContactsDropzone}
            handleCloseDialog={handleCloseBulkCsvDialog}
            saving={payerContactsCsvIsLoading}
            handleLoad={csvFile => handleLoadContacts(csvFile)}
            title="Cargar contactos de pagadores"
            description={DESCRIPTION}
            actionText="IMPORTANTE: Se eliminarán los contactos previamente cargados."
            errorsCsv={invalidContacts}
            resetDataAndErrors={() =>
              dispatch(resetContactsCsvError(CONTACTABLE_TYPE))
            }
            serverError={payerContactsCsvError}
            source="contact"
          />
        )}

        {showFormDialog && (
          <PayerFormDialog
            open={showFormDialog}
            saving={savingPayer}
            error={payerError}
            resetError={resetPayerError}
            taxSubSectors={subRubros}
            handleCloseDialog={() => {
              setShowFormDialog(false);
            }}
            handleSubmitForm={handleSubmitNewPayer}
          />
        )}

        <Grid item xs={12} className={classes.buttonContainer}>
          <Can
            perform={PAYERS_DOWNLOAD_LIST_PERFORM}
            yes={() => (
              <Tooltip
                component="span"
                title="Acciones"
                className={classes.classTooltip}
              >
                <Button
                  ref={anchorElContacts}
                  onClick={event => {
                    setAnchorElContacts(event.currentTarget);
                  }}
                  color="primary"
                  startIcon={<User />}
                >
                  Contactos
                </Button>
              </Tooltip>
            )}
          />
          <Can
            perform={PAYERS_UPLOAD_CSV_PERFORM}
            yes={() => (
              <>
                <Tooltip
                  component="span"
                  title="Acciones"
                  className={classes.classTooltip}
                >
                  <Button
                    ref={anchorEl}
                    onClick={event => {
                      setAnchorEl(event.currentTarget);
                    }}
                    startIcon={<StorefrontIcon />}
                    color="primary"
                  >
                    Pagadores
                  </Button>
                </Tooltip>
              </>
            )}
          />
          <Can
            perform={PAYERS_ADD_NEW_PERFORM}
            yes={() => (
              <Button
                onClick={() => {
                  setShowFormDialog(true);
                }}
                startIcon={<AddCircleIcon />}
                color="primary"
                variant="text"
              >
                Nuevo Pagador
              </Button>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Panel
            title="Lista de pagadores"
            contentPadding="zero zero sm"
            actions={
              <SearchBar
                placeholder="Buscar pagador..."
                handleChangeInput={value => changeSearchInput(value)}
                fields={[
                  { field: '`Payer`.`name`', title: 'Nombre' },
                  {
                    field:
                      country === COUNTRY_CODE_CL && !isApiGlobal
                        ? 'rut'
                        : 'identifier',
                    title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
                  },
                  {
                    field: '`EconomicGroup`.`name`',
                    title: 'Grupo Económico',
                  },
                ]}
                selectedOption={{ field: '`Payer`.`name`', title: 'Nombre' }}
              />
            }
          >
            <PayersTable
              pagination={pagination}
              handleChangePage={handleChangePage}
              tableData={payers}
            />
          </Panel>
        </Grid>
      </Grid>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem key="show-payer-dropzone">
          <Button
            startIcon={<CloudUploadIcon />}
            variant="text"
            color="primary"
            onClick={handleShowPayerDropzone}
          >
            Subir Csv Pagadores
          </Button>
        </MenuItem>
        <MenuItem key="show-payer-csv-link">
          {savingCsv ? (
            <Grid item className={classes.gridButtonsStyle}>
              <CircularProgress
                className={classes.circularProgressStyle}
                size={20}
              />
              <Typography variant="body1" component="div" color="primary">
                <Box fontWeight="fontWeightBold">Cargando pagadores...</Box>
              </Typography>
            </Grid>
          ) : (
            <Button
              startIcon={<CloudDownloadIcon />}
              className={classes.addPayerCsvButton}
              onClick={handlerDownloadPayersCsv}
              variant="text"
              color="primary"
            >
              Descargar pagadores
            </Button>
          )}
        </MenuItem>
      </Menu>
      <Menu
        id="long-menu"
        anchorEl={anchorElContacts}
        keepMounted
        open={Boolean(anchorElContacts)}
        onClose={() => {
          setAnchorElContacts(null);
        }}
        anchorPosition={{
          marginLeft: 60,
        }}
        getContentAnchorEl={null}
      >
        <MenuItem key="show-contacts-dropzone">
          <Button
            variant="text"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={handleShowContactsDropzone}
          >
            Subir Csv Contactos
          </Button>
        </MenuItem>
        <MenuItem key="download-contacts-csv">
          {payerContactsCsvIsLoading ? (
            <Grid item className={classes.gridButtonsStyle}>
              <CircularProgress
                className={classes.circularProgressStyle}
                size={20}
              />
              <Typography variant="body1" component="div" color="primary">
                <Box fontWeight="fontWeightBold">Cargando contactos...</Box>
              </Typography>
            </Grid>
          ) : (
            <Button
              startIcon={<CloudDownloadIcon />}
              onClick={handlerDownloadContactsCsv}
              variant="text"
              color="primary"
            >
              Descargar contactos
            </Button>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default HomePayers;
