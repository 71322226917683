export const resetPayrollSelectorUseCase = ({ currentSelectorState }) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const { amount, name, value, receiverDebtServiceAmount } =
      currentSelectorState[receiverIdentifier];
    acc[receiverIdentifier] = {
      amount,
      name,
      receiverDebtServiceAmount,
      value: value.map(({ rate, expirationDate, ...doc }) => doc),
    };

    return acc;
  }, {});
};
