import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFetchInvoices } from '../infrastructure/store';
import { COUNTRY_CODE_CL, DATASTUDIO_URL } from '../../../helpers/Constants';
import useOrderDetails from '../../pages/PaymentsDetails/useOrderDetails';

const useDetailsActions = () => {
  const { id: orderId } = useParams();
  const { order = {} } = useOrderDetails();
  const { invoices = [] } = useFetchInvoices(orderId);
  const { country } = useSelector(state => state.config);

  const { businessIdentifier } = order;
  const [{ issuerIdentifier } = {}] = invoices;

  const riskAnalysisUrl =
    country === COUNTRY_CODE_CL
      ? `${DATASTUDIO_URL}/u/0/reporting/ee31219f-e777-4b16-8dd1-4870fbdeb6a7/page/p_cr8cgf2vrc?params={"ds20.OrderId":"${orderId}","ds1.RutCliente":"${issuerIdentifier}","ds50.RutPagador":"${businessIdentifier}"}`
      : `${DATASTUDIO_URL}/u/0/reporting/fda05245-3aa3-4d35-a743-97f0eb648710/page/pi7tC?params={"ds27.rfcCliente":"${businessIdentifier}","ds26.rfcCliente":"${businessIdentifier}","ds0.rfcCliente":"${businessIdentifier}","ds35.rfcPagador":"${issuerIdentifier}","ds123.rfcCliente":"${businessIdentifier}","ds2.rfcCliente":"${businessIdentifier}","ds33.rfcCliente":"${businessIdentifier}","ds33.rfcPagador":"${issuerIdentifier}","ds1.orderId":"${orderId}","ds1.rfcCliente":"${businessIdentifier}","ds34.rfcPagador":"${issuerIdentifier}","ds168.rfcCliente":"${businessIdentifier}","ds168.orderId":"${orderId}"}`;

  return { riskAnalysisUrl };
};

export default useDetailsActions;
