import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MailInProcess2 = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M21.8896 19.7232L17.3226 11.5659L12.7556 3.40857C12.682 3.28381 12.5786 3.1807 12.4554 3.10914C12.3321 3.03759 12.193 3 12.0516 3C11.9102 3 11.7712 3.03759 11.6479 3.10914C11.5246 3.1807 11.4212 3.28381 11.3477 3.40857L6.78068 11.5659L2.11066 19.7232C2.03936 19.8505 2.00125 19.9948 2.00003 20.1419C1.99881 20.2891 2.03453 20.434 2.10371 20.5626C2.17289 20.6912 2.27316 20.7991 2.39474 20.8758C2.51632 20.9524 2.65506 20.9952 2.79743 21H21.1685C21.3138 21.0013 21.4568 20.963 21.5831 20.8888C21.7094 20.8147 21.8145 20.7073 21.8877 20.5777C21.9609 20.4481 21.9997 20.3008 22 20.1507C22.0003 20.0006 21.9622 19.8531 21.8896 19.7232ZM13.1333 17.6661C13.1333 17.8152 13.1049 17.9627 13.0496 18.1004C12.9944 18.2381 12.9135 18.3632 12.8114 18.4686C12.7094 18.574 12.5883 18.6576 12.455 18.7147C12.3216 18.7717 12.1788 18.801 12.0345 18.801C11.8902 18.801 11.7473 18.7717 11.614 18.7147C11.4806 18.6576 11.3595 18.574 11.2575 18.4686C11.1554 18.3632 11.0745 18.2381 11.0193 18.1004C10.964 17.9627 10.9356 17.8152 10.9356 17.6661V17.1696C10.9356 16.8686 11.0514 16.5799 11.2575 16.3671C11.4635 16.1542 11.743 16.0347 12.0345 16.0347C12.3259 16.0347 12.6054 16.1542 12.8114 16.3671C13.0175 16.5799 13.1333 16.8686 13.1333 17.1696V17.6661ZM13.1333 13.6229C13.1333 13.9239 13.0175 14.2126 12.8114 14.4254C12.6054 14.6383 12.3259 14.7579 12.0345 14.7579C11.743 14.7579 11.4635 14.6383 11.2575 14.4254C11.0514 14.2126 10.9356 13.9239 10.9356 13.6229V8.33842C10.9356 8.03742 11.0514 7.74875 11.2575 7.5359C11.4635 7.32306 11.743 7.20349 12.0345 7.20349C12.3259 7.20349 12.6054 7.32306 12.8114 7.5359C13.0175 7.74875 13.1333 8.03742 13.1333 8.33842V13.6229Z" />
    </SvgIcon>
  );
};

export default MailInProcess2;
