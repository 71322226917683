import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import StatusChip from './chips/StatusChip';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles({
  root: {
    margin: convertSpacingToCss('-xs'),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  item: {
    margin: convertSpacingToCss('xs'),
  },
});

const ORDER = 'order';
const INVOICE = 'invoice';
const CREDIT = 'credit';
const OPERATION_TYPE = 'operationType';

const ShowSelectedStatuses = ({
  selectedStatuses,
  handleDeleteStatus,
  entityType,
}) => {
  const classes = useStyles();

  return (
    <Grid item container className={classes.root}>
      {selectedStatuses?.map(status => (
        <StatusChip
          key={status}
          size="small"
          entityType={entityType}
          status={status}
          className={classes.item}
          onDelete={() => handleDeleteStatus(status)}
          data-qa="selected-status-filter-chip"
        />
      ))}
    </Grid>
  );
};

ShowSelectedStatuses.propTypes = {
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleDeleteStatus: PropTypes.func.isRequired,
  entityType: PropTypes.oneOf([ORDER, INVOICE, CREDIT, OPERATION_TYPE])
    .isRequired,
};

export default ShowSelectedStatuses;
