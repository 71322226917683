// TODO: to move ALL

export const hasValue = v => ![null, undefined, ''].includes(v);

const fixNumberTo = lengthDecimals => num => {
  if (!hasValue(num)) {
    return '';
  }

  const isINT = num % 1 === 0;
  const formattedNumber = isINT ? num : (+num)?.toFixed(lengthDecimals);

  return +formattedNumber;
};

export function to(promise, errInfo) {
  return promise
    .then(res => [undefined, res])
    .catch(err => {
      if (errInfo) {
        Object.assign(err, errInfo);
      }

      return [err, undefined];
    });
}

export const defaultValue = (value, defaultVal = '-', format = v => v) => {
  return value ? format(value) : defaultVal;
};

export const fixDecimals = fixNumberTo(2);

export const percentageFormat = val => {
  if (!hasValue(val)) {
    return '-';
  }

  const formattedNumber = fixDecimals(val);

  return `${formattedNumber}%`;
};

// TODO: to replace by purgeNestedObj
export const purgeObj = obj =>
  Object.keys(obj)
    .filter(k => hasValue(obj[k]))
    .reduce((acc, k) => ({ ...acc, [k]: obj[k] }), {});

export const toList = obj => Object.keys(obj).map(k => obj[k]);

export const addDays = (days, date = new Date()) => {
  const result = new Date(date);
  const req = result.getDate() + +days;
  result.setDate(req);

  return result;
};
