export function getKeysSelected<T extends Record<string, unknown>>(props: T): string[] {
  const keysSelected: string[] = [];

  Object.entries(props).forEach(([key, value]) => {
    if (value) {
      keysSelected.push(key);
    }
  });

  return keysSelected;
}
