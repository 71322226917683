import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Tooltip } from '@material-ui/core';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { dateFormatter } from '../../../../../helpers/DateUtils';
import useGetCountry from '../../../../../hooks/useGetCountry';
import EditIcon from '../../../icons/EditIcon';
import {
  RowText,
  BigText,
  BusinessSegmentText,
  RowTextBold,
  CurrencyRowTextBold,
  CurrencyBigText,
} from '../styles';
import { palette } from '../../../../../theme/palette';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import {
  BusinessSegment,
  Countries,
  CurrencyAvailable,
  FundOperationResumeDictionary,
  FundOperationResumeModel,
  FundOperationSummaryData,
  Operation,
  OperationResumeDictionary,
  OperationResumeModel,
  OperationSummaryData,
  VariantCountryFormatHelperAvailable,
} from '../../../interfaces/ARInterfaces';
import { features } from '../../../../../config/features';
import {
  fetchOperationFundSummary,
  fetchOperationSummary,
  fetchSelectedOrder,
  getTransferAmountPyme,
  resetStateUpdateOrder,
} from '../../../../../actions/orderActions';
import {
  BUSINESS_SEGMENT_DICTIONARY,
  COUNTRY_CODE_CL,
  CURRENCY,
  DATASTUDIO_URL,
  STATUS_APPEALED,
  STATUS_APPROVED,
  STATUS_APPROVED_FUND,
  STATUS_IN_REVIEW,
  STATUS_REVIEWED,
  STATUS_TO_DEPOSIT,
} from '../../../../../helpers/Constants';
import {
  INVOICES_LINKED_PDF_PERFORM,
  ORDERS_DISCOUNTS_PERFORM,
  ORDERS_NEW_SIMULATION_PERFORM,
  ORDERS_EDIT_STATUS_PERFORM,
} from '../../../../../helpers/performsType';
import { resetDiscount } from '../../../../../actions/discountActions';
import { checkAuth } from '../../../../../helpers/validation/auth';

const useOperationResume = (operation: Operation) => {
  const country = useGetCountry();
  const dispatch = useDispatch();

  const {
    transferDatePyme,
    transferAmountPyme,
    orderSummaryIsLoading,
    fundSummaryIsLoading,
    fetchOrderIsLoading,
  } = useSelector((state: RootStateOrAny) => state.order);
  const { business: businessInfoComplete } = useSelector(
    (state: RootStateOrAny) => state.business
  );
  const {
    fundSummary,
    orderSummary,
    orderSummaryWasLoaded,
    facturas: invoices,
  } = useSelector((state: RootStateOrAny) => state.order);
  const { discountWasCreated, discountWasDeleted, discountWasUpdated } =
    useSelector((state: RootStateOrAny) => state.discount);
  const {
    rules,
    user: { roles },
  } = useSelector((state: RootStateOrAny) => state.auth);
  const {
    attributes: { currency },
    paymentOrderDetailsIsLoading: isLoadingCurrency,
  } = useSelector((state: RootStateOrAny) => state.paymentOrderDetails);

  const countryFeatures = features[country];
  const isAdmin = roles.some((role: any) => role.rolename === 'admin');
  const [showOperationSummary, setShowOperationSummary] = useState(true);
  const [showFundOperationSummary, setShowFundOperationSummary] =
    useState(false);
  const [showDiscountDrawer, setShowDiscountDrawer] = useState(false);
  const [businessSegment, setBusinessSegment] = useState('');
  const [operationCurrency, setOperationCurrency] = useState<CurrencyAvailable>(
    currency as CurrencyAvailable
  );

  const toggleShowOperationSummary = useCallback(() => {
    setShowOperationSummary(!showOperationSummary);
  }, [setShowOperationSummary, showOperationSummary]);

  const toggleShowFundOperationSummary = useCallback(() => {
    setShowFundOperationSummary(!showFundOperationSummary);
  }, [setShowFundOperationSummary, showFundOperationSummary]);

  const handleCloseDiscountDrawer = useCallback(() => {
    setShowDiscountDrawer(false);
    dispatch(resetStateUpdateOrder());
  }, [setShowDiscountDrawer]);

  const handleShowDiscountDrawer = useCallback(() => {
    setShowDiscountDrawer(true);
  }, [setShowDiscountDrawer]);

  const businessIdentifier = businessInfoComplete?.identifier;
  const payerIdentifier = invoices?.[0]?.payerIdentifier;

  const riskAnalysisUrl =
    country === COUNTRY_CODE_CL
      ? `${DATASTUDIO_URL}/u/0/reporting/ee31219f-e777-4b16-8dd1-4870fbdeb6a7/page/p_cr8cgf2vrc?params={"ds20.OrderId":"${operation?.id}","ds1.RutCliente":"${businessIdentifier}","ds50.RutPagador":"${payerIdentifier}"}`
      : `${DATASTUDIO_URL}/u/0/reporting/fda05245-3aa3-4d35-a743-97f0eb648710/page/pi7tC?params={"ds27.rfcCliente":"${businessIdentifier}","ds26.rfcCliente":"${businessIdentifier}","ds0.rfcCliente":"${businessIdentifier}","ds35.rfcPagador":"${payerIdentifier}","ds123.rfcCliente":"${businessIdentifier}","ds2.rfcCliente":"${businessIdentifier}","ds33.rfcCliente":"${businessIdentifier}","ds33.rfcPagador":"${payerIdentifier}","ds1.orderId":"${operation?.id}","ds1.rfcCliente":"${businessIdentifier}","ds34.rfcPagador":"${payerIdentifier}","ds168.rfcCliente":"${businessIdentifier}","ds168.orderId":"${operation?.id}"}`;

  const availableNewSimulationDialog =
    isAdmin ||
    (checkAuth(roles, ORDERS_NEW_SIMULATION_PERFORM, rules) &&
      [
        STATUS_IN_REVIEW,
        STATUS_REVIEWED,
        STATUS_APPEALED,
        STATUS_APPROVED,
        STATUS_APPROVED_FUND,
        STATUS_TO_DEPOSIT,
      ].includes(operation?.status));

  const availableDiscountStatus = {
    CL: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPEALED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
    ],
    MX: [STATUS_IN_REVIEW, STATUS_REVIEWED, STATUS_APPEALED],
  };

  const availableDiscountButton =
    countryFeatures.orderActions.showOperationDiscountButton &&
    checkAuth(roles, ORDERS_DISCOUNTS_PERFORM, rules) &&
    availableDiscountStatus[country].includes(operation?.status);

  const availableTransferAmount =
    countryFeatures.orderActions.fetchTransferAmountPyme;

  const operationResumeDictionary: {
    [key in OperationResumeDictionary]: string;
  } = {
    totalAmount: 'Monto total de facturas',
    advancePercentage: 'Anticipo',
    advance: 'Monto de anticipo',
    retention: 'Retención',
    baseRate: 'Tasa base',
    interest: 'Diferencia de precio',
    operationCost: 'Asesoría digital',
    totalDiscounts: 'Descuentos a facturas',
    transferDiscount: 'Dto. a operación',
    finalAmount: 'Total a transferir',
    availableRetention: 'Retención vigente',
    totalDebtAmount: 'Monto total adeudado',
    transferDate: 'Fecha de transferencia',
  };

  const fundOperationResumeDictionary: {
    [key in FundOperationResumeDictionary]: string;
  } = {
    totalAmount: 'Monto total',
    advance: 'Anticipo',
    totalRetention: 'Retención',
    avgAmountBaseRate: 'Tasa base',
    interest: 'Costo fondo',
    transferFund: 'Monto Transferencia Fondo',
    availableRetention: 'Retención vigente',
  };

  const availablesCurrencyPrefixes: { [key in CurrencyAvailable]?: string } =
    {};
  const availablesFundCurrencyPrefixes: {
    [key in CurrencyAvailable]?: string;
  } = {};

  const performs = {
    INVOICES_LINKED_PDF_PERFORM,
    ORDERS_DISCOUNTS_PERFORM,
    ORDERS_EDIT_STATUS_PERFORM,
  };

  const getAvailableRetentionColor = useCallback(() => {
    let currentRetention = 0;
    if (
      Array.isArray(invoices) &&
      invoices.length &&
      !!invoices.find(invoice => invoice.amountRetention)
    ) {
      currentRetention = invoices.reduce(
        (total, current) =>
          current.amountRetention ? total + current.amountRetention : total,
        0
      );
    } else if (invoices?.amountRetention) {
      currentRetention = invoices.amountRetention;
    }

    return currentRetention > 0 ? palette.success.main : palette.error.main;
  }, [invoices]);

  const getCountryFormatHelper = useCallback(
    ({
      value,
      variant,
      country,
      currency,
    }: {
      value: number;
      variant: VariantCountryFormatHelperAvailable;
      country: Countries;
      currency: CurrencyAvailable;
    }) => {
      return (
        <>
          {/* @ts-ignore */}
          <CountryFormatHelper
            value={value}
            variant={variant}
            countryId={country}
            currency={currency}
          />
        </>
      );
    },
    []
  );

  const getTooltipTitle = useCallback(
    (value: number, operationCurrency: CurrencyAvailable) => {
      if (!availableTransferAmount) return '';
      if (value === transferAmountPyme) {
        return 'ok';
      }
      return getCountryFormatHelper({
        value: transferAmountPyme || 0,
        variant: 'currency',
        country,
        currency: operationCurrency,
      });
    },
    [availableTransferAmount, transferAmountPyme]
  );

  const getFinalAmountColor = useCallback(
    (finalAmount: number) => {
      if (!availableTransferAmount) return '';
      return finalAmount === transferAmountPyme
        ? palette.success.main
        : palette.error.main;
    },
    [availableTransferAmount, transferAmountPyme]
  );

  const getOperationSummaryLabel = useCallback(
    (key: OperationResumeDictionary) => {
      switch (key) {
        case 'totalAmount':
        case 'advancePercentage':
        case 'advance':
        case 'retention':
        case 'baseRate':
        case 'interest':
        case 'operationCost':
        case 'totalDiscounts':
        case 'availableRetention':
        case 'transferDate':
          return <RowText>{operationResumeDictionary[key]}</RowText>;
        case 'transferDiscount':
          return (
            <Button
              variant="text"
              color="primary"
              startIcon={
                <EditIcon
                  color={
                    availableDiscountButton
                      ? palette.primary.main
                      : palette.text.disabled
                  }
                />
              }
              onClick={handleShowDiscountDrawer}
              disabled={!availableDiscountButton}
            >
              {operationResumeDictionary[key]}
            </Button>
          );
        case 'finalAmount':
          return <BigText>{operationResumeDictionary[key]}</BigText>;
        case 'totalDebtAmount':
          return (
            <Box>
              <RowText>{operationResumeDictionary[key]}</RowText>
              {businessSegment && (
                <BusinessSegmentText>({businessSegment})</BusinessSegmentText>
              )}
            </Box>
          );
        default:
          return <RowText>-</RowText>;
      }
    },
    [businessSegment, availableDiscountButton, handleShowDiscountDrawer]
  );

  const getFundOperationSummaryLabel = useCallback(
    (key: FundOperationResumeDictionary) => {
      switch (key) {
        case 'totalAmount':
        case 'advance':
        case 'totalRetention':
        case 'avgAmountBaseRate':
        case 'interest':
        case 'transferFund':
        case 'availableRetention':
          return <RowText>{fundOperationResumeDictionary[key]}</RowText>;
        default:
          return <RowText>-</RowText>;
      }
    },
    []
  );

  const getOperationSummaryValue = useCallback(
    (
      key: OperationResumeDictionary,
      {
        value,
        variant,
        applyPrefixCurrency = true,
        operationCurrency = 'CLP',
        color = '',
      }: {
        value: number | string;
        variant: VariantCountryFormatHelperAvailable;
        applyPrefixCurrency?: boolean;
        operationCurrency?: CurrencyAvailable;
        color?: string;
      }
    ) => {
      let currencyText = '';
      if (applyPrefixCurrency) {
        currencyText = availablesCurrencyPrefixes[operationCurrency] || '';
      }

      switch (key) {
        case 'totalAmount':
        case 'advance':
        case 'retention':
        case 'interest':
        case 'operationCost':
        case 'totalDiscounts':
        case 'transferDiscount':
        case 'availableRetention':
        case 'totalDebtAmount':
        case 'advancePercentage':
        case 'baseRate':
          return (
            <Box display="flex" alignItems="center">
              {currencyText && (
                <CurrencyRowTextBold customColor={color}>
                  {currencyText}
                </CurrencyRowTextBold>
              )}
              <RowTextBold customColor={color}>
                {getCountryFormatHelper({
                  value: Number(value),
                  variant,
                  country,
                  currency: operationCurrency,
                })}
              </RowTextBold>
            </Box>
          );
        case 'finalAmount':
          return (
            <Tooltip title={getTooltipTitle(Number(value), operationCurrency)}>
              <Box display="flex" alignItems="center">
                {currencyText && (
                  <CurrencyBigText customColor={color}>
                    {currencyText}
                  </CurrencyBigText>
                )}
                <BigText customColor={color}>
                  {getCountryFormatHelper({
                    value: Number(value),
                    variant,
                    country,
                    currency: operationCurrency,
                  })}
                </BigText>
              </Box>
            </Tooltip>
          );
        case 'transferDate':
          return (
            <RowTextBold customColor={color}>
              {dateFormatter(value) || '-'}
            </RowTextBold>
          );
        default:
          return <RowText>-</RowText>;
      }
    },
    [getTooltipTitle]
  );

  const getFundOperationSummaryValue = useCallback(
    (
      key: FundOperationResumeDictionary,
      {
        value,
        variant,
        applyPrefixCurrency = true,
        operationCurrency = 'CLP',
        color = '',
      }: {
        value: number;
        variant: VariantCountryFormatHelperAvailable;
        applyPrefixCurrency?: boolean;
        operationCurrency?: CurrencyAvailable;
        color?: string;
      }
    ) => {
      let currencyText = '';
      if (applyPrefixCurrency) {
        currencyText = availablesFundCurrencyPrefixes[operationCurrency] || '';
      }

      switch (key) {
        case 'totalAmount':
        case 'advance':
        case 'totalRetention':
        case 'avgAmountBaseRate':
        case 'interest':
        case 'transferFund':
        case 'availableRetention':
          return (
            <Box display="flex" alignItems="center">
              {currencyText && (
                <CurrencyRowTextBold customColor={color}>
                  {currencyText}
                </CurrencyRowTextBold>
              )}
              <RowTextBold customColor={color}>
                {getCountryFormatHelper({
                  value,
                  variant,
                  country,
                  currency: operationCurrency,
                })}
              </RowTextBold>
            </Box>
          );
        default:
          return <RowText>-</RowText>;
      }
    },
    []
  );

  const operationSummaryData = useCallback(
    (operationSummary: OperationSummaryData) => {
      const summary: OperationResumeModel[] = [
        {
          key: 'totalAmount',
          label: getOperationSummaryLabel('totalAmount'),
          value: getOperationSummaryValue('totalAmount', {
            value: operationSummary?.totalAmount || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryTotalAmount',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'advancePercentage',
          label: getOperationSummaryLabel('advancePercentage'),
          value: getOperationSummaryValue('advancePercentage', {
            value: operationSummary?.advancePercentage || 0,
            variant: 'percentage',
            applyPrefixCurrency: false,
          }),
          dataCy: 'orderSummaryAdvancePercentage',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'advance',
          label: getOperationSummaryLabel('advance'),
          value: getOperationSummaryValue('advance', {
            value: operationSummary?.advance || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryAdvance',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'retention',
          label: getOperationSummaryLabel('retention'),
          value: getOperationSummaryValue('retention', {
            value: operationSummary?.retention || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryRetention',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'baseRate',
          label: getOperationSummaryLabel('baseRate'),
          value: getOperationSummaryValue('baseRate', {
            value: operationSummary?.baseRate || 0,
            variant: 'percentage',
            applyPrefixCurrency: false,
          }),
          dataCy: 'orderSummaryBaseRate',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'interest',
          label: getOperationSummaryLabel('interest'),
          value: getOperationSummaryValue('interest', {
            value: operationSummary?.interest || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryInterest',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'operationCost',
          label: getOperationSummaryLabel('operationCost'),
          value: getOperationSummaryValue('operationCost', {
            value: operationSummary?.operationCost || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryOperationCost',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'totalDiscounts',
          label: getOperationSummaryLabel('totalDiscounts'),
          value: getOperationSummaryValue('totalDiscounts', {
            value: operationSummary?.totalDiscounts || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryTotalDiscounts',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'transferDiscount',
          label: getOperationSummaryLabel('transferDiscount'),
          value: getOperationSummaryValue('transferDiscount', {
            value: operationSummary?.transferDiscount || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryTransferDiscount',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'finalAmount',
          label: getOperationSummaryLabel('finalAmount'),
          value: getOperationSummaryValue('finalAmount', {
            value: operationSummary?.finalAmount || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
            color: getFinalAmountColor(operationSummary?.finalAmount),
          }),
          dataCy: 'orderSummaryFinalAmount',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'availableRetention',
          label: getOperationSummaryLabel('availableRetention'),
          value: getOperationSummaryValue('availableRetention', {
            value: operationSummary?.availableRetention || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
            color: getAvailableRetentionColor(),
          }),
          dataCy: 'orderSummaryAvailableRetention',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'totalDebtAmount',
          label: getOperationSummaryLabel('totalDebtAmount'),
          value: getOperationSummaryValue('totalDebtAmount', {
            value: operationSummary?.totalDebtAmount || 0,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'orderSummaryTotalDebtAmount',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'transferDate',
          label: getOperationSummaryLabel('transferDate'),
          value: getOperationSummaryValue('transferDate', {
            value: transferDatePyme,
            variant: 'longdate',
            applyPrefixCurrency: false,
          }),
          dataCy: 'orderSummaryTransferDate',
          country: ['CL'],
          currency: ['CLP', 'MXN', 'USD'],
        },
      ];

      return summary.filter(
        sum =>
          sum.country.includes(country) &&
          sum.currency.includes(operationCurrency)
      );
    },
    [
      operationCurrency,
      country,
      transferDatePyme,
      getAvailableRetentionColor,
      getFinalAmountColor,
      getOperationSummaryLabel,
      getOperationSummaryValue,
    ]
  );

  const fundOperationSummaryData = useCallback(
    (fundOperationSummary: FundOperationSummaryData) => {
      const fundSummary: FundOperationResumeModel[] = [
        {
          key: 'totalAmount',
          label: getFundOperationSummaryLabel('totalAmount'),
          value: getFundOperationSummaryValue('totalAmount', {
            value: fundOperationSummary?.totalAmount,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'fundSummaryTotalAmount',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'advance',
          label: getFundOperationSummaryLabel('advance'),
          value: getFundOperationSummaryValue('advance', {
            value: fundOperationSummary?.advance,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'fundSummaryAdvance',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'totalRetention',
          label: getFundOperationSummaryLabel('totalRetention'),
          value: getFundOperationSummaryValue('totalRetention', {
            value: fundOperationSummary?.totalRetention,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'fundSummaryTotalRetention',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'avgAmountBaseRate',
          label: getFundOperationSummaryLabel('avgAmountBaseRate'),
          value: getFundOperationSummaryValue('avgAmountBaseRate', {
            value: fundOperationSummary?.avgAmountBaseRate,
            variant: 'percentage',
            applyPrefixCurrency: false,
          }),
          dataCy: 'fundSummaryAvgAmountBaseRate',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'interest',
          label: getFundOperationSummaryLabel('interest'),
          value: getFundOperationSummaryValue('interest', {
            value: fundOperationSummary?.interest,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'fundSummaryInterest',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'transferFund',
          label: getFundOperationSummaryLabel('transferFund'),
          value: getFundOperationSummaryValue('transferFund', {
            value: fundOperationSummary?.transferFund,
            variant: 'currency',
            operationCurrency: operationCurrency,
          }),
          dataCy: 'fundSummaryTransferFund',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
        {
          key: 'availableRetention',
          label: getFundOperationSummaryLabel('availableRetention'),
          value: getFundOperationSummaryValue('availableRetention', {
            value: fundOperationSummary?.availableRetention,
            variant: 'currency',
            operationCurrency: operationCurrency,
            color: fundOperationSummary?.availableRetention
              ? palette.success.main
              : palette.error.main,
          }),
          dataCy: 'fundSummaryAvailableRetention',
          country: ['CL', 'MX'],
          currency: ['CLP', 'MXN', 'USD'],
        },
      ];

      return fundSummary.filter(
        fundSum =>
          fundSum.country.includes(country) &&
          fundSum.currency.includes(operationCurrency)
      );
    },
    [operationCurrency, country]
  );

  useEffect(() => {
    if (orderSummary?.segment) {
      const getBusinessSegment =
        BUSINESS_SEGMENT_DICTIONARY[orderSummary?.segment as BusinessSegment] ||
        '';
      setBusinessSegment(getBusinessSegment);
    }
  }, [orderSummary]);

  useEffect(() => {
    if (discountWasUpdated || discountWasDeleted || discountWasCreated) {
      handleCloseDiscountDrawer();
      dispatch(fetchSelectedOrder(operation?.id));
      dispatch(fetchOperationSummary(operation?.id));
      dispatch(resetDiscount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountWasCreated, discountWasDeleted, discountWasUpdated]);

  useEffect(() => {
    if (orderSummaryWasLoaded && operation?.id) {
      dispatch(fetchOperationFundSummary(operation?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSummaryWasLoaded, operation?.id]);

  useEffect(() => {
    if (!isLoadingCurrency) {
      setOperationCurrency(currency || CURRENCY[country]);
    }
  }, [currency, isLoadingCurrency]);

  useEffect(() => {
    if (countryFeatures.orderActions.fetchTransferAmountPyme && operation?.id) {
      dispatch(getTransferAmountPyme(operation?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation?.id]);

  return {
    fetchOrderIsLoading,
    orderSummaryIsLoading,
    fundSummaryIsLoading,
    riskAnalysisUrl,
    availableNewSimulationDialog,
    performs,
    countryFeatures,
    orderSummary: orderSummary || {},
    fundSummary: fundSummary || {},
    showDiscountDrawer,
    showOperationSummary,
    showFundOperationSummary,
    operationResumeDictionary,
    fundOperationResumeDictionary,
    operationSummaryData,
    fundOperationSummaryData,
    toggleShowOperationSummary,
    toggleShowFundOperationSummary,
    handleCloseDiscountDrawer,
    handleShowDiscountDrawer,
  };
};

export default useOperationResume;
