import axios from 'axios';

interface Props {
  orderInvoices: number[];
  type: string;
  expirationDate: Date | undefined;
  feeAmount: number | undefined;
  startDate: Date | undefined;
  baseRate: number | undefined;
  justification: string;
  linkDocuments: string;
}

const bulkCreateExtensions = async ({
  orderInvoices,
  type,
  expirationDate,
  feeAmount,
  startDate,
  baseRate,
  justification,
  linkDocuments,
}: Props) => {
  try {
    const { data } = await axios.post('/api/collection/payers/extensions', {
      orderInvoices,
      newExpirationDate: expirationDate,
      firstFeeAmount: feeAmount,
      newBaseRate: baseRate,
      justification,
      linkDocuments,
      type,
      startDate,
    });
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export default bulkCreateExtensions;
