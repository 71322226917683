/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControlLabel,
  Button,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { CloseOutlined, Check, Colorize } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Switch from '@material-ui/core/Switch';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import {
  GRAY_COLOR,
  SECTIONS_ARRAY,
  STATUS_PERMISSION_ARRAY,
  GRAY_WHITE_COLOR,
  WHITE_PURE_COLOR,
  MANATEE_COLOR,
  STATUS_ORDER_TYPE,
} from '../../helpers/Constants';
import { ROLES_COLORS } from '../../theme/otherColors';
import capitalizeFirstLetter from '../../helpers/FontUtils';
import CollapsePanel from './CollapsePanel';
import BoxForm from '../form/Box';
import CheckboxValidator from './CheckboxValidator';
import StatusBadge from './StatusBadge';

const useStyles = makeStyles(theme => ({
  appBar: {
    background:
      'transparent linear-gradient(270deg, #1A32E7 0%, #6394F0 100%) 0% 0% no-repeat padding-box;',
  },
  bodyContainer: {
    display: 'grid',
    width: 800,
    gridTemplateColumns: '200px auto',
    gridTemplateRows: 'calc(100% - 64px - 120px) 120px',
    margin: '0 auto',
  },
  paddedContainer: {
    padding: '25px 25px 0 25px',
  },
  roleList: {
    gridRow: '1 / 2',
    gridColumn: '1 / 2',
  },
  form: {
    height: '100%',
  },
  subtitle: {
    marginBottom: 10,
  },
  justifyRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridRole: {
    top: '200px',
    left: '320px',
    width: '168px',
    height: '33px',
  },
  gridRoles: {
    maxHeight: '500px',
    width: '100%',
    overflow: 'auto',
  },
  gridCollapsable: {
    marginTop: 30,
    width: 500,
  },
  boxIcon: {
    color: WHITE_PURE_COLOR,
    marginTop: 2,
    marginLeft: 2,
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  scrollable: {
    overflow: 'auto',
    maxHeight: '709px',
    width: '100%',
    padding: 10,
  },
  notScrollable: { overflow: 'hidden', position: 'fixed' },
  listSection: { height: 500, width: 500 },
  buttonFloat: { position: 'relative', top: 'calc(100% - 76px)', left: 10 },
  hasSupervisor: {
    textAlign: 'left',
    letterSpacing: 0,
    color: MANATEE_COLOR,
    opacity: 1,
  },
  tabBar: {
    background: WHITE_PURE_COLOR,
    color: MANATEE_COLOR,
    '& .Mui-selected': {
      color: GRAY_COLOR,
    },
    borderRadius: 8,
    marginTop: 20,
    width: '500px',
    border: `1px solid ${GRAY_WHITE_COLOR}`,
  },
  gridStatus: {
    marginRight: 10,
  },
  displayInLineBlock: {
    display: 'inline-block',
  },
  labelSwitchActive: {
    color: theme.palette.primary.main,
  },
}));

const RolesSection = ({
  handleCloseDrawer,
  handleSelectRole,
  handleSubmitRole,
  handleDeleteRole,
  selectedRole,
}) => {
  const classes = useStyles();
  const {
    performs: performsSelectedRole = [],
    rolename: rolenameSelectedRole,
    statusPermissions: statusSelectedRole = [],
    hasSupervisor: hasSupervisorSelectedRole = false,
    color: colorSelectedRole,
  } = selectedRole || {};
  const roles = useSelector(state => state.user.roles);
  const performs = useSelector(state => state.user.performs);
  const [rolePerforms, setRolePeforms] = useState(performsSelectedRole);
  const [statusPermissions, setStatusPermissions] =
    useState(statusSelectedRole);
  const [rolename, setRolename] = useState(rolenameSelectedRole);
  const [roleColor, setRoleColor] = useState(colorSelectedRole);
  const [permissionType, setPermissionType] = useState('perform');
  const [hasSupervisor, setHasSupervisor] = useState(hasSupervisorSelectedRole);
  const [isValid, setIsValid] = useState(!!selectedRole);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorCustom, setColorCustom] = useState();
  const handleValidForm = () => {
    let valid = true;
    if (
      !rolename ||
      !roleColor ||
      ((!rolePerforms.length || !statusPermissions.length) && !selectedRole)
    ) {
      valid = !valid;
    }
    setIsValid(valid);
  };

  const setDefaultStatusPermission = () => {
    if (!statusPermissions?.length) {
      const statusDefault = STATUS_PERMISSION_ARRAY.ORDER.statuses.map(
        status => ({
          status,
          actionType: 'VIEW',
          statusType: 'ORDER',
        })
      );
      setStatusPermissions(statusDefault);
    }
  };

  useEffect(() => {
    handleValidForm();
    setDefaultStatusPermission();
    // eslint-disable-next-line
  }, [handleValidForm]);

  const handleSubmit = () => {
    let data = {
      rolename,
      color: roleColor,
      performs: rolePerforms,
      statusPermissions,
      hasSupervisor,
    };
    if (selectedRole) {
      const { performs: oldPerforms, statusPermissions: oldStatusPermissions } =
        selectedRole;
      data = { ...selectedRole, ...data, oldPerforms, oldStatusPermissions };
    }
    handleSubmitRole(data);
  };

  const handleChangeName = event => {
    const {
      target: { value },
    } = event;
    setRolename(value);
    handleValidForm();
  };

  const handleHasSupervisor = event => {
    const {
      target: { checked },
    } = event;
    setHasSupervisor(checked);
    handleValidForm();
  };

  const handleSelected = role => {
    const {
      rolename: name = '',
      color,
      hasSupervisor: hasSupervisorRole = false,
      performs = [],
      statusPermissions = [],
    } = role || {};
    setRolename(name);
    setRoleColor(color);
    setHasSupervisor(hasSupervisorRole);
    setRolePeforms(performs);
    setStatusPermissions(statusPermissions);
    handleSelectRole(role);
  };

  const handlePerformSelected = perform => {
    const { id: performId } = perform;
    const isAlreadySelected = rolePerforms.some(({ id }) => performId === id);
    let performsSelected = [...rolePerforms];
    if (isAlreadySelected) {
      performsSelected = rolePerforms.filter(({ id }) => performId !== id);
    } else {
      performsSelected.push(perform);
    }
    setRolePeforms(performsSelected);
    handleValidForm();
  };

  const handleStatusPermission = (status, action, type) => {
    const isAlreadySelected = statusPermissions.some(
      ({ status: statusPermission, actionType, statusType }) =>
        statusPermission === status &&
        actionType === action &&
        statusType === type
    );
    let statusesSelected = [...statusPermissions];
    if (isAlreadySelected) {
      statusesSelected = statusPermissions.filter(
        ({ status: statusPermission, actionType, statusType }) =>
          !(
            statusPermission === status &&
            actionType === action &&
            statusType === type
          )
      );
    } else {
      statusesSelected.push({
        status,
        actionType: action,
        statusType: type,
      });
    }
    setStatusPermissions(statusesSelected);
    handleValidForm();
  };

  const handleChangeColorCustom = color => {
    setRoleColor(color?.hex);
    setColorCustom(color?.hex);
    handleValidForm();
  };
  const styleInputName = { width: '357px' };
  let bodyOptions = [
    {
      label: 'Nombre del rol',
      data: (
        <TextValidator
          variant="outlined"
          type="string"
          name="rolename"
          value={rolename}
          style={styleInputName}
          onChange={handleChangeName}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />
      ),
    },
    {
      label: 'Color del rol',
      data: (
        <Grid container direction="row">
          {ROLES_COLORS.map((color, index) => (
            <Grid item xs={1}>
              {!index ? (
                <>
                  <IconButton onClick={() => setShowColorPicker(true)}>
                    <BoxForm
                      color={colorCustom}
                      icon={<Colorize className={classes.boxIcon} />}
                    />
                  </IconButton>
                  {showColorPicker && (
                    <div className={classes.popover}>
                      <div
                        className={classes.cover}
                        onClick={() => setShowColorPicker(false)}
                      />
                      <SketchPicker
                        color={colorCustom}
                        onChangeComplete={handleChangeColorCustom}
                        onClose={() => setShowColorPicker(true)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <IconButton onClick={() => setRoleColor(color)}>
                  <BoxForm
                    color={color}
                    icon={
                      roleColor === color ? (
                        <Check className={classes.boxIcon} />
                      ) : null
                    }
                  />
                </IconButton>
              )}
            </Grid>
          ))}
        </Grid>
      ),
      type: 'selectorColor',
    },
    {
      label: 'Supervisor',
      data: (
        <CheckboxValidator
          checked={hasSupervisor}
          color="primary"
          onChange={handleHasSupervisor}
          label="Activo"
        />
      ),
    },
  ];

  const handleTabChange = (e, activeTab) => {
    setPermissionType(activeTab);
  };
  const handleDelete = () => {
    setRolename('');
    handleDeleteRole();
  };
  bodyOptions = bodyOptions.filter(option =>
    selectedRole ? option.label !== 'Supervisor' : true
  );
  const styleCardRole = {
    background: WHITE_PURE_COLOR,
    border: `1px solid ${GRAY_WHITE_COLOR}`,
  };
  return (
    <>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Gestión de roles
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() => handleCloseDrawer()}
              >
                <CloseOutlined />{' '}
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container direction="row" className={classes.bodyContainer}>
          <Grid item className={classes.roleList}>
            <Grid container direction="row">
              <Grid item xs={12} className={classes.gridRole}>
                <Button
                  onClick={() => handleSelected(null)}
                  variant="text"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  Nuevo rol
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" className={classes.gridRoles}>
                  {roles.map(role => {
                    const { color, isSupervisor, rolename } = role;
                    let name = rolename;
                    if (isSupervisor) {
                      name = `Superv. ${rolename}`;
                    }
                    const isSelected =
                      selectedRole?.rolename === rolename &&
                      selectedRole?.isSupervisor === isSupervisor;
                    const style = {
                      borderRight: isSelected ? '3px solid orange' : null,
                      background: isSelected ? GRAY_WHITE_COLOR : null,
                    };
                    return (
                      <Grid item xs={12} className={classes.gridRole}>
                        <Button
                          onClick={() => handleSelected(role)}
                          style={style}
                          variant="text"
                        >
                          <Typography
                            variant="body1"
                            style={{ color }}
                            component="div"
                          >
                            <Box fontWeight="fontWeightBold">
                              {capitalizeFirstLetter(name)}
                            </Box>
                          </Typography>
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" className={classes.scrollable}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={classes.subtitle}
                  component="div"
                >
                  <Box fontWeight="fontWeightBold">Editar rol</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="textPrimary">
                  Usa los roles para organizar a los usuarios y personalizar sus
                  permisos.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  className={classes.gridCollapsable}
                >
                  <CollapsePanel
                    isEditable
                    expanded
                    style={styleCardRole}
                    body={bodyOptions}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AppBar
                  position="static"
                  className={classes.tabBar}
                  elevation={0}
                >
                  <Tabs
                    value={permissionType}
                    onChange={(e, v) => handleTabChange(e, v)}
                    indicatorColor="primary"
                  >
                    <Tab label="Acciones" value="perform" />
                    <Tab label="Estados" value="status" />
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid container direction="row" className={classes.listSection}>
                {permissionType === 'perform' &&
                  performs &&
                  Object.entries(performs).length &&
                  SECTIONS_ARRAY.map(({ key, label }) => {
                    let performSection;
                    const performOptions = performs[key]?.map(perform => {
                      const { id, description, perform: action } = perform;
                      const regex = new RegExp(`${key}:section`, 'gi');
                      if (action.match(regex)) {
                        performSection = perform;
                      }
                      const switchActive = rolePerforms.some(
                        ({ id: performRoleId }) => performRoleId === id
                      );
                      return {
                        label: description,
                        data: (
                          <FormControlLabel
                            classes={{
                              label: switchActive
                                ? classes.labelSwitchActive
                                : null,
                            }}
                            label="Activo"
                            control={
                              <Switch
                                color="primary"
                                checked={switchActive}
                                onChange={() => handlePerformSelected(perform)}
                              />
                            }
                          />
                        ),
                      };
                    });
                    const toogleSection =
                      !['orders', 'invoices'].includes(key) &&
                      performSection ? (
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={rolePerforms.some(
                                performRole =>
                                  performRole.id === performSection.id
                              )}
                              onChange={() =>
                                handlePerformSelected(performSection)
                              }
                            />
                          }
                        />
                      ) : null;
                    const title = (
                      <Grid item xs={12}>
                        <Grid container direction="row">
                          <Grid item xs={10}>
                            <Typography color="textSecondary" variant="body2">
                              {label}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {toogleSection}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                    return (
                      <Grid item xs={12} className={classes.gridCollapsable}>
                        <CollapsePanel
                          title={title}
                          direction="row"
                          isSwitch
                          style={styleCardRole}
                          body={performOptions}
                        />
                      </Grid>
                    );
                  })}
                {permissionType === 'status' && (
                  <>
                    {Object.entries(STATUS_PERMISSION_ARRAY).map(
                      STATUS_PERMISSION => {
                        const [statusType] = STATUS_PERMISSION;
                        const { label: section, statuses } =
                          STATUS_PERMISSION[1];
                        const title = (
                          <Grid item xs={12}>
                            <Grid container direction="row">
                              <Grid item xs={10}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`Estados en ${section}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                        const switchWidth =
                          statusType === STATUS_ORDER_TYPE ? 4 : 2;
                        const labelWidth =
                          statusType === STATUS_ORDER_TYPE ? 8 : 10;
                        const headStatusOption = {
                          label: '',
                          type: 'status',
                          switchWidth,
                          labelWidth,
                          data: (
                            <Grid item xs={12}>
                              <Grid container direction="row">
                                {statusType === STATUS_ORDER_TYPE ? (
                                  <Grid item xs={6}>
                                    <Typography
                                      display="inline"
                                      variant="body1"
                                      color="textSecondary"
                                      component="div"
                                    >
                                      <Box
                                        className={classes.displayInLineBlock}
                                        fontWeight="fontWeightBold"
                                      >
                                        Ver
                                      </Box>
                                    </Typography>
                                  </Grid>
                                ) : null}

                                <Grid
                                  item
                                  xs={statusType === STATUS_ORDER_TYPE ? 6 : 12}
                                >
                                  <Typography
                                    display="inline"
                                    variant="body1"
                                    color="textSecondary"
                                    component="div"
                                  >
                                    <Box
                                      className={classes.displayInLineBlock}
                                      fontWeight="fontWeightBold"
                                    >
                                      Cambiar
                                    </Box>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ),
                        };
                        let statusOptions = statuses.map(status => {
                          const checkedChange = statusPermissions.some(
                            ({
                              actionType,
                              statusType: typePermission,
                              status: statusPermission,
                            }) =>
                              actionType === 'CHANGE' &&
                              typePermission === statusType &&
                              statusPermission === status
                          );
                          const checkedView =
                            statusPermissions.some(
                              ({
                                actionType,
                                statusType: typePermission,
                                status: statusPermission,
                              }) =>
                                actionType === 'VIEW' &&
                                typePermission === statusType &&
                                statusPermission === status
                            ) || !selectedRole;
                          const formControlChange = (
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={checkedChange}
                                  onChange={() =>
                                    handleStatusPermission(
                                      status,
                                      'CHANGE',
                                      statusType
                                    )
                                  }
                                />
                              }
                            />
                          );
                          const formControlView = (
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={checkedView}
                                  onChange={() =>
                                    handleStatusPermission(
                                      status,
                                      'VIEW',
                                      statusType
                                    )
                                  }
                                />
                              }
                            />
                          );
                          return {
                            label: (
                              <StatusBadge chipWidth={120} status={status} />
                            ),
                            type: 'status',
                            switchWidth,
                            labelWidth,
                            data:
                              statusType === STATUS_ORDER_TYPE ? (
                                <Grid item xs={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={6}>
                                      {formControlView}
                                    </Grid>
                                    <Grid item xs={6}>
                                      {formControlChange}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                formControlChange
                              ),
                          };
                        });
                        statusOptions = [headStatusOption, ...statusOptions];
                        return (
                          <Grid
                            item
                            xs={12}
                            className={classes.gridCollapsable}
                          >
                            <CollapsePanel
                              title={title}
                              isSwitch
                              direction="row"
                              style={styleCardRole}
                              body={statusOptions}
                            />
                          </Grid>
                        );
                      }
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!selectedRole}
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

RolesSection.defaultProps = {
  selectedRole: null,
};

RolesSection.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
  handleSelectRole: PropTypes.func.isRequired,
  handleSubmitRole: PropTypes.func.isRequired,
  handleDeleteRole: PropTypes.func.isRequired,
  selectedRole: PropTypes,
};

export default RolesSection;
