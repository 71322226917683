import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChevronDown = props => {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7705 1.89626L10.478 0.60376L6.27051 4.80209L2.06301 0.603759L0.770508 1.89626L6.27051 7.39626L11.7705 1.89626Z"
        // eslint-disable-next-line react/prop-types
        fill={props.fill ?? '#8D8A96'}
      />
    </SvgIcon>
  );
};

export default ChevronDown;
