import { Role, RoleInput } from '../schemas';

export const isRoleAdmin = (roleInput: RoleInput | RoleInput[]) => {
  if (Array.isArray(roleInput)) {
    return roleInput.some(item => item.id === Role.adminId);
  }

  return roleInput.id === Role.adminId;
};

export const isRoleRisk = (role: RoleInput) => role.id === Role.risk;
export const isRoleCommercial = (role: RoleInput) => role.id === Role.commercial;
export const isRolePymesClKamId = (role: RoleInput) => role.id === Role.pymesClKamId;
export const isRolePymesClLeaderId = (role: RoleInput) => role.id === Role.pymesClLeaderId;
export const isRolePymesClManagement = (role: RoleInput) => role.id === Role.pymesClManagement;
export const isRoleCorpsClKam = (role: RoleInput) => role.id === Role.corpsClKam;
export const isRoleCorpsClSalesOps = (role: RoleInput) => role.id === Role.corpsClSalesOps;
export const isRolecorpsClLeader = (role: RoleInput) => role.id === Role.corpsClLeader;
export const isRoleRevenueMxKamEnrollment = (role: RoleInput) => role.id === Role.revenueMxKamEnrollment;
export const isRoleRevenueMxKamActivation = (role: RoleInput) => role.id === Role.revenueMxKamActivation;
export const isRoleRevenueMxKamRecurrence = (role: RoleInput) => role.id === Role.revenueMxKamRecurrence;
export const isRoleRevenueMxLeader = (role: RoleInput) => role.id === Role.revenueMxLeader;
export const isRoleRevenueMxManagement = (role: RoleInput) => role.id === Role.revenueMxManagement;
export const isRoleRiskManager = (role: RoleInput) => role.id === Role.riskManager;
export const isRoleLegal = (role: RoleInput) => role.id === Role.legal;
export const isRoleOperation = (role: RoleInput) => role.id === Role.operation;
export const isRoleOperationManager = (role: RoleInput) => role.id === Role.operationManager;
export const isRoleOperationMx = (role: RoleInput) => role.id === Role.operationMx;

export const hasRoleKam = (roles: RoleInput[] = []) => {
  const isKam = (role: RoleInput) => (
    isRoleCommercial(role)
    || isRolePymesClKamId(role) || isRolePymesClLeaderId(role)
    || isRolePymesClManagement(role) || isRoleCorpsClKam(role)
    || isRoleCorpsClSalesOps(role) || isRolecorpsClLeader(role)
    || isRoleRevenueMxKamEnrollment(role) || isRoleRevenueMxKamActivation(role)
    || isRoleRevenueMxKamRecurrence(role) || isRoleRevenueMxLeader(role)
    || isRoleRevenueMxManagement(role)
  );

  return roles.some(isKam);
};

export const hasRoleRisk = (roles: RoleInput[] = []) => {
  const isRisk = (role: RoleInput) => isRoleRisk(role) || isRoleRiskManager(role);

  return roles.some(isRisk);
};

export const hasRoleLegal = (roles: RoleInput[] = []) => roles.some(role => isRoleLegal(role));

export const hasRoleOperation = (roles: RoleInput[] = []) => {
  const isOperation = (role: RoleInput) => (
    isRoleOperation(role)
    || isRoleOperationManager(role)
    || isRoleOperationMx(role)
  );

  return roles.some(isOperation);
};
