/* eslint-disable react/prop-types */
import { Grid, Tooltip, IconButton, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { DECIMAL_PRECISION } from '../../../../../../helpers/Constants';

import loadingIcon from '../../../../../../assets/loading.gif';

import { TogglePanel } from '../../../../../../components/icons';

import LoadingButton from '../../../../../../components/elements/LoadingButton';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import DataList from '../../../../../../components/elements/DataList';
import useStyles from './summaryStyles';
import { useNewSimulation } from '../../../../infrastructure/store';
import { Text } from '../../../../../commons/formatters';

const InvoiceOperationSummary = ({
  order,
  invoicesData,
  orderSummary,
  orderSummaryIsLoading,
  manageSelectedInvoices,
  buttonText,
  buttonDisabled,
  buttonIcon,
  buttonIsLoading,
  showHint,
  action,
}) => {
  const { newSimulation, orderSimulation, orderSimulationIsLoading } =
    useNewSimulation();
  const { id: orderId } = order;
  const classes = useStyles();
  const country = useSelector(state => state.config.country);
  const [orderResume, setOperationResume] = useState([]);
  const [operationSimulation, setOperationSimulation] = useState([]);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationOrderId, setSimulationOrderId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);

  const item = {
    title: 'Comparar',
    data: orderResume,
    type: 'operation',
  };

  const orderAttributes = (simulation = false) => {
    return [
      {
        label: 'Monto total facturas',
        type: 'currency',
        data: simulation
          ? orderSimulation?.totalAmount
          : orderSummary?.operation?.totalInvoicesAmount,
      },
      {
        label: 'Total a pagar',
        type: 'currency',
        data: simulation
          ? orderSimulation?.advance
          : orderSummary?.operation?.totalInvoicesAmount +
            orderSummary?.operation?.interest +
            orderSummary?.operation?.operationCost,
      },
      {
        label: 'Tasa base',
        type: 'percentage',
        data: simulation
          ? orderSimulation?.baseRate.toFixed(DECIMAL_PRECISION)
          : orderSummary?.operation?.baseRate.toFixed(DECIMAL_PRECISION),
      },
      {
        label: 'Total intereses a pagar',
        type: 'currency',
        data: simulation
          ? orderSimulation?.interest
          : orderSummary?.operation?.interest,
      },
      {
        label: 'Asesoría Xepelin',
        type: 'currency',
        data: simulation
          ? orderSimulation?.operationCost
          : orderSummary?.operation?.operationCost,
      },
      {
        label: 'Descuentos a facturas',
        type: 'currency',
        data: simulation
          ? orderSimulation?.totalDiscounts
          : orderSummary?.operation?.transferDiscount,
      },
    ];
  };

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const simulate = () => {
    const { invoices } = invoicesData;
    setLoadingSimulation(true);
    setOperationSimulation([]);
    setSimulationData({ ...invoices });
    setSimulationOrderId(orderId);
    const body =
      action === 'move'
        ? { extraOrderInvoices: invoices }
        : { extraInvoices: invoices };
    newSimulation({ orderId, body });
  };

  const checkSimulation = () => {
    if (orderId !== simulationOrderId) {
      return false;
    }
    const { invoices: invoicesToCompare } = invoicesData;
    let simulationDataToCompare;
    if (typeof simulationData === 'object' && !Array.isArray(simulationData)) {
      simulationDataToCompare = [
        ...Object.keys(simulationData).reduce((acc, key) => {
          acc.push(simulationData[key]);
          return [...acc];
        }, []),
      ];
    } else {
      simulationDataToCompare = [...simulationData];
    }

    if (simulationDataToCompare.length === invoicesToCompare.length) {
      return simulationDataToCompare.every(
        ({ id, baseRate, expirationDate }) => {
          const foundInvoice = invoicesToCompare.find(i => i.id === id);
          if (!foundInvoice) return false;
          return (
            foundInvoice.baseRate === baseRate &&
            foundInvoice.expirationDate === expirationDate
          );
        }
      );
    }
    return false;
  };

  useEffect(() => {
    if (!orderSummary) return;
    setOperationResume(orderAttributes());

    // eslint-disable-next-line
  }, [orderSummary, orderSummaryIsLoading]);

  useEffect(() => {
    if (!orderSimulation) return;
    setOperationSimulation(orderAttributes(true));
    setLoadingSimulation(false);
    // eslint-disable-next-line
  }, [orderSimulation, orderSimulationIsLoading]);

  const { data, title } = item;
  const { invoicesAmount, invoicesSize } = invoicesData;

  const sideBarContent = () => {
    if (invoicesSize === 0) return '';

    return !orderSimulationIsLoading &&
      operationSimulation.length &&
      checkSimulation() ? (
      <>
        <Grid item className={classes.relativeContainer}>
          <Grid
            container
            justify="space-between"
            align="center"
            className={classes.marginBottom20}
          >
            <Grid item container justify="flex-end">
              <Text variant="body1" color="textSecondary" align="right" noWrap>
                {title}
              </Text>
              <Tooltip title={<Text variant="body1">Comparar con antes</Text>}>
                <IconButton
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={togglePanel}
                  className={classes.togglePanel}
                >
                  <TogglePanel
                    className={
                      isOpen ? classes.toggleOpen : classes.toggleClose
                    }
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <DataList wide data={operationSimulation} justify="flex-end" />
          </Grid>
          {isOpen ? (
            <Grid container className={classes.sideResumeContainer}>
              <Text
                variant="body1"
                align="left"
                noWrap
                className={classes.marginBottom33}
              >
                Antes
              </Text>
              <DataList
                wide
                displayInDialog
                data={data}
                justify="flex-start"
                showLabel={false}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} className={classes.containerSimulation}>
          <LoadingButton
            onClick={() => manageSelectedInvoices(order?.id)}
            startIcon={buttonIcon}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            isLoading={buttonIsLoading}
          >
            {buttonText}
          </LoadingButton>
        </Grid>
      </>
    ) : (
      <>
        {showHint && (
          <Text
            variant="body2"
            align="center"
            className={classes.marginBottom20}
          >
            *Antes de simular recuerda revisar y editar las tasas de ser
            necesario.
          </Text>
        )}
        <Button
          onClick={simulate}
          variant="contained"
          color="primary"
          disabled={loadingSimulation}
        >
          Simular
        </Button>
      </>
    );
  };

  return (
    <Grid container className={classes.resumeContainer} justify="center">
      <Grid item align="center" xs={12}>
        <Text
          variant="body1"
          align="center"
          component="div"
          fontWeight="fontWeightBold"
        >
          Resumen operación
        </Text>
      </Grid>
      <Grid
        item
        align="center"
        className={clsx(
          classes.invoiceAmountContainer,
          !invoicesSize > 0 && classes.invoiceAmountContainerEmpty
        )}
      >
        <Text variant="body2" noWrap color="white">
          {invoicesSize > 0
            ? `Facturas a mover (${invoicesSize})`
            : 'Seleccione una factura'}
        </Text>
        <Text
          variant="h6"
          noWrap
          component="div"
          fontWeight="fontWeightBold"
          color="white"
        >
          <CountryFormatHelper
            value={invoicesAmount}
            variant="currency"
            countryId={country}
          />
        </Text>
      </Grid>
      {!orderSummaryIsLoading ? (
        sideBarContent()
      ) : (
        <Grid container justify="center">
          <img src={loadingIcon} alt="loader" />
        </Grid>
      )}
    </Grid>
  );
};

InvoiceOperationSummary.defaultProps = {
  buttonDisabled: false,
  orderSummary: {},
  showHint: false,
  buttonIsLoading: false,
};

InvoiceOperationSummary.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    orderInvoices: PropTypes.arrayOf(
      PropTypes.shape({
        SiiFactura: PropTypes.shape({
          SiiNotasCredito: PropTypes.shape({
            detMntTotal: PropTypes.number.isRequired,
          }),
        }),
      })
    ),
  }).isRequired,
  orderSummary: PropTypes.shape({
    totalAmount: PropTypes.number,
    advancePercentage: PropTypes.number,
    advance: PropTypes.number,
    retention: PropTypes.number,
    baseRate: PropTypes.number,
    interest: PropTypes.number,
    operationCost: PropTypes.number,
    transferDiscount: PropTypes.number,
    finalAmount: PropTypes.number,
    transfer: PropTypes.number,
    totalDiscounts: PropTypes.number,
    transferDate: PropTypes.number,
    availableRetention: PropTypes.number,
    operation: PropTypes.shape({
      totalAmount: PropTypes.number,
      advancePercentage: PropTypes.number,
      advance: PropTypes.number,
      retention: PropTypes.number,
      baseRate: PropTypes.number,
      interest: PropTypes.number,
      operationCost: PropTypes.number,
      transferDiscount: PropTypes.number,
      finalAmount: PropTypes.number,
      transfer: PropTypes.number,
      totalDiscounts: PropTypes.number,
      transferDate: PropTypes.number,
      availableRetention: PropTypes.number,
    }),
  }),
  orderSummaryIsLoading: PropTypes.bool.isRequired,
  invoicesData: PropTypes.shape({
    invoicesSize: PropTypes.number.isRequired,
    invoicesAmount: PropTypes.number.isRequired,
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        baseRate: PropTypes.number,
        expirationDate: PropTypes.string,
      })
    ),
  }).isRequired,
  manageSelectedInvoices: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
  buttonIcon: PropTypes.element.isRequired,
  showHint: PropTypes.bool,
  action: PropTypes.oneOf(['add', 'move']).isRequired,
  buttonIsLoading: PropTypes.bool,
};

export default InvoiceOperationSummary;
