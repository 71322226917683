import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams } from 'react-router-dom';

import { t13s } from '../../../../../translationKeys';
import { useFormSummary } from '../../../hooks/useFormSummary';
import { Select } from '../../../../../components/elements/Select';
import { useStyles } from './styles';
import {
  businessStatusOpts,
  discountOpts,
  invoiceStatusOpts,
  operationsOpts,
} from '../constants';
import {
  MAX_RANGE,
  BO_STATUS_FILTER_OPTIONS,
  BUSINESS_STATUS,
} from '../../../constants/main';
import { FormSummary } from '../../FormSummary';
import { BarFilter } from '../../BarFilter';
import { OPERATION_TYPE } from '../../../constants/operations';
import { useFetchAssignees } from '../../../../opportunities/domains/document/services';

const InvoiceFormComponent = ({ fieldsToHide, form, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { identifier: receiverIdentifier } = useParams();
  const { formState, handleSetFormState, handleSubmit, summary, resetForm } =
    useFormSummary({ onSubmit, form });
  const [validForm, setValidForm] = useState(true);
  const appliedDiscount = discountOpts.find(d => d.value === summary.discount);
  const { data: response } = useFetchAssignees(
    { receiverIdentifier },
    { refetchOnMount: false }
  );
  const assignees = response?.data?.assignees || [];
  const summaryAssignee = summary.assignee;
  const mappedSummary = {
    ...summary,
    status: BO_STATUS_FILTER_OPTIONS[summary.status]?.label,
    businessStatus: BUSINESS_STATUS[summary.businessStatus]?.label,
    operationType: OPERATION_TYPE[summary.operationType]?.label,
    discount: appliedDiscount?.label,
    assignee:
      summaryAssignee?.name && summaryAssignee?.identifier
        ? `${summaryAssignee?.name} (${summaryAssignee?.identifier})`
        : summaryAssignee?.name ?? summaryAssignee?.identifier,
  };

  const getOptionLabel = ({ name, identifier }) => {
    if (name && identifier) {
      return `${name} (${identifier})`;
    }
    return name ?? identifier;
  };

  const renderOption = option => <>{getOptionLabel(option)}</>;

  const validatorFormRef = useRef();
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    validatorFormRef.current
      ?.isFormValid(true)
      .then(isValid => setValidForm(isValid));
  }, [formState]);

  return (
    <>
      <BarFilter handleCloseDrawer={onClose} />

      <Grid container className={classes.container}>
        <FormSummary summary={mappedSummary} resetForm={resetForm} />
        <Grid item xs={12}>
          <Box
            alignItems="flex-start"
            className={classes.w100}
            display="flex"
            flexDirection="column"
          >
            <ValidatorForm
              className={classes.w100}
              onSubmit={handleSubmit}
              ref={validatorFormRef}
            >
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Emisor</Box>
              </Typography>
              <TextValidator
                className={classes.w100}
                label="Nombre"
                name="issuerName"
                onChange={handleSetFormState}
                type="text"
                value={formState.issuerName}
                variant="outlined"
              />
              <TextValidator
                className={classes.w100}
                label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
                name="issuerId"
                onChange={handleSetFormState}
                type="text"
                value={formState.issuerId}
                variant="outlined"
              />
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Factura</Box>
              </Typography>
              <TextValidator
                className={classes.w100}
                label="Folio"
                name="folio"
                onChange={handleSetFormState}
                type="text"
                value={formState.folio}
                variant="outlined"
              />
              <Select
                label="Estado"
                value={formState.status}
                name="status"
                onChange={handleSetFormState}
                options={invoiceStatusOpts}
              />
              {!fieldsToHide.businessStatus && (
                <Select
                  label="Estado negocio"
                  value={formState.businessStatus}
                  name="businessStatus"
                  onChange={handleSetFormState}
                  options={businessStatusOpts}
                />
              )}
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Monto bruto</Box>
              </Typography>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 1 }}
                    label="$ Mínimo"
                    name="minAmount"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.minAmount}
                    variant="outlined"
                    validators={[
                      'minNumber:1',
                      `maxNumber:${formState.maxAmount || MAX_RANGE}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 1',
                      'No puede ser mayor al Max.',
                    ]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.hyphen} />
                </Grid>
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 1 }}
                    label="$ Máximo"
                    name="maxAmount"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.maxAmount}
                    variant="outlined"
                    validators={[
                      'minNumber:1',
                      `minNumber:${formState.minAmount || 1}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 1',
                      'No puede ser menor al Min.',
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Operación</Box>
              </Typography>
              <Select
                label="Tipo operación"
                value={formState.operationType}
                name="operationType"
                onChange={handleSetFormState}
                options={operationsOpts}
              />
              {!fieldsToHide.orderIds && (
                <TextValidator
                  className={classes.w100}
                  label="Order ID"
                  name="orderIds"
                  placeholder="1234, 4567, 8910"
                  onChange={e => {
                    const { name, value } = e.target;
                    handleSetFormState({
                      ...e,
                      target: {
                        name,
                        value: value
                          ? value
                              ?.replace(/\s/g, '')
                              .replace(/,,/g, ',')
                              .split(',')
                          : undefined,
                      },
                    });
                  }}
                  type="text"
                  value={
                    (formState.orderIds?.join(', ').endsWith(' ')
                      ? formState.orderIds?.join(', ').slice(0, -1)
                      : formState.orderIds?.join(', ')) || ''
                  }
                  validators={['matchRegexp:^[0-9]+$|^[0-9][0-9 ,]+$']}
                  errorMessages={[
                    'Los IDs deben ser numéricos y separarse por comas',
                  ]}
                  variant="outlined"
                />
              )}

              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Tasa Originación</Box>
              </Typography>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="% Mínimo"
                    name="minOriginationRate"
                    onChange={handleSetFormState}
                    type="number"
                    validators={[
                      'minNumber:0',
                      `maxNumber:${formState.maxOriginationRate || 9999}`,
                    ]}
                    value={formState.minOriginationRate}
                    variant="outlined"
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser mayor al Max.',
                    ]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.hyphen} />
                </Grid>
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="% Máximo"
                    name="maxOriginationRate"
                    onChange={handleSetFormState}
                    type="number"
                    validators={[
                      'minNumber:0',
                      `minNumber:${formState.minOriginationRate || 0}`,
                    ]}
                    value={formState.maxOriginationRate}
                    variant="outlined"
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser menor al Min.',
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Tasa Fondo</Box>
              </Typography>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="% Mínimo"
                    name="minFundRate"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.minFundRate}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `maxNumber:${formState.maxFundRate || MAX_RANGE}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser mayor al Max.',
                    ]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.hyphen} />
                </Grid>
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="% Máximo"
                    name="maxFundRate"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.maxFundRate}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `minNumber:${formState.minFundRate || 0}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser menor al Min.',
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Días emisión</Box>
              </Typography>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="Mínimo"
                    name="minDaysOfIssue"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.minDaysOfIssue}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `maxNumber:${formState.maxDaysOfIssue || MAX_RANGE}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser mayor al Max.',
                    ]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.hyphen} />
                </Grid>
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="Máximo"
                    name="maxDaysOfIssue"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.maxDaysOfIssue}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `minNumber:${formState.minDaysOfIssue || 0}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser menor al Min.',
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Typography
                variant="body1"
                color="textPrimary"
                component="div"
                className={classes.titleSection}
              >
                <Box fontWeight="fontWeightBold">Plazo</Box>
              </Typography>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="Mínimo"
                    name="minTerm"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.minTerm}
                    variant="outlined"
                    validators={[
                      'minNumber:0',
                      `maxNumber:${formState.maxTerm || MAX_RANGE}`,
                    ]}
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser mayor al Max.',
                    ]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.hyphen} />
                </Grid>
                <Grid item className={classes.inputBox} xs={5}>
                  <TextValidator
                    className={classes.w100}
                    inputProps={{ min: 0 }}
                    label="Máximo"
                    name="maxTerm"
                    onChange={handleSetFormState}
                    type="number"
                    value={formState.maxTerm}
                    variant="outlined"
                    errorMessages={[
                      'No puede ser menor a 0',
                      'No puede ser menor al Min.',
                    ]}
                    validators={[
                      'minNumber:0',
                      `minNumber:${formState.minTerm || 0}`,
                    ]}
                  />
                </Grid>
              </Grid>
              {!fieldsToHide.discount && (
                <>
                  <Divider className={classes.divider} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={classes.titleSection}
                  >
                    <Box fontWeight="fontWeightBold">Descuento</Box>
                  </Typography>
                  <Select
                    label="Descuento"
                    value={formState.discount}
                    name="discount"
                    onChange={handleSetFormState}
                    options={discountOpts}
                  />
                </>
              )}
              {!fieldsToHide.assignee && (
                <>
                  <Divider className={classes.divider} />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={classes.titleSection}
                  >
                    <Box fontWeight="fontWeightBold">Cesionario</Box>
                  </Typography>
                  <Autocomplete
                    selectOnFocus
                    value={formState.assignee}
                    options={assignees}
                    onChange={(_, assignee) =>
                      handleSetFormState({
                        target: {
                          name: 'assignee',
                          value: assignee,
                        },
                      })
                    }
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Rut o nombre cesionario"
                        variant="outlined"
                      />
                    )}
                  />
                </>
              )}
              <Grid item xs={12} className={classes.btnBox}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={!validForm}
                >
                  Aplicar filtros
                </Button>
              </Grid>
            </ValidatorForm>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

InvoiceFormComponent.defaultProps = {
  fieldsToHide: {},
};

InvoiceFormComponent.propTypes = {
  fieldsToHide: PropTypes.objectOf(Object),
  form: PropTypes.objectOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const InvoiceForm = memo(InvoiceFormComponent);
