// LISTA DE ERRORES DE JOI, LA USAREMOS EN EL FUTURO... ESPERO.
// eslint-disable-next-line no-unused-vars
const t = {
  'alternatives.all': 'error no traducido: [alternatives.all].',
  'alternatives.any': 'error no traducido: [alternatives.any].',
  'alternatives.match': 'error no traducido: [alternatives.match].',
  'alternatives.one': 'error no traducido: [alternatives.one].',
  'alternatives.types': 'error no traducido: [alternatives.types].',
  'any.custom': 'valor no cumplió alguna regla de negocio.',
  'any.default': 'error no traducido: [any.default].',
  'any.failover': 'error no traducido: [any.failover].',
  'any.invalid': 'valor entregado no es válido.',
  'any.only': 'error no traducido: [any.only].',
  'any.ref': 'error no traducido: [any.ref].',
  'any.required': 'valor requerido.',
  'any.unknown': 'error no traducido: [any.unknown].',
  'array.base': 'el valor enviado no es un arreglo.',
  'array.excludes': 'error no traducido: [array.excludes].',
  'array.includesRequiredBoth':
    'error no traducido: [array.includesRequiredBoth].',
  'array.includesRequiredKnowns':
    'error no traducido: [array.includesRequiredKnowns].',
  'array.includesRequiredUnknowns':
    'error no traducido: [array.includesRequiredUnknowns].',
  'array.includes': 'error no traducido: [array.includes].',
  'array.length': 'error no traducido: [array.length].',
  'array.max': 'error no traducido: [array.max].',
  'array.min': 'error no traducido: [array.min].',
  'array.orderedLength': 'error no traducido: [array.orderedLength].',
  'array.sort': 'error no traducido: [array.sort].',
  'array.sort.mismatching': 'error no traducido: [array.sort.mismatching].',
  'array.sort.unsupported': 'error no traducido: [array.sort.unsupported].',
  'array.sparse': 'error no traducido: [array.sparse].',
  'array.unique': 'error no traducido: [array.unique].',
  'array.hasKnown': 'error no traducido: [array.hasKnown].',
  'array.hasUnknown': 'error no traducido: [array.hasUnknown].',
  'binary.base': 'el valor enviado no es una binary.',
  'binary.length': 'error no traducido: [binary.length].',
  'binary.max': 'error no traducido: [binary.max].',
  'binary.min': 'error no traducido: [binary.min].',
  'boolean.base': 'error no traducido: [boolean.base].',
  'date.base': 'el valor enviado no es una fecha.',
  'date.format': 'fecha con formato no válido.',
  'date.greater': 'fecha anterior a la esperada.',
  'date.less': 'fecha posterior a la esperada.',
  'date.max': 'fecha posterior la máxima permitida',
  'date.min': 'fecha anterior la mínima permitida',
  'date.strict': 'error no traducido: [date.strict].',
  'function.arity': 'error no traducido: [function.arity].',
  'function.class': 'error no traducido: [function.class].',
  'function.maxArity': 'error no traducido: [function.maxArity].',
  'function.minArity': 'error no traducido: [function.minArity].',
  'number.base': 'el valor enviado no es un número.',
  'number.greater': 'valor superior al esperado.',
  'number.infinity': 'error no traducido: [number.infinity].',
  'number.integer': 'valor debe ser un entero.',
  'number.less': 'valor inferior al esperado.',
  'number.max': 'valor superior el máximo permitido.',
  'number.min': 'valor inferior el mínimo permitido.',
  'number.multiple': 'error no traducido: [number.multiple].',
  'number.negative': 'valor debe ser negativo.',
  'number.port': 'valor fuera del rango de puertos TCP válidos.',
  'number.positive': 'valor debe ser positivo.',
  'number.precision': 'valor no cumple con la cantidad de decimales esperados.',
  'number.unsafe': 'error no traducido: [number.unsafe].',
  'object.unknown': 'error no traducido: [object.unknown].',
  'object.and': 'error no traducido: [object.and].',
  'object.assert': 'error no traducido: [object.assert].',
  'object.base': 'el valor enviado no es un objeto.',
  'object.length': 'error no traducido: [object.length].',
  'object.max': 'error no traducido: [object.max].',
  'object.min': 'error no traducido: [object.min].',
  'object.missing': 'error no traducido: [object.missing].',
  'object.nand': 'error no traducido: [object.nand].',
  'object.pattern.match': 'error no traducido: [object.pattern.match].',
  'object.refType': 'error no traducido: [object.refType].',
  'object.regex': 'error no traducido: [object.regex].',
  'object.rename.multiple': 'error no traducido: [object.rename.multiple].',
  'object.rename.override': 'error no traducido: [object.rename.override].',
  'object.schema': 'error no traducido: [object.schema].',
  'object.instance': 'error no traducido: [object.instance].',
  'object.with': 'error no traducido: [object.with].',
  'object.without': 'error no traducido: [object.without].',
  'object.xor': 'error no traducido: [object.xor].',
  'object.oxor': 'error no traducido: [object.oxor].',
  'string.alphanum': 'valor debe ser alfanumérico.',
  'string.base64': 'valor debe ser un base64 válido.',
  'string.base': 'el valor enviado no es un texto.',
  'string.creditCard': 'valor no corresponde a una tarjeta de crédito válida.',
  'string.dataUri': 'valor no corresponde a un Data Uri válida.',
  'string.domain': 'valor no corresponde a un dominio válido.',
  'string.email': 'valor no corresponde a un email válido.',
  'string.empty': 'este campo debe esta vacío.',
  'string.guid': 'valor no corresponde a un Guid válido.',
  'string.hexAlign': 'error no traducido: [string.hexAlign].',
  'string.hex': 'error no traducido: [string.hex].',
  'string.hostname': 'valor no corresponde a un hostname válido.',
  'string.ipVersion': 'error no traducido: [string.ipVersion].',
  'string.ip': 'valor no corresponde a una IP válida.',
  'string.isoDate': 'valor no cumple formato de fecha ISO.',
  'string.isoDuration': 'valor no cumple formato de duración ISO.',
  'string.length': 'valor no es del largo esperado.',
  'string.lowercase': 'valor no está en minuúsculas.',
  'string.max': 'valor excede el largo máximo esperado.',
  'string.min': 'valor no alcanza el largo mínimo esperado.',
  'string.normalize': 'error no traducido: [string.normalize].',
  'string.pattern.base': 'error no traducido: [string.pattern.base].',
  'string.pattern.name': 'error no traducido: [string.pattern.name].',
  'string.pattern.invert.base':
    'error no traducido: [string.pattern.invert.base].',
  'string.pattern.invert.name':
    'error no traducido: [string.pattern.invert.name].',
  'string.token': 'valor no es un token válido.',
  'string.trim': 'error no traducido: [string.trim].',
  'string.uppercase': 'valor no está en mayúsculas.',
  'string.uri': 'valor no corresponde a un Uri válido.',
  'string.uriCustomScheme': 'error no traducido: [string.uriCustomScheme].',
  'string.uriRelativeOnly': 'error no traducido: [string.uriRelativeOnly].',
  'symbol.base': 'el valor enviado no es un symbol.',
  'symbol.map': 'error no traducido: [symbol.map].',

  /* *
   *  C U S T O M   E R R O R S
   * */
  'error.notFound': 'referencia no encontrada.',
  'error.unhandled': 'error no controlado en el servidor].',
};

// eslint-disable-next-line import/prefer-default-export
export const csvErrorsReportBuilder = csvErrors => {
  if (!csvErrors || !csvErrors.length) {
    return null;
  }

  const report = csvErrors.map(error => {
    const { line, message } = error;

    return { data: line, error: message };
  });

  return report;
};
