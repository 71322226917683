export const MAX_RANGE = 999999999999999999;

export const BUSINESS_STATUS = {
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'En Proceso',
  },
  APPROVED: {
    value: 'APPROVED',
    label: 'Aprobada',
  },
  ASSIGNED: {
    value: 'ASSIGNED',
    label: 'Cedida',
  },
  NOT_ASSIGNED: {
    value: 'NOT_ASSIGNED',
    label: 'No Cedida',
  },
  REJECTED: {
    value: 'REJECTED',
    label: 'Rechazada',
  },
  REJECTED_TIMEOUT: {
    value: 'REJECTED_TIMEOUT',
    label: 'Rechazada Time Out',
  },
  PAID: {
    value: 'PAID',
    label: 'Pagada',
  },
  RENEGOTIATION: {
    value: 'RENEGOTIATION',
    label: 'Renegociar',
  },
  VALIDATING: {
    value: 'VALIDATING',
    label: 'Validando',
  },
};

export const SORT = {
  DESC_NULLS_LAST: {
    value: 'desc_nulls_last',
  },
  DESC_NULLS_FIRST: {
    value: 'desc_nulls_first',
  },
  ASC_NULLS_LAST: {
    value: 'asc_nulls_last',
  },
  ASC_NULLS_FIRST: {
    value: 'asc_nulls_first',
  },
};

export const BO_STATUS_FILTER_OPTIONS = {
  REVIEWED: {
    value: 'REVIEWED',
    label: 'Revisado',
  },
  TO_DEPOSIT: {
    value: 'TO_DEPOSIT',
    label: 'Por depositar',
  },
  APPROVED: {
    value: 'APPROVED',
    label: 'Aprobado',
  },
  ACTIVE: {
    value: 'ACTIVE',
    label: 'Activo',
  },
  PRIORITY_PAYMENT: {
    value: 'PRIORITY_PAYMENT',
    label: 'Pago Prioritario',
  },
};

export const BO_OPPORTUNITY_STATUS = [
  'REVIEWED',
  'TO_DEPOSIT',
  'APPROVED',
  'ACTIVE',
  'PRIORITY_PAYMENT',
];

export const ERROR_CODES = {
  ERROR_NO_PAYROLL_INVOICE_TO_ASSIGN: {
    errorCode: 'ERROR_NO_PAYROLL_INVOICE_TO_ASSIGN',
    message: 'La factura debe estar cedida para hacer un cambio.',
    status: 400,
  },
  ERROR_INVOICE_NOT_FOUND: {
    errorCode: 'ERROR_INVOICE_NOT_FOUND',
    message:
      'No se encontró una factura. Por favor contáctate por Slack con #soporte-portafolio.',
    status: 404,
  },
  ERROR_NO_PAYROLL_INVOICE_TO_NOTIFY: {
    errorCode: 'ERROR_NO_PAYROLL_INVOICE_TO_NOTIFY',
    message: 'No hay facturas para notificar.',
    status: 400,
  },
  ERROR_UNHANDLED_EXCEPTION: {
    errorCode: 'ERROR_UNHANDLED_EXCEPTION',
    message:
      'Hubo un error desconocido. Por favor, contáctate por Slack con #soporte-portafolio.',
  },
  ERROR_PAYROLLINVOICE_INVALID_ASSIGNATION: {
    errorCode: 'ERROR_PAYROLLINVOICE_INVALID_ASSIGNATION',
    message: 'Esta factura ya está cedida.',
    status: 400,
  },
  ERROR_VALIDATION_ERROR: {
    errorCode: 'ERROR_VALIDATION_ERROR',
    message: 'Hubo un error de validación.',
  },
  ERROR_USER_LOGIN_USER_NOT_FOUND: {
    errorCode: 'ERROR_USER_LOGIN_USER_NOT_FOUND',
    message:
      'Cuenta no registrada. Revisa que este escrita correctamente o regístrate para iniciar sesión.',
    status: 404,
  },
  ERROR_USER_LOGIN_INVALID_PASSWORD: {
    errorCode: 'ERROR_USER_LOGIN_INVALID_PASSWORD',
    message:
      'Contraseña incorrecta. Si necesitas recuperarla, por favor contáctate con tu ejecutivo.',
    status: 401,
  },
  ERROR_SUBSCRIPTION_TYPE_NOT_FOUND: {
    errorCode: 'ERROR_SUBSCRIPTION_TYPE_NOT_FOUND',
    message: 'SubscriptionType not found',
    status: 404,
  },
  ERROR_REGISTER_ERROR: {
    errorCode: 'ERROR_REGISTER_ERROR',
    message: 'User register error',
  },
  ERROR_CREATE_USER_EXISTING_USER: {
    errorCode: 'ERROR_CREATE_USER_EXISTING_USER',
    message: 'User already registered',
    status: 409,
  },
  ERROR_FUND_NOT_FOUND: {
    errorCode: 'ERROR_FUND_NOT_FOUND',
    message:
      'No se encontró el fondo. Por favor contáctate por Slack con #soporte-portafolio.',
    status: 404,
  },
  ERROR_PAYROLL_INVALID_TRANSITION: {
    errorCode: 'ERROR_PAYROLL_INVALID_TRANSITION',
    message: 'Completa todos pasos para continuar.',
    status: 400,
  },
  ERROR_PAYROLLINVOICE_INVALID_TRANSITION: {
    errorCode: 'ERROR_PAYROLLINVOICE_INVALID_TRANSITION',
    message: 'Completa todos los pasos para continuar.',
    status: 400,
  },
  ERROR_PAYROLLINVOICE_NOT_FOUND: {
    errorCode: 'ERROR_PAYROLLINVOICE_NOT_FOUND',
    message:
      'No se encontró una factura. Por favor contáctate por Slack con #soporte-portafolio.',
    status: 404,
  },
  ERROR_PAYROLL_NOT_FOUND: {
    errorCode: 'ERROR_PAYROLL_NOT_FOUND',
    message:
      'No se encontró la nómina. Por favor revisa que los datos estén correctos.',
    status: 404,
  },
  ERROR_USER_UNAUTHORIZED: {
    errorCode: 'ERROR_USER_UNAUTHORIZED',
    message: 'No estás autorizado para realizar está acción.',
    status: 401,
  },
  ERROR_HOLIDAY_SERVICE_FAILED: {
    errorCode: 'ERROR_HOLIDAY_SERVICE_FAILED',
    message: `Holiday api failed for year`,
    status: 503,
  },
  ERROR_GLOBAL_API_SERVICE_FAILED: {
    errorCode: 'ERROR_GLOBAL_API_SERVICE_FAILED',
    message:
      'Error en la api global. Por favor, contáctate por Slack con #soporte-portafolio.',
    status: 500,
  },
};
