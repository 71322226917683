import { useTranslation } from 'react-i18next';
import { t13s } from '../../../../../../translationKeys';
import {
  DebtManagementResults,
  DebtManagementTypes,
  PayerCategorizationResults,
  PayerCategorizationTypes,
} from '../../../../../commons/interfaces/managements';

const LABEL = t13s.LABEL.COLLECTIONS.MANAGEMENTS_RESULTS;
type StatusChipProps = {
  status: string;
  entityType: string;
  label: string;
};
export const useGetManagementsStatusChipProps = () => {
  const { t } = useTranslation();
  const getManagementsStatusChipProps = (
    type: DebtManagementTypes | PayerCategorizationTypes,
    result: DebtManagementResults | PayerCategorizationResults
  ): StatusChipProps => {
    const isPayerCategorizationResult = result in PayerCategorizationResults;
    const translationKey = isPayerCategorizationResult
      ? `${result}_${type}`
      : result;

    const translationValue = String(
      LABEL[translationKey as unknown as keyof Object]
    );
    return {
      status: isPayerCategorizationResult
        ? `${result}_${type}`
        : `${result}_DEBT_MANAGEMENT`,
      entityType: isPayerCategorizationResult
        ? 'payerCategorization'
        : 'debtManagement',
      label: t(translationValue) || result,
    };
  };

  return { getManagementsStatusChipProps };
};
