import React from 'react';
import PropTypes from 'prop-types';
import {
  SvgIcon,
  Link,
  Typography,
  Button,
  Box,
  Tooltip,
} from '@material-ui/core';
import { withStyles, makeStyles, withTheme } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { SIDEBAR_NAVIGATION_BUTTON_ROOT } from '../../theme/otherColors';

const CollapsedButton = withStyles(theme => ({
  root: {
    display: 'grid',
    gridGap: 10,
    justifyItems: 'center',
    cursor: 'pointer',
    padding: '10px',
    width: '100%',
    borderRadius: '13px',
    textDecoration: 'none !important',
    color: SIDEBAR_NAVIGATION_BUTTON_ROOT,
    '&:hover': {
      color: theme.palette.primary.main,
      '& .sideBarIcon': {
        transform: 'scale(1.2)',
      },
    },
    '& .sideBarIcon': {
      transition: '.4s',
    },
    '&.active': {
      background: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
  },
}))(Link);

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderLeft: props =>
      props.active && !props.withLabel
        ? `4px solid ${theme.palette.primary.main}`
        : null,
    padding: props =>
      props.active && !props.withLabel ? '0 10px 0 6px' : '0 10px',
  },
}));

const SideBarNavigationButton = props => {
  const classes = useStyles(props);
  const { active, withLabel, children, icon, ...otherProps } = props;

  return (
    <Box className={classes.buttonContainer}>
      <Tooltip
        title={!withLabel && children ? children : ''}
        placement="right"
        arrow
      >
        <CollapsedButton
          className={active ? 'active' : ''}
          component={otherProps?.to ? RouterLink : Button}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        >
          {icon && <SvgIcon component={icon} className="sideBarIcon" />}
          {withLabel && (
            <Typography variant="body1" align="center">
              {children}
            </Typography>
          )}
        </CollapsedButton>
      </Tooltip>
    </Box>
  );
};

SideBarNavigationButton.defaultProps = {
  disabled: false,
};

SideBarNavigationButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  withLabel: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  to: PropTypes.string.isRequired,
};

export default withTheme(SideBarNavigationButton);
