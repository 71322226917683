import React, { ReactNode, FC } from 'react';
import { Box, styled } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const BoxIcon = styled(Box)({
  width: 24,
  height: 24,
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  borderRadius: 20,
});

const ExclamationIcon: FC<Props> = ({
  color = palette.primary.main,
  style,
}) => {
  return (
    <BoxIcon
      style={{
        color,
        ...style,
      }}
    >
      !!!
    </BoxIcon>
  );
};

export default ExclamationIcon;
