import axios from 'axios';
import { SET_REMOTE_VERSION } from './types';

// eslint-disable-next-line import/prefer-default-export
export const verifyRemoteVersion = () => (dispatch, getState) => {
  axios
    .get(`//${window.location.hostname}:${window.location.port}/version.json`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    .then(resp => {
      if (getState()?.uiState?.remoteVersion === resp.data.version) {
        return;
      }

      dispatch({
        type: SET_REMOTE_VERSION,
        payload: { remoteVersion: resp.data.version },
      });
    });
};
