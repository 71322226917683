import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles, Typography, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../helpers/DateUtils';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import { settings } from '../../config/settings';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles({
  buttonStyles: {
    marginTop: 25,
  },
  businessNameStyles: {
    paddingBottom: 14,
  },
  secondaryTitleStyles: {
    paddingTop: 35,
    paddingBottom: 15,
  },
  displayInlineBlock: {
    display: 'inline-block',
  },
  containerButton: {
    marginTop: 30,
    marginBottom: 15,
  },
});

const ComplianceDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  complianceInformation,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const {
    name,
    compliance = [],
    complianceDetail = {},
    lastConsultCompliance,
  } = complianceInformation;

  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const elementLabelCompliance = (compliance, lastConsultCompliance) => {
    let labelCompliance;
    if (isApiGlobal) {
      labelCompliance = complianceDetail?.types
        ? complianceDetail?.types
        : 'Sin coincidencias';
    } else if (compliance.length) {
      labelCompliance = compliance.reduce(
        (text, type, index) => (!index ? type : `${text}, ${type}`),
        ''
      );
      return labelCompliance;
    }
    if (!lastConsultCompliance) {
      labelCompliance = 'No se ha realizado consultas';
      return labelCompliance;
    }
    return labelCompliance;
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Información de compliance"
    >
      <Grid container direction="column" alignItems="center">
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.businessNameStyles}
          component="div"
        >
          <Box fontWeight="fontWeightBold">{name}</Box>
        </Typography>

        <Typography variant="subtitle1" color="textSecondary">
          Coincidencias:
        </Typography>
        <Typography variant="body1" color="textSecondary" component="div">
          <Box fontWeight="fontWeightBold">
            {elementLabelCompliance(compliance, lastConsultCompliance)}
          </Box>
        </Typography>
        {lastConsultCompliance && (
          <Typography variant="body1" color="textSecondary" align="center">
            {`Última consulta: ${dateFormatter(lastConsultCompliance)}`}
          </Typography>
        )}

        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.secondaryTitleStyles}
          component="div"
        >
          <Box fontWeight="fontWeightBold">Consulta Compliance</Box>
        </Typography>

        <Typography
          variant="subtitle1"
          color="textSecondary"
          align="center"
          component="div"
        >
          Estás por consultar{' '}
          <Box
            fontWeight="fontWeightBold"
            className={classes.displayInlineBlock}
          >
            {name}
          </Box>{' '}
          en {t(t13s.LABEL.COMPLIANCE_ENTITY)}, esta acción generará cargos
          ¿Deseas Continuar?
        </Typography>

        <ValidatorForm
          onSubmit={handleSubmit}
          className={classes.containerButton}
        >
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Consultar"
            loading={loading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

ComplianceDialog.defaultProps = {
  loading: false,
};

ComplianceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  complianceInformation: PropTypes.shape({
    name: PropTypes.string,
    compliance: PropTypes.arrayOf(PropTypes.string),
    complianceDetail: PropTypes.shape({
      types: PropTypes.string,
    }),
    lastConsultCompliance: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
};
export default ComplianceDialog;
