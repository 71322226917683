/* eslint-disable no-param-reassign */
import axios from 'axios';
import copy from 'copy-to-clipboard';
import {
  COUNTRY_CODE_MX,
  DIRECT_FINANCING,
  FORCED,
  NONE,
  EARLY_PAYMENT,
  CONFIRMING,
} from '../helpers/Constants';
import { b64toBlob, download, downloadFile } from '../helpers/fileHandling';
import trackOrderSubmitted from '../helpers/segment/tracking/orderSubmitted';
import { arrayToURL } from '../helpers/url';
import { getErrorCode } from '../helpers/handleErrors';
// eslint-disable-next-line import/no-cycle
import { fetchOrderInvoicesFund } from './fundsActions';
import {
  ADD_FUND_FINANCING_FAILURE,
  ADD_FUND_FINANCING_START,
  ADD_FUND_FINANCING_SUCCESS,
  COPY_EXTERNAL_PDF_URL_END,
  COPY_EXTERNAL_PDF_URL_START,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_NOT_ENROLLED_FAILURE,
  CREATE_ORDER_NOT_ENROLLED_START,
  CREATE_ORDER_NOT_ENROLLED_SUCCESS,
  DELETE_DEBT_PERIOD_FAILURE,
  DELETE_DEBT_PERIOD_START,
  DELETE_DEBT_PERIOD_SUCCESS,
  DELETE_ORDER_INVOICE_FUND_RATE_FAILURE,
  DELETE_ORDER_INVOICE_FUND_RATE_START,
  DELETE_ORDER_INVOICE_FUND_RATE_SUCCESS,
  DOWNLOAD_AEC_INVOICE_FAILURE,
  DOWNLOAD_AEC_INVOICE_START,
  DOWNLOAD_AEC_INVOICE_SUCCESS,
  FETCH_FACTURAS_FAILURE,
  FETCH_FACTURAS_START,
  FETCH_FACTURAS_SUCCESS,
  FETCH_FUND_SUMMARY_OPERATION_FAILURE,
  FETCH_FUND_SUMMARY_OPERATION_START,
  FETCH_FUND_SUMMARY_OPERATION_SUCCESS,
  FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_FAILURE,
  FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_START,
  FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_ID_BY_PAYER_RUT_FAILURE,
  FETCH_ORDERS_ID_BY_PAYER_RUT_START,
  FETCH_ORDERS_ID_BY_PAYER_RUT_SUCCESS,
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_CREDIT_FAILURE,
  FETCH_ORDER_CREDIT_START,
  FETCH_ORDER_CREDIT_SUCCESS,
  FETCH_ORDER_FEES_FAILURE,
  FETCH_ORDER_FEES_START,
  FETCH_ORDER_FEES_SUCCESS,
  FETCH_SELECTED_CREDIT_SUCCESS,
  FETCH_SELECTED_ORDER_FAILURE,
  FETCH_SELECTED_ORDER_START,
  FETCH_SELECTED_ORDER_SUCCESS,
  FETCH_SUMMARY_OPERATION_FAILURE,
  FETCH_SUMMARY_OPERATION_START,
  FETCH_SUMMARY_OPERATION_SUCCESS,
  FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_FAILURE,
  FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_START,
  FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_SUCCESS,
  FETCH_TRANSFER_AMOUNT_PYME,
  GET_PENDING_INVOICES_FAILURE,
  GET_PENDING_INVOICES_START,
  GET_PENDING_INVOICES_SUCCESS,
  INVOICES_BULK_UPDATE_FAILURE,
  INVOICES_BULK_UPDATE_START,
  INVOICES_BULK_UPDATE_SUCCESS,
  LOAD_EXTERNAL_PDF_FAILURE,
  LOAD_EXTERNAL_PDF_START,
  LOAD_EXTERNAL_PDF_SUCCESS,
  LOAD_INVOICE_PDF_FAILURE,
  LOAD_INVOICE_PDF_START,
  LOAD_INVOICE_PDF_SUCCESS,
  MATCH_PENDING_INVOICES_FAILURE,
  MATCH_PENDING_INVOICES_START,
  MATCH_PENDING_INVOICES_SUCCESS,
  REGISTER_DEBT_PERIOD_FAILURE,
  REGISTER_DEBT_PERIOD_START,
  REGISTER_DEBT_PERIOD_SUCCESS,
  REGISTER_ORDER_CREDIT_FAILURE,
  REGISTER_ORDER_CREDIT_SUCCESS,
  RESET_CREATE_ORDER,
  RESET_STATE_FETCH_ORDER_IDS_BY_PAYER_RUT,
  RESET_STATE_REPLACED_INVOICE,
  RESET_STATE_UPDATE_BULK_INVOICES,
  FETCH_SIMULATION_FOR_ORDER_START,
  FETCH_SIMULATION_FOR_ORDER_SUCCESS,
  FETCH_SIMULATION_FOR_ORDER_FAILURE,
  RESET_STATE_UPDATE_INVOICE,
  RESET_STATE_UPDATE_ORDER,
  RESET_STATE_UPLOADING_INVOICE,
  UPDATE_DEBT_PERIOD_FAILURE,
  UPDATE_DEBT_PERIOD_START,
  UPDATE_DEBT_PERIOD_SUCCESS,
  UPDATE_FACTURA_FAILURE,
  UPDATE_FACTURA_START,
  UPDATE_FACTURA_SUCCESS,
  UPDATE_ORDER_DETAILS_FAILURE,
  UPDATE_ORDER_DETAILS_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_INVOICE_FUND_FAILURE,
  UPDATE_ORDER_INVOICE_FUND_START,
  UPDATE_ORDER_INVOICE_FUND_SUCCESS,
  UPDATE_ORDER_START,
  UPDATE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_START,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  UPDATE_PENDING_INVOICES_FAILURE,
  UPDATE_PENDING_INVOICES_START,
  UPDATE_PENDING_INVOICES_SUCCESS,
  VERIFY_INVOICE_SUCCESS,
  FETCH_ORDER_INVOICE_RISK_START,
  FETCH_ORDER_INVOICE_RISK_SUCCESS,
  FETCH_ORDER_INVOICE_RISK_FAILURE,
  FETCH_INVOICE_DOCUMENTS_START,
  FETCH_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_INVOICE_DOCUMENTS_FAILURE,
  DOWNLOAD_INVOICE_DOCUMENTS_START,
  DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_INVOICE_DOCUMENTS_FAILURE,
  DOWNLOAD_SIMULATION_PDF_START,
  DOWNLOAD_SIMULATION_PDF_SUCCESS,
  DOWNLOAD_SIMULATION_PDF_FAILURE,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE,
  UPLOAD_ORDER_INVOICE_PDF_START,
  UPLOAD_ORDER_INVOICE_PDF_SUCCESS,
  UPLOAD_ORDER_INVOICE_PDF_FAILURE,
  DELETE_ORDER_INVOICE_PDF_START,
  DELETE_ORDER_INVOICE_PDF_SUCCESS,
  DELETE_ORDER_INVOICE_PDF_FAILURE,
  RESET_ORDER_INVOICE_PDF_UPLOAD_STATUS,
  DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_START,
  DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_SUCCESS,
  DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_FAILURE,
  MASS_ASSIGNABLE_INVOICE_PDF_START,
  MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS,
  MASS_ASSIGNABLE_INVOICE_PDF_FAILURE,
  VERIFY_INVOICE_START,
  VERIFY_INVOICE_FAILURE,
  REGISTER_ORDER_CREDIT_START,
  MANAGE_ORDER_INVOICES_START,
  MANAGE_ORDER_INVOICES_SUCCESS,
  MANAGE_ORDER_INVOICES_FAILURE,
  FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_START,
  FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_SUCCESS,
  FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_FAILURE,
  FETCH_ORDER_INVOICES_ATTRIBUTES_START,
  FETCH_ORDER_INVOICES_ATTRIBUTES_SUCCESS,
  FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE,
  RESET_ORDER_FEES,
  GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_START,
  GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_SUCCESS,
  GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_FAILURE,
  UPDATE_ORDER_INVOICE_IS_HOLD_START,
  UPDATE_ORDER_INVOICE_IS_HOLD_SUCCESS,
  UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE,
  RESET_ORDER_INVOICE_IS_HOLD_STORE,
  FETCH_COUNT_ORDER_COMMENTS_SUCCESS,
  FETCH_COUNT_ORDER_COMMENTS_START,
  FETCH_COUNT_ORDER_RESPONSIBLES_SUCCESS,
  FETCH_COUNT_ORDER_RESPONSIBLES_START,
  RESET_FETCH_SUMMARY_OPERATION,
  RESET_FETCH_INVOICES,
  RESET_OPERATION_DETAIL_DATA,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';

export const resetCreateOrder = () => dispatch => {
  dispatch({ type: RESET_CREATE_ORDER });
};

export const createOperation =
  (
    orderType,
    { businessId, rawOrderInvoices, orderInvoices, feesData, businesses },
    simulation,
    availableInvoicesCount,
    availableInvoicesAmount,
    autoRate,
    currency,
    exchangeRate,
    paymentType = undefined,
    bankAccounts = undefined
  ) =>
  dispatch => {
    dispatch({
      type: CREATE_ORDER_START,
    });
    axios
      .post(`/api/business/${businessId}/order?orderType=${orderType}`, {
        orderInvoices,
        feesData,
        businesses,
        autoRate,
        currency,
        exchangeRate,
        paymentType,
        bankAccounts,
      })
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_CREATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: CREATE_ORDER_SUCCESS,
          payload: resData,
        });
        trackOrderSubmitted(
          businessId,
          resData.createdOrder.id,
          orderType,
          rawOrderInvoices,
          simulation,
          availableInvoicesCount,
          availableInvoicesAmount
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_ORDER_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const createOperationNotEnrolled =
  (
    orderType,
    { clientSelected, rawOrderInvoices, feesData, businesses },
    simulation,
    availableInvoicesCount,
    availableInvoicesAmount,
    autoRate,
    currency,
    exchangeRate,
    operationPaymentType = undefined,
    bankAccounts = undefined
  ) =>
  dispatch => {
    dispatch({
      type: CREATE_ORDER_NOT_ENROLLED_START,
    });
    const { identifier, name } = clientSelected;
    axios
      .post('/api/invoice/not-enrolled', {
        invoices: rawOrderInvoices,
        client: { identifier, name },
      })
      .then(res => {
        const {
          invoices: orderInvoices,
          business: { id: businessId },
        } = res.data;
        dispatch(
          createOperation(
            orderType,
            {
              businessId,
              rawOrderInvoices,
              orderInvoices,
              feesData,
              businesses,
            },
            simulation,
            availableInvoicesCount,
            availableInvoicesAmount,
            autoRate,
            currency,
            exchangeRate,
            operationPaymentType,
            bankAccounts
          )
        );
      })
      .then(() =>
        dispatch({
          type: CREATE_ORDER_NOT_ENROLLED_SUCCESS,
        })
      )
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_ORDER_NOT_ENROLLED_FAILURE,
        });
        dispatch({
          type: CREATE_ORDER_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchOrders =
  ({
    operation = DIRECT_FINANCING,
    page = 1,
    limit = 20,
    filters = null,
    type = FETCH_ORDERS_START,
  }) =>
  dispatch => {
    dispatch({ type });
    const stringFilters = arrayToURL(filters);
    axios
      .get('/api/orders', {
        params: {
          operation,
          page,
          size: limit,
          filters: stringFilters,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_ORDERS_SUCCESS,
          payload: {
            ...data,
            operation,
          },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_ORDERS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({ type: FETCH_ORDERS_FAILURE, payload: { errorCode } });
      });
  };

export const fetchInvoiceRisk = orderId => dispatch => {
  dispatch({ type: FETCH_ORDER_INVOICE_RISK_START });
  axios
    .get(`/api/invoice-risk/${orderId}`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_ORDER_INVOICE_RISK_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_ORDER_INVOICE_RISK_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchSelectedOrder = orderId => dispatch => {
  dispatch({ type: FETCH_SELECTED_ORDER_START });
  axios
    .get(`/api/orders/${orderId}`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_SELECTED_ORDER_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_ORDER_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_SELECTED_ORDER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchTotalOrderTransferred = orderId => dispatch => {
  dispatch({ type: FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_START });
  axios
    .get(`/api/orders/totalOrdersTransferred?orderId=${orderId}`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_SUCCESS,
        payload: resData,
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_FAILURE,
      });
    });
};

export const fetchOrderInvoicesAttributes = orderId => dispatch => {
  dispatch({ type: FETCH_ORDER_INVOICES_ATTRIBUTES_START });
  axios
    .get(`/api/orders/${orderId}/invoices/attributes`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_ORDER_INVOICES_ATTRIBUTES_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE,
          { variant: 'error' }
        )
      );
      dispatch({
        type: FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE,
      });
    });
};

export const fetchFacturas = orderId => dispatch => {
  dispatch({ type: FETCH_FACTURAS_START });
  axios
    .get(`/api/orders/${orderId}/facturas`)
    .then(({ data }) => {
      dispatch({ type: FETCH_FACTURAS_SUCCESS, payload: data });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_INVOICES_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: FETCH_FACTURAS_FAILURE, payload: { errorCode } });
    })
    .finally(() => {
      dispatch(fetchOrderInvoicesAttributes(orderId));
    });
};

export const resetFetchInvoices = () => dispatch => {
  dispatch({ type: RESET_FETCH_INVOICES });
};

export const fetchOperationSummary = orderId => dispatch => {
  dispatch({ type: FETCH_SUMMARY_OPERATION_START });
  axios
    .get(`/api/orders/${orderId}/summary`)
    .then(res => {
      const { fundSummary, orderSummary } = res.data;

      dispatch({
        type: FETCH_SUMMARY_OPERATION_SUCCESS,
        payload: { orderSummary },
      });

      if (Object.keys(res.data).includes('fundSummary')) {
        dispatch({
          type: FETCH_FUND_SUMMARY_OPERATION_SUCCESS,
          payload: { fundSummary },
        });
      }
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_SUMMARY_OPERATION_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_SUMMARY_OPERATION_FAILURE,
      });
    });
};

export const fetchOperationFundSummary = orderId => dispatch => {
  dispatch({ type: FETCH_FUND_SUMMARY_OPERATION_START });
  axios
    .get(`/api/orders/${orderId}/fundsummary`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_FUND_SUMMARY_OPERATION_SUCCESS,
        payload: { fundSummary: resData },
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.FETCH_FUND_SUMMARY_OPERATION_FAILURE,
          {
            variant: 'error',
          }
        )
      );
      dispatch({
        type: FETCH_FUND_SUMMARY_OPERATION_FAILURE,
      });
    });
};

export const resetFetchOperationSummary = () => dispatch => {
  dispatch({ type: RESET_FETCH_SUMMARY_OPERATION });
};

export const updateOrder = (orderData, operationType) => dispatch => {
  const { orderId } = orderData;
  dispatch({ type: UPDATE_ORDER_START });
  axios
    .post(`/api/orders/${orderId}?operation=${operationType}`, orderData)
    .then(({ data }) => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ORDER_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: UPDATE_ORDER_FAILURE, payload: { errorCode } });
    })
    .finally(() => {
      dispatch(fetchSelectedOrder(orderId));
      dispatch(fetchOperationSummary(orderId));
      dispatch(fetchFacturas(orderId));
    });
};

const parseDataFees = ({
  businessId,
  retentionPct,
  tasaBase,
  issuedDate,
  discount,
  operationCost,
}) => {
  return {
    businessId,
    retentionPct: retentionPct === '' ? 0 : retentionPct,
    rate: tasaBase === '' ? 0 : tasaBase,
    issuedDate,
    operationCost: operationCost === '' ? 0 : operationCost,
    discount: discount === '' ? 0 : discount,
  };
};

export const fetchOrderFees =
  ({
    orderId,
    businessId,
    retentionPct = 0,
    tasaBase = 0,
    operation = DIRECT_FINANCING,
    issuedDate = null,
    discount = 0,
    operationCost = 0,
    autoRate,
  }) =>
  dispatch => {
    const dataFees = parseDataFees({
      businessId,
      retentionPct,
      tasaBase,
      issuedDate,
      discount,
      operationCost,
    });

    dataFees.autoRate = NONE;

    if (autoRate) {
      const getAutoRate = {
        [DIRECT_FINANCING]: FORCED,
        [EARLY_PAYMENT]: FORCED,
      };

      dataFees.autoRate = getAutoRate[operation] || NONE;
    }

    dispatch({ type: FETCH_ORDER_FEES_START });
    axios
      .post(`/api/orders/${orderId}/fees?operation=${operation}`, dataFees)
      .then(res => {
        const resData = res.data;
        dispatch({ type: FETCH_ORDER_FEES_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({ type: FETCH_ORDER_FEES_FAILURE, payload: { errorCode } });
      });
  };

export const resetOrderFees = () => dispatch => {
  dispatch({ type: RESET_ORDER_FEES });
};

export const updateOrderDetails = orderData => dispatch => {
  delete orderData.totalAmount;
  delete orderData.totalAmountCreditNotes;
  axios
    .post('/api/orderdetail', orderData)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ORDER_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({ type: UPDATE_ORDER_DETAILS_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: UPDATE_ORDER_DETAILS_FAILURE, payload: { errorCode } });
    })
    .finally(() => {
      const { orderId } = orderData;
      dispatch(fetchOperationSummary(orderId));
      dispatch(fetchOrderInvoicesAttributes(orderId));
    });
};

const mapOrderInvoice = ({
  id,
  orderId,
  expirationDate,
  paymentConfirmed,
  paymentDate,
  fundPaymentDate,
  fundExpirationDate,
  fechaVencimiento,
  verificationStatus,
  statusReason,
  status,
  discount,
  discountReason,
  baseRate,
  amountRetention,
  destinationId,
  oldStatus,
  defaultDate,
}) => {
  return {
    id,
    orderId,
    expirationDate,
    paymentConfirmed,
    paymentDate,
    fundPaymentDate,
    fundExpirationDate,
    fechaVencimiento,
    verificationStatus,
    statusReason,
    status,
    discount,
    discountReason,
    baseRate,
    tasaBase: baseRate,
    destinationId,
    oldStatus,
    defaultDate,
    ...(typeof amountRetention !== 'undefined' && { amountRetention }),
  };
};

export const updateOrderInvoice =
  (
    invoiceId,
    orderId,
    invoice,
    { fetchOrder = true, notCalculateSurplus = null } = {}
  ) =>
  dispatch => {
    const body = mapOrderInvoice(invoice);
    dispatch({ type: UPDATE_FACTURA_START });
    axios
      .put(`/api/orderfactura/${invoiceId}`, {
        data: {
          ...body,
          notCalculateSurplus,
        },
      })
      .then(({ data }) => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.INVOICE_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({ type: UPDATE_FACTURA_SUCCESS, payload: data });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_FACTURA_FAILURE,
          payload: { errorCode },
        });
      })
      .finally(() => {
        if (fetchOrder) {
          dispatch(fetchSelectedOrder(orderId));
          dispatch(fetchOperationSummary(orderId));
        }
      });
  };

export const fetchInvoicePDF =
  (invoiceId, fileName, orderType, country) => dispatch => {
    dispatch({ type: LOAD_INVOICE_PDF_START, payload: invoiceId });
    const url =
      country === COUNTRY_CODE_MX
        ? `api/orderinvoice/${invoiceId}/mimetuspdf`
        : `api/orderfactura/${invoiceId}/invoice-pdf`;
    axios
      .get(url, {
        params: { type: orderType },
      })
      .then(res => {
        dispatch({ type: LOAD_INVOICE_PDF_SUCCESS });
        const resData = res.data;
        const blob = b64toBlob(resData.pdf, 'application/pdf');
        return download(blob, `${fileName}.pdf`);
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({ type: LOAD_INVOICE_PDF_FAILURE, payload: { errorCode } });
      });
  };

export const bulkUpdateFacturas =
  (bulkUpdateData, { notCalculateSurplus = null } = {}) =>
  dispatch => {
    dispatch({ type: INVOICES_BULK_UPDATE_START });
    axios
      .put(`/api/orderfactura/bulk-update`, {
        bulkUpdateData: {
          ...bulkUpdateData,
          notCalculateSurplus,
        },
      })
      .then(({ data }) => {
        const { failedOrderInvoices } = data;

        if (failedOrderInvoices) {
          dispatch(
            enqueueSnackbar(
              `${failedOrderInvoices} factura${
                failedOrderInvoices > 1 ? 's' : ''
              } tienen emisor/pagador en blocklist`,
              {
                variant: 'error',
              }
            )
          );
        }
        dispatch({
          type: INVOICES_BULK_UPDATE_SUCCESS,
          payload: [],
        });
        dispatch(fetchOrderInvoicesFund(bulkUpdateData.orderId));
        dispatch(fetchFacturas(bulkUpdateData.orderId));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: INVOICES_BULK_UPDATE_FAILURE,
          payload: { errorCode },
        });
      })
      .finally(() => {
        const { orderId } = bulkUpdateData;
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
      });
  };

export const downloadAecInvoice = cessionId => dispatch => {
  dispatch({ type: DOWNLOAD_AEC_INVOICE_START });
  axios
    .get(`api/cession/${cessionId}`)
    .then(res => {
      const { data } = res;
      const blob = b64toBlob(data.cession.aec, 'application/xml');
      dispatch({ type: DOWNLOAD_AEC_INVOICE_SUCCESS });
      download(blob, `${Date.now()}.xml`);
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: DOWNLOAD_AEC_INVOICE_FAILURE });
    });
};

export const getPendingInvoices = orderId => dispatch => {
  dispatch({ type: GET_PENDING_INVOICES_START, payload: orderId });
  axios
    .get(`api/orders/${orderId}/process-pending-invoices`, { timeout: 300000 })
    .then(res => {
      const { data: resData } = res;
      const pdfList = resData.facturasPdf;
      dispatch({
        type: GET_PENDING_INVOICES_SUCCESS,
        payload: {
          invoices: resData.facturas,
          pdfList,
          zipBase64List: resData.zipBase64List,
        },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_INVOICES_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: GET_PENDING_INVOICES_FAILURE, payload: { errorCode } });
    });
};

export const updatePendingInvoices = (invoices, fileName, id) => dispatch => {
  try {
    dispatch({
      type: UPDATE_PENDING_INVOICES_START,
    });
    const newInvoices = invoices.map((invoice, index) => {
      if (invoice.fileName === fileName && id !== index) {
        return {
          ...invoice,
          fileName: null,
        };
      }
      return invoice;
    });
    newInvoices[id].fileName = fileName;
    dispatch({
      type: UPDATE_PENDING_INVOICES_SUCCESS,
      payload: {
        invoices: newInvoices,
      },
    });
    return invoices;
  } catch (err) {
    const errorCode = getErrorCode(err);
    dispatch({ type: UPDATE_PENDING_INVOICES_FAILURE, payload: { errorCode } });
    return null;
  }
};

export const matchPendingInvoices = (orderId, invoices) => dispatch => {
  dispatch({ type: MATCH_PENDING_INVOICES_START, payload: orderId });
  axios
    .post(`api/orders/${orderId}/process-pending-invoices`, {
      invoices,
    })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: MATCH_PENDING_INVOICES_SUCCESS,
        payload: {
          data: { ...resData },
        },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.PENDING_INVOICES_MATCHED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: MATCH_PENDING_INVOICES_FAILURE,
        payload: { errorCode },
      });
    })
    .finally(() => {
      dispatch(fetchSelectedOrder(orderId));
      dispatch(fetchOperationSummary(orderId));
      dispatch(fetchFacturas(orderId));
    });
};

export const registerOrderCredit = (orderId, data) => dispatch => {
  dispatch({
    type: REGISTER_ORDER_CREDIT_START,
  });
  axios
    .post(`/api/orders/${orderId}/credit`, data)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ORDER_CREDIT_CREATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: REGISTER_ORDER_CREDIT_SUCCESS,
        payload: resData,
      });
      dispatch({
        type: FETCH_SELECTED_CREDIT_SUCCESS,
        payload: { selectedCredit: resData },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_ORDER_CREDIT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchOrdersIdByPayerRut =
  (payerIdentifier, totalAmount, orderType = null) =>
  dispatch => {
    dispatch({ type: FETCH_ORDERS_ID_BY_PAYER_RUT_START });
    axios
      .get(`/api/payer/${payerIdentifier}/ordersId`, {
        params: { totalAmount, orderType },
      })
      .then(res => {
        const resData = res.data;
        if (resData?.ordersId && resData?.ordersId?.length <= 1) {
          resData.errorCode = 'ERROR_PAYER_NOT_FOUND';
        }
        dispatch({
          type: FETCH_ORDERS_ID_BY_PAYER_RUT_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYER_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_ORDERS_ID_BY_PAYER_RUT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchFacturasIdByOrderId =
  (orderId, totalAmount, orderType = null) =>
  dispatch => {
    dispatch({ type: FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_START });
    axios
      .get(`/api/orders/${orderId}/facturasId`, {
        params: {
          totalAmount,
          orderType,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_SUCCESS,
          payload: resData,
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_INVOICES_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_FAILURE,
        });
      });
  };

export const invoiceVerification = (factura, data) => dispatch => {
  const { id: facturaId } = factura;
  dispatch({ type: VERIFY_INVOICE_START });
  axios
    .post(`/api/orderfactura/${facturaId}/verification`, data)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: VERIFY_INVOICE_SUCCESS,
        payload: { ...resData, id: facturaId },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.VERIFY_INVOICE_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: VERIFY_INVOICE_FAILURE, payload: { errorCode } });
    });
};

export const resetStateUploadingInvoice = () => dispatch => {
  dispatch({
    type: RESET_STATE_UPLOADING_INVOICE,
  });
};

export const copyExternalPdfURL = externalUrl => dispatch => {
  dispatch({ type: COPY_EXTERNAL_PDF_URL_START });
  copy(externalUrl);
  dispatch(
    enqueueSnackbar(t13s.NOTIFICATION.URL_COPIED, {
      variant: 'success',
    })
  );
  dispatch({ type: COPY_EXTERNAL_PDF_URL_END });
};

export const getExternalPdfURL = orderFacturaId => dispatch => {
  dispatch({ type: LOAD_EXTERNAL_PDF_START, payload: { orderFacturaId } });
  axios
    .get(`api/orderfactura/${orderFacturaId}/external-pdf-url`)
    .then(res => {
      const {
        data: { externalUrl },
      } = res;
      dispatch({
        type: LOAD_EXTERNAL_PDF_SUCCESS,
        payload: { orderFacturaId, externalUrl },
      });
      dispatch(copyExternalPdfURL(externalUrl));
      return res.data;
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: LOAD_EXTERNAL_PDF_FAILURE,
        payload: { orderFacturaId, errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.LOAD_EXTERNAL_PDF_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const getTransferAmountPyme = id => dispatch => {
  axios
    .get(`/api/orders/${id}/transfer-amount-pyme`)
    .then(res => {
      const resData = res.data;
      let payload = '';

      // MIGRACIONCL: The condition is fulfilled when isApiGlobal is enabled
      if ('transferAmountPyme' in resData) {
        const { transferAmountPyme, transferDatePyme } = resData;
        payload = {
          transferAmountPyme: transferAmountPyme ?? 0,
          transferDatePyme,
        };
      } else {
        payload = {
          transferAmountPyme: resData.transferData[0]?.trans ?? 0,
        };
      }
      dispatch({
        type: FETCH_TRANSFER_AMOUNT_PYME,
        payload,
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_TRANSFER_AMOUNT_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const addFundFinancingToInvoice =
  (orderId, fundFinancing) => dispatch => {
    dispatch({ type: ADD_FUND_FINANCING_START });
    axios
      .post(`/api/orderinvoicefund`, fundFinancing)
      .then(res => {
        const { data } = res;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FUND_FINANCING_ADDED, {
            variant: 'success',
          })
        );
        dispatch({
          type: ADD_FUND_FINANCING_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: ADD_FUND_FINANCING_FAILURE,
          payload: { errorCode },
        });
      })
      .finally(() => {
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      });
  };

export const addOrderInvoiceFundRate =
  (orderId, orderInvoiceFundRate) => dispatch => {
    dispatch({ type: REGISTER_DEBT_PERIOD_START });
    const { orderInvoiceFundId } = orderInvoiceFundRate;
    delete orderInvoiceFundRate.orderInvoiceFundId;
    axios
      .post(
        `/api/orderinvoicefund/${orderInvoiceFundId}/rate`,
        orderInvoiceFundRate
      )
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DEBT_PERIOD_REGISTERED, {
            variant: 'success',
          })
        );
        let newOrderInvoiceFundRate;
        if (!resData.period) {
          newOrderInvoiceFundRate = resData;
        }

        dispatch({
          type: REGISTER_DEBT_PERIOD_SUCCESS,
          payload: newOrderInvoiceFundRate,
        });
      })
      .catch(() => {
        dispatch({ type: REGISTER_DEBT_PERIOD_FAILURE });
      })
      .finally(() => {
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      });
  };

export const updateOrderInvoiceFundRate =
  (orderInvoiceFundId, orderInvoiceFundRate, orderId) => dispatch => {
    dispatch({ type: UPDATE_DEBT_PERIOD_START });
    const { id } = orderInvoiceFundRate;
    delete orderInvoiceFundRate.id;
    axios
      .put(
        `/api/orderinvoicefund/${orderInvoiceFundId}/rate/${id}`,
        orderInvoiceFundRate
      )
      .then(res => {
        const resData = res.data;
        const updatedPeriod = { ...orderInvoiceFundRate, id };
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DEBT_PERIOD_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: UPDATE_DEBT_PERIOD_SUCCESS,
          payload: { ...resData, updatedPeriod },
        });
      })
      .catch(() => {
        dispatch({ type: UPDATE_DEBT_PERIOD_FAILURE });
      })
      .finally(() => {
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      });
  };

export const deleteOrderInvoiceFundRate =
  (id, orderInvoiceFundId, orderId, country) => dispatch => {
    dispatch({ type: DELETE_DEBT_PERIOD_START });
    axios
      .delete(`/api/orderinvoicefund/${orderInvoiceFundId}/rate/${id}`)
      .then(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DEBT_PERIOD_DELETED, {
            variant: 'success',
          })
        );
        dispatch({ type: DELETE_DEBT_PERIOD_SUCCESS, payload: { id } });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({ type: DELETE_DEBT_PERIOD_FAILURE, payload: { errorCode } });
      })
      .finally(() => {
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        if (country === COUNTRY_CODE_MX) {
          dispatch(fetchOperationFundSummary(orderId));
        }

        dispatch(fetchFacturas(orderId));
      });
  };

export const unlinkOrderInvoiceFund =
  (orderId, orderInvoiceFundId, orderInvoiceId) => dispatch => {
    dispatch({ type: DELETE_ORDER_INVOICE_FUND_RATE_START });
    axios
      .delete(
        `/api/orderinvoicefund/${orderInvoiceFundId}?orderInvoiceId=${orderInvoiceId}`
      )
      .then(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_INVOICE_FUND_RATE_DELETED, {
            variant: 'success',
          })
        );
        dispatch({
          type: DELETE_ORDER_INVOICE_FUND_RATE_SUCCESS,
          payload: { orderInvoiceFundId },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: DELETE_ORDER_INVOICE_FUND_RATE_FAILURE,
          payload: { errorCode },
        });
      })
      .finally(() => {
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      });
  };

export const addFundFinancingToBulkInvoices =
  (orderId, bulkInvoices) => dispatch => {
    dispatch({ type: ADD_FUND_FINANCING_START });
    axios
      .post(`/api/orderfactura/bulk-fund`, bulkInvoices)
      .then(res => {
        const { data } = res;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FUND_FINANCING_ADDED, {
            variant: 'success',
          })
        );
        dispatch({
          type: ADD_FUND_FINANCING_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: ADD_FUND_FINANCING_FAILURE,
          payload: { errorCode },
        });
      })
      .finally(() => {
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      });
  };

export const updateOrderInvoiceFund =
  (orderInvoiceFundId, orderInvoiceFund) => dispatch => {
    dispatch({ type: UPDATE_ORDER_INVOICE_FUND_START });
    axios
      .put(`/api/orderinvoicefund/${orderInvoiceFundId}`, orderInvoiceFund)
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER_INVOICE_FUND_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_ORDER_INVOICE_FUND_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const resetStateUpdateOrder = () => dispatch => {
  dispatch({
    type: RESET_STATE_UPDATE_ORDER,
  });
};

export const resetStateUpdateInvoice = () => dispatch => {
  dispatch({
    type: RESET_STATE_UPDATE_INVOICE,
  });
};

export const updateStatusOrder =
  (orderData, operationType, { force = false } = {}) =>
  dispatch => {
    const { orderId } = orderData;
    dispatch({ type: UPDATE_ORDER_STATUS_START });
    axios
      .post(`/api/orders/${orderId}?operation=${operationType}`, {
        ...orderData,
        force,
      })
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_ORDER_STATUS_FAILURE,
          payload: { errorCode },
        });
      })
      .finally(() => {
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
      });
  };

export const resetStateFetchOrdersIdByPayerRut = () => dispatch => {
  dispatch({
    type: RESET_STATE_FETCH_ORDER_IDS_BY_PAYER_RUT,
  });
};

export const resetStateReplacedInvoice = () => dispatch => {
  dispatch({
    type: RESET_STATE_REPLACED_INVOICE,
  });
};

export const resetStateUpdateBulkInvoices = () => dispatch => {
  dispatch({
    type: RESET_STATE_UPDATE_BULK_INVOICES,
  });
};

export const manageOrderInvoices =
  (
    orderId,
    {
      action,
      orderInvoiceIds = [],
      newOrderId = null,
      invoices = [],
      deleteInvoiceReason = null,
    }
  ) =>
  async dispatch => {
    dispatch({
      type: MANAGE_ORDER_INVOICES_START,
    });
    axios
      .post(`/api/orders/${orderId}/manageOrderInvoices`, {
        action,
        orderInvoiceIds,
        newOrderId,
        invoices,
        reason: deleteInvoiceReason,
      })
      .then(() => {
        dispatch({
          type: MANAGE_ORDER_INVOICES_SUCCESS,
        });
        window.location.reload();
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: MANAGE_ORDER_INVOICES_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(errorCode, {
            variant: 'error',
          })
        );
      });
  };

export const newSimulation = (orderId, body) => async dispatch => {
  dispatch({
    type: FETCH_SIMULATION_FOR_ORDER_START,
  });
  return axios
    .post(`/api/orders/${orderId}/simulation`, body)
    .then(res => {
      const { data: orderSimulation } = res;
      dispatch({
        type: FETCH_SIMULATION_FOR_ORDER_SUCCESS,
        payload: { orderSimulation },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(enqueueSnackbar(errorCode, { variant: 'error' }));
      dispatch({
        type: FETCH_SIMULATION_FOR_ORDER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchOrderCredit = orderId => dispatch => {
  dispatch({ type: FETCH_ORDER_CREDIT_START });
  axios
    .get(`/api/orders/${orderId}/credit`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_ORDER_CREDIT_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_ORDER_CREDIT_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_ORDER_CREDIT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const getBillDocuments = (businessId, orderId) => dispatch => {
  dispatch({ type: FETCH_INVOICE_DOCUMENTS_START });
  axios
    .get(`/api/orders/${businessId}/order/${orderId}/bill`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_INVOICE_DOCUMENTS_SUCCESS,
        payload: { invoiceDocuments: data },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_DOCUMENTS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_INVOICE_DOCUMENTS_FAILURE,
        payload: { errorCode },
      });
    });
};

export const downloadInvoiceDocuments =
  (orderId, invoiceId, fileExtension = 'pdf') =>
  dispatch => {
    dispatch({ type: DOWNLOAD_INVOICE_DOCUMENTS_START });
    axios
      .get(`/api/orders/${orderId}/bill/${invoiceId}`, {
        params: {
          file: fileExtension,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
        });
        const { file } = data;
        downloadFile(file, invoiceId);
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: DOWNLOAD_INVOICE_DOCUMENTS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const downloadSimulationPdf = orderId => dispatch => {
  dispatch({ type: DOWNLOAD_SIMULATION_PDF_START });
  axios
    .post(`/api/orders/${orderId}/simulation-pdf/`)
    .then(({ data }) => {
      dispatch({
        type: DOWNLOAD_SIMULATION_PDF_SUCCESS,
      });
      const {
        data: { pdf },
      } = data;

      downloadFile(pdf, orderId);
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: DOWNLOAD_SIMULATION_PDF_FAILURE,
        payload: { errorCode },
      });
    });
};

export const checkOrderInvoiceAttachments = orderId => dispatch => {
  dispatch({ type: FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START });
  axios
    .get(`/api/orders/${orderId}/hasinvoiceattachment`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS,
        payload: data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_DOCUMENT_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const uploadOrderInvoicePdf = (invoiceId, pdf) => dispatch => {
  dispatch({
    type: UPLOAD_ORDER_INVOICE_PDF_START,
    payload: { updatingInvoiceId: invoiceId },
  });
  const formData = new FormData();
  formData.append('pdf', pdf, pdf.name);
  axios
    .post(`/api/orderinvoice/${invoiceId}/pdfinvoice`, formData)
    .then(({ data }) => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FILE_UPLOADED, {
          variant: 'success',
        })
      );
      dispatch({
        type: UPLOAD_ORDER_INVOICE_PDF_SUCCESS,
        payload: data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPLOAD_ORDER_INVOICE_PDF_FAILURE,
        payload: { errorCode },
      });
    });
};

export const deleteOrderInvoicePdf = (invoiceId, pdfId) => dispatch => {
  dispatch({
    type: DELETE_ORDER_INVOICE_PDF_START,
    payload: { deletingInvoiceId: invoiceId },
  });

  axios
    .delete(`/api/orderinvoice/${invoiceId}/pdfinvoice`, {
      params: { pdfId },
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FILE_DELETED, {
          variant: 'success',
        })
      );
      dispatch({
        type: DELETE_ORDER_INVOICE_PDF_SUCCESS,
        payload: { deletedInvoiceId: invoiceId },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: DELETE_ORDER_INVOICE_PDF_FAILURE,
        payload: { errorCode },
      });
    });
};

export const resetUploadOrderInvoicePdfStatus = () => dispatch => {
  dispatch({ type: RESET_ORDER_INVOICE_PDF_UPLOAD_STATUS });
};

export const downloadMarketInvoicerPdfGlobalCl = invoiceId => dispatch => {
  dispatch({ type: DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_START });
  axios
    .get(`/api/orderinvoice/${invoiceId}/marketinvoicerpdf`)
    .then(({ data }) => {
      dispatch({
        type: DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_SUCCESS,
      });
      const { pdf } = data;
      if (pdf) {
        downloadFile(pdf, invoiceId);
      } else {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FILE_NOT_EXIST, {
            variant: 'error',
          })
        );
      }
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchAmountInfoByOrderInvoiceIds = orderInvoiceIds => dispatch => {
  dispatch({ type: FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_START });
  axios
    .post(`/api/orderinvoice/totalAmountInfo`, { orderInvoiceIds })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_SUCCESS,
        payload: {
          ...resData,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_FAILURE,
      });
    });
};

export const getRestrictiveInformationNewSimulation =
  (operationType, operationId, businessId) => dispatch => {
    const autoRate = {
      [DIRECT_FINANCING]: FORCED,
      [EARLY_PAYMENT]: NONE,
      [CONFIRMING]: NONE,
    };
    dispatch({ type: GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_START });
    axios
      .post(`/api/orders/${operationId}/fees?operation=${operationType}`, {
        autoRate: autoRate[operationType],
        businessId,
      })
      .then(({ data }) => {
        dispatch({
          type: GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_SUCCESS,
          payload: data?.fees,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updateOrderInvoiceIsHold = orderInvoiceId => dispatch => {
  dispatch({ type: UPDATE_ORDER_INVOICE_IS_HOLD_START });
  axios
    .patch(`/api/orderinvoice/${orderInvoiceId}/isHold`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: UPDATE_ORDER_INVOICE_IS_HOLD_SUCCESS,
        payload: {
          ...resData,
          orderInvoiceId,
        },
      });

      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ORDER_INVOICE_IS_HOLD_UPDATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE,
        payload: { errorCode },
      });
    });
};

export const resetOrderInvoiceIsHoldStore = () => dispatch => {
  dispatch({ type: RESET_ORDER_INVOICE_IS_HOLD_STORE });
};
export const downloadmassassignableinvoicepdf =
  (orderId, invoices) => dispatch => {
    dispatch({ type: MASS_ASSIGNABLE_INVOICE_PDF_START });
    axios
      .post(`/api/orders/${orderId}/massassignableinvoicepdf`, {
        orderInvoiceIds: invoices,
      })
      .then(() => {
        dispatch({ type: MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS });
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS,
            {
              variant: 'success',
            }
          )
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: MASS_ASSIGNABLE_INVOICE_PDF_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.MASS_ASSIGNABLE_INVOICE_PDF_FAILURE,
            {
              variant: 'error',
            }
          )
        );
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS,
            {
              variant: 'success',
            }
          )
        );
      });
  };

export const fetchCountOrderComments = (operationId, userEmail) => dispatch => {
  dispatch({ type: FETCH_COUNT_ORDER_COMMENTS_START });
  axios
    .get(`/api/order/${operationId}/comment/viewed/${userEmail}`)
    .then(({ data }) => {
      dispatch({ type: FETCH_COUNT_ORDER_COMMENTS_SUCCESS, payload: data });
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_COUNT_ORDER_COMMENTS_FAILURE, {
          variant: 'error',
        })
      )
    );
};

export const fetchCountOrderResponsibles = businessIdentifier => dispatch => {
  dispatch({ type: FETCH_COUNT_ORDER_RESPONSIBLES_START });
  axios
    .get(`/api/kam/${businessIdentifier}`)
    .then(({ data }) => {
      const responsiblesCount = data?.length || 0;
      dispatch({
        type: FETCH_COUNT_ORDER_RESPONSIBLES_SUCCESS,
        payload: {
          responsiblesCount,
        },
      });
    })
    .catch(() =>
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.FETCH_COUNT_ORDER_RESPONSBILES_FAILURE,
          {
            variant: 'error',
          }
        )
      )
    );
};

export const resetOperationDetailData = () => dispatch => {
  dispatch({ type: RESET_OPERATION_DETAIL_DATA });
};
