import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, styled, TextField } from '@material-ui/core';

const Dropdown = styled(TextField)(() => ({
  width: '100%',
  marginBottom: 0,
}));

const StatusFilter = ({ invoiceStatus, invoicesStatuses, handleStatus }) => {
  const invoiceStatusValue = invoiceStatus;

  return (
    <Dropdown
      select
      variant="outlined"
      value={invoiceStatusValue}
      onChange={handleStatus}
    >
      {Object.values(invoicesStatuses).map(status => (
        <MenuItem key={status} value={status}>
          {status}
        </MenuItem>
      ))}
    </Dropdown>
  );
};

StatusFilter.propTypes = {
  invoiceStatus: PropTypes.string.isRequired,
  invoicesStatuses: PropTypes.arrayOf(String).isRequired,
  handleStatus: PropTypes.func.isRequired,
};

export default StatusFilter;
