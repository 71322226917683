export const addDraftReceiverUseCase = ({
  draftPayrollSelectorState,
  prevStateTable,
  selectedRows,
}) => {
  const currentSelectorState = draftPayrollSelectorState || {};
  const selectedCurrentIds = selectedRows.map(r => r.receiverIdentifier);
  const newReceiversToAdd = selectedCurrentIds.filter(
    id => !Object.keys(currentSelectorState).includes(id)
  );

  if (newReceiversToAdd.length) {
    return newReceiversToAdd.reduce((acc, receiverIdentifier) => {
      const receiverToAdd = selectedRows.find(
        r => r.receiverIdentifier === receiverIdentifier
      );

      return {
        ...acc,
        [receiverIdentifier]: {
          selected: true,
          value: receiverToAdd.value,
        },
      };
    }, currentSelectorState);
  }

  const selectedPrevIds = prevStateTable.reduce((acc, row) => {
    if (row.tableData.checked) {
      acc.push(row.receiverIdentifier);
    }
    return acc;
  }, []);
  const selectedIdsAgain = selectedCurrentIds.filter(
    id => !selectedPrevIds.includes(id)
  );
  const removedReceiverIds = selectedPrevIds.filter(
    r => !selectedCurrentIds.includes(r)
  );

  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentSelectedReceiver = currentSelectorState[receiverIdentifier];

    if (removedReceiverIds.includes(receiverIdentifier)) {
      const removedReceiver = prevStateTable.find(
        row => row.receiverIdentifier === receiverIdentifier
      );
      const allDeleted =
        currentSelectedReceiver.value.length ===
        removedReceiver.documentsNumber;

      if (allDeleted) {
        delete acc[receiverIdentifier];

        return acc;
      }

      return {
        ...acc,
        [receiverIdentifier]: {
          selected: false,
          value: currentSelectedReceiver.value.filter(
            doc => !removedReceiver.value.find(docu => docu.id === doc.id)
          ),
        },
      };
    }

    if (selectedIdsAgain.includes(receiverIdentifier)) {
      const receiverToAdd = selectedRows.find(
        r => r.receiverIdentifier === receiverIdentifier
      );

      return {
        ...acc,
        [receiverIdentifier]: {
          selected: true,
          value: receiverToAdd.value,
        },
      };
    }

    return {
      ...acc,
      [receiverIdentifier]: currentSelectorState[receiverIdentifier],
    };
  }, {});
};
