import {
  FETCH_LINK_FUND_RATES_FAILURE,
  FETCH_LINK_FUND_RATES_START,
  FETCH_LINK_FUND_RATES_SUCCESS,
  FETCH_LINK_FUND_FAILURE,
  FETCH_LINK_FUND_START,
  FETCH_LINK_FUND_SUCCESS,
  FETCH_LINK_FUND_SUMMARY_FAILURE,
  FETCH_LINK_FUND_SUMMARY_START,
  FETCH_LINK_FUND_SUMMARY_SUCCESS,
  REGISTER_LINK_FUND_START,
  REGISTER_LINK_FUND_SUCCESS,
  REGISTER_LINK_FUND_FAILURE,
  UPDATE_LINK_FUND_START,
  UPDATE_LINK_FUND_SUCCESS,
  UPDATE_LINK_FUND_FAILURE,
  CLEAR_LINK_FUND_RATES,
} from '../actions/types';

const initialState = {
  fund: null,

  fundSummary: null,
  fetchFundSummaryIsLoading: false,
  fetchFundSummaryError: null,

  updateFundIsLoading: false,
  updateFundError: null,
  fundUpdated: false,

  registerFundIsLoading: false,
  registerFundError: null,
  fundRegistered: false,

  fundRates: null,
  fetchFundRatesIsLoading: false,
};

const fetchLinkFundSummaryStart = state => {
  return {
    ...state,
    fundSummaryIsLoading: true,
  };
};

const fetchLinkFundSummarySuccess = (state, payload) => {
  return {
    ...state,
    fundSummary: payload.data,
    fundSummaryIsLoading: false,
  };
};

const clearLinkFundRates = state => {
  return {
    ...state,
    fundRates: null,
  };
};

const fetchLinkFundSummaryFailure = state => {
  return {
    ...state,
    fundSummaryIsLoading: false,
  };
};

const fetchLinkFundRatesStart = state => {
  return {
    ...state,
    fetchFundRatesIsLoading: true,
  };
};

const fetchLinkFundRatesSuccess = (state, payload) => {
  return {
    ...state,
    fundRates: payload.data,
    fetchFundRatesIsLoading: false,
  };
};

const fetchLinkFundRatesFailure = state => {
  return {
    ...state,
    fetchFundRatesIsLoading: false,
  };
};

const fetchLinkFundStart = state => {
  return {
    ...state,
    registerFundError: null,
    updateFundError: null,
    fetchCreditFundIsLoading: true,
  };
};

const fetchLinkFundSuccess = (state, payload) => {
  return {
    ...state,
    fund: payload.data,
    fetchCreditFundIsLoading: false,
  };
};

const fetchLinkFundFailure = state => {
  return {
    ...state,
    fetchCreditFundIsLoading: false,
  };
};

const registerLinkFundStart = state => {
  return {
    ...state,
    registerFundIsLoading: true,
    registerFundError: null,
    fundRegistered: false,
  };
};

const registerLinkFundSuccess = (state, payload) => {
  return {
    ...state,
    fund: payload.data,
    fundRegistered: true,
    registerFundIsLoading: false,
  };
};

const registerLinkFundFailure = (state, payload) => {
  return {
    ...state,
    registerFundIsLoading: false,
    registerFundError: payload?.errorMsg,
    fundRegistered: false,
  };
};

const updateLinkFundStart = state => {
  return {
    ...state,
    updateFundError: null,
    updateFundIsLoading: true,
    fundUpdated: false,
  };
};

const updateLinkFundSuccess = (state, payload) => {
  return {
    ...state,
    fund: payload.data,
    fundUpdated: true,
    updateFundIsLoading: false,
  };
};

const updateLinkFundFailure = (state, payload) => {
  return {
    ...state,
    fundUpdated: false,
    updateFundIsLoading: false,
    updateFundError: payload?.errorMsg,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_LINK_FUND_RATES:
      return clearLinkFundRates(state);
    case FETCH_LINK_FUND_RATES_START:
      return fetchLinkFundRatesStart(state);
    case FETCH_LINK_FUND_RATES_SUCCESS:
      return fetchLinkFundRatesSuccess(state, payload);
    case FETCH_LINK_FUND_RATES_FAILURE:
      return fetchLinkFundRatesFailure(state);
    case FETCH_LINK_FUND_START:
      return fetchLinkFundStart(state);
    case FETCH_LINK_FUND_SUCCESS:
      return fetchLinkFundSuccess(state, payload);
    case FETCH_LINK_FUND_FAILURE:
      return fetchLinkFundFailure(state);
    case FETCH_LINK_FUND_SUMMARY_START:
      return fetchLinkFundSummaryStart(state);
    case FETCH_LINK_FUND_SUMMARY_SUCCESS:
      return fetchLinkFundSummarySuccess(state, payload);
    case FETCH_LINK_FUND_SUMMARY_FAILURE:
      return fetchLinkFundSummaryFailure(state);
    case REGISTER_LINK_FUND_START:
      return registerLinkFundStart(state);
    case REGISTER_LINK_FUND_SUCCESS:
      return registerLinkFundSuccess(state, payload);
    case REGISTER_LINK_FUND_FAILURE:
      return registerLinkFundFailure(state, payload);
    case UPDATE_LINK_FUND_START:
      return updateLinkFundStart(state);
    case UPDATE_LINK_FUND_SUCCESS:
      return updateLinkFundSuccess(state, payload);
    case UPDATE_LINK_FUND_FAILURE:
      return updateLinkFundFailure(state, payload);
    default:
      return state;
  }
};
