import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const fetchPartialPaymentsService = async (
  sourceType,
  sourceId,
  { page = null, size = null } = {}
) => {
  try {
    const data = await axios.get(`/api/${sourceType}/${sourceId}/partialpay`, {
      params: { page, size },
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

export default fetchPartialPaymentsService;
