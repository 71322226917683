/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const AutocompleteComponent = props => {
  const {
    label,
    data,
    value,
    onChange,
    typeInput,
    noOptionsText,
    placeholder,
    renderOption,
    className,
    renderGroup,
    maxHeight,
  } = props;
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChangeInput = value => {
    let newValue = value;
    if (typeInput === 'number') {
      newValue = newValue.replace(/[^0-9]/g, '');
    } else {
      newValue = newValue.toLowerCase();
    }
    setInputValue(newValue);
  };

  return (
    <Autocomplete
      className={className}
      selectOnFocus
      value={value}
      multiple
      options={data}
      disableCloseOnSelect
      groupBy={option => option.group}
      onChange={(event, newValue) => {
        // because when delete all the input go in here and submit this method, but props isSelect is false.
        const isSelect = !!newValue;
        onChange(newValue, isSelect);
      }}
      getOptionLabel={option => option.label}
      renderOption={renderOption}
      renderGroup={renderGroup}
      noOptionsText={noOptionsText}
      filterOptions={options => {
        const optionsFiltered = options.filter(
          option => option.label.toLowerCase().indexOf(inputValue) !== -1
        );
        return optionsFiltered;
      }}
      onInputChange={(event, newInputValue) => {
        handleChangeInput(newInputValue);
      }}
      ListboxProps={{
        style: {
          maxHeight,
        },
      }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            style: { padding: 0 },
          }}
        />
      )}
    />
  );
};

AutocompleteComponent.defaultProps = {
  inputValue: '',
  value: [],
  typeInput: '',
  noOptionsText: '',
  label: '',
  placeholder: '',
  renderOption: null,
  renderGroup: null,
  className: null,
  maxHeight: 200,
};

AutocompleteComponent.propTypes = {
  data: PropTypes.arrayOf(String).isRequired,
  onChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any),
  typeInput: PropTypes.string,
  noOptionsText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  renderGroup: PropTypes.func,
  maxHeight: PropTypes.number,
  className: PropTypes.string,
};

export default AutocompleteComponent;
