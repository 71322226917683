import React from 'react';
import { Box, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from './Card';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    flexWrap: 'nowrap',
  },
  panelPreTitle: {
    padding: props => convertSpacingToCss(props.preTitlePadding),
    margin: 0,
  },
  panelTitle: {
    padding: props => convertSpacingToCss(props.titlePadding),
    margin: 0,
  },
  panelFilters: {
    padding: props => convertSpacingToCss(props.filtersPadding),
    margin: 0,
  },
  panelBody: {
    padding: props => convertSpacingToCss(props.contentPadding),
    margin: 0,
  },
  title: {
    verticalAlign: 'middle',
    padding: props => (props.actions ? '6px 0' : 0),
    color: props =>
      props.variant === 'green-title'
        ? theme.palette.text.contrast1
        : theme.palette.primary,
  },
  actions: {
    alignItems: 'center',
  },
}));

const Panel = ({
  variant,
  title,
  titleAlign,
  titlePadding,
  preTitlePadding,
  filtersPadding,
  actions,
  contentPadding,
  children,
  noBorder,
  preTitle,
  filters,
  borderRadius,
}) => {
  const styles = {
    preTitlePadding,
    titlePadding,
    filtersPadding,
    contentPadding,
    actions,
    variant,
  };
  const classes = useStyles(styles);

  const getTitleVariant = () => {
    let titleVariant = 'h5';
    if (variant === 'green-title') {
      titleVariant = 'h6';
    } else if (variant === 'section') {
      titleVariant = 'subtitle1';
    }
    return titleVariant;
  };

  const getActionSize = () => {
    let size = 12;
    if (actions) {
      if (variant === 'section') {
        size = 6;
      } else {
        size = 3;
      }
    }
    return size;
  };

  const getTitle = variant => {
    return (
      <Typography
        variant={getTitleVariant()}
        align={titleAlign}
        className={classes.title}
      >
        {variant === 'green-title' ? (
          title
        ) : (
          <Box fontWeight="fontWeightBold">{title}</Box>
        )}
      </Typography>
    );
  };

  return (
    <Card variant="white" noBorder={noBorder} borderRadius={borderRadius}>
      <Grid container direction="column" className={classes.gridContainer}>
        {preTitle && (
          <>
            <Grid item xs={12} className={classes.panelPreTitle}>
              {preTitle}
            </Grid>
            <Divider />
          </>
        )}

        {(title || actions) && (
          <Grid item xs={12} className={classes.panelTitle}>
            <Grid container direction="row">
              <Grid item xs={getActionSize()}>
                {getTitle(variant)}
              </Grid>
              {actions && (
                <Grid
                  item
                  xs={12 - getActionSize()}
                  align="right"
                  className={classes.actions}
                >
                  {actions}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {filters && (
          <>
            <Grid item xs={12} className={classes.panelFilters}>
              {filters}
            </Grid>
            <Divider />
          </>
        )}

        {children && (
          <Grid item xs={12} className={classes.panelBody}>
            {children}
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

Panel.defaultProps = {
  variant: 'normal',
  titleAlign: 'left',
  children: null,
  actions: null,
  noBorder: false,
  titlePadding: 'xl',
  preTitlePadding: 'zero',
  filtersPadding: 'lg',
  contentPadding: 'zero lg lg lg',
  preTitle: null,
  filters: null,
  title: null,
  borderRadius: 17,
};

Panel.propTypes = {
  variant: PropTypes.oneOf(['green-title', 'normal', 'section']),
  title: PropTypes.string,
  titleAlign: PropTypes.oneOf(['left', 'center', 'rigth']),
  titlePadding: PropTypes.string,
  preTitlePadding: PropTypes.string,
  filtersPadding: PropTypes.string,
  actions: PropTypes.objectOf(Object),
  contentPadding: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  noBorder: PropTypes.bool,
  preTitle: PropTypes.objectOf(Object),
  filters: PropTypes.objectOf(Object),
  borderRadius: PropTypes.number,
};

export default Panel;
