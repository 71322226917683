import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BUSINESS_SEGMENT } from '../../../helpers/Constants';
import { getMultiplesHash } from '../../../helpers/tools';

const ALL = 'ALL';

const arrayTabs = [
  {
    label: 'Todos',
    value: ALL,
  },
];

const useTabSection = () => {
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash.replace('#', '');
  const cleanHash = getMultiplesHash(hash);

  const existTab: boolean = BUSINESS_SEGMENT.some(
    ({ value }) => value === cleanHash?.businessSegment
  );

  const [activeTab, setActiveTab] = useState<string>(
    existTab ? cleanHash?.businessSegment : ALL
  );

  const handleBusinessSegmentTabChange = (
    valueTab: string,
    hashUrl: string
  ) => {
    history.push(`${location.pathname}#${hashUrl}=${valueTab}`);
    setActiveTab(valueTab);
  };

  return {
    activeTab,
    arrayTabs,
    setActiveTab,
    handleBusinessSegmentTabChange,
  };
};

export default useTabSection;
