import { useCallback, useState } from 'react';
import useFetchRequirements from '../infrastructure/store/useFetchRequirements';
import { RequirementData, Params } from '../interfaces';
import { useParams } from 'react-router-dom';
import useGetCurrentOperation from '../../../../../hooks/useGetCurrentOperation';
import useRequirements from './useRequirements';
import useVerifyRequirements from '../infrastructure/store/useVerifyRequirements';

const useRequirementsTab = () => {
  const { id: operationId } = useParams<Params>();
  const {
    sectionData,
    handleGetProgress,
    handleGetRequirementData,
    handleGetRequirementIcon,
    handleGetLinearProgress,
    handleGetCountRequirements,
  } = useRequirements();
  const operation = useGetCurrentOperation();
  const operationType = operation?.orderType || operation?.type;
  const operationStatus = operation?.status;

  const [
    showInvoicePendingRequirementsDrawer,
    setShowInvoicePendingRequirementsDrawer,
  ] = useState<boolean>(false);
  const [invoiceRequirementSelected, setInvoiceRequirementSelected] =
    useState<RequirementData | null>(null);

  const { requirements, isLoading: fetchRequirementsIsLoading } =
    useFetchRequirements(Number(operationId));

  const { isLoading: verifyRequirementsIsLoading, verifyRequirements } =
    useVerifyRequirements();

  const handleShowInvoicePendingRequirementsDrawer = useCallback(
    (requirement: RequirementData) => {
      setShowInvoicePendingRequirementsDrawer(true);
      setInvoiceRequirementSelected(requirement);
    },
    [setShowInvoicePendingRequirementsDrawer, setInvoiceRequirementSelected]
  );

  const handleCloseInvoicePendingRequirementsDrawer = useCallback(() => {
    setShowInvoicePendingRequirementsDrawer(false);
    setInvoiceRequirementSelected(null);
  }, [setShowInvoicePendingRequirementsDrawer, setInvoiceRequirementSelected]);

  const handleVerifyRequirements = useCallback(() => {
    verifyRequirements({
      operationId: Number(operationId),
      fetchRequirements: true,
    });
  }, [verifyRequirements, operationId]);

  return {
    requirements,
    fetchRequirementsIsLoading,
    invoiceRequirementSelected,
    showInvoicePendingRequirementsDrawer,
    sectionData,
    operationType,
    operationStatus,
    verifyRequirementsIsLoading,
    handleShowInvoicePendingRequirementsDrawer,
    handleCloseInvoicePendingRequirementsDrawer,
    handleGetProgress,
    handleGetRequirementData,
    handleGetRequirementIcon,
    handleGetLinearProgress,
    handleGetCountRequirements,
    handleVerifyRequirements,
  };
};

export default useRequirementsTab;
