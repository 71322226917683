const apiClErrorsToErrorCode = {
  'User not found': 'ERROR_USER_NOT_FOUND',
  'Paid status requires a payment date':
    'ERROR_PAYMENT_STATUS_REQUIRE_PAYMENT_DATE',
  'Transfer an invoice to complete status it must be in paid':
    'ERROR_INVOICE_SHOULD_BE_PAID_TO_CHANGE_STATUS_TO_COMPLETE',
  'To modify an invoice to complete status, it must be in paid status before':
    'ERROR_INVOICE_SHOULD_BE_PAID_TO_MODIFY_STATUS_TO_COMPLETE',
  'Passwords do not match': 'ERROR_PASSWORD_NOT_MATCH',
  'User already registered': 'ERROR_USER_ALREADY_REGISTERED',
  'User already exist': 'ERROR_USER_ALREADY_EXIST',
  'orderId not found': 'ERROR_ORDER_ID_NOT_FOUND',
  'Wrong password': 'ERROR_WRONG_PASSWORD',
  'Password fields cannot be empty': 'ERROR_EMPTY_PASSWORD_FIELD',
  'New password does not match': 'ERROR_NEW_PASSWORD_DOES_NOT_MATCH',
  'Error in compliance service': 'ERROR_COMPLIANCE_SERVICE',
  "Document doesn't exist": 'ERROR_DOCUMENT_NOT_FOUND',
  'Error create contact': 'ERROR_CREATE_CONTACT',
  'Invoices in final status, it is not possible to update property':
    'ERROR_UNABLE_TO_UPDATE_OPERATION_INVOICES_WITH_FINAL_STATUS',
  'return status 500': 'ERROR_SERVER_RETURN_STATUS_500',
  'Order not found': 'ERROR_ORDER_NOT_FOUND',
  'Business not found': 'ERROR_BUSINESS_NOT_FOUND',
  'Email already exists': 'ERROR_EMAIL_ALREADY_EXIST',
  'Fail to download file': 'ERROR_DOWNLOADING_FILE',
  'Bank account already exists': 'ERROR_BANK_ACCOUNT_ALREADY_EXIST',
  'This invoice is already active in another operation':
    'ERROR_INVOICE_ALREADY_EXIST',
  'Invoice is hold': 'ERROR_INVOICE_IS_HOLD',
};

// eslint-disable-next-line import/prefer-default-export
export const getErrorCode = axiosError => {
  const body = axiosError.response?.data;

  // ERROR FROM API GLOBAL
  if (body?.errorCode) {
    return body.errorCode;
  }
  // ERROR FROM API CL
  if (body?.error) {
    return apiClErrorsToErrorCode[body.error] || 'ERROR_UNHANDLED_ERROR';
  }

  // UNHANDLED ERROR
  return 'ERROR_UNHANDLED_ERROR';
};
