import React from 'react';
import openBoxIcon from './openBoxIcon.svg';
import {
  RootContainer,
  TextContainer,
  IconContainer,
  BigText,
  SmallText,
} from './styles';

const EmptyStateCard = () => {
  return (
    <RootContainer container direction="column" alignContent="center">
      <IconContainer item xs={12}>
        <img src={openBoxIcon} alt="icon" />
      </IconContainer>
      <TextContainer item xs={12}>
        <BigText fontWeight="bold">No hay operaciones en este estado</BigText>
      </TextContainer>
      <TextContainer item xs={12}>
        <SmallText>
          Por el momento, de todas las operaciones disponibles ninguna tiene
          este estado
        </SmallText>
      </TextContainer>
    </RootContainer>
  );
};

export default EmptyStateCard;
