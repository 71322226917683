import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Warning = props => (
  <SvgIcon
    width={20}
    height={18}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m19.066 16.08-4.187-7.478-4.186-7.477a.767.767 0 0 0-.275-.275.737.737 0 0 0-1.016.275L5.216 8.602.935 16.08a.8.8 0 0 0 .26 1.056c.112.07.239.11.37.114h16.84a.737.737 0 0 0 .38-.102.768.768 0 0 0 .279-.285.8.8 0 0 0 .002-.783Zm-8.027-1.886a1.07 1.07 0 0 1-.295.736 1.007 1.007 0 0 1-.712.304.98.98 0 0 1-.713-.304 1.043 1.043 0 0 1-.295-.736v-.455c0-.276.106-.54.295-.736a.991.991 0 0 1 .713-.305c.267 0 .523.11.712.305.189.195.295.46.295.736v.455Zm0-3.706c0 .276-.106.54-.295.735a.991.991 0 0 1-.712.305.991.991 0 0 1-.713-.305 1.058 1.058 0 0 1-.295-.735V5.644c0-.276.106-.541.295-.736a.991.991 0 0 1 .713-.305c.267 0 .523.11.712.305.189.195.295.46.295.736v4.844Z"
      fill="#F4B231"
    />
  </SvgIcon>
);

export default Warning;
