import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchOrderFees = async ({ operation, orderId, dataFees }) => {
  try {
    const { data } = await axios.post(
      `/api/orders/${orderId}/fees?operation=${operation}`,
      dataFees
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrderFees };
