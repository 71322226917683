import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updatePartialPaymentService } from '../services';

export const useUpdatePartialPayment = ({
  sourceType,
  sourceId,
  partialPaymentId,
}) => {
  const dispatch = useDispatch();

  const {
    mutate: updatePartialPayment,
    isLoading: updatePartialPaymentIsLoading,
    error: updatePartialPaymentError,
    isSuccess: updatePartialPaymentSuccess,
    data: updatePartialPaymentData,
  } = useMutation(
    ({ amount, paymentDate, comment }) =>
      updatePartialPaymentService(sourceType, sourceId, partialPaymentId, {
        amount,
        paymentDate,
        comment,
      }),
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PARTIAL_PAYMENT_UPDATED, {
            variant: 'success',
          })
        );
      },
    }
  );

  return {
    updatePartialPayment,
    updatePartialPaymentError,
    updatePartialPaymentIsLoading,
    updatePartialPaymentSuccess,
    updatePartialPaymentData,
  };
};
export default useUpdatePartialPayment;
