import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import {
  DatePicker as MaterialDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { styled } from '@material-ui/styles';
import { DateRange, Clear } from '@material-ui/icons';

const DATE_FORMAT = 'dd-MM-yyyy';

const CustomMaterialDatePicker = styled(MaterialDatePicker)({
  margin: 0,
});

const InputWrapper = styled(Box)({
  position: 'relative',
});

const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  right: 13,
  transform: 'translateY(-50%)',
});

const DatePicker = ({ label, name, value, onChange, onClear }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomMaterialDatePicker
        inputVariant="outlined"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        format={DATE_FORMAT}
        margin="normal"
        minDateMessage="Fecha inválida"
        autoOk
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <DateRange color="action" />
            </InputAdornment>
          ),
        }}
        placeholder={label}
        TextFieldComponent={args => (
          <InputWrapper>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <TextField {...args} />
            {value && onClear ? (
              <CustomIconButton
                onClick={onClear}
                aria-label="clear"
                edge="end"
                size="small"
              >
                <Clear />
              </CustomIconButton>
            ) : null}
          </InputWrapper>
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.defaultProps = {
  value: null,
  onClear: () => undefined,
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default DatePicker;
