import * as actions from '../actions/websocket';
import { WS_CONNECT, WS_DISCONNECT, WS_DISCONNECTED } from '../actions/types';

const socketMiddleware = () => {
  let socket = null;
  const autoReconnectInterval = 1 * 5000;
  let autoReconnect;
  const onOpen = store => event => {
    if (autoReconnect) clearTimeout(autoReconnect);
    store.dispatch(actions.wsConnected(event.target.url));
  };

  const onClose = store => () => {
    store.dispatch(actions.wsDisconnected());
  };

  // eslint-disable-next-line consistent-return
  const onMessage = store => event => {
    let payload;
    try {
      payload = JSON.parse(event.data);
    } catch (e) {
      return e;
    }
    if (payload?.message?.command === 'CESSION_STATUS') {
      store.dispatch(actions.cessionUpdate(payload.message));
    }
    if (payload?.message?.command === 'WS_UPDATE_INVOICE') {
      store.dispatch(actions.orderfacturaUpdate(payload.message));
    }
    if (payload?.message?.command === 'VERIFY_INVOICE') {
      store.dispatch(actions.verifiedOrderfactura(payload.message));
    }
    if (payload?.message?.command === 'UPDATE_FACTURAS_AND_ORDER') {
      store.dispatch(actions.updateFactura(payload.message));
    }
    if (payload?.message?.command === 'WS_UPDATE_BULK_INVOICES') {
      store.dispatch(actions.updateBulkFactura(payload.message));
    }
    if (payload?.message?.command === 'WS_REGISTER_ROLE') {
      store.dispatch(actions.registerRole(payload.message));
    }
    if (payload?.message?.command === 'WS_UPDATE_ROLE') {
      store.dispatch(actions.updateRole(payload.message));
    }
    if (payload?.message?.command === 'WS_DISABLE_ROLE') {
      store.dispatch(actions.disableRole(payload.message));
    }
    if (payload?.message?.command === 'TAX_SCRAPE_DATA_MANUAL') {
      store.dispatch(actions.wsTaxScrapeUpdated(payload.message));
    }
  };

  // eslint-disable-next-line consistent-return
  return store => next => action => {
    switch (action.type) {
      case WS_CONNECT:
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(process.env.REACT_APP_WS_URL);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case WS_DISCONNECTED:
        // if disconnected try to reconnect
        autoReconnect = setTimeout(() => {
          store.dispatch({ type: WS_CONNECT });
        }, autoReconnectInterval);

        break;
      case 'CESSION_START':
        if (socket) {
          socket.send(
            JSON.stringify({
              command: 'CESSION_START',
              token: action.token,
            })
          );
        }
        break;
      case 'START_FACTURA_SCORE':
        if (socket) {
          socket.send(
            JSON.stringify({
              command: 'START_FACTURA_SCORE',
              token: action.token,
            })
          );
        }
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
