import { styled, Link as MaterialLink, Grid } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../formatters';

const CustomLink = styled(MaterialLink)({
  display: 'flex',
  cursor: 'pointer',
});

const CustomText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

const IconGrid = styled(Grid)({
  marginRight: 15,
  display: 'flex',
  alignItems: 'center',
});

const Link = ({
  children,
  handler,
  fontWeight,
  icon,
  href,
  target,
  variant,
}) => {
  return (
    <CustomLink onClick={handler} href={href} target={target}>
      {icon && <IconGrid item>{icon}</IconGrid>}
      <CustomText variant={variant} fontWeight={fontWeight}>
        {children}
      </CustomText>
    </CustomLink>
  );
};

Link.defaultProps = {
  children: undefined,
  handler: undefined,
  fontWeight: 'bold',
  icon: undefined,
  href: undefined,
  target: undefined,
  variant: 'h6',
};

Link.propTypes = {
  children: PropTypes.node,
  handler: PropTypes.func,
  fontWeight: PropTypes.string,
  icon: PropTypes.element,
  href: PropTypes.string,
  target: PropTypes.string,
  variant: PropTypes.string,
};

export default Link;
