import { Box, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  img: {
    width: '100%',
    maxHeight: '75vh',
  },
  downloadButton: {
    marginRight: 16,
  },
});

export const ImgContainer = styled(Box)({
  minHeight: 352,
  minWidth: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
