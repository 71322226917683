import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { useInvoiceTableReloadRef } from '../../ui/PayrollInvoices/InvoicesTable/store';
import { bulkAssignBankAccountToInvoicesService } from '../services';

export const useBulkAssignBankAccountToInvoices = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const invoiceTableReloadRef = useInvoiceTableReloadRef();

  const { isLoading, isSuccess, data, mutate, error } = useMutation(
    ({ businessId, invoicesIds, bankAccountId }) => {
      return bulkAssignBankAccountToInvoicesService({
        businessId,
        invoicesIds,
        bankAccountId,
      });
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BANK_ACOUNT_ASSIGNED_SUCCESS, {
            variant: 'success',
          })
        );

        queryClient.invalidateQueries('fetchTransferAccounts', {
          exact: false,
        });

        invoiceTableReloadRef();
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    error,
    data,
    mutate,
  };
};

export default useBulkAssignBankAccountToInvoices;
