import { FormControlLabel, Grid, styled } from '@material-ui/core';

const SubtitleContainer = styled(Grid)({
  marginBottom: 17,
});

const SubmitButtonContainer = styled(Grid)({
  marginTop: 16,
  width: '100%',
});

const BankAccountContainer = styled(Grid)(({ theme }) => ({
  height: 60,
  borderBottom: `1px solid ${theme.palette.payments.grey5}`,
}));

const CustomFormControlLabel = styled(FormControlLabel)({
  marginBottom: 0,
  marginRight: 0,
});

const AddBankAccountButtonContainer = styled(Grid)({
  marginBottom: 34,
});

export {
  SubtitleContainer,
  SubmitButtonContainer,
  BankAccountContainer,
  CustomFormControlLabel,
  AddBankAccountButtonContainer,
};
