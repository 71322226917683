import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { t13s } from '../../../../translationKeys';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

enum PayersFilter {
  portfolio = 'portfolio',
  supervisedPortfolio = 'supervisedPortfolio',
  all = 'all',
}

export const useGetUserMessage = () => {
  const { authState } = useOktaAuth();
  const { t } = useTranslation();
  const [messageToUser, setMessageToUser] = useState<string>();
  const { collectionsPayersFilteredBy } = useSelector(
    (state: RootStateOrAny) => state.collection
  );

  const filteredBy = collectionsPayersFilteredBy as PayersFilter;
  const userName = authState?.idToken?.claims?.name || '';
  const messages = {
    [PayersFilter.all]: () =>
      t(t13s.LABEL.COLLECTIONS.NOT_FILTERING_BY_PORTFOLIO, { userName }),
    [PayersFilter.supervisedPortfolio]: () =>
      t(t13s.LABEL.COLLECTIONS.FILTERING_BY_SUPERVISOR_PORTFOLIO, { userName }),
    [PayersFilter.portfolio]: () =>
      t(t13s.LABEL.COLLECTIONS.FILTERING_BY_PORTFOLIO, { userName }),
  };

  useEffect(() => {
    if (!collectionsPayersFilteredBy) return;
    setMessageToUser(messages[filteredBy]());
  }, [collectionsPayersFilteredBy]);

  return messageToUser || messages[PayersFilter.all]();
};
