import React, { FC } from 'react';
import FileUploadDialog from '../../../../commons/components/FileUploadModal';
import { csvErrorsReportBuilder } from '../../../../../helpers/CsvErrorsReportBuilder';
import useFundBulkUpdate from './infrastructure/store/useFundBulkUpdate';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const FundBulkUpdateModal: FC<Props> = ({ open, handleClose }) => {
  const {
    uploadFile,
    resetDataAndErrors,
    isLoading,
    hasErrors,
    serverError,
    isSuccess,
    errorLines,
  } = useFundBulkUpdate();

  const TITLE = 'Actualización masiva de Fondos';
  const DESCRIPTION =
    'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

  const CSV_TEMPLATE = [
    {
      orderInvoiceId: 1,
      fundId: 1,
      nominaId: 1,
      folio: '12345',
      startDate: '2021-01-01',
      expirationDate: '2021-01-02',
      interest: 1000,
      baseRate: 1.1,
      debtRate: 1.8,
      debtDays: 20,
      advancePercentage: 90,
      effectiveFundCostRate: 1.2,
      effectiveFundCostInterest: 1000,
      xepelinCapitalCostRate: 1.66,
    },
  ];

  const onClose = () => {
    handleClose();
    resetDataAndErrors();
  };

  if (!isLoading && isSuccess && !hasErrors && !serverError) {
    onClose();
  }

  return (
    <FileUploadDialog
      open={open}
      handleCloseDialog={onClose}
      handleLoad={uploadFile}
      saving={isLoading}
      errorsCsv={csvErrorsReportBuilder(errorLines)}
      serverError={serverError ? String(serverError) : null}
      title={TITLE}
      description={DESCRIPTION}
      csvTemplate={CSV_TEMPLATE}
      resetDataAndErrors={resetDataAndErrors}
    />
  );
};

export default FundBulkUpdateModal;
