
import { useEffect } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { request } from 'graphql-request';
import { useOktaAuth } from '@okta/okta-react';

type Options = {
  query: string;
  variables?: Record<string, unknown>;
} & Omit<UseQueryOptions, 'refetchInterval'>;

export const useGraphQlQuery = <Data>(
  queryKey: string,
  {
    query,
    variables = {},
    enabled = true,
    retry,
    refetchInterval,
  }: Options
  & {
    refetchInterval?: number | false | ((data: Data | undefined, query: unknown) => number | false);
  }) => {
  const endpoint = process.env.REACT_APP_GRAPHQL_SERVER_URL;
  const { authState } = useOktaAuth();

  const {
    isLoading: loading,
    data,
    error,
    isSuccess,
    isError,
    isFetched,
    isPreviousData,
    isRefetching,
    refetch,
    remove,
  } = useQuery<Data, string>(queryKey, async ({ signal }) => request<Data>({
    url: endpoint!,
    document: query,
    requestHeaders: { authorization: `Bearer ${authState?.accessToken?.accessToken || ''}` },
    variables,
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    signal,
  }), { enabled, retry, refetchInterval });

  useEffect(() => remove, []);

  return {
    loading,
    data,
    error,
    isSuccess,
    isError,
    isFetched,
    isPreviousData,
    isRefetching,
    refetch,
  };
};
