import { gql } from 'graphql-request';

export const query = gql`
  query SlackThread($dto: GetSlackThreadDTO!) {
    slackThread(dto: $dto) {
      threadId
      link
    }
  }
`;

export type WorkFlowSlackThreadId = {
  threadId: string;
  link: string;
};

export type Query = {
  slackThread: WorkFlowSlackThreadId | null;
};
