import produce from 'immer';
import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import orderBulkUpdateStateService from '../services/orderBulkUpdateStateService';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys/index';

const useOperationBulkUpdate = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    data,
    mutate: changeState,
    reset: resetDataAndErrors,
    isSuccess,
  } = useMutation(params => orderBulkUpdateStateService(params), {
    onMutate: async ({ orderIds, status }) => {
      await queryClient.cancelQueries('getOperations');
      await queryClient.cancelQueries('getOperationTotals');
      const prevDataGetOperations = queryClient.getQueryData('getOperations', {
        exact: false,
      });
      const prevDataGetOperationTotals = queryClient.getQueryData(
        'getOperationTotals',
        { exact: false }
      );
      const updatedData = produce(prevDataGetOperations, draft => {
        orderIds.forEach(order => {
          const index = draft.data.findIndex(invoice => order === invoice.id);
          // eslint-disable-next-line no-param-reassign
          if (index !== -1) draft.data[index].status = status;
        });
      });
      queryClient.setQueryData('getOperations', updatedData);
      return { prevDataGetOperations, prevDataGetOperationTotals };
    },
    onError: (_err, _variables, context) => {
      dispatch(
        enqueueSnackbar(t13s.ERROR.ERROR_ORDER_BULK_UPDATE_STATUS, {
          variant: 'error',
        })
      );
      queryClient.setQueryData('getOperations', context.prevDataGetOperations);
      return queryClient.setQueryData(
        'getOperationTotals',
        context.prevDataGetOperationTotals
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries('getOperations', {
        exact: false,
      });
      queryClient.invalidateQueries('getOperationTotals', {
        exact: false,
      });
    },
    onSuccess: () => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.ORDER_UPDATED, {
          variant: 'success',
        })
      );
    },
  });

  return {
    isLoading,
    isError,
    data,
    isSuccess,
    changeState,
    resetDataAndErrors,
  };
};

export default useOperationBulkUpdate;
