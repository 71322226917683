// eslint-disable-next-line import/prefer-default-export
export const LABEL = {
  TAX_SERVICE_LONG_NAME: 'LABEL_TAX_SERVICE_LONG_NAME',
  TAX_SERVICE_SHORT_NAME: 'LABEL_TAX_SERVICE_SHORT_NAME',
  TAX_RESUME: 'LABEL_TAX_RESUME',
  BUSINESS_IDENTIFIER: 'LABEL_BUSINESS_IDENTIFIER',
  TOTAL_TO_TRANSFER: 'LABEL_TOTAL_TO_TRANSFER',
  COMPANY_ADDRESS: 'LABEL_COMPANY_ADDRESS',
  TAX_SERVICE_CREDENTIAL: 'LABEL_TAX_SERVICE_CREDENTIAL',
  BANK_ACCOUNT_IDENTIFIER: 'LABEL_BANK_ACCOUNT_IDENTIFIER',
  DEBT_SERVICE: 'LABEL_DEBT_SERVICE',
  TAX_SERVICE_COLUMN_NAME: 'LABEL_TAX_SERVICE_COLUMN_NAME',
  DEBT_SERVICE_NAME: 'LABEL_DEBT_SERVICE_NAME',
  COMPLIANCE_ENTITY: 'LABEL_COMPLIANCE_ENTITY',
  PHONE_NUMBER_COUNTRY_CODE: 'LABEL_PHONE_NUMBER_COUNTRY_CODE',
  ISSUED: 'LABEL_ISSUED',
  RECEIVER: 'LABEL_RECEIVER',
  CHARGE_TO_PAY: 'LABEL_CHARGE_TO_PAY',
  CEDED_INVOICES: 'LABEL_CEDED_INVOICES',
  MONTLHY_RATE: 'LABEL_MONTLHY_RATE',
  PAYMENTS_BANK_ACCOUNT_IDENTIFIER: 'LABEL_PAYMENTS_BANK_ACCOUNT_IDENTIFIER',
  COLLECTIONS: {
    TABS: {
      DEBT_LIST: 'COLLECTIONS_TAB_DEBTS_LIST',
      MANAGEMENTS_HYSTORY: 'COLLECTIONS_TAB_MANAGEMENTS_HISTORY',
    },
    BUSINESS_IDENTIFIER: 'COLLECTIONS_BUSINESS_IDENTIFIER',
    DEBTS_LIST_HEADER_TITLE: 'COLLECTIONS_DEBTS_LIST_HEADER_TITLE',
    DEBTS_LIST_HEADER_DESCRIPTION: 'COLLECTIONS_DEBTS_LIST_HEADER_DESCRIPTION',
    DEBTS_LIST_BODY_DEBTS_NOT_FUND:
      'COLLECTIONS_DEBTS_LIST_BODY_DEBTS_NOT_FUND',
    FILTERING_BY_PORTFOLIO: 'COLLECTIONS_FILTERING_BY_PORTFOLIO',
    FILTERING_BY_SUPERVISOR_PORTFOLIO:
      'COLLECTIONS_FILTERING_BY_SUPERVISOR_PORTFOLIO',
    NOT_FILTERING_BY_PORTFOLIO: 'COLLECTIONS_NOT_FILTERING_BY_PORTFOLIO',
    RENEGOTIATION: 'COLLECTIONS_RENEGOTIATION',
    DEBT_LIST_COLUMNS: {
      OPERATION_ID: 'COLLECTIONS_DEBTS_COLUMN_OPERATION_ID',
      FOLIO: 'COLLECTIONS_DEBTS_COLUMN_FOLIO',
      STATUS: 'COLLECTIONS_DEBTS_COLUMN_STATUS',
      MANAGEMENT: 'COLLECTIONS_DEBTS_COLUMN_MANAGEMENT',
      AMOUNT: 'COLLECTIONS_DEBTS_COLUMN_AMOUNT',
      ISSUE_DATE: 'COLLECTIONS_DEBTS_COLUMN_ISSUE_DATE',
      ISSUER: 'COLLECTIONS_DEBTS_COLUMN_ISSUER',
      FUND: 'COLLECTIONS_DEBTS_COLUMN_FUND',
      CUSTODIAN: 'COLLECTIONS_DEBTS_COLUMN_CUSTODIAN',
      EXPIRATION_DATE: 'COLLECTIONS_DEBTS_COLUMN_EXPIRATION_DATE',
      PROMISE_DATE: 'COLLECTIONS_DEBTS_COLUMN_PROMISE_DATE',
      MORA_DAYS: 'COLLECTIONS_DEBTS_COLUMN_MORA_DAYS',
      OPERATION_TYPE: 'COLLECTIONS_DEBTS_COLUMN_OPERATION_TYPE',
    },
    DEBTS_MANAGEMENTS_TOOLTIPS: {
      EXTENDED_DEBT: 'COLLECTIONS_DEBTS_MANAGEMENTS_TOOLTIP_EXTENDED_DEBT',
      JUDICIAL_ACTION: 'COLLECTIONS_DEBTS_MANAGEMENTS_TOOLTIP_JUDICIAL_ACTION',
    },
    MANAGEMENTS_HISTORY_LAST_MANAGEMENT:
      'COLLECTIONS_MANAGEMENTS_HISTORY_LAST_MANAGEMENT',
    MANAGEMENTS_HISTORY_LAST_CATEGORIZATION:
      'COLLECTIONS_MANAGEMENTS_HISTORY_LAST_CATEGORIZATION',
    MANAGEMENTS_HISTORY_COLUMNS: {
      REGISTER: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_REGISTER',
      CHANNEL: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_CHANNEL',
      REGISTERED_ON: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_REGISTERED_ON',
      RESULT: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_RESULT',
      COMMENT: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_COMMENT',
      USER: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_USER',
      DATE: 'COLLECTIONS_MANAGEMENTS_COLUMN_HISTORY_DATE',
    },
    MANAGEMENTS_TABLE: {
      ROWS_PER_PAGE: 'COLLECTIONS_MANAGEMENTS_TABLE_ROWS_PER_PAGE',
      MANAGEMENTS_NOT_FOUND: 'COLLECTIONS_MANAGEMENTS_TABLE_NOT_FOUND',
      NO_MORE_PAGES: 'COLLECTIONS_MANAGEMENTS_TABLE_NO_MORE_PAGES',
      MANAGEMENTS_LAST_MANAGEMENT:
        'COLLECTIONS_MANAGEMENTS_TABLE_LAST_MANAGEMENT',
      MANAGEMENTS_LAST_CATEGORIZATION:
        'COLLECTIONS_MANAGEMENTS_TABLE_LAST_CATEGORIZATION',
    },
    MANAGEMENTS_TYPES: {
      PRE_EXPIRATION: 'COLLECTIONS_MANAGEMENTS_TYPE_PRE_EXPIRATION',
      FOLLOW_UP: 'COLLECTIONS_MANAGEMENTS_TYPE_FOLLOW_UP',
      DEBT_PORTAL: 'COLLECTIONS_MANAGEMENTS_TYPE_DEBT_PORTAL',
      PAYMENT_PLAN: 'COLLECTIONS_MANAGEMENTS_TYPE_PAYMENT_PLAN',
      INVOICE_PROBLEMS: 'COLLECTIONS_MANAGEMENTS_TYPE_INVOICE_PROBLEMS',
      INTERNAL: 'COLLECTIONS_MANAGEMENTS_TYPE_INTERNAL',
      POSSIBLE_FRAUD: 'COLLECTIONS_MANAGEMENTS_TYPE_POSSIBLE_FRAUD',
      REASSIGNMENT: 'COLLECTIONS_MANAGEMENTS_TYPE_REASSIGNMENT',
      CONTACTABILITY: 'COLLECTIONS_MANAGEMENTS_TYPE_CONTACTABILITY',
      PAYMENT_INTENTION: 'COLLECTIONS_MANAGEMENTS_TYPE_PAYMENT_INTENTION',
      PAYMENT_CAPACITY: 'COLLECTIONS_MANAGEMENTS_TYPE_PAYMENT_CAPACITY',
      RECAP: 'COLLECTIONS_MANAGEMENTS_TYPE_RECAP',
    },
    MANAGEMENTS_SUBTYPES: {
      EMAIL: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_EMAIL',
      SMS: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_SMS',
      WHATSAPP: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_WHATSAPP',
      PHONE_CALL: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_PHONE_CALL',
      VISIT: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_VISIT',
      MEETING: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_MEETING',
      BURO: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_BURO',
      COBRANZA_ONLINE: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_COBRANZA_ONLINE',
      DICOM: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_DICOM',
      SLACK_SII: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_SLACK_SII',
      COLLECTIONS_CHILE: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_COLLECTIONS_CHILE',
      COLLECTIONS_MEXICO: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_COLLECTIONS_MEXICO',
      NORMALIZATION: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_NORMALIZATION',
      JUDICIAL_ACTION: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_JUDICIAL_ACTION',
      EXTERNAL_COLLECTION:
        'COLLECTIONS_MANAGEMENTS_SUBTYPE_EXTERNAL_COLLECTION',
      CONCILIATION: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_CONCILIATION',
      CATEGORIZATION: 'COLLECTIONS_MANAGEMENTS_SUBTYPE_CATEGORIZATION',
    },
    MANAGEMENTS_RESULTS: {
      PENDING: 'COLLECTIONS_MANAGEMENTS_RESULTS_COLLECTIONS_PENDING',
      LOW_CONTACTABILITY: 'COLLECTIONS_MANAGEMENTS_RESULTS_LOW_CONTACTABILITY',
      MEDIUM_CONTACTABILITY:
        'COLLECTIONS_MANAGEMENTS_RESULTS_MEDIUM_CONTACTABILITY',
      HIGH_CONTACTABILITY:
        'COLLECTIONS_MANAGEMENTS_RESULTS_HIGH_CONTACTABILITY',
      LOW_PAYMENT_INTENTION:
        'COLLECTIONS_MANAGEMENTS_RESULTS_LOW_PAYMENT_INTENTION',
      MEDIUM_PAYMENT_INTENTION:
        'COLLECTIONS_MANAGEMENTS_RESULTS_MEDIUM_PAYMENT_INTENTION',
      HIGH_PAYMENT_INTENTION:
        'COLLECTIONS_MANAGEMENTS_RESULTS_HIGH_PAYMENT_INTENTION',
      LOW_PAYMENT_CAPACITY:
        'COLLECTIONS_MANAGEMENTS_RESULTS_LOW_PAYMENT_CAPACITY',
      MEDIUM_PAYMENT_CAPACITY:
        'COLLECTIONS_MANAGEMENTS_RESULTS_MEDIUM_PAYMENT_CAPACITY',
      HIGH_PAYMENT_CAPACITY:
        'COLLECTIONS_MANAGEMENTS_RESULTS_HIGH_PAYMENT_CAPACITY',
      UNKNOWN: 'COLLECTIONS_MANAGEMENTS_RESULTS_UNKNOWN',
      ONLY_SENT: 'COLLECTIONS_MANAGEMENTS_RESULTS_ONLY_SENT',
      WITH_CONTACT: 'COLLECTIONS_MANAGEMENTS_RESULTS_WITH_CONTACT',
      CONTACT_WITH_OTHERS:
        'COLLECTIONS_MANAGEMENTS_RESULTS_CONTACT_WITH_OTHERS',
      NO_CONTACT: 'COLLECTIONS_MANAGEMENTS_RESULTS_NO_CONTACT',
      INVALID_ADDRESS: 'COLLECTIONS_MANAGEMENTS_RESULTS_INVALID_ADDRESS',
      POSTED: 'COLLECTIONS_MANAGEMENTS_RESULTS_POSTED',
      UNPOSTED: 'COLLECTIONS_MANAGEMENTS_RESULTS_UNPOSTED',
      TO_POST: 'COLLECTIONS_MANAGEMENTS_RESULTS_TO_POST',
      IN_PROCESS: 'COLLECTIONS_MANAGEMENTS_RESULTS_IN_PROCESS',
      COMPLIANCE: 'COLLECTIONS_MANAGEMENTS_RESULTS_COMPLIANCE',
      NO_COMPLIANCE: 'COLLECTIONS_MANAGEMENTS_RESULTS_NO_COMPLIANCE',
      CREDIT_NOTE: 'COLLECTIONS_MANAGEMENTS_RESULTS_CREDIT_NOTE',
      ANULATION: 'COLLECTIONS_MANAGEMENTS_RESULTS_ANULATION',
      CLAIMED: 'COLLECTIONS_MANAGEMENTS_RESULTS_CLAIMED',
      DISCOUNT: 'COLLECTIONS_MANAGEMENTS_RESULTS_DISCOUNT',
      PAYED_TO_STAKEHOLDER:
        'COLLECTIONS_MANAGEMENTS_RESULTS_PAYED_TO_STAKEHOLDER',
      SOLVED: 'COLLECTIONS_MANAGEMENTS_RESULTS_SOLVED',
      TO_BE_SENT: 'COLLECTIONS_MANAGEMENTS_RESULTS_TO_BE_SENT',
      SEND_AND_KEEP: 'COLLECTIONS_MANAGEMENTS_RESULTS_SEND_AND_KEEP',
      SEND_AND_TAKE: 'COLLECTIONS_MANAGEMENTS_RESULTS_SEND_AND_TAKE',
    },
    UPDATE_PAYER_CATEGORIZATION: 'COLLECTIONS_UPDATE_PAYER_CATEGORIZATION',
    UPDATE_RECAP: 'COLLECTIONS_UPDATE_RECAP',
    MORE_ACTIONS: 'COLLECTIONS_MORE_ACTIONS',
    PAYER_CATEGORIZATION: 'COLLECTIONS_PAYER_CATEGORIZATION',
    CONTACTABILITY_DESCRIPTION: 'COLLECTIONS_CONTACTABILITY_DESCRIPTION',
    PAYMENT_CAPACITY_DESCRIPTION: 'COLLECTIONS_PAYMENT_CAPACITY_DESCRIPTION',
    PAYMENT_INTENTION_DESCRIPTION: 'COLLECTIONS_PAYMENT_INTENTION_DESCRIPTION',
    LEVEL: 'COLLECTIONS_LEVEL',
    COMMENTARY: 'COLLECTIONS_COMMENTARY',
    TOOLTIP_CONTACTABILITY: 'COLLECTIONS_TOOLTIP_CONTACTABILITY',
    TOOLTIP_PAYMENT_CAPACITY: 'COLLECTIONS_TOOLTIP_PAYMENT_CAPACITY',
    TOOLTIP_PAYMENT_INTENTION: 'COLLECTIONS_TOOLTIP_PAYMENT_INTENTION',
    OPTIONAL: 'COLLECTIONS_OPTIONAL',
    RECAP_DESCRIPTION: 'COLLECTIONS_RECAP_DESCRIPTION',
    CANCEL: 'COLLECTIONS_CANCEL',
    CONFIRM: 'COLLECTIONS_CONFIRM',
    FILTERING_BY_EXPIRATION_DATE: 'COLLECTIONS_FILTERING_BY_EXPIRATION_DATE',
    FILTERING_BY_EXTENSION_DATE: 'COLLECTIONS_FILTERING_BY_EXTENSION_DATE',
    FILTERING_BY_AMOUNT_INVOICE: 'COLLECTIONS_FILTERING_BY_AMOUNT_INVOICE',
    FILTERING_BY_OPERATION_ID: 'COLLECTIONS_FILTERING_BY_OPERATION_ID',
    FILTERING_BY_CUSTODIAN: 'COLLECTIONS_FILTERING_BY_CUSTODIAN',
    ORDER_BY: 'COLLECTIONS_ORDER_BY',
  },
  BANKACCOUNT_NUMBER: 'LABEL_BANKACCOUNT_NUMBER',
};
