import { useQuery } from 'react-query';

import { fetchTransferAccountsService } from '../services';

const useFetchTransferAccounts = orderId => {
  const {
    data,
    refetch: refetchTransferAccounts,
    isLoading: fetchTransferAccountsIsLoading,
  } = useQuery(
    ['fetchTransferAccounts', orderId],
    () => fetchTransferAccountsService(orderId),
    { enabled: !!orderId, refetchOnWindowFocus: false }
  );

  const transferAccounts = data || [];

  return {
    transferAccounts,
    refetchTransferAccounts,
    fetchTransferAccountsIsLoading,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchTransferAccounts };
