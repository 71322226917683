import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { checkAuth } from '../helpers/validation/auth';

const Can = props => {
  const { rules } = useSelector(state => state.auth);
  const { roles } = rules;
  const { perform, yes, no } = props;

  return checkAuth(roles, perform, rules) ? yes() : no();
};

Can.defaultProps = {
  no: () => null,
  perform: '',
};

Can.propTypes = {
  perform: PropTypes.string,
  yes: PropTypes.func.isRequired,
  no: PropTypes.func,
};

export default Can;
