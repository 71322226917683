import axios from 'axios';
import { CONFIRMING, UNKNOWN_ERROR } from '../../../../helpers/Constants';

const cessionActionService = async (
  selectedInvoices,
  cessionaryId,
  businessId
) => {
  try {
    const invoiceIds = selectedInvoices?.map(invoice => invoice.invoiceId);
    return await axios.post('/api/cession', {
      invoiceIds,
      cessionaryId,
      businessId,
      orderType: CONFIRMING,
    });
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { cessionActionService };
