import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import BaseDialog from './BaseDialog';
import LoadingButton from '../elements/LoadingButton';
import AlertForm from '../elements/AlertForm';

const useStyles = makeStyles({
  validatorFormPaddingTop: {
    paddingTop: 5,
  },
  buttonSpacing: {
    marginTop: 15,
  },
});

const UpdatePasswordDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  userInformation,
}) => {
  const classes = useStyles();
  const { updateUserIsLoading, updateUserError } = useSelector(
    state => state.user
  );
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    ValidatorForm.addValidationRule('isEqual', () => {
      return (
        password &&
        confirmPassword &&
        password.trim() === confirmPassword.trim()
      );
    });
  });

  const handleBuildSubmit = () => {
    const { id } = userInformation;
    const buildPassword = { password: password.trim() };
    handleSubmit(buildPassword, id);
  };

  return (
    <BaseDialog
      title="Cambiar contraseña"
      description={`Vas a cambiar la contraseña para ${userInformation.name}`}
      isOpen={open}
      handleClose={handleCloseDialog}
    >
      <ValidatorForm
        onSubmit={handleBuildSubmit}
        className={classes.validatorFormPaddingTop}
      >
        <TextValidator
          fullWidth
          variant="outlined"
          label="Nueva contraseña"
          type="password"
          id="password"
          name="password"
          aria-describedby="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          validators={['required', 'minStringLength:5']}
          errorMessages={[
            'Campo requerido',
            'La contraseña debe tener 5 o más caracteres',
          ]}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          label="Confirma la nueva contraseña"
          type="password"
          id="repeatPassword"
          name="repeatPassword"
          aria-describedby="repeatPassword"
          value={confirmPassword}
          onChange={e => {
            setConfirmPassword(e.target.value);
          }}
          validators={['required', 'isEqual']}
          errorMessages={['Campo requerido', 'Las contraseñas no coinciden']}
        />

        {updateUserError && (
          <AlertForm message={updateUserError} variant="error" />
        )}

        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          isLoading={updateUserIsLoading}
          disabled={updateUserIsLoading}
          className={classes.buttonSpacing}
        >
          Cambiar contraseña
        </LoadingButton>
      </ValidatorForm>
    </BaseDialog>
  );
};

UpdatePasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userInformation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpdatePasswordDialog;
