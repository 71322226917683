import PaymentInboxZustandStore from './PaymentInboxZustandStore';

const usePage = () => PaymentInboxZustandStore(state => state.page);
const useItemsPerPage = () =>
  PaymentInboxZustandStore(state => state.itemsPerPage);

const useSetPage = () => PaymentInboxZustandStore(state => state.setPage);
const useGoNextPage = () => PaymentInboxZustandStore(state => state.goNextPage);
const useGoPrevPage = () => PaymentInboxZustandStore(state => state.goPrevPage);
const useSetItemsPerPage = () =>
  PaymentInboxZustandStore(state => state.setItemsPerPage);

const useSetPayrolls = () =>
  PaymentInboxZustandStore(state => state.setPayrolls);

const useSelectedPayrolls = () =>
  PaymentInboxZustandStore(state => state.selectedPayrolls);
const useSetSelectedPayrolls = () =>
  PaymentInboxZustandStore(state => state.setSelectedPayrolls);
const useAppendSelectedPayroll = () =>
  PaymentInboxZustandStore(state => state.appendSelectedPayroll);
const useRemoveSelectedPayroll = () =>
  PaymentInboxZustandStore(state => state.removeSelectedPayroll);
const useClearSelectedPayrolls = () =>
  PaymentInboxZustandStore(state => state.clearSelectedPayrolls);

const useGetFilters = () => PaymentInboxZustandStore(state => state.getFilters);
const useFilters = () => PaymentInboxZustandStore(state => state.filters);

const useStatusFilter = () =>
  PaymentInboxZustandStore(state => state.filters.status);
const useSetStatusFilter = () =>
  PaymentInboxZustandStore(state => state.setStatusFilter);

const useFieldFilter = () =>
  PaymentInboxZustandStore(state => state.filters.field);
const useSetFieldFilter = () =>
  PaymentInboxZustandStore(state => state.setFieldFilter);

const useAgreementFilter = () =>
  PaymentInboxZustandStore(state => state.filters.agreement);
const useSetAgreementFilter = () =>
  PaymentInboxZustandStore(state => state.setAgreementFilter);

const useIssuedDateFilter = () =>
  PaymentInboxZustandStore(state => state.filters.issuedDate);
const useSetIssuedDateFilter = () =>
  PaymentInboxZustandStore(state => state.setIssuedDateFilter);

const usePaymentTypeFilter = () =>
  PaymentInboxZustandStore(state => state.filters.paymentType);
const useSetPaymentTypeFilter = () =>
  PaymentInboxZustandStore(state => state.setPaymentTypeFilter);

const usePaymentCesionFilter = () =>
  PaymentInboxZustandStore(state => state.filters.paymentCesion);
const useSetPaymentCesionFilter = () =>
  PaymentInboxZustandStore(state => state.setPaymentCesionFilter);

const useResetFilters = () =>
  PaymentInboxZustandStore(state => state.resetFilters);

export {
  usePage,
  useItemsPerPage,
  useSetPage,
  useGoNextPage,
  useGoPrevPage,
  useSetItemsPerPage,
  useSetPayrolls,
  useSelectedPayrolls,
  useSetSelectedPayrolls,
  useAppendSelectedPayroll,
  useRemoveSelectedPayroll,
  useClearSelectedPayrolls,
  useGetFilters,
  useFilters,
  useStatusFilter,
  useSetStatusFilter,
  useFieldFilter,
  useSetFieldFilter,
  useAgreementFilter,
  useSetAgreementFilter,
  useIssuedDateFilter,
  useSetIssuedDateFilter,
  usePaymentTypeFilter,
  useSetPaymentTypeFilter,
  usePaymentCesionFilter,
  useSetPaymentCesionFilter,
  useResetFilters,
};
