import {
  Accordion as MaterialAccordion,
  AccordionDetails,
  AccordionSummary as MaterialAccordionSummary,
  Grid,
  styled,
} from '@material-ui/core';
import { Text } from '../../../commons/formatters';

const DetailsGrid = styled(Grid)({
  background: '#FFFFFF',
  padding: 24,
  borderRadius: 16,
});

const AccordionTitleGrid = styled(Grid)({
  marginRight: 16,
});

const Accordion = styled(MaterialAccordion)({
  margin: '0 !important',
});

const AccordionSummary = styled(MaterialAccordionSummary)({
  flexDirection: 'row-reverse',
});

const TitleText = styled(Text)({
  fontSize: 18,
});

const ActionsGrid = styled(Grid)(({ theme }) => ({
  paddingTop: 22,
  borderTop: `1px solid ${theme.palette.payments.grey4}`,
}));

const FundFinancingWrapper = styled(Grid)({
  height: '100%',
});

const FundFinancingGridItem = styled(Grid)({
  marginBottom: 16,
});

const FundFinancingText = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey2,
}));

const DebtAlertGridContainer = styled(Grid)({
  marginBottom: 24,
});

const CustomAccordionDetails = styled(AccordionDetails)({
  flexDirection: 'column',
});

export {
  DetailsGrid,
  AccordionTitleGrid,
  Accordion,
  AccordionSummary,
  TitleText,
  ActionsGrid,
  FundFinancingWrapper,
  FundFinancingGridItem,
  FundFinancingText,
  DebtAlertGridContainer,
  CustomAccordionDetails,
};
