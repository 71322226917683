import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { OpportunitiesContext } from './OpportunitiesContext';
import { SummaryBar } from '../../ui/Opportunities/SummaryBar';
import { VIEW_TYPES } from './constants';
import { useGetReceiverForm } from '../../domains/receiver/state';
import { TABLE_OPTIONS } from '../../../commons/constants/ui';
import { ReceiverDetailDrawer } from '../../ui/Opportunities/Documents/ReceiverDetailDrawer';
import { DocumentDetailDrawer } from '../../ui/Opportunities/Documents/DocumentDetailDrawer';

export const OpportunitiesContextProvider = ({ children }) => {
  const location = useLocation();
  const [viewType, setViewType] = useState(VIEW_TYPES.RECEIVER);
  const [openReceiverDrawer, setOpenReceiverDrawer] = useState({
    open: false,
    receiverIdentifier: null,
  });
  const [openDocumentDrawer, setOpenDocumentDrawer] = useState({
    open: false,
    documentId: null,
  });
  const isAssignView = location.pathname.includes('creation');
  const receiverForm = useGetReceiverForm();
  const [reqFilters, setReqFilters] = useState({
    ...receiverForm,
    limit: TABLE_OPTIONS.pageSizeOptions[0],
  });

  const changeToDocumentView = useCallback(() => {
    setViewType(VIEW_TYPES.DOCUMENT);
  }, []);

  const changeToReceiverView = useCallback(() => {
    setViewType(VIEW_TYPES.RECEIVER);
  }, []);

  const value = useMemo(
    () => ({
      changeToDocumentView,
      changeToReceiverView,
      reqFilters,
      setOpenDocumentDrawer,
      setOpenReceiverDrawer,
      setReqFilters,
      viewType,
    }),
    [
      changeToDocumentView,
      changeToReceiverView,
      reqFilters,
      setOpenDocumentDrawer,
      setOpenReceiverDrawer,
      setReqFilters,
      viewType,
    ]
  );

  return (
    <OpportunitiesContext.Provider value={value}>
      {children}
      {!isAssignView && <SummaryBar />}
      <ReceiverDetailDrawer
        open={openReceiverDrawer.open}
        receiverIdentifier={openReceiverDrawer?.receiverIdentifier}
        onClose={() =>
          setOpenReceiverDrawer({ open: false, receiverIdentifier: null })
        }
      />
      <DocumentDetailDrawer
        onClose={() => setOpenDocumentDrawer({ open: false, documentId: null })}
        open={openDocumentDrawer.open}
        payrollInvoiceId={openDocumentDrawer.documentId}
      />
    </OpportunitiesContext.Provider>
  );
};

OpportunitiesContextProvider.defaultProps = {
  children: null,
};

OpportunitiesContextProvider.propTypes = {
  children: PropTypes.node,
};
