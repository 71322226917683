import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { calculateDebtPartialPaymentService } from '../services';

export const useCalcultateDebtPartialPayment = ({ sourceType, sourceId }) => {
  const dispatch = useDispatch();
  const placeholderData = () => {
    return {
      baseRate: null,
      debtInterestAtDate: null,
      segment: null,
      totalCapitalDebt: null,
      totalDebt: null,
      payedInterests: null,
    };
  };
  const {
    data: debtPartialPaymentServiceData,
    refetch: refetchCalculateDebtPartialPayment,
    isLoading: calculateDebtPartialPaymentIsLoading,
  } = useQuery(
    'calculateDebtPartialPayment',
    () => calculateDebtPartialPaymentService(sourceType, sourceId),
    {
      placeholderData,
      refetchOnWindowFocus: false,
      enabled: false,
      onError: () => {
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE,
            {
              variant: 'error',
            }
          )
        );
      },
    }
  );

  return {
    refetchCalculateDebtPartialPayment,
    calculateDebtPartialPaymentIsLoading,
    debtPartialPaymentServiceData,
  };
};

export default useCalcultateDebtPartialPayment;
