import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { downloadFile } from '../../../../helpers/fileHandling';
import { t13s } from '../../../../translationKeys';
import { fetchGlobalInvoicePdfService } from '../services';

export const useFetchGlobalInvoicePdf = id => {
  const dispatch = useDispatch();
  const { refetch: refetchPdfGlobalCl, isLoading: fetchPdfGlobalClIsLoading } =
    useQuery('getPdfGlobalCl', () => fetchGlobalInvoicePdfService(id), {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: response => {
        const { pdf } = response.data;
        if (pdf) {
          downloadFile(pdf, id);
        } else {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.FILE_NOT_EXIST, {
              variant: 'error',
            })
          );
        }
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
            variant: 'error',
          })
        );
      },
    });

  return {
    refetchPdfGlobalCl,
    fetchPdfGlobalClIsLoading,
  };
};

export default useFetchGlobalInvoicePdf;
