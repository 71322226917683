import React from 'react';
import { Box } from '@material-ui/core';
import PayrollCard from './PayrollCard';
import Loader from '../../../../components/elements/Loader';

interface IPayrollCards {
  payrolls: Object[];
  payrollsAreLoading: boolean;
}

const PayrollCards = ({ payrolls, payrollsAreLoading }: IPayrollCards) => {
  if (payrollsAreLoading) return <Loader />;

  return (
    <Box>
      {payrolls.map(payroll => (
        <PayrollCard payroll={payroll} />
      ))}
    </Box>
  );
};

export default PayrollCards;
