import { Grid, styled, Switch } from '@material-ui/core';
import { Text } from '../../../../../commons/formatters';

const TitleGrid = styled(Grid)(({ isEditable }) => ({
  marginRight: isEditable ? 14 : 0,
}));

const CustomSwitch = styled(Switch)({
  marginRight: 10,
});

const CustomText = styled(Text)(({ isActive, theme }) => ({
  color: isActive
    ? theme.palette.primary.main
    : theme.palette.payments.inactive,
}));

export { TitleGrid, CustomSwitch, CustomText };
