import React, { ReactNode, FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const CommentsPendingIcon: FC<Props> = ({
  color = palette.primary.main,
  style,
}) => {
  return (
    <SvgIcon
      style={{
        color,
        ...style,
      }}
      viewBox="0 0 26 26"
    >
      <path
        d="M20.99 4V4.00056L20.9987 19.5844L18.7071 17.2929L18.4142 17H18H4C3.45228 17 3 16.5477 3 16V4C3 3.45228 3.45228 3 4 3H20C20.5412 3 20.99 3.44575 20.99 4Z"
        stroke="#1A49E7"
        strokeWidth="2"
        fill="#fff"
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="16.5"
        y="0.5"
        width="9"
        height="9"
        rx="4.5"
        fill="#E0584F"
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="16.5"
        y="0.5"
        width="9"
        height="9"
        rx="4.5"
        stroke="#fff"
        fill="red"
      />
    </SvgIcon>
  );
};

export default CommentsPendingIcon;
