import React, { memo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

import ActionBar from '../../../../../components/elements/ActionBar';
import ActionBarIcon from '../../../../../components/elements/ActionBarIcon';
import { Delete, Edit } from '../../../../../components/icons';
import {
  usePayrollActions,
  usePayrollSelector,
  useSelectedPayroll,
} from '../../../adapters/store/payroll';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import ConfirmDialog from '../../../../../components/dialogs/ConfirmDialog';
import { ChangeStatusPayrollInvoiceDialog } from './ChangeStatusPayrollInvoiceDialog/ChangeStatusPayrollInvoiceDialog';
import { useStyles } from './FloatingActionBar.styles';
import {
  useUpdatePayrollInvoiceStatus,
  useDeleteInvoices,
  FETCH_INVOICES_KEY,
  useFetchInvoices,
} from '../../../domains/invoice/services';
import { FETCH_RECEIVERS_KEY } from '../../../domains/receiver/services';
import { ERROR_CODES } from '../../../../commons/constants/main';

// eslint-disable-next-line react/prop-types
const SummaryBar = memo(({ title, subTitle }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item alignItems="center" style={{ display: 'flex' }}>
        <Typography variant="body1">{subTitle}</Typography>
      </Grid>
    </Grid>
  );
});

export const FloatingActionBar = ({
  setIsDeleting,
  setIsBulkUpdating,
  businessStatus,
  invoiceForm,
}) => {
  const css = useStyles();
  const { cleanPayrollSelector, showSnackBar, fetchPayroll } =
    usePayrollActions();
  const { summary } = usePayrollSelectorController();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const payrollSelector = usePayrollSelector();
  const selectedPayroll = useSelectedPayroll();
  const { invoices = [] } = useFetchInvoices({
    ...{ businessStatus },
    ...invoiceForm,
  });

  const queryClient = useQueryClient();
  const { mutateAsync: updatePayrollInvoiceStatus } =
    useUpdatePayrollInvoiceStatus({
      onSuccess: response => {
        queryClient.refetchQueries([FETCH_INVOICES_KEY], { stale: true });
        queryClient.refetchQueries([FETCH_RECEIVERS_KEY], { stale: true });
        setIsBulkUpdating(false);
        cleanPayrollSelector();
        showSnackBar({
          msg: 'Estado cambiado con éxito',
          type: 'success',
        });
        if (
          response.payrollInvoices.some(
            invoice => invoice.status === 'ASSIGNED'
          )
        ) {
          fetchPayroll();
        }
      },
      onError: res => {
        const defaultMsg = 'No se pudo cambiar el estado';
        setIsBulkUpdating(false);
        showSnackBar({
          msg: ERROR_CODES[res.response.data?.errorCode]?.message || defaultMsg,
          type: 'error',
        });
      },
    });

  const { mutateAsync: deleteInvoices } = useDeleteInvoices({
    onSuccess: () => {
      queryClient.refetchQueries([FETCH_INVOICES_KEY], { stale: true });
      queryClient.refetchQueries([FETCH_RECEIVERS_KEY], { stale: true });
      setIsDeleting(false);
      cleanPayrollSelector();
      fetchPayroll();
      showSnackBar({
        msg: `Se han eliminado ${summary.totalInvoices} facturas`,
        type: 'success',
      });
    },
    onError: () => {
      setIsBulkUpdating(false);
      showSnackBar({
        msg: 'No se ha podido eliminar la selección',
        type: 'error',
      });
    },
  });

  const onDeleteSubmit = () => {
    setShowConfirmation(false);
    setIsDeleting(true);
    deleteInvoices();
  };

  const onUpdateSubmit = payrollInvoiceStatus => {
    setIsBulkUpdating(true);
    updatePayrollInvoiceStatus({
      payrollInvoiceStatus,
    });

    setShowUpdateConfirmation(false);
  };

  const onClose = () => {
    cleanPayrollSelector();
  };

  let payrollInvoicesId = [];
  Object.keys(payrollSelector).forEach(receiver => {
    payrollInvoicesId = [
      ...payrollInvoicesId,
      ...new Set(payrollSelector[receiver].value),
    ];
  });
  const payrollInvoicesStatus = [
    ...new Set(
      invoices
        .filter(invoice => payrollInvoicesId.includes(invoice.id))
        .map(filteredInvoice => filteredInvoice.status)
    ),
  ];
  const closedEditCondition =
    selectedPayroll?.closedAt &&
    payrollInvoicesStatus?.some(status => status !== 'ASSIGNED');

  return (
    <>
      <ActionBar
        message={
          <SummaryBar
            title={`${summary.totalReceivers} pagadores`}
            subTitle={`(${summary.totalInvoices} facturas seleccionadas)`}
          />
        }
        resetSelectedRows={onClose}
        show={!!Object.keys(payrollSelector)?.length}
      >
        <ActionBarIcon
          disabled={closedEditCondition}
          className={css.ActionBarIcon}
          IconComponent={Edit}
          onClick={() => setShowUpdateConfirmation(true)}
        />
        <ActionBarIcon
          className={css.ActionBarIcon}
          IconComponent={Delete}
          onClick={() => setShowConfirmation(true)}
        />
      </ActionBar>
      {showConfirmation && (
        <ConfirmDialog
          isOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
          title="Eliminar facturas"
          message={`¿Estás seguro que deseas eliminar ${summary.totalInvoices} facturas de la nómina?`}
          buttonLabel="Eliminar"
          buttonOnAccept={onDeleteSubmit}
        />
      )}
      {showUpdateConfirmation && (
        <ChangeStatusPayrollInvoiceDialog
          open={showUpdateConfirmation}
          handleCloseDialog={() => setShowUpdateConfirmation(false)}
          title={`Editar estado${summary.totalInvoices > 1 ? 's' : ''}`}
          handleSubmit={onUpdateSubmit}
        />
      )}
    </>
  );
};

FloatingActionBar.propTypes = {
  setIsBulkUpdating: PropTypes.func.isRequired,
  setIsDeleting: PropTypes.func.isRequired,
  businessStatus: PropTypes.string.isRequired,
  invoiceForm: PropTypes.shape({}).isRequired,
};
