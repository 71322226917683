import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, makeStyles } from '@material-ui/core';
import {
  fetchReports,
  registerReport,
  updateReport,
  executeReport,
} from '../actions/reportActions';
import { Add } from './icons';
import { REPORTS_ADD_PERFORM } from '../helpers/performsType';
import Can from './Can';
import LoaderComponent from './elements/LoaderComponent';
import ReportFormDialog from './elements/ReportFormDialog';
import ReportParamDialog from './elements/ReportParamDialog';
import { DRAWER_BACKGROUND } from '../theme/otherColors';
import Panel from './elements/Panel';
import ReportsTable from './tables/ReportsTable';
import usePagination from '../hooks/pagination';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  button: {
    marginRight: '20px',
  },
  drawerPaper: {
    width: '100%',
    background: DRAWER_BACKGROUND,
  },
});

const HomeReports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    reports,
    fetchReportsIsLoading,
    pagination,
    updateReportIsLoading,
    registerReportIsLoading,
    reportWasRegistered,
  } = useSelector(state => state.report);
  const { updatePageAndLimit } = usePagination(
    () => {},
    pagination.page,
    pagination.pageSize
  );
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showParamDialog, setShowParamDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [reportSelected, setReportSelected] = useState(null);

  useEffect(() => {
    dispatch(fetchReports({ page: 1, limit: 20 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoading = updateReportIsLoading;
    setShowLoader(handleLoading);
  }, [updateReportIsLoading]);

  useEffect(() => {
    if (reportWasRegistered) setShowReportDialog(false);
  }, [reportWasRegistered]);

  const handleSubmitUpdateReport = reportData => {
    setShowReportDialog(false);
    dispatch(updateReport(reportData));
  };

  const handleSubmitRegisterReport = reportData => {
    dispatch(registerReport(reportData));
  };

  const handleSubmitDownloadReport = reportData => {
    dispatch(executeReport(reportData));
  };

  const handleEditReportDialog = reportData => {
    setShowReportDialog(true);
    setReportSelected(reportData);
  };

  const handleParamDialog = reportData => {
    setReportSelected(reportData);
    setShowParamDialog(true);
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Can
            perform={REPORTS_ADD_PERFORM}
            yes={() => (
              <Button
                onClick={() => setShowReportDialog(true)}
                startIcon={<Add />}
                color="primary"
                variant="outlined"
              >
                Nuevo reporte
              </Button>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel title="Lista de reportes" contentPadding="zero">
            <ReportsTable
              tableData={reports}
              loadingReports={fetchReportsIsLoading}
              handleChangePage={updatePageAndLimit}
              pagination={pagination}
              handleEditReportDialog={handleEditReportDialog}
              handleParamDialog={handleParamDialog}
            />
          </Panel>
        </Grid>
      </Grid>

      {showReportDialog && (
        <ReportFormDialog
          open
          handleCloseDialog={() => {
            setShowReportDialog(false);
            setReportSelected(null);
          }}
          handleSubmitForm={
            reportSelected
              ? handleSubmitUpdateReport
              : handleSubmitRegisterReport
          }
          report={reportSelected}
          showLoader={registerReportIsLoading}
        />
      )}
      {showParamDialog && (
        <ReportParamDialog
          open
          handleCloseDialog={() => {
            setShowParamDialog(false);
            setReportSelected(null);
          }}
          handleSubmitForm={handleSubmitDownloadReport}
          report={reportSelected}
          showLoader={registerReportIsLoading}
        />
      )}
      {showLoader && (
        <LoaderComponent
          open={showLoader}
          handleCloseDialog={() => setShowLoader(false)}
        />
      )}
    </>
  );
};

export default HomeReports;
