import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useScrollStyles = makeStyles({
  container: {
    'flex-wrap': 'nowrap',
    'overflow-x': 'auto',
    display: 'flex',
    width: '100%',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&> *': {
      flex: '0 0 auto',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  wrapper: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      background: '#fff',
      right: '0',
      top: 0,
      bottom: 0,
      width: '2px',
      opacity: 0.8,
    },
  },
});
const Scroll = props => {
  const classes = useScrollStyles();

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.container}>
        {props.children}
      </Grid>
    </div>
  );
};

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Scroll;
