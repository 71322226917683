import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys/index';
import { PayerCategorization } from '../../../commons/interfaces/payerCategorization';
import { bulkCreatePayerCategorizations } from '../services';

interface Props {
  payersIdentifiers: string[];
  categorizations: PayerCategorization[];
}

const useBulkCreatePayerCategorizations = (onSuccessAction: () => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    data,
    mutate: createPayerCategorizations,
    reset: resetDataAndErrors,
    isSuccess,
  } = useMutation((params: Props) => bulkCreatePayerCategorizations(params), {
    onError: (_err, _variables, context: unknown) => {
      dispatch(
        enqueueSnackbar(t13s.ERROR.ERROR_CREATE_BULK_MANAGEMENTS, {
          variant: 'error',
        })
      );
      queryClient.setQueryData(
        'getPayerCategorizationSummary',
        (context as any).data
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries('getPayerCategorizationSummary', {
        exact: false,
      });
      queryClient.invalidateQueries('getManagements', {
        exact: false,
      });
      queryClient.invalidateQueries('getNewestPayerCategorization', {
        exact: false,
      });
    },
    onSuccess: async () => {
      await queryClient.cancelQueries('getPayerCategorizationSummary', {
        exact: false,
      });
      const prevPayerCategorization = queryClient.getQueryData(
        'getPayerCategorizationSummary',
        {
          exact: false,
        }
      );
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.COLLECTION_PAYER_CATEGORIZATION_BULK_CREATED,
          {
            variant: 'success',
          }
        )
      );
      onSuccessAction();
      return prevPayerCategorization;
    },
  });

  return {
    isLoading,
    isError,
    data,
    isSuccess,
    createPayerCategorizations,
    resetDataAndErrors,
  };
};

export default useBulkCreatePayerCategorizations;
