import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from '@material-table/core';

import {
  TABLE_OPTIONS,
  FUNDS_BASE_PATH,
} from '../../../../commons/constants/ui';
import { useStyles } from './ReceiversList.styles';
import { useGetCountry } from '../../../../adapters/store';
import { DIALOG_TYPES, GET_COLUMNS } from './constants';
import { ALT_BORDER_COLOR } from '../../../../../theme/otherColors';
import { useDraftPayrollSelectorController } from '../../../domains/payroll/controllers/useDraftPayrollSelectorController';
import {
  useGetSelectedFund,
  useGetSimulation,
} from '../../../domains/payroll/state';
import { useReceiverActions } from '../../../domains/receiver/state';
import { FEATURES } from '../../../../commons/constants/features';
import { UpdateFundRateDialog } from '../UpdateFundRateDialog';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import { UpdateTaxEntityDialog } from './UpdateTaxEntityDialog';

const ReceiversListComponent = ({ changeToDocumentView }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const css = useStyles();
  const country = useGetCountry();
  const [openEditDialog, setOpenEditDialog] = useState();
  const [selectedReceiverToEdit, setSelectedReceiverToEdit] = useState();
  const simulation = useGetSimulation();
  const { selectDraftReceiver } = useReceiverActions();
  const { addReceiver, getReceivers } = useDraftPayrollSelectorController();
  const { editReceiver } = usePayrollSelectorController();
  const receivers = getReceivers();
  const selectedFund = useGetSelectedFund();
  const columns = GET_COLUMNS({
    changeToDocumentView,
    country,
    css,
    selectDraftReceiver,
    selectedFund,
    setOpenEditDialog,
    setSelectedReceiverToEdit,
    t,
    showPDFStatus: !!selectedFund?.entityFeatures?.find(
      feature => feature.name === FEATURES.INVOICE_HAS_PDF
    ),
  });

  useEffect(() => {
    if (!receivers?.length) {
      history.push(
        `/${country.toLowerCase()}/${FUNDS_BASE_PATH}/opportunities`
      );
    }
  }, [receivers]);

  return (
    <>
      <MaterialTable
        columns={columns}
        data={receivers}
        isLoading={simulation?.isLoading}
        style={{
          border: `1px solid ${ALT_BORDER_COLOR}`,
          borderRadius: 17,
          boxShadow: 'none',
          overflow: 'hidden',
          width: '100%',
        }}
        onSelectionChange={selectedRows => addReceiver(selectedRows, receivers)}
        components={{
          Toolbar: () => null,
          Pagination: () => null,
        }}
        options={{
          ...TABLE_OPTIONS,
          selectionProps: sp => {
            return {
              indeterminate: sp.tableData.indeterminate,
            };
          },
        }}
      />

      <UpdateFundRateDialog
        isOpen={openEditDialog === DIALOG_TYPES.RATE}
        handleCloseDialog={() => setOpenEditDialog(null)}
        title="Editar tasa"
        description={`Pagador ${selectedReceiverToEdit?.name}`}
        selectedRate={{ rate: selectedReceiverToEdit?.rate }}
        handleSubmit={newValue => {
          editReceiver(newValue, selectedReceiverToEdit.receiverIdentifier);
          setOpenEditDialog(null);
        }}
      />

      <UpdateTaxEntityDialog
        isOpen={openEditDialog === DIALOG_TYPES.DEBT_SERVICE_AMOUNT}
        handleCloseDialog={() => setOpenEditDialog(null)}
        description={`Pagador ${selectedReceiverToEdit?.name}`}
        selectedData={{
          receiverDebt: selectedReceiverToEdit?.receiverDebtServiceAmount,
        }}
        handleSubmit={newValue => {
          editReceiver(newValue, selectedReceiverToEdit.receiverIdentifier);
          setOpenEditDialog(null);
        }}
      />
    </>
  );
};

ReceiversListComponent.propTypes = {
  changeToDocumentView: PropTypes.func.isRequired,
};

export const ReceiversList = memo(ReceiversListComponent);
