import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { ManagementDebt } from '../../../commons/interfaces/managementDebts';

export const fetchManagementDebtByInvoice = async (orderInvoiceId: number): Promise<ManagementDebt[]> => {
  try {
      const { data } = await axios.get<ManagementDebt[]>(`/api/collection/debts/${orderInvoiceId}/managements`);
      return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    }
    throw new Error(UNKNOWN_ERROR);
  }
};

