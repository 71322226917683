export const b64toBlob = (b64Data, contentType = '') => {
  const sliceSize = 512;
  const parsedB64Data = b64Data.replace(/^[^,]+,/, '').replace(/\s/g, '');
  const byteCharacters = window.atob(parsedB64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const download = (file, filename) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = filename;
  link.click();
  link.remove();
};

export const downloadFile = (file, title = '') => {
  const getBlobFileExtension = fileExtension => {
    switch (fileExtension) {
      case 'pdf':
        return 'application/pdf';
      case 'xml':
        return 'application/xml';
      case 'vnd.ms-excel':
        return 'application/vnd.ms-excel';
      case 'octet-stream':
        return 'application/octet-stream';
      case 'msword':
        return 'application/msword';
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return `image/${fileExtension}`;
    }
  };
  const getNameFileExtension = fileExtension => {
    switch (fileExtension) {
      case 'vnd.ms-excel':
        return 'xls';
      case 'octet-stream':
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'msword':
        return 'doc';
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      default:
        return fileExtension;
    }
  };
  let blob;
  let extension = 'pdf';
  if (file.includes('base64')) {
    const fileData = file.split(';base64,');
    extension = fileData[0].split(/\//)?.[1];
    blob = b64toBlob(
      fileData[1].replace(/={1,2}$/, ''),
      getBlobFileExtension(extension)
    );
  } else {
    blob = b64toBlob(file, 'application/pdf');
  }
  const name = `${title}.${getNameFileExtension(extension)}`;
  return download(blob, name);
};
