import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useOrderAttributes,
  useUpdateOrderAttribute,
} from '../../../../infrastructure/store';

const menuItems = [
  { value: true, label: 'Con cesión' },
  { value: false, label: 'Sin cesión' },
];

const usePaymentCessionDialog = () => {
  const { id: orderId } = useParams();
  const { orderAttributes } = useOrderAttributes(orderId);
  const {
    orderAttributeIsUpdating,
    updateOrderAttributeIsSuccess,
    updateOrderAttribute,
    resetOrderAttributeUpdateData,
  } = useUpdateOrderAttribute();
  const { hasCesion } = orderAttributes;
  const [formHasCession, setFormHasCession] = useState(hasCesion);

  const handleSelect = e => {
    setFormHasCession(e.target.value);
  };

  const handleSubmit = () => {
    updateOrderAttribute({ orderId, attribute: { hasCesion: formHasCession } });
  };

  return {
    menuItems,
    formHasCession,
    orderAttributeIsUpdating,
    updateOrderAttributeIsSuccess,
    handleSelect,
    handleSubmit,
    resetOrderAttributeUpdateData,
  };
};

export default usePaymentCessionDialog;
