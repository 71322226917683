import React, { memo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OperationTabs from '../../../components/elements/OperationTabs';
import { convertSpacingToCss } from '../../../helpers/stylesHelpers';
import {
  PaymentHeader,
  PaymentFilters,
  PayrollCards,
  SelectedPayrollActionBar,
} from '../../inbox/ui';
import { Card, Pagination } from '../../commons/components';
import usePaymentInbox from './usePaymentInbox';
import EmptyStateCard from '../../inbox/ui/EmptyStateCard';

const useStyles = makeStyles({
  root: {
    padding: convertSpacingToCss('zero'),
  },
  container: {
    padding: convertSpacingToCss('lg'),
  },
  panelContainer: {
    padding: convertSpacingToCss('zero lg lg lg'),
  },
  selectedStatuses: {
    padding: convertSpacingToCss('zero lg sm'),
  },
});

const PaymentsInbox = memo(() => {
  const classes = useStyles();
  const {
    operationType,
    country,
    handleTabChange,
    payrolls,
    payrollsPagination,
    payrollsAreLoading,
    page,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
  } = usePaymentInbox();

  return (
    <Grid item xs={12} className={classes.root}>
      <OperationTabs
        value={operationType}
        country={country}
        onChange={handleTabChange}
      />
      <Grid item xs={12} className={classes.panelContainer}>
        <PaymentHeader />
        <Card>
          <PaymentFilters />
          {payrolls?.length === 0 && !payrollsAreLoading && <EmptyStateCard />}
          <SelectedPayrollActionBar />
          <PayrollCards
            payrolls={payrolls}
            payrollsAreLoading={payrollsAreLoading}
          />
        </Card>
        <Pagination
          page={page}
          itemsPerPage={itemsPerPage}
          payrollsPagination={payrollsPagination}
          handleChangePage={handleChangePage}
          handleChangeItemsPerPage={handleChangeItemsPerPage}
        />
      </Grid>
    </Grid>
  );
});

export default PaymentsInbox;
