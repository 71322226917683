import React, { useRef } from 'react';
import { Grid, styled } from '@material-ui/core';
import InvoicesHeader from './InvoicesHeader';
import InvoicesFilters from './InvoicesFilters';
import InvoicesTable from './InvoicesTable';
import HorizontalSeparator from '../../../../components/elements/HorizontalSeparator';
import useHandleInvoiceData from './InvoicesTable/useHandleInvoiceData';

const InvoicesGrid = styled(Grid)({
  background: '#FFFFFF',
  borderRadius: 16,
});

const PaddedGrid = styled(Grid)({
  padding: 24,
});

const TableGrid = styled(Grid)({
  paddingBottom: 24,
});

const PayrollInvoices = () => {
  const tableRef = useRef();

  const {
    invoices,
    handleRemoteData,
    isLoading,
    handleRowSelection,
    rowsPerPage,
    count,
    page,
    handleDropdown,
    handleSearch,
    handleAttributes,
    handleStatus,
    searchKey,
    invoiceStatus,
    invoicesStatuses,
    invoicesAttributes,
    dropDownFilters,
    dropdown,
    invoiceAttribute,
  } = useHandleInvoiceData(tableRef);

  return (
    <InvoicesGrid container>
      <PaddedGrid item xs={12}>
        <InvoicesHeader />
      </PaddedGrid>
      <HorizontalSeparator />
      <PaddedGrid item xs={12}>
        <InvoicesFilters
          searchKey={searchKey}
          dropdown={dropdown}
          invoiceStatus={invoiceStatus}
          invoicesStatuses={invoicesStatuses}
          invoiceAttribute={invoiceAttribute}
          invoicesAttributes={invoicesAttributes}
          dropDownFilters={dropDownFilters}
          handleSearch={handleSearch}
          handleDropdown={handleDropdown}
          handleAttributes={handleAttributes}
          handleStatus={handleStatus}
        />
      </PaddedGrid>
      <HorizontalSeparator />
      <TableGrid item xs={12}>
        <InvoicesTable
          tableRef={tableRef}
          invoices={invoices}
          isLoading={isLoading}
          handleRemoteData={handleRemoteData}
          handleRowSelection={handleRowSelection}
          rowsPerPage={rowsPerPage}
          count={count}
          page={page}
        />
      </TableGrid>
    </InvoicesGrid>
  );
};

PayrollInvoices.propTypes = {};

export default PayrollInvoices;
