import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState, memo } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';

import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';
import { DatePicker } from '../../funds/commons/components/DatePicker';

const useStyles = makeStyles({
  subTitle: {
    marginBottom: '20px',
  },
});

const minTerm = 0;
const rules = ['required', `minNumber:${minTerm}`];
const errorMsg = ['Campo requerido', `Plazo mínimo ${minTerm}`];
const validators = { rules, errorMsg };

const EditTermDialog = ({
  handleCloseDialog,
  handleReset,
  handleSubmit: handleSave,
  loading,
  open,
  subTitle,
  value,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [term, setTerm] = useState(value || '');

  useEffect(() => {
    setChecked(false);
  }, [setChecked]);

  useEffect(() => {
    if (!open) {
      setChecked(false);
      setTerm('');
    }
  }, [open]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = () => {
    return checked ? handleSave(term) : handleReset();
  };

  const onChange = dateForm => {
    setTerm(dateForm);
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Editar Plazo"
    >
      <Typography
        className={classes.subTitle}
        variant="body1"
        color="textPrimary"
        align="center"
        component="div"
      >
        {subTitle()}
        <br />
        Modifica el plazo colocando la fecha de vencimiento, se calcularán
        automáticamente los días de plazo.
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <DatePicker
          baseDate={value}
          onChange={onChange}
          validators={validators}
        />
        <Box mb={2} mt={2}>
          <CheckButton
            check={checked}
            handleCheck={handleCheckboxChange}
            labelButton="Guardar"
            loading={loading}
            disabled={!term}
          />
        </Box>
      </ValidatorForm>
    </BaseDialog>
  );
};

EditTermDialog.propTypes = {
  subTitle: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default memo(EditTermDialog);
