import React from 'react';

import { PayrollDetailContextProvider } from '../../context';
import { PayrollDetailLayout } from './PayrollDetailLayout';

export const PayrollDetail = () => {
  return (
    <PayrollDetailContextProvider>
      <PayrollDetailLayout />
    </PayrollDetailContextProvider>
  );
};
