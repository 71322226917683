import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import InfoIcon from '@material-ui/icons/Info';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import GetAppIcon from '../icons/GetApp';
import AlertForm from './AlertForm';
import TransferList from './TransferList';
import CountryFormatHelper from './CountryFormatHelper';
import Dropzone from './Dropzone/Dropzone';
import VerticalStepper from './VerticalStepper';
import {
  DATE_FORMAT,
  DF_LABEL,
  EP_LABEL,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
  CURRENCY,
} from '../../helpers/Constants';
import { features } from '../../config/features';
import { settings } from '../../config/settings';
import { dateWithoutFormatter } from '../../helpers/DateUtils';
import {
  validateIdentifier,
  rutCleaner,
} from '../../helpers/validation/businessIdentifier';
import { DRAWER_BACKGROUND } from '../../theme/otherColors';
import { t13s } from '../../translationKeys';
import {
  OPERATION_COST_PERCENTAGE_CHANGE,
  ORDER_RATES_PERCENTAGE_CHANGE,
} from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';
import { fixNumber, formatCurrencyValue } from '../../helpers/mathUtils';
import useGetCountry from '../../hooks/useGetCountry';

const useStyles = makeStyles(theme => ({
  containerInvoiceSelection: {
    paddingTop: 20,
  },
  containerTotalAmount: {
    marginRight: 45,
    marginTop: 15,
  },
  textTotalAmount: {
    marginRight: 5,
  },
  containerGeneralInformation: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  inputs: {
    paddingTop: 5,
    paddingRight: 5,
  },
  containerConditionsAndSummary: {
    paddingTop: 20,
  },
  titleOperationSummary: {
    paddingBottom: 20,
  },
  containerOperationSummary: {
    backgroundColor: DRAWER_BACKGROUND,
    padding: '25px 30px',
    borderRadius: 13,
  },
  itemOperationSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  containerCircularProgress: {
    paddingTop: 5,
    paddingLeft: 10,
  },
  containerCircularProgressLarge: {
    padding: '20px 90px',
  },
  containerInputs: {
    paddingRight: 10,
  },
  containerAlertForm: {
    paddingTop: 20,
  },
  templateButton: {
    textDecoration: 'none',
    marginTop: 5,
    color: theme.palette.text.secondary,
  },
  alertForm: {
    marginTop: 10,
  },
  autoRateInformation: {
    marginLeft: 5,
  },
  labelAutoRateBase: {
    fontWeight: 'bold',
  },
  labelAutoRateChecked: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  containerInformationText: {
    marginLeft: 10,
    marginTop: -8,
  },
  spacingInformationText: {
    margin: '0 4px',
  },
}));

const OperationUploadSteps = ({
  steps,
  isLastStep,
  handleSubmit,
  handleNextStep,
  handleValidateNextStep,
  isLoadingButton,
  buttonLabelFinish,
  onDropDirectFinanceCsv,
  onClientSelect,
  onSelectPayer,
  onSelectPayerNotEnrolled,
  CsvDirectFinanceTemplate,
  onCalculateFees,
  operation,
  submitError,
  operationType,
  setOperationType,
  handleStepBack,
  fetchClientByIdentifier,
  handleClientNotEnrolled,
  handleResetCsvToOrder,
  isDirectFinanceByCsv,
  setIsDirectFinanceByCsv,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const country = useGetCountry();
  const countryFeatures = features[country];
  const countrySettings = settings[country];
  const [clientSelected, setClientSelected] = useState({});
  const [payers, setPayers] = useState([]);
  const [payerSelected, setPayerSelected] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [availableInvoicesAmount, setAvailableInvoicesAmount] = useState(0);
  const [bounce, setBounce] = useState(null);
  const [fees, setFees] = useState({});
  const [currency, setCurrency] = useState(CURRENCY[country]);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [generalInformationAlert, setGeneralInformationAlert] = useState(null);
  const [conditionsAndSummaryError, setConditionsAndSummaryError] =
    useState(null);
  const [hidePayersInput, setHidePayersInput] = useState(false);
  const [csvTemplate, setCsvTemplate] = useState([]);
  const [csvErrors, setCsvErrors] = useState(null);
  const [disabledClientName, setDisabledClientName] = useState(true);
  const [validationMinRate, setValidationMinRate] = useState(0);
  const [validationMinOperationCost, setValidationMinOperationCost] =
    useState(0);
  const [defaultBaseRate, setDefaultBaseRate] = useState(0);
  const [defaultOperationCost, setDefaultOperationCost] = useState(0);
  const [autoRate, setAutoRate] = useState(true);

  const {
    fetchBusinessPayerLoading,
    businessPayers,
    businessInvoices,
    calculateBusinessFeesLoading,
    businessFees,
    fetchBusinessPayerError,
    fetchBusinessInvoicesError,
    calculateBusinessFeesError,
    businessName,
    fetchingBusinessName,
    business,
    loadingBusiness,
  } = useSelector(state => state.business);
  const [formFees, setFormFees] = useState({
    baseRate: 0,
    issuedDate: Date.now(),
    expirationDate: Date.now(),
    operationCost: businessFees.operationCost,
  });
  const {
    uploadingInvoiceCsvToOrder,
    csvInvoiceToOrderErrors,
    uploadedInvoiceCsvToOrder,
    invoices: invoicesCsv,
  } = useSelector(state => state.invoice);
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);

  const handleValidationRate = () => {
    ORDER_RATES_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const rawNewMinRate = fixNumber(defaultBaseRate - percentageChange);
        const minBaseRateSettings = countrySettings.minBaseRateCreateOperation;
        const newMinRate = Math.max(rawNewMinRate, minBaseRateSettings);
        setValidationMinRate(newMinRate);
      }
    });
  };

  const handleValidationOperationCost = () => {
    OPERATION_COST_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const valueChange = fixNumber(
          businessFees.operationCost * (percentageChange / 100)
        );
        const minOperationCost = fixNumber(
          businessFees.operationCost - valueChange
        );
        setValidationMinOperationCost(minOperationCost);
      }
    });
  };

  const handleSelectOperationType = type => {
    let operationTypeChosen = DIRECT_FINANCING;
    switch (type) {
      case EARLY_PAYMENT: {
        if (countryFeatures.orderActions.createEarlyPaymentOrder) {
          operationTypeChosen = EARLY_PAYMENT;
        }
        break;
      }
      case DIRECT_FINANCING:
      default: {
        operationTypeChosen = DIRECT_FINANCING;
        break;
      }
    }
    return operationTypeChosen;
  };

  const handleSelectClientEnrolled = client => {
    setClientSelected(client);
    setDisabledClientName(true);
    setPayerSelected({});
    setSelectedInvoices([]);
    setInvoices([]);
    setFees({});
    setTotalAmount(0);

    if (client !== null && !hidePayersInput) {
      onClientSelect(operationType, client.id);
    }
  };

  const handleOperationTypeChange = event => {
    const opType = event.target.value;
    setOperationType(opType);
    setClientSelected({});
    setGeneralInformationAlert(null);
    setPayerSelected({});
    setSelectedInvoices([]);
    setInvoices([]);
    setFees({});
    setTotalAmount(0);

    if (hidePayersInput) {
      setHidePayersInput(false);
    }
  };

  const handleSelectPayer = payer => {
    setPayerSelected(payer);
    setSelectedInvoices([]);
    setInvoices([]);
    setFees({});
    setTotalAmount(0);
    if (clientSelected.id && payer?.identifier) {
      onSelectPayer(clientSelected.id, payer.identifier);
    } else {
      onSelectPayerNotEnrolled(clientSelected.identifier, payer.identifier);
    }
  };

  const handleSelectCurrency = event => {
    setCurrency(event.target.value);
  };

  const handleExchangeRate = event => {
    setExchangeRate(event.target.value);
  };

  const handleSelectedInvoices = orderInvoices => {
    const totalAmount = orderInvoices.reduce(
      (sumAmount, currentInvoice) => sumAmount + currentInvoice.amount,
      0
    );
    setSelectedInvoices(orderInvoices);
    setTotalAmount(totalAmount);
  };

  const handleDownloadTemplateDirectFinanceCsv = () => {
    setCsvTemplate(CsvDirectFinanceTemplate);
  };

  const calculateAdvancePercentage = value => {
    return value ? 100 - value : null;
  };

  const handleFeesCalculate = (
    localFees = {},
    autoRate,
    shouldKeepOperationCost = false
  ) => {
    const retentionPct =
      localFees.retentionPct !== '' && localFees.retentionPct >= 0
        ? localFees.retentionPct
        : settings[country].retentionPct;
    const baseRate =
      localFees.baseRate !== '' && localFees.baseRate >= 0
        ? localFees.baseRate
        : 0;

    const operationCost =
      localFees.operationCost !== '' &&
      localFees.operationCost >= 0 &&
      shouldKeepOperationCost
        ? localFees.operationCost
        : undefined;

    const feesData = {
      retentionPct,
      baseRate,
      operationCost,
      issuedDate: localFees.issuedDate,
      expirationDate: localFees.expirationDate,
      currency,
    };

    onCalculateFees(
      operationType,
      clientSelected.id,
      selectedInvoices?.length > 0 ? selectedInvoices : invoicesCsv,
      invoices.length,
      availableInvoicesAmount,
      feesData,
      autoRate,
      localFees?.payerIdentifier
    );
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    const newFees = {
      ...formFees,
      [name]: value,
    };
    setFormFees(newFees);
    clearTimeout(bounce);
    const shouldKeepOperationCost = true;
    setBounce(
      setTimeout(() => {
        handleFeesCalculate(newFees, autoRate, shouldKeepOperationCost);
      }, 1000)
    );
  };

  const handleDateChange = (name, date) => {
    const newFees = {
      ...formFees,
      [name]: date,
    };
    setFormFees(newFees);
    handleFeesCalculate(newFees, autoRate, true);
  };

  const handleClientIdentifierChange = event => {
    let identifier = event?.target?.value;
    if (country === COUNTRY_CODE_CL) {
      identifier = rutCleaner(event?.target?.value);
    }

    setClientSelected({ identifier });
    setGeneralInformationAlert(null);

    if (validateIdentifier(identifier, country)) {
      fetchClientByIdentifier(operationType, identifier);
    }
  };

  const handleClientNameChange = event => {
    setClientSelected({
      ...clientSelected,
      name: event?.target?.value,
    });
  };

  const getCsvErrorsLink = () => (
    <Button
      endIcon={<InfoIcon />}
      color="primary"
      variant="text"
      className={classes.linkError}
    >
      <CSVLink
        data={csvTemplate}
        separator=","
        style={{ textDecoration: 'none', fontSize: 13 }}
        filename="errores"
        asyncOnClick
        onClick={(event, done) => {
          setCsvTemplate(csvErrors);
          done();
        }}
      >
        Descargar el detalle
      </CSVLink>
    </Button>
  );

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value => {
      return validateIdentifier(value, country);
    });
    ValidatorForm.addValidationRule('maxBaseRate', value => {
      return Number(value) <= countrySettings.maxBaseRate;
    });
    setFormFees({
      retentionPct: settings[country].retentionPct,
      issuedDate: Date.now(),
      expirationDate: Date.now(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    ValidatorForm.addValidationRule('minBaseRate', value => {
      if (autoRate) return true;
      return Number(value) >= validationMinRate;
    });
    ValidatorForm.addValidationRule('minOperationCost', value => {
      return Number(value) >= validationMinOperationCost;
    });
  }, [validationMinRate, validationMinOperationCost, autoRate]);

  useEffect(() => {
    if (!isLastStep) {
      setDefaultBaseRate(0);
      setDefaultOperationCost(0);
      setValidationMinRate(0);
      setValidationMinOperationCost(0);
    }
  }, [isLastStep]);

  useEffect(() => {
    if (defaultBaseRate) handleValidationRate();
    if (defaultOperationCost) handleValidationOperationCost();
  }, [defaultBaseRate, defaultOperationCost]);

  useEffect(() => {
    setFormFees({
      ...formFees,
      baseRate: fees?.baseRate,
      operationCost: fees?.operationCost,
    });
    if (!defaultBaseRate) {
      setDefaultBaseRate(fees?.baseRate);
    }
    if (!defaultOperationCost) {
      setDefaultOperationCost(fees?.operationCost);
    }
  }, [businessFees, fees]);

  useEffect(() => {
    setOperationType(handleSelectOperationType(operation));
    setGeneralInformationAlert(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPayers(businessPayers);
  }, [businessPayers]);

  useEffect(() => {
    if (businessName) {
      setClientSelected({ ...clientSelected, name: businessName });
      setDisabledClientName(businessName?.trim() !== '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessName]);

  useEffect(() => {
    if (business?.taxUserActive) {
      handleSelectClientEnrolled(business);
    } else if (
      !loadingBusiness &&
      clientSelected?.identifier &&
      !businessName
    ) {
      setGeneralInformationAlert({
        message: 'Cliente no enrolado',
        variant: 'info',
      });
      handleClientNotEnrolled(operationType, clientSelected.identifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, businessName, loadingBusiness]);

  useEffect(() => {
    setInvoices(businessInvoices);
    const availableInvoicesAmount = invoices.reduce(
      (sum, invoice) => sum + invoice.amount,
      0
    );
    setAvailableInvoicesAmount(availableInvoicesAmount);
  }, [businessInvoices, invoices]);

  useEffect(() => {
    setFees(businessFees);
  }, [businessFees]);

  useEffect(() => {
    setCsvErrors(csvInvoiceToOrderErrors);
  }, [csvInvoiceToOrderErrors]);

  useEffect(() => {
    const errorMessage = fetchBusinessPayerError || fetchBusinessInvoicesError;
    if (errorMessage) {
      setGeneralInformationAlert({ message: errorMessage, variant: 'error' });
    }
  }, [fetchBusinessPayerError, fetchBusinessInvoicesError]);

  useEffect(() => {
    const errorMessage = submitError || calculateBusinessFeesError;
    setConditionsAndSummaryError(errorMessage);
  }, [submitError, calculateBusinessFeesError]);

  useEffect(() => {
    if (hidePayersInput) {
      setHidePayersInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationType]);

  useEffect(() => {
    handleResetCsvToOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationType]);

  useEffect(() => {
    const IsDirectFinanceByCsv =
      uploadedInvoiceCsvToOrder && !csvInvoiceToOrderErrors.length;
    setIsDirectFinanceByCsv(IsDirectFinanceByCsv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedInvoiceCsvToOrder]);

  useEffect(() => {
    if (isDirectFinanceByCsv && invoicesCsv.length) {
      handleSelectedInvoices(invoicesCsv);
    }
  }, [isDirectFinanceByCsv, invoicesCsv]);

  const hideDropZoneDirectFinance =
    uploadingInvoiceCsvToOrder ||
    (uploadedInvoiceCsvToOrder && !csvInvoiceToOrderErrors.length);

  const csvDirectFinance = (
    <Grid item xs={12}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={4}>
          <Button startIcon={<GetAppIcon />} color="default" variant="text">
            <CSVLink
              data={csvTemplate}
              separator=","
              className={classes.templateButton}
              filename="data_template"
              asyncOnClick
              onClick={(event, done) => {
                handleDownloadTemplateDirectFinanceCsv();
                done();
              }}
            >
              Descargar Template
            </CSVLink>
          </Button>
        </Grid>
      </Grid>
      {!hideDropZoneDirectFinance && (
        <Grid container direction="row" justify="flex-start">
          <Grid item xs={7}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              handleSubmit={files =>
                onDropDirectFinanceCsv(
                  files,
                  setCsvErrors,
                  operationType,
                  clientSelected
                )
              }
              accept=".csv"
              maxFiles={1}
              actionText="Cargar"
              disabled={!clientSelected}
              isLoading={uploadingInvoiceCsvToOrder}
            />
          </Grid>
        </Grid>
      )}
      {uploadingInvoiceCsvToOrder ? (
        <Grid container direction="row" justify="center">
          <CircularProgress size={80} className={classes.loaderDropzone} />
        </Grid>
      ) : null}
      {csvErrors && uploadedInvoiceCsvToOrder && (
        <Grid item xs={12} align="center" className={classes.alertForm}>
          <AlertForm
            messageComponent={
              <Typography
                align="left"
                variant="body2"
                color="textPrimary"
                component="div"
              >
                <Box fontWeight="fontWeightBold">
                  {csvErrors?.length > 0
                    ? `Se presentaron ${csvErrors.length} errores. `
                    : `Se cargaron ${invoicesCsv.length} facturas.`}
                  {csvErrors?.length > 0 && getCsvErrorsLink()}
                </Box>
              </Typography>
            }
            variant={csvErrors?.length ? 'error' : 'success'}
          />
        </Grid>
      )}
    </Grid>
  );

  const generalInformation = (
    <Grid container className={classes.containerGeneralInformation}>
      <Grid item xs={7}>
        <SelectValidator
          fullWidth
          variant="outlined"
          id="operationType"
          label="Tipo Operación"
          value={operationType}
          name="operationType"
          onChange={e => handleOperationTypeChange(e)}
        >
          {countryFeatures.orderActions.createDirectFinancingOrder && (
            <MenuItem value={DIRECT_FINANCING}>{DF_LABEL}</MenuItem>
          )}
          {countryFeatures.orderActions.createEarlyPaymentOrder && (
            <MenuItem value={EARLY_PAYMENT}>{EP_LABEL}</MenuItem>
          )}
        </SelectValidator>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={7}>
          <TextValidator
            id="input-client-identifier"
            fullWidth
            variant="outlined"
            value={clientSelected?.identifier || ''}
            label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} cliente`}
            placeholder={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} cliente`}
            type="string"
            name="client"
            aria-describedby={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} cliente`}
            onChange={e => handleClientIdentifierChange(e)}
            validators={['required', 'isValidIdentifier']}
            errorMessages={[
              'Campo requerido',
              t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
            ]}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-start">
        <Grid item xs={7}>
          {clientSelected?.name && (
            <TextValidator
              fullWidth
              variant="outlined"
              value={clientSelected.name}
              disabled={disabledClientName}
              label="Nombre cliente"
              placeholder="Nombre cliente"
              type="string"
              aria-describedby="Nombre cliente"
              onChange={e => handleClientNameChange(e)}
              validators={['required', 'minStringLength:2']}
              errorMessages={[
                'Campo requerido',
                'Debe tener un mínimo de 3 carácteres',
              ]}
            />
          )}
        </Grid>
      </Grid>
      {!hidePayersInput ? (
        <Grid container item xs={12}>
          <Grid item xs={7}>
            <Autocomplete
              fullWidth
              options={payers}
              getOptionLabel={option => option?.identifier || ''}
              value={payerSelected}
              onChange={(event, payer) => {
                handleSelectPayer(payer);
              }}
              disabled={fetchBusinessPayerLoading || isDirectFinanceByCsv}
              renderInput={params => (
                <TextValidator
                  variant="outlined"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  value={payerSelected?.identifier}
                  label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador`}
                  placeholder={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador`}
                  type="string"
                  validators={
                    isDirectFinanceByCsv
                      ? []
                      : ['isValidIdentifier', 'required']
                  }
                  errorMessages={[
                    'Campo requerido',
                    t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
                  ]}
                  InputProps={{
                    ...params.InputProps,
                    style: { padding: '0px 0px 0px 5px' },
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            {fetchBusinessPayerLoading && !fetchingBusinessName ? (
              <Box className={classes.containerCircularProgress}>
                <CircularProgress size={25} />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={7}>
        <SelectValidator
          fullWidth
          variant="outlined"
          id="currency"
          label="Tipo de moneda"
          value={currency}
          name="currency"
          onChange={e => handleSelectCurrency(e)}
        >
          <MenuItem value={CURRENCY[country]}>{CURRENCY[country]}</MenuItem>
          <MenuItem value={CURRENCY.US}>{CURRENCY.US}</MenuItem>
        </SelectValidator>
      </Grid>

      {currency === CURRENCY.US && country === COUNTRY_CODE_MX && (
        <Grid item xs={7}>
          <TextValidator
            disabled
            fullWidth
            variant="outlined"
            id="exchangeRate"
            label="Tipo de cambio ($)"
            value={exchangeRate}
            name="exchangeRate"
            onChange={e => handleExchangeRate(e)}
            validators={['required']}
            errorMessages={['Campo requerido']}
          />
        </Grid>
      )}

      {csvDirectFinance}
      <br />
      {generalInformationAlert ? (
        <Grid item xs={7}>
          <AlertForm
            message={generalInformationAlert.message}
            variant={generalInformationAlert.variant}
          />
        </Grid>
      ) : null}
    </Grid>
  );

  const invoiceSelection = (
    <Grid container className={classes.containerInvoiceSelection}>
      <Grid item xs={12}>
        <TransferList
          titleChoices="Facturas disponibles"
          titleChosen="Facturas seleccionadas"
          data={invoices}
          selected={selectedInvoices}
          primaryField={{ field: 'folio' }}
          secondaryField={{ field: 'amount', type: 'currency', country }}
          handleSelected={invoiceSelected =>
            handleSelectedInvoices(invoiceSelected)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.containerTotalAmount}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.textTotalAmount}
          >
            Monto Total:
          </Typography>
          <Typography variant="body1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={totalAmount}
                variant="currency"
                countryId={country}
              />
            </Box>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const resumenOperationData = [
    {
      title: 'Monto total:',
      body: (
        <CountryFormatHelper
          value={fees?.totalAmount}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Porcentaje anticipo:',
      body: (
        <CountryFormatHelper
          value={calculateAdvancePercentage(fees?.retentionPct)}
          variant="percentage"
          countryId={country}
        />
      ),
    },
    {
      title: 'Anticipo:',
      body: (
        <CountryFormatHelper
          value={fees?.advance}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Retención:',
      body: (
        <CountryFormatHelper
          value={fees?.retention}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Tasa base:',
      body: (
        <CountryFormatHelper
          value={fees?.baseRate}
          variant="percentage"
          countryId={country}
        />
      ),
    },
    {
      title: 'Aporte Pagador:',
      body: (
        <CountryFormatHelper
          value={fees?.interest}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Asesoría digital:',
      prefix: currency,
      body: (
        <CountryFormatHelper
          value={formFees?.operationCost}
          variant="currency"
          countryId={country}
          currency={currency}
        />
      ),
    },
  ];

  const conditionsAndSummary = (
    <Grid container className={classes.containerConditionsAndSummary}>
      <Grid container item xs={5}>
        <Grid container className={classes.containerInputs}>
          <Grid item xs={12} className={classes.inputs}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="% Retención"
              inputProps={{ min: 0, max: 100, step: 0.000001 }}
              type="number"
              id="retentionPct"
              name="retentionPct"
              value={formFees?.retentionPct ?? settings[country].retentionPct}
              onChange={e => handleInputChange(e)}
              validators={['required', 'minNumber:0', 'maxNumber:100']}
              errorMessages={[
                'Campo requerido',
                'El valor mínimo debe ser 0',
                'El valor máximo debe ser 100',
              ]}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputs}>
            <TextValidator
              fullWidth
              disabled={autoRate || calculateBusinessFeesLoading}
              variant="outlined"
              label="Tasa base"
              inputProps={{
                min: 0,
                max: countrySettings.maxBaseRate,
                step: 0.001,
              }}
              type="number"
              name="baseRate"
              value={formFees?.baseRate ?? 0}
              onChange={e => handleInputChange(e)}
              validators={['required', 'minBaseRate', 'maxBaseRate']}
              errorMessages={[
                'Campo requerido',
                `El valor mínimo debe ser ${validationMinRate}`,
                `El valor máximo debe ser ${countrySettings.maxBaseRate}`,
              ]}
            />
            {validationMinRate !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Tasa base minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">{validationMinRate}%</Box>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} className={classes.inputs}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                label="Fecha operación"
                id="issuedDate"
                name="issuedDate"
                format={DATE_FORMAT}
                value={dateWithoutFormatter(formFees?.issuedDate || Date.now())}
                onChange={date => {
                  handleDateChange('issuedDate', date);
                }}
                autoOk
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} className={classes.inputs}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                label="Fecha vencimiento"
                name="expirationDate"
                format={DATE_FORMAT}
                value={dateWithoutFormatter(
                  formFees?.expirationDate || Date.now()
                )}
                onChange={date => handleDateChange('expirationDate', date)}
                autoOk
                fullWidth
                minDate={formFees?.issuedDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} className={classes.inputs}>
            <TextValidator
              fullWidth
              disabled={
                calculateBusinessFeesLoading ||
                (currency === CURRENCY.US && country === COUNTRY_CODE_MX)
              }
              variant="outlined"
              label="Asesoría digital"
              type="number"
              id="operationCost"
              name="operationCost"
              value={
                formatCurrencyValue(formFees?.operationCost, currency) ?? 0
              }
              onChange={e => handleInputChange(e)}
              validators={['required', 'minOperationCost']}
              errorMessages={[
                'Campo requerido',
                `El valor mínimo debe ser ${validationMinOperationCost}`,
              ]}
            />
            {validationMinOperationCost !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Asesoría digital minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">
                    {validationMinOperationCost}
                  </Box>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={7}>
        <Grid item xs={12} className={classes.containerOperationSummary}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              component="div"
              color="textPrimary"
              className={classes.titleOperationSummary}
            >
              <Box fontWeight="fontWeightBold">Resumen de la operación</Box>
            </Typography>
            <FormControlLabel
              disabled={calculateBusinessFeesLoading}
              control={
                <Switch
                  checked={autoRate}
                  onClick={() => {
                    setAutoRate(!autoRate);
                    handleFeesCalculate(formFees, !autoRate, true);
                  }}
                  size="small"
                  color="primary"
                  name="autoTasas"
                />
              }
              label="Autotasa"
              labelPlacement="start"
              classes={{
                label: autoRate
                  ? classes.labelAutoRateChecked
                  : classes.labelAutoRateBase,
              }}
            />
          </Box>
          {resumenOperationData.map(({ title, body, prefix }) => (
            <Grid item className={classes.itemOperationSummary}>
              {calculateBusinessFeesLoading ? (
                <Skeleton variant="rect" width="100%" height={19} />
              ) : (
                <>
                  <Typography variant="body1">{title}</Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                  >
                    {prefix ? (
                      <Box fontWeight="fontWeightBold">
                        {prefix} {body}
                      </Box>
                    ) : (
                      <Box fontWeight="fontWeightBold">{body}</Box>
                    )}
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {conditionsAndSummaryError && (
        <Grid item xs={12} className={classes.containerAlertForm}>
          <AlertForm message={conditionsAndSummaryError} variant="error" />
        </Grid>
      )}
    </Grid>
  );

  const operationSteps = {
    [DIRECT_FINANCING]: [
      generalInformation,
      invoiceSelection,
      conditionsAndSummary,
    ],
    [EARLY_PAYMENT]: [
      generalInformation,
      invoiceSelection,
      conditionsAndSummary,
    ],
  };
  const operationStepsWithCsv = {
    [DIRECT_FINANCING]: [generalInformation, conditionsAndSummary],
    [EARLY_PAYMENT]: [generalInformation, conditionsAndSummary],
  };

  const stepsContent = isDirectFinanceByCsv
    ? operationStepsWithCsv[operationType]
    : operationSteps[operationType];

  return (
    <VerticalStepper
      steps={steps}
      stepsContent={stepsContent}
      handleSubmit={handleSubmit(
        fees,
        selectedInvoices,
        formFees,
        clientSelected,
        currency,
        exchangeRate,
        invoices.length,
        availableInvoicesAmount,
        autoRate
      )}
      handleNextStep={handleNextStep(
        operationType !== EARLY_PAYMENT
          ? formFees
          : { ...formFees, payerIdentifier: payerSelected?.identifier },
        handleFeesCalculate,
        isDirectFinanceByCsv
      )}
      handleValidateNextStep={handleValidateNextStep(
        hidePayersInput,
        invoices,
        selectedInvoices,
        clientSelected,
        isDirectFinanceByCsv
      )}
      isLoadingButton={isLoadingButton}
      buttonLabelFinish={buttonLabelFinish}
      handleStepBack={handleStepBack}
    />
  );
};

OperationUploadSteps.defaultProps = {
  isLoading: false,
  buttonLabelFinish: 'Guardar',
  isLoadingButton: false,
  stepsContent: undefined,
  submitError: undefined,
  operationType: undefined,
};

OperationUploadSteps.propTypes = {
  steps: PropTypes.arrayOf(String).isRequired,
  isLastStep: PropTypes.bool.isRequired,
  stepsContent: PropTypes.arrayOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  handleValidateNextStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoadingButton: PropTypes.bool,
  buttonLabelFinish: PropTypes.string,
  onDropDirectFinanceCsv: PropTypes.func.isRequired,
  onClientSelect: PropTypes.func.isRequired,
  onSelectPayer: PropTypes.func.isRequired,
  onSelectPayerNotEnrolled: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  CsvDirectFinanceTemplate: PropTypes.arrayOf(Object),
  onCalculateFees: PropTypes.func.isRequired,
  operation: PropTypes.string.isRequired,
  submitError: PropTypes.string,
  operationType: PropTypes.string,
  setOperationType: PropTypes.func.isRequired,
  isDirectFinanceByCsv: PropTypes.bool.isRequired,
  setIsDirectFinanceByCsv: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func.isRequired,
  fetchClientByIdentifier: PropTypes.func.isRequired,
  handleClientNotEnrolled: PropTypes.func.isRequired,
  handleResetCsvToOrder: PropTypes.func.isRequired,
};

export default OperationUploadSteps;
