// eslint-disable-next-line import/prefer-default-export
export const CL = {
  isApiGlobal: process.env.REACT_APP_IS_CL_API_GLOBAL === 'true',
  decimalSeparator: ',',
  thousandSeparator: '.',
  localization: 'America/Santiago',
  currencySymbol: '$',
  currencyCode: 'CLP',
  currencyDecimalPoint: 0,
  dateformat: 'DD-MM-YYYY',
  phoneformat: '#########',
  rate: {
    minRate: 0.5,
    maxRate: 3,
  },
  retention: {
    minRetention: 0.5,
    maxRetention: 100,
  },
  pastInvoiceDaysLimit: 65,
  baseRate: 3.99,
  baseRateOrder: 2.5,
  retentionPct: 5,
  operationCost: 35000,
  minBaseRateCreateOperation: 0,
  maxBaseRate: 3.99,
};
