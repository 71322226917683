import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchOrderAttributes = async orderId => {
  try {
    return await axios.get(`/api/payment/order/${orderId}/attributes`);
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrderAttributes };
