import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Toolbar,
} from '@material-ui/core';

import { Drawer } from '../../../../../commons/components/Drawer';
import { useFetchDocument } from '../../../../domains/document/services';
import { useStyles } from './DocumentDetailDrawer.styles';
import { Close } from '../../../../../../components/icons';
import { t13s } from '../../../../../../translationKeys';
import {
  dateToDDMMYYYY,
  defaultValue,
  getDaysOfIssueInDays,
  getRangeOfDays,
  pluralize,
} from '../../../../../commons/utils';
import { OPERATION_TYPE } from '../../../../../commons/constants/operations';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import StatusChip from '../../../../../../components/elements/chips/StatusChip';

export const DocumentDetailDrawer = ({ onClose, payrollInvoiceId, open }) => {
  const css = useStyles();
  const { t } = useTranslation();
  const { isFetching, data } = useFetchDocument(payrollInvoiceId, {
    enabled: !!payrollInvoiceId,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const pluralizeDays = count => pluralize(count, 'día', 'días');
  const rangeDays = data && pluralizeDays(getRangeOfDays(data?.expirationDate));
  const emissionDays =
    data && pluralizeDays(getDaysOfIssueInDays(data?.issuedDate));

  return (
    <Drawer open={open} onClose={onClose}>
      {isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && data && (
        <>
          <AppBar position="static" className={css.appBar} elevation={0}>
            <Toolbar className={css.toolBar}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className={css.navTitle}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={css.drawerTitle}
                  >
                    Detalle factura <strong>folio {data?.folio}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={onClose}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container className={css.container}>
            <Grid item xs={12}>
              <span className={css.subTitle}>Pagador</span>
              <h4 className={css.companyName}>{data?.receiver.name}</h4>
              <p className={css.companyIdentifier}>
                {t(t13s.LABEL.BUSINESS_IDENTIFIER)}:{' '}
                {data?.receiver?.identifier}
              </p>
              <p>
                Emisor:
                <span className={css.hasEllipsis} title={data?.issuer?.name}>
                  {data?.issuer?.name}
                </span>
              </p>
              <p className={css.within}>
                {t(t13s.LABEL.BUSINESS_IDENTIFIER)} Emisor:
                <span>{data?.issuer?.identifier}</span>
              </p>
              <p>
                Plazo: <span>{rangeDays}</span>
              </p>
              <p className={css.within}>
                Fecha Vencimiento:
                <span>{dateToDDMMYYYY(data.expirationDate)}</span>
              </p>
              <p>
                Días Emisión:
                <span>{emissionDays}</span>
              </p>
              <p className={css.within}>
                Fecha Emisión: <span>{dateToDDMMYYYY(data.issuedDate)}</span>
              </p>
              <p>
                Tipo Operación:
                <span>
                  {defaultValue(OPERATION_TYPE[data.operationType]?.label)}
                </span>
              </p>
              <p className={css.within}>
                Score: <span>{defaultValue(data.score)}</span>
              </p>
              <p>
                Monto bruto:
                <span>
                  <CountryFormatHelper
                    value={data.amount}
                    countryId={data.countryId}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={css.within}>
                Nota de crédito:
                <span>
                  <CountryFormatHelper
                    value={data.totalAmountCreditNotes}
                    countryId={data.countryId}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={css.within}>
                Otros descuentos:
                <CountryFormatHelper
                  value={data.discounts}
                  countryId={data.countryId}
                  variant="currency"
                />
              </p>
              <p>
                Sector:
                <span className={css.hasEllipsis} title={data.businessActivity}>
                  {defaultValue(data.businessActivity)}
                </span>
              </p>
              <p>
                Tasa Originación: <span>{data.baseRate}</span>
              </p>
              <p>
                Order ID: <span>{data.orderId}</span>
              </p>
              <p className={css.within}>
                Estado BO:&nbsp;
                <span>
                  <StatusChip
                    status={data.status}
                    entityType="invoice"
                    size="small"
                  />
                </span>
              </p>
              <p className={css.within}>
                Acuse Recibo:
                <span>
                  {data?.invoiceTaxProviders?.acknowledgementOfReceipt
                    ? 'Sí'
                    : 'No'}
                </span>
              </p>
              <p className={css.within}>
                Estado verificación:
                <span>{defaultValue(data?.verificationStatus)}</span>
              </p>
              <p className={css.within}>
                Dicom Emisor:
                <span>
                  <CountryFormatHelper
                    value={data?.issuer?.debtServiceAmount}
                    countryId={data.countryId}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={css.within}>
                Dicom Pagador:
                <span>
                  <CountryFormatHelper
                    value={data?.receiver?.debtServiceAmount}
                    countryId={data?.countryId}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={css.within}>
                Cesionario:
                <span
                  className={css.hasEllipsis}
                  title={data?.invoiceTaxProviders?.assignee}
                >
                  {defaultValue(data?.invoiceTaxProviders?.assignee)}
                </span>
              </p>
              <p className={css.within}>
                Factura Recompra: <span>{defaultValue(data.repurchase)}</span>
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </Drawer>
  );
};

DocumentDetailDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  payrollInvoiceId: PropTypes.number.isRequired,
};
