import axios from 'axios';
import { Dispatch } from 'react';
import {
  FETCH_COLLECTION_INVOICES_START,
  FETCH_COLLECTION_INVOICES_SUCCESS,
  FETCH_COLLECTION_INVOICES_FAILURE,
  FETCH_COLLECTION_INVOICE_DETAIL_START,
  FETCH_COLLECTION_INVOICE_DETAIL_SUCCESS,
  FETCH_COLLECTION_INVOICE_DETAIL_FAILURE,
  BULK_UPDATE_COLLECTION_INVOICES_START,
  BULK_UPDATE_COLLECTION_INVOICES_SUCCESS,
  BULK_UPDATE_COLLECTION_INVOICES_FAILURE,
  FETCH_COLLECTION_PAYERS_START,
  FETCH_COLLECTION_PAYERS_SUCCESS,
  FETCH_COLLECTION_PAYERS_FAILURE,
} from './types';
import { arrayToURL } from '../helpers/url';
import { getErrorCode } from '../helpers/handleErrors';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys';
import { getCollectionFilters } from '../collection/commons/utils/getCollectionFilters';

interface Filters {
  field: string;
  value?: string[] | string | null;
}

interface FetchCollectionInvoices {
  page: number;
  size: number;
  filters: Filters[];
  segment?: string | null;
}

interface FetchCollectionPayers {
  page?: number;
  size?: number;
  filters?: Filters[];
}

interface InvoiceCollection {
  id: number;
  invoiceId: number;
  orderId: number;
  status: string;
  verificationReason: string;
  expirationDate: string;
  invoice: {
    amount: number;
    folio: number;
    issueDate: string;
  };
  issuer: {
    identifier: string;
    name: string;
  };
  receiver: {
    identifier: string;
    name: string;
  };
  fundName: string;
  operationType: string;
  latePaymentDays: number;
  ratePaymentDays: string;
  tableData?: {
    id: number;
    checked: boolean;
  };
}

export const fetchCollectionInvoices =
  ({ page, size, filters = []}: FetchCollectionInvoices) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({ type: FETCH_COLLECTION_INVOICES_START });
    const stringFilters = arrayToURL(filters);
    axios
      .get(`/api/collection`, {
        params: { filters: stringFilters, page, size },
      })
      .then(({ data: resData }) => {
        dispatch({
          type: FETCH_COLLECTION_INVOICES_SUCCESS,
          payload: {
            collectionInvoices: resData.data,
            pagination: resData.pagination,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_COLLECTION_INVOICES_FAILURE,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_COLLECTION_INVOICES_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchInvoiceCollectionDetail =
  (orderInvoiceId: number) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({ type: FETCH_COLLECTION_INVOICE_DETAIL_START });
    axios
      .get(`/api/collection/orderinvoice/${orderInvoiceId}`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_COLLECTION_INVOICE_DETAIL_SUCCESS,
          payload: {
            invoiceDetail: data,
          },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_COLLECTION_INVOICE_DETAIL_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.FETCH_COLLECTION_INVOICE_DETAIL_FAILURE,
            {
              variant: 'error',
            }
          )
        );
      });
  };

export const bulkUpdateCollectionInvoices =
  (orderInvoices: InvoiceCollection[]) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({ type: BULK_UPDATE_COLLECTION_INVOICES_START });
    axios
      .put('/api/collection/bulk', { orderInvoices })
      .then(({ data }) => {
        dispatch({
          type: BULK_UPDATE_COLLECTION_INVOICES_SUCCESS,
          payload: { ...data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.COLLECTION_INVOICES_BULK_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: BULK_UPDATE_COLLECTION_INVOICES_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchCollectionPayers =
({ page = 1, size = 10, filters = []}: FetchCollectionPayers = {}) => (dispatch: Dispatch<{ type: string; payload?: any }>) => {
  dispatch({
    type: FETCH_COLLECTION_PAYERS_START,
  });

  const queryFilters = getCollectionFilters(filters);

  axios
    .get('api/collection/payers', {
      params: {
        ...queryFilters,
        page,
        size,
      }
    })
    .then(({ data: response }) => {
      dispatch({
        type: FETCH_COLLECTION_PAYERS_SUCCESS,
        payload: {
          collectionPayers: response.data,
          collectionPayerPagination: response.pagination,
        },
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_COLLECTION_PAYERS_FAILURE,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_COLLECTION_PAYERS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

