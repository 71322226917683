import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';

import { Close, Filter } from '../../../../components/icons';
import { useStyles } from './styles';

const BarFilterComponent = ({ handleCloseDrawer }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item className={classes.navTitle}>
            <Typography
              variant="h6"
              component="div"
              className={classes.drawerTitle}
            >
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item className={classes.icon}>
                  <Filter />
                </Grid>
                <Grid item>
                  <strong>Filtros</strong>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleCloseDrawer}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

BarFilterComponent.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
};

export const BarFilter = memo(BarFilterComponent);
