import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import BaseDialog from '../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../components/elements/CheckButton';
import AlertForm from '../../../../components/elements/AlertForm';
import {
  GLOBAL_REASONS,
  STATUS_APPEALED,
  STATUS_REJECTED,
} from '../../../../helpers/Constants';
import { checkStatusAuth } from '../../../../helpers/validation/auth';
import { useStatusesInbox } from '../../../inbox/hooks';
import ChangeStatusRequeriments from './ChangeStatusRequirements';
import { useCheckCanDeposit } from '../../infrastructure/store';
import { STATUS_TO_DEPOSIT } from '../../../commons/constants/status';

const useStyles = makeStyles({
  selectInput: {
    marginBottom: 10,
  },
});

const ChangeStatusOrderModal = ({
  open,
  handleCloseDialog,
  handleSubmit,
  statusOrder,
  title,
  error,
  isLoading,
  warning,
}) => {
  const classes = useStyles();
  const { getStatuses } = useStatusesInbox();
  const { rules } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);

  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState(statusOrder);
  const [showStatusReason, setShowStatusReason] = useState(false);
  const [statusReason, setStatusReason] = useState(null);

  const { id: orderId } = useParams();
  const { requirements } = useCheckCanDeposit([orderId]);

  const handleSelectStatus = status => {
    setStatusSelected(status);
    if ([STATUS_REJECTED, STATUS_APPEALED].includes(status)) {
      setShowStatusReason(true);
    } else {
      setShowStatusReason(false);
    }
  };

  return (
    <BaseDialog isOpen={open} handleClose={handleCloseDialog} title={title}>
      <ValidatorForm
        onSubmit={() => handleSubmit(statusSelected, statusReason)}
      >
        <Grid container>
          <Grid item xs={12} className={classes.selectInput}>
            <SelectValidator
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={e => handleSelectStatus(e.target.value)}
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
            >
              {getStatuses('order').map(({ key, label }) => (
                <MenuItem
                  key={key}
                  value={key}
                  disabled={
                    !checkStatusAuth(key, 'ORDER', rules) ||
                    key === 'RENEGOTIATION'
                  }
                >
                  {label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>

          {showStatusReason && (
            <Grid item xs={12} className={classes.selectInput}>
              <SelectValidator
                fullWidth
                variant="outlined"
                name="operationReason"
                id="operation-reason-select"
                value={statusReason}
                onChange={e => setStatusReason(e.target.value)}
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
              >
                {GLOBAL_REASONS.filter(
                  reason =>
                    reason.type === `INVOICE_${statusSelected}` &&
                    reason.country.includes(country)
                ).map(reason => (
                  <MenuItem key={reason.value} value={reason.value}>
                    {reason.label}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}

          {!isLoading && error && <AlertForm variant="error" message={error} />}

          {!isLoading && warning && (
            <AlertForm variant="info" messageComponent={warning} />
          )}

          {!!requirements.length && statusSelected === STATUS_TO_DEPOSIT && (
            <ChangeStatusRequeriments requirements={requirements} />
          )}

          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeStatusOrderModal.defaultProps = {
  statusOrder: null,
  error: null,
  warning: null,
};

ChangeStatusOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
  warning: PropTypes.string,
  statusOrder: PropTypes.string,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ChangeStatusOrderModal;
