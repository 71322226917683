import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/InsertInvitationOutlined';
import { useSetSelectedInvoice, useSetShowBillDatesDialog } from '../store';

const InvoiceDates = ({ rowData }) => {
  const setShowBillDatesDialog = useSetShowBillDatesDialog();
  const setSelectedInvoice = useSetSelectedInvoice();

  const handleShowBillDatesDialog = invoice => {
    setSelectedInvoice({
      ...invoice,
      tableData: {
        ...invoice.tableData,
      },
    });
    setShowBillDatesDialog(true);
  };

  return (
    <Tooltip title="Ver fechas">
      <IconButton onClick={() => handleShowBillDatesDialog(rowData)}>
        <CalendarIcon />
      </IconButton>
    </Tooltip>
  );
};

InvoiceDates.propTypes = {
  rowData: PropTypes.shape({}).isRequired,
};

export default InvoiceDates;
