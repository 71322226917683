import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';
import { RowText } from '../styles';
import { Skeleton } from '@material-ui/lab';

interface Props extends PropsWithChildren {
  isLoading: boolean;
  operationResumeDictionary: { [key: string]: string };
}

const OperationResumeSkeleton: FC<Props> = ({
  isLoading = false,
  operationResumeDictionary,
  children,
}) => {
  const values = Object.values(operationResumeDictionary);
  return (
    <>
      {!isLoading ? (
        children
      ) : (
        <>
          {values.map(value => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={value}
              mb={3}
            >
              <RowText>{value}</RowText>
              <Skeleton variant="rect" width={120} height={21} />
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default OperationResumeSkeleton;
