import React from 'react';
import {
  Drawer,
  Typography,
  Box,
  makeStyles,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Close as CloseIcon } from '../icons';
import { MIFIEL } from '../../helpers/Constants';

const useStyles = makeStyles(theme => ({
  rootDrawer: {
    background: '#fff',
  },
  rootContainer: {
    width: 570,
    padding: 20,
  },
  titleContainer: {
    marginBottom: 10,
  },
  divider: {
    marginBottom: 25,
  },
  subtitles: {
    marginBottom: 15,
  },
  containerTableSignatories: {
    marginBottom: 25,
  },
  badgeStatusSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  badgeStatusPending: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StatusSignatoriesDrawer = ({
  isOpen,
  handleCloseDrawer,
  isLoading,
  electronicSignatureType,
  signatories,
}) => {
  const classes = useStyles();

  const getTitleByElectronicSignature = () => {
    if (electronicSignatureType === MIFIEL) {
      return MIFIEL;
    }
    return null;
  };

  const getClassNameByStatusSignatory = isSignedDocument => {
    if (isSignedDocument) {
      return classes.badgeStatusSuccess;
    }
    return classes.badgeStatusPending;
  };

  const getTitleTooltipByStatusSignatory = isSignedDocument => {
    if (isSignedDocument) {
      return 'Documento firmado';
    }
    return 'Documento pendiente de firma';
  };

  const transformWords = (word, maxLength) => {
    if (word?.length >= maxLength) {
      return `${word.slice(0, maxLength)}...`;
    }
    return word;
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      classes={{
        paper: classes.rootDrawer,
      }}
    >
      <Box className={classes.rootContainer}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h5" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">
                Verificar estado de {getTitleByElectronicSignature()}
              </Box>
            </Typography>
          </Box>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />

        <Box
          display="flex"
          flexDirection="column"
          className={classes.containerTableSignatories}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            component="div"
            className={classes.subtitles}
          >
            <Box fontWeight="fontWeightBold">Lista de firmantes</Box>
          </Typography>

          <Table>
            <TableBody>
              {signatories?.map(signatoryData => (
                <TableRow key={signatoryData?.name}>
                  <TableCell align="left">
                    <Tooltip
                      title={getTitleTooltipByStatusSignatory(
                        signatoryData?.signed
                      )}
                      placement="right"
                    >
                      <Badge
                        classes={{
                          badge: getClassNameByStatusSignatory(
                            signatoryData?.signed
                          ),
                        }}
                        invisible={false}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="div"
                    >
                      <Box fontWeight="fontWeightBold">
                        {transformWords(signatoryData.name, 20)}
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{signatoryData.identifier}</TableCell>
                  <TableCell align="left">
                    {transformWords(signatoryData.email, 20)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

StatusSignatoriesDrawer.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  electronicSignatureType: PropTypes.string.isRequired,
  signatories: PropTypes.arrayOf({
    name: PropTypes.string,
    identifier: PropTypes.string,
    email: PropTypes.string,
    signed: PropTypes.string,
  }).isRequired,
};

export default StatusSignatoriesDrawer;
