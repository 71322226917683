import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  Typography,
  makeStyles,
  IconButton,
  Menu,
  Grid,
  Tooltip,
  Button,
} from '@material-ui/core';
import { Edit, More, Mysql } from '../icons';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import Can from '../Can';
import { REPORTS_EDIT_PERFORM } from '../../helpers/performsType';
import MenuItem from '../elements/MenuItem';
import { datetimeFormatter } from '../../helpers/DateUtils';

const useStyles = makeStyles({
  switch: {
    margin: 0,
  },
});

const ReportsTable = ({
  tableData,
  loadingReports,
  handleChangePage,
  pagination,
  handleEditReportDialog,
  handleParamDialog,
}) => {
  const classes = useStyles();

  const { user } = useSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [reportSelected, setReportSelected] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const checkIsSupervisorToRole = ({ roles }) => {
    const { roles: rolesUser } = user;
    let isSupervisor = false;
    const adminRole = rolesUser.some(role => role.rolename === 'admin');
    if (adminRole) {
      return true;
    }
    if (roles) {
      // Is supervisor of the area in common with the user to edit.
      isSupervisor = rolesUser.some(userLoggedRole =>
        roles.some(
          role =>
            role.rolename === userLoggedRole.rolename &&
            userLoggedRole.isSupervisor
        )
      );
    }
    return isSupervisor;
  };

  const columns = [
    {
      title: 'ID',
      field: 'reportId',
      width: '5px',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.id}
          </Typography>
        );
      },
    },
    {
      title: 'Nombre',
      field: 'name',
      width: '150px',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.name}
          </Typography>
        );
      },
    },
    {
      title: 'Descripción',
      field: 'description',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.description}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha de creación',
      width: '200px',
      field: 'createdAt',
      type: 'date',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.createdAt ? datetimeFormatter(rowData?.createdAt) : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Repositorio',
      field: 'repository',
      width: '200px',
      render: rowData => {
        return (
          <Grid container direction="row" xs={12} className={classes.statusRow}>
            <Tooltip
              component="span"
              title="Base de Datos: Mysql"
              className={classes.classTooltip}
            >
              <Button
                ref={anchorEl}
                onClick={event => {
                  event.preventDefault();
                  // setAnchorEl(event.currentTarget);
                }}
                startIcon={<Mysql />}
                variant="contained"
              >
                {rowData?.repository}
              </Button>
            </Tooltip>
          </Grid>
        );
      },
    },
    {
      width: '200px',
      render: rowData => {
        return (
          <Grid container direction="row" xs={12} className={classes.statusRow}>
            <Tooltip
              component="span"
              title="Preparar reporte"
              className={classes.classTooltip}
            >
              <Button
                ref={anchorEl}
                onClick={event => {
                  event.preventDefault();
                  handleParamDialog(rowData);
                }}
                startIcon={<CloudDownloadIcon />}
                color="primary"
                variant="outlined"
              >
                Preparar reporte
              </Button>
            </Tooltip>
          </Grid>
        );
      },
    },
    {
      width: '100px',
      render: rowData => {
        const isSupervisor = checkIsSupervisorToRole(rowData);
        return (
          <>
            {isSupervisor ? (
              <IconButton
                onClick={e => {
                  setReportSelected(rowData);
                  handleClick(e);
                }}
              >
                <More />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <MaterialTable
        columns={columns}
        data={tableData}
        isLoading={loadingReports}
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(0, rowsPerPage)}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination?.totalData}
              page={pagination?.page - 1}
              onChangePage={(e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page + 1, props?.rowsPerPage)
              }
            />
          ),
          Toolbar: () => null,
        }}
        localization={{
          pagination: {
            labelRowsSelect: 'reportes',
            labelRowsPerPage: 'Reportes por página',
            labelDisplayedRows: '{count} reportes totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay reportes para mostrar',
          },
        }}
        options={{
          showTitle: false,
          emptyRowsWhenPaging: false,
          filtering: false,
          search: false,
          sorting: false,
          pageSize: pagination?.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Can
          perform={REPORTS_EDIT_PERFORM}
          yes={() => (
            <MenuItem
              icon={<Edit />}
              onClick={() => {
                handleEditReportDialog(reportSelected);
                setAnchorEl(null);
              }}
            >
              Editar reporte
            </MenuItem>
          )}
        />
      </Menu>
    </>
  );
};

ReportsTable.propTypes = {
  tableData: PropTypes.arrayOf(Object).isRequired,
  loadingReports: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalData: PropTypes.number,
  }).isRequired,
  handleEditReportDialog: PropTypes.func.isRequired,
  handleParamDialog: PropTypes.func.isRequired,
};

export default ReportsTable;
