import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { fetchCessionariesService } from '../services';

const useFetchCessionaries = token => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    refetch: fetchCessionaries,
  } = useQuery(
    ['fetchCessionaries', token],
    () => fetchCessionariesService(token),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_CESSIONARIES_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  const { cessionaries } = data || { cessionaries: [] };

  return {
    cessionaries,
    isLoading,
    fetchCessionaries,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchCessionaries };
