import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as Actions from './receiver.actions';
import * as Selects from './receiver.selectors';

export const useSelectedReceiver = () =>
  useSelector(Selects.getSelectedReceiver, shallowEqual);

export const useSelectedDraftReceiver = () =>
  useSelector(Selects.getSelectedDraftReceiver, shallowEqual);

export const useGetReceiverForm = () =>
  useSelector(Selects.getReceiverForm, shallowEqual);

export const useReceiverActions = () => {
  const dispatch = useDispatch();

  const selectReceiver = useCallback(
    receiver => Actions.selectReceiver(receiver)(dispatch),
    [dispatch]
  );
  const selectDraftReceiver = useCallback(
    receiver => Actions.selectDraftReceiver(receiver)(dispatch),
    [dispatch]
  );
  const updateDraftReceiver = useCallback(
    receiver => Actions.updateDraftReceiver(receiver)(dispatch),
    [dispatch]
  );
  const cleanSelectedReceiver = useCallback(
    () => Actions.cleanSelectedReceiver()(dispatch),
    [dispatch]
  );
  const cleanSelectedDraftReceiver = useCallback(
    () => Actions.cleanSelectedDraftReceiver()(dispatch),
    [dispatch]
  );
  const saveReceiverForm = useCallback(
    form => Actions.saveReceiverForm(form)(dispatch),
    [dispatch]
  );
  const cleanReceiverForm = useCallback(
    () => Actions.cleanReceiverForm()(dispatch),
    [dispatch]
  );

  return {
    cleanReceiverForm,
    cleanSelectedDraftReceiver,
    cleanSelectedReceiver,
    saveReceiverForm,
    selectDraftReceiver,
    selectReceiver,
    updateDraftReceiver,
  };
};
