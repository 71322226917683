/* eslint-disable react/jsx-props-no-spreading */
import { Grid, MenuItem, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { settings } from '../../../../config/settings';
import {
  ACCOUNT_TYPES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../../helpers/Constants';
import { convertSpacingToCss } from '../../../../helpers/stylesHelpers';
import { validateIdentifier } from '../../../../helpers/validation/businessIdentifier';
import { t13s } from '../../../../translationKeys';
import bankIcon from '../../../../assets/bank.svg';
import xepelinBankIcon from '../../../../assets/xepelin-bank.svg';
import { features } from '../../../../config/features';
import BaseDialog from '../../../../components/dialogs/BaseDialog';
import AlertForm from '../../../../components/elements/AlertForm';
import CheckButton from '../../../../components/elements/CheckButton';
import { useFetchBanks } from '../../../details/infrastructure/store';

const CustomValidatorForm = styled(ValidatorForm)({
  padding: convertSpacingToCss('sm sm md sm'),
});

const ButtonGrid = styled(Grid)({
  marginTop: 10,
});

const BankAccountDialog = ({
  bankAccount,
  open,
  handleClose,
  handleSubmit,
  isXepelin,
  isLoading,
  error,
}) => {
  const { identifier, name } = useSelector(state => state.business.business);
  const isRegister = !bankAccount?.id;
  const { banks } = useFetchBanks();
  const { disabledAccountHolder } = useSelector(state => state.banks);
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { roles } = useSelector(state => state.auth.user);

  // TODO: set this to work with every country
  const initialState = {
    isXepelin,
    bankId: '',
    accountEmail: '',
    alias: '',
    accountNumber: '',
    accountType: ACCOUNT_TYPES[country][0].value,
    currency: settings[country].currencyCode,
    sourceIdentifier: bankAccount?.sourceIdentifier || identifier || '',
    accountHolder: bankAccount?.name || name || '',
  };

  const [bankAccountData, setBankAccountData] = useState(initialState);
  const [checked, setChecked] = useState(false);
  const isAdmin = roles.some(role => role.rolename === 'admin');

  useEffect(() => {
    if (!isRegister) {
      setBankAccountData(bankAccount);
    }
  }, [bankAccount]);

  // TODO: Add validation rules to MX CLABE (stronger CLABE validation) and MX RFC (if necessary)
  useEffect(() => {
    ValidatorForm.addValidationRule('validateIdentifier', value => {
      return validateIdentifier(value, country);
    });

    ValidatorForm.addValidationRule('validateAccountNumber', value => {
      if (country === COUNTRY_CODE_CL) return true;
      const regex = /^([0-9]{18})$/;
      return regex.test(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (field, value) => {
    let finalValue = value;
    if (field === 'accountNumber') {
      finalValue = value.replace(/[^0-9]/g, '');
    }

    setBankAccountData({
      ...bankAccountData,
      [field]: finalValue,
    });
  };

  // Get bank code from CLABE and set it as selected .MX ONLY
  useEffect(() => {
    if (country === COUNTRY_CODE_MX) {
      if (
        bankAccountData?.accountNumber?.toString().length >= 3 &&
        !bankAccountData?.bankId
      ) {
        const clabeBankCode = bankAccountData.accountNumber
          .toString()
          .substring(0, 3);
        const selectedBank = banks.find(bank => {
          return bank.code === clabeBankCode;
        });

        if (!selectedBank) return;

        setBankAccountData({ ...bankAccountData, bankId: selectedBank.id });
      }
      if (
        bankAccountData?.accountNumber?.toString().length < 3 &&
        bankAccountData.bankId
      ) {
        setBankAccountData({ ...bankAccountData, bankId: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountData.accountNumber]);

  const {
    bankId,
    accountType,
    accountNumber,
    sourceIdentifier,
    accountHolder,
    accountEmail,
    alias,
    currency,
  } = bankAccountData;

  // TODO: move this to settingsCL and settingsMX
  const currencies = {
    CL: [
      {
        value: settings[country].currencyCode,
        label: settings[country].currencyCode,
      },
    ],
    MX: [
      {
        value: settings[country].currencyCode,
        label: settings[country].currencyCode,
      },
      { value: 'USD', label: 'USD' },
    ],
  };

  const prefixTitle = isRegister ? 'Registrar' : 'Editar';
  const bankType = isXepelin ? 'cuenta Xepelin' : 'cuenta bancaria';
  const title = `${prefixTitle} ${bankType}`;
  const topIcon = isXepelin ? xepelinBankIcon : bankIcon;
  const disabledIdentifier =
    country === COUNTRY_CODE_CL && bankAccount && !isAdmin && !isRegister;
  const disabledAccountName =
    country === COUNTRY_CODE_CL && disabledAccountHolder && !isAdmin;

  const bankSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Banco"
      name="bankId"
      value={bankId}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      disabled={isLoading}
    >
      {banks?.map(bank => (
        <MenuItem value={bank.id} key={bank.name}>
          {bank.name}
        </MenuItem>
      ))}
    </SelectValidator>
  );

  const accountTypeSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Tipo de cuenta"
      name="accountType"
      value={accountType}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      disabled={isLoading}
    >
      {ACCOUNT_TYPES[country].map(type => {
        return <MenuItem value={type.value}>{type.label}</MenuItem>;
      })}
    </SelectValidator>
  );

  const accountNumberField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BANK_ACCOUNT_IDENTIFIER)}
      type="text"
      name="accountNumber"
      value={accountNumber}
      validators={['required', 'validateAccountNumber']}
      errorMessages={['Campo requerido', t(t13s.INPUT_ERROR.ACCOUNT_NUMBER)]}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      disabled={isLoading}
    />
  );

  const sourceIdentifierField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
      type="text"
      name="sourceIdentifier"
      disabled={disabledIdentifier}
      value={sourceIdentifier}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['validateIdentifier']}
      errorMessages={[t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER)]}
    />
  );

  const accountHolderField = (
    <TextValidator
      fullWidth
      variant="outlined"
      disabled={disabledAccountName}
      label="Nombre o razón social"
      type="text"
      name="accountHolder"
      value={accountHolder}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
    />
  );

  let currencySelectField = '';
  if (features[country].bankAccountsActions.showCurrencyInput) {
    currencySelectField = (
      <SelectValidator
        fullWidth
        variant="outlined"
        label="Divisa"
        type="text"
        name="currency"
        value={currency}
        onChange={e => handleInputChange(e.target.name, e.target.value)}
        validators={['required']}
        errorMessages={['Campo requerido']}
        disabled={isLoading}
      >
        {currencies[country].map(currency => (
          <MenuItem value={currency.value}>{currency.label}</MenuItem>
        ))}
      </SelectValidator>
    );
  }

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      title={title}
      topIcon={topIcon}
      topIconWidth={114}
    >
      <CustomValidatorForm onSubmit={() => handleSubmit(bankAccountData)}>
        {country === COUNTRY_CODE_CL && (
          <>
            {bankSelectField}
            {accountTypeSelectField}
            {accountNumberField}
            {sourceIdentifierField}
            {accountHolderField}
          </>
        )}

        {country === COUNTRY_CODE_MX && (
          <>
            {sourceIdentifierField}
            {accountNumberField}
            {accountHolderField}
            {bankSelectField}
            <Grid container direction="row" spacing={2}>
              <Grid item xs={9}>
                {accountTypeSelectField}
              </Grid>
              <Grid item xs={3}>
                {currencySelectField}
              </Grid>
            </Grid>
          </>
        )}
        <TextValidator
          fullWidth
          variant="outlined"
          label="Email"
          name="accountEmail"
          value={accountEmail}
          onChange={e => handleInputChange(e.target.name, e.target.value)}
          validators={['required', 'isEmail']}
          errorMessages={['Campo requerido', 'Email incorrecto']}
          disabled={isLoading}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Alias (opcional)"
          type="text"
          name="alias"
          value={alias}
          onChange={e => handleInputChange(e.target.name, e.target.value)}
          disabled={isLoading}
        />
        {error && <AlertForm message={error} variant="error" />}
        <ButtonGrid>
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            loading={isLoading}
            labelButton={isRegister ? 'Agregar cuenta' : 'Actualizar cuenta'}
            disabled={isLoading}
            type="submit"
          />
        </ButtonGrid>
      </CustomValidatorForm>
    </BaseDialog>
  );
};

BankAccountDialog.defaultProps = {
  bankAccount: {},
  isXepelin: false,
  isLoading: false,
  error: undefined,
};

BankAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  bankAccount: PropTypes.shape(),
  isXepelin: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default BankAccountDialog;
