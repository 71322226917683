import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import 'moment/locale/es';
import Loader from './components/elements/Loader';
import tracker from './helpers/tracker';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(tracker.onRequest);
axios.interceptors.response.use(tracker.onResponse);

// SEGMENT
window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
