// eslint-disable-next-line import/prefer-default-export
export const ERROR = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  CREATE_STAKEHOLDER: 'ERROR_CREATE_STAKEHOLDER',
  INVALID_CONTACT_CSV: 'ERROR_INVALID_CONTACT_CSV',
  ERROR_ORDER_IS_LOCKED: 'ERROR_ORDER_IS_LOCKED',
  ERROR_LOCK_ORDER: 'ERROR_LOCK_ORDER',
  ERROR_UNLOCK_ORDER: 'ERROR_UNLOCK_ORDER',
  ERROR_ORDER_BULK_UPDATE_STATUS: 'ERROR_ORDER_BULK_UPDATE_STATUS',
  ERROR_UPDATE_ORDER_STATUS_FORBIDDEN: 'ERROR_UPDATE_ORDER_STATUS_FORBIDDEN',
  UPLOAD_INVOICE_PDF: 'ERROR_UPLOAD_INVOICE_PDF',
  DELETE_INVOICE_PDF: 'ERROR_DELETE_INVOICE_PDF',
  FETCH_ORDER_INVOICES_FUNDS: 'ERROR_FETCH_ORDER_INVOICES_FUNDS',
  FETCH_ORDER_INVOICES_ATTRIBUTES: 'ERROR_FETCH_ORDER_INVOICES_ATTRIBUTES',
  TGR_CERTIFICATE_NOT_FOUND: 'ERROR_TGR_CERTIFICATE_NOT_FOUND',
  NO_INVOICES_WITH_PFX_FOUND: 'ERROR_NO_INVOICES_WITH_PFX_FOUND',
  BULK_INVOICES_BLOCKED: 'ERROR_BULK_INVOICES_BLOCKED',
  BULK_DISCOUNT_ERROR: 'ERROR_BULK_DISCOUNT',
  INVOICE_PDF_NOT_FOUND: 'ERROR_INVOICE_PDF_NOT_FOUND',
  BANK_ACCOUNT_UPDATE_ERROR: 'ERROR_BANK_ACCOUNT_UPDATE',
  ERROR_CREATE_BULK_EXTENSIONS: 'ERROR_CREATE_BULK_EXTENSIONS',
  ERROR_CREATE_BULK_PAYMENT_PROMISES: 'ERROR_CREATE_BULK_PAYMENT_PROMISES',
  ERROR_CREATE_BULK_MANAGEMENTS: 'ERROR_CREATE_BULK_MANAGEMENTS',
  ERROR_UPDATE_TRANSFER_ACCOUNT_NUMBER_DUPLICATED:
    'ERROR_UPDATE_TRANSFER_ACCOUNT_NUMBER_DUPLICATED',
  ERROR_CREATE_BULK_PAYER_CATEGORIZATIONS:
    'ERROR_CREATE_BULK_PAYER_CATEGORIZATIONS',
};
