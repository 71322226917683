import React, { FC } from 'react';
import SearchField from './SearchField';
import StatusFilter from './StatusFilter';
import useFilters from '../../hooks/useFilters';
import {
  ContainerRootFilters,
  ContainerSectionAllFilters,
  ContainerTabsStatusFilters,
} from './styles';

const Filters: FC = () => {
  const {
    statusFilter,
    statusFilters,
    fieldFilter,
    fieldFilters,
    handleChangeStatusFilter,
    handleChangeFieldFilter,
  } = useFilters();

  return (
    <ContainerRootFilters>
      <ContainerTabsStatusFilters>
        <StatusFilter
          filters={statusFilters}
          activeFilter={statusFilter}
          handler={handleChangeStatusFilter}
        />
      </ContainerTabsStatusFilters>
      <ContainerSectionAllFilters>
        <SearchField
          filters={fieldFilters}
          activeFilter={fieldFilter}
          handler={handleChangeFieldFilter}
        />
      </ContainerSectionAllFilters>
    </ContainerRootFilters>
  );
};

export default Filters;
