import * as PayrollTypes from './payroll.types';

const initialState = {
  draftPayrollSelector: null,
  payrollSelector: null,
  selectedFund: null,
  simulation: null,
};

const payrollSelector = (state = initialState.payrollSelector, action) => {
  switch (action.type) {
    case PayrollTypes.PICK_RECEIVER.SUCCESS.type:
    case PayrollTypes.DELETE_RECEIVERS.SUCCESS.type:
    case PayrollTypes.UPDATE_PAYROLL_SELECTOR.SUCCESS.type: {
      return action.payload;
    }
    case PayrollTypes.CLEAN_PAYROLL_SELECTOR.SUCCESS.type: {
      return initialState.payrollSelector;
    }
    default:
      return state;
  }
};

const draftPayrollSelector = (state = initialState.payrollSelector, action) => {
  switch (action.type) {
    case PayrollTypes.PICK_DRAFT_RECEIVER.SUCCESS.type: {
      return action.payload;
    }
    case PayrollTypes.CLEAN_PAYROLL_SELECTOR.SUCCESS.type:
    case PayrollTypes.CLEAN_DRAFT_PAYROLL_SELECTOR.SUCCESS.type:
    case PayrollTypes.DELETE_DRAFT_RECEIVERS.SUCCESS.type: {
      return initialState.draftPayrollSelector;
    }
    default:
      return state;
  }
};

const selectedFund = (state = initialState.selectedFund, action) => {
  switch (action.type) {
    case PayrollTypes.SELECT_FUND.SUCCESS.type: {
      return action.payload;
    }
    case PayrollTypes.CLEAN_SIMULATION.SUCCESS.type:
    case PayrollTypes.REMOVE_SELECTED_FUND.SUCCESS.type: {
      return initialState.selectedFund;
    }
    default:
      return state;
  }
};

const simulation = (state = initialState.simulation, action) => {
  switch (action.type) {
    case PayrollTypes.SET_SIMULATION.START.type: {
      return { isLoading: true };
    }
    case PayrollTypes.SET_SIMULATION.SUCCESS.type: {
      return { ...action.payload, isLoading: false };
    }
    case PayrollTypes.CLEAN_SIMULATION.SUCCESS.type:
    case PayrollTypes.REMOVE_SELECTED_FUND.SUCCESS.type: {
      return initialState.simulation;
    }
    default:
      return state;
  }
};

export const payrollReducer = {
  name: 'payroll',
  reducers: {
    draftPayrollSelector,
    payrollSelector,
    selectedFund,
    simulation,
  },
};
