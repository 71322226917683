import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Typography,
  makeStyles,
  Box,
  CircularProgress,
  Button,
  Collapse,
  Tabs,
  Tab,
} from '@material-ui/core';

import {
  fetchFees,
  fetchSelectedCredit,
  registerFee,
  updateCredit,
} from '../actions/creditsActions';
import { CREDITS_NEW_FEE_PERFORM } from '../helpers/performsType';
import Can from './Can';
import SectionHeader from './elements/SectionHeader';
import Panel from './elements/Panel';
import CreditFeesTable from './tables/CreditFeesTable';
import CreditResume from './elements/CreditResume';
import CreditDialog from './dialogs/CreditDialog';
import { fetchActiveFunds } from '../actions/fundsActions';
import { fetchLinkFund } from '../actions/linkFundActions';
import { ITEM_TYPE_CREDIT, COUNTRY_CODE_MX } from '../helpers/Constants';
import { dateFormatter } from '../helpers/DateUtils';
import Card from './elements/Card';
import { Add, Down, Right } from './icons';
import DocumentsTable from './tables/DocumentsTable';
import { fetchDocuments } from '../actions/documentActions';
import { getCYACreditInvoiceDocuments } from '../actions/creditsActions';
import CreateDocumentDialog from './dialogs/CreateDocumentDialog';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import PartialPaymentsDialog from './dialogs/PartialPaymentsDialog';
import PaymentsTable from './tables/PaymentsTable';
import {
  addPartialPayment,
  fetchPartialPayments,
} from '../actions/partialPaymentsActions';

const RADIX = 10;
const DUES_LIST = 'DUES_LIST';
const PAYMENT_HISTORY = 'PAYMENT_HISTORY';
const CREDIT = 'credit';

const useStyles = makeStyles({
  root: {
    padding: 20,
    display: 'grid',
    gridTemplateColumns: 'auto 330px',
  },
  labelInfo: {
    fontSize: 14,
    marginRight: 5,
    textTransform: 'none',
  },
  info: {
    marginRight: 30,
    fontSize: 14,
  },
  rootInfo: {
    display: 'flex',
  },
  leftContainerData: {
    paddingRight: 20,
  },
  containerDocuments: {
    marginBottom: 20,
  },
  containerTitleCollapse: {
    padding: 20,
    cursor: 'pointer',
  },
  containerIconTitleCollapse: {
    marginRight: 20,
  },
  containerMainAreaBodyCollapse: {
    paddingBottom: 20,
  },
  containerMainAreaButtonsCollapse: {
    padding: '0 20px',
  },
  tabsStyles: {
    borderBottom: '1px solid #E3E1E8',
  },
  containerAttributes: {
    display: 'flex',
    padding: convertSpacingToCss('xl lg'),
  },
  containerItem: {
    margin: convertSpacingToCss('zero sm'),
    width: 120,
  },
  containerButtonAddDocument: {
    marginLeft: 'auto',
    marginRight: 10,
    marginTop: 8,
  },
});

const HomeCreditDetails = () => {
  const { id: paramId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { country } = useSelector(state => state.config);
  const {
    selectedCredit,
    registerFeeIsLoading,
    feeDeleted,
    feesRegistered,
    updateCreditIsLoading,
    updateCreditError,
    creditWasUpdated,
    fetchSelectedCreditIsLoading,
    cyaInvoiceDocuments,
    fetchCyaInvoiceDocumentsIsLoading,
  } = useSelector(state => state.credits);
  const {
    creditDocuments,
    creditDocumentsIsLoading,
    documentWasCreate,
    createDocumentIsLoading,
    createDocumentError,
  } = useSelector(state => state.documents);
  const {
    addPartialPaymentIsLoading,
    addPartialPaymentError,
    partialPaymentAdded,
  } = useSelector(state => state.partialPayments);

  const [showEditCreditDialog, setShowEditCreditDialog] = useState(false);

  useEffect(() => {
    if (creditWasUpdated) {
      setShowEditCreditDialog(false);
    }
  }, [creditWasUpdated]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [partialPaymentPagination, setPartialPaymentsPagination] = useState({
    page: 1,
    size: 10,
  });
  const [creditDocumentsFetched, setCreditDocumentsFetched] = useState(false);
  const [collapseDocuments, setCollapseDocuments] = useState(true);
  const [showCreateDocumentDialog, setShowCreateDocumentDialog] =
    useState(false);
  const [stateTab, setStateTab] = useState(DUES_LIST);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const tabs = [
    {
      label: 'Lista de Cuotas',
      value: DUES_LIST,
    },
    {
      label: 'Historial de Pagos',
      value: PAYMENT_HISTORY,
    },
  ];

  const actionsForEntity = entity => {
    const creditId = parseInt(paramId, RADIX);
    const actions = {
      [PAYMENT_HISTORY]: ({ page, size }) => {
        dispatch(fetchPartialPayments('credit', creditId, { page, size }));
      },
      [DUES_LIST]: ({ page, size }) => {
        dispatch(fetchFees(creditId, { page, limit: size }));
      },
    };

    return actions[entity];
  };

  useEffect(() => {
    const creditId = parseInt(paramId, RADIX);
    dispatch(fetchSelectedCredit(creditId));
    dispatch(fetchActiveFunds());
    dispatch(
      fetchLinkFund({ sourceId: creditId, sourceType: ITEM_TYPE_CREDIT })
    );
  }, []);

  useEffect(() => {
    const creditId = parseInt(paramId, RADIX);
    if (feeDeleted || feesRegistered) {
      dispatch(fetchSelectedCredit(creditId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeDeleted, feesRegistered]);

  useEffect(() => {
    const fetching = actionsForEntity(stateTab);
    if (stateTab === PAYMENT_HISTORY) {
      fetching(partialPaymentPagination);
    }
  }, [partialPaymentPagination, paramId, stateTab]);

  useEffect(() => {
    const fetching = actionsForEntity(stateTab);
    if (stateTab === DUES_LIST) {
      fetching({ page, size: limit });
    }
  }, [page, limit, paramId, stateTab]);

  useEffect(() => {
    if (partialPaymentAdded) {
      setShowPaymentDialog(false);
    }
  }, [partialPaymentAdded]);

  useEffect(() => {
    const creditId = parseInt(paramId, RADIX);
    if (!collapseDocuments && !creditDocumentsFetched) {
      dispatch(fetchDocuments(creditId, CREDIT));
      setCreditDocumentsFetched(true);
    }
  }, [collapseDocuments, paramId, dispatch]);

  useEffect(() => {
    if (documentWasCreate) {
      setShowCreateDocumentDialog(false);
    }
  }, [documentWasCreate]);

  useEffect(() => {
    if (country === COUNTRY_CODE_MX) {
      const creditId = parseInt(paramId, RADIX);
      dispatch(getCYACreditInvoiceDocuments(creditId));
    }
  }, []);

  const handleChangePageCreditFees = (pageData, limitData) => {
    setPage(pageData);
    setLimit(limitData);
  };

  const handleChangePagePayments = (pageData, limitData) => {
    setPartialPaymentsPagination({ page: pageData, size: limitData });
  };

  const handleTabs = (_e, valueTab) => {
    setStateTab(valueTab);
  };

  const handleAddPayment = () => {
    setShowPaymentDialog(true);
  };

  const handleSubmitPayment = formData => {
    const creditId = parseInt(paramId, RADIX);
    dispatch(addPartialPayment('credit', creditId, formData));
  };

  const handleUpdateCredit = credit => {
    dispatch(updateCredit(credit.id, { ...credit }));
  };

  const buildCyaBaseDocument = () => {
    return cyaInvoiceDocuments.map(cyaInvoiceDocuments => {
      const { id, description, pdf } = cyaInvoiceDocuments;
      return {
        action: '',
        active: true,
        approved: true,
        document: pdf,
        documentableId: id,
        documentableType: 'CYA_CREDIT_INVOICE_DOCUMENT',
        title:
          description === 'interest' ? 'Factura Interés' : 'Factura Asesoría',
        hiddenToggleEnableDocument: true,
      };
    });
  };

  return (
    <Box>
      <Box>
        <SectionHeader
          title={selectedCredit.name}
          padding="lg"
          breadCrumbs={[
            {
              title: 'Creditos',
              link: `/${country.toLowerCase()}/credits`,
            },
            {
              title: 'Detalle del crédito',
            },
          ]}
          mainArea={
            <Box display="flex">
              <Box display="flex">
                <Typography
                  color="textPrimary"
                  className={classes.labelInfo}
                  component="div"
                >
                  <Box fontWeight="fontWeightBold">ID Crédito:</Box>
                </Typography>
                <Typography color="textSecondary" className={classes.info}>
                  {selectedCredit?.id}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography
                  color="textPrimary"
                  className={classes.labelInfo}
                  component="div"
                >
                  <Box fontWeight="fontWeightBold">Tipo de operación:</Box>
                </Typography>
                <Typography color="textSecondary" className={classes.info}>
                  Crédito
                </Typography>
              </Box>
              <Box display="flex">
                <Typography
                  color="textPrimary"
                  className={classes.labelInfo}
                  component="div"
                >
                  <Box fontWeight="fontWeightBold">Fecha de solicitud:</Box>
                </Typography>
                <Typography color="textSecondary" className={classes.info}>
                  {dateFormatter(selectedCredit?.createdAt)}
                </Typography>
              </Box>
            </Box>
          }
        />
      </Box>
      <Box className={classes.root}>
        <Box className={classes.leftContainerData}>
          <Box className={classes.containerDocuments}>
            <Card variant="white" border>
              <Box
                display="flex"
                alignItems="center"
                onClick={() => setCollapseDocuments(!collapseDocuments)}
                className={classes.containerTitleCollapse}
              >
                <Box className={classes.containerIconTitleCollapse}>
                  {collapseDocuments ? <Right /> : <Down />}
                </Box>
                <Typography variant="h5" component="div">
                  <Box fontWeight="fontWeightBold">Documentos</Box>
                </Typography>
              </Box>
              <Collapse in={!collapseDocuments} timeout="auto" unmountOnExit>
                <Box className={classes.containerMainAreaBodyCollapse}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className={classes.containerMainAreaButtonsCollapse}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => setShowCreateDocumentDialog(true)}
                    >
                      Nuevo Documento
                    </Button>
                  </Box>
                  <DocumentsTable
                    documents={[...creditDocuments, ...buildCyaBaseDocument()]}
                    documentsAreLoading={
                      creditDocumentsIsLoading ||
                      fetchCyaInvoiceDocumentsIsLoading
                    }
                    documentableType="credit"
                    documentableId={paramId}
                  />
                </Box>
              </Collapse>
            </Card>
          </Box>
          <Panel titlePadding="lg lg zero" contentPadding="zero" title="">
            <Tabs
              value={stateTab}
              onChange={handleTabs}
              className={classes.tabsStyles}
            >
              {tabs.map(tab => {
                const { label, value, stylesTab } = tab;
                return (
                  <Tab
                    label={label}
                    value={value}
                    className={`${classes.tabStylesBase} ${stylesTab}`}
                    aria-controls={`wrapped-tabpanel-${value}`}
                    id={`wrapped-tab-${value}`}
                    key={label}
                  />
                );
              })}
              {stateTab === PAYMENT_HISTORY && (
                <Box
                  className={classes.containerButtonAddDocument}
                  component="div"
                >
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleAddPayment}
                  >
                    Agregar pago
                  </Button>
                </Box>
              )}
              {stateTab === DUES_LIST && (
                <Box className={classes.containerButtonAddDocument}>
                  <Can
                    perform={CREDITS_NEW_FEE_PERFORM}
                    yes={() => (
                      <Button
                        variant="text"
                        color="primary"
                        disabled={registerFeeIsLoading}
                        startIcon={
                          registerFeeIsLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : (
                            <Add />
                          )
                        }
                        onClick={() =>
                          dispatch(
                            registerFee({
                              creditId: selectedCredit?.id,
                            })
                          )
                        }
                      >
                        Agregar cuota
                      </Button>
                    )}
                  />
                </Box>
              )}
            </Tabs>
            {stateTab === DUES_LIST && (
              <CreditFeesTable handleChangePage={handleChangePageCreditFees} />
            )}
            {stateTab === PAYMENT_HISTORY && (
              <PaymentsTable
                handleChangePage={handleChangePagePayments}
                creditId={parseInt(paramId, RADIX)}
              />
            )}
          </Panel>
        </Box>
        <Box>
          <CreditResume
            onClickUpdate={() => setShowEditCreditDialog(true)}
            creditSummary={selectedCredit}
            creditSummaryIsLoading={fetchSelectedCreditIsLoading}
          />
        </Box>
      </Box>
      {showPaymentDialog && (
        <PartialPaymentsDialog
          open={showPaymentDialog}
          handleCloseDialog={() => setShowPaymentDialog(false)}
          handleSubmit={handleSubmitPayment}
          isLoading={addPartialPaymentIsLoading}
          error={addPartialPaymentError}
        />
      )}
      {showEditCreditDialog && (
        <CreditDialog
          open={showEditCreditDialog}
          handleCloseDialog={() => {
            setShowEditCreditDialog(false);
          }}
          handleSubmit={handleUpdateCredit}
          selectedCredit={selectedCredit}
          isLoading={updateCreditIsLoading}
          error={updateCreditError}
        />
      )}
      {showCreateDocumentDialog && (
        <CreateDocumentDialog
          open={showCreateDocumentDialog}
          handleCloseDialog={() => setShowCreateDocumentDialog(false)}
          isLoading={createDocumentIsLoading}
          error={createDocumentError}
          documentableId={paramId}
          documentableType={CREDIT}
          showCustomDocument={country === COUNTRY_CODE_MX}
          showDescription={country === COUNTRY_CODE_MX}
        />
      )}
    </Box>
  );
};

export default HomeCreditDetails;
