import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { downloadOrderCommentAttachmentService } from '../services/downloadOrderCommentAttachmentService';
import { downloadFile } from '../../../../helpers/fileHandling';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';

const useDownloadOrderCommentAttachment = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: downloadOrderCommentAttachment,
  } = useMutation(
    ({ attachmentId }) => downloadOrderCommentAttachmentService(attachmentId),
    {
      onSuccess: ({ file }, variables) => {
        return downloadFile(file, variables.fileName);
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_ORDER_COMMENT_ATTACHMENT, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    downloadOrderCommentAttachment,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useDownloadOrderCommentAttachment };
