import React, { FC } from 'react';
import {
  Skeleton as MUISkeleton,
  SkeletonProps as MUISkeletonProps,
} from '@material-ui/lab';

type SkeletonProps = MUISkeletonProps & {
  isLoading?: boolean;
};

export const Skeleton: FC<SkeletonProps> = ({
  width = 50,
  height = 20,
  isLoading = true,
  children = null,
  ...extras
}) => {
  if (isLoading) {
    return <MUISkeleton width={width} height={height} {...extras} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
