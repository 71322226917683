import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const EditIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      style={style}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 15.9708H3.414L12.728 6.65679L11.314 5.24279L2 14.5568V15.9708ZM18 17.9708H0V13.7278L13.435 0.292787C13.6225 0.105316 13.8768 0 14.142 0C14.4072 0 14.6615 0.105316 14.849 0.292787L17.678 3.12179C17.8655 3.30931 17.9708 3.56362 17.9708 3.82879C17.9708 4.09395 17.8655 4.34826 17.678 4.53579L6.243 15.9708H18V17.9708ZM12.728 3.82879L14.142 5.24279L15.556 3.82879L14.142 2.41479L12.728 3.82879V3.82879Z"
        fill="#1A49E7"
      />
    </CustomSvgIcon>
  );
};

EditIcon.defaultProps = {
  style: {},
};

EditIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default EditIcon;
