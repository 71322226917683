import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InvoiceAmountChip } from '../../../../../../components/elements/chips';
import { checkAuth } from '../../../../../../helpers/validation/auth';

import {
  useSetSelectedInvoice,
  useSetDiscountDialogData,
  useSetShowDiscountFormDialog,
} from '../store';
import { INVOICES_DISCOUNTS_PERFORM } from '../../../../../../helpers/performsType';

const Amount = ({ rowData }) => {
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const isAuth = checkAuth(roles, INVOICES_DISCOUNTS_PERFORM, rules);
  const country = useSelector(state => state.config.country);
  const { fetchAmountInfoIsLoading } = useSelector(state => state.order);

  const setSelectedInvoice = useSetSelectedInvoice();
  const setDiscountDialogData = useSetDiscountDialogData();
  const setShowDiscountFormDialog = useSetShowDiscountFormDialog();

  const handleShowDiscountDialog = invoice => {
    if (isAuth) {
      setSelectedInvoice({
        ...invoice,
        tableData: {
          ...invoice.tableData,
        },
      });
      let data;

      const { invoiceDiscounts = [] } = invoice;
      const [discount] = invoiceDiscounts;
      if (discount) {
        const { amount, reason, id } = discount;
        data = { discount: amount, discountReason: reason, id };
      }
      setDiscountDialogData(data);
      setShowDiscountFormDialog(true);
    }
  };

  return (
    <InvoiceAmountChip
      amount={rowData.amount}
      discounts={rowData.invoiceDiscounts}
      amountCreditNotes={rowData.totalAmountCreditNotes}
      isLoading={fetchAmountInfoIsLoading}
      isClaimed={rowData.isClaimed}
      isCancelled={rowData.isCancelled}
      country={country}
      onClick={() => handleShowDiscountDialog(rowData)}
    />
  );
};

Amount.propTypes = {
  rowData: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    invoiceDiscounts: PropTypes.number.isRequired,
    totalAmountCreditNotes: PropTypes.number.isRequired,
    isClaimed: PropTypes.bool.isRequired,
    isCancelled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Amount;
