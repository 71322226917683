/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  STATUS_CEDED,
  STATUS_CLAIMED,
  DEH_DESCRIPTION,
  STATUS_INCASH_OR_FREE,
  STATUS_CANCELED,
  COUNTRY_CODE_MX,
  INVOICE_DESCRIPTIONS_DETAILS,
} from '../../helpers/Constants';
import { Chip } from './chips';
import CountryFormatHelper from './CountryFormatHelper';
import { dateFormatter } from '../../helpers/DateUtils';
import PaginationMaterialTable from './PaginationMaterialTable';
import { settings } from '../../config/settings';

const useStylesComponent = {
  materialTableStylesGlobal: {
    borderRadius: 17,
    boxShadow: 'none',
    padding: 0,
    overflow: 'hidden',
  },
};

const useStyles = makeStyles({
  descriptionChip: {
    margin: '4px',
  },
});

const FacturasTableBusiness = ({
  facturas = [],
  loading = false,
  pagination,
  handleChangePage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];
  const checkExpirationDateCustom = date => {
    const momentDate = date ? moment.utc(date) : null;
    const momentToday = moment(Date.now());
    return momentDate ? momentToday.diff(momentDate, 'days') : null;
  };

  const events = {
    0: { name: STATUS_CEDED, status: false },
    1: { name: STATUS_CLAIMED, status: false },
    2: { name: DEH_DESCRIPTION, status: true },
    3: { name: STATUS_INCASH_OR_FREE, status: false },
    5: { name: '', status: true },
    6: { name: '', status: true },
    7: { name: STATUS_CANCELED, status: false },
    null: { name: '', status: true },
  };

  const calculateMntTotal = (mntTotal = 0, mntTotalCredit = 0, currency) => {
    const subtractionMnt = mntTotal - mntTotalCredit;

    return (
      <div>
        <CountryFormatHelper
          value={subtractionMnt}
          variant="currency"
          countryId={country}
        />
        {currency && ` ${currency}`}
      </div>
    );
  };

  const buildFacturasColumns = [
    {
      title: 'Folio',
      field: 'folio',
      type: 'numeric',
      defaultSort: 'asc',
      align: 'left',
      width: 100,
      render: rowData => {
        const { folio = '-' } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {folio}
          </Typography>
        );
      },
    },
    {
      title: 'Receptor',
      field: 'rznSocRecep',
      render: rowData => {
        let stakeholderName;
        let blocklist;

        if (isApiGlobal) {
          stakeholderName = rowData.stakeholderName;
          blocklist = rowData.blocklist;
        } else {
          stakeholderName = rowData.rznSocRecep;
          blocklist = rowData.blackList;
        }

        if (!blocklist)
          return (
            <Typography variant="body2" color="textPrimary">
              {stakeholderName || '-'}
            </Typography>
          );
        return (
          <Tooltip title="Blocklist">
            <Chip label={stakeholderName} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: 'Rut receptor',
      field: 'rutReceptor',
      render: rowData => {
        let businessIdentifier;
        let blocklist;

        if (isApiGlobal) {
          businessIdentifier = rowData.stakeholderIdentifier;
          blocklist = rowData.blocklist;
        } else {
          const { rutReceptor, dvReceptor } = rowData;
          businessIdentifier =
            rutReceptor && dvReceptor ? `${rutReceptor}-${dvReceptor}` : '-';
          blocklist = rowData.blackList;
        }

        if (!blocklist)
          return (
            <Typography variant="body2" color="textPrimary">
              {businessIdentifier}
            </Typography>
          );

        return (
          <Tooltip title="Blocklist">
            <Chip label={businessIdentifier} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: 'Fecha emisión',
      field: 'fechaEmision',
      type: 'date',
      render: rowData => {
        const issueDate = isApiGlobal
          ? rowData.issueDate
          : rowData.fechaEmision;

        const daysPassedCount = checkExpirationDateCustom(issueDate);
        const cleanDate = dateFormatter(issueDate);
        const { pastInvoiceDaysLimit } = settings[country];
        return daysPassedCount && daysPassedCount > pastInvoiceDaysLimit ? (
          <Tooltip title={`> ${pastInvoiceDaysLimit} días`}>
            <Chip label={cleanDate} variant="red" />
          </Tooltip>
        ) : (
          <Typography variant="body2" color="textPrimary">
            {cleanDate}
          </Typography>
        );
      },
    },
    country === COUNTRY_CODE_MX
      ? {
          title: 'Descripciones',
          field: 'descriptions',
          render: rowData => {
            const { descriptions } = rowData;

            if (descriptions.lenght <= 0)
              return (
                <Typography variant="body2" color="textPrimary">
                  Sin evento definido
                </Typography>
              );

            return (
              <div>
                {descriptions
                  .filter(({ type, code }) =>
                    Boolean(INVOICE_DESCRIPTIONS_DETAILS[type][code])
                  )
                  .map(({ type, code }) => (
                    <Tooltip
                      key={type}
                      title={INVOICE_DESCRIPTIONS_DETAILS[type][code]}
                    >
                      <Chip
                        label={code}
                        variant="red"
                        className={classes.descriptionChip}
                      />
                    </Tooltip>
                  ))}
              </div>
            );
          },
        }
      : {
          title: 'Descripción',
          field: 'dehOrdenEvento',
          render: rowData => {
            const { dehOrdenEvento } = rowData;
            const eventDescription = events[dehOrdenEvento];
            if (!eventDescription)
              return (
                <Typography variant="body2" color="textPrimary">
                  Sin evento definido
                </Typography>
              );
            const { name, status } = eventDescription;
            if (status)
              return (
                <Typography variant="body2" color="textPrimary">
                  {name}
                </Typography>
              );
            return <Chip label={name} variant="red" />;
          },
        },

    {
      title: 'En operación',
      field: 'orderFactura',
      render: rowData => {
        const { orderFactura = null, orderId = null } = rowData;
        const order = isApiGlobal ? orderId : orderFactura;
        if (!order) return 'Sin operación';
        return (
          <Typography variant="body2" color="textPrimary">
            <Link to={`/${country.toLowerCase()}/orders/${order}`}>
              {order}
            </Link>
          </Typography>
        );
      },
    },
    {
      title: 'Monto total',
      field: 'mntTotal',
      type: 'numeric',
      align: 'left',

      render: rowData => {
        const { amount, mntTotal = amount, mntNotaCredito, currency } = rowData;
        if (!mntNotaCredito || !mntTotal) {
          return (
            <Chip
              label={
                <CountryFormatHelper
                  value={mntTotal}
                  variant="currency"
                  countryId={country}
                />
              }
            />
          );
        }
        const mntTotalWithNotaCredito = calculateMntTotal(
          mntTotal,
          mntNotaCredito,
          currency
        );
        return (
          <Tooltip title={`NC $${mntNotaCredito}`}>
            <Chip label={mntTotalWithNotaCredito} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: 'Tipo Factura',
      field: 'invoiceType',
      type: 'string',
      align: 'left',

      render: ({ invoiceType }) => {
        return (
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body1" color="textPrimary">
              {t(invoiceType)}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <MaterialTable
      columns={buildFacturasColumns}
      data={facturas}
      style={useStylesComponent.materialTableStylesGlobal}
      onChangeRowsPerPage={pageSize => handleChangePage(1, pageSize)}
      components={{
        Pagination: props =>
          isApiGlobal ? (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination.totalData}
              page={pagination.page - 1}
              onChangePage={(e, page) =>
                handleChangePage(page + 1, props.rowsPerPage)
              }
            />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <PaginationMaterialTable {...props} />
          ),
        Toolbar: () => null,
      }}
      options={{
        emptyRowsWhenPaging: false,
        search: false,
        sorting: false,
        pageSize: pagination.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
        toolbar: false,
        draggable: false,
      }}
      isLoading={loading}
      localization={{
        pagination: {
          labelRowsPerPage: 'Facturas por página',
          labelDisplayedRows: '{count} facturas totales',
        },
      }}
    />
  );
};

FacturasTableBusiness.defaultProps = {
  pagination: undefined,
};

FacturasTableBusiness.propTypes = {
  facturas: PropTypes.arrayOf(PropTypes.objectOf(Object)).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    totalData: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  loading: PropTypes.bool.isRequired,
};

export default FacturasTableBusiness;
