import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';

import { t13s } from '../../../../../translationKeys';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import {
  defaultValue,
  fixDecimals,
  percentageFormat,
  roundTerms,
} from '../../../../commons/utils';
import { useSelectedPayroll } from '../../../adapters/store/payroll';

export const GET_COLUMNS = ({
  handleOnClickReceiver,
  country,
  t,
  onEditRate,
  onEditEffectiveFundRate,
}) => {
  const selectedPayroll = useSelectedPayroll();
  const disableEdition = () => !!selectedPayroll?.closedAt;
  return [
    {
      title: 'Pagadores',
      align: 'left',
      field: 'receiver',
      width: '500px',
      type: 'string',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          align="left"
          onClick={() => handleOnClickReceiver(rowData)}
        >
          <Box fontWeight="fontWeightBold">
            {defaultValue(rowData?.receiver)}
          </Box>
        </Link>
      ),
    },
    {
      title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
      align: 'left',
      field: 'receiverId',
      width: '230px',
      type: 'string',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(rowData?.receiverId)}
        </Typography>
      ),
    },
    {
      title: 'Nº Emisores',
      align: 'left',
      field: 'issuersNumber',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(rowData?.issuersNumber)}
        </Typography>
      ),
    },
    {
      title: 'Nº Facturas',
      align: 'left',
      field: 'invoicesNumber',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(rowData?.invoicesNumber)}
        </Typography>
      ),
    },
    {
      title: 'Monto bruto',
      align: 'left',
      field: 'amount',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          <CountryFormatHelper
            value={rowData?.amount}
            countryId={country}
            variant="currency"
          />
        </Typography>
      ),
    },
    {
      title: 'Plazo ponderado',
      align: 'left',
      field: 'averageTerm',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(roundTerms(rowData?.averageTerm))}
        </Typography>
      ),
    },
    {
      title: 'Score ponderado',
      align: 'left',
      field: 'averageScore',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(fixDecimals(rowData?.averageScore))}
        </Typography>
      ),
    },
    {
      title: 'Dicom',
      align: 'left',
      field: 'receiverDebt',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {rowData?.receiverDebt ? (
            <CountryFormatHelper
              value={fixDecimals(rowData?.receiverDebt)}
              countryId={country}
              variant="currency"
            />
          ) : (
            '-'
          )}
        </Typography>
      ),
    },
    {
      title: 'Tasa Fondo Ponderada',
      align: 'left',
      field: 'averageFundRate',
      type: 'numeric',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          align="left"
          color={disableEdition() ? 'textPrimary' : 'secondary'}
          disabled={disableEdition()}
          onClick={() => onEditRate(rowData)}
        >
          <Box
            fontWeight={
              disableEdition() ? 'fontWeightRegular' : 'fontWeightBold'
            }
          >
            {defaultValue(fixDecimals(rowData?.averageFundRate))}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Tasa Costo Fondo Efectiva',
      align: 'left',
      field: 'averageEffectiveFundRate',
      type: 'numeric',
      render: rowData => (
        <Link
          component="button"
          variant="body1"
          align="left"
          color={disableEdition() ? 'textPrimary' : 'secondary'}
          disabled={disableEdition()}
          onClick={() => onEditEffectiveFundRate(rowData)}
        >
          <Box
            fontWeight={
              disableEdition() ? 'fontWeightRegular' : 'fontWeightBold'
            }
          >
            {defaultValue(fixDecimals(rowData?.averageEffectiveFundRate))}
          </Box>
        </Link>
      ),
    },
    {
      title: 'Tasa Originación Ponderada',
      align: 'left',
      field: 'avgOriginationRate',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(fixDecimals(rowData?.avgOriginationRate))}
        </Typography>
      ),
    },
    {
      title: '% Activo',
      align: 'left',
      field: 'activePercentage',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(rowData?.activePercentage)}
        </Typography>
      ),
    },
    {
      title: '% No Act. Cedido',
      align: 'left',
      field: 'notActiveAssignedPercentage',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(rowData?.notActiveAssignedPercentage)}
        </Typography>
      ),
    },
    {
      title: '% No Cedido',
      align: 'left',
      field: 'notAssignedPercentage',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(rowData?.notAssignedPercentage)}
        </Typography>
      ),
    },
    {
      title: '% AR',
      align: 'left',
      field: 'acknowledgementPercentage',
      type: 'numeric',
      render: rowData => (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(rowData?.acknowledgementPercentage)}
        </Typography>
      ),
    },
  ];
};
