import React, { useState } from 'react';
import CollectionsByPayerTableHeader from './CollectionsByPayerTableHeader';
import CollectionsByPayerTableData from './CollectionsByPayerTableData';
import { PayerDebts } from '../../../commons/interfaces/payerDebts';
import { Paper } from '@material-ui/core';
import PayerManagementsData from './PayerManagementsData';

export enum CollectionsByPayerTabs {
  debtsList,
  collectionHistory,
}

type CollectionByPayerTableProps = {
  isLoading: boolean;
  data: PayerDebts;
};
const CollectionByPayerTable = ({
  data,
  isLoading,
}: CollectionByPayerTableProps) => {
  const [selectedTab, setSelectedTab] = useState(
    CollectionsByPayerTabs.debtsList
  );
  return (
    <Paper elevation={1}>
      <CollectionsByPayerTableHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === CollectionsByPayerTabs.debtsList && (
        <CollectionsByPayerTableData data={data} isLoading={isLoading} />
      )}
      {selectedTab === CollectionsByPayerTabs.collectionHistory && (
        <PayerManagementsData debtsData={data} />
      )}
    </Paper>
  );
};

export default CollectionByPayerTable;
