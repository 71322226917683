import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Button,
  Box,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import {
  Drawer,
  ContainerRoot,
  TitleContainer,
  Title,
  Divider,
  ContainerData,
  ContainerInputs,
  ContainerScroll,
  SubTitle,
  ContainerComments,
  Comments,
  ContainerCommentsTitle,
  CommentsTitle,
  CommentsTitleDate,
  ContainerCommentsBody,
  CommentsBodyText,
  CommentsTagTitle,
  Tags,
  CommentsAttachmentsTitle,
  CommentsAttachmentsBody,
  TextNotFound,
  CommentsBodyTextEliminated,
} from './styles';
import { useOrderComments } from '../../../hooks';
import Avatar from '../../../../../AR/commons/components/Avatar';
import OrderCommentsInput from '../../../../../AR/commons/components/OrderCommentsDrawer/ui/OrderCommentsInput';
import OrderCommentsTags from '../../../../../AR/commons/components/OrderCommentsDrawer/ui/OrderCommentsTags';
import OrderCommentsDropzone from '../../../../../AR/commons/components/OrderCommentsDrawer/ui/OrderCommentsDropzone';
import OrderCommentsSkeleton from '../../../../../AR/commons/components/OrderCommentsDrawer/ui/OrderCommentsSkeleton';
import AddCircleIcon from '../../../../../AR/commons/icons/AddCircleIcon';
import CloseIcon from '../../../../../AR/commons/icons/CloseIcon';
import DeleteIcon from '../../../../../AR/commons/icons/DeleteIcon';
import ImageIcon from '../../../../../AR/commons/icons/ImageIcon';
import PdfIcon from '../../../../../AR/commons/icons/PdfIcon';

const OrderCommentsDrawer = ({ open, orderId, handleClose }) => {
  const { authState } = useOktaAuth();
  const {
    showCommentInput,
    showDropZoneInput,
    showTagsInput,
    commentInformation,
    users,
    comments,
    commentIdSelected,
    usersIsLoading,
    commentsIsLoading,
    addOrderCommentIsLoading,
    updateOrderCommentIsLoading,
    downloadOrderCommentAttachmentIsLoading,
    handleOpenCommentInput,
    handleCloseCommentInput,
    handleToggleDropZoneInput,
    handleToggleTagsInput,
    handleChangeCommentData,
    handleChangeDropzone,
    handleChangeTagSelector,
    handleGetDateTimeCommentFormat,
    handleFilterCurrentUser,
    handleMaxWords,
    handleGetExtensionFile,
    handleSetOrderCommentIdSelected,
    handleAddOrderComment,
    handleUpdateOrderComment,
    handleDownloadOrderCommentAttachment,
  } = useOrderComments(orderId);

  const containerSubTitleRef = document.getElementById('containerSubTitle');

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <ContainerRoot>
        <TitleContainer>
          <Title>Comentarios</Title>
          <IconButton onClick={handleClose} data-cy="orderCommentsCloseButton">
            <CloseIcon color="#000" />
          </IconButton>
        </TitleContainer>

        <Divider />
        <ContainerData>
          <ContainerInputs>
            <Box mr={2}>
              <Avatar name={authState?.idToken?.claims?.name || ''} />
            </Box>
            {showCommentInput ? (
              <Box display="flex" flexDirection="column">
                <OrderCommentsInput
                  commentInformation={commentInformation}
                  showDropZoneInput={showDropZoneInput}
                  showTagsInput={showTagsInput}
                  isLoading={addOrderCommentIsLoading}
                  isDisabled={addOrderCommentIsLoading}
                  handleChangeCommentData={handleChangeCommentData}
                  handleCloseCommentInput={handleCloseCommentInput}
                  handleToggleDropZoneInput={handleToggleDropZoneInput}
                  handleToggleTagsInput={handleToggleTagsInput}
                  handleSubmit={() => handleAddOrderComment(commentInformation)}
                />
                <OrderCommentsTags
                  isCollapse={showTagsInput}
                  users={handleFilterCurrentUser(users)}
                  selectedUsers={commentInformation.tagsData}
                  isLoading={usersIsLoading}
                  isDisabled={addOrderCommentIsLoading}
                  handleChangeTagSelector={handleChangeTagSelector}
                />
                <OrderCommentsDropzone
                  isCollapse={showDropZoneInput}
                  handleChangeDropzone={handleChangeDropzone}
                  isDisabled={addOrderCommentIsLoading}
                />
              </Box>
            ) : (
              <Button
                variant="text"
                color="primary"
                endIcon={<AddCircleIcon />}
                onClick={handleOpenCommentInput}
                data-cy="orderCommentsAddCommentButton"
              >
                Agregar Comentario
              </Button>
            )}
          </ContainerInputs>

          <ContainerScroll>
            <SubTitle id="containerSubTitle">Historial de comentarios</SubTitle>
            <ContainerComments>
              {commentsIsLoading ? (
                <Comments>
                  <OrderCommentsSkeleton />
                </Comments>
              ) : (
                <>
                  {comments?.map(
                    ({
                      id,
                      comment,
                      userName,
                      userEmail,
                      deleted,
                      createdAt,
                      CommentAttachments: commentAttachments,
                      CommentTags: commentTags,
                    }) => (
                      <Comments key={id}>
                        <ContainerCommentsTitle>
                          <Box display="flex" alignItems="center">
                            <Avatar name={userName} />
                            <CommentsTitle>{userName}</CommentsTitle>
                            <CommentsTitleDate>
                              {handleGetDateTimeCommentFormat(createdAt)}
                            </CommentsTitleDate>
                          </Box>

                          {!deleted &&
                            authState?.idToken?.claims?.email === userEmail && (
                              <>
                                {updateOrderCommentIsLoading &&
                                commentIdSelected === id ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <Tooltip title="Eliminar comentario" arrow>
                                    <IconButton
                                      disabled={updateOrderCommentIsLoading}
                                      onClick={() => {
                                        handleSetOrderCommentIdSelected(id);
                                        handleUpdateOrderComment(id, {
                                          deleted: true,
                                        });
                                      }}
                                      data-cy="orderCommentsDeleteCommentButton"
                                    >
                                      <DeleteIcon color="#000" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                        </ContainerCommentsTitle>

                        <ContainerCommentsBody>
                          {deleted ? (
                            <CommentsBodyTextEliminated data-cy="orderCommentsBodyCommentButton">
                              Comentario eliminado
                            </CommentsBodyTextEliminated>
                          ) : (
                            <>
                              <CommentsBodyText data-cy="orderCommentsBodyCommentButton">
                                {comment}
                              </CommentsBodyText>
                              {commentTags?.length !== 0 && (
                                <Box>
                                  <CommentsTagTitle>
                                    Personas asignadas:
                                  </CommentsTagTitle>
                                  <Box display="flex" flexWrap="wrap">
                                    {commentTags?.map(commentTag => (
                                      <Tags
                                        key={commentTag.id}
                                        data-cy="orderCommentsTagCommentButton"
                                      >
                                        {commentTag.userName}
                                      </Tags>
                                    ))}
                                  </Box>
                                </Box>
                              )}
                              {commentAttachments?.length !== 0 && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexWrap="wrap"
                                >
                                  <CommentsAttachmentsTitle>
                                    Descarga de documentos adjuntos:
                                  </CommentsAttachmentsTitle>

                                  {commentAttachments?.map(
                                    commentAttachment => (
                                      <CommentsAttachmentsBody key={id}>
                                        <Tooltip
                                          title={handleMaxWords(
                                            commentAttachment.fileName,
                                            30
                                          )}
                                          arrow
                                        >
                                          <IconButton
                                            disabled={
                                              downloadOrderCommentAttachmentIsLoading
                                            }
                                            onClick={() => {
                                              handleSetOrderCommentIdSelected(
                                                id
                                              );
                                              handleDownloadOrderCommentAttachment(
                                                commentAttachment.fileName,
                                                commentAttachment.id
                                              );
                                            }}
                                            data-cy="orderCommentsDownloadDocumentButton"
                                          >
                                            {handleGetExtensionFile(
                                              commentAttachment?.path
                                            ).includes('pdf') ? (
                                              <PdfIcon color="#000" />
                                            ) : (
                                              <ImageIcon color="#000" />
                                            )}
                                          </IconButton>
                                        </Tooltip>
                                      </CommentsAttachmentsBody>
                                    )
                                  )}
                                  {downloadOrderCommentAttachmentIsLoading &&
                                    commentIdSelected === id && (
                                      <CircularProgress size={20} />
                                    )}
                                </Box>
                              )}
                            </>
                          )}
                        </ContainerCommentsBody>
                      </Comments>
                    )
                  )}
                  {comments?.length === 0 && (
                    <TextNotFound>
                      Nadie a realizado comentarios asociados a esta operación
                    </TextNotFound>
                  )}
                </>
              )}
            </ContainerComments>
          </ContainerScroll>
        </ContainerData>
        <Divider />
        <Box display="flex" justifyContent="center">
          <Button
            onClick={() => containerSubTitleRef?.scrollIntoView()}
            variant="text"
            color="primary"
          >
            Volver al inicio
          </Button>
        </Box>
      </ContainerRoot>
    </Drawer>
  );
};

OrderCommentsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OrderCommentsDrawer;
