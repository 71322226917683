import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useQueryClient } from 'react-query';

import { BtnFilter } from '../../../../commons/components/BtnFilter';
import { useStyles } from './Receivers.styles';
import Panel from '../../../../../components/elements/Panel';
import { ReceiversList } from './ReceiversList';
import { MainLayout } from '../../../../MainLayout';
import {
  useGetReceiverForm,
  useReceiverActions,
} from '../../../domains/receiver/state';
import { PayerFilter } from '../../../../commons/components/PayerFilter';
import { FETCH_RECEIVERS_KEY } from '../../../domains/receiver/services';
import { purgeNestedObj } from '../../../../commons/utils';

export const Receivers = () => {
  const css = useStyles();
  const queryClient = useQueryClient();
  const [openFilter, setOpenFilter] = useState(false);
  const { cleanSelectedReceiver, saveReceiverForm } = useReceiverActions();
  const receiverForm = useGetReceiverForm();

  const onSubmit = useCallback(
    summaryForm => {
      const form = purgeNestedObj(summaryForm?.form);
      setOpenFilter(false);
      queryClient.invalidateQueries([FETCH_RECEIVERS_KEY]);
      saveReceiverForm(form);
    },
    [setOpenFilter, queryClient, saveReceiverForm]
  );

  useEffect(() => {
    cleanSelectedReceiver();
  }, []);

  return (
    <MainLayout>
      <Grid container className={css.btnContainer} spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" justifyContent="flex-end">
              <BtnFilter
                appliedFilters={Object.values(receiverForm).length}
                setShowFilter={() => setOpenFilter(true)}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} className={css.rootContainer}>
        <Panel title="Pagadores" titlePadding="lg" contentPadding="lg lg zero">
          <ReceiversList />
        </Panel>
      </Grid>

      <PayerFilter
        fieldsToHide={{ businessStatus: true }}
        form={receiverForm}
        onClose={() => setOpenFilter(false)}
        onSubmit={onSubmit}
        open={openFilter}
        showInvoiceFields
      />
    </MainLayout>
  );
};
