import React, { FC } from 'react';
import { Box, styled, alpha } from '@material-ui/core';
import { palette } from '../../../theme/palette';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';

const CustomButton = styled(Box)({
  width: 32,
  height: 32,
  color: palette.primary.main,
  backgroundColor: palette.payments.softBlue,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'backgroundColor 1s, color 1s',
  '&:hover, &:focus': {
    color: alpha(palette.primary.main, 0.7),
    backgroundColor: alpha(palette.payments.softBlue, 0.7),
  },
});

interface Props {
  isOpen: boolean;
}

const CollapseButton: FC<Props> = ({ isOpen, ...rest }) => {
  return (
    <CustomButton {...rest}>
      {isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
    </CustomButton>
  );
};

export default CollapseButton;
