import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface Props {
  isLoading: boolean;
  children: JSX.Element | JSX.Element[];
}

const NotificationSkeleton: FC<Props> = ({ isLoading = false, children }) => {
  if (!isLoading) return <>{children}</>;
  return (
    <Box
      style={{
        padding: '16px 36px',
        borderBottom: '1px solid #CDCAD8',
      }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2}>
          <Skeleton variant="circle" width={24} height={24} />
        </Box>
        <Skeleton variant="rect" width={151} height={18} />
      </Box>
      <Skeleton variant="rect" width={496} height={18} />
    </Box>
  );
};

export default NotificationSkeleton;
