/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useTranslation } from 'react-i18next';
import RegisterPayerBlocklistDialog from './dialogs/RegisterPayerBlocklistDialog';
import {
  fetchPayerBlocklist,
  registerPayerBlocklist,
  bulkCsvRegisterPayersBlocklist,
  resetRegisterPayerBlocklistError,
  resetSelectedPayerName,
  resetBulkCsvRegisterPayersBlocklist,
  updatePayerBlocklist,
} from '../actions/payerBlocklistAction';
import { BLOCK_LIST_ADD_PERFORM } from '../helpers/performsType';
import Can from './Can';
import PayerBlocklistTable from './tables/PayerBlocklistTable';
import Panel from './elements/Panel';
import SearchBar from './elements/SearchBar';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import BulkCsvPayersBlocklistDialog from './dialogs/BulkCsvPayersBlocklistDialog';
import { t13s } from '../translationKeys';
import HistoryLogsDialog from './dialogs/HistoryLogsDialog';
import { EditBlockReasonDialog } from './dialogs/EditBlockReasonDialog';

const useStyles = makeStyles({
  mainContainer: {
    padding: convertSpacingToCss('lg'),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: convertSpacingToCss('lg'),
  },
  csvButton: {
    marginRight: convertSpacingToCss('lg'),
  },
});

const HomePayerBlocklist = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    payersBlocklist,
    fetchPayersBlocklistIsLoading,
    registerPayerBlocklistIsLoading,
    payerBlocklistWasRegistered,
    registerPayerBlocklistError,
    payerSelected,
    pagination,
    csvRegisterPayersBlocklistIsLoading,
    csvPayersBlocklistWasRegistered,
    csvPayersBlocklistWithError,
    csvRegisterPayersBlocklistError,
  } = useSelector(state => state.payerBlocklist);

  const [
    showRegisterPayerBlocklistDialog,
    setShowRegisterPayerBlocklistDialog,
  ] = useState(false);
  const [
    showBulkCsvPayersBlocklistDialog,
    setShowBulkCsvPayersBlocklistDialog,
  ] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [field, setField] = useState(null);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(1);
  const [historyLogDialog, setHistoryLogDialog] = useState(false);
  const [showEditReasonModal, setShowEditReasonModal] = useState(false);
  const [context, setContext] = useState({});

  useEffect(() => {
    dispatch(fetchPayerBlocklist({}));
  }, [dispatch]);

  useEffect(() => {
    if (payerBlocklistWasRegistered) {
      setShowRegisterPayerBlocklistDialog(false);
      dispatch(resetSelectedPayerName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerBlocklistWasRegistered]);

  useEffect(() => {
    if (csvPayersBlocklistWasRegistered) {
      setShowBulkCsvPayersBlocklistDialog(false);
    }
  }, [csvPayersBlocklistWasRegistered]);

  const searcher = () => {
    dispatch(
      fetchPayerBlocklist({
        searchInput,
        field,
        size,
        page,
      })
    );
  };

  useEffect(() => {
    searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, page, size]);

  const handleChangePage = (page, size) => {
    setSize(size);
    setPage(page);
  };

  const changeSearchInput = ({ value, field }) => {
    setField(field);
    setSearchInput(value);
    setPage(1);
  };

  const handleSubmitRegisterPayerBlocklist = payerBlocklistData => {
    dispatch(
      registerPayerBlocklist(
        payerBlocklistData.payerIdentifier,
        payerBlocklistData.payerName,
        payerBlocklistData.reason
      )
    );
  };

  const handleSubmitBulkCsvPayersBlocklist = payerBlocklistData => {
    dispatch(bulkCsvRegisterPayersBlocklist(payerBlocklistData));
  };

  const handleCloseRegisterPayerBlocklistDialog = () => {
    setShowRegisterPayerBlocklistDialog(false);
    if (payerSelected) dispatch(resetSelectedPayerName());
    if (registerPayerBlocklistError)
      dispatch(resetRegisterPayerBlocklistError());
  };

  const handleCloseBulkCsvPayersBlocklistDialog = () => {
    setShowBulkCsvPayersBlocklistDialog(false);
    if (csvPayersBlocklistWithError.length || csvRegisterPayersBlocklistError)
      dispatch(resetBulkCsvRegisterPayersBlocklist());
  };

  const handleOpenHistoryLogs = config => {
    setContext(config);
    setHistoryLogDialog(true);
  };

  const handleOpenEditReason = config => {
    setContext(config);
    setShowEditReasonModal(true);
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Can
        perform={BLOCK_LIST_ADD_PERFORM}
        yes={() => (
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button
              onClick={() => setShowBulkCsvPayersBlocklistDialog(true)}
              startIcon={<CloudUploadOutlinedIcon />}
              color="primary"
              variant="text"
              className={classes.csvButton}
            >
              Subir CSV Blocklist
            </Button>
            <Button
              onClick={() => setShowRegisterPayerBlocklistDialog(true)}
              startIcon={<AddCircleIcon />}
              color="primary"
              variant="text"
            >
              Agregar Pagador
            </Button>
          </Grid>
        )}
      />
      <Grid item xs={12}>
        <Panel
          title="Blocklist de pagadores"
          contentPadding="zero"
          actions={
            <SearchBar
              placeholder="Buscar pagador..."
              handleChangeInput={value => changeSearchInput(value)}
              fields={[
                {
                  title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} del Pagador`,
                  field: 'payerIdentifier',
                },
                { title: 'Nombre del Pagador', field: 'payerName' },
              ]}
              selectedOption={{
                title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} del Pagador`,
                field: 'payerIdentifier',
              }}
            />
          }
        >
          <PayerBlocklistTable
            tableData={payersBlocklist}
            loadingPayersBlocklist={fetchPayersBlocklistIsLoading}
            handleChangePage={handleChangePage}
            handleOpenHistoryLogs={handleOpenHistoryLogs}
            handleOpenEditReason={handleOpenEditReason}
            pagination={pagination}
          />
        </Panel>
      </Grid>

      {showRegisterPayerBlocklistDialog && (
        <RegisterPayerBlocklistDialog
          open={showRegisterPayerBlocklistDialog}
          handleCloseDialog={handleCloseRegisterPayerBlocklistDialog}
          handleSubmit={handleSubmitRegisterPayerBlocklist}
          isLoading={registerPayerBlocklistIsLoading}
          error={registerPayerBlocklistError}
        />
      )}

      {showBulkCsvPayersBlocklistDialog && (
        <BulkCsvPayersBlocklistDialog
          open={showBulkCsvPayersBlocklistDialog}
          handleCloseDialog={handleCloseBulkCsvPayersBlocklistDialog}
          handleSubmit={handleSubmitBulkCsvPayersBlocklist}
          isLoading={csvRegisterPayersBlocklistIsLoading}
          errorRequest={csvRegisterPayersBlocklistError}
          errorPayers={csvPayersBlocklistWithError}
        />
      )}

      {historyLogDialog && (
        <HistoryLogsDialog
          open={historyLogDialog}
          handleCloseDialog={() => setHistoryLogDialog(false)}
          contextId={context.contextId}
          contextType={context.contextType}
        />
      )}
      {showEditReasonModal && (
        <EditBlockReasonDialog
          isOpen={showEditReasonModal}
          handleCloseDialog={() => setShowEditReasonModal(false)}
          baseReason={context.reason}
          blockId={context.blockId}
          handleSubmit={(value, id) => {
            dispatch(updatePayerBlocklist(id, { reason: value }));
            setShowEditReasonModal(false);
          }}
          title="Editar motivo"
        />
      )}
    </Grid>
  );
};

export default HomePayerBlocklist;
