import React from 'react';
import PropTypes from 'prop-types';
import Chip from './Chip';
import {
  STATUS_REVIEWED,
  STATUS_IN_REVIEW,
  STATUS_APPEALED,
  STATUS_APPROVED,
  STATUS_APPROVED_FUND,
  STATUS_PV_APPROVED,
  STATUS_CEDE,
  STATUS_ACTIVE,
  STATUS_PAID,
  STATUS_DEFAULT,
  STATUS_SOFT_DELINQUENCY,
  STATUS_DELINQUENT,
  STATUS_HARD_COLLECTION,
  STATUS_COMPLETE,
  STATUS_REJECTED,
  STATUS_TO_DEPOSIT,
  STATUS_POST_VERIFIED,
  STATUS_VERIFIED,
  STATUS_HARD_DELINQUENCY,
  STATUS_PENDING,
  DIRECT_FINANCING,
  DEBT_EXTENSION,
  EARLY_PAYMENT,
  CONFIRMING,
  STATUSES,
  STATUS_PRIORITY_PAYMENT,
  LOW_CONTACTABILITY,
  MEDIUM_CONTACTABILITY,
  HIGH_CONTACTABILITY,
  LOW_PAYMENT_CAPACITY,
  LOW_PAYMENT_INTENTION,
  MEDIUM_PAYMENT_INTENTION,
  HIGH_PAYMENT_INTENTION,
  MEDIUM_PAYMENT_CAPACITY,
  HIGH_PAYMENT_CAPACITY,
  PENDING,
  ONLY_SENT_DEBT_MANAGEMENT,
  WITH_CONTACT_DEBT_MANAGEMENT,
  CONTACT_WITH_OTHERS_DEBT_MANAGEMENT,
  NO_CONTACT_DEBT_MANAGEMENT,
  INVALID_ADDRESS_DEBT_MANAGEMENT,
  POSTED_DEBT_MANAGEMENT,
  UNPOSTED_DEBT_MANAGEMENT,
  TO_POST_DEBT_MANAGEMENT,
  IN_PROCESS_DEBT_MANAGEMENT,
  COMPLIANCE_DEBT_MANAGEMENT,
  NO_COMPLIANCE_DEBT_MANAGEMENT,
  CREDIT_NOTE_DEBT_MANAGEMENT,
  ANULATION_DEBT_MANAGEMENT,
  CLAIMED_DEBT_MANAGEMENT,
  DISCOUNT_DEBT_MANAGEMENT,
  PAYED_TO_STAKEHOLDER_DEBT_MANAGEMENT,
  SOLVED_DEBT_MANAGEMENT,
  TO_BE_SENT_DEBT_MANAGEMENT,
  SEND_AND_KEEP_DEBT_MANAGEMENT,
  SEND_AND_TAKE_DEBT_MANAGEMENT,
} from '../../../helpers/Constants';
import { PAYROLL_STATUS } from '../../../funds/commons/constants/payroll';
import useStatuses from '../../../hooks/useStatuses';

const INVOICE = 'invoice';
const ORDER = 'order';
const PAYROLL_FUNDS = 'payrollFunds';
const CREDIT_FEE = 'creditFee';
const CREDIT = 'credit';
const OPERATION_TYPE = 'operationType';
const DEBT = 'debt';
const PAYER_CATEGORIZATION = 'payerCategorization';
const DEBT_MANAGEMENT = 'debtManagement';

const StatusChip = props => {
  const { status, entityType, label = '', ...otherProps } = props;
  const { getStatuses } = useStatuses();

  const getVariant = () => {
    switch (entityType) {
      case INVOICE: {
        switch (status) {
          case STATUS_IN_REVIEW:
          case STATUS_REVIEWED:
          case STATUS_APPEALED:
            return 'yellow';
          case STATUS_APPROVED:
          case STATUS_APPROVED_FUND:
            return 'lightgreen';
          case STATUS_TO_DEPOSIT:
          case STATUS_POST_VERIFIED:
          case STATUS_VERIFIED:
          case STATUS_CEDE:
          case STATUS_PRIORITY_PAYMENT:
            return 'green';
          case STATUS_ACTIVE:
          case STATUS_PAID:
            return 'lightblue';
          case STATUS_DEFAULT:
          case STATUS_SOFT_DELINQUENCY:
          case STATUS_HARD_DELINQUENCY:
          case STATUS_DELINQUENT:
          case STATUS_HARD_COLLECTION:
            return 'blue';
          case STATUS_COMPLETE:
            return 'purple';
          case STATUS_REJECTED:
            return 'red';
          default:
            return 'default';
        }
      }
      case ORDER: {
        switch (status) {
          case STATUS_IN_REVIEW:
          case STATUS_REVIEWED:
          case STATUS_APPEALED:
            return 'yellow';
          case STATUS_APPROVED:
          case STATUS_APPROVED_FUND:
            return 'lightgreen';
          case STATUS_TO_DEPOSIT:
          case STATUS_POST_VERIFIED:
          case STATUS_VERIFIED:
          case STATUS_PV_APPROVED:
            return 'green';
          case STATUS_ACTIVE:
          case STATUS_PAID:
            return 'lightblue';
          case STATUS_DEFAULT:
            return 'blue';
          case STATUS_COMPLETE:
            return 'purple';
          case STATUS_REJECTED:
            return 'red';
          default:
            return 'default';
        }
      }
      case PAYROLL_FUNDS: {
        switch (status) {
          case PAYROLL_STATUS.IN_PROGRESS.value:
            return 'lightorange';
          case PAYROLL_STATUS.PAYMENT_VERIFIED.value:
          case PAYROLL_STATUS.PAID.value:
            return 'lightgreen';
          case PAYROLL_STATUS.TO_PAY.value:
            return 'lightblue';
          case PAYROLL_STATUS.REJECTED.value:
            return 'red';
          default:
            return 'default';
        }
      }
      case CREDIT_FEE: {
        switch (status) {
          case STATUS_ACTIVE:
            return 'lightblue';
          case STATUS_PAID:
            return 'green';
          case STATUS_REJECTED:
            return 'red';
          default:
            return 'default';
        }
      }
      case CREDIT: {
        switch (status) {
          case STATUS_PENDING:
            return 'yellow';
          case STATUS_APPROVED:
          case STATUS_APPROVED_FUND:
            return 'lightgreen';
          case STATUS_ACTIVE:
          case STATUS_PAID:
            return 'lightblue';
          case STATUS_COMPLETE:
            return 'purple';
          case STATUS_REJECTED:
            return 'red';
          case STATUS_TO_DEPOSIT:
            return 'green';
          default:
            return 'default';
        }
      }
      case OPERATION_TYPE: {
        switch (status) {
          case DIRECT_FINANCING:
            return 'purple';
          case EARLY_PAYMENT:
            return 'blue';
          case CONFIRMING:
            return 'orange';
          default:
            return 'default';
        }
      }
      case DEBT: {
        switch (status) {
          case DEBT_EXTENSION:
            return 'purple';
          default:
            return 'default';
        }
      }
      case PAYER_CATEGORIZATION: {
        switch (status) {
          case LOW_CONTACTABILITY:
            return 'red';
          case MEDIUM_CONTACTABILITY:
            return 'yellow';
          case HIGH_CONTACTABILITY:
            return 'green';
          case LOW_PAYMENT_INTENTION:
            return 'red';
          case MEDIUM_PAYMENT_INTENTION:
            return 'yellow';
          case HIGH_PAYMENT_INTENTION:
            return 'green';
          case LOW_PAYMENT_CAPACITY:
            return 'red';
          case MEDIUM_PAYMENT_CAPACITY:
            return 'yellow';
          case HIGH_PAYMENT_CAPACITY:
            return 'green';
          case PENDING:
            return 'default';
          default:
            return 'default';
        }
      }

      case DEBT_MANAGEMENT: {
        switch (status) {
          case CLAIMED_DEBT_MANAGEMENT:
          case CREDIT_NOTE_DEBT_MANAGEMENT:
          case DISCOUNT_DEBT_MANAGEMENT:
          case PAYED_TO_STAKEHOLDER_DEBT_MANAGEMENT:
            return 'orange';
          case UNPOSTED_DEBT_MANAGEMENT:
          case IN_PROCESS_DEBT_MANAGEMENT:
            return 'purple';
          case WITH_CONTACT_DEBT_MANAGEMENT:
          case POSTED_DEBT_MANAGEMENT:
          case COMPLIANCE_DEBT_MANAGEMENT:
          case SOLVED_DEBT_MANAGEMENT:
          case SEND_AND_KEEP_DEBT_MANAGEMENT:
          case SEND_AND_TAKE_DEBT_MANAGEMENT:
            return 'green';
          case NO_CONTACT_DEBT_MANAGEMENT:
          case INVALID_ADDRESS_DEBT_MANAGEMENT:
          case TO_POST_DEBT_MANAGEMENT:
          case NO_COMPLIANCE_DEBT_MANAGEMENT:
          case TO_BE_SENT_DEBT_MANAGEMENT:
            return 'pink';
          case ONLY_SENT_DEBT_MANAGEMENT:
          case CONTACT_WITH_OTHERS_DEBT_MANAGEMENT:
          case ANULATION_DEBT_MANAGEMENT:
            return 'grey';
          default:
            return 'default';
        }
      }
      default:
        return 'default';
    }
  };

  const getLabel = () => {
    switch (entityType) {
      case INVOICE: {
        return getStatuses('factura').find(st => st.key === status)?.label;
      }
      case ORDER: {
        return getStatuses('order').find(st => st.key === status)?.label;
      }
      case PAYROLL_FUNDS: {
        return STATUSES.payrollFunds.find(st => st.key === status)?.label;
      }
      case CREDIT_FEE: {
        return getStatuses('fee').find(st => st.key === status)?.label;
      }
      case CREDIT: {
        return getStatuses('credit').find(st => st.key === status)?.label;
      }
      case OPERATION_TYPE: {
        return getStatuses('operationTypes').find(st => st.key === status)
          ?.label;
      }
      case DEBT: {
        return getStatuses('debt').find(st => st.key === status)?.label;
      }
      case PAYER_CATEGORIZATION: {
        return (
          getStatuses('payerCategorization').find(st => st.key === status)
            ?.label || label
        );
      }
      case DEBT_MANAGEMENT: {
        return label;
      }
      default:
        return '';
    }
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Chip variant={getVariant()} label={getLabel()} {...otherProps} />
  );
};

StatusChip.propTypes = {
  entityType: PropTypes.oneOf([
    ORDER,
    INVOICE,
    PAYROLL_FUNDS,
    CREDIT_FEE,
    CREDIT,
    OPERATION_TYPE,
    DEBT,
    PAYER_CATEGORIZATION,
    DEBT_MANAGEMENT,
  ]).isRequired,
  status: PropTypes.string.isRequired,
  label: PropTypes.string,
};

StatusChip.defaultProps = {
  label: '',
};

export default StatusChip;
