import { restFunds } from '../../../../infra/http';
import * as InvoiceMap from './invoice.map';
import { purgeNestedObj } from '../../../../commons/utils';

export const fetchInvoicesByReceiver = filter => {
  const { id, ...fields } = InvoiceMap.invoiceFilterMap.toPersistence(filter);

  return restFunds()
    .get(`/payroll/${id}/payrollInvoices`, {
      params: purgeNestedObj(fields),
    })
    .then(r => r?.data?.payrollInvoices.map(InvoiceMap.invoiceMap.toDomain));
};

export const updateInvoice = form => {
  const body = InvoiceMap.invoiceEditionMap.toPersistence(form);

  return restFunds()
    .post('/payrollinvoice/bulk-update', body)
    .then(r => r.data)
    .then(InvoiceMap.invoiceEditionMap.toDomain);
};

export const fetchInvoiceById = filter => {
  const { payrollInvoiceId } = InvoiceMap.invoiceByIdMap.toPersistence(filter);

  return restFunds()
    .get(`/payrollinvoice/${payrollInvoiceId}`)
    .then(r => r.data)
    .then(InvoiceMap.invoiceByIdMap.toDomain);
};

export const deleteInvoices = req => {
  const body = InvoiceMap.invoiceDeleteMap.toPersistence(req);

  return restFunds()
    .post('payrollinvoice/bulk-delete', body)
    .then(r => r.data);
};

export const updatePayrollInvoiceStatus = req => {
  const body = InvoiceMap.payrollInvoicesUpdateStatusMap.toPersistence(req);

  return restFunds()
    .post('payrollinvoice/bulk-status', body)
    .then(r => r.data)
    .then(InvoiceMap.payrollInvoicesUpdateStatusMap.toDomain);
};
