import { CHANGE_COUNTRY, TOGGLE_COLLAPSE_HOME_SIDE_BAR } from './types';
import { setCountryHeader } from '../helpers/axiosUtils';

export const changeCountry = country => dispatch => {
  setCountryHeader(country);
  dispatch({
    type: CHANGE_COUNTRY,
    payload: { country },
  });
};

export const toggleCollapseHomeSideBar = isExpanded => dispatch => {
  dispatch({
    type: TOGGLE_COLLAPSE_HOME_SIDE_BAR,
    payload: { isExpanded },
  });
};
