import axios from 'axios';
import { OrderResponsiblesApiResponse } from '../../interfaces';
import { UNKNOWN_ERROR } from './../../../../../../helpers/Constants';

const fetchOrderResponsibles = async (businessIdentifier: string) => {
  try {
    const { data } = await axios.get<OrderResponsiblesApiResponse[]>(
      `/api/kam/${businessIdentifier}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fetchOrderResponsibles;
