export function createActions(base) {
  return ['START', 'SUCCESS', 'FAILURE'].reduce((acc, state) => {
    const type = `${base}_${state}`;
    acc[state] = {
      type,
      action: (payload, error = null) => ({ type, payload, error }),
    };

    return acc;
  }, {});
}
