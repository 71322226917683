import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import AddInvoicesButton from './Components/AddInvoicesButton';
import MoreActionsButton from './Components/MoreActionsButton';
import DiscountsDialog from './Components/DiscountsDialog';
import useDiscountDialog from '../../../hooks/useDiscountDialog';
import { Text } from '../../../../commons/formatters';
import { FundDialog } from '../InvoicesTable/dialogs';
import {
  useFetchActiveFunds,
  useFetchOrderInvoicesFund,
  useUploadBulkFunds,
  useBulkUpdateInvoices,
  useOrder,
  useFetchInvoices,
} from '../../../infrastructure/store';
import LoaderComponent from '../../../../../components/elements/LoaderComponent';
import { useHandleCessionDialog } from '../InvoicesTable/hooks';
import InvoiceCessionDialog from '../InvoicesTable/dialogs/InvoiceCessionDialog/InvoiceCessionDialog';
import { CustomGrid, CustomRefreshIcon } from './styles';
import { useHandleDownloadAEC } from '../../../hooks';
import {
  useSelectedInvoices,
  useSetSelectedInvoices,
} from '../InvoicesTable/store';
import InvoicesUpdateDialog from './Components/InvoicesUpdateDialog';
import { STATUS_PAID, STATUS_REJECTED } from '../../../../../helpers/Constants';

const InvoicesHeader = () => {
  const {
    locales,
    discountReason,
    showDiscountsDialog,
    uploadBulkDiscountsLoading,
    handleUploadFile,
    handleInputChange,
    handleShowDiscountsDialog,
    handleCloseDiscountsDialog,

    showFundsBulkUpdateDialog,
    showInvoicesBulkUpdateDialog,
    handleShowFundsBulkUpdateDialog,
    handleCloseFundsBulkUpdateDialog,
    handleShowInvoicesBulkUpdateDialog,
    handleCloseInvoicesBulkUpdateDialog,
  } = useDiscountDialog();

  const { handleDownloadAEC } = useHandleDownloadAEC();

  const { showCessionDialog, handleCloseCessionDialog, handleCession } =
    useHandleCessionDialog();

  const { id: orderId } = useParams();
  const { order } = useOrder(orderId);
  const { uploadBulkFunds, uploadBulkFundsLoading } = useUploadBulkFunds();
  const { refetch: refetchOrderInvoicesFund } =
    useFetchOrderInvoicesFund(orderId);
  const selectedInvoices = useSelectedInvoices();
  const setSelectedInvoices = useSetSelectedInvoices();
  const { activeFunds } = useFetchActiveFunds();
  const {
    refetch: fetchInvoices,
    suppliersCount,
    invoicesCount,
  } = useFetchInvoices(orderId);

  const {
    bulkUpdateInvoices,
    bulkUpdateInvoicesIsUpdating,
    bulkUpdateInvoicesIsSuccess,
    bulkUpdateInvoicesError,
  } = useBulkUpdateInvoices();

  const onSuccessUploadBulkFunds = () => {
    refetchOrderInvoicesFund();
  };

  const handleSubmitFundFinancing = fundFinancing => {
    const newFundFinancing = {
      ...fundFinancing,
      operationType: 'CONFIRMING',
      orderInvoiceId: null,
    };
    const filteredInvoices = selectedInvoices.filter(
      invoice => invoice.status !== STATUS_REJECTED
    );
    const invoices = filteredInvoices.map(({ id }) => id);
    // eslint-disable-next-line no-param-reassign
    delete newFundFinancing.orderInvoiceId;
    uploadBulkFunds({ invoices, fundFinancing, onSuccessUploadBulkFunds });
    handleCloseFundsBulkUpdateDialog();
  };

  const getValidProperty = (value, defaultValue) => {
    return value !== null ? value : defaultValue;
  };

  const mapInvoice = (invoice, data = {}) => {
    const invoiceData = { ...data };
    Object.keys(invoice).forEach(key => {
      if (key === 'tasaBase') {
        invoiceData[key] = getValidProperty(data.baseRate, invoice[key]);
      } else {
        invoiceData[key] = getValidProperty(data[key], invoice[key]);
      }
    });
    const {
      status,
      statusReason,
      fechaVencimiento,
      paymentDate = null,
      paymentConfirmed = null,
      fundExpirationDate,
      verificationStatus,
      verificationReason,
      expirationDate,
      baseRate,
      defaultDate,
    } = invoiceData;

    const fundPaymentDate =
      data.status === STATUS_PAID
        ? { fundPaymentDate: data.paymentDate }
        : null;

    return {
      ...invoice,
      defaultDate,
      status: invoice.status === STATUS_REJECTED ? invoice.status : status,
      statusReason,
      fechaVencimiento,
      paymentDate,
      paymentConfirmed,
      fundExpirationDate,
      verificationStatus,
      verificationReason,
      expirationDate,
      baseRate,
      ...fundPaymentDate,
    };
  };

  const clearInvoices = () => {
    setSelectedInvoices([]);
  };

  const handleSubmitBulkDialog = ({ data, notCalculateSurplus, country }) => {
    const orderFacturas = selectedInvoices.map(invoice =>
      mapInvoice(invoice, data)
    );

    const [{ orderId, businessId }] = selectedInvoices;
    bulkUpdateInvoices(
      { orderId, businessId, orderFacturas, onSuccessCallback: clearInvoices },
      country,
      {
        notCalculateSurplus,
      }
    );
  };

  return (
    <>
      <InvoiceCessionDialog
        open={showCessionDialog}
        handleCloseDialog={handleCloseCessionDialog}
      />
      {showDiscountsDialog ? (
        <DiscountsDialog
          locales={locales}
          discountReason={discountReason}
          showDiscountsDialog={showDiscountsDialog}
          uploadBulkDiscountsLoading={uploadBulkDiscountsLoading}
          handleUploadFile={handleUploadFile}
          handleInputChange={handleInputChange}
          handleCloseDiscountsDialog={handleCloseDiscountsDialog}
        />
      ) : null}
      {showFundsBulkUpdateDialog && (
        <FundDialog
          operationType="CONFIRMING"
          linkFundType="ORDER_INVOICE"
          open={showFundsBulkUpdateDialog}
          handleCloseDialog={handleCloseFundsBulkUpdateDialog}
          handleSubmit={handleSubmitFundFinancing}
          funds={activeFunds}
        />
      )}
      {uploadBulkFundsLoading && (
        <LoaderComponent open={uploadBulkFundsLoading} />
      )}
      <InvoicesUpdateDialog
        open={showInvoicesBulkUpdateDialog}
        handleCloseDialog={handleCloseInvoicesBulkUpdateDialog}
        invoices={selectedInvoices}
        type="facturasBulk"
        handleSubmit={handleSubmitBulkDialog}
        wasUpdated={bulkUpdateInvoicesIsSuccess}
        isLoading={bulkUpdateInvoicesIsUpdating}
        statusOrder={order.status}
        invoicesBulkError={bulkUpdateInvoicesError}
      />
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Grid container direction="column">
            <Grid item xs>
              <Text fontWeight="bold">Facturas de la operacion </Text>
            </Grid>
            <Grid item xs>
              <Text>
                Proveedores: {suppliersCount} - facturas: {invoicesCount}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <MoreActionsButton
                handleShowDiscountsDialog={handleShowDiscountsDialog}
                handleCession={handleCession}
                handleDownloadAEC={handleDownloadAEC}
                handleShowFundsBulkUpdateDialog={
                  handleShowFundsBulkUpdateDialog
                }
                handleShowInvoicesBulkUpdateDialog={
                  handleShowInvoicesBulkUpdateDialog
                }
              />
            </Grid>
            <CustomGrid item>
              <AddInvoicesButton />
            </CustomGrid>
            <CustomGrid item>
              <IconButton onClick={fetchInvoices}>
                <CustomRefreshIcon />
              </IconButton>
            </CustomGrid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

InvoicesHeader.propTypes = {};

export default InvoicesHeader;
