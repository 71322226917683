import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import * as St from './SummaryBar.styles';
import ActionBar from '../../../../../components/elements/ActionBar';
import ActionBarIcon from '../../../../../components/elements/ActionBarIcon';
import ShoppingCart from '../../../../../components/icons/ShoppingCart';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { SummaryCart } from '../SummaryCart';
import { FUNDS_BASE_PATH } from '../../../../commons/constants/ui';

const parseCartSummary = (amount, countryId, invoicesCount) => {
  const outputInvoicesText = () => {
    return invoicesCount > 1
      ? `${invoicesCount} facturas seleccionadas`
      : `${invoicesCount} factura seleccionada`;
  };
  const styles = {
    fontSize: 19,
  };
  const summaryStyles = {
    fontSize: 16,
    paddingLeft: 12,
  };

  return (
    <>
      <strong style={styles}>
        Monto bruto:&nbsp;
        <CountryFormatHelper
          value={amount}
          countryId={countryId}
          variant="currency"
        />
      </strong>
      <span style={summaryStyles}> ({outputInvoicesText()})</span>
    </>
  );
};

export const SummaryBar = () => {
  const css = St.useStyles();
  const history = useHistory();
  const { country } = useSelector(state => state.config, shallowEqual);
  const { cleanPayrollSelector, summary } = usePayrollSelectorController();
  const [showOpportunitiesCart, setShowOpportunitiesCart] = useState(false);

  if (!summary.totalInvoices) {
    return null;
  }

  return (
    <>
      <ActionBar
        count={summary.totalInvoices}
        message={parseCartSummary(
          summary.amount,
          country,
          summary.totalInvoices
        )}
        resetSelectedRows={cleanPayrollSelector}
        show
      >
        <ActionBarIcon
          IconComponent={ShoppingCart}
          onClick={() => setShowOpportunitiesCart(true)}
          counter={summary.totalInvoices}
        />
        <Button
          onClick={() => {
            history.push(
              // eslint-disable-next-line max-len
              `/${country.toLowerCase()}/${FUNDS_BASE_PATH}/opportunities/creation`
            );
          }}
          variant="contained"
          color="primary"
          className={css.actionBarButton}
        >
          Enviar a fondo
        </Button>
      </ActionBar>
      {showOpportunitiesCart && (
        <SummaryCart
          showDrawer={showOpportunitiesCart}
          handleCloseDrawer={() => setShowOpportunitiesCart(false)}
        />
      )}
    </>
  );
};
