/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid, makeStyles, Checkbox, Typography, Box } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import { DATE_FORMAT } from '../../helpers/Constants';
import AlertForm from '../elements/AlertForm';
import { dateWithoutFormatter } from '../../helpers/DateUtils';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import CheckButton from '../elements/CheckButton';

const useStyles = makeStyles({
  submitButton: {
    marginTop: 10,
  },
  containerDefaultValue: {
    marginTop: '-10px',
    marginBottom: 10,
  },
});

const PartialPaymentDialog = ({
  open,
  handleCloseDialog,
  selectedPartialPayment,
  defaultAmount,
  handleSubmit,
  isLoading,
  error,
}) => {
  const classes = useStyles();

  const [partialPayment, setPartialPayment] = useState({
    amount: 0,
    paymentDate: '',
    comment: '',
  });
  const [checkDefaultAmount, setCheckDefaultAmount] = useState(false);
  const [checkButton, setCheckButton] = useState(false);

  const { country } = useSelector(state => state.config);

  const { amount, paymentDate, comment } = partialPayment;

  useEffect(() => {
    if (selectedPartialPayment) {
      setPartialPayment(selectedPartialPayment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPositiveOrZero', value => value >= 0);
  }, []);

  const onSubmit = () => {
    handleSubmit(partialPayment);
  };

  const handleInputChange = event => {
    const {
      target: { value: valueInput, name: nameInput },
    } = event;

    setPartialPayment({
      ...partialPayment,
      [nameInput]: valueInput,
    });
  };

  const handleDateChange = (name, value) => {
    setPartialPayment({
      ...partialPayment,
      [name]: value,
    });
  };

  const handleCheckDefaultValue = value => {
    setCheckDefaultAmount(value);
    const newAmount = value ? defaultAmount : 0;
    setPartialPayment({ ...partialPayment, amount: newAmount });
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={
        selectedPartialPayment ? 'Editar pago parcial' : 'Nuevo pago parcial'
      }
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={onSubmit}>
          <TextValidator
            fullWidth
            variant="outlined"
            label="Monto ($)"
            name="amount"
            type="number"
            value={amount}
            disabled={checkDefaultAmount}
            onChange={handleInputChange}
            validators={['required', 'isPositiveOrZero']}
            errorMessages={[
              'Campo requerido',
              'El valor debe ser mayor o igual a 0',
            ]}
          />
          {!selectedPartialPayment && defaultAmount !== 0 && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.containerDefaultValue}
            >
              <Checkbox
                checked={checkDefaultAmount}
                onChange={(e, check) => handleCheckDefaultValue(check)}
                name="checkDefaultAmount"
              />
              <Typography variant="body1" color="textPrimary">
                Ingresar total:{' '}
                <CountryFormatHelper
                  value={defaultAmount}
                  countryId={country}
                  variant="currency"
                />
              </Typography>
            </Box>
          )}
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha"
              name="paymentDate"
              format={DATE_FORMAT}
              onChange={data => handleDateChange('paymentDate', data)}
              minDateMessage="Fecha inválida"
              maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
              autoOk
              disableFuture
              value={dateWithoutFormatter(paymentDate)}
              TextFieldComponent={props => {
                return (
                  <TextValidator
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                  />
                );
              }}
            />
          </MuiPickersUtilsProvider>
          <TextValidator
            fullWidth
            variant="outlined"
            type="text"
            label="Comentario (opcional)"
            name="comment"
            value={comment}
            onChange={handleInputChange}
          />
          {error && <AlertForm message={error} variant="error" />}

          <Box className={classes.submitButton}>
            <CheckButton
              check={checkButton}
              handleCheck={() => setCheckButton(!checkButton)}
              labelButton={selectedPartialPayment ? 'Guardar' : 'Agregar'}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              color="primary"
            />
          </Box>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

PartialPaymentDialog.defaultProps = {
  isLoading: false,
  error: null,
  selectedPartialPayment: null,
  defaultAmount: 0,
};

PartialPaymentDialog.propTypes = {
  selectedPartialPayment: PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
    paymentDate: PropTypes.string,
    comment: PropTypes.string,
  }),
  defaultAmount: PropTypes.number,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default PartialPaymentDialog;
