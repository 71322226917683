/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import {
  TextValidator,
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import { DATE_FORMAT, COUNTRY_CODE_CL } from '../../helpers/Constants';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import LoadingButton from '../elements/LoadingButton';
import AlertForm from '../elements/AlertForm';
import {
  rutCleaner,
  validateIdentifier,
} from '../../helpers/validation/businessIdentifier';
import { dateWithoutFormatter } from '../../helpers/DateUtils';
import { t13s } from '../../translationKeys';
import { features } from '../../config/features/index';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  btnSpacing: {
    marginTop: convertSpacingToCss('xs'),
  },
});

const CreditDialog = ({
  open,
  handleCloseDialog,
  selectedCredit,
  handleSubmit,
  isLoading,
  error,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const countryFeature = features[country];

  const [credit, setCredit] = useState({
    identifier: '',
    name: '',
    amount: '',
    fees: '',
    rate: '',
    period: '',
    startDate: null,
    taxITE: 0,
  });

  const { identifier, name, amount, fees, rate, period, startDate, taxITE } =
    credit;

  useEffect(() => {
    if (selectedCredit) {
      setCredit(selectedCredit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value => {
      return validateIdentifier(value, country);
    });
    ValidatorForm.addValidationRule('isZeroOrPositive', value => value >= 0);
  }, [country]);

  const onSubmit = () => {
    const newCreditValues = { ...credit };
    if (!countryFeature.creditActions.taxITE) {
      delete newCreditValues.taxITE;
    }
    handleSubmit(newCreditValues);
  };

  const handleIdentifierChange = event => {
    let identifierValue = event?.target?.value;
    if (country === COUNTRY_CODE_CL) {
      identifierValue = rutCleaner(identifierValue);
    }

    setCredit({
      ...credit,
      identifier: identifierValue,
    });
  };

  const handleInputChange = event => {
    const {
      target: { value: valueInput, name: nameInput },
    } = event;

    const cleanValueInput = valueInput === '' ? null : valueInput;

    setCredit({
      ...credit,
      [nameInput]: cleanValueInput,
    });
  };

  const handleDateChange = (name, value) => {
    setCredit({
      ...credit,
      [name]: value,
    });
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={!selectedCredit ? 'Nueva operación de crédito' : 'Editar crédito'}
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={onSubmit}>
          <TextValidator
            fullWidth
            variant="outlined"
            label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
            type="string"
            name="identifier"
            aria-describedby={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
            value={identifier}
            onChange={handleIdentifierChange}
            validators={['required', 'isValidIdentifier']}
            errorMessages={[
              'Campo requerido',
              t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
            ]}
            disabled={!!selectedCredit}
          />
          <TextValidator
            fullWidth
            variant="outlined"
            label="Nombre o Razón social"
            type="string"
            name="name"
            aria-describedby="Nombre o Razón social"
            value={name}
            onChange={handleInputChange}
            validators={['required']}
            errorMessages={['Campo requerido']}
          />
          <TextValidator
            fullWidth
            variant="outlined"
            label="Monto"
            name="amount"
            aria-describedby="Monto"
            value={amount}
            onChange={handleInputChange}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                variant="outlined"
                label="Nº de cuotas"
                type="number"
                inputProps={{ min: 1, step: 1 }}
                name="fees"
                aria-describedby="Nº de cuotas"
                value={fees}
                onChange={handleInputChange}
                validators={['required', 'minNumber:1']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
                disabled={!!selectedCredit}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                variant="outlined"
                label="% Tasa"
                inputProps={{ min: 0, step: 0.0001 }}
                type="number"
                name="rate"
                aria-describedby="Tasa"
                value={rate}
                onChange={handleInputChange}
                validators={['required']}
                errorMessages={['Campo requerido']}
              />
            </Grid>
          </Grid>
          <SelectValidator
            fullWidth
            variant="outlined"
            label="Periocidad de pago"
            type="string"
            name="period"
            aria-describedby="Periocidad de pago"
            value={period}
            onChange={handleInputChange}
            validators={['required']}
            errorMessages={['Campo requerido']}
          >
            <MenuItem value="BIANNUAL">Semestral</MenuItem>
            <MenuItem value="QUARTERLY">Trimestral</MenuItem>
            <MenuItem value="MONTHLY">Mensual</MenuItem>
            <MenuItem value="BIWEEKLY">Quincenal</MenuItem>
          </SelectValidator>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha Inicio Crédito"
              name="startDate"
              format={DATE_FORMAT}
              onChange={date => handleDateChange('startDate', date)}
              minDateMessage="Fecha inválida"
              autoOk
              value={dateWithoutFormatter(startDate)}
            />
          </MuiPickersUtilsProvider>

          {countryFeature.creditActions.taxITE && (
            <TextValidator
              fullWidth
              variant="outlined"
              label="ITE"
              name="taxITE"
              value={taxITE}
              onChange={handleInputChange}
              validators={['isZeroOrPositive', 'required']}
              errorMessages={[
                'El valor debe ser mayor o igual a 0',
                'Campo requerido',
              ]}
            />
          )}

          {error && <AlertForm message={error} variant="error" />}

          <Grid className={classes.justifyCenter}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Guardar
            </LoadingButton>
          </Grid>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

CreditDialog.defaultProps = {
  isLoading: false,
  error: null,
  selectedCredit: null,
};

CreditDialog.propTypes = {
  selectedCredit: PropTypes.shape({
    identifier: PropTypes.string,
    rut: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    fees: PropTypes.number,
    rate: PropTypes.number,
    period: PropTypes.string,
    startDate: PropTypes.string,
  }),
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default CreditDialog;
