import { useSelector, RootStateOrAny } from 'react-redux';

const useGetCurrentOperation = () => {
  const order = useSelector(
    (state: RootStateOrAny) => state.order.updatedOrder
  );
  return order;
};

export default useGetCurrentOperation;
