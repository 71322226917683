import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    position: props => (props.sticky ? 'sticky' : null),
    top: props => props.stickyTop || 0,
    height: props => props.height || 'max-content',
    boxSizing: 'border-box',
    gridTemplateColumns: props => props.gridTemplate || null,
    gridGap: props => props.gridGap || null,
    padding: props => props.padding || null,
    gridAutoRows: 'min-content',
    width: '100%',
    zIndex: props => props.zIndex || 0,
    background: props => props.background || null,
    alignItems: props => props.align || null,
    justifyContent: props => props.justify || null,
    justifyItems: props => props.justify || null,
    marginBottom: props => props.marginBottom || null,
  },
});
const Container = props => {
  const classes = useStyles(props);

  return (
    <Box
      className={`
        ${classes.root}
        ${props.className}
      `}
      id={props.id}
    >
      {props.children}
    </Box>
  );
};

Container.defaultProps = {
  className: {},
  id: '',
};

Container.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  id: PropTypes.string,
};

export default Container;
