import { styled, Box, Grid } from '@material-ui/core';
import LoadingButton from '../../../../../components/elements/LoadingButton';
import { Text } from '../../../../commons/formatters';

export const SubtitleBox = styled(Box)({
  marginTop: 4,
  marginBottom: 26,
});

export const SubtitleText = styled(Text)({
  fontSize: 14,
});

export const HalfItem = styled(Grid)({
  flex: 1,
});

export const EditAccountButton = styled(LoadingButton)({
  color: 'white',
  background: '#1A49E7',
  width: '100%',
  fontSize: 14,
  padding: '10px 0',
  '&:hover': {
    background: '#1A49E7',
  },
});
