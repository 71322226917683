import { useQuery } from 'react-query';
import { fetchOrderSummary } from '../services';

const useOrderSummary = orderId => {
  const { isLoading, data, refetch } = useQuery(
    ['getOrderSummary', orderId],
    () => fetchOrderSummary(orderId),
    {
      refetchOnWindowFocus: false,
      enabled: !!orderId,
    }
  );

  const { data: orderSummary } = data || { data: [] };

  return {
    orderSummary,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useOrderSummary };
