import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';
import {
  CommentDataToUpdate,
  CommentDataToUpdateApiReponse,
} from '../../interfaces';

const updateOrderComment = async (
  operationId: number,
  commentId: number,
  { deleted }: CommentDataToUpdate
) => {
  try {
    const { data } = await axios.put<CommentDataToUpdateApiReponse>(
      `/api/order/${operationId}/comment/${commentId}`,
      { deleted }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default updateOrderComment;
