/* eslint-disable no-unused-vars */
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { getErrorCode } from '../../../../helpers/handleErrors';
import { manageInvoicesService } from '../services';
import { useFetchInvoices } from './useFetchInvoices';

const useManageOrderInvoices = orderId => {
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.config);
  const { invoicesCount } = useFetchInvoices(orderId);
  const history = useHistory();
  const lowerCaseCountry = country.toLowerCase();

  const PAYMENTS_INBOX_PATH = `/${lowerCaseCountry}/payments`;

  const {
    mutate: manageOrderInvoices,
    isLoading: manageOrderInvoicesIsLoading,
    error: manageOrderInvoicesError,
  } = useMutation(
    ({
      orderId,
      params: {
        action,
        orderInvoiceIds = [],
        newOrderId = null,
        invoices = [],
        deleteInvoiceReason = null,
      },
    }) =>
      manageInvoicesService({
        orderId,
        params: {
          action,
          orderInvoiceIds,
          newOrderId,
          invoices,
          deleteInvoiceReason,
        },
      }),
    {
      onSuccess: (_res, dataSent) => {
        const {
          params: { orderInvoiceIds },
        } = dataSent;
        const affectedInvoicesCount = orderInvoiceIds?.length ?? 0;
        if (affectedInvoicesCount === invoicesCount) {
          history.push(PAYMENTS_INBOX_PATH);
        } else {
          window.location.reload();
        }
      },
      onError: error => {
        const errorCode = getErrorCode(error);
        dispatch(
          enqueueSnackbar(errorCode, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    manageOrderInvoices,
    manageOrderInvoicesIsLoading,
    manageOrderInvoicesError,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useManageOrderInvoices };
