import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../../translationKeys/index';
import fundBulkUpdateService from '../service/fundBulkUpdateService';

const useFundBulkUpdate = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    mutate: uploadFile,
    reset: resetDataAndErrors,
  } = useMutation(csvFile => fundBulkUpdateService(csvFile), {
    onSuccess: response => {
      if (!response?.errorLines?.length) {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FUNDS_BULK_UPDATE_SUCCESS, {
            variant: 'success',
          })
        );
      }
    },
  });

  const errorLines = data?.errorLines;
  const hasErrors = !!errorLines?.length || isError;
  const serverError = error || null;

  return {
    isLoading,
    errorLines,
    serverError,
    hasErrors,
    isSuccess,
    uploadFile,
    resetDataAndErrors,
  };
};

export default useFundBulkUpdate;
