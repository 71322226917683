/* eslint-disable no-param-reassign */
import produce from 'immer';
import { format } from 'date-fns';
import { createStore } from '../../../../commons/utils/store';

const STORE_NAME = 'PaymentInboxZustandStore';
const STORE_VERSION = 4;

const PAGE = 1;
const ITEMS_PER_PAGE = 5;
const PAYMENT_STATUS = 'ALL';
const PAYMENT_TYPE = ['Caja propia', 'Financiamiento'];
const PAYMENT_AGREEMENT = [];
const PAYMENT_ISSUED_DATE = null;
const PAYMENT_CESION = ['Con cesión', 'Sin cesión'];
const PAYMENT_FIELD = {
  select: { title: 'Razón Social', field: 'name' },
  input: '',
};

const PaymentInboxZustandStore = createStore(
  (set, get) => ({
    page: PAGE,
    itemsPerPage: ITEMS_PER_PAGE,
    selectedPayrolls: [],
    filters: {
      status: PAYMENT_STATUS,
      field: PAYMENT_FIELD,
      agreement: PAYMENT_AGREEMENT,
      issuedDate: PAYMENT_ISSUED_DATE,
      paymentType: PAYMENT_TYPE,
      paymentCesion: PAYMENT_CESION,
    },
    setPage: page =>
      set(state => {
        state.page = page;
      }),
    goNextPage: () =>
      set(state => {
        state.page += 1;
      }),
    goPrevPage: () =>
      set(state => {
        state.page -= 1;
      }),
    setItemsPerPage: itemsPerPage =>
      set(state => {
        state.page = 1;
        state.itemsPerPage = itemsPerPage;
      }),
    setSelectedPayrolls: selectedPayrolls =>
      set(state => {
        state.selectedPayrolls = selectedPayrolls;
      }),
    appendSelectedPayroll: payrolls => {
      payrolls.forEach(newPayroll => {
        set(state => {
          state.selectedPayrolls.push(newPayroll);
        });
      });
    },
    removeSelectedPayroll: payrolls => {
      payrolls.forEach(selectedPayrollId => {
        set(state => {
          const index = state.selectedPayrolls.findIndex(
            payroll => payroll === selectedPayrollId
          );
          if (index !== -1) state.selectedPayrolls.splice(index, 1);
        });
      });
    },
    clearSelectedPayrolls: () =>
      set(state => {
        state.selectedPayrolls = [];
      }),
    setStatusFilter: status =>
      set(state => {
        state.page = 1;
        state.filters.status = status;
      }),
    setFieldFilter: ({ select, input }) =>
      set(state => {
        state.page = 1;
        state.filters.field.select = select;
        state.filters.field.input = input;
      }),
    setAgreementFilter: agreement =>
      set(state => {
        state.page = 1;
        state.filters.agreement = agreement;
      }),
    setIssuedDateFilter: issuedDate =>
      set(state => {
        state.page = 1;
        state.filters.issuedDate = issuedDate;
      }),
    setPaymentTypeFilter: paymentType => {
      if (paymentType.length >= 1) {
        set(state => {
          state.page = 1;
          state.filters.paymentType = paymentType;
        });
      }
    },
    setPaymentCesionFilter: paymentCesion => {
      if (paymentCesion.length >= 1) {
        set(state => {
          state.page = 1;
          state.filters.paymentCesion = paymentCesion;
        });
      }
    },
    getFilters: () => {
      const rawFilters = get().filters;
      const {
        field,
        paymentCesion,
        issuedDate,
        paymentType,
        status,
        agreement,
      } = rawFilters;
      // eslint-disable-next-line no-param-reassign
      return produce(rawFilters, draft => {
        delete draft.field;
        delete draft.paymentCesion;
        delete draft.paymentType;
        delete draft.agreement;
        delete draft.issuedDate;

        if (status === 'ALL') {
          delete draft.status;
        }

        if (agreement.length) {
          draft.agreementIds = agreement;
        }

        if (paymentCesion.length === 1) {
          draft.paymentCesion = paymentCesion[0] === 'Con cesión';
        }

        if (paymentType.length === 1) {
          draft.paymentType = paymentType[0] === 'Financiamiento';
        }

        if (issuedDate !== null) {
          draft.issuedDate = format(new Date(issuedDate), 'yyyy-MM-dd');
        }

        if (field.input) {
          draft.fieldKey = field.select.field;
          draft.fieldValue = field.input;
        }
      });
    },
    resetFilters: () =>
      set(state => {
        state.page = PAGE;
        state.itemsPerPage = ITEMS_PER_PAGE;
        state.filters = {
          status: PAYMENT_STATUS,
          field: PAYMENT_FIELD,
          agreement: PAYMENT_AGREEMENT,
          issuedDate: PAYMENT_ISSUED_DATE,
          paymentType: PAYMENT_TYPE,
          paymentCesion: PAYMENT_CESION,
        };
      }),
  }),
  {
    name: STORE_NAME,
    version: STORE_VERSION,
  }
);

export default PaymentInboxZustandStore;
