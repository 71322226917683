import axios from 'axios';

export const setCountryHeader = country => {
  let {
    defaults: {
      headers: { common = {} },
    },
  } = axios;
  common = {
    ...common,
    country,
  };
  axios.defaults.headers.common = common;
};

export const deleteCountryHeader = () => {
  delete axios.defaults.headers.common.country;
  delete axios.defaults.headers.common.isApiGlobal;
};

export const setAuthToken = token => {
  let {
    defaults: {
      headers: { common = {} },
    },
  } = axios;
  common = {
    ...common,
    Authorization: `Bearer ${token}`,
  };
  axios.defaults.headers.common = common;
};

export const deleteAuthToken = () => {
  delete axios.defaults.headers.common.Authorization;
};
