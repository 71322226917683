import * as Predicates from './predicates';

export const toList = obj => Object.keys(obj).map(k => obj[k]);

export const purgeNestedObj = obj => {
  return Object.entries(obj)
    .filter(([, v]) => {
      return !Predicates.isEmptyObj(v) || Predicates.hasValue(v);
    })
    .reduce((acc, [key, value]) => {
      const getValue = () => {
        if (Array.isArray(value)) {
          return value;
        }
        return value === Object(value) ? purgeNestedObj(value) : value;
      };
      const propToAdd = {
        [key]: getValue(),
      };

      const purged = () => {
        if (
          !Predicates.isObject(propToAdd[key]) ||
          !Predicates.isEmptyObj(propToAdd[key])
        ) {
          return propToAdd;
        }

        return {};
      };

      return { ...acc, ...purged() };
    }, {});
};

export const isValidObject = object =>
  object != null && typeof object === 'object';

export const isEqual = (obj1, obj2) => {
  if (!isValidObject(obj1) || !isValidObject(obj2)) {
    return false;
  }
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);

  if (props1.length !== props2.length) {
    return false;
  }
  for (let i = 0; i < props1.length; i++) {
    const val1 = obj1[props1[i]];
    const val2 = obj2[props1[i]];

    const isObjects = isValidObject(val1) && isValidObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};

export const getProp = obj => fieldName => {
  const fieldNames = fieldName.split('.');
  if (fieldNames.length === 1) {
    return obj[fieldName];
  }

  return fieldNames.reduce((acc, field) => {
    return acc[field];
  }, obj);
};
