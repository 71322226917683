import {
  FETCH_FUNDS_START,
  FETCH_FUNDS_SUCCESS,
  FETCH_FUNDS_FAILURE,
  EDIT_FUNDS_START,
  EDIT_FUNDS_SUCCESS,
  EDIT_FUNDS_FAILURE,
  REGISTER_FUNDS_START,
  REGISTER_FUNDS_SUCCESS,
  REGISTER_FUNDS_FAILURE,
  FETCH_FUND_RATES_START,
  FETCH_FUND_RATES_SUCCESS,
  FETCH_FUND_RATES_FAILURE,
  EDIT_FUND_RATES_START,
  EDIT_FUND_RATES_SUCCESS,
  EDIT_FUND_RATES_FAILURE,
  REGISTER_FUND_RATES_START,
  REGISTER_FUND_RATES_SUCCESS,
  REGISTER_FUND_RATES_FAILURE,
  UPDATE_SELECTED_FUND,
  UPDATE_SELECTED_FUND_RATE,
  RESET_FUND_FORM_DIALOG,
  DELETE_FUND_RATE_START,
  DELETE_FUND_RATE_SUCCESS,
  DELETE_FUND_RATE_FAILURE,
  FETCH_FUND_START,
  FETCH_FUND_SUCCESS,
  FETCH_FUND_FAILURE,
  RESET_BANK_ACCOUNT_DIALOG,
  SELECT_BANK_ACCOUNT,
  RESET_SELECTED_BANK_ACCOUNT,
  RESET_MESSAGES_RESPONSE,
  FETCH_FUND_PAYROLL_START,
  FETCH_FUND_PAYROLL_SUCCESS,
  FETCH_FUND_PAYROLL_FAILURE,
  RESET_FUND_PAYROLL,
  FETCH_FUNDRATES_BYFUND_START,
  FETCH_FUNDRATES_BYFUND_SUCCESS,
  FETCH_FUNDRATES_BYFUND_FAILURE,
  FETCH_ORDER_INVOICE_FUND_START,
  FETCH_ORDER_INVOICE_FUND_SUCCESS,
  FETCH_ORDER_INVOICE_FUND_FAILURE,
  FETCH_ORDER_INVOICE_FUND_RATES_START,
  FETCH_ORDER_INVOICE_FUND_RATES_SUCCESS,
  FETCH_ORDER_INVOICE_FUND_RATES_FAILURE,
  UPDATE_ORDER_INVOICE_FUND2_START,
  UPDATE_ORDER_INVOICE_FUND2_SUCCESS,
  UPDATE_ORDER_INVOICE_FUND2_FAILURE,
  DELETE_DEBT_PERIOD_START,
  DELETE_DEBT_PERIOD_SUCCESS,
  DELETE_DEBT_PERIOD_FAILURE,
  REGISTER_DEBT_PERIOD_SUCCESS,
  UPDATE_DEBT_PERIOD_SUCCESS,
  DELETE_ORDER_INVOICE_FUND_RATE_START,
  DELETE_ORDER_INVOICE_FUND_RATE_SUCCESS,
  DELETE_ORDER_INVOICE_FUND_RATE_FAILURE,
  ADD_FUND_FINANCING_START,
  ADD_FUND_FINANCING_SUCCESS,
  ADD_FUND_FINANCING_FAILURE,
  UPDATE_ORDER_INVOICE_FUND_SUCCESS,
  VERIFY_ORDER_FULLY_FINANCED_START,
  VERIFY_ORDER_FULLY_FINANCED_SUCCESS,
  VERIFY_ORDER_FULLY_FINANCED_FAILURE,
  UPLOAD_CSV_FUNDS_START,
  UPLOAD_CSV_FUNDS_SUCCESS,
  UPLOAD_CSV_FUNDS_FAILURE,
  RESET_BULK_CSV_FUNDS_ERRORS,
} from '../actions/types';

const initialState = {
  loadingFunds: false,
  funds: [],
  fundsPagination: undefined,
  ladingFund: false,
  fund: {},
  activeFunds: [],
  registeredFund: false,
  updatedFund: false,
  saving: false,
  saved: false,
  fundError: false,
  fundId: 0,
  selectedFund: {},
  selectedFundRate: undefined,
  fundRates: [],
  loadingFundRates: false,
  total: 0,
  errors: '',
  registeringBankAccount: false,
  registeredBankAccount: false,
  updatingDefaultBankAccount: false,
  deletingBankAccount: false,
  deletedBankAccount: false,
  selectedBankAccount: {},
  msgSuccess: '',
  msgError: '',
  fetchingFundPayroll: false,
  fundPayroll: [],
  deletingFundRates: false,
  deletedFundRates: false,

  fundRatesFund: {},
  fundRatesFundIsLoading: false,
  fundRatesFundError: null,
  fundRatesFundFetched: false,
  fundRatesFundDeleted: false,

  orderInvoicesFund: [],
  orderOwnerInvoicesFund: null,
  orderInvoicesFundIsLoading: false,
  orderInvoicesFundError: null,
  orderInvoicesFundFetched: false,

  createOrderInvoiceFundIsLoading: false,
  createOrderInvoiceFundError: null,
  orderInvoiceFundWasCreated: false,

  orderInvoiceFundRates: [],
  orderInvoiceFundRatesIsLoading: false,
  orderInvoiceFundRatesError: null,
  orderInvoiceFundRatesFetched: false,

  orderInvoiceFundWasUpdated: false,
  updateOrderInvoiceFundIsLoading: false,
  updateOrderInvoiceFundError: null,

  deleteOrderInvoiceFundIsLoading: false,
  deleteOrderInvoiceFundError: null,
  orderInvoiceFundWasDeleted: false,

  deleteOrderInvoiceFundRateIsLoading: false,
  deleteOrderInvoiceFundRateError: null,
  orderInvoiceFundRateWasDeleted: false,

  orderIdVerified: null,
  orderIsFullyFinanced: false,
  orderIsFullyFinancedLoading: false,
  orderIsFullyFinancedError: null,

  uploadCsvFundsIsLoading: false,
  uploadCsvFundsError: null,
  uploadCsvFundsErrorLines: null,
  csvFundsWasUploaded: false,
};

const fetchFundsStart = state => {
  return { ...state, loadingFunds: true };
};

const fetchFundsSuccess = (state, payload) => {
  const activeFunds = payload?.data?.filter(
    fund => fund.active === 'ACTIVE' || fund.active === true
  );
  return {
    ...state,
    loadingFunds: false,
    funds: payload.data,
    fundsPagination: payload.pagination,
    activeFunds,
    total: payload.length,
  };
};

const fetchFundsError = (state, payload) => {
  return {
    ...state,
    loadingFunds: false,
    funds: initialState.funds,
    errors: payload.errorCode,
  };
};

const editFundsStart = state => {
  return {
    ...state,
    updatedFund: false,
    saving: true,
    saved: false,
    loadingFunds: true,
  };
};

const editFundSuccess = (state, payload) => {
  const { funds } = state;
  const { id } = payload;

  const updatedFunds = Array.isArray(payload?.data)
    ? [...payload?.data]
    : funds.map(e => {
        if (e.id === id) {
          return {
            ...payload,
          };
        }
        return e;
      });

  return {
    ...state,
    saving: false,
    updatedFund: true,
    saved: true,
    loadingFunds: false,
    funds: updatedFunds,
    total: payload.count,
    msgSuccess: 'Fondo actualizado',
  };
};

const editFundError = state => {
  return {
    ...state,
    updatedFund: false,
    saving: false,
    saved: false,
    loadingFunds: false,
    msgError: 'Error actualizando fondo',
  };
};

const fetchFundRatesByFundStart = state => {
  return {
    ...state,
    fundRatesFundIsLoading: true,
    fundRatesFundError: null,
    fundRatesFundFetched: false,
  };
};

const fetchFundRatesByFundSuccess = (state, payload) => {
  return {
    ...state,
    fundRatesFundIsLoading: false,
    fundRatesFundFetched: true,
    fundRatesFund: { ...state.fundRatesFund, ...payload },
  };
};

const fetchFundRatesByFundFailure = (state, payload) => {
  return {
    ...state,
    fundRatesFundIsLoading: false,
    fundRatesFundError: payload.errorCode,
    fundRatesFundFetched: false,
  };
};

const fetchOrderInvoicesFundStart = (state, payload) => {
  return {
    ...state,
    orderInvoicesFund: [],
    orderOwnerInvoicesFund: payload.orderId,
    orderInvoicesFundIsLoading: true,
    orderInvoicesFundError: null,
    orderInvoicesFundFetched: false,
  };
};

const fetchOrderInvoicesFundSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoicesFund: payload,
    orderInvoicesFundIsLoading: false,
    orderInvoicesFundFetched: true,
  };
};

const fetchOrderInvoicesFundFailure = (state, payload) => {
  return {
    ...state,
    orderInvoicesFundIsLoading: false,
    orderInvoicesFundError: payload.errorCode,
  };
};

const createOrderInvoiceFundStart = state => {
  return {
    ...state,
    createOrderInvoiceFundIsLoading: true,
    createOrderInvoiceFundError: null,
    orderInvoiceFundWasCreated: false,
  };
};

const createOrderInvoiceFundSuccess = (state, payload) => {
  const newOif = payload?.orderInvoiceFund ?? payload;
  newOif.interest = parseInt(newOif.interest, 10);
  newOif.orderInvoiceId = parseInt(newOif.orderInvoiceId, 10);
  return {
    ...state,
    createOrderInvoiceFundIsLoading: false,
    orderInvoiceFundWasCreated: true,
    orderInvoicesFund: [...state.orderInvoicesFund, newOif],
  };
};

const createOrderInvoiceFundFailure = (state, payload) => {
  return {
    ...state,
    createOrderInvoiceFundIsLoading: false,
    createOrderInvoiceFundError: payload.errorCode,
  };
};

const fetchOrderInvoiceFundRatesStart = state => {
  return {
    ...state,
    orderInvoiceFundRatesIsLoading: true,
    orderInvoiceFundRatesError: null,
    orderInvoiceFundRatesFetched: false,
  };
};

const fetchOrderInvoiceFundRatesSuccess = (state, payload) => {
  const listWithoutUpdated = state.orderInvoiceFundRates.filter(
    oifr => !payload.some(oifr2 => oifr2.id === oifr.id)
  );

  return {
    ...state,
    orderInvoiceFundRates: [...listWithoutUpdated, ...payload],
    orderInvoiceFundRatesIsLoading: false,
    orderInvoiceFundRatesFetched: true,
  };
};

const deleteOrderInvoiceFundRateStart = state => {
  return {
    ...state,
    deleteOrderInvoiceFundRateIsLoading: true,
    orderInvoiceFundRateWasDeleted: false,
    deleteOrderInvoiceFundRateError: null,
  };
};

const deleteOrderInvoiceFundRateSuccess = (state, payload) => {
  return {
    ...state,
    deleteOrderInvoiceFundRateIsLoading: false,
    orderInvoiceFundRateWasDeleted: true,
    orderInvoiceFundRates: state.orderInvoiceFundRates.filter(
      oifr => oifr.id !== payload.id
    ),
  };
};

const deleteOrderInvoiceFundRateFailure = (state, payload) => {
  return {
    ...state,
    deleteOrderInvoiceFundRateIsLoading: false,
    deleteOrderInvoiceFundRateError: payload.errorCode,
  };
};

const createOrderInvoiceFundRateSuccess = (state, payload) => {
  const orderInvoiceFundRates = [...state.orderInvoiceFundRates];
  // APICL esto es un fix solo para CL por la respuesta que entrega.
  if (payload) {
    orderInvoiceFundRates.push(payload);
  }
  return {
    ...state,
    orderInvoiceFundRates,
  };
};

const updateOrderInvoiceFundRateSuccess = (state, payload) => {
  const oifrUpdated = payload?.period?.orderInvoiceFundRate || payload;
  return {
    ...state,
    orderInvoiceFundRates: state.orderInvoiceFundRates.map(oifr =>
      oifr.id === oifrUpdated.id ? oifrUpdated : oifr
    ),
  };
};

const fetchOrderInvoiceFundRatesFailure = (state, payload) => {
  return {
    ...state,
    orderInvoiceFundRatesIsLoading: false,
    orderInvoiceFundRatesError: payload.errorCode,
  };
};

const updateOrderInvoiceFundWithRatesStart = state => {
  return {
    ...state,
    orderInvoiceFundWasUpdated: false,
    updateOrderInvoiceFundIsLoading: true,
    updateOrderInvoiceFundError: null,
  };
};

const updateOrderInvoiceFundWithRatesSuccess = state => {
  return {
    ...state,
    orderInvoiceFundWasUpdated: true,
    updateOrderInvoiceFundIsLoading: false,
  };
};

const updateOrderInvoiceFundWithRatesFailure = (state, payload) => {
  return {
    ...state,
    updateOrderInvoiceFundIsLoading: false,
    updateOrderInvoiceFundError: payload.errorCode,
  };
};

const deleteOrderInvoiceFundStart = state => {
  return {
    ...state,
    deleteOrderInvoiceFundIsLoading: true,
    deleteOrderInvoiceFundError: null,
    orderInvoiceFundWasDeleted: false,
  };
};

const deleteOrderInvoiceFundSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoicesFund: state.orderInvoicesFund.filter(
      oif => oif.id !== payload.orderInvoiceFundId
    ),
    deleteOrderInvoiceFundIsLoading: false,
    orderInvoiceFundWasDeleted: true,
  };
};

const deleteOrderInvoiceFundFailure = (state, payload) => {
  return {
    ...state,
    deleteOrderInvoiceFundIsLoading: false,
    deleteOrderInvoiceFundError: payload.errorCode,
  };
};

const updateOrderInvoiceFundSuccess = (state, payload) => {
  const oifUpdated = payload.fundFinancing || payload;
  return {
    ...state,
    orderInvoicesFund: state.orderInvoicesFund.map(oif =>
      oif.id === oifUpdated.id ? oifUpdated : oif
    ),
  };
};

const verifyIfOrderIsFullyFinancedStart = (state, payload) => {
  return {
    ...state,
    orderIdVerified: payload.orderId,
    orderIsFullyFinanced: false,
    orderIsFullyFinancedLoading: true,
    orderIsFullyFinancedError: null,
  };
};

const verifyIfOrderIsFullyFinancedSuccess = (state, payload) => {
  return {
    ...state,
    orderIsFullyFinanced: payload.orderIsFullyFinanced,
    orderIsFullyFinancedLoading: false,
  };
};

const verifyIfOrderIsFullyFinancedFailure = (state, payload) => {
  return {
    ...state,
    orderIsFullyFinancedLoading: false,
    orderIsFullyFinancedError: payload.errorCode,
  };
};

const registerFundStart = state => {
  return { ...state, saving: true, saved: false, registeredFund: false };
};

const registerFundsSuccess = (state, payload) => {
  const { funds } = state;
  const updatedFunds = Array.isArray(payload.data)
    ? [...payload.data]
    : [{ ...payload.data }, ...funds];

  return {
    ...state,
    registeredFund: true,
    saving: false,
    saved: true,
    msgSuccess: 'Fondo registrado',
    funds: updatedFunds,
    total: payload.count,
  };
};

const registerFundFailure = state => {
  return {
    ...state,
    saving: false,
    saved: false,
    funds: initialState.funds,
    msgError: 'Error registrando fondo',
  };
};

const uploadCsvFundsStart = state => {
  return {
    ...state,
    uploadCsvFundsIsLoading: true,
    uploadCsvFundsError: null,
    uploadCsvFundsErrorLines: null,
    csvFundsWasUploaded: false,
  };
};
const uploadCsvFundsSuccess = state => {
  return {
    ...state,
    uploadCsvFundsIsLoading: false,
    csvFundsWasUploaded: true,
  };
};
const uploadCsvFundsFailure = (state, payload) => {
  return {
    ...state,
    uploadCsvFundsIsLoading: false,
    uploadCsvFundsError: payload.errorCode,
    uploadCsvFundsErrorLines: payload.errorLines,
  };
};

const resetUploadCsvErrors = state => {
  return {
    ...state,
    uploadCsvFundsError: null,
    uploadCsvFundsErrorLines: null,
  };
};

const editFundRatesSuccess = (state, payload) => {
  const { fundRates } = state;
  const updatedFundRates =
    payload?.data ||
    fundRates.map(e => {
      if (e.id) {
        return { ...payload };
      }
      return e;
    });
  return {
    ...state,
    saving: false,
    saved: true,
    fundRates: [...updatedFundRates],
    msgSuccess: 'Tasa editada',
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FUNDS_START:
      return fetchFundsStart(state);
    case FETCH_FUNDS_SUCCESS:
      return fetchFundsSuccess(state, payload);
    case FETCH_FUNDS_FAILURE:
      return fetchFundsError(state, payload);
    case EDIT_FUNDS_START:
      return editFundsStart(state);
    case EDIT_FUNDS_SUCCESS:
      return editFundSuccess(state, payload);
    case EDIT_FUNDS_FAILURE:
      return editFundError(state, payload);
    case REGISTER_FUNDS_START:
      return registerFundStart(state);
    case REGISTER_FUNDS_SUCCESS:
      return registerFundsSuccess(state, payload);
    case REGISTER_FUNDS_FAILURE:
      return registerFundFailure(state);
    case UPDATE_SELECTED_FUND:
      return {
        ...state,
        fundId: payload.fundId,
        selectedFund: payload.selectedFund,
      };
    case RESET_FUND_FORM_DIALOG:
      return {
        ...initialState,
        funds: state.funds,
        fundRates: state.fundRates,
        selectedFund: {},
        selectedFundRate: undefined,
      };
    case FETCH_FUND_RATES_START:
      return { ...state, loadingFundRates: true };
    case FETCH_FUND_RATES_SUCCESS:
      return {
        ...state,
        loadingFundRates: false,
        fundRates: payload,
      };
    case FETCH_FUND_RATES_FAILURE:
      return {
        ...state,
        loadingFundRates: false,
        fundRates: initialState.fundRates,
        errors: payload.errorCode,
      };
    case REGISTER_FUND_RATES_START:
      return {
        ...state,
        loadingFundRates: true,
        saving: true,
        saved: false,
      };
    case REGISTER_FUND_RATES_SUCCESS: {
      const fundRates = Array.isArray(payload)
        ? payload
        : [...state.fundRates, payload].sort((a, b) => a.days - b.days);
      return {
        ...state,
        saving: false,
        saved: true,
        loadingFundRates: false,
        fundRates,
      };
    }
    case REGISTER_FUND_RATES_FAILURE:
      return {
        ...state,
        saving: false,
        saved: false,
        loadingFundRates: false,
        errors: payload.errorCode,
      };
    case EDIT_FUND_RATES_START:
      return { ...state, saving: true, saved: false };
    case EDIT_FUND_RATES_SUCCESS:
      return editFundRatesSuccess(state, payload);
    case EDIT_FUND_RATES_FAILURE:
      return {
        ...state,
        saving: false,
        saved: false,
        errors: payload.errors,
      };
    case UPDATE_SELECTED_FUND_RATE:
      return {
        ...state,
        selectedFundRate: payload.selectedFundRate,
      };
    case DELETE_FUND_RATE_START:
      return {
        ...state,
        deletingFundRates: true,
        deletedFundRates: false,
        fundRatesFundDeleted: false,
      };
    case DELETE_FUND_RATE_SUCCESS:
      return {
        ...state,
        fundRatesFundDeleted: true,
        deletingFundRates: false,
        deletedFundRates: true,
        fundRates: payload.data,
        msgSuccess: 'Tasa eliminada',
      };
    case DELETE_FUND_RATE_FAILURE:
      return {
        ...state,
        fundRatesFundDeleted: false,
        deletingFundRates: false,
        deletedFundRates: false,
        fundRates: initialState.fundRates,
        msgError: 'Error eliminando tasa',
        errors: payload.errorCode,
      };
    case FETCH_FUND_START:
      return { ...state, loadingFund: true };
    case FETCH_FUND_SUCCESS:
      return {
        ...state,
        loadingFund: false,
        fund: payload.fundData ?? payload,
      };
    case FETCH_FUND_FAILURE:
      return {
        ...state,
        loadingFund: false,
        errors: payload.errorCode,
      };

    case RESET_BANK_ACCOUNT_DIALOG:
      return {
        ...state,
        registeringBankAccount: false,
        registeredBankAccount: false,
        errors: '',
      };
    case SELECT_BANK_ACCOUNT:
      return {
        ...state,
        selectedBankAccount: payload.bankAccount,
      };
    case RESET_SELECTED_BANK_ACCOUNT:
      return {
        ...state,
        selectedBankAccount: {},
      };

    case RESET_MESSAGES_RESPONSE:
      return {
        ...state,
        msgSuccess: '',
        msgError: '',
        saving: false,
      };
    case FETCH_FUND_PAYROLL_START:
      return {
        ...state,
        fetchingFundPayroll: true,
        fundPayroll: [],
      };
    case FETCH_FUND_PAYROLL_SUCCESS:
      return {
        ...state,
        fetchingFundPayroll: false,
        fundPayroll: payload.fundPayroll,
      };
    case FETCH_FUND_PAYROLL_FAILURE:
      return {
        ...state,
        fetchingFundPayroll: false,
        fundPayroll: [],
      };
    case RESET_FUND_PAYROLL:
      return {
        ...state,
        fundPayroll: [],
      };
    case FETCH_FUNDRATES_BYFUND_START:
      return fetchFundRatesByFundStart(state);
    case FETCH_FUNDRATES_BYFUND_SUCCESS:
      return fetchFundRatesByFundSuccess(state, payload);
    case FETCH_FUNDRATES_BYFUND_FAILURE:
      return fetchFundRatesByFundFailure(state, payload);
    case FETCH_ORDER_INVOICE_FUND_START:
      return fetchOrderInvoicesFundStart(state, payload);
    case FETCH_ORDER_INVOICE_FUND_SUCCESS:
      return fetchOrderInvoicesFundSuccess(state, payload);
    case FETCH_ORDER_INVOICE_FUND_FAILURE:
      return fetchOrderInvoicesFundFailure(state, payload);
    case ADD_FUND_FINANCING_START:
      return createOrderInvoiceFundStart(state, payload);
    case ADD_FUND_FINANCING_SUCCESS:
      return createOrderInvoiceFundSuccess(state, payload);
    case ADD_FUND_FINANCING_FAILURE:
      return createOrderInvoiceFundFailure(state, payload);
    case FETCH_ORDER_INVOICE_FUND_RATES_START:
      return fetchOrderInvoiceFundRatesStart(state);
    case FETCH_ORDER_INVOICE_FUND_RATES_SUCCESS:
      return fetchOrderInvoiceFundRatesSuccess(state, payload);
    case REGISTER_DEBT_PERIOD_SUCCESS:
      return createOrderInvoiceFundRateSuccess(state, payload);
    case UPDATE_DEBT_PERIOD_SUCCESS:
      return updateOrderInvoiceFundRateSuccess(state, payload);
    case DELETE_DEBT_PERIOD_START:
      return deleteOrderInvoiceFundRateStart(state);
    case DELETE_DEBT_PERIOD_SUCCESS:
      return deleteOrderInvoiceFundRateSuccess(state, payload);
    case DELETE_DEBT_PERIOD_FAILURE:
      return deleteOrderInvoiceFundRateFailure(state, payload);
    case FETCH_ORDER_INVOICE_FUND_RATES_FAILURE:
      return fetchOrderInvoiceFundRatesFailure(state, payload);
    case UPDATE_ORDER_INVOICE_FUND2_START:
      return updateOrderInvoiceFundWithRatesStart(state);
    case UPDATE_ORDER_INVOICE_FUND2_SUCCESS:
      return updateOrderInvoiceFundWithRatesSuccess(state);
    case UPDATE_ORDER_INVOICE_FUND2_FAILURE:
      return updateOrderInvoiceFundWithRatesFailure(state, payload);
    case DELETE_ORDER_INVOICE_FUND_RATE_START:
      return deleteOrderInvoiceFundStart(state);
    case DELETE_ORDER_INVOICE_FUND_RATE_SUCCESS:
      return deleteOrderInvoiceFundSuccess(state, payload);
    case DELETE_ORDER_INVOICE_FUND_RATE_FAILURE:
      return deleteOrderInvoiceFundFailure(state, payload);
    case UPDATE_ORDER_INVOICE_FUND_SUCCESS:
      return updateOrderInvoiceFundSuccess(state, payload);
    case VERIFY_ORDER_FULLY_FINANCED_START:
      return verifyIfOrderIsFullyFinancedStart(state, payload);
    case VERIFY_ORDER_FULLY_FINANCED_SUCCESS:
      return verifyIfOrderIsFullyFinancedSuccess(state, payload);
    case VERIFY_ORDER_FULLY_FINANCED_FAILURE:
      return verifyIfOrderIsFullyFinancedFailure(state, payload);
    case UPLOAD_CSV_FUNDS_START:
      return uploadCsvFundsStart(state);
    case UPLOAD_CSV_FUNDS_SUCCESS:
      return uploadCsvFundsSuccess(state);
    case UPLOAD_CSV_FUNDS_FAILURE:
      return uploadCsvFundsFailure(state, payload);
    case RESET_BULK_CSV_FUNDS_ERRORS:
      return resetUploadCsvErrors(state);
    default:
      return state;
  }
};
