import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const addFundFinancingToInvoiceService = async fundFinancing => {
  try {
    const { data } = await axios.post('/api/orderinvoicefund', fundFinancing);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default addFundFinancingToInvoiceService;
