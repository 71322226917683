import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Typography,
  Grid,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import {
  GLOBAL_REASONS,
  STATUSES_VERIFIED,
  STATUS_VERIFIED,
  STATUS_POST_VERIFIED,
  STATUS_REJECTED,
  STATUS_APPEALED,
  STATUS_HARD_COLLECTION,
  ITEM_TYPE_INVOICE,
  ITEM_TYPE_BULK_INVOICE,
  DATE_FORMAT,
  STATUS_UNVERIFIED,
  STATUS_ACTIVE,
  STATUS_DEFAULT,
  STATUS_COMPLETE,
  STATUS_PAID,
  STATUS_IN_CONCILIATION,
} from '../../helpers/Constants';
import BaseDialog from './BaseDialog';
import verifyDecimals from '../../helpers/validation/decimals';
import { features } from '../../config/features';
import CheckButton from '../elements/CheckButton';
import {
  dateWithoutFormatter,
  isWeekend,
  setHourString,
} from '../../helpers/DateUtils';
import AlertForm from '../elements/AlertForm';
import useStatuses from '../../hooks/useStatuses';
import { checkStatusAuth } from '../../helpers/validation/auth';

const useStyles = makeStyles(() => ({
  dateWithCheckbox: {
    marginBottom: 0,
  },
  spacingLeft: {
    marginLeft: 15,
  },
}));

const FacturasUpdateDialog = ({
  open,
  handleCloseDialog,
  type,
  handleSubmit,
  showBaseRateInput,
  invoices,
  warning,
  isLoading,
  wasUpdated,
  statusOrder,
}) => {
  const classes = useStyles();
  const { getStatuses } = useStatuses();
  const { rules } = useSelector(state => state.auth);
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(null);
  const [verificationStatus, setStatusVerification] = useState(null);
  const [baseRate, setBaseRate] = useState(null);
  const [verificationStatusReason, setStatusVerificationReason] =
    useState(null);
  const [statusReason, setStatusReason] = useState(null);
  const [showDefaultDateInput, setShowDefaultDateInput] = useState(false);
  const [fechaVencimiento, setFechaVencimiento] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [fundPaymentDate, setFundPaymentDate] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(null);
  const [fundExpirationDate, setFundExpirationDate] = useState(null);
  const [notCalculateSurplus, setNotCalculateSurplus] = useState(false);
  const country = useSelector(state => state.config.country);
  const { invoicesBulkError } = useSelector(state => state.order);
  const countryFeatures = features[country];

  const { holidays } = useSelector(state => state.holidays);
  const countryHolidays = holidays?.[country];

  const [checkPayment, setCheckPayment] = useState({
    checkPaymentConfirmed: false,
    checkPaymentDate: false,
    checkFundPaymentDate: false,
  });

  const showSelectReason =
    countryFeatures.orderActions.changeStatusReasonInvoice &&
    [ITEM_TYPE_INVOICE, ITEM_TYPE_BULK_INVOICE].includes(type) &&
    [
      STATUS_HARD_COLLECTION,
      STATUS_VERIFIED,
      STATUS_POST_VERIFIED,
      STATUS_REJECTED,
      STATUS_APPEALED,
    ].includes(status);

  const showVerificationStatus =
    countryFeatures.orderActions.changeVerificationStatus &&
    verificationStatus !== null;

  useEffect(() => {
    ValidatorForm.addValidationRule('maxDecimals', value => {
      if (value) {
        verifyDecimals(value);
      }
      return true;
    });
  });

  useEffect(() => {
    if (wasUpdated) {
      setChecked(false);
    }
  }, [wasUpdated]);

  const handleDisabledDates = date => {
    // Disable saturday and sunday on DatePickers
    return (
      isWeekend(date) ||
      countryHolidays?.some(h =>
        moment.utc(date).isSame(moment.utc(h.date), 'day')
      )
    );
  };

  const handleFormSubmit = () => {
    const data = {
      fechaVencimiento,
      expirationDate: fechaVencimiento,
      baseRate,
      fundExpirationDate,
      status,
      statusReason,
      verificationStatus,
      verificationReason: verificationStatusReason,
      defaultDate,
    };

    if (checkPayment.checkPaymentDate) {
      setPaymentDate(null);
    } else {
      data.paymentDate = paymentDate;
    }

    if (checkPayment.checkFundPaymentDate) {
      setFundPaymentDate(null);
    } else {
      data.fundPaymentDate = fundPaymentDate;
    }

    if (checkPayment.checkPaymentConfirmed) {
      setPaymentConfirmed(null);
    } else {
      data.paymentConfirmed = paymentConfirmed;
    }
    handleSubmit({ item: invoices, type, data, notCalculateSurplus });
  };

  const handleStatusChange = status => {
    setStatus(status);
    setShowDefaultDateInput(status === STATUS_DEFAULT);
    setDefaultDate(
      status === STATUS_DEFAULT ? dateWithoutFormatter(Date.now()) : null
    );
  };

  const handleCheck = () => {
    if (
      fechaVencimiento ||
      paymentDate ||
      fundPaymentDate ||
      paymentConfirmed ||
      fundExpirationDate ||
      status ||
      verificationStatus
    ) {
      setChecked(!checked);
    }
  };

  const handleChangeCheckbox = event => {
    setCheckPayment({
      ...checkPayment,
      [event.target.name]: event.target.checked,
    });
    switch (event.target.name) {
      case 'checkPaymentConfirmed':
        setPaymentConfirmed(null);
        break;
      case 'checkPaymentDate':
        setPaymentDate(null);
        break;
      case 'checkFundPaymentDate':
        setFundPaymentDate(null);
        break;
      default:
        break;
    }
  };

  const getTextValidator = props => (
    <TextValidator
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      validators={['required']}
      errorMessages={['Campo requerido']}
    />
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Edición masiva de facturas"
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha de vencimiento PYME"
              id="fechaVencimiento"
              name="fechaVencimiento"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(fechaVencimiento)}
              onChange={date => setFechaVencimiento(setHourString(date))}
              shouldDisableDate={date => handleDisabledDates(date)}
              disabled={checked || statusOrder === STATUS_ACTIVE}
              minDateMessage="Fecha inválida"
              autoOk
            />
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha de vencimiento FONDO"
              name="fundExpirationDate"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(fundExpirationDate)}
              onChange={date => setFundExpirationDate(setHourString(date))}
              disabled={checked}
              minDateMessage="Fecha inválida"
              autoOk
            />
            <DatePicker
              className={classes.dateWithCheckbox}
              inputVariant="outlined"
              fullWidth
              label="Fecha pago confirmada deudor"
              name="paymentConfirmed"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(paymentConfirmed)}
              onChange={date => setPaymentConfirmed(setHourString(date))}
              disabled={checked || checkPayment.checkPaymentConfirmed}
              minDateMessage="Fecha inválida"
              autoOk
              id="paymentConfirmed"
            />
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <FormControlLabel
                labelPlacement="start"
                disabled={checked}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checkPayment.checkPaymentConfirmed}
                    onChange={handleChangeCheckbox}
                    name="checkPaymentConfirmed"
                    color="primary"
                  />
                }
                label={<Typography variant="caption">Borrar fecha</Typography>}
              />
            </Box>
            <DatePicker
              className={classes.dateWithCheckbox}
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de pago pyme"
              name="paymentDate"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(paymentDate)}
              onChange={date => setPaymentDate(setHourString(date))}
              disabled={checked || checkPayment.checkPaymentDate}
              shouldDisableDate={date => handleDisabledDates(date)}
              minDateMessage="Fecha inválida"
              maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
              disableFuture
            />
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <FormControlLabel
                labelPlacement="start"
                disabled={checked}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checkPayment.checkPaymentDate}
                    onChange={handleChangeCheckbox}
                    name="checkPaymentDate"
                    color="primary"
                  />
                }
                label={<Typography variant="caption">Borrar fecha</Typography>}
              />
            </Box>
            <DatePicker
              className={classes.dateWithCheckbox}
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de pago fondo"
              name="fundPaymentDate"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(fundPaymentDate)}
              onChange={date => setFundPaymentDate(setHourString(date))}
              disabled={checked || checkPayment.checkFundPaymentDate}
            />
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <FormControlLabel
                labelPlacement="start"
                disabled={checked}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checkPayment.checkFundPaymentDate}
                    onChange={handleChangeCheckbox}
                    name="checkFundPaymentDate"
                    color="primary"
                  />
                }
                label={<Typography variant="caption">Borrar fecha</Typography>}
              />
            </Box>
          </MuiPickersUtilsProvider>
          <>
            {countryFeatures.orderActions.changeStatusInvoice && (
              <>
                <SelectValidator
                  variant="outlined"
                  fullWidth
                  id="operation-status-select"
                  value={status}
                  onChange={e => handleStatusChange(e.target.value)}
                  disabled={checked}
                  label="Estado"
                  dontvalidate="false"
                >
                  {getStatuses(type).map(s => (
                    <MenuItem
                      key={s.key}
                      value={s.key}
                      disabled={!checkStatusAuth(s.key, 'INVOICE', rules)}
                    >
                      <Typography variant="body1">{s.label}</Typography>
                    </MenuItem>
                  ))}
                </SelectValidator>

                {[
                  STATUS_COMPLETE,
                  STATUS_PAID,
                  STATUS_IN_CONCILIATION,
                ].includes(status) && (
                  <Box className={classes.spacingLeft}>
                    <Typography variant="body1">
                      <Box fontWeight="fontWeightBold">Excepción</Box>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notCalculateSurplus}
                          onChange={() =>
                            setNotCalculateSurplus(!notCalculateSurplus)
                          }
                          disabled={checked}
                          name="notCalculateSurplus"
                          color="primary"
                        />
                      }
                      label="No calcular los excedentes"
                    />
                  </Box>
                )}
              </>
            )}

            {showDefaultDateInput && (
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  disabled={checked}
                  label="Fecha de estado en default"
                  name="defaultDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(defaultDate)}
                  onChange={date => setDefaultDate(date)}
                  autoOk
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            )}
            {showSelectReason && (
              <SelectValidator
                variant="outlined"
                fullWidth
                name="operationReason"
                id="operation-reason-select"
                value={statusReason}
                onChange={e => setStatusReason(e.target.value)}
                disabled={checked}
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
              >
                {GLOBAL_REASONS.filter(
                  r =>
                    r.country.includes(country) &&
                    r.type === `INVOICE_${status}`
                ).map(s => (
                  <MenuItem key={s.value} value={s.value}>
                    <Typography variant="body1">{s.label}</Typography>
                  </MenuItem>
                ))}
              </SelectValidator>
            )}
            <SelectValidator
              variant="outlined"
              id="operation-status-select"
              value={verificationStatus}
              onChange={e => setStatusVerification(e.target.value)}
              disabled={checked}
              label="Estado de verificación"
              dontvalidate="false"
              fullWidth
            >
              {STATUSES_VERIFIED.map(s => (
                <MenuItem key={s.key} value={s.key}>
                  <Typography variant="body1">{s.label}</Typography>
                </MenuItem>
              ))}
            </SelectValidator>
            {showVerificationStatus &&
              verificationStatus !== STATUS_UNVERIFIED && (
                <SelectValidator
                  variant="outlined"
                  name="operationReason"
                  id="operation-reason-select"
                  value={verificationStatusReason}
                  disabled={checked}
                  onChange={e => setStatusVerificationReason(e.target.value)}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  label="Razón"
                  fullWidth
                >
                  {GLOBAL_REASONS.filter(
                    r =>
                      r.country.includes(country) &&
                      r.type === `INVOICE_${verificationStatus}`
                  ).map(s => (
                    <MenuItem key={s.value} value={s.value}>
                      {s.label}
                    </MenuItem>
                  ))}
                </SelectValidator>
              )}
          </>
          {showBaseRateInput && (
            <TextValidator
              variant="outlined"
              fullWidth
              id="baseRate-invoice"
              label="Tasa base (%)"
              InputProps={{ step: 0.0001 }}
              type="number"
              name="baseRate"
              disabled={checked}
              value={baseRate}
              aria-describedby="baseRate"
              onChange={e => setBaseRate(e.target.value)}
              validators={['isPositive', 'maxDecimals']}
              errorMessages={[
                'Valor debe ser mayor o igual a 0',
                'Valor puede contener 4 decimales',
              ]}
            />
          )}

          {(checkPayment.checkPaymentDate ||
            checkPayment.checkPaymentConfirmed) && (
            <AlertForm
              variant="info"
              message="Al guardar se borrarán las fechas de pago indicadas y los campos quedarán vacios."
            />
          )}
          {warning && !isLoading && (
            <AlertForm variant="info" message={warning} />
          )}

          {invoicesBulkError && (
            <AlertForm variant="error" message={invoicesBulkError} />
          )}

          <Grid item xs={12}>
            <CheckButton
              check={checked}
              handleCheck={handleCheck}
              labelButton="Guardar"
              loading={isLoading}
            />
          </Grid>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

FacturasUpdateDialog.defaultProps = {
  showBaseRateInput: false,
  invoices: [],
  warning: '',
  isLoading: false,
  wasUpdated: false,
};

FacturasUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showBaseRateInput: PropTypes.bool,
  invoices: PropTypes.arrayOf(Object),
  warning: PropTypes.string,
  isLoading: PropTypes.bool,
  wasUpdated: PropTypes.bool,
  statusOrder: PropTypes.string.isRequired,
};

export default FacturasUpdateDialog;
