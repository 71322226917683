import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGOUT_USER,
  SESSION_EXPIRED,
  SET_CURRENT_USER,
  SET_RULES,
  WS_UPDATE_ROLE,
} from '../actions/types';
import isEmpty from '../helpers/validation/is-empty';

const initialState = {
  loginSubmit: false,
  isAuthenticated: false,
  expired: false,
  expires: 0,
  errors: null,
  token: null,
  user: {},
  clearSession: false,
  rules: {},
};

export default function (state = initialState, action) {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(payload),
        user: payload.decoded.data,
        token: payload.token,
        expired: false,
        expires: payload.decoded.exp,
        errors: null,
      };

    case LOGIN_BEGIN: {
      return {
        ...state,
        loginSubmit: true,
        errors: null,
      };
    }

    case LOGIN_ERROR: {
      return {
        ...state,
        loginSubmit: false,
        errors: payload.errorCode,
      };
    }
    case LOGOUT_USER: {
      return {
        ...initialState,
      };
    }
    case SESSION_EXPIRED: {
      return {
        ...initialState,
        expired: true,
      };
    }
    case WS_UPDATE_ROLE: {
      const {
        data: { roles: [role] = [] },
      } = payload;
      const { user } = state;
      const { roles: userRoles } = user;
      const isRoleUser = userRoles.some(({ id }) => id === role.id);
      let clearSession = false;
      if (isRoleUser) {
        clearSession = true;
      }
      return { ...state, clearSession };
    }
    case SET_RULES:
      return {
        ...state,
        rules: payload,
        roles: payload.roles,
        user: { roles: payload.roles },
      };
    default:
      return state;
  }
}
