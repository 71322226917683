import * as PayrollTypes from './payroll.types';

const initialState = {
  payrollSelector: {},
  selectedInvoices: null,
  selectedReceiver: null,
  selectedPayroll: null,
};

const selectedPayroll = (state = initialState.selectedPayroll, action) => {
  switch (action.type) {
    case PayrollTypes.SELECT_PAYROLL.START.type: {
      return { ...state, isLoading: true };
    }
    case PayrollTypes.SELECT_PAYROLL.SUCCESS.type:
    case PayrollTypes.UPDATE_PAYROLL_SUMMARY.SUCCESS.type: {
      if (!action.payload) {
        return { isLoading: true };
      }
      return { ...action.payload, isLoading: false };
    }
    case PayrollTypes.SELECT_PAYROLL.FAILURE.type: {
      return { ...initialState.selectedPayroll, isLoading: false };
    }
    case PayrollTypes.CLOSE_PAYROLL.SUCCESS.type: {
      return { ...state, closedAt: new Date().toISOString(), isClosing: false };
    }
    case PayrollTypes.CLOSE_PAYROLL.START.type: {
      return { ...state, isClosing: true };
    }
    case PayrollTypes.CLOSE_PAYROLL.FAILURE.type: {
      return { ...state, isClosing: false };
    }
    default:
      return state;
  }
};

const selectedReceiver = (state = initialState.selectedReceiver, action) => {
  switch (action.type) {
    case PayrollTypes.SELECT_RECEIVER.SUCCESS.type: {
      return action.payload;
    }
    default:
      return state;
  }
};

const payrollSelector = (state = initialState.payrollSelector, action) => {
  switch (action.type) {
    case PayrollTypes.PICK_RECEIVER.SUCCESS.type: {
      return action.payload;
    }
    case PayrollTypes.PICK_INVOICE.SUCCESS.type: {
      return action.payload;
    }
    case PayrollTypes.CLEAN_PAYROLL_SELECTOR.SUCCESS.type: {
      return initialState.payrollSelector;
    }
    default:
      return state;
  }
};

export const payrollReducer = {
  name: 'fundPayroll',
  reducers: {
    payrollSelector,
    selectedPayroll,
    selectedReceiver,
  },
};
