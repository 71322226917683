import { hasValue } from './predicates';
import { fixDecimals } from './numbers';

export const defaultValue = (value, defaultVal = '-', format = v => v) => {
  return hasValue(value) ? format(value) : defaultVal;
};

export const percentageFormat = val => {
  if (!hasValue(val)) {
    return '-';
  }

  const formattedNumber = fixDecimals(val);

  return `${formattedNumber}%`;
};

export const pluralize = (value, singular, plural, notValue) => {
  if (!hasValue(value) || value === '-') {
    return '';
  }
  const label = +value === 1 ? singular : plural;

  if (notValue) {
    return label;
  }

  return `${value || 0} ${label}`;
};
