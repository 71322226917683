import { useQuery } from 'react-query';
import { fetchManagementsSummary } from '../services';

export interface Summary {
    [key: string]: {
      [type: string]: {
        [result: string]: string;
      }
    }
  }

const useFetchManagementsSummary = (orderInvoices: number[], loading: boolean) => {
  const placeholderData: Summary = {}
  const {
    isLoading,
    isPlaceholderData,
    data,
    refetch,
    status,
    error,
    isError,
  } = useQuery<Summary>(
    ['getManagementsSummary', orderInvoices],
    async () => fetchManagementsSummary(orderInvoices, loading),
    {
      placeholderData,
      enabled: !!orderInvoices,
    }
  );

  return {
    data: data === undefined ? {} : data,
    isLoading: isLoading || isPlaceholderData,
    refetch,
    status,
    error,
    isError,
  };
};

export default useFetchManagementsSummary;
