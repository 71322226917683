import { Button, Grid, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocuments,
  verifyStatusDocuments,
} from '../actions/documentActions';
import {
  createStakeholder,
  fetchStakeholders,
} from '../actions/stakeholderActions';
import { features } from '../config/features';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../helpers/Constants';
import { HashKey } from '../helpers/hashKeys';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import Panel from './elements/Panel';
import BusinessDocuments from './BusinessDocuments';
import BusinessStakeholdersTable from './tables/BusinessStakeholdersTable';
import { fetchComplianceStakeholder } from '../actions/businessAction';
import { fetchDebtServiceByIdentifier } from '../actions/debtServiceActions';
import StakeholderDialogCL from './dialogs/StakeholderDialogCL';

const useStyles = makeStyles({
  containerRoot: {
    padding: convertSpacingToCss('lg'),
  },
  containerStakeholder: {
    paddingBottom: 20,
  },
});

const BusinessLegal = ({ businessId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedStakeholder, setSelectedStakeholder] = useState();
  const [showDialogStakeholder, setShowDialogStakeholder] = useState(false);

  const country = useSelector(state => state.config.country);
  const { businessIdDocumentsOwner } = useSelector(state => state.documents);
  const {
    businessIdStakeholderOwner,
    businessStakeholders,
    stakeholderCreated,
    createStakeholderError,
    createStakeholderIsLoading,
  } = useSelector(state => state.stakeholders);

  const countryFeatures = features[country];

  useEffect(() => {
    if (businessId !== businessIdStakeholderOwner) {
      dispatch(fetchStakeholders(businessId));
    }
    if (businessId !== businessIdDocumentsOwner) {
      dispatch(fetchDocuments(businessId, 'business'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, country, dispatch]);

  useEffect(() => {
    if (businessStakeholders?.length > 0) {
      businessStakeholders.forEach(stakeholder => {
        const { stakeholderId, identifier, businessId, id } = stakeholder;
        if (
          countryFeatures.businessDetailsSection.legal.stakeholdersHasDocuments
        ) {
          dispatch(verifyStatusDocuments(stakeholderId, 'stakeholder'));
        }
        dispatch(fetchComplianceStakeholder(identifier, businessId));
        dispatch(fetchDebtServiceByIdentifier(id, 'stakeholder'));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessStakeholders]);

  useEffect(() => {
    if (stakeholderCreated) {
      setShowDialogStakeholder(false);
    }
  }, [stakeholderCreated]);

  const handleClickCreateStakeholder = businessId => () => {
    switch (country) {
      case COUNTRY_CODE_MX: {
        const hashedBusinessId = HashKey(businessId);
        window.open(
          `${process.env.REACT_APP_CLIENT_GLOBAL_URL}/legal-representatives/register/?id=${hashedBusinessId}`,
          '_blank'
        );
        break;
      }
      case COUNTRY_CODE_CL: {
        setShowDialogStakeholder(true);
        break;
      }
      default:
        break;
    }
  };

  const handleCreateStakeholder = stakeholder => {
    dispatch(createStakeholder(businessId, stakeholder));
  };

  return (
    <Grid container className={classes.containerRoot}>
      <Grid item xs={12} className={classes.containerStakeholder}>
        <Panel
          title="Representantes legales"
          titlePadding="lg"
          contentPadding="zero zero lg"
          variant="section"
          actions={
            <Button
              variant="text"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleClickCreateStakeholder(businessId)}
            >
              Nuevo Representante
            </Button>
          }
        >
          <BusinessStakeholdersTable
            country={country}
            selectedStakeholder={selectedStakeholder}
            setSelectedStakeholder={setSelectedStakeholder}
          />
        </Panel>
      </Grid>

      {countryFeatures.businessDetailsSection.showDocuments && (
        <BusinessDocuments businessId={businessId} />
      )}

      {showDialogStakeholder && country === COUNTRY_CODE_CL && (
        <StakeholderDialogCL
          isOpen={showDialogStakeholder}
          handleClose={() => {
            setShowDialogStakeholder(false);
          }}
          onSubmit={handleCreateStakeholder}
          error={createStakeholderError}
          loading={createStakeholderIsLoading}
        />
      )}
    </Grid>
  );
};

BusinessLegal.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export default BusinessLegal;
