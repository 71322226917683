// TODO: sera movido a inProgress en un PR 'no-breaking-change'
export const receiverFilterMap = {
  toPersistence: (filter = {}) => ({
    invoiceMaxAmount: filter.maxAmount,
    invoiceMinAmount: filter.minAmount,
    payrollId: filter.payrollId,
    receiverIdentifier: filter.payerIdentifier,
    receiverName: filter.name,
    status: filter.businessStatus,
  }),
};

// TODO: eliminar cuando pase a prod: POR-413
export const opportunityReceiverFilterMap = {
  toPersistence: (filter = {}) => ({
    amountMax: filter.maxAmount,
    amountMin: filter.minAmount,
    discounts: filter.discount,
    folio: filter.folio,
    identifier: filter.payerIdentifier,
    issuerIdentifier: filter.issuerId,
    issuerName: filter.issuerName,
    name: filter.name,
    operationType: filter.operationType,
    payrollId: filter.payrollId,
    rateMax: filter.maxOriginationRate,
    rateMin: filter.minOriginationRate,
    status: filter.status,
    assigneeIdentifier: filter?.assignee?.identifier,
    assigneeName: filter?.assignee?.name,
    orderIds: filter?.orderIds?.length
      ? encodeURIComponent(
          JSON.stringify(
            filter.orderIds.reduce(
              (acc, orderId) =>
                orderId ? (acc ? [...acc, +orderId] : [+orderId]) : acc,
              null
            )
          )
        )
      : null,
  }),
};
