import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import {
  DIRECT_FINANCING,
  DF_LABEL,
  AVAILABLE_COUNTRIES,
  EP_LABEL,
  EARLY_PAYMENT,
  CO_LABEL,
  CONFIRMING,
} from '../../helpers/Constants';
import { features } from '../../config/features';

const useStyles = makeStyles({
  appBar: {
    background: 'white',
    borderBottom: '1px solid #d9d9d9',
    '&.shadow': {
      boxShadow: 'rgba(0, 0, 0, 0.28) -2px 5px 5px -5px',
    },
  },
});

const OperationTabs = props => {
  const { country, value, onChange, hideTabs } = props;
  const classes = useStyles();
  const countryFeatures = features[country];

  const OPERATIONS = [
    {
      type: DIRECT_FINANCING,
      label: DF_LABEL,
      featureKey: 'directFinancing',
      dataQa: 'tab-direct-financing',
      'data-cy': 'tabDirectFinancing',
      flagActive: true,
    },
    {
      type: EARLY_PAYMENT,
      label: EP_LABEL,
      featureKey: 'earlyPayment',
      dataQa: 'tab-early-payment',
      'data-cy': 'tabEarlyPayment',
      flagActive: true,
    },
    {
      type: 'PAYMENTS',
      label: CO_LABEL,
      featureKey: 'payments',
      dataQa: 'tab-payments',
      'data-cy': 'tabPayments',
      flagActive: true,
    },
  ];

  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      className={classes.appBar}
      value={value}
      onChange={(e, v) => onChange(v)}
    >
      {OPERATIONS.map(operation => {
        const {
          type,
          label,
          featureKey,
          dataQa,
          flagActive = null,
        } = operation;
        if (
          countryFeatures.enabledOperations[featureKey] &&
          !hideTabs.includes(type) &&
          flagActive
        ) {
          return (
            <Tab
              label={label}
              value={type}
              data-qa={dataQa}
              data-cy={operation['data-cy']}
            />
          );
        }
        return null;
      })}
    </Tabs>
  );
};

OperationTabs.defaultProps = {
  hideTabs: [],
};

OperationTabs.propTypes = {
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  value: PropTypes.oneOf([DIRECT_FINANCING, EARLY_PAYMENT, CONFIRMING])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  hideTabs: PropTypes.oneOf([DIRECT_FINANCING, EARLY_PAYMENT, CONFIRMING]),
};

export default OperationTabs;
