import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSelector } from 'react-redux';
import { COUNTRY_CODE_CL } from '../../helpers/Constants';

const CustomChip = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '12px',
    height: '25px',
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
})(Chip);

const useStyles = makeStyles({
  chipInReview: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#E29D02',
    background: '#FFEFC9',
    '&:hover, &:focus': {
      color: '#FFEFC9',
      background: '#E29D02',
    },
  },
  chipApproved: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#75B801',
    background: '#E6F8C5',
    '&:hover, &:focus': {
      color: '#E6F8C5',
      background: '#75B801',
    },
  },
  chipApprovedFund: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#F87348',
    background: '#F8DDD4',
    '&:hover, &:focus': {
      color: '#F8DDD4',
      background: '#F87348',
    },
  },
  chipRejected: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#D92F24',
    background: '#F8C7C4',
    '&:hover, &:focus': {
      color: '#F8C7C4',
      background: '#D92F24',
    },
  },
  chipTransferred: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#75B801',
    background: '#E6F8C5',
    '&:hover, &:focus': {
      color: '#E6F8C5',
      background: '#75B801',
    },
  },
  chipPaid: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#0ABDBD',
    background: '#C5FDFD',
    '&:hover, &:focus': {
      color: '#C5FDFD',
      background: '#0ABDBD',
    },
  },
  chipComplete: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#901AE7',
    background: '#E0D3FD',
    '&:hover, &:focus': {
      color: '#E0D3FD',
      background: '#901AE7',
    },
  },
  chipDefault: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#0529F0',
    background: '#D3DDFD',
    '&:hover, &:focus': {
      color: '#D3DDFD',
      background: '#0529F0',
    },
  },
  chipPaidInvoice: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#0093FF',
    background: '#D3F2FD',
    '&:hover, &:focus': {
      color: '#D3F2FD',
      background: '#0093FF',
    },
  },
  chipInactiveWallet: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#A8A4B3',
    background: '#E9E6F0',
    '&:hover, &:focus': {
      color: '#E9E6F0',
      background: '#A8A4B3',
    },
  },
  chipActiveWallet: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#0093FF',
    background: '#D3F2FD',
    '&:hover, &:focus': {
      color: '#D3F2FD',
      background: '#0093FF',
    },
  },
  chipAcceptedWallet: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#119B41',
    background: 'rgba(17, 155, 65, 0.2)',
    '&:hover, &:focus': {
      color: '#B7ECBF',
      background: '#47984C',
    },
  },
  chipPostVerified: {
    width: '100%',
    height: 32,
    fontWeight: 'bold',
    color: '#E29D02',
    background: 'rgba(226, 157, 2, 0.2)',
    '&:hover, &:focus': {
      color: '#F9EBCC',
      background: '#E29D02',
    },
  },
  iconClose: {
    marginTop: 3,
  },
});

const StatusChip = ({ status, handleClick = null }) => {
  const classes = useStyles();
  const country = useSelector(state => state.config.country);
  const getLabel = (text, icon = false) => {
    return icon ? (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={10}>
          <Typography variant="body1">{text}</Typography>
        </Grid>
        <Grid item xs={2}>
          <HighlightOffIcon className={classes.iconClose} />
        </Grid>
      </Grid>
    ) : (
      text
    );
  };
  switch (status) {
    case 'IN_REVIEW':
      return (
        <CustomChip
          label={getLabel('En Revisión', true)}
          className={classes.chipInReview}
          onClick={() => handleClick(status)}
        />
      );
    case 'APPROVED':
      return (
        <CustomChip
          label={getLabel('Aprobado', true)}
          className={classes.chipApproved}
          onClick={() => handleClick(status)}
        />
      );
    case 'REJECTED':
      return (
        <CustomChip
          label={getLabel('Rechazado', true)}
          className={classes.chipRejected}
          onClick={() => handleClick(status)}
        />
      );
    case 'TRANSFERRED':
      return (
        <CustomChip
          label={getLabel('Transferido', true)}
          className={classes.chipTransferred}
          onClick={() => handleClick(status)}
        />
      );
    case 'PAID':
      return (
        <CustomChip
          label={getLabel('Pagado', true)}
          className={classes.chipPaid}
          onClick={() => handleClick(status)}
        />
      );
    case 'RENEGOTIATION':
      return (
        <CustomChip
          label={getLabel('Renegociado', true)}
          className={classes.chipComplete}
          onClick={() => handleClick(status)}
        />
      );

    // Invoices
    case 'ACTIVE':
      return (
        <CustomChip
          label={getLabel('Activo', true)}
          className={classes.chipPaidInvoice}
          onClick={() => handleClick(status)}
        />
      );
    case 'HARD_COLLECTION':
      return (
        <CustomChip
          label={getLabel('Estado Critico', true)}
          className={classes.chipDefault}
          onClick={() => handleClick(status)}
        />
      );

    case 'REVIEWED':
      return (
        <CustomChip
          label={getLabel('Revisado', true)}
          className={classes.chipInReview}
          onClick={handleClick}
        />
      );

    case 'APPEALED':
      return (
        <CustomChip
          label={getLabel('Apelable', true)}
          className={classes.chipInReview}
          onClick={handleClick}
        />
      );

    case 'APPROVED_FUND':
      return (
        <CustomChip
          label={getLabel(
            country === COUNTRY_CODE_CL ? 'Aprobado Fondo' : 'Pendiente Firma',
            true
          )}
          className={
            country === COUNTRY_CODE_CL
              ? classes.chipApproved
              : classes.chipApprovedFund
          }
          onClick={handleClick}
        />
      );

    case 'TO_DEPOSIT':
      return (
        <CustomChip
          label={getLabel('Por Depositar', true)}
          className={classes.chipPostVerified}
          onClick={() => handleClick(status)}
        />
      );

    case 'DEFAULT':
      return (
        <CustomChip
          label={getLabel('Default', true)}
          className={classes.chipDefault}
          onClick={handleClick}
        />
      );

    case 'COMPLETE':
      return (
        <CustomChip
          label={getLabel('Completo', true)}
          className={classes.chipComplete}
          onClick={handleClick}
        />
      );

    case 'PV_APPROVED':
      return (
        <CustomChip
          label={getLabel('PV Aprobado', true)}
          className={classes.chipPostVerified}
          onClick={handleClick}
        />
      );

    case 'HARD_DELINQUENCY':
      return (
        <CustomChip
          label={getLabel('Default Interno', true)}
          className={classes.chipDefault}
          onClick={() => handleClick(status)}
        />
      );
    case 'SOFT_DELINQUENCY':
      return (
        <CustomChip
          label={getLabel('Mora Blanda', true)}
          className={classes.chipDefault}
          onClick={() => handleClick(status)}
        />
      );
    case 'DELINQUENT':
      return (
        <CustomChip
          label={getLabel('Mora', true)}
          className={classes.chipDefault}
          onClick={() => handleClick(status)}
        />
      );
    case 'REPLACED':
      return (
        <CustomChip
          label={getLabel('Reemplazada', true)}
          className={classes.chipDefault}
          onClick={() => handleClick(status)}
        />
      );

    // wallets

    case 'REQUESTED':
      return (
        <CustomChip
          label={getLabel('Solicitado', true)}
          className={classes.chipTransferred}
          onClick={() => handleClick(status)}
        />
      );

    case 'WALLET_ACTIVE':
      return (
        <CustomChip
          label={getLabel('Activo', true)}
          className={classes.chipActiveWallet}
          onClick={() => handleClick(status)}
        />
      );

    case 'WALLET_INACTIVE':
      return (
        <CustomChip
          label={getLabel('Inactivo', true)}
          className={classes.chipInactiveWallet}
          onClick={() => handleClick(status)}
        />
      );

    case 'WALLET_ACCEPTED':
      return (
        <CustomChip
          label={getLabel('Aceptado', true)}
          className={classes.chipAcceptedWallet}
          onClick={() => handleClick(status)}
        />
      );

    default:
      return <CustomChip label={status} onClick={() => handleClick(status)} />;
  }
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default StatusChip;
