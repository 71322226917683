import {
  b64toBlob,
  download as downloadFile,
} from '../../../../../helpers/fileHandling';
import { restFunds } from '../../../../infra/http';

export const useDownload = () => {
  const download = async ({ fileUrl, fileName }) => {
    try {
      const data = await restFunds()
        .get(fileUrl)
        .then(response => response.data);

      const file = b64toBlob(data);

      downloadFile(file, fileName);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return { download };
};
