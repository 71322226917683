/* eslint react/prop-types: 0 */ // --> OFF
import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequests } from '../../actions/walletActions';
import { REQUEST_STATUSES, WALLET_ACTIVITIES } from '../../helpers/Constants';
import { dateFormatter } from '../../helpers/DateUtils';
import BaseDialog from '../dialogs/BaseDialog';
import CountryFormatHelper from './CountryFormatHelper';
import PaginationMaterialTable from './PaginationMaterialTable';

const RequestsHistoryDialog = ({
  walletData = {},
  open,
  handleCloseDialog,
}) => {
  const { identifier } = walletData.AccountProfile;

  const dispatch = useDispatch();
  const requests = useSelector(state => state.wallet.allRequests);
  const loadingRequests = useSelector(state => state.wallet.loadingAllRequests);

  useEffect(() => {
    if (!requests && identifier) {
      dispatch(fetchRequests(identifier));
    }
  }, [requests, identifier, dispatch]);

  const requestColumns = [
    {
      title: 'Fecha',
      field: 'date',
      type: 'date',
      render: ({ createdAt }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(createdAt)}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo',
      field: 'activity',
      type: 'string',
      render: ({ activity }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {WALLET_ACTIVITIES[activity] || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Monto',
      field: 'amount',
      render: ({ amount = 0 }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={amount}
              countryId="CL"
              variant="currency"
            />
          </Typography>
        );
      },
    },
    {
      title: 'Comentario',
      field: 'comment',
      render: ({ comment }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {comment || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Estado',
      field: 'status',
      render: ({ status }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {REQUEST_STATUSES[status]}
          </Typography>
        );
      },
    },
  ];
  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Historial de solicitudes"
      size="lg"
    >
      <MaterialTable
        columns={requestColumns}
        data={requests || []}
        isLoading={loadingRequests}
        components={{
          Toolbar: () => null,
          Pagination: props => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <PaginationMaterialTable {...props} />
          ),
        }}
        options={{
          paging: true,
          filtering: false,
          search: false,
          sorting: true,
          pageSize: 5,
        }}
        localization={{
          pagination: {
            labelRowsSelect: 'solicitudes',
            labelRowsPerPage: 'Solicitudes por página',
            labelDisplayedRows: '{count} solicitudes totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay solicitudes para mostrar',
          },
        }}
      />
    </BaseDialog>
  );
};

RequestsHistoryDialog.propTypes = {
  walletData: PropTypes.shape({
    AccountProfile: PropTypes.shape({
      identifier: PropTypes.string.isRequired,
    }),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default RequestsHistoryDialog;
