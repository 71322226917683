import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { CloudUpload, AddCircleOutline } from '@material-ui/icons';
import { useStyles } from './styles';
import Can from '../../../../components/Can';
import {
  ORDERS_NEW_DF_PERFORM,
  ORDERS_NEW_EP_PERFORM,
} from '../../../../helpers/performsType';
import { features } from '../../../../config/features/index';
import { DIRECT_FINANCING } from '../../../../helpers/Constants';
import { Countries } from '../../../commons/interfaces/ARInterfaces';

interface Props {
  country: Countries;
  operationType: string;
  handleChangeAnchorEl: (x: any) => void;
  handleShowCreateOrderDialog: () => void;
}

const HeaderButtons: FC<Props> = ({
  country,
  operationType,
  handleChangeAnchorEl,
  handleShowCreateOrderDialog,
}) => {
  const classes = useStyles();
  const countryFeatures = features[country];

  return (
    <>
      {(countryFeatures.orderActions.bulkInvoicesUpdates ||
        countryFeatures.orderActions.bulkFundsUpdates ||
        countryFeatures.orderActions.bulkPartialPaymentsRegister) && (
        <Button
          className={classes.buttonBulkUpdate}
          variant="text"
          color="primary"
          startIcon={<CloudUpload />}
          onClick={(e: any) => handleChangeAnchorEl(e.currentTarget)}
          data-qa="bulk-invoice-update-action"
        >
          Actualizacion masiva
        </Button>
      )}

      {(countryFeatures.orderActions.createDirectFinancingOrder ||
        countryFeatures.orderActions.createEarlyPaymentOrder ||
        countryFeatures.orderActions.createConfirmingOrder) && (
        <Can
          perform={
            operationType === DIRECT_FINANCING
              ? ORDERS_NEW_DF_PERFORM
              : ORDERS_NEW_EP_PERFORM
          }
          yes={() => (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddCircleOutline />}
              onClick={handleShowCreateOrderDialog}
              data-qa="create-order-action"
            >
              Nueva operación
            </Button>
          )}
        />
      )}
    </>
  );
};

export default HeaderButtons;
