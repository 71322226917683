import { useCallback, useEffect, useState } from 'react';
import {
  useGetSelectedOperations,
  useClearSelectedOperations,
} from '../infrastructure/store/InboxStore';
import useOperationBulkUpdate from '../infrastructure/store/useOperationBulkUpdate';
import { features } from '../../../config/features';
import { Countries } from '../../commons/interfaces/ARInterfaces';
import useGetCountry from '../../../hooks/useGetCountry';

const useSelectedOperations = () => {
  const country: Countries = useGetCountry();
  const [showChangeStatusOperationDialog, setShowChangeStatusOperationDialog] =
    useState(false);
  const selectedOperations = useGetSelectedOperations();
  const clearSelectedOperations = useClearSelectedOperations();
  const totalSelectedOperations = selectedOperations.length;
  const countryFeatures = features[country];
  const {
    isLoading: bulkdUpdateStatusIsLoading,
    isSuccess: bulkdUpdateStatusIsSuccess,
    changeState,
  } = useOperationBulkUpdate();

  const handleClearSelectedOperations = useCallback(() => {
    clearSelectedOperations();
  }, [clearSelectedOperations]);

  const handleOpenChangeStatusOperationDialog = useCallback(() => {
    setShowChangeStatusOperationDialog(true);
  }, [setShowChangeStatusOperationDialog]);

  const handleCloseChangeStatusOperationDialog = useCallback(() => {
    setShowChangeStatusOperationDialog(false);
  }, [setShowChangeStatusOperationDialog]);

  const handlebulkUpdateStatus = (status: string, statusReason: string) => {
    // @ts-ignore
    changeState({ orderIds: selectedOperations, status, statusReason });
  };

  useEffect(() => {
    if (!bulkdUpdateStatusIsLoading && bulkdUpdateStatusIsSuccess) {
      handleCloseChangeStatusOperationDialog();
      handleClearSelectedOperations();
    }
  }, [
    bulkdUpdateStatusIsLoading,
    bulkdUpdateStatusIsSuccess,
    handleClearSelectedOperations,
    handleCloseChangeStatusOperationDialog,
  ]);

  return {
    totalSelectedOperations,
    showChangeStatusOperationDialog,
    bulkdUpdateStatusIsLoading,
    countryFeatures,
    handleClearSelectedOperations,
    handleOpenChangeStatusOperationDialog,
    handleCloseChangeStatusOperationDialog,
    handlebulkUpdateStatus,
  };
};

export default useSelectedOperations;
