export const ORDER_PAYMENT_TYPE = {
  OWN_CAPITAL: 'Capital Propio',
  FINANCING: 'Financiamiento',
};

export const OWN_CAPITAL = 'OWN_CAPITAL';
export const FINANCING = 'FINANCING';

export const OWN_CAPITAL_LABEL = 'Payments sin Financiamiento';
export const FINANCING_LABEL = 'Payments con Financiamiento';
