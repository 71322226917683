import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { FileUploadDialog } from '../../../../../commons/components';
import { DISCOUNTS } from '../../../../../../helpers/Constants';

const DiscountsDialog = ({
  locales,
  discountReason,
  handleUploadFile,
  handleInputChange,
  showDiscountsDialog,
  uploadBulkDiscountsLoading,
  handleCloseDiscountsDialog,
}) => {
  const { country } = useSelector(state => state.config);
  const templateLabels = {
    folio: 'NÚMERO DE FOLIO',
    uuid: 'UUID',
    issuerIdMX: 'RFC',
    issuerIdCL: 'RUT',
    amount: 'MONTO A DESCONTARSE',
  };
  const csvTemplate = [
    [
      country === 'MX' ? [templateLabels.uuid] : [templateLabels.folio],
      country === 'MX'
        ? [templateLabels.issuerIdMX]
        : [templateLabels.issuerIdCL],
      templateLabels.amount,
    ],
  ];

  return (
    <FileUploadDialog
      open={showDiscountsDialog}
      handleCloseDialog={handleCloseDiscountsDialog}
      handleLoad={handleUploadFile}
      title={locales.title}
      description={locales.description}
      actionText={locales.action}
      downloadText={locales.download}
      csvTemplate={csvTemplate}
      disabled={!discountReason}
      saving={uploadBulkDiscountsLoading}
    >
      <Grid container direction="column">
        <ValidatorForm onSubmit={() => undefined}>
          <SelectValidator
            variant="outlined"
            label="Razón de descuento"
            name="reason"
            fullWidth
            value={discountReason.reason}
            onChange={e => handleInputChange(e)}
            isRequired
          >
            {DISCOUNTS.filter(
              reason =>
                reason.type === 'BASE_DISCOUNT' &&
                reason.country.includes(country)
            ).map(item => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </SelectValidator>
        </ValidatorForm>
      </Grid>
    </FileUploadDialog>
  );
};

DiscountsDialog.propTypes = {
  discountReason: PropTypes.string.isRequired,
  showDiscountsDialog: PropTypes.bool.isRequired,
  handleCloseDiscountsDialog: PropTypes.func.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
  locales: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.string,
    download: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  uploadBulkDiscountsLoading: PropTypes.bool.isRequired,
};

export default DiscountsDialog;
