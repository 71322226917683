import { combineReducers } from 'redux';

import { payrollReducer } from '../../inProgress/adapters/store/payroll/payroll.reducer';
import { payrollReducer as opportunityPayrollReducer } from '../../opportunities/domains/payroll/state/payroll.reducers';
import { receiverReducer as opportunityReceiverReducer } from '../../opportunities/domains/receiver/state/receiver.reducers';
import { documentReducer as opportunityDocumentReducer } from '../../opportunities/domains/document/state/document.reducers';

export const fundsModuleReducer = () => {
  return {
    [payrollReducer.name]: combineReducers(payrollReducer.reducers),
    fundOpportunities: combineReducers({
      [opportunityPayrollReducer.name]: combineReducers(
        opportunityPayrollReducer.reducers
      ),
      [opportunityReceiverReducer.name]: combineReducers(
        opportunityReceiverReducer.reducers
      ),
      [opportunityDocumentReducer.name]: combineReducers(
        opportunityDocumentReducer.reducers
      ),
    }),
  };
};
