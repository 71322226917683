const cleanValues = arrayValues => {
  const hashObject = {};
  if (!Array?.isArray(arrayValues)) return hashObject;
  arrayValues.forEach(arrayValue => {
    const getValue =
      typeof arrayValue === 'string' ? arrayValue?.split('=') : arrayValue;
    if (getValue?.length > 1) {
      hashObject[getValue[0]] = getValue[1].toUpperCase();
    }
  });
  return hashObject;
};

// eslint-disable-next-line import/prefer-default-export
export const getMultiplesHash = hashValue => {
  if (hashValue === null || hashValue === undefined) return hashValue;
  if (typeof hashValue === 'string') {
    const arrayHash = hashValue?.includes('&')
      ? hashValue.split('&')
      : [hashValue];
    return cleanValues(arrayHash);
  }
  return cleanValues(hashValue);
};
