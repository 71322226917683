import { useQuery } from 'react-query';
import { fetchHolidaysService } from '../services';

const useFetchHolidays = orderId => {
  const { data } = useQuery(
    'getHolidays',
    () => fetchHolidaysService(orderId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const holidaysData = data || { MX: [], CL: [] };

  return {
    holidaysData,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchHolidays };
