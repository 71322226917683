import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  makeStyles,
  CircularProgress,
  Box,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { fetchPayerNameByIdentifier } from '../../actions/payerBlocklistAction';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import AlertForm from '../elements/AlertForm';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import { t13s } from '../../translationKeys';
import {
  BLOCKLIST_REASONS,
  BLOCKLIST_REASONS_CL,
  COUNTRY_CODE_CL,
} from '../../helpers/Constants';

const useStyles = makeStyles({
  containerInputs: {
    paddingBottom: 10,
  },
  containerButton: {
    paddingTop: 10,
  },
});

const RegisterPayerBlocklistDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  isLoading,
  error,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { country } = useSelector(state => state.config);
  const {
    payerSelected,
    fetchPayerBlocklistIsLoading,
    payerBlocklistWasConsulted,
  } = useSelector(state => state.payerBlocklist);

  const [payerIdentifier, setPayerIdentifier] = useState('');
  const [payerName, setPayerName] = useState('');
  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    setPayerName(payerSelected?.name || '');
  }, [payerSelected]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value => {
      return validateIdentifier(value, country);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerIdentifier]);

  const handleSubmitRegisterPayerBlocklist = () => {
    handleSubmit({
      payerIdentifier,
      payerName,
      reason,
    });
  };

  const handleFetchPayerNameByIdentifier = isValid => {
    if (isValid && payerSelected?.identifier !== payerIdentifier) {
      dispatch(fetchPayerNameByIdentifier(payerIdentifier));
    }
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Pagador"
      description="Ingresa la información del pagador"
    >
      <ValidatorForm onSubmit={handleSubmitRegisterPayerBlocklist}>
        <Grid item xs={12} className={classes.containerInputs}>
          <TextValidator
            fullWidth
            variant="outlined"
            label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador`}
            autoComplete={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador`}
            type="string"
            name="payerIdentifier"
            value={payerIdentifier}
            onChange={e => setPayerIdentifier(e.target.value)}
            validators={['required', 'isValidIdentifier']}
            errorMessages={[
              'Campo requerido',
              t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
            ]}
            validatorListener={isValid =>
              handleFetchPayerNameByIdentifier(isValid)
            }
          />
          {payerBlocklistWasConsulted || fetchPayerBlocklistIsLoading ? (
            <>
              {fetchPayerBlocklistIsLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress size={36} />
                </Box>
              ) : (
                <>
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    disabled={payerBlocklistWasConsulted && payerSelected?.name}
                    label="Nombre pagador"
                    name="payerName"
                    value={payerName}
                    onChange={e => setPayerName(e.target.value)}
                  />
                  <SelectValidator
                    fullWidth
                    variant="outlined"
                    name="operationReason"
                    id="operation-reason-select"
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                    label="Motivo"
                  >
                    {[
                      ...BLOCKLIST_REASONS,
                      ...(country === COUNTRY_CODE_CL
                        ? BLOCKLIST_REASONS_CL
                        : []),
                    ].map(reason => (
                      <MenuItem key={reason.value} value={reason.value}>
                        <Typography variant="body1">{reason.label}</Typography>
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </>
              )}
            </>
          ) : null}
        </Grid>

        {error && <AlertForm variant="error" message={error} />}

        <Grid item xs={12} className={classes.containerButton}>
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

RegisterPayerBlocklistDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

export default RegisterPayerBlocklistDialog;
