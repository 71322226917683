import { useContext } from 'react';

import { OpportunitiesContext } from './OpportunitiesContext';

export const useOpportunitiesContext = () => {
  const context = useContext(OpportunitiesContext);

  if (!context) {
    throw new Error(
      'useOpportunitiesContext must be used within a OpportunitiesContextProvider'
    );
  }

  return context;
};
