import { removeAccents } from '../../../helpers/regexUtils';
import { fetcher } from './http.adapter';

export const restFunds = (mainConf = {}) => {
  const baseURL = process.env.REACT_APP_SLS_FUNDS_URL;
  const token = process.env.REACT_APP_SLS_FUNDS_TOKEN;
  const authState = JSON.parse(localStorage.getItem('okta-token-storage'));
  const { email, name } = authState.idToken.claims;
  const user = { firstName: name, lastName: '', email };

  return fetcher({
    baseURL,
    headers: {
      bouser: removeAccents(JSON.stringify(user)),
      Authorization: token ? `Bearer ${token}` : '',
    },
    ...mainConf,
    onResponse: instance => {
      instance.interceptors.response.use(
        response => response,
        error => {
          // eslint-disable-next-line no-console
          console.error(error);
          return Promise.reject(error.response);
        }
      );
    },
  });
};
