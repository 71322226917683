import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SmileyIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { useOktaAuth } from '@okta/okta-react';
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logoutUser } from '../actions/authentication';
import xepelinHorizontalLogo from '../assets/xepelin-horizontal.svg';
import SelectCountry from './elements/SelectCountry';
import BellNotification from './elements/Notifications/ui/BellNotification';
import useGetCountry from '../hooks/useGetCountry';
import { AuthState } from '@okta/okta-auth-js';
import useFilters from '../AR/inbox/hooks/useFilters';

const useStyles = makeStyles({
  appBar: {
    position: 'sticky',
    background:
      'transparent linear-gradient(270deg, #1A85E7 0%, #321AE7 100%) 0% 0% no-repeat padding-box;',
    top: 0,
    left: 'auto',
    right: 0,
    width: '100%',
    zIndex: 100,
  },
  toolBar: {
    display: 'flex',
    minHeight: 56,
    alignItems: 'center',
    padding: '0px 10px',
  },
  avatarButton: {
    marginLeft: 'auto',
    background: 'transparent',
  },
  logo: {
    height: 46,
    marginLeft: 10,
  },
  logout: {
    top: '46px !important',
  },
});

interface Props {
  auth: AuthState;
}

const TopBar: FC<Props> = ({ auth }) => {
  const user = {
    name: auth?.idToken?.claims?.name || '-',
  };
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const country = useGetCountry();
  const { handleClearFilters } = useFilters();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logoutUser(oktaAuth));
  };

  const handleChangeCountry = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    history.push(
      location.pathname
        .replace(country.toLowerCase(), value?.toLowerCase())
        .replace('/pagenotfound', '')
    );
  };

  const shouldShowCountrySelect = () => {
    const pathParts = location.pathname.split('/'); // => ['', country, seccion, ('' || id)]
    const lenghtPath = pathParts.length;
    const isModuleHome = lenghtPath === 3;
    const isFundsHome = pathParts[2]?.includes('funds') && lenghtPath === 4;

    return (
      isModuleHome || isFundsHome || (lenghtPath === 4 && pathParts[3] === '')
    );
  };

  return (
    <header className={classes.appBar}>
      <Box className={classes.toolBar}>
        <Box style={{ flexGrow: 1, alignSelf: 'end' }}>
          <img
            src={xepelinHorizontalLogo}
            alt="xepelin-horizontal-logo"
            className={classes.logo}
          />
        </Box>

        {shouldShowCountrySelect() && (
          <SelectCountry
            handleChangeCountry={(e: ChangeEvent<HTMLInputElement>) => {
              handleChangeCountry(e);
              handleClearFilters();
            }}
            country={country}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.avatarButton}
          endIcon={<SmileyIcon />}
          onClick={handleClick}
          ref={anchorEl}
        >
          {user && (
            <Typography
              style={{ textTransform: 'capitalize' }}
              variant="h5"
              component="div"
            >
              Hola,{' '}
              <Box
                fontWeight="fontWeightBold"
                style={{ display: 'inline-block' }}
              >
                {user.name}
              </Box>
            </Typography>
          )}
        </Button>

        <Menu
          className={classes.logout}
          id="menu-appbar"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleLogout}>Salir</MenuItem>
        </Menu>

        <BellNotification />
      </Box>
    </header>
  );
};

export default TopBar;
