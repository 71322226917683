export const statusInvoiceConverter = (invoiceStatus: number) => {
    switch (invoiceStatus) {
        case 1:
            return 'Activo'
        case 2:
            return 'Mora blanda'
        case 3:
            return 'Mora'
        case 4:
            return 'Default interno'
        default:
            return 'Estado crítico'
        
    }
  };
