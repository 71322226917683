interface Filters {
    field: string;
    value?: string[] | string | null;
  }

export const getCollectionFilters = (filters: Filters[]) => {

    const arrayFilters = filters.map((filter) => {
        if (filter.field === 'payerName'){
            return { filter: `RAZON SOCIAL:${filter.value}`}
        }
        if (filter.field === 'priority'){
            if (filter.value) return { orderBy: `${filter.value}:DESC`}
        }
        if (filter.field === 'RUT' || filter.field === 'RFC'){
            return { filter: `${filter.field}:${filter.value}`}
        }
    }).filter((e) => e !== undefined)
    return Object.assign({}, ...arrayFilters)
}
