/* eslint-disable no-param-reassign */
import produce from 'immer';
import { DIRECT_FINANCING } from '../../../../../helpers/Constants';
import createStore from '../../../../commons/utils/store';

const STORE_NAME = 'InboxZustandStore';
const STORE_VERSION = 3;

const PAGE = 1;
const ITEMS_PER_PAGE = 10;
const DEFAULT_STATUS = 'ALL';
const DEFAULT_OPERATION_TYPE = DIRECT_FINANCING;
const FIELD = {
  select: { title: 'Razón Social', field: 'name' },
  input: '',
};

const InboxZustandStore = createStore(
  (set, get) => ({
    page: PAGE,
    itemsPerPage: ITEMS_PER_PAGE,
    selectedOperations: [],
    filters: {
      status: DEFAULT_STATUS,
      field: FIELD,
      operation: DEFAULT_OPERATION_TYPE,
    },
    setPage: page =>
      set(state => {
        state.page = page;
      }),
    goNextPage: () =>
      set(state => {
        state.page += 1;
      }),
    goPrevPage: () =>
      set(state => {
        state.page -= 1;
      }),
    setItemsPerPage: itemsPerPage =>
      set(state => {
        state.page = 1;
        state.itemsPerPage = itemsPerPage;
      }),
    setSelectedOperations: selectedOperations =>
      set(state => {
        state.selectedOperations = selectedOperations;
      }),
    appendSelectedOperation: operations => {
      operations.forEach(newOperation => {
        set(state => {
          state.selectedOperations.push(newOperation);
        });
      });
    },
    removeSelectedOperation: operations => {
      operations.forEach(selectedOperationId => {
        set(state => {
          const index = state.selectedOperations.findIndex(
            operation => operation === selectedOperationId
          );
          if (index !== -1) state.selectedOperations.splice(index, 1);
        });
      });
    },
    clearSelectedOperations: () =>
      set(state => {
        state.selectedOperations = [];
      }),
    setStatusFilter: status =>
      set(state => {
        state.page = 1;
        state.filters.status = status;
      }),
    setFieldFilter: ({ select, input }) =>
      set(state => {
        state.page = 1;
        state.filters.field.select = select;
        state.filters.field.input = input;
      }),
    setOperationFilter: operationType =>
      set(state => {
        state.page = 1;
        state.filters.operation = operationType;
      }),
    setClearFilters: () =>
      set(state => {
        state.filters.field = FIELD;
        state.filters.status = DEFAULT_STATUS;
      }),
    getFilters: () => {
      const rawFilters = get().filters;
      const { field, status, operation } = rawFilters;
      // eslint-disable-next-line no-param-reassign
      return produce(rawFilters, draft => {
        delete draft.field;

        if (status === 'ALL') {
          delete draft.status;
        }

        if (field.input) {
          draft.fieldKey = field.select.field;
          draft.fieldValue = field.input;
        }

        if (!operation) {
          draft.operation = DIRECT_FINANCING;
        }
      });
    },
  }),
  {
    name: STORE_NAME,
    version: STORE_VERSION,
  }
);

export default InboxZustandStore;
