import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MailLowRisk = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17316C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2ZM13.3061 17.3469C13.3061 17.5185 13.2723 17.6883 13.2067 17.8468C13.1411 18.0052 13.0449 18.1492 12.9236 18.2705C12.8023 18.3918 12.6583 18.488 12.4998 18.5536C12.3414 18.6193 12.1715 18.6531 12 18.6531C11.6536 18.6531 11.3214 18.5154 11.0764 18.2705C10.8315 18.0256 10.6939 17.6933 10.6939 17.3469V16.6939C10.7045 16.3546 10.8468 16.0328 11.0906 15.7966C11.3344 15.5604 11.6606 15.4284 12 15.4286C12.1696 15.4231 12.3385 15.4518 12.4968 15.5129C12.655 15.574 12.7994 15.6664 12.9212 15.7844C13.0431 15.9025 13.1399 16.0438 13.2061 16.2C13.2722 16.3563 13.3062 16.5242 13.3061 16.6939V17.3469ZM13.3061 12.4898C13.3061 12.6613 13.2723 12.8312 13.2067 12.9896C13.1411 13.1481 13.0449 13.2921 12.9236 13.4134C12.8023 13.5346 12.6583 13.6309 12.4998 13.6965C12.3414 13.7621 12.1715 13.7959 12 13.7959C11.6536 13.7959 11.3214 13.6583 11.0764 13.4134C10.8315 13.1684 10.6939 12.8362 10.6939 12.4898V6.61224C10.6939 6.26584 10.8315 5.93362 11.0764 5.68868C11.3214 5.44373 11.6536 5.30612 12 5.30612C12.1715 5.30612 12.3414 5.33991 12.4998 5.40554C12.6583 5.47118 12.8023 5.56739 12.9236 5.68868C13.0449 5.80996 13.1411 5.95395 13.2067 6.11241C13.2723 6.27088 13.3061 6.44072 13.3061 6.61224V12.4898Z" />
    </SvgIcon>
  );
};

export default MailLowRisk;
