import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowLeft = props => {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3332 7.08317H4.17734L9.3015 1.959L7.99984 0.666504L0.666504 7.99984L7.99984 15.3332L9.29234 14.0407L4.17734 8.9165H15.3332V7.08317Z"
        fill="#8D8A96"
      />
    </SvgIcon>
  );
};

export default ArrowLeft;
