import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Pdf = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8889 7L15.2593 2H4.14721C3.6361 2 3.22221 2.444 3.22221 2.992V9H3C1.89543 9 1 9.89543 1 11V16C1 17.1046 1.89543 18 3 18H3.22221V21.008C3.22221 21.545 3.63425 22 4.14166 22H18.9694C19.4768 22 19.8889 21.549 19.8889 20.993V18H20C21.1046 18 22 17.1046 22 16V11C22 9.89543 21.1046 9 20 9H19.8889V7ZM18.037 9V8H14.3333V4H5.07407V9H18.037ZM5.07407 18V20H18.037V18H5.07407ZM5 11V16.2682H6.06118V14.1372H7.19719C7.73231 14.1372 8.14726 13.9967 8.44203 13.7155C8.74134 13.4294 8.89099 13.0471 8.89099 12.5686C8.89099 12.0852 8.7436 11.7029 8.44883 11.4218C8.1586 11.1406 7.74138 11 7.19719 11H5ZM7.06114 13.2567H6.06118V11.8879H7.06114C7.34684 11.8879 7.55545 11.9471 7.68696 12.0655C7.82301 12.1839 7.89103 12.354 7.89103 12.576C7.89103 12.7931 7.82301 12.9608 7.68696 13.0792C7.55091 13.1975 7.3423 13.2567 7.06114 13.2567ZM9.59031 11V16.2682H11.4678C12.0029 16.2682 12.4587 16.1646 12.8351 15.9574C13.216 15.7453 13.5062 15.4444 13.7058 15.0547C13.9053 14.6601 14.0051 14.1841 14.0051 13.6267C14.0051 13.0742 13.9031 12.6032 13.699 12.2135C13.4994 11.8188 13.2092 11.5179 12.8283 11.3108C12.4519 11.1036 11.9984 11 11.4678 11H9.59031ZM11.393 15.3359H10.6515V11.9323H11.393C12.4269 11.9323 12.9439 12.4971 12.9439 13.6267C12.9439 14.1989 12.8147 14.6281 12.5562 14.9142C12.2977 15.1953 11.9099 15.3359 11.393 15.3359ZM14.8383 16.2682V11H18.1034V11.9175H15.8859V13.1384H17.9674V14.0485H15.8859V16.2682H14.8383Z"
      />
    </SvgIcon>
  );
};

export default Pdf;
