import {
  FETCH_COMMENTS_START,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  FETCH_LAST_COMMENT_START,
  FETCH_LAST_COMMENT_SUCCESS,
  FETCH_LAST_COMMENT_FAILURE,
  CREATE_COMMENT_START,
  CREATE_COMMENT_FAILURE,
  CREATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_START,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  RESET_COMMENTS,
} from '../actions/types';

const initialState = {
  sourceType: null,

  comments: [],
  fetchCommentsIsLoading: false,

  comment: null,
  fetchCommentIsLoading: false,

  createCommentIsLoading: false,
  commentWasCreated: false,
  createCommentError: null,

  commentUpdated: false,
  updateCommentIsLoading: false,
  updateCommentError: null,
};

const fetchCommentsStart = state => ({
  ...state,
  comments: [],
  fetchCommentsIsLoading: true,
});
const fetchCommentsSuccess = (state, payload) => ({
  ...state,
  sourceType: payload.sourceType,
  comments: payload.comments,
  fetchCommentsIsLoading: false,
});
const fetchCommentsFailure = state => ({
  ...state,
  fetchCommentsIsLoading: false,
});

const fetchLastCommentStart = state => ({
  ...state,
  comment: null,
  fetchCommentIsLoading: true,
});
const fetchLastCommentSuccess = (state, payload) => ({
  ...state,
  sourceType: payload.sourceType,
  comment: payload.comment,
  fetchCommentIsLoading: false,
});
const fetchLastCommentFailure = state => ({
  ...state,
  fetchCommentIsLoading: false,
});

const createCommentStart = state => ({
  ...state,
  createCommentIsLoading: true,
  commentWasCreated: false,
  createCommentError: null,
});
const createCommentSuccess = (state, payload) => ({
  ...state,
  sourceType: payload.sourceType,
  commentWasCreated: true,
  createCommentIsLoading: false,
  comments: [payload.comment, ...state.comments],
  comment: payload.comment,
});
const createCommentFailure = (state, payload) => ({
  ...state,
  createCommentIsLoading: false,
  createCommentError: payload.errorCode,
});
const updateCommentStart = state => ({
  ...state,
  commentUpdated: false,
  updateCommentIsLoading: true,
  updateCommentError: null,
});
const updateCommentSuccess = (state, payload) => ({
  ...state,
  sourceType: payload.sourceType,
  commentUpdated: true,
  updateCommentIsLoading: false,
  comment: payload.comment,
});
const updateCommentFailure = (state, payload) => ({
  ...state,
  updateCommentsIsLoading: false,
  updateCommentsError: payload.errorCode,
});

const resetComments = state => ({
  ...state,
  sourceType: null,
  commentWasCreated: false,
  createCommentError: null,
  commentUpdated: false,
  updateCommentError: null,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMMENTS_START:
      return fetchCommentsStart(state);
    case FETCH_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, payload);
    case FETCH_COMMENTS_FAILURE:
      return fetchCommentsFailure(state);
    case FETCH_LAST_COMMENT_START:
      return fetchLastCommentStart(state);
    case FETCH_LAST_COMMENT_SUCCESS:
      return fetchLastCommentSuccess(state, payload);
    case FETCH_LAST_COMMENT_FAILURE:
      return fetchLastCommentFailure(state);
    case CREATE_COMMENT_START:
      return createCommentStart(state);
    case CREATE_COMMENT_SUCCESS:
      return createCommentSuccess(state, payload);
    case CREATE_COMMENT_FAILURE:
      return createCommentFailure(state, payload);
    case UPDATE_COMMENT_START:
      return updateCommentStart(state);
    case UPDATE_COMMENT_SUCCESS:
      return updateCommentSuccess(state, payload);
    case UPDATE_COMMENT_FAILURE:
      return updateCommentFailure(state, payload);
    case RESET_COMMENTS:
      return resetComments(state);
    default:
      return state;
  }
};
