import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { PayerCategorizationSummary } from '../../../commons/interfaces/payerCategorization';

export const fetchPayerCategorizationSummary = async (payerIdentifier: string): Promise<PayerCategorizationSummary> => {
  try {
    const { data } = await axios.get(`/api/collection/payers/${payerIdentifier}/categorizations/summary`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    }
    throw new Error(UNKNOWN_ERROR);
  }
};

