import {
  FETCH_HOLIDAYS_START,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_FAILURE,
} from '../actions/types';

const initialState = {
  holidays: null,
  holidaysIsLoading: false,
  holidaysError: null,
};

const fetchHolidaysStart = state => {
  return {
    ...state,
    holidaysIsLoading: true,
  };
};

const fetchHolidaysSuccess = (state, payload) => {
  return {
    ...state,
    holidaysIsLoading: false,
    holidays: payload,
  };
};

const fetchHolidaysError = (state, payload) => {
  return {
    ...state,
    holidays: null,
    holidaysIsLoading: false,
    holidaysError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HOLIDAYS_START:
      return fetchHolidaysStart(state);
    case FETCH_HOLIDAYS_SUCCESS:
      return fetchHolidaysSuccess(state, payload);
    case FETCH_HOLIDAYS_FAILURE:
      return fetchHolidaysError(state, payload);
    default:
      return state;
  }
};
