export const getDocumentsUseCase = ({
  documents,
  payrollSelector,
  receiverIdentifier,
}) => {
  const receiverSelected = payrollSelector?.[receiverIdentifier];

  return documents?.map(docum => {
    if (!receiverSelected) {
      return {
        ...docum,
        tableData: {
          ...docum.tableData,
          checked: false,
        },
      };
    }
    if (receiverSelected?.selected) {
      return {
        ...docum,
        tableData: { ...docum.tableData, checked: true },
      };
    }

    if (!receiverSelected?.selected) {
      return {
        ...docum,
        tableData: {
          ...docum.tableData,
          checked: !!receiverSelected.value.find(doc => doc.id === docum.id),
        },
      };
    }

    return docum;
  });
};
