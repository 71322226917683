import React from 'react';
import { Box } from '@material-ui/core';
import SkeletonMui from '@material-ui/lab/Skeleton';

const BankAccountSelectorDrawerSkeleton = () => {
  return (
    <>
      {[1, 2, 3].map(() => (
        <>
          <Box
            display="flex"
            alignItems="center"
            height={58}
            justifyContent="space-between"
          >
            <SkeletonMui
              variant="circle"
              width={13}
              height={13}
              animation="wave"
            />

            <SkeletonMui
              variant="rect"
              width={250}
              height={18}
              animation="wave"
            />
            <SkeletonMui
              variant="rect"
              width={150}
              height={18}
              animation="wave"
            />
            <SkeletonMui
              variant="rect"
              width={21}
              height={18}
              animation="wave"
            />
          </Box>
          <SkeletonMui
            variant="rect"
            width="100%"
            height={1}
            animation="wave"
          />
        </>
      ))}
    </>
  );
};

export default BankAccountSelectorDrawerSkeleton;
