import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../../../components/elements/CheckButton';

export const UpdateTaxEntityDialog = ({
  isOpen,
  handleCloseDialog,
  selectedData,
  handleSubmit,
  isLoading,
  description,
}) => {
  const [taxEntity, setTaxEntity] = useState({});
  const [entityType, setEntityType] = useState('dicom');
  const [checked, setChecked] = useState(false);

  const { country } = useSelector(state => state.config);

  useEffect(() => {
    setEntityType(country === 'CL' ? 'dicom' : 'buro');
  }, [country]);

  useEffect(() => {
    setTaxEntity(selectedData);
    setChecked(false);
  }, [selectedData]);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      title={`Editar ${entityType}`}
      description={description}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={() => handleSubmit(taxEntity)}>
          <TextValidator
            variant="outlined"
            fullWidth
            label="Monto ($)"
            inputProps={{ min: 0 }}
            type="number"
            name={entityType}
            value={taxEntity.receiverDebt}
            onChange={e => {
              setTaxEntity({ ...taxEntity, receiverDebt: +e.target.value });
            }}
            validators={['required', 'isPositive']}
            errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
          />
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UpdateTaxEntityDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  description: '',
};

UpdateTaxEntityDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  description: PropTypes.string,
  // Ver bien lo que se pasara para hacer un shape
  selectedData: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
