import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Title } from './styles';
import useHeader from '../../hooks/useHeader';
import CreateOrderDialog from '../../../../components/dialogs/CreateOrderDialog';
import useInbox from '../../hooks/useInbox';
import HeaderButtons from './HeaderButtons';
import HeaderMenu from './HeaderMenu';
import InvoicesBulkUpdateModal from './InvoicesBulkUpdateModal';
import PartialPaymentsBulkUpdateModal from './PartialPaymentsBulkUpdateModal';
import FundBulkUpdateModal from './FundBulkUpdateModal';

const Header: FC = () => {
  const {
    country,
    showCreateOrderDialog,
    anchorEl,
    createOrderIsLoading,
    createOrderError,
    showInvoicesBulkUpdateDialog,
    showPartialPaymentsBulkUpdateDialog,
    showFundsBulkUpdateDialog,
    invoicesBulkUpdateType,
    handleChangeAnchorEl,
    handleShowCreateOrderDialog,
    handleCloseCreateOrderDialog,
    handleSubmitCreateOperation,
    handleShowInvoicesBulkUpdateDialog,
    handleCloseInvoicesBulkUpdateDialog,
    handleShowPartialPaymentsBulkUpdateDialog,
    handleClosePartialPaymentsBulkUpdateDialog,
    handleShowFundsBulkUpdateDialog,
    handleCloseFundsBulkUpdateDialog,
  } = useHeader();
  const { operationType } = useInbox();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Title>Lista de operaciones</Title>
      <Box display="flex">
        <HeaderButtons
          country={country}
          operationType={operationType}
          handleChangeAnchorEl={handleChangeAnchorEl}
          handleShowCreateOrderDialog={handleShowCreateOrderDialog}
        />

        <HeaderMenu
          country={country}
          anchorEl={anchorEl}
          handleChangeAnchorEl={handleChangeAnchorEl}
          handleShowInvoicesBulkUpdateDialog={
            handleShowInvoicesBulkUpdateDialog
          }
          handleShowPartialPaymentsBulkUpdateDialog={
            handleShowPartialPaymentsBulkUpdateDialog
          }
          handleShowFundsBulkUpdateDialog={handleShowFundsBulkUpdateDialog}
        />
      </Box>

      {showCreateOrderDialog && (
        <CreateOrderDialog
          open={showCreateOrderDialog}
          operation={operationType}
          handleSubmit={handleSubmitCreateOperation}
          handleCloseDialog={handleCloseCreateOrderDialog}
          isLoading={createOrderIsLoading}
          submitError={createOrderError}
        />
      )}

      {showInvoicesBulkUpdateDialog && (
        <InvoicesBulkUpdateModal
          open={showInvoicesBulkUpdateDialog}
          handleClose={handleCloseInvoicesBulkUpdateDialog}
          sourceType={invoicesBulkUpdateType}
        />
      )}

      {showPartialPaymentsBulkUpdateDialog && (
        <PartialPaymentsBulkUpdateModal
          open={showPartialPaymentsBulkUpdateDialog}
          handleClose={handleClosePartialPaymentsBulkUpdateDialog}
        />
      )}

      {showFundsBulkUpdateDialog && (
        <FundBulkUpdateModal
          open={showFundsBulkUpdateDialog}
          handleClose={handleCloseFundsBulkUpdateDialog}
        />
      )}
    </Box>
  );
};

export default Header;
