import { useQuery } from 'react-query';

import { fetchOrderCreditService } from '../services';

const useOrderCredit = orderId => {
  const { isLoading, data, refetch } = useQuery(
    'getOrderCredit',
    () => fetchOrderCreditService(orderId),
    { refetchOnWindowFocus: false }
  );

  const { data: orderCredit } = data || { data: [] };
  return {
    orderCredit,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useOrderCredit };
