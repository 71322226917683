import React from 'react';
import { Grid } from '@material-ui/core';

import PropTypes from 'prop-types';

import FormInputs from './FormInputs';
import { Dialog } from '../../../../commons/components';

const CreditFormDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      title="Nueva operación de crédito"
      handleClose={handleClose}
      isOpen={open}
      width="md"
      showDialogActions={false}
    >
      <Grid item xs={12} style={{ marginBottom: 45 }}>
        <FormInputs />
      </Grid>
    </Dialog>
  );
};

CreditFormDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreditFormDialog;
