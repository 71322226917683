import { useQuery } from 'react-query';

import { fetchFundRatesServices } from '../services';

const useFetchFundRates = fundId => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    'getFundRates',
    () => fetchFundRatesServices(fundId),
    { refetchOnWindowFocus: false, enabled: !!fundId }
  );

  const { data: fundRates } = data || { data: [] };
  return {
    fundRates,
    isLoading,
    isFetching,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchFundRates };
