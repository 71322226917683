import React from 'react';
import { Grid, styled } from '@material-ui/core';
import StatusFilter from './StatusFilter';
import SearchField from './SearchField';
import AgreementFilter from './AgreementFilter';
import IssuedDateFilter from './IssuedDateFilter';
import PaymentTypeFilter from './PaymentTypeFilter';
import PaymentCesionFilter from './PaymentCesionFilter';
import { usePaymentFilters } from '../../hooks';

const FiltersWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '30px 24px',
  gap: 20,
  borderTop: `2px solid ${theme.palette.payments.grey4}`,
  borderBottom: `1px solid ${theme.palette.payments.grey4}`,
}));

const PaymentFilters = () => {
  const {
    fieldFilter,
    fieldFilters,
    issuedDateFilter,
    paymentTypeFilter,
    paymentTypeFilters,
    paymentCesionFilter,
    paymentCesionFilters,
    statusFilters,
    statusFilter,
    handleChangeFieldFilter,
    handleChangeIssuedDateFilter,
    handleClearIssuedDateFilter,
    handleChangePaymentTypeFilter,
    handleChangePaymentCesionFilter,
    handleChangeStatusFilter,
    agreements,
    agreementFilter,
    handleChangeAgreementFilter,
    handleClearAgreementFilter,
    isCountryCL,
  } = usePaymentFilters();

  return (
    <Grid container direction="column">
      <Grid item>
        <StatusFilter
          filters={statusFilters}
          activeFilter={statusFilter}
          handler={handleChangeStatusFilter}
        />
      </Grid>
      <FiltersWrapper item>
        <SearchField
          filters={fieldFilters}
          activeFilter={fieldFilter}
          handler={handleChangeFieldFilter}
        />
        {isCountryCL && (
          <AgreementFilter
            agreements={agreements}
            selectedAgreementsIds={agreementFilter}
            handler={handleChangeAgreementFilter}
            clearHandler={handleClearAgreementFilter}
          />
        )}
        <IssuedDateFilter
          activeFilter={issuedDateFilter}
          handler={handleChangeIssuedDateFilter}
          clearHandler={handleClearIssuedDateFilter}
        />
        <PaymentTypeFilter
          filters={paymentTypeFilters}
          activeFilter={paymentTypeFilter}
          handler={handleChangePaymentTypeFilter}
        />
        {isCountryCL && (
          <PaymentCesionFilter
            filters={paymentCesionFilters}
            activeFilter={paymentCesionFilter}
            handler={handleChangePaymentCesionFilter}
          />
        )}
      </FiltersWrapper>
    </Grid>
  );
};

export default PaymentFilters;
