import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '../../../../commons/components/Drawer';
import { PayrollForm } from './PayrollForm';

const PayrollFilterComponent = ({ open, onClose, onSubmit, form }) => {
  return (
    <Drawer open={open} onClose={onClose} disableEscapeKeyDown>
      <PayrollForm form={form} onClose={onClose} onSubmit={onSubmit} />
    </Drawer>
  );
};

PayrollFilterComponent.defaultProps = {
  open: false,
};

PayrollFilterComponent.propTypes = {
  form: PropTypes.objectOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export const PayrollFilter = memo(PayrollFilterComponent);
