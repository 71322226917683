import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { settings } from '../../../config/settings';
import { COUNTRY_CODE_CL } from '../../../helpers/Constants';
import { t13s } from '../../../translationKeys';
import {
  useOrderAttributes,
  useUpdateOrderAttribute,
} from '../infrastructure/store';

const usePayrollAttributesTab = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams();
  const { orderAttributes } = useOrderAttributes(orderId);
  const { country } = useSelector(state => state.config);
  const { updateOrderAttribute } = useUpdateOrderAttribute();
  const { currencyCode } = settings[country];
  const [showCurrencyDialog, setShowCurrencyDialog] = useState(false);
  const [showPaymentCessionDialog, setShowPaymentCessionDialog] =
    useState(false);
  const handleShowCurrencyDialog = () => setShowCurrencyDialog(true);
  const handleCloseCurrencyDialog = () => setShowCurrencyDialog(false);
  const handleShowPaymentCessionDialog = () =>
    setShowPaymentCessionDialog(true);
  const handleClosePaymentCessionDialog = () =>
    setShowPaymentCessionDialog(false);

  const {
    currency,
    hasCesion,
    hasFund,
    hasPayerContribution,
    hasRevolving,
    isCeded,
    orderDocuments,
    backupDocuments,
    paymentType,
  } = orderAttributes;

  const handleUpdatePayerContribution = () => {
    updateOrderAttribute({
      orderId,
      attribute: { hasPayerContribution: !hasPayerContribution },
    });
  };

  const handleUpdateRevolving = () => {
    updateOrderAttribute({
      orderId,
      attribute: { hasRevolving: !hasRevolving },
    });
  };

  const showHasCessionAttribute =
    country === COUNTRY_CODE_CL && paymentType !== 'OWN_CAPITAL';

  const ATTRIBUTES = [
    {
      title: 'Docs. de operación',
      value: orderDocuments.concat(backupDocuments),
      flagType: 'icon',
    },
    {
      title: 'Costo de operación',
      value: hasPayerContribution,
      flagType: 'switch',
      isEditable: true,
      handleEdit: handleUpdatePayerContribution,
    },
    {
      title: 'Revolving',
      value: hasRevolving,
      flagType: 'switch',
      isEditable: true,
      handleEdit: handleUpdateRevolving,
    },
    {
      title: 'Tipo de moneda',
      value: currency ?? currencyCode,
      isEditable: true,
      flagType: 'custom',
      handleEdit: handleShowCurrencyDialog,
    },
    {
      title: t(t13s.LABEL.CEDED_INVOICES),
      value: isCeded,
      flagType: 'icon',
    },
    {
      title: 'Fondo asignado',
      value: hasFund,
      flagType: 'icon',
    },
    {
      ...(showHasCessionAttribute && {
        title: 'Tipo de payment',
        value: hasCesion,
        flagType: 'switch',
        handleEdit: handleShowPaymentCessionDialog,
      }),
    },
  ];

  return {
    orderAttributes: ATTRIBUTES,
    showCurrencyDialog,
    showPaymentCessionDialog,
    handleCloseCurrencyDialog,
    handleClosePaymentCessionDialog,
  };
};

export default usePayrollAttributesTab;
