import axios from 'axios';
import {
  RESET_PARTIAL_PAYMENT_ERRORS,
  FETCH_PARTIAL_PAYMENTS_START,
  FETCH_PARTIAL_PAYMENTS_SUCCESS,
  FETCH_PARTIAL_PAYMENTS_FAILURE,
  ADD_PARTIAL_PAYMENT_START,
  ADD_PARTIAL_PAYMENT_SUCCESS,
  ADD_PARTIAL_PAYMENT_FAILURE,
  UPDATE_PARTIAL_PAYMENT_START,
  UPDATE_PARTIAL_PAYMENT_SUCCESS,
  UPDATE_PARTIAL_PAYMENT_FAILURE,
  DELETE_PARTIAL_PAYMENT_START,
  DELETE_PARTIAL_PAYMENT_SUCCESS,
  DELETE_PARTIAL_PAYMENT_FAILURE,
  BULK_PARTIAL_PAYMENT_REGISTER_START,
  BULK_PARTIAL_PAYMENT_REGISTER_SUCCESS,
  BULK_PARTIAL_PAYMENT_REGISTER_FAILURE,
  CALCULATE_DEBT_PARTIAL_PAYMENT_START,
  CALCULATE_DEBT_PARTIAL_PAYMENT_SUCCESS,
  CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';
import { fetchOperationSummary } from './orderActions';

const ORDER_INVOICE = 'orderinvoice';

export const calculateDebtPartialPayment =
  (sourceType, sourceId) => dispatch => {
    dispatch({
      type: CALCULATE_DEBT_PARTIAL_PAYMENT_START,
    });
    axios
      .get(`api/${sourceType}/${sourceId}/partialpay/calculatedebt`)
      .then(({ data }) => {
        dispatch({
          type: CALCULATE_DEBT_PARTIAL_PAYMENT_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE,
            {
              variant: 'error',
            }
          )
        );
        dispatch({
          type: CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE,
        });
      });
  };

export const fetchPartialPayments =
  (sourceType, sourceId, { page = null, size = null } = {}) =>
  dispatch => {
    dispatch({
      type: FETCH_PARTIAL_PAYMENTS_START,
    });
    axios
      .get(`/api/${sourceType}/${sourceId}/partialpay`, {
        params: { page, size },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_PARTIAL_PAYMENTS_SUCCESS,
          payload: { partialPayments: data },
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_PARTIAL_PAYMENTS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_PARTIAL_PAYMENTS_FAILURE,
        });
      });
  };

export const addPartialPayment =
  (sourceType, sourceId, { amount, paymentDate, comment, operationId }) =>
  dispatch => {
    dispatch({
      type: ADD_PARTIAL_PAYMENT_START,
    });
    axios
      .post(`/api/${sourceType}/${sourceId}/partialpay`, {
        amount,
        paymentDate,
        comment,
      })
      .then(({ data }) => {
        dispatch({
          type: ADD_PARTIAL_PAYMENT_SUCCESS,
          payload: { newPartialPayment: data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PARTIAL_PAYMENT_CREATED, {
            variant: 'success',
          })
        );
        if (sourceType === ORDER_INVOICE) {
          dispatch(calculateDebtPartialPayment(sourceType, sourceId));
          dispatch(fetchOperationSummary(operationId));
        }
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: ADD_PARTIAL_PAYMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updatePartialPayment =
  (
    sourceType,
    sourceId,
    partialPaymentId,
    { amount, paymentDate, comment, operationId }
  ) =>
  dispatch => {
    dispatch({
      type: UPDATE_PARTIAL_PAYMENT_START,
    });
    axios
      .put(`/api/${sourceType}/${sourceId}/partialpay/${partialPaymentId}`, {
        amount,
        paymentDate,
        comment,
      })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_PARTIAL_PAYMENT_SUCCESS,
          payload: { updatedPartialPayment: data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PARTIAL_PAYMENT_UPDATED, {
            variant: 'success',
          })
        );
        if (sourceType === ORDER_INVOICE) {
          dispatch(fetchOperationSummary(operationId));
        }
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_PARTIAL_PAYMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const deletePartialPayment =
  (sourceType, sourceId, { partialPaymentId }) =>
  dispatch => {
    dispatch({
      type: DELETE_PARTIAL_PAYMENT_START,
    });
    axios
      .delete(`api/${sourceType}/${sourceId}/partialpay/${partialPaymentId}`)
      .then(() => {
        dispatch({
          type: DELETE_PARTIAL_PAYMENT_SUCCESS,
          payload: { partialPaymentId },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PARTIAL_PAYMENT_DELETED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: DELETE_PARTIAL_PAYMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const bulkPartialPaymentRegister = csvFile => dispatch => {
  dispatch({
    type: BULK_PARTIAL_PAYMENT_REGISTER_START,
  });
  const formData = new FormData();
  formData.append('csv', csvFile, csvFile.name);
  axios
    .post('/api/bulkcsv/partialpay', formData)
    .then(({ data }) => {
      const { errorLines } = data;
      if (errorLines?.length) {
        dispatch({
          type: BULK_PARTIAL_PAYMENT_REGISTER_FAILURE,
          payload: { errorLines },
        });
      } else {
        dispatch({
          type: BULK_PARTIAL_PAYMENT_REGISTER_SUCCESS,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BULK_PARTIAL_PAYMENT_CREATED, {
            variant: 'success',
          })
        );
      }
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: BULK_PARTIAL_PAYMENT_REGISTER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const resetPartialPaymentErrors = () => dispatch => {
  dispatch({
    type: RESET_PARTIAL_PAYMENT_ERRORS,
  });
};
