import { useQuery } from 'react-query';
import { fetchDocumentTypesService } from '../services';

const useFetchDocumentTypes = ({ field, searchInput, active = true }) => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    'getDocumentTypes',
    () => fetchDocumentTypesService({ field, searchInput, active }),
    { refetchOnWindowFocus: false }
  );

  const documentTypes = data || [];

  return {
    documentTypes,
    isLoading,
    refetch,
    isFetching,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchDocumentTypes };
