import React, { ReactNode } from 'react';
import { SvgIcon } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const OpenEyeIcon = ({ color = palette.primary.main, style }: Props) => {
  return (
    <SvgIcon
      style={{
        color,
        ...style,
      }}
      viewBox="0 0 24 24"
    >
      <path d="M12 6.5c3.79 0 7.17 2.13 8.82 5.5-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5z" />
    </SvgIcon>
  );
};

export default OpenEyeIcon;
