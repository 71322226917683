import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as payrollActions from './payroll.actions';
import * as selects from './payroll.selectors';
import { enqueueSnackbar } from '../../../../../actions/notificationAction';

export const useSelectedReceiver = () =>
  useSelector(selects.getSelectedReceiver, shallowEqual);

export const usePayrollSelector = () =>
  useSelector(selects.getPayrollSelector, shallowEqual);

export const useSelectedPayroll = () =>
  useSelector(selects.getSelectedPayroll, shallowEqual);

export const usePayrollActions = () => {
  const dispatch = useDispatch();
  const { payrollId } = useParams();

  const fetchPayroll = useCallback(
    callbacks => payrollActions.fetchPayroll(payrollId, callbacks)(dispatch),
    [dispatch]
  );
  const selectPayroll = useCallback(
    payroll => payrollActions.selectPayroll(payroll)(dispatch),
    [dispatch]
  );
  const selectReceiver = useCallback(
    receiver => payrollActions.selectReceiver(receiver)(dispatch),
    [dispatch]
  );
  const updatePayrollSummary = useCallback(
    payrollSummary =>
      payrollActions.updatePayrollSummary(payrollSummary)(dispatch),
    [dispatch]
  );
  const pickReceiver = useCallback(
    receivers => payrollActions.pickReceiver(receivers)(dispatch),
    [dispatch]
  );
  const pickInvoice = useCallback(
    invoices => payrollActions.pickInvoice(invoices)(dispatch),
    [dispatch]
  );
  const cleanPayrollSelector = useCallback(
    () => payrollActions.cleanPayrollSelector()(dispatch),
    [dispatch]
  );
  const showSnackBar = useCallback(
    ({ msg, type }) => dispatch(enqueueSnackbar(msg, { variant: type })),
    [dispatch]
  );
  const fetchCessionSummary = useCallback(
    payrollId => payrollActions.fetchCessionSummary(payrollId)(dispatch),
    [dispatch]
  );
  const notifyCessions = useCallback(
    payrollId => payrollActions.notifyCessions(payrollId)(dispatch),
    [dispatch]
  );
  const closePayroll = useCallback(
    payrollId => payrollActions.closePayroll(payrollId)(dispatch),
    [dispatch]
  );

  return {
    closePayroll,
    cleanPayrollSelector,
    fetchCessionSummary,
    fetchPayroll,
    notifyCessions,
    pickInvoice,
    pickReceiver,
    selectPayroll,
    selectReceiver,
    showSnackBar,
    updatePayrollSummary,
  };
};
