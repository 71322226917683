import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Grid, Button, Box } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { fetchCredits, registerCredit } from '../actions/creditsActions';
import { CREDITS_NEW_PERFORM } from '../helpers/performsType';
import Can from './Can';
import Panel from './elements/Panel';
import CreditDialog from './dialogs/CreditDialog';
import CreditsTable from './tables/CreditsTable';
import SearchBar from './elements/SearchBar';
import { t13s } from '../translationKeys/index';
import StatusFilter from './elements/StatusFilter';
import ShowSelectedStatuses from './elements/ShowSelectedStatuses';
import useStatuses from '../hooks/useStatuses';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  statusFilterContainer: {
    marginLeft: 10,
  },
  selectedStatusesContainer: {
    marginBlock: 10,
  },
});

const HomeCredits = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { getStatuses } = useStatuses();

  const [showCreateCreditDialog, setShowCreateCreditDialog] = useState(false);
  const [selectedStatusesFilter, setSelectedStatusesFilter] = useState([
    ...getStatuses('credit').map(status => status.key),
  ]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchInput, setSearchInput] = useState(null);
  const [field, setField] = useState(null);

  const {
    credits,
    creditPagination,
    fetchCreditsIsLoading,
    registerCreditIsLoading,
    registerCreditError,
    creditWasRegistered,
  } = useSelector(state => state.credits);

  useEffect(() => {
    dispatch(
      fetchCredits({
        page,
        limit,
        searchInput,
        field,
        status: selectedStatusesFilter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusesFilter, searchInput, page, limit]);

  useEffect(() => {
    if (creditWasRegistered) {
      setShowCreateCreditDialog(false);
    }
  }, [creditWasRegistered]);

  const handleChangePage = (pageData, limitData) => {
    setPage(pageData);
    setLimit(limitData);
  };

  const changeSearchInput = ({ value: valueData, field: fieldData }) => {
    setField(fieldData);
    setSearchInput(valueData);
    setPage(1);
  };

  const handleChangeSelection = statuses => {
    setSelectedStatusesFilter(statuses);
  };

  const handleDeleteSelectedStatus = selectedStatus => {
    setSelectedStatusesFilter(
      selectedStatusesFilter.filter(status => status !== selectedStatus)
    );
  };

  return (
    <Grid className={classes.mainContainer}>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Can
          perform={CREDITS_NEW_PERFORM}
          yes={() => (
            <Button
              onClick={() => setShowCreateCreditDialog(true)}
              startIcon={<AddCircleOutline />}
              color="primary"
              variant="text"
            >
              Nuevo Crédito
            </Button>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Panel
          title="Lista de créditos"
          titlePadding="lg lg zero"
          contentPadding="zero"
          actions={
            <Box className={classes.filterContainer}>
              <SearchBar
                placeholder="Buscar credito..."
                handleChangeInput={value => changeSearchInput(value)}
                fields={[
                  {
                    title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} del Cliente`,
                    field: 'identifier',
                  },
                  { title: 'ID del Crédito', field: 'id' },
                  { title: 'Nombre del Cliente', field: 'name' },
                ]}
                selectedOption={{
                  title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} del Cliente`,
                  field: 'identifier',
                }}
              />
              <Box className={classes.statusFilterContainer}>
                <StatusFilter
                  selectedStatuses={selectedStatusesFilter}
                  onChangeSelection={handleChangeSelection}
                  entityType="credit"
                  statuses={getStatuses('credit')}
                />
              </Box>
            </Box>
          }
        >
          <Box className={classes.selectedStatusesContainer}>
            <ShowSelectedStatuses
              selectedStatuses={selectedStatusesFilter}
              handleDeleteStatus={handleDeleteSelectedStatus}
              entityType="credit"
            />
          </Box>

          <CreditsTable
            tableData={credits}
            loadingCredits={fetchCreditsIsLoading}
            handleChangePage={handleChangePage}
            pagination={creditPagination}
          />
        </Panel>
      </Grid>

      {showCreateCreditDialog && (
        <CreditDialog
          open={showCreateCreditDialog}
          handleCloseDialog={() => {
            setShowCreateCreditDialog(false);
          }}
          handleSubmit={formData => dispatch(registerCredit(formData))}
          isLoading={registerCreditIsLoading}
          error={registerCreditError}
        />
      )}
    </Grid>
  );
};

export default HomeCredits;
