import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { features } from '../config/features';
import {
  deleteBankAccount,
  editBankAccount,
  fetchBankAccountsById,
  registerBankAccount,
  setDefaultBankAccount,
  resetBankAccountHolder,
} from '../actions/bankActions';
import { enqueueSnackbar } from '../actions/notificationAction';
import {
  COUNTRY_CODE_MX,
  GRAY_BORDER_COLOR,
  TRANSFORMED_ACCOUNT_TYPES,
} from '../helpers/Constants';
import { BUSINESS_ADD_BANK_ACCOUNT_PERFORM } from '../helpers/performsType';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import Can from './Can';
import BankAccountDialog from './dialogs/BankAccountDialog';
import ConfirmDialog from './dialogs/ConfirmDialog';
import BankAccountItem from './elements/BankAccountItem';
import Container from './elements/Container';
import Panel from './elements/Panel';
import { t13s } from '../translationKeys';

const useStyles = makeStyles({
  containerRoot: {
    padding: convertSpacingToCss('lg'),
  },
  separatorBottom: {
    height: 1,
    width: '100%',
    backgroundColor: GRAY_BORDER_COLOR,
  },
  separatorRight: {
    width: 1,
    backgroundColor: GRAY_BORDER_COLOR,
    justifyContent: 'flex-end',
  },
});

const entity = 'business';

const BankAccounts = ({ businessId }) => {
  const [dialogs, setDialogs] = useState({
    showDeleteDialog: false,
    showSetDefaultDialog: false,
    showBankAccountDialog: false,
  });
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [isRegisterBankAccount, setIsRegisterBankAccount] = useState(false);
  const [isXepelinBankAccount, setIsXepelinBankAccount] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    bankAccounts,
    deletedBankAccount,
    registeredBankAccount,
    bankAccountSettedDefault,
    bankAccountEdited,
  } = useSelector(state => state.banks);
  const accountsSize =
    (bankAccounts[entity] && bankAccounts[entity][businessId]?.length) || 0;
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchBankAccountsById(entity, businessId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      registeredBankAccount ||
      bankAccountEdited ||
      bankAccountSettedDefault ||
      deletedBankAccount
    ) {
      dispatch(fetchBankAccountsById(entity, businessId));
    }
    setDialogs({
      ...dialogs,
      showDeleteDialog: false,
      showSetDefaultDialog: false,
      showBankAccountDialog: false,
    });
    setSelectedBankAccount(null);
    // eslint-disable-next-line
  }, [
    deletedBankAccount,
    bankAccountSettedDefault,
    registeredBankAccount,
    bankAccountEdited,
  ]);

  const handleShowDeleteDialog = bankAccount => {
    setSelectedBankAccount(bankAccount);
    setDialogs({
      ...dialogs,
      showDeleteDialog: true,
      showSetDefaultDialog: false,
      showBankAccountDialog: false,
    });
  };

  const handleCloseDeleteDialog = () => {
    setSelectedBankAccount(null);
    setDialogs({
      ...dialogs,
      showDeleteDialog: false,
    });
  };

  const handleDeleteBankAccount = () => {
    // businessId is used only in xepelin-server-global.
    dispatch(deleteBankAccount(entity, businessId, selectedBankAccount));
  };

  const handleShowSetDefaultDialog = bankAccount => {
    setSelectedBankAccount(bankAccount);
    setDialogs({
      ...dialogs,
      showSetDefaultDialog: true,
      showDeleteDialog: false,
      showBankAccountDialog: false,
    });
  };

  const handleCloseSetDefaultDialog = () => {
    setSelectedBankAccount(null);
    setDialogs({
      ...dialogs,
      showSetDefaultDialog: false,
    });
  };

  const handleSetDefaultAccount = () => {
    // entity, entityId, bankAccountId
    dispatch(setDefaultBankAccount(entity, businessId, selectedBankAccount));
  };

  const handleShowRegisterAccountDialog = ({ isXepelin = false }) => {
    dispatch(resetBankAccountHolder());
    setIsRegisterBankAccount(true);
    // this flag is to set the kind of bankAccount the user is registering (icon and onSubmit use this flag).
    setIsXepelinBankAccount(isXepelin);
    setDialogs({
      ...dialogs,
      showBankAccountDialog: true,
      showDeleteDialog: false,
    });
  };

  const handleRegisterAccount = bankAccountData => {
    const bankAccountDataObj = {
      ...bankAccountData,
      sourceId: businessId,
    };
    dispatch(registerBankAccount(entity, bankAccountDataObj));
  };

  const getBankAccountToEdit = selectedBankAccount => {
    const bankAccount =
      bankAccounts[entity] &&
      bankAccounts[entity][businessId].find(account => {
        return account.id === selectedBankAccount;
      });

    return {
      ...bankAccount,
      bankId: bankAccount.Bank.id,
    };
  };

  const handleShowEditAccountDialog = bankAccount => {
    const bankAccountToEdit = getBankAccountToEdit(bankAccount);
    setSelectedBankAccount(bankAccountToEdit);
    setIsXepelinBankAccount(bankAccountToEdit.isXepelin);
    setIsRegisterBankAccount(false);
    setDialogs({
      ...dialogs,
      showBankAccountDialog: true,
      showDeleteDialog: false,
      showSetDefaultDialog: false,
    });
  };

  const handleCloseBankAccountDialog = () => {
    setSelectedBankAccount(null);
    setIsRegisterBankAccount(false);
    setDialogs({
      ...dialogs,
      showBankAccountDialog: false,
    });
  };

  const handleEditAccount = bankAccountData => {
    const { id, ...rest } = bankAccountData;
    delete rest.createdAt;
    delete rest.updatedAt;
    delete rest.Bank;
    delete rest.source;
    delete rest.currency;
    delete rest.isXepelin;
    delete rest.isDefault;
    dispatch(
      editBankAccount(entity, businessId, { ...rest, sourceId: businessId }, id)
    );
  };

  const handleSubmitBankAccountDialog = bankAccountData => {
    if (isRegisterBankAccount) {
      handleRegisterAccount(bankAccountData);
    } else {
      handleEditAccount(bankAccountData);
    }
  };

  const handleCopyData = bankAccount => {
    if (bankAccount) {
      const dataString =
        country === COUNTRY_CODE_MX
          ? bankAccount.accountNumber
          : `
    ${bankAccount.accountHolder}
    ${t(t13s.LABEL.BUSINESS_IDENTIFIER)}: ${bankAccount.sourceIdentifier}
    ${bankAccount.Bank.name} 
    ${TRANSFORMED_ACCOUNT_TYPES[bankAccount.accountType]} 
    N° de cuenta: ${bankAccount.accountNumber} 
    ${bankAccount.accountEmail}
    ${bankAccount.alias ? `Alias: ${bankAccount.alias}` : 'Sin alias'}
    `;
      copy(dataString, { format: 'text/plain' });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
          variant: 'success',
        })
      );
    } else {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }
  };

  return (
    <>
      {dialogs.showDeleteDialog && (
        <ConfirmDialog
          isOpen={dialogs.showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          title="Eliminar cuenta bancaria"
          message="¿Deseas eliminar la cuenta bancaria?"
          buttonLabel="Eliminar cuenta"
          buttonOnAccept={handleDeleteBankAccount}
        />
      )}
      {dialogs.showSetDefaultDialog && (
        <ConfirmDialog
          isOpen={dialogs.showSetDefaultDialog}
          handleClose={handleCloseSetDefaultDialog}
          title="Cambiar cuenta predeterminada"
          message="Si cambias la cuenta predeterminada ahora, será la que usaremos para transferir futuras operaciones."
          buttonLabel="Cambiar predeterminada"
          buttonOnAccept={handleSetDefaultAccount}
        />
      )}
      {dialogs.showBankAccountDialog && (
        <BankAccountDialog
          open={dialogs.showBankAccountDialog}
          bankAccount={selectedBankAccount || {}}
          onSubmit={handleSubmitBankAccountDialog}
          onClose={handleCloseBankAccountDialog}
          isRegister={isRegisterBankAccount}
          isXepelin={isXepelinBankAccount}
        />
      )}

      <Container className={classes.containerRoot} gridGap={20}>
        {countryFeatures.bankAccountsActions.showXepelinBankAccounts && (
          <Panel
            title="Cuenta Xepelin"
            variant="section"
            titlePadding="lg"
            contentPadding="zero zero lg zero"
            actions={
              <Can
                perform={BUSINESS_ADD_BANK_ACCOUNT_PERFORM}
                yes={() => (
                  <Button
                    onClick={() =>
                      handleShowRegisterAccountDialog({ isXepelin: true })
                    }
                    startIcon={<AddCircleIcon />}
                    variant="text"
                    color="primary"
                  >
                    Agregar cuenta
                  </Button>
                )}
                no={() => null}
              />
            }
          >
            <Container direction="row">
              {accountsSize ? (
                bankAccounts[entity][businessId]
                  .filter(bankAccount => bankAccount.isXepelin)
                  .map(bankAccount => (
                    <BankAccountItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`item-${bankAccount.id}`}
                      bankAccount={bankAccount}
                      handleShowDeleteDialog={handleShowDeleteDialog}
                      handleShowDefaultAccountDialog={
                        handleShowSetDefaultDialog
                      }
                      handleShowEditDialog={handleShowEditAccountDialog}
                      handleCopyData={handleCopyData}
                    />
                  ))
                  .reduce((accu, elem) => {
                    return accu === null
                      ? [elem]
                      : [
                          ...accu,
                          <div
                            key={`key${accu.length}`}
                            className={classes.separatorBottom}
                          />,
                          elem,
                        ];
                  }, null)
              ) : (
                <Container className={classes.containerRoot}>
                  <Typography variant="subtitle1" color="textPrimary">
                    No se encontaron cuentas Xepelin
                  </Typography>
                </Container>
              )}
            </Container>
          </Panel>
        )}
        <Panel
          title="Cuentas bancarias"
          variant="section"
          titlePadding="lg"
          contentPadding="zero zero lg zero"
          actions={
            <Can
              perform={BUSINESS_ADD_BANK_ACCOUNT_PERFORM}
              yes={() => (
                <Button
                  onClick={handleShowRegisterAccountDialog}
                  startIcon={<AddCircleIcon />}
                  variant="text"
                  color="primary"
                >
                  Agregar cuenta
                </Button>
              )}
              no={() => null}
            />
          }
        >
          <Container direction="row">
            {accountsSize ? (
              bankAccounts[entity][businessId]
                .filter(bankAccount => !bankAccount.isXepelin)
                .map(bankAccount => (
                  <BankAccountItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`item-${bankAccount.id}`}
                    bankAccount={bankAccount}
                    handleShowDeleteDialog={handleShowDeleteDialog}
                    handleShowDefaultAccountDialog={handleShowSetDefaultDialog}
                    handleShowEditDialog={handleShowEditAccountDialog}
                    handleCopyData={handleCopyData}
                  />
                ))
                .reduce((accu, elem) => {
                  return accu === null
                    ? [elem]
                    : [
                        ...accu,
                        <div
                          key={`key${accu.length}`}
                          className={classes.separatorBottom}
                        />,
                        elem,
                      ];
                }, null)
            ) : (
              <Container className={classes.containerRoot}>
                <Typography variant="subtitle1" color="textPrimary">
                  No se encontraron cuentas bancarias
                </Typography>
              </Container>
            )}
          </Container>
        </Panel>
      </Container>
    </>
  );
};

BankAccounts.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export default BankAccounts;
