import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys/index';
import bulkCreatePaymentPromises from '../services/bulkCreatePaymentPromises';

interface Props {
  orderInvoices: number[];
  type: string;
  expirationDate: Date | undefined;
  justification: string;
}

const useBulkCreatePaymentPromises = (onSuccessAction: () => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    data,
    mutate: createPaymentPromises,
    reset: resetDataAndErrors,
    isSuccess,
  } = 
   useMutation((params: Props) => bulkCreatePaymentPromises(params), {
    onMutate: async () => {
      await queryClient.cancelQueries('getPayerById', {
        exact: false,
      });
      const prevDataByPayer = queryClient.getQueryData('getPayerById', {
        exact: false,
      });
      return prevDataByPayer;
    },
    onError: (_err, _variables, context: unknown) => {
      dispatch(
        enqueueSnackbar(t13s.ERROR.ERROR_CREATE_BULK_PAYMENT_PROMISES, {
          variant: 'error',
        })
      );
      queryClient.setQueryData('getPayerById', (context as any).data);
    },
    onSettled: () => {
      queryClient.invalidateQueries('getPayerById', {
        exact: false,
      });
    },
    onSuccess: () => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COLLECTION_PAYMENT_PROMISE_BULK_CREATED, {
          variant: 'success',
        })
      );
      onSuccessAction();
    },
  });

  return {
    isLoading,
    isError,
    data,
    isSuccess,
    createPaymentPromises,
    resetDataAndErrors,
  };
};

export default useBulkCreatePaymentPromises;