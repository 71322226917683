import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  Divider,
  Typography,
  Paper,
} from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import {
  useStyles,
  ContainerRoot,
  ContainerHeader,
  ContainerBody,
  ContainerTitle,
  Title,
  ContainerExclamation,
  customColumnHeader,
} from './styles';
import useFetchCriticalOrderInvoicesInformation from './infrastructure/store/useFetchCriticalOrderInvoicesInformation';
import { Close } from '../../icons';
import CountryFormatHelper from '../../elements/CountryFormatHelper';
import useGetCountry from './../../../hooks/useGetCountry';

type IRow = {
  folio: string;
  amount: number;
  debtDays: number;
  orderId: number;
};

interface Props {
  isOpen: boolean;
  orderInvoicesDebtConfig: {
    debtType: string;
    orderInvoiceIds: number[];
  };
  handleCloseDrawer: () => void;
}

interface debtTypes {
  PAYMENTS_5DAYS: string;
  FD_5DAYS: string;
  FD_60DAYS: string;
  FD_15DAYS: string;
  CRITICAL_AMOUNT: string;
  DEFAULT: string;
}

const OperationsDebtDrawer = ({
  isOpen,
  handleCloseDrawer,
  orderInvoicesDebtConfig: { debtType, orderInvoiceIds },
}: Props) => {
  const classes = useStyles();
  const country = useGetCountry();
  const { criticalOrderInvoicesInformation, isLoading } =
    useFetchCriticalOrderInvoicesInformation({
      orderInvoiceIds,
    });

  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);

  useEffect(() => {
    if (criticalOrderInvoicesInformation?.length) {
      setTotalInvoicesAmount(
        criticalOrderInvoicesInformation.reduce(
          (acc, obj: IRow) => acc + obj.amount,
          0
        )
      );
    }
  }, [criticalOrderInvoicesInformation]);


  function selectTitle() {
    const debtTypes: debtTypes = {
      PAYMENTS_5DAYS: 'Mora Payments sobre 5 días',
      FD_5DAYS: 'Mora FD sobre 5 días',
      FD_60DAYS: 'Mora FD sobre 60 días',
      FD_15DAYS: 'Mora pagador FD sobre 15 días',
      CRITICAL_AMOUNT: 'Saldo Crítico',
      DEFAULT: ''
    };
    return debtTypes[debtType as keyof debtTypes] || debtTypes[debtTypes.DEFAULT as keyof debtTypes];
  }

  const buildColumnsTable = (): Array<Column<IRow>> => {
    return [
      {
        title: 'Folio',
        field: 'folio',
        type: 'string',
        width: '68px',
        render: (rowData: IRow) => (
          <Typography color="textPrimary" variant="body2">
            {rowData.folio}
          </Typography>
        ),
      },
      {
        title: 'Monto Total',
        field: 'amount',
        type: 'numeric',
        width: '112px',
        defaultSort: 'asc',
        headerStyle: customColumnHeader,
        render: (rowData: IRow) => (
          <Typography color="textPrimary" variant="body2">
            {/* @ts-ignore */}
            <CountryFormatHelper
              value={rowData.amount}
              countryId={country}
              variant="currency"
            />
          </Typography>
        ),
      },
      {
        title: 'Días en mora',
        field: 'debtDays',
        type: 'numeric',
        width: '98px',
        headerStyle: customColumnHeader,
        render: (rowData: IRow) => (
          <Typography color="textPrimary" variant="body2">
            {rowData.debtDays}
          </Typography>
        ),
      },
      {
        title: 'ID Op',
        field: 'orderId',
        type: 'numeric',
        width: '68px',
        headerStyle: customColumnHeader,
        render: (rowData: IRow) => (
          <Typography color="textPrimary" variant="body2">
            <RouterLink
              to={`/${country.toLowerCase()}/orders/${rowData.orderId}`}
              target="_blank"
            >
              {rowData.orderId}
            </RouterLink>
          </Typography>
        ),
      },
    ];
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        handleCloseDrawer();
      }}
      classes={{
        paper: classes.rootDrawer,
      }}
    >
      <ContainerRoot>
        <ContainerHeader>
          <ContainerTitle>
            <Title>{selectTitle()}</Title>
          </ContainerTitle>
          <IconButton
            onClick={() => {
              handleCloseDrawer();
            }}
          >
            <Close />
          </IconButton>
        </ContainerHeader>

        <Divider />
      </ContainerRoot>

      <ContainerBody>
        <Box
          component="span"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box flex className={classes.textSubheading}>
            <ContainerExclamation>!!!</ContainerExclamation>
            Facturas en mora
          </Box>

          <Typography component="div" className={classes.textSubheadingBold}>
            {/* @ts-ignore */}
            <CountryFormatHelper
              value={totalInvoicesAmount ?? 0}
              countryId={country}
              variant="currency"
            />
          </Typography>
        </Box>

        <MaterialTable
          columns={buildColumnsTable()}
          data={criticalOrderInvoicesInformation}
          isLoading={isLoading}
          components={{
            Container: props => (
              <Paper
                elevation={0}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            ),
          }}
          options={{
            paging: false,
            sorting: false,
            detailPanelType: 'single',
            toolbar: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No se tienen registros para mostrar',
            },
          }}
        />
      </ContainerBody>
    </Drawer>
  );
};

export default OperationsDebtDrawer;
