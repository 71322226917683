import React from 'react';
import { Box, Button, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import BaseDialog from '../../../../../../../components/dialogs/BaseDialog';
import PanelResumenInformation from '../../../../../../../components/elements/PanelResumenInformation';
import { useStyles } from './styles';
import useHistoryDialog from '../../hooks/useHistoryDialog';

const HistoryLogsDialog = ({
  open,
  handleCloseDialog,
  contextId,
  contextType,
}) => {
  const classes = useStyles();

  const {
    getScoreInfo,
    scoreVariablesData,
    stateTab,
    setStateTab,
    historyLogs,
    fetchHistoryLogsIsLoading,
    scoreVars,
    buildColumnsTableHistoryLogs,
  } = useHistoryDialog({
    contextId,
    contextType,
  });
  return (
    <BaseDialog
      title="Historial de cambios"
      isOpen={open}
      handleClose={handleCloseDialog}
      size="xl"
    >
      {scoreVariablesData.length > 0 && (
        <Grid item xs={12}>
          <Tabs
            value={stateTab}
            TabIndicatorProps={{
              style: {
                height: '3px',
                borderRadius: '10px',
              },
            }}
            onChange={(e, valueTab) => setStateTab(valueTab)}
          >
            <Tab
              label="Cambios"
              value="changes"
              key="changes"
              className={classes.headerTabsTab}
            />
            <Tab
              label="Variables de riesgo"
              value="score"
              key="score"
              className={classes.headerTabsTab}
            />
          </Tabs>
        </Grid>
      )}
      <Grid item className={classes.root}>
        <Grid
          role="tabpanel"
          hidden={stateTab !== 'changes'}
          id={`wrapped-tabpanel-${stateTab}`}
          aria-labelledby={`wrapped-tab-${stateTab}`}
          item
        >
          <MaterialTable
            columns={buildColumnsTableHistoryLogs()}
            data={historyLogs}
            isLoading={fetchHistoryLogsIsLoading}
            components={{
              Container: props => (
                <Paper
                  elevation={0}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              ),
            }}
            options={{
              detailPanelType: 'single',
              pageSize: 5,
              pageSizeOptions: [5, 10, 15],
              emptyRowsWhenPaging: false,
              toolbar: false,
              maxBodyHeight: 400,
            }}
          />
        </Grid>
        {scoreVariablesData.length > 0 && (
          <Grid
            role="tabpanel"
            hidden={stateTab !== 'score'}
            id={`wrapped-tabpanel-${stateTab}`}
            aria-labelledby={`wrapped-tab-${stateTab}`}
            item
            className={classes.tabScore}
          >
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={() => copy(JSON.stringify(scoreVariablesData[0]))}
                color="primary"
                variant="text"
                startIcon={<FileCopyIcon />}
                className={classes.copyButton}
              >
                Copiar variables
              </Button>
            </Box>

            <Grid container item xs={12} className={classes.panels}>
              {scoreVars.map(({ label, type }) => (
                <PanelResumenInformation
                  key={type}
                  title={label}
                  informationDetail={getScoreInfo(type)}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </BaseDialog>
  );
};

HistoryLogsDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  contextId: PropTypes.number.isRequired,
  contextType: PropTypes.string.isRequired,
};

export default HistoryLogsDialog;
