import { useContext } from 'react';

import { PayrollDetailContext } from './PayrollDetailContext';

export const usePayrollDetailContext = () => {
  const context = useContext(PayrollDetailContext);

  if (!context) {
    throw new Error(
      'usePayrollDetailContext must be used within a PayrollDetailContextProvider'
    );
  }

  return context;
};
