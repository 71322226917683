import { PAYROLL_TYPE } from '../../../commons/constants/payroll';
import { dateToDDMMYYYY, percentageFormat } from '../../../commons/utils';
import { SORT_BY } from './constants';

export const getSortLabel = val => SORT_BY.find(s => s.value === val).label;
export const defaultSortBy = SORT_BY.find(s => s.isDefault);

export const getDescription = payroll => {
  return [
    {
      label: 'ID',
      value: payroll?.id,
    },
    {
      label: 'Tipo',
      value: PAYROLL_TYPE[payroll?.type]?.label,
    },
    {
      label: 'N° Facturas',
      value: payroll?.invoicesNumber,
    },
    {
      label: 'Pagadores',
      value: payroll?.receiversNumber,
    },
    {
      label: '% Activo',
      value: percentageFormat(payroll?.activePercentage),
    },
    {
      label: '% No Act - cedido',
      value: percentageFormat(payroll?.notActiveAssignedPercentage),
    },
    {
      label: '% No Cedido',
      value: percentageFormat(payroll?.notAssignedPercentage),
    },
    {
      label: 'Tasa Ponderada',
      value: percentageFormat(payroll?.avgRate),
    },
    {
      label: 'Fecha creación',
      value: dateToDDMMYYYY(payroll?.createdAt),
    },
  ];
};
