// @ts-nocheck
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        textAlign: 'start',
      },
    },
  },
});

interface Props {
  values: string[];
  options: string[];
}

const ChipSelector: FC<Props> = ({ values, options, ...otherProps }) => {
  const classes = useStyles();
  const selectedValues = values || [];
  return (
    <Autocomplete
      className={classes.autocomplete}
      selectOnFocus
      value={selectedValues}
      multiple={true}
      freeSolo
      options={options}
      /* eslint-disable react/jsx-props-no-spreading */
      {...otherProps}
    />
  );
};

export default ChipSelector;
