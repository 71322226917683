import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from '../reducers/rootReducer';
import wsMiddleware from '../middlewares/websocketMiddleware';
import tracker from '../helpers/tracker';
import migrations from './migration';
import {
  SET_REMOTE_VERSION,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../actions/types';
// import logger from "redux-logger";

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

let middleware = [];
if (process.env.NODE_ENV !== 'production' && process.env.REDUX_LOGGER) {
  // eslint-disable-next-line global-require
  const logger = require('redux-logger').createLogger({
    predicate: (getState, action) =>
      ![SET_REMOTE_VERSION, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR].includes(
        action.type
      ),
  });
  middleware = [logger];
}

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  applyMiddleware(tracker.middleware),
  applyMiddleware(wsMiddleware),
  applyMiddleware(...middleware)
);

const persistConfig = {
  key: 'root',
  storage,
  version: 17,
  whitelist: ['auth', 'order', 'config'],
  timeout: null,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};
