import axios from 'axios';
import { DIRECT_FINANCING, UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { OperationCountTotal } from '../../../commons/interfaces/ARInterfaces';

const fetchOperationTotalsService = async ({
  operationType = DIRECT_FINANCING,
}: {
  operationType: string;
}) => {
  try {
    const { data } = await axios.get<OperationCountTotal[]>(
      '/api/orders/totals',
      {
        params: { operation: operationType },
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fetchOperationTotalsService;
