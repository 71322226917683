import { useQuery } from 'react-query';
import { fetchCommentsCounterService } from '../services';

const useFetchCommentsCounter = (orderId, userEmail) => {
  const { isLoading, data, isFetching, isRefetching, refetch } = useQuery(
    ['getCommentsCounter'],
    () => fetchCommentsCounterService(orderId, userEmail),
    { refetchOnWindowFocus: false }
  );

  const {
    data: { hasPendingComments, commentsCount },
  } = data || { data: { hasPendingComments: false, commentsCount: 0 } };

  return {
    hasPendingComments,
    commentsCount,
    isLoading: isLoading || isFetching || isRefetching,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchCommentsCounter };
