/* eslint react/prop-types: 0 */ // --> OFF
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HistoryIcon from '@material-ui/icons/History';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import StatusBadge from './StatusBadge';
import PaginationMaterialTable from './PaginationMaterialTable';
import {
  WALLET_ACCOUNT_TYPES,
  WALLET_ACTIVITIES,
} from '../../helpers/Constants';
import { dateFormatter } from '../../helpers/DateUtils';
import CountryFormatHelper from './CountryFormatHelper';
import WalletItem from './WalletItem';

const useStyles = makeStyles({
  menuItemIcon: {
    marginRight: 10,
  },
});

const WalletsTable = ({
  handleShowWalletStatusDialog,
  handleChangePage,
  wallets,
  pagination,
  loading,
  handleShowCreateTransactionDialog,
  handleShowRequestStatusDialog,
  handleShowRequestsHistoryDialog,
  handleAccountState,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const openMenu = (e, rowData) => {
    const anchorElement = e.currentTarget;
    setCurrentRow(rowData);
    setAnchorEl(anchorElement);
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const walletColumns = [
    {
      title: 'Wallet ID',
      field: 'AccountProfile.id',
      type: 'numeric',
      align: 'center',
      render: ({ AccountProfile: { id } }) => (
        <Typography variant="body1" color="textPrimary">
          {id || '-'}
        </Typography>
      ),
    },
    {
      title: 'Rut',
      field: 'AccountProfile.identifier',
      type: 'string',
      render: ({ AccountProfile: { identifier } }) => (
        <Typography variant="body1" color="textPrimary">
          {identifier || '-'}
        </Typography>
      ),
    },
    {
      title: 'Razón social',
      field: 'AccountProfile.name',
      type: 'string',
      render: ({ AccountProfile: { name } }) => (
        <Typography variant="body1" color="textPrimary">
          {name || '-'}
        </Typography>
      ),
    },
    {
      title: 'Tipo de cuenta',
      field: 'accountProfile.type',
      type: 'string',
      render: ({ AccountProfile: { type } }) => (
        <Typography variant="body1" color="textPrimary">
          {WALLET_ACCOUNT_TYPES[type]}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      field: 'balance',
      type: 'numeric',
      align: 'center',
      render: ({ balance }) => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={balance}
              countryId="CL"
              variant="currency"
            />
          </Typography>
        );
      },
    },
    {
      title: 'Fecha último movimiento',
      field: 'lastTransactionDate',
      type: 'date',
      render: ({ AccountActivities }) => {
        return (
          <Typography variant="body1" color="textPrimary">
            {AccountActivities?.[0]?.createdAt
              ? dateFormatter(AccountActivities[0].createdAt)
              : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo último movimiento',
      field: 'lastTransactionType',
      type: 'string',
      render: ({ AccountActivities }) => {
        return (
          <Typography variant="body1" color="textPrimary">
            {AccountActivities?.[0]?.activity
              ? WALLET_ACTIVITIES[AccountActivities[0].activity]
              : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Acciones',
      render: rowData => {
        return (
          <IconButton onClick={e => openMenu(e, rowData)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
    {
      title: 'Estado',
      field: 'status',
      align: 'center',
      render: rowData => {
        return (
          <StatusBadge
            status={rowData.status}
            chipWidth={100}
            handleClick={() => handleShowWalletStatusDialog(rowData)}
          />
        );
      },
    },
  ];

  return (
    <>
      <MaterialTable
        columns={walletColumns}
        data={wallets}
        isLoading={loading}
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination.maxRecords}
              page={pagination.page}
            />
          ),
          Toolbar: () => null,
        }}
        options={{
          showTitle: false,
          emptyRowsWhenPaging: false,
          search: false,
          sorting: true,
          actionsColumnIndex: -1,
          pageSize: pagination.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        detailPanel={rowData => {
          return (
            <WalletItem
              handleShowCreateTransactionDialog={
                handleShowCreateTransactionDialog
              }
              handleShowRequestStatusDialog={handleShowRequestStatusDialog}
              walletData={rowData}
            />
          );
        }}
        onChangePage={handleChangePage}
        localization={{
          pagination: {
            labelRowsSelect: 'wallets',
            labelRowsPerPage: 'Wallets por página',
            labelDisplayedRows: '{count} wallets totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay wallets para mostrar',
          },
        }}
      />
      {showMenu && (
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={showMenu}
          onClick={event => event.stopPropagation()}
          onSelect={event => event.stopPropagation()}
          onClose={closeMenu}
        >
          <MenuItem onClick={() => handleAccountState(currentRow)}>
            <CloudDownloadIcon className={classes.menuItemIcon} />
            Descargar estado de cuenta
          </MenuItem>
          <MenuItem onClick={() => handleShowRequestsHistoryDialog(currentRow)}>
            <HistoryIcon className={classes.menuItemIcon} />
            Historial de solicitudes
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

WalletsTable.propTypes = {
  handleShowCreateTransactionDialog: PropTypes.func.isRequired,
  handleShowWalletStatusDialog: PropTypes.func.isRequired,
  handleShowRequestsHistoryDialog: PropTypes.func.isRequired,
  handleAccountState: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(Object).isRequired,
  wallets: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WalletsTable;
