import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Text from '../../../commons/components/Text';
import EditIcon from '../../../commons/icons/EditIcon';
import { renegotiationTypes } from '../../../commons/utils/constants';
import { useStyles } from './styles';
import AlertForm from '../../../commons/components/AlertForm/AlertForm';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';
import {
  validateNumbers,
  validateBaseRate,
} from '../../../commons/utils/validateNumbers';
import { Countries } from '../../../../interfaces/globalInterfaces';
import { RootStateOrAny, useSelector } from 'react-redux';

const MAX_LENGTH_FEE_AMOUNT = (country: string) => {
  if (country === 'CL') return 12;
  return 15;
};
const MIN_EXTENSION_INTEREST_RATE = 0;
const MAX_EXTENSION_INTEREST_RATE = 100;

interface Props {
  open: boolean;
  closeDrawer: () => void;
  handleOnClick: (
    type: string,
    expirationDate: Date | undefined,
    feeAmount: number | undefined,
    startDate: Date | undefined,
    baseRate: number | undefined,
    justification: string,
    linkDocuments: string
  ) => void;
  isLoading: boolean;
}

const ExtensionsDrawer = ({
  open,
  closeDrawer,
  handleOnClick,
  isLoading,
}: Props) => {
  const classes = useStyles();
  const loading = false;
  const [selectedRenegotiation, setSelectedRenegotiation] = useState<string>(
    renegotiationTypes[0].value
  );
  const [dueInterestDate, setDueInterestDate] = useState<Moment | null>(null);
  const [paymentCapitalDate, setPaymentCapitalDate] = useState<Moment | null>(
    null
  );
  const [feeAmount, setFeeAmount] = useState<string>('');
  const [baseRate, setBaseRate] = useState<string>('');
  const [justification, setJustification] = useState('');
  const [linkDocuments, setLinkDocuments] = useState('');
  const [check, setCheck] = useState(false);
  const [click, setClick] = useState(false);
  const country: Countries = useSelector(
    (state: RootStateOrAny) => state.config.country
  );

  const validate = () => {
    if (
      check &&
      feeAmount &&
      justification &&
      linkDocuments &&
      dueInterestDate &&
      paymentCapitalDate
    ) {
      setClick(true);
    } else {
      setClick(false);
    }
  };

  useEffect(() => {
    validate();
  }, [
    check,
    feeAmount,
    justification,
    linkDocuments,
    dueInterestDate,
    paymentCapitalDate,
  ]);

  const handleFeeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    if (validateNumbers(e.target.value, country)) {
      setFeeAmount(e.target.value);
    }
  };

  const handleExtensionInterestRate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = Number(event.target.value);
    if (
      validateBaseRate(event.target.value) &&
      value >= MIN_EXTENSION_INTEREST_RATE &&
      value <= MAX_EXTENSION_INTEREST_RATE
    ) {
      setBaseRate(event.target.value);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => closeDrawer()}>
      <div style={{ maxWidth: '350px', padding: '20px' }}>
        <Box my={1} ml={1}>
          <div style={{ display: 'flex' }}>
            <EditIcon />
            <Text
              className={classes.title}
              isLoading={loading}
              variant="h5"
              align="center"
              fontWeight="bold"
            >
              Renegociar deuda
            </Text>
            <Button
              style={{ marginLeft: '65px' }}
              onClick={() => closeDrawer()}
            >
              X
            </Button>
          </div>
          <Divider />
        </Box>
        <Box mt={2} mb={1}>
          <Box mt={2}>
            <ul style={{ margin: 0, padding: 0 }}>
              <li className={classes.spacingBottom}>
                <Text isLoading={loading} className={classes.label}>
                  Ingresa la información para aplicar los cambios de las
                  condiciones de la deuda.
                </Text>
              </li>
              <li className={classes.spacingVertical}>
                <Text isLoading={loading} fontWeight="bold">
                  Información del cambio
                </Text>
              </li>
              <li className={classes.spacingVertical}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ width: 330 }}
                >
                  <InputLabel>Tipo de renegociación</InputLabel>
                  <Select
                    disabled
                    value={selectedRenegotiation}
                    label="Tipo de renegociación"
                    onChange={e =>
                      setSelectedRenegotiation(e.target.value as string)
                    }
                  >
                    {renegotiationTypes.map(option => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
              <li className={classes.spacingVerticalDif}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    style={{ width: 330 }}
                    label="Nueva fecha para pago de capital"
                    format={'DD-MM-YYYY'}
                    value={paymentCapitalDate}
                    onChange={date => setPaymentCapitalDate(date)}
                    minDateMessage="Fecha inválida"
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  label="Intereses de mora ($)"
                  variant="outlined"
                  style={{ width: 330 }}
                  value={feeAmount}
                  onChange={handleFeeAmount}
                  helperText={`${
                    MAX_LENGTH_FEE_AMOUNT(country) -
                    (feeAmount?.toString().length || 0)
                  } caracteres restantes.`}
                />
              </li>
              <li className={classes.spacingVertical5}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    style={{ width: 330 }}
                    label="Fecha de pago interes de mora"
                    format={'DD-MM-YYYY'}
                    value={dueInterestDate}
                    onChange={date => setDueInterestDate(date)}
                    minDateMessage="Fecha inválida"
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  label="Tasa de interes de prorroga (%)"
                  variant="outlined"
                  style={{ width: 330 }}
                  value={baseRate}
                  onChange={handleExtensionInterestRate}
                />
              </li>
              <li style={{ display: 'flex' }}>
                <Text isLoading={loading} fontWeight="bold">
                  Motivo de renegociación
                </Text>
                <Text isLoading={loading} className={classes.spacingLeft}>
                  (Requerido)
                </Text>
              </li>
              <li className={classes.spacingVertical5}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={2}
                  className={classes.roundCorners}
                  style={{ width: 330 }}
                  value={justification}
                  inputProps={{ maxLength: 500 }}
                  onChange={e => setJustification(e.target.value)}
                  helperText={`${
                    500 - justification.length
                  } caracteres restantes.`}
                />
              </li>
              <li className={classes.spacingVertical5}>
                <Text isLoading={loading} fontWeight="bold">
                  Documentos de respaldo
                </Text>
              </li>
              <li className={classes.spacingVertical5}>
                <Text isLoading={loading} className={classes.label}>
                  Copia el link de la carpeta de Google Drive donde subiste los
                  documentos.
                </Text>
              </li>
              <li className={classes.spacingTop}>
                <TextField
                  variant="outlined"
                  style={{ width: 330 }}
                  value={linkDocuments}
                  onChange={e => setLinkDocuments(e.target.value)}
                  helperText="Ingresa un link válido, debe partir con http://"
                />
              </li>
              <li className={classes.spacingTop}>
                <AlertForm
                  variant="info"
                  message="Recuerda cargar los documentos relevantes a una carpeta de Drive y dejar esta carpeta visible para otros usuarios, antes de copiar y pegar el link en esta sección. "
                  // @ts-ignore
                  className={classes.alertForm}
                />
              </li>
              <li style={{ display: 'flex', marginTop: 20 }}>
                <Checkbox checked={check} onChange={() => setCheck(!check)} />
                <Text isLoading={loading} className={classes.labelCheckBox}>
                  Confirmo que estoy cumpliendo con los requisitos y cuento con
                  todas las aprobaciones necesarias para crear esta prórroga.{' '}
                </Text>
              </li>
              <li className={classes.spacingAll}>
                <Button
                  variant="outlined"
                  style={{ width: 150 }}
                  onClick={closeDrawer}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!click || isLoading}
                  style={{ width: 150 }}
                  onClick={() =>
                    handleOnClick(
                      selectedRenegotiation,
                      paymentCapitalDate?.toDate(),
                      Number(feeAmount),
                      dueInterestDate?.toDate(),
                      Number(baseRate),
                      justification,
                      linkDocuments
                    )
                  }
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Confirmar'
                  )}
                </Button>
              </li>
            </ul>
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default ExtensionsDrawer;
