import React, { FC } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import HistoryLogsDialog from '../../../../../components/dialogs/HistoryLogsDialog';
import OrderCommentsDrawer from '../../../../commons/components/OrderCommentsDrawer';
import OrderCommentsIcon from '../../../../commons/components/OrderCommentsIcon';
import HistoryIcon from '../../../../commons/icons/HistoryIcon';
import { OperationList } from '../../../../commons/interfaces/ARInterfaces';
import useOperationCard from '../../../hooks/useOperationCard';
import { ButtonCollapse } from './style';
import ArrowDownIcon from '../../../../commons/icons/ArrowDownIcon';
import ArrowUpIcon from '../../../../commons/icons/ArrowUpIcon';

interface Props {
  operation: OperationList;
  isExpanded: boolean;
  handleToggleExpanded: () => void;
}

const OperationCardSectionButtons: FC<Props> = ({
  operation,
  isExpanded,
  handleToggleExpanded,
}) => {
  const {
    showHistoryLogDialog,
    showCommentsDrawer,
    countryFeatures,
    handleOpenHistoryLogDialog,
    handleCloseHistoryLogDialog,
    handleOpenCommentsDrawer,
    handleCloseCommentsDrawer,
  } = useOperationCard();

  return (
    <>
      <Box mr={5}>
        {countryFeatures.orderActions.showHistoryLogsButton && (
          <Tooltip title="Historial de cambios" arrow>
            <IconButton onClick={handleOpenHistoryLogDialog}>
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box>
        <Tooltip title="Comentarios">
          <OrderCommentsIcon
            commentsCount={operation?.commentsCount || 0}
            handleOpenDrawer={handleOpenCommentsDrawer}
          />
        </Tooltip>
      </Box>

      <ButtonCollapse onClick={handleToggleExpanded}>
        {isExpanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
      </ButtonCollapse>

      {showHistoryLogDialog && (
        <HistoryLogsDialog
          open={showHistoryLogDialog}
          handleCloseDialog={handleCloseHistoryLogDialog}
          contextId={operation?.id}
          contextType="ORDER"
        />
      )}

      {showCommentsDrawer && (
        <OrderCommentsDrawer
          isOpen={showCommentsDrawer}
          flagType="operationList"
          operationId={operation?.id}
          handleCloseDrawer={handleCloseCommentsDrawer}
        />
      )}
    </>
  );
};

export default OperationCardSectionButtons;
