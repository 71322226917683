import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';
import { User } from '../../interfaces';

const fetchUsers = async () => {
  try {
    const { data } = await axios.get<User[]>('/api/user/all/simple');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fetchUsers;
