import * as PayrollMap from '../../payroll/services/payroll.map';

export const receiverMap = {
  toDomain: (receiver = {}) => ({
    acquiredAssignments: receiver.acquiredAssignments,
    activePercentage: receiver.activePercentage,
    amount: receiver.amount,
    averageFundRate: receiver.avgRate,
    averageEffectiveFundRate: receiver.avgEffectiveFundCostRate,
    averageScore: receiver.score,
    averageTerm: receiver.avgTerm,
    avgOriginationRate: receiver.avgBaseRate,
    invoicesNumber: receiver.invoiceNumber,
    issuersNumber: receiver.issuersNumber,
    notAssignedPercentage: receiver.notAssignedPercentage,
    acknowledgementPercentage: receiver.acknowledgementPercentage,
    notActiveAssignedPercentage: receiver.notActiveAssignedPercentage,
    payrollInvoicesId: receiver.payrollInvoicesId,
    receiver: receiver.name,
    receiverDebt: receiver.debtServiceAmount,
    receiverId: receiver.receiverIdentifier,
    totalInvoices: receiver.totalDocuments,
  }),
};

export const receiverEditionMap = {
  toDomain: (res = {}) => ({
    receivers: res?.receivers?.map(r => ({
      averageFundRate: r?.rate,
      receiverId: r?.identifier,
    })),
    payrollSummary: PayrollMap.payrollMap.toDomain(res.payrollSummary),
  }),
  toPersistence: (form = {}) => ({
    payrollId: form.payrollId,
    receivers: form.receivers?.map(r => ({
      effectiveFundCostRate: r.effectiveFundCostRate,
      rate: r.rate,
      identifier: r.receiverId,
    })),
  }),
};
