import { RoleInput } from '../schemas';
import { 
  isRoleAdmin, 
  isRoleCommercial, 
  isRoleCorpsClKam, 
  isRolecorpsClLeader, 
  isRoleCorpsClSalesOps, 
  isRolePymesClKamId, 
  isRolePymesClLeaderId, 
  isRolePymesClManagement, 
  isRoleRevenueMxKamActivation, 
  isRoleRevenueMxKamEnrollment, 
  isRoleRevenueMxKamRecurrence,
  isRoleRevenueMxLeader,
  isRoleRevenueMxManagement
} from './roles';

export const canEditStateToAppeal = (roles: RoleInput[] = []) => {
    const isAdminOrKam = (role: RoleInput) => (
      isRoleAdmin(role) || isRoleCommercial(role) ||
      isRolePymesClKamId(role) || isRolePymesClLeaderId(role) ||
      isRolePymesClManagement(role) || isRoleCorpsClKam(role) ||
      isRoleCorpsClSalesOps(role) || isRolecorpsClLeader(role) ||
      isRoleRevenueMxKamEnrollment(role) || isRoleRevenueMxKamActivation(role) || 
      isRoleRevenueMxKamRecurrence(role) || isRoleRevenueMxLeader(role) || 
      isRoleRevenueMxManagement(role)
    );
    return roles.some(isAdminOrKam);
  }
