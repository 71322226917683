import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const RedirectToProductAdapter = ({ orderType, country }) => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const redirectTo = {
    CONFIRMING: () => {
      if (!pathname.includes('/payments/')) {
        window.location.href = `/${country.toLowerCase()}/payments/${id}`;
      }
    },
    DIRECT_FINANCING: () => {
      if (!pathname.includes('/orders/')) {
        window.location.href = `/${country.toLowerCase()}/orders/${id}`;
      }
    },
    EARLY_PAYMENT: () => {
      if (!pathname.includes('/orders/')) {
        window.location.href = `/${country.toLowerCase()}/orders/${id}`;
      }
    },
  };

  useEffect(() => {
    if (orderType && country) {
      redirectTo[orderType]();
    }
  }, [orderType, country]);

  return null;
};

RedirectToProductAdapter.propTypes = {
  orderType: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default RedirectToProductAdapter;
