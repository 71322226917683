import {
  FETCH_AGREEMENTS_START,
  FETCH_AGREEMENTS_SUCCESS,
  FETCH_AGREEMENTS_FAILURE,
  REGISTER_AGREEMENT_START,
  REGISTER_AGREEMENT_SUCCESS,
  REGISTER_AGREEMENT_FAILURE,
  EDIT_AGREEMENT_START,
  EDIT_AGREEMENT_SUCCESS,
  EDIT_AGREEMENT_FAILURE,
  UPDATE_SELECTED_AGREEMENT,
  RESET_AGREEMENT_FORM_DIALOG,
  RESET_AGREEMENT_ERROR,
  FETCH_AGREEMENTS_GROUPS_START,
  FETCH_AGREEMENTS_GROUPS_SUCCESS,
  FETCH_AGREEMENTS_GROUPS_FAILURE,
  FETCH_AGREEMENTS_CONTACTS_START,
  FETCH_AGREEMENTS_CONTACTS_SUCCESS,
  FETCH_AGREEMENTS_CONTACTS_FAILURE,
  REGISTER_AGREEMENTS_CONTACTS_START,
  REGISTER_AGREEMENTS_CONTACTS_SUCCESS,
  REGISTER_AGREEMENTS_CONTACTS_FAILURE,
  DELETE_AGREEMENTS_CONTACTS_START,
  DELETE_AGREEMENTS_CONTACTS_SUCCESS,
  DELETE_AGREEMENTS_CONTACTS_FAILURE,
  EDIT_AGREEMENTS_CONTACTS_START,
  EDIT_AGREEMENTS_CONTACTS_SUCCESS,
  EDIT_AGREEMENTS_CONTACTS_FAILURE,
} from '../actions/types';

const initialState = {
  agreements: [],
  pagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
  },
  loadingAgreements: false,
  savingAgreement: false,
  savedAgreement: false,
  agreementError: '',
  selectedAgreement: null,
  agreementsGroups: [],
  loadingAgreementsGroup: false,
  agreemenGrouptError: null,
  contacts: [],
  loadingContacts: false,
  savingContacts: false,
  savedContacts: false,
  deletingContacts: false,
  deletedContacts: false,
  editingContacts: false,
  editedContacts: false,
  contactsError: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AGREEMENTS_START:
      return { ...state, loadingAgreements: true };
    case FETCH_AGREEMENTS_SUCCESS:
      return {
        ...state,
        loadingAgreements: false,
        agreements: payload.agreementData,
        pagination: payload.pagination,
      };
    case FETCH_AGREEMENTS_FAILURE:
      return {
        ...state,
        loadingAgreements: false,
        agreementError: payload.errorCode,
      };
    case REGISTER_AGREEMENT_START:
      return {
        ...state,
        savingAgreement: true,
        savedAgreement: false,
        agreementError: null,
      };
    case REGISTER_AGREEMENT_SUCCESS:
      return {
        ...state,
        savingAgreement: false,
        savedAgreement: true,
        agreements: payload.agreementData,
      };
    case REGISTER_AGREEMENT_FAILURE:
      return {
        ...state,
        savingAgreement: false,
        savedAgreement: false,
        agreementError: payload.errorCode,
      };
    case EDIT_AGREEMENT_START:
      return { ...state, savingAgreement: true, savedAgreement: false };
    case EDIT_AGREEMENT_SUCCESS:
      return {
        ...state,
        savingAgreement: false,
        savedAgreement: true,
        agreements: payload.agreementData,
      };
    case EDIT_AGREEMENT_FAILURE:
      return {
        ...state,
        savingAgreement: false,
        savedAgreement: false,
        agreementError: payload.errorCode,
      };

    case UPDATE_SELECTED_AGREEMENT:
      return {
        ...state,
        selectedAgreement: payload.selectedAgreement,
      };

    case RESET_AGREEMENT_FORM_DIALOG:
      return {
        ...state,
        savingAgreement: false,
        savedAgreement: false,
        agreementError: '',
      };
    case RESET_AGREEMENT_ERROR:
      return {
        ...state,
        agreementError: initialState.agreementError,
        contactsError: initialState.contactsError,
      };
    case FETCH_AGREEMENTS_GROUPS_START:
      return { ...state, loadingAgreements: true };
    case FETCH_AGREEMENTS_GROUPS_SUCCESS:
      return {
        ...state,
        loadingAgreements: false,
        agreementsGroups: payload.agreementsGroups || payload,
      };
    case FETCH_AGREEMENTS_GROUPS_FAILURE:
      return {
        ...state,
        loadingAgreements: false,
        agreemenGrouptError: payload.errorCode,
      };
    case FETCH_AGREEMENTS_CONTACTS_START:
      return { ...state, loadingContacts: true };
    case FETCH_AGREEMENTS_CONTACTS_SUCCESS:
      return {
        ...state,
        loadingContacts: false,
        contacts: payload,
      };
    case FETCH_AGREEMENTS_CONTACTS_FAILURE:
      return {
        ...state,
        loadingContacts: false,
        contactsError: payload.errorCode,
      };
    case REGISTER_AGREEMENTS_CONTACTS_START:
      return { ...state, savingContacts: true, savedContacts: false };
    case REGISTER_AGREEMENTS_CONTACTS_SUCCESS:
      return {
        ...state,
        savingContacts: false,
        savedContacts: true,
        contacts: [...state.contacts, payload],
      };
    case REGISTER_AGREEMENTS_CONTACTS_FAILURE:
      return {
        ...state,
        savingContacts: false,
        savedContacts: false,
        contactsError: payload.errorCode,
      };
    case DELETE_AGREEMENTS_CONTACTS_START:
      return { ...state, deletingContacts: true, deletedContacts: false };
    case DELETE_AGREEMENTS_CONTACTS_SUCCESS:
      return {
        ...state,
        deletingContacts: false,
        deletedContacts: true,
        contacts: state.contacts.filter(contact => contact.id !== payload),
      };
    case DELETE_AGREEMENTS_CONTACTS_FAILURE:
      return {
        ...state,
        deletingContacts: false,
        deletedContacts: false,
        contactsError: payload.errorCode,
      };
    case EDIT_AGREEMENTS_CONTACTS_START:
      return { ...state, editingContacts: true, editedContacts: false };
    case EDIT_AGREEMENTS_CONTACTS_SUCCESS: {
      const contacts = state.contacts.map(contact =>
        contact.id !== payload.id ? contact : { ...contact, ...payload }
      );
      return {
        ...state,
        editingContacts: false,
        editedContacts: true,
        contacts,
      };
    }
    case EDIT_AGREEMENTS_CONTACTS_FAILURE:
      return {
        ...state,
        editingContacts: false,
        editedContacts: false,
        contactsError: payload.errorCode,
      };
    default:
      return state;
  }
};
