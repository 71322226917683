import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import 'react-phone-input-2/lib/bootstrap.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetPayerFormDialog } from '../../actions/payerAction';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import AlertForm from '../elements/AlertForm';
import CheckboxValidator from '../elements/CheckboxValidator';
import LoadingButton from '../elements/LoadingButton';
import BaseDialog from './BaseDialog';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles({
  titleSpacing: {
    marginBottom: 15,
  },
});

const PayerFormDialog = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    open,
    saving,
    error,
    resetError,
    taxSubSectors,
    handleCloseDialog,
    handleSubmitForm,
  } = props;

  const [payer, setPayer] = useState({
    name: '',
    identifier: '',
    taxSubSectorId: taxSubSectors[taxSubSectors?.length - 1]?.id || '',
    confirmationContactName: '',
    confirmationContactPhone: '',
    confirmationContactEmail: '',
    payerContactName: '',
    payerContactPhone: '',
    payerContactEmail: '',
    sameContact: false,
    economicGroup: {},
  });
  const { economicGroups } = useSelector(state => state.economicGroup);
  const { country } = useSelector(state => state.config);

  const {
    name,
    identifier,
    taxSubSectorId,
    confirmationContactName,
    confirmationContactPhone,
    confirmationContactEmail,
    payerContactName,
    payerContactPhone,
    payerContactEmail,
    sameContact,
  } = payer;

  useEffect(() => {
    resetError();
    dispatch(resetPayerFormDialog());

    ValidatorForm.addValidationRule('validateIdentifier', value => {
      return validateIdentifier(value, country);
    });

    ValidatorForm.addValidationRule(
      'emailValidatorWithAllCases',
      emailValue => {
        // Una implementación del Estandard Official: RFC 5322 (valida en el 99.99% de los emails existentes)
        const regexEmail =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        const secondRegexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const emailValidator = emailValue.includes('@')
          ? emailValue.split('@')
          : emailValue.split('.');
        const urlEmailValidator = emailValidator[1]?.split('.');
        if (
          !regexEmail.test(emailValue) ||
          !secondRegexEmail.test(emailValue) ||
          emailValidator[2]?.length > 3 ||
          urlEmailValidator[1]?.length > 3 ||
          urlEmailValidator.length > 3 ||
          emailValidator.length > 3
        ) {
          return false;
        }
        return true;
      }
    );

    ValidatorForm.addValidationRule('phone with simbol or nah', phoneValue => {
      const regexPhone = /^\+?\d+?[0-9]*$/;
      return regexPhone.test(phoneValue);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = event => {
    event.persist();
    setPayer({
      ...payer,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const submitPayer = { ...payer };
    delete submitPayer.sameContact;
    handleSubmitForm(submitPayer);
  };

  const handleCheckBoxContact = checked => {
    const {
      confirmationContactName,
      confirmationContactPhone,
      confirmationContactEmail,
    } = payer;

    setPayer({
      ...payer,
      sameContact: checked,
      payerContactName: checked ? confirmationContactName : '',
      payerContactPhone: checked ? confirmationContactPhone : '',
      payerContactEmail: checked ? confirmationContactEmail : '',
    });
  };

  const getOptionLabel = option => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.name;
  };

  const handleAutocompleteChange = value => {
    if (typeof value === 'string') {
      setPayer({
        ...payer,
        economicGroup: { id: 0, name: value },
      });

      return;
    }
    setPayer({
      ...payer,
      economicGroup: value || null,
    });
  };

  const xOR = (name, email, phone) => {
    if (!(name && email && phone) && !(!name && !email && !phone)) {
      return true;
    }
    return false;
  };

  const isntEmptyArgumentsForContactConfirm = xOR(
    confirmationContactName,
    confirmationContactPhone,
    confirmationContactEmail
  );

  const isntEmptyArgumentsForContactsPay = xOR(
    payerContactName,
    payerContactEmail,
    payerContactPhone
  );

  return (
    <BaseDialog
      title="Nuevo pagador"
      isOpen={open}
      handleClose={handleCloseDialog}
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          fullWidth
          variant="outlined"
          label="Nombre pagador"
          type="string"
          name="name"
          aria-describedby="Nombre pagador"
          value={name}
          onChange={handleInputChange}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
          type="string"
          name="identifier"
          aria-describedby="Rut pagador"
          value={identifier}
          onChange={handleInputChange}
          validators={['required', 'validateIdentifier']}
          errorMessages={[
            'Campo requerido',
            t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
          ]}
        />
        <Autocomplete
          id="combo-box-subrubro"
          options={taxSubSectors}
          defaultValue={taxSubSectors[taxSubSectors.length - 1]}
          getOptionLabel={option => option.name}
          onChange={(event, value) => {
            setPayer({ ...payer, taxSubSectorId: value ? value.id : null });
          }}
          renderInput={params => (
            <TextValidator
              variant="outlined"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Subrubro"
              placeholder="Subrubro"
              type="string"
              name="taxSubSectorId"
              value={taxSubSectorId}
              aria-describedby="Subrubro"
              validators={['required']}
              errorMessages={['Campo requerido']}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          id="combo-box-grupo"
          options={economicGroups}
          freeSolo
          autoSelect
          getOptionLabel={option => getOptionLabel(option)}
          onChange={(event, value) => handleAutocompleteChange(value)}
          renderInput={params => (
            <TextValidator
              variant="outlined"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Grupo Económico"
              placeholder="Grupo Económico"
              type="string"
              name="economicGroup"
              aria-describedby="EconomicGroup"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          className={classes.titleSpacing}
        >
          <Box fontWeight="fontWeightBold">Contacto de Confirmación</Box>
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Nombre"
              type="string"
              name="confirmationContactName"
              aria-describedby="Nombre confirmacion"
              value={confirmationContactName}
              onChange={handleInputChange}
              validators={
                isntEmptyArgumentsForContactConfirm ? ['required'] : []
              }
              errorMessages={
                isntEmptyArgumentsForContactConfirm ? ['Campo requerido'] : []
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              id="confirmationContactPhone"
              name="confirmationContactPhone"
              type="text"
              variant="outlined"
              value={confirmationContactPhone}
              label="Número de contacto"
              validators={
                isntEmptyArgumentsForContactConfirm
                  ? [
                      'required',
                      'phone with simbol or nah',
                      'maxStringLength:16',
                      'minStringLength:9',
                    ]
                  : []
              }
              errorMessages={
                isntEmptyArgumentsForContactConfirm
                  ? [
                      'Campo requerido',
                      'Teléfono incorrecto',
                      'Ingrese un teléfono de 16 dígitos o menos',
                      'Ingrese un teléfono de 10 dígitos o más',
                    ]
                  : []
              }
              onChange={handleInputChange}
              InputProps={{
                startAdornment: t(t13s.LABEL.PHONE_NUMBER_COUNTRY_CODE),
              }}
            />
          </Grid>
        </Grid>
        <TextValidator
          fullWidth
          variant="outlined"
          label="Email"
          type="string"
          name="confirmationContactEmail"
          aria-describedby="Email confirmacion"
          value={confirmationContactEmail}
          onChange={handleInputChange}
          validators={
            isntEmptyArgumentsForContactConfirm
              ? ['required', 'emailValidatorWithAllCases']
              : []
          }
          errorMessages={
            isntEmptyArgumentsForContactConfirm
              ? ['Campo requerido', 'Email incorrecto']
              : []
          }
        />
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          className={classes.titleSpacing}
        >
          <Box fontWeight="fontWeightBold">Contacto de Pago</Box>
        </Typography>
        <CheckboxValidator
          checked={sameContact}
          color="primary"
          onChange={e => {
            handleCheckBoxContact(e.target.checked);
          }}
          label="Mismo contacto"
        />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Nombre"
              type="string"
              name="payerContactName"
              aria-describedby="Nombre pago"
              value={payerContactName}
              onChange={handleInputChange}
              validators={isntEmptyArgumentsForContactsPay ? ['required'] : []}
              errorMessages={
                isntEmptyArgumentsForContactsPay ? ['Campo requerido'] : []
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              id="payerContactPhone"
              name="payerContactPhone"
              type="text"
              variant="outlined"
              value={payerContactPhone}
              label="Número de contacto"
              validators={
                isntEmptyArgumentsForContactsPay
                  ? [
                      'required',
                      'phone with simbol or nah',
                      'maxStringLength:16',
                      'minStringLength:9',
                    ]
                  : []
              }
              errorMessages={
                isntEmptyArgumentsForContactsPay
                  ? [
                      'Campo requerido',
                      'Teléfono incorrecto',
                      'Ingrese un teléfono de 16 dígitos o menos',
                      'Ingrese un teléfono de 10 dígitos o más',
                    ]
                  : []
              }
              onChange={handleInputChange}
              InputProps={{
                startAdornment: t(t13s.LABEL.PHONE_NUMBER_COUNTRY_CODE),
              }}
            />
          </Grid>
        </Grid>
        <TextValidator
          fullWidth
          variant="outlined"
          label="Email"
          type="string"
          name="payerContactEmail"
          aria-describedby="Email pago"
          value={payerContactEmail}
          onChange={handleInputChange}
          validators={
            isntEmptyArgumentsForContactsPay
              ? ['required', 'emailValidatorWithAllCases']
              : []
          }
          errorMessages={
            isntEmptyArgumentsForContactsPay
              ? ['Campo requerido', 'Email incorrecto']
              : []
          }
        />

        {error && (
          <AlertForm message="Ocurrió un error al guardar" variant="error" />
        )}

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          className={classes.dialogButton}
          isLoading={saving}
          fullWidth
        >
          Guardar
        </LoadingButton>
      </ValidatorForm>
    </BaseDialog>
  );
};

PayerFormDialog.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  taxSubSectors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PayerFormDialog;
