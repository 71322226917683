import { Pagination } from './global';

export enum DebtManagementTypes {
  PRE_EXPIRATION = 'PRE_EXPIRATION',
  FOLLOW_UP = 'FOLLOW_UP',
  DEBT_PORTAL = 'DEBT_PORTAL',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  INVOICE_PROBLEMS = 'INVOICE_PROBLEMS',
  INTERNAL = 'INTERNAL',
  POSSIBLE_FRAUD = 'POSSIBLE_FRAUD',
  REASSIGNMENT = 'REASSIGNMENT',
}

export enum DebtManagementSubTypes {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  PHONE_CALL = 'PHONE_CALL',
  VISIT = 'VISIT',
  MEETING = 'MEETING',
  BURO = 'BURO',
  COBRANZA_ONLINE = 'COBRANZA_ONLINE',
  DICOM = 'DICOM',
  SLACK_SII = 'SLACK_SII',
  COLLECTIONS_CHILE = 'COLLECTIONS_CHILE',
  COLLECTIONS_MEXICO = 'COLLECTIONS_MEXICO',
  NORMALIZATION = 'NORMALIZATION',
  JUDICIAL_ACTION = 'JUDICIAL_ACTION',
  EXTERNAL_COLLECTION = 'EXTERNAL_COLLECTION',
  CONCILIATION = 'CONCILIATION',
}

export enum DebtManagementResults {
  ONLY_SENT = 'ONLY_SENT',
  WITH_CONTACT = 'WITH_CONTACT',
  CONTACT_WITH_OTHERS = 'CONTACT_WITH_OTHERS',
  NO_CONTACT = 'NO_CONTACT',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  POSTED = 'POSTED',
  UNPOSTED = 'UNPOSTED',
  TO_POST = 'TO_POST',
  IN_PROCESS = 'IN_PROCESS',
  COMPLIANCE = 'COMPLIANCE',
  NO_COMPLIANCE = 'NO_COMPLIANCE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  ANULATION = 'ANULATION',
  CLAIMED = 'CLAIMED',
  DISCOUNT = 'DISCOUNT',
  PAYED_TO_STAKEHOLDER = 'PAYED_TO_STAKEHOLDER',
  SOLVED = 'SOLVED',
  TO_BE_SENT = 'TO_BE_SENT',
  SEND_AND_KEEP = 'SEND_AND_KEEP',
  SEND_AND_TAKE = 'SEND_AND_TAKE',
}

export enum PayerCategorizationTypes {
  CONTACTABILITY = 'CONTACTABILITY',
  PAYMENT_INTENTION = 'PAYMENT_INTENTION',
  PAYMENT_CAPACITY = 'PAYMENT_CAPACITY',
  RECAP = 'RECAP',
}

export enum PayerCategorizationResults {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  UNKNOWN = 'UNKNOWN',
}

export enum CollectionsSourceTypes {
  orderInvoiceId = 'orderInvoiceId',
  // TO DO: COB-445 fix collectionPayerIdentifier with full name when sourceType length is fixed
  collectionPayerIdentifier = 'collectionPayerIdent',
}

export type Management = {
  id?: string;
  sourceType: CollectionsSourceTypes;
  sourceId: string;
  appliedOn?: string;
  type: DebtManagementTypes | PayerCategorizationTypes;
  subType?: DebtManagementSubTypes;
  result?: DebtManagementResults | PayerCategorizationResults;
  detail?: string;
  createdBy?: string;
  country?: 'CL' | 'MX';
  createdAt: Date;
};

export type ManagementsFetchedData = {
  data: Management[];
  pagination: Pagination;
};

export enum GetManagementsFilters {
  debtManagement = 'debtManagement',
  payerCategorization = 'payerCategorization',
}
