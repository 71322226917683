import React, { FC, useCallback, useEffect } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { OperationList } from '../../interfaces/ARInterfaces';
import BaseDrawer from '../BaseDrawer';
import useRequirements from '../RequirementsTab/hooks/useRequirements';
import { RequirementData } from '../RequirementsTab/interfaces';
import useFetchRequirements from '../RequirementsTab/infrastructure/store/useFetchRequirements';
import {
  MainContainer,
  RequirementContainerTitle,
  RequirementDataRow,
  RequirementDataRowText,
  RequirementDataTitle,
  RequirementStatus,
  RequirementTitle,
} from './styles';
import RequirementSkeleton from './ui/RequirementSkeleton';
import ReadMoreIcon from '../../icons/ReadMoreIcon';
import useVerifyRequirements from '../RequirementsTab/infrastructure/store/useVerifyRequirements';
import { useQueryClient } from 'react-query';

interface Props {
  isOpen: boolean;
  operation: OperationList;
  handleCloseDrawer: () => void;
  handleOpenInvoicePendingRequirementsDrawer: (
    requirement: RequirementData
  ) => void;
}

const RequirementsDrawer: FC<Props> = ({
  isOpen,
  operation,
  handleCloseDrawer,
  handleOpenInvoicePendingRequirementsDrawer,
}) => {
  const queryClient = useQueryClient();
  const {
    sectionData,
    handleGetRequirementIcon,
    handleGetRequirementData,
    handleGetCountRequirements,
    handleGetLinearProgressMulticolor,
  } = useRequirements();

  const {
    isLoading: isLoadingVerifyRequirement,
    verifyRequirements,
    isSuccess,
  } = useVerifyRequirements();
  const { isLoading: IsLoadingFetchRequirement, requirements } =
    useFetchRequirements(operation.id, {
      enabled: isSuccess,
    });

  const { totalRequirements, successRequirements } = handleGetCountRequirements(
    {
      section: 'all',
      rawRequirementData: requirements,
      operationType: operation.type,
      operationStatus: operation.status,
    }
  );

  useEffect(() => {
    verifyRequirements({
      operationId: operation.id,
    });
  }, [verifyRequirements, operation.id]);

  const handleCloseRequirementsDrawer = useCallback(() => {
    handleCloseDrawer();
    queryClient.invalidateQueries('getOperations', { exact: false });
  }, [handleCloseDrawer, queryClient]);

  return (
    <BaseDrawer
      title="Requisitos"
      anchor="right"
      isOpen={isOpen}
      handleCloseDrawer={handleCloseRequirementsDrawer}
    >
      <MainContainer>
        <RequirementSkeleton
          isLoading={IsLoadingFetchRequirement || isLoadingVerifyRequirement}
        >
          <RequirementContainerTitle>
            <RequirementTitle>Requisitos completos</RequirementTitle>
            <RequirementStatus>{`${successRequirements}/${totalRequirements}`}</RequirementStatus>
          </RequirementContainerTitle>

          <Box mb={5} mt={1}>
            {handleGetLinearProgressMulticolor(
              successRequirements,
              totalRequirements
            )}
          </Box>

          <>
            {sectionData.map(section => {
              const requirementData =
                handleGetRequirementData({
                  section: section.key,
                  rawRequirementData: requirements,
                  operationType: operation.type,
                  operationStatus: operation.status,
                }) || [];
              if (!requirementData.length) return null;
              return (
                <Box key={section.key} mb={5}>
                  <RequirementDataTitle>{section.label}</RequirementDataTitle>
                  {requirementData.map((requirementData: RequirementData) => (
                    <RequirementDataRow>
                      <Box display="flex">
                        {handleGetRequirementIcon(
                          requirementData.keyValue,
                          requirementData.status
                        )}
                        <RequirementDataRowText>
                          {requirementData.label}
                        </RequirementDataRowText>
                      </Box>
                      {requirementData.showDetail && (
                        <IconButton
                          onClick={() => {
                            handleCloseDrawer();
                            handleOpenInvoicePendingRequirementsDrawer(
                              requirementData
                            );
                          }}
                        >
                          <ReadMoreIcon color="#63606D" />
                        </IconButton>
                      )}
                    </RequirementDataRow>
                  ))}
                </Box>
              );
            })}
          </>
        </RequirementSkeleton>
      </MainContainer>
    </BaseDrawer>
  );
};

export default RequirementsDrawer;
