import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const deleteOrderInvoicePdfService = async (invoiceId, pdfId) => {
  try {
    return await axios.delete(`/api/orderinvoice/${invoiceId}/pdfinvoice`, {
      params: { pdfId },
    });
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { deleteOrderInvoicePdfService };
