import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../../../components/elements/CheckButton';
import { setHourString } from '../../../../../../helpers/DateUtils';
import { DatePicker } from '../../../../../commons/components/DatePicker';

export const BulkUpdateSelectionDialog = ({
  isOpen,
  handleCloseDialog,
  title,
  description,
  handleSubmit,
  isLoading,
}) => {
  const [inputValues, setInputValues] = useState({
    days: '',
    rate: '',
    debt: '',
    advancePercentage: '',
  });
  const [checked, setChecked] = useState(false);

  const handleInputDateChange = value => {
    const date = setHourString(value);

    setInputValues({
      ...inputValues,
      days: date,
    });
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={() => handleSubmit(inputValues)}>
          <DatePicker
            onChange={dateForm => handleInputDateChange(dateForm.inDate)}
          />
          <Divider style={{ margin: 'auto auto 15px' }} />

          <TextValidator
            variant="outlined"
            fullWidth
            label="Tasa Fondo (%)"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="rate"
            defaultValue={inputValues.rate}
            aria-describedby="Tasa fondo"
            onChange={event =>
              setInputValues({
                ...inputValues,
                rate:
                  event.target.value !== '' ? Number(event.target.value) : '',
              })
            }
          />
          <Divider style={{ margin: 'auto auto 15px' }} />

          <TextValidator
            variant="outlined"
            fullWidth
            label="Dicom ($)"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="debt"
            defaultValue={inputValues.debt}
            aria-describedby="Dicom"
            onChange={event =>
              setInputValues({
                ...inputValues,
                debt:
                  event.target.value !== '' ? Number(event.target.value) : '',
              })
            }
            validators={['isPositive']}
          />
          <Divider style={{ margin: 'auto auto 15px' }} />

          <TextValidator
            variant="outlined"
            fullWidth
            label="Anticipo (%)"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="advancePercentage"
            defaultValue={inputValues.advancePercentage}
            aria-describedby="Anticipo en porcentaje"
            onChange={event =>
              setInputValues({
                ...inputValues,
                advancePercentage:
                  event.target.value !== '' ? Number(event.target.value) : '',
              })
            }
            validators={['isPositive']}
          />
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

BulkUpdateSelectionDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  description: '',
};

BulkUpdateSelectionDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
