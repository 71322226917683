import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useCallback } from 'react';

import * as selects from './payroll.selectors';
import * as payrollActions from './payroll.actions';

export const usePayrollSelector = () =>
  useSelector(selects.getPayrollSelector, shallowEqual);

export const useDraftPayrollSelector = () =>
  useSelector(selects.getDraftPayrollSelector, shallowEqual);

export const useGetDraftReceivers = () =>
  useSelector(selects.getDraftReceivers, shallowEqual);

export const useGetSelectedFund = () =>
  useSelector(selects.getSelectedFund, shallowEqual);

export const useGetSimulation = () =>
  useSelector(selects.getSimulation, shallowEqual);

export const usePayrollActions = () => {
  const dispatch = useDispatch();

  const pickReceiver = useCallback(
    newState => payrollActions.pickReceiver(newState)(dispatch),
    [dispatch]
  );
  const deleteReceivers = useCallback(
    newState => payrollActions.deleteReceivers(newState)(dispatch),
    [dispatch]
  );
  const updatePayrollSelector = useCallback(
    newState => payrollActions.updatePayrollSelector(newState)(dispatch),
    [dispatch]
  );
  const cleanPayrollSelector = useCallback(
    () => payrollActions.cleanPayrollSelector()(dispatch),
    [dispatch]
  );
  const pickDraftReceiver = useCallback(
    newState => payrollActions.pickDraftReceiver(newState)(dispatch),
    [dispatch]
  );
  const deleteDraftReceivers = useCallback(
    newState => payrollActions.deleteDraftReceivers(newState)(dispatch),
    [dispatch]
  );
  const cleanDraftPayrollSelector = useCallback(
    () => payrollActions.cleanDraftPayrollSelector()(dispatch),
    [dispatch]
  );
  const selectFund = useCallback(
    fund => payrollActions.selectFund(fund)(dispatch),
    [dispatch]
  );
  const startSimulation = useCallback(
    () => payrollActions.startSimulation()(dispatch),
    [dispatch]
  );
  const setSimulation = useCallback(
    fund => payrollActions.setSimulation(fund)(dispatch),
    [dispatch]
  );
  const cleanSimulation = useCallback(
    () => payrollActions.cleanSimulation()(dispatch),
    [dispatch]
  );
  const removeSelectedFund = useCallback(
    () => payrollActions.removeSelectedFund()(dispatch),
    [dispatch]
  );

  return {
    cleanDraftPayrollSelector,
    cleanPayrollSelector,
    cleanSimulation,
    pickDraftReceiver,
    pickReceiver,
    deleteDraftReceivers,
    deleteReceivers,
    removeSelectedFund,
    selectFund,
    setSimulation,
    startSimulation,
    updatePayrollSelector,
  };
};
