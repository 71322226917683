import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchBillDocuments = async (orderId, businessId) => {
  try {
    return await axios.get(
      // LEGACY ENDPOINT. MOVE THIS TO PAYMENTS DOMAIN IN XEPELIN-SERVER-GLOBAL
      `/api/orders/${businessId}/order/${orderId}/bill`
    );
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchBillDocuments };
