import {
  FETCH_PAYERS_START,
  FETCH_PAYERS_SUCCESS,
  FETCH_PAYERS_FAILURE,
  FETCH_INFO_PAYERS_TO_CSV_START,
  FETCH_INFO_PAYERS_TO_CSV_SUCCESS,
  FETCH_INFO_PAYERS_TO_CSV_FAILURE,
  REGISTER_PAYER_START,
  REGISTER_PAYER_SUCCESS,
  REGISTER_PAYER_FAILURE,
  RESET_PAYER_FORM_DIALOG,
  RESET_PAYER_ERROR,
  LOAD_PAYERS_CSV_START,
  LOAD_PAYERS_CSV_SUCCESS,
  LOAD_PAYERS_CSV_FAILURE,
  RESET_BULK_CSV_PAYERS_DIALOG,
  RESET_PAYER_CSV_DATA,
} from '../actions/types';

const initialState = {
  fetchingPayers: false,
  payers: [],
  payersToCSV: [],
  fetchingPayersToCsv: false,
  infoPayerstoCsvWasFetched: false,
  payersError: '',
  savingPayer: false,
  savedPayer: false,
  selectedPayer: {},
  payerError: '',
  loadedPayerCsv: false,
  savingCsv: false,
  payerCsvError: null,
  invalidPayers: [],
  pagination: {},
};

const resetBulkCsvPayerDialog = state => {
  return {
    ...state,
    payerCsvError: null,
  };
};

const fetchPayersStart = state => {
  return {
    ...state,
    fetchingPayers: true,
  };
};

const fetchPayersSuccess = (state, payload) => {
  return {
    ...state,
    payers: payload.payers,
    fetchingPayers: false,
    pagination: {
      maxRecords: payload.maxRecords,
      page: payload.page,
      limit: payload.limit,
    },
  };
};

const fetchPayersFailure = (state, payload) => {
  return {
    ...state,
    payersError: payload.errorCode,
    fetchingPayers: false,
    payers: initialState.payers,
  };
};

const fetchInfoPayersToCsvStart = state => {
  return {
    ...state,
    infoPayerstoCsvWasFetched: false,
    fetchingPayersToCsv: true,
  };
};

const fetchInfoPayersToCsvSuccess = (state, payload) => {
  const arrayPayers = payload?.payersToCSV ?? payload;
  return {
    ...state,
    fetchingPayersToCsv: false,
    infoPayerstoCsvWasFetched: true,
    payersToCSV: arrayPayers,
  };
};

const fetchInfoPayersToCsvFailure = state => {
  return {
    ...state,
    fetchingPayersToCsv: false,
  };
};

const registerPayerStart = state => {
  return {
    ...state,
    savingPayer: true,
    savedPayer: false,
  };
};

const registerPayerSuccess = (state, payload) => {
  const newPayer = payload.payer || payload;
  return {
    ...state,
    savingPayer: false,
    savedPayer: true,
    payers: [newPayer, ...state.payers],
  };
};

const registerPayerFailure = (state, payload) => {
  return {
    ...state,
    savingPayer: false,
    savedPayer: false,
    payerError: payload.errorCode,
  };
};

const loadPayersCsvStart = state => {
  return {
    ...state,
    loadedPayerCsv: false,
    savingCsv: true,
    fetchingPayers: true,
    payerCsvError: null,
    invalidPayers: [],
  };
};

const loadPayersCsvSuccess = (state, payload) => {
  const { payers = [], invalidPayers = [] } = payload;
  return {
    ...state,
    loadedPayerCsv: true,
    savingCsv: false,
    fetchingPayers: false,
    payers,
    invalidPayers,
  };
};

const loadPayersCsvFailure = (state, payload) => {
  const { invalidPayers = [] } = payload;
  return {
    ...state,
    loadedPayerCsv: false,
    savingCsv: false,
    fetchingPayers: false,
    payerCsvError: payload.errorCode,
    invalidPayers,
  };
};

const resetPayerFormDialog = state => {
  return {
    ...state,
    savingPayer: false,
    payerError: '',
  };
};

const resetPayerError = state => {
  return {
    ...state,
    payerError: '',
  };
};

const resetPayerCsvData = state => {
  return {
    ...state,
    payersToCSV: [],
    loadedPayerCsv: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYERS_START:
      return fetchPayersStart(state);
    case FETCH_PAYERS_SUCCESS:
      return fetchPayersSuccess(state, payload);
    case FETCH_PAYERS_FAILURE:
      return fetchPayersFailure(state, payload);

    case FETCH_INFO_PAYERS_TO_CSV_START:
      return fetchInfoPayersToCsvStart(state);
    case FETCH_INFO_PAYERS_TO_CSV_SUCCESS:
      return fetchInfoPayersToCsvSuccess(state, payload);
    case FETCH_INFO_PAYERS_TO_CSV_FAILURE:
      return fetchInfoPayersToCsvFailure(state);

    case REGISTER_PAYER_START:
      return registerPayerStart(state);
    case REGISTER_PAYER_SUCCESS:
      return registerPayerSuccess(state, payload);
    case REGISTER_PAYER_FAILURE:
      return registerPayerFailure(state, payload);

    case LOAD_PAYERS_CSV_START:
      return loadPayersCsvStart(state);
    case LOAD_PAYERS_CSV_SUCCESS:
      return loadPayersCsvSuccess(state, payload);
    case LOAD_PAYERS_CSV_FAILURE:
      return loadPayersCsvFailure(state, payload);

    case RESET_BULK_CSV_PAYERS_DIALOG:
      return resetBulkCsvPayerDialog(state);

    case RESET_PAYER_FORM_DIALOG:
      return resetPayerFormDialog(state);
    case RESET_PAYER_ERROR:
      return resetPayerError(state);
    case RESET_PAYER_CSV_DATA:
      return resetPayerCsvData(state);

    default:
      return state;
  }
};
