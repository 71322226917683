import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import verifyRut from '../../helpers/validation/rut';
import verifyDecimals from '../../helpers/validation/decimals';
import loading from '../../assets/loading.gif';
import LoadingButton from './LoadingButton';
import {
  BUTTON_VERTICAL_STEPPER,
  SIDEBAR_NAVIGATION_BUTTON_ROOT,
} from '../../theme/otherColors';

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '15px 0',
  },
  formContainer: {
    padding: '0px 10px',
    textAlign: 'start',
  },
  stepper: {
    padding: 10,
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: theme.palette.primary.main,
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: theme.palette.primary.main,
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: SIDEBAR_NAVIGATION_BUTTON_ROOT,
      fontWeight: 900,
    },
    '& .MuiStepLabel-label': {
      color: SIDEBAR_NAVIGATION_BUTTON_ROOT,
      fontWeight: 900,
    },
  },
  containerButton: {
    display: 'flex',
    background: theme.palette.grey[100],
    borderRadius: 50,
  },
  buttonCustom: {
    color: BUTTON_VERTICAL_STEPPER,
  },
  containerBottom: {
    paddingTop: 5,
  },
}));

const VerticalStepper = ({
  steps,
  stepsContent,
  handleValidateNextStep,
  handleSubmit,
  handleNextStep,
  isLoading,
  buttonLabelFinish,
  handleStepBack,
  isLoadingButton,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    ValidatorForm.addValidationRule('isValidRut', value => verifyRut(value));
    ValidatorForm.addValidationRule('maxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  const handleNext = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    if (handleNextStep) handleNextStep(nextStep);
  };

  const handleBack = () => {
    const nextStep = activeStep - 1;
    setActiveStep(nextStep);
    if (handleStepBack) handleStepBack(nextStep);
  };

  const handleForm = () => {
    handleSubmit();
  };

  const activeNextStep = handleValidateNextStep
    ? handleValidateNextStep(activeStep)
    : true;

  return (
    <Stepper
      className={classes.stepper}
      activeStep={activeStep}
      orientation="vertical"
    >
      {steps.map((title, step) => (
        <Step key={title}>
          <StepLabel>
            <Grid container direction="row">
              {step === activeStep ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
              <Typography variant="body1">{title}</Typography>
            </Grid>
          </StepLabel>
          <StepContent>
            <Grid
              container
              direction="column"
              align="center"
              spacing={2}
              className={classes.formContainer}
            >
              <ValidatorForm
                onSubmit={
                  activeStep === steps.length - 1 ? handleForm : handleNext
                }
              >
                {stepsContent && stepsContent.length ? stepsContent[step] : ''}

                <Grid container xs={12} className={classes.actionsContainer}>
                  {isLoading && (
                    <Grid container justify="center">
                      <Grid item xs={6}>
                        <img src={loading} alt="loader" />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item className={classes.containerBottom}>
                    <Box className={activeStep > 0 && classes.containerButton}>
                      {activeStep > 0 && (
                        <Button
                          onClick={handleBack}
                          className={classes.buttonCustom}
                          disabled={isLoadingButton}
                        >
                          Atrás
                        </Button>
                      )}
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        isLoading={isLoadingButton}
                        disabled={!activeNextStep && !isLoading}
                      >
                        {activeStep === steps.length - 1
                          ? buttonLabelFinish
                          : 'Siguiente'}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

VerticalStepper.defaultProps = {
  isLoading: false,
  buttonLabelFinish: 'Guardar',
  handleStepBack: () => {},
  isLoadingButton: false,
};

VerticalStepper.propTypes = {
  steps: PropTypes.arrayOf(String).isRequired,
  stepsContent: PropTypes.arrayOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidateNextStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoadingButton: PropTypes.bool,
  buttonLabelFinish: PropTypes.string,
  handleStepBack: PropTypes.func,
};

export default VerticalStepper;
