import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Sudo = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M10.0902 15.5898L11.5007 17.0003L16.501 12L11.5007 6.99966L10.0902 8.41017L12.6702 11.0003H3V13.0007H12.6702L10.0902 15.5898ZM19.0007 3H5.00034C4.73761 2.99987 4.47744 3.05152 4.23468 3.15199C3.99193 3.25247 3.77136 3.39981 3.58559 3.58559C3.39981 3.77136 3.25247 3.99193 3.15199 4.23468C3.05152 4.47744 2.99987 4.73761 3 5.00034V9H5.00034V5.00034H19.0007V19.0007H5.00034V15H3V18.9997C2.99987 19.2624 3.05152 19.5226 3.15199 19.7653C3.25247 20.0081 3.39981 20.2286 3.58559 20.4144C3.77136 20.6002 3.99193 20.7475 4.23468 20.848C4.47744 20.9485 4.73761 21.0001 5.00034 21H19.0007C19.5304 20.9984 20.038 20.7872 20.4126 20.4126C20.7872 20.038 20.9984 19.5304 21 19.0007V5.00034C20.9987 4.4704 20.7876 3.96254 20.413 3.58773C20.0384 3.21291 19.5306 3.00161 19.0007 3Z" />
    </SvgIcon>
  );
};

export default Sudo;
