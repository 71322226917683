import {
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  REGISTER_REPORT_START,
  REGISTER_REPORT_SUCCESS,
  REGISTER_REPORT_FAILURE,
  EDIT_REPORT_START,
  EDIT_REPORT_SUCCESS,
  EDIT_REPORT_FAILURE,
  FETCH_PERFORM_START,
  FETCH_PERFORM_SUCCESS,
  FETCH_PERFORM_FAILURE,
  EXECUTE_REPORT_START,
  EXECUTE_REPORT_SUCCESS,
  EXECUTE_REPORT_FAILURE,
} from '../actions/types';

const initialState = {
  roles: [],
  performs: [],
  reportsError: {},
  loading: false,
  selectedRole: null,

  reports: [],
  fetchReportsIsLoading: false,

  pagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
  },

  updateReportIsLoading: false,
  reportWasUpdated: true,
  updateReportError: null,

  registerReportIsLoading: false,
  reportWasRegistered: false,
};

const fetchReportsStart = state => {
  return {
    ...state,
    fetchReportsIsLoading: true,
  };
};
const fetchReportsSuccess = (state, payload) => {
  const { reports, roles } = payload;

  return {
    ...state,
    reports: reports.data,
    roles,
    fetchReportsIsLoading: false,
    pagination: reports.pagination,
  };
};
const fetchReportsFailure = state => {
  return {
    ...state,
    reports: [],
    roles: [],
    fetchReportsIsLoading: false,
  };
};

const registerReportStart = state => {
  return {
    ...state,
    registerReportIsLoading: true,
    reportError: null,
    reportWasRegistered: false,
  };
};
const registerReportSuccess = (state, payload) => {
  const newReports = [...state.reports, payload];
  return {
    ...state,
    registerReportIsLoading: false,
    reports: newReports,
    reportWasRegistered: true,
  };
};
const registerReportFailure = (state, payload) => {
  return {
    ...state,
    registerReportIsLoading: false,
    reportError: payload.errorCode,
  };
};

const editReportStart = state => {
  return { ...state, updateReportIsLoading: true, reportError: null };
};
const editReportSuccess = (state, payload) => {
  const reports = state.reports.map(report => {
    if (report.id === payload.id) {
      return payload;
    }
    return report;
  });
  return { ...state, updateReportIsLoading: false, reports };
};
const editReportFailure = (state, payload) => {
  return {
    ...state,
    updateReportIsLoading: false,
    reportError: payload.errorCode,
  };
};

const fetchPerformStart = state => {
  return { ...state, loading: true };
};
const fetchPerformSuccess = (state, payload) => {
  return { ...state, performs: payload.data.performs, loading: false };
};
const fetchPerformFailure = state => {
  return { ...state, loading: false };
};

const executeReportStart = state => {
  return {
    ...state,
    registerReportIsLoading: true,
    reportError: null,
    reportWasRegistered: false,
  };
};
const executeReportSuccess = state => {
  return {
    ...state,
    registerReportIsLoading: false,
    reportWasRegistered: true,
  };
};
const executeReportFailure = (state, payload) => {
  return {
    ...state,
    registerReportIsLoading: false,
    reportError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REPORTS_START:
      return fetchReportsStart(state);
    case FETCH_REPORTS_SUCCESS:
      return fetchReportsSuccess(state, payload);
    case FETCH_REPORTS_FAILURE:
      return fetchReportsFailure(state);

    case REGISTER_REPORT_START:
      return registerReportStart(state);
    case REGISTER_REPORT_SUCCESS:
      return registerReportSuccess(state, payload);
    case REGISTER_REPORT_FAILURE:
      return registerReportFailure(state, payload);

    case EDIT_REPORT_START:
      return editReportStart(state);
    case EDIT_REPORT_SUCCESS:
      return editReportSuccess(state, payload);
    case EDIT_REPORT_FAILURE:
      return editReportFailure(state, payload);

    case FETCH_PERFORM_START:
      return fetchPerformStart(state);
    case FETCH_PERFORM_SUCCESS:
      return fetchPerformSuccess(state, payload);
    case FETCH_PERFORM_FAILURE:
      return fetchPerformFailure(state);

    case EXECUTE_REPORT_START:
      return executeReportStart(state);
    case EXECUTE_REPORT_SUCCESS:
      return executeReportSuccess(state);
    case EXECUTE_REPORT_FAILURE:
      return executeReportFailure(state, payload);

    default:
      return state;
  }
};
