import axios from 'axios';
import { UNKNOWN_ERROR_CODE } from '../../../../helpers/Constants';

const editTransferAccountsService = async (
  bankAccountData,
  accountId,
  businessId,
  originalAccount,
  orderId
) => {
  try {
    const { data } = await axios.put(
      `api/payment/order/transfer-accounts/${accountId}`,
      {
        bankAccountData,
        businessId,
        originalAccount,
        orderId,
      }
    );

    return data;
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode ?? UNKNOWN_ERROR_CODE;
    throw new Error(errorCode);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { editTransferAccountsService };
