import fetchOrderResponsibles from '../service/fetchOrderResponsibles';
import { useQuery } from 'react-query';
import { OrderResponsiblesOwner } from '../../interfaces';

interface Response {
  responsibles: OrderResponsiblesOwner[];
  isLoading: boolean;
}

const useFetchOrderResponsibles = (businessIdentifier: string): Response => {
  const { data, isLoading, isRefetching } = useQuery(
    ['fetchOrderResponsibles'],
    () => fetchOrderResponsibles(businessIdentifier),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      staleTime: 0,
    }
  );

  const responsibles: OrderResponsiblesOwner[] =
    data?.map(responsibles => responsibles?.owner) || [];

  return {
    responsibles,
    isLoading: isLoading || isRefetching,
  };
};

export default useFetchOrderResponsibles;
