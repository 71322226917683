import React, { FC } from 'react';
import StatusFilterBadge from './StatusFilterBadge';
import {
  StatusFilter,
  OperationCountTotal,
} from '../../../commons/interfaces/ARInterfaces';
import useFilters from '../../hooks/useFilters';
import useOperationTotals from '../../infrastructure/store/useOperationTotals';

interface Props {
  status: StatusFilter;
  selected: string;
}

const StatusFilterLabel: FC<Props> = ({ status, selected }) => {
  const { label, key } = status;
  const { operationFilter } = useFilters();
  const { opertationTotals } = useOperationTotals(operationFilter);

  const total =
    opertationTotals?.find((order: OperationCountTotal) => order.status === key)
      ?.total ?? '0';
  const isSelected = key === selected;

  return (
    <div>
      {label}{' '}
      <StatusFilterBadge isSelected={isSelected}>{total}</StatusFilterBadge>
    </div>
  );
};

export default StatusFilterLabel;
