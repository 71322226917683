import { useQuery } from 'react-query';

import { fetchBusinessCriticalStatusInfo } from '../services';

const useBusinessCriticalStatusInfo = businessId => {
  const { isIdle, isLoading, data, refetch } = useQuery(
    'getBusinessCriticalInfo',
    () => fetchBusinessCriticalStatusInfo(businessId),
    { refetchOnWindowFocus: false, enabled: !!businessId }
  );

  const { data: criticalStatusInfo } = data || { data: '' };

  return {
    criticalStatusInfo,
    criticalStatusInfoIsLoading: isLoading || isIdle,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useBusinessCriticalStatusInfo };
