import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, styled } from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';

const CustomGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.payments.error.main,
  background: theme.palette.payments.error.light,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

const AlertMessage = ({ messageComponent, onClear }) => {
  return (
    <CustomGrid container direction="row">
      <Grid item xs={1}>
        <Cancel color="inherit" />
      </Grid>
      <Grid container item xs={10} direction="column" alignContent="flex-start">
        {messageComponent}
      </Grid>
      {onClear && (
        <Grid item xs={1}>
          <IconButton fontSize="small" onClick={onClear} color="inherit">
            <Close />
          </IconButton>
        </Grid>
      )}
    </CustomGrid>
  );
};

AlertMessage.defaultProps = {
  onClear: null,
};

AlertMessage.propTypes = {
  messageComponent: PropTypes.element.isRequired,
  onClear: PropTypes.func,
};

export default AlertMessage;
