import { Box, styled, Typography, LinearProgress } from '@material-ui/core';

export const ContainerRoot = styled(Box)({
  padding: '25px 30px',
});

export const RequirementContainerTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RequirementTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 14,
  color: '#8D8A96',
});

export const RequirementStatus = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 14,
  color: '#44414C',
});

export const RequirementContainerData = styled(Box)({
  width: 400,
  marginRight: 55,
  '&:last-Child': {
    marginRight: 0,
  },
});

export const RequirementDataTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  color: '#312F37',
  marginRight: 10,
});

export const RequirementDataRow = styled(Box)({
  minHeight: 33,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px 0 5px',
  borderBottom: '1px solid #CDCAD8',
  '&:last-child': {
    borderBottom: '0',
  },
});

export const RequirementDataRowText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  color: '#8D8A96',
  marginLeft: 15,
});

export const LinearProgressDefault = styled(LinearProgress)({
  '&.MuiLinearProgress-root': {
    height: 6,
    borderRadius: 20,
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#D3E4FA',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#478FEB',
  },
});

export const LinearProgressFull = styled(LinearProgress)({
  '&.MuiLinearProgress-root': {
    height: 6,
    borderRadius: 20,
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#DCF0B9',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#90C633',
  },
});

export const LinearProgressMiddle = styled(LinearProgress)({
  '&.MuiLinearProgress-root': {
    height: 6,
    borderRadius: 20,
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#FAE9C2',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#FFB100',
  },
});

export const LinearProgressEmpty = styled(LinearProgress)({
  '&.MuiLinearProgress-root': {
    height: 6,
    borderRadius: 20,
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#F3D2D0',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#E0584F',
  },
});