import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  DOWNLOAD_DOCUMENT_START,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_SIGNED_DOCUMENT_START,
  UPDATE_SIGNED_DOCUMENT_SUCCESS,
  UPDATE_SIGNED_DOCUMENT_FAILURE,
  CREATE_DOCUMENT_START,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILURE,
  APPROVE_REJECT_DOCUMENT_START,
  APPROVE_REJECT_DOCUMENT_SUCCESS,
  APPROVE_REJECT_DOCUMENT_FAILURE,
  CANCEL_MIFIEL_DOCUMENT_START,
  CANCEL_MIFIEL_DOCUMENT_SUCCESS,
  CANCEL_MIFIEL_DOCUMENT_FAILURE,
  RESET_DOCUMENT_ERRORS,
  VERIFY_DOCUMENTS_START,
  VERIFY_DOCUMENTS_SUCCESS,
  VERIFY_DOCUMENTS_FAILURE,
  VERIFY_DOCUMENTS_RESET,
  LOAD_DOCUMENTS_START,
  LOAD_DOCUMENTS_SUCCESS,
  LOAD_DOCUMENTS_FAILURE,
  FETCH_DOCUMENT_BASE64_START,
  FETCH_DOCUMENT_BASE64_SUCCESS,
  FETCH_DOCUMENT_BASE64_FAILURE,
  FETCH_DOCUMENTS_TYPES_START,
  FETCH_DOCUMENTS_TYPES_SUCCESS,
  FETCH_DOCUMENTS_TYPES_FAILURE,
  UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_START,
  UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_SUCCESS,
  UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_FAILURE,
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_START,
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_SUCCESS,
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE,
} from '../actions/types';

const initialState = {
  businessIdDocumentsOwner: null,
  businessDocuments: [],
  businessDocumentsIsLoading: false,
  businessDocumentsError: null,

  stakeholderIdDocumentsOwner: null,
  stakeholderDocuments: [],
  stakeholderDocumentsIsLoading: false,
  stakeholderDocumentsError: null,

  orderIdDocumentsOwner: null,
  orderDocuments: [],
  orderDocumentsIsLoading: false,
  orderDocumentsError: null,

  creditDocuments: [],
  creditDocumentsIsLoading: false,
  creditDocumentsError: null,

  stakeholderVerifyDocuments: {},

  businessVerifyDocuments: null,
  businessVerifyDocumentsLoading: false,
  businessVerifyDocumentsError: null,

  orderVerifyDocuments: null,
  orderVerifyDocumentsLoading: false,
  orderVerifyDocumentsError: null,

  documentWasDownload: false,
  documentDownloadKey: null,
  downloadDocumentsIsLoading: false,
  downloadDocumentsError: null,

  fileDocumentWasUpdate: false,
  updateFileDocumentsIsLoading: false,
  updateFileDocumentsError: null,
  updateFileDocumentId: null,

  updateElectronicSignatureDocumentIsLoading: false,
  electronicSignatureDocumentWasUpdate: false,
  updateElectronicSignatureDocumentError: null,

  signedfileDocumentWasUpdate: false,
  updateSignedFileDocumentIsLoading: false,
  updateSignedFileDocumentError: null,

  documentWasCreate: false,
  createDocumentIsLoading: false,
  createDocumentError: null,

  documentWasApproveReject: false,
  approveRejectDocumentIsLoading: false,
  approveRejectDocumentError: null,

  documentWasCancel: false,
  cancelMifielDocumentIsLoading: false,
  cancelMifielDocumentError: null,

  loadDocumentsIsLoading: false,
  loadDocumentsError: null,

  documentBase64: {},
  fetchDocumentBase64IsLoading: false,
  fetchDocumentBase64Error: null,

  documentTypes: [],
  fetchDocumentTypesIsLoading: false,

  electronicSignatureSignatories: [],
  fetchElectronicSignatureSignatoriesIsLoading: false,
};

const sortDocuments = (a, b) => {
  return a.sequence - b.sequence;
};

const fetchDocumentsStart = (state, payload) => {
  const { documentableType } = payload;
  return {
    ...state,
    [`${documentableType}Documents`]: [],
    [`${documentableType}DocumentsIsLoading`]: true,
    [`${documentableType}DocumentsError`]: null,
    [`${documentableType}IdDocumentsOwner`]: payload.documentableId,
  };
};

const fetchDocumentsSuccess = (state, payload) => {
  const { documentableType } = payload;
  const documents = payload.documents.sort((a, b) => sortDocuments(a, b));
  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    [`${documentableType}DocumentsIsLoading`]: false,
  };
};

const fetchDocumentsFailure = (state, payload) => {
  const { documentableType } = payload;
  return {
    ...state,
    [`${documentableType}DocumentsIsLoading`]: false,
    [`${documentableType}DocumentsError`]: payload.errorCode,
  };
};

const verifyDocumentsStart = (state, payload) => {
  const { documentableType, documentableId } = payload;
  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: true,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    case 'stakeholder': {
      const verifiedStakeholder = {
        ...state.stakeholderVerifyDocuments,
        [documentableId]: {
          verifyIsLoading: true,
          verifyError: null,
        },
      };
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: verifiedStakeholder,
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: true,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    default:
      return state;
  }
};

const verifyDocumentsSuccess = (state, payload) => {
  const { documentableType, allDocumentsCompleted, documentableId } = payload;

  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: allDocumentsCompleted,
        [`${documentableType}VerifyDocumentsLoading`]: false,
      };
    case 'stakeholder': {
      const verifiedStakeholder = {
        ...state.stakeholderVerifyDocuments,
        [documentableId]: {
          verifyIsLoading: false,
          verifyError: null,
          allDocumentsCompleted,
        },
      };
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: verifiedStakeholder,
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: allDocumentsCompleted,
        [`${documentableType}VerifyDocumentsLoading`]: false,
      };
    default:
      return state;
  }
};

const verifyDocumentsFailure = (state, payload) => {
  const { documentableType, documentableId, error } = payload;

  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: error,
      };
    case 'stakeholder': {
      const errorStakeholder = {
        ...state.stakeholderVerifyDocuments,
        [documentableId]: {
          verifyIsLoading: false,
          verifyError: error,
        },
      };
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: errorStakeholder,
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: error,
      };
    default:
      return state;
  }
};

const verifyDocumentsReset = (state, payload) => {
  const { documentableType } = payload;
  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: null,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    case 'stakeholder': {
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: {},
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: null,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    default:
      return state;
  }
};

const downloadDocumentStart = (state, payload) => {
  return {
    ...state,
    documentWasDownload: false,
    documentDownloadKey: payload.documentKey,
    downloadDocumentsIsLoading: true,
    downloadDocumentsError: null,
  };
};

const downloadDocumentSuccess = state => {
  return {
    ...state,
    downloadDocumentsIsLoading: false,
    documentDownloadKey: null,
    documentWasDownload: true,
  };
};

const downloadDocumentFailure = (state, payload) => {
  return {
    ...state,
    downloadDocumentsIsLoading: false,
    downloadDocumentsError: payload.errorCode,
    documentDownloadKey: null,
    documentWasDownload: false,
  };
};

const updateDocumentStart = (state, payload) => {
  return {
    ...state,
    updateFileDocumentsIsLoading: true,
    fileDocumentWasUpdate: false,
    updateFileDocumentsError: null,
    updateFileDocumentId: payload.documentId,
  };
};

const updateDocumentSuccess = (state, payload) => {
  const { documentableType, updatedDocument } = payload;

  const documents = state[`${documentableType}Documents`]
    .map(doc => (doc.id !== updatedDocument.id ? doc : updatedDocument))
    .sort((a, b) => sortDocuments(a, b));

  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    updateFileDocumentsIsLoading: false,
    fileDocumentWasUpdate: true,
    updateFileDocumentId: updatedDocument.id,
  };
};

const updateDocumentFailure = (state, payload) => {
  return {
    ...state,
    updateFileDocumentsIsLoading: false,
    updateFileDocumentsError: payload.errorCode,
  };
};

const updateSignedDocumentStart = state => {
  return {
    ...state,
    updateSignedFileDocumentIsLoading: true,
    signedfileDocumentWasUpdate: false,
    updateSignedFileDocumentError: null,
  };
};

const updateSignedDocumentSuccess = (state, payload) => {
  const { documentableType, updatedDocument } = payload;

  const documents = state[`${documentableType}Documents`]
    .map(doc => (doc.id !== updatedDocument.id ? doc : updatedDocument))
    .sort((a, b) => sortDocuments(a, b));

  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    updateSignedFileDocumentIsLoading: false,
    signedfileDocumentWasUpdate: true,
  };
};

const updateSignedDocumentFailure = (state, payload) => {
  return {
    ...state,
    updateSignedFileDocumentIsLoading: false,
    updateSignedFileDocumentError: payload.errorCode,
  };
};

const createDocumentStart = state => {
  return {
    ...state,
    createDocumentIsLoading: true,
    documentWasCreate: false,
    createDocumentError: null,
  };
};

const createDocumentSuccess = (state, payload) => {
  const { documentableType, newDocument } = payload;
  const documents = [...state[`${documentableType}Documents`], newDocument];

  return {
    ...state,
    [`${documentableType}Documents`]: documents.sort((a, b) =>
      sortDocuments(a, b)
    ),
    createDocumentIsLoading: false,
    documentWasCreate: true,
  };
};

const createDocumentFailure = (state, payload) => {
  return {
    ...state,
    createDocumentIsLoading: false,
    createDocumentError: payload.errorCode,
  };
};

const approveRejectDocumentStart = state => {
  return {
    ...state,
    approveRejectDocumentIsLoading: true,
    documentWasApproveReject: false,
    approveRejectDocumentError: null,
  };
};

const approveRejectDocumentSuccess = (state, payload) => {
  const { documentableType, updatedDocument } = payload;

  const documents = state[`${documentableType}Documents`]
    .map(doc => (doc.id !== updatedDocument.id ? doc : updatedDocument))
    .sort((a, b) => sortDocuments(a, b));

  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    approveRejectDocumentIsLoading: false,
    documentWasApproveReject: true,
  };
};

const approveRejectDocumentFailure = (state, payload) => {
  return {
    ...state,
    approveRejectDocumentIsLoading: false,
    approveRejectDocumentError: payload.errorCode,
  };
};

const cancelMifielDocumentStart = state => {
  return {
    ...state,
    documentWasCancel: false,
    cancelMifielDocumentIsLoading: true,
    cancelMifielDocumentError: null,
  };
};

const cancelMifielDocumentSuccess = (state, payload) => {
  const { documentableType, documentId } = payload;

  const documents = state[`${documentableType}Documents`]
    .map(doc => {
      if (doc.id === documentId) {
        const newDoc = { ...doc };
        delete newDoc.document;
        return newDoc;
      }
      return doc;
    })
    .sort((a, b) => sortDocuments(a, b));
  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    cancelMifielDocumentIsLoading: false,
    documentWasCancel: true,
  };
};

const cancelMifielDocumentFailure = (state, payload) => {
  return {
    ...state,
    cancelMifielDocumentIsLoading: false,
    cancelMifielDocumentError: payload.errorCode,
  };
};

const resetDocumentErrors = (state, payload) => {
  const { documentableType } = payload;
  return {
    ...state,
    [`${documentableType}DocumentsError`]: null,
    downloadDocumentsError: null,
    updateFileDocumentsError: null,
    updateSignedFileDocumentError: null,
    createDocumentError: null,
    approveRejectDocumentError: null,
    cancelMifielDocumentError: null,
    updateElectronicSignatureDocumentError: null,
  };
};

const loadDocumentsStart = state => {
  return {
    ...state,
    loadDocumentsIsLoading: true,
    loadDocumentsError: null,
  };
};

const loadDocumentsSuccess = state => {
  return {
    ...state,
    loadDocumentsIsLoading: false,
  };
};

const loadDocumentsFailure = (state, payload) => {
  return {
    ...state,
    loadDocumentsIsLoading: false,
    loadDocumentsError: payload.errorCode,
  };
};

const fetchDocumentBase64Start = state => {
  return {
    ...state,
    documentBase64: null,
    fetchDocumentBase64IsLoading: true,
    fetchDocumentBase64Error: null,
  };
};

const fetchDocumentBase64Success = (state, payload) => {
  return {
    ...state,
    documentBase64: payload.file,
    fetchDocumentBase64IsLoading: false,
  };
};

const fetchDocumentBase64Failure = (state, payload) => {
  return {
    ...state,
    documentBase64: null,
    fetchDocumentBase64IsLoading: false,
    fetchDocumentBase64Error: payload.errorCode,
  };
};

const fetchDocumentTypesStart = state => {
  return {
    ...state,
    documentTypes: [],
    fetchDocumentTypesIsLoading: true,
  };
};

const fetchDocumentTypesSuccess = (state, payload) => {
  return {
    ...state,
    documentTypes: payload.documentTypes,
    fetchDocumentTypesIsLoading: false,
  };
};

const fetchDocumentTypesFailure = state => {
  return {
    ...state,
    fetchDocumentTypesIsLoading: false,
  };
};

const updateElectronicSignatureDocumentStart = state => {
  return {
    ...state,
    updateElectronicSignatureDocumentIsLoading: true,
    electronicSignatureDocumentWasUpdate: false,
    updateElectronicSignatureDocumentError: null,
  };
};

const updateElectronicSignatureDocumentSuccess = (state, payload) => {
  const { documentableType, updatedDocument } = payload;

  const documents = state[`${documentableType}Documents`]
    .map(doc => (doc.id !== updatedDocument.id ? doc : updatedDocument))
    .sort((a, b) => sortDocuments(a, b));

  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    updateElectronicSignatureDocumentIsLoading: false,
    electronicSignatureDocumentWasUpdate: true,
  };
};

const updateElectronicSignatureDocumentFailure = (state, payload) => {
  return {
    ...state,
    updateElectronicSignatureDocumentIsLoading: false,
    updateElectronicSignatureDocumentError: payload.errorCode,
  };
};

const fetchSignatoriesElectronicSignatureStart = state => {
  return {
    ...state,
    electronicSignatureSignatories: [],
    fetchElectronicSignatureSignatoriesIsLoading: true,
  };
};
const fetchSignatoriesElectronicSignatureSuccess = (state, payload) => {
  return {
    ...state,
    electronicSignatureSignatories: payload.signatories,
    fetchElectronicSignatureSignatoriesIsLoading: false,
  };
};
const fetchSignatoriesElectronicSignatureFailure = state => {
  return {
    ...state,
    fetchElectronicSignatureSignatoriesIsLoading: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOCUMENTS_START:
      return fetchDocumentsStart(state, payload);
    case FETCH_DOCUMENTS_SUCCESS:
      return fetchDocumentsSuccess(state, payload);
    case FETCH_DOCUMENTS_FAILURE:
      return fetchDocumentsFailure(state, payload);
    case VERIFY_DOCUMENTS_START:
      return verifyDocumentsStart(state, payload);
    case VERIFY_DOCUMENTS_SUCCESS:
      return verifyDocumentsSuccess(state, payload);
    case VERIFY_DOCUMENTS_FAILURE:
      return verifyDocumentsFailure(state, payload);
    case VERIFY_DOCUMENTS_RESET:
      return verifyDocumentsReset(state, payload);
    case DOWNLOAD_DOCUMENT_START:
      return downloadDocumentStart(state, payload);
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return downloadDocumentSuccess(state);
    case DOWNLOAD_DOCUMENT_FAILURE:
      return downloadDocumentFailure(state, payload);
    case UPDATE_DOCUMENT_START:
      return updateDocumentStart(state, payload);
    case UPDATE_DOCUMENT_SUCCESS:
      return updateDocumentSuccess(state, payload);
    case UPDATE_DOCUMENT_FAILURE:
      return updateDocumentFailure(state, payload);
    case UPDATE_SIGNED_DOCUMENT_START:
      return updateSignedDocumentStart(state);
    case UPDATE_SIGNED_DOCUMENT_SUCCESS:
      return updateSignedDocumentSuccess(state, payload);
    case UPDATE_SIGNED_DOCUMENT_FAILURE:
      return updateSignedDocumentFailure(state, payload);
    case CREATE_DOCUMENT_START:
      return createDocumentStart(state);
    case CREATE_DOCUMENT_SUCCESS:
      return createDocumentSuccess(state, payload);
    case CREATE_DOCUMENT_FAILURE:
      return createDocumentFailure(state, payload);
    case APPROVE_REJECT_DOCUMENT_START:
      return approveRejectDocumentStart(state);
    case APPROVE_REJECT_DOCUMENT_SUCCESS:
      return approveRejectDocumentSuccess(state, payload);
    case APPROVE_REJECT_DOCUMENT_FAILURE:
      return approveRejectDocumentFailure(state, payload);
    case CANCEL_MIFIEL_DOCUMENT_START:
      return cancelMifielDocumentStart(state);
    case CANCEL_MIFIEL_DOCUMENT_SUCCESS:
      return cancelMifielDocumentSuccess(state, payload);
    case CANCEL_MIFIEL_DOCUMENT_FAILURE:
      return cancelMifielDocumentFailure(state, payload);
    case RESET_DOCUMENT_ERRORS:
      return resetDocumentErrors(state, payload);
    case LOAD_DOCUMENTS_START:
      return loadDocumentsStart(payload);
    case LOAD_DOCUMENTS_SUCCESS:
      return loadDocumentsSuccess(payload);
    case LOAD_DOCUMENTS_FAILURE:
      return loadDocumentsFailure(state, payload);
    case FETCH_DOCUMENT_BASE64_START:
      return fetchDocumentBase64Start(state);
    case FETCH_DOCUMENT_BASE64_SUCCESS:
      return fetchDocumentBase64Success(state, payload);
    case FETCH_DOCUMENT_BASE64_FAILURE:
      return fetchDocumentBase64Failure(state, payload);
    case FETCH_DOCUMENTS_TYPES_START:
      return fetchDocumentTypesStart(state);
    case FETCH_DOCUMENTS_TYPES_SUCCESS:
      return fetchDocumentTypesSuccess(state, payload);
    case FETCH_DOCUMENTS_TYPES_FAILURE:
      return fetchDocumentTypesFailure(state);
    case UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_START:
      return updateElectronicSignatureDocumentStart(state);
    case UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_SUCCESS:
      return updateElectronicSignatureDocumentSuccess(state, payload);
    case UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_FAILURE:
      return updateElectronicSignatureDocumentFailure(state, payload);
    case FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_START:
      return fetchSignatoriesElectronicSignatureStart(state);
    case FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_SUCCESS:
      return fetchSignatoriesElectronicSignatureSuccess(state, payload);
    case FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE:
      return fetchSignatoriesElectronicSignatureFailure(state);
    default:
      return state;
  }
};
