import { DIRECT_FINANCING, EARLY_PAYMENT } from '../../Constants';
import { SEGMENT_EVENTS } from '../constants';
import {
  trackSegment,
  sleep,
  identify,
  analyticsReset,
} from '../trackingSegment';

export const trackOrderStarted = (
  businessId,
  operationType,
  selectedInvoices,
  simulation,
  availableInvoicesCount,
  availableInvoicesAmount,
  user,
  isAutoRateEnabled = false
) => {
  const selectedInvoicesCount = selectedInvoices.length;
  const selectedCartInvoicesAmount = selectedInvoices.reduce(
    (sum, invoice) => sum + invoice.amount,
    0
  );

  const {
    retentionPct,
    retention,
    operationCost: fee,
    interest,
    baseRate: rate,
  } = simulation;

  const advancePercentage = !Number.isNaN(retentionPct)
    ? 100 - retentionPct
    : null;

  const eventPayload = {
    type: operationType === DIRECT_FINANCING ? 'FD' : 'PP',
    num_invoices_selected: selectedInvoicesCount,
    amt_invoices_selected: selectedCartInvoicesAmount,
    initial_order_rate: rate,
    initial_pct_advancement: advancePercentage,
    initial_amt_interest: interest,
    initial_amt_fee: fee,
    initial_amt_excess: retention,
    is_auto_rate_enabled: isAutoRateEnabled,
    backoffice_user: user,
  };

  switch (operationType) {
    case DIRECT_FINANCING:
      eventPayload.num_invoices_available_df = availableInvoicesCount;
      eventPayload.amt_invoices_available_df = availableInvoicesAmount;
      break;
    case EARLY_PAYMENT:
      eventPayload.num_invoices_available_pp = availableInvoicesCount;
      eventPayload.amt_invoices_available_pp = availableInvoicesAmount;
      break;
    default:
      break;
  }
  analyticsReset();
  const identifyPayload = {
    is_personified: true,
  };
  identify(businessId, identifyPayload);
  sleep(200).then(() => {
    trackSegment(SEGMENT_EVENTS.ORDER_STARTED, eventPayload);
  });
};

export default trackOrderStarted;
