import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, styled, TextField } from '@material-ui/core';

const Dropdown = styled(TextField)(() => ({
  width: '100%',
  marginBottom: 0,
}));

const AttributesFilter = ({
  invoiceAttribute,
  invoicesAttributes,
  handleAttributes,
}) => {
  const invoiceAttributeValue = invoiceAttribute;

  return (
    <Dropdown
      select
      variant="outlined"
      value={invoiceAttributeValue}
      onChange={handleAttributes}
    >
      {Object.values(invoicesAttributes).map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Dropdown>
  );
};

AttributesFilter.propTypes = {
  invoiceAttribute: PropTypes.string.isRequired,
  invoicesAttributes: PropTypes.arrayOf(String).isRequired,
  handleAttributes: PropTypes.func.isRequired,
};

export default AttributesFilter;
