import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import PropTypes from 'prop-types';

const BulkUpdatePaymentMenu = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        startIcon={<CloudUpload />}
        onClick={e => setAnchorEl(e.currentTarget)}
        data-qa="bulk-invoice-update-action"
      >
        Actualización masiva
      </Button>
      <Menu
        id="massiveUpdate"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems?.map(menuItem => (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              menuItem.handleShowModal();
            }}
          >
            {menuItem.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

BulkUpdatePaymentMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handleShow: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default BulkUpdatePaymentMenu;
