import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useParams } from 'react-router-dom';
import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../../../components/elements/CheckButton';
import AlertForm from '../../../../../../components/elements/AlertForm';
import {
  DATE_FORMAT,
  GLOBAL_REASONS,
  STATUS_HARD_COLLECTION,
  STATUS_PAID,
  STATUS_POST_VERIFIED,
  STATUS_REJECTED,
  STATUS_REPLACED,
  STATUS_VERIFIED,
  COUNTRY_CODE_MX,
  STATUS_DEFAULT,
  STATUS_APPEALED,
  STATUS_IN_REVIEW,
  COUNTRY_CODE_CL,
  STATUS_IN_CONCILIATION,
} from '../../../../../../helpers/Constants';
import { checkStatusAuth } from '../../../../../../helpers/validation/auth';
import { resetStateFetchOrdersIdByPayerRut } from '../../../../../../actions/orderActions';
import { convertSpacingToCss } from '../../../../../../helpers/stylesHelpers';
import {
  dateWithoutFormatterStartOfDay,
  dateWithoutFormatter,
} from '../../../../../../helpers/DateUtils';
import { useOrder } from '../../../../infrastructure/store';
import { STATUSES_PAYMENT } from '../../../../../commons/constants/status';

const STATUSES_AND_DEFAULT_REASONS = {
  [STATUS_REJECTED]: 'HIGH_RISK',
  [STATUS_HARD_COLLECTION]: 'CREDIT_NOTE',
};

const useStyles = makeStyles({
  selectInput: {
    marginBottom: 10,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    margin: convertSpacingToCss('sm'),
  },
});

const ChangeInvoiceStatusDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  invoice,
  title,
  error,
  isLoading,
}) => {
  const classes = useStyles();
  const { rules } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { id: orderId } = useParams();
  const { isLoading: loadingOrders } = useOrder(orderId);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  const [showFundPaymentDateInput, setShowFundPaymentDateInput] =
    useState(false);
  const [showPaymentDateInput, setShowPaymentDateInput] = useState(false);
  const [showDefaultDateInput, setShowDefaultDateInput] = useState(false);
  const [disabledCheckButton, setDisabledCheckButton] = useState(false);
  const [showStatusReason, setShowStatusReason] = useState(false);
  const [paymentDate, setPaymentDate] = useState(null);
  const [fundPaymentDate, setFundPaymentDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [statusReason, setStatusReason] = useState(null);
  const [oldStatus, setOldStatus] = useState();
  const handleShowStatusReason = status => {
    const showSelector =
      status === STATUS_HARD_COLLECTION ||
      status === STATUS_VERIFIED ||
      status === STATUS_POST_VERIFIED ||
      status === STATUS_REJECTED ||
      status === STATUS_APPEALED;
    setShowStatusReason(showSelector);
  };

  const getReason = () => {
    let reason;
    if (invoice.StatusReasons?.length) {
      ({
        StatusReasons: [{ reason }],
      } = invoice);
    } else if (invoice.statusReason?.length) {
      reason = invoice.statusReason;
    }
    return reason;
  };

  useEffect(() => {
    const { status } = invoice;
    handleShowStatusReason(status);
    setStatusSelected(status);
    setStatusReason(getReason());
    const actionByStatus = {
      [STATUS_PAID]: () => {
        setShowPaymentDateInput(true);
        setPaymentDate(
          invoice.paymentDate || dateWithoutFormatterStartOfDay(Date.now())
        );
        setFundPaymentDate(
          invoice.fundPaymentDate || dateWithoutFormatterStartOfDay(Date.now())
        );
      },
      [STATUS_DEFAULT]: () => {
        setShowDefaultDateInput(true);
        setDefaultDate(invoice.defaultDate || dateWithoutFormatter(Date.now()));
      },
    };
    if (actionByStatus[status]) {
      actionByStatus[status]();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  useEffect(() => {
    if (open) {
      setOldStatus(invoice.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleStatusReason = status => {
    const includeStatus = [
      STATUS_REJECTED,
      STATUS_HARD_COLLECTION,
      STATUS_APPEALED,
    ].includes(status);

    if (includeStatus) {
      const reason = STATUSES_AND_DEFAULT_REASONS[status] ?? '';
      setStatusReason(reason);
    }
  };

  const handleStateChange = event => {
    const { value } = event.target;
    setStatusSelected(value);
    dispatch(resetStateFetchOrdersIdByPayerRut());
    setDisabledCheckButton(false);
    setShowPaymentDateInput(value === STATUS_IN_CONCILIATION);
    setPaymentDate(
      invoice?.paymentDate ?? dateWithoutFormatterStartOfDay(Date.now())
    );
    setShowFundPaymentDateInput(value === STATUS_PAID);
    setFundPaymentDate(
      invoice?.fundPaymentDate ?? dateWithoutFormatterStartOfDay(Date.now())
    );
    setShowDefaultDateInput(value === STATUS_DEFAULT);
    setDefaultDate(invoice?.defaultDate ?? dateWithoutFormatter(Date.now()));
    handleShowStatusReason(value);
    handleStatusReason(value);
  };

  const handleSubmitDialog = () => {
    handleSubmit({
      status: statusSelected,
      statusReason,
      paymentDate,
      fundPaymentDate,
      defaultDate,
      oldStatus,
    });
  };

  const filteredStatus = () => {
    return STATUSES_PAYMENT.factura.filter(
      status => !(status.key === STATUS_REPLACED && country === COUNTRY_CODE_MX)
    );
  };

  const getTextValidator = props => (
    <TextValidator
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      validators={['required']}
      errorMessages={['Campo requerido']}
    />
  );

  return (
    <BaseDialog isOpen={open} handleClose={handleCloseDialog} title={title}>
      <ValidatorForm onSubmit={handleSubmitDialog}>
        <Grid container>
          <Grid item xs={12} className={classes.selectInput}>
            <SelectValidator
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={handleStateChange}
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
            >
              {/* CONSIDERAR REFACTOR DE ESTADOS POR PAIS */}
              {filteredStatus().map(({ key, label }) => (
                <MenuItem
                  key={key}
                  value={key}
                  disabled={!checkStatusAuth(key, 'INVOICE', rules)}
                >
                  {label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>

          {statusSelected === STATUS_IN_REVIEW && country === COUNTRY_CODE_CL && (
            <Grid item xs={12}>
              <AlertForm
                variant="warning"
                message="¡Recuerda revisar el estado cartera del cliente!, si hay wallet negativa o estado crítico, riesgo hará un descuento "
              />
            </Grid>
          )}

          {showStatusReason && (
            <Grid item xs={12} className={classes.selectInput}>
              <SelectValidator
                fullWidth
                variant="outlined"
                name="operationReason"
                id="operation-reason-select"
                value={statusReason}
                onChange={e => setStatusReason(e.target.value)}
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
              >
                {GLOBAL_REASONS.filter(
                  r =>
                    r.type === `INVOICE_${statusSelected}` &&
                    r.country.includes(country)
                ).map(reason => (
                  <MenuItem key={reason.value} value={reason.value}>
                    <Typography variant="body1">{reason.label}</Typography>
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}

          {showPaymentDateInput && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de pago real"
                  name="paymentDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(paymentDate)}
                  onChange={date => setPaymentDate(date)}
                  minDateMessage="Fecha inválida"
                  maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
                  autoOk
                  disableFuture
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}

          {showFundPaymentDateInput && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de pago fondo"
                  name="fundPaymentDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(fundPaymentDate)}
                  onChange={date => setFundPaymentDate(date)}
                  minDateMessage="Fecha inválida"
                  maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
                  autoOk
                  disableFuture
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}

          {showDefaultDateInput && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de estado en default"
                  name="defaultDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(defaultDate)}
                  onChange={date => setDefaultDate(date)}
                  autoOk
                  inputVariant="outlined"
                  TextFieldComponent={props => getTextValidator(props)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}

          {!loadingOrders && error && (
            <Grid item xs={12} align="center">
              <AlertForm variant="error" message={error} />
            </Grid>
          )}

          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
            disabled={disabledCheckButton}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeInvoiceStatusDialog.defaultProps = {
  error: null,
  isLoading: false,
};

ChangeInvoiceStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  invoice: PropTypes.shape({
    name: PropTypes.string,
    VerificationReasons: PropTypes.arrayOf(
      PropTypes.shape({ reason: PropTypes.string })
    ),
    StatusReasons: PropTypes.arrayOf(
      PropTypes.shape({ reason: PropTypes.string })
    ),
    Relationships: PropTypes.shape({
      orderId: PropTypes.string,
      folio: PropTypes.string,
    }),
    status: PropTypes.string,
    paymentDate: PropTypes.string,
    fundPaymentDate: PropTypes.string,
    payerIdentifier: PropTypes.string,
    rutReceptor: PropTypes.string,
    mntTotal: PropTypes.number,
    amount: PropTypes.number,
    statusReason: PropTypes.string,
    defaultDate: PropTypes.string,
  }).isRequired,
};

export default ChangeInvoiceStatusDialog;
