import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { VIEW_TYPES, FIELDS_TO_HIDE_IN_INVOICES } from './constants';
import { PayrollDetailContext } from './PayrollDetailContext';
import { InvoiceDetailDrawer } from '../../ui/PayrollDetail/InvoiceDetailDrawer';
import { PayerFilter } from '../../../commons/components/PayerFilter';
import { InvoiceFilter } from '../../../commons/components/InvoiceFilter';
import { FloatingActionBar } from '../../ui/PayrollDetail/FloatingActionBar';
import FundDebtorDetail from '../../../../components/elements/FundDebtorDetail';
import { useFormWithSummary } from '../../../commons/hooks/useFormWithSummary';
import { purgeNestedObj } from '../../../commons/utils';
import { FETCH_RECEIVERS_KEY } from '../../domains/receiver/services';
import { FETCH_INVOICES_KEY } from '../../domains/invoice/services';

export const PayrollDetailContextProvider = ({ children }) => {
  const { payrollId } = useParams();
  const [receiverForm, setReceiverForm] = useState();
  const [invoiceForm, setInvoiceForm] = useState();
  const [viewType, setViewType] = useState(VIEW_TYPES.RECEIVER);
  const [openInvoiceDetail, setOpenInvoiceDetail] = useState(false);
  const [isDebtorDetailVisible, setIsDebtorDetailVisible] = useState(false);
  const [openStateChangeDialog, setOpenStateChangeDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isBulkUpdating, setIsBulkUpdating] = useState(false);
  const [payrollInvoiceId, setPayrollInvoiceId] = useState();
  const { country } = useSelector(state => state.config, shallowEqual);
  const receiverFilter = useFormWithSummary();
  const invoiceFilter = useFormWithSummary();
  const queryClient = useQueryClient();

  const filter = () => {
    if (viewType === VIEW_TYPES.INVOICE) {
      return invoiceFilter;
    }

    return receiverFilter;
  };

  const changeToInvoiceView = useCallback(() => {
    setViewType(VIEW_TYPES.INVOICE);
    const payerForm = { businessStatus: receiverForm?.businessStatus };

    invoiceFilter.handleSubmit()({
      form: payerForm,
      appliedFilters: Object.values(purgeNestedObj(payerForm)).length,
    });
  }, [invoiceFilter, receiverForm]);

  const changeToReceiverView = useCallback(() => {
    setViewType(VIEW_TYPES.RECEIVER);
    setInvoiceForm({});
  }, []);

  const handleOpenInvoiceDetail = useCallback(id => {
    setOpenInvoiceDetail(true);
    setPayrollInvoiceId(id);
  }, []);

  const value = useMemo(
    () => ({
      changeToInvoiceView,
      changeToReceiverView,
      country,
      filter,
      handleOpenInvoiceDetail,
      invoiceForm,
      isBulkUpdating,
      isDeleting,
      openStateChangeDialog,
      payrollId,
      receiverForm,
      setIsDebtorDetailVisible,
      setOpenStateChangeDialog,
      viewType,
    }),
    [
      changeToInvoiceView,
      changeToReceiverView,
      country,
      filter,
      handleOpenInvoiceDetail,
      invoiceForm,
      isBulkUpdating,
      isDeleting,
      openStateChangeDialog,
      payrollId,
      receiverForm,
      setIsDebtorDetailVisible,
      setOpenStateChangeDialog,
      viewType,
    ]
  );

  const onSubmitReceiver = receiverFilter.handleSubmit(form => {
    queryClient.invalidateQueries([FETCH_RECEIVERS_KEY]);
    setReceiverForm(purgeNestedObj(form));
  });

  const onSubmitInvoice = invoiceFilter.handleSubmit(form => {
    queryClient.invalidateQueries([FETCH_INVOICES_KEY]);
    setInvoiceForm(purgeNestedObj(form));
  });

  return (
    <PayrollDetailContext.Provider value={value}>
      {children}
      <InvoiceDetailDrawer
        onClose={() => setOpenInvoiceDetail(false)}
        open={openInvoiceDetail}
        payrollInvoiceId={payrollInvoiceId}
      />
      <FundDebtorDetail
        showDrawer={isDebtorDetailVisible}
        handleCloseDrawer={() => setIsDebtorDetailVisible(false)}
      />
      <PayerFilter
        form={receiverFilter.formFilled}
        onClose={() => receiverFilter.setShowFilter(false)}
        onSubmit={onSubmitReceiver}
        open={receiverFilter.showFilter}
      />
      <InvoiceFilter
        fieldsToHide={FIELDS_TO_HIDE_IN_INVOICES}
        form={{
          businessStatus: receiverForm?.businessStatus,
          ...invoiceFilter.formFilled,
        }}
        onClose={() => invoiceFilter.setShowFilter(false)}
        onSubmit={onSubmitInvoice}
        open={invoiceFilter.showFilter}
      />
      <FloatingActionBar
        setIsDeleting={setIsDeleting}
        setIsBulkUpdating={setIsBulkUpdating}
        businessStatus={receiverForm?.businessStatus}
        invoiceForm={invoiceForm}
      />
    </PayrollDetailContext.Provider>
  );
};

PayrollDetailContextProvider.defaultProps = {
  children: null,
};

PayrollDetailContextProvider.propTypes = {
  children: PropTypes.node,
};
