/* eslint-disable eqeqeq */

import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../Constants';

const validateRut = rutInput => {
  const regex = /^((?:\d{1,3})?(?:\.?\d{3}){2}-[0-9kK])$/;
  if (!regex.test(rutInput)) {
    return false;
  }
  // clean the input
  const rut = rutInput.replace(/[^kK0-9]/g, '').slice(0, -1);
  if (rut.length < 7) return false;

  // get the dv
  const dv = rutInput.slice(-1).toLowerCase();

  let mult = 2;
  const suma = [...rut].reverse().reduce((sum, val) => {
    const acc = mult * val;
    mult = mult < 7 ? mult + 1 : 2;
    return sum + acc;
  }, 0);

  const expectedDv = 11 - (suma % 11);
  // special cases
  const compareDv = dv == 'k' ? 10 : dv == 0 ? 11 : dv;

  return compareDv == expectedDv;
};

const validateRfc = rfc => {
  const regex = /^([a-zA-Z&]{3,4}\d{6}[0-9a-zA-Z&]{3})$/;
  return regex.test(rfc);
};

export const rutCleaner = rut => {
  let cleanRut = rut.replace(/\./g, '');
  cleanRut = cleanRut.replace(/-/g, '');
  cleanRut = `${cleanRut.slice(0, -1)}-${cleanRut.slice(-1)}`;
  return cleanRut;
};

export const validateIdentifier = (identifier, countryId) => {
  switch (countryId) {
    case COUNTRY_CODE_CL:
      return validateRut(identifier);
    case COUNTRY_CODE_MX:
      return validateRfc(identifier);
    default:
      return false;
  }
};
