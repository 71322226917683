import { useQueries } from 'react-query';
import fetchOrderInvoicesAttributesService from '../services/fetchOrderInvoicesAttributesService';

const useGetOrderInvoiceIsHold = (operationIds: number[]) => {
  const queries = useQueries(
    operationIds.map(id => ({
      queryKey: ['order', id],
      queryFn: () => fetchOrderInvoicesAttributesService(id),
      enabled: !!id,
    }))
  );

  return queries.map(query => query.data);
};

export default useGetOrderInvoiceIsHold;
