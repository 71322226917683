import { Box, makeStyles, styled, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  inputMultiLine: {
    '& fieldset': {
      borderRadius: 17,
    },
  },
});
const ContainerSpinner = styled(Box)({
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'center',
});
const TextDocumentType = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 13,
  marginTop: '-10px',
  marginBottom: 20,
}));
const ContainerDescription = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
});
const LabelDescription = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 5,
}));
const LabelDefault = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 5,
}));
const LabelUploadDocument = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 15,
}));
const ContainerError = styled(Box)({
  marginTop: 30,
});
const ContainerSaveButton = styled(Box)({
  marginTop: 30,
});
const ContainerInitialState = styled(Box)({
  marginBottom: 20,
});

export {
  useStyles,
  ContainerSpinner,
  TextDocumentType,
  ContainerDescription,
  LabelDescription,
  LabelDefault,
  LabelUploadDocument,
  ContainerError,
  ContainerSaveButton,
  ContainerInitialState,
};
