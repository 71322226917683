import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TRANSFORMED_ACCOUNT_TYPES } from '../../../helpers/Constants';
import {
  useBulkAssignBankAccountToInvoices,
  useCreateBankAccount,
  useFetchBankAccounts,
  useFetchTransferAccounts,
  useOrder,
} from '../infrastructure/store';
import { t13s } from '../../../translationKeys';
import { enqueueSnackbar } from '../../../actions/notificationAction';
import { settings } from '../../../config/settings';
import {
  useSelectedTransferAccount,
  useSetSelectedTransferAccount,
} from '../ui/PayrollTabs/TransferAccountsTab/store';

const useBankAccountsTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { id: orderId } = useParams();
  const { order } = useOrder(orderId);
  const { transferAccounts, fetchTransferAccountsIsLoading } =
    useFetchTransferAccounts(orderId);

  const {
    mutate: bulkAssignInvoicesBankAccount,
    isSuccess: bankAccountWasAssigned,
    isLoading: bankAccountIsAssigning,
  } = useBulkAssignBankAccountToInvoices();

  const setSelectedBankAccount = useSetSelectedTransferAccount();
  const selectedBankAccount = useSelectedTransferAccount();

  const supplierIdentifier = selectedBankAccount?.sourceIdentifier;

  const supplierName = selectedBankAccount?.accountHolder;

  const { refetch: refetchBusinessSupplierBankAccounts } = useFetchBankAccounts(
    'supplier',
    selectedBankAccount?.supplierPaymentsId
  );

  const { createBankAccount, bankAccountIsCreating } = useCreateBankAccount();

  const [mergedBankAccounts, setMergedBankAccounts] = useState([]);
  const [showBankAccountDrawer, setShowBankAccountDrawer] = useState(false);
  const [showBankAccountSelectorDrawer, setIsShowBankAccountSelectorDrawer] =
    useState(false);
  const [showAddBankAccountDrawer, setIsShowAddBankAccountDrawer] =
    useState(false);

  const [showEditBankAccountDrawer, setShowEditBankAccountDrawer] =
    useState(false);

  const handleShowBankAccountDrawer = useCallback(bankAccount => {
    setSelectedBankAccount(bankAccount);
    setShowBankAccountDrawer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseBankAccountDrawer = useCallback(() => {
    setSelectedBankAccount(null);
    setShowBankAccountDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowEditBankAccountDrawer = useCallback(bankAccount => {
    setSelectedBankAccount(bankAccount);
    setShowEditBankAccountDrawer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseEditBankAccountDrawer = useCallback(() => {
    setSelectedBankAccount(null);
    setShowEditBankAccountDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditBackButton = useCallback(() => {
    setShowEditBankAccountDrawer(false);
    setShowBankAccountDrawer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenBackButton = useCallback(() => {
    setShowEditBankAccountDrawer(true);
    setShowBankAccountDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleShowBankAccountSelectorDrawer = bankAccount => {
    setSelectedBankAccount({ ...bankAccount });
    setIsShowBankAccountSelectorDrawer(true);
  };

  const handleShowAddBankAccountDrawer = useCallback(() => {
    setIsShowAddBankAccountDrawer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseBankAccountSelectorDrawer = useCallback(() => {
    setSelectedBankAccount(null);
    setIsShowBankAccountSelectorDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseAddBankAccountDrawer = useCallback(() => {
    setIsShowAddBankAccountDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssignSupplierBankAccountBulk = useCallback(
    selectedBankAccountId => {
      const { bankAccountId: oldBankAccountId } = selectedBankAccount;
      const matchingTransferAccounts = transferAccounts.filter(
        account => account.bankAccountId === oldBankAccountId
      );

      const invoicesIds = matchingTransferAccounts.map(
        account => account.invoiceId
      );
      bulkAssignInvoicesBankAccount({
        businessId: order.businessId,
        invoicesIds,
        bankAccountId: selectedBankAccountId,
      });
    }
  );

  const handleAddBankAccountDrawer = useCallback(bankAccountData => {
    createBankAccount({
      source: 'supplier',
      sourceId: order.businessId, // no se usa, need refactor
      payerBusinessId: order.businessId,
      supplierIdentifier: selectedBankAccount?.sourceIdentifier,
      bankAccount: bankAccountData,
      onSuccessCallback: () => {
        refetchBusinessSupplierBankAccounts();
        handleCloseAddBankAccountDrawer();
      },
    });
  });

  useEffect(() => {
    if (transferAccounts?.length) {
      const res = [];
      for (let i = 0; i < transferAccounts.length; i++) {
        const ind = res.findIndex(
          el => el.accountNumber === transferAccounts[i].accountNumber
        );
        if (ind === -1) {
          res.push(transferAccounts[i]);
        } else {
          res[ind].amount += transferAccounts[i].amount;
        }
      }
      setMergedBankAccounts(res);
    }
  }, [transferAccounts, transferAccounts?.length]);

  useEffect(() => {
    if (bankAccountWasAssigned) handleCloseBankAccountSelectorDrawer();
  }, [bankAccountWasAssigned]);

  const handleCopyBankAccount = () => {
    const data = [
      {
        key: 'Total a transferir',
        value: selectedBankAccount.amount,
      },
      {
        key: 'Razón social',
        value: selectedBankAccount.accountHolder,
      },
      {
        key: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        value: selectedBankAccount.identifier,
      },
      {
        key: 'Banco',
        value: selectedBankAccount.bankName,
      },
      {
        key: 'Cuenta',
        value: selectedBankAccount.accountNumber,
      },
      {
        key: 'Alias',
        value: selectedBankAccount.alias,
      },
      {
        key: 'Divisa',
        value: settings[country].currencyCode,
      },
      {
        key: 'Correo',
        value: selectedBankAccount.accountEmail,
      },
    ];

    if (selectedBankAccount.accountType)
      data.push({
        key: 'Tipo de cuenta:',
        value: TRANSFORMED_ACCOUNT_TYPES[selectedBankAccount.accountType],
      });

    if (selectedBankAccount) {
      const dataString = data
        .reduce((acc, item) => {
          const { key, value } = item;
          const newLine = `${key}: ${value}`;
          return [...acc, newLine];
        }, [])
        .join('\n');

      copy(dataString, { format: 'text/plain' });

      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
          variant: 'success',
        })
      );
    } else {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }
  };

  return {
    selectedBankAccount,
    fetchTransferAccountsIsLoading,
    bankAccounts: mergedBankAccounts,

    showBankAccountDrawer,
    showBankAccountSelectorDrawer,
    bankAccountIsAssigning,
    handleCopyBankAccount,
    handleOpenBackButton,
    handleShowBankAccountDrawer,
    handleCloseBankAccountDrawer,

    showEditBankAccountDrawer,
    handleEditBackButton,
    handleShowEditBankAccountDrawer,
    handleCloseEditBankAccountDrawer,
    handleShowBankAccountSelectorDrawer,
    handleCloseBankAccountSelectorDrawer,
    handleAssignSupplierBankAccountBulk,

    showAddBankAccountDrawer,
    handleShowAddBankAccountDrawer,
    handleAddBankAccountDrawer,
    bankAccountIsCreating,
    handleCloseAddBankAccountDrawer,
    supplierIdentifier,
    supplierName,
  };
};

export default useBankAccountsTab;
