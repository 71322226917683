import React from 'react';
import { styled, Badge as MaterialBadge } from '@material-ui/core';

const CustomBadge = styled(MaterialBadge)(({ selected, theme }) => ({
  color: selected ? theme.palette.payments.white : theme.palette.payments.grey2,
  backgroundColor: selected
    ? theme.palette.primary.main
    : theme.palette.payments.softBlue,
  padding: '1px 7px',
  borderRadius: 20,
  fontSize: 12,
  marginLeft: 5,
}));

const Badge = params => {
  return (
    <CustomBadge selected={params.selected}>{params.children}</CustomBadge>
  );
};

export default Badge;
