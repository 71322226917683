import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import {
  Badge,
  Button,
  Grid,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from './icons';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../helpers/Constants';
import { BUSINESS_CHANGE_NAME_PERFORM } from '../helpers/performsType';
import { fetchBusiness, editBusiness } from '../actions/businessAction';
import SectionHeader from './elements/SectionHeader';
import TGRAgreements from './TGRAgreements';
import BankAccounts from './BankAccounts';
import BusinessLegal from './BusinessLegal';
import InvoicesFinder from './elements/InvoicesFinder';
import { features } from '../config/features';
import BusinessInformation from './BusinessInformation';
import Loader from './elements/Loader';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { fetchDebtService } from '../actions/debtServiceActions';
import { verifyStatusDocuments } from '../actions/documentActions';
import HistoryLogsDialog from './dialogs/HistoryLogsDialog';
import BaseDialog from './dialogs/BaseDialog';
import Can from './Can';
import { settings } from '../config/settings';
import {
  BusinessCreditLine,
  TAB_CREDIT_LINE,
  setTabsWithCreditLine,
} from '../features/credit-line';

const TAB_INFORMATION = 'information';
const TAB_TGR = 'tgragreements';
const TAB_BANK_ACCOUNT = 'bankaccounts';
const TAB_INVOICES_FINDER = 'invoicefinder';
const TAB_LEGAL = 'legal';
const ENTITY = 'business';

const useStyles = makeStyles(theme => ({
  infoBusiness: {
    padding: convertSpacingToCss('lg'),
  },
  headerTabsTab: {
    minWidth: '69px',
    textTransform: 'none',
  },
  firstOrderBadge: {
    backgroundColor: theme.palette.text.contrast1,
  },
  headerBusinessName: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const HomeBusinessDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { business, loadingBusiness, businessUpdated } = useSelector(
    state => state.business
  );
  const { country } = useSelector(state => state.config);
  const { name: businessName, id: businessId, identifier } = business;
  const countryFeatures = features[country];
  const { isApiGlobal } = settings[country];

  const cleanHash = location.hash.replace('#', '');
  const existTab = [
    TAB_INFORMATION,
    TAB_INVOICES_FINDER,
    TAB_LEGAL,
    TAB_TGR,
    TAB_BANK_ACCOUNT,
    TAB_CREDIT_LINE,
  ].includes(cleanHash);
  const [stateTab, setStateTab] = useState(
    existTab ? cleanHash : TAB_INFORMATION
  );
  const [editBusinessName, setEditBusinessName] = useState(false);
  const [stateBusinessName, setStateBusinessName] = useState('');
  const [editBusinessValue, setEditBusinessValue] = useState('');
  const [historyLogDialog, setHistoryLogDialog] = useState(false);
  const [context, setContexts] = useState({
    contextType: '',
    contextId: '',
  });

  useEffect(() => {
    if (id?.toString() !== businessId?.toString()) {
      dispatch(fetchBusiness(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  useEffect(() => {
    if (countryFeatures.businessDetailsSection.showDocuments && id)
      dispatch(verifyStatusDocuments(id, ENTITY));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      businessId === Number(id) &&
      (!isApiGlobal || country === COUNTRY_CODE_MX)
    ) {
      dispatch(fetchDebtService(businessId, ENTITY));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, id, dispatch, country]);

  useEffect(() => {
    setStateBusinessName(businessName);
    setEditBusinessValue(businessName);
  }, [businessName]);

  useEffect(() => {
    if (businessUpdated) {
      setEditBusinessName(false);
    }
  }, [businessUpdated]);

  let arrayTabs = {
    CL: [
      {
        label: 'Información',
        value: TAB_INFORMATION,
      },
      {
        label: 'Convenios TGR',
        value: TAB_TGR,
      },
      {
        label: 'Legal',
        value: TAB_LEGAL,
      },
      {
        label: 'Cuentas Bancarias',
        value: TAB_BANK_ACCOUNT,
      },
      {
        label: 'Buscador de facturas',
        value: TAB_INVOICES_FINDER,
      },
    ],
    MX: [
      {
        label: 'Información',
        value: TAB_INFORMATION,
      },
      {
        label: 'Cuentas Bancarias',
        value: TAB_BANK_ACCOUNT,
      },
      {
        label: 'Legal',
        value: TAB_LEGAL,
      },
      {
        label: 'Buscador de facturas',
        value: TAB_INVOICES_FINDER,
      },
    ],
  };

  arrayTabs = setTabsWithCreditLine(arrayTabs);

  if (!isApiGlobal) {
    arrayTabs[COUNTRY_CODE_CL].splice(2, 1);
  }

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = valueTab => {
    history.push(`${location.pathname}#${valueTab}`);
    setStateTab(valueTab);
  };

  const handleHistoryLogDialog = async (
    contextId,
    contextType = 'BUSINESS'
  ) => {
    setContexts({ contextId, contextType });
    setHistoryLogDialog(true);
  };

  const onEditBussinessNameSubmit = () => {
    if (editBusinessValue !== '') {
      dispatch(editBusiness({ name: editBusinessValue, businessId }));
    }
  };

  return (
    <>
      <Grid item>
        <SectionHeader
          preTitleAction={
            <Tooltip title="Operaciones Transferidas">
              <Badge
                classes={
                  business.totalOrdersTransferred < 2
                    ? { badge: classes.firstOrderBadge }
                    : ''
                }
                badgeContent={business.totalOrdersTransferred}
                color="primary"
                invisible={false}
              />
            </Tooltip>
          }
          title={
            <Can
              perform={BUSINESS_CHANGE_NAME_PERFORM}
              yes={() => (
                <Box className={classes.headerBusinessName}>
                  <Typography variant="h5" color="textPrimary" component="div">
                    {stateBusinessName}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => setEditBusinessName(!editBusinessName)}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              )}
              no={() => (
                <Typography variant="h5" color="textPrimary" component="div">
                  {stateBusinessName}
                </Typography>
              )}
            />
          }
          isLoading={loadingBusiness}
          padding="lg lg zero"
          breadCrumbs={[
            {
              title: 'Empresas',
              link: `/${country.toLowerCase()}/business`,
            },
            {
              title: 'Detalle de empresa',
            },
          ]}
          mainArea={
            <Tabs
              value={stateTab}
              onChange={(e, valueTab) => handleTabChange(valueTab)}
              TabIndicatorProps={{
                style: {
                  height: '3px',
                  borderRadius: '10px',
                },
              }}
            >
              {arrayTabs[country].map(tab => {
                const { label, value: title } = tab;
                return (
                  <Tab
                    label={label}
                    value={title}
                    key={label}
                    className={classes.headerTabsTab}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...a11yProps(title)}
                  />
                );
              })}
            </Tabs>
          }
          secondaryActions={
            <>
              {countryFeatures.enabledOperations.directFinancing && (
                <Button
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  color="primary"
                  href={`/${country.toLowerCase()}/direct_financing?identifier=${identifier}`}
                  target="_blank"
                  disabled={loadingBusiness}
                >
                  Ver operaciones FD
                </Button>
              )}
              {countryFeatures.enabledOperations.earlyPayment && (
                <Button
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  href={`/${country.toLowerCase()}/early_payment?identifier=${identifier}`}
                  target="_blank"
                  color="primary"
                  variant="text"
                  disabled={loadingBusiness}
                >
                  Ver operaciones PP
                </Button>
              )}
            </>
          }
        />
        {historyLogDialog && (
          <HistoryLogsDialog
            open={historyLogDialog}
            handleCloseDialog={() => setHistoryLogDialog(false)}
            contextId={context.contextId}
            contextType={context.contextType}
          />
        )}

        {loadingBusiness ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              role="tabpanel"
              hidden={stateTab !== TAB_INFORMATION}
              id={`wrapped-tabpanel-${stateTab}`}
              aria-labelledby={`wrapped-tab-${stateTab}`}
            >
              <BusinessInformation
                id={id}
                setStateTab={setStateTab}
                business={business}
                handleHistoryLogDialog={handleHistoryLogDialog}
              />
            </Grid>

            <Grid
              item
              xs={12}
              role="tabpanel"
              hidden={stateTab !== TAB_CREDIT_LINE}
              id={`wrapped-tabpanel-${stateTab}`}
              aria-labelledby={`wrapped-tab-${stateTab}`}
            >
              {stateTab === TAB_CREDIT_LINE && (
                <BusinessCreditLine country={country} business={business} />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              role="tabpanel"
              hidden={stateTab !== TAB_TGR}
              id={`wrapped-tabpanel-${stateTab}`}
              aria-labelledby={`wrapped-tab-${stateTab}`}
            >
              {stateTab === TAB_TGR && <TGRAgreements id={id} />}
            </Grid>

            <Grid
              item
              xs={12}
              role="tabpanel"
              hidden={stateTab !== TAB_BANK_ACCOUNT}
              id={`wrapped-tabpanel-${stateTab}`}
              aria-labelledby={`wrapped-tab-${stateTab}`}
            >
              {stateTab === TAB_BANK_ACCOUNT && (
                <BankAccounts businessId={id} />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              role="tabpanel"
              hidden={stateTab !== TAB_LEGAL}
              id={`wrapped-tabpanel-${stateTab}`}
              aria-labelledby={`wrapped-tab-${stateTab}`}
            >
              {stateTab === TAB_LEGAL && <BusinessLegal businessId={id} />}
            </Grid>

            <Grid
              item
              xs={12}
              role="tabpanel"
              hidden={stateTab !== TAB_INVOICES_FINDER}
              id={`wrapped-tabpanel-${stateTab}`}
              aria-labelledby={`wrapped-tab-${stateTab}`}
            >
              {stateTab === TAB_INVOICES_FINDER && <InvoicesFinder id={id} />}
            </Grid>
          </Grid>
        )}
      </Grid>
      {editBusinessName && (
        <BaseDialog
          isOpen={editBusinessName}
          title="Editar nombre empresa"
          handleClose={() => setEditBusinessName(!editBusinessName)}
        >
          <ValidatorForm onSubmit={onEditBussinessNameSubmit}>
            <TextValidator
              variant="outlined"
              placeholder="Razon Social"
              fullWidth
              value={editBusinessValue}
              onChange={e => setEditBusinessValue(e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <Button variant="contained" fullWidth color="primary" type="submit">
              Guardar
            </Button>
          </ValidatorForm>
        </BaseDialog>
      )}
    </>
  );
};

export default HomeBusinessDetails;
