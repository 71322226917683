import React, { useState, FC } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CommentAttachment } from '../../interfaces';
import AddCircleIcon from '../../../../icons/AddCircleIcon';
import useFetchOrderCommentAttachment from '../../infrastructure/store/useFetchOrderCommentAttachment';
import useDownloadOrderCommentAttachment from '../../infrastructure/store/useDownloadOrderCommentAttachment';
import {
  PaginatorContainer,
  PdfContainer,
  ProgressContainer,
  useStyles,
} from './styles';

interface Props {
  isOpen: boolean;
  commentAttachment: CommentAttachment | null;
  handleCloseDialog: () => void;
}

const PdfVisualizer: FC<Props> = ({
  isOpen,
  commentAttachment,
  handleCloseDialog,
}) => {
  const classes = useStyles();

  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { isLoading: fetchOrderCommentAttachmentIsLoading, data } =
    useFetchOrderCommentAttachment(Number(commentAttachment?.id));
  const {
    isLoading: downloadOrderCommentAttachmentIsLoading,
    downloadOrderCommentAttachment,
  } = useDownloadOrderCommentAttachment();

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={isOpen}
      maxWidth={'xl'}
      PaperProps={{
        className: classes.root,
      }}
    >
      <DialogContent>
        {fetchOrderCommentAttachmentIsLoading ? (
          <ProgressContainer>
            <CircularProgress size={60} />
          </ProgressContainer>
        ) : (
          <Box>
            <PdfContainer display="flex" justifyContent="center">
              <Document
                file={data?.file}
                onLoadSuccess={({ numPages }: { numPages: number | null }) => {
                  setTotalPages(numPages);
                }}
                loading={<CircularProgress />}
              >
                <Page pageNumber={pageNumber} scale={1.28} />
              </Document>
            </PdfContainer>
            <Box display="flex" justifyContent="center" mt={2}>
              <PaginatorContainer>
                <Pagination
                  count={totalPages || 0}
                  page={pageNumber}
                  onChange={(e, page) => setPageNumber(page)}
                  size="small"
                  color="secondary"
                />
              </PaginatorContainer>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.downloadButton}
          variant="contained"
          color="primary"
          startIcon={
            downloadOrderCommentAttachmentIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <AddCircleIcon color="#fff" />
            )
          }
          disabled={downloadOrderCommentAttachmentIsLoading}
          onClick={() =>
            downloadOrderCommentAttachment({
              fileName: commentAttachment?.fileName || '',
              attachmentId: commentAttachment?.id || 0,
            })
          }
        >
          Descargar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PdfVisualizer;
