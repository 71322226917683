import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  payerLink: {
    fontWeight: 400,
    textDecoration: 'underline',
  },
  hasEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 300,
  },
});
