import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { t13s } from '../../../../translationKeys';
import { PayerDebts } from '../../../commons/interfaces/payerDebts';
import useFetchManagements from '../../infrastructure/store/useFecthManagements';
import MaterialTable from 'material-table';
import { useGetManagementsColumns } from './hooks/managementsTab/useGetManagementsColumns';
import PaginationMaterialTable from '../../../../components/elements/PaginationMaterialTable';
import { GetManagementsFilters } from '../../../commons/interfaces/managements';
import { format } from 'date-fns';
import Loader from '../../../../components/elements/Loader';

const { MANAGEMENTS_TABLE } = t13s.LABEL.COLLECTIONS;
const DEFAULT_PAGE_SIZE = 10;

type PayerManagementsDataProps = {
  debtsData: PayerDebts;
};
const PayerManagementsData = ({ debtsData }: PayerManagementsDataProps) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const { t } = useTranslation();
  const columns = useGetManagementsColumns();
  const {
    data: { data: managements },
    isLoading: isManagementsLoading,
    refetch: refetchManagements,
  } = useFetchManagements({
    debtsIds: debtsData.payerDebts.map(debt => debt.orderInovice.id),
    identifiers: [debtsData.payerIdentifier],
    order: 'createdAt:desc',
    pagination: { page, size: pageSize },
  });
  const {
    data: { data: newestDebtManagement },
    refetch: refetchNewestDebtManagement,
  } = useFetchManagements({
    debtsIds: debtsData.payerDebts.map(debt => debt.orderInovice.id),
    identifiers: [debtsData.payerIdentifier],
    order: 'createdAt:desc',
    pagination: { page: 1, size: 1 },
    filter: GetManagementsFilters.debtManagement,
    useQueryId: 'getNewestDebtManagement',
  });
  const {
    data: { data: newestPayerCategorization },
    refetch: refetchNewestPayerCategorization,
  } = useFetchManagements({
    debtsIds: debtsData.payerDebts.map(debt => debt.orderInovice.id),
    identifiers: [debtsData.payerIdentifier],
    order: 'createdAt:desc',
    pagination: { page: 1, size: 1 },
    filter: GetManagementsFilters.payerCategorization,
    useQueryId: 'getNewestPayerCategorization',
  });

  const paginationCount =
    managements?.length >= pageSize
      ? pageSize * page + pageSize
      : pageSize * (page - 1) + managements?.length;
  const newestDebtManagementDate =
    newestDebtManagement && newestDebtManagement[0]?.createdAt;
  const newestPayerCategorizationDate =
    newestPayerCategorization && newestPayerCategorization[0]?.createdAt;
  const emptyDataSourceMessage =
    page > 1
      ? t(MANAGEMENTS_TABLE.NO_MORE_PAGES)
      : t(MANAGEMENTS_TABLE.MANAGEMENTS_NOT_FOUND);
  const handleChangePageSize = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    refetchManagements();
    refetchNewestDebtManagement();
    refetchNewestPayerCategorization();
  }, [page, pageSize]);

  return (
    <Box py={4} pl={4}>
      <Box display="flex" pb={4}>
        {newestDebtManagementDate && (
          <Box pr={4}>
            <Typography color="textPrimary" variant="body1">
              {t(MANAGEMENTS_TABLE.MANAGEMENTS_LAST_MANAGEMENT, {
                date: format(newestDebtManagementDate, 'dd-MM-yyyy'),
              })}
            </Typography>
          </Box>
        )}
        {newestPayerCategorizationDate && (
          <Typography color="textPrimary" variant="body1">
            {t(MANAGEMENTS_TABLE.MANAGEMENTS_LAST_CATEGORIZATION, {
              date: format(newestPayerCategorizationDate, 'dd-MM-yyyy'),
            })}
          </Typography>
        )}
      </Box>
      {isManagementsLoading ? (
        <Loader />
      ) : (
        <MaterialTable
          columns={columns}
          data={managements}
          isLoading={isManagementsLoading}
          style={{
            overflow: 'hidden',
            boxShadow: 'none',
            width: '100%',
            borderRadius: 17,
          }}
          onChangeRowsPerPage={handleChangePageSize}
          components={{
            Pagination: props => (
              <PaginationMaterialTable
                {...props}
                page={page - 1}
                count={paginationCount}
                onChangePage={(_e, page) => setPage(page + 1)}
              />
            ),
            Toolbar: () => null,
          }}
          localization={{
            pagination: {
              labelRowsPerPage: t(MANAGEMENTS_TABLE.ROWS_PER_PAGE),
              labelDisplayedRows: '',
            },
            body: {
              emptyDataSourceMessage,
            },
          }}
          options={{
            showTitle: false,
            emptyRowsWhenPaging: false,
            filtering: false,
            search: false,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
        />
      )}
    </Box>
  );
};

export default PayerManagementsData;
