import { makeStyles } from '@material-ui/core';

import { ALT_BORDER_COLOR } from '../../../../../theme/otherColors';

export const useStyles = makeStyles(() => ({
  containerOperationResume: {
    background: '#fff',
    border: `1px solid ${ALT_BORDER_COLOR}`,
    borderRadius: 10,
    boxSizing: 'border-box',
    padding: '30px 20px',
    width: '280px',
    alignSelf: 'flex-start',
  },
  selectFundOperationResume: {
    marginBottom: 10,
    width: '100%',
  },
  titleOperationResume: {
    marginBottom: 20,
  },
  informationFundContainerValues: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  spacingBottom: {
    marginBottom: 8,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  totalAmountContainer: {
    background: '#1C1732',
    borderRadius: 7,
    marginBottom: 25,
    padding: '5px 0px 10px',
    width: '100%',
  },
  informationFundOperationResume: {
    flexWrap: 'wrap',
  },
  xlsxDownloadLink: {
    color: '#312F37',
    fontSize: 13,
    lineHeight: '150%',
    textDecoration: 'underline',
    marginTop: 15,
    '& > svg': {
      marginRight: 4,
      marginBottom: -2,
    },
    '&.disabled': {
      color: '#C1BDCE',
      userSelect: 'none',
      cursor: 'not-allowed',
      '& > svg path': {
        fill: '#C1BDCE',
      },
    },
  },
}));
