
import { useEffect } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { request } from 'graphql-request';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  mutation: string;
} & Omit<UseMutationOptions, 'onSuccess'>;

export const useGraphQlMutation = <Variables, Data>({
  mutation,
  onSuccess,
  ...props
}: Props
& { onSuccess?: (data: Data, variables: Variables, context: unknown) => void }) => {
  const endpoint = process.env.REACT_APP_GRAPHQL_SERVER_URL;
  const { authState } = useOktaAuth();

  const mutationFn = async (variables: Variables) => request<Data>({
    url: endpoint!,
    document: mutation,
    requestHeaders: { authorization: `Bearer ${authState?.accessToken?.accessToken || ''}` },
    variables,
  });

  const {
    isLoading: loading,
    data,
    error,
    isSuccess,
    isError,
    reset,
    mutate,
    mutateAsync,
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
  } = useMutation<Data, string, Variables>(mutationFn, {
    ...props,
    onSuccess,
  });

  useEffect(() => reset, []);

  return {
    loading,
    data,
    error,
    isSuccess,
    isError,
    mutate,
    mutateAsync,
  };
};
