/* eslint react/prop-types: 0 */ // --> OFF
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fetchRequests, fetchTransactions } from '../../actions/walletActions';
import WalletTransactionsTable from './WalletTransactionsTable';
import WalletRequestsTable from './WalletRequestsTable';
import Can from '../Can';
import { WALLETS_SECTION_PERFORM } from '../../helpers/performsType';

const useStyles = makeStyles({
  requestsTableContainer: {
    background: '#fff',
    borderRadius: 17,
    border: '1px solid #CECAD9',
    padding: 0,
    marginBottom: 30,
    marginTop: 60,
    maxWidth: '75%',
  },
  transactionsTableContainer: {
    background: '#fff',
    borderRadius: 17,
    border: '1px solid #CECAD9',
    padding: 0,
    marginBottom: 60,
    maxWidth: '75%',
  },
  tableTitleAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 24,
    paddingRight: 50,
  },
  createTransactionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 10,
    paddingLeft: 24,
    paddingRight: 50,
  },
});

const WalletItem = ({
  handleShowCreateTransactionDialog,
  walletData = {},
  handleShowRequestStatusDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { identifier, id: profileId } = walletData.AccountProfile;
  const transactions = useSelector(
    state => state.wallet.transactions[profileId]
  );
  const requests = useSelector(
    state => state.wallet.pendingRequests[identifier]
  );
  const {
    loadingTransactions,
    transactionCreated,
    loadingPendingRequests: loadingRequests,
  } = useSelector(state => state.wallet);

  const [showTransactions, setShowTransactions] = useState(false);
  const [showRequests, setShowRequests] = useState(true);

  useEffect(() => {
    if (transactionCreated) {
      dispatch(fetchTransactions(profileId));
    }
  }, [transactionCreated, dispatch, profileId]);

  useEffect(() => {
    if (showTransactions && !transactions && profileId) {
      dispatch(fetchTransactions(profileId));
    }
  }, [showTransactions, transactions, profileId, dispatch]);

  useEffect(() => {
    if (showRequests && !requests && identifier) {
      dispatch(fetchRequests(identifier, 'PENDING,ACCEPTED'));
    }
  }, [showRequests, requests, identifier, dispatch]);

  return (
    <>
      <Container className={classes.requestsTableContainer}>
        <Grid container xs={12} className={classes.tableTitleAndButton}>
          <Typography variant="h5" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">Solicitudes vigentes</Box>
          </Typography>
          <IconButton onClick={() => setShowRequests(!showRequests)}>
            {showRequests ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Grid>
        {showRequests && (
          <WalletRequestsTable
            handleShowRequestStatusDialog={handleShowRequestStatusDialog}
            requests={requests}
            loading={loadingRequests}
          />
        )}
      </Container>
      <Container className={classes.transactionsTableContainer}>
        <Grid container xs={12} className={classes.tableTitleAndButton}>
          <Typography variant="h5" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">Historial de transacciones</Box>
          </Typography>
          <IconButton onClick={() => setShowTransactions(!showTransactions)}>
            {showTransactions ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Grid>
        {showTransactions && (
          <Grid container xs={12} className={classes.createTransactionButton}>
            <Can
              perform={WALLETS_SECTION_PERFORM}
              yes={() => (
                <Button
                  onClick={() => handleShowCreateTransactionDialog(walletData)}
                  startIcon={<AddCircleOutlineIcon />}
                  className={classes.actionButton}
                >
                  Agregar transacción
                </Button>
              )}
              no={() => null}
            />
          </Grid>
        )}
        {showTransactions && (
          <WalletTransactionsTable
            transactions={transactions}
            loading={loadingTransactions}
          />
        )}
      </Container>
    </>
  );
};

WalletItem.propTypes = {
  walletData: PropTypes.shape({
    AccountActivities: PropTypes.arrayOf(
      PropTypes.shape({
        activity: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
      }).isRequired
    ),
    AccountProfile: PropTypes.shape({
      balance: PropTypes.number,
      id: PropTypes.number,
      status: PropTypes.string,
    }),
    balance: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
  handleShowCreateTransactionDialog: PropTypes.func.isRequired,
  handleShowRequestStatusDialog: PropTypes.func.isRequired,
};

export default WalletItem;
