import { styled, makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
      marginTop: '24px',
    },
    tableContainer: {
      width: '100%',
      overflow: 'initial',
    },
    linkText: {
      fontWeight: 'bold',
      fontSize: 13,
    },
    tabLabel: {
      fontSize: '16px',
      fontWeight: 700,
    },
});

const TableStickHead = styled(TableCell)({
    top: 55,
    padding: '6px 16px',
  });

const BodyCell = styled(TableCell)({
    padding: '6px 16px',
});

export {
  useStyles,
  TableStickHead,
  BodyCell,
};