import React from 'react';
import {
  Drawer,
  Typography,
  Box,
  Button,
  IconButton,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import PartialPaymentsTable from '../../../../../../components/tables/PartialPaymentsTable';
import PartialPaymentDialog from '../../../../../../components/dialogs/PartialPaymentDialog';
import {
  Close as CloseIcon,
  Money as MoneyIcon,
} from '../../../../../../components/icons';
import useStyles from './styles';
import usePartialPaymentsDrawer from '../hooks/usePartialPaymentsDrawer';
import { useGetCountry } from '../../../../../../funds/adapters/store';
import DataListRow from './DataListRow';

const PAY = 'PAY';
const HISTORY = 'HISTORY';

const PartialPaymentsDrawer = ({
  isOpen,
  handleCloseDrawer,
  selectedInvoice,
}) => {
  const classes = useStyles();
  const country = useGetCountry();
  const {
    dataList,
    dataPayList,
    tabList,
    showAddPartialPaymentDialog,
    showUpdatePartialPaymentDialog,
    partialPaymentSelected,
    stateTab,
    a11yProps,
    setStateTab,
    setShowUpdatePartialPaymentDialog,
    setAddShowPartialPaymentDialog,
    handleSelectPartialPayment,
    handleAddPartialPayment,
    handleUpdatePartialPayment,
    updatePartialPaymentIsLoading,
    updatePartialPaymentError,
    addPartialPaymentIsLoading,
    addPartialPaymentError,
    fetchPartialPaymentsIsLoading,
    calculateDebtPartialPaymentIsLoading,
    partialPaymentCalculations,
    partialPayments,
    locales,
  } = usePartialPaymentsDrawer({
    selectedInvoice,
    country,
    isOpen,
  });

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleCloseDrawer}
        classes={{
          paper: classes.rootDrawer,
        }}
      >
        <Box className={classes.rootContainer}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.titleContainer}
          >
            <Box display="flex" alignItems="center">
              <MoneyIcon />
              <Typography
                variant="h5"
                color="textPrimary"
                component="div"
                className={classes.title}
              >
                <Box fontWeight="fontWeightBold">{locales.description}</Box>
              </Typography>
            </Box>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider className={classes.divider} />

          <Box
            display="flex"
            flexDirection="column"
            className={classes.dataList}
          >
            {dataList.map(({ label, value }) => (
              <DataListRow label={label} value={value} key={label} />
            ))}
          </Box>

          <Tabs
            value={stateTab}
            onChange={(e, valueTab) => setStateTab(valueTab)}
            className={classes.tab}
            TabIndicatorProps={{
              style: {
                height: '3px',
                borderRadius: '10px',
              },
            }}
          >
            {tabList.map(tab => {
              const { label, value } = tab;
              return (
                <Tab
                  label={label}
                  value={value}
                  key={label}
                  className={classes.headerTabsTab}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...a11yProps(value)}
                />
              );
            })}
          </Tabs>

          <Box
            role="tabpanel"
            hidden={stateTab !== PAY}
            id={`wrapped-tabpanel-${stateTab}`}
            aria-labelledby={`wrapped-tab-${stateTab}`}
          >
            {stateTab === PAY && (
              <Box display="flex" flexDirection="column">
                {dataPayList.map(({ label, value }) => (
                  <DataListRow
                    label={label}
                    value={value}
                    loading={calculateDebtPartialPaymentIsLoading}
                    key={label}
                  />
                ))}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.dataListLastRow}
                >
                  <Typography variant="h6" color="textPrimary" component="div">
                    <Box fontWeight="fontWeightBold">Total:</Box>
                  </Typography>
                  {calculateDebtPartialPaymentIsLoading ? (
                    <Skeleton variant="rect" width={150} height={20} />
                  ) : (
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      component="div"
                    >
                      <Box fontWeight="fontWeightBold">
                        <CountryFormatHelper
                          value={partialPaymentCalculations?.totalDebt}
                          countryId={country}
                          variant="currency"
                        />
                      </Box>
                    </Typography>
                  )}
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => setAddShowPartialPaymentDialog(true)}
                >
                  {locales.title}
                </Button>
              </Box>
            )}
          </Box>

          <Box
            role="tabpanel"
            hidden={stateTab !== HISTORY}
            id={`wrapped-tabpanel-${stateTab}`}
            aria-labelledby={`wrapped-tab-${stateTab}`}
            className={classes.dataList}
          >
            {stateTab === HISTORY && (
              <PartialPaymentsTable
                partialPayments={partialPayments}
                handleUpdatePartialPayment={handleSelectPartialPayment}
                isLoading={fetchPartialPaymentsIsLoading}
              />
            )}
          </Box>
        </Box>
      </Drawer>

      {showAddPartialPaymentDialog && (
        <PartialPaymentDialog
          open={showAddPartialPaymentDialog}
          handleCloseDialog={() => setAddShowPartialPaymentDialog(false)}
          handleSubmit={data => handleAddPartialPayment(data)}
          isLoading={addPartialPaymentIsLoading}
          error={addPartialPaymentError}
        />
      )}

      {showUpdatePartialPaymentDialog && (
        <PartialPaymentDialog
          open={showUpdatePartialPaymentDialog}
          handleCloseDialog={() => setShowUpdatePartialPaymentDialog(false)}
          selectedPartialPayment={partialPaymentSelected}
          handleSubmit={data => handleUpdatePartialPayment(data)}
          isLoading={updatePartialPaymentIsLoading}
          error={updatePartialPaymentError}
        />
      )}
    </>
  );
};

PartialPaymentsDrawer.defaultProps = {
  selectedInvoice: {
    id: 0,
    folio: '',
    payerName: '',
    expirationDate: '',
    orderId: 0,
  },
};

PartialPaymentsDrawer.propTypes = {
  selectedInvoice: PropTypes.shape({
    id: PropTypes.number,
    folio: PropTypes.string,
    payerName: PropTypes.string,
    expirationDate: PropTypes.string,
    orderId: PropTypes.number,
  }),
  handleCloseDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PartialPaymentsDrawer;
