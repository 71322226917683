import axios from 'axios';
import {
  FETCH_ENTITY_FEATURES_START,
  FETCH_ENTITY_FEATURES_SUCCESS,
  FETCH_ENTITY_FEATURES_FAILURE,
  CREATE_ENTITY_FEATURE_START,
  CREATE_ENTITY_FEATURE_SUCCESS,
  CREATE_ENTITY_FEATURE_FAILURE,
  UPDATE_ENTITY_FEATURE_START,
  UPDATE_ENTITY_FEATURE_SUCCESS,
  UPDATE_ENTITY_FEATURE_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';
import { getErrorCode } from '../helpers/handleErrors';

export const fetchEntityFeatures = (source, sourceId) => dispatch => {
  dispatch({ type: FETCH_ENTITY_FEATURES_START });
  axios
    .get(`/api/${source}/${sourceId}/entityfeature`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_ENTITY_FEATURES_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_ENTITY_FEATURES_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_ENTITY_FEATURES_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const createEntityFeature =
  (source, sourceId, featureId) => dispatch => {
    dispatch({ type: CREATE_ENTITY_FEATURE_START });
    axios
      .post(`/api/${source}/${sourceId}/entityfeature`, { featureId })
      .then(res => {
        const resData = res.data;

        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ENTITY_FEATURE_CREATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: CREATE_ENTITY_FEATURE_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_ENTITY_FEATURE_FAILURE,
          payload: { errorCode },
        });

        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CREATE_ENTITY_FEATURE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const updateEntityFeature =
  (source, sourceId, entityFeatureId, active) => dispatch => {
    dispatch({ type: UPDATE_ENTITY_FEATURE_START });
    axios
      .put(`/api/${source}/${sourceId}/entityfeature/${entityFeatureId}`, {
        active,
      })
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ENTITY_FEATURE_UPDATED, {
            variant: 'success',
          })
        );
        dispatch({
          type: UPDATE_ENTITY_FEATURE_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_ENTITY_FEATURE_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ENTITY_FEATURE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };
