import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  divider: {
    margin: '10px auto 15px',
  },
  w100: {
    width: '100%',
  },
  titleSection: {
    marginBottom: '15px',
  },
  btnBox: {
    marginTop: '30px',
  },
  inputBox: {
    '& p': {
      bottom: '-130%',
      left: '5px',
      position: 'absolute',
    },
  },
  container: {
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0 20px 30px',
    backgroundColor: 'white',
    '& p span': {
      float: 'right',
      color: '#312F37',
      textAlign: 'right',
    },
    '& p': {
      color: '#8D8A96',
    },
  },
  hyphen: {
    background: '#d0cdda',
    height: '2px',
    margin: 'auto auto 10px',
    width: '50%',
  },
}));
