import { useQuery } from 'react-query';
import fetchInvoicePendingRequirements from '../service/fetchInvoicePendingRequirements';

const useFetchInvoicePendingRequirements = (
  operationId: number,
  requirementId: number
) => {
  const { isLoading, isRefetching, data } = useQuery(
    ['fetchInvoicePendingRequirements', requirementId],
    () => fetchInvoicePendingRequirements(operationId, requirementId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 0,
      retry: false,
    }
  );
  return {
    isLoading: isLoading || isRefetching,
    invoicePendingRequirements: data || [],
  };
};

export default useFetchInvoicePendingRequirements;
