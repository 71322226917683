/* eslint-disable no-param-reassign */
import {
  FETCH_BANKS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
  FETCH_COMPANY_NAME_BY_RUT_START,
  FETCH_COMPANY_NAME_BY_RUT_SUCCESS,
  FETCH_COMPANY_NAME_BY_RUT_FAILURE,
  REGISTER_BANK_ACCOUNT_INPUT_CHANGE,
  RESET_BANK_ACCOUNT_DIALOG,
  BANK_ACCOUNT_RESET,
  FETCH_BANK_ACCOUNTS_BY_ID_START,
  FETCH_BANK_ACCOUNTS_BY_ID_SUCCESS,
  FETCH_BANK_ACCOUNTS_BY_ID_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT_START,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  REGISTER_BANK_ACCOUNT_START,
  REGISTER_BANK_ACCOUNT_SUCCESS,
  REGISTER_BANK_ACCOUNT_FAILURE,
  EDIT_BANK_ACCOUNT_START,
  EDIT_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT_FAILURE,
  SELECT_BANK_ACCOUNT,
} from '../actions/types';

const initialState = {
  loadingBanks: false,
  banks: [],
  banksError: '',
  disabledAccountHolder: true,
  bankId: '',
  accountType: '',
  accountNumber: '',
  accountHolder: '',
  accountRut: '',
  accountEmail: '',
  alias: '',
  nameByIdentifierIsLoading: false,
  loadingBankAccounts: false,
  bankAccounts: {},
  settingDefaultBankAccount: false,
  bankAccountSettedDefault: false,
  registeredBankAccount: false,
  registeringBankAccount: false,
  editingBankAccount: false,
  bankAccountEdited: false,
  bankAccountError: null,
};

const fetchBanksStart = state => {
  return {
    ...state,
    loadingBanks: true,
    banksError: false,
  };
};

const fetchBanksSuccess = (state, payload) => {
  return {
    ...state,
    loadingBanks: false,
    banks: payload.banks ?? payload,
  };
};

const fetchBanksFailure = (state, payload) => {
  return {
    ...state,
    loadingBanks: false,
    banksError: payload.errorCode,
  };
};

const fetchCompanyNameByRutStart = state => {
  return {
    ...state,
    nameByIdentifierIsLoading: true,
    disabledAccountHolder: true,
  };
};

const fetchCompanyNameByRutSuccess = (state, payload) => {
  const name = payload?.data?.name ?? payload?.name ?? '';
  return {
    ...state,
    nameByIdentifierIsLoading: false,
    accountHolder: name,
    disabledAccountHolder: name !== '',
  };
};

const fetchCompanyNameByRutFailure = state => {
  return {
    ...state,
    nameByIdentifierIsLoading: false,
    loadingSiiDataByRut: false,
    disabledAccountHolder: false,
  };
};

const registerBankAccountInputChange = (state, payload) => {
  const { field } = payload;
  let { value } = payload;
  if (field === 'accountNumber') {
    value = value.replace(/[^0-9]/g, '');
  }
  return {
    ...state,
    [field]: value,
  };
};

const resetBankAccountDialog = state => {
  return {
    ...state,
    bankId: '',
    accountType: '',
    accountNumber: '',
    accountHolder: '',
    accountRut: '',
    accountEmail: '',
    alias: '',
    companyName: '',
  };
};

const bankAccountReset = state => {
  return {
    ...state,
    accountHolder: '',
    editingBankAccount: false,
    bankAccountEdited: false,
  };
};

const fetchBankAccountsByIdStart = state => {
  return {
    ...state,
    loadingBankAccounts: true,
  };
};

const fetchBankAccountsByIdSuccess = (state, payload) => {
  const { data, entityId, entity } = payload;
  const { bankAccounts } = state;
  return {
    ...state,
    loadingBankAccounts: false,
    bankAccounts: { ...bankAccounts, [entity]: { [entityId]: [...data] } },
  };
};

const fetchBankAccountsByIdFailure = state => {
  return {
    ...state,
    loadingBankAccounts: false,
  };
};

const setDefaultBankAccountStart = state => {
  return {
    ...state,
    settingDefaultBankAccount: true,
    bankAccountSettedDefault: false,
    bankAccountError: null,
  };
};

const setDefaultBankAccountSuccess = (state, payload) => {
  const { bankAccounts } = state;
  const { entity, entityId, bankAccountId } = payload;

  const updatedBankAccounts = bankAccounts[entity][entityId].map(
    bankAccount => {
      bankAccount.isDefault = false;
      if (bankAccount.id === bankAccountId) {
        bankAccount.isDefault = true;
      }

      return bankAccount;
    }
  );

  return {
    ...state,
    bankAccounts: updatedBankAccounts,
    settingDefaultBankAccount: false,
    bankAccountSettedDefault: true,
    setDefaultAccountError: false,
  };
};

const setDefaultBankAccountFailure = (state, payload) => {
  return {
    ...state,
    settingDefaultBankAccount: false,
    bankAccountSettedDefault: false,
    bankAccountError: payload.errorCode,
  };
};

const deleteBankAccountStart = state => {
  return {
    ...state,
    errors: '',
    deletingBankAccount: true,
    deletedBankAccount: false,
  };
};

const deleteBankAccountSuccess = (state, payload) => {
  return {
    ...state,
    deletingBankAccount: false,
    deletedBankAccount: true,
    fund: payload.fundData,
  };
};

const deleteBankAccountFailure = (state, payload) => {
  return {
    ...state,
    deletedBankAccount: false,
    deletingBankAccount: false,
    errors: payload.errorCode,
  };
};

const registerBankAccountStart = state => {
  return {
    ...state,
    registeredBankAccount: false,
    registeringBankAccount: true,
  };
};

const registerBankAccountSuccess = (state, payload) => {
  if (!payload?.fundData) {
    const { bankAccounts } = state;
    const { entity, entityId, ...rest } = payload;
    const updatedBankaccount = {
      ...bankAccounts,
      [entity]: {
        ...bankAccounts[entity],
        [entityId]: [...bankAccounts[entity][entityId], { ...rest }],
      },
    };
    return {
      ...state,
      registeringBankAccount: false,
      registeredBankAccount: true,
      bankAccounts: updatedBankaccount,
    };
  }
  return {
    ...state,
    registeringBankAccount: false,
    registeredBankAccount: true,
  };
};

const registeredBankAccountFailure = (state, payload) => {
  return {
    ...state,
    registeringBankAccount: false,
    registeredBankAccount: false,
    errors: payload.errorCode,
  };
};

const editBankAccountStart = state => {
  return {
    ...state,
    editingBankAccount: true,
    bankAccountEdited: false,
    bankAccountError: null,
  };
};

const editBankAccountSuccess = state => {
  return {
    ...state,
    editingBankAccount: false,
    bankAccountEdited: true,
  };
};

const editBankAccountFailure = (state, payload) => {
  return {
    ...state,
    editingBankAccount: false,
    bankAccountEdited: false,
    bankAccountError: payload.errorCode,
  };
};

const selectBankAccount = (state, payload) => {
  return {
    ...state,
    selectedBankAccount: payload.bankAccount,
  };
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_BANKS_START:
      return fetchBanksStart(state);
    case FETCH_BANKS_SUCCESS:
      return fetchBanksSuccess(state, payload);
    case FETCH_BANKS_FAILURE:
      return fetchBanksFailure(state, payload);
    case FETCH_COMPANY_NAME_BY_RUT_START:
      return fetchCompanyNameByRutStart(state);
    case FETCH_COMPANY_NAME_BY_RUT_SUCCESS:
      return fetchCompanyNameByRutSuccess(state, payload);
    case FETCH_COMPANY_NAME_BY_RUT_FAILURE:
      return fetchCompanyNameByRutFailure(state);
    case REGISTER_BANK_ACCOUNT_INPUT_CHANGE:
      return registerBankAccountInputChange(state, payload);
    case RESET_BANK_ACCOUNT_DIALOG:
      return resetBankAccountDialog(state);
    case BANK_ACCOUNT_RESET:
      return bankAccountReset(state);
    case FETCH_BANK_ACCOUNTS_BY_ID_START:
      return fetchBankAccountsByIdStart(state);
    case FETCH_BANK_ACCOUNTS_BY_ID_SUCCESS:
      return fetchBankAccountsByIdSuccess(state, payload);
    case FETCH_BANK_ACCOUNTS_BY_ID_FAILURE:
      return fetchBankAccountsByIdFailure(state);
    case SET_DEFAULT_BANK_ACCOUNT_START:
      return setDefaultBankAccountStart(state);
    case SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
      return setDefaultBankAccountSuccess(state, payload);
    case SET_DEFAULT_BANK_ACCOUNT_FAILURE:
      return setDefaultBankAccountFailure(state, payload);
    case DELETE_BANK_ACCOUNT_START:
      return deleteBankAccountStart(state);
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return deleteBankAccountSuccess(state, payload);
    case DELETE_BANK_ACCOUNT_FAILURE:
      return deleteBankAccountFailure(state, payload);
    case REGISTER_BANK_ACCOUNT_START:
      return registerBankAccountStart(state);
    case REGISTER_BANK_ACCOUNT_SUCCESS:
      return registerBankAccountSuccess(state, payload);
    case REGISTER_BANK_ACCOUNT_FAILURE:
      return registeredBankAccountFailure(state, payload);
    case EDIT_BANK_ACCOUNT_START:
      return editBankAccountStart(state);
    case EDIT_BANK_ACCOUNT_SUCCESS:
      return editBankAccountSuccess(state);
    case EDIT_BANK_ACCOUNT_FAILURE:
      return editBankAccountFailure(state, payload);
    case SELECT_BANK_ACCOUNT:
      return selectBankAccount(state, payload);
    default:
      return state;
  }
}
