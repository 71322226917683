import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Typography,
  Grid,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import {
  GLOBAL_REASONS,
  DATE_FORMAT,
  STATUS_UNVERIFIED,
  STATUS_ACTIVE,
  STATUS_COMPLETE,
  STATUS_PAID,
} from '../../../../../../helpers/Constants';
import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import verifyDecimals from '../../../../../../helpers/validation/decimals';
import CheckButton from '../../../../../../components/elements/CheckButton';
import {
  dateWithoutFormatter,
  setHourString,
} from '../../../../../../helpers/DateUtils';
import AlertForm from '../../../../../../components/elements/AlertForm';
import { useUpdateInvoicesDialog } from '../../../../hooks';
import {
  useOrder,
  useOrderSummary,
  useFetchInvoices,
} from '../../../../infrastructure/store';
import { STATUSES_PAYMENT } from '../../../../../commons/constants/status';

const useStyles = makeStyles(() => ({
  dateWithCheckbox: {
    marginBottom: 0,
  },
  spacingLeft: {
    marginLeft: 15,
  },
}));

const InvoicesUpdateDialog = ({
  open,
  handleCloseDialog,
  type,
  handleSubmit,
  showBaseRateInput,
  invoices,
  warning,
  isLoading,
  wasUpdated,
  statusOrder,
  invoicesBulkError,
}) => {
  const { id: orderId } = useParams();
  const { refetch: refetChOrder } = useOrder(orderId);
  const { refetch: refetchOrderSummary } = useOrderSummary(orderId);
  const { refetch: refetchInvoices } = useFetchInvoices(orderId);
  const classes = useStyles();
  const {
    handleStatusChange,
    handleCheck,
    showSelectReason,
    showVerificationStatus,
    setExpirationDate,
    setFundExpirationDate,
    setPaymentConfirmed,
    setPaymentDate,
    setDefaultDate,
    setStatusReason,
    setStatusVerificationReason,
    setBaseRate,
    expirationDate,
    showDefaultDateInput,
    fundExpirationDate,
    paymentConfirmed,
    paymentDate,
    defaultDate,
    status,
    statusReason,
    verificationStatus,
    verificationStatusReason,
    baseRate,
    checked,
    setChecked,
    country,
    countryFeatures,
    handleDisabledDates,
    handleChangeCheckbox,
    checkPayment,
    setNotCalculateSurplus,
    notCalculateSurplus,
    bulkError,
    setBulkError,
  } = useUpdateInvoicesDialog();

  const handleFormSubmit = () => {
    const data = {
      expirationDate,
      expirationDate: expirationDate,
      baseRate,
      fundExpirationDate,
      status,
      statusReason,
      verificationStatus,
      verificationReason: verificationStatusReason,
      defaultDate,
    };

    if (checkPayment.checkPaymentDate) {
      setPaymentDate(null);
    } else {
      data.paymentDate = paymentDate;
    }

    if (checkPayment.checkPaymentConfirmed) {
      setPaymentConfirmed(null);
    } else {
      data.paymentConfirmed = paymentConfirmed;
    }
    handleSubmit({ item: invoices, type, data, notCalculateSurplus, country });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('maxDecimals', value => {
      if (value) {
        verifyDecimals(value);
      }
      return true;
    });
  });

  useEffect(() => {
    if (open) {
      setBulkError(null);
    }
  }, [open]);

  useEffect(() => {
    setBulkError(invoicesBulkError);
  }, [invoicesBulkError]);

  useEffect(() => {
    if (wasUpdated) {
      setChecked(false);
      refetchOrderSummary();
      refetChOrder();
      refetchInvoices();
      handleCloseDialog();
    }
  }, [wasUpdated]);

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Edición masiva de facturas"
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha de vencimiento PYME"
              id="expirationDate"
              name="expirationDate"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(expirationDate)}
              onChange={date => setExpirationDate(setHourString(date))}
              shouldDisableDate={date => handleDisabledDates(date)}
              disabled={statusOrder === STATUS_ACTIVE}
              minDateMessage="Fecha inválida"
              autoOk
            />
            {countryFeatures.orderActions.showLinkedFund && (
              <DatePicker
                inputVariant="outlined"
                fullWidth
                label="Fecha de vencimiento FONDO"
                name="fundExpirationDate"
                format={DATE_FORMAT}
                value={dateWithoutFormatter(fundExpirationDate)}
                onChange={date => setFundExpirationDate(setHourString(date))}
                disabled={checked}
                minDateMessage="Fecha inválida"
                autoOk
              />
            )}
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <FormControlLabel
                labelPlacement="start"
                disabled={checked}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checkPayment.checkPaymentConfirmed}
                    onChange={handleChangeCheckbox}
                    name="checkPaymentConfirmed"
                    color="primary"
                  />
                }
                label={<Typography variant="caption">Borrar fecha</Typography>}
              />
            </Box>
            <DatePicker
              className={classes.dateWithCheckbox}
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de pago real"
              name="paymentDate"
              format={DATE_FORMAT}
              value={dateWithoutFormatter(paymentDate)}
              onChange={date => setPaymentDate(setHourString(date))}
              disabled={checked || checkPayment.checkPaymentDate}
              minDateMessage="Fecha inválida"
              maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
              disableFuture
            />
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <FormControlLabel
                labelPlacement="start"
                disabled={checked}
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={checkPayment.checkPaymentDate}
                    onChange={handleChangeCheckbox}
                    name="checkPaymentDate"
                    color="primary"
                  />
                }
                label={<Typography variant="caption">Borrar fecha</Typography>}
              />
            </Box>
          </MuiPickersUtilsProvider>
          <>
            {countryFeatures.orderActions.changeStatusInvoice && (
              <>
                <SelectValidator
                  variant="outlined"
                  fullWidth
                  id="operation-status-select"
                  value={status}
                  onChange={e => handleStatusChange(e.target.value)}
                  disabled={checked}
                  label="Estado"
                  dontvalidate="false"
                >
                  {STATUSES_PAYMENT[type].map(s => (
                    <MenuItem key={s.key} value={s.key}>
                      <Typography variant="body1">{s.label}</Typography>
                    </MenuItem>
                  ))}
                </SelectValidator>

                {[STATUS_COMPLETE, STATUS_PAID].includes(status) && (
                  <Box className={classes.spacingLeft}>
                    <Typography variant="body1">
                      <Box fontWeight="fontWeightBold">Excepción</Box>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notCalculateSurplus}
                          onChange={() =>
                            setNotCalculateSurplus(!notCalculateSurplus)
                          }
                          disabled={checked}
                          name="notCalculateSurplus"
                          color="primary"
                        />
                      }
                      label="No calcular los excedentes"
                    />
                  </Box>
                )}
              </>
            )}

            {showDefaultDateInput && (
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  disabled={checked}
                  label="Fecha de estado en default"
                  name="defaultDate"
                  format={DATE_FORMAT}
                  value={dateWithoutFormatter(defaultDate)}
                  onChange={date => setDefaultDate(date)}
                  autoOk
                  inputVariant="outlined"
                  TextFieldComponent={props => (
                    <TextValidator
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            )}
            {showSelectReason(type) && (
              <SelectValidator
                variant="outlined"
                fullWidth
                name="operationReason"
                id="operation-reason-select"
                value={statusReason}
                onChange={e => setStatusReason(e.target.value)}
                disabled={checked}
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
              >
                {GLOBAL_REASONS.filter(
                  r =>
                    r.country.includes(country) &&
                    r.type === `INVOICE_${status}`
                ).map(s => (
                  <MenuItem key={s.value} value={s.value}>
                    <Typography variant="body1">{s.label}</Typography>
                  </MenuItem>
                ))}
              </SelectValidator>
            )}
            {showVerificationStatus &&
              verificationStatus !== STATUS_UNVERIFIED && (
                <SelectValidator
                  variant="outlined"
                  name="operationReason"
                  id="operation-reason-select"
                  value={verificationStatusReason}
                  disabled={checked}
                  onChange={e => setStatusVerificationReason(e.target.value)}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  label="Razón"
                  fullWidth
                >
                  {GLOBAL_REASONS.filter(
                    r =>
                      r.country.includes(country) &&
                      r.type === `INVOICE_${verificationStatus}`
                  ).map(s => (
                    <MenuItem key={s.value} value={s.value}>
                      {s.label}
                    </MenuItem>
                  ))}
                </SelectValidator>
              )}
          </>
          {showBaseRateInput && (
            <TextValidator
              variant="outlined"
              fullWidth
              id="baseRate-invoice"
              label="Tasa base (%)"
              InputProps={{ step: 0.0001 }}
              type="number"
              name="baseRate"
              disabled={checked}
              value={baseRate}
              aria-describedby="baseRate"
              onChange={e => setBaseRate(e.target.value)}
              validators={['isPositive', 'maxDecimals']}
              errorMessages={[
                'Valor debe ser mayor o igual a 0',
                'Valor puede contener 4 decimales',
              ]}
            />
          )}

          {(checkPayment.checkPaymentDate ||
            checkPayment.checkPaymentConfirmed) && (
            <AlertForm
              variant="info"
              message="Al guardar se borrarán las fechas de pago indicadas y los campos quedarán vacios."
            />
          )}
          {warning && !isLoading && (
            <AlertForm variant="info" message={warning} />
          )}

          {bulkError && <AlertForm variant="error" message={bulkError} />}

          <Grid item xs={12}>
            <CheckButton
              check={checked}
              handleCheck={handleCheck}
              labelButton="Guardar"
              loading={isLoading}
            />
          </Grid>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

InvoicesUpdateDialog.defaultProps = {
  showBaseRateInput: false,
  invoices: [],
  warning: '',
  isLoading: false,
  wasUpdated: false,
  invoicesBulkError: false,
};

InvoicesUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showBaseRateInput: PropTypes.bool,
  invoices: PropTypes.arrayOf(Object),
  warning: PropTypes.string,
  isLoading: PropTypes.bool,
  wasUpdated: PropTypes.bool,
  statusOrder: PropTypes.string.isRequired,
  invoicesBulkError: PropTypes.bool,
};

export default InvoicesUpdateDialog;
