/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Paper, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import {
  GRAY_PURPLE_COLOR,
  GRAY_BORDER_COLOR,
  SECOND_WHITE_COLOR,
} from '../../helpers/Constants';
import { palette } from '../../theme/palette';
import CountryFormatHelper from './CountryFormatHelper';
import { dateFormatter } from '../../helpers/DateUtils';

const useStyles = makeStyles({
  containerPaperTGR: {
    display: 'flex',
    background: SECOND_WHITE_COLOR,
  },
  paper: {
    marginTop: 16,
    marginLeft: 28,
    width: '100%',
    background: '#F4F3FA',
    color: GRAY_PURPLE_COLOR,
  },
  headerContainerTabs: {
    borderBottom: `1px solid ${GRAY_BORDER_COLOR}`,
    marginLeft: 28,
    backgroundColor: SECOND_WHITE_COLOR,
  },
  headerTabsTab: {
    minWidth: '55px',
    padding: '0px',
    marginRight: '40px',
    fontSize: '12px',
    color: GRAY_PURPLE_COLOR,
    textTransform: 'none',
  },
});

const useStylesComponent = {
  cuotasRowStyles: {
    backgroundColor: SECOND_WHITE_COLOR,
  },
  cuotasHeaderStyles: {
    backgroundColor: SECOND_WHITE_COLOR,
  },
  folioHeaderStyles: {
    backgroundColor: SECOND_WHITE_COLOR,
  },
  folioRowStyles: {
    backgroundColor: SECOND_WHITE_COLOR,
  },
  tabIndicatorPropsStyles: {
    backgroundColor: palette.primary.main,
    height: '3px',
    borderRadius: '10px',
  },
  folioGeneralStyles: {
    overflow: 'hidden',
  },
  cuotasGeneralStyles: {
    overflow: 'hidden',
  },
};

const TGRAgreementDetails = ({
  agreement: { TGRDebtsAgreements, TGRAgreementPaymentFees },
}) => {
  const FOLIO = 'Folio';
  const CUOTAS = 'Cuotas';
  const classes = useStyles();
  const [operationSelected, setOperationSelected] = useState(FOLIO);
  const handleTabChange = (_, activeTab) => {
    setOperationSelected(activeTab);
  };

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const addIndexArrayTGR = arrayTGR => {
    arrayTGR.forEach((value, indexArray) => {
      // eslint-disable-next-line no-param-reassign
      value.index = indexArray;
    });
    return arrayTGR;
  };

  if (!TGRDebtsAgreements?.[0]?.index || !TGRAgreementPaymentFees?.[0]?.index) {
    // eslint-disable-next-line no-param-reassign
    TGRDebtsAgreements = addIndexArrayTGR(TGRDebtsAgreements);
    // eslint-disable-next-line no-param-reassign
    TGRAgreementPaymentFees = addIndexArrayTGR(TGRAgreementPaymentFees);
  }

  const buildContentTableFolio = [
    {
      title: 'N°',
      field: 'index',
      type: 'numeric',
      width: 10,
      defaultSort: 'asc',
      align: 'left',
      render: rowData => {
        const { index } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {index}
          </Typography>
        );
      },
    },
    {
      title: 'Form',
      field: 'form',
      type: 'numeric',
      align: 'left',
      width: 10,
      render: rowData => {
        const { form } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {form}
          </Typography>
        );
      },
    },
    {
      title: 'Folio',
      field: 'folio',
      type: 'string',
      defaultSort: 'asc',
      width: 10,
      render: rowData => {
        const { folio } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {folio}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha vencimiento',
      field: 'dueDate',
      type: 'date',
      width: 10,
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(rowData.dueDate)}
          </Typography>
        );
      },
    },
    {
      title: 'Saldo ($)',
      field: 'balance',
      type: 'numeric',
      align: 'left',
      width: 10,
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData.balance}
              variant="currency"
              countryId="CL"
            />
          </Typography>
        );
      },
    },
  ];

  const buildContentTableCuotas = [
    {
      title: 'N°',
      field: 'index',
      type: 'numeric',
      defaultSort: 'asc',
      align: 'left',
      width: 50,
      render: rowData => {
        const { index } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {index}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha vencimiento',
      field: 'dueDate',
      type: 'date',
      defaultSort: 'asc',
      width: 100,
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(rowData.dueDate)}
          </Typography>
        );
      },
    },
    {
      title: 'Monto ($)',
      field: 'amount',
      type: 'numeric',
      align: 'left',
      width: 150,
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData.amount}
              variant="currency"
              countryId="CL"
            />
          </Typography>
        );
      },
    },
    {
      title: 'Estado Pago',
      field: 'paymentState',
      type: 'string',
      width: 50,
      align: 'left',
      render: rowData => {
        const { paymentState } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {paymentState ? 'Si' : 'No'}
          </Typography>
        );
      },
    },
  ];

  return (
    <Grid container xs={12} style={{ backgroundColor: SECOND_WHITE_COLOR }}>
      <Grid container direction="row">
        <Grid item xs={12} className={classes.headerContainerTabs}>
          <Tabs
            value={operationSelected}
            onChange={(e, v) => handleTabChange(e, v)}
            TabIndicatorProps={{
              style: useStylesComponent.tabIndicatorPropsStyles,
            }}
          >
            <Tab
              label="Folio"
              value={FOLIO}
              className={classes.headerTabsTab}
              {...a11yProps(0)}
            />
            <Tab
              label="Cuotas"
              value={CUOTAS}
              className={classes.headerTabsTab}
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} className={classes.containerPaperTGR}>
          {operationSelected === FOLIO && (
            <MaterialTable
              columns={buildContentTableFolio}
              data={TGRDebtsAgreements}
              style={useStylesComponent.folioGeneralStyles}
              components={{
                Container: props => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Paper elevation={0} className={classes.paper} {...props} />
                ),
              }}
              options={{
                toolbar: false,
                detailPanelType: 'single',
                actionsColumnIndex: -1,
                pageSize: 20,
                maxBodyHeight: 440,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 50],
                headerStyle: useStylesComponent.folioHeaderStyles,
                rowStyle: useStylesComponent.folioRowStyles,
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'Folios',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
                body: {
                  emptyDataSourceMessage: 'No hay folios que mostrar',
                },
              }}
            />
          )}
          {operationSelected === CUOTAS && (
            <MaterialTable
              columns={buildContentTableCuotas}
              data={TGRAgreementPaymentFees}
              components={{
                Container: props => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Paper elevation={0} className={classes.paper} {...props} />
                ),
              }}
              style={useStylesComponent.cuotasGeneralStyles}
              options={{
                toolbar: false,
                detailPanelType: 'single',
                actionsColumnIndex: -1,
                pageSize: 20,
                pageSizeOptions: [10, 20, 50],
                headerStyle: useStylesComponent.cuotasHeaderStyles,
                rowStyle: useStylesComponent.cuotasRowStyles,
                maxBodyHeight: 440,
                emptyRowsWhenPaging: false,
              }}
              localization={{
                pagination: {
                  labelRowsSelect: 'Cuotas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                },
                body: {
                  emptyDataSourceMessage: 'No hay cuotas que mostrar',
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

TGRAgreementDetails.propTypes = {
  agreement: PropTypes.objectOf(Object).isRequired,
};
export default TGRAgreementDetails;
