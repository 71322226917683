export const getInvoicesUseCase = ({
  invoices,
  payrollSelector,
  receiverId,
}) => {
  const receiverSelected = payrollSelector?.[receiverId];

  return invoices?.map(invoice => {
    if (!receiverSelected) {
      return {
        ...invoice,
        tableData: {
          ...invoice.tableData,
          checked: false,
        },
      };
    }
    if (receiverSelected?.selected) {
      return {
        ...invoice,
        tableData: { ...invoice.tableData, checked: true },
      };
    }

    if (!receiverSelected?.selected) {
      return {
        ...invoice,
        tableData: {
          ...invoice.tableData,
          checked: !!receiverSelected.value.find(
            invoiceId => invoiceId === invoice.id
          ),
        },
      };
    }

    return invoice;
  });
};
