import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import invoicesBulkStatusUpdateService from '../services/invoicesBulkStatusUpdateService';

export const useInvoicesBulkStatusUpdate = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    data,
    error,
    mutate: uploadFile,
    reset: resetDataAndErrors,
  } = useMutation(
    csvFile => {
      return invoicesBulkStatusUpdateService(csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(
              t13s.NOTIFICATION.INVOICES_BULK_UPDATE_STATUS_SUCCESS,
              {
                variant: 'success',
              }
            )
          );
        }
      },
    }
  );

  const errorLines = data?.errorLines;
  const hasErrors = errorLines || isError;
  const serverError = error || null;

  return {
    isLoading,
    hasErrors,
    errorLines,
    serverError,
    uploadFile,
    resetDataAndErrors,
  };
};

export default useInvoicesBulkStatusUpdate;
