import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Menu,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HistoryIcon from '@material-ui/icons/History';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Edit as EditIcon } from './icons';
import {
  fetchAgreementsGroups,
  fetchAgreements,
  registerAgreement,
  resetAgreementFormDialog,
  updateAgreementById,
  updateSelectedAgreement,
} from '../actions/agreementActions';
import { STATUS_ACTIVE, STATUS_PAUSED } from '../helpers/Constants';
import { dateFormatter } from '../helpers/DateUtils';
import {
  AGREEMENTS_ADD_PERFORM,
  AGREEMENTS_EDIT_PERFORM,
} from '../helpers/performsType';
import Can from './Can';
import AgreementFormDialog from './dialogs/AgreementFormDialog';
import AgreementContactSection from './elements/AgreementContactSection';
import CountryFormatHelper from './elements/CountryFormatHelper';
import PaginationMaterialTable from './elements/PaginationMaterialTable';
import Panel from './elements/Panel';
import StatusBadge from './elements/StatusBadge';
import { settings } from '../config/settings/index';
import { t13s } from '../translationKeys';
import SearchBar from './elements/SearchBar';
import MenuItem from './elements/MenuItem';
import { CONTEXT_TYPES } from '../helpers/userLogsUtils';
import HistoryLogsDialog from './dialogs/HistoryLogsDialog';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
  },
  paperContainer: {
    padding: 20,
  },
  roundedPaper: {
    borderRadius: '14px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  statusRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

const HomeAgreements = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [showAgreementFormDialog, setShowAgreementFormDialog] = useState(false);
  const [showHistoryLogDialog, setShowHistoryLogDialog] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [field, setField] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    loadingAgreements,
    savingAgreement,
    savedAgreement,
    agreementError,
    agreements,
    pagination,
    agreementsGroups,
    selectedAgreement,
  } = useSelector(state => state.agreement);

  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  useEffect(() => {
    dispatch(fetchAgreementsGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (savedAgreement) {
      dispatch(fetchAgreements({ page, limit }));
      dispatch(fetchAgreementsGroups());
      setShowAgreementFormDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAgreement]);

  useEffect(() => {
    dispatch(fetchAgreements({ page, limit, searchInput, field }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, searchInput]);

  const handleChangePage = (page, limit) => {
    setLimit(limit);
    setPage(page);
  };

  const changeSearchInput = ({ value, field }) => {
    setField(field);
    setSearchInput(value);
    setPage(1);
  };

  const handleShowAgreementFormDialog = (editing, rowData = null) => {
    dispatch(updateSelectedAgreement(rowData));
    setEditOrder(editing);
    setShowAgreementFormDialog(true);
    setAnchorEl(null);
  };

  const handleSubmitNewAgreement = agreementData => {
    const findAgreementGroupId = agreementsGroups?.find(
      e => e.name === agreementData?.AgreementsGroup?.name
    )?.id;
    const formatedData = {
      ...agreementData,
      active: agreementData.status === STATUS_ACTIVE,
      flexible: agreementData.autoRate || false,
      agreementGroupName: agreementData.AgreementsGroup?.name,
      ...(findAgreementGroupId && {
        agreementGroupId: findAgreementGroupId,
      }),
    };
    delete formatedData.status;
    delete formatedData.autoRate;
    delete formatedData.id;
    delete formatedData.AgreementsGroup;
    dispatch(registerAgreement(formatedData));
  };

  const handleSubmitUpdatedAgreement = agreementData => {
    const findAgreementGroupId = agreementsGroups?.find(
      e => e.name === agreementData?.AgreementsGroup?.name
    )?.id;
    const formatedData = {
      ...agreementData,
      active: agreementData.status === STATUS_ACTIVE,
      flexible: agreementData.autoRate,
      agreementGroupName: agreementData.AgreementsGroup?.name,
      ...(findAgreementGroupId && {
        agreementGroupId: findAgreementGroupId,
      }),
    };
    delete formatedData.status;
    delete formatedData.autoRate;
    delete formatedData.AgreementsGroup;
    dispatch(updateAgreementById(formatedData));
  };

  const handleCloseAgreementFormDialog = () => {
    dispatch(resetAgreementFormDialog());
    dispatch(updateSelectedAgreement(undefined));
    setShowAgreementFormDialog(false);
  };

  const handleShowHistoryDialog = () => {
    setShowHistoryLogDialog(true);
    setAnchorEl(null);
  };

  const handleCloseHistoryLogDialog = () => {
    setShowHistoryLogDialog(false);
    setAnchorEl(null);
  };

  const columns = [
    {
      title: 'Nombre pagador',
      field: 'payerName',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {rowData.payerName}
          </Typography>
        );
      },
    },
    {
      title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador`,
      render: rowData => {
        const payerName = !isApiGlobal
          ? `${rowData.payerRut}-${rowData.payerRutDv}`
          : rowData.payerIdentifier;
        return (
          <Typography variant="body1" color="textPrimary">
            {payerName}
          </Typography>
        );
      },
    },
    {
      title: 'Grupo convenio',
      render: rowData => {
        const agreementGroupId = !isApiGlobal
          ? rowData.agreementsGroupId
          : rowData.agreementGroupId;
        const agreementGroupName = agreementsGroups?.find(
          e => e.id === agreementGroupId
        );
        return (
          <Typography variant="body1" color="textPrimary">
            {`${agreementGroupName?.name || 'No tiene grupo asignado'}`}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha inicio',
      field: 'startDate',
      name: 'startDate',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {dateFormatter(rowData.startDate)}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha término',
      field: 'endDate',
      name: 'endDate',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {dateFormatter(rowData.endDate)}
          </Typography>
        );
      },
    },
    {
      title: 'Tasa',
      name: 'rate',
      field: 'rate',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.rate}
              variant="percentage"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: '% Anticipo',
      name: 'advancePercentage',
      field: 'advancePercentage',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.advancePercentage}
              variant="percentage"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Costo fijo de operación',
      field: 'operationCost',
      name: 'operationCost',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={rowData.operationCost}
              countryId={country}
              variant="currency"
            />
          </Typography>
        );
      },
    },
    {
      title: 'Spread inicial ',
      field: 'initialSpread',
      name: 'initialSpread',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.initialSpread}
              variant="percentage"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Estado',
      field: 'status',
      name: 'status',
      type: 'string',
      lookup: {
        [STATUS_ACTIVE]: 'Activo',
        [STATUS_PAUSED]: 'Pausado',
      },
      render: rowData => {
        const agreementStatus = !isApiGlobal
          ? rowData?.status === STATUS_ACTIVE
          : rowData?.active;
        const selectStatus = agreementStatus ? STATUS_ACTIVE : STATUS_PAUSED;

        return (
          <Grid container direction="row">
            <Grid item xs={12} className={classes.statusRow}>
              <Can
                perform={AGREEMENTS_EDIT_PERFORM}
                yes={() => (
                  <StatusBadge
                    status={selectStatus}
                    handleClick={() =>
                      handleShowAgreementFormDialog(true, rowData)
                    }
                  />
                )}
                no={() => <StatusBadge status={selectStatus} />}
              />
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const handleCloseMenu = event => {
    event.persist();
    setAnchorEl(null);
    dispatch(updateSelectedAgreement(undefined));
  };

  const handleClickMenu = (event, agreement) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    dispatch(updateSelectedAgreement(agreement));
  };

  const actions = [
    {
      icon: () => (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
      ),
      tooltip: 'Más opciones',
      position: 'row',
      onClick: (event, rowData) => handleClickMenu(event, rowData),
    },
  ];

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      {showAgreementFormDialog && (
        <AgreementFormDialog
          open={showAgreementFormDialog}
          saving={savingAgreement}
          error={agreementError}
          editar={editOrder}
          country={country}
          handleCloseDialog={handleCloseAgreementFormDialog}
          selectedAgreement={selectedAgreement}
          agreementsGroups={agreementsGroups}
          handleSubmitForm={agreementData =>
            !selectedAgreement
              ? handleSubmitNewAgreement(agreementData)
              : handleSubmitUpdatedAgreement(agreementData)
          }
        />
      )}

      <Can
        perform={AGREEMENTS_ADD_PERFORM}
        yes={() => (
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              variant="text"
              onClick={() => handleShowAgreementFormDialog(false)}
              startIcon={<AddCircleIcon />}
              color="primary"
            >
              Nuevo convenio
            </Button>
          </Grid>
        )}
      />
      <Panel
        title="Lista de convenios"
        contentPadding="zero"
        actions={
          <SearchBar
            placeholder="Buscar convenio..."
            handleChangeInput={value => changeSearchInput(value)}
            fields={[
              { title: 'Nombre Pagador', field: 'payerName' },
              {
                title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} del Pagador`,
                field: 'payerIdentifier',
              },
              { title: 'Grupo Convenio', field: '`AgreementGroup`.`name`' },
            ]}
            selectedOption={{
              title: 'Nombre Pagador',
              field: 'payerName',
            }}
          />
        }
      >
        {showHistoryLogDialog && selectedAgreement?.id && (
          <HistoryLogsDialog
            open
            handleCloseDialog={handleCloseHistoryLogDialog}
            contextId={selectedAgreement.id}
            contextType={CONTEXT_TYPES.agreement}
          />
        )}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <Can
            perform={AGREEMENTS_EDIT_PERFORM}
            yes={() => (
              <MenuItem
                icon={<EditIcon />}
                onClick={() =>
                  handleShowAgreementFormDialog(true, selectedAgreement)
                }
              >
                Editar convenio
              </MenuItem>
            )}
          />
          <MenuItem icon={<HistoryIcon />} onClick={handleShowHistoryDialog}>
            Historial de cambios
          </MenuItem>
        </Menu>
        <MaterialTable
          data={agreements}
          columns={columns}
          style={{
            overflow: 'hidden',
            boxShadow: 'none',
            width: '100%',
            borderRadius: 17,
          }}
          isLoading={loadingAgreements}
          onChangeRowsPerPage={rowsPerPage => handleChangePage(1, rowsPerPage)}
          components={{
            Pagination: props => (
              <PaginationMaterialTable
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                count={pagination?.totalData}
                page={pagination?.page - 1}
                onChangePage={(e, page) =>
                  // eslint-disable-next-line react/prop-types
                  handleChangePage(page + 1, props.rowsPerPage)
                }
              />
            ),
            Toolbar: () => null,
          }}
          options={{
            showTitle: false,
            pageSizeOptions: [5, 10, 20, 50, 100],
            detailPanelType: 'single',
            emptyRowsWhenPaging: false,
            initialPage: 1,
            search: false,
            pageSize: pagination.pageSize,
            actionsColumnIndex: -1,
          }}
          localization={{
            pagination: {
              labelRowsSelect: 'convenios',
              labelRowsPerPage: 'Convenios por página',
              labelDisplayedRows: '{count} convenios totales',
            },
            body: {
              emptyDataSourceMessage: 'No hay convenios para mostrar',
            },
            header: {
              actions: '',
            },
          }}
          detailPanel={rowData => {
            return <AgreementContactSection agreementId={rowData.id} />;
          }}
          actions={actions}
        />
      </Panel>
    </Grid>
  );
};

export default HomeAgreements;
