import React, { useState } from 'react';
import { Typography, MenuItem, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BaseDialog from '../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../components/elements/CheckButton';
import AlertForm from '../../../../components/elements/AlertForm';
import {
  CommentCategory,
  Comment,
  Category,
} from '../../interfaces/collection';
import {
  useStyles,
  ContainerCategory,
  ContainerComment,
  ContainerCommentInput,
  LabelComment,
} from './styles';
import Skeleton from '../Skeleton';
import { commentDialogSchema } from '../../schemas/collectionBodySchema';
import { commentCategory } from '../../utils/constants';

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  error?: string | null;
  sourceId: number;
  sourceType: string;
  handleCloseDialog: () => void;
  handleSubmitDialog: (
    sourceType: string,
    sourceId: number,
    comment: Comment
  ) => void;
}

type Inputs = {
  category: Category;
  comment: string;
};

const CommentDialog = ({
  isOpen = false,
  isLoading = false,
  error = null,
  sourceId,
  sourceType,
  handleCloseDialog,
  handleSubmitDialog,
}: Props) => {
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(commentDialogSchema),
  });

  const [checkButton, setCheckButton] = useState(false);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      title="Agregar un comentario"
      description="Escribe el comentario pertinente a esta factura."
    >
      <form
        noValidate
        onSubmit={handleSubmit((comment: Inputs) =>
          handleSubmitDialog(sourceType, sourceId, comment)
        )}
      >
        <ContainerCategory>
          <TextField
            select
            variant="outlined"
            disabled={isLoading}
            id="category"
            label="Categoría del comentario"
            fullWidth
            defaultValue={commentCategory[0].value}
            error={!!errors.category}
            helperText={errors.category?.message}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('category')}
          >
            {commentCategory?.map(({ value, label }: CommentCategory) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </ContainerCategory>

        <ContainerComment>
          <LabelComment>Comentario</LabelComment>
          <Typography variant="caption">(Requerido)</Typography>
        </ContainerComment>
        <ContainerCommentInput>
          <Skeleton
            isLoading={isLoading}
            variant="rect"
            width={400}
            height={145}
          >
            <TextField
              fullWidth
              multiline
              rows={7}
              variant="outlined"
              type="string"
              id="comment"
              placeholder="Comentario"
              classes={{
                root: classes.inputMultiLine,
              }}
              error={!!errors.comment}
              helperText={errors.comment?.message}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('comment')}
            />
          </Skeleton>
        </ContainerCommentInput>

        {error && <AlertForm message={error} variant="error" />}

        <CheckButton
          check={checkButton}
          handleCheck={() => setCheckButton(!checkButton)}
          labelButton="Guardar"
          loading={isLoading}
          disabled={isLoading}
        />
      </form>
    </BaseDialog>
  );
};

export default CommentDialog;
