// eslint-disable-next-line import/prefer-default-export
export const NOTIFICATION = {
  SIMULATION_SENT: 'NOTIFICATION_SIMULATION_SENT',
  SIMULATION_PDF_DOWNLOADED: 'NOTIFICATION_SIMULATION_PDF_DOWNLOADED',
  FETCH_COMPANY_NAME_BY_RUT_FAILURE:
    'NOTIFICATION_FETCH_COMPANY_NAME_BY_RUT_FAILURE',
  CHECK_TAX_FAILURE: 'NOTIFICATION_CHECK_TAX_FAILURE',
  UPDATE_SCRAPPER_FAILURE: 'NOTIFICATION_UPDATE_SCRAPPER_FAILURE',
  SCRAPPER_UPDATED: 'NOTIFICATION_SCRAPPER_UPDATED',
  FETCH_SUBRUBRO_FAILURE: 'NOTIFICATION_FETCH_SUBRUBRO_FAILURE',
  FETCH_ALL_RESPONSIBLES_FAILURE: 'NOTIFICATION_FETCH_ALL_RESPONSIBLES_FAILURE',
  FETCH_RESPONSIBLES_FAILURE: 'NOTIFICATION_FETCH_RESPONSIBLES_FAILURE',
  FETCH_REASONS_FAILURE: 'NOTIFICATION_FETCH_REASONS_FAILURE',
  FETCH_BUSINESS_RATES_FAILURE: 'NOTIFICATION_FETCH_BUSINESS_RATES_FAILURE',
  FETCH_AGREEMENT_RATES_FAILURE: 'NOTIFICATION_FETCH_AGREEMENT_RATES_FAILURE',
  FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE:
    'NOTIFICATION_FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE',
  ORDER_INVOICE_BUSINESS_RATES_CREATED:
    'NOTIFICATION_ORDER_INVOICE_BUSINESS_RATES_CREATED',
  ORDER_INVOICE_BUSINESS_RATES_DELETED:
    'NOTIFICATION_ORDER_INVOICE_BUSINESS_RATES_DELETED',
  ORDER_INVOICE_BUSINESS_RATES_UPDATED:
    'NOTIFICATION_ORDER_INVOICE_BUSINESS_RATES_UPDATED',
  FETCH_PAYERS_BLOCKLIST_FAILURE: 'NOTIFICATION_FETCH_PAYERS_BLOCKLIST_FAILURE',
  PAYER_BLOCKLIST_CREATED: 'NOTIFICATION_PAYER_BLOCKLIST_CREATED',
  PAYER_BLOCKLIST_UPDATED: 'NOTIFICATION_PAYER_BLOCKLIST_UPDATED',
  FETCH_PAYER_BLOCKLIST_FAILURE: 'NOTIFICATION_FETCH_PAYER_BLOCKLIST_FAILURE',
  PAYERS_BLOCKLIST_CSV_REGISTERED:
    'NOTIFICATION_PAYERS_BLOCKLIST_CSV_REGISTERED',
  FETCH_WALLETS_FAILURE: 'NOTIFICATION_FETCH_WALLETS_FAILURE',
  WALLET_STATUS_UPDATED: 'NOTIFICATION_WALLET_STATUS_UPDATED',
  WALLET_REQUEST_STATUS_UPDATED: 'NOTIFICATION_WALLET_REQUEST_STATUS_UPDATED',
  WALLET_TRANSACTION_CREATED: 'NOTIFICATION_WALLET_TRANSACTION_CREATED',
  FETCH_WALLET_TRANSACTIONS_FAILURE:
    'NOTIFICATION_FETCH_WALLET_TRANSACTIONS_FAILURE',
  FETCH_WALLET_REQUEST_FAILURE: 'NOTIFICATION_FETCH_WALLET_REQUEST_FAILURE',
  FETCH_WALLET_HTML_FAILURE: 'NOTIFICATION_FETCH_WALLET_HTML_FAILURE',
  FETCH_USERS_FAILURE: 'NOTIFICATION_FETCH_USERS_FAILURE',
  USER_CREATED: 'NOTIFICATION_USER_CREATED',
  USER_UPDATED: 'NOTIFICATION_USER_UPDATED',
  IMPERSONATE_FAILURE: 'NOTIFICATION_IMPERSONATE_FAILURE',
  FETCH_PERFORM_FAILURE: 'NOTIFICATION_FETCH_PERFORM_FAILURE',
  ROLE_CREATED_OR_UPDATED: 'NOTIFICATION_ROLE_CREATED_OR_UPDATED',
  ROLE_DELETED: 'NOTIFICATION_ROLE_DELETED',
  FETCH_TAX_FOLDER_FAILURE: 'NOTIFICATION_FETCH_TAX_FOLDER_FAILURE',
  CHECK_TAX_FOLDER_FAILURE: 'NOTIFICATION_CHECK_TAX_FOLDER_FAILURE',
  FETCH_STAKEHOLDERS_FAILURE: 'NOTIFICATION_FETCH_STAKEHOLDERS_FAILURE',
  STAKEHOLDER_UPDATED: 'NOTIFICATION_STAKEHOLDER_UPDATED',
  STAKEHOLDER_DELETED: 'NOTIFICATION_STAKEHOLDER_DELETED',
  STAKEHOLDER_CREATED: 'NOTIFICATION_STAKEHOLDER_CREATED',
  FETCH_ACCOUNT_BALANCE_FAILURE: 'NOTIFICATION_FETCH_ACCOUNT_BALANCE_FAILURE',
  FETCH_AGREEMENTS_FAILURE: 'NOTIFICATION_FETCH_AGREEMENTS_FAILURE',
  FETCH_AGREEMENT_GROUPS_FAILURE: 'NOTIFICATION_FETCH_AGREEMENT_GROUPS_FAILURE',
  FETCH_AGREEMENT_CONTACTS_FAILURE:
    'NOTIFICATION_FETCH_AGREEMENT_CONTACTS_FAILURE',
  AGREEMENT_CREATED: 'NOTIFICATION_AGREEMENT_CREATED',
  AGREEMENT_UPDATED: 'NOTIFICATION_AGREEMENT_UPDATED',
  AGREEMENT_CONTACT_CREATED: 'NOTIFICATION_AGREEMENT_CONTACT_CREATED',
  AGREEMENT_CONTACT_UPDATED: 'NOTIFICATION_AGREEMENT_CONTACT_UPDATED',
  AGREEMENT_CONTACT_DELETED: 'NOTIFICATION_AGREEMENT_CONTACT_DELETED',

  DISCOUNT_CREATED: 'NOTIFICATION_DISCOUNT_CREATED',
  DISCOUNT_UPDATED: 'NOTIFICATION_DISCOUNT_UPDATED',
  DISCOUNT_DELETED: 'NOTIFICATION_DISCOUNT_DELETED',
  FETCH_DISCOUNT_FAILURE: 'NOTIFICATION_FETCH_DISCOUNT_FAILURE',

  DOCUMENTS_LOADED: 'NOTIFICATION_DOCUMENTS_LOADED',
  LOAD_DOCUMENTS_FAILURE: 'NOTIFICATION_LOAD_DOCUMENTS_FAILURE',
  FETCH_DOCUMENTS_FAILURE: 'NOTIFICATION_FETCH_DOCUMENTS_FAILURE',
  VERIFY_DOCUMENTS_FAILURE: 'NOTIFICATION_VERIFY_DOCUMENTS_FAILURE',
  DOWNLOAD_DOCUMENT_FAILURE: 'NOTIFICATION_DOWNLOAD_DOCUMENT_FAILURE',
  DOCUMENT_UPDATED: 'NOTIFICATION_DOCUMENT_UPDATED',
  UPDATE_DOCUMENT_FAILURE: 'NOTIFICATION_UPDATE_DOCUMENT_FAILURE',
  DOCUMENT_UPLOADED: 'NOTIFICATION_DOCUMENT_UPLOADED',
  DOCUMENT_CREATED: 'NOTIFICATION_DOCUMENT_CREATED',

  EMAILS_SENT: 'NOTIFICATION_EMAILS_SENT',

  FETCH_ENTITY_FEATURES_FAILURE: 'NOTIFICATION_FETCH_ENTITY_FEATURES_FAILURE',
  ENTITY_FEATURE_CREATED: 'NOTIFICATION_ENTITY_FEATURE_CREATED',
  CREATE_ENTITY_FEATURE_FAILURE: 'NOTIFICATION_CREATE_ENTITY_FEATURE_FAILURE',
  UPDATE_ENTITY_FEATURE_FAILURE: 'NOTIFICATION_UPDATE_ENTITY_FEATURE_FAILURE',
  ENTITY_FEATURE_UPDATED: 'NOTIFICATION_ENTITY_FEATURE_UPDATED',

  CALCULATE_FEES_FAILURE: 'NOTIFICATION_CALCULATE_FEES_FAILURE',
  BANK_ACCOUNT_UPDATED: 'NOTIFICATION_BANK_ACCOUNT_UPDATED',
  FETCH_CESSIONARIES_FAILURE: 'NOTIFICATION_FETCH_CESSIONARIES_FAILURE',
  MAKE_CESSION_SUCCESS: 'NOTIFICATION_MAKE_CESSION_SUCCESS',
  FETCH_CESSION_FAILURE: 'NOTIFICATION_FETCH_CESSION_FAILURE',
  MAKE_CESSION_FAILURE: 'NOTIFICATION_MAKE_CESSION_FAILURE',
  FILE_DOWNLOADED: 'NOTIFICATION_FILE_DOWNLOADED',
  DOWNLOAD_FILE_FAILURE: 'NOTIFICATION_DOWNLOAD_FILE_FAILURE',
  FETCH_COLLECTION_INVOICES_FAILURE:
    'NOTIFICATION_FETCH_COLLECTION_INVOICES_FAILURE',
  FETCH_COLLECTION_INVOICE_DETAIL_FAILURE:
    'NOTIFICATION_FETCH_COLLECTION_INVOICE_DETAIL_FAILURE',
  COLLECTION_INVOICES_BULK_UPDATED:
    'NOTIFICATION_COLLECTION_INVOICES_BULK_UPDATED',
  COLLECTION_EXTENSION_BULK_CREATED:
    'NOTIFICATION_COLLECTION_EXTENSION_BULK_CREATED',
  COLLECTION_PAYMENT_PROMISE_BULK_CREATED:
    'NOTIFICATION_COLLECTION_PAYMENT_PROMISE_BULK_CREATED',
  COLLECTION_MANAGEMENT_BULK_CREATED:
    'NOTIFICATION_COLLECTION_MANAGEMENT_BULK_CREATED',
  COLLECTION_EMAIL_SENT: 'NOTIFICATION_COLLECTION_EMAIL_SENT',
  SEND_COLLECTION_EMAIL_FAILURE: 'NOTIFICATION_SEND_COLLECTION_EMAIL_FAILURE',
  COLLECTION_PAYER_CATEGORIZATION_BULK_CREATED:
    'NOTIFICATION_COLLECTION_PAYER_CATEGORIZATION_BULK_CREATED',
  INVOICE_RESPONSIBLES_ADDED: 'NOTIFICATION_INVOICE_RESPONSIBLES_ADDED',
  ADD_INVOICE_RESPONSIBLES_FAILURE:
    'NOTIFICATION_ADD_INVOICE_RESPONSIBLES_FAILURE',
  FETCH_GLOBAL_COMMENTS_FAILURE: 'NOTIFICATION_FETCH_GLOBAL_COMMENTS_FAILURE',
  COMMENTS_SOURCE_UPDATED: 'NOTIFICATION_COMMENTS_SOURCE_UPDATED',
  UPDATE_COMMENTS_SOURCE_FAILURE: 'NOTIFICATION_UPDATE_COMMENTS_SOURCE_FAILURE',
  COMMENT_CREATED: 'NOTIFICATION_COMMENT_CREATED',
  CREATE_COMMENT_FAILURE: 'NOTIFICATION_CREATE_COMMENT_FAILURE',
  FETCH_CONTACTS_FAILURE: 'NOTIFICATION_FETCH_CONTACTS_FAILURE',
  CONTACT_CSV_UPLOADED: 'NOTIFICATION_CONTACT_CSV_UPLOADED',
  UPLOAD_CONTACT_CSV_FAILURE: 'NOTIFICATION_UPLOAD_CONTACT_CSV_FAILURE',
  FETCH_CONTACTS_CSV_FAILURE: 'NOTIFICATION_FETCH_CONTACTS_CSV_FAILURE',
  CONTACT_CREATED: 'NOTIFICATION_CONTACT_CREATED',
  CONTACT_UPDATED: 'NOTIFICATION_CONTACT_UPDATED',
  CONTACT_DELETED: 'NOTIFICATION_CONTACT_DELETED',
  FETCH_CREDITS_FAILURE: 'NOTIFICATION_FETCH_CREDITS_FAILURE',
  CREDIT_UPDATED: 'NOTIFICATION_CREDIT_UPDATED',
  CREDIT_CREATED: 'NOTIFICATION_CREDIT_CREATED',
  FETCH_FEES_FAILURE: 'NOTIFICATION_FETCH_FEES_FAILURE',
  FETCH_CREDIT_FAILURE: 'NOTIFICATION_FETCH_CREDIT_FAILURE',
  FEE_CREATED: 'NOTIFICATION_FEE_CREATED',
  FEE_UPDATED: 'NOTIFICATION_FEE_UPDATED',
  FEE_DELETED: 'NOTIFICATION_FEE_DELETED',
  CREATE_FEE_FAILURE: 'NOTIFICATION_CREATE_FEE_FAILURE',
  BUSINESS_FEES_UPDATED: 'NOTIFICATION_BUSINESS_FEES_UPDATED',
  BUSINESS_FILE_UPLOADED: 'NOTIFICATION_BUSINESS_FILE_UPLOADED',
  BUSINESS_UPDATED: 'NOTIFICATION_BUSINESS_UPDATED',
  COMPLIANCE_CONSULTED: 'NOTIFICATION_COMPLIANCE_CONSULTED',
  DEBT_SUPER_CONSULTED: 'NOTIFICATION_DEBT_SUPER_CONSULTED',
  DELETE_FILE_FAILURE: 'NOTIFICATION_DELETE_FILE_FAILURE',
  ENABLE_AUTO_RATE_FAILURE: 'NOTIFICATION_ENABLE_AUTO_RATE_FAILURE',
  ENABLE_CONFIRMING_FAILURE: 'NOTIFICATION_ENABLE_CONFIRMING_FAILURE',
  FETCH_BUSINESS_FAILURE: 'NOTIFICATION_FETCH_BUSINESS_FAILURE',
  FETCH_BUSINESS_PAYERS_FAILURE: 'NOTIFICATION_FETCH_BUSINESS_PAYERS_FAILURE',
  FETCH_BUSINESS_USERS_FAILURE: 'NOTIFICATION_FETCH_BUSINESS_USERS_FAILURE',
  FETCH_BUSINESSES_FAILURE: 'NOTIFICATION_FETCH_BUSINESSES_FAILURE',
  FETCH_COMPLIANCE_FAILURE: 'NOTIFICATION_FETCH_COMPLIANCE_FAILURE',
  FETCH_ENROLLED_BUSINESS_FAILURE:
    'NOTIFICATION_FETCH_ENROLLED_BUSINESS_FAILURE',
  FETCH_TGR_AGREEMENTS_FAILURE: 'NOTIFICATION_FETCH_TGR_AGREEMENTS_FAILURE',
  FETCH_TGR_DEBT_FAILURE: 'NOTIFICATION_FETCH_TGR_DEBT_FAILURE',
  FILE_DELETED: 'NOTIFICATION_FILE_DELETED',
  PFX_UPLOADED: 'NOTIFICATION_PFX_UPLOADED',
  TGR_CERTIFICATE_DOWNLOADED: 'NOTIFICATION_TGR_CERTIFICATE_DOWNLOADED',
  UPLOAD_BUSINESS_FILE_FAILURE: 'NOTIFICATION_UPLOAD_BUSINESS_FILE_FAILURE',
  FETCH_BANKS_FAILURE: 'NOTIFICATION_FETCH_BANKS_FAILURE',
  FETCH_BANK_ACCOUNTS_FAILURE: 'NOTIFICATION_FETCH_BANK_ACCOUNTS_FAILURE',
  DEFAULT_BANK_ACCOUNT_SET: 'NOTIFICATION_DEFAULT_BANK_ACCOUNT_SET',
  BANK_ACCOUNT_DELETED: 'NOTIFICATION_BANK_ACCOUNT_DELETED',
  BANK_ACCOUNT_CREATED: 'NOTIFICATION_BANK_ACCOUNT_CREATED',
  FETCH_PAYERS_FAILURE: 'NOTIFICATION_FETCH_PAYERS_FAILURE',
  FETCH_PAYER_FAILURE: 'NOTIFICATION_FETCH_PAYER_FAILURE',
  FETCH_PAYERS_CSV_FAILURE: 'NOTIFICATION_FETCH_PAYERS_CSV_FAILURE',
  PAYER_CREATED: 'NOTIFICATION_PAYER_CREATED',
  PAYERS_CSV_LOADED: 'NOTIFICATION_PAYERS_CSV_LOADED',
  ORDER_CREATED: 'NOTIFICATION_ORDER_CREATED',
  CLIENT_CREATED: 'NOTIFICATION_CLIENT_CREATED',
  FETCH_ORDERS_FAILURE: 'NOTIFICATION_FETCH_ORDERS_FAILURE',
  FETCH_ORDER_INVOICE_RISK_FAILURE:
    'NOTIFICATION_FETCH_ORDER_INVOICE_RISK_FAILURE',
  FETCH_ORDER_FAILURE: 'NOTIFICATION_FETCH_ORDER_FAILURE',
  FETCH_TOTAL_ORDER_TRANSFERRED_FAILURE:
    'NOTIFICATION_FETCH_TOTAL_ORDER_TRANSFERRED_FAILURE',
  FETCH_INVOICES_FAILURE: 'NOTIFICATION_FETCH_INVOICES_FAILURE',
  FETCH_SUMMARY_OPERATION_FAILURE:
    'NOTIFICATION_FETCH_SUMMARY_OPERATION_FAILURE',
  FETCH_FUND_SUMMARY_OPERATION_FAILURE:
    'NOTIFICATION_FETCH_FUND_SUMMARY_OPERATION_FAILURE',
  ORDER_UPDATED: 'NOTIFICATION_ORDER_UPDATED',
  INVOICE_UPDATED: 'NOTIFICATION_INVOICE_UPDATED',
  FILE_UPLOADED: 'NOTIFICATION_FILE_UPLOADED',
  PENDING_INVOICES_MATCHED: 'NOTIFICATION_PENDING_INVOICES_MATCHED',
  ORDER_CREDIT_CREATED: 'NOTIFICATION_ORDER_CREDIT_CREATED',
  INVOICES_UPDATED: 'NOTIFICATION_INVOICES_UPDATED',
  URL_COPIED: 'NOTIFICATION_URL_COPIED',
  LOAD_EXTERNAL_PDF_FAILURE: 'NOTIFICATION_LOAD_EXTERNAL_PDF_FAILURE',
  FETCH_TRANSFER_AMOUNT_FAILURE: 'NOTIFICATION_FETCH_TRANSFER_AMOUNT_FAILURE',
  DEBT_SUPER_REQUESTED: 'NOTIFICATION_DEBT_SUPER_REQUESTED',
  FUND_FINANCING_ADDED: 'NOTIFICATION_FUND_FINANCING_ADDED',
  DEBT_PERIOD_REGISTERED: 'NOTIFICATION_DEBT_PERIOD_REGISTERED',
  DEBT_PERIOD_UPDATED: 'NOTIFICATION_DEBT_PERIOD_UPDATED',
  DEBT_PERIOD_DELETED: 'NOTIFICATION_DEBT_PERIOD_DELETED',
  ORDER_INVOICE_FUND_RATE_DELETED:
    'NOTIFICATION_ORDER_INVOICE_FUND_RATE_DELETED',
  FETCH_ORDER_CREDIT_FAILURE: 'NOTIFICATION_FETCH_ORDER_CREDIT_FAILURE',
  FETCH_DOCUMENT_FAILURE: 'NOTIFICATION_FETCH_DOCUMENT_FAILURE',
  FILE_NOT_EXIST: 'NOTIFICATION_FILE_NOT_EXIST',
  BULK_CONSULT_DEBT_SERVICE_FAILURE:
    'NOTIFICATION_BULK_CONSULT_DEBT_SERVICE_FAILURE',
  BULK_CONSULT_DEBT_SUPER_FAILURE:
    'NOTIFICATION_BULK_CONSULT_DEBT_SUPER_FAILURE',
  CONSULT_DEBT_SERVICE_FAILURE: 'NOTIFICATION_CONSULT_DEBT_SERVICE_FAILURE',
  DEBT_SERVICE_CONSULTED: 'NOTIFICATION_DEBT_SERVICE_CONSULTED',
  FETCH_DEBT_SERVICE_FAILURE: 'NOTIFICATION_FETCH_DEBT_SERVICE_FAILURE',
  FETCH_DEBT_SUPER_FAILURE: 'NOTIFICATION_FETCH_DEBT_SUPER_FAILURE',
  LOADING_INVOICES: 'NOTIFICATION_LOADING_INVOICES',
  DATA_COPIED: 'NOTIFICATION_DATA_COPIED',
  COPY_DATA_FAILURE: 'NOTIFICATION_COPY_DATA_FAILURE',
  NO_EXPORTABLE_DATA_FAILURE: 'NOTIFICATION_NO_EXPORTABLE_DATA_FAILURE',
  CONTACTS_ADDED: 'NOTIFICATION_CONTACTS_ADDED',
  INVOICES_NOT_FOUND: 'NOTIFICATION_INVOICES_NOT_FOUND',
  ACCESS_DENIED: 'NOTIFICATION_ACCESS_DENIED',
  FETCH_LOGS_FAILURE: 'NOTIFICATION_FETCH_LOGS_FAILURE',
  INVOICES_CSV_UPLOADED: 'NOTIFICATION_INVOICES_CSV_UPLOADED',
  FETCH_GEO_MX_STATES_FAILURE: 'NOTIFICATION_FETCH_GEO_MX_STATES_FAILURE',
  FETCH_FUNDS_FAILURE: 'NOTIFICATION_FETCH_FUNDS_FAILURE',
  FETCH_FUND_RATES_FAILURE: 'NOTIFICATION_FETCH_FUND_RATES_FAILURE',
  ORDER_INVOICE_FUND_UPDATED: 'NOTIFICATION_ORDER_INVOICE_FUND_UPDATED',
  FUND_UPDATED: 'NOTIFICATION_FUND_UPDATED',
  FUND_CREATED: 'NOTIFICATION_FUND_CREATED',
  FUND_RATES_CREATED: 'NOTIFICATION_FUND_RATES_CREATED',
  FUND_RATES_UPDATED: 'NOTIFICATION_FUND_RATES_UPDATED',
  FUND_RATE_DELETED: 'NOTIFICATION_FUND_RATE_DELETED',
  CSV_FUNDS_UPLOADED: 'NOTIFICATION_CSV_FUNDS_UPLOADED',
  UPDATE_ORDER_INVOICE_FUND_FAILURE:
    'NOTIFICATION_UPDATE_ORDER_INVOICE_FUND_FAILURE',
  FETCH_RATES_FAILURE: 'NOTIFICATION_FETCH_RATES_FAILURE',
  VERIFY_INVOICE_FAILURE: 'NOTIFICATION_VERIFY_INVOICE_FAILURE',
  FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE:
    'NOTIFICATION_FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE',

  FETCH_PARTIAL_PAYMENTS_FAILURE: 'NOTIFICATION_FETCH_PARTIAL_PAYMENTS_FAILURE',
  PARTIAL_PAYMENT_CREATED: 'NOTIFICATION_PARTIAL_PAYMENT_CREATED',
  PARTIAL_PAYMENT_UPDATED: 'NOTIFICATION_PARTIAL_PAYMENT_UPDATED',
  BULK_PARTIAL_PAYMENT_CREATED: 'NOTIFICATION_BULK_PARTIAL_PAYMENT_CREATED',

  REGISTER_LINK_FUND_FAILURE: 'NOTIFICATION_REGISTER_LINK_FUND_FAILURE',
  REGISTER_LINK_FUND_SUCCESS: 'NOTIFICATION_REGISTER_LINK_FUND_SUCCESS',
  UPDATE_LINK_FUND_SUCCESS: 'NOTIFICATION_UPDATE_LINK_FUND_SUCCESS',
  UPDATE_LINK_FUND_FAILURE: 'NOTIFICATION_UPDATE_LINK_FUND_FAILURE',
  FETCH_LINK_FUND_FAILURE: 'NOTIFICATION_FETCH_LINK_FUND_FAILURE',
  FETCH_LINK_FUND_RATES_FAILURE: 'NOTIFICATION_FETCH_LINK_FUND_RATES_FAILURE',
  FETCH_LINK_FUND_SUMMARY_FAILURE:
    'NOTIFICATION_FETCH_LINK_FUND_SUMMARY_FAILURE',
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE:
    'NOTIFICATION_FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE',

  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE:
    'NOTIFICATION_GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE',
  UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS:
    'NOTIFICATION_UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS',
  UPDATE_PAYMENT_ORDER_DETAILS_FAILURE:
    'NOTIFICATION_UPDATE_PAYMENT_ORDER_DETAILS_FAILURE',

  FUNDS_BULK_UPDATE_SUCCESS: 'NOTIFICATION_FUNDS_BULK_UPDATE_SUCCESS',
  INVOICES_BULK_UPDATE_SUCCESS: 'NOTIFICATION_INVOICES_BULK_UPDATE_SUCCESS',

  PARTIAL_PAYMENT_DELETED: 'NOTIFICATION_PARTIAL_PAYMENT_DELETED',
  DELETE_PARTIAL_PAYMENT_FAILURE: 'NOTIFICATION_DELETE_PARTIAL_PAYMENT_FAILURE',
  FETCH_CRITIAL_STATUS_INFO_FAILURE:
    'NOTIFICATION_FETCH_CRITIAL_STATUS_INFO_FAILURE',
  CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE:
    'NOTIFICATION_CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE',
  BULK_PARTIAL_PAYMENT_SUCCESS: 'NOTIFICATION_BULK_PARTIAL_PAYMENT_SUCCESS',
  UPLOAD_BANK_MOVEMENTS_SUCCESS: 'UPLOAD_BANK_MOVEMENTS_SUCCESS',
  UPLOAD_INTERBANK_INQUIRY_SUCCESS: 'UPLOAD_INTERBANK_INQUIRY_SUCCESS',
  UPLOAD_SAME_BANK_COMPLEMENTARY_INFO_SUCCESS:
    'UPLOAD_SAME_BANK_COMPLEMENTARY_INFO_SUCCESS',
  INVOICES_BULK_UPDATE_STATUS_SUCCESS:
    'NOTIFICATION_INVOICES_BULK_UPDATE_STATUS_SUCCESS',
  UPDATE_ORDER_STATUS_SUCCESS: 'NOTIFICATION_UPDATE_ORDER_STATUS_SUCCESS',
  FETCH_COMMENTS_FAILURE: 'NOTIFICATION_FETCH_COMMENTS_FAILURE',
  FETCH_LAST_COMMENT_FAILURE: 'NOTIFICATION_FETCH_LAST_COMMENT_FAILURE',
  ORDER_INVOICE_IS_HOLD_UPDATED: 'NOTIFICATION_ORDER_INVOICE_IS_HOLD_UPDATED',
  MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS:
    'NOTIFICATION_MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS',
  MASS_ASSIGNABLE_INVOICE_PDF_FAILURE:
    'NOTIFICATION_MASS_ASSIGNABLE_INVOICE_PDF_FAILURE',
  FETCH_COLLECTION_PAYERS_FAILURE:
    'NOTIFICATION_FETCH_COLLECTION_PAYERS_FAILURE',
  CREATE_RESPONSIBLE_SUCCESS: 'NOTIFICATION_CREATE_RESPONSIBLE_SUCCESS',
  DELETE_RESPONSIBLE_SUCCESS: 'NOTIFICATION_DELETE_RESPONSIBLE_SUCCESS',

  REPORT_CREATED: 'NOTIFICATION_REPORT_CREATED',
  REPORT_UPDATED: 'NOTIFICATION_REPORT_UPDATED',
  FETCH_REPORTS_FAILURE: 'NOTIFICATION_FETCH_REPORTS_FAILURE',
  REPORT_EXECUTED: 'NOTIFICATION_REPORT_EXECUTED',
  UPDATE_SIGNED_DOCUMENT_SUCCESS: 'NOTIFICATION_UPDATE_SIGNED_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE: 'NOTIFICATION_CREATE_DOCUMENT_FAILURE',
  VERIFY_DOCUMENTS_STATUS_FAILURE:
    'NOTIFICATION_VERIFY_DOCUMENTS_STATUS_FAILURE',
  FETCH_SIGNATORIES_SUCCESS: 'NOTIFICATION_FETCH_SIGNATORIES_SUCCESS',
  FETCH_API_CORPS_AMOUNT_FAILURE: 'NOTIFICATION_FETCH_API_CORPS_AMOUNT_FAILURE',
  FETCH_COUNT_ORDER_COMMENTS_FAILURE:
    'NOTIFICATION_FETCH_COUNT_ORDER_COMMENTS_FAILURE',
  ORDER_COMMENT_CREATED: 'NOTIFICATION_ORDER_COMMENT_CREATED',
  ORDER_COMMENT_UPDATED: 'NOTIFICATION_ORDER_COMMENT_UPDATED',
  CREATE_ORDER_COMMENT_FAILURE: 'NOTIFICATION_CREATE_ORDER_COMMENT_FAILURE',
  UPDATE_ORDER_COMMENT_FAILURE: 'NOTIFICATION_UPDATE_ORDER_COMMENT_FAILURE',
  DOWNLOAD_ORDER_COMMENT_ATTACHMENT:
    'NOTIFICATION_DOWNLOAD_ORDER_COMMENT_ATTACHMENT',
  UPDATE_ORDER_COMMENT_TAGS_FAILURE:
    'NOTIFICATION_UPDATE_ORDER_COMMENT_TAGS_FAILURE',
  FETCH_COUNT_ORDER_RESPONSBILES_FAILURE:
    'NOTIFICATION_FETCH_COUNT_ORDER_RESPONSBILES_FAILURE',
  VERIFY_REQUIREMENTS_FAILURE: 'NOTIFICATION_VERIFY_REQUIREMENTS_FAILURE',
  BANK_ACOUNT_ASSIGNED_SUCCESS: 'NOTIFICATION_BANK_ACOUNT_ASSIGNED_SUCCESS',

  PAYMENTS_BANK_ACCOUNT_UPDATE_SUCCESS: 'PAYMENTS_BANK_ACCOUNT_UPDATE_SUCCESS',
};
