import axios from 'axios';
import { Country } from '../schemas';

type ErrorResponse = {
  response: {
    data: string;
  };
};

export class CreditLineNotFound extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'CreditLineNotFound';
    Error.captureStackTrace(this, this.constructor);
  }
}

const getCreditLineService = async (country: Country, businessId?: string) => {
  const url = `/api/business/${businessId || ''}/creditline/share/`;
  return axios.get(url, { headers: { country } }).catch((err: ErrorResponse) => {
    if (err.response.data.includes('Credit Line not found')) {
      throw new CreditLineNotFound();
    } else {
      throw new Error(err.response.data);
    }
  });
};

export default getCreditLineService;
