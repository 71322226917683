import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Grid,
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { CSVLink } from 'react-csv';
import CSVFileValidator from 'csv-file-validator';
import BaseDialog from './BaseDialog';
import Dropzone from '../elements/Dropzone/Dropzone';
import AlertForm from '../elements/AlertForm';
import { PAYERS_BLOCKLIST_CSV_CONFIG } from '../../helpers/CsvConfig';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import {
  BLOCKLIST_REASONS,
  BLOCKLIST_REASONS_CL,
  COUNTRY_CODE_CL,
} from '../../helpers/Constants';

const useStyles = makeStyles(theme => ({
  error: {
    color: '#ffff',
    background: theme.palette.error.main,
    marginTop: 10,
    padding: 8,
    borderRadius: 5,
  },
  template: {
    marginBottom: 20,
  },
  containerError: {
    marginTop: 20,
  },
}));
const BulkCsvPayersBlocklistDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  isLoading,
  errorRequest,
  errorPayers,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config);

  const [csvErrors, setCsvErrors] = useState('');
  const [csvTemplate, setCsvTemplate] = useState([]);

  const csvValidator = (data, errors) => {
    if (errors.length) {
      setCsvErrors(errors[0]);
      return false;
    }
    if (data?.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const rowData of data) {
        if (!validateIdentifier(rowData.payerIdentifier, country)) {
          setCsvErrors(
            `La fila ${
              data.indexOf(rowData) + 2
            } contiene un Payer Identifier erroneo. (${
              rowData.payerIdentifier
            })`
          );
          return false;
        }
      }
    } else {
      setCsvErrors('CSV sin datos');
      return false;
    }
    return true;
  };

  const onDrop = files => {
    if (!files) {
      setCsvErrors('Debe seleccionar un archivo antes');
      return;
    }
    const file = files?.length ? files[0].file : [];
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      if (!fileData.includes(',')) {
        setCsvErrors(`Dilimitador erroneo. Delimitador esperado: "," (coma).`);
        return;
      }
      CSVFileValidator(file, PAYERS_BLOCKLIST_CSV_CONFIG)
        .then(csv => {
          if (csvValidator(csv.data, csv.inValidMessages)) {
            handleSubmit(csv.data);
            setCsvErrors('');
          }
        })
        .catch(err => {
          setCsvErrors(`CSVFileValidator: ${err}`);
        });
    };
    reader.readAsBinaryString(file);
  };

  const linkError = (
    <CSVLink
      data={csvTemplate}
      separator=","
      style={{ textDecoration: 'none' }}
      filename="payers_with_error"
      asyncOnClick
      onClick={(event, done) => {
        setCsvTemplate(errorPayers);
        done();
      }}
    >
      <Typography variant="body2" align="center">
        <Link>Descargar el detalle</Link>
      </Typography>
    </CSVLink>
  );

  const selectMessage = dataArray => {
    if (dataArray.length > 1) {
      return `Tuvimos un problema al cargar ${dataArray?.length} registros, estos ya se encontraban registrados o venian duplicados.`;
    }
    return `Tuvimos un problema al cargar 1 registro, este ya se encontraba registrado o venia duplicado.`;
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Carga de Blocklist"
      size="lg"
    >
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<CloudDownloadIcon />}
            color="primary"
            variant="text"
            className={classes.template}
            disabled={isLoading}
          >
            <CSVLink
              data={csvTemplate}
              separator=","
              style={{ textDecoration: 'none' }}
              filename="data_template"
              asyncOnClick
              onClick={(event, done) => {
                setCsvTemplate(
                  [
                    ...BLOCKLIST_REASONS,
                    ...(country === COUNTRY_CODE_CL
                      ? BLOCKLIST_REASONS_CL
                      : []),
                  ].map(({ label, value }) => ({
                    payerName: `Empresa ${label}`,
                    payerIdentifier: '123456789',
                    reason: value,
                  }))
                );
                done();
              }}
            >
              Descargar Template
            </CSVLink>
          </Button>
        </Grid>

        <Grid item xs={12} align="center">
          {isLoading ? (
            <CircularProgress color="primary" size={60} />
          ) : (
            <Dropzone
              handleSubmit={onDrop}
              maxFiles={1}
              accept=".csv"
              buttonText="Subir archivo"
              isLoading={isLoading}
            />
          )}

          {(csvErrors || errorRequest) && (
            <Grid item xs={12} className={classes.containerError}>
              <AlertForm message={csvErrors || errorRequest} variant="error" />
            </Grid>
          )}

          {errorPayers?.length > 0 && (
            <Grid item xs={12} className={classes.containerError}>
              <AlertForm
                messageComponent={
                  <Typography variant="body2" align="center" component="div">
                    <Box fontWeight="fontWeightBold">
                      {selectMessage(errorPayers)}
                      {linkError} para conocer más detalles.
                    </Box>
                  </Typography>
                }
                variant="error"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

BulkCsvPayersBlocklistDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorRequest: PropTypes.string.isRequired,
  errorPayers: PropTypes.arrayOf(Object).isRequired,
};

export default BulkCsvPayersBlocklistDialog;
