import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../../../../helpers/Constants';
import { MenuSelector } from '../../../../../commons/components';

const MoreActionsButton = ({
  handleCession,
  handleDownloadAEC,
  handleShowDiscountsDialog,
  handleShowFundsBulkUpdateDialog,
  handleShowInvoicesBulkUpdateDialog,
}) => {
  const { country } = useSelector(state => state.config);
  const menuActions = [
    {
      itemText: 'Descargar AEC',
      itemHandler: handleDownloadAEC,
      activeCountries: [COUNTRY_CODE_CL],
    },
    {
      itemText: 'Ceder facturas',
      itemHandler: handleCession,
      activeCountries: [COUNTRY_CODE_CL],
    },
    {
      itemText: 'Edición masiva de facturas',
      itemHandler: handleShowInvoicesBulkUpdateDialog,
      activeCountries: [COUNTRY_CODE_CL, COUNTRY_CODE_MX],
    },
    {
      itemText: 'Asignación masiva de fondos',
      itemHandler: handleShowFundsBulkUpdateDialog,
      activeCountries: [COUNTRY_CODE_CL, COUNTRY_CODE_MX],
    },
    {
      itemText: 'Cargar descuento a facturas',
      itemHandler: handleShowDiscountsDialog,
      activeCountries: [COUNTRY_CODE_CL, COUNTRY_CODE_MX],
    },
  ];
  const menuActionsActive = menuActions.filter(action =>
    action.activeCountries.includes(country)
  );
  return <MenuSelector actions={menuActionsActive} menuText="Más acciones" />;
};

MoreActionsButton.propTypes = {
  handleShowDiscountsDialog: PropTypes.func.isRequired,
  handleDownloadAEC: PropTypes.func.isRequired,
  handleShowFundsBulkUpdateDialog: PropTypes.func.isRequired,
  handleShowInvoicesBulkUpdateDialog: PropTypes.func.isRequired,
  handleCession: PropTypes.func.isRequired,
};

export default MoreActionsButton;
