import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, styled } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import useGetCountry from '../hooks/useGetCountry';
import {
  fetchFacturas,
  fetchSelectedOrder,
  fetchOrderFees,
  updateOrderInvoice,
  updateOrderDetails,
  updateOrder,
  registerOrderCredit,
  fetchOrdersIdByPayerRut,
  fetchFacturasIdByOrderId,
  invoiceVerification,
  fetchOperationSummary,
  fetchOrderCredit,
  resetOrderFees,
} from '../actions/orderActions';
import { fetchActiveFunds } from '../actions/fundsActions';
import { impersonate } from '../actions/userActions';
import { registerPfx, fetchBusiness } from '../actions/businessAction';
import { makeCession } from '../actions/cessionAction';
import { fetchHolidays } from '../actions/holidaysActions';
import { createDiscount, updateDiscount } from '../actions/discountActions';
import InvoicesTable from './tables/invoice/InvoicesTable';
import StatusChangeDialog from './dialogs/StatusChangeDialog';
import NewSimulationDialog from './elements/NewSimulationDialog';
import FacturasCessionDialog from './elements/FacturasCessionDialog';
import OrderDetailsCard from './elements/OrderDetailsCard';
import PfxUploadDialog from './dialogs/PfxUploadDialog';
import SimulationVoucher from './elements/SimulationVoucher';
import MatchProcessPdfDialog from './elements/MatchInvoicesPDFDialog';
import CreditFormDialog from './elements/CreditFormDialog';
import LoaderComponent from './elements/LoaderComponent';
import HistoryLogsDialog from './dialogs/HistoryLogsDialog';
import RedirectToProductAdapter from './RedirectToProductAdapter';
import {
  STATUS_REPLACED,
  ITEM_TYPE_CHECK_INVOICE,
  ITEM_TYPE_ORDER,
  ITEM_TYPE_INVOICE,
  STATUS_RENEGOTIATION,
  DIALOG_TYPES,
  STATUS_PAID,
  FORCED,
  NONE,
  STATUS_VERIFICATION_WITHOUT_AMOUNT,
  COUNTRY_CODE_CL,
} from '../helpers/Constants';

const ContainerInvoicesTable = styled(Box)({
  padding: '0px 20px 70px 20px',
});

const HomeOperationDetail = () => {
  const dispatch = useDispatch();
  const country = useGetCountry();
  const history = useHistory();
  const { id: paramId } = useParams();
  const {
    facturas,
    updatedOrder,
    selectedFactura,
    orderCredit: credit,
    orderError,
    matchPendingInvoicesWasSuccessful,
    loadingOrders,
    wasOrderFetch,
    orderCreditWasCreated,
    orderSummaryWasLoaded,
    invoicesFetched,
    invoiceVerified,
    invoiceWasUpdated,
    savedOrder,
  } = useSelector(state => state.order);
  const { business, pfxSuccess } = useSelector(state => state.business);
  const { holidays } = useSelector(state => state.holidays);

  const [operation, setOperation] = useState('');
  const [recession] = useState(false);
  const [showCreditFormDialog, setShowCreditFormDialog] = useState(false);
  const [showFacturaStatusDialog, setShowFacturaStatusDialog] = useState(false);
  const [showSimulationDialog, setShowSimulationDialog] = useState(false);
  const [showCessionDialog, setShowCessionDialog] = useState(false);
  const [showPfxDialog, setShowPfxDialog] = useState(false);
  const [showSimulationDrawer, setShowSimulationDrawer] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMatchInvoicesPDFDialog, setShowMatchInvoicesPDFDialog] =
    useState(false);
  const [redirect, setRedirect] = useState(false);
  const [typeDialog, setTypeDialog] = useState('');
  const [showHistoryLogDialog, setShowHistoryLogDialog] = useState(false);
  const [contextId, setContextId] = useState(null);
  const [contextType, setContextType] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const handleResetOrderFees = () => dispatch(resetOrderFees());

  const handleFetchBusiness = (businessId, includeDetails = false) =>
    dispatch(fetchBusiness(businessId, includeDetails));

  const handleFetchInvoices = operationId =>
    dispatch(fetchFacturas(operationId));

  const handleFetchOperation = operationId =>
    dispatch(fetchSelectedOrder(operationId));

  const handleFetchOperationSummary = operationId =>
    dispatch(fetchOperationSummary(operationId));

  const handleUpdateOrder = (orderData, operationType) =>
    dispatch(updateOrder(orderData, operationType));

  const handleUpdateSelectedFund = (selectedFund = null) =>
    dispatch({
      type: 'UPDATE_SELECTED_FUND',
      payload: { selectedFund },
    });

  const handleUpdateSelectedFactura = invoice =>
    dispatch({
      type: 'HANDLE_SELECT_FACTURA_CHANGE',
      payload: { invoice },
    });

  const handleFetchOrderFees = ({
    orderId,
    businessId,
    retentionPct = null,
    tasaBase = null,
    operation,
    issuedDate,
    expirationDate,
    discount,
    operationCost,
    autoRate,
    country,
  }) =>
    dispatch(
      fetchOrderFees({
        orderId,
        businessId,
        retentionPct,
        tasaBase,
        operation,
        issuedDate,
        expirationDate,
        discount,
        operationCost,
        autoRate,
        country,
      })
    );

  const handleUpdateOrderDetails = operationData =>
    dispatch(updateOrderDetails(operationData));

  const handleMakeCession = (invoices, cessionary, businessId) =>
    dispatch(makeCession(invoices, cessionary, businessId));

  const handleUpdateOrderInvoice = (id, operationId, invoice) =>
    dispatch(updateOrderInvoice(id, operationId, invoice));

  const handleRegisterPfx = ({ businessId, pfx, password }) =>
    dispatch(registerPfx({ businessId, pfx, password }));

  const handleResetPfxDialogProps = () =>
    dispatch({ type: 'HANDLE_RESET_PFX' });

  const handleResetOrderCreditFormDialog = () =>
    dispatch({ type: 'RESET_ORDER_CREDIT_FORM_DIALOG' });

  const handleRegisterOrderCredit = (operationId, credit) =>
    dispatch(registerOrderCredit(operationId, credit));

  const handleFetchOrdersIdByPayerRut = (payerRut, totalAmount) =>
    dispatch(fetchOrdersIdByPayerRut(payerRut, totalAmount));

  const handleFetchFacturasIdByOrderId = (operationId, totalAmount) =>
    dispatch(fetchFacturasIdByOrderId(operationId, totalAmount));

  const handleImpersonate = (userId, country) =>
    dispatch(impersonate(userId, country));

  const handleInvoiceVerification = (factura, data) =>
    dispatch(invoiceVerification(factura, data));

  const handleFetchOrderCredit = operationId =>
    dispatch(fetchOrderCredit(operationId));

  const handleFetchActiveFunds = () => dispatch(fetchActiveFunds());

  const handleFetchHolidays = () => dispatch(fetchHolidays());

  const handleCreateDiscount = (sourceId, sourceType, discountData) =>
    dispatch(createDiscount(sourceId, sourceType, discountData));

  const handleUpdateDiscount = (sourceId, sourceType, discountData) =>
    dispatch(updateDiscount(sourceId, sourceType, discountData));

  const handleHasParamId = operationId => {
    setShowLoader(true);
    handleFetchOrderCredit(operationId);
    handleFetchOperation(operationId);
    handleFetchOperationSummary(operationId);
    handleFetchInvoices(operationId);
  };

  const handleRedirectTypeOrder = operationId => {
    const path = operationId
      ? `/${country.toLowerCase()}/orders/${operationId}`
      : `/${country.toLowerCase()}/direct_financing`;
    history.push(path);
    if (operationId) {
      handleHasParamId(operationId);
    }
  };

  const handleCloseDialogs = () => {
    setShowCreditFormDialog(false);
    setShowMatchInvoicesPDFDialog(false);
    setShowFacturaStatusDialog(false);
    setTypeDialog('');
    setShowLoader(false);
    setShowPfxDialog(false);
    setShowSimulationDialog(false);
    setShowCessionDialog(false);
  };

  const handleCloseStatusDialog = () => {
    handleUpdateSelectedFund({});
    setShowFacturaStatusDialog(false);
  };

  const handleCloseNewSimulationDialog = () => {
    setShowSimulationDialog(false);
    handleResetOrderFees();
  };

  const handleShowSimulationDialog = operation => {
    setShowSimulationDialog(true);
    setOperation(operation.type);
  };

  const handleSubmitOrder = (
    { id: orderId, updateFacturas, discount, discountReason },
    status
  ) => {
    let orderData = {
      orderId,
      statusOrder: status,
    };
    setShowLoader(true);
    if (country === COUNTRY_CODE_CL) {
      orderData = {
        ...orderData,
        updateFacturas,
        discount,
        discountReason,
      };
    }
    handleUpdateOrder(orderData, operation);
  };

  const handleSubmitInvoice = ({
    data,
    status,
    statusReason,
    paymentDate,
    destinationId,
  }) => {
    setShowLoader(true);
    let facturaData = { ...data, status, destinationId };
    const { id, orderId } = selectedFactura;
    if (!status) {
      facturaData = { ...selectedFactura, ...facturaData };
    } else if (status === STATUS_PAID) {
      facturaData = { ...facturaData, paymentDate };
    } else if (statusReason && statusReason !== '') {
      facturaData = { ...facturaData, statusReason };
    }
    handleUpdateOrderInvoice(id, orderId, facturaData);
  };

  const handleSubmitVerificationInvoice = (status, statusReason) => {
    setShowLoader(true);
    handleInvoiceVerification(selectedFactura, {
      status,
      reason: statusReason,
    });
  };

  const handleSubmitDialog = (
    data,
    type,
    status,
    statusReason,
    paymentDate,
    invoiceId,
    checkInvoiceDiscount = {}
  ) => {
    handleCloseDialogs();
    if (type === ITEM_TYPE_ORDER) {
      handleSubmitOrder(data, status);
    } else if (type === ITEM_TYPE_INVOICE) {
      handleSubmitInvoice({
        data,
        status,
        paymentDate,
        statusReason,
        destinationId: invoiceId,
      });
    } else if (type === ITEM_TYPE_CHECK_INVOICE) {
      handleSubmitVerificationInvoice(status, statusReason);
    }
    if (status === STATUS_VERIFICATION_WITHOUT_AMOUNT) {
      if (checkInvoiceDiscount.isNewDiscount) {
        handleCreateDiscount(data?.id, 'orderinvoice', {
          amount: checkInvoiceDiscount?.amount,
          reason: 'DIFFERENCE_API_CORP',
        });
      } else {
        handleUpdateDiscount(data?.id, 'orderinvoice', {
          amount: checkInvoiceDiscount?.amount,
          reason: 'DIFFERENCE_API_CORP',
        });
      }
    }
  };

  const handleSubmitSimulation = orderData => {
    const newOrderData = { ...orderData };
    setShowLoader(true);

    if (operation !== newOrderData.operationType) {
      setOperation(orderData.operationType);
    }
    newOrderData.autoRate = orderData.autoRate ? FORCED : NONE;
    handleUpdateOrderDetails(newOrderData);
  };

  const handleSubmitPfx = ({ pfx, password }) => {
    handleRegisterPfx({ businessId: updatedOrder.businessId, pfx, password });
  };

  const handleSubmitCreditDialog = (creditData, operationType) => {
    handleCloseDialogs();
    setShowLoader(true);
    setRedirect(true);
    handleRegisterOrderCredit(updatedOrder.id, {
      ...creditData,
      type: operationType,
    });
  };

  const handleShowCreditFormDialog = () => {
    if (updatedOrder.status !== STATUS_RENEGOTIATION) {
      handleResetOrderCreditFormDialog();
      setShowCreditFormDialog(true);
    } else if (credit && Object.entries(credit)?.length) {
      history.push(`/${country.toLowerCase()}/credits/${credit.id}`);
    }
  };

  const handleShowHistoryLogDialog = (contextId, contextType = 'ORDER') => {
    setContextId(contextId);
    setContextType(contextType);
    setShowHistoryLogDialog(true);
  };

  const handleShowVerifiedDialog = invoice => {
    handleUpdateSelectedFactura(invoice);
    const { status, Relationships } = invoice;
    if (status === STATUS_REPLACED && Relationships) {
      const { rutReceptor, mntTotal } = invoice;
      handleFetchOrdersIdByPayerRut(rutReceptor, mntTotal);
      handleFetchFacturasIdByOrderId(Relationships?.orderId, mntTotal);
    }
    setShowFacturaStatusDialog(true);
    setTypeDialog(ITEM_TYPE_CHECK_INVOICE);
  };

  const handleSetSelectedInvoices = invoices => {
    setSelectedInvoices(invoices);
  };

  const handleRedirectToCredits = () => {
    if (redirect && credit && Object.entries(credit)?.length) {
      setRedirect(false);
      history.push(`/${country.toLowerCase()}/credits/${credit.id}`);
    }
  };

  useEffect(() => {
    handleResetPfxDialogProps();
    handleFetchActiveFunds();
  }, []);

  useEffect(() => {
    if (!holidays) {
      handleFetchHolidays();
    }
  }, []);

  useEffect(() => {
    const isNumber = /^-?\d+$/.test(Number(paramId));
    if (paramId && isNumber) {
      handleHasParamId(paramId);
    } else {
      history.push(`/${country.toLowerCase()}/direct_financing`);
    }
  }, []);

  useEffect(() => {
    if (updatedOrder?.businessId) {
      handleFetchBusiness(updatedOrder.businessId, true);
    }
  }, [updatedOrder?.businessId]);

  useEffect(() => {
    if (matchPendingInvoicesWasSuccessful) {
      handleCloseDialogs();
    }
  }, [matchPendingInvoicesWasSuccessful]);

  useEffect(() => {
    if (orderError === 'ERROR_GET_ORDER_NOT_FOUND') {
      history.push(`/${country.toLowerCase()}/direct_financing`);
    }
  }, [orderError]);

  useEffect(() => {
    if (pfxSuccess) {
      setShowPfxDialog(false);
      handleResetPfxDialogProps();
    }
  }, [pfxSuccess]);

  useEffect(() => {
    if (wasOrderFetch) {
      handleCloseDialogs();
    }
  }, [wasOrderFetch]);

  useEffect(() => {
    if (orderSummaryWasLoaded) {
      handleCloseDialogs();
    }
  }, [orderSummaryWasLoaded]);

  useEffect(() => {
    if (invoicesFetched) {
      handleCloseDialogs();
    }
  }, [invoicesFetched]);

  useEffect(() => {
    if (invoiceVerified) {
      handleCloseDialogs();
    }
  }, [invoiceVerified]);

  useEffect(() => {
    if (orderCreditWasCreated) {
      handleCloseDialogs();
      handleRedirectToCredits();
    }
  }, [orderCreditWasCreated]);

  useEffect(() => {
    if (invoiceWasUpdated) {
      handleCloseDialogs();
    }
  }, [invoiceWasUpdated]);

  useEffect(() => {
    if (savedOrder) {
      handleCloseDialogs();
    }
  }, [savedOrder]);

  return (
    <Box>
      <RedirectToProductAdapter
        orderType={updatedOrder?.orderType}
        country={country}
      />
      <Box>
        <OrderDetailsCard
          order={updatedOrder}
          facturas={facturas}
          businessOrder={business}
          handleShowSimulationDialog={handleShowSimulationDialog}
          handleShowLoader={() => setShowLoader(true)}
          handleShowDrawer={() => setShowSimulationDrawer(true)}
          handleShowCredit={handleShowCreditFormDialog}
          handleImpersonate={handleImpersonate}
          handleShowMatchInvoicesPDFDialog={() =>
            setShowMatchInvoicesPDFDialog(true)
          }
          handleHistoryLogDialog={handleShowHistoryLogDialog}
        />
      </Box>
      <ContainerInvoicesTable>
        <InvoicesTable
          handleShowVerifiedDialog={handleShowVerifiedDialog}
          handleShowCessionDialog={() => setShowCessionDialog(true)}
          handleShowPfxDialog={() => setShowPfxDialog(true)}
          handleRedirectToOrderById={handleRedirectTypeOrder}
          business={business}
          handleHistoryLogDialog={handleShowHistoryLogDialog}
          handleShowLoader={() => setShowLoader(true)}
          handleSetSelectedInvoices={handleSetSelectedInvoices}
        />
      </ContainerInvoicesTable>

      {showHistoryLogDialog && (
        <HistoryLogsDialog
          open={showHistoryLogDialog}
          handleCloseDialog={() => setShowHistoryLogDialog(false)}
          contextId={contextId}
          contextType={contextType}
        />
      )}

      {showSimulationDrawer && updatedOrder && (
        <SimulationVoucher
          isOpen={showSimulationDrawer}
          order={updatedOrder}
          handleCloseDrawer={() => setShowSimulationDrawer(false)}
        />
      )}

      {showFacturaStatusDialog && updatedOrder && (
        <StatusChangeDialog
          open={showFacturaStatusDialog}
          handleCloseDialog={handleCloseStatusDialog}
          item={selectedFactura}
          itemType={typeDialog}
          handleSubmit={handleSubmitDialog}
          orderId={updatedOrder?.orderId}
          loadingOrders={loadingOrders}
          subTitle="empty"
        />
      )}

      {showSimulationDialog && updatedOrder && (
        <NewSimulationDialog
          open={showSimulationDialog}
          handleCloseDialog={handleCloseNewSimulationDialog}
          handleFeesChange={handleFetchOrderFees}
          handleSubmitSimulation={handleSubmitSimulation}
          order={updatedOrder}
          operationType={updatedOrder.orderType}
        />
      )}

      {showCessionDialog && updatedOrder && (
        <FacturasCessionDialog
          orderId={updatedOrder.id}
          fetchFacturas={handleFetchInvoices}
          open={showCessionDialog}
          recession={recession}
          handleCloseDialog={() => setShowCessionDialog(false)}
          handleMakeCession={handleMakeCession}
          selectedInvoices={selectedInvoices}
        />
      )}

      {showPfxDialog && (
        <PfxUploadDialog
          open={showPfxDialog}
          handleClose={() => setShowPfxDialog(false)}
          handleSubmit={handleSubmitPfx}
        />
      )}

      {showMatchInvoicesPDFDialog && (
        <MatchProcessPdfDialog
          open={showMatchInvoicesPDFDialog}
          handleCloseDialog={() => setShowMatchInvoicesPDFDialog(false)}
        />
      )}

      {showCreditFormDialog && (
        <CreditFormDialog
          open={showCreditFormDialog}
          handleCloseDialog={handleCloseDialogs}
          handleSubmitForm={handleSubmitCreditDialog}
          type={DIALOG_TYPES.ORDERCREDIT}
          country={country}
        />
      )}

      {showLoader && (
        <LoaderComponent
          open={showLoader}
          handleCloseDialog={handleCloseDialogs}
        />
      )}
    </Box>
  );
};

export default HomeOperationDetail;
