import React from 'react';
import { Grid, styled } from '@material-ui/core';
import AttributeElement from './AttributeElement';
import { usePayrollAttributesTab } from '../../../hooks';
import CurrencyUpdateDialog from './CurrencyUpdateDialog';
import PaymentCessionDialog from './PaymentCessionDialog';

const CustomGridItem = styled(Grid)({
  marginBottom: 27,
});

const AttributesTab = () => {
  const {
    orderAttributes,
    showCurrencyDialog,
    showPaymentCessionDialog,
    handleClosePaymentCessionDialog,
    handleCloseCurrencyDialog,
  } = usePayrollAttributesTab();

  return (
    <Grid container>
      {orderAttributes.map(attribute => {
        const { title, value, flagType, isEditable, handleEdit } = attribute;
        return (
          <CustomGridItem item xs={4}>
            <AttributeElement
              title={title}
              value={value}
              flagType={flagType}
              isEditable={isEditable}
              handleEdit={handleEdit}
            />
          </CustomGridItem>
        );
      })}

      {showCurrencyDialog && (
        <CurrencyUpdateDialog
          open={showCurrencyDialog}
          handleClose={handleCloseCurrencyDialog}
        />
      )}

      {showPaymentCessionDialog && (
        <PaymentCessionDialog
          open={showPaymentCessionDialog}
          handleClose={handleClosePaymentCessionDialog}
        />
      )}
    </Grid>
  );
};

export default AttributesTab;
