// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCreateOperationData } from '../../../actions/businessAction';
import {
  resetCreateOrder,
  createOperation,
  createOperationNotEnrolled,
} from '../../../actions/orderActions';
import { CONFIRMING, ORDER_PATH } from '../../../helpers/Constants';
import useGetCountry from '../../../hooks/useGetCountry';
import {
  Simulation,
  CreateOperationData,
  InvoicesBulkUpdateType,
} from '../../commons/interfaces/ARInterfaces';

const useHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCreateOrderDialog, setShowCreateOrderDialog] = useState(false);
  const [showInvoicesBulkUpdateDialog, setShowInvoicesBulkUpdateDialog] =
    useState(false);
  const [
    showPartialPaymentsBulkUpdateDialog,
    setshowPartialPaymentsBulkUpdateDialog,
  ] = useState(false);
  const [showFundsBulkUpdateDialog, setShowFundsBulkUpdateDialog] =
    useState(false);
  const [invoicesBulkUpdateType, setInvoicesBulkUpdateType] =
    useState<InvoicesBulkUpdateType>('DATE');

  const country = useGetCountry();
  const business = useSelector(
    (state: RootStateOrAny) => state.business.business
  );
  const { createOrderIsLoading, createOrderError, orderId, orderWasCreated } =
    useSelector((state: RootStateOrAny) => state.order);

  const handleShowCreateOrderDialog = useCallback(() => {
    dispatch(resetCreateOperationData());
    setShowCreateOrderDialog(true);
  }, [setShowCreateOrderDialog, resetCreateOperationData]);

  const handleCloseCreateOrderDialog = useCallback(() => {
    setShowCreateOrderDialog(false);
    dispatch(resetCreateOperationData());
  }, [setShowCreateOrderDialog, resetCreateOperationData]);

  const handleShowInvoicesBulkUpdateDialog = useCallback(
    (bulkUpdateType: InvoicesBulkUpdateType) => {
      setShowInvoicesBulkUpdateDialog(true);
      setInvoicesBulkUpdateType(bulkUpdateType);
    },
    [setShowInvoicesBulkUpdateDialog, setInvoicesBulkUpdateType]
  );

  const handleCloseInvoicesBulkUpdateDialog = useCallback(() => {
    setShowInvoicesBulkUpdateDialog(false);
  }, [setShowInvoicesBulkUpdateDialog]);

  const handleShowPartialPaymentsBulkUpdateDialog = useCallback(() => {
    setshowPartialPaymentsBulkUpdateDialog(true);
  }, [setshowPartialPaymentsBulkUpdateDialog]);

  const handleClosePartialPaymentsBulkUpdateDialog = useCallback(() => {
    setshowPartialPaymentsBulkUpdateDialog(false);
  }, [setshowPartialPaymentsBulkUpdateDialog]);

  const handleShowFundsBulkUpdateDialog = useCallback(() => {
    setShowFundsBulkUpdateDialog(true);
  }, [setShowFundsBulkUpdateDialog]);

  const handleCloseFundsBulkUpdateDialog = useCallback(() => {
    setShowFundsBulkUpdateDialog(false);
  }, [setShowFundsBulkUpdateDialog]);

  const handleChangeAnchorEl = useCallback(
    (value: any) => {
      setAnchorEl(value);
    },
    [setAnchorEl]
  );

  const handleSubmitCreateOperation = useCallback(
    (
      orderType: string,
      {
        clientSelected,
        rawOrderInvoices,
        orderInvoices,
        feesData,
        businesses,
        newInvoices,
      }: CreateOperationData,
      simulation: Simulation,
      availableInvoicesCount: number,
      availableInvoicesAmount: number,
      autoRateType: string,
      currency: string
    ) => {
      const isBusinessEnrolled = business?.taxUserActive;

      if (
        !clientSelected.id ||
        orderType === CONFIRMING ||
        !isBusinessEnrolled ||
        newInvoices?.length
      ) {
        dispatch(
          createOperationNotEnrolled(
            orderType,
            {
              clientSelected,
              rawOrderInvoices,
              feesData,
              businesses,
            },
            simulation,
            availableInvoicesCount,
            availableInvoicesAmount,
            autoRateType,
            currency
          )
        );
      } else {
        dispatch(
          createOperation(
            orderType,
            {
              businessId: clientSelected.id,
              rawOrderInvoices,
              orderInvoices,
              feesData,
              businesses,
              newInvoices,
            },
            simulation,
            availableInvoicesCount,
            availableInvoicesAmount,
            autoRateType,
            currency
          )
        );
      }
    },
    [dispatch, createOperationNotEnrolled, createOperation, business]
  );

  useEffect(() => {
    if (orderWasCreated) setShowCreateOrderDialog(false);
    if (orderId && orderWasCreated) {
      const path = orderId
        ? `/${country.toLowerCase()}/orders/${orderId}`
        : `/${country.toLowerCase()}${ORDER_PATH.root}`;
      history.push(path);
      dispatch(resetCreateOrder());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orderWasCreated]);

  return {
    country,
    showCreateOrderDialog,
    anchorEl,
    createOrderIsLoading,
    createOrderError,
    showInvoicesBulkUpdateDialog,
    showPartialPaymentsBulkUpdateDialog,
    showFundsBulkUpdateDialog,
    invoicesBulkUpdateType,
    handleChangeAnchorEl,
    handleShowCreateOrderDialog,
    handleCloseCreateOrderDialog,
    handleSubmitCreateOperation,
    handleShowInvoicesBulkUpdateDialog,
    handleCloseInvoicesBulkUpdateDialog,
    handleShowPartialPaymentsBulkUpdateDialog,
    handleClosePartialPaymentsBulkUpdateDialog,
    handleShowFundsBulkUpdateDialog,
    handleCloseFundsBulkUpdateDialog,
  };
};

export default useHeader;
