import axios from 'axios';

// eslint-disable-next-line
export const uploadBankMovementsDocService = async (type, csvFile) => {
  try {
    const formData = new FormData();
    formData.append('xlsx', csvFile, csvFile?.name);

    const { data } = await axios.post(
      `/api/bankTransactions/upload/${type}`,
      formData
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
