import { useMutation } from 'react-query';
import { getRestrictiveInformationNewSimulationService } from '../services';

const useGetRestrictiveInformationNewSimulation = () => {
  const { isLoading, data, mutate } = useMutation(
    ({ operationType, operationId, businessId }) =>
      getRestrictiveInformationNewSimulationService({
        operationType,
        operationId,
        businessId,
      })
  );

  return {
    data,
    mutate,
    isLoading,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useGetRestrictiveInformationNewSimulation };
