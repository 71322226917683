/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
import {
  Typography,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { settings } from '../../config/settings';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import EditInvoiceDialog from '../dialogs/EditInvoiceDialog';
import { dateFormatter } from '../../helpers/DateUtils';
import { Delete } from '../icons';
import emptyBrowserIcon from '../../assets/icons/emptyBrowserIcon.svg';

import { ALT_BORDER_COLOR } from '../../theme/otherColors';
import Chip from '../elements/chips/Chip';

const useStylesComponent = {
  materialTableStylesGlobal: {
    borderRadius: 17,
    boxShadow: 'none',
    padding: 0,
    overflow: 'hidden',
    width: '100%',
  },
};

const useStyles = makeStyles(() => ({
  emptyResumeContainer: {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: 'transparent',
    width: '100%',
    border: `1.5px dashed ${ALT_BORDER_COLOR}`,
    boxSizing: 'border-box',
    borderRadius: '17px',
  },
  emptyResumeIcon: {
    margin: '35px auto 26px auto',
  },
}));

const InvoicesTableMinified = ({
  invoices = [],
  setInvoices,
  loading,
  handleRowSelection,
  emptyMessage,
}) => {
  const { country } = useSelector(state => state.config);

  const classes = useStyles();

  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [showEditInvoiceDialog, setShowEditInvoiceDialog] = useState(false);
  const [formKeys, setformKeys] = useState([{ key: 'tasaBase' }]);

  const checkExpirationDateCustom = date => {
    const momentDate = date ? moment.utc(date) : null;
    const momentToday = moment(Date.now());
    return momentDate ? momentToday.diff(momentDate, 'days') : null;
  };

  const calculateMntTotal = (mntTotal = 0, mntTotalCredit = 0) => {
    const subtractionMnt = mntTotal - mntTotalCredit;

    return (
      <CountryFormatHelper
        value={subtractionMnt}
        variant="currency"
        countryId="CL"
      />
    );
  };

  const numberFormat = (number = null) => {
    if (!number) return 0;
    return (
      <CountryFormatHelper value={number} variant="currency" countryId="CL" />
    );
  };

  const handleCloseEditDialog = () => {
    setSelectedInvoice({});
    setShowEditInvoiceDialog(false);
  };

  const handleOpenEditDialog = (invoice, field = 'rate') => {
    setSelectedInvoice(invoice);
    setformKeys([{ key: field }]);
    setShowEditInvoiceDialog(true);
  };

  const handleSubmit = (invoice, form) => {
    const { rate, expirationDate } = form;
    const invoicesCopy = invoices.map(d => {
      if (d.id === invoice.id) {
        return {
          ...d,
          rate: rate || d.rate,
          expirationDate: expirationDate || d.expirationDate,
        };
      }
      return d;
    });
    setInvoices(invoicesCopy);
    handleCloseEditDialog();
  };

  const buildInvoicesColumns = [
    {
      title: '',
      type: 'boolean',
      width: '30px',
      align: 'center',
      render: rowData => {
        const { id } = rowData;

        const handleDelete = () => {
          setInvoices(invoices.filter(invoice => invoice.id !== id));
        };

        return (
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        );
      },
    },
    {
      title: 'Folio',
      field: 'folio',
      type: 'numeric',
      defaultSort: 'asc',
      align: 'left',
      width: 100,
      render: rowData => {
        const { folio = '-' } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {folio}
          </Typography>
        );
      },
    },
    {
      title: 'Receptor',
      field: ' payerName',
      render: rowData => {
        const { payerName = '-', blackList = false } = rowData;

        if (!blackList)
          return (
            <Typography variant="body2" color="textPrimary">
              {payerName}
            </Typography>
          );
        return (
          <Tooltip title="Blocklist">
            <Chip variant="red" label={payerName} />
          </Tooltip>
        );
      },
    },
    {
      title: 'Rut Receptor',
      field: 'rutReceptor',
      render: rowData => {
        const { blackList = false, payerIdentifier = '' } = rowData;

        if (!blackList)
          return (
            <Typography variant="body2" color="textPrimary">
              {payerIdentifier}
            </Typography>
          );
        return (
          <Tooltip title="Blocklist">
            <Chip variant="red" label={payerIdentifier} />
          </Tooltip>
        );
      },
    },
    {
      title: 'Fecha Emisión',
      field: 'fechaEmision',
      type: 'date',
      render: rowData => {
        const { issueDate = '-' } = rowData;
        const cleanDate = dateFormatter(issueDate);
        return (
          <Typography variant="body2" color="textPrimary">
            {cleanDate}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha Vencimiento',
      field: 'expirationDate',
      type: 'date',
      render: rowData => {
        const { expirationDate = '' } = rowData;
        const daysPassedCount = checkExpirationDateCustom(expirationDate);
        const cleanDate = dateFormatter(expirationDate);
        const { pastInvoiceDaysLimit } = settings[country];
        return daysPassedCount && daysPassedCount > pastInvoiceDaysLimit ? (
          <Tooltip title={`> ${pastInvoiceDaysLimit} días`}>
            <Chip
              variant="red"
              label={cleanDate}
              onClick={() => {
                handleOpenEditDialog(rowData, 'expirationDate');
              }}
            />
          </Tooltip>
        ) : (
          <Link
            component="button"
            onClick={() => {
              handleOpenEditDialog(rowData, 'expirationDate');
            }}
            underline="always"
          >
            <Typography variant="body2">{cleanDate}</Typography>
          </Link>
        );
      },
    },
    {
      title: 'Monto total',
      field: 'mntTotal',
      type: 'numeric',
      align: 'left',
      render: rowData => {
        const { invoiceAmount, totalCreditNotesAmount } = rowData;

        if (!totalCreditNotesAmount || !invoiceAmount)
          return (
            <Typography variant="body2" color="textPrimary">
              {numberFormat(invoiceAmount)}
            </Typography>
          );

        const totalAmountWithCreditNote = calculateMntTotal(
          invoiceAmount,
          totalCreditNotesAmount
        );
        return (
          <Typography variant="body2" color="textPrimary">
            {totalAmountWithCreditNote};
          </Typography>
        );
      },
    },
    {
      title: 'Tasa Base',
      field: 'rate',
      type: 'numeric',
      align: 'left',
      render: rowData => {
        const { rate = '' } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            <Link
              onClick={() => {
                handleOpenEditDialog(rowData, 'rate');
              }}
              underline="always"
            >
              <CountryFormatHelper
                value={rate}
                variant="percentage"
                countryId={country}
              />
            </Link>
          </Typography>
        );
      },
    },
  ];

  return invoices.length ? (
    <>
      <EditInvoiceDialog
        open={showEditInvoiceDialog}
        formKeys={formKeys}
        invoice={selectedInvoice}
        handleCloseDialog={handleCloseEditDialog}
        onSubmitFnc={handleSubmit}
      />
      <MaterialTable
        columns={buildInvoicesColumns}
        data={invoices}
        style={useStylesComponent.materialTableStylesGlobal}
        options={{
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, 50, 100],
          emptyRowsWhenPaging: false,
          search: false,
          toolbar: false,
          draggable: false,
        }}
        isLoading={loading}
        onSelectionChange={handleRowSelection}
        components={{
          Pagination: props => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <PaginationMaterialTable {...props} />
          ),
          Toolbar: () => null,
        }}
        localization={{
          body: {
            emptyDataSourceMessage:
              'Ingresa uno o más folios para iniciar la búsqueda',
          },
          pagination: {
            labelRowsPerPage: 'Facturas por página',
            labelDisplayedRows: `${invoices.length} facturas totales`,
          },
        }}
      />
    </>
  ) : (
    <Grid className={classes.emptyResumeContainer}>
      <img
        src={emptyBrowserIcon}
        className={classes.emptyResumeIcon}
        alt="Top"
      />
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        gutterBottom
        noWrap
      >
        {emptyMessage}
      </Typography>
    </Grid>
  );
};

InvoicesTableMinified.defaultProps = {
  emptyMessage: 'Sin información.',
  handleRowSelection: () => null,
  loading: false,
};

InvoicesTableMinified.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.objectOf(Object)).isRequired,
  setInvoices: PropTypes.PropTypes.func.isRequired,
  loading: PropTypes.bool,
  handleRowSelection: PropTypes.func,
  emptyMessage: PropTypes.string,
};

export default InvoicesTableMinified;
