import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Text } from '../formatters';
import LoadingButton from '../../../components/elements/LoadingButton';

const CloseButton = styled(IconButton)({
  display: 'flex',
  marginLeft: 'auto',
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    textAlign: 'center',
    maxWidth: 'auto',
  },
})(MaterialDialog);

const CustomDialogTitle = styled(DialogTitle)({
  padding: '10px 10px 0 10px',
});

const CustomDialogContent = styled(DialogContent)({
  width: props => props.width,
  padding: '0 60px 0 60px',
});

const CustomDialogActions = styled(DialogActions)({
  padding: '20px 20px 40px 20px',
  justifyContent: 'center',
});

const Title = styled(Text)({
  marginBottom: 20,
});

const Dialog = ({
  isOpen,
  handleClose,
  handleAction,
  title,
  message,
  actionText,
  children,
  width,
  showCancelButton,
  customActionButton,
  isLoading,
  showActionButton,
  showDialogActions,
}) => {
  return (
    <CustomDialog open={isOpen}>
      <CustomDialogTitle>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </CustomDialogTitle>
      <CustomDialogContent width={width}>
        <Title variant="h5" fontWeight="bold">
          {title}
        </Title>
        <Text variant="h6">{message}</Text>
        {children}
      </CustomDialogContent>
      {showDialogActions && (
        <CustomDialogActions>
          {showCancelButton && (
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancelar
            </Button>
          )}
          {customActionButton ??
            (showActionButton && (
              <LoadingButton
                onClick={handleAction}
                variant="contained"
                color="primary"
                isLoading={isLoading}
                disabled={isLoading}
              >
                {actionText}
              </LoadingButton>
            ))}
        </CustomDialogActions>
      )}
    </CustomDialog>
  );
};

Dialog.defaultProps = {
  isOpen: false,
  title: undefined,
  message: undefined,
  children: undefined,
  width: 'auto',
  showCancelButton: true,
  customActionButton: null,
  handleAction: () => undefined,
  isLoading: false,
  showActionButton: true,
  showDialogActions: true,
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  actionText: PropTypes.string.isRequired,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCancelButton: PropTypes.bool,
  customActionButton: PropTypes.element,
  isLoading: PropTypes.bool,
  showActionButton: PropTypes.bool,
  showDialogActions: PropTypes.bool,
};

export default Dialog;
