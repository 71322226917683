import React from 'react';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Management } from '../../../../../commons/interfaces/managements';
import { t13s } from '../../../../../../translationKeys';
import { Column } from 'material-table';
import Text from '../../../../../commons/components/Text';
import { useGetManagementTypeLabel } from './useGetManagementTypeLabel';
import { useGetManagementSubtypeLabel } from './useGetManagementSubtypeLabel';
import { StatusChip } from '../../../../../../components/elements/chips';
import { useGetManagementsStatusChipProps } from './useGetManagementsStatusChipProps';

const { MANAGEMENTS_HISTORY_COLUMNS } = t13s.LABEL.COLLECTIONS;

export const useGetManagementsColumns = (): Column<Management>[] => {
  const { t } = useTranslation();
  const { getTypeLabel } = useGetManagementTypeLabel();
  const { getSubtypeLabel } = useGetManagementSubtypeLabel();
  const { getManagementsStatusChipProps } = useGetManagementsStatusChipProps();

  return [
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.REGISTER),
      field: 'type',
      width: '100px',
      resizable: true,
      sorting: false,
      render: (data: Management) => (
        <Text fontWeight="bold" variant="body1">
          {getTypeLabel(data.type)}
        </Text>
      ),
    },
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.CHANNEL),
      field: 'subType',
      width: '100px',
      resizable: true,
      sorting: false,
      render: (data: Management) => (
        <Text fontWeight="bold" variant="body1">
          {getSubtypeLabel({ type: data.type, subtype: data.subType })}
        </Text>
      ),
    },
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.REGISTERED_ON),
      field: 'sourceId',
      width: '100px',
      resizable: true,
      sorting: false,
      render: (data: Management) => (
        <Text fontWeight="bold" variant="body1">
          {data.sourceId}
        </Text>
      ),
    },
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.RESULT),
      field: 'result',
      width: '100px',
      resizable: true,
      sorting: false,
      render: (data: Management) => {
        if (data.result) {
          const { entityType, label, status } = getManagementsStatusChipProps(
            data.type,
            data.result
          );
          return (
            <StatusChip status={status} entityType={entityType} label={label} />
          );
        }
      },
    },
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.COMMENT),
      field: 'detail',
      width: '500px',
      resizable: true,
      sorting: false,
      render: (data: Management) => (
        <Typography color="textPrimary" variant="body2">
          {data.detail}
        </Typography>
      ),
    },
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.USER),
      field: 'createdBy',
      width: '120px',
      resizable: true,
      sorting: false,
      render: (data: Management) => (
        <Typography color="textPrimary" variant="body2">
          {data.createdBy}
        </Typography>
      ),
    },
    {
      title: t(MANAGEMENTS_HISTORY_COLUMNS.DATE),
      field: 'createdAt',
      width: '100px',
      resizable: true,
      sorting: false,
      render: (data: Management) => (
        <Typography color="textPrimary" variant="body2">
          {data.createdAt instanceof Date &&
            format(data.createdAt, 'dd-MM-yyyy')}
        </Typography>
      ),
    },
  ];
};
