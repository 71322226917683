import React, { FC } from 'react';
import FileUploadDialog from '../../../../commons/components/FileUploadModal';
import { csvErrorsReportBuilder } from '../../../../../helpers/CsvErrorsReportBuilder';
import usePartialPaymentsBulkUpdate from './infrastructure/store/usePartialPaymentsBulkUpdate';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const PartialPaymentsBulkUpdateModal: FC<Props> = ({ open, handleClose }) => {
  const {
    uploadFile,
    resetDataAndErrors,
    isLoading,
    hasErrors,
    serverError,
    isSuccess,
    errorLines,
  } = usePartialPaymentsBulkUpdate();

  const TITLE = 'Ingreso masivo de Pagos Parciales';
  const DESCRIPTION =
    'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

  const CSV_TEMPLATE = [
    {
      sourceType: 'orderinvoice',
      sourceId: 212428,
      amount: 1000,
      paymentDate: '2021-01-02',
      comment: '-',
    },
  ];

  const onClose = () => {
    handleClose();
    resetDataAndErrors();
  };

  if (!isLoading && isSuccess && !hasErrors && !serverError) {
    onClose();
  }

  return (
    <FileUploadDialog
      open={open}
      handleCloseDialog={onClose}
      handleLoad={uploadFile}
      saving={isLoading}
      errorsCsv={csvErrorsReportBuilder(errorLines)}
      serverError={serverError ? String(serverError) : null}
      title={TITLE}
      description={DESCRIPTION}
      csvTemplate={CSV_TEMPLATE}
      resetDataAndErrors={resetDataAndErrors}
    />
  );
};

export default PartialPaymentsBulkUpdateModal;
