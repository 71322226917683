import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

import { i18n as i18nCreditLine } from './features/credit-line/components/BusinessCreditLine';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'CL',
    fallbackLng: {
      CL: ['es'],
      MX: ['es'],
    },
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/translations.json',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on('languageChanged', lng => {
  i18nCreditLine.changeLanguage(lng);
});

export default i18n;
