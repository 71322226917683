import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../helpers/Constants';
import { PendingRequirementResponse } from '../../interfaces';

const fetchInvoicePendingRequirements = async (
  operationId: number,
  requirementId: number
) => {
  try {
    const { data } = await axios.get<PendingRequirementResponse[]>(
      `/api/requirement/orderinvoice/${operationId}/${requirementId}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fetchInvoicePendingRequirements;
