import { useQuery } from 'react-query';
import { ManagementDebt } from '../../../commons/interfaces/managementDebts';
import { fetchManagementDebtByInvoice } from '../services/fetchManagementDebtByInvoice';

const useFetchManagementsDebtByInvoice = (orderInvoiceId: number) => {
  const placeholderData: ManagementDebt[] = []

  const {
    isLoading,
    isPlaceholderData,
    data,
    refetch,
    status,
    error,
    isError,
  } = useQuery<ManagementDebt[]>(
    ['getManagementDebts', orderInvoiceId],
    async () => fetchManagementDebtByInvoice(orderInvoiceId),
    {
      placeholderData,
      enabled: !!orderInvoiceId,
    }
  );

  return {
    data,
    isLoading: isLoading || isPlaceholderData,
    refetch,
    status,
    error,
    isError,
  };
};

export default useFetchManagementsDebtByInvoice;
