import React from 'react';
import { Grid } from '@material-ui/core';
import TransferAccountsTable from './TransferAccountsTable';
import { useBankAccountsTab } from '../../../hooks';
import {
  EditTransferAccountDrawer,
  TransferAccountsDrawer,
  BankAccountSelectorDrawer,
  AddBankAccountDrawer,
} from '../../drawers';

const TransferAccountsTab = () => {
  const {
    bankAccounts,
    fetchTransferAccountsIsLoading,

    showBankAccountDrawer,
    handleOpenBackButton,

    showEditBankAccountDrawer,
    handleEditBackButton,
    handleShowEditBankAccountDrawer,
    handleCloseEditBankAccountDrawer,
    selectedBankAccount,
    showBankAccountSelectorDrawer,
    bankAccountIsAssigning,
    handleCopyBankAccount,
    handleShowBankAccountDrawer,
    handleCloseBankAccountDrawer,
    handleShowBankAccountSelectorDrawer,
    handleCloseBankAccountSelectorDrawer,
    handleAssignSupplierBankAccountBulk,

    showAddBankAccountDrawer,
    handleShowAddBankAccountDrawer,
    handleAddBankAccountDrawer,
    bankAccountIsCreating,
    handleCloseAddBankAccountDrawer,
    supplierIdentifier,
    supplierName,
  } = useBankAccountsTab();

  return (
    <Grid container>
      {showBankAccountDrawer && (
        <TransferAccountsDrawer
          isOpen={showBankAccountDrawer}
          selectedBankAccount={selectedBankAccount}
          handleOpenBackButton={handleOpenBackButton}
          handleCopyBankAccount={handleCopyBankAccount}
          handleCloseBankAccountDrawer={handleCloseBankAccountDrawer}
        />
      )}

      {showAddBankAccountDrawer && (
        <AddBankAccountDrawer
          open={showAddBankAccountDrawer}
          handleClose={handleCloseAddBankAccountDrawer}
          supplierName={supplierName}
          supplierIdentifier={supplierIdentifier}
          handleSubmit={handleAddBankAccountDrawer}
          bankAccountIsCreating={bankAccountIsCreating}
        />
      )}

      {showBankAccountSelectorDrawer && (
        <BankAccountSelectorDrawer
          open={showBankAccountSelectorDrawer}
          handleClose={handleCloseBankAccountSelectorDrawer}
          handleSubmit={handleAssignSupplierBankAccountBulk}
          assignedBankAccountId={selectedBankAccount?.bankAccountId}
          supplierPaymentsId={selectedBankAccount?.supplierPaymentsId}
          bankAccountIsAssigning={bankAccountIsAssigning}
          handleShowAddBankAccountDrawer={handleShowAddBankAccountDrawer}
        />
      )}

      {showEditBankAccountDrawer && (
        <EditTransferAccountDrawer
          isOpen={showEditBankAccountDrawer}
          selectedBankAccount={selectedBankAccount}
          handleEditBackButton={handleEditBackButton}
          handleCopyBankAccount={handleCopyBankAccount}
          handleCloseEditBankAccountDrawer={handleCloseEditBankAccountDrawer}
        />
      )}

      <TransferAccountsTable
        bankAccounts={bankAccounts}
        isLoading={fetchTransferAccountsIsLoading}
        handleShowBankAccountDrawer={handleShowBankAccountDrawer}
        handleShowEditBankAccountDrawer={handleShowEditBankAccountDrawer}
        handleShowBankAccountSelectorDrawer={
          handleShowBankAccountSelectorDrawer
        }
      />
    </Grid>
  );
};

export default TransferAccountsTab;
