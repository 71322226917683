import {
    Typography,
    Box,
    styled,
    Divider as DividerMui,
  } from '@material-ui/core';
  
  const LabelInput = styled(Typography)({
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 20,
  });
  
  const ContainerInput = styled(Box)({
    padding: '0 15px 20px 15px',
  });
  
  const Divider = styled(DividerMui)({
    marginBottom: 20,
  });
  
  const ContainerListResponsibles = styled(Box)({
    minHeight: 615,
  });
  
  const TextBold = styled(Typography)({
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 25,
  });
  
  const ContainerSection = styled(Box)({
    padding: '0 15px',
    marginBottom: 30,
  });
  const TextSubTitle = styled(Typography)({
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#8D8A96',
  });
  const ContainerRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  });
  const RowText = styled(Typography)({
    fontSize: 14,
  });
  
  const TextSmall = styled(Typography)({
    fontSize: 12,
    textAlign: 'center',
  });
  
  export {
    LabelInput,
    ContainerInput,
    Divider,
    ContainerListResponsibles,
    TextBold,
    ContainerSection,
    TextSubTitle,
    ContainerRow,
    RowText,
    TextSmall,
  };
