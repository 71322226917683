import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, MenuItem } from '@material-ui/core';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import BaseDialog from '../../../../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../../../../components/elements/CheckButton';
import { useSelectedInvoices } from '../../store';
import {
  useCessionAction,
  useFetchCessionaries,
  useOrder,
} from '../../../../../infrastructure/store';
import useInvoiceCessionDialog from '../../../../../hooks/useInvoiceCessionDialog';
import { STATUS_REJECTED } from '../../../../../../../helpers/Constants';

const InvoiceCessionDialog = ({ handleCloseDialog, open }) => {
  const wsconnected = useSelector(state => state.ws.wsconnected);
  const { id: orderId } = useParams();
  const {
    order: { businessId },
  } = useOrder(orderId);
  const selectedInvoices = useSelectedInvoices();
  const { cessionaries } = useFetchCessionaries();
  const [cessionaryId, setCessionaryId] = useState(1);
  const [check, setCheck] = useState(false);
  const filteredInvoices = selectedInvoices.filter(
    invoice => invoice.status !== STATUS_REJECTED
  );
  const {
    cessionSuccess,
    requestCessionError,
    requestingCession,
    makeCession,
  } = useCessionAction(filteredInvoices, cessionaryId, businessId);

  useInvoiceCessionDialog({
    requestCessionError,
    cessionSuccess,
    wsconnected,
    handleCloseDialog,
  });

  const handleSelectChange = value => {
    setCessionaryId(value);
  };

  const handleSubmit = () => {
    makeCession();
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={() => handleCloseDialog()}
      title="Cesión de facturas"
    >
      {requestingCession ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <ValidatorForm onSubmit={() => handleSubmit()}>
          <SelectValidator
            fullWidth
            variant="outlined"
            onChange={e => handleSelectChange(e.target.value)}
          >
            {cessionaries?.map(({ name, id }) => {
              return (
                <MenuItem key={name} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </SelectValidator>
          <CheckButton
            check={check}
            disabled={!cessionaries.length}
            handleCheck={() => {
              setCheck(!check);
            }}
          />
        </ValidatorForm>
      )}
    </BaseDialog>
  );
};

InvoiceCessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default InvoiceCessionDialog;
