export const resetDraftReceiverUseCase = receiver => {
  const { value, invoiceNumber, ...currentReceiver } = receiver;

  return {
    ...currentReceiver,
    invoiceNumber: invoiceNumber || value.length,
    term: null,
    value,
  };
};
