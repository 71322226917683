import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { Tooltip, IconButton, Grid, Button } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PropTypes from 'prop-types';
import Can from '../../Can';
import {
  INVOICES_BULK_PERFORM,
  ORDERS_CESSION_PERFORM,
  ORDERS_BULK_AEC_PERFORM,
  INVOICES_BULK_FUND_PERFORM,
  INVOICES_DOWNLOAD_PDF_PERFORM,
} from '../../../helpers/performsType';
import { features } from '../../../config/features';
import LoadingButton from '../../elements/LoadingButton';

const useStyles = makeStyles({
  padding: {
    padding: 10,
  },
  button: {
    margin: '5px 10px',
  },
});

const InvoiceToolbar = ({
  handleCession,
  handleShowBulkChangeDialog,
  handleShowBulkFundDialog,
  getFacturas,
  handleDownloadAEC,
  hanldeMassassignableinvoicepdf,
  selectedInvoices,
  openAddInvoices,
  hasPfxCertificate,
}) => {
  const classes = useStyles();
  const downloadingAec = useSelector(state => state.order.downloadingAec);
  const country = useSelector(state => state.config.country);

  const countryFeatures = features[country];

  const cessionButtonEnabled =
    selectedInvoices.length > 0 &&
    selectedInvoices.every(
      ({ cessionStatus, previousCessionId }) =>
        (cessionStatus === 'FAILED' && previousCessionId === null) ||
        cessionStatus === undefined
    );

  return (
    <div>
      <Grid container direction="row" justify="space-between">
        <Grid item className={classes.padding} />
        <Grid item className={classes.padding}>
          <Grid container>
            {countryFeatures.orderActions.showAddInvoice && (
              <Grid item>
                <Button
                  startIcon={<AddCircleIcon fontSize="small" />}
                  color="primary"
                  variant="contained"
                  onClick={openAddInvoices}
                  className={classes.button}
                  data-cy="invoiceToolbarAddInvoicesButton"
                >
                  Agregar facturas
                </Button>
              </Grid>
            )}
            {countryFeatures.orderActions.showDownloadAllPdfInvoiceButton && (
              <Grid item className={classes.button}>
                <Can
                  perform={INVOICES_DOWNLOAD_PDF_PERFORM}
                  yes={() => (
                    <Button
                      disabled={
                        selectedInvoices.length < 1 || !hasPfxCertificate
                      }
                      color="primary"
                      variant="contained"
                      onClick={hanldeMassassignableinvoicepdf}
                    >
                      Descargar múltiples Facturas
                    </Button>
                  )}
                />
              </Grid>
            )}
            {countryFeatures.orderActions.showBulkAEC && (
              <Grid item className={classes.button}>
                <Can
                  perform={ORDERS_BULK_AEC_PERFORM}
                  yes={() => (
                    <LoadingButton
                      disabled={selectedInvoices.length < 2}
                      isLoading={downloadingAec}
                      fullWidth
                      color="primary"
                      type="submit"
                      variant="contained"
                      onClick={handleDownloadAEC}
                    >
                      Descargar AEC
                    </LoadingButton>
                  )}
                />
              </Grid>
            )}

            {countryFeatures.orderActions.showCessionButton && (
              <Grid item>
                <Can
                  perform={ORDERS_CESSION_PERFORM}
                  yes={() => (
                    <Button
                      disabled={!cessionButtonEnabled}
                      color="primary"
                      variant="contained"
                      onClick={handleCession}
                      className={classes.button}
                    >
                      Ceder
                    </Button>
                  )}
                />
              </Grid>
            )}
            <Can
              perform={INVOICES_BULK_PERFORM}
              yes={() => (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleShowBulkChangeDialog}
                    disabled={selectedInvoices.length < 2}
                    className={classes.button}
                  >
                    Cambio masivo de facturas
                  </Button>
                </Grid>
              )}
            />
            {countryFeatures.orderActions.showBulkLinkedFund && (
              <Can
                perform={INVOICES_BULK_FUND_PERFORM}
                yes={() => (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleShowBulkFundDialog}
                      disabled={selectedInvoices.length < 2}
                      className={classes.button}
                    >
                      Asignación masiva de fondo
                    </Button>
                  </Grid>
                )}
              />
            )}
            <Grid item>
              <Tooltip title="Actualizar estado de cesiones">
                <IconButton onClick={getFacturas}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

InvoiceToolbar.defaultProps = {
  selectedInvoices: [],
};

InvoiceToolbar.propTypes = {
  handleCession: PropTypes.func.isRequired,
  handleShowBulkChangeDialog: PropTypes.func.isRequired,
  handleShowBulkFundDialog: PropTypes.func.isRequired,
  hanldeMassassignableinvoicepdf: PropTypes.func.isRequired,
  getFacturas: PropTypes.func.isRequired,
  handleDownloadAEC: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(Object),
  openAddInvoices: PropTypes.func.isRequired,
  hasPfxCertificate: PropTypes.bool.isRequired,
};

export default withTheme(InvoiceToolbar);
