import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FileUploadDialog } from '../../../commons/components';
import { useInvoicesBulkStatusUpdate } from '../../infrastructure/store';
import { csvErrorsReportBuilder } from '../../../../helpers/CsvErrorsReportBuilder';

const InvoicesBulkStatusUpdateModal = ({ open, handleClose }) => {
  const { country } = useSelector(state => state.config);

  const {
    uploadFile,
    resetDataAndErrors,
    isLoading,
    hasErrors,
    serverError,
    isSuccess,
    errorLines,
  } = useInvoicesBulkStatusUpdate();
  const TITLE = 'Actualización masiva de estados de facturas';
  const DESCRIPTION =
    'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

  const CSV_TEMPLATE = {
    orderInvoiceId: '200320',
  };

  if (!open || (isSuccess && !hasErrors)) {
    return null;
  }

  const onClose = () => {
    handleClose();
    resetDataAndErrors();
  };

  return (
    <FileUploadDialog
      open={open}
      handleCloseDialog={onClose}
      handleLoad={uploadFile}
      saving={isLoading}
      errorsCsv={csvErrorsReportBuilder(errorLines)}
      serverError={serverError}
      title={TITLE}
      description={DESCRIPTION}
      csvTemplate={CSV_TEMPLATE[country]}
      actionText="Subir archivo"
      resetDataAndErrors={resetDataAndErrors}
    />
  );
};

InvoicesBulkStatusUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InvoicesBulkStatusUpdateModal;
