// TODO: funds borrar luego de sacar de nominas
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Typography,
  Grid,
  Box,
  AppBar,
  Toolbar,
  CircularProgress,
  Drawer,
  makeStyles,
} from '@material-ui/core';

import { Close } from '../icons';
import { ALT_BORDER_COLOR, DRAWER_SECONDARY } from '../../theme/otherColors';
import CountryFormatHelper from './CountryFormatHelper';
import { fixDecimals, percentageFormat } from '../../utils';
import { t13s } from '../../translationKeys';
import { fetchReceivers } from '../../actions/fundInvoicesAction';
import { roundToInt } from '../../funds/commons/utils';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '380px',
    background: 'white',
    '& p': {
      margin: 0,
      paddingTop: 12,
      paddingBottom: 12,
      '&:hover': {
        backgroundColor: '#E8E6EF',
      },
    },
  },
  appBar: {
    padding: '20px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    position: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      bottom: 0,
      left: 20,
      width: 'calc(100% - 40px)',
      height: 1,
      borderBottom: '1px solid #CDCAD8',
    },
  },
  toolBar: {
    minHeight: 'auto',
    padding: 0,
  },
  navTitle: {
    flex: '1',
  },
  drawerTitle: {
    fontSize: 18,
  },
  container: {
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 700,
    padding: '25px 20px',
    backgroundColor: 'white',
    '& p span': {
      float: 'right',
      color: '#312F37',
      textAlign: 'right',
    },
    '& p': {
      color: '#8D8A96',
    },
  },
  subTitle: {
    display: 'block',
    fontSize: 16,
    fontWeight: 400,
    color: '#8D8A96',
    marginTop: 0,
    marginBottom: 5,
  },
  companyName: {
    fontSize: 20,
    fontWeight: 700,
    color: '#312F37',
    marginTop: 0,
    marginBottom: 5,
  },
  companyIdentifier: {
    marginTop: 0,
    marginBottom: 30,
    fontSize: 16,
  },
  within: {
    paddingLeft: '12px',
    '&::before': {
      content: '"-"',
    },
  },
  containerSearchBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  containerBody: {
    display: 'flex',
  },
  containerOperationResume: {
    padding: '30px 20px',
    background: '#fff',
    borderRadius: 10,
    width: 300,
    minWidth: 260,
    height: 345,
    border: `1px solid ${ALT_BORDER_COLOR}`,
    marginRight: 15,
  },
  titleOperationResume: {
    marginBottom: 20,
  },
  selectFundOperationResume: {
    marginBottom: 10,
    width: '100%',
  },
  informationFundOperationResume: {
    marginBottom: 20,
    width: '100%',
  },
  informationFundContainerValues: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  spacingBottom: {
    marginBottom: 8,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  totalAmountContainer: {
    marginBottom: 25,
    background: '#1C1732',
    borderRadius: 7,
    padding: '5px 0px',
    width: '100%',
  },
  containerTables: {
    width: '100%',
  },
  hasEllipsis: {
    width: 230,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const FundDebtorDetail = ({ showDrawer, handleCloseDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fundPayerDetailInfo, fundPayers, fundPayersFormFields } = useSelector(
    state => state.fundInvoices
  );
  const { country } = useSelector(state => state.config, shallowEqual);
  const { t } = useTranslation();

  const { payerIdentifier } = fundPayerDetailInfo;

  const currentReceiver = fundPayers?.find(
    payer => payer.receiverIdentifier === payerIdentifier
  );

  useEffect(() => {
    if (payerIdentifier) {
      dispatch(
        fetchReceivers({
          ...fundPayersFormFields,
          identifier: payerIdentifier,
        })
      );
    }
  }, [payerIdentifier, dispatch]);

  return (
    <Drawer
      anchor="right"
      width="380"
      open={showDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleCloseDrawer}
      disableEscapeKeyDown
    >
      {!payerIdentifier && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      )}

      {payerIdentifier && (
        <>
          <AppBar position="static" className={classes.appBar} elevation={0}>
            <Toolbar className={classes.toolBar}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className={classes.navTitle}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={classes.drawerTitle}
                  >
                    Detalle pagador
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleCloseDrawer}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <span className={classes.subTitle}>Pagador</span>
              <h4 className={classes.companyName}>{currentReceiver?.name}</h4>
              <p className={classes.companyIdentifier}>
                {t(t13s.LABEL.BUSINESS_IDENTIFIER)}: {payerIdentifier}
              </p>
              <p>
                Monto bruto:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.amount}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={classes.within}>
                Activo:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.activeAmount}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={classes.within}>
                Cedido:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.noActiveAssignee}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={classes.within}>
                No cedido:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.noActiveNoAssignee}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p>
                Nº facturas: <span>{currentReceiver?.invoiceNumber}</span>
              </p>
              <p className={classes.within}>
                Financiamiento Directo:
                <span>{currentReceiver?.directFinancing}</span>
              </p>
              <p className={classes.within}>
                Pronto Pago:
                <span>{currentReceiver?.earlyPayment}</span>
              </p>
              <p className={classes.within}>
                Payments Con Cesión:
                <span>{currentReceiver?.paymentsWithCession}</span>
              </p>
              <p className={classes.within}>
                Payments Sin Cesión:
                <span>{currentReceiver?.paymentsWithoutCession}</span>
              </p>
              <p>
                Nº Emisores: <span>{currentReceiver?.issuersNumber}</span>
              </p>
              <p>Plazo:</p>
              <p className={classes.within}>
                Max:
                <span>{currentReceiver?.maxTerm}</span>
              </p>
              <p className={classes.within}>
                Min:
                <span>{currentReceiver?.minTerm}</span>
              </p>
              <p className={classes.within}>
                Ponderado:
                <span>{roundToInt(currentReceiver?.avgTerm)}</span>
              </p>
              <p>
                Score Ponderado:
                <span>{fixDecimals(currentReceiver?.score)}</span>
              </p>
              <p>
                % AR de facturas sin Mérito:
                <span>
                  {percentageFormat(currentReceiver?.acknowledgementPercentage)}
                </span>
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </Drawer>
  );
};

FundDebtorDetail.defaultProps = {
  showDrawer: false,
};

FundDebtorDetail.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool,
};

export default FundDebtorDetail;
