import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { defaultValue } from '../../../../../utils';
import { Close } from '../../../../../components/icons';

import { ArrowLeft, TrashBin, ChevronDown } from '../../../../commons/icons';
import * as St from './SummaryCart.styles';
import { useFetchDocuments } from '../../../domains/document/services';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import { usePayrollSelector } from '../../../domains/payroll/state';
import { useGetCountry } from '../../../../adapters/store';
import { FUNDS_BASE_PATH } from '../../../../commons/constants/ui';

export const SummaryCart = ({ showDrawer, handleCloseDrawer }) => {
  const classes = St.useStyles();
  const history = useHistory();
  const [selectedReceiverId, setSelectedReceiverId] = useState();
  const [openCartAccordion, setOpenCartAccordion] = useState('');
  const { documents, isFetching } = useFetchDocuments(
    {
      receiverIdentifier: selectedReceiverId,
    },
    { enabled: !!selectedReceiverId, refetchOnMount: false }
  );
  const payrollSelector = usePayrollSelector();
  const { summary, deleteReceivers } = usePayrollSelectorController();
  const country = useGetCountry();

  return (
    <Drawer
      anchor="right"
      width="380"
      open={showDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleCloseDrawer}
      disableEscapeKeyDown
    >
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolBar}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={classes.navTitle}>
              <Typography
                variant="h6"
                component="div"
                className={classes.drawerTitle}
              >
                Carrito
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleCloseDrawer}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Grid container>
        <Grid item xs={12} className={classes.cartSummary}>
          <strong style={{ marginRight: 10, display: 'inline-block' }}>
            {summary.totalReceivers}&nbsp;pagador
            {summary.totalReceivers > 1 && 'es'}
          </strong>
          ({summary.totalInvoices}&nbsp;factura
          {summary.totalInvoices > 1 && 's'})
        </Grid>
      </Grid>
      {!Object.keys(payrollSelector).length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      )}
      <Grid container className={classes.cartBody}>
        {Object.keys(payrollSelector)?.map(receiverIdentifier => {
          const { name, value, amount } = payrollSelector[receiverIdentifier];
          return (
            <Grid
              key={receiverIdentifier}
              item
              xs={12}
              className={classes.singleDebtor}
              hidden={!receiverIdentifier}
            >
              <h4>{name}</h4>
              <TrashBin
                className={classes.trashBinIcon}
                onClick={() => {
                  deleteReceivers([receiverIdentifier]);
                  if (Object.keys(payrollSelector).length === 1) {
                    handleCloseDrawer();
                  }
                }}
              />
              <p>
                Monto total:&nbsp;
                <CountryFormatHelper
                  countryId={country}
                  value={amount}
                  variant="currency"
                />
              </p>
              <div
                className={`${classes.invoicesDetail} ${
                  openCartAccordion === receiverIdentifier && 'open'
                }`}
              >
                <Link
                  className={classes.summaryText}
                  onClick={() => {
                    setSelectedReceiverId(receiverIdentifier);
                    if (openCartAccordion !== receiverIdentifier) {
                      setOpenCartAccordion(receiverIdentifier);
                    } else {
                      setOpenCartAccordion('');
                    }
                  }}
                >
                  {value?.length} factura{value?.length > 1 && 's'}
                  <ChevronDown className={classes.chevronDown} />
                </Link>
                <div className={classes.invoicesSummary}>
                  {isFetching && (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', p: 5 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  {!isFetching &&
                    documents &&
                    value.map(({ id }) => {
                      const {
                        folio,
                        issuer,
                        amount: documentAmount,
                      } = documents?.find(document => document.id === id) || {};
                      return (
                        <div key={id}>
                          <span className="folio">{folio}</span>
                          <span
                            className={`${classes.hasEllipsis} issuer-name`}
                            title={issuer?.name}
                          >
                            {defaultValue(issuer?.name)}
                          </span>
                          <span className="amount">
                            <CountryFormatHelper
                              value={documentAmount}
                              countryId={country}
                              variant="currency"
                            />
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <Grid container className={classes.cartFooter}>
        <Grid item xs={12} className={classes.footerSummary}>
          <span>Monto bruto:</span>&nbsp;
          <span>
            <CountryFormatHelper
              value={summary.amount}
              countryId={country}
              variant="currency"
            />
          </span>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            style={{ width: '100%' }}
            variant="contained"
            onClick={() => {
              history.push(
                // eslint-disable-next-line max-len
                `/${country.toLowerCase()}/${FUNDS_BASE_PATH}/opportunities/creation`
              );
            }}
          >
            Enviar a fondo
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.grayText}>
          <Link className={classes.collapseDrawer} onClick={handleCloseDrawer}>
            <ArrowLeft />
            Seguir agregando facturas
          </Link>
        </Grid>
      </Grid>
    </Drawer>
  );
};

SummaryCart.defaultProps = {
  showDrawer: false,
};

SummaryCart.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool,
};
