import {
  FETCH_ALL_RESPONSIBLES_START,
  FETCH_ALL_RESPONSIBLES_SUCCESS,
  FETCH_RESPONSIBLES_START,
  FETCH_RESPONSIBLES_SUCCESS,
  CREATE_RESPONSIBLE_START,
  CREATE_RESPONSIBLE_SUCCESS,
  DELETE_RESPONSIBLE_START,
  DELETE_RESPONSIBLE_SUCCESS,
} from './types';

export const fetchAllResponsibles = () => dispatch => {
  dispatch({
    type: FETCH_ALL_RESPONSIBLES_START,
  });
  setTimeout(() => {
    dispatch({
      type: FETCH_ALL_RESPONSIBLES_SUCCESS,
      payload: {
        allResponsibles: [
          {
            id: 1,
            fullname: 'Oscar Valenzuela',
            role: ['admin'],
          },
          {
            id: 2,
            fullname: 'Dani Romero',
            role: ['Cobranza'],
          },
          {
            id: 3,
            fullname: 'Pepito asd',
            role: ['Cobranza', 'Jefe de Cobranza'],
          },
          {
            id: 4,
            fullname: 'Ramiro cantaFuerte',
            role: ['Kam'],
          },
          {
            id: 5,
            fullname: 'Jimena patasLargas',
            role: ['Cobranza'],
          },
        ],
      },
    });
  }, 1000);
};

// eslint-disable-next-line no-unused-vars
export const fetchResponsibles = (sourceId, sourceType) => dispatch => {
  dispatch({
    type: FETCH_RESPONSIBLES_START,
  });
  setTimeout(() => {
    dispatch({
      type: FETCH_RESPONSIBLES_SUCCESS,
      payload: {
        responsibles: [
          {
            id: 1,
            fullname: 'Oscar Valenzuela',
            role: ['admin'],
          },
        ],
      },
    });
  }, 700);
};

export const createResponsible = (sourceId, sourceType, body) => dispatch => {
  dispatch({
    type: CREATE_RESPONSIBLE_START,
  });
  setTimeout(() => {
    dispatch({
      type: CREATE_RESPONSIBLE_SUCCESS,
      payload: { newResponsible: body },
    });
  }, 500);
};

export const deleteResponsible = sourceId => dispatch => {
  // Todo
  dispatch({
    type: DELETE_RESPONSIBLE_START,
  });
  setTimeout(() => {
    dispatch({
      type: DELETE_RESPONSIBLE_SUCCESS,
      payload: { responsibleId: sourceId },
    });
  }, 500);
};
