/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FUNDS_EDIT_PERFORM,
  FUNDS_STATUS_PERFORM,
} from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';
import { STATUS_ACTIVE } from '../../helpers/Constants';
import Can from '../Can';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import FundRatesDetailCard from '../elements/FundRatesDetailCard';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import Panel from '../elements/Panel';
import { Edit } from '../icons';
import { settings } from '../../config/settings';
import { t13s } from '../../translationKeys';

const useStyles = {
  mainContainer: {
    padding: 20,
    background: '#F5F5F5',
  },
  paperContainer: {
    padding: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  addButton: {
    color: '#1A73E7',
  },
  statusRow: {
    display: 'flex',
    alignItems: 'center',
  },
  switch: {
    margin: 0,
  },
};

const checkDeprecatedActive = active => {
  if (typeof active === 'string') {
    return active === STATUS_ACTIVE;
  }
  return active;
};

const FundsTable = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    handleFundFormDialog,
    handleFundRateDialog,
    classes,
    tableData,
    handleFundRates,
    handleShowEditFormDialog,
    handleShowBankAccountDialog,
    handleUpdatedFundState,
    page,
    limit,
    updatePageAndLimit,
    totalData,
  } = props;

  const { loadingFunds } = useSelector(state => state.funds);
  const { user, rules } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const handleShowFormDialog = fund => {
    if (fund) handleFundFormDialog(fund);
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      type: 'numeric',
      width: '30px',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData.id}
          </Typography>
        );
      },
    },
    {
      title: 'Nombre',
      field: 'name',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData.name}
          </Typography>
        );
      },
    },
    {
      title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)}`,
      field: 'rut',
      type: 'string',
      width: '130px',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.rut ?? rowData?.debtordni}
          </Typography>
        );
      },
    },
    {
      title: 'Tasa Financiamiento Directo',
      field: 'rateDirectFinancing',
      type: 'numeric',
      width: '40px',
      render: rowData => (
        <Typography variant="body2" color="textPrimary">
          <CountryFormatHelper
            variant="percentage"
            countryId={country}
            value={rowData.rateDirectFinancing}
          />
        </Typography>
      ),
    },
    {
      title: 'Tasa Pronto pago',
      field: 'rateEarlyPayment',
      type: 'numeric',
      width: '40px',
      render: rowData => (
        <Typography variant="body2" color="textPrimary">
          <CountryFormatHelper
            variant="percentage"
            countryId={country}
            value={rowData.rateEarlyPayment}
          />
        </Typography>
      ),
    },
    {
      title: 'Tasa Confirming',
      field: 'rateConfirming',
      type: 'numeric',
      width: '40px',
      render: rowData => (
        <Typography variant="body2" color="textPrimary">
          <CountryFormatHelper
            variant="percentage"
            countryId={country}
            value={rowData.rateConfirming}
          />
        </Typography>
      ),
    },
    {
      title: 'Dirección',
      field: 'address',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.address ?? rowData?.ContactDetail?.address}
          </Typography>
        );
      },
    },
    {
      title: 'Nombre Contacto',
      field: 'contactName',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData?.contactName ?? rowData?.ContactDetail?.name}
          </Typography>
        );
      },
    },
    {
      title: 'Teléfono Contacto',
      field: 'contactPhone',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData.contactPhone ?? rowData?.ContactDetail?.phone}
          </Typography>
        );
      },
    },
    {
      title: 'Email Contacto',
      field: 'contactEmail',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData.contactEmail ?? rowData?.ContactDetail?.email}
          </Typography>
        );
      },
    },
    {
      title: 'Estado',
      field: 'active',
      name: 'active',
      type: 'string',
      lookup: {
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
      },
      render: rowData => {
        const active = checkDeprecatedActive(rowData.active);
        const onClick = checkAuth(user.roles, FUNDS_STATUS_PERFORM, rules)
          ? e => {
              e.stopPropagation();
              handleUpdatedFundState({
                ...rowData,
                active: !active,
              });
            }
          : () => {};

        return (
          <Grid container direction="row" xs={12} className={classes.statusRow}>
            <Can
              perform={FUNDS_EDIT_PERFORM}
              yes={() => (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleShowFormDialog(rowData);
                  }}
                >
                  <Edit />
                </IconButton>
              )}
            />
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch size="small" checked={active} onClick={onClick} />
              }
            />
          </Grid>
        );
      },
    },
  ];

  return (
    <Panel
      title="Fondos"
      titlePadding="xl xl zero"
      contentPadding="zero zero sm"
    >
      <MaterialTable
        columns={columns}
        data={tableData}
        title={null}
        isLoading={loadingFunds}
        style={{ width: '100%', boxShadow: 'none' }}
        components={{
          Pagination: props =>
            isApiGlobal ? (
              <PaginationMaterialTable
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                count={totalData}
                page={page - 1}
                rowsPerPage={limit}
                onChangePage={(e, page) =>
                  updatePageAndLimit(Number(page + 1), limit)
                }
                onChangeRowsPerPage={(e, row) => {
                  // eslint-disable-next-line react/prop-types
                  updatePageAndLimit(page, row.props.value);
                }}
              />
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <PaginationMaterialTable {...props} />
            ),
        }}
        options={{
          pageSize: limit,
          pageSizeOptions: [5, 10, 20, 50, 100],
          detailPanelType: 'single',
          emptyRowsWhenPaging: false,
          initialPage: 1,
        }}
        detailPanel={[
          {
            disabled: true,
            render: fund => {
              return (
                <FundRatesDetailCard
                  fund={fund}
                  history={history}
                  handleFundRatesDetail={handleFundRates}
                  handleAddRateFormDialog={(operation, fundId) =>
                    handleFundRateDialog(operation, fundId)
                  }
                  handleEditStepperFormDialog={handleShowEditFormDialog}
                  handleShowBankAccountDialog={handleShowBankAccountDialog}
                />
              );
            },
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
      />
    </Panel>
  );
};

FundsTable.defaultProps = {
  page: undefined,
  limit: undefined,
  updatePageAndLimit: undefined,
  totalData: undefined,
};

FundsTable.propTypes = {
  handleFundFormDialog: PropTypes.func.isRequired,
  handleFundRateDialog: PropTypes.func.isRequired,
  handleFundRates: PropTypes.func.isRequired,
  handleShowEditFormDialog: PropTypes.func.isRequired,
  handleUpdatedFundState: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      rut: PropTypes.string,
      rateDirectFinancing: PropTypes.string,
      rateEarlyPayment: PropTypes.string,
      rateConfirming: PropTypes.string,
      address: PropTypes.string,
      contactName: PropTypes.string,
      contactPhone: PropTypes.string,
      contactEmail: PropTypes.string,
      active: PropTypes.string,
    })
  ).isRequired,
  handleShowBankAccountDialog: PropTypes.func.isRequired,
  page: PropTypes.number,
  limit: PropTypes.number,
  updatePageAndLimit: PropTypes.func,
  totalData: PropTypes.number,
};

export default withStyles(useStyles)(FundsTable);
