import React, { FC, PropsWithChildren } from 'react';
import { Box, styled } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface Props extends PropsWithChildren {
  isLoading?: boolean;
}

const TableSkeleton = styled(Box)({
  borderBottom: '1px solid #CDCAD8',
});

export const InvoicePendingRequirementsSkeleton: FC<Props> = ({
  isLoading,
  children,
}) => {
  return (
    <>
      {!isLoading ? (
        children
      ) : (
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={4}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width={150}
              height={150}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={22}
                  height={30}
                />
              </Box>
              <Skeleton
                animation="wave"
                variant="text"
                width={205}
                height={30}
              />
            </Box>
            <Skeleton animation="wave" variant="text" width={40} height={30} />
          </Box>
          <Box mb={4}>
            <Skeleton
              animation="wave"
              variant="text"
              width={'100%'}
              height={14}
            />
          </Box>
          <Box px={4}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
              <TableSkeleton
                display="flex"
                justifyContent="space-around"
                py={2}
              >
                {[1, 2, 3, 4].map(() => (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={40}
                    height={25}
                  />
                ))}
              </TableSkeleton>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default InvoicePendingRequirementsSkeleton;
