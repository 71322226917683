import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const uploadBulkFundsService = async bulkInvoices => {
  try {
    const { data } = await axios.post(
      `/api/orderfactura/bulk-fund`,
      bulkInvoices
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { uploadBulkFundsService };
