import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const History = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4C9.536 4 7.332 5.114 5.865 6.865L8 9H2V3L4.447 5.446C6.28 3.336 8.984 2 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z" />
    </SvgIcon>
  );
};

export default History;
