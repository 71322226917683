import { Grid, styled, Link } from '@material-ui/core';
import React from 'react';
import { useSelectedPayrollActionBar } from '../../hooks';
import ChangeStatusOrderModal from './ChangeStatusOrderModal';
import { Text } from '../../../commons/formatters';
import { Button } from '../../../commons/components';
import { EditIcon } from '../../../commons/icons';

const SelectedPayrollActionBar = () => {
  const {
    totalSelectedPayrolls,
    handleClearSelection,
    handlebulkUpdateStatus,
    isChangeStatusOrderOpen,
    openChangeStatusOrderModal,
    closeChangeStatusOrderModal,
  } = useSelectedPayrollActionBar();

  const Wrapper = styled(Grid)(({ theme }) => ({
    background: theme.palette.payments.softBlue,
    alignItems: 'center',
    padding: '12px 24px',
  }));

  const SelectionDetails = styled(Grid)({
    display: 'flex',
    gap: 16,
  });

  const SelectedText = styled(Text)(({ theme }) => ({
    display: 'inline-block',
    color: theme.palette.primary.main,
  }));

  const UndoSelection = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  }));

  const EditStateButtonWrapper = styled(Grid)({
    marginLeft: 'auto',
  });

  if (totalSelectedPayrolls <= 0) return null;

  return (
    <Wrapper container>
      <ChangeStatusOrderModal
        open={isChangeStatusOrderOpen}
        handleCloseDialog={closeChangeStatusOrderModal}
        handleSubmit={handlebulkUpdateStatus}
        title="Cambiar estado de las operaciones"
        isLoading={false}
        error={false}
        warning={false}
      />
      <SelectionDetails item>
        <SelectedText variant="h6" fontWeight="fontWeightBold">
          {totalSelectedPayrolls} operaciones seleccionadas
        </SelectedText>
        <UndoSelection
          variant="h6"
          fontWeight="fontWeightBold"
          onClick={handleClearSelection}
        >
          Deshacer selección
        </UndoSelection>
      </SelectionDetails>
      <EditStateButtonWrapper item>
        <Button startIcon={<EditIcon />} onClick={openChangeStatusOrderModal}>
          Editar Estado
        </Button>
      </EditStateButtonWrapper>
    </Wrapper>
  );
};

export default SelectedPayrollActionBar;
