import { themeOverrides } from './themeOverrides';
import { palette } from './palette';
import { typography } from './typography';

// eslint-disable-next-line import/prefer-default-export
export const theme = {
  palette,
  typography,
  overrides: themeOverrides,
  spacing: [0, 5, 10, 15, 20, 30],
};
