import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Xtree = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M16 9C16 9.875 15.6562 10.625 15.0312 11.2188C14.4688 11.75 13.6875 12 12.9062 12H9V15C9 15.5625 8.5312 16 8 16C7.4375 16 6.9688 15.5625 6.9688 15L7 12H3.0625C2.28125 12 1.5 11.75 0.9375 11.2188C0.3125 10.625 0 9.875 0 9C0 8.4062 0.15625 7.875 0.5 7.375C0.8125 6.875 1.28125 6.5312 1.84375 6.2812C1.53125 5.78125 1.4375 5.28125 1.5 4.75C1.5625 4.21875 1.78125 3.75 2.15625 3.375C2.5 3 2.9375 2.71875 3.4375 2.59375C3.9375 2.46875 4.46875 2.5 5 2.71875C5.0625 1.96875 5.40625 1.3125 5.96875 0.78125C6.5312 0.28125 7.2188 0 8 0C8.75 0 9.4375 0.28125 10 0.78125C10.5625 1.3125 10.9062 1.96875 11 2.71875C11.5 2.5 12.0312 2.46875 12.5312 2.59375C13.0312 2.71875 13.4688 3 13.8438 3.375C14.1875 3.75 14.4062 4.21875 14.4688 4.75C14.5312 5.28125 14.4375 5.78125 14.1875 6.2812H14.1562C14.7188 6.5312 15.1562 6.875 15.5 7.375C15.8125 7.875 16 8.4062 16 9Z" />
    </SvgIcon>
  );
};

export default Xtree;
