import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import EditRateDialog from '../../../../../components/dialogs/EditRateDialog';
import Panel from '../../../../../components/elements/Panel';
import { TABLE_OPTIONS } from '../../../../commons/constants/ui';
import { usePayrollActions } from '../../../adapters/store/payroll';
import { usePayrollDetailContext, VIEW_TYPES } from '../../../context';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import {
  useFetchReceivers,
  useUpdateReceivers,
} from '../../../domains/receiver/services';
import { GET_COLUMNS } from './constants';
import { useStyles } from './PayersTable.styles';

export const PayersTable = () => {
  const { t } = useTranslation();
  const css = useStyles();
  const [showEditRateDialog, setShowEditRateDialog] = useState(false);
  const [showEditEffectiveFundRateDialog, setShowEditEffectiveFundRateDialog] =
    useState(false);
  const [editedReceiver, setEditedReceiver] = useState();
  const payrollDetailCtx = usePayrollDetailContext();
  const { selectReceiver, updatePayrollSummary } = usePayrollActions();
  const { addReceiver } = usePayrollSelectorController();
  const { isFetching, receivers, refetch } = useFetchReceivers(
    payrollDetailCtx.receiverForm
  );
  const { mutateAsync: updateReceivers, isLoading: isUpdating } =
    useUpdateReceivers({
      onSuccess: res => {
        refetch();
        updatePayrollSummary(res.payrollSummary);
      },
      onError: () => {
        payrollDetailCtx.setIsBulkUpdating(false);
      },
    });

  const handleOnClickReceiver = rowData => {
    selectReceiver(rowData);
    payrollDetailCtx.changeToInvoiceView(VIEW_TYPES.INVOICE);
  };

  const onEditRate = receiver => {
    setEditedReceiver(receiver);
    setShowEditRateDialog(true);
  };
  const onEditEffectiveFundRate = receiver => {
    setEditedReceiver(receiver);
    setShowEditEffectiveFundRateDialog(true);
  };

  const submitRate = res => {
    updatePayrollSummary();
    updateReceivers({
      receivers: [{ receiverId: editedReceiver.receiverId, rate: res }],
    });
    setShowEditRateDialog(false);
  };

  const submitEffectiveFundRate = res => {
    updateReceivers({
      receivers: [
        { receiverId: editedReceiver.receiverId, effectiveFundCostRate: res },
      ],
    });
    setShowEditEffectiveFundRateDialog(false);
  };

  const generateSubtitle = receiverName => (
    <>
      Se editarán las facturas del pagador
      <Box fontWeight="fontWeightBold">{receiverName}</Box>
    </>
  );

  const columns = GET_COLUMNS({
    country: payrollDetailCtx.country,
    t,
    handleOnClickReceiver,
    onEditRate,
    onEditEffectiveFundRate,
  });

  return (
    <>
      <Grid item xs={12} className={css.tableContainer}>
        <Panel
          title="Pagadores"
          titlePadding="lg"
          contentPadding="zero lg zero"
        >
          <MaterialTable
            columns={columns}
            data={receivers}
            style={{ width: '100%', boxShadow: 'none' }}
            isLoading={
              isFetching ||
              isUpdating ||
              payrollDetailCtx.isDeleting ||
              payrollDetailCtx.isBulkUpdating
            }
            components={{
              Pagination: () => null,
              Toolbar: () => null,
            }}
            options={{
              ...TABLE_OPTIONS,
              selectionProps: sp => {
                return {
                  indeterminate: sp.tableData.indeterminate,
                };
              },
            }}
            onSelectionChange={rowsSelected =>
              addReceiver(rowsSelected, receivers)
            }
            localization={{
              pagination: {
                labelDisplayedRows: '{count} pagadores en total',
                labelRowsPerPage: 'Pagadores por página',
                labelRowsSelect: 'pagadores',
              },
              body: {
                emptyDataSourceMessage: 'No hay pagadores para mostrar',
              },
            }}
          />
          <EditRateDialog
            open={showEditRateDialog}
            title="Editar Tasa Ponderada"
            label="Tasa Fondo Ponderada (%)"
            subTitle={() => generateSubtitle(editedReceiver?.receiver)}
            value={editedReceiver?.averageFundRate || 0}
            handleSubmit={submitRate}
            handleReset={submitRate}
            handleCloseDialog={() => {
              setShowEditRateDialog(false);
            }}
            loading={false}
          />
          <EditRateDialog
            open={showEditEffectiveFundRateDialog}
            title="Editar Tasa Costo Fondo Efectiva"
            label="Tasa Costo Fondo Efectiva (%)"
            subTitle={() => generateSubtitle(editedReceiver?.receiver)}
            value={editedReceiver?.averageEffectiveFundRate}
            handleSubmit={submitEffectiveFundRate}
            handleReset={submitEffectiveFundRate}
            handleCloseDialog={() => {
              setShowEditEffectiveFundRateDialog(false);
            }}
            loading={false}
          />
        </Panel>
      </Grid>
    </>
  );
};
