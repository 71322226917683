import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { fundsBulkUpdateService } from '../services';

export const useFundsBulkUpdate = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    data,
    error,
    mutate: uploadFile,
    reset: resetDataAndErrors,
  } = useMutation(
    csvFile => {
      return fundsBulkUpdateService(csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.FUNDS_BULK_UPDATE_SUCCESS, {
              variant: 'success',
            })
          );
        }
      },
    }
  );

  const errorLines = data?.errorLines;
  const hasErrors = errorLines || isError;
  const serverError = error || null;

  return {
    isLoading,
    errorLines,
    serverError,
    hasErrors,
    uploadFile,
    resetDataAndErrors,
  };
};

export default useFundsBulkUpdate;
