import { styled, makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
    detailLabel: {
      maxWidth: 330,
      wordWrap: 'break-word'
    }
});

export {
  useStyles,
};