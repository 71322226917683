/* eslint-disable react/no-array-index-key */
import { Box, Grid, Link, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import LoadingButton from '../../../../../components/elements/LoadingButton';
import { useBoActions, useGetCountry } from '../../../../adapters/store';
import { Skeleton } from '../../../../commons/components/Skeleton';
import { FEATURES } from '../../../../commons/constants/features';
import { FUNDS_BASE_PATH } from '../../../../commons/constants/ui';
import { Download } from '../../../../commons/icons';
import { useOpportunitiesContext } from '../../../context/OpportunitiesContext';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import { useDraftPayrollSelectorController } from '../../../domains/payroll/controllers/useDraftPayrollSelectorController';
import {
  useCheckPayrollPdf,
  useDownloadPayrollDraft,
  useFetchFunds,
  useFetchPdfSummary,
  useNotifyMissingPdfs,
  useSendPayroll,
} from '../../../domains/payroll/services';
import {
  useGetSelectedFund,
  useGetSimulation,
  usePayrollActions,
  usePayrollSelector,
} from '../../../domains/payroll/state';
import { UpdateFundRateDialog } from '../UpdateFundRateDialog';
import { GET_SUMMARY } from './constants';
import { FilesAndPayrollDialog } from './FilesAndPayrollDialog';
import { FundSuccessPayrollDialog } from './FundSuccessPayrollDialog';
import * as St from './PayrollSummary.styles';
import { AssignedInvoicesDialog } from './AssignedInvoicesErrorDialog/AssignedInvoicesDialog';
import { useFetchReceivers } from '../../../domains/receiver/services';

export const PayrollSummary = () => {
  const css = St.useStyles();
  const history = useHistory();
  const opportunitiesCtx = useOpportunitiesContext();
  const [openEditRateDialog, setOpenEditRateDialog] = useState(false);
  const [openEditEffectiveFundRateDialog, setOpenEditEffectiveFundRateDialog] =
    useState(false);
  const [openEditCapitalCostRateDialog, setOpenEditCapitalCostRateDialog] =
    useState(false);
  const [payrollId, setPayrollId] = useState();
  const [openFilesAndPayrollDialog, setOpenFilesAndPayrollDialog] =
    useState(false);
  const [openDialogPayrollSuccess, setOpenDialogPayrollSuccess] =
    useState(false);
  const [openAssignedInvoicesErrorDialog, setOpenAssignedInvoicesErrorDialog] =
    useState(false);
  const [assignedIds, setAssignedIds] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [afterAssignCheckPayroll, setAfterAssignPayroll] = useState();
  const country = useGetCountry();
  const { showSnackBar } = useBoActions();
  const { cleanPayrollSelector } = usePayrollActions();
  const selectedFund = useGetSelectedFund();
  const payrollSelector = usePayrollSelector();
  const simulation = useGetSimulation();
  const { data, isFetching } = useFetchFunds({ refetchOnMount: false });
  const { mutateAsync: sendPayroll, isLoading: isLoadingSendPayroll } =
    useSendPayroll();
  const { editSummaryRate, selectFund, resetSimulation, summary } =
    usePayrollSelectorController();
  const fundHasPdf = !!selectedFund?.entityFeatures?.find(
    feature => feature.name === FEATURES.INVOICE_HAS_PDF
  );
  const {
    resetDraftReceiver,
    updatePayrollByAssignedInvoices,
    deleteDraftDocuments,
  } = useDraftPayrollSelectorController();
  const {
    data: pdfSummary,
    isFetching: isFetchingPdfSummary,
    refetch: reFetchGetPdfSummary,
  } = useFetchPdfSummary(payrollId, {
    enabled: false,
    onError: () => {
      setOpenDialogPayrollSuccess(false);
    },
  });
  const { mutateAsync: notifyMissingPdfs } = useNotifyMissingPdfs();
  const { mutateAsync: downloadPayrollDraft, isLoading: isDownloading } =
    useDownloadPayrollDraft();
  const { mutateAsync: checkPayrollPdf } = useCheckPayrollPdf();
  const currentSelectorState = useMemo(
    () => payrollSelector || {},
    [payrollSelector]
  );
  const { refetch: refetchReceivers } = useFetchReceivers(
    opportunitiesCtx.reqFilters,
    {
      refetchOnMount: true,
    }
  );
  const isLoading = isFetching || !!simulation?.isLoading;
  const xlsxReady = !isLoading && !isDownloading && selectedFund && simulation;
  const summaryFields = GET_SUMMARY({
    country,
    isLoading,
    setOpenEditRateDialog,
    setOpenEditEffectiveFundRateDialog,
    setOpenEditCapitalCostRateDialog,
    simulation,
    summary,
  });

  const onSendPayroll = () => {
    if (fundHasPdf) {
      setOpenFilesAndPayrollDialog(true);
    }
    sendPayroll(
      {
        countryId: country,
        fundId: selectedFund.id,
        payrollSelector,
      },
      {
        onSuccess: res => {
          setPayrollId(res.payrollId);
          if (!fundHasPdf) {
            setOpenDialogPayrollSuccess(true);

            return;
          }

          checkPayrollPdf(res.payrollId);
          reFetchGetPdfSummary();
          notifyMissingPdfs(res.payrollId);
        },
        onError: ({ response }) => {
          if (response.data.errorCode === 'ERROR_INVOICES_ALREADY_ASSIGNED') {
            setOpenFilesAndPayrollDialog(false);
            const { data: errorResponse } = response.data;
            setAfterAssignPayroll(errorResponse);
            setTotalDocuments(
              Object.keys(currentSelectorState).reduce(
                (acc, receiver) =>
                  acc + currentSelectorState[receiver].value.length,
                0
              )
            );
            setAssignedIds(errorResponse);
            setOpenAssignedInvoicesErrorDialog(true);
          }
          showSnackBar({
            msg: 'Ocurrió un error, vuelve a intentar',
            type: 'error',
          });
        },
      }
    );
  };

  const onReSend = () => {
    setOpenDialogPayrollSuccess(false);
    resetSimulation();
    resetDraftReceiver();
  };

  const goBackToOpportunities = () => {
    Promise.resolve(() =>
      history.push(`/${country.toLowerCase()}/${FUNDS_BASE_PATH}/opportunities`)
    )
      .then(cleanPayrollSelector)
      .then(refetchReceivers);
  };

  const onNoThanks = () => {
    setOpenDialogPayrollSuccess(false);
    goBackToOpportunities();
  };

  const onAssignedErrorReceived = () => {
    deleteDraftDocuments(assignedIds);
    setOpenAssignedInvoicesErrorDialog(false);
    setAssignedIds([]);
    resetSimulation();
    resetDraftReceiver();
  };

  const onBackToSelection = () => {
    setOpenAssignedInvoicesErrorDialog(false);
    setAssignedIds([]);
    goBackToOpportunities();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={css.containerOperationResume}
      >
        <ValidatorForm
          className={css.selectFundOperationResume}
          onSubmit={() => {}}
        >
          <Skeleton width={237} height={50} isLoading={isFetching}>
            <Autocomplete
              value={selectedFund?.name || ''}
              disableClearable
              disabled={isLoading}
              getOptionSelected={(option, value) => option === value}
              options={data?.map(fund => fund?.name)}
              renderInput={p => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField variant="outlined" {...p} label="Fondo" />
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              style={{ width: '100%' }}
              onChange={(_, newValue) => {
                selectFund(
                  data.find(f => f.name === newValue),
                  opportunitiesCtx.viewType
                );
              }}
            />
          </Skeleton>
        </ValidatorForm>

        <Typography
          variant="body1"
          color="textPrimary"
          className={css.titleOperationResume}
          style={{ fontWeight: 'bold' }}
        >
          Resumen operación
        </Typography>

        <Box display="flex" className={css.informationFundOperationResume}>
          {summaryFields.map(({ value, label }, idx) => (
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={css.spacingBottom}
                  key={idx}
                  style={{ fontWeight: 'bold' }}
                >
                  {label}
                </Typography>
              </Grid>

              <Grid item xs={6} className={css.informationFundContainerValues}>
                <React.Fragment key={idx}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={clsx(css.spacingBottom, css.textAlignRight)}
                    style={{ fontWeight: 'bold' }}
                  >
                    {value}
                  </Typography>
                </React.Fragment>
              </Grid>
            </Grid>
          ))}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={css.totalAmountContainer}
          marginTop="20px"
        >
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            <Box color="primary.contrastText">Monto a Giro</Box>
          </Typography>
          <Skeleton
            height={13}
            width={140}
            style={{ background: 'white' }}
            isLoading={isLoading}
          >
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              <Box color="primary.contrastText">
                <CountryFormatHelper
                  value={
                    simulation?.totalPayrollInvoiceAmount || summary.amount
                  }
                  countryId={country}
                  variant="currency"
                />
              </Box>
            </Typography>
          </Skeleton>
        </Box>

        <LoadingButton
          color="primary"
          disabled={isLoading || !selectedFund || isLoadingSendPayroll}
          isLoading={isLoadingSendPayroll}
          onClick={onSendPayroll}
          variant="contained"
        >
          Enviar
        </LoadingButton>

        <Link
          className={`${!xlsxReady ? 'disabled' : 'enabled'} ${
            css.xlsxDownloadLink
          }`}
          component="button"
          variant="body1"
          aria-disabled={!xlsxReady}
          title={
            !xlsxReady
              ? 'Debes elegir un fondo primero'
              : 'Descargar borrador de nómina'
          }
          onClick={() => {
            downloadPayrollDraft({
              interestFormula: selectedFund.interestFormula,
              receivers: currentSelectorState,
            });
          }}
        >
          <Download style={{ width: 10, height: 12 }} />
          Descargar .xlsx
        </Link>
      </Box>

      {openEditRateDialog && (
        <UpdateFundRateDialog
          isOpen={openEditRateDialog}
          handleCloseDialog={() => setOpenEditRateDialog(false)}
          title="Editar tasa ponderada"
          label="Tasa Fondo Ponderada (%)"
          description="Si editas la tasa se sobreescribirá en todas las facturas."
          selectedRate={{ rate: simulation?.averageRate }}
          handleSubmit={({ rate }) => {
            editSummaryRate({ rate });
            setOpenEditRateDialog(false);
          }}
        />
      )}
      {openEditEffectiveFundRateDialog && (
        <UpdateFundRateDialog
          isOpen={openEditEffectiveFundRateDialog}
          handleCloseDialog={() => setOpenEditEffectiveFundRateDialog(false)}
          title="Editar tasa costo fondo efectiva"
          label="Tasa Costo Fondo Efectiva (%)"
          description="Si editas la tasa se sobreescribirá en todas las facturas."
          selectedRate={{ rate: simulation?.averageEffectiveFundCostRate }}
          handleSubmit={({ rate }) => {
            editSummaryRate({ effectiveFundCostRate: rate });
            setOpenEditEffectiveFundRateDialog(false);
          }}
        />
      )}
      {openEditCapitalCostRateDialog && (
        <UpdateFundRateDialog
          isOpen={openEditCapitalCostRateDialog}
          handleCloseDialog={() => setOpenEditCapitalCostRateDialog(false)}
          title="Editar tasa costo capital Xepelin"
          label="Tasa Costo Capital Xepelin (%)"
          description="Si editas la tasa se sobreescribirá en todas las facturas."
          selectedRate={{ rate: simulation?.averageCapitalCostRate }}
          handleSubmit={({ rate }) => {
            editSummaryRate({ capitalCostRate: rate });
            setOpenEditCapitalCostRateDialog(false);
          }}
        />
      )}

      <FundSuccessPayrollDialog
        isOpen={openDialogPayrollSuccess}
        handleCloseDialog={onReSend}
        handleCloseManageInvoice={onNoThanks}
      />

      {openAssignedInvoicesErrorDialog && (
        <AssignedInvoicesDialog
          handleCloseManageInvoice={onBackToSelection}
          handleCloseDialog={onAssignedErrorReceived}
          isOpen={openAssignedInvoicesErrorDialog}
          totalDocuments={totalDocuments}
          assignedDocuments={assignedIds}
          agreePayrollUpdate={() =>
            updatePayrollByAssignedInvoices(afterAssignCheckPayroll)
          }
        />
      )}

      <FilesAndPayrollDialog
        isOpen={openFilesAndPayrollDialog}
        isLoading={isLoadingSendPayroll || isFetchingPdfSummary}
        summary={pdfSummary}
        onClose={() => {
          setOpenFilesAndPayrollDialog(false);
          onNoThanks();
        }}
        onReSend={() => {
          setOpenFilesAndPayrollDialog(false);
          onReSend();
        }}
        onDownload={() => {
          //
        }}
      />
    </>
  );
};
