import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateBankAccountService = async ({
  source,
  sourceId,
  bankAccount,
  bankAccountId,
}) => {
  try {
    const { data } = await axios.patch(
      `/api/payment/${source}/${sourceId}/bankaccount/${bankAccountId}`,
      bankAccount
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateBankAccountService;
