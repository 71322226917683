import {
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  CONFIRMING,
  GRAY_BORDER_COLOR,
  LABEL_TOTAL_AMOUNT_CREDIT_NOTES,
  DECIMAL_PRECISION,
  COUNTRY_CODE_MX,
} from '../../helpers/Constants';
import { INVOICE_RESUME, ALT_BORDER_COLOR } from '../../theme/otherColors';
import CountryFormatHelper from './CountryFormatHelper';
import DataList from './DataList';

import { TogglePanel, Error } from '../icons';
import loadingIcon from '../../assets/loading.gif';

import { newSimulation } from '../../actions/orderActions';
import LoadingButton from './LoadingButton';

const useStyles = makeStyles(theme => ({
  containerButton: {
    padding: 30,
  },
  classSection: {
    borderRadius: 17,
    border: `1px solid ${GRAY_BORDER_COLOR}`,
    padding: 0,
  },
  containerSimulation: {
    paddingTop: 40,
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginBottom12: {
    marginBottom: 12,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom33: {
    marginBottom: 33,
  },
  retentionPositive: {
    color: theme.palette.success.main,
  },
  retentionNegative: {
    color: theme.palette.error.main,
  },
  buttonLabel: {
    color: theme.palette.primary.main,
  },
  containerIconButtonDataList: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  resumeContainer: {
    backgroundColor: INVOICE_RESUME.altBackgroundColor,
    borderRadius: '15px',
    padding: '20px',
    width: 'min-content',
    minWidth: '400px',
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
  },
  sideResumeContainer: {
    background: INVOICE_RESUME.altBackgroundColor,
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
    boxSizing: 'border-box',
    borderRadius: '0px 17px 17px 0px',
    padding: '19px',
    paddingRight: '0',
    position: 'absolute',
    top: '0',
    left: '100%',
    marginLeft: '20px',
    marginTop: '-20px',
    width: 'max-content',
  },
  invoiceAmountContainer: {
    width: '95%',
    padding: '10px 54px',
    background: INVOICE_RESUME.backgroundColor,
    borderRadius: '7px',
    color: INVOICE_RESUME.textColor,
    margin: '10px auto 20px auto',
  },
  invoiceAmountContainerEmpty: {
    background: INVOICE_RESUME.emptyBackgroundColor,
    color: INVOICE_RESUME.emptyTextColor,
  },
  relativeContainer: {
    position: 'relative',
    width: '100%',
  },
  togglePanel: {
    top: '-4px',
  },
  toggleOpen: {
    color: INVOICE_RESUME.toggleOpen,
  },
  toggleClose: {
    color: INVOICE_RESUME.toggleClose,
  },
}));

const InvoiceOperationResume = ({
  order,
  invoicesData,
  orderSummary,
  orderSummaryWasLoaded,
  manageSelectedInvoices,
  buttonText,
  buttonDisabled,
  buttonIcon,
  buttonIsLoading,
  showHint,
  action,
}) => {
  const { orderSimulation, orderSimulationWasLoaded } = useSelector(
    state => state.order
  );

  const { id: orderId, type: orderType } = order;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.config);
  const [orderResume, setOperationResume] = useState([]);
  const [operationSimulation, setOperationSimulation] = useState([]);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationOrderId, setSimulationOrderId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);

  const item = {
    title: 'Comparar',
    data: orderResume,
    type: 'operation',
  };

  const amountTotalCreditNotes = (total, invoice) => {
    let amountTotal = total;
    if (invoice?.SiiFactura?.SiiNotasCredito?.detMntTotal) {
      const {
        SiiFactura: {
          SiiNotasCredito: { detMntTotal },
        },
      } = invoice;
      amountTotal += Number(detMntTotal);
    }
    return amountTotal;
  };

  const isNoteCreditPresent = () => {
    if (country === COUNTRY_CODE_MX) {
      return false;
    }
    const { orderInvoices = [] } = order;
    const orderNoteCredit = orderInvoices.length
      ? orderInvoices.find(
          invoice => invoice.SiiFactura && invoice.SiiFactura.SiiNotasCredito
        )
      : null;
    return !!orderNoteCredit;
  };

  const getTextToolTip = orderInvoices => {
    const total = orderInvoices.reduce(amountTotalCreditNotes, 0);
    return (
      <Typography variant="body1">
        {LABEL_TOTAL_AMOUNT_CREDIT_NOTES}:
        <CountryFormatHelper
          value={total}
          variant="currency"
          countryId={country}
        />
      </Typography>
    );
  };

  const orderAttributes = (simulation = false) => {
    const obj = simulation ? orderSimulation : orderSummary;
    return [
      {
        label: 'Monto total',
        type: 'currency',
        data: obj?.totalAmount,
      },
      {
        label: 'Porcentaje de anticipo',
        type: 'percentage',
        data: obj?.advancePercentage,
      },
      {
        label: 'Anticipo',
        type: 'currency',
        data: obj?.advance,
      },
      {
        label: 'Retención',
        type: 'currency',
        data: obj?.retention,
        suffix: isNoteCreditPresent() ? (
          <Tooltip title={getTextToolTip()}>
            <Error color="primary" />
          </Tooltip>
        ) : (
          ''
        ),
      },
      {
        label: 'Tasa base',
        data: obj?.baseRate?.toFixed(DECIMAL_PRECISION),
        type: 'percentage',
      },
      {
        label:
          orderType === CONFIRMING ? 'Aporte pagador' : 'Diferencia de precio',
        type: 'currency',
        data: obj?.interest,
      },
      {
        label: 'Asesoría digital',
        type: 'currency',
        data: obj?.operationCost,
      },
      {
        label: 'Descuentos a facturas',
        type: 'currency',
        data: obj?.totalDiscounts,
      },
      {
        label: 'Descuento a operación',
        type: 'currency',
        data: obj?.transferDiscount,
      },
      {
        label:
          orderType === CONFIRMING
            ? 'Transferencia aporte pagador'
            : 'Monto de transferencia',
        type: 'currency',
        data: obj?.transfer - obj?.transferDiscount || obj?.finalAmount,
      },
    ];
  };

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const simulate = () => {
    const { invoices } = invoicesData;
    setLoadingSimulation(true);
    setOperationSimulation([]);
    setSimulationData({ ...invoices });
    setSimulationOrderId(orderId);
    const body =
      action === 'move'
        ? { extraOrderInvoices: invoices }
        : { extraInvoices: invoices };
    dispatch(newSimulation(orderId, body));
  };

  const checkSimulation = () => {
    if (orderId !== simulationOrderId) {
      return false;
    }
    const { invoices: invoicesToCompare } = invoicesData;
    let simulationDataToCompare;
    if (typeof simulationData === 'object' && !Array.isArray(simulationData)) {
      simulationDataToCompare = [
        ...Object.keys(simulationData).reduce((acc, key) => {
          acc.push(simulationData[key]);
          return [...acc];
        }, []),
      ];
    } else {
      simulationDataToCompare = [...simulationData];
    }

    if (simulationDataToCompare.length === invoicesToCompare.length) {
      return simulationDataToCompare.every(
        ({ id, baseRate, expirationDate }) => {
          const foundInvoice = invoicesToCompare.find(i => i.id === id);
          if (!foundInvoice) return false;
          return (
            foundInvoice.baseRate === baseRate &&
            foundInvoice.expirationDate === expirationDate
          );
        }
      );
    }
    return false;
  };

  useEffect(() => {
    if (!orderSummary) return;
    setOperationResume(orderAttributes());

    // eslint-disable-next-line
  }, [orderSummary, orderSummaryWasLoaded]);

  useEffect(() => {
    if (!orderSimulation) return;
    setOperationSimulation(orderAttributes(true));
    setLoadingSimulation(false);
    // eslint-disable-next-line
  }, [orderSimulation, orderSimulationWasLoaded]);

  const { data, title } = item;
  const { invoicesAmount, invoicesSize } = invoicesData;

  const sideBarContent = () => {
    if (invoicesSize === 0) return '';

    return orderSimulationWasLoaded &&
      operationSimulation.length &&
      checkSimulation() ? (
      <>
        <Grid item className={classes.relativeContainer}>
          <Grid
            container
            justify="space-between"
            align="center"
            className={classes.marginBottom20}
          >
            <Grid item container justify="flex-end">
              <Typography
                variant="body1"
                color="textSecondary"
                align="right"
                noWrap
              >
                {title}
              </Typography>
              <Tooltip
                title={
                  <Typography variant="body1">Comparar con antes</Typography>
                }
              >
                <IconButton
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={togglePanel}
                  className={classes.togglePanel}
                >
                  <TogglePanel
                    className={
                      isOpen ? classes.toggleOpen : classes.toggleClose
                    }
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <DataList wide data={operationSimulation} justify="flex-end" />
          </Grid>
          {isOpen ? (
            <Grid container className={classes.sideResumeContainer}>
              <Typography
                variant="body1"
                align="left"
                noWrap
                className={classes.marginBottom33}
              >
                Antes
              </Typography>
              <DataList
                wide
                displayInDialog
                data={data}
                justify="flex-start"
                showLabel={false}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} className={classes.containerSimulation}>
          <LoadingButton
            onClick={() => manageSelectedInvoices(order?.id)}
            startIcon={buttonIcon}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            isLoading={buttonIsLoading}
          >
            {buttonText}
          </LoadingButton>
        </Grid>
      </>
    ) : (
      <>
        {showHint && (
          <Typography
            variant="body2"
            align="center"
            className={classes.marginBottom20}
          >
            *Antes de simular recuerda revisar y editar las tasas de ser
            necesario.
          </Typography>
        )}
        <Button
          onClick={simulate}
          variant="contained"
          color="primary"
          disabled={loadingSimulation}
        >
          Simular
        </Button>
      </>
    );
  };

  return (
    <Grid container className={classes.resumeContainer} justify="center">
      <Grid item align="center" xs={12}>
        <Typography variant="body1" align="center" component="div">
          <Box fontWeight="fontWeightBold">Resumen operación</Box>
        </Typography>
      </Grid>
      <Grid
        item
        align="center"
        className={clsx(
          classes.invoiceAmountContainer,
          !invoicesSize > 0 && classes.invoiceAmountContainerEmpty
        )}
      >
        <Typography variant="body2" noWrap>
          {invoicesSize > 0
            ? `Facturas a mover (${invoicesSize})`
            : 'Seleccione una factura'}
        </Typography>
        <Typography variant="h6" noWrap component="div">
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={invoicesAmount}
              variant="currency"
              countryId={country}
            />
          </Box>
        </Typography>
      </Grid>
      {orderSummaryWasLoaded ? (
        sideBarContent()
      ) : (
        <Grid container justify="center">
          <img src={loadingIcon} alt="loader" />
        </Grid>
      )}
    </Grid>
  );
};

InvoiceOperationResume.defaultProps = {
  buttonDisabled: false,
  orderSummary: {},
  showHint: false,
  buttonIsLoading: false,
};

InvoiceOperationResume.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    orderInvoices: PropTypes.arrayOf(
      PropTypes.shape({
        SiiFactura: PropTypes.shape({
          SiiNotasCredito: PropTypes.shape({
            detMntTotal: PropTypes.number.isRequired,
          }),
        }),
      })
    ),
  }).isRequired,
  orderSummary: PropTypes.shape({
    totalAmount: PropTypes.number,
    advancePercentage: PropTypes.number,
    advance: PropTypes.number,
    retention: PropTypes.number,
    baseRate: PropTypes.number,
    interest: PropTypes.number,
    operationCost: PropTypes.number,
    transferDiscount: PropTypes.number,
    finalAmount: PropTypes.number,
    transfer: PropTypes.number,
    totalDiscounts: PropTypes.number,
    transferDate: PropTypes.number,
    availableRetention: PropTypes.number,
  }),
  orderSummaryWasLoaded: PropTypes.bool.isRequired,
  invoicesData: PropTypes.shape({
    invoicesSize: PropTypes.number.isRequired,
    invoicesAmount: PropTypes.number.isRequired,
    invoices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        baseRate: PropTypes.number,
        expirationDate: PropTypes.string,
      })
    ),
  }).isRequired,
  manageSelectedInvoices: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
  buttonIcon: PropTypes.element.isRequired,
  showHint: PropTypes.bool,
  action: PropTypes.oneOf(['add', 'move']).isRequired,
  buttonIsLoading: PropTypes.bool,
};

export default InvoiceOperationResume;
