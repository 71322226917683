import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Ticket = props => (
  <SvgIcon
    width={16}
    height={12}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.167 8.908 14.593.48l1.297 1.296L6.167 11.5.333 5.666 1.629 4.37l4.538 4.538Z"
      fill="#44414C"
    />
  </SvgIcon>
);

export default Ticket;
