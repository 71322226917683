import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { Add, CloseOutlined } from '@material-ui/icons';

type Context = {
  states: boolean[];
  setStates?: React.Dispatch<React.SetStateAction<boolean[]>>;
  selectedIndex?: number;
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedId?: string;
  setSelectedId?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

type RootProps = {
  children?: React.ReactNode;
  onToggle?: (id: string | undefined, isToggle: boolean) => void;
};

export const TableDataAndHistoryContext = React.createContext<Context>({states: []});

export const Root = ({ children, onToggle }: RootProps) => {
  const [states, setStates] = useState<boolean[]>([false, false, false]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [selectedId, setSelectedId] = useState<string>();

  useEffect(() => {
    if (onToggle) {
      if (selectedId) {
        onToggle(selectedId, true);
      } else {
        onToggle(selectedId, false);
      }
    }
  }, [selectedId]);

  return (
    <TableDataAndHistoryContext.Provider
      value={{
        states,
        setStates,
        selectedIndex,
        setSelectedIndex,
        selectedId,
        setSelectedId,
      }}
    >
      {children}
    </TableDataAndHistoryContext.Provider>
  );
};

type ToggleProps = {
  selectedIndex: number;
  id: string;
};

export const Toggle = ({selectedIndex, id}: ToggleProps) => {
  const {states, setStates, setSelectedIndex, setSelectedId } = useContext(TableDataAndHistoryContext);

  const handleOnAction = () => {
    if (setSelectedId) {
      setSelectedId(undefined);
      if (setSelectedIndex) {
        setSelectedIndex(selectedIndex);
      }
    }

    if (setStates && setSelectedId) {
      setStates(selectedItems => {
        if (selectedItems[selectedIndex]) {
          setSelectedId(undefined);
        } else {
          setSelectedId(id);
        }

        return [
          ...selectedItems.slice(0, selectedIndex).map(() => false),
          !selectedItems[selectedIndex],
          ...selectedItems.slice(selectedIndex + 1).map(() => false),
        ];
      });
    }
  };

  return (
    <IconButton onClick={handleOnAction}>
      {states[selectedIndex] ? <CloseOutlined/> : <Add/>}
    </IconButton>
  );
};

export const ContentLeft = ({ children }: RootProps) => {
  const {selectedId } = useContext(TableDataAndHistoryContext);
  return (
    <Grid item xs={ selectedId ? 8 : 12}>
      <Box marginLeft={4} marginRight={selectedId ? 0 : 4}>
        {children}
      </Box>
    </Grid>
  );
};

export const ContentRight = ({ children }: RootProps) => {
  const {states, selectedIndex, selectedId } = useContext(TableDataAndHistoryContext);
  return (
    <>
      {selectedIndex !== undefined && (
        <>
          { states[selectedIndex] && selectedId
          && <Grid item xs={4}>
            <Box marginRight={4}>
              {children}
            </Box>
          </Grid>
          }
        </>
      )}
    </>
  );
};
