import { Typography, styled, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  spacingLeft: {
    marginLeft: 5,
  },
  roundCorners: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  labelCheckBox: {
    marginTop: 5,
    marginLeft: 2,
    maxWidth: 300,
  },
  alertForm: {
    marginTop: 15,
    marginRight: 20,
  },
  title: {
    marginLeft: '10px',
  },
  alignLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  spacingBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 5px',
  },
  spacingVertical: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  spacingVertical5: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
  },
  spacingTop: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0px 0px 0px',
  },
  spacingVerticalDif: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0 5px',
  },
  spacingAll: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '25px 8px 0px 8px',
  },
  label: {
    maxWidth: 330,
  },
  links: {
    fontWeight: 'bold',
    textSize: '12',
    color: '#000'
  },
  liDrawer: {
    display: 'block',
    padding: '15px 0 5px',
  },
  linksDocument: {
    color: '#1A49E7',
    fontWeight: 700
  },
  breakWords: {
    wordBreak: 'break-word',
    display: 'block',
    padding: '15px 0 5px',
  }
});

const TextNormal = styled(Typography)({
  fontSize: 16,
});

const TextLabel = styled(Typography)({
  color: '#8D8A96',
  fontSize: '12px',
  fontWeight: 400,
});

const TextLabelValue = styled(Typography)({
  color: '#8D8A96',
  fontSize: '12px',
  fontWeight: 700,
});

export { useStyles, TextNormal, TextLabel, TextLabelValue };
