/* eslint-disable no-unused-expressions */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useOktaAuth } from '@okta/okta-react';
import { dateFormatter } from '../../../../../helpers/DateUtils';
import {
  CommentUserState,
  CommentDataToUpdate,
  User,
  Comment,
  CommentAttachment,
  FlagType,
} from '../interfaces';
import useFetchUsers from '../infrastructure/store/useFetchUsers';
import useFetchOrderComments from '../infrastructure/store/useFetchOrderComments';
import useAddOrderComment from '../infrastructure/store/useAddOrderComment';
import useUpdateOrderComment from '../infrastructure/store/useUpdateOrderComment';
import { toBase64 } from '../../../../../helpers/fileToBase64';
import useDownloadOrderCommentAttachment from '../infrastructure/store/useDownloadOrderCommentAttachment';
import useUpdateOrderCommentsTags from '../infrastructure/store/useUpdateOrderCommentsTags';

const useOrderComments = (operationId: number, flagType: FlagType) => {
  const { authState } = useOktaAuth();
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showDropZoneInput, setShowDropZoneInput] = useState(false);
  const [showTagsInput, setShowTagsInput] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showVisualizerImgDialog, setShowVisualizerImgDialog] = useState(false);
  const [showVisualizerPdfDialog, setShowVisualizerPdfDialog] = useState(false);
  const [commentAttachmentSelected, setCommentAttachmentSelected] =
    useState<CommentAttachment | null>(null);
  const [commentIdSelected, setCommentIdSelected] = useState<number | null>(
    null
  );
  const [commentInformation, setCommentInformation] =
    useState<CommentUserState>({
      commentData: '',
      attachmentData: [],
      tagsData: [],
    });

  const { users, isLoading: usersIsLoading } = useFetchUsers();
  const { orderComments, isLoading: fetchOrderCommentsIsLoading } =
    useFetchOrderComments(operationId);
  const {
    isLoading: addOrderCommentIsLoading,
    isSuccess: addOrderCommentIsSuccess,
    addOrderComment,
  } = useAddOrderComment();
  const {
    isLoading: updateOrderCommentIsLoading,
    isSuccess: updateOrderCommentIsSuccess,
    updateOrderComment,
  } = useUpdateOrderComment();
  const {
    isLoading: downloadOrderCommentAttachmentIsLoading,
    downloadOrderCommentAttachment,
  } = useDownloadOrderCommentAttachment();
  const { updateOrderCommentsTags } = useUpdateOrderCommentsTags();

  const handleSetInitialData = useCallback(() => {
    setShowCommentInput(false);
    setCommentInformation({
      commentData: '',
      attachmentData: [],
      tagsData: [],
    });
    setShowTagsInput(false);
    setShowDropZoneInput(false);
  }, [
    setShowCommentInput,
    setCommentInformation,
    setShowTagsInput,
    setShowDropZoneInput,
  ]);

  const handleShowVisualizerImgDialog = useCallback(
    (commentAttachment: CommentAttachment) => {
      setCommentAttachmentSelected(commentAttachment);
      setShowVisualizerImgDialog(true);
    },
    [setShowVisualizerImgDialog, setCommentAttachmentSelected]
  );

  const handleCloseVisualizerImgDialog = useCallback(() => {
    setShowVisualizerImgDialog(false);
    setCommentAttachmentSelected(null);
  }, [setShowVisualizerImgDialog, setCommentAttachmentSelected]);

  const handleShowVisualizerPdfDialog = useCallback(
    (commentAttachment: CommentAttachment) => {
      setCommentAttachmentSelected(commentAttachment);
      setShowVisualizerPdfDialog(true);
    },
    [setShowVisualizerPdfDialog, setCommentAttachmentSelected]
  );

  const handleCloseVisualizerPdfDialog = useCallback(() => {
    setShowVisualizerPdfDialog(false);
    setCommentAttachmentSelected(null);
  }, [setShowVisualizerPdfDialog, setCommentAttachmentSelected]);

  const handleOpenCommentInput = useCallback(() => {
    setShowCommentInput(true);
  }, [setShowCommentInput]);

  const handleCloseCommentInput = useCallback(() => {
    setShowCommentInput(false);
  }, [setShowCommentInput]);

  const handleToggleDropZoneInput = useCallback(() => {
    setShowDropZoneInput(!showDropZoneInput);
  }, [setShowDropZoneInput, showDropZoneInput]);

  const handleToggleTagsInput = useCallback(() => {
    setShowTagsInput(!showTagsInput);
  }, [setShowTagsInput, showTagsInput]);

  const handleChangeCommentData = useCallback(
    (commentData: string) => {
      setCommentInformation({
        ...commentInformation,
        commentData,
      });
    },
    [setCommentInformation, commentInformation]
  );

  const handleChangeDropzone = useCallback(
    (documentFile: any, status: string) => {
      const copyCommentInformation = { ...commentInformation };
      const arrayFiles = copyCommentInformation.attachmentData;

      if (status === 'done') {
        arrayFiles.push(documentFile);
        setCommentInformation({
          ...commentInformation,
          attachmentData: arrayFiles,
        });
      } else if (status === 'removed') {
        const newArrayFiles = arrayFiles.filter(
          file => file?.meta?.id !== documentFile?.meta?.id
        );
        setCommentInformation({
          ...commentInformation,
          attachmentData: newArrayFiles,
        });
      }
    },
    [commentInformation, setCommentInformation]
  );

  const handleChangeTagSelector = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, values: User[]) => {
      const newValue = values[values.length - 1];
      const oldValues = values.slice(0, values.length - 1);
      // Because must be check if the user is deselecting the collector.
      const isAlreadySelected = oldValues.find(
        (value: User) => value.email === newValue.email
      );
      const tagsData = isAlreadySelected
        ? oldValues.filter((value: User) => value.email !== newValue.email)
        : values;
      setCommentInformation({
        ...commentInformation,
        tagsData,
      });
    },
    [setCommentInformation, commentInformation]
  );

  const handleGetDateTimeCommentFormat = useCallback((date: string) => {
    const today = moment(new Date().toISOString());
    const momentDate = moment(new Date(date).toISOString());

    const diffDays = Math.abs(today.diff(momentDate, 'days'));
    const diffHours = Math.abs(today.diff(momentDate, 'hours'));

    if (diffDays > 1 && diffDays <= 3) {
      return `hace ${diffDays} días`;
    }

    if (diffDays === 1) {
      return `hace ${diffDays} día`;
    }

    if (diffHours > 1 && diffHours <= 12) {
      return `hace ${diffHours} horas`;
    }

    if (diffHours === 1) {
      return `hace ${diffHours} hora`;
    }

    if (diffHours === 0) {
      return 'menos de 1 hora';
    }
    return dateFormatter(date);
  }, []);

  const handleFilterCurrentUser = useCallback(
    (userList: User[]) => {
      const currentUserEmail = authState?.idToken?.claims?.email || '-';
      return userList?.filter(
        ({ email, firstName }: User) =>
          email !== currentUserEmail && firstName?.trim()
      );
    },
    [authState]
  );

  const handleMaxWords = useCallback((text: string, maxLength: number) => {
    if (text?.trim()?.length <= maxLength) return text;
    return `${text?.trim().slice(0, maxLength)}...`;
  }, []);

  const handleGetExtensionFile = useCallback((pathFile: string) => {
    const newArray = pathFile?.split('.');
    return newArray[newArray?.length - 1] || '-';
  }, []);

  const handleSetOrderCommentIdSelected = useCallback(
    (commentId: number) => {
      setCommentIdSelected(commentId);
    },
    [setCommentIdSelected]
  );

  const handleAddOrderComment = useCallback(
    async (commentUserState: CommentUserState) => {
      const attachments: { file: string; fileName: string }[] = [];
      const attachmentsPromises = commentUserState?.attachmentData.map(
        attachment => {
          attachments.push({ fileName: attachment.file.name, file: '' });
          return toBase64(attachment.file);
        }
      );
      const attachmentsResolved = await Promise.all(attachmentsPromises);
      attachmentsResolved.forEach((attachment, index) => {
        attachments[index] = {
          ...attachments[index],
          file: attachment,
        };
      });

      const userEmail = authState?.idToken?.claims?.email || '';
      const userName = authState?.idToken?.claims?.name || '';

      const commentDataToCreate = {
        comment: commentUserState.commentData,
        userEmail,
        userEmails:
          commentUserState?.tagsData?.map(tagData => tagData.email) || [],
        attachments,
        category: 'XEPELIN',
        userName,
      };
      addOrderComment({ operationId, commentDataToCreate, flagType });
    },
    [addOrderComment, authState, operationId, flagType]
  );

  const handleUpdateOrderComment = useCallback(
    (commentId: number, commentDataToUpdate: CommentDataToUpdate) => {
      updateOrderComment({
        operationId,
        commentId,
        commentDataToUpdate,
        flagType,
      });
    },
    [updateOrderComment, operationId, flagType]
  );

  const handleDownloadOrderCommentAttachment = useCallback(
    (fileName: string, attachmentId: number) => {
      downloadOrderCommentAttachment({ fileName, attachmentId });
    },
    [downloadOrderCommentAttachment]
  );

  const handleUpdateOrderCommentTags = useCallback(() => {
    const commentIds = orderComments.map(({ id }: Comment) => id);
    const userEmail = authState?.idToken?.claims?.email || '-';
    updateOrderCommentsTags({ commentIds, operationId, userEmail });
  }, [updateOrderCommentsTags, operationId, orderComments, authState]);

  useEffect(() => {
    if (addOrderCommentIsSuccess && !addOrderCommentIsLoading) {
      handleSetInitialData();
    }
  }, [
    addOrderCommentIsLoading,
    addOrderCommentIsSuccess,
    handleSetInitialData,
  ]);

  useEffect(() => {
    if (updateOrderCommentIsSuccess && !updateOrderCommentIsLoading) {
      handleSetInitialData();
    }
  }, [
    updateOrderCommentIsSuccess,
    updateOrderCommentIsLoading,
    handleSetInitialData,
  ]);

  useEffect(
    () => {
      if (
        orderComments?.length &&
        !fetchOrderCommentsIsLoading &&
        authState?.idToken?.claims?.email &&
        isFirstRender
      ) {
        setIsFirstRender(false);
        handleUpdateOrderCommentTags();
      }
    },
    // eslint-disable-next-line
    [
      orderComments,
      authState?.idToken?.claims?.email,
      fetchOrderCommentsIsLoading,
    ]
  );

  return {
    commentAttachmentSelected,
    showVisualizerImgDialog,
    showVisualizerPdfDialog,
    showCommentInput,
    showDropZoneInput,
    showTagsInput,
    commentInformation,
    users,
    orderComments,
    commentIdSelected,
    usersIsLoading,
    fetchOrderCommentsIsLoading,
    addOrderCommentIsLoading,
    updateOrderCommentIsLoading,
    downloadOrderCommentAttachmentIsLoading,
    handleOpenCommentInput,
    handleCloseCommentInput,
    handleToggleDropZoneInput,
    handleToggleTagsInput,
    handleChangeCommentData,
    handleChangeDropzone,
    handleChangeTagSelector,
    handleGetDateTimeCommentFormat,
    handleFilterCurrentUser,
    handleMaxWords,
    handleGetExtensionFile,
    handleSetOrderCommentIdSelected,
    handleAddOrderComment,
    handleUpdateOrderComment,
    handleDownloadOrderCommentAttachment,
    handleUpdateOrderCommentTags,
    handleShowVisualizerImgDialog,
    handleCloseVisualizerImgDialog,
    handleShowVisualizerPdfDialog,
    handleCloseVisualizerPdfDialog,
  };
};

export default useOrderComments;
