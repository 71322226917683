import { useQuery } from 'react-query';

import { fetchOrder } from '../services';

const useOrder = orderId => {
  const { isLoading, data, refetch } = useQuery(
    'getOrder',
    () => fetchOrder(orderId),
    { refetchOnWindowFocus: false }
  );

  const { data: order } = data || { data: [] };

  return {
    order,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useOrder };
