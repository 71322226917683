import axios from 'axios';

import {
  FETCH_LINK_FUND_SUMMARY_START,
  FETCH_LINK_FUND_SUMMARY_SUCCESS,
  FETCH_LINK_FUND_SUMMARY_FAILURE,
  FETCH_LINK_FUND_START,
  FETCH_LINK_FUND_SUCCESS,
  FETCH_LINK_FUND_FAILURE,
  UPDATE_LINK_FUND_START,
  UPDATE_LINK_FUND_SUCCESS,
  UPDATE_LINK_FUND_FAILURE,
  REGISTER_LINK_FUND_START,
  REGISTER_LINK_FUND_SUCCESS,
  REGISTER_LINK_FUND_FAILURE,
  FETCH_LINK_FUND_RATES_START,
  FETCH_LINK_FUND_RATES_SUCCESS,
  FETCH_LINK_FUND_RATES_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys';

export const fetchLinkFundSummary =
  ({ sourceType, sourceId }) =>
  dispatch => {
    dispatch({ type: FETCH_LINK_FUND_SUMMARY_START });
    axios
      .get(`/api/${sourceType}/${sourceId}/fund/summary`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_LINK_FUND_SUMMARY_SUCCESS,
          payload: { data },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_LINK_FUND_SUMMARY_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_LINK_FUND_SUMMARY_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchLinkFundRates = linkFundId => dispatch => {
  dispatch({ type: FETCH_LINK_FUND_RATES_START });
  axios
    .get(`/api/linkfund/${linkFundId}/rate`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_LINK_FUND_RATES_SUCCESS,
        payload: { data },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_LINK_FUND_RATES_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_LINK_FUND_RATES_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchLinkFund =
  ({ sourceType, sourceId }) =>
  dispatch => {
    dispatch({ type: FETCH_LINK_FUND_START });
    axios
      .get(`/api/${sourceType}/${sourceId}/fund`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_LINK_FUND_SUCCESS,
          payload: { data },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_LINK_FUND_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_LINK_FUND_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const updateLinkFund =
  ({ sourceType, sourceId }, { fund }) =>
  dispatch => {
    dispatch({ type: UPDATE_LINK_FUND_START });
    axios
      .put(`/api/${sourceType}/${sourceId}/fund`, fund)
      .then(({ data }) => {
        dispatch({
          type: UPDATE_LINK_FUND_SUCCESS,
          payload: { data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_LINK_FUND_SUCCESS, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_LINK_FUND_FAILURE,
          payload: {
            errorCode,
            errorMsg: t13s.NOTIFICATION.UPDATE_LINK_FUND_FAILURE,
          },
        });
      });
  };

export const registerLinkFund =
  ({ sourceType, sourceId }, { fund }) =>
  dispatch => {
    dispatch({ type: REGISTER_LINK_FUND_START });
    axios
      .post(`/api/${sourceType}/${sourceId}/fund`, fund)
      .then(({ data }) => {
        dispatch({
          type: REGISTER_LINK_FUND_SUCCESS,
          payload: { data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.REGISTER_LINK_FUND_SUCCESS, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: REGISTER_LINK_FUND_FAILURE,
          payload: {
            errorCode,
            errorMsg: t13s.NOTIFICATION.REGISTER_LINK_FUND_FAILURE,
          },
        });
      });
  };
