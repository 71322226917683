import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Text } from '../../../../commons/formatters';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import {
  useOrder,
  useBusinessCriticalStatusInfo,
} from '../../../infrastructure/store';
import { COUNTRY_CODE_CL } from '../../../../../helpers/Constants';

const WalletStatusTab = () => {
  const { country } = useSelector(state => state.config);
  const { id: orderId } = useParams();
  const {
    order: { businessId },
  } = useOrder(orderId);
  const { criticalStatusInfo } = useBusinessCriticalStatusInfo(businessId);

  return (
    <Grid container>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item>
            <Text fontWeight="bold">Mora mayor a 30 dias</Text>
          </Grid>
          <Grid item>
            <Text>
              <CountryFormatHelper
                countryId={country}
                value={criticalStatusInfo?.debtAmount30}
                variant="currency"
              />
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item>
            <Text fontWeight="bold">Saldo estado crítico</Text>
          </Grid>
          <Grid item>
            <Text>
              <CountryFormatHelper
                countryId={country}
                value={criticalStatusInfo?.criticalStateAmount}
                variant="currency"
              />
            </Text>
          </Grid>
        </Grid>
      </Grid>
      {country === COUNTRY_CODE_CL && (
        <Grid item xs>
          <Grid container direction="column">
            <Grid item>
              <Text fontWeight="bold">Saldo estado wallet</Text>
            </Grid>
            <Grid item>
              <Text>
                <CountryFormatHelper
                  countryId={country}
                  value={criticalStatusInfo?.accountBalance}
                  variant="currency"
                />
              </Text>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

WalletStatusTab.propTypes = {};

export default WalletStatusTab;
