import React, { useState } from 'react';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { GLOBAL_REASONS } from '../../../../../../helpers/Constants';
import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import MenuItem from '../../../../../../components/elements/MenuItem';
import AlertForm from '../../../../../../components/elements/AlertForm';
import CheckButton from '../../../../../../components/elements/CheckButton';
import { useGetCountry } from '../../../../../../funds/adapters/store';

const INVOICE_DELETED = 'INVOICE_DELETED';

const useStyles = makeStyles({
  containerButton: {
    marginTop: 10,
  },
  containerError: {
    marginTop: 10,
  },
});

const DeleteInvoiceDialog = ({
  isOpen,
  handleCloseDialog,
  handleSubmit,
  isLoading,
  error,
}) => {
  const classes = useStyles();
  const country = useGetCountry();

  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState(
    GLOBAL_REASONS?.filter(
      globalReason =>
        globalReason.type === INVOICE_DELETED &&
        globalReason.country.includes(country)
    )[0]?.value
  );

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      title="Eliminar factura"
      description="Estás por eliminar una o varias facturas de la operación, seleciona la razón y luego confirma para continuar."
    >
      <ValidatorForm onSubmit={() => handleSubmit(reason)}>
        <SelectValidator
          fullWidth
          variant="outlined"
          value={reason}
          onChange={e => setReason(e.target.value)}
          label="Estado"
          validators={['required']}
          errorMessages={['Seleccione una opción']}
        >
          {GLOBAL_REASONS.filter(
            globalReason =>
              globalReason.type === INVOICE_DELETED &&
              globalReason.country.includes(country)
          ).map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </SelectValidator>

        {error && (
          <Grid item xs={12} align="center" className={classes.containerError}>
            <AlertForm variant="error" message={error} />
          </Grid>
        )}
        <Grid xs={12} className={classes.containerButton}>
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Eliminar"
            diabled={isLoading}
            loading={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

DeleteInvoiceDialog.defaultProps = {
  isLoading: false,
  error: null,
};

DeleteInvoiceDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default DeleteInvoiceDialog;
