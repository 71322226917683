import React, { useState, MouseEvent } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  CONFIRMING,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
} from '../../../../helpers/Constants';
import { StatusChip } from '../../../../components/elements/chips';
import { getCurrencyFormat } from '../../../../features/credit-line/utils';
import Text from '../../../commons/components/Text';
import { DaysPastDue } from '../../../../components/elements/LatePaymentComponents';
import {
  useShowInvoiceInformation,
  useShowExtensionDetail,
  useExportPayersInXLS,
} from '../../hooks';
import CollectionByPayerInvoiceInformation from './CollectionByPayerInvoiceInformation';
import ExtensionDetailDrawer from '../ExtensionDetailDrawer';
import { t13s } from '../../../../translationKeys';
import { PayerDebts } from '../../../commons/interfaces/payerDebts';
import SelectedInvoices from '../SelectedInvoices';
import { Role } from '../../../commons/interfaces/global';
import { collectionRoles } from '../../../commons/utils/roles';
import { DebtExtensionDrawer } from '../../../commons/interfaces/debtExtension';
import { BodyCell, TableStickHead, useStyles } from './styles';
import { format } from 'date-fns';
import TodayIcon from '@material-ui/icons/Today';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import useFetchManagementsSummary from '../../infrastructure/store/useFecthManagementsSummary';
import {
  invoiceLabels,
  managementTypes,
} from '../../../commons/utils/managementOptions';
import { collectBy, prop } from 'ramda';
import useQueryParams from '../../hooks/useQueryParams';

const {
  DEBT_LIST_COLUMNS: debtsColumnsLabels,
  DEBTS_MANAGEMENTS_TOOLTIPS: debtManagementTooltipsLabels,
  ...collectionsLabels
} = t13s.LABEL.COLLECTIONS;
const operationTypes = {
  [DIRECT_FINANCING]: 'FD',
  [EARLY_PAYMENT]: 'PP',
  [CONFIRMING]: 'Payments',
};

type TableHeaderInvoicesProps = {
  onSelectAllClick: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  allChecked: boolean;
  isCollectionRole: boolean;
  country: string;
};
const TableHeaderInvoices = ({
  onSelectAllClick,
  allChecked,
  isCollectionRole,
  country,
}: TableHeaderInvoicesProps) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {isCollectionRole && (
          <TableStickHead padding="checkbox">
            <Checkbox
              checked={allChecked}
              onClick={event => onSelectAllClick(event)}
            />
          </TableStickHead>
        )}
        <TableStickHead>{t(debtsColumnsLabels.OPERATION_ID)}</TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.FOLIO)}</TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.STATUS)}</TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.MANAGEMENT)}</TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.AMOUNT)}</TableStickHead>
        <TableStickHead style={{ padding: '6px 30px' }}>
          {t(debtsColumnsLabels.ISSUE_DATE)}
        </TableStickHead>
        <TableStickHead>
          {t(t13s.LABEL.COLLECTIONS.BUSINESS_IDENTIFIER)}{' '}
          {t(debtsColumnsLabels.ISSUER)}
        </TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.ISSUER)}</TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.FUND)}</TableStickHead>
        {country === 'CL' && (
          <TableStickHead>{t(debtsColumnsLabels.CUSTODIAN)}</TableStickHead>
        )}
        <TableStickHead style={{ padding: '6px 20px' }}>
          {t(debtsColumnsLabels.EXPIRATION_DATE)}
        </TableStickHead>
        <TableStickHead style={{ padding: '6px 28px' }}>
          {t(debtsColumnsLabels.PROMISE_DATE)}
        </TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.MORA_DAYS)}</TableStickHead>
        <TableStickHead>{t(debtsColumnsLabels.OPERATION_TYPE)}</TableStickHead>
      </TableRow>
    </TableHead>
  );
};

type CollectionsByPayerTableDataProps = {
  isLoading: boolean;
  data: PayerDebts;
};

const CollectionsByPayerTableData = ({
  isLoading,
  data,
}: CollectionsByPayerTableDataProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = useQueryParams();
  const { handleExportXLS } = useExportPayersInXLS();
  const { country } = useSelector((state: RootStateOrAny) => state.config);
  const { open, setInvoiceAndOpen, invoiceId, closeDrawer } =
    useShowInvoiceInformation();
  const {
    showExtensionDetail,
    toggleShowExtensionDetail,
    openDrawerWithDetail,
    extensionDetail,
  } = useShowExtensionDetail();
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedFolios, setSelectedFolios] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const { roles } = useSelector((state: RootStateOrAny) => state.auth.user);
  const isCollectionRole = roles.some((role: Role) =>
    collectionRoles.includes(role.rolename)
  );

  let countClients = 0;
  if (data) {
    countClients = collectBy(
      prop<string>('businessId'),
      data.payerDebts
    ).length;
  }

  const onSortBy = (event: React.ChangeEvent<{ value: unknown }>) => {
    if ((event.target as HTMLInputElement).value) {
      history.push(
        `${location.pathname}?sortBy=${
          (event.target as HTMLInputElement).value
        }`
      );
    }
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const { data: managements } = useFetchManagementsSummary(
    data.payerDebts.map(debt => debt.orderInovice.id),
    isLoading
  );

  const handleSelectAllClick = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    setAllChecked(!allChecked);
    if ((event.target as Element & { checked: boolean })?.checked) {
      const newSelecteds: number[] = data.payerDebts.map(
        i => i.orderInovice.id
      );
      const newSelectedFolios = data.payerDebts.map(i => i.folio);
      setSelected(newSelecteds);
      setSelectedFolios(newSelectedFolios);
      return;
    }
    setSelected([]);
    setSelectedFolios([]);
  };

  const handleClick = (
    _: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    id: number,
    folio: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];
    let newSelectedFolios: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedFolios = newSelectedFolios.concat(selectedFolios, folio);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedFolios = newSelectedFolios.concat(selectedFolios.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedFolios = newSelectedFolios.concat(selectedFolios.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedFolios = newSelectedFolios.concat(
        selectedFolios.slice(0, selectedIndex),
        selectedFolios.slice(selectedIndex + 1)
      );
    }
    if (allChecked) setAllChecked(false);
    if (newSelected.length === data.payerDebts.length) setAllChecked(true);

    setSelected(newSelected);
    setSelectedFolios(newSelectedFolios);
  };

  if (isLoading) {
    return (
      <TableContainer>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHeaderInvoices
            onSelectAllClick={e => handleSelectAllClick(e)}
            allChecked={allChecked}
            isCollectionRole={isCollectionRole}
            country={country}
          />
          <TableBody>
            {Array(18)
              .fill(1)
              .map((data, index) => (
                <TableRow key={`table-skeleton-${index}`}>
                  {Array(country === 'CL' ? 14 : 13)
                    .fill('x')
                    .map((k, i) => (
                      <TableCell
                        key={`table-row-skeleton${i}`}
                        component="th"
                        scope="row"
                      >
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <Box p={{ xs: '24px' }}>
        <Grid container>
          <Grid item xs={2}>
            <Text fontWeight="bold" variant="h6">
              {t(collectionsLabels.DEBTS_LIST_HEADER_TITLE)}
            </Text>
            <Text isLoading={isLoading}>
              {t(collectionsLabels.DEBTS_LIST_HEADER_DESCRIPTION, {
                countClients,
                payerDebtsLength: data?.payerDebts.length,
              })}
            </Text>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="row" justifyContent="flex-start">
              <Button
                variant="outlined"
                color="primary"
                style={{ width: 250 }}
                onClick={() => handleExportXLS(data)}
              >
                Exportar Excel
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="row" justifyContent="flex-end">
              {!isLoading && (
                <FormControl size="medium" style={{ width: 250 }}>
                  <TextField
                    name="sortBy"
                    variant="outlined"
                    label={t(t13s.LABEL.COLLECTIONS.ORDER_BY)}
                    value={query.get('sortBy') || ''}
                    onChange={onSortBy}
                    select
                  >
                    <MenuItem value="expirationDate:desc">
                      {t(collectionsLabels.FILTERING_BY_EXPIRATION_DATE)}
                    </MenuItem>
                    <MenuItem value="extension:desc">
                      {t(collectionsLabels.FILTERING_BY_EXTENSION_DATE)}
                    </MenuItem>
                    <MenuItem value="amountInvoice:desc">
                      {t(collectionsLabels.FILTERING_BY_AMOUNT_INVOICE)}
                    </MenuItem>
                    <MenuItem value="operationId:asc">
                      {t(collectionsLabels.FILTERING_BY_OPERATION_ID)}
                    </MenuItem>
                    {country === 'CL' && (
                      <MenuItem value="custodian:asc">
                        {t(collectionsLabels.FILTERING_BY_CUSTODIAN)}
                      </MenuItem>
                    )}
                  </TextField>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <SelectedInvoices
        selectedInvoices={selected}
        selectedFolios={selectedFolios}
        setSelectedInvoices={setSelected}
        setSelectedFolios={setSelectedFolios}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
      />
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="Payers debts table"
          size="small"
        >
          <TableHeaderInvoices
            onSelectAllClick={handleSelectAllClick}
            allChecked={allChecked}
            isCollectionRole={isCollectionRole}
            country={country}
          />
          <TableBody>
            {data.payerDebts.length <= 0 ? (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={11}>
                  <Text align="center">
                    {t(collectionsLabels.DEBTS_LIST_BODY_DEBTS_NOT_FUND)}
                  </Text>
                </TableCell>
              </TableRow>
            ) : (
              data.payerDebts.map(debt => {
                const isItemSelected = isSelected(debt.orderInovice.id);
                const labelId = `invoice-table-checkbox-${debt.orderInovice.id}`;
                return (
                  <TableRow
                    key={debt.orderInovice.id}
                    onClick={event =>
                      isCollectionRole &&
                      handleClick(event, debt.orderInovice.id, debt.folio)
                    }
                  >
                    {isCollectionRole && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                    )}
                    <BodyCell component="th" id={labelId} scope="row">
                      <Link
                        className={classes.linkText}
                        component={RouterLink}
                        underline="hover"
                        to={`/${country.toLowerCase()}/orders/${
                          debt.orderInovice.orderID
                        }`}
                        target="_blank"
                      >
                        {debt.orderInovice.orderID}
                      </Link>
                    </BodyCell>
                    <BodyCell>
                      <Button
                        color="primary"
                        style={{ minWidth: 0 }}
                        onClick={() => setInvoiceAndOpen(debt.orderInovice.id)}
                      >
                        {debt.folio}
                      </Button>
                    </BodyCell>
                    <TableCell>
                      <StatusChip
                        status={debt.orderInovice.status}
                        entityType="invoice"
                        // @ts-ignore
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex">
                        {Object.keys(managements).includes(
                          debt.orderInovice.id.toString()
                        ) &&
                          (Object.keys(
                            managements[debt.orderInovice.id]
                          ).includes(managementTypes.INVOICE_PROBLEMS) ? (
                            <Tooltip
                              title={
                                invoiceLabels[
                                  managements[debt.orderInovice.id][
                                    managementTypes.INVOICE_PROBLEMS
                                  ].result
                                ]
                              }
                              style={{ display: 'inline-block' }}
                            >
                              <IconButton>
                                <ReportOutlinedIcon htmlColor="#FFB100" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <div style={{ padding: 16 }} />
                          ))}
                        {debt.extensions &&
                        debt.extensions.type === 'OFFICIAL' ? (
                          <Tooltip
                            title={t(
                              debtManagementTooltipsLabels.EXTENDED_DEBT
                            )}
                            style={{ display: 'inline-block' }}
                          >
                            <IconButton
                              onClick={() =>
                                openDrawerWithDetail({
                                  ...debt.extensions,
                                  originalExpiration: debt.issueDate,
                                } as DebtExtensionDrawer)
                              }
                            >
                              <TodayIcon htmlColor="#690870" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <div style={{ padding: 16 }} />
                        )}
                        {Object.keys(managements).length > 0 &&
                          Object.keys(managements).includes(
                            debt.orderInovice.id.toString()
                          ) &&
                          (Object.keys(
                            managements[debt.orderInovice.id]
                          ).includes('REASSIGNMENT') ? (
                            <Tooltip
                              title={t(
                                debtManagementTooltipsLabels.JUDICIAL_ACTION
                              )}
                            >
                              <IconButton>
                                <GavelOutlinedIcon htmlColor="#000000" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <div style={{ padding: 16 }} />
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {getCurrencyFormat(debt.amount, country)}
                    </TableCell>
                    <TableCell>
                      {format(new Date(debt.issueDate), 'dd-MM-yyyy')}
                    </TableCell>
                    <TableCell>{debt.issuer || '-'}</TableCell>
                    <TableCell>
                      <Text>{debt.issuing}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{debt.fund.name || '-'}</Text>
                    </TableCell>
                    {country === 'CL' && (
                      <TableCell>
                        <Text>{debt.custodian.name || '-'}</Text>
                      </TableCell>
                    )}
                    <TableCell>
                      {format(
                        new Date(debt.orderInovice.expirationDate),
                        'dd-MM-yyyy'
                      )}
                    </TableCell>
                    <TableCell>
                      {debt.extensions?.newExpirationDate
                        ? format(
                            new Date(debt.extensions?.newExpirationDate),
                            'dd-MM-yyyy'
                          )
                        : 'Sin fecha'}
                    </TableCell>
                    <TableCell>
                      <DaysPastDue
                        expirationDate={debt.orderInovice.expirationDate}
                      />
                    </TableCell>
                    <TableCell>
                      {operationTypes[debt.order.orderType]}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <CollectionByPayerInvoiceInformation
          open={open}
          invoiceId={invoiceId}
          closeDrawer={closeDrawer}
        />
        <ExtensionDetailDrawer
          open={showExtensionDetail}
          closeDrawer={toggleShowExtensionDetail}
          extension={extensionDetail}
        />
      </TableContainer>
    </>
  );
};

export default CollectionsByPayerTableData;
