import { Box, styled, Typography, Divider } from '@material-ui/core';

const ContainerRoot = styled(Box)({
  borderRadius: 8,
  border: '1px solid #DAD9DB',
  padding: 16,
});
const ContainerHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const ContainerSubHeader = styled(Box)({
  marginLeft: 33,
  marginBottom: 10,
});
const ContainerBody = styled(Box)({
  maxHeight: 473,
  marginBottom: 8,
  overflowY: 'auto',
  overflowX: 'auto',
});
const ContainerBodyRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 15,
  paddingRight: 8,
});
const ContainerBodyRowFolio = styled(Box)({
  display: 'flex',
  minWidth: 52,
  marginRight: 8,
});
const ContainerBodyRowAttributes = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minWidth: 18,
  marginRight: 8,
});
const ContainerBodyRowAmount = styled(Box)({
  display: 'flex',
  minWidth: 90,
  marginRight: 8,
});
const ContainerTotalAmount = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 16,
});
const ContainerSkeleton = styled(Box)({
  margin: '0 8px',
});
const TextSecondary = styled(Typography)({
  fontSize: 14,
  color: '#8D8A96',
});
const TextBoldSecondary = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#8D8A96',
});
const TextSmallSecondary = styled(Typography)({
  fontSize: 12,
  color: '#8D8A96',
});
const TextPrimary = styled(Typography)({
  fontSize: 14,
  color: '#44414C',
});
const TextCriticalStatus = styled(Typography)({
  fontSize: 12,
  color: '#0093FF',
});
const TextExpiredInvoiceStatus = styled(Typography)({
  fontSize: 12,
  color: '#D92F24',
});
const VerticalDivider = styled(Typography)({
  margin: '0 16px',
});
const HorizontalDivider = styled(Divider)({
  marginBottom: 16,
});
const ExpiredInvoiceAttribute = styled(Box)({
  width: 5,
  height: 5,
  background: '#D92F24',
  borderRadius: 100,
  marginRight: 4,
});
const CriticalStatusAttribute = styled(Box)({
  width: 5,
  height: 5,
  background: '#478FEB',
  borderRadius: 100,
  marginRight: 4,
});

export {
  ContainerRoot,
  ContainerHeader,
  ContainerSubHeader,
  ContainerBody,
  ContainerBodyRow,
  ContainerBodyRowFolio,
  ContainerBodyRowAttributes,
  ContainerBodyRowAmount,
  ContainerTotalAmount,
  ContainerSkeleton,
  TextSecondary,
  TextBoldSecondary,
  TextSmallSecondary,
  TextPrimary,
  TextCriticalStatus,
  TextExpiredInvoiceStatus,
  VerticalDivider,
  HorizontalDivider,
  ExpiredInvoiceAttribute,
  CriticalStatusAttribute,
};
