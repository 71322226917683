import React, { useState } from 'react';
import { makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import LogoXepelin from '../assets/xepelin-logo-login.svg';
import nubesBackground from '../assets/nubesBackgroundLogin.svg';
import LoadingButton from './elements/LoadingButton';

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 17,
    minWidth: 414,
    height: 300,
    background: `transparent url(${nubesBackground})  top no-repeat`,
    backgroundColor: '#FFFF',
  },
  logoTopBar: {
    display: 'flex',
    justifyContent: 'center',
    padding: 21,
    paddingBottom: 87,
  },
  backgroundImage: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flexBasis: '85%',
    alignSelf: 'center',
    borderRadius: 17,
    boxShadow: '0px 3px 10px #00000015',
  },
  form: {
    width: '100%',
  },
  formContainer: {
    width: '100%',
    padding: '43px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    color: '#FFFF',
    margin: '43px 0 0',
  },
  subtitle: {
    color: '#FFFF',
  },
  logo: {
    height: 70,
  },
}));

const Login = () => {
  const [loginSubmit, setLoginSubmit] = useState(false);
  const classes = useStyles();

  const { oktaAuth } = useOktaAuth();

  const login = async () => {
    setLoginSubmit(true);
    oktaAuth.signInWithRedirect();
  };

  return (
    <Grid
      container
      direction="row"
      alignContent="flex-start"
      justify="center"
      className={classes.mainContainer}
    >
      <Grid item xs={12} className={classes.logoTopBar}>
        <img src={LogoXepelin} className={classes.logo} alt="LogoXepelin" />
      </Grid>
      <Grid item xs={12} className={classes.backgroundImage}>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.paddingTop}
        >
          <Paper className={classes.paper}>
            <Typography
              variant="h5"
              align="center"
              color="textPrimary"
              className={classes.title}
              gutterBottom
            >
              Iniciar Sesión
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="textPrimary"
              className={classes.subtitle}
            >
              Ingresa los datos de tu cuenta Backoffice.
            </Typography>
            <Grid item xs={12} className={classes.formContainer}>
              <LoadingButton
                onClick={login}
                isLoading={loginSubmit}
                fullWidth
                variant="contained"
                color="primary"
              >
                Ingresar con Okta
              </LoadingButton>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
