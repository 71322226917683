import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import Container from './Container';

const useStyles = makeStyles({
  card: {
    borderRadius: 8,
    background: 'white',
    padding: props => props.padding || 30,
  },
  titleContainer: {
    alignItems: 'center',
  },
});

const Section = props => {
  const classes = useStyles(props);
  const { className, card, title, buttonBar, children, gridGap } = props;
  const gridGapCard = card ? 30 : 20;
  return (
    <Container
      className={`${className} ${card && classes.card}`}
      gridGap={gridGap || gridGapCard}
    >
      {title && (
        <Container
          gridTemplate="1fr max-content"
          className={classes.titleContainer}
        >
          <Typography variant="h6" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
          {buttonBar && <Container>{buttonBar}</Container>}
        </Container>
      )}
      {children}
    </Container>
  );
};

Section.defaultProps = {
  buttonBar: null,
  className: null,
  title: '',
  gridGap: null,
};

Section.propTypes = {
  className: PropTypes.objectOf(Object),
  card: PropTypes.bool.isRequired,
  title: PropTypes.string,
  gridGap: PropTypes.number,
  buttonBar: PropTypes.func,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTheme(Section);
