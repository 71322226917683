import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { features } from '../../../../../config/features';
import {
  useAddDrawer,
  useEnableManageOrderInvoice,
  useMoveDrawer,
  useSelectedInvoices,
  useSetShowDeleteInvoice,
  useSetUseAddDrawer,
  useSetUseMoveDrawer,
  useShowActionBar,
  useShowDeleteInvoice,
} from './store';
import ActionBar from '../../../../../components/elements/ActionBar';
import styles from './styles';
import { useUtils } from './hooks';
import {
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
  DECIMAL_PRECISION,
} from '../../../../../helpers/Constants';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import ActionBarIcon from '../../../../../components/elements/ActionBarIcon';
import { MoveTo, Delete as DeleteIcon } from '../../../../../components/icons';
import ManageInvoiceDrawer from './ManageInvoiceDrawer';
import {
  useManageOrderInvoices,
  useOrder,
} from '../../../infrastructure/store';
import DeleteInvoiceDialog from './dialogs/DeleteInvoiceDialog';

const TableManageOrderInvoices = ({ handleRowSelection, tableRef }) => {
  const { id: orderId } = useParams();
  const { order } = useOrder(orderId);
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];
  const classes = styles;
  const { copyToClipboard } = useUtils();

  const advancePercentage = (order.advance * 100) / order.finalAmount;

  const {
    manageOrderInvoices,
    manageOrderInvoicesIsLoading,
    manageOrderInvoicesError,
  } = useManageOrderInvoices(orderId);

  const enableManageOrderInvoice = useEnableManageOrderInvoice();
  const showActionBar = useShowActionBar();
  const uuseMoveDrawer = useMoveDrawer();
  const uuseAddDrawer = useAddDrawer();
  const setUseMoveDrawer = useSetUseMoveDrawer();
  const setUseAddDrawer = useSetUseAddDrawer();
  const showDeleteInvoice = useShowDeleteInvoice();
  const setShowDeleteInvoice = useSetShowDeleteInvoice();
  const selectedInvoices = useSelectedInvoices();

  const resetSelectedRows = () => {
    handleRowSelection([]);
    if (tableRef?.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const sumInvoices = selectInvoices => {
    return selectInvoices?.reduce((sum, invoice) => sum + invoice.amount, 0);
  };

  const sumInvoicesWithoutDiscounts = selectInvoices => {
    const totalAmountInvoices = selectInvoices?.reduce(
      (sum, invoice) => sum + invoice.amount,
      0
    );
    const totalAmountDiscount = selectInvoices?.reduce((sum, invoice) => {
      const discounts = invoice.invoiceDiscounts?.reduce(
        (totalDiscount, discount) => totalDiscount + discount?.amount,
        0
      );
      return sum + discounts;
    }, 0);
    const totalAmountDiscountFromTaxService = selectInvoices?.reduce(
      (sum, invoice) => {
        return sum + invoice?.totalAmountCreditNotes;
      },
      0
    );
    return (
      totalAmountInvoices +
      totalAmountDiscount +
      totalAmountDiscountFromTaxService
    );
  };

  const fixedDecimalAmount = amount => {
    switch (country) {
      case COUNTRY_CODE_CL:
        return amount.toFixed(0);
      case COUNTRY_CODE_MX:
        return amount.toFixed(DECIMAL_PRECISION);
      default:
        return amount;
    }
  };

  const deleteSelectedInvoices = deleteInvoiceReason => {
    if (selectedInvoices.length === 0) return;

    const orderInvoiceIds = selectedInvoices.map(i => i.id);

    manageOrderInvoices({
      orderId,
      params: {
        action: 'delete',
        orderInvoiceIds,
        deleteInvoiceReason,
      },
    });
  };

  const moveSelectedInvoices = newOrderId => {
    if (selectedInvoices.length === 0) return;

    const orderInvoiceIds = selectedInvoices.map(i => i.id);

    manageOrderInvoices({
      orderId,
      params: {
        action: 'move',
        orderInvoiceIds,
        newOrderId,
      },
    });
  };

  const addSelectedInvoices = invoices => {
    if (!invoices.length) return;
    const formatedInvoices = invoices.map(({ id, expirationDate, rate }) => {
      return { id, expirationDate, rate };
    });

    manageOrderInvoices({
      orderId,
      params: {
        action: 'add',
        invoices: formatedInvoices,
      },
    });
  };

  const closeDrawer = () => {
    setUseMoveDrawer(false);
    setUseAddDrawer(false);
  };

  if (
    !countryFeatures.orderActions.showDeleteInvoice &&
    !countryFeatures.orderActions.showMoveInvoice
  ) {
    return <></>;
  }

  return (
    <>
      <ManageInvoiceDrawer
        show={uuseMoveDrawer || uuseAddDrawer}
        closeDrawer={closeDrawer}
        selectedInvoices={uuseAddDrawer ? [] : selectedInvoices}
        manageSelectedInvoices={
          uuseAddDrawer ? addSelectedInvoices : moveSelectedInvoices
        }
        drawerType={uuseAddDrawer ? 'add' : 'move'}
        order={order}
        country={country}
      />
      {showDeleteInvoice && (
        <DeleteInvoiceDialog
          isOpen={showDeleteInvoice}
          handleCloseDialog={() => setShowDeleteInvoice(false)}
          handleSubmit={deleteSelectedInvoices}
          isLoading={manageOrderInvoicesIsLoading}
          error={manageOrderInvoicesError}
        />
      )}

      <ActionBar
        count={selectedInvoices.length}
        type="facturas"
        customMessage={
          <Box display="flex" alignItems="center">
            <Tooltip title="Copiar al portapapeles" arrow>
              <Box
                className={classes.customMessage}
                onClick={() => {
                  copyToClipboard(
                    fixedDecimalAmount(
                      sumInvoicesWithoutDiscounts(selectedInvoices)
                    )
                  );
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.cursorPointer}
                >
                  <Box fontWeight="fontWeightBold">
                    Monto Bruto:{' '}
                    <CountryFormatHelper
                      value={sumInvoicesWithoutDiscounts(selectedInvoices)}
                      countryId={country}
                      variant="currency"
                    />
                  </Box>
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip title="Copiar al portapapeles" arrow>
              <Box
                className={classes.customMessage}
                onClick={() => {
                  const total = fixedDecimalAmount(
                    sumInvoices(selectedInvoices) * (advancePercentage / 100)
                  );
                  copyToClipboard(total);
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.cursorPointer}
                >
                  <Box fontWeight="fontWeightBold">
                    Monto Anticipado:{' '}
                    <CountryFormatHelper
                      value={
                        sumInvoices(selectedInvoices) *
                        (advancePercentage / 100)
                      }
                      countryId={country}
                      variant="currency"
                    />
                  </Box>
                </Typography>
              </Box>
            </Tooltip>

            <Typography variant="body1">
              {selectedInvoices.length > 1
                ? `(${selectedInvoices.length} seleccionadas)`
                : '(1 seleccionada)'}
            </Typography>
          </Box>
        }
        resetSelectedRows={resetSelectedRows}
        show={showActionBar}
      >
        {countryFeatures.orderActions.showDeleteInvoice && (
          <ActionBarIcon
            tooltipMsg={
              enableManageOrderInvoice
                ? 'Eliminar facturas'
                : 'Alguna de las facturas seleccionadas no se pueden eliminar de la operación'
            }
            onClick={() => setShowDeleteInvoice(true)}
            disabled={!enableManageOrderInvoice}
            IconComponent={DeleteIcon}
          />
        )}
        {countryFeatures.orderActions.showMoveInvoice && (
          <ActionBarIcon
            tooltipMsg={
              enableManageOrderInvoice
                ? 'Mover facturas seleccionadas'
                : 'Alguna de las facturas seleccionadas no se pueden mover de la operación'
            }
            onClick={() => setUseMoveDrawer(true)}
            disabled={!enableManageOrderInvoice}
            IconComponent={MoveTo}
          />
        )}
      </ActionBar>
    </>
  );
};

TableManageOrderInvoices.propTypes = {
  handleRowSelection: PropTypes.func.isRequired,
  tableRef: PropTypes.objectOf(Object).isRequired,
};

export default TableManageOrderInvoices;
