export const addReceiverUseCase = ({
  currentSelectorState,
  rowsSelected,
  prevStateTable,
}) => {
  const selectedPrevIds = prevStateTable
    .filter(row => row.tableData.checked)
    .map(r => r.receiverId);
  const selectedCurrentIds = rowsSelected.map(r => r.receiverId);
  const receiverIdsRemoved = selectedPrevIds.filter(
    r => !selectedCurrentIds.includes(r)
  );
  const newReceiversToAdd = selectedCurrentIds.filter(
    id => !Object.keys(currentSelectorState).includes(id)
  );
  const selectedIdsAgain = selectedCurrentIds.filter(
    id => !selectedPrevIds.includes(id)
  );

  if (newReceiversToAdd.length) {
    return newReceiversToAdd.reduce((acc, receiverId) => {
      const receiverToAdd = rowsSelected.find(r => r.receiverId === receiverId);
      const { amount, invoicesNumber, payrollInvoicesId, totalInvoices } =
        receiverToAdd;
      const allIsSelected = totalInvoices === invoicesNumber;

      return {
        ...acc,
        [receiverId]: {
          amount,
          selected: allIsSelected,
          value: payrollInvoicesId,
        },
      };
    }, currentSelectorState);
  }

  return Object.keys(currentSelectorState).reduce((acc, receiverId) => {
    const currentSelectedReceiver = currentSelectorState[receiverId];

    if (receiverIdsRemoved.includes(receiverId)) {
      const removedReceiver = prevStateTable.find(
        r => r.receiverId === receiverId
      );
      const allDeleted =
        currentSelectedReceiver.value.length ===
        removedReceiver.payrollInvoicesId.length;

      if (allDeleted) {
        delete acc[receiverId];

        return acc;
      }

      return {
        ...acc,
        [receiverId]: {
          amount: currentSelectedReceiver.amount - removedReceiver.amount,
          selected: false,
          value: currentSelectedReceiver.value.filter(
            d => !removedReceiver.payrollInvoicesId.includes(d)
          ),
        },
      };
    }

    if (selectedIdsAgain.includes(receiverId)) {
      const receiverToAdd = rowsSelected.find(r => r.receiverId === receiverId);
      const allIsSelected =
        receiverToAdd.totalInvoices === receiverToAdd.payrollInvoicesId.length;
      const newIdsDocToAdd = receiverToAdd.payrollInvoicesId.filter(
        d => !currentSelectedReceiver.value.includes(d)
      );

      if (allIsSelected) {
        return {
          ...acc,
          [receiverId]: {
            amount: receiverToAdd.amount,
            selected: allIsSelected,
            value: receiverToAdd.payrollInvoicesId,
          },
        };
      }

      const idsDocsToAdd = [
        ...currentSelectedReceiver.value,
        ...newIdsDocToAdd,
      ];
      const selected = idsDocsToAdd.length === receiverToAdd.totalInvoices;

      return {
        ...acc,
        [receiverId]: {
          amount: currentSelectedReceiver.amount + receiverToAdd.amount,
          selected,
          value: idsDocsToAdd,
        },
      };
    }

    return {
      ...acc,
      [receiverId]: currentSelectorState[receiverId],
    };
  }, {});
};
