import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Checkbox,
  Chip,
  styled,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const AgreementFilter = memo(
  ({ agreements, selectedAgreementsIds, handler, clearHandler }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const selectedAgreements = agreements.filter(item =>
      selectedAgreementsIds.includes(item.id)
    );

    const CustomAutocomplete = styled(Autocomplete)({
      width: '100%',
      maxWidth: 200,
    });

    const CustomChip = styled(Chip)({
      maxWidth: 110,
    });

    const CustomTextfield = styled(TextField)({
      margin: 0,
      '& input': {
        minWidth: '0 !important',
        maxWidth: '0 !important',
      },
      '& > div': {
        paddingRight: '0 !important',
        minHeight: 36.44,
      },
    });

    const ExtraTagsIndicator = styled(Box)({
      marginLeft: 10,
    });

    return (
      <CustomAutocomplete
        id="agreementFilter"
        multiple
        openOnFocus
        key={selectedAgreements}
        limitTags={1}
        options={agreements}
        getOptionLabel={option => option.name}
        defaultValue={selectedAgreements}
        onChange={(_event, selectedItems) => {
          const selectedItemsIds = selectedItems.map(item => item.id);
          handler(selectedItemsIds);
        }}
        renderTags={selections => {
          const tagName = selections[0].name;
          const extraTagsQuantity =
            selections.length > 1 && `+${selections.length - 1}`;
          return (
            <>
              <Tooltip title={tagName}>
                <CustomChip label={tagName} size="small" />
              </Tooltip>
              <ExtraTagsIndicator>{extraTagsQuantity}</ExtraTagsIndicator>
            </>
          );
        }}
        renderOption={(option, { selected }) => (
          <Box>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </Box>
        )}
        renderInput={params => (
          <CustomTextfield
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label="Convenios"
            onClear={clearHandler}
          />
        )}
      />
    );
  }
);

AgreementFilter.propTypes = {
  agreements: PropTypes.arrayOf(String).isRequired,
  selectedAgreementsIds: PropTypes.arrayOf(Number).isRequired,
  handler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
};

export default AgreementFilter;
