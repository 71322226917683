import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const downloadSimulationPdfService = async ({ orderId }) => {
  try {
    const { data } = await axios.post(`/api/orders/${orderId}/simulation-pdf/`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default downloadSimulationPdfService;
