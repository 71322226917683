/* eslint-disable no-param-reassign */
import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { devtools } from 'zustand/middleware';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const customDevtools = (store, config) => {
  const customConfig = {
    ...config,
    stateSanitizer: state => ({
      ...state,
    }),
  };
  return devtools(store, customConfig);
};

const createBankAccountSelectorDrawerStore = pipe(
  immer,
  customDevtools,
  create
);

const BankAccountSelectorDrawerStore = createBankAccountSelectorDrawerStore(
  set => ({
    isShown: false,
    show: () =>
      set(state => {
        state.isShown = true;
      }),
    hide: () =>
      set(state => {
        state.isShown = false;
      }),
  })
);

export default BankAccountSelectorDrawerStore;
