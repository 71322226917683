import React from 'react';
import { Text } from '../../../commons/formatters';

const HeaderTitle = () => {
  return (
    <Text variant="h5" fontWeight="bold">
      Lista de operaciones
    </Text>
  );
};

export default HeaderTitle;
