import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const checkInvoicesInActiveOrderService = async facturas => {
  const invoicesIds = facturas.map(({ invoiceId }) => invoiceId).join(',');
  try {
    const { data } = await axios.get(
      `/api/orders/invoices/check-in-active-order`,
      {
        params: { ids: invoicesIds },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

export default checkInvoicesInActiveOrderService;
