import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  FormControlLabel,
  TextareaAutosize,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../components/elements/CheckButton';
import { convertSpacingToCss as spacing } from '../../../../helpers/stylesHelpers';
import { TransitionType } from '../../schemas';

export type Option = {
  id: string;
  name: string;
  enabled: boolean;
  commentsRequired?: boolean;
};

export type OnSubmitData = {
  optionId: string;
  comment: string | null;
  canPP: boolean;
};

type ChangeStateDialogProps = {
  options: Option[];
  title: string;
  loading?: boolean;
  defaultOption?: string;
  defaultComment: string | null;
  defaultTransitionType?: TransitionType;
  riskLDCId?: string;
  t?: (keyword: string) => string;
  onClose: (open: boolean) => void;
  onSubmit: (data: OnSubmitData) => void;
};

const ChangeStateDialog = ({
  title,
  defaultComment,
  defaultOption,
  defaultTransitionType,
  options,
  loading = false,
  riskLDCId,
  onClose,
  onSubmit,
  t = (keyword: string) => keyword,
}: ChangeStateDialogProps) => {
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [optionSelected, setOptionSelected] = useState(defaultOption);
  const [comment, setComment] = useState(defaultComment || null);
  const [isShowCheckPP, setIsShowCheckPP] = useState(false);
  const [canPP, setCanPP] = useState(true);
  const commentsRequired = options.find(
    option => option.id === optionSelected,
  )?.commentsRequired;

  useEffect(() => {
    showCheckPP(defaultOption);
  }, [defaultOption, riskLDCId, defaultTransitionType]);

  const handleClose = (value: boolean) => {
    setOpen(value);
    onClose(false);
  };

  const handleCheckboxChange = () => {
    if (defaultOption !== optionSelected) {
      setChecked(isChecked => !isChecked);
    }
  };

  const handleSubmit = () => {
    if (optionSelected) {
      onSubmit({
        optionId: optionSelected,
        comment,
        canPP,
      });
    }
  };

  const showCheckPP = (stateId: string | undefined) => {
    setIsShowCheckPP(riskLDCId === stateId);
  };

  const onChangeSelectOptionHandler = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string;
    showCheckPP(value);
    if (value === defaultOption) {
      setComment(defaultComment);
      setCanPP(defaultTransitionType === 'PRONTO_PAGO_ALLOWED');
    } else {
      setComment('');
      setCanPP(false);
    }

    setChecked(false);
    setOptionSelected(value);
  };

  const handleChangeCanPP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanPP(event.target.checked);
  };

  return (
    <BaseDialog isOpen={open} handleClose={handleClose} title={title}>
      <Box sx={{ padding: spacing('zero xl') as string }}>
        <ValidatorForm onSubmit={handleSubmit}>
          <FormControl
            fullWidth
            style={{ marginBottom: spacing('lg') as string }}
          >
            <InputLabel id="status-select-label">Estado</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={optionSelected}
              label="Estado"
              onChange={onChangeSelectOptionHandler}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {options.map(option => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  disabled={!option.enabled}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isShowCheckPP && (
            <FormControlLabel
              control={<Checkbox value={canPP} defaultChecked={defaultTransitionType === 'PRONTO_PAGO_ALLOWED'} onChange={handleChangeCanPP} />}
              label={
                <>
                  <span>
                    Cliente apto para operar con <strong>Pronto Pago</strong>
                  </span>
                </>
              }
            />
          )}

          <FormControlLabel
            style={{
              margin: spacing('zero zero lg') as string,
              width: '100%',
              alignItems: 'start',
              gap: 8,
            }}
            labelPlacement="top"
            label={
              <>
                <strong>Comentario</strong>{' '}
                {commentsRequired ? (
                  <span>(Requerido)</span>
                ) : (
                  <span>(Opcional)</span>
                )}
                . Máximo 1000 caracteres
              </>
            }
            control={
              <TextareaAutosize
                maxLength={1000}
                minRows={3}
                maxRows={25}
                value={comment || ''}
                disabled={loading}
                onChange={event => {
                  setComment(event.target.value);
                }}
                aria-label="minimum height"
                style={{
                  borderRadius: 17.5,
                  padding: spacing('sm lg') as string,
                  width: '90%',
                  borderColor: '#ccc',
                  resize: 'none',
                  overflow: 'auto',
                }}
                required={commentsRequired}
                placeholder={t(
                  'Escribe acá los detalles necesarios y pertinentes del cambio de estado',
                )}
              />
            }
          />
          <Box mb={2}>
            <CheckButton
              disabled={options.length === 0}
              check={checked}
              handleCheck={handleCheckboxChange}
              labelButton={t('Cambiar')}
              loading={loading}
            />
          </Box>
        </ValidatorForm>
      </Box>
    </BaseDialog>
  );
};

export default ChangeStateDialog;
