import React, { useState, useEffect, ReactNode } from 'react';
import { Box, Button, IconButton, Tabs, Tab } from '@material-ui/core';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import PartialPaymentDialog from '../../dialogs/PartialPaymentDialog';
import { Close as CloseIcon, Money as MoneyIcon } from '../../icons';
import { dateFormatter } from '../../../helpers/DateUtils';
import CountryFormatHelper from '../../elements/CountryFormatHelper';
import PartialPaymentsTable from '../../tables/PartialPaymentsTable';
import {
  fetchPartialPayments,
  addPartialPayment,
  updatePartialPayment,
  calculateDebtPartialPayment,
} from '../../../actions/partialPaymentsActions';
import {
  BUSINESS_SEGMENT,
  COUNTRY_CODE_CL,
  DIRECT_FINANCING,
} from '../../../helpers/Constants';
import Skeleton from '../../elements/Skeleton';
import {
  useStyles,
  Drawer,
  ContainerRoot,
  Title,
  ContainerRowData,
  RowDataText,
  RowDataTitleText,
  TopDivider,
  BottomDivider,
} from './styles';

const PAY = 'PAY';
const HISTORY = 'HISTORY';

interface PartialPayment {
  id?: number;
  amount: number;
  paymentDate: string;
  comment: string;
}

interface Props {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  selectedInvoice: {
    id: string;
    folio: string;
    payerName: string;
    expirationDate: string;
    orderId: number;
  };
  orderType: string;
}

const PartialPaymentsDrawer = ({
  isOpen,
  handleCloseDrawer,
  selectedInvoice,
  orderType,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    partialPayments,
    fetchPartialPaymentsIsLoading,
    addPartialPaymentIsLoading,
    partialPaymentAdded,
    addPartialPaymentError,
    updatePartialPaymentIsLoading,
    partialPaymentUpdated,
    updatePartialPaymentError,
    fetchCalculateDebtPartialPaymentIsLoading,
    partialPaymentCalculations,
  } = useSelector((state: RootStateOrAny) => state.partialPayments);
  const updatedOrder = useSelector(
    (state: RootStateOrAny) => state.order.updatedOrder
  );
  const country = useSelector((state: RootStateOrAny) => state.config.country);

  const [showAddPartialPaymentDialog, setAddShowPartialPaymentDialog] =
    useState(false);
  const [showUpdatePartialPaymentDialog, setShowUpdatePartialPaymentDialog] =
    useState(false);
  const [partialPaymentSelected, setPartialPaymentSelected] =
    useState<PartialPayment | null>(null);
  const [businessSegment, setBusinessSegment] = useState<string | null>(null);
  const [stateTab, setStateTab] = useState(PAY);

  useEffect(() => {
    if (stateTab === HISTORY && isOpen) {
      dispatch(fetchPartialPayments('orderinvoice', selectedInvoice?.id));
    } else if (stateTab === PAY && isOpen) {
      dispatch(
        calculateDebtPartialPayment('orderinvoice', selectedInvoice?.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTab, isOpen]);

  useEffect(() => {
    if (partialPaymentAdded) {
      setAddShowPartialPaymentDialog(false);
    }
  }, [partialPaymentAdded]);

  useEffect(() => {
    if (partialPaymentCalculations?.segment) {
      const getBusinessSegment = BUSINESS_SEGMENT.find(
        ({ value }) => value === partialPaymentCalculations?.segment
      );
      setBusinessSegment(getBusinessSegment?.label || null);
    }
  }, [partialPaymentCalculations]);

  useEffect(() => {
    if (partialPaymentUpdated) {
      setShowUpdatePartialPaymentDialog(false);
    }
  }, [partialPaymentUpdated]);

  const dataList = [
    {
      label: 'Folio',
      value: selectedInvoice?.folio,
    },
    {
      label: 'Receptor',
      value:
        selectedInvoice?.payerName?.length > 30
          ? `${selectedInvoice?.payerName?.substring(0, 30)}...`
          : selectedInvoice?.payerName,
    },
    {
      label: 'Fecha vencimiento',
      value: dateFormatter(selectedInvoice?.expirationDate),
    },
    {
      label: 'Anticipo',
      value: (
        // @ts-ignore
        <CountryFormatHelper
          value={updatedOrder?.advancePercentage}
          countryId={country}
          variant="percentage"
        />
      ),
    },
    {
      label: 'Tasa de mora',
      value: (
        // @ts-ignore
        <CountryFormatHelper
          value={partialPaymentCalculations?.baseRate}
          countryId={country}
          variant="percentage"
        />
      ),
    },
    {
      label: 'Segmento empresarial',
      value: businessSegment,
    },
  ];

  const dataPayList = [
    {
      label: 'Deuda capital',
      value: (
        // @ts-ignore
        <CountryFormatHelper
          value={partialPaymentCalculations?.totalCapitalDebt}
          countryId={country}
          variant="currency"
        />
      ),
    },
    {
      label: 'Deuda interés',
      value: (
        // @ts-ignore
        <CountryFormatHelper
          value={partialPaymentCalculations?.debtInterestAtDate}
          countryId={country}
          variant="currency"
        />
      ),
    },
  ];

  const tabList = [
    { label: 'Pagar', value: PAY },
    {
      label: 'Historial',
      value: HISTORY,
    },
  ];

  const a11yProps = (index: string) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const getDataListRow = ({
    label,
    value,
    isLoading = false,
  }: {
    label: string;
    value: ReactNode;
    isLoading?: boolean;
  }) => {
    return (
      <ContainerRowData key={`${label}.${value}`} mb={2}>
        <RowDataText>{label}</RowDataText>
        <Skeleton variant="rect" width={150} height={16} isLoading={isLoading}>
          <RowDataText>{value}</RowDataText>
        </Skeleton>
      </ContainerRowData>
    );
  };

  const getDataListTitleRow = ({
    label,
    value,
    isLoading,
  }: {
    label: string;
    value: string;
    isLoading: boolean;
  }) => {
    return (
      <ContainerRowData key={`${label}.${value}`}>
        <RowDataTitleText>{label}</RowDataTitleText>
        <Skeleton variant="rect" width={150} height={20} isLoading={isLoading}>
          <RowDataTitleText>
            {/* @ts-ignore */}
            <CountryFormatHelper
              value={value}
              countryId={country}
              variant="currency"
            />
          </RowDataTitleText>
        </Skeleton>
      </ContainerRowData>
    );
  };

  const handleUpdatePartialPayment = (
    selectedPartialPayment: PartialPayment
  ) => {
    setPartialPaymentSelected(selectedPartialPayment);
    setShowUpdatePartialPaymentDialog(true);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
      <ContainerRoot>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box display="flex" alignItems="center">
            <MoneyIcon />
            <Title>Pagos parciales</Title>
          </Box>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TopDivider />

        <Box display="flex" flexDirection="column" mb={5}>
          {dataList.map(({ label, value }) => getDataListRow({ label, value }))}
        </Box>

        <Tabs
          value={stateTab}
          onChange={(e, valueTab) => setStateTab(valueTab)}
          className={classes.tab}
          TabIndicatorProps={{
            style: {
              height: '3px',
              borderRadius: '10px',
            },
          }}
        >
          {tabList.map(({ label, value }) => {
            return (
              <Tab
                label={label}
                value={value}
                key={label}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...a11yProps(value)}
              />
            );
          })}
        </Tabs>

        <Box
          role="tabpanel"
          hidden={stateTab !== PAY}
          id={`wrapped-tabpanel-${stateTab}`}
          aria-labelledby={`wrapped-tab-${stateTab}`}
        >
          {stateTab === PAY && (
            <Box display="flex" flexDirection="column">
              {country === COUNTRY_CODE_CL && orderType === DIRECT_FINANCING ? (
                <Box display="flex" flexDirection="column">
                  <Box mb={3}>
                    {[
                      {
                        label: 'Total deuda cliente:',
                        value: partialPaymentCalculations?.totalDebt,
                        isLoading: fetchCalculateDebtPartialPaymentIsLoading,
                      },
                    ].map(rowData => getDataListTitleRow(rowData))}
                  </Box>

                  <Box ml={4}>
                    {dataPayList.map(({ label, value }) =>
                      getDataListRow({
                        label,
                        value,
                        isLoading: fetchCalculateDebtPartialPaymentIsLoading,
                      })
                    )}
                  </Box>

                  <BottomDivider />

                  <Box mb={5}>
                    {[
                      {
                        label: 'Total deuda Pagador:',
                        value: partialPaymentCalculations?.payerDebtFD,
                        isLoading: fetchCalculateDebtPartialPaymentIsLoading,
                      },
                    ].map(rowData => getDataListTitleRow(rowData))}
                  </Box>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  {dataPayList.map(({ label, value }) =>
                    getDataListRow({
                      label,
                      value,
                      isLoading: fetchCalculateDebtPartialPaymentIsLoading,
                    })
                  )}
                  <Box mb={5}>
                    {[
                      {
                        label: 'Total:',
                        value: partialPaymentCalculations?.totalDebt,
                        isLoading: fetchCalculateDebtPartialPaymentIsLoading,
                      },
                    ].map(rowData => getDataListTitleRow(rowData))}
                  </Box>
                </Box>
              )}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setAddShowPartialPaymentDialog(true)}
              >
                Agregar pago parcial
              </Button>
            </Box>
          )}
        </Box>

        <Box
          role="tabpanel"
          hidden={stateTab !== HISTORY}
          id={`wrapped-tabpanel-${stateTab}`}
          aria-labelledby={`wrapped-tab-${stateTab}`}
          mb={5}
        >
          {stateTab === HISTORY && (
            <PartialPaymentsTable
              partialPayments={partialPayments}
              handleUpdatePartialPayment={handleUpdatePartialPayment}
              isLoading={fetchPartialPaymentsIsLoading}
            />
          )}
        </Box>
      </ContainerRoot>
      {showAddPartialPaymentDialog && (
        <PartialPaymentDialog
          open={showAddPartialPaymentDialog}
          handleCloseDialog={() => setAddShowPartialPaymentDialog(false)}
          handleSubmit={data =>
            dispatch(
              addPartialPayment('orderinvoice', selectedInvoice.id, {
                ...data,
                operationId: selectedInvoice?.orderId,
              })
            )
          }
          isLoading={addPartialPaymentIsLoading}
          error={addPartialPaymentError}
        />
      )}

      {showUpdatePartialPaymentDialog && (
        <PartialPaymentDialog
          open={showUpdatePartialPaymentDialog}
          handleCloseDialog={() => setShowUpdatePartialPaymentDialog(false)}
          selectedPartialPayment={partialPaymentSelected}
          handleSubmit={data =>
            dispatch(
              updatePartialPayment(
                'orderinvoice',
                selectedInvoice.id,
                partialPaymentSelected?.id,
                { ...data, operationId: selectedInvoice?.orderId }
              )
            )
          }
          isLoading={updatePartialPaymentIsLoading}
          error={updatePartialPaymentError}
        />
      )}
    </Drawer>
  );
};

export default PartialPaymentsDrawer;
