import axios from 'axios';

interface Props {
  orderInvoices: number[];
  type: string;
  subType: string;
  result: string;
  appliedOn: string;
  justification: string;
}

export const bulkCreateManagements = async ({
  orderInvoices,
  type,
  subType,
  result,
  appliedOn,
  justification,
}: Props) => {
  try {
    const { data } = await axios.post('/api/collection/debts/managements/bulk-create', {
      orderInvoicesIds: orderInvoices,
      type,
      subType,
      result,
      appliedOn,
      detail: justification,
    });
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};