import React from 'react';

import ChangeStateDialog, { OnSubmitData } from '../dialogs/ChangeStateDialog';

import { useGetWorkflowStates } from './useGetWorkflowStates';
import { useCreateTransitionAction } from '../../hooks/useCreateTransitionAction';
import { noop } from '../../utils';
import { TransitionType } from '../../schemas';

type Props = {
  onClose: (open: boolean) => void;
  businessIdentifier: string;
  workflowId?: string;
  workflowName?: string;
  currentStateId?: string;
  currentStateComment: string | null;
  currentTransitionType: TransitionType | undefined;
  loading?: boolean;
  riskLdcAssignedId?: string;
  onSuccess?: (entityId: string, workflowId: string) => void;
};

const ManageChangeStateDialog = ({
  businessIdentifier,
  onClose,
  workflowId,
  workflowName,
  currentStateId,
  currentStateComment,
  currentTransitionType,
  loading,
  riskLdcAssignedId,
  onSuccess = noop,
}: Props) => {
  const { loading: loadingWorkflowStates, workflowStates } = useGetWorkflowStates({
    currentStateId: currentStateId!,
    workflowId: workflowId!,
  });

  const {
    create,
    loading: createTransitionActionLoading,
  } = useCreateTransitionAction({ onSuccess });

  const submitHandler = (data: OnSubmitData) => {
    if (workflowId && currentStateId) {
      create({
        targetId: data.optionId,
        workflowId,
        businessIdentifier,
        currentStateId,
        comment: data.comment,
        type: data.canPP ? TransitionType.prontoPagoAllowed : TransitionType.normal,
      });
    }
  };

  return (
    <ChangeStateDialog
      title={`Cambiar estado de ${workflowName || ''}`}
      loading={createTransitionActionLoading || loadingWorkflowStates || loading}
      defaultComment={currentStateComment}
      defaultOption={currentStateId}
      defaultTransitionType={currentTransitionType}
      options={workflowStates}
      onClose={onClose}
      onSubmit={submitHandler}
      riskLDCId={riskLdcAssignedId}
    />
  );
};

export default ManageChangeStateDialog;
