/* eslint-disable react/prop-types */
import React from 'react';

import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { Skeleton } from '../../../../commons/components/Skeleton';
import { defaultValue } from '../../../../commons/utils';

export const DIALOG_TYPES = {
  ADVANCE: 'ADVANCE',
  DEBT_SERVICE_AMOUNT: 'DEBT_SERVICE_AMOUNT',
  RATE: 'RATE',
  TERM: 'TERM',
};

export const GET_RECEIVER_SUMMARY = ({
  country,
  selectedReceiver,
  simulation,
}) => [
  {
    title: 'N° Emisores:',
    value: (
      <Skeleton
        height={13}
        isLoading={simulation?.isLoading || false}
        style={{ marginTop: '4px' }}
        variant="rect"
        width={40}
      >
        {defaultValue(selectedReceiver?.issuersNumber)}
      </Skeleton>
    ),
  },
  {
    title: 'N° Facturas:',
    value: (
      <Skeleton
        height={13}
        isLoading={simulation?.isLoading || false}
        style={{ marginTop: '4px' }}
        variant="rect"
        width={40}
      >
        {selectedReceiver?.value?.length}
      </Skeleton>
    ),
  },
  {
    title: 'Monto bruto:',
    value: (
      <Skeleton
        height={13}
        isLoading={simulation?.isLoading || false}
        style={{ marginTop: '4px' }}
        variant="rect"
        width={40}
      >
        <CountryFormatHelper
          value={selectedReceiver?.amount}
          countryId={country}
          variant="currency"
        />
      </Skeleton>
    ),
  },
  {
    title: 'Plazo ponderado:',
    value: (
      <Skeleton
        height={13}
        isLoading={simulation?.isLoading || false}
        style={{ marginTop: '4px' }}
        variant="rect"
        width={40}
      >
        {defaultValue(selectedReceiver?.term)}
      </Skeleton>
    ),
  },
  {
    title: 'Score ponderado:',
    value: (
      <Skeleton
        height={13}
        isLoading={simulation?.isLoading || false}
        style={{ marginTop: '4px' }}
        variant="rect"
        width={40}
      >
        {defaultValue(selectedReceiver?.score)}
      </Skeleton>
    ),
  },
];
