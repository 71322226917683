import { hasValue } from './predicates';

const fixNumberTo = lengthDecimals => num => {
  if (!hasValue(num)) {
    return '';
  }

  const isINT = num % 1 === 0;
  const formattedNumber = isINT ? num : (+num)?.toFixed(lengthDecimals);

  return +formattedNumber;
};

export const roundToInt = fixNumberTo(0);

export const fixDecimals = fixNumberTo(2);
