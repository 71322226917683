import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { uploadBulkFundsService } from '../services';

export const useUploadBulkFunds = () => {
  const dispatch = useDispatch();
  const {
    isLoading: uploadBulkFundsLoading,
    isSuccess: uploadBulkFundsSuccess,
    error: uploadBulkFundsError,
    mutate: uploadBulkFunds,
  } = useMutation(
    bulkInvoices => {
      return uploadBulkFundsService(bulkInvoices);
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessUploadBulkFunds } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FUND_FINANCING_ADDED, {
            variant: 'success',
          })
        );
        onSuccessUploadBulkFunds();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_INVOICE_FUND_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    uploadBulkFundsLoading,
    uploadBulkFundsSuccess,
    uploadBulkFundsError,
    uploadBulkFunds,
  };
};

export default useUploadBulkFunds;
