
export enum StateType {
  operation,
  legal,
  risk,
}

export const statesMetadata = [
  {
    id: '38e425bd-1f73-478f-8c19-f69f85312b15',
    type: StateType.risk,
    position: 1,
    commentsRequired: false,
  },
  {
    id: '0eb4b3f4-65de-493e-8ca6-e50f022a03f1',
    type: StateType.risk,
    position: 2,
    commentsRequired: false,
  },
  {
    id: 'f539d01c-f5dc-4dc4-b209-f633be85041e',
    type: StateType.risk,
    position: 3,
    commentsRequired: false,
  },
  {
    id: 'b19fe325-9ed1-4229-893a-d34bddcbbe95',
    type: StateType.risk,
    position: 4,
    commentsRequired: false,
  },
  {
    id: 'd3950f69-291a-4e94-8e2b-4bf73dc736f1',
    type: StateType.risk,
    position: 5,
    commentsRequired: true,
  },
  {
    id: '3a6084d5-b3e0-460a-8ef7-1ca46e3714ac',
    type: StateType.operation,
    position: 1,
    commentsRequired: false,
  },
  {
    id: '8613dd51-981c-4598-a2aa-a451c6a1e5ff',
    type: StateType.operation,
    position: 2,
    commentsRequired: false,
  },
  {
    id: 'c16d8427-36b6-4819-89c5-67734bf33dfd',
    type: StateType.operation,
    position: 3,
    commentsRequired: false,
  },
  {
    id: '7c2634aa-cc6b-4de6-8df5-165d95d7cec7',
    type: StateType.operation,
    position: 4,
    commentsRequired: false,
  },
  {
    id: '05d8ff58-423a-4d8c-a84c-3f89b9b40f85',
    type: StateType.operation,
    position: 5,
    commentsRequired: true,
  },
  {
    id: 'c7b2e6aa-6b93-441a-9724-c62506cd6677',
    type: StateType.operation,
    position: 6,
    commentsRequired: true,
  },
  {
    id: '45cc7189-f483-424f-9f2e-1aa8506e8e51',
    type: StateType.operation,
    position: 7,
    commentsRequired: false,
  },
  {
    id: '12d3932d-be5e-4e05-9e83-2754156494d0',
    type: StateType.operation,
    position: 8,
    commentsRequired: true,
  },
  {
    id: '9310b502-f475-4910-bf23-d82e9ec2324b',
    type: StateType.legal,
    position: 6,
    commentsRequired: true,
  },
  {
    id: '9bc36605-bd20-4064-8cb9-3974a1e172f2',
    type: StateType.legal,
    position: 1,
    commentsRequired: false,
  },
  {
    id: 'a719fe4c-c022-481f-b3c7-cda843a73c1c',
    type: StateType.legal,
    position: 2,
    commentsRequired: false,
  },
  {
    id: 'e23a7838-179e-44ea-b2ed-28e46a7e2b9f',
    type: StateType.legal,
    position: 3,
    commentsRequired: false,
  },
  {
    id: 'babfb5c9-05fa-4ea1-8d7c-479b78ce35a3',
    type: StateType.legal,
    position: 4,
    commentsRequired: true,
  },
  {
    id: '2380ba5b-c329-4b62-8cbd-cc3392883752',
    type: StateType.legal,
    position: 5,
    commentsRequired: true,
  },
] as const;
