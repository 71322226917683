import {
  Box,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  Collapse,
  CircularProgress,
  styled,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDocument,
  createDocumentWithSignedFile,
  fetchDocumentsTypes,
  resetDocumentErrors,
} from '../../actions/documentActions';
import { toBase64 } from '../../helpers/fileToBase64';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import Dropzone from '../elements/Dropzone/Dropzone';
import BaseDialog from './BaseDialog';
import MenuItem from '../elements/MenuItem';
import { features } from '../../config/features';
import {
  MIFIEL_ON,
  UPLOAD,
  SIGN,
  DOWNLOAD,
  COUNTRY_CODE_CL,
} from '../../helpers/Constants';

const useStyles = makeStyles({
  inputMultiLine: {
    '& fieldset': {
      borderRadius: 17,
    },
  },
});
const ContainerSpinner = styled(Box)({
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'center',
});
const TextDocumentType = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 13,
  marginTop: '-10px',
  marginBottom: 20,
}));
const ContainerDescription = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
});
const LabelDescription = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 5,
}));
const LabelDefault = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 5,
}));
const LabelUploadDocument = styled(Typography)(theme => ({
  ...theme.props,
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 15,
}));
const ContainerError = styled(Box)({
  marginTop: 30,
});
const ContainerSaveButton = styled(Box)({
  marginTop: 30,
});
const ContainerInitialState = styled(Box)({
  marginBottom: 20,
});
const FIELD_RELATION_TYPE = 'relationType';

const ORDER = 'order';
const BUSINESS = 'business';
const CREDIT = 'credit';
const CONTRACT = 'Contrato';
const DOCUMENTS_WITHOUT_INITIAL_DOCUMENT = [MIFIEL_ON];
const CONTRACTS = [
  { id: 1, title: 'Contrato Unificado' },
  { id: 2, title: 'Contrato Unificado (sin firma Xepelin)' },
  { id: 3, title: 'Contrato Payments' },
  { id: 4, title: 'Contrato Payments (sin firma Xepelin)' },
  { id: 5, title: 'Contrato Pronto Pago/Confirming' },
  { id: 6, title: 'Contrato Pronto Pago/Confirming (sin firma Xepelin)' },
  { id: 7, title: 'Contrato Crédito' },
  { id: 8, title: 'Contrato Crédito (sin firma Xepelin)' },
  { id: 9, title: 'Contrato Financiamiento Directo' },
  { id: 10, title: 'Contrato Financiamiento Directo (sin firma Xepelin)' },
];

const descriptionPlaceholderRequired =
  'Escribe la descripción o especificación del documento';
const descriptionPlaceholderNotRequired =
  'Escribe la descripción o especificación en caso de ser necesario del documento que vas a subir.';

const CreateDocumentDialog = ({
  open,
  handleCloseDialog,
  isLoading,
  error,
  documentableId,
  documentableType,
  customAction,
  showCustomDocument,
  showDescription,
  showSignatureType,
  showInitialStatus,
  mimetypes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { country } = useSelector(state => state.config);
  const { documentTypes, fetchDocumentTypesIsLoading } = useSelector(
    state => state.documents
  );
  const countryFeatures = features[country];
  const initialAction = countryFeatures.businessDetailsSection.legal
    .createDocumentWithElectronicSignature
    ? MIFIEL_ON
    : SIGN;

  const [documentInfo, setDocumentInfo] = useState({
    title: '',
    description: '',
    action: customAction || initialAction,
    active: true,
    subTitle: '',
  });
  const [showDropzone, setShowDropzone] = useState(true);
  const [file, setFile] = useState(null);
  const [checkButton, setCheckButton] = useState(false);
  const [isCustomDocument, setIsCustomDocument] = useState(false);

  const { title, description, subTitle } = documentInfo;

  const RELATION_TYPE = {
    CL: () => getRelationType(),
    MX: () => documentableType
  }

  const getRelationType = () => {
    if (documentableType === ORDER || documentableType === BUSINESS) {
      return BUSINESS;
    }
    return CREDIT;
  };

  useEffect(() => {
    dispatch(
      fetchDocumentsTypes({
        field: FIELD_RELATION_TYPE,
        searchInput: RELATION_TYPE[country](),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentTypes?.length) {
      setDocumentInfo({ ...documentInfo, title: documentTypes[0]?.title });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypes]);

  useEffect(() => {
    if (CONTRACTS.length) {
      setDocumentInfo({ ...documentInfo, subTitle: CONTRACTS[0]?.title });
    }
  }, [CONTRACTS]);

  const handleChangeStatus = (documentFile, status) => {
    if (status === 'done') {
      setFile(documentFile.file);
    } else if (status === 'removed') {
      setFile(null);
    }
  };

  const handleChangeDocumentInfo = ({ target }) => {
    const { value, name } = target;

    if (name === 'title') {
      setIsCustomDocument(value === 'Otro');
    }

    setDocumentInfo({
      ...documentInfo,
      [name]: value,
    });
  };

  const handleChangeDocumentActive = ({ target }) => {
    const isActive = target.value.includes('true');
    setDocumentInfo({
      ...documentInfo,
      active: isActive,
    });
    setShowDropzone(isActive);
  };

  const handleSubmitDialog = async () => {
    const fileBase64 = file ? await toBase64(file) : undefined;
    if (!file) {
      dispatch(createDocument(documentableId, documentableType, documentInfo));
    } else {
      dispatch(
        createDocumentWithSignedFile(
          documentableId,
          documentableType,
          documentInfo,
          fileBase64
        )
      );
    }
  };

  const getMenuItem = ({ id, title }) => (
    <MenuItem value={title} key={id}>
      {title}
    </MenuItem>
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={() => {
        if (documentableType) {
          dispatch(resetDocumentErrors(documentableType));
        }
        handleCloseDialog();
      }}
      title="Subir nuevo documento"
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={handleSubmitDialog}>
          {fetchDocumentTypesIsLoading ? (
            <ContainerSpinner>
              <CircularProgress size={30} />
            </ContainerSpinner>
          ) : (
            <SelectValidator
              disabled={fetchDocumentTypesIsLoading}
              variant="outlined"
              value={title}
              id="title"
              name="title"
              label="Tipo de documento"
              placeholder="CC"
              onChange={handleChangeDocumentInfo}
              fullWidth
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {documentTypes?.map(documentType => getMenuItem(documentType))}
              {showCustomDocument && <MenuItem value="Otro">Otro</MenuItem>}
            </SelectValidator>
          )}
          <TextDocumentType>
            El tipo de documentos se le mostrará al usuario en su perfil.
          </TextDocumentType>

          {title === CONTRACT && country === COUNTRY_CODE_CL && (
            <SelectValidator
              variant="outlined"
              value={subTitle}
              id="subTitle"
              name="subTitle"
              label="Tipo de contrato"
              placeholder="test"
              onChange={handleChangeDocumentInfo}
              fullWidth
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {CONTRACTS.map(documentType => getMenuItem(documentType))}
            </SelectValidator>
          )}

          {showDescription && (
            <>
              <ContainerDescription>
                <LabelDescription>Descripción</LabelDescription>
                <Typography variant="caption">
                  {isCustomDocument ? '(Requerido)' : '(Opcional)'}
                </Typography>
              </ContainerDescription>
              <TextValidator
                fullWidth
                multiline
                maxRows={2}
                variant="outlined"
                type="string"
                id="description"
                name="description"
                inputProps={{ maxLength: 50 }}
                validators={isCustomDocument ? ['required'] : []}
                errorMessages={isCustomDocument ? ['Campo requerido'] : []}
                placeholder={
                  isCustomDocument
                    ? descriptionPlaceholderRequired
                    : descriptionPlaceholderNotRequired
                }
                aria-describedby="description"
                value={description}
                onChange={handleChangeDocumentInfo}
                classes={{
                  root: classes.inputMultiLine,
                }}
              />
            </>
          )}

          {showSignatureType && (
            <>
              <LabelDefault>Tipo de firma</LabelDefault>
              <SelectValidator
                variant="outlined"
                id="action"
                value={documentInfo.action}
                name="action"
                onChange={handleChangeDocumentInfo}
                fullWidth
              >
                {countryFeatures.businessDetailsSection.legal
                  .createDocumentWithElectronicSignature && (
                  <MenuItem value={MIFIEL_ON}>E-firma</MenuItem>
                )}
                <MenuItem value={SIGN}>Firma física</MenuItem>
                <MenuItem value={UPLOAD}>Solo subida</MenuItem>
                <MenuItem value={DOWNLOAD}>Solo bajada</MenuItem>
              </SelectValidator>
            </>
          )}
          {showInitialStatus && (
            <ContainerInitialState>
              <LabelDefault>Estado inicial</LabelDefault>
              <RadioGroup
                row
                name="active"
                value={documentInfo.active}
                onChange={handleChangeDocumentActive}
              >
                <FormControlLabel
                  value
                  control={<Radio color="primary" />}
                  label="Activo"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Inactivo"
                />
              </RadioGroup>
            </ContainerInitialState>
          )}
          <Collapse
            in={
              showDropzone &&
              !DOCUMENTS_WITHOUT_INITIAL_DOCUMENT.includes(documentInfo?.action)
            }
            timeout="auto"
            unmountOnExit
          >
            <LabelUploadDocument>Subir documento final</LabelUploadDocument>
            <Dropzone
              maxFiles={1}
              minSize={100}
              isLoading={isLoading}
              onChangeStatus={handleChangeStatus}
              SubmitButtonComponent={null}
              accept={mimetypes}
            />
          </Collapse>

          {error && (
            <ContainerError item xs={12} align="center">
              <AlertForm variant="error" message={error} />
            </ContainerError>
          )}

          <ContainerSaveButton>
            <CheckButton
              check={checkButton}
              handleCheck={() => setCheckButton(!checkButton)}
              labelButton="Agregar documento"
              loading={isLoading}
            />
          </ContainerSaveButton>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

CreateDocumentDialog.defaultProps = {
  error: null,
  customAction: null,
  showCustomDocument: true,
  showDescription: true,
  showSignatureType: true,
  showInitialStatus: true,
  mimetypes: '.pdf,image/jpeg,image/gif,image/png',
};

CreateDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  documentableId: PropTypes.string.isRequired,
  documentableType: PropTypes.string.isRequired,
  customAction: PropTypes.string,
  showCustomDocument: PropTypes.bool,
  showDescription: PropTypes.bool,
  showSignatureType: PropTypes.bool,
  showInitialStatus: PropTypes.bool,
  mimetypes: PropTypes.string,
};

export default CreateDocumentDialog;
