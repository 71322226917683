import { Grid, styled } from '@material-ui/core';
import { palette } from '../../../../theme/palette';
import { Text } from '../../../commons/formatters';

const ContainerGrid = styled(Grid)({
  background: '#FFFFFF',
  padding: '0px 32px',
  borderRadius: 16,
});

const DatePaddedGrid = styled(Grid)({
  margin: '52px 52px 52px 0px',
});

const PaddedGrid = styled(Grid)({
  margin: '52px 52px 52px 0px',
  minWidth: 215,
});

const CreditAmountGrid = styled(Grid)({
  margin: '24px 0px',
});

const IconChipGrid = styled(Grid)({
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const CreditGrid = styled(Grid)({
  marginRight: 101,
});

const FullHeightGrid = styled(Grid)({
  heigh: '100%',
});

const CreditAmountText = styled(Text)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.primary.main,
}));

const TitleGrid = styled(Grid)({
  marginBottom: 4,
});

const BottomSpacedGrid = styled(Grid)({
  marginBottom: 8,
});

const CustomText = styled(Text)({
  fontSize: 16,
});

const IconChipContainer = styled(Grid)({
  width: 48,
  height: 48,
  backgroundColor: palette.payments.backgroundGrey,
  borderRadius: 50,
});

export {
  ContainerGrid,
  PaddedGrid,
  CreditAmountGrid,
  IconChipGrid,
  CreditGrid,
  FullHeightGrid,
  CreditAmountText,
  TitleGrid,
  BottomSpacedGrid,
  CustomText,
  IconChipContainer,
  DatePaddedGrid,
};
