import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';

const DataListRow = ({ label, value, isLoading }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      key={`${label}.${value}`}
      className={classes.dataListRow}
    >
      <Typography variant="body1" color="textSecondary" component="div">
        <Box fontWeight="fontWeightBold">{label}</Box>
      </Typography>
      {isLoading ? (
        <Skeleton variant="rect" width={150} height={16} />
      ) : (
        <Typography variant="body1" color="textSecondary" component="div">
          <Box fontWeight="fontWeightBold">{value}</Box>
        </Typography>
      )}
    </Box>
  );
};

DataListRow.defaultProps = {
  label: '',
  value: '',
  isLoading: false,
};

DataListRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  isLoading: PropTypes.bool,
};

export default DataListRow;
