import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Download = props => {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.916707 10.1404H9.08337C9.23808 10.1404 9.38646 10.2033 9.49585 10.3151C9.60525 10.427 9.66671 10.5787 9.66671 10.7369C9.66671 10.8951 9.60525 11.0468 9.49585 11.1587C9.38646 11.2706 9.23808 11.3334 9.08337 11.3334H0.916707C0.761998 11.3334 0.613625 11.2706 0.504229 11.1587C0.394832 11.0468 0.333374 10.8951 0.333374 10.7369C0.333374 10.5787 0.394832 10.427 0.504229 10.3151C0.613625 10.2033 0.761998 10.1404 0.916707 10.1404ZM5.58337 6.66587L7.47512 4.73205L8.29996 5.57548L5.00004 8.94983L1.70012 5.57548L2.52496 4.73205L4.41671 6.66587V0.666748H5.58337V6.66587Z"
        fill="#312F37"
      />
    </SvgIcon>
  );
};

export default Download;
