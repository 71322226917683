import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  infoContainer: {
    borderRadius: 5,
    overflow: 'hidden',
    marginBottom: 16,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.info.main,
    paddingLeft: 6,
    paddingRight: 7,
  },
  textContainer: {
    fontSize: 12,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'rgba(0, 147, 255, 0.2)',
  },
}));
