import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '../../../commons/components';

const LockOperationDialog = ({
  open,
  handleClose,
  title,
  message,
  actionText,
  handleAction,
  isLoading,
}) => {
  return (
    <Dialog
      handleAction={handleAction}
      title={title}
      message={message}
      isOpen={open}
      handleClose={handleClose}
      actionText={actionText}
      isLoading={isLoading}
    />
  );
};

LockOperationDialog.defaultProps = {
  isLoading: false,
};

LockOperationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default LockOperationDialog;
