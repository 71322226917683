import { gql } from 'graphql-request';

export const query = gql`
  query workflowStateList($dto: GetWorflowStateListDTO!) {
    workflowStateList(dto: $dto) {
      enabled
      state {
        name
        id
      }
    }
  }
`;

export type Query = {
  workflowStateList: Array<{
    enabled: boolean;
    state: {
      name: string;
      id: string;
    };
  }>;
};
