import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  IconButton,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import RatesStepper from './RatesStepper';
import Can from '../Can';
import {
  FUNDS_ADD_BANK_ACCOUNT_PERFORM,
  FUNDS_EDIT_RATE_PERFORM,
} from '../../helpers/performsType';
import {
  CONFIRMING,
  CO_LABEL,
  DF_LABEL,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
  EP_LABEL,
  COUNTRY_CODE_MX,
} from '../../helpers/Constants';
import { fetchFundRates } from '../../actions/fundsActions';
import { fetchBankAccountsById } from '../../actions/bankActions';
import { settings } from '../../config/settings';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '25px 30px',
  },
  appBar: {
    background: 'inherit',
    color: theme.palette.grey[800],
    borderBottom: '1px solid #d9d9d9',
    '& .Mui-selected': {
      color: '#5d5966',
    },
  },
  headerTab: {
    borderBottom: '1px solid #d9d9d9',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  blueFont: {
    color: theme.palette.primary.main,
  },
  loader: {
    justifyContent: 'space-around',
  },
}));

const entity = 'fund';

const FundRatesDetailsCard = ({
  fund: { id: fundId, BankAccounts },
  handleFundRatesDetail,
  handleAddRateFormDialog,
  handleEditStepperFormDialog,
  handleShowBankAccountDialog,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [operationTypeSelected, setOperationTypeSelected] =
    useState(DIRECT_FINANCING);

  const { fundRates, loadingFundRates, deletedFundRates } = useSelector(
    state => state.funds
  );
  const { bankAccounts, registeredBankAccount } = useSelector(
    state => state.banks
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const classes = useStyles();

  const handleTabChange = (e, activeTab) => {
    setOperationTypeSelected(activeTab);
    handleFundRatesDetail(fundId, activeTab);
  };

  useEffect(() => {
    handleFundRatesDetail(fundId, operationTypeSelected);
    if (!(fundId in bankAccounts) && isApiGlobal) {
      dispatch(fetchBankAccountsById(entity, fundId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deletedFundRates) {
      dispatch(fetchFundRates(fundId, operationTypeSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedFundRates]);

  useEffect(() => {
    if (registeredBankAccount && country === COUNTRY_CODE_MX) {
      dispatch(fetchBankAccountsById(entity, fundId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredBankAccount]);

  const handleClickMenu = event => {
    event.persist();
    setAnchorEl(event.target);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getNormalizedAccountType = accountType => {
    switch (accountType) {
      case 'CUENTA_CORRIENTE':
        return 'Cuenta corriente';
      case 'CUENTA_VISTA':
        return 'Cuenta vista';
      case 'CUENTA_AHORRO':
        return 'Cuenta de ahorro';
      default:
        return accountType;
    }
  };

  const defaultBankAccount =
    BankAccounts?.length > 0
      ? BankAccounts[0]
      : bankAccounts[entity] && bankAccounts[entity][fundId]?.length
      ? bankAccounts[entity][fundId][0]
      : undefined;
  return (
    <Grid
      container
      className={classes.container}
      xs={12}
      direction="row"
      spacing={3}
    >
      <Grid item xs={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <AppBar
                  position="static"
                  className={classes.appBar}
                  elevation={0}
                >
                  <Tabs
                    value={operationTypeSelected}
                    onChange={(e, v) => handleTabChange(e, v)}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#427cf1',
                      },
                    }}
                  >
                    <Tab label={DF_LABEL} value={DIRECT_FINANCING} />
                    <Tab label={EP_LABEL} value={EARLY_PAYMENT} />
                    <Tab label={CO_LABEL} value={CONFIRMING} />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body1">
                  Tasas y plazos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {loadingFundRates ? (
                  <Grid item xs={12}>
                    <Grid container direction="row" className={classes.loader}>
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <RatesStepper
                    fundRates={fundRates.sort(
                      (fr1, fr2) => fr1.days - fr2.days
                    )}
                    handleAddFundRateForm={() =>
                      handleAddRateFormDialog(operationTypeSelected, fundId)
                    }
                    handleClickAction={data => {
                      handleEditStepperFormDialog(data);
                    }}
                    operation={operationTypeSelected}
                    perform={FUNDS_EDIT_RATE_PERFORM}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body1" color="primary">
                  CUENTA BANCARIA PREDETERMINADA
                </Typography>
              </Grid>
              <Grid item>
                <IconButton ref={anchorEl} onClick={e => handleClickMenu(e)}>
                  <MoreIcon />
                </IconButton>
                <Menu
                  className={classes.logout}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseMenu()}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                >
                  <Can
                    perform={FUNDS_ADD_BANK_ACCOUNT_PERFORM}
                    yes={() => (
                      <MenuItem
                        onClick={() => {
                          handleShowBankAccountDialog(fundId);
                          setAnchorEl(null);
                        }}
                      >
                        Agregar cuenta nueva
                      </MenuItem>
                    )}
                  />
                  <MenuItem
                    onClick={() =>
                      history.push(
                        `/${country.toLowerCase()}/funds/${fundId}/bank-accounts`
                      )
                    }
                  >
                    Ver otras cuentas
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          {defaultBankAccount ? (
            <Grid container xs={12} justifyContent="space-between">
              <Grid container direction="column" spacing={1} xs={6}>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    Nombre:{' '}
                    <Box
                      className={classes.displayInLineBlock}
                      fontWeight="fontWeightBold"
                    >
                      {defaultBankAccount.accountHolder}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    {t(t13s.LABEL.BUSINESS_IDENTIFIER).toUpperCase()}:{' '}
                    <Box
                      className={classes.displayInLineBlock}
                      fontWeight="fontWeightBold"
                    >
                      {defaultBankAccount?.accountRut ??
                        defaultBankAccount?.sourceIdentifier}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    EMAIL:{' '}
                    <Box
                      className={classes.displayInLineBlock}
                      fontWeight="fontWeightBold"
                    >
                      {defaultBankAccount?.accountEmail}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" spacing={1} xs={6}>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    Banco:{' '}
                    <Box
                      className={classes.displayInLineBlock}
                      fontWeight="fontWeightBold"
                    >
                      {defaultBankAccount?.Bank?.name}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    Tipo de cuenta:{' '}
                    <Box
                      className={classes.displayInLineBlock}
                      fontWeight="fontWeightBold"
                    >
                      {getNormalizedAccountType(
                        defaultBankAccount?.accountType
                      )}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    Nº de cuenta:{' '}
                    <Box
                      className={classes.displayInLineBlock}
                      fontWeight="fontWeightBold"
                    >
                      {defaultBankAccount.accountNumber}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            'No hay cuentas agregadas aún'
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

FundRatesDetailsCard.propTypes = {
  fund: PropTypes.objectOf(Object).isRequired,
  handleAddRateFormDialog: PropTypes.func.isRequired,
  handleEditStepperFormDialog: PropTypes.func.isRequired,
  handleFundRatesDetail: PropTypes.func.isRequired,
  handleShowBankAccountDialog: PropTypes.func.isRequired,
};

export default FundRatesDetailsCard;
