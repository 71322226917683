import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import updateOrderCommentsTagsService from '../services/updateOrderCommentsTags';
import { enqueueSnackbar } from '../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../translationKeys/index';
import { fetchCountOrderComments } from '../../../../../../actions/orderActions';

interface Variables {
  commentIds: number[];
  operationId: number;
  userEmail: string;
}

const useUpdateOrderCommentsTags = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: updateOrderCommentsTags,
  } = useMutation(
    ({ commentIds, userEmail }: Variables) =>
      updateOrderCommentsTagsService(commentIds, userEmail),
    {
      onSuccess: (_data, variables) => {
        dispatch(
          fetchCountOrderComments(variables?.operationId, variables?.userEmail)
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_COMMENT_TAGS_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    updateOrderCommentsTags,
  };
};

export default useUpdateOrderCommentsTags;
