export const HOME_START_MAIN_PURPLE = '#731DFB';
export const HOME_START_TEXT_PRIMARY = '#707070';

export const TOPBAR_PUBLIC_TEXT_MENU = '#2b2350';
export const TOPBAR_PUBLIC_TEXT_MENU_HOVER = '#7052fb';

export const DRAWER_BACKGROUND = '#f6f5f7';

export const TABLE_HEADER_TEXT = '#a8a4b3';

export const TOOLTIP_COLOR = '#575360';

export const CHIP_VARIANTS = {
  blue: {
    code: 'blue',
    color: '#0529F0',
  },
  lightblue: {
    code: 'lightblue',
    color: '#0093FF',
  },
  green: {
    code: 'green',
    color: '#119B41',
  },
  lightgreen: {
    code: 'lightgreen',
    color: '#75B801',
  },
  yellow: {
    code: 'yellow',
    color: '#E29D02',
  },
  purple: {
    code: 'purple',
    color: '#901AE7',
  },
  red: {
    code: 'red',
    color: '#D92F24',
  },
  orange: {
    code: 'orange',
    color: '#FF7F00',
  },
  lightorange: {
    code: 'lightorange',
    color: '#E29D02',
  },
  grey: {
    code: 'grey',
    color: '#4C6074',
  },
  pink: {
    code: 'pink',
    color: '#DA27F3',
  },
  default: {
    code: 'default',
    color: '#B5B2B7',
  },
};

export const CARD_VARIANTS = {
  blue: {
    code: 'blue',
    background: '#1A73E72B',
    border: '#1a73e7',
  },
  green: {
    code: 'green',
    background: '#E6F8C5',
    border: '#8CC63F',
  },
  grey: {
    code: 'grey',
    background: '#F2F2F2',
    border: '#DBDDEB',
  },
  white: {
    code: 'white',
    background: '#FFF',
    border: '#E7E5EC',
  },
};

export const ROLES_COLORS = [
  '#A8A4B3',
  '#575360',
  '#0093FF',
  '#1A49E7',
  '#7300FF',
  '#E29D02',
  '#E25F02',
  '#119B41',
  '#75B801',
];

export const ORDER_STEP_COMPLETED = '#75B801';
export const ORDER_STEP_WARNING = '#FF7F00';
export const ORDER_BADGE_OPERATION = '#75B801';

export const SIDEBAR_NAVIGATION_BUTTON_ROOT = '#6C6776';

export const BORDER_PANEL = '#E4E1E9';

export const BUTTON_VERTICAL_STEPPER = '#8D8A96';

export const RADIO_BUTTON_BORDER = '#C0BDCD';
export const RADIO_BUTTON_BACKGROUND = '#E5E9FF';

export const DRAWER_SECONDARY = {
  backgroundColor: '#F3F2F5',
  textColor: '#FFF',
  headerBackgroundColor: '#FFF',
  headerTextColor: '#44414C',
  altBackgroundColor: '#FFF',
};

export const ALT_BORDER_COLOR = '#CDCAD8';

export const ACTION_BAR = {
  backgroundColor: '#1C1732',
  textColor: '#FFF',
  iconColor: '#FFF',
};

export const INVOICE_RESUME = {
  backgroundColor: '#1C1732',
  altBackgroundColor: '#FFF',
  textColor: '#FFF',
  emptyBackgroundColor: '#F1EFF5',
  emptyTextColor: '#44414C',
  toggleOpen: '#1A49E7',
  toggleClose: '#8D8A96',
};

export const BACKGROUND_CUSTOM_BUTTON_SELECTED = '#D6DDF7';

export const SUCCESS_MAIN = '#119B41';
export const ERROR_MAIN = '#D92F24';
