import { dateToDDMMYYYY, percentageFormat } from '../../../../commons/utils';
import { STATUSES } from '../../../../../helpers/Constants';
import { PAYROLL_TYPE } from '../../../../commons/constants/payroll';

const getPayrollStatus = status =>
  STATUSES.payrollFunds.find(st => st.key === status)?.label;

export const getPayrollFields = payroll => {
  return [
    {
      label: 'ID:',
      value: payroll?.id,
    },
    {
      label: 'Tipo:',
      value: PAYROLL_TYPE[payroll?.type]?.label,
    },
    {
      label: 'N° Pagadores',
      value: payroll?.receiversNumber,
    },
    {
      label: 'Estado:',
      value: getPayrollStatus(payroll?.payrollStatus),
    },
    {
      label: 'Fecha creación:',
      value: dateToDDMMYYYY(payroll?.createdAt),
    },
    {
      label: '% Activo:',
      value: percentageFormat(payroll?.activePercentage),
    },
    {
      label: '% No Act. - Cedido:',
      value: percentageFormat(payroll?.notActiveAssignedPercentage),
    },
    {
      label: '% No Cedido:',
      value: percentageFormat(payroll?.notAssignedPercentage),
    },
  ];
};

export const getCards = (summary = {}) => {
  return [
    {
      color: 'textPrimary',
      label: 'N° Documentos',
      value: summary?.invoicesNumber,
      variant: 'numeric',
    },
    {
      color: 'textPrimary',
      label: 'Monto bruto total',
      value: summary?.amount,
      variant: 'currency',
    },
    {
      color: 'textPrimary',
      label: 'Intereses',
      value: summary?.interest,
      variant: 'currency',
    },
    {
      color: 'primary',
      label: 'Monto a giro',
      value: summary?.payrollInvoiceAmount,
      variant: 'currency',
    },
  ];
};
