import axios from 'axios';
import { COUNTRY_CODE_MX, UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchInvoicePdfService = async ({
  id,
  country,
  orderType,
  identifier,
}) => {
  try {
    const url =
      country === COUNTRY_CODE_MX
        ? `api/orderinvoice/${id}/mimetuspdf`
        : `api/orderfactura/${id}/invoice-pdf`;
    const data = await axios.get(url, {
      params: { type: orderType, identifier },
    });
    return data;
  } catch (error) {
    throw new Error(error ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchInvoicePdfService };
