import { useParams } from 'react-router-dom';
import { useDownloadAEC } from '../infrastructure/store';
import { useSelectedInvoices } from '../ui/PayrollInvoices/InvoicesTable/store';

const useHandleDownloadAEC = () => {
  const selectedInvoices = useSelectedInvoices();
  const { id: orderId } = useParams();
  const invoicesWithAEC = selectedInvoices.filter(
    invoice => invoice.cessionStatus === 'SUCCESS'
  );

  const { downloadAEC } = useDownloadAEC({
    orderId,
    invoices: invoicesWithAEC,
  });

  const handleDownloadAEC = () => downloadAEC();

  return { handleDownloadAEC };
};

export default useHandleDownloadAEC;
