import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  useOrderCredit,
  useRegisterOrderCredit,
} from '../../../infrastructure/store';

const useCreditFormDialog = () => {
  const {
    registerOrderCredit,
    orderCreditIsRegistering,
    registerOrderCreditIsSuccess,
    createdOrderCredit,
    resetCreditRegisterData,
  } = useRegisterOrderCredit();
  const history = useHistory();
  const { country } = useSelector(state => state.config);
  const { id: orderId } = useParams();

  const { orderCredit } = useOrderCredit(orderId);

  const handleSubmit = creditFormData => {
    registerOrderCredit({ orderId, creditFormData });
  };

  useEffect(() => {
    const creditId = createdOrderCredit?.id;
    if (registerOrderCreditIsSuccess && creditId) {
      history.push(`/${country.toLowerCase()}/credits/${creditId}`);
      resetCreditRegisterData();
    }
  }, [registerOrderCreditIsSuccess, createdOrderCredit]);

  return {
    orderCredit,
    orderCreditIsRegistering,
    resetCreditRegisterData,
    handleSubmit,
  };
};

export default useCreditFormDialog;
