import React from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Edit as EditIcon } from '../icons';
import { dateFormatter } from '../../helpers/DateUtils';
import CountryFormatHelper from '../elements/CountryFormatHelper';

const useStyles = makeStyles({
  table: {
    maxWidth: 450,
  },
  circularProgress: {
    marginTop: 20,
  },
});

const PartialPaymentsTable = ({
  partialPayments,
  handleUpdatePartialPayment,
  isLoading,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config);
  return (
    <>
      {partialPayments && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">N°</TableCell>
              <TableCell align="left">Monto</TableCell>
              <TableCell align="left">Fecha pago parcial</TableCell>
              <TableCell align="left">Comentario</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {partialPayments?.map((history, index) => (
              <TableRow key={history?.id}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">
                  <CountryFormatHelper
                    value={history?.amount}
                    countryId={country}
                    variant="currency"
                  />
                </TableCell>
                <TableCell align="left">
                  {dateFormatter(history?.paymentDate) || '-'}
                </TableCell>
                <TableCell align="left">{history?.comment || '-'}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => handleUpdatePartialPayment(history)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {!isLoading && partialPayments?.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  No hay pagos parciales para mostrar
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress className={classes.circularProgress} />
        </Box>
      )}
    </>
  );
};

PartialPaymentsTable.defaultProps = {
  partialPayments: [
    {
      id: 0,
      amount: 0,
      paymentDate: '',
      comment: '',
      createdAt: '',
      sourceType: '',
      sourceId: 0,
      updatedAt: '',
    },
  ],
};

const PartialPayments = PropTypes.shape({
  id: PropTypes.number,
  amount: PropTypes.number,
  paymentDate: PropTypes.string,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  sourceType: PropTypes.string,
  sourceId: PropTypes.number,
  updatedAt: PropTypes.string,
});

PartialPaymentsTable.propTypes = {
  partialPayments: PropTypes.arrayOf(PartialPayments),
  handleUpdatePartialPayment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PartialPaymentsTable;
