import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
  Box,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { updateContact } from '../../actions/contactsActions';
import Sat from '../icons/Sat';
import { GRAY_PURPLE_COLOR } from '../../helpers/Constants';
import MailInProcess1 from '../icons/MailInProcess1';
import MailInProcess2 from '../icons/MailInProcess2';
import MailLowRisk from '../icons/MailLowRisk';
import MailNotValid from '../icons/MailNotValid';

const useStyles = makeStyles({
  containerContact: { marginBottom: 30, width: 300 },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  containerIconAndName: { display: 'flex', alignItems: 'center' },
  detailName: {
    marginLeft: '5px',
  },
  containerIcons: {
    display: 'flex',
    flexDirection: 'flex-end',
    paddingRight: 8,
  },
  divider: {
    backgroundColor: '#E6E2ED',
    width: '87%',
    marginLeft: 14,
    height: 1,
    marginBottom: 8,
  },
  containerEmailPhone: { paddingLeft: 14 },
  containerEmail: {
    marginBottom: 5,
    display: 'inline-flex',
    alignItems: 'center',
  },
  datos: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontFamily: 'Muli',
    fontSize: '10px',
    color: GRAY_PURPLE_COLOR,
  },
  datos2: {
    marginLeft: '5px',
  },
  greenIcon: {
    color: '#80C100',
    padding: 3,
  },
  redIcon: {
    color: 'red',
    padding: 3,
  },
  inputStyleGeneric: {
    width: 155,
    height: 'auto',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: 10,
    marginBottom: '3px',
    marginTop: '3px',
  },
  inputEmail: {
    marginLeft: 29,
  },
  inputPhone: {
    marginLeft: 15,
  },
  action: {
    padding: 4,
  },
  boxFlex: {
    display: 'flex',
  },
  iconBox: {
    marginRight: 5,
  },
});

const CONTACTABLE_TYPE = 'payer';

const PayerContactCard = ({ payerContact, handleOpenDeleteDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [contact, setContact] = useState([]);
  const [edit, setEdit] = useState(null);
  const [errors, setErrors] = useState({});
  const {
    id,
    name,
    email,
    phone,
    probability,
    emailVerifyError,
    emailVerifyisLoading,
    sourceId = '',
    contactableId = '',
  } = payerContact;
  const payerId = contactableId ?? sourceId;
  const editBody = { id, name, email, phone };
  useEffect(() => {
    ValidatorForm.addValidationRule(
      'email validator with steroids',
      emailValue => {
        const regexEmail =
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regexEmail.test(emailValue);
      }
    );
    ValidatorForm.addValidationRule('phone number validator', value => {
      const regexNumber = /^[0-9]*$/;
      return regexNumber.test(value);
    });
  }, [payerId]);

  const handleEdit = elem => {
    const { name, email, phone, id } = elem;
    setContact({
      name,
      email,
      phone,
    });
    setEdit(id);
  };

  const validate = contact => {
    const err = {};
    const { email, phone } = contact;
    const regexEmail =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const regexPhone = /^\+?\d+?[0-9]*$/;
    err.email = !regexEmail.test(email);
    err.phone = !regexPhone.test(phone) || phone.length > 15;
    return err;
  };

  const handleInputChange = e => {
    e.preventDefault();
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...contact,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleSubmit = (CONTACTABLE_TYPE, payerId, contactId, contact) => {
    dispatch(updateContact(CONTACTABLE_TYPE, payerId, contactId, contact));
    setEdit(null);
  };

  const getEmailVerificationIcon = () => {
    let emailIcon = (
      <Tooltip title="Loading" placement="left">
        <Box className={classes.iconBox}>
          <MailInProcess1 color="primary" fontSize="small" />
        </Box>
      </Tooltip>
    );

    if (emailVerifyisLoading) {
      emailIcon = (
        <Tooltip title="Email de bajo riesgo." placement="left">
          <Box className={classes.iconBox}>
            <MailInProcess1 color="primary" fontSize="small" />
          </Box>
        </Tooltip>
      );
    }

    if (emailVerifyError) {
      emailIcon = (
        <Tooltip
          title={
            <>
              Email sin validar. No se
              <br /> pudo comprobar la
              <br /> autenticidad del dominio.
            </>
          }
          placement="left"
        >
          <Box className={classes.iconBox}>
            <MailNotValid color="primary" fontSize="small" />
          </Box>
        </Tooltip>
      );
    }

    if (probability >= 0.5) {
      emailIcon = (
        <Tooltip
          title={
            <>
              Email riesgoso. Se
              <br /> recomienda proceder
              <br /> con precaución
            </>
          }
          placement="left"
        >
          <Box color="warning.main" className={classes.iconBox}>
            <MailInProcess2 fontSize="small" />
          </Box>
        </Tooltip>
      );
    }

    if (probability < 0.5 && probability !== null) {
      emailIcon = (
        <Tooltip title="Email de bajo riesgo." placement="left">
          <Box className={classes.iconBox}>
            <MailLowRisk color="primary" fontSize="small" />
          </Box>
        </Tooltip>
      );
    }

    return emailIcon;
  };

  return (
    <ValidatorForm className={classes.containerContact}>
      <Grid container className={classes.headerContainer}>
        <Grid item className={classes.boxFlex}>
          <Grid className={classes.containerIconAndName}>
            <Sat fontSize="small" color="primary" />
            <Typography variant="body2" color="primary" component="div">
              <Box fontWeight="fontWeightBold">Nombre:</Box>
            </Typography>
          </Grid>

          {edit !== id ? (
            <Typography
              color="primary"
              variant="body2"
              className={classes.detailName}
            >
              {name}
            </Typography>
          ) : (
            <>
              <TextValidator
                variant="outlined"
                name="name"
                size="small"
                type="string"
                value={contact.name}
                className={classes.inputStyleGeneric}
                onChange={handleInputChange}
                validators={[
                  'required',
                  'maxStringLength:50',
                  'minStringLength:1',
                ]}
                errorMessages={[
                  'Campo requerido',
                  'Máximo 50 caracteres',
                  'Campo requerido',
                ]}
              />
            </>
          )}
        </Grid>

        <Grid item className={classes.containerIcons}>
          {edit !== id ? (
            <>
              <IconButton
                className={classes.action}
                onClick={() => handleEdit(editBody)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                className={classes.action}
                onClick={() => handleOpenDeleteDialog(id)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                className={classes.greenIcon}
                onClick={() =>
                  handleSubmit(CONTACTABLE_TYPE, payerId, id, contact)
                }
                disabled={
                  !contact.email ||
                  !contact.name ||
                  !contact.phone ||
                  errors.email ||
                  errors.phone
                }
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                className={classes.redIcon}
                onClick={() => setEdit(null)}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>

      <Grid className={classes.divider} />

      <Grid container className={classes.containerEmailPhone}>
        <Grid container className={classes.containerEmail}>
          {getEmailVerificationIcon()}
          <Typography className={classes.datos} display="inline">
            <strong>Email:</strong>
          </Typography>
          {edit !== id ? (
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.datos2}
            >
              {email}
            </Typography>
          ) : (
            <>
              <TextValidator
                variant="outlined"
                size="small"
                className={[classes.inputStyleGeneric, classes.inputEmail]}
                name="email"
                autoFocus
                value={contact.email}
                onChange={handleInputChange}
                validators={[
                  'required',
                  'maxStringLength:50',
                  'minStringLength:1',
                  'email validator with steroids',
                ]}
                errorMessages={[
                  'Campo requerido',
                  'Máximo 50 caracteres',
                  'Campo requerido',
                  'Mail inválido',
                ]}
              />
            </>
          )}
        </Grid>

        <Grid container>
          <Typography variant="caption" color="textSecondary" display="inline">
            <Box fontWeight="fontWeightBold">Teléfono:</Box>
          </Typography>
          {edit !== id ? (
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.datos2}
            >
              {phone}
            </Typography>
          ) : (
            <TextValidator
              variant="outlined"
              size="small"
              className={[classes.inputStyleGeneric, classes.inputPhone]}
              name="phone"
              autoFocus
              value={contact.phone}
              onChange={handleInputChange}
              validators={[
                'required',
                'maxStringLength:15',
                'minStringLength:1',
              ]}
              errorMessages={[
                'Campo requerido',
                'Máximo 15 caracteres',
                'Campo requerido',
              ]}
            />
          )}
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

PayerContactCard.defautProps = {
  sourceId: '',
  contactableId: '',
};

PayerContactCard.propTypes = {
  payerContact: PropTypes.shape({
    sourceId: PropTypes.number,
    contactableId: PropTypes.number,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.number.isRequired,
    probability: PropTypes.number,
    emailVerifyError: PropTypes.number,
    emailVerifyisLoading: PropTypes.number,
  }).isRequired,
  handleOpenDeleteDialog: PropTypes.func.isRequired,
};

export default PayerContactCard;
