// ORDERS
export const ORDERS_NEW_DF_PERFORM = 'orders:newFD';
export const ORDERS_NEW_EP_PERFORM = 'orders:newPP';
export const ORDERS_NEW_SIMULATION_PERFORM = 'orders:newSimulation';
export const ORDERS_DISCOUNTS_PERFORM = 'orders:discounts';
export const ORDERS_REPACT_PERFORM = 'orders:repact';
export const ORDERS_CESSION_PERFORM = 'orders:cession';
export const ORDERS_RECESSION_PERFORM = 'orders:recession';
export const ORDERS_FUND_RATES_PERFORM = 'orders:fundRates';
export const ORDERS_ASSIGN_FUND_PERFORM = 'orders:assignFund';
export const ORDERS_TRANSFER_FUND_DATE_PERFORM = 'orders:transferFundDate';
export const ORDERS_ADVANCED_FUND_PERFORM = 'orders:advancedFund';
export const ORDERS_EDIT_STATUS_PERFORM = 'orders:edit:status';
export const ORDERS_UNLINK_FUND_PERFORM = 'orders:unlink:fund';
export const ORDERS_BULK_AEC_PERFORM = 'orders:bulkAec';
export const ORDERS_LOCK_PERFORM = 'orders:lock';
export const ORDERS_SKIP_DEBT_WALLET_TO_REVIEWED =
  'orders:skipDebtWalletToReviewed';
export const ORDERS_SKIP_DEBT_WALLET_TO_APPROVED =
  'orders:skipDebtWalletToApproved';

// INVOICES
export const INVOICES_EDIT_PERFORM = 'invoices:edit';
export const INVOICES_PAYMENT_DATE_PERFORM = 'invoices:paymentDate';
export const INVOICES_CONFIRMED_DATE_PERFORM = 'invoices:confirmedDate';
export const INVOICES_EXPIRED_DATE_PERFORM = 'invoices:expiredDate';
export const INVOICES_FUND_DATE_PERFORM = 'invoices:fundDate';
export const INVOICES_BULK_PERFORM = 'invoices:bulk';
export const INVOICES_BULK_FUND_PERFORM = 'invoices:bulk:fund';
export const INVOICES_DISCOUNTS_PERFORM = 'invoices:discounts';
export const INVOICES_BASE_RATE_PERFORM = 'invoices:baseRate';
export const INVOICES_LINKED_PDF_PERFORM = 'invoices:linkedPDF';
export const INVOICES_DOWNLOAD_PDF_PERFORM = 'invoices:downloadPDF';
export const INVOICES_VERIFICATION_PERFORM = 'invoices:verification';
export const INVOICES_EDIT_RATES_PERFORM = 'invoices:edit:rates';
export const INVOICES_IS_HOLD_UPDATE_PERFORM = 'invoices:update:isHold';

// CREDITS
export const CREDITS_SECTION_PERFORM = 'credits:section';
export const CREDITS_NEW_PERFORM = 'credits:new';
export const CREDITS_EDIT_PERFORM = 'credits:edit';
export const CREDITS_NEW_FEE_PERFORM = 'credits:newFee';
export const CREDITS_EDIT_FEE_PERFORM = 'credits:editFee';

// BUSINESS
export const BUSINESS_DICOM_PERFORM = 'business:dicom';
export const BUSINESS_TAX_FOLDER_PERFORM = 'business:taxFolder';
export const BUSINESS_SUDO_PERFORM = 'business:sudo';
export const BUSINESS_UPLOAD_LEGAL_FILES_PERFORM = 'business:uploadLegalFiles';
export const BUSINESS_SUDO_TGR_PERFORM = 'business:sudoTGR';
export const BUSINESS_SUDO_PREVIRED_PERFORM = 'business:sudoPrevired';
export const BUSINESS_UPLOAD_PFX_PERFORM = 'business:uploadPFX';
export const BUSINESS_UPLOAD_NUBOX_PERFORM = 'business:uploadNUBOX';
export const BUSINESS_EDIT_FEE_PERFORM = 'business:editFee';
export const BUSINESS_DOWNLOAD_TAX_FOLDER_PERFORM =
  'business:downloadTaxFolder';
export const BUSINESS_SECTION_PERFORM = 'business:section';
export const BUSINESS_BURO_PERFORM = 'business:buro';
export const BUSINESS_ADD_BANK_ACCOUNT_PERFORM = 'business:add:bankAccount';
export const BUSINESS_EDIT_BANK_ACCOUNT_PERFORM = 'business:edit:bankAccount';
export const BUSINESS_DELETE_BANK_ACCOUNT_PERFORM =
  'business:delete:bankAccount';
export const BUSINESS_UPDATE_BANK_ACCOUNT_IDENTIFIER_PERFORM =
  'business:updateBankAccountIdentifier';
export const BUSINESS_COMPLIANCE_PERFORM = 'business:compliance';
export const BUSINESS_EDIT_RATES_PERFORM = 'business:edit:rates';
export const BUSINESS_DETAIL_PERFORM = 'business:detail';
export const BUSINESS_CHANGE_NAME_PERFORM = 'business:edit:business';
export const BUSINESS_UPDATE_SWITCH_AUTO_RATE = 'business:updateSwitchAutoRate';

// COLLECTION
export const COLLECTION_ACTIONS_PERFORM = 'collection:actions';
export const COLLECTION_SEND_EMAIL_PERFORM = 'collection:send:email';
export const COLLECTION_BULK_UPDATE_PERFORM = 'collection:update:bulk';
export const COLLECTION_COMMENT_PERFORM = 'collection:comment';
export const COLLECTION_DISCOUNTS_PERFORM = 'collection:discounts';
export const COLLECTION_SECTION_PERFORM = 'collection:section';

// FUNDS
export const FUNDS_SECTION_PERFORM = 'funds:section';
export const FUNDS_DOWNLOAD_PAYROLL_PERFORM = 'funds:downloadPayroll';
export const FUNDS_ADD_PERFORM = 'funds:add';
export const FUNDS_EDIT_PERFORM = 'funds:edit';
export const FUNDS_STATUS_PERFORM = 'funds:status';
export const FUNDS_EDIT_RATE_PERFORM = 'funds:editRate';
export const FUNDS_ADD_BANK_ACCOUNT_PERFORM = 'funds:bankAccount';

// BLOCKLIST
export const BLOCK_LIST_SECTION_PERFORM = 'blocklist:section';
export const BLOCK_LIST_STATUS_PERFORM = 'blocklist:statusPayer';
export const BLOCK_LIST_ADD_PERFORM = 'blocklist:add';

// PAYERS
export const PAYERS_SECTION_PERFORM = 'payers:section';
export const PAYERS_UPLOAD_CSV_PERFORM = 'payers:upload';
export const PAYERS_ADD_INFO_PERFORM = 'payers:addInfo';
export const PAYERS_ADD_NEW_PERFORM = 'payers:addNew';
export const PAYERS_DOWNLOAD_LIST_PERFORM = 'payers:download';

// AGREEMENTS
export const AGREEMENTS_SECTION_PERFORM = 'agreements:section';
export const AGREEMENTS_ADD_PERFORM = 'agreements:add';
export const AGREEMENTS_EDIT_PERFORM = 'agreements:edit';
export const AGREEMENTS_STATUS_PERFORM = 'agreements:status';
export const AGREEMENTS_EDIT_RATES_PERFORM = 'agreements:edit:rates';

// USERS
export const USERS_SECTION_PERFORM = 'users:section';
export const USERS_STATUS_PERFORM = 'users:status';
export const USERS_EDIT_PERFORM = 'users:edit';
export const USERS_ADD_PERFORM = 'users:add';
export const USERS_ADD_ROLE_PERFORM = 'users:manage:roles';

// CONTACTS OF PAYERS
export const CONTACTS_UPLOAD_CSV_PERFORM = 'payers:uploadContacts';
export const CONTACTS_DOWNLOAD_CSV_PERFORM = 'payers:downloadContacts';

// WALLET
export const WALLETS_SECTION_PERFORM = 'wallets:section';

// STAKEHOLDERS
export const STAKEHOLDERS_DELETE_PERFORM = 'stakeholders:delete';

// ORDER RATES
export const ORDER_RATES_PERCENTAGE_CHANGE = [
  'orderRates:percentage:change_0',
  'orderRates:percentage:change_0.2',
  'orderRates:percentage:change_0.4',
  'orderRates:percentage:change_0.6',
  'orderRates:percentage:change_0.8',
  'orderRates:percentage:change_1',
  'orderRates:percentage:change_1.2',
  'orderRates:percentage:change_1.4',
  'orderRates:percentage:change_1.6',
  'orderRates:percentage:change_1.8',
  'orderRates:percentage:change_2',
  'orderRates:percentage:change_2.2',
  'orderRates:percentage:change_2.4',
  'orderRates:percentage:change_2.6',
  'orderRates:percentage:change_2.8',
];

// OPERATION COST
export const OPERATION_COST_PERCENTAGE_CHANGE = [
  'operationCost:percentage:change_0',
  'operationCost:percentage:change_10',
  'operationCost:percentage:change_20',
  'operationCost:percentage:change_30',
  'operationCost:percentage:change_40',
  'operationCost:percentage:change_50',
  'operationCost:percentage:change_60',
  'operationCost:percentage:change_70',
  'operationCost:percentage:change_80',
  'operationCost:percentage:change_90',
  'operationCost:percentage:change_100',
];

// REPORTS
export const REPORTS_SECTION_PERFORM = 'reports:section';
export const REPORTS_ADD_PERFORM = 'reports:add';
export const REPORTS_EDIT_PERFORM = 'reports:edit';
