
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import { useGraphQlQuery } from '../../hooks/useGraphQlQuery';
import { query, Query } from './query';
import { statesMetadata, StateType } from './statesMetadata';
import { isRoleAdmin, hasRoleKam, hasRoleRisk, hasRoleLegal, hasRoleOperation } from '../../utils';
import { AuthSelector } from '../../schemas';

type Props = {
  currentStateId: string;
  workflowId: string;
};

export const useGetWorkflowStates = ({ currentStateId, workflowId }: Props) => {
  const { user } = useSelector((state: RootState) => state.auth as AuthSelector);
  const isAdmin = isRoleAdmin(user?.roles || []);
  const isKam = hasRoleKam(user?.roles);
  const isRisk = hasRoleRisk(user?.roles);
  const isLegal = hasRoleLegal(user?.roles);
  const isOperation = hasRoleOperation(user?.roles);

  const rolesByWorkflowType = {
    [StateType.legal]: isLegal,
    [StateType.operation]: isOperation,
    [StateType.risk]: isRisk,
  };

  const variables = {
    dto: {
      currentStateId,
      workflowId,
    },
  };

  const { data, loading } = useGraphQlQuery<Query>('getWorkflowStateList', { query, variables });

  const workflowStates = (data?.workflowStateList || []).map(item => {
    const metadata = statesMetadata.find(data => data.id === item.state.id);

    const stateEnabled = metadata ? rolesByWorkflowType[metadata.type] : false;

    return {
      enabled: isAdmin || isKam || stateEnabled,
      id: item.state.id,
      name: item.state.name,
      commentsRequired: isAdmin || isKam || metadata?.commentsRequired,
      position: metadata?.position,
    };
  }).sort((firstItem, secondItem) => {
    if (firstItem.position! > secondItem.position!) {
      return 1;
    }

    if (firstItem.position! < secondItem.position!) {
      return -1;
    }

    return 0;
  });

  return {
    loading,
    workflowStates,
  };
};
