import { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DIRECT_FINANCING } from '../../../helpers/Constants';
import {
  useFilters,
  useGetFilters,
  useItemsPerPage,
  usePage,
  useSetItemsPerPage,
  useSetPage,
  useSetOperationFilter,
  useClearSelectedOperations,
  useSetClearFilters,
} from '../infrastructure/store/InboxStore';
import useOperations from '../infrastructure/store/useOperations';
import useARFilters from './useFilters';
import { useTranslation } from 'react-i18next';
import { t13s } from '../../../translationKeys';
import useGetCountry from '../../../hooks/useGetCountry';
import { resetOperationDetailData } from '../../../actions/orderActions';
import { useDispatch } from 'react-redux';

const useInbox = () => {
  const country = useGetCountry();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { handleChangeFieldFilter } = useARFilters();
  const { pathname, search } = location;
  const pathnameSplited = pathname.split('/') || [];
  const [operationType] = useState(
    pathnameSplited?.length
      ? pathnameSplited[pathnameSplited?.length - 1].toUpperCase()
      : DIRECT_FINANCING
  );
  const setOperationFilter = useSetOperationFilter();

  const handleChangeOperationType = useCallback(
    async (newOperationType: string) => {
      await setOperationFilter(newOperationType.toUpperCase());
    },
    [setOperationFilter]
  );

  useEffect(() => {
    handleChangeOperationType(operationType);
  }, [operationType, handleChangeOperationType]);

  const page = usePage();
  const itemsPerPage = useItemsPerPage();
  const setPage = useSetPage();
  const setItemsPerPage = useSetItemsPerPage();

  const clearSelectedOperations = useClearSelectedOperations();
  const setClearFilters = useSetClearFilters();

  const filters = useFilters();
  const getFilters = useGetFilters();

  const {
    operations,
    pagination: operationsPagination,
    isLoading: fetchOperationIsLoading,
  } = useOperations({ filters, getFilters, page, itemsPerPage });

  const handleChangePage = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeItemsPerPage = useCallback(
    (
      event: ChangeEvent<{
        name?: string;
        value: any;
      }>
    ) => {
      setItemsPerPage(event.target.value);
    },
    [setItemsPerPage]
  );

  const handleClearFilters = useCallback(() => {
    clearSelectedOperations();
    setClearFilters();
  }, [clearSelectedOperations, setClearFilters]);

  const handleTabChange = useCallback(
    (value: string) => {
      const countryKey = country.toLowerCase();
      const valueKey = value.toLowerCase();
      history.push(`/${countryKey}/${valueKey}`);
      handleChangeOperationType(valueKey);
      handleClearFilters();
    },
    [country, history, handleChangeOperationType, handleClearFilters]
  );

  const handleGetQueryParams = useCallback(
    (searchData: string, fieldKey: string) =>
      new URLSearchParams(searchData).get(fieldKey),
    []
  );

  useEffect(() => {
    if (search) {
      const searchField = handleGetQueryParams(search, 'identifier') || '';
      handleChangeFieldFilter({
        select: {
          title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
          field: 'identifier',
        },
        input: searchField,
      });
    }
  }, [search, handleChangeFieldFilter, t]);

  useEffect(() => {
    dispatch(resetOperationDetailData());
  }, []);

  return {
    page,
    itemsPerPage,
    country,
    operationType,
    operations,
    operationsPagination,
    fetchOperationIsLoading,
    handleTabChange,
    handleChangeItemsPerPage,
    handleChangePage,
    handleClearFilters,
  };
};

export default useInbox;
