import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { XtreeStatuses } from '../../helpers/Constants';
import { Xtree as XtreeIcon, XtreeDefault as XtreeDefaultIcon } from '../icons';

const useStyles = makeStyles(theme => ({
  xtreeIcon: {
    color: theme.palette.grey[700],
    width: 16,
  },
  xtreeDefault: {
    color: theme.palette.grey[400],
    width: 16,
    overflow: 'visible',
    marginRight: 10,
  },
  xtreeSuccess: {
    color: theme.palette.primary.main,
  },
}));

const XtreeBadge = ({ status }) => {
  const classes = useStyles();

  const getXtreeIcon = status => {
    let icon;

    if (
      [XtreeStatuses.PENDING.key, XtreeStatuses.SUCCESS.key].includes(status)
    ) {
      icon = (
        <XtreeIcon
          className={clsx(classes.xtreeIcon, {
            [classes.xtreeSuccess]: status === XtreeStatuses.SUCCESS.key,
          })}
        />
      );
    } else if (status === XtreeStatuses.DEFAULT.key || !status) {
      icon = <XtreeDefaultIcon className={classes.xtreeDefault} />;
    }

    return icon;
  };

  const title = (
    <Typography variant="body1">
      {XtreeStatuses[status ?? XtreeStatuses.DEFAULT.key].label}
    </Typography>
  );

  return (
    <Tooltip placement="bottom" title={title}>
      <div>{getXtreeIcon(status)}</div>
    </Tooltip>
  );
};

XtreeBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default XtreeBadge;
