import {
  makeStyles,
  Drawer as DrawerMui,
  styled,
  Box,
  Typography,
  Divider,
} from '@material-ui/core';

export const useStyles = makeStyles({
  tab: {
    marginBottom: 30,
  },
});

export const Drawer = styled(DrawerMui)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});
export const ContainerRoot = styled(Box)({
  width: 450,
  padding: 20,
});
export const Title = styled(Typography)({
  fontSize: 20,
  fontWeight: 'bold',
  marginLeft: 10,
});
export const ContainerRowData = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const RowDataText = styled(Typography)({
  fontSize: 14,
  color: '#8D8A96',
  fontWeight: 'bold',
});
export const RowDataTitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
});
export const TopDivider = styled(Divider)({
  marginBottom: 30,
});
export const BottomDivider = styled(Divider)({
  marginBottom: 10,
});
