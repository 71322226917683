export function to(promise, errInfo) {
  return promise
    .then(res => [undefined, res])
    .catch(err => {
      if (errInfo) {
        Object.assign(err, errInfo);
      } else if (err?.response?.data) {
        Object.assign(err, err?.response?.data);
      }

      return [err, undefined];
    });
}
