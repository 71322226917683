import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from '../../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../../translationKeys/index';
import partialPaymentBulkUpdateService from '../service/partialPaymentBulkUpdateService';

const usePartialPaymentsBulkUpdate = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    mutate: uploadFile,
    reset: resetDataAndErrors,
  } = useMutation(
    csvFile => {
      return partialPaymentBulkUpdateService(csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.BULK_PARTIAL_PAYMENT_CREATED, {
              variant: 'success',
            })
          );
        }
      },
    }
  );

  const errorLines = data?.errorLines;
  const hasErrors = !!errorLines?.length || isError;
  const serverError = error || null;

  return {
    isLoading,
    hasErrors,
    errorLines,
    serverError,
    isSuccess,
    uploadFile,
    resetDataAndErrors,
  };
};

export default usePartialPaymentsBulkUpdate;
