import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const User = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20 23H18V21C18 20.2044 17.6839 19.4413 17.1213 18.8787C16.5587 18.3161 15.7956 18 15 18H9C8.20435 18 7.44129 18.3161 6.87868 18.8787C6.31607 19.4413 6 20.2044 6 21V23H4V21C4 19.6739 4.52678 18.4021 5.46447 17.4645C6.40215 16.5268 7.67392 16 9 16H15C16.3261 16 17.5979 16.5268 18.5355 17.4645C19.4732 18.4021 20 19.6739 20 21V23ZM12 14C11.2121 14 10.4319 13.8448 9.7039 13.5433C8.97595 13.2417 8.31451 12.7998 7.75736 12.2426C7.20021 11.6855 6.75825 11.0241 6.45672 10.2961C6.15519 9.56815 6 8.78793 6 8C6 7.21207 6.15519 6.43185 6.45672 5.7039C6.75825 4.97595 7.20021 4.31451 7.75736 3.75736C8.31451 3.20021 8.97595 2.75825 9.7039 2.45672C10.4319 2.15519 11.2121 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8C18 9.5913 17.3679 11.1174 16.2426 12.2426C15.1174 13.3679 13.5913 14 12 14ZM12 12C13.0609 12 14.0783 11.5786 14.8284 10.8284C15.5786 10.0783 16 9.06087 16 8C16 6.93913 15.5786 5.92172 14.8284 5.17157C14.0783 4.42143 13.0609 4 12 4C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8C8 9.06087 8.42143 10.0783 9.17157 10.8284C9.92172 11.5786 10.9391 12 12 12Z" />
    </SvgIcon>
  );
};

export default User;
