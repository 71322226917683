import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EyeClose = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M9.166 18.172L7.235 17.654L8.022 14.715C6.84397 14.2805 5.74912 13.6474 4.785 12.843L2.632 14.997L1.217 13.582L3.371 11.429C2.15508 9.97276 1.33809 8.22576 1 6.359L2.968 6C3.727 10.202 7.403 13.39 11.824 13.39C16.244 13.39 19.921 10.202 20.68 6L22.648 6.358C22.3104 8.22502 21.4937 9.97237 20.278 11.429L22.431 13.582L21.016 14.997L18.863 12.843C17.8989 13.6474 16.804 14.2805 15.626 14.715L16.413 17.655L14.482 18.172L13.694 15.232C12.4564 15.4441 11.1916 15.4441 9.954 15.232L9.166 18.172Z" />
    </SvgIcon>
  );
};

export default EyeClose;
