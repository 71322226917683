import React from 'react';
import { Grid, styled } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

import { Link, CreateDocumentDialog } from '../../../../commons/components';
import OrderDocumentsTable from './OrderDocumentsTable';
import { useDocumentsTab } from '../../../hooks';

const ButtonsGrid = styled(Grid)({
  marginBottom: 37,
});

const MarginRightGrid = styled(Grid)({
  marginRight: 38,
});

const DocumentsTab = () => {
  const {
    documents,
    documentsIsLoading,
    orderId,
    showNewDocumentDialog,
    handleShowNewDocumentDialog,
    handleCloseNewDocumentDialog,
  } = useDocumentsTab();

  return (
    <Grid item container direction="column">
      {showNewDocumentDialog && (
        <CreateDocumentDialog
          open={showNewDocumentDialog}
          handleCloseDialog={handleCloseNewDocumentDialog}
        />
      )}

      <ButtonsGrid item container>
        <MarginRightGrid item>
          <Link
            handler={handleShowNewDocumentDialog}
            icon={<AddCircleOutlineOutlined />}
          >
            Nuevo documento
          </Link>
        </MarginRightGrid>
      </ButtonsGrid>
      <Grid item container>
        <OrderDocumentsTable
          documents={documents}
          documentTableIsLoading={documentsIsLoading}
          orderId={orderId}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentsTab;
