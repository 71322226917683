import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchInvoiceAttachmentsInfoService = async orderId => {
  try {
    return await axios.get(`/api/orders/${orderId}/hasinvoiceattachment`);
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchInvoiceAttachmentsInfoService };
