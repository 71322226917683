import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE } from '../../../../actions/types';
import { t13s } from '../../../../translationKeys';
import { updateOrderInvoiceIsHoldService } from '../services';

export const useUpdateOrderInvoiceIsHold = () => {
  const dispatch = useDispatch();
  const {
    isError: isErrorUpdateOrderInvoiceIsHold,
    isLoading: isLoadingUpdateOrderInvoiceIsHold,
    mutate: updateOrderInvoiceIsHold,
  } = useMutation(
    ({ orderInvoiceId }) => {
      return updateOrderInvoiceIsHoldService({ orderInvoiceId });
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_INVOICE_IS_HOLD_UPDATED, {
            variant: 'success',
          })
        );
        onSuccessCallback();
      },
      onError: res => {
        dispatch({
          type: UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE,
          payload: { errorCode: res },
        });
      },
    }
  );

  return {
    isErrorUpdateOrderInvoiceIsHold,
    isLoadingUpdateOrderInvoiceIsHold,
    updateOrderInvoiceIsHold,
  };
};

export default useUpdateOrderInvoiceIsHold;
