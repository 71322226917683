import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default Component => {
  const NotAuthentication = () => {
    const history = useHistory();
    const auth = useSelector(state => state.auth);

    const validate = () => {
      if (auth.isAuthenticated) {
        let redirectUrl = '/orders';
        if (history.location.search) {
          const params = new URLSearchParams(history.location.search);
          if (params.has('redirect_to')) {
            redirectUrl = params.get('redirect_to');
            redirectUrl += history.location.hash;
          }
        }
        history.replace(redirectUrl);
      }
    };

    useEffect(() => {
      validate();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component />;
  };

  return NotAuthentication;
};
