import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

const NewPaymentButton = ({ handler }) => {
  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<AddCircleOutline />}
      onClick={handler}
    >
      Nueva operación
    </Button>
  );
};
NewPaymentButton.propTypes = {
  handler: PropTypes.func.isRequired,
};
export default NewPaymentButton;
