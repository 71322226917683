import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TogglePanel = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M8.33333 2.49967H4.16667C3.25 2.49967 2.5 3.24967 2.5 4.16634V15.833C2.5 16.7497 3.25 17.4997 4.16667 17.4997H8.33333V19.1663H10V0.833008H8.33333V2.49967ZM8.33333 14.9997H4.16667L8.33333 9.99967V14.9997ZM15.8333 2.49967H11.6667V4.16634H15.8333V14.9997L11.6667 9.99967V17.4997H15.8333C16.75 17.4997 17.5 16.7497 17.5 15.833V4.16634C17.5 3.24967 16.75 2.49967 15.8333 2.49967Z" />
    </SvgIcon>
  );
};

export default TogglePanel;
