import {
  FETCH_ECONOMIC_GROUP_START,
  FETCH_ECONOMIC_GROUP_SUCCESS,
  FETCH_ECONOMIC_GROUP_FAILURE,
} from '../actions/types';

const initialState = {
  fetchingEconomicGroups: false,
  economicGroups: [],
  economicGroupsError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ECONOMIC_GROUP_START:
      return {
        ...state,
        fetchingEconomicGroups: true,
      };

    case FETCH_ECONOMIC_GROUP_SUCCESS:
      return {
        ...state,
        economicGroups: payload.economicGroups || payload,
        fetchingEconomicGroups: false,
      };

    case FETCH_ECONOMIC_GROUP_FAILURE:
      return {
        ...state,
        economicGroupsError: payload.errorCode,
        fetchingEconomicGroups: false,
      };

    default:
      return state;
  }
};
