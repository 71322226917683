import { useQuery } from 'react-query';
import { fetchBillDocuments } from '../services';

const useBillDocuments = (orderId, businessId) => {
  const { isLoading, data, refetch } = useQuery(
    'getBillDocuments',
    () => fetchBillDocuments(orderId, businessId),
    { refetchOnWindowFocus: false }
  );

  const { data: billDocuments } = data || { data: [] };

  return {
    billDocuments,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useBillDocuments };
