import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { useGraphQlQuery } from '../../../../hooks/useGraphQlQuery';
import { query, Query } from './query';
import { buttonStateColors, grayButton } from '../../useGetWorkflowStates/buttonStateColors';

type Props = {
  businessIdentifier: string;
  workFlowId: string;
  enabled: boolean;
};

export const useGetTransitionActions = ({ businessIdentifier, workFlowId, enabled }: Props) => {
  const variables = {
    dto: {
      entityRefId: businessIdentifier,
      workflowId: workFlowId,
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    loading,
    data,
    error,
    refetch,
    isRefetching,
  } = useGraphQlQuery<Query>('transitionActions', {
    query,
    variables,
    enabled: Boolean(businessIdentifier) && enabled && workFlowId !== '',
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        'Surgió un error al obtener la información',
        { variant: 'error' },
      );
    }
  }, [error]);

  useEffect(() => {
    if (workFlowId) {
      /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
      refetch();
    }
  }, [workFlowId]);

  const transitionActionResponse = data?.transitionActions || [];
  const transitionActions = (error || loading) ? [] : transitionActionResponse.map(transitions => {
    if (transitions) {
      return ({
        createdAt: format(new Date(transitions.createdAt), 'dd-MM-yyyy'),
        comment: transitions.comment,
        id: transitions.id,
        target: transitions.target.name,
        workflow: transitions.workflow,
        buttonStyle: buttonStateColors.find(item => item.id === transitions.target.id) || grayButton,
        modifiedByUserRef: transitions.modifiedByUserRef,
      });
    }

    return ({
      createdAt: null,
      comment: null,
      id: null,
      target: null,
      workflow: {
        id: null,
        name: null,
      },
      buttonStyle: grayButton,
    });
  });

  return {
    loading,
    error,
    refetch,
    isRefetching,
    transitionActions,
  };
};
