import { Grid, IconButton, styled } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Text } from '../../../../commons/formatters';

// PayrollCard
const Wrapper = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.payments.grey4}`,
  borderBottom: `1px solid ${theme.palette.payments.grey4}`,
  '&:last-child': {
    borderBottom: '0',
  },
  padding: '32px',
  flexWrap: 'nowrap',
}));

const SelectorWrapper = styled(Grid)({
  marginRight: 24,
});

const ContentWrapper = styled(Grid)({
  width: '100%',
});

const ExpanderWrapper = styled(Grid)({
  margin: '0 32px 0 20px',
});

const StatsWrapper = styled(Grid)(({ theme }) => ({
  marginLeft: 'auto',
  paddingLeft: 32,
  borderLeft: `2px solid ${theme.palette.payments.grey4}`,
  width: 230,
  minWidth: 230,
  boxSizing: 'content-box',
  alignSelf: 'center',
}));

// CardHeader

const HeaderWrapper = styled(Grid)({
  marginBottom: 24,
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey1,
  fontSize: 18,
}));

const Identifier = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey2,
}));

const Separator = styled(Text)({
  fontSize: 16,
  margin: '0 8px',
});

const HistoryButton = styled(IconButton)({
  width: 16,
  height: 16,
});

const TitleWrapper = styled(Grid)({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
});

const HeaderTop = styled(Grid)({
  gap: 30,
  marginBottom: 12,
});

const HeaderBottom = styled(Grid)({
  gap: 24,
});

const HeaderItem = styled(Grid)({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 6,
});

const HeaderLabel = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey2,
}));

const OrderIdLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '700',
  fontSize: 16,
}));

//  Card Stats

const ViewDetailsButton = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  cursor: 'pointer',
  '& p': {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '5px 15px',
    minWidth: 64,
    boxShadow: 'none !important',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    borderRadius: 50,
    textAlign: 'center',
    marginTop: 10,
  },
}));

export {
  Wrapper,
  SelectorWrapper,
  ContentWrapper,
  ExpanderWrapper,
  StatsWrapper,
  HeaderWrapper,
  Title,
  Identifier,
  Separator,
  HistoryButton,
  TitleWrapper,
  HeaderTop,
  HeaderBottom,
  HeaderItem,
  HeaderLabel,
  ViewDetailsButton,
  OrderIdLink,
};
