import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Loader from '../../../components/elements/Loader';
import useOrderDetails from './useOrderDetails';
import {
  PayrollHeader,
  PayrollSummary,
  PayrollInvoices,
  PayrollTabs,
  PayrollDatesAndAmounts,
} from '../../details/ui';

import {
  RootGrid,
  TopGrid,
  MiddleGrid,
  BottomGrid,
  DetailsGrid,
} from './styles';
import { useUnlockOrder } from '../../inbox/infrastructure/store';
import LockOperationDialog from '../../details/ui/PayrollHeader/LockOperationDialog';
import LockedOrderAlert from '../../details/ui/LockedOrderAlert';

const PaymentsDetails = () => {
  const { order, orderSummary, isLoading } = useOrderDetails();
  const { unlockOrder, isUnlockingOrder } = useUnlockOrder();

  const [isLockDialogOpen, setIsLockDialogOpen] = useState(false);
  const { isLocked = false, id } = order;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {isLockDialogOpen && (
        <LockOperationDialog
          open={isLockDialogOpen}
          handleClose={() => setIsLockDialogOpen(false)}
          handleAction={() => unlockOrder({ orderId: id })}
          title="Desbloquear operación"
          message="Las acciones de esta operación volverán a quedar habilitadas en Backoffice."
          actionText="Desbloquear"
          isLoading={isUnlockingOrder}
        />
      )}
      <RootGrid container>
        <Grid item xs={12}>
          <PayrollHeader order={order} />
        </Grid>
        {isLocked && (
          <LockedOrderAlert
            handleOpenIsLockDialog={() => setIsLockDialogOpen(true)}
          />
        )}
        <TopGrid item xs={12}>
          <PayrollDatesAndAmounts orderSummary={orderSummary} />
        </TopGrid>
        <MiddleGrid item container xs={12}>
          <Grid item xs>
            <PayrollTabs />
          </Grid>
          <DetailsGrid item>
            <PayrollSummary orderSummary={orderSummary} order={order} />
          </DetailsGrid>
        </MiddleGrid>
        <BottomGrid item xs={12}>
          <PayrollInvoices />
        </BottomGrid>
      </RootGrid>
    </>
  );
};

export default PaymentsDetails;
