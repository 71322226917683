import { useState } from 'react';

const useHandleHistoryLogDialog = () => {
  const [contextId, setContextId] = useState(null);
  const [contextType, setContextType] = useState('');
  const [showHistoryLogDialog, setShowHistoryLogDialog] = useState(false);

  const handleOpenHistoryLogDialog = () => {
    setShowHistoryLogDialog(true);
  };

  const handleCloseHistoryLogDialog = () => {
    setShowHistoryLogDialog(false);
  };

  const handleHistoryLogDialog = ({ contextId, contextType = 'ORDER' }) => {
    setContextId(contextId);
    setContextType(contextType);
    handleOpenHistoryLogDialog();
  };

  return {
    contextId,
    contextType,
    showHistoryLogDialog,
    handleCloseHistoryLogDialog,
    handleHistoryLogDialog,
  };
};
export default useHandleHistoryLogDialog;
