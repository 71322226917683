import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MuiChip, Box } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOffRounded';
import { CHIP_VARIANTS } from '../../../theme/otherColors';

const Chip = React.forwardRef((props, ref) => {
  const { variant, noBold, label, ...otherProps } = props;
  const { color } = CHIP_VARIANTS[variant];

  const getBackground = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    const a = 0.2;

    return `rgba(${r},${g},${b},${a})`;
  };

  return (
    <MuiChip
      component="span"
      deleteIcon={<HighlightOffIcon style={{ color }} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      label={
        <Box
          fontWeight={noBold ? 'fontWeightRegular' : 'fontWeightBold'}
          component="span"
        >
          {label}
        </Box>
      }
      ref={ref}
      variant="default"
      style={{ background: getBackground(color), color, border: 0 }}
    />
  );
});

Chip.defaultProps = {
  variant: 'default',
  noBold: false,
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(
    Object.keys(CHIP_VARIANTS).map(variant => CHIP_VARIANTS[variant].code)
  ),
  noBold: PropTypes.bool,
};

export default Chip;
