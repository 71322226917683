import React, { FC, ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  name: string;
  style?: {
    [key: string]: ReactNode;
  };
}

const useStyles = makeStyles({
  avatar: {
    width: 24,
    height: 24,
    border: `1px solid ${palette.info.main}`,
    borderRadius: 19,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.info.main,
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const Avatar: FC<Props> = ({ name, style }) => {
  const classes = useStyles();

  return (
    <Box className={classes.avatar} style={{ ...style }}>
      {name?.charAt(0)}
    </Box>
  );
};

export default Avatar;
