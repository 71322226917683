import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fetchBusinessByIdentifier,
  fetchBusinessName,
  calculateFeesBusinessNotEnrolled,
  resetBusiness,
} from '../../../actions/businessAction';
import { CONFIRMING, NONE } from '../../../helpers/Constants';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import OperationUploadSteps from './OperationUploadSteps';
import {
  uploadInvoiceCsvConfirming,
  resetInvoicesCsvToOrder,
} from '../../../actions/invoiceAction';

const CreateOrderDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  isLoading,
  submitError,
}) => {
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.config);
  const { fetchBusinessInvoicesLoading } = useSelector(state => state.business);
  const {
    issuingBusinesses,
    invoices: invoicesCsv,
    bankAccounts,
  } = useSelector(state => state.invoice);

  useEffect(() => {
    dispatch(resetInvoicesCsvToOrder());
    dispatch(resetBusiness());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientByIdentifier = identifier => {
    dispatch(resetBusiness());
    dispatch(fetchBusinessByIdentifier(identifier, CONFIRMING));
  };

  const handleClientNotEnrolled = identifier => {
    dispatch(fetchBusinessName(identifier));
  };

  const transformInvoicesNotEnrolled = invoices => {
    return invoices.map(invoice => {
      return {
        amount: invoice.amount,
        expirationDate: invoice.expirationDate,
      };
    });
  };

  const onCalculateFees = (
    clientSelectedId,
    invoices,
    feesData,
    operationPaymentType
  ) => {
    dispatch(
      calculateFeesBusinessNotEnrolled(
        CONFIRMING,
        transformInvoicesNotEnrolled(invoices),
        feesData,
        clientSelectedId,
        operationPaymentType
      )
    );
  };

  const handleNextStep = (formFees, handleFeesCalculate) => step => {
    if (step === 2) {
      handleFeesCalculate(formFees, true);
    }
    return true;
  };

  const handleValidateNextStep = clientSelected => step => {
    if (step === 0) {
      if (clientSelected?.taxUserActive && !clientSelected?.hasConfirming) {
        return false;
      }
      return clientSelected !== '' && clientSelected?.name;
    }
    if (step === 1) {
      return invoicesCsv?.length > 0;
    }

    if (step === 2) {
      return !isLoading;
    }
    return false;
  };

  const handleSubmitOperation =
    (
      fees,
      formFees,
      clientSelected,
      currency,
      availableInvoicesCount,
      availableInvoicesAmount,
      operationPaymentType
    ) =>
    () => {
      const feesData = {
        retentionPct: fees.retentionPct,
        baseRate: fees.baseRate,
        issuedDate: fees.issuedDate,
        operationCost: formFees.operationCost,
      };

      const autoRateType = NONE;

      handleSubmit(
        {
          clientSelected,
          rawOrderInvoices: invoicesCsv,
          feesData,
          businesses: issuingBusinesses,
        },
        fees,
        availableInvoicesCount,
        availableInvoicesAmount,
        autoRateType,
        currency,
        operationPaymentType,
        bankAccounts
      );
    };

  const confirmingTemplate = {
    CL: {
      name: 'Lorem',
      identifier: '12345678-9',
      folio: '54321',
      issuedDate: '20-05-2021',
      expirationDate: '12-10-2021',
      docType: '33',
      amount: 150000,
      email: 'email@email.com',
      accountType: 'CUENTA_CORRIENTE',
      bankAccount: '123456',
      bankCode: '001',
    },
    MX: {
      name: 'Lorem',
      identifier: 'XEPE1901011XX',
      uuid: '1231231-12312-12312asda-asdasd12-asd1',
      folio: '54321',
      issuedDate: '20-05-2021',
      expirationDate: '12-10-2021',
      amount: 150000,
      email: 'email@email.com',
      CLABE: '01887162312312',
      currency: 'MXN',
      exchange: '',
    },
  };

  const onDrop = (files, setCsvErrors, clientSelected) => {
    if (!files) {
      setCsvErrors('Debe seleccionar un archivo antes');
      return;
    }
    const file = files?.length ? files[0].file : [];
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      if (!fileData.includes(',')) {
        setCsvErrors(`Delimitador erroneo. Delimitador esperado: "," (coma).`);
        return;
      }
      dispatch(uploadInvoiceCsvConfirming(file, clientSelected));
    };
    reader.readAsBinaryString(file);
  };

  const hanleCloseDialogCallback = () => {
    handleCloseDialog();
    dispatch(resetInvoicesCsvToOrder());
    dispatch(resetBusiness());
  };

  const handleStepBack = step => {
    if (step >= 2) {
      dispatch(resetInvoicesCsvToOrder());
    }
  };

  const steps = [
    'Información general',
    'Selección de facturas',
    'Condiciones y resumen',
  ];

  return (
    <BaseDialog
      isOpen={open}
      size="lg"
      handleClose={hanleCloseDialogCallback}
      title="Nueva Operación de Payments"
    >
      <OperationUploadSteps
        steps={steps}
        handleSubmit={handleSubmitOperation}
        handleNextStep={handleNextStep}
        handleValidateNextStep={handleValidateNextStep}
        isLoadingButton={fetchBusinessInvoicesLoading || isLoading}
        buttonLabelFinish="Crear operación"
        onDrop={onDrop}
        CsvTemplate={[confirmingTemplate[country]]}
        onCalculateFees={onCalculateFees}
        submitError={submitError}
        handleStepBack={handleStepBack}
        fetchClientByIdentifier={fetchClientByIdentifier}
        handleClientNotEnrolled={handleClientNotEnrolled}
      />
    </BaseDialog>
  );
};

CreateOrderDialog.defaultProps = {
  submitError: undefined,
};

CreateOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitError: PropTypes.string,
};

export default CreateOrderDialog;
