import { Button, Grid, styled } from '@material-ui/core';

const CustomButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    color: theme.palette.primary.main,
    background: 'white',
  },
  '&:disabled': {
    border: 'none',
  },
}));

const NewSimulationButtonGrid = styled(Grid)({
  marginBottom: 30,
});

const DownloadSimulationButtonGrid = styled(Grid)({
  marginRight: 39,
});

export { CustomButton, NewSimulationButtonGrid, DownloadSimulationButtonGrid };
