import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const bulkUpdateInvoicesService = async (
  bulkUpdateData,
  { notCalculateSurplus = null } = {}
) => {
  try {
    const { data } = await axios.put(`/api/orderfactura/bulk-update`, {
      bulkUpdateData: {
        ...bulkUpdateData,
        notCalculateSurplus,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default bulkUpdateInvoicesService;
