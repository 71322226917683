/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography,
  Tooltip,
  Box,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Container from './Container';
import Section from './Section';
import { NO_DATA } from '../../helpers/Constants';
import CountryFormatHelper from './CountryFormatHelper';

const useStyles = makeStyles({
  data: {
    justifySelf: props => props.justify || null,
  },
  resume: {
    padding: 'initial',
    marginTop: -12,
    userSelect: 'text',
  },
  column: {
    marginTop: 5,
    maxWidth: 'max-content',
  },
  columnSwitch: {
    marginTop: 5,
    width: '100%',
  },
  body: {
    marginBottom: '2%',
  },
  expansionPanel: {
    boxShadow: 'none',
    '&.MuiExpansionPanel-rounded:last-child': {
      borderRadius: 8,
    },
    background: '#ffffff',
  },
  expansionPanelDetails: {
    padding: '0 20px',
  },
  iconExpansion: { marginBottom: -5, marginLeft: -8 },
  switch: {
    marginTop: -5,
    width: '100%',
  },
  gridData: {
    width: '100%',
  },
});

const CollapsePanel = props => {
  const classes = useStyles(props);
  const panel = 'panel';
  const {
    expanded: expandedProp,
    expandedDisabled,
    title: titleProp,
    isSwitch,
    isEditable,
    resume,
    body,
    buttons,
    direction,
  } = props;
  const [expanded, setExpanded] = useState(expandedProp ? panel : expandedProp);

  const handleChange = panel => (event, newExpanded) => {
    if (!expandedDisabled) {
      setExpanded(newExpanded ? panel : false);
    }
  };
  const title = !isSwitch ? (
    <Typography variant="h6" color="textPrimary" component="div">
      <Box fontWeight="fontWeightBold">
        {expanded !== '' && expanded ? (
          <ExpandLessIcon className={classes.iconExpansion} />
        ) : (
          <KeyboardArrowRightIcon className={classes.iconExpansion} />
        )}
        {titleProp || ''}
      </Box>
    </Typography>
  ) : (
    <Grid item xs={12}>
      <Grid container direction="row">
        <Grid item xs={1}>
          {expanded !== '' && expanded ? (
            <ExpandLessIcon className={classes.iconExpansion} />
          ) : (
            <KeyboardArrowRightIcon className={classes.iconExpansion} />
          )}
        </Grid>
        <Grid item xs={11} className={classes.gridTitle}>
          {titleProp || ''}
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Container>
      <ExpansionPanel
        className={classes.expansionPanel}
        expanded={expanded === panel}
        onChange={handleChange(panel)}
        {...props}
      >
        {!isEditable && (
          <ExpansionPanelSummary className={classes.resume}>
            <Section card gridGap={20}>
              {title}
              {resume && <CollapseData data={resume} classes={classes} />}
            </Section>
          </ExpansionPanelSummary>
        )}
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <Grid container direction={direction}>
            <Grid item className={classes.body}>
              <CollapseData
                data={body}
                isSwitch={isSwitch}
                isEditable={isEditable}
                direction={direction}
                classes={classes}
              />
            </Grid>
            {buttons && (
              <Grid item>
                <CollapseData data={buttons} classes={classes} />
              </Grid>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Container>
  );
};

const CollapseData = props => {
  const { data, classes, suffix, placeholder, isSwitch, direction } = props;
  const getDataComponent = row => {
    const { data, suffix: rowSuffix, type } = row;
    let dataComponent = (
      <>
        {data || placeholder || null}
        {rowSuffix || null}
      </>
    );
    if (type === 'currency') {
      dataComponent = (
        <>
          <CountryFormatHelper value={data} variant={type} countryId="CL" />
          {suffix || null}
        </>
      );
    }
    return dataComponent;
  };
  return (
    <Container>
      {data && data.length > 0 ? (
        <Grid
          container
          justify={data.length > 6 ? 'flex-start space-between' : ''}
          spacing={!isSwitch ? 4 : 0}
          direction="row"
        >
          {data.map(row => {
            const {
              disabled,
              label,
              tooltip,
              type,
              switchWidth = 2,
              labelWidth = 10,
            } = row;
            const dataComponent = getDataComponent(row);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item style={isSwitch ? { width: '100%' } : null}>
                <Grid container direction={direction}>
                  <Grid
                    item
                    xs={labelWidth}
                    className={isSwitch ? classes.columnSwitch : classes.column}
                    justify="left"
                  >
                    <Typography
                      display="inline"
                      variant="body1"
                      color={disabled ? 'textSecondary' : 'textPrimary'}
                    >
                      {label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={type === 'selectorColor' ? 12 : switchWidth}
                    className={isSwitch ? classes.switch : classes.column}
                    justify="right"
                  >
                    {!isSwitch ? (
                      <Tooltip
                        disableHoverListener={!tooltip}
                        title={tooltip || label}
                      >
                        <Typography
                          variant="body1"
                          className={classes.data}
                          color={disabled ? 'textSecondary' : 'textPrimary'}
                        >
                          {dataComponent}
                        </Typography>
                      </Tooltip>
                    ) : (
                      dataComponent
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.data}
        >
          {NO_DATA}
        </Typography>
      )}
    </Container>
  );
};

CollapsePanel.defaultProps = {
  title: '',
  expandedDisabled: false,
  isEditable: false,
  direction: 'column',
  isSwitch: false,
};

CollapseData.defaultProps = {
  suffix: '',
  placeholder: '',
  direction: 'column',
  isSwitch: false,
};

CollapsePanel.propTypes = {
  resume: PropTypes.arrayOf(Object).isRequired,
  body: PropTypes.arrayOf(Object).isRequired,
  buttons: PropTypes.arrayOf(Object).isRequired,
  title: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  expandedDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  direction: PropTypes.string,
  isSwitch: PropTypes.bool,
};

CollapseData.propTypes = {
  classes: PropTypes.objectOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  suffix: PropTypes.string,
  isSwitch: PropTypes.bool,
  placeholder: PropTypes.string,
  direction: PropTypes.string,
};

export default withTheme(CollapsePanel);
