import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useFetchInvoices,
  useOrderSummary,
  useUploadBulkDiscounts,
} from '../infrastructure/store';
import { enqueueSnackbar } from '../../../actions/notificationAction';
import { t13s } from '../../../translationKeys';
import {
  useInvoiceTableReloadRef,
  useSelectedInvoices,
} from '../ui/PayrollInvoices/InvoicesTable/store';

const useDiscountDialog = () => {
  const dispatch = useDispatch();
  const { id: orderId } = useParams();
  const selectedInvoices = useSelectedInvoices();
  const [discountReason, setDiscountReason] = useState('');
  const [showDiscountsDialog, setShowDiscountsDialog] = useState(false);
  const [showFundsBulkUpdateDialog, setShowFundsBulkUpdateDialog] =
    useState(false);
  const [showInvoicesBulkUpdateDialog, setShowInvoicesBulkUpdateDialog] =
    useState(false);
  const { refetch: refetchInvoices } = useFetchInvoices(orderId);
  const invoiceTableReloadRef = useInvoiceTableReloadRef();
  const { refetch: refetchOrderSummary } = useOrderSummary(orderId);

  const showSnackbar = () => {
    dispatch(
      enqueueSnackbar(t13s.ERROR.BULK_INVOICES_BLOCKED, {
        variant: 'error',
      })
    );
  };

  const handleShowDiscountsDialog = () => {
    setShowDiscountsDialog(true);
  };

  const handleCloseDiscountsDialog = () => {
    setDiscountReason('');
    setShowDiscountsDialog(false);
  };

  const onSuccessCallback = () => {
    refetchInvoices();
    invoiceTableReloadRef();
    handleCloseDiscountsDialog();
    refetchOrderSummary();
  };

  const handleShowFundsBulkUpdateDialog = () => {
    if (!selectedInvoices.length) {
      return showSnackbar();
    }
    return setShowFundsBulkUpdateDialog(true);
  };

  const handleCloseFundsBulkUpdateDialog = () => {
    setShowFundsBulkUpdateDialog(false);
  };

  const handleShowInvoicesBulkUpdateDialog = () => {
    if (!selectedInvoices.length) {
      return showSnackbar();
    }
    return setShowInvoicesBulkUpdateDialog(true);
  };

  const handleCloseInvoicesBulkUpdateDialog = () => {
    setShowInvoicesBulkUpdateDialog(false);
  };

  const { uploadBulkDiscounts, uploadBulkDiscountsLoading } =
    useUploadBulkDiscounts(orderId, setShowDiscountsDialog);

  const handleUploadFile = async csvFile => {
    const source = 'orderinvoice';
    const formData = new FormData();
    formData.append('csv', csvFile[0]?.file, csvFile[0]?.file?.name);
    formData.append('reason', discountReason);
    formData.append('source', source);
    uploadBulkDiscounts({ formData, orderId, onSuccessCallback });
  };

  const locales = {
    title: 'Cargar descuentos a facturas',
    description:
      'Arrastra o selecciona el archivo que quieres cargar. (Formato permitido: CSV, máx. 10Mb)',
    action:
      'Arrastra el archivo aquí o haz click para subirlo desde tu computador.',
    download: 'Descargar plantilla',
  };

  const handleInputChange = e => {
    const newReason = e.target.value;
    setDiscountReason(newReason);
  };

  return {
    locales,
    discountReason,
    showDiscountsDialog,
    uploadBulkDiscountsLoading,
    handleUploadFile,
    handleInputChange,
    handleShowDiscountsDialog,
    handleCloseDiscountsDialog,

    showFundsBulkUpdateDialog,
    showInvoicesBulkUpdateDialog,
    handleShowFundsBulkUpdateDialog,
    handleCloseFundsBulkUpdateDialog,
    handleShowInvoicesBulkUpdateDialog,
    handleCloseInvoicesBulkUpdateDialog,
  };
};

export default useDiscountDialog;
