import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const HistoryIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      style={style}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99967 0.833252C15.0624 0.833252 19.1663 4.93717 19.1663 9.99992C19.1663 15.0627 15.0624 19.1666 9.99967 19.1666C4.93692 19.1666 0.833008 15.0627 0.833008 9.99992H2.66634C2.66634 14.0498 5.94984 17.3333 9.99967 17.3333C14.0495 17.3333 17.333 14.0498 17.333 9.99992C17.333 5.95009 14.0495 2.66659 9.99967 2.66659C7.74101 2.66659 5.72067 3.68775 4.37592 5.29284L6.33301 7.24992H0.833008V1.74992L3.07609 3.99209C4.75634 2.05792 7.23501 0.833252 9.99967 0.833252ZM10.9163 5.41659V9.6195L13.8891 12.5923L12.592 13.8893L9.08301 10.3785V5.41659H10.9163Z"
        fill="#1A49E7"
      />
    </CustomSvgIcon>
  );
};

HistoryIcon.defaultProps = {
  style: {},
};

HistoryIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default HistoryIcon;
