import axios from 'axios';
import {
  FETCH_TAX_FOLDER_START,
  FETCH_TAX_FOLDER_SUCCESS,
  FETCH_TAX_FOLDER_FAILURE,
  CHECK_TAX_FOLDER_START,
  CHECK_TAX_FOLDER_SUCCESS,
  CHECK_TAX_FOLDER_FAILURE,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';
import { enqueueSnackbar } from './notificationAction';

export const getTaxFolder = (businessId, type) => dispatch => {
  dispatch({ type: FETCH_TAX_FOLDER_START, payload: { type } });
  axios
    .get(`/api/business/${businessId}/tax-folder`, { params: { type } })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_TAX_FOLDER_SUCCESS,
        payload: { type, response: resData.taxFolder },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_TAX_FOLDER_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_TAX_FOLDER_FAILURE,
        payload: { type, errorCode },
      });
    });
};

export const checkTaxFolder = businessId => dispatch => {
  dispatch({ type: CHECK_TAX_FOLDER_START });
  axios
    .get(`/api/business/${businessId}/tax-folder/check`)
    .then(res => {
      const { hasTaxFolder } = res.data;
      dispatch({
        type: CHECK_TAX_FOLDER_SUCCESS,
        payload: { hasTaxFolder },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.CHECK_TAX_FOLDER_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: CHECK_TAX_FOLDER_FAILURE,
        payload: { errorCode },
      });
    });
};
