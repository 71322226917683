import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  containerTab: {
    borderBottom: `1px solid '#DAD9DB'`,
  },
  headerTabsTab: {
    minWidth: 130,
  },
});

const tabIndicatorStyles = {
  height: '2px',
  borderRadius: '10px',
};

// eslint-disable-next-line import/prefer-default-export
export { useStyles, tabIndicatorStyles };
