import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import Info from '../../../../../commons/icons/Info';
import { useStyles } from './AssignedInvoicesErrorDialog.styles';

export const AssignedInvoicesDialog = ({
  isOpen,
  handleCloseDialog,
  handleCloseManageInvoice,
  assignedDocuments,
  totalDocuments,
  agreePayrollUpdate,
}) => {
  const css = useStyles();
  const erroredInvoicesCount = assignedDocuments
    ? Object.values(assignedDocuments).flatMap(invoice => invoice).length
    : 0;
  const erroredInvoicesFolios = Object.keys(assignedDocuments).reduce(
    (acc, identifier) => {
      const folios = assignedDocuments[identifier].map(doc => doc.folio);
      return [
        ...acc,
        <>
          <strong>{identifier}</strong>: {String(folios).replace(/,/g, ', ')}
        </>,
      ];
    },
    []
  );
  const allDocumentsAssigned =
    erroredInvoicesCount && erroredInvoicesCount === totalDocuments;
  const modalTitle = allDocumentsAssigned
    ? 'Todas las facturas están cedidas'
    : 'Eliminar facturas cedidas';
  const descriptionText = () => {
    if (allDocumentsAssigned) {
      return (
        <>
          Para crear la nómina{' '}
          <strong>selecciona facturas que no estén cedidas</strong>.
        </>
      );
    }
    return (
      <>
        Para crear la nómina modificaremos la selección y{' '}
        <strong>quitaremos las siguientes facturas cedidas</strong>:
      </>
    );
  };

  const closeDialog = () => {
    if (allDocumentsAssigned) {
      handleCloseManageInvoice();
    } else {
      handleCloseDialog();
      agreePayrollUpdate();
    }
  };

  return (
    <BaseDialog isOpen={isOpen} handleClose={closeDialog} title={modalTitle}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <>
          <Box display="flex" className={css.infoContainer}>
            <div className={css.iconContainer}>
              <Info />
            </div>
            <div className={css.textContainer}>
              {descriptionText()}
              {!allDocumentsAssigned && (
                <ul>
                  {erroredInvoicesFolios.slice(0, 3).map((receiver, index) => (
                    <li key={index}>{receiver}</li>
                  ))}
                  {erroredInvoicesFolios.length > 3 && <li>Y otros.</li>}
                </ul>
              )}
            </div>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (allDocumentsAssigned) {
                handleCloseManageInvoice();
              }
              handleCloseDialog();
            }}
            fullWidth
          >
            {allDocumentsAssigned ? 'Aceptar' : 'Aceptar eliminación'}
          </Button>
          <br />
        </>
      </Box>
    </BaseDialog>
  );
};

AssignedInvoicesDialog.defaultProps = {
  isOpen: false,
};

AssignedInvoicesDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  totalDocuments: PropTypes.number.isRequired,
};
