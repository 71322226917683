import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
  Box,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '../form/Autocomplete';
import {
  STATUSES_VERIFIED,
  ITEM_TYPE_CHECK_INVOICE,
  ITEM_TYPE_ORDER,
  ITEM_TYPE_INVOICE,
  ITEM_TYPE_BULK_INVOICE,
  ITEM_TYPE_CREDIT,
  ITEM_TYPE_FEE,
  STATUS_RENEGOTIATION,
  STATUS_REPLACED,
  STATUS_HARD_COLLECTION,
  STATUS_VERIFIED,
  STATUS_POST_VERIFIED,
  STATUS_ORDER_TYPE,
  STATUS_INVOICE_TYPE,
  STATUS_CREDIT_TYPE,
  STATUS_FEE_TYPE,
  TITLE_INVOICE_CHECK,
  LABEL_NO_ORDERS_REPLACE,
  LABEL_MUST_SELECT_ORDER,
  TITLE_DIALOG_CHANGE_STATUS,
  STATUS_REJECTED,
  STATUS_PAID,
  DATE_FORMAT,
  GLOBAL_REASONS_WITH_XTREE,
  STATUS_VERIFICATION_WITHOUT_AMOUNT,
  REASON_XTREE,
} from '../../helpers/Constants';
import { checkStatusAuth } from '../../helpers/validation/auth';
import BaseDialog from './BaseDialog';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import CheckButton from '../elements/CheckButton';
import { features } from '../../config/features';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import useStatuses from '../../hooks/useStatuses';
import { fetchDiscount } from '../../actions/discountActions';
import Skeleton from '../elements/Skeleton';
import { fetchApiCorpsAmount } from '../../actions/businessAction';

const useStyles = makeStyles(theme => ({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 5,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 290,
    },
    '& .MuiSelect-selectMenu': {
      maxWidth: 150,
      marginLeft: 5,
    },
  },
  dialog: {
    borderRadius: '17px',
  },
  dialogTitle: {
    marginBottom: 10,
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: '15px 37px 40px 37px',
  },
}));

const getReason = (item, type) => {
  const {
    verificationReason,
    VerificationReasons = verificationReason,
    StatusReasons: statusReason,
  } = item;
  let reason = '';
  if (type === ITEM_TYPE_CHECK_INVOICE && VerificationReasons?.length) {
    [{ reason }] = VerificationReasons;
  } else if (statusReason && statusReason?.length) {
    [{ reason }] = statusReason;
  }
  return reason;
};
const StatusChangeDialog = ({
  open,
  handleCloseDialog,
  item,
  itemType,
  handleSubmit,
  isCreditDialog = false,
  handleFetchOrdersId,
  loadingOrders,
  ordersId,
  facturasReplaced = [],
  handleFetchInvoiceSelectedOrder,
  destinationOrder,
  destinationInvoice,
  subTitle = null,
  handleStatusBulk,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getStatuses } = useStatuses();
  const [checked, setChecked] = useState(false);
  const { verificationStatus, status, verificationReason } = item;
  const itemStatusValue =
    itemType === ITEM_TYPE_CHECK_INVOICE ? verificationStatus : status;
  const [itemStatus, setItemStatus] = useState(itemStatusValue);
  const itemStatusReasonValue = verificationReason ?? getReason(item, itemType);
  const [itemStatusReason, setItemStatusReason] = useState(
    itemStatusReasonValue
  );
  const [itemDestinationOrder, setItemDestinationOrder] =
    useState(destinationOrder);
  const [itemDestinationInvoice, setItemDestinationInvoice] =
    useState(destinationInvoice);
  const [paymentDateInput, setPaymentDateInput] = useState(false);
  const [paymentDate, setPaymentDate] = useState(item?.paymentDate || '');
  const [
    invoiceVerifiedWithoutAmountDiscount,
    setInvoiceVerifiedWithoutAmountDiscount,
  ] = useState({
    isNewDiscount: true,
    amount: 0,
  });

  const { rules } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { updatedOrder } = useSelector(state => state.order);
  const { discount, fetchDiscountIsLoading } = useSelector(
    state => state.discount
  );
  const { fetchApiCorpsIsLoading, apiCorpsData, business, loadingBusiness } =
    useSelector(state => state.business);
  const validatePaymentDateInput =
    itemType === ITEM_TYPE_INVOICE && paymentDateInput;
  const countryFeatures = features[country];

  const verificationWithoutAmountIsLoading =
    fetchDiscountIsLoading || fetchApiCorpsIsLoading || loadingBusiness;

  useEffect(() => {
    setItemStatus(itemStatusValue);
    setChecked(false);
    setItemDestinationOrder(destinationOrder);
    setItemDestinationInvoice(destinationInvoice);
  }, [
    item,
    destinationOrder,
    destinationInvoice,
    itemStatusValue,
    itemStatusReasonValue,
  ]);

  useEffect(() => {
    if (
      itemStatus === STATUS_VERIFICATION_WITHOUT_AMOUNT &&
      item &&
      business?.id
    ) {
      dispatch(fetchDiscount(item?.id, 'orderinvoice'));
      dispatch(fetchApiCorpsAmount(business?.id, item?.folio));
    }
  }, [item, itemStatus, business]);

  useEffect(() => {
    if (discount?.amount) {
      setInvoiceVerifiedWithoutAmountDiscount({
        isNewDiscount: false,
        amount: discount?.amount,
      });
    }
  }, [discount]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isRazonRequired', value => {
      return ITEM_TYPE_CHECK_INVOICE === itemType && value;
    });
    ValidatorForm.addValidationRule('isGreaterThanZero', value => value > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // just to remove wrong status
  useEffect(() => {
    // im so sorry 4 this
    if (
      itemStatusReason &&
      !GLOBAL_REASONS_WITH_XTREE.find(
        r =>
          r.country.includes(country) &&
          r.type === `INVOICE_${itemStatus}` &&
          r.value === itemStatusReason
      )
    ) {
      setItemStatusReason(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemStatus]);

  const handleCheckboxChange = () => {
    if (itemStatus) setChecked(!checked);
  };

  const controllerPaymentDateInput = value => {
    if (value === STATUS_PAID) {
      setPaymentDateInput(true);
    } else {
      setPaymentDateInput(false);
    }
  };

  const handleValueChange = e => {
    const {
      target: { value },
    } = e;
    setItemStatus(value);
    if (value === STATUS_REPLACED) {
      handleFetchOrdersId();
    }
    controllerPaymentDateInput(value);
  };

  const translatedItemType = itemType => {
    switch (itemType) {
      case ITEM_TYPE_ORDER:
        return 'la operación';
      case ITEM_TYPE_INVOICE:
        return 'factura';
      case ITEM_TYPE_BULK_INVOICE:
        return 'facturas';
      case ITEM_TYPE_CREDIT:
        return 'crédito';
      case ITEM_TYPE_FEE:
        return 'cuota';
      default:
        return itemType;
    }
  };

  const handleFormSubmit = () => {
    const facturaReplaced =
      facturasReplaced.find(factura => {
        return factura.folio === itemDestinationInvoice;
      }) || {};
    if (handleStatusBulk) {
      handleStatusBulk(item, itemStatus);
      return;
    }
    handleSubmit(
      item,
      itemType,
      itemStatus,
      itemStatusReason,
      paymentDate,
      facturaReplaced.id,
      invoiceVerifiedWithoutAmountDiscount
    );
  };

  const isDisabled = (status, type) => {
    return (
      !checkStatusAuth(
        status,
        type === ITEM_TYPE_ORDER ? STATUS_ORDER_TYPE : STATUS_INVOICE_TYPE,
        rules
      ) || [STATUS_RENEGOTIATION].includes(status)
    );
  };

  const handleSelectDestinationOrder = (data, isSelect = false) => {
    let orderId = data || '';
    if (data && data.target) {
      ({ value: orderId } = data.target);
    }
    orderId = orderId.replace(/[^0-9]/g, '');
    if (isSelect) {
      setItemDestinationOrder(orderId);
      handleFetchInvoiceSelectedOrder(orderId);
    } else {
      setItemDestinationOrder(orderId);
      setItemDestinationInvoice('');
      handleFetchInvoiceSelectedOrder();
    }
  };

  const handleSelectDestinationInvoice = folio => {
    const folioValue =
      folio && typeof folio === 'object' ? folio.target.value : folio || '';
    if (folioValue !== '' && itemDestinationInvoice !== folioValue) {
      setItemDestinationInvoice(folioValue);
    }
  };

  const handleGetStatusesVerified = () => {
    const baseStatuses = STATUSES_VERIFIED;
    const operationType = updatedOrder?.type || updatedOrder?.orderType;
    const orderInvoiceAction = countryFeatures.orderInvoiceActions.attributes;

    if (!orderInvoiceAction.enableInvoiceVerifiedWithoutAmount) {
      return baseStatuses;
    }
    if (
      !orderInvoiceAction.invoiceVerifiedWithoutAmountProducts.includes(
        operationType
      )
    ) {
      return baseStatuses;
    }

    return [
      ...baseStatuses,
      {
        key: STATUS_VERIFICATION_WITHOUT_AMOUNT,
        label: 'Verificado sin monto',
      },
    ];
  };

  const GLOBAL_REASONS = useMemo(() => {
    const list = GLOBAL_REASONS_WITH_XTREE.filter(
      r => r.country.includes(country) && r.type === `INVOICE_${itemStatus}`
    );

    if (itemStatusReason !== REASON_XTREE.value) {
      return list.filter(r => r.value !== REASON_XTREE.value);
    }

    return list;
  }, [itemStatusReason, itemStatus]);

  const verifyAllowEnterReason = (status, type) => {
    return (
      (status === STATUS_HARD_COLLECTION ||
        status === STATUS_VERIFIED ||
        status === STATUS_POST_VERIFIED ||
        status === STATUS_VERIFICATION_WITHOUT_AMOUNT ||
        status === STATUS_REJECTED) &&
      [
        ITEM_TYPE_INVOICE,
        ITEM_TYPE_BULK_INVOICE,
        ITEM_TYPE_CHECK_INVOICE,
      ].includes(type)
    );
  };
  const folios = facturasReplaced.map(({ folio }) => folio);
  const title =
    itemType === ITEM_TYPE_CHECK_INVOICE
      ? TITLE_INVOICE_CHECK
      : `Cambiar estado de ${translatedItemType(itemType)}`;

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={
        subTitle !== 'empty' ? subTitle || TITLE_DIALOG_CHANGE_STATUS : ''
      }
    >
      <ValidatorForm onSubmit={handleFormSubmit} className={classes.rootForm}>
        {isCreditDialog && (
          <SelectValidator
            fullWidth
            variant="outlined"
            id="credit-status-select"
            value={itemStatus}
            onChange={handleValueChange}
            label="Estado"
          >
            {getStatuses(itemType)?.map(({ key, label }) => (
              <MenuItem
                key={key}
                value={key}
                disabled={
                  !checkStatusAuth(
                    key,
                    itemType === ITEM_TYPE_CREDIT
                      ? STATUS_CREDIT_TYPE
                      : STATUS_FEE_TYPE,
                    rules
                  ) || [STATUS_RENEGOTIATION].includes(key)
                }
              >
                <Typography variant="body1">{label}</Typography>
              </MenuItem>
            ))}
          </SelectValidator>
        )}
        {[ITEM_TYPE_ORDER, ITEM_TYPE_INVOICE, ITEM_TYPE_BULK_INVOICE].includes(
          itemType
        ) &&
          !isCreditDialog && (
            <SelectValidator
              fullWidth
              variant="outlined"
              id="operation-status-select"
              value={itemStatus}
              onChange={handleValueChange}
              label="Estado"
            >
              {getStatuses(itemType)?.map(({ key, label }) => (
                <MenuItem
                  key={key}
                  value={key}
                  disabled={isDisabled(key, itemType)}
                >
                  <Typography variant="body1">{label}</Typography>
                </MenuItem>
              ))}
            </SelectValidator>
          )}
        {ITEM_TYPE_CHECK_INVOICE === itemType && (
          <SelectValidator
            fullWidth
            variant="outlined"
            id="operation-status-verified"
            value={itemStatus}
            onChange={handleValueChange}
            label="Estado de verificación"
            validators={['required']}
            errorMessages={['Campo requerido']}
          >
            {handleGetStatusesVerified().map(status => (
              <MenuItem key={status.key} value={status.key}>
                <Typography variant="body1">{status.label}</Typography>
              </MenuItem>
            ))}
          </SelectValidator>
        )}

        {itemStatus === STATUS_REPLACED &&
          (!loadingOrders ? (
            <>
              <Autocomplete
                id="auto-complete-destination-order"
                data={ordersId}
                value={itemDestinationOrder}
                onChange={handleSelectDestinationOrder}
                name="destinationOrder"
                label="Orden de destino"
                type="string"
                typeInput="number"
                validators={['required']}
                errorMessages={['Campo requerido']}
                noOptionsText={LABEL_NO_ORDERS_REPLACE}
              />
              <Autocomplete
                id="auto-complete-destination-factura"
                data={folios}
                value={itemDestinationInvoice}
                onChange={handleSelectDestinationInvoice}
                name="destinationFactura"
                label="Factura de destino"
                type="string"
                typeInput="number"
                validators={['required']}
                errorMessages={['Campo requerido']}
                noOptionsText={LABEL_MUST_SELECT_ORDER}
              />
            </>
          ) : (
            <Grid item xs={6} className={classes.justifyCenter}>
              <CircularProgress />
            </Grid>
          ))}
        {verifyAllowEnterReason(itemStatus, itemType) && (
          <SelectValidator
            fullWidth
            variant="outlined"
            name="operationReason"
            id="operation-reason-select"
            value={
              itemStatusReason ||
              (itemStatus === 'REJECTED' && 'HIGH_RISK') ||
              (itemStatus === 'HARD_COLLECTION' && 'CREDIT_NOTE')
            }
            onChange={e => setItemStatusReason(e.target.value)}
            validators={['required', 'isRazonRequired']}
            errorMessages={['Campo requerido', 'Debes seleccionar una razón']}
            label="Razón"
          >
            {GLOBAL_REASONS.map(reason => (
              <MenuItem key={reason.value} value={reason.value}>
                <Typography variant="body1">{reason.label}</Typography>
              </MenuItem>
            ))}
          </SelectValidator>
        )}
        {validatePaymentDateInput && (
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              fullWidth
              label="Fecha de pago real"
              name="paymentDate"
              format={DATE_FORMAT}
              margin="normal"
              value={paymentDate ? moment(paymentDate).utc() : null}
              onChange={date => setPaymentDate(date)}
              minDateMessage="Fecha inválida"
              autoOk
              inputVariant="outlined"
              TextFieldComponent={props => {
                return (
                  <TextValidator
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    validators={paymentDateInput ? ['required'] : []}
                    errorMessages={paymentDateInput ? ['Campo requerido'] : []}
                  />
                );
              }}
            />
          </MuiPickersUtilsProvider>
        )}

        {itemStatus === STATUS_VERIFICATION_WITHOUT_AMOUNT && (
          <>
            <TextValidator
              fullWidth
              disabled={verificationWithoutAmountIsLoading}
              variant="outlined"
              type="number"
              id="discountAmount"
              name="discountAmount"
              value={invoiceVerifiedWithoutAmountDiscount?.amount}
              onChange={e =>
                setInvoiceVerifiedWithoutAmountDiscount({
                  ...invoiceVerifiedWithoutAmountDiscount,
                  amount: e.target.value,
                })
              }
              label="Monto del descuento ($)"
              placeholder="Monto del descuento"
              validators={['required', 'isGreaterThanZero']}
              errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              InputProps={{
                endAdornment: verificationWithoutAmountIsLoading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={16} />
                  </InputAdornment>
                ) : null,
              }}
            />
            <Box display="flex" alignItems="center" mb={2} ml={2}>
              <Skeleton
                isLoading={verificationWithoutAmountIsLoading}
                variant="text"
                width={360}
                height={35}
              >
                <Typography variant="body1">
                  Monto confirmado vía API:{' '}
                  {apiCorpsData?.amount ? (
                    <CountryFormatHelper
                      value={apiCorpsData?.amount}
                      variant="currency"
                      countryId={country}
                    />
                  ) : (
                    'Sin información'
                  )}
                </Typography>
              </Skeleton>
            </Box>
          </>
        )}

        <Box mt={2}>
          <CheckButton
            disabled={verificationWithoutAmountIsLoading}
            check={checked}
            handleCheck={handleCheckboxChange}
            labelButton="Guardar"
          />
        </Box>
      </ValidatorForm>
    </BaseDialog>
  );
};
StatusChangeDialog.defaultProps = {
  ordersId: [],
  facturasReplaced: [],
  handleFetchOrdersId: null,
  handleFetchInvoiceSelectedOrder: null,
  destinationOrder: '',
  destinationInvoice: '',
  isCreditDialog: false,
  loadingOrders: false,
  subTitle: null,
  handleStatusBulk: null,
};

StatusChangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  item: PropTypes.objectOf(String).isRequired,
  itemType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFetchOrdersId: PropTypes.func,
  handleFetchInvoiceSelectedOrder: PropTypes.func,
  isCreditDialog: PropTypes.bool,
  loadingOrders: PropTypes.bool,
  destinationOrder: PropTypes.string,
  destinationInvoice: PropTypes.string,
  ordersId: PropTypes.arrayOf(Number),
  facturasReplaced: PropTypes.arrayOf(Object),
  subTitle: PropTypes.string,
  handleStatusBulk: PropTypes.func,
};

export default StatusChangeDialog;
