import React, { Component } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  MenuItem,
  Button,
  Box,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  ValidatorForm,
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import {
  validateIdentifier,
  rutCleaner,
} from '../../helpers/validation/businessIdentifier';
import verifyDecimals from '../../helpers/validation/decimals';
import NumberFormatCustom from './NumberFormatCustom';
import { DATE_FORMAT, ITEM_TYPE_NEW_CREDIT } from '../../helpers/Constants';
import { t13s } from '../../translationKeys';

const RADIX = 10;
const useStyles = {
  dialog: {
    background: '#FFFFFF',
    borderRadius: '17px',
    maxWidth: 444,
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogTitle: {
    marginBottom: 10,
  },
  successDialog: {
    background: '#F2FEDD',
    borderRadius: '17px',
  },
  gridRate: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: '20px 55px',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
};

class CreditFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier: '',
      name: '',
      amount: '',
      fees: '',
      rate: '',
      period: '',
      startDate: null,
    };
  }

  componentDidMount = () => {
    const { country } = this.props;
    ValidatorForm.addValidationRule('isValidIdentifier', value =>
      validateIdentifier(value, country)
    );
    ValidatorForm.addValidationRule('maxDecimals', value =>
      verifyDecimals(value)
    );
    const { credit } = this.props;
    if (credit) {
      this.setState(prevState => ({
        ...prevState,
        ...credit,
      }));
    }
  };

  handleInputChange = event => {
    const { country } = this.props;
    const targetName = event.target.name;
    let targetValue = event.target.value;

    if (targetName === 'amount') {
      if (country === 'CL') {
        targetValue = parseInt(targetValue.replace(/[^0-9]/g, ''), RADIX);
      } else {
        targetValue = targetValue.replace('$', '');
        const arrAmount = targetValue.split('.');
        targetValue = parseFloat(
          `${arrAmount[0].replace(/,/g, '')}.${arrAmount[1]}`
        );
      }
    }
    this.setState(prevState => ({
      ...prevState,
      [targetName]: targetValue,
    }));
  };

  handleIdentifierChange = event => {
    event.persist();
    const { country } = this.props;
    let identifier = event?.target?.value;
    if (country === 'CL') {
      identifier = rutCleaner(identifier);
    }

    this.setState(prevState => ({
      ...prevState,
      identifier,
    }));
  };

  handleStartDateChange = dateValue => {
    this.setState(prevState => ({
      ...prevState,
      startDate: dateValue,
    }));
  };

  getInputsFormCredit = () => {
    const { credit, t } = this.props;
    const { name, amount, fees, rate, period, startDate, ...identifiers } =
      this.state;
    const identifierValue = identifiers.rut || identifiers.identifier;

    return (
      <>
        <TextValidator
          fullWidth
          variant="outlined"
          label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
          type="string"
          name="identifier"
          aria-describedby={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
          value={identifierValue}
          onChange={e => this.handleIdentifierChange(e)}
          validators={['required', 'isValidIdentifier']}
          errorMessages={[
            'Campo requerido',
            t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
          ]}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Nombre o Razón social"
          type="string"
          name="name"
          aria-describedby="Nombre o Razón social"
          value={name}
          onChange={e => this.handleInputChange(e)}
          validators={['required']}
          errorMessages={['Campo requerido']}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Monto"
          name="amount"
          aria-describedby="Monto"
          value={amount}
          onChange={e => this.handleInputChange(e)}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Nº de cuotas"
              type="number"
              inputProps={{ min: 1, step: 1 }}
              name="fees"
              aria-describedby="Nº de cuotas"
              value={fees}
              onChange={e => this.handleInputChange(e)}
              validators={['required', 'minNumber:1']}
              errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              disabled={!!credit && Object.entries(credit).length}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              variant="outlined"
              label="% Tasa"
              inputProps={{ min: 0, step: 0.0001 }}
              type="number"
              name="rate"
              aria-describedby="Tasa"
              value={rate}
              onChange={e => this.handleInputChange(e)}
              validators={['required', 'maxDecimals']}
              errorMessages={['Campo requerido', 'Max. 4 decimales']}
            />
          </Grid>
        </Grid>
        <SelectValidator
          fullWidth
          variant="outlined"
          label="Periocidad de pago"
          type="string"
          name="period"
          aria-describedby="Periocidad de pago"
          value={period}
          onChange={e => this.handleInputChange(e)}
          validators={['required']}
          errorMessages={['Campo requerido']}
        >
          <MenuItem value="BIANNUAL">Semestral</MenuItem>
          <MenuItem value="QUARTERLY">Trimestral</MenuItem>
          <MenuItem value="MONTHLY">Mensual</MenuItem>
          <MenuItem value="BIWEEKLY">Quincenal</MenuItem>
        </SelectValidator>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <DatePicker
            inputVariant="outlined"
            fullWidth
            label="Fecha Inicio Crédito"
            name="startDate"
            format={DATE_FORMAT}
            onChange={date => this.handleStartDateChange(date)}
            minDateMessage="Fecha inválida"
            autoOk
            value={startDate}
          />
        </MuiPickersUtilsProvider>
      </>
    );
  };

  handleSubmitForm = () => {
    const { type, handleSubmitForm } = this.props;
    handleSubmitForm(this.state, type);
  };

  render() {
    const { classes, open, handleCloseDialog, updated, type } = this.props;
    const title =
      type === ITEM_TYPE_NEW_CREDIT || type === 'orderCredit'
        ? 'Nueva operación de crédito'
        : 'Editar crédito';

    return (
      <Dialog
        open={open}
        PaperProps={{
          className: updated ? classes.successDialog : classes.dialog,
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className={classes.closeButtonContainer}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container direction="column" align="center" justify="center">
          <Grid item xs={12}>
            <Typography
              className={classes.dialogTitle}
              color="textPrimary"
              variant="h5"
              component="div"
            >
              <Box fontWeight="fontWeightBold">{title}</Box>
            </Typography>
          </Grid>
        </Grid>
        <DialogContent className={classes.dialogContent}>
          <Grid item xs={12}>
            <ValidatorForm
              ref={form => {
                this.form = form;
              }}
              onSubmit={this.handleSubmitForm}
            >
              {this.getInputsFormCredit()}

              <Grid className={classes.justifyCenter}>
                <Button type="submit" variant="contained" color="primary">
                  Guardar
                </Button>
              </Grid>
            </ValidatorForm>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

CreditFormDialog.propTypes = {
  classes: PropTypes.objectOf(Object).isRequired,
  credit: PropTypes.objectOf(Object).isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updated: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  country: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CreditFormDialog.defaultProps = {
  country: 'CL',
};

export default withTranslation()(withStyles(useStyles)(CreditFormDialog));
