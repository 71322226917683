import axios from 'axios';
import {
  FETCH_BANKS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
  BANK_ACCOUNT_RESET,
  FETCH_BANK_ACCOUNTS_BY_ID_START,
  FETCH_BANK_ACCOUNTS_BY_ID_SUCCESS,
  FETCH_BANK_ACCOUNTS_BY_ID_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT_START,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  REGISTER_BANK_ACCOUNT_START,
  REGISTER_BANK_ACCOUNT_SUCCESS,
  REGISTER_BANK_ACCOUNT_FAILURE,
  EDIT_BANK_ACCOUNT_START,
  EDIT_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT_FAILURE,
  INVOICE_BANK_ACCOUNT_SUCCESS,
  SELECT_BANK_ACCOUNT,
  RESET_BANK_ACCOUNT_DIALOG,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys';
import { enqueueSnackbar } from './notificationAction';

export const fetchBanks = () => dispatch => {
  dispatch({ type: FETCH_BANKS_START });
  axios
    .get('/api/banks/')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_BANKS_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_BANKS_FAILURE, payload: { errorCode } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_BANKS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const resetBankAccountHolder = () => dispatch => {
  dispatch({ type: BANK_ACCOUNT_RESET });
};

export const fetchBankAccountsById = (entity, entityId) => dispatch => {
  dispatch({ type: FETCH_BANK_ACCOUNTS_BY_ID_START });
  axios
    .get(`/api/${entity}/${entityId}/bankaccount`)
    .then(({ data }) => {
      const formatedData = data?.bankAccounts ?? data;
      dispatch({
        type: FETCH_BANK_ACCOUNTS_BY_ID_SUCCESS,
        payload: { data: formatedData, entityId, entity },
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_BANK_ACCOUNTS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: FETCH_BANK_ACCOUNTS_BY_ID_FAILURE });
    });
};

export const setDefaultBankAccount =
  (entity, entityId, bankAccountId) => dispatch => {
    dispatch({ type: SET_DEFAULT_BANK_ACCOUNT_START });
    axios
      .put(`/api/${entity}/${entityId}/bankaccount/${bankAccountId}`, {
        isDefault: true,
      })
      .then(({ data }) => {
        dispatch({
          type: SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
          payload: { data, entity, entityId, bankAccountId },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DEFAULT_BANK_ACCOUNT_SET, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: SET_DEFAULT_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const deleteBankAccount =
  (entity, entityId, bankAccountId) => dispatch => {
    dispatch({ type: DELETE_BANK_ACCOUNT_START });
    axios
      .delete(`/api/${entity}/${entityId}/bankaccount/${bankAccountId}`)
      .then(res => {
        const resData = res.data;
        dispatch({ type: DELETE_BANK_ACCOUNT_SUCCESS, payload: resData });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BANK_ACCOUNT_DELETED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: DELETE_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const registerBankAccount = (entity, bankAccountData) => dispatch => {
  dispatch({ type: REGISTER_BANK_ACCOUNT_START });
  axios
    .post(`/api/${entity}/${bankAccountData?.sourceId}/bankaccount`, {
      bankAccountData,
    })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: REGISTER_BANK_ACCOUNT_SUCCESS,
        payload: { ...resData, entity, entityId: bankAccountData?.sourceId },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.BANK_ACCOUNT_CREATED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_BANK_ACCOUNT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const editBankAccount =
  (entity, entityId, bankAccountData, bankAccountId) => dispatch => {
    dispatch({ type: EDIT_BANK_ACCOUNT_START });
    axios
      .put(`/api/${entity}/${entityId}/bankaccount/${bankAccountId}`, {
        ...bankAccountData,
      })
      .then(res => {
        if (res.data.bankAccount) {
          dispatch({
            type: INVOICE_BANK_ACCOUNT_SUCCESS,
            payload: res.data,
          });
        }
        dispatch({
          type: EDIT_BANK_ACCOUNT_SUCCESS,
          payload: res.data,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BANK_ACCOUNT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: EDIT_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const selectBankAccount = bankAccount => dispatch => {
  dispatch({ type: SELECT_BANK_ACCOUNT, payload: { bankAccount } });
};

export const resetBankAccountDialog = () => dispatch => {
  dispatch({ type: RESET_BANK_ACCOUNT_DIALOG });
};
