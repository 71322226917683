import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  MenuItem,
  Button,
  Box,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import NumberFormatCustom from './NumberFormatCustom';
import {
  WALLET_TRANSACTION_TYPES,
  TRANSACTION_AMOUNT_TYPES,
  DATE_FORMAT,
} from '../../helpers/Constants';

const useStyles = makeStyles({
  dialog: {
    background: '#FFFFFF',
    borderRadius: 17,
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    color: '#75B801',
    fontSize: 80,
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const CreateWalletTransactionDialog = ({ open, onClose, onSubmit }) => {
  const [date, setDate] = useState(moment().utc());
  const [activity, setActivity] = useState('');
  const [orderInvoiceId, setOrderInvoiceId] = useState('');
  const [type, setType] = useState('');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  const createTransactionError = useSelector(state => state.wallet.errors);
  const isLoading = useSelector(state => state.wallet.creatingTransaction);

  const classes = useStyles();

  const handleSubmit = () => {
    const transactionData = {
      date,
      activity,
      orderInvoiceId,
      type,
      amount:
        type === 'ABONO'
          ? Number(amount.replace(/[^0-9-]/g, ''))
          : -Number(amount.replace(/[^0-9-]/g, '')),
      comment,
    };
    onSubmit(transactionData);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      PaperProps={{ className: classes.dialog }}
      fullWidth
    >
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container direction="column" align="center" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color="textPrimary"
            align="center"
            component="div"
          >
            <Box fontWeight="fontWeightBold">Agregar transacción</Box>
          </Typography>
        </Grid>
      </Grid>
      <DialogContent className={classes.dialogContent}>
        <Grid item xs={12}>
          <ValidatorForm onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                fullWidth
                label="Fecha"
                maxDate={moment().utc()}
                name="date"
                format={DATE_FORMAT}
                value={date}
                onChange={date => setDate(date)}
                autoOk
              />
            </MuiPickersUtilsProvider>
            <SelectValidator
              fullWidth
              variant="outlined"
              label="Tipo"
              name="activity"
              value={activity}
              onChange={e => setActivity(e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {WALLET_TRANSACTION_TYPES.map(({ value, label }) => {
                return <MenuItem value={value}>{label}</MenuItem>;
              })}
            </SelectValidator>
            {activity === 'DEUDA_PENDIENTE' && (
              <TextValidator
                variant="outlined"
                fullWidth
                label="Comentario"
                type="text"
                name="comment"
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
            )}
            <TextValidator
              variant="outlined"
              fullWidth
              label="Factura asociada"
              type="number"
              name="orderInvoiceId"
              value={orderInvoiceId}
              validators={['required']}
              errorMessages={['Campo requerido']}
              onChange={e => setOrderInvoiceId(Number(e.target.value))}
            />
            <SelectValidator
              variant="outlined"
              fullWidth
              value={type}
              onChange={e => setType(e.target.value)}
              label="Tipo de monto"
              name="type"
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {TRANSACTION_AMOUNT_TYPES.map(({ value, label }) => (
                <MenuItem value={value}>{label}</MenuItem>
              ))}
            </SelectValidator>
            <TextValidator
              variant="outlined"
              fullWidth
              value={amount}
              onChange={e => setAmount(e.target.value)}
              label="Monto"
              name="amount"
              validators={['required']}
              errorMessages={['Campo requerido']}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            {createTransactionError && (
              <Typography variant="body2" color="error">
                {createTransactionError}
              </Typography>
            )}
            <Grid className={classes.justifyCenter}>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Guardar
              </Button>
            </Grid>
          </ValidatorForm>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

CreateWalletTransactionDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateWalletTransactionDialog;
