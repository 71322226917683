import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../../../../helpers/Constants';
import { ICsvApiResponse } from '../../../../../../commons/interfaces/ARInterfaces';

const fundBulkUpdateService = async (csvFile: any) => {
  try {
    const formData = new FormData();
    formData.append('csv', csvFile[0]?.file, csvFile[0]?.file?.name);

    const { data } = await axios.post<ICsvApiResponse>(
      '/api/orderinvoicefund/csv',
      formData
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fundBulkUpdateService;
