import React, { useState } from 'react';
import { Box, Grid, styled, Tab } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';

import { useSelector } from 'react-redux';
import DocumentsTab from './DocumentsTab';
import TransferAccountsTab from './TransferAccountsTab';
import AttributesTab from './AttributesTab';
import WalletStatusTab from './WalletStatusTab';

const TabsGrid = styled(Grid)({
  background: '#FFFFFF',
  borderRadius: 16,
  height: '100%',
});

const PaddedTabPanel = styled(TabPanel)({
  padding: '35px 32px',
});

const AttributesTabPanel = styled(TabPanel)({
  padding: '35px 32px 5px 32px',
});

const ATTRIBUTES_VALUE = 'attributes';
const DOCUMENTS_VALUE = 'documents';
const WALLET_STATUS = 'walletStatus';
const TRANSFER_ACCOUNTS_VALUE = 'transferAccounts';

const tabs = {
  CL: [
    {
      label: 'Atributos',
      value: ATTRIBUTES_VALUE,
    },
    {
      label: 'Documentos',
      value: DOCUMENTS_VALUE,
    },
    {
      label: 'Estado cartera',
      value: WALLET_STATUS,
    },
    {
      label: 'Cuentas a transferir',
      value: TRANSFER_ACCOUNTS_VALUE,
    },
  ],
  MX: [
    {
      label: 'Atributos',
      value: ATTRIBUTES_VALUE,
    },
    {
      label: 'Documentos',
      value: DOCUMENTS_VALUE,
    },
    {
      label: 'Estado cartera',
      value: WALLET_STATUS,
    },
    {
      label: 'Cuentas a transferir',
      value: TRANSFER_ACCOUNTS_VALUE,
    },
  ],
};

const PayrollTabs = () => {
  const [value, setValue] = useState('attributes');
  const { country } = useSelector(state => state.config);

  const handleChange = (_event, tabValue) => {
    setValue(tabValue);
  };

  return (
    <TabsGrid container direction="column">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="payroll tabs">
            {tabs[country].map(tab => (
              <Tab label={tab.label} value={tab.value} key={tab.value} />
            ))}
          </TabList>
        </Box>
        <AttributesTabPanel value={ATTRIBUTES_VALUE}>
          <AttributesTab />
        </AttributesTabPanel>
        <PaddedTabPanel value={DOCUMENTS_VALUE}>
          <DocumentsTab />
        </PaddedTabPanel>
        <PaddedTabPanel value={WALLET_STATUS}>
          <WalletStatusTab />
        </PaddedTabPanel>
        <PaddedTabPanel value={TRANSFER_ACCOUNTS_VALUE}>
          <TransferAccountsTab />
        </PaddedTabPanel>
      </TabContext>
    </TabsGrid>
  );
};

export default PayrollTabs;
