import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, styled, Typography } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import {
  updatePaymentOrderDetails,
  resetPayrollDetailsReduxStore,
} from '../../actions/paymentOrderDetailActions';
import { settings } from '../../config/settings';
import { AlertMessage } from '../../payments/commons/components';
import useGetCountry from '../../hooks/useGetCountry';

const CustomGrid = styled(Grid)({
  marginBottom: 15,
});

const PayrollCurrencyDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [formCurrency, setFormCurrency] = useState();
  const country = useGetCountry();
  const {
    attributes,
    paymentOrderDetailsIsUpdating: isLoading,
    paymentOrderDetailsWasUpdated: wasUpdated,
    paymentOrderDetailsError: error,
  } = useSelector(state => state.paymentOrderDetails);
  const { id: orderId } = useSelector(state => state.order?.updatedOrder);
  const { currency } = attributes;

  const defaultCurrencyCode = settings[country].currencyCode;

  useEffect(() => {
    setFormCurrency(currency ?? defaultCurrencyCode);
  }, [currency]);

  const handleClear = () => {
    dispatch(resetPayrollDetailsReduxStore());
  };

  useEffect(() => {
    if (wasUpdated) {
      handleClear();
      handleClose();
    }
  }, [wasUpdated]);

  const handleSubmit = () => {
    const valueToUpdate = { currency: formCurrency };
    dispatch(updatePaymentOrderDetails(orderId, valueToUpdate));
  };

  const onClose = () => {
    handleClear();
    handleClose();
  };

  return (
    <BaseDialog
      title="Editar tipo de moneda"
      isOpen={open}
      handleClose={onClose}
      size="sm"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <SelectValidator
          fullWidth
          variant="outlined"
          label="Tipo de moneda"
          name="formCurrency"
          value={formCurrency}
          onChange={({ target }) => setFormCurrency(target.value)}
          validators={['required']}
          errorMessages={['Campo requerido']}
          disabled={isLoading}
        >
          <MenuItem value="USD" key="USD">
            USD
          </MenuItem>
          <MenuItem value={defaultCurrencyCode} key={defaultCurrencyCode}>
            {defaultCurrencyCode}
          </MenuItem>
        </SelectValidator>

        {error && (
          <CustomGrid container grid xs={12}>
            <AlertMessage
              onClear={handleClear}
              messageComponent={<Typography>{error}</Typography>}
            />
          </CustomGrid>
        )}

        <Grid container item direction="row" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Guardar cambios
          </Button>
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

PayrollCurrencyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PayrollCurrencyDialog;
