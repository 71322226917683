import React, { useState, useEffect } from 'react';

import {
  Box,
  Typography,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import { LaunchOutlined } from '@material-ui/icons';
import { WorkFlowState } from './query';
import ManageChangeStateDialog from '../ManageChangeStateDialog';
import { useGetWorkflowStates } from './useGetWorkflowStates';
import { convertSpacingToCss as spacing } from '../../../../helpers/stylesHelpers';
import { Country, TransitionType } from '../../schemas';
import { getLinkPymeEncoded } from './getLinkPymeEncoded';
import { useGetWorkflowSlackThread } from './useGetWorkflowSlackThread';
import { Skeleton } from '@material-ui/lab';
import * as TableDataAndHistory from '../TableDataAndHistory';
import { ManageAppealState } from '../ManageAppealState';

type Props = {
  businessIdentifier: string;
  country: Country;
  t?: (keyword: string) => string;
  onSuccesChangeState: (workFlowId: string) => void;
};

const TableBusinessCreditLine = ({
  t = (keyword: string) => keyword,
  businessIdentifier,
  country,
  onSuccesChangeState,
}: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentStateId, setCurrentStateId] = useState<string | undefined>();
  const [currentComment, setCurrentComment] = useState<string | null>(null);
  const [currentTransitionType, setCurrentTransitionType] = useState<TransitionType | undefined>(undefined);
  const [workflowId, setWorkflowId] = useState<string | undefined>();
  const [workflowName, setWorkflowName] = useState<string | undefined>();
  const [enableSlackThreadRefetch, setEnableSlackThreadRefetch] = useState(false);

  const { loading, refetch, workflowStates, isRefetching, isSuccess, riskLdcAssigned }
    = useGetWorkflowStates({ businessIdentifier });

  const {
    slackLink,
    loading: isLoadingLink,
    isSuccess: isSuccessGetLink,
    isRefetching: isRefetchingGetSlackThread,
  } = useGetWorkflowSlackThread({
    businessIdentifier,
    enableRefetch: enableSlackThreadRefetch,
    onExceedRefetchLimit() {
      setEnableSlackThreadRefetch(false);
    },
  });

  useEffect(() => {
    if (isSuccess && !isRefetching) {
      setOpenDialog(false);
    }
  }, [isRefetching, isSuccess]);

  const onSuccessHandler = async (_newEntityId: string, workFlowId: string) => {
    onSuccesChangeState(workFlowId);
    await refetch();
    setEnableSlackThreadRefetch(true);
  };

  const displayManageChangeDialog = openDialog && currentStateId && workflowId;

  const onClickCurrentStateHandler = (item: WorkFlowState) => {
    setOpenDialog(true);
    setWorkflowId(item.workflow.id);
    setWorkflowName(item.workflow.name);
    setCurrentComment(item.comment);
    setCurrentTransitionType(item.transitionType);

    if (item.target) {
      setCurrentStateId(item.target.id);
    }
  };

  const handleClickOpenSlackLink = () => {
    if (slackLink) {
      window.open(slackLink, '_blank');
    }
  };

  const pymeLinkClickHandler = () => {
    const link = getLinkPymeEncoded(country, businessIdentifier);
    window.open(link, '_blank');
  };

  return (
    <React.Fragment>
      {displayManageChangeDialog && (
        <ManageChangeStateDialog
          loading={isRefetching}
          currentStateId={currentStateId}
          currentStateComment={currentComment}
          currentTransitionType={currentTransitionType}
          workflowId={workflowId}
          workflowName={workflowName}
          onClose={setOpenDialog}
          onSuccess={onSuccessHandler}
          businessIdentifier={businessIdentifier}
          riskLdcAssignedId={riskLdcAssigned?.id}
        />
      )}
      <Card style={{ borderRadius: 16, boxShadow: 'unset' }}>
        <Box m={8} sx={{ padding: spacing('xl') as string, paddingBottom: 68 }}>
          <Grid container justify="space-between">
            <Typography variant="subtitle1" color="textPrimary">
              <Box fontWeight="bold" mb={5}>
                {t('Estado proceso según equipo')}
              </Box>
            </Typography>
            <Box gridGap={40} display={'flex'}>
              <Box>
                <Button
                  startIcon={<LaunchOutlined />}
                  color="primary"
                  variant="text"
                  onClick={pymeLinkClickHandler}
                >
                  {t(' Ver Dashboard de Aprobación')}
                </Button>
              </Box>
              {(isRefetchingGetSlackThread || isLoadingLink) && (
                <Box sx={{ paddingTop: 7 }}>
                  <Skeleton width={125} height={20} />
                </Box>
              )}
              {isSuccessGetLink && slackLink && (
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<LaunchOutlined />}
                    onClick={() => {
                      handleClickOpenSlackLink();
                    }}
                  >
                    {t('Ver hilo Slack')}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Box>
            {loading && (
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            )}
            <Table aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('')}</TableCell>
                  <TableCell>{t('Equipo')}</TableCell>
                  <TableCell align="center">{t('Estado')}</TableCell>
                  <TableCell>{t('Fecha del estado')}</TableCell>
                  <TableCell align="right" hidden />
                </TableRow>
              </TableHead>
              <TableBody>
                {workflowStates.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell width={50}>
                      <TableDataAndHistory.Toggle
                        selectedIndex={index}
                        id={item.workflow.id}
                      />
                    </TableCell>
                    <TableCell width={140}>
                      <Typography>
                        <Box fontWeight="bold" fontSize="12.5">
                          {item.workflow.name}
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell width={140} align="center">
                      <Box>
                        <Button
                          style={item.buttonStyle}
                          variant="contained"
                          color="primary"
                          onClick={onClickCurrentStateHandler.bind(null, item)}
                        >
                          {item.target.name}
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell width={140}>
                      <Typography>
                        <Box fontSize="12">{item.createdAt}</Box>
                      </Typography>
                    </TableCell>
                    <TableCell width={60} align="right">
                      <ManageAppealState
                        actionEnabled={!item.actionEnabled}
                        businessIdentifier={businessIdentifier}
                        currentStateId={item.target.id}
                        workflowId={item.workflow.id}
                        onSuccess={onSuccessHandler}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default TableBusinessCreditLine;
