// - HISTORY ----------------------
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

let delayQueueHistory = [];
let trackerHistory = i => delayQueueHistory.push(i);
let storeLess = { getState: () => ({}) };
const nextLess = () => 1;

const Component = ({ store }) => {
  const history = useHistory();
  React.useEffect(() => {
    trackerHistory({ path: window.location.pathname, store });
    storeLess = store;
    let echoLess = window.location.pathname;

    return history.listen(location => {
      if (echoLess === location.pathname) {
        return null;
      }
      echoLess = location.pathname;
      try {
        return trackerHistory({ path: location.pathname, store });
      } catch (e) {
        /* SILENT ERROR */
        return null;
      }
    });
  }, [history, store]);

  return null;
};

Component.propTypes = {
  store: PropTypes.oneOfType({}).isRequired,
};

// - REDUX MIDDLEWARE ----------------------
let delayQueueActions = [];
let trackerMiddleware = (store, next, action) => {
  const data = { prevState: store.getState(), action };
  const result = next(action);
  data.nextState = store.getState();
  delayQueueActions.push(JSON.stringify(data));
  return result;
};

const middleware = store => {
  return next => action => {
    try {
      trackerMiddleware(store, () => 1, action);
    } catch (e) {
      /* SILENT ERROR */
    }
    return next(action);
  };
};

// - HTTP INTERCEPTORS ----------------------
let delayQueueFetch = [];
let trackerOnResponse = response =>
  delayQueueFetch.push(JSON.stringify(response));
let trackerOnRequest = d => d;

setTimeout(() => {
  delayQueueFetch = []; /* CLEAR QUEUE */
  delayQueueActions = [];
  delayQueueHistory = [];
}, 1000 * 120); /* WAIT 2 MINS FOR GTM SCRIPT */

const onRequest = config => {
  try {
    trackerOnRequest(config);
  } catch (e) {
    /* SILENT ERROR */
  }
  return config;
};

const onResponse = response => {
  try {
    trackerOnResponse(response);
  } catch (e) {
    /* SILENT ERROR */
  }
  return response;
};

// - SINGLE TRACK ----------------------
const track = (type, payload = {}) => {
  try {
    return trackerMiddleware(storeLess, nextLess, { type, payload });
  } catch (e) {
    /* SILENT ERROR */
  }
  return null;
};

// - ON READY ----------------------
if (window) {
  window.growthTrackerOnReady = (
    fnOnRequest,
    fnOnResponse,
    fnMiddleware,
    fnHistory
  ) => {
    trackerOnRequest = fnOnRequest;
    trackerOnResponse = fnOnResponse;
    trackerMiddleware = fnMiddleware;
    trackerHistory = fnHistory;

    return { delayQueueFetch, delayQueueActions, delayQueueHistory };
  };
}

setTimeout(() => {
  Promise.resolve().then(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      window.location.hostname === 'backoffice.xepelin.com'
        ? 'https://gth.xepel.in/tracker/tracker-backoffice.js'
        : 'https://s3.amazonaws.com/gth.xepel.in/tracker/tracker-backoffice-stg.js';
    document.body.appendChild(script);
  });
}, 2000); /* WAIT 2 MINS BEF INSTALL SCRIPT */

export default { onRequest, onResponse, middleware, Component, event: track };
