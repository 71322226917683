import { createActions } from '../../../../commons/utils';

export const SELECT_PAYROLL = createActions('SELECT_PAYROLL');
export const SELECT_RECEIVER = createActions('SELECT_RECEIVER');
export const UPDATE_PAYROLL_SUMMARY = createActions('UPDATE_PAYROLL_SUMMARY');
export const PICK_RECEIVER = createActions('PICK_RECEIVER');
export const PICK_INVOICE = createActions('PICK_INVOICE');
export const CLEAN_PAYROLL_SELECTOR = createActions('CLEAN_PAYROLL_SELECTOR');

export const FETCH_CESSION_SUMMARY = createActions('FETCH_CESSION_SUMMARY');
export const NOTIFY_CESSIONS = createActions('NOTIFY_CESSIONS');
export const CLOSE_PAYROLL = createActions('CLOSE_PAYROLL');
