import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Drawer, Grid, makeStyles } from '@material-ui/core';
import {
  deleteRole,
  fetchPerforms,
  fetchUsers,
  registerOrUpdateRole,
  registerUser,
  resetUserError,
  selectRole,
  updateUser,
} from '../actions/userActions';
import { Add, Settings } from './icons';
import {
  USERS_ADD_PERFORM,
  USERS_ADD_ROLE_PERFORM,
} from '../helpers/performsType';
import { checkAuth } from '../helpers/validation/auth';
import Can from './Can';
import LoaderComponent from './elements/LoaderComponent';
import RolesSection from './elements/RolesSection';
import UserFormDialog from './elements/UserFormDialog';
import { DRAWER_BACKGROUND } from '../theme/otherColors';
import Panel from './elements/Panel';
import UsersTable from './tables/UsersTable';
import SearchBar from './elements/SearchBar';
import usePagination from '../hooks/pagination';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  button: {
    marginRight: '20px',
  },
  drawerPaper: {
    width: '100%',
    background: DRAWER_BACKGROUND,
  },
});

const HomeUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    users,
    fetchUsersIsLoading,
    pagination,
    roles,
    selectedRole,
    updateUserIsLoading,
    registerUserIsLoading,
    userWasRegistered,
    updateOrRegisterRoleIsLoading,
    deleteRoleIsLoading,
  } = useSelector(state => state.user);
  const { page, limit, updatePageAndLimit } = usePagination(
    () => {},
    pagination.page,
    pagination.pageSize
  );
  const { user, rules } = useSelector(state => state.auth);

  const [showUserDialog, setShowUserDialog] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [field, setField] = useState(null);

  useEffect(() => {
    dispatch(fetchUsers({}));
    if (checkAuth(user?.roles, USERS_ADD_ROLE_PERFORM, rules)) {
      dispatch(fetchPerforms());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoading =
      updateUserIsLoading ||
      updateOrRegisterRoleIsLoading ||
      deleteRoleIsLoading;
    setShowLoader(handleLoading);
  }, [updateUserIsLoading, updateOrRegisterRoleIsLoading, deleteRoleIsLoading]);

  useEffect(() => {
    if (userWasRegistered) setShowUserDialog(false);
  }, [userWasRegistered]);

  const searcher = () => {
    dispatch(
      fetchUsers({
        searchInput,
        field,
        limit,
        page,
      })
    );
  };

  useEffect(() => {
    searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, page, limit]);

  const changeSearchInput = ({ value, field }) => {
    setField(field);
    setSearchInput(value);
  };

  const handleSelectRole = roleData => {
    dispatch(selectRole(roleData));
  };

  const handleSubmitRole = roleData => {
    dispatch(registerOrUpdateRole(roleData));
  };

  const handleDeleteRole = () => {
    dispatch(deleteRole(selectedRole?.id));
  };

  const handleEditUserDialog = userData => {
    dispatch(resetUserError());
    setShowUserDialog(true);
    setUserSelected(userData);
  };

  const handleChangePassword = userData => {
    setUserSelected(userData);
    setResetPassword(true);
    setShowUserDialog(true);
  };

  const handleSubmitUpdateUser = userData => {
    setShowUserDialog(false);
    dispatch(updateUser(userData));
  };

  const handleSubmitRegisterUser = userData => {
    dispatch(registerUser(userData));
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Can
            perform={USERS_ADD_ROLE_PERFORM}
            yes={() => (
              <Button
                onClick={() => setShowDrawer(true)}
                startIcon={<Settings />}
                className={classes.button}
                color="primary"
                variant="text"
              >
                Gestionar roles
              </Button>
            )}
          />
          <Can
            perform={USERS_ADD_PERFORM}
            yes={() => (
              <Button
                onClick={() => setShowUserDialog(true)}
                startIcon={<Add />}
                color="primary"
                variant="text"
              >
                Nuevo usuario
              </Button>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel
            title="Lista de usuarios"
            contentPadding="zero"
            actions={
              <SearchBar
                placeholder="Buscar usuario..."
                handleChangeInput={value => changeSearchInput(value)}
                fields={[
                  {
                    title: 'Nombre',
                    field: 'name',
                  },
                  { title: 'Email', field: 'email' },
                  {
                    title: 'Rol',
                    field: 'role',
                  },
                ]}
                selectedOption={{
                  title: 'Nombre',
                  field: 'name',
                }}
              />
            }
          >
            <UsersTable
              tableData={users}
              loadingUsers={fetchUsersIsLoading}
              handleChangePage={updatePageAndLimit}
              pagination={pagination}
              handleEditUserDialog={handleEditUserDialog}
              handleChangePassword={handleChangePassword}
            />
          </Panel>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        width="100%"
        PaperProps={{
          id: 'rolesDrawer',
          className: classes.drawerPaper,
        }}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <RolesSection
          handleCloseDrawer={() => setShowDrawer(false)}
          handleSelectRole={handleSelectRole}
          handleSubmitRole={handleSubmitRole}
          handleDeleteRole={handleDeleteRole}
          selectedRole={selectedRole}
        />
      </Drawer>

      {showUserDialog && (
        <UserFormDialog
          open
          handleCloseDialog={() => {
            setShowUserDialog(false);
            setResetPassword(false);
            setUserSelected(null);
          }}
          handleSubmitForm={
            userSelected ? handleSubmitUpdateUser : handleSubmitRegisterUser
          }
          listRoles={roles}
          user={userSelected}
          resetPassword={resetPassword}
          showLoader={registerUserIsLoading}
        />
      )}
      {showLoader && (
        <LoaderComponent
          open={showLoader}
          handleCloseDialog={() => setShowLoader(false)}
        />
      )}
    </>
  );
};

export default HomeUsers;
