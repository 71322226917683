import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, styled } from '@material-ui/core';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { AlertMessage, Dialog } from '../../../../../commons/components';
import { Text } from '../../../../../commons/formatters';
import LoadingButton from '../../../../../../components/elements/LoadingButton';
import useCurrencyUpdateDialog from './useCurrencyUpdateDialog';

const CustomGrid = styled(Grid)({
  marginBottom: 15,
});

const CurrencyUpdateDialog = ({ open, handleClose }) => {
  const {
    formCurrency,
    defaultCurrencyCode,
    orderAttributeIsUpdating,
    updateOrderAttributeIsSuccess,
    updateOrderAttributeError,
    handleSelect,
    handleSubmit,
    resetOrderAttributeUpdateData,
  } = useCurrencyUpdateDialog();

  useEffect(() => {
    if (updateOrderAttributeIsSuccess) {
      handleClose();
      resetOrderAttributeUpdateData();
    }
  }, [updateOrderAttributeIsSuccess]);

  return (
    <Dialog
      isOpen={open}
      handleClose={handleClose}
      title="Editar tipo de moneda"
      actionText=""
      handleAction={() => undefined}
      showDialogActions={false}
      width={360}
    >
      <CustomGrid>
        <ValidatorForm onSubmit={handleSubmit}>
          <SelectValidator
            fullWidth
            variant="outlined"
            label="Tipo de moneda"
            name="formCurrency"
            value={formCurrency}
            onChange={handleSelect}
            validators={['required']}
            errorMessages={['Campo requerido']}
            disabled={orderAttributeIsUpdating}
          >
            <MenuItem value="USD" key="USD">
              USD
            </MenuItem>
            <MenuItem value={defaultCurrencyCode} key={defaultCurrencyCode}>
              {defaultCurrencyCode}
            </MenuItem>
          </SelectValidator>

          {updateOrderAttributeError && (
            <CustomGrid container grid xs={12}>
              <AlertMessage
                onClear={resetOrderAttributeUpdateData}
                messageComponent={<Text>{updateOrderAttributeError}</Text>}
              />
            </CustomGrid>
          )}

          <Grid container item direction="row" justifyContent="center">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={orderAttributeIsUpdating}
              isLoading={orderAttributeIsUpdating}
            >
              Guardar cambios
            </LoadingButton>
          </Grid>
        </ValidatorForm>
      </CustomGrid>
    </Dialog>
  );
};

CurrencyUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CurrencyUpdateDialog;
