/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';

const useStyles = makeStyles({
  containerButton: {
    justifyContent: 'center',
  },
  error: {
    marginBottom: 20,
    marginTop: -5,
  },
});
const ConfirmDialog = props => {
  const classes = useStyles(props);
  const {
    isOpen,
    handleClose,
    title,
    message,
    buttonOnAccept,
    buttonLabel,
    data,
    loading,
    error,
    resetErrors,
  } = props;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
    resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialogHandler = () => {
    handleClose();
    setChecked(false);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleFormSubmit = () => {
    buttonOnAccept();
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={closeDialogHandler}
      title={title}
      description={message}
    >
      <ValidatorForm onSubmit={handleFormSubmit}>
        {data && (
          <Grid item xs={12}>
            {data}
          </Grid>
        )}
        {error && (
          <Grid item xs={12} align="center" className={classes.error}>
            <AlertForm variant="error" message={error} />
          </Grid>
        )}
        <Grid xs={12} className={classes.containerButton}>
          <CheckButton
            check={checked}
            handleCheck={handleCheckboxChange}
            labelButton={buttonLabel}
            diabled={loading}
            loading={loading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ConfirmDialog.defaultProps = {
  subtitle: null,
  data: null,
  loading: false,
  error: null,
  resetErrors: () => {},
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.element.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonOnAccept: PropTypes.func.isRequired,
  data: PropTypes,
  loading: PropTypes.bool,
  error: PropTypes.string,
  resetErrors: PropTypes.func,
};

export default ConfirmDialog;
