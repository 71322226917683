import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  usePayrollSelector,
  useSelectedReceiver,
} from '../../../adapters/store/payroll';
import * as Repo from './invoice.repo';
import { getInvoicesUseCase } from '../useCases';

export const FETCH_INVOICES_KEY = 'fetchInvoicesByReceiver';
const FETCH_INVOICE_KEY = 'fetchInvoiceById';

export const useFetchInvoices = (filter, opts) => {
  const { payrollId } = useParams();
  const selectedReceiver = useSelectedReceiver();
  const payrollSelector = usePayrollSelector();
  const req = {
    ...filter,
    payrollId,
    receiverId: selectedReceiver?.receiverId,
  };
  const { data: invoices, ...rest } = useQuery(
    [FETCH_INVOICES_KEY, req],
    () => Repo.fetchInvoicesByReceiver(req),
    opts
  );

  const memoizedInvoices = useMemo(
    () =>
      getInvoicesUseCase({
        invoices,
        payrollSelector,
        receiverId: selectedReceiver?.receiverId,
      }),
    [invoices, payrollSelector, selectedReceiver?.receiverId]
  );

  return { invoices: memoizedInvoices, ...rest };
};

export const useFetchInvoice = (filter, opts) => {
  return useQuery(
    [FETCH_INVOICE_KEY, filter],
    () => Repo.fetchInvoiceById(filter),
    opts
  );
};

export const useUpdateInvoice = opts => {
  return useMutation(req => Repo.updateInvoice(req), opts);
};

export const useDeleteInvoices = opts => {
  const { payrollId } = useParams();
  const payrollSelector = usePayrollSelector();

  return useMutation(
    () => Repo.deleteInvoices({ payrollId, state: payrollSelector }),
    opts
  );
};

export const useUpdatePayrollInvoiceStatus = opts => {
  const { payrollId } = useParams();
  const payrollSelector = usePayrollSelector();

  return useMutation(
    ({ payrollInvoiceStatus }) =>
      Repo.updatePayrollInvoiceStatus({
        payrollId,
        state: payrollSelector,
        payrollInvoiceStatus,
      }),
    opts
  );
};
