import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { registerOrderCreditService } from '../services';

export const useRegisterOrderCredit = () => {
  const dispatch = useDispatch();
  const {
    isLoading: orderCreditIsRegistering,
    isSuccess: registerOrderCreditIsSuccess,
    error: registerOrderCreditError,
    mutate: registerOrderCredit,
    reset: resetCreditRegisterData,
    data: createdOrderCredit,
  } = useMutation(
    ({ orderId, creditFormData }) => {
      return registerOrderCreditService(orderId, creditFormData);
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_CREDIT_CREATED, {
            variant: 'success',
          })
        );
      },
    }
  );

  return {
    orderCreditIsRegistering,
    registerOrderCreditIsSuccess,
    registerOrderCreditError,
    createdOrderCredit,
    registerOrderCredit,
    resetCreditRegisterData,
  };
};

export default useRegisterOrderCredit;
