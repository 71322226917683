import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import BaseDialog from '../../../../components/dialogs/BaseDialog';
import AlertForm from '../../../../components/elements/AlertForm';
import CheckButton from '../../../../components/elements/CheckButton';
import Dropzone from '../../../../components/elements/Dropzone/Dropzone';
import MenuItem from '../../../../components/elements/MenuItem';

import {
  MIFIEL_ON,
  UPLOAD,
  SIGN,
  DOWNLOAD,
  COUNTRY_CODE_CL,
} from '../../../../helpers/Constants';
import { toBase64 } from '../../../../helpers/fileToBase64';
import useDocumentDialog from '../../../details/hooks/useDocumentDialog';
import { useOrderAttributes } from '../../../details/infrastructure/store';
import {
  useStyles,
  ContainerSpinner,
  TextDocumentType,
  ContainerDescription,
  LabelDescription,
  LabelDefault,
  LabelUploadDocument,
  ContainerError,
  ContainerSaveButton,
  ContainerInitialState,
} from './styles';

const CreateDocumentDialog = ({
  open,
  handleCloseDialog,
  showDescription,
  showSignatureType,
  showInitialStatus,
  mimetypes,
}) => {
  const classes = useStyles();

  const { id: orderId } = useParams();
  const { country } = useSelector(state => state.config);
  const { refetch: fetchOrderAttributes } = useOrderAttributes(orderId);
  const [showDropzone, setShowDropzone] = useState(true);
  const [file, setFile] = useState(null);
  const [checkButton, setCheckButton] = useState(false);
  const [isCustomDocument, setIsCustomDocument] = useState(false);

  const {
    documentTypes,
    documentTypesIsLoading,
    documentDialogConstants,
    documentForm,
    createDocumentWithElectronicSignature,
    createDocumentError,
    createDocumentIsLoading,
    createDocumentIsSuccess,
    handleCreateDocument,
    handleCreateDocumentWithSignedFile,
    resetDocumentData,
    setDocumentForm,
  } = useDocumentDialog();
  const { title, description, subTitle, active } = documentForm;

  const {
    CONTRACT,
    DOCUMENTS_WITHOUT_INITIAL_DOCUMENT,
    CONTRACTS,
    DESCRIPTION_PLACEHOLDER_REQUIRED,
    DESCRIPTION_PLACEHOLDER_NOT_REQUIRED,
  } = documentDialogConstants;

  useEffect(() => {
    if (documentTypes?.length) {
      setDocumentForm({ ...documentForm, title: documentTypes[0]?.title });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypes?.length]);

  useEffect(() => {
    setDocumentForm({ ...documentForm, subTitle: CONTRACTS[0]?.title });
  }, []);

  useEffect(() => {
    if (createDocumentIsSuccess) {
      handleCloseDialog();
      resetDocumentData();
      fetchOrderAttributes();
    }
  }, [createDocumentIsSuccess]);

  const handleChangeStatus = (documentFile, status) => {
    if (status === 'done') {
      setFile(documentFile.file);
    } else if (status === 'removed') {
      setFile(null);
    }
  };

  const handleChangeDocumentInfo = ({ target }) => {
    const { value, name } = target;

    if (name === 'title') {
      setIsCustomDocument(value === 'Otro');
    }

    setDocumentForm({
      ...documentForm,
      [name]: value,
    });
  };

  const handleChangeDocumentActive = () => {
    setDocumentForm({
      ...documentForm,
      active: !active,
    });
    setShowDropzone(!active);
  };

  const handleSubmitDialog = async () => {
    const fileBase64 = file ? await toBase64(file) : undefined;
    if (!file) {
      handleCreateDocument(documentForm);
    } else {
      handleCreateDocumentWithSignedFile({
        document: documentForm,
        file: fileBase64,
      });
    }
  };

  const getMenuItem = ({ id, title }) => (
    <MenuItem value={title} key={id}>
      {title}
    </MenuItem>
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={() => {
        handleCloseDialog();
        resetDocumentData();
      }}
      title="Subir nuevo documento"
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={handleSubmitDialog}>
          {documentTypesIsLoading ? (
            <ContainerSpinner>
              <CircularProgress size={30} />
            </ContainerSpinner>
          ) : (
            <SelectValidator
              disabled={documentTypesIsLoading}
              variant="outlined"
              value={title}
              id="title"
              name="title"
              label="Tipo de documento"
              placeholder="CC"
              onChange={handleChangeDocumentInfo}
              fullWidth
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {documentTypes?.map(documentType => getMenuItem(documentType))}
              <MenuItem value="Otro">Otro</MenuItem>
            </SelectValidator>
          )}
          <TextDocumentType>
            El tipo de documentos se le mostrará al usuario en su perfil.
          </TextDocumentType>

          {title === CONTRACT && country === COUNTRY_CODE_CL && (
            <SelectValidator
              variant="outlined"
              value={subTitle}
              id="subTitle"
              name="subTitle"
              label="Tipo de contrato"
              placeholder="test"
              onChange={handleChangeDocumentInfo}
              fullWidth
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {CONTRACTS.map(documentType => getMenuItem(documentType))}
            </SelectValidator>
          )}

          {showDescription && (
            <>
              <ContainerDescription>
                <LabelDescription>Descripción</LabelDescription>
                <Typography variant="caption">
                  {isCustomDocument ? '(Requerido)' : '(Opcional)'}
                </Typography>
              </ContainerDescription>
              <TextValidator
                fullWidth
                multiline
                maxRows={2}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                type="string"
                id="description"
                name="description"
                validators={isCustomDocument ? ['required'] : []}
                errorMessages={isCustomDocument ? ['Campo requerido'] : []}
                placeholder={
                  isCustomDocument
                    ? DESCRIPTION_PLACEHOLDER_REQUIRED
                    : DESCRIPTION_PLACEHOLDER_NOT_REQUIRED
                }
                aria-describedby="description"
                value={description}
                onChange={handleChangeDocumentInfo}
                classes={{
                  root: classes.inputMultiLine,
                }}
              />
            </>
          )}

          {showSignatureType && (
            <>
              <LabelDefault>Tipo de firma</LabelDefault>
              <SelectValidator
                variant="outlined"
                id="action"
                value={documentForm.action}
                name="action"
                onChange={handleChangeDocumentInfo}
                fullWidth
              >
                {createDocumentWithElectronicSignature && (
                  <MenuItem value={MIFIEL_ON}>E-firma</MenuItem>
                )}
                <MenuItem value={SIGN}>Firma física</MenuItem>
                <MenuItem value={UPLOAD}>Solo subida</MenuItem>
                <MenuItem value={DOWNLOAD}>Solo bajada</MenuItem>
              </SelectValidator>
            </>
          )}
          {showInitialStatus && (
            <ContainerInitialState>
              <LabelDefault>Estado inicial</LabelDefault>
              <RadioGroup
                row
                name="active"
                value={documentForm.active}
                onChange={handleChangeDocumentActive}
              >
                <FormControlLabel
                  value
                  control={<Radio color="primary" />}
                  label="Activo"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Inactivo"
                />
              </RadioGroup>
            </ContainerInitialState>
          )}
          <Collapse
            in={
              showDropzone &&
              !DOCUMENTS_WITHOUT_INITIAL_DOCUMENT.includes(documentForm.action)
            }
            timeout="auto"
            unmountOnExit
          >
            <LabelUploadDocument>Subir documento final</LabelUploadDocument>
            <Dropzone
              maxFiles={1}
              minSize={100}
              isLoading={createDocumentIsLoading}
              onChangeStatus={handleChangeStatus}
              SubmitButtonComponent={null}
              accept={mimetypes}
            />
          </Collapse>

          {createDocumentError && (
            <ContainerError item xs={12} align="center">
              <AlertForm variant="error" message={createDocumentError} />
            </ContainerError>
          )}

          <ContainerSaveButton>
            <CheckButton
              check={checkButton}
              handleCheck={() => setCheckButton(!checkButton)}
              labelButton="Agregar documento"
              loading={createDocumentIsLoading}
            />
          </ContainerSaveButton>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

CreateDocumentDialog.defaultProps = {
  showDescription: true,
  showSignatureType: true,
  showInitialStatus: true,
  mimetypes: '.pdf,image/jpeg,image/gif,image/png',
};

CreateDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
  showSignatureType: PropTypes.bool,
  showInitialStatus: PropTypes.bool,
  mimetypes: PropTypes.string,
};

export default CreateDocumentDialog;
