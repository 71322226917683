import {
  SEND_EMAIL_START,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_RESET_ERRORS,
  SEND_SIMULATION_EMAIL_GLOBAL_START,
  SEND_SIMULATION_EMAIL_GLOBAL_SUCCESS,
  SEND_SIMULATION_EMAIL_GLOBAL_FAILURE,
  SEND_SIMULATION_EMAIL_GLOBAL_RESET_ERRORS,
} from '../actions/types';

const initialState = {
  sendEmailIsLoading: false,
  emailSent: false,
  sendEmailErrorsLines: null,
  sendEmailError: null,

  sendSimulationEmailIsLoading: false,
  simulationEmailSent: false,
  sendSimulationEmailErrorsLines: null,
  sendSimulationEmailError: null,
};

const sendEmailStart = state => {
  return {
    ...state,
    sendEmailIsLoading: true,
    sendEmailErrorsLines: null,
    sendEmailError: null,
    emailSent: false,
  };
};

const sendEmailSuccess = (state, payload) => {
  return {
    ...state,
    sendEmailIsLoading: false,
    emailSent: !payload?.errorLines?.length,
    sendEmailErrorsLines: payload.errorLines,
  };
};

const sendEmailFailure = (state, payload) => {
  return {
    ...state,
    sendEmailIsLoading: false,
    sendEmailError: payload.errorCode,
  };
};

const sendSimulationEmailStart = state => {
  return {
    ...state,
    sendSimulationEmailIsLoading: true,
    sendSimulationEmailErrorsLines: null,
    sendSimulationEmailError: null,
  };
};

const sendSimulationEmailSuccess = (state, payload) => {
  return {
    ...state,
    sendSimulationEmailIsLoading: false,
    simulationEmailSent: !payload.errorLines.length,
    sendSimulationEmailErrorsLines: payload.errorLines,
  };
};

const sendSimulationEmailFailure = (state, payload) => {
  return {
    ...state,
    sendSimulationEmailIsLoading: false,
    sendSimulationEmailError: payload.errorCode,
  };
};

const sendSimulationEmailReset = state => {
  return {
    ...state,
    sendSimulationEmailError: null,
    sendSimulationEmailErrorsLines: null,
  };
};

const sendEmailReset = state => {
  return {
    ...state,
    sendEmailError: null,
    sendEmailErrorsLines: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEND_EMAIL_START:
      return sendEmailStart(state);
    case SEND_EMAIL_SUCCESS:
      return sendEmailSuccess(state, payload);
    case SEND_EMAIL_FAILURE:
      return sendEmailFailure(state, payload);
    case SEND_EMAIL_RESET_ERRORS:
      return sendEmailReset(state);
    case SEND_SIMULATION_EMAIL_GLOBAL_START:
      return sendSimulationEmailStart(state);
    case SEND_SIMULATION_EMAIL_GLOBAL_SUCCESS:
      return sendSimulationEmailSuccess(state, payload);
    case SEND_SIMULATION_EMAIL_GLOBAL_FAILURE:
      return sendSimulationEmailFailure(state, payload);
    case SEND_SIMULATION_EMAIL_GLOBAL_RESET_ERRORS:
      return sendSimulationEmailReset(state);
    default:
      return state;
  }
};
