import {
  FETCH_BUSINESS_RATES_START,
  FETCH_BUSINESS_RATES_SUCCESS,
  FETCH_BUSINESS_RATES_FAILURE,
  FETCH_AGREEMENT_RATES_START,
  FETCH_AGREEMENT_RATES_SUCCESS,
  FETCH_AGREEMENT_RATES_FAILURE,
  FETCH_ORDER_INVOICE_BUSINESS_RATES_START,
  FETCH_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  ADD_ORDER_INVOICE_BUSINESS_RATES_START,
  ADD_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  ADD_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  UPDATE_ORDER_INVOICE_BUSINESS_RATES_START,
  UPDATE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  UPDATE_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  DELETE_ORDER_INVOICE_BUSINESS_RATES_START,
  DELETE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS,
  DELETE_ORDER_INVOICE_BUSINESS_RATES_FAILURE,
  RESET_RATES_ACTIONS,
} from '../actions/types';

const initialState = {
  businessRates: [],
  fetchBusinessRatesIsLoading: false,
  fetchBusinessRatesError: null,

  agreementRates: [],
  fetchAgreementRatesIsLoading: false,
  fetchAgreementRatesError: null,

  orderInvoiceBusinessRates: [],
  orderInvoiceIdOwnerOfBusinessRates: 0,
  fetchOrderInvoiceBusinessRatesIsLoading: false,
  fetchOrderInvoiceBusinessRatesError: null,

  orderInvoiceBusinessRateWasCreated: false,
  addOrderInvoiceBusinessRatesIsLoading: false,
  addOrderInvoiceBusinessRatesError: null,

  orderInvoiceBusinessRateWasUpdated: false,
  updateOrderInvoiceBusinessRatesIsLoading: false,
  updateOrderInvoiceBusinessRatesError: null,

  orderInvoiceBusinessRateWasDeleted: false,
  deleteOrderInvoiceBusinessRatesIsLoading: false,
  deleteOrderInvoiceBusinessRatesError: null,
};

const fetchBusinessRatesStart = state => {
  return {
    ...state,
    fetchBusinessRatesIsLoading: true,
    fetchBusinessRatesError: null,
  };
};

const fetchBusinessRatesSuccess = (state, payload) => {
  return {
    ...state,
    fetchBusinessRatesIsLoading: false,
    businessRates: payload.businessRates,
  };
};

const fetchBusinessRatesFailure = (state, payload) => {
  return {
    ...state,
    fetchBusinessRatesIsLoading: false,
    fetchBusinessRatesError: payload.errorCode,
  };
};

const fetchAgreementRatesStart = state => {
  return {
    ...state,
    fetchAgreementRatesIsLoading: true,
    fetchAgreementRatesError: null,
  };
};

const fetchAgreementRatesSuccess = (state, payload) => {
  return {
    ...state,
    fetchAgreementRatesIsLoading: false,
    agreementRates: payload.agreementRates,
  };
};

const fetchAgreementRatesFailure = (state, payload) => {
  return {
    ...state,
    fetchAgreementRatesIsLoading: false,
    fetchAgreementRatesError: payload.errorCode,
  };
};

const fetchOrderInvoiceBusinessRatesStart = (state, orderInvoiceId) => {
  return {
    ...state,
    fetchOrderInvoiceBusinessRatesIsLoading: true,
    fetchOrderInvoiceBusinessRatesError: null,
    orderInvoiceIdOwnerOfBusinessRates: orderInvoiceId,
  };
};

const fetchOrderInvoiceBusinessRatesSuccess = (state, payload) => {
  return {
    ...state,
    fetchOrderInvoiceBusinessRatesIsLoading: false,
    orderInvoiceBusinessRates: payload.orderInvoiceBusinessRates,
  };
};

const fetchOrderInvoiceBusinessRatesFailure = (state, payload) => {
  return {
    ...state,
    fetchOrderInvoiceBusinessRatesIsLoading: false,
    fetchOrderInvoiceBusinessRatesError: payload.errorCode,
  };
};

const addOrderInvoiceBusinessRateStart = state => {
  return {
    ...state,
    orderInvoiceBusinessRateWasCreated: false,
    addOrderInvoiceBusinessRatesIsLoading: true,
    addOrderInvoiceBusinessRatesError: null,
  };
};
const addOrderInvoiceBusinessRateSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoiceBusinessRates: [
      ...state.orderInvoiceBusinessRates,
      payload.orderInvoiceBusinessRate,
    ],
    addOrderInvoiceBusinessRatesIsLoading: false,
    orderInvoiceBusinessRateWasCreated: true,
  };
};

const addOrderInvoiceBusinessRateFailure = (state, payload) => {
  return {
    ...state,
    addOrderInvoiceBusinessRatesIsLoading: false,
    addOrderInvoiceBusinessRatesError: payload.errorCode,
  };
};

const updateOrderInvoiceBusinessRateStart = state => {
  return {
    ...state,
    orderInvoiceBusinessRateWasUpdated: false,
    updateOrderInvoiceBusinessRatesIsLoading: true,
    updateOrderInvoiceBusinessRatesError: null,
  };
};
const updateOrderInvoiceBusinessRateSuccess = (state, payload) => {
  const { orderInvoiceBusinessRate: rate } = payload;
  return {
    ...state,
    orderInvoiceBusinessRates: state.orderInvoiceBusinessRates.map(oibr =>
      oibr.id !== rate.id ? oibr : rate
    ),
    updateOrderInvoiceBusinessRatesIsLoading: false,
    orderInvoiceBusinessRateWasUpdated: true,
  };
};

const updateOrderInvoiceBusinessRateFailure = (state, payload) => {
  return {
    ...state,
    updateOrderInvoiceBusinessRatesIsLoading: false,
    updateOrderInvoiceBussinessRatesError: payload.errorCode,
  };
};

const deleteOrderInvoiceBusinessRateStart = state => {
  return {
    ...state,
    orderInvoiceBusinessRateWasDeleted: false,
    deleteOrderInvoiceBusinessRatesIsLoading: true,
    deleteOrderInvoiceBusinessRatesError: null,
  };
};

const deleteOrderInvoiceBusinessRateSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoiceBusinessRates: state.orderInvoiceBusinessRates.filter(
      oibr => oibr.id !== payload.id
    ),
    deleteOrderInvoiceBusinessRatesIsLoading: false,
    orderInvoiceBusinessRateWasCreated: true,
  };
};

const deleteOrderInvoiceBusinessRateFailure = (state, payload) => {
  return {
    ...state,
    deleteOrderInvoiceBusinessRatesIsLoading: false,
    deleteOrderInvoiceBusinessRatesError: payload.errorCode,
  };
};

const resetRatesActions = state => {
  return {
    ...state,
    orderInvoiceBusinessRateWasCreated: false,
    orderInvoiceBusinessRateWasUpdated: false,
    orderInvoiceBusinessRateWasDeleted: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BUSINESS_RATES_START:
      return fetchBusinessRatesStart(state);
    case FETCH_BUSINESS_RATES_SUCCESS:
      return fetchBusinessRatesSuccess(state, payload);
    case FETCH_BUSINESS_RATES_FAILURE:
      return fetchBusinessRatesFailure(state, payload);
    case FETCH_AGREEMENT_RATES_START:
      return fetchAgreementRatesStart(state);
    case FETCH_AGREEMENT_RATES_SUCCESS:
      return fetchAgreementRatesSuccess(state, payload);
    case FETCH_AGREEMENT_RATES_FAILURE:
      return fetchAgreementRatesFailure(state, payload);
    case FETCH_ORDER_INVOICE_BUSINESS_RATES_START:
      return fetchOrderInvoiceBusinessRatesStart(state);
    case FETCH_ORDER_INVOICE_BUSINESS_RATES_SUCCESS:
      return fetchOrderInvoiceBusinessRatesSuccess(state, payload);
    case FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE:
      return fetchOrderInvoiceBusinessRatesFailure(state, payload);
    case ADD_ORDER_INVOICE_BUSINESS_RATES_START:
      return addOrderInvoiceBusinessRateStart(state);
    case ADD_ORDER_INVOICE_BUSINESS_RATES_SUCCESS:
      return addOrderInvoiceBusinessRateSuccess(state, payload);
    case ADD_ORDER_INVOICE_BUSINESS_RATES_FAILURE:
      return addOrderInvoiceBusinessRateFailure(state, payload);
    case UPDATE_ORDER_INVOICE_BUSINESS_RATES_START:
      return updateOrderInvoiceBusinessRateStart(state);
    case UPDATE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS:
      return updateOrderInvoiceBusinessRateSuccess(state, payload);
    case UPDATE_ORDER_INVOICE_BUSINESS_RATES_FAILURE:
      return updateOrderInvoiceBusinessRateFailure(state, payload);
    case DELETE_ORDER_INVOICE_BUSINESS_RATES_START:
      return deleteOrderInvoiceBusinessRateStart(state);
    case DELETE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS:
      return deleteOrderInvoiceBusinessRateSuccess(state, payload);
    case DELETE_ORDER_INVOICE_BUSINESS_RATES_FAILURE:
      return deleteOrderInvoiceBusinessRateFailure(state, payload);
    case RESET_RATES_ACTIONS:
      return resetRatesActions(state);
    default:
      return state;
  }
};
