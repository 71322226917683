import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

interface Props {
  isLoading: boolean;
  children: JSX.Element | JSX.Element[];
}

const NotificationsDrawerSkeleton: FC<Props> = ({
  isLoading = false,
  children,
}) => {
  if (!isLoading) return <>{children}</>;
  return (
    <>
      {[1, 2, 3, 4, 5].map(() => (
        <Box
          style={{
            padding: '16px 36px',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Box mr={2}>
              <Skeleton variant="circle" width={24} height={24} />
            </Box>
            <Skeleton variant="rect" width={151} height={18} />
          </Box>
          <Skeleton variant="rect" width={496} height={18} />
        </Box>
      ))}
    </>
  );
};

export default NotificationsDrawerSkeleton;
