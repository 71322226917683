import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import InfoIcon from '@material-ui/icons/Info';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import GetAppIcon from '../../../components/icons/GetApp';
import AlertForm from '../../../components/elements/AlertForm';
import CountryFormatHelper from '../../../components/elements/CountryFormatHelper';
import Dropzone from '../../../components/elements/Dropzone/Dropzone';
import VerticalStepper from '../../../components/elements/VerticalStepper';
import {
  DATE_FORMAT,
  COUNTRY_CODE_CL,
  CURRENCY,
} from '../../../helpers/Constants';
import { features } from '../../../config/features';
import { settings } from '../../../config/settings';
import { dateWithoutFormatter } from '../../../helpers/DateUtils';
import {
  validateIdentifier,
  rutCleaner,
} from '../../../helpers/validation/businessIdentifier';
import { DRAWER_BACKGROUND } from '../../../theme/otherColors';
import { t13s } from '../../../translationKeys';
import {
  OPERATION_COST_PERCENTAGE_CHANGE,
  ORDER_RATES_PERCENTAGE_CHANGE,
} from '../../../helpers/performsType';
import { checkAuth } from '../../../helpers/validation/auth';
import { fixNumber } from '../../../helpers/mathUtils';
import { WarningCircleIcon } from '../../../components/icons';
import {
  FINANCING,
  FINANCING_LABEL,
  OWN_CAPITAL,
  OWN_CAPITAL_LABEL,
} from '../constants/order';

const useStyles = makeStyles(theme => ({
  containerGeneralInformation: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  inputs: {
    paddingTop: 5,
    paddingRight: 5,
  },
  containerConditionsAndSummary: {
    paddingTop: 20,
  },
  titleOperationSummary: {
    paddingBottom: 20,
  },
  containerOperationSummary: {
    backgroundColor: DRAWER_BACKGROUND,
    padding: '25px 30px',
    borderRadius: 13,
  },
  itemOperationSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  containerInputs: {
    paddingRight: 10,
  },
  containerAlertForm: {
    paddingTop: 20,
  },
  templateButton: {
    textDecoration: 'none',
    marginTop: 5,
    color: theme.palette.text.secondary,
  },
  alertForm: {
    marginTop: 10,
  },
  containerInformationText: {
    marginLeft: 10,
    marginTop: -8,
  },
  spacingInformationText: {
    margin: '0 4px',
  },
  autoTasaWarning: {
    color: theme.palette.warning.dark,
    padding: 0,
  },
  autoTasaWarningIcon: {
    marginRight: 8,
    '& svg': {
      width: 16,
    },
  },
}));

const OperationUploadSteps = ({
  steps,
  handleSubmit,
  handleNextStep,
  handleValidateNextStep,
  isLoadingButton,
  buttonLabelFinish,
  onDrop,
  CsvTemplate,
  onCalculateFees,
  submitError,
  handleStepBack,
  fetchClientByIdentifier,
  handleClientNotEnrolled,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config);
  const countryFeatures = features[country];
  const countrySettings = settings[country];
  const [clientSelected, setClientSelected] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [availableInvoicesAmount, setAvailableInvoicesAmount] = useState(0);
  const [bounce, setBounce] = useState(null);
  const [fees, setFees] = useState({});
  const [currency, setCurrency] = useState(CURRENCY[country]);
  const [generalInformationAlert, setGeneralInformationAlert] = useState(null);
  const [operationPaymentType, setOperationPaymentType] = useState(FINANCING);
  const [conditionsAndSummaryError, setConditionsAndSummaryError] =
    useState(null);
  const [csvTemplate, setCsvTemplate] = useState([]);
  const [csvErrors, setCsvErrors] = useState(null);
  const {
    businessInvoices,
    calculateBusinessFeesLoading,
    businessFees,
    fetchBusinessPayerError,
    fetchBusinessInvoicesError,
    calculateBusinessFeesError,
    businessName,
    business,
    loadingBusiness,
  } = useSelector(state => state.business);

  const [formFees, setFormFees] = useState({
    baseRate: 0,
    issuedDate: Date.now(),
    expirationDate: Date.now(),
    operationCost: businessFees.operationCost,
  });
  const [disabledClientName, setDisabledClientName] = useState(true);
  const [validationRates, setValidationRates] = useState({
    minRate: 0,
  });
  const [validationOperationCost, setValidationOperationCost] = useState({
    minOperationCost: 0,
  });

  const [defaultBaseRate, setDefaultBaseRate] = useState(0);
  const [defaultOperationCost, setDefaultOperationCost] = useState(0);
  const {
    uploadingInvoiceCsvToOrder,
    csvInvoiceToOrderErrors,
    uploadedInvoiceCsvToOrder,
    invoices: invoicesCsv,
  } = useSelector(state => state.invoice);
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const { t } = useTranslation();

  const handleValidationRate = () => {
    ORDER_RATES_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const rawNewMinRate = fixNumber(defaultBaseRate - percentageChange);
        const minBaseRateSettings = countrySettings.minBaseRateCreateOperation;
        const newMinRate = Math.max(rawNewMinRate, minBaseRateSettings);
        setValidationRates({
          minRate: newMinRate,
        });
      }
    });
  };

  const handleValidationOperationCost = () => {
    OPERATION_COST_PERCENTAGE_CHANGE.forEach(perform => {
      if (checkAuth(roles, perform, rules)) {
        const percentageChange = Number(perform.split('_')[1]);
        const valueChange = fixNumber(
          businessFees.operationCost * (percentageChange / 100)
        );
        const minOperationCost = fixNumber(
          businessFees.operationCost - valueChange
        );
        setValidationOperationCost({
          minOperationCost,
        });
      }
    });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value => {
      return validateIdentifier(value, country);
    });
    ValidatorForm.addValidationRule('maxBaseRate', value => {
      return Number(value) <= countrySettings.maxBaseRate;
    });
    setFormFees({
      retentionPct: settings[country].retentionPct,
      issuedDate: Date.now(),
      expirationDate: Date.now(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (defaultBaseRate) {
      handleValidationRate();
    }
    if (defaultOperationCost) {
      handleValidationOperationCost();
    }
  }, [defaultBaseRate, defaultOperationCost]);

  useEffect(() => {
    ValidatorForm.addValidationRule('minOperationCost', value => {
      return Number(value) >= validationOperationCost.minOperationCost;
    });
  }, [validationRates, validationOperationCost]);

  useEffect(() => {
    if (operationPaymentType !== OWN_CAPITAL) return;
    setFormFees({
      ...formFees,
      operationCost: 0,
    });
  }, [operationPaymentType]);

  useEffect(() => {
    setFormFees({
      ...formFees,
      baseRate: fees?.baseRate,
      baseRateService: fees?.baseRateService,
      operationCost:
        operationPaymentType === OWN_CAPITAL
          ? formFees.operationCost
          : fees?.operationCost,
    });
    if (fees?.baseRate && !defaultBaseRate) {
      setDefaultBaseRate(fees?.baseRate);
    }
    if (fees?.operationCost && !defaultOperationCost) {
      setDefaultOperationCost(fees?.operationCost);
    }
  }, [businessFees, fees]);

  const handleSelectClientEnrolled = client => {
    setClientSelected(client);
    setDisabledClientName(true);
    setInvoices([]);
    setFees({});

    if (!client.hasConfirming) {
      setGeneralInformationAlert({
        message: 'Cliente no tiene confirming activado',
        variant: 'info',
      });
    }
  };

  useEffect(() => {
    setGeneralInformationAlert(null);
  }, []);

  useEffect(() => {
    if (businessName) {
      setClientSelected({ ...clientSelected, name: businessName });
      setDisabledClientName(businessName?.trim() !== '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessName]);

  useEffect(() => {
    if (business?.taxUserActive) {
      handleSelectClientEnrolled(business);
    } else if (
      !loadingBusiness &&
      clientSelected?.identifier &&
      !businessName
    ) {
      setGeneralInformationAlert({
        message: 'Cliente no enrolado',
        variant: 'info',
      });
      setClientSelected({ id: business.id, ...clientSelected });
      handleClientNotEnrolled(clientSelected.identifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, businessName, loadingBusiness]);

  useEffect(() => {
    setInvoices(businessInvoices);
    const availableInvoicesAmount = invoices.reduce(
      (sum, invoice) => sum + invoice.amount,
      0
    );
    setAvailableInvoicesAmount(availableInvoicesAmount);
  }, [businessInvoices, invoices]);

  useEffect(() => {
    const ownCapitalFeesData = { ...businessFees, baseRate: 0 };
    const feesDataToSend =
      operationPaymentType === OWN_CAPITAL ? ownCapitalFeesData : businessFees;
    setFees(feesDataToSend);
  }, [businessFees]);

  useEffect(() => {
    setCsvErrors(csvInvoiceToOrderErrors);
  }, [csvInvoiceToOrderErrors]);

  useEffect(() => {
    const errorMessage = fetchBusinessPayerError || fetchBusinessInvoicesError;
    if (errorMessage) {
      setGeneralInformationAlert({ message: errorMessage, variant: 'error' });
    }
  }, [fetchBusinessPayerError, fetchBusinessInvoicesError]);

  useEffect(() => {
    const errorMessage = submitError || calculateBusinessFeesError;
    setConditionsAndSummaryError(errorMessage);
  }, [submitError, calculateBusinessFeesError]);

  const handleSelectCurrency = event => {
    setCurrency(event.target.value);
  };

  const handleDownloadTemplateCsv = () => {
    setCsvTemplate(CsvTemplate);
  };

  const handleFeesCalculate = (
    localFees = {},
    shouldKeepOperationCost = false
  ) => {
    let { baseRate } = settings[country];
    baseRate = operationPaymentType === OWN_CAPITAL ? 0 : baseRate;
    const operationCost =
      localFees.operationCost !== '' &&
      localFees.operationCost >= 0 &&
      shouldKeepOperationCost
        ? localFees.operationCost
        : undefined;

    const feesData = {
      retentionPct: 0,
      baseRate,
      operationCost,
      issuedDate: localFees.issuedDate,
    };

    onCalculateFees(
      clientSelected.id,
      invoicesCsv,
      feesData,
      operationPaymentType
    );
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    const newFees = {
      ...formFees,
      [name]: value,
    };
    setFormFees(newFees);
    clearTimeout(bounce);
    const shouldKeepOperationCost = true;
    setBounce(
      setTimeout(() => {
        handleFeesCalculate(newFees, shouldKeepOperationCost);
      }, 1000)
    );
  };

  const handleDateChange = (name, date) => {
    const newFees = {
      ...formFees,
      [name]: date,
    };
    setFormFees(newFees);
    handleFeesCalculate(newFees, true);
  };

  const handleClientIdentifierChange = event => {
    let identifier = event?.target?.value;
    if (country === COUNTRY_CODE_CL) {
      identifier = rutCleaner(event?.target?.value);
    }

    setClientSelected({ identifier });
    setGeneralInformationAlert(null);

    if (validateIdentifier(identifier, country)) {
      fetchClientByIdentifier(identifier);
    }
  };

  const handleClientNameChange = event => {
    setClientSelected({
      ...clientSelected,
      name: event?.target?.value,
    });
  };

  const getCsvErrorsLink = () => (
    <Button
      endIcon={<InfoIcon />}
      color="primary"
      variant="text"
      className={classes.linkError}
    >
      <CSVLink
        data={csvTemplate}
        separator=","
        style={{ textDecoration: 'none', fontSize: 13 }}
        filename="errores"
        asyncOnClick
        onClick={(event, done) => {
          setCsvTemplate(csvErrors);
          done();
        }}
      >
        Descargar el detalle
      </CSVLink>
    </Button>
  );

  const generalInformation = (
    <Grid container className={classes.containerGeneralInformation}>
      <Grid item xs={7}>
        <SelectValidator
          fullWidth
          variant="outlined"
          id="operationType"
          label="Tipo de Pago"
          value={operationPaymentType}
          name="operationType"
          onChange={e => setOperationPaymentType(e.target.value)}
        >
          {countryFeatures.orderActions.createConfirmingOrder && (
            <MenuItem value={OWN_CAPITAL}>{OWN_CAPITAL_LABEL}</MenuItem>
          )}
          {countryFeatures.orderActions.createConfirmingOrder && (
            <MenuItem value={FINANCING}>{FINANCING_LABEL}</MenuItem>
          )}
        </SelectValidator>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={7}>
          <TextValidator
            id="input-client-identifier"
            fullWidth
            variant="outlined"
            value={clientSelected?.identifier || ''}
            label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} cliente`}
            placeholder={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} cliente`}
            type="string"
            name="client"
            aria-describedby={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} cliente`}
            onChange={e => handleClientIdentifierChange(e)}
            validators={['required', 'isValidIdentifier']}
            errorMessages={[
              'Campo requerido',
              t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
            ]}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-start">
        <Grid item xs={7}>
          {clientSelected?.name && (
            <TextValidator
              fullWidth
              variant="outlined"
              value={clientSelected.name}
              disabled={disabledClientName}
              label="Nombre cliente"
              placeholder="Nombre cliente"
              type="string"
              aria-describedby="Nombre cliente"
              onChange={e => handleClientNameChange(e)}
              validators={['required', 'minStringLength:2']}
              errorMessages={[
                'Campo requerido',
                'Debe tener un mínimo de 3 carácteres',
              ]}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={7}>
        <SelectValidator
          fullWidth
          variant="outlined"
          id="currency"
          label="Tipo de moneda"
          value={currency}
          name="currency"
          onChange={e => handleSelectCurrency(e)}
        >
          <MenuItem value={CURRENCY[country]}>{CURRENCY[country]}</MenuItem>
          <MenuItem value={CURRENCY.US}>{CURRENCY.US}</MenuItem>
        </SelectValidator>
      </Grid>

      {generalInformationAlert ? (
        <Grid item xs={7}>
          <AlertForm
            message={generalInformationAlert.message}
            variant={generalInformationAlert.variant}
          />
        </Grid>
      ) : null}
    </Grid>
  );

  const uploadCSVInvoiceStep = (
    <Grid item xs={12}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={4}>
          {!uploadingInvoiceCsvToOrder && (
            <Button startIcon={<GetAppIcon />} color="default" variant="text">
              <CSVLink
                data={csvTemplate}
                separator=","
                className={classes.templateButton}
                filename="data_template"
                asyncOnClick
                onClick={(event, done) => {
                  handleDownloadTemplateCsv();
                  done();
                }}
              >
                Descargar Template
              </CSVLink>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        {uploadingInvoiceCsvToOrder ? (
          <CircularProgress size={80} className={classes.loaderDropzone} />
        ) : (
          <Dropzone
            handleSubmit={files => onDrop(files, setCsvErrors, clientSelected)}
            accept=".csv"
            maxFiles={1}
            isLoading={uploadingInvoiceCsvToOrder}
            actionText="Cargar"
          />
        )}
        {!uploadingInvoiceCsvToOrder && uploadedInvoiceCsvToOrder && (
          <Grid item xs={12} align="center" className={classes.alertForm}>
            <AlertForm
              messageComponent={
                <Typography
                  align="left"
                  variant="body2"
                  color="textPrimary"
                  component="div"
                >
                  <Box fontWeight="fontWeightBold">
                    {csvErrors?.length > 0
                      ? `Se presentaron ${csvErrors.length} errores. `
                      : `Se cargaron ${invoicesCsv.length} facturas.`}
                    {csvErrors?.length > 0 && getCsvErrorsLink()}
                  </Box>
                </Typography>
              }
              variant={csvErrors?.length ? 'error' : 'success'}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const resumenOperationData = [
    {
      title: 'Monto total facturas:',
      body: (
        <CountryFormatHelper
          value={fees?.totalAmount}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Total a pagar:',
      body: (
        <CountryFormatHelper
          value={fees?.advance}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Tasa base:',
      body: (
        <CountryFormatHelper
          value={operationPaymentType === OWN_CAPITAL ? 0 : fees?.baseRate}
          variant="percentage"
          countryId={country}
        />
      ),
    },
    {
      title: 'Total intereses a pagar:',
      body: (
        <CountryFormatHelper
          value={fees?.interest}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      title: 'Costo asesoría Xepelin:',
      body: (
        <CountryFormatHelper
          value={formFees?.operationCost}
          variant="currency"
          countryId={country}
        />
      ),
    },
  ];

  const conditionsAndSummary = (
    <Grid container className={classes.containerConditionsAndSummary}>
      <Grid container item xs={5}>
        <Grid container className={classes.containerInputs}>
          <Grid item xs={12} className={classes.inputs}>
            <TextValidator
              fullWidth
              disabled
              variant="outlined"
              label="% Tasa base"
              inputProps={{
                min: 0,
                max: countrySettings.maxBaseRate,
                step: 0.001,
              }}
              type="number"
              name="baseRate"
              value={
                operationPaymentType === OWN_CAPITAL
                  ? 0
                  : formFees?.baseRate ?? 0
              }
              onChange={e => handleInputChange(e)}
              validators={['required', 'maxBaseRate']}
              errorMessages={[
                'Campo requerido',
                `El valor máximo debe ser ${countrySettings.maxBaseRate}`,
              ]}
              FormHelperTextProps={{
                className: classes.autoTasaWarning,
              }}
              helperText={
                formFees?.baseRateService !== undefined &&
                !formFees?.baseRateService &&
                operationPaymentType === FINANCING && (
                  <Grid container>
                    <Grid item className={classes.autoTasaWarningIcon}>
                      <WarningCircleIcon />
                    </Grid>
                    <Grid item xs>
                      Hemos calculado un promedio para fijar la tasa, por lo que
                      puedes modificarla en una simulación posterior
                    </Grid>
                  </Grid>
                )
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.inputs}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                inputVariant="outlined"
                label="Fecha de pago a proveedores"
                id="issuedDate"
                name="issuedDate"
                format={DATE_FORMAT}
                value={dateWithoutFormatter(formFees?.issuedDate || Date.now())}
                onChange={date => {
                  handleDateChange('issuedDate', date);
                }}
                autoOk
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} className={classes.inputs}>
            <TextValidator
              fullWidth
              disabled={calculateBusinessFeesLoading}
              variant="outlined"
              label="Costo asesoría Xepelin"
              type="number"
              id="operationCost"
              name="operationCost"
              value={formFees?.operationCost ?? 0}
              onChange={e => handleInputChange(e)}
              validators={['required', 'minOperationCost']}
              errorMessages={[
                'Campo requerido',
                `El valor mínimo debe ser ${validationOperationCost.minOperationCost}`,
              ]}
            />
            {validationOperationCost.minOperationCost !== 0 && (
              <Box display="flex" className={classes.containerInformationText}>
                <Typography variant="body1" color="textSecondary">
                  Asesoría digital minima:
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="div"
                  className={classes.spacingInformationText}
                >
                  <Box fontWeight="fontWeightBold">
                    {validationOperationCost.minOperationCost}
                  </Box>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={7}>
        <Grid item xs={12} className={classes.containerOperationSummary}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              component="div"
              color="textPrimary"
              className={classes.titleOperationSummary}
            >
              <Box fontWeight="fontWeightBold">Resumen de la operación</Box>
            </Typography>
          </Box>
          {resumenOperationData.map(({ title, body }) => (
            <Grid item className={classes.itemOperationSummary}>
              {calculateBusinessFeesLoading ? (
                <Skeleton variant="rect" width="100%" height={19} />
              ) : (
                <>
                  <Typography variant="body1">{title}</Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">{body}</Box>
                  </Typography>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {conditionsAndSummaryError && (
        <Grid item xs={12} className={classes.containerAlertForm}>
          <AlertForm message={conditionsAndSummaryError} variant="error" />
        </Grid>
      )}
    </Grid>
  );

  const stepsContent = [
    generalInformation,
    uploadCSVInvoiceStep,
    conditionsAndSummary,
  ];

  return (
    <VerticalStepper
      steps={steps}
      stepsContent={stepsContent}
      handleSubmit={handleSubmit(
        fees,
        formFees,
        clientSelected,
        currency,
        invoices.length,
        availableInvoicesAmount,
        operationPaymentType
      )}
      handleNextStep={handleNextStep(formFees, handleFeesCalculate)}
      handleValidateNextStep={handleValidateNextStep(clientSelected)}
      isLoadingButton={isLoadingButton}
      buttonLabelFinish={buttonLabelFinish}
      handleStepBack={handleStepBack}
    />
  );
};

OperationUploadSteps.defaultProps = {
  buttonLabelFinish: 'Guardar',
  isLoadingButton: false,
  CsvTemplate: undefined,
  submitError: undefined,
};

OperationUploadSteps.propTypes = {
  steps: PropTypes.arrayOf(String).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidateNextStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  isLoadingButton: PropTypes.bool,
  buttonLabelFinish: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  // es solo para dejar que el csvTemplate puede ser agnostico
  // eslint-disable-next-line react/forbid-prop-types
  CsvTemplate: PropTypes.arrayOf(Object),
  // eslint-disable-next-line react/require-default-props
  onCalculateFees: PropTypes.func.isRequired,
  submitError: PropTypes.string,
  handleStepBack: PropTypes.func.isRequired,
  fetchClientByIdentifier: PropTypes.func.isRequired,
  handleClientNotEnrolled: PropTypes.func.isRequired,
};

export default OperationUploadSteps;
