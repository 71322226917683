import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import updateOrderCommentService from '../services/updateOrderComment';
import { enqueueSnackbar } from '../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../translationKeys/index';
import { fetchCountOrderComments } from '../../../../../../actions/orderActions';
import { CommentDataToUpdate, FlagType } from '../../interfaces';

interface Variables {
  operationId: number;
  commentId: number;
  commentDataToUpdate: CommentDataToUpdate;
  flagType: FlagType;
}

const useUpdateOrderComment = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { authState } = useOktaAuth();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: updateOrderComment,
  } = useMutation(
    ({ operationId, commentId, commentDataToUpdate }: Variables) =>
      updateOrderCommentService(operationId, commentId, commentDataToUpdate),
    {
      onSuccess: (_data, variables) => {
        if (variables.flagType === 'operationDetail') {
          dispatch(
            fetchCountOrderComments(
              variables?.operationId,
              authState?.idToken?.claims?.email
            )
          );
        }
        if (variables.flagType === 'operationList') {
          queryClient.invalidateQueries('getOperations', { exact: false });
        }
        queryClient.invalidateQueries('fetchOrderComments', { exact: false });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_COMMENT_UPDATED, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_COMMENT_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    updateOrderComment,
  };
};

export default useUpdateOrderComment;
