import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTgrDebt,
  resetBusinessFees,
  updateBusinessFees,
  consultBusinessCompliance,
  fetchBusinessCompliance,
  resetBusinessCompliance,
  fetchBusinessComplianceFile,
  registerPfx,
} from '../actions/businessAction';
import {
  consultDebtService,
  fetchDebtService,
  resetConsultDebtService,
  consultDebtSuper,
  fetchDebtSuper,
} from '../actions/debtServiceActions';
import { enqueueSnackbar } from '../actions/notificationAction';
import { COMPLIANCE, COMPLIANCE_DOWNLOAD } from '../helpers/Constants';
import {
  BUSINESS_COMPLIANCE_PERFORM,
  BUSINESS_EDIT_FEE_PERFORM,
  BUSINESS_UPLOAD_PFX_PERFORM,
  BUSINESS_TAX_FOLDER_PERFORM,
  BUSINESS_DICOM_PERFORM,
  BUSINESS_UPDATE_SWITCH_AUTO_RATE,
} from '../helpers/performsType';
import Can from './Can';
import EditRatesDialog from './dialogs/EditRatesDialog';
import ComplianceDialog from './dialogs/ComplianceDialog';
import CountryFormatHelper from './elements/CountryFormatHelper';
import MenuMoreOptions from './elements/MenuMoreOptions';
import MenuItem from './elements/MenuItem';
import {
  Check as CheckIcon,
  Copy as CopyIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Help as HelpIcon,
  Info as InfoIcon,
  Reload as ReloadIcon,
  Download as DownloadIcon,
  Folder as FolderIcon,
  Upload as UploadIcon,
} from './icons';
import PfxUploadDialog from './dialogs/PfxUploadDialog';
import TaxFolderDialog from './dialogs/TaxFolderDialog';
import DebtServiceDialog from './dialogs/DebtServiceDialog';
import { checkTaxFolder } from '../actions/taxfolderActions';
import LoadingButton from './elements/LoadingButton';
import DebtSuperDialog from './elements/DebtSuperDialog';
import { dateFormatter } from '../helpers/DateUtils';
import {
  useEntityFeatures,
  ENTITY_FEATURE_AUTOTASA,
  ENTITY_FEATURE_CONFIRMING,
} from '../hooks/entityFeatures';
import { t13s } from '../translationKeys';
import { checkAuth } from '../helpers/validation/auth';

const useStyles = makeStyles({
  containerItemsSizeStandar: {
    margin: 10,
    width: 120,
  },
  containerItemsSizeLarge: {
    margin: 10,
    width: 260,
  },
  elementTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 26,
    marginBottom: 5,
  },
  spacingRight: {
    marginRight: 8,
  },
  containerButtons: {
    padding: 10,
    paddingBottom: 0,
  },
  infoBusinessButtons: {
    marginRight: 20,
    marginBottom: 10,
  },
  help: {
    marginLeft: 10,
  },
});

const SPECIAL_CASES = ['Correo de intercambio'];
const BUSINESS = 'business';

const BusinessDetailCL = ({ handleTgrClick, business }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    updateFeesLoading,
    updateFeesError,
    updateFeesDone,
    totalTgrDebt,
    fetchTotalTgrDebtLoading,
    businessCompliance,
    fetchBusinessComplianceIsLoading,
    consultBusinessComplianceIsLoading,
    pfxWasUploaded,
    saving,
  } = useSelector(state => state.business);
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const { updatedEntityFeatureIsLoading } = useSelector(
    state => state.entityFeature
  );

  const {
    consulted: isConsulted,
    debtService,
    canConsult: canConsultDebtService,
    fetchDebtSuperIsLoading,
    debtSuper,
    debtSuperWasConsult,
  } = useSelector(state => state.debtService);
  const { hasTaxFolder, checkTaxFolderIsLoading } = useSelector(
    state => state.taxfolder
  );
  const { country } = useSelector(state => state.config);

  const [showEditRatesDialog, setShowEditRatesDialog] = useState(false);
  const [showPfxDialog, setShowPfxDialog] = useState(false);
  const [showDebtSuperDialog, setShowDebtSuperDialog] = useState(false);
  const [showTaxFolderDialog, setShowTaxFolderDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [showComplianceDialog, setShowComplianceDialog] = useState(false);
  const [showDebtServiceDialog, setShowDebtServiceDialog] = useState(false);
  const [canUpdateAutoRate, setCanUpdateAutoRate] = useState(false);

  const {
    id: businessId,
    name: businessName,
    identifier,
    rate,
    retention,
    defaultConfirmingRate,
    exchangeEmail,
    hasMarketInvoicer,
    hasIsOneDay,
    hasConfirmingEnabled,
    hasPfxCertificate,
    defaultRates,
    hasTaxUserActive,
  } = business;

  const [
    currentEntityFeatures,
    handleEntityStatus,
    fetchEntityFeaturesIsLoading,
  ] = useEntityFeatures(BUSINESS, businessId);

  // entityFeatures
  const autoRate =
    currentEntityFeatures?.[ENTITY_FEATURE_AUTOTASA.keyName]?.active ?? false;
  const confirmingFeature =
    currentEntityFeatures?.[ENTITY_FEATURE_CONFIRMING.keyName]?.active ?? false;

  useEffect(() => {
    if (updateFeesDone) {
      setShowEditRatesDialog(false);
    }
  }, [updateFeesDone]);

  useEffect(() => {
    if (pfxWasUploaded) {
      setShowPfxDialog(false);
    }
  }, [pfxWasUploaded]);

  useEffect(() => {
    if (isConsulted) {
      dispatch(resetConsultDebtService());
      setShowDebtServiceDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsulted]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchTgrDebt(businessId));
      dispatch(checkTaxFolder(businessId));
      dispatch(fetchDebtService(businessId, BUSINESS));
    }
  }, [businessId, dispatch]);

  useEffect(() => {
    if (debtSuperWasConsult) {
      setShowDebtSuperDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtSuperWasConsult, businessId]);

  useEffect(() => {
    if (identifier) {
      dispatch(fetchDebtSuper(identifier));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  useEffect(() => {
    setCanUpdateAutoRate(
      checkAuth(roles, BUSINESS_UPDATE_SWITCH_AUTO_RATE, rules)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, rules]);

  const handleSubmitFees = (
    rateSelected,
    confirmingRate,
    retentionSelected,
    devengoRates
  ) => {
    dispatch(
      updateBusinessFees({
        businessId,
        rate: rateSelected,
        confirmingRate,
        retention: retentionSelected,
        devengoRates,
        type: 'update',
      })
    );
  };

  const handleResetFees = () => {
    dispatch(updateBusinessFees({ businessId, type: 'reset' }));
  };

  const handleCloseDialogs = () => {
    setShowEditRatesDialog(false);
    setShowComplianceDialog(false);
  };

  const handleClickMenu = (event, type) => {
    event.persist();
    setAnchorEl({ ...anchorEl, [type]: event.target });
  };

  const handleAutoRateChange = () => {
    handleEntityStatus(ENTITY_FEATURE_AUTOTASA);
  };

  const copyToClipboard = (value = null) => {
    copy(value);
    if (value) {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
          variant: 'success',
        })
      );
    } else {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }
  };

  useEffect(() => {
    if (businessId) {
      dispatch(fetchTgrDebt(businessId));
    }
  }, [businessId, dispatch]);

  useEffect(() => {
    dispatch(resetBusinessCompliance());
  }, [dispatch]);

  useEffect(() => {
    if (identifier) {
      dispatch(fetchBusinessCompliance(identifier, businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, identifier]);

  const handleConsultCompliance = () => {
    const selectIdentifier = identifier;
    dispatch(
      consultBusinessCompliance(selectIdentifier, {
        businessId,
        businessName,
      })
    );
    setAnchorEl({});
    handleCloseDialogs();
  };

  const statusRetention = defaultRates ? ' (Auto)' : ' (Custom)';
  const statusRate = defaultRates ? ' (Auto)' : ' (Custom)';

  const handleConfirmingStatus = () => {
    handleEntityStatus(ENTITY_FEATURE_CONFIRMING);
  };

  const debtServiceData = () => {
    if (debtService && Object.keys(debtService).length) {
      const { montoTotal, totalAmount = montoTotal } = debtService;
      if (totalAmount < 0) {
        return (
          <Typography variant="body1" color="textSecondary">
            Rut sin giro comercial
          </Typography>
        );
      }

      return (
        <Tooltip
          title={
            debtService?.createdAt
              ? `Última actualización: ${dateFormatter(debtService.createdAt)}`
              : `No hay datos de Buró de Crédito`
          }
        >
          <Typography variant="body1" color="textSecondary">
            <Box
              color={totalAmount > 0 ? 'error.main' : ''}
              display="flex"
              alignContent="center"
            >
              <CountryFormatHelper
                value={totalAmount}
                countryId={country}
                variant="currency"
              />
            </Box>
          </Typography>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={canConsultDebtService ? '' : 'El cliente no ha firmado'}>
        <Typography variant="body1" color="textSecondary">
          -
        </Typography>
      </Tooltip>
    );
  };

  const complianceData = () => {
    let textCompliance = businessCompliance?.lastConsultCompliance
      ? 'Sin coincidencias'
      : null;
    if (businessCompliance?.complianceDetail) {
      const labelCompliance = businessCompliance?.complianceDetail.types;
      textCompliance = (
        <>
          <Typography variant="body1" color="textSecondary">
            Coincidencias en
          </Typography>

          <Typography variant="body1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightBold">{labelCompliance}</Box>
          </Typography>
        </>
      );
    }
    return (
      <Typography variant="body1" color="textSecondary">
        {textCompliance || '-'}
      </Typography>
    );
  };

  const getDebtSuperDataContent = () => {
    const { totalDebt = null, status, currentDebt } = debtSuper;
    const totalDebtWithoutDebtSuper = status === '-11' ? null : totalDebt;
    return totalDebtWithoutDebtSuper !== null ? (
      <Box color={currentDebt > 0 ? 'error.main' : 'default.main'}>
        {currentDebt !== null ? (
          <Typography variant="body1" color="inherit">
            <CountryFormatHelper
              value={currentDebt * 1000}
              countryId="CL"
              variant="currency"
            />
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            Sin registros
          </Typography>
        )}
      </Box>
    ) : (
      <Typography variant="body1" color="textSecondary">
        -
      </Typography>
    );
  };

  const getIcon = value => (
    <Box color={value ? 'success.main' : 'error.main'}>
      {value ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
    </Box>
  );

  const infoBusiness = [
    {
      title: 'Rut',
      content: (
        <Typography
          variant="body1"
          color="textSecondary"
          data-cy="businessDetailIdentifier"
        >
          {identifier}
        </Typography>
      ),
      icon: (
        <IconButton
          size="small"
          onClick={() => copyToClipboard(identifier)}
          data-cy="businessDetailCopyIdentifierButton"
        >
          <CopyIcon />
        </IconButton>
      ),
    },
    {
      title: 'Monto Dicom',
      content: debtServiceData(),
      icon: (
        <Can
          perform={BUSINESS_DICOM_PERFORM}
          yes={() => (
            <Tooltip
              title={
                debtService?.createdAt
                  ? `Última actualización: ${dateFormatter(
                      debtService.createdAt
                    )}`
                  : `No hay datos de Dicom`
              }
            >
              <IconButton
                onClick={() => setShowDebtServiceDialog(true)}
                size="small"
              >
                <Box display="flex" alignContent="center">
                  <ReloadIcon />
                </Box>
              </IconButton>
            </Tooltip>
          )}
        />
      ),
    },
    {
      title: 'TGR',
      content: fetchTotalTgrDebtLoading ? (
        <CircularProgress size={15} />
      ) : totalTgrDebt ? (
        <Typography color="textSecondary" variant="body1">
          <CountryFormatHelper
            value={totalTgrDebt}
            countryId="CL"
            variant="currency"
          />
        </Typography>
      ) : null,
      icon: (
        <Tooltip title="Ver convenios">
          <IconButton onClick={handleTgrClick} size="small">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Compliance',
      icon: (
        <Can
          perform={BUSINESS_COMPLIANCE_PERFORM}
          yes={() => (
            <MenuMoreOptions
              options={[
                <MenuItem
                  onClick={() => setShowComplianceDialog(true)}
                  icon={<ReloadIcon />}
                >
                  Consultar compliance
                </MenuItem>,
                <MenuItem
                  onClick={() => {
                    dispatch(
                      fetchBusinessComplianceFile(
                        businessId,
                        COMPLIANCE_DOWNLOAD
                      )
                    );
                    setAnchorEl({});
                  }}
                  icon={<DownloadIcon />}
                  disabled={!businessCompliance?.complianceDetail?.id}
                >
                  Descargar archivo
                </MenuItem>,
              ]}
              anchorEl={anchorEl.COMPLIANCE}
              handleCloseMenu={() => setAnchorEl({})}
              handleClickMenu={handleClickMenu}
              type={COMPLIANCE}
            />
          )}
          no={() => (
            <MenuMoreOptions
              options={[
                <MenuItem
                  onClick={() => {
                    dispatch(
                      fetchBusinessComplianceFile(
                        businessId,
                        COMPLIANCE_DOWNLOAD
                      )
                    );
                    setAnchorEl({});
                  }}
                  icon={<DownloadIcon />}
                  disabled={!businessCompliance?.complianceDetail?.id}
                >
                  Descargar archivo
                </MenuItem>,
              ]}
              anchorEl={anchorEl.COMPLIANCE}
              handleCloseMenu={() => setAnchorEl({})}
              handleClickMenu={handleClickMenu}
              type={COMPLIANCE}
            />
          )}
        />
      ),
      content:
        fetchBusinessComplianceIsLoading ||
        consultBusinessComplianceIsLoading ? (
          <Skeleton variant="rect" width={98} height={20} />
        ) : (
          complianceData()
        ),
    },
    {
      title: 'Deuda Financiera',
      content: fetchDebtSuperIsLoading ? (
        <Skeleton variant="rect" width={98} height={20} />
      ) : (
        getDebtSuperDataContent()
      ),
      icon: (
        <Tooltip
          title={
            debtSuper?.createdAt
              ? `Última actualización: ${dateFormatter(debtSuper.createdAt)}`
              : 'Rut sin historial crediticio'
          }
        >
          <IconButton size="small" onClick={() => setShowDebtSuperDialog(true)}>
            <ReloadIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Balance cuentas',
      content: null,
    },
    {
      title: 'MiPyme',
      content: getIcon(!hasMarketInvoicer),
    },
    {
      title: 'Retención',
      content: retention ? (
        <Typography color="textSecondary" variant="body1">
          <CountryFormatHelper
            value={retention}
            variant="percentage"
            countryId={country}
          />
          {statusRetention}
        </Typography>
      ) : null,
    },
    {
      title: 'Tasa',
      content: rate ? (
        <Typography color="textSecondary" variant="body1">
          <CountryFormatHelper
            value={rate}
            variant="percentage"
            countryId={country}
          />
          {statusRate}
        </Typography>
      ) : null,
    },
    {
      title: 'Tasa Confirming',
      hidden: !hasConfirmingEnabled,
      content: defaultConfirmingRate ? (
        <Typography color="textSecondary" variant="body1">
          <CountryFormatHelper
            value={defaultConfirmingRate}
            variant="percentage"
            countryId={country}
          />
        </Typography>
      ) : null,
    },
    {
      title: 'Empresa en un día',
      content: getIcon(hasIsOneDay),
    },
    {
      title: 'PFX',
      content: getIcon(hasPfxCertificate),
    },
    {
      title: 'Contribuyente autorizado',
      content: getIcon(hasTaxUserActive),
    },
    {
      title: 'Confirming',
      content: fetchEntityFeaturesIsLoading ? (
        <CircularProgress size={25} />
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={confirmingFeature}
              onClick={handleConfirmingStatus}
              size="small"
              color="primary"
              name="checkedConfirming"
            />
          }
          label={confirmingFeature ? 'Activo' : 'Inactivo'}
        />
      ),
    },
    {
      title: (
        <Grid container alignItems="center">
          <Typography color="textPrimary" variant="subtitle2" component="div">
            <Box fontWeight="fontWeightBold">Auto Tasa</Box>
          </Typography>
          <Tooltip title="Al activar esta opción esta operación aplicará el modelo de tasas basado en el nivel de riesgo de cada factura.">
            <span>
              <HelpIcon fontSize="inherit" className={classes.help} />
            </span>
          </Tooltip>
        </Grid>
      ),
      content:
        fetchEntityFeaturesIsLoading || updatedEntityFeatureIsLoading ? (
          <CircularProgress size={25} />
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={autoRate}
                onClick={canUpdateAutoRate ? handleAutoRateChange : null}
                disabled={!canUpdateAutoRate}
                size="small"
                color="primary"
                name="checkedAutoRate"
              />
            }
            label={autoRate ? 'Activo' : 'Inactivo'}
          />
        ),
    },
    {
      title: 'Correo de intercambio',
      content: (
        <Typography color="textSecondary" variant="body1">
          {exchangeEmail}
        </Typography>
      ),
    },
  ];

  return (
    <Grid item xs={12}>
      <Grid container>
        {infoBusiness
          .filter(({ hidden }) => !hidden)
          .map(infoCard => {
            const { title, content, icon } = infoCard;
            const isSpecialCase = SPECIAL_CASES.some(
              specialCase => specialCase === title
            );
            return (
              <Box
                className={
                  isSpecialCase
                    ? classes.containerItemsSizeLarge
                    : classes.containerItemsSizeStandar
                }
              >
                <Typography variant="body2" color="textPrimary" component="div">
                  <Box
                    fontWeight="fontWeightBold"
                    className={classes.elementTitle}
                  >
                    <Box className={classes.spacingRight}>{title}</Box>
                    {icon || null}
                  </Box>
                </Typography>
                {content !== null ? (
                  content
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    -
                  </Typography>
                )}
              </Box>
            );
          })}
      </Grid>

      <Grid container className={classes.containerButtons}>
        <Grid item>
          {!hasPfxCertificate ? (
            <Can
              perform={BUSINESS_UPLOAD_PFX_PERFORM}
              yes={() => (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<UploadIcon />}
                  onClick={() => setShowPfxDialog(true)}
                  className={classes.infoBusinessButtons}
                >
                  PFX
                </Button>
              )}
            />
          ) : null}
        </Grid>
        <Grid item>
          <Can
            perform={BUSINESS_EDIT_FEE_PERFORM}
            yes={() => (
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => {
                  dispatch(resetBusinessFees());
                  setShowEditRatesDialog(true);
                }}
                className={classes.infoBusinessButtons}
              >
                Editar fees
              </Button>
            )}
          />
        </Grid>
        <Grid item>
          <Can
            perform={BUSINESS_TAX_FOLDER_PERFORM}
            yes={() => (
              <LoadingButton
                variant="contained"
                color="primary"
                isLoading={checkTaxFolderIsLoading}
                disabled={!hasTaxFolder}
                startIcon={<FolderIcon />}
                onClick={() => setShowTaxFolderDialog(true)}
                className={classes.infoBussinessButtons}
              >
                Tributaria
              </LoadingButton>
            )}
          />
        </Grid>
      </Grid>

      {showPfxDialog && (
        <PfxUploadDialog
          open={showPfxDialog}
          handleClose={() => setShowPfxDialog(false)}
          handleSubmit={({ pfx, password }) => {
            dispatch(registerPfx({ businessId, pfx, password }));
          }}
        />
      )}

      {showEditRatesDialog && (
        <EditRatesDialog
          open={showEditRatesDialog}
          handleCloseDialog={() => setShowEditRatesDialog(false)}
          business={business}
          handleSubmit={handleSubmitFees}
          handleReset={handleResetFees}
          loading={updateFeesLoading}
          error={updateFeesError}
        />
      )}

      {showComplianceDialog && (
        <ComplianceDialog
          open={showComplianceDialog}
          handleCloseDialog={handleCloseDialogs}
          handleSubmit={handleConsultCompliance}
          complianceInformation={{ ...businessCompliance, name: businessName }}
        />
      )}

      {showDebtServiceDialog && (
        <DebtServiceDialog
          saving={saving}
          open={showDebtServiceDialog}
          handleCloseDialog={() => setShowDebtServiceDialog(false)}
          handleSubmit={identifier =>
            dispatch(consultDebtService(businessId, BUSINESS, identifier))
          }
          debtServiceData={{
            name: businessName,
            reloadId: businessId,
            ...debtService,
          }}
        />
      )}

      {showTaxFolderDialog && (
        <TaxFolderDialog
          open={showTaxFolderDialog}
          handleCloseDialog={() => setShowTaxFolderDialog(false)}
          businessId={businessId}
        />
      )}

      {showDebtSuperDialog && (
        <DebtSuperDialog
          open={showDebtSuperDialog}
          handleCloseDialog={() => setShowDebtSuperDialog(false)}
          handleSubmit={businessIdentifier =>
            dispatch(consultDebtSuper(businessIdentifier))
          }
          debtSuperData={{ ...debtSuper, businessName }}
        />
      )}
    </Grid>
  );
};

BusinessDetailCL.propTypes = {
  business: PropTypes.shape({
    defaultConfirmingRate: PropTypes.number.isRequired,
    defaultRates: PropTypes.bool.isRequired,
    exchangeEmail: PropTypes.string.isRequired,
    hasAutoRate: PropTypes.bool.isRequired,
    hasConfirmingEnabled: PropTypes.bool.isRequired,
    hasIsOneDay: PropTypes.bool.isRequired,
    hasMarketInvoicer: PropTypes.bool.isRequired,
    hasPfxCertificate: PropTypes.bool.isRequired,
    hasTaxUserActive: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
    retention: PropTypes.number.isRequired,
  }).isRequired,
  handleTgrClick: PropTypes.string.isRequired,
};

export default BusinessDetailCL;
