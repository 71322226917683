import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const fetcher = mainConf => {
  const http = axios.create({
    timeout: 45000,
    ...mainConf,
  });

  return {
    get: (url, config) => http.get(url, config),
    post: (url, body, config) => http.post(url, body, config),
    put: (url, body, config) => http.put(url, body, config),
    patch: (url, body, config) => http.patch(url, body, config),
    delete: (url, config) => http.delete(url, config),
  };
};
