import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState, memo } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector, shallowEqual } from 'react-redux';

import { settings } from '../../config/settings';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';

const useStyles = makeStyles({
  subTitle: {
    marginBottom: '20px',
  },
});
const EditRateDialog = ({
  error,
  handleCloseDialog,
  handleReset,
  handleSubmit: handleSave,
  loading,
  open,
  subTitle,
  title,
  label,
  value,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config, shallowEqual);
  const {
    rate: { minRate, maxRate },
  } = settings[country];
  const warningMsg = `¡Ojo! La tasa supera el ${maxRate}%.`;
  const [checked, setChecked] = useState(false);
  const [checkedReset, setCheckedReset] = useState(false);
  const [rate, setRate] = useState(value || '');
  const [validations, setValidations] = useState({
    rateValidator: ['required', `minNumber:${minRate}`],
    rateErrorMessage: ['Campo requerido', `Tasa mínima ${minRate}%`],
  });

  useEffect(() => {
    setChecked(false);
    setCheckedReset(false);
  }, [setChecked, setCheckedReset]);

  useEffect(() => {
    if (!open) {
      setChecked(false);
      setCheckedReset(false);
      setRate('');
    }
    if (open && value) {
      setRate(value);
    }
  }, [value, open]);

  const handleCheckboxChange = type => {
    if (type === 'reset') {
      setCheckedReset(!checkedReset);
      setChecked(false);
      setValidations({
        rateValidator: [],
        rateErrorMessage: [],
      });
    } else {
      setChecked(!checked);
      setCheckedReset(false);
      setValidations({
        rateValidator: ['required', `minNumber:${minRate}`],
        rateErrorMessage: ['Campo requerido', `Tasa mínima ${minRate}%`],
      });
    }
  };

  const handleSubmit = () => {
    return checked ? handleSave(rate) : handleReset();
  };

  return (
    <BaseDialog isOpen={open} handleClose={handleCloseDialog} title={title}>
      <Typography
        className={classes.subTitle}
        variant="body1"
        color="textPrimary"
        align="center"
        component="div"
      >
        {subTitle()}
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          fullWidth
          variant="outlined"
          label={label}
          type="number"
          inputProps={{ min: minRate, step: 0.0001 }}
          id="rate"
          name="rate"
          aria-describedby="tasaFondo"
          value={rate}
          onChange={e => {
            setRate(e.target.value);
          }}
          validators={validations.rateValidator}
          errorMessages={validations.rateErrorMessage}
        />

        {error && <AlertForm message={error} variant="error" />}
        {rate > maxRate && <AlertForm message={warningMsg} variant="warning" />}
        <Box mb={2} mt={2}>
          <CheckButton
            check={checked}
            handleCheck={handleCheckboxChange}
            labelButton="Guardar"
            loading={loading}
            disabled={!rate}
          />
        </Box>
      </ValidatorForm>
    </BaseDialog>
  );
};
EditRateDialog.defaultProps = {
  error: '',
};
EditRateDialog.propTypes = {
  error: PropTypes.string,
  subTitle: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  label: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(EditRateDialog);
