import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import BaseDialog from './BaseDialog';
import { MESSAGE_FIELD_NOT_EMPTY } from '../../helpers/Constants';
import LoadingButton from '../elements/LoadingButton';
import AlertForm from '../elements/AlertForm';

const useStyles = makeStyles({
  validatorFormPaddingTop: {
    paddingTop: 5,
  },
  buttonSpacing: {
    marginTop: 15,
  },
});

const UpdateUserDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  userInformation,
}) => {
  const classes = useStyles();
  const { updateUserIsLoading, updateUserError } = useSelector(
    state => state.user
  );
  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    phone: '',
    email: '',
  });

  useEffect(() => {
    const { name, phone, email } = userInformation;
    setPersonalInfo({
      name,
      phone,
      email,
    });
  }, [userInformation]);

  useEffect(() => {
    ValidatorForm.addValidationRule('phoneValidation', value => {
      const regexPhone = /^[0-9]+$/;
      return regexPhone.test(value.trim());
    });
  });

  const handleBuildSubmit = () => {
    const { id } = userInformation;
    const { name, phone, email } = personalInfo;
    const buildPersonalInformation = {
      name: name.trim(),
      phone: phone.trim(),
      email: email.trim(),
    };
    handleSubmit(buildPersonalInformation, id);
  };

  const setStatePersonalInfo = e => {
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <BaseDialog
      title="Editar usuario"
      isOpen={open}
      handleClose={handleCloseDialog}
    >
      <ValidatorForm
        onSubmit={handleBuildSubmit}
        className={classes.validatorFormPaddingTop}
      >
        <TextValidator
          fullWidth
          variant="outlined"
          label="Nombre y apellido"
          type="string"
          id="name"
          name="name"
          aria-describedby="name"
          value={personalInfo.name}
          onChange={e => setStatePersonalInfo(e)}
          validators={['required', 'trim']}
          errorMessages={['Campo requerido', MESSAGE_FIELD_NOT_EMPTY]}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          label="Teléfono"
          type="string"
          id="phone"
          name="phone"
          aria-describedby="phone"
          value={personalInfo.phone}
          onChange={e => setStatePersonalInfo(e)}
          validators={['required', 'phoneValidation']}
          errorMessages={[
            'Campo requerido',
            'Ingrese un número telefónico valido (solo números)',
          ]}
        />

        <TextValidator
          fullWidth
          variant="outlined"
          label="Email"
          type="string"
          id="email"
          name="email"
          aria-describedby="email"
          value={personalInfo.email}
          onChange={e => setStatePersonalInfo(e)}
          validators={['required', 'isEmail']}
          errorMessages={['Campo requerido', 'Email incorrecto']}
        />

        {updateUserError && (
          <AlertForm message={updateUserError} variant="error" />
        )}

        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          isLoading={updateUserIsLoading}
          disabled={updateUserIsLoading}
          className={classes.buttonSpacing}
        >
          Guardar
        </LoadingButton>
      </ValidatorForm>
    </BaseDialog>
  );
};

UpdateUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userInformation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpdateUserDialog;
