import AddBankAccountDrawerStore from './AddBankAccountDrawerStore';

const useIsShownAddBankAccountDrawer = () =>
  AddBankAccountDrawerStore(state => state.isShown);

const useShowAddBankAccountDrawer = () =>
  AddBankAccountDrawerStore(state => state.show);

const useHideAddBankAccountDrawer = () =>
  AddBankAccountDrawerStore(state => state.hide);

export {
  useIsShownAddBankAccountDrawer,
  useShowAddBankAccountDrawer,
  useHideAddBankAccountDrawer,
};
