import React from 'react';
import { Box, Typography, TypographyProps } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const fontWeights: { [key: string]: string } = {
  bold: 'fontWeightBold',
  medium: 'fontWeightMedium',
  regular: 'fontWeightRegular',
  semibold: 'fontWeightsSemibold',
};

interface IText extends TypographyProps {
  fontWeight?: string;
  isLoading?: boolean;
}

const Text = React.memo(
  ({
    variant = 'body1',
    color = 'textPrimary',
    className,
    fontWeight = 'regular',
    isLoading = false,
    children,
    align,
  }: IText) => {
    const fontWeightSelected = fontWeights[fontWeight] ?? fontWeights.regular;

    return (
      <Typography
        variant={variant}
        color={color}
        className={className}
        component="div"
        align={align}
      >
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Box fontWeight={fontWeightSelected}>{children}</Box>
        )}
      </Typography>
    );
  }
);

export default Text;
