import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, styled } from '@material-ui/core';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import BaseDialog from '../../../../components/dialogs/BaseDialog';
import LoadingButton from '../../../../components/elements/LoadingButton';
import AlertMessage from '../AlertMessage';
import { Text } from '../../formatters';

const AlertGrid = styled(Grid)({
  marginBottom: 15,
});

const ChangeIsHoldDialog = ({
  open,
  handleClose,
  handleSubmit,
  isLoading,
  handleResetError,
  error,
}) => {
  const [isHold, setIsHold] = useState(true);
  const { t } = useTranslation();

  const messageComponent = (
    <Grid container alignContent="center" justifyContent="center">
      <Text fontWeight="bold" color="error.main">
        {t(error)}
      </Text>
    </Grid>
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      title="Editar tipo factura"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <SelectValidator
          variant="outlined"
          label="Tipo factura"
          value={isHold}
          name="isHold"
          onChange={e => setIsHold(e.target.value)}
          fullWidth
          validators={['required']}
          errorMessages={['Campo requerido']}
          disabled={false}
        >
          <MenuItem key="temporaryInvoice" value>
            Temporal
          </MenuItem>
          <MenuItem key="definitiveInvoice" value={false}>
            Definitiva
          </MenuItem>
        </SelectValidator>
        {error && (
          <AlertGrid container grid xs={12}>
            <AlertMessage
              onClear={handleResetError}
              messageComponent={messageComponent}
            />
          </AlertGrid>
        )}
        <Grid item container justifyContent="center">
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading || isHold}
            isLoading={isLoading}
          >
            Guardar Cambios
          </LoadingButton>
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeIsHoldDialog.defaultProps = {
  isLoading: false,
  handleResetError: () => undefined,
  error: null,
};

ChangeIsHoldDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleResetError: PropTypes.func,
  error: PropTypes.string,
};

export default ChangeIsHoldDialog;
