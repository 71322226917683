import { gql } from 'graphql-request';

export const mutation = gql`
  mutation createTransitionAction($dto: CreateTransitionActionDTO!) {
    createTransitionAction(dto: $dto) {
      id
      entityId
      origin {
        name
      }
      workflow {
        id
      }
    }
  }
`;

export type Mutation = {
  createTransitionAction: {
    id: string;
    entityId: string;
    origin: {
      name: string;
    };
    workflow: {
      id: string;
    };
  };
};
