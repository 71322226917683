import { createActions } from '../../../../commons/utils';

export const CLEAN_PAYROLL_SELECTOR = createActions(
  'OP:CLEAN_PAYROLL_SELECTOR'
);
export const PICK_RECEIVER = createActions('OP:PICK_RECEIVER');
export const DELETE_RECEIVERS = createActions('OP:DELETE_RECEIVERS');
export const UPDATE_PAYROLL_SELECTOR = createActions(
  'OP:UPDATE_PAYROLL_SELECTOR'
);

export const PICK_DRAFT_RECEIVER = createActions('OP:PICK_DRAFT_RECEIVER');
export const DELETE_DRAFT_RECEIVERS = createActions(
  'OP:DELETE_DRAFT_RECEIVERS'
);
export const CLEAN_DRAFT_PAYROLL_SELECTOR = createActions(
  'OP:CLEAN_DRAFT_PAYROLL_SELECTOR'
);

export const SELECT_FUND = createActions('OP:SELECT_FUND');
export const SET_SIMULATION = createActions('OP:SET_SIMULATION');
export const CLEAN_SIMULATION = createActions('OP:CLEAN_SIMULATION');
export const REMOVE_SELECTED_FUND = createActions('OP:REMOVE_SELECTED_FUND');
