import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { differenceInDays } from 'date-fns';

const useStyles = makeStyles({
  green: {
    color: '#75B801',
  },
  yellow: {
    color: '#FFB100',
  },
  orange: {
    color: '#FF7F00',
  },
  red: {
    color: '#D92F24',
  },
});

type RangeDaysPastDueProps = { date: string };
export const RangeDaysPastDue = ({ date }: RangeDaysPastDueProps) => {
  const expirationDate = moment(date);
  const debtDays = moment().diff(expirationDate, 'days');
  let debtDaysText = '>90';
  if (debtDays <= 0) {
    debtDaysText = 'Vigente';
  } else if (debtDays > 0 && debtDays <= 30) {
    debtDaysText = '0-30';
  } else if (debtDays > 30 && debtDays <= 60) {
    debtDaysText = '30-60';
  } else if (debtDays > 60 && debtDays <= 90) {
    debtDaysText = '60-90';
  }
  return (
    <Typography color="textSecondary" variant="body1" component="div">
      <Box fontWeight="fontWeightBold">{debtDaysText}</Box>
    </Typography>
  );
};

type DaysPastDueProps = { expirationDate: Date };
export const DaysPastDue = ({ expirationDate }: DaysPastDueProps) => {
  const classes = useStyles();
  const diffDays = getDiffDatys(expirationDate);

  let className = classes.red;

  if (diffDays <= -8) {
    className = classes.green;
  } else if (diffDays < 0 && diffDays > -8) {
    className = classes.yellow;
  } else if (diffDays >= 0 && diffDays < 30) {
    className = classes.orange;
  } else if (diffDays >= 30) {
    className = classes.red;
  }

  return (
    <Typography variant="body1" component="div" className={className}>
      <Box fontWeight="fontWeightBold">{diffDays}</Box>
    </Typography>
  );
};

const getDiffDatys = (expirationDate: Date): number => {
  const nowDate = new Date();
  const isInArrears = nowDate > expirationDate;
  const leftDate = isInArrears ? expirationDate : nowDate;
  const rightDate = isInArrears ? nowDate : expirationDate;

  const diffDays = differenceInDays(leftDate, rightDate);

  return isInArrears ? -diffDays : diffDays - 1;
};
