import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateBankAccountService } from '../services';

export const useUpdateBankAccount = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isLoading: bankAccountIsUpdating,
    isSuccess: updateBankAccountIsSuccess,
    error: updateBankAccountError,
    mutate: updateBankAccount,
    reset: resetBankAccountUpdateData,
  } = useMutation(
    ({ source, sourceId, bankAccount, bankAccountId }) => {
      return updateBankAccountService({
        source,
        sourceId,
        bankAccount,
        bankAccountId,
      });
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BANK_ACCOUNT_UPDATED, {
            variant: 'success',
          })
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries('getBusinessBankAccounts', {
          exact: false,
        });
      },
    }
  );

  return {
    bankAccountIsUpdating,
    updateBankAccountIsSuccess,
    updateBankAccountError,
    updateBankAccount,
    resetBankAccountUpdateData,
  };
};

export default useUpdateBankAccount;
