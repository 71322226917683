import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  AppBar,
  Tooltip,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import moment from 'moment';
import PropTypes from 'prop-types';
import { downloadSimulationPdf } from '../../actions/orderActions';
import FacturasSimpleTable from './FacturasSimpleTable';
import LogoSVG from '../../assets/logo-horizontal-blanco.svg';
import CountryFormatHelper from './CountryFormatHelper';
import { dateFormatter } from '../../helpers/DateUtils';

const useStyles = makeStyles(theme => ({
  containerSimulationVoucher: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
  },
  appBar: {
    background:
      'transparent linear-gradient(270deg, #1A32E7 0%, #6394F0 100%) 0% 0% no-repeat padding-box;',
  },
  paddedContainer: {
    padding: '28px 24px 0 30px',
  },
  iconButtons: {
    '& svg': {
      fontSize: 22,
    },
  },
  iconButtonMargin: {
    marginRight: 25,
  },
  containerToolbar: {
    padding: '0px 30px',
  },
  marginBottom32: {
    marginBottom: 32,
  },
  containerDetailOperation: {
    padding: '0px 30px 0px 30px',
  },
  containerTotalAmount: {
    marginBottom: 17,
    width: 230,
  },
  containerDetailData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerGenericDetailData: {
    width: 230,
    paddingLeft: 3,
    marginBottom: 8,
  },
  footerContainer: {
    padding: '60px 30px 15px 30px',
    marginTop: 'auto',
  },
  footerText: {
    color: theme.palette.grey[600],
    paddingLeft: 3,
  },
}));

const SimulationVoucher = ({ isOpen, order, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const country = useSelector(state => state.config.country);
  const {
    createdAt,
    OrderDetails: [orderDetail],
    transferAmount,
  } = order;

  const {
    retencion: retention,
    tasaBase: baseRate,
    issuedDate,
    operationCost,
    interes,
  } = orderDetail;

  const dataCountry = {
    CL: {
      interest: interes,
      totalAmount: transferAmount,
    },
    MX: {
      interest: interes,
      totalAmount: transferAmount,
    },
  };

  const detailData = [
    {
      label: 'Retención',
      data: (
        <CountryFormatHelper
          value={retention}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Intereses',
      data: (
        <CountryFormatHelper
          value={dataCountry[country].interest}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Asesoría Digital',
      data: (
        <CountryFormatHelper
          value={operationCost}
          variant="currency"
          countryId={country}
        />
      ),
    },
    {
      label: 'Tasa base:',
      data: (
        <CountryFormatHelper
          value={baseRate}
          variant="percentage"
          countryId={country}
        />
      ),
    },
  ];

  const handleClickDownloadButton = () => {
    dispatch(downloadSimulationPdf(order.id));
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
      <Grid item xs={12} className={classes.containerSimulationVoucher}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar className={classes.containerToolbar}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" noWrap component="div">
                  <Box fontWeight="fontWeightBold">Simulación de operación</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={<Typography variant="body1">Descargar PDF</Typography>}
                >
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleClickDownloadButton}
                    className={classes.iconButtons}
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleCloseDrawer}
                  className={classes.iconButtons}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="row"
          className={[classes.paddedContainer, classes.marginBottom32]}
        >
          <Grid item xs={12} className={classes.marginBottom32}>
            <Typography variant="subtitle1" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">
                {`Fecha de la simulación: 
            ${dateFormatter(new Date(issuedDate || createdAt))}`}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FacturasSimpleTable />
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.containerDetailOperation}
          justify="space-between"
          direction="column"
          alignItems="flex-end"
        >
          <Grid item className={classes.containerTotalAmount}>
            <Grid xs={12} className={classes.containerDetailData}>
              <Typography color="textPrimary" variant="h6" component="div">
                <Box fontWeight="fontWeightBold">Total a girar:</Box>
              </Typography>
              <Typography color="textPrimary" variant="h6" component="div">
                <Box fontWeight="fontWeightBold">
                  <CountryFormatHelper
                    value={dataCountry[country].totalAmount}
                    variant="currency"
                    countryId={country}
                  />
                </Box>
              </Typography>
            </Grid>
          </Grid>

          {detailData.map(item => {
            const { label, data } = item;
            return (
              <Grid
                item
                className={classes.containerGenericDetailData}
                key={label}
              >
                <Grid xs={12} className={classes.containerDetailData}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">{label}</Box>
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body2"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">{data}</Box>
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid container className={classes.footerContainer}>
          <Grid item xs={12}>
            <img src={LogoSVG} alt="logo-Xepelin" />
          </Grid>
          <Grid item xs={12} className={classes.footerText}>
            <Typography variant="subtitle1">
              {`Fecha emisión: ${dateFormatter(moment())}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

SimulationVoucher.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  order: PropTypes.objectOf(Object).isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
};

export default SimulationVoucher;
