import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  InputAdornment,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { EyeOpen, EyeClose } from '../icons';
import {
  SECOND_WHITE_COLOR,
  DATE_FORMAT,
  ERROR_ITEM_SELECTED,
} from '../../helpers/Constants';
import * as DatesUtils from '../../helpers/DateUtils';
import RolesSelector from './RolesSelector';
import loading from '../../assets/loading.gif';
import { resetUserError } from '../../actions/userActions';
import AlertForm from './AlertForm';
import { enqueueSnackbar } from '../../actions/notificationAction';

const useStyles = makeStyles({
  dialog: {
    background: SECOND_WHITE_COLOR,
    borderRadius: '17px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogTitle: {
    marginBottom: 10,
  },
  dialogSubtitle: {
    marginBottom: 20,
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    padding: '10px 50px',
  },
  copyButton: {
    marginLeft: 20,
  },
  buttonSubmit: {
    marginBottom: 10,
  },
});

const UserFormDialog = ({
  open,
  handleCloseDialog,
  handleSubmitForm,
  resetPassword,
  user,
  showLoader,
  changeUserInformationDialog,
}) => {
  const {
    nombre: firstNameExternalUser,
    apellido: secondNameExternalUser,
    email: emailExternalUser,
    telefono: phoneExternalNumberUser,
  } = user || {};
  const {
    firstName: firstNameUser = firstNameExternalUser || '',
    lastName: lastNameUser = secondNameExternalUser || '',
    birthdate: birthdateUser,
    email: emailUser = emailExternalUser || '',
    roles: rolesUser = [],
    phoneNumber: phoneNumberUser = phoneExternalNumberUser || '',
  } = user || {};
  const dispatch = useDispatch();
  const { userError } = useSelector(state => state.user);
  const [firstName, setFirstName] = useState(firstNameUser);
  const [lastName, setLastName] = useState(lastNameUser);
  const [birthdate, setBirthdate] = useState(birthdateUser);
  const [email, setEmail] = useState(emailUser);
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberUser);
  const [roles, setRoles] = useState(rolesUser);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  ValidatorForm.addValidationRule('isEqual', () => {
    const validation =
      password && confirmPassword && password === confirmPassword;
    if (validation) return true;
    return false;
  });
  ValidatorForm.addValidationRule('itemSelected', () => {
    if (roles?.length) return true;
    return false;
  });

  const handleInputChange = (event, setState) => {
    const {
      target: { value },
    } = event;
    // eslint-disable-next-line no-unused-expressions
    value.trim().length ? setState(value) : setState(null);
  };

  const handleWarning = message => {
    dispatch(enqueueSnackbar(message, { variant: 'warning' }));
  };
  const getInputs = () => {
    return (
      <>
        {changeUserInformationDialog && (
          <>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Nombre"
              type="string"
              name="firstName"
              aria-describedby="Nombre"
              value={firstName}
              onChange={e => handleInputChange(e, setFirstName)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Apellido"
              name="lastName"
              aria-describedby="Apellido"
              value={lastName}
              onChange={e => handleInputChange(e, setLastName)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Telefono"
              name="phoneNumber"
              aria-describedby="Telefono"
              value={phoneNumber}
              onChange={e => handleInputChange(e, setPhoneNumber)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              aria-describedby="Email"
              value={email}
              onChange={e => handleInputChange(e, setEmail)}
              validators={['required', 'isEmail']}
              errorMessages={['Campo requerido', 'Email incorrecto']}
            />
          </>
        )}
        {!resetPassword && !changeUserInformationDialog && (
          <>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Nombre o Razón social"
              type="string"
              name="firstName"
              aria-describedby="Nombre o Razón social"
              value={firstName}
              onChange={e => handleInputChange(e, setFirstName)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Apellido"
              name="lastName"
              aria-describedby="Apellido"
              value={lastName}
              onChange={e => handleInputChange(e, setLastName)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            />
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <DatePicker
                label="Fecha de nacimiento"
                name="birthdate"
                inputVariant="outlined"
                fullWidth
                format={DATE_FORMAT}
                value={birthdate ? moment(birthdate).utc() : null}
                onChange={e => setBirthdate(e)}
                minDateMessage="Fecha inválida"
                autoOk
              />
            </MuiPickersUtilsProvider>
          </>
        )}
        {(!user || resetPassword) && !changeUserInformationDialog ? (
          <>
            <TextValidator
              fullWidth
              variant="outlined"
              // eslint-disable-next-line no-return-assign
              label="Contraseña nueva"
              name="password"
              aria-describedby="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => {
                setConfirmPassword(confirmPassword);
                handleInputChange(e, setPassword);
              }}
              validators={['required', 'minStringLength:5']}
              errorMessages={[
                'Campo requerido',
                'La contraseña debe tener 5 o más caracteres',
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <EyeClose /> : <EyeOpen />}
                      </IconButton>
                    </>
                  </InputAdornment>
                ),
              }}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Confirma tu nueva contraseña"
              name="confirmPassword"
              id="confirmPassword"
              aria-describedby="Confirmación de Password"
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={e => handleInputChange(e, setConfirmPassword)}
              validators={['required', 'isEqual', 'minStringLength:5']}
              errorMessages={[
                'Campo requerido',
                'Las contraseñas no coinciden',
                'La contraseña debe tener 5 o más caracteres',
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <EyeClose /> : <EyeOpen />}
                      </IconButton>
                    </>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : null}

        {!resetPassword && !changeUserInformationDialog && (
          <>
            <TextValidator
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              aria-describedby="Email"
              value={email}
              onChange={e => handleInputChange(e, setEmail)}
              validators={['required', 'isEmail']}
              errorMessages={['Campo requerido', 'Email incorrecto']}
            />
            <RolesSelector
              handleSelectedRole={setRoles}
              userRoles={roles}
              handleWarning={handleWarning}
              isNew={!user}
              validators={['itemSelected']}
              errorMessages={[ERROR_ITEM_SELECTED]}
            />
          </>
        )}
      </>
    );
  };

  const handleSubmit = () => {
    const { id } = user || {};
    const birthdateUser = birthdate
      ? DatesUtils.setHourString(birthdate)
      : undefined;
    const data = {
      id,
      firstName,
      lastName,
      birthdate: birthdateUser,
      email,
      roles,
      password,
      confirmPassword,
    };
    if (changeUserInformationDialog) data.phoneNumber = phoneNumber;
    handleSubmitForm(data);
  };

  const handleClose = () => {
    dispatch(resetUserError());
    handleCloseDialog();
  };
  const classes = useStyles();
  let title = 'Nuevo usuario';
  const subTitle = `Vas a cambiar la contraseña para: ${
    firstNameUser && firstNameUser
  } ${lastNameUser && lastNameUser}`;

  if (resetPassword) {
    title = 'Cambiar contraseña';
  } else if (user) {
    title = 'Editar usuario';
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        className: classes.dialog,
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container direction="column" align="center" justify="center">
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h5"
            color="textPrimary"
            component="div"
            className={classes.dialogTitle}
          >
            <Box fontWeight="fontWeightBold">{!showLoader && title}</Box>
          </Typography>
          {resetPassword && (
            <Typography
              variant="subtitle1"
              className={classes.dialogSubtitle}
              align="center"
              color="textPrimary"
            >
              {!showLoader && subTitle}
            </Typography>
          )}
        </Grid>
      </Grid>
      <DialogContent>
        <Grid item xs={12}>
          {!showLoader && (
            <ValidatorForm onSubmit={() => handleSubmit()}>
              {getInputs()}
              {userError && <AlertForm message={userError} variant="error" />}
              <Grid className={classes.justifyCenter}>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Guardar
                </Button>
              </Grid>
            </ValidatorForm>
          )}
          {showLoader && (
            <Grid item xs={12} className={classes.justifyCenter}>
              <img src={loading} alt="loader" />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

UserFormDialog.defaultProps = {
  user: null,
  changeUserInformationDialog: false,
};

UserFormDialog.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(Object),
  resetPassword: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  changeUserInformationDialog: PropTypes.bool,
};

export default UserFormDialog;
