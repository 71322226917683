import {
  FEES_CALCULATE_START,
  FEES_CALCULATE_SUCCESS,
  FEES_CALCULATE_ERROR,
} from '../actions/types';

const initialState = {
  fees: {},
  feesLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FEES_CALCULATE_START:
      return {
        ...state,
        feesLoading: true,
      };
    case FEES_CALCULATE_SUCCESS:
      return {
        ...state,
        fees: payload,
        feesLoading: false,
      };
    case FEES_CALCULATE_ERROR:
      return {
        ...state,
        feesLoading: false,
      };
    default:
      return state;
  }
};
