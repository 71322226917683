import { purgeNestedObj } from '../../../../commons/utils';

export const updateDocumentUseCase = ({
  currentSelectorState,
  newValues,
  selectedReceiverIdentifier,
  selectedDocumentId,
}) => {
  const { rate, advancePercentage, issuerDebt, expirationDate } = newValues;
  const valuesToUpdate = purgeNestedObj({
    rate,
    effectiveFundCostRate: rate,
    advancePercentage,
    expirationDate,
    issuer: { debtServiceAmount: issuerDebt },
  });

  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    if (selectedReceiverIdentifier !== receiverIdentifier) {
      acc[receiverIdentifier] = { ...currentReceiver };

      return acc;
    }

    acc[receiverIdentifier] = {
      ...currentReceiver,
      value: currentReceiver.value.map(doc => {
        if (doc.id !== selectedDocumentId) {
          return doc;
        }

        return { ...doc, ...valuesToUpdate };
      }),
    };

    return acc;
  }, {});
};
