import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles, CircularProgress, Box } from '@material-ui/core';
import Chip from './Chip';
import CountryFormatHelper from '../CountryFormatHelper';
import {
  AVAILABLE_COUNTRIES,
  CURRENCY,
  DISCOUNTS,
  ORDER_TYPES_LABEL,
} from '../../../helpers/Constants';

const useStyles = makeStyles({
  tooltipItem: {
    display: 'block',
  },
  loading: {
    marginRight: 10,
    marginTop: 10,
  },
});

const InvoiceAmountChip = props => {
  const {
    amount,
    discounts,
    isClaimed,
    isCancelled,
    amountCreditNotes,
    country,
    orderType,
    currency,
    isLoading,
    size,
    ...otherProps
  } = props;

  const classes = useStyles();

  const getVariant = () => {
    let variant = 'default';

    if (discounts.length && !amountCreditNotes) {
      variant = 'orange';
    } else if (amountCreditNotes || isClaimed || isCancelled) {
      variant = 'red';
    }

    return variant;
  };

  const getTooltipTitle = () => {
    let discountTexts = '';
    if (discounts.length) {
      discountTexts = discounts.map(({ amount, reason: discountReason }) => {
        return (
          <>
            Descuento:{' '}
            <CountryFormatHelper
              value={amount}
              countryId={country}
              variant="currency"
            />
            {discountReason ? (
              <span>{` | ${
                DISCOUNTS.find(
                  discount =>
                    ['CHECK_INVOICE_DISCOUNT', 'BASE_DISCOUNT'].includes(
                      discount.type
                    ) &&
                    discount.country.includes(country) &&
                    discount.value === discountReason
                )?.label || discountReason
              }`}</span>
            ) : null}
          </>
        );
      });
    }
    let creditNotesText = '';
    if (amountCreditNotes) {
      creditNotesText = (
        <>
          Monto Nota de Crédito:{' '}
          <CountryFormatHelper
            value={amountCreditNotes}
            countryId={country}
            variant="currency"
          />
        </>
      );
    }

    let claimedText = '';
    if (isClaimed) {
      claimedText = 'Factura reclamada';
    }

    let canceledText = '';
    if (isCancelled) {
      canceledText = 'Factura cancelada';
    }

    const finalTitle = [
      ...discountTexts,
      creditNotesText,
      claimedText,
      canceledText,
    ]
      .map(txt => {
        return txt ? <span className={classes.tooltipItem}>{txt}</span> : null;
      })
      .filter(txt => txt);

    return finalTitle.length ? <>{finalTitle}</> : '';
  };

  const getValue = () => {
    // Frozen in Ops usd
    // if (
    //   country === COUNTRY_CODE_MX &&
    //   currency === CURRENCY.US &&
    //   orderType !== CONFIRMING
    // ) {
    //   return (
    //     <div>
    //       {currency} &nbsp;
    //       <CountryFormatHelper
    //         countryId={country}
    //         value={amount}
    //         variant="currency"
    //       />
    //     </div>
    //   );
    // }
    return (
      <CountryFormatHelper
        countryId={country}
        value={amount}
        variant="currency"
      />
    );
  };

  return (
    <Tooltip title={getTooltipTitle()} placement="top">
      <Box display="flex" fontWeight="fontWeightBold" justifyContent="center">
        <>
          {isLoading && (
            <CircularProgress size={15} className={classes.loading} />
          )}
          <Chip
            variant={getVariant()}
            label={getValue()}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
          />
        </>
      </Box>
    </Tooltip>
  );
};

InvoiceAmountChip.defaultProps = {
  discounts: [],
  isClaimed: false,
  isCancelled: false,
  amountCreditNotes: 0,
  isLoading: false,
  size: 'small',
  currency: null,
  orderType: null,
};

InvoiceAmountChip.propTypes = {
  amount: PropTypes.number.isRequired,
  country: PropTypes.oneOf([AVAILABLE_COUNTRIES]).isRequired,
  currency: PropTypes.objectOf(CURRENCY),
  orderType: PropTypes.objectOf(ORDER_TYPES_LABEL),
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      reason: PropTypes.string,
    })
  ),
  isClaimed: PropTypes.bool,
  isCancelled: PropTypes.bool,
  amountCreditNotes: PropTypes.number,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
};

export default InvoiceAmountChip;
