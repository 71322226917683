export const verifyMultipleEmails = emails => {
  if (!emails) return true;
  const validateEmail = value => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!regex.test(value);
  };

  for (let i = 0; i < emails.length; i++) {
    if (!validateEmail(String(emails[i]).toLowerCase())) {
      return false;
    }
  }
  return true;
};

export const getValidEmails = emails => {
  const data = [];
  if (!emails) return data;
  const validateEmail = value => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!regex.test(value);
  };

  for (let i = 0; i < emails.length; i++) {
    if (validateEmail(String(emails[i]).toLowerCase())) {
      data.push(emails[i]);
    }
  }
  return data;
};

export const verifyEmail = emailValue => {
  // Una implementación del Estandard Official: RFC 5322 (valida en el 99.99% de los emails existentes)
  const regexEmail =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  const secondRegexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const emailValidator = emailValue.includes('@')
    ? emailValue.split('@')
    : emailValue.split('.');
  const urlEmailValidator = emailValidator[1]?.split('.');
  if (
    !regexEmail.test(emailValue) ||
    !secondRegexEmail.test(emailValue) ||
    emailValidator[2]?.length > 3 ||
    urlEmailValidator[1]?.length > 3 ||
    urlEmailValidator.length > 3 ||
    emailValidator.length > 3
  ) {
    return false;
  }
  return true;
};
