import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MenuExpand = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M21 18.0001V20.0001H3V18.0001H21ZM17.404 3.90405L22 8.50005L17.404 13.0961L15.99 11.6821L19.172 8.50005L15.99 5.31805L17.404 3.90405ZM12 11.0001V13.0001H3V11.0001H12ZM12 4.00005V6.00005H3V4.00005H12Z" />
    </SvgIcon>
  );
};

export default MenuExpand;
