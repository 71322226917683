import React, { FC } from 'react';
import { Button, CircularProgress, ButtonProps } from '@material-ui/core';

interface Props extends ButtonProps {
  isLoading: boolean;
}

const LoadingButton: FC<Props> = ({ isLoading = false, children, ...rest }) => {
  return (
    <Button {...rest}>
      {isLoading ? <CircularProgress size={20} /> : children}
    </Button>
  );
};

export default LoadingButton;
