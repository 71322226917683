import { useQuery } from 'react-query';
import { fetchInvoiceAttachmentsInfoService } from '../services';

const useFetchInvoiceAttachmentsInfo = orderId => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    'getInvoiceAttachmentsInfo',
    () => fetchInvoiceAttachmentsInfoService(orderId),
    { refetchOnWindowFocus: false }
  );

  const { data: invoiceAttachmentsInfo } = data || { data: [] };

  return {
    invoiceAttachmentsInfo,
    isLoading,
    refetch,
    isFetching,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchInvoiceAttachmentsInfo };
