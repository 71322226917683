import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, IconButton, Menu } from '@material-ui/core';
import MaterialTable from 'material-table';
import { CREDITS_EDIT_PERFORM } from '../../helpers/performsType';
import Can from '../Can';
import { CREDIT_TYPES, CREDIT_PERIODS } from '../../helpers/Constants';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import { t13s } from '../../translationKeys';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import ChangeSimpleStatusDialog from '../dialogs/ChangeSimpleStatusDialog';
import { More as MoreIcon, Edit as EditIcon } from '../icons';
import MenuItem from '../elements/MenuItem';
import CreditDialog from '../dialogs/CreditDialog';
import StatusChip from '../elements/chips/StatusChip';
import { updateCredit } from '../../actions/creditsActions';

const CreditsTable = ({
  tableData,
  loadingCredits,
  handleChangePage,
  pagination,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { updateCreditIsLoading, updateCreditError, creditWasUpdated } =
    useSelector(state => state.credits);

  const [anchorEl, setAnchorEl] = useState(null);
  const [creditSelected, setCreditSelected] = useState({});
  const [showChangeCreditStatusDialog, setShowChangeCreditStatusDialog] =
    useState(false);
  const [showUpdateCreditDialog, setShowUpdateCreditDialog] = useState(false);

  useEffect(() => {
    if (creditWasUpdated) {
      setShowChangeCreditStatusDialog(false);
      setShowUpdateCreditDialog(false);
    }
  }, [creditWasUpdated]);

  const handleCreditStatusChange = creditData => {
    setCreditSelected(creditData);
    setShowChangeCreditStatusDialog(true);
  };

  const columns = [
    {
      title: 'ID Crédito',
      field: 'id',
      type: 'numeric',
      width: 30,
      render: rowData => {
        return (
          <Typography align="center" variant="body1" color="textPrimary">
            <Link
              disabled
              component={RouterLink}
              to={`/${country.toLowerCase()}/credits/${rowData.id}`}
            >
              {rowData.id}
            </Link>
          </Typography>
        );
      },
    },
    {
      title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
      field: 'identifier',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {rowData.identifier}
          </Typography>
        );
      },
    },
    {
      title: 'Nombre',
      field: 'name',
      type: 'string',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {rowData.name}
          </Typography>
        );
      },
    },
    {
      title: 'Monto',
      field: 'amount',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={rowData.amount}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Nº de cuotas',
      field: 'fees',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {rowData.fees}
          </Typography>
        );
      },
    },
    {
      title: 'Tasa',
      field: 'rate',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            <CountryFormatHelper
              value={rowData.rate}
              variant="percentage"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Periocidad',
      field: 'period',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {CREDIT_PERIODS[rowData.period]}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo',
      field: 'type',
      render: rowData => {
        return (
          <Typography variant="body1" color="textPrimary">
            {CREDIT_TYPES[rowData.type]}
          </Typography>
        );
      },
    },
    {
      title: 'Estado',
      field: 'status',
      name: 'status',
      type: 'string',
      lookup: {
        ACTIVE: 'Activo',
        PENDING: 'Pendiente',
        PAID: 'Finalizado',
      },
      render: rowData => (
        <StatusChip
          status={rowData.status}
          onClick={() => handleCreditStatusChange(rowData)}
          entityType="credit"
          size="small"
        />
      ),
    },
    {
      width: '50px',
      render: rowData => (
        <IconButton
          onClick={e => {
            setCreditSelected(rowData);
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <MaterialTable
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        columns={columns}
        data={tableData}
        isLoading={loadingCredits}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(1, rowsPerPage)}
        localization={{
          body: {
            emptyDataSourceMessage: 'No tenemos créditos para mostrar',
          },
          pagination: {
            labelRowsPerPage: 'Créditos por página',
            labelDisplayedRows: '{count} créditos totales',
          },
        }}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onChangePage={(e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page + 1, props.rowsPerPage)
              }
              count={pagination?.totalData}
              page={pagination?.page - 1}
            />
          ),
        }}
        options={{
          showTitle: false,
          filtering: false,
          search: false,
          toolbar: false,
          pageSize: pagination?.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
          emptyRowsWhenPaging: false,
        }}
      />
      <Menu
        id="menu-credit"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Can
          perform={CREDITS_EDIT_PERFORM}
          yes={() => (
            <MenuItem
              icon={<EditIcon />}
              onClick={() => {
                setShowUpdateCreditDialog(true);
                setAnchorEl(null);
              }}
            >
              Editar credito
            </MenuItem>
          )}
        />
      </Menu>

      {showUpdateCreditDialog && (
        <CreditDialog
          open={showUpdateCreditDialog}
          handleCloseDialog={() => {
            setShowUpdateCreditDialog(false);
          }}
          handleSubmit={formData =>
            dispatch(updateCredit(creditSelected?.id, formData))
          }
          selectedCredit={creditSelected}
          isLoading={updateCreditIsLoading}
          error={updateCreditError}
        />
      )}

      {showChangeCreditStatusDialog && (
        <ChangeSimpleStatusDialog
          open={showChangeCreditStatusDialog}
          status={creditSelected?.status}
          title="Cambiar estado del crédito"
          description="Selecciona el nuevo estado del crédito."
          entityType="credit"
          handleCloseDialog={() => {
            setShowChangeCreditStatusDialog(false);
          }}
          handleSubmit={newStatus =>
            dispatch(updateCredit(creditSelected?.id, { status: newStatus }))
          }
          error={updateCreditError}
          isLoading={updateCreditIsLoading}
        />
      )}
    </>
  );
};

CreditsTable.propTypes = {
  tableData: PropTypes.arrayOf({
    id: PropTypes.number,
    identifier: PropTypes.string,
    amount: PropTypes.number,
    name: PropTypes.string,
    fees: PropTypes.number,
    rate: PropTypes.number,
    period: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  loadingCredits: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalData: PropTypes.number,
  }).isRequired,
};

export default CreditsTable;
