import axios from 'axios';
import {
  FETCH_LOGS_START,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAILURE,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';

// eslint-disable-next-line import/prefer-default-export
export const fetchHistoryLogs = (contextId, contextType) => dispatch => {
  dispatch({
    type: FETCH_LOGS_START,
  });
  axios
    .get(`/api/logs`, {
      params: {
        contextId,
        contextType,
      },
    })
    .then(({ data }) => {
      const { logs } = data;
      dispatch({
        type: FETCH_LOGS_SUCCESS,
        payload: logs,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_LOGS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_LOGS_FAILURE,
        payload: { errorCode },
      });
    });
};
