export const validateNumbers = (value: string, country: string) => {
  if (country === 'CL') {
    const regex = /^[0-9]{0,12}$/;
    return regex.test(value);
  } else if (country === 'MX') {
    const regex = /^[0-9]{0,12}(\.[0-9]{0,2})?$/;
    return regex.test(value);
  }
};

export const validateBaseRate = (value: string) => {
  const regex = /^[0-9]*(\.[0-9]*)?$/;
  return regex.test(value);
};
