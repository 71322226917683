import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Sat = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M7.5 11C9.433 11 11 9.433 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5C4 9.433 5.567 11 7.5 11Z" />
      <path d="M7.5 20C9.433 20 11 18.433 11 16.5C11 14.567 9.433 13 7.5 13C5.567 13 4 14.567 4 16.5C4 18.433 5.567 20 7.5 20Z" />
      <path d="M16.5 11C18.433 11 20 9.433 20 7.5C20 5.567 18.433 4 16.5 4C14.567 4 13 5.567 13 7.5C13 9.433 14.567 11 16.5 11Z" />
      <path d="M16.5 20C18.433 20 20 18.433 20 16.5C20 14.567 18.433 13 16.5 13C14.567 13 13 14.567 13 16.5C13 18.433 14.567 20 16.5 20Z" />
    </SvgIcon>
  );
};

export default Sat;
