import axios from 'axios';
import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_TYPES_START,
  FETCH_DOCUMENTS_TYPES_SUCCESS,
  FETCH_DOCUMENTS_TYPES_FAILURE,
  DOWNLOAD_DOCUMENT_START,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_SIGNED_DOCUMENT_START,
  UPDATE_SIGNED_DOCUMENT_SUCCESS,
  UPDATE_SIGNED_DOCUMENT_FAILURE,
  CREATE_DOCUMENT_START,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILURE,
  APPROVE_REJECT_DOCUMENT_START,
  APPROVE_REJECT_DOCUMENT_SUCCESS,
  APPROVE_REJECT_DOCUMENT_FAILURE,
  CANCEL_MIFIEL_DOCUMENT_START,
  CANCEL_MIFIEL_DOCUMENT_SUCCESS,
  CANCEL_MIFIEL_DOCUMENT_FAILURE,
  RESET_DOCUMENT_ERRORS,
  VERIFY_DOCUMENTS_START,
  VERIFY_DOCUMENTS_SUCCESS,
  VERIFY_DOCUMENTS_FAILURE,
  VERIFY_DOCUMENTS_RESET,
  LOAD_DOCUMENTS_START,
  LOAD_DOCUMENTS_SUCCESS,
  LOAD_DOCUMENTS_FAILURE,
  FETCH_DOCUMENT_BASE64_START,
  FETCH_DOCUMENT_BASE64_SUCCESS,
  FETCH_DOCUMENT_BASE64_FAILURE,
  UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_START,
  UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_SUCCESS,
  UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_FAILURE,
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_START,
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_SUCCESS,
  FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';
import { downloadFile } from '../helpers/fileHandling';
import { getErrorCode } from '../helpers/handleErrors';

export const fetchDocumentsTypes =
  ({ field, searchInput, active = true } = {}) =>
  dispatch => {
    dispatch({
      type: FETCH_DOCUMENTS_TYPES_START,
    });
    axios
      .get(`/api/documenttype`, {
        params: {
          field,
          searchInput,
          active,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_DOCUMENTS_TYPES_SUCCESS,
          payload: { documentTypes: data },
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_DOCUMENTS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_DOCUMENTS_TYPES_FAILURE,
        });
      });
  };

export const loadDocuments = (documentableId, documentableType) => dispatch => {
  dispatch({ type: LOAD_DOCUMENTS_START });
  axios
    .post(`/api/${documentableType}/${documentableId}/document/load`)
    .then(() => {
      dispatch({
        type: LOAD_DOCUMENTS_SUCCESS,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DOCUMENTS_LOADED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: LOAD_DOCUMENTS_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.LOAD_DOCUMENTS_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const fetchDocuments =
  (documentableId, documentableType, query = {}) =>
  dispatch => {
    dispatch({
      type: FETCH_DOCUMENTS_START,
      payload: { documentableType, documentableId },
    });

    axios
      .get(`/api/${documentableType}/${documentableId}/document`, {
        params: query,
      })
      .then(res => {
        const documents = res.data;
        dispatch({
          type: FETCH_DOCUMENTS_SUCCESS,
          payload: { documentableType, documents },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_DOCUMENTS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_DOCUMENTS_FAILURE,
          payload: { documentableType, errorCode },
        });
      });
  };

export const verifyStatusDocuments =
  (documentableId, documentableType) => dispatch => {
    dispatch({
      type: VERIFY_DOCUMENTS_START,
      payload: { documentableType, documentableId },
    });

    axios
      .get(`/api/${documentableType}/${documentableId}/document/completed`)
      .then(res => {
        const resData = res.data;
        dispatch({
          type: VERIFY_DOCUMENTS_SUCCESS,
          payload: { documentableType, documentableId, ...resData },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.VERIFY_DOCUMENTS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: VERIFY_DOCUMENTS_FAILURE,
          payload: {
            documentableType,
            documentableId,
            errorCode,
          },
        });
      });
  };

export const fetchDocumentBase64 =
  (documentableType, document, file = 'signed') =>
  dispatch => {
    const { documentableId, id: documentId } = document;
    dispatch({ type: FETCH_DOCUMENT_BASE64_START });
    axios
      .get(
        `/api/${documentableType}/${documentableId}/document/${documentId}/download`,
        {
          params: {
            file,
          },
        }
      )
      .then(({ data }) => {
        dispatch({
          type: FETCH_DOCUMENT_BASE64_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: FETCH_DOCUMENT_BASE64_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const downloadDocument =
  (documentableId, documentableType, document, file = 'signed') =>
  dispatch => {
    const { id: documentId, title } = document;
    const documentKey = `${documentableType}${documentableId}`;

    dispatch({ type: DOWNLOAD_DOCUMENT_START, payload: { documentKey } });
    axios
      .get(
        `/api/${documentableType}/${documentableId}/document/${documentId}/download`,
        {
          params: {
            file,
          },
        }
      )
      .then(res => {
        const resData = res.data;
        dispatch({
          type: DOWNLOAD_DOCUMENT_SUCCESS,
        });

        const { file } = resData;
        return downloadFile(file, title);
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: DOWNLOAD_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_DOCUMENT_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const updateDocument =
  (documentableId, documentableType, documentId, document) => dispatch => {
    dispatch({ type: UPDATE_DOCUMENT_START, payload: { documentId } });
    axios
      .put(
        `/api/${documentableType}/${documentableId}/document/${documentId}`,
        document
      )
      .then(res => {
        const updatedDocument = res.data;
        dispatch({
          type: UPDATE_DOCUMENT_SUCCESS,
          payload: { documentableType, updatedDocument },
        });
        dispatch(verifyStatusDocuments(documentableId, documentableType));
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOCUMENT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
        if (document.action) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.UPDATE_DOCUMENT_FAILURE, {
              variant: 'error',
            })
          );
        }
      });
  };

export const updateSignedDocument =
  (documentableId, documentableType, documentId, file, approved) =>
  dispatch => {
    dispatch({ type: UPDATE_SIGNED_DOCUMENT_START });
    axios
      .put(
        `/api/${documentableType}/${documentableId}/document/${documentId}/sign`,
        {
          file,
          approved,
        }
      )
      .then(res => {
        const updatedDocument = res.data;
        dispatch({
          type: UPDATE_SIGNED_DOCUMENT_SUCCESS,
          payload: { documentableType, updatedDocument },
        });
        dispatch(verifyStatusDocuments(documentableId, documentableType));
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOCUMENT_UPLOADED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_SIGNED_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const createDocument =
  (documentableId, documentableType, document) => dispatch => {
    dispatch({ type: CREATE_DOCUMENT_START });
    axios
      .post(`/api/${documentableType}/${documentableId}/document`, {
        ...document,
        description: document.description || undefined,
        file: document.file || undefined,
      })
      .then(res => {
        const newDocument = res.data;
        dispatch({
          type: CREATE_DOCUMENT_SUCCESS,
          payload: { documentableType, newDocument },
        });
        dispatch(verifyStatusDocuments(documentableId, documentableType));
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOCUMENT_CREATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const createDocumentWithSignedFile =
  (documentableId, documentableType, document, signedFile) => dispatch => {
    dispatch({ type: CREATE_DOCUMENT_START });
    axios
      .post(`/api/${documentableType}/${documentableId}/document`, {
        ...document,
        description: document.description || undefined,
      })
      .then(res => {
        const newDocument = res.data;
        dispatch(
          updateSignedDocument(
            documentableId,
            documentableType,
            newDocument.id,
            signedFile,
            true
          )
        );
        dispatch({
          type: CREATE_DOCUMENT_SUCCESS,
          payload: { documentableType, newDocument },
        });
        dispatch(verifyStatusDocuments(documentableId, documentableType));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const approveRejectDocument =
  (
    documentId,
    documentableId,
    documentableType,
    veredict,
    reason = undefined
  ) =>
  dispatch => {
    dispatch({ type: APPROVE_REJECT_DOCUMENT_START });
    axios
      .put(
        `/api/${documentableType}/${documentableId}/document/${documentId}/${veredict}`,
        {
          reason,
        }
      )
      .then(res => {
        const updatedDocument = res.data;
        dispatch({
          type: APPROVE_REJECT_DOCUMENT_SUCCESS,
          payload: { documentableType, updatedDocument },
        });
        dispatch(verifyStatusDocuments(documentableId, documentableType));
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOCUMENT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: APPROVE_REJECT_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updateElectronicSignatureDocument =
  (documentableId, documentableType, documentId, document, rawSignatories) =>
  dispatch => {
    dispatch({ type: UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_START });
    const signatories = rawSignatories.length ? rawSignatories : null;
    axios
      .put(
        `/api/${documentableType}/${documentableId}/document/${documentId}`,
        {
          ...document,
          signatories,
        }
      )
      .then(({ data }) => {
        const updatedDocument = data;
        dispatch({
          type: UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_SUCCESS,
          payload: { documentableType, updatedDocument },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOCUMENT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const cancelMiFiel =
  (documentableId, documentableType, documentId) => dispatch => {
    dispatch({ type: CANCEL_MIFIEL_DOCUMENT_START });
    axios
      .delete(
        `/api/${documentableType}/${documentableId}/document/cancelMifiel/${documentId}`
      )
      .then(() => {
        dispatch(
          updateDocument(documentableId, documentableType, documentId, {
            file: null,
          })
        );
        dispatch({
          type: CANCEL_MIFIEL_DOCUMENT_SUCCESS,
          payload: { documentableType, documentId },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CANCEL_MIFIEL_DOCUMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchStatusSignatories = documentId => dispatch => {
  dispatch({ type: FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_START });
  axios
    .get(`/api/mifiel/${documentId}`)
    .then(({ data }) => {
      const signatories = data?.signatures || [];
      dispatch({
        type: FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_SUCCESS,
        payload: { signatories },
      });
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(
          t13s.NOTIFICATION.FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE,
          {
            variant: 'error',
          }
        )
      );
      dispatch({
        type: FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE,
      });
    });
};

export const resetDocumentErrors = documentableType => dispatch => {
  dispatch({ type: RESET_DOCUMENT_ERRORS, payload: { documentableType } });
};

export const resetDocumentsVerify = documentableType => dispatch => {
  dispatch({ type: VERIFY_DOCUMENTS_RESET, payload: { documentableType } });
};
