/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  styled,
  Link as MaterialLink,
  Collapse,
} from '@material-ui/core';
import { BrowserRouter, Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import InputIcon from '@material-ui/icons/Input';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { fetchBusiness } from '../../actions/businessAction';
import {
  fetchDebtService,
  resetConsultDebtService,
} from '../../actions/debtServiceActions';
import {
  addFundFinancingToBulkInvoices,
  fetchCountOrderComments,
  fetchFacturas,
  fetchOperationSummary,
  fetchSelectedOrder,
  updateStatusOrder,
  fetchCountOrderResponsibles,
} from '../../actions/orderActions';
import {
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
  DIRECT_FINANCING,
  ORDER_TYPES_LABEL,
  STATUS_APPROVED,
  STATUS_RENEGOTIATION,
  STATUS_REVIEWED,
  TIME_ZONE,
} from '../../helpers/Constants';
import * as DateUtils from '../../helpers/DateUtils';
import {
  BUSINESS_SUDO_PERFORM,
  ORDERS_LOCK_PERFORM,
  ORDERS_SKIP_DEBT_WALLET_TO_REVIEWED,
  ORDERS_SKIP_DEBT_WALLET_TO_APPROVED,
} from '../../helpers/performsType';
import { CONTEXT_TYPES } from '../../helpers/userLogsUtils';
import { features } from '../../config/features';
import BusinessDetailMX from '../BusinessDetailMX';
import Can from '../Can';
import FundDialog from '../dialogs/FundDialog';
import OrderLegal from './OrderLegal';
import Container from './Container';
import Panel from './Panel';
import SectionHeader from './SectionHeader';
import TGRAgreementsDrawer from './TGRAgreementsDrawer';
import ChangeStatusOrderDialog from '../dialogs/ChangeStatusOrderDialog';
import { verifyIfOrderIsFullyFinanced } from '../../actions/fundsActions';
import BusinessDetailCL from '../BusinessDetailCL';
import { MenuSelector, Alert, Dialog } from '../../payments/commons/components';
import {
  useLockOrder,
  useUnlockOrder,
} from '../../payments/inbox/infrastructure/store';
import { checkAuth } from '../../helpers/validation/auth';
import useGetOrderInvoiceIsHold from '../../AR/inbox/infrastructure/store/useGetOrderInvoiceIsHold';
import OrderCommentsIcon from '../../AR/commons/components/OrderCommentsIcon';
import OrderResponsiblesIcon from '../../AR/commons/components/OrderResponsiblesIcon';
import OrderCommentsDrawer from '../../AR/commons/components/OrderCommentsDrawer';
import OrderResponsiblesDrawer from '../../AR/commons/components/OrderResponsiblesDrawer/OrderResponsiblesDrawer';
import checkInvoicesInActiveOrderService from '../../AR/inbox/infrastructure/services/checkInvoicesInActiveOrderService';
import useGetCountry from '../../hooks/useGetCountry';
import CollapseButton from '../../AR/commons/components/CollapseButton';
import OperationResume from '../../AR/commons/components/OperationResumen';

const useStyles = makeStyles(theme => ({
  sudoButton: {
    marginLeft: 10,
  },
  sudoIcon: {
    color: theme.palette.primary.main,
    padding: '0px 0px 0px 0px',
    fontSize: 19,
  },
  labelInfo: {
    fontSize: 14,
    marginRight: 5,
    textTransform: 'none',
  },
  info: {
    marginRight: 30,
    fontSize: 14,
  },
  containerOrderDetail: {
    padding: '0px 20px 20px 20px',
  },
  containerLockOrderDetail: {
    padding: '0px 20px 0 20px',
  },
  rootInfo: {
    display: 'flex',
  },
  firstOrderBadge: {
    backgroundColor: theme.palette.text.contrast1,
  },
  containerCommentsData: {
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 5,
  },
}));

const BUSINESS = 'business';

const OrderDetailsCard = ({
  order,
  businessOrder = {},
  handleShowSimulationDialog,
  handleShowDrawer,
  facturas,
  handleShowCredit,
  handleImpersonate,
  handleShowMatchInvoicesPDFDialog,
  handleShowLoader,
  handleHistoryLogDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const country = useGetCountry();
  const { search } = location;
  const { authState } = useOktaAuth();
  const [isLockDialogOpen, setIsLockDialogOpen] = useState(false);

  const { id: businessId, totalOrdersTransferred, identifier } = businessOrder;

  const { business: businessInfoComplete } = useSelector(
    state => state.business
  );
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const canLockOrder = checkAuth(roles, ORDERS_LOCK_PERFORM, rules);
  const {
    updateOrderStatusIsLoading,
    updateOrderStatusError,
    orderStatusWasUpdated,
    fetchCountOrderCommentsIsLoading,
    hasPendingComments,
    commentsCount,
    fetchCountOrderResponsiblesIsLoading,
    responsiblesCount,
  } = useSelector(state => state.order);
  const consultedDebtService = useSelector(
    state => state.debtService.consulted
  );
  const { orderIsFullyFinanced } = useSelector(state => state.funds);
  const [showTGRDialog, setShowTGRDialog] = useState(false);
  const [showChangeStatusOrderDialog, setShowChangeStatusOrderDialog] =
    useState(false);
  const [tempBusiness, setTempBusiness] = useState(businessInfoComplete || {});
  const [showFundDialog, setShowFundDialog] = useState(false);
  const [showOrderCommentsDrawer, setShowOrderCommentsDrawer] = useState(false);
  const [showOrderResponsiblesDrawer, setShowOrderResponsiblesDrawer] =
    useState(false);
  const [activeOrdersWithInvoices, setActiveOrdersWithInvoices] = useState([]);
  const [showActiveOrderWarning, setShowActiveOrderWarning] = useState(false);
  const [showBusinessInformationPanel, setShowBusinessInformationPanel] =
    useState(false);
  const countryFeatures = features[country];

  const [orderDetail] = order?.OrderDetails?.length ? order?.OrderDetails : [];
  const { name, sudoUser } = tempBusiness;

  const { lockOrder } = useLockOrder();
  const { unlockOrder } = useUnlockOrder();
  const data = useGetOrderInvoiceIsHold([order?.id]);

  const orderInvoiceIsHold = orderInvoices => {
    const orderInvoicesValues = orderInvoices && Object.values(orderInvoices);
    const orderInvoicesHoldStatus = orderInvoicesValues?.length
      ? orderInvoicesValues?.map(orderInvoice => orderInvoice.isHold)
      : [];

    const isHold = orderInvoicesHoldStatus?.some(
      invoiceHoldStatus => invoiceHoldStatus === true
    );
    return !!isHold;
  };

  const someOrderInvoiceIsHold = data?.some(orderInvoiceIsHold);

  const handleGetQueryParams = (searchData, fieldKey) =>
    new URLSearchParams(searchData).get(fieldKey);

  useEffect(() => {
    if (search) {
      const hasShowComments =
        handleGetQueryParams(search, 'showComments') || '';
      setShowOrderCommentsDrawer(hasShowComments === 'true');
    }
  }, [search]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchBusiness(businessId));
      dispatch(fetchDebtService(businessId, BUSINESS));
    }
  }, [dispatch, businessId]);

  useEffect(() => {
    if (identifier && countryFeatures.orderActions.showResponsibles) {
      dispatch(fetchCountOrderResponsibles(identifier));
    }
  }, [dispatch, identifier]);

  useEffect(() => {
    if (order.id) {
      dispatch(verifyIfOrderIsFullyFinanced(order?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (businessInfoComplete) setTempBusiness(businessInfoComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessOrder, businessInfoComplete]);

  useEffect(() => {
    if (consultedDebtService) {
      dispatch(fetchSelectedOrder(order?.id));
      dispatch(resetConsultDebtService());

      if (country === COUNTRY_CODE_CL) {
        dispatch(fetchOperationSummary(order?.id));
        dispatch(fetchFacturas(order?.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, consultedDebtService]);

  useEffect(() => {
    if (orderStatusWasUpdated) setShowChangeStatusOrderDialog(false);
  }, [orderStatusWasUpdated]);

  useEffect(() => {
    if (order?.id && authState?.idToken?.claims?.email) {
      dispatch(
        fetchCountOrderComments(order.id, authState?.idToken?.claims?.email)
      );
    }
  }, [order?.id, authState]);

  useEffect(() => {
    if (facturas.length && showChangeStatusOrderDialog) {
      checkInvoicesInActiveOrderService(facturas).then(orders => {
        const ordersIdMap = orders.map(({ orderId }) => orderId);
        const ordersIdMapFiltered =
          ordersIdMap.filter(
            (orderId, index) =>
              orderId !== order?.id && ordersIdMap.indexOf(orderId) === index
          ) || [];

        setActiveOrdersWithInvoices(ordersIdMapFiltered);
        if (ordersIdMapFiltered.length) {
          setShowActiveOrderWarning(true);
        }
      });
    }
  }, [showChangeStatusOrderDialog]);

  const translateTypeOrder = type => {
    return ORDER_TYPES_LABEL[type];
  };

  const handleSudo = () => {
    handleImpersonate(sudoUser.id, country);
  };

  const requestDate = DateUtils.datetimeFormatterWithTimezone(
    orderDetail?.issuedDate ?? order?.createdAt,
    TIME_ZONE[country]
  );

  const handleCloseFundDialog = () => {
    setShowFundDialog(false);
  };

  const handleSubmitFundFinancing = fundFinancing => {
    const [{ orderId }] = facturas;
    const invoices = facturas.map(({ id }) => id);
    dispatch(
      addFundFinancingToBulkInvoices(orderId, {
        invoices,
        fundFinancing,
      })
    );
    setShowFundDialog(false);
    handleShowLoader();
  };

  const handleSubmitChangeStatusOrderDialog = (
    newStatus,
    statusReason = null
  ) => {
    let skipDebtBlock = false;
    if (newStatus === STATUS_REVIEWED) {
      skipDebtBlock = checkAuth(
        roles,
        ORDERS_SKIP_DEBT_WALLET_TO_REVIEWED,
        rules
      );
    }
    if (newStatus === STATUS_APPROVED) {
      skipDebtBlock = checkAuth(
        roles,
        ORDERS_SKIP_DEBT_WALLET_TO_APPROVED,
        rules
      );
    }

    dispatch(
      updateStatusOrder(
        {
          orderId: order?.id,
          statusOrder: newStatus,
          statusReason,
        },
        order?.type || order?.orderType,
        { force: skipDebtBlock }
      )
    );
  };

  const toggleShowBusinessInformationPanel = () => {
    setShowBusinessInformationPanel(!showBusinessInformationPanel);
  };

  const handleShowChangeStatusOperationDialog = () => {
    setShowChangeStatusOrderDialog(true);
  };

  const handleShowNewSimulationDialog = () => {
    handleShowSimulationDialog(order);
  };

  const AlertUnlockLink = styled(MaterialLink)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: 14,
    textDecoration: 'underline',
    cursor: 'pointer',
  }));

  const menuActions = [
    {
      itemText:
        order?.status === STATUS_RENEGOTIATION
          ? 'Ver crédito'
          : 'Repactar Orden',
      itemHandler: handleShowCredit,
      permission: countryFeatures.orderActions.repactOrder,
    },
    {
      itemText: 'Historial de la orden',
      itemHandler: () => handleHistoryLogDialog(order?.id, CONTEXT_TYPES.order),
      permission: countryFeatures.orderActions.showHistoryLogsButton,
    },
    {
      itemText: 'Bloquear operación',
      itemHandler: () => setIsLockDialogOpen(true),
      permission: canLockOrder,
    },
  ];
  return (
    <Container gridGap={20}>
      {isLockDialogOpen && (
        <Dialog
          isOpen={isLockDialogOpen}
          handleClose={() => setIsLockDialogOpen(false)}
          handleAction={() =>
            (order?.isLocked ? unlockOrder : lockOrder)({
              orderId: order?.id,
            })
          }
          title={
            order?.isLocked ? 'Desbloquear operación' : 'Bloquear operación'
          }
          message={
            order?.isLocked
              ? 'Las acciones de esta operación volverán a quedar habilitadas en Backoffice.'
              : 'Las acciones de esta operación quedarán inhabilitadas en Backoffice.'
          }
          actionText={order?.isLocked ? 'Desbloquear' : 'Bloquear'}
          width={400}
        />
      )}

      {showTGRDialog && order.businessId && (
        <TGRAgreementsDrawer
          businessId={order.businessId}
          open={showTGRDialog}
          handleCloseDialog={() => setShowTGRDialog(false)}
        />
      )}

      {showActiveOrderWarning && (
        <Dialog
          isOpen={showActiveOrderWarning}
          handleClose={() => setShowActiveOrderWarning(false)}
          handleAction={() => setShowActiveOrderWarning(false)}
          title="Hay facturas activas en otra operación"
          message={`Elimina de esta solicitud las facturas que se encuentran activas en la solicitud N° ${activeOrdersWithInvoices.join(
            ','
          )} e infórmale a la persona usuaria la razón.`}
          actionText="Entendido"
          showCancelButton={false}
          width={400}
        />
      )}

      {showFundDialog && (
        <FundDialog
          open={showFundDialog}
          handleCloseDialog={handleCloseFundDialog}
          handleSubmit={handleSubmitFundFinancing}
        />
      )}

      {showChangeStatusOrderDialog && (
        <ChangeStatusOrderDialog
          open={showChangeStatusOrderDialog}
          handleCloseDialog={() => setShowChangeStatusOrderDialog(false)}
          handleSubmit={handleSubmitChangeStatusOrderDialog}
          statusOrder={order?.status}
          statusReason={order?.statusReason}
          title="Cambiar estado de la operación"
          isLoading={updateOrderStatusIsLoading}
          error={updateOrderStatusError}
          isHold={someOrderInvoiceIsHold}
        />
      )}

      {showOrderCommentsDrawer && order?.id && (
        <OrderCommentsDrawer
          isOpen={showOrderCommentsDrawer}
          flagType="operationDetail"
          operationId={order?.id}
          handleCloseDrawer={() => setShowOrderCommentsDrawer(false)}
        />
      )}

      {showOrderResponsiblesDrawer && identifier && (
        <OrderResponsiblesDrawer
          isOpen={showOrderResponsiblesDrawer}
          businessIdentifier={identifier}
          handleCloseDrawer={() => setShowOrderResponsiblesDrawer(false)}
        />
      )}

      <SectionHeader
        preTitleAction={
          <Tooltip title="Operaciones Transferidas">
            <Badge
              classes={
                totalOrdersTransferred < 2
                  ? { badge: classes.firstOrderBadge }
                  : ''
              }
              badgeContent={totalOrdersTransferred}
              color="primary"
              invisible={false}
            />
          </Tooltip>
        }
        padding="lg"
        title={name}
        breadCrumbs={[
          {
            title: 'Operaciones',
            link: `/${country.toLowerCase()}/${DIRECT_FINANCING?.toLowerCase()}`,
          },
          { title: 'Detalle de la operación' },
        ]}
        actionTitle={
          countryFeatures.orderActions.showSudoButton &&
          sudoUser?.id && (
            <Can
              perform={BUSINESS_SUDO_PERFORM}
              yes={() => (
                <IconButton className={classes.sudoButton} onClick={handleSudo}>
                  <InputIcon className={classes.sudoIcon} />
                </IconButton>
              )}
            />
          )
        }
        secondaryActions={
          <Box display="flex" flexDirection="column">
            <Box className={classes.containerCommentsData}>
              {countryFeatures.orderActions.showResponsibles && identifier && (
                <OrderResponsiblesIcon
                  responsiblesCount={responsiblesCount ?? 0}
                  isLoading={fetchCountOrderResponsiblesIsLoading}
                  handleOpenDrawer={() => setShowOrderResponsiblesDrawer(true)}
                />
              )}
              <OrderCommentsIcon
                hasPendingComments={hasPendingComments}
                commentsCount={commentsCount ?? 0}
                isLoading={fetchCountOrderCommentsIsLoading}
                handleOpenDrawer={() => setShowOrderCommentsDrawer(true)}
              />
            </Box>

            <MenuSelector
              actions={menuActions}
              menuText="Más acciones"
              disabled={order?.isLocked}
            />
          </Box>
        }
        mainArea={
          <Grid container xs={12} direction="row">
            <Grid item className={classes.rootInfo}>
              <Typography color="textPrimary" className={classes.labelInfo}>
                <Box fontWeight="fontWeightBold">ID Operación:</Box>
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.info}
                data-cy="sectionHeaderOperationId"
              >
                {order?.id}
              </Typography>
            </Grid>

            <Grid item className={classes.rootInfo}>
              <Typography color="textPrimary" className={classes.labelInfo}>
                <Box fontWeight="fontWeightBold">Tipo de operación:</Box>
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.info}
                data-cy="sectionHeaderOperationType"
              >
                {translateTypeOrder(order?.type || order?.orderType)}
              </Typography>
            </Grid>

            <Grid item className={classes.rootInfo}>
              <Typography color="textPrimary" className={classes.labelInfo}>
                <Box fontWeight="fontWeightBold">Fecha de solicitud:</Box>
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.info}
                data-cy="sectionHeaderIssuedDate"
              >
                {requestDate} {requestDate && country}
              </Typography>
            </Grid>

            <Grid item className={classes.rootInfo}>
              <Typography color="textPrimary" className={classes.labelInfo}>
                <Box fontWeight="fontWeightBold">ID Empresa:</Box>
              </Typography>
              <Typography color="textPrimary" className={classes.info}>
                <BrowserRouter forceRefresh>
                  <Link
                    data-cy="sectionHeaderBusinessId"
                    to={`/${country.toLowerCase()}/business/${businessId}`}
                    underline="always"
                  >
                    {businessId}
                  </Link>
                </BrowserRouter>
              </Typography>
            </Grid>
          </Grid>
        }
      />

      {order?.isLocked && (
        <Grid container className={classes.containerLockOrderDetail}>
          <Container>
            <Alert
              type="warning"
              title="Operación bloqueada"
              message="Las acciones de esta operación están inhabilitadas en Backoffice."
            >
              {canLockOrder && (
                <AlertUnlockLink onClick={() => setIsLockDialogOpen(true)}>
                  Desbloquear
                </AlertUnlockLink>
              )}
            </Alert>
          </Container>
        </Grid>
      )}

      <Grid container className={classes.containerOrderDetail}>
        <Container gridTemplate="1fr 398px" gridGap={20}>
          <Container gridGap={20}>
            <Panel
              title="Información de la empresa"
              variant="section"
              titlePadding="lg lg sm lg"
              contentPadding="sm"
              borderRadius={8}
              noBorder
              actions={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {countryFeatures.businessDetailsSection
                    .showHistoryLogsButton && (
                    <Tooltip title="Historial de cambios" arrow>
                      <Button
                        className={{ marginLeft: 10 }}
                        disabled={!businessInfoComplete.id}
                        onClick={() =>
                          handleHistoryLogDialog(
                            businessId,
                            CONTEXT_TYPES.business
                          )
                        }
                        startIcon={<HistoryIcon />}
                        variant="text"
                        color="primary"
                      >
                        Historial de la empresa
                      </Button>
                    </Tooltip>
                  )}

                  {businessInfoComplete.id && (
                    <CollapseButton
                      isOpen={showBusinessInformationPanel}
                      onClick={toggleShowBusinessInformationPanel}
                      ml={2}
                    />
                  )}
                </Box>
              }
            >
              <Collapse
                in={showBusinessInformationPanel}
                timeout="auto"
                unmountOnExit
              >
                {country === COUNTRY_CODE_CL && (
                  <BusinessDetailCL
                    business={businessInfoComplete}
                    handleTgrClick={() => setShowTGRDialog(true)}
                  />
                )}
                {country === COUNTRY_CODE_MX && (
                  <BusinessDetailMX business={businessInfoComplete} />
                )}
              </Collapse>
            </Panel>
            <Grid item>
              <OrderLegal
                isCeded={order?.isCeded}
                isVerified={order?.isVerified}
                hasFundFinancing={orderIsFullyFinanced}
                orderId={order?.id}
              />
            </Grid>
          </Container>

          <OperationResume
            operation={order || {}}
            handleShowNewSimulationDialog={handleShowNewSimulationDialog}
            handleShowSimulation={handleShowDrawer}
            handleShowMatchInvoicesPDFDialog={handleShowMatchInvoicesPDFDialog}
            handleShowChangeStatusOperationDialog={
              handleShowChangeStatusOperationDialog
            }
          />
        </Container>
      </Grid>
    </Container>
  );
};

OrderDetailsCard.propTypes = {
  order: PropTypes.objectOf(Object).isRequired,
  businessOrder: PropTypes.objectOf(String).isRequired,
  handleShowSimulationDialog: PropTypes.func.isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
  facturas: PropTypes.arrayOf(Object).isRequired,
  handleShowCredit: PropTypes.func.isRequired,
  handleImpersonate: PropTypes.func.isRequired,
  handleShowMatchInvoicesPDFDialog: PropTypes.func.isRequired,
  handleShowLoader: PropTypes.func.isRequired,
  handleHistoryLogDialog: PropTypes.func.isRequired,
};
export default OrderDetailsCard;
