import { purgeNestedObj } from '../../../../commons/utils';

export const updateReceiverUseCase = ({
  currentSelectorState,
  newValues,
  selectedReceiverIdentifier,
}) => {
  const { rate, receiverDebt } = newValues;
  const updatedValuesForReceiver = purgeNestedObj({
    rate,
    effectiveFundCostRate: rate,
    receiverDebtServiceAmount: receiverDebt,
  });
  const updatedValuesForDocuments = purgeNestedObj({
    rate,
    effectiveFundCostRate: rate,
  });
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    if (selectedReceiverIdentifier !== receiverIdentifier) {
      acc[receiverIdentifier] = { ...currentSelectorState[receiverIdentifier] };

      return acc;
    }

    acc[receiverIdentifier] = {
      ...currentReceiver,
      ...updatedValuesForReceiver,
      value: currentReceiver.value.map(doc => ({
        ...doc,
        ...updatedValuesForDocuments,
      })),
    };

    return acc;
  }, {});
};
