import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useStatusFilter,
  useSetStatusFilter,
  useFieldFilter,
  useSetFieldFilter,
  useClearSelectedOperations,
  useOperationFilter,
  useSetClearFilters,
} from '../infrastructure/store/InboxStore';
import { t13s } from '../../../translationKeys/index';
import { FilterField } from '../../commons/interfaces/ARInterfaces';
import useStatuses from '../../../hooks/useStatuses';

const useFilters = () => {
  const { t } = useTranslation();
  const { getStatuses } = useStatuses();
  const statusFilter = useStatusFilter();
  const setStatusFilter = useSetStatusFilter();
  const fieldFilter = useFieldFilter();
  const setFieldFilter = useSetFieldFilter();
  const operationFilter = useOperationFilter();

  const clearSelectedOperations = useClearSelectedOperations();
  const setClearFilters = useSetClearFilters();

  const fieldFilters = useMemo(
    () => [
      {
        title: 'Order ID',
        field: 'id',
      },
      { title: 'Razon Social', field: 'name' },
      {
        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        field: 'identifier',
      },
    ],
    [t]
  );

  const handleChangeStatusFilter = useCallback(
    async (value: string) => {
      await setStatusFilter(value);
      clearSelectedOperations();
    },
    [setStatusFilter, clearSelectedOperations]
  );

  const handleChangeFieldFilter = useCallback(
    async ({ select, input }: FilterField) => {
      await setFieldFilter({ select, input });
      clearSelectedOperations();
    },
    [setFieldFilter, clearSelectedOperations]
  );

  const statusFilters = [
    {
      key: 'ALL',
      label: 'Todos',
    },
    ...getStatuses('order'),
  ];

  const handleClearFilters = useCallback(() => {
    clearSelectedOperations();
    setClearFilters();
  }, [clearSelectedOperations, setClearFilters]);

  return {
    statusFilter,
    statusFilters,
    fieldFilter,
    fieldFilters,
    operationFilter,
    handleClearFilters,
    handleChangeStatusFilter,
    handleChangeFieldFilter,
  };
};

export default useFilters;
