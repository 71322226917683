
import { creditLineTab } from '../constants';

type CreditLineTab = typeof creditLineTab;

type Tabs = {
  MX: CreditLineTab[];
  CL: CreditLineTab[];
};

export const setTabsWithCreditLine = (tabs: Tabs) => ({
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  CL: [...tabs.CL.slice(0, 1), creditLineTab, ...tabs.CL.slice(1)],
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  MX: [...tabs.MX.slice(0, 1), creditLineTab, ...tabs.MX.slice(1)],
});
