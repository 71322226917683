import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateOrderDetailsService = async orderData => {
  try {
    const { data } = await axios.post('/api/orderdetail', orderData);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateOrderDetailsService;
