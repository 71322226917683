import { Grid, styled } from '@material-ui/core';
import { RefreshIcon } from '../../../../commons/icons';

export const CustomRefreshIcon = styled(RefreshIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const CustomGrid = styled(Grid)({
  marginLeft: 16,
});
