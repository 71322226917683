import axios from 'axios';
import { UNKNOWN_ERROR_CODE } from '../../../../helpers/Constants';

export const createBankAccountService = async ({
  source,
  sourceId,
  supplierIdentifier,
  payerBusinessId,
  bankAccount,
}) => {
  try {
    const { data } = await axios.post(
      `/api/payment/${source}/${sourceId}/bankaccount`,
      { supplierIdentifier, payerBusinessId, bankAccount }
    );
    return data;
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode ?? UNKNOWN_ERROR_CODE;
    throw new Error(errorCode);
  }
};

export default createBankAccountService;
