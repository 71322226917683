import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { TitleGrid } from './styles';
import { Text } from '../../../../../commons/formatters';
import { Edit as EditIcon } from '../../../../../../components/icons';

const AttributeCustomValue = ({ title, value, handler, isEditable }) => {
  return (
    <Grid container direction="column">
      <Grid item container>
        <TitleGrid item isEditable>
          <Text fontWeight="bold" variant="subtitle1">
            {title}
          </Text>
        </TitleGrid>
        {isEditable && (
          <Grid item>
            <IconButton size="small" onClick={handler}>
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid item container>
        <Text variant="subtitle1">{value}</Text>
      </Grid>
    </Grid>
  );
};

AttributeCustomValue.defaultProps = {
  handler: () => undefined,
  isEditable: false,
};

AttributeCustomValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default AttributeCustomValue;
