import {
  Box,
  Drawer as DrawerMui,
  Typography,
  styled,
} from '@material-ui/core';

export const BaseDrawer = styled(DrawerMui)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});

export const ContainerRoot = styled(Box)({
  width: 414,
  padding: 36,
});

export const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #CDCAD8',
});

export const Title = styled(Typography)({
  fontSize: 19.5,
  fontWeight: 'bold',
  color: '#44414C',
});

export const MainContainer = styled(Box)({
  minHeight: 'calc(100vh - 163px)',
  marginBottom: 10,
});

export const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 209px)',
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#44414C',
    borderRadius: 4,
  },
});

export const SubTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#44414C',
  marginBottom: 25,
});

export const ResponsibleRole = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#8D8A96',
  marginBottom: 20,
});

export const TextResponsible = styled(Typography)(
  ({ isCurrent = true }: { isCurrent?: boolean }) => ({
    fontSize: 14,
    color: isCurrent ? '#312F37' : '#8D8A96',
    fontWeight: isCurrent ? 'bold' : 'normal',
  })
);

export const SpacerResponsible = styled(Box)({
  margin: '0 10px',
});

export const NotFoundDataText = styled(Typography)({
  fontSize: 14,
  color: '#8D8A96',
  textAlign: 'center',
});

export const TextBottom = styled(Typography)({
  fontSize: 12,
  color: '#312F37',
  textAlign: 'center',
});
