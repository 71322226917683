import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  makeStyles,
  Switch,
  FormControlLabel,
  IconButton,
  Menu,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BLOCK_LIST_STATUS_PERFORM } from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import { dateFormatter } from '../../helpers/DateUtils';
import { updatePayerBlocklist } from '../../actions/payerBlocklistAction';
import { t13s } from '../../translationKeys';
import {
  BLOCKLIST_REASONS,
  BLOCKLIST_REASONS_CL,
} from '../../helpers/Constants';
import MenuItem from '../elements/MenuItem';
import { Edit, More, History } from '../icons';

const useStyles = makeStyles({
  checkbox: {
    margin: 0,
  },
});

const PayerBlocklistTable = ({
  tableData,
  loadingPayersBlocklist,
  handleChangePage,
  handleOpenHistoryLogs,
  handleOpenEditReason,
  pagination,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user, rules } = useSelector(state => state.auth);
  const [anchorElement, setAnchorElement] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [reason, setReason] = useState('');

  const handleClick = (event, id, currentReason) => {
    setAnchorElement(event.currentTarget);
    setRowId(id);
    setReason(currentReason);
  };

  const isActive = active => (active ? 'Activo' : 'Inactivo');

  const getColumns = () => {
    return [
      {
        title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} del Pagador`,
        field: 'payerIdentifier',
        name: 'payerIdentifier',
        width: '120px',
        render: rowData => {
          return (
            <Typography color="textPrimary" variant="body2">
              {rowData.payerIdentifier}
            </Typography>
          );
        },
      },
      {
        title: 'Nombre del Pagador',
        field: 'payerName',
        name: 'payerName',
        width: 410,
        render: rowData => {
          return (
            <Typography color="textPrimary" variant="body2">
              {rowData.payerName || '-'}
            </Typography>
          );
        },
      },
      {
        title: 'Motivo',
        field: 'reason',
        name: 'reason',
        width: 210,
        render: rowData => {
          return (
            <Typography color="textPrimary" variant="body2">
              {[...BLOCKLIST_REASONS, ...BLOCKLIST_REASONS_CL].find(
                ({ value }) => rowData.reason === value
              )?.label || 'Old_Status'}
            </Typography>
          );
        },
      },
      {
        title: 'Fecha de Creación',
        field: 'createdAt',
        name: 'createdAt',
        width: '150px',
        render: rowData => {
          return (
            <Typography color="textPrimary" variant="body2">
              {dateFormatter(rowData.createdAt)}
            </Typography>
          );
        },
      },
      {
        title: 'Fecha de Actualización',
        field: 'updatedAt',
        name: 'updatedAt',
        width: '150px',
        render: rowData => {
          return (
            <Typography color="textPrimary" variant="body2">
              {dateFormatter(rowData.updatedAt)}
            </Typography>
          );
        },
      },
      {
        title: 'Estado',
        name: 'active',
        field: 'active',
        width: '150px',
        render: rowData => {
          const isAuth = checkAuth(
            user.roles,
            BLOCK_LIST_STATUS_PERFORM,
            rules
          );

          return (
            <>
              {isAuth ? (
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Switch
                      size="small"
                      checked={rowData.active}
                      onChange={() =>
                        dispatch(
                          updatePayerBlocklist(rowData.id, {
                            active: !rowData.active,
                          })
                        )
                      }
                    />
                  }
                  label={isActive(rowData.active)}
                />
              ) : (
                <Typography variant="body2" color="textPrimary">
                  {isActive(rowData.active)}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        width: '100px',
        render: rowData => {
          return (
            <IconButton
              onClick={e => {
                handleClick(e, rowData.id, rowData.reason);
              }}
            >
              <More />
            </IconButton>
          );
        },
      },
    ];
  };

  return (
    <>
      <MaterialTable
        columns={getColumns()}
        data={tableData}
        isLoading={loadingPayersBlocklist}
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(0, rowsPerPage)}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination.totalData}
              page={pagination.page - 1}
              onChangePage={(_e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page + 1, props.rowsPerPage)
              }
            />
          ),
          Toolbar: () => null,
        }}
        localization={{
          pagination: {
            labelRowsSelect: 'pagadores',
            labelRowsPerPage: 'Pagadores por página',
            labelDisplayedRows: '{count} pagadores totales',
          },
          body: {
            emptyDataSourceMessage: 'No hay pagadores para mostrar',
          },
        }}
        options={{
          showTitle: false,
          emptyRowsWhenPaging: false,
          filtering: false,
          search: false,
          pageSize: pagination.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      <Menu
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
      >
        <MenuItem
          icon={<History />}
          onClick={() => {
            handleOpenHistoryLogs({
              contextId: rowId,
              contextType: 'PAYER_BLOCKLIST',
            });
            setAnchorElement(null);
          }}
        >
          Historial de cambios
        </MenuItem>
        <MenuItem
          icon={<Edit />}
          onClick={() => {
            handleOpenEditReason({
              reason,
              blockId: rowId,
            });
            setAnchorElement(null);
          }}
        >
          Editar motivo
        </MenuItem>
      </Menu>
    </>
  );
};

PayerBlocklistTable.propTypes = {
  tableData: PropTypes.arrayOf(Object).isRequired,
  loadingPayersBlocklist: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenHistoryLogs: PropTypes.func.isRequired,
  handleOpenEditReason: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalData: PropTypes.number,
  }).isRequired,
};

export default PayerBlocklistTable;
