import { Source, GetCsvTemplateReturn } from './FileUpload.type';

export const getCsvTemplate = (source: Source): GetCsvTemplateReturn => {
  const csvTypes = {
    partialPayment: {
      sourceType: 'orderinvoice',
      sourceId: 212428,
      amount: 1000,
      paymentDate: '2022-01-02',
      comment: '-',
    },
    invoicesCL: {
      orderInvoiceId: 123,
      paymentConfirmed: '2022-01-01',
      paymentDate: '2022-01-02',
      fundPaymentDate: '2022-01-02',
    },
    invoicesMX: {
      uuid: '088A54E8-46EA-474E-A99C-FBB1F67EE1E5',
      paymentConfirmed: '2022-01-01',
      paymentDate: '2022-01-02',
      fundPaymentDate: '2022-01-02',
    },
    invoicesStatus: {
      orderInvoiceId: '200320',
      status: 'ACTIVE',
      statusDate: '2022-01-02',
    },
    fundInvoice: {
      orderInvoiceId: 1,
      fundId: 1,
      nominaId: 1,
      folio: '12345',
      startDate: '2022-01-01',
      expirationDate: '2022-01-02',
      interest: 1000,
      baseRate: 1.1,
      debtRate: 1.8,
      debtDays: 20,
      advancePercentage: 90,
    },
    payer: {
      identifier: 'ABC123456789',
      ranking: 9999,
      name: 'business example',
      taxSubSectorId: 0,
      economicGroupId: 0,
    },
    contact: {
      identifier: 'ABC123456789',
      name: 'contact example',
      email: 'contact@example.com',
      phone: '123456789',
      type: 'PAYMENT',
    },
    movements: {
      'Fecha Operacion': '44749',
      Concepto: 'PAGO CUENTA DE TERCERO',
      Referencia: '0127959337  027',
      'Referencia Ampliada': '7106641F9138 OP9538',
      Cargo: ' ',
      Abono: '69600.24',
      Saldo: '70382524.39',
    },
    sameBankComplementaryInfo: {
      'Cuenta beneficiaria': '0117050817',
      'Titular cuenta': 'ALIMENTARIA REGIONAL SA',
      'Numero banco receptor': '023',
      'Cuenta ordenante': '1929129829',
      'Cuenta CLABE ordenante': '3079178201639162707',
      Divisa: 'MXP',
      'Titular cuenta ordenante': 'RAUL MERCEDES',
      'RFC ordenante': 'DIR9270017',
      'Fecha de pago': '2022-07-07',
      'Numero de movimiento': '000008719',
      'Codigo de leyenda': 'N06',
      Concepto: 'PAGO CUENTA DE TERCERO',
      'Debe-Haber': '2',
      Importe: '0000000004000.00',
      'Efectivo real': '0000000000000.00',
      'Referencia numerica': '6208189786',
      'Referencia ampliada': 'BNET    159536762 8892',
      'Forma de pago': '99',
    },
    interbankInquiry: {
      Fecha: '2022-07-08 14:18:27',
      'Referencia numerica': '9489043',
      'Concepto de codigo de leyenda': 'SPEI RECIBOSBANCO',
      Referencia: '0173247937 014',
      'Concepto de pago': 'OPERACION 9460',
      Importe: '55643.92',
      Saldo: '55722671.90',
      'Banco ordenante': 'BANCO NEW',
      'Nombre ordenante': 'CARMEN RUIZ',
      'Cuenta ordenante': '00014617655076298176',
      'Banco beneficiario': 'BBVA RUIZ',
      'Nombre beneficiario': 'TOMATOES SUGAR',
      'Cuenta beneficiario': '00012180001170910902',
      'Clave de rastreo': '2022077182003 BET0000494862738',
      'Estado del pago': 'ABONADA',
      'Motivo de devolucion': '40N',
    },
  };

  return csvTypes[source];
};

export const onDrop = (files: any, source: Source) => {
  const file = files?.length ? files[0].file : [];
  return source === 'payer' || source === 'contact' ? files : file;
};
