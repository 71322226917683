import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { settings } from '../../../../../config/settings';
import { useEditTransferAccounts } from '../../../infrastructure/store';

const useEditTransferAccountDrawer = (
  orderId,
  businessId,
  selectedBankAccount,
  refetchTransferAccounts,
  handleCloseEditBankAccountDrawer
) => {
  const { country } = useSelector(state => state.config);

  const {
    alias,
    bankId,
    bankName,
    currency,
    sourceIdentifier,
    accountType,
    accountEmail,
    accountNumber,
    accountHolder,
  } = selectedBankAccount || {};

  const initialState = {
    alias,
    bankId,
    bankName,
    currency,
    sourceIdentifier,
    accountType,
    accountEmail,
    accountNumber,
    accountHolder,
  };

  const currencies = [
    {
      label: settings[country].currencyCode,
    },
    { label: 'USD' },
  ];

  const [bankAccountData, setBankAccountData] = useState(initialState);

  const onSuccessCallback = () => {
    handleCloseEditBankAccountDrawer();
    refetchTransferAccounts();
  };

  const { editTransferAccounts, isLoading: isEditingTransferAccounts } =
    useEditTransferAccounts();

  useEffect(() => {
    setBankAccountData(initialState);
  }, [selectedBankAccount]);

  const handleInputChange = (field, value) => {
    let finalValue = value;
    if (field === 'accountNumber') {
      finalValue = value.replace(/[^0-9]/g, '');
    }

    setBankAccountData({
      ...bankAccountData,
      [field]: finalValue,
    });
  };

  const handleEditTransferAccount = ({
    bankAccountData,
    accountId,
    businessId,
    originalAccount,
    orderId,
  }) => {
    editTransferAccounts({
      bankAccountData,
      accountId,
      businessId,
      originalAccount,
      orderId,
      onSuccessCallback,
    });
  };

  return {
    currencies,
    bankAccountData,
    handleInputChange,
    handleEditTransferAccount,
    isEditingTransferAccounts,
  };
};

export default useEditTransferAccountDrawer;
