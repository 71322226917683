// TYPES
export const DIRECT_FINANCING = 'DIRECT_FINANCING';
export const EARLY_PAYMENT = 'EARLY_PAYMENT';
export const CONFIRMING = 'CONFIRMING';
export const PAYMENTS = 'PAYMENTS';
export const DF_LABEL = 'Financiamiento Directo';
export const EP_LABEL = 'Pronto Pago';
export const CO_LABEL = 'Payments';
export const ORDER_TYPES_LABEL = {
  DIRECT_FINANCING: DF_LABEL,
  EARLY_PAYMENT: EP_LABEL,
  CONFIRMING: CO_LABEL,
};

// SINGLE STATUS
export const STATUS_IN_REVIEW = 'IN_REVIEW';
export const STATUS_REVIEWED = 'REVIEWED';
export const STATUS_APPEALED = 'APPEALED';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_APPROVED_FUND = 'APPROVED_FUND';
export const STATUS_TO_DEPOSIT = 'TO_DEPOSIT';
export const STATUS_REPLACED = 'REPLACED';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_DEFAULT = 'DEFAULT';
export const STATUS_PAID = 'PAID';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_COMPLETE = 'COMPLETE';
export const STATUS_PV_APPROVED = 'PV_APPROVED';
export const STATUS_RENEGOTIATION = 'RENEGOTIATION';
export const STATUS_VERIFIED = 'VERIFIED';
export const STATUS_UNVERIFIED = 'UNVERIFIED';
export const STATUS_POST_VERIFIED = 'POST_VERIFIED';
export const STATUS_TRANSFERRED = 'TRANSFERRED';
export const STATUS_PRIORITY_PAYMENT = 'PRIORITY_PAYMENT';
export const STATUS_DELINQUENT = 'DELINQUENT';
export const STATUS_SOFT_DELINQUENCY = 'SOFT_DELINQUENCY';
export const STATUS_HARD_DELINQUENCY = 'HARD_DELINQUENCY';
export const STATUS_HARD_COLLECTION = 'HARD_COLLECTION';
export const STATUS_PENDING = 'PENDING';
export const STATUS_GROUP_CREDITS = 'CREDITS';
export const DEH_DESCRIPTION = 'Acuse Recibo';
export const STATUS_CEDED = 'Cedida';
export const STATUS_CLAIMED = 'Reclamada';
export const STATUS_CANCELED = 'Anulada';
export const STATUS_INCASH_OR_FREE = 'Contado/Gratuito';
export const STATUS_PAUSED = 'PAUSED';
export const STATUS_IN_CONCILIATION = 'IN_CONCILIATION';
export const OPERATION_TYPES = 'OPERATION_TYPES';

// SETTINGS STATUS
export const STATUS_ORDER_TYPE = 'ORDER';
export const STATUS_INVOICE_TYPE = 'INVOICE';
export const STATUS_CREDIT_TYPE = 'CREDIT';
export const STATUS_FEE_TYPE = 'FEE';
export const ACTION_VIEW_TYPE = 'VIEW';
export const ACTION_CHANGE_TYPE = 'CHANGE';
export const STATUS_FAILED = 'FAILED';
export const STATUS_SUCCESS = 'SUCCESS';
export const IN_REVIEW = 'En Revisión';
export const REVIEWED = 'Revisado';
export const APPEALED = 'Apelado';
export const APPROVED = 'Aprobado';
export const APPROVED_FUND = 'Aprobado Fondo';
export const PENDING_SIGNATURE = 'Pendiente Firma';
export const POST_VERIFIED = 'Post-Verificado';
export const PV_APPROVED = 'PV Aprobado';
export const VERIFIED = 'Verificado';
export const TO_DEPOSIT = 'Por Depositar';
export const ACTIVE = 'Activo';
export const DEFAULT = 'Default Contable';
export const PAID = 'Pagado';
export const COMPLETE = 'Completo';
export const REJECTED = 'Rechazado';
export const REPLACED = 'Reemplazado';
export const TRANSFERRED = 'Transferido';
export const SOFT_DELINQUENCY = 'Mora Blanda';
export const HARD_DELINQUENCY = 'Default Interno';
export const DELINQUENT = 'Mora';
export const HARD_COLLECTION = 'Estado Crítico';
export const PRIORITY_PAYMENT = 'Pago Prioritario';
export const IN_CONCILIATION = 'En conciliación';
export const RENEGOTIATION = 'Renegociado';

export const PAYROLL_STATUS = {
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'En Proceso',
  },
  TO_PAY: {
    value: 'TO_PAY',
    label: 'Por Pagar',
  },
  PAID: {
    value: 'PAID',
    label: 'Pagada',
  },
  PAYMENT_VERIFIED: {
    value: 'PAYMENT_VERIFIED',
    label: 'Pago Confirmado',
  },
  REJECTED: {
    value: 'REJECTED',
    label: 'Rechazada',
  },
};

export const CRITICAL_INVOICES_STATUS_GROUP = [
  'IN_REVIEW',
  'APPROVED',
  'TRANSFERRED',
];

export const STATUS_IN_BLOCK = 'IN_BLOCK';
export const STATUS_BLOCKED = 'BLOCKED';

export const DEFAULT_STATUS = [
  // IN REVIEW
  {
    key: STATUS_IN_REVIEW,
    label: 'En Revisión',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_REVIEWED,
    label: 'Revisado',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_APPEALED,
    label: 'Apelable',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_APPROVED,
    label: 'Aprobado',
    group: STATUS_IN_REVIEW,
    country: ['CL', 'MX'],
  },
  // APPROVED
  {
    key: STATUS_APPROVED_FUND,
    label: 'Aprobado Fondo',
    group: STATUS_APPROVED,
    country: ['CL'],
  },
  {
    key: STATUS_APPROVED_FUND,
    label: 'Pendiente Firma',
    group: STATUS_APPROVED,
    country: ['MX'],
  },
  {
    key: STATUS_TO_DEPOSIT,
    label: 'Por Depositar',
    group: STATUS_APPROVED,
    country: ['CL', 'MX'],
  },
  // TRANSFERRED
  {
    key: STATUS_ACTIVE,
    label: 'Activo',
    group: STATUS_TRANSFERRED,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_DEFAULT,
    label: DEFAULT,
    group: STATUS_TRANSFERRED,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_IN_CONCILIATION,
    label: 'En conciliación',
    group: STATUS_IN_CONCILIATION,
    country: ['CL', 'MX'],
  },
  // PAID
  {
    key: STATUS_PAID,
    label: 'Pagado',
    group: STATUS_PAID,
    country: ['CL', 'MX'],
  },
  {
    key: STATUS_COMPLETE,
    label: 'Completo',
    group: STATUS_PAID,
    country: ['CL', 'MX'],
  },
  // REJECTED
  {
    key: STATUS_REJECTED,
    label: 'Rechazado',
    group: STATUS_REJECTED,
    country: ['CL', 'MX'],
  },
];

export const STATUSES_VERIFIED = [
  {
    key: STATUS_POST_VERIFIED,
    label: 'Post-Verificado',
  },
  { key: STATUS_VERIFIED, label: 'Verificado' },
  { key: STATUS_UNVERIFIED, label: 'Sin verificar' },
];

export const STATUSES_PAYMENT = {
  factura: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_PRIORITY_PAYMENT,
      label: 'Pago Prioritario',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_SOFT_DELINQUENCY,
      label: 'Mora Blanda',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_DELINQUENCY,
      label: 'Default Interno',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_DELINQUENT,
      label: 'Mora',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_COLLECTION,
      label: 'Estado Crítico',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_REPLACED,
      label: 'Reemplazado',
      group: STATUS_REPLACED,
      country: ['CL', 'MX'],
    },
  ],
  facturasBulk: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_PRIORITY_PAYMENT,
      label: 'Pago Prioritario',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_SOFT_DELINQUENCY,
      label: 'Mora Blanda',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_DELINQUENCY,
      label: 'Default Interno',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_DELINQUENT,
      label: 'Mora',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_HARD_COLLECTION,
      label: 'Estado Crítico',
      group: STATUS_TRANSFERRED,
      country: ['CL', 'MX'],
    },
  ],
  order: [
    ...DEFAULT_STATUS.filter(status => status.key !== STATUS_IN_CONCILIATION),
    {
      key: STATUS_PV_APPROVED,
      label: 'PV Aprobado',
      group: STATUS_APPROVED,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_RENEGOTIATION,
      label: 'Renegociado',
      group: STATUS_REJECTED,
      country: ['CL', 'MX'],
    },
  ],
  fee: [
    {
      key: STATUS_ACTIVE,
      label: 'Activo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_PAID,
      label: 'Pagado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_REJECTED,
      label: 'Rechazado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
  ],
  credit: [
    {
      key: STATUS_PENDING,
      label: 'Pendiente',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_APPROVED,
      label: 'Aprobado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_APPROVED_FUND,
      label: 'Aprobado Fondo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL'],
    },
    {
      key: STATUS_APPROVED_FUND,
      label: 'Pendiente Firma',
      group: STATUS_GROUP_CREDITS,
      country: ['MX'],
    },
    {
      key: STATUS_TO_DEPOSIT,
      label: 'Por Depositar',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_ACTIVE,
      label: 'Activo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_PAID,
      label: 'Pagado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_COMPLETE,
      label: 'Completo',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
    {
      key: STATUS_REJECTED,
      label: 'Rechazado',
      group: STATUS_GROUP_CREDITS,
      country: ['CL', 'MX'],
    },
  ],
  payrollFunds: Object.keys(PAYROLL_STATUS).map(status => {
    const { value, label } = PAYROLL_STATUS[status];
    return {
      key: value,
      label,
    };
  }),
  checkInvoice: STATUSES_VERIFIED,
  operationTypes: [
    {
      key: EARLY_PAYMENT,
      label: EP_LABEL,
      group: OPERATION_TYPES,
      country: ['CL', 'MX'],
    },
    {
      key: DIRECT_FINANCING,
      label: DF_LABEL,
      group: OPERATION_TYPES,
      country: ['CL', 'MX'],
    },
    {
      key: CONFIRMING,
      label: CO_LABEL,
      group: OPERATION_TYPES,
      country: ['CL', 'MX'],
    },
  ],
};
