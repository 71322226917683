import React, { FC } from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  styled,
  Typography,
  Box,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Dropzone from '../../../components/elements/Dropzone/Dropzone';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import AlertForm from '../../../components/elements/AlertForm';
import AlertMessage from '../../../components/elements/AlertMessage';

const CustomOuterGrid = styled(Grid)({
  marginBottom: 20,
});

const CustomInnerGrid = styled(Grid)({
  marginBottom: 24,
});

const CustomButton = styled(Button)({
  paddingLeft: 0,
});

interface Props {
  open: boolean;
  saving: boolean;
  errorsCsv?: any;
  title: string;
  description?: string;
  serverError?: string | null;
  csvTemplate?: any;
  handleLoad: () => void;
  resetDataAndErrors?: () => void;
  handleCloseDialog: () => void;
}

const FileUploadModal: FC<Props> = ({
  open,
  saving,
  title,
  description = '',
  csvTemplate = [],
  errorsCsv = null,
  serverError = null,
  handleCloseDialog,
  handleLoad,
  resetDataAndErrors,
}) => {
  const errorMessage = `El archivo cargado presenta ${errorsCsv?.length}
  error${errorsCsv?.length > 1 ? 'es' : ''}`;

  const alertMessageComponent = (
    <>
      <Grid item>
        <Typography variant="body1" component="div">
          <Box fontWeight="bold">{errorMessage}</Box>
        </Typography>
      </Grid>
      <Grid item>
        <CustomButton color="primary" variant="text">
          <CSVLink
            data={errorsCsv}
            separator=","
            filename="errores"
            asyncOnClick
            onClick={(event, done) => {
              done();
            }}
          >
            Descargar el detalle
          </CSVLink>
        </CustomButton>
      </Grid>
    </>
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
      size="md"
    >
      <Grid container>
        <CustomOuterGrid container item xs={12} justifyContent="center">
          {errorsCsv?.length > 0 && (
            <CustomInnerGrid item xs={12}>
              <AlertMessage
                onClear={resetDataAndErrors}
                messageComponent={alertMessageComponent}
              />
            </CustomInnerGrid>
          )}

          {serverError && (
            <Grid item xs={12}>
              <AlertForm message={serverError} variant="error" />
            </Grid>
          )}

          {saving ? (
            <CircularProgress color="primary" size={60} />
          ) : (
            <Dropzone handleSubmit={handleLoad} maxFiles={1} accept=".csv" />
          )}
        </CustomOuterGrid>

        <Grid container item xs={12} justifyContent="center">
          <Button
            startIcon={<CloudDownloadIcon color="primary" />}
            color="primary"
            disabled={saving}
          >
            <CSVLink
              data={csvTemplate}
              separator=","
              filename="data_template"
              asyncOnClick
              onClick={(event, done) => {
                done();
              }}
            >
              Descargar planilla
            </CSVLink>
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default FileUploadModal;
