import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FileUploadDialog } from '../../../commons/components';
import { useInvoicesBulkUpdate } from '../../infrastructure/store';
import { csvErrorsReportBuilder } from '../../../../helpers/CsvErrorsReportBuilder';

const InvoicesBulkUpdateModal = ({ open, handleClose }) => {
  const { country } = useSelector(state => state.config);
  const {
    uploadFile,
    resetDataAndErrors,
    isLoading,
    hasErrors,
    serverError,
    isSuccess,
    errorLines,
  } = useInvoicesBulkUpdate();

  const TITLE = 'Actualización masiva de facturas';
  const DESCRIPTION =
    'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

  const CSV_TEMPLATE = {
    CL: [
      {
        folio: 123,
        identifier: '17654321-9',
        amount: 1500,
        paymentConfirmed: '2021-01-01',
        paymentDate: '2021-01-02',
        fundPaymentDate: '2021-01-02',
      },
    ],
    MX: [
      {
        uuid: '088A54E8-46EA-474E-A99C-FBB1F67EE1E5',
        paymentConfirmed: '2021-01-01',
        paymentDate: '2021-01-02',
        fundPaymentDate: '2021-01-02',
      },
    ],
  };

  if (!open || (isSuccess && !hasErrors)) {
    return null;
  }

  const onClose = () => {
    handleClose();
    resetDataAndErrors();
  };

  return (
    <FileUploadDialog
      open={open}
      handleCloseDialog={onClose}
      handleLoad={uploadFile}
      saving={isLoading}
      errorsCsv={csvErrorsReportBuilder(errorLines)}
      serverError={serverError}
      title={TITLE}
      description={DESCRIPTION}
      csvTemplate={CSV_TEMPLATE[country]}
      actionText="Subir archivo"
      resetDataAndErrors={resetDataAndErrors}
    />
  );
};

InvoicesBulkUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InvoicesBulkUpdateModal;
