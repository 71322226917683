import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Typography, Grid, Radio } from '@material-ui/core';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import {
  IN_REVIEW,
  REVIEWED,
  APPEALED,
  APPROVED,
  APPROVED_FUND,
  POST_VERIFIED,
  PV_APPROVED,
  VERIFIED,
  TO_DEPOSIT,
  ACTIVE,
  DEFAULT,
  PAID,
  COMPLETE,
  REJECTED,
  TRANSFERRED,
  REPLACED,
} from '../../helpers/Constants';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import StatusChip from '../elements/chips/StatusChip';
import { dateFormatter } from '../../helpers/DateUtils';

const OrdersTableMinified = ({
  ordersData,
  ordersLoading,
  handleChangePage,
  handleChangeRowSelection,
  pagination,
  singleSelect,
}) => {
  const { country } = useSelector(state => state.config);

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const getColumns = () => {
    const columns = [
      {
        title: 'Order ID',
        type: 'numeric',
        width: '30px',
        align: 'left',
        render: rowData => {
          const { id } = rowData;
          return (
            <Typography variant="body2" color="textPrimary">
              {id}
            </Typography>
          );
        },
      },
      {
        title: 'Fecha Solicitud',
        type: 'date',
        align: 'center',
        width: '150px',
        headerStyle: { paddingLeft: 32 },
        render: rowdata => {
          const { createdAt } = rowdata;
          const issuedDate =
            rowdata.issuedDate || rowdata.OrderDetails[0]?.issuedDate;
          return (
            <Typography align="center" variant="body2" color="textPrimary">
              {dateFormatter(issuedDate || createdAt)}
            </Typography>
          );
        },
      },
      {
        title: 'Transferencia',
        type: 'numeric',
        align: 'center',
        width: '300px',
        headerStyle: { paddingLeft: 32 },
        render: rowData => {
          const transfer =
            rowData.transfer ?? rowData.OrderDetails[0]?.montoTransferencia;
          return (
            <Typography align="center" variant="body2" color="textPrimary">
              <CountryFormatHelper
                value={transfer}
                variant="currency"
                countryId={country}
              />
            </Typography>
          );
        },
      },
      {
        title: 'Intereses',
        type: 'numeric',
        align: 'center',
        width: '150px',
        headerStyle: { paddingLeft: 32 },
        render: rowData => {
          const interest =
            rowData.interest ?? rowData.OrderDetails[0]?.intereses;
          return (
            <Typography align="center" variant="body2" color="textPrimary">
              <CountryFormatHelper
                value={interest}
                variant="currency"
                countryId={country}
              />
            </Typography>
          );
        },
      },
      {
        title: 'Tasa base',
        type: 'numeric',
        align: 'center',
        headerStyle: { paddingLeft: 32 },
        render: rowData => {
          const baseRate =
            rowData.baseRate ?? rowData.OrderDetails[0]?.tasaBase;
          return (
            <Typography align="center" variant="body2" color="textPrimary">
              <CountryFormatHelper
                value={baseRate}
                variant="percentage"
                countryId={country}
              />
            </Typography>
          );
        },
      },
      {
        title: 'Estado',
        type: 'string',
        align: 'center',
        lookup: {
          IN_REVIEW,
          REVIEWED,
          APPEALED,
          APPROVED,
          APPROVED_FUND,
          POST_VERIFIED,
          PV_APPROVED,
          VERIFIED,
          TO_DEPOSIT,
          ACTIVE,
          DEFAULT,
          PAID,
          COMPLETE,
          REJECTED,
          TRANSFERRED,
          REPLACED,
        },
        headerStyle: { paddingLeft: 32 },
        render: rowData => {
          const { status } = rowData;

          return <StatusChip key={status} status={status} entityType="order" />;
        },
      },
    ];

    if (singleSelect) {
      columns.unshift({
        title: '',
        type: 'boolean',
        width: '30px',
        align: 'center',
        render: rowData => {
          const { id } = rowData;

          const handleChange = () => {
            setSelectedOrderId(id);
            handleChangeRowSelection(rowData);
          };

          return (
            <Radio
              name="selectRow"
              onChange={handleChange}
              checked={selectedOrderId === id}
            />
          );
        },
      });
    }
    return columns;
  };

  return (
    <Grid container item xs={12}>
      <MaterialTable
        columns={getColumns()}
        data={ordersData}
        isLoading={ordersLoading}
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        onSelectionChange={handleChangeRowSelection}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(1, rowsPerPage)}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={pagination?.totalData}
              page={pagination?.page - 1}
              onChangePage={(e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page, props.rowsPerPage)
              }
            />
          ),
          Toolbar: () => null,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No tenemos ordenes para mostrar',
          },
          pagination: {
            labelRowsPerPage: 'Ordenes por página',
            labelDisplayedRows: '{count} ordenes totales',
          },
        }}
        options={{
          showTitle: false,
          emptyRowsWhenPaging: false,
          selection: !singleSelect,
          filtering: false,
          search: false,
          actionsColumnIndex: -1,
          pageSize: pagination?.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </Grid>
  );
};

OrdersTableMinified.defaultProps = {
  singleSelect: false,
};

OrdersTableMinified.propTypes = {
  ordersData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      transfer: PropTypes.string,
      interest: PropTypes.string,
      baseRate: PropTypes.string,
      OrderDetails: PropTypes.arrayOf(
        PropTypes.shape({
          montoTransferencia: PropTypes.number,
          intereses: PropTypes.number,
          tasaBase: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  ordersLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowSelection: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    totalData: PropTypes.number,
    totalPages: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  singleSelect: PropTypes.bool,
};

export default OrdersTableMinified;
