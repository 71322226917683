// USER LOG
export const CONTEXT_TYPES = {
  business: 'BUSINESS',
  order: 'ORDER',
  orderFacturas: 'ORDER_FACTURAS',
  agreement: 'AGREEMENT',
};

export const LOGS_MESSAGES = {
  CREATE: {
    ORDER: 'Nueva operación',
    DISCOUNT: 'Se agregó descuento',
    ORDER_DETAILS: 'Se genero nueva simulación',
    PFX_CERTIFICATE: 'Se agregó archivo PFX',
    DICOM: 'Se consultó monto Dicom',
    BURO: 'Se consultó monto Buró',
    COMPLIANCE: 'Se consultó compliance',
    COMPLIANCE_LEGAL: 'Se consultó compliance de representante',
    DEBT_SUPER: 'Se consultó deuda financiera',
    ORDER_FACTURAS: 'Se creo una factura',
    ADD_ORDER_INVOICE: 'Se agregó una o más facturas a la operación.',
    PAYER_BLOCKLIST: 'Nueva empresa',
    AGREEMENT: 'Convenio creado',
    AGREEMENT_RATE: 'Tasa Mora Creada',
  },
  UPDATE: {
    AUTORATE: {
      active: 'Cambio en Autotasa',
    },
    status: 'Actualización de estado',
    fechaVencimiento: 'Cambio fecha de vencimiento',
    fundExpirationDate: 'Cambio fecha de vcto. de fondo',
    tasaBase: 'Cambio de tasa base',
    verificationStatus: 'Cambio de estado de verificación',
    isCanceled: 'Factura cancelada',
    debtDate: 'Cambio de fecha de mora',
    daysDebt: 'Cambio de dias a mora',
    daysIssued: 'Cambio en día de generación',
    isClaimed: 'Cambio estado de reclamo',
    score: 'Cambio de score',
    paymentConfirmed: 'Cambio fecha confirmación',
    paymentDate: 'Cambio fecha de pago',
    expirationDate: 'Cambio fecha de vencimiento',
    dehDescripcion: 'Cambio de descripcion',
    dehOrdenEvento: 'Cambio de Evento',
    dehOrdenEventoPublicar: 'Cambio de Evento Publicar',
    orderId: 'Se cambio de operación una factura',
    rate: 'Tasa Base Actualizada',
    bankName: 'Actualización cuenta bancaria',
    DISCOUNT: {
      amount: 'Cambio en el descuento',
      reason: 'Cambio en la razón de descuento',
    },
    ORDER_DETAILS: 'Se genero nueva simulación',
    BUSINESS: {
      rate: 'Cambio de tasa base',
      retention: 'Cambio de retención',
      hasConfirmingEnabled: 'Cambio confirming',
      autorate: 'Cambio autotasas',
    },
    active: 'Cambio estado',
    reason: 'Cambio motivo',
    MOVE_ORDER_INVOICE: {
      orderId: 'Se movieron una o más facturas de la operación',
    },
    AGREEMENT_ACTIVATED: {
      active: 'Convenio Activado',
    },
    AGREEMENT_PAUSED: {
      active: 'Convenio Pausado',
    },
    AGREEMENT_RATE: {
      rate: 'Tasa Mora Actualizada',
      days: 'Días de Mora Actualizada',
    },
    CHANGE_ORDER_TYPE: {
      orderType: 'Cambio de tipo de operación',
    },
  },
  DESTROY: {
    DELETE_ORDER_INVOICE: 'Se eliminaron una o más facturas de la operación.',
    DELETE_ORDER_INVOICE_HOLD:
      'Se eliminaron una o más facturas temporales de la operación.',
    AGREEMENT_RATE: 'Tasa Mora Eliminada',
  },
};

export const SUBCONTEXT_WITH_LABELS = ['ORDER_DETAILS', 'BUSINESS'];

export const CHANGES_DETAILS_GLOBAL = [
  {
    key: 'id',
    label: 'ID:',
    type: 'text',
  },
  {
    key: ['baseRate', 'rate'],
    label: 'Tasa Base:',
    type: 'percentage',
  },
  {
    key: 'retentionPct',
    label: 'Porcentaje de retención:',
    type: 'percentage',
  },
  {
    key: 'retention',
    label: 'Retención:',
    type: 'currency',
  },
  {
    key: 'discount',
    label: 'Descuento:',
    type: 'currency',
  },
  {
    key: 'operationCost',
    label: 'Asesoria digital:',
    type: 'currency',
  },
  {
    key: 'transfer',
    label: 'Monto de transferencia:',
    type: 'currency',
  },
  {
    key: 'issuedDate',
    label: 'Fecha de creación:',
    type: 'transformDate',
  },
  {
    key: 'interest',
    label: 'Diferencia de precio:',
    type: 'currency',
  },
  {
    key: 'checkAutoRate',
    label: 'Autotasa:',
    type: 'bool',
  },
  {
    key: ['autorate', 'hasConfirmingEnabled'],
    label: '',
    type: 'bool',
  },
  {
    key: [
      'debtDate',
      'fechaVencimiento',
      'paymentDate',
      'paymentConfirmed',
      'expirationDate',
      'fundExpirationDate',
    ],
    label: '',
    type: 'transformDate',
  },
  {
    key: ['status'],
    label: '',
    type: 'translateStates',
  },
  {
    key: ['daysDebt', 'score', 'dehDescripcion', 'verificationStatus'],
    label: '',
    type: 'transformInfoStatusVerified',
  },
  {
    key: ['isCanceled', 'isClaimed'],
    label: '',
    type: 'sino',
  },
  {
    key: ['type'],
    label: '',
    type: 'type',
  },
  {
    key: ['dehOrdenEvento'],
    label: '',
    type: 'getEventValue',
  },
  {
    key: ['dehOrdenEventoPublicar'],
    label: '',
    type: 'getEventPublisherValue',
  },
  {
    key: ['amount'],
    label: '',
    type: 'percentage',
  },
  {
    key: ['identifier'],
    label: '',
    type: 'text',
  },
  {
    key: ['reason'],
    label: '',
    type: 'translateReasons',
  },
  {
    key: ['daysIssued'],
    label: '',
    type: 'validNumber',
  },
  {
    key: ['active'],
    label: 'Estado',
    type: 'bool',
  },
  {
    key: 'orderType',
    label: '',
    type: 'type',
  },
  {
    key: ['folioId'],
    label: '',
    type: 'stringArray',
    helpHeader: 'folio - Id Factura',
  },
  {
    key: ['folios'],
    label: '',
    type: 'stringArray',
    helpHeader: 'folios',
  },
];
