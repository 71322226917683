import { Grid, Box, styled, makeStyles } from '@material-ui/core';
import { convertSpacingToCss } from '../../../helpers/stylesHelpers';

const ContainerRoot = styled(Grid)({
  padding: convertSpacingToCss('xl lg'),
});
const ContainerHeader = styled(Box)({
  marginBottom: 20,
});

export { ContainerRoot, ContainerHeader };

export const useStyles = makeStyles({
  layoutContainerStyles: {
    display: 'flex',
    backgroundColor: '#DDF6E3',
    textAlign: 'center',
    padding: '12px 0',
  },
});
