import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  btnContainer: {
    padding: '30px 20px 0',
  },
  rootContainer: {
    padding: '20px 20px 100px 20px',
  },
});
