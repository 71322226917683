import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, ListItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deleteContact } from '../../actions/contactsActions';
import PayerContactCard from './PayerContactCard';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { GRAY_COLOR } from '../../helpers/Constants';
import { settings } from '../../config/settings';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inlineListItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 300,
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Muli',
    fontSize: 20,
    color: GRAY_COLOR,
  },
  dialogMessage: {
    fontSize: '13px',
    color: GRAY_COLOR,
    marginLeft: 5,
    marginRight: 5,
  },
  dialogButton: {
    fontWeight: 'bold',
    fontFamily: 'Muli',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
});

const useStylesComponent = {
  confirmDialog: {
    height: 250,
  },
};

const CONTACTABLE_TYPE = 'payer';

const PayerContactContainer = ({
  payerId,
  type,
  contacts: payerContacts = [],
}) => {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [contactToDelete, setContactDelete] = useState({});

  const dispatch = useDispatch();

  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDeleteDialog = contactId => {
    setContactDelete(contactId);
    setOpenDelete(true);
  };

  const handleDeleteContact = (CONTACTABLE_TYPE, contactId) => {
    dispatch(deleteContact(CONTACTABLE_TYPE, payerId, contactId));
    handleCloseDelete();
  };

  return (
    <Grid container xs={12} className={classes.main}>
      {openDelete && (
        <ConfirmDialog
          styles={useStylesComponent.confirmDialog}
          isOpen={openDelete}
          handleClose={handleCloseDelete}
          title={
            <Typography className={classes.dialogTitle}>
              Eliminar contacto
            </Typography>
          }
          buttonOnAccept={() =>
            handleDeleteContact(CONTACTABLE_TYPE, contactToDelete)
          }
          buttonLabel={
            <Typography className={classes.dialogButton}>Eliminar</Typography>
          }
          message={
            <>
              <Typography className={classes.dialogMessage}>
                Si eliminas este contacto, no podrás recuperar sus datos.
              </Typography>
              <Typography className={classes.dialogMessage}>
                ¿Quieres eliminar este contacto?
              </Typography>
            </>
          }
        />
      )}
      {!payerContacts.length ? (
        <div>Agrega un contacto para ver su información aquí.</div>
      ) : (
        payerContacts.length &&
        payerContacts.map(contact => {
          //  MIGRACIONCL: APICL does not have contactableId, this is why we add it here manually. APIGLOBAL already has this property, so we should delete this when migration's over
          const payerContact = contact;
          if (!isApiGlobal) {
            payerContact.contactableId = payerId;
          }
          return (
            contact.type === type && (
              <ListItem className={classes.inlineListItem}>
                <PayerContactCard
                  payerContact={payerContact}
                  handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
              </ListItem>
            )
          );
        })
      )}
    </Grid>
  );
};

PayerContactContainer.propTypes = {
  payerId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf.isRequired,
};

export default PayerContactContainer;
