import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Divider, Drawer, Tab, Tabs } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { RootStateOrAny, useSelector } from 'react-redux';
import Text from '../../../commons/components/Text';
import { useCalcultateDebtPartialPayment } from '../../../../payments/details/infrastructure/store';
import { getCurrencyFormat } from '../../../../features/credit-line/utils';
import { useStyles } from './styles';
import { invoiceInformationTabs } from '../../../commons/utils/constants';
import ManagementTab from './folioTabs/ManagementTab';

type CollectionByPayerInvoiceInformationProps = {
  open: boolean;
  invoiceId: number;
  closeDrawer: () => void;
};

enum LabelTabs {
  Summary = 'Summary',
  Managements = 'Managements'
}

const CollectionByPayerInvoiceInformation = ({
  open,
  invoiceId,
  closeDrawer,
}: CollectionByPayerInvoiceInformationProps) => {
  const classes = useStyles();
  const { country } = useSelector((state: RootStateOrAny) => state.config);
  const { debtPartialPaymentServiceData, refetchCalculateDebtPartialPayment } =
    useCalcultateDebtPartialPayment({
      sourceType: 'orderinvoice',
      sourceId: invoiceId,
    });
  
  const [tabs, setTabs] = useState(invoiceInformationTabs[0].value);
  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    setTabs(newValue);
  };

  useEffect(() => {
    if (invoiceId) {
      refetchCalculateDebtPartialPayment();
    }
  }, [invoiceId]);

  return (
    <Drawer anchor="right" open={open} onClose={() => closeDrawer()}>
      <div style={{ padding: '20px', maxWidth: '350px' }}>
        <Box my={5}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <Text variant="h5" align="center" fontWeight="bold">
              Detalle de factura
            </Text>
            <Button onClick={() => closeDrawer()}>X</Button>
          </div>
          <Divider />
        </Box>
        <Box mt={5} mb={5} mr={5}>
          <Box mt={4}>
            <Tabs
              value={tabs}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => handleChange(e, value)}
            >
              {invoiceInformationTabs.map(tab => (
                <Tab
                  key={tab.value}
                  className={classes.tabLabel}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
            {tabs === LabelTabs.Summary && (
              <ul style={{ margin: 0, padding: 0 }}>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0',
                  }}
                >
                  <Text fontWeight="bold">Tasa de Mora</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    {!debtPartialPaymentServiceData?.data ? (
                      <Skeleton variant="text" />
                    ) : (
                      debtPartialPaymentServiceData.data.baseRate
                    )}
                  </Text>
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0',
                  }}
                >
                  <Text fontWeight="bold">Intereses a la fecha</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    {!debtPartialPaymentServiceData?.data ? (
                      <Skeleton variant="text" />
                    ) : (
                      getCurrencyFormat(
                        debtPartialPaymentServiceData.data.debtInterestAtDate,
                        country
                      )
                    )}
                  </Text>
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0',
                  }}
                >
                  <Text fontWeight="bold">Intereses pagados</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    {!debtPartialPaymentServiceData?.data ? (
                      <Skeleton variant="text" />
                    ) : (
                      getCurrencyFormat(
                        debtPartialPaymentServiceData.data.payedInterests,
                        country
                      )
                    )}
                  </Text>
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0',
                  }}
                >
                  <Text fontWeight="bold">Deuda capital</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    {!debtPartialPaymentServiceData?.data ? (
                      <Skeleton variant="text" />
                    ) : (
                      getCurrencyFormat(
                        Math.ceil(
                          debtPartialPaymentServiceData.data.totalCapitalDebt
                        ),
                        country
                      )
                    )}
                  </Text>
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0',
                  }}
                >
                  <Text fontWeight="bold">Total de la deuda</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    {!debtPartialPaymentServiceData?.data ? (
                      <Skeleton variant="text" />
                    ) : (
                      getCurrencyFormat(
                        Math.ceil(debtPartialPaymentServiceData.data.totalDebt),
                        country
                      )
                    )}
                  </Text>
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0',
                  }}
                >
                  <Text fontWeight="bold">Total menos pagos parciales</Text>
                  <Text style={{ marginLeft: 'auto' }}>
                    {!debtPartialPaymentServiceData?.data ? (
                      <Skeleton variant="text" />
                    ) : (
                      getCurrencyFormat(
                        Math.ceil(
                          debtPartialPaymentServiceData.data.payerDebtFD
                        ),
                        country
                      )
                    )}
                  </Text>
                </li>
              </ul>
            )}
            {tabs === LabelTabs.Managements && (
              <ManagementTab invoiceId={invoiceId} />
            )}
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default CollectionByPayerInvoiceInformation;
