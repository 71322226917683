import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createEntityFeature,
  updateEntityFeature,
  fetchEntityFeatures,
} from '../actions/entityFeatureActions';

/*  so in order to add a new feature you only need to declare it here below 
 and then add it to entityFeatureList, you have to check that 
 'featureId' matches ids with the Features Table on the BD */

export const ENTITY_FEATURE_CONFIRMING = {
  featureId: 2,
  keyName: 'confirming',
};

export const ENTITY_FEATURE_AUTOTASA = {
  featureId: 1,
  keyName: 'autotasa',
};

export const entityFeatureList = {
  [ENTITY_FEATURE_CONFIRMING.keyName]: ENTITY_FEATURE_CONFIRMING,
  [ENTITY_FEATURE_AUTOTASA.keyName]: ENTITY_FEATURE_AUTOTASA,
};

export const useEntityFeatures = (entity, entityId) => {
  const [currentEntityFeatures, setCurrentEntityFeatures] = useState();
  const { fetchEntityFeaturesIsLoading, entityFeatures } = useSelector(
    state => state.entityFeature
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (entityId && !entityFeatures?.find(e => e.sourceId === entityId)) {
      dispatch(fetchEntityFeatures(entity, entityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    if (entityFeatures?.length > 0) {
      Object.keys(entityFeatureList).forEach(key => {
        const currentValue = entityFeatures.find(
          ({ featureId }) => featureId === entityFeatureList[key]?.featureId
        );
        setCurrentEntityFeatures(a => ({
          ...a,
          ...(currentValue && { [key]: currentValue }),
        }));
      });
    }
  }, [entityFeatures]);

  const handleEntityStatus = selectedFeature => {
    const selectedFeatureCurrentValue =
      currentEntityFeatures?.[selectedFeature.keyName];
    if (selectedFeatureCurrentValue?.id) {
      const { id, active } = selectedFeatureCurrentValue;
      dispatch(updateEntityFeature(entity, entityId, id, !active));
    } else {
      dispatch(
        createEntityFeature(entity, entityId, selectedFeature.featureId)
      );
    }
  };

  return [
    currentEntityFeatures,
    handleEntityStatus,
    fetchEntityFeaturesIsLoading,
  ];
};

export default useEntityFeatures;
