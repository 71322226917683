import { DEFAULT_CAPITAL_COST_RATE } from '../../../../commons/constants/payroll';

export const updateByRecommendedRateUseCase = ({
  currentSelectorState,
  response,
  updateStaticFieldsOfReceivers,
}) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    if (updateStaticFieldsOfReceivers) {
      acc[receiverIdentifier] = { ...currentReceiver };

      return acc;
    }

    acc[receiverIdentifier] = {
      ...currentReceiver,
      ...response[receiverIdentifier],
      effectiveFundCostRate: response[receiverIdentifier].rate,
      capitalCostRate: DEFAULT_CAPITAL_COST_RATE,
      value: currentReceiver.value.map(doc => ({
        ...doc,
        ...response[receiverIdentifier],
        effectiveFundCostRate: response[receiverIdentifier].rate,
        capitalCostRate: DEFAULT_CAPITAL_COST_RATE,
      })),
    };

    return acc;
  }, {});
};
