import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { arrayToURL } from '../../../../helpers/url';

const fetchAvailableBusinessInvoicesService = async ({
  businessId,
  config: { filters = [{}], operationType, includeRate = false, defaultRate },
}) => {
  try {
    return await axios.get(`/api/business/${businessId}/available-invoices`, {
      params: {
        filters: arrayToURL(filters),
        includeRate,
        operationType,
        defaultRate,
        page: 1,
        limit: 50,
      },
    });
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchAvailableBusinessInvoicesService };
