import axios from 'axios';
import {
  FETCH_PAYERS_BLOCKLIST_START,
  FETCH_PAYERS_BLOCKLIST_SUCCESS,
  FETCH_PAYERS_BLOCKLIST_FAILURE,
  REGISTER_PAYER_BLOCKLIST_START,
  REGISTER_PAYER_BLOCKLIST_SUCCESS,
  REGISTER_PAYER_BLOCKLIST_FAILURE,
  UPDATE_PAYER_BLOCKLIST_START,
  UPDATE_PAYER_BLOCKLIST_SUCCESS,
  UPDATE_PAYER_BLOCKLIST_FAILURE,
  FETCH_PAYER_BLOCKLIST_START,
  FETCH_PAYER_BLOCKLIST_SUCCESS,
  FETCH_PAYER_BLOCKLIST_FAILURE,
  CSV_REGISTER_PAYERS_BLOCKLIST_START,
  CSV_REGISTER_PAYERS_BLOCKLIST_SUCCESS,
  CSV_REGISTER_PAYERS_BLOCKLIST_FAILURE,
  RESET_REGISTER_PAYER_BLOCKLIST_ERROR,
  RESET_SELECTED_PAYER_NAME,
  RESET_CSV_REGISTER_PAYERS_BLOCKLIST_ERROR,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchPayerBlocklist =
  ({ searchInput, field, size, page }) =>
  dispatch => {
    dispatch({ type: FETCH_PAYERS_BLOCKLIST_START });
    axios
      .get('/api/payerblocklist', {
        params: {
          searchInput,
          field,
          size,
          page,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({ type: FETCH_PAYERS_BLOCKLIST_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYERS_BLOCKLIST_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_PAYERS_BLOCKLIST_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const registerPayerBlocklist =
  (payerIdentifier, payerName, reason) => dispatch => {
    dispatch({ type: REGISTER_PAYER_BLOCKLIST_START });
    axios
      .post('/api/payerblocklist', { payerIdentifier, payerName, reason })
      .then(res => {
        const resData = res.data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PAYER_BLOCKLIST_CREATED, {
            variant: 'success',
          })
        );
        dispatch({ type: REGISTER_PAYER_BLOCKLIST_SUCCESS, payload: resData });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: REGISTER_PAYER_BLOCKLIST_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updatePayerBlocklist =
  (payerId, { payerIdentifier, payerName, active, reason }) =>
  dispatch => {
    dispatch({ type: UPDATE_PAYER_BLOCKLIST_START });
    axios
      .put(`/api/payerblocklist/${payerId}`, {
        payerIdentifier,
        payerName,
        active,
        reason,
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: UPDATE_PAYER_BLOCKLIST_SUCCESS,
          payload: resData,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PAYER_BLOCKLIST_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_PAYER_BLOCKLIST_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchPayerNameByIdentifier = payerIdentifier => dispatch => {
  dispatch({ type: FETCH_PAYER_BLOCKLIST_START });
  axios
    .get('/api/payerblocklist/infoTaxService', {
      params: {
        payerIdentifier,
      },
    })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_PAYER_BLOCKLIST_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYER_BLOCKLIST_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_PAYER_BLOCKLIST_FAILURE,
        payload: { errorCode },
      });
    });
};

export const bulkCsvRegisterPayersBlocklist =
  payersBlocklistData => dispatch => {
    dispatch({ type: CSV_REGISTER_PAYERS_BLOCKLIST_START });
    axios
      .post('/api/payerblocklist/bulk', payersBlocklistData)
      .then(res => {
        const resData = res.data;
        const { registeredPayers } = resData;
        if (registeredPayers.length) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.PAYERS_BLOCKLIST_CSV_REGISTERED, {
              variant: 'success',
            })
          );
        }
        dispatch({
          type: CSV_REGISTER_PAYERS_BLOCKLIST_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CSV_REGISTER_PAYERS_BLOCKLIST_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const resetRegisterPayerBlocklistError = () => dispatch => {
  dispatch({
    type: RESET_REGISTER_PAYER_BLOCKLIST_ERROR,
  });
};

export const resetSelectedPayerName = () => dispatch => {
  dispatch({
    type: RESET_SELECTED_PAYER_NAME,
  });
};

export const resetBulkCsvRegisterPayersBlocklist = () => dispatch => {
  dispatch({
    type: RESET_CSV_REGISTER_PAYERS_BLOCKLIST_ERROR,
  });
};
