import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SORTED_STATUS_GROUP,
  ORDER_STATUSES_GROUP,
  INVOICE_STATUSES_GROUP,
  CREDIT_STATUSES_GROUP,
  OPERATION_TYPES_STATUSES_GROUP,
} from '../../helpers/Constants';
import AutocompleteComponent from '../form/Autocomplete';
import { getVariantOrColor } from '../../helpers/Colors';

const useStyles = makeStyles({
  filterSelect: {
    width: 200,
  },
  groupHeader: {
    marginLeft: 10,
  },
});

const ORDER = 'order';
const INVOICE = 'invoice';
const CREDIT = 'credit';
const OPERATION_TYPE = 'operationType';

const StatusFilter = ({
  selectedStatuses,
  onChangeSelection,
  statuses,
  entityType,
  placeholder,
  label,
}) => {
  const classes = useStyles();
  const statusKeysAllowedByUserRole = useSelector(
    state => state.order.ordersAvailableFilters
  );

  const getStatusesGroup = group => {
    switch (entityType) {
      case ORDER:
        return ORDER_STATUSES_GROUP[group];
      case INVOICE:
        return INVOICE_STATUSES_GROUP[group];
      case CREDIT:
        return CREDIT_STATUSES_GROUP[group];
      case OPERATION_TYPE:
        return OPERATION_TYPES_STATUSES_GROUP[group];
      default:
        return ORDER_STATUSES_GROUP[group];
    }
  };

  const statusesAllowedByUserRole =
    entityType === ORDER
      ? statuses.filter(status =>
          statusKeysAllowedByUserRole.some(
            statusName => statusName === status.key
          )
        )
      : statuses;

  const sortedStatusAvailable = statusesAllowedByUserRole.sort(
    (a, b) =>
      SORTED_STATUS_GROUP.indexOf(a.group) -
      SORTED_STATUS_GROUP.indexOf(b.group)
  );

  const notifyChangeSelection = statusesData => {
    let sortedStatuses;
    if (!statusesData.length) {
      sortedStatuses = statusesData.map(status => status.key);
    } else {
      sortedStatuses = sortedStatusAvailable
        .filter(fullStatus => statusesData.includes(fullStatus.key))
        .map(fullStatus => fullStatus.key);
    }
    onChangeSelection(sortedStatuses);
  };

  const handleChangeMultiple = ([status]) => {
    const isAlreadySelected = selectedStatuses.includes(status.key);
    if (isAlreadySelected) {
      notifyChangeSelection(
        selectedStatuses.filter(selectedStatus => selectedStatus !== status.key)
      );
    } else {
      notifyChangeSelection([...selectedStatuses, status.key]);
    }
  };

  return (
    <AutocompleteComponent
      data={sortedStatusAvailable}
      multiple
      maxHeight={500}
      className={classes.filterSelect}
      label={label}
      placeholder={placeholder}
      onChange={handleChangeMultiple}
      data-qa="status-filter-autocomplete"
      renderOption={option => {
        const checked = selectedStatuses?.includes(option.key);
        const color = getVariantOrColor(entityType, option.key, true);
        return (
          <>
            <Checkbox
              key={option.key}
              checked={checked}
              style={{
                color,
                marginRight: 8,
              }}
              size="small"
              data-qa="status-filter-option"
            />
            <Typography variant="body2" color="textSecondary">
              {option.label}
            </Typography>
          </>
        );
      }}
      renderGroup={({ key, group, children }) => {
        return (
          <div key={key}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.groupHeader}
            >
              {getStatusesGroup(group).label}
            </Typography>
            {children}
          </div>
        );
      }}
      noOptionsText="Sin estados disponibles"
    />
  );
};

StatusFilter.defaultProps = {
  statuses: [],
  placeholder: 'Filtra por estado',
  label: 'Estados',
};

StatusFilter.propTypes = {
  onChangeSelection: PropTypes.func.isRequired,
  selectedStatuses: PropTypes.arrayOf(String).isRequired,
  entityType: PropTypes.oneOf([ORDER, INVOICE, CREDIT, OPERATION_TYPE])
    .isRequired,
  statuses: PropTypes.arrayOf({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  label: PropTypes.string,
};
export default StatusFilter;
