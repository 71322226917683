import {
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_TAX_SCRAPE_UPDATED_STORE,
} from '../actions/types';

const initialState = {
  wsconnected: false,
  loadStatus: '',
  loadPercentage: '',
  jobId: '',
  businessId: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WS_CONNECTED:
      return { ...state, wsconnected: true };
    case WS_TAX_SCRAPE_UPDATED_STORE:
      return {
        ...state,
        loadStatus: payload.jobState,
        loadPercentage: payload.jobProgress,
        jobId: payload.jobId,
        businessId: payload.businessId,
      };
    case WS_DISCONNECTED:
      return { ...state, wsconnected: false };
    default:
      return state;
  }
};
