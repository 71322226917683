import React, { useCallback } from 'react';
import invoicePendingRequirementsFirstStep from '../../../../../assets/invoicePendingRequirementsFirstStep.png';
import invoicePendingRequirementsSecondStep from '../../../../../assets/invoicePendingRequirementsSecondStep.png';
import invoicePendingRequirementsThirdStep from '../../../../../assets/invoicePendingRequirementsThirdStep.png';
import { useParams } from 'react-router-dom';
import useFetchInvoicePendingRequirements from '../infrastructure/store/useFetchPendingRequirements';
import { Params, PendingRequirementResponse } from '../interfaces';
import useRequirements from '../../RequirementsTab/hooks/useRequirements';
import CheckCircle from '../../../icons/CheckCircle';
import ExclamationIcon from '../../../icons/ExclamationIcon';
import DoneIcon from '../../../icons/DoneIcon';
import CloseIcon from '../../../icons/CloseIcon';

const useInvoicePendingRequirements = (
  requirementId: number,
  rawOperationId?: number
) => {
  const { id } = useParams<Params>();
  const {
    dictionary,
    requirementStatus,
    handleGetProgress,
    handleGetLinearProgressMulticolor,
  } = useRequirements();
  const operationId = Number(rawOperationId || id);
  const {
    isLoading: fetchInvoicePendingRequirementsIsLoading,
    invoicePendingRequirements,
  } = useFetchInvoicePendingRequirements(operationId, requirementId);

  const handleGetTableIconByStatus = useCallback(
    (status: string) => {
      if (status === requirementStatus.SUCCESS) {
        return <DoneIcon color="#75B801" />;
      }
      return <CloseIcon color="#D92F24" />;
    },
    [requirementStatus.SUCCESS]
  );

  const handleGetLinearProgressStatusIcon = useCallback(
    (successRequirements: number, totalRequirements: number) => {
      const percentage = handleGetProgress(
        successRequirements,
        totalRequirements
      );
      if (percentage === 100) {
        return <CheckCircle color="#75B801" />;
      }
      if (percentage > 50 && percentage < 100) {
        return (
          <ExclamationIcon
            color="#FFB100"
            style={{
              backgroundColor: '#FAE9C2',
            }}
          />
        );
      }
      return (
        <ExclamationIcon
          color="#D92F24"
          style={{
            backgroundColor: '#F3D2D0',
          }}
        />
      );
    },
    [handleGetProgress]
  );

  const handleGetDefaultTitleByKey = useCallback(
    (status: string) => {
      const title: { [key: string]: string } =
        {
          [dictionary.invoiceCeded]: 'Facturas con cesión',
          [dictionary.invoicePdf]: 'Facturas con PDF',
          [dictionary.invoiceVerified]: 'Facturas verificadas',
          [dictionary.invoicePaymentDateConfirmed]:
            'Facturas con fecha de pago',
          [dictionary.invoiceExecutiveMerit]: 'Facturas con mérito ejecutivo',
        } || '';
      return title[status];
    },
    [
      dictionary.invoiceCeded,
      dictionary.invoicePdf,
      dictionary.invoiceVerified,
      dictionary.invoicePaymentDateConfirmed,
      dictionary.invoiceExecutiveMerit,
    ]
  );

  const handleGetSucessTitleByKey = useCallback(
    (status: string) => {
      const title: { [key: string]: string } =
        {
          [dictionary.invoiceCeded]: 'No hay facturas por confirmar',
          [dictionary.invoicePdf]: 'Todas las facturas tienen PDF',
          [dictionary.invoiceVerified]: 'Todas las facturas fueron verificadas',
          [dictionary.invoicePaymentDateConfirmed]:
            'Todas las fechas de pago confirmadas',
          [dictionary.invoiceExecutiveMerit]:
            'No faltan facturas de mérito ejecutivo',
        } || '-';
      return title[status];
    },
    [
      dictionary.invoiceCeded,
      dictionary.invoicePdf,
      dictionary.invoiceVerified,
      dictionary.invoicePaymentDateConfirmed,
      dictionary.invoiceExecutiveMerit,
    ]
  );

  const handleGetTableHeader = useCallback(
    (status: string) => {
      const header: { [key: string]: string } =
        {
          [dictionary.invoiceCeded]: 'Cedida',
          [dictionary.invoicePdf]: 'PDF',
          [dictionary.invoiceVerified]: 'Verificada',
          [dictionary.invoicePaymentDateConfirmed]: 'Fecha de pago',
          [dictionary.invoiceExecutiveMerit]: 'Mérito ejecutivo',
        } || '-';
      return header[status];
    },
    [
      dictionary.invoiceCeded,
      dictionary.invoicePdf,
      dictionary.invoiceVerified,
      dictionary.invoicePaymentDateConfirmed,
      dictionary.invoiceExecutiveMerit,
    ]
  );

  const handleGetImageByProgress = useCallback(
    (successRequirements: number, totalRequirements: number) => {
      const percentage = handleGetProgress(
        successRequirements,
        totalRequirements
      );
      if (percentage === 100) {
        return invoicePendingRequirementsThirdStep;
      }
      if (percentage > 50 && percentage < 100) {
        return invoicePendingRequirementsSecondStep;
      }
      return invoicePendingRequirementsFirstStep;
    },
    [handleGetProgress]
  );


  const handleGetCountRequirements = useCallback(
    (invoicesRequirements: PendingRequirementResponse[]) => {
      const totalRequirements = invoicesRequirements.length || 0;
      const successRequirements =
        invoicesRequirements?.filter(
          ({ status }) => status === requirementStatus.SUCCESS
        )?.length || 0;

      return {
        totalRequirements,
        successRequirements,
      };
    },
    [requirementStatus.SUCCESS]
  );

  return {
    fetchInvoicePendingRequirementsIsLoading,
    invoicePendingRequirements,
    handleGetProgress,
    handleGetTableIconByStatus,
    handleGetLinearProgressMulticolor,
    handleGetLinearProgressStatusIcon,
    handleGetDefaultTitleByKey,
    handleGetSucessTitleByKey,
    handleGetTableHeader,
    handleGetImageByProgress,
    handleGetCountRequirements,
  };
};

export default useInvoicePendingRequirements;
