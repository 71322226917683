import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, styled } from '@material-ui/core';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { decimalFormatter, Text } from '../../../../commons/formatters';
import { COUNTRY_CODE_CL } from '../../../../../helpers/Constants';
import LinearProgress from '../../../../commons/components/LinearProgress';

const StatItem = styled(Grid)({
  marginBottom: 16,
});

const FinalAmountText = styled(Text)({
  fontSize: 18,
});

const CardStats = ({ expanded, payroll }) => {
  const {
    cessionAmount,
    cessionPercentage,
    supplierAmount,
    supplierPercentage,
    finalAmount,
    hasCesion,
  } = payroll;

  const { country } = useSelector(state => state.config);

  return (
    <Grid container direction="column">
      <StatItem item container spacing={1}>
        <Grid item xs={12}>
          <Text variant="h6">Total a transferir</Text>
        </Grid>
        <Grid item xs={12}>
          <FinalAmountText variant="h5" fontWeight="bold">
            <CountryFormatHelper
              value={finalAmount}
              countryId={country}
              variant="currency"
            />
          </FinalAmountText>
        </Grid>
      </StatItem>
      <StatItem item container spacing={1}>
        <Grid item xs={12}>
          <Text variant="h6">Pago a proveedores</Text>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          xs={12}
        >
          <Grid item>
            <Text variant="subtitle1" fontWeight="bold">
              <CountryFormatHelper
                value={supplierAmount}
                countryId={country}
                variant="currency"
              />
            </Text>
          </Grid>
          <Grid item>
            <Text variant="subtitle1" fontWeight="bold">
              {decimalFormatter(supplierPercentage)}%
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress value={supplierPercentage} />
        </Grid>
      </StatItem>
      {expanded && country === COUNTRY_CODE_CL && hasCesion && (
        <StatItem item container spacing={1}>
          <Grid item xs={12}>
            <Text variant="h6">Monto cedido</Text>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            xs={12}
          >
            <Grid item>
              <Text variant="subtitle1" fontWeight="bold">
                <CountryFormatHelper
                  value={cessionAmount}
                  countryId={country}
                  variant="currency"
                />
              </Text>
            </Grid>
            <Grid item>
              <Text variant="subtitle1" fontWeight="bold">
                {decimalFormatter(cessionPercentage, 0)}%
              </Text>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress value={cessionPercentage} />
          </Grid>
        </StatItem>
      )}
    </Grid>
  );
};

CardStats.propTypes = {
  expanded: PropTypes.bool.isRequired,
  payroll: PropTypes.objectOf(Object).isRequired,
};

export default CardStats;
