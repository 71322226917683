import { useState } from 'react';

export const useFormWithSummary = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(0);
  const [formFilled, setFormFilled] = useState({});

  const handleSubmit = callback => form => {
    setFormFilled(form.form);
    setAppliedFilters(form.appliedFilters);
    if (callback) {
      callback(form.form);
    }
    setShowFilter(false);
  };

  return {
    appliedFilters,
    formFilled,
    handleSubmit,
    setAppliedFilters,
    setFormFilled,
    setShowFilter,
    showFilter,
  };
};
