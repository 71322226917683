import { Box, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  downloadButton: {
    marginRight: 16,
  },
});

export const ProgressContainer = styled(Box)({
  minHeight: 352,
  minWidth: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PdfContainer = styled(Box)({
  overflowY: 'auto',
  maxHeight: 'calc(75vh)',
  display: 'flex',
  justifyContent: 'center',
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#44414C',
    borderRadius: 4,
  },
});

export const PaginatorContainer = styled(Box)({
  background: '#fff',
  borderRadius: 8,
  padding: 3,
});
