import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateSignedDocumentService = async ({
  documentableType,
  documentableId,
  documentId,
  file,
}) => {
  try {
    const { data } = axios.put(
      `/api/${documentableType}/${documentableId}/document/${documentId}/sign`,
      {
        file,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateSignedDocumentService;
