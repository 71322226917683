import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EyeOpen = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M11.819 3C17.211 3 21.697 6.88 22.638 12C21.698 17.12 17.211 21 11.819 21C6.427 21 1.941 17.12 1 12C1.94 6.88 6.427 3 11.819 3ZM11.819 19C13.8585 18.9996 15.8374 18.3068 17.4319 17.0352C19.0263 15.7635 20.1419 13.9883 20.596 12C20.1402 10.0133 19.0239 8.24 17.4296 6.97003C15.8353 5.70005 13.8573 5.00853 11.819 5.00853C9.7807 5.00853 7.80269 5.70005 6.20838 6.97003C4.61406 8.24 3.49777 10.0133 3.042 12C3.4961 13.9883 4.61167 15.7635 6.20614 17.0352C7.80061 18.3068 9.77953 18.9996 11.819 19ZM11.819 16.5C10.6255 16.5 9.48093 16.0259 8.63702 15.182C7.79311 14.3381 7.319 13.1935 7.319 12C7.319 10.8065 7.79311 9.66193 8.63702 8.81802C9.48093 7.97411 10.6255 7.5 11.819 7.5C13.0125 7.5 14.1571 7.97411 15.001 8.81802C15.8449 9.66193 16.319 10.8065 16.319 12C16.319 13.1935 15.8449 14.3381 15.001 15.182C14.1571 16.0259 13.0125 16.5 11.819 16.5ZM11.819 14.5C12.482 14.5 13.1179 14.2366 13.5868 13.7678C14.0556 13.2989 14.319 12.663 14.319 12C14.319 11.337 14.0556 10.7011 13.5868 10.2322C13.1179 9.76339 12.482 9.5 11.819 9.5C11.156 9.5 10.5201 9.76339 10.0512 10.2322C9.58239 10.7011 9.319 11.337 9.319 12C9.319 12.663 9.58239 13.2989 10.0512 13.7678C10.5201 14.2366 11.156 14.5 11.819 14.5Z" />
    </SvgIcon>
  );
};

export default EyeOpen;
