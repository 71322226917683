/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import BaseDialog from './BaseDialog';
import { DATE_FORMAT } from '../../helpers/Constants';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { updateOrderInvoice } from '../../actions/orderActions';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import { dateWithoutFormatter, isWeekend } from '../../helpers/DateUtils';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
});

const EditInvoiceDialog = ({
  open,
  handleCloseDialog,
  invoice: { id, orderId, rate, expirationDate, folio },
  onSubmitFnc,
  formKeys,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updatingInvoice, updateFacturaErrors } = useSelector(
    state => state.order
  );
  const { country } = useSelector(state => state.config);
  const { holidays } = useSelector(state => state.holidays);
  const countryHolidays = holidays?.[country];
  const [invoiceForm, setInvoiceForm] = useState({
    id,
    orderId,
    rate,
    expirationDate: dateWithoutFormatter(expirationDate),
  });
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (id) {
      setInvoiceForm({
        id,
        orderId,
        rate,
        expirationDate: dateWithoutFormatter(expirationDate),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = () => {
    if (onSubmitFnc) {
      onSubmitFnc({ id, orderId, rate, expirationDate, folio }, invoiceForm);
    } else {
      dispatch(updateOrderInvoice(id, orderId, invoiceForm));
    }
  };

  const handleDisabledDates = date => {
    // Disable saturday and sunday on DatePickers
    return (
      isWeekend(date) ||
      countryHolidays?.some(h =>
        moment.utc(date).isSame(moment.utc(h.date), 'day')
      )
    );
  };

  const handleInputChange = event => {
    if (event.target) {
      const {
        target: { name, value },
      } = event;
      setInvoiceForm({
        ...invoiceForm,
        [name]: value,
      });
    } else if (moment.isMoment(event)) {
      setInvoiceForm({
        ...invoiceForm,
        expirationDate: event,
      });
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={`Editar factura (${folio})`}
    >
      <ValidatorForm onSubmit={onSubmit} className={classes.rootForm}>
        {formKeys.map(({ key }) => {
          if (key === 'rate') {
            const { rate: formRate } = invoiceForm;
            return (
              <TextValidator
                fullWidth
                variant="outlined"
                label="Tasa base (%)"
                type="number"
                id="rate"
                name="rate"
                aria-describedby="rate"
                value={formRate}
                onChange={handleInputChange}
                InputProps={{
                  min: 0,
                }}
                validators={['required', 'isPositive']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              />
            );
          }
          if (key === 'expirationDate') {
            const { expirationDate: formExpirationDate } = invoiceForm;
            return (
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  label="Fecha de Vencimiento"
                  name="expirationDate"
                  id="expirationDate"
                  format={DATE_FORMAT}
                  margin="normal"
                  value={dateWithoutFormatter(formExpirationDate)}
                  onChange={handleInputChange}
                  minDateMessage="Fecha inválida"
                  autoOk
                  inputVariant="outlined"
                  shouldDisableDate={date => handleDisabledDates(date)}
                  TextFieldComponent={props => {
                    return (
                      <TextValidator
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            );
          }
          return '';
        })}
        {updateFacturaErrors && (
          <AlertForm message={updateFacturaErrors} variant="error" />
        )}
        <CheckButton
          check={checked}
          handleCheck={handleCheckboxChange}
          loading={updatingInvoice}
          labelButton="Guardar"
        />
      </ValidatorForm>
    </BaseDialog>
  );
};

EditInvoiceDialog.defaultProps = {
  onSubmitFnc: null,
  formKeys: [{ key: 'tasaBase' }],
  invoice: {},
};

EditInvoiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.number,
    orderId: PropTypes.number,
    folio: PropTypes.string,
    expirationDate: PropTypes.string,
    rate: PropTypes.number,
  }),
  onSubmitFnc: PropTypes.func,
  formKeys: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
};

export default EditInvoiceDialog;
