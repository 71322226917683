import { Box, Typography, styled } from '@material-ui/core';

export const RootContainer = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  padding: '16px 36px',
  borderBottom: '1px solid #CDCAD8',
  transition: 'all .2s ease-in-out',
  '&:hover': {
    backgroundColor: '#F1EFF5',
  },
});

export const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: 2,
  marginBottom: 10,
});

export const CheckedDot = styled(Box)({
  backgroundColor: '#E0584F',
  height: 10,
  width: 10,
  borderRadius: '50%',
  marginRight: 10,
});

export const SpacerDot = styled(Box)({
  height: 10,
  width: 10,
  marginRight: 10,
});

export const Title = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 10,
});

export const SpanTitle = styled(Typography)({
  fontSize: 12,
  color: '#8D8A96',
});

export const DescriptionNew = styled(Typography)({
  fontSize: 14,
  color: '#312F37',
});

export const DescriptionChecked = styled(Typography)({
  fontSize: 14,
  color: '#551A8B',
});
