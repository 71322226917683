import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography } from '@material-ui/core';
import CardHeader from './CardHeader';
import CardAttributes from './CardAttributes';
import CardStats from './CardStats';
import ExpandCardButton from './ExpandCardButton';
import { usePayrollCard } from '../../../hooks';

import {
  Wrapper,
  SelectorWrapper,
  ContentWrapper,
  ExpanderWrapper,
  StatsWrapper,
  ViewDetailsButton,
} from './styles';

const PayrollCard = ({ payroll }) => {
  const {
    expanded,
    expandAttributes,
    country,
    handleSelectedPayroll,
    isPayrollSelected,
  } = usePayrollCard();

  const ORDER_DETAILS_PATH = `/${country.toLowerCase()}/payments/${payroll.id}`;

  return (
    <Wrapper container>
      <SelectorWrapper item>
        <Checkbox
          onChange={evt =>
            handleSelectedPayroll({ payrollId: payroll.id, evt })
          }
          checked={isPayrollSelected(payroll.id)}
        />
      </SelectorWrapper>
      <ContentWrapper item>
        <CardHeader payroll={payroll} />
        <CardAttributes
          payroll={payroll}
          country={country}
          expanded={expanded}
        />
      </ContentWrapper>
      <ExpanderWrapper item>
        <ExpandCardButton
          expanded={expanded}
          onClick={() => expandAttributes()}
        />
      </ExpanderWrapper>
      <StatsWrapper item>
        <CardStats expanded={expanded} payroll={payroll} />
        <ViewDetailsButton
          to={ORDER_DETAILS_PATH}
          type="button"
          variant="outlined"
          fullWidth
        >
          <Typography>Ver detalle</Typography>
        </ViewDetailsButton>
      </StatsWrapper>
    </Wrapper>
  );
};

PayrollCard.propTypes = {
  payroll: PropTypes.objectOf(Object).isRequired,
};

export default PayrollCard;
