import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '../../icons/CloseIcon';
import useFetchOrderResponsibles from './infrastructure/store/useFetchOrderResponsibles';
import {
  BaseDrawer,
  ContainerRoot,
  HeaderContainer,
  Title,
  MainContainer,
  SubTitle,
  TextBottom,
  NotFoundDataText,
  ScrollContainer,
  TextResponsible,
} from './styles';
import OrderResponsiblesSkeleton from './ui/OrderResponsiblesSkeleton';

interface Props {
  isOpen: boolean;
  businessIdentifier: string;
  handleCloseDrawer: () => void;
}

const OrderResponsiblesDrawer: FC<Props> = ({
  isOpen,
  businessIdentifier,
  handleCloseDrawer,
}) => {
  const { responsibles, isLoading } =
    useFetchOrderResponsibles(businessIdentifier);

  return (
    <BaseDrawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
      <ContainerRoot>
        <HeaderContainer mb={5}>
          <Title>Lista de responsables</Title>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon color="#312F37" />
          </IconButton>
        </HeaderContainer>
        <MainContainer>
          <OrderResponsiblesSkeleton isLoading={isLoading}>
            <>
              <SubTitle>KAM Asignado</SubTitle>
              <ScrollContainer>
                {responsibles?.map(({ firstName, lastName, email }, index) => (
                  <TextResponsible isCurrent={index === 0}>
                    {firstName ? `${firstName} ${lastName}` : email}
                  </TextResponsible>
                ))}
                {responsibles?.length === 0 && (
                  <NotFoundDataText>
                    No se tienen responsables registrados para esta operación
                  </NotFoundDataText>
                )}
              </ScrollContainer>
            </>
          </OrderResponsiblesSkeleton>
        </MainContainer>
        <TextBottom>Responsables registrados hasta el día de hoy</TextBottom>
      </ContainerRoot>
    </BaseDrawer>
  );
};

export default OrderResponsiblesDrawer;
