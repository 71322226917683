import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../actions/types';

const initialState = {
  notifications: [],
};

const enqueueSnackbar = (state, action) => {
  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        key: action.key,
        ...action.notification,
      },
    ],
  };
};

const closeSnackbar = (state, action) => {
  return {
    ...state,
    notifications: state.notifications.map(notification =>
      action.dismissAll || notification.key === action.key
        ? { ...notification, dismissed: true }
        : { ...notification }
    ),
  };
};

const removeSnackbar = (state, action) => {
  return {
    ...state,
    notifications: state.notifications.filter(
      notification => notification.key !== action.key
    ),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return enqueueSnackbar(state, action);
    case CLOSE_SNACKBAR:
      return closeSnackbar(state, action);
    case REMOVE_SNACKBAR:
      return removeSnackbar(state, action);
    default:
      return state;
  }
};
