import React, { useState } from 'react';
import { Box, FormControlLabel, TextareaAutosize, RadioGroup, Radio, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../components/dialogs/BaseDialog';
import CheckButton from '../../../../components/elements/CheckButton';
import { convertSpacingToCss as spacing } from '../../../../helpers/stylesHelpers';
import { noop } from '../../utils';

type AppealStateDialogProps = {
  description: string;
  title: string;
  children?: React.ReactNode;
  loading?: boolean;
  t?: (keyword: string) => string;
  onClose?: (open: boolean) => void;
  onSubmit?: (comment: string) => void;
};

export type RadioSelectedProps = {
  label: string;
  title: string;
};

export const RadioSelected = ({ label, title }: RadioSelectedProps) => (
  <RadioGroup
    name="active"
    value="1"
  >
    <Typography
      variant="subtitle1"
      color="textPrimary"
      component="span"
    >
      <Box fontWeight="bold">
        {title}:
      </Box>
    </Typography>
    <FormControlLabel
      style={{ marginBottom: 0 }}
      value="1"
      control={<Radio required color="primary" />}
      label={label}
    />
  </RadioGroup>
);

const AppealStateDialog = ({
  children,
  description,
  title,
  loading = false,
  onClose = noop,
  onSubmit = noop,
  t = (keyword: string) => keyword,
}: AppealStateDialogProps) => {
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState<string>();

  const handleClose = (value: boolean) => {
    setOpen(value);
    onClose(false);
  };

  const handleCheckboxChange = () => {
    setChecked(isChecked => !isChecked);
  };

  const handleSubmit = () => {
    if (comment) {
      onSubmit(comment);
    }
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      title={title}
    >
      <Box sx={{ padding: spacing('zero xl') as string }}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Box>{description}</Box>
            {children}
            <FormControlLabel
              style={{
                margin: spacing('zero zero lg') as string,
                width: '100%',
                alignItems: 'start',
                gap: 8,
              }}
              labelPlacement="top"
              label={(
                <>
                  <strong>Comentario</strong> (Requerido).
                  Máximo 1000 caracteres
                </>
              )}
              control={(
                <TextareaAutosize
                  maxLength={1000}
                  minRows={3}
                  maxRows={25}
                  value={comment || ''}
                  disabled={loading}
                  onChange={event => {
                    setComment(event.target.value);
                  }}
                  aria-label="minimum height"
                  style={{
                    borderRadius: 17.5,
                    padding: spacing('sm lg') as string,
                    width: '90%',
                    borderColor: '#ccc',
                    resize: 'none',
                    overflow: 'auto',
                  }}
                  required
                  placeholder={
                    t('Escribe acá los detalles necesarios y pertinentes del cambio de estado')
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <CheckButton
              disabled={false}
              check={checked}
              handleCheck={handleCheckboxChange}
              labelButton={t('Guardar cambios')}
              loading={loading}
            />
          </Box>
        </ValidatorForm>
      </Box>
    </BaseDialog>
  );
};

export default AppealStateDialog;
