import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Box,
  Tooltip,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { DATE_FORMAT, SECOND_WHITE_COLOR } from '../../helpers/Constants';
import loading from '../../assets/loading.gif';
import AlertForm from './AlertForm';

const useStyles = makeStyles({
  dialog: {
    background: SECOND_WHITE_COLOR,
    borderRadius: '17px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogTitle: {
    marginBottom: 10,
  },
  dialogSubtitle: {
    marginBottom: 20,
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    padding: '10px 50px',
  },
  copyButton: {
    marginLeft: 20,
  },
  buttonSubmit: {
    marginBottom: 10,
  },
});

const ReportFormDialog = ({
  open,
  handleCloseDialog,
  handleSubmitForm,
  report,
  showLoader,
}) => {
  const { query } = report || {};
  const { reportError } = useSelector(state => state.report);
  const [params, setParams] = useState([]);

  const extractParamsQuery = () => {
    return query
      .split(' ')
      .filter(element => element.indexOf('@') !== -1)
      .map(element => element.replace(/@/g, '').replace(/'/g, ''));
  };

  useEffect(() => {
    const paramsQuery = extractParamsQuery();
    const paramsChange = [...params];
    paramsQuery.forEach((param, i) => {
      paramsChange[i] = {};
      paramsChange[i].label = param;
      paramsChange[i].param = `@${param}`;
      paramsChange[i].value = moment(new Date()).format('YYYY-MM-DD');
    });
    setParams(paramsChange);
    // eslint-disable-next-line
  }, []);

  const getInputs = () => {
    return (
      <>
        {(params || []).map((param, i) => {
          return (
            <>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                <DatePicker
                  fullWidth
                  disabled={false}
                  label={param.label}
                  format={DATE_FORMAT}
                  value={param.value}
                  onChange={date => {
                    const paramsChange = [...params];
                    paramsChange[i].value = moment(date).format('YYYY-MM-DD');
                    setParams(paramsChange);
                  }}
                  autoOk
                  inputVariant="outlined"
                  TextFieldComponent={props => (
                    <TextValidator
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </>
          );
        })}
      </>
    );
  };

  const handleSubmit = () => {
    const { id } = report || {};
    const data = {
      id,
      params,
    };
    handleSubmitForm(data);
  };

  const handleClose = () => {
    handleCloseDialog();
  };
  const classes = useStyles();
  let title = 'Nuevo reporte';
  if (report) {
    title = '';
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        className: classes.dialog,
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container direction="column" align="center" justify="center">
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h5"
            color="textPrimary"
            component="div"
            className={classes.dialogTitle}
          >
            <Box fontWeight="fontWeightBold">{!showLoader && title}</Box>
          </Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid item xs={12}>
          {!showLoader && (
            <ValidatorForm onSubmit={() => handleSubmit()}>
              {getInputs()}
              {reportError && (
                <AlertForm message={reportError} variant="error" />
              )}
              <Grid className={classes.justifyCenter}>
                <Tooltip
                  component="button"
                  title="Descargar reporte"
                  className={classes.classTooltip}
                >
                  <Button
                    startIcon={<CloudDownloadIcon />}
                    className={classes.buttonSubmit}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Descargar reporte
                  </Button>
                </Tooltip>
              </Grid>
            </ValidatorForm>
          )}
          {showLoader && (
            <Grid item xs={12} className={classes.justifyCenter}>
              <img src={loading} alt="loader" />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ReportFormDialog.defaultProps = {
  report: null,
};

ReportFormDialog.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  report: PropTypes.objectOf(Object),
  showLoader: PropTypes.bool.isRequired,
};

export default ReportFormDialog;
