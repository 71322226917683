import React, { FC } from 'react';
import { alpha, Chip, styled } from '@material-ui/core';
import {
  COUNTRY_CODE_CL,
  INVOICE_STATUS_CL,
  INVOICE_STATUS_MX,
} from '../../../helpers/Constants';
import useGetCountry from '../../../hooks/useGetCountry';
import { palette } from '../../../theme/palette';
import { Countries, XepelinStatus } from '../interfaces/ARInterfaces';

interface Props {
  status: XepelinStatus;
}

const CustomChip = styled(Chip)(({ status }: { status: XepelinStatus }) => {
  const { stateText1, stateText4 } = palette.payments;
  const {
    complete,
    approved,
    pvApproved,
    rejected,
    renegotiation,
    default: defaultStateColor,
  } = palette.payments.statusColors;

  const defaultColor = {
    color: stateText4,
    background: alpha(stateText4, 0.24),
  };

  const statusColor: {
    [key in XepelinStatus]?: {
      color: string;
      background: string;
    };
  } = {
    ACTIVE: {
      color: stateText4,
      background: alpha(stateText4, 0.24),
    },
    PAID: {
      color: stateText4,
      background: alpha(stateText4, 0.24),
    },
    IN_REVIEW: {
      color: stateText1,
      background: alpha(stateText1, 0.24),
    },
    REVIEWED: {
      color: stateText1,
      background: alpha(stateText1, 0.24),
    },
    APPEALED: {
      color: stateText1,
      background: alpha(stateText1, 0.24),
    },
    COMPLETE: {
      color: complete,
      background: alpha(complete, 0.24),
    },
    APPROVED: {
      color: approved,
      background: alpha(approved, 0.24),
    },
    APPROVED_FUND: {
      color: approved,
      background: alpha(approved, 0.24),
    },
    PV_APPROVED: {
      color: pvApproved,
      background: alpha(pvApproved, 0.24),
    },
    TO_DEPOSIT: {
      color: pvApproved,
      background: alpha(pvApproved, 0.24),
    },
    REJECTED: {
      color: rejected,
      background: alpha(rejected, 0.24),
    },
    RENEGOTIATION: {
      color: renegotiation,
      background: alpha(renegotiation, 0.24),
    },
    DEFAULT: {
      color: defaultStateColor,
      background: alpha(defaultStateColor, 0.24),
    },
  };

  const statusColorSelected = statusColor[status] || defaultColor;

  return {
    fontSize: 12,
    height: 24,
    ...statusColorSelected,
  };
});

const StatusChip: FC<Props> = ({ status }) => {
  const country = useGetCountry();

  const getStatusByCountry = ({
    status,
    country,
  }: {
    status: XepelinStatus;
    country: Countries;
  }) => {
    const invoicesStatus =
      country === COUNTRY_CODE_CL ? INVOICE_STATUS_CL : INVOICE_STATUS_MX;
    return invoicesStatus[status];
  };

  const label = getStatusByCountry({ status, country }) ?? '-';

  return <CustomChip label={label} status={status} />;
};

export default StatusChip;
