import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { arrayToURL } from '../../../../helpers/url';

const fetchOrdersService = async ({
  operation,
  page = 1,
  limit = 20,
  filters = null,
}) => {
  const stringFilters = arrayToURL(filters);
  try {
    const { data } = await axios.get('/api/orders', {
      params: {
        operation,
        page,
        size: limit,
        filters: stringFilters,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrdersService };
