import {
  RESET_PARTIAL_PAYMENT_ERRORS,
  FETCH_PARTIAL_PAYMENTS_START,
  FETCH_PARTIAL_PAYMENTS_SUCCESS,
  FETCH_PARTIAL_PAYMENTS_FAILURE,
  ADD_PARTIAL_PAYMENT_START,
  ADD_PARTIAL_PAYMENT_SUCCESS,
  ADD_PARTIAL_PAYMENT_FAILURE,
  UPDATE_PARTIAL_PAYMENT_START,
  UPDATE_PARTIAL_PAYMENT_SUCCESS,
  UPDATE_PARTIAL_PAYMENT_FAILURE,
  DELETE_PARTIAL_PAYMENT_START,
  DELETE_PARTIAL_PAYMENT_FAILURE,
  DELETE_PARTIAL_PAYMENT_SUCCESS,
  BULK_PARTIAL_PAYMENT_REGISTER_START,
  BULK_PARTIAL_PAYMENT_REGISTER_SUCCESS,
  BULK_PARTIAL_PAYMENT_REGISTER_FAILURE,
  CALCULATE_DEBT_PARTIAL_PAYMENT_START,
  CALCULATE_DEBT_PARTIAL_PAYMENT_SUCCESS,
  CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE,
} from '../actions/types';

const initialState = {
  partialPayments: [],
  fetchPartialPaymentsIsLoading: false,

  addPartialPaymentIsLoading: false,
  partialPaymentAdded: false,
  addPartialPaymentError: null,

  updatePartialPaymentIsLoading: false,
  partialPaymentUpdated: false,
  updatePartialPaymentError: null,

  deletePartialPaymentIsLoading: false,
  partialPaymentDeleted: false,
  deletePartialPaymentError: null,

  partialPaymentsPagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
    totalPages: 1,
  },

  bulkPartialPaymentRegisterIsLoading: false,
  bulkPartialPaymentRegistered: false,
  bulkPartialPaymentRegisterError: null,
  bulkPartialPaymentRegisterErrorLines: null,

  fetchCalculateDebtPartialPaymentIsLoading: false,
  partialPaymentCalculations: {
    baseRate: null,
    debtInterestAtDate: null,
    segment: null,
    totalCapitalDebt: null,
    totalDebt: null,
    payedInterests: null,
    payerDebtFD: null,
  },
};

const fetchPartialPaymentsStart = state => ({
  ...state,
  partialPayments: [],
  fetchPartialPaymentsIsLoading: true,
});
const fetchPartialPaymentsSuccess = (state, payload) => ({
  ...state,
  partialPayments: payload.partialPayments.data,
  fetchPartialPaymentsIsLoading: false,
  partialPaymentsPagination: payload.partialPayments.pagination,
});
const fetchPartialPaymentsFailure = state => ({
  ...state,
  fetchPartialPaymentsIsLoading: false,
});

const addPartialPaymentStart = state => ({
  ...state,
  addPartialPaymentIsLoading: true,
  partialPaymentAdded: false,
  addPartialPaymentError: null,
});
const addPartialPaymentSuccess = (state, payload) => {
  const newPartialPayments = [...state.partialPayments];
  newPartialPayments.unshift(payload.newPartialPayment);
  const pagination = { ...state.partialPaymentsPagination };
  return {
    ...state,
    addPartialPaymentIsLoading: false,
    partialPaymentAdded: true,
    partialPayments: newPartialPayments,
    partialPaymentsPagination: {
      ...pagination,
      totalData: newPartialPayments.length,
    },
  };
};
const addPartialPaymentFailure = (state, payload) => ({
  ...state,
  addPartialPaymentIsLoading: false,
  addPartialPaymentError: payload.errorCode,
});

const updatePartialPaymentStart = state => ({
  ...state,
  updatePartialPaymentIsLoading: true,
  partialPaymentUpdated: false,
  updatePartialPaymentError: null,
});
const updatePartialPaymentSuccess = (state, payload) => {
  const { updatedPartialPayment } = payload;
  const newPartialPayments = state.partialPayments.map(partialPayment => {
    if (partialPayment.id === updatedPartialPayment.id) {
      return updatedPartialPayment;
    }
    return partialPayment;
  });
  return {
    ...state,
    updatePartialPaymentIsLoading: false,
    partialPaymentUpdated: true,
    partialPayments: newPartialPayments,
  };
};
const updatePartialPaymentFailure = (state, payload) => ({
  ...state,
  updatePartialPaymentIsLoading: false,
  updatePartialPaymentError: payload.errorCode,
});

const deletePartialPaymentsStart = state => ({
  ...state,
  partialPaymentDeleted: false,
  deletePartialPaymentIsLoading: true,
});
const deletePartialPaymentsSuccess = (state, payload) => ({
  ...state,
  partialPaymentDeleted: true,
  partialPayments: state.partialPayments.filter(
    partialPayment => partialPayment.id !== payload.partialPaymentId
  ),
  deletePartialPaymentIsLoading: false,
  partialPaymentsPagination: {
    ...state.partialPaymentsPagination,
    totalData: state.partialPaymentsPagination.totalData - 1,
  },
});
const deletePartialPaymentsFailure = state => ({
  ...state,
  partialPaymentDeleted: false,
  deletePartialPaymentIsLoading: false,
});

const bulkPartialPaymentRegisterStart = state => ({
  ...state,
  bulkPartialPaymentRegisterIsLoading: true,
  bulkPartialPaymentRegistered: false,
  bulkPartialPaymentRegisterError: null,
  bulkPartialPaymentRegisterErrorLines: null,
});
const bulkPartialPaymentRegisterSuccess = state => ({
  ...state,
  bulkPartialPaymentRegisterIsLoading: false,
  bulkPartialPaymentRegistered: true,
});
const bulkPartialPaymentRegisterFailure = (state, payload) => ({
  ...state,
  bulkPartialPaymentRegisterIsLoading: false,
  bulkPartialPaymentRegisterError: payload.errorCode,
  bulkPartialPaymentRegisterErrorLines: payload.errorLines,
});

const calculateDebtPartialPaymentStart = state => ({
  ...state,
  fetchCalculateDebtPartialPaymentIsLoading: true,
  partialPaymentCalculations: {
    baseRate: null,
    debtInterestAtDate: null,
    segment: null,
    totalCapitalDebt: null,
    totalDebt: null,
    payedInterests: null,
  },
});

const calculateDebtPartialPaymentSuccess = (state, payload) => ({
  ...state,
  fetchCalculateDebtPartialPaymentIsLoading: false,
  partialPaymentCalculations: payload,
});

const calculateDebtPartialPaymentFailure = state => ({
  ...state,
  fetchCalculateDebtPartialPaymentIsLoading: false,
});

const resetPartialPaymentErrors = state => ({
  ...state,
  addPartialPaymentError: null,
  updatePartialPaymentError: null,
  bulkPartialPaymentRegisterError: null,
  bulkPartialPaymentRegisterErrorLines: null,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PARTIAL_PAYMENTS_START:
      return fetchPartialPaymentsStart(state);
    case FETCH_PARTIAL_PAYMENTS_SUCCESS:
      return fetchPartialPaymentsSuccess(state, payload);
    case FETCH_PARTIAL_PAYMENTS_FAILURE:
      return fetchPartialPaymentsFailure(state);
    case ADD_PARTIAL_PAYMENT_START:
      return addPartialPaymentStart(state);
    case ADD_PARTIAL_PAYMENT_SUCCESS:
      return addPartialPaymentSuccess(state, payload);
    case ADD_PARTIAL_PAYMENT_FAILURE:
      return addPartialPaymentFailure(state, payload);
    case UPDATE_PARTIAL_PAYMENT_START:
      return updatePartialPaymentStart(state);
    case UPDATE_PARTIAL_PAYMENT_SUCCESS:
      return updatePartialPaymentSuccess(state, payload);
    case UPDATE_PARTIAL_PAYMENT_FAILURE:
      return updatePartialPaymentFailure(state, payload);
    case DELETE_PARTIAL_PAYMENT_START:
      return deletePartialPaymentsStart(state);
    case DELETE_PARTIAL_PAYMENT_SUCCESS:
      return deletePartialPaymentsSuccess(state, payload);
    case DELETE_PARTIAL_PAYMENT_FAILURE:
      return deletePartialPaymentsFailure(state);
    case BULK_PARTIAL_PAYMENT_REGISTER_START:
      return bulkPartialPaymentRegisterStart(state);
    case BULK_PARTIAL_PAYMENT_REGISTER_SUCCESS:
      return bulkPartialPaymentRegisterSuccess(state);
    case BULK_PARTIAL_PAYMENT_REGISTER_FAILURE:
      return bulkPartialPaymentRegisterFailure(state, payload);
    case CALCULATE_DEBT_PARTIAL_PAYMENT_START:
      return calculateDebtPartialPaymentStart(state);
    case CALCULATE_DEBT_PARTIAL_PAYMENT_SUCCESS:
      return calculateDebtPartialPaymentSuccess(state, payload);
    case CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE:
      return calculateDebtPartialPaymentFailure(state);
    case RESET_PARTIAL_PAYMENT_ERRORS:
      return resetPartialPaymentErrors(state);
    default:
      return state;
  }
};
