import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

import { useGraphQlQuery } from '../../../hooks/useGraphQlQuery';
import { query, WorkflowsWithStateQuery } from '../query';
import { buttonStateColors, grayButton } from './buttonStateColors';
import {
  declinedDocOperationsId,
  requestMoreDocsOperationsId,
  declinedDocLegalId,
  requestMoreDocsLegalId,
  objectionRiskId,
  ldcRiskId,
} from './workFlowAllowedId';
import { operationId, riskId, legalId } from '../../../constants';
import { StateType } from '../../../schemas';

type Props = {
  businessIdentifier: string;
};

const workflows = [
  {
    id: operationId,
    name: 'Operaciones',
    defaultState: {
      id: '3a6084d5-b3e0-460a-8ef7-1ca46e3714ac',
      name: 'Vinculando cuenta SAT',
    },
  },
  {
    id: riskId,
    name: 'Riesgo',
    defaultState: {
      id: '38e425bd-1f73-478f-8c19-f69f85312b15',
      name: 'Vinculando cuenta SAT',
    },
  },
  {
    id: legalId,
    name: 'Legal',
    defaultState: {
      id: '9bc36605-bd20-4064-8cb9-3974a1e172f2',
      name: 'Vinculando cuenta SAT',
    },
  },
];

const workflowIds = workflows.map(item => item.id);

export const useGetWorkflowStates = ({ businessIdentifier }: Props) => {
  const variables = {
    dto1: {
      entityRefId: businessIdentifier,
      workflowIds,
    },
    dto2: {
      type: StateType.riskLdcAssigned,
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    loading,
    data,
    error,
    refetch,
    isSuccess,
    isRefetching,
  } = useGraphQlQuery<WorkflowsWithStateQuery>('workflowsWithState', {
    query,
    variables,
    enabled: Boolean(businessIdentifier),
    retry: 1,
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        'Surgió un error al obtener los estados',
        { variant: 'error' },
      );
    }
  }, [error]);

  const workflowsWithState = data?.workflowsWithState || [];

  const workflowStates = (error || loading) ? [] : workflows.map(workflow => {
    const workflowWithState = workflowsWithState.find(item => item.workflow.id === workflow.id);
    if (workflowWithState) {
      let actionEnabled = false;
      if (operationId === workflow.id) {
        actionEnabled = [declinedDocOperationsId, requestMoreDocsOperationsId].includes(workflowWithState.target.id);
      }

      if (legalId === workflow.id) {
        actionEnabled = [declinedDocLegalId, requestMoreDocsLegalId].includes(workflowWithState.target.id);
      }

      if (riskId === workflow.id) {
        actionEnabled = [objectionRiskId, ldcRiskId].includes(workflowWithState.target.id);
      }

      return ({
        createdAt: format(new Date(workflowWithState.createdAt), 'dd-MM-yyyy'),
        comment: workflowWithState.comment,
        id: workflowWithState.id,
        target: workflowWithState.target,
        workflow: workflowWithState.workflow,
        buttonStyle: buttonStateColors.find(item => item.id === workflowWithState.target.id) || grayButton,
        actionEnabled,
        transitionType: workflowWithState.transitionType,
      });
    }

    return ({
      createdAt: '',
      comment: null,
      id: null,
      target: workflow.defaultState,
      workflow: {
        id: workflow.id,
        name: workflow.name,
      },
      buttonStyle: buttonStateColors.find(item => item.id === workflow.defaultState.id) || grayButton,
      actionEnabled: false,
    });
  });

  return {
    loading,
    error,
    refetch,
    isRefetching,
    isSuccess,
    workflowStates,
    riskLdcAssigned: data?.state,
  };
};
