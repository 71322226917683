import React, { FC } from 'react';
import { Collapse } from '@material-ui/core';
import {
  ContainerRoot,
  SelectionDetails,
  SelectedText,
  UndoSelection,
} from './styles';
import EditIcon from '../../../commons/icons/EditIcon';
import SelectedOperationsButton from './SelectedOperationsButton';
import useSelectedOperations from '../../hooks/useSelectedOperations';
import ChangeStatusOrderModal from '../../../commons/components/ChangeStatusOrderModal';
import { useGetSelectedOperations } from '../../infrastructure/store/InboxStore';
import useGetOrderInvoiceIsHold from '../../infrastructure/store/useGetOrderInvoiceIsHold';

const SelectedOperations: FC = () => {
  const selectedOperations = useGetSelectedOperations();
  const data = useGetOrderInvoiceIsHold(selectedOperations);

  const orderInvoiceIsHold = (orderInvoices: any) => {
    const orderInvoicesValues = orderInvoices && Object.values(orderInvoices);
    const orderInvoicesHoldStatus = orderInvoicesValues?.length
      ? orderInvoicesValues?.map((orderInvoice: any) => orderInvoice.isHold)
      : [];
    const isHold = orderInvoicesHoldStatus?.some(
      (invoiceHoldStatus: any) => invoiceHoldStatus === true
    );
    return !!isHold;
  };

  const someOrderInvoiceIsHold = data?.some(orderInvoiceIsHold);

  const {
    totalSelectedOperations,
    showChangeStatusOperationDialog,
    bulkdUpdateStatusIsLoading,
    countryFeatures,
    handleClearSelectedOperations,
    handleOpenChangeStatusOperationDialog,
    handleCloseChangeStatusOperationDialog,
    handlebulkUpdateStatus,
  } = useSelectedOperations();

  return (
    <Collapse in={totalSelectedOperations} timeout="auto">
      <ContainerRoot>
        <SelectionDetails>
          <SelectedText>
            {totalSelectedOperations > 1
              ? `${totalSelectedOperations} operaciones seleccionadas`
              : '1 operación  seleccionada'}
          </SelectedText>
          <UndoSelection onClick={handleClearSelectedOperations}>
            Deshacer selección
          </UndoSelection>
        </SelectionDetails>

        <SelectedOperationsButton
          startIcon={<EditIcon />}
          onClick={handleOpenChangeStatusOperationDialog}
          disabled={!countryFeatures.orderActions.bulkOrderStatusChange}
        >
          Editar Estado
        </SelectedOperationsButton>
      </ContainerRoot>

      {showChangeStatusOperationDialog && (
        <ChangeStatusOrderModal
          open={showChangeStatusOperationDialog}
          handleCloseDialog={handleCloseChangeStatusOperationDialog}
          handleSubmit={handlebulkUpdateStatus}
          title="Cambiar estado de las operaciones"
          isLoading={bulkdUpdateStatusIsLoading}
          isHold={someOrderInvoiceIsHold}
        />
      )}
    </Collapse>
  );
};

export default SelectedOperations;
