import {
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_START,
  CREATE_TRANSACTION_SUCCESS,
  FETCH_ALL_REQUESTS_START,
  FETCH_HTML_REPORT,
  FETCH_HTML_REPORT_FAILURE,
  FETCH_HTML_REPORT_SUCCESS,
  FETCH_PENDING_REQUESTS_START,
  FETCH_REQUESTS_FAILURE,
  FETCH_REQUESTS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_WALLETS_FAILURE,
  FETCH_WALLETS_START,
  FETCH_WALLETS_SUCCESS,
  RESET_REQUESTS,
  SET_WALLET_ACTIVE_FILTERS,
  UPDATE_REQUEST_STATUS_FAILURE,
  UPDATE_REQUEST_STATUS_START,
  UPDATE_REQUEST_STATUS_SUCCESS,
  UPDATE_WALLET_STATUS_FAILURE,
  UPDATE_WALLET_STATUS_START,
  UPDATE_WALLET_STATUS_SUCCESS,
} from '../actions/types';
import { WALLET_STATUS } from '../helpers/Constants';

const initialState = {
  wallets: [],
  loadingWallets: false,
  walletAvailableFilters: WALLET_STATUS,
  walletActiveFilters: WALLET_STATUS,
  pagination: { page: 0, maxRecords: 0, pageSize: 20 },
  errors: null,
  updatingWalletStatus: false,
  walletStatusUpdated: false,
  updatingRequestStatus: false,
  requestStatusUpdated: false,
  creatingTransaction: false,
  transactionCreated: false,
  transactions: {},
  loadingTransactions: false,
  selectedWallets: [],
  loadingNominaPagos: false,
  pendingRequests: {},
  loadingPendingRequests: false,
  allRequests: null,
  loadingAllRequests: false,
  loadingHtmlReport: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_WALLETS_START:
      return {
        ...state,
        loadingWallets: true,
      };
    case FETCH_WALLETS_SUCCESS:
      return {
        ...state,
        loadingWallets: false,
        wallets: payload.accounts,
        requestStatusUpdated: false,
        transactionCreated: false,
        walletActiveFiltersSetted: false,
        pagination: {
          maxRecords: payload.maxRecords,
          page: payload.page,
          pageSize: payload.limit,
        },
      };
    case FETCH_WALLETS_FAILURE:
      return {
        ...state,
        loadingWallets: false,
        wallets: initialState.wallets,
        errors: payload.errorCode,
        requestStatusUpdated: false,
        transactionCreated: false,
        walletActiveFiltersSetted: false,
      };
    case FETCH_TRANSACTIONS_START:
      return {
        ...state,
        loadingTransactions: true,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        transactionCreated: false,
        transactions: {
          ...state.transactions,
          [payload.profileId]: payload.transactions,
        },
      };
    case FETCH_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loadingTransactions: false,
        transactionCreated: false,
        errors: payload.errorCode,
      };
    case FETCH_PENDING_REQUESTS_START:
      return {
        ...state,
        loadingPendingRequests: true,
        errors: null,
      };
    case FETCH_ALL_REQUESTS_START:
      return {
        ...state,
        loadingAllRequests: true,
        errors: null,
      };
    case FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingPendingRequests: false,
        loadingAllRequests: false,
        pendingRequests: payload.status
          ? {
              ...state.pendingRequests,
              [payload.identifier]: payload.requests,
            }
          : state.pendingRequests,
        allRequests: payload.status ? state.allRequests : payload.requests,
      };
    case FETCH_REQUESTS_FAILURE:
      return {
        ...state,
        loadingPendingRequests: false,
        loadingAllRequests: false,
        errors: payload.errorCode,
      };
    case FETCH_HTML_REPORT:
      return {
        ...state,
        loadingHtmlReport: true,
        errors: null,
      };
    case FETCH_HTML_REPORT_SUCCESS:
      return {
        ...state,
        loadingHtmlReport: false,
      };
    case FETCH_HTML_REPORT_FAILURE:
      return {
        ...state,
        loadingHtmlReport: false,
        errors: payload.errorCode,
      };
    case RESET_REQUESTS: {
      return {
        ...state,
        loadingAllRequests: false,
        allRequests: null,
      };
    }
    case SET_WALLET_ACTIVE_FILTERS:
      return {
        ...state,
        walletActiveFilters: payload.walletActiveFilters,
        walletActiveFiltersSetted: true,
      };
    case UPDATE_WALLET_STATUS_START:
      return {
        ...state,
        updatingWalletStatus: true,
        walletStatusUpdated: false,
      };
    case UPDATE_WALLET_STATUS_SUCCESS: {
      const { wallets } = state;
      const updatedWalletPosition = wallets.findIndex(
        wallet => wallet.AccountProfile.id === payload.profileId
      );
      const updatedWallet = wallets[updatedWalletPosition];
      updatedWallet.status = payload.status;
      wallets.splice(updatedWalletPosition, 1, updatedWallet);
      return {
        ...state,
        updatingWalletStatus: false,
        walletStatusUpdated: true,
        wallets,
      };
    }
    case UPDATE_WALLET_STATUS_FAILURE:
      return {
        ...state,
        updatingWalletStatus: false,
        errors: payload.errorCode,
      };
    case UPDATE_REQUEST_STATUS_START:
      return {
        ...state,
        updatingRequestStatus: true,
        requestStatusUpdated: false,
        errors: null,
      };
    case UPDATE_REQUEST_STATUS_SUCCESS: {
      const walletPendingStatus = ['PENDING', 'ACCEPTED'];
      const pendingRequests = { ...state.pendingRequests };
      // payload.updatedRequest is the old CL Api scheme, delete payload.updatedRequest
      const newUpdatedRequest = payload.updatedRequest
        ? payload.updatedRequest
        : payload;
      const updatedRequest = pendingRequests[
        newUpdatedRequest.identifier
      ].filter(request => request.id !== newUpdatedRequest.id);
      pendingRequests[newUpdatedRequest.identifier] =
        !walletPendingStatus.includes(newUpdatedRequest.status)
          ? updatedRequest
          : [].concat(newUpdatedRequest);

      return {
        ...state,
        updatingRequestStatus: false,
        requestStatusUpdated: true,
        pendingRequests,
      };
    }
    case UPDATE_REQUEST_STATUS_FAILURE:
      return {
        ...state,
        updatingRequestStatus: false,
        errors: payload.errorCode,
      };
    case CREATE_TRANSACTION_START:
      return {
        ...state,
        creatingTransaction: true,
        transactionCreated: false,
      };
    case CREATE_TRANSACTION_SUCCESS: {
      const { wallets } = state;
      const updatedWalletPosition = wallets.findIndex(
        wallet => wallet.AccountProfile.id === payload.profileId
      );
      const updatedWallet = wallets[updatedWalletPosition];
      updatedWallet.balance += payload.newActivity.amount;
      wallets.splice(updatedWalletPosition, 1, updatedWallet);
      return {
        ...state,
        creatingTransaction: false,
        transactionCreated: true,
        wallets,
      };
    }
    case CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        creatingTransaction: false,
        errors: payload.errorCode,
      };
    default:
      return state;
  }
};
