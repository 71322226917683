import { restFunds } from '../../../../infra/http';
import {
  b64toBlob,
  download as downloadFile,
} from '../../../../../helpers/fileHandling';
import * as Mapper from './payroll.map';

export const fetchFunds = () => {
  return restFunds()
    .get('fund', { params: { features: true, active: true } })
    .then(r => r.data)
    .then(r => r.map(Mapper.fundMap.toDomain));
};

export const fetchRecommendedRate = ({ receiverIdentifiers, fundId }) => {
  return restFunds()
    .post('rate/recommended', { fundId, receiverIdentifiers })
    .then(r => r.data)
    .then(Mapper.recommendedRateMap.toDomain);
};

export const fetchCustomExpirationDate = ({ invoiceIds, fundId }) => {
  return restFunds()
    .post('v2/payroll/custom-expiration-date', { fundId, invoiceIds })
    .then(r => r.data)
    .then(Mapper.customExpirationDateMap.toDomain);
};

export const makeSimulation = req => {
  return restFunds()
    .post('v2/payroll/simulate', Mapper.simulationMap.toPersistence(req))
    .then(r => r.data)
    .then(Mapper.simulationMap.toDomain);
};

export const downloadPayrollDraft = ({ interestFormula, receivers }) => {
  return restFunds()
    .post(
      '/payroll/simulate/export',
      Mapper.payrollDraftMap.toPersistence({ interestFormula, receivers })
    )
    .then(r => r.data)
    .then(r => downloadFile(b64toBlob(r), 'Simulación de Nómina.xlsx'));
};

export const sendPayroll = req => {
  return restFunds()
    .post('payroll', Mapper.sendPayrollMap.toPersistence(req))
    .then(r => r.data)
    .then(Mapper.sendPayrollMap.toDomain);
};

export const fetchPdfSummary = payrollId => {
  return restFunds()
    .post(`payroll/${payrollId}/pdf-summary`, { payrollInvoiceIds: [] })
    .then(r => r.data)
    .then(Mapper.pdfSummaryMap.toDomain);
};

export const notifyMissingPdfs = payrollId => {
  return restFunds().post(`payroll/${payrollId}/notify-missing-pdfs`);
};

export const checkPayrollPdf = payrollId => {
  return restFunds()
    .post(`payroll/${payrollId}/check-pdf`)
    .then(r => r.data);
};
