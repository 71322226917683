import { Grid, IconButton, styled, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  height: 32,
  width: 32,
}));

const CustomArrowBackIcon = styled(ArrowBack)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const IconButtonGrid = styled(Grid)({
  marginRight: 24,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const PayerNameGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  marginRight: 30,
});

const SudoIconButton = styled(IconButton)({
  marginRight: 20,
});

const CustomGridItem = styled(Grid)({
  marginRight: 16,
});

const CustomGridContainer = styled(Grid)({
  marginBottom: 3,
});

const TitleTypography = styled(Typography)(theme => ({
  ...theme.props,
  color: '#312F37',
  fontSize: 18,
  fontWeight: 'bold',
}));

const SubtitleTypography = styled(Typography)(theme => ({
  ...theme.props,
  color: '#312F37',
  fontSize: 14,
  fontWeight: 'regular',
}));

export {
  CustomIconButton,
  CustomArrowBackIcon,
  IconButtonGrid,
  PayerNameGrid,
  SudoIconButton,
  CustomGridItem,
  CustomGridContainer,
  TitleTypography,
  SubtitleTypography,
};
