import {
  Box,
  Button,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextValidator,
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { approveRejectDocument } from '../../actions/documentActions';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';
import { REJECTED_DOC_REASONS } from '../../helpers/Constants';

const REVIEW = 'review';
const LOADING = 'loading';

const useStyles = makeStyles({
  textBold: {
    fontWeight: 'bold',
  },
  radioButton: {
    marginBottom: 0,
  },
  textArea: {
    '& fieldset': {
      marginTop: 10,
      borderRadius: 17,
    },
  },
});

const ReviewDialog = ({
  open,
  handleCloseDialog,
  isLoading,
  error,
  handleDownloadDocument,
  document,
  resetErrors,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [statusDialog] = useState(REVIEW);

  const [confirmChanges, setConfirmChanges] = useState(false);
  const [actionDocument, setActionDocument] = useState('approve');
  const [reasonOption, setReasonOption] = useState('');
  const [reasonRejection, setReasonRejection] = useState('');

  const country = useSelector(state => state.config.country);

  const {
    id: documentId,
    documentableId,
    documentableType,
    isLegal,
  } = document;

  useEffect(() => {
    resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAction = event => {
    setActionDocument(event.target.value);
  };

  useEffect(() => {
    if (reasonOption !== 'OTHER') {
      setReasonRejection(reasonOption);
    } else {
      setReasonRejection('');
    }
  }, [reasonOption]);

  const handleSubmitDialog = () => {
    if (confirmChanges) {
      switch (actionDocument) {
        case 'approve':
          dispatch(
            approveRejectDocument(
              documentId,
              documentableId,
              documentableType,
              actionDocument
            )
          );
          break;

        case 'reject':
          dispatch(
            approveRejectDocument(
              documentId,
              documentableId,
              documentableType,
              actionDocument,
              reasonRejection
            )
          );
          break;

        default:
          break;
      }
    }
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Revisión de Documento"
      description="Confirma el estado de la revisión del documento"
      hideCloseButton={statusDialog === LOADING}
    >
      <ValidatorForm onSubmit={handleSubmitDialog}>
        {statusDialog === REVIEW && (
          <Grid container direction="column">
            <Button
              color="primary"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={() => handleDownloadDocument(document)}
            >
              Descargar documento
            </Button>

            {error && (
              <Grid item xs={12} align="center" className={classes.error}>
                <AlertForm variant="error" message={t(error)} />
              </Grid>
            )}

            <Box paddingTop={3} alignItems="left">
              <Typography className={classes.textBold}>
                Estado del documento:
              </Typography>
              <RadioGroup
                name="documentAction"
                value={actionDocument}
                onChange={handleChangeAction}
              >
                <FormControlLabel
                  value="approve"
                  control={<Radio color="primary" />}
                  label="Aprobado"
                  classes={{ root: classes.radioButton }}
                />
                <FormControlLabel
                  value="reject"
                  control={<Radio color="primary" />}
                  label="Rechazado"
                  classes={{ root: classes.radioButton }}
                />
              </RadioGroup>
            </Box>
            {actionDocument === 'reject' &&
              (isLegal ? (
                <Box paddingTop={2}>
                  <SelectValidator
                    variant="outlined"
                    fullWidth
                    id="reasonOptions"
                    value={reasonOption}
                    onChange={event => setReasonOption(event.target.value)}
                    label="Razón de rechazo"
                    dontvalidate="false"
                  >
                    {Object.values(REJECTED_DOC_REASONS[country]).map(
                      reason => (
                        <MenuItem key={reason.value} value={reason.value}>
                          <Typography>{reason.label}</Typography>
                        </MenuItem>
                      )
                    )}
                  </SelectValidator>
                  {reasonOption === 'OTHER' && (
                    <TextValidator
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={2}
                      placeholder="Por ej: Certificado vencido"
                      variant="outlined"
                      type="string"
                      id="reasonRejection"
                      name="reasonRejection"
                      value={reasonRejection}
                      onChange={e => setReasonRejection(e.target.value)}
                      validators={confirmChanges ? ['required', 'trim'] : []}
                      errorMessages={
                        confirmChanges
                          ? [
                              'Campo requerido',
                              'El campo no puede estar en vacío',
                            ]
                          : []
                      }
                      classes={{
                        root: classes.textArea,
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography>
                    En caso de rechazo, se le enviará un correo al usuario con
                    las causas. Describe correctamente el motivo de rechazo del
                    documento.
                  </Typography>
                  <TextValidator
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={2}
                    placeholder="Por ej: Certificado vencido"
                    variant="outlined"
                    type="string"
                    id="reasonRejection"
                    name="reasonRejection"
                    value={reasonRejection}
                    onChange={e => setReasonRejection(e.target.value)}
                    validators={confirmChanges ? ['required', 'trim'] : []}
                    errorMessages={
                      confirmChanges
                        ? [
                            'Campo requerido',
                            'El campo no puede estar en vacío',
                          ]
                        : []
                    }
                    classes={{
                      root: classes.textArea,
                    }}
                  />
                </Box>
              ))}

            <Box marginY={3}>
              <CheckButton
                check={confirmChanges}
                handleCheck={() => setConfirmChanges(!confirmChanges)}
                labelButton="Guardar Cambios"
                loading={isLoading}
              />
            </Box>
          </Grid>
        )}
      </ValidatorForm>
    </BaseDialog>
  );
};

ReviewDialog.defaultProps = {
  error: null,
  resetErrors: () => {},
};

ReviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleDownloadDocument: PropTypes.func.isRequired,
  document: PropTypes.shape({
    action: PropTypes.string.isRequired,
    actionDate: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    approved: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string,
    document: PropTypes.string.isRequired,
    documentableId: PropTypes.number.isRequired,
    documentableType: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    order: PropTypes.number.isRequired,
    signedDocument: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    isLegal: PropTypes.bool.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  resetErrors: PropTypes.func,
};

export default ReviewDialog;
