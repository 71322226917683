import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { features } from '../../../config/features';
import { MIFIEL_ON, SIGN } from '../../../helpers/Constants';
import {
  useCreateDocument,
  useFetchDocumentTypes,
  useUpdateSignedDocument,
  useVerifyDocumentsStatus,
} from '../infrastructure/store';

const ORDER = 'order';
const BUSINESS = 'business';
const FIELD_RELATION_TYPE = 'relationType';
const RELATION_TYPE = {
  CL: BUSINESS,
  MX: ORDER,
};

const useDocumentDialog = () => {
  const { id: orderId } = useParams();
  const { country } = useSelector(state => state.config);
  const countryFeatures = features[country];
  const { createDocumentWithElectronicSignature } =
    countryFeatures.businessDetailsSection.legal;
  const initialAction = createDocumentWithElectronicSignature
    ? MIFIEL_ON
    : SIGN;

  const [documentForm, setDocumentForm] = useState({
    title: '',
    description: '',
    action: initialAction,
    active: true,
    subTitle: '',
  });

  const { documentTypes, documentTypesIsLoading } = useFetchDocumentTypes({
    field: FIELD_RELATION_TYPE,
    searchInput: RELATION_TYPE[country],
  });
  const documentableType = ORDER;
  const documentableId = orderId;
  const {
    createDocumentError,
    createDocumentIsLoading,
    createDocumentIsSuccess,
    createDocument,
    createDocumentAsync,
    resetDocumentData,
  } = useCreateDocument();
  const { updateSignedDocument } = useUpdateSignedDocument();
  const { verifyDocumentsStatus } = useVerifyDocumentsStatus(
    documentableType,
    documentableId
  );

  const handleCreateDocument = document => {
    return createDocument({
      documentableType,
      documentableId,
      document,
    });
  };

  const handleCreateDocumentWithSignedFile = async ({ document, file }) => {
    const ccdata = await createDocumentAsync({
      documentableType,
      documentableId,
      document,
    });

    await updateSignedDocument({
      documentableType,
      documentableId,
      documentId: ccdata.id,
      file,
    });

    verifyDocumentsStatus();
  };

  const documentDialogConstants = {
    BUSINESS: 'business',
    CONTRACT: 'Contrato',
    DOCUMENTS_WITHOUT_INITIAL_DOCUMENT: [MIFIEL_ON],
    CONTRACTS: [
      { id: 1, title: 'Contrato Unificado' },
      { id: 2, title: 'Contrato Unificado (sin firma Xepelin)' },
      { id: 3, title: 'Contrato Payments' },
      { id: 4, title: 'Contrato Payments (sin firma Xepelin)' },
      { id: 5, title: 'Contrato Pronto Pago/Confirming' },
      { id: 6, title: 'Contrato Pronto Pago/Confirming (sin firma Xepelin)' },
      { id: 7, title: 'Contrato Crédito' },
      { id: 8, title: 'Contrato Crédito (sin firma Xepelin)' },
      { id: 9, title: 'Contrato Financiamiento Directo' },
      { id: 10, title: 'Contrato Financiamiento Directo (sin firma Xepelin)' },
    ],
    DESCRIPTION_PLACEHOLDER_REQUIRED:
      'Escribe la descripción o especificación del documento',
    DESCRIPTION_PLACEHOLDER_NOT_REQUIRED:
      'Escribe la descripción o especificación en caso de ser necesario del documento que vas a subir.',
  };

  return {
    documentDialogConstants,
    documentTypes,
    documentTypesIsLoading,
    documentForm,
    createDocumentWithElectronicSignature,
    createDocumentError,
    createDocumentIsLoading,
    createDocumentIsSuccess,
    handleCreateDocumentWithSignedFile,
    handleCreateDocument,
    resetDocumentData,
    setDocumentForm,
  };
};

export default useDocumentDialog;
