/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import {
  DATE_FORMAT,
  defaultFundCapitalCostRate,
  rateTypesObj,
} from '../../../../../../helpers/Constants';
import CheckButton from '../../../../../../components/elements/CheckButton';
import { convertSpacingToCss } from '../../../../../../helpers/stylesHelpers';
import {
  setHourString,
  dateWithoutFormatter,
} from '../../../../../../helpers/DateUtils';
import AlertForm from '../../../../../../components/elements/AlertForm';
import {
  useFetchFundRates,
  useFetchOrderInvoiceFundRates,
} from '../../../../infrastructure/store';
import { ContainerSpinner } from '../../../../../commons/components/CreateDocumentDialog/styles';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  selectAdornment: { marginRight: 20 },
  alertform: {
    marginBottom: 15,
  },
});

const FundDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  linkFundType,
  linkFundData,
  isLoading,
  error,
  funds,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const [fundFinancing, setFundFinancing] = useState({
    fundId: '',
    startDate: '',
    expirationDate: '',
    interest: '',
    baseRate: '',
    debtRate: '',
    debtDays: '',
    advancePercentage: '',
    effectiveFundCostRate: '',
    effectiveFundCostInterest: '',
    xepelinCapitalCostRate: defaultFundCapitalCostRate,
  });

  const {
    fundId,
    startDate,
    expirationDate,
    interest,
    baseRate,
    debtRate,
    debtDays,
    advancePercentage,
    effectiveFundCostRate,
    effectiveFundCostInterest,
    xepelinCapitalCostRate,
  } = fundFinancing;

  const {
    fundRates: fundRatesFund,
    refetch: refetchFundRates,
    isLoading: fundRatesFundIsLoading,
    isFetching: fundRatesFundIsFetching,
  } = useFetchFundRates(fundId);

  const { orderInvoiceFundRates, isLoadingOrderInvoiceFundRates } =
    useFetchOrderInvoiceFundRates(linkFundData?.id);

  useEffect(() => {
    ValidatorForm.addValidationRule('beforeExpirationDate', () => {
      return new Date(startDate).getTime() < new Date(expirationDate).getTime();
    });
    ValidatorForm.addValidationRule('greaterOrEqualToBaseRate', () => {
      return baseRate <= debtRate || !debtRate;
    });
    ValidatorForm.addValidationRule('debtRate', () => {
      return (
        (debtRate && debtDays) ||
        (!debtRate && !debtDays) ||
        (debtRate && !debtDays)
      );
    });
    ValidatorForm.addValidationRule('debtDay', () => {
      return (
        (debtRate && debtDays) ||
        (!debtRate && !debtDays) ||
        (!debtRate && debtDays)
      );
    });
  });

  const updatePreloadedInfo = (fundId, linkFundRates = []) => {
    const rates = linkFundRates.length ? linkFundRates : fundRatesFund;
    const sortedRates = rates
      ?.filter(rate => rate.rateType === rateTypesObj.accounting)
      ?.sort((a, b) => a.days - b.days);
    const selectedFund = funds.find(fund => fund.id === fundId) || {};
    const { advancePercentage } = selectedFund || {};
    const {
      advancePercentage: oifAdvancePercentage,
      effectiveFundCostInterest,
    } = linkFundData || {};
    const { days = '', rate = '' } = sortedRates?.[1] || {};
    const { rate: baseRate = '' } = sortedRates?.[0] || {};
    const effectiveFundCostRate =
      rates?.find(
        rate =>
          rate.rateType === rateTypesObj.effectiveFundCost && rate.days === 0
      )?.rate || '';
    const xepelinCapitalCostRate =
      rates?.find(
        rate => rate.rateType === rateTypesObj.capitalCost && rate.days === 0
      )?.rate || defaultFundCapitalCostRate;
    setFundFinancing({
      ...fundFinancing,
      fundId,
      advancePercentage: linkFundData
        ? oifAdvancePercentage
        : advancePercentage,
      baseRate,
      debtDays: days,
      debtRate: rate,
      effectiveFundCostRate,
      effectiveFundCostInterest,
      xepelinCapitalCostRate,
    });
  };

  useEffect(() => {
    if (linkFundData) {
      const {
        fundId,
        expirationDate,
        startDate,
        interest,
        advancePercentage,
        baseRate,
        debtDays,
        debtRate,
        effectiveFundCostRate,
        effectiveFundCostInterest,
        xepelinCapitalCostRate,
      } = linkFundData;

      setFundFinancing({
        ...fundFinancing,
        fundId,
        startDate,
        expirationDate,
        interest,
        advancePercentage,
        baseRate,
        debtDays,
        debtRate,
        effectiveFundCostRate,
        effectiveFundCostInterest,
        xepelinCapitalCostRate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      fundId &&
      linkFundData &&
      orderInvoiceFundRates.length &&
      !isLoadingOrderInvoiceFundRates
    ) {
      updatePreloadedInfo(
        fundId,
        linkFundType === 'ORDER_INVOICE'
          ? orderInvoiceFundRates.filter(
              oifr => oifr.orderInvoiceFundId === linkFundData.id
            )
          : orderInvoiceFundRates.filter(oifr => oifr.id === linkFundData.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInvoiceFundRates, isLoadingOrderInvoiceFundRates]);

  const onSubmit = () => {
    const {
      interest,
      baseRate,
      debtRate = null,
      debtDays = null,
      advancePercentage,
      effectiveFundCostRate,
      effectiveFundCostInterest,
      xepelinCapitalCostRate,
    } = fundFinancing;

    handleSubmit(
      {
        ...fundFinancing,
        interest,
        baseRate,
        debtRate: debtRate !== '' ? debtRate : null,
        debtDays: debtDays !== '' ? debtDays : null,
        advancePercentage,
        effectiveFundCostRate: effectiveFundCostRate || null,
        effectiveFundCostInterest: effectiveFundCostInterest || null,
        xepelinCapitalCostRate: xepelinCapitalCostRate || null,
      },
      linkFundData?.id
    );
  };

  const handleInputDateChange = (value, name) => {
    const date = setHourString(value);
    setFundFinancing({
      ...fundFinancing,
      [name]: date,
    });
  };

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event;
    setFundFinancing({
      ...fundFinancing,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!fundRatesFund?.length && fundId) {
      refetchFundRates();
      updatePreloadedInfo(fundId, fundRatesFund);
    }
  }, [fundId]);

  const handleSelectFund = async event => {
    const {
      target: { value: fundId },
    } = event;
    setFundFinancing({
      ...fundFinancing,
      fundId,
    });
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={linkFundData ? 'Editar Fondo' : 'Vincular Fondo'}
      description={linkFundData ? null : 'Selecciona el fondo a vincular'}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={onSubmit} className={classes.rootForm}>
          {isLoadingOrderInvoiceFundRates ? (
            <ContainerSpinner>
              <CircularProgress size={50} />
            </ContainerSpinner>
          ) : (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <SelectValidator
                fullWidth
                variant="outlined"
                id="fundId"
                name="fundId"
                value={fundId}
                onChange={handleSelectFund}
                label="Fondos"
                validators={['required']}
                errorMessages={['Campo requerido']}
                InputProps={{
                  endAdornment: (fundRatesFundIsLoading ||
                    isLoadingOrderInvoiceFundRates ||
                    fundRatesFundIsFetching) && (
                    <CircularProgress
                      className={classes.selectAdornment}
                      color="primary"
                      variant="indeterminate"
                      size={20}
                    />
                  ),
                }}
              >
                {funds?.map(fund => {
                  return (
                    <MenuItem key={fund.id} value={fund.id}>
                      {fund.name}
                    </MenuItem>
                  );
                })}
              </SelectValidator>

              <DatePicker
                autoOk
                fullWidth
                inputVariant="outlined"
                id="startDate"
                name="startDate"
                label="Fecha de inicio"
                format={DATE_FORMAT}
                value={dateWithoutFormatter(startDate)}
                onChange={date => handleInputDateChange(date, 'startDate')}
                minDateMessage="Fecha inválida"
                TextFieldComponent={props => {
                  return (
                    <TextValidator
                      {...props}
                      validators={['required', 'beforeExpirationDate']}
                      errorMessages={[
                        'Campo requerido',
                        'Debe ser anterior a fecha de término',
                      ]}
                    />
                  );
                }}
              />
              <DatePicker
                fullWidth
                id="expirationDate"
                name="expirationDate"
                label="Fecha de termino"
                format={DATE_FORMAT}
                inputVariant="outlined"
                value={dateWithoutFormatter(expirationDate)}
                onChange={date => handleInputDateChange(date, 'expirationDate')}
                minDateMessage="Fecha inválida"
                autoOk
                TextFieldComponent={props => {
                  return (
                    <TextValidator
                      {...props}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  );
                }}
              />
              <TextValidator
                fullWidth
                variant="outlined"
                label="Interes fondo ($)"
                type="number"
                id="interest"
                name="interest"
                aria-describedby="interest"
                value={interest}
                onChange={handleInputChange}
                InputProps={{
                  min: 0,
                }}
                validators={['required', 'isPositive']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              />
              <TextValidator
                fullWidth
                label="Tasa inicial (%)"
                variant="outlined"
                InputProps={{ inputProps: { min: 0, max: 100, step: 0.0001 } }}
                id="baseRate"
                name="baseRate"
                type="number"
                aria-describedby="baseRate"
                value={baseRate ?? ''}
                onChange={handleInputChange}
                validators={['required']}
                errorMessages={['Campo requerido']}
              />
              <TextValidator
                fullWidth
                label="Tasa mora (%)"
                variant="outlined"
                InputProps={{ inputProps: { min: 0, max: 100, step: 0.0001 } }}
                id="debtRate"
                name="debtRate"
                type="number"
                aria-describedby="debtRate"
                value={debtRate ?? ''}
                onChange={handleInputChange}
                validators={['isPositive', 'debtRate']}
                errorMessages={[
                  'Valor debe ser mayor a 0',
                  'Si incluye días de inicio mora, debe ingresar tasa de mora',
                ]}
              />
              <TextValidator
                fullWidth
                variant="outlined"
                label="Días inicio mora"
                type="number"
                InputProps={{ inputProps: { min: 0, step: 1 } }}
                id="debtDays"
                name="debtDays"
                aria-describedby="debtDays"
                value={debtDays ?? ''}
                onChange={handleInputChange}
                validators={['isPositive', 'debtDay']}
                errorMessages={[
                  'Valor debe ser mayor a 0',
                  'Si incluye tasa de mora, debe ingresar los días para el inicio de mora',
                ]}
              />
              <TextValidator
                fullWidth
                variant="outlined"
                label="Porcentaje de anticipo (%)"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100, step: 1 } }}
                id="advancePercentage"
                name="advancePercentage"
                aria-describedby="advancePercentage"
                value={advancePercentage}
                onChange={handleInputChange}
                validators={['required', 'isPositive']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              />
              <TextValidator
                fullWidth
                label="Tasa costo fondo efectiva (%)"
                variant="outlined"
                InputProps={{ inputProps: { min: 0, max: 100, step: 0.0001 } }}
                id="effectiveFundCostRate"
                name="effectiveFundCostRate"
                type="number"
                aria-describedby="effectiveFundCostRate"
                value={effectiveFundCostRate}
                onChange={handleInputChange}
                validators={['required', 'isPositive']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              />
              <TextValidator
                fullWidth
                label="Interés tasa costo fondo efectiva ($)"
                variant="outlined"
                InputProps={{
                  min: 0,
                }}
                id="effectiveFundCostInterest"
                name="effectiveFundCostInterest"
                type="number"
                aria-describedby="effectiveFundCostInterest"
                value={effectiveFundCostInterest}
                onChange={handleInputChange}
                validators={['required', 'isPositive']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              />
              <TextValidator
                fullWidth
                label="Tasa costo capital Xepelin (%)"
                variant="outlined"
                InputProps={{ inputProps: { min: 0, max: 100, step: 0.0001 } }}
                id="xepelinCapitalCostRate"
                name="xepelinCapitalCostRate"
                type="number"
                aria-describedby="xepelinCapitalCostRate"
                value={xepelinCapitalCostRate}
                onChange={handleInputChange}
                validators={['required', 'isPositive']}
                errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
              />

              {error && (
                <Grid item className={classes.alertform}>
                  {' '}
                  <AlertForm message={error} variant="error" />
                </Grid>
              )}
              <CheckButton
                check={checked}
                handleCheck={handleCheckboxChange}
                labelButton="Guardar"
                loading={isLoading}
              />
            </MuiPickersUtilsProvider>
          )}
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

FundDialog.defaultProps = {
  isLoading: false,
  error: null,
  operationType: null,
  linkFundType: null,
  linkFundData: null,
  linkFundRates: [],
  funds: [],
};

FundDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  operationType: PropTypes.string,
  linkFundType: PropTypes.string,
  linkFundData: PropTypes.shape({
    id: PropTypes.number,
    orderType: PropTypes.string,
    fundId: PropTypes.number,
    expirationDate: PropTypes.string,
    startDate: PropTypes.string,
    interest: PropTypes.number,
    advancePercentage: PropTypes.number,
    baseRate: PropTypes.number,
    debtDays: PropTypes.number,
    debtRate: PropTypes.number,
    effectiveFundCostRate: PropTypes.number,
    effectiveFundCostInterest: PropTypes.number,
    xepelinCapitalCostRate: PropTypes.number,
  }),
  linkFundRates: PropTypes.arrayOf(Object),
  funds: PropTypes.arrayOf(Object),
};

export default FundDialog;
