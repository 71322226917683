import { normalize } from '../../../../commons/utils/data';

export const getDraftDocumentsUseCase = ({
  draftDocuments,
  draftPayrollSelector,
  receiverIdentifier,
  allDocuments,
}) => {
  const receiverSelected = draftPayrollSelector?.[receiverIdentifier];
  const normalizedDocuments = normalize(allDocuments, 'id');

  if (!allDocuments) {
    return [];
  }

  return draftDocuments?.map(docum => {
    const mappedDocument = {
      ...normalizedDocuments[docum.id],
      ...docum,
      issuer: { ...normalizedDocuments[docum.id]?.issuer, ...docum?.issuer },
    };

    if (!receiverSelected) {
      return {
        ...mappedDocument,
        tableData: {
          ...docum.tableData,
          checked: false,
        },
      };
    }
    if (receiverSelected?.selected) {
      return {
        ...mappedDocument,
        tableData: { ...docum.tableData, checked: true },
      };
    }
    if (!receiverSelected?.selected) {
      return {
        ...mappedDocument,
        tableData: {
          ...docum.tableData,
          checked: !!receiverSelected.value.find(doc => doc.id === docum.id),
        },
      };
    }

    return mappedDocument;
  });
};
