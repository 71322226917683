import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HistoryIcon } from '../../../../commons/icons';
import {
  HeaderWrapper,
  Title,
  Identifier,
  Separator,
  HistoryButton,
  TitleWrapper,
  HeaderTop,
  HeaderBottom,
  HeaderItem,
  HeaderLabel,
  OrderIdLink,
} from './styles';

import usePaymentCardHeader from '../../../hooks/usePaymentCardHeader';
import HistoryLogsDialog from '../../../../../components/dialogs/HistoryLogsDialog';

import { t13s } from '../../../../../translationKeys';

const CardHeader = ({ payroll }) => {
  const { t } = useTranslation();
  const {
    businessName,
    businessIdentifier,
    id,
    agreementName = '--',
  } = payroll;
  const ORDER = 'ORDER';

  const { BUSINESS_IDENTIFIER } = t13s.LABEL;
  const TRANSLATED_BUSINESS_IDENTIFIER = t(BUSINESS_IDENTIFIER).toUpperCase();

  const {
    showHistoryLogDialog,
    handleCloseHistoryLogDialog,
    handleShowHistoryLogDialog,
    isCountryCL,
    country,
  } = usePaymentCardHeader();

  const ORDER_DETAILS_PATH = `/${country.toLowerCase()}/payments/${id}`;

  return (
    <HeaderWrapper container direction="column">
      <HeaderTop container item direction="row">
        <TitleWrapper>
          <Title variant="body1" fontWeight="bold">
            {businessName}
          </Title>
          <Separator variant="h6">-</Separator>
          <Identifier variant="h6">
            {TRANSLATED_BUSINESS_IDENTIFIER}: {businessIdentifier}
          </Identifier>
        </TitleWrapper>
        <Grid item>
          <Tooltip title="Historial de cambios">
            <HistoryButton onClick={handleShowHistoryLogDialog}>
              <HistoryIcon />
            </HistoryButton>
          </Tooltip>
        </Grid>
      </HeaderTop>
      {showHistoryLogDialog && (
        <HistoryLogsDialog
          open={showHistoryLogDialog}
          handleCloseDialog={handleCloseHistoryLogDialog}
          contextId={id}
          contextType={ORDER}
        />
      )}
      <HeaderBottom container item>
        <HeaderItem item>
          <HeaderLabel variant="h6">ID Operación:</HeaderLabel>
          <OrderIdLink to={ORDER_DETAILS_PATH} type="button" fullWidth>
            {id}
          </OrderIdLink>
        </HeaderItem>
        {isCountryCL && (
          <HeaderItem item>
            <HeaderLabel variant="h6">Convenio: {agreementName}</HeaderLabel>
          </HeaderItem>
        )}
      </HeaderBottom>
    </HeaderWrapper>
  );
};

CardHeader.propTypes = {
  payroll: PropTypes.objectOf(Object).isRequired,
};

export default CardHeader;
