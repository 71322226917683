import React, { FC } from 'react';
import { Box, Collapse } from '@material-ui/core';
import { TitleInputs } from '../styles';
import Dropzone from '../../Dropzone';

interface Props {
  isCollapse: boolean;
  isDisabled: boolean;
  handleChangeDropzone: (documentFile: any, status: string) => void;
}

const OrderCommentsDropzone: FC<Props> = ({
  isCollapse,
  isDisabled,
  handleChangeDropzone,
}) => {
  return (
    <Collapse in={isCollapse} timeout="auto">
      <Box maxWidth={525}>
        <Box mb={2}>
          <TitleInputs>Subir documentos</TitleInputs>
        </Box>
        {/* @ts-ignore */}
        <Dropzone
          data-cy="orderCommentsDropzoneInput"
          maxFiles={10}
          maxSizeBytes={10000000}
          disabled={isDisabled}
          onChangeStatus={handleChangeDropzone}
          accept=".pdf, image/jpeg, image/png"
        />
      </Box>
    </Collapse>
  );
};

export default OrderCommentsDropzone;
