import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useStyles, tabIndicatorStyles } from './styles';

interface Props {
  activeTab: string;
  handleTabChange: (valueTab: string) => void;
  tabs: { label: string; value: string }[];
}

const TabSection = ({ activeTab, handleTabChange, tabs }: Props) => {
  const classes = useStyles();

  const a11yProps = (index: string) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <Tabs
      value={activeTab}
      onChange={(_e_, valueTab) => handleTabChange(valueTab)}
      className={classes.containerTab}
      TabIndicatorProps={{
        style: tabIndicatorStyles,
      }}
    >
      {tabs.map(({ label, value }) => (
        <Tab
          label={label}
          value={value}
          key={label}
          className={classes.headerTabsTab}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...a11yProps(value)}
        />
      ))}
    </Tabs>
  );
};

export default TabSection;
