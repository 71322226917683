interface Options {
  value: string;
  label: string;
}
interface PayerCategorizationMenu {
  [key: string]: Options[];
}

export const payerCategorizationMenu: PayerCategorizationMenu = {
  CONTACTABILITY: [
    { value: 'LOW', label: 'LOW_CONTACTABILITY' },
    { value: 'MEDIUM', label: 'MEDIUM_CONTACTABILITY' },
    { value: 'HIGH', label: 'HIGH_CONTACTABILITY' },
    { value: 'UNKNOWN', label: 'UNKNOWN' },
    { value: 'PENDING', label: 'PENDING' },
  ],
  PAYMENT_INTENTION: [
    { value: 'LOW', label: 'LOW_PAYMENT_INTENTION' },
    { value: 'MEDIUM', label: 'MEDIUM_PAYMENT_INTENTION' },
    { value: 'HIGH', label: 'HIGH_PAYMENT_INTENTION' },
    { value: 'UNKNOWN', label: 'UNKNOWN' },
    { value: 'PENDING', label: 'PENDING' },
  ],
  PAYMENT_CAPACITY: [
    { value: 'LOW', label: 'LOW_PAYMENT_CAPACITY' },
    { value: 'MEDIUM', label: 'MEDIUM_PAYMENT_CAPACITY' },
    { value: 'HIGH', label: 'HIGH_PAYMENT_CAPACITY' },
    { value: 'UNKNOWN', label: 'UNKNOWN' },
    { value: 'PENDING', label: 'PENDING' },
  ],
};

export enum payerCategorizationType {
  CONTACTABILITY = 'CONTACTABILITY',
  PAYMENT_INTENTION = 'PAYMENT_INTENTION',
  PAYMENT_CAPACITY = 'PAYMENT_CAPACITY',
  RECAP = 'RECAP',
}

export const predetermined = 'PENDING';
