import axios from 'axios';
import moment from 'moment';
import { download } from '../helpers/fileHandling';
import { getErrorCode } from '../helpers/handleErrors';
import { DATE_LOCALE } from '../helpers/Constants';
import { t13s } from '../translationKeys';
import { enqueueSnackbar } from './notificationAction';
import {
  FETCH_CESSIONARIES_START,
  FETCH_CESSIONARIES_SUCCESS,
  FETCH_CESSIONARIES_FAILURE,
  MAKE_CESSION_START,
  MAKE_CESSION_SUCCESS,
  MAKE_CESSION_FAILURE,
  FETCH_CESSION_START,
  FETCH_CESSION_SUCCESS,
  FETCH_CESSION_FAILURE,
  DOWNLOAD_AEC_START,
  DOWNLOAD_AEC_SUCCESS,
  DOWNLOAD_AEC_FAILURE,
} from './types';

// TODO rename function
export const fetchCessionaries = token => dispatch => {
  dispatch({ type: FETCH_CESSIONARIES_START });
  axios
    .get('/api/cessionary', { token })
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_CESSIONARIES_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_CESSIONARIES_FAILURE, payload: { errorCode } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CESSIONARIES_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const makeCession =
  (invoices, cessionary, businessId, orderType = undefined) =>
  dispatch => {
    const cessionaryId = cessionary?.id;
    const invoiceIds = invoices.map(invoice => invoice.invoiceId);
    dispatch({ type: MAKE_CESSION_START, payload: invoices });
    axios
      .post('/api/cession', {
        invoiceIds,
        cessionaryId,
        businessId,
        orderType, //  orderType param is required for payments(confirming) only
      })
      .then(({ data }) => {
        const isSuccessfulSeccions = data?.successfulCessions.length;
        dispatch({
          type: MAKE_CESSION_SUCCESS,
          payload: isSuccessfulSeccions,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.MAKE_CESSION_SUCCESS, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: MAKE_CESSION_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchCession = (token, orderId) => dispatch => {
  dispatch({ type: FETCH_CESSION_START });
  axios
    .get(`/api/cession/${orderId}`, { token })
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_CESSION_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_CESSION_FAILURE, payload: { errorCode } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CESSION_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const downloadAEC = (orderId, invoices) => dispatch => {
  dispatch({ type: DOWNLOAD_AEC_START });
  axios
    .post('/api/cession/aec', { invoices }, { responseType: 'blob' })
    .then(res => {
      const resData = res.data;
      dispatch({ type: DOWNLOAD_AEC_SUCCESS });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FILE_DOWNLOADED, {
          variant: 'success',
        })
      );
      const today = moment().locale(DATE_LOCALE).format('DD-MM-YYYY_HH:mm:ss');
      return download(resData, `AEC_${orderId}_${today}.zip`);
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: DOWNLOAD_AEC_FAILURE, payload: { errorCode } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
          variant: 'error',
        })
      );
    });
};
