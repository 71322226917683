import React, { FC, ChangeEvent } from 'react';
import {
  Drawer,
  IconButton,
  Divider,
  Typography,
  Button,
  MenuItem,
  Collapse,
  Box,
} from '@material-ui/core';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  useStyles,
  ContainerRoot,
  ContainerHeader,
  ContainerTitle,
  Title,
  ContainerBody,
  Description,
  ContainerInformation,
  ContainerLeft,
  ContainerHeaderCollapse,
  ContainerOpsInformation,
  ContainerIconCollapse,
  TextDiscountBold,
  TextCollapse,
  ContainerAlertFormTop,
  ContainerSelectorInvoices,
  ContainerRight,
  CardPrimary,
  CardSecondary,
  CardTertiary,
  TitleCardPrimary,
  BodyCardRow,
  BodyCardRowLabel,
  BodyCardRowValue,
  BodyCardRowValueGreen,
  ContainerComment,
  LabelComment,
  ContainerButtons,
  TextDescriptionDelete,
  ContainerDeleteButton,
} from './styles';
import { Edit, Close, Delete, Add, Right, Down } from '../../icons';
import useDiscountDrawer, {
  SourceType,
  SourceData,
  DiscountInformation,
} from './useDiscountDrawer';
import AlertForm from '../../elements/AlertForm';
import SelectorInvoices from '../../elements/SelectorInvoices';
import CountryFormatHelper from '../../elements/CountryFormatHelper';
import Skeleton from '../../elements/Skeleton';
import useGetCountry from '../../../hooks/useGetCountry';

interface Props {
  isOpen: boolean;
  sourceId: number;
  sourceType: SourceType;
  sourceData: SourceData;
  handleCloseDrawer: () => void;
}

const DiscountDrawer: FC<Props> = ({
  isOpen,
  sourceId,
  sourceType,
  sourceData,
  handleCloseDrawer,
}) => {
  const classes = useStyles();
  const country = useGetCountry();
  const {
    fetchDiscountIsLoading,
    createDiscountIsLoading,
    createDiscountError,
    updateDiscountIsLoading,
    updateDiscountError,
    deleteDiscountIsLoading,
    deleteDiscountError,
    replacementInvoicesError,
  } = useSelector((state: RootStateOrAny) => state.discount);
  const {
    discount,
    isReplacementOperation,
    discountSummary,
    replacementOperationDiscountSummary,
    replacementOperationDiscountInterest,
    replacementOperationData,
    calculations,
    getDiscounts,
    getTexts,
    getTotalAmountsSummary,
    getReplacementOperationMessage,
    handleAddSelectorInvoice,
    handleDeleteSelectorInvoice,
    handleChangeReplacementOperationOperationId,
    handleChangeReplacementOperationSelectedInvoices,
    handleChangeReplacementOperationIsCollapse,
    handleSubmit,
    handleInputChange,
    handleDeleteDiscount,
    handleResetDiscountStore,
  } = useDiscountDrawer({ sourceId, sourceType, sourceData });

  const countryFormatHelper = (data: DiscountInformation) => {
    if (data.formatHelperVariant) {
      return (
        <>
          {/* @ts-ignore */}
          <CountryFormatHelper
            value={data.value}
            countryId={country}
            variant={data.formatHelperVariant}
          />
        </>
      );
    }
    return data.value;
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        handleResetDiscountStore();
        handleCloseDrawer();
      }}
      classes={{
        paper: classes.rootDrawer,
      }}
    >
      <ContainerRoot>
        <ContainerHeader>
          <ContainerTitle>
            <Edit />
            <Title>{getTexts().title}</Title>
          </ContainerTitle>
          <IconButton
            onClick={() => {
              handleResetDiscountStore();
              handleCloseDrawer();
            }}
          >
            <Close />
          </IconButton>
        </ContainerHeader>

        <Divider />

        <ValidatorForm onSubmit={handleSubmit}>
          <ContainerBody>
            <Description>{getTexts().description}</Description>
            <ContainerInformation>
              <ContainerLeft>
                <TextDiscountBold>Información del descuento</TextDiscountBold>
                <Skeleton
                  isLoading={fetchDiscountIsLoading}
                  variant="rect"
                  width={345}
                  height={36}
                >
                  <SelectValidator
                    variant="outlined"
                    value={discount.reason}
                    id="reason"
                    name="reason"
                    label="Razón del descuento"
                    placeholder="Razón del descuento"
                    onChange={handleInputChange}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                  >
                    {getDiscounts().map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Skeleton>

                {!isReplacementOperation && discount?.reason && (
                  <>
                    <TextDiscountBold>Monto</TextDiscountBold>
                    <Skeleton
                      isLoading={fetchDiscountIsLoading}
                      variant="rect"
                      width={345}
                      height={36}
                    >
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="number"
                        id="amount"
                        name="amount"
                        value={discount.amount}
                        onChange={handleInputChange}
                        label="Monto del descuento ($)"
                        placeholder="Monto del descuento ($)"
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    </Skeleton>
                  </>
                )}

                {isReplacementOperation && (
                  <>
                    <TextDiscountBold>
                      Operaciones a reemplazar
                    </TextDiscountBold>
                    {Object.values(replacementOperationData).map(data => (
                      <Box key={data.id}>
                        <ContainerHeaderCollapse>
                          <ContainerOpsInformation
                            onClick={() =>
                              handleChangeReplacementOperationIsCollapse(
                                data.id,
                                !data.isCollapse
                              )
                            }
                          >
                            <ContainerIconCollapse>
                              {data.isCollapse ? <Down /> : <Right />}
                            </ContainerIconCollapse>
                            {data.isCollapse ? (
                              <TextCollapse>
                                Seleccione la operación a reemplazar
                              </TextCollapse>
                            ) : (
                              <TextCollapse>
                                OP {data.operationId || '-'}
                              </TextCollapse>
                            )}
                          </ContainerOpsInformation>
                          {data.id !== 1 && (
                            <IconButton
                              onClick={() =>
                                handleDeleteSelectorInvoice(data.id)
                              }
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </ContainerHeaderCollapse>
                        <Collapse in={data.isCollapse} timeout="auto">
                          <TextValidator
                            fullWidth
                            variant="outlined"
                            type="string"
                            id="operationId"
                            name="operationId"
                            value={data.operationId}
                            label="ID Operación a reemplazar"
                            placeholder="ID Operación a reemplazar"
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleChangeReplacementOperationOperationId(
                                data.id,
                                event.target.value
                              )
                            }
                          />
                          {replacementInvoicesError[data.id] && (
                            <ContainerAlertFormTop>
                              <AlertForm
                                message="ID de operación no encontrado"
                                variant="warning"
                              />
                            </ContainerAlertFormTop>
                          )}

                          <ContainerSelectorInvoices>
                            <SelectorInvoices
                              isLoading={data.isLoading}
                              handleGetSelectedInvoices={selectedInvoices => {
                                handleChangeReplacementOperationSelectedInvoices(
                                  data.id,
                                  selectedInvoices
                                );
                              }}
                              invoices={data?.invoices || []}
                            />
                          </ContainerSelectorInvoices>
                        </Collapse>
                      </Box>
                    ))}
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<Add />}
                      onClick={handleAddSelectorInvoice}
                    >
                      Agregar otra Operación
                    </Button>
                  </>
                )}
              </ContainerLeft>

              <ContainerRight>
                {/* First Card Information */}
                <CardPrimary>
                  <TitleCardPrimary>
                    {getTexts().cardInformationTitle}
                  </TitleCardPrimary>
                  {discountSummary.map(discountSummaryData => (
                    <BodyCardRow key={discountSummaryData.label}>
                      <BodyCardRowLabel>
                        {discountSummaryData.label}
                      </BodyCardRowLabel>
                      <BodyCardRowValue>
                        {countryFormatHelper(discountSummaryData)}
                      </BodyCardRowValue>
                    </BodyCardRow>
                  ))}
                </CardPrimary>

                {/* Second Card Information */}
                {isReplacementOperation && (
                  <CardPrimary>
                    <TitleCardPrimary>
                      Resumen de las facturas a reemplazar
                    </TitleCardPrimary>
                    {replacementOperationDiscountSummary.map(
                      invoiceReplacementDataMaped => (
                        <BodyCardRow key={invoiceReplacementDataMaped.label}>
                          <BodyCardRowLabel>
                            {invoiceReplacementDataMaped.label}
                          </BodyCardRowLabel>
                          <BodyCardRowValue>
                            {countryFormatHelper(invoiceReplacementDataMaped)}
                          </BodyCardRowValue>
                        </BodyCardRow>
                      )
                    )}
                    {replacementOperationDiscountInterest.value !== 0 && (
                      <CardTertiary>
                        <BodyCardRow>
                          <BodyCardRowLabel>
                            {replacementOperationDiscountInterest.label}
                          </BodyCardRowLabel>
                          <BodyCardRowValue>
                            {countryFormatHelper(
                              replacementOperationDiscountInterest
                            )}
                          </BodyCardRowValue>
                        </BodyCardRow>
                      </CardTertiary>
                    )}
                  </CardPrimary>
                )}

                {discount.reason && (
                  <>
                    {/* Total amounts Information */}
                    <CardSecondary>
                      {getTotalAmountsSummary().map(totalAmountSummary => (
                        <BodyCardRow key={totalAmountSummary.label}>
                          <BodyCardRowLabel>
                            {totalAmountSummary.label}
                          </BodyCardRowLabel>
                          {totalAmountSummary.textGreen ? (
                            <BodyCardRowValueGreen>
                              {countryFormatHelper(totalAmountSummary)}
                            </BodyCardRowValueGreen>
                          ) : (
                            <BodyCardRowValue>
                              {countryFormatHelper(totalAmountSummary)}
                            </BodyCardRowValue>
                          )}
                        </BodyCardRow>
                      ))}
                    </CardSecondary>

                    <ContainerComment>
                      <LabelComment>Comentario</LabelComment>
                      <Typography variant="caption">(Opcional)</Typography>
                    </ContainerComment>
                    <Skeleton
                      isLoading={fetchDiscountIsLoading}
                      variant="rect"
                      width={325}
                      height={67}
                    >
                      <TextValidator
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={2}
                        variant="outlined"
                        type="string"
                        id="comment"
                        name="comment"
                        value={discount.comment}
                        onChange={handleInputChange}
                        classes={{
                          root: classes.inputMultiLine,
                        }}
                      />
                    </Skeleton>
                  </>
                )}
              </ContainerRight>
            </ContainerInformation>

            {isReplacementOperation && getReplacementOperationMessage() && (
              <AlertForm
                message={getReplacementOperationMessage()}
                variant="info"
              />
            )}

            {(createDiscountError ||
              updateDiscountError ||
              deleteDiscountError) && (
              <AlertForm
                message={
                  createDiscountError ||
                  updateDiscountError ||
                  deleteDiscountError
                }
                variant="error"
              />
            )}

            <ContainerButtons mt={4}>
              <Button
                disabled={
                  fetchDiscountIsLoading ||
                  createDiscountIsLoading ||
                  updateDiscountIsLoading ||
                  deleteDiscountIsLoading
                }
                variant="outlined"
                color="primary"
                className={classes.footerButtons}
                onClick={() => {
                  handleResetDiscountStore();
                  handleCloseDrawer();
                }}
              >
                Cancelar
              </Button>

              <Button
                disabled={
                  fetchDiscountIsLoading ||
                  createDiscountIsLoading ||
                  updateDiscountIsLoading ||
                  deleteDiscountIsLoading ||
                  (calculations?.totalAmount === 0 && isReplacementOperation)
                }
                variant="contained"
                color="primary"
                type="submit"
                className={classes.footerButtons}
              >
                Aplicar descuento
              </Button>
            </ContainerButtons>

            <TextDescriptionDelete>
              ¿Desea eliminar el descuento existente?
            </TextDescriptionDelete>

            <ContainerDeleteButton>
              <Button
                disabled={
                  fetchDiscountIsLoading ||
                  createDiscountIsLoading ||
                  updateDiscountIsLoading ||
                  deleteDiscountIsLoading
                }
                variant="text"
                color="primary"
                startIcon={<Delete />}
                className={classes.footerButtons}
                onClick={handleDeleteDiscount}
              >
                Eliminar descuento
              </Button>
            </ContainerDeleteButton>
          </ContainerBody>
        </ValidatorForm>
      </ContainerRoot>
    </Drawer>
  );
};

export default DiscountDrawer;
