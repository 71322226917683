import {
    Box,
    Typography,
    Divider as DividerMui,
    makeStyles,
    styled,
  } from '@material-ui/core';
  
  const useStyles = makeStyles({
    rootDrawer: {
      background: '#fff',
    },
  });
  
  const ContainerRoot = styled(Box)({
    width: 470,
    padding: 36,
  });
  const ContainerHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px 5px 10px',
  });
  const ContainerTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
  });
  const ContainerTitleIcon = styled(Box)({
    marginRight: 7,
  });
  const Title = styled(Typography)({
    fontSize: 20,
    fontWeight: 'bold',
  });
  const Divider = styled(DividerMui)({
    marginBottom: 20,
  });
  
  export {
    useStyles,
    ContainerRoot,
    ContainerHeader,
    ContainerTitle,
    ContainerTitleIcon,
    Title,
    Divider,
  };