import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { wsConnect } from '../../../actions/websocket';
import { useFetchInvoices } from '../infrastructure/store';
import { useFetchCessionaries } from '../infrastructure/store/useFetchCessionaries';

const useInvoiceCessionDialog = ({
  requestCessionError,
  cessionSuccess,
  wsconnected,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const { id: orderId } = useParams();
  const { fetchCessionaries } = useFetchCessionaries();
  const { refetch: fetchInvoices } = useFetchInvoices(orderId);

  useEffect(() => {
    if (requestCessionError || cessionSuccess) {
      fetchInvoices();
      handleCloseDialog();
    }
  }, [requestCessionError, cessionSuccess]);

  useEffect(() => {
    fetchCessionaries();
    if (!wsconnected) {
      dispatch(wsConnect());
    }
  }, [wsconnected]);
};

export default useInvoiceCessionDialog;
