import {
  FETCH_CESSIONS_START,
  FETCH_CESSIONS_SUCCESS,
  FETCH_CESSIONS_FAILURE,
  FETCH_CESSION_START,
  FETCH_CESSION_SUCCESS,
  FETCH_CESSION_FAILURE,
  FETCH_CESSIONARY_START,
  FETCH_CESSIONARY_SUCCESS,
  FETCH_CESSIONARY_FAILURE,
  FETCH_CESSIONARIES_START,
  FETCH_CESSIONARIES_SUCCESS,
  FETCH_CESSIONARIES_FAILURE,
  MAKE_CESSION_START,
  MAKE_CESSION_SUCCESS,
  MAKE_CESSION_FAILURE,
  HANDLE_CLOSE_CESSION_DIALOG,
} from '../actions/types';

const initialState = {
  loadingCessions: false,
  cessions: [],
  cession: {},
  cessionError: null,
  loadingCessionary: false,
  cessionary: {},
  cessionaryError: null,
  loadingCessionaries: false,
  cessionaries: [],
  cessionariesError: false,
  requestingCession: false,
  cessionSuccess: false,
  requestCessionError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CESSIONS_START:
      return { ...state, loadingCessions: true };
    case FETCH_CESSIONS_SUCCESS:
      return {
        ...state,
        loadingCessions: false,
        cessions: payload.orderData,
      };
    case FETCH_CESSIONS_FAILURE:
      return {
        ...state,
        loadingCessions: false,
        cessionError: payload.errorCode,
      };

    case FETCH_CESSION_START:
      return { ...state, fetchingCession: true };
    case FETCH_CESSION_SUCCESS:
      return {
        ...state,
        fetchingCession: false,
        cession: payload.cession,
      };
    case FETCH_CESSION_FAILURE:
      return {
        ...state,
        fetchingCession: false,
        cessionError: payload.errorCode,
      };

    case FETCH_CESSIONARY_START:
      return { ...state, loadingCessionary: true };
    case FETCH_CESSIONARY_SUCCESS:
      return {
        ...state,
        loadingCessionary: false,
        cessionary: payload.orderData,
      };
    case FETCH_CESSIONARY_FAILURE:
      return {
        ...state,
        loadingCessionary: false,
        cessionaryError: payload.errorCode,
      };

    case FETCH_CESSIONARIES_START:
      return { ...state, loadingCessionaries: true };
    case FETCH_CESSIONARIES_SUCCESS:
      return {
        ...state,
        loadingCessionaries: false,
        cessionaries: payload.cessionaries,
      };
    case FETCH_CESSIONARIES_FAILURE:
      return {
        ...state,
        loadingCessionaries: false,
        cessionariesError: payload.errorCode,
      };

    case MAKE_CESSION_START:
      return {
        ...state,
        cessionSuccess: false,
        requestingCession: true,
        requestCessionError: null,
      };

    case MAKE_CESSION_SUCCESS:
      return {
        ...state,
        cessionSuccess: payload.success,
        requestingCession: false,
      };

    case MAKE_CESSION_FAILURE:
      return {
        ...state,
        requestingCession: false,
        requestCessionError: payload.errorCode,
      };

    case HANDLE_CLOSE_CESSION_DIALOG:
      return {
        ...state,
        requestingCession: false,
        cessionSuccess: false,
        requestCessionError: null,
      };

    default:
      return state;
  }
};
