import React from 'react';
import { SecureRoute } from '@okta/okta-react';

import { Receivers } from '../../opportunities/ui/Opportunities/Receivers';
import { Documents } from '../../opportunities/ui/Opportunities/Documents';
import requireAuth from '../../../components/hoc/require_okta_auth';
import { FUNDS_SECTION_PERFORM } from '../../../helpers/performsType';
import { Creation } from '../../opportunities/ui/Creation';

export const OpportunitiesRoutes = basePath => {
  return [
    <SecureRoute
      key={1}
      exact
      path={basePath}
      component={requireAuth(Receivers, FUNDS_SECTION_PERFORM)}
    />,
    <SecureRoute
      key={2}
      exact
      path={`${basePath}/creation`}
      component={requireAuth(Creation, FUNDS_SECTION_PERFORM)}
    />,
    <SecureRoute
      key={3}
      exact
      path={`${basePath}/:receiverIdentifier`}
      component={requireAuth(Documents, FUNDS_SECTION_PERFORM)}
    />,
  ];
};
