import { gql } from 'graphql-request';

export const query = gql`
  query TransitionActions($dto: GetTransitionActionsDTO!) {
    transitionActions(dto: $dto) {
      id
      comment
      createdAt
      origin {
        id
        name
      }
      target {
        id
        name
      }
      workflow {
        id
        name
      }
      modifiedByUserRef
    }
  }
`;

export type TransitionActions = {
  id: string | null;
  comment: string | null;
  createdAt: string;
  origin: {
    id: string;
    name: string;
  };
  target: {
    id: string;
    name: string;
  };
  workflow: {
    id: string;
    name: string;
  };
  modifiedByUserRef: string;
};

export type Query = {
  transitionActions: TransitionActions[];
};
