import React, { FC } from 'react';
import StatusFilterLabel from './StatusFilterLabel';
import { StatusFilter as IStatusFilter } from '../../../commons/interfaces/ARInterfaces';
import { CustomTabs, Tab } from './styles';

interface Props {
  filters: IStatusFilter[];
  activeFilter: string;
  handler: (value: string) => void;
}

const StatusFilter: FC<Props> = ({ filters, activeFilter, handler }) => {
  return (
    <CustomTabs
      value={activeFilter}
      onChange={(_e_, value) => handler(value)}
      variant="scrollable"
      scrollButtons="auto"
    >
      {filters.map(({ key, label }) => (
        <Tab
          key={key}
          data-cy={`FILTER_STATUS_${key}`}
          label={
            <StatusFilterLabel
              status={{ key, label }}
              selected={activeFilter}
            />
          }
          value={key}
        />
      ))}
    </CustomTabs>
  );
};

export default StatusFilter;
