import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import CheckButton from './CheckButton';
import { WALLET_STATUS_SELECT } from '../../helpers/Constants';

const useStyles = makeStyles({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 5,
  },
  dialog: {
    borderRadius: '17px',
  },
  dialogTitle: {
    marginBottom: 10,
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: '20px 50px',
  },
});

const WalletStatusDialog = ({ open, onClose, onSubmit, currentStatus }) => {
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(currentStatus);

  const classes = useStyles();

  useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle className={classes.closeButtonContainer}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Typography
        align="center"
        className={classes.dialogTitle}
        variant="h5"
        color="textPrimary"
        component="div"
      >
        <Box fontWeight="fontWeightBold">Cambiar estado de la Wallet</Box>
      </Typography>
      <DialogContent className={classes.dialogContent}>
        <Grid item xs={12}>
          <ValidatorForm onSubmit={() => onSubmit(status)}>
            <SelectValidator
              fullWidth
              variant="outlined"
              label="Estado"
              name="status"
              value={status}
              onChange={e => setStatus(e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {WALLET_STATUS_SELECT.map(({ value, label }) => {
                return <MenuItem value={value}>{label}</MenuItem>;
              })}
            </SelectValidator>

            <Grid item xs={12} className={classes.justifyCenter}>
              <CheckButton
                check={checked}
                handleCheck={handleCheckboxChange}
                labelButton="Guardar"
              />
            </Grid>
          </ValidatorForm>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

WalletStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentStatus: PropTypes.string.isRequired,
};

export default WalletStatusDialog;
