import React, { useState, FC, ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import CheckButton from '../../../components/elements/CheckButton';
import AlertForm from '../../../components/elements/AlertForm';
import {
  GLOBAL_REASONS,
  STATUS_APPEALED,
  STATUS_REJECTED,
} from '../../../helpers/Constants';
import { checkStatusAuth } from '../../../helpers/validation/auth';
import useStatuses from '../../../hooks/useStatuses';
import useGetCountry from '../../../hooks/useGetCountry';

const useStyles = makeStyles({
  selectInput: {
    marginBottom: 10,
  },
});

interface Props {
  open: boolean;
  statusOrder?: string | null;
  title: string;
  error?: string | null;
  isLoading: boolean;
  warning?: JSX.Element | null;
  isHold?: boolean;
  handleSubmit: (statusSelected: string, statusReason: string) => void;
  handleCloseDialog: () => void;
}

const ChangeStatusOrderModal: FC<Props> = ({
  open,
  statusOrder = null,
  title,
  error = null,
  isLoading,
  warning = null,
  isHold = false,
  handleSubmit,
  handleCloseDialog,
}) => {
  const classes = useStyles();
  const country = useGetCountry();
  const { getStatuses } = useStatuses();
  const { rules } = useSelector((state: RootStateOrAny) => state.auth);

  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState<string | null>(
    statusOrder
  );
  const [showStatusReason, setShowStatusReason] = useState(false);
  const [statusReason, setStatusReason] = useState<string | null>(null);

  const handleSelectStatus = (status: string) => {
    setStatusSelected(status);
    if ([STATUS_REJECTED, STATUS_APPEALED].includes(status)) {
      setShowStatusReason(true);
    } else {
      setShowStatusReason(false);
    }
  };

  const notAvailableHoldStatuses = ['ACTIVE', 'TO_DEPOSIT'];

  return (
    <BaseDialog isOpen={open} handleClose={handleCloseDialog} title={title}>
      <ValidatorForm
        onSubmit={() => handleSubmit(statusSelected!, statusReason!)}
      >
        <Grid container>
          <Grid item xs={12} className={classes.selectInput}>
            <SelectValidator
              name="changeOrderState"
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleSelectStatus(event.target.value)
              }
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
            >
              {getStatuses('order').map(({ key, label }) => (
                <MenuItem
                  key={key}
                  value={key}
                  disabled={
                    !checkStatusAuth(key, 'ORDER', rules) ||
                    key === 'RENEGOTIATION' ||
                    (isHold && notAvailableHoldStatuses.includes(key))
                  }
                >
                  {isHold && notAvailableHoldStatuses.includes(key) ? (
                    <Typography variant="body2" color="textPrimary">
                      {label}{' '}
                      <span style={{ fontStyle: 'italic' }}>
                        (orden seleccionada con facturas temporales)
                      </span>
                    </Typography>
                  ) : (
                    <span>{label}</span>
                  )}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>

          {showStatusReason && (
            <Grid item xs={12} className={classes.selectInput}>
              <SelectValidator
                fullWidth
                variant="outlined"
                name="operationReason"
                id="operation-reason-select"
                value={statusReason}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setStatusReason(event.target.value)
                }
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
              >
                {GLOBAL_REASONS.filter(
                  reason =>
                    reason.type === `INVOICE_${statusSelected}` &&
                    reason.country.includes(country)
                ).map(reason => (
                  <MenuItem key={reason.value} value={reason.value}>
                    {reason.label}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}

          {!isLoading && error && <AlertForm variant="error" message={error} />}

          {!isLoading && warning && (
            <AlertForm variant="info" messageComponent={warning} />
          )}

          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

export default ChangeStatusOrderModal;
