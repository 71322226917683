import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EditPen = props => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M9.33347 22.639H10.6801L19.5506 13.7685L18.2039 12.4218L9.33347 21.2923V22.639ZM24.5716 24.5437H7.42871V20.5028L20.2239 7.70756C20.4025 7.52901 20.6447 7.42871 20.8973 7.42871C21.1498 7.42871 21.392 7.52901 21.5706 7.70756L24.2649 10.4018C24.4434 10.5804 24.5437 10.8226 24.5437 11.0752C24.5437 11.3277 24.4434 11.5699 24.2649 11.7485L13.3744 22.639H24.5716V24.5437ZM19.5506 11.0752L20.8973 12.4218L22.2439 11.0752L20.8973 9.72851L19.5506 11.0752Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default EditPen;
