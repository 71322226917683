/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import { BUSINESS_SEGMENT } from '../../../../../../helpers/Constants';
import { dateFormatter } from '../../../../../../helpers/DateUtils';
import {
  useAddPartialPayment,
  useCalcultateDebtPartialPayment,
  useFetchPartialPayments,
  useOrder,
  useUpdatePartialPayment,
} from '../../../../infrastructure/store';
import { useShowPartialPaymentDrawer } from '../store';

const usePartialPaymentsDrawer = ({ selectedInvoice, country, isOpen }) => {
  const PAY = 'PAY';
  const HISTORY = 'HISTORY';
  const {
    refetchPartialPayments,
    fetchPartialPaymentsIsLoading,
    fetchPartialPaymentData,
  } = useFetchPartialPayments({
    sourceType: 'orderinvoice',
    sourceId: selectedInvoice?.id,
  });
  const {
    refetchCalculateDebtPartialPayment,
    calculateDebtPartialPaymentIsLoading,
    debtPartialPaymentServiceData,
  } = useCalcultateDebtPartialPayment({
    sourceType: 'orderinvoice',
    sourceId: selectedInvoice?.id,
  });
  const {
    addPartialPayment,
    addPartialPaymentError,
    addPartialPaymentIsLoading,
    addPartialPaymentSuccess,
  } = useAddPartialPayment({
    sourceType: 'orderinvoice',
    sourceId: selectedInvoice?.id,
    operationId: selectedInvoice?.orderId,
  });
  const [partialPaymentSelected, setPartialPaymentSelected] = useState(null);
  const {
    updatePartialPayment,
    updatePartialPaymentIsLoading,
    updatePartialPaymentError,
    updatePartialPaymentSuccess,
  } = useUpdatePartialPayment({
    sourceType: 'orderinvoice',
    sourceId: selectedInvoice?.id,
    partialPaymentId: partialPaymentSelected?.id,
    operationId: selectedInvoice?.orderId,
  });

  const showPartialPaymentDrawer = useShowPartialPaymentDrawer();

  const { id: orderId } = useParams();

  const { order } = useOrder(orderId);

  const advancePercentage = (order?.advance * 100) / order?.finalAmount;

  const sortedPartialPayments = fetchPartialPaymentData?.data.data.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );

  const [partialPaymentCalculations, setPartialPaymentCalculations] = useState(
    debtPartialPaymentServiceData
  );
  const [showAddPartialPaymentDialog, setAddShowPartialPaymentDialog] =
    useState(false);
  const [showUpdatePartialPaymentDialog, setShowUpdatePartialPaymentDialog] =
    useState(false);
  const [businessSegment, setBusinessSegment] = useState(null);
  const [stateTab, setStateTab] = useState(PAY);

  useEffect(() => {
    if (addPartialPaymentSuccess) {
      setPartialPaymentCalculations(debtPartialPaymentServiceData?.data);
      setAddShowPartialPaymentDialog(false);
      refetchPartialPayments();
    }
  }, [addPartialPaymentSuccess]);

  useEffect(() => {
    if (updatePartialPaymentSuccess) {
      setPartialPaymentCalculations(debtPartialPaymentServiceData?.data);
      setShowUpdatePartialPaymentDialog(false);
      refetchPartialPayments();
    }
  }, [updatePartialPaymentSuccess]);

  useEffect(() => {
    if (showPartialPaymentDrawer) {
      refetchPartialPayments();
    }
  }, []);

  useEffect(() => {
    setPartialPaymentCalculations(debtPartialPaymentServiceData?.data);
  }, [debtPartialPaymentServiceData]);

  useEffect(() => {
    if (stateTab === HISTORY && isOpen) {
      refetchPartialPayments();
    } else if (stateTab === PAY && isOpen) {
      refetchCalculateDebtPartialPayment();
    }
  }, [stateTab, isOpen]);

  useEffect(() => {
    if (partialPaymentCalculations?.segment) {
      const getBusinessSegment = BUSINESS_SEGMENT.find(
        ({ value }) => value === partialPaymentCalculations?.segment
      );
      setBusinessSegment(getBusinessSegment?.label || null);
    }
  }, [partialPaymentCalculations]);

  const handleSelectPartialPayment = useCallback(
    row => {
      setPartialPaymentSelected(row);
      setShowUpdatePartialPaymentDialog(true);
    },
    [setPartialPaymentSelected, setShowUpdatePartialPaymentDialog]
  );

  const handleAddPartialPayment = useCallback(
    data => {
      addPartialPayment({ ...data });
    },
    [addPartialPayment]
  );

  const handleUpdatePartialPayment = useCallback(
    data => {
      updatePartialPayment({ ...data });
    },
    [updatePartialPayment]
  );

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const dataList = [
    {
      label: 'Folio',
      value: selectedInvoice?.folio,
    },
    {
      label: 'Receptor',
      value:
        selectedInvoice?.payerName?.length > 30
          ? `${selectedInvoice?.payerName?.substring(0, 30)}...`
          : selectedInvoice?.payerName,
    },
    {
      label: 'Fecha vencimiento',
      value: dateFormatter(selectedInvoice?.expirationDate),
    },
    {
      label: 'Anticipo',
      value: (
        <CountryFormatHelper
          value={advancePercentage}
          countryId={country}
          variant="percentage"
        />
      ),
    },
    {
      label: 'Tasa de mora',
      value: (
        <CountryFormatHelper
          value={partialPaymentCalculations?.baseRate}
          countryId={country}
          variant="percentage"
        />
      ),
    },
    {
      label: 'Segmento empresarial',
      value: businessSegment,
    },
  ];

  const dataPayList = [
    {
      label: 'Deuda capital',
      value: (
        <CountryFormatHelper
          value={partialPaymentCalculations?.totalCapitalDebt}
          countryId={country}
          variant="currency"
        />
      ),
    },
    {
      label: 'Deuda interés',
      value: (
        <CountryFormatHelper
          value={partialPaymentCalculations?.debtInterestAtDate}
          countryId={country}
          variant="currency"
        />
      ),
    },
  ];

  const tabList = [
    { label: 'Pagar', value: PAY },
    {
      label: 'Historial',
      value: HISTORY,
    },
  ];

  const locales = {
    title: 'Agregar pago parcial',
    description: 'Pagos parciales',
  };

  return {
    dataList,
    dataPayList,
    tabList,
    showAddPartialPaymentDialog,
    showUpdatePartialPaymentDialog,
    partialPaymentSelected,
    stateTab,
    a11yProps,
    setStateTab,
    setShowUpdatePartialPaymentDialog,
    setAddShowPartialPaymentDialog,
    handleSelectPartialPayment,
    handleAddPartialPayment,
    handleUpdatePartialPayment,
    updatePartialPaymentIsLoading,
    updatePartialPaymentError,
    addPartialPaymentIsLoading,
    addPartialPaymentError,
    fetchPartialPaymentsIsLoading,
    calculateDebtPartialPaymentIsLoading,
    partialPayments: sortedPartialPayments,
    partialPaymentCalculations,
    locales,
  };
};

export default usePartialPaymentsDrawer;
