import {
  FETCH_CREDITS_START,
  FETCH_CREDITS_SUCCESS,
  FETCH_CREDITS_FAILURE,
  UPDATE_CREDIT_START,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_CREDIT_FAILURE,
  REGISTER_CREDIT_START,
  REGISTER_CREDIT_SUCCESS,
  REGISTER_CREDIT_FAILURE,
  FETCH_FEES_START,
  FETCH_FEES_SUCCESS,
  FETCH_FEES_FAILURE,
  FETCH_SELECTED_CREDIT_START,
  FETCH_SELECTED_CREDIT_SUCCESS,
  FETCH_SELECTED_CREDIT_FAILURE,
  UPDATE_FEE_START,
  UPDATE_FEE_SUCCESS,
  UPDATE_FEE_FAILURE,
  REGISTER_FEE_START,
  REGISTER_FEE_SUCCESS,
  REGISTER_FEE_FAILURE,
  RESET_SELECTED_CREDIT,
  DELETE_FEE_START,
  DELETE_FEE_SUCCESS,
  DELETE_FEE_FAILURE,
  FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_START,
  FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE,
} from '../actions/types';

const initialState = {
  fetchCreditsIsLoading: false,
  credits: [],
  creditPagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
    totalPages: 1,
  },

  registerCreditIsLoading: false,
  registerCreditError: null,
  creditWasRegistered: false,

  updateCreditIsLoading: false,
  updateCreditError: null,
  creditWasUpdated: false,

  fees: [],
  fetchFeesIsLoading: false,
  feesPagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
    totalPages: 1,
  },

  fetchSelectedCreditIsLoading: false,
  selectedCredit: {},

  registerFeeIsLoading: false,
  feesRegistered: false,

  updateFeeIsLoading: false,
  feeUpdated: false,
  updateFeeError: null,

  deleteFeeIsLoading: false,
  feeDeleted: false,
  deleteFeeError: null,

  cyaInvoiceDocuments: [],
  fetchCyaInvoiceDocumentsIsLoading: false,
  fetchCyaInvoiceDocumentsError: null,
};

const fetchCreditsStart = state => {
  return {
    ...state,
    fetchCreditsIsLoading: true,
  };
};
const fetchCreditsSuccess = (state, payload) => {
  return {
    ...state,
    fetchCreditsIsLoading: false,
    credits: payload.data,
    creditPagination: payload.pagination,
  };
};
const fetchCreditsFailure = state => {
  return {
    ...state,
    fetchCreditsIsLoading: false,
  };
};

const fetchCyaInvoiceDocumentsStart = state => {
  return {
    ...state,
    fetchCyaInvoiceDocumentsIsLoading: true,
    fetchCyaInvoiceDocumentsError: null,
  };
};

const fetchCyaInvoiceDocumentsSuccess = (state, payload) => {
  return {
    ...state,
    fetchCyaInvoiceDocumentsIsLoading: false,
    cyaInvoiceDocuments: payload.cyaInvoiceDocuments,
  };
};

const fetchCyaInvoiceDocumentsFailure = (state, payload) => {
  return {
    ...state,
    fetchCyaInvoiceDocumentsError: payload.errorCode,
    fetchCyaInvoiceDocumentsIsLoading: false,
  };
};

const registerCreditStart = state => {
  return {
    ...state,
    registerCreditIsLoading: true,
    registerCreditError: null,
    creditWasRegistered: false,
  };
};
const registerCreditSuccess = (state, payload) => {
  const pagination = { ...state.creditPagination };
  return {
    ...state,
    registerCreditIsLoading: false,
    creditWasRegistered: true,
    credits: [payload.newCredit, ...state.credits],
    creditPagination: { ...pagination, totalData: pagination.totalData + 1 },
  };
};
const registerCreditFailure = (state, payload) => {
  return {
    ...state,
    registerCreditIsLoading: false,
    registerCreditError: payload?.errorCode,
  };
};

const updateCreditStart = state => {
  return {
    ...state,
    updateCreditIsLoading: true,
    updateCreditError: null,
    creditWasUpdated: false,
  };
};
const updateCreditSuccess = (state, payload) => {
  const credits = state.credits.map(credit => {
    if (credit.id === payload.id) {
      return payload;
    }
    return credit;
  });

  return {
    ...state,
    updateCreditIsLoading: false,
    creditWasUpdated: true,
    credits,
    selectedCredit: payload,
  };
};
const updateCreditFailure = (state, payload) => {
  return {
    ...state,
    updateCreditIsLoading: false,
    creditWasUpdated: true,
    updateCreditError: payload.errorCode,
  };
};

const fetchFeesStart = state => {
  return {
    ...state,
    fees: [],
    fetchFeesIsLoading: true,
  };
};
const fetchFeesSuccess = (state, payload) => {
  return {
    ...state,
    fees: payload.data,
    fetchFeesIsLoading: false,
    feesPagination: payload.pagination,
  };
};
const fetchFeesFailure = state => {
  return {
    ...state,
    fetchFeesIsLoading: false,
  };
};

const fetchSelectedCreditStart = state => {
  return {
    ...state,
    fetchSelectedCreditIsLoading: true,
  };
};
const fetchSelectedCreditSuccess = (state, payload) => {
  return {
    ...state,
    fetchSelectedCreditIsLoading: false,
    selectedCredit: payload.selectedCredit,
  };
};
const fetchSelectedCreditFailure = state => {
  return {
    ...state,
    fetchSelectedCreditIsLoading: false,
  };
};

const registerFeeStart = state => {
  return { ...state, registerFeeIsLoading: true, feesRegistered: false };
};
const registerFeeSuccess = (state, payload) => {
  const pagination = { ...state.feesPagination };
  return {
    ...state,
    fees: [...state.fees, payload.newCreditFees],
    feesPagination: { ...pagination, totalData: pagination.totalData + 1 },
    registerFeeIsLoading: false,
    feesRegistered: true,
  };
};
const registerFeeFailure = state => {
  return {
    ...state,
    registerFeeIsLoading: false,
  };
};

const updateFeeStart = state => {
  return {
    ...state,
    updateFeeIsLoading: true,
    updateFeeError: null,
    feeUpdated: false,
  };
};
const updateFeeSuccess = (state, payload) => {
  const updatedCreditFees = state.fees.map(fee => {
    if (fee.id === payload.id) {
      return { ...payload, feeNumber: fee.feeNumber };
    }
    return fee;
  });

  return {
    ...state,
    updateFeeIsLoading: false,
    feeUpdated: true,
    fees: updatedCreditFees,
  };
};
const updateFeeFailure = (state, payload) => {
  return {
    ...state,
    updateFeeIsLoading: false,
    updateFeeError: payload.errorCode,
  };
};

const deleteFeeStart = state => {
  return {
    ...state,
    deleteFeeIsLoading: true,
    feeDeleted: false,
    deleteFeeError: null,
  };
};
const deleteFeeSuccess = (state, payload) => {
  const newCreditFees = state.fees.filter(fee => fee.id !== payload.id);
  const feesPagination = { ...state.feesPagination };

  return {
    ...state,
    deleteFeeIsLoading: false,
    feeDeleted: true,
    fees: newCreditFees,
    feesPagination: {
      ...feesPagination,
      totalData: feesPagination.totalData - 1,
    },
  };
};
const deleteFeeFailure = (state, payload) => {
  return {
    ...state,
    deleteFeeIsLoading: false,
    deleteFeeError: payload.errorCode,
  };
};

const resetSelectedCredit = state => {
  return { ...state, selectedCredit: {} };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CREDITS_START:
      return fetchCreditsStart(state);
    case FETCH_CREDITS_SUCCESS:
      return fetchCreditsSuccess(state, payload);
    case FETCH_CREDITS_FAILURE:
      return fetchCreditsFailure(state);
    case UPDATE_CREDIT_START:
      return updateCreditStart(state);
    case UPDATE_CREDIT_SUCCESS:
      return updateCreditSuccess(state, payload);
    case UPDATE_CREDIT_FAILURE:
      return updateCreditFailure(state, payload);
    case REGISTER_CREDIT_START:
      return registerCreditStart(state);
    case REGISTER_CREDIT_SUCCESS:
      return registerCreditSuccess(state, payload);
    case REGISTER_CREDIT_FAILURE:
      return registerCreditFailure(state, payload);
    case FETCH_FEES_START:
      return fetchFeesStart(state);
    case FETCH_FEES_SUCCESS:
      return fetchFeesSuccess(state, payload);
    case FETCH_FEES_FAILURE:
      return fetchFeesFailure(state);
    case FETCH_SELECTED_CREDIT_START:
      return fetchSelectedCreditStart(state);
    case FETCH_SELECTED_CREDIT_SUCCESS:
      return fetchSelectedCreditSuccess(state, payload);
    case FETCH_SELECTED_CREDIT_FAILURE:
      return fetchSelectedCreditFailure(state);
    case REGISTER_FEE_START:
      return registerFeeStart(state);
    case REGISTER_FEE_SUCCESS:
      return registerFeeSuccess(state, payload);
    case REGISTER_FEE_FAILURE:
      return registerFeeFailure(state);
    case UPDATE_FEE_START:
      return updateFeeStart(state);
    case UPDATE_FEE_SUCCESS:
      return updateFeeSuccess(state, payload);
    case UPDATE_FEE_FAILURE:
      return updateFeeFailure(state, payload);
    case DELETE_FEE_START:
      return deleteFeeStart(state);
    case DELETE_FEE_SUCCESS:
      return deleteFeeSuccess(state, payload);
    case DELETE_FEE_FAILURE:
      return deleteFeeFailure(state, payload);
    case RESET_SELECTED_CREDIT:
      return resetSelectedCredit(state);
    case FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_START:
      return fetchCyaInvoiceDocumentsStart(state);
    case FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS:
      return fetchCyaInvoiceDocumentsSuccess(state, payload);
    case FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE:
      return fetchCyaInvoiceDocumentsFailure(state, payload);
    default:
      return state;
  }
};
