import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Timer = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C20.4678 9.17917 21.1609 11.4579 20.9691 13.7501C20.7772 16.0424 19.715 18.1742 18.0005 19.7077C16.286 21.2412 14.0494 22.0601 11.75 21.9961C9.4506 21.9321 7.26301 20.9901 5.63647 19.3635C4.00993 17.737 3.06793 15.5494 3.00392 13.25C2.93991 10.9506 3.75875 8.71402 5.29228 6.99951C6.82581 5.285 8.95761 4.22275 11.2499 4.03092C13.5421 3.83909 15.8208 4.53223 17.618 5.968ZM12 20C12.9193 20 13.8295 19.8189 14.6788 19.4672C15.5281 19.1154 16.2997 18.5998 16.9497 17.9497C17.5998 17.2997 18.1154 16.5281 18.4672 15.6788C18.8189 14.8295 19 13.9193 19 13C19 12.0807 18.8189 11.1705 18.4672 10.3212C18.1154 9.47194 17.5998 8.70026 16.9497 8.05025C16.2997 7.40024 15.5281 6.88463 14.6788 6.53284C13.8295 6.18106 12.9193 6 12 6C10.1435 6 8.36301 6.7375 7.05025 8.05025C5.7375 9.36301 5 11.1435 5 13C5 14.8565 5.7375 16.637 7.05025 17.9497C8.36301 19.2625 10.1435 20 12 20ZM11 8H13V14H11V8ZM8 1H16V3H8V1Z" />
    </SvgIcon>
  );
};

export default Timer;
