import React, { useCallback } from 'react';
import useGetCountry from '../../../../../hooks/useGetCountry';
import {
  STATUS_IN_REVIEW,
  STATUS_REVIEWED,
  STATUS_APPEALED,
  STATUS_APPROVED_FUND,
  STATUS_APPROVED,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
} from '../../../../../helpers/Constants';
import {
  Requirement,
  RequirementApiResponse,
  RequirementApiResponseDetail,
  RequirementDictionary,
  RequirementResponse,
  RequirementsByOperationType,
  RequirementSections,
  RequirementStatusDictionary,
  RequirementSectionValues,
} from '../interfaces';
import {
  LinearProgressFull,
  LinearProgressDefault,
  LinearProgressMiddle,
  LinearProgressEmpty,
} from '../styles';
import PendingActionsIcon from '../../../icons/PendingActionsIcon';
import WarningIcon from '../../../icons/WarningIcon';
import CloseIcon from '../../../icons/CloseIcon';
import TextIcon from '../../../icons/TextIcon';
import DoneAllIcon from '../../../icons/DoneAllIcon';
import DoneIcon from '../../../icons/DoneIcon';
import {
  OperationTypes,
  XepelinStatus,
} from '../../../interfaces/ARInterfaces';

const useRequirements = () => {
  const country = useGetCountry();

  const requirementStatus: RequirementStatusDictionary = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    EXPIRED: 'EXPIRED',
    REJECTED: 'REJECTED',
    UPLOAD: 'UPLOAD',
  };

  const dictionary: RequirementDictionary = {
    invoiceCeded: 'INVOICE_CEDED',
    invoicePdf: 'INVOICE_PDF',
    invoiceVerified: 'INVOICE_VERIFIED',
    invoicePaymentDateConfirmed: 'PAYMENT_DATE_CONFIRMED',
    invoiceExecutiveMerit: 'INVOICES_EXECUTIVE_MERIT',
    PEP: 'PEP',
    UAF: 'UAF',
    contract: 'UNIFIED_CONTRACT',
    documentIdentifier: 'CI',
    statutes: 'STATUTES',
    factoringConfirming: 'FACTORING_CONFIRMING',
    factoringNotification: 'FACTORING_NOTIFICATION',
    promissoryNote: 'PROMISSORY_NOTE',
    factoringContract: 'FACTORING_CONTRACT',
    KYC: 'KYC',
    domiciliation: 'DOMICILIATION',
  };

  const requirementByOperationType: RequirementsByOperationType = {
    CL: {
      INVOICE_CEDED: [DIRECT_FINANCING, EARLY_PAYMENT],
      INVOICE_PDF: [DIRECT_FINANCING, EARLY_PAYMENT],
      INVOICE_VERIFIED: [DIRECT_FINANCING, EARLY_PAYMENT],
      PAYMENT_DATE_CONFIRMED: [DIRECT_FINANCING, EARLY_PAYMENT],
      INVOICES_EXECUTIVE_MERIT: [DIRECT_FINANCING, EARLY_PAYMENT],
      PEP: [DIRECT_FINANCING, EARLY_PAYMENT],
      UAF: [DIRECT_FINANCING, EARLY_PAYMENT],
      UNIFIED_CONTRACT: [DIRECT_FINANCING, EARLY_PAYMENT],
      CI: [DIRECT_FINANCING, EARLY_PAYMENT],
      STATUTES: [DIRECT_FINANCING, EARLY_PAYMENT],
    },
    MX: {
      INVOICE_VERIFIED: [EARLY_PAYMENT],
      FACTORING_CONFIRMING: [DIRECT_FINANCING, EARLY_PAYMENT],
      FACTORING_NOTIFICATION: [DIRECT_FINANCING, EARLY_PAYMENT],
      PROMISSORY_NOTE: [DIRECT_FINANCING, EARLY_PAYMENT],
      FACTORING_CONTRACT: [DIRECT_FINANCING, EARLY_PAYMENT],
      KYC: [DIRECT_FINANCING, EARLY_PAYMENT],
      DOMICILIATION: [DIRECT_FINANCING],
    },
  };

  const requirementSections: RequirementSections = {
    invoices: 'invoices',
    legalDocuments: 'legalDocuments',
  };

  const handleGetProgress = useCallback(
    (successProgress: number, totalProgress: number): number => {
      return Math.round((successProgress * 100) / totalProgress);
    },
    []
  );

  const sectionData: { key: RequirementSectionValues; label: string }[] = [
    {
      key: requirementSections.invoices,
      label: 'Facturas',
    },
    {
      key: requirementSections.legalDocuments,
      label: 'Documentos Legal',
    },
  ];

  const transformRequirementData = useCallback(
    (operationData: RequirementApiResponse) => {
      const dataValues: RequirementApiResponseDetail[][] =
        Object.values(operationData);

      let dataArray: RequirementApiResponseDetail[] = [];
      dataValues.forEach(dataValue => {
        dataArray = [...dataArray, ...dataValue];
      });

      let requirementResponse: RequirementResponse = {};

      dataArray.forEach(({ description, status, id }) => {
        requirementResponse[description] = {
          id,
          status,
        };
      });

      return requirementResponse;
    },
    []
  );

  const handleGetRequirementData = useCallback(
    ({
      section,
      rawRequirementData,
      operationType,
      operationStatus,
    }: {
      section: RequirementSectionValues;
      rawRequirementData: RequirementResponse;
      operationType: OperationTypes;
      operationStatus: XepelinStatus;
    }) => {
      const requirementsList: Requirement = {
        CL: [
          {
            id: rawRequirementData[dictionary.invoiceCeded]?.id!,
            keyValue: dictionary.invoiceCeded,
            label: 'Facturas cedidas',
            status: rawRequirementData[dictionary.invoiceCeded]?.status!,
            showDetail: true,
            section: [requirementSections.invoices, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.invoiceCeded] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.invoicePdf]?.id!,
            keyValue: dictionary.invoicePdf,
            label: 'PDF de factura',
            status: rawRequirementData[dictionary.invoicePdf]?.status!,
            showDetail: true,
            section: [requirementSections.invoices, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.invoicePdf] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.invoiceVerified]?.id!,
            keyValue: dictionary.invoiceVerified,
            label: 'Facturas verificadas',
            status: rawRequirementData[dictionary.invoiceVerified]?.status!,
            showDetail: true,
            section: [requirementSections.invoices, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.invoiceVerified] ||
              [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.invoicePaymentDateConfirmed]?.id!,
            keyValue: dictionary.invoicePaymentDateConfirmed,
            label: 'Fechas de pago confirmada',
            status:
              rawRequirementData[dictionary.invoicePaymentDateConfirmed]
                ?.status!,
            showDetail: true,
            section: [requirementSections.invoices, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][
                dictionary.invoicePaymentDateConfirmed
              ] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.invoiceExecutiveMerit]?.id!,
            keyValue: dictionary.invoiceExecutiveMerit,
            label: 'Mérito Ejecutivo',
            status:
              rawRequirementData[dictionary.invoiceExecutiveMerit]?.status!,
            showDetail: true,
            section: [requirementSections.invoices, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][
                dictionary.invoiceExecutiveMerit
              ] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.PEP]?.id!,
            keyValue: dictionary.PEP,
            label: dictionary.PEP,
            status: rawRequirementData[dictionary.PEP]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.PEP] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.UAF]?.id!,
            keyValue: dictionary.UAF,
            label: dictionary.UAF,
            status: rawRequirementData[dictionary.UAF]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.UAF] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.contract]?.id!,
            keyValue: dictionary.contract,
            label: 'Contrato',
            status: rawRequirementData[dictionary.contract]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.contract] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.documentIdentifier]?.id!,
            keyValue: dictionary.documentIdentifier,
            label: 'CI',
            status: rawRequirementData[dictionary.documentIdentifier]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.documentIdentifier] ||
              [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.statutes]?.id!,
            keyValue: dictionary.statutes,
            label: 'Estatutos',
            status: rawRequirementData[dictionary.statutes]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['CL'][dictionary.statutes] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
        ],
        MX: [
          {
            id: rawRequirementData[dictionary.invoiceVerified]?.id!,
            keyValue: dictionary.invoiceVerified,
            label: 'Facturas verificadas',
            status: rawRequirementData[dictionary.invoiceVerified]?.status!,
            showDetail: true,
            section: [requirementSections.invoices, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][dictionary.invoiceVerified] ||
              [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.factoringConfirming]?.id!,
            keyValue: dictionary.factoringConfirming,
            label: 'Confirmación factoraje',
            status: rawRequirementData[dictionary.factoringConfirming]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][
                dictionary.factoringConfirming
              ] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.factoringNotification]?.id!,
            keyValue: dictionary.factoringNotification,
            label: 'Notificación factoraje',
            status:
              rawRequirementData[dictionary.factoringNotification]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][
                dictionary.factoringNotification
              ] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.promissoryNote]?.id!,
            keyValue: dictionary.promissoryNote,
            label: 'Pagaré',
            status: rawRequirementData[dictionary.promissoryNote]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][dictionary.promissoryNote] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.factoringContract]?.id!,
            keyValue: dictionary.factoringContract,
            label: 'Contrato Marco',
            status: rawRequirementData[dictionary.factoringContract]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][dictionary.factoringContract] ||
              [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.KYC]?.id!,
            keyValue: dictionary.KYC,
            label: dictionary.KYC,
            status: rawRequirementData[dictionary.KYC]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][dictionary.KYC] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
          {
            id: rawRequirementData[dictionary.domiciliation]?.id!,
            keyValue: dictionary.domiciliation,
            label: 'Domiciliación',
            status: rawRequirementData[dictionary.domiciliation]?.status!,
            showDetail: false,
            section: [requirementSections.legalDocuments, 'all'],
            availableOperationTypes:
              requirementByOperationType['MX'][dictionary.domiciliation] || [],
            availableOperationStatuses: [
              STATUS_IN_REVIEW,
              STATUS_REVIEWED,
              STATUS_APPEALED,
              STATUS_APPROVED,
              STATUS_APPROVED_FUND,
            ],
          },
        ],
      };

      const requirementSelected = requirementsList[country].filter(
        requirement =>
          requirement?.availableOperationTypes.includes(operationType) &&
          requirement?.availableOperationStatuses.includes(operationStatus) &&
          requirement?.section.includes(section) &&
          requirement?.status
      );

      return requirementSelected;
    },
    [country]
  );

  const handleGetRequirementIcon = useCallback(
    (keyValue: string, status: string) => {
      if (status === requirementStatus.PENDING) {
        return <PendingActionsIcon color="#63606D" />;
      }
      if (status === requirementStatus.EXPIRED) {
        return <WarningIcon color="#FFB100" />;
      }

      if (status === requirementStatus.REJECTED) {
        return <CloseIcon color="#44414C" />;
      }

      if (status === requirementStatus.UPLOAD) {
        return <PendingActionsIcon color="#FFB100" />;
      }

      switch (keyValue) {
        case dictionary.invoiceCeded:
          return <TextIcon text="C" color="#75B801" />;
        case dictionary.invoiceVerified:
          return <TextIcon text="V" color="#75B801" />;
        case dictionary.invoicePdf:
        case dictionary.invoicePaymentDateConfirmed:
        case dictionary.invoiceExecutiveMerit:
          return <DoneAllIcon color="#75B801" />;
        case dictionary.PEP:
        case dictionary.UAF:
        case dictionary.documentIdentifier:
        case dictionary.contract:
        case dictionary.factoringConfirming:
        case dictionary.promissoryNote:
        case dictionary.factoringContract:
        case dictionary.KYC:
        case dictionary.factoringNotification:
        case dictionary.statutes:
        case dictionary.domiciliation:
          return <DoneIcon color="#75B801" />;
        default:
          return <CloseIcon color="#44414C" />;
      }
    },
    []
  );

  const handleGetLinearProgress = useCallback(
    (successRequirements: number, totalRequirements: number) => {
      const percentage = handleGetProgress(
        successRequirements,
        totalRequirements
      );
      if (percentage === 100) {
        return <LinearProgressFull variant="determinate" value={percentage} />;
      }
      return <LinearProgressDefault variant="determinate" value={percentage} />;
    },
    [handleGetProgress]
  );

  const handleGetLinearProgressMulticolor = useCallback(
    (successRequirements: number, totalRequirements: number) => {
      const percentage = handleGetProgress(
        successRequirements,
        totalRequirements
      );
      if (percentage === 100) {
        return <LinearProgressFull variant="determinate" value={percentage} />;
      }
      if (percentage > 50 && percentage < 100) {
        return (
          <LinearProgressMiddle variant="determinate" value={percentage} />
        );
      }
      return <LinearProgressEmpty variant="determinate" value={percentage} />;
    },
    [handleGetProgress]
  );

  const handleGetCountRequirements = useCallback(
    ({
      section,
      rawRequirementData,
      operationType,
      operationStatus,
    }: {
      section: RequirementSectionValues;
      rawRequirementData: RequirementResponse;
      operationType: OperationTypes;
      operationStatus: XepelinStatus;
    }) => {
      const requirements = handleGetRequirementData({
        section,
        rawRequirementData,
        operationType,
        operationStatus,
      });
      const totalRequirements = requirements.length;
      const successRequirements = requirements.filter(
        ({ status }) => status === requirementStatus.SUCCESS
      ).length;

      return {
        totalRequirements,
        successRequirements,
      };
    },
    [handleGetRequirementData, requirementStatus.SUCCESS]
  );

  return {
    dictionary,
    sectionData,
    requirementStatus,
    handleGetProgress,
    handleGetLinearProgress,
    handleGetRequirementIcon,
    handleGetRequirementData,
    transformRequirementData,
    handleGetLinearProgressMulticolor,
    handleGetCountRequirements,
  };
};

export default useRequirements;
