import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress as MaterialLinearProgress } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const CustomLinearProgress = styled(MaterialLinearProgress)({
  height: 6,
  borderRadius: 20,
});

const LinearProgress = ({ value, variant }) => {
  return <CustomLinearProgress value={value} variant={variant} />;
};

LinearProgress.defaultProps = {
  variant: 'determinate',
};

LinearProgress.propTypes = {
  value: PropTypes.number.isRequired,
  variant: PropTypes.string,
};

export default LinearProgress;
