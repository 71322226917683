import axios from 'axios';
import { CONFIRMING, UNKNOWN_ERROR } from '../../../../helpers/Constants';

const getRestrictiveInformationNewSimulationService = async ({
  operationType,
  operationId,
  businessId,
}) => {
  try {
    const autoRate = {
      [CONFIRMING]: 'FIRST',
    };
    const { data } = await axios.post(
      `/api/orders/${operationId}/fees?operation=${operationType}`,
      {
        autoRate: autoRate[operationType],
        businessId,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getRestrictiveInformationNewSimulationService };
