import { Box, Typography, styled, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  spacingRightMiddle: {
    marginRight: 4,
  },
  spacingRight: {
    marginRight: 8,
  },
  spacingRight3: {
    marginRight: 24,
  },
  spacingRight4: {
    marginRight: 32,
  },
  spacingRight8: {
    marginRight: 64,
  },
  paddingRight9: {
    paddingRight: 100,
  },
  spacingBottomMiddle: {
    marginBottom: 4,
  },
  spacingBottom: {
    marginBottom: 8,
  },
  spacingBottom2: {
    marginBottom: 16,
  },
  spacingTop3: {
    marginTop: 24,
  },
  buttonShowInvoices: {
    width: 200,
    height: 32,
  },
  paddingLeft4: {
    paddingLeft: 32,
  },
  rangeChip: {
    marginTop: 2,
    marginRight: 4,
  },
  marginTop2: {
    marginTop: 2,
  },
  marginTop3: {
    marginTop: 3,
  },
  spacingVertical: {
    marginTop: 10,
    marginBottom: 40,
  },
  identifier: {
    marginTop: 2,
    marginRight: 64,
  }
});

const ContainerRoot = styled(Box)({
  padding: 32,
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: '1px solid #DAD9DB',
});
const ContainerFlex = styled(Box)({
  display: 'flex',
});
const ContainerInformation = styled(Box)({
  width: 900,
});
const ContainerInformationTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});
const TitleBold = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
});
const TitleNormal = styled(Typography)({
  fontSize: 14,
  marginTop: 5,
});
const Chip = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 98,
  height: 32,
  borderRadius: 16,
  color: '#0529F0',
  backgroundColor: 'rgba(5, 41, 240, 0.2)',
  fontSize: 12,
  fontWeight: 'bold',
});
const TextNormal = styled(Typography)({
  fontSize: 16,
});
const TextBold = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
});
const ContainerDetail = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 144,
});
const ContainerDetailTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const TextDetailTitle = styled(Typography)({
  fontSize: 14,
  color: '#828795',
});
const TextDetail = styled(Typography)({
  fontSize: 14,
  color: '#44414C',
});
const TextBlue = styled(Typography)({
  fontSize: 14,
  color: '#1A49E7',
  fontWeight: 'bold',
});
const TextRed = styled(Typography)({
  fontSize: 14,
  color: '#D92F24',
  fontWeight: 'bold',
});
const TextOrange = styled(Typography)({
  fontSize: 14,
  color: '#F87348',
  fontWeight: 700,
});
const TextButton = styled(Typography)({
  fontSize: 14,
  color: '#1A49E7',
  fontWeight: 'bold',
  cursor: 'pointer',
});

const ContainerButtonCollapse = styled(Box)({
  display: 'flex',
});
const ButtonCollapse = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  backgroundColor: '#E6F0FF',
  borderRadius: 4,
  cursor: 'pointer',
});
const ContainerButtonInvoices = styled(Box)({
  display: 'flex',
  borderLeft: '2px solid #DAD9DB',
  marginRight: 50,
});

export {
  useStyles,
  ContainerRoot,
  ContainerFlex,
  ContainerInformation,
  ContainerInformationTitle,
  TitleBold,
  TitleNormal,
  Chip,
  TextNormal,
  TextBold,
  ContainerDetail,
  ContainerDetailTitle,
  TextDetailTitle,
  TextDetail,
  TextBlue,
  TextRed,
  TextOrange,
  TextButton,
  ContainerButtonCollapse,
  ButtonCollapse,
  ContainerButtonInvoices,
};