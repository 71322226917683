import { makeStyles } from '@material-ui/core';
import { convertSpacingToCss } from '../../../../helpers/stylesHelpers';

const useStyles = makeStyles({
    paperWidthMd: {
      maxWidth: 400,
    },
    paper: {
      borderRadius: 17,
      padding: convertSpacingToCss('50px xl xl xl'),
    },
    closeButton: {
      position: 'absolute',
      top: 15,
      right: 15,
    },
    dialogTitle: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    item: {
      marginBottom: convertSpacingToCss('lg'),
      maxWidth: 330,
    },
    itemTitle: {
      marginBottom: convertSpacingToCss('lg'),
      maxWidth: 330,
      fontWeight: 'bold'
    },
    content: {
      padding: 0,
      overflowY: 'unset',
    },
    roundCorners: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
    },
    spacingAll: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '25px 30px 0px 30px',
    },
    spacingVertical5: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 0',
    },
  });

  export {
    useStyles,
  };