import React, { FC } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import CommentsPendingIcon from '../../icons/CommentsPendingIcon';
import CommentsIcon from '../../icons/CommentsIcon';

const useStyles = makeStyles({
  containerIcon: {
    marginRight: 5,
    height: 22,
  },
  orderCommentIconContainer: {
    width: 51,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 16,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
});

interface Props {
  hasPendingComments?: boolean;
  commentsCount: number;
  isLoading?: boolean;
  handleOpenDrawer: () => void;
}

const OrderCommentsIcon: FC<Props> = ({
  hasPendingComments = false,
  commentsCount,
  isLoading = false,
  handleOpenDrawer,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      {isLoading ? (
        <CircularProgress size={26} />
      ) : (
        <Tooltip title="Ver comentarios" arrow>
          <Box
            className={classes.orderCommentIconContainer}
            data-cy="orderCommentsShowCommentsButton"
            onClick={handleOpenDrawer}
          >
            <Box className={classes.containerIcon}>
              {hasPendingComments ? <CommentsPendingIcon /> : <CommentsIcon />}
            </Box>
            <Typography
              variant="body1"
              color="textPrimary"
              data-cy="orderCommentsCountComments"
            >
              {commentsCount}
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default OrderCommentsIcon;
