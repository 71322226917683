import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Button,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Can from '../Can';
import {
  BUSINESS_SUDO_PERFORM,
  BUSINESS_DETAIL_PERFORM,
  BUSINESS_SUDO_PREVIRED_PERFORM,
  BUSINESS_SUDO_TGR_PERFORM,
} from '../../helpers/performsType';
import { dateFormatter } from '../../helpers/DateUtils';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import { impersonate } from '../../actions/userActions';
import { Close, Check, Sudo } from '../icons';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import handleLoginService from '../../helpers/services';
import { COUNTRY_CODE_CL } from '../../helpers/Constants';
import { t13s } from '../../translationKeys';

const BusinessesTable = ({
  tableData,
  pagination,
  handleChangePage,
  loadingBusinesses,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { country, isExpandedHomeSideBar } = useSelector(state => state.config);

  const getIconStatus = value => {
    return (
      <Box
        color={value ? 'success.main' : 'error.main'}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {value ? <Check color="inherit" /> : <Close color="inherit" />}
      </Box>
    );
  };

  const getColumns = () => {
    const columns = [
      {
        title: 'ID',
        field: 'id',
        type: 'numeric',
        width: '76px',
        render: rowData => {
          return (
            <Can
              perform={BUSINESS_DETAIL_PERFORM}
              yes={() => (
                <Typography variant="body2" color="textPrimary">
                  <Link
                    disabled
                    component={RouterLink}
                    to={`/${country.toLowerCase()}/business/${rowData?.id}`}
                  >
                    {rowData?.id}
                  </Link>
                </Typography>
              )}
              no={() => (
                <Typography variant="body2" color="textPrimary">
                  {rowData?.id}
                </Typography>
              )}
            />
          );
        },
      },
      {
        title: 'Razón social',
        field: 'name',
        type: 'string',
        render: rowData => (
          <Typography variant="body2" color="textPrimary">
            {rowData?.name}
          </Typography>
        ),
      },
      {
        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        field: 'identifier',
        type: 'string',
        width: '150px',
        render: rowData => (
          <Typography variant="body2" noWrap color="textPrimary">
            {rowData?.identifier || '-'}
          </Typography>
        ),
      },
      {
        title: 'Nombre',
        field: 'user.name',
        type: 'string',
        render: rowData => (
          <Typography variant="body2" color="textPrimary">
            {rowData?.user?.name || '-'}
          </Typography>
        ),
      },
      {
        title: 'Email',
        field: 'user.email',
        type: 'string',
        width: '250px',
        render: rowData => (
          <Typography variant="body2" color="textPrimary">
            {rowData?.user?.email || '-'}
          </Typography>
        ),
      },
      {
        title: `${t(t13s.LABEL.TAX_SERVICE_SHORT_NAME)} Autorizado`,
        field: 'hasTaxUserActive',
        type: 'bool',
        align: 'center',
        width: '100px',
        render: rowData => getIconStatus(rowData?.hasTaxUserActive),
      },
      {
        title: 'Fecha Registro',
        field: 'createdAt',
        align: 'center',
        width: '100px',
        render: rowData => (
          <Typography variant="body2" color="textPrimary" noWrap>
            {dateFormatter(rowData?.createdAt) || '-'}
          </Typography>
        ),
      },
      {
        title: 'Acciones',
        width: '50px',
        render: rowData => (
          <Can
            perform={BUSINESS_SUDO_PERFORM}
            yes={() => (
              <Tooltip
                title={
                  rowData?.user?.id
                    ? 'Personificar'
                    : 'No se tiene información del cliente'
                }
              >
                <span>
                  <IconButton
                    onClick={() =>
                      dispatch(impersonate(rowData?.user?.id, country))
                    }
                    color="primary"
                    disabled={!rowData?.user}
                  >
                    <Sudo />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          />
        ),
      },
    ];

    if (country === COUNTRY_CODE_CL) {
      const columnsCL = [
        {
          title: 'MiPyme',
          type: 'bool',
          align: 'center',
          render: rowData => getIconStatus(!rowData?.hasMarketInvoicer),
        },
        {
          title: 'Empresa en un día',
          type: 'bool',
          align: 'center',
          render: rowData => getIconStatus(rowData?.hasIsOneDay),
        },
        {
          title: 'Certificado PFX',
          type: 'bool',
          align: 'center',
          render: rowData => getIconStatus(rowData?.hasPfxCertificate),
        },
        {
          title: 'Total deuda TGR',
          type: 'currency',
          align: 'left',
          render: rowData => (
            <Typography variant="body2" color="textPrimary" component="div">
              <CountryFormatHelper
                countryId={country}
                value={rowData?.tgrDebt ?? '-'}
                variant="currency"
              />
            </Typography>
          ),
        },
        {
          title: 'Detalles',
          align: 'center',
          render: rowData => (
            <Box display="flex">
              <Can
                perform={BUSINESS_SUDO_TGR_PERFORM}
                yes={() => (
                  <Button
                    onClick={() => handleLoginService(rowData?.tgr)}
                    disabled={!rowData?.tgr}
                    color="primary"
                    variant="text"
                  >
                    TGR
                  </Button>
                )}
              />
              <Can
                perform={BUSINESS_SUDO_PREVIRED_PERFORM}
                yes={() => (
                  <Button
                    onClick={() => handleLoginService(rowData?.previred)}
                    disabled={!rowData?.previred}
                    color="primary"
                    variant="text"
                  >
                    PREVIRED
                  </Button>
                )}
              />
            </Box>
          ),
        },
      ];
      columns.splice(6, 0, ...columnsCL);
    }

    return columns;
  };

  return (
    <MaterialTable
      columns={getColumns()}
      data={tableData}
      style={{
        overflow: 'hidden',
        boxShadow: 'none',
        width: isExpandedHomeSideBar
          ? 'calc(100vw - 177px)'
          : 'calc(100vw - 124px)',
        borderRadius: 17,
      }}
      isLoading={loadingBusinesses}
      onChangeRowsPerPage={pageSize => handleChangePage(0, pageSize)}
      components={{
        Pagination: props => (
          <PaginationMaterialTable
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            count={pagination.totalData}
            page={pagination.page - 1}
            onChangePage={(e, page) =>
              // eslint-disable-next-line react/prop-types
              handleChangePage(page, props.rowsPerPage)
            }
          />
        ),
        Toolbar: () => null,
      }}
      options={{
        emptyRowsWhenPaging: false,
        search: false,
        sorting: true,
        pageSize: pagination.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
      }}
      localization={{
        pagination: {
          labelRowsPerPage: 'Empresa por página',
          labelDisplayedRows: '{count} empresas totales',
        },
        body: {
          emptyDataSourceMessage: 'No hay empresas para mostrar',
        },
      }}
    />
  );
};

BusinessesTable.propTypes = {
  tableData: PropTypes.arrayOf(Object).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(Number).isRequired,
  loadingBusinesses: PropTypes.bool.isRequired,
};

export default BusinessesTable;
