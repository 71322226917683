import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateOrderInvoiceFundService } from '../services';

export const useUpdateOrderInvoiceFund = () => {
  const dispatch = useDispatch();
  const {
    isLoading: updateOrderInvoiceFundLoading,
    isSuccess: updateOrderInvoiceFundSuccess,
    error: updateOrderInvoiceFundError,
    mutate: updateOrderInvoiceFund,
  } = useMutation(
    ({ orderInvoiceFundId, newFundFinancing }) => {
      return updateOrderInvoiceFundService(
        orderInvoiceFundId,
        newFundFinancing
      );
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessUploadInvoiceFund } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_INVOICE_FUND_UPDATED, {
            variant: 'success',
          })
        );
        onSuccessUploadInvoiceFund();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_INVOICE_FUND_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    updateOrderInvoiceFundLoading,
    updateOrderInvoiceFundSuccess,
    updateOrderInvoiceFundError,
    updateOrderInvoiceFund,
  };
};

export default useUpdateOrderInvoiceFund;
