import { makeStyles } from '@material-ui/core';

import { BUTTON_VERTICAL_STEPPER } from '../../../../../theme/otherColors';

export const useStyles = makeStyles({
  mainWrapper: {
    overflow: 'hidden',
  },
  containerTextMainAreaBold: {
    marginRight: 20,
  },
  textMainAreaBold: {
    marginRight: 5,
  },
  buttonsContainer: {
    padding: '20px 20px 0',
  },
  cardsContainer: {
    padding: '30px 20px 0',
  },
  btnBlue: {
    background: '#1A49E7',
    color: '#fff',
    padding: '6px 34px',
    fontSize: 13,
    height: 32,
  },
  card: {
    background: '#fff',
    borderRadius: '17px',
    padding: '25px',
  },
  labelCard: {
    color: BUTTON_VERTICAL_STEPPER,
    marginBottom: '15px',
  },
});
