import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Container from './Container';
import {
  DATE_FORMAT,
  BLOCKED_FILTERS,
  VERIFICATION_MENU_FILTER,
  MORA_RANGE_FILTER,
  SEARCHBAR_TIMEOUT,
} from '../../helpers/Constants';
import { dateDBFormatter } from '../../helpers/DateUtils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gridTemplateColumns: '1fr max-content max-content max-content',
    width: 320,
    height: 34.44,
    borderRadius: 100,
    border: '1px solid #c4c4c4',
    boxSizing: 'border-box',
    background: '#fff',
    padding: 2,
    '&:hover': {
      borderColor: `${theme.palette.grey[800]}`,
    },
    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
  },
  input: {
    margin: '0 5px',
    flex: 1,
  },
  iconButton: {
    padding: 4,
    justifyContent: 'start',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  menuItem: {
    width: '190px',
    justifyContent: 'center',
  },
  menu: {
    maxHeight: 400,
  },
  textIconAlone: {
    paddingLeft: 5,
    paddingRight: 10,
  },
  datePicker: {
    marginBottom: 0,
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important',
    },
  },
}));

const SearchBar = ({
  handleChangeInput,
  placeholder,
  disabled,
  fields,
  refresh,
  selectedOption,
  size,
}) => {
  const classes = useStyles();
  const inputRef = React.createRef();
  const collectors = useSelector(state => state.collection.collectors);
  const cleanFields =
    fields?.length && Array.isArray(fields)
      ? fields.filter(e => !BLOCKED_FILTERS.includes(e.field))
      : [];
  const initFields = cleanFields.length ? cleanFields[0] : {};
  const [field, setField] = useState(initFields);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorValue, setAnchorValue] = useState(false);
  const [value, setValue] = useState(null);
  const [valueInput, setValueInput] = useState('');
  const [timer, setTimer] = useState(null);

  const searchBarWidth = {
    normal: 320,
    lg: 420,
    xl: 500,
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = '';
    setValueInput('');
    setValue(null);
    setField(selectedOption);
    // eslint-disable-next-line
  }, [refresh]);

  const clearInput = () => {
    if (inputRef.current) inputRef.current.value = '';
    setValueInput('');
    setValue(null);
    handleChangeInput({ field: field.field });
  };

  const changeInput = e => {
    const {
      target: { value },
    } = e;
    clearTimeout(timer);
    setValue(null);
    setValueInput(value);
    const targetValue = value.trim();

    setTimer(
      setTimeout(() => {
        if (targetValue !== '' || value === '') {
          handleChangeInput({
            value: targetValue,
            field: field.field,
          });
        }
      }, SEARCHBAR_TIMEOUT)
    );
  };

  const handleChangeField = value => {
    setAnchorEl(null);
    setField(value);
    clearInput();
  };

  const handleChangeDate = date => {
    setValue(date);
    setValueInput('');

    if (date.isValid()) {
      handleChangeInput({
        value: dateDBFormatter(date),
        field: field.field,
      });
    }
  };

  const handleChangeMenuItem = item => {
    setAnchorValue(null);
    setValue(item);
    handleChangeInput({
      value: item,
      field: field.field,
    });
  };

  const getMenu = (data, text) => {
    let inputValue = value || text;
    if (value && typeof value === 'object') {
      inputValue = value.label;
    }
    return (
      <Container align="start">
        <IconButton
          onClick={e => setAnchorValue(e.currentTarget)}
          className={classes.iconButton}
          aria-label={field.field}
        >
          <Typography
            color={value ? 'textPrimary' : 'textSecondary'}
            variant="caption"
            className={classes.input}
          >
            {inputValue}
          </Typography>
        </IconButton>
        <Menu
          id={field.field}
          anchorEl={anchorValue}
          open={Boolean(anchorValue)}
          onClose={() => setAnchorValue(null)}
          getContentAnchorEl={null}
          className={classes.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
        >
          {data.map(item => {
            return (
              <MenuItem
                className={classes.menuItem}
                key={typeof item === 'object' ? item.id : item}
                onClick={() => {
                  handleChangeMenuItem(item);
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  {typeof item === 'object' ? item.label : item}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Container>
    );
  };

  const getMenuCollectors = () => {
    const collectorsMaped = collectors.map(collector => ({
      label: collector.fullname,
      value: collector.id,
    }));
    return getMenu(collectorsMaped, 'Selecciona una opción');
  };

  const getInput = () => {
    if (field.type === 'date') {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <KeyboardDatePicker
            name="date"
            format={DATE_FORMAT}
            placeholder={DATE_FORMAT}
            invalidDateMessage={null}
            value={value}
            onChange={date => handleChangeDate(date)}
            autoOk
            fullWidth
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      );
    }

    switch (field.field) {
      case 'paymentConfirmed':
      case 'verificationReasons': {
        return getMenu(VERIFICATION_MENU_FILTER, 'Selecciona una opción');
      }
      case 'latePaymentRange':
        return getMenu(MORA_RANGE_FILTER, 'Selecciona un rango');
      case 'collectors': {
        return getMenuCollectors();
      }
      default: {
        let type = 'string';
        if (field.field === 'latePaymentDays') {
          type = 'number';
        }
        return (
          <InputBase
            type={type}
            disabled={disabled}
            variant="outlined"
            value={valueInput}
            className={classes.input}
            placeholder={placeholder || 'Buscar'}
            onChange={changeInput}
            inputProps={{
              'aria-label': 'Buscar',
            }}
          />
        );
      }
    }
  };

  const handleOnSubmitPaper = e => {
    e.preventDefault();
    clearTimeout(timer);
    const targetValue = valueInput.trim();
    if (handleChangeInput)
      handleChangeInput({
        value: targetValue,
        field: field.field,
      });
  };

  return (
    <Paper
      component="form"
      onSubmit={handleOnSubmitPaper}
      elevation={0}
      className={classes.root}
      style={{
        width: searchBarWidth[size],
      }}
    >
      {cleanFields.length > 1 ? (
        <Button
          onClick={e => setAnchorEl(e.currentTarget)}
          className={classes.iconButton}
          aria-label="search"
        >
          <Typography variant="subtitle2">{field.title}</Typography>
          <ExpandMoreIcon />
        </Button>
      ) : (
        <Typography variant="subtitle2" className={classes.textIconAlone}>
          {field.title}
        </Typography>
      )}

      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem />

      {cleanFields.length > 1 && (
        <Menu
          id="fields-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
        >
          {cleanFields &&
            cleanFields.map(field => (
              <MenuItem
                key={field.title}
                onClick={() => handleChangeField(field)}
              >
                <Typography variant="caption" color="textSecondary">
                  {field.title}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}

      {getInput()}

      <IconButton
        onClick={clearInput}
        className={classes.iconButton}
        aria-label="search"
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};

SearchBar.defaultProps = {
  disabled: false,
  refresh: false,
  size: 'normal',
};

SearchBar.propTypes = {
  handleChangeInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(Object).isRequired,
  refresh: PropTypes.bool,
  selectedOption: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.oneOfType(['normal', 'lg', 'xl']),
};
export default SearchBar;
