import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles(theme => ({
  separator: {
    height: 1,
    width: props => props.width,
    background: theme.palette.grey[300],
    margin: props => convertSpacingToCss(props.margin),
  },
}));
const HorizontalSeparator = props => {
  const classes = useStyles(props);
  return <div className={classes.separator} />;
};

HorizontalSeparator.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  margin: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  width: PropTypes.string,
};

HorizontalSeparator.defaultProps = {
  margin: 'zero',
  width: '100%',
};

export default HorizontalSeparator;
