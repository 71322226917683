import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { enqueueSnackbar } from '../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../translationKeys';

const useUtils = () => {
  const dispatch = useDispatch();

  const copyToClipboard = (value = null) => {
    copy(value);
    if (value) {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
          variant: 'success',
        })
      );
    } else {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }
  };

  return { copyToClipboard };
};

export default useUtils;
