import React from 'react';
import { useOrderTotals } from '../../infrastructure/store';
import { Badge } from '../../../commons/components';

const StatusFilterLabel = ({ status, selected }) => {
  const { label, key } = status;
  const { orderTotals } = useOrderTotals();
  const total = orderTotals?.find(order => order.status === key)?.total ?? '0';
  const isSelected = key === selected;
  return (
    <div>
      {label} <Badge selected={isSelected}>{total}</Badge>
    </div>
  );
};

export default StatusFilterLabel;
