import { useQuery } from 'react-query';

import { fetchOrderInvoiceFundRatesService } from '../services';

const useFetchOrderInvoiceFundRates = orderInvoiceFundId => {
  const { isLoading, data, refetch, isFetching } = useQuery(
    'getOrderInvoiceFundRates',
    () => fetchOrderInvoiceFundRatesService(orderInvoiceFundId),
    { refetchOnWindowFocus: false, enabled: !!orderInvoiceFundId }
  );

  const { data: orderInvoiceFundRates } = data || { data: [] };
  return {
    orderInvoiceFundRates,
    isLoadingOrderInvoiceFundRates: isLoading || isFetching,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchOrderInvoiceFundRates };
