import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, styled } from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';

const CustomGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.payments.error.main,
  background: theme.palette.payments.error.light,
  padding: 8,
  borderRadius: 16,
}));

const AlertMessage = ({ messageComponent, onClear }) => {
  return (
    <CustomGrid container direction="row">
      <Grid item xs={2}>
        <Cancel color="inherit" />
      </Grid>
      <Grid container item xs={9} direction="column" alignContent="flex-start">
        {messageComponent}
      </Grid>
      <Grid item xs={1}>
        <IconButton fontSize="small" onClick={onClear} color="inherit">
          <Close />
        </IconButton>
      </Grid>
    </CustomGrid>
  );
};

AlertMessage.propTypes = {
  messageComponent: PropTypes.element.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default AlertMessage;
