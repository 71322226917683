import { useState, useCallback } from 'react';

const useShowInvoiceInformation = () => {
  const [open, setOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [setInvoiceId]);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, [open]);

  const setInvoiceAndOpen = useCallback(id => {
    setInvoiceId(id);
    setOpen(true);
  });

  return {
    open,
    setOpen,
    invoiceId,
    setInvoiceId,
    toggleDrawer,
    setInvoiceAndOpen,
    closeDrawer,
  };
};

export default useShowInvoiceInformation;
