import {
  Box,
  Drawer as DrawerMui,
  Typography,
  styled,
} from '@material-ui/core';

export const Drawer = styled(DrawerMui)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});

export const ContainerRoot = styled(Box)(
  ({ containerRootWidth = 417 }: { containerRootWidth?: number }) => ({
    width: containerRootWidth,
    padding: 36,
  })
);

export const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #CDCAD8',
  marginBottom: 20,
});

export const Title = styled(Typography)({
  fontSize: 19.5,
  fontWeight: 'bold',
  color: '#44414C',
});
