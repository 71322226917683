/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDiscount } from './hooks';
import {
  useDiscountDialogData,
  useRequestDebtServiceData,
  useRequestDebtSuperData,
  useSelectedBankAccount,
  useSelectedInvoice,
  useSetShowBillDatesDialog,
  useSetShowDebtServiceDialog,
  useSetShowDebtSuperDialog,
  useSetShowDiscountFormDialog,
  useSetShowUploadDocumentDialog,
  useShowBillDatesDialog,
  useShowDebtServiceDialog,
  useShowDebtSuperDialog,
  useShowDiscountFormDialog,
  useShowEditInvoiceDialog,
  useShowFundDialog,
  useShowInvoiceStatusDialog,
  useShowRegisterAccountDialog,
  useShowUploadDocumentDialog,
  useShowFacturasBulkUpdateDialog,
  useSelectedInvoices,
  useSetShowFacturasBulkUpdateDialog,
  useSetShowFundDialog,
  useSetShowEditInvoiceDialog,
  useSetSelectedInvoice,
  useSetAnchorEl,
  useSetSelectedBankAccount,
  useSetShowRegisterAccountDialog,
  useSetShowInvoiceStatusDialog,
} from './store';
import FacturasUpdateDialog from '../../../../../components/dialogs/FacturasUpdateDialog';
import {
  ChangeInvoiceStatusDialog,
  DiscountDialog,
  FundDialog,
  BillDatesDialog,
} from './dialogs';
import DebtServiceDialog from '../../../../../components/dialogs/DebtServiceDialog';
import DebtSuperDialog from '../../../../../components/elements/DebtSuperDialog';
import EditInvoiceDialog from '../../../../../components/dialogs/EditInvoiceDialog';
import UploadFileDialog from '../../../../../components/dialogs/UploadFileDialog';

import {
  DIALOG_TYPES,
  STATUS_DEFAULT,
  STATUS_IN_CONCILIATION,
  STATUS_PAID,
} from '../../../../../helpers/Constants';
import {
  bulkUpdateFacturas,
  resetStateUpdateBulkInvoices,
  resetStateUpdateInvoice,
} from '../../../../../actions/orderActions';
import {
  consultInvoiceDebtService,
  consultInvoiceDebtSuper,
} from '../../../../../actions/debtServiceActions';

import BankAccountFormDialog from '../../../../commons/components/BankAccountFormDialog';
import {
  useAddFundFinancingToInvoice,
  useFetchActiveFunds,
  useFetchOrderInvoicesFund,
  useUpdateOrderInvoiceFund,
  useUploadOrderInvoicePdf,
  useFetchInvoiceAttachmentsInfo,
  useUpdateOrderInvoiceStatus,
  useOrder,
  useCreateBankAccount,
  useUpdateBankAccount,
  useFetchBankAccounts,
  useAssignBankAccountToInvoice,
} from '../../../infrastructure/store';
import LoaderComponent from '../../../../../components/elements/LoaderComponent';
import {
  useHideAddBankAccountDrawer,
  useIsShownAddBankAccountDrawer,
  useShowAddBankAccountDrawer,
} from '../../drawers/AddBankAccountDrawer/store';
import { BankAccountSelectorDrawer, AddBankAccountDrawer } from '../../drawers';
import {
  useHideBankAccountSelectorDrawer,
  useIsShownBankAccountSelectorDrawer,
} from '../../drawers/BankAccountSelectorDrawer/store';

const source = 'supplier';
const ORDER_TYPE = 'CONFIRMING';

const TableDialogs = ({ business, orderInvoicesFund }) => {
  const dispatch = useDispatch();
  const { id: orderId } = useParams();
  const country = useSelector(state => state.config.country);
  const { order, isLoading: orderIsLoading } = useOrder(orderId);
  const { businessId } = order;

  const {
    updatingInvoice,
    invoicesBulkWasUpdated,
    holdInvoicesWarningMessage,
  } = useSelector(state => state.order);

  const { orderInvoiceFundRates } = useSelector(state => state.funds);

  const requestDebtSuperData = useRequestDebtSuperData();
  const requestDebtServiceData = useRequestDebtServiceData();
  const showFundDialog = useShowFundDialog();
  const selectedBankAccount = useSelectedBankAccount();
  const showRegisterAccountDialog = useShowRegisterAccountDialog();
  const showDebtSuperDialog = useShowDebtSuperDialog();
  const showDebtServiceDialog = useShowDebtServiceDialog();
  const showBillDatesDialog = useShowBillDatesDialog();
  const showInvoiceStatusDialog = useShowInvoiceStatusDialog();
  const selectedInvoice = useSelectedInvoice();
  const discountDialogData = useDiscountDialogData();
  const showDiscountFormDialog = useShowDiscountFormDialog();
  const setShowDebtSuperDialog = useSetShowDebtSuperDialog();
  const setShowDebtServiceDialog = useSetShowDebtServiceDialog();
  const setShowBillDatesDialog = useSetShowBillDatesDialog();
  const setShowDiscountFormDialog = useSetShowDiscountFormDialog();
  const showEditInvoiceDialog = useShowEditInvoiceDialog();
  const showUploadDocumentDialog = useShowUploadDocumentDialog();
  const setShowUploadDocumentDialog = useSetShowUploadDocumentDialog();
  const showFacturasBulkUpdateDialog = useShowFacturasBulkUpdateDialog();
  const selectedInvoices = useSelectedInvoices();
  const setShowFacturasBulkUpdateDialog = useSetShowFacturasBulkUpdateDialog();
  const setShowFundDialog = useSetShowFundDialog();
  const setShowEditInvoiceDialog = useSetShowEditInvoiceDialog();
  const setSelectedInvoice = useSetSelectedInvoice();
  const setAnchorEl = useSetAnchorEl();
  const setSelectedBankAccount = useSetSelectedBankAccount();
  const setShowRegisterAccountDialog = useSetShowRegisterAccountDialog();
  const setShowInvoiceStatusDialog = useSetShowInvoiceStatusDialog();
  const { activeFunds } = useFetchActiveFunds();
  const { refetch: refetchOrderInvoicesFund } =
    useFetchOrderInvoicesFund(orderId);
  const { addFundFinancingToInvoice, addFundFinancingToInvoiceLoading } =
    useAddFundFinancingToInvoice();
  const { updateOrderInvoiceFund, updateOrderInvoiceFundLoading } =
    useUpdateOrderInvoiceFund();
  const { refetch: refetchInvoiceAttachmentsInfo } =
    useFetchInvoiceAttachmentsInfo(orderId);

  const {
    updateOrderInvoiceStatus,
    updateOrderInvoiceStatusIsUpdating,
    updateOrderInvoiceStatusIsError,
  } = useUpdateOrderInvoiceStatus();

  const {
    handleCreateInvoiceDiscount,
    handleUpdateInvoiceDiscount,
    handleDeleteInvoiceDiscount,
    createInvoiceDiscountLoading,
    updateInvoiceDiscountLoading,
    deleteInvoiceDiscountLoading,
  } = useDiscount();

  const {
    createBankAccount,
    resetBankAccountCreateData,
    bankAccountIsCreating,
    createBankAccountError,
  } = useCreateBankAccount();

  const {
    updateBankAccount,
    resetBankAccountUpdateData,
    bankAccountIsUpdating,
    updateBankAccountError,
  } = useUpdateBankAccount();

  const {
    uploadOrderInvoicePdf,
    uploadOrderInvoicePdfLoading,
    uploadOrderInvoicePdfError,
  } = useUploadOrderInvoicePdf();

  const isShownAddBankAccountDrawer = useIsShownAddBankAccountDrawer();
  const hideAddBankAccountDrawer = useHideAddBankAccountDrawer();

  const supplierIdentifier = selectedInvoice?.issuerIdentifier;

  const supplierName = selectedInvoice?.issuerName;

  const supplierPaymentsId = selectedInvoice?.supplierPaymentsId;

  const { refetch: refetchBusinessSupplierBankAccounts } = useFetchBankAccounts(
    'supplier',
    supplierPaymentsId
  );

  const {
    mutate: assignInvoiceBankAccount,
    isSuccess: bankAccountWasAssigned,
    isLoading: bankAccountIsAssigning,
  } = useAssignBankAccountToInvoice();

  const hideBankAccountSelectorDrawer = useHideBankAccountSelectorDrawer();
  const isShownBankAccountSelectorDrawer =
    useIsShownBankAccountSelectorDrawer();

  const showAddBankAccountDrawer = useShowAddBankAccountDrawer();

  const handleShowAddBankAccountDrawer = () => {
    showAddBankAccountDrawer();
  };

  const onSuccessUploadPDFCallback = () => {
    refetchInvoiceAttachmentsInfo();
    setShowUploadDocumentDialog(false);
  };

  const handleSubmitUploadDocument = files => {
    const { id } = selectedInvoice;
    uploadOrderInvoicePdf({
      invoiceId: id,
      pdf: files[0]?.file,
      onSuccessUploadPDFCallback,
    });
  };

  const handleCloseInvoiceStatusDialog = () => {
    setShowInvoiceStatusDialog(false);
  };

  const handleSubmitStatusInvoiceDialog = ({
    status,
    statusReason,
    paymentDate,
    fundPaymentDate,
    defaultDate,
    oldStatus,
  }) => {
    const { id } = selectedInvoice;

    const statusData = {
      [STATUS_PAID]: { fundPaymentDate },
      [STATUS_IN_CONCILIATION]: { paymentDate },
      [STATUS_DEFAULT]: { defaultDate },
    };

    const invoiceData = {
      ...selectedInvoice,
      status,
      statusReason,
      oldStatus,
      ...(statusData[status] ?? {}),
    };

    updateOrderInvoiceStatus({
      id,
      invoiceData,
      onSuccessCallback: handleCloseInvoiceStatusDialog,
    });
  };

  const handleCloseBankAccountDialog = () => {
    setSelectedInvoice(undefined);
    setSelectedBankAccount(null);
    setShowRegisterAccountDialog(false);
    resetBankAccountCreateData();
    resetBankAccountUpdateData();
  };

  const handleSubmitBankAccountDialog = ({
    bankId,
    accountType,
    accountNumber,
    accountHolder,
    sourceIdentifier,
    accountEmail,
    alias,
    currency,
  }) => {
    const bankAccountDataObj = {
      bankId,
      accountType,
      accountNumber,
      accountHolder,
      sourceIdentifier,
      accountEmail,
      alias,
      currency,
    };

    const { issuerBusinessId: sourceId } = selectedInvoice;

    if (selectedBankAccount?.id) {
      updateBankAccount({
        source,
        sourceId,
        bankAccount: bankAccountDataObj,
        bankAccountId: selectedBankAccount.id,
      });
    } else {
      createBankAccount({
        source,
        sourceId,
        payerBusinessId: businessId,
        bankAccount: bankAccountDataObj,
      });
    }
  };

  const handleCloseEditDialog = () => {
    setShowEditInvoiceDialog(false);
    setShowBillDatesDialog(false);
    setSelectedInvoice(undefined);
    setAnchorEl(null);
    dispatch(resetStateUpdateInvoice());
  };

  const handleSubmitDebtSuperDialog = () => {
    const { identifier } = requestDebtSuperData;
    dispatch(consultInvoiceDebtSuper(identifier));
    setShowDebtSuperDialog(false);
  };

  const handleSubmitDebtServiceDialog = () => {
    const { identifier } = requestDebtServiceData;
    dispatch(consultInvoiceDebtService(business.id, 'business', identifier));
    setShowDebtServiceDialog(false);
  };

  const getValidProperty = (value, defaultValue) => {
    return value !== null ? value : defaultValue;
  };

  const mapInvoice = (invoice, data = {}) => {
    const invoiceData = { ...data };
    Object.keys(invoice).forEach(key => {
      if (key === 'tasaBase') {
        invoiceData[key] = getValidProperty(data.baseRate, invoice[key]);
      } else {
        invoiceData[key] = getValidProperty(data[key], invoice[key]);
      }
    });
    const {
      status,
      statusReason,
      fechaVencimiento,
      paymentDate = null,
      paymentConfirmed = null,
      fundExpirationDate,
      verificationStatus,
      verificationReason,
      expirationDate,
      baseRate,
      defaultDate,
    } = invoiceData;
    return {
      ...invoice,
      defaultDate,
      status,
      statusReason,
      fechaVencimiento,
      paymentDate,
      paymentConfirmed,
      fundExpirationDate,
      verificationStatus,
      verificationReason,
      expirationDate,
      baseRate,
    };
  };

  const onSuccessUploadInvoiceFund = () => {
    refetchOrderInvoicesFund();
  };

  const updateInvoiceFund = (orderInvoiceFundId, newFundFinancing) => {
    if (!orderInvoiceFundId)
      return addFundFinancingToInvoice({
        ...newFundFinancing,
        onSuccessUploadInvoiceFund,
      });
    updateOrderInvoiceFund({
      orderInvoiceFundId,
      newFundFinancing,
      onSuccessUploadInvoiceFund,
    });
  };

  const handleSubmitFundFinancing = (
    fundFinancing,
    orderInvoiceFundId = null
  ) => {
    const newFundFinancing = {
      ...fundFinancing,
      operationType: ORDER_TYPE,
      orderInvoiceId: selectedInvoice?.id,
    };
    setShowFundDialog(false);
    if (selectedInvoice)
      return updateInvoiceFund(orderInvoiceFundId, newFundFinancing);
  };

  const getOrderInvoiceFund = id => {
    const orderInvoice = orderInvoicesFund.find(
      oif => oif.orderInvoiceId === id
    );
    return orderInvoice && { ...orderInvoice, orderType: ORDER_TYPE };
  };

  const handleCloseBulkUpdateDialog = () => {
    dispatch(resetStateUpdateBulkInvoices());
    setShowFacturasBulkUpdateDialog(false);
  };

  const handleSubmitBulkDialog = ({ item: invoices, data }) => {
    const orderFacturas = invoices.map(factura => mapInvoice(factura, data));
    const [{ businessId }] = invoices;
    dispatch(
      bulkUpdateFacturas({ orderId, businessId, orderFacturas }, country)
    );
  };

  const handleSubmitAddBankAccount = bankAccountData => {
    createBankAccount({
      source: 'supplier',
      sourceId: businessId, // no se usa, need refactor
      payerBusinessId: businessId,
      supplierIdentifier,
      bankAccount: bankAccountData,
      onSuccessCallback: () => {
        refetchBusinessSupplierBankAccounts();
        hideAddBankAccountDrawer();
      },
    });
  };

  const handleAssignSupplierBankAccount = bankAccountId => {
    assignInvoiceBankAccount({
      invoiceId: selectedInvoice?.invoiceId,
      businessId,
      bankAccountId,
    });
  };

  useEffect(() => {
    if (bankAccountWasAssigned) hideBankAccountSelectorDrawer();
  }, [bankAccountWasAssigned]);

  useEffect(() => {
    if (invoicesBulkWasUpdated && !holdInvoicesWarningMessage) {
      handleCloseBulkUpdateDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesBulkWasUpdated, holdInvoicesWarningMessage]);

  return (
    <>
      {showFacturasBulkUpdateDialog && (
        <FacturasUpdateDialog
          open={showFacturasBulkUpdateDialog}
          handleCloseDialog={handleCloseBulkUpdateDialog}
          type={DIALOG_TYPES.FACTURASBULK}
          handleSubmit={handleSubmitBulkDialog}
          invoices={selectedInvoices}
          isLoading={updatingInvoice}
          warning={holdInvoicesWarningMessage}
          wasUpdated={invoicesBulkWasUpdated}
          statusOrder={order.status}
          showBaseRateInput
        />
      )}
      {showFundDialog && (
        <FundDialog
          linkFundData={getOrderInvoiceFund(selectedInvoice?.id)}
          operationType={ORDER_TYPE}
          linkFundType="ORDER_INVOICE"
          linkFundRates={orderInvoiceFundRates}
          open={showFundDialog}
          handleCloseDialog={() => setShowFundDialog(false)}
          handleSubmit={handleSubmitFundFinancing}
          funds={activeFunds}
        />
      )}
      {showDebtServiceDialog && (
        <DebtServiceDialog
          open={showDebtServiceDialog}
          handleCloseDialog={() => setShowDebtServiceDialog(false)}
          handleSubmit={handleSubmitDebtServiceDialog}
          debtServiceData={requestDebtServiceData}
        />
      )}
      {showDebtSuperDialog && (
        <DebtSuperDialog
          open={showDebtSuperDialog}
          handleCloseDialog={() => setShowDebtSuperDialog(false)}
          handleSubmit={handleSubmitDebtSuperDialog}
          debtSuperData={requestDebtSuperData}
        />
      )}
      {showEditInvoiceDialog && (
        <EditInvoiceDialog
          open={showEditInvoiceDialog}
          invoice={selectedInvoice}
          handleCloseDialog={handleCloseEditDialog}
        />
      )}
      {showRegisterAccountDialog && (
        <BankAccountFormDialog
          open={showRegisterAccountDialog}
          bankAccount={selectedBankAccount}
          handleSubmit={handleSubmitBankAccountDialog}
          handleClose={handleCloseBankAccountDialog}
          isLoading={bankAccountIsCreating || bankAccountIsUpdating}
          error={createBankAccountError || updateBankAccountError}
        />
      )}
      {isShownAddBankAccountDrawer && (
        <AddBankAccountDrawer
          open={isShownAddBankAccountDrawer}
          handleClose={hideAddBankAccountDrawer}
          supplierName={supplierName}
          supplierIdentifier={supplierIdentifier}
          handleSubmit={handleSubmitAddBankAccount}
          bankAccountIsCreating={bankAccountIsCreating}
        />
      )}
      {isShownBankAccountSelectorDrawer && (
        <BankAccountSelectorDrawer
          open={isShownBankAccountSelectorDrawer}
          handleClose={hideBankAccountSelectorDrawer}
          businessId={businessId}
          supplierIdentifier={selectedInvoice?.issuerIdentifier}
          assignedBankAccountId={selectedInvoice?.assignedBankAccountId}
          supplierPaymentsId={selectedInvoice?.supplierPaymentsId}
          handleSubmit={handleAssignSupplierBankAccount}
          bankAccountIsAssigning={bankAccountIsAssigning}
          handleShowAddBankAccountDrawer={handleShowAddBankAccountDrawer}
        />
      )}
      {showInvoiceStatusDialog && (
        <ChangeInvoiceStatusDialog
          open={showInvoiceStatusDialog}
          invoice={selectedInvoice}
          title="Cambiar estado de la factura"
          error={updateOrderInvoiceStatusIsError}
          isLoading={updateOrderInvoiceStatusIsUpdating || orderIsLoading}
          handleCloseDialog={handleCloseInvoiceStatusDialog}
          handleSubmit={handleSubmitStatusInvoiceDialog}
        />
      )}
      {showDiscountFormDialog && (
        <DiscountDialog
          open={showDiscountFormDialog}
          data={discountDialogData}
          handleCloseDialog={() => setShowDiscountFormDialog(false)}
          title="Descuento a factura"
          subtitle="Ingrese un monto de descuento, confirme y guarde"
          sourceType="orderinvoice"
          sourceId={selectedInvoice.id}
          orderId={orderId}
          isLoading={
            createInvoiceDiscountLoading ||
            updateInvoiceDiscountLoading ||
            deleteInvoiceDiscountLoading
          }
          handleCreateInvoiceDiscount={handleCreateInvoiceDiscount}
          handleUpdateInvoiceDiscount={handleUpdateInvoiceDiscount}
          handleDeleteInvoiceDiscount={handleDeleteInvoiceDiscount}
        />
      )}
      {showUploadDocumentDialog && (
        <UploadFileDialog
          open={showUploadDocumentDialog}
          handleCloseDialog={() => setShowUploadDocumentDialog(false)}
          title="Subir factura"
          description="Al subir un documento nuevo reemplazarás el ya existente"
          handleSubmit={handleSubmitUploadDocument}
          isLoading={uploadOrderInvoicePdfLoading}
          accept=".pdf,.xml"
          error={uploadOrderInvoicePdfError}
        />
      )}
      {showBillDatesDialog && (
        <BillDatesDialog
          open={showBillDatesDialog}
          handleCloseDialog={() => setShowBillDatesDialog(false)}
          selectedInvoice={selectedInvoice}
        />
      )}
      {(addFundFinancingToInvoiceLoading || updateOrderInvoiceFundLoading) && (
        <LoaderComponent
          open={
            addFundFinancingToInvoiceLoading || updateOrderInvoiceFundLoading
          }
        />
      )}
    </>
  );
};

TableDialogs.propTypes = {
  business: PropTypes.objectOf(Object).isRequired,
  orderInvoicesFund: PropTypes.arrayOf(Object).isRequired,
};

export default TableDialogs;
