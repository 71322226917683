/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { COUNTRY_CODE_CL } from '../../helpers/Constants';

const NumberFormatCustom = props => {
  const { inputRef, onChange } = props;
  const { country } = useSelector(state => state.config);

  return country !== COUNTRY_CODE_CL ? (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator="."
      thousandSeparator=","
      decimalScale={2}
      prefix="$"
    />
  ) : (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      prefix="$"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default NumberFormatCustom;
