/* eslint-disable no-undef */
import React from 'react';
import { Skeleton as SkeletonMui, SkeletonProps } from '@material-ui/lab';

interface Props extends SkeletonProps {
  isLoading?: boolean;
  children: JSX.Element[] | JSX.Element;
}

const Skeleton = ({ isLoading = false, children, ...rest }: Props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <>{isLoading ? <SkeletonMui {...rest} /> : children}</>
  );
};

export default Skeleton;
