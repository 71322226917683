import { styled, Box, Grid, Link, Button } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { Text } from '../../../../commons/formatters';

export const SubtitleBox = styled(Box)({
  marginTop: 4,
  marginBottom: 26,
});

export const SubtitleText = styled(Text)({
  fontSize: 14,
});

export const CopyToClipboardButton = styled(Link)({
  display: 'flex',
  justifyContent: 'right',
  fontSize: 16,
  cursor: 'pointer',
  marginTop: 24,
  marginBottom: 24,
  alignItems: 'center',
});

export const CopyText = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.primary.main,
  textDecoration: 'underline',
}));

export const CopyIcon = styled(FileCopyOutlined)({
  fontSize: 14,
  marginLeft: 10,
});

export const FirstGrid = styled(Grid)({
  padding: '18px 0px',
  display: 'flex',
});

export const BorderTopGrid = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.payments.grey5}`,
  padding: '18px 0px',
  display: 'flex',
}));

export const LastGrid = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.payments.grey5}`,
  borderBottom: `1px solid ${theme.palette.payments.grey5}`,
  padding: '18px 0px',
  display: 'flex',
}));

export const ItemGrid = styled(Grid)({
  flex: 2,
  textOverflow: 'ellipsis',
  display: 'flex',
  justifyContent: 'end',
});

export const TitleGrid = styled(Grid)({
  flex: 1,
  textOverflow: 'ellipsis',
});

export const EditButton = styled(Button)({
  width: '100%',
  height: 40,
  fontSize: 14,
});

export const AmountText = styled(Text)({
  fontSize: 20,
});

export const SecondaryText = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.secondary,
}));
