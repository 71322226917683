import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_TRANSFERRED,
  STATUS_PAID,
} from '../../helpers/Constants';
import CountryFormatHelper from './CountryFormatHelper';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles(theme => ({
  TableCellHeader: {
    color: theme.palette.grey[800],
  },
  paddingRight0: {
    paddingRight: 0,
  },
  TableCellBody: {
    color: theme.palette.grey[700],
  },
}));

const FacturasSimpleTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const facturas = useSelector(state => state.order.facturas);
  const country = useSelector(state => state.config.country);
  const statusTransform = status => {
    switch (status) {
      case STATUS_IN_REVIEW:
        return 'En revisión';

      case STATUS_APPROVED:
        return 'Aprobado';

      case STATUS_REJECTED:
        return 'Rechazado';

      case STATUS_TRANSFERRED:
        return 'Transferido';

      case STATUS_PAID:
        return 'Pagado';

      default:
        return status;
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">Folio</Box>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">Nombre pagador</Box>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">
                {t(t13s.LABEL.BUSINESS_IDENTIFIER)} pagador
              </Box>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">Fecha de emisión</Box>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">Fecha de pago</Box>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">Estado</Box>
            </Typography>
          </TableCell>
          <TableCell className={classes.paddingRight0}>
            <Typography
              variant="subtitle2"
              component="div"
              className={classes.TableCellHeader}
            >
              <Box fontWeight="fontWeightBold">Monto total</Box>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {facturas.map(factura => {
          const {
            mntTotal,
            amount,
            folio,
            fechaEmision,
            issueDate,
            expirationDate,
            fechaVencimiento,
            status,
            payerIdentifier,
            payerName,
          } = factura;
          const total = mntTotal || amount || 0;
          return (
            <TableRow key={folio} className={classes.tableBody}>
              <TableCell>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">{folio}</Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">{payerName}</Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">{payerIdentifier}</Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">
                    <CountryFormatHelper
                      value={fechaEmision || issueDate}
                      variant="longdate"
                      countryId={country}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">
                    <CountryFormatHelper
                      value={fechaVencimiento || expirationDate}
                      variant="longdate"
                      countryId={country}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">
                    {statusTransform(status)}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell className={classes.paddingRight0}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.TableCellBody}
                >
                  <Box fontWeight="fontWeightBold">
                    <CountryFormatHelper
                      value={total}
                      variant="currency"
                      countryId={country}
                    />
                  </Box>
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default FacturasSimpleTable;
