import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from '../actions/notificationAction';
import { t13s } from '../translationKeys';
import { uploadBankMovementsDocService } from '../actions/bankMovementsActions';

export const useBankMovements = () => {
  const dispatch = useDispatch();
  const {
    isLoading: areBankMovementsLoading,
    data: bankMovementsData,
    error: bankMovementsError,
    mutate: bankMovementsUploadFile,
    reset: bankMovementsResetDataAndErrors,
  } = useMutation(
    csvFile => {
      return uploadBankMovementsDocService('RSM', csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.UPLOAD_BANK_MOVEMENTS_SUCCESS, {
              variant: 'success',
            })
          );
        }
      },
    }
  );

  const {
    isLoading: isInterbankInquiryLoading,
    data: interbankInquiryData,
    error: interbankInquiryError,
    mutate: interbankInquiryUploadFile,
    reset: interbankInquiryResetDataAndErrors,
  } = useMutation(
    csvFile => {
      return uploadBankMovementsDocService('CIB', csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(
              t13s.NOTIFICATION.UPLOAD_INTERBANK_INQUIRY_SUCCESS,
              {
                variant: 'success',
              }
            )
          );
        }
      },
    }
  );

  const {
    isLoading: isSameBankComplementaryInfoLoading,
    data: sameBankComplementaryInfoData,
    error: sameBankComplementaryInfoError,
    mutate: sameBankComplementaryInfoUploadFile,
    reset: sameBankComplementaryInfoResetDataAndErrors,
  } = useMutation(
    csvFile => {
      return uploadBankMovementsDocService('CMB', csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(
              t13s.NOTIFICATION.UPLOAD_SAME_BANK_COMPLEMENTARY_INFO_SUCCESS,
              {
                variant: 'success',
              }
            )
          );
        }
      },
    }
  );

  return {
    areBankMovementsLoading,
    bankMovementsErrorLines: bankMovementsData?.errorLines,
    bankMovementsServerError: bankMovementsError || null,
    bankMovementsUploadFile,
    bankMovementsResetDataAndErrors,

    isSameBankComplementaryInfoLoading,
    sameBankComplementaryInfoErrorLines:
      sameBankComplementaryInfoData?.errorLines,
    sameBankComplementaryInfoServerError:
      sameBankComplementaryInfoError || null,
    sameBankComplementaryInfoUploadFile,
    sameBankComplementaryInfoResetDataAndErrors,

    isInterbankInquiryLoading,
    interbankInquiryErrorLines: interbankInquiryData?.errorLines,
    interbankInquiryServerError: interbankInquiryError || null,
    interbankInquiryUploadFile,
    interbankInquiryResetDataAndErrors,
  };
};

export default useBankMovements;
