import * as CommonUtils from '../../utils';

export const disableWeekends = momentDate => {
  // eslint-disable-next-line no-underscore-dangle
  return momentDate._d.getDay() === 0 || momentDate._d.getDay() === 6;
};

export const daysToAddByHolidays = dateTime => {
  if (CommonUtils.isSaturday(dateTime)) {
    return 2;
  }
  if (CommonUtils.isSunday(dateTime)) {
    return 1;
  }

  return 0;
};
