import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OperationList } from '../../../commons/interfaces/ARInterfaces';
import OperationCard from './OperationCard';
import Loader from '../../../../components/elements/Loader';

interface Props {
  operations: OperationList[];
  fetchOperationIsLoading: boolean;
}

const OperationCards: FC<Props> = ({ operations, fetchOperationIsLoading }) => {
  if (fetchOperationIsLoading) return <Loader />;

  return (
    <Box>
      {operations?.map((operation: OperationList) => (
        <OperationCard operation={operation} key={operation?.id} />
      ))}
      {operations?.length === 0 && !fetchOperationIsLoading && (
        <Box display="flex" justifyContent="center" my={5}>
          <Typography align="center" variant="h6" color="textPrimary">
            No tenemos operaciones para mostrar
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default OperationCards;
