import * as React from 'react';
import { styled } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import { Icon } from '../../schemas';

const CustomCheckCircle = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));
const CustomDangerIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
}));
const CustomErrorIcon = styled(CancelIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export type CreditLineIconProps = {
  typeIcon: Icon;
};

const CreditLineIcon = ({ typeIcon }: CreditLineIconProps) => {
  switch (typeIcon) {
    case 'error': return <CustomErrorIcon fontSize="large" />;
    case 'checked': return <CustomCheckCircle fontSize="large" />;
    default: return <CustomDangerIcon fontSize="large" />;
  }
};

export default CreditLineIcon;
