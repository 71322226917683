import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import BusinessDetailCL from './BusinessDetailCL';
import BusinessDetailMX from './BusinessDetailMX';
import TableBusinessUsers from './tables/TableBusinessUsers';
import { features } from '../config/features';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../helpers/Constants';
import { CONTEXT_TYPES } from '../helpers/userLogsUtils';
import Panel from './elements/Panel';

const useStyles = makeStyles({
  containerRoot: {
    padding: convertSpacingToCss('lg'),
  },
  infoBusiness: {
    paddingBottom: 20,
  },
});

const BusinessInformation = ({
  id,
  setStateTab,
  business,
  handleHistoryLogDialog,
}) => {
  const classes = useStyles();
  const { country } = useSelector(state => state.config);
  const countryFeatures = features[country];

  return (
    <Grid container className={classes.containerRoot}>
      <Grid item xs={12} className={classes.infoBusiness}>
        <Panel
          title="Información de la empresa"
          variant="section"
          titlePadding="lg lg sm lg"
          contentPadding="sm"
          actions={
            countryFeatures.businessDetailsSection.showHistoryLogsButton && (
              <Tooltip title="Historial de cambios">
                <Button
                  className={{ marginLeft: 10 }}
                  onClick={() =>
                    handleHistoryLogDialog(id, CONTEXT_TYPES.business)
                  }
                  startIcon={<HistoryIcon />}
                  variant="text"
                  color="primary"
                >
                  Historial de la empresa
                </Button>
              </Tooltip>
            )
          }
        >
          {country === COUNTRY_CODE_CL && (
            <BusinessDetailCL
              business={business}
              handleTgrClick={() => setStateTab('tgragreements')}
            />
          )}

          {country === COUNTRY_CODE_MX && (
            <BusinessDetailMX business={business} />
          )}
        </Panel>
      </Grid>

      {countryFeatures.businessUserInfo && (
        <TableBusinessUsers businessId={id} />
      )}
    </Grid>
  );
};

BusinessInformation.propTypes = {
  id: PropTypes.string.isRequired,
  setStateTab: PropTypes.func.isRequired,
  business: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  handleHistoryLogDialog: PropTypes.func.isRequired,
};

export default BusinessInformation;
