import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MoveTo = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V6H9.17L10.58 7.41L11.17 8H20V18ZM12.16 12H8V14H12.16L10.57 15.59L11.99 17L16 13.01L11.99 9L10.58 10.41L12.16 12Z" />
    </SvgIcon>
  );
};

export default MoveTo;
