import { Box, styled } from '@material-ui/core';

const ContainerPagination = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 0',
});

export default ContainerPagination;
