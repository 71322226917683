import { useQuery } from 'react-query';

import { fetchActiveFundsService } from '../services';

const useFetchActiveFunds = () => {
  const { isLoading, data, refetch } = useQuery(
    'getActiveFunds',
    () => fetchActiveFundsService(),
    { refetchOnWindowFocus: false }
  );

  const { data: activeFunds } = data || { data: [] };
  return {
    activeFunds,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchActiveFunds };
