import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Filter = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M9.77778 19H14.2222V16.8333H9.77778V19ZM2 6V8.16667H22V6H2ZM5.33333 13.5833H18.6667V11.4167H5.33333V13.5833Z" />
    </SvgIcon>
  );
};

export default Filter;
