import { useTranslation } from 'react-i18next';
import {
  DebtManagementSubTypes,
  DebtManagementTypes,
  PayerCategorizationTypes,
} from '../../../../../commons/interfaces/managements';
import { t13s } from '../../../../../../translationKeys';

const LABELS = t13s.LABEL.COLLECTIONS.MANAGEMENTS_SUBTYPES;

export const useGetManagementSubtypeLabel = () => {
  const { t } = useTranslation();
  const getSubtypeLabel = (props: {
    type?: DebtManagementTypes | PayerCategorizationTypes;
    subtype?: DebtManagementSubTypes;
  }): string => {
    const { type, subtype } = props;
    if (type && !subtype) {
      const isCategorizationType = type in PayerCategorizationTypes;
      return isCategorizationType ? t(LABELS.CATEGORIZATION) : '';
    }
    const translationKey = LABELS[subtype as unknown as keyof Object];
    return translationKey ? t(`${translationKey}`) : subtype || '';
  };

  return { getSubtypeLabel };
};
