import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const AddItemIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      style={style}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7705 1.89626L10.478 0.60376L6.27051 4.80209L2.06301 0.603759L0.770508 1.89626L6.27051 7.39626L11.7705 1.89626Z"
        fill="#1A49E7"
      />
    </CustomSvgIcon>
  );
};

AddItemIcon.defaultProps = {
  style: {},
};

AddItemIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default AddItemIcon;
