import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';

import { t13s } from '../../../../translationKeys';
import { fetchOrderInvoicesFundsService } from '../services';

const useFetchOrderInvoicesFunds = orderId => {
  const dispatch = useDispatch();
  const {
    data,
    isLoading: orderInvoicesFundsIsLoading,
    refetch,
  } = useQuery(
    'getOrderInvoicesFunds',
    () => fetchOrderInvoicesFundsService(orderId),
    { refetchOnWindowFocus: false },
    {
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.ERROR.FETCH_ORDER_INVOICES_FUNDS, {
            variant: 'error',
          })
        );
      },
    }
  );

  const { data: orderInvoicesFunds } = data || { data: [] };

  return { orderInvoicesFunds, orderInvoicesFundsIsLoading, refetch };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchOrderInvoicesFunds };
