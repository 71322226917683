import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Grid, Box, Tooltip, Badge } from '@material-ui/core';
import {
  CONFIRMING,
  COUNTRY_CODE_CL,
  ORDER_TYPES_LABEL,
  STATUS_APPROVED,
  STATUS_IN_REVIEW,
} from '../../../../../../helpers/Constants';

import emptyBrowserIcon from '../../../../../../assets/icons/emptyBrowserIcon.svg';

import SectionHeader from '../../../../../../components/elements/SectionHeader';
import Panel from '../../../../../../components/elements/Panel';
import SearchBar from '../../../../../../components/elements/SearchBar';
import OrdersTableMinified from '../../../../../../components/tables/OrdersTableMinified';
import { t13s } from '../../../../../../translationKeys';
import { MoveTo } from '../../../../../../components/icons';
import {
  useFetchOrders,
  useOrderSummary,
} from '../../../../infrastructure/store';
import useStyles from './styles';
import InvoiceOperationSummary from './InvoiceOperationSummary';
import { STATUSES_PAYMENT } from '../../../../../commons/constants/status';

const orderStatus = [
  ...STATUSES_PAYMENT.order
    .filter(
      status =>
        status.group === STATUS_IN_REVIEW || status.group === STATUS_APPROVED
    )
    .map(status => status.key),
];

const MoveInvoiceDrawer = ({
  show,
  country,
  selectedInvoices,
  manageSelectedInvoices,
  manageOrderInvoicesIsLoading,
  order,
}) => {
  const classes = useStyles();

  const { id: orderId } = useParams();

  const {
    businessId,
    businessName,
    businessIdentifier,
    totalOrdersTransferred,
  } = order;

  const [search, setSearch] = useState(null);
  const [field, setField] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [invoicesData, setInvoicesData] = useState({});
  const [selectedRowID, setSelectedRowID] = useState(undefined);

  const location = useLocation();
  const { t } = useTranslation();

  const handleChangePage = (page, limit) => {
    setLimit(limit);
    setPage(page);
  };

  const changeSearchInput = ({ value, field }) => {
    setField(field);
    setSearch(value);
    setPage(1);
  };

  const handleChangeRowSelection = row => {
    if (row?.id) setSelectedRowID(row.id);
    setSelectedOrder(row);
  };

  const { orderSummary, isLoading: orderSummaryIsLoading } =
    useOrderSummary(selectedRowID);

  const { baseRate } = orderSummary?.operation ?? {};

  const getOrderFilters = () => {
    const filter = [
      {
        field: 'status',
        value: orderStatus,
      },
      {
        field: 'businessId',
        value: businessId,
        cmp: '=',
      },
      {
        field: 'Order.id',
        value: `(${orderId})`,
        cmp: 'NOT IN',
      },
    ];
    const filteredIdentifier = new URLSearchParams(location.search).get(
      'filteredIdentifier'
    );
    if (filteredIdentifier) {
      filter.push({
        field: country === COUNTRY_CODE_CL ? 'rut' : 'identifier',
        value: filteredIdentifier,
      });
    }
    if (search) {
      filter.push({
        field,
        value: search,
      });
    }

    return filter;
  };

  const {
    data: orders,
    refetch: fetchOrders,
    isLoading: ordersLoading,
  } = useFetchOrders({
    operation: CONFIRMING,
    page,
    limit,
    filters: getOrderFilters(),
  });

  const searcher = () => {
    fetchOrders();
  };

  const initialiceDefault = () => {
    setPage(1);
    searcher();
    let invoicesAmount = 0;
    const invoices = [];
    const invoicesSize = selectedInvoices.length;

    if (invoicesSize) {
      invoicesAmount = selectedInvoices.reduce(
        (a, b) => a + (parseFloat(b.amount) || 0),
        0
      );
      selectedInvoices.forEach(
        ({ id, baseRate: invoiceBaseRate, expirationDate }) => {
          invoices.push({
            id,
            baseRate: invoiceBaseRate || baseRate,
            expirationDate,
          });
        }
      );
    }
    setInvoicesData({
      invoicesAmount,
      invoices,
      invoicesSize,
    });
  };

  const searchOptions = [
    {
      title: 'Order ID',
      field: 'Order.id',
    },
    { title: 'Razon Social', field: 'name' },
    {
      title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
      field: country === 'CL' ? 'rut' : 'identifier',
    },
    {
      title: 'Solicitud',
      field: 'Order.createdAt',
    },
  ];

  useEffect(() => {
    if (show) searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, limit]);

  useEffect(() => {
    setFilteredData(orders?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (show) {
      initialiceDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <SectionHeader
        padding="lg"
        title={businessName}
        transparentBg
        mainArea={
          <Grid container xs={12} direction="row">
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">
                  {country === COUNTRY_CODE_CL ? 'RUT:' : 'Identifier:'}
                </Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {businessIdentifier}
              </Typography>
            </Grid>

            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">Tipo de operación:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {ORDER_TYPES_LABEL[CONFIRMING]}
              </Typography>
            </Grid>
          </Grid>
        }
        preTitleAction={
          <Tooltip title="Operaciones Transferidas">
            <Badge
              classes={
                totalOrdersTransferred[0]?.count < 2
                  ? { badge: classes.firstOrderBadge }
                  : ''
              }
              badgeContent={totalOrdersTransferred[0]?.count}
              color="primary"
              invisible={false}
            />
          </Tooltip>
        }
        breadCrumbs={[]}
        showBreadCrumbs={false}
      />
      <Grid container item className={classes.bottomContainer} spacing={1}>
        <Grid item xs={8}>
          {orders && (
            <Panel
              title="Lista de Operaciones"
              titlePadding="lg lg zero"
              contentPadding="zero"
              actions={
                <Grid item xs={12} container spacing={1} justify="flex-end">
                  <Grid item>
                    <SearchBar
                      placeholder="Buscar operaciones..."
                      handleChangeInput={value => changeSearchInput(value)}
                      selectedOption={searchOptions[0]}
                      fields={searchOptions}
                    />
                  </Grid>
                </Grid>
              }
            >
              <Grid container item xs={12}>
                <OrdersTableMinified
                  handleChangeRowSelection={handleChangeRowSelection}
                  ordersData={filteredData}
                  ordersLoading={ordersLoading}
                  handleChangePage={handleChangePage}
                  pagination={orders?.pagination}
                  singleSelect
                />
              </Grid>
            </Panel>
          )}
        </Grid>
        <Grid item xs={3}>
          {selectedOrder ? (
            <InvoiceOperationSummary
              order={selectedOrder}
              orderSummary={orderSummary}
              orderSummaryIsLoading={orderSummaryIsLoading}
              invoicesData={invoicesData}
              manageSelectedInvoices={() =>
                manageSelectedInvoices(selectedOrder.id)
              }
              buttonText="Mover Facturas"
              buttonIsLoading={manageOrderInvoicesIsLoading}
              action="move"
              buttonIcon={<MoveTo />}
            />
          ) : (
            <Grid className={classes.emptyResumeContainer}>
              <img
                src={emptyBrowserIcon}
                className={classes.emptyResumeIcon}
                alt="Top"
              />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
                noWrap
              >
                Selecciona una operación para ver la simulación aquí.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

MoveInvoiceDrawer.defaultProps = {
  show: false,
  manageOrderInvoicesIsLoading: false,
};

MoveInvoiceDrawer.propTypes = {
  country: PropTypes.string.isRequired,
  show: PropTypes.bool,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      invoiceId: PropTypes.number.isRequired,
      baseRate: PropTypes.number.isRequired,
      expirationDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  manageSelectedInvoices: PropTypes.func.isRequired,
  order: PropTypes.shape({
    businessName: PropTypes.string.isRequired,
    businessId: PropTypes.number.isRequired,
    businessIdentifier: PropTypes.number.isRequired,
    totalOrdersTransferred: PropTypes.shape({ count: PropTypes.number }),
  }).isRequired,
  manageOrderInvoicesIsLoading: PropTypes.bool,
};

export default MoveInvoiceDrawer;
