import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';

import { addOrderCommentService } from '../services';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';

const useAddOrderComment = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: addOrderComment,
  } = useMutation(
    ({ orderId, commentDataToCreate }) =>
      addOrderCommentService(orderId, commentDataToCreate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getComments'], { exact: true });
        queryClient.invalidateQueries(['getCommentsCounter'], { exact: true });

        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_COMMENT_CREATED, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CREATE_ORDER_COMMENT_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    addOrderComment,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useAddOrderComment };
