import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { unlockOrderService } from '../services';
import { t13s } from '../../../../translationKeys';

export const useUnlockOrder = () => {
  const dispatch = useDispatch();
  const { mutate: unlockOrder, isLoading: isUnlockingOrder } = useMutation(
    params => {
      return unlockOrderService(params);
    },
    {
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.ERROR.ERROR_UNLOCK_ORDER, {
            variant: 'error',
          })
        );
      },
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  return {
    unlockOrder,
    isUnlockingOrder,
  };
};

export default useUnlockOrder;
