import React, { useState, useCallback, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const DEFAULT_DEBOUNCE = 500;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gridTemplateColumns: '1fr max-content max-content max-content',
    width: 320,
    height: 34.44,
    borderRadius: 100,
    border: '1px solid #c4c4c4',
    boxSizing: 'border-box',
    background: '#fff',
    padding: 2,
    '&:hover': {
      borderColor: `${theme.palette.grey[800]}`,
    },
    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
  },
  input: {
    margin: '0 5px',
    flex: 1,
  },
  iconButton: {
    padding: 4,
    justifyContent: 'start',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  menuItem: {
    width: '190px',
    justifyContent: 'center',
  },
  menu: {
    maxHeight: 400,
  },
  textIconAlone: {
    paddingLeft: 5,
    paddingRight: 10,
  },
  datePicker: {
    marginBottom: 0,
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important',
    },
  },
}));

const SearchFilter = ({
  handleChangeInput,
  placeholder,
  disabled,
  options,
  selectValue,
  inputValue,
  debounceTime,
}) => {
  const classes = useStyles();
  const inputRef = useRef();

  const [anchorEl, setAnchorEl] = useState(false);

  const clearInputComponent = () => {
    if (inputRef.current) inputRef.current.value = '';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce(handleChangeInput, debounceTime ?? DEFAULT_DEBOUNCE),
    []
  );

  const changeInput = event => {
    const { value: inputText } = event.target;

    debouncedHandler({
      select: selectValue,
      input: inputText,
    });
  };

  const handleClearInputField = () => {
    clearInputComponent();

    handleChangeInput({
      select: selectValue,
      input: '',
    });
  };

  const handleChangeField = field => {
    clearInputComponent();
    setAnchorEl(null);

    handleChangeInput({ select: field, input: '' });
  };

  // const handleOnSubmitPaper = e => {
  //   e.preventDefault();
  //   clearTimeout(timer);
  //   const targetValue = valueInput.trim();
  //   if (handleChangeInput)
  //     handleChangeInput({
  //       value: targetValue,
  //       field: field.field,
  //     });
  // };

  return (
    <Paper elevation={0} className={classes.root}>
      {options.length > 1 ? (
        <Button
          onClick={e => setAnchorEl(e.currentTarget)}
          className={classes.iconButton}
          aria-label="search"
        >
          <Typography variant="subtitle2">{selectValue?.title}</Typography>
          <ExpandMoreIcon />
        </Button>
      ) : (
        <Typography variant="subtitle2" className={classes.textIconAlone}>
          {selectValue?.title}
        </Typography>
      )}

      {options.length > 1 && (
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
        >
          {options &&
            options.map(field => (
              <MenuItem
                key={field.title}
                onClick={() => handleChangeField(field)}
              >
                <Typography variant="caption" color="textSecondary">
                  {field.title}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}

      <InputBase
        type="string"
        disabled={disabled}
        variant="outlined"
        className={classes.input}
        placeholder={placeholder || 'Buscar'}
        onChange={changeInput}
        startAdornment={
          <InputAdornment>
            <SearchIcon color="action" />
          </InputAdornment>
        }
        InputProps={{
          'aria-label': 'Buscar',
        }}
        inputRef={inputRef}
        defaultValue={inputValue}
      />

      {inputValue && (
        <IconButton
          onClick={handleClearInputField}
          className={classes.iconButton}
          aria-label="search"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
};

SearchFilter.defaultProps = {
  disabled: false,
  inputValue: '',
  debounceTime: undefined,
};

SearchFilter.propTypes = {
  handleChangeInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(Object).isRequired,
  selectValue: PropTypes.shape({
    title: PropTypes.string,
    field: PropTypes.string,
  }).isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debounceTime: PropTypes.number,
};
export default SearchFilter;
