import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { LOGOUT_USER } from '../actions/types';
import agreementReducer from './agreementReducer';
import authReducer from './authReducer';
import bankReducer from './bankReducer';
import businessReducer from './businessReducer';
import cessionReducer from './cessionReducer';
import configReducer from './configReducer';
import creditsReducer from './creditsReducer';
import linkFundReducer from './linkFundReducer';
import debtServiceReducer from './debtServiceReducer';
import documentsReducer from './documentsReducer';
import economicGroupReducer from './economicGroupReducer';
import feesReducer from './feesReducer';
import fundsReducer from './fundsReducer';
import fundInvoicesReducer from './fundInvoicesReducer';
import geoReducer from './geoReducer';
import invoiceReducer from './invoiceReducer';
import logsReducer from './logsReducer';
import notificationReducer from './notificationReducer';
import orderReducer from './orderReducer';
import payerBlocklistReducer from './payerBlocklistReducer';
import payerReducer from './payerReducer';
import rubroReducer from './rubroReducer';
import stakeholdersReducer from './stakeholdersReducer';
import taxReducer from './taxReducer';
import uiStateReducer from './uiStateReducer';
import userReducer from './userReducer';
import walletReducer from './walletReducer';
import websocketReducer from './websocketReducer';
import ratesReducer from './ratesReducer';
import collectionReducer from './collectionReducer';
import responsiblesReducer from './responsiblesReducer';
import reasonsReducer from './reasonsReducer';
import commentsReducer from './commentsReducer';
import contactsReducer from './contactsReducer';
import emailReducer from './emailReducer';
import entityFeatureReducer from './entityFeatureReducer';
import taxfolderReducer from './taxfolderReducer';
import partialPaymentsReducer from './partialPaymentsReducer';
import { fundsModuleReducer } from '../funds/adapters/store';
import paymentOrderDetailReducer from './paymentOrderDetailReducer';
import holidaysReducer from './holidaysReducer';
import discountReducer from './discountReducer';
import reportReducer from './reportReducer';

const siteReducer = combineReducers({
  ...fundsModuleReducer(),
  auth: authReducer,
  business: businessReducer,
  documents: documentsReducer,
  stakeholders: stakeholdersReducer,
  order: orderReducer,
  cession: cessionReducer,
  user: userReducer,
  agreement: agreementReducer,
  ws: websocketReducer,
  funds: fundsReducer,
  fundInvoices: fundInvoicesReducer,
  payerBlocklist: payerBlocklistReducer,
  payer: payerReducer,
  rubro: rubroReducer,
  economicGroup: economicGroupReducer,
  banks: bankReducer,
  credits: creditsReducer,
  linkFund: linkFundReducer,
  invoice: invoiceReducer,
  fees: feesReducer,
  collection: collectionReducer,
  notification: notificationReducer,
  logs: logsReducer,
  tax: taxReducer,
  wallet: walletReducer,
  config: configReducer,
  uiState: uiStateReducer,
  debtService: debtServiceReducer,
  geo: geoReducer,
  rates: ratesReducer,
  responsibles: responsiblesReducer,
  reasons: reasonsReducer,
  comments: commentsReducer,
  contacts: contactsReducer,
  email: emailReducer,
  entityFeature: entityFeatureReducer,
  taxfolder: taxfolderReducer,
  partialPayments: partialPaymentsReducer,
  paymentOrderDetails: paymentOrderDetailReducer,
  holidays: holidaysReducer,
  discount: discountReducer,
  report: reportReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_USER) {
    // eslint-disable-next-line no-param-reassign
    state = {};
  }
  return siteReducer(state, action);
};
export type RootState = StateType<typeof siteReducer>;

export default rootReducer;
