import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AccountBalanceOutlined } from '@material-ui/icons';
import { EyeOpen, Edit } from '../../../../../components/icons';

const itemText = {
  marginLeft: 10,
};

const ActionsMenu = ({
  rowData,
  handleShowBankAccountDrawer,
  handleShowEditBankAccountDrawer,
  handleShowBankAccountSelectorDrawer,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      key: 'ver',
      element: (
        <>
          <EyeOpen />
          <span style={itemText}>Ver detalle</span>
        </>
      ),
      action: () => {
        setAnchorEl(null);
        handleShowBankAccountDrawer(rowData);
      },
    },
    {
      key: 'editar',
      element: (
        <>
          <Edit />
          <span style={itemText}>Editar cuenta</span>
        </>
      ),
      action: () => {
        setAnchorEl(null);
        handleShowEditBankAccountDrawer(rowData);
      },
    },
    {
      key: 'assign',
      element: (
        <>
          <AccountBalanceOutlined />
          <span style={itemText}>Ver cuentas</span>
        </>
      ),
      action: () => {
        setAnchorEl(null);
        handleShowBankAccountSelectorDrawer(rowData);
      },
    },
    /* {
      key: 'eliminar',
      element: (
        <>
          <Delete />
          <span style={itemText}>Eliminar cuenta</span>
        </>
      ),
      action: () => {
        setAnchorEl(null);
      },
    }, */
  ];

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option.key} onClick={option.action}>
            {option.element}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ActionsMenu.propTypes = {
  rowData: PropTypes.shape({
    accountHolder: PropTypes.string,
    accountNumber: PropTypes.string,
    amount: PropTypes.number,
    bankName: PropTypes.string,
    countryId: PropTypes.string,
    identifier: PropTypes.string,
    alias: PropTypes.string,
    accountEmail: PropTypes.string,
    tableData: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired,
  handleShowBankAccountDrawer: PropTypes.func.isRequired,
  handleShowEditBankAccountDrawer: PropTypes.func.isRequired,
  handleShowBankAccountSelectorDrawer: PropTypes.func.isRequired,
};

export default ActionsMenu;
