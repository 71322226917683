import React from 'react';
import PropTypes from 'prop-types';
import { Link, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useUtils from '../hooks/useUtils';
import { STATUS_REPLACED } from '../../../../../../helpers/Constants';
import styles from '../styles';

const Folio = ({ rowData }) => {
  const history = useHistory();
  const classes = styles;
  const { copyToClipboard } = useUtils();
  const { identifier, folio, status, Relationships } = rowData;
  const { country } = useSelector(state => state.config);

  const handleRedirectToOrderById = () => {
    const { orderId } = Relationships;
    const path = orderId
      ? `/${country.toLowerCase()}/orders/${orderId}`
      : `/${country.toLowerCase()}`;
    history.push(path);
    // if (orderId) this.handleHasParamId(orderId);
  };

  if (status === STATUS_REPLACED && Relationships) {
    return (
      <Link
        onClick={() => handleRedirectToOrderById()}
        className={classes.replacedFolio}
      >
        {folio}
      </Link>
    );
  }

  return (
    <Tooltip title="Copiar Folio Fiscal">
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          copyToClipboard(identifier);
        }}
      >
        {folio}
      </Link>
    </Tooltip>
  );
};

Folio.propTypes = {
  rowData: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    folio: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    Relationships: PropTypes.shape({
      orderId: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Folio;
