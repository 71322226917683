import axios from 'axios';
import { OrderInvoice } from '../../interfaces';

const fetchCriticalOrderInvoicesInformation = async (
  orderInvoiceIds: number[]
) => {
  try {
    const { data } = await axios.get<OrderInvoice[]>(
      '/api/business/criticalOrderInvoicesInformation',
      {
        params: {
          orderInvoiceIds,
        },
      }
    );

    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw new Error(err?.response?.data?.message);
    } else {
      throw new Error('Unexpected error');
    }
  }
};

export default fetchCriticalOrderInvoicesInformation;
