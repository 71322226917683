import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { HistoryOutlined, Input } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import {
  CustomIconButton,
  CustomArrowBackIcon,
  IconButtonGrid,
  PayerNameGrid,
  SudoIconButton,
  CustomGridItem,
  CustomGridContainer,
  TitleTypography,
  SubtitleTypography,
} from './styles';
import { impersonate } from '../../../../../actions/userActions';
import { usePayrollHeader } from '../../../hooks';
import HistoryLogsDialog from '../../../../../components/dialogs/HistoryLogsDialog';
import { COUNTRY_CODE_CL } from '../../../../../helpers/Constants';

const SummaryTitle = ({
  businessName,
  issuedDate,
  daysPassed,
  orderId,
  agreement,
  sudoUser,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showHistoryDialog,
    handleShowHistoryDialog,
    handleCloseHistoryDialog,
  } = usePayrollHeader();
  const { country } = useSelector(state => state.config);

  const lowerCaseCountry = country.toLowerCase();
  const formatedIssuedDate =
    issuedDate && format(new Date(issuedDate), 'dd-MM-yyyy');

  const handleSudo = () => {
    dispatch(impersonate(sudoUser.id, country));
  };

  return (
    <>
      <Grid container>
        <IconButtonGrid item>
          <CustomIconButton
            onClick={() => history.push(`/${lowerCaseCountry}/payments`)}
          >
            <CustomArrowBackIcon />
          </CustomIconButton>
        </IconButtonGrid>
        <Grid item>
          <Grid container direction="column">
            <CustomGridContainer container item xs={12}>
              <PayerNameGrid item>
                <TitleTypography componente="div">
                  {businessName}
                </TitleTypography>
              </PayerNameGrid>
              <IconButtonGrid item>
                <SudoIconButton onClick={handleSudo} disabled={!sudoUser}>
                  <Input color={sudoUser ? 'primary' : 'disabled'} />
                </SudoIconButton>
                <IconButton onClick={handleShowHistoryDialog}>
                  <HistoryOutlined color="primary" />
                </IconButton>
              </IconButtonGrid>
            </CustomGridContainer>
            <Grid item container xs={12}>
              <CustomGridItem item>
                <SubtitleTypography>
                  Fecha solicitud: {formatedIssuedDate}
                </SubtitleTypography>
              </CustomGridItem>
              <CustomGridItem item>
                <SubtitleTypography>
                  Días transcurrridos: {daysPassed}
                </SubtitleTypography>
              </CustomGridItem>
              {country === COUNTRY_CODE_CL && agreement && (
                <Grid item>
                  <SubtitleTypography>Convenio: {agreement}</SubtitleTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showHistoryDialog && (
        <HistoryLogsDialog
          open={showHistoryDialog}
          handleCloseDialog={handleCloseHistoryDialog}
          contextId={orderId}
          contextType="ORDER"
        />
      )}
    </>
  );
};

SummaryTitle.defaultProps = {
  agreement: undefined,
  sudoUser: undefined,
};

SummaryTitle.propTypes = {
  businessName: PropTypes.string.isRequired,
  issuedDate: PropTypes.string.isRequired,
  daysPassed: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
  agreement: PropTypes.string,
  sudoUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default SummaryTitle;
