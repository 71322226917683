import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fetchBusinessByIdentifier,
  fetchBusinessPayers,
  fetchBusinessInvoices,
  fetchNotEnrolledBusinessInvoices,
  fetchBusinessName,
  calculateBusinessFees,
  calculateFeesBusinessNotEnrolled,
  fetchPayersBusinessNotEnrolled,
  resetBusiness,
} from '../../actions/businessAction';
import {
  ORDER_TYPES_LABEL,
  CONFIRMING,
  NONE,
  NORMAL,
  FORCED,
  DIRECT_FINANCING,
  EARLY_PAYMENT,
} from '../../helpers/Constants';
import BaseDialog from './BaseDialog';
import OperationUploadSteps from '../elements/OperationUploadSteps';
import {
  resetInvoicesCsvToOrder,
  uploadInvoiceCsvDirectFinance,
} from '../../actions/invoiceAction';
import useGetCountry from '../../hooks/useGetCountry';

const CreateOrderDialog = ({
  open,
  operation,
  handleCloseDialog,
  handleSubmit,
  isLoading,
  submitError,
}) => {
  const dispatch = useDispatch();
  const country = useGetCountry();
  const {
    fetchBusinessInvoicesLoading,
    business,
    calculateBusinessFeesLoading,
  } = useSelector(state => state.business);
  const { issuingBusinesses, invoices: invoicesCsv } = useSelector(
    state => state.invoice
  );

  const [operationType, setOperationType] = useState();
  const [isDirectFinanceByCsv, setIsDirectFinanceByCsv] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);

  useEffect(() => {
    dispatch(resetInvoicesCsvToOrder());
    dispatch(resetBusiness());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientByIdentifier = (operationType, identifier) => {
    dispatch(resetBusiness());
    dispatch(fetchBusinessByIdentifier(identifier, operationType));
  };

  const onClientSelect = (operationType, clientId) => {
    dispatch(fetchBusinessPayers(operationType, clientId));
  };

  const onSelectPayer = (clientSelectedId, payerIdentifier) => {
    dispatch(fetchBusinessInvoices(clientSelectedId, payerIdentifier));
  };

  const onSelectPayerNotEnrolled = (clientIdentifier, payerIdentifier) => {
    dispatch(
      fetchNotEnrolledBusinessInvoices(clientIdentifier, payerIdentifier)
    );
  };

  const handleClientNotEnrolled = (operationType, identifier) => {
    if ([DIRECT_FINANCING, EARLY_PAYMENT].includes(operationType)) {
      dispatch(fetchPayersBusinessNotEnrolled(operationType, identifier));
    }
    dispatch(fetchBusinessName(identifier));
  };

  const transformInvoices = invoices => {
    return invoices.map(
      ({
        id: invoiceId,
        expirationDate,
        amount,
        InvoiceStakeholder: { identifier = null } = {},
        payerIdentifier,
      }) => ({
        invoiceId,
        expirationDate,
        amount,
        payerIdentifier: identifier || payerIdentifier,
      })
    );
  };

  const transformInvoicesNotEnrolled = invoices => {
    return invoices.map(invoice => {
      return {
        amount: invoice.amount,
        expirationDate: invoice.expirationDate,
      };
    });
  };

  const onCalculateFees = (
    orderType,
    clientSelectedId,
    invoices,
    availableInvoicesCount,
    availableInvoicesAmount,
    feesData,
    autoRate,
    payerIdentifier
  ) => {
    const isBusinessEnrolled = business?.taxUserActive;

    if (!clientSelectedId || orderType === CONFIRMING || !isBusinessEnrolled) {
      dispatch(
        calculateFeesBusinessNotEnrolled(
          orderType,
          transformInvoicesNotEnrolled(invoices),
          feesData,
          clientSelectedId
        )
      );
    } else {
      dispatch(
        calculateBusinessFees(
          clientSelectedId,
          invoices,
          transformInvoices(invoices),
          availableInvoicesCount,
          availableInvoicesAmount,
          feesData,
          orderType,
          autoRate,
          payerIdentifier
        )
      );
    }
  };

  const handleNextStep =
    (formFees, handleFeesCalculate, isDirectFinanceByCsv) => step => {
      if (step === 1 && isDirectFinanceByCsv) {
        handleFeesCalculate(formFees, true);
      }
      if (step === 2 && !isDirectFinanceByCsv) {
        handleFeesCalculate(formFees, true);
      }
      return true;
    };

  const handleValidateNextStep =
    (
      hidePayersInput,
      invoices,
      selectedInvoices,
      clientSelected,
      isDirectFinanceByCsv
    ) =>
    step => {
      if (step === 0) {
        if (
          operationType === CONFIRMING &&
          clientSelected?.taxUserActive &&
          !clientSelected?.hasConfirming
        ) {
          return false;
        }
        if (hidePayersInput) {
          return clientSelected !== '' && clientSelected?.name;
        }
        if (isDirectFinanceByCsv && clientSelected) {
          return true;
        }
        setIsLastStep(false);
        return invoices?.length > 0;
      }
      if (step === 1 && !isDirectFinanceByCsv) {
        setIsLastStep(false);
        return selectedInvoices?.length > 0 || invoicesCsv?.length > 0;
      }
      if (step === 1 && isDirectFinanceByCsv) {
        setIsLastStep(false);
        return !isLoading;
      }
      if (step === 2) {
        setIsLastStep(true);
        return !isLoading && !calculateBusinessFeesLoading;
      }
      return false;
    };

  const mapInvoicesToCreateOperation = orderInvoices => {
    const newInvoices = [];
    const registeredInvoices = [];
    orderInvoices.forEach(orderInvoice => {
      if (orderInvoice.mustBeRegistered) {
        newInvoices.push(orderInvoice);
      } else {
        const [transformedInvoice] = transformInvoices([orderInvoice]);
        delete transformedInvoice.amount;
        delete transformedInvoice.payerIdentifier;
        registeredInvoices.push(transformedInvoice);
      }
    });
    return { newInvoices, orderInvoices: registeredInvoices };
  };

  const handleSubmitOperation =
    (
      fees,
      selectedInvoices,
      formFees,
      clientSelected,
      currency,
      exchangeRate,
      availableInvoicesCount,
      availableInvoicesAmount,
      autoRate = false
    ) =>
    () => {
      const feesData = {
        retentionPct: fees.retentionPct,
        baseRate: fees.baseRate,
        issuedDate: fees.issuedDate,
        ...(operationType !== CONFIRMING && {
          expirationDate: formFees.expirationDate,
        }),
        operationCost: formFees.operationCost,
      };

      let autoRateType = NONE;
      if (autoRate) {
        switch (operationType) {
          case DIRECT_FINANCING:
            autoRateType = FORCED;
            break;
          case EARLY_PAYMENT:
            autoRateType = NORMAL;
            break;
          default:
            autoRateType = NONE;
            break;
        }
      }

      const { newInvoices, orderInvoices } =
        mapInvoicesToCreateOperation(selectedInvoices);

      handleSubmit(
        operationType,
        {
          clientSelected,
          rawOrderInvoices: selectedInvoices.length
            ? selectedInvoices
            : invoicesCsv,
          orderInvoices,
          newInvoices,
          feesData,
          businesses: issuingBusinesses,
        },
        fees,
        availableInvoicesCount,
        availableInvoicesAmount,
        autoRateType,
        currency,
        exchangeRate
      );
    };

  const directFinanceTemplate = {
    CL: {
      payerName: 'Lorem',
      payerIdentifier: '12345678-9',
      folio: '54321',
      issuedDate: '20-05-2021',
      expirationDate: '12-10-2021',
      amount: 150000,
      docType: 33,
    },
    MX: {
      payerIdentifier: 'XEPE1901011XX',
      payerName: 'Lorem',
      uuid: '4D802629-D7B4-4C1C-919C-B417B91C9CC4',
      amount: 150000,
      issuedDate: '20-05-2021',
      expirationDate: '12-10-2021',
    },
  };

  const onDropDirectFinanceCsv = (
    files,
    setCsvErrors,
    orderType,
    clientSelected
  ) => {
    if (!files) {
      setCsvErrors('Debe seleccionar un archivo antes');
      return;
    }
    const file = files?.length ? files[0].file : [];
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      if (!fileData.includes(',')) {
        setCsvErrors(`Delimitador erroneo. Delimitador esperado: "," (coma).`);
        return;
      }
      dispatch(uploadInvoiceCsvDirectFinance(file, orderType, clientSelected));
    };
    reader.readAsBinaryString(file);
  };

  const hanleCloseDialogCallback = () => {
    handleCloseDialog();
    dispatch(resetInvoicesCsvToOrder());
    dispatch(resetBusiness());
  };

  const handleStepBack = step => {
    if (step >= 2) {
      dispatch(resetInvoicesCsvToOrder());
    }
  };

  const handleResetCsvToOrder = () => {
    dispatch(resetInvoicesCsvToOrder());
  };

  const steps = isDirectFinanceByCsv
    ? ['Información general', 'Condiciones y resumen']
    : ['Información general', 'Selección de facturas', 'Condiciones y resumen'];

  return (
    <BaseDialog
      isOpen={open}
      size="lg"
      handleClose={hanleCloseDialogCallback}
      title={`Nueva Operación de ${ORDER_TYPES_LABEL[operationType]}`}
    >
      <OperationUploadSteps
        steps={steps}
        isLastStep={isLastStep}
        handleSubmit={handleSubmitOperation}
        handleNextStep={handleNextStep}
        handleValidateNextStep={handleValidateNextStep}
        isLoadingButton={
          fetchBusinessInvoicesLoading ||
          isLoading ||
          calculateBusinessFeesLoading
        }
        buttonLabelFinish="Crear operación"
        onDropDirectFinanceCsv={onDropDirectFinanceCsv}
        onClientSelect={onClientSelect}
        onSelectPayer={onSelectPayer}
        onSelectPayerNotEnrolled={onSelectPayerNotEnrolled}
        CsvDirectFinanceTemplate={[directFinanceTemplate[country]]}
        onCalculateFees={onCalculateFees}
        operation={operation}
        submitError={submitError}
        operationType={operationType}
        setOperationType={setOperationType}
        isDirectFinanceByCsv={isDirectFinanceByCsv}
        setIsDirectFinanceByCsv={setIsDirectFinanceByCsv}
        handleStepBack={handleStepBack}
        fetchClientByIdentifier={fetchClientByIdentifier}
        handleClientNotEnrolled={handleClientNotEnrolled}
        handleResetCsvToOrder={handleResetCsvToOrder}
      />
    </BaseDialog>
  );
};

CreateOrderDialog.defaultProps = {
  submitError: undefined,
};

CreateOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  operation: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitError: PropTypes.string,
};

export default CreateOrderDialog;
