import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import AlertForm from '../elements/AlertForm';
import {
  GLOBAL_REASONS,
  STATUS_APPEALED,
  STATUS_REJECTED,
} from '../../helpers/Constants';
import { checkStatusAuth } from '../../helpers/validation/auth';
import useStatuses from '../../hooks/useStatuses';

const useStyles = makeStyles({
  selectInput: {
    marginBottom: 10,
  },
});

const ChangeStatusOrderDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  statusOrder,
  statusReason,
  title,
  error,
  isLoading,
  warning,
  isHold,
}) => {
  const classes = useStyles();
  const { rules } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { getStatuses } = useStatuses();

  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState(statusOrder);
  const [showStatusReason, setShowStatusReason] = useState(false);
  const [statusReasonSelected, setStatusReasonSelected] =
    useState(statusReason);

  const handleShowStatusReason = status => {
    const showSelector = status === STATUS_APPEALED;
    setShowStatusReason(showSelector);
  };

  useEffect(() => {
    handleShowStatusReason(statusOrder);
  }, []);

  const handleSelectStatus = status => {
    setStatusSelected(status);
    if ([STATUS_REJECTED, STATUS_APPEALED].includes(status)) {
      setShowStatusReason(true);
    } else {
      setShowStatusReason(false);
    }
  };

  const notAvailableHoldStatuses = ['ACTIVE', 'TO_DEPOSIT'];

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      data-cy="changeStatusOrderDialogContainer"
    >
      <ValidatorForm
        onSubmit={() => handleSubmit(statusSelected, statusReasonSelected)}
      >
        <Grid container>
          <Grid item xs={12} className={classes.selectInput}>
            <SelectValidator
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={e => handleSelectStatus(e.target.value)}
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
              data-cy="changeStatusOrderDialogSelectInput"
            >
              {getStatuses('order').map(({ key, label }) => (
                <MenuItem
                  key={key}
                  value={key}
                  data-cy={`changeStatusOrderDialogInputValue_${key}`}
                  disabled={
                    !checkStatusAuth(key, 'ORDER', rules) ||
                    key === 'RENEGOTIATION' ||
                    (isHold && notAvailableHoldStatuses.includes(key))
                  }
                >
                  {isHold && notAvailableHoldStatuses.includes(key) ? (
                    <Typography variant="body2" color="textPrimary">
                      {label}{' '}
                      <span style={{ fontStyle: 'italic' }}>
                        (orden seleccionada con facturas temporales)
                      </span>
                    </Typography>
                  ) : (
                    <span>{label}</span>
                  )}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>

          {showStatusReason && (
            <Grid item xs={12} className={classes.selectInput}>
              <SelectValidator
                fullWidth
                variant="outlined"
                name="operationReason"
                id="operation-reason-select"
                value={statusReasonSelected}
                onChange={e => setStatusReasonSelected(e.target.value)}
                validators={['required']}
                errorMessages={['Campo requerido']}
                label="Razón"
              >
                {GLOBAL_REASONS.filter(
                  reason =>
                    reason.type === `INVOICE_${statusSelected}` &&
                    reason.country.includes(country)
                ).map(reason => (
                  <MenuItem key={reason.value} value={reason.value}>
                    {reason.label}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}

          {!isLoading && error && (
            <Box mb={3}>
              <AlertForm variant="error" message={error} />
            </Box>
          )}

          {!isLoading && warning && (
            <Box mb={3}>
              <AlertForm variant="info" messageComponent={warning} />
            </Box>
          )}

          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeStatusOrderDialog.defaultProps = {
  statusOrder: null,
  statusReason: null,
  error: null,
  warning: null,
  isHold: null,
};

ChangeStatusOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
  warning: PropTypes.string,
  statusOrder: PropTypes.string,
  statusReason: PropTypes.string,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isHold: PropTypes.bool,
};

export default ChangeStatusOrderDialog;
