import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { cessionActionService } from '../services';

const useCessionAction = (selectedInvoices, cessionaryId, businessId) => {
  const dispatch = useDispatch();
  const {
    isSuccess: cessionSuccess,
    mutate: makeCession,
    isError: requestCessionError,
    isLoading: requestingCession,
  } = useMutation(
    () => cessionActionService(selectedInvoices, cessionaryId, businessId),
    {
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_CESSIONARIES_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    cessionSuccess,
    requestCessionError,
    requestingCession,
    makeCession,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useCessionAction };
