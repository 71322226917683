import React, { useEffect, useState } from 'react';
import { Box, Grid, MenuItem, styled } from '@material-ui/core';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BaseDrawer } from '../../../../commons/components';
import { Add } from '../../../../../components/icons';
import { Text } from '../../../../commons/formatters';
import AlertForm from '../../../../../components/elements/AlertForm';
import { ButtonGrid } from '../../PayrollHeader/styles';
import {
  ACCOUNT_TYPES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../../../helpers/Constants';
import { t13s } from '../../../../../translationKeys';
import { useFetchBanks } from '../../../infrastructure/store';
import { settings } from '../../../../../config/settings';
import { validateIdentifier } from '../../../../../helpers/validation/businessIdentifier';
import LoadingButton from '../../../../../components/elements/LoadingButton';
import useRoles from '../../../../commons/hooks/useRoles';

const DescriptionText = styled(Text)({
  marginBottom: 16,
});

const AddAccountButton = styled(LoadingButton)({
  color: 'white',
  background: '#1A49E7',
  width: '100%',
  fontSize: 14,
  padding: '10px 0',
  '&:hover': {
    background: '#1A49E7',
  },
});

const AddBankAccountDrawer = ({
  open,
  handleClose,
  supplierName,
  supplierIdentifier,
  handleSubmit,
  bankAccountIsCreating,
}) => {
  const { banks } = useFetchBanks();
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { isAdmin, isTreasurySupervisor } = useRoles();
  const canEditIdentifierAndName = isAdmin || isTreasurySupervisor;

  const initialState = {
    bankId: '',
    accountEmail: '',
    alias: '',
    accountNumber: '',
    accountHolder: supplierName,
    source: 'supplier',
    sourceIdentifier: supplierIdentifier,
    accountType: ACCOUNT_TYPES[country][0].value,
    currency: settings[country].currencyCode,
  };

  const [bankAccountData, setBankAccountData] = useState(initialState);

  const currencies = {
    CL: [
      {
        value: settings[country].currencyCode,
        label: settings[country].currencyCode,
      },
      { value: 'USD', label: 'USD' },
    ],
    MX: [
      {
        value: settings[country].currencyCode,
        label: settings[country].currencyCode,
      },
      { value: 'USD', label: 'USD' },
    ],
  };

  const error = false;

  const handleInputChange = (field, value) => {
    let finalValue = value;
    if (field === 'accountNumber') {
      finalValue = value.replace(/[^0-9]/g, '');
    }

    setBankAccountData({
      ...bankAccountData,
      [field]: finalValue,
    });
  };

  const bankSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Banco"
      name="bankId"
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      value={bankAccountData?.bankId}
    >
      {banks?.map(bank => (
        <MenuItem value={bank.id} key={bank.name}>
          {bank.name}
        </MenuItem>
      ))}
    </SelectValidator>
  );

  const accountTypeSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Tipo de cuenta"
      name="accountType"
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      value={bankAccountData?.accountType}
    >
      {ACCOUNT_TYPES[country].map(type => {
        return <MenuItem value={type.value}>{type.label}</MenuItem>;
      })}
    </SelectValidator>
  );

  const accountNumberField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BANK_ACCOUNT_IDENTIFIER)}
      type="text"
      name="accountNumber"
      validators={['required', 'validateAccountNumber']}
      errorMessages={['Campo requerido', t(t13s.INPUT_ERROR.ACCOUNT_NUMBER)]}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      value={bankAccountData?.accountNumber}
    />
  );

  const sourceIdentifierField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
      type="text"
      name="sourceIdentifier"
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['validateIdentifier']}
      errorMessages={[t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER)]}
      disabled={!canEditIdentifierAndName}
      value={bankAccountData?.sourceIdentifier}
    />
  );

  const accountHolderField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label="Nombre o razón social"
      type="text"
      name="accountHolder"
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      disabled={!canEditIdentifierAndName}
      value={bankAccountData?.accountHolder}
    />
  );

  const currencySelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Divisa"
      type="text"
      name="currency"
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      value={bankAccountData?.currency}
    >
      {currencies[country].map(currency => (
        <MenuItem value={currency.value}>{currency.label}</MenuItem>
      ))}
    </SelectValidator>
  );

  useEffect(() => {
    ValidatorForm.addValidationRule('validateIdentifier', value => {
      return validateIdentifier(value, country);
    });

    ValidatorForm.addValidationRule('validateAccountNumber', value => {
      if (country === COUNTRY_CODE_CL) return true;
      const regex = /^([0-9]{18})$/;
      return regex.test(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BaseDrawer
        anchor="right"
        title="Agregar cuenta"
        titleIcon={<Add />}
        open={open}
        handleClose={handleClose}
        width={486}
      >
        <Box>
          <DescriptionText fontWeight="regular" varian="subtitle1">
            Ingresa los datos de la nueva cuenta. Recuerda que debe estar
            asociada al RUT registrado del proveedor.
          </DescriptionText>

          <ValidatorForm onSubmit={() => handleSubmit(bankAccountData)}>
            {country === COUNTRY_CODE_CL && (
              <>
                {bankSelectField}
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={9}>
                    {accountTypeSelectField}
                  </Grid>
                  <Grid item xs={3}>
                    {currencySelectField}
                  </Grid>
                </Grid>
                {accountNumberField}
                {sourceIdentifierField}
                {accountHolderField}
              </>
            )}

            {country === COUNTRY_CODE_MX && (
              <>
                {bankSelectField}
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={9}>
                    {accountTypeSelectField}
                  </Grid>
                  <Grid item xs={3}>
                    {currencySelectField}
                  </Grid>
                </Grid>
                {accountNumberField}
                {accountHolderField}
                {sourceIdentifierField}
              </>
            )}
            <TextValidator
              fullWidth
              variant="outlined"
              label="Email"
              name="accountEmail"
              onChange={e => handleInputChange(e.target.name, e.target.value)}
              validators={['required', 'isEmail']}
              errorMessages={['Campo requerido', 'Email incorrecto']}
              value={bankAccountData?.accountEmail}
            />
            <TextValidator
              fullWidth
              variant="outlined"
              label="Alias (opcional)"
              type="text"
              name="alias"
              onChange={e => handleInputChange(e.target.name, e.target.value)}
              value={bankAccountData?.alias}
            />
            {error && <AlertForm message={error} variant="error" />}
            <ButtonGrid>
              <AddAccountButton
                variant="contained"
                color="primary"
                isLoading={bankAccountIsCreating}
                disabled={bankAccountIsCreating}
                type="submit"
              >
                Agregar cuenta
              </AddAccountButton>
            </ButtonGrid>
          </ValidatorForm>
        </Box>
      </BaseDrawer>
    </div>
  );
};

AddBankAccountDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierIdentifier: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  bankAccountIsCreating: PropTypes.bool.isRequired,
};

export default AddBankAccountDrawer;
