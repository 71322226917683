import { Country } from '../schemas';

export const getCurrencyFormat = (amount: number, country: Country) => {
  const options = {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    CL: {
      locale: 'es-CL',
      currency: 'CLP',
    },
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    MX: {
      locale: 'es-MX',
      currency: 'MXN',
    },
  };

  return new Intl.NumberFormat(
    options[country].locale,
    { style: 'currency', currency: options[country].currency },
  ).format(amount);
};
