import {
  FETCH_ALL_RESPONSIBLES_START,
  FETCH_ALL_RESPONSIBLES_SUCCESS,
  FETCH_ALL_RESPONSIBLES_FAILURE,
  FETCH_RESPONSIBLES_START,
  FETCH_RESPONSIBLES_SUCCESS,
  FETCH_RESPONSIBLES_FAILURE,
  CREATE_RESPONSIBLE_START,
  CREATE_RESPONSIBLE_SUCCESS,
  CREATE_RESPONSIBLE_FAILURE,
  DELETE_RESPONSIBLE_START,
  DELETE_RESPONSIBLE_SUCCESS,
  DELETE_RESPONSIBLE_FAILURE,
} from '../actions/types';

const initialState = {
  allResponsibles: [],
  fetchAllResponsiblesIsLoading: false,
  responsibles: [],
  fetchResponsiblesIsLoading: false,
  createResponsibleIsLoading: false,
  responsibleWasCreated: false,
  createResponsibleError: null,
  deleteResponsibleIsLoading: false,
  responsibleWasDeleted: false,
  deleteResponsibleError: null,
};

const fetchAllResponsiblesStart = state => ({
  ...state,
  allResponsibles: [],
  fetchAllResponsiblesIsLoading: true,
});
const fetchAllResponsiblesSuccess = (state, payload) => ({
  ...state,
  allResponsibles: payload.allResponsibles,
  fetchAllResponsiblesIsLoading: false,
});
const fetchAllResponsiblesFailure = state => ({
  ...state,
  allResponsibles: [],
  fetchAllResponsiblesIsLoading: false,
});
const fetchResponsiblesStart = state => ({
  ...state,
  responsibles: [],
  fetchResponsiblesIsLoading: true,
});
const fetchResponsiblesSuccess = (state, payload) => ({
  ...state,
  responsibles: payload.responsibles,
  fetchResponsiblesIsLoading: false,
});
const fetchResponsiblesFailure = state => ({
  ...state,
  responsibles: [],
  fetchResponsiblesIsLoading: false,
});
const createResponsibleStart = state => ({
  ...state,
  createResponsibleIsLoading: true,
  responsibleWasCreated: false,
  createResponsibleError: null,
});
const createResponsibleSuccess = (state, payload) => ({
  ...state,
  createResponsibleIsLoading: false,
  responsibleWasCreated: true,
  responsibles: [...state.responsibles, payload.newResponsible],
});
const createResponsibleFailure = (state, payload) => ({
  ...state,
  createResponsibleIsLoading: false,
  createResponsibleError: payload.errorCode,
});
const deleteResponsibleStart = state => ({
  ...state,
  deleteResponsibleIsLoading: true,
  responsibleWasDeleted: false,
  deleteResponsibleError: null,
});
const deleteResponsibleSuccess = (state, payload) => ({
  ...state,
  deleteResponsibleIsLoading: false,
  responsibleWasDeleted: true,
  responsibles: state.responsibles.filter(
    responsible => responsible.id !== payload.responsibleId
  ),
});
const deleteResponsibleFailure = (state, payload) => ({
  ...state,
  deleteResponsibleIsLoading: false,
  deleteResponsibleError: payload.errorCode,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ALL_RESPONSIBLES_START:
      return fetchAllResponsiblesStart(state);
    case FETCH_ALL_RESPONSIBLES_SUCCESS:
      return fetchAllResponsiblesSuccess(state, payload);
    case FETCH_ALL_RESPONSIBLES_FAILURE:
      return fetchAllResponsiblesFailure(state);
    case FETCH_RESPONSIBLES_START:
      return fetchResponsiblesStart(state);
    case FETCH_RESPONSIBLES_SUCCESS:
      return fetchResponsiblesSuccess(state, payload);
    case FETCH_RESPONSIBLES_FAILURE:
      return fetchResponsiblesFailure(state);
    case CREATE_RESPONSIBLE_START:
      return createResponsibleStart(state);
    case CREATE_RESPONSIBLE_SUCCESS:
      return createResponsibleSuccess(state, payload);
    case CREATE_RESPONSIBLE_FAILURE:
      return createResponsibleFailure(state, payload);
    case DELETE_RESPONSIBLE_START:
      return deleteResponsibleStart(state);
    case DELETE_RESPONSIBLE_SUCCESS:
      return deleteResponsibleSuccess(state, payload);
    case DELETE_RESPONSIBLE_FAILURE:
      return deleteResponsibleFailure(state, payload);
    default:
      return state;
  }
};
