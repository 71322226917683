import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selectInput: {
    marginBottom: '10px',
  },
  receiverText: {
    fontSize: 12,
    textAlign: 'center',
    margin: '-15px auto 20px',
    maxWidth: 270,
  },
  feedback: {
    alignItems: 'stretch',
    background: 'rgba(0, 147, 255, 0.2)',
    borderRadius: '5px',
    display: 'flex',
    marginBottom: '25px',
  },
  iconBox: {
    background: '#1A73E7',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    padding: '18px 8px',
    borderRadius: '5px 0 0 5px',
  },
  msgBox: {
    padding: '10px 20px',
    fontWeight: '500',
  },
});
