import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  Divider,
  ContainerData,
  ContainerRow,
  ContainerFirstParagraph,
  ContainerComment,
  Comment,
  Date,
  UserName,
  Category,
  ContainerFooter,
} from './styles';
import Skeleton from '../Skeleton';
import { dateFormatter } from '../../../../helpers/DateUtils';
import { CommentResponse } from '../../interfaces/collection';
import { fetchComments } from '../../../../actions/commentsActions';
import { commentCategoryDictionary } from '../../utils/constants';
import BaseDrawer from '../../../../components/drawers/BaseDrawer';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  sourceId: number;
  sourceType: string;
  comments: CommentResponse[];
  handleCloseDrawer: () => void;
}

const ListCommentDrawer = ({
  isOpen,
  isLoading,
  sourceId,
  sourceType,
  comments,
  handleCloseDrawer,
}: Props) => {
  const dispatch = useDispatch();
  const containerTitleRef: HTMLElement | null =
    document.getElementById('containerData');

  useEffect(() => {
    if (sourceType && sourceId) {
      dispatch(fetchComments(sourceType, sourceId));
    }
  }, [isOpen]);

  return (
    <BaseDrawer
      isOpen={isOpen}
      title="Historial de comentarios"
      width={500}
      handleCloseDrawer={handleCloseDrawer}
    >
      <ContainerData id="containerData">
        <Skeleton isLoading={isLoading} variant="rect" width={500} height={160}>
          <>
            {comments?.map((comment: CommentResponse) => (
              <ContainerRow key={comment.id}>
                <ContainerFirstParagraph>
                  <ContainerComment>
                    <Comment>{comment.comment}</Comment>
                  </ContainerComment>
                  <Date>{dateFormatter(comment.createdAt)}</Date>
                </ContainerFirstParagraph>
                <UserName>Usuario: {comment.username}</UserName>
                <Category>
                  Categoría: {commentCategoryDictionary[comment.category]}
                </Category>
              </ContainerRow>
            ))}
            {comments?.length === 0 && (
              <Comment>No hay comentarios para mostrar</Comment>
            )}
          </>
        </Skeleton>
      </ContainerData>
      <Divider />
      <ContainerFooter>
        <Button
          onClick={() =>
            containerTitleRef && containerTitleRef.scrollIntoView()
          }
          variant="text"
          color="primary"
        >
          Volver al último comentario
        </Button>
      </ContainerFooter>
    </BaseDrawer>
  );
};

export default ListCommentDrawer;
