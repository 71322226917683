import React, { FC } from 'react';
import {
  Box,
  styled,
  alpha,
  BoxProps,
  CircularProgress,
} from '@material-ui/core';
import useGetCountry from '../../../../../hooks/useGetCountry';
import {
  COUNTRY_CODE_CL,
  INVOICE_STATUS_CL,
  INVOICE_STATUS_MX,
} from '../../../../../helpers/Constants';
import { XepelinStatus } from '../../../interfaces/ARInterfaces';
import { palette } from '../../../../../theme/palette';

const ChangeStatusOperationButtonCustom = styled(Box)(
  ({ status, disabled }: { status: XepelinStatus; disabled?: boolean }) => {
    const { stateText1, stateText4 } = palette.payments;
    const {
      complete,
      approved,
      pvApproved,
      rejected,
      renegotiation,
      default: defaultStateColor,
    } = palette.payments.statusColors;

    const defaultColor = {
      color: stateText4,
      background: alpha(stateText4, 0.12),
    };

    const disabledColor = {
      color: palette.default.main,
      background: palette.text.disabled,
    };

    const statusColor: {
      [key in XepelinStatus]?: {
        color: string;
        background: string;
      };
    } = {
      ACTIVE: {
        color: stateText4,
        background: alpha(stateText4, 0.24),
      },
      PAID: {
        color: stateText4,
        background: alpha(stateText4, 0.24),
      },
      IN_REVIEW: {
        color: stateText1,
        background: alpha(stateText1, 0.24),
      },
      REVIEWED: {
        color: stateText1,
        background: alpha(stateText1, 0.24),
      },
      APPEALED: {
        color: stateText1,
        background: alpha(stateText1, 0.24),
      },
      COMPLETE: {
        color: complete,
        background: alpha(complete, 0.24),
      },
      APPROVED: {
        color: approved,
        background: alpha(approved, 0.24),
      },
      APPROVED_FUND: {
        color: approved,
        background: alpha(approved, 0.24),
      },
      PV_APPROVED: {
        color: pvApproved,
        background: alpha(pvApproved, 0.24),
      },
      TO_DEPOSIT: {
        color: pvApproved,
        background: alpha(pvApproved, 0.24),
      },
      REJECTED: {
        color: rejected,
        background: alpha(rejected, 0.24),
      },
      RENEGOTIATION: {
        color: renegotiation,
        background: alpha(renegotiation, 0.24),
      },
      DEFAULT: {
        color: defaultStateColor,
        background: alpha(defaultStateColor, 0.24),
      },
    };

    let statusColorSelected = statusColor[status] || defaultColor;

    if (disabled) {
      statusColorSelected = disabledColor;
    }
    return {
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 'bold',
      height: 40,
      cursor: disabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...statusColorSelected,
      transition: 'background 0.5s',
      '&:hover, &focus': {
        background: alpha(statusColorSelected.background, 0.18),
      },
    };
  }
);

interface Props extends BoxProps {
  operationStatus: XepelinStatus;
  disabled?: boolean;
  isLoading?: boolean;
}

const ChangeStatusOperationButton: FC<Props> = ({
  operationStatus,
  disabled = false,
  isLoading = false,
  ...rest
}) => {
  const country = useGetCountry();
  const status =
    country === COUNTRY_CODE_CL ? INVOICE_STATUS_CL : INVOICE_STATUS_MX;
  const label = status[operationStatus];

  return (
    <ChangeStatusOperationButtonCustom
      {...rest}
      status={operationStatus}
      disabled={disabled}
    >
      {isLoading ? <CircularProgress size={18} /> : label}
    </ChangeStatusOperationButtonCustom>
  );
};

export default ChangeStatusOperationButton;
