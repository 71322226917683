/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextValidator } from 'react-material-ui-form-validator';
import { RoleChip } from './ChipComponents';
import capitalizeFirstLetter from '../../helpers/FontUtils';

const getRolesFiltered = (roles, user) => {
  const { roles: rolesUser } = user;
  const rolesUserSupervisor = rolesUser.filter(
    roleUser => roleUser.isSupervisor || roleUser.rolename === 'admin'
  );
  const rolesFiltered = roles.filter(role => {
    const roleSupervisor = rolesUserSupervisor.find(
      roleUser =>
        roleUser.rolename === role.rolename || roleUser.rolename === 'admin'
    );
    return !!roleSupervisor;
  });
  return rolesFiltered;
};

const RoleSelector = props => {
  const { handleSelectedRole, userRoles, handleWarning, isNew } = props;
  const { roles, user } = props;
  const selectedRoles = userRoles || [];
  const filter = createFilterOptions();
  const filterRoles = getRolesFiltered(roles, user);
  const onChangeSelectedRole = (event, values) => {
    if (!values.length && !isNew) {
      handleWarning('El usuario siempre debe tener un rol asignado');
      return;
    }
    const newValue = values[values.length - 1];
    const oldValues = values.slice(0, values.length - 1);
    // Check if newValue is already selected. Remove this element from the list.
    const listFiltered = oldValues.filter(({ id, isSupervisor }) => {
      if (newValue.isSupervisor) {
        return id !== newValue.id || !isSupervisor;
      }
      return id !== newValue.id || isSupervisor;
    });
    const selectedValues =
      listFiltered?.length !== oldValues.length ? listFiltered : values;
    handleSelectedRole(selectedValues);
  };

  const getRoleRenderOption = option => {
    const { isSupervisor, rolename } = option;
    const preffix = isSupervisor ? 'Superv. ' : '';
    const inputName = isSupervisor ? rolename : capitalizeFirstLetter(rolename);
    return (
      <>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{
            marginRight: 8,
          }}
          checked={selectedRoles.some(
            ({ rolename: name, isSupervisor: supervisor }) => {
              let condition = name === rolename && !isSupervisor;
              if (supervisor) {
                condition = name === rolename && isSupervisor;
              }
              return condition;
            }
          )}
        />
        <Typography variant="caption" color="textSecondary">
          {preffix}
          {inputName}
        </Typography>
      </>
    );
  };
  const getRoleFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (
      params.inputValue !== '' &&
      !options.some(o => o.rolename === params.inputValue)
    ) {
      filtered.push({
        inputValue: params.inputValue,
        comment: `Agregar "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const getRoleOptionLabel = option => {
    if (typeof option === 'string') {
      return option;
    }
    const { isSupervisor, rolename } = option;
    const preffix = isSupervisor ? 'Superv. ' : '';
    const inputName = isSupervisor ? rolename : capitalizeFirstLetter(rolename);
    return `${preffix}${inputName}`;
  };

  return (
    <Autocomplete
      selectOnFocus
      value={selectedRoles}
      multiple
      disableClearable
      disableCloseOnSelect
      options={filterRoles}
      onChange={onChangeSelectedRole}
      filterOptions={getRoleFilterOptions}
      getOptionLabel={getRoleOptionLabel}
      renderOption={getRoleRenderOption}
      renderInput={params => (
        <TextValidator
          variant="outlined"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Roles"
          placeholder="Selecciona el o los roles"
          color="primary"
          InputProps={{
            ...params.InputProps,
            readOnly: true,
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map(({ rolename, color, isSupervisor }, index) => {
          return (
            <RoleChip
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
              role={rolename}
              color={color}
              supervisor={isSupervisor}
            />
          );
        });
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    roles: state.user.roles,
    user: state.auth.user,
  };
};

RoleSelector.defaultProps = {
  userRoles: null,
  isNew: false,
};

RoleSelector.propTypes = {
  roles: PropTypes.arrayOf(Object).isRequired,
  user: PropTypes.objectOf(Object).isRequired,
  handleSelectedRole: PropTypes.func.isRequired,
  handleWarning: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(String),
  isNew: PropTypes.bool,
};

export default connect(mapStateToProps)(RoleSelector);
