/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid, makeStyles, Box } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { DATE_FORMAT } from '../../helpers/Constants';
import { dateWithoutFormatter } from '../../helpers/DateUtils';
import AlertForm from '../elements/AlertForm';
import CheckButton from '../elements/CheckButton';
import BaseDialog from './BaseDialog';

const useStyles = makeStyles({
  submitButton: {
    marginTop: 10,
  },
  containerDefaultValue: {
    marginTop: '-10px',
    marginBottom: 10,
  },
});

const PartialPaymentsDialog = ({
  open,
  handleCloseDialog,
  selectedPayment,
  handleSubmit,
  isLoading,
  error,
}) => {
  const classes = useStyles();

  const [payment, setPayment] = useState({
    amount: 0,
    paymentDate: '',
  });
  const [checkButton, setCheckButton] = useState(false);

  const { amount, paymentDate } = payment;

  useEffect(() => {
    if (selectedPayment) {
      setPayment(selectedPayment);
    }
  }, [selectedPayment]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPositiveOrZero', value => value >= 0);
  }, []);

  const onSubmit = () => {
    handleSubmit(payment);
  };

  const handleInputChange = event => {
    const {
      target: { value: valueInput, name: nameInput },
    } = event;

    setPayment(previousValue => ({
      ...previousValue,
      [nameInput]: valueInput,
    }));
  };

  const handleDateChange = (name, value) => {
    setPayment(previousValue => ({
      ...previousValue,
      [name]: value,
    }));
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={selectedPayment ? 'Editar pago' : 'Agregar pago'}
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={onSubmit}>
          <TextValidator
            fullWidth
            variant="outlined"
            label="Monto ($)"
            name="amount"
            type="number"
            value={amount}
            onChange={handleInputChange}
            validators={['required', 'isPositiveOrZero']}
            errorMessages={[
              'Campo requerido',
              'El valor debe ser mayor o igual a 0',
            ]}
          />
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha"
              name="paymentDate"
              format={DATE_FORMAT}
              onChange={data => handleDateChange('paymentDate', data)}
              minDateMessage="Fecha inválida"
              maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
              autoOk
              disableFuture
              value={dateWithoutFormatter(paymentDate)}
              TextFieldComponent={props => {
                return (
                  <TextValidator
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                  />
                );
              }}
            />
          </MuiPickersUtilsProvider>

          {error && <AlertForm message={error} variant="error" />}

          <Box className={classes.submitButton}>
            <CheckButton
              check={checkButton}
              handleCheck={() => setCheckButton(!checkButton)}
              labelButton={selectedPayment ? 'Editar' : 'Agregar pago'}
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              color="primary"
            />
          </Box>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

PartialPaymentsDialog.defaultProps = {
  isLoading: false,
  error: null,
  selectedPayment: null,
  defaultAmount: 0,
};

PartialPaymentsDialog.propTypes = {
  selectedPayment: PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
    paymentDate: PropTypes.string,
  }),
  defaultAmount: PropTypes.number,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default PartialPaymentsDialog;
