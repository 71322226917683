import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Reload = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M4.4516 4.42036C6.30093 2.83105 8.66642 1.97077 11.1047 2.00076C13.5429 2.03075 15.8865 2.94896 17.6962 4.58327C19.5059 6.21758 20.6574 8.45579 20.9349 10.8784C21.2124 13.3009 20.5968 15.7416 19.2035 17.7427L16.0093 12H19.0076C19.0072 10.4298 18.5456 8.89436 17.68 7.5843C16.8145 6.27425 15.5831 5.24732 14.1389 4.63108C12.6947 4.01484 11.1013 3.83643 9.55657 4.11801C8.01184 4.3996 6.58387 5.12877 5.45 6.21496L4.4516 4.42036ZM17.5484 19.5796C15.6991 21.1689 13.3336 22.0292 10.8953 21.9992C8.45707 21.9693 6.11345 21.051 4.30377 19.4167C2.49409 17.7824 1.34259 15.5442 1.0651 13.1216C0.787617 10.6991 1.4032 8.25845 2.79647 6.25728L5.99858 12H2.98612C2.98593 13.5712 3.44759 15.1077 4.3137 16.4186C5.1798 17.7295 6.41214 18.7568 7.85748 19.3729C9.30281 19.989 10.8974 20.1667 12.4429 19.8838C13.9884 19.6009 15.4166 18.87 16.55 17.7819L17.5484 19.5796Z" />
    </SvgIcon>
  );
};

export default Reload;
