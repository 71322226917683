import { styled, Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  inputMultiLine: {
    '& fieldset': {
      borderRadius: 17,
    },
  },
});

const ContainerCategory = styled(Box)({
  marginBottom: 10,
});

const ContainerComment = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
});

const ContainerCommentInput = styled(Box)({
  marginBottom: 25,
});

const LabelComment = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: 5,
});

export {
  useStyles,
  ContainerCategory,
  ContainerComment,
  ContainerCommentInput,
  LabelComment,
};
