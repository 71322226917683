import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchOrderResponsiblesCounterService = async businessIdentifier => {
  try {
    const { data } = await axios.get(`/api/kam/${businessIdentifier}`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrderResponsiblesCounterService };
