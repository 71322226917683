import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import verifyDecimals from '../../../../../../helpers/validation/decimals';
import CheckButton from '../../../../../../components/elements/CheckButton';
import { setHourString } from '../../../../../../helpers/DateUtils';
import { DatePicker } from '../../../../../commons/components/DatePicker';

const rules = ['required'];
const errorMsg = ['Campo requerido'];
const validators = { rules, errorMsg };

export const UpdateFundInvoiceTermDialog = ({
  isOpen,
  handleCloseDialog,
  title,
  description,
  selectedTerm,
  handleSubmit,
  isLoading,
}) => {
  const [term, setTerm] = useState(selectedTerm);
  const [checked, setChecked] = useState(false);

  const handleInputDateChange = (value, name) => {
    const date = setHourString(value);
    setTerm({
      ...term,
      [name]: date,
    });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('verifyMaxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  useEffect(() => {
    setTerm(selectedTerm);
    setChecked(false);
  }, [selectedTerm]);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={() => {
        handleCloseDialog();
        setChecked(false);
      }}
      title={title}
      description={description}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm
          onSubmit={() => {
            handleSubmit(term);
            setChecked(false);
          }}
        >
          <DatePicker
            baseDate={term.expirationDate}
            onChange={dateForm =>
              handleInputDateChange(dateForm.inDate, 'expirationDate')
            }
            validators={validators}
          />
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UpdateFundInvoiceTermDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  description: '',
};

UpdateFundInvoiceTermDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // Ver bien lo que se pasara para hacer un shape
  selectedTerm: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
