import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Divider, Drawer, Link } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { RootStateOrAny, useSelector } from 'react-redux';
import Text from '../../../commons/components/Text';
import { useStyles, TextLabel, TextLabelValue } from './styles';
import { DebtExtensionDrawer } from '../../../commons/interfaces/debtExtension';
import { StatusChip } from '../../../../components/elements/chips';
import { DEBT_EXTENSION } from '../../../../helpers/Constants';
import { getCurrencyFormat } from '../../../../features/credit-line/utils';

interface Props {
  open: boolean;
  closeDrawer: () => void;
  extension: DebtExtensionDrawer | undefined;
}

const ExtensionsDrawer = ({ open, closeDrawer, extension }: Props) => {
  const classes = useStyles();
  const { country } = useSelector((state: RootStateOrAny) => state.config);
  return (
    <Drawer anchor="right" open={open} onClose={() => closeDrawer()}>
      <div style={{ maxWidth: '350px', padding: '20px' }}>
        <Box my={1} ml={1}>
          <div style={{ display: 'flex' }}>
            <Text
              variant="h5"
              align="center"
              fontWeight="bold"
            >
              Renegociación
            </Text>
            <Button
              style={{ marginLeft: '65px' }}
              onClick={() => closeDrawer()}
            >
              X
            </Button>
          </div>
          <Divider />
        </Box>
        <Box mt={2} mb={1}>
          <Box mt={4}>
            <ul style={{ margin: 0, padding: 0 }}>
              <li className={classes.spacingVertical}>
                <TextLabel>Tipo</TextLabel>
                <StatusChip
                  status={DEBT_EXTENSION}
                  entityType="debt"
                  // @ts-ignore
                  size="small"
                />
              </li>
              <li className={classes.spacingVertical}>
                <TextLabel>Vencimiento original</TextLabel>
                <TextLabelValue>
                  {
                    new Date(extension?.originalExpiration || new Date())
                      .toLocaleDateString('es-CL')
                      .split('T')[0]
                  }
                </TextLabelValue>
              </li>
              <li className={classes.spacingVertical}>
                <TextLabel>Promesa de pago</TextLabel>
                <TextLabelValue>
                  {
                    new Date(extension?.newExpirationDate || new Date())
                      .toLocaleDateString('es-CL')
                      .split('T')[0]
                  }
                </TextLabelValue>
              </li>
              <li className={classes.spacingVertical}>
                <TextLabelValue>Interés por mora</TextLabelValue>
                <Text fontWeight="bold">
                  {getCurrencyFormat(extension?.firstFeeAmount || 0, country)}
                </Text>
              </li>
              <li className={classes.spacingVertical}>
                <TextLabel>Pago de interés por mora</TextLabel>
                <TextLabelValue>
                  {
                    new Date(extension?.startDate || new Date())
                      .toLocaleDateString('es-CL')
                      .split('T')[0]
                  }
                </TextLabelValue>
              </li>
              <li className={classes.spacingVertical}>
                <TextLabel>Creado por</TextLabel>
                <Text fontWeight="bold">{extension?.createdBy}</Text>
              </li>
              <li className={classes.spacingVertical}>
                <TextLabel>Fecha de creación</TextLabel>
                <TextLabelValue>
                  {
                    new Date(extension?.createdAt || new Date())
                      .toLocaleDateString('es-CL')
                      .split('T')[0]
                  }
                </TextLabelValue>
              </li>

              {extension?.relatedInvoices && (
                <li className={classes.breakWords}>
                  <TextLabel>Folios renegociados</TextLabel>
                  {extension?.relatedInvoices?.map((invoice, index) => (
                    <Link
                      className={classes.links}
                      component={RouterLink}
                      underline="hover"
                      to={`/${country.toLowerCase()}/orders/${
                        invoice.orderInvoiceID
                      }`}
                      target="_blank"
                    >
                      {`${invoice.folio}${extension.relatedInvoices?.[index + 1] ? ', ' : ''}`}
                    </Link>
                  ))}
                </li>
              )}
              <li className={classes.liDrawer}>
                <TextLabel>Motivo </TextLabel>
                <Text>{extension?.justification || '-'}</Text>
              </li>
              <li className={classes.liDrawer}>
                <a
                  rel="noopener noreferrer"
                  href={`${extension?.linkDocuments}`}
                  target="_blank"
                  className={`${classes.linksDocument} ${classes.alignLink}`}
                >
                  <span>Ver documentos</span>
                  <OpenInNew className={classes.title} />
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default ExtensionsDrawer;
