import {
  Box,
  Typography,
  Divider as DividerMui,
  styled,
} from '@material-ui/core';

const Divider = styled(DividerMui)({
  marginBottom: 30,
});
const ContainerData = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 725,
});
const ContainerRow = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: 15,
  marginBottom: 15,
  '&:hover': {
    backgroundColor: '#F7F5FA',
  },
});
const ContainerFirstParagraph = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 15,
  minHeight: 39,
});
const ContainerComment = styled(Box)({
  display: 'flex',
  width: 380,
});
const Comment = styled(Typography)({
  fontSize: 14,
});
const Date = styled(Typography)({
  fontSize: 14,
  color: '#8D8A96',
});
const UserName = styled(Typography)({
  fontSize: 12,
  color: '#8D8A96',
  marginBottom: 10,
});
const Category = styled(Typography)({
  fontSize: 12,
  color: '#8D8A96',
});
const ContainerFooter = styled(Box)({
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export {
  Divider,
  ContainerData,
  ContainerRow,
  ContainerFirstParagraph,
  ContainerComment,
  Comment,
  Date,
  UserName,
  Category,
  ContainerFooter,
};
