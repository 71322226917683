import React, { FC } from 'react';
import { Box, Button, Collapse } from '@material-ui/core';
import AddCircleIcon from '../../icons/AddCircleIcon';
import LinkIcon from '../../icons/LinkIcon';
import OpenEyeIcon from '../../icons/OpenEyeIcon';
import SearchIcon from '../../icons/SearchIcon';
import {
  RootContainer,
  TitleText,
  ContainerSection,
  Divider,
  ContainerButtons,
  NewSimulationButton,
  RowTextEmpty,
} from './styles';
import useOperationResume from './hooks/useOperationResume';
import ChangeStatusOperationButton from './ui/ChangeStatusOperationButton';
import HeaderPanelCollapse from './ui/HeaderPanelCollapse';
import OperationResumeSkeleton from './ui/OperationResumeSkeleton';
import DiscountDrawer from '../../../../components/drawers/DiscountDrawer';
import Can from '../../../../components/Can';
import { Operation } from '../../interfaces/ARInterfaces';

interface Props {
  operation: Operation;
  handleShowSimulation: () => void;
  handleShowNewSimulationDialog: () => void;
  handleShowMatchInvoicesPDFDialog: () => void;
  handleShowChangeStatusOperationDialog: () => void;
}

const OperationResume: FC<Props> = ({
  operation,
  handleShowSimulation,
  handleShowNewSimulationDialog,
  handleShowMatchInvoicesPDFDialog,
  handleShowChangeStatusOperationDialog,
}) => {
  const {
    fetchOrderIsLoading,
    orderSummaryIsLoading,
    fundSummaryIsLoading,
    riskAnalysisUrl,
    availableNewSimulationDialog,
    performs,
    countryFeatures,
    orderSummary,
    fundSummary,
    showDiscountDrawer,
    showOperationSummary,
    showFundOperationSummary,
    operationResumeDictionary,
    fundOperationResumeDictionary,
    operationSummaryData,
    fundOperationSummaryData,
    toggleShowOperationSummary,
    toggleShowFundOperationSummary,
    handleCloseDiscountDrawer,
  } = useOperationResume(operation);

  return (
    <>
      <RootContainer>
        <Box mb={4}>
          <TitleText>Estado de la operación</TitleText>
        </Box>
        <Can
          perform={performs.ORDERS_EDIT_STATUS_PERFORM}
          yes={() => (
            <ChangeStatusOperationButton
              mb={4}
              operationStatus={operation?.status}
              data-cy="changeStatusOrderButton"
              onClick={handleShowChangeStatusOperationDialog}
              isLoading={fetchOrderIsLoading}
            />
          )}
          no={() => (
            <ChangeStatusOperationButton
              mb={4}
              operationStatus={operation?.status}
              data-cy="changeStatusOrderButton"
              disabled
              isLoading={fetchOrderIsLoading}
            />
          )}
        />

        <ContainerSection mb={4}>
          <HeaderPanelCollapse
            handlerClick={toggleShowOperationSummary}
            showInformation={showOperationSummary}
          >
            Resumen de la operación
          </HeaderPanelCollapse>

          <Collapse in={showOperationSummary} timeout="auto">
            <Box mt={4} mb={2}>
              <OperationResumeSkeleton
                isLoading={orderSummaryIsLoading}
                operationResumeDictionary={operationResumeDictionary}
              >
                {operationSummaryData(orderSummary).length ? (
                  operationSummaryData(orderSummary).map(summary => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      key={summary.key}
                      data-cy={summary.dataCy}
                      mb={3}
                    >
                      {summary.label}
                      {summary.value}
                    </Box>
                  ))
                ) : (
                  <RowTextEmpty>No hay información disponible.</RowTextEmpty>
                )}
              </OperationResumeSkeleton>
            </Box>
          </Collapse>
          <Divider />
        </ContainerSection>

        <ContainerSection mb={5}>
          <HeaderPanelCollapse
            handlerClick={toggleShowFundOperationSummary}
            showInformation={showFundOperationSummary}
          >
            Resumen Financiamiento Fondo
          </HeaderPanelCollapse>

          <Collapse in={showFundOperationSummary} timeout="auto">
            <Box mt={4} mb={2}>
              <OperationResumeSkeleton
                isLoading={orderSummaryIsLoading || fundSummaryIsLoading}
                operationResumeDictionary={fundOperationResumeDictionary}
              >
                {Object.keys(fundSummary)?.length ? (
                  <>
                    {fundOperationSummaryData(fundSummary).length ? (
                      fundOperationSummaryData(fundSummary).map(fundSummary => (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          key={fundSummary.key}
                          data-cy={fundSummary.dataCy}
                          mb={3}
                        >
                          {fundSummary.label}
                          {fundSummary.value}
                        </Box>
                      ))
                    ) : (
                      <RowTextEmpty>
                        No hay información disponible.
                      </RowTextEmpty>
                    )}
                  </>
                ) : (
                  <RowTextEmpty>No hay información disponible.</RowTextEmpty>
                )}
              </OperationResumeSkeleton>
            </Box>
          </Collapse>
          <Divider />
        </ContainerSection>

        <ContainerButtons>
          {availableNewSimulationDialog && (
            <NewSimulationButton
              color="primary"
              variant="contained"
              startIcon={<AddCircleIcon color="#fff" />}
              onClick={handleShowNewSimulationDialog}
              data-cy="operationResumeNewSimulationButton"
              disabled={!countryFeatures.orderActions.showNewSimulationButton}
            >
              Nueva simulación
            </NewSimulationButton>
          )}

          <Box mt={2} mb={2}>
            <Button
              variant="text"
              color="primary"
              startIcon={<OpenEyeIcon />}
              onClick={handleShowSimulation}
            >
              Ver simulación
            </Button>
          </Box>

          {operation?.showPendingInvoicesButton && (
            <Box mb={2}>
              <Can
                perform={performs.INVOICES_LINKED_PDF_PERFORM}
                yes={() => (
                  <Button
                    onClick={handleShowMatchInvoicesPDFDialog}
                    startIcon={<LinkIcon />}
                    variant="text"
                    color="primary"
                  >
                    Vincular PDF cedible
                  </Button>
                )}
              />
            </Box>
          )}

          <Button
            variant="text"
            color="primary"
            target="_blank"
            href={riskAnalysisUrl}
            startIcon={<SearchIcon />}
            disabled={!countryFeatures.orderActions.showRiskAnalysisButton}
          >
            Análisis de Riesgo
          </Button>
        </ContainerButtons>
      </RootContainer>

      {showDiscountDrawer && (
        <DiscountDrawer
          isOpen={showDiscountDrawer}
          sourceId={operation.id}
          sourceType="order"
          sourceData={{
            totalAmount:
              orderSummary?.finalAmount + orderSummary?.transferDiscount,
            operationType: operation.orderType,
            recommendedDiscount: orderSummary?.recommendedDiscount,
          }}
          handleCloseDrawer={handleCloseDiscountDrawer}
        />
      )}
    </>
  );
};

export default OperationResume;
