import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Chip as MuiChip, Box } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOffRounded';

import { palette } from '../../theme/palette';

const Chip = ({
  bg,
  color,
  deleteIcon,
  disabled,
  label,
  noBold,
  onClick,
  onDelete,
  size,
  variant,
  ...extra
}) => {
  return (
    <MuiChip
      className={extra.className}
      deleteIcon={deleteIcon}
      disabled={disabled}
      onClick={onClick}
      onDelete={onDelete}
      size={size}
      variant={variant}
      label={
        <Box
          fontWeight={noBold ? 'fontWeightRegular' : 'fontWeightBold'}
          style={
            extra.ellipsisWidth && {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: extra.ellipsisWidth,
            }
          }
        >
          {label}
        </Box>
      }
      style={{ background: bg, color, border: 0 }}
    />
  );
};

Chip.defaultProps = {
  bg: palette.primary.main,
  color: '#fff',
  deleteIcon: <HighlightOffIcon style={{ color: '#fff' }} />,
  disabled: false,
  noBold: false,
  onClick: () => {},
  onDelete: () => {},
  size: 'small',
  variant: 'default',
};

Chip.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  deleteIcon: PropTypes.element,
  disabled: PropTypes.bool,
  label: PropTypes.node.isRequired,
  noBold: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.string,
};

export default memo(Chip);
