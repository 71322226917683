/* eslint-disable no-unused-vars */
import {
  Select,
  FormControl,
  MenuItem,
  makeStyles,
  Typography,
  TableCell,
} from '@material-ui/core';
import MTPagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import * as React from 'react';
/* eslint-enable no-unused-vars */

const useStyles = makeStyles({
  root: {
    padding: 0,
    border: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 25,
    paddingBottom: 15,
  },
  labelRowsPerPage: {
    marginRight: 5,
  },
  pagination: {
    margin: '0 40px',
  },
});

const Pagination = props => {
  const {
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onChangeRowsPerPage,
    labelRowsPerPage,
    labelDisplayedRows,
    onChangePage,
    hasRowsSelector = true,
    hasLabelRowsPerPage,
  } = props;
  const classes = useStyles();

  const maxPages = rowsPerPage ? Math.ceil(count / rowsPerPage) : 0;
  const from = rowsPerPage ? page * rowsPerPage + 1 : 0;
  const to = rowsPerPage
    ? Math.min(page * rowsPerPage + rowsPerPage, count)
    : 0;

  const handleChangePage = (event, selectedPage) => {
    onChangePage(event, selectedPage - 1);
  };

  return (
    <TableCell className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.labelRowsPerPage}
        >
          {labelRowsPerPage}
        </Typography>
        {hasRowsSelector && (
          <FormControl size="small" variant="outlined">
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={onChangeRowsPerPage}
            >
              {rowsPerPageOptions.map(option => {
                let selectValue = option;
                let selectOption = option;
                if (typeof option === 'object' && option !== null) {
                  selectValue = option.value;
                  selectOption = option.label;
                }
                return (
                  <MenuItem
                    key={selectValue}
                    value={selectValue}
                    selected={selectValue === rowsPerPage}
                  >
                    {selectOption}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        <MTPagination
          count={maxPages}
          page={page + 1}
          onChange={handleChangePage}
          size="small"
          color="secondary"
          className={classes.pagination}
        />
        {hasLabelRowsPerPage && (
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.labelRowsPerPage}
          >
            {labelDisplayedRows({ from, to, count })}
          </Typography>
        )}
      </div>
    </TableCell>
  );
};

Pagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
  ]),
  labelDisplayedRows: PropTypes.func,
  labelRowsPerPage: PropTypes.string,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  hasRowsSelector: PropTypes.bool,
  hasLabelRowsPerPage: PropTypes.bool,
};

Pagination.defaultProps = {
  labelDisplayedRows: () => '{count} registros totales',
  labelRowsPerPage: 'Registros por página',
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  count: 0,
  hasRowsSelector: true,
  hasLabelRowsPerPage: true,
};

export default Pagination;
