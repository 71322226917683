import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { features } from '../../../config/features';
import RatesResume from '../../elements/RatesResume';
import Card from '../../elements/Card';
import { STATUS_REJECTED, STATUS_ACTIVE } from '../../../helpers/Constants';
import Can from '../../Can';
import RatesStepper from '../../elements/RatesStepper';
import {
  INVOICES_EDIT_RATES_PERFORM,
  ORDERS_FUND_RATES_PERFORM,
  ORDERS_UNLINK_FUND_PERFORM,
} from '../../../helpers/performsType';
import {
  deleteOrderInvoiceFundRate,
  updateOrderInvoiceFundRate,
  addOrderInvoiceFundRate,
  unlinkOrderInvoiceFund,
  fetchFacturas,
} from '../../../actions/orderActions';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import RateDialog from '../../dialogs/RateDialog';
import { fetchOrderInvoiceFundRates } from '../../../actions/fundsActions';
import {
  fetchOrderInvoiceBusinessRates,
  deleteOrderInvoiceBusinessRate,
  addOrderInvoiceBusinessRate,
  updateOrderInvoiceBusinessRate,
  resetRatesActions,
} from '../../../actions/ratesActions';

const useStyles = makeStyles({
  pymeRates: {
    marginBottom: 40,
  },
});

// APICL el prop 'invoice' es válido sólo para CL
const InvoiceDetail = ({
  orderInvoiceFund,
  orderInvoice,
  handleShowLoader,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showFundRateFormDialog, setShowFundRateFormDialog] = useState(false);
  const [showBusinessRateFormDialog, setShowBusinessRateFormDialog] =
    useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState();

  const country = useSelector(state => state.config.country);
  const { orderSummary } = useSelector(state => state.order);
  const {
    funds = [],
    orderInvoiceFundRates,
    deleteOrderInvoiceFundIsLoading,
    deleteOrderInvoiceFundError,
    orderInvoiceFundWasDeleted,
    deleteOrderInvoiceFundRateIsLoading,
    orderInvoiceFundRateWasDeleted,
  } = useSelector(state => state.funds);
  const {
    orderInvoiceBusinessRates,
    orderInvoiceIdOwnerOfBusinessRates,
    orderInvoiceBusinessRateWasCreated,
    orderInvoiceBusinessRateWasUpdated,
    orderInvoiceBusinessRateWasDeleted,
    addOrderInvoiceBusinessRatesError,
    updateOrderInvoiceBusinessRatesError,
    deleteOrderInvoiceBusinessRatesError,
    updateOrderInvoiceBusinessRatesIsLoading,
    addOrderInvoiceBusinessRatesIsLoading,
    deleteOrderInvoiceBusinessRatesIsLoading,
    fetchOrderInvoiceBusinessRatesIsLoading,
  } = useSelector(state => state.rates);

  const {
    advancePercentage,
    id: orderInvoiceFundId,
    fundId,
  } = orderInvoiceFund || {};

  const {
    orderId,
    id: orderInvoiceId,
    status: orderInvoiceStatus,
  } = orderInvoice;
  const countryFeatures = features[country];
  const { status: invoiceStatus } = orderInvoice;
  const { status: orderStatus } = updateOrderInvoiceBusinessRate;
  const { name: fundName = '' } = funds?.find(fund => fund.id === fundId) || {};

  useEffect(() => {
    if (orderInvoiceFundId) {
      dispatch(fetchOrderInvoiceFundRates(orderInvoiceFundId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInvoiceFundId]);

  useEffect(() => {
    if (
      countryFeatures.orderActions.showBusinessRates &&
      orderInvoiceId !== orderInvoiceIdOwnerOfBusinessRates
    ) {
      dispatch(fetchOrderInvoiceBusinessRates(orderInvoiceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInvoiceId]);

  useEffect(() => {
    if (orderInvoiceFundWasDeleted || orderInvoiceFundRateWasDeleted) {
      setShowConfirmDialog(false);
    }
  }, [orderInvoiceFundWasDeleted, orderInvoiceFundRateWasDeleted]);

  useEffect(() => {
    if (
      orderInvoiceBusinessRateWasCreated ||
      orderInvoiceBusinessRateWasUpdated ||
      orderInvoiceBusinessRateWasDeleted
    ) {
      setShowBusinessRateFormDialog(false);
      dispatch(resetRatesActions());
      if (orderId) dispatch(fetchFacturas(orderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderInvoiceBusinessRateWasCreated,
    orderInvoiceBusinessRateWasUpdated,
    orderInvoiceBusinessRateWasDeleted,
  ]);

  const handleSubmitOrderInvoiceFundRate = orderInvoiceFundRate => {
    setShowFundRateFormDialog(false);
    handleShowLoader();
    if (!selectedPeriod) {
      dispatch(
        addOrderInvoiceFundRate(orderId, {
          ...orderInvoiceFundRate,
          orderInvoiceFundId,
        })
      );
    } else {
      const newOrderInvoicerFundRate = { ...orderInvoiceFundRate };
      delete newOrderInvoicerFundRate.orderInvoiceFundId;
      delete newOrderInvoicerFundRate.createdAt;
      delete newOrderInvoicerFundRate.updatedAt;
      dispatch(
        updateOrderInvoiceFundRate(
          orderInvoiceFundRate.orderInvoiceFundId,
          newOrderInvoicerFundRate,
          orderId
        )
      );
    }
  };

  const handleDeleteOrderInvoiceFundRate = () => {
    const { id, orderInvoiceFundId } = selectedPeriod;
    dispatch(
      deleteOrderInvoiceFundRate(id, orderInvoiceFundId, orderId, country)
    );
  };

  const handleSubmitOrderInvoiceBusinessRate = orderInvoiceBusinessRate => {
    if (!selectedPeriod) {
      dispatch(
        addOrderInvoiceBusinessRate(orderInvoiceId, orderId, {
          ...orderInvoiceBusinessRate,
        })
      );
    } else {
      dispatch(
        updateOrderInvoiceBusinessRate(
          orderInvoiceId,
          orderId,
          orderInvoiceBusinessRate
        )
      );
    }
  };

  const handleDeleteOrderInvoiceBusinessRate = id => {
    dispatch(deleteOrderInvoiceBusinessRate(id, orderInvoiceId, orderId));
  };

  const handleEditFundRate = period => {
    setSelectedPeriod(period);
    setShowFundRateFormDialog(true);
  };

  const handleEditBusinessRate = period => {
    setSelectedPeriod(period);
    setShowBusinessRateFormDialog(true);
  };

  const handleSubmitUnlinkFundDialog = () => {
    dispatch(
      unlinkOrderInvoiceFund(orderId, orderInvoiceFundId, orderInvoiceId)
    );
  };

  const handleCloseFundRateDialog = () => {
    setSelectedPeriod(undefined);
    setShowFundRateFormDialog(false);
  };

  const handleCloseBusinessRateDialog = () => {
    setSelectedPeriod(undefined);
    setShowBusinessRateFormDialog(false);
  };

  return (
    <>
      {showFundRateFormDialog && (
        <RateDialog
          open={showFundRateFormDialog}
          handleCloseDialog={handleCloseFundRateDialog}
          selectedRate={selectedPeriod}
          handleSubmit={handleSubmitOrderInvoiceFundRate}
          handleDelete={handleDeleteOrderInvoiceFundRate}
          isLoading={deleteOrderInvoiceFundRateIsLoading}
        />
      )}
      {showBusinessRateFormDialog && (
        <RateDialog
          open={showBusinessRateFormDialog}
          handleCloseDialog={handleCloseBusinessRateDialog}
          selectedRate={selectedPeriod}
          handleSubmit={handleSubmitOrderInvoiceBusinessRate}
          handleDelete={() =>
            handleDeleteOrderInvoiceBusinessRate(selectedPeriod.id)
          }
          isLoading={
            updateOrderInvoiceBusinessRatesIsLoading ||
            addOrderInvoiceBusinessRatesIsLoading ||
            deleteOrderInvoiceBusinessRatesIsLoading
          }
          error={
            addOrderInvoiceBusinessRatesError ||
            updateOrderInvoiceBusinessRatesError ||
            deleteOrderInvoiceBusinessRatesError
          }
        />
      )}
      {showConfirmDialog && (
        <ConfirmDialog
          isOpen={showConfirmDialog}
          handleClose={() => setShowConfirmDialog(false)}
          title="Desvincular fondo"
          loading={deleteOrderInvoiceFundIsLoading}
          error={deleteOrderInvoiceFundError}
          message={
            <>
              ¿Seguro deseas desvincular el fondo para la factura
              <Box fontWeight="fontWeightBold">ID {orderInvoiceId}?</Box>
            </>
          }
          buttonOnAccept={handleSubmitUnlinkFundDialog}
          buttonLabel="Desvincular"
        />
      )}
      <Card variant="white" padding="40px xl">
        <Grid container direction="column">
          {countryFeatures.orderActions.showBusinessRates && (
            <Grid item xs={12} className={classes.pymeRates}>
              <RatesResume
                title="Plazos y tasas de la pyme"
                fetchingRates={fetchOrderInvoiceBusinessRatesIsLoading}
                data={
                  orderInvoiceBusinessRates.length > 0
                    ? [
                        {
                          label: 'Porcentaje anticipo',
                          data: orderSummary?.advancePercentage,
                          type: 'percentage',
                        },
                        {
                          label: 'Períodos y tasas',
                          data: (
                            <Can
                              perform={INVOICES_EDIT_RATES_PERFORM}
                              yes={() => (
                                <RatesStepper
                                  fundRates={orderInvoiceBusinessRates}
                                  handleClickAction={handleEditBusinessRate}
                                  handleAddFundRateForm={() =>
                                    setShowBusinessRateFormDialog(true)
                                  }
                                  classColor={classes.paper}
                                  perform={ORDERS_FUND_RATES_PERFORM}
                                  disabled={
                                    orderInvoiceStatus !== STATUS_ACTIVE &&
                                    orderInvoiceStatus !== STATUS_REJECTED &&
                                    orderStatus !== STATUS_ACTIVE &&
                                    orderStatus !== STATUS_REJECTED
                                  }
                                />
                              )}
                              no={() => (
                                <RatesStepper
                                  fundRates={orderInvoiceBusinessRates}
                                  classColor={classes.paper}
                                  disabled
                                />
                              )}
                            />
                          ),
                        },
                      ]
                    : []
                }
                noDataComponent={
                  <Grid item xs={12} className={classes.containerLinkFound}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.messageLinkFound}
                    >
                      <Box fontStyle="italic">
                        No hay información disponible
                      </Box>
                    </Typography>
                  </Grid>
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RatesResume
              title="Plazos y tasas del fondo"
              data={
                orderInvoiceFund
                  ? [
                      {
                        label: 'Fondo',
                        data: fundName,
                      },
                      {
                        label: 'Porcentaje anticipo',
                        data: advancePercentage,
                        type: 'percentage',
                      },
                      {
                        label: 'Períodos y tasas',
                        data: (
                          <RatesStepper
                            fundRates={orderInvoiceFundRates.filter(
                              oifr =>
                                oifr.orderInvoiceFundId === orderInvoiceFundId
                            )}
                            handleClickAction={handleEditFundRate}
                            handleAddFundRateForm={() =>
                              setShowFundRateFormDialog(true)
                            }
                            classColor={classes.paper}
                            perform={ORDERS_FUND_RATES_PERFORM}
                          />
                        ),
                      },
                    ]
                  : []
              }
              button={
                orderInvoiceFund ? (
                  <Can
                    perform={ORDERS_UNLINK_FUND_PERFORM}
                    yes={() => (
                      <Button
                        onClick={() => setShowConfirmDialog(true)}
                        startIcon={<LinkOffIcon />}
                        variant="text"
                        color="primary"
                      >
                        Desvincular fondo
                      </Button>
                    )}
                  />
                ) : null
              }
              noDataComponent={
                <Grid item xs={12} className={classes.containerLinkFound}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.messageLinkFound}
                  >
                    <Box fontStyle="italic">
                      {invoiceStatus !== STATUS_REJECTED
                        ? 'Aún no se asigna un fondo.'
                        : 'Operación rechazada, no es posible vincular fondo.'}
                    </Box>
                  </Typography>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

InvoiceDetail.propTypes = {
  orderInvoice: PropTypes.objectOf(Object).isRequired,
  handleShowLoader: PropTypes.func.isRequired,
  orderInvoiceFund: PropTypes.shape({
    advancePercentage: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    fundId: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect()(InvoiceDetail);
