import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Menu, IconButton } from '@material-ui/core';
import MaterialTable from 'material-table';

import { checkAuth } from '../../helpers/validation/auth';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import { dateFormatter } from '../../helpers/DateUtils';
import { CREDITS_EDIT_FEE_PERFORM } from '../../helpers/performsType';
import {
  addPartialPayment,
  deletePartialPayment,
  updatePartialPayment,
} from '../../actions/partialPaymentsActions';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import MenuItem from '../elements/MenuItem';
import {
  Edit as EditIcon,
  More as MoreIcon,
  Delete as DeleteIcon,
} from '../icons';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import PartialPaymentsDialog from '../dialogs/PartialPaymentsDialog';

const PaymentsTable = ({ handleChangePage, creditId }) => {
  const dispatch = useDispatch();

  const {
    partialPayments,
    fetchPartialPaymentsIsLoading,
    updatePartialPaymentIsLoading,
    partialPaymentUpdated,
    updatePartialPaymentError,
    deletePartialPaymentIsLoading,
    deletePaymentError,
    partialPaymentsPagination,
  } = useSelector(state => state.partialPayments);
  const { user, rules } = useSelector(state => state.auth);
  const { roles } = user;
  const { country } = useSelector(state => state.config);

  const [showDeletePaymentDialog, setshowDeletePaymentDialog] = useState(false);
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (partialPaymentUpdated) {
      setShowUpdatePaymentDialog(false);
    }
  }, [partialPaymentUpdated]);

  useEffect(() => {
    if (!deletePartialPaymentIsLoading) {
      setshowDeletePaymentDialog(false);
    }
  }, [deletePartialPaymentIsLoading]);

  const checkRole = checkAuth(roles, CREDITS_EDIT_FEE_PERFORM, rules);

  const handleOnDeletePayment = paymentId => {
    dispatch(
      deletePartialPayment('credit', creditId, { partialPaymentId: paymentId })
    );
  };

  const columns = [
    {
      title: 'ID Pago',
      field: 'id',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {rowData.id}
          </Typography>
        );
      },
    },
    {
      title: 'Monto total',
      field: 'amount',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.amount}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Fecha',
      field: 'paymentDate',
      type: 'date',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(rowData.paymentDate) || '-'}
          </Typography>
        );
      },
    },
    {
      width: '50px',
      render: rowData => {
        return (
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedPayment(rowData);
            }}
          >
            <MoreIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Grid container item xs={12}>
      <MaterialTable
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        columns={columns}
        data={partialPayments}
        isLoading={fetchPartialPaymentsIsLoading}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(1, rowsPerPage)}
        localization={{
          body: {
            emptyDataSourceMessage: 'No tenemos pagos para mostrar',
          },
          pagination: {
            labelRowsPerPage: 'Pagos por página',
            labelDisplayedRows: '{count} elementos totales',
          },
        }}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onChangePage={(e, page) => {
                // eslint-disable-next-line react/prop-types
                handleChangePage(Number(page + 1), props.rowsPerPage);
              }}
              count={partialPaymentsPagination?.totalData}
              page={partialPaymentsPagination?.page - 1}
            />
          ),
        }}
        options={{
          filtering: false,
          search: false,
          toolbar: false,
          showTitle: false,
          sorting: false,
          pageSize: partialPaymentsPagination?.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
          emptyRowsWhenPaging: false,
        }}
      />

      {showDeletePaymentDialog && (
        <ConfirmDialog
          isOpen={showDeletePaymentDialog}
          handleClose={() => setshowDeletePaymentDialog(false)}
          buttonLabel="Eliminar"
          loading={deletePartialPaymentIsLoading}
          buttonOnAccept={() => handleOnDeletePayment(selectedPayment.id)}
          title="¿Eliminar pago?"
          message="Al eliminar pago este desaparecerá del historial permanentemente y no habrá forma de recuperarlo."
          error={deletePaymentError}
        />
      )}

      {showUpdatePaymentDialog && (
        <PartialPaymentsDialog
          open={showUpdatePaymentDialog}
          handleCloseDialog={() => setShowUpdatePaymentDialog(false)}
          selectedPayment={selectedPayment}
          handleSubmit={formData =>
            selectedPayment.id
              ? dispatch(
                  updatePartialPayment(
                    'credit',
                    creditId,
                    selectedPayment?.id,
                    formData
                  )
                )
              : dispatch(addPartialPayment('credit', creditId, formData))
          }
          isLoading={updatePartialPaymentIsLoading}
          error={updatePartialPaymentError}
        />
      )}

      <Menu
        id="menu-credit-fees"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          icon={<EditIcon />}
          disabled={!checkRole}
          onClick={() => {
            setShowUpdatePaymentDialog(true);
            setAnchorEl(null);
          }}
        >
          Editar pago
        </MenuItem>
        <MenuItem
          icon={<DeleteIcon />}
          disabled={!checkRole}
          onClick={() => {
            setshowDeletePaymentDialog(true);
            setAnchorEl(null);
          }}
        >
          Eliminar pago
        </MenuItem>
      </Menu>
    </Grid>
  );
};

PaymentsTable.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  creditId: PropTypes.number.isRequired,
};

export default PaymentsTable;
