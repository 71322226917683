import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccountBalanceOutlined } from '@material-ui/icons';
import { Box, Grid, Radio } from '@material-ui/core';

import { useSelector } from 'react-redux';
import BaseDrawer from '../../../../commons/components/BaseDrawer';

import { Text } from '../../../../commons/formatters';

import LoadingButton from '../../../../../components/elements/LoadingButton';
import { useFetchBankAccounts } from '../../../infrastructure/store';
import {
  BankAccountContainer,
  CustomFormControlLabel,
  AddBankAccountButtonContainer,
  SubmitButtonContainer,
  SubtitleContainer,
} from './styles';
import BankAccountSelectorDrawerSkeleton from './BankAccountSelectorDrawerSkeleton';
import { Link } from '../../../../commons/components';
import Add from '../../../../../components/icons/Add';
import BankAccountVerificationIcon from '../../../../commons/components/BankAccountVerificationIcon';
import { COUNTRY_CODE_CL } from '../../../../../helpers/Constants';

const BankAccountSelectorDrawer = ({
  open,
  handleClose,
  assignedBankAccountId,
  supplierPaymentsId,
  handleSubmit,
  bankAccountIsAssigning,
  handleShowAddBankAccountDrawer,
}) => {
  const { country } = useSelector(state => state.config);
  const isCl = country === COUNTRY_CODE_CL;
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(
    assignedBankAccountId
  );

  const {
    bankAccounts: supplierBankAccounts,
    isLoading: bankAccountsIsFetching,
  } = useFetchBankAccounts('supplier', supplierPaymentsId);

  const isLoading = bankAccountIsAssigning || bankAccountsIsFetching;

  const handleChange = e => {
    setSelectedBankAccountId(+e.target.value);
  };

  return (
    <BaseDrawer
      title="Seleccionar cuenta bancaria"
      titleIcon={<AccountBalanceOutlined />}
      open={open}
      handleClose={handleClose}
      width={488}
    >
      <SubtitleContainer>
        <Text variant="subtitle1">
          Seleciona la cuenta para utilizar con este proveedor para la
          operación.
        </Text>
      </SubtitleContainer>

      <AddBankAccountButtonContainer container justifyContent="flex-end">
        <Grid item>
          <Link
            variant="subtitle1"
            icon={<Add />}
            handler={handleShowAddBankAccountDrawer}
            fontWeight="medium"
          >
            Agregar una cuenta
          </Link>
        </Grid>
      </AddBankAccountButtonContainer>

      {isLoading && <BankAccountSelectorDrawerSkeleton />}

      {!isLoading &&
        !supplierBankAccounts?.length &&
        'No existen cuentas bancarias para este proveedor'}

      {!isLoading &&
        supplierBankAccounts?.map(bankAccount => (
          <BankAccountContainer
            container
            display="flex"
            justifyContent="space-between"
          >
            <Box item display="flex" alignItems="center">
              <CustomFormControlLabel
                style={{ marginRight: 0, marginBottom: 0 }}
                value={bankAccount.id}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    checked={selectedBankAccountId === bankAccount.id}
                    onChange={handleChange}
                  />
                }
                label={<Text variant="subtitle1">{bankAccount.bankName}</Text>}
              />
            </Box>
            <Box item display="flex" alignItems="center">
              <Box mr={3}>
                <Text variant="subtitle1">{bankAccount.accountNumber}</Text>
              </Box>
              {!isCl ? (
                <BankAccountVerificationIcon
                  verificationStatus={bankAccount.verificationStatus}
                />
              ) : null}
            </Box>
          </BankAccountContainer>
        ))}

      {supplierBankAccounts?.length > 0 && (
        <SubmitButtonContainer item xs={12}>
          <LoadingButton
            onClick={() => handleSubmit(selectedBankAccountId)}
            variant="contained"
            color="primary"
            disabled={
              isLoading || assignedBankAccountId === selectedBankAccountId
            }
            isLoading={isLoading}
            fullWidth
          >
            Guardar
          </LoadingButton>
        </SubmitButtonContainer>
      )}
    </BaseDrawer>
  );
};

BankAccountSelectorDrawer.defaultProps = {
  assignedBankAccountId: undefined,
};

BankAccountSelectorDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  bankAccountIsAssigning: PropTypes.bool.isRequired,
  supplierPaymentsId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  assignedBankAccountId: PropTypes.number,
  handleShowAddBankAccountDrawer: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export { BankAccountSelectorDrawer };
