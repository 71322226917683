import { useQuery } from 'react-query';

import { fetchOrderTotals } from '../services';

const useOrderTotals = () => {
  const { isLoading, data, refetch } = useQuery(
    'getOrderTotals',
    () => fetchOrderTotals(),
    { refetchOnWindowFocus: false }
  );

  const { data: orderTotals } = data || { data: [] };

  return {
    orderTotals,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export default useOrderTotals;
