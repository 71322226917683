import React from 'react';
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

class CheckboxValidator extends ValidatorComponent {
  renderValidatorComponent() {
    /* eslint-disable no-unused-vars */
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      value,
      label,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    return (
      <>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              ref={r => {
                this.input = r;
              }}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              indeterminateIcon={<CircleUnchecked />}
            />
          }
        />
        {!isValid && (
          <FormHelperText variant="filled" className="Mui-error" align="left">
            {this.getErrorMessage()}
          </FormHelperText>
        )}
      </>
    );
  }
}

export default CheckboxValidator;
