import { toList } from '../../utils';
import {
  BO_STATUS_FILTER_OPTIONS,
  BUSINESS_STATUS,
} from '../../constants/main';
import { OPERATION_TYPE } from '../../constants/operations';

export const discountOpts = [
  {
    label: 'Con Descuento',
    value: true,
  },
  {
    label: 'Sin Descuento',
    value: false,
  },
];
export const invoiceStatusOpts = toList(BO_STATUS_FILTER_OPTIONS);
export const businessStatusOpts = toList(BUSINESS_STATUS);
export const operationsOpts = toList(OPERATION_TYPE);
