export const wsConnect = () => ({ type: 'WS_CONNECT' });
export const startCession = token => ({
  type: 'CESSION_START',
  token,
});
export const cessionUpdate = payload => ({
  type: 'CESSION_UPDATE',
  payload,
});
export const orderfacturaUpdate = payload => ({
  type: 'WS_UPDATE_INVOICE',
  payload,
});
export const verifiedOrderfactura = payload => ({
  type: 'VERIFY_INVOICE',
  payload,
});
export const updateFactura = payload => ({
  type: 'WS_UPDATE_FACTURA',
  payload,
});
export const updateBulkFactura = payload => ({
  type: 'WS_UPDATE_BULK_INVOICES',
  payload,
});
export const registerRole = payload => ({
  type: 'WS_REGISTER_ROLE',
  payload,
});
export const updateRole = payload => ({
  type: 'WS_UPDATE_ROLE',
  payload,
});
export const disableRole = payload => ({
  type: 'DISABLE_ROLE_SUCCESS',
  payload,
});
export const wsTaxScrapeUpdated = payload => ({
  type: 'WS_TAX_SCRAPE_UPDATED_STORE',
  payload,
});
export const wsConnected = host => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = host => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = host => ({ type: 'WS_DISCONNECTED', host });
