import * as Types from './payroll.types';

export const pickReceiver = newState => {
  return dispatch => dispatch(Types.PICK_RECEIVER.SUCCESS.action(newState));
};

export const deleteReceivers = newState => {
  return dispatch => dispatch(Types.DELETE_RECEIVERS.SUCCESS.action(newState));
};

export const updatePayrollSelector = newState => {
  return dispatch =>
    dispatch(Types.UPDATE_PAYROLL_SELECTOR.SUCCESS.action(newState));
};

export const cleanPayrollSelector = () => {
  return dispatch => dispatch(Types.CLEAN_PAYROLL_SELECTOR.SUCCESS.action());
};

export const pickDraftReceiver = newState => {
  return dispatch =>
    dispatch(Types.PICK_DRAFT_RECEIVER.SUCCESS.action(newState));
};

export const deleteDraftReceivers = () => {
  return dispatch => dispatch(Types.DELETE_DRAFT_RECEIVERS.SUCCESS.action());
};

export const cleanDraftPayrollSelector = () => {
  return dispatch =>
    dispatch(Types.CLEAN_DRAFT_PAYROLL_SELECTOR.SUCCESS.action());
};

export const selectFund = fund => {
  return dispatch => dispatch(Types.SELECT_FUND.SUCCESS.action(fund));
};

export const startSimulation = () => {
  return dispatch => dispatch(Types.SET_SIMULATION.START.action());
};

export const setSimulation = simulation => {
  return dispatch => dispatch(Types.SET_SIMULATION.SUCCESS.action(simulation));
};

export const cleanSimulation = () => {
  return dispatch => dispatch(Types.CLEAN_SIMULATION.SUCCESS.action());
};

export const removeSelectedFund = () => {
  return dispatch => dispatch(Types.REMOVE_SELECTED_FUND.SUCCESS.action());
};
