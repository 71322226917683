import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, CircularProgress, Grid } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import { getTaxFolder } from '../../actions/taxfolderActions';
import { Download } from '../icons';
import LoadingButton from '../elements/LoadingButton';
import { downloadFile } from '../../helpers/fileHandling';

const useStyles = makeStyles({
  buttonSpacing: {
    marginBottom: 15,
  },
});

const TaxFolderDialog = ({ open, handleCloseDialog, businessId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    htmlTaxFolderIsLoading,
    htmlTaxFolder,
    pdfTaxFolderIsLoading,
    pdfTaxFolder,
  } = useSelector(state => state.taxfolder);

  useEffect(() => {
    if (!htmlTaxFolder) {
      dispatch(getTaxFolder(businessId, 'html'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch]);

  useEffect(() => {
    if (!pdfTaxFolder) {
      dispatch(getTaxFolder(businessId, 'pdf'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch]);

  return (
    <BaseDialog
      title="Carpeta tributaria"
      size="lg"
      isOpen={open}
      handleClose={handleCloseDialog}
      disableBackdropClick={false}
    >
      <Grid item xs={12} align="right">
        <LoadingButton
          color="primary"
          variant="outlined"
          startIcon={<Download />}
          isLoading={pdfTaxFolderIsLoading}
          className={classes.buttonSpacing}
          disabled={pdfTaxFolderIsLoading || !pdfTaxFolder}
          onClick={() => {
            downloadFile(pdfTaxFolder.data, 'Carpeta Tributaria.pdf');
          }}
        >
          Descargar Pdf
        </LoadingButton>
      </Grid>
      {htmlTaxFolderIsLoading ? (
        <Grid item align="center">
          <CircularProgress size={25} />
        </Grid>
      ) : (
        <Grid
          align="center"
          dangerouslySetInnerHTML={{ __html: htmlTaxFolder }}
        />
      )}
    </BaseDialog>
  );
};

TaxFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  businessId: PropTypes.number.isRequired,
};

export default TaxFolderDialog;
