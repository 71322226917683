import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SearchFilter } from '../../../commons/components';

const SearchField = memo(({ filters, activeFilter, handler }) => {
  const { select, input } = activeFilter;

  return (
    <SearchFilter
      placeholder="Buscar operaciones..."
      handleChangeInput={handler}
      selectValue={select}
      inputValue={input}
      options={filters}
    />
  );
});

SearchField.propTypes = {
  filters: PropTypes.arrayOf(String).isRequired,
  activeFilter: PropTypes.shape({
    select: PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    }),
    input: PropTypes.string,
  }).isRequired,
  handler: PropTypes.func.isRequired,
};

export default SearchField;
