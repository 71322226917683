import { getTermDate, purgeNestedObj } from '../../../../commons/utils';

export const fundMap = {
  toDomain: res => ({
    entityFeatures: res.EntityFeatures.map(ef => ({ name: ef.Feature.name })),
    id: res.id,
    interestFormula: res.interestFormula,
    name: res.name,
  }),
};

export const recommendedRateMap = {
  toDomain: res => {
    return res.reduce((acc, receiver) => {
      acc[receiver.receiverIdentifier] = { rate: receiver.rate };

      return acc;
    }, {});
  },
};

export const customExpirationDateMap = {
  toDomain: res => {
    return res.reduce((acc, doc) => {
      acc[doc.invoiceId] = { expirationDate: doc.expirationDate };

      return acc;
    }, {});
  },
};

const normalizeDocuments = documents =>
  documents.reduce((accu, doc) => {
    const {
      advancePercentage,
      id,
      issuer,
      rate,
      expirationDate,
      effectiveFundCostRate,
      capitalCostRate,
    } = doc;

    return {
      ...accu,
      [id]: purgeNestedObj({
        advancePercentage,
        issuerDebtServiceAmount: issuer?.debtServiceAmount,
        rate,
        effectiveFundCostRate,
        capitalCostRate,
        term: expirationDate,
      }),
    };
  }, {});

const parseDataForSimulation = (interestFormula, receivers) => {
  return {
    interestFormula,
    receivers: Object.keys(receivers).reduce((acc, receiverIdentifier) => {
      const currentReceiver = receivers[receiverIdentifier];
      const normalizedDocuments = normalizeDocuments(currentReceiver.value);
      acc[receiverIdentifier] = {
        receiverDebtServiceAmount: currentReceiver?.receiverDebtServiceAmount
          ? currentReceiver?.receiverDebtServiceAmount
          : undefined,
        invoices: normalizedDocuments,
      };

      return acc;
    }, {}),
  };
};

export const simulationMap = {
  toDomain: ({ simulation, receivers }) => {
    return {
      simulation: {
        averageRate: simulation.averageRate,
        averageCapitalCostRate: simulation.averageCapitalCostRate,
        averageEffectiveFundCostRate: simulation.averageEffectiveFundCostRate,
        averageTerm: simulation.averageTerm,
        interest: simulation.interest,
        effectiveFundCostInterest: simulation.effectiveFundCostInterest,
        totalPayrollInvoiceAmount: simulation.totalPayrollInvoiceAmount,
      },
      receivers: Object.keys(receivers).reduce((acc, receiverIdentifier) => {
        const currentReceiver = receivers[receiverIdentifier];

        acc[receiverIdentifier] = {
          acknowledgementPercentage: currentReceiver.acknowledgementPercentage,
          amount: currentReceiver.amount,
          hasPdf: currentReceiver.hasPdfAccess,
          issuersNumber: currentReceiver.issuersNumber,
          rate: currentReceiver.avgRate,
          receiverDebtServiceAmount: currentReceiver.debtServiceAmount,
          score: currentReceiver.score,
          term: currentReceiver.avgTerm,
        };

        return acc;
      }, {}),
    };
  },
  toPersistence: ({ interestFormula, receivers }) =>
    parseDataForSimulation(interestFormula, receivers),
};

export const payrollDraftMap = {
  toPersistence: ({ interestFormula, receivers }) =>
    parseDataForSimulation(interestFormula, receivers),
};

export const sendPayrollMap = {
  toPersistence: ({ fundId, countryId, payrollSelector }) => {
    const documents = Object.keys(payrollSelector).flatMap(receiverIdentifier =>
      payrollSelector[receiverIdentifier].value.map(
        ({
          advancePercentage,
          rate,
          id,
          issuer,
          expirationDate,
          effectiveFundCostRate,
          capitalCostRate,
        }) =>
          purgeNestedObj({
            advancePercentage: advancePercentage ?? 100,
            expirationDate: getTermDate(expirationDate),
            invoiceId: id,
            issuerDebtServiceAmount: issuer?.debtServiceAmount,
            rate,
            effectiveFundCostRate,
            capitalCostRate,
            receiverDebtServiceAmount:
              payrollSelector[receiverIdentifier].receiverDebtServiceAmount ||
              undefined,
          })
      )
    );

    return {
      fundId,
      countryId,
      type: 'sales',
      data: documents,
    };
  },
  toDomain: res => {
    return {
      payrollId: res.id,
    };
  },
};

export const pdfSummaryMap = {
  toDomain: res => {
    return {
      payrollInvoicesNumber: res.payrollInvoices.length,
      payrollId: res.payrollId,
      payrollInvoicesWithPdfNumber: res.payrollInvoicesWithPdf.length,
      payrollInvoicesWithoutPdfNumber: res.payrollInvoicesWithoutPdf.length,
    };
  },
};
