import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Down = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M18.295 9.70504L16.885 8.29504L12.295 12.875L7.705 8.29504L6.295 9.70504L12.295 15.705L18.295 9.70504Z" />
    </SvgIcon>
  );
};

export default Down;
