import { ACTION_CHANGE_TYPE } from '../Constants';

export const checkAuth = (roles = [], action, rules = {}, data = null) => {
  const permissions = {};
  const { perform = {} } = rules;
  roles.forEach(role => {
    const { rolename, isSupervisor } = role;
    let ruleRole = rolename;
    if (isSupervisor) {
      ruleRole = `${ruleRole}Supervisor`;
    }
    const { static: staticRule = [] } = perform[ruleRole];
    let { static: staticPermission = [] } = permissions;
    staticPermission = [...staticPermission, ...staticRule];
    permissions.static = staticPermission;
  });
  if (!Object.entries(permissions).length || !permissions.static.length) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }
    return permissionCondition(data);
  }
  return false;
};
export const checkStatusAuth = (status, type, rules) => {
  const { status: statusRules = [] } = rules;
  const isAuthToChange = statusRules.some(
    ({ name, statusType, actionType }) =>
      name === status &&
      statusType === type &&
      actionType === ACTION_CHANGE_TYPE
  );
  return isAuthToChange;
};
