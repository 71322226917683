/* eslint-disable react/jsx-props-no-spreading */
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import bankIcon from '../../assets/bank.svg';
import xepelinBankIcon from '../../assets/xepelin-bank.svg';
import { fetchBanks, resetBankAccountHolder } from '../../actions/bankActions';
import {
  ACCOUNT_TYPES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../helpers/Constants';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import AlertForm from '../elements/AlertForm';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import { settings } from '../../config/settings';
import { features } from '../../config/features';
import { t13s } from '../../translationKeys';
import { BUSINESS_UPDATE_BANK_ACCOUNT_IDENTIFIER_PERFORM } from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  iconSelect: {
    marginRight: 10,
  },
  button: {
    marginTop: 10,
  },
});

const BankAccountDialog = ({
  bankAccount,
  open,
  onClose,
  onSubmit,
  isRegister,
  isXepelin = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { identifier, name } = useSelector(state => state.business.business);
  const {
    banks,
    accountHolder: fetchedAccountHolder,
    disabledAccountHolder,
    registeringBankAccount,
    bankAccountRegistered,
    editingBankAccount,
    bankAccountEdited,
    bankAccountError,
  } = useSelector(state => state.banks);
  const { country } = useSelector(state => state.config);
  const {
    rules,
    user: { roles },
  } = useSelector(state => state.auth);
  const hasUpdateBankAccountIdentifier = checkAuth(
    roles,
    BUSINESS_UPDATE_BANK_ACCOUNT_IDENTIFIER_PERFORM,
    rules
  );
  // TODO: set this to work with every country

  const initialState = {
    bankId: '',
    accountType:
      country === COUNTRY_CODE_MX ? ACCOUNT_TYPES[country][0].value : '',
    accountNumber: '',
    sourceIdentifier:
      country === COUNTRY_CODE_MX
        ? bankAccount?.sourceIdentifier || identifier || ''
        : identifier || '',
    accountHolder:
      country === COUNTRY_CODE_MX ? bankAccount?.name || name || '' : '',
    accountEmail: '',
    alias: '',
    currency: settings[country].currencyCode,
    isXepelin,
    ...bankAccount,
  };

  const [bankAccountData, setBankAccountData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    onClose();
    dispatch(resetBankAccountHolder());
    setBankAccountData(initialState);
  };
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(editingBankAccount || registeringBankAccount);
  }, [editingBankAccount, registeringBankAccount]);

  useEffect(() => {
    if (bankAccount && Object.keys(bankAccount).length) {
      setBankAccountData(bankAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccount]);

  // TODO: Add validation rules to MX CLABE (stronger CLABE validation) and MX RFC (if necessary)
  useEffect(() => {
    dispatch(fetchBanks());
    ValidatorForm.addValidationRule('validateIdentifier', value => {
      return validateIdentifier(value, country);
    });
    ValidatorForm.addValidationRule('validateAccountNumber', value => {
      if (country === COUNTRY_CODE_CL) return true;
      const regex = /^([0-9]{18})$/;
      return regex.test(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      fetchedAccountHolder &&
      fetchedAccountHolder !== '' &&
      bankAccountData?.sourceIdentifier
    ) {
      setBankAccountData({
        ...bankAccountData,
        accountHolder: fetchedAccountHolder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedAccountHolder]);

  useEffect(() => {
    if (bankAccountRegistered || bankAccountEdited) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [bankAccountRegistered, bankAccountEdited]);

  const handleInputChange = (field, value) => {
    let finalValue = value;
    if (field === 'accountNumber') {
      finalValue = value.replace(/[^0-9]/g, '');
    }

    setBankAccountData({
      ...bankAccountData,
      [field]: finalValue,
    });
  };

  // Get bank code from CLABE and set it as selected .MX ONLY
  useEffect(() => {
    if (country === COUNTRY_CODE_MX) {
      if (
        bankAccountData?.accountNumber?.toString().length >= 3 &&
        !bankAccountData?.bankId
      ) {
        const clabeBankCode = bankAccountData.accountNumber
          .toString()
          .substring(0, 3);
        const selectedBank = banks.find(bank => {
          return bank.code === clabeBankCode;
        });

        if (!selectedBank) return;

        setBankAccountData({ ...bankAccountData, bankId: selectedBank.id });
      }
      if (
        bankAccountData?.accountNumber?.toString().length < 3 &&
        bankAccountData.bankId
      ) {
        setBankAccountData({ ...bankAccountData, bankId: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountData.accountNumber]);

  const {
    bankId,
    accountType,
    accountNumber,
    sourceIdentifier,
    accountHolder,
    accountEmail,
    alias,
    currency,
  } = bankAccountData;

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  // TODO: move this to settingsCL and settingsMX
  const currencies = {
    CL: [
      {
        value: settings[country].currencyCode,
        label: settings[country].currencyCode,
      },
    ],
    MX: [
      {
        value: settings[country].currencyCode,
        label: settings[country].currencyCode,
      },
      { value: 'USD', label: 'USD' },
    ],
  };

  const handleSubmit = () => {
    onSubmit(bankAccountData);
  };

  const prefixTitle = isRegister ? 'Registrar' : 'Editar';
  const bankType = isXepelin ? 'cuenta Xepelin' : 'cuenta bancaria';
  const title = `${prefixTitle} ${bankType}`;
  const topIcon = isXepelin ? xepelinBankIcon : bankIcon;

  const disabledIdentifier = bankAccount && !hasUpdateBankAccountIdentifier;
  const disabledAccountName =
    disabledAccountHolder && !hasUpdateBankAccountIdentifier && !isRegister;

  const bankSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Banco"
      name="bankId"
      value={bankId}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      disabled={isLoading}
    >
      {banks?.map(bank => (
        <MenuItem value={bank.id} key={bank.name}>
          {bank.name}
        </MenuItem>
      ))}
    </SelectValidator>
  );

  const accountTypeSelectField = (
    <SelectValidator
      fullWidth
      variant="outlined"
      label="Tipo de cuenta"
      name="accountType"
      value={accountType}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
      disabled={isLoading}
    >
      {ACCOUNT_TYPES[country].map(type => {
        return <MenuItem value={type.value}>{type.label}</MenuItem>;
      })}
    </SelectValidator>
  );

  const accountNumberField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BANK_ACCOUNT_IDENTIFIER)}
      type="text"
      name="accountNumber"
      value={accountNumber}
      validators={['required', 'validateAccountNumber']}
      errorMessages={['Campo requerido', t(t13s.INPUT_ERROR.ACCOUNT_NUMBER)]}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      disabled={isLoading}
    />
  );

  const sourceIdentifierField = (
    <TextValidator
      fullWidth
      variant="outlined"
      label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
      type="text"
      name="sourceIdentifier"
      disabled={disabledIdentifier || isLoading}
      value={sourceIdentifier}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['validateIdentifier']}
      errorMessages={[t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER)]}
    />
  );

  const accountHolderField = (
    <TextValidator
      fullWidth
      variant="outlined"
      disabled={disabledAccountName || isLoading}
      label="Nombre o razón social"
      type="text"
      name="accountHolder"
      value={accountHolder}
      onChange={e => handleInputChange(e.target.name, e.target.value)}
      validators={['required']}
      errorMessages={['Campo requerido']}
    />
  );

  let currencySelectField = '';
  if (features[country].bankAccountsActions.showCurrencyInput) {
    currencySelectField = (
      <SelectValidator
        fullWidth
        variant="outlined"
        label="Divisa"
        type="text"
        name="currency"
        value={currency}
        onChange={e => handleInputChange(e.target.name, e.target.value)}
        validators={['required']}
        errorMessages={['Campo requerido']}
        disabled={isLoading}
      >
        {currencies[country].map(currency => (
          <MenuItem value={currency.value}>{currency.label}</MenuItem>
        ))}
      </SelectValidator>
    );
  }

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleClose}
      title={title}
      topIcon={topIcon}
      topIconWidth={114}
    >
      <ValidatorForm onSubmit={handleSubmit} className={classes.rootForm}>
        {country === COUNTRY_CODE_CL && (
          <>
            {bankSelectField}
            {accountTypeSelectField}
            {accountNumberField}
            {sourceIdentifierField}
            {accountHolderField}
          </>
        )}

        {country === COUNTRY_CODE_MX && (
          <>
            {sourceIdentifierField}
            {accountNumberField}
            {accountHolderField}
            {bankSelectField}
            <Grid container direction="row" spacing={2}>
              <Grid item xs={9}>
                {accountTypeSelectField}
              </Grid>
              <Grid item xs={3}>
                {currencySelectField}
              </Grid>
            </Grid>
          </>
        )}
        <TextValidator
          fullWidth
          variant="outlined"
          label="Email"
          name="accountEmail"
          value={accountEmail}
          onChange={e => handleInputChange(e.target.name, e.target.value)}
          validators={['required', 'isEmail']}
          errorMessages={['Campo requerido', 'Email incorrecto']}
          disabled={isLoading}
        />
        <TextValidator
          fullWidth
          variant="outlined"
          label="Alias (opcional)"
          type="text"
          name="alias"
          value={alias}
          onChange={e => handleInputChange(e.target.name, e.target.value)}
          disabled={isLoading}
        />
        {bankAccountError && (
          <AlertForm message={bankAccountError} variant="error" />
        )}
        <Grid className={classes.button}>
          <CheckButton
            check={checked}
            handleCheck={handleCheckboxChange}
            loading={isLoading}
            labelButton={isRegister ? 'Agregar cuenta' : 'Actualizar cuenta'}
            disabled={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

BankAccountDialog.defaultProps = {
  bankAccount: null,
  isRegister: true,
  isXepelin: false,
};

BankAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bankAccount: PropTypes.objectOf(Object),
  isRegister: PropTypes.bool,
  isXepelin: PropTypes.bool,
};

export default BankAccountDialog;
