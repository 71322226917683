import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { TitleGrid } from './styles';
import { Text } from '../../../../../commons/formatters';

const AttributeIcon = ({ title, value }) => {
  const icon = value ? (
    <Box color="success.main">
      <CheckIcon fontSize="small" />
    </Box>
  ) : (
    <CloseIcon color="error" fontSize="small" />
  );

  return (
    <Grid container direction="column">
      <TitleGrid item>
        <Text fontWeight="bold" variant="subtitle1">
          {title}
        </Text>
      </TitleGrid>

      <Grid item container>
        {icon}
      </Grid>
    </Grid>
  );
};

AttributeIcon.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default AttributeIcon;
