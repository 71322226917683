import React from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { CollectionsByPayerTabs } from './CollectionsByPayerTable';
import { useTranslation } from 'react-i18next';
import { t13s } from '../../../../translationKeys';

const { TABS } = t13s.LABEL.COLLECTIONS;

type CollectionsByPayerTableHeaderProps = {
  selectedTab: CollectionsByPayerTabs;
  setSelectedTab: (newTab: CollectionsByPayerTabs) => void;
};
const CollectionsByPayerTableHeader = ({
  selectedTab,
  setSelectedTab,
}: CollectionsByPayerTableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Box py={{ xs: '24px' }}>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, value) => setSelectedTab(value)}
          aria-label="Collections by payer tabs"
        >
          <Tab label={t(TABS.DEBT_LIST)} />
          <Tab label={t(TABS.MANAGEMENTS_HYSTORY)} />
        </Tabs>
      </Box>
    </>
  );
};

export default CollectionsByPayerTableHeader;
