import { useQuery } from 'react-query';
import downloadOrderCommentAttachmentService from '../services/downloadOrderCommentAttachment';

const useFetchOrderCommentAttachment = (attachmentId: number) => {
  const { isLoading, isRefetching, isError, isSuccess, data, error } = useQuery(
    ['fetchOrderCommentAttachment'],
    () => downloadOrderCommentAttachmentService({ attachmentId }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
      staleTime: 0,
    }
  );

  return {
    isLoading: isLoading || isRefetching,
    isError,
    isSuccess,
    data,
    error,
  };
};

export default useFetchOrderCommentAttachment;
