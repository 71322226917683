import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { usePayrollHeader } from '../../hooks';
import { MenuSelector } from '../../../commons/components';
import BulkInvoicesDiscountDialog from './BulkInvoicesDiscountDialog';
import LockOperationDialog from './LockOperationDialog';
import SummaryBreadcrumbs from './SummaryBreadcrumbs';
import SummaryTitle from './SummaryTitle';
import SummaryDetails from './SummaryDetails';
import {
  HeaderGrid,
  ButtonGrid,
  BreadcrumbsGrid,
  SummaryTitleGrid,
  OrderCommentsGrid,
} from './styles';
import { useLockOrder } from '../../../inbox/infrastructure/store';
import CreditFormDialog from './CreditFormDialog';

import OrderCommentsIcon from '../../../../AR/commons/components/OrderCommentsIcon';
import OrderCommentsDrawer from './OrderCommentsDrawer';
import OrderResponsiblesIcon from '../../../../AR/commons/components/OrderResponsiblesIcon';
import { OrderResponsiblesDrawer } from '../../../../AR/commons/components/OrderResponsiblesDrawer';

const PayrollHeader = ({ order }) => {
  const {
    menuActions,
    showInvoicesDiscountDialog,
    showCreditFormDialog,
    showLockOperationDialog,
    showOrderCommentsDrawer,
    commentsCount,
    commentsCounterIsLoading,
    hasPendingComments,
    isShowResponsiblesEnabled,
    responsiblesCount,
    responsiblesCounterIsLoading,
    showOrderResponsiblesDrawer,
    handleCloseInvoicesDiscountDialog,
    handleCloseCreditFormDialog,
    handleCloseLockOperationDialog,
    setShowOrderCommentsDrawer,
    setShowOrderResponsiblesDrawer,
  } = usePayrollHeader();
  const { lockOrder, isLockingOrder } = useLockOrder();

  const {
    businessName,
    issuedDate,
    daysPassed,
    businessId,
    id,
    hasCesion,
    countryId,
    agreementName,
    isFinancing,
    paymentType,
    isLocked,
    sudoUser,
    businessIdentifier,
  } = order;

  return (
    <>
      <HeaderGrid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="column">
            <BreadcrumbsGrid item xs={12}>
              <SummaryBreadcrumbs />
            </BreadcrumbsGrid>
            <SummaryTitleGrid item xs={12}>
              <SummaryTitle
                businessName={businessName}
                issuedDate={issuedDate}
                daysPassed={daysPassed}
                orderId={id}
                agreement={agreementName}
                sudoUser={sudoUser}
              />
            </SummaryTitleGrid>
            <Grid item xs={12}>
              <SummaryDetails
                orderId={id}
                businessId={businessId}
                hasCession={hasCesion}
                countryId={countryId}
                isFinancing={isFinancing}
                paymentType={paymentType}
              />
            </Grid>
          </Grid>
        </Grid>
        <ButtonGrid item>
          <Grid container direction="column">
            <OrderCommentsGrid container item justifyContent="flex-end">
              {isShowResponsiblesEnabled && (
                <OrderResponsiblesIcon
                  responsiblesCount={responsiblesCount ?? 0}
                  isLoading={responsiblesCounterIsLoading}
                  handleOpenDrawer={() => setShowOrderResponsiblesDrawer(true)}
                />
              )}
              <OrderCommentsIcon
                hasPendingComments={hasPendingComments}
                commentsCount={commentsCount ?? 0}
                isLoading={commentsCounterIsLoading}
                handleOpenDrawer={() => setShowOrderCommentsDrawer(true)}
              />
            </OrderCommentsGrid>
            <Grid item>
              <MenuSelector
                actions={menuActions}
                menuText="Más acciones"
                disabled={isLocked}
              />
            </Grid>
          </Grid>
        </ButtonGrid>
      </HeaderGrid>

      {showInvoicesDiscountDialog && (
        <BulkInvoicesDiscountDialog
          open={showInvoicesDiscountDialog}
          handleClose={handleCloseInvoicesDiscountDialog}
        />
      )}

      {showCreditFormDialog && (
        <CreditFormDialog
          open={showCreditFormDialog}
          handleClose={handleCloseCreditFormDialog}
        />
      )}

      {showLockOperationDialog && (
        <LockOperationDialog
          open={showLockOperationDialog}
          handleClose={handleCloseLockOperationDialog}
          handleAction={() => lockOrder({ orderId: id })}
          title="Bloquear operación"
          message="Las acciones de esta operación quedarán inhabilitadas en Backoffice. Deseas continuar?"
          actionText="Bloquear"
          isLoading={isLockingOrder}
        />
      )}

      {showOrderCommentsDrawer && (
        <OrderCommentsDrawer
          open={showOrderCommentsDrawer}
          orderId={id}
          handleClose={() => setShowOrderCommentsDrawer(false)}
        />
      )}

      {showOrderResponsiblesDrawer && businessIdentifier && (
        <OrderResponsiblesDrawer
          isOpen={showOrderResponsiblesDrawer}
          businessIdentifier={businessIdentifier}
          handleCloseDrawer={() => setShowOrderResponsiblesDrawer(false)}
        />
      )}
    </>
  );
};

PayrollHeader.propTypes = {
  order: PropTypes.shape({
    businessName: PropTypes.string.isRequired,
    issuedDate: PropTypes.string.isRequired,
    daysPassed: PropTypes.number.isRequired,
    businessId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    hasCesion: PropTypes.bool,
    countryId: PropTypes.string.isRequired,
    agreementName: PropTypes.string,
    isFinancing: PropTypes.oneOf([PropTypes.bool, PropTypes.number]).isRequired,
    paymentType: PropTypes.string.isRequired,
    isLocked: PropTypes.oneOf([PropTypes.bool, PropTypes.number]).isRequired,
    businessIdentifier: PropTypes.string.isRequired,
    sudoUser: PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default PayrollHeader;
