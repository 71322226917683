import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  headerTabsTab: {
    minWidth: '144px',
    textTransform: 'none',
  },
  containerTabs: {
    background: '#fff',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  indicator: {
    height: '3px',
    borderRadius: '10px',
  },
  rightTabs: {
    marginLeft: 'auto',
  },
  actionBarButton: {
    marginLeft: 25,
  },
});
