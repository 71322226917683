import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';

import { useGraphQlMutation } from '../useGraphQlMutation';

import { mutation, Mutation } from './mutation';
import { noop } from '../../utils';
import { TransitionType } from '../../schemas';

type Props = {
  onSuccess?: (entityId: string, workflowId: string) => void;
};

type CreateData = {
  workflowId: string;
  targetId: string;
  currentStateId: string;
  businessIdentifier: string;
  comment: string | null;
  type?: TransitionType;
};

type Variables = {
  dto: Omit<CreateData, 'currentStateId' | 'businessIdentifier'>
  & { originId: string; entityRefId: string; modifiedByUserRef: string };
};

export const useCreateTransitionAction = ({ onSuccess = noop }: Props) => {
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: createTransitionAction,
    loading,
  } = useGraphQlMutation<Variables, Mutation>({
    mutation,
    mutationKey: useCreateTransitionAction.name,
    onError() {
      enqueueSnackbar('Surgió error al momento de cambiar estado', { variant: 'error' });
    },
    onSuccess(response) {
      enqueueSnackbar('Cambio de estado efectuado', { variant: 'success' });
      onSuccess(
        response.createTransitionAction.entityId,
        response.createTransitionAction.workflow.id,
      );
    },
  });

  const create = (data: CreateData) => {
    if (authState?.idToken?.claims.email) {
      createTransitionAction({
        dto: {
          workflowId: data.workflowId,
          targetId: data.targetId,
          originId: data.currentStateId,
          entityRefId: data.businessIdentifier,
          modifiedByUserRef: authState?.idToken?.claims.email,
          comment: data.comment,
          type: data.type,
        },
      });
    }
  };

  return {
    create,
    loading,
  };
};
