import {
  Box,
  Typography,
  styled,
  Divider as MuiDivider,
  Button,
} from '@material-ui/core';

interface CustomTexts {
  customColor?: string;
}

export const RootContainer = styled(Box)({
  width: 354,
  padding: 24,
  borderRadius: 8,
  background: '#fff',
});

export const TitleText = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
});

export const RowText = styled(Typography)({
  fontSize: 14,
  color: '#605C6B',
});

export const RowTextEmpty = styled(Typography)({
  fontSize: 14,
  color: '#605C6B',
  textAlign: 'center',
  fontWeight: 'bold',
});

export const RowTextBold = styled(Typography)(
  ({ customColor = '#44414C' }: CustomTexts) => ({
    fontSize: 14,
    fontWeight: 'bold',
    color: customColor,
  })
);

export const CurrencyRowTextBold = styled(Typography)(
  ({ customColor = '#44414C' }: CustomTexts) => ({
    fontSize: 14,
    fontWeight: 'bold',
    color: customColor,
    marginRight: 5,
  })
);

export const BigText = styled(Typography)(({ customColor = '#000' }: CustomTexts) => ({
  fontSize: 16,
  fontWeight: 'bold',
  color: customColor,
}));

export const CurrencyBigText = styled(Typography)(
  ({ customColor = '#000' }: CustomTexts) => ({
    fontSize: 16,
    fontWeight: 'bold',
    color: customColor,
    marginRight: 5,
  })
);

export const BusinessSegmentText = styled(Typography)({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#605C6B',
});

export const ContainerSection = styled(Box)({
  marginBottom: 20,
});

export const Divider = styled(MuiDivider)({
  marginTop: 20,
});

export const ContainerButtons = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const NewSimulationButton = styled(Button)({
  width: 215,
  height: 32,
});