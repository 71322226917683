import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import verifyDecimals from '../../../../../../helpers/validation/decimals';
import CheckButton from '../../../../../../components/elements/CheckButton';

export const UpdateFundInvoiceIssuerDebtDialog = ({
  isOpen,
  handleCloseDialog,
  description,
  selectedDebt,
  handleSubmit,
  isLoading,
}) => {
  const [debt, setDebt] = useState({});
  const [entityType, setEntityType] = useState('dicom');
  const [checked, setChecked] = useState(false);
  const { country } = useSelector(state => state.config);

  useEffect(() => {
    ValidatorForm.addValidationRule('verifyMaxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  useEffect(() => {
    setEntityType(country === 'CL' ? 'dicom' : 'buro');
  }, [country]);

  useEffect(() => {
    setDebt(selectedDebt);
  }, [selectedDebt]);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={() => {
        handleCloseDialog();
        setChecked(false);
      }}
      title={`Editar ${entityType} emisor`}
      description={description}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm
          onSubmit={() => {
            handleSubmit(debt);
            setChecked(false);
          }}
        >
          <TextValidator
            variant="outlined"
            fullWidth
            label="Monto ($)"
            inputProps={{ min: 0 }}
            type="number"
            name="issuerDebt"
            value={Number.isNaN(debt.issuerDebt) ? '' : debt.issuerDebt}
            aria-describedby="Deuda emisor"
            onChange={e =>
              setDebt({
                ...debt,
                issuerDebt: Number(e.target.value),
              })
            }
            validators={['required', 'isPositive']}
            errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
          />
          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UpdateFundInvoiceIssuerDebtDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  description: '',
};

UpdateFundInvoiceIssuerDebtDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  description: PropTypes.string,
  // Ver bien lo que se pasara para hacer un shape
  selectedDebt: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
