/* eslint-disable no-param-reassign */
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { NONE } from '../../../../helpers/Constants';
import { t13s } from '../../../../translationKeys';
import { updateOrderDetailsService } from '../services';
import { useOrderAttributes } from './useOrderAttributes';
import { useOrderSummary } from './useOrderSummary';

export const useUpdateOrderDetailsSummary = () => {
  const dispatch = useDispatch();
  const { id: orderId } = useParams();
  const { refetch: getOrderSummary } = useOrderSummary(orderId);
  const { refetch: getOrderAttributes } = useOrderAttributes(orderId);
  const { mutate: updateOrderDetails, data: updatedOrder } = useMutation(
    orderData => {
      delete orderData.totalAmount;
      delete orderData.totalAmountCreditNotes;
      orderData.autoRate = NONE;
      return updateOrderDetailsService(orderData);
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.ORDER_UPDATED, {
            variant: 'success',
          })
        );
        getOrderSummary();
        getOrderAttributes();
      },
    }
  );

  return {
    updateOrderDetails,
    updatedOrder,
  };
};

export default useUpdateOrderDetailsSummary;
