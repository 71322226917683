import {
  FETCH_LOGS_START,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAILURE,
} from '../actions/types';

const initialState = {
  historyLogs: [],
  historyLogsError: null,
  historyLogsIsLoading: false,
};

const fetchHistoryLogsStart = state => {
  return {
    ...state,
    historyLogsError: null,
    historyLogsIsLoading: true,
  };
};

const fetchHistoryLogsSuccess = (state, payload) => {
  return {
    ...state,
    historyLogs: payload,
    historyLogsIsLoading: false,
  };
};

const fetchHistoryLogsFailure = (state, payload) => {
  return {
    ...state,
    historyLogs: [],
    historyLogsIsLoading: false,
    historyLogsError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LOGS_START:
      return fetchHistoryLogsStart(state);
    case FETCH_LOGS_SUCCESS:
      return fetchHistoryLogsSuccess(state, payload);
    case FETCH_LOGS_FAILURE:
      return fetchHistoryLogsFailure(state, payload);
    default:
      return state;
  }
};
