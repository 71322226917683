import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const updateOrderCommentsTagsService = async (commentIds, userEmail) => {
  try {
    const { data } = await axios.put(`/api/commenttag/viewed`, {
      commentIds,
      userEmail,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};
// eslint-disable-next-line import/prefer-default-export
export { updateOrderCommentsTagsService };
