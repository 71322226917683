import tracker from '../../tracker';

export const trackOrderUpdated = (orderData, invoices) => {
  const order = { ...orderData, invoices };
  const payload = {
    order,
    businessId: orderData.businessId,
  };
  tracker.event('UPDATE_ORDER_STATUS', payload);
};

export default trackOrderUpdated;
