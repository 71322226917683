import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { settings } from '../../../../../../config/settings';
import {
  useOrderAttributes,
  useUpdateOrderAttribute,
} from '../../../../infrastructure/store';

const useCurrencyUpdateDialog = () => {
  const { id: orderId } = useParams();

  const { country } = useSelector(state => state.config);
  const defaultCurrencyCode = settings[country].currencyCode;
  const { orderAttributes } = useOrderAttributes(orderId);
  const { currency } = orderAttributes;
  const [formCurrency, setFormCurrency] = useState(
    currency ?? defaultCurrencyCode
  );

  const {
    updateOrderAttribute,
    resetOrderAttributeUpdateData,
    orderAttributeIsUpdating,
    updateOrderAttributeError,
    updateOrderAttributeIsSuccess,
  } = useUpdateOrderAttribute();

  const handleSelect = e => {
    setFormCurrency(e.target.value);
  };

  const handleSubmit = () => {
    updateOrderAttribute({ orderId, attribute: { currency: formCurrency } });
  };

  return {
    formCurrency,
    defaultCurrencyCode,
    orderAttributeIsUpdating,
    updateOrderAttributeIsSuccess,
    updateOrderAttributeError,
    handleSelect,
    handleSubmit,
    resetOrderAttributeUpdateData,
  };
};

export default useCurrencyUpdateDialog;
