import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface Props extends PropsWithChildren {
  isLoading: boolean;
}

const OrderResponsiblesSkeleton: FC<Props> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <Box>
          <Box mb={4}>
            <Skeleton variant="rect" width={120} height={21} />
          </Box>
          {[1, 2, 3, 4, 5, 6].map(() => (
            <Box mb={4} display="flex">
              <Skeleton variant="text" width={400} height={21} />
            </Box>
          ))}
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default OrderResponsiblesSkeleton;
