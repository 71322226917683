/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, CircularProgress, MenuItem } from '@material-ui/core';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import { wsConnect } from '../../../actions/websocket';
import { fetchCessionaries, makeCession } from '../../../actions/cessionAction';
import { fetchFacturas } from '../../../actions/orderActions';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import CheckButton from '../../../components/elements/CheckButton';
import { CONFIRMING } from '../../../helpers/Constants';

const CessionDialog = ({ open, handleClose, selectedInvoices }) => {
  const dispatch = useDispatch();
  const [cessionaryId, setCessionaryId] = useState(2);
  const [checked, setChecked] = useState(false);

  const { id: orderId } = useParams();
  const { wsconnected } = useSelector(state => state.ws);
  const { business } = useSelector(state => state.business);
  const {
    requestingCession,
    requestCessionError,
    cessionSuccess,
    cessionaries,
  } = useSelector(state => state.cession);

  useEffect(() => {
    dispatch(fetchCessionaries());
  }, []);

  useEffect(() => {
    if (!wsconnected) {
      dispatch(wsConnect());
    }
  }, [wsconnected]);

  const onClose = () => {
    handleClose();
    dispatch({ type: 'HANDLE_CLOSE_CESSION_DIALOG' });
  };

  useEffect(() => {
    if (requestCessionError || cessionSuccess) {
      dispatch(fetchFacturas(orderId));
      onClose();
    }
  }, [requestCessionError, cessionSuccess]);

  const handleSubmit = () => {
    const [cessionary] = cessionaries.filter(val => {
      return val.id === cessionaryId;
    });

    dispatch(
      makeCession(selectedInvoices, cessionary, business?.id, CONFIRMING)
    );
  };

  return (
    <BaseDialog isOpen={open} handleClose={onClose} title="Cesión de facturas">
      {requestingCession ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <ValidatorForm onSubmit={handleSubmit}>
          <SelectValidator
            fullWidth
            variant="outlined"
            value={cessionaryId}
            onChange={e => setCessionaryId(e.target.value)}
          >
            {cessionaries &&
              cessionaries.map(({ name, id }) => {
                return (
                  <MenuItem key={name} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
          </SelectValidator>
          <CheckButton
            check={checked}
            disabled={!cessionaries.length}
            handleCheck={() => setChecked(!checked)}
          />
        </ValidatorForm>
      )}
    </BaseDialog>
  );
};

CessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
};

export default CessionDialog;
