import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  fetchDiscount,
  createDiscount,
  deleteDiscount,
  updateDiscount,
  fetchReplacementInvoices,
  resetDiscount,
} from '../../../actions/discountActions';
import {
  DISCOUNTS,
  REPLACEMENT_OPERATION_REASONS_DISCOUNTS,
} from '../../../helpers/Constants';
import {
  InvoiceList,
  Invoice,
} from '../../elements/SelectorInvoices/SelectorInvoices';

const BASE_DISCOUNT = 'BASE_DISCOUNT';
const REPLACEMENT_OPERATION_DISCOUNT = 'REPLACEMENT_OPERATION_DISCOUNT';
const CHECK_INVOICE_DISCOUNT = 'CHECK_INVOICE_DISCOUNT';

export interface DiscountConstant {
  type: string;
  label: string;
  value: string;
  country: string | string[];
  operationType?: string | string[];
}

export interface DiscountInformation {
  label: string;
  value: number | string;
  interest?: number;
  formatHelperVariant?: string;
  textGreen?: boolean;
}

export interface DiscountData {
  reason: string;
  amount: number;
  comment: string;
}

interface CreateReplacementOperationDiscount extends DiscountData {
  replacementOperations?: {
    orderInvoiceId: number;
    interest: number;
  }[];
}

export type SourceType = 'order' | 'orderinvoice';

export interface SourceData {
  totalAmount: number;
  operationType?: string;
  recommendedDiscount?: number;
}

interface Texts {
  [x: string]: {
    title: string;
    description: string;
    cardInformationTitle: string;
    cardInformationSourceId: string;
    cardInformationAmount: string;
  };
}

interface ValuesReplacementOperationData {
  id: number;
  operationId: string;
  isLoading: boolean;
  isCollapse: boolean;
  invoices: Invoice[];
  selectedInvoices: InvoiceList[];
}

interface ReplacementOperationData {
  [x: number]: ValuesReplacementOperationData;
}

interface Props {
  sourceId: number;
  sourceType: SourceType;
  sourceData: SourceData;
}

const useDiscountDrawer = ({ sourceId, sourceType, sourceData }: Props) => {
  const dispatch = useDispatch();
  const country = useSelector((state: RootStateOrAny) => state.config.country);
  const {
    discount: storeDiscount,
    replacementInvoicesData,
    replacementInvoicesIsLoading,
  } = useSelector((state: RootStateOrAny) => state.discount);

  const [discount, setDiscount] = useState<DiscountData>({
    reason: '',
    amount: 0,
    comment: '',
  });
  const [replacementOperationData, setReplacementOperationData] =
    useState<ReplacementOperationData>({});
  const [counter, setCounter] = useState(1);
  const [isReplacementOperation, setIsReplacementOperation] = useState(false);
  const [calculations, setCalculations] = useState({
    operationIds: '-',
    totalAmount: 0,
    interests: 0,
    totalAmountToTransfer: 0,
    totalAmountDiscount: 0,
  });
  const [bounce, setBounce] = useState(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDiscount({
      ...discount,
      [name]: value,
    });
    if (
      name === 'reason' &&
      REPLACEMENT_OPERATION_REASONS_DISCOUNTS.includes(value)
    ) {
      setIsReplacementOperation(true);
      setCounter(counter + 1);
      setReplacementOperationData({
        1: {
          id: 1,
          operationId: '',
          isLoading: false,
          isCollapse: true,
          invoices: [],
          selectedInvoices: [],
        },
      });
    }
    if (
      name === 'reason' &&
      !REPLACEMENT_OPERATION_REASONS_DISCOUNTS.includes(value)
    ) {
      setIsReplacementOperation(false);
    }
  };

  const handleChangeReplacementOperationSelectedInvoices = useCallback(
    (selectorInvoiceId: number, selectedInvoices: InvoiceList[]) => {
      const newReplacementOperationData = { ...replacementOperationData };
      newReplacementOperationData[selectorInvoiceId].selectedInvoices =
        selectedInvoices;
      setReplacementOperationData(newReplacementOperationData);
    },
    [replacementOperationData]
  );

  const handleChangeReplacementOperationIsCollapse = useCallback(
    (selectorInvoiceId: number, isCollapse: boolean) => {
      const newReplacementOperationData = { ...replacementOperationData };
      newReplacementOperationData[selectorInvoiceId].isCollapse = isCollapse;
      setReplacementOperationData(newReplacementOperationData);
    },
    [replacementOperationData]
  );

  const handleChangeReplacementOperationIsLoading = useCallback(
    (selectorInvoiceId: number, isLoading: boolean) => {
      const newReplacementOperationData = { ...replacementOperationData };
      newReplacementOperationData[selectorInvoiceId].isLoading = isLoading;
      setReplacementOperationData(newReplacementOperationData);
    },
    [replacementOperationData]
  );

  const handleChangeReplacementOperationOperationId = useCallback(
    (selectorInvoiceId: number, operationId: string) => {
      const newReplacementOperationData = { ...replacementOperationData };
      newReplacementOperationData[selectorInvoiceId] = {
        ...newReplacementOperationData[selectorInvoiceId],
        operationId,
        invoices: [],
        selectedInvoices: [],
      };
      setReplacementOperationData(newReplacementOperationData);
      // @ts-ignore
      clearTimeout(bounce);
      setBounce(
        // @ts-ignore
        setTimeout(() => {
          dispatch(fetchReplacementInvoices(operationId, selectorInvoiceId));
        }, 800)
      );
    },
    [replacementOperationData, bounce]
  );

  const handleAddSelectorInvoice = useCallback(() => {
    const newReplacementOperationData = { ...replacementOperationData };
    newReplacementOperationData[counter] = {
      id: counter,
      operationId: '',
      isLoading: false,
      isCollapse: true,
      invoices: [],
      selectedInvoices: [],
    };
    setReplacementOperationData(newReplacementOperationData);
    setCounter(counter + 1);
  }, [replacementOperationData, counter]);

  const handleDeleteSelectorInvoice = useCallback(
    (selectorInvoiceId: number) => {
      const newReplacementOperationData = { ...replacementOperationData };
      delete newReplacementOperationData[selectorInvoiceId];
      setReplacementOperationData(newReplacementOperationData);
    },
    [replacementOperationData]
  );

  const handleDeleteDiscount = () => {
    dispatch(deleteDiscount(sourceId, sourceType));
  };

  const getDiscounts = useCallback(() => {
    const baseDiscounts = DISCOUNTS.filter(
      ({ type, country: countryDiscount }) =>
        type === BASE_DISCOUNT && countryDiscount.includes(country)
    );

    const replacementOperationDiscount: { [x: string]: DiscountConstant[] } =
      {
        order: DISCOUNTS.filter(
          ({
            type,
            country: countryDiscount,
            sourceType: source,
            operationType,
          }) =>
            type === REPLACEMENT_OPERATION_DISCOUNT &&
            countryDiscount.includes(country) &&
            source?.includes('order') &&
            operationType?.includes(sourceData.operationType || '')
        ),
        orderinvoice: DISCOUNTS.filter(
          ({
            type,
            country: countryDiscount,
            sourceType: source,
            operationType,
          }) =>
            type === REPLACEMENT_OPERATION_DISCOUNT &&
            countryDiscount.includes(country) &&
            source?.includes('orderinvoice') &&
            operationType?.includes(sourceData.operationType || '')
        ),
      } || {};

    const checkInvoiceDiscount =
      {
        order: DISCOUNTS.filter(
          ({
            type,
            country: countryDiscount,
            sourceType: source,
            operationType,
          }) =>
            type === CHECK_INVOICE_DISCOUNT &&
            countryDiscount.includes(country) &&
            source?.includes('order') &&
            operationType?.includes(sourceData.operationType || '')
        ),
        orderinvoice: DISCOUNTS.filter(
          ({
            type,
            country: countryDiscount,
            sourceType: source,
            operationType,
          }) =>
            type === CHECK_INVOICE_DISCOUNT &&
            countryDiscount.includes(country) &&
            source?.includes('orderinvoice') &&
            operationType?.includes(sourceData.operationType || '')
        ),
      } || {};

    return [
      ...baseDiscounts,
      ...replacementOperationDiscount[sourceType],
      ...checkInvoiceDiscount[sourceType],
    ];
  }, [DISCOUNTS]);

  const calculateReplacementOperationSummary = useCallback(() => {
    const replacementOperationValues = Object.values(replacementOperationData);
    const getArrSelectedInvoices = replacementOperationValues.map(
      values => values.selectedInvoices
    );

    const operationIds: string =
      replacementOperationValues
        .map(value => value.operationId)
        .toString()
        .split(',')
        .join(', ') || '-';

    const selectedInvoicesAmounts: number[] = getArrSelectedInvoices.map(
      arrInvoices => {
        if (arrInvoices?.length) {
          return arrInvoices
            .map((x: InvoiceList) => x.amount || 0)
            .reduce((prev: number, current: number) => prev + current, 0);
        }
        return 0;
      }
    );

    const selectedInvoicesInterest: number[] = getArrSelectedInvoices.map(
      arrInvoices => {
        if (arrInvoices?.length) {
          return arrInvoices
            .map((x: InvoiceList) => x.interest || 0)
            .reduce((prev: number, current: number) => prev + current, 0);
        }
        return 0;
      }
    );

    const totalAmount: number =
      selectedInvoicesAmounts?.reduce((prev, current) => prev + current, 0) ||
      0;
    const interests: number =
      selectedInvoicesInterest?.reduce((prev, current) => prev + current, 0) ||
      0;

    const totalAmountDiscount = totalAmount + interests;

    const totalAmountToTransfer = Math.max(
      0,
      sourceData.totalAmount - totalAmountDiscount
    );

    setCalculations({
      operationIds,
      totalAmount,
      interests,
      totalAmountToTransfer,
      totalAmountDiscount,
    });
  }, [replacementOperationData]);

  const getTexts = useCallback(() => {
    const textsBySource: Texts =
      {
        order: {
          title: 'Descuento a operación',
          description:
            ' Ingresa la información necesaria para hacer el descuento en la operación. Este se verá reflejado en el total a transferir',
          cardInformationTitle: isReplacementOperation
            ? 'Resumen de la operación de reemplazo'
            : 'Resumen de la operación',
          cardInformationSourceId: 'ID de operación',
          cardInformationAmount: 'Monto de transferencia',
        },
        orderinvoice: {
          title: 'Descuento a factura',
          description:
            ' Ingresa la información necesaria para hacer el descuento en la factura. Este se verá reflejado en el total a transferir',
          cardInformationTitle: 'Resumen de la factura',
          cardInformationSourceId: 'ID de factura',
          cardInformationAmount: 'Monto total',
        },
      } || {};

    return textsBySource[sourceType];
  }, []);

  const discountSummary = [
    {
      label: getTexts().cardInformationSourceId,
      value: sourceId,
    },
    {
      label: getTexts().cardInformationAmount,
      value: sourceData.totalAmount,
      formatHelperVariant: 'currency',
    },
  ];

  const replacementOperationDiscountSummary = [
    {
      label: 'ID de operación',
      value: calculations.operationIds,
    },
    {
      label: 'Monto total',
      value: calculations.totalAmount,
      formatHelperVariant: 'currency',
    },
  ];

  const replacementOperationDiscountInterest = {
    label: 'Total intereses',
    value: calculations.interests,
    formatHelperVariant: 'currency',
  };

  const getTotalAmountsSummary = useCallback(() => {
    if (isReplacementOperation) {
      return [
        {
          label: 'Monto a total a transferir',
          value: calculations.totalAmountToTransfer,
          formatHelperVariant: 'currency',
          textGreen: true,
        },
        {
          label: 'Monto del descuento',
          value: calculations.totalAmountDiscount,
          formatHelperVariant: 'currency',
          textGreen: false,
        },
      ];
    }

    const data = [
      {
        label: 'Monto del descuento',
        value: discount.amount,
        formatHelperVariant: 'currency',
        textGreen: true,
      },
    ];

    if (
      discount.reason === 'DEBT_WALLET' &&
      sourceType === 'order' &&
      sourceData?.recommendedDiscount
    ) {
      data.unshift({
        label: 'Monto del descuento sugerido',
        value: sourceData?.recommendedDiscount,
        formatHelperVariant: 'currency',
        textGreen: false,
      });
    }

    return data;
  }, [discount, isReplacementOperation, calculations, sourceData, sourceType]);

  const handleSubmit = useCallback(() => {
    const data: CreateReplacementOperationDiscount = {
      ...discount,
    };
    if (isReplacementOperation) {
      const replacementOperations: {
        orderInvoiceId: number;
        interest: number;
      }[] = [];
      const arrSelectedInvoices = Object.values(replacementOperationData).map(
        replacementOperation => replacementOperation.selectedInvoices
      );
      arrSelectedInvoices.forEach(selectedInvoice => {
        if (selectedInvoice?.length) {
          selectedInvoice.forEach((invoice: Invoice) => {
            replacementOperations.push({
              orderInvoiceId: invoice.id,
              interest: invoice.interest,
            });
          });
        }
      });
      data.replacementOperations = replacementOperations;
      data.amount = calculations.totalAmountDiscount;
    }
    if (storeDiscount && Object.values(storeDiscount)?.length) {
      dispatch(updateDiscount(sourceId, sourceType, data));
    } else {
      dispatch(createDiscount(sourceId, sourceType, data));
    }
  }, [discount, replacementOperationData, calculations]);

  const getReplacementOperationMessage = useCallback(() => {
    const { totalAmountDiscount } = calculations;
    if (totalAmountDiscount !== 0) {
      if (totalAmountDiscount === sourceData.totalAmount) {
        return 'El reemplazo de estas facturas se identifica como “reemplazo perfecto” debido a que el monto antiguo y nuevo es exacto';
      }
      if (totalAmountDiscount > sourceData.totalAmount) {
        return 'El reemplazo de estas facturas se identifica como “reemplazo parcial” debido a que el monto antiguo es mayor al monto total a transferir de la operación nueva';
      }
      if (totalAmountDiscount < sourceData.totalAmount) {
        return 'El reemplazo de estas facturas se identifica como “reemplazo completo” debido a que el monto antiguo es menor al monto a transferir de la operación nueva';
      }
    }
    return '';
  }, [calculations]);

  const handleResetDiscountStore = () => {
    dispatch(resetDiscount());
  };

  useEffect(() => {
    if (replacementInvoicesData.selectorInvoicesId) {
      const newReplacementOperationData = { ...replacementOperationData };
      newReplacementOperationData[replacementInvoicesData.selectorInvoicesId] =
        {
          ...newReplacementOperationData[
            replacementInvoicesData.selectorInvoicesId
          ],
          isLoading: replacementInvoicesIsLoading,
          invoices: replacementInvoicesData.replacementInvoices,
        };
      setReplacementOperationData(newReplacementOperationData);
    }
  }, [replacementInvoicesData, replacementInvoicesIsLoading]);

  useEffect(() => {
    dispatch(fetchDiscount(sourceId, sourceType));
  }, []);

  useEffect(() => {
    if (storeDiscount && Object.values(storeDiscount).length) {
      const discountData = {
        reason: storeDiscount.reason,
        amount: storeDiscount.amount,
        comment: storeDiscount.comment,
      };
      setDiscount(discountData);
      if (storeDiscount.replacementOperations) {
        setIsReplacementOperation(true);
        const replacementOperations = [...storeDiscount.replacementOperations];
        let counterTemporary = 1;
        const newReplacementOperationData: ReplacementOperationData = {};
        replacementOperations.forEach((value, index) => {
          const keyObject = index + 1;
          counterTemporary += 1;
          newReplacementOperationData[keyObject] = {
            id: keyObject,
            operationId: value.orderId,
            isLoading: false,
            isCollapse: keyObject === 1,
            invoices: value.invoices.map((invoice: InvoiceList) => ({
              ...invoice,
              checked: true,
              hidden: false,
            })),
            selectedInvoices: [],
          };
        });
        setCounter(counterTemporary);
        setReplacementOperationData(newReplacementOperationData);
      }
    }
  }, [storeDiscount]);

  useEffect(() => {
    if (isReplacementOperation) {
      calculateReplacementOperationSummary();
    }
  }, [isReplacementOperation, replacementOperationData]);

  useEffect(() => {
    getTexts();
  }, [isReplacementOperation]);

  return {
    discount,
    isReplacementOperation,
    discountSummary,
    replacementOperationDiscountSummary,
    replacementOperationDiscountInterest,
    replacementOperationData,
    calculations,
    setDiscount,
    setIsReplacementOperation,
    setReplacementOperationData,
    getDiscounts,
    getTexts,
    getTotalAmountsSummary,
    getReplacementOperationMessage,
    handleAddSelectorInvoice,
    handleDeleteSelectorInvoice,
    handleChangeReplacementOperationOperationId,
    handleChangeReplacementOperationSelectedInvoices,
    handleChangeReplacementOperationIsCollapse,
    handleChangeReplacementOperationIsLoading,
    handleSubmit,
    handleInputChange,
    handleDeleteDiscount,
    calculateReplacementOperationSummary,
    handleResetDiscountStore,
  };
};

export default useDiscountDrawer;
