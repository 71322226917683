import {differenceInDays} from "date-fns";

export const getDiffDays = (expirationDate: Date) => {
    const nowDate = new Date();
    const isInArrears = nowDate > expirationDate;
    const leftDate = isInArrears ? expirationDate : nowDate;
    const rightDate = isInArrears ? nowDate : expirationDate;

    const diffDays = differenceInDays(leftDate, rightDate);

    return isInArrears ? -diffDays : diffDays - 1;
};