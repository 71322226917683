import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const deleteInvoiceDiscountService = async ({
  sourceId,
  sourceType,
}) => {
  try {
    const { data } = await axios.delete(
      `/api/${sourceType}/${sourceId}/discount`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default deleteInvoiceDiscountService;
