import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import BaseDialog from '../../../../../components/dialogs/BaseDialog';
import verifyDecimals from '../../../../../helpers/validation/decimals';
import CheckButton from '../../../../../components/elements/CheckButton';

export const UpdateFundRateDialog = ({
  isOpen,
  handleCloseDialog,
  title,
  label,
  description,
  selectedRate,
  handleSubmit,
  isLoading,
}) => {
  const [fundRate, setFundRate] = useState({});
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('verifyMaxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  useEffect(() => {
    setFundRate(selectedRate);
    setChecked(false);
  }, [selectedRate]);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={() => {
        handleCloseDialog();
        setChecked(false);
      }}
      title={title}
      description={description}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm
          onSubmit={() => {
            handleSubmit(fundRate);
            setChecked(false);
          }}
        >
          <TextValidator
            variant="outlined"
            fullWidth
            label={label}
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="fundRate"
            value={fundRate.rate}
            aria-describedby="Tasa"
            onChange={e =>
              setFundRate({ ...fundRate, rate: Number(e.target.value) })
            }
            validators={['required', 'isPositive', 'verifyMaxDecimals']}
            errorMessages={[
              'Campo requerido',
              'Valor debe ser mayor a 0',
              'Valor puede contener 4 decimales',
            ]}
          />
          <CheckButton
            check={checked}
            handleCheck={() => {
              setChecked(!checked);
            }}
            labelButton="Guardar"
            loading={isLoading}
          />
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

UpdateFundRateDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  description: '',
  label: 'Tasa (%)',
};

UpdateFundRateDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleCloseDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  // Ver bien lo que se pasara para hacer un shape
  selectedRate: PropTypes.objectOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
