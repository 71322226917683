import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import {
  fetchFunds,
  editFund,
  registerFund,
  fetchFundRates,
  registerFundRate,
  editFundRate,
  updateSelectedFund,
  resetFundFormDialog,
  updateSelectedFundRate,
} from '../../../../actions/fundsActions';
import {
  registerBankAccount,
  resetBankAccountDialog,
} from '../../../../actions/bankActions';
import FundsTable from '../../../../components/tables/FundsTable';
import usePagination from '../../../../hooks/pagination';
import BankAccountDialog from '../../../../components/dialogs/BankAccountDialog';
import LoaderComponent from '../../../../components/elements/LoaderComponent';
import Can from '../../../../components/Can';
import { FUNDS_ADD_PERFORM } from '../../../../helpers/performsType';
import { KEY_CODES } from '../../../../helpers/Constants';
import RateDialog from '../../../../components/dialogs/RateDialog';
import FundFormDialog from '../../../../components/dialogs/FundFormDialog';
import { settings } from '../../../../config/settings';
import { MainLayout } from '../../../MainLayout';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
    background: '#F5F5F5',
  },
  paperContainer: {
    padding: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
});

const entity = 'fund';

export const Funds = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showFundFormDialog, setShowFundFormDialog] = useState(false);
  const [showFundRateFormDialog, setShowFundRateFormDialog] = useState(false);
  const [showBankAccountDialog, setShowBankAccountDialog] = useState(false);
  const [selectedFundId, setSelectedFundId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [operationType, setOperationType] = useState();

  const {
    funds,
    registeredFund,
    updatedFund,
    fundsPagination,
    fundRates,
    loadingFundRates,
    selectedFund,
    selectedFundRate,
    fundId,
    saved,
  } = useSelector(state => state.funds);
  const { registeredBankAccount } = useSelector(state => state.banks);
  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const { page, limit, updatePageAndLimit } = usePagination(
    (newpage, newlimit) => {
      dispatch(fetchFunds(newpage, newlimit));
    }
  );

  const handleCloseDialogs = () => {
    setShowLoader(false);
  };

  const handleShowLoader = (show = true) => {
    setShowLoader(show);
  };

  const handleCloseFundFormDialog = () => {
    setShowFundFormDialog(false);
    setShowFundRateFormDialog(false);
  };

  const handleCloseBankAccountDialog = () => {
    setShowBankAccountDialog(false);
    setSelectedFundId(null);
    dispatch(resetBankAccountDialog());
  };

  const handleEscapeButton = event => {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      handleCloseFundFormDialog();
      handleCloseBankAccountDialog();
      handleCloseDialogs();
    }
  };

  useEffect(() => {
    dispatch(updateSelectedFund());
    document.addEventListener('keydown', handleEscapeButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registeredFund || updatedFund) {
      setShowFundFormDialog(false);
    }
  }, [registeredFund, updatedFund]);

  useEffect(() => {
    if (!loadingFundRates && showLoader) {
      handleCloseDialogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFundRates]);

  useEffect(() => {
    if (registeredBankAccount) {
      if (!isApiGlobal) {
        dispatch(fetchFunds(page, limit));
      }
      handleCloseBankAccountDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredBankAccount]);

  useEffect(() => {
    if (saved) {
      if (fundRates.length) {
        dispatch(fetchFunds(page, limit));
      }

      handleShowLoader(false);
    }
  }, [saved]);

  const handleSubmitNewFund = fund => {
    dispatch(registerFund(fund));
  };

  const handleSubmitUpdatedFund = fund => {
    const fundObj = { ...fund };
    const { id } = fundObj;
    fundObj.contactDetailId = fundObj?.ContactDetail?.id;
    delete fundObj.id;
    delete fundObj.ContactDetail;
    delete fundObj.createdAt;
    delete fundObj.updatedAt;
    delete fundObj.tableData;
    dispatch(editFund(id, fundObj));
  };

  const handleFundFormDialog = fund => {
    dispatch(resetFundFormDialog());
    dispatch(updateSelectedFund(fund.id, fund));
    setShowFundFormDialog(true);
    setShowFundRateFormDialog(false);
  };

  const handleFundRateDialog = (operation, fundId) => {
    dispatch(resetFundFormDialog());
    setOperationType(operation);
    dispatch(updateSelectedFund(fundId, null, operation));
    setShowFundFormDialog(false);
    setShowFundRateFormDialog(true);
  };

  const registerNewFund = () => {
    dispatch(resetFundFormDialog());
    dispatch(updateSelectedFund(null, null, null));
    setShowFundFormDialog(true);
  };

  const handleFundRatesDetail = (fundId, operation) => {
    dispatch(updateSelectedFund(fundId, null, operation));
    dispatch(fetchFundRates(fundId, operation));
  };

  const handleShowEditFundFormDialog = fundRate => {
    dispatch(resetFundFormDialog());
    dispatch(updateSelectedFundRate(fundRate));
    setShowFundRateFormDialog(true);
  };

  const handleSubmitNewFundRate = fundRate => {
    const data = { ...fundRate, fundId, orderType: operationType };
    dispatch(registerFundRate(data));
  };

  const handleSubmitUpdateFundRate = fundRate => {
    const formattedFundRate = { ...fundRate };
    delete formattedFundRate?.createdAt;
    delete formattedFundRate?.updatedAt;
    dispatch(editFundRate({ ...formattedFundRate }));
  };

  const handleRateSubmitForm = data => {
    handleShowLoader();
    if (!selectedFundRate) {
      handleSubmitNewFundRate(data);
    } else {
      handleSubmitUpdateFundRate(data);
    }

    handleCloseFundFormDialog();
  };

  const handleDeleteFundRate = data => {
    handleShowLoader();
    handleSubmitUpdateFundRate({ ...data, active: false });
    handleCloseFundFormDialog();
  };

  const handleUpdatedFundState = ({ id, active, ...rest }) => {
    const updatedFund = { ...rest };
    delete updatedFund.createdAt;
    delete updatedFund.updatedAt;
    delete updatedFund.tableData;
    delete updatedFund.id;
    dispatch(editFund(id, { active, ...updatedFund }));
  };

  const handleShowBankAccountDialog = fundId => {
    setShowBankAccountDialog(true);
    setSelectedFundId(fundId);
  };

  const handleSubmitBankAccount = bankAccountData => {
    const bankAccountDataObj = {
      ...bankAccountData,
      // The bank account table now uses sourceId instead of businessId
      sourceId: selectedFundId,
      // This new property is to indicate whether the new bank acccount is for a business or a fund (more sources could be implented in the future).
      source: 'FUND',
    };

    dispatch(registerBankAccount(entity, bankAccountDataObj));
  };

  return (
    <MainLayout>
      {showBankAccountDialog && (
        <BankAccountDialog
          open={showBankAccountDialog}
          onClose={handleCloseBankAccountDialog}
          onSubmit={handleSubmitBankAccount}
          isRegister
        />
      )}
      {showFundRateFormDialog && (
        <RateDialog
          open={showFundRateFormDialog}
          handleCloseDialog={handleCloseFundFormDialog}
          selectedRate={selectedFundRate}
          handleSubmit={handleRateSubmitForm}
          handleDelete={handleDeleteFundRate}
        />
      )}
      {showFundFormDialog && (
        <FundFormDialog
          open={showFundFormDialog}
          handleCloseDialog={handleCloseFundFormDialog}
          selectedFund={selectedFund}
          handleSubmitNewFund={handleSubmitNewFund}
          handleSubmitUpdatedFund={handleSubmitUpdatedFund}
        />
      )}

      {showLoader && (
        <LoaderComponent
          open={showLoader}
          handleCloseDialog={() => handleShowLoader(false)}
        />
      )}

      <Grid container className={classes.mainContainer}>
        <Can
          perform={FUNDS_ADD_PERFORM}
          yes={() => (
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                onClick={() => registerNewFund()}
                startIcon={<AddCircleIcon />}
                variant="text"
                color="primary"
              >
                Nuevo Fondo
              </Button>
            </Grid>
          )}
        />
        <Grid item xs={12}>
          <FundsTable
            tableData={funds}
            fundRates={fundRates}
            handleFundFormDialog={handleFundFormDialog}
            handleFundRateDialog={handleFundRateDialog}
            handleFundRates={handleFundRatesDetail}
            handleShowEditFormDialog={handleShowEditFundFormDialog}
            handleUpdatedFundState={handleUpdatedFundState}
            handleShowBankAccountDialog={handleShowBankAccountDialog}
            page={page}
            limit={limit}
            updatePageAndLimit={updatePageAndLimit}
            totalData={fundsPagination?.totalData}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
