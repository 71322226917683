import {
  STATUS_APPROVED,
  STATUS_APPROVED_FUND,
  AR_PRODUCTS,
  EARLY_PAYMENT,
  STATUS_REVIEWED,
  STATUS_APPEALED,
  STATUS_IN_REVIEW,
} from '../../helpers/Constants';

// eslint-disable-next-line import/prefer-default-export
export const CL = {
  businessDetailsSection: {
    showDocuments: true,
    showHistoryLogsButton: true,
    showInvoicesFinderScrappingButton: true,
    legal: {
      stakeholdersHasDocuments: false,
      createDocumentWithElectronicSignature: false,
    },
  },
  businessUserInfo: true,
  enabledOperations: {
    directFinancing: true,
    earlyPayment: true,
    confirming: true,
    payments: true,
  },
  fundActions: {
    downloadPayroll: true,
    fetch: true,
  },
  showAutoRateNewSimulationButton: {
    directFinancing: true,
    earlyPayment: true,
    confirming: true,
  },
  orderActions: {
    showResponsibles: true,
    addInvoiceDiscounts: true,
    bulkOrderStatusChange: true,
    changeStatusInvoice: true,
    changeStatusReasonInvoice: true,
    changeVerificationStatus: true,
    fetchTransferAmountPyme: true,
    repactOrder: true,
    showBulkAEC: true,
    bulkInvoicesUpdates: true,
    bulkFundsUpdates: true,
    bulkPartialPaymentsRegister: true,
    createDirectFinancingOrder: true,
    createEarlyPaymentOrder: true,
    createConfirmingOrder: true,
    showButtonUploadAccounting: true,
    showCessionButton: true,
    showDownloadAllPdfInvoiceButton: true,
    showComplianceDialog: true,
    showFundAllocationAttribute: true,
    showInvoiceDetail: true,
    showBusinessRates: true,
    showHistoryLogsButton: true,
    showInvoicesActionButton: true,
    showBulkLinkedFund: true,
    showNewSimulationButton: true,
    showOperationDiscountButton: true,
    showOrderSelectionButton: true,
    showReasonDiscount: true,
    showRepactOrderButton: true,
    showRiskAnalysisButton: true,
    showSendEmailSimulationButton: true,
    showSudoButton: true,
    verificationInvoice: true,
    showDownloadPdfInvoiceButton: true,
    showInvoiceHistoryChangeButton: true,
    showSharableInvoicePdfButton: true,
    showEditInvoiceButton: true,
    showBankAccountButton: true,
    changeOperationType: true,
    showDeleteInvoice: true,
    showMoveInvoice: true,
    showAddInvoice: true,
    createOrderNotEnrolled: true,
    showCessionAttribute: true,
    orderLegal: {
      requirements: {
        showTab: true,
        availableOperationStatuses: [
          STATUS_IN_REVIEW,
          STATUS_REVIEWED,
          STATUS_APPEALED,
          STATUS_APPROVED,
          STATUS_APPROVED_FUND,
        ],
        availableOperationTypes: AR_PRODUCTS,
      },
      attributtes: {
        showTab: true,
      },
      documents: {
        showTab: true,
      },
      walletStatus: {
        showTab: true,
        showDebtPaymentsOver5Days: true,
        showDebtDirectFinancingOver5Days: false,
        showDebtDirectFinancingOver60Days: true,
        showDebtDirectFinancingOver15Days: true,
        showHardCollectionBalance: true,
        showWalletBalance: true,
      },
    },
  },
  bankAccountsActions: {
    showCurrencyInput: false,
    showXepelinBankAccounts: false,
  },
  orderInvoiceActions: {
    showTotalOrdersTransferred: true,
    attributes: {
      enableInvoiceVerifiedWithoutAmount: true,
      invoiceVerifiedWithoutAmountProducts: [EARLY_PAYMENT],
    },
  },
  creditActions: {
    taxITE: true,
  },
  homeSidebar: [
    'orders',
    'credits',
    'businesses',
    'collection',
    'blocklist',
    'payers',
    'agreements',
    'funds',
    'users',
    'wallets',
    'reports',
  ],
  documentActions: {
    downloadInvoiceDocumentXML: false,
  },
};
