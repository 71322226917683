import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Drawer,
  ContainerRoot,
  ContainerTitle,
  Title,
  Divider,
  ContainerBody,
  ContainerCountData,
  CountDataTitle,
  CountDataStatus,
  ContainerTableScroll,
  TableTextBold,
  TableText,
} from './styles';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import InvoicePendingRequirementsSkeleton from './ui/InvoicePendingRequirementsSkeleton';
import useInvoicePendingRequirements from './hooks/useInvoicePendingRequirements';
import { CSVLink } from 'react-csv';
import { RequirementData } from '../RequirementsTab/interfaces';
import useGetCountry from '../../../../hooks/useGetCountry';
import Skeleton from '../Skeleton';
import CloseIcon from '../../icons/CloseIcon';
import CloudDownloadIcon from '../../icons/CloudDownloadIcon';

interface Props {
  isOpen: boolean;
  requirement: RequirementData | null;
  operationId?: number;
  handleCloseDrawer: () => void;
}

const InvoicePendingRequirementsDrawer: FC<Props> = ({
  isOpen,
  requirement,
  operationId,
  handleCloseDrawer,
}) => {
  const {
    fetchInvoicePendingRequirementsIsLoading,
    invoicePendingRequirements,
    handleGetProgress,
    handleGetTableIconByStatus,
    handleGetLinearProgressMulticolor,
    handleGetLinearProgressStatusIcon,
    handleGetDefaultTitleByKey,
    handleGetSucessTitleByKey,
    handleGetTableHeader,
    handleGetImageByProgress,
    handleGetCountRequirements,
  } = useInvoicePendingRequirements(requirement?.id || 0, operationId);
  const country = useGetCountry();
  const { totalRequirements, successRequirements } = handleGetCountRequirements(
    invoicePendingRequirements
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
      <ContainerRoot>
        <ContainerTitle mb={1}>
          <Title>Requisitos pendientes</Title>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon color="#000" />
          </IconButton>
        </ContainerTitle>
        <Divider />

        <ContainerBody>
          <InvoicePendingRequirementsSkeleton
            isLoading={fetchInvoicePendingRequirementsIsLoading}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={4}
            >
              <img
                alt="invoicePendingRequirementImg"
                src={handleGetImageByProgress(
                  successRequirements,
                  totalRequirements
                )}
                width={150}
                height={150}
              />
            </Box>
            <ContainerCountData mb={2}>
              <Box display="flex" alignItems="center">
                {handleGetLinearProgressStatusIcon(
                  successRequirements,
                  totalRequirements
                )}
                <CountDataTitle>
                  {handleGetProgress(successRequirements, totalRequirements) ===
                  100
                    ? handleGetSucessTitleByKey(requirement?.keyValue || '')
                    : handleGetDefaultTitleByKey(requirement?.keyValue || '')}
                </CountDataTitle>
              </Box>
              <CountDataStatus>
                {handleGetProgress(successRequirements, totalRequirements) !==
                  100 && `${successRequirements}/${totalRequirements}`}
              </CountDataStatus>
            </ContainerCountData>
            <Box mb={4}>
              {handleGetLinearProgressMulticolor(
                successRequirements,
                totalRequirements
              )}
            </Box>
            <ContainerTableScroll>
              <Box px={4}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="center">
                        <TableTextBold>Folio</TableTextBold>
                      </TableCell>
                      <TableCell align="center">
                        <TableTextBold>Monto Total</TableTextBold>
                      </TableCell>
                      <TableCell align="center">
                        <TableTextBold>
                          {handleGetTableHeader(requirement?.keyValue || '')}
                        </TableTextBold>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {invoicePendingRequirements?.map(
                      ({ id, folio, amount, status }, index) => (
                        <TableRow key={id}>
                          <TableCell align="center" padding="checkbox">
                            <TableText>{index + 1}</TableText>
                          </TableCell>
                          <TableCell align="center">
                            <TableText>{folio}</TableText>
                          </TableCell>
                          <TableCell align="center">
                            <TableTextBold>
                              {/* @ts-ignore */}
                              <CountryFormatHelper
                                value={amount}
                                variant="currency"
                                countryId={country}
                              />
                            </TableTextBold>
                          </TableCell>
                          <TableCell align="center">
                            {handleGetTableIconByStatus(status)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </ContainerTableScroll>
          </InvoicePendingRequirementsSkeleton>
        </ContainerBody>
        <Divider />
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Skeleton
              isLoading={fetchInvoicePendingRequirementsIsLoading}
              animation="wave"
              variant="rect"
              width={163}
              height={25}
            >
              {totalRequirements > 0 && (
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                >
                  <CSVLink
                    data={invoicePendingRequirements}
                    separator=","
                    filename="data_template"
                    asyncOnClick
                    onClick={(_event: any, done: any) => {
                      done();
                    }}
                  >
                    Descargar lista .csv
                  </CSVLink>
                </Button>
              )}
            </Skeleton>
          </Box>
        </Box>
      </ContainerRoot>
    </Drawer>
  );
};

export default InvoicePendingRequirementsDrawer;
