import React from 'react';
import PropTypes from 'prop-types';
import { Grid, styled } from '@material-ui/core';
import { useSelector } from 'react-redux';

import SearchField from './SearchField';
import AttributesFilter from './AttributesFilter';
import StatusFilter from './StatusFilter';

const CustomGrid = styled(Grid)({
  marginRight: '16px',
  width: '70%',
  display: 'flex',
  height: '100%',
});

const LargeGrid = styled(Grid)({
  flex: 0.4,
});

const InnerGrid = styled(Grid)({
  flex: 0.3,
  marginLeft: '10px',
});

const InvoicesFilters = ({
  searchKey,
  dropdown,
  dropDownFilters,
  invoiceStatus,
  invoicesStatuses,
  invoiceAttribute,
  invoicesAttributes,
  handleSearch,
  handleDropdown,
  handleStatus,
  handleAttributes,
}) => {
  const country = useSelector(state => state.config.country);

  return (
    <CustomGrid container>
      <LargeGrid item>
        <SearchField
          searchKey={searchKey}
          dropdown={dropdown}
          dropDownFilters={dropDownFilters}
          handleSearch={handleSearch}
          handleDropdown={handleDropdown}
        />
      </LargeGrid>
      {country === 'CL' && (
        <InnerGrid item>
          <AttributesFilter
            invoiceAttribute={invoiceAttribute}
            invoicesAttributes={invoicesAttributes}
            handleAttributes={handleAttributes}
          />
        </InnerGrid>
      )}
      <InnerGrid item>
        <StatusFilter
          invoiceStatus={invoiceStatus}
          invoicesStatuses={invoicesStatuses}
          handleStatus={handleStatus}
        />
      </InnerGrid>
    </CustomGrid>
  );
};

InvoicesFilters.propTypes = {
  searchKey: PropTypes.string.isRequired,
  dropdown: PropTypes.string.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  invoicesStatuses: PropTypes.arrayOf(String).isRequired,
  invoiceAttribute: PropTypes.string.isRequired,
  invoicesAttributes: PropTypes.arrayOf(String).isRequired,
  dropDownFilters: PropTypes.arrayOf(Object).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDropdown: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
  handleAttributes: PropTypes.func.isRequired,
};

export default InvoicesFilters;
