import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Financing = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 22.9167C6.477 22.9167 2 18.2532 2 12.5C2 6.74692 6.477 2.08337 12 2.08337C17.523 2.08337 22 6.74692 22 12.5C22 18.2532 17.523 22.9167 12 22.9167ZM12 20.8334C14.1217 20.8334 16.1566 19.9554 17.6569 18.3926C19.1571 16.8298 20 14.7102 20 12.5C20 10.2899 19.1571 8.17029 17.6569 6.60748C16.1566 5.04468 14.1217 4.16671 12 4.16671C9.87827 4.16671 7.84344 5.04468 6.34315 6.60748C4.84285 8.17029 4 10.2899 4 12.5C4 14.7102 4.84285 16.8298 6.34315 18.3926C7.84344 19.9554 9.87827 20.8334 12 20.8334ZM8.5 14.5834H14C14.1326 14.5834 14.2598 14.5285 14.3536 14.4308C14.4473 14.3332 14.5 14.2007 14.5 14.0625C14.5 13.9244 14.4473 13.7919 14.3536 13.6943C14.2598 13.5966 14.1326 13.5417 14 13.5417H10C9.33696 13.5417 8.70107 13.2673 8.23223 12.779C7.76339 12.2906 7.5 11.6282 7.5 10.9375C7.5 10.2469 7.76339 9.58449 8.23223 9.09612C8.70107 8.60774 9.33696 8.33337 10 8.33337H11V6.25004H13V8.33337H15.5V10.4167H10C9.86739 10.4167 9.74021 10.4716 9.64645 10.5693C9.55268 10.6669 9.5 10.7994 9.5 10.9375C9.5 11.0757 9.55268 11.2082 9.64645 11.3058C9.74021 11.4035 9.86739 11.4584 10 11.4584H14C14.663 11.4584 15.2989 11.7327 15.7678 12.2211C16.2366 12.7095 16.5 13.3719 16.5 14.0625C16.5 14.7532 16.2366 15.4156 15.7678 15.904C15.2989 16.3923 14.663 16.6667 14 16.6667H13V18.75H11V16.6667H8.5V14.5834Z" />
    </SvgIcon>
  );
};

export default Financing;
