import tracker from '../../tracker';

export const trackOrderSubmitted = (
  businessId,
  orderId,
  operationType,
  selectedInvoices,
  simulation,
  availableInvoicesCount,
  availableInvoicesAmount
) => {
  const payload = {
    extend: {
      simulation,
      selectedInvoices,
      orderType: operationType,
      availableInvoicesCount,
      availableInvoicesAmount,
      id: orderId,
    },
    businessId,
  };
  tracker.event('ORDER_SUBMIT_SUCCESS', payload);
};

export default trackOrderSubmitted;
