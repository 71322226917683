import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchHistoryLogsService = async (contextId, contextType) => {
  try {
    return await axios.get(
      `/api/logs/?contextId=${contextId}&contextType=${contextType}`
    );
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchHistoryLogsService };
