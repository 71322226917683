import {
  getTermDate,
  getDaysOfIssueDate,
  getTermInDays,
} from '../../../../commons/utils';

const getOrderBy = ({ field, direction } = {}) => {
  if (!direction) {
    return undefined;
  }

  const by = {
    folio: 'folio',
    'issuer.name': 'Issuer.name',
    'issuer.identifier': 'Issuer.identifier',
    expirationDate: 'expirationDate',
    issuedDate: 'issuedDate',
    operationType: 'operationType',
    amount: 'amount',
    'invoiceTaxProviders.assignee': 'InvoiceTaxProviders.metadata.assignee',
    'invoiceTaxProviders.assigneeName':
      'InvoiceTaxProviders.metadata.assigneeName',
    baseRate: 'baseRate',
    orderId: 'orderId',
    status: 'status',
  };

  return `${by[field]}:${direction}`;
};

export const documentMap = {
  toDomain: doc => {
    if (!doc) {
      return {};
    }
    const issuer = doc.Issuer;
    const receiver = doc.Receiver;
    const invoiceTaxProviders = doc.InvoiceTaxProviders?.metadata;

    return {
      amount: doc.amount,
      baseRate: doc.baseRate,
      businessActivity: doc.businessActivity,
      countryId: doc.countryId,
      discounts: doc.discounts,
      expirationDate: doc.expirationDate,
      folio: doc.folio,
      id: doc.id,
      issuedDate: doc.issuedDate,
      operationType: doc.operationType,
      orderId: doc.orderId,
      repurchase: doc.repurchase,
      score: doc.score,
      status: doc.status,
      totalAmountCreditNotes: doc.totalAmountCreditNotes,
      verificationStatus: doc.verificationStatus,
      invoiceTaxProviders: {
        assignee: invoiceTaxProviders.assignee,
        assigneeName: invoiceTaxProviders.assigneeName,
        acknowledgementOfReceipt: invoiceTaxProviders.acknowledgementOfReceipt,
      },
      issuer: {
        identifier: doc.issuerIdentifier,
        name: issuer.name,
        debtServiceAmount: issuer.debtServiceAmount,
      },
      receiver: {
        identifier: receiver.identifier,
        name: receiver.name,
        debtServiceAmount: receiver.debtServiceAmount,
      },
    };
  },
};

export const documentFilterMap = {
  toPersistence: (filter = {}) => {
    return {
      assigneeIdentifier: filter?.assignee?.identifier,
      assigneeName: filter?.assignee?.name,
      discounts: filter.discount,
      folio: filter.folio,
      issuerIdentifier: filter.issuerId,
      issuerName: filter.issuerName,
      limit: filter.limit,
      maxAmount: filter.maxAmount,
      maxBaseRate: filter.maxOriginationRate,
      maxExpirationDate: getTermDate(filter.maxTerm),
      maxIssuedDate: getDaysOfIssueDate(filter.maxDaysOfIssue),
      maxRate: filter.maxFundRate,
      minAmount: filter.minAmount,
      minBaseRate: filter.minOriginationRate,
      minExpirationDate: getTermDate(filter.minTerm),
      minIssuedDate: getDaysOfIssueDate(filter.minDaysOfIssue),
      minRate: filter.minFundRate,
      operationType: filter.operationType,
      orderBy: getOrderBy(filter.orderBy),
      page: filter.page,
      receiverIdentifier: filter.receiverIdentifier,
      status: filter.status,
      orderIds: filter?.orderIds?.length
        ? encodeURIComponent(
            JSON.stringify(
              filter.orderIds.reduce(
                (acc, orderId) =>
                  orderId ? (acc ? [...acc, +orderId] : [+orderId]) : acc,
                null
              )
            )
          )
        : null,
    };
  },
  toDomain: res => {
    return {
      records: res.data.map(docum => {
        const issuer = docum.Issuer;
        const invoiceTaxProviders = docum.InvoiceTaxProviders?.metadata;
        const invoiceAttribute = docum.InvoiceAttribute;

        return {
          advancePercentage: 100,
          amount: docum.amount,
          baseRate: docum.baseRate,
          expirationDate: getTermInDays(docum.expirationDate),
          folio: docum.folio,
          id: docum.id,
          invoiceAttribute: {
            hasPdf: invoiceAttribute?.hasPdf,
          },
          issuedDate: docum.issuedDate,
          operationType: docum.operationType,
          orderId: docum.orderId,
          status: docum.status,
          invoiceTaxProviders: {
            assignee: invoiceTaxProviders.assignee,
            assigneeName: invoiceTaxProviders.assigneeName,
          },
          issuer: {
            debtServiceAmount: issuer.debtServiceAmount || 0,
            identifier: issuer.identifier,
            name: issuer.name,
            hasPfx: issuer.hasPfx,
          },
        };
      }),
      pagination: {
        page: res.pagination.page,
        pageSize: res.pagination.pageSize,
        totalRecords: res.pagination.totalData,
        totalPages: res.pagination.totalPages,
      },
    };
  },
};
