import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  CircularProgress,
  styled,
  Typography,
  Box,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Dropzone from '../../../../components/elements/Dropzone/Dropzone';
import BaseDialog from '../../../../components/dialogs/BaseDialog';
import AlertForm from '../../../../components/elements/AlertForm';
import AlertMessage from '../AlertMessage';

const CustomOuterGrid = styled(Grid)({
  marginBottom: 20,
});

const CustomInnerGrid = styled(Grid)({
  marginBottom: 24,
});

const CustomButton = styled(Button)({
  paddingLeft: 0,
});

const FileUploadDialog = props => {
  const {
    open,
    handleCloseDialog,
    saving,
    handleLoad,
    title,
    description,
    csvTemplate,
    actionText,
    errorsCsv,
    serverError,
    resetDataAndErrors,
    downloadText,
    children,
    disabled,
  } = props;

  const errorMessage = `El archivo cargado presenta ${errorsCsv?.length}
  error${errorsCsv?.length > 1 ? 'es' : ''}`;

  const alertMessageComponent = (
    <>
      <Grid item>
        <Typography variant="body1" component="div">
          <Box fontWeight="bold">{errorMessage}</Box>
        </Typography>
      </Grid>
      <Grid item>
        <CustomButton color="primary" variant="text">
          <CSVLink
            data={errorsCsv}
            separator=","
            filename="errores"
            asyncOnClick
            onClick={(event, done) => {
              done();
            }}
          >
            Descargar el detalle
          </CSVLink>
        </CustomButton>
      </Grid>
    </>
  );

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
      size="md"
    >
      {children}
      <Grid container>
        <CustomOuterGrid container item xs={12} justifyContent="center">
          {errorsCsv?.length > 0 && (
            <CustomInnerGrid item xs={12}>
              <AlertMessage
                onClear={resetDataAndErrors}
                messageComponent={alertMessageComponent}
              />
            </CustomInnerGrid>
          )}

          {serverError && (
            <Grid item xs={12}>
              <AlertForm message={serverError} variant="error" />
            </Grid>
          )}

          {saving ? (
            <CircularProgress color="primary" size={60} />
          ) : (
            <Dropzone
              handleSubmit={handleLoad}
              maxFiles={1}
              accept=".csv"
              buttonText={actionText}
              isLoading={saving}
              disabled={disabled}
            />
          )}
        </CustomOuterGrid>

        <Grid container item xs={12} justifyContent="center">
          <Button
            startIcon={<CloudDownloadIcon color="primary" />}
            color="primary"
            disabled={saving}
          >
            <CSVLink
              data={csvTemplate}
              separator=","
              filename="data_template"
              asyncOnClick
              onClick={(event, done) => {
                done();
              }}
            >
              {downloadText ?? 'Descargar planilla'}
            </CSVLink>
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

FileUploadDialog.defaultProps = {
  errorsCsv: null,
  serverError: null,
  description: '',
  csvTemplate: [],
  resetDataAndErrors: () => undefined,
  downloadText: null,
  children: undefined,
  disabled: false,
};

FileUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  handleLoad: PropTypes.func.isRequired,
  errorsCsv: PropTypes.arrayOf(Object),
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  description: PropTypes.string,
  serverError: PropTypes.string,
  resetDataAndErrors: PropTypes.func,
  csvTemplate: PropTypes.arrayOf(PropTypes.shape({})),
  downloadText: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default FileUploadDialog;
