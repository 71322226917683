import { getProp } from '../utils';

export const FUNDS_BASE_PATH = 'funds';

export const TABLE_OPTIONS = {
  emptyRowsWhenPaging: false,
  filtering: false,
  pageSize: 999999,
  pageSizeOptions: [50, 100, 200, 400, 800],
  search: false,
  selection: true,
  showSelectAllCheckbox: true,
  showTitle: false,
};

export const SORT_NULL_LAST = field => (a, b, _, order) => {
  // NOTE: los undefined no los ordena, por eso se pone null como default
  const fieldA = getProp(a)(field) || null;
  const fieldB = getProp(b)(field) || null;

  if (fieldA === fieldB) {
    return 0;
  }
  if (fieldA === null) {
    return order === 'desc' ? -1 : 1;
  }
  if (fieldB === null) {
    return order === 'desc' ? 1 : -1;
  }

  return fieldA < fieldB ? -1 : 1;
};
