import { useQuery } from 'react-query';
import { fetchBankAccountsService } from '../services';

const useFetchBankAccounts = (source, sourceId) => {
  const { isLoading, data, refetch } = useQuery(
    'getBankAccounts',
    () => fetchBankAccountsService(source, sourceId),
    { enabled: !!sourceId, refetchOnWindowFocus: false }
  );

  const { data: bankAccounts } = data || { bankAccounts: [] };

  return {
    bankAccounts,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchBankAccounts };
