import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, Typography, Box, makeStyles, SvgIcon } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { ReactComponent as IconCl } from '../../assets/FlagCl.svg';
import { ReactComponent as IconMx } from '../../assets/FlagMx.svg';
import { useGetCommercialClassification } from '../../features/credit-line';
import useGetCountry from '../../hooks/useGetCountry';

const useStyles = makeStyles({
  header: {
    background: '#FFF',
  },
  headerPadding: {
    padding: props => convertSpacingToCss(props.padding),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginBottom: convertSpacingToCss('sm'),
  },
  titleRoot: {
    marginBottom: convertSpacingToCss('md'),
    display: 'inline-flex',
    alignItems: 'center',
  },
  title: {
    marginRight: convertSpacingToCss('xs'),
  },
  preTitleAction: {
    marginTop: '-2px',
    marginLeft: '10px',
    width: '20px',
  },
  containerTop: {
    display: 'flex',
    marginBottom: convertSpacingToCss('lg'),
  },
  iconFlag: {
    marginRight: 10,
  },
  titleCC: {
    marginTop: '5px',
    display: 'flex',
    marginBottom: '5px',
    gap: '5px',
    alignItems: 'center',
  },
});

const SectionHeader = props => {
  const {
    preTitleAction,
    title,
    mainArea,
    secondaryActions,
    breadCrumbs,
    showBreadCrumbs,
    isLoading,
    actionTitle,
    transparentBg,
  } = props;
  const classes = useStyles(props);

  const country = useGetCountry();
  const { business } = useSelector(state => state.business);

  const iconFlag = {
    CL: <SvgIcon component={IconCl} className={classes.iconFlag} />,
    MX: <SvgIcon component={IconMx} className={classes.iconFlag} />,
  };

  const resolveTitleComponent = titleComponent =>
    React.isValidElement(titleComponent) ? (
      titleComponent
    ) : (
      <Typography
        variant="h5"
        color="textPrimary"
        component="div"
        className={classes.title}
      >
        {titleComponent}
      </Typography>
    );

  const { commercialClassification, commercialClassificationLoading } =
    useGetCommercialClassification({
      country,
      businessId: business.id,
    });

  return (
    <Grid
      container
      className={[transparentBg ? '' : classes.header, classes.headerPadding]}
      direction="row"
    >
      <Grid item xs={10}>
        <Grid item xs={12}>
          {showBreadCrumbs && (
            <Box className={classes.containerTop}>
              {iconFlag[country]}
              <Breadcrumbs dataBreadCrumbs={breadCrumbs} />
            </Box>
          )}
          {isLoading ? (
            <Box>
              <Skeleton variant="text" width={300} height={26} />
              <Skeleton variant="text" width={300} height={26} />
            </Box>
          ) : (
            <Grid>
              <Box className={classes.titleRoot}>
                {preTitleAction && (
                  <Box className={classes.preTitleAction}>{preTitleAction}</Box>
                )}
                <Box fontWeight="fontWeightBold">
                  {resolveTitleComponent(title)}
                </Box>
                {actionTitle}
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  component="div"
                  className={classes.titleCC}
                >
                  <Box>Clasificación comercial:</Box>
                  {commercialClassificationLoading ? (
                    <Box>
                      <Skeleton variant="text" width={200} height={26} />
                    </Box>
                  ) : (
                    <Box fontWeight="fontWeightBold">
                      {commercialClassification}
                    </Box>
                  )}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        {mainArea && !isLoading && (
          <Grid item xs={12}>
            {mainArea}
          </Grid>
        )}
      </Grid>
      <Grid item xs={2} className={classes.actions}>
        {secondaryActions}
      </Grid>
    </Grid>
  );
};

SectionHeader.defaultProps = {
  mainArea: null,
  secondaryActions: null,
  isLoading: false,
  padding: 'xl',
  actionTitle: null,
  transparentBg: false,
  preTitleAction: null,
  showBreadCrumbs: true,
};

SectionHeader.propTypes = {
  title: PropTypes.element.isRequired,
  breadCrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  secondaryActions: PropTypes.element,
  preTitleAction: PropTypes.element,
  mainArea: PropTypes.element,
  // eslint-disable-next-line react/no-unused-prop-types
  padding: PropTypes.string,
  isLoading: PropTypes.bool,
  actionTitle: PropTypes.element,
  transparentBg: PropTypes.bool,
  showBreadCrumbs: PropTypes.bool,
};

export default SectionHeader;
