/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { BulkUpdateSelectionDialog } from './BulkUpdateSelectionDialog';
import ConfirmDialog from '../../../../../components/dialogs/ConfirmDialog';
import ActionBar from '../../../../../components/elements/ActionBar';
import ActionBarIcon from '../../../../../components/elements/ActionBarIcon';
import EditPen from '../../../../commons/icons/EditPen';
import TrashBin from '../../../../commons/icons/TrashBin';
import { pluralize } from '../../../../commons/utils';
import { usePayrollSelectorController } from '../../../domains/payroll/controllers';
import { useDraftPayrollSelectorController } from '../../../domains/payroll/controllers/useDraftPayrollSelectorController';
import { usePayrollActions } from '../../../domains/payroll/state';
import * as St from './SummaryActionBar.styles';

const Message = ({ totalDocuments, totalReceivers }) => {
  return (
    <span style={{ fontSize: 16, paddingLeft: 12 }}>
      <strong>{pluralize(totalReceivers, 'pagador', 'pagadores')}</strong> (
      {pluralize(totalDocuments, 'documento', 'documentos')}{' '}
      {pluralize(totalDocuments, 'seleccionado', 'seleccionados', true)})
    </span>
  );
};

export const SummaryActionBar = () => {
  const css = St.useStyles();
  const [openDeleteConfirm, setDeleteConfirm] = useState(false);
  const [openBulkEditionDialog, setOpenBulkEditionDialog] = useState(false);
  const { cleanDraftPayrollSelector } = usePayrollActions();
  const { deleteDraftDocuments, summary } = useDraftPayrollSelectorController();
  const { bulkEdit } = usePayrollSelectorController();

  if (!summary?.totalReceivers) {
    return null;
  }

  return (
    <>
      <ActionBar
        message={<Message {...summary} />}
        resetSelectedRows={cleanDraftPayrollSelector}
        show
      >
        <ActionBarIcon
          IconComponent={EditPen}
          className={css.selectionActionBarIcon}
          onClick={() => setOpenBulkEditionDialog(true)}
        />
        <ActionBarIcon
          tooltipMsg="Eliminar pagadores y facturas seleccionadas"
          IconComponent={TrashBin}
          onClick={() => setDeleteConfirm(true)}
          className={css.selectionActionBarIcon}
        />
      </ActionBar>
      <ConfirmDialog
        isOpen={openDeleteConfirm}
        handleClose={() => setDeleteConfirm(false)}
        title="Eliminar documentos"
        message={`¿Estás seguro que deseas eliminar ${pluralize(
          summary.totalDocuments,
          'documento',
          'documentos'
        )} de la nómina?`}
        buttonLabel="Eliminar"
        buttonOnAccept={() => {
          deleteDraftDocuments();
          setDeleteConfirm(false);
        }}
      />
      {openBulkEditionDialog && (
        <BulkUpdateSelectionDialog
          handleSubmit={newValues => {
            setOpenBulkEditionDialog(false);
            bulkEdit(newValues);
          }}
          isOpen={openBulkEditionDialog}
          title="Edición masiva"
          description="Recuerda que lo que edites sobreescribirá todos los datos de las facturas seleccionadas. Para modificar el plazo ingresa la fecha de vencimiento y se calcularán automáticamente los días de plazo."
          handleCloseDialog={() => setOpenBulkEditionDialog(false)}
        />
      )}
    </>
  );
};
