import {
  STATUS_APPROVED,
  STATUS_APPROVED_FUND,
  AR_PRODUCTS,
  STATUS_REVIEWED,
  STATUS_APPEALED,
  STATUS_IN_REVIEW,
} from '../../helpers/Constants';

// eslint-disable-next-line import/prefer-default-export
export const MX = {
  businessDetailsSection: {
    showDocuments: true,
    showHistoryLogsButton: true,
    showInvoicesFinderScrappingButton: false,
    legal: {
      stakeholdersHasDocuments: true,
      createDocumentWithElectronicSignature: true,
    },
  },
  businessUserInfo: true,
  enabledOperations: {
    directFinancing: true,
    earlyPayment: true,
    confirming: true,
    payments: true,
  },
  fundActions: {
    downloadPayroll: false,
    fetch: true,
  },
  showAutoRateNewSimulationButton: {
    directFinancing: true,
    earlyPayment: true,
    confirming: false,
  },
  orderActions: {
    showResponsibles: true,
    addInvoiceDiscounts: true,
    bulkOrderStatusChange: true,
    changeStatusInvoice: true,
    changeStatusReasonInvoice: true,
    changeVerificationStatus: true,
    fetchTransferAmountPyme: false,
    repactOrder: true,
    showBulkAEC: false,
    showButtonBulkInvoicesUpdate: false,
    showButtonCreateOrder: true,
    createDirectFinancingOrder: true,
    createEarlyPaymentOrder: true,
    createConfirmingOrder: true,
    bulkInvoicesUpdates: true,
    bulkFundsUpdates: true,
    bulkPartialPaymentsRegister: true,
    showButtonUploadAccounting: false,
    showCessionButton: false,
    showDownloadAllPdfInvoiceButton: false,
    showComplianceDialog: false,
    showFundAllocationAttribute: false,
    showInvoiceDetail: true,
    showBusinessRates: true,
    showFundResume: false,
    showHistoryLogsButton: true,
    showBulkLinkedFund: true,
    showNewSimulationButton: true,
    showOperationDiscountButton: true,
    showOrderSelectionButton: false,
    showReasonDiscount: true,
    showRepactOrderButton: false,
    showRiskAnalysisButton: true,
    showSendEmailSimulationButton: true,
    showSudoButton: true,
    verificationInvoice: true,
    showInvoicesActionButton: true,
    showDownloadPdfInvoiceButton: true,
    showInvoiceHistoryChangeButton: true,
    showSharableInvoicePdfButton: false,
    showEditInvoiceButton: false,
    showBankAccountButton: true,
    changeOperationType: true,
    showDeleteInvoice: true,
    showMoveInvoice: true,
    showAddInvoice: true,
    createOrderNotEnrolled: false,
    showCessionAttribute: false,
    showIsHoldInvoiceAttribute: true,
    orderLegal: {
      requirements: {
        showTab: true,
        availableOperationStatuses: [
          STATUS_IN_REVIEW,
          STATUS_REVIEWED,
          STATUS_APPEALED,
          STATUS_APPROVED,
          STATUS_APPROVED_FUND,
        ],
        availableOperationTypes: AR_PRODUCTS,
      },
      attributtes: {
        showTab: true,
      },
      documents: {
        showTab: true,
      },
      walletStatus: {
        showTab: true,
        showDebtPaymentsOver5Days: true,
        showDebtDirectFinancingOver5Days: true,
        showDebtDirectFinancingOver60Days: false,
        showDebtDirectFinancingOver15Days: false,
        showHardCollectionBalance: true,
        showWalletBalance: false,
      },
    },
  },
  bankAccountsActions: {
    showCurrencyInput: true,
    showXepelinBankAccounts: true,
  },
  orderInvoiceActions: {
    showTotalOrdersTransferred: false,
    attributes: {
      enableInvoiceVerifiedWithoutAmount: true,
      invoiceVerifiedWithoutAmountProducts: [],
    },
  },
  creditActions: {
    taxITE: false,
  },
  homeSidebar: [
    'orders',
    'credits',
    'businesses',
    'collection',
    'users',
    'blocklist',
    'payers',
    'agreements',
    'funds',
    'reports',
    'bankMovements',
  ],
  documentActions: {
    downloadInvoiceDocumentXML: true,
  },
};
