import React, { ReactNode, FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const AddCircleIcon: FC<Props> = ({ color = palette.primary.main, style }) => {
  return (
    <SvgIcon
      style={{
        color,
        ...style,
      }}
      viewBox="0 0 24 24"
    >
      <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </SvgIcon>
  );
};

export default AddCircleIcon;
