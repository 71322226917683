import {
  FETCH_CONTACTS_START,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  CREATE_CONTACT_START,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  UPDATE_CONTACT_START,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  DELETE_CONTACT_START,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  FETCH_CONTACTS_CSV_START,
  FETCH_CONTACTS_CSV_SUCCESS,
  FETCH_CONTACTS_CSV_FAILURE,
  FETCH_CONTACTS_CSV_MIGRACIONCL_START,
  FETCH_CONTACTS_CSV_MIGRACIONCL_SUCCESS,
  FETCH_CONTACTS_CSV_MIGRACIONCL_FAILURE,
  LOAD_CONTACTS_CSV_START,
  LOAD_CONTACTS_CSV_SUCCESS,
  LOAD_CONTACTS_CSV_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESET_CONTACTS_CSV_ERROR,
  RESET_PAYER_CONTACTS_CSV_DATA,
} from '../actions/types';

const initialState = {
  payerContactsOwner: null,
  payerContacts: [],
  payerContactsIsLoading: false,
  payerContactsError: null,
  payerContactIsLoading: false,
  payerContactError: null,
  payerContactsCsv: [],
  payerContactsCsvIsLoading: false,
  payerContactsCsvIsLoaded: false,
  payerContactsCsvError: '',
  fetchingInfoContactsToCsvMIGRACIONCL: false,
  contactsToCSVMIGRACIONCL: [],
};

const verifyEmailStart = (state, payload) => {
  const { contactId, contactableType } = payload;
  const contacts = state[`${contactableType}Contacts`].map(contact => {
    if (contact.id === contactId) {
      return {
        ...contact,
        probability: null,
        emailVerifyIsLoading: true,
        emailVerifyError: null,
      };
    }
    return contact;
  });

  return {
    ...state,
    [`${contactableType}Contacts`]: contacts,
  };
};

const verifyEmailSuccess = (state, payload) => {
  const { contactId, contactableType, probability } = payload;
  const contacts = state[`${contactableType}Contacts`].map(contact => {
    if (contact.id === contactId) {
      return {
        ...contact,
        probability,
        emailVerifyIsLoading: false,
      };
    }
    return contact;
  });

  return {
    ...state,
    [`${contactableType}Contacts`]: contacts,
  };
};

const verifyEmailFailure = (state, payload) => {
  const { contactId, contactableType } = payload;
  const contacts = state[`${contactableType}Contacts`].map(contact => {
    if (contact.id === contactId) {
      return {
        ...contact,
        probability: null,
        emailVerifyIsLoading: false,
        emailVerifyError: true,
      };
    }
    return contact;
  });

  return {
    ...state,
    [`${contactableType}Contacts`]: contacts,
  };
};

const fetchContactsStart = (state, payload) => {
  const { contactableType, contactableId } = payload;
  return {
    ...state,
    [`${contactableType}Contacts`]: [],
    [`${contactableType}ContactsIsLoading`]: true,
    [`${contactableType}ContactsError`]: null,
    [`${contactableType}IdContactsOwner`]: contactableId,
  };
};

const fetchContactsSuccess = (state, payload) => {
  const { contactableType, contacts } = payload;
  return {
    ...state,
    [`${contactableType}Contacts`]: contacts,
    [`${contactableType}ContactsIsLoading`]: false,
  };
};

const fetchContactsFailure = (state, payload) => {
  const { contactableType, errorCode } = payload;
  return {
    ...state,
    [`${contactableType}ContactsIsLoading`]: false,
    [`${contactableType}ContactsError`]: errorCode,
  };
};

const fetchContactsCsvStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactsCsv`]: '',
    [`${contactableType}ContactsCsvIsLoading`]: true,
    [`${contactableType}ContactsCsvError`]: '',
  };
};

const fetchContactsCsvSuccess = (state, payload) => {
  const { contactsCsv = '', contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactsCsv`]: contactsCsv,
    [`${contactableType}ContactsCsvIsLoading`]: false,
  };
};

const fetchContactsCsvFailure = (state, payload) => {
  const { contactableType, errorCode } = payload;
  return {
    ...state,
    [`${contactableType}ContactsCsvIsLoading`]: false,
    [`${contactableType}ContactsCsvError`]: errorCode,
  };
};

const fetchContactsCsvMIGRACIONCLStart = state => {
  return {
    ...state,
    fetchingInfoContactsToCsvMIGRACIONCL: true,
  };
};

const fetchContactsCsvMIGRACIONCLSuccess = (state, payload) => {
  const { contactsToCSV = [] } = payload;
  const arrayContacts = contactsToCSV.length === 0 ? null : contactsToCSV;
  return {
    ...state,
    fetchingInfoContactsToCsvMIGRACIONCL: false,
    contactsToCSVMIGRACIONCL: arrayContacts,
  };
};

const fetchContactsCsvMIGRACIONCLFailure = state => {
  return {
    ...state,
    fetchingInfoContactsToCsvMIGRACIONCL: false,
  };
};

const loadContactsCsvStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactsCsvError`]: '',
    [`${contactableType}ContactsCsvIsLoaded`]: false,
    [`${contactableType}ContactsCsvIsLoading`]: true,
    invalidContacts: [],
  };
};

const loadContactsCsvSuccess = (state, payload) => {
  const { contactableType, invalidContacts = null } = payload;

  return {
    ...state,
    [`${contactableType}ContactsCsvIsLoading`]: false,
    [`${contactableType}ContactsCsvIsLoaded`]: true,
    invalidContacts,
  };
};

const loadContactsCsvFailure = (state, payload) => {
  const { contactableType, errorCode, invalidContacts = [] } = payload;
  return {
    ...state,
    [`${contactableType}ContactsCsvIsLoading`]: false,
    [`${contactableType}ContactsCsvError`]: errorCode,
    invalidContacts,
  };
};

const createContactStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: true,
    [`${contactableType}ContactError`]: null,
  };
};

const createContactSuccess = (state, payload) => {
  const { contactableType, contact } = payload;
  const { [`${contactableType}Contacts`]: contacts } = state;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: false,
    [`${contactableType}Contacts`]: contacts.concat(contact),
  };
};

const createContactFailure = (state, payload) => {
  const { contactableType, errorCode } = payload;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: false,
    [`${contactableType}ContactError`]: errorCode,
  };
};

const updateContactStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: true,
    [`${contactableType}ContactError`]: null,
  };
};

const updateContactSuccess = (state, payload) => {
  const { contactableType, contactId, contactInfo } = payload;
  const { [`${contactableType}Contacts`]: contacts } = state;
  const updatedContacts = contacts.map(contact => {
    const { name, email, phone } = contactInfo;
    if (contact.id === contactId) {
      return {
        ...contact,
        name,
        email,
        phone,
      };
    }
    return contact;
  });
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: false,
    [`${contactableType}Contacts`]: updatedContacts,
  };
};

const updateContactFailure = (state, payload) => {
  const { contactableType, errorCode } = payload;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: false,
    [`${contactableType}ContactError`]: errorCode,
  };
};

const deleteContactStart = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: true,
    [`${contactableType}ContactIsError`]: null,
  };
};

const deleteContactSuccess = (state, payload) => {
  const { contactableType, contactId } = payload;
  const { [`${contactableType}Contacts`]: contacts } = state;
  const updatedContacts = contacts.filter(contact => contact.id !== contactId);
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: false,
    [`${contactableType}Contacts`]: updatedContacts,
  };
};

const deleteContactFailure = (state, payload) => {
  const { contactableType, errorCode } = payload;
  return {
    ...state,
    [`${contactableType}ContactIsLoading`]: false,
    [`${contactableType}ContactError`]: errorCode,
  };
};

const resetContactsCsvError = (state, payload) => {
  const { contactableType } = payload;
  return {
    ...state,
    invalidContacts: [],
    [`${contactableType}ContactsCsvError`]: '',
  };
};

const resetPayerContactsCsvData = state => {
  return {
    ...state,
    payerContactsCsv: [],
    contactsToCSVMIGRACIONCL: [],
    payerContactsCsvIsLoaded: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VERIFY_EMAIL_START:
      return verifyEmailStart(state, payload);
    case VERIFY_EMAIL_SUCCESS:
      return verifyEmailSuccess(state, payload);
    case VERIFY_EMAIL_FAILURE:
      return verifyEmailFailure(state, payload);
    case FETCH_CONTACTS_START:
      return fetchContactsStart(state, payload);
    case FETCH_CONTACTS_SUCCESS:
      return fetchContactsSuccess(state, payload);
    case FETCH_CONTACTS_FAILURE:
      return fetchContactsFailure(state, payload);
    case FETCH_CONTACTS_CSV_START:
      return fetchContactsCsvStart(state, payload);
    case FETCH_CONTACTS_CSV_SUCCESS:
      return fetchContactsCsvSuccess(state, payload);
    case FETCH_CONTACTS_CSV_FAILURE:
      return fetchContactsCsvFailure(state, payload);
    case LOAD_CONTACTS_CSV_START:
      return loadContactsCsvStart(state, payload);
    case LOAD_CONTACTS_CSV_SUCCESS:
      return loadContactsCsvSuccess(state, payload);
    case LOAD_CONTACTS_CSV_FAILURE:
      return loadContactsCsvFailure(state, payload);
    case CREATE_CONTACT_START:
      return createContactStart(state, payload);
    case CREATE_CONTACT_SUCCESS:
      return createContactSuccess(state, payload);
    case CREATE_CONTACT_FAILURE:
      return createContactFailure(state, payload);
    case UPDATE_CONTACT_START:
      return updateContactStart(state, payload);
    case UPDATE_CONTACT_SUCCESS:
      return updateContactSuccess(state, payload);
    case UPDATE_CONTACT_FAILURE:
      return updateContactFailure(state, payload);
    case DELETE_CONTACT_START:
      return deleteContactStart(state, payload);
    case DELETE_CONTACT_SUCCESS:
      return deleteContactSuccess(state, payload);
    case DELETE_CONTACT_FAILURE:
      return deleteContactFailure(state, payload);
    case RESET_CONTACTS_CSV_ERROR:
      return resetContactsCsvError(state, payload);
    case FETCH_CONTACTS_CSV_MIGRACIONCL_START:
      return fetchContactsCsvMIGRACIONCLStart(state, payload);
    case FETCH_CONTACTS_CSV_MIGRACIONCL_SUCCESS:
      return fetchContactsCsvMIGRACIONCLSuccess(state, payload);
    case FETCH_CONTACTS_CSV_MIGRACIONCL_FAILURE:
      return fetchContactsCsvMIGRACIONCLFailure(state, payload);
    case RESET_PAYER_CONTACTS_CSV_DATA:
      return resetPayerContactsCsvData(state);
    default:
      return state;
  }
};
