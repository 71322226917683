import moment from 'moment';

import { DATE_DB_FORMAT, DATE_FORMAT } from '../../../helpers/Constants';
import { SATURDAY, SUNDAY } from '../constants/dates';

export const dateToDDMMYYYY = date => {
  return date ? moment.utc(date).format(DATE_FORMAT) : '';
};

function getParsedStringDate(baseDate) {
  // NOTE: to fix date with dash separation
  // https://stackoverflow.com/questions/5619202/converting-a-string-to-a-date-in-javascript
  const parsedStringDate =
    typeof baseDate === 'string' && baseDate.split('T')[0].replace(/-/g, '/');

  return new Date(parsedStringDate || baseDate);
}

export const getYYYYMMDD = (baseDate, separator = '-') => {
  const newDate = new Date(getParsedStringDate(baseDate));
  const month = `0${newDate.getMonth() + 1}`.slice(-2);
  const day = `0${newDate.getDate()}`.slice(-2);
  const year = newDate.getFullYear();

  return [year.toString(), month.toString(), day.toString()].join(separator);
};

export const isSaturday = dateTime => {
  const dayToEvaluate = new Date(getYYYYMMDD(dateTime, '/')).getDay();

  return SATURDAY === dayToEvaluate;
};

export const isSunday = dateTime => {
  const dayToEvaluate = new Date(getYYYYMMDD(dateTime, '/')).getDay();

  return SUNDAY === dayToEvaluate;
};

export const dateDBFormatter = date => {
  return date ? moment.utc(date).format(DATE_DB_FORMAT) : '';
};

export const getRangeOfDays = (
  dateFrom,
  dateTo = new Date(),
  unit = 'days'
) => {
  if (!dateFrom) {
    return '';
  }

  const a = moment(getYYYYMMDD(dateFrom)).utc();
  const b = moment(getYYYYMMDD(dateTo)).utc();

  return +a.diff(b, unit, true).toFixed();
};

export const addDays = (days, date = new Date()) => {
  const result = new Date(getYYYYMMDD(date));
  const req = result.getDate() + +days;
  result.setDate(req);

  return result;
};

const subtractDays = (days, date = new Date()) => {
  const result = new Date(date);
  const req = result.getDate() - +days;
  result.setDate(req);

  return result;
};

export const roundTerms = termDays => parseInt(termDays, 10);

export const getTermInDays = termDate => roundTerms(getRangeOfDays(termDate));

export const getDaysOfIssueInDays = daysOfIssueDate =>
  getRangeOfDays(new Date(), daysOfIssueDate);

export const getTermDate = term =>
  term ? dateDBFormatter(addDays(term)) : undefined;

export const getDaysOfIssueDate = issueDay =>
  issueDay ? dateDBFormatter(subtractDays(issueDay)) : undefined;
