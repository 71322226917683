import { defaultValue } from '../../../../../commons/utils';

export const GET_SUMMARY = res => [
  {
    label: 'N° de documentos:',
    value: defaultValue(res?.payrollInvoicesNumber),
  },
  {
    label: 'PDFs encontrados:',
    value: defaultValue(res?.payrollInvoicesWithPdfNumber),
  },
  {
    label: 'Documentos sin PDFs:',
    value: defaultValue(res?.payrollInvoicesWithoutPdfNumber),
  },
];
