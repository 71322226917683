import axios from 'axios';

export const fetchOrderCreditService = async orderId => {
  try {
    //  EDNPOINT IS TEMPORARY UNTIL REFACTOR (SHOULD IMPLEMENT ENDPOINT UNDER PAYMENTS DOMAIN IN SERVER GLOBAL)
    return await axios.get(`/api/orders/${orderId}/credit`);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default fetchOrderCreditService;
