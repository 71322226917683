import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useOktaAuth } from '@okta/okta-react';
import { ApolloProvider } from '@apollo/client';
import graphQlNotificationsClient from '../../infra/apollo-notifications-client';
import TopBar from '../TopBar';
import HomeSideBar from '../HomeSideBar';
import Container from '../elements/Container';

const { REACT_APP_VERSION } = process.env;

const Layout = props => {
  const { remoteVersion } = useSelector(state => state.uiState, shallowEqual);
  const { children } = props;
  const { authState } = useOktaAuth();

  const reloadBrowser = () => {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  return authState && authState.isAuthenticated ? (
    <Container>
      <ApolloProvider client={graphQlNotificationsClient}>
        <TopBar auth={authState} />
      </ApolloProvider>
      {remoteVersion !== REACT_APP_VERSION ? (
        <Container sticky stickyTop="56px" zIndex={100}>
          <Alert
            variant="filled"
            severity="warning"
            action={
              <Button color="inherit" onClick={reloadBrowser}>
                Actualizar
              </Button>
            }
          >
            Debes refrescar el sitio para tener la última versión de Backoffice
            Xepelin.
          </Alert>
        </Container>
      ) : null}
      <Container
        gridTemplate="min-content 1fr"
        sticky
        stickyTop={remoteVersion !== REACT_APP_VERSION ? '104px' : '56px'}
        zIndex={1}
      >
        <HomeSideBar
          sticky
          stickyTop={remoteVersion !== REACT_APP_VERSION ? '104px' : '56px'}
          zIndex={1}
        />
        <div>{children}</div>
      </Container>
    </Container>
  ) : (
    <div>{children}</div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
