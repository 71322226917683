import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, MenuItem } from '@material-ui/core';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';

import BaseDialog from './BaseDialog';
import {
  BLOCKLIST_REASONS,
  BLOCKLIST_REASONS_CL,
  COUNTRY_CODE_CL,
} from '../../helpers/Constants';

const EditBlockReasonDialogComponent = ({
  isOpen,
  handleCloseDialog,
  handleSubmit,
  baseReason,
  blockId,
  title,
}) => {
  const [reason, setReason] = useState(baseReason);
  const parseSubmit = () => handleSubmit(reason, blockId);
  const { country } = useSelector(state => state.config);

  return (
    <BaseDialog isOpen={isOpen} handleClose={handleCloseDialog} title={title}>
      <ValidatorForm onSubmit={parseSubmit}>
        <SelectValidator
          fullWidth
          variant="outlined"
          name="operationReason"
          id="operation-reason-select"
          value={reason}
          onChange={e => setReason(e.target.value)}
          validators={['required']}
          errorMessages={['Campo requerido']}
          label="Motivo"
        >
          {[
            ...BLOCKLIST_REASONS,
            ...(country === COUNTRY_CODE_CL ? BLOCKLIST_REASONS_CL : []),
          ].map(blockReason => (
            <MenuItem key={blockReason.value} value={blockReason.value}>
              <Typography variant="body1">{blockReason.label}</Typography>
            </MenuItem>
          ))}
        </SelectValidator>
        <Button fullWidth variant="contained" color="primary" type="submit">
          Guardar
        </Button>
      </ValidatorForm>
    </BaseDialog>
  );
};

EditBlockReasonDialogComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  baseReason: PropTypes.string.isRequired,
  blockId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export const EditBlockReasonDialog = memo(EditBlockReasonDialogComponent);
