import axios from 'axios';
import {
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  REGISTER_REPORT_START,
  REGISTER_REPORT_SUCCESS,
  REGISTER_REPORT_FAILURE,
  EDIT_REPORT_START,
  EDIT_REPORT_SUCCESS,
  EDIT_REPORT_FAILURE,
  FETCH_PERFORM_START,
  FETCH_PERFORM_SUCCESS,
  FETCH_PERFORM_FAILURE,
  EXECUTE_REPORT_START,
  EXECUTE_REPORT_SUCCESS,
  EXECUTE_REPORT_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchReports =
  ({ searchInput, field, limit, page }) =>
  dispatch => {
    dispatch({ type: FETCH_REPORTS_START });
    axios
      .get(`/api/reports`, {
        params: {
          searchInput,
          field,
          limit,
          page,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({
          type: FETCH_REPORTS_SUCCESS,
          payload: resData,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_REPORTS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_REPORTS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const registerReport = report => dispatch => {
  dispatch({ type: REGISTER_REPORT_START });
  axios
    .post(`/api/reports`, report)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.REPORT_CREATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: REGISTER_REPORT_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_REPORT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const updateReport = report => dispatch => {
  dispatch({ type: EDIT_REPORT_START });
  axios
    .put(`/api/reports/${report.id}`, report)
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.REPORT_UPDATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: EDIT_REPORT_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: EDIT_REPORT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const fetchPerforms = () => dispatch => {
  dispatch({ type: FETCH_PERFORM_START });
  axios
    .get('/api/user/performs')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_PERFORM_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_PERFORM_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_PERFORM_FAILURE,
        payload: { errorCode },
      });
    });
};

export const executeReport = report => dispatch => {
  dispatch({ type: EXECUTE_REPORT_START });
  axios
    .post(`/api/reports/execute/${report.id}`, report)
    .then(res => {
      const resData = res.data.fileBuffer;
      const anchorRref = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${resData}`;
      window.location.href = anchorRref;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.REPORT_EXECUTED, {
          variant: 'success',
        })
      );
      dispatch({
        type: EXECUTE_REPORT_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: EXECUTE_REPORT_FAILURE,
        payload: { errorCode },
      });
    });
};
