import axios from 'axios';

export const lockOrderService = async ({ orderId }) => {
  try {
    return await axios.put(`/api/orders/order/${orderId}/lock`);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default lockOrderService;
