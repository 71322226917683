import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Grid, MenuItem } from '@material-ui/core';
import {
  TextValidator,
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import { DATE_FORMAT } from '../../helpers/Constants';
import LoadingButton from '../elements/LoadingButton';
import AlertForm from '../elements/AlertForm';
import { dateWithoutFormatter } from '../../helpers/DateUtils';
import useStatuses from '../../hooks/useStatuses';

const CreditFeesDialog = ({
  open,
  handleCloseDialog,
  selectedCreditFees,
  handleSubmit,
  isLoading,
  error,
}) => {
  const { getStatuses } = useStatuses();
  const [creditFees, setCreditFees] = useState({
    amount: null,
    estimatedPaymentDate: null,
    paymentDate: null,
    interest: null,
    accruedInterest: null,
    capital: null,
    status: null,
  });

  useEffect(() => {
    if (selectedCreditFees) {
      const {
        creditId,
        amount,
        estimatedPaymentDate,
        paymentDate,
        interest,
        accruedInterest,
        capital,
        status,
      } = selectedCreditFees;
      const newSelectedCreditFees = {
        creditId,
        amount,
        estimatedPaymentDate,
        paymentDate,
        interest,
        accruedInterest,
        capital,
        status,
      };
      setCreditFees(newSelectedCreditFees);
    }
  }, [selectedCreditFees]);

  const onSubmit = () => {
    handleSubmit(creditFees);
  };

  const handleInputChange = event => {
    const {
      target: { value: valueInput, name: nameInput },
    } = event;

    const cleanValueInput = valueInput === '' ? null : valueInput;

    setCreditFees({
      ...creditFees,
      [nameInput]: cleanValueInput,
    });
  };

  const handleDateChange = (name, value) => {
    setCreditFees({
      ...creditFees,
      [name]: value,
    });
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Editar cuota"
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={onSubmit}>
          <TextValidator
            fullWidth
            variant="outlined"
            label="Monto"
            type="number"
            inputProps={{ min: 0, step: 1 }}
            name="amount"
            value={creditFees.amount}
            onChange={handleInputChange}
          />

          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha estimada de pago"
              name="estimatedPaymentDate"
              format={DATE_FORMAT}
              onChange={date => handleDateChange('estimatedPaymentDate', date)}
              minDateMessage="Fecha inválida"
              autoOk
              value={dateWithoutFormatter(creditFees.estimatedPaymentDate)}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              inputVariant="outlined"
              fullWidth
              label="Fecha de pago"
              name="paymentDate"
              format={DATE_FORMAT}
              onChange={date => handleDateChange('paymentDate', date)}
              minDateMessage="Fecha inválida"
              autoOk
              value={dateWithoutFormatter(creditFees.paymentDate)}
            />
          </MuiPickersUtilsProvider>

          <TextValidator
            fullWidth
            variant="outlined"
            inputProps={{ min: 0, step: 1 }}
            label="Intereses"
            type="number"
            name="interest"
            value={creditFees.interest}
            onChange={handleInputChange}
          />

          <TextValidator
            fullWidth
            variant="outlined"
            label="Interés Devengado"
            inputProps={{ min: 0, step: 1 }}
            type="number"
            name="accruedInterest"
            value={creditFees.accruedInterest}
            onChange={handleInputChange}
          />

          <TextValidator
            fullWidth
            variant="outlined"
            label="Capital"
            inputProps={{ min: 0, step: 1 }}
            type="number"
            name="capital"
            value={creditFees.capital}
            onChange={handleInputChange}
          />

          <SelectValidator
            fullWidth
            variant="outlined"
            label="Estado"
            type="string"
            name="status"
            value={creditFees.status}
            onChange={handleInputChange}
            validators={['required']}
            errorMessages={['Campo requerido']}
          >
            {getStatuses('fee').map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </SelectValidator>

          {error && <AlertForm message={error} variant="error" />}

          <LoadingButton
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Guardar
          </LoadingButton>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

CreditFeesDialog.defaultProps = {
  isLoading: false,
  error: null,
  selectedCreditFees: null,
};

CreditFeesDialog.propTypes = {
  selectedCreditFees: PropTypes.shape({
    creditId: PropTypes.number,
    amount: PropTypes.number,
    estimatedPaymentDate: PropTypes.string,
    paymentDate: PropTypes.string,
    interest: PropTypes.number,
    accruedInterest: PropTypes.number,
    capital: PropTypes.number,
    status: PropTypes.string,
  }),
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

export default CreditFeesDialog;
