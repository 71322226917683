import {
  Box,
  Typography,
  Divider as DividerMui,
  styled,
  Drawer as DrawerMui,
} from '@material-ui/core';

export const Drawer = styled(DrawerMui)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});
export const ContainerRoot = styled(Box)({
  width: 333,
  padding: '25px 30px',
});

export const ContainerTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const Title = styled(Typography)({
  fontSize: '19.5px',
  fontWeight: 'bold',
});
export const Divider = styled(DividerMui)({
  marginBottom: 25,
});

export const ContainerBody = styled(Box)({
  minHeight: 'calc(100vh - 171px)',
});
export const ContainerCountData = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const CountDataTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#8D8A96',
  marginLeft: 8,
});
export const CountDataStatus = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#44414C',
});

export const ContainerTableScroll = styled(Box)({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 421px)',
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4,
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#44414C',
    borderRadius: 4,
  },
});

export const TableTextBold = styled(Typography)({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#8D8A96',
});

export const TableText = styled(Typography)({
  fontSize: 14,
  color: '#312F37',
});
