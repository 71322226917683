import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { fetchHistoryLogsService } from '../services/fetchHistoryLogsService';

const useFetchHistoryLogs = ({ contextId, contextType }) => {
  const dispatch = useDispatch();

  const {
    data: fetchHistoryLogsData,
    refetch: fetchHistoryLogsRefetch,
    isLoading: fetchHistoryLogsIsLoading,
    isError: fetchHistoryLogsError,
  } = useQuery(
    ['fetchHistoryLogs', contextId, contextType],
    () => fetchHistoryLogsService(contextId, contextType),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_LOGS_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    fetchHistoryLogsRefetch,
    fetchHistoryLogsData,
    fetchHistoryLogsIsLoading,
    fetchHistoryLogsError,
  };
};

export default useFetchHistoryLogs;
