import { useQuery } from 'react-query';
import { PayerCategorizationSummary } from '../../../commons/interfaces/payerCategorization';
import { fetchPayerCategorizationSummary } from '../services';

const useFetchPayerCategorizationSummary = (payerIdentifier: string) => {
  const placeholderData = {};

  const {
    isLoading,
    isPlaceholderData,
    data,
    refetch,
    status,
    error,
    isError,
  } = useQuery<PayerCategorizationSummary>(
    ['getPayerCategorizationSummary', payerIdentifier],
    async () => fetchPayerCategorizationSummary(payerIdentifier),
    {
      placeholderData,
      enabled: !!payerIdentifier,
    }
  );

  return {
    data,
    isLoading: isLoading || isPlaceholderData,
    refetch,
    status,
    error,
    isError,
  };
};

export default useFetchPayerCategorizationSummary;
