import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  STATUS_VERIFIED,
  STATUS_POST_VERIFIED,
  STATUS_REJECTED,
  STATUS_HARD_COLLECTION,
  ITEM_TYPE_INVOICE,
  ITEM_TYPE_BULK_INVOICE,
  STATUS_DEFAULT,
  STATUS_APPEALED,
} from '../../../helpers/Constants';
import { dateWithoutFormatter, isWeekend } from '../../../helpers/DateUtils';
import { features } from '../../../config/features';

const useUpdateInvoicesDialog = () => {
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(null);
  const [verificationStatus, setStatusVerification] = useState(null);
  const [baseRate, setBaseRate] = useState(null);
  const [bulkError, setBulkError] = useState(null);
  const [verificationStatusReason, setStatusVerificationReason] =
    useState(null);
  const [statusReason, setStatusReason] = useState(null);
  const [showDefaultDateInput, setShowDefaultDateInput] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(null);
  const [fundExpirationDate, setFundExpirationDate] = useState(null);
  const [notCalculateSurplus, setNotCalculateSurplus] = useState(false);
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];

  const { holidays } = useSelector(state => state.holidays);
  const countryHolidays = holidays?.[country];

  const [checkPayment, setCheckPayment] = useState({
    checkPaymentConfirmed: false,
    checkPaymentDate: false,
  });

  const showSelectReason = type => {
    return (
      countryFeatures.orderActions.changeStatusReasonInvoice &&
      [ITEM_TYPE_INVOICE, ITEM_TYPE_BULK_INVOICE].includes(type) &&
      [
        STATUS_HARD_COLLECTION,
        STATUS_VERIFIED,
        STATUS_POST_VERIFIED,
        STATUS_REJECTED,
        STATUS_APPEALED,
      ].includes(status)
    );
  };

  const showVerificationStatus =
    countryFeatures.orderActions.changeVerificationStatus &&
    verificationStatus !== null;

  const handleDisabledDates = date => {
    // Disable saturday and sunday on DatePickers
    return (
      isWeekend(date) ||
      countryHolidays?.some(h =>
        moment.utc(date).isSame(moment.utc(h.date), 'day')
      )
    );
  };

  const handleStatusChange = status => {
    setStatus(status);
    setShowDefaultDateInput(status === STATUS_DEFAULT);
    setDefaultDate(
      status === STATUS_DEFAULT ? dateWithoutFormatter(Date.now()) : null
    );
  };

  const handleCheck = () => {
    if (
      expirationDate ||
      paymentDate ||
      paymentConfirmed ||
      fundExpirationDate ||
      status
    ) {
      setChecked(!checked);
    }
  };

  const handleChangeCheckbox = event => {
    setCheckPayment({
      ...checkPayment,
      [event.target.name]: event.target.checked,
    });

    if (event.target.name === 'checkPaymentConfirmed') {
      setPaymentConfirmed(null);
    } else {
      setPaymentDate(null);
    }
  };

  return {
    handleStatusChange,
    handleCheck,
    showSelectReason,
    showVerificationStatus,
    setExpirationDate,
    setFundExpirationDate,
    setPaymentConfirmed,
    setShowDefaultDateInput,
    setPaymentDate,
    setDefaultDate,
    setStatus,
    setStatusReason,
    setStatusVerification,
    setStatusVerificationReason,
    setBaseRate,
    expirationDate,
    showDefaultDateInput,
    fundExpirationDate,
    paymentConfirmed,
    paymentDate,
    defaultDate,
    status,
    statusReason,
    verificationStatus,
    verificationStatusReason,
    baseRate,
    checked,
    setChecked,
    country,
    countryFeatures,
    handleDisabledDates,
    handleChangeCheckbox,
    checkPayment,
    setNotCalculateSurplus,
    notCalculateSurplus,
    setBulkError,
    bulkError,
  };
};

export default useUpdateInvoicesDialog;
