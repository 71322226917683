import {
  Button,
  Divider,
  Hidden,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  AGREEMENTS_SECTION_PERFORM,
  BLOCK_LIST_SECTION_PERFORM,
  BUSINESS_SECTION_PERFORM,
  COLLECTION_SECTION_PERFORM,
  CREDITS_SECTION_PERFORM,
  FUNDS_SECTION_PERFORM,
  PAYERS_SECTION_PERFORM,
  USERS_SECTION_PERFORM,
  REPORTS_SECTION_PERFORM,
} from '../helpers/performsType';
import Can from './Can';
import Container from './elements/Container';
import SideBarNavigationButton from './elements/SideBarNavigationButton';
import { features } from '../config/features';
import {
  MenuExpand,
  MenuCollapse,
  Orders2,
  Credits,
  Businesses,
  Collection,
  Blocklist,
  Payers,
  File,
  Financing,
  User,
  Wallet,
  Report,
} from './icons';
import { toggleCollapseHomeSideBar } from '../actions/configAction';

const useStyles = makeStyles({
  leftContainer: {
    padding: '15px 0 0',
    borderRight: '1px solid #edeff1',
    background: 'white',
    overflowY: 'scroll',
    zIndex: 999,
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  toggleButton: {
    borderTop: '1px solid white',
  },
  collapsableContainer: {
    maxHeight: 'calc(100% + 0px)',
    transition: '0.2s',
    '& a': {
      maxHeight: '100px',
      transition: '0.2s',
      overflow: 'hidden',
    },
    '&.closed': {
      maxHeight: '0px',
      overflow: 'hidden',
      '& a': {
        maxHeight: '0px',
        opacity: '0',
      },
    },
  },
  boxIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

const HomeSideBar = props => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { country: countryConfig = 'CL' } = useSelector(state => state.config);
  const country = countryConfig.toLowerCase();
  const countryFeatures = features[countryConfig];

  useEffect(() => {
    setIsExpanded(localStorage.getItem('isExpanded'));
    setIsSidebarOpen(localStorage.getItem('isSidebarOpen'));
  }, []);

  useEffect(() => {
    localStorage.setItem('isExpanded', isExpanded ? 'true' : '');
    localStorage.setItem('isSidebarOpen', isSidebarOpen ? 'true' : '');
  }, [isExpanded, isSidebarOpen]);

  useEffect(() => {
    dispatch(toggleCollapseHomeSideBar(isSidebarOpen));
  }, [isSidebarOpen]);

  const toggleButton = () => {
    setIsExpanded(!isExpanded);
  };

  const getNavigationButton = key => {
    switch (key) {
      case 'orders':
        return (
          <SideBarNavigationButton
            icon={Orders2}
            to={`/${country}/direct_financing`}
            withLabel={isSidebarOpen}
            active={
              location.pathname.startsWith(`/${country}/orders`) ||
              location.pathname.startsWith(`/${country}/direct_financing`) ||
              location.pathname.startsWith(`/${country}/early_payment`) ||
              location.pathname.startsWith(`/${country}/payments`)
            }
          >
            Operaciones
          </SideBarNavigationButton>
        );
      case 'credits':
        return (
          <Can
            perform={CREDITS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Credits}
                active={location.pathname.startsWith(`/${country}/credits`)}
                to={`/${country}/credits`}
                withLabel={isSidebarOpen}
              >
                Créditos
              </SideBarNavigationButton>
            )}
          />
        );
      case 'businesses':
        return (
          <Can
            perform={BUSINESS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Businesses}
                active={location.pathname.startsWith(`/${country}/business`)}
                to={`/${country}/business`}
                withLabel={isSidebarOpen}
              >
                Empresas
              </SideBarNavigationButton>
            )}
          />
        );
      case 'collection':
        return (
          <Can
            perform={COLLECTION_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Collection}
                active={location.pathname.startsWith(`/${country}/collection`)}
                to={`/${country}/collection`}
                withLabel={isSidebarOpen}
              >
                Cobranza
              </SideBarNavigationButton>
            )}
          />
        );
      case 'blocklist':
        return (
          <Can
            perform={BLOCK_LIST_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Blocklist}
                active={location.pathname.startsWith(
                  `/${country}/payerblocklist`
                )}
                to={`/${country}/payerblocklist`}
                withLabel={isSidebarOpen}
              >
                Blocklist
              </SideBarNavigationButton>
            )}
          />
        );
      case 'payers':
        return (
          <Can
            perform={PAYERS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Payers}
                active={location.pathname.startsWith(`/${country}/payers`)}
                to={`/${country}/payers`}
                withLabel={isSidebarOpen}
              >
                Pagadores
              </SideBarNavigationButton>
            )}
          />
        );
      case 'agreements':
        return (
          <Can
            perform={AGREEMENTS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                size="collapsed"
                icon={File}
                active={location.pathname.startsWith(`/${country}/agreements`)}
                to={`/${country}/agreements`}
                withLabel={isSidebarOpen}
              >
                Convenios
              </SideBarNavigationButton>
            )}
          />
        );
      case 'funds':
        return (
          <Can
            perform={FUNDS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Financing}
                active={location.pathname.startsWith(`/${country}/funds`)}
                to={`/${country}/funds`}
                withLabel={isSidebarOpen}
              >
                Fondos
              </SideBarNavigationButton>
            )}
          />
        );
      case 'users':
        return (
          <Can
            perform={USERS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={User}
                to={`/${country}/users`}
                active={location.pathname.startsWith(`/${country}/users`)}
                withLabel={isSidebarOpen}
              >
                Usuarios
              </SideBarNavigationButton>
            )}
            no={() => null}
          />
        );
      case 'wallets':
        return (
          <SideBarNavigationButton
            icon={Wallet}
            to={`/${country}/wallets`}
            active={location.pathname.startsWith(`/${country}/wallets`)}
            withLabel={isSidebarOpen}
          >
            Wallets
          </SideBarNavigationButton>
        );
      case 'reports':
        return (
          <Can
            perform={REPORTS_SECTION_PERFORM}
            yes={() => (
              <SideBarNavigationButton
                icon={Report}
                to={`/${country}/reports`}
                active={location.pathname.startsWith(`/${country}/reports`)}
                withLabel={isSidebarOpen}
              >
                Reportes regulatorios
              </SideBarNavigationButton>
            )}
            no={() => null}
          />
        );
      /* case 'bankMovements':
        return (
          <SideBarNavigationButton
            icon={Movements}
            to={`/${country}/bank-movements`}
            active={location.pathname.startsWith(`/${country}/bank-movements`)}
            withLabel={isSidebarOpen}
          >
            Movimientos bancarios
          </SideBarNavigationButton>
        ); */
      default:
        return null;
    }
  };

  return (
    <Hidden xsDown>
      <Container
        gridGap={10}
        height={`calc(100vh - ${props.stickyTop})`}
        className={classes.leftContainer}
        sticky={props.sticky}
        stickyTop={props.stickyTop}
      >
        <Tooltip
          title={isSidebarOpen ? 'Colapsar menú' : 'Expandir menú'}
          placement="right"
        >
          <Box color="grey.700" className={classes.boxIcon}>
            <IconButton
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              color="inherit"
            >
              {isSidebarOpen ? <MenuCollapse /> : <MenuExpand />}
            </IconButton>
          </Box>
        </Tooltip>

        {countryFeatures.homeSidebar
          .slice(0, 5)
          .map(buttonKey => getNavigationButton(buttonKey))}

        {countryFeatures.homeSidebar.length > 5 && (
          <>
            <>
              <Divider />
              <Button
                className={classes.toggleButton}
                onClick={() => toggleButton()}
              >
                {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </>

            <Container
              gridGap={10}
              className={`${classes.collapsableContainer} ${
                isExpanded ? '' : 'closed'
              }`}
            >
              {countryFeatures.homeSidebar
                .slice(5)
                .map(buttonKey => getNavigationButton(buttonKey))}
            </Container>
          </>
        )}
      </Container>
    </Hidden>
  );
};

HomeSideBar.propTypes = {
  sticky: PropTypes.bool.isRequired,
  stickyTop: PropTypes.string.isRequired,
};

export default withTheme(HomeSideBar);
