import { CommentCategory } from '../interfaces/collection';
import {CONFIRMING, DIRECT_FINANCING, EARLY_PAYMENT} from "../../../helpers/Constants";

export const commentCategory: CommentCategory[] = [
  { value: 'FUND', label: 'Fondo' },
  { value: 'PAYER', label: 'Pagador' },
  { value: 'XEPELIN', label: 'Xepelin' },
  { value: 'CLIENT', label: 'Cliente' },
];

export const commentCategoryDictionary = {
  FUND: 'Fondo',
  PAYER: 'Pagador',
  XEPELIN: 'Xepelin',
  CLIENT: 'Cliente',
};

export const selectPriority = [
  { value: 'MONTO', label: 'Monto total' },
  { value: 'TRAMO', label: 'Tramo de mora más crítico' },
  { value: 'VENCIMIENTO', label: 'Próximo vencimiento' },
  { value: '', label: 'Predeterminado'},
];

export const renegotiationTypes = [
  { value: 'OFFICIAL', label: 'Prórroga' },
  { value: 'UNOFFICIAL', label: 'Promesa de pago' },
];

export const operationTypes = {
  DIRECT_FINANCING: 'FD',
  EARLY_PAYMENT: 'PP',
  CONFIRMING: 'Payments',
};

export const invoiceInformationTabs = [
  { value: 'Summary', label: 'Resumen' },
  { value: 'Managements', label: 'Gestiones' },
]
