import { Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { managementLabels } from '../../../../commons/utils/managementOptions';
import useFetchManagementDebtByInvoice from '../../../infrastructure/store/useFetchManagementDebtByInvoice';
import Text from '../../../../commons/components/Text';
import { useStyles } from './styles';

interface Props {
  invoiceId: number;
}

const ManagementTab = ({ invoiceId }: Props) => {
  const classes = useStyles();
  const { country } = useSelector((state: RootStateOrAny) => state.config);
  const { data, refetch: refetchManagement } =
    useFetchManagementDebtByInvoice(invoiceId);

  useEffect(() => {
    if (invoiceId) {
      refetchManagement();
    }
  }, [invoiceId]);

  return (
    <ul style={{ margin: 0, padding: 0 }}>
      {data?.map(managament => (
        <>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
              marginTop: 15,
            }}
          >
            <Text fontWeight='bold'>Gestión</Text>
            <Text style={{ marginLeft: 'auto' }}>
              {!data ? (
                <Skeleton variant="text" />
              ) : (
                managementLabels[country][managament.type].label
              )}
            </Text>
          </li>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <Text fontWeight='bold'>Canal</Text>
            <Text style={{ marginLeft: 'auto' }}>
              {!data ? (
                <Skeleton variant="text" />
              ) : (
                managementLabels[country][managament.type].subTypes[
                  managament.subType
                ].label
              )}
            </Text>
          </li>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <Text fontWeight='bold'>Resultado</Text>
            <Text style={{ marginLeft: 'auto' }}>
              {!data ? (
                <Skeleton variant="text" />
              ) : (
                managementLabels[country][managament.type].subTypes[
                  managament.subType
                ].result[managament.result]
              )}
            </Text>
          </li>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <Text fontWeight='bold'>Fecha realizada</Text>
            <Text style={{ marginLeft: 'auto' }}>
              {!data ? (
                <Skeleton variant="text" />
              ) : (
                format(new Date(managament.createdAt), 'dd-MM-yyy')
              )}
            </Text>
          </li>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <Text fontWeight='bold'>Gestor</Text>
            <Text style={{ marginLeft: 'auto' }}>
              {!data ? <Skeleton variant="text" /> : managament.createdBy}
            </Text>
          </li>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            <Text fontWeight='bold'>Comentario</Text>
          </li>
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 0',
              marginBottom: 15
            }}
          >
            <Text className={classes.detailLabel}>
              {!data ? <Skeleton variant="text" /> : managament.detail}
            </Text>
          </li>
          <Divider />
        </>
      ))}
    </ul>
  );
};

export default ManagementTab;
