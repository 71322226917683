import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useCallback } from 'react';

import { enqueueSnackbar } from '../../../actions/notificationAction';

export const useGetCountry = () =>
  useSelector(({ config }) => config.country, shallowEqual);

export const useBoActions = () => {
  const dispatch = useDispatch();

  const showSnackBar = useCallback(
    ({ msg, type }) => dispatch(enqueueSnackbar(msg, { variant: type })),
    [dispatch]
  );

  return { showSnackBar };
};
