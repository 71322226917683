import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const verifyDocumentsStatusService = async (
  documentableType,
  documentableId
) => {
  try {
    const { data } = await axios.get(
      `/api/${documentableType}/${documentableId}/document/completed`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { verifyDocumentsStatusService };
