import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateOrderInvoiceFundService = async (
  orderInvoiceFundId,
  newFundFinancing
) => {
  try {
    const { data } = await axios.put(
      `/api/orderinvoicefund/${orderInvoiceFundId}/rates`,
      newFundFinancing
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateOrderInvoiceFundService;
