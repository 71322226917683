import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  tableContainer: {
    background: 'white',
    borderRadius: 17,
    border: '1px solid #eee',
  },
  returnLink: {
    margin: '15px 0 0 0',
    display: 'inline-block',
    color: '#8D8A96',
    cursor: 'pointer',
    '& span': {
      position: 'relative',
      top: 2,
      textDecoration: 'underline',
    },
  },
  containerTextMainAreaBold: {
    marginRight: 20,
  },
  textMainAreaBold: {
    marginRight: 5,
  },
});
