import { makeStyles } from '@material-ui/core';

import { DRAWER_SECONDARY } from '../../../../../theme/otherColors';

export const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '380px',
    background: 'white',
    '& p': {
      margin: 0,
      paddingTop: 12,
      paddingBottom: 12,
      '&:hover': {
        backgroundColor: '#E8E6EF',
      },
    },
  },
  appBar: {
    padding: '20px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    position: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      bottom: 0,
      left: 20,
      width: 'calc(100% - 40px)',
      height: 1,
      borderBottom: '1px solid #CDCAD8',
    },
  },
  toolBar: {
    minHeight: 'auto',
    padding: 0,
  },
  navTitle: {
    flex: '1',
  },
  drawerTitle: {
    fontSize: 18,
  },
  container: {
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 700,
    padding: '25px 20px',
    backgroundColor: 'white',
    '& p span': {
      float: 'right',
      color: '#312F37',
      textAlign: 'right',
    },
    '& p': {
      color: '#8D8A96',
    },
  },
  hasEllipsis: {
    width: 230,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cartSummary: {
    backgroundColor: '#1C1732',
    color: '#ffffff',
    padding: '5px 20px',
  },
  cartBody: {
    paddingTop: 30,
  },
  singleDebtor: {
    position: 'relative',
    marginBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: '1px solid #CDCAD8',
    '&:last-of-type': {
      marginBottom: 130,
    },
    '& h4': {
      width: 'calc(100% - 50px)',
      margin: 0,
    },
  },
  trashBinIcon: {
    position: 'absolute',
    right: 12,
    top: 12,
    cursor: 'pointer',
  },
  invoicesDetail: {
    marginBottom: 22,
    '&.open a + div': {
      display: 'block',
    },
    '&.open a svg': {
      transform: 'rotate(180deg)',
    },
  },
  summaryText: {
    color: '#8D8A96',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  invoicesSummary: {
    display: 'none',
    marginTop: 20,
    '& > div': {
      marginBottom: 12,
    },
    '& > div:hover': {
      backgroundColor: '#E8E6EF',
    },
    '& span': {
      color: '#8D8A96',
    },
    '& .folio': {
      display: 'inline-block',
      marginRight: 25,
      width: 30,
    },
    '& .issuer-name': {
      width: 140,
      display: 'inline-block',
      marginRight: 25,
      position: 'relative',
      top: 2,
    },
    '& .amount span': {
      color: '#312F37',
      position: 'relative',
      top: -4,
    },
  },
  chevronDown: {
    width: 12,
    height: 8,
    marginLeft: 10,
    transition: '.2s',
  },
  cartFooter: {
    padding: 20,
    position: 'fixed',
    bottom: 0,
    width: 380,
    background: 'white',
  },
  footerSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 700,
    color: '#312F37',
    marginBottom: 15,
  },
  grayText: {
    paddingTop: 18,
    textAlign: 'center',
    color: '#8D8A96',
    fontSize: 14,
    '& svg': {
      marginRight: 12,
      position: 'relative',
      top: 7,
    },
  },
  collapseDrawer: {
    cursor: 'pointer',
    color: '#8D8A96',
  },
}));
