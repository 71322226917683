import {
  FETCH_TAX_FOLDER_START,
  FETCH_TAX_FOLDER_SUCCESS,
  FETCH_TAX_FOLDER_FAILURE,
  CHECK_TAX_FOLDER_START,
  CHECK_TAX_FOLDER_SUCCESS,
  CHECK_TAX_FOLDER_FAILURE,
} from '../actions/types';

const initialState = {
  pdfTaxFolderIsLoading: false,
  pdfTaxFolder: null,

  htmlTaxFolderIsLoading: false,
  htmlTaxFolder: null,

  fetchError: null,

  hasTaxFolder: false,
  checkTaxFolderIsLoading: false,
  checkTaxFolderError: null,
};

const fetchTaxFolderStart = (state, payload) => {
  return {
    ...state,
    fetchError: null,
    [`${payload.type}TaxFolderIsLoading`]: true,
  };
};
const fetchTaxFolderSuccess = (state, payload) => {
  const { type, response } = payload;
  return {
    ...state,
    [`${type}TaxFolderIsLoading`]: false,
    [`${type}TaxFolder`]: response,
  };
};
const fetchTaxFolderFailure = (state, payload) => {
  const { type, errorCode } = payload;
  return {
    ...state,
    [`${type}TaxFolder`]: null,
    [`${type}TaxFolderIsLoading`]: false,
    fetchError: errorCode,
  };
};

const checkTaxFolderStart = state => {
  return {
    ...state,
    checkTaxFolderError: null,
    checkTaxFolderIsLoading: true,
  };
};
const checkTaxFolderSuccess = (state, payload) => {
  return {
    ...state,
    hasTaxFolder: payload.hasTaxFolder,
    checkTaxFolderIsLoading: false,
  };
};
const checkTaxFolderFailure = (state, payload) => {
  return {
    ...state,
    checkTaxFolderError: payload.errorCode,
    checkTaxFolderIsLoading: true,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TAX_FOLDER_START:
      return fetchTaxFolderStart(state, payload);
    case FETCH_TAX_FOLDER_SUCCESS:
      return fetchTaxFolderSuccess(state, payload);
    case FETCH_TAX_FOLDER_FAILURE:
      return fetchTaxFolderFailure(state, payload);
    case CHECK_TAX_FOLDER_START:
      return checkTaxFolderStart(state);
    case CHECK_TAX_FOLDER_SUCCESS:
      return checkTaxFolderSuccess(state, payload);
    case CHECK_TAX_FOLDER_FAILURE:
      return checkTaxFolderFailure(state, payload);
    default:
      return state;
  }
};
