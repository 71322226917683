import React, { FC } from 'react';
import {
  IconButton,
  Button,
  Box,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import {
  Drawer,
  ContainerRoot,
  TitleContainer,
  Title,
  Divider,
  ContainerData,
  ContainerInputs,
  ContainerScroll,
  SubTitle,
  ContainerComments,
  Comments,
  ContainerCommentsTitle,
  CommentsTitle,
  CommentsTitleDate,
  ContainerCommentsBody,
  CommentsBodyText,
  CommentsTagTitle,
  Tags,
  CommentsAttachmentsTitle,
  CommentsAttachmentsBody,
  TextNotFound,
  CommentsBodyTextEliminated,
} from './styles';
import Avatar from '../Avatar';
import OrderCommentsInput from './ui/OrderCommentsInput';
import OrderCommentsDropzone from './ui/OrderCommentsDropzone';
import OrderCommentsSkeleton from './ui/OrderCommentsSkeleton';
import OrderCommentsTags from './ui/OrderCommentsTags';
import { Comment, FlagType } from './interfaces';
import useOrderComments from './hooks/useOrderComments';
import CloseIcon from '../../icons/CloseIcon';
import AddCircleIcon from '../../icons/AddCircleIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import PdfIcon from '../../icons/PdfIcon';
import ImageIcon from '../../icons/ImageIcon';
import ImgVisualizer from './ui/ImgVisualizer';
import PdfVisualizer from './ui/PdfVisualizer';

interface Props {
  isOpen: boolean;
  flagType: FlagType;
  operationId: number;
  handleCloseDrawer: () => void;
}

const OrderCommentsDrawer: FC<Props> = ({
  isOpen,
  flagType,
  operationId,
  handleCloseDrawer,
}) => {
  const { authState } = useOktaAuth();
  const {
    commentAttachmentSelected,
    showVisualizerImgDialog,
    showVisualizerPdfDialog,
    showCommentInput,
    showDropZoneInput,
    showTagsInput,
    commentInformation,
    users,
    orderComments,
    commentIdSelected,
    usersIsLoading,
    fetchOrderCommentsIsLoading,
    addOrderCommentIsLoading,
    updateOrderCommentIsLoading,
    downloadOrderCommentAttachmentIsLoading,
    handleOpenCommentInput,
    handleCloseCommentInput,
    handleToggleDropZoneInput,
    handleToggleTagsInput,
    handleChangeCommentData,
    handleChangeDropzone,
    handleChangeTagSelector,
    handleGetDateTimeCommentFormat,
    handleFilterCurrentUser,
    handleMaxWords,
    handleGetExtensionFile,
    handleSetOrderCommentIdSelected,
    handleAddOrderComment,
    handleUpdateOrderComment,
    handleShowVisualizerImgDialog,
    handleCloseVisualizerImgDialog,
    handleShowVisualizerPdfDialog,
    handleCloseVisualizerPdfDialog,
  } = useOrderComments(operationId, flagType);

  const containerSubTitleRef: HTMLElement | null =
    document.getElementById('containerSubTitle');

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer}>
        <ContainerRoot>
          <TitleContainer>
            <Title>Historial de comentarios</Title>
            <IconButton
              onClick={handleCloseDrawer}
              data-cy="orderCommentsCloseButton"
            >
              <CloseIcon color="#000" />
            </IconButton>
          </TitleContainer>

          <Divider />
          <ContainerData>
            <ContainerInputs>
              <Box mr={2}>
                <Avatar name={authState?.idToken?.claims?.name || ''} />
              </Box>
              {showCommentInput ? (
                <Box display="flex" flexDirection="column">
                  <OrderCommentsInput
                    commentInformation={commentInformation}
                    showDropZoneInput={showDropZoneInput}
                    showTagsInput={showTagsInput}
                    isLoading={addOrderCommentIsLoading}
                    isDisabled={addOrderCommentIsLoading}
                    handleChangeCommentData={handleChangeCommentData}
                    handleCloseCommentInput={handleCloseCommentInput}
                    handleToggleDropZoneInput={handleToggleDropZoneInput}
                    handleToggleTagsInput={handleToggleTagsInput}
                    handleSubmit={() =>
                      handleAddOrderComment(commentInformation)
                    }
                  />
                  <OrderCommentsTags
                    isCollapse={showTagsInput}
                    users={handleFilterCurrentUser(users)}
                    selectedUsers={commentInformation.tagsData}
                    isLoading={usersIsLoading}
                    isDisabled={addOrderCommentIsLoading}
                    handleChangeTagSelector={handleChangeTagSelector}
                  />
                  <OrderCommentsDropzone
                    isCollapse={showDropZoneInput}
                    handleChangeDropzone={handleChangeDropzone}
                    isDisabled={addOrderCommentIsLoading}
                  />
                </Box>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  endIcon={<AddCircleIcon />}
                  onClick={handleOpenCommentInput}
                  data-cy="orderCommentsAddCommentButton"
                >
                  Agregar Comentario
                </Button>
              )}
            </ContainerInputs>

            <ContainerScroll>
              <SubTitle id="containerSubTitle">
                Historial de comentarios
              </SubTitle>
              <ContainerComments>
                {fetchOrderCommentsIsLoading ? (
                  <Comments>
                    <OrderCommentsSkeleton />
                  </Comments>
                ) : (
                  <>
                    {orderComments?.map(
                      ({
                        id,
                        comment,
                        userName,
                        userEmail,
                        deleted,
                        createdAt,
                        CommentAttachments: commentAttachments,
                        CommentTags: commentTags,
                      }: Comment) => (
                        <Comments key={id}>
                          <ContainerCommentsTitle>
                            <Box display="flex" alignItems="center">
                              <Avatar name={userName} />
                              <CommentsTitle>{userName}</CommentsTitle>
                              <CommentsTitleDate>
                                {handleGetDateTimeCommentFormat(createdAt)}
                              </CommentsTitleDate>
                            </Box>

                            {!deleted &&
                              authState?.idToken?.claims?.email ===
                                userEmail && (
                                <>
                                  {updateOrderCommentIsLoading &&
                                  commentIdSelected === id ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Tooltip title="Eliminar comentario" arrow>
                                      <IconButton
                                        disabled={updateOrderCommentIsLoading}
                                        onClick={() => {
                                          handleSetOrderCommentIdSelected(id);
                                          handleUpdateOrderComment(id, {
                                            deleted: true,
                                          });
                                        }}
                                        data-cy="orderCommentsDeleteCommentButton"
                                      >
                                        <DeleteIcon color="#000" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                          </ContainerCommentsTitle>

                          <ContainerCommentsBody>
                            {deleted ? (
                              <CommentsBodyTextEliminated data-cy="orderCommentsBodyCommentButton">
                                Comentario eliminado
                              </CommentsBodyTextEliminated>
                            ) : (
                              <>
                                <CommentsBodyText data-cy="orderCommentsBodyCommentButton">
                                  {comment}
                                </CommentsBodyText>
                                {commentTags?.length !== 0 && (
                                  <Box>
                                    <CommentsTagTitle>
                                      Personas asignadas:
                                    </CommentsTagTitle>
                                    <Box display="flex" flexWrap="wrap">
                                      {commentTags?.map(commentTag => (
                                        <Tags
                                          key={commentTag.id}
                                          data-cy="orderCommentsTagCommentButton"
                                        >
                                          {commentTag.userName}
                                        </Tags>
                                      ))}
                                    </Box>
                                  </Box>
                                )}
                                {commentAttachments?.length !== 0 && (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    flexWrap="wrap"
                                  >
                                    <CommentsAttachmentsTitle>
                                      Descarga de documentos adjuntos:
                                    </CommentsAttachmentsTitle>

                                    {commentAttachments?.map(
                                      commentAttachment => (
                                        <CommentsAttachmentsBody key={id}>
                                          <Tooltip
                                            title={handleMaxWords(
                                              commentAttachment.fileName,
                                              30
                                            )}
                                            arrow
                                          >
                                            <IconButton
                                              disabled={
                                                downloadOrderCommentAttachmentIsLoading
                                              }
                                              onClick={() => {
                                                handleSetOrderCommentIdSelected(
                                                  id
                                                );
                                                if (
                                                  handleGetExtensionFile(
                                                    commentAttachment?.path
                                                  ).includes('pdf')
                                                ) {
                                                  handleShowVisualizerPdfDialog(
                                                    commentAttachment
                                                  );
                                                } else {
                                                  handleShowVisualizerImgDialog(
                                                    commentAttachment
                                                  );
                                                }
                                              }}
                                              data-cy="orderCommentsDownloadDocumentButton"
                                            >
                                              {handleGetExtensionFile(
                                                commentAttachment?.path
                                              ).includes('pdf') ? (
                                                <PdfIcon color="#000" />
                                              ) : (
                                                <ImageIcon color="#000" />
                                              )}
                                            </IconButton>
                                          </Tooltip>
                                        </CommentsAttachmentsBody>
                                      )
                                    )}
                                    {downloadOrderCommentAttachmentIsLoading &&
                                      commentIdSelected === id && (
                                        <CircularProgress size={20} />
                                      )}
                                  </Box>
                                )}
                              </>
                            )}
                          </ContainerCommentsBody>
                        </Comments>
                      )
                    )}
                    {orderComments?.length === 0 && (
                      <TextNotFound>
                        Nadie a realizado comentarios asociados a esta operación
                      </TextNotFound>
                    )}
                  </>
                )}
              </ContainerComments>
            </ContainerScroll>
          </ContainerData>
          <Divider />
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => containerSubTitleRef?.scrollIntoView()}
              variant="text"
              color="primary"
            >
              Volver al inicio
            </Button>
          </Box>
        </ContainerRoot>
      </Drawer>

      {showVisualizerImgDialog && (
        <ImgVisualizer
          isOpen={showVisualizerImgDialog}
          commentAttachment={commentAttachmentSelected}
          handleCloseDialog={handleCloseVisualizerImgDialog}
        />
      )}

      {showVisualizerPdfDialog && (
        <PdfVisualizer
          isOpen={showVisualizerPdfDialog}
          commentAttachment={commentAttachmentSelected}
          handleCloseDialog={handleCloseVisualizerPdfDialog}
        />
      )}
    </>
  );
};

export default OrderCommentsDrawer;
