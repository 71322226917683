import {
  FETCH_GEO_MX_COLONY_FAILURE,
  FETCH_GEO_MX_COLONY_START,
  FETCH_GEO_MX_COLONY_SUCCESS,
  FETCH_GEO_MX_MUNICIPALITY_FAILURE,
  FETCH_GEO_MX_MUNICIPALITY_START,
  FETCH_GEO_MX_MUNICIPALITY_SUCCESS,
  FETCH_GEO_MX_STATES_FAILURE,
  FETCH_GEO_MX_STATES_START,
  FETCH_GEO_MX_STATES_SUCCESS,
} from '../actions/types';

const initialState = {
  mxStates: [],
  mxMunicipalities: [],
  mxColonies: [],
  geoIsLoading: false,
  geoError: null,
};

const fetchGeoMXStateStart = state => {
  return {
    ...state,
    geoIsLoading: true,
    geoError: null,
  };
};

const fetchGeoMXStateSuccess = (state, payload) => {
  return {
    ...state,
    mxStates: [...payload.mxStates],
    geoIsLoading: false,
  };
};

const fetchGeoMXStateFailure = (state, payload) => {
  return {
    ...state,
    geoIsLoading: false,
    geoError: payload.errorCode,
  };
};

const fetchGeoMXMunicipalityStart = state => {
  return {
    ...state,
    geoIsLoading: true,
    geoError: null,
  };
};

const fetchGeoMXMunicipalitySuccess = (state, payload) => {
  return {
    ...state,
    mxMunicipalities: [...payload.mxMunicipalities],
    geoIsLoading: false,
  };
};

const fetchGeoMXMunicipalityFailure = (state, payload) => {
  return {
    ...state,
    geoIsLoading: false,
    geoError: payload.errorCode,
  };
};

const fetchGeoMXColonyStart = state => {
  return {
    ...state,
    geoIsLoading: true,
    geoError: null,
  };
};

const fetchGeoMXColonySuccess = (state, payload) => {
  return {
    ...state,
    mxColonies: payload.mxColonies,
    geoError: false,
  };
};

const fetchGeoMXColonyFailure = (state, payload) => {
  return {
    ...state,
    geoIsLoading: false,
    geoError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_GEO_MX_STATES_START:
      return fetchGeoMXStateStart(state);
    case FETCH_GEO_MX_STATES_SUCCESS:
      return fetchGeoMXStateSuccess(state, payload);
    case FETCH_GEO_MX_STATES_FAILURE:
      return fetchGeoMXStateFailure(state, payload);
    case FETCH_GEO_MX_MUNICIPALITY_START:
      return fetchGeoMXMunicipalityStart(state);
    case FETCH_GEO_MX_MUNICIPALITY_SUCCESS:
      return fetchGeoMXMunicipalitySuccess(state, payload);
    case FETCH_GEO_MX_MUNICIPALITY_FAILURE:
      return fetchGeoMXMunicipalityFailure(state, payload);
    case FETCH_GEO_MX_COLONY_START:
      return fetchGeoMXColonyStart(state);
    case FETCH_GEO_MX_COLONY_SUCCESS:
      return fetchGeoMXColonySuccess(state, payload);
    case FETCH_GEO_MX_COLONY_FAILURE:
      return fetchGeoMXColonyFailure(state, payload);
    default:
      return state;
  }
};
