import { Box, Typography, styled } from '@material-ui/core';

export const MainContainer = styled(Box)({
  minHeight: 'calc(100vh - 163px)',
  marginBottom: 10,
});

export const RequirementContainerTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RequirementTitle = styled(Typography)({
  fontSize: 16,
  color: '#0A1334',
});

export const RequirementStatus = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  color: '#0A1334',
});

export const RequirementDataTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  color: '#312F37',
});

export const RequirementDataRow = styled(Box)({
  minHeight: 33,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px 0 5px',
  borderBottom: '1px solid #CDCAD8',
});
export const RequirementDataRowText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  color: '#8D8A96',
  marginLeft: 15,
});
