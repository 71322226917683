/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  CONSULT_DEBT_SERVICE_START,
  CONSULT_DEBT_SERVICE_SUCCESS,
  CONSULT_DEBT_SERVICE_FAILURE,
  RESET_CONSULT_DEBT_SERVICE,
  FETCH_DEBT_SERVICE_START,
  FETCH_DEBT_SERVICE_SUCCESS,
  FETCH_DEBT_SERVICE_FAILURE,
  FETCH_DEBT_SERVICE_BYIDENTIFIER_START,
  FETCH_DEBT_SERVICE_BYIDENTIFIER_SUCCESS,
  FETCH_DEBT_SERVICE_BYIDENTIFIER_FAILURE,
  CONSULT_DEBT_SERVICE_BYIDENTIFIER_START,
  CONSULT_DEBT_SERVICE_BYIDENTIFIER_SUCCESS,
  CONSULT_DEBT_SERVICE_BYIDENTIFIER_FAILURE,
  CONSULT_INVOICE_DEBT_SERVICE_START,
  CONSULT_INVOICE_DEBT_SERVICE_SUCCESS,
  CONSULT_INVOICE_DEBT_SERVICE_FAILURE,
  BULK_FETCH_DEBT_SERVICE_START,
  BULK_FETCH_DEBT_SERVICE_SUCCESS,
  BULK_FETCH_DEBT_SERVICE_FAILURE,
  FETCH_DEBT_SUPER_START,
  FETCH_DEBT_SUPER_SUCCESS,
  FETCH_DEBT_SUPER_FAILURE,
  CONSULT_DEBT_SUPER_START,
  CONSULT_DEBT_SUPER_SUCCESS,
  CONSULT_DEBT_SUPER_FAILURE,
  CONSULT_INVOICE_DEBT_SUPER_START,
  CONSULT_INVOICE_DEBT_SUPER_SUCCESS,
  CONSULT_INVOICE_DEBT_SUPER_FAILURE,
  BULK_FETCH_DEBT_SUPER_START,
  BULK_FETCH_DEBT_SUPER_SUCCESS,
  BULK_FETCH_DEBT_SUPER_FAILURE,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys';

export const fetchDebtService = (entityId, entityType) => dispatch => {
  dispatch({ type: FETCH_DEBT_SERVICE_START });
  axios
    .get(`/api/${entityType}/${entityId}/debtservice`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_DEBT_SERVICE_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_DEBT_SERVICE_FAILURE, payload: { errorCode } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_DEBT_SERVICE_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const consultDebtService =
  (entityId, entityType, identifier) => dispatch => {
    dispatch({ type: CONSULT_DEBT_SERVICE_START });
    axios
      .get(`/api/${entityType}/${entityId}/debtservice/consult`, {
        params: { identifier },
      })
      .then(res => {
        const resData = res?.data?.response ?? res?.data;
        dispatch({
          type: CONSULT_DEBT_SERVICE_SUCCESS,
          payload: resData,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DEBT_SERVICE_CONSULTED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CONSULT_DEBT_SERVICE_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONSULT_DEBT_SERVICE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const resetConsultDebtService = () => dispatch => {
  dispatch({ type: RESET_CONSULT_DEBT_SERVICE });
};

export const fetchDebtServiceByIdentifier =
  (entityId, entityType) => dispatch => {
    dispatch({
      type: FETCH_DEBT_SERVICE_BYIDENTIFIER_START,
      payload: { entityId, entityType },
    });
    axios
      .get(`/api/${entityType}/${entityId}/debtservice`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_DEBT_SERVICE_BYIDENTIFIER_SUCCESS,
          payload: { entityId, entityType, data },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_DEBT_SERVICE_BYIDENTIFIER_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_DEBT_SERVICE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const consultDebtServiceByIdentifier =
  (entityId, entityType, identifier) => dispatch => {
    dispatch({ type: CONSULT_DEBT_SERVICE_BYIDENTIFIER_START });
    axios
      .get(`/api/${entityType}/${entityId}/debtservice/consult`, {
        params: { identifier },
      })
      .then(({ data }) => {
        dispatch({
          type: CONSULT_DEBT_SERVICE_BYIDENTIFIER_SUCCESS,
          payload: { entityId, entityType, data },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CONSULT_DEBT_SERVICE_BYIDENTIFIER_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONSULT_DEBT_SERVICE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const consultInvoiceDebtService =
  (entityId, entityType, identifier) => dispatch => {
    dispatch({ type: CONSULT_INVOICE_DEBT_SERVICE_START });
    axios
      .get(`/api/${entityType}/${entityId}/debtservice/consult`, {
        params: { identifier },
      })
      .then(({ data }) => {
        dispatch({
          type: CONSULT_INVOICE_DEBT_SERVICE_SUCCESS,
          payload: data?.response ?? data,
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DEBT_SERVICE_CONSULTED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CONSULT_INVOICE_DEBT_SERVICE_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CONSULT_DEBT_SERVICE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const bulkFetchDebtService =
  (entityId, entityType, identifiers) => dispatch => {
    dispatch({ type: BULK_FETCH_DEBT_SERVICE_START });
    axios
      .post(`/api/${entityType}/${entityId}/debtservice/bulk/fetch`, {
        identifiers,
      })
      .then(({ data }) => {
        dispatch({
          type: BULK_FETCH_DEBT_SERVICE_SUCCESS,
          payload: { bulkDebtService: data },
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: BULK_FETCH_DEBT_SERVICE_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BULK_CONSULT_DEBT_SERVICE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const fetchDebtSuper = identifier => dispatch => {
  dispatch({ type: FETCH_DEBT_SUPER_START });
  axios
    .get(`/api/debtsuper/${identifier}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_DEBT_SUPER_SUCCESS,
        payload: { debtSuper: data },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_DEBT_SUPER_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_DEBT_SUPER_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const consultDebtSuper = identifier => dispatch => {
  dispatch({ type: CONSULT_DEBT_SUPER_START });
  axios
    .post(`/api/debtsuper/${identifier}`)
    .then(({ data }) => {
      dispatch({
        type: CONSULT_DEBT_SUPER_SUCCESS,
        payload: { debtSuper: data },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DEBT_SUPER_CONSULTED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: CONSULT_DEBT_SUPER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const consultInvoiceDebtSuper = identifier => dispatch => {
  dispatch({ type: CONSULT_INVOICE_DEBT_SUPER_START });
  axios
    .post(`/api/debtsuper/${identifier}`)
    .then(({ data }) => {
      dispatch({
        type: CONSULT_INVOICE_DEBT_SUPER_SUCCESS,
        payload: { debtSuper: data },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DEBT_SUPER_CONSULTED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: CONSULT_INVOICE_DEBT_SUPER_FAILURE,
        payload: { errorCode },
      });
    });
};

export const bulkFetchDebtSuper = identifiers => dispatch => {
  dispatch({ type: BULK_FETCH_DEBT_SUPER_START });
  axios
    .post('/api/debtsuper/bulk/fetch', { identifiers })
    .then(({ data }) => {
      dispatch({
        type: BULK_FETCH_DEBT_SUPER_SUCCESS,
        payload: { bulkDebtSuper: data },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: BULK_FETCH_DEBT_SUPER_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.BULK_CONSULT_DEBT_SUPER_FAILURE, {
          variant: 'error',
        })
      );
    });
};
