import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import {
  ApolloLink,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_APOLLO_NOTIFICATIONS_URL,
  REACT_APP_APOLLO_NOTIFICATIONS_API_KEY,
} = process.env;

const url = REACT_APP_APOLLO_NOTIFICATIONS_URL;
const region = REACT_APP_AWS_REGION;
const auth = {
  type: 'API_KEY',
  apiKey: REACT_APP_APOLLO_NOTIFICATIONS_API_KEY,
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
