import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateOrderAttributeService = async (orderId, attribute) => {
  try {
    const { data } = await axios.patch(`/api/payment/order/${orderId}/detail`, {
      ...attribute,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateOrderAttributeService;
