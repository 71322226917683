import { Button } from '@material-ui/core';
import React from 'react';
import { useSetUseAddDrawer } from '../../InvoicesTable/store';
// import PropTypes from 'prop-types';

const AddInvoicesButton = () => {
  const setUseAddDrawer = useSetUseAddDrawer();
  return (
    <Button
      onClick={() => setUseAddDrawer(true)}
      variant="contained"
      color="primary"
    >
      Agregar facturas
    </Button>
  );
};

AddInvoicesButton.propTypes = {};

export default AddInvoicesButton;
