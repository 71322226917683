import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import AlertForm from '../elements/AlertForm';
import useStatuses from '../../hooks/useStatuses';

const useStyles = makeStyles({
  selectInput: {
    marginBottom: 10,
  },
});

const ChangeSimpleStatusDialog = ({
  open,
  handleCloseDialog,
  title,
  handleSubmit,
  entityType,
  status,
  error,
  isLoading,
  warning,
  description,
}) => {
  const classes = useStyles();
  const { getStatuses } = useStatuses();

  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState(status);

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
    >
      <ValidatorForm onSubmit={() => handleSubmit(statusSelected)}>
        <Grid container>
          <Grid item xs={12} className={classes.selectInput}>
            <SelectValidator
              fullWidth
              variant="outlined"
              id="changeOrderState"
              value={statusSelected}
              onChange={e => setStatusSelected(e.target.value)}
              label="Estado"
              validators={['required']}
              errorMessages={['Seleccione una opción']}
            >
              {getStatuses(entityType).map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>

          {!isLoading && error && <AlertForm variant="error" message={error} />}

          {!isLoading && warning && (
            <AlertForm variant="info" messageComponent={warning} />
          )}

          <CheckButton
            check={checked}
            handleCheck={() => setChecked(!checked)}
            labelButton="Guardar"
            loading={isLoading}
            disabled={isLoading}
          />
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

ChangeSimpleStatusDialog.defaultProps = {
  status: null,
  error: null,
  warning: null,
  description: null,
};

ChangeSimpleStatusDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
  warning: PropTypes.string,
  status: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  entityType: PropTypes.oneOf(['credit', 'fee']).isRequired,
  description: PropTypes.string,
};

export default ChangeSimpleStatusDialog;
