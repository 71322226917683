import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Typography,
  Grid,
  Box,
  AppBar,
  Toolbar,
  CircularProgress,
} from '@material-ui/core';

import { Close } from '../../../../../../components/icons';
import { t13s } from '../../../../../../translationKeys';
import StatusChip from '../../../../../../components/elements/chips/StatusChip';
import {
  dateToDDMMYYYY,
  defaultValue,
  getDaysOfIssueInDays,
  getRangeOfDays,
} from '../../../../../commons/utils';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import { useStyles } from './styles';
import { fetchInvoiceById } from '../../../../domains/invoice/services/invoice.repo';
import { useFetch } from '../../../../../commons/hooks/useFetch';
import { OPERATION_TYPE } from '../../../../../commons/constants/operations';

const InvoiceDetailComponent = ({ onClose, payrollInvoiceId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: response, isLoading } = useFetch(
    () => fetchInvoiceById({ payrollInvoiceId }),
    [payrollInvoiceId]
  );
  const data = response || {};

  return (
    <>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar className={classes.toolBar}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item className={classes.navTitle}>
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.drawerTitle}
                >
                  Detalle factura <strong>folio {data.invoice?.folio}</strong>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={onClose}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      {!isLoading && data.invoice?.folio && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <span className={classes.subTitle}>Pagador</span>
            <h4 className={classes.companyName}>{data?.receiver?.name}</h4>
            <p className={classes.companyIdentifier}>
              {t(t13s.LABEL.BUSINESS_IDENTIFIER)}: {data?.receiver?.id}
            </p>
            <p>
              Emisor:
              <span className={classes.hasEllipsis} title={data.issuer?.name}>
                {data.issuer?.name}
              </span>
            </p>
            <p className={classes.within}>
              {t(t13s.LABEL.BUSINESS_IDENTIFIER)} Emisor:
              <span>{data.issuer?.id}</span>
            </p>
            <p>
              Plazo: <span>{getRangeOfDays(data.invoice?.term)}</span>
            </p>
            <p className={classes.within}>
              Fecha Vencimiento:{' '}
              <span>{dateToDDMMYYYY(data.invoice?.term)}</span>
            </p>
            <p>
              Días Emisión:
              <span>{getDaysOfIssueInDays(data.invoice?.issuedDate)}</span>
            </p>
            <p className={classes.within}>
              Fecha Emisión:{' '}
              <span>{dateToDDMMYYYY(data.invoice?.issuedDate)}</span>
            </p>
            <p>
              Tipo Operación:{' '}
              <span>
                {defaultValue(
                  OPERATION_TYPE[data.invoice?.operationType]?.label
                )}
              </span>
            </p>
            <p className={classes.within}>
              Score: <span>{data.invoice?.score}</span>
            </p>
            <p>
              Monto bruto:&nbsp;
              <span>
                <CountryFormatHelper
                  value={data.invoice?.amount}
                  countryId={data.invoice?.countryId}
                  variant="currency"
                />
              </span>
            </p>
            <p className={classes.within}>
              Nota de crédito:
              <span>
                <CountryFormatHelper
                  value={data.invoice?.totalAmountCreditNotes}
                  countryId={data.invoice?.countryId}
                  variant="currency"
                />
              </span>
            </p>
            <p className={classes.within}>
              Otros descuentos:
              <CountryFormatHelper
                value={data.invoice?.discounts}
                countryId={data.invoice?.countryId}
                variant="currency"
              />
            </p>
            <p>
              Sector:
              <span
                className={classes.hasEllipsis}
                title={data.invoice?.businessActivity}
              >
                {defaultValue(data.invoice?.businessActivity)}
              </span>
            </p>
            <p>
              Tasa Originación: <span>{data.invoice?.originationRate}</span>
            </p>
            <p>
              Order ID: <span>{data.invoice?.orderId}</span>
            </p>
            <p className={classes.within}>
              Estado BO:&nbsp;
              <span>
                <StatusChip
                  status={data.invoice?.status}
                  entityType="invoice"
                  size="small"
                />
              </span>
            </p>
            <p className={classes.within}>
              Acuse Recibo:
              <span>
                {data.invoice?.invoiceTaxProviders?.acknowledgementOfReceipt
                  ? 'Sí'
                  : 'No'}
              </span>
            </p>
            <p className={classes.within}>
              Estado verificación:
              <span>{defaultValue(data?.invoice?.verificationStatus)}</span>
            </p>
            <p className={classes.within}>
              Dicom Emisor:
              <span>
                <CountryFormatHelper
                  value={data?.issuer?.debt}
                  countryId={data.invoice?.countryId}
                  variant="currency"
                />
              </span>
            </p>
            <p className={classes.within}>
              Dicom Deudor:
              <span>
                <CountryFormatHelper
                  value={data?.receiver?.debt}
                  countryId={data.invoice?.countryId}
                  variant="currency"
                />
              </span>
            </p>
            <p className={classes.within}>
              Cesionario:
              <span
                className={classes.hasEllipsis}
                title={data?.invoiceTaxProviders?.assignee}
              >
                {data?.invoiceTaxProviders?.assignee}
              </span>
            </p>
            <p className={classes.within}>
              Factura Recompra:
              <span>{defaultValue(data.invoice?.repurchase)}</span>
            </p>
          </Grid>
        </Grid>
      )}
    </>
  );
};

InvoiceDetailComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  payrollInvoiceId: PropTypes.number.isRequired,
};

export const InvoiceDetail = memo(InvoiceDetailComponent);
