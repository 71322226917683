import { useMemo } from 'react';

import { useBoActions } from '../../../../adapters/store';
import { FEATURES } from '../../../../commons/constants/features';
import { VIEW_TYPES } from '../../../context/OpportunitiesContext/constants';
import { useFetchDraftDocuments } from '../../document/services';
import {
  useReceiverActions,
  useSelectedDraftReceiver,
  useSelectedReceiver,
} from '../../receiver/state';
import {
  useFetchCustomExpirationDate,
  useFetchRecommendedRate,
  useMakeSimulation,
} from '../services';
import {
  useDraftPayrollSelector,
  useGetSelectedFund,
  usePayrollActions,
  usePayrollSelector,
} from '../state';
import {
  addDocumentUseCase,
  addReceiverUseCase,
  getSelectedSummaryUseCase,
  deleteReceiversUseCase,
  resetPayrollSelectorUseCase,
  updateByCustomExpirationDateUseCase,
  updateByRecommendedRateUseCase,
  updateBySimulationUseCase,
} from '../useCases';
import { resetPayrollSelectorToInitialStateUseCase } from '../useCases/resetPayrollSelectorToInitialStateUseCase';
import { updateByBulkEditionUseCase } from '../useCases/updateByBulkEditionUseCase';
import { updateBySummaryRateUseCase } from '../useCases/updateBySummaryRateUseCase';
import { updateDocumentUseCase } from '../useCases/updateDocumentUseCase';
import { updateReceiverUseCase } from '../useCases/updateReceiverUseCase';

export const usePayrollSelectorController = () => {
  const {
    cleanPayrollSelector,
    cleanSimulation,
    pickReceiver,
    deleteReceivers: deleteReceiversAction,
    selectFund: selectFundAction,
    setSimulation,
    startSimulation,
    updatePayrollSelector,
  } = usePayrollActions();
  const { selectDraftReceiver } = useReceiverActions();
  const selectedDraftReceiver = useSelectedDraftReceiver();
  const { showSnackBar } = useBoActions();
  const selectedFund = useGetSelectedFund();
  const draftPayrollState = useDraftPayrollSelector();
  const payrollSelector = usePayrollSelector();
  const selectedReceiver = useSelectedReceiver();
  const { refetch: refetchDocuments } = useFetchDraftDocuments(
    { receiverIdentifier: selectedDraftReceiver?.receiverIdentifier },
    {
      enabled: false,
      cacheTime: 0,
    }
  );
  const { mutateAsync: fetchRecommendedRate } = useFetchRecommendedRate();
  const { mutateAsync: fetchCustomExpirationDate } =
    useFetchCustomExpirationDate();
  const { mutateAsync: makeSimulation } = useMakeSimulation();
  const currentSelectorState = useMemo(
    () => payrollSelector || {},
    [payrollSelector]
  );
  const summary = useMemo(
    () => getSelectedSummaryUseCase({ currentSelectorState }),
    [currentSelectorState]
  );

  const resetSimulation = () => {
    const newState = resetPayrollSelectorUseCase({ currentSelectorState });
    updatePayrollSelector(newState);
    cleanSimulation();
  };

  const resetSelectorToInitialState = () => {
    const newState = resetPayrollSelectorToInitialStateUseCase({
      currentSelectorState,
    });
    updatePayrollSelector(newState);
    cleanSimulation();
  };

  const onError = () => {
    resetSimulation();
    showSnackBar({
      msg: 'Ocurrió un error, vuelve a intentar',
      type: 'error',
    });
  };

  const onUpdatePayrollSelector = newState => {
    updatePayrollSelector(newState);
    if (selectedDraftReceiver) {
      selectDraftReceiver({
        ...newState[selectedDraftReceiver.receiverIdentifier],
        receiverIdentifier: selectedDraftReceiver.receiverIdentifier,
      });
    }
  };

  const addReceiver = (selectedRows, prevStateTable) => {
    const { newState } = addReceiverUseCase({
      currentSelectorState,
      prevStateTable,
      selectedRows,
    });
    pickReceiver(newState);
  };

  const addDocument = (selectedRows, prevStateTable) => {
    const { newState } = addDocumentUseCase({
      selectedRows,
      currentSelectorState,
      selectedReceiver,
      prevStateTable,
    });
    pickReceiver(newState);
  };

  const editSummaryRate = newRate => {
    const newState = updateBySummaryRateUseCase({
      currentSelectorState,
      newRate,
    });
    startSimulation();
    makeSimulation(
      {
        interestFormula: selectedFund.interestFormula,
        receivers: newState,
      },
      {
        onSuccess: res => {
          setSimulation(res.simulation);
          const stateSelector = updateBySimulationUseCase({
            currentSelectorState: newState,
            response: res.receivers,
          });
          onUpdatePayrollSelector(stateSelector);
        },
        onError,
      }
    );
  };

  const editReceiver = (newValues, receiverIdentifier) => {
    const newState = updateReceiverUseCase({
      currentSelectorState,
      newValues,
      selectedReceiverIdentifier: receiverIdentifier,
    });
    startSimulation();
    makeSimulation(
      {
        interestFormula: selectedFund.interestFormula,
        receivers: newState,
      },
      {
        onSuccess: res => {
          setSimulation(res.simulation);
          const stateSelector = updateBySimulationUseCase({
            currentSelectorState: newState,
            response: res.receivers,
          });
          onUpdatePayrollSelector(stateSelector);
        },
        onError,
      }
    );
  };

  const editDocument = (newValues, receiverIdentifier, selectedDocumentId) => {
    const newState = updateDocumentUseCase({
      currentSelectorState,
      newValues,
      selectedDocumentId,
      selectedReceiverIdentifier: receiverIdentifier,
    });
    if (!selectedFund) {
      onUpdatePayrollSelector(newState);

      return;
    }
    startSimulation();
    makeSimulation(
      {
        interestFormula: selectedFund.interestFormula,
        receivers: newState,
      },
      {
        onSuccess: res => {
          setSimulation(res.simulation);
          const stateSelector = updateBySimulationUseCase({
            currentSelectorState: newState,
            response: res.receivers,
          });
          onUpdatePayrollSelector(stateSelector);
        },
        onError,
      }
    );
  };

  const bulkEdit = newValues => {
    const newState = updateByBulkEditionUseCase({
      currentSelectorState,
      draftPayrollState,
      fundHasBeenSelected: !!selectedFund,
      newValues,
    });
    if (!selectedFund) {
      onUpdatePayrollSelector(newState);

      return;
    }
    startSimulation();
    makeSimulation(
      {
        interestFormula: selectedFund.interestFormula,
        receivers: newState,
      },
      {
        onSuccess: res => {
          setSimulation(res.simulation);
          const stateSelector = updateBySimulationUseCase({
            currentSelectorState: newState,
            response: res.receivers,
          });
          onUpdatePayrollSelector(stateSelector);
        },
        onError,
      }
    );
  };

  const deleteReceivers = receiversToRemove => {
    const newState = deleteReceiversUseCase({
      currentSelectorState,
      receiversToRemove,
    });
    deleteReceiversAction(newState);
  };

  const getIsSpecialFundTerm = fund => {
    return fund?.entityFeatures.find(e =>
      [FEATURES.ROUND_TERM_TO_FRIDAY, FEATURES.CUSTOM_LATAM_TERM].includes(
        e.name
      )
    );
  };

  const selectFund = (fund, viewType, updateStaticFieldsOfReceivers) => {
    if (!fund) {
      return;
    }

    if (selectedDraftReceiver && viewType === VIEW_TYPES.DOCUMENT) {
      refetchDocuments();
    }

    selectFundAction(fund);
    startSimulation();
    let stateSelectorUpdatedByFund = {};
    const isSpecialFund = getIsSpecialFundTerm(fund);
    fetchRecommendedRate(
      {
        fundId: fund.id,
        receiverIdentifiers: Object.keys(currentSelectorState),
      },
      {
        onSuccess: res => {
          stateSelectorUpdatedByFund = updateByRecommendedRateUseCase({
            currentSelectorState,
            response: res,
            updateStaticFieldsOfReceivers,
          });

          if (isSpecialFund) {
            fetchCustomExpirationDate(
              {
                fundId: fund.id,
                invoiceIds: Object.keys(currentSelectorState).flatMap(
                  receiverIdentifier =>
                    currentSelectorState[receiverIdentifier].value.map(
                      doc => doc.id
                    )
                ),
              },
              {
                onSuccess: res => {
                  stateSelectorUpdatedByFund =
                    updateByCustomExpirationDateUseCase({
                      currentSelectorState: stateSelectorUpdatedByFund,
                      response: res,
                      updateStaticFieldsOfReceivers,
                    });
                  makeSimulation(
                    {
                      interestFormula: fund.interestFormula,
                      receivers: stateSelectorUpdatedByFund,
                      isSpecialFund,
                    },
                    {
                      onSuccess: res => {
                        setSimulation(res.simulation);
                        stateSelectorUpdatedByFund = updateBySimulationUseCase({
                          currentSelectorState: stateSelectorUpdatedByFund,
                          response: res.receivers,
                        });
                        onUpdatePayrollSelector(stateSelectorUpdatedByFund);
                      },
                      onError: () => {
                        cleanSimulation();
                        showSnackBar({
                          msg: 'Ocurrió un error, vuelve a intentar',
                          type: 'error',
                        });
                      },
                    }
                  );
                },
                onError,
              }
            );
          } else {
            makeSimulation(
              {
                interestFormula: fund.interestFormula,
                receivers: stateSelectorUpdatedByFund,
              },
              {
                onSuccess: res => {
                  setSimulation(res.simulation);
                  stateSelectorUpdatedByFund = updateBySimulationUseCase({
                    currentSelectorState: stateSelectorUpdatedByFund,
                    response: res.receivers,
                  });
                  onUpdatePayrollSelector(stateSelectorUpdatedByFund);
                },
                onError,
              }
            );
          }
        },
        onError,
      }
    );
  };

  return {
    addDocument,
    addReceiver,
    bulkEdit,
    cleanPayrollSelector,
    deleteReceivers,
    editDocument,
    editReceiver,
    editSummaryRate,
    resetSelectorToInitialState,
    resetSimulation,
    selectFund,
    summary,
  };
};
