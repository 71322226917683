import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const manageInvoicesService = async ({
  orderId,
  params: {
    action,
    orderInvoiceIds = [],
    newOrderId = null,
    invoices = [],
    deleteInvoiceReason = null,
  },
}) => {
  try {
    return await axios.post(`/api/orders/${orderId}/manageOrderInvoices`, {
      action,
      orderInvoiceIds,
      newOrderId,
      invoices,
      reason: deleteInvoiceReason,
    });
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { manageInvoicesService };
