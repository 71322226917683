import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchOrderInvoicesFundsService = async orderId => {
  try {
    return await axios.get(`/api/orderinvoicefund`, {
      params: {
        orderId,
      },
    });
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrderInvoicesFundsService };
