import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Filter } from '../../../../components/icons';
import { useStyles } from './styles';

const BtnFilterComponent = ({ appliedFilters, setShowFilter }) => {
  const css = useStyles();

  return (
    <Button
      variant="outlined"
      className={appliedFilters ? css.btnApplied : css.filterButton}
      startIcon={<Filter />}
      onClick={() => setShowFilter(true)}
    >
      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid item className={css.icon}>
          Filtros
        </Grid>
        {appliedFilters > 0 && (
          <Grid item>
            <div className={css.filterBadge}>{appliedFilters}</div>
          </Grid>
        )}
      </Grid>
    </Button>
  );
};

BtnFilterComponent.propTypes = {
  appliedFilters: PropTypes.number.isRequired,
  setShowFilter: PropTypes.func.isRequired,
};

export const BtnFilter = memo(BtnFilterComponent);
