import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const assignBankAccountToInvoiceService = async ({
  businessId,
  invoiceId,
  bankAccountId,
}) => {
  try {
    const { data } = await axios.put(
      `/api/payment/business/${businessId}/invoice/${invoiceId}/bankaccount`,
      {
        bankAccountId,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { assignBankAccountToInvoiceService };
