import React, { ChangeEvent, FC } from 'react';
import { Box, IconButton, TextField } from '@material-ui/core';
import { CommentInput, CommentInputActions, colors } from '../styles';
import LoadingButton from '../../LoadingButton';
import BackIcon from '../../../icons/BackIcon';
import UploadIcon from '../../../icons/UploadIcon';
import TagIcon from '../../../icons/TagIcon';

interface Props {
  commentInformation: any;
  showDropZoneInput: boolean;
  showTagsInput: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  handleChangeCommentData: (data: string) => void;
  handleCloseCommentInput: () => void;
  handleToggleDropZoneInput: () => void;
  handleToggleTagsInput: () => void;
  handleSubmit: () => void;
}

const OrderCommentsInput: FC<Props> = ({
  commentInformation,
  showDropZoneInput,
  showTagsInput,
  isLoading,
  isDisabled,
  handleChangeCommentData,
  handleCloseCommentInput,
  handleToggleDropZoneInput,
  handleToggleTagsInput,
  handleSubmit,
}) => {
  return (
    <CommentInput>
      <TextField
        
        fullWidth
        multiline
        disabled={isDisabled}
        rows={4}
        maxRows={8}
        type="string"
        name="comment"
        placeholder="Agrega un comentario"
        value={commentInformation.commentData}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChangeCommentData(e.target.value)
        }
        data-cy="orderCommentsInput"
        inputProps={{ maxLength: 600 }}
      />
      <CommentInputActions>
        <IconButton
          onClick={handleCloseCommentInput}
          data-cy="orderCommentsInputBackButton"
        >
          <BackIcon color="#000000" />
        </IconButton>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <IconButton
              onClick={handleToggleDropZoneInput}
              data-cy="orderCommentsInputUploadDocumentButton"
            >
              <UploadIcon
                color={
                  showDropZoneInput ? colors.pressedIcon : colors.standardIcon
                }
              />
            </IconButton>
          </Box>
          <Box mr={2}>
            <IconButton
              onClick={handleToggleTagsInput}
              data-cy="orderCommentsInputAddTagButton"
            >
              <TagIcon
                color={showTagsInput ? colors.pressedIcon : colors.standardIcon}
              />
            </IconButton>
          </Box>
          <LoadingButton
            isLoading={isLoading}
            disabled={
              isDisabled || commentInformation?.commentData?.trim().length === 0
            }
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            data-cy="orderCommentsInputAddCommentButton"
          >
            Agregar
          </LoadingButton>
        </Box>
      </CommentInputActions>
    </CommentInput>
  );
};

export default OrderCommentsInput;
