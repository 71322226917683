import { useEffect, useState } from 'react';

const usePagination = (callback, initialPageValue, initialLimitValue) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    callback(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  useEffect(() => {
    if (initialPageValue && page !== initialPageValue) {
      setPage(initialPageValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPageValue]);

  useEffect(() => {
    if (initialLimitValue && limit !== initialLimitValue) {
      setLimit(initialLimitValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLimitValue]);

  const updatePageAndLimit = (updatedPage, updatedLimit) => {
    setPage(updatedPage);
    setLimit(updatedLimit);
  };

  return { page, limit, updatePageAndLimit };
};

export default usePagination;
