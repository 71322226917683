import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const addOrderCommentService = async (operationId, commentDataToCreate) => {
  try {
    const { data } = await axios.post(`/api/order/${operationId}/comment`, {
      ...commentDataToCreate,
      category: 'XEPELIN',
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { addOrderCommentService };
