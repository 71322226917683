import axios from 'axios';

const fetchInvoices = async (orderId, params = {}) => {
  try {
    const { data } = await axios.get(`api/payment/order/${orderId}/invoices`, {
      params: Object.keys(params).length ? params : {},
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchInvoices };
