import React from 'react';
import {
  Select as MaterialSelect,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Select = ({
  options,
  name,
  label,
  value: selected,
  onChange,
  customWidth,
}) => {
  const handleSelect = event => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <FormControl variant="outlined" style={{ width: customWidth }}>
      <InputLabel id="select-component">{label}</InputLabel>
      <MaterialSelect
        labelId="select-component"
        value={selected}
        name={name}
        label={label}
        onChange={handleSelect}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

Select.defaultProps = {
  value: null,
  customWidth: '100%',
};

Select.propTypes = {
  options: PropTypes.arrayOf(String).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  customWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
