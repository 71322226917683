import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import { Button, Grid, Menu } from '@material-ui/core';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import MenuItem from './elements/MenuItem';
import FileUploadDialog from './dialogs/FileUploadDialog';
import { csvErrorsReportBuilder } from '../helpers/CsvErrorsReportBuilder';
import useBankMovements from './useBankMovements';

const useStyles = makeStyles({
  root: {
    padding: convertSpacingToCss('zero'),
  },
  container: {
    padding: convertSpacingToCss('lg'),
  },
});

const BankMovements = () => {
  const classes = useStyles();
  const [anchorUploadFiles, setAnchorUploadFiles] = useState(null);
  const [showSameBankComplementaryInfo, setShowSameBankComplementaryInfo] =
    useState(false);
  const [showInterbankInquiry, setShowInterbankInquiry] = useState(false);
  const [showBankMovementsModal, setShowBankMovementsModal] = useState(false);
  const {
    areBankMovementsLoading,
    bankMovementsErrorLines,
    bankMovementsServerError,
    bankMovementsUploadFile,
    bankMovementsResetDataAndErrors,

    isSameBankComplementaryInfoLoading,
    sameBankComplementaryInfoErrorLines,
    sameBankComplementaryInfoServerError,
    sameBankComplementaryInfoUploadFile,
    sameBankComplementaryInfoResetDataAndErrors,

    isInterbankInquiryLoading,
    interbankInquiryErrorLines,
    interbankInquiryServerError,
    interbankInquiryUploadFile,
    interbankInquiryResetDataAndErrors,
  } = useBankMovements();

  const locales = {
    SAME_BANK_COMPLEMENTARY_INFO_LOAD:
      'Carga masiva de movimientos mismo banco',
    INTERBANK_INQUIRY_LOAD: 'Carga masiva de movimientos interbancarios',
    BANK_MOVEMENTS_LOAD: 'Carga masiva de cartola bancaria',
  };

  const uploadBankMovementsOptionsData = [
    {
      label: locales.SAME_BANK_COMPLEMENTARY_INFO_LOAD,
      action: () => {
        setAnchorUploadFiles(null);
        setShowSameBankComplementaryInfo(true);
      },
    },
    {
      label: locales.INTERBANK_INQUIRY_LOAD,
      action: () => {
        setAnchorUploadFiles(null);
        setShowInterbankInquiry(true);
      },
    },
    {
      label: locales.BANK_MOVEMENTS_LOAD,
      action: () => {
        setAnchorUploadFiles(null);
        setShowBankMovementsModal(true);
      },
    },
  ];
  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <Grid item xs={12} className={classes.container}>
          <Grid item container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <Button
                variant="text"
                color="primary"
                startIcon={<CloudUpload />}
                onClick={e => setAnchorUploadFiles(e.currentTarget)}
                data-qa="bulk-invoice-update-action"
              >
                Carga de movimientos bancarios
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        id="uploadFiles"
        anchorEl={anchorUploadFiles}
        keepMounted
        open={Boolean(anchorUploadFiles)}
        onClose={() => setAnchorUploadFiles(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {uploadBankMovementsOptionsData.map(option => (
          <MenuItem onClick={option.action}>{option.label}</MenuItem>
        ))}
      </Menu>

      {showSameBankComplementaryInfo && (
        <FileUploadDialog
          open={showSameBankComplementaryInfo}
          handleCloseDialog={() => {
            setShowSameBankComplementaryInfo(false);
          }}
          handleLoad={sameBankComplementaryInfoUploadFile}
          saving={isSameBankComplementaryInfoLoading}
          errorsCsv={csvErrorsReportBuilder(
            sameBankComplementaryInfoErrorLines
          )}
          serverError={sameBankComplementaryInfoServerError}
          title={locales.SAME_BANK_COMPLEMENTARY_INFO_LOAD}
          actionText="Subir archivo"
          resetDataAndErrors={sameBankComplementaryInfoResetDataAndErrors}
          source="sameBankComplementaryInfo"
          fileExtension=".xlsx,.xls"
        />
      )}
      {showInterbankInquiry && (
        <FileUploadDialog
          open={showInterbankInquiry}
          handleCloseDialog={() => {
            setShowInterbankInquiry(false);
          }}
          handleLoad={interbankInquiryUploadFile}
          saving={isInterbankInquiryLoading}
          errorsCsv={csvErrorsReportBuilder(interbankInquiryErrorLines)}
          serverError={interbankInquiryServerError}
          title={locales.INTERBANK_INQUIRY_LOAD}
          actionText="Subir archivo"
          resetDataAndErrors={interbankInquiryResetDataAndErrors}
          source="interbankInquiry"
          fileExtension=".xlsx,.xls"
        />
      )}
      {showBankMovementsModal && (
        <FileUploadDialog
          open={showBankMovementsModal}
          handleCloseDialog={() => {
            setShowBankMovementsModal(false);
          }}
          handleLoad={bankMovementsUploadFile}
          saving={areBankMovementsLoading}
          errorsCsv={csvErrorsReportBuilder(bankMovementsErrorLines)}
          serverError={bankMovementsServerError}
          title={locales.BANK_MOVEMENTS_LOAD}
          actionText="Subir archivo"
          resetDataAndErrors={bankMovementsResetDataAndErrors}
          source="movements"
          fileExtension=".xlsx,.xls"
        />
      )}
    </>
  );
};

export default BankMovements;
