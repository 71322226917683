import React from 'react';
import { Box, Link, Tooltip, Typography } from '@material-ui/core';

import { StatusChip } from '../../../../../../components/elements/chips';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import { OPERATION_TYPE } from '../../../../../commons/constants/operations';
import {
  defaultValue,
  getDaysOfIssueInDays,
  percentageFormat,
} from '../../../../../commons/utils';
import { t13s } from '../../../../../../translationKeys';
import { Ticket, Warning } from '../../../../../commons/icons';
import { DIALOG_TYPES } from '../constants';
import { SORT_NULL_LAST } from '../../../../../commons/constants/ui';

export const GET_COLUMNS = ({
  country,
  openDocumentDrawer,
  selectedFund,
  setOpenEditDialog,
  setSelectedDocumentToEdit,
  showHasPdf,
  t,
}) => [
  {
    title: 'Folio',
    align: 'left',
    field: 'folio',
    type: 'numeric',
    width: '80px',
    render: row => (
      <Link
        component="button"
        variant="body1"
        align="left"
        onClick={() => {
          openDocumentDrawer(row.id);
        }}
      >
        <Box fontWeight="fontWeightBold">{row?.folio}</Box>
      </Link>
    ),
  },
  ...(showHasPdf
    ? [
        {
          title: 'PDF',
          align: 'left',
          field: 'invoiceAttribute.hasPdf',
          type: 'boolean',
          render: row => {
            if (row?.invoiceAttribute?.hasPdf || row?.issuer?.hasPfx) {
              return <Ticket />;
            }

            return (
              <Tooltip title="Factura sin PDF">
                <Box display="flex" alignContent="center">
                  <Warning />
                </Box>
              </Tooltip>
            );
          },
        },
      ]
    : []),
  {
    title: 'Order ID',
    align: 'left',
    field: 'orderId',
    type: 'numeric',
    width: '120px',
    render: row => (
      <Link
        target="_blank"
        href={`/${country?.toLowerCase()}/orders/${row?.orderId}`}
        variant="body1"
        align="left"
      >
        <Box>{defaultValue(row?.orderId)}</Box>
      </Link>
    ),
  },
  {
    title: 'Emisor',
    align: 'left',
    field: 'issuer.name',
    type: 'string',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.issuer.name)}
      </Typography>
    ),
  },
  {
    title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Emisor`,
    field: 'issuer.identifier',
    type: 'string',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.issuer.identifier)}
      </Typography>
    ),
  },
  {
    title: 'Plazo',
    align: 'left',
    field: 'expirationDate',
    type: 'date',
    width: '100px',
    render: row =>
      selectedFund ? (
        <Link
          component="button"
          variant="body1"
          align="left"
          onClick={() => {
            setOpenEditDialog(DIALOG_TYPES.TERM);
            setSelectedDocumentToEdit(row);
          }}
        >
          {defaultValue(row?.expirationDate)}
        </Link>
      ) : (
        <Typography variant="body1" color="textPrimary">
          {defaultValue(row?.expirationDate)}
        </Typography>
      ),
  },
  {
    title: 'Días de Emisión',
    align: 'left',
    field: 'issuedDate',
    type: 'number',
    width: '100px',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {getDaysOfIssueInDays(row.issuedDate)}
      </Typography>
    ),
  },
  {
    title: 'Tipo Operación',
    align: 'left',
    field: 'operationType',
    type: 'string',
    width: '100px',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {OPERATION_TYPE[row?.operationType]?.label}
      </Typography>
    ),
  },
  {
    title: 'Monto bruto',
    align: 'left',
    field: 'amount',
    type: 'numeric',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        <CountryFormatHelper
          value={row?.amount}
          countryId={country}
          variant="currency"
        />
      </Typography>
    ),
  },
  {
    title: 'Cesionario',
    align: 'left',
    field: 'invoiceTaxProviders.assignee',
    type: 'string',
    width: '200px',
    customSort: SORT_NULL_LAST('invoiceTaxProviders.assignee'),
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row.invoiceTaxProviders?.assignee)}
      </Typography>
    ),
  },
  {
    title: 'Nombre cesionario',
    align: 'left',
    field: 'invoiceTaxProviders.assigneeName',
    type: 'string',
    width: '200px',
    customSort: SORT_NULL_LAST('invoiceTaxProviders.assigneeName'),
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row.invoiceTaxProviders?.assigneeName)}
      </Typography>
    ),
  },
  {
    title: 'Tasa Originación',
    align: 'left',
    field: 'baseRate',
    type: 'numeric',
    width: '100px',
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {percentageFormat(row?.baseRate)}
      </Typography>
    ),
  },
  {
    title: 'Tasa Fondo',
    align: 'left',
    field: 'rate',
    type: 'numeric',
    width: '100px',
    render: row =>
      !selectedFund ? (
        '-'
      ) : (
        <Typography variant="body1" color="textPrimary">
          <Link
            component="button"
            variant="body1"
            align="left"
            onClick={() => {
              setOpenEditDialog(DIALOG_TYPES.RATE);
              setSelectedDocumentToEdit(row);
            }}
          >
            {percentageFormat(row?.rate)}
          </Link>
        </Typography>
      ),
  },
  {
    title: 'Tasa Costo Fondo Efectiva',
    align: 'left',
    field: 'effectiveFundCostRate',
    type: 'numeric',
    width: '100px',
    render: row =>
      !selectedFund ? (
        '-'
      ) : (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(row?.effectiveFundCostRate)}
        </Typography>
      ),
  },
  {
    title: 'Dicom Emisor',
    align: 'left',
    field: 'issuer.debtServiceAmount',
    type: 'numeric',
    width: '100px',
    customSort: SORT_NULL_LAST('issuer.debtServiceAmount'),
    render: row =>
      selectedFund ? (
        <Link
          component="button"
          variant="body1"
          align="left"
          onClick={() => {
            setOpenEditDialog(DIALOG_TYPES.DEBT_SERVICE_AMOUNT);
            setSelectedDocumentToEdit(row);
          }}
        >
          <CountryFormatHelper
            value={row?.issuer?.debtServiceAmount}
            countryId={country}
            variant="currency"
          />
        </Link>
      ) : (
        <Typography variant="body1" color="textPrimary">
          <CountryFormatHelper
            value={row?.issuer?.debtServiceAmount}
            countryId={country}
            variant="currency"
          />
        </Typography>
      ),
  },
  {
    title: '% Anticipo',
    align: 'left',
    field: 'advancePercentage',
    type: 'numeric',
    width: '100px',
    render: row =>
      selectedFund ? (
        <Link
          component="button"
          variant="body1"
          align="left"
          onClick={() => {
            setOpenEditDialog(DIALOG_TYPES.ADVANCE);
            setSelectedDocumentToEdit(row);
          }}
        >
          {percentageFormat(row?.advancePercentage)}
        </Link>
      ) : (
        <Typography variant="body1" color="textPrimary">
          {percentageFormat(row?.advancePercentage)}
        </Typography>
      ),
  },
  {
    title: 'Estado BO',
    align: 'left',
    field: 'status',
    type: 'string',
    render: row => (
      <StatusChip status={row?.status} entityType="invoice" size="small" />
    ),
  },
];
