import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selectionActionBarIcon: {
    marginRight: '15px',
    '& path': {
      fill: 'white',
    },
  },
});
