import {
  Box,
  Drawer as MuiDrawer,
  Divider as DividerMui,
  styled,
  Typography,
} from '@material-ui/core';

const Drawer = styled(MuiDrawer)({
  '& .MuiDrawer-paper': {
    background: '#fff',
  },
});

const TitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 14,
});
const Title = styled(Typography)({
  fontSize: '19.5px',
  fontWeight: 'bold',
});

const Divider = styled(DividerMui)({
  marginBottom: 24,
});

const BodyContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 156px)',
});

const TitleIconContainer = styled(Box)({
  display: 'flex',
  marginRight: 7,
  alignItems: 'center',
});

export {
  Drawer,
  TitleContainer,
  Title,
  Divider,
  BodyContainer,
  TitleIconContainer,
};
