import React from 'react';
import { Link, Typography } from '@material-ui/core';

import { t13s } from '../../../../../../translationKeys';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import {
  fixDecimals,
  roundTerms,
  defaultValue,
  percentageFormat,
} from '../../../../../commons/utils';

export const GET_COLUMNS = ({
  changeToDocumentView,
  country,
  css,
  receiverForm,
  t,
}) => [
  {
    align: 'left',
    field: 'name',
    title: 'Pagador',
    type: 'string',
    width: '320px',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'name'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Link
        component="button"
        variant="body1"
        align="left"
        title={row?.name}
        className={`${css.payerLink} ${css.hasEllipsis}`}
        onClick={() => {
          changeToDocumentView(row);
        }}
      >
        {defaultValue(row?.name)}
      </Link>
    ),
  },
  {
    title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
    align: 'left',
    field: 'receiverIdentifier',
    type: 'string',
    width: '140px',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'receiverIdentifier'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.receiverIdentifier)}
      </Typography>
    ),
  },
  {
    align: 'left',
    field: 'documentsNumber',
    title: 'Nº Facturas',
    type: 'numeric',
    width: '80px',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'documentsNumber'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.documentsNumber)}
      </Typography>
    ),
  },
  {
    align: 'left',
    field: 'amount',
    title: 'Monto bruto',
    type: 'numeric',
    width: '200px',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'amount'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        <CountryFormatHelper
          value={row?.amount}
          countryId={country}
          variant="currency"
        />
      </Typography>
    ),
  },
  {
    align: 'left',
    field: 'issuersNumber',
    title: 'Nº Emisores',
    type: 'numeric',
    width: '80px',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'issuersNumber'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(row?.issuersNumber)}
      </Typography>
    ),
  },
  {
    align: 'left',
    field: 'term',
    title: 'Plazo ponderado',
    type: 'numeric',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'term'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(roundTerms(row?.term))}
      </Typography>
    ),
  },
  {
    align: 'left',
    field: 'score',
    title: 'Score ponderado',
    type: 'numeric',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'score'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {defaultValue(fixDecimals(row?.score))}
      </Typography>
    ),
  },
  {
    align: 'left',
    field: 'acknowledgementPercentage',
    title: '% AR',
    type: 'numeric',
    customSort: () => 0,
    defaultSort:
      receiverForm?.orderBy?.field === 'acknowledgementPercentage'
        ? receiverForm?.orderBy?.direction
        : undefined,
    render: row => (
      <Typography variant="body1" color="textPrimary">
        {percentageFormat(row?.acknowledgementPercentage)}
      </Typography>
    ),
  },
];
