import { restFunds } from '../../../../infra/http';
import { purgeNestedObj } from '../../../../commons/utils';
import * as PayrollMap from './payroll.map';

export const fetchPayrolls = filter => {
  const fields = PayrollMap.payrollFilterMap.toPersistence(filter);

  return restFunds()
    .get('/payroll/summary', { params: purgeNestedObj(fields) })
    .then(r => r.data)
    .then(r => r?.map(PayrollMap.payrollMap.toDomain));
};

export const fetchPayrollById = payrollId => {
  return restFunds()
    .get('/payroll/summary', { params: { payrollId } })
    .then(r => r.data)
    .then(r => r?.map(PayrollMap.payrollMap.toDomain)[0]);
};

export const fetchCessionSummary = payrollId => {
  return restFunds()
    .get('/payroll/cession-summary', { params: { payrollId } })
    .then(r => r.data)
    .then(PayrollMap.cessionSummaryMap.toDomain);
};

export const notifyCessions = payrollId => {
  return restFunds()
    .post(`/payroll/${payrollId}/notify-cessions`)
    .then(res => res.data);
};

export const closePayroll = payrollId => {
  return restFunds()
    .post(`/payroll/${payrollId}/assign`)
    .then(() => {
      return restFunds()
        .post(`/payroll/${payrollId}/close`)
        .then(res => res.data);
    });
};
