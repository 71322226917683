import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateSignedDocumentService } from '../services';

export const useUpdateSignedDocument = () => {
  const dispatch = useDispatch();

  const {
    isLoading: updateSignedDocumentIsLoading,
    isSuccess: updateSignedDocumentIsSuccess,
    error: updateSignedDocumentError,
    mutate: updateSignedDocument,
    reset: resetDocumentData,
  } = useMutation(
    ({ documentableType, documentableId, documentId, file }) => {
      return updateSignedDocumentService({
        documentableType,
        documentableId,
        documentId,
        file,
      });
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_SIGNED_DOCUMENT_SUCCESS, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_DOCUMENT_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    updateSignedDocumentIsLoading,
    updateSignedDocumentIsSuccess,
    updateSignedDocumentError,
    updateSignedDocument,
    resetDocumentData,
  };
};

export default useUpdateSignedDocument;
