import { useMemo } from 'react';

import { useBoActions } from '../../../../adapters/store';
import { isEmptyObj, pluralize } from '../../../../commons/utils';
import {
  useReceiverActions,
  useSelectedDraftReceiver,
} from '../../receiver/state';
import { getDraftReceiversUseCase } from '../../receiver/useCases/getDraftReceiversUseCase';
import { useMakeSimulation } from '../services';
import {
  usePayrollActions,
  useDraftPayrollSelector,
  useGetDraftReceivers,
  useGetSelectedFund,
  usePayrollSelector,
} from '../state';
import {
  resetPayrollSelectorUseCase,
  updateBySimulationUseCase,
} from '../useCases';
import { addDraftDocumentUseCase } from '../useCases/draft/addDraftDocumentUseCase';
import { addDraftReceiverUseCase } from '../useCases/draft/addDraftReceiverUseCase';
import { deleteDraftDocumentsUseCase } from '../useCases/draft/deleteDraftDocumentsUseCase';
import { getDraftSelectedSummaryUseCase } from '../useCases/draft/getDraftSelectedSummaryUseCase';
import { resetDraftReceiverUseCase } from '../useCases/draft/resetDraftReceiverUseCase';
import { updatePayrollByAssignedInvoicesUseCase } from '../useCases/updatePayrollByAssignedInvoicesUseCase';

export const useDraftPayrollSelectorController = () => {
  const {
    cleanSimulation,
    pickDraftReceiver,
    deleteDraftReceivers,
    startSimulation,
    setSimulation,
    deleteReceivers,
    updatePayrollSelector,
  } = usePayrollActions();
  const selectedDraftReceiver = useSelectedDraftReceiver();
  const { selectDraftReceiver, updateDraftReceiver } = useReceiverActions();
  const draftPayrollSelectorState = useDraftPayrollSelector();
  const payrollSelectorState = usePayrollSelector();
  const selectedFund = useGetSelectedFund();
  const { showSnackBar } = useBoActions();
  const receivers = useGetDraftReceivers();
  const { mutateAsync: makeSimulation } = useMakeSimulation();
  const summary = useMemo(() => {
    return getDraftSelectedSummaryUseCase({
      currentSelectorState: draftPayrollSelectorState,
    });
  }, [draftPayrollSelectorState]);

  const resetSimulation = () => {
    const newState = resetPayrollSelectorUseCase({
      currentSelectorState: payrollSelectorState,
    });
    updatePayrollSelector(newState);
    cleanSimulation();
  };

  const resetDraftReceiver = () => {
    if (!selectedDraftReceiver) {
      return;
    }
    updateDraftReceiver(resetDraftReceiverUseCase(selectedDraftReceiver));
  };

  const onError = () => {
    resetSimulation();
    showSnackBar({
      msg: 'Ocurrió un error, vuelve a intentar',
      type: 'error',
    });
  };

  const deleteDraftDocuments = documentsToRemove => {
    const stateToRemove =
      documentsToRemove !== undefined
        ? Object.keys(documentsToRemove).reduce(
            (acc, identifier) => ({
              ...acc,
              [identifier]: {
                ...payrollSelectorState[identifier],
                value: documentsToRemove[identifier].map(invoice => ({
                  id: invoice.id,
                })),
              },
            }),
            {}
          )
        : draftPayrollSelectorState;

    if (isEmptyObj(stateToRemove)) {
      return;
    }

    const newPayrollSelectorState = deleteDraftDocumentsUseCase({
      payrollSelectorState,
      draftPayrollSelectorState: stateToRemove,
    });
    const alreadyAssignedDocumentsCount = Object.values(stateToRemove).reduce(
      (acc, receiver) => {
        return acc + receiver.value.length;
      },
      0
    );
    const toBeDeletedCount = documentsToRemove
      ? alreadyAssignedDocumentsCount
      : summary.totalDocuments;

    deleteReceivers(newPayrollSelectorState);
    deleteDraftReceivers();
    showSnackBar({
      msg: `Se ${pluralize(
        toBeDeletedCount,
        'ha',
        'han',
        true
      )} eliminado ${pluralize(toBeDeletedCount, 'factura', 'facturas')}`,
      type: 'success',
    });

    if (selectedFund && !isEmptyObj(newPayrollSelectorState)) {
      startSimulation();
      makeSimulation(
        {
          interestFormula: selectedFund.interestFormula,
          receivers: newPayrollSelectorState,
        },
        {
          onSuccess: res => {
            setSimulation(res.simulation);
            const stateSelector = updateBySimulationUseCase({
              currentSelectorState: newPayrollSelectorState,
              response: res.receivers,
            });
            updatePayrollSelector(stateSelector);
            if (selectedDraftReceiver) {
              selectDraftReceiver({
                ...stateSelector[selectedDraftReceiver.receiverIdentifier],
                receiverIdentifier: selectedDraftReceiver.receiverIdentifier,
              });
            }
          },
          onError,
        }
      );
    }
  };

  const addReceiver = (selectedRows, prevStateTable) => {
    const newState = addDraftReceiverUseCase({
      draftPayrollSelectorState,
      prevStateTable,
      selectedRows,
    });

    pickDraftReceiver(newState);
  };

  const addDocument = (selectedRows, prevStateTable) => {
    const { newState } = addDraftDocumentUseCase({
      selectedRows,
      draftPayrollSelectorState,
      selectedReceiver: selectedDraftReceiver,
      prevStateTable,
    });

    pickDraftReceiver(newState);
  };

  const getReceivers = () => {
    return getDraftReceiversUseCase({
      receivers,
      draftPayrollSelectorState,
    });
  };

  const updatePayrollByAssignedInvoices = response => {
    const newState = updatePayrollByAssignedInvoicesUseCase({
      currentSelectorState: payrollSelectorState,
      response,
    });
    updatePayrollSelector(newState);
  };

  return {
    addDocument,
    addReceiver,
    deleteDraftDocuments,
    getReceivers,
    resetDraftReceiver,
    updatePayrollByAssignedInvoices,
    summary,
  };
};
