import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { useSelector } from 'react-redux';

import styles from '../styles';
import { features } from '../../../../../../config/features';

import {
  useSetIssuerBusinessId,
  useSetSelectedInvoice,
  useInvoiceTableReloadRef,
} from '../store';
import { INVOICES_IS_HOLD_UPDATE_PERFORM } from '../../../../../../helpers/performsType';
import Can from '../../../../../../components/Can';
import { ChangeIsHoldDialog } from '../../../../../commons/components';
import { useUpdateOrderInvoiceIsHold } from '../../../../infrastructure/store';
import { XCAPITAL_CESSIONARY } from '../../../../../../helpers/Constants';
import { useShowBankAccountSelectorDrawer } from '../../../drawers/BankAccountSelectorDrawer/store';

const Attributes = ({ rowData }) => {
  const classes = styles();
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];

  const setIssuerBusinessId = useSetIssuerBusinessId();

  const showBankAccountSelector = useShowBankAccountSelectorDrawer();
  const setSelectedInvoice = useSetSelectedInvoice();

  const [invoiceIdToUpdateIsHold, setInvoiceIdToUpdateIsHold] = useState(null);
  const [showIsHoldDialog, setShowIsHoldDialog] = useState(false);
  const invoiceTableReloadRef = useInvoiceTableReloadRef();

  const {
    isErrorUpdateOrderInvoiceIsHold,
    isLoadingUpdateOrderInvoiceIsHold,
    updateOrderInvoiceIsHold,
  } = useUpdateOrderInvoiceIsHold();

  const handleBankAccountDialog = invoice => {
    setSelectedInvoice({
      ...invoice,
      tableData: {
        ...invoice.tableData,
      },
    });
    setIssuerBusinessId(invoice.issuerBusinessId);

    showBankAccountSelector();
  };

  const getConfirmingAttributes = invoice => {
    const colorBankAccount = invoice.BankAccount
      ? classes.iconGreen
      : classes.iconGray;

    const bankAccountAttribute = (
      <Tooltip title="Cuenta Bancaria">
        <IconButton
          size="small"
          className={[colorBankAccount, classes.verifiedMargin]}
          onClick={() => handleBankAccountDialog(invoice)}
        >
          B
        </IconButton>
      </Tooltip>
    );

    return { bankAccountAttribute };
  };

  const handleShowIsHoldDialog = orderInvoiceId => {
    setInvoiceIdToUpdateIsHold(orderInvoiceId);
    setShowIsHoldDialog(true);
  };

  const handleCloseIsHoldDialog = () => {
    setInvoiceIdToUpdateIsHold(null);
    setShowIsHoldDialog(false);
    invoiceTableReloadRef();
  };

  const handleSubmitInvoiceIsHold = () => {
    updateOrderInvoiceIsHold({
      orderInvoiceId: invoiceIdToUpdateIsHold,
      onSuccessCallback: handleCloseIsHoldDialog,
    });
    setShowIsHoldDialog(false);
  };

  const getIsHoldButton = orderInvoiceId => (
    <Can
      perform={INVOICES_IS_HOLD_UPDATE_PERFORM}
      yes={() => (
        <Tooltip title="Factura temporal">
          <IconButton
            size="small"
            className={classes.iconRed}
            onClick={() => handleShowIsHoldDialog(orderInvoiceId)}
          >
            <ErrorOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      no={() => (
        <Tooltip title="Factura temporal">
          <IconButton size="small" className={classes.iconRed}>
            <ErrorOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    />
  );

  const getCessionAttribute = (isHold, ceded, invoiceId) => {
    if (isHold && !ceded) {
      const isHoldButton = getIsHoldButton(invoiceId);
      return isHoldButton;
    }

    const cededLabel = ceded ? 'Factura cedida' : 'Factura sin ceder';
    const cededColor = ceded ? classes.iconGreen : classes.iconGray;
    return (
      <Tooltip title={cededLabel}>
        <IconButton
          size="small"
          className={[cededColor, classes.verifiedMargin]}
        >
          C
        </IconButton>
      </Tooltip>
    );
  };

  const getAttributes = invoice => {
    const ceded =
      !invoice.ceded &&
      invoice.cessionary === XCAPITAL_CESSIONARY &&
      invoice.cessionStatus === 'SUCCESS';

    const attributes = [];
    if (countryFeatures.orderActions.showCessionAttribute) {
      const cessionAttribute = getCessionAttribute(
        invoice.isHold,
        ceded,
        invoice.id
      );
      attributes.push(cessionAttribute);
    }

    // TODO: check if this  duplicates the field for CL
    if (
      countryFeatures.orderActions.showIsHoldInvoiceAttribute &&
      invoice.isHold
    ) {
      const isHoldButton = getIsHoldButton(invoice.id);
      attributes.push(isHoldButton);
    }

    if (countryFeatures.orderActions.showBankAccountButton) {
      const { bankAccountAttribute, registerStatusAttribute } =
        getConfirmingAttributes(invoice);
      attributes.push(bankAccountAttribute);
      attributes.push(registerStatusAttribute);
    }
    return attributes;
  };

  return (
    <>
      {showIsHoldDialog && (
        <ChangeIsHoldDialog
          open={showIsHoldDialog}
          handleClose={handleCloseIsHoldDialog}
          handleSubmit={handleSubmitInvoiceIsHold}
          handleResetError={() => undefined}
          isLoading={isLoadingUpdateOrderInvoiceIsHold}
          error={isErrorUpdateOrderInvoiceIsHold}
        />
      )}
      {!rowData ? (
        <CircularProgress size={15} />
      ) : (
        <Box display="flex">{getAttributes(rowData)}</Box>
      )}
    </>
  );
};

Attributes.propTypes = {
  rowData: PropTypes.shape({}).isRequired,
};

export default Attributes;
