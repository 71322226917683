import { Grid, styled } from '@material-ui/core';
import { Text } from '../../../../commons/formatters';

const OperationSummaryItem = styled(Grid)({
  marginBottom: 16,
});

const TotalAmountText = styled(Text)({
  fontSize: 18,
});

const DescriptionText = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey2,
}));

const OperationCostText = styled(Text)(({ theme }) => ({
  marginRight: 16,
  color: theme.palette.payments.grey2,
}));

const FlexGrid = styled(Grid)({
  display: 'flex',
});

const ExpandedGrid = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.payments.grey4}`,
  borderRadius: 8,
  padding: 24,
  marginTop: 18,
}));

const OperationSummaryWrapper = styled(Grid)({
  height: '100%',
});

const AlertMainContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.payments.error.main,
  background: theme.palette.payments.error.light,
  padding: '16px 24px',
  border: `1px solid ${theme.palette.payments.error.main}`,
  borderRadius: 16,
}));

const AlertIconGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: 23.5,
});

const AlertDescriptionGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export {
  OperationSummaryWrapper,
  OperationSummaryItem,
  TotalAmountText,
  DescriptionText,
  OperationCostText,
  FlexGrid,
  ExpandedGrid,
  AlertMainContainer,
  AlertIconGrid,
  AlertDescriptionGrid,
};
