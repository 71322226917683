import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WarningCircleIcon = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M16 8C16 12.4193 12.418 16 8 16C3.58203 16 0 12.4193 0 8C0 3.58332 3.58203 0 8 0C12.418 0 16 3.58332 16 8ZM8 9.6129C7.18048 9.6129 6.51613 10.2773 6.51613 11.0968C6.51613 11.9163 7.18048 12.5806 8 12.5806C8.81952 12.5806 9.48387 11.9163 9.48387 11.0968C9.48387 10.2773 8.81952 9.6129 8 9.6129ZM6.59119 4.27916L6.83048 8.66626C6.84168 8.87155 7.01142 9.03226 7.217 9.03226H8.783C8.98858 9.03226 9.15832 8.87155 9.16952 8.66626L9.40881 4.27916C9.4209 4.05742 9.24435 3.87097 9.02229 3.87097H6.97768C6.75561 3.87097 6.5791 4.05742 6.59119 4.27916V4.27916Z"
        fill="#C4900C"
      />
    </SvgIcon>
  );
};
export default WarningCircleIcon;
