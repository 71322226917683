import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import InfoIcon from '@material-ui/icons/Info';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import moment from 'moment';
import { Typography, Grid, Box, IconButton, Tooltip } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import {
  INVOICES_PAYMENT_DATE_PERFORM,
  INVOICES_EXPIRED_DATE_PERFORM,
  INVOICES_FUND_DATE_PERFORM,
} from '../../../../../../../helpers/performsType';
import { checkAuth } from '../../../../../../../helpers/validation/auth';

import {
  DATE_FORMAT,
  STATUS_ACTIVE,
  STATUS_COMPLETE,
  STATUS_PAID,
  STATUS_REJECTED,
} from '../../../../../../../helpers/Constants';

import {
  dateWithoutFormatter,
  isWeekend,
  setHourString,
} from '../../../../../../../helpers/DateUtils';

import Skeleton from '../../../../../../../collection/commons/components/Skeleton';
import BaseDialog from '../../../../../../../components/dialogs/BaseDialog';
import CountryFormatHelper from '../../../../../../../components/elements/CountryFormatHelper';
import AlertForm from '../../../../../../../components/elements/AlertForm';
import LoadingButton from '../../../../../../../components/elements/LoadingButton';
import styles from './styles';
import {
  useFetchOrderInvoicesFunds,
  useOrder,
  useUpdateInvoice,
  useUpdateInvoiceFund,
} from '../../../../../infrastructure/store';
import { useInvoiceTableReloadRef } from '../../store';

const BillDatesDialog = ({ open, handleCloseDialog, selectedInvoice }) => {
  const classes = styles();
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const { id: orderId } = useParams();
  const { status: orderStatus } = useOrder(orderId);
  const { country } = useSelector(state => state.config);
  const { holidays } = useSelector(state => state.holidays);
  const countryHolidays = holidays?.[country];

  const { orderInvoicesFunds } = useFetchOrderInvoicesFunds(orderId);
  const {
    updateInvoice,
    updateInvoiceIsLoading,
    invoiceWasUpdated,
    updateInvoiceError,
  } = useUpdateInvoice();
  const { updateInvoiceFund } = useUpdateInvoiceFund();
  const invoiceTableReloadRef = useInvoiceTableReloadRef();

  const {
    status,
    invoicesAttributesIsLoading,
    id: orderInvoiceId,
    issueDate,
    suggestedPaymentDate,
    averageDaysLate,
  } = selectedInvoice;

  const [datesForm, setDatesForm] = useState({
    orderId,
    id: orderInvoiceId,
    expirationDate: selectedInvoice?.expirationDate ?? '',
    paymentDate: selectedInvoice?.paymentDate ?? null,
    paymentConfirmed: selectedInvoice?.paymentConfirmed ?? null,
  });

  const orderInvoiceFund = orderInvoicesFunds?.find(
    oif => oif.orderInvoiceId === orderInvoiceId
  );

  const [fundDatesForm, setFundDatesForm] = useState({
    expirationDate: orderInvoiceFund?.expirationDate ?? '',
    paymentDate: selectedInvoice?.fundPaymentDate ?? '',
  });

  const showFundDatesForm = !!orderInvoiceFund;

  const handlePymeDateChange = (value, dateName, e = null) => {
    if (e != null) {
      e.stopPropagation();
    }
    const date = value ? setHourString(value) : null;
    setDatesForm({
      ...datesForm,
      [dateName]: date,
    });
  };

  const handleDisabledDates = date => {
    // Disable weekend days and holidays on DatePickers
    return (
      isWeekend(date) ||
      countryHolidays?.some(h =>
        moment.utc(date).isSame(moment.utc(h.date), 'day')
      )
    );
  };

  const handleFundDateChange = (value, dateName, e = null) => {
    if (e != null) {
      e.stopPropagation();
    }
    const date = value ? setHourString(value) : null;
    setFundDatesForm({
      ...fundDatesForm,
      [dateName]: date,
    });
  };

  const onSuccessCallback = () => {
    invoiceTableReloadRef();
  };

  const handleSubmit = () => {
    updateInvoice({ orderInvoiceId, invoice: datesForm, onSuccessCallback });

    if (showFundDatesForm) {
      updateInvoiceFund({
        orderInvoiceFundId: orderInvoiceFund.id,
        orderInvoiceFund: { ...fundDatesForm, orderInvoiceId },
      });
    }
  };

  useEffect(() => {
    if (invoiceWasUpdated) {
      handleCloseDialog();
    }
  }, [invoiceWasUpdated]);

  const { expirationDate, paymentDate, paymentConfirmed } = datesForm;
  const { expirationDate: fundExpirationDate, paymentDate: fundPaymenDate } =
    fundDatesForm;

  const shouldShowAdornment = () => {
    return (
      status !== STATUS_COMPLETE &&
      status !== STATUS_PAID &&
      status !== STATUS_REJECTED
    );
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Fechas de pago"
      description={
        <Box>
          <Box
            fontWeight="fontWeightBold"
            sx={{ display: 'flex', py: 1, justifyContent: 'space-between' }}
          >
            <Typography variant="body1" component="span" color="textSecondary">
              Fecha de emisión:
            </Typography>

            <CountryFormatHelper
              value={issueDate}
              variant="longdate"
              countryId={country}
            />
          </Box>

          <Box
            fontWeight="fontWeightBold"
            sx={{
              display: 'flex',
              py: 1,
              mb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Skeleton
              isLoading={invoicesAttributesIsLoading}
              variant="rect"
              width="100%"
              height={20}
            >
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Fecha de pago sugerida:
              </Typography>

              <CountryFormatHelper
                value={suggestedPaymentDate}
                variant="longdate"
                countryId={country}
              />
            </Skeleton>
          </Box>

          <Skeleton
            isLoading={invoicesAttributesIsLoading}
            variant="rect"
            width="100%"
            height={40}
          >
            <AlertForm
              variant="info"
              message={`Dado el historial de pago del proveedor la fecha sugerida es de ${
                averageDaysLate ?? '-'
              } días`}
              className={classes.alertForm}
            />
          </Skeleton>
        </Box>
      }
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={handleSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              component="div"
              className={classes.pymeTypography}
            >
              <Box fontWeight="fontWeightBold">PYME</Box>
            </Typography>
            <DatePicker
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de vencimiento"
              name="expirationDate"
              format={DATE_FORMAT}
              className={classes.input}
              value={dateWithoutFormatter(expirationDate)}
              onChange={date => handlePymeDateChange(date, 'expirationDate')}
              shouldDisableDate={date => handleDisabledDates(date)}
              disabled={
                !checkAuth(roles, INVOICES_EXPIRED_DATE_PERFORM, rules) ||
                updateInvoiceIsLoading
                  ? true
                  : orderStatus === STATUS_ACTIVE
              }
              minDateMessage="Fecha inválida"
            />
            <DatePicker
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de pago"
              name="paymentDate"
              format={DATE_FORMAT}
              className={classes.input}
              value={dateWithoutFormatter(paymentDate)}
              onChange={date => handlePymeDateChange(date, 'paymentDate')}
              disabled={
                !checkAuth(roles, INVOICES_PAYMENT_DATE_PERFORM, rules) ||
                updateInvoiceIsLoading
              }
              minDateMessage="Fecha inválida"
              maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
              disableFuture
              shouldDisableDate={date => handleDisabledDates(date)}
              InputProps={{
                endAdornment: !shouldShowAdornment() ? null : paymentDate ? (
                  <Tooltip title="Borrar fecha">
                    <IconButton
                      onClick={e =>
                        handlePymeDateChange(null, 'paymentDate', e)
                      }
                    >
                      <BackspaceOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  selectedInvoice.paymentDate && (
                    <InfoIcon className={classes.infoDateButton} />
                  )
                ),
              }}
            />

            {showFundDatesForm && (
              <>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  align="left"
                  component="div"
                  className={classes.fundTypography}
                >
                  <Box fontWeight="fontWeightBold">FONDO</Box>
                </Typography>
                <DatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  label="Fecha de vencimiento"
                  name="expirationDate"
                  format={DATE_FORMAT}
                  className={classes.input}
                  value={dateWithoutFormatter(fundExpirationDate)}
                  onChange={date =>
                    handleFundDateChange(date, 'expirationDate')
                  }
                  disabled={
                    !checkAuth(roles, INVOICES_FUND_DATE_PERFORM, rules) ||
                    updateInvoiceIsLoading
                  }
                  minDateMessage="Fecha inválida"
                />
                <DatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  label="Fecha de pago"
                  name="paymentDate"
                  format={DATE_FORMAT}
                  className={classes.input}
                  value={dateWithoutFormatter(fundPaymenDate)}
                  onChange={date => handleFundDateChange(date, 'paymentDate')}
                  disabled={
                    !checkAuth(roles, INVOICES_FUND_DATE_PERFORM, rules) ||
                    updateInvoiceIsLoading
                  }
                  InputProps={{
                    endAdornment:
                      !shouldShowAdornment() ? null : paymentDate ? (
                        <Tooltip title="Borrar fecha">
                          <IconButton
                            onClick={e =>
                              handleFundDateChange(null, 'paymentDate', e)
                            }
                          >
                            <BackspaceOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        selectedInvoice.paymentDate && (
                          <InfoIcon className={classes.infoDateButton} />
                        )
                      ),
                  }}
                />{' '}
              </>
            )}

            {((selectedInvoice.paymentConfirmed && !paymentConfirmed) ||
              (selectedInvoice.paymentDate && !paymentDate) ||
              (showFundDatesForm &&
                selectedInvoice.paymentDate &&
                !fundPaymenDate)) && (
              <Grid item xs={12} align="center" className={classes.error}>
                <Box fontSize={12} fontWeight="fontWeightBold" sx={{ pb: 2 }}>
                  Al guardar se borrarán las fechas de pago indicadas y los
                  campos quedarán vacios
                </Box>
              </Grid>
            )}

            {updateInvoiceError && (
              <AlertForm variant="error" message={updateInvoiceError} />
            )}

            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              isLoading={updateInvoiceIsLoading}
              disabled={updateInvoiceIsLoading}
            >
              Guardar
            </LoadingButton>
          </MuiPickersUtilsProvider>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

BillDatesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  selectedInvoice: PropTypes.objectOf(Object).isRequired,
};

export default BillDatesDialog;
