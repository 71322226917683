import { useEffect, useState } from 'react';

import { hasValue, to } from '../../utils';

export const useFetch = (fetcher, arrDeps = []) => {
  const [response, setResponse] = useState({
    data: null,
    isLoading: false,
    error: null,
  });

  useEffect(() => {
    if (arrDeps.some(d => !hasValue(d))) {
      return;
    }

    const fetching = async () => {
      setResponse(st => ({ ...st, isLoading: true }));
      const [err, res] = await to(fetcher());

      if (err || !res) {
        setResponse(st => ({ ...st, error: err, isLoading: false }));
      }

      setResponse(st => ({ ...st, data: res, isLoading: false }));
    };

    fetching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, arrDeps);

  return response;
};
