import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  MenuItem,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import CheckButton from './CheckButton';
import { b64toBlob, download } from '../../helpers/fileHandling';
import Container from './Container';
import Can from '../Can';
import { INVOICES_DOWNLOAD_PDF_PERFORM } from '../../helpers/performsType';
import BaseDialog from '../dialogs/BaseDialog';
import {
  getPendingInvoices,
  matchPendingInvoices,
  updatePendingInvoices,
} from '../../actions/orderActions';

const useStyles = makeStyles({
  containerJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 20px',
  },
  containerInvoices: {
    maxHeight: 400,
    overflowX: 'hidden',
    overflowY: 'scroll',
    margin: '20px 0px',
  },
  containerInvoice: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px',
  },
  containerSelectFolio: {
    paddingTop: 15,
  },
});

const MatchInvoicesPDFDialog = ({ open, handleCloseDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const {
    updatedOrder,
    pendingInvoices: invoices,
    pendingPDFList: pdfList,
    zipBase64List,
    loadingPendingInvoices,
    matchPendingInvoicesIsLoading,
    pendingInvoicesIsLoading,
  } = useSelector(state => state.order);

  useEffect(() => {
    const { id: orderId } = updatedOrder;
    dispatch(getPendingInvoices(orderId));
  }, [dispatch, updatedOrder]);

  const handleChange = (e, id) => {
    const { value: fileName } = e.target;
    dispatch(updatePendingInvoices(invoices, fileName, id));
  };

  const handleSubmit = () => {
    const { id: orderId } = updatedOrder;
    dispatch(matchPendingInvoices(orderId, invoices));
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Vincular PDF a factura correspondiente"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container>
          {loadingPendingInvoices || pendingInvoicesIsLoading ? (
            <Grid item xs={12} className={classes.loading} align="center">
              <CircularProgress size={60} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} className={classes.containerJustifyCenter}>
                <Can
                  perform={INVOICES_DOWNLOAD_PDF_PERFORM}
                  yes={() => (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        const blob = b64toBlob(
                          zipBase64List,
                          'application/zip'
                        );
                        download(blob, `facturas_orden_${updatedOrder.id}`);
                      }}
                    >
                      Descargar archivos PDF
                    </Button>
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.containerInvoices}>
                {invoices.map((invoice, id) => (
                  <Container className={classes.containerInvoice}>
                    <Grid item xs={4}>
                      <Typography color="textPrimary" variant="body1">
                        Folio: {invoice.folio}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.containerSelectFolio}>
                      <SelectValidator
                        fullWidth
                        variant="outlined"
                        id="selectInvoiceFolio"
                        value={invoice.fileName}
                        name={id}
                        onChange={e => {
                          handleChange(e, id);
                        }}
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      >
                        {pdfList.map(({ fileName }) => {
                          return (
                            <MenuItem value={fileName}>{fileName}</MenuItem>
                          );
                        })}
                      </SelectValidator>
                    </Grid>
                  </Container>
                ))}
              </Grid>
              <Grid item xs={12} className={classes.containerJustifyCenter}>
                <CheckButton
                  check={checked}
                  handleCheck={() => setChecked(!checked)}
                  labelButton="Guardar"
                  loading={matchPendingInvoicesIsLoading}
                />
              </Grid>
            </>
          )}
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

MatchInvoicesPDFDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default MatchInvoicesPDFDialog;
