import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { createDocumentService } from '../services';

export const useCreateDocument = () => {
  const dispatch = useDispatch();
  const {
    isLoading: createDocumentIsLoading,
    isSuccess: createDocumentIsSuccess,
    error: createDocumentError,
    mutate: createDocument,
    mutateAsync: createDocumentAsync,
    reset: resetDocumentData,
    data: createDocumentData,
  } = useMutation(
    ({ documentableType, documentableId, document }) => {
      return createDocumentService({
        documentableType,
        documentableId,
        document,
      });
    },
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOCUMENT_CREATED, {
            variant: 'success',
          })
        );
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CREATE_DOCUMENT_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    createDocumentIsLoading,
    createDocumentIsSuccess,
    createDocumentError,
    createDocumentData,
    createDocument,
    createDocumentAsync,
    resetDocumentData,
  };
};

export default useCreateDocument;
