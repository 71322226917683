import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
  Link,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import TGRAgreementDetails from './elements/TGRAgreementDetails';
import {
  fetchTgrAgreements,
  fetchTgrDebt,
  getTGRCertificate,
} from '../actions/businessAction';
import Container from './elements/Container';
import { SECOND_WHITE_COLOR, WHITE_PURE_COLOR } from '../helpers/Constants';
import CountryFormatHelper from './elements/CountryFormatHelper';
import { dateFormatter } from '../helpers/DateUtils';
import PaginationMaterialTable from './elements/PaginationMaterialTable';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { settings } from '../config/settings';

const useStyles = makeStyles({
  containerRoot: {
    padding: convertSpacingToCss('lg'),
  },
  download: {
    padding: '10px 0px 28px 0',
  },
  loading: {
    padding: '10px 0px 28px 0',
    fontSize: 13,
  },
  description: {
    padding: convertSpacingToCss('zero lg xl lg'),
  },
  displayFlex: {
    display: 'flex',
  },
  rowOpened: {
    fontWeight: '700',
  },
  statusChip: {
    backgroundColor: '#008232',
    color: '#EDF7FF',
  },
  statusExpired: {
    backgroundColor: '#EBEFF4',
    color: '#697086',
  },
  title: {
    fontWeight: '700',
    marginBottom: 16,
  },
});

const stylesCustom = {
  generalStyles: {
    borderRadius: 17,
    boxShadow: 'none',
    padding: 0,
    overflow: 'hidden',
  },
};

const TGRAgreements = ({ id: businessId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { country } = useSelector(state => state.config);
  const { isApiGlobal } = settings[country];

  const {
    tgrAgreements,
    tgrAgreementsPagination: pagination,
    loadingTgrAgreements,
    fetchingTGRCertificate,
  } = useSelector(state => state.business);

  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchTgrAgreements({ businessId }));
      if (isApiGlobal) {
        dispatch(fetchTgrDebt(businessId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch]);

  const sumBalanceFolio = tgrAgreements => {
    tgrAgreements.forEach((tgr, index) => {
      const { TGRDebtsAgreements: tgrDebtsAgreements } = tgr;
      const totalBalance = tgrDebtsAgreements.reduce(
        (total, current) => total + current.balance,
        0
      );
      // eslint-disable-next-line no-param-reassign
      tgrAgreements[index].currentBalance = totalBalance;
    });
  };

  useEffect(() => {
    sumBalanceFolio(tgrAgreements);
  }, [tgrAgreements]);

  const buildTableTGRAgreements = [
    {
      title: 'ID',
      field: 'id',
      type: 'numeric',
      align: 'left',
      defaultSort: 'asc',
      render: rowData => {
        return (
          <Typography
            variant="body2"
            color="textPrimary"
            className={clsx({
              [classes.rowOpened]: selectedRow === rowData.tableData.id,
            })}
          >
            {rowData.id}
          </Typography>
        );
      },
    },
    {
      title: 'Nº Resolución',
      field: 'resolutionNumber',
      type: 'numeric',
      defaultSort: 'asc',
      align: 'left',
      render: rowData => (
        <Typography
          variant="body2"
          color="textPrimary"
          className={clsx({
            [classes.rowOpened]: selectedRow === rowData.tableData.id,
          })}
        >
          {rowData.resolutionNumber}
        </Typography>
      ),
    },
    {
      title: 'Fecha Resolución',
      field: 'resolutionDate',
      type: 'date',
      align: 'left',
      render: rowData => {
        return (
          <Typography
            variant="body2"
            color="textPrimary"
            className={clsx({
              [classes.rowOpened]: selectedRow === rowData.tableData.id,
            })}
          >
            {dateFormatter(rowData.resolutionDate)}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo de Pago',
      field: 'paymentType',
      type: 'string',
      width: 150,
      render: rowData => (
        <Typography
          variant="body2"
          color="textPrimary"
          className={clsx({
            [classes.rowOpened]: selectedRow === rowData.tableData.id,
          })}
        >
          {rowData.paymentType}
        </Typography>
      ),
    },
    {
      title: 'Nº Cuotas',
      field: 'grantedFees',
      type: 'numeric',
      align: 'left',
      render: rowData => (
        <Typography
          variant="body2"
          color="textPrimary"
          className={clsx({
            [classes.rowOpened]: selectedRow === rowData.tableData.id,
          })}
        >
          {rowData.grantedFees}
        </Typography>
      ),
    },
    {
      title: 'Nº de Deudas',
      field: 'debtNumbers',
      type: 'numeric',
      align: 'left',
      render: rowData => (
        <Typography
          align="left"
          variant="body2"
          color="textPrimary"
          className={clsx({
            [classes.rowOpened]: selectedRow === rowData.tableData.id,
          })}
        >
          {rowData.debtNumbers}
        </Typography>
      ),
    },
    {
      title: 'Saldo Neto Actual ($)',
      field: 'currentBalance',
      type: 'numeric',
      align: 'left',
      width: 170,
      render: rowData => {
        return (
          <Typography
            variant="body2"
            color="textPrimary"
            className={clsx({
              [classes.rowOpened]: selectedRow === rowData.tableData.id,
            })}
          >
            <CountryFormatHelper
              value={rowData.currentBalance}
              variant="currency"
              countryId="CL"
            />
          </Typography>
        );
      },
    },
    {
      title: 'Estado',
      align: 'left',
      render: rowData => {
        const isAgreementExpired = !rowData.isActive;

        return (
          <Chip
            label={
              <Typography
                variant="subtitle2"
                className={clsx(classes.statusChip, {
                  [classes.statusExpired]: isAgreementExpired,
                })}
              >
                {isAgreementExpired ? 'Caducado' : 'Vigente'}
              </Typography>
            }
            className={clsx(classes.statusChip, {
              [classes.statusExpired]: isAgreementExpired,
            })}
            size="small"
          />
        );
      },
    },
  ];

  return (
    <Container className={classes.containerRoot}>
      <Grid container item xs={12} direction="column">
        {fetchingTGRCertificate ? (
          <Typography variant="body2" align="left" className={classes.loading}>
            <Box display="flex">
              <CircularProgress
                size={18}
                style={{
                  marginRight: '4px',
                }}
              />
              Cargando...
            </Box>
          </Typography>
        ) : (
          <Typography variant="body2" align="left" className={classes.download}>
            <Link
              component="button"
              underline="always"
              onClick={() => dispatch(getTGRCertificate(businessId))}
            >
              <Box display="flex">Ver certificado</Box>
            </Link>
          </Typography>
        )}

        <Typography variant="h5" align="left" className={classes.title}>
          Convenios
        </Typography>
        <MaterialTable
          columns={buildTableTGRAgreements}
          data={tgrAgreements}
          isLoading={loadingTgrAgreements}
          style={stylesCustom.generalStyles}
          onChangeRowsPerPage={pageSize =>
            dispatch(
              fetchTgrAgreements({ businessId, page: 1, limit: pageSize })
            )
          }
          components={{
            Pagination: props =>
              isApiGlobal ? (
                <PaginationMaterialTable
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  count={pagination.totalData}
                  page={pagination.page - 1}
                  onChangePage={(e, page) =>
                    dispatch(
                      fetchTgrAgreements({
                        businessId,
                        page: page + 1,
                        // eslint-disable-next-line react/prop-types
                        limit: props.rowsPerPage,
                      })
                    )
                  }
                />
              ) : (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <PaginationMaterialTable {...props} />
              ),
            Toolbar: () => null,
          }}
          options={{
            detailPanelType: 'single',
            actionsColumnIndex: -1,
            pageSize: pagination.pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            rowStyle: rowData => ({
              backgroundColor:
                selectedRow === rowData.tableData.id
                  ? SECOND_WHITE_COLOR
                  : WHITE_PURE_COLOR,
              ...stylesCustom.rowStyle,
            }),
            headerStyle: stylesCustom.headerStyle,
            emptyRowsWhenPaging: false,
            toolbar: false,
          }}
          detailPanel={[
            {
              disabled: true,
              render: agreement => {
                return <TGRAgreementDetails agreement={agreement} />;
              },
            },
          ]}
          onRowClick={(event, agreement, togglePanel) => {
            const { id } = agreement.tableData;

            setSelectedRow(id === selectedRow ? null : id);
            const { innerHTML } = event.target;
            if (
              innerHTML !== '' &&
              innerHTML !== 'Activo' &&
              innerHTML !== 'Inactivo' &&
              innerHTML.indexOf('<path') === -1
            ) {
              return togglePanel();
            }
            return false;
          }}
          localization={{
            pagination: {
              labelRowsPerPage: 'Convenios TGR por página',
              labelDisplayedRows: '{count} convenios totales',
            },
            body: {
              emptyDataSourceMessage: 'No hay convenios que mostrar',
            },
          }}
        />
      </Grid>
    </Container>
  );
};

TGRAgreements.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TGRAgreements;
