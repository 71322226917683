import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { Debts, PayerDebts } from '../../../commons/interfaces/payerDebts';

export const fetchPayerById = async (
  id: string,
  sortBy: string
): Promise<PayerDebts> => {
  try {
    const { data } = await axios.get(`/api/collection/byPayer/${id}`, {
      params: { sortBy },
    });
    return {
      payerIdentifier: data.root,
      payerName: data.nameRoot,
      debtsQuantity: data.cantDebtInvoices,
      expiredDebtsQuantity: data.cantExpiredInvoices,
      totalAmount: data.totalAmount,
      payerDebts: parseInvoiceToDebt(data.invoices),
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    }
    throw new Error(UNKNOWN_ERROR);
  }
};

const parseInvoiceToDebt = (invoices: any[]): Debts[] =>
  invoices.map(invoice => ({
    issuing: invoice.issuing,
    issuer: invoice.issuer,
    amount: invoice.amount,
    folio: invoice.folio,
    issueDate: new Date(invoice.issueDate),
    invoiceType: invoice.invoiceType,
    invoiceStakeholderIdentifier: invoice.invoiceStakeholderIdentifier,
    orderInovice: {
      id: invoice.orderInovices.id,
      status: invoice.orderInovices.status,
      expirationDate: new Date(invoice.orderInovices.expirationDate),
      orderID: invoice.orderInovices.orderId,
      verificationStatus: invoice.orderInovices.verificationStatus,
    },
    order: { orderType: invoice.order.orderType, status: invoice.order.status },
    business: {
      identifier: invoice.business.identifier,
      name: invoice.business.businessName,
      segment: invoice.business.segment,
    },
    custodian: { name: invoice.custodian.custodianName },
    fund: { name: invoice.fund.fundName },
    ...(invoice.extensions?.id
      ? {
          extensions: {
            id: invoice.extensions.id,
            orderInvoiceId: invoice.extensions.orderInvoiceId,
            type: invoice.extensions.type,
            newExpirationDate: new Date(invoice.extensions.newExpirationDate),
            firstFeeAmount: invoice.extensions.firstFeeAmount,
            newBaseRate: invoice.extensions.newBaseRate,
            justification: invoice.extensions.justification,
            linkDocuments: invoice.extensions.linkDocuments,
            createdBy: invoice.extensions.createdBy,
            relatedInvoices: invoice.extensions.relatedInvoices,
            startDate: new Date(invoice.extensions.startDate),
            createdAt: new Date(invoice.extensions.createdAt),
            updatedAt: new Date(invoice.extensions.updatedAt),
          },
        }
      : {}),
  }));
