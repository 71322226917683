export const OPERATION_TYPE = {
  DIRECT_FINANCING: {
    value: 'DIRECT_FINANCING',
    label: 'FD',
  },
  EARLY_PAYMENT: {
    value: 'EARLY_PAYMENT',
    label: 'PP',
  },
  PAYMENTS_WITH_CESSION: {
    value: 'PAYMENTS_WITH_CESSION',
    label: 'PTC',
  },
  PAYMENTS_WITHOUT_CESSION: {
    value: 'PAYMENTS_WITHOUT_CESSION',
    label: 'PT',
  },
};
