import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PropTypes from 'prop-types';
import DataList from './DataList';
import { getTransferAmountPyme } from '../../actions/orderActions';
import { features } from '../../config/features';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: 17,
  },
  title: {
    marginTop: 6,
    marginRight: 8,
  },
  expansionPanel: {
    boxShadow: 'none',
    borderBottom: '1px solid #F1F0F6',
  },
  iconExpansion: {
    marginTop: 8,
    marginRight: 4,
    width: 13,
    height: 13,
    color: theme.palette.default.main,
  },
  iconExpandLessIcon: {
    marginTop: 3,
    marginLeft: -7,
    width: 24,
    height: 24,
    color: theme.palette.default.main,
  },
  detail: {
    borderBottom: 10,
  },
  containerLinkFound: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 5,
  },
  message: {
    marginBottom: 16,
  },
}));

const useStylesComponent = {
  expansionPanel: {
    borderRadius: '17px 17px 0px 0px',
    marginTop: 0,
    marginBottom: 0,
  },
};

const ExpansionPanelResumeOperation = ({
  items = [],
  buttonBar,
  requestTransferAmount,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('operation');
  const { id } = useParams();

  const dispatch = useDispatch();
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];

  useEffect(() => {
    setExpanded('operation');
  }, []);

  useEffect(() => {
    if (
      id &&
      countryFeatures.orderActions.fetchTransferAmountPyme &&
      requestTransferAmount
    ) {
      dispatch(getTransferAmountPyme(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const handleExpand = (e, type) => {
    // If posButtonChip is 0, then the user clicked on the chip Button
    const posButtonChip = e.target.outerHTML.indexOf(
      '<span class="MuiChip-label">'
    );
    if (posButtonChip) {
      const activeExpand = type === expanded ? '' : type;
      setExpanded(activeExpand);
    }
  };

  return (
    <Grid xs={12} className={classes.root}>
      {items?.length ? (
        <>
          {items.map(item => (
            <ExpansionPanel
              expanded={expanded === item?.type}
              className={classes.expansionPanel}
              onChange={e => handleExpand(e, item?.type)}
              style={useStylesComponent.expansionPanel}
              key={item?.title}
            >
              <ExpansionPanelSummary>
                {expanded !== '' && item?.type === expanded ? (
                  <ExpandLessIcon className={classes.iconExpandLessIcon} />
                ) : (
                  <ArrowForwardIosIcon className={classes.iconExpansion} />
                )}
                <Typography
                  className={classes.title}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  <Box fontWeight="fontWeightBold">{item?.title}</Box>
                </Typography>
                {buttonBar && item?.type === 'operation' && <>{buttonBar}</>}
                {item?.button}
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {item?.data?.length ? (
                  <DataList
                    wide
                    data={item?.data}
                    justify="flex-end"
                    requestTransferAmount={requestTransferAmount}
                    isLoading={item?.isLoading}
                  />
                ) : (
                  <Grid container className={classes.containerLinkFound}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      align="center"
                      className={classes.message}
                    >
                      {item?.message || 'No hay información disponible.'}
                    </Typography>
                  </Grid>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </>
      ) : (
        <Typography
          variant="body1"
          color="textPrimary"
          align="center"
          className={classes.message}
        >
          No hay información disponible.
        </Typography>
      )}
    </Grid>
  );
};

ExpansionPanelResumeOperation.defaultProps = {
  requestTransferAmount: true,
};

ExpansionPanelResumeOperation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      data: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.objectOf(PropTypes.any),
      ]).isRequired,
      button: PropTypes.element,
      message: PropTypes.string,
      statusOrder: PropTypes.string,
    })
  ).isRequired,
  buttonBar: PropTypes.element.isRequired,
  requestTransferAmount: PropTypes.bool,
};

export default ExpansionPanelResumeOperation;
