import { useQuery } from 'react-query';
import { creditPropCardMessage } from '../constants';
import postCreditLineProposalService, {
  CreditLineProposal,
} from '../services/postCreditLineProposalService';

const usePostCreditLineProposal = (country: string, identifier?: string) => {
  const {data, ...rest} = useQuery<CreditLineProposal>(
    'postCreditLineProposal',
    async () => postCreditLineProposalService({ identifier, country }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: Boolean(identifier),
    },
  );

  const amount = data?.creditline;
  let message = '';

  if (amount?.toString() === '-' || !amount) {
    message = creditPropCardMessage;
  }

  return {
    ...rest,
    amount,
    message,
  };
};

export default usePostCreditLineProposal;
