import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import BaseDialog from '../dialogs/BaseDialog';
import CheckButton from './CheckButton';
import CountryFormatHelper from './CountryFormatHelper';
import AlertForm from './AlertForm';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: '-10px',
    marginBottom: 10,
  },
  dialogSubTitle: {
    marginBottom: 10,
  },
  dialogLightText: {
    marginBottom: 28,
  },
  displayInLineBlock: {
    display: 'inline-block',
  },
  marginTop: {
    marginTop: 25,
  },
}));

const DebtSuperDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  debtSuperData,
}) => {
  const classes = useStyles();
  const { consultDebtSuperIsLoading, consultDebtSuperError } = useSelector(
    state => state.debtService
  );
  const { diffDays, currentDebt, identifier, businessName } = debtSuperData;
  const [checked, setChecked] = useState(false);

  const handleFormSubmit = () => {
    handleSubmit(identifier);
  };

  const colorText = currentDebt > 0 ? 'error.main' : 'default.main';

  let labelLastConsult = `No se ha realizado una consulta`;
  if (diffDays === 0) {
    labelLastConsult = `Última consulta hoy`;
  } else if (diffDays > 0) {
    labelLastConsult = `Última consulta hace ${diffDays} días`;
  }

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Deuda Super"
    >
      <ValidatorForm onSubmit={handleFormSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="center"
              className={classes.title}
              variant="subtitle1"
              color="textPrimary"
              component="div"
            >
              <Box fontWeight="fontWeightBold">{businessName}</Box>
            </Typography>

            {currentDebt !== null ? (
              <Typography
                align="center"
                variant="subtitle1"
                color="textPrimary"
                component="div"
                className={classes.dialogText}
              >
                <Box fontWeight="fontWeightBold" color={colorText}>
                  Deuda:{' '}
                  <CountryFormatHelper
                    value={currentDebt * 1000}
                    countryId="CL"
                    variant="currency"
                  />
                </Box>
              </Typography>
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                color="textPrimary"
              >
                No posee registros en Deuda Financiera
              </Typography>
            )}

            <Typography
              className={classes.dialogLightText}
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              {labelLastConsult}
            </Typography>

            <Typography
              align="center"
              className={classes.dialogSubTitle}
              component="div"
              variant="subtitle1"
              color="textPrimary"
            >
              <Box fontWeight="fontWeightBold">Consultar Deuda Financiera</Box>
            </Typography>

            <Grid item xs={12} align="center">
              <Typography
                align="center"
                display="inline"
                variant="subtitle1"
                color="textPrimary"
              >
                Estás por consultar a{' '}
              </Typography>
              <Typography
                align="center"
                display="inline"
                color="textPrimary"
                component="div"
              >
                <Box
                  fontWeight="fontWeightBold"
                  className={classes.displayInLineBlock}
                >
                  {businessName}
                </Box>
              </Typography>{' '}
              <Typography
                align="center"
                display="inline"
                variant="subtitle1"
                color="textPrimary"
                className={classes.dialogText}
              >
                en Deuda Financiera, esta acción generará cargos. ¿Deseas
                continuar?
              </Typography>
            </Grid>

            {consultDebtSuperError && (
              <Grid item xs={12} className={classes.marginTop}>
                <AlertForm message={consultDebtSuperError} variant="error" />
              </Grid>
            )}

            <Grid item xs={12} className={classes.marginTop}>
              <CheckButton
                loading={consultDebtSuperIsLoading}
                check={checked}
                handleCheck={() => setChecked(!checked)}
                labelButton="Guardar"
              />
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </BaseDialog>
  );
};

DebtSuperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  debtSuperData: PropTypes.shape({
    diffDays: PropTypes.number,
    currentDebt: PropTypes.string,
    identifier: PropTypes.string,
    businessName: PropTypes.string,
  }).isRequired,
};

export default DebtSuperDialog;
