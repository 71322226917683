import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const AddItemIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      style={style}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.770508 6.10374L2.06301 7.39624L6.27051 3.19791L10.478 7.39624L11.7705 6.10374L6.27051 0.603739L0.770508 6.10374Z"
        fill="#1A49E7"
      />
    </CustomSvgIcon>
  );
};

AddItemIcon.defaultProps = {
  style: {},
};

AddItemIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default AddItemIcon;
