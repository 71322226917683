import { getRangeOfDays, purgeNestedObj } from '../../../../commons/utils';

const TODAY = new Date();

export const updateByBulkEditionUseCase = ({
  currentSelectorState,
  draftPayrollState,
  fundHasBeenSelected,
  newValues,
}) => {
  const selectedDraftReceiverIdentifiers = Object.keys(draftPayrollState);
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    const { advancePercentage, days, debt, rate } = newValues;
    const term = getRangeOfDays(days, TODAY);
    const formattedNewValues = purgeNestedObj({
      advancePercentage,
      expirationDate: term,
      issuer: { debtServiceAmount: debt },
      rate,
    });

    if (selectedDraftReceiverIdentifiers.includes(receiverIdentifier)) {
      acc[receiverIdentifier] = {
        ...currentReceiver,
        ...(!fundHasBeenSelected &&
          purgeNestedObj({
            rate,
            term,
          })),
        value: currentReceiver.value.map(doc => {
          const selectedDocumentsIds = draftPayrollState[
            receiverIdentifier
          ].value.map(d => d.id);

          return {
            ...doc,
            ...(selectedDocumentsIds.includes(doc.id) && formattedNewValues),
          };
        }),
      };
    } else {
      acc[receiverIdentifier] = { ...currentReceiver };
    }

    return acc;
  }, {});
};
