import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import CheckButton from '../elements/CheckButton';
import loading from '../../assets/loading.gif';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import AlertForm from '../elements/AlertForm';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles(() => ({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 5,
  },
  dialogLightText: {
    marginBottom: 28,
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  debtStyles: {
    marginBottom: 6,
  },
  secondTitle: {
    marginBottom: 10,
  },
  marginTop: {
    marginTop: 25,
  },
  displayInLineBlock: {
    display: 'inline-block',
  },
}));

const DebtServiceDialog = ({
  open,
  handleCloseDialog,
  handleSubmit,
  saving,
  debtServiceData,
  error,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const country = useSelector(state => state.config.country);
  const { identifier, rut } = useSelector(state => state.business.business);
  const {
    montoTotal,
    totalAmount = montoTotal,
    name,
    diffDays,
    reloadId,
  } = debtServiceData;
  const debtIdentifier = debtServiceData?.identifier ?? identifier ?? rut;
  const consulting = useSelector(state => state.debtService.consulting);

  useEffect(() => {
    setChecked(false);
  }, []);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleFormSubmit = () => {
    handleSubmit(debtIdentifier, reloadId);
  };

  let labelLastConsult = `No se ha realizado consulta`;
  if ([-1, 0].includes(Number(diffDays))) {
    labelLastConsult = `Última consulta hoy`;
  } else if (diffDays > 0) {
    labelLastConsult = `Última consulta hace ${diffDays.toString()} días`;
  }

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={t(t13s.LABEL.DEBT_SERVICE_NAME)}
      subtitle={name}
    >
      <Grid container direction="column" align="center" justify="center">
        <Grid item xs={12} />
        {saving ? (
          <Grid container justify="center">
            <Grid item xs={12} className={classes.justifyCenter}>
              <img src={loading} alt="loader" />
            </Grid>
          </Grid>
        ) : (
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container direction="row" spacing={4} justify="center">
              <Grid item xs={12}>
                <Grid>
                  <Typography
                    className={classes.debtStyles}
                    color="textPrimary"
                    align="center"
                    variant="subtitle1"
                    component="div"
                  >
                    <Box
                      display="inline"
                      color="text.primary"
                      fontWeight="fontWeightBold"
                    >
                      Deuda:{' '}
                    </Box>
                    <Box
                      display="inline"
                      color={
                        totalAmount && totalAmount > 0
                          ? 'error.main'
                          : 'text.primary'
                      }
                      fontWeight="fontWeightBold"
                    >
                      <CountryFormatHelper
                        value={totalAmount}
                        variant="currency"
                        countryId={country}
                      />
                    </Box>
                  </Typography>
                </Grid>

                <Grid>
                  <Typography
                    className={classes.dialogLightText}
                    color="textSecondary"
                    variant="subtitle1"
                    align="center"
                  >
                    {labelLastConsult}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    align="center"
                    color="textPrimary"
                    className={classes.secondTitle}
                    variant="subtitle1"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">
                      Consultar {t(t13s.LABEL.DEBT_SERVICE_NAME)}
                    </Box>
                  </Typography>
                </Grid>

                <Grid item xs={12} align="center">
                  <Typography
                    align="center"
                    display="inline"
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    Estás por consultar a{' '}
                  </Typography>
                  <Typography
                    align="center"
                    variant="subtitle1"
                    display="inline"
                    color="textPrimary"
                    component="div"
                  >
                    <Box
                      fontWeight="fontWeightBold"
                      className={classes.displayInLineBlock}
                    >
                      {name}
                    </Box>
                  </Typography>{' '}
                  <Typography
                    align="center"
                    display="inline"
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    en {t(t13s.LABEL.DEBT_SERVICE_NAME)}. Esta acción generará
                    cargos. ¿Deseas continuar?
                  </Typography>
                </Grid>
                {error && <AlertForm message={error} variant="error" />}
                <Grid item xs={12} className={classes.marginTop}>
                  <CheckButton
                    check={checked}
                    handleCheck={handleCheckboxChange}
                    labelButton="Consultar"
                    loading={consulting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        )}
      </Grid>
    </BaseDialog>
  );
};

DebtServiceDialog.defaultProps = {
  error: undefined,
};

DebtServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  error: PropTypes.string,
  debtServiceData: PropTypes.shape({
    identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalAmount: PropTypes.number,
    montoTotal: PropTypes.number,
    name: PropTypes.string,
    diffDays: PropTypes.number,
    reloadId: PropTypes.number,
  }).isRequired,
};

export default DebtServiceDialog;
