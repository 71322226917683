import { Grid, styled } from '@material-ui/core';

const HeaderGrid = styled(Grid)({
  background: '#FFFFFF',
  padding: 24,
});

const ButtonGrid = styled(Grid)({
  display: 'flex',
  direction: 'row',
  alignItems: 'flex-end',
});

const BreadcrumbsGrid = styled(Grid)({
  marginBottom: 20.5,
});

const SummaryTitleGrid = styled(Grid)({
  marginBottom: 24,
});

const OrderCommentsGrid = styled(Grid)({
  marginBottom: 25,
  paddingRight: 5,
});

export {
  HeaderGrid,
  ButtonGrid,
  BreadcrumbsGrid,
  SummaryTitleGrid,
  OrderCommentsGrid,
};
