import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { fetchFund } from '../../actions/fundsActions';
import BankAccountDialog from '../dialogs/BankAccountDialog';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import {
  fetchBankAccountsById,
  setDefaultBankAccount,
  deleteBankAccount,
  registerBankAccount,
  resetBankAccountHolder,
  selectBankAccount,
  resetBankAccountDialog,
} from '../../actions/bankActions';
import { BUSINESS_ADD_BANK_ACCOUNT_PERFORM } from '../../helpers/performsType';
import BankAccountItem from './BankAccountItem';
import Container from './Container';
import Can from '../Can';
import Panel from './Panel';
import { settings } from '../../config/settings';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
  },
  root: {
    flexGrow: 1,
  },
});

const entity = 'fund';

const FundBankAccounts = () => {
  const [showBankAccountDialog, setShowBankAccountDialog] = useState(false);
  const [showDeleteBankAccountDialog, setShowDeleteBankAccountDialog] =
    useState(false);

  const dispatch = useDispatch();

  const { id } = useParams();

  const { t } = useTranslation();

  const classes = useStyles();

  const { fund } = useSelector(state => state.funds);
  const { country } = useSelector(state => state.config);

  const { isApiGlobal } = settings[country];

  const {
    bankAccounts,
    bankAccountSettedDefault,
    deletedBankAccount,
    registeredBankAccount,
    settingDefaultBankAccount,
    selectedBankAccount,
  } = useSelector(state => state.banks);

  const handleCloseDeleteBankAccountDialog = () => {
    setShowDeleteBankAccountDialog(false);
    dispatch(resetBankAccountHolder());
  };

  const handleCloseBankAccountDialog = () => {
    setShowBankAccountDialog(false);
    dispatch(resetBankAccountDialog());
  };

  const updateBankAccounts = () => {
    if (isApiGlobal) {
      dispatch(fetchBankAccountsById(entity, id));
    } else {
      dispatch(fetchFund(id));
    }
  };

  useEffect(() => {
    if (id) dispatch(fetchFund(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fund?.BankAccounts && !(id in bankAccounts)) {
      updateBankAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deletedBankAccount) {
      handleCloseDeleteBankAccountDialog();
      updateBankAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedBankAccount]);

  useEffect(() => {
    if (registeredBankAccount) {
      handleCloseBankAccountDialog();
      updateBankAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredBankAccount]);

  useEffect(() => {
    if (bankAccountSettedDefault) {
      updateBankAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountSettedDefault]);

  const handleShowBankAccountDialog = () => {
    setShowBankAccountDialog(true);
  };

  const handleSubmitBankAccount = bankAccountData => {
    const { id: fundId } = fund;
    const bankAccountDataObj = { ...bankAccountData };

    // The bank account table now uses sourceId instead of businessId
    bankAccountDataObj.sourceId = fundId;

    // This new property is to indicate whether the new bank acccount is for a business or a fund (more sources could be implented in the future).
    bankAccountDataObj.source = 'FUND';

    dispatch(registerBankAccount(entity, bankAccountDataObj));
  };

  const handleSetDefaultBankAccount = bankAccountId => {
    const { id } = fund;
    dispatch(setDefaultBankAccount(entity, id, bankAccountId));
  };

  const handleShowDeleteBankAccountDialog = bankAccount => {
    dispatch(selectBankAccount(bankAccount));
    setShowDeleteBankAccountDialog(true);
  };

  const handleDeleteBankAccount = () => {
    const { id } = fund;
    dispatch(deleteBankAccount(entity, id, selectedBankAccount));
  };

  const BankAccounts =
    fund?.BankAccounts ?? (bankAccounts[entity] && bankAccounts[entity][id]);

  const formatBankAccount = bankAccount => {
    const { accountRut, sourceIdentifier } = bankAccount;
    return { ...bankAccount, sourceIdentifier: sourceIdentifier ?? accountRut };
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {showDeleteBankAccountDialog && (
        <ConfirmDialog
          isOpen={showDeleteBankAccountDialog}
          handleClose={handleCloseDeleteBankAccountDialog}
          title="Eliminar cuenta bancaria"
          subtitle="¿Deseas eliminar esta cuenta?"
          buttonLabel="Eliminar"
          buttonOnAccept={handleDeleteBankAccount}
        />
      )}
      {showBankAccountDialog && (
        <BankAccountDialog
          open={showBankAccountDialog}
          onSubmit={handleSubmitBankAccount}
          onClose={handleCloseBankAccountDialog}
        />
      )}
      <Grid
        container
        direction="column"
        className={classes.mainContainer}
        spacing={3}
      >
        <Grid container direction="column" spacing={1} xs={12}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5">
              {fund?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={5}>
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >{`ID: ${fund?.id}`}</Typography>
              </Grid>
              <Grid item>
                <Typography color="textPrimary" variant="body2">{`${t(
                  t13s.LABEL.BUSINESS_IDENTIFIER
                )}: ${fund?.rut ?? fund?.identifier}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container gridGap={20}>
          <Panel
            title="Cuentas bancarias"
            variant="section"
            titlePadding="lg"
            contentPadding="zero zero lg zero"
            actions={
              <Can
                perform={BUSINESS_ADD_BANK_ACCOUNT_PERFORM}
                yes={() => (
                  <Button
                    onClick={handleShowBankAccountDialog}
                    startIcon={<AddCircleIcon />}
                    variant="text"
                    color="primary"
                  >
                    Agregar cuenta
                  </Button>
                )}
                no={() => null}
              />
            }
          >
            <Container direction="row">
              {BankAccounts?.length > 0 ? (
                BankAccounts?.map(bankAccount => {
                  return (
                    <BankAccountItem
                      key={`item-${bankAccount.id}`}
                      bankAccount={formatBankAccount(bankAccount)}
                      handleShowDeleteDialog={handleShowDeleteBankAccountDialog}
                      handleShowDefaultAccountDialog={
                        handleSetDefaultBankAccount
                      }
                      disableRadio={settingDefaultBankAccount}
                    />
                  );
                })
              ) : (
                <Container className={classes.containerRoot}>
                  <Typography variant="subtitle1" color="textPrimary">
                    No se encontraron cuentas bancarias
                  </Typography>
                </Container>
              )}
            </Container>
          </Panel>
        </Container>
      </Grid>
    </div>
  );
};

export default FundBankAccounts;
