import React, { useState } from 'react';
import Container from '../../../components/elements/Container';
import { HomeCollectionHeader, HomeCollectionBody } from '../../home/ui';
import { ContainerRoot, ContainerHeader } from './styles';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { useGetUserMessage } from './hooks/useGetUserMessage';

type LayoutContainerProps = { onClose: (value: boolean) => void };
const LayoutContainer = ({ onClose }: LayoutContainerProps) => {
  const styles = useStyles();
  const messageToUser = useGetUserMessage();
  return (
    <Container className={styles.layoutContainerStyles}>
      <Box width="100%">
        <Typography variant="body1">{messageToUser}</Typography>
      </Box>
      <Box pr={5}>
        <CloseIcon onClick={() => onClose(false)} />
      </Box>
    </Container>
  );
};

const HomeCollection = () => {
  const [showLayoutContainer, setShowLayoutContainer] = useState<boolean>(true);

  return (
    <>
      {showLayoutContainer && (
        <LayoutContainer onClose={setShowLayoutContainer} />
      )}
      <ContainerRoot>
        <ContainerHeader>
          <HomeCollectionHeader />
        </ContainerHeader>
        <HomeCollectionBody />
      </ContainerRoot>
    </>
  );
};

export default HomeCollection;
