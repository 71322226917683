import { Tab as MaterialTab, Tabs, styled } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import StatusFilterLabel from './StatusFilterLabel';

const Tab = styled(MaterialTab)(({ selected, theme }) => ({
  fontWeight: selected ? 700 : 400,
  color: selected ? theme.palette.primary.main : theme.palette.payments.grey2,
  minWidth: 'auto',
  padding: '0 16px',
}));

// THIS IS A NASTY PATCH FIX
const CustomTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    width: 0,
  },
});

const StatusFilter = ({ filters, activeFilter, handler }) => {
  return (
    <CustomTabs
      value={activeFilter}
      onChange={handler}
      variant="scrollable"
      scrollButtons="auto"
    >
      {filters.map(({ key, label }) => (
        <Tab
          key={key}
          label={
            <StatusFilterLabel
              status={{ key, label }}
              selected={activeFilter}
            />
          }
          value={key}
        />
      ))}
    </CustomTabs>
  );
};

StatusFilter.propTypes = {
  handler: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(String).isRequired,
  activeFilter: PropTypes.string.isRequired,
};

export default StatusFilter;
