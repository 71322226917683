import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../../../../../components/elements/SectionHeader';
import Panel from '../../../../../components/elements/Panel';
import { BtnFilter } from '../../../../commons/components/BtnFilter';
import { useStyles } from './Documents.styles';
import { DocumentList } from './DocumentList';
import { InvoiceFilter } from '../../../../commons/components/InvoiceFilter';
import {
  useReceiverActions,
  useSelectedReceiver,
} from '../../../domains/receiver/state';
import { GET_RECEIVER_FIELDS } from './constants';
import { useFetchReceiver } from '../../../domains/receiver/services';
import { useGetCountry } from '../../../../adapters/store';
import { FETCH_DOCUMENTS_KEY } from '../../../domains/document/services';
import { purgeNestedObj } from '../../../../commons/utils';
import {
  useDocumentActions,
  useGetDocumentForm,
} from '../../../domains/document/state';
import { useOpportunitiesContext } from '../../../context/OpportunitiesContext';
import { FUNDS_BASE_PATH } from '../../../../commons/constants/ui';

export const Documents = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const country = useGetCountry();
  const queryClient = useQueryClient();
  const { receiverIdentifier } = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const selectedReceiver = useSelectedReceiver();
  const { selectReceiver } = useReceiverActions();
  const { saveDocumentForm } = useDocumentActions();
  const documentForm = useGetDocumentForm();
  const opportunitiesCtx = useOpportunitiesContext();

  const { isFetching } = useFetchReceiver(
    { receiverIdentifier, ...documentForm },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      onSuccess: res => {
        selectReceiver(res);
      },
    }
  );

  const receiverSummary = useMemo(
    () =>
      GET_RECEIVER_FIELDS({
        country,
        receiver: selectedReceiver,
        t,
      }),
    [country, selectedReceiver, t]
  );

  const onSubmit = useCallback(
    summaryForm => {
      setOpenFilter(false);
      queryClient.invalidateQueries([FETCH_DOCUMENTS_KEY]);
      saveDocumentForm(purgeNestedObj(summaryForm?.form));
    },
    [setOpenFilter, queryClient, saveDocumentForm]
  );

  return (
    <>
      <Grid container className={css.shoppingCartRow}>
        <SectionHeader
          title={selectedReceiver?.name}
          breadCrumbs={[
            {
              title: 'Oportunidades',
              // eslint-disable-next-line max-len
              link: `/${country.toLowerCase()}/${FUNDS_BASE_PATH}/opportunities`,
            },
            {
              title: 'Detalle Oportunidad',
            },
          ]}
          padding="xl lg sm"
          isLoading={isFetching}
          showBreadCrumbs
          mainArea={
            <Box display="flex">
              {receiverSummary.map(({ title, value }, idx) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  display="flex"
                  className={css.containerTextMainAreaBold}
                >
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={css.textMainAreaBold}
                  >
                    <Box fontWeight="fontWeightBold">{title}</Box>
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {value}
                  </Typography>
                </Box>
              ))}
              <Link
                component="button"
                variant="body1"
                align="left"
                onClick={() =>
                  opportunitiesCtx.setOpenReceiverDrawer({
                    open: true,
                    receiverIdentifier: selectedReceiver.receiverIdentifier,
                  })
                }
              >
                Ver más detalle
              </Link>
            </Box>
          }
        />

        <Grid container className={css.btnContainer} spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box display="flex" justifyContent="flex-end">
                <BtnFilter
                  appliedFilters={Object.values(documentForm).length}
                  setShowFilter={() => setOpenFilter(true)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} className={css.containerFundPayerInvoicesTable}>
          <Panel title="Facturas" titlePadding="lg" contentPadding="lg lg zero">
            <DocumentList filters={documentForm} />
          </Panel>
        </Grid>
      </Grid>

      <InvoiceFilter
        fieldsToHide={{ businessStatus: true }}
        form={documentForm}
        onClose={() => setOpenFilter(false)}
        onSubmit={onSubmit}
        open={openFilter}
      />
    </>
  );
};
