import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';
import { useSelector } from 'react-redux';

import usePostCreditLineProposal from '../../hooks/usePostCreditLineProposal';
import { RootState } from '../../../../reducers/rootReducer';
import { creditPropCardMessage } from '../../constants';
import { getCurrencyFormat } from '../../utils';
import { BusinessSelector, Country } from '../../schemas';

type Props = {
  country: Country;
};

const CreditLineProposalCard = ({ country }: Props) => {
  const { business } = useSelector(
    (state: RootState) => state.business as BusinessSelector,
  );

  const { isLoading, amount, message } = usePostCreditLineProposal(country, business?.identifier);

  return (
    <Box p={5} display="flex" alignItems="center">
      <Box sx={{ mr: 2 }}>
        <MonetizationOnOutlined fontSize="large" />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="textSecondary">
          <Box fontWeight="bold" fontSize="12.5">
						Línea de crédito Modelo
          </Box>
        </Typography>
        <Box sx={{ mt: 1, p: 0.5 }}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            component="span">
            <Box fontWeight="bold">
              {isLoading ? (
                <CircularProgress size={14} />
              ) : (
                <Box fontWeight="bold">
                  {message === creditPropCardMessage ? (
                    message
                  ) : (
                    amount && getCurrencyFormat(amount, country)
                  )}
                </Box>
              )}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditLineProposalCard;
