import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { fetchPartialPaymentsService } from '../services';

export const useFetchPartialPayments = ({ sourceType, sourceId }) => {
  const dispatch = useDispatch();
  const placeholderData = () => {
    return { data: { data: [] } };
  };
  const {
    data: fetchPartialPaymentData,
    refetch: refetchPartialPayments,
    isLoading: fetchPartialPaymentsIsLoading,
  } = useQuery(
    'fetchPartialPayments',
    () => fetchPartialPaymentsService(sourceType, sourceId),
    {
      placeholderData,
      refetchOnWindowFocus: false,
      enabled: false,
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_PARTIAL_PAYMENTS_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    refetchPartialPayments,
    fetchPartialPaymentsIsLoading,
    fetchPartialPaymentData,
  };
};

export default useFetchPartialPayments;
