import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Panel from './elements/Panel';
import { fetchDocuments } from '../actions/documentActions';
import CreateDocumentDialog from './dialogs/CreateDocumentDialog';
import DocumentsTable from './tables/DocumentsTable';
import { COUNTRY_CODE_MX } from '../helpers/Constants';

const BUSINESS = 'business';

const BusinessDocuments = ({ businessId }) => {
  const dispatch = useDispatch();

  const {
    businessDocuments,
    businessDocumentsIsLoading,
    updateSignedFileDocumentIsLoading,
    documentWasCreate,
    createDocumentIsLoading,
    createDocumentError,
  } = useSelector(state => state.documents);
  const { country } = useSelector(state => state.config);

  const [showCreateDocumentDialog, setShowCreateDocumentDialog] =
    useState(false);

  useEffect(() => {
    if (documentWasCreate && !updateSignedFileDocumentIsLoading) {
      setShowCreateDocumentDialog(false);
    }
  }, [documentWasCreate, updateSignedFileDocumentIsLoading]);

  useEffect(() => {
    dispatch(fetchDocuments(businessId, 'business'));
  }, [businessId, dispatch]);

  return (
    <Grid item xs={12}>
      <Panel
        title="Documentos"
        titlePadding="lg"
        contentPadding="zero zero lg"
        variant="section"
        actions={
          <Button
            variant="text"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setShowCreateDocumentDialog(true)}
          >
            Nuevo Documento
          </Button>
        }
      >
        <DocumentsTable
          documents={businessDocuments}
          documentsAreLoading={businessDocumentsIsLoading}
          documentableType={BUSINESS}
          documentableId={businessId}
        />
      </Panel>

      {showCreateDocumentDialog && (
        <CreateDocumentDialog
          open={showCreateDocumentDialog}
          handleCloseDialog={() => setShowCreateDocumentDialog(false)}
          isLoading={
            createDocumentIsLoading || updateSignedFileDocumentIsLoading
          }
          error={createDocumentError}
          documentableId={businessId}
          documentableType={BUSINESS}
          showCustomDocument={country === COUNTRY_CODE_MX}
          showDescription={country === COUNTRY_CODE_MX}
        />
      )}
    </Grid>
  );
};

BusinessDocuments.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export default BusinessDocuments;
