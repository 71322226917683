export const updateBySimulationUseCase = ({
  currentSelectorState,
  response,
}) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    acc[receiverIdentifier] = {
      ...currentReceiver,
      ...response[receiverIdentifier],
    };

    return acc;
  }, {});
};
