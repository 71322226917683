const verifyRut = rutInput => {
  // clean the input
  const rut = rutInput.replace(/[^kK0-9]/g, '').slice(0, -1);
  if (rut.length < 7) return false;

  // get the dv
  const dv = rutInput.slice(-1).toLowerCase();

  let mult = 2;
  const suma = [...rut].reverse().reduce((sum, val) => {
    const acc = mult * val;
    mult = mult < 7 ? mult + 1 : 2;
    return sum + acc;
  }, 0);

  const expectedDv = 11 - (suma % 11);
  // special cases
  // eslint-disable-next-line eqeqeq
  const compareDv = dv === 'k' ? 10 : dv == 0 ? 11 : dv;

  // eslint-disable-next-line eqeqeq
  return compareDv == expectedDv;
};

export default verifyRut;
