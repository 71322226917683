import { makeStyles } from '@material-ui/core';
import { ALT_BORDER_COLOR } from '../../../../../../theme/otherColors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gridTemplateColumns: '1fr max-content max-content max-content',
    width: '100%',
    height: '45px',
    borderRadius: 50,
    border: `1px solid ${ALT_BORDER_COLOR}`,
    boxSizing: 'border-box',
    background: '#fff',
    padding: 10,
    '&:hover': {
      borderColor: `${theme.palette.grey[800]}`,
    },
    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
  },
  rootData: {
    borderRadius: '15px 15px 0 0',
  },
  input: {
    margin: '0 5px',
    flex: 1,
  },
  iconButton: {
    padding: 4,
    justifyContent: 'start',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  menuItem: {
    width: '190px',
    justifyContent: 'center',
  },
  menu: {
    maxHeight: 400,
  },
  textIconAlone: {
    paddingLeft: 5,
    paddingRight: 10,
  },
  datePicker: {
    marginBottom: 0,
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important',
    },
  },
  displayInfoContainer: {
    background: '#fff',
    border: '1px solid #CDCAD8',
    marginTop: -2,
    padding: 10,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 16px rgb(0 0 0 / 20%)',
    borderRadius: '0 0 15px 15px',
  },
  buttonIcon: {
    background: '#1C1732',
    borderRadius: 7,
    color: '#fff',
    display: 'flex',
    width: 'fit-content',
    padding: 4,
  },
  hintContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  rowActive: {
    backgroundColor: '#EDEAF0 !important',
  },
}));

export default useStyles;
