import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AlertForm from '../elements/AlertForm';
import Dropzone from '../elements/Dropzone/Dropzone';
import BaseDialog from './BaseDialog';

import { SIGNED_DOCUMENT } from '../../helpers/Constants';

const useStyles = makeStyles({
  error: {
    marginTop: 20,
  },
});

const UploadFileDialog = ({
  open,
  handleCloseDialog,
  title,
  accept,
  description,
  handleSubmit,
  isLoading,
  uploadType,
  error,
  resetErrors,
}) => {
  const classes = useStyles();

  const handleSubmitFile = files => {
    handleSubmit(files, { type: uploadType ?? SIGNED_DOCUMENT });
  };

  useEffect(() => {
    resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseDialog
      size="lg"
      isOpen={open}
      handleClose={handleCloseDialog}
      title={title}
      description={description}
    >
      <Grid item xs={12} align="center">
        {isLoading ? (
          <CircularProgress size={60} color="primary" />
        ) : (
          <Dropzone
            handleSubmit={handleSubmitFile}
            accept={accept}
            maxFiles={1}
            minSize={100}
            isLoading={isLoading}
          />
        )}
      </Grid>
      {error && (
        <Grid item xs={12} align="center" className={classes.error}>
          <AlertForm variant="error" message={error} />
        </Grid>
      )}
    </BaseDialog>
  );
};

UploadFileDialog.defaultProps = {
  error: null,
  uploadType: null,
  resetErrors: () => {},
};

UploadFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  uploadType: PropTypes.string,
  error: PropTypes.string,
  resetErrors: PropTypes.func,
};

export default UploadFileDialog;
