import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys/index';
import { bulkCreateManagements } from '../services';

interface Props {
    orderInvoices: number[];
    type: string;
    subType: string;
    result: string;
    appliedOn: string;
    justification: string;
}

const useBulkCreateManagements = (onSuccessAction: () => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    data,
    mutate: createManagements,
    reset: resetDataAndErrors,
    isSuccess,
  } = 
   useMutation((params: Props) => bulkCreateManagements(params), {
    onMutate: async () => {
      await queryClient.cancelQueries('getManagementsSummary', {
        exact: false,
      });
      const prevManagements = queryClient.getQueryData('getManagementsSummary', {
        exact: false,
      });
      return prevManagements;
    },
    onError: (_err, _variables, context: unknown) => {
      dispatch(
        enqueueSnackbar(t13s.ERROR.ERROR_CREATE_BULK_MANAGEMENTS, {
          variant: 'error',
        })
      );
      queryClient.setQueryData('getManagementsSummary', (context as any).data);
    },
    onSettled: () => {
      queryClient.invalidateQueries('getManagementsSummary', {
        exact: false,
      });
    },
    onSuccess: () => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COLLECTION_MANAGEMENT_BULK_CREATED, {
          variant: 'success',
        })
      );
      onSuccessAction();
    },
  });

  return {
    isLoading,
    isError,
    data,
    isSuccess,
    createManagements,
    resetDataAndErrors,
  };
};

export default useBulkCreateManagements;