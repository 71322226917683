/* eslint-disable react/prop-types */
import React from 'react';

import {
  defaultValue,
  fixDecimals,
  roundToInt,
} from '../../../../commons/utils';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { t13s } from '../../../../../translationKeys';

export const GET_RECEIVER_FIELDS = ({ country, receiver, t }) => [
  {
    title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
    value: defaultValue(receiver?.receiverIdentifier),
  },
  {
    title: 'N° Facturas:',
    value: defaultValue(receiver?.documentsNumber),
  },
  {
    title: 'Monto bruto:',
    value: (
      <CountryFormatHelper
        value={receiver?.amount}
        countryId={country}
        variant="currency"
      />
    ),
  },
  {
    title: 'N° Emisores:',
    value: defaultValue(receiver?.issuersNumber),
  },
  {
    title: 'Plazo:',
    value: receiver?.term ? `${roundToInt(receiver?.term)} días` : '-',
  },
  {
    title: 'Score Ponderado:',
    value: defaultValue(fixDecimals(receiver?.score)),
  },
];
