import {
  FETCH_PAYERS_BLOCKLIST_START,
  FETCH_PAYERS_BLOCKLIST_SUCCESS,
  FETCH_PAYERS_BLOCKLIST_FAILURE,
  REGISTER_PAYER_BLOCKLIST_START,
  REGISTER_PAYER_BLOCKLIST_SUCCESS,
  REGISTER_PAYER_BLOCKLIST_FAILURE,
  UPDATE_PAYER_BLOCKLIST_START,
  UPDATE_PAYER_BLOCKLIST_SUCCESS,
  UPDATE_PAYER_BLOCKLIST_FAILURE,
  FETCH_PAYER_BLOCKLIST_START,
  FETCH_PAYER_BLOCKLIST_SUCCESS,
  FETCH_PAYER_BLOCKLIST_FAILURE,
  CSV_REGISTER_PAYERS_BLOCKLIST_START,
  CSV_REGISTER_PAYERS_BLOCKLIST_SUCCESS,
  CSV_REGISTER_PAYERS_BLOCKLIST_FAILURE,
  RESET_REGISTER_PAYER_BLOCKLIST_ERROR,
  RESET_SELECTED_PAYER_NAME,
  RESET_CSV_REGISTER_PAYERS_BLOCKLIST_ERROR,
} from '../actions/types';

const initialState = {
  payersBlocklist: [],
  fetchPayersBlocklistIsLoading: false,
  fetchPayersBlocklistError: null,
  pagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
  },

  registerPayerBlocklistIsLoading: false,
  payerBlocklistWasRegistered: false,
  registerPayerBlocklistError: null,

  updatePayerBlocklistIsLoading: false,
  updatePayerBlocklistError: null,

  payerSelected: null,
  fetchPayerBlocklistIsLoading: false,
  payerBlocklistWasConsulted: false,
  fetchPayerBlocklistError: null,

  csvRegisterPayersBlocklistIsLoading: false,
  csvPayersBlocklistWasRegistered: false,
  csvPayersBlocklistWithError: [],
  csvRegisterPayersBlocklistError: null,
};

const fetchPayersBlocklistStart = state => {
  return {
    ...state,
    fetchPayersBlocklistIsLoading: true,
    fetchPayersBlocklistError: null,
  };
};
const fetchPayersBlocklistSuccess = (state, payload) => {
  return {
    ...state,
    fetchPayersBlocklistIsLoading: false,
    payersBlocklist: payload.data,
    pagination: payload.pagination,
  };
};
const fetchPayersBlocklistFailure = (state, payload) => {
  return {
    ...state,
    fetchPayersBlocklistIsLoading: false,
    fetchPayersBlocklistError: payload.errorCode,
  };
};

const registerPayerBlocklistStart = state => {
  return {
    ...state,
    registerPayerBlocklistIsLoading: true,
    registerPayerBlocklistError: null,
    payerBlocklistWasRegistered: false,
  };
};
const registerPayerBlocklistSuccess = (state, payload) => {
  const getPayersBlocklist = [...state.payersBlocklist];
  getPayersBlocklist.unshift(payload);
  return {
    ...state,
    registerPayerBlocklistIsLoading: false,
    payerBlocklistWasRegistered: true,
    payersBlocklist: getPayersBlocklist,
  };
};
const registerPayerBlocklistFailure = (state, payload) => {
  return {
    ...state,
    registerPayerBlocklistIsLoading: false,
    registerPayerBlocklistError: payload.errorCode,
  };
};

const updatePayerBlocklistStart = state => {
  return {
    ...state,
    updatePayerBlocklistIsLoading: true,
    updatePayerBlocklistError: null,
  };
};
const updatePayerBlocklistSuccess = (state, payload) => {
  const newPayerList = state.payersBlocklist.map(payer => {
    return payer.id === payload.id ? payload : payer;
  });

  return {
    ...state,
    updatePayerBlocklistIsLoading: false,
    payersBlocklist: newPayerList,
  };
};
const updatePayerBlocklistFailure = (state, payload) => {
  return {
    ...state,
    updatePayerBlocklistIsLoading: false,
    updatePayerBlocklistError: payload.errorCode,
  };
};

const fetchPayerBlocklistStart = state => {
  return {
    ...state,
    fetchPayerBlocklistIsLoading: true,
    fetchPayerBlocklistError: null,
    payerBlocklistWasConsulted: false,
    payerSelected: null,
  };
};
const fetchPayerBlocklistSuccess = (state, payload) => {
  return {
    ...state,
    fetchPayerBlocklistIsLoading: false,
    payerBlocklistWasConsulted: true,
    payerSelected: payload,
  };
};
const fetchPayerBlocklistFailure = (state, payload) => {
  return {
    ...state,
    fetchPayerBlocklistIsLoading: false,
    fetchPayerBlocklistError: payload.errorCode,
  };
};

const csvRegisterPayersBlocklistStart = state => {
  return {
    ...state,
    csvRegisterPayersBlocklistIsLoading: true,
    csvPayersBlocklistWasRegistered: false,
    csvPayersBlocklistWithError: [],
    csvRegisterPayersBlocklistError: null,
  };
};
const csvRegisterPayersBlocklistSuccess = (state, payload) => {
  const { registeredPayers, errorPayers } = payload;
  const newPayerList = [...registeredPayers, ...state.payersBlocklist];

  return {
    ...state,
    csvRegisterPayersBlocklistIsLoading: false,
    csvPayersBlocklistWasRegistered: !errorPayers.length,
    csvPayersBlocklistWithError: errorPayers,
    payersBlocklist: newPayerList,
  };
};
const csvRegisterPayersBlocklistFailure = (state, payload) => {
  return {
    ...state,
    csvRegisterPayersBlocklistIsLoading: false,
    csvRegisterPayersBlocklistError: payload.errorCode,
  };
};

const resetRegisterPayerBlocklistError = state => {
  return {
    ...state,
    registerPayerBlocklistError: null,
  };
};

const resetSelectedPayerName = state => {
  return {
    ...state,
    payerSelected: null,
    payerBlocklistWasConsulted: false,
  };
};

const resetCsvRegisterPayersBlocklistError = state => {
  return {
    ...state,
    csvPayersBlocklistWithError: [],
    csvRegisterPayersBlocklistError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYERS_BLOCKLIST_START:
      return fetchPayersBlocklistStart(state);
    case FETCH_PAYERS_BLOCKLIST_SUCCESS:
      return fetchPayersBlocklistSuccess(state, payload);
    case FETCH_PAYERS_BLOCKLIST_FAILURE:
      return fetchPayersBlocklistFailure(state, payload);
    case REGISTER_PAYER_BLOCKLIST_START:
      return registerPayerBlocklistStart(state);
    case REGISTER_PAYER_BLOCKLIST_SUCCESS:
      return registerPayerBlocklistSuccess(state, payload);
    case REGISTER_PAYER_BLOCKLIST_FAILURE:
      return registerPayerBlocklistFailure(state, payload);
    case UPDATE_PAYER_BLOCKLIST_START:
      return updatePayerBlocklistStart(state);
    case UPDATE_PAYER_BLOCKLIST_SUCCESS:
      return updatePayerBlocklistSuccess(state, payload);
    case UPDATE_PAYER_BLOCKLIST_FAILURE:
      return updatePayerBlocklistFailure(state, payload);
    case FETCH_PAYER_BLOCKLIST_START:
      return fetchPayerBlocklistStart(state);
    case FETCH_PAYER_BLOCKLIST_SUCCESS:
      return fetchPayerBlocklistSuccess(state, payload);
    case FETCH_PAYER_BLOCKLIST_FAILURE:
      return fetchPayerBlocklistFailure(state, payload);
    case CSV_REGISTER_PAYERS_BLOCKLIST_START:
      return csvRegisterPayersBlocklistStart(state);
    case CSV_REGISTER_PAYERS_BLOCKLIST_SUCCESS:
      return csvRegisterPayersBlocklistSuccess(state, payload);
    case CSV_REGISTER_PAYERS_BLOCKLIST_FAILURE:
      return csvRegisterPayersBlocklistFailure(state);
    case RESET_REGISTER_PAYER_BLOCKLIST_ERROR:
      return resetRegisterPayerBlocklistError(state);
    case RESET_SELECTED_PAYER_NAME:
      return resetSelectedPayerName(state);
    case RESET_CSV_REGISTER_PAYERS_BLOCKLIST_ERROR:
      return resetCsvRegisterPayersBlocklistError(state);
    default:
      return state;
  }
};
