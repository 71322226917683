import axios from 'axios';
import { getErrorCode } from '../helpers/handleErrors';
import {
  FETCH_ECONOMIC_GROUP_START,
  FETCH_ECONOMIC_GROUP_SUCCESS,
  FETCH_ECONOMIC_GROUP_FAILURE,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchEconomicGroups = () => dispatch => {
  dispatch({ type: FETCH_ECONOMIC_GROUP_START });
  axios
    .get('/api/egroup')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_ECONOMIC_GROUP_SUCCESS, payload: resData });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({ type: FETCH_ECONOMIC_GROUP_FAILURE, payload: { errorCode } });
    });
};
