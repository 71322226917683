export const updateBySummaryRateUseCase = ({
  currentSelectorState,
  newRate,
}) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    acc[receiverIdentifier] = {
      ...currentReceiver,
      ...newRate,
      value: currentReceiver.value.map(doc => ({
        ...doc,
        ...newRate,
      })),
    };

    return acc;
  }, {});
};
