import React from 'react';
import { Box } from '@material-ui/core';
import { Schedule, Check, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

const errorIcon = <Close fontSize="small" color="error" />;

const VERIFICATION_STATUS_ICON = {
  VERIFIED: (
    <Box color="success.main">
      <Check fontSize="small" />
    </Box>
  ),
  VERIFYING: <Schedule fontSize="small" />,
  UNVERIFIED: errorIcon,
  FAILED: errorIcon,
  INVALID: errorIcon,
};

const BankAccountVerificationIcon = ({ verificationStatus }) => {
  return VERIFICATION_STATUS_ICON[verificationStatus] ?? errorIcon;
};

BankAccountVerificationIcon.propTypes = {
  verificationStatus: PropTypes.string.isRequired,
};

export default BankAccountVerificationIcon;
