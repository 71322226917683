
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      gridTemplateColumns: '1fr max-content max-content max-content',
      width: 320,
      height: 34.44,
      borderRadius: 100,
      border: '1px solid #c4c4c4',
      boxSizing: 'border-box',
      background: '#fff',
      padding: 2,
      '&:hover': {
        borderColor: `${theme.palette.grey[800]}`,
      },
      '&:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
      },
    },
    input: {
      margin: '0 5px',
      flex: 1,
    },
    iconButton: {
      padding: 4,
      justifyContent: 'start',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    menuItem: {
      width: '190px',
      justifyContent: 'center',
    },
    menu: {
      maxHeight: 400,
    },
    textIconAlone: {
      paddingLeft: 5,
      paddingRight: 10,
    },
    datePicker: {
      marginBottom: 0,
      '& .MuiInput-underline:after': {
        borderBottom: 'unset !important',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'unset !important',
      },
    },
}));

export {
    useStyles,
  };
  