import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { useInvoiceTableReloadRef } from '../../ui/PayrollInvoices/InvoicesTable/store';
import { updateOrderInvoiceStatusService } from '../services';

export const useUpdateOrderInvoiceStatus = () => {
  const dispatch = useDispatch();
  const invoiceTableReloadRef = useInvoiceTableReloadRef();
  const queryClient = useQueryClient();
  const {
    isLoading: updateOrderInvoiceStatusIsUpdating,
    isSuccess: updateOrderInvoiceStatusIsSuccess,
    error: updateOrderInvoiceStatusIsError,
    mutate: updateOrderInvoiceStatus,
  } = useMutation(
    ({ id, invoiceData, extraData }) => {
      return updateOrderInvoiceStatusService({
        id,
        invoiceData,
        extraData,
      });
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.INVOICES_UPDATED, {
            variant: 'success',
          })
        );
        onSuccessCallback();
        invoiceTableReloadRef();
      },
      onSettled: () => {
        queryClient.invalidateQueries('getOrder', {
          exact: false,
        });
        queryClient.invalidateQueries('getOrderSummary', {
          exact: false,
        });
        queryClient.invalidateQueries('getInvoices', {
          exact: false,
        });
      },
    }
  );

  return {
    updateOrderInvoiceStatusIsUpdating,
    updateOrderInvoiceStatusIsSuccess,
    updateOrderInvoiceStatusIsError,
    updateOrderInvoiceStatus,
  };
};

export default useUpdateOrderInvoiceStatus;
