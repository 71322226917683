import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import ReactDropzoneUploader from 'react-dropzone-uploader';
import { Typography, Grid, makeStyles, Button } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Preview from './Preview';
import Layout from './Layout';

const useStyles = makeStyles({
  dropzone: {
    background: '#FBFAFC 0% 0% no-repeat padding-box',
    border: '2px dashed #A8A4B3',
    borderRadius: 28,
    width: '100%',
    height: 225,
    textAlign: 'center',
    minHeight: 200,
    maxHeight: 250,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  inputLabelWithFiles: {
    margin: 0,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    background: '#FBFAFC 0% 0% no-repeat padding-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },
  submitButtonContainer: {
    justifyContent: 'center',
    marginBottom: 0,
  },
  button: {
    marginTop: 20,
  },
});

const Input = (files, { maxFiles }) => {
  const textItem = maxFiles > 1 ? 'los archivos' : 'el archivo';
  const textAction = maxFiles > 1 ? 'subirlos' : 'subirlo';
  return (
    <Grid>
      <SaveAltIcon fontSize="large" color="action" />
      <Typography color="textPrimary" variant="body1">
        {`Arrastra ${textItem} aquí o haz click`}
        <br />
        {`para ${textAction} desde tu computador.`}
      </Typography>
    </Grid>
  );
};

const Dropzone = props => {
  // eslint-disable-next-line react/prop-types
  const {
    multiple,
    accept,
    maxFiles,
    handleSubmit,

    ...otherDropzoneProps
  } = props;
  const classes = useStyles();

  const handleSubmitFiles = (files, allFiles) => {
    if (handleSubmit) {
      handleSubmit(files);
    }
    allFiles.forEach(f => f.remove());
  };

  return (
    <ReactDropzoneUploader
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherDropzoneProps}
      LayoutComponent={multiple ? Layout : null}
      SubmitButtonComponent={props => {
        // eslint-disable-next-line react/prop-types
        const { onSubmit, disabled, files = [] } = props;
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit(
                files.filter(f =>
                  ['headers_received', 'done'].includes(f.meta.status)
                )
              );
            }}
            disabled={disabled}
            className={classes.button}
          >
            {files.length > 1 ? 'Subir archivos' : 'Subir archivo'}
          </Button>
        );
      }}
      inputContent={Input}
      inputWithFilesContent={Input}
      PreviewComponent={Preview}
      onSubmit={handleSubmitFiles}
      classNames={{
        dropzone: classes.dropzone,
        inputLabel: classes.inputLabel,
        inputLabelWithFiles: classes.inputLabelWithFiles,
        submitButtonContainer: classes.submitButtonContainer,
      }}
      accept={accept}
      maxFiles={maxFiles}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

Dropzone.defaultProps = {
  handleSubmit: null,
  multiple: false,
};

Dropzone.propTypes = {
  accept: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func,
  multiple: PropTypes.bool,
};

export default Dropzone;
