import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { OpportunitiesContextProvider } from '../../../context/OpportunitiesContext';
import { OpportunitiesRoutes } from '../../../../adapters/routes/opportunities.routes';

export const OpportunitiesRouter = ({ basePath }) => {
  return (
    <OpportunitiesContextProvider>
      <Switch>{OpportunitiesRoutes(basePath)}</Switch>
    </OpportunitiesContextProvider>
  );
};

OpportunitiesRouter.propTypes = {
  basePath: PropTypes.string.isRequired,
};
