import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker, Day } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import InfoIcon from '@material-ui/icons/Info';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import moment from 'moment';
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  updateOrderInvoice,
  updateOrderInvoiceFund,
} from '../../actions/orderActions';
import {
  INVOICES_PAYMENT_DATE_PERFORM,
  INVOICES_CONFIRMED_DATE_PERFORM,
  INVOICES_EXPIRED_DATE_PERFORM,
  INVOICES_FUND_DATE_PERFORM,
} from '../../helpers/performsType';
import { checkAuth } from '../../helpers/validation/auth';

import {
  DATE_FORMAT,
  STATUS_ACTIVE,
  STATUS_COMPLETE,
  STATUS_PAID,
  STATUS_REJECTED,
} from '../../helpers/Constants';

import BaseDialog from './BaseDialog';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import {
  dateFormatter,
  dateWithoutFormatter,
  isWeekend,
  setHourString,
  getDay,
} from '../../helpers/DateUtils';
import AlertForm from '../elements/AlertForm';
import LoadingButton from '../elements/LoadingButton';
import Skeleton from '../../collection/commons/components/Skeleton';
import { palette } from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  infoDateButton: { color: theme.palette.info.main },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconParallelBar: {
    marginRight: 11,
  },
  group: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const BillDatesDialog = ({
  open,
  handleCloseDialog,
  selectedInvoice,
  statusOrder,
  updateInvoiceAttributes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const { country } = useSelector(state => state.config);
  const { updatingInvoice, updateFacturaErrors } = useSelector(
    state => state.order
  );
  const { orderInvoicesFund = {} } = useSelector(state => state.funds);
  const { holidays } = useSelector(state => state.holidays);
  const countryHolidays = holidays?.[country];

  const [datesForm, setDatesForm] = useState({
    id: selectedInvoice.id,
    orderId: selectedInvoice.orderId,
    expirationDate: '',
    paymentDate: '',
    paymentConfirmed: '',
  });
  const [fundDatesForm, setFundDatesForm] = useState({
    expirationDate: '',
    paymentDate: '',
  });

  const orderInvoiceFund = orderInvoicesFund?.find(
    oif => oif.orderInvoiceId === selectedInvoice.id
  );

  const { status } = selectedInvoice;
  const showFundDatesForm = !!orderInvoiceFund;

  const { fetchOrderInvoicesAttributesIsLoading } = useSelector(
    state => state.order
  );

  useEffect(() => {
    setDatesForm({
      id: selectedInvoice?.id,
      orderId: selectedInvoice?.orderId,
      expirationDate:
        selectedInvoice?.fechaVencimiento || selectedInvoice?.expirationDate,
      paymentDate: selectedInvoice?.paymentDate,
      paymentConfirmed: selectedInvoice?.paymentConfirmed,
    });

    if (orderInvoiceFund || selectedInvoice?.fundPaymentDate) {
      setFundDatesForm({
        expirationDate: orderInvoiceFund?.expirationDate,
        paymentDate: selectedInvoice?.fundPaymentDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoice]);

  const handlePymeDateChange = (value, dateName, e = null) => {
    if (e != null) {
      e.stopPropagation();
    }
    const date = value ? setHourString(value) : null;
    setDatesForm({
      ...datesForm,
      [dateName]: date,
    });
  };

  const handleDisabledDates = date => {
    // Disable weekend days and holidays on DatePickers
    return (
      isWeekend(date) ||
      countryHolidays?.some(h =>
        moment.utc(date).isSame(moment.utc(h.date), 'day')
      )
    );
  };

  const handleFundDateChange = (value, dateName, e = null) => {
    if (e != null) {
      e.stopPropagation();
    }
    const date = value ? setHourString(value) : null;
    setFundDatesForm({
      ...fundDatesForm,
      [dateName]: date,
    });
  };

  const handleSubmit = () => {
    const { id, orderId } = selectedInvoice;
    dispatch(updateOrderInvoice(id, orderId, { ...datesForm }));

    if (showFundDatesForm) {
      dispatch(
        updateOrderInvoiceFund(orderInvoiceFund.id, {
          ...fundDatesForm,
          orderInvoiceId: id,
        })
      );
    }
  };

  const { fechaEmision, issueDate, suggestedPaymentDate, averageDaysLate } =
    selectedInvoice;
  const { expirationDate, paymentDate, paymentConfirmed } = datesForm;
  const { expirationDate: fundExpirationDate, paymentDate: fundPaymenDate } =
    fundDatesForm;

  const shouldShowAdornment = () => {
    return (
      status !== STATUS_COMPLETE &&
      status !== STATUS_PAID &&
      status !== STATUS_REJECTED
    );
  };

  /*
    call event from child to parent for update 
    invoice props attributes [suggestedPaymentDate && averageDaysLate] 
  */
  useEffect(() => {
    updateInvoiceAttributes(selectedInvoice.id);
  }, [fetchOrderInvoicesAttributesIsLoading]);

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title="Fechas de pago"
      data-cy="billDatesDialogContainer"
      description={
        <Box>
          <Box
            fontWeight="fontWeightBold"
            sx={{ display: 'flex', py: 1, justifyContent: 'space-between' }}
          >
            <Typography variant="body1" component="span" color="textSecondary">
              Fecha de emisión:
            </Typography>

            <CountryFormatHelper
              value={fechaEmision || issueDate}
              variant="longdate"
              countryId={country}
            />
          </Box>

          <Box
            fontWeight="fontWeightBold"
            sx={{
              display: 'flex',
              py: 1,
              mb: 2,
              justifyContent: 'space-between',
            }}
          >
            <Skeleton
              isLoading={fetchOrderInvoicesAttributesIsLoading}
              variant="rect"
              width="100%"
              height={20}
            >
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
              >
                Fecha de pago sugerida:
              </Typography>

              <CountryFormatHelper
                value={suggestedPaymentDate}
                variant="longdate"
                countryId={country}
              />
            </Skeleton>
          </Box>

          <Skeleton
            isLoading={fetchOrderInvoicesAttributesIsLoading}
            variant="rect"
            width="100%"
            height={40}
          >
            <AlertForm
              variant="info"
              message={`Dado el historial de pago del proveedor la fecha sugerida es de ${averageDaysLate} días`}
              className={classes.group}
            />
          </Skeleton>
        </Box>
      }
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={handleSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <DatePicker
              renderDay={date => {
                if (
                  !paymentConfirmed &&
                  dateFormatter(new Date()) === dateFormatter(date)
                ) {
                  return (
                    <Day
                      style={{
                        backgroundColor: palette.primary.main,
                        color: palette.primary.contrastText,
                      }}
                    >
                      {getDay(date)}
                    </Day>
                  );
                }

                if (dateFormatter(paymentConfirmed) === dateFormatter(date)) {
                  return (
                    <Day
                      style={{
                        backgroundColor: palette.primary.main,
                        color: palette.primary.contrastText,
                      }}
                    >
                      {getDay(date)}
                    </Day>
                  );
                }

                if (
                  !fetchOrderInvoicesAttributesIsLoading &&
                  dateFormatter(suggestedPaymentDate) === dateFormatter(date)
                ) {
                  return (
                    <Day style={{ backgroundColor: palette.grey[300] }}>
                      {getDay(date)}
                    </Day>
                  );
                }

                return <Day>{getDay(date)}</Day>;
              }}
              fullWidth
              autoOk
              inputVariant="outlined"
              label="Fecha pago confirmada deudor"
              id="paymentConfirmed"
              name="paymentConfirmed"
              format={DATE_FORMAT}
              className={classes.input}
              value={dateWithoutFormatter(paymentConfirmed)}
              onChange={date => handlePymeDateChange(date, 'paymentConfirmed')}
              data-cy="billDatesDialogPaymentConfirmed"
              disabled={
                !checkAuth(roles, INVOICES_CONFIRMED_DATE_PERFORM, rules) ||
                updatingInvoice
              }
              minDateMessage="Fecha inválida"
              InputProps={{
                endAdornment: paymentConfirmed ? (
                  <Tooltip title="Borrar fecha">
                    <IconButton
                      onClick={e =>
                        handlePymeDateChange(null, 'paymentConfirmed', e)
                      }
                    >
                      <BackspaceOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  selectedInvoice.paymentConfirmed && (
                    <InfoIcon className={classes.infoDateButton} />
                  )
                ),
              }}
            />
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              component="div"
              className={classes.group}
            >
              <Box fontWeight="fontWeightBold">PYME</Box>
            </Typography>
            <DatePicker
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de vencimiento"
              name="expirationDate"
              format={DATE_FORMAT}
              className={classes.input}
              value={dateWithoutFormatter(expirationDate)}
              onChange={date => handlePymeDateChange(date, 'expirationDate')}
              data-cy="billDatesDialogExpirationDate"
              shouldDisableDate={date => handleDisabledDates(date)}
              disabled={
                !checkAuth(roles, INVOICES_EXPIRED_DATE_PERFORM, rules) ||
                updatingInvoice
                  ? true
                  : statusOrder === STATUS_ACTIVE
              }
              minDateMessage="Fecha inválida"
            />
            <DatePicker
              autoOk
              fullWidth
              inputVariant="outlined"
              label="Fecha de pago"
              name="paymentDate"
              format={DATE_FORMAT}
              className={classes.input}
              value={dateWithoutFormatter(paymentDate)}
              onChange={date => handlePymeDateChange(date, 'paymentDate')}
              data-cy="billDatesDialogPaymentDate"
              disabled={
                !checkAuth(roles, INVOICES_PAYMENT_DATE_PERFORM, rules) ||
                updatingInvoice
              }
              minDateMessage="Fecha inválida"
              maxDateMessage="La fecha de pago no puede ser mayor a la de hoy"
              disableFuture
              shouldDisableDate={date => handleDisabledDates(date)}
              InputProps={{
                endAdornment: !shouldShowAdornment() ? null : paymentDate ? (
                  <Tooltip title="Borrar fecha">
                    <IconButton
                      onClick={e =>
                        handlePymeDateChange(null, 'paymentDate', e)
                      }
                    >
                      <BackspaceOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  selectedInvoice.paymentDate && (
                    <InfoIcon className={classes.infoDateButton} />
                  )
                ),
              }}
            />

            {showFundDatesForm && (
              <>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  align="left"
                  component="div"
                  className={classes.group}
                >
                  <Box fontWeight="fontWeightBold">FONDO</Box>
                </Typography>
                <DatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  label="Fecha de vencimiento"
                  name="expirationDate"
                  format={DATE_FORMAT}
                  className={classes.input}
                  value={dateWithoutFormatter(fundExpirationDate)}
                  data-cy="billDatesDialogFundExpirationDate"
                  onChange={date =>
                    handleFundDateChange(date, 'expirationDate')
                  }
                  disabled={
                    !checkAuth(roles, INVOICES_FUND_DATE_PERFORM, rules) ||
                    updatingInvoice
                  }
                  minDateMessage="Fecha inválida"
                />
                <DatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  label="Fecha de pago"
                  name="paymentDate"
                  format={DATE_FORMAT}
                  className={classes.input}
                  value={dateWithoutFormatter(fundPaymenDate)}
                  onChange={date => handleFundDateChange(date, 'paymentDate')}
                  data-cy="billDatesDialogFundPaymentDate"
                  disabled={
                    !checkAuth(roles, INVOICES_FUND_DATE_PERFORM, rules) ||
                    updatingInvoice
                  }
                  InputProps={{
                    endAdornment:
                      !shouldShowAdornment() ? null : paymentDate ? (
                        <Tooltip title="Borrar fecha">
                          <IconButton
                            onClick={e =>
                              handleFundDateChange(null, 'paymentDate', e)
                            }
                          >
                            <BackspaceOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        selectedInvoice.paymentDate && (
                          <InfoIcon className={classes.infoDateButton} />
                        )
                      ),
                  }}
                />{' '}
              </>
            )}

            {((selectedInvoice.paymentConfirmed && !paymentConfirmed) ||
              (selectedInvoice.paymentDate && !paymentDate) ||
              (showFundDatesForm &&
                selectedInvoice.paymentDate &&
                !fundPaymenDate)) && (
              <Grid item xs={12} align="center" className={classes.error}>
                <Box fontSize={12} fontWeight="fontWeightBold" sx={{ pb: 2 }}>
                  Al guardar se borrarán las fechas de pago indicadas y los
                  campos quedarán vacios
                </Box>
              </Grid>
            )}

            {updateFacturaErrors && (
              <AlertForm variant="error" message={updateFacturaErrors} />
            )}

            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              isLoading={updatingInvoice}
              disabled={updatingInvoice}
              data-cy="billDatesDialogSaveButton"
            >
              Guardar
            </LoadingButton>
          </MuiPickersUtilsProvider>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

BillDatesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  selectedInvoice: PropTypes.objectOf(Object).isRequired,
  statusOrder: PropTypes.string.isRequired,
  updateInvoiceAttributes: PropTypes.func.isRequired,
};

export default BillDatesDialog;
