import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  AddCircleOutlineOutlined,
  CloudDownloadOutlined,
  Search,
} from '@material-ui/icons';
import { Link } from '../../../../commons/components';
import {
  CustomButton,
  NewSimulationButtonGrid,
  DownloadSimulationButtonGrid,
} from './styles';
import { useDetailsActions } from '../../../hooks';

const DetailsActions = ({
  handleShowSimulationDialog,
  handleDownloadSimulationPdf,
}) => {
  const { riskAnalysisUrl } = useDetailsActions();
  return (
    <Grid item container direction="column" xs={12}>
      <NewSimulationButtonGrid item xs={12}>
        <CustomButton
          variant="contained"
          startIcon={<AddCircleOutlineOutlined />}
          onClick={handleShowSimulationDialog}
        >
          Nueva Simulación
        </CustomButton>
      </NewSimulationButtonGrid>
      <Grid item container xs={12}>
        <DownloadSimulationButtonGrid item>
          <Link
            icon={<CloudDownloadOutlined />}
            handler={handleDownloadSimulationPdf}
          >
            Descargar resumen
          </Link>
        </DownloadSimulationButtonGrid>
        <Grid item>
          <Link icon={<Search />} href={riskAnalysisUrl} target="_blank">
            Análisis de riesgo
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsActions.propTypes = {
  handleShowSimulationDialog: PropTypes.func.isRequired,
  handleDownloadSimulationPdf: PropTypes.func.isRequired,
};

export default DetailsActions;
