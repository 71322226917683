import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

interface Summary {
  [key: string]: {
    [type: string]: {
      [result: string]: string;
    }
  }
}

export const fetchManagementsSummary = 
async (orderInvoices: number[], loading: boolean): Promise<Summary> => {
  try {
    if (!loading){
      const { data } = await axios.post<Summary>('/api/collection/debts/managements/summary/get', {
        debtsIds: orderInvoices
      });
      return data
    }
    return {}
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    }
    throw new Error(UNKNOWN_ERROR);
  }
};
