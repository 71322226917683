// TODO: funds borrar luego de sacar de nominas
/* eslint-disable no-param-reassign */
import {
  BULK_SELECTION_ACTION,
  CLEAR_INVOICE_CART,
  CLEAR_SELECTED_SUBGROUP,
  FETCH_FUND_PAYER_DETAIL_INFO_FAILURE,
  FETCH_FUND_PAYER_DETAIL_INFO_START,
  FETCH_FUND_PAYER_DETAIL_INFO_SUCCESS,
  FETCH_FUND_PAYER_DETAIL_INVOICES_FAILURE,
  FETCH_FUND_PAYER_DETAIL_INVOICES_START,
  FETCH_FUND_PAYER_DETAIL_INVOICES_SUCCESS,
  FETCH_FUND_PAYERS_FAILURE,
  FETCH_FUND_PAYERS_START,
  FETCH_FUND_PAYERS_SUCCESS,
  FETCH_FUNDS_LIST_FAILURE,
  FETCH_FUNDS_LIST_START,
  FETCH_FUNDS_LIST_SUCCESS,
  FETCH_INVOICE_INFO_START,
  FETCH_INVOICE_INFO_SUCCESS,
  FETCH_PAYER_DETAIL_START,
  HANDLE_SHOW_FUND_MANAGE_INVOICES,
  HANDLE_SHOW_FUND_SHOPPING_CART,
  REMOVE_RECEIVER_IN_CART,
  REMOVE_SELECTED_INVOICE,
  REMOVE_SELECTED_SUBGROUP,
  RESET_SEND_PAYROLL,
  SEND_PAYROLL_FAILURE,
  SEND_PAYROLL_START,
  SEND_PAYROLL_SUCCESS,
  SET_SELECTED_FUND,
  SET_SIMULATION_DATA,
  SIMULATION_ERROR,
  TOGGLE_DEBTOR_DETAIL,
  TOGGLE_INVOICE_ADVANCE_PERCENTAGE_CHANGE,
  TOGGLE_INVOICE_DETAIL,
  TOGGLE_INVOICE_ISSUER_DEBT_CHANGE,
  TOGGLE_INVOICE_RATE_CHANGE,
  TOGGLE_MODAL_CONFIRM_BULK_ACTION,
  TOGGLE_MODAL_UPDATE_TERM,
  UPDATE_BULK_SELECTION,
  UPDATE_FUNDS_ALL_INVOICES_RATE,
  UPDATE_FUNDS_INVOICES_CART,
  UPDATE_INVOICE_ADVANCE_PERCENTAGE,
  UPDATE_INVOICE_FUND_RATE,
  UPDATE_INVOICE_ISSUER_DEBT,
  UPDATE_INVOICE_TERM,
  UPDATE_RATES_FAILURE,
  UPDATE_RECEIVER_DEBT,
  UPDATE_RECEIVER_PAYROLL_RATE,
  UPDATE_RECOMMENDED_RATES,
  UPDATE_EXPIRATION_DATES,
  UPDATE_EXPIRATION_DATES_FAILURE,
  UPDATE_SELECTED_INVOICES,
  UPDATE_SUBGROUP_SELECTION,
  RESET_CLEARING_SUBGROUP,
} from '../actions/types';
import { fixDecimals, getTermInDays, roundToInt } from '../funds/commons/utils';

const initialState = {
  fundPayersAll: [],
  fundPayersSelected: [],
  fundPayers: [],
  fundPayersFormFields: {},
  fetchFundPayersIsLoading: false,
  paginationFundPayersTable: {
    page: 1,
    pageSize: 99999999,
    totalData: 0,
    totalPages: 1,
  },

  fundPayerDetailInfo: {},
  fetchFundPayerDetailInfoIsLoading: false,

  fundPayerDetailInvoices: [],
  fetchFundPayerDetailInvoiceIsLoading: false,
  paginationFundPayerInvoicesTable: {
    page: 1,
    pageSize: 20,
    totalData: 0,
    totalPages: 1,
  },

  fundList: [],
  fundSelected: null,
  fundFeatures: {},
  fetchCustomExpirationDatesError: null,
  fetchFundListIsLoading: false,

  invoicesSelected: {},
  countInvoicesSelected: 0,
  countSelectedDebtors: 0,
  totalAmountInvoicesSelected: 0,
  invoicesInCart: [],
  fundsRecommendedRates: null,
  hasCustomRate: {},
  customRate: {},
  simulation: {},
  simulationError: null,
  invoiceRateChange: {},
  invoiceAdvancePercentageChange: {},
  invoiceIssuerDebtChange: {},
  selectionSubGroup: [],
  clearSubGroupRows: false,
  invoiceTermChange: {},

  showFundManageInvoice: false,
  showInvoiceFullInfo: false,
  showDebtorDetail: false,
  showFundCart: false,
  showConfirmBulkDeletionModal: false,

  fetchInvoiceDetailIsLoading: false,
  invoiceDetail: {},

  sendPayrollIsLoading: false,
  payrollWasSent: false,
  sendPayrollError: null,
};

const fetchFundPayersStart = state => {
  return {
    ...state,
    fetchFundPayersIsLoading: true,
  };
};

const fetchFundPayersSuccess = (state, payload) => {
  const isChecked = state.fundPayersSelected.map(
    receiver => receiver.receiverIdentifier
  );

  const updatedPayers = () =>
    payload.payers?.map(receiver => {
      if (isChecked.includes(receiver.receiverIdentifier)) {
        return {
          ...receiver,
          tableData: {
            checked: true,
          },
        };
      }
      return receiver;
    });

  const hasFilters = !!Object.keys(payload.filter).length;

  return {
    ...state,
    fundPayersFormFields: payload.filter,
    fundPayers: isChecked.length ? updatedPayers() : payload.payers,
    fundPayersAll:
      payload.isSingle || hasFilters ? state.fundPayersAll : payload.payers,
    fetchFundPayersIsLoading: false,
  };
};

const fetchFundPayersFailure = state => {
  return {
    ...state,
    fetchFundPayersIsLoading: false,
  };
};

const fetchFundPayerDetailInfoStart = state => {
  return {
    ...state,
    fetchFundPayerDetailInfoIsLoading: true,
  };
};

const fetchFundPayerDetailInfoSuccess = (state, payload) => {
  const { fundPayerDetailInfo: oldInfo, invoicesSelected } = state;
  const keepChecks = invoicesSelected[payload.payerIdentifier]?.map(
    invoice => invoice.id
  );
  let updatedInvoices = {};
  if (keepChecks) {
    updatedInvoices = {
      payerIdentifier: payload.payerIdentifier,
      invoices: payload.invoices?.map(invoice => {
        if (keepChecks?.includes(invoice.id)) {
          return {
            ...invoice,
            tableData: {
              checked: true,
            },
          };
        }
        return invoice;
      }),
    };
  } else {
    updatedInvoices = payload;
  }

  return {
    ...state,
    fundPayerDetailInfo: {
      ...oldInfo,
      ...updatedInvoices,
    },
    fetchFundPayerDetailInfoIsLoading: false,
  };
};

const fetchFundPayerDetailInfoFailure = state => {
  return {
    ...state,
    fetchFundPayerDetailInfoIsLoading: false,
  };
};

const fetchFundPayerDetailInvoicesStart = state => {
  return {
    ...state,
    fetchFundPayerDetailInvoiceIsLoading: true,
  };
};

const fetchFundPayerDetailInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    fundPayerDetailInvoices: payload?.invoices,
    fetchFundPayerDetailInvoiceIsLoading: false,
  };
};

const fetchFundPayerDetailInvoicesFailure = state => {
  return {
    ...state,
    fetchFundPayerDetailInvoiceIsLoading: false,
  };
};

const fetchFundsListStart = state => {
  return {
    ...state,
    fetchFundListIsLoading: true,
  };
};

const fetchFundsListSuccess = (state, payload) => {
  const fundFeatures = {};

  payload.forEach(fund => {
    fundFeatures[fund.id] = fund.EntityFeatures.map(
      entity => entity.Feature.name
    ).flatMap(fundFeature => fundFeature);
  });

  return {
    ...state,
    fetchFundListIsLoading: false,
    fundList: payload,
    fundFeatures,
  };
};

const fetchFundsListFailure = state => {
  return {
    ...state,
    fetchFundListIsLoading: false,
  };
};

const isAddingInvoices = (receiver, invoices) => {
  if (!receiver) return true;
  if (invoices.length === 0) return false;

  return receiver.length < invoices.length;
};

const handleOpportunitiesSelection = (
  currentReceiver = [],
  invoices = [],
  newInvoices = [],
  identifier
) => {
  if (
    (isAddingInvoices(currentReceiver, invoices) && invoices.length) ||
    currentReceiver.length === invoices.length
  ) {
    // Opportunities + adding
    // eslint-disable-next-line no-param-reassign
    newInvoices[identifier] = invoices;
  } else if (!isAddingInvoices(currentReceiver, invoices) && !invoices.length) {
    // Opportunities + removing
    // eslint-disable-next-line no-param-reassign
    delete newInvoices[identifier];
  }
};

const handleReceiverInvoicesSelection = (
  currentReceiver,
  invoices,
  newInvoices,
  identifier,
  currentId,
  receiverDetails
) => {
  const addFilteredInvoices = inputInvoices => {
    const filteredInvoices = inputInvoices.filter(
      invoice => !newInvoices[identifier].some(i => i.id === invoice.id)
    );

    newInvoices[identifier] = [
      ...[...newInvoices[identifier], ...filteredInvoices]
        .reduce((map, obj) => map.set(obj.id, obj), new Map())
        .values(),
    ];
  };

  if (isAddingInvoices(currentReceiver, invoices) && currentId) {
    // adding single invoice
    if (newInvoices[identifier]) {
      addFilteredInvoices(invoices);
    } else {
      newInvoices[identifier] = invoices;
    }
  } else if (!isAddingInvoices(currentReceiver, invoices) && currentId) {
    if (currentReceiver.map(invoice => invoice.id).includes(currentId)) {
      newInvoices[identifier] = newInvoices[identifier].filter(
        invoice => invoice.id !== currentId
      );
    } else {
      addFilteredInvoices(invoices);
    }
  } else if (!currentId) {
    if (!newInvoices[identifier] || !newInvoices[identifier].length) {
      newInvoices[identifier] = invoices;
    } else if (receiverDetails.invoices?.length === currentReceiver?.length) {
      if (invoices.length) {
        addFilteredInvoices(invoices);
      } else {
        delete newInvoices[identifier];
      }
    } else if (receiverDetails?.payerIdentifier === identifier) {
      // This could be a case of adding all using general checkbox
      if (invoices.length && currentReceiver.length !== invoices.length) {
        addFilteredInvoices(invoices);
      } else {
        const idsToRemove = receiverDetails.invoices.map(invoice => invoice.id);
        newInvoices[identifier] = newInvoices[identifier].filter(
          invoice => !idsToRemove.includes(invoice.id)
        );
      }
    } else {
      delete newInvoices[identifier];
    }
  }
};

const totalAmount = invoicesDictionary => {
  return Object.values(invoicesDictionary)
    .flatMap(a => a)
    .reduce((a, b) => a + b.amount, 0);
};

const countInvoices = invoicesDictionary =>
  Object.values(invoicesDictionary).flatMap(a => a).length;

const updateSelectedInvoices = (
  state,
  { receiverIdentifier, invoices, currentId = null, isReceiver = false }
) => {
  const newInvoices = { ...state.invoicesSelected };
  let { fundPayerDetailInfo, fundPayersFormFields } = state;
  const currentReceiver = newInvoices[receiverIdentifier];
  if (!receiverIdentifier) {
    return state;
  }

  if (isReceiver) {
    handleOpportunitiesSelection(
      currentReceiver,
      invoices,
      newInvoices,
      receiverIdentifier
    );
  } else {
    fundPayersFormFields = {}; // does not affect on selection so it is not saved
    handleReceiverInvoicesSelection(
      currentReceiver,
      invoices,
      newInvoices,
      receiverIdentifier,
      currentId,
      fundPayerDetailInfo
    );
  }

  Object.keys(newInvoices).forEach(key => {
    if (key === 'undefined') {
      delete newInvoices[undefined];
    }
  });
  const countInvoicesSelected = countInvoices(newInvoices);

  if (countInvoicesSelected === 0) {
    return {
      ...state,
      invoicesSelected: newInvoices,
      totalAmountInvoicesSelected: totalAmount(newInvoices),
      countInvoicesSelected,
      countSelectedDebtors: Object.keys(newInvoices).length,
      simulation: {},
      customRate: {},
      hasCustomRate: {},
      fundSelected: null,
      fundPayers: state.fundPayers.map(receiver => {
        return {
          ...receiver,
          tableData: {
            ...receiver.tableData,
            checked: false,
          },
        };
      }),
      fundPayersSelected: [],
    };
  }

  const payers = state.fundPayersAll.length
    ? state.fundPayersAll
    : state.fundPayers;

  const fundPayersSelected = payers
    .filter(f => f.receiverIdentifier === receiverIdentifier && invoices.length)
    // NOTE: here is saved the payer with the filters applied when it was selected to then recover the invoices
    .map(payer => {
      return { ...payer, fundPayersFormFields };
    });

  if (newInvoices[receiverIdentifier]) {
    fundPayerDetailInfo = fundPayerDetailInfo?.invoices?.map(i => {
      const invoice = newInvoices[receiverIdentifier].find(
        newInvoice => i.id === newInvoice.id
      );
      if (!invoice) {
        delete i.tableData?.checked;
      }
      return i;
    });
  } else {
    fundPayerDetailInfo = fundPayerDetailInfo?.invoices?.map(i => {
      delete i.tableData?.checked;
      return i;
    });
  }
  const updateFundPayersCheck = identifier => {
    return state.fundPayers.map(receiver => {
      if (identifier === receiver.receiverIdentifier) {
        return {
          ...receiver,
          tableData: {
            ...receiver.tableData,
            checked: !!invoices.length,
          },
        };
      }
      return receiver;
    });
  };
  const updateReceiversSelected = () => {
    const cleanArray = [
      ...[...state.fundPayersSelected, ...fundPayersSelected]
        .reduce((map, obj) => map.set(obj.receiverIdentifier, obj), new Map())
        .values(),
    ];
    if (invoices.length) {
      return cleanArray;
    }
    return cleanArray.filter(r => r.receiverIdentifier !== receiverIdentifier);
  };

  return {
    ...state,
    invoicesSelected: newInvoices,
    totalAmountInvoicesSelected: totalAmount(newInvoices),
    countInvoicesSelected,
    fundPayers: updateFundPayersCheck(receiverIdentifier),
    countSelectedDebtors: Object.keys(newInvoices).length,
    fundPayersSelected: updateReceiversSelected(),
  };
};

const setSelectedFund = (state, payload) => {
  return {
    ...state,
    fundSelected: payload,
  };
};

const removeSelectedInvoice = state => {
  return { ...state };
};

const handleShowFundManageInvoices = (state, payload) => {
  return {
    ...state,
    showFundManageInvoice: payload.isOpen,
    fundPayerDetailInfo: {},
  };
};

const handleShowFundCart = (state, payload) => {
  return {
    ...state,
    showFundCart: payload.isOpen,
  };
};

const sendPayrollStart = state => {
  return {
    ...state,
    sendPayrollIsLoading: true,
    payrollWasSent: false,
    sendPayrollError: null,
  };
};

const sendPayrollSuccess = state => {
  return {
    ...state,
    sendPayrollIsLoading: false,
    payrollWasSent: true,
  };
};

const sendPayrollFailure = (state, payroll) => {
  return {
    ...state,
    sendPayrollIsLoading: false,
    sendPayrollError: payroll.errorCode,
  };
};

const resetSendPayroll = state => {
  const { invoicesSelected } = state;

  // clean rate after send payroll
  Object.keys(invoicesSelected).forEach(receiver => {
    invoicesSelected[receiver] = invoicesSelected[receiver]?.map(invoice => {
      // eslint-disable-next-line no-param-reassign
      delete invoice.rate;
      return invoice;
    });
  });

  return {
    ...state,
    sendPayrollIsLoading: false,
    payrollWasSent: false,
    sendPayrollError: null,
    simulation: {},
    customRate: {},
    hasCustomRate: {},
    fundSelected: null,
    invoicesSelected,
  };
};

const toggleInvoiceDetail = (state, payload) => {
  return {
    ...state,
    showInvoiceFullInfo: payload.isOpen,
  };
};

const toggleDebtorDetail = (state, payload) => {
  return {
    ...state,
    showDebtorDetail: payload.isOpen,
  };
};

const fetchInvoiceStart = state => {
  return {
    ...state,
    invoiceDetail: {},
    fetchInvoiceDetailIsLoading: true,
  };
};

const fetchInvoiceSuccess = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDetailIsLoading: false,
    invoiceDetail: payload,
  };
};

const fetchPayerDetailStart = state => {
  return {
    ...state,
    fundPayerDetailInfo: {},
  };
};

const DEFAULT_ADVANCE_PERCENTAGE = 100;

const calculateAverageTerm = invoices => {
  return invoices.every(a => a.expirationDate)
    ? (
        invoices
          .map(i => Number(getTermInDays(i.expirationDate)))
          .reduce((a, b) => a + b, 0) / invoices.length
      ).toFixed(0)
    : 0;
};

const updateInvoicesCart = state => {
  const {
    invoicesSelected,
    invoicesInCart,
    fundPayersSelected,
    hasCustomRate,
    customRate,
  } = state;
  const newInvoicesInCart = Object.keys(invoicesSelected).map(receiver => {
    const updateRate = identifier => {
      if (!hasCustomRate[identifier]) {
        return '-';
      }
      return customRate[identifier];
    };

    const receiverInvoices = invoicesSelected[receiver]?.map(invoice => {
      // eslint-disable-next-line no-param-reassign
      delete invoice?.tableData?.checked;
      return {
        ...invoice,
        advancePercentage:
          invoice.advancePercentage ?? DEFAULT_ADVANCE_PERCENTAGE,
        customRate,
      };
    });

    if (!receiverInvoices.length) {
      return {};
    }

    const receiverSelected = fundPayersSelected.find(
      f => f.receiverIdentifier === receiver
    );

    return {
      receiverName: receiverSelected?.name,
      receiverIdentifier: receiverSelected?.receiverIdentifier,
      receiverDebt: receiverSelected?.debtServiceAmount,
      issuers: receiverInvoices.every(i => i.folio)
        ? Array.from(new Set(receiverInvoices.map(i => i.Issuer.identifier)))
            .length
        : 0,
      invoicesNum: Array.from(
        new Set(receiverInvoices.map(invoice => invoice.id))
      ).length,
      amount: receiverInvoices.reduce((a, b) => a + b.amount, 0),
      averageTerm: Number.isNaN(calculateAverageTerm(receiverInvoices))
        ? 0
        : calculateAverageTerm(receiverInvoices),
      averageScore: null,
      rate: updateRate(receiverSelected?.receiverIdentifier),
      invoices: receiverInvoices,
      hasInvoicesWithoutPdf: !receiverInvoices.every(
        i => i?.InvoiceAttribute?.hasPdf || i?.Issuer?.hasPfx
      ),
    };
  });
  const cart = newInvoicesInCart.filter(i => i?.invoices?.length);

  const updateChecks = cartData => {
    if (invoicesInCart.length) {
      const selectedIds = invoicesInCart.map(
        ({ receiverIdentifier }) => receiverIdentifier
      );
      return cartData.map(receiver => {
        if (selectedIds.includes(receiver.receiverIdentifier)) {
          const { tableData: foundTableData, invoices: foundInvoices } =
            invoicesInCart.find(
              r => r.receiverIdentifier === receiver.receiverIdentifier
            );
          return {
            ...receiver,
            tableData: {
              ...receiver.tableData,
              checked: foundTableData?.checked,
              indeterminate: foundTableData?.indeterminate,
            },
            invoices: receiver.invoices.map(invoice => {
              const foundInvoice = foundInvoices.find(i => i.id === invoice.id);
              if (foundInvoice) {
                return {
                  ...invoice,
                  tableData: {
                    ...invoice.tableData,
                    checked: foundInvoice.tableData?.checked,
                  },
                };
              }
              return invoice;
            }),
          };
        }
        return receiver;
      });
    }
    return cartData;
  };

  return {
    ...state,
    invoicesInCart: updateChecks(cart),
  };
};

const updateRecommendedRates = (state, payload) => {
  const { invoicesInCart, invoicesSelected, hasCustomRate, customRate } = state;
  const customRateCopy = { ...customRate };
  const hasCustomRateCopy = { ...hasCustomRate };

  const updateRates = () => {
    return invoicesInCart.map(invoice => {
      const { receiverIdentifier: identifier } = invoice;
      const recommendedRate = payload.find(
        debtor => debtor.receiverIdentifier === invoice.receiverIdentifier
      ).rate;
      customRateCopy[identifier] = recommendedRate;
      hasCustomRateCopy[identifier] = true;
      return {
        ...invoice,
        rate: recommendedRate,
        invoices: invoice.invoices.map(i => ({ ...i, rate: recommendedRate })),
      };
    });
  };

  const updateInvoicesSelectedRate = () => {
    const copy = { ...invoicesSelected };
    Object.keys(copy).forEach(receiver => {
      copy[receiver] = invoicesSelected[receiver].map(invoice => {
        const recommendedRate = payload.find(
          debtor => debtor.receiverIdentifier === receiver
        ).rate;
        return {
          ...invoice,
          rate: recommendedRate,
        };
      });
    });
    return copy;
  };

  return {
    ...state,
    invoicesInCart: updateRates(),
    customRate: customRateCopy,
    hasCustomRate: hasCustomRateCopy,
    invoicesSelected: updateInvoicesSelectedRate(),
  };
};

const updateRecommendedRatesFailure = (state, payload) => {
  return {
    ...state,
    fundsRecommendedRates: payload,
  };
};
const updateExpirationDates = (state, payload) => {
  const { invoicesInCart, invoicesSelected } = state;

  const getExpirationDateById = id =>
    payload.find(payrollInvoice => payrollInvoice.invoiceId === id)
      ?.expirationDate;

  const updatedInvoicesSelected = { ...invoicesSelected };

  Object.keys(updatedInvoicesSelected).forEach(identifier => {
    updatedInvoicesSelected[identifier] = updatedInvoicesSelected[
      identifier
    ].map(invoice => {
      return { ...invoice, expirationDate: getExpirationDateById(invoice.id) };
    });
  });

  const updatedInvoicesInCart = invoicesInCart.map(receiver => {
    const invoices = receiver.invoices.map(cartInvoice => {
      return {
        ...cartInvoice,
        expirationDate: getExpirationDateById(cartInvoice.id),
      };
    });
    return { ...receiver, invoices };
  });
  return {
    ...state,
    invoicesInCart: updatedInvoicesInCart,
    invoicesSelected: updatedInvoicesSelected,
  };
};

const updateExpirationDatesFailure = (state, payload) => {
  return {
    ...state,
    fetchCustomExpirationDatesError: payload,
  };
};

const updateFundsPayrollRate = (state, payload) => {
  const { invoicesSelected, customRate, hasCustomRate } = state;
  const newRate = Number(payload.rate);

  const updatedInvoicesSelected = () => {
    return {
      ...invoicesSelected,
      [payload.identifier]: invoicesSelected[payload.identifier].map(
        invoice => {
          return {
            ...invoice,
            rate: newRate,
          };
        }
      ),
    };
  };

  return {
    ...state,
    invoicesSelected: updatedInvoicesSelected(),
    hasCustomRate: {
      ...hasCustomRate,
      [payload.identifier]: true,
    },
    customRate: {
      ...customRate,
      [payload.identifier]: newRate,
    },
  };
};

const updateReceiverDebt = (state, payload) => {
  const { invoicesInCart, invoicesSelected } = state;
  const { identifier, receiverDebt } = payload;

  const updatedReceiver = invoicesInCart.find(
    i => i.receiverIdentifier === identifier
  );

  updatedReceiver.receiverDebt = !Number.isNaN(receiverDebt) ? receiverDebt : 0;
  const updatedInvoicesSelected = invoices => {
    const invoicesToUpdate = invoices[identifier].map(invoice => {
      return {
        ...invoice,
        Receiver: {
          ...invoice.Receiver,
          debtServiceAmount: receiverDebt,
        },
      };
    });
    return {
      ...invoices,
      [identifier]: invoicesToUpdate,
    };
  };

  return {
    ...state,
    invoicesInCart: [
      ...invoicesInCart.filter(
        invoice => invoice.receiverIdentifier !== identifier
      ),
      updatedReceiver,
    ],
    invoicesSelected: updatedInvoicesSelected(invoicesSelected),
    fundPayersSelected: state.fundPayersSelected.map(f => {
      if (f.receiverIdentifier === identifier) {
        f.debtServiceAmount = receiverDebt;
      }
      return f;
    }),
  };
};

const clearInvoiceCart = state => {
  return {
    ...state,
    invoicesInCart: [],
    fundPayerDetailInfo: {},
    countInvoicesSelected: 0,
    invoicesSelected: {},
    fundPayersSelected: [],
    totalAmountInvoicesSelected: 0,
    countSelectedDebtors: 0,
    fundSelected: null,
  };
};

const removeReceiverInCart = (state, payload) => {
  const removeReceiver = identifier => {
    return state.invoicesInCart.filter(
      receiver => receiver.receiverIdentifier !== identifier
    );
  };

  return {
    ...state,
    invoicesInCart: removeReceiver(payload),
    fundPayers: state.fundPayers.map(receiver => {
      return {
        ...receiver,
        tableData: {},
      };
    }),
    fundPayerDetailInfo: {},
  };
};

const updateReceiverAttributes = (receivers, arrayOfInvoices) => {
  return arrayOfInvoices.map((receiver, id) => {
    const {
      acknowledgementPercentage,
      avgRate: receiverRate,
      avgTerm: averageTerm,
      invoiceNumber: invoicesNum,
      issuersNumber: issuers,
      score: averageScore,
      amount,
      hasInvoicesWithoutPdf,
    } = receivers.find(
      currentReceiver =>
        currentReceiver.identifier === receiver.receiverIdentifier
    );

    let rate = receiverRate ?? receiver.rate;
    if (rate === '-') {
      rate = null;
    }

    return {
      ...receiver,
      id,
      acknowledgementPercentage,
      averageTerm: averageTerm.toFixed() ?? receiver.averageTerm.toFixed(),
      rate: rate ? rate.toFixed(2) : rate,
      invoicesNum: invoicesNum ?? receiver.invoicesNum,
      issuers: issuers ?? receiver.issuers,
      averageScore: averageScore ? averageScore.toFixed() : averageScore,
      amount: amount ?? receiver.amount,
      hasInvoicesWithoutPdf:
        hasInvoicesWithoutPdf ?? receiver.hasInvoicesWithoutPdf,
    };
  });
};

const getSimulationData = (state, payload) => {
  const { invoicesInCart } = state;
  const {
    averageTerm,
    averageRate,
    interest,
    totalInvoiceAmount,
    documentsNumber,
    receivers,
  } = payload;

  return {
    ...state,
    simulation: { ...payload, isLoading: false },
    averageTerm: roundToInt(averageTerm),
    weightedAverageRate: fixDecimals(averageRate),
    fundInterest: interest,
    totalAmountInvoicesSelected: totalInvoiceAmount,
    countInvoicesSelected: documentsNumber,
    invoicesInCart: updateReceiverAttributes(receivers, invoicesInCart),
  };
};

const simulationError = (state, payload) => {
  return {
    ...state,
    simulationError: payload,
  };
};

const toggleInvoiceRateChange = (state, payload) => {
  return {
    ...state,
    invoiceRateChange: payload,
  };
};

const updateInvoiceFundRate = (state, payload) => {
  // payload: { isOpen: boolean; identifier: string; id: number; rate: number; }
  const { invoicesInCart, invoicesSelected } = state;
  const { identifier, id, rate } = payload;
  const updateOneInvoiceRate = () => {
    let updatedInvoices = invoicesInCart.map(receiver => {
      if (receiver.receiverIdentifier === identifier) {
        return {
          ...receiver,
          invoices: receiver.invoices.map(invoice => {
            if (invoice.id === id) {
              return {
                ...invoice,
                rate,
              };
            }
            return invoice;
          }),
        };
      }
      return receiver;
    });

    if (rate) {
      // * Update payer average rate with single invoice change
      updatedInvoices = updatedInvoices.map(receiver => {
        if (receiver.receiverIdentifier === identifier) {
          return {
            ...receiver,
            rate: (
              receiver.invoices.reduce((a, b) => a + b.rate * b.amount, 0) /
              receiver.invoices.reduce((a, b) => a + b.amount, 0)
            ).toFixed(2),
          };
        }
        return receiver;
      });
    }

    return updatedInvoices;
  };
  const updateOneInvoiceSelectedRate = () => {
    const copy = { ...invoicesSelected };
    copy[identifier].find(invoice => invoice.id === id).rate = rate;
    return copy;
  };

  return {
    ...state,
    invoicesInCart: updateOneInvoiceRate(),
    invoicesSelected: updateOneInvoiceSelectedRate(),
  };
};

const toggleInvoiceAdvancePercentageChange = (state, payload) => {
  return {
    ...state,
    invoiceAdvancePercentageChange: payload,
  };
};

const updateInvoiceAdvancePercentage = (state, payload) => {
  const { invoicesInCart, invoicesSelected } = state;
  const { identifier, id, advancePercentage } = payload;
  const updateOneInvoiceAdvance = () => {
    return invoicesInCart.map(receiver => {
      if (receiver.receiverIdentifier === identifier) {
        return {
          ...receiver,
          invoices: receiver.invoices.map(invoice => {
            if (invoice.id === id) {
              return {
                ...invoice,
                advancePercentage,
              };
            }
            return invoice;
          }),
        };
      }
      return receiver;
    });
  };
  const updateOneInvoiceSelectedAdvance = () => {
    const copy = { ...invoicesSelected };
    copy[identifier].find(invoice => invoice.id === id).advancePercentage =
      advancePercentage;
    return {
      ...copy,
    };
  };

  return {
    ...state,
    invoicesInCart: updateOneInvoiceAdvance(),
    invoicesSelected: updateOneInvoiceSelectedAdvance(),
    invoiceAdvancePercentageChange: {
      isOpen: false,
    },
  };
};

const toggleInvoiceIssuerDebtChange = (state, payload) => {
  return {
    ...state,
    invoiceIssuerDebtChange: payload,
  };
};
const toggleInvoiceTermChange = (state, payload) => {
  return {
    ...state,
    invoiceTermChange: payload,
  };
};

const updateInvoiceIssuerDebt = (state, payload) => {
  const { invoicesInCart, invoicesSelected } = state;
  const { debt, issuerIdentifier } = payload;

  const replaceDebt = invoice => {
    return {
      ...invoice,
      Issuer: {
        ...invoice.Issuer,
        debtServiceAmount: debt,
      },
    };
  };

  const updateOneParsedIssuedDebt = () => {
    return invoicesInCart.map(receiver => {
      return {
        ...receiver,
        invoices: receiver.invoices.map(invoice => {
          if (invoice.Issuer.identifier === issuerIdentifier) {
            return replaceDebt(invoice);
          }
          return invoice;
        }),
      };
    });
  };

  const updateInvoicesSelectedDebt = () => {
    const copy = { ...invoicesSelected };

    Object.keys(copy).forEach(receiver => {
      copy[receiver] = invoicesSelected[receiver].map(invoice => {
        if (invoice.Issuer.identifier === issuerIdentifier) {
          return replaceDebt(invoice);
        }
        return invoice;
      });
    });
    return copy;
  };

  return {
    ...state,
    invoicesInCart: updateOneParsedIssuedDebt(),
    invoicesSelected: updateInvoicesSelectedDebt(),
  };
};

const bulkSelectionAction = (state, payload) => {
  const { invoicesInCart, selectionSubGroup } = state;

  const indeterminateReceivers = invoicesInCart.filter(
    receiver => receiver.tableData?.indeterminate
  );
  const hasIndeterminateSelected = !!indeterminateReceivers.length;
  const selectedIdentifiers = payload.selection.map(
    ({ receiverIdentifier }) => receiverIdentifier
  );
  const isInSelectionAlready = selectionSubGroup.find(
    ({ receiverIdentifier }) => selectedIdentifiers.includes(receiverIdentifier)
  );
  const selectAll = selection => {
    return selection.map(receiver => {
      return {
        ...receiver,
        tableData: {
          ...receiver.tableData,
          checked: true,
          indeterminate: false,
        },
        invoices: receiver.invoices.map(invoice => {
          return {
            ...invoice,
            tableData: {
              ...invoice.tableData,
              checked: true,
            },
          };
        }),
      };
    });
  };
  const resetList = receiver => ({
    ...receiver,
    tableData: { ...receiver.tableData, checked: false, indeterminate: false },
    invoices: receiver.invoices.map(invoice => ({
      ...invoice,
      tableData: {
        ...invoice.tableData,
        checked: false,
      },
    })),
  });
  const setChecked = receiver => ({
    ...receiver,
    invoices: receiver.invoices.map(invoice => {
      return {
        ...invoice,
        tableData: { ...invoice.tableData, checked: true },
      };
    }),
    tableData: {
      ...receiver.tableData,
      checked: true,
      indeterminate: false,
    },
  });
  const setIndeterminates = receiver => ({
    ...receiver,
    tableData: {
      ...receiver.tableData,
      checked: false,
      indeterminate: true,
    },
  });

  const updatedInvoicesInCart = selection => {
    if (hasIndeterminateSelected) {
      const indeterminateIdentifiers = indeterminateReceivers.map(
        ({ receiverIdentifier }) => receiverIdentifier
      );
      const selected = selection.map(
        ({ receiverIdentifier }) => receiverIdentifier
      );
      return invoicesInCart
        .map(resetList)
        .map(receiver => {
          if (selected.includes(receiver.receiverIdentifier)) {
            return setChecked(receiver);
          }
          return receiver;
        })
        .map(receiver => {
          if (indeterminateIdentifiers.includes(receiver.receiverIdentifier)) {
            const indeterminateReceiver = setIndeterminates(receiver);
            const chosen = selection.find(
              ({ receiverIdentifier }) =>
                receiverIdentifier === receiver.receiverIdentifier
            )?.invoices;
            indeterminateReceiver.invoices = indeterminateReceivers.find(
              ({ receiverIdentifier }) =>
                receiverIdentifier === receiver.receiverIdentifier
            ).invoices;
            if (chosen?.length === indeterminateReceiver.invoices.length) {
              indeterminateReceiver.tableData = {
                ...indeterminateReceiver.tableData,
                checked: true,
                indeterminate: false,
              };
              indeterminateReceiver.invoices =
                indeterminateReceiver.invoices.map(invoice => ({
                  ...invoice,
                  tableData: { ...invoice.tableData, checked: true },
                }));
            }
            return indeterminateReceiver;
          }
          return receiver;
        });
    }
    if (
      selectionSubGroup.length &&
      payload.rowData &&
      selectionSubGroup.filter(
        ({ receiverIdentifier }) =>
          receiverIdentifier === payload.rowData.receiverIdentifier
      )?.length
    ) {
      const removables = invoicesInCart
        .filter(
          re =>
            !selection
              .map(r => r.receiverIdentifier)
              .includes(re.receiverIdentifier)
        )
        .map(({ receiverIdentifier }) => receiverIdentifier);
      return invoicesInCart.map(receiver => {
        if (removables.includes(receiver.receiverIdentifier)) {
          return resetList(receiver);
        }
        return receiver;
      });
    }

    return invoicesInCart.map(receiver => {
      if (
        selection
          .map(({ receiverIdentifier }) => receiverIdentifier)
          .includes(receiver.receiverIdentifier)
      ) {
        return selectAll(
          selection.filter(
            selectedReceiver =>
              selectedReceiver.receiverIdentifier ===
              receiver.receiverIdentifier
          )
        )[0];
      }
      return receiver;
    });
  };

  const updatedSubSelection = selection => {
    const updatedIndeterminates = indeterminateReceivers.map(receiver => ({
      ...receiver,
      invoices: selectionSubGroup.find(
        ({ receiverIdentifier }) =>
          receiverIdentifier === receiver.receiverIdentifier
      )?.invoices,
    }));
    if (hasIndeterminateSelected) {
      if (isInSelectionAlready) {
        // Replace indeterminate with fully checked receiver
        if (
          selection.some(r =>
            indeterminateReceivers
              .map(({ receiverIdentifier }) => receiverIdentifier)
              .includes(r.receiverIdentifier)
          )
        ) {
          if (payload.rowData) {
            return [
              ...selection,
              ...selectionSubGroup.filter(
                receiver =>
                  !selection
                    .map(r => r.receiverIdentifier)
                    .includes(receiver.receiverIdentifier)
              ),
            ];
          }
          return selection;
        }
        // Remove single receiver
        if (
          selectionSubGroup.some(
            r => r.receiverIdentifier === payload.rowData.receiverIdentifier
          )
        ) {
          const toBeDeleted = selectionSubGroup.find(
            r => r.receiverIdentifier === payload.rowData.receiverIdentifier
          )?.receiverIdentifier;
          return selectionSubGroup.filter(
            ({ receiverIdentifier }) => receiverIdentifier !== toBeDeleted
          );
        }
        return [...selection, ...updatedIndeterminates];
      }
      return [...selection, ...updatedIndeterminates];
    }
    return selection;
  };

  return {
    ...state,
    selectionSubGroup: updatedSubSelection(payload.selection),
    clearSubGroupRows: false,
    invoicesInCart: updatedInvoicesInCart(payload.selection),
  };
};
const removeSelectedReceivers = state => {
  const {
    selectionSubGroup,
    invoicesInCart,
    invoicesSelected,
    fundPayers,
    fundPayersSelected,
  } = state;
  const idsToRemove = selectionSubGroup
    .map(receiver => receiver.invoices)
    .flatMap(array => array)
    .map(invoice => invoice.id);

  const clearInvoices = invoicesArray => {
    return invoicesArray.map(receiver => {
      const invoices = receiver.invoices.filter(
        invoice => !idsToRemove.includes(invoice.id)
      );
      return {
        ...receiver,
        invoices,
        invoicesNum: invoices.length,
        amount: invoices.reduce((a, b) => a + b.amount, 0),
        issuers: Array.from(new Set(invoices.map(i => i.Issuer.identifier)))
          .length,
        averageScore: null,
        averageTerm: calculateAverageTerm(invoices),
        hasInvoicesWithoutPdf: !invoices.every(
          i => i?.InvoiceAttribute?.hasPdf
        ),
      };
    });
  };
  const clearEmptyReceivers = selectionArray => {
    return selectionArray
      .map(receiver => {
        if (receiver.invoices.length) {
          return {
            ...receiver,
            tableData: {
              ...receiver.tableData,
              indeterminate: false,
              checked: false,
            },
          };
        }
        return undefined;
      })
      .filter(Boolean);
  };

  const updatedSelection = () =>
    clearEmptyReceivers(clearInvoices(invoicesInCart));
  const updatedSubGroup = () =>
    clearEmptyReceivers(clearInvoices(selectionSubGroup));
  const updatedInvoicesSelected = () => {
    const copy = { ...invoicesSelected };
    Object.keys(copy).forEach(receiver => {
      copy[receiver] = copy[receiver]
        .map(invoice => {
          if (!idsToRemove.includes(invoice.id)) {
            return invoice;
          }
          return undefined;
        })
        .filter(Boolean);
    });
    return copy;
  };
  const updatedReceivers = () => {
    const stillSelectedIds = updatedSelection().map(r => r.receiverIdentifier);
    return fundPayers.map(receiver => {
      return {
        ...receiver,
        tableData: {
          ...receiver.tableData,
          checked: !!stillSelectedIds.includes(receiver.receiverIdentifier),
        },
      };
    });
  };
  const updatedSelectedReceivers = () => {
    const stillSelectedIds = updatedSelection().map(r => r.receiverIdentifier);
    return fundPayersSelected.filter(receiver =>
      stillSelectedIds.includes(receiver.receiverIdentifier)
    );
  };

  return {
    ...state,
    invoicesInCart: updatedSelection(),
    selectionSubGroup: updatedSubGroup(),
    invoicesSelected: updatedInvoicesSelected(),
    fundPayers: updatedReceivers(),
    fundPayersSelected: updatedSelectedReceivers(),
    totalAmountInvoicesSelected: totalAmount(updatedInvoicesSelected()),
    countInvoicesSelected: countInvoices(updatedInvoicesSelected()),
    countSelectedDebtors: Object.keys(updatedInvoicesSelected()).length,
  };
};

const clearSubGroup = (state, payload) => {
  const { invoicesInCart, selectionSubGroup } = state;

  const clearChecks = arrayOfReceivers => {
    const emptyTableData = {
      checked: false,
      indeterminate: false,
    };

    const uncheckedInvoices = invoicesList =>
      invoicesList.map(invoice => {
        return {
          ...invoice,
          tableData: emptyTableData,
        };
      });

    if (payload) {
      return arrayOfReceivers.map(receiver => {
        if (receiver.receiverIdentifier === payload.receiverIdentifier) {
          return {
            ...receiver,
            tableData: emptyTableData,
            invoices: uncheckedInvoices(receiver.invoices),
          };
        }
        return receiver;
      });
    }

    return arrayOfReceivers.map(receiver => {
      return {
        ...receiver,
        tableData: emptyTableData,
        invoices: uncheckedInvoices(receiver.invoices),
      };
    });
  };

  const filteredSelection = receiver => {
    if (!selectionSubGroup) {
      return [];
    }
    return selectionSubGroup.filter(
      ({ receiverIdentifier }) =>
        receiverIdentifier !== receiver.receiverIdentifier
    );
  };

  return {
    ...state,
    selectionSubGroup: payload ? filteredSelection(payload) : [],
    invoicesInCart: clearChecks(invoicesInCart),
    clearSubGroupRows: false,
  };
};

const resetClearingSubgroup = state => {
  return {
    ...state,
    clearSubGroupRows: false,
  };
};

const updateSubGroupInvoices = (state, payload) => {
  const { selectionSubGroup, invoicesInCart } = state;
  const { invoices, receiverIdentifier } = payload;

  if (!invoices.length) {
    return {
      ...state,
      selectionSubGroup: selectionSubGroup.filter(
        ({ receiverIdentifier: chosenIdentifier }) =>
          chosenIdentifier !== receiverIdentifier
      ),
      invoicesInCart: invoicesInCart.map(receiver => {
        if (receiver.receiverIdentifier === receiverIdentifier) {
          return {
            ...receiver,
            tableData: {
              indeterminate: false,
              checked: false,
            },
            invoices: receiver.invoices.map(invoice => {
              return {
                ...invoice,
                tableData: {
                  ...invoice.tableData,
                  checked: false,
                },
              };
            }),
          };
        }
        return receiver;
      }),
    };
  }

  const updatedSubGroup = () => {
    const hasReceiverIn = identifier => {
      return !!selectionSubGroup.find(
        receiver => receiver.receiverIdentifier === identifier
      );
    };

    // add receiver and fill it
    if (!hasReceiverIn(receiverIdentifier) && invoices.length) {
      const [{ Receiver: receiver }] = invoices;
      return [
        ...selectionSubGroup,
        {
          receiverName: receiver.name,
          receiverIdentifier,
          amount: invoices.reduce((a, b) => a + b.amount, 0),
          receiverDebt: receiver.debtServiceAmount,
          issuers: Array.from(
            new Set(invoices.map(invoice => invoice.Issuer.identifier))
          ),
          invoicesNum: invoices.length,
          hasInvoicesWithoutPdf: !invoices.every(
            i => i?.InvoiceAttribute?.hasPdf
          ),
          averageTerm:
            invoices
              .map(invoice => getTermInDays(invoice.expirationDate))
              .reduce((a, b) => a + b, 0) / invoices.length,
          averageScore: null,
          rate: '-',
          invoices,
        },
      ];
    }
    return selectionSubGroup.map(receiver => {
      if (receiver.receiverIdentifier === receiverIdentifier) {
        const selectedInSelection = invoicesInCart.find(
          chosenReceiver =>
            chosenReceiver.receiverIdentifier === receiverIdentifier
        ).invoices.length;
        const isChecked = selectedInSelection === invoices.length;
        // if receiver is already in subgroup
        return {
          ...receiver,
          invoices,
          tableData: {
            ...receiver.tableData,
            checked: isChecked,
            indeterminate: !!invoices.length && !isChecked,
          },
        };
      }
      return receiver;
    });
  };

  const updatedCart = () => {
    const checkedInvoices = payload.invoices
      .map(invoice => {
        if (invoice?.tableData) {
          return {
            id: invoice.id,
            checked: !!invoice.tableData?.checked,
          };
        }
        return undefined;
      })
      .filter(Boolean);

    const checkStatus = (status, name) => {
      const subSelected = updatedSubGroup().find(
        receiver => receiver.receiverName === name
      );
      if (!subSelected) {
        return false;
      }
      const totalInvoices = invoicesInCart.find(
        receiver => receiver.receiverName === subSelected.receiverName
      )?.invoices.length;
      const subSelectedChecked = subSelected?.invoices?.map(
        invoice => invoice.tableData?.checked
      ).length;
      if (status === 'checked') {
        return (
          subSelected.tableData?.checked || totalInvoices === subSelectedChecked
        );
      }
      // status indeterminate
      return typeof subSelected.tableData?.checked !== 'undefined'
        ? !subSelected.tableData?.checked
        : subSelectedChecked && totalInvoices !== subSelectedChecked;
    };

    const newList = invoicesInCart.map(receiver => {
      if (receiver.receiverIdentifier === payload.receiverIdentifier) {
        return {
          ...receiver,
          invoices: receiver.invoices.map(invoice => {
            return {
              ...invoice,
              tableData: {
                ...invoice.tableData,
                checked: !!checkedInvoices.find(
                  selectedInvoice => selectedInvoice.id === invoice.id
                )?.checked,
              },
            };
          }),
        };
      }
      return receiver;
    });

    return newList.map(receiver => {
      const { receiverName: name } = receiver;
      return {
        ...receiver,
        tableData: {
          ...receiver.tableData,
          checked: checkStatus('checked', name),
          indeterminate: checkStatus('indeterminate', name),
        },
      };
    });
  };

  return {
    ...state,
    selectionSubGroup: updatedSubGroup(),
    invoicesInCart: updatedCart(),
  };
};

const updateBulkSelection = (state, payload) => {
  const { days, rate, debt, advancePercentage } = payload;
  const { selectionSubGroup, invoicesInCart, invoicesSelected } = state;
  const issuersToEdit = [];
  const payersToEdit = [];
  const idsToEdit = selectionSubGroup
    .map(receiver =>
      receiver.invoices.map(({ id, Issuer, Receiver }) => {
        payersToEdit.push(Receiver.identifier);
        issuersToEdit.push(Issuer.identifier);
        return id;
      })
    )
    .flatMap(array => array);

  const editModifiedOnly = (value, defaultValue) => {
    return value !== '' ? value : defaultValue;
  };

  const bulkUpdatedSubGroup = () => {
    const averageRate = invoices =>
      (
        invoices.reduce((a, b) => a + b.rate * b.amount, 0) /
        invoices.reduce((a, b) => a + b.amount, 0)
      ).toFixed(2);

    return invoicesInCart.map(receiver => {
      const invoices = receiver.invoices.map(invoice => {
        const issuerIdentifier = invoice.Issuer.identifier;
        if (idsToEdit.includes(invoice.id)) {
          return {
            ...invoice,
            advancePercentage: editModifiedOnly(
              advancePercentage,
              invoice.advancePercentage
            ),
            rate: editModifiedOnly(rate, invoice.rate),
            expirationDate: editModifiedOnly(days, invoice.expirationDate),
            Issuer: {
              ...invoice.Issuer,
              debtServiceAmount: editModifiedOnly(
                debt,
                invoice.Issuer.debtServiceAmount
              ),
            },
          };
        }

        if (issuersToEdit.includes(issuerIdentifier)) {
          return {
            ...invoice,
            Issuer: {
              ...invoice.Issuer,
              debtServiceAmount: editModifiedOnly(
                debt,
                invoice.Issuer.debtServiceAmount
              ),
            },
          };
        }

        return invoice;
      });

      return {
        ...receiver,
        ...(payersToEdit.includes(receiver.receiverIdentifier) && {
          rate: averageRate(invoices),
        }),
        invoices,
      };
    });
  };

  const bulkUpdateInvoicesSelected = () => {
    const copy = { ...invoicesSelected };

    Object.keys(copy).forEach(receiver => {
      copy[receiver] = invoicesSelected[receiver].map(invoice => {
        const issuerIdentifier = invoice.Issuer.identifier;
        if (idsToEdit.includes(invoice.id)) {
          return {
            ...invoice,
            advancePercentage: editModifiedOnly(
              advancePercentage,
              invoice.advancePercentage
            ),
            rate: editModifiedOnly(rate, invoice.rate),
            expirationDate: editModifiedOnly(days, invoice.expirationDate),
            Issuer: {
              ...invoice.Issuer,
              debtServiceAmount: editModifiedOnly(
                debt,
                invoice.Issuer.debtServiceAmount
              ),
            },
          };
        }

        if (issuersToEdit.includes(issuerIdentifier)) {
          return {
            ...invoice,
            Issuer: {
              ...invoice.Issuer,
              debtServiceAmount: editModifiedOnly(
                debt,
                invoice.Issuer.debtServiceAmount
              ),
            },
          };
        }

        return invoice;
      });
    });
    return copy;
  };

  return {
    ...state,
    invoicesInCart: bulkUpdatedSubGroup(),
    invoicesSelected: bulkUpdateInvoicesSelected(),
  };
};

const toggleModalConfirmBulkAction = (state, payload) => {
  return {
    ...state,
    showConfirmBulkDeletionModal: payload,
  };
};

const updateInvoiceTerm = (state, payload) => {
  const { invoicesInCart, invoicesSelected } = state;
  const { expirationDate, issuerIdentifier, id } = payload;

  const replaceTerm = invoice => {
    return {
      ...invoice,
      expirationDate,
    };
  };
  const checkInvoiceValues = invoice => {
    if (invoice.Issuer.identifier === issuerIdentifier && invoice.id === id) {
      return replaceTerm(invoice);
    }
    return invoice;
  };

  const updateOneInvoiceTerm = () => {
    return invoicesInCart.map(receiver => {
      return {
        ...receiver,
        invoices: receiver.invoices.map(checkInvoiceValues),
      };
    });
  };
  const updateOneInvoiceTermSelection = () => {
    const copy = { ...invoicesSelected };
    Object.keys(copy).forEach(receiver => {
      copy[receiver] = invoicesSelected[receiver].map(invoice => {
        if (invoice.id === id) {
          return {
            ...invoice,
            expirationDate,
          };
        }
        return invoice;
      });
    });
    return copy;
  };

  return {
    ...state,
    invoicesInCart: updateOneInvoiceTerm(),
    invoicesSelected: updateOneInvoiceTermSelection(),
  };
};

const updateAllInvoicesRate = (state, payload) => {
  const { invoicesSelected, invoicesInCart, customRate } = state;

  const updateInvoices = invoices =>
    invoices.map(invoice => {
      return {
        ...invoice,
        rate: payload,
      };
    });

  const updateInvoicesInCart = () =>
    invoicesInCart.map(receiver => {
      return {
        ...receiver,
        rate: payload,
        invoices: updateInvoices(receiver.invoices),
      };
    });

  const updateInvoicesSelected = () => {
    const copy = { ...invoicesSelected };
    Object.keys(copy).forEach(
      // eslint-disable-next-line no-return-assign
      receiver => (copy[receiver] = updateInvoices(invoicesSelected[receiver]))
    );
    return copy;
  };
  const updateCustomRates = () => {
    const copy = { ...customRate };
    // eslint-disable-next-line no-return-assign
    Object.keys(copy).forEach(receiver => (copy[receiver] = payload));

    return copy;
  };

  return {
    ...state,
    invoicesSelected: updateInvoicesSelected(),
    invoicesInCart: updateInvoicesInCart(),
    customRate: updateCustomRates(),
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FUND_PAYERS_START:
      return fetchFundPayersStart(state);
    case FETCH_FUND_PAYERS_SUCCESS:
      return fetchFundPayersSuccess(state, payload);
    case FETCH_FUND_PAYERS_FAILURE:
      return fetchFundPayersFailure(state);
    case FETCH_FUND_PAYER_DETAIL_INFO_START:
      return fetchFundPayerDetailInfoStart(state);
    case FETCH_FUND_PAYER_DETAIL_INFO_SUCCESS:
      return fetchFundPayerDetailInfoSuccess(state, payload);
    case FETCH_FUND_PAYER_DETAIL_INFO_FAILURE:
      return fetchFundPayerDetailInfoFailure(state);
    case FETCH_FUND_PAYER_DETAIL_INVOICES_START:
      return fetchFundPayerDetailInvoicesStart(state);
    case FETCH_FUND_PAYER_DETAIL_INVOICES_SUCCESS:
      return fetchFundPayerDetailInvoicesSuccess(state, payload);
    case FETCH_FUND_PAYER_DETAIL_INVOICES_FAILURE:
      return fetchFundPayerDetailInvoicesFailure(state);
    case FETCH_FUNDS_LIST_START:
      return fetchFundsListStart(state);
    case FETCH_FUNDS_LIST_SUCCESS:
      return fetchFundsListSuccess(state, payload);
    case FETCH_FUNDS_LIST_FAILURE:
      return fetchFundsListFailure(state);
    case UPDATE_SELECTED_INVOICES:
      return updateSelectedInvoices(state, payload);
    case REMOVE_SELECTED_INVOICE:
      return removeSelectedInvoice(state);
    case HANDLE_SHOW_FUND_MANAGE_INVOICES:
      return handleShowFundManageInvoices(state, payload);
    case SEND_PAYROLL_START:
      return sendPayrollStart(state);
    case SEND_PAYROLL_SUCCESS:
      return sendPayrollSuccess(state);
    case SEND_PAYROLL_FAILURE:
      return sendPayrollFailure(state, payload);
    case RESET_SEND_PAYROLL:
      return resetSendPayroll(state);
    case TOGGLE_INVOICE_DETAIL:
      return toggleInvoiceDetail(state, payload);
    case TOGGLE_DEBTOR_DETAIL:
      return toggleDebtorDetail(state, payload);
    case FETCH_INVOICE_INFO_START:
      return fetchInvoiceStart(state);
    case FETCH_INVOICE_INFO_SUCCESS:
      return fetchInvoiceSuccess(state, payload);
    case FETCH_PAYER_DETAIL_START:
      return fetchPayerDetailStart(state);
    case SET_SELECTED_FUND:
      return setSelectedFund(state, payload);
    case UPDATE_FUNDS_INVOICES_CART:
      return updateInvoicesCart(state);
    case UPDATE_RECOMMENDED_RATES:
      return updateRecommendedRates(state, payload);
    case UPDATE_RATES_FAILURE:
      return updateRecommendedRatesFailure(state, payload);
    case UPDATE_EXPIRATION_DATES:
      return updateExpirationDates(state, payload);
    case UPDATE_EXPIRATION_DATES_FAILURE:
      return updateExpirationDatesFailure(state, payload);
    case HANDLE_SHOW_FUND_SHOPPING_CART:
      return handleShowFundCart(state, payload);
    case UPDATE_RECEIVER_PAYROLL_RATE:
      return updateFundsPayrollRate(state, payload);
    case UPDATE_RECEIVER_DEBT:
      return updateReceiverDebt(state, payload);
    case CLEAR_INVOICE_CART:
      return clearInvoiceCart(state);
    case REMOVE_RECEIVER_IN_CART:
      return removeReceiverInCart(state, payload);
    case SET_SIMULATION_DATA:
      return getSimulationData(state, payload);
    case SIMULATION_ERROR:
      return simulationError(state, payload);
    case TOGGLE_INVOICE_RATE_CHANGE:
      return toggleInvoiceRateChange(state, payload);
    case UPDATE_INVOICE_FUND_RATE:
      return updateInvoiceFundRate(state, payload);
    case TOGGLE_INVOICE_ADVANCE_PERCENTAGE_CHANGE:
      return toggleInvoiceAdvancePercentageChange(state, payload);
    case UPDATE_INVOICE_ADVANCE_PERCENTAGE:
      return updateInvoiceAdvancePercentage(state, payload);
    case TOGGLE_INVOICE_ISSUER_DEBT_CHANGE:
      return toggleInvoiceIssuerDebtChange(state, payload);
    case UPDATE_INVOICE_ISSUER_DEBT:
      return updateInvoiceIssuerDebt(state, payload);
    case BULK_SELECTION_ACTION:
      return bulkSelectionAction(state, payload);
    case REMOVE_SELECTED_SUBGROUP:
      return removeSelectedReceivers(state);
    case CLEAR_SELECTED_SUBGROUP:
      return clearSubGroup(state, payload);
    case UPDATE_SUBGROUP_SELECTION:
      return updateSubGroupInvoices(state, payload);
    case UPDATE_BULK_SELECTION:
      return updateBulkSelection(state, payload);
    case TOGGLE_MODAL_CONFIRM_BULK_ACTION:
      return toggleModalConfirmBulkAction(state, payload);
    case TOGGLE_MODAL_UPDATE_TERM:
      return toggleInvoiceTermChange(state, payload);
    case UPDATE_INVOICE_TERM:
      return updateInvoiceTerm(state, payload);
    case UPDATE_FUNDS_ALL_INVOICES_RATE:
      return updateAllInvoicesRate(state, payload);
    case RESET_CLEARING_SUBGROUP:
      return resetClearingSubgroup(state);
    default:
      return state;
  }
};
