import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const checkCanDepositService = async orderIds => {
  try {
    const { data } = await axios.get(
      `/api/payment/business/checkchangetodeposit`,
      {
        params: {
          orderIds,
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { checkCanDepositService };
