import { REHYDRATE } from 'redux-persist';
import {
  ADD_FUND_FINANCING_FAILURE,
  ADD_FUND_FINANCING_START,
  ADD_FUND_FINANCING_SUCCESS,
  BULK_INVOICES_FUNDS_CSV_FAILURE,
  BULK_INVOICES_FUNDS_CSV_START,
  BULK_INVOICES_FUNDS_CSV_SUCCESS,
  BULK_INVOICES_UPDATE_CSV_FAILURE,
  BULK_INVOICES_UPDATE_CSV_START,
  BULK_INVOICES_UPDATE_CSV_SUCCESS,
  CESSION_UPDATE,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  DELETE_DEBT_PERIOD_FAILURE,
  DELETE_DEBT_PERIOD_START,
  DELETE_DEBT_PERIOD_SUCCESS,
  FETCH_FACTURAS_FAILURE,
  FETCH_FACTURAS_START,
  FETCH_FACTURAS_SUCCESS,
  FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_FAILURE,
  FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_START,
  FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_ID_BY_PAYER_RUT_FAILURE,
  FETCH_ORDERS_ID_BY_PAYER_RUT_START,
  FETCH_ORDERS_ID_BY_PAYER_RUT_SUCCESS,
  FETCH_ORDERS_START,
  FETCH_ORDERS_START_PERSIST_ORDER,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_FEES_FAILURE,
  FETCH_ORDER_FEES_START,
  FETCH_ORDER_FEES_SUCCESS,
  FETCH_SELECTED_ORDER_FAILURE,
  FETCH_SELECTED_ORDER_START,
  FETCH_SELECTED_ORDER_SUCCESS,
  FETCH_SUMMARY_OPERATION_FAILURE,
  FETCH_SUMMARY_OPERATION_START,
  FETCH_SUMMARY_OPERATION_SUCCESS,
  FETCH_FUND_SUMMARY_OPERATION_START,
  FETCH_FUND_SUMMARY_OPERATION_SUCCESS,
  FETCH_FUND_SUMMARY_OPERATION_FAILURE,
  FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_FAILURE,
  FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_START,
  FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_SUCCESS,
  FETCH_TRANSFER_AMOUNT_PYME,
  GET_PENDING_INVOICES_FAILURE,
  GET_PENDING_INVOICES_START,
  GET_PENDING_INVOICES_SUCCESS,
  HANDLE_ORDER_SELECTION_CHANGE,
  HANDLE_SELECT_FACTURA_CHANGE,
  HANDLE_SELECT_ORDER_CHANGE,
  HANDLE_SHOW_DICOM_DIALOG,
  INVOICES_BULK_UPDATE_FAILURE,
  INVOICES_BULK_UPDATE_START,
  INVOICES_BULK_UPDATE_SUCCESS,
  INVOICE_BANK_ACCOUNT_SUCCESS,
  LOAD_EXTERNAL_PDF_FAILURE,
  LOAD_EXTERNAL_PDF_START,
  LOAD_EXTERNAL_PDF_SUCCESS,
  MAKE_CESSION_START,
  MATCH_PENDING_INVOICES_FAILURE,
  MATCH_PENDING_INVOICES_START,
  MATCH_PENDING_INVOICES_SUCCESS,
  REGISTER_DEBT_PERIOD_FAILURE,
  REGISTER_DEBT_PERIOD_START,
  REGISTER_DEBT_PERIOD_SUCCESS,
  REGISTER_ORDER_CREDIT_FAILURE,
  REGISTER_ORDER_CREDIT_SUCCESS,
  RESET_BULK_CSV_FUNDS_ERRORS,
  RESET_CREATE_ORDER_ERROR,
  RESET_DICOM_DIALOG,
  RESET_STATE_REPLACED_INVOICE,
  RESET_SELECTED_ORDERS,
  RESET_STATE_UPDATE_ORDER,
  SET_ORDERS_ACTIVE_FILTERS_BY_DEFAULT,
  UPDATE_DEBT_PERIOD_FAILURE,
  UPDATE_DEBT_PERIOD_START,
  UPDATE_DEBT_PERIOD_SUCCESS,
  UPDATE_FACTURA_FAILURE,
  UPDATE_FACTURA_START,
  UPDATE_FACTURA_SUCCESS,
  UPDATE_ORDER_DETAILS_FAILURE,
  UPDATE_ORDER_DETAILS_SUCCESS,
  UPDATE_ORDER_INVOICE_FUND_FAILURE,
  UPDATE_ORDER_INVOICE_FUND_START,
  UPDATE_ORDER_INVOICE_FUND_SUCCESS,
  UPDATE_PENDING_INVOICES_FAILURE,
  UPDATE_PENDING_INVOICES_SUCCESS,
  UPDATE_PENDING_INVOICES_START,
  UPDATE_STATUS_ORDER_TABLE,
  VERIFY_INVOICE,
  VERIFY_INVOICE_START,
  VERIFY_INVOICE_SUCCESS,
  VERIFY_INVOICE_FAILURE,
  WS_UPDATE_BULK_INVOICES,
  WS_UPDATE_FACTURA,
  WS_UPDATE_INVOICE,
  UPDATE_ORDER_START,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  RESET_STATE_UPDATE_INVOICE,
  RESET_STATE_UPLOADING_INVOICE,
  DOWNLOAD_AEC_INVOICE_START,
  DOWNLOAD_AEC_INVOICE_SUCCESS,
  DOWNLOAD_AEC_INVOICE_FAILURE,
  UPDATE_ORDER_STATUS_START,
  UPDATE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
  RESET_CREATE_ORDER,
  RESET_STATE_FETCH_ORDER_IDS_BY_PAYER_RUT,
  RESET_STATE_UPDATE_BULK_INVOICES,
  FETCH_SIMULATION_FOR_ORDER_START,
  FETCH_SIMULATION_FOR_ORDER_SUCCESS,
  FETCH_SIMULATION_FOR_ORDER_FAILURE,
  FETCH_ORDER_CREDIT_SUCCESS,
  FETCH_ORDER_CREDIT_FAILURE,
  FETCH_INVOICE_DOCUMENTS_START,
  FETCH_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_INVOICE_DOCUMENTS_FAILURE,
  DOWNLOAD_INVOICE_DOCUMENTS_START,
  DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_INVOICE_DOCUMENTS_FAILURE,
  FETCH_ORDER_INVOICE_RISK_START,
  FETCH_ORDER_INVOICE_RISK_SUCCESS,
  FETCH_ORDER_INVOICE_RISK_FAILURE,
  UPDATE_INVOICE_RISK,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE,
  UPLOAD_ORDER_INVOICE_PDF_START,
  UPLOAD_ORDER_INVOICE_PDF_SUCCESS,
  UPLOAD_ORDER_INVOICE_PDF_FAILURE,
  DELETE_ORDER_INVOICE_PDF_START,
  DELETE_ORDER_INVOICE_PDF_SUCCESS,
  DELETE_ORDER_INVOICE_PDF_FAILURE,
  RESET_ORDER_INVOICE_PDF_UPLOAD_STATUS,
  DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_START,
  DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_SUCCESS,
  DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_FAILURE,
  REGISTER_ORDER_CREDIT_START,
  MANAGE_ORDER_INVOICES_START,
  MANAGE_ORDER_INVOICES_SUCCESS,
  MANAGE_ORDER_INVOICES_FAILURE,
  FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_START,
  FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_SUCCESS,
  FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_FAILURE,
  FETCH_ORDER_INVOICES_ATTRIBUTES_START,
  FETCH_ORDER_INVOICES_ATTRIBUTES_SUCCESS,
  FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE,
  LOAD_INVOICE_PDF_START,
  LOAD_INVOICE_PDF_SUCCESS,
  LOAD_INVOICE_PDF_FAILURE,
  RESET_ORDER_FEES,
  GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_START,
  GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_SUCCESS,
  GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_FAILURE,
  UPDATE_ORDER_INVOICE_IS_HOLD_START,
  UPDATE_ORDER_INVOICE_IS_HOLD_SUCCESS,
  UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE,
  RESET_ORDER_INVOICE_IS_HOLD_STORE,
  FETCH_COUNT_ORDER_COMMENTS_START,
  FETCH_COUNT_ORDER_COMMENTS_SUCCESS,
  FETCH_COUNT_ORDER_COMMENTS_FAILURE,
  FETCH_COUNT_ORDER_RESPONSIBLES_START,
  FETCH_COUNT_ORDER_RESPONSIBLES_SUCCESS,
  FETCH_COUNT_ORDER_RESPONSIBLES_FAILURE,
  RESET_FETCH_SUMMARY_OPERATION,
  RESET_FETCH_INVOICES,
  RESET_MATCH_PENDING_INVOICES,
  RESET_OPERATION_DETAIL_DATA,
} from '../actions/types';
import { DEFAULT_COUNTRY } from '../helpers/Constants';
import trackOrderUpdated from '../helpers/segment/tracking/orderUpdated';

const initialState = {
  selectedOrders: [],
  loadingOrders: false,
  fetchOrderIsLoading: false,
  orders: [],
  orderError: false,
  changingStatusInput: false,
  updatedOrder: {},
  selectedFactura: {},
  facturas: [],
  updatedOrderFactura: {},
  updatedFactura: false,
  updatingInvoice: false,
  invoiceWasUpdated: false,
  loadingInvoiceId: null,
  loadingFacturas: false,
  invoicesFetched: false,
  saved: false,
  tableData: {
    data: [],
    page: 1,
    totalCount: 0,
    pageSize: 20,
  },
  orderPagination: {
    page: 1,
    totalData: 0,
    pageSize: 20,
  },
  ordersAvailableFilters: [],
  ordersActiveFilters: [],
  ordersTransferred: ['ACTIVE', 'DEFAULT'],
  activeTable: true,
  pendingInvoicesErrors: false,
  pendingInvoices: [],
  loadingPendingInvoices: false,
  pendingPDFList: [],
  zipBase64List: null,
  matchPendingInvoices: null,
  selectedDicom: null,
  orderCredit: null,
  errorCreateOrder: '',

  facturasId: [],
  facturasReplaced: [],
  orderIdReplaced: '',
  simulationPdf: {},
  sendingSimulationEmail: false,
  cessionButtonEnabled: false,
  reCessionButtonEnabled: false,
  bulkButtonEnabled: false,
  bulkAECButtonEnabled: false,
  wasOrderFetch: null,
  gettingURL: [],
  transferAmountPyme: '',
  transferDatePyme: '',
  country: DEFAULT_COUNTRY,
  loadingFundFinancing: false,

  orderSummaryIsLoading: false,
  orderSummaryWasLoaded: false,
  orderSummary: null,

  fundSummary: null,
  fundSummaryIsLoading: false,
  fundSummaryWasLoaded: false,

  businesses: [],
  fetchingBusinesses: false,
  csvInvoiceToOrderErrors: [],
  loadingDebtSuper: false,
  loadingTotalOrdersTransferred: false,
  savingOrder: false,
  fees: {},
  updatingOrder: false,
  savedOrder: false,
  savedInvoice: false,
  invoiceWasUploaded: false,
  downloadingPdf: false,
  downloadingAec: false,

  createOrderIsLoading: false,
  orderWasCreated: false,
  createOrderError: null,
  orderId: null,
  orderType: null,

  bulkInvoicesUpdateErrors: [],
  savingBulkInvoicesUpdate: false,
  savedBulkInvoicesUpdate: false,
  bulkInvoicesUpdateQty: 0,

  savedBulkInvoicesFund: false,
  savingBulkInvoicesFund: false,
  errorsBulkInvoicesFund: [],

  updateOrderStatusIsLoading: false,
  updateOrderStatusError: null,
  orderStatusWasUpdated: false,

  updateBulkOrderStatusIsLoading: false,
  updateBulkOrderStatusError: null,
  bulkOrderStatusWasUpdated: false,
  matchPendingInvoicesIsLoading: false,
  matchPendingInvoicesWasSuccessful: false,

  pendingInvoicesIsLoading: false,

  orderInvoiceWasCreate: false,
  updateOrderErrors: null,

  fetchOrdersIdByPayerRutError: null,
  ordersIdToReplace: [],

  holdInvoicesWarningMessage: null,
  invoicesBulkWasUpdated: false,
  orderIdsWithHoldInvoices: [],
  invoicesBulkError: null,

  invoiceDocuments: [],
  fetchInvoiceDocumentsIsLoading: false,
  fetchInvoiceDocumentsError: null,

  downloadInvoiceDocumentsIsLoading: false,
  invoiceDocumentWasDownloaded: false,
  downloadInvoiceDocumentsError: null,

  orderSimulationIsLoading: false,
  orderSimulationWasLoaded: false,
  orderSimulation: null,

  uploadCsvInvoicesIsLoading: false,
  uploadCsvInvoicesError: null,
  uploadCsvInvoicesErrorLines: null,
  csvInvoicesWasUploaded: false,

  invoiceRiskIsLoading: false,
  invoiceRiskError: null,

  fetchOrderInvoiceHasAttachmentsIsLoading: false,
  fetchOrderInvoiceHasAttachmentsError: null,

  orderInvoicesAttachments: [],

  orderInvoicePdfIsUploading: false,
  orderInvoicePdfWasUploaded: false,
  uploadOrderInvoicePdfError: null,
  updatingInvoiceId: null,

  orderInvoicePdfIsDeleting: false,
  orderInvoicePdfWasDeleted: false,
  deleteOrderInvoicePdfError: null,
  deletingInvoiceId: null,

  marketInvoicerPdfGlobalClIsDownloading: false,
  marketInvoicerPdfGlobalClWasDownloaded: false,
  marketInvoicerPdfGlobalClError: null,

  invoiceVerificationIsLoading: false,
  invoiceVerified: false,
  invoiceVerificationError: null,

  createOrderCreditIsLoading: false,
  orderCreditWasCreated: false,

  manageOrderInvoicesIsLoading: false,
  manageOrderInvoicesIsDone: false,
  manageOrderInvoicesError: null,

  fetchAmountInfoIsLoading: false,
  fetchOrderInvoicesAttributesIsLoading: false,

  fetchRestrictiveDataNewSimulationIsLoading: false,
  restrictiveDataNewSimulation: null,

  orderInvoiceIsHoldIsUpdating: false,
  updateOrderInvoiceIsHoldError: null,
  orderInvoiceIsHoldWasUpdated: false,

  fetchCountOrderCommentsIsLoading: false,
  hasPendingComments: false,
  commentsCount: 0,

  fetchCountOrderResponsiblesIsLoading: false,
  responsiblesCount: 0,
};

const fetchFacturasStart = state => ({
  ...state,
  loadingFacturas: true,
  invoicesFetched: false,
});

const fetchFacturasSuccess = (state, payload) => {
  const { invoices, orderFacturasData } = payload;
  let facturas = orderFacturasData;
  if (invoices) {
    facturas = invoices;
  }

  return {
    ...state,
    loadingFacturas: false,
    invoicesFetched: true,
    facturas,
  };
};

const fetchFacturasFailure = (state, payload) => ({
  ...state,
  loadingFacturas: false,
  facturaError: payload.errorCode,
  invoicesFetched: true,
});

const resetFetchFacturas = state => ({
  ...state,
  invoicesFetched: true,
});

const bulkInvoicesFundCsvStart = state => ({
  ...state,
  savedBulkInvoicesFund: false,
  savingBulkInvoicesFund: true,
  errorsBulkInvoicesFund: [],
});

const bulkInvoicesFundCsvSuccess = (state, payload) => ({
  ...state,
  errorsBulkInvoicesFund: payload.errors,
  savedBulkInvoicesFund: payload.statusRequest,
  savingBulkInvoicesFund: false,
});

const bulkInvoicesFundCsvFailure = state => ({
  ...state,
  savedBulkInvoicesFund: false,
  savingBulkInvoicesFund: false,
});

const resetBulkCsvInvoices = state => ({
  ...state,
  errorsBulkInvoicesFund: [],
  savedBulkInvoicesFund: false,
  savingBulkInvoicesFund: false,
  bulkInvoicesUpdateErrors: [],
  uploadCsvInvoicesError: null,
  uploadCsvInvoicesErrorLines: null,
});

const fetchSummaryOperationStart = state => ({
  ...state,
  loadingOrders: true,
  orderSummaryIsLoading: true,
  orderSummaryWasLoaded: false,
});

const fetchSummaryOperationSuccess = (state, payload) => {
  const { orderSummary } = payload;
  return {
    ...state,
    loadingOrders: false,
    orderSummaryWasLoaded: true,
    orderSummaryIsLoading: false,
    orderSummary,
  };
};

const fetchSummaryOperationFailure = state => ({
  ...state,
  loadingOrders: false,
  orderSummaryIsLoading: false,
  orderSummaryWasLoaded: false,
});

const resetSummaryOperation = state => ({
  ...state,
  orderSummaryWasLoaded: false,
});

const fetchFundSummaryOperationStart = state => ({
  ...state,
  loadingOrders: true,
  fundSummaryIsLoading: true,
  fundSummaryWasLoaded: false,
});

const fetchFundSummaryOperationSuccess = (state, payload) => {
  const { fundSummary } = payload;
  return {
    ...state,
    loadingOrders: false,
    fundSummaryWasLoaded: true,
    fundSummaryIsLoading: false,
    fundSummary,
  };
};

const fetchFundSummaryOperationFailure = state => ({
  ...state,
  loadingOrders: false,
  fundSummaryIsLoading: false,
});

const updateOrderInvoiceFundSetLoading = (state, isLoading = false) => ({
  ...state,
  updateOrderInvoiceFundIsLoading: isLoading,
});

const setLoadingFundFinancing = (state, isLoading = false) => ({
  ...state,
  loadingFundFinancing: isLoading,
});
const fetchTotalOrderTransferredBySetLoading = (state, isLoading = false) => {
  return {
    ...state,
    loadingTotalOrdersTransferred: isLoading,
  };
};

const invoiceBankAccountSuccess = (state, payload) => {
  const { bankAccount } = payload;
  const invoices = state.facturas.map(invoice => {
    if (invoice.payerIdentifier === bankAccount.accountRut) {
      return { ...invoice, BankAccount: bankAccount };
    }
    return invoice;
  });
  return {
    ...state,
    facturas: invoices,
  };
};
const resetStateUpdateOrder = state => ({
  ...state,
  loadingOrders: false,
  updatingOrder: false,
  savedOrder: false,
  updateOrderErrors: null,
});

const resetStateUpdateInvoice = state => ({
  ...state,
  loadingFacturas: false,
  updatingInvoice: false,
  savedInvoice: false,
  updateFacturaErrors: null,
});

const resetStateUpdateBulkInvoices = state => ({
  ...state,
  holdInvoicesWarningMessage: null,
  invoicesBulkWasUpdated: false,
  loadingFacturas: false,
  updatingInvoice: false,
});

const resetStateFetchOrdersIdByPayerRut = state => ({
  ...state,
  loadingOrders: false,
  ordersIdToReplace: [],
  fetchOrdersIdByPayerRutError: null,
});

const resetStateUploadingInvoice = state => ({
  ...state,
  uploadingFactura: false,
  invoiceWasUploaded: false,
  uploadingFacturaError: false,
});

const fetchTotalOrderTransferredBySuccess = (state, payload = []) => {
  const totalOrderTransferredByBusinessIdentifier = payload;
  const invoices = state.facturas.map(invoice => {
    const { totalOrdersTransferred = 0 } =
      totalOrderTransferredByBusinessIdentifier.find(
        ({ identifier }) => identifier === invoice.payerIdentifier
      ) || {};
    return { ...invoice, totalOrdersTransferred };
  });
  return {
    ...state,
    facturas: invoices,
    loadingTotalOrdersTransferred: false,
  };
};

const updateOrderStart = state => ({
  ...state,
  loadingOrders: true,
  updatingOrder: true,
  savedOrder: false,
});

const updateOrderSuccess = (state, payload) => {
  const orders = [...state.orders];
  const { order: { id: orderId } = {} } = payload;
  const index = orders.findIndex(({ id }) => id === orderId);
  if (index >= 0) {
    orders[index] = payload.order;
  }
  return {
    ...state,
    orders,
    loadingOrders: false,
    updatingOrder: false,
    savedOrder: true,
  };
};

const updateOrderFailure = (state, payload) => ({
  ...state,
  loadingOrders: false,
  updatingOrder: false,
  savedOrder: false,
  updateOrderErrors: payload.errorCode,
});

const wsUpdateBulkInvoices = (state, payload) => {
  const { updatedOrderInvoices: wsInvoices, updatedOrder: wsUpdatedOrder } =
    payload;
  const { updatedOrder, facturas: invoicesState } = state;
  let newState = state;
  if (updatedOrder.id === wsUpdatedOrder.id) {
    const facturas = invoicesState.map(is => {
      const foundInvoice = wsInvoices.find(wsInvoice => is.id === wsInvoice.id);
      return { ...is, ...foundInvoice };
    });

    newState = {
      ...state,
      updatedOrder,
      facturas,
    };
  }
  return newState;
};

const invoicesBulkUpdateSuccess = (state, payload) => {
  const { updatedOrderInvoices = [], holdInvoicesCode } = payload;
  const invoices = state.facturas.map(invoice => {
    const updatedInvoice = updatedOrderInvoices.find(
      orderInvoice => orderInvoice.id === invoice.id
    );
    if (updatedInvoice) {
      return {
        ...invoice,
        ...updatedInvoice,
      };
    }
    return invoice;
  });

  return {
    ...state,
    bulkAECButtonEnabled: false,
    reCessionButtonEnabled: false,
    cessionButtonEnabled: false,
    facturas: invoices,
    holdInvoicesWarningMessage: holdInvoicesCode,
    invoicesBulkWasUpdated: true,
    loadingFacturas: false,
    updatingInvoice: false,
    invoicesBulkError: null,
  };
};

const fetchOrdersStart = state => ({
  ...state,
  loadingOrders: true,
  updatedOrder: {},
  fundSummary: null,
  orderSummary: null,
});

const fetchOrdersStartPersistOrder = state => ({
  ...state,
  loadingOrders: true,
});

const fetchOrdersSuccess = (state, payload) => {
  const { data, pagination, operation } = payload;
  const tableData = {
    ...pagination,
    operation,
  };
  return {
    ...state,
    loadingOrders: false,
    tableData,
    orderPagination: pagination,
    orders: data,
  };
};

const fetchOrdersFailure = (state, payload) => ({
  ...state,
  loadingOrders: false,
  orderError: payload.errorCode,
});

const setLoadingDownloadAecInvoice = (state, downloadingAec = false) => ({
  ...state,
  downloadingAec,
});

const makeCessionStart = (state, payload) => {
  const filteredFacturas = payload.reduce(
    (acc, val) => ({ ...acc, [val.id]: val }),
    {}
  );
  const retFacturas = state.facturas.map(val =>
    filteredFacturas[val.id] ? { ...val, cessionStatus: 'PROCESS' } : { ...val }
  );
  return {
    ...state,
    facturas: retFacturas,
  };
};

const fetchOrderFeesStart = state => ({ ...state, loadingFees: true });

const fetchOrderFeesSuccess = (state, payload) => ({
  ...state,
  fees: payload.fees,
  loadingFees: false,
});

const fetchOrderFeesFailure = (state, payload) => ({
  ...state,
  loadingFees: false,
  feeErrors: payload.errorCode,
});

const updateOrderDetailsSuccess = (state, payload) => {
  const { order, orderFacturasData } = payload;
  const newInvoices = state.facturas.map(invoice => {
    const getInvoice = orderFacturasData.find(({ id }) => id === invoice.id);
    if (getInvoice) {
      return {
        ...invoice,
        ...getInvoice,
      };
    }
    return invoice;
  });

  return {
    ...state,
    updatedOrder: order,
    facturas: newInvoices,
  };
};

const updateOrderDetailsFailure = (state, payload) => ({
  ...state,
  feeErrors: payload.errorCode,
});

const handleSelectOrderChange = (state, payload) => {
  const { order: updatedOrder } = payload;
  return {
    ...state,
    updatedOrder,
  };
};

const handleSelectFacturaChange = (state, payload) => ({
  ...state,
  selectedFactura: payload.invoice,
  ordersIdToReplace: [],
  facturasId: [],
});

const handleShowDicomDialog = (state, payload) => ({
  ...state,
  selectedDicom: payload.dicomData,
});

const resetDicomDialog = state => ({ ...state, selectedDicom: null });

const updateFacturaStart = state => ({
  ...state,
  loadingFacturas: true,
  updatingInvoice: true,
  invoiceWasUpdated: false,
  updateFacturaErrors: null,
});

const updateFacturaSuccess = (state, payload) => {
  const {
    updatedOrder,
    updatedOrderFacturas: { facturas: newInvoices },
  } = payload;
  const {
    orders: stateOrders,
    selectedOrders: stateSelectedOrders,
    facturas: storeInvoices,
  } = state;
  const orders = stateOrders.map(order => {
    if (order.id === updatedOrder.id) {
      return updatedOrder;
    }
    return order;
  });
  const selectedOrders = stateSelectedOrders.filter(
    order => order.id !== updatedOrder.id
  );

  // change values without losing attributes
  const invoices = newInvoices.map(invoice => {
    const getInvoice = storeInvoices.find(({ id }) => id === invoice.id);
    if (getInvoice) {
      return {
        ...getInvoice,
        ...invoice,
      };
    }
    return invoice;
  });

  return {
    ...state,
    facturas: invoices,
    updatedOrder,
    loadingFacturas: false,
    updatingInvoice: false,
    invoiceWasUpdated: true,
    selectedOrders,
    orders,
  };
};

const updateFacturaFailure = (state, payload) => ({
  ...state,
  updateFacturaErrors: payload.errorCode,
  loadingFacturas: false,
  updatingInvoice: false,
  invoiceWasUpdated: false,
});

const wsUpdateFactura = (state, payload) => {
  const {
    updatedOrder: wsUpdatedOrder,
    updatedOrderFacturas: { facturas: wsFacturas },
  } = payload;
  const { updatedOrder: stateUpdatedOrder, facturas: storeInvoices } = state;
  const { id: wsUpdatedOrderId } = wsUpdatedOrder;
  const { id: stateUpdatedOrderId } = stateUpdatedOrder;

  // change values without losing attributes
  const invoices = storeInvoices.map(invoice => {
    const getInvoice = wsFacturas.find(({ id }) => id === invoice.id);
    if (getInvoice) {
      return {
        ...invoice,
        ...getInvoice,
      };
    }
    return invoice;
  });

  return {
    ...state,
    facturas:
      stateUpdatedOrderId === wsUpdatedOrderId ? invoices : storeInvoices,
    updatedOrder:
      stateUpdatedOrderId === wsUpdatedOrderId
        ? wsUpdatedOrder
        : stateUpdatedOrder,
  };
};

const invoicesBulkUpdateStart = state => ({
  ...state,
  loadingFacturas: true,
  updatingInvoice: true,
  invoicesBulkWasUpdated: false,
  invoicesBulkError: null,
  holdInvoiceIds: [],
});

const invoicesBulkUpdateFailure = (state, payload) => ({
  ...state,
  loadingFacturas: false,
  updatingInvoice: false,
  invoicesBulkWasUpdated: false,
  invoicesBulkError: payload.errorCode,
  holdInvoiceIds: [],
});

const cessionUpdate = (state, payload) => {
  const retFacturas = state.facturas.map(val => {
    if (payload.siiFacturaId === val.siiFacturaId) {
      return {
        ...val,
        cessionStatus: payload.status,
        cessionStatusSii: payload.statusSii,
        cessionId: payload.cessionId,
        cessionary: payload.cessionaryName,
      };
    }
    return { ...val };
  });
  return {
    ...state,
    facturas: retFacturas,
  };
};

const loadInvoicePdfStart = (state, payload) => ({
  ...state,
  orderInvoicePdfIsDownloading: true,
  loadingInvoiceId: payload,
});

const loadInvoicePdfSuccess = state => ({
  ...state,
  orderInvoicePdfIsDownloading: false,
  loadingInvoiceId: null,
});

const loadInvoicePdfFailure = state => ({
  ...state,
  orderInvoicePdfIsDownloading: false,
  loadingInvoiceId: null,
});

const wsUpdateInvoice = (state, payload) => {
  const { siiFacturaId, command } = payload;
  const retFacturas = state.facturas.map(val => {
    if (siiFacturaId && siiFacturaId === val.siiFacturaId) {
      // eslint-disable-next-line no-param-reassign
      if (command) delete payload.command;
      return {
        ...val,
        ...payload,
      };
    }
    if (payload.id && payload.id === val.id) {
      return {
        ...val,
        ...payload,
      };
    }
    return { ...val };
  });
  return {
    ...state,
    facturas: retFacturas,
  };
};

const setOrdersActiveFilterByDefault = (state, payload) => {
  const { ordersAvailableFilters, ordersActiveGroupFilters } = payload;
  return {
    ...state,
    ordersActiveFilters: ordersAvailableFilters,
    ordersAvailableFilters,
    ordersActiveGroupFilters,
  };
};

const fetchSelectedOrderStart = state => ({
  ...state,
  updatedOrder: {},
  fetchOrderIsLoading: true,
  loadingOrders: true,
  wasOrderFetch: false,
  orderError: null,
});

const fetchSelectedOrderSuccess = (state, payload) => ({
  ...state,
  updatedOrder: payload.order,
  fetchOrderIsLoading: false,
  loadingOrders: false,
  wasOrderFetch: true,
});

const fetchSelectedOrderFailure = (state, payload) => ({
  ...state,
  loadingOrders: false,
  fetchOrderIsLoading: false,
  orderError: payload.errorCode,
  wasOrderFetch: true,
});

const getPendingInvoicesStart = state => ({
  ...state,
  loadingPendingInvoices: true,
});

const getPendingInvoicesSuccess = (state, payload) => ({
  ...state,
  pendingInvoices: payload.invoices,
  pendingPDFList: payload.pdfList,
  zipBase64List: payload.zipBase64List,
  loadingPendingInvoices: false,
});

const getPendingInvoicesFailure = (state, payload) => ({
  ...state,
  pendingInvoicesErrors: payload.errorCode,
  loadingPendingInvoices: false,
});

const updatePendingInvoicesStart = state => ({
  ...state,
  pendingInvoicesIsLoading: true,
});

const updatePendingInvoicesSuccess = (state, payload) => ({
  ...state,
  pendingInvoices: payload.invoices,
  pendingInvoicesIsLoading: false,
});

const updatePendingInvoicesFailure = (state, payload) => ({
  ...state,
  pendingInvoicesErrors: payload.errorCode,
  pendingInvoicesIsLoading: false,
});

const matchPendingInvoicesStart = state => ({
  ...state,
  matchPendingInvoicesIsLoading: true,
  matchPendingInvoicesWasSuccessful: false,
});

const matchPendingInvoicesSuccess = (state, payload) => ({
  ...state,
  matchPendingInvoices: payload.data,
  matchPendingInvoicesIsLoading: false,
  matchPendingInvoicesWasSuccessful: true,
});

const matchPendingInvoicesFailure = (state, payload) => ({
  ...state,
  matchPendingInvoices: payload.errorCode,
  matchPendingInvoicesIsLoading: false,
});

const resetMatchPendingInvoices = state => ({
  ...state,
  matchPendingInvoicesWasSuccessful: false,
});

const updateStatusOrderTable = (state, payload) => ({
  ...state,
  activeTable: payload.activeTable,
});

const handleOrderSelectionChange = (state, payload) => {
  if (!payload.length) {
    return {
      ...state,
      selectedOrders: [],
    };
  }
  if (payload[0].tableData.checked) {
    const data = payload.filter(
      order => !state.selectedOrders.some(o => o.id === order.id)
    );
    return {
      ...state,
      selectedOrders: [...state.selectedOrders, ...data],
    };
  }

  const selectedOrders = state.selectedOrders.filter(
    order => order.id !== payload[0].id
  );
  return {
    ...state,
    selectedOrders,
  };
};

const resetSelectedOrders = state => ({ ...state, selectedOrders: [] });

const registerOrderCreditStart = state => {
  return {
    ...state,
    orderCredit: null,
    createOrderCreditIsLoading: true,
    orderCreditWasCreated: false,
  };
};

const registerOrderCreditSuccess = (state, payload) => {
  return {
    ...state,
    orderCredit: payload,
    createOrderCreditIsLoading: false,
    orderCreditWasCreated: true,
  };
};

const registerOrderCreditFailure = state => ({
  ...state,
  orderCredit: null,
  createOrderCreditIsLoading: false,
});

const resetCreaterOrder = state => {
  return {
    ...state,
    createOrderIsLoading: false,
    orderWasCreated: false,
    createOrderError: null,
    orderId: null,
    orderType: null,
  };
};

const createOrderStart = state => {
  return {
    ...state,
    createOrderIsLoading: true,
    orderWasCreated: false,
    createOrderError: null,
    orderId: null,
    orderType: null,
  };
};

const createOrderSuccess = (state, payload) => {
  // Remove attribute "data" in migration
  const { createdOrder, data } = payload;
  return {
    ...state,
    orderId: data ? data.orderId : createdOrder.id,
    orderType: data ? data.orderType : createdOrder.orderType,
    orderWasCreated: true,
    createOrderIsLoading: false,
  };
};
const createOrderFailure = (state, payload) => ({
  ...state,
  orderId: null,
  orderType: null,
  createOrderIsLoading: false,
  createOrderError: payload.errorCode,
});

const resetCreateOrderError = state => ({
  ...state,
  errorCreateOrder: '',
});

const bulkInvoicesUpdateCsvStart = state => ({
  ...state,
  savedBulkInvoicesUpdate: false,
  savingBulkInvoicesUpdate: true,
});

const bulkInvoicesUpdateCsvSuccess = (state, payload) => {
  const { bulkInvoicesUpdateQty, bulkInvoicesUpdateErrors } = payload;
  return {
    ...state,
    savedBulkInvoicesUpdate: !bulkInvoicesUpdateErrors.length,
    savingBulkInvoicesUpdate: false,
    bulkInvoicesUpdateErrors,
    bulkInvoicesUpdateQty,
  };
};
const bulkInvoicesUpdateCsvFailure = state => ({
  ...state,
  savedBulkInvoicesUpdate: false,
  savingBulkInvoicesUpdate: false,
});

const fetchOrdersIdByPayerRutStart = state => ({
  ...state,
  loadingOrders: true,
  fetchOrdersIdByPayerRutError: null,
});

const fetchOrdersIdByPayerRutSuccess = (state, payload) => {
  const {
    data,
    ordersId,
    errorCode: fetchOrdersIdByPayerRutError = null,
  } = payload;

  const newOrdersId = data?.ordersId || ordersId || [];
  return {
    ...state,
    loadingOrders: false,
    ordersIdToReplace: newOrdersId,
    fetchOrdersIdByPayerRutError,
  };
};

const fetchOrdersIdByPayerRutFailure = (state, payload) => ({
  ...state,
  ordersIdToReplace: [],
  loadingOrders: false,
  fetchOrdersIdByPayerRutError: payload.errorCode,
});

const fetchIdAndFolioFacturasByOrderIdStart = state => ({
  ...state,
  loadingOrders: true,
});
const fetchIdAndFolioFacturasByOrderIdSuccess = (state, payload) => ({
  ...state,
  loadingOrders: false,
  facturasReplaced: payload.data.facturas,
  orderIdReplaced: payload.orderId,
});
const fetchIdAndFolioFacturasByOrderIdFailure = state => ({
  ...state,
  facturasReplaced: [],
  orderIdReplaced: '',
  loadingOrders: false,
});

const resetStateReplacedInvoice = state => ({
  ...state,
  facturasReplaced: initialState.facturasReplaced,
  orderIdReplaced: initialState.orderIdReplaced,
});

const verifyInvoice = (state, payload) => {
  const { verifiedInvoice, isVerifiedOrder } = payload;
  const facturas = state.facturas.map(factura => {
    const VerificationReasons =
      factura.id === verifiedInvoice?.id
        ? verifiedInvoice.verificationReason
        : factura.VerificationReasons;
    const verificationStatus =
      factura.id === verifiedInvoice?.id
        ? verifiedInvoice.verificationStatus
        : factura.verificationStatus;
    return {
      ...factura,
      verificationReasons: VerificationReasons,
      verificationStatus,
    };
  });
  const updatedOrder = {
    ...state.updatedOrder,
    isVerified: isVerifiedOrder,
  };
  return {
    ...state,
    facturas,
    updatedOrder,
  };
};

const verifyInvoiceStart = state => {
  return {
    ...state,
    invoiceVerificationIsLoading: true,
    invoiceVerified: false,
    invoiceVerificationError: null,
  };
};

const verifyInvoiceSuccess = (state, payload) => {
  const { verificationReason, verificationStatus, isVerifiedOrderInvoice, id } =
    payload;
  const invoices = state.facturas.map(invoice => {
    return {
      ...invoice,
      ...(invoice.id === id && {
        verificationReason,
        verificationStatus,
        isVerifiedOrderInvoice,
      }),
    };
  });
  const updatedOrder = {
    ...state.updatedOrder,
    isVerified: isVerifiedOrderInvoice,
  };
  return {
    ...state,
    facturas: invoices,
    updatedOrder,
    invoiceVerificationIsLoading: false,
    invoiceVerified: true,
  };
};

const verifyInvoiceFailure = (state, payload) => {
  return {
    ...state,
    invoiceVerificationIsLoading: false,
    invoiceVerified: true,
    invoiceVerificationError: payload.errorCode,
  };
};

const loadExternalPdfStart = (state, payload) => ({
  ...state,
  gettingURL: [...state.gettingURL, payload.orderFacturaId],
});

const loadExternalPdfSuccess = (state, payload) => {
  const newFacturas = [...state.facturas];

  const idx = state.facturas.findIndex(of => {
    return of.id === payload.orderFacturaId;
  });

  newFacturas[idx].externalUrl = payload.externalUrl;

  return {
    ...state,
    gettingURL: [
      ...state.gettingURL.filter(of => of !== payload.orderFacturaId),
    ],
    facturas: newFacturas,
  };
};

const loadExternalPdfFailure = (state, payload) => ({
  ...state,
  gettingURL: [...state.gettingURL.filter(o => o !== payload.orderFacturaId)],
});

const fetchTransferAmountPyme = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const rehydrate = payload => ({
  ...initialState,
  ordersAvailableFilters: payload?.order?.ordersAvailableFilters || [],
  ordersActiveFilters: payload?.order?.ordersActiveFilters || [],
});

const updateOrderStatusStart = state => ({
  ...state,
  updateOrderStatusIsLoading: true,
  updateOrderStatusError: null,
  orderStatusWasUpdated: false,
});

const updateOrderStatusSuccess = (state, payload) => {
  const orders = [...state.orders];
  const { order: { id: orderId } = {} } = payload;
  const index = orders.findIndex(({ id }) => id === orderId);
  if (index >= 0) {
    orders[index] = payload.order;
  }
  trackOrderUpdated(payload.order, state.facturas);
  const updatedOrders = orders.map(order => {
    // this evaluation is for CL, remove it when global is fully implemented
    if (order.Business) {
      if (order.Business.rut === payload.order.Business.rut) {
        return {
          ...order,
          Business: {
            ...order.Business,
            totalOrdersTransferred:
              payload.order.Business.totalOrdersTransferred,
          },
        };
      }
    } else if (order.businessId === payload.order.businessId) {
      if (order.status === 'REJECTED') {
        return {
          ...order,
          totalOrdersTransferred: payload.order.totalOrdersTransferred,
          baseRate: 0,
          interest: 0,
          transfer: 0,
        };
      }
      return {
        ...order,
        totalOrdersTransferred: payload.order.totalOrdersTransferred,
      };
    }

    return order;
  });

  return {
    ...state,
    orders: updatedOrders,
    updateOrderStatusIsLoading: false,
    orderStatusWasUpdated: true,
  };
};

const updateOrderStatusFailure = (state, payload) => ({
  ...state,
  updateOrderStatusIsLoading: false,
  updateOrderStatusError: payload.errorCode,
});

const addOrderInvoiceRateStart = state => {
  return { ...state, loadingFundFinancing: true, orderInvoiceWasCreate: false };
};

const addOrderInvoiceRateSuccess = state => {
  return { ...state, loadingFundFinancing: false, orderInvoiceWasCreate: true };
};

const addOrderInvoiceRateFailure = state => {
  return {
    ...state,
    loadingFundFinancing: false,
    orderInvoiceWasCreate: false,
  };
};

const fetchSimulationOperationStart = state => ({
  ...state,
  loadingOrders: true,
  orderSimulationIsLoading: true,
  orderSimulationWasLoaded: false,
});

const fetchSimulationOperationSuccess = (state, payload) => {
  const { orderSimulation } = payload;
  return {
    ...state,
    loadingOrders: false,
    orderSimulationWasLoaded: true,
    orderSimulationIsLoading: false,
    orderSimulation,
  };
};

const fetchSimulationOperationFailure = (state, payload) => ({
  ...state,
  loadingOrders: false,
  orderSimulationIsLoading: false,
  orderSimulationWasLoaded: true,
  orderSimulation: null,
  orderSimulationError: payload.errorCode,
});

const fetchOrdeCreditSuccess = (state, payload) => {
  return { ...state, orderCredit: payload };
};

const fetchOrdeCreditFailure = state => {
  return { ...state, orderCredit: null };
};

const fetchInvoiceDocumentsStart = state => {
  return {
    ...state,
    fetchInvoiceDocumentsIsLoading: true,
    fetchInvoiceDocumentsError: null,
  };
};

const fetchInvoiceDocumentsSuccess = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDocumentsIsLoading: false,
    invoiceDocuments: payload.invoiceDocuments,
  };
};

const fetchInvoiceDocumentsFailure = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDocumentsError: payload.errorCode,
    fetchInvoiceDocumentsIsLoading: false,
  };
};

const downloadInvoiceDocumentsStart = state => {
  return {
    ...state,
    downloadInvoiceDocumentsIsLoading: true,
    invoiceDocumentWasDownloaded: false,
    downloadInvoiceDocumentsError: null,
  };
};

const downloadInvoiceDocumentsSuccess = state => {
  return {
    ...state,
    downloadInvoiceDocumentsIsLoading: false,
    invoiceDocumentWasDownloaded: true,
  };
};

const downloadInvoiceDocumentsFailure = (state, payload) => {
  return {
    ...state,
    downloadInvoiceDocumentsError: payload.errorCode,
    downloadInvoiceDocumentsIsLoading: false,
    invoiceDocumentWasDownloaded: true,
  };
};

const fetchInvoiceRiskStart = state => ({
  ...state,
  invoiceRiskIsLoading: true,
  invoiceRiskError: null,
});

const fetchInvoiceRiskSuccess = (state, invoiceRisks) => {
  const orderInvoices = state.facturas.map(orderInvoice => {
    const invoiceRiskFound = invoiceRisks.find(
      ({ orderInvoiceId }) => orderInvoiceId === orderInvoice.id
    );
    const { level, probability } = invoiceRiskFound || {};
    return { ...orderInvoice, level, probability };
  });
  return {
    ...state,
    facturas: orderInvoices,
    invoiceRiskIsLoading: false,
  };
};

const fetchInvoiceRiskFailure = (state, payload) => ({
  ...state,
  invoiceRiskIsLoading: false,
  invoiceRiskError: payload.errorCode,
});

const updateInvoiceRisk = (state, payload) => {
  const { orderId, orderInvoiceId, level } = payload;
  const facturas = [...state.facturas];
  const idx = facturas.findIndex(of => {
    return of.id === +orderInvoiceId && of.orderId === +orderId;
  });
  if (idx >= 0) facturas[idx].level = level;
  return {
    ...state,
    facturas,
  };
};

const fetchOrderInvoiceHasAttachmentsStart = state => {
  return {
    ...state,
    fetchOrderInvoiceHasAttachmentsIsLoading: true,
    fetchOrderInvoiceHasAttachmentsError: null,
  };
};

const fetchOrderInvoiceHasAttachmentsSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoicesAttachments: payload,
    fetchOrderInvoiceHasAttachmentsIsLoading: true,
  };
};

const fetchOrderInvoiceHasAttachmentsFailure = (state, payload) => {
  return {
    ...state,
    fetchOrderInvoiceHasAttachmentsIsLoading: true,
    fetchOrderInvoiceHasAttachmentsError: payload.errorCode,
  };
};

const resetOrderInvoicePdfStatus = state => {
  return {
    ...state,
    orderInvoicePdfWasUploaded: false,
  };
};

const uploadOrderInvoicePdfStart = (state, payload) => {
  const { updatingInvoiceId } = payload;
  return {
    ...state,
    orderInvoicePdfIsUploading: true,
    orderInvoicePdfWasUploaded: false,
    uploadOrderInvoicePdfError: null,
    updatingInvoiceId,
  };
};

const uploadOrderInvoicePdfSuccess = (state, payload) => {
  const updatedOrderInvoicesAttachments = state.orderInvoicesAttachments.map(
    attachment => {
      if (attachment.orderInvoiceId === payload.orderInvoiceId) {
        return {
          ...attachment,
          id: payload.attachmentId,
          hasAttachment: true,
        };
      }
      return attachment;
    }
  );
  return {
    ...state,
    orderInvoicePdfIsUploading: false,
    orderInvoicePdfWasUploaded: true,
    uploadOrderInvoicePdfError: null,
    updatingInvoiceId: null,
    orderInvoicesAttachments: updatedOrderInvoicesAttachments,
  };
};

const uploadOrderInvoicePdfFailure = (state, payload) => {
  return {
    ...state,
    orderInvoicePdfIsUploading: false,
    orderInvoicePdfWasUploaded: false,
    uploadOrderInvoicePdfError: payload.errorCode,
    updatingInvoiceId: null,
  };
};

const deleteOrderInvoicePdfStart = (state, payload) => {
  const { deletingInvoiceId } = payload;
  return {
    ...state,
    orderInvoicePdfIsDeleting: true,
    orderInvoicePdfWasDeleted: false,
    deleteOrderInvoicePdfError: null,
    deletingInvoiceId,
  };
};

const deleteOrderInvoicePdfSuccess = (state, payload) => {
  const updatedOrderInvoicesAttachments = state.orderInvoicesAttachments.map(
    attachment => {
      if (attachment.orderInvoiceId === payload.deletedInvoiceId) {
        return {
          ...attachment,
          hasAttachment: false,
        };
      }
      return attachment;
    }
  );
  return {
    ...state,
    orderInvoicePdfIsDeleting: false,
    orderInvoicePdfWasDeleted: false,
    deleteOrderInvoicePdfError: null,
    deletingInvoiceId: null,
    orderInvoicesAttachments: updatedOrderInvoicesAttachments,
  };
};

const deleteOrderInvoicePdfFailure = (state, payload) => {
  return {
    ...state,
    orderInvoicePdfIsDeleting: false,
    orderInvoicePdfWasDeleted: false,
    deleteOrderInvoicePdfError: payload.errorCode,
    deletingInvoiceId: null,
  };
};

const downloadMarketInvoicerPdfGlobalStart = state => {
  return {
    ...state,
    orderInvoicePdfIsDownloading: true,
    marketInvoicerPdfGlobalClWasDownloaded: false,
  };
};

const downloadMarketInvoicerPdfGlobalSuccess = state => {
  return {
    ...state,
    orderInvoicePdfIsDownloading: false,
    marketInvoicerPdfGlobalClWasDownloaded: true,
  };
};

const downloadMarketInvoicerPdfGlobalFailure = (state, payload) => {
  return {
    ...state,
    orderInvoicePdfIsDownloading: false,
    marketInvoicerPdfGlobalClWasDownloaded: false,
    marketInvoicerPdfGlobalClError: payload.errorCode,
  };
};

const manageOrderInvoicesStart = state => ({
  ...state,
  manageOrderInvoicesIsLoading: true,
  manageOrderInvoicesIsDone: false,
  manageOrderInvoicesError: null,
});
const manageOrderInvoicesSuccess = state => ({
  ...state,
  manageOrderInvoicesIsLoading: false,
  manageOrderInvoicesIsDone: true,
});
const manageOrderInvoicesFailure = (state, payload) => ({
  ...state,
  manageOrderInvoicesIsLoading: false,
  manageOrderInvoicesError: payload.errorCode,
});

const fetchAmountInfoByOrderInvoiceIdsStart = state => {
  return { ...state, fetchAmountInfoIsLoading: true };
};
const fetchAmountInfoByOrderInvoiceIdsSuccess = (state, payload) => {
  const updatedOrderInvoices = state.facturas.map(orderInvoice => {
    const amountInfo = payload[orderInvoice.id] || {};
    return { ...orderInvoice, ...amountInfo };
  });
  return {
    ...state,
    facturas: updatedOrderInvoices,
    fetchAmountInfoIsLoading: false,
  };
};
const fetchAmountInfoByOrderInvoiceIdsFailure = state => {
  return { ...state, fetchAmountInfoIsLoading: false };
};
const fetchOrderInvoicesAttributesStart = state => {
  return { ...state, fetchOrderInvoicesAttributesIsLoading: true };
};
const fetchOrderInvoicesAttributesSuccess = (state, payload) => {
  const updatedOrderInvoices = state.facturas.map(orderInvoice => {
    const orderInvoiceAttributes = payload[orderInvoice.id] || {};
    return {
      ...orderInvoice,
      ...orderInvoiceAttributes,
    };
  });
  return {
    ...state,
    facturas: updatedOrderInvoices,
    fetchOrderInvoicesAttributesIsLoading: false,
  };
};

const fetchOrderInvoicesAttributesFailure = state => {
  return { ...state, fetchOrderInvoicesAttributesIsLoading: false };
};

const resetOrderFees = state => ({
  ...state,
  fees: {},
  restrictiveDataNewSimulation: null,
});

const getRestrictiveInformationNewSimulationStart = state => ({
  ...state,
  fetchRestrictiveDataNewSimulationIsLoading: true,
  restrictiveDataNewSimulation: null,
});
const getRestrictiveInformationNewSimulationSuccess = (state, payload) => ({
  ...state,
  fetchRestrictiveDataNewSimulationIsLoading: false,
  restrictiveDataNewSimulation: payload,
});
const getRestrictiveInformationNewSimulationFailure = state => ({
  ...state,
  fetchRestrictiveDataNewSimulationIsLoading: false,
});

const updateOrderInvoiceIsHoldStart = state => ({
  ...state,
  updateOrderInvoiceIsHoldError: null,
  orderInvoiceIsHoldIsUpdating: true,
});

const updateOrderInvoiceIsHoldSuccess = (state, payload) => {
  const updatedInvoices = state.facturas.map(orderInvoice => {
    if (orderInvoice.id === payload.orderInvoiceId) {
      return {
        ...orderInvoice,
        isHold: false,
      };
    }
    return orderInvoice;
  });

  return {
    ...state,
    facturas: updatedInvoices,
    orderInvoiceIsHoldIsUpdating: false,
    orderInvoiceIsHoldWasUpdated: true,
  };
};

const updateOrderInvoiceIsHoldFailure = (state, payload) => ({
  ...state,
  updateOrderInvoiceIsHoldError: payload.errorCode,
  orderInvoiceIsHoldIsUpdating: false,
});

const resetOrderInvoiceIsHoldStore = state => ({
  ...state,
  updateOrderInvoiceIsHoldError: null,
  orderInvoiceIsHoldIsUpdating: false,
  orderInvoiceIsHoldWasUpdated: false,
});

const fetchCountOrderCommentsStart = state => ({
  ...state,
  fetchCountOrderCommentsIsLoading: true,
  hasPendingComments: false,
  commentsCount: 0,
});

const fetchCountOrderCommentsSuccess = (state, payload) => ({
  ...state,
  fetchCountOrderCommentsIsLoading: false,
  hasPendingComments: payload.hasPendingComments,
  commentsCount: payload.commentsCount,
});

const fetchCountOrderCommentsFailure = state => ({
  ...state,
  fetchCountOrderCommentsIsLoading: false,
});

const fetchCountOrderResponsiblesStart = state => ({
  ...state,
  fetchCountOrderResponsiblesIsLoading: true,
  responsiblesCount: 0,
});

const fetchCountOrderResponsiblesSuccess = (state, payload) => ({
  ...state,
  fetchCountOrderResponsiblesIsLoading: false,
  responsiblesCount: payload.responsiblesCount,
});

const fetchCountOrderResponsiblesFailure = state => ({
  ...state,
  fetchCountOrderResponsiblesIsLoading: false,
});

const resetOperationDetailData = state => ({
  ...state,
  updatedOrder: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ORDER_START:
      return updateOrderStart(state);
    case UPDATE_ORDER_SUCCESS:
      return updateOrderSuccess(state, payload);
    case UPDATE_ORDER_FAILURE:
      return updateOrderFailure(state, payload);
    case FETCH_ORDERS_START:
      return fetchOrdersStart(state);
    case FETCH_ORDERS_START_PERSIST_ORDER:
      return fetchOrdersStartPersistOrder(state);
    case FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, payload);
    case FETCH_ORDERS_FAILURE:
      return fetchOrdersFailure(state, payload);
    case FETCH_FACTURAS_START:
      return fetchFacturasStart(state);
    case FETCH_FACTURAS_SUCCESS:
      return fetchFacturasSuccess(state, payload);
    case FETCH_FACTURAS_FAILURE:
      return fetchFacturasFailure(state, payload);
    case MAKE_CESSION_START:
      return makeCessionStart(state, payload);
    case FETCH_ORDER_FEES_START:
      return fetchOrderFeesStart(state);
    case FETCH_ORDER_FEES_SUCCESS:
      return fetchOrderFeesSuccess(state, payload);
    case FETCH_ORDER_FEES_FAILURE:
      return fetchOrderFeesFailure(state, payload);
    case UPDATE_ORDER_DETAILS_SUCCESS:
      return updateOrderDetailsSuccess(state, payload);
    case UPDATE_ORDER_DETAILS_FAILURE:
      return updateOrderDetailsFailure(state, payload);
    case HANDLE_SELECT_ORDER_CHANGE:
      return handleSelectOrderChange(state, payload);
    case HANDLE_SELECT_FACTURA_CHANGE:
      return handleSelectFacturaChange(state, payload);
    case HANDLE_SHOW_DICOM_DIALOG:
      return handleShowDicomDialog(state, payload);
    case RESET_DICOM_DIALOG:
      return resetDicomDialog(state);
    case UPDATE_FACTURA_START:
      return updateFacturaStart(state);
    case UPDATE_FACTURA_SUCCESS:
      return updateFacturaSuccess(state, payload);
    case UPDATE_FACTURA_FAILURE:
      return updateFacturaFailure(state, payload);
    case WS_UPDATE_FACTURA:
      return wsUpdateFactura(state, payload);
    case INVOICES_BULK_UPDATE_START:
      return invoicesBulkUpdateStart(state);
    case INVOICES_BULK_UPDATE_SUCCESS:
      return invoicesBulkUpdateSuccess(state, payload);
    case INVOICES_BULK_UPDATE_FAILURE:
      return invoicesBulkUpdateFailure(state, payload);
    case CESSION_UPDATE:
      return cessionUpdate(state, payload);
    case WS_UPDATE_INVOICE:
      return wsUpdateInvoice(state, payload);
    case LOAD_INVOICE_PDF_START:
      return loadInvoicePdfStart(state, payload);
    case LOAD_INVOICE_PDF_SUCCESS:
      return loadInvoicePdfSuccess(state);
    case LOAD_INVOICE_PDF_FAILURE:
      return loadInvoicePdfFailure(state);
    case SET_ORDERS_ACTIVE_FILTERS_BY_DEFAULT:
      return setOrdersActiveFilterByDefault(state, payload);
    case FETCH_SELECTED_ORDER_START:
      return fetchSelectedOrderStart(state);
    case FETCH_SELECTED_ORDER_SUCCESS:
      return fetchSelectedOrderSuccess(state, payload);
    case FETCH_SELECTED_ORDER_FAILURE:
      return fetchSelectedOrderFailure(state, payload);
    case GET_PENDING_INVOICES_START:
      return getPendingInvoicesStart(state);
    case GET_PENDING_INVOICES_SUCCESS:
      return getPendingInvoicesSuccess(state, payload);
    case GET_PENDING_INVOICES_FAILURE:
      return getPendingInvoicesFailure(state, payload);
    case UPDATE_PENDING_INVOICES_START:
      return updatePendingInvoicesStart(state);
    case UPDATE_PENDING_INVOICES_SUCCESS:
      return updatePendingInvoicesSuccess(state, payload);
    case UPDATE_PENDING_INVOICES_FAILURE:
      return updatePendingInvoicesFailure(state, payload);
    case MATCH_PENDING_INVOICES_START:
      return matchPendingInvoicesStart(state);
    case MATCH_PENDING_INVOICES_SUCCESS:
      return matchPendingInvoicesSuccess(state, payload);
    case MATCH_PENDING_INVOICES_FAILURE:
      return matchPendingInvoicesFailure(state, payload);
    case UPDATE_STATUS_ORDER_TABLE:
      return updateStatusOrderTable(state, payload);
    case HANDLE_ORDER_SELECTION_CHANGE:
      return handleOrderSelectionChange(state, payload);
    case RESET_SELECTED_ORDERS:
      return resetSelectedOrders(state);
    case REGISTER_ORDER_CREDIT_START:
      return registerOrderCreditStart(state);
    case REGISTER_ORDER_CREDIT_SUCCESS:
      return registerOrderCreditSuccess(state, payload);
    case REGISTER_ORDER_CREDIT_FAILURE:
      return registerOrderCreditFailure(state);
    case FETCH_ORDER_CREDIT_SUCCESS:
      return fetchOrdeCreditSuccess(state, payload);
    case FETCH_ORDER_CREDIT_FAILURE:
      return fetchOrdeCreditFailure(state);
    case RESET_CREATE_ORDER:
      return resetCreaterOrder(state);
    case CREATE_ORDER_START:
      return createOrderStart(state);
    case CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, payload);
    case CREATE_ORDER_FAILURE:
      return createOrderFailure(state, payload);
    case RESET_CREATE_ORDER_ERROR:
      return resetCreateOrderError(state);
    case BULK_INVOICES_UPDATE_CSV_START:
      return bulkInvoicesUpdateCsvStart(state);
    case BULK_INVOICES_UPDATE_CSV_SUCCESS:
      return bulkInvoicesUpdateCsvSuccess(state, payload);
    case BULK_INVOICES_UPDATE_CSV_FAILURE:
      return bulkInvoicesUpdateCsvFailure(state);
    case FETCH_ORDERS_ID_BY_PAYER_RUT_START:
      return fetchOrdersIdByPayerRutStart(state);
    case FETCH_ORDERS_ID_BY_PAYER_RUT_SUCCESS:
      return fetchOrdersIdByPayerRutSuccess(state, payload);
    case FETCH_ORDERS_ID_BY_PAYER_RUT_FAILURE:
      return fetchOrdersIdByPayerRutFailure(state, payload);
    case FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_START:
      return fetchIdAndFolioFacturasByOrderIdStart(state);
    case FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_SUCCESS:
      return fetchIdAndFolioFacturasByOrderIdSuccess(state, payload);
    case FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_FAILURE:
      return fetchIdAndFolioFacturasByOrderIdFailure(state);
    case RESET_STATE_REPLACED_INVOICE:
      return resetStateReplacedInvoice(state);
    case VERIFY_INVOICE:
      return verifyInvoice(state, payload);
    case VERIFY_INVOICE_START:
      return verifyInvoiceStart(state);
    case VERIFY_INVOICE_SUCCESS:
      return verifyInvoiceSuccess(state, payload);
    case VERIFY_INVOICE_FAILURE:
      return verifyInvoiceFailure(state);
    case WS_UPDATE_BULK_INVOICES:
      return wsUpdateBulkInvoices(state, payload);
    case LOAD_EXTERNAL_PDF_START:
      return loadExternalPdfStart(state, payload);
    case LOAD_EXTERNAL_PDF_SUCCESS:
      return loadExternalPdfSuccess(state, payload);
    case LOAD_EXTERNAL_PDF_FAILURE:
      return loadExternalPdfFailure(state, payload);
    case FETCH_TRANSFER_AMOUNT_PYME:
      return fetchTransferAmountPyme(state, payload);
    case REHYDRATE:
      return rehydrate(payload);
    case ADD_FUND_FINANCING_START:
      return addOrderInvoiceRateStart(state);
    case ADD_FUND_FINANCING_SUCCESS:
      return addOrderInvoiceRateSuccess(state);
    case ADD_FUND_FINANCING_FAILURE:
      return addOrderInvoiceRateFailure(state);
    case REGISTER_DEBT_PERIOD_START:
      return setLoadingFundFinancing(state, true);
    case REGISTER_DEBT_PERIOD_SUCCESS:
      return setLoadingFundFinancing(state);
    case REGISTER_DEBT_PERIOD_FAILURE:
      return setLoadingFundFinancing(state);
    case UPDATE_DEBT_PERIOD_START:
      return setLoadingFundFinancing(state, true);
    case UPDATE_DEBT_PERIOD_SUCCESS:
      return setLoadingFundFinancing(state);
    case UPDATE_DEBT_PERIOD_FAILURE:
      return setLoadingFundFinancing(state);
    case DELETE_DEBT_PERIOD_START:
      return setLoadingFundFinancing(state, true);
    case DELETE_DEBT_PERIOD_SUCCESS:
      return setLoadingFundFinancing(state);
    case DELETE_DEBT_PERIOD_FAILURE:
      return setLoadingFundFinancing(state);
    case BULK_INVOICES_FUNDS_CSV_START:
      return bulkInvoicesFundCsvStart(state);
    case BULK_INVOICES_FUNDS_CSV_SUCCESS:
      return bulkInvoicesFundCsvSuccess(state, payload);
    case BULK_INVOICES_FUNDS_CSV_FAILURE:
      return bulkInvoicesFundCsvFailure(state);
    case RESET_BULK_CSV_FUNDS_ERRORS:
      return resetBulkCsvInvoices(state);
    case FETCH_SUMMARY_OPERATION_START:
      return fetchSummaryOperationStart(state);
    case FETCH_SUMMARY_OPERATION_SUCCESS:
      return fetchSummaryOperationSuccess(state, payload);
    case FETCH_SUMMARY_OPERATION_FAILURE:
      return fetchSummaryOperationFailure(state);
    case FETCH_FUND_SUMMARY_OPERATION_START:
      return fetchFundSummaryOperationStart(state);
    case FETCH_FUND_SUMMARY_OPERATION_SUCCESS:
      return fetchFundSummaryOperationSuccess(state, payload);
    case FETCH_FUND_SUMMARY_OPERATION_FAILURE:
      return fetchFundSummaryOperationFailure(state);
    case UPDATE_ORDER_INVOICE_FUND_START:
      return updateOrderInvoiceFundSetLoading(state, true);
    case UPDATE_ORDER_INVOICE_FUND_SUCCESS:
      return updateOrderInvoiceFundSetLoading(state);
    case UPDATE_ORDER_INVOICE_FUND_FAILURE:
      return updateOrderInvoiceFundSetLoading(state);
    case FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_START:
      return fetchTotalOrderTransferredBySetLoading(state, true);
    case FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_SUCCESS:
      return fetchTotalOrderTransferredBySuccess(state, payload);
    case FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_FAILURE:
      return fetchTotalOrderTransferredBySetLoading(state);
    case INVOICE_BANK_ACCOUNT_SUCCESS:
      return invoiceBankAccountSuccess(state, payload);
    case RESET_STATE_UPDATE_ORDER:
      return resetStateUpdateOrder(state);
    case RESET_STATE_UPDATE_INVOICE:
      return resetStateUpdateInvoice(state);
    case RESET_STATE_UPLOADING_INVOICE:
      return resetStateUploadingInvoice(state);
    case DOWNLOAD_AEC_INVOICE_START:
      return setLoadingDownloadAecInvoice(state, true);
    case DOWNLOAD_AEC_INVOICE_SUCCESS:
      return setLoadingDownloadAecInvoice(state);
    case DOWNLOAD_AEC_INVOICE_FAILURE:
      return setLoadingDownloadAecInvoice(state);
    case UPDATE_ORDER_STATUS_START:
      return updateOrderStatusStart(state);
    case UPDATE_ORDER_STATUS_SUCCESS:
      return updateOrderStatusSuccess(state, payload);
    case UPDATE_ORDER_STATUS_FAILURE:
      return updateOrderStatusFailure(state, payload);
    case RESET_STATE_FETCH_ORDER_IDS_BY_PAYER_RUT:
      return resetStateFetchOrdersIdByPayerRut(state);
    case RESET_STATE_UPDATE_BULK_INVOICES:
      return resetStateUpdateBulkInvoices(state);
    case FETCH_INVOICE_DOCUMENTS_START:
      return fetchInvoiceDocumentsStart(state);
    case FETCH_INVOICE_DOCUMENTS_SUCCESS:
      return fetchInvoiceDocumentsSuccess(state, payload);
    case FETCH_INVOICE_DOCUMENTS_FAILURE:
      return fetchInvoiceDocumentsFailure(state, payload);
    case DOWNLOAD_INVOICE_DOCUMENTS_START:
      return downloadInvoiceDocumentsStart(state);
    case DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS:
      return downloadInvoiceDocumentsSuccess(state);
    case DOWNLOAD_INVOICE_DOCUMENTS_FAILURE:
      return downloadInvoiceDocumentsFailure(state, payload);
    case FETCH_SIMULATION_FOR_ORDER_START:
      return fetchSimulationOperationStart(state);
    case FETCH_SIMULATION_FOR_ORDER_SUCCESS:
      return fetchSimulationOperationSuccess(state, payload);
    case FETCH_SIMULATION_FOR_ORDER_FAILURE:
      return fetchSimulationOperationFailure(state, payload);
    case FETCH_ORDER_INVOICE_RISK_START:
      return fetchInvoiceRiskStart(state);
    case FETCH_ORDER_INVOICE_RISK_SUCCESS:
      return fetchInvoiceRiskSuccess(state, payload);
    case FETCH_ORDER_INVOICE_RISK_FAILURE:
      return fetchInvoiceRiskFailure(state, payload);
    case UPDATE_INVOICE_RISK:
      return updateInvoiceRisk(state, payload);
    case FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START:
      return fetchOrderInvoiceHasAttachmentsStart(state);
    case FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS:
      return fetchOrderInvoiceHasAttachmentsSuccess(state, payload);
    case FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE:
      return fetchOrderInvoiceHasAttachmentsFailure(state, payload);
    case UPLOAD_ORDER_INVOICE_PDF_START:
      return uploadOrderInvoicePdfStart(state, payload);
    case UPLOAD_ORDER_INVOICE_PDF_SUCCESS:
      return uploadOrderInvoicePdfSuccess(state, payload);
    case UPLOAD_ORDER_INVOICE_PDF_FAILURE:
      return uploadOrderInvoicePdfFailure(state, payload);
    case DELETE_ORDER_INVOICE_PDF_START:
      return deleteOrderInvoicePdfStart(state, payload);
    case DELETE_ORDER_INVOICE_PDF_SUCCESS:
      return deleteOrderInvoicePdfSuccess(state, payload);
    case DELETE_ORDER_INVOICE_PDF_FAILURE:
      return deleteOrderInvoicePdfFailure(state, payload);
    case RESET_ORDER_INVOICE_PDF_UPLOAD_STATUS:
      return resetOrderInvoicePdfStatus(state);
    case DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_START:
      return downloadMarketInvoicerPdfGlobalStart(state);
    case DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_SUCCESS:
      return downloadMarketInvoicerPdfGlobalSuccess(state);
    case DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_FAILURE:
      return downloadMarketInvoicerPdfGlobalFailure(state, payload);
    case MANAGE_ORDER_INVOICES_START:
      return manageOrderInvoicesStart(state);
    case MANAGE_ORDER_INVOICES_SUCCESS:
      return manageOrderInvoicesSuccess(state);
    case MANAGE_ORDER_INVOICES_FAILURE:
      return manageOrderInvoicesFailure(state, payload);
    case FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_START:
      return fetchAmountInfoByOrderInvoiceIdsStart(state);
    case FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_SUCCESS:
      return fetchAmountInfoByOrderInvoiceIdsSuccess(state, payload);
    case FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_FAILURE:
      return fetchAmountInfoByOrderInvoiceIdsFailure(state);
    case FETCH_ORDER_INVOICES_ATTRIBUTES_START:
      return fetchOrderInvoicesAttributesStart(state);
    case FETCH_ORDER_INVOICES_ATTRIBUTES_SUCCESS:
      return fetchOrderInvoicesAttributesSuccess(state, payload);
    case FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE:
      return fetchOrderInvoicesAttributesFailure(state);
    case RESET_ORDER_FEES:
      return resetOrderFees(state);
    case GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_START:
      return getRestrictiveInformationNewSimulationStart(state);
    case GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_SUCCESS:
      return getRestrictiveInformationNewSimulationSuccess(state, payload);
    case GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_FAILURE:
      return getRestrictiveInformationNewSimulationFailure(state);
    case UPDATE_ORDER_INVOICE_IS_HOLD_START:
      return updateOrderInvoiceIsHoldStart(state);
    case UPDATE_ORDER_INVOICE_IS_HOLD_SUCCESS:
      return updateOrderInvoiceIsHoldSuccess(state, payload);
    case UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE:
      return updateOrderInvoiceIsHoldFailure(state, payload);
    case RESET_ORDER_INVOICE_IS_HOLD_STORE:
      return resetOrderInvoiceIsHoldStore(state);
    case FETCH_COUNT_ORDER_COMMENTS_START:
      return fetchCountOrderCommentsStart(state);
    case FETCH_COUNT_ORDER_COMMENTS_SUCCESS:
      return fetchCountOrderCommentsSuccess(state, payload);
    case FETCH_COUNT_ORDER_COMMENTS_FAILURE:
      return fetchCountOrderCommentsFailure(state);
    case FETCH_COUNT_ORDER_RESPONSIBLES_START:
      return fetchCountOrderResponsiblesStart(state);
    case FETCH_COUNT_ORDER_RESPONSIBLES_SUCCESS:
      return fetchCountOrderResponsiblesSuccess(state, payload);
    case FETCH_COUNT_ORDER_RESPONSIBLES_FAILURE:
      return fetchCountOrderResponsiblesFailure(state);
    case RESET_FETCH_SUMMARY_OPERATION:
      return resetSummaryOperation(state);
    case RESET_FETCH_INVOICES:
      return resetFetchFacturas(state);
    case RESET_MATCH_PENDING_INVOICES:
      return resetMatchPendingInvoices(state);
    case RESET_OPERATION_DETAIL_DATA:
      return resetOperationDetailData(state);
    default:
      return state;
  }
};
