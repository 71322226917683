import React from 'react';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import ReactDropzoneUploader from 'react-dropzone-uploader';
import { Typography, makeStyles, Button, styled } from '@material-ui/core';
import Preview from './Preview';
import Layout from './Layout';
import UploadIcon from '../../icons/UploadIcon';

const useStyles = makeStyles({
  dropzone: {
    background: '#F7F5FA',
    border: '1px dashed #A7A4B2',
    borderRadius: 8,
    width: '100%',
    height: 176,
    textAlign: 'center',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#44414C',
      borderRadius: 4,
    },
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
  },
  inputLabelWithFiles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  submitButtonContainer: {
    justifyContent: 'center',
    marginBottom: 0,
  },
  button: {
    marginBottom: 30,
  },
});

const DropzoneLabel = styled(Typography)({
  size: 12,
  textAlign: 'center',
  color: '#8D8A96',
});

const Input = () => {
  return (
    <>
      <UploadIcon
        style={{
          width: 40,
          height: 40,
        }}
        color="#8D8A96"
      />
      <DropzoneLabel>
        Arrastra el documento aquí o haz click para cargarlo
      </DropzoneLabel>
    </>
  );
};

const Dropzone = ({
  multiple,
  accept,
  maxFiles,
  handleSubmit,
  submitButtonComponent,
  ...otherDropzoneProps
}) => {
  const classes = useStyles();

  const handleSubmitFiles = (files, allFiles) => {
    if (handleSubmit) {
      handleSubmit(files);
    }
    allFiles.forEach(f => f.remove());
  };

  return (
    <ReactDropzoneUploader
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherDropzoneProps}
      LayoutComponent={multiple ? Layout : null}
      SubmitButtonComponent={({ onSubmit, disabled, files = [] }) => {
        return (
          <>
            {submitButtonComponent ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onSubmit(
                    files.filter(f =>
                      ['headers_received', 'done'].includes(f.meta.status)
                    )
                  );
                }}
                disabled={disabled}
                className={classes.button}
              >
                {files.length > 1 ? 'Subir archivos' : 'Subir archivo'}
              </Button>
            ) : null}
          </>
        );
      }}
      inputContent={Input}
      inputWithFilesContent={Input}
      PreviewComponent={Preview}
      onSubmit={handleSubmitFiles}
      classNames={{
        dropzone: classes.dropzone,
        inputLabel: classes.inputLabel,
        inputLabelWithFiles: classes.inputLabelWithFiles,
        submitButtonContainer: classes.submitButtonContainer,
      }}
      accept={accept}
      maxFiles={maxFiles}
    />
  );
};

Dropzone.defaultProps = {
  handleSubmit: null,
  multiple: false,
  submitButtonComponent: false,
};

Dropzone.propTypes = {
  accept: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func,
  multiple: PropTypes.bool,
  submitButtonComponent: PropTypes.bool,
};

export default Dropzone;
