import { Typography, makeStyles, styled } from '@material-ui/core';
import { palette } from '../../../../theme/palette';

export const useStyles = makeStyles({
  buttonBulkUpdate: {
    marginRight: 38,
  },
});

export const Title = styled(Typography)({
  fontSize: 20,
  color: palette.payments.grey1,
  fontWeight: 'bold',
});
