import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { useInvoiceTableReloadRef } from '../../ui/PayrollInvoices/InvoicesTable/store';
import bulkUpdateInvoicesService from '../services/bulkUpdateInvoicesService';

export const useBulkUpdateInvoices = () => {
  const dispatch = useDispatch();
  const invoiceTableReloadRef = useInvoiceTableReloadRef();
  const {
    isLoading: bulkUpdateInvoicesIsUpdating,
    isSuccess: bulkUpdateInvoicesIsSuccess,
    data: bulkUpdateInvoicesData,
    mutate: bulkUpdateInvoices,
    error: bulkUpdateInvoicesError,
  } = useMutation(
    (bulkUpdateData, country, { notCalculateSurplus = null } = {}) => {
      return bulkUpdateInvoicesService(
        bulkUpdateData,
        country,
        notCalculateSurplus
      );
    },
    {
      onSuccess: (_res, data) => {
        const { failedOrderInvoices, onSuccessCallback = () => undefined } =
          data;
        if (failedOrderInvoices) {
          dispatch(
            enqueueSnackbar(
              `${failedOrderInvoices} factura${
                failedOrderInvoices > 1 ? 's' : ''
              } tienen emisor/pagador en blocklist`,
              {
                variant: 'error',
              }
            )
          );
        }
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.INVOICES_BULK_UPDATE_SUCCESS, {
            variant: 'success',
          })
        );
        invoiceTableReloadRef();
        onSuccessCallback();
      },
    }
  );

  return {
    bulkUpdateInvoicesIsUpdating,
    bulkUpdateInvoicesIsSuccess,
    bulkUpdateInvoicesError,
    bulkUpdateInvoicesData,
    bulkUpdateInvoices,
  };
};

export default useBulkUpdateInvoices;
