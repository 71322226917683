import React, { useState, ChangeEvent, FormEvent, useRef, useEffect } from 'react';
import {
  Paper,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SEARCHBAR_TIMEOUT } from '../../../../helpers/Constants';
import { useStyles } from './styles';

interface searchProps {
    field: string,
    value: string,
}

interface searchOneProps{
  field: string
}

interface FieldProps {
  title: string,
  field: string,
  type: string,
}

interface Props {
    handleChangeInput: (arg : searchProps | searchOneProps) => void,
    placeholder: string,
    disabled?: boolean,
    fields: FieldProps[],
    selectedOption: FieldProps,
    size?: 'normal' | 'lg' | 'xl', 
}

const SearchBar = ({
  handleChangeInput,
  placeholder,
  disabled=false,
  fields,
  selectedOption,
  size='normal',
}: Props) => {
  const classes = useStyles();
  const menuFields = fields.length ? fields : [];
  const initFields = menuFields.length ? menuFields[0] : {title: '', field: '', type: ''};
  const [field, setField] = useState(initFields);
  const [valueInput, setValueInput] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [search, setSearch] = useState(false);

  const searchBarWidth = {
    normal: 320,
    lg: 420,
    xl: 500,
  };

  useEffect(() => {
    setValueInput('');
    setField(selectedOption);
  }, []);

  useEffect(() => {
    if (search) {
      const timeOutId = setTimeout(() => {
        if (valueInput !== '') {
          const targetValue = valueInput.trim();
        handleChangeInput({
          value: targetValue,
          field: field.field,
        });
      }}, SEARCHBAR_TIMEOUT);
      setSearch(false)
      return () => clearTimeout(timeOutId);
    }
  }, [valueInput]);

  const handleOnSubmitPaper = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const targetValue = valueInput.trim();
    if (handleChangeInput)
      handleChangeInput({
        value: targetValue,
        field: field.field,
      });
  };

  const clearInput = () => {
    setValueInput('');
    handleChangeInput({ field: field.field, value: ''});
  };

  const handleChangeField = (value: FieldProps) => {
    setAnchorEl(null);
    setField(value);
    setValueInput('');
    handleChangeInput({ field: field.field});
  };

  const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setValueInput(value);
    setSearch(true)
  };

  return (
    <Paper
      component="form"
      onSubmit={handleOnSubmitPaper}
      elevation={0}
      className={classes.root}
      style={{
        width: searchBarWidth[size],
      }}
    >
      {menuFields.length > 1 ? (
        <Button
          onClick={e => setAnchorEl(e.currentTarget)}
          className={classes.iconButton}
          aria-label="search"
        >
          <Typography variant="subtitle2">{field.title}</Typography>
          <ExpandMoreIcon />
        </Button>
      ) : (
        <Typography variant="subtitle2" className={classes.textIconAlone}>
          {field.title}
        </Typography>
      )}

      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem />

      {menuFields.length > 1 && (
        <Menu
          id="fields-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
        >
          {menuFields.map((field: FieldProps )=> (
              <MenuItem
                key={field.title}
                onClick={() => handleChangeField(field)}
              >
                <Typography variant="caption" color="textSecondary">
                  {field.title}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}

      <InputBase
        type="string"
        disabled={disabled}
        value={valueInput}
        className={classes.input}
        placeholder={placeholder || 'Buscar'}
        onChange={changeInput}
        inputProps={{
          'aria-label': 'Buscar',
        }}
      />

      <IconButton
        onClick={clearInput}
        className={classes.iconButton}
        aria-label="search"
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
