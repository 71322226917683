import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TodayOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Text } from '../../../commons/formatters';
import LinearProgress from '../../../commons/components/LinearProgress';
import {
  ContainerGrid,
  PaddedGrid,
  DatePaddedGrid,
  CreditAmountGrid,
  IconChipGrid,
  CreditGrid,
  FullHeightGrid,
  CreditAmountText,
  TitleGrid,
  BottomSpacedGrid,
  CustomText,
  IconChipContainer,
} from './styles';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import { COUNTRY_CODE_CL } from '../../../../helpers/Constants';
import { dateToDDMMYYYY } from '../../../../funds/commons/utils';

const PayrollDatesAndAmounts = ({ orderSummary }) => {
  const { country } = useSelector(state => state.config);
  const {
    cessionAmount,
    cessionPercentage,
    supplierPaymentDate,
    supplierAmount,
    supplierPercentage,
    expirationDate,
    creditLineBalance: { available, total, used } = {},
  } = orderSummary;

  const formattedSupplierPaymentDate = supplierPaymentDate
    ? dateToDDMMYYYY(supplierPaymentDate)
    : '-';
  const formattedExpirationDate = expirationDate
    ? dateToDDMMYYYY(expirationDate)
    : '-';

  return (
    <ContainerGrid container>
      <DatePaddedGrid item>
        <Grid container>
          <IconChipGrid item>
            <IconChipContainer
              container
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <Grid item>
                <TodayOutlined />
              </Grid>
            </IconChipContainer>
          </IconChipGrid>
          <Grid item>
            <FullHeightGrid
              container
              display="flex"
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <TitleGrid item>
                <Text variant="body2">
                  Fecha pago <br />a proveedores
                </Text>
              </TitleGrid>
              <Grid item>
                <Text variant="body2" fontWeight="bold">
                  {formattedSupplierPaymentDate}
                </Text>
              </Grid>
            </FullHeightGrid>
          </Grid>
        </Grid>
      </DatePaddedGrid>
      <DatePaddedGrid item>
        <Grid container>
          <IconChipGrid item>
            <IconChipContainer
              container
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <Grid item>
                <TodayOutlined />
              </Grid>
            </IconChipContainer>
          </IconChipGrid>
          <Grid item>
            <FullHeightGrid
              container
              display="flex"
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <TitleGrid item>
                <Text variant="body2">
                  Fecha que vence <br />
                  el financiamiento
                </Text>
              </TitleGrid>
              <Grid item>
                <Text variant="body2" fontWeight="bold">
                  {formattedExpirationDate}
                </Text>
              </Grid>
            </FullHeightGrid>
          </Grid>
        </Grid>
      </DatePaddedGrid>
      <PaddedGrid item>
        <Grid container display="flex" direction="column">
          <TitleGrid item>
            <CustomText fontWeight="bold">Pago a proveedores</CustomText>
          </TitleGrid>
          <BottomSpacedGrid justifyContent="space-between" item container>
            <Grid item>
              <Text variant="subtitle1" component="div">
                <CountryFormatHelper
                  value={supplierAmount}
                  countryId={country}
                  variant="currency"
                />
              </Text>
            </Grid>
            <Grid item>
              <Text variant="subtitle1" component="div">
                <CountryFormatHelper
                  value={Math.round(supplierPercentage)}
                  countryId={country}
                  variant="percentage"
                />
              </Text>
            </Grid>
          </BottomSpacedGrid>
          <Grid item>
            <LinearProgress value={supplierPercentage} />
          </Grid>
        </Grid>
      </PaddedGrid>
      {country === COUNTRY_CODE_CL && (
        <PaddedGrid item>
          <Grid container display="flex" direction="column">
            <TitleGrid item>
              <CustomText fontWeight="bold">Monto cedido</CustomText>
            </TitleGrid>
            <BottomSpacedGrid justifyContent="space-between" item container>
              <Grid item>
                <Text variant="subtitle1" component="div">
                  <CountryFormatHelper
                    value={cessionAmount}
                    countryId={country}
                    variant="currency"
                  />
                </Text>
              </Grid>
              <Grid item>
                <Text variant="subtitle1" component="div">
                  <CountryFormatHelper
                    value={Math.round(cessionPercentage)}
                    countryId={country}
                    variant="percentage"
                  />
                </Text>
              </Grid>
            </BottomSpacedGrid>
            <Grid item>
              <LinearProgress value={cessionPercentage} />
            </Grid>
          </Grid>
        </PaddedGrid>
      )}
      <CreditAmountGrid item>
        <Grid container display="flex" direction="column">
          <BottomSpacedGrid item>
            <CustomText fontWeight="bold">Crédito disponible</CustomText>
          </BottomSpacedGrid>
          <BottomSpacedGrid item>
            <CreditAmountText fontWeight="bold" component="div">
              <CountryFormatHelper
                value={available}
                countryId={country}
                variant="currency"
              />
            </CreditAmountText>
          </BottomSpacedGrid>
          <BottomSpacedGrid item>
            <Grid item>
              <LinearProgress value={total} />
            </Grid>
          </BottomSpacedGrid>
          <Grid item container>
            <CreditGrid item>
              <Grid container direction="column">
                <TitleGrid item>
                  <Text variant="body2">Crédito utilizado</Text>
                </TitleGrid>
                <Grid item>
                  <Text fontWeight="bold" variant="body2" component="div">
                    <CountryFormatHelper
                      value={used}
                      countryId={country}
                      variant="currency"
                    />
                  </Text>
                </Grid>
              </Grid>
            </CreditGrid>
            <Grid item>
              <Grid container direction="column">
                <TitleGrid item>
                  <Text variant="body2">Línea de crédito</Text>
                </TitleGrid>
                <Grid item container justifyContent="flex-end">
                  <Text fontWeight="bold" variant="body2" component="div">
                    <CountryFormatHelper
                      value={total}
                      countryId={country}
                      variant="currency"
                    />
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CreditAmountGrid>
    </ContainerGrid>
  );
};

PayrollDatesAndAmounts.propTypes = {
  orderSummary: PropTypes.shape({
    cessionAmount: PropTypes.number,
    cessionPercentage: PropTypes.number,
    supplierPaymentDate: PropTypes.string,
    supplierAmount: PropTypes.number,
    supplierPercentage: PropTypes.number,
    expirationDate: PropTypes.string,
    creditLineBalance: {
      creditAmount: PropTypes.number,
      balance: PropTypes.number,
    },
  }).isRequired,
};

export default PayrollDatesAndAmounts;
