import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const updateOrderCommentService = async (orderId, commentId, { deleted }) => {
  try {
    const { data } = await axios.put(
      `/api/order/${orderId}/comment/${commentId}`,
      { deleted }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { updateOrderCommentService };
