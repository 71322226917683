import { ButtonBase, styled } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../commons/icons';
import { palette } from '../../../../../theme/palette';

const ExpandButton = styled(ButtonBase)(({ theme }) => ({
  background: theme.palette.payments.softBlue,
  padding: '13px 11px',
  borderRadius: 4,
}));

const iconStyles = {
  width: 12,
  height: 'auto',
  '& path': {
    fill: palette.primary.main,
  },
};

const ExpandCardButton = ({ expanded, onClick }) => {
  return (
    <ExpandButton disableRipple expanded={expanded} onClick={onClick}>
      {expanded ? (
        <ArrowUpIcon style={iconStyles} />
      ) : (
        <ArrowDownIcon style={iconStyles} />
      )}
    </ExpandButton>
  );
};

ExpandCardButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExpandCardButton;
