import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { persist, devtools } from 'zustand/middleware';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const persistence = (store, config) => persist(store, config);

const createStore = pipe(persistence, immer, devtools, create);

export default createStore;
