/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../translationKeys';
import { useSelectedInvoices } from '../store';

const useHandleCessionDialog = () => {
  const dispatch = useDispatch();
  const selectedInvoices = useSelectedInvoices();
  const [businessesWithPfx, setBusinessesWithPfx] = useState([]);
  const [showCessionDialog, setShowCessionDialog] = useState(false);

  useEffect(() => {
    if (selectedInvoices.length) {
      const invoicesWithPfx = selectedInvoices.filter(
        invoice => invoice.issuerHasPfxCertificate === true
      );
      setBusinessesWithPfx(invoicesWithPfx);
    }
  }, [selectedInvoices]);

  const handleShowCessionDialog = () => {
    setShowCessionDialog(true);
  };

  const handleCloseCessionDialog = () => {
    setShowCessionDialog(false);
  };

  const handleCession = () => {
    // eslint-disable-next-line no-constant-condition
    if (businessesWithPfx.length > 0) {
      handleShowCessionDialog();
    }
    dispatch(
      enqueueSnackbar(t13s.ERROR.NO_INVOICES_WITH_PFX_FOUND, {
        variant: 'error',
      })
    );
  };

  return {
    showCessionDialog,
    handleCloseCessionDialog,
    handleCession,
  };
};
export default useHandleCessionDialog;
