import { Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import {
  WALLET_REQUEST_COMMENTS,
  WALLET_REQUEST_STATUS,
} from '../../helpers/Constants';
import BaseDialog from '../dialogs/BaseDialog';
import CheckButton from './CheckButton';

const useStyles = makeStyles({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 5,
  },
});

const WalletRequestStatusDialog = ({
  open,
  onClose,
  onSubmit,
  selectedWalletRequest,
}) => {
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(
    selectedWalletRequest?.status || 'PENDING'
  );
  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState(selectedWalletRequest?.amount || 0);

  const classes = useStyles();

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = () => {
    const updatedRequest = {
      status,
      comment,
      amount,
    };
    onSubmit(updatedRequest);
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={onClose}
      title="Cambiar estado de la solicitud"
    >
      <Grid item xs={12}>
        <ValidatorForm onSubmit={handleSubmit}>
          <SelectValidator
            fullWidth
            variant="outlined"
            label="Estado"
            name="status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            validators={['required']}
            errorMessages={['Campo requerido']}
          >
            {WALLET_REQUEST_STATUS.map(({ value, label }) => {
              return <MenuItem value={value}>{label}</MenuItem>;
            })}
          </SelectValidator>
          {status === 'REJECTED' && (
            <SelectValidator
              fullWidth
              variant="outlined"
              label="Comentarios"
              name="comment"
              value={comment}
              onChange={e => setComment(e.target.value)}
              validators={['required']}
              errorMessages={['Campo requerido']}
            >
              {WALLET_REQUEST_COMMENTS.map(comment => (
                <MenuItem value={comment} key={comment}>
                  {comment}
                </MenuItem>
              ))}
            </SelectValidator>
          )}

          {status === 'ACCOMPLISHED' && (
            <TextValidator
              variant="outlined"
              fullWidth
              id="wallet-request-amount"
              label="Monto"
              InputProps={{ step: 1 }}
              type="number"
              name="walletRequestAmount"
              value={Number(amount).toFixed(0)}
              aria-describedby="walletRequestAmount"
              onChange={e => setAmount(e.target.value)}
              validators={['isPositive']}
              errorMessages={['Valor debe ser mayor a 0']}
            />
          )}
          <Grid item xs={12} className={classes.justifyCenter}>
            <CheckButton
              check={checked}
              handleCheck={handleCheckboxChange}
              labelButton="Guardar"
            />
          </Grid>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

WalletRequestStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedWalletRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
};

WalletRequestStatusDialog.defaultProps = {
  selectedWalletRequest: null,
};

export default WalletRequestStatusDialog;
