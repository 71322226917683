import { getRangeOfDays } from '../../../../commons/utils';

export const updateByCustomExpirationDateUseCase = ({
  currentSelectorState,
  response,
  updateStaticFieldsOfReceivers,
}) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const currentReceiver = currentSelectorState[receiverIdentifier];
    acc[receiverIdentifier] = {
      ...currentReceiver,
      value: currentReceiver.value.map(doc => {
        if (updateStaticFieldsOfReceivers) {
          return doc;
        }

        return {
          ...doc,
          expirationDate: +getRangeOfDays(response[doc.id]?.expirationDate),
        };
      }),
    };

    return acc;
  }, {});
};
