import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Clear';
import SuccessICon from '@material-ui/icons/Check';

const useStyles = makeStyles({
  infoBusinessContainer: {
    minWidth: props => props.minWidth || null,
    maxWidth: props => props.maxWidth || null,
    marginTop: props => props.marginTop || null,
    marginBottom: props => props.marginBottom || 32,
    marginLeft: props => props.marginLeft || 20,
    marginRight: props => props.marginRight || 20,
  },
  infoBusinessData: {
    display: 'flex',
    alignItems: 'center',
    height: 26,
    marginBottom: 5,
  },
});

const DetailBusinessInformation = ({ data, variant }) => {
  const { title, content, icon = null, titleDisable } = data;
  const classes = useStyles(variant);

  const transformContent = content => {
    let newContent;
    switch (content) {
      case null:
        newContent = '-';
        break;

      case 'success':
        newContent = (
          <Box color="success.main">
            <SuccessICon fontSize="small" />
          </Box>
        );
        break;

      case 'error':
        newContent = <ErrorIcon color="error" fontSize="small" />;
        break;

      default:
        newContent = content;
    }
    return newContent;
  };

  return (
    <Box className={classes.infoBusinessContainer}>
      <Box color={titleDisable ? 'text.disabled' : 'text.primary'}>
        <Typography color="inherit" variant="body2" component="div">
          <Box fontWeight="fontWeightBold" className={classes.infoBusinessData}>
            <Box mr={1}>{title}</Box>
            {icon}
          </Box>
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {transformContent(content)}
        </Typography>
      </Box>
    </Box>
  );
};

DetailBusinessInformation.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  variant: PropTypes.objectOf(String),
};

DetailBusinessInformation.defaultProps = {
  variant: {},
};

export default DetailBusinessInformation;
