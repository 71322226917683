import { MenuItem } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import NumberFormatCustom from '../../../../../components/elements/NumberFormatCustom';
import { t13s } from '../../../../../translationKeys';
import useCreditFormDialog from './useCreditFormDialog';
import {
  rutCleaner,
  validateIdentifier,
} from '../../../../../helpers/validation/businessIdentifier';
import verifyDecimals from '../../../../../helpers/validation/decimals';
import LoadingButton from '../../../../../components/elements/LoadingButton';

const RADIX = 10;

const FormInputs = () => {
  const { t } = useTranslation();
  const { country } = useSelector(state => state.config);
  const { orderCredit, orderCreditIsRegistering, handleSubmit } =
    useCreditFormDialog();

  const creditInitialData = {
    identifier: '',
    name: '',
    amount: '',
    fees: '',
    rate: '',
    period: '',
    startDate: null,
    type: 'orderCredit',
  };

  const [creditFormData, setCreditFormData] = useState({
    ...creditInitialData,
    ...orderCredit,
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidIdentifier', value =>
      validateIdentifier(value, country)
    );
    ValidatorForm.addValidationRule('maxDecimals', value =>
      verifyDecimals(value)
    );
  }, []);

  const handleInputChange = event => {
    const targetName = event.target.name;
    let targetValue = event.target.value;

    if (targetName === 'amount') {
      if (country === 'CL') {
        targetValue = parseInt(targetValue.replace(/[^0-9]/g, ''), RADIX);
      } else {
        targetValue = targetValue.replace('$', '');
        const arrAmount = targetValue.split('.');
        targetValue = parseFloat(
          `${arrAmount[0].replace(/,/g, '')}.${arrAmount[1]}`
        );
      }
    }

    setCreditFormData({
      ...creditFormData,
      [targetName]: targetValue,
    });
  };

  const handleIdentifierChange = event => {
    event.persist();
    let formIdentifier = event?.target?.value;
    if (country === 'CL') {
      formIdentifier = rutCleaner(formIdentifier);
    }

    setCreditFormData({
      ...creditFormData,
      identifier: formIdentifier,
    });
  };

  const handleStartDateChange = dateValue => {
    setCreditFormData({
      ...creditFormData,
      startDate: dateValue,
    });
  };

  const { name, amount, fees, rate, period, startDate, identifier } =
    creditFormData;

  return (
    <ValidatorForm onSubmit={() => handleSubmit(creditFormData)}>
      <TextValidator
        fullWidth
        variant="outlined"
        label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
        type="string"
        name="identifier"
        aria-describedby={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
        value={identifier}
        onChange={handleIdentifierChange}
        validators={['required', 'isValidIdentifier']}
        errorMessages={[
          'Campo requerido',
          t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
        ]}
      />
      <TextValidator
        fullWidth
        variant="outlined"
        label="Nombre o Razón social"
        type="string"
        name="name"
        aria-describedby="Nombre o Razón social"
        value={name}
        onChange={handleInputChange}
        validators={['required']}
        errorMessages={['Campo requerido']}
      />
      <TextValidator
        fullWidth
        variant="outlined"
        label="Monto"
        name="amount"
        aria-describedby="Monto"
        value={amount}
        onChange={handleInputChange}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
      <TextValidator
        fullWidth
        variant="outlined"
        label="Nº de cuotas"
        type="number"
        inputProps={{ min: 1, step: 1 }}
        name="fees"
        aria-describedby="Nº de cuotas"
        value={fees}
        onChange={handleInputChange}
        validators={['required', 'minNumber:1']}
        errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
      />
      <TextValidator
        fullWidth
        variant="outlined"
        label="% Tasa"
        inputProps={{ min: 0, step: 0.0001 }}
        type="number"
        name="rate"
        aria-describedby="Tasa"
        value={rate}
        onChange={e => handleInputChange(e)}
        validators={['required', 'maxDecimals']}
        errorMessages={['Campo requerido', 'Max. 4 decimales']}
      />
      <SelectValidator
        fullWidth
        variant="outlined"
        label="Periocidad de pago"
        type="string"
        name="period"
        aria-describedby="Periocidad de pago"
        value={period}
        onChange={handleInputChange}
        validators={['required']}
        errorMessages={['Campo requerido']}
      >
        <MenuItem value="BIANNUAL">Semestral</MenuItem>
        <MenuItem value="QUARTERLY">Trimestral</MenuItem>
        <MenuItem value="MONTHLY">Mensual</MenuItem>
        <MenuItem value="BIWEEKLY">Quincenal</MenuItem>
      </SelectValidator>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
        <DatePicker
          inputVariant="outlined"
          fullWidth
          label="Fecha Inicio Crédito"
          name="startDate"
          format="DD-MM-YYYY"
          onChange={handleStartDateChange}
          minDateMessage="Fecha inválida"
          autoOk
          value={startDate}
        />
      </MuiPickersUtilsProvider>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        isLoading={orderCreditIsRegistering}
      >
        Guardar
      </LoadingButton>
    </ValidatorForm>
  );
};

export default FormInputs;
