import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  btnApplied: {
    background: '#fff',
    borderColor: '#385bea',
  },
  filterButton: {
    background: '#fff',
  },
  filterBadge: {
    background: '#385bea',
    color: '#fff',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginLeft: '10px',
  },
});
