import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import downloadOrderCommentAttachmentService from '../services/downloadOrderCommentAttachment';
import { enqueueSnackbar } from '../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../translationKeys';
import { downloadFile } from '../../../../../../helpers/fileHandling';
import { DownloadOrderCommentAttachment } from '../../interfaces';

const useDownloadOrderCommentAttachment = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    mutate: downloadOrderCommentAttachment,
  } = useMutation(
    ({ attachmentId }: DownloadOrderCommentAttachment) =>
      downloadOrderCommentAttachmentService({ attachmentId }),
    {
      onSuccess: ({ file }, variables) => {
        return downloadFile(file, variables.fileName);
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_ORDER_COMMENT_ATTACHMENT, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    downloadOrderCommentAttachment,
  };
};

export default useDownloadOrderCommentAttachment;
