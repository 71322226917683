import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../commons/formatters';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import {
  FundFinancingWrapper,
  FundFinancingGridItem,
  FundFinancingText,
} from './styles';
import { t13s } from '../../../../translationKeys';

const FundFinancingSummary = ({ fundSummary }) => {
  const { country } = useSelector(state => state.config);
  const { t } = useTranslation();

  const { amountTransferFund, baseRate, fundCost, totalAmount } = fundSummary;

  return (
    <FundFinancingWrapper container item direction="column">
      <FundFinancingGridItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <FundFinancingText>Monto total facturas</FundFinancingText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="currency"
            value={totalAmount}
          />
        </Text>
      </FundFinancingGridItem>
      <FundFinancingGridItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <FundFinancingText variant="subtitle1">
            {t(t13s.LABEL.MONTLHY_RATE)}
          </FundFinancingText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="percentage"
            value={baseRate}
          />
        </Text>
      </FundFinancingGridItem>
      <FundFinancingGridItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <FundFinancingText variant="subtitle1">Costo fondo</FundFinancingText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="currency"
            value={fundCost}
          />
        </Text>
      </FundFinancingGridItem>
      <FundFinancingGridItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <FundFinancingText variant="subtitle1">
            Monto transferencia fondo
          </FundFinancingText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="currency"
            value={amountTransferFund}
          />
        </Text>
      </FundFinancingGridItem>
    </FundFinancingWrapper>
  );
};

FundFinancingSummary.propTypes = {
  fundSummary: PropTypes.shape({
    amountTransferFund: PropTypes.number,
    baseRate: PropTypes.number,
    fundCost: PropTypes.number,
    totalAmount: PropTypes.number,
  }).isRequired,
};

export default FundFinancingSummary;
