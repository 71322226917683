import React, { FC } from 'react';
import FileUploadDialog from '../../../../commons/components/FileUploadModal';
import useInvoicesBulkUpdate from './infrastructure/store/useInvoicesBulkUpdate';
import { csvErrorsReportBuilder } from '../../../../../helpers/CsvErrorsReportBuilder';
import { InvoicesBulkUpdateType } from '../../../../commons/interfaces/ARInterfaces';
import useGetCountry from '../../../../../hooks/useGetCountry';

interface Props {
  open: boolean;
  sourceType: InvoicesBulkUpdateType;
  handleClose: () => void;
}

const InvoicesBulkUpdateModal: FC<Props> = ({
  open,
  sourceType,
  handleClose,
}) => {
  const country = useGetCountry();
  const {
    uploadFile,
    resetDataAndErrors,
    isLoading,
    hasErrors,
    serverError,
    isSuccess,
    errorLines,
  } = useInvoicesBulkUpdate(sourceType);

  const TITLE = {
    STATUS: 'Actualización masiva de estado de facturas',
    DATE: 'Actualización masiva de fechas de facturas',
  };
  const DESCRIPTION =
    'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

  const getCsvTemplate = () => {
    const templates = [
      {
        type: 'STATUS',
        country: ['CL', 'MX'],
        value: [
          {
            orderInvoiceId: '200320',
            status: 'ACTIVE',
          },
        ],
      },
      {
        type: 'DATE',
        country: ['CL'],
        value: [
          {
            orderInvoiceId: 123,
            paymentConfirmed: '2021-01-01',
            paymentDate: '2021-01-02',
            fundPaymentDate: '2021-01-02',
          },
        ],
      },
      {
        type: 'DATE',
        country: ['MX'],
        value: [
          {
            uuid: '088A54E8-46EA-474E-A99C-FBB1F67EE1E5',
            paymentConfirmed: '2021-01-01',
            paymentDate: '2021-01-02',
            fundPaymentDate: '2021-01-02',
          },
        ],
      },
    ];

    return templates.filter(
      template =>
        template.country.includes(country) && template.type === sourceType
    )[0]?.value;
  };

  const onClose = () => {
    handleClose();
    resetDataAndErrors();
  };

  if (!isLoading && isSuccess && !hasErrors && !serverError) {
    onClose();
  }

  return (
    <FileUploadDialog
      open={open}
      handleCloseDialog={onClose}
      handleLoad={uploadFile}
      saving={isLoading}
      errorsCsv={csvErrorsReportBuilder(errorLines)}
      serverError={serverError ? String(serverError) : null}
      title={TITLE[sourceType]}
      description={DESCRIPTION}
      csvTemplate={getCsvTemplate()}
      resetDataAndErrors={resetDataAndErrors}
    />
  );
};

export default InvoicesBulkUpdateModal;
