import React, { useEffect } from 'react';
import { Switch, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SecureRoute } from '@okta/okta-react';
import HomeBusinesses from '../components/HomeBusinesses';
import HomeBusinessDetails from '../components/HomeBusinessDetails';
import HomeUsers from '../components/HomeUsers';
import HomeAgreements from '../components/HomeAgreements';
import HomePayerBlocklist from '../components/HomePayerBlocklist';
import HomeWallets from '../components/HomeWallets';
import HomePayers from '../components/HomePayers';
import HomeCredits from '../components/HomeCredits';
import HomeCreditDetails from '../components/HomeCreditDetails';
import HomeReports from '../components/HomeReports';
import SectionNotAvailable from '../components/SectionNotAvailable';
import requireAuth from '../components/hoc/require_okta_auth';
import {
  AGREEMENTS_SECTION_PERFORM,
  BLOCK_LIST_SECTION_PERFORM,
  BUSINESS_SECTION_PERFORM,
  COLLECTION_SECTION_PERFORM,
  CREDITS_SECTION_PERFORM,
  FUNDS_SECTION_PERFORM,
  PAYERS_SECTION_PERFORM,
  USERS_SECTION_PERFORM,
  REPORTS_SECTION_PERFORM,
} from '../helpers/performsType';
import { changeCountry } from '../actions/configAction';
import FundBankAccounts from '../components/elements/FundBankAccounts';
import HomeCollection from '../collection/pages/HomeCollection';
import { FundsRoutes } from '../funds/adapters/routes';

import { PaymentsDetails, PaymentsInbox } from '../payments/pages';
import DetailCollection from '../collection/pages/CollectionsByPayer';
import ARInbox from '../AR/pages/Inbox';
import HomeOperationDetail from '../components/HomeOperationDetail';

const RoutesCL = () => {
  const { country: countryParams } = useParams();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const country = countryParams.toUpperCase();

  useEffect(() => {
    if (i18n.language !== country) {
      i18n.changeLanguage(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  dispatch(changeCountry(country));

  return (
    <Switch>
      {FundsRoutes('cl')}
      <SecureRoute
        exact
        path="/cl/direct_financing"
        component={requireAuth(ARInbox)}
      />
      <SecureRoute
        exact
        path="/cl/early_payment"
        component={requireAuth(ARInbox)}
      />
      <SecureRoute
        exact
        path="/cl/payments"
        component={requireAuth(PaymentsInbox)}
      />
      <SecureRoute
        exact
        path="/cl/payments/:id"
        component={requireAuth(PaymentsDetails)}
      />
      <SecureRoute
        exact
        path="/cl/orders/:id"
        component={requireAuth(HomeOperationDetail)}
      />
      <SecureRoute
        exact
        path="/cl/business"
        component={requireAuth(HomeBusinesses, BUSINESS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/business/:id"
        component={requireAuth(HomeBusinessDetails, BUSINESS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/users"
        component={requireAuth(HomeUsers, USERS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/funds/:id/bank-accounts"
        component={requireAuth(FundBankAccounts, FUNDS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/agreements"
        component={requireAuth(HomeAgreements, AGREEMENTS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/payerblocklist"
        component={requireAuth(HomePayerBlocklist, BLOCK_LIST_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/wallets"
        component={requireAuth(HomeWallets)}
      />
      <SecureRoute
        exact
        path="/cl/payers"
        component={requireAuth(HomePayers, PAYERS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/credits"
        component={requireAuth(HomeCredits, CREDITS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/credits/:id([0-9]+)"
        component={requireAuth(HomeCreditDetails, CREDITS_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/collection"
        component={requireAuth(HomeCollection, COLLECTION_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/collection/:id"
        component={requireAuth(DetailCollection, COLLECTION_SECTION_PERFORM)}
      />
      <SecureRoute
        exact
        path="/cl/reports"
        component={requireAuth(HomeReports, REPORTS_SECTION_PERFORM)}
      />

      <SecureRoute component={requireAuth(SectionNotAvailable)} />
    </Switch>
  );
};
export default RoutesCL;
