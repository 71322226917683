import axios from 'axios';

export const fetchAgreements = async () => {
  try {
    return await axios.get('/api/agreementgroup');
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default fetchAgreements;
