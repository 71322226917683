import { useState } from 'react';
import { useOrderBulkUpdateState } from '../infrastructure/store';
import {
  useSelectedPayrolls,
  useClearSelectedPayrolls,
} from '../infrastructure/store/PaymentInboxStore';

const useSelectedPayrollActionBar = () => {
  const [isChangeStatusOrderOpen, setIsChangeStatusOrderOpen] = useState(false);
  const { changeState } = useOrderBulkUpdateState();
  const selectedPayrolls = useSelectedPayrolls();
  const clearSelectedPayrolls = useClearSelectedPayrolls();
  const totalSelectedPayrolls = selectedPayrolls.length;

  const openChangeStatusOrderModal = () => setIsChangeStatusOrderOpen(true);
  const closeChangeStatusOrderModal = () => setIsChangeStatusOrderOpen(false);

  const handleClearSelection = () => clearSelectedPayrolls();

  const handlebulkUpdateStatus = (status, statusReason) => {
    changeState({ orderIds: selectedPayrolls, status, statusReason });
    closeChangeStatusOrderModal();
  };

  return {
    totalSelectedPayrolls,
    handleClearSelection,
    handlebulkUpdateStatus,
    isChangeStatusOrderOpen,
    openChangeStatusOrderModal,
    closeChangeStatusOrderModal,
  };
};

export default useSelectedPayrollActionBar;
