export default {
  15: () => {
    // migration clear out everything
    return {};
  },
  16: () => {
    // migration clear out everything
    return {};
  },
  17: () => {
    // migration clear out everything
    return {};
  },
};
