import React from 'react';
import PropTypes from 'prop-types';
import { Alert as MaterialAlert, AlertTitle } from '@material-ui/lab';
import { Box, styled } from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import { Text } from '../formatters';

const ALERT_TYPES = ['success', 'warning', 'error', 'info'];

const CustomAlert = styled(MaterialAlert)(({ theme, severity }) => {
  const alertStyles = {
    warning: {
      borderColor: theme.palette.payments.warning.dark,
      background: theme.palette.payments.warning.light,
    },
  };
  return {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 16,
    ...alertStyles[severity],
  };
});

const CustomWarningIcon = styled(ErrorRounded)(({ theme }) => ({
  color: theme.palette.payments.warning.dark,
}));

const Title = styled(AlertTitle)(({ theme }) => ({
  color: theme.palette.payments.warning.dark,
}));

const Message = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.warning.dark,
}));

const ChildWrapper = styled(Box)({
  marginTop: 10,
});

const ALERT_ICONS = { warning: <CustomWarningIcon /> };

const Alert = ({ type, title, message, children }) => {
  return (
    <CustomAlert severity={type} iconMapping={ALERT_ICONS}>
      <Title variant="h6">{title}</Title>
      <Message variant="subtitle1">{message}</Message>
      <ChildWrapper>{children}</ChildWrapper>
    </CustomAlert>
  );
};

Alert.defaultProps = {
  message: '',
  title: '',
  type: ALERT_TYPES[0],
  children: undefined,
};

Alert.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(ALERT_TYPES),
  children: PropTypes.node,
};

export default Alert;
