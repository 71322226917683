import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Typography, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TGRAgreements from '../TGRAgreements';

const useStyles = {
  dialog: {
    background: '#F3F2F5',
    borderRadius: '17px',
  },
  closeButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alignContentCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawerPaper: {
    background: 'white',
    overflowY: 'scroll',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 5px 13px 5px',
    borderBottom: '1px solid #CDCAD8',
  },
  drawerTitle: {
    fontWeight: '700',
  },
};

class TGRAgreementsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCloseButtonClick = () => {
    this.props.handleCloseDialog();
  };

  render() {
    const { classes, open, businessId } = this.props;

    return (
      <Drawer
        anchor="right"
        paperProps={{
          sx: { width: '90%' },
        }}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={this.handleCloseButtonClick}
        disableEscapeKeyDown
      >
        <Container>
          <div className={classes.header}>
            <Typography variant="h5" className={classes.drawerTitle}>
              TGR
            </Typography>
            <div className={classes.closeButtonContainer}>
              <IconButton onClick={this.handleCloseButtonClick}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <TGRAgreements id={businessId} />
        </Container>
      </Drawer>
    );
  }
}

TGRAgreementsDialog.propTypes = {
  businessId: PropTypes.number.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(useStyles)(TGRAgreementsDialog);
