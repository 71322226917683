import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { differenceInDays, parseISO } from 'date-fns';

type CreditLineEnrollmentTimeCardProps = {
  enrolledAt?: string;
  isLoading?: boolean;
};

const CreditLineEnrollmentTimeCard = ({
  enrolledAt,
  isLoading,
}: CreditLineEnrollmentTimeCardProps) => {
  const getDiffDaysEnrollment = () =>
    enrolledAt
      ? differenceInDays(new Date(), parseISO(enrolledAt))
      : 'No Enrolado';

  return (
    <Box p={5} display="flex" alignItems="center">
      <Box sx={{ mr: 2 }}>
        <DateRangeIcon fontSize="large" />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="textSecondary" component="span">
          <Box fontWeight="bold" fontSize="12.5">
            Días transcurridos
          </Box>
        </Typography>
        <Box sx={{ mt: 1, p: 0.5 }}>
          <Typography variant="subtitle1" color="textPrimary" component="span">
            <Box fontWeight="bold">
              {isLoading ? (
                <CircularProgress size={14} title="isLoadingEnrollmentAt" />
              ) : (
                <Box fontWeight="bold">{getDiffDaysEnrollment()}</Box>
              )}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditLineEnrollmentTimeCard;
