// @ts-nocheck
import { Box, Link, styled, Typography, Button } from '@material-ui/core';

export const ContainerRoot = styled(Box)(({ theme }) => ({
  background: theme.palette.payments.softBlue,
  alignItems: 'center',
  padding: '12px 24px',
  borderBottom: '1px solid #CDCAD8',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const SelectionDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const SelectedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16.25px',
  marginRight: 10,
}));

export const UndoSelection = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: '16.25px',
}));

export const RenegotiationButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  fontSize: 14,
  padding: '10px 24px',
  fontWeight: 700,
}));