const handleLoginService = async services => {
  let rut;
  let dverificador = '';
  if (services.BusinessService.username) {
    rut = services.BusinessService.username
      .replace(/[^kK0-9]/g, '')
      .slice(0, -1);
    dverificador = services.BusinessService.username.slice(-1).toLowerCase();
  }

  let url = '';
  let params = {};

  switch (services.name.toLowerCase()) {
    case 'tgr':
      url =
        'https://www.tesoreria.cl/portal/portlets/acceso_usuario/login/validarUsuario.do';
      params = {
        redireccion: 'datosPersonales',
        rut,
        dverificador,
        clave: services.BusinessService.password,
      };

      break;
    case 'previred':
      url = 'https://www.previred.com/wPortal/CtrlFce';
      params = {
        reqName: 'prglogin',
        web_rut: rut,
        web_dv: dverificador,
        web_password: services.BusinessService.password,
      };
      break;
    default:
      break;
  }
  if (!url || !params) return;

  const form = document.createElement('form');
  const name = 'xcapital-tgr';
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  form.setAttribute('target', name);
  // eslint-disable-next-line no-restricted-syntax
  for (const i in params) {
    if (Object.prototype.hasOwnProperty.call(params, i)) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = i;
      input.value = params[i];
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  window.open('', name);
  form.submit();
  document.body.removeChild(form);
};

export default handleLoginService;
