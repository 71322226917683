import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, IconButton, Box } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import { formatBytes, formatDuration } from './utils';
import DeleteIcon from '../../icons/DeleteIcon';

const useStyles = makeStyles({
  previewContainer: {
    padding: '10px 3%',
    minHeight: 50,
    borderBottom: '1px solid #CDCAD8',
  },
  fileName: {
    overflowWrap: 'break-word',
    maxWidth: 'calc(100% - 32.38px - 17.4px)',
  },
  fileNameImg: {
    marginLeft: 10,
    overflowWrap: 'break-word',
  },
});

const Preview = ({
  className,
  imageClassName,
  fileWithMeta: { cancel, remove, restart },
  meta: {
    name = '',
    percent = 0,
    size = 0,
    previewUrl,
    status,
    duration,
    validationError,
  },
  isUpload,
  canCancel,
  canRemove,
  canRestart,
  extra: { minSizeBytes },
}) => {
  const classes = useStyles();

  let title = `${name || '?'}, ${formatBytes(size)}`;
  if (duration) title = `${title}, ${formatDuration(duration)}`;

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className}>
        <Typography
          variant="body1"
          align="left"
          color="textPrimary"
          className={classes.fileName}
        >
          {title}
        </Typography>
        {status === 'error_file_size' && (
          <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>
        )}
        {status === 'error_validation' && (
          <span>{String(validationError)}</span>
        )}
        {canRemove && (
          <IconButton color="default" onClick={remove}>
            <DeleteIcon color="#000" />
          </IconButton>
        )}
      </div>
    );
  }

  if (
    status === 'error_upload_params' ||
    status === 'exception_upload' ||
    status === 'error_upload'
  ) {
    title = `${title} (upload failed)`;
  }
  if (status === 'aborted') title = `${title} (cancelled)`;

  return (
    <Box className={`${className} ${classes.previewContainer}`}>
      {previewUrl && (
        <Box display="flex" alignItems="center">
          <img
            className={imageClassName}
            src={previewUrl}
            alt={title}
            title={title}
          />
          <Typography
            variant="body1"
            align="left"
            color="textPrimary"
            className={classes.fileNameImg}
          >
            {title}
          </Typography>
        </Box>
      )}
      {!previewUrl && (
        <Typography
          variant="body1"
          align="left"
          color="textPrimary"
          className={classes.fileName}
        >
          {title}
        </Typography>
      )}

      <Box className="dzu-previewStatusContainer">
        {isUpload && (
          <progress
            max={100}
            value={
              status === 'done' || status === 'headers_received' ? 100 : percent
            }
          />
        )}

        {status === 'uploading' && canCancel && (
          <IconButton color="default" onClick={cancel}>
            <PauseRoundedIcon color="action" />
          </IconButton>
        )}
        {status !== 'preparing' &&
          status !== 'getting_upload_params' &&
          status !== 'uploading' &&
          canRemove && (
            <IconButton color="default" onClick={remove}>
              <DeleteIcon color="#000" />
            </IconButton>
          )}
        {[
          'error_upload_params',
          'exception_upload',
          'error_upload',
          'aborted',
          'ready',
        ].includes(status) &&
          canRestart && (
            <IconButton color="default" onClick={restart}>
              <PlayArrowRoundedIcon color="action" />
            </IconButton>
          )}
      </Box>
    </Box>
  );
};

Preview.defaultProps = {
  className: '',
  imageClassName: '',
};

Preview.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  fileWithMeta: PropTypes.shape({
    cancel: PropTypes.func.isRequired,
    restart: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  // copy of fileWithMeta.meta, won't be mutated
  meta: PropTypes.shape({
    status: PropTypes.oneOf([
      'preparing',
      'error_file_size',
      'error_validation',
      'ready',
      'getting_upload_params',
      'error_upload_params',
      'uploading',
      'exception_upload',
      'aborted',
      'error_upload',
      'headers_received',
      'done',
    ]).isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    uploadedDate: PropTypes.string.isRequired,
    percent: PropTypes.number,
    size: PropTypes.number,
    lastModifiedDate: PropTypes.string,
    previewUrl: PropTypes.string,
    duration: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }).isRequired,
  isUpload: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool.isRequired,
  canRestart: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired, // eslint-disable-line react/no-unused-prop-types
  extra: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reject: PropTypes.bool.isRequired,
    dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    minSizeBytes: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
};

export default Preview;
