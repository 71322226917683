import InvoiceStore from './InvoiceStore';

const useRequestDebtSuperData = () =>
  InvoiceStore(state => state.requestDebtSuperData);
const useRequestDebtServiceData = () =>
  InvoiceStore(state => state.requestDebtServiceData);
const useShowFundDialog = () => InvoiceStore(state => state.showFundDialog);
const useIssuerBusinessId = () => InvoiceStore(state => state.issuerBusinessId);
const useIsRegisterBankAccount = () =>
  InvoiceStore(state => state.isRegisterBankAccount);
const useSelectedBankAccount = () =>
  InvoiceStore(state => state.selectedBankAccount);
const useShowRegisterAccountDialog = () =>
  InvoiceStore(state => state.showRegisterAccountDialog);
const useShowDebtSuperDialog = () =>
  InvoiceStore(state => state.showDebtSuperDialog);
const useShowDebtServiceDialog = () =>
  InvoiceStore(state => state.showDebtServiceDialog);
const useShowBillDatesDialog = () =>
  InvoiceStore(state => state.showBillDatesDialog);
const useShowInvoiceStatusDialog = () =>
  InvoiceStore(state => state.showInvoiceStatusDialog);
const useSelectedInvoice = () => InvoiceStore(state => state.selectedInvoice);
const useSelectedInvoices = () => InvoiceStore(state => state.selectedInvoices);
const useDiscountDialogData = () =>
  InvoiceStore(state => state.discountDialogData);
const useShowDiscountFormDialog = () =>
  InvoiceStore(state => state.showDiscountFormDialog);
const useSetRequestDebtSuperData = () =>
  InvoiceStore(state => state.setRequestDebtSuperData);
const useSetRequestDebtServiceData = () =>
  InvoiceStore(state => state.setRequestDebtServiceData);
const useSetShowFundDialog = () =>
  InvoiceStore(state => state.setShowFundDialog);
const useSetIssuerBusinessId = () =>
  InvoiceStore(state => state.setIssuerBusinessId);
const useSetSelectedBankAccount = () =>
  InvoiceStore(state => state.setSelectedBankAccount);
const useSetShowRegisterAccountDialog = () =>
  InvoiceStore(state => state.setShowRegisterAccountDialog);
const useSetShowDebtSuperDialog = () =>
  InvoiceStore(state => state.setShowDebtSuperDialog);
const useSetShowDebtServiceDialog = () =>
  InvoiceStore(state => state.setShowDebtServiceDialog);
const useSetShowBillDatesDialog = () =>
  InvoiceStore(state => state.setShowBillDatesDialog);
const useSetShowInvoiceStatusDialog = () =>
  InvoiceStore(state => state.setShowInvoiceStatusDialog);
const useSetSelectedInvoice = () =>
  InvoiceStore(state => state.setSelectedInvoice);
const useSetSelectedInvoices = () =>
  InvoiceStore(state => state.setSelectedInvoices);
const useSetDiscountDialogData = () =>
  InvoiceStore(state => state.setDiscountDialogData);
const useSetShowDiscountFormDialog = () =>
  InvoiceStore(state => state.setShowDiscountFormDialog);
const useAnchorEl = () => InvoiceStore(state => state.anchorEl);
const useSetAnchorEl = () => InvoiceStore(state => state.setAnchorEl);
const useShowPartialPaymentDrawer = () =>
  InvoiceStore(state => state.showPartialPaymentDrawer);
const useSetShowPartialPaymentDrawer = () =>
  InvoiceStore(state => state.setShowPartialPaymentDrawer);
const useShowEditInvoiceDialog = () =>
  InvoiceStore(state => state.showEditInvoiceDialog);
const useSetShowEditInvoiceDialog = () =>
  InvoiceStore(state => state.setShowEditInvoiceDialog);
const useShowUploadDocumentDialog = () =>
  InvoiceStore(state => state.showUploadDocumentDialog);
const useSetShowUploadDocumentDialog = () =>
  InvoiceStore(state => state.setShowUploadDocumentDialog);
const useShowFacturasBulkUpdateDialog = () =>
  InvoiceStore(state => state.showFacturasBulkUpdateDialog);
const useSetShowFacturasBulkUpdateDialog = () =>
  InvoiceStore(state => state.setShowFacturasBulkUpdateDialog);
const useOrderIdsToReplace = () =>
  InvoiceStore(state => state.orderIdsToReplace);
const useSetOrderIdsToReplace = () =>
  InvoiceStore(state => state.setOrderIdsToReplace);
const useEnableManageOrderInvoice = () =>
  InvoiceStore(state => state.enableManageOrderInvoice);
const useSetEnableManageOrderInvoice = () =>
  InvoiceStore(state => state.setEnableManageOrderInvoice);
const useShowActionBar = () => InvoiceStore(state => state.showActionBar);
const useSetShowActionBar = () => InvoiceStore(state => state.setShowActionBar);
const useMoveDrawer = () => InvoiceStore(state => state.useMoveDrawer);
const useSetUseMoveDrawer = () => InvoiceStore(state => state.setUseMoveDrawer);
const useAddDrawer = () => InvoiceStore(state => state.useAddDrawer);
const useSetUseAddDrawer = () => InvoiceStore(state => state.setUseAddDrawer);
const useShowDeleteInvoice = () =>
  InvoiceStore(state => state.showDeleteInvoice);
const useSetShowDeleteInvoice = () =>
  InvoiceStore(state => state.setShowDeleteInvoice);
const useInvoicesParams = () => InvoiceStore(state => state.invoicesParams);
const useSetInvoicesParams = () =>
  InvoiceStore(state => state.setInvoicesParams);
const useSetInvoiceTableReloadRef = () =>
  InvoiceStore(state => state.setInvoiceTableReloadRef);
const useInvoiceTableReloadRef = () =>
  InvoiceStore(state => state.invoiceTableReloadRef);

export {
  useRequestDebtSuperData,
  useRequestDebtServiceData,
  useShowFundDialog,
  useIssuerBusinessId,
  useIsRegisterBankAccount,
  useSelectedBankAccount,
  useShowRegisterAccountDialog,
  useShowDebtSuperDialog,
  useShowDebtServiceDialog,
  useShowBillDatesDialog,
  useShowInvoiceStatusDialog,
  useSelectedInvoice,
  useSelectedInvoices,
  useDiscountDialogData,
  useShowDiscountFormDialog,
  useSetRequestDebtSuperData,
  useSetRequestDebtServiceData,
  useSetShowFundDialog,
  useSetIssuerBusinessId,
  useSetSelectedBankAccount,
  useSetShowRegisterAccountDialog,
  useSetShowDebtSuperDialog,
  useSetShowDebtServiceDialog,
  useSetShowBillDatesDialog,
  useSetShowInvoiceStatusDialog,
  useSetSelectedInvoice,
  useSetSelectedInvoices,
  useSetDiscountDialogData,
  useSetShowDiscountFormDialog,
  useAnchorEl,
  useSetAnchorEl,
  useShowPartialPaymentDrawer,
  useSetShowPartialPaymentDrawer,
  useShowEditInvoiceDialog,
  useSetShowEditInvoiceDialog,
  useShowUploadDocumentDialog,
  useSetShowUploadDocumentDialog,
  useShowFacturasBulkUpdateDialog,
  useSetShowFacturasBulkUpdateDialog,
  useOrderIdsToReplace,
  useSetOrderIdsToReplace,
  useEnableManageOrderInvoice,
  useSetEnableManageOrderInvoice,
  useShowActionBar,
  useSetShowActionBar,
  useMoveDrawer,
  useSetUseMoveDrawer,
  useAddDrawer,
  useSetUseAddDrawer,
  useShowDeleteInvoice,
  useSetShowDeleteInvoice,
  useInvoicesParams,
  useSetInvoicesParams,
  useSetInvoiceTableReloadRef,
  useInvoiceTableReloadRef,
};
