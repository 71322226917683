import { useQuery } from 'react-query';
import fetchRequirements from './../service/fetchRequirements';
import useRequirements from '../../hooks/useRequirements';

const useFetchRequirements = (
  operationId: number,
  { enabled = true }: { enabled?: boolean } = {}
) => {
  const { transformRequirementData } = useRequirements();
  const {
    isLoading,
    isRefetching,
    data = {},
  } = useQuery(
    ['getRequirements', operationId],
    () => fetchRequirements(operationId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 0,
      retry: false,
      enabled,
    }
  );

  const requirements = transformRequirementData(data);

  return {
    requirements,
    isLoading: isLoading || isRefetching,
  };
};

export default useFetchRequirements;
