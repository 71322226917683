import React, { useState } from 'react';
import { Collapse, IconButton, Button, Tooltip } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useStyles,
  ContainerRoot,
  ContainerFlex,
  ContainerInformation,
  ContainerInformationTitle,
  TitleBold,
  Chip,
  TextNormal,
  TextBold,
  ContainerDetail,
  ContainerDetailTitle,
  TextDetailTitle,
  TextDetail,
  TextBlue,
  TextRed,
  TextOrange,
  ContainerButtonCollapse,
  ButtonCollapse,
  ContainerButtonInvoices,
} from './styles';
import { dateFormatter } from '../../../../../helpers/DateUtils';
import { useTranslation } from 'react-i18next';
import { t13s } from '../../../../../translationKeys';
import HistoryIcon from '../../../../commons/icons/HistoryIcon';
import PersonIcon from '../../../../commons/icons/PersonIcon';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { delinquencyRangeConverter } from '../../../../commons/utils/delinquencyRangeConverter';
import { statusInvoiceConverter } from '../../../../commons/utils/statusInvoiceConverter';

interface Props {
  collectionPayer: {
    businessName: string;
    collectionPayerIndentifier: string;
    mostCriticalStatus: number;
    nextDueInvoice: Date;
    cantInvoices: number;
    dueInvoices: number;
    totalAmount: number;
    dueAmount: number;
  };
}

const PayerCard = ({ collectionPayer }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const country = useSelector((state: RootStateOrAny) => state.config.country);
  const [showCollapseInformation, setShowCollapseInformation] = useState(true);
  const { t } = useTranslation();

  const dataValues = [
    {
      label: 'Total Facturas',
      value: <TextBlue>{collectionPayer.cantInvoices}</TextBlue>,
    },
    {
      label: 'Facturas con mora',
      value: <TextRed>{collectionPayer.dueInvoices}</TextRed>,
    },
    {
      label: 'Tramo de mora',
      value: (
        <TextOrange>
          {delinquencyRangeConverter(collectionPayer.mostCriticalStatus)}
        </TextOrange>
      ),
    },
    {
      label: 'Próximo vencimiento',
      value: (
        <TextDetail>
          {dateFormatter(collectionPayer.nextDueInvoice) ||
            'No hay facturas por vencer'}
        </TextDetail>
      ),
    },
  ];
  return (
    <ContainerRoot>
      <ContainerInformation>
        <ContainerInformationTitle className={classes.spacingBottom}>
          <ContainerFlex alignItems="center">
            <TitleBold className={classes.spacingRight}>
              {collectionPayer.businessName}
            </TitleBold>
          </ContainerFlex>
        </ContainerInformationTitle>

        <ContainerFlex style={{ marginTop: 15 }}>
          <ContainerFlex className={classes.spacingRight8}>
            <TextNormal className={classes.rangeChip}>
              Factura más crítica:
            </TextNormal>
            <Chip className={classes.spacingRight3}>
              {statusInvoiceConverter(collectionPayer.mostCriticalStatus)}
            </Chip>
          </ContainerFlex>
          <ContainerFlex className={classes.identifier}>
            <TextNormal className={classes.spacingRightMiddle}>
              {t(t13s.LABEL.BUSINESS_IDENTIFIER).toUpperCase()}:
            </TextNormal>
            <TextNormal>
              {collectionPayer.collectionPayerIndentifier}
            </TextNormal>
          </ContainerFlex>
          <ContainerFlex className={classes.marginTop2}>
            <TextNormal className={classes.spacingRightMiddle}>
              Monto total:
            </TextNormal>
            <TextNormal>
              {/* @ts-ignore */}
              <CountryFormatHelper
                value={collectionPayer.totalAmount}
                variant="currency"
                countryId={country}
              />
            </TextNormal>
          </ContainerFlex>
        </ContainerFlex>

        <Collapse in={showCollapseInformation} timeout="auto">
          <ContainerFlex className={classes.spacingTop3}>
            {dataValues.map((dataValue, index: number) =>
              index === dataValues.length - 1 ? (
                <ContainerDetail>
                  {dataValue.label && (
                    <ContainerDetailTitle
                      className={classes.spacingBottomMiddle}
                    >
                      <TextDetailTitle>{dataValue.label}</TextDetailTitle>
                    </ContainerDetailTitle>
                  )}
                  {dataValue.value}
                </ContainerDetail>
              ) : (
                <ContainerDetail className={classes.paddingRight9}>
                  {dataValue.label && (
                    <ContainerDetailTitle
                      className={classes.spacingBottomMiddle}
                    >
                      <TextDetailTitle>{dataValue.label}</TextDetailTitle>
                    </ContainerDetailTitle>
                  )}
                  {dataValue.value}
                </ContainerDetail>
              )
            )}
          </ContainerFlex>
        </Collapse>
      </ContainerInformation>

      <ContainerFlex alignItems="start">
        {false && (
          <Tooltip
            title="Historial de cambios"
            className={classes.spacingRight4}
          >
            <IconButton>
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        )}
        {false && (
          <>
            <Tooltip
              title="Gestores de cobranza"
              className={classes.spacingRight}
            >
              <IconButton>
                <PersonIcon />
              </IconButton>
            </Tooltip>
            <ContainerFlex className={classes.marginTop3}>
              <TextNormal className={classes.spacingRight3}>3</TextNormal>
            </ContainerFlex>
          </>
        )}
        <ContainerButtonCollapse className={classes.spacingRight4}>
          <ButtonCollapse
            onClick={() => setShowCollapseInformation(!showCollapseInformation)}
          >
            {showCollapseInformation ? (
              <ArrowDown color="primary" />
            ) : (
              <ArrowUp color="primary" />
            )}
          </ButtonCollapse>
        </ContainerButtonCollapse>

        <ContainerButtonInvoices
          className={classes.paddingLeft4}
          flexDirection="column"
        >
          <ContainerFlex flexDirection="column">
            <TextNormal color="textSecondary">Monto vencido</TextNormal>
            <TextBold className={classes.spacingVertical}>
              {/* @ts-ignore */}
              <CountryFormatHelper
                value={collectionPayer.dueAmount}
                variant="currency"
                countryId={country}
              />
            </TextBold>
          </ContainerFlex>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 250 }}
            onClick={() =>
                window.open(`/${country.toLowerCase()}/collection/${collectionPayer.collectionPayerIndentifier}`, '_blank')
            }
            className={classes.buttonShowInvoices}
          >
            Ver detalle
          </Button>
        </ContainerButtonInvoices>
      </ContainerFlex>
    </ContainerRoot>
  );
};

export default PayerCard;
