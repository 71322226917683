import {
  CHECK_TAX_SUCCESS,
  CHECK_TAX_FAILURE,
  CHECK_TAX_START,
} from '../actions/types';

const initialState = {
  loadingSiiData: false,
  siiEnabled: false,
  jobState: '',
  jobProgress: '',
  siiLoaded: false,
  siiLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECK_TAX_START:
      return {
        ...state,
        siiLoading: true,
      };
    case CHECK_TAX_SUCCESS: {
      const newEnabled = payload.isSiiCredentialActive;
      return {
        ...state,
        siiEnabled: newEnabled,
        jobState: payload.jobState,
        jobProgress: payload.jobProgress,
        siiLoading: false,
        siiLoaded: true,
        loadingSiiData: false,
      };
    }
    case CHECK_TAX_FAILURE:
      return {
        ...initialState,
        siiEnabled: false,
        siiLoading: false,
        siiLoaded: true,
        loadingSiiData: false,
      };
    default:
      return state;
  }
};
