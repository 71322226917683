import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import LoadingButton from './LoadingButton';

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    borderRadius: '50px',
    display: 'flex',
    background: '#F6F5F7',
    paddingLeft: '20px',
    justifyContent: 'flex-end',
    height: 36,
  },
  dialogButton: {
    minWidth: 140,
  },
  checkCircle: {
    color: theme.palette.primary.main,
  },
  checkInput: {
    padding: 5,
  },
  variantError: {
    background: theme.palette.error.main,
    minWidth: 140,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  formControlLabelRoot: {
    marginBottom: 0,
  },
}));

const ERROR = 'error';

const SUBMIT = 'submit';
const BUTTON = 'button';

const CheckButton = ({
  check,
  handleCheck,
  labelButton,
  loading,
  variant,
  disabled,
  buttonType,
  onClickAction,
}) => {
  const classes = useStyles();

  const selectClass = () => {
    if (variant === ERROR) {
      return classes.variantError;
    }
    return classes.dialogButton;
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item className={classes.actionsContainer}>
        <FormControlLabel
          classes={{
            root: classes.formControlLabelRoot,
          }}
          control={
            <Checkbox
              data-cy="checkButtonCheckbox"
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              checked={check}
              disabled={disabled || loading}
              onChange={handleCheck}
              className={classes.checkInput}
            />
          }
          label="Confirmo"
        />
        <LoadingButton
          type={buttonType}
          onClick={buttonType !== SUBMIT ? onClickAction : null}
          variant="contained"
          color="primary"
          disabled={disabled || !check || loading}
          size="small"
          isLoading={loading}
          className={selectClass()}
          data-cy="checkButtonLoadingButton"
        >
          {labelButton}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

CheckButton.defaultProps = {
  loading: false,
  disabled: false,
  variant: 'primary',
  labelButton: 'Guardar',
  check: false,
  buttonType: SUBMIT,
  onClickAction: null,
};

CheckButton.propTypes = {
  check: PropTypes.bool,
  handleCheck: PropTypes.func.isRequired,
  labelButton: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.oneOf([BUTTON, SUBMIT]),
  onClickAction: PropTypes.func,
};

export default CheckButton;
