/* eslint-disable react/jsx-props-no-spreading */
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Menu,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import CalendarIcon from '@material-ui/icons/InsertInvitationOutlined';
import LinkIcon from '@material-ui/icons/Link';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  editBankAccount,
  registerBankAccount,
  fetchBankAccountsById,
} from '../../../actions/bankActions';
import { downloadAEC } from '../../../actions/cessionAction';
import {
  fetchOrderInvoicesFund,
  updateOrderInvoiceFund,
  verifyIfOrderIsFullyFinanced,
} from '../../../actions/fundsActions';
import { enqueueSnackbar } from '../../../actions/notificationAction';
import {
  addFundFinancingToBulkInvoices,
  addFundFinancingToInvoice,
  bulkUpdateFacturas,
  copyExternalPdfURL,
  downloadAecInvoice,
  fetchFacturas,
  fetchFacturasIdByOrderId,
  fetchInvoicePDF,
  fetchOperationSummary,
  fetchOrdersIdByPayerRut,
  fetchSelectedOrder,
  fetchTotalOrderTransferred,
  getExternalPdfURL,
  resetStateFetchOrdersIdByPayerRut,
  resetStateReplacedInvoice,
  resetStateUpdateBulkInvoices,
  resetStateUpdateInvoice,
  resetStateUploadingInvoice,
  updateOrderInvoice,
  fetchInvoiceRisk,
  manageOrderInvoices,
  checkOrderInvoiceAttachments,
  uploadOrderInvoicePdf,
  deleteOrderInvoicePdf,
  resetUploadOrderInvoicePdfStatus,
  downloadMarketInvoicerPdfGlobalCl,
  fetchAmountInfoByOrderInvoiceIds,
  updateOrderInvoiceIsHold,
  resetOrderInvoiceIsHoldStore,
  downloadmassassignableinvoicepdf,
} from '../../../actions/orderActions';
import {
  bulkFetchDebtService,
  bulkFetchDebtSuper,
  consultInvoiceDebtService,
  consultInvoiceDebtSuper,
} from '../../../actions/debtServiceActions';

import { wsConnect } from '../../../actions/websocket';
import { features } from '../../../config/features';
import {
  BLUE_ROW_COLOR,
  CONFIRMING,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
  DEH_DESCRIPTION,
  DIALOG_TYPES,
  INVOICE_STATUS_CL,
  INVOICE_STATUS_MX,
  RED_ROW_COLOR,
  STATUS_FAILED,
  STATUS_PAID,
  STATUS_REJECTED,
  STATUS_REPLACED,
  STATUS_SUCCESS,
  SUPER_DEBT,
  WHITE_BORDER_COLOR,
  XCAPITAL_CESSIONARY,
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  DECIMAL_PRECISION,
  GLOBAL_REASONS_WITH_XTREE,
  STATUS_DEFAULT,
  CESSION_STATUS,
  RISK,
  STATUS_IN_CONCILIATION,
  STATUS_ACTIVE,
  STATUS_HARD_COLLECTION,
  STATUS_COMPLETE,
  STATUS_REVIEWED,
  STATUS_APPEALED,
  STATUS_APPROVED_FUND,
} from '../../../helpers/Constants';
import { dateFormatter } from '../../../helpers/DateUtils';
import {
  BUSINESS_DICOM_PERFORM,
  INVOICES_BASE_RATE_PERFORM,
  INVOICES_DISCOUNTS_PERFORM,
  INVOICES_DOWNLOAD_PDF_PERFORM,
  INVOICES_EDIT_PERFORM,
  INVOICES_IS_HOLD_UPDATE_PERFORM,
  INVOICES_VERIFICATION_PERFORM,
} from '../../../helpers/performsType';
import { CONTEXT_TYPES } from '../../../helpers/userLogsUtils';
import { checkAuth } from '../../../helpers/validation/auth';
import Can from '../../Can';
import BankAccountDialog from '../../dialogs/BankAccountDialog';
import BillDatesDialog from '../../dialogs/BillDatesDialog';
import ChangeStatusInvoiceDialog from '../../dialogs/ChangeStatusInvoiceDialog';
import DiscountDrawer from '../../drawers/DiscountDrawer';
import EditInvoiceDialog from '../../dialogs/EditInvoiceDialog';
import FacturasUpdateDialog from '../../dialogs/FacturasUpdateDialog';
import FundDialog from '../../dialogs/FundDialog';
import UploadFileDialog from '../../dialogs/UploadFileDialog';
import {
  EndDateChip,
  IssueDateChip,
  MontoRetentionChip,
  RetentiontDaysChip,
} from '../../elements/ChipComponents';
import Container from '../../elements/Container';
import CountryFormatHelper from '../../elements/CountryFormatHelper';
import DebtSuperDialog from '../../elements/DebtSuperDialog';
import DebtServiceDialog from '../../dialogs/DebtServiceDialog';
import ActionBar from '../../elements/ActionBar';
import ActionBarIcon from '../../elements/ActionBarIcon';
import ManageInvoiceDrawer from '../../elements/ManageInvoiceDrawer';
import MenuItem from '../../elements/MenuItem';
import StatusBadge from '../../elements/StatusBadge';
import {
  Xepelin,
  XepelinDefault,
  XepelinGreen,
  MoveTo,
  Delete as DeleteIcon,
  Money as MoneyIcon,
} from '../../icons';
import InvoiceDetail from './InvoiceDetail';
import InvoiceToolbar from './InvoiceToolbar';
import InvoiceSignalScore from '../../elements/InvoiceSignalScore';
import InvoiceSignalRisk from '../../elements/InvoiceSignalRisk';
import InvoiceAmountChip from '../../elements/chips/InvoiceAmountChip';
import { t13s } from '../../../translationKeys';
import { lightenColor } from '../../../helpers/Colors';
import { ACTION_BAR } from '../../../theme/otherColors';
import PartialPaymentsDrawer from '../../drawers/PartialPaymentsDrawer';
import DeleteInvoiceDialog from '../../dialogs/DeleteInvoiceDialog';
import {
  CessionDialog,
  ChangeIsHoldDialog,
} from '../../../payments/commons/components';
import { resetDiscount } from '../../../actions/discountActions';
import useStatuses from '../../../hooks/useStatuses';
import XtreeBadge from '../../elements/XtreeBadge';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    background: 'white',
    borderRadius: 8,
    border: 'none',
    fontSize: '0.7777777777777778rem',
    fontWeight: 400,
    lineHeight: '1.43',
    textAlign: 'left',
  },
  table: {
    borderTop: `1px solid ${WHITE_BORDER_COLOR}`,
    display: 'block',
  },
  cessionStatusRow: {
    display: 'flex',
    alignItems: 'center',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  displayContents: {
    display: 'contents',
  },
  checkCircleGreaterThan8Days: {
    color: theme.palette.success.main,
    fontSize: 16,
  },
  checkCircleDehDescripcion: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  checkCirclePreselected: {
    color: theme.palette.warning.main,
    fontSize: 16,
  },
  verde: {
    marginRight: 5,
    background: `${theme.palette.success.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  amarillo: {
    marginRight: 5,
    background: `${theme.palette.warning.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  rojo: {
    marginRight: 5,
    background: `${theme.palette.error.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  iconGreen: {
    color: theme.palette.success.main,
    marginBottom: 8,
  },
  iconYellow: {
    color: theme.palette.warning.main,
    marginBottom: 8,
  },
  iconRed: {
    color: theme.palette.error.main,
    marginBottom: 8,
  },
  iconGray: {
    marginBottom: 8,
  },
  verifiedMargin: {
    marginLeft: 3,
    marginRight: 3,
  },
  replacedFolio: {
    color: theme.palette.primary.main,
  },
  containerFlex: {
    display: 'flex',
  },
  debtSuperRed: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
    fontSize: 20,
  },
  debtSuperGray: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  noTransferredOrders: {
    backgroundColor: theme.palette.success.light,
  },
  orderIdColumn: {
    textAlign: 'left',
    padding: '16px 0 16px 0',
    width: 50,
  },
  Xepelin: {
    margin: '0px 3px 12px 3px',
    width: 22,
    transform: 'scale(0.9)',
  },
  // Dont delete this dicom classes, they are used in getDicomIcon method.
  dicomGreen: {
    fontWeight: 'bold',
    color: theme.palette.success.main,
  },
  dicomRed: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
  dicomGray: {
    fontWeight: 'bold',
  },
  loaderScore: {
    marginTop: 2,
    marginLeft: 10,
  },
  customMessage: {
    marginRight: 10,
    borderRadius: 17,
    padding: '5px 15px',
    '&:hover': {
      background: lightenColor(ACTION_BAR.backgroundColor, 20),
      cursor: 'pointer',
    },
  },
  cursorPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  containerIcon: {
    marginTop: 6,
  },
}));

const entity = 'business';

const InvoicesTable = memo(
  ({
    handleShowVerifiedDialog,
    handleShowCessionDialog,
    handleShowPfxDialog,
    handleRedirectToOrderById,
    business = {},
    handleHistoryLogDialog,
    handleShowLoader,
    handleSetSelectedInvoices,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getStatuses } = useStatuses();

    const orderStatus = [
      ...getStatuses('order')
        .filter(
          status =>
            status.group === STATUS_IN_REVIEW ||
            status.group === STATUS_APPROVED
        )
        .map(status => status.key),
    ];

    const {
      user: { roles },
      rules,
    } = useSelector(state => state.auth);
    const {
      loadingFacturas,
      loadingTotalOrdersTransferred,
      updatedOrder,
      updatingInvoice,
      ordersIdToReplace: orderIds,
      updateFacturaErrors,
      fetchOrdersIdByPayerRutError,
      invoiceWasUpdated,
      invoicesBulkWasUpdated,
      loadingInvoiceId,
      uploadingPdf,
      downloadingPdf,
      invoiceWasUploaded,
      facturas: invoices,
      gettingURL,
      facturasReplaced: invoicesReplaced,
      holdInvoicesWarningMessage,
      orderInvoiceWasCreate,
      orderInvoicesAttachments,
      orderInvoicePdfIsDeleting,
      orderInvoicePdfIsUploading,
      orderInvoicePdfIsDownloading,
      uploadOrderInvoicePdfError,
      orderInvoicePdfWasUploaded,
      orderSummary,
      fetchAmountInfoIsLoading,
      fetchOrderInvoicesAttributesIsLoading,
      manageOrderInvoicesIsLoading,
      manageOrderInvoicesError,
      updateOrderInvoiceIsHoldError,
      orderInvoiceIsHoldIsUpdating,
      orderInvoiceIsHoldWasUpdated,
    } = useSelector(state => state.order);

    const {
      attributes: { currency },
    } = useSelector(state => state.paymentOrderDetails);
    const { discountWasUpdated, discountWasCreated, discountWasDeleted } =
      useSelector(state => state.discount);
    const { businessId } = updatedOrder;
    const {
      bulkDebtSuper,
      bulkFetchDebtSuperIsLoading,
      consultInvoiceDebtSuperIsLoading,
      bulkDebtService,
      bulkFetchDebtServiceIsLoading,
      consultInvoiceDebtServiceIsLoading,
    } = useSelector(state => state.debtService);
    const wsconnected = useSelector(state => state.ws.wsconnected);
    const country = useSelector(state => state.config.country);

    const {
      orderInvoicesFund,
      funds,
      orderInvoiceFundRates,
      orderOwnerInvoicesFund,
      fundRatesFundIsLoading,
      loadingFunds,
      orderInvoiceFundWasCreated,
      orderInvoiceFundWasUpdated,
    } = useSelector(state => state.funds);
    const { bankAccounts } = useSelector(state => state.banks);

    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [enableManageOrderInvoice, setEnableManageOrderInvoice] =
      useState(false);
    const [showFundDialog, setShowFundDialog] = useState(false);
    const [showDiscountDrawer, setShowDiscountDrawer] = useState(false);
    const [showDebtServiceDialog, setShowDebtServiceDialog] = useState(false);
    const [requestDebtServiceData, setRequestDebtServiceData] = useState({});
    const [showDebtSuperDialog, setShowDebtSuperDialog] = useState(false);
    const [requestDebtSuperData, setRequestDebtSuperData] = useState({});
    const [showFacturasBulkUpdateDialog, setShowFacturasBulkUpdateDialog] =
      useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(undefined);
    const [showRegisterAccountDialog, setShowRegisterAccountDialog] =
      useState(false);
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);
    const [isRegisterBankAccount, setIsRegisterBankAccount] = useState(false);
    const [showEditInvoiceDialog, setShowEditInvoiceDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showUploadDocumentDialog, setShowUploadDocumentDialog] =
      useState(false);
    const [showBillDatesDialog, setShowBillDatesDialog] = useState(false);
    const [orderIdsToReplace, setOrderIdsToReplace] = useState([]);
    const [showInvoiceStatusDialog, setShowInvoiceStatusDialog] =
      useState(false);
    const [showActionBar, setShowActionBar] = useState(false);
    const [useMoveDrawer, setUseMoveDrawer] = useState(false);
    const [useAddDrawer, setUseAddDrawer] = useState(false);
    const [showDeleteInvoice, setShowDeleteInvoice] = useState(false);

    const tableRef = useRef();
    const [issuerBusinessId, setIssuerBusinessId] = useState(null);
    const [showPartialPaymentDrawer, setShowPartialPaymentDrawer] =
      useState(false);
    const [, setAllOrderInvoiceSelected] = useState(false);
    const [showPaymentCessionDialog, setShowPaymentCessionDialog] =
      useState(false);
    const [showIsHoldDialog, setShowIsHoldDialog] = useState(false);
    const [invoiceIdToUpdateIsHold, setInvoiceIdToUpdateIsHold] =
      useState(null);

    const { type, orderType = type, id: orderId } = updatedOrder;
    const countryFeatures = features[country];

    useEffect(() => {
      if (selectedInvoices.length) {
        const invoicesId = selectedInvoices.map(selectedInv => selectedInv.id);
        const newInvoicesSelected = [];
        invoices.forEach(invoice => {
          if (
            invoicesId.includes(invoice.id) &&
            invoice.status !== STATUS_REJECTED
          ) {
            // eslint-disable-next-line no-param-reassign
            invoice.tableData.checked = true;
            newInvoicesSelected.push(invoice);
          }
        });
        setSelectedInvoices(newInvoicesSelected);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInvoices.length, invoices]);

    useEffect(() => {
      if (
        orderId &&
        invoices.length &&
        (orderOwnerInvoicesFund !== orderId ||
          orderInvoiceWasCreate ||
          invoicesBulkWasUpdated ||
          orderInvoiceFundWasUpdated)
      ) {
        dispatch(fetchOrderInvoicesFund(orderId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices, orderInvoiceFundWasUpdated, orderId]);

    useEffect(() => {
      if (invoices?.length && orderId) {
        if (country === COUNTRY_CODE_CL) {
          const orderInvoiceIds = invoices.map(({ id }) => id);
          dispatch(fetchAmountInfoByOrderInvoiceIds(orderInvoiceIds));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices?.length, loadingFacturas, country, orderId]);

    useEffect(() => {
      if (orderId && orderType !== CONFIRMING) {
        dispatch(fetchInvoiceRisk(orderId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, invoices.length]);

    const handleCloseDiscountDrawer = () => {
      setShowDiscountDrawer(false);
      dispatch(resetStateUpdateInvoice());
    };

    useEffect(() => {
      if (discountWasUpdated || discountWasDeleted || discountWasCreated) {
        handleCloseDiscountDrawer();
        dispatch(fetchSelectedOrder(orderId));
        dispatch(fetchOperationSummary(orderId));
        dispatch(fetchFacturas(orderId));
        dispatch(resetDiscount());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountWasCreated, discountWasDeleted, discountWasUpdated]);

    useEffect(() => {
      if (orderInvoiceFundWasCreated) {
        setShowFundDialog(false);
        dispatch(verifyIfOrderIsFullyFinanced(orderId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInvoiceFundWasCreated]);

    useEffect(() => {
      if (orderId && orderInvoiceWasCreate) {
        dispatch(fetchOrderInvoicesFund(orderId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInvoiceWasCreate]);

    useEffect(() => {
      if (orderId && invoices?.length) {
        dispatch(checkOrderInvoiceAttachments(orderId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, invoices?.length]);

    useEffect(() => {
      if (orderInvoiceIsHoldWasUpdated) {
        setShowIsHoldDialog(false);
        dispatch(resetOrderInvoiceIsHoldStore());
      }
    }, [orderInvoiceIsHoldWasUpdated]);

    const handleCloseEditDialog = () => {
      setShowEditInvoiceDialog(false);
      setShowBillDatesDialog(false);
      setSelectedInvoice(undefined);
      setAnchorEl(null);
      dispatch(resetStateUpdateInvoice());
    };

    const handleCloseInvoiceStatusDialog = () => {
      dispatch(resetStateFetchOrdersIdByPayerRut());
      dispatch(resetStateUpdateInvoice());
      setShowInvoiceStatusDialog(false);
    };

    const handleCloseBulkUpdateDialog = () => {
      dispatch(resetStateUpdateBulkInvoices());
      setShowFacturasBulkUpdateDialog(false);
    };

    useEffect(() => {
      if (invoiceWasUploaded) {
        setShowUploadDocumentDialog(false);
        dispatch(resetStateUploadingInvoice());
      }
    }, [invoiceWasUploaded, dispatch]);

    useEffect(() => {
      if (
        countryFeatures.orderInvoiceActions.showTotalOrdersTransferred &&
        orderType === CONFIRMING
      ) {
        dispatch(fetchTotalOrderTransferred(updatedOrder.id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedOrder]);

    useEffect(() => {
      if (invoiceWasUpdated) {
        if (showEditInvoiceDialog) {
          handleCloseEditDialog();
          // APICL eliminar cuando se apague la api de cl
        } else if (showDiscountDrawer) {
          handleCloseDiscountDrawer();
        } else if (showInvoiceStatusDialog) {
          handleCloseInvoiceStatusDialog();
        }
        setShowBillDatesDialog(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceWasUpdated]);

    useEffect(() => {
      if (invoicesBulkWasUpdated && !holdInvoicesWarningMessage) {
        handleCloseBulkUpdateDialog();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicesBulkWasUpdated, holdInvoicesWarningMessage]);

    useEffect(() => {
      let filteredOrderIds = [];
      if (selectedInvoice && orderIds?.length) {
        filteredOrderIds = orderIds.filter(orderId => {
          return (
            parseInt(orderId, 10) !== parseInt(selectedInvoice.orderId, 10)
          );
        });
        setOrderIdsToReplace(filteredOrderIds);
      }
    }, [selectedInvoice, orderIds]);

    useEffect(() => {
      if (!wsconnected) {
        dispatch(wsConnect());
      }
    }, [wsconnected, loadingFacturas, dispatch]);

    useEffect(() => {
      if (!showActionBar) {
        setSelectedInvoices([]);
      }
    }, [showActionBar]);

    useEffect(() => {
      setEnableManageOrderInvoice(
        selectedInvoices.every(i => orderStatus.includes(i.status))
      );
      if (selectedInvoices.length >= 1) {
        setShowActionBar(true);
      } else {
        setShowActionBar(false);
      }
    }, [selectedInvoices]);

    useEffect(() => {
      if (orderType === CONFIRMING && Object.values(bankAccounts).length) {
        const bankAccountsIssuerBusiness =
          bankAccounts[entity][issuerBusinessId];
        const bankAccountFound =
          bankAccountsIssuerBusiness.find(
            account => account.alias === 'Confirming'
          ) || bankAccountsIssuerBusiness.find(account => account.isDefault);
        const bankAccount = bankAccounts[entity] && bankAccountFound;
        setSelectedBankAccount(bankAccount);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccounts]);

    useEffect(() => {
      if (orderInvoicePdfWasUploaded) {
        setShowUploadDocumentDialog(false);
        dispatch(resetUploadOrderInvoicePdfStatus());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInvoicePdfWasUploaded]);

    useEffect(() => {
      if (invoices.length && country === COUNTRY_CODE_CL && businessId) {
        const identifiers = invoices.map(
          ({ payerIdentifier }) => payerIdentifier
        );
        // Remove duplicates
        const uniqueIdentifiers = [...new Set(identifiers)];
        dispatch(bulkFetchDebtSuper(uniqueIdentifiers));
        dispatch(
          bulkFetchDebtService(businessId, 'business', uniqueIdentifiers)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingFacturas]);

    const getRowColor = rowData => {
      const {
        status,
        SiiFactura,
        isClaimed = false,
        isCanceled = false,
        isHold = false,
        totalAmountCreditNotes,
      } = rowData;
      let color = '';
      if (status === STATUS_REPLACED) {
        color = BLUE_ROW_COLOR;
      }
      if (
        SiiFactura?.SiiNotasCredito ||
        isClaimed ||
        isCanceled ||
        isHold ||
        totalAmountCreditNotes
      ) {
        color = RED_ROW_COLOR;
      }
      return color;
    };

    const disableRowFilter = ({ cessionStatus, status }) =>
      status === STATUS_REJECTED ||
      (cessionStatus &&
        ![STATUS_FAILED, STATUS_SUCCESS].includes(cessionStatus));

    // HACK: disabled data on other pages are being selected.
    // https://github.com/mbrn/material-table/pull/1787#issuecomment-645158129
    const mapTableData = rows =>
      rows.map(data => {
        // eslint-disable-next-line no-param-reassign
        data.tableData = {
          ...data.tableData,
          disabled: disableRowFilter(data),
        };

        return data;
      });

    const handleRowSelection = orderInvoices => {
      const allInvoicesSelected =
        invoices.filter(({ tableData }) => !tableData.disabled).length ===
        orderInvoices.length;

      const handleSetInvoices = () => {
        const selectedOrderInvoices = orderInvoices.map(orderInvoice => ({
          ...orderInvoice,
          businessId: updatedOrder.businessId,
          userId: updatedOrder.userId,
        }));
        handleSetSelectedInvoices(selectedOrderInvoices);
        setSelectedInvoices(selectedOrderInvoices);
      };

      setAllOrderInvoiceSelected(prevData => {
        if (prevData) {
          if (allInvoicesSelected) {
            orderInvoices.forEach(orderInvoice => {
              // eslint-disable-next-line no-param-reassign
              orderInvoice.tableData.checked = false;
            });
            handleSetSelectedInvoices([]);
            setSelectedInvoices([]);
          } else {
            handleSetInvoices();
          }
          return false;
        }
        if (allInvoicesSelected) {
          handleSetInvoices();
          return true;
        }
        handleSetInvoices();
        return false;
      });
    };

    const handleCession = () => {
      if (orderType === CONFIRMING) {
        setShowPaymentCessionDialog(true);
      } else {
        const { hasPfxCertificate } = business;

        if (hasPfxCertificate) {
          handleShowCessionDialog();
        } else {
          handleShowPfxDialog();
        }
      }
    };

    const handleDownloadAEC = () => {
      const invoicesWithAEC = invoices.filter(
        invoice => invoice.cessionStatus === 'SUCCESS'
      );
      dispatch(downloadAEC(updatedOrder.id, invoicesWithAEC));
    };

    const hanldeMassassignableinvoicepdf = () => {
      const invoices = selectedInvoices.map(({ id }) => id);
      dispatch(downloadmassassignableinvoicepdf(updatedOrder.id, invoices));
    };

    const getFacturas = () => {
      dispatch(fetchFacturas(updatedOrder.id));
    };

    const getFolioElement = factura => {
      const { status, folio, Relationships } = factura;
      let folioElement = folio;
      if (status === STATUS_REPLACED && Relationships) {
        folioElement = (
          <Link
            onClick={() => handleRedirectToOrderById(Relationships?.orderId)}
            className={classes.replacedFolio}
          >
            {folio}
          </Link>
        );
      }
      return folioElement;
    };

    const handleDeleteInvoicePdf = () => {
      const { id: orderInvoiceId } = selectedInvoice;
      const { id: pdfId } = orderInvoicesAttachments.find(
        attachment => attachment.orderInvoiceId === orderInvoiceId
      );
      dispatch(deleteOrderInvoicePdf(orderInvoiceId, pdfId));
    };

    const handleUpdloadInvoicePdf = () => {
      setAnchorEl(null);
      setShowUploadDocumentDialog(true);
    };

    const getPdfActionButtonMx = () => {
      const {
        id,
        folio,
        OrderFacturaAttachments: orderFacturaAttachments,
      } = selectedInvoice || {};
      const hasAttachments =
        (orderFacturaAttachments && orderFacturaAttachments?.length) ||
        orderInvoicesAttachments?.find(
          attachment =>
            attachment?.orderInvoiceId === selectedInvoice?.id &&
            attachment?.hasAttachment
        );

      if (hasAttachments) {
        return (
          <>
            <MenuItem
              size="small"
              onClick={() => dispatch(downloadMarketInvoicerPdfGlobalCl(id))}
              icon={
                orderInvoicePdfIsDownloading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <CloudDownloadIcon />
                )
              }
            >
              Descargar PDF de la factura Mimetus
            </MenuItem>

            <MenuItem
              size="small"
              icon={
                orderInvoicePdfIsDeleting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <DeleteIcon />
                )
              }
              onClick={handleDeleteInvoicePdf}
            >
              Eliminar PDF de la factura
            </MenuItem>
          </>
        );
      }

      const fileName = `${country}_${orderId}_${folio}`;
      const processedInvoiceId = id;

      return (
        <>
          <MenuItem
            icon={<CloudUploadIcon />}
            onClick={handleUpdloadInvoicePdf}
          >
            Subir PDF de la factura
          </MenuItem>
          <MenuItem
            size="small"
            onClick={() =>
              dispatch(
                fetchInvoicePDF(
                  processedInvoiceId,
                  fileName,
                  orderType,
                  country
                )
              )
            }
            icon={
              orderInvoicePdfIsDownloading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <CloudDownloadIcon />
              )
            }
          >
            Descargar PDF de la factura
          </MenuItem>
        </>
      );
    };

    const getPdfActionButton = () => {
      const {
        id,
        folio,
        OrderFacturaAttachments: orderFacturaAttachments,
      } = selectedInvoice || {};
      const { hasMarketInvoicer, hasPfxCertificate } = business;

      if (!hasMarketInvoicer && hasPfxCertificate) {
        const fileName = `${country}_${orderId}_${folio}`;
        const processedInvoiceId = id;
        return (
          <MenuItem
            size="small"
            onClick={() =>
              dispatch(
                fetchInvoicePDF(
                  processedInvoiceId,
                  fileName,
                  orderType,
                  country
                )
              )
            }
            icon={
              orderInvoicePdfIsDownloading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <CloudDownloadIcon />
              )
            }
          >
            Descargar PDF de la factura
          </MenuItem>
        );
      }
      let actionButtons = (
        <MenuItem icon={<CloudUploadIcon />} onClick={handleUpdloadInvoicePdf}>
          Subir PDF de la factura
        </MenuItem>
      );

      if (
        (orderFacturaAttachments && orderFacturaAttachments?.length) ||
        orderInvoicesAttachments?.find(
          attachment =>
            attachment?.orderInvoiceId === selectedInvoice?.id &&
            attachment?.hasAttachment
        )
      ) {
        actionButtons = (
          <>
            <MenuItem
              size="small"
              onClick={() => dispatch(downloadMarketInvoicerPdfGlobalCl(id))}
              icon={
                orderInvoicePdfIsDownloading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <CloudDownloadIcon />
                )
              }
            >
              Descargar PDF de la factura
            </MenuItem>

            <MenuItem
              size="small"
              icon={
                orderInvoicePdfIsDeleting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <DeleteIcon />
                )
              }
              onClick={handleDeleteInvoicePdf}
            >
              Eliminar PDF de la factura
            </MenuItem>
          </>
        );
      }

      return actionButtons;
    };

    const handleBankAccountDialog = invoice => {
      setSelectedInvoice(invoice);
      setIssuerBusinessId(invoice.issuerBusinessId);
      dispatch(fetchBankAccountsById(entity, invoice.issuerBusinessId));
      setShowRegisterAccountDialog(true);
    };

    const handleShowIsHoldDialog = orderInvoiceId => {
      setInvoiceIdToUpdateIsHold(orderInvoiceId);
      setShowIsHoldDialog(true);
    };

    const handleResetInvoiceIsHoldStore = () => {
      dispatch(resetOrderInvoiceIsHoldStore());
    };

    const handleCloseIsHoldDialog = () => {
      setInvoiceIdToUpdateIsHold(null);
      setShowIsHoldDialog(false);
      handleResetInvoiceIsHoldStore();
    };

    const getIsHoldButton = orderInvoiceId => (
      <Can
        perform={INVOICES_IS_HOLD_UPDATE_PERFORM}
        yes={() => (
          <Tooltip title="Factura temporal">
            <IconButton
              size="small"
              className={classes.iconRed}
              onClick={() => handleShowIsHoldDialog(orderInvoiceId)}
              data-cy="invoicesTableIsHold"
            >
              <ErrorOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        no={() => (
          <Tooltip title="Factura temporal">
            <IconButton
              size="small"
              className={classes.iconRed}
              data-cy="invoicesTableIsHold"
            >
              <ErrorOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      />
    );

    const getCessionAttribute = (isHold, ceded, orderInvoiceId = undefined) => {
      if (isHold && !ceded) {
        return getIsHoldButton(orderInvoiceId);
      }

      const cededLabel = ceded ? 'Factura cedida' : 'Factura sin ceder';
      const cededColor = ceded ? classes.iconGreen : classes.iconGray;
      return (
        <Tooltip title={cededLabel}>
          <IconButton
            size="small"
            className={[cededColor, classes.verifiedMargin]}
            data-cy="invoicesTableCeded"
          >
            C
          </IconButton>
        </Tooltip>
      );
    };

    const getConfirmingAttributes = invoice => {
      const colorBankAccount = invoice.BankAccount
        ? classes.iconGreen
        : classes.iconGray;
      const bankAccountAttribute = (
        <Tooltip title="Cuenta Bancaria">
          <IconButton
            size="small"
            className={[colorBankAccount, classes.verifiedMargin]}
            onClick={() => handleBankAccountDialog(invoice)}
            data-cy="invoicesTableBankAccount"
          >
            B
          </IconButton>
        </Tooltip>
      );
      let registerStatusAttribute;
      switch (invoice.payerRegisterStatus) {
        case 'ENROLLED':
          registerStatusAttribute = (
            <Tooltip title="Enrolado sin PFX">
              <IconButton
                size="small"
                className={classes.Xepelin}
                data-cy="invoicesTableEnrolled"
              >
                <XepelinGreen />
              </IconButton>
            </Tooltip>
          );
          break;
        case 'PFXENROLLED':
          registerStatusAttribute = (
            <Tooltip title="Enrolado con PFX">
              <IconButton
                size="small"
                className={classes.Xepelin}
                data-cy="invoicesTableEnrolledPFX"
              >
                <Xepelin />
              </IconButton>
            </Tooltip>
          );
          break;
        case 'DEFAULT':
        default:
          registerStatusAttribute = (
            <Tooltip title="No enrolado">
              <IconButton
                size="small"
                className={classes.Xepelin}
                data-cy="invoicesTableNotEnrolled"
              >
                <XepelinDefault />
              </IconButton>
            </Tooltip>
          );
          break;
      }
      return { bankAccountAttribute, registerStatusAttribute };
    };

    const getVerifiedAttribute = invoice => {
      const { verificationReason, isVerifiedOrderInvoice, verificationStatus } =
        invoice;
      const verificationStatuses = verificationStatus
        ? `INVOICE_${verificationStatus}`
        : verificationStatus;

      let verifiedLabel = isVerifiedOrderInvoice
        ? GLOBAL_REASONS_WITH_XTREE.find(
            reason =>
              reason?.type === verificationStatuses &&
              reason?.value === verificationReason
          )?.label
        : 'No verificada';

      verifiedLabel =
        verificationStatuses === 'INVOICE_VERIFICATION_WITHOUT_AMOUNT'
          ? 'Montos no coinciden'
          : verifiedLabel;

      let classColorVerified;
      switch (verificationStatuses) {
        case 'INVOICE_VERIFIED':
        case 'INVOICE_POST_VERIFIED':
          classColorVerified = classes.iconGreen;
          break;
        case 'INVOICE_VERIFICATION_WITHOUT_AMOUNT':
          classColorVerified = classes.iconYellow;
          break;
        default:
          classColorVerified = classes.iconGray;
          break;
      }

      const classVerified = clsx(classColorVerified, classes.verifiedMargin);
      return countryFeatures.orderActions.verificationInvoice ? (
        <Can
          perform={INVOICES_VERIFICATION_PERFORM}
          yes={() => (
            <Tooltip title={verifiedLabel}>
              <IconButton
                size="small"
                className={classVerified}
                onClick={() => handleShowVerifiedDialog(invoice)}
                data-cy="invoicesTableVerified"
              >
                V
              </IconButton>
            </Tooltip>
          )}
          no={() => (
            <Tooltip title={verifiedLabel}>
              <IconButton
                size="small"
                className={classVerified}
                data-cy="invoicesTableVerified"
              >
                V
              </IconButton>
            </Tooltip>
          )}
        />
      ) : null;
    };

    const getAttributes = invoice => {
      const ceded =
        !invoice.ceded &&
        invoice.cessionary === XCAPITAL_CESSIONARY &&
        invoice.cessionStatus === 'SUCCESS';

      const attributes = [];
      if (countryFeatures.orderActions.showCessionAttribute) {
        const cessionAttribute = getCessionAttribute(
          invoice.isHold,
          ceded,
          invoice.id
        );
        attributes.push(cessionAttribute);
      }

      // TODO: check if this  duplicates the field for CL
      if (
        countryFeatures.orderActions.showIsHoldInvoiceAttribute &&
        invoice.isHold
      ) {
        attributes.push(getIsHoldButton(invoice.id));
      }

      if (
        countryFeatures.orderActions.showBankAccountButton &&
        orderType === CONFIRMING
      ) {
        const { bankAccountAttribute, registerStatusAttribute } =
          getConfirmingAttributes(invoice);
        attributes.push(bankAccountAttribute);
        attributes.push(registerStatusAttribute);
      }
      if (orderType === CONFIRMING) return attributes;

      const verifiedAttribute = getVerifiedAttribute(invoice);
      attributes.push(verifiedAttribute);
      if (invoice.hasMerit)
        attributes.push(
          <Tooltip title="> 8 Días">
            <Box
              className={classes.containerIcon}
              data-cy="invoicesTableHasMerit"
            >
              <CheckCircle
                area-label="Code Repository"
                className={classes.checkCircleGreaterThan8Days}
              />
            </Box>
          </Tooltip>
        );
      if (invoice.hasAck)
        attributes.push(
          <Tooltip title={DEH_DESCRIPTION}>
            <Box
              className={classes.containerIcon}
              data-cy="invoicesTableHasAck"
            >
              <CheckCircle className={classes.checkCircleDehDescripcion} />
            </Box>
          </Tooltip>
        );

      if (invoice.preselected)
        attributes.push(
          <Tooltip title="Preseleccionada">
            <Box
              className={classes.containerIcon}
              data-cy="invoicesTablePreSelected"
            >
              <CheckBoxIcon className={classes.checkCirclePreselected} />
            </Box>
          </Tooltip>
        );
      return attributes;
    };

    const handleSendDebtServiceData = bodyOfDebtService => {
      setRequestDebtServiceData(bodyOfDebtService);
      setShowDebtServiceDialog(true);
    };

    const getDicomIcon = data => {
      const { orderId: reloadId, payerIdentifier, payerName } = data;
      let classColor;
      let dateLastFetch;
      const debtServiceData = bulkDebtService[payerIdentifier];

      const { diffDays, status, createdAt } = debtServiceData || {};

      const amount = debtServiceData?.totalAmount;

      if (
        bulkFetchDebtServiceIsLoading ||
        (consultInvoiceDebtServiceIsLoading &&
          payerIdentifier === requestDebtServiceData.identifier)
      ) {
        return <CircularProgress size={25} />;
      }

      if (!debtServiceData) {
        classColor = classes.dicomGray;
      } else {
        classColor = classes[status];
        dateLastFetch = dateFormatter(createdAt);
      }

      if (debtServiceData) {
        // EL CASO EN QUE EL RUT NO SEA COMERCIAL
        if (amount < 0) {
          return (
            <Tooltip
              title={
                <>
                  <Typography variant="body1">{`Última actualización: ${dateLastFetch}`}</Typography>
                  <Typography variant="body1">
                    Rut sin giro comercial
                  </Typography>
                </>
              }
            >
              <IconButton className={classColor}>D</IconButton>
            </Tooltip>
          );
        }
        const getDicomButton = (active = false) => {
          let props = {
            className: classColor,
          };
          if (active) {
            props = {
              ...props,
              onClick: () =>
                handleSendDebtServiceData({
                  identifier: payerIdentifier,
                  totalAmount: amount,
                  name: payerName,
                  diffDays,
                  reloadId,
                }),
            };
          }
          return (
            <Tooltip
              title={
                <>
                  <Typography variant="body1">{`Última actualización: ${dateLastFetch}`}</Typography>
                  <Typography variant="body1">
                    Monto:{' '}
                    <CountryFormatHelper
                      value={amount || 0}
                      variant="currency"
                      countryId={country}
                    />
                  </Typography>
                </>
              }
            >
              <IconButton {...props}>D</IconButton>
            </Tooltip>
          );
        };
        // SI TIENE DATOS
        return (
          <Can
            perform={BUSINESS_DICOM_PERFORM}
            yes={() => getDicomButton(true)}
            no={() => getDicomButton()}
          />
        );
      }

      // SI NO HAY DATA
      return (
        <Can
          perform={BUSINESS_DICOM_PERFORM}
          yes={() => (
            <Tooltip
              title={
                <Typography variant="body1">No hay datos de dicom</Typography>
              }
            >
              <IconButton
                className={classColor}
                onClick={() =>
                  handleSendDebtServiceData({
                    identifier: payerIdentifier,
                    totalAmount: null,
                    name: payerName,
                    diffDays: null,
                    reloadId,
                  })
                }
              >
                D
              </IconButton>
            </Tooltip>
          )}
          no={() => (
            <Tooltip
              title={
                <Typography variant="body1">No hay datos de dicom</Typography>
              }
            >
              <IconButton className={classColor}>D</IconButton>
            </Tooltip>
          )}
        />
      );
    };

    const getDebtSuperValue = (debtSuperData = {}) => {
      const { currentDebt } = debtSuperData;

      if (!currentDebt) {
        return 'Sin información de deuda súper';
      }
      return (
        <>
          Deuda directa vigente:{' '}
          <CountryFormatHelper
            value={currentDebt * 1000}
            countryId="CL"
            variant="currency"
          />
        </>
      );
    };

    const handleSendDebtSuperData = bodyOfDebtSuper => {
      setRequestDebtSuperData(bodyOfDebtSuper);
      setShowDebtSuperDialog(true);
    };

    const getDebtSuperIcon = data => {
      const { payerIdentifier, payerName } = data;
      const debtSuperData = bulkDebtSuper[payerIdentifier];
      const currentDebt = debtSuperData?.currentDebt;
      const diffDays = debtSuperData?.diffDays;

      const diffDaysToDialog = diffDays >= 0 ? diffDays : null;

      const propStyles =
        currentDebt > 0 ? classes.debtSuperRed : classes.debtSuperGray;

      const bodyToCreateConsultDebtSuper = {
        identifier: payerIdentifier,
        businessName: payerName,
        diffDays: diffDaysToDialog,
        currentDebt,
      };

      return bulkFetchDebtSuperIsLoading ||
        (consultInvoiceDebtSuperIsLoading &&
          payerIdentifier === requestDebtSuperData.identifier) ? (
        <CircularProgress size={25} />
      ) : (
        <Tooltip title={getDebtSuperValue(debtSuperData)}>
          <Grid>
            <IconButton
              className={propStyles}
              onClick={() =>
                handleSendDebtSuperData(bodyToCreateConsultDebtSuper)
              }
            >
              S
            </IconButton>
          </Grid>
        </Tooltip>
      );
    };

    const getShareablePdfButton = (orderFactura = {}) => {
      const { id, externalUrl } = orderFactura;

      return (
        <MenuItem
          icon={<ShareIcon />}
          onClick={() =>
            dispatch(
              externalUrl
                ? copyExternalPdfURL(externalUrl)
                : getExternalPdfURL(id)
            )
          }
        >
          Copiar URL para externos
        </MenuItem>
      );
    };

    const translatedCessionStatus = rowData => {
      const { cessionStatus, cessionId, cessionStatusSii } = rowData;
      switch (cessionStatus) {
        case 'PROCESS':
          return <CircularProgress size={25} />;
        case 'FAILED':
          return (
            <Tooltip title={cessionStatusSii || 'Error'}>
              <CloseIcon style={{ color: red[500] }} />
            </Tooltip>
          );
        case 'SUCCESS':
          return (
            <span className={(classes.boldFont, classes.displayContents)}>
              <CheckIcon style={{ color: green[500] }} />
              <Tooltip title="Bajar AEC">
                <IconButton aria-label="download aec">
                  <ArrowDownwardIcon
                    size="small"
                    onClick={() => dispatch(downloadAecInvoice(cessionId))}
                  />
                </IconButton>
              </Tooltip>
            </span>
          );

        default:
          return cessionStatus;
      }
    };

    const handleShowFundDialog = invoice => {
      setSelectedInvoice(invoice);
      setShowFundDialog(true);
    };

    const handleShowDiscountDialog = invoice => {
      setSelectedInvoice(invoice);
      setShowDiscountDrawer(true);
    };

    const handleShowBillDatesDialog = invoice => {
      setSelectedInvoice(invoice);
      setShowBillDatesDialog(true);
    };

    const getTotalOrdersRender = totalOrdersTransferred => {
      return loadingFacturas || loadingTotalOrdersTransferred ? (
        <CircularProgress size={25} />
      ) : (
        <Tooltip title="Operaciones Transferidas">
          <Badge
            classes={
              totalOrdersTransferred === 0
                ? { badge: classes.noTransferredOrders }
                : {}
            }
            badgeContent={
              totalOrdersTransferred ? totalOrdersTransferred.toString() : '0'
            }
            color="primary"
          />
        </Tooltip>
      );
    };

    const handleShowInvoiceStatusDialog = invoice => {
      const { status, Relationships: relationship } = invoice;
      if (status === STATUS_REPLACED && relationship) {
        const { rutReceptor, mntTotal } = invoice;
        const { orderId } = relationship;
        dispatch(fetchOrdersIdByPayerRut(rutReceptor, mntTotal));
        dispatch(fetchFacturasIdByOrderId(orderId, mntTotal));
      }
      setSelectedInvoice(invoice);
      setShowInvoiceStatusDialog(true);
    };

    const copyToClipboard = (value = null) => {
      copy(value);
      if (value) {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
            variant: 'success',
          })
        );
      } else {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
            variant: 'error',
          })
        );
      }
    };

    const availableDiscountStatus = {
      CL: [
        STATUS_IN_REVIEW,
        STATUS_REVIEWED,
        STATUS_APPEALED,
        STATUS_APPROVED,
        STATUS_APPROVED_FUND,
      ],
      MX: [STATUS_IN_REVIEW, STATUS_REVIEWED, STATUS_APPEALED],
    };

    const getInvoicesColumns = () => {
      let columns = [
        {
          title: 'Folio',
          field: 'folio',
          render: rowData => (
            <Tooltip title="Copiar Folio Fiscal">
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  copyToClipboard(rowData?.identifier);
                }}
              >
                {getFolioElement(rowData)}
              </Link>
            </Tooltip>
          ),
        },
        {
          title: 'Atributos',
          align: 'center',
          type: 'boolean',
          sorting: false,
          ...(country === COUNTRY_CODE_CL && {
            field: 'cessionStatus',
            lookup: {
              SUCCESS: 'Cedidas',
            },
          }),
          render: rowData =>
            fetchOrderInvoicesAttributesIsLoading ? (
              <CircularProgress size={15} />
            ) : (
              <Box display="flex">{getAttributes(rowData)}</Box>
            ),
        },
        {
          title: `${orderType === CONFIRMING ? 'Proveedor' : 'Receptor'}`,
          field: orderType === CONFIRMING ? 'issuerName' : 'payerName',
          type: 'string',
          align: 'center',
          render: rowData => {
            const payerName =
              orderType === CONFIRMING ? rowData.issuerName : rowData.payerName;
            return (
              <Typography
                variant="body2"
                color="textPrimary"
                data-cy="invoicesTablePayerName"
              >
                {payerName}
              </Typography>
            );
          },
        },
        {
          title: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)} ${
            orderType === CONFIRMING ? 'proveedor' : 'receptor'
          }`,
          field:
            orderType === CONFIRMING ? 'issuerIdentifier' : 'payerIdentifier',
          align: 'center',
          render: rowData => {
            const payerIdentifier =
              orderType === CONFIRMING
                ? rowData.issuerIdentifier
                : rowData.payerIdentifier;
            return (
              <Typography
                align="center"
                variant="body2"
                color="textPrimary"
                data-cy="invoicesTablePayerIdentifier"
              >
                {payerIdentifier}
              </Typography>
            );
          },
        },
        {
          title: `${
            orderType !== CONFIRMING ? 'Fecha emisión' : 'Días de emisión'
          }`,
          field: 'issueDate',
          align: 'center',
          customFilterAndSearch: (term, rowData) =>
            String(dateFormatter(rowData.issueDate)).includes(term),
          render: rowData => (
            <IssueDateChip data={rowData} data-cy="invoicesTableIssuedDate" />
          ),
        },
        {
          title: 'Fecha Vencimiento',
          field: 'expirationDate',
          align: 'center',
          customFilterAndSearch: (term, rowData) =>
            String(dateFormatter(rowData.expirationDate)).includes(term),
          render: rowData => (
            <EndDateChip data={rowData} data-cy="invoicesTableExpirationDate" />
          ),
        },
        {
          title: 'Monto total',
          field: 'amount',
          align: 'center',
          render: rowData => {
            return (
              <InvoiceAmountChip
                data-cy="invoicesTableTotalAmount"
                amount={rowData.amount}
                discounts={rowData.invoiceDiscounts}
                amountCreditNotes={rowData.totalAmountCreditNotes}
                isLoading={fetchAmountInfoIsLoading}
                isClaimed={rowData.isClaimed}
                isCancelled={rowData.isCancelled}
                country={country}
                orderType={orderType}
                currency={currency}
                onClick={
                  checkAuth(roles, INVOICES_DISCOUNTS_PERFORM, rules) &&
                  availableDiscountStatus[country].includes(rowData.status)
                    ? () => handleShowDiscountDialog(rowData)
                    : null
                }
              />
            );
          },
        },
        {
          title: 'Días de retención',
          field: 'availableDays',
          align: 'center',
          hidden:
            orderType === CONFIRMING ||
            !invoices.some(({ availableDays }) => availableDays),
          render: rowData => <RetentiontDaysChip data={rowData} />,
        },
        {
          title: 'Monto de retención',
          field: 'amountRetention',
          align: 'center',
          hidden: !invoices.some(({ amountRetention }) => amountRetention),
          render: rowData => (
            <MontoRetentionChip
              data={rowData}
              data-cy="invoicesTableAmountRetention"
            />
          ),
        },
        {
          title: 'Tasa',
          field: 'baseRate',
          align: 'center',
          render: rowData => {
            return (
              <Typography
                variant="body2"
                color="textPrimary"
                data-cy="invoicesTableBaseRate"
              >
                <CountryFormatHelper
                  value={rowData.baseRate}
                  variant="percentage"
                  countryId={country}
                />
              </Typography>
            );
          },
        },
        {
          title: 'Fondos',
          type: 'string',
          align: 'center',
          sorting: false,
          render: rowData => {
            const orderInvoiceFund = orderInvoicesFund.find(
              orderInvoiceFund => orderInvoiceFund.orderInvoiceId === rowData.id
            );
            const fund = orderInvoiceFund
              ? funds.find(fund => fund.id === orderInvoiceFund.fundId)
              : null;
            const color = orderInvoiceFund ? 'primary' : 'disabled';
            return loadingFunds || fundRatesFundIsLoading ? (
              <CircularProgress color="primary" size={25} />
            ) : orderInvoiceFund ? (
              <Button
                variant="text"
                color="primary"
                onClick={() => handleShowFundDialog(rowData)}
              >
                {fund?.name?.length > 8
                  ? `${fund?.name?.substring(0, 9)}...`
                  : fund?.name}
              </Button>
            ) : (
              <IconButton
                color={color}
                onClick={() => handleShowFundDialog(rowData)}
              >
                <LinkIcon />
              </IconButton>
            );
          },
        },
        {
          title: 'Estado factura',
          field: 'status',
          type: 'string',
          align: 'center',
          lookup:
            country === COUNTRY_CODE_CL ? INVOICE_STATUS_CL : INVOICE_STATUS_MX,
          render: rowData => {
            return (
              <Can
                perform={INVOICES_EDIT_PERFORM}
                yes={() => (
                  <StatusBadge
                    status={rowData.status}
                    handleClick={() => handleShowInvoiceStatusDialog(rowData)}
                    data-cy="invoicesTableInvoiceStatus"
                  />
                )}
                no={() => <StatusBadge status={rowData.status} />}
              />
            );
          },
        },
        {
          title: 'X-tree',
          align: 'center',
          field: 'xtreeStatus',
          sorting: false,
          render: rowData => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={40}
            >
              <XtreeBadge status={rowData.xtreeStatus} />
            </Box>
          ),
        },
        {
          title: 'Reemplazo',
          render: rowData => {
            if (rowData.replaceOperationId) {
              if (
                rowData.status === STATUS_ACTIVE ||
                rowData.status === STATUS_HARD_COLLECTION
              ) {
                return (
                  <Typography variant="body2" color="textPrimary">
                    En proceso
                  </Typography>
                );
              }
              if (
                rowData.status === STATUS_PAID ||
                rowData.status === STATUS_COMPLETE
              ) {
                return (
                  <Typography variant="body2" color="textPrimary">
                    <Link
                      component={RouterLink}
                      to={`/${country.toLowerCase()}/orders/${
                        rowData.replaceOperationId
                      }`}
                    >
                      {rowData.replaceOperationId}
                    </Link>
                  </Typography>
                );
              }
            }
            return (
              <Typography variant="body2" color="textPrimary">
                -
              </Typography>
            );
          },
        },
        {
          title: 'Fechas factura',
          align: 'center',
          sorting: false,
          render: rowData => (
            <Tooltip title="Ver fechas">
              <IconButton
                onClick={() => handleShowBillDatesDialog(rowData)}
                data-cy="invoicesTableShowBillDatesDialogButton"
              >
                <CalendarIcon />
              </IconButton>
            </Tooltip>
          ),
        },
        {
          title: 'Puntaje',
          field: 'score',
          align: 'center',
          editable: 'never',
          hidden: orderType === CONFIRMING,
          render: rowData => {
            return (
              <InvoiceSignalScore
                score={rowData.score}
                latestScoreUpdate={rowData.latestScoreUpdate}
                orderId={rowData.orderId}
                hasInvoiceHistory={business?.hasInvoiceHistory}
              />
            );
          },
        },
        {
          title: 'Semáforo',
          field: 'level',
          headerStyle: {
            textAlign: 'left',
          },
          align: 'center',
          hidden: orderType === CONFIRMING,
          lookup: RISK,
          render: rowData => {
            return (
              <InvoiceSignalRisk
                level={rowData.level}
                latestLevelUpdate={rowData.latestLevelUpdate}
                invoiceId={rowData.invoiceId}
                hasInvoiceHistory={business?.hasInvoiceHistory}
              />
            );
          },
        },
      ];
      if (country === COUNTRY_CODE_CL) {
        const totalOrdersTransferredColumn = {
          title: 'Operaciones',
          field: 'totalOrdersTransferred',
          type: 'numeric',
          width: '30px',
          align: 'center',
          hidden: orderType !== CONFIRMING,
          render: rowData => {
            const { totalOrdersTransferred } = rowData;
            return getTotalOrdersRender(totalOrdersTransferred);
          },
        };

        columns.splice(1, 0, totalOrdersTransferredColumn);

        const cessionStatusColumn = {
          title: 'Cesión',
          field: 'cessionStatus',
          type: 'string',
          align: 'center',
          lookup: CESSION_STATUS,
          render: rowData => {
            return (
              <Grid container direction="row">
                <Grid item xs={12} className={classes.cessionStatusRow}>
                  {rowData.cessionStatus ? (
                    translatedCessionStatus(rowData)
                  ) : (
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      component="div"
                    >
                      <Box fontWeight="fontWeightBold"> Sin cesión</Box>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            );
          },
        };

        columns.splice(11, 0, cessionStatusColumn);

        const debtsColumns = [
          {
            title: t(t13s.LABEL.DEBT_SERVICE_NAME),
            render: rowData => getDicomIcon(rowData),
          },
          {
            title: SUPER_DEBT,
            render: rowData => getDebtSuperIcon(rowData),
          },
        ];

        columns = [...columns, ...debtsColumns];
      }

      return columns;
    };

    const handleSubmitFundFinancing = (
      fundFinancing,
      orderInvoiceFundId = null
    ) => {
      const newFundFinancing = {
        ...fundFinancing,
        operationType: updatedOrder.orderType,
        orderInvoiceId: selectedInvoice?.id,
      };
      if (selectedInvoice) {
        const { orderId } = selectedInvoice;
        if (!orderInvoiceFundId) {
          dispatch(addFundFinancingToInvoice(orderId, newFundFinancing));
        } else {
          dispatch(
            updateOrderInvoiceFund(
              orderId,
              orderInvoiceFundId,
              newFundFinancing
            )
          );
        }
      } else {
        const [{ orderId }] = selectedInvoices;
        const invoices = selectedInvoices.map(({ id }) => id);
        // eslint-disable-next-line no-param-reassign
        delete newFundFinancing.orderInvoiceId;
        dispatch(
          addFundFinancingToBulkInvoices(orderId, {
            invoices,
            fundFinancing,
          })
        );
      }
      setShowFundDialog(false);
      handleShowLoader();
    };

    const handleShowBulkFundDialog = () => {
      setSelectedInvoice(undefined);
      setShowFundDialog(true);
    };

    const handleCloseFundDialog = () => {
      setShowFundDialog(false);
    };

    const getValidProperty = (value, defaultValue) => {
      return value !== null ? value : defaultValue;
    };

    const mapInvoice = (invoice, data = {}) => {
      const invoiceData = { ...data };
      Object.keys(invoice).forEach(key => {
        if (key === 'tasaBase') {
          invoiceData[key] = getValidProperty(data.baseRate, invoice[key]);
        } else {
          invoiceData[key] = getValidProperty(data[key], invoice[key]);
        }
      });
      const {
        status,
        statusReason,
        fechaVencimiento,
        paymentDate = null,
        paymentConfirmed = null,
        fundExpirationDate,
        fundPaymentDate = null,
        verificationStatus,
        verificationReason,
        expirationDate,
        baseRate,
        defaultDate,
        conciliationDate,
      } = invoiceData;
      return {
        ...invoice,
        defaultDate,
        conciliationDate,
        status,
        statusReason,
        fechaVencimiento,
        paymentDate,
        paymentConfirmed,
        fundExpirationDate,
        fundPaymentDate,
        verificationStatus,
        verificationReason,
        expirationDate,
        baseRate,
      };
    };

    const handleSubmitBulkDialog = ({
      item: invoices,
      data,
      notCalculateSurplus,
    }) => {
      const orderFacturas = invoices.map(factura => mapInvoice(factura, data));
      const [{ orderId, businessId }] = invoices;
      dispatch(
        bulkUpdateFacturas(
          { orderId, businessId, orderFacturas },
          {
            notCalculateSurplus,
          }
        )
      );
    };

    const handleSubmitDebtServiceDialog = () => {
      const { identifier } = requestDebtServiceData;
      dispatch(consultInvoiceDebtService(business.id, 'business', identifier));
      setShowDebtServiceDialog(false);
    };

    const handleSubmitDebtSuperDialog = () => {
      const { identifier } = requestDebtSuperData;
      dispatch(consultInvoiceDebtSuper(identifier));
      setShowDebtSuperDialog(false);
    };

    const handleSubmitBankAccountDialog = ({
      bankId,
      accountType,
      accountNumber,
      accountHolder,
      sourceIdentifier,
      accountEmail,
      alias,
    }) => {
      const bankAccountDataObj = {
        bankId,
        accountType,
        accountNumber,
        accountHolder,
        sourceIdentifier,
        accountEmail,
        alias,
      };

      const sourceId = selectedInvoice.issuerBusinessId;
      if (isRegisterBankAccount) {
        dispatch(registerBankAccount(entity, bankAccountDataObj));
      } else {
        dispatch(
          editBankAccount(
            entity,
            sourceId,
            bankAccountDataObj,
            selectedBankAccount.id
          )
        );
      }
    };

    const handleCloseBankAccountDialog = () => {
      setSelectedInvoice(undefined);
      setSelectedBankAccount(null);
      setIsRegisterBankAccount(false);
      setShowRegisterAccountDialog(false);
    };

    const handleCloseMenu = event => {
      event.persist();
      setAnchorEl(null);
      setSelectedInvoice(undefined);
    };

    const handleClickMenu = (event, invoice) => {
      event.persist();
      setAnchorEl(event.currentTarget);
      setSelectedInvoice(invoice);
    };

    const handleSubmitUploadDocument = async files => {
      const { id } = selectedInvoice;
      dispatch(uploadOrderInvoicePdf(id, files[0]?.file));
    };

    const getActionIcon = () => {
      const loadingActions =
        !gettingURL.indexOf(selectedInvoice?.id) ||
        selectedInvoice?.siiFacturaId === loadingInvoiceId ||
        loadingInvoiceId ||
        downloadingPdf;
      return loadingActions ? (
        <CircularProgress size={25} />
      ) : (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          data-cy="invoicesTableActionButton"
        >
          <MoreVertIcon />
        </IconButton>
      );
    };

    const handleShowEditInvoiceDialog = () => {
      setShowEditInvoiceDialog(true);
      setAnchorEl(null);
    };

    const handleShowHistoryDialog = () => {
      setAnchorEl(null);
      handleHistoryLogDialog(selectedInvoice?.id, CONTEXT_TYPES.orderFacturas);
    };

    const handleSubmitStatusInvoiceDialog = ({
      status,
      statusReason,
      fundPaymentDate,
      defaultDate,
      paymentDate,
      destinationId,
      oldStatus,
      notCalculateSurplus,
    }) => {
      let invoiceData = {
        ...selectedInvoice,
        status,
        destinationId,
        statusReason,
        oldStatus,
      };
      const { id, orderId: operationId } = selectedInvoice;
      switch (status) {
        case STATUS_PAID:
          invoiceData = { ...invoiceData, fundPaymentDate };
          break;
        case STATUS_DEFAULT:
          invoiceData = { ...invoiceData, defaultDate };
          break;
        case STATUS_IN_CONCILIATION:
          invoiceData = { ...invoiceData, paymentDate };
          break;
        default:
          break;
      }
      dispatch(
        updateOrderInvoice(id, operationId, invoiceData, {
          fetchOrder: true,
          notCalculateSurplus,
        })
      );
    };

    const handleFetchInvoiceSelectedOrder = orderId => {
      const totalAmount = selectedInvoice.amount;
      const orderTypeSelected = updatedOrder?.orderType;
      if (orderId && orderId !== '') {
        dispatch(
          fetchFacturasIdByOrderId(orderId, totalAmount, orderTypeSelected)
        );
      } else {
        dispatch(resetStateReplacedInvoice());
      }
    };

    const actions = countryFeatures.orderActions.showInvoicesActionButton
      ? [
          {
            icon: () => getActionIcon(),
            tooltip: 'Más opciones',
            position: 'row',
            onClick: (event, rowData) => handleClickMenu(event, rowData),
          },
        ]
      : [];

    const resetSelectedRows = () => {
      handleRowSelection([]);
      if (tableRef?.current) {
        tableRef.current.onAllSelected(false);
      }
    };

    const deleteSelectedInvoices = deleteInvoiceReason => {
      if (selectedInvoices.length === 0) return;

      const orderInvoiceIds = selectedInvoices.map(i => i.id);
      dispatch(
        manageOrderInvoices(orderId, {
          action: 'delete',
          orderInvoiceIds,
          deleteInvoiceReason,
        })
      );
    };

    const moveSelectedInvoices = newOrderId => {
      if (selectedInvoices.length === 0) return;

      const orderInvoiceIds = selectedInvoices.map(i => i.id);

      dispatch(
        manageOrderInvoices(orderId, {
          action: 'move',
          orderInvoiceIds,
          newOrderId,
        })
      );
    };

    const addSelectedInvoices = invoices => {
      if (!invoices.length) return;
      const formatedInvoices = invoices.map(({ id, expirationDate, rate }) => {
        return { id, expirationDate, rate };
      });

      dispatch(
        manageOrderInvoices(orderId, {
          action: 'add',
          invoices: formatedInvoices,
        })
      );
    };

    const closeDrawer = () => {
      setUseMoveDrawer(false);
      setUseAddDrawer(false);
    };

    const sumInvoices = selectInvoices => {
      return selectInvoices?.reduce((sum, invoice) => sum + invoice.amount, 0);
    };

    const fixedDecimalAmount = amount => {
      switch (country) {
        case COUNTRY_CODE_CL:
          return amount.toFixed(0);
        case COUNTRY_CODE_MX:
          return amount.toFixed(DECIMAL_PRECISION);
        default:
          return amount;
      }
    };

    const manageOrderInvoicesJSX = () => {
      if (
        !countryFeatures.orderActions.showDeleteInvoice &&
        !countryFeatures.orderActions.showMoveInvoice
      ) {
        return <></>;
      }

      return (
        <>
          <ManageInvoiceDrawer
            show={useMoveDrawer || useAddDrawer}
            closeDrawer={closeDrawer}
            selectedInvoices={useAddDrawer ? [] : selectedInvoices}
            manageSelectedInvoices={
              useAddDrawer ? addSelectedInvoices : moveSelectedInvoices
            }
            drawerType={useAddDrawer ? 'add' : 'move'}
            order={updatedOrder}
            country={country}
          />
          {showDeleteInvoice && (
            <DeleteInvoiceDialog
              isOpen={showDeleteInvoice}
              handleCloseDialog={() => setShowDeleteInvoice(false)}
              handleSubmit={deleteSelectedInvoices}
              isLoading={manageOrderInvoicesIsLoading}
              error={manageOrderInvoicesError}
            />
          )}
          {showPaymentCessionDialog && (
            <CessionDialog
              open={showPaymentCessionDialog}
              handleClose={() => setShowPaymentCessionDialog(false)}
              selectedInvoices={selectedInvoices}
            />
          )}

          <ActionBar
            count={selectedInvoices.length}
            type="facturas"
            customMessage={
              <Box display="flex" alignItems="center">
                <Tooltip title="Copiar al portapapeles" arrow>
                  <Box
                    className={classes.customMessage}
                    onClick={() => {
                      copyToClipboard(
                        fixedDecimalAmount(sumInvoices(selectedInvoices))
                      );
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.cursorPointer}
                    >
                      <Box fontWeight="fontWeightBold">
                        Monto Bruto:{' '}
                        <CountryFormatHelper
                          value={sumInvoices(selectedInvoices)}
                          countryId={country}
                          variant="currency"
                        />
                      </Box>
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Copiar al portapapeles" arrow>
                  <Box
                    className={classes.customMessage}
                    onClick={() => {
                      const total = fixedDecimalAmount(
                        sumInvoices(selectedInvoices) *
                          (orderSummary?.advancePercentage / 100)
                      );
                      copyToClipboard(total);
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.cursorPointer}
                    >
                      <Box fontWeight="fontWeightBold">
                        Monto Anticipado:{' '}
                        <CountryFormatHelper
                          value={
                            sumInvoices(selectedInvoices) *
                            (orderSummary?.advancePercentage / 100)
                          }
                          countryId={country}
                          variant="currency"
                        />
                      </Box>
                    </Typography>
                  </Box>
                </Tooltip>

                <Typography variant="body1">
                  {selectedInvoices.length > 1
                    ? `(${selectedInvoices.length} seleccionadas)`
                    : '(1 seleccionada)'}
                </Typography>
              </Box>
            }
            resetSelectedRows={resetSelectedRows}
            show={showActionBar}
          >
            {countryFeatures.orderActions.showDeleteInvoice && (
              <ActionBarIcon
                tooltipMsg={
                  enableManageOrderInvoice
                    ? 'Eliminar facturas'
                    : 'Alguna de las facturas seleccionadas no se pueden eliminar de la operación'
                }
                onClick={() => setShowDeleteInvoice(true)}
                disabled={!enableManageOrderInvoice}
                IconComponent={DeleteIcon}
              />
            )}
            {countryFeatures.orderActions.showMoveInvoice && (
              <ActionBarIcon
                tooltipMsg={
                  enableManageOrderInvoice
                    ? 'Mover facturas seleccionadas'
                    : 'Alguna de las facturas seleccionadas no se pueden mover de la operación'
                }
                onClick={() => setUseMoveDrawer(true)}
                disabled={!enableManageOrderInvoice}
                IconComponent={MoveTo}
              />
            )}
          </ActionBar>
        </>
      );
    };

    const getOrderInvoiceFund = id => {
      const orderInvoice = orderInvoicesFund.find(
        oif => oif.orderInvoiceId === id
      );
      return (
        orderInvoice && { ...orderInvoice, orderType: updatedOrder.orderType }
      );
    };

    const handleSubmitInvoiceIsHold = () => {
      dispatch(updateOrderInvoiceIsHold(invoiceIdToUpdateIsHold));
    };

    const updateInvoiceAttributes = invoiceId => {
      const invoiceData = invoices.find(i => i.id === invoiceId);
      setSelectedInvoice(invoiceData);
    };

    return (
      <>
        {manageOrderInvoicesJSX()}
        <Container className={classes.tableContainer}>
          {showFacturasBulkUpdateDialog && (
            <FacturasUpdateDialog
              open={showFacturasBulkUpdateDialog}
              handleCloseDialog={handleCloseBulkUpdateDialog}
              type={DIALOG_TYPES.FACTURASBULK}
              handleSubmit={handleSubmitBulkDialog}
              invoices={selectedInvoices}
              isLoading={updatingInvoice}
              warning={holdInvoicesWarningMessage}
              wasUpdated={invoicesBulkWasUpdated}
              statusOrder={updatedOrder.status}
              showBaseRateInput
            />
          )}
          {showFundDialog && (
            <FundDialog
              linkFundData={getOrderInvoiceFund(selectedInvoice?.id)}
              operationType={updatedOrder.orderType}
              linkFundType="ORDER_INVOICE"
              linkFundRates={orderInvoiceFundRates}
              open={showFundDialog}
              handleCloseDialog={handleCloseFundDialog}
              handleSubmit={handleSubmitFundFinancing}
            />
          )}
          {showDebtServiceDialog && (
            <DebtServiceDialog
              open={showDebtServiceDialog}
              handleCloseDialog={() => setShowDebtServiceDialog(false)}
              handleSubmit={handleSubmitDebtServiceDialog}
              debtServiceData={requestDebtServiceData}
            />
          )}
          {showDebtSuperDialog && (
            <DebtSuperDialog
              open={showDebtSuperDialog}
              handleCloseDialog={() => setShowDebtSuperDialog(false)}
              handleSubmit={handleSubmitDebtSuperDialog}
              debtSuperData={requestDebtSuperData}
            />
          )}
          {showEditInvoiceDialog && (
            <EditInvoiceDialog
              open={showEditInvoiceDialog}
              invoice={selectedInvoice}
              handleCloseDialog={handleCloseEditDialog}
            />
          )}
          {showRegisterAccountDialog && (
            <BankAccountDialog
              open={showRegisterAccountDialog}
              bankAccount={selectedBankAccount}
              onSubmit={handleSubmitBankAccountDialog}
              onClose={handleCloseBankAccountDialog}
              isRegister={isRegisterBankAccount}
            />
          )}
          {showInvoiceStatusDialog && (
            <ChangeStatusInvoiceDialog
              open={showInvoiceStatusDialog}
              handleCloseDialog={handleCloseInvoiceStatusDialog}
              handleSubmit={handleSubmitStatusInvoiceDialog}
              invoice={selectedInvoice}
              title="Cambiar estado de la factura"
              isLoading={updatingInvoice}
              error={updateFacturaErrors || fetchOrdersIdByPayerRutError}
              orderIdsToReplace={orderIdsToReplace}
              invoicesReplaced={invoicesReplaced}
              handleFetchInvoiceSelectedOrder={handleFetchInvoiceSelectedOrder}
            />
          )}
          {showDiscountDrawer && (
            <DiscountDrawer
              isOpen={showDiscountDrawer}
              sourceId={selectedInvoice.id}
              sourceType="orderinvoice"
              sourceData={{
                totalAmount: selectedInvoice.amount,
                operationType: orderType,
              }}
              handleCloseDrawer={handleCloseDiscountDrawer}
            />
          )}
          {showUploadDocumentDialog && (
            <UploadFileDialog
              open={showUploadDocumentDialog}
              handleCloseDialog={() => setShowUploadDocumentDialog(false)}
              title="Subir factura"
              description="Al subir un documento nuevo reemplazarás el ya existente"
              handleSubmit={handleSubmitUploadDocument}
              isLoading={uploadingPdf || orderInvoicePdfIsUploading}
              accept=".pdf,.xml"
              error={uploadOrderInvoicePdfError}
            />
          )}
          {showBillDatesDialog && (
            <BillDatesDialog
              open={showBillDatesDialog}
              handleCloseDialog={() => setShowBillDatesDialog(false)}
              selectedInvoice={selectedInvoice}
              statusOrder={updatedOrder.status}
              updateInvoiceAttributes={updateInvoiceAttributes}
            />
          )}

          {showIsHoldDialog && (
            <ChangeIsHoldDialog
              open={showIsHoldDialog}
              handleClose={handleCloseIsHoldDialog}
              handleSubmit={handleSubmitInvoiceIsHold}
              handleResetError={handleResetInvoiceIsHoldStore}
              isLoading={orderInvoiceIsHoldIsUpdating}
              error={updateOrderInvoiceIsHoldError}
            />
          )}

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              icon={<MoneyIcon />}
              onClick={() => setShowPartialPaymentDrawer(true)}
              data-cy="invoicesTablePartialPayment"
            >
              Pagos parciales
            </MenuItem>

            {countryFeatures.orderActions.showDownloadPdfInvoiceButton && (
              <Can
                perform={INVOICES_DOWNLOAD_PDF_PERFORM}
                yes={() =>
                  country === COUNTRY_CODE_MX
                    ? getPdfActionButtonMx()
                    : getPdfActionButton(selectedInvoice)
                }
              />
            )}
            {countryFeatures.orderActions.showInvoiceHistoryChangeButton && (
              <MenuItem
                icon={<HistoryIcon />}
                onClick={handleShowHistoryDialog}
              >
                Historial de cambios
              </MenuItem>
            )}
            {/* TODO: edit exchangeRate per Invoice
            {selectedInvoice?.exchangeRate && (
              <MenuItem
                icon={<BorderColorOutlined />}
                onClick={handleEditExchangeRateDialog}
              >
                Editar Tipo de Cambio
              </MenuItem>
            )} */}
            {countryFeatures.orderActions.showSharableInvoicePdfButton && (
              <Can
                perform={INVOICES_DOWNLOAD_PDF_PERFORM}
                yes={() => getShareablePdfButton(selectedInvoice)}
              />
            )}
            {countryFeatures.orderActions.showEditInvoiceButton && (
              <Can
                perform={INVOICES_BASE_RATE_PERFORM}
                yes={() => (
                  <MenuItem
                    icon={<EditIcon />}
                    onClick={handleShowEditInvoiceDialog}
                  >
                    Editar tasa base
                  </MenuItem>
                )}
              />
            )}
          </Menu>
          <MaterialTable
            columns={getInvoicesColumns()}
            data={mapTableData(invoices)}
            title="Facturas"
            style={{
              display: 'grid',
              width: '100%',
              boxShadow: 'none',
              borderRadius: 8,
              overflow: 'hidden',
            }}
            components={{
              Toolbar: () => (
                <InvoiceToolbar
                  hasPfxCertificate={business?.hasPfxCertificate}
                  handleCession={handleCession}
                  handleShowBulkChangeDialog={() =>
                    setShowFacturasBulkUpdateDialog(true)
                  }
                  handleShowBulkFundDialog={handleShowBulkFundDialog}
                  getFacturas={getFacturas}
                  handleDownloadAEC={handleDownloadAEC}
                  hanldeMassassignableinvoicepdf={
                    hanldeMassassignableinvoicepdf
                  }
                  selectedInvoices={selectedInvoices}
                  openAddInvoices={() => setUseAddDrawer(true)}
                />
              ),
            }}
            actions={actions}
            localization={{
              header: {
                actions: 'Acciones',
              },
            }}
            options={{
              selection: true,
              selectionProps: rowData => ({
                disabled: rowData.tableData.disabled,
              }),
              filtering: true,
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50, 100],
              rowStyle: rowData => ({
                backgroundColor: getRowColor(rowData),
                verticalAlign: 'middle',
              }),
              emptyRowsWhenPaging: false,
              detailPanelType: 'single',
            }}
            onSelectionChange={handleRowSelection}
            isLoading={loadingFacturas || updatingInvoice}
            detailPanel={[
              orderInvoice => {
                const orderInvoiceFund = orderInvoicesFund.find(
                  oif => oif.orderInvoiceId === orderInvoice.id
                );
                return {
                  tooltip: 'Ver detalle',
                  disabled:
                    !countryFeatures.orderActions.showInvoiceDetail &&
                    !orderInvoiceFund,
                  render: () => (
                    <InvoiceDetail
                      orderInvoice={orderInvoice}
                      orderInvoiceFund={orderInvoiceFund}
                      handleShowLoader={handleShowLoader}
                    />
                  ),
                };
              },
            ]}
            tableRef={tableRef}
          />
          <PartialPaymentsDrawer
            isOpen={showPartialPaymentDrawer}
            handleCloseDrawer={() => setShowPartialPaymentDrawer(false)}
            selectedInvoice={selectedInvoice}
            orderType={orderType}
          />
        </Container>
      </>
    );
  }
);

InvoicesTable.propTypes = {
  business: PropTypes.objectOf(Object).isRequired,
  handleShowCessionDialog: PropTypes.func.isRequired,
  handleShowPfxDialog: PropTypes.func.isRequired,
  handleRedirectToOrderById: PropTypes.func.isRequired,
  handleShowVerifiedDialog: PropTypes.func.isRequired,
  handleHistoryLogDialog: PropTypes.func.isRequired,
  handleShowLoader: PropTypes.func.isRequired,
  handleSetSelectedInvoices: PropTypes.func.isRequired,
};

export default InvoicesTable;
