import axios from 'axios';
import {
  FETCH_CREDITS_START,
  FETCH_CREDITS_SUCCESS,
  FETCH_CREDITS_FAILURE,
  UPDATE_CREDIT_START,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_CREDIT_FAILURE,
  REGISTER_CREDIT_START,
  REGISTER_CREDIT_SUCCESS,
  REGISTER_CREDIT_FAILURE,
  FETCH_FEES_START,
  FETCH_FEES_SUCCESS,
  FETCH_FEES_FAILURE,
  FETCH_SELECTED_CREDIT_START,
  FETCH_SELECTED_CREDIT_SUCCESS,
  FETCH_SELECTED_CREDIT_FAILURE,
  UPDATE_FEE_START,
  UPDATE_FEE_SUCCESS,
  UPDATE_FEE_FAILURE,
  REGISTER_FEE_START,
  REGISTER_FEE_SUCCESS,
  REGISTER_FEE_FAILURE,
  DELETE_FEE_START,
  DELETE_FEE_SUCCESS,
  DELETE_FEE_FAILURE,
  DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_START,
  DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE,
  FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_START,
  FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys';
import { loadDocuments } from './documentActions';
import { downloadFile } from '../helpers/fileHandling';

const CREDIT = 'credit';

export const fetchCredits =
  ({ page, limit, searchInput, field, status: rawStatus } = {}) =>
  dispatch => {
    dispatch({ type: FETCH_CREDITS_START });
    const status = rawStatus.length
      ? encodeURIComponent(JSON.stringify(rawStatus))
      : null;
    axios
      .get(`/api/credits`, {
        params: {
          page,
          limit,
          searchInput,
          field,
          status,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_CREDITS_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_CREDITS_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_CREDITS_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const registerCredit =
  ({
    name,
    identifier,
    amount,
    taxITE,
    fees,
    rate,
    period,
    type,
    status,
    startDate,
  }) =>
  dispatch => {
    dispatch({ type: REGISTER_CREDIT_START });
    axios
      .post('/api/credits', {
        name,
        identifier,
        amount,
        taxITE,
        fees,
        rate,
        period,
        type,
        status,
        startDate,
      })
      .then(({ data }) => {
        dispatch({
          type: REGISTER_CREDIT_SUCCESS,
          payload: { newCredit: data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CREDIT_CREATED, {
            variant: 'success',
          })
        );
        dispatch(loadDocuments(data.id, CREDIT));
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: REGISTER_CREDIT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchSelectedCredit = creditId => dispatch => {
  dispatch({ type: FETCH_SELECTED_CREDIT_START });
  axios
    .get(`/api/credits/${creditId}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_SELECTED_CREDIT_SUCCESS,
        payload: { selectedCredit: data },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: FETCH_SELECTED_CREDIT_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_CREDIT_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const updateCredit =
  (
    creditId,
    {
      identifier,
      name,
      amount,
      taxITE,
      fees,
      rate,
      period,
      type,
      status,
      startDate,
    }
  ) =>
  dispatch => {
    dispatch({ type: UPDATE_CREDIT_START });
    axios
      .put(`/api/credits/${creditId}`, {
        identifier,
        name,
        amount,
        taxITE,
        fees,
        rate,
        period,
        type,
        status,
        startDate,
      })
      .then(({ data }) => {
        dispatch({ type: UPDATE_CREDIT_SUCCESS, payload: data });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CREDIT_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_CREDIT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchFees =
  (creditId, { page, limit } = {}) =>
  dispatch => {
    dispatch({ type: FETCH_FEES_START });
    axios
      .get(`/api/credits/${creditId}/fees`, {
        params: {
          page,
          limit,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FETCH_FEES_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_FEES_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_FEES_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const registerFee =
  ({ creditId }) =>
  dispatch => {
    dispatch({ type: REGISTER_FEE_START });
    axios
      .post(`/api/credits/fee`, { creditId })
      .then(({ data }) => {
        dispatch({
          type: REGISTER_FEE_SUCCESS,
          payload: { newCreditFees: data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FEE_CREATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: REGISTER_FEE_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.CREATE_FEE_FAILURE, {
            variant: 'error',
          })
        );
      });
  };

export const updateFee =
  (
    feeId,
    {
      creditId,
      amount,
      estimatedPaymentDate,
      paymentDate,
      interest,
      accruedInterest,
      capital,
      status,
    }
  ) =>
  dispatch => {
    dispatch({ type: UPDATE_FEE_START });
    axios
      .put(`/api/credits/${creditId}/fee/${feeId}`, {
        creditId,
        amount,
        estimatedPaymentDate,
        paymentDate,
        interest,
        accruedInterest,
        capital,
        status,
      })
      .then(({ data }) => {
        dispatch({ type: UPDATE_FEE_SUCCESS, payload: data });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FEE_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(enqueueSnackbar(errorCode, { variant: 'error' }));
        dispatch({
          type: UPDATE_FEE_FAILURE,
        });
      });
  };

export const deleteFee = (feeId, feeData) => dispatch => {
  const { creditId } = feeData;
  dispatch({ type: DELETE_FEE_START });
  axios
    .put(`/api/credits/${creditId}/fee/${feeId}`, feeData)
    .then(() => {
      dispatch({ type: DELETE_FEE_SUCCESS, payload: { id: feeId } });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FEE_DELETED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(enqueueSnackbar(errorCode, { variant: 'error' }));
      dispatch({
        type: DELETE_FEE_FAILURE,
        payload: { errorCode },
      });
    });
};

export const getCYACreditInvoiceDocuments = creditId => dispatch => {
  dispatch({ type: FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_START });
  axios
    .get(`/api/cyabills/credit-bills-mx?creditId=${creditId}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS,
        payload: { cyaInvoiceDocuments: data.creditBills },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_DOCUMENTS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE,
        payload: { errorCode },
      });
    });
};

export const downloadCYACreditInvoiceDocuments =
  (invoiceId, fileExtension = 'pdf') =>
  dispatch => {
    dispatch({ type: DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_START });
    axios
      .get(
        `/api/cyabills/credit-bills-mx/${invoiceId}/download?type=${fileExtension}`
      )
      .then(({ data }) => {
        dispatch({
          type: DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS,
        });
        downloadFile(data.file, invoiceId);
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE,
          payload: { errorCode },
        });
      });
  };
