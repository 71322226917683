import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { uploadOrderInvoicePdfService } from '../services';

export const useUploadOrderInvoicePdf = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isLoading: uploadOrderInvoicePdfLoading,
    isSuccess: uploadOrderInvoicePdfSuccess,
    error: uploadOrderInvoicePdfError,
    mutate: uploadOrderInvoicePdf,
  } = useMutation(
    ({ invoiceId, pdf }) => {
      return uploadOrderInvoicePdfService(invoiceId, pdf);
    },
    {
      onMutate: async params => {
        const { invoiceId } = params;
        await queryClient.cancelQueries('getInvoiceAttachmentsInfo', {
          exact: false,
        });

        const prevData = queryClient.getQueryData('getInvoiceAttachmentsInfo', {
          exact: false,
        });

        const updatedData = produce(prevData, draft => {
          const invoiceIndex = draft.data.findIndex(
            d => d.orderInvoiceId === invoiceId
          );
          // eslint-disable-next-line no-param-reassign
          draft.data[invoiceIndex].hasAttachment = true;
        });

        queryClient.setQueryData('getInvoiceAttachmentsInfo', updatedData);
        return prevData;
      },
      onSuccess: (_res, data) => {
        const { onSuccessUploadPDFCallback } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FILE_UPLOADED, {
            variant: 'success',
          })
        );
        onSuccessUploadPDFCallback();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.ERROR.UPLOAD_INVOICE_PDF, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    uploadOrderInvoicePdfLoading,
    uploadOrderInvoicePdfSuccess,
    uploadOrderInvoicePdfError,
    uploadOrderInvoicePdf,
  };
};

export default useUploadOrderInvoicePdf;
