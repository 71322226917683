import { useQuery } from 'react-query';
import { fetchBanksService } from '../services';

const useFetchBanks = () => {
  const { isLoading, data, refetch } = useQuery(
    'getBanks',
    () => fetchBanksService(),
    { refetchOnWindowFocus: false }
  );

  const { data: banks } = data || { banks: [] };

  return {
    banks,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchBanks };
