import { restFunds } from '../../../../infra/http';
import { purgeNestedObj } from '../../../../commons/utils';
import * as Mapper from './document.map';

export const fetchDocumentsByReceiver = filter => {
  const { receiverIdentifier, ...fields } =
    Mapper.documentFilterMap.toPersistence(filter);
  const params = { ...purgeNestedObj(fields), opportunity: true };

  return restFunds()
    .get(`v2/receiver/${receiverIdentifier}/invoices`, {
      params,
    })
    .then(r => r.data)
    .then(Mapper.documentFilterMap.toDomain);
};

export const fetchDocumentById = id => {
  return restFunds()
    .get(`/invoice/${id}`)
    .then(r => r.data)
    .then(Mapper.documentMap.toDomain);
};

export const fetchAssignees = filters => {
  const { receiverIdentifier } = filters;

  return restFunds().get(`assignee`, {
    params: { receiverIdentifier, opportunity: true },
  });
};
