import { makeStyles } from '@material-ui/core';
import { GRAY_BORDER_COLOR } from '../../../../../../helpers/Constants';
import {
  ALT_BORDER_COLOR,
  INVOICE_RESUME,
} from '../../../../../../theme/otherColors';

const useStyles = makeStyles(theme => ({
  containerButton: {
    padding: 30,
  },
  classSection: {
    borderRadius: 17,
    border: `1px solid ${GRAY_BORDER_COLOR}`,
    padding: 0,
  },
  containerSimulation: {
    paddingTop: 40,
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginBottom12: {
    marginBottom: 12,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom33: {
    marginBottom: 33,
  },
  retentionPositive: {
    color: theme.palette.success.main,
  },
  retentionNegative: {
    color: theme.palette.error.main,
  },
  buttonLabel: {
    color: theme.palette.primary.main,
  },
  containerIconButtonDataList: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  resumeContainer: {
    backgroundColor: INVOICE_RESUME.altBackgroundColor,
    borderRadius: '15px',
    padding: '20px',
    width: 'min-content',
    minWidth: '400px',
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
  },
  sideResumeContainer: {
    background: INVOICE_RESUME.altBackgroundColor,
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
    boxSizing: 'border-box',
    borderRadius: '0px 17px 17px 0px',
    padding: '19px',
    paddingRight: '0',
    position: 'absolute',
    top: '0',
    left: '100%',
    marginLeft: '20px',
    marginTop: '-20px',
    width: 'max-content',
  },
  invoiceAmountContainer: {
    width: '95%',
    padding: '10px 54px',
    background: INVOICE_RESUME.backgroundColor,
    borderRadius: '7px',
    color: INVOICE_RESUME.textColor,
    margin: '10px auto 20px auto',
  },
  invoiceAmountContainerEmpty: {
    background: INVOICE_RESUME.emptyBackgroundColor,
    color: INVOICE_RESUME.emptyTextColor,
  },
  relativeContainer: {
    position: 'relative',
    width: '100%',
  },
  togglePanel: {
    top: '-4px',
  },
  toggleOpen: {
    color: INVOICE_RESUME.toggleOpen,
  },
  toggleClose: {
    color: INVOICE_RESUME.toggleClose,
  },
}));

export default useStyles;
