import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { Edit as EditIcon } from '../../../../components/icons';
import useGetCreditLine from '../../hooks/useGetCreditLine';
import { RootState } from '../../../../reducers/rootReducer';
import { Country, BusinessSelector, AuthSelector } from '../../schemas';
import { canEditCreditLine } from '../../utils';
import CreditLineIcon from '../icons/CreditLineIcon';
import { riskLink } from '../../constants';

type Props = {
  country: Country;
  isProntoPagoAllowed?: boolean;
};

const CreditLineRiskCard = ({ country, isProntoPagoAllowed }: Props) => {
  const { business } = useSelector(
    (state: RootState) => state.business as BusinessSelector,
  );
  const { user } = useSelector(
    (state: RootState) => state.auth as AuthSelector,
  );

  const { isLoading, creditLine, typeIcon } = useGetCreditLine(
    country,
    business?.id,
  );

  const creditLineDialogEnabled = canEditCreditLine(user?.roles);
  const handleClickEditIcon = () => {
    window.open(
      process.env.NODE_ENV === 'production'
        ? `${riskLink.prod}/${business?.identifier || ''}`
        : riskLink.dev,
      '_blank',
    );
  };

  return (
    <Box p={5} display="flex" alignItems="center">
      <Box sx={{ mr: 2 }}>
        {isLoading ? (
          <Skeleton width={33} height={33} variant="circle" />
        ) : (
          <CreditLineIcon typeIcon={typeIcon} />
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="textSecondary">
          <Box fontWeight="bold" fontSize="12.5">
            Línea de crédito Riesgos
          </Box>
        </Typography>
        <Box sx={{ mt: 1, p: 0.5 }}>
          <Typography variant="subtitle1" color="textPrimary" component="span">
            {isLoading ? (
              <CircularProgress size={14} />
            ) : (
              <Box fontWeight="bold">
                {creditLine}
                {isProntoPagoAllowed && ' - Aplica para Pronto Pago'}
              </Box>
            )}
          </Typography>
        </Box>
      </Box>
      {creditLineDialogEnabled && (
        <Box sx={{ minHeight: 50, ml: 1 }}>
          <IconButton size="medium">
            <EditIcon fontSize="small" onClick={handleClickEditIcon} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CreditLineRiskCard;
