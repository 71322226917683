import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Grid,
  Button,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CountryFormatHelper from './CountryFormatHelper';
import { BORDER_PANEL, DRAWER_BACKGROUND } from '../../theme/otherColors';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
  },
  transferCard: {
    boxShadow: 'none',
    border: `1px solid ${BORDER_PANEL}`,
    borderRadius: 17,
    '& MuiPaper-root': {
      '& .MuiCard-root': {
        borderRadius: 17,
      },
    },
    maxHeight: 325,
    maxWidth: 200,
  },
  list: {
    height: 185,
    overflow: 'auto',
  },
  textFieldSearch: {
    marginBottom: 10,
    backgroundColor: DRAWER_BACKGROUND,
    borderRadius: 17,
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 4,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 8,
    },
  },
  paginator: {
    '& .MuiTablePagination-toolbar': {
      width: '280px',
    },
    '& .MuiTablePagination-selectRoot': {
      marginLeft: '0px',
      marginRight: '15px',
      fontSize: '11px',
    },
    '& .MuiTablePagination-actions': {
      marginLeft: '0px',
    },
    '& .MuiTablePagination-caption': {
      fontSize: '11px',
    },
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    width: 20,
    height: 30,
    marginBottom: 5,
    borderRadius: 10,
    paddingLeft: 25,
    paddingRight: 13,
  },
  searcher: {
    padding: '0px 10px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
});

const RIGHT = 'right';
const LEFT = 'left';

const intersection = (a, b) => a.filter(value => b.indexOf(value) !== -1);

const not = (a, b) => a.filter(value => b.indexOf(value) === -1);

const union = (a, b) => [...a, ...not(b, a)];

const TransferList = props => {
  const {
    handleSelected,
    titleChoices,
    titleChosen,
    primaryField,
    secondaryField,
    selected,
    data,
  } = props;
  const classes = useStyles();
  const [page] = useState({ left: 0, right: 0 });
  const [rowsPerPage] = useState({
    left: 100,
    right: 100,
  });
  const [checked, setChecked] = useState([]);
  const [textFilter] = useState({ left: '', right: '' });
  const [right, setRight] = useState(selected.slice(0, rowsPerPage.right));
  const [left, setLeft] = useState(
    not(data, selected).slice(0, rowsPerPage.left)
  );
  const leftData = not(data, selected);
  const leftChecked = intersection(checked, leftData);
  const rightChecked = intersection(checked, selected);
  const [paginateTotalCount, setPaginateTotalCount] = useState({
    left: leftData.length,
    right: selected.length,
  });

  const resetTable = (tableData, rows = 100, side) => {
    page[side] = 0;
    return tableData.slice(0, rows);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newRightData = selected.concat(leftChecked);
    const newLefttData = not(leftData, leftChecked);
    setRight(resetTable(newRightData, rowsPerPage.right, RIGHT));
    setLeft(resetTable(newLefttData, rowsPerPage.left, LEFT));
    setChecked(not(checked, leftChecked));
    handleSelected(newRightData);
    setPaginateTotalCount({
      right: newRightData.length,
      left: newLefttData.length,
    });
  };

  const handleCheckedLeft = () => {
    const newRightData = not(selected, rightChecked);
    const newLefttData = leftData.concat(rightChecked);
    setLeft(resetTable(newLefttData, rowsPerPage.left, LEFT));
    setRight(resetTable(newRightData, rowsPerPage.right, RIGHT));
    setChecked(not(checked, rightChecked));
    handleSelected(newRightData);
    setPaginateTotalCount({
      right: newRightData.length,
      left: newLefttData.length,
    });
  };

  const handleChangeSearch = (e, items, side) => {
    const { value } = e.target;
    textFilter[side] = value;
    const filter = items.filter(item =>
      item[primaryField.field].includes(value)
    );
    let filtered = items;
    let newFilteredData = leftData;

    if (value !== '') {
      filtered = filter.length
        ? resetTable(filter, rowsPerPage[side], side)
        : [];
      paginateTotalCount[side] = filter.length;
      newFilteredData = filter;
    } else {
      const resetData = side === RIGHT ? selected : newFilteredData;
      filtered = resetTable(resetData, rowsPerPage[side], side);
      paginateTotalCount[side] = resetData.length;
    }

    if (side === RIGHT) {
      setRight(filtered);
    } else {
      setLeft(filtered);
    }
  };

  const paginate = (data, pageSize, pageNumber) => {
    if (pageNumber === 0) return data.slice(pageNumber, pageSize);
    return data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  };

  const handleChangePage = (newPage, side) => {
    page[side] = newPage;
    if (side === LEFT) {
      setLeft(paginate(leftData, rowsPerPage[side], newPage));
    } else {
      setRight(paginate(selected, rowsPerPage[side], newPage));
    }
    setPaginateTotalCount({
      right: selected.length,
      left: leftData.length,
    });
  };

  const handleChangeRowsPerPage = (event, side) => {
    const newRowPerPage = parseInt(event.target.value, 10);
    rowsPerPage[side] = newRowPerPage;
    if (side === LEFT) {
      setLeft(resetTable(leftData, newRowPerPage, side));
    } else {
      setRight(resetTable(selected, newRowPerPage, side));
    }
  };

  const clearFilter = side => {
    if (textFilter !== '') {
      const resetData = side === RIGHT ? selected : leftData;
      const filtered = resetTable(resetData, rowsPerPage[side], side);
      paginateTotalCount[side] = resetData.length;
      textFilter[side] = '';
      if (side === RIGHT) {
        setRight(filtered);
      } else {
        setLeft(filtered);
      }
    }
  };

  const CustomCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#0000FF',
      },
    },
    checked: {},
  })(Checkbox);

  const getItems = value => {
    const primaryData = primaryField ? value[primaryField.field] : value;
    const secondaryData = secondaryField ? value[secondaryField.field] : '';
    const labelId = `transfer-list-all-item-${primaryData}-label`;

    return (
      <ListItem
        key={value.id || ''}
        role="listitem"
        button
        onClick={handleToggle(value)}
      >
        <ListItemIcon>
          <CustomCheckbox
            color="primary"
            checked={checked.indexOf(value) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={
            primaryField ? (
              primaryField.type === 'currency' ? (
                <CountryFormatHelper
                  value={primaryData || '-'}
                  variant={primaryField.type}
                  countryId={secondaryField.country}
                />
              ) : (
                primaryData
              )
            ) : (
              ''
            )
          }
          secondary={
            secondaryField ? (
              secondaryField.type === 'currency' ? (
                <CountryFormatHelper
                  value={secondaryData || '-'}
                  variant={secondaryField.type}
                  countryId={secondaryField.country}
                />
              ) : (
                secondaryData
              )
            ) : (
              ''
            )
          }
        />
      </ListItem>
    );
  };

  const customList = (title, items, side) => {
    const listData = side === RIGHT ? selected : leftData;
    return (
      <Card className={classes.transferCard}>
        <CardHeader
          avatar={
            <CustomCheckbox
              color="primary"
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={
            <Typography variant="body2" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">{title}</Box>
            </Typography>
          }
          subheader={`${numberOfChecked(listData)}/${
            listData.length
          } seleccionadas`}
        />

        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.searcher}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Limpiar">
                      <IconButton
                        size="small"
                        onClick={() => clearFilter(side)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              value={textFilter[side]}
              placeholder={`Buscar ${primaryField.field}`}
              variant="outlined"
              onChange={e => handleChangeSearch(e, listData, side)}
              className={classes.textFieldSearch}
            />
          </Grid>
        </Grid>

        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map(value => getItems(value))}
          <ListItem />
        </List>
        <TablePagination
          component="div"
          className={classes.paginator}
          labelRowsPerPage="Filas: "
          nexticonbuttontext="Siguiente"
          rowsPerPageOptions={[100, 300, 500]}
          count={paginateTotalCount[side]}
          page={page[side]}
          onChangePage={(e, page) => handleChangePage(page, side)}
          rowsPerPage={rowsPerPage[side]}
          onChangeRowsPerPage={e => handleChangeRowsPerPage(e, side)}
        />
      </Card>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item>{customList(titleChoices, left, LEFT)}</Grid>
      <Grid item className={classes.containerButtons}>
        <Button
          size="small"
          variant="outlined"
          className={classes.button}
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          data-cy="transferListArrowRightButton"
          startIcon={
            <KeyboardArrowRightIcon
              color={leftChecked.length === 0 ? 'disabled' : 'primary'}
            />
          }
        />
        <Button
          size="small"
          variant="outlined"
          className={classes.button}
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          data-cy="transferListArrowLeftButton"
          startIcon={
            <KeyboardArrowLeftIcon
              color={rightChecked.length === 0 ? 'disabled' : 'primary'}
            />
          }
        />
      </Grid>
      <Grid item>{customList(titleChosen, right, RIGHT)}</Grid>
    </Grid>
  );
};

TransferList.defaultProps = {
  secondaryField: null,
};

TransferList.propTypes = {
  titleChoices: PropTypes.string.isRequired,
  titleChosen: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  selected: PropTypes.arrayOf(Object).isRequired,
  primaryField: PropTypes.objectOf(Object).isRequired,
  secondaryField: PropTypes.objectOf(Object),
  handleSelected: PropTypes.func.isRequired,
};

export default TransferList;
