import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import SkeletonMui from '@material-ui/lab/Skeleton';

const OrderCommentsSkeleton: FC = () => {
  return (
    <>
      <Box mb={3} display="flex" alignItems="center">
        <Box mr={3}>
          <SkeletonMui
            variant="circle"
            width={24}
            height={24}
            animation="wave"
          />
        </Box>
        <SkeletonMui variant="rect" width={151} height={18} animation="wave" />
      </Box>
      {[1, 2, 3].map(() => (
        <Box mb={3}>
          <SkeletonMui
            variant="rect"
            width={537}
            height={18}
            animation="wave"
          />
        </Box>
      ))}
      {[1, 2].map(() => (
        <Box>
          <SkeletonMui
            variant="text"
            width={156}
            height={40}
            animation="wave"
          />
        </Box>
      ))}
      <Box mt={2}>
        <SkeletonMui variant="rect" width={189} height={18} animation="wave" />
      </Box>
    </>
  );
};

export default OrderCommentsSkeleton;
