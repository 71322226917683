/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import BaseDialog from '../../../../../../components/dialogs/BaseDialog';
import imgCheckbox from '../../../../../../assets/check.svg';
import { GET_SUMMARY } from './constants';
import { useStyles } from './FilesAndPayrollDialog.styles';
import { Skeleton } from '../../../../../commons/components/Skeleton';

export const FilesAndPayrollDialog = ({
  isOpen,
  onClose,
  isLoading,
  onReSend,
  summary,
}) => {
  const css = useStyles();
  const summaryFields = GET_SUMMARY(summary, isLoading);

  return (
    <BaseDialog
      isOpen={isOpen}
      handleClose={onClose}
      title={
        isLoading
          ? 'Enviando nómina'
          : `Nómina ID ${summary?.payrollId} enviada`
      }
      description={
        isLoading ? (
          'Enviando la nómina y analizando PDFs de las facturas'
        ) : (
          <>
            <span style={{ fontWeight: 'bold' }}>
              Si deseas enviar la misma nómina a otro fondo
            </span>
            <br /> haz click en Reenviar
          </>
        )
      }
      topIcon={isLoading ? '' : imgCheckbox}
      topIconWidth={41}
    >
      <Box display="flex" className={css.containerSummary}>
        {summaryFields.map(({ value, label }, idx) => (
          <Grid container className={css.itemBox}>
            <Grid item xs={9}>
              <Skeleton
                variant="rect"
                height={13}
                width={160}
                isLoading={isLoading}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={css.spacingBottom}
                  key={idx}
                  style={{ fontWeight: 'bold' }}
                >
                  {label}
                </Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={3} className={css.itemRight}>
              <React.Fragment key={idx}>
                <Skeleton
                  variant="rect"
                  height={13}
                  width={40}
                  isLoading={isLoading}
                >
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                    className={clsx(css.spacingBottom, css.textAlignRight)}
                    style={{ fontWeight: 'bold' }}
                  >
                    {value}
                  </Typography>
                </Skeleton>
              </React.Fragment>
            </Grid>
          </Grid>
        ))}
      </Box>
      {isLoading && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onReSend}
            className={css.loadingButton}
            disabled={isLoading}
          >
            <CircularProgress
              className={css.spinner}
              size={24}
              color="inherit"
            />{' '}
            Cargando...
          </Button>
        </Box>
      )}
      {!isLoading && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onReSend}
            className={css.sendButton}
            fullWidth
          >
            Reenviar
          </Button>
          {/* TODO: falta HU para descarga */}
          {/* <Link
            className={`${!xlsxReady ? 'disabledp' : 'enabled'} ${
              css.xlsxDownloadLink
            }`}
            component="button"
            variant="body1"
            color="primary"
            onClick={onDownload}
          >
            <CloudDownload fill="#1A49E7" style={{ width: 20, height: 17 }} />
            <span style={{ fontWeight: 'bold' }}>
              Descargar lista de documentos sin PDF
            </span>
          </Link> */}
        </Box>
      )}
    </BaseDialog>
  );
};

FilesAndPayrollDialog.defaultProps = {
  isLoading: false,
  isOpen: false,
  summary: null,
};

FilesAndPayrollDialog.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReSend: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  summary: PropTypes.any,
};
