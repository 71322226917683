import React, { useCallback, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PAYMENTS } from '../../../helpers/Constants';
import { usePayroll } from '../../inbox/infrastructure/store';
import {
  useFilters,
  useGetFilters,
  usePage,
  useItemsPerPage,
  useSetPage,
  useSetItemsPerPage,
  useClearSelectedPayrolls,
  useResetFilters,
} from '../../inbox/infrastructure/store/PaymentInboxStore';

const usePaymentInbox = () => {
  const { country } = useSelector((state: RootStateOrAny) => state.config);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const pathnameSplited = pathname.split('/') || [];
  const [operationType] = useState(
    pathnameSplited.length
      ? pathnameSplited[pathnameSplited.length - 1].toUpperCase()
      : PAYMENTS
  );

  const page = usePage();
  const itemsPerPage = useItemsPerPage();
  const setPage = useSetPage();
  const setItemsPerPage = useSetItemsPerPage();
  const clearSelectedPayrolls = useClearSelectedPayrolls();

  const filters = useFilters();
  const getFilters = useGetFilters();
  const resetFilters = useResetFilters();
  const {
    payrolls,
    pagination: payrollsPagination,
    isLoading: payrollsAreLoading,
  } = usePayroll({ filters, getFilters, page, itemsPerPage });

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeItemsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setItemsPerPage(value);
    },
    []
  );

  const handleTabChange = (value: string) => {
    const countryKey = country.toLowerCase();
    const valueKey = value.toLowerCase();
    clearSelectedPayrolls();
    resetFilters();
    history.push(`/${countryKey}/${valueKey}`);
  };

  return {
    handleTabChange,
    country,
    operationType,
    payrolls,
    payrollsPagination,
    payrollsAreLoading,
    page,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
  };
};

export default usePaymentInbox;
