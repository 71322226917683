import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { usePayrollSelector } from '../../payroll/state';
import * as Repo from './receiver.repo';
import { getReceiversUseCase } from '../useCases';

export const FETCH_RECEIVERS_KEY = 'opportunities:fetchReceivers';
export const FETCH_RECEIVER_KEY = 'opportunities:fetchReceiver';

export const useFetchReceivers = (filter, opts) => {
  const { data, ...rest } = useQuery(
    [FETCH_RECEIVERS_KEY, filter],
    () => Repo.fetchReceivers(filter),
    opts
  );

  const payrollSelector = usePayrollSelector();
  const memoizedReceivers = useMemo(
    () => getReceiversUseCase({ receivers: data?.records, payrollSelector }),
    [data?.records, payrollSelector]
  );

  return {
    ...rest,
    pagination: data?.pagination,
    receivers: memoizedReceivers,
  };
};

export const useFetchReceiver = (filter, opts) => {
  return useQuery(
    [FETCH_RECEIVER_KEY, filter],
    () => Repo.fetchReceiver(filter),
    opts
  );
};
