import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { addFundFinancingToInvoiceService } from '../services';

export const useAddFundFinancingToInvoice = () => {
  const dispatch = useDispatch();
  const {
    isLoading: addFundFinancingToInvoiceLoading,
    isSuccess: addFundFinancingToInvoiceSuccess,
    error: addFundFinancingToInvoiceError,
    mutate: addFundFinancingToInvoice,
  } = useMutation(
    fundFinancing => {
      return addFundFinancingToInvoiceService(fundFinancing);
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessUploadInvoiceFund } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FUND_FINANCING_ADDED, {
            variant: 'success',
          })
        );
        onSuccessUploadInvoiceFund();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.UPDATE_ORDER_INVOICE_FUND_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    addFundFinancingToInvoiceLoading,
    addFundFinancingToInvoiceSuccess,
    addFundFinancingToInvoiceError,
    addFundFinancingToInvoice,
  };
};

export default useAddFundFinancingToInvoice;
