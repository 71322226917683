// authentication.js
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { PURGE } from 'redux-persist';
import {
  deleteAuthToken,
  deleteCountryHeader,
  setAuthToken,
} from '../helpers/axiosUtils';
import {
  ACTION_VIEW_TYPE,
  DEFAULT_COUNTRY,
  STATUSES,
  STATUS_ORDER_TYPE,
  COUNTRY_CODE_CL,
} from '../helpers/Constants';
import { getErrorCode } from '../helpers/handleErrors';
import { changeCountry } from './configAction';
import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  SESSION_EXPIRED,
  SET_CURRENT_USER,
  SET_ORDERS_ACTIVE_FILTERS_BY_DEFAULT,
  SET_RULES,
  FETCH_RULES,
  FETCH_RULES_SUCCESS,
  FETCH_RULES_ERROR,
} from './types';

export const logoutClearUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const sessionExpired = () => {
  return {
    type: SESSION_EXPIRED,
  };
};

export const logoutUser = oktaAuth => dispatch => {
  dispatch({ type: PURGE, result: () => {} });
  localStorage.removeItem('jwtToken');
  Cookies.remove('access_token');
  dispatch(logoutClearUser());
  deleteAuthToken();
  deleteCountryHeader();
  if (oktaAuth) {
    oktaAuth.signOut();
  }
};

export const checkLogState = exp => (dispatch, getState) => {
  const currentTime = Math.round(Date.now() / 1000);
  const expiresIn = exp - currentTime;
  setAuthToken(getState().auth.token);
  setTimeout(() => {
    // if logged, logout user
    if (getState().auth.isAuthenticated) {
      dispatch(logoutUser());
      dispatch(sessionExpired());
    }
  }, expiresIn * 1000);
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setRules = rules => ({
  type: SET_RULES,
  payload: rules,
});

const setStatusRules = rules => dispatch => {
  const { status: statusRules } = rules;
  const statusGroupAvailable = [];
  const statusActiveFilters = [];
  statusRules.forEach(statusAvailable => {
    const { statusType, actionType, name } = statusAvailable;
    if (statusType === STATUS_ORDER_TYPE && actionType === ACTION_VIEW_TYPE) {
      const statusFound = STATUSES.order
        .filter(status => status.country.includes(COUNTRY_CODE_CL))
        .find(status => status.key === name);
      const { key, group } = statusFound;
      if (!statusGroupAvailable.some(status => status === group)) {
        statusGroupAvailable.push(group);
      }
      if (!statusActiveFilters.some(status => status === key)) {
        statusActiveFilters.push(key);
      }
    }
  });
  dispatch({
    type: SET_ORDERS_ACTIVE_FILTERS_BY_DEFAULT,
    payload: {
      ordersAvailableFilters: statusActiveFilters,
      ordersActiveGroupFilters: statusGroupAvailable,
    },
  });
};

export const loginUser = user => dispatch => {
  dispatch({
    type: LOGIN_BEGIN,
  });
  axios
    .post('/api/user/login', user)
    .then(async res => {
      const { token, rules } = res.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      dispatch(changeCountry(DEFAULT_COUNTRY));
      dispatch(setRules(rules));
      dispatch(setStatusRules(rules));
      // this is used to pass down the token to the Websocket for authorization
      Cookies.set('access_token', token);
      const decoded = jwtDecode(token);
      dispatch(setCurrentUser({ decoded, token }));
      dispatch(checkLogState(decoded.exp));
      dispatch({
        type: LOGIN_SUCCESS,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: LOGIN_ERROR,
        payload: { errorCode },
      });
    });
};

export const fetchRules =
  ({ type = FETCH_RULES }) =>
  dispatch => {
    dispatch({ type });
    axios
      .get('/api/user/userRules')
      .then(async res => {
        const { rules } = res.data;
        dispatch(setRules(rules));
        dispatch(setStatusRules(rules));
        dispatch({
          type: FETCH_RULES_SUCCESS,
        });
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: FETCH_RULES_ERROR,
          payload: { errorCode },
        });
      });
  };
