import { gql } from 'graphql-request';
import { useGraphQlQuery } from '../hooks/useGraphQlQuery';
import { getKeysSelected } from './helpers/getKeySelected';

type EntityParams = {
  refId?: string;
};

type EntityDTO = {
  dto: EntityParams;
};

type EntityFieldsResponse = {
  id?: boolean;
  refId?: boolean;
  enrolledAt?: boolean;
};

type Entity = {
  id: string;
  refId: string;
  enrolledAt: string;
};

type EntityResponse = {
  entity: Entity | null;
};

type UseGetEntity = {
  entityData?: EntityResponse;
  isEntityLoading: boolean;
};

type UseGetEntityProps = {
  params: EntityParams;
  fieldsResponse: EntityFieldsResponse;
};

const getEntityQuery = (props: EntityFieldsResponse): string => {
  const keysSelected = getKeysSelected<EntityFieldsResponse>(props);
  return gql`
    query Entity($dto: GetEntityDTO!) {
      entity(dto: $dto) {
        ${keysSelected.map(key => key)}
      }
    }
  `;
};

function useGetEntity(props: UseGetEntityProps): UseGetEntity {
  const { params, fieldsResponse } = props;
  const dto: EntityDTO = {
    dto: params,
  };
  const query = getEntityQuery(fieldsResponse);
  const { data, loading } = useGraphQlQuery<EntityResponse>('getEntity', {
    query,
    variables: dto,
  });

  return {
    entityData: data,
    isEntityLoading: loading,
  };
}

export default useGetEntity;
