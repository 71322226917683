import * as Types from './receiver.types';

export const selectReceiver = receiver => dispatch =>
  dispatch(Types.SELECT_RECEIVER.SUCCESS.action(receiver));

export const selectDraftReceiver = receiver => dispatch =>
  dispatch(Types.SELECT_DRAFT_RECEIVER.SUCCESS.action(receiver));

export const updateDraftReceiver = receiver => dispatch =>
  dispatch(Types.UPDATE_DRAFT_RECEIVER.SUCCESS.action(receiver));

export const cleanSelectedDraftReceiver = () => dispatch =>
  dispatch(Types.CLEAN_SELECTED_DRAFT_RECEIVER.SUCCESS.action());

export const cleanSelectedReceiver = () => dispatch =>
  dispatch(Types.CLEAN_SELECTED_RECEIVER.SUCCESS.action());

export const saveReceiverForm = form => dispatch =>
  dispatch(Types.SAVE_RECEIVER_FORM.SUCCESS.action(form));

export const cleanReceiverForm = () => dispatch =>
  dispatch(Types.CLEAN_RECEIVER_FORM.SUCCESS.action());
