import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateOrderAttributeService } from '../services';

export const useUpdateOrderAttribute = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isLoading: orderAttributeIsUpdating,
    isSuccess: updateOrderAttributeIsSuccess,
    error,
    mutate: updateOrderAttribute,
    reset: resetOrderAttributeUpdateData,
  } = useMutation(
    ({ orderId, attribute }) => {
      return updateOrderAttributeService(orderId, attribute);
    },
    {
      onMutate: async params => {
        await queryClient.cancelQueries('getOrderAttributes', {
          exact: false,
        });

        const prevData = queryClient.getQueryData('getOrderAttributes', {
          exact: false,
        });

        const updatedData = produce(prevData, draft => {
          // eslint-disable-next-line no-param-reassign
          draft.data = { ...draft.data, ...params.attribute };
        });

        queryClient.setQueryData('getOrderAttributes', updatedData);
        return prevData;
      },
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(
            t13s.NOTIFICATION.UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS,
            {
              variant: 'success',
            }
          )
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries('getOrderAttributes', {
          exact: false,
        });
      },
    }
  );

  return {
    orderAttributeIsUpdating,
    updateOrderAttributeIsSuccess,
    updateOrderAttributeError: error?.message,
    updateOrderAttribute,
    resetOrderAttributeUpdateData,
  };
};

export default useUpdateOrderAttribute;
