import { Badge, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { settings } from '../../config/settings';
import { PAGE_SIZE_INIT } from '../../helpers/Constants';
import PaginationMaterialTable from './PaginationMaterialTable';
import PayerContactDetail from './PayerContactDetail';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles({
  actionMenu: {
    border: '1px solid #f5f5f5',
    boxShadow: '10px 10px 8px -8px #a3a3a3',
  },
  link: {
    cursor: 'pointer',
  },
  verde: {
    background: '#75B801 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  amarillo: {
    background: '#FFB100 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  rojo: {
    background: '#D92F24 0% 0% no-repeat padding-box',
    opacity: 1,
  },
});

const PayersTable = ({
  tableData = [],
  pagination: { maxRecords = 0, page = 0, limit = PAGE_SIZE_INIT },
  handleChangePage,
}) => {
  const classes = useStyles();
  const fetchingPayers = useSelector(state => state.payer.fetchingPayers);
  const country = useSelector(state => state.config.country);
  const { isApiGlobal } = settings[country];
  const { fetchingSubRubros, subRubros } = useSelector(state => state.rubro);
  const { fetchingEconomicGroups, economicGroups } = useSelector(
    state => state.economicGroup
  );
  const { t } = useTranslation();

  const payerColumns = [
    {
      title: 'Nombre',
      field: 'name',
      width: '350px',
      type: 'string',
      render: rowData => {
        const { name } = rowData;
        return (
          <Typography variant="body1" color="textPrimary">
            {name}
          </Typography>
        );
      },
    },
    {
      title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
      field: 'businessIdentifier',
      width: '100px',
      type: 'string',
      render: rowData => {
        const businessIdentifier = isApiGlobal
          ? rowData?.identifier
          : `${rowData?.rut}-${rowData?.dv}`;

        return (
          <Typography variant="body1" color="textPrimary" noWrap>
            {fetchingPayers ? '' : businessIdentifier}
          </Typography>
        );
      },
    },
    {
      title: 'Ranking Pagador',
      field: 'ranking',
      width: '100px',
      type: 'integer',
      render: rowData => {
        const { ranking } = rowData;
        if (!ranking) return '-';
        return (
          <Typography align="center" variant="body1" color="textPrimary">
            {ranking}
          </Typography>
        );
      },
    },
    {
      title: 'Grupo Económico',
      field: 'EconomicGroup.name',
      width: '200px',
      render: rowData => {
        const economicGroup = economicGroups.find(
          ec => ec.id === rowData.economicGroupId
        );
        return fetchingEconomicGroups ? (
          <CircularProgress size={25} />
        ) : (
          <Typography variant="body1" color="textPrimary">
            {economicGroup?.name || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Subrubro',
      field: isApiGlobal ? 'TaxSubSector.name' : 'SubRubro.name',
      render: rowData => {
        const subSector = subRubros?.find(
          subRubro =>
            subRubro.id === (rowData.subRubroId || rowData.taxSubSectorId)
        );

        return fetchingSubRubros ? (
          <CircularProgress size={25} />
        ) : (
          <Typography variant="body1" color="textPrimary">
            {subSector?.name || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Semáforo',
      field: isApiGlobal ? 'TaxSubSector.rating' : 'SubRubro.rating',
      width: '80px',
      align: 'center',
      render: rowData => {
        const { SubRubro, TaxSubSector } = rowData;
        const rating = SubRubro?.rating || TaxSubSector?.rating || 'rojo';
        return (
          <Badge
            classes={{ badge: classes[rating.toLowerCase()] }}
            badgeContent=""
            color="default"
          />
        );
      },
    },
  ];

  return (
    <MaterialTable
      columns={payerColumns}
      data={tableData}
      title={null}
      isLoading={fetchingPayers}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      onChangeRowsPerPage={pageSize => handleChangePage(0, pageSize)}
      style={{ width: '100%', boxShadow: 'none' }}
      components={{
        Toolbar: () => null,
        Pagination: props => (
          <PaginationMaterialTable
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            count={maxRecords}
            page={page}
            onChangePage={(e, page) =>
              // eslint-disable-next-line react/prop-types
              handleChangePage(page, props.rowsPerPage)
            }
          />
        ),
      }}
      localization={{
        pagination: {
          labelRowsSelect: 'pagadores',
          labelRowsPerPage: 'Pagadores por página',
          labelDisplayedRows: '{count} pagadores totales',
        },
        body: {
          emptyDataSourceMessage: 'No hay pagadores para mostrar',
        },
      }}
      options={{
        toolbar: true,
        pageSize: limit,
        pageSizeOptions: [5, 10, 20, 50, 100],
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        filtering: false,
        search: false,
        detailPanelType: 'single',
      }}
      detailPanel={({ id }) => {
        return <PayerContactDetail payerId={id} />;
      }}
    />
  );
};

PayersTable.propTypes = {
  tableData: PropTypes.objectOf(Array).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(Number).isRequired,
};

export default PayersTable;
