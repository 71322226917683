import React from 'react';
import PropTypes from 'prop-types';
import { Grid, styled } from '@material-ui/core';
import { format } from 'date-fns';

import { AmountByCountry, Text } from '../../../../commons/formatters';
import { AVAILABLE_COUNTRIES } from '../../../../../helpers/Constants';
import { StatusChip } from '../../../../commons/components';

const AttributeTitle = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey3,
  marginBottom: 8,
}));

const AttributeLabel = styled(Text)(({ theme }) => ({
  color: theme.palette.payments.grey1,
  display: 'flex',
  height: 24,
}));

const AttributeBlock = styled(Grid)(({ theme }) => ({
  color: theme.palette.payments.grey1,
  display: 'grid',
  gridTemplateColumns: 'repeat(5, minmax(auto, 144px))',
  gridRowGap: 16,
}));

const AttributeItem = styled(Grid)({
  width: 144,
});

const InterestAttribute = styled(AmountByCountry)(({ theme }) => ({
  color: theme.palette.payments.grey1,
}));

const CardAttributes = ({ payroll, expanded, country }) => {
  const {
    issuedDate,
    daysPassed,
    isFinancing,
    hasCesion,
    status,
    interest,
    baseRate,
    countryId,
    suppliersCount,
    invoicesCount,
    currency,
  } = payroll;

  const formatedDate = format(new Date(issuedDate), 'dd-MM-yyyy');
  const paymentType = isFinancing ? 'Financiamiento' : 'Caja Propia';
  const paymentCesion = hasCesion ? 'Con cesión' : 'Sin cesión';
  const countryCurrency = {
    MX: 'MXN',
    CL: 'CLP',
  };

  const IssuedDateItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Fecha solicitud</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {formatedDate}
      </AttributeLabel>
    </AttributeItem>
  );

  const DaysPassedItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Días transcurridos</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {daysPassed}
      </AttributeLabel>
    </AttributeItem>
  );

  const PaymentTypeItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Tipo de pago</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {paymentType}
      </AttributeLabel>
    </AttributeItem>
  );

  const PaymentCessionItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Tipo payments</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {paymentCesion}
      </AttributeLabel>
    </AttributeItem>
  );

  const StatusItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Estado</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        <StatusChip status={status} />
      </AttributeLabel>
    </AttributeItem>
  );

  const SuppliersCountItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Proveedores</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {suppliersCount}
      </AttributeLabel>
    </AttributeItem>
  );

  const InvoicesCountItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Facturas</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {invoicesCount}
      </AttributeLabel>
    </AttributeItem>
  );

  const InterestItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Intereses</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        <InterestAttribute
          text={interest}
          countryId={country}
          fontWeight="medium"
          variant="subtitle1"
        />
      </AttributeLabel>
    </AttributeItem>
  );

  const BaseRateItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Tasa base</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {baseRate}%
      </AttributeLabel>
    </AttributeItem>
  );

  const CurrencyTypeItem = () => (
    <AttributeItem item>
      <AttributeTitle variant="subtitle1">Tipo de moneda</AttributeTitle>
      <AttributeLabel variant="subtitle1" fontWeight="medium">
        {currency ?? countryCurrency[countryId]}
      </AttributeLabel>
    </AttributeItem>
  );

  const itemsByCountry = {
    CL: [
      <IssuedDateItem />,
      <DaysPassedItem />,
      <PaymentTypeItem />,
      <PaymentCessionItem />,
      <StatusItem />,
      <SuppliersCountItem />,
      <InvoicesCountItem />,
      <InterestItem />,
      <BaseRateItem />,
      <CurrencyTypeItem />,
    ],
    MX: [
      <IssuedDateItem />,
      <SuppliersCountItem />,
      <InvoicesCountItem />,
      <PaymentTypeItem />,
      <StatusItem />,
      <InterestItem />,
      <BaseRateItem />,
      <CurrencyTypeItem />,
    ],
  };

  const attributesByCountry = itemsByCountry[country] ?? itemsByCountry.cl;
  const attributes = expanded
    ? attributesByCountry
    : attributesByCountry.slice(0, 5);

  return (
    <Grid container>
      <AttributeBlock container item>
        {attributes.map(item => item)}
      </AttributeBlock>
    </Grid>
  );
};

CardAttributes.propTypes = {
  expanded: PropTypes.bool.isRequired,
  country: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  payroll: PropTypes.objectOf(Object).isRequired,
};

export default CardAttributes;
