import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { downloadFile } from '../../../../helpers/fileHandling';
import { t13s } from '../../../../translationKeys';
import { downloadSimulationPdfService } from '../services';

export const useDownloadSimulationPdf = () => {
  const dispatch = useDispatch();
  const { mutate: downloadSimulationPdf } = useMutation(
    orderId => {
      return downloadSimulationPdfService({ orderId });
    },
    {
      onSuccess: (res, param) => {
        const {
          data: { pdf },
        } = res;
        downloadFile(pdf, param);
      },
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DOWNLOAD_FILE_FAILURE, {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    downloadSimulationPdf,
  };
};

export default useDownloadSimulationPdf;
