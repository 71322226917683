import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import {
  ContainerRoot,
  SelectionDetails,
  SelectedText,
  UndoSelection,
  RenegotiationButton,
} from './styles';
import useSelectedInvoices from '../../hooks/useSelectedInvoices';
import ExtensionsDrawer from '../ExtensionsDrawer/ExtensionsDrawer';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Role } from '../../../commons/interfaces/global';
import PaymentPromisesDrawer from '../PaymentPromisesDrawer';
import renegotiationCalendar from '../../../../assets/renegotiation-calendar.svg';
import paymentPromiseCalendar from '../../../../assets/payment-promise-calendar.svg';
import { collectionRoles } from '../../../commons/utils/roles';
import AddIcon from '../../../commons/icons/AddIcon';
import ManagementDrawer from '../ManagementDrawer';

interface Props {
  selectedInvoices: number[];
  selectedFolios: string[];
  setSelectedInvoices: (arg: number[]) => void;
  setSelectedFolios: (arg: string[]) => void;
  allChecked: boolean;
  setAllChecked: (arg: boolean) => void;
}

const SelectedInvoices = ({
  selectedInvoices,
  selectedFolios,
  setSelectedInvoices,
  setSelectedFolios,
  allChecked,
  setAllChecked,
}: Props) => {
  const { roles } = useSelector((state: RootStateOrAny) => state.auth.user);
  const isCollectionManager = roles.some(
    (role: Role) => role.rolename === 'Manager Cobranza'
  );
  const isCollectionRole = roles.some((role: Role) =>
    collectionRoles.includes(role.rolename)
  );

  const {
    totalSelectedInvoices,
    showRenegotiationDrawer,
    showPromiseDrawer,
    showManagementDrawer,
    bulkCreateExtensionsIsLoading,
    bulkCreatePaymentPromisesIsLoading,
    bulkCreateManagementsIsLoading,
    handleClearSelectedInvoices,
    handleShowRenegotiationDrawer,
    handleHideRenegotiationDrawer,
    handleShowPromiseDrawer,
    handleHidePromiseDrawer,
    setShowManagementDrawer,
    handleBulkCreateExtensions,
    handleBulkCreatePaymentPromises,
    handleBulkCreateManagements,
  } = useSelectedInvoices({
    selectedInvoices,
    setSelectedInvoices,
    setSelectedFolios,
    allChecked,
    setAllChecked,
  });

  return (
    <Collapse in={totalSelectedInvoices > 0} timeout="auto">
      <ContainerRoot>
        <SelectionDetails>
          <SelectedText>
            {totalSelectedInvoices === 1
              ? '1 factura  seleccionada.'
              : `${totalSelectedInvoices} facturas seleccionadas.`}
          </SelectedText>
          <UndoSelection onClick={handleClearSelectedInvoices}>
            Deseleccionar
          </UndoSelection>
        </SelectionDetails>
        <div>
          {isCollectionRole && (
            <RenegotiationButton
              style={{ marginRight: 30 }}
              startIcon={<img src={paymentPromiseCalendar} />}
              onClick={handleShowPromiseDrawer}
            >
              Agregar fecha de pago
            </RenegotiationButton>
          )}
          {isCollectionManager && (
            <RenegotiationButton
              startIcon={<img src={renegotiationCalendar} />}
              onClick={handleShowRenegotiationDrawer}
            >
              Renegociar deuda
            </RenegotiationButton>
          )}
          {isCollectionRole && (
            <Tooltip style={{ marginLeft: 15 }} title="Agregar gestión">
              <IconButton onClick={() => setShowManagementDrawer(true)}>
                <AddIcon style={{ height: '28px', width: '28px' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </ContainerRoot>

      {showPromiseDrawer && (
        <PaymentPromisesDrawer
          selectedFolios={selectedFolios}
          open={showPromiseDrawer}
          closeDrawer={handleHidePromiseDrawer}
          handleOnClick={handleBulkCreatePaymentPromises}
          isLoading={bulkCreatePaymentPromisesIsLoading}
        />
      )}

      {showRenegotiationDrawer && (
        <ExtensionsDrawer
          open={showRenegotiationDrawer}
          closeDrawer={handleHideRenegotiationDrawer}
          handleOnClick={handleBulkCreateExtensions}
          isLoading={bulkCreateExtensionsIsLoading}
        />
      )}

      {showManagementDrawer && (
        <ManagementDrawer
          selectedFolios={selectedFolios}
          open={showManagementDrawer}
          closeDrawer={() => setShowManagementDrawer(false)}
          handleOnClick={handleBulkCreateManagements}
          isLoading={bulkCreateManagementsIsLoading}
        />
      )}
    </Collapse>
  );
};

export default SelectedInvoices;
