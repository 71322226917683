export const updatePayrollByAssignedInvoicesUseCase = ({
  currentSelectorState,
  response,
}) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const { amount, name, value, receiverDebtServiceAmount } =
      currentSelectorState[receiverIdentifier];
    acc[receiverIdentifier] = {
      amount,
      name,
      receiverDebtServiceAmount,
      value: value.filter(
        ({ id }) =>
          !response[receiverIdentifier].map(({ id }) => id).includes(id)
      ),
    };

    return acc;
  }, {});
};
