import { useQuery } from 'react-query';
import { checkCanDepositService } from '../services';

const useCheckCanDeposit = orderIds => {
  const { data: requirements } = useQuery(
    'checkCanDeposit',
    () => checkCanDepositService(orderIds),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  return {
    requirements,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useCheckCanDeposit };
