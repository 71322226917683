import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import { HomeRoutes } from '../adapters/routes/home.routes';
import { useGetCountry } from '../adapters/store';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../../helpers/Constants';
import { LEFT_TABS, MX_TABS, RIGHT_TABS } from '../MainLayout/constants';

const isMxRoute = path => {
  return MX_TABS.map(tab => tab.value).some(tabPath => path.includes(tabPath));
};

export const HomeRouter = ({ basePath }) => {
  const country = useGetCountry();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (country === COUNTRY_CODE_MX) {
      history.push(`${basePath}/${RIGHT_TABS[0].value}`);
    }
    if (country === COUNTRY_CODE_CL && isMxRoute(location.pathname)) {
      history.push(`${basePath}/${LEFT_TABS[0].value}`);
    }
  }, [country]);

  return (
    <Switch>
      {HomeRoutes(basePath)}
      <Redirect
        from={basePath}
        to={
          country === COUNTRY_CODE_CL
            ? `${basePath}/${LEFT_TABS[0].value}`
            : `${basePath}/${RIGHT_TABS[0].value}`
        }
      />
    </Switch>
  );
};

HomeRouter.propTypes = {
  basePath: PropTypes.string.isRequired,
};
