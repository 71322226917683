import {
  CHANGE_COUNTRY,
  TOGGLE_COLLAPSE_HOME_SIDE_BAR,
} from '../actions/types';
import { DEFAULT_COUNTRY } from '../helpers/Constants';

const initialState = {
  country: DEFAULT_COUNTRY,
  isExpandedHomeSideBar: false,
};

const changeCountry = (state, payload) => ({
  ...state,
  country: payload.country,
});

const toggleCollapseHomeSideBar = (state, payload) => ({
  ...state,
  isExpandedHomeSideBar: payload.isExpanded,
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_COUNTRY:
      return changeCountry(state, payload);
    case TOGGLE_COLLAPSE_HOME_SIDE_BAR:
      return toggleCollapseHomeSideBar(state, payload);
    default:
      return state;
  }
};
