import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { lockOrderService } from '../services';
import { t13s } from '../../../../translationKeys';

export const useLockOrder = () => {
  const dispatch = useDispatch();
  const { mutate: lockOrder, isLoading: isLockingOrder } = useMutation(
    params => {
      return lockOrderService(params);
    },
    {
      onError: () => {
        dispatch(
          enqueueSnackbar(t13s.ERROR.ERROR_LOCK_ORDER, {
            variant: 'error',
          })
        );
      },
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  return {
    lockOrder,
    isLockingOrder,
  };
};

export default useLockOrder;
