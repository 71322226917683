import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Grid, Box, Tooltip, Badge } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import emptyBrowserIcon from '../../../../../../assets/icons/emptyBrowserIcon.svg';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import SectionHeader from '../../../../../../components/elements/SectionHeader';
import InvoicesTableMinified from '../../../../../../components/tables/InvoicesTableMinified';
import {
  CONFIRMING,
  ORDER_TYPES_LABEL,
} from '../../../../../../helpers/Constants';
import {
  dateFormatter,
  getFirstBusinessDayFrom,
} from '../../../../../../helpers/DateUtils';
import { t13s } from '../../../../../../translationKeys';
import Chip from '../../../../../../components/elements/chips/Chip';
import {
  useFetchHolidays,
  useFetchAvailableBusinessInvoices,
  useOrderSummary,
} from '../../../../infrastructure/store';
import useStyles from './styles';
import InvoiceOperationSummary from './InvoiceOperationSummary';
import SearchBarPanel from './SearchBarPanel';

const AddInvoicesDrawer = ({
  show,
  manageSelectedInvoices,
  order,
  manageOrderInvoicesIsLoading,
}) => {
  const classes = useStyles();
  const { id: orderId } = useParams();
  const { orderSummary, isLoading: orderSummaryIsLoading } =
    useOrderSummary(orderId);

  const {
    createdAt: orderCreatedAt,
    totalOrdersTransferred,
    businessId,
    businessName,
  } = order;

  const { baseRate } = orderSummary?.operation ?? {};

  const country = useSelector(state => state.config.country);
  const { holidaysData } = useFetchHolidays();

  const countryHolidays = holidaysData?.[country];

  const [search, setSearch] = useState('');
  const [field, setField] = useState(null);
  const [invoicesData, setInvoicesData] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const { t } = useTranslation();

  const handleSelectedData = (data = []) => {
    setSelectedData(data);
  };

  const handleSelectedInvoices = (data = []) => {
    setSelectedInvoices(data);
  };

  const changeSearchInput = ({ value = '', field }) => {
    setField(field);
    setSearch(value);
  };

  const getInvoiceFilters = () => {
    const filter = [];
    const tSearch = search;
    if (tSearch) {
      filter.push({
        field,
        value: tSearch,
        cmp: '=',
      });
    }

    return filter;
  };

  const { availableInvoices, isLoading: fetchingAvailableInvoices } =
    useFetchAvailableBusinessInvoices({
      businessId,
      countryHolidays,
      config: {
        filters: getInvoiceFilters(),
        operationType: CONFIRMING,
        includeRate: true,
        defaultRate: baseRate,
      },
    });

  const searcher = () => {
    const value = search;
    if (!value.length) {
      setSelectedInvoices([]);
    }
  };

  useEffect(() => {
    if (show) searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    let invoicesAmount = 0;
    const invoices = [];
    const invoicesSize = selectedData.length;

    const selectedInvoicesUpdated = selectedInvoices.map(selectedInvoice => {
      const exists = selectedData.find(
        selectData => selectData.id === selectedInvoice.id
      );
      return {
        ...selectedInvoice,
        exists: !!exists,
      };
    });

    setSelectedInvoices(selectedInvoicesUpdated);

    if (invoicesSize) {
      invoicesAmount = selectedData.reduce(
        (previousValue, currentValue) =>
          previousValue + (parseFloat(currentValue.amount) || 0),
        0
      );
      selectedData.forEach(({ id, rate, baseRate = rate, expirationDate }) => {
        invoices.push({
          id,
          baseRate,
          expirationDate,
        });
      });
    }
    setInvoicesData({
      invoicesAmount,
      invoices,
      invoicesSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  useEffect(() => {
    if (availableInvoices?.length) {
      const preAvailableInvoices = availableInvoices?.map(invoice => {
        return {
          ...invoice,
          expirationDate: getFirstBusinessDayFrom(
            invoice.expirationDate,
            countryHolidays
          ),
        };
      });
      const updatedList = preAvailableInvoices?.map(invoice => {
        return {
          ...invoice,
          exists: selectedData?.some(
            selectData => selectData.id === invoice.id
          ),
        };
      });
      setSelectedInvoices(updatedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableInvoices]);

  useEffect(() => {
    setSearch('');
    setInvoicesData({});
    setSelectedInvoices([]);
    setSelectedData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const tableColumns = [
    {
      title: '',
      field: 'folio',
      type: 'numeric',
      defaultSort: 'asc',
      align: 'left',
      width: 100,
      render: rowData => {
        const { folio = '-' } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            {folio}
          </Typography>
        );
      },
    },
    {
      title: '',
      field: 'rznSocRecep',
      render: rowData => {
        const { payerName = '-', blackList } = rowData;
        if (!blackList) {
          return (
            <Typography variant="body2" color="textPrimary">
              {payerName}
            </Typography>
          );
        }
        return (
          <Tooltip title="Blocklist">
            <Chip label={payerName} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      field: 'rutReceptor',
      render: rowData => {
        const { payerIdentifier = '-', blackList = false } = rowData;

        if (!blackList) {
          return (
            <Typography variant="body2" color="textPrimary">
              {payerIdentifier}
            </Typography>
          );
        }
        return (
          <Tooltip title="Blocklist">
            <Chip label={payerIdentifier} variant="red" />
          </Tooltip>
        );
      },
    },
    {
      title: '',
      field: 'mntTotal',
      type: 'numeric',
      align: 'left',

      render: rowData => {
        const { finalAmount = 0 } = rowData;
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={finalAmount}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
  ];

  const getFilterByCountry = () => {
    const filters = {
      CL: {
        title: 'Folio',
        field: 'folio',
      },
      MX: {
        title: 'UUID',
        field: 'uuid',
      },
    };

    return filters[country] || null;
  };

  return (
    <>
      <SectionHeader
        padding="lg"
        title={businessName}
        transparentBg
        mainArea={
          <Grid container xs={12} direction="row">
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">ID:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {businessId}
              </Typography>
            </Grid>
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">Tipo de operación:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {ORDER_TYPES_LABEL[CONFIRMING]}
              </Typography>
            </Grid>
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">Fecha de solicitud:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {dateFormatter(orderCreatedAt)}
              </Typography>
            </Grid>
          </Grid>
        }
        preTitleAction={
          <Tooltip title="Operaciones Transferidas">
            <Badge
              classes={
                totalOrdersTransferred[0]?.count < 2
                  ? { badge: classes.firstOrderBadge }
                  : ''
              }
              badgeContent={totalOrdersTransferred[0]?.count}
              color="primary"
              invisible={false}
            />
          </Tooltip>
        }
        breadCrumbs={[]}
        showBreadCrumbs={false}
      />
      <Grid container item spacing={2}>
        <Grid item xs={8}>
          <Grid className={classes.marginBottom20}>
            <SearchBarPanel
              placeholder="Buscar facturas..."
              handleChangeInput={value => changeSearchInput(value)}
              panelOptions={{
                useDataPanel: true,
                data: selectedInvoices,
                handleData: handleSelectedInvoices,
                loadingData: fetchingAvailableInvoices,
                handleSelectedData,
                tableColumns,
                selectedData,
              }}
              selectedOption={getFilterByCountry()}
              fields={[
                {
                  title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
                  field: 'identifier',
                },
                getFilterByCountry(),
              ]}
            />
          </Grid>
          <Grid container xs={12}>
            <InvoicesTableMinified
              invoices={selectedData}
              setInvoices={handleSelectedData}
              emptyMessage="Busca una o más facturas para ver su información aquí y editar las tasas bases."
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {order ? (
            <InvoiceOperationSummary
              order={order}
              orderSummary={orderSummary}
              orderSummaryIsLoading={orderSummaryIsLoading}
              invoicesData={invoicesData}
              manageSelectedInvoices={() =>
                manageSelectedInvoices(selectedData)
              }
              buttonText="Agregar Facturas"
              action="add"
              buttonDisabled={
                selectedData.length === 0 || manageOrderInvoicesIsLoading
              }
              buttonIcon={<AddCircleOutlineOutlinedIcon />}
              buttonIsLoading={manageOrderInvoicesIsLoading}
              showHint
            />
          ) : (
            <Grid alignItems="center" className={classes.emptyResumeContainer}>
              <img
                src={emptyBrowserIcon}
                className={classes.emptyResumeIcon}
                alt="Top"
              />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
                noWrap
              >
                Selecciona una operación para ver su información acá.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

AddInvoicesDrawer.defaultProps = {
  show: false,
  manageOrderInvoicesIsLoading: false,
};

AddInvoicesDrawer.propTypes = {
  show: PropTypes.bool,
  manageSelectedInvoices: PropTypes.func.isRequired,
  order: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    businessId: PropTypes.number.isRequired,
    businessName: PropTypes.string.isRequired,
    totalOrdersTransferred: PropTypes.shape({ count: PropTypes.number }),
  }).isRequired,
  manageOrderInvoicesIsLoading: PropTypes.bool,
};

export default AddInvoicesDrawer;
