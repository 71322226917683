import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const downloadOrderCommentAttachmentService = async attachmentId => {
  try {
    const { data } = await axios.get(
      `/api/commentattachment/${attachmentId}/download`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { downloadOrderCommentAttachmentService };
