import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatISO } from 'date-fns';
import {
  useAgreementFilter,
  useFieldFilter,
  useSetAgreementFilter,
  useSetFieldFilter,
  useIssuedDateFilter,
  useSetIssuedDateFilter,
  usePaymentTypeFilter,
  useSetPaymentTypeFilter,
  usePaymentCesionFilter,
  useSetPaymentCesionFilter,
  useStatusFilter,
  useSetStatusFilter,
  useClearSelectedPayrolls,
} from '../infrastructure/store/PaymentInboxStore';
import { useAgreements } from '../infrastructure/store';
import { t13s } from '../../../translationKeys';
import useStatusesInbox from './useStatusesInbox';

const paymentTypeFilters = ['Caja propia', 'Financiamiento'];
const paymentCesionFilters = ['Con cesión', 'Sin cesión'];

const usePaymentFilters = () => {
  const { t } = useTranslation();
  const { getStatuses } = useStatusesInbox();
  const statusFilter = useStatusFilter();
  const setStatusFilter = useSetStatusFilter();
  const fieldFilter = useFieldFilter();
  const setFieldFilter = useSetFieldFilter();
  const agreementFilter = useAgreementFilter();
  const setAgreementFilter = useSetAgreementFilter();
  const issuedDateFilter = useIssuedDateFilter();
  const setIssuedDateFilter = useSetIssuedDateFilter();
  const paymentTypeFilter = usePaymentTypeFilter();
  const setPaymentTypeFilter = useSetPaymentTypeFilter();
  const paymentCesionFilter = usePaymentCesionFilter();
  const setPaymentCesionFilter = useSetPaymentCesionFilter();
  const { agreements } = useAgreements();

  const { country } = useSelector(state => state.config);
  const isCountryCL = country.toLowerCase() === 'cl';

  const fieldFilters = useMemo(
    () => [
      {
        title: 'Order ID',
        field: 'id',
      },
      { title: 'Razon Social', field: 'name' },
      {
        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        field: 'identifier',
      },
    ],
    []
  );

  const clearSelectedPayrolls = useClearSelectedPayrolls();

  const handleChangeStatusFilter = useCallback(
    async (_event, value) => {
      await setStatusFilter(value);
      clearSelectedPayrolls();
    },
    [setStatusFilter]
  );

  const handleChangeFieldFilter = useCallback(
    async ({ select, input }) => {
      await setFieldFilter({ select, input });
    },
    [setFieldFilter]
  );

  const handleChangeIssuedDateFilter = useCallback(
    async value => {
      const IsoDate = formatISO(new Date(value));
      await setIssuedDateFilter(IsoDate);
    },
    [setIssuedDateFilter]
  );

  const handleClearIssuedDateFilter = useCallback(async () => {
    await setIssuedDateFilter(null);
  }, [setIssuedDateFilter]);

  const handleChangePaymentTypeFilter = useCallback(
    async value => {
      await setPaymentTypeFilter(value);
    },
    [setPaymentTypeFilter]
  );

  const handleChangePaymentCesionFilter = useCallback(
    async value => {
      await setPaymentCesionFilter(value);
    },
    [setPaymentCesionFilter]
  );

  const handleChangeAgreementFilter = useCallback(
    agreementsIds => {
      setAgreementFilter(agreementsIds);
    },
    [setAgreementFilter]
  );

  const handleClearAgreementFilter = useCallback(
    () => setAgreementFilter([]),
    [setAgreementFilter]
  );

  const statusFilters = [
    {
      key: 'ALL',
      label: 'Todos',
    },
    ...getStatuses('order'),
  ];

  return {
    fieldFilter,
    fieldFilters,
    agreementFilter,
    issuedDateFilter,
    paymentTypeFilter,
    paymentTypeFilters,
    paymentCesionFilter,
    paymentCesionFilters,
    statusFilter,
    statusFilters,
    handleChangeFieldFilter,
    handleChangeAgreementFilter,
    handleClearAgreementFilter,
    handleChangeIssuedDateFilter,
    handleClearIssuedDateFilter,
    handleChangePaymentTypeFilter,
    handleChangePaymentCesionFilter,
    handleChangeStatusFilter,
    agreements,
    isCountryCL,
  };
};

export default usePaymentFilters;
