import { useQuery } from 'react-query';
import { fetchOrderAttributes } from '../services';

const useOrderAttributes = orderId => {
  const { isLoading, data, isFetching, refetch } = useQuery(
    ['getOrderAttributes', orderId],
    () => fetchOrderAttributes(orderId),
    { refetchOnWindowFocus: false }
  );

  const { data: orderAttributes } = data || { data: [] };

  return {
    orderAttributes,
    isLoading,
    isFetching,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useOrderAttributes };
