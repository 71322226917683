import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { query, Query } from './query';
import { useGraphQlQuery } from '../useGraphQlQuery';

type Props = {
  bussinesRefId?: string;
};

export const usePostAssignedKam = ({bussinesRefId}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const variables = {
    dto: {
      refId: bussinesRefId,
    },
  };

  const {
    loading,
    data,
    error,
    isSuccess,
  } = useGraphQlQuery<Query>(
    'contactOwnerHistory',
    {
      query,
      variables,
      enabled: Boolean(bussinesRefId),
    },
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Surgió un error al obtener el KAM responsable', {
        variant: 'error',
      });
    }
  }, [error]);

  const [firsItem] = data?.contactOwnerHistory ? data.contactOwnerHistory : [null];

  let kamAssignedData = 'No asignado';
  if (firsItem) {
    kamAssignedData = firsItem?.owner.firstName ? `${firsItem?.owner.firstName} ${firsItem?.owner.lastName}` : firsItem?.owner.email;
  }

  return {
    loading,
    error,
    isSuccess,
    kamAssignedData,
  };
};

