import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { InvoiceDetail } from './InvoiceDetail';
import { Drawer } from '../../../../commons/components/Drawer';

const InvoiceDetailDrawerComponent = ({ onClose, payrollInvoiceId, open }) => {
  return (
    <Drawer open={open} onClose={onClose}>
      <InvoiceDetail onClose={onClose} payrollInvoiceId={payrollInvoiceId} />
    </Drawer>
  );
};

InvoiceDetailDrawerComponent.defaultProps = {
  open: false,
  payrollInvoiceId: null,
};

InvoiceDetailDrawerComponent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  payrollInvoiceId: PropTypes.number,
};

export const InvoiceDetailDrawer = memo(InvoiceDetailDrawerComponent);
