
export const grayButton = Object.freeze({
  color: '#312F37',
  backgroundColor: '#F7F5FA',
});

const blueButton = {
  color: '#0529F0',
  backgroundColor: 'rgba(5, 41, 240, 0.2)',
};

const yellowButton = {
  color: '#E29D02',
  backgroundColor: 'rgba(226, 157, 2, 0.2)',
};

const greenButton = {
  color: '#119B41',
  backgroundColor: 'rgba(17, 155, 65, 0.2)',
};

const redButton = {
  color: '#D92F24',
  backgroundColor: 'rgba(217, 47, 36, 0.2)',
};

export const buttonStateColors = [
  {
    id: '38e425bd-1f73-478f-8c19-f69f85312b15',
    ...grayButton,
  },
  {
    id: '0eb4b3f4-65de-493e-8ca6-e50f022a03f1',
    ...blueButton,
  },
  {
    id: 'f539d01c-f5dc-4dc4-b209-f633be85041e',
    ...yellowButton,
  },
  {
    id: 'b19fe325-9ed1-4229-893a-d34bddcbbe95',
    ...yellowButton,
  },
  {
    id: 'd3950f69-291a-4e94-8e2b-4bf73dc736f1',
    ...greenButton,
  },
  {
    id: '3a6084d5-b3e0-460a-8ef7-1ca46e3714ac',
    ...grayButton,
  },
  {
    id: '8613dd51-981c-4598-a2aa-a451c6a1e5ff',
    ...blueButton,
  },
  {
    id: 'c16d8427-36b6-4819-89c5-67734bf33dfd',
    ...blueButton,
  },
  {
    id: '7c2634aa-cc6b-4de6-8df5-165d95d7cec7',
    ...blueButton,
  },
  {
    id: '05d8ff58-423a-4d8c-a84c-3f89b9b40f85',
    ...yellowButton,
  },
  {
    id: 'c7b2e6aa-6b93-441a-9724-c62506cd6677',
    ...blueButton,
  },
  {
    id: '45cc7189-f483-424f-9f2e-1aa8506e8e51',
    ...greenButton,
  },
  {
    id: '12d3932d-be5e-4e05-9e83-2754156494d0',
    ...redButton,
  },
  {
    id: '9310b502-f475-4910-bf23-d82e9ec2324b',
    ...redButton,
  },
  {
    id: '9bc36605-bd20-4064-8cb9-3974a1e172f2',
    ...grayButton,
  },
  {
    id: 'a719fe4c-c022-481f-b3c7-cda843a73c1c',
    ...blueButton,
  },
  {
    id: 'e23a7838-179e-44ea-b2ed-28e46a7e2b9f',
    ...yellowButton,
  },
  {
    id: 'babfb5c9-05fa-4ea1-8d7c-479b78ce35a3',
    ...blueButton,
  },
  {
    id: '2380ba5b-c329-4b62-8cbd-cc3392883752',
    ...greenButton,
  },
] as const;
