import { to } from '../../../../commons/utils';
import * as PayrollTypes from './payroll.types';
import * as PayrollRepo from '../../../domains/payroll/services/payroll.repo';
import { enqueueSnackbar } from '../../../../../actions/notificationAction';

export const fetchPayroll = (payrollId, callbacks) => {
  return async dispatch => {
    await dispatch(PayrollTypes.SELECT_PAYROLL.START.action(payrollId));

    const [err, payroll] = await to(PayrollRepo.fetchPayrollById(payrollId));
    if (err || !payroll) {
      if (callbacks?.onFail) {
        callbacks.onFail();
      }
      return dispatch(PayrollTypes.SELECT_PAYROLL.FAILURE.action(err));
    }

    return dispatch(PayrollTypes.SELECT_PAYROLL.SUCCESS.action(payroll));
  };
};

export const selectPayroll = payroll => {
  return dispatch => {
    return dispatch(PayrollTypes.SELECT_PAYROLL.SUCCESS.action(payroll));
  };
};

export const selectReceiver = receiver => {
  return dispatch => {
    return dispatch(PayrollTypes.SELECT_RECEIVER.SUCCESS.action(receiver));
  };
};

export const updatePayrollSummary = payrollSummary => {
  return dispatch => {
    return dispatch(
      PayrollTypes.UPDATE_PAYROLL_SUMMARY.SUCCESS.action(payrollSummary)
    );
  };
};

export const pickReceiver = receivers => {
  return dispatch => {
    return dispatch(PayrollTypes.PICK_RECEIVER.SUCCESS.action(receivers));
  };
};

export const pickInvoice = invoices => {
  return dispatch => {
    return dispatch(PayrollTypes.PICK_INVOICE.SUCCESS.action(invoices));
  };
};

export const cleanPayrollSelector = () => {
  return dispatch => {
    return dispatch(PayrollTypes.CLEAN_PAYROLL_SELECTOR.SUCCESS.action());
  };
};

export const fetchCessionSummary = payrollId => {
  return async dispatch => {
    await dispatch(PayrollTypes.FETCH_CESSION_SUMMARY.START.action(payrollId));

    const [err, res] = await to(PayrollRepo.fetchCessionSummary(payrollId));
    if (err || !res) {
      return dispatch(PayrollTypes.FETCH_CESSION_SUMMARY.FAILURE.action(err));
    }

    return dispatch(PayrollTypes.FETCH_CESSION_SUMMARY.SUCCESS.action(res));
  };
};

export const notifyCessions = payrollId => {
  return async dispatch => {
    await dispatch(PayrollTypes.NOTIFY_CESSIONS.START.action(payrollId));

    const [err, res] = await to(PayrollRepo.notifyCessions(payrollId));

    if (err || !res) {
      dispatch(
        enqueueSnackbar('La notificación no pudo ser procesada', {
          variant: 'error',
        })
      );
      return dispatch(PayrollTypes.NOTIFY_CESSIONS.FAILURE.action(err));
    }
    dispatch(
      enqueueSnackbar('La notificación fue procesada con éxito', {
        variant: 'success',
      })
    );

    return dispatch(PayrollTypes.NOTIFY_CESSIONS.SUCCESS.action(res));
  };
};

export const closePayroll = payrollId => {
  return async dispatch => {
    await dispatch(PayrollTypes.CLOSE_PAYROLL.START.action(payrollId));

    const [err, res] = await to(PayrollRepo.closePayroll(payrollId));

    if (err || !res) {
      dispatch(
        enqueueSnackbar('Hubo un problema al cerrar la nómina.', {
          variant: 'error',
        })
      );
      return dispatch(PayrollTypes.CLOSE_PAYROLL.FAILURE.action(err));
    }
    dispatch(
      enqueueSnackbar('La nómina se cerro con éxito.', {
        variant: 'success',
      })
    );

    return dispatch(PayrollTypes.CLOSE_PAYROLL.SUCCESS.action(res));
  };
};
