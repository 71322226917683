import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { createInvoiceDiscountService } from '../services';

export const useCreateInvoiceDiscount = () => {
  const dispatch = useDispatch();
  const {
    isLoading: createInvoiceDiscountLoading,
    mutate: createInvoiceDiscount,
  } = useMutation(
    ({ sourceId, sourceType, body }) => {
      return createInvoiceDiscountService({ sourceId, sourceType, body });
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DISCOUNT_CREATED, {
            variant: 'success',
          })
        );
        onSuccessCallback();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar('error al crear descuento', {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    createInvoiceDiscountLoading,
    createInvoiceDiscount,
  };
};

export default useCreateInvoiceDiscount;
