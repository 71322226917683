import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Menu, IconButton, Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import { checkAuth } from '../../helpers/validation/auth';
import CountryFormatHelper from '../elements/CountryFormatHelper';
import { dateFormatter } from '../../helpers/DateUtils';
import { CREDITS_EDIT_FEE_PERFORM } from '../../helpers/performsType';
import { deleteFee, updateFee } from '../../actions/creditsActions';
import StatusChip from '../elements/chips/StatusChip';
import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import ChangeSimpleStatusDialog from '../dialogs/ChangeSimpleStatusDialog';
import MenuItem from '../elements/MenuItem';
import {
  Edit as EditIcon,
  More as MoreIcon,
  Delete as DeleteIcon,
} from '../icons';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import CreditFeesDialog from '../dialogs/CreditFeesDialog';

const DELETED = 'DELETED';

const CreditFeesTable = ({ handleChangePage }) => {
  const dispatch = useDispatch();

  const {
    fees,
    fetchFeesIsLoading,
    updateFeeIsLoading,
    feeUpdated,
    updateFeeError,
    deleteFeeIsLoading,
    feeDeleted,
    deleteFeeError,
    feesPagination,
  } = useSelector(state => state.credits);
  const { user, rules } = useSelector(state => state.auth);
  const { roles } = user;
  const { country } = useSelector(state => state.config);

  const [showUpdateFeeStatusDialog, setShowUpdateFeeStatusDialog] =
    useState(false);
  const [showUpdateFeeDialog, setShowUpdateFeeDialog] = useState(false);
  const [showDeleteFeeDialog, setShowDeleteFeeDialog] = useState(false);
  const [selectedCreditFee, setSelectedCreditFee] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (feeUpdated) {
      setShowUpdateFeeStatusDialog(false);
      setShowUpdateFeeDialog(false);
    }
  }, [feeUpdated]);

  useEffect(() => {
    if (feeDeleted) {
      setShowDeleteFeeDialog(false);
    }
  }, [feeDeleted]);

  const checkRole = checkAuth(roles, CREDITS_EDIT_FEE_PERFORM, rules);

  const columns = [
    {
      title: 'Nº',
      field: 'feeNumber',
      width: '50px',
      render: rowData => {
        return (
          <Typography align="center" variant="body2" color="textPrimary">
            {rowData.feeNumber}
          </Typography>
        );
      },
    },
    {
      title: 'Monto',
      field: 'amount',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.amount}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Fecha estimada de pago',
      field: 'estimatedPaymentDate',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(rowData.estimatedPaymentDate) || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Fecha de pago',
      field: 'paymentDate',
      type: 'date',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            {dateFormatter(rowData.paymentDate) || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Intereses',
      field: 'interest',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.interest}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Interés Devengado',
      field: 'accruedInterest',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.accruedInterest}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Capital',
      field: 'capital',
      render: rowData => {
        return (
          <Typography variant="body2" color="textPrimary">
            <CountryFormatHelper
              value={rowData?.capital}
              variant="currency"
              countryId={country}
            />
          </Typography>
        );
      },
    },
    {
      title: 'Estado',
      field: 'status',
      render: rowData => {
        return (
          <Box display="flex">
            <StatusChip
              size="small"
              status={rowData.status}
              entityType="creditFee"
              onClick={() => {
                setSelectedCreditFee(rowData);
                setShowUpdateFeeStatusDialog(true);
              }}
            />
          </Box>
        );
      },
    },
    {
      width: '50px',
      render: rowData => {
        return (
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedCreditFee(rowData);
            }}
          >
            <MoreIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Grid container item xs={12}>
      <MaterialTable
        style={{
          overflow: 'hidden',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 17,
        }}
        columns={columns}
        data={fees}
        isLoading={fetchFeesIsLoading}
        onChangeRowsPerPage={rowsPerPage => handleChangePage(1, rowsPerPage)}
        localization={{
          body: {
            emptyDataSourceMessage: 'No tenemos cuotas para mostrar',
          },
          pagination: {
            labelRowsPerPage: 'Cuotas por página',
            labelDisplayedRows: '{count} cuotas totales',
          },
        }}
        components={{
          Pagination: props => (
            <PaginationMaterialTable
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onChangePage={(e, page) =>
                // eslint-disable-next-line react/prop-types
                handleChangePage(page + 1, props.rowsPerPage)
              }
              count={feesPagination?.totalData}
              page={feesPagination?.page - 1}
            />
          ),
        }}
        options={{
          filtering: false,
          search: false,
          toolbar: false,
          showTitle: false,
          sorting: false,
          pageSize: feesPagination?.pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
          emptyRowsWhenPaging: false,
        }}
      />

      {showUpdateFeeStatusDialog && (
        <ChangeSimpleStatusDialog
          open={showUpdateFeeStatusDialog}
          status={selectedCreditFee?.status}
          title="Cambiar estado de la cuota"
          description="Selecciona el nuevo estado de la cuota."
          entityType="fee"
          handleCloseDialog={() => {
            setShowUpdateFeeStatusDialog(false);
          }}
          handleSubmit={status => {
            dispatch(
              updateFee(selectedCreditFee.id, {
                creditId: selectedCreditFee?.creditId,
                status,
              })
            );
          }}
          error={updateFeeError}
          isLoading={updateFeeIsLoading}
        />
      )}

      {showDeleteFeeDialog && (
        <ConfirmDialog
          isOpen={showDeleteFeeDialog}
          handleClose={() => setShowDeleteFeeDialog(false)}
          title="Eliminar cuota"
          message="Al eliminar la cuota ya no aparecerá en el backoffice."
          buttonOnAccept={() =>
            dispatch(
              deleteFee(selectedCreditFee.id, {
                creditId: selectedCreditFee?.creditId,
                status: DELETED,
              })
            )
          }
          buttonLabel="Eliminar"
          loading={deleteFeeIsLoading}
          error={deleteFeeError}
        />
      )}

      {showUpdateFeeDialog && (
        <CreditFeesDialog
          open={showUpdateFeeDialog}
          handleCloseDialog={() => setShowUpdateFeeDialog(false)}
          selectedCreditFees={selectedCreditFee}
          handleSubmit={formData =>
            dispatch(updateFee(selectedCreditFee?.id, formData))
          }
          isLoading={updateFeeIsLoading}
          error={updateFeeError}
        />
      )}

      <Menu
        id="menu-credit-fees"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          icon={<EditIcon />}
          disabled={!checkRole}
          onClick={() => {
            setShowUpdateFeeDialog(true);
            setAnchorEl(null);
          }}
        >
          Editar cuota
        </MenuItem>
        <MenuItem
          icon={<DeleteIcon />}
          disabled={!checkRole}
          onClick={() => {
            setShowDeleteFeeDialog(true);
            setAnchorEl(null);
          }}
        >
          Eliminar cuota
        </MenuItem>
      </Menu>
    </Grid>
  );
};

CreditFeesTable.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
};

export default CreditFeesTable;
