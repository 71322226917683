import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Text } from '../../../../commons/formatters';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import {
  OperationSummaryWrapper,
  OperationSummaryItem,
  TotalAmountText,
  DescriptionText,
  OperationCostText,
  FlexGrid,
  ExpandedGrid,
} from './styles';

const OperationSummary = ({ operationSummary }) => {
  const { country } = useSelector(state => state.config);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    baseRate,
    interest,
    operationCost,
    totalTransferAmount,
    totalInvoicesAmount,
    totalOperationCost,
    transferDiscount,
    debtBalance,
  } = operationSummary;

  const handleExpand = () => setIsExpanded(!isExpanded);

  return (
    <OperationSummaryWrapper container item direction="column">
      <OperationSummaryItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <TotalAmountText>Total a pagar</TotalAmountText>
        </Grid>
        <TotalAmountText fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="currency"
            value={totalTransferAmount}
          />
        </TotalAmountText>
      </OperationSummaryItem>
      <OperationSummaryItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <DescriptionText variant="subtitle1">
            Monto total facturas
          </DescriptionText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="currency"
            value={totalInvoicesAmount}
          />
        </Text>
      </OperationSummaryItem>
      <OperationSummaryItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <DescriptionText variant="subtitle1">
            Tasa de interés mensual
          </DescriptionText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="percentage"
            value={baseRate}
          />
        </Text>
      </OperationSummaryItem>
      <OperationSummaryItem
        item
        container
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <DescriptionText variant="subtitle1">
            Descuento a facturas
          </DescriptionText>
        </Grid>
        <Text variant="subtitle1" fontWeight="bold">
          <CountryFormatHelper
            countryId={country}
            variant="currency"
            value={transferDiscount}
          />
        </Text>
      </OperationSummaryItem>
      {country === 'CL' && (
        <OperationSummaryItem
          item
          container
          direction="row"
          justifyContent="space-between"
          xs={12}
        >
          <Grid item>
            <DescriptionText variant="subtitle1">
              Mora estado cartera
            </DescriptionText>
          </Grid>
          <Text variant="subtitle1" fontWeight="bold">
            <CountryFormatHelper
              countryId={country}
              variant="currency"
              value={debtBalance}
            />
          </Text>
        </OperationSummaryItem>
      )}
      <Grid item container xs={12} justifyContent="space-between">
        <FlexGrid item>
          <OperationCostText variant="subtitle1">
            Costo operación
          </OperationCostText>
          <IconButton size="small" onClick={handleExpand}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </FlexGrid>
        <Grid item>
          <Text variant="subtitle1" fontWeight="bold">
            <CountryFormatHelper
              countryId={country}
              variant="currency"
              value={totalOperationCost}
            />
          </Text>
        </Grid>
      </Grid>
      {isExpanded && (
        <ExpandedGrid container item direction="column">
          <OperationSummaryItem
            item
            container
            direction="row"
            justifyContent="space-between"
            xs={12}
          >
            <Grid item>
              <DescriptionText variant="subtitle1">
                Total intereses a pagar
              </DescriptionText>
            </Grid>
            <Text variant="subtitle1" fontWeight="bold">
              <CountryFormatHelper
                countryId={country}
                variant="currency"
                value={interest}
              />
            </Text>
          </OperationSummaryItem>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            xs={12}
          >
            <Grid item>
              <DescriptionText variant="subtitle1">
                Costo asesoría Xepelin
              </DescriptionText>
            </Grid>
            <Text variant="subtitle1" fontWeight="bold">
              <CountryFormatHelper
                countryId={country}
                variant="currency"
                value={operationCost}
              />
            </Text>
          </Grid>
        </ExpandedGrid>
      )}
    </OperationSummaryWrapper>
  );
};

OperationSummary.propTypes = {
  operationSummary: PropTypes.shape({
    baseRate: PropTypes.number,
    interest: PropTypes.number,
    debtBalance: PropTypes.number,
    operationCost: PropTypes.number,
    totalInvoicesAmount: PropTypes.number,
    totalOperationCost: PropTypes.number,
    totalTransferAmount: PropTypes.number,
    transferDiscount: PropTypes.number,
  }).isRequired,
};

export default OperationSummary;
