import React, { FC } from 'react';
import { Box, Checkbox } from '@material-ui/core';
import { OperationList } from '../../../../commons/interfaces/ARInterfaces';
import { ContainerCard, ContainerSelector, Divider } from './style';
import OperationCardHeader from './OperationCardHeader';
import OperationCardStats from './OperationCardStats';
import OperationCardAttributes from './OperationCardAttributes';
import OperationCardSectionButtons from './OperationCardSectionButtons';
import OperationCardSummary from './OperationCardSummary';
import useOperationCard from '../../../hooks/useOperationCard';

interface Props {
  operation: OperationList;
}

const OperationCard: FC<Props> = ({ operation }) => {
  const {
    isExpanded,
    handleToggleExpanded,
    handleSelectedOperation,
    isSelectedOperation,
    copyToClipboard,
  } = useOperationCard();

  return (
    <ContainerCard>
      <Box display="flex">
        <ContainerSelector>
          <Checkbox
            onChange={e =>
              handleSelectedOperation({
                operationId: operation?.id,
                checked: e.target.checked,
              })
            }
            checked={isSelectedOperation(operation?.id)}
          />
        </ContainerSelector>
        <Box display="flex" flexDirection="column">
          <OperationCardHeader operation={operation} />
          <OperationCardStats
            operation={operation}
            copyToClipboard={copyToClipboard}
          />
          <OperationCardAttributes
            operation={operation}
            isExpanded={isExpanded}
          />
        </Box>
      </Box>

      <Box display="flex">
        <OperationCardSectionButtons
          operation={operation}
          isExpanded={isExpanded}
          handleToggleExpanded={handleToggleExpanded}
        />
        <Divider orientation="vertical" />
        <OperationCardSummary isExpanded={isExpanded} operation={operation} />
      </Box>
    </ContainerCard>
  );
};

export default OperationCard;
