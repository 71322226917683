import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import {
  Drawer,
  TitleContainer,
  Title,
  Divider,
  BodyContainer,
  TitleIconContainer,
} from './styles';
import { Close } from '../../../../components/icons';

const useStyles = makeStyles({
  rootContainer: {
    width: props => props.width,
    padding: '25px 46px',
  },
});

const BaseDrawer = ({
  open,
  handleClose,
  title,
  titleIcon,
  width,
  children,
  ...otherProps
}) => {
  const classes = useStyles({ width });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Drawer anchor="right" open={open} onClose={handleClose} {...otherProps}>
      <Grid className={classes.rootContainer}>
        <TitleContainer>
          <Box display="flex">
            {titleIcon && <TitleIconContainer>{titleIcon}</TitleIconContainer>}
            <Title>{title}</Title>
          </Box>
          <IconButton onClick={handleClose} data-cy="orderCommentsCloseButton">
            <Close color="#000" />
          </IconButton>
        </TitleContainer>
        <Divider />
        <BodyContainer>{children}</BodyContainer>
      </Grid>
    </Drawer>
  );
};

BaseDrawer.defaultProps = {
  children: null,
  titleIcon: null,
  width: 'auto',
};

BaseDrawer.propTypes = {
  children: PropTypes.element,
  titleIcon: PropTypes.element,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  width: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

// eslint-disable-next-line import/prefer-default-export
export { BaseDrawer };
