import { makeStyles } from '@material-ui/core';
import { lightenColor } from '../../../../../helpers/Colors';
import { WHITE_BORDER_COLOR } from '../../../../../helpers/Constants';
import {
  ACTION_BAR,
  ALT_BORDER_COLOR,
  DRAWER_SECONDARY,
} from '../../../../../theme/otherColors';

const styles = makeStyles(theme => ({
  tableContainer: {
    background: 'white',
    borderRadius: 17,
    border: `1px solid ${WHITE_BORDER_COLOR}`,
    fontSize: '0.7777777777777778rem',
    fontWeight: 400,
    lineHeight: '1.43',
    textAlign: 'left',
  },
  table: {
    borderTop: `1px solid ${WHITE_BORDER_COLOR}`,
    display: 'block',
  },
  cessionStatusRow: {
    display: 'flex',
    alignItems: 'center',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  displayContents: {
    display: 'contents',
  },
  checkCircleGreaterThan8Days: {
    color: theme.palette.success.main,
    fontSize: 16,
  },
  checkCircleDehDescripcion: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  checkCirclePreselected: {
    color: theme.palette.warning.main,
    fontSize: 16,
  },
  verde: {
    marginRight: 5,
    background: `${theme.palette.success.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  amarillo: {
    marginRight: 5,
    background: `${theme.palette.warning.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  rojo: {
    marginRight: 5,
    background: `${theme.palette.error.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  iconGreen: {
    color: theme.palette.success.main,
    marginBottom: 8,
  },
  iconRed: {
    color: theme.palette.error.main,
    marginBottom: 8,
  },
  iconGray: {
    marginBottom: 8,
  },
  verifiedMargin: {
    marginLeft: 3,
    marginRight: 3,
  },
  replacedFolio: {
    color: theme.palette.primary.main,
  },
  containerFlex: {
    display: 'flex',
  },
  debtSuperRed: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
    fontSize: 20,
  },
  debtSuperGray: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  noTransferredOrders: {
    backgroundColor: theme.palette.success.light,
  },
  orderIdColumn: {
    textAlign: 'left',
    padding: '16px 0 16px 0',
    width: 50,
  },
  Xepelin: {
    margin: '0px 3px 12px 3px',
    width: 22,
    transform: 'scale(0.9)',
  },
  // Dont delete this dicom classes, they are used in getDicomIcon method.
  dicomGreen: {
    fontWeight: 'bold',
    color: theme.palette.success.main,
  },
  dicomRed: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
  dicomGray: {
    fontWeight: 'bold',
  },
  loaderScore: {
    marginTop: 2,
    marginLeft: 10,
  },
  customMessage: {
    marginRight: 10,
    borderRadius: 17,
    padding: '5px 15px',
    '&:hover': {
      background: lightenColor(ACTION_BAR.backgroundColor, 20),
      cursor: 'pointer',
    },
  },
  cursorPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  containerIcon: {
    marginTop: 6,
  },
  emptyResumeContainer: {
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    borderRadius: '15px',
    padding: '20px',
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
    textAlign: 'center',
  },
  emptyResumeIcon: {
    margin: '35px auto 26px auto',
  },
  bottomContainer: { columnGap: '10px' },
  labelInfo: {
    fontSize: 14,
    marginRight: 5,
    textTransform: 'none',
  },
  info: {
    marginRight: 30,
    fontSize: 14,
  },
  rootInfo: {
    display: 'flex',
  },
}));

export default styles;
