import {
  FETCH_REASONS_START,
  FETCH_REASONS_SUCCESS,
  FETCH_REASONS_FAILURE,
} from '../actions/types';

const initialState = {
  orderInvoiceReasons: [],
  orderInvoiceReasonsOwnerId: null,
  orderInvoiceReasonsIsLoading: false,
  orderInvoiceReasonsError: null,
};

const fetchReasonsStart = (state, payload) => {
  const { sourceType, sourceId } = payload;
  return {
    ...state,
    [`${sourceType}ReasonsIsLoading`]: true,
    [`${sourceType}ReasonsError`]: null,
    [`${sourceType}Reasons`]: [],
    [`${sourceType}ReasonsOwnerId`]: sourceId,
  };
};

const fetchReasonsSuccess = (state, payload) => {
  const { sourceType, reasons } = payload;
  return {
    ...state,
    [`${sourceType}ReasonsIsLoading`]: false,
    [`${sourceType}Reasons`]: reasons,
  };
};

const fetchReasonsFailure = (state, payload) => {
  const { sourceType } = payload;
  return {
    ...state,
    [`${sourceType}ReasonsIsLoading`]: false,
    [`${sourceType}ReasonsError`]: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REASONS_START:
      return fetchReasonsStart(state, payload);
    case FETCH_REASONS_SUCCESS:
      return fetchReasonsSuccess(state, payload);
    case FETCH_REASONS_FAILURE:
      return fetchReasonsFailure(state, payload);
    default:
      return state;
  }
};
