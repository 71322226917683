import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../../../../actions/notificationAction';
import { t13s } from '../../../../../../../translationKeys/index';
import statusInvoicesBulkUpdateService from '../service/statusInvoicesBulkUpdateService';
import dateInvoicesBulkUpdateService from '../service/dateInvoicesBulkUpdateService';
import { InvoicesBulkUpdateType } from '../../../../../../commons/interfaces/ARInterfaces';

const useInvoicesBulkUpdate = (sourceType: InvoicesBulkUpdateType) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    mutate: uploadFile,
    reset: resetDataAndErrors,
  } = useMutation(
    csvFile => {
      if (sourceType === 'STATUS') {
        return statusInvoicesBulkUpdateService(csvFile);
      }
      return dateInvoicesBulkUpdateService(csvFile);
    },
    {
      onSuccess: response => {
        if (!response?.errorLines?.length) {
          dispatch(
            enqueueSnackbar(t13s.NOTIFICATION.INVOICES_BULK_UPDATE_SUCCESS, {
              variant: 'success',
            })
          );
        }
      },
    }
  );

  const errorLines = data?.errorLines;
  const hasErrors = !!errorLines?.length || isError;
  const serverError = error || null;

  return {
    isLoading,
    hasErrors,
    errorLines,
    serverError,
    isSuccess,
    uploadFile,
    resetDataAndErrors,
  };
};

export default useInvoicesBulkUpdate;
