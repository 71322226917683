import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  IconButton,
  Typography,
  Grid,
  AppBar,
  Toolbar,
  Drawer,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseOutlined } from '@material-ui/icons';
import MoveInvoicesDrawer from './components/MoveInvoicesDrawer';
import AddInvoicesDrawer from './components/AddInvoicesDrawer';
import { DRAWER_SECONDARY } from '../../../../../theme/otherColors';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '100%',
    background: theme.palette.grey[100],
  },
  appBar: {
    padding: '19px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
  },
  navTitle: {
    flex: '1',
  },
  container: {
    width: '90%',
    padding: '36px 0',
    margin: '0 auto',
  },
}));

const ManageInvoiceDrawer = ({
  show,
  closeDrawer,
  country,
  selectedInvoices,
  manageSelectedInvoices,
  drawerType,
  order,
}) => {
  const moveDrawer = drawerType === 'move';
  const classes = useStyles();
  const { manageOrderInvoicesIsLoading } = useSelector(state => state.order);

  return (
    <Drawer
      anchor="right"
      width="100%"
      open={show}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={closeDrawer}
      disableEscapeKeyDown
    >
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item className={classes.navTitle} justify="center">
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                noWrap
                component="div"
              >
                <Box fontWeight="fontWeightBold">
                  {moveDrawer ? 'Mover Facturas' : 'Agregar Facturas'}
                </Box>
              </Typography>
              <Typography variant="body2" align="center" noWrap>
                {moveDrawer
                  ? 'Selecciona la operación a la que desea mover las facturas seleccionadas.'
                  : 'Selecciona las facturas que deseas agregar a la operación, edita sus tasas y verifica el resumen de la operación.'}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={closeDrawer}
              >
                <CloseOutlined />{' '}
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.container}
      >
        {moveDrawer ? (
          <MoveInvoicesDrawer
            show={show}
            country={country}
            selectedInvoices={selectedInvoices}
            manageSelectedInvoices={manageSelectedInvoices}
            manageOrderInvoicesIsLoading={manageOrderInvoicesIsLoading}
            order={order}
          />
        ) : (
          <AddInvoicesDrawer
            show={show}
            manageSelectedInvoices={manageSelectedInvoices}
            manageOrderInvoicesIsLoading={manageOrderInvoicesIsLoading}
            order={order}
          />
        )}
      </Grid>
    </Drawer>
  );
};

ManageInvoiceDrawer.defaultProps = {
  show: false,
  drawerType: 'move',
};

ManageInvoiceDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  show: PropTypes.bool,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      invoiceId: PropTypes.number.isRequired,
      baseRate: PropTypes.number.isRequired,
      expirationDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  manageSelectedInvoices: PropTypes.func.isRequired,
  drawerType: PropTypes.string,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default ManageInvoiceDrawer;
