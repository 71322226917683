import {
  FETCH_DEBT_SERVICE_START,
  FETCH_DEBT_SERVICE_SUCCESS,
  FETCH_DEBT_SERVICE_FAILURE,
  CONSULT_DEBT_SERVICE_START,
  CONSULT_DEBT_SERVICE_SUCCESS,
  CONSULT_DEBT_SERVICE_FAILURE,
  RESET_CONSULT_DEBT_SERVICE,
  FETCH_DEBT_SERVICE_BYIDENTIFIER_START,
  FETCH_DEBT_SERVICE_BYIDENTIFIER_SUCCESS,
  FETCH_DEBT_SERVICE_BYIDENTIFIER_FAILURE,
  CONSULT_DEBT_SERVICE_BYIDENTIFIER_START,
  CONSULT_DEBT_SERVICE_BYIDENTIFIER_SUCCESS,
  CONSULT_DEBT_SERVICE_BYIDENTIFIER_FAILURE,
  CONSULT_INVOICE_DEBT_SERVICE_START,
  CONSULT_INVOICE_DEBT_SERVICE_SUCCESS,
  CONSULT_INVOICE_DEBT_SERVICE_FAILURE,
  BULK_FETCH_DEBT_SERVICE_START,
  BULK_FETCH_DEBT_SERVICE_SUCCESS,
  BULK_FETCH_DEBT_SERVICE_FAILURE,
  FETCH_DEBT_SUPER_START,
  FETCH_DEBT_SUPER_SUCCESS,
  FETCH_DEBT_SUPER_FAILURE,
  CONSULT_DEBT_SUPER_START,
  CONSULT_DEBT_SUPER_SUCCESS,
  CONSULT_DEBT_SUPER_FAILURE,
  CONSULT_INVOICE_DEBT_SUPER_START,
  CONSULT_INVOICE_DEBT_SUPER_SUCCESS,
  CONSULT_INVOICE_DEBT_SUPER_FAILURE,
  BULK_FETCH_DEBT_SUPER_START,
  BULK_FETCH_DEBT_SUPER_SUCCESS,
  BULK_FETCH_DEBT_SUPER_FAILURE,
} from '../actions/types';

const initialState = {
  debtService: null,
  consulted: false,
  consulting: false,
  canConsult: false,
  fetched: false,

  debtList: {},
  debtServiceError: null,

  consultInvoiceDebtServiceIsLoading: false,
  invoiceDebtServiceWasConsult: false,
  consultInvoiceDebtServiceError: null,

  bulkFetchDebtServiceIsLoading: false,
  bulkDebtService: {},
  bulkFetchDebtServiceError: null,

  fetchDebtSuperIsLoading: false,
  debtSuper: {},
  fetchDebtSuperError: null,

  consultDebtSuperIsLoading: false,
  debtSuperWasConsult: false,
  consultDebtSuperError: null,

  consultInvoiceDebtSuperIsLoading: false,
  invoiceDebtSuperWasConsult: false,
  consultInvoiceDebtSuperError: null,

  bulkFetchDebtSuperIsLoading: false,
  bulkDebtSuper: {},
  bulkFetchDebtSuperError: null,
};

const fechDebtServiceByidentifierStart = (state, payload) => {
  const { entityId, entityType } = payload;
  const { debtList } = state;
  const updatedDebtList = {
    ...debtList,
    [entityType]: { ...debtList[entityType], [entityId]: { loading: true } },
  };
  return {
    ...state,
    debtList: { ...updatedDebtList },
  };
};

const fechDebtServiceByidentifierSuccess = (state, payload) => {
  const { entityId, entityType, data } = payload;
  const { debtList } = state;
  const updatedDebtList = {
    ...debtList,
    [entityType]: {
      ...debtList[entityType],
      [entityId]: { loading: false, ...data },
    },
  };
  return {
    ...state,
    debtList: { ...updatedDebtList },
  };
};

const fechDebtServiceByidentifierFailure = (state, payload) => {
  const { entityId, entityType, data } = payload;
  const { debtList } = state;
  const updatedDebtList = {
    ...debtList,
    [entityType]: {
      ...debtList[entityType],
      [entityId]: { loading: false, error: data },
    },
  };
  return {
    ...state,
    debtList: { ...updatedDebtList },
  };
};

const consultDebtServiceByIdentifierStart = state => {
  return {
    ...state,
    consulting: true,
    consulted: false,
  };
};

const consultDebtServiceByIdentifierSuccess = (state, payload) => {
  const { entityId, entityType, data } = payload;
  const { debtList } = state;
  const updatedDebtList = {
    ...debtList,
    [entityType]: {
      ...debtList[entityType],
      [entityId]: { loading: false, ...data },
    },
  };
  return {
    ...state,
    debtList: { ...updatedDebtList },
    consulting: false,
    consulted: true,
  };
};

const consultDebtServiceByIdentifierFailure = (state, payload) => {
  return {
    ...state,
    consulting: true,
    consulted: false,
    debtServiceError: payload.errorCode,
  };
};

const consultInvoiceDebtServiceStart = state => {
  return {
    ...state,
    invoiceDebtServiceWasConsult: false,
    consultInvoiceDebtServiceIsLoading: true,
    consultInvoiceDebtServiceError: null,
  };
};

const consultInvoiceDebtServiceSuccess = (state, payload) => {
  const { debtService } = payload;
  return {
    ...state,
    consultInvoiceDebtServiceIsLoading: false,
    invoiceDebtServiceWasConsult: true,
    bulkDebtService: {
      ...state.bulkDebtService,
      [debtService.identifier]: debtService,
    },
  };
};

const consultInvoiceDebtServiceFailure = (state, payload) => {
  return {
    ...state,
    consultInvoiceDebtServiceIsLoading: false,
    consultInvoiceDebtServiceError: payload.errorCode,
  };
};

const bulkFetchDebtServiceStart = state => {
  return {
    ...state,
    bulkFetchDebtServiceIsLoading: true,
    bulkDebtService: {},
    bulkFetchDebtServiceError: null,
  };
};

const bulkFetchDebtServiceSuccess = (state, payload) => {
  return {
    ...state,
    bulkFetchDebtServiceIsLoading: false,
    bulkDebtService: payload.bulkDebtService,
  };
};

const bulkFetchDebtServiceFailure = (state, payload) => {
  return {
    ...state,
    bulkFetchDebtServiceIsLoading: false,
    bulkFetchDebtServiceError: payload.errorCode,
  };
};

const fetchDebtSuperStart = state => {
  return {
    ...state,
    fetchDebtSuperIsLoading: true,
    debtSuper: {},
    fetchDebtSuperError: null,
  };
};

const fetchDebtSuperSuccess = (state, payload) => {
  return {
    ...state,
    fetchDebtSuperIsLoading: false,
    debtSuper: payload.debtSuper,
  };
};

const fetchDebtSuperFailure = (state, payload) => {
  return {
    ...state,
    fetchDebtSuperIsLoading: false,
    fetchDebtSuperError: payload.errorCode,
  };
};

const consultDebtSuperStart = state => {
  return {
    ...state,
    consultDebtSuperIsLoading: true,
    debtSuperWasConsult: false,
    consultDebtSuperError: null,
  };
};

const consultDebtSuperSuccess = (state, payload) => {
  return {
    ...state,
    consultDebtSuperIsLoading: false,
    debtSuperWasConsult: true,
    debtSuper: payload.debtSuper,
  };
};

const consultDebtSuperFailure = (state, payload) => {
  return {
    ...state,
    consultDebtSuperIsLoading: false,
    consultDebtSuperError: payload.errorCode,
  };
};

const consultInvoiceDebtSuperStart = state => {
  return {
    ...state,
    invoiceDebtSuperWasConsult: false,
    consultInvoiceDebtSuperIsLoading: true,
    consultInvoiceDebtSuperError: null,
  };
};

const consultInvoiceDebtSuperSuccess = (state, payload) => {
  const { debtSuper } = payload;
  return {
    ...state,
    consultInvoiceDebtSuperIsLoading: false,
    invoiceDebtSuperWasConsult: true,
    bulkDebtSuper: {
      ...state.bulkDebtSuper,
      [debtSuper.identifier]: debtSuper,
    },
  };
};

const consultInvoiceDebtSuperFailure = (state, payload) => {
  return {
    ...state,
    consultInvoiceDebtSuperIsLoading: false,
    consultInvoiceDebtSuperError: payload.errorCode,
  };
};

const bulkFetchDebtSuperStart = state => {
  return {
    ...state,
    bulkFetchDebtSuperIsLoading: true,
    bulkDebtSuper: {},
    bulkFetchDebtSuperError: null,
  };
};

const bulkFetchDebtSuperSuccess = (state, payload) => {
  return {
    ...state,
    bulkFetchDebtSuperIsLoading: false,
    bulkDebtSuper: payload.bulkDebtSuper,
  };
};

const bulkFetchDebtSuperFailure = (state, payload) => {
  return {
    ...state,
    bulkFetchDebtSuperIsLoading: false,
    bulkFetchDebtSuperError: payload.errorCode,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONSULT_DEBT_SERVICE_START:
      return {
        ...state,
        consulting: true,
      };
    case CONSULT_DEBT_SERVICE_SUCCESS:
      return {
        ...state,
        consulting: false,
        consulted: true,
        debtService: payload?.debtService ?? payload,
        canConsult: payload?.canConsult ?? state?.canConsult,
      };
    case CONSULT_DEBT_SERVICE_FAILURE:
      return {
        ...state,
        consulting: false,
        consulted: true,
      };
    case RESET_CONSULT_DEBT_SERVICE:
      return {
        ...state,
        consulting: false,
        consulted: false,
        fetched: false,
      };
    case FETCH_DEBT_SERVICE_START:
      return {
        ...state,
        debtService: null,
        canConsult: false,
      };
    case FETCH_DEBT_SERVICE_SUCCESS:
      return {
        ...state,
        debtService: payload.debtService,
        canConsult: payload.canConsult,
        fetched: true,
      };
    case FETCH_DEBT_SERVICE_FAILURE:
      return {
        ...state,
        debtService: null,
        canConsult: false,
        fetched: false,
      };
    case FETCH_DEBT_SERVICE_BYIDENTIFIER_START:
      return fechDebtServiceByidentifierStart(state, payload);
    case FETCH_DEBT_SERVICE_BYIDENTIFIER_SUCCESS:
      return fechDebtServiceByidentifierSuccess(state, payload);
    case FETCH_DEBT_SERVICE_BYIDENTIFIER_FAILURE:
      return fechDebtServiceByidentifierFailure(state, payload);
    case CONSULT_DEBT_SERVICE_BYIDENTIFIER_START:
      return consultDebtServiceByIdentifierStart(state);
    case CONSULT_DEBT_SERVICE_BYIDENTIFIER_SUCCESS:
      return consultDebtServiceByIdentifierSuccess(state, payload);
    case CONSULT_DEBT_SERVICE_BYIDENTIFIER_FAILURE:
      return consultDebtServiceByIdentifierFailure(state, payload);
    case CONSULT_INVOICE_DEBT_SERVICE_START:
      return consultInvoiceDebtServiceStart(state);
    case CONSULT_INVOICE_DEBT_SERVICE_SUCCESS:
      return consultInvoiceDebtServiceSuccess(state, payload);
    case CONSULT_INVOICE_DEBT_SERVICE_FAILURE:
      return consultInvoiceDebtServiceFailure(state, payload);
    case BULK_FETCH_DEBT_SERVICE_START:
      return bulkFetchDebtServiceStart(state);
    case BULK_FETCH_DEBT_SERVICE_SUCCESS:
      return bulkFetchDebtServiceSuccess(state, payload);
    case BULK_FETCH_DEBT_SERVICE_FAILURE:
      return bulkFetchDebtServiceFailure(state, payload);
    case FETCH_DEBT_SUPER_START:
      return fetchDebtSuperStart(state);
    case FETCH_DEBT_SUPER_SUCCESS:
      return fetchDebtSuperSuccess(state, payload);
    case FETCH_DEBT_SUPER_FAILURE:
      return fetchDebtSuperFailure(state, payload);
    case CONSULT_DEBT_SUPER_START:
      return consultDebtSuperStart(state);
    case CONSULT_DEBT_SUPER_SUCCESS:
      return consultDebtSuperSuccess(state, payload);
    case CONSULT_DEBT_SUPER_FAILURE:
      return consultDebtSuperFailure(state, payload);
    case CONSULT_INVOICE_DEBT_SUPER_START:
      return consultInvoiceDebtSuperStart(state);
    case CONSULT_INVOICE_DEBT_SUPER_SUCCESS:
      return consultInvoiceDebtSuperSuccess(state, payload);
    case CONSULT_INVOICE_DEBT_SUPER_FAILURE:
      return consultInvoiceDebtSuperFailure(state, payload);
    case BULK_FETCH_DEBT_SUPER_START:
      return bulkFetchDebtSuperStart(state);
    case BULK_FETCH_DEBT_SUPER_SUCCESS:
      return bulkFetchDebtSuperSuccess(state, payload);
    case BULK_FETCH_DEBT_SUPER_FAILURE:
      return bulkFetchDebtSuperFailure(state, payload);
    default:
      return state;
  }
};
