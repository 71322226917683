import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const mapOrderInvoice = ({
  id,
  orderId,
  expirationDate,
  paymentConfirmed,
  paymentDate,
  fundPaymentDate,
  fundExpirationDate,
  fechaVencimiento,
  verificationStatus,
  statusReason,
  status,
  discount,
  discountReason,
  baseRate,
  amountRetention,
  destinationId,
  oldStatus,
  defaultDate,
}) => {
  return {
    id,
    orderId,
    expirationDate,
    paymentConfirmed,
    paymentDate,
    fundPaymentDate,
    fundExpirationDate,
    fechaVencimiento,
    verificationStatus,
    statusReason,
    status,
    discount,
    discountReason,
    baseRate,
    tasaBase: baseRate,
    destinationId,
    oldStatus,
    defaultDate,
    ...(typeof amountRetention !== 'undefined' && { amountRetention }),
  };
};

export const updateOrderInvoiceStatusService = async ({
  id,
  invoiceData,
  extraData = {},
}) => {
  try {
    const { notCalculateSurplus = null } = extraData;
    const body = mapOrderInvoice(invoiceData);
    const { data } = await axios.put(`/api/orderfactura/${id}`, {
      data: {
        ...body,
        notCalculateSurplus,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateOrderInvoiceStatusService;
