import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchOrderInvoicesAttributesService = async orderId => {
  try {
    const { data } = await axios.get(
      `/api/orders/${orderId}/invoices/attributes`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default fetchOrderInvoicesAttributesService;
