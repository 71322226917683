import { restFunds } from '../../../../infra/http';
import * as ReceiverMap from './receiver.map';
import * as CommonReceiverMap from '../../../../commons/mappers/receiver.map';
import { purgeNestedObj } from '../../../../commons/utils';

export const fetchReceivers = filter => {
  const { payrollId, ...fields } =
    CommonReceiverMap.receiverFilterMap.toPersistence(filter);

  return restFunds()
    .get(`payroll/${payrollId}/receivers`, { params: purgeNestedObj(fields) })
    .then(r => r.data)
    .then(r => r.receivers.map(ReceiverMap.receiverMap.toDomain));
};

export const updateReceivers = form => {
  const body = ReceiverMap.receiverEditionMap.toPersistence(form);

  return restFunds()
    .post('payrollinvoice/receivers/bulk-update', body)
    .then(r => r.data)
    .then(ReceiverMap.receiverEditionMap.toDomain);
};
