import { makeStyles } from '@material-ui/core';
import { DRAWER_SECONDARY } from '../../../../theme/otherColors';

export const useStyles = makeStyles(() => ({
  drawer: {
    width: '100%',
  },
  appBar: {
    padding: '19px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
  },
  navTitle: {
    flex: '1',
  },
  container: {
    padding: '40px 15px 0',
  },
  containerBody: {
    columnGap: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '120px',
  },
  containerTables: {
    width: 'calc(100% - 300px)',
  },
}));
