import axios from 'axios';

export const registerOrderCreditService = async (orderId, creditData) => {
  try {
    //  EDNPOINT IS TEMPORARY UNTIL REFACTOR (SHOULD IMPLEMENT ENDPOINT UNDER PAYMENTS DOMAIN IN SERVER GLOBAL)
    const { data } = await axios.post(
      `/api/orders/${orderId}/credit`,
      creditData
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default registerOrderCreditService;
