import { useQuery } from 'react-query';

import * as Repo from './payroll.repo';

const FETCH_PAYROLLS_KEY = 'fetchPayrolls';

export const useFetchPayrolls = (filters, opts) => {
  const { data: payrolls, ...rest } = useQuery(
    [FETCH_PAYROLLS_KEY, filters],
    () => Repo.fetchPayrolls(filters),
    opts
  );

  return { payrolls, ...rest };
};
