import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { updateInvoiceService } from '../services';
import { useFetchInvoices } from './useFetchInvoices';
import { useFetchOrderInvoicesFunds } from './useFetchOrderInvoicesFunds';

export const useUpdateInvoice = () => {
  const dispatch = useDispatch();
  const { id: orderId } = useParams();
  const { refetch: fetchInvoices } = useFetchInvoices(orderId);
  const { refetch: fetchOrderInvoicesFunds } =
    useFetchOrderInvoicesFunds(orderId);

  const {
    isLoading: updateInvoiceIsLoading,
    isSuccess: invoiceWasUpdated,
    error: updateInvoiceError,
    mutate: updateInvoice,
  } = useMutation(
    ({ orderInvoiceId, invoice }) => {
      return updateInvoiceService({ orderInvoiceId, invoice });
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.INVOICE_UPDATED, {
            variant: 'success',
          })
        );
        fetchInvoices();
        fetchOrderInvoicesFunds();
        onSuccessCallback();
      },
      onError: () => {
        dispatch(
          enqueueSnackbar('Error al actualizar factura', {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    updateInvoice,
    updateInvoiceIsLoading,
    invoiceWasUpdated,
    updateInvoiceError,
  };
};

export default useUpdateInvoice;
