// TODO: funds borrar luego de sacar de nominas
import {
  FETCH_FUND_PAYERS_START,
  FETCH_FUND_PAYERS_SUCCESS,
  FETCH_FUND_PAYERS_FAILURE,
  FETCH_FUND_PAYER_DETAIL_INFO_START,
  FETCH_FUND_PAYER_DETAIL_INFO_SUCCESS,
  FETCH_FUND_PAYER_DETAIL_INFO_FAILURE,
  FETCH_FUNDS_LIST_START,
  FETCH_FUNDS_LIST_SUCCESS,
  FETCH_FUNDS_LIST_FAILURE,
  REMOVE_SELECTED_INVOICE,
  HANDLE_SHOW_FUND_MANAGE_INVOICES,
  SEND_PAYROLL_START,
  SEND_PAYROLL_SUCCESS,
  SEND_PAYROLL_FAILURE,
  RESET_SEND_PAYROLL,
  TOGGLE_INVOICE_DETAIL,
  FETCH_INVOICE_INFO_START,
  FETCH_INVOICE_INFO_SUCCESS,
  FETCH_INVOICE_INFO_FAILURE,
  TOGGLE_DEBTOR_DETAIL,
  HANDLE_SHOW_FUND_SHOPPING_CART,
  UPDATE_RECOMMENDED_RATES,
  UPDATE_RATES_FAILURE,
  UPDATE_FUNDS_INVOICES_CART,
  SET_SIMULATION_DATA,
  SIMULATION_ERROR,
  UPDATE_EXPIRATION_DATES,
  UPDATE_EXPIRATION_DATES_FAILURE,
  FUNDS_SIMULATION_START,
} from './types';
import { getTermInDays, purgeNestedObj } from '../funds/commons/utils';
import * as CommonDocumentMap from '../funds/opportunities/domains/document/services/document.map';
import * as CommonReceiverMap from '../funds/commons/mappers/receiver.map';
import { restFunds } from '../funds/infra/http';

export const fetchReceivers = filter => async dispatch => {
  try {
    dispatch({ type: FETCH_FUND_PAYERS_START });
    const params = purgeNestedObj(
      CommonReceiverMap.opportunityReceiverFilterMap.toPersistence(filter)
    );

    // NOTE: to not take the identifier used in invoices view to filter receivers
    let frmToSave = filter;
    if (filter?.identifier) {
      const { identifier, ...rest } = filter;
      frmToSave = rest;
    }

    await restFunds()
      .get('receiver', {
        params: {
          opportunity: true,
          ...(filter?.identifier && {
            identifier: filter?.identifier,
          }),
          ...params,
        },
      })
      .then(({ data }) => {
        const payload = {
          payers: data,
          filter: frmToSave,
          isSingle: !!filter.identifier,
        };
        dispatch({
          type: FETCH_FUND_PAYERS_SUCCESS,
          payload,
        });
      });
  } catch (error) {
    dispatch({ type: FETCH_FUND_PAYERS_FAILURE, payload: error });
  }
};

export const fetchFundPayerDetailInfo =
  (frm, receiverFilter = undefined) =>
  // NOTE: param receiverFilter is only used in especial cases when selection filter and current filters are different
  dispatch => {
    dispatch({ type: FETCH_FUND_PAYER_DETAIL_INFO_START });
    const formFields = CommonDocumentMap.documentFilterMap.toPersistence(frm);

    const {
      minAmount,
      maxAmount,
      issuerIdentifier,
      operationType,
      status,
      minBaseRate,
      maxBaseRate,
      folio,
      minIssuedDate,
      maxIssuedDate,
      minExpirationDate,
      maxExpirationDate,
      issuerName,
      assigneeName,
      assigneeIdentifier,
      orderIds,
    } = formFields;

    restFunds()
      .get(`/receiver/${formFields.receiverIdentifier}/invoices`, {
        params: purgeNestedObj({
          minAmount,
          maxAmount,
          issuerIdentifier,
          operationType,
          status,
          minBaseRate,
          maxBaseRate,
          folio,
          minIssuedDate,
          maxIssuedDate,
          minExpirationDate,
          maxExpirationDate,
          discounts: frm.discount,
          issuerName,
          opportunity: true,
          assigneeName,
          assigneeIdentifier,
          orderIds,
        }),
      })
      .then(({ data: invoices = [] }) => {
        dispatch({
          type: FETCH_FUND_PAYER_DETAIL_INFO_SUCCESS,
          payload: {
            payerIdentifier: frm.receiverIdentifier,
            invoices,
          },
        });
        if (receiverFilter) {
          dispatch(
            fetchFundPayerDetailInfo({
              ...receiverFilter,
              receiverIdentifier: formFields.receiverIdentifier,
            })
          );
        }
      })
      .catch(err => {
        dispatch({ type: FETCH_FUND_PAYER_DETAIL_INFO_FAILURE, payload: err });
      });
  };

export const fetchFundsList = () => dispatch => {
  dispatch({ type: FETCH_FUNDS_LIST_START });

  restFunds()
    .get('fund', { params: { features: true, active: true } })
    .then(({ data: funds }) => {
      dispatch({
        type: FETCH_FUNDS_LIST_SUCCESS,
        payload: funds,
      });
    })
    .catch(error =>
      dispatch({ type: FETCH_FUNDS_LIST_FAILURE, payload: error })
    );
};

export const fetchInvoiceFullInformation =
  ({ id }) =>
  dispatch => {
    dispatch({ type: FETCH_INVOICE_INFO_START });
    restFunds()
      .get(`/invoice/${id}`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_INVOICE_INFO_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        dispatch({ type: FETCH_INVOICE_INFO_FAILURE, payload: err });
      });
  };

export const removeSelectedInvoice = invoice => dispatch => {
  dispatch({
    type: REMOVE_SELECTED_INVOICE,
    payload: { invoice },
  });
};

export const handleShowFundDetailInvoice =
  ({ isOpen }) =>
  dispatch => {
    dispatch({
      type: TOGGLE_INVOICE_DETAIL,
      payload: { isOpen },
    });
  };

export const handleShowFundDebtorDetail =
  ({ isOpen }) =>
  dispatch => {
    dispatch({
      type: TOGGLE_DEBTOR_DETAIL,
      payload: { isOpen },
    });
  };

export const handleShowShoppingCart = isOpen => dispatch => {
  dispatch({
    type: HANDLE_SHOW_FUND_SHOPPING_CART,
    payload: { isOpen },
  });
};

const parsePayrollInvoicesToPost = (invoicesSelected, customRate) => {
  const allInvoices = Object.values(invoicesSelected).flatMap(
    invoiceSelected => invoiceSelected
  );

  return allInvoices.map(invoice => {
    const {
      id: invoiceId,
      expirationDate,
      rate,
      advancePercentage = 100,
      Issuer: { debtServiceAmount: issuerDebtServiceAmount },
      Receiver: {
        debtServiceAmount: receiverDebtServiceAmount,
        identifier: receiverIdentifier,
      },
    } = invoice;

    return {
      invoiceId,
      rate: rate ?? customRate[receiverIdentifier],
      expirationDate,
      issuerDebtServiceAmount,
      receiverDebtServiceAmount,
      advancePercentage,
    };
  });
};

export const sendPayroll =
  (fundId, invoices, countryId, customRate) => dispatch => {
    dispatch({ type: SEND_PAYROLL_START });
    const parsedInvoices = parsePayrollInvoicesToPost(invoices, customRate);

    restFunds()
      .post('payroll', {
        fundId,
        countryId,
        type: 'sales', // TODO: fill logic to handle types of payrolls
        data: parsedInvoices,
      })
      .then(() => {
        dispatch({
          type: SEND_PAYROLL_SUCCESS,
          payload: parsedInvoices,
        });
      })
      .catch(error => dispatch({ type: SEND_PAYROLL_FAILURE, payload: error }));
  };

export const fetchRecommendedRates = (invoices, fundId) => dispatch => {
  const parsedInvoicesDni = Array.from(
    new Set(
      Object.values(invoices)
        .flatMap(a => a)
        .map(invoice => invoice.Receiver.identifier)
    )
  );

  restFunds()
    .post('rate/recommended', {
      fundId,
      receiverIdentifiers: parsedInvoicesDni,
    })
    .then(({ data }) => {
      dispatch({ type: UPDATE_RECOMMENDED_RATES, payload: data });
    })
    .catch(error => dispatch({ type: UPDATE_RATES_FAILURE, payload: error }));
};

export const fetchCustomExpirationDates = (invoices, fundId) => dispatch => {
  const parsedInvoices = Array.from(
    Object.values(invoices)
      .flatMap(a => a)
      .map(({ id, issuedDate, expirationDate }) => {
        return { id, issuedDate, expirationDate };
      })
  );

  restFunds()
    .post('payroll/custom-expiration-date', {
      invoices: parsedInvoices,
      fundId,
    })
    .then(({ data }) => {
      dispatch({
        type: UPDATE_EXPIRATION_DATES,
        payload: data,
      });
    })
    .catch(error =>
      dispatch({ type: UPDATE_EXPIRATION_DATES_FAILURE, payload: error })
    );
};

const getAllInvoices = state => {
  const { fundInvoices } = state;
  const { customRate, invoicesInCart } = fundInvoices;
  return invoicesInCart
    .map(receiver => {
      return receiver.invoices.map(invoice => {
        return {
          invoiceId: invoice.id,
          invoiceAmount: invoice.amount,
          rate: customRate[receiver.receiverIdentifier] ?? invoice.rate,
          term: getTermInDays(invoice.expirationDate),
          score: invoice.score,
          advancePercentage: invoice.advancePercentage ?? 100,
          receiverIdentifier:
            invoice?.Receiver?.identifier ?? receiver.receiverIdentifier,
          issuerIdentifier: invoice?.Issuer?.identifier,
          acknowledgementOfReceipt:
            invoice?.InvoiceTaxProviders?.metadata?.acknowledgementOfReceipt,
          receiverDebtServiceAmount: receiver.receiverDebt,
        };
      });
    })
    .flat();
};

export const fetchSimulationData =
  (interestFormula, payrollInvoices) => (dispatch, getState) => {
    const invoices = payrollInvoices || getAllInvoices(getState());

    if (
      invoices.some(
        payrollInvoice => !payrollInvoice.rate || !payrollInvoice.invoiceId
      )
    ) {
      return;
    }

    dispatch({ type: FUNDS_SIMULATION_START });

    restFunds()
      .post('payroll/simulate', {
        interestFormula,
        payrollInvoices: invoices,
      })
      .then(({ data }) => {
        dispatch({ type: SET_SIMULATION_DATA, payload: data });
      })
      .catch(error => dispatch({ type: SIMULATION_ERROR, payload: error }));
  };

export const handleShowFundManageInvoices = isOpen => dispatch => {
  if (isOpen) {
    dispatch(fetchFundsList());
    dispatch({ type: UPDATE_FUNDS_INVOICES_CART });
    // NOTE: simulated only to update receivers summary (rates are not defined, so interestFormula is irrelevant)
    dispatch(fetchSimulationData('THIRTY_DAYS'));
  }

  dispatch({
    type: HANDLE_SHOW_FUND_MANAGE_INVOICES,
    payload: { isOpen },
  });
};

export const resetSendPayroll = () => dispatch => {
  dispatch({
    type: RESET_SEND_PAYROLL,
  });
};

export const toggleInvoiceDetail = isOpen => dispatch => {
  dispatch({
    type: TOGGLE_INVOICE_DETAIL,
    payload: { isOpen },
  });
};

export const toggleDebtorDetail = isOpen => dispatch => {
  dispatch({
    type: TOGGLE_DEBTOR_DETAIL,
    payload: { isOpen },
  });
};

export const checkAddedInvoicesData = (invoices, payers) => dispatch => {
  const identifiers = Object.keys(invoices);
  identifiers.forEach(identifier => {
    if (invoices[identifier].length) {
      const payerInvoices = invoices[identifier];
      const currentPayer = payers?.find(
        payer => payer.receiverIdentifier === identifier
      );
      let invoiceFilters = {};
      if (currentPayer) {
        const { payerIdentifier, name, ...invoiceFields } =
          currentPayer.fundPayersFormFields;
        invoiceFilters = invoiceFields;
      }
      if (payerInvoices.some(invoice => !invoice.folio)) {
        dispatch(
          fetchFundPayerDetailInfo({
            receiverIdentifier: identifier,
            ...invoiceFilters,
          })
        );
      }
    }
  });
};
