export const VIEW_TYPES = {
  INVOICE: 'INVOICE',
  RECEIVER: 'RECEIVER',
};

export const FIELDS_TO_HIDE_IN_INVOICES = {
  discount: true,
  assignee: true,
  orderIds: true,
};
