import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const createDocumentService = async ({
  documentableType,
  documentableId,
  document,
}) => {
  try {
    const { data } = await axios.post(
      `/api/${documentableType}/${documentableId}/document`,
      {
        ...document,
        description: document.description || undefined,
        file: document.file || undefined,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default createDocumentService;
