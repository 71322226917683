// eslint-disable-next-line import/prefer-default-export
export const MX = {
  isApiGlobal: true,
  decimalSeparator: '.',
  thousandSeparator: ',',
  localization: 'America/Mexico_City',
  currencySymbol: '$',
  currencyCode: 'MXN',
  currencyDecimalPoint: 2,
  dateformat: 'DD-MM-YYYY',
  phoneformat: '+52##########',
  rate: {
    minRate: 1,
    maxRate: 3,
  },
  retention: {
    minRetention: 5,
    maxRetention: 100,
  },
  pastInvoiceDaysLimit: 45,
  baseRate: 2.8,
  baseRateOrder: 2.8,
  retentionPct: 5,
  operationCost: 1890,
  minBaseRateCreateOperation: 0,
  maxBaseRate: 100,
};
