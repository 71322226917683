import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DEFAULT, COUNTRY_CODE_CL } from '../../helpers/Constants';

const useStyles = makeStyles({
  chipInReview: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#E29D02',
    background: '#FFEFC9',
    '&:hover, &:focus': {
      color: '#FFEFC9',
      background: '#E29D02',
    },
  },
  chipApproved: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#75B801',
    background: '#E6F8C5',
    '&:hover, &:focus': {
      color: '#E6F8C5',
      background: '#75B801',
    },
  },
  chipApprovedFund: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#F87348',
    background: '#F8DDD4',
    '&:hover, &:focus': {
      color: '#F8DDD4',
      background: '#F87348',
    },
  },
  chipPostVerified: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    fontWeight: 'bold',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    color: '#E29D02',
    background: 'rgba(226, 157, 2, 0.2)',
    '&:hover, &:focus': {
      color: '#F9EBCC',
      background: '#E29D02',
    },
  },
  chipRejected: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#D92F24',
    background: '#F8C7C4',
    '&:hover, &:focus': {
      color: '#F8C7C4',
      background: '#D92F24',
    },
  },
  chipTransferred: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#75B801',
    background: '#E6F8C5',
    '&:hover, &:focus': {
      color: '#E6F8C5',
      background: '#75B801',
    },
  },
  chipPaid: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#0093FF',
    background: '#D3F2FD',
    '&:hover, &:focus': {
      color: '#D3F2FD',
      background: '#0093FF',
    },
  },
  chipComplete: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#901AE7',
    background: '#E0D3FD',
    '&:hover, &:focus': {
      color: '#E0D3FD',
      background: '#901AE7',
    },
  },
  chipInactive: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#A8A4B3',
    background: '#E9E6F0',
    '&:hover, &:focus': {
      color: '#E9E6F0',
      background: '#A8A4B3',
    },
  },
  chipAccepted: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#119B41',
    background: 'rgba(17, 155, 65, 0.2)',
    '&:hover, &:focus': {
      color: '#B7ECBF',
      background: '#47984C',
    },
  },
  chipDefault: {
    width: ({ chipWidth }) =>
      chipWidth && Number.isInteger(chipWidth) ? chipWidth : '100%',
    height: ({ chipHeight }) =>
      chipHeight && Number.isInteger(chipHeight) ? chipHeight : 32,
    fontWeight: 'bold',
    color: '#0529F0',
    background: '#D3DDFD',
    '&:hover, &:focus': {
      color: '#D3DDFD',
      background: '#0529F0',
    },
  },
});

const StatusBadge = ({
  chipWidth,
  chipHeight,
  status,
  handleClick = null,
  ...props
}) => {
  const dataCy = props['data-cy'];
  const classes = useStyles({ chipWidth, chipHeight });
  const country = useSelector(state => state.config.country);

  switch (status) {
    case 'IN_REVIEW':
      return (
        <Chip
          label="En Revisión"
          className={classes.chipInReview}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'APPROVED':
      return (
        <Chip
          label="Aprobado"
          className={classes.chipApproved}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'REJECTED':
      return (
        <Chip
          label="Rechazado"
          className={classes.chipRejected}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'TRANSFERRED':
      return (
        <Chip
          label="Transferido"
          className={classes.chipTransferred}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'PAID':
      return (
        <Chip
          label="Pagado"
          className={classes.chipPaid}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'ACTIVE':
      return (
        <Chip
          label="Activo"
          className={classes.chipPaid}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'INACTIVE':
      return (
        <Chip
          label="Inactivo"
          className={classes.chipRejected}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'PAUSED':
      return (
        <Chip
          label="Pausado"
          className={classes.chipInReview}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'REVIEWED':
      return (
        <Chip
          label="Revisado"
          className={classes.chipInReview}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'APPEALED':
      return (
        <Chip
          label="Apelable"
          className={classes.chipInReview}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'APPROVED_FUND':
      return (
        <Chip
          label={
            country === COUNTRY_CODE_CL ? 'Aprobado Fondo' : 'Pendiente Firma'
          }
          className={
            country === COUNTRY_CODE_CL
              ? classes.chipApproved
              : classes.chipApprovedFund
          }
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'POST_VERIFIED':
      return (
        <Chip
          label="Post-Verificado"
          className={classes.chipPostVerified}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'PV_APPROVED':
      return (
        <Chip
          label="PV Aprobado"
          className={classes.chipPostVerified}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'VERIFIED':
      return (
        <Chip
          label="Verificado"
          className={classes.chipPostVerified}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'TO_DEPOSIT':
      return (
        <Chip
          label="Por Depositar"
          className={classes.chipPostVerified}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'COMPLETE':
      return (
        <Chip
          label="Completo"
          className={classes.chipComplete}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'CEDE':
      return (
        <Chip
          label="Cedido"
          className={classes.chipPostVerified}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'SOFT_DELINQUENCY':
      return (
        <Chip
          label="Mora Blanda"
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'HARD_DELINQUENCY':
      return (
        <Chip
          label="Default Interno"
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'DELINQUENT':
      return (
        <Chip
          label="Mora"
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'HARD_COLLECTION':
      return (
        <Chip
          label="Estado Critico"
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'PENDING':
      return (
        <Chip
          label="Pendiente"
          className={classes.chipInReview}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'RENEGOTIATION':
      return (
        <Chip
          label="Renegociado"
          className={classes.chipComplete}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'REPLACED':
      return (
        <Chip
          label="Reemplazada"
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'REQUESTED':
      return (
        <Chip
          label="Solicitado"
          className={classes.chipApproved}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'WALLET_INACTIVE':
      return (
        <Chip
          label="Inactivo"
          className={classes.chipInactive}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'WALLET_ACTIVE':
      return (
        <Chip
          label="Activo"
          className={classes.chipPaid}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'WALLET_ACCEPTED':
      return (
        <Chip
          label="Aceptado"
          className={classes.chipAccepted}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'DEFAULT':
      return (
        <Chip
          label={DEFAULT}
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );

    case 'IN_CONCILIATION':
      return (
        <Chip
          label="En conciliación"
          className={classes.chipDefault}
          onClick={handleClick}
          data-cy={dataCy}
        />
      );
    case 'PRIORITY_PAYMENT':
      return (
        <Chip
          label="Pago Prioritario"
          className={classes.chipApproved}
          onClick={handleClick}
        />
      );

    default:
      return <Chip label={status} onClick={handleClick} data-cy={dataCy} />;
  }
};

StatusBadge.defaultProps = {
  chipWidth: 0,
  chipHeight: 0,
  handleClick: () => null,
  'data-cy': null,
};

StatusBadge.propTypes = {
  chipWidth: PropTypes.number,
  chipHeight: PropTypes.number,
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  'data-cy': PropTypes.string,
};
export default StatusBadge;
