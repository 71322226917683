import { Box, Checkbox, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTransaction,
  fetchHtmlReport,
  fetchWallets,
  resetRequests,
  setWalletActiveFilters,
  updateRequestStatus,
  updateWalletStatus,
} from '../actions/walletActions';
import {
  INIT_PAGE,
  PAGE_SIZE_INIT,
  SEARCH_BAR_TIMEOUT,
} from '../helpers/Constants';
import CreateWalletTransactionDialog from './elements/CreateWalletTransactionDialog';
import LoaderComponent from './elements/LoaderComponent';
import Panel from './elements/Panel';
import RequestsHistoryDialog from './elements/RequestsHistoryDialog';
import SearchBar from './elements/SearchBar';
import StatusChip from './elements/StatusChip';
import WalletRequestStatusDialog from './elements/WalletRequestStatusDialog';
import WalletsTable from './elements/WalletsTable';
import WalletStatusDialog from './elements/WalletStatusDialog';
import { settings } from '../config/settings/index';

const useStyles = makeStyles({
  filtersBar: {
    paddingRight: 40,
    paddingTop: 10,
    paddingBottom: 20,
  },
  root: {
    padding: 20,
  },
});

const HomeWallets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    wallets,
    pagination,
    loadingWallets,
    sendingEmail,
    walletActiveFiltersSetted,
    walletStatusUpdated,
    requestStatusUpdated,
    transactionCreated,
    walletAvailableFilters,
    walletActiveFilters,
  } = useSelector(state => state.wallet);
  const { country } = useSelector(state => state.config);

  const [searchInput, setSearchInput] = useState('');
  const [searchBy, setSearchBy] = useState('AccountProfile.id');
  const [activeSearch, setActiveSearch] = useState(false);
  const [showCreateTransactionDialog, setShowCreateTransactionDialog] =
    useState(false);
  const [showWalletStatusDialog, setShowWalletStatusDialog] = useState(false);
  const [showRequestStatusDialog, setShowRequestStatusDialog] = useState(false);
  const [showRequestsHistoryDialog, setShowRequestsHistoryDialog] =
    useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedWalletRequest, setSelectedWalletRequest] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const { isApiGlobal } = settings[country];

  useEffect(() => {
    dispatch(fetchWallets());
  }, [dispatch]);

  useEffect(() => {
    setShowLoader(sendingEmail);
  }, [sendingEmail]);

  useEffect(() => {
    if (selectedWalletRequest) {
      setShowRequestStatusDialog(true);
    }
  }, [selectedWalletRequest]);

  const handleShowCreateTransactionDialog = walletData => {
    setSelectedWallet(walletData);
    setShowCreateTransactionDialog(true);
  };

  const handleCloseCreateTransactionDialog = () => {
    setSelectedWallet(null);
    setShowCreateTransactionDialog(false);
  };

  const handleSubmitTransaction = transactionData => {
    const { id: profileId } = selectedWallet.AccountProfile;
    const data = { ...transactionData };

    if (!isApiGlobal) {
      delete data.orderInvoiceId;
      data.orderFacturaId = transactionData.orderInvoiceId;
    }
    dispatch(createTransaction(transactionData, profileId));
  };

  const handleShowWalletStatusDialog = walletData => {
    setSelectedWallet(walletData);
    setShowWalletStatusDialog(true);
  };

  const handleCloseWalletStatusDialog = () => {
    setSelectedWallet(null);
    setShowWalletStatusDialog(false);
  };

  const handleSubmitWalletStatus = status => {
    const { id: profileId } = selectedWallet.AccountProfile;
    dispatch(updateWalletStatus(status, profileId));
  };

  const handleShowRequestStatusDialog = walletRequestId => {
    setSelectedWalletRequest(walletRequestId);
  };

  const handleCloseRequestStatusDialog = () => {
    setSelectedWalletRequest(null);
    setShowRequestStatusDialog(false);
  };

  const handleSubmitRequestStatus = updatedRequest => {
    dispatch(updateRequestStatus(updatedRequest, selectedWalletRequest.id));
    handleCloseRequestStatusDialog();
  };

  const handleAccountState = walletData => {
    const {
      AccountProfile: { identifier, id },
      status,
    } = walletData;

    dispatch(fetchHtmlReport(identifier, status, id));
  };

  const handleShowRequestsHistoryDialog = walletData => {
    setSelectedWallet(walletData);
    setShowRequestsHistoryDialog(true);
  };

  const handleCloseRequestsHistoryDialog = () => {
    setShowRequestsHistoryDialog(false);
    setSelectedWallet(null);
    dispatch(resetRequests());
  };

  const handleCloseLoader = () => {
    setShowLoader(false);
  };

  const getFilters = ({ searchInput = '', field }) => {
    const filter = [
      {
        field: 'status',
        value: walletActiveFilters,
      },
    ];
    if (field === 'AccountProfile.identifier') {
      filter.push({
        field: 'AccountProfile.identifier',
        value: searchInput,
      });
    } else if (field) {
      filter.push({
        field,
        value: searchInput,
      });
    }

    return filter;
  };

  const search = ({ page, limit, searchInput, field }) => {
    const filters = getFilters({ searchInput, field });
    if (!loadingWallets) {
      dispatch(
        fetchWallets({
          page,
          limit,
          filters,
        })
      );
    }
  };

  const handleSearchChange = ({
    value = '',
    statusChange = false,
    field,
  } = {}) => {
    const inputSearch = !statusChange ? value : searchInput;
    setSearchInput(inputSearch);
    setSearchBy(field);
    setActiveSearch(inputSearch.length >= 2);
    search({
      page: INIT_PAGE,
      searchInput: statusChange ? searchInput : value,
      field: statusChange ? searchBy : field,
      limit: PAGE_SIZE_INIT,
    });
  };

  useEffect(() => {
    if (walletStatusUpdated) {
      handleCloseWalletStatusDialog();
    }
  }, [walletStatusUpdated]);

  useEffect(() => {
    if (requestStatusUpdated) {
      handleCloseRequestStatusDialog();
    }
  }, [requestStatusUpdated]);

  useEffect(() => {
    if (transactionCreated) {
      handleCloseCreateTransactionDialog();
    }
  }, [transactionCreated]);

  useEffect(() => {
    if (walletActiveFiltersSetted) {
      handleSearchChange({ statusChange: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletActiveFiltersSetted]);

  const changeSearchInput = ({ value, field }) => {
    if (value?.length >= 2) {
      setTimeout(() => {
        handleSearchChange({ value, field });
      }, SEARCH_BAR_TIMEOUT);
    } else if (!value || (value?.length <= 2 && activeSearch)) {
      setTimeout(() => {
        handleSearchChange();
      }, SEARCH_BAR_TIMEOUT);
    }
  };

  const handleChangeMultiple = e => {
    const filters = e.target.value;
    if (filters?.length >= 1) {
      dispatch(setWalletActiveFilters(filters));
    }
  };

  const handleRemoveFilter = status => {
    const statusArray = [...walletActiveFilters];
    if (statusArray.length > 1) {
      statusArray.splice(statusArray.indexOf(status), 1);
      dispatch(setWalletActiveFilters(statusArray));
    }
  };

  const handleChangePage = (selectedPage, pageSize) => {
    search({
      page: selectedPage,
      limit: pageSize,
      searchInput,
      field: searchBy,
    });
  };

  const searchFields = [
    { title: 'Wallet ID', field: 'AccountProfile.id' },
    { title: 'Rut', field: 'AccountProfile.identifier' },
    { title: 'Razón social', field: 'AccountProfile.name' },
  ];

  return (
    <Grid item xs={12} className={classes.root}>
      {showCreateTransactionDialog && (
        <CreateWalletTransactionDialog
          open={showCreateTransactionDialog}
          onClose={handleCloseCreateTransactionDialog}
          onSubmit={handleSubmitTransaction}
        />
      )}
      {showWalletStatusDialog && (
        <WalletStatusDialog
          open={showWalletStatusDialog}
          onClose={handleCloseWalletStatusDialog}
          onSubmit={handleSubmitWalletStatus}
          currentStatus={selectedWallet?.status}
        />
      )}
      {showRequestStatusDialog && (
        <WalletRequestStatusDialog
          open={showRequestStatusDialog}
          onClose={handleCloseRequestStatusDialog}
          onSubmit={handleSubmitRequestStatus}
          selectedWalletRequest={selectedWalletRequest}
        />
      )}
      {showRequestsHistoryDialog && (
        <RequestsHistoryDialog
          open={showRequestsHistoryDialog}
          handleCloseDialog={handleCloseRequestsHistoryDialog}
          walletData={selectedWallet}
        />
      )}
      {showLoader && (
        <LoaderComponent
          open={showLoader}
          handleCloseDialog={handleCloseLoader}
        />
      )}
      <Panel
        title="Wallet"
        contentPadding="zero zero sm"
        actions={
          <Grid container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <SearchBar
                placeholder="Buscar wallet..."
                handleChangeInput={value => changeSearchInput(value)}
                fields={searchFields}
                selectedOption={searchFields[0]}
              />
            </Grid>

            <Grid item align="left">
              <Select
                variant="outlined"
                name="statuses"
                value={walletActiveFilters}
                onChange={e => handleChangeMultiple(e)}
                className={classes.filterSelect}
                renderValue={() => (
                  <Box color="text.secondary">
                    <span>Filtrar por:</span>
                  </Box>
                )}
                multiple
                fullWidth
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                {walletAvailableFilters?.map(status => (
                  <MenuItem
                    key={status}
                    value={status}
                    className={classes.filterMenuItem}
                  >
                    <Checkbox
                      key={status}
                      checked={
                        walletActiveFilters
                          ? walletActiveFilters.includes(status)
                          : false
                      }
                    />
                    <StatusChip
                      key={status}
                      status={status}
                      handleClick={handleRemoveFilter}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {walletActiveFilters?.length > 0 && (
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                className={classes.filtersBar}
                spacing={1}
              >
                {walletActiveFilters.map(status => (
                  <Grid item align="center">
                    <StatusChip
                      key={status}
                      status={status}
                      handleClick={handleRemoveFilter}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        }
      >
        <WalletsTable
          handleAccountState={handleAccountState}
          handleShowCreateTransactionDialog={handleShowCreateTransactionDialog}
          handleShowWalletStatusDialog={handleShowWalletStatusDialog}
          handleShowRequestStatusDialog={handleShowRequestStatusDialog}
          handleShowRequestsHistoryDialog={handleShowRequestsHistoryDialog}
          pagination={pagination}
          handleChangePage={handleChangePage}
          loading={loadingWallets}
          wallets={wallets}
        />
      </Panel>
    </Grid>
  );
};

export default HomeWallets;
