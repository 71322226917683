import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import BaseDialog from './BaseDialog';
import AlertForm from '../elements/AlertForm';
import { fetchDocumentBase64 } from '../../actions/documentActions';

const useStyles = makeStyles({
  actions: {
    marginBottom: 10,
  },
  bodyPDF: {
    marginBottom: 20,
    height: 650,
    overflowY: 'scroll',
  },
  spacingSkeleton: {
    marginBottom: 20,
  },
});

const VisualizePdfDialog = ({
  isOpen,
  handleCloseDialog,
  document,
  documentableType,
  actions,
  errorActions,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    documentBase64,
    fetchDocumentBase64IsLoading,
    fetchDocumentBase64Error,
  } = useSelector(state => state.documents);

  useEffect(() => {
    if (document) {
      dispatch(fetchDocumentBase64(documentableType, document));
    }
  }, [dispatch, document, documentableType]);

  return (
    <BaseDialog
      title={document.title}
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      size="lg"
    >
      <Box display="flex" flexDirection="column">
        {fetchDocumentBase64IsLoading ? (
          <>
            <Skeleton
              variant="rect"
              width={800}
              height={32}
              className={classes.actions}
            />
            <Box display="flex" justifyContent="center">
              <Skeleton
                variant="rect"
                width={800}
                height={650}
                className={classes.spacingSkeleton}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Skeleton variant="rect" width={252} height={26} />
            </Box>
          </>
        ) : (
          <>
            <Box>{actions}</Box>

            <Box
              display="flex"
              justifyContent="center"
              className={classes.bodyPDF}
            >
              <Document
                file={documentBase64}
                onLoadSuccess={({ numPages }) => {
                  setTotalPages(numPages);
                }}
                loading={<CircularProgress />}
              >
                <Page pageNumber={pageNumber} scale={1.28} />
              </Document>
            </Box>

            <Box display="flex" justifyContent="center">
              <Pagination
                count={totalPages}
                page={pageNumber}
                onChange={(e, page) => setPageNumber(page)}
                size="small"
                color="secondary"
              />
            </Box>
          </>
        )}
        {(errorActions || fetchDocumentBase64Error) && (
          <AlertForm message={errorActions || fetchDocumentBase64Error} />
        )}
      </Box>
    </BaseDialog>
  );
};

VisualizePdfDialog.defaultProps = {
  documentableType: 'business',
  actions: null,
  errorActions: null,
};

VisualizePdfDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    approved: PropTypes.bool.isRequired,
    action: PropTypes.string.isRequired,
    signedDocument: PropTypes.string,
    document: PropTypes.string,
  }).isRequired,
  documentableType: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  errorActions: PropTypes.string,
};

export default VisualizePdfDialog;
