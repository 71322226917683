import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { usePostAssignedKam } from '../../hooks/assignedKam';
import { useSelector } from 'react-redux';
import { BusinessSelector } from '../../schemas';
import { RootState } from '../../../../reducers/rootReducer';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const CreditLineAssignedKam = () => {
  const { business } = useSelector(
    (state: RootState) => state.business as BusinessSelector,
  );

  const { loading: isLoadingAssignedKam,
    isSuccess: isSuccessGetResponsibleKam,
    kamAssignedData,
  } = usePostAssignedKam({ bussinesRefId: business?.identifier });

  return (
    <Box p={5} display="flex" alignItems="center">
      <Box sx={{ mr: 2 }}>
        <PermIdentityIcon fontSize="large" />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="textSecondary" component="span">
          <Box fontWeight="bold" fontSize="12.5">
            KAM Responsable
          </Box>
        </Typography>
        <Box sx={{ mt: 1, p: 0.5 }}>
          <Typography variant="subtitle1" color="textPrimary" component="span">
            <Box fontWeight="bold">
              {isLoadingAssignedKam ? (
                <CircularProgress size={14} title="isLoadingResponsibleKAM" />
              ) : (
                <>
                  {isSuccessGetResponsibleKam ? (
                    <Box fontWeight="bold">
                      {kamAssignedData}
                    </Box>
                  ) : (
                    <Box fontWeight="bold">
                      {kamAssignedData}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditLineAssignedKam;
