import {
  FETCH_DISCOUNT_START,
  FETCH_DISCOUNT_SUCCESS,
  FETCH_DISCOUNT_FAILURE,
  CREATE_DISCOUNT_START,
  CREATE_DISCOUNT_SUCCESS,
  CREATE_DISCOUNT_FAILURE,
  UPDATE_DISCOUNT_START,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAILURE,
  DELETE_DISCOUNT_START,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_FAILURE,
  FETCH_REPLACEMENT_INVOICES_START,
  FETCH_REPLACEMENT_INVOICES_SUCCESS,
  FETCH_REPLACEMENT_INVOICES_FAILURE,
  RESET_DISCOUNTS,
} from '../actions/types';

const initialState = {
  sourceTypeDiscount: null,

  discount: null,
  fetchDiscountIsLoading: false,

  discountWasCreated: false,
  createDiscountIsLoading: false,
  createDiscountError: null,

  discountWasUpdated: false,
  updateDiscountIsLoading: false,
  updateDiscountError: null,

  discountWasDeleted: false,
  deleteDiscountIsLoading: false,
  deleteDiscountError: null,

  replacementInvoicesData: {
    selectorInvoicesId: null,
    operationId: null,
    replacementInvoices: [],
  },
  replacementInvoicesIsLoading: false,
  replacementInvoicesError: {},
};

const fetchDiscountStart = (state, payload) => ({
  ...state,
  discount: null,
  fetchDiscountIsLoading: true,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});
const fetchDiscountSuccess = (state, payload) => ({
  ...state,
  discount: payload.discount,
  fetchDiscountIsLoading: false,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});
const fetchDiscountFailure = (state, payload) => ({
  ...state,
  fetchDiscountIsLoading: false,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});

const createDiscountStart = (state, payload) => ({
  ...state,
  discountWasCreated: false,
  createDiscountIsLoading: true,
  createDiscountError: null,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});
const createDiscountSuccess = (state, payload) => ({
  ...state,
  discountWasCreated: true,
  createDiscountIsLoading: false,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});
const createDiscountFailure = (state, payload) => ({
  ...state,
  createDiscountIsLoading: false,
  createDiscountError: payload.errorCode,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});

const updateDiscountStart = (state, payload) => ({
  ...state,
  discountWasUpdated: false,
  updateDiscountIsLoading: true,
  updateDiscountError: null,
  sourceTypeDiscount: payload.sourceType,
});
const updateDiscountSuccess = (state, payload) => ({
  ...state,
  discountWasUpdated: true,
  updateDiscountIsLoading: false,
  sourceTypeDiscount: payload.sourceType,
});
const updateDiscountFailure = (state, payload) => ({
  ...state,
  updateDiscountIsLoading: false,
  updateDiscountError: payload.errorCode,
  sourceTypeDiscount: payload.sourceType,
});

const deleteDiscountStart = (state, payload) => ({
  ...state,
  discountWasDeleted: false,
  deleteDiscountIsLoading: true,
  deleteDiscountError: null,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});
const deleteDiscountSuccess = (state, payload) => ({
  ...state,
  discountWasDeleted: true,
  deleteDiscountIsLoading: false,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});
const deleteDiscountFailure = (state, payload) => ({
  ...state,
  deleteDiscountIsLoading: false,
  deleteDiscountError: payload.errorCode,
  sourceTypeDiscount: payload.sourceTypeDiscount,
});

const fetchReplacementInvoicesStart = (state, payload) => ({
  ...state,
  replacementInvoicesData: {
    selectorInvoicesId: payload?.selectorInvoicesId || null,
    operationId: payload?.operationId || null,
    replacementInvoices: [],
  },
  replacementInvoicesIsLoading: true,
  replacementInvoicesError: {
    ...state.replacementInvoicesError,
    [payload?.selectorInvoicesId]: null,
  },
});
const fetchReplacementInvoicesSuccess = (state, payload) => ({
  ...state,
  replacementInvoicesData: {
    selectorInvoicesId: payload?.selectorInvoicesId || null,
    operationId: payload?.operationId || null,
    replacementInvoices: payload?.replacementInvoices || [],
  },
  replacementInvoicesIsLoading: false,
});
const fetchReplacementInvoicesFailure = (state, payload) => ({
  ...state,
  replacementInvoicesData: {
    selectorInvoicesId: payload?.selectorInvoicesId || null,
    operationId: payload?.operationId || null,
    replacementInvoices: [],
  },
  replacementInvoicesIsLoading: false,
  replacementInvoicesError: {
    ...state.replacementInvoicesError,
    [payload?.selectorInvoicesId]: payload?.errorCode,
  },
});

const resetDiscount = state => ({
  ...state,
  sourceTypeDiscount: null,
  discount: null,
  discountWasCreated: false,
  createDiscountError: null,
  discountWasUpdated: false,
  updateDiscountError: null,
  discountWasDeleted: false,
  deleteDiscountError: null,
  replacementInvoicesData: {
    selectorInvoicesId: null,
    operationId: null,
    replacementInvoices: [],
  },
  replacementInvoicesError: {},
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DISCOUNT_START:
      return fetchDiscountStart(state, payload);
    case FETCH_DISCOUNT_SUCCESS:
      return fetchDiscountSuccess(state, payload);
    case FETCH_DISCOUNT_FAILURE:
      return fetchDiscountFailure(state, payload);
    case CREATE_DISCOUNT_START:
      return createDiscountStart(state, payload);
    case CREATE_DISCOUNT_SUCCESS:
      return createDiscountSuccess(state, payload);
    case CREATE_DISCOUNT_FAILURE:
      return createDiscountFailure(state, payload);
    case UPDATE_DISCOUNT_START:
      return updateDiscountStart(state, payload);
    case UPDATE_DISCOUNT_SUCCESS:
      return updateDiscountSuccess(state, payload);
    case UPDATE_DISCOUNT_FAILURE:
      return updateDiscountFailure(state, payload);
    case DELETE_DISCOUNT_START:
      return deleteDiscountStart(state, payload);
    case DELETE_DISCOUNT_SUCCESS:
      return deleteDiscountSuccess(state, payload);
    case DELETE_DISCOUNT_FAILURE:
      return deleteDiscountFailure(state, payload);
    case FETCH_REPLACEMENT_INVOICES_START:
      return fetchReplacementInvoicesStart(state, payload);
    case FETCH_REPLACEMENT_INVOICES_SUCCESS:
      return fetchReplacementInvoicesSuccess(state, payload);
    case FETCH_REPLACEMENT_INVOICES_FAILURE:
      return fetchReplacementInvoicesFailure(state, payload);
    case RESET_DISCOUNTS:
      return resetDiscount(state);
    default:
      return state;
  }
};
