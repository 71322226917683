import axios from 'axios';
import {
  FETCH_PAYERS_START,
  FETCH_PAYERS_SUCCESS,
  FETCH_PAYERS_FAILURE,
  FETCH_INFO_PAYERS_TO_CSV_START,
  FETCH_INFO_PAYERS_TO_CSV_SUCCESS,
  FETCH_INFO_PAYERS_TO_CSV_FAILURE,
  REGISTER_PAYER_START,
  REGISTER_PAYER_SUCCESS,
  REGISTER_PAYER_FAILURE,
  LOAD_PAYERS_CSV_START,
  LOAD_PAYERS_CSV_SUCCESS,
  LOAD_PAYERS_CSV_FAILURE,
  RESET_PAYER_ERROR,
  RESET_PAYER_FORM_DIALOG,
  RESET_BULK_CSV_PAYERS_DIALOG,
  RESET_PAYER_CSV_DATA,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { INIT_PAGE, PAGE_SIZE_INIT } from '../helpers/Constants';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchPayers =
  ({ page = INIT_PAGE, limit = PAGE_SIZE_INIT, searchInput, field } = {}) =>
  dispatch => {
    dispatch({ type: FETCH_PAYERS_START });
    axios
      .get('/api/payer', {
        params: {
          page,
          limit,
          searchInput,
          field,
        },
      })
      .then(res => {
        const resData = res.data;
        dispatch({ type: FETCH_PAYERS_SUCCESS, payload: resData });
        return resData;
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYERS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_PAYERS_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const fetchInfoPayersToCSV = () => dispatch => {
  dispatch({ type: FETCH_INFO_PAYERS_TO_CSV_START });
  axios
    .get('api/payer/csv')
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_INFO_PAYERS_TO_CSV_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_PAYERS_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_INFO_PAYERS_TO_CSV_FAILURE,
        payload: { errorCode },
      });
    });
};

export const registerPayer = payerData => dispatch => {
  dispatch({ type: REGISTER_PAYER_START });
  axios
    .post('/api/payer', { payerData })
    .then(res => {
      const resData = res.data;
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.PAYER_CREATED, {
          variant: 'success',
        })
      );
      dispatch({
        type: REGISTER_PAYER_SUCCESS,
        payload: resData,
      });
      return resData;
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: REGISTER_PAYER_FAILURE,
        payload: { errorCode },
      });
    });
};

//  MIGRATIONCL: this action sends an array from an already processed csv file. File processing should occur on the server side.
export const loadPayersCsvMIGRACIONCL = payersData => dispatch => {
  dispatch({ type: LOAD_PAYERS_CSV_START });
  axios
    .post('/api/payer/csv', { payersData })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: LOAD_PAYERS_CSV_SUCCESS,
        payload: resData,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.PAYERS_CSV_LOADED, {
          variant: 'success',
        })
      );
      return resData;
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch({
        type: LOAD_PAYERS_CSV_FAILURE,
        payload: { errorCode },
      });
    });
};

export const loadPayersCsv = payersFormData => dispatch => {
  dispatch({ type: LOAD_PAYERS_CSV_START });
  axios
    .post('/api/payer/csv', payersFormData)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: LOAD_PAYERS_CSV_SUCCESS,
        payload: resData,
      });
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.PAYERS_CSV_LOADED, {
          variant: 'success',
        })
      );
    })
    .catch(err => {
      //  We have to handle two type of error responses. One when the request fails with all invalid payers
      //  and the other when the csv parsing fails or the server itself also fails
      const errorCode = getErrorCode(err);
      const invalidPayers = err?.response?.data?.invalidPayers;
      dispatch({
        type: LOAD_PAYERS_CSV_FAILURE,
        payload: { errorCode, invalidPayers },
      });
    });
};

export const resetPayerError = () => dispatch => {
  dispatch({
    type: RESET_PAYER_ERROR,
  });
};

export const resetPayerFormDialog = () => dispatch => {
  dispatch({
    type: RESET_PAYER_FORM_DIALOG,
  });
};

export const resetBulkCsvPayersDialog = () => dispatch => {
  dispatch({ type: RESET_BULK_CSV_PAYERS_DIALOG });
};

export const resetPayerCsvData = () => dispatch => {
  dispatch({ type: RESET_PAYER_CSV_DATA });
};
