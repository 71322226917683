import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useAppendSelectedPayroll,
  useRemoveSelectedPayroll,
  useSelectedPayrolls,
} from '../infrastructure/store/PaymentInboxStore';

const usePayrollCard = () => {
  const selectedPayrolls = useSelectedPayrolls();
  const appendSelectedPayroll = useAppendSelectedPayroll();
  const removeSelectedPayroll = useRemoveSelectedPayroll();
  const [expanded, setExpanded] = useState(false);
  const expandAttributes = () => setExpanded(prev => !prev);
  const { country } = useSelector(state => state.config);

  const handleSelectedPayroll = ({ payrollId, evt }) => {
    const {
      target: { checked },
    } = evt;
    if (checked) {
      appendSelectedPayroll([payrollId]);
    } else {
      removeSelectedPayroll([payrollId]);
    }
  };

  const isPayrollSelected = payrollId => {
    return selectedPayrolls.includes(payrollId);
  };

  return {
    expanded,
    expandAttributes,
    country,
    handleSelectedPayroll,
    isPayrollSelected,
  };
};

export default usePayrollCard;
