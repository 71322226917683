import axios from 'axios';

export const updateOrderStatusService = async ({
  orderId,
  status,
  statusReason,
}) => {
  try {
    //  STRUCTURE AND URL ARE TEMPORARY UNTIL REFACTOR
    const orderData = { orderId, statusOrder: status, statusReason };
    const { data } = await axios.post(
      `/api/orders/${orderId}?operation=CONFIRMING`,
      orderData,
      status
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default updateOrderStatusService;
