import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Link } from '@material-ui/core';
import { Schedule as ScheduleIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { timeSinceCalculated } from '../../helpers/DateUtils';
import { DATASTUDIO_URL, reportingByCountry } from '../../helpers/Constants';
import SignalTooltip from './SignalTooltip';

const useStyles = makeStyles(theme => ({
  green: {
    marginRight: 5,
    background: `${theme.palette.success.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  yellow: {
    marginRight: 5,
    background: `${theme.palette.warning.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  red: {
    marginRight: 5,
    background: `${theme.palette.error.main} 0% 0% no-repeat padding-box`,
    opacity: 1,
  },
  loaderRisk: {
    marginTop: 2,
    marginLeft: 10,
  },
}));

const InvoiceSignalRisk = ({
  level,
  latestLevelUpdate,
  invoiceId,
  hasInvoiceHistory,
}) => {
  const classes = useStyles();
  const country = useSelector(state => state.config.country);

  let className = null;
  let componentRisk = '';

  switch (level) {
    case 'HIGH':
      className = classes.red;
      break;
    case 'MEDIUM':
      className = classes.yellow;
      break;
    case 'LOW':
      className = classes.green;
      break;
    default:
      break;
  }
  const dataStudioURLParams = `{"${reportingByCountry.risk[country].field_key}":"${invoiceId}"}`;
  const dataStudioURL = `${DATASTUDIO_URL}/reporting/${reportingByCountry.risk[country].reporting}/page/h0DxC?params=${dataStudioURLParams}`;

  const waitingForInfo = (
    <SignalTooltip title="Esperando mayor información para mostrar un resultado.">
      <ScheduleIcon style={{ color: '#8D8A96' }} />
    </SignalTooltip>
  );

  if (level && latestLevelUpdate) {
    componentRisk = (
      <SignalTooltip
        title={timeSinceCalculated(new Date(latestLevelUpdate))}
        placement="bottom"
      >
        <Link href={dataStudioURL} target="_blank" rel="noreferrer">
          <Badge
            classes={{ badge: className }}
            badgeContent=""
            color="default"
          />
        </Link>
      </SignalTooltip>
    );
  } else if (!hasInvoiceHistory || !level) {
    componentRisk = waitingForInfo;
  } else {
    componentRisk = waitingForInfo;
  }

  return componentRisk;
};

InvoiceSignalRisk.propTypes = {
  level: PropTypes.string.isRequired,
  latestLevelUpdate: PropTypes.string.isRequired,
  invoiceId: PropTypes.number.isRequired,
  hasInvoiceHistory: PropTypes.bool.isRequired,
};

export default InvoiceSignalRisk;
