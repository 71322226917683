import { Box, Link, styled, Typography } from '@material-ui/core';
import { palette } from '../../../../theme/palette';

export const ContainerRoot = styled(Box)({
  background: palette.payments.softBlue,
  alignItems: 'center',
  padding: '12px 24px',
  borderBottom: '1px solid #CDCAD8',
  display: 'flex',
  justifyContent: 'space-between',
});

export const SelectionDetails = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const SelectedText = styled(Typography)({
  color: palette.primary.main,
  fontSize: '16.25px',
  marginRight: 10,
});

export const UndoSelection = styled(Link)({
  color: palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: '16.25px',
});
