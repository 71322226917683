import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';
import { settings } from '../../config/settings';
import { AVAILABLE_COUNTRIES } from '../../helpers/Constants';

const CURRENCY = 'currency';
const NUMERIC = 'numeric';
const PERCENTAGE = 'percentage';
const DATE = 'longdate';

const CountryFormatHelper = props => {
  const { countryId, value, variant, display = 'text', currency } = props;
  const {
    thousandSeparator,
    decimalSeparator,
    currencyDecimalPoint,
    currencySymbol,
    dateformat,
  } = settings[countryId];

  const getFormatedValue = () => {
    switch (variant) {
      case CURRENCY: {
        // IF CURRENCY IS INTEGER => SHOW 0 DECIMAL, ELSE 2 DECIMAL
        const decimalScale =
          Number.parseFloat(value) && value % 1 === 0
            ? 0
            : currency === 'USD'
            ? 2
            : currencyDecimalPoint;

        let finalValue = Number.parseFloat(value)
          ? parseFloat(value).toFixed(decimalScale)
          : value;

        if (finalValue === '-0') {
          finalValue = '0';
        }

        return (
          <NumberFormat
            value={finalValue ?? '-'}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            prefix={currencySymbol}
            displayType={display}
            isNumericString
          />
        );
      }
      case NUMERIC:
        return (
          <NumberFormat
            value={value ?? '-'}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            displayType={display}
            isNumericString
          />
        );
      case PERCENTAGE:
        return (
          <NumberFormat
            value={value ?? '-'}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            suffix="%"
            displayType={display}
            isNumericString
          />
        );
      case DATE:
        return moment(value).utc().format(dateformat);
      default:
        return null;
    }
  };
  return getFormatedValue();
};

CountryFormatHelper.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOf([CURRENCY, PERCENTAGE, NUMERIC, DATE]),
  currency: PropTypes.string,
};

export default CountryFormatHelper;
