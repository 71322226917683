import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MenuExpand = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M21 18.0001V20.0001H3V18.0001H21ZM6.596 3.90405L8.01 5.31805L4.828 8.50005L8.01 11.6821L6.596 13.0961L2 8.50005L6.596 3.90405ZM21 11.0001V13.0001H12V11.0001H21ZM21 4.00005V6.00005H12V4.00005H21Z" />
    </SvgIcon>
  );
};

export default MenuExpand;
