import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import {
  useAppendSelectedOperation,
  useRemoveSelectedOperation,
  useGetSelectedOperations,
} from '../infrastructure/store/InboxStore';
import { features } from '../../../config/features';
import { OperationList } from '../../commons/interfaces/ARInterfaces';
import { enqueueSnackbar } from '../../../actions/notificationAction';
import { t13s } from '../../../translationKeys';
import {
  STATUS_APPEALED,
  STATUS_IN_REVIEW,
  STATUS_TO_DEPOSIT,
  STATUS_ACTIVE,
  STATUS_DEFAULT,
  STATUS_IN_CONCILIATION,
  STATUS_PAID,
  STATUS_COMPLETE,
  STATUS_REJECTED,
  STATUS_PV_APPROVED,
  STATUS_RENEGOTIATION,
  STATUS_REVIEWED,
  STATUS_APPROVED,
  STATUS_APPROVED_FUND,
} from '../../../helpers/Constants';
import useGetCountry from '../../../hooks/useGetCountry';
import { RequirementData } from '../../commons/components/RequirementsTab/interfaces';
import useRequirements from '../../commons/components/RequirementsTab/hooks/useRequirements';
import CalendarIcon from '../../commons/icons/CalendarIcon';
import DocumentIcon from '../../commons/icons/DocumentIcon';
import PdfIcon from '../../commons/icons/PdfIcon';
import TextIcon from '../../commons/icons/TextIcon';
import DoneIcon from '../../commons/icons/DoneIcon';

const useOperationCard = () => {
  const dispatch = useDispatch();
  const country = useGetCountry();
  const [isExpanded, setIsExpanded] = useState(true);
  const [showHistoryLogDialog, setShowHistoryLogDialog] = useState(false);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [showResponsiblesDrawer, setShowResponsiblesDrawer] = useState(false);
  const [showRequirementsDrawer, setShowRequirementsDrawer] = useState(false);
  const [
    showInvoicePendingRequirementsDrawer,
    setShowInvoicePendingRequirementsDrawer,
  ] = useState(false);
  const [invoiceRequirementSelected, setInvoiceRequirementSelected] =
    useState<RequirementData | null>(null);

  const appendSelectedOperation = useAppendSelectedOperation();
  const removeSelectedOperation = useRemoveSelectedOperation();
  const selectedOperations = useGetSelectedOperations();
  const { dictionary, handleGetProgress, requirementStatus } =
    useRequirements();
  const countryFeatures = features[country];

  const handleToggleExpanded = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, [setIsExpanded]);

  const handleSelectedOperation = useCallback(
    ({ operationId, checked }: { operationId: number; checked: boolean }) => {
      if (checked) {
        appendSelectedOperation([operationId]);
      } else {
        removeSelectedOperation([operationId]);
      }
    },
    [appendSelectedOperation, removeSelectedOperation]
  );

  const isSelectedOperation = useCallback(
    (selectedOperationId: number) =>
      selectedOperations.includes(selectedOperationId),
    [selectedOperations]
  );

  const handleOpenHistoryLogDialog = useCallback(() => {
    setShowHistoryLogDialog(true);
  }, [setShowHistoryLogDialog]);

  const handleCloseHistoryLogDialog = useCallback(() => {
    setShowHistoryLogDialog(false);
  }, [setShowHistoryLogDialog]);

  const handleOpenCommentsDrawer = useCallback(() => {
    setShowCommentsDrawer(true);
  }, [setShowCommentsDrawer]);

  const handleCloseCommentsDrawer = useCallback(() => {
    setShowCommentsDrawer(false);
  }, [setShowCommentsDrawer]);

  const handleOpenResponsiblesDrawer = useCallback(() => {
    setShowResponsiblesDrawer(true);
  }, [setShowResponsiblesDrawer]);

  const handleCloseResponsiblesDrawer = useCallback(() => {
    setShowResponsiblesDrawer(false);
  }, [setShowResponsiblesDrawer]);

  const handleOpenRequirementsDrawer = useCallback(() => {
    setShowRequirementsDrawer(true);
  }, [setShowRequirementsDrawer]);

  const handleCloseRequirementsDrawer = useCallback(() => {
    setShowRequirementsDrawer(false);
  }, [setShowRequirementsDrawer]);

  const handleOpenInvoicePendingRequirementsDrawer = useCallback(
    (requirement: RequirementData) => {
      setShowInvoicePendingRequirementsDrawer(true);
      setInvoiceRequirementSelected(requirement);
    },
    [setShowInvoicePendingRequirementsDrawer, setInvoiceRequirementSelected]
  );

  const handleCloseInvoicePendingRequirementsDrawer = useCallback(() => {
    setShowInvoicePendingRequirementsDrawer(false);
    setInvoiceRequirementSelected(null);
  }, [setShowInvoicePendingRequirementsDrawer, setInvoiceRequirementSelected]);

  const copyToClipboard = useCallback(
    (value: string = '') => {
      copy(value);
      if (value) {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
            variant: 'success',
          })
        );
      } else {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
            variant: 'error',
          })
        );
      }
    },
    [dispatch]
  );

  const getShowAttributes = useCallback(
    (key: string, operation: OperationList): boolean => {
      switch (operation.status) {
        case STATUS_IN_REVIEW:
        case STATUS_REVIEWED:
        case STATUS_APPEALED:
        case STATUS_APPROVED:
        case STATUS_APPROVED_FUND:
          return [
            'invoiceRequirement',
            'legalDocumentRequirement',
            'issuedDate',
            'clientType',
          ].includes(key);
        case STATUS_TO_DEPOSIT:
        case STATUS_ACTIVE:
        case STATUS_DEFAULT:
        case STATUS_IN_CONCILIATION:
        case STATUS_PAID:
        case STATUS_COMPLETE:
        case STATUS_REJECTED:
        case STATUS_PV_APPROVED:
        case STATUS_RENEGOTIATION:
          return ['issuedDate', 'baseRate', 'interest'].includes(key);
        default:
          return false;
      }
    },
    []
  );

  const getShowRequirementProgressBar = useCallback(
    (operation: OperationList): boolean => {
      switch (operation.status) {
        case STATUS_IN_REVIEW:
        case STATUS_REVIEWED:
        case STATUS_APPEALED:
        case STATUS_APPROVED:
        case STATUS_APPROVED_FUND:
          return true;
        default:
          return false;
      }
    },
    []
  );

  const handleGetInvoiceRequirementIcon = useCallback(
    ({ keyValue, status }: { keyValue: string; status: string }) => {
      const fontSize = { fontSize: 20 };
      const isSuccess = status === requirementStatus.SUCCESS;
      const requirementIcon: { [key: string]: JSX.Element | null } =
        {
          [dictionary.invoiceCeded]: (
            <TextIcon
              style={fontSize}
              text="C"
              color={isSuccess ? '#75B801' : '#605C6B'}
            />
          ),
          [dictionary.invoicePdf]: (
            <PdfIcon color={isSuccess ? '#75B801' : '#605C6B'} />
          ),
          [dictionary.invoiceVerified]: (
            <TextIcon
              style={fontSize}
              text="V"
              color={isSuccess ? '#75B801' : '#605C6B'}
            />
          ),
          [dictionary.invoiceExecutiveMerit]: isSuccess ? (
            <DoneIcon color="#75B801" />
          ) : null,
          [dictionary.invoicePaymentDateConfirmed]: (
            <CalendarIcon color={isSuccess ? '#75B801' : '#605C6B'} />
          ),
        } || null;
      return requirementIcon[keyValue];
    },
    [
      requirementStatus.SUCCESS,
      dictionary.invoiceCeded,
      dictionary.invoicePdf,
      dictionary.invoiceVerified,
      dictionary.invoiceExecutiveMerit,
      dictionary.invoicePaymentDateConfirmed,
    ]
  );

  const handleGetLegelDocumentRequerimientIcon = useCallback(
    (legalDocumentRequirements: RequirementData[]) => {
      const count = legalDocumentRequirements?.length;
      const legalDocumentSuccess = legalDocumentRequirements.filter(
        ({ status }) => status === requirementStatus.SUCCESS
      )?.length;

      const legalDocumentuUpload = legalDocumentRequirements.filter(
        ({ status }) => status === requirementStatus.UPLOAD
      )?.length;
      const successProgress = handleGetProgress(legalDocumentSuccess, count);
      const uploadProgress = handleGetProgress(legalDocumentuUpload, count);
      if (successProgress === 100) return <DocumentIcon color="#75B801" />;
      if (uploadProgress === 100) return <DocumentIcon color="#FFB100" />;
      return <DocumentIcon color="#605C6B" />;
    },
    [requirementStatus.SUCCESS, requirementStatus.UPLOAD, handleGetProgress]
  );

  return {
    isExpanded,
    showHistoryLogDialog,
    showCommentsDrawer,
    showResponsiblesDrawer,
    countryFeatures,
    showRequirementsDrawer,
    showInvoicePendingRequirementsDrawer,
    invoiceRequirementSelected,
    handleToggleExpanded,
    handleSelectedOperation,
    isSelectedOperation,
    handleOpenHistoryLogDialog,
    handleCloseHistoryLogDialog,
    handleOpenCommentsDrawer,
    handleCloseCommentsDrawer,
    handleOpenResponsiblesDrawer,
    handleCloseResponsiblesDrawer,
    handleOpenRequirementsDrawer,
    handleCloseRequirementsDrawer,
    handleOpenInvoicePendingRequirementsDrawer,
    handleCloseInvoicePendingRequirementsDrawer,
    copyToClipboard,
    getShowAttributes,
    getShowRequirementProgressBar,
    handleGetInvoiceRequirementIcon,
    handleGetLegelDocumentRequerimientIcon,
  };
};

export default useOperationCard;
