import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box, Card, Divider } from '@material-ui/core';

const SkeletonDataHistory = () => (
  <Card style={{
    borderRadius: 16,
    boxShadow: 'unset',
    marginLeft: 20,
    height: 585,
  }}>
    <Box m={3} paddingTop={3} display="flex" justifyContent="space-between">
      <Skeleton variant="text" width={205} height={30}/>
      <Skeleton variant="text" width={170} height={30}/>
    </Box>
    <Box m={3} paddingTop={5} display="flex">
      <Skeleton variant="text" width={110} height={20}/>
      <Skeleton variant="text" width={20} height={20} style={{marginLeft: 10}}/>
    </Box>
    <Divider style={{ margin: 4 }}/>
    <Box m={3} paddingTop={0} display="flex">
      <Skeleton variant="text" width={20} height={30}/>
      <Skeleton variant="text" width={185} height={30} style={{marginLeft: 10}}/>
    </Box>
    <Box m={3} paddingTop={4} display="flex">
      <Skeleton variant="text" width={115} height={24}/>
      <Skeleton variant="text" width={20} height={24} style={{marginLeft: 10}}/>
      <Skeleton variant="text" width={80} height={24} style={{marginLeft: 230}}/>
    </Box>
    <Divider style={{ margin: 4 }}/>
    <Box m={3} display="flex">
      <Skeleton variant="text" width={115} height={24}/>
      <Skeleton variant="text" width={20} height={24} style={{marginLeft: 10}}/>
      <Skeleton variant="text" width={80} height={24} style={{marginLeft: 230}}/>
    </Box>
  </Card>
);

export default SkeletonDataHistory;
