import React from 'react';
import { useParams } from 'react-router-dom';
import { ContainerRoot } from '../HomeCollection/styles';
import CollectionsByPayerHeader from '../../payers/ui/CollectionByPayerHeader/CollectionByPayerHeader';
import CollectionByPayerTable from '../../payers/ui/CollectionsByPayerTable/CollectionsByPayerTable';
import { useFetchPayerById } from '../../payers/infrastructure/store/useFetchPayerById';
import PayerNotFound from '../../commons/components/PayerNotFound';
import useQueryParams from '../../payers/hooks/useQueryParams';

const CollectionsByPayer = () => {
  const { id } = useParams();
  const query = useQueryParams();
  const { data, isLoading, isError } = useFetchPayerById(
    id,
    query.get('sortBy')
  );
  if (isError) {
    return <PayerNotFound />;
  }
  return (
    <ContainerRoot
      style={{
        display: 'grid',
      }}
    >
      <CollectionsByPayerHeader data={data} isLoading={isLoading} />
      <CollectionByPayerTable data={data} isLoading={isLoading} />
    </ContainerRoot>
  );
};

export default CollectionsByPayer;
