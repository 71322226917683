import { gql } from 'graphql-request';
import { TransitionType } from '../../schemas';

export const query = gql`
  query workflows($dto1: GetWorflowStatesDTO!, $dto2: GetStateDTO!) {
    workflowsWithState: workflowsWithState(dto: $dto1) {
      id
      comment
      createdAt
      workflow {
        id
        name
      }
      target {
        id
        name
      }
      transitionType
    }
    state: state(dto: $dto2) {
      id
      name
      workflowId
    }
  }
`;

export type State = {
  id: string;
  name: string;
  workflowId: string;
};

export type WorkFlowState = {
  id: string | null;
  comment: string | null;
  createdAt: string;
  transitionType?: TransitionType;
  workflow: {
    id: string;
    name: string;
  };
  target: {
    id: string;
    name: string;
  };
};

export type WorkflowsWithStateQuery = {
  workflowsWithState: WorkFlowState[];
  state: State | null;
};
