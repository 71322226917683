/* eslint-disable no-param-reassign */
import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { devtools } from 'zustand/middleware';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const customDevtools = (store, config) => {
  const customConfig = {
    ...config,
    stateSanitizer: state => ({
      ...state,
      anchorEl: null,
    }),
  };
  return devtools(store, customConfig);
};

const createStoreInvoice = pipe(immer, customDevtools, create);

const InvoiceStore = createStoreInvoice(set => ({
  requestDebtSuperData: {},
  requestDebtServiceData: {},
  showFundDialog: false,
  issuerBusinessId: null,
  isRegisterBankAccount: false,
  selectedBankAccount: null,
  showRegisterAccountDialog: false,
  showDebtSuperDialog: false,
  showDebtServiceDialog: false,
  showBillDatesDialog: false,
  showInvoiceStatusDialog: false,
  selectedInvoice: undefined,
  selectedInvoices: [],
  discountDialogData: null,
  showDiscountFormDialog: false,
  anchorEl: null,
  showPartialPaymentDrawer: false,
  showEditInvoiceDialog: false,
  showUploadDocumentDialog: false,
  showFacturasBulkUpdateDialog: false,
  orderIdsToReplace: [],
  enableManageOrderInvoice: false,
  showActionBar: false,
  useMoveDrawer: false,
  useAddDrawer: false,
  showDeleteInvoice: false,
  invoiceTableReloadRef: {},
  invoicesParams: { page: 1, size: 10 },
  setInvoicesParams: param =>
    set(state => {
      state.invoicesParams = param;
    }),
  setRequestDebtSuperData: param =>
    set(state => {
      state.requestDebtSuperData = param;
    }),
  setRequestDebtServiceData: param =>
    set(state => {
      state.requestDebtServiceData = param;
    }),
  setShowFundDialog: param =>
    set(state => {
      state.showFundDialog = param;
    }),
  setIssuerBusinessId: param =>
    set(state => {
      state.issuerBusinessId = param;
    }),
  setSelectedBankAccount: param =>
    set(state => {
      state.selectedBankAccount = param;
    }),
  setShowRegisterAccountDialog: param =>
    set(state => {
      state.showRegisterAccountDialog = param;
    }),
  setShowDebtSuperDialog: param =>
    set(state => {
      state.showDebtSuperDialog = param;
    }),
  setShowDebtServiceDialog: param =>
    set(state => {
      state.showDebtServiceDialog = param;
    }),
  setShowBillDatesDialog: param =>
    set(state => {
      state.showBillDatesDialog = param;
    }),
  setShowInvoiceStatusDialog: param =>
    set(state => {
      state.showInvoiceStatusDialog = param;
    }),
  setSelectedInvoice: param =>
    set(state => {
      state.selectedInvoice = param;
    }),
  setSelectedInvoices: param =>
    set(state => {
      state.selectedInvoices = param;
    }),
  setDiscountDialogData: param =>
    set(state => {
      state.discountDialogData = param;
    }),
  setShowDiscountFormDialog: param =>
    set(state => {
      state.showDiscountFormDialog = param;
    }),
  setAnchorEl: param =>
    set(state => {
      state.anchorEl = param;
    }),
  setShowPartialPaymentDrawer: param =>
    set(state => {
      state.showPartialPaymentDrawer = param;
    }),
  setShowEditInvoiceDialog: param =>
    set(state => {
      state.showEditInvoiceDialog = param;
    }),
  setShowUploadDocumentDialog: param =>
    set(state => {
      state.showUploadDocumentDialog = param;
    }),
  setShowFacturasBulkUpdateDialog: param =>
    set(state => {
      state.showFacturasBulkUpdateDialog = param;
    }),
  setOrderIdsToReplace: param =>
    set(state => {
      state.orderIdsToReplace = param;
    }),
  setEnableManageOrderInvoice: param =>
    set(state => {
      state.enableManageOrderInvoice = param;
    }),
  setShowActionBar: param =>
    set(state => {
      state.showActionBar = param;
    }),
  setUseMoveDrawer: param =>
    set(state => {
      state.useMoveDrawer = param;
    }),
  setUseAddDrawer: param =>
    set(state => {
      state.useAddDrawer = param;
    }),
  setShowDeleteInvoice: param =>
    set(state => {
      state.showDeleteInvoice = param;
    }),
  setInvoiceTableReloadRef: param =>
    set(state => {
      state.invoiceTableReloadRef = param;
    }),
}));

export default InvoiceStore;
