export const resetPayrollSelectorToInitialStateUseCase = ({
  currentSelectorState,
}) => {
  return Object.keys(currentSelectorState).reduce((acc, receiverIdentifier) => {
    const { amount, name, value } = currentSelectorState[receiverIdentifier];
    acc[receiverIdentifier] = {
      amount,
      name,
      value: value.map(
        ({ advancePercentage, rate, issuer, expirationDate, ...doc }) => doc
      ),
    };

    return acc;
  }, {});
};
