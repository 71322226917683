import React, { ReactNode, FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { palette } from '../../../theme/palette';

interface Props {
  color?: string;
  style?: {
    [x: string]: ReactNode;
  };
}

const BackIcon: FC<Props> = ({ color = palette.primary.main, style }) => {
  return (
    <SvgIcon
      style={{
        color,
        ...style,
      }}
      viewBox="0 0 24 24"
    >
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </SvgIcon>
  );
};

export default BackIcon;
