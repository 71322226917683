import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Box,
  Tooltip,
  Badge,
  makeStyles,
} from '@material-ui/core';

import { MoveTo } from '../icons';
import emptyBrowserIcon from '../../assets/icons/emptyBrowserIcon.svg';

import InvoiceOperationResume from './InvoiceOperationResume';
import SearchBar from './SearchBar';
import Panel from './Panel';
import SectionHeader from './SectionHeader';
import OrdersTableMinified from '../tables/OrdersTableMinified';
import {
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  COUNTRY_CODE_CL,
  ORDER_TYPES_LABEL,
} from '../../helpers/Constants';

import { DRAWER_SECONDARY, ALT_BORDER_COLOR } from '../../theme/otherColors';

import { FETCH_ORDERS_START_PERSIST_ORDER } from '../../actions/types';

import { fetchOrders, fetchOperationSummary } from '../../actions/orderActions';
import { t13s } from '../../translationKeys';
import useStatuses from '../../hooks/useStatuses';

const useStyles = makeStyles(() => ({
  emptyResumeContainer: {
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    borderRadius: '15px',
    padding: '20px',
    border: `0.5px solid ${ALT_BORDER_COLOR}`,
    textAlign: 'center',
  },
  emptyResumeIcon: {
    margin: '35px auto 26px auto',
  },
  bottomContainer: { columnGap: '10px' },
  labelInfo: {
    fontSize: 14,
    marginRight: 5,
    textTransform: 'none',
  },
  info: {
    marginRight: 30,
    fontSize: 14,
  },
  rootInfo: {
    display: 'flex',
  },
}));

const MoveInvoiceDrawer = ({
  show,
  country,
  selectedInvoices,
  manageSelectedInvoices,
  order,
  manageOrderInvoicesIsLoading,
}) => {
  const { getStatuses } = useStatuses();
  const {
    orders,
    loadingOrders,
    orderSummary,
    orderSummaryWasLoaded,
    orderPagination,
  } = useSelector(state => state.order);

  const orderStatus = [
    ...getStatuses('order')
      .filter(
        status =>
          status.group === STATUS_IN_REVIEW || status.group === STATUS_APPROVED
      )
      .map(status => status.key),
  ];

  const { id: orderId, type, orderType = type } = order;
  const baseRate = orderSummary?.baseRate;

  const { business } = useSelector(state => state.business);
  const {
    id: businessId,
    name: businessName,
    identifier,
    rut: businessIdentifier = identifier,
    totalOrdersTransferred,
  } = business;

  const [search, setSearch] = useState(null);
  const [field, setField] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [invoicesData, setInvoicesData] = useState({});

  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChangePage = (page, limit) => {
    setLimit(limit);
    setPage(page);
  };

  const changeSearchInput = ({ value, field }) => {
    setField(field);
    setSearch(value);
    setPage(1);
  };

  const handleChangeRowSelection = row => {
    if (row?.id) dispatch(fetchOperationSummary(row.id));
    setSelectedOrder(row);
  };

  const translateTypeOrder = type => {
    return ORDER_TYPES_LABEL[type];
  };

  const getOrderFilters = () => {
    const filter = [
      {
        field: 'status',
        value: orderStatus,
      },
      {
        field: 'businessId',
        value: businessId,
        cmp: '=',
      },
      {
        field: 'Order.id',
        value: `(${orderId})`,
        cmp: 'NOT IN',
      },
    ];
    const filteredIdentifier = new URLSearchParams(location.search).get(
      'filteredIdentifier'
    );
    if (filteredIdentifier) {
      filter.push({
        field: country === COUNTRY_CODE_CL ? 'rut' : 'identifier',
        value: filteredIdentifier,
      });
    }
    if (search) {
      filter.push({
        field,
        value: search,
      });
    }

    return filter;
  };

  const searcher = () => {
    dispatch(
      fetchOrders({
        operation: orderType,
        page,
        limit,
        filters: getOrderFilters(),
        type: FETCH_ORDERS_START_PERSIST_ORDER,
      })
    );
  };

  const initialiceDefault = () => {
    setPage(1);
    searcher();
    let invoicesAmount = 0;
    const invoices = [];
    const invoicesSize = selectedInvoices.length;

    if (invoicesSize) {
      invoicesAmount = selectedInvoices.reduce(
        (a, b) => a + (parseFloat(b.amount) || 0),
        0
      );
      selectedInvoices.forEach(
        ({ id, baseRate: invoiceBaseRate, expirationDate }) => {
          invoices.push({
            id,
            baseRate: invoiceBaseRate || baseRate,
            expirationDate,
          });
        }
      );
    }
    setInvoicesData({
      invoicesAmount,
      invoices,
      invoicesSize,
    });
  };

  useEffect(() => {
    if (show) searcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, limit]);

  useEffect(() => {
    setFilteredData(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (show) {
      initialiceDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <SectionHeader
        padding="lg"
        title={businessName}
        transparentBg
        mainArea={
          <Grid container xs={12} direction="row">
            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">
                  {country === COUNTRY_CODE_CL ? 'RUT:' : 'Identifier:'}
                </Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {businessIdentifier}
              </Typography>
            </Grid>

            <Grid item className={classes.rootInfo}>
              <Typography
                color="textPrimary"
                className={classes.labelInfo}
                component="div"
              >
                <Box fontWeight="fontWeightBold">Tipo de operación:</Box>
              </Typography>
              <Typography color="textSecondary" className={classes.info}>
                {translateTypeOrder(orderType)}
              </Typography>
            </Grid>
          </Grid>
        }
        preTitleAction={
          <Tooltip title="Operaciones Transferidas">
            <Badge
              classes={
                totalOrdersTransferred < 2
                  ? { badge: classes.firstOrderBadge }
                  : ''
              }
              badgeContent={totalOrdersTransferred}
              color="primary"
              invisible={false}
            />
          </Tooltip>
        }
        breadCrumbs={[]}
        showBreadCrumbs={false}
      />
      <Grid container item className={classes.bottomContainer}>
        <Grid item xs={8}>
          <Panel
            title="Lista de Operaciones"
            titlePadding="lg lg zero"
            contentPadding="zero"
            actions={
              <Grid item xs={12} container spacing={1} justify="flex-end">
                <Grid item>
                  <SearchBar
                    placeholder="Buscar operaciones..."
                    handleChangeInput={value => changeSearchInput(value)}
                    selectedOption={{
                      title: 'Order ID',
                      field: country === 'CL' ? 'Orders.id' : 'Order.id',
                    }}
                    fields={[
                      {
                        title: 'Order ID',
                        field: country === 'CL' ? 'Orders.id' : 'Order.id',
                      },
                      { title: 'Razon Social', field: 'name' },
                      {
                        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
                        field: country === 'CL' ? 'rut' : 'identifier',
                      },
                      {
                        title: 'Solicitud',
                        field:
                          country === 'CL'
                            ? 'Orders.createdAt'
                            : 'Order.createdAt',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            }
          >
            <Grid container item xs={12}>
              <OrdersTableMinified
                handleChangeRowSelection={handleChangeRowSelection}
                ordersData={filteredData}
                ordersLoading={loadingOrders}
                handleChangePage={handleChangePage}
                pagination={orderPagination}
                singleSelect
              />
            </Grid>
          </Panel>
        </Grid>
        <Grid item xs={3}>
          {selectedOrder ? (
            <InvoiceOperationResume
              order={selectedOrder}
              orderSummary={orderSummary}
              orderSummaryWasLoaded={orderSummaryWasLoaded}
              invoicesData={invoicesData}
              manageSelectedInvoices={() =>
                manageSelectedInvoices(selectedOrder.id)
              }
              buttonText="Mover Facturas"
              buttonIsLoading={manageOrderInvoicesIsLoading}
              action="move"
              buttonIcon={<MoveTo />}
            />
          ) : (
            <Grid className={classes.emptyResumeContainer}>
              <img
                src={emptyBrowserIcon}
                className={classes.emptyResumeIcon}
                alt="Top"
              />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
                noWrap
              >
                Selecciona una operación para ver la simulación aquí.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

MoveInvoiceDrawer.defaultProps = {
  show: false,
  manageOrderInvoicesIsLoading: false,
};

MoveInvoiceDrawer.propTypes = {
  country: PropTypes.string.isRequired,
  show: PropTypes.bool,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      invoiceId: PropTypes.number.isRequired,
      baseRate: PropTypes.number.isRequired,
      expirationDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  manageSelectedInvoices: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    orderType: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  manageOrderInvoicesIsLoading: PropTypes.bool,
};

export default MoveInvoiceDrawer;
