import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useOrder } from '../../../infrastructure/store';
import { useFetchInvoicesPaginated } from '../../../infrastructure/store/useFetchInvoicesPaginated';
import { fetchInvoices } from '../../../infrastructure/services';
import { useSetSelectedInvoices } from './store';
import { STATUS_REJECTED } from '../../../../../helpers/Constants';

const useHandleInvoiceData = tableRef => {
  const country = useSelector(state => state.config.country);
  const invoicesStatuses = {
    ALL: 'Todos los estados',
    IN_REVIEW: 'En Revisión',
    REVIEWED: 'Revisado',
    APPEALED: 'Apelado',
    APPROVED: 'Aprobado',
    APPROVED_FUND: country === 'MX' ? 'Pendiente Firma' : 'Aprobado Fondo',
    TO_DEPOSIT: 'Por Depositar',
    ACTIVE: 'Activo',
    DEFAULT: 'Default Contable',
    PAID: 'Pagado',
    REJECTED: 'Rechazado',
    COMPLETE: 'Completo',
    SOFT_DELINQUENCY: 'Mora Blanda',
    HARD_DELINQUENCY: 'Default Interno',
    DELINQUENT: 'Mora',
    HARD_COLLECTION: 'Estado Crítico',
    REPLACED: 'Reemplazado',
    IN_CONCILIATION: 'En conciliación',
    PRIORITY_PAYMENT: 'Pago prioritario',
  };
  const invoicesAttributes = { ALL: 'Todas las facturas', SUCCESS: 'Cedidos' };
  const dropDownFilters = [
    {
      value: 'folio',
      label: 'Folio',
    },
    {
      value: 'issuerName',
      label: 'Razón Social',
    },
    {
      value: 'identifier',
      label: country === 'CL' ? 'RUT' : 'RFC',
    },
  ];
  const [searchKey, setSearchKey] = useState('');
  const [invoiceStatus, setInvoiceStatus] = useState(invoicesStatuses.ALL);
  const [dropdown, setDropDown] = useState(dropDownFilters[0].value);
  const [invoiceAttribute, setInvoiceAttribute] = useState(
    invoicesAttributes.ALL
  );
  const { id: orderId } = useParams();
  const { order } = useOrder(orderId);
  const setSelectedInvoices = useSetSelectedInvoices();
  const [searchQuery, setSearchQuery] = useState({});
  const [menuQueries, setMenuQueries] = useState({});
  const [parametros, setParametros] = useState({});
  const { invoices, suppliersCount, invoicesCount, isLoading, pagination } =
    useFetchInvoicesPaginated(orderId, {
      ...parametros,
      filters: { ...searchQuery, ...menuQueries },
    });

  const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9- ]+$/;
  const RUT_REGEX = /^[a-zA-Z0-9-]{0,10}$/;
  const RFC_REGEX = /^[a-zA-Z0-9-]{0,13}$/;
  const FOLIO_REGEX = /^[0-9]*$/;

  const handleRowSelection = orderInvoices => {
    const selectedOrderInvoices = [...orderInvoices]
      ?.filter(invoice => invoice.status !== STATUS_REJECTED)
      .map(invoice => ({
        ...invoice,
        businessId: order.businessId,
        userId: order.userId,
        tableData: {
          ...invoice.tableData,
        },
      }));
    setSelectedInvoices(selectedOrderInvoices);
  };

  const handleRemoteData = async query => {
    const tableParams = {
      page: query.page ? query.page + 1 : 1,
      size: query.pageSize ? query.pageSize : 10,
    };
    setParametros(tableParams);

    const remoteData = await fetchInvoices(orderId, {
      ...tableParams,
      filters: {
        ...searchQuery,
        ...menuQueries,
      },
    });

    const { paginationData } = remoteData || {
      data: [],
      pagination: {},
    };

    return {
      data: paginationData?.data || [],
      page: paginationData?.page - 1,
      totalCount: paginationData?.totalData,
    };
  };

  const handleDropdown = e => {
    setDropDown(e.target.value);
    setSearchKey('');
    setSearchQuery({});
  };

  const handleSearch = e => {
    const searchValue = e.target.value;
    if (
      (searchValue !== '' &&
        !RUT_REGEX.test(searchValue) &&
        dropdown === 'identifier' &&
        country === 'CL') ||
      (searchValue !== '' &&
        !RFC_REGEX.test(searchValue) &&
        dropdown === 'identifier' &&
        country === 'MX') ||
      (searchValue !== '' &&
        !ALPHANUMERIC_REGEX.test(searchValue) &&
        dropdown === 'issuerName') ||
      (searchValue !== '' &&
        !FOLIO_REGEX.test(searchValue) &&
        dropdown === 'folio')
    ) {
      return;
    }

    let dropdownFilter;
    if (searchValue !== '') {
      dropdownFilter = {
        [dropdown]: dropdown === 'folio' ? Number(searchValue) : searchValue,
      };
    } else {
      dropdownFilter = {};
    }
    setSearchKey(searchValue);
    setSearchQuery(dropdownFilter);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      tableRef.current.onQueryChange();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const handleAttributes = e => {
    const selectedAttribute = e.target.value;
    const attrKey = Object.keys(invoicesAttributes).find(
      key => invoicesAttributes[key] === selectedAttribute
    );
    setInvoiceAttribute(selectedAttribute);
    if (attrKey !== 'ALL') {
      setMenuQueries({ ...menuQueries, cessionStatus: attrKey });
    } else {
      setMenuQueries({ ...menuQueries, cessionStatus: '' });
    }
    tableRef.current.onQueryChange();
  };

  const handleStatus = e => {
    const selectedStatus = e.target.value;
    const attrKey = Object.keys(invoicesStatuses).find(
      key => invoicesStatuses[key] === selectedStatus
    );
    setInvoiceStatus(selectedStatus);
    if (attrKey !== 'ALL') {
      setMenuQueries({ ...menuQueries, status: attrKey });
    } else {
      setMenuQueries({ ...menuQueries, status: '' });
    }
    tableRef.current.onQueryChange();
  };

  return {
    invoices,
    isLoading,
    invoicesCount,
    suppliersCount,
    handleRemoteData,
    handleRowSelection,
    rowsPerPage: pagination?.pageSize,
    count: pagination?.totalData,
    page: pagination?.page - 1,
    handleDropdown,
    handleSearch,
    handleAttributes,
    handleStatus,
    searchKey,
    invoiceStatus,
    invoicesStatuses,
    invoicesAttributes,
    dropDownFilters,
    dropdown,
    invoiceAttribute,
  };
};

export default useHandleInvoiceData;
