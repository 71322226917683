import {
  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_START,
  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_SUCCESS,
  GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE,
  UPDATE_PAYMENT_ORDER_DETAILS_START,
  UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS,
  UPDATE_PAYMENT_ORDER_DETAILS_FAILURE,
  RESET_PAYROLL_DETAILS_STORE,
} from '../actions/types';

const initialState = {
  attributes: {},
  paymentOrderDetailsIsLoading: false,
  paymentOrderDetailsIsUpdating: false,
  paymentOrderDetailsWasUpdated: false,
  paymentOrderDetailsError: null,
};

const getOrCreatePaymentOrderDetailsStart = state => {
  return {
    ...state,
    paymentOrderDetailsIsLoading: true,
  };
};

const getOrCreatePaymentOrderDetailsSuccess = (state, payload) => {
  const attributes = payload;
  return { ...state, attributes, paymentOrderDetailsIsLoading: false };
};

const getOrCreatePaymentOrderDetailsFailure = state => {
  return { ...state, paymentOrderDetailsIsLoading: false };
};

const updatePaymentOrderDetailsStart = state => {
  return {
    ...state,
    paymentOrderDetailsIsUpdating: true,
    paymentOrderDetailsWasUpdated: false,
    paymentOrderDetailsError: null,
  };
};

const updatePaymentOrderDetailsSuccess = (state, payload) => {
  return {
    ...state,
    attributes: { ...state.attributes, ...payload },
    paymentOrderDetailsIsUpdating: false,
    paymentOrderDetailsWasUpdated: true,
  };
};

const updatePaymentOrderDetailsFailure = (state, payload) => {
  return {
    ...state,
    paymentOrderDetailsIsUpdating: false,
    paymentOrderDetailsError: payload.errorCode,
  };
};

const resetPayrollDetailsReduxStore = state => {
  return {
    ...state,
    paymentOrderDetailsWasUpdated: false,
    paymentOrderDetailsError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_OR_CREATE_PAYMENT_ORDER_DETAILS_START:
      return getOrCreatePaymentOrderDetailsStart(state);
    case GET_OR_CREATE_PAYMENT_ORDER_DETAILS_SUCCESS:
      return getOrCreatePaymentOrderDetailsSuccess(state, payload);
    case GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE:
      return getOrCreatePaymentOrderDetailsFailure(state);
    case UPDATE_PAYMENT_ORDER_DETAILS_START:
      return updatePaymentOrderDetailsStart(state);
    case UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS:
      return updatePaymentOrderDetailsSuccess(state, payload);
    case UPDATE_PAYMENT_ORDER_DETAILS_FAILURE:
      return updatePaymentOrderDetailsFailure(state, payload);
    case RESET_PAYROLL_DETAILS_STORE:
      return resetPayrollDetailsReduxStore(state);
    default:
      return state;
  }
};
