/* eslint react/prop-types: 0 */ // --> OFF
import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import { WALLET_ACTIVITIES } from '../../helpers/Constants';
import PaginationMaterialTable from './PaginationMaterialTable';
import CountryFormatHelper from './CountryFormatHelper';
import { datetimeFormatter } from '../../helpers/DateUtils';

const WalletTransactionsTable = ({ transactions = [], loading }) => {
  const transactionColumns = [
    {
      title: 'Fecha',
      field: 'createdAt',
      type: 'date',
      render: ({ createdAt }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {datetimeFormatter(createdAt)}
          </Typography>
        );
      },
    },
    {
      title: 'Tipo',
      field: 'activity',
      render: ({ activity }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {activity ? WALLET_ACTIVITIES[activity] : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Factura asociada',
      field: 'orderFacturaId',
      render: ({ orderFacturaId = '-' }) => {
        return (
          <Typography variant="body2" color="textPrimary">
            {orderFacturaId}
          </Typography>
        );
      },
    },
    {
      title: 'Abono',
      field: 'amount',
      type: 'numeric',
      render: ({ amount }) => {
        if (Math.sign(amount) === 1) {
          return (
            <Typography variant="body2" color="textPrimary">
              <CountryFormatHelper
                value={amount}
                countryId="CL"
                variant="currency"
              />
            </Typography>
          );
        }
        return '-';
      },
    },
    {
      title: 'Cargo',
      field: 'amount',
      type: 'numeric',
      align: 'center',
      render: ({ amount }) => {
        if (Math.sign(amount) === -1) {
          return (
            <Typography variant="body2" color="textPrimary">
              <CountryFormatHelper
                value={amount * -1}
                countryId="CL"
                variant="currency"
              />
            </Typography>
          );
        }
        return '-';
      },
    },
  ];

  return (
    <MaterialTable
      columns={transactionColumns}
      data={transactions}
      style={{
        overflow: 'hidden',
        boxShadow: 'none',
        width: '100%',
        borderRadius: 17,
      }}
      isLoading={loading}
      components={{
        Toolbar: () => null,
        Pagination: props => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <PaginationMaterialTable {...props} />
        ),
      }}
      options={{
        paging: true,
        filtering: false,
        search: false,
        sorting: true,
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, 50, 100],
      }}
      localization={{
        pagination: {
          labelRowsSelect: 'transacciones',
          labelRowsPerPage: 'Transacciones por página',
          labelDisplayedRows: '{count} transacciones totales',
        },
      }}
    />
  );
};

WalletTransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.objectOf(PropTypes.any),
      activity: PropTypes.string.isRequired,
      orderFacturaId: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WalletTransactionsTable;
