export const deleteDraftDocumentsUseCase = ({
  payrollSelectorState,
  draftPayrollSelectorState,
}) => {
  return Object.keys(payrollSelectorState).reduce((acc, receiverIdentifier) => {
    const currentPayrollReceiver = payrollSelectorState[receiverIdentifier];
    const currentDraftPayrollReceiver =
      draftPayrollSelectorState[receiverIdentifier];

    if (!currentDraftPayrollReceiver) {
      acc[receiverIdentifier] = { ...payrollSelectorState[receiverIdentifier] };

      return acc;
    }

    if (
      currentPayrollReceiver.value.length ===
      currentDraftPayrollReceiver.value.length
    ) {
      return acc;
    }

    acc[receiverIdentifier] = {
      ...currentPayrollReceiver,
      value: currentPayrollReceiver.value.filter(
        doc =>
          !currentDraftPayrollReceiver.value
            .map(docu => docu.id)
            .includes(doc.id)
      ),
    };

    return acc;
  }, {});
};
