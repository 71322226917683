import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { RootStateOrAny, useSelector } from 'react-redux';
import SkeletonMui from '@material-ui/lab/Skeleton';
import { Search, Close } from '../../icons';
import CountryFormatHelper from '../CountryFormatHelper';
import Skeleton from '../Skeleton';
import { STATUS_HARD_COLLECTION } from '../../../helpers/Constants';
import {
  ContainerRoot,
  ContainerHeader,
  ContainerSubHeader,
  ContainerBody,
  ContainerBodyRow,
  ContainerBodyRowFolio,
  ContainerBodyRowAttributes,
  ContainerBodyRowAmount,
  ContainerTotalAmount,
  ContainerSkeleton,
  TextSecondary,
  TextBoldSecondary,
  TextSmallSecondary,
  TextPrimary,
  TextCriticalStatus,
  TextExpiredInvoiceStatus,
  VerticalDivider,
  HorizontalDivider,
  ExpiredInvoiceAttribute,
  CriticalStatusAttribute,
} from './styles';

export interface Invoice {
  id: number;
  folio: string;
  amount: number;
  interest: number;
  isExpired?: boolean;
  status?: string;
}

export interface InvoiceList extends Invoice {
  checked?: boolean;
  hidden?: boolean;
}

interface Props {
  invoices: InvoiceList[];
  isLoading?: boolean;
  handleGetSelectedInvoices: (selectedInvoices: InvoiceList[]) => void;
}

const SelectorInvoices = ({
  invoices = [],
  isLoading = false,
  handleGetSelectedInvoices,
}: Props) => {
  const country = useSelector((state: RootStateOrAny) => state.config.country);

  const [listInvoices, setListInvoices] = useState<InvoiceList[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceList[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [allInvoicesSelected, setAllInvoicesSelected] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    let isPreloadedData = false;
    let countPreloadedData = 0;
    const newInvoices =
      invoices?.map(invoice => {
        if (invoice?.checked) {
          isPreloadedData = true;
          countPreloadedData += 1;
        }
        return {
          ...invoice,
          checked: invoice.checked || false,
          hidden: invoice.hidden || false,
        };
      }) || [];
    setListInvoices(newInvoices);
    if (isPreloadedData) {
      setSelectedInvoices(newInvoices);
      setAllInvoicesSelected(invoices.length === countPreloadedData);
    } else {
      setSelectedInvoices([]);
      setAllInvoicesSelected(false);
    }
  }, [invoices]);

  const handleSearch = (value: string) => {
    setSearchInput(value);
    const filteredInvoices = listInvoices.map(listInvoice => {
      const hidden = !listInvoice.folio.includes(value);
      return {
        ...listInvoice,
        hidden,
      };
    });
    setListInvoices(filteredInvoices);
  };

  const handleSelectInvoice = (invoice: InvoiceList) => {
    const newListInvoices = listInvoices.map(listInvoice => {
      if (listInvoice.id === invoice.id) {
        return {
          ...listInvoice,
          checked: !listInvoice.checked,
        };
      }
      return listInvoice;
    });
    setListInvoices(newListInvoices);
    if (invoice.checked) {
      const filteredSelectedInvoices = selectedInvoices.filter(
        selectedInvoice => selectedInvoice.id !== invoice.id
      );
      setSelectedInvoices(filteredSelectedInvoices);
    } else {
      setSelectedInvoices([...selectedInvoices, { ...invoice, checked: true }]);
    }
  };

  const handleSelectAllInvoices = () => {
    const isChecked = !allInvoicesSelected;
    setAllInvoicesSelected(isChecked);
    setListInvoices(
      listInvoices.map(listInvoice => ({ ...listInvoice, checked: isChecked }))
    );
    setSelectedInvoices(isChecked ? listInvoices : []);
  };

  const calculateTotalAmount = () => {
    if (selectedInvoices.length) {
      const amounts = selectedInvoices.map(
        ({ amount = 0, interest = 0 }) => amount + interest
      );
      const sumTotalAmounts = amounts.reduce((x, y) => x + y, 0);
      setTotalAmount(sumTotalAmounts);
    } else {
      setTotalAmount(0);
    }
  };

  useEffect(() => {
    calculateTotalAmount();
    handleGetSelectedInvoices(selectedInvoices);
  }, [selectedInvoices]);

  const getCountryFormatHelper = (
    value: number,
    variant: string = 'currency'
  ) => {
    return (
      <>
        {/* @ts-ignore */}
        <CountryFormatHelper
          value={value}
          countryId={country}
          variant={variant}
        />
      </>
    );
  };

  return (
    <ContainerRoot>
      <ContainerHeader>
        <Checkbox
          color="primary"
          disabled={isLoading || !invoices.length}
          checked={allInvoicesSelected}
          onChange={handleSelectAllInvoices}
        />
        <TextBoldSecondary>Facturas disponibles a reemplazar</TextBoldSecondary>
      </ContainerHeader>

      <ContainerSubHeader>
        <Skeleton isLoading={isLoading} variant="rect" width={233} height={18}>
          <>
            {invoices?.length !== 0 && (
              <TextSmallSecondary>{`${selectedInvoices.length}/${invoices.length} seleccionadas`}</TextSmallSecondary>
            )}
          </>
        </Skeleton>
      </ContainerSubHeader>

      <Skeleton
        isLoading={isLoading}
        variant="rect"
        width={311}
        height={36}
        style={{ marginBottom: 15 }}
      >
        <>
          {invoices?.length !== 0 && (
            <TextField
              label="Buscar por folio"
              placeholder="Buscar por folio"
              id="input-selector-invoices"
              fullWidth
              value={searchInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleSearch(event.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearch('')}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          )}
        </>
      </Skeleton>

      <ContainerBody>
        {isLoading && (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(() => (
              <ContainerBodyRow>
                <ContainerSkeleton>
                  <SkeletonMui variant="rect" width={16} height={16} />
                </ContainerSkeleton>
                <ContainerSkeleton>
                  <SkeletonMui variant="rect" width={51} height={21} />
                </ContainerSkeleton>
                <ContainerSkeleton>
                  <SkeletonMui variant="rect" width={64} height={21} />
                </ContainerSkeleton>
              </ContainerBodyRow>
            ))}
          </>
        )}

        {!isLoading &&
          listInvoices?.map(invoice => (
            <>
              {!invoice.hidden && (
                <ContainerBodyRow key={invoice.id}>
                  <Checkbox
                    color="primary"
                    checked={invoice?.checked}
                    onChange={() => handleSelectInvoice(invoice)}
                  />
                  <ContainerBodyRowFolio>
                    <TextPrimary>{invoice.folio}</TextPrimary>
                  </ContainerBodyRowFolio>

                  <ContainerBodyRowAttributes>
                    {invoice.isExpired && <ExpiredInvoiceAttribute />}
                    {invoice.status === STATUS_HARD_COLLECTION && (
                      <CriticalStatusAttribute />
                    )}
                  </ContainerBodyRowAttributes>

                  <ContainerBodyRowAmount>
                    <TextSmallSecondary>
                      {getCountryFormatHelper(invoice.amount)}
                    </TextSmallSecondary>
                  </ContainerBodyRowAmount>

                  {invoice?.interest !== 0 && (
                    <TextSmallSecondary>
                      {['+ ', getCountryFormatHelper(invoice.interest)]}
                    </TextSmallSecondary>
                  )}
                </ContainerBodyRow>
              )}
            </>
          ))}
      </ContainerBody>

      <HorizontalDivider />

      <ContainerTotalAmount>
        <TextBoldSecondary>Monto total</TextBoldSecondary>
        {invoices?.length !== 0 && (
          <TextSecondary>: {getCountryFormatHelper(totalAmount)}</TextSecondary>
        )}
      </ContainerTotalAmount>

      <Box display="flex">
        <TextCriticalStatus>Estado Crítico </TextCriticalStatus>
        <VerticalDivider>|</VerticalDivider>
        <TextExpiredInvoiceStatus>Factura vencida</TextExpiredInvoiceStatus>
      </Box>
    </ContainerRoot>
  );
};

export default SelectorInvoices;
