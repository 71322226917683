import { Grid, styled } from '@material-ui/core';

const RootGrid = styled(Grid)({
  padding: 0,
});

const TopGrid = styled(Grid)({
  padding: 20,
});

const MiddleGrid = styled(Grid)({
  padding: '0 20px 20px 20px',
  gap: 24,
});

const BottomGrid = styled(Grid)({
  padding: '0 20px 20px 20px',
});

const DetailsGrid = styled(Grid)({
  maxWidth: 480,
  width: '100%',
});

export { RootGrid, TopGrid, MiddleGrid, BottomGrid, DetailsGrid };
