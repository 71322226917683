import React, { FC, PropsWithChildren } from 'react';
import { styled, Badge } from '@material-ui/core';
import { palette } from '../../../../theme/palette';

interface Props extends PropsWithChildren {
  isSelected: boolean;
}

const FilterBadge = styled(Badge)(
  ({ isSelected }: { isSelected: boolean }) => ({
    color: isSelected ? palette.payments.white : palette.payments.grey2,
    backgroundColor: isSelected
      ? palette.primary.main
      : palette.payments.softBlue,
    padding: '1px 7px',
    borderRadius: 20,
    fontSize: 12,
    marginLeft: 5,
  })
);

const StatusFilterBadge: FC<Props> = ({ isSelected, children }) => {
  return <FilterBadge isSelected={isSelected}>{children}</FilterBadge>;
};

export default StatusFilterBadge;
