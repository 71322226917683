const isLegalDocument = (country, documentTypeId) => {
  switch (country) {
    case 'MX':
      return (
        (documentTypeId === 2 ||
          documentTypeId === 3 ||
          documentTypeId === 4) ??
        false
      );

    case 'CL':
      return (
        (documentTypeId === 5 ||
          documentTypeId === 7 ||
          documentTypeId === 8 ||
          documentTypeId === 9 ||
          documentTypeId === 11) ??
        false
      );

    default:
      return false;
  }
};
// eslint-disable-next-line import/prefer-default-export
export { isLegalDocument };
