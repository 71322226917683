import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const updateInvoiceDiscountService = async ({
  sourceId,
  sourceType,
  body,
}) => {
  const { discount = 0, discountReason: reason } = body;
  try {
    const { data } = await axios.put(
      `/api/${sourceType}/${sourceId}/discount`,
      {
        amount: Number(discount),
        reason,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

export default updateInvoiceDiscountService;
