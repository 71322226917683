export const appliedOnOptions = [
  { value: 'Emisor de la factura', label: 'Emisor de la factura' },
  { value: 'Receptor de la factura', label: 'Receptor de la factura' },
];

const contactOptions = [
  { value: 'WITH_CONTACT', label: 'Hubo contacto con deudor' },
  { value: 'CONTACT_WITH_OTHERS', label: 'Contacto con terceros' },
  { value: 'NO_CONTACT', label: 'Sin contacto' },
];

const visitOptions = [
  { value: 'INVALID_ADDRESS', label: 'Dirección no existe' },
];

const messageOptions = [
  { value: 'ONLY_SENT', label: 'Enviado esperando respuesta' },
];

const meetingOptions = [
  { value: 'WITH_CONTACT', label: 'Hubo reunión' },
  { value: 'NO_CONTACT', label: 'No hubo reunión' },
];

const postingOptions = [
  { value: 'POSTED', label: 'Publicado' },
  { value: 'UNPOSTED', label: 'Aclarado' },
  { value: 'TO_POST', label: 'Por publicar' },
];

const planOptions = [
  { value: 'IN_PROCESS', label: 'En proceso' },
  { value: 'COMPLIANCE', label: 'En cumplimiento' },
  { value: 'NO_COMPLIANCE', label: 'Incumplido' },
];

const invoiceOptions = [
  { value: 'CREDIT_NOTE', label: 'Nota de crédito parcial' },
  { value: 'ANULATION', label: 'Nota de crédito total' },
  { value: 'CLAIMED', label: 'Con reclamo' },
  { value: 'DISCOUNT', label: 'Con descuento' },
  { value: 'PAYED_TO_STAKEHOLDER', label: 'Pagado a emisor' },
  { value: 'SOLVED', label: 'Problema resuelto' },
];

const reassignmentOptions = [
  { value: 'TO_BE_SENT', label: 'Por enviar' },
  { value: 'SEND_AND_KEEP', label: 'Enviar y mantener en cartera' },
  { value: 'SEND_AND_TAKE', label: 'Enviar y sacar de cartera' },
];

const preExpirationMenu = {
  type: { value: 'PRE_EXPIRATION', label: 'Recordatorio pre vencimiento' },
  subType: [
    { value: 'EMAIL', label: 'Email' },
    { value: 'SMS', label: 'Mensaje de texto' },
    { value: 'WHATSAPP', label: 'Whatsapp' },
    { value: 'PHONE_CALL', label: 'Llamada' },
  ],
  result: {
    EMAIL: [...messageOptions, ...contactOptions],
    SMS: [...messageOptions, ...contactOptions],
    WHATSAPP: [...messageOptions, ...contactOptions],
    PHONE_CALL: contactOptions,
  },
};

const followUpMenu = {
  type: { value: 'FOLLOW_UP', label: 'Seguimiento' },
  subType: [
    { value: 'EMAIL', label: 'Email' },
    { value: 'SMS', label: 'Mensaje de texto' },
    { value: 'WHATSAPP', label: 'Whatsapp' },
    { value: 'PHONE_CALL', label: 'Llamada' },
    { value: 'VISIT', label: 'Visita a terreno' },
    { value: 'MEETING', label: 'Reunión' },
  ],
  result: {
    EMAIL: [...messageOptions, ...contactOptions],
    SMS: [...messageOptions, ...contactOptions],
    WHATSAPP: [...messageOptions, ...contactOptions],
    PHONE_CALL: contactOptions,
    VISIT: [...contactOptions, ...visitOptions],
    MEETING: meetingOptions,
  },
};

const DebtPortalCLMenu = {
  type: { value: 'DEBT_PORTAL', label: 'Portales de deuda' },
  subType: [
    { value: 'DICOM', label: 'Dicom' },
    { value: 'COBRANZA_ONLINE', label: 'Cobranza Online' },
  ],
  result: {
    DICOM: postingOptions,
    COBRANZA_ONLINE: postingOptions,
  },
};

const DebtPortalMXMenu = {
  type: { value: 'DEBT_PORTAL', label: 'Portales de deuda' },
  subType: [{ value: 'BURO', label: 'Buró' }],
  result: {
    BURO: postingOptions,
  },
};

const paymentPlanMenu = {
  type: { value: 'PAYMENT_PLAN', label: 'Plan de pago' },
  subType: [],
  result: {
    PAYMENT_PLAN: planOptions,
  },
};

const invoiceProblemsMenu = {
  type: { value: 'INVOICE_PROBLEMS', label: 'Factura con problemas' },
  subType: [
    { value: 'SLACK_SII', label: 'Slack SII' },
    { value: 'EMAIL', label: 'Email' },
  ],
  result: {
    SLACK_SII: invoiceOptions,
    EMAIL: invoiceOptions,
  },
};

const internalMenu = {
  type: { value: 'INTERNAL', label: 'Campaña operativo interno' },
  subType: [],
  result: {},
};

const possibleFraudMenu = {
  type: { value: 'POSSIBLE_FRAUD', label: 'Posible fraude' },
  subType: [],
  result: {},
};

const reassignmentMXMenu = {
  type: { value: 'REASSIGNMENT', label: 'Traspaso de equipo' },
  subType: [
    { value: 'COLLECTIONS_MEXICO', label: 'Cobranza' },
    { value: 'JUDICIAL_ACTION', label: 'Cobranza judicial' },
    { value: 'EXTERNAL_COLLECTION', label: 'Cobranza externa' },
    { value: 'CONCILIATION', label: 'Conciliación' },
  ],
  result: {
    COLLECTIONS_MEXICO: reassignmentOptions,
    JUDICIAL_ACTION: reassignmentOptions,
    EXTERNAL_COLLECTION: reassignmentOptions,
    CONCILIATION: reassignmentOptions,
  },
};

const reassignmentCLMenu = {
  type: { value: 'REASSIGNMENT', label: 'Traspaso de equipo' },
  subType: [
    { value: 'COLLECTIONS_CHILE', label: 'Cobranza tradicional' },
    { value: 'NORMALIZATION', label: 'Normalización' },
    { value: 'JUDICIAL_ACTION', label: 'Cobranza judicial' },
    { value: 'EXTERNAL_COLLECTION', label: 'Cobranza externa' },
    { value: 'CONCILIATION', label: 'Conciliación' },
  ],
  result: {
    COLLECTIONS_CHILE: reassignmentOptions,
    NORMALIZATION: reassignmentOptions,
    JUDICIAL_ACTION: reassignmentOptions,
    EXTERNAL_COLLECTION: reassignmentOptions,
    CONCILIATION: reassignmentOptions,
  },
};

interface Options {
  value: string;
  label: string;
}

interface MenuItems {
  type: Options;
  subType: Options[];
  result: Record<string, Options[]>;
}

interface Menu {
  [country: string]: Record<string, MenuItems>;
}

export const menuManagement: Menu = {
  CL: {
    PRE_EXPIRATION: preExpirationMenu,
    FOLLOW_UP: followUpMenu,
    DEBT_PORTAL: DebtPortalCLMenu,
    PAYMENT_PLAN: paymentPlanMenu,
    INVOICE_PROBLEMS: invoiceProblemsMenu,
    INTERNAL: internalMenu,
    POSSIBLE_FRAUD: possibleFraudMenu,
    REASSIGNMENT: reassignmentCLMenu,
  },
  MX: {
    PRE_EXPIRATION: preExpirationMenu,
    FOLLOW_UP: followUpMenu,
    DEBT_PORTAL: DebtPortalMXMenu,
    PAYMENT_PLAN: paymentPlanMenu,
    INVOICE_PROBLEMS: invoiceProblemsMenu,
    INTERNAL: internalMenu,
    POSSIBLE_FRAUD: possibleFraudMenu,
    REASSIGNMENT: reassignmentMXMenu,
  },
};

interface subType {
  label: string;
  result: Record<string, string>;
}

interface Type {
  label: string;
  subTypes: Record<string, subType>;
}

interface subType {
  label: string;
  result: Record<string, string>;
}

interface Type {
  label: string;
  subTypes: Record<string, subType>;
}

interface ManagementLabels {
  [country: string]: Record<string, Type>;
}

export const managementLabels: ManagementLabels = {
  CL: {
    PRE_EXPIRATION: {
      label: 'Recordatorio pre vencimiento',
      subTypes: {
        EMAIL: {
          label: 'Email',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        SMS: {
          label: 'Mensaje de texto',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        WHATSAPP: {
          label: 'Whatsapp',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        PHONE_CALL: {
          label: 'Llamada',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
          },
        },
      },
    },
    FOLLOW_UP: {
      label: 'Seguimiento',
      subTypes: {
        EMAIL: {
          label: 'Email',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        SMS: {
          label: 'Mensaje de texto',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        WHATSAPP: {
          label: 'Whatsapp',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        PHONE_CALL: {
          label: 'Llamada',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
          },
        },
        VISIT: {
          label: 'Visita a terreno',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
          },
        },
        MEETING: {
          label: 'Reunión',
          result: {
            WITH_CONTACT: 'Hubo reunión',
            NO_CONTACT: 'No hubo reunión',
          },
        },
      },
    },
    DEBT_PORTAL: {
      label: 'Portales de deuda',
      subTypes: {
        DICOM: {
          label: 'Dicom',
          result: {
            POSTED: 'Publicado',
            UNPOSTED: 'Aclarado',
            'TO_POST': 'Por publicar',
          },
        },
        COBRANZA_ONLINE: {
          label: 'Cobranza Online',
          result: {
            POSTED: 'Publicado',
            UNPOSTED: 'Aclarado',
            'TO_POST': 'Por publicar',
          },
        },
      },
    },
    PAYMENT_PLAN: {
      label: 'Plan de pago',
      subTypes: {
        '': {
          label: '',
          result: {
            IN_PROCESS: 'En proceso',
            COMPLIANCE: 'En cumplimiento',
            NO_COMPLIANCE: 'Incumplido',
          },
        },
      },
    },
    INVOICE_PROBLEMS: {
      label: 'Estado crítico',
      subTypes: {
        SLACK_SII: {
          label: 'Slack SII',
          result: {
            CREDIT_NOTE: 'Nota de crédito parcial',
            ANULATION: 'Nota de crédito total',
            CLAIMED: 'Con reclamo',
            DISCOUNT: 'Con descuento',
            PAYED_TO_STAKEHOLDER: 'Pagado a emisor',
            SOLVED: 'Problema resuelto',
          },
        },
        EMAIL: {
          label: 'Email',
          result: {
            CREDIT_NOTE: 'Nota de crédito parcial',
            ANULATION: 'Nota de crédito total',
            CLAIMED: 'Con reclamo',
            DISCOUNT: 'Con descuento',
            PAYED_TO_STAKEHOLDER: 'Pagado a emisor',
            SOLVED: 'Problema resuelto',
          },
        },
      },
    },
    INTERNAL: {
      label: 'Campaña operativo interno',
      subTypes: {
        '': {
          label: '',
          result: {
            '': '',
          },
        },
      },
    },
    POSSIBLE_FRAUD: {
      label: 'Posible fraude',
      subTypes: {
        '': {
          label: '',
          result: {
            '': '',
          },
        },
      },
    },
    REASSIGNMENT: {
      label: 'Traspaso de equipo',
      subTypes: {
        COLLECTIONS_CHILE: {
          label: 'Cobranza tradicional',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        NORMALIZATION: {
          label: 'Normalización',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        JUDICIAL_ACTION: {
          label: 'Cobranza judicial',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        EXTERNAL_COLLECTION: {
          label: 'Cobranza externa',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        CONCILIATION: {
          label: 'Conciliación',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
      },
    },
  },
  MX: {
    PRE_EXPIRATION: {
      label: 'Recordatorio pre vencimiento',
      subTypes: {
        EMAIL: {
          label: 'Email',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        SMS: {
          label: 'Mensaje de texto',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        WHATSAPP: {
          label: 'Whatsapp',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        PHONE_CALL: {
          label: 'Llamada',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
          },
        },
      },
    },
    FOLLOW_UP: {
      label: 'Seguimiento',
      subTypes: {
        EMAIL: {
          label: 'Email',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        SMS: {
          label: 'Mensaje de texto',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        WHATSAPP: {
          label: 'Whatsapp',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
            ONLY_SENT: 'Enviado esperando respuesta',
          },
        },
        PHONE_CALL: {
          label: 'Llamada',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
          },
        },
        VISIT: {
          label: 'Visita a terreno',
          result: {
            WITH_CONTACT: 'Hubo contacto con deudor',
            CONTACT_WITH_OTHERS: 'Contacto con terceros',
            NO_CONTACT: 'Sin contacto',
            INVALID_ADDRESS: 'Dirección no existe',
          },
        },
        MEETING: {
          label: 'Reunión',
          result: {
            WITH_CONTACT: 'Hubo reunión',
            NO_CONTACT: 'No hubo reunión',
          },
        },
      },
    },
    DEBT_PORTAL: {
      label: 'Portales de deuda',
      subTypes: {
        BURO: {
          label: 'Buró',
          result: {
            POSTED: 'Publicado',
            UNPOSTED: 'Aclarado',
            'TO_POST': 'Por publicar',
          },
        },
      },
    },
    PAYMENT_PLAN: {
      label: 'Plan de pago',
      subTypes: {
        '': {
          label: '',
          result: {
            IN_PROCESS: 'En proceso',
            COMPLIANCE: 'En cumplimiento',
            NO_COMPLIANCE: 'Incumplido',
          },
        },
      },
    },
    INVOICE_PROBLEMS: {
      label: 'Estado crítico',
      subTypes: {
        SLACK_SII: {
          label: 'Slack SII',
          result: {
            CREDIT_NOTE: 'Nota de crédito parcial',
            ANULATION: 'Nota de crédito total',
            CLAIMED: 'Con reclamo',
            DISCOUNT: 'Con descuento',
            PAYED_TO_STAKEHOLDER: 'Pagado a emisor',
            SOLVED: 'Problema resuelto',
          },
        },
        EMAIL: {
          label: 'Email',
          result: {
            CREDIT_NOTE: 'Nota de crédito parcial',
            ANULATION: 'Nota de crédito total',
            CLAIMED: 'Con reclamo',
            DISCOUNT: 'Con descuento',
            PAYED_TO_STAKEHOLDER: 'Pagado a emisor',
            SOLVED: 'Problema resuelto',
          },
        },
      },
    },
    INTERNAL: {
      label: 'Campaña operativo interno',
      subTypes: {
        '': {
          label: '',
          result: {
            '': '',
          },
        },
      },
    },
    POSSIBLE_FRAUD: {
      label: 'Posible fraude',
      subTypes: {
        '': {
          label: '',
          result: {
            '': '',
          },
        },
      },
    },
    REASSIGNMENT: {
      label: 'Traspaso de equipo',
      subTypes: {
        COLLECTIONS_MEXICO: {
          label: 'Cobranza',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        JUDICIAL_ACTION: {
          label: 'Cobranza judicial',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        EXTERNAL_COLLECTION: {
          label: 'Cobranza externa',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
        CONCILIATION: {
          label: 'Conciliación',
          result: {
            TO_BE_SENT: 'Por enviar',
            SEND_AND_KEEP: 'Enviar y mantener en cartera',
            SEND_AND_TAKE: 'Enviar y sacar de cartera',
          },
        },
      },
    },
  },
};

export const invoiceLabels: Record<string, string> = {
  CREDIT_NOTE: 'Nota de crédito parcial',
  ANULATION: 'Nota de crédito total',
  CLAIMED: 'Con reclamo',
  DISCOUNT: 'Con descuento',
  PAYED_TO_STAKEHOLDER: 'Pagado a emisor',
  SOLVED: 'Problema resuelto',
};

export enum managementTypes {
  PRE_EXPIRATION = 'PRE_EXPIRATION',
  FOLLOW_UP = 'FOLLOW_UP',
  DEBT_PORTAL = 'DEBT_PORTAL',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  INVOICE_PROBLEMS = 'INVOICE_PROBLEMS',
  INTERNAL = 'INTERNAL',
  POSSIBLE_FRAUD = 'POSSIBLE_FRAUD',
  REASSIGNMENT = 'REASSIGNMENT',
}
