import { useQuery } from 'react-query';
import { fetchInvoices } from '../services';

const useFetchInvoicesPaginated = (orderId, params) => {
  const placeholderData = {
    paginationData: { data: [], pagination: {} },
    suppliersCount: 0,
  };

  const { isLoading, isPlaceholderData, data, refetch } = useQuery(
    ['getInvoicesPaginated', orderId, params],
    () => fetchInvoices(orderId, params),
    {
      placeholderData,
      enabled: !!orderId,
    }
  );

  const { paginationData, suppliersCount } = data || {
    paginationData: { data: [], pagination: {} },
    suppliersCount: 0,
  };

  return {
    invoices: paginationData?.data,
    suppliersCount,
    invoicesCount: paginationData?.pagination?.totalData,
    pagination: paginationData?.pagination,
    isLoading: isLoading || isPlaceholderData,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchInvoicesPaginated };
