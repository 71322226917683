import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

export const calculateDebtPartialPaymentService = async (
  sourceType,
  sourceId
) => {
  try {
    const data = await axios.get(
      `api/${sourceType}/${sourceId}/partialpay/calculatedebt`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || UNKNOWN_ERROR);
  }
};

export default calculateDebtPartialPaymentService;
