import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Typography,
  Grid,
  AppBar,
  Toolbar,
  Drawer,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import * as St from './Creation.styles';
import { Close } from '../../../../components/icons';
import { PayrollSummary } from './PayrollSummary';
import { ReceiversList } from './ReceiversList';
import { useOpportunitiesContext } from '../../context/OpportunitiesContext';
import { VIEW_TYPES } from '../../context/OpportunitiesContext/constants';
import { SummaryActionBar } from './SummaryActionBar';
import {
  useGetSelectedFund,
  usePayrollActions,
} from '../../domains/payroll/state';
import { usePayrollSelectorController } from '../../domains/payroll/controllers';
import { DocumentsView } from './DocumentsView';
import { useReceiverActions } from '../../domains/receiver/state';

export const Creation = () => {
  const css = St.useStyles();
  const history = useHistory();
  const [firstRender, setFirstRender] = useState(true);
  const opportunitiesCtx = useOpportunitiesContext();
  const { cleanDraftPayrollSelector } = usePayrollActions();
  const { resetSelectorToInitialState, selectFund } =
    usePayrollSelectorController();
  const { cleanSelectedDraftReceiver } = useReceiverActions();
  const selectedFund = useGetSelectedFund();

  useEffect(() => {
    cleanDraftPayrollSelector();
    resetSelectorToInitialState();
    cleanSelectedDraftReceiver();
    opportunitiesCtx.changeToReceiverView();
  }, []);

  useEffect(() => {
    if (opportunitiesCtx.viewType === VIEW_TYPES.RECEIVER) {
      cleanSelectedDraftReceiver();
      setFirstRender(false);

      if (selectedFund && !firstRender) {
        selectFund(selectedFund, opportunitiesCtx.viewType, true);
      }
    }
  }, [opportunitiesCtx.viewType, firstRender]);

  return (
    <Drawer anchor="right" classes={{ paper: css.drawer }} open>
      <AppBar position="static" className={css.appBar} elevation={0}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={css.navTitle}>
              <Typography variant="h6" align="center">
                <Box fontWeight="fontWeightBold">Enviar nómina</Box>
              </Typography>
              <Typography variant="body2" align="center">
                Selecciona el fondo, edita las tasas y/o el Dicom de ser
                necesario y envía.
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={history.goBack}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container className={css.container}>
        <Grid item xs={12} className={css.containerBody}>
          <Box className={css.containerTables}>
            {opportunitiesCtx.viewType === VIEW_TYPES.RECEIVER && (
              <ReceiversList
                changeToDocumentView={opportunitiesCtx.changeToDocumentView}
              />
            )}
            {opportunitiesCtx.viewType === VIEW_TYPES.DOCUMENT && (
              <DocumentsView />
            )}
          </Box>
          <PayrollSummary />
        </Grid>
      </Grid>
      <SummaryActionBar />
    </Drawer>
  );
};
