import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  resetCreateOrder,
  createOperationNotEnrolled,
} from '../../../actions/orderActions';

import { CONFIRMING, ORDER_PATH } from '../../../helpers/Constants';

const usePaymentHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { createOrderIsLoading, createOrderError, orderId, orderWasCreated } =
    useSelector(state => state.order);

  const { country } = useSelector(state => state.config);

  const [showInvoicesBulkUpdateDialog, setShowInvoicesBulkUpdateDialog] =
    useState(false);

  const [showFundsBulkUpdateDialog, setShowFundsBulkUpdateDialog] =
    useState(false);

  const [showCreateOrderDialog, setShowCreateOrderDialog] = useState(false);

  const [
    showPartialPaymentsBulkUpdateDialog,
    setshowPartialPaymentsBulkUpdateDialog,
  ] = useState(false);

  const [
    showInvoicesBulkStatusUpdateDialog,
    setInvoicesBulkStatusUpdateDialog,
  ] = useState(false);

  const handleShowInvoicesBulkUpdateDialog = useCallback(() => {
    setShowInvoicesBulkUpdateDialog(true);
  }, [setShowInvoicesBulkUpdateDialog]);

  const handleCloseInvoicesBulkUpdateDialog = useCallback(() => {
    setShowInvoicesBulkUpdateDialog(false);
  }, [setShowInvoicesBulkUpdateDialog]);

  const handleShowFundsBulkUpdateDialog = useCallback(() => {
    setShowFundsBulkUpdateDialog(true);
  }, [setShowFundsBulkUpdateDialog]);

  const handleCloseFundsBulkUpdateDialog = useCallback(() => {
    setShowFundsBulkUpdateDialog(false);
  }, [setShowFundsBulkUpdateDialog]);

  const handleShowCreateOrderDialog = useCallback(() => {
    setShowCreateOrderDialog(true);
  }, [setShowCreateOrderDialog]);

  const handleCloseCreateOrderDialog = useCallback(() => {
    setShowCreateOrderDialog(false);
  }, [setShowCreateOrderDialog]);

  const handleShowPartialPaymentsBulkUpdateDialog = useCallback(() => {
    setshowPartialPaymentsBulkUpdateDialog(true);
  }, [setshowPartialPaymentsBulkUpdateDialog]);

  const handleClosePartialPaymentsBulkUpdateDialog = useCallback(() => {
    setshowPartialPaymentsBulkUpdateDialog(false);
  }, [setshowPartialPaymentsBulkUpdateDialog]);

  const handleShowInvoicesBulkStatusUpdateDialog = useCallback(() => {
    setInvoicesBulkStatusUpdateDialog(true);
  }, [setInvoicesBulkStatusUpdateDialog]);

  const handleCloseInvoicesBulkStatusUpdateDialog = useCallback(() => {
    setInvoicesBulkStatusUpdateDialog(false);
  }, [setInvoicesBulkStatusUpdateDialog]);

  const handleSubmitCreateOperation = (
    { clientSelected, rawOrderInvoices, feesData, businesses },
    simulation,
    availableInvoicesCount,
    availableInvoicesAmount,
    autoRateType,
    currency,
    operationPaymentType,
    bankAccounts
  ) => {
    dispatch(
      createOperationNotEnrolled(
        CONFIRMING,
        {
          clientSelected,
          rawOrderInvoices,
          feesData,
          businesses,
        },
        simulation,
        availableInvoicesCount,
        availableInvoicesAmount,
        autoRateType,
        currency,
        0,
        operationPaymentType,
        bankAccounts
      )
    );
  };

  useEffect(() => {
    if (orderWasCreated) setShowCreateOrderDialog(false);
    if (orderId && orderWasCreated) {
      const path = orderId
        ? `/${country.toLowerCase()}/payments/${orderId}`
        : `/${country.toLowerCase()}${ORDER_PATH.root}`;
      history.push(path);
      dispatch(resetCreateOrder());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orderWasCreated]);

  const bulkUpdateMenuItems = [
    {
      name: 'Actualización masiva de facturas',
      handleShowModal: handleShowInvoicesBulkUpdateDialog,
    },
    {
      name: 'Actualización masiva de fondos',
      handleShowModal: handleShowFundsBulkUpdateDialog,
    },
    {
      name: 'Ingreso masivo de Pagos Parciales',
      handleShowModal: handleShowPartialPaymentsBulkUpdateDialog,
    },
    {
      name: 'Actualización masiva de estados de facturas',
      handleShowModal: handleShowInvoicesBulkStatusUpdateDialog,
    },
  ];

  return {
    handleCloseInvoicesBulkUpdateDialog,
    handleCloseFundsBulkUpdateDialog,
    showInvoicesBulkUpdateDialog,
    showFundsBulkUpdateDialog,
    bulkUpdateMenuItems,
    showCreateOrderDialog,
    handleShowCreateOrderDialog,
    handleCloseCreateOrderDialog,
    handleSubmitCreateOperation,
    createOrderIsLoading,
    createOrderError,
    handleShowPartialPaymentsBulkUpdateDialog,
    handleClosePartialPaymentsBulkUpdateDialog,
    showPartialPaymentsBulkUpdateDialog,
    handleShowInvoicesBulkStatusUpdateDialog,
    handleCloseInvoicesBulkStatusUpdateDialog,
    showInvoicesBulkStatusUpdateDialog,
  };
};

export default usePaymentHeader;
