import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mb15: {
    marginBottom: '15px',
  },
  cleanFilters: {
    color: '#8D8A96',
  },
  icon: {
    marginRight: '15px',
  },
  chip: {
    margin: '0 10px 10px 0',
  },
}));
