/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MANATEE_COLOR } from '../../helpers/Constants';

const useStyles = makeStyles({
  root: {
    borderRadius: '4px',
    width: 25,
    height: 25,
  },
});

const Box = props => {
  const classes = useStyles();
  const { icon, color } = props;
  return (
    <div className={classes.root} style={{ background: `${color}` }}>
      {icon}
    </div>
  );
};

Box.defaultProps = {
  icon: null,
  color: MANATEE_COLOR,
};

Box.propTypes = {
  color: PropTypes.string,
  icon: PropTypes,
};

export default Box;
