import React from 'react';
import PropTypes from 'prop-types';
import { FileUploadDialog } from '../../../commons/components';
import { useFundsBulkUpdate } from '../../infrastructure/store';
import { csvErrorsReportBuilder } from '../../../../helpers/CsvErrorsReportBuilder';

const FundsBulkUpdateModal = ({ open, handleClose }) => {
  const {
    uploadFile,
    resetDataAndErrors,
    isLoading,
    errorLines,
    serverError,
    isSuccess,
    hasErrors,
  } = useFundsBulkUpdate();

  const TITLE = 'Actualización masiva de fondos';
  const DESCRIPTION =
    'Arrastra o selecciona el archivo que deseas cargar. (Formato permitido: CSV, máx. 10 Mb)';

  if (!open || (isSuccess && !hasErrors)) {
    return null;
  }

  const CSV_TEMPLATE = [
    {
      orderInvoiceId: 1,
      fundId: 1,
      nominaId: 1,
      folio: '12345',
      startDate: '2021-01-01',
      expirationDate: '2021-01-02',
      interest: 1000,
      baseRate: 1.1,
      debtRate: 1.8,
      debtDays: 20,
      advancePercentage: 90,
    },
  ];

  const onClose = () => {
    handleClose();
    resetDataAndErrors();
  };

  return (
    <FileUploadDialog
      open={open}
      handleCloseDialog={onClose}
      handleLoad={uploadFile}
      saving={isLoading}
      errorsCsv={csvErrorsReportBuilder(errorLines)}
      serverError={serverError}
      title={TITLE}
      description={DESCRIPTION}
      csvTemplate={CSV_TEMPLATE}
      actionText="Subir archivo"
      resetDataAndErrors={resetDataAndErrors}
    />
  );
};

FundsBulkUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FundsBulkUpdateModal;
