import { gql } from 'graphql-request';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { useGraphQlQuery } from '../hooks/useGraphQlQuery';
import { TransitionType } from '../schemas';
import { getKeysSelected } from './helpers/getKeySelected';

type WorkflowsWithStateParams = {
  entityRefId?: string;
  workflowIds: string[];
};

type WorkflowsWithStatesDTO = {
  dto: WorkflowsWithStateParams;
};

type WorkflowsWithStatesFieldsResponse = {
  id?: boolean;
  comment?: boolean;
  createAt?: boolean;
  origin?: boolean;
  target?: boolean;
  workflow?: boolean;
  modifiedByUserRef?: boolean;
  transitionType?: boolean;
};

type WorkFlowWithState = {
  id: string;
  comment: string;
  createAt: string;
  origin: string;
  target: string;
  workflow: {
    id: string;
    name: string;
  };
  modifiedByUserRef: string;
  transitionType: TransitionType;
};

type WorkflowsWithStatesResponse = {
  workflowsWithState: WorkFlowWithState[];
};

type UseWorkflowsWithStateResponse = {
  workflowsStateData?: WorkflowsWithStatesResponse;
  isWorkflowStateLoading?: boolean;
  refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<WorkflowsWithStatesResponse, string>>;
};

type UseWorkflowsWithStateProps = {
  params: WorkflowsWithStateParams;
  fieldsResponse: WorkflowsWithStatesFieldsResponse;
};

function getWorkflowsWithStateQuery(
  props: WorkflowsWithStatesFieldsResponse,
): string {
  const keysSelected = getKeysSelected<WorkflowsWithStatesFieldsResponse>(props);
  return gql`
    query WorkflowsWithState($dto: GetWorflowStatesDTO!) {
      workflowsWithState(dto: $dto) {
        ${keysSelected.map(key => key)}
      }
    }
  `;
}

function useWorkflowsWithState(
  props: UseWorkflowsWithStateProps,
): UseWorkflowsWithStateResponse {
  const { params, fieldsResponse } = props;
  const dto: WorkflowsWithStatesDTO = {
    dto: params,
  };
  const query = getWorkflowsWithStateQuery(fieldsResponse);
  const { data, loading, refetch } = useGraphQlQuery<WorkflowsWithStatesResponse>(
    'getWorkflowsWithState',
    {
      query,
      variables: dto,
    },
  );

  return {
    workflowsStateData: data,
    isWorkflowStateLoading: loading,
    refetch,
  };
}

export type { WorkFlowWithState };
export default useWorkflowsWithState;
