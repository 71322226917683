import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  Grid,
  Tooltip,
  Typography,
  Box,
  Button,
  CircularProgress,
  makeStyles,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import copy from 'copy-to-clipboard';
import {
  fetchBusiness,
  updateBusinessFees,
  resetBusinessFessFailure,
  resetBusinessFees,
  consultBusinessCompliance,
  fetchBusinessCompliance,
  resetBusinessCompliance,
} from '../actions/businessAction';
import {
  consultDebtService,
  resetConsultDebtService,
} from '../actions/debtServiceActions';
import { enqueueSnackbar } from '../actions/notificationAction';
import { verifyStatusDocuments } from '../actions/documentActions';
import EditRatesDialog from './dialogs/EditRatesDialog';
import DebtServiceDialog from './dialogs/DebtServiceDialog';
import ComplianceDialog from './dialogs/ComplianceDialog';
import Can from './Can';
import MenuMoreOptions from './elements/MenuMoreOptions';
import {
  BUSINESS_EDIT_FEE_PERFORM,
  BUSINESS_BURO_PERFORM,
  BUSINESS_COMPLIANCE_PERFORM,
  BUSINESS_UPDATE_SWITCH_AUTO_RATE,
} from '../helpers/performsType';
import { COMPLIANCE_LABEL, COMPLIANCE } from '../helpers/Constants';
import CountryFormatHelper from './elements/CountryFormatHelper';
import { dateFormatter } from '../helpers/DateUtils';
import MenuItem from './elements/MenuItem';
import {
  Settings as SettingsIcon,
  FolderOpen as FolderOpenIcon,
  Check as CheckIcon,
  Copy as CopyIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Reload as ReloadIcon,
  Help as HelpIcon,
} from './icons';
import { fetchBusinessRates } from '../actions/ratesActions';
import {
  useEntityFeatures,
  ENTITY_FEATURE_AUTOTASA,
  ENTITY_FEATURE_CONFIRMING,
} from '../hooks/entityFeatures';
import { t13s } from '../translationKeys';
import { checkAuth } from '../helpers/validation/auth';

const useStyles = makeStyles({
  containerItemsSizeStandar: {
    margin: 10,
    width: 120,
  },
  containerItemsSizeLarge: {
    margin: 10,
    width: 260,
  },
  elementTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 26,
    marginBottom: 5,
  },
  spacingRight: {
    marginRight: 8,
  },
  containerButtons: {
    padding: 10,
    paddingBottom: 0,
  },
  infoBussinessButtons: {
    marginRight: 20,
    marginBottom: 10,
  },
  help: {
    marginLeft: 10,
  },
});

const SPECIAL_CASES = ['Correo de intercambio'];
const BUSINESS = 'business';

const BusinessDetailMX = ({ business }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    saving,
    savedBusiness,
    updateFeesLoading,
    updateFeesDone,
    updateFeesError,
    businessCompliance,
    fetchBusinessComplianceIsLoading,
    consultBusinessComplianceIsLoading,
    businessComplianceConsulted,
  } = useSelector(state => state.business);
  const { businessVerifyDocuments, businessVerifyDocumentsLoading } =
    useSelector(state => state.documents);
  const { country } = useSelector(state => state.config);
  const consultedDebtService = useSelector(
    state => state.debtService.consulted
  );
  const canConsultDebtService = useSelector(
    state => state.debtService.canConsult
  );
  let debtService = useSelector(state => state.debtService.debtService);
  debtService = business?.debtService || debtService;
  const {
    user: { roles },
    rules,
  } = useSelector(state => state.auth);
  const [
    currentEntityFeatures,
    handleEntityStatus,
    fetchEntityFeaturesIsLoading,
  ] = useEntityFeatures(BUSINESS, business?.id);
  const { updatedEntityFeatureIsLoading } = useSelector(
    state => state.entityFeature
  );

  const [showEditRatesDialog, setShowEditRatesDialog] = useState(false);
  const [showDebtServiceDialog, setShowDebtServiceDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [showComplianceDialog, setShowComplianceDialog] = useState(false);
  const [canUpdateAutoRate, setCanUpdateAutoRate] = useState(false);

  const {
    id: businessId,
    name: businessName,
    rut,
    exchangeEmail,
    rate,
    defaultRates,
    retention,
    identifier,
    hasTaxUserActive,
  } = business;

  useEffect(() => {
    dispatch(resetBusinessCompliance());
  }, [dispatch]);

  useEffect(() => {
    if (identifier) {
      dispatch(fetchBusinessCompliance(identifier, businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, identifier]);

  useEffect(() => {
    if (consultedDebtService) {
      setShowDebtServiceDialog(false);
      dispatch(resetConsultDebtService());
    }
  }, [dispatch, consultedDebtService, businessId, country, identifier]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchBusinessRates(businessId));
    }
  }, [businessId, dispatch]);

  useEffect(() => {
    if (!business) {
      history.push('/business');
    }
    if (savedBusiness) {
      dispatch(fetchBusiness(businessId));
      dispatch(resetBusinessFessFailure());
    }
    // eslint-disable-next-line
  }, [business, savedBusiness]);

  useEffect(() => {
    if (updateFeesDone) {
      setShowEditRatesDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFeesDone]);

  useEffect(() => {
    if (savedBusiness) {
      dispatch(fetchBusiness(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedBusiness]);

  useEffect(() => {
    if (businessComplianceConsulted) {
      setShowComplianceDialog(false);
    }
  }, [businessComplianceConsulted]);

  useEffect(() => {
    if (businessId) {
      dispatch(verifyStatusDocuments(businessId, BUSINESS));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  useEffect(() => {
    setCanUpdateAutoRate(
      checkAuth(roles, BUSINESS_UPDATE_SWITCH_AUTO_RATE, rules)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, rules]);

  // entityFeatures
  const autoRate =
    currentEntityFeatures?.[ENTITY_FEATURE_AUTOTASA.keyName]?.active ?? false;
  const confirmingFeature =
    currentEntityFeatures?.[ENTITY_FEATURE_CONFIRMING.keyName]?.active ?? false;

  const handleSubmitFees = (
    rateFees,
    hasConfirmingRate,
    retentionFees,
    devengoRates
  ) => {
    dispatch(
      updateBusinessFees({
        businessId,
        rate: rateFees,
        retention: retentionFees,
        confirmingRate: hasConfirmingRate,
        devengoRates,
        type: 'update',
      })
    );
  };

  const handleResetFees = () => {
    dispatch(updateBusinessFees({ businessId, type: 'reset' }));
  };

  const debtServiceData = () => {
    if (debtService && Object.keys(debtService).length) {
      const { totalAmount } = debtService;
      if (totalAmount < 0) {
        return (
          <Typography variant="body1" color="textSecondary">
            RFC sin giro comercial
          </Typography>
        );
      }

      return (
        <Tooltip
          title={
            debtService?.createdAt
              ? `Última actualización: ${dateFormatter(debtService.createdAt)}`
              : `No hay datos de Buró de Crédito`
          }
        >
          <Typography variant="body1" color="textSecondary">
            <Box
              color={totalAmount > 0 ? 'error.main' : ''}
              display="flex"
              alignContent="center"
            >
              <CountryFormatHelper
                value={totalAmount}
                countryId={country}
                variant="currency"
              />
            </Box>
          </Typography>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={canConsultDebtService ? '' : 'El cliente no ha firmado'}>
        <Typography variant="body1" color="textSecondary">
          -
        </Typography>
      </Tooltip>
    );
  };

  const handleClickMenu = (event, type) => {
    event.persist();
    setAnchorEl({ ...anchorEl, [type]: event.target });
  };

  const copyToClipboard = (value = null) => {
    copy(value);
    if (value) {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.DATA_COPIED, {
          variant: 'success',
        })
      );
    } else {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.COPY_DATA_FAILURE, {
          variant: 'error',
        })
      );
    }
  };

  const handleConsultCompliance = () => {
    const selectIdentifier = identifier || rut;
    dispatch(
      consultBusinessCompliance(selectIdentifier, {
        businessId,
        businessName,
      })
    );
    setAnchorEl({});
  };

  const complianceData = () => {
    let textCompliance = businessCompliance?.lastConsultCompliance
      ? 'Sin coincidencias'
      : null;
    if (businessCompliance?.complianceDetail) {
      const labelCompliance = businessCompliance?.complianceDetail.types;
      textCompliance = (
        <>
          <Typography variant="body1" color="textSecondary">
            Coincidencias en
          </Typography>

          <Typography variant="body1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightBold">{labelCompliance}</Box>
          </Typography>
        </>
      );
    }
    return (
      <Typography variant="body1" color="textSecondary">
        {textCompliance || '-'}
      </Typography>
    );
  };

  const handleConfirmingStatus = () => {
    handleEntityStatus(ENTITY_FEATURE_CONFIRMING);
  };

  const handleAutoRateChange = () => {
    handleEntityStatus(ENTITY_FEATURE_AUTOTASA);
  };

  const getIcon = value => (
    <Box color={value ? 'success.main' : 'error.main'}>
      {value ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
    </Box>
  );

  const statusRetention = defaultRates ? ' (Auto)' : ' (Custom)';
  const statusRate = defaultRates ? ' (Auto)' : ' (Custom)';

  const infoBusiness = [
    {
      title: 'RFC',
      content: (
        <Typography
          variant="body1"
          color="textSecondary"
          data-cy="businessDetailIdentifier"
        >
          {identifier || '-'}
        </Typography>
      ),
      icon: (
        <IconButton
          size="small"
          data-cy="businessDetailCopyIdentifierButton"
          onClick={() => copyToClipboard(identifier)}
        >
          <CopyIcon />
        </IconButton>
      ),
    },
    {
      title: 'Monto Buró de Crédito',
      content: debtServiceData(),
      icon: (
        <Can
          perform={BUSINESS_BURO_PERFORM}
          yes={() => (
            <Tooltip
              title={
                debtService?.createdAt
                  ? `Última actualización: ${dateFormatter(
                      debtService.createdAt
                    )}`
                  : `No hay datos de Buró de Crédito`
              }
            >
              <IconButton
                onClick={() => setShowDebtServiceDialog(true)}
                size="small"
                disabled={!canConsultDebtService}
              >
                <Box display="flex" alignContent="center">
                  <ReloadIcon />
                </Box>
              </IconButton>
            </Tooltip>
          )}
        />
      ),
    },
    {
      title: COMPLIANCE_LABEL,
      icon: (
        <Can
          perform={BUSINESS_COMPLIANCE_PERFORM}
          yes={() => (
            <MenuMoreOptions
              options={[
                <MenuItem
                  onClick={() => setShowComplianceDialog(true)}
                  icon={<ReloadIcon />}
                >
                  Consultar compliance
                </MenuItem>,
              ]}
              anchorEl={anchorEl.COMPLIANCE}
              handleCloseMenu={() => setAnchorEl({})}
              handleClickMenu={handleClickMenu}
              type={COMPLIANCE}
            />
          )}
        />
      ),
      content:
        fetchBusinessComplianceIsLoading ||
        consultBusinessComplianceIsLoading ? (
          <Skeleton variant="rect" width={98} height={20} />
        ) : (
          complianceData()
        ),
    },
    {
      title: 'Docs. de Empresa',
      icon: (
        <MenuMoreOptions
          options={[
            <MenuItem
              onClick={() => {
                history.push(`/mx/business/${businessId}#legal`);
              }}
              icon={<SettingsIcon />}
            >
              Gestionar documentos
            </MenuItem>,
            <MenuItem disabled>
              Buró crédito, domiciliación, contrato, KYC.
            </MenuItem>,
          ]}
          anchorEl={anchorEl.DOCUMENTS}
          handleCloseMenu={() => setAnchorEl({})}
          handleClickMenu={handleClickMenu}
          type="DOCUMENTS"
        />
      ),
      content: businessVerifyDocumentsLoading ? (
        <CircularProgress size={14} />
      ) : (
        getIcon(businessVerifyDocuments)
      ),
    },
    {
      title: 'Balance cuentas',
      content: null,
    },
    {
      title: 'Estados críticos',
      content: null,
    },
    {
      title: 'Contribuyente autorizado',
      content: getIcon(hasTaxUserActive),
    },
    {
      title: 'Estado de empresa',
      content: null,
    },
    {
      title: 'Ventas anuales',
      content: null,
    },
    {
      title: 'Retención',
      content: retention ? (
        <Typography color="textSecondary" variant="body1">
          <CountryFormatHelper
            value={retention}
            variant="percentage"
            countryId={country}
          />
          {statusRetention}
        </Typography>
      ) : null,
      collapse: true,
    },
    {
      title: 'Tasa',
      content: rate ? (
        <Typography color="textSecondary" variant="body1">
          <CountryFormatHelper
            value={rate}
            variant="percentage"
            countryId={country}
          />
          {statusRate}
        </Typography>
      ) : null,
      collapse: true,
    },
    {
      title: 'Confirming',
      content: fetchEntityFeaturesIsLoading ? (
        <CircularProgress size={25} />
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={confirmingFeature}
              onClick={handleConfirmingStatus}
              size="small"
              color="primary"
              name="checkedConfirming"
            />
          }
          label={confirmingFeature ? 'Activo' : 'Inactivo'}
        />
      ),
    },
    {
      title: (
        <Grid container alignItems="center">
          <Typography color="textPrimary" variant="subtitle2" component="div">
            <Box fontWeight="fontWeightBold">Auto Tasa</Box>
          </Typography>
          <Tooltip title="Al activar esta opción esta operación aplicará el modelo de tasas basado en el nivel de riesgo de cada factura.">
            <span>
              <HelpIcon fontSize="inherit" className={classes.help} />
            </span>
          </Tooltip>
        </Grid>
      ),
      content:
        fetchEntityFeaturesIsLoading || updatedEntityFeatureIsLoading ? (
          <CircularProgress size={25} />
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={autoRate}
                onClick={canUpdateAutoRate ? handleAutoRateChange : null}
                disabled={!canUpdateAutoRate}
                size="small"
                color="primary"
                name="checkedConfirming"
              />
            }
            label={autoRate ? 'Activo' : 'Inactivo'}
          />
        ),
    },
    {
      title: 'Correo de intercambio',
      content: (
        <Typography color="textSecondary" variant="body1">
          {exchangeEmail || '-'}
        </Typography>
      ),
    },
  ];

  return (
    <Grid item xs={12}>
      <Grid container>
        {infoBusiness.map(infoCard => {
          const { title, content, icon } = infoCard;
          const isSpecialCase = SPECIAL_CASES.some(
            specialCase => specialCase === title
          );
          return (
            <Box
              className={
                isSpecialCase
                  ? classes.containerItemsSizeLarge
                  : classes.containerItemsSizeStandar
              }
            >
              <Typography variant="body2" color="textPrimary" component="div">
                <Box
                  fontWeight="fontWeightBold"
                  className={classes.elementTitle}
                >
                  <Box className={classes.spacingRight}>{title}</Box>
                  {icon || null}
                </Box>
              </Typography>
              {content || (
                <Typography variant="body1" color="textSecondary">
                  -
                </Typography>
              )}
            </Box>
          );
        })}
      </Grid>
      <Grid container className={classes.containerButtons}>
        <Button
          className={classes.infoBussinessButtons}
          variant="contained"
          color="primary"
          startIcon={<FolderOpenIcon />}
          disabled
        >
          Declaración fiscal
        </Button>

        <Can
          perform={BUSINESS_EDIT_FEE_PERFORM}
          yes={() => (
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => {
                dispatch(resetBusinessFees());
                setShowEditRatesDialog(true);
              }}
              className={classes.infoBussinessButtons}
            >
              Editar fees
            </Button>
          )}
        />
      </Grid>

      {showEditRatesDialog && (
        <EditRatesDialog
          open={showEditRatesDialog}
          handleCloseDialog={() => setShowEditRatesDialog(false)}
          business={business}
          handleSubmit={handleSubmitFees}
          handleReset={handleResetFees}
          loading={updateFeesLoading}
          error={updateFeesError}
        />
      )}
      {showDebtServiceDialog && (
        <DebtServiceDialog
          saving={saving}
          open={showDebtServiceDialog}
          handleCloseDialog={() => setShowDebtServiceDialog(false)}
          handleSubmit={submitIdentifier =>
            dispatch(consultDebtService(businessId, BUSINESS, submitIdentifier))
          }
          debtServiceData={{
            name: businessName,
            reloadId: businessId,
            ...debtService,
          }}
        />
      )}
      {showComplianceDialog && (
        <ComplianceDialog
          open={showComplianceDialog}
          handleCloseDialog={() => setShowComplianceDialog(false)}
          handleSubmit={handleConsultCompliance}
          complianceInformation={{ ...businessCompliance, name: businessName }}
        />
      )}
    </Grid>
  );
};

BusinessDetailMX.propTypes = {
  business: PropTypes.objectOf(Object).isRequired,
};

export default BusinessDetailMX;
