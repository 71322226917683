import axios from 'axios';
import { settings } from '../config/settings';
import { COUNTRY_CODE_CL } from '../helpers/Constants';
import { enqueueSnackbar } from './notificationAction';
import {
  FETCH_COMPANY_NAME_BY_RUT_START,
  FETCH_COMPANY_NAME_BY_RUT_SUCCESS,
  FETCH_COMPANY_NAME_BY_RUT_FAILURE,
  CHECK_TAX_START,
  CHECK_TAX_SUCCESS,
  CHECK_TAX_FAILURE,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { t13s } from '../translationKeys/index';

export const fetchCompanyNameByRut = rut => dispatch => {
  dispatch({ type: FETCH_COMPANY_NAME_BY_RUT_START });
  axios
    .get('/api/siiuser/infoSiiByRut', {
      params: {
        rut,
      },
    })
    .then(res => {
      const resData = res.data;
      const name = resData.data?.name || resData.name || '';

      dispatch({
        type: FETCH_COMPANY_NAME_BY_RUT_SUCCESS,
        payload: { name },
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_COMPANY_NAME_BY_RUT_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: FETCH_COMPANY_NAME_BY_RUT_FAILURE,
        payload: { errorCode },
      });
    });
};

export const checkLastTaxScrapping = idBusiness => dispatch => {
  dispatch({
    type: CHECK_TAX_START,
  });
  axios
    .get(`/api/siiuser/${idBusiness}/check`, {
      params: {
        manual: true,
      },
    })
    .then(({ data }) => {
      dispatch({
        type: CHECK_TAX_SUCCESS,
        payload: data,
      });
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.CHECK_TAX_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({
        type: CHECK_TAX_FAILURE,
        payload: { errorCode },
      });
    });
};

export const startManualScrappe = (rut, idBusiness) => dispatch => {
  const { isApiGlobal } = settings[COUNTRY_CODE_CL];
  axios
    .get(`/api/siiuser/updateData/${isApiGlobal ? idBusiness : rut}`, {
      params: {
        manual: true,
      },
    })
    .then(() => {
      dispatch(checkLastTaxScrapping(idBusiness));
    })
    .catch(() => {
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.UPDATE_SCRAPPER_FAILURE, {
          variant: 'error',
        })
      );
    });
};

export const statusUpdateData = status => async dispatch => {
  if (status) {
    dispatch(
      enqueueSnackbar(t13s.NOTIFICATION.SCRAPPER_UPDATED, {
        variant: 'success',
      })
    );
  } else {
    dispatch(
      enqueueSnackbar(t13s.NOTIFICATION.UPDATE_SCRAPPER_FAILURE, {
        variant: 'error',
      })
    );
  }
};
