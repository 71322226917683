import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChangePassword = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C14.401 22.0033 16.7224 21.1393 18.537 19.567L17.54 17.772C16.2099 19.0488 14.4795 19.827 12.6416 19.9749C10.8038 20.1228 8.97127 19.6313 7.45408 18.5836C5.93689 17.5358 4.82818 15.9962 4.31549 14.2251C3.80281 12.4541 3.91762 10.5603 4.64052 8.86408C5.36342 7.16791 6.65002 5.77351 8.28268 4.91677C9.91533 4.06002 11.7938 3.79352 13.6003 4.16236C15.4069 4.53119 17.0305 5.51272 18.1967 6.94089C19.3628 8.36906 19.9998 10.1562 20 12H17L20.19 17.74C21.33 16.116 22 14.136 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22ZM15.5556 10H14.6667V9.57143C14.6667 8.88944 14.3857 8.23539 13.8856 7.75315C13.3855 7.27092 12.7072 7 12 7C11.2928 7 10.6145 7.27092 10.1144 7.75315C9.61428 8.23539 9.33333 8.88944 9.33333 9.57143V10H8.44444C8.32657 10 8.21352 10.0452 8.13017 10.1255C8.04683 10.2059 8 10.3149 8 10.4286V15.5714C8 15.6851 8.04683 15.7941 8.13017 15.8745C8.21352 15.9548 8.32657 16 8.44444 16H15.5556C15.6734 16 15.7865 15.9548 15.8698 15.8745C15.9532 15.7941 16 15.6851 16 15.5714V10.4286C16 10.3149 15.9532 10.2059 15.8698 10.1255C15.7865 10.0452 15.6734 10 15.5556 10ZM13.7778 9.57143V10H10.2222V9.57143C10.2222 9.11677 10.4095 8.68074 10.7429 8.35925C11.0763 8.03775 11.5285 7.85714 12 7.85714C12.4715 7.85714 12.9237 8.03775 13.2571 8.35925C13.5905 8.68074 13.7778 9.11677 13.7778 9.57143Z"
      />
    </SvgIcon>
  );
};

export default ChangePassword;
