import { gql } from '@apollo/client';

export const NOTIFICATIONS_SUSCRIPTION = gql`
  subscription UserNotifications($id: String!) {
    onNotificationCreated(id: $id) {
      id
      status
      subscriptions {
        id
        createdAt
        status
        subscriptionType
        payload
      }
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query GerUserNotifications($subscriptionId: ID!) {
    notificationsById(subscriptionId: $subscriptionId) {
      id
      status
      subscriptions {
        id
        createdAt
        status
        subscriptionType
        payload
      }
    }
  }
`;

export const UPDATE_BELL_STATUS = gql`
  mutation UpdateStatusSubscription($subscriptionId: ID!) {
    updateStatusSubscription(subscriptionId: $subscriptionId) {
      description
      status
    }
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation UpdateStatusMessage($messageId: ID!, $subscriptionId: ID!) {
    updateStatusMessage(
      messageId: $messageId
      subscriptionId: $subscriptionId
    ) {
      description
      status
    }
  }
`;
