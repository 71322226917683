import { Grid, styled, alpha } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { WarningCircleIcon } from '../../../../components/icons';
import { Text } from '../../../commons/formatters';

const RequerimentsBlock = styled(Grid)(({ theme }) => ({
  background: alpha(theme.palette.payments.stateText1, 0.2),
  borderRadius: 15,
  padding: 17,
  marginBottom: 25,
}));

const Title = styled(Text)(({ theme }) => ({
  color: theme.palette.warning.dark,
  lineHeight: '21px',
}));

const Warnings = styled('ul')(() => ({
  padding: 0,
  listStylePosition: 'inside',
  marginTop: 10,
}));

const WarningItem = styled('li')(({ theme }) => ({
  color: theme.palette.warning.dark,
  fontSize: 14,
}));

const ChangeStatusRequeriments = ({ requirements }) => {
  return (
    <RequerimentsBlock container wrap="nowrap" spacing={2}>
      <Grid item>
        <WarningCircleIcon />
      </Grid>
      <Grid item>
        <Title variant="subtitle1" fontWeight="bold">
          ¿Quieres cambiar el estado? Ten en cuenta que la operación tiene:
        </Title>
        <Warnings>
          {requirements.map(requirement => (
            <WarningItem variant="subtitle2">{requirement}</WarningItem>
          ))}
        </Warnings>
      </Grid>
    </RequerimentsBlock>
  );
};

ChangeStatusRequeriments.propTypes = {
  requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ChangeStatusRequeriments;
