type PostCreditLineProposalParams = {
  identifier?: string;
  country: string;
};

export type CreditLineProposal = {
  creditline: number;
} | null;

const postCreditLineProposalService = async ({
  identifier,
  country,
}: PostCreditLineProposalParams): Promise<CreditLineProposal> => {
  const endpoint = process.env.REACT_APP_CREDITLINEPROPOSAL_URL!;

  const data = {
    identifier,
    country,
  };

  try {
    const request = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await request.json() as { body: string };
    if (response) {
      return JSON.parse(response.body) as CreditLineProposal;
    }

    return null;
  } catch {
    return null;
  }
};

export default postCreditLineProposalService;
