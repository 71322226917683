import { useSelector } from 'react-redux';
import { ADMIN_ROLE, TREASURY_ROLE } from '../../../helpers/Constants';

const useRoles = () => {
  const { roles } = useSelector(state => state.auth.user);

  const isAdmin = roles.some(role => role.rolename === ADMIN_ROLE);
  const isTreasurySupervisor = roles.some(
    role => role.rolename === TREASURY_ROLE && role.isSupervisor === true
  );

  return {
    isAdmin,
    isTreasurySupervisor,
  };
};

export default useRoles;
