import React from 'react';
import PropTypes from 'prop-types';
import { SelectValidator } from 'react-material-ui-form-validator';
import { MenuItem } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const Select = ({ label, value, name, onChange, options }) => {
  return (
    <SelectValidator
      fullWidth
      variant="outlined"
      label={label}
      type="string"
      name={name}
      value={value}
      onChange={onChange}
    >
      {options.map((o, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={idx} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </SelectValidator>
  );
};

Select.defaultProps = {
  value: '',
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};
