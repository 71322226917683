import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const checkPfxService = async businessId => {
  try {
    const { data } = await axios.get(`/api/business/${businessId}/pfx/check`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { checkPfxService };
