import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Tooltip, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Container from './Container';
import CountryFormatHelper from './CountryFormatHelper';
import { features } from '../../config/features';
import { COUNTRY_CODE_CL } from '../../helpers/Constants';

const useStyles = makeStyles(theme => ({
  container: {
    gridTemplateColumns: props => (props.wide ? '1fr' : 'repeat(4, 1fr)'),
    gridGap: props => (props.wide ? 15 : '30px 60px'),
  },
  dataRow: {
    gridTemplateColumns: props =>
      props.wide ? `${props.displayInDialog ? 100 : 160}px 1fr` : '1fr',
    gridGap: 10,
    color: theme.palette.grey[700],
  },
  data: {
    justifySelf: props => props.justify || null,
  },
  green: {
    justifySelf: props => props.justify || null,
    color: theme.palette.success.main,
  },
  red: {
    justifySelf: props => props.justify || null,
    color: theme.palette.error.main,
  },
  displayInLineBlock: {
    display: 'inline-block',
  },
}));

const DataList = props => {
  const { data, placeholder, isLoading, showLabel, requestTransferAmount } =
    props;
  const classes = useStyles(props);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { transferDatePyme, transferAmountPyme } = useSelector(
    state => state.order
  );
  const country = useSelector(state => state.config.country);
  const countryFeatures = features[country];

  useEffect(() => {
    if (
      id &&
      countryFeatures.orderActions.fetchTransferAmountPyme &&
      requestTransferAmount
    ) {
      setOpen(true);
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <Container>
      {data && data.length ? (
        <Container className={classes.container}>
          {data.map((row, index) => {
            const { label, type, className, prefix, currency } = row;

            let { data } = row;
            if (
              country === COUNTRY_CODE_CL &&
              label === 'Fecha de transferencia' &&
              transferDatePyme
            ) {
              data = moment.utc(transferDatePyme).format('DD MMMM YYYY');
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Container key={index} className={classes.dataRow}>
                {showLabel ? (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">{label}</Box>
                  </Typography>
                ) : (
                  ''
                )}
                {isLoading && (
                  <Skeleton variant="rect" width={130} height={20} />
                )}
                {!isLoading &&
                  (open && label === 'Monto de transferencia' ? (
                    <Tooltip
                      title={
                        data === transferAmountPyme ? (
                          'ok'
                        ) : (
                          <CountryFormatHelper
                            value={transferAmountPyme}
                            variant="currency"
                            countryId={country}
                          />
                        )
                      }
                      className={classes.data}
                    >
                      <Box
                        color={
                          data === transferAmountPyme
                            ? 'success.main'
                            : 'error.main'
                        }
                      >
                        <Typography
                          variant="body2"
                          component="div"
                          data-cy={row['data-cy']}
                        >
                          <Box fontWeight="fontWeightBold">
                            <CountryFormatHelper
                              value={data}
                              variant="currency"
                              countryId={country}
                            />
                          </Box>
                        </Typography>
                      </Box>
                    </Tooltip>
                  ) : (
                    <>
                      {type === 'currency' ? (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={clsx(classes.data, className)}
                          component="div"
                          data-cy={row['data-cy']}
                        >
                          <Box fontWeight="fontWeightBold">
                            {prefix} &nbsp;
                            <CountryFormatHelper
                              value={data || 0}
                              variant="currency"
                              countryId={country}
                              currency={currency}
                            />
                          </Box>
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={classes.data}
                          component="div"
                          data-cy={row['data-cy']}
                        >
                          <Box
                            fontWeight="fontWeightBold"
                            className={classes.displayInLineBlock}
                          >
                            {type === 'percentage' ? (
                              <CountryFormatHelper
                                value={data || placeholder}
                                countryId={country}
                                variant="percentage"
                              />
                            ) : (
                              data || placeholder || '-'
                            )}
                          </Box>
                        </Typography>
                      )}
                    </>
                  ))}
              </Container>
            );
          })}
        </Container>
      ) : (
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.data}
        >
          No hay información disponible.
        </Typography>
      )}
    </Container>
  );
};

DataList.defaultProps = {
  placeholder: null,
  isLoading: false,
  showLabel: true,
  requestTransferAmount: true,
};

DataList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['percentage', 'currency']),
      data: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.objectOf(PropTypes.any), // date
      ]).isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  showLabel: PropTypes.bool,
  requestTransferAmount: PropTypes.bool,
};

export default withTheme(DataList);
