/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const TAB_CREDIT_LINE = 'creditline';

export const creditLineTab = Object.freeze({
  label: 'Línea de crédito',
  value: TAB_CREDIT_LINE,
});

export const creditLineRiskCardTab = Object.freeze({
  error: 'CreditLineNotFound',
  message: 'Por asignar',
});

export const riskLink = {
  prod: 'http://credit-line-manager.s3-website-sa-east-1.amazonaws.com',
  dev: 'https://docs.google.com/spreadsheets/d/dev',
};

export const creditPropCardMessage = 'No registra';

export const operationId = 'a30d4fdb-dab6-40e6-a250-d3d037c2c137';
export const riskId = 'e505def2-44cb-4976-ab4e-352f633e438e';
export const legalId = 'a47eb31b-43a9-46e8-9896-45cb728c3196';
