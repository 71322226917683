import React, { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Box, Card, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import TableBusinessCreditLine from './TableBusinessCreditLine';
import i18next from '../i18n/setupTranslations';
import CreditLineRiskCard from './card/CreditLineRiskCard';
import CreditLineProposalCard from './card/CreditLineProposalCard';
import type { Country, BusinessSelector } from '../schemas';
import CreditLineEnrollmentTimeCard from './card/CreditLineEnrollmentTimeCard';
import CreditLineAssignedKam from './card/CreditLineAssignedKam';
import { RootState } from '../../../reducers/rootReducer';
import * as TableDataAndHistory from './TableDataAndHistory';
import WorkflowSummarySelected from './TableBusinessCreditLine/workflowSummarySelected/WorkflowSummarySelected';
import useWorkflowsWithState from '../querys/useWorkflowsWithState';
import useGetEntity from '../querys/useGetEntity';
import { riskId } from '../constants';

export const i18n = i18next;

type Props = {
  country: Country;
};

const BusinessCreditLine: React.FC<Props> = (props: Props) => {
  const { business } = useSelector(
    (state: RootState) => state.business as BusinessSelector,
  );
  const [selectedId, setSelectedId] = useState<string>();

  const { isEntityLoading, entityData } = useGetEntity({
    params: {
      refId: business?.identifier,
    },
    fieldsResponse: {
      enrolledAt: true,
    },
  });

  const { workflowsStateData, refetch } = useWorkflowsWithState({
    params: {
      entityRefId: business?.identifier,
      workflowIds: [riskId],
    },
    fieldsResponse: {
      id: true,
      comment: true,
      transitionType: true,
    },
  });

  const [latestRiskTransition] = workflowsStateData?.workflowsWithState || [null];
  const isProntoPagoAllowed = latestRiskTransition?.transitionType === 'PRONTO_PAGO_ALLOWED';

  return (
    <I18nextProvider i18n={i18n}>
      <Box m={4}>
        <Card style={{ borderRadius: 16, boxShadow: 'unset' }}>
          <Box display="flex">
            <CreditLineEnrollmentTimeCard
              isLoading={isEntityLoading}
              enrolledAt={entityData?.entity?.enrolledAt}
            />
            <CreditLineProposalCard country={props.country} />
            <CreditLineRiskCard
              isProntoPagoAllowed={isProntoPagoAllowed}
              country={props.country}
            />
            <CreditLineAssignedKam />

          </Box>
        </Card>
      </Box>
      <Grid container spacing={0}>
        <TableDataAndHistory.Root
          onToggle={(id, isToggle) => {
            if (isToggle) {
              setSelectedId(id);
            } else {
              setSelectedId(undefined);
            }
          }}
        >
          <TableDataAndHistory.ContentLeft>
            <TableBusinessCreditLine
              country={props.country}
              businessIdentifier={business?.identifier || ''}
              onSuccesChangeState={workflowId => {
                if (workflowId === selectedId) {
                  setSelectedId(undefined);
                  setSelectedId(workflowId);
                }

                refetch();
              }}
            />
          </TableDataAndHistory.ContentLeft>
          <TableDataAndHistory.ContentRight>
            {selectedId && (
              <WorkflowSummarySelected
                workFlowId={selectedId}
                businessIdentifier={business?.identifier || ''}
              />
            )}
          </TableDataAndHistory.ContentRight>
        </TableDataAndHistory.Root>
      </Grid>
    </I18nextProvider>
  );
};

export default BusinessCreditLine;
