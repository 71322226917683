import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import {
  Filters,
  OperationList,
  Pagination,
} from '../../../commons/interfaces/ARInterfaces';

interface Props {
  filters: Filters;
  page: number;
  size: number;
}

interface Response {
  data: OperationList[];
  pagination: Pagination;
}

const fetchOperationsService = async ({ filters, page, size }: Props) => {
  try {
    const { data } = await axios.get<Response>('/api/orders', {
      params: {
        ...filters,
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error(UNKNOWN_ERROR);
    }
  }
};

export default fetchOperationsService;
