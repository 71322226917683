import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';
import { Pagination } from '../../../commons/interfaces/global';
import {
  CollectionsSourceTypes,
  DebtManagementResults,
  DebtManagementSubTypes,
  DebtManagementTypes,
  GetManagementsFilters,
  Management,
  ManagementsFetchedData,
  PayerCategorizationResults,
  PayerCategorizationTypes,
} from '../../../commons/interfaces/managements';

type fetchManagementsProps = {
  debtsIds?: number[];
  identifiers?: string[];
  order: string;
  filter?: GetManagementsFilters;
  pagination: Pagination;
};
export const fetchManagements = async (
  props: fetchManagementsProps
): Promise<ManagementsFetchedData> => {
  const { debtsIds, identifiers, pagination, order, filter } = props;
  try {
    const { data } = await axios.post<ManagementsFetchedData>(
      '/api/collection/managements/get',
      {
        ...(debtsIds ? { debtsIds } : {}),
        ...(identifiers ? { identifiers } : {}),
      },
      {
        params: {
          order,
          page: pagination.page,
          size: pagination.size,
          ...(filter ? { filter } : {}),
        },
      }
    );
    return { data: parseManagements(data.data), pagination: data.pagination };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.message);
    }
    throw new Error(UNKNOWN_ERROR);
  }
};

const parseManagements = (response: any[]): Management[] =>
  response.map(management => ({
    sourceType: management.sourceType as CollectionsSourceTypes,
    sourceId: String(management.sourceId),
    appliedOn: String(management.appliedOn),
    type:
      management.sourceType === CollectionsSourceTypes.orderInvoiceId
        ? (management.type as DebtManagementTypes)
        : (management.type as PayerCategorizationTypes),
    subType: management.subType as DebtManagementSubTypes,
    result:
      management.sourceType === CollectionsSourceTypes.orderInvoiceId
        ? (management.result as DebtManagementResults)
        : (management.result as PayerCategorizationResults),
    detail: String(management.detail),
    createdBy: management.createdBy,
    createdAt: new Date(management.createdAt),
  }));
