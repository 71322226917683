import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '../Drawer';
import { PayerForm } from './PayerForm';

const PayerFilterComponent = ({
  fieldsToHide,
  form,
  onClose,
  onSubmit,
  open,
  showInvoiceFields,
}) => {
  return (
    <Drawer open={open} onClose={onClose}>
      <PayerForm
        fieldsToHide={fieldsToHide}
        form={form}
        onClose={onClose}
        onSubmit={onSubmit}
        showInvoiceFields={showInvoiceFields}
      />
    </Drawer>
  );
};

PayerFilterComponent.defaultProps = {
  fieldsToHide: {},
  open: false,
  showInvoiceFields: false,
};

PayerFilterComponent.propTypes = {
  fieldsToHide: PropTypes.objectOf(Object),
  form: PropTypes.objectOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showInvoiceFields: PropTypes.bool,
};

export const PayerFilter = memo(PayerFilterComponent);
