export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
export const trackSegment = (msg, payload = null) => {
  if (payload) {
    window.analytics.track(msg, payload);
  } else {
    window.analytics.track(msg);
  }
};

export const identify = (businessId, identifyPayload = null) => {
  if (identifyPayload) {
    window.analytics.identify(businessId, identifyPayload);
  } else {
    window.analytics.identify(businessId);
  }
};

export const identifyAndTrack = (
  businessId,
  identifyPayload,
  trackMsg,
  trackPayload = null
) => {
  if (identifyPayload) {
    window.analytics.identify(businessId, identifyPayload);
  } else {
    identify(businessId);
  }
  if (trackPayload) {
    window.analytics.track(trackMsg, trackPayload);
  } else {
    window.analytics.track(trackMsg);
  }
};

export const analyticsReset = () => {
  window.analytics.reset();
};
