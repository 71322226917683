import { useQuery } from 'react-query';
import { PayerDebts } from '../../../commons/interfaces/payerDebts';
import { fetchPayerById } from '../services';

const useFetchPayerById = (payerId: string, sortBy: string) => {
  const placeholderData = {
    payerIdentifier: payerId,
    payerName: '',
    debtsQuantity: 0,
    expiredDebtsQuantity: 0,
    totalAmount: 0,
    payerDebts: [],
  };

  const {
    isLoading,
    isPlaceholderData,
    data,
    refetch,
    status,
    error,
    isError,
  } = useQuery<PayerDebts>(
    ['getPayerById', payerId, sortBy],
    async () => fetchPayerById(payerId, sortBy),
    {
      placeholderData,
      enabled: !!payerId,
    }
  );

  return {
    data,
    isLoading: isLoading || isPlaceholderData,
    refetch,
    status,
    error,
    isError,
  };
};

export { useFetchPayerById };

export default { useFetchPayerById };
