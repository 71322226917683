import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  IMPERSONATE_USERS_START,
  IMPERSONATE_USERS_SUCCESS,
  EDIT_USER_START,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  FETCH_PERFORM_START,
  FETCH_PERFORM_SUCCESS,
  FETCH_PERFORM_FAILURE,
  REGISTER_OR_UPDATE_ROLE_START,
  REGISTER_OR_UPDATE_ROLE_SUCCESS,
  REGISTER_OR_UPDATE_ROLE_FAILURE,
  DISABLE_ROLE_START,
  DISABLE_ROLE_SUCCESS,
  DISABLE_ROLE_FAILURE,
  SELECT_ROLE,
  WS_REGISTER_ROLE,
  RESET_USER_ERROR,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from '../actions/types';

const initialState = {
  roles: [],
  performs: [],
  usersError: {},
  loading: false,
  selectedRole: null,

  users: [],
  fetchUsersIsLoading: false,

  pagination: {
    page: 1,
    pageSize: 20,
    totalData: 0,
  },

  updateUserIsLoading: false,
  userWasUpdated: true,
  updateUserError: null,

  registerUserIsLoading: false,
  userWasRegistered: false,

  updateOrRegisterRoleIsLoading: false,

  deleteRoleIsLoading: false,
};

const getListRoles = (isNew, rolesState, listRoles, selectedRoleState) => {
  let roles = rolesState;
  if (!isNew) {
    roles = rolesState.map(roleState => {
      const { id, isSupervisor } = roleState;
      const roleFound = listRoles.find(
        ({ id: roleId, isSupervisor: roleIsSupervisor }) =>
          roleId === id && roleIsSupervisor === isSupervisor
      );
      return roleFound || roleState;
    });
  }

  const selectedRole = listRoles.find(
    ({ id, hasSupervisor, isSupervisor }) =>
      (isNew && !hasSupervisor) ||
      (isNew && hasSupervisor && isSupervisor) ||
      (!isNew &&
        id === selectedRoleState.id &&
        isSupervisor === selectedRoleState.isSupervisor)
  );
  const newRoles = isNew ? [...roles, selectedRole] : roles;
  return { roles, selectedRole, newRoles };
};
const updateUserStart = state => {
  return {
    ...state,
    updateUserIsLoading: true,
    userWasUpdated: false,
    updateUserError: null,
  };
};
const updateUserSuccess = state => {
  return {
    ...state,
    updateUserIsLoading: false,
    userWasUpdated: true,
  };
};
const updateUserFailure = (state, payload) => {
  return {
    ...state,
    updateUserIsLoading: false,
    userWasUpdated: false,
    updateUserError: payload.errorCode,
  };
};

const fetchUsersStart = state => {
  return {
    ...state,
    fetchUsersIsLoading: true,
  };
};
const fetchUsersSuccess = (state, payload) => {
  const { users, roles } = payload;

  return {
    ...state,
    users: users.data,
    roles,
    fetchUsersIsLoading: false,
    pagination: users.pagination,
  };
};
const fetchUsersFailure = state => {
  return {
    ...state,
    users: [],
    roles: [],
    fetchUsersIsLoading: false,
  };
};

const impersonateUsersStart = state => {
  return {
    ...state,
  };
};
const impersonateUsersSuccess = (state, payload) => {
  return {
    ...state,
    token: payload.token,
  };
};

const registerUserStart = state => {
  return {
    ...state,
    registerUserIsLoading: true,
    userError: null,
    userWasRegistered: false,
  };
};
const registerUserSuccess = (state, payload) => {
  const { users } = payload;
  const newUsers = [users[0], ...state.users];
  return {
    ...state,
    registerUserIsLoading: false,
    users: newUsers,
    userWasRegistered: true,
  };
};
const registerUserFailure = (state, payload) => {
  return {
    ...state,
    registerUserIsLoading: false,
    userError: payload.errorCode,
  };
};

const editUserStart = state => {
  return { ...state, updateUserIsLoading: true, userError: null };
};
const editUserSuccess = (state, payload) => {
  const users = state.users.map(user => {
    if (user.id === payload.user.id) {
      return payload.user;
    }
    return user;
  });
  return { ...state, updateUserIsLoading: false, users };
};
const editUserFailure = (state, payload) => {
  return { ...state, updateUserIsLoading: false, userError: payload.errorCode };
};

const fetchPerformStart = state => {
  return { ...state, loading: true };
};
const fetchPerformSuccess = (state, payload) => {
  return { ...state, performs: payload.data.performs, loading: false };
};
const fetchPerformFailure = state => {
  return { ...state, loading: false };
};

const registerOrUpdateRoleStart = state => {
  return { ...state, updateOrRegisterRoleIsLoading: true };
};
const registerOrUpdateRoleSuccess = (state, payload) => {
  const { roles: rolesState, selectedRole: selectedRoleState = {} } = state;
  const {
    data: { roles: rolesPayload, isNew },
  } = payload;
  const { selectedRole, newRoles } = getListRoles(
    isNew,
    rolesState,
    rolesPayload,
    selectedRoleState
  );

  return {
    ...state,
    roles: newRoles,
    selectedRole,
    updateOrRegisterRoleIsLoading: false,
  };
};
const registerOrUpdateRoleFailure = state => {
  return { ...state, updateOrRegisterRoleIsLoading: false };
};

const wsRegisterRole = (state, payload) => {
  let { roles } = state;
  const {
    data: { roles: newRoles },
  } = payload;
  const [newRole] = newRoles;
  const alreadyPresent = roles.some(role => role.id === newRole.id);
  if (!alreadyPresent) {
    roles = [...roles, ...newRoles];
  }
  return { ...state, roles };
};

const disableRoleStart = state => {
  return { ...state, deleteRoleIsLoading: true };
};

const disableRoleSuccess = (state, payload) => {
  let { roles } = state;
  const {
    data: { id, users },
  } = payload;
  roles = roles.filter(role => role.id !== Number(id));
  return {
    ...state,
    users,
    roles,
    selectedRole: null,
    deleteRoleIsLoading: false,
  };
};

const disableRoleFailure = state => {
  return { ...state, deleteRoleIsLoading: false };
};

const selectRole = (state, payload) => {
  return { ...state, selectedRole: payload.role };
};

const resetUserError = state => {
  return {
    ...state,
    userError: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_START:
      return updateUserStart(state);
    case UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case UPDATE_USER_FAILURE:
      return updateUserFailure(state, payload);

    case FETCH_USERS_START:
      return fetchUsersStart(state);
    case FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, payload);
    case FETCH_USERS_FAILURE:
      return fetchUsersFailure(state);

    case IMPERSONATE_USERS_START:
      return impersonateUsersStart(state);
    case IMPERSONATE_USERS_SUCCESS:
      return impersonateUsersSuccess(state, payload);

    case REGISTER_USER_START:
      return registerUserStart(state);
    case REGISTER_USER_SUCCESS:
      return registerUserSuccess(state, payload);
    case REGISTER_USER_FAILURE:
      return registerUserFailure(state, payload);

    case EDIT_USER_START:
      return editUserStart(state);
    case EDIT_USER_SUCCESS:
      return editUserSuccess(state, payload);
    case EDIT_USER_FAILURE:
      return editUserFailure(state, payload);

    case FETCH_PERFORM_START:
      return fetchPerformStart(state);
    case FETCH_PERFORM_SUCCESS:
      return fetchPerformSuccess(state, payload);
    case FETCH_PERFORM_FAILURE:
      return fetchPerformFailure(state);

    case REGISTER_OR_UPDATE_ROLE_START:
      return registerOrUpdateRoleStart(state);
    case REGISTER_OR_UPDATE_ROLE_SUCCESS:
      return registerOrUpdateRoleSuccess(state, payload);
    case REGISTER_OR_UPDATE_ROLE_FAILURE:
      return registerOrUpdateRoleFailure(state);

    case WS_REGISTER_ROLE:
      return wsRegisterRole(state, payload);

    case DISABLE_ROLE_START:
      return disableRoleStart(state);
    case DISABLE_ROLE_SUCCESS:
      return disableRoleSuccess(state, payload);
    case DISABLE_ROLE_FAILURE:
      return disableRoleFailure(state);

    case SELECT_ROLE:
      return selectRole(state, payload);

    case RESET_USER_ERROR:
      return resetUserError(state);

    default:
      return state;
  }
};
