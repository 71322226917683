import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  Button,
  IconButton,
  Link,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { features } from '../../config/features';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import DocumentsTable from '../tables/DocumentsTable';
import {
  fetchDocuments,
  verifyStatusDocuments,
} from '../../actions/documentActions';
import { getBillDocuments } from '../../actions/orderActions';
import { getOrCreatePaymentOrderDetails } from '../../actions/paymentOrderDetailActions';
import CreateDocumentDialog from '../dialogs/CreateDocumentDialog';
import Panel from './Panel';
import {
  MIMETYPE_IMG_DOC_XLSX_XML,
  COUNTRY_CODE_MX,
  ORDER_TYPES_LABEL,
} from '../../helpers/Constants';
import CountryFormatHelper from './CountryFormatHelper';
import { settings } from '../../config/settings';
import { Edit as EditIcon } from '../icons';
import PayrollCurrencyDialog from '../dialogs/PayrollCurrencyDialog';
import { fetchCriticalStatusInfo } from '../../actions/businessAction';
import Skeleton from './Skeleton';
import OperationsDebtDrawer from '../drawers/OperationsDebtDrawer';
import RequirementsTab from '../../AR/commons/components/RequirementsTab';

const DOCUMENTS = 'documents';
const BACKUP = 'BACKUP';
const ATTRIBUTES = 'attributes';
const ORDER = 'order';
const WALLET_STATUS = 'walletStatus';
const REQUIREMENTS = 'requirements';

const useStyles = makeStyles({
  tabsStyles: {
    borderBottom: '1px solid #E3E1E8',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: convertSpacingToCss('xl lg'),
  },
  containerDocuments: {
    paddingBottom: 20,
  },
  containerButtonAddDocument: {
    marginLeft: 'auto',
    marginRight: 10,
    marginTop: 8,
  },
  containerItemCollection: {
    padding: '16px 24px 16px 24px',
    border: '1px solid #E8E6EF',
    borderRadius: '8px',
    marginBottom: '20px',
    marginRight: '10px',
  },
  criticalStateBlockMain: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  criticalStateBlockSecondary: {
    fontSize: 14,
    color: '#8D8A96',
    fontWeight: 'normal',
  },
  criticalStateBlockPlaceholder: {
    padding: '63px 0px 0px 0px',
  },
  linkShowOperation: {
    textDecoration: 'underline',
  },
});

const AttributeTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
});

const AttributeRow = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  marginLeft: 15,
  color: '#8D8A96',
});

const AttributeCurrency = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  color: '#312F37',
});

const ContainerRow = styled(Box)(({ isLoading = false, lastChild }) => ({
  width: 265,
  borderBottom: isLoading || lastChild ? 'none' : '1px solid #CDCAD8',
  padding: 10,
}));

const OrderLegal = ({ isCeded, isVerified, hasFundFinancing, orderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    orderDocuments = [],
    orderDocumentsIsLoading,
    documentWasCreate,
    createDocumentIsLoading,
    createDocumentError,
    orderVerifyDocuments,
    orderVerifyDocumentsLoading,
    loadDocumentsIsLoading,
  } = useSelector(state => state.documents);
  const { invoiceDocuments, fetchInvoiceDocumentsIsLoading, updatedOrder } =
    useSelector(state => state.order);
  const {
    business: { id: businessId },
    criticalStatusInfo,
    criticalStatusInfoIsLoading,
  } = useSelector(state => state.business);
  const { attributes, paymentOrderDetailsIsLoading } = useSelector(
    state => state.paymentOrderDetails
  );
  const { orderIsFullyFinancedLoading } = useSelector(state => state.funds);
  const { country } = useSelector(state => state.config);

  const [stateTab, setStateTab] = useState(ATTRIBUTES);
  const [showCreateDocumentDialog, setShowCreateDocumentDialog] =
    useState(false);
  const [showCreateBackupDocumentDialog, setShowCreateBackupDocumentDialog] =
    useState(false);
  const [showPayrollCurrencyDialog, setShowPayrollCurrencyDialog] =
    useState(false);
  const countryFeatures = features[country];

  const defaultCurrencyCode = settings[country].currencyCode;

  const [showOperationsDebtDrawer, setShowOperationsDebtDrawer] =
    useState(false);
  const [orderInvoicesDebtDrawerConfig, setOrderInvoicesDebtDrawerConfig] =
    useState({
      debtType: null,
      orderInvoiceIds: [],
    });

  useEffect(() => {
    if (orderId && stateTab === DOCUMENTS)
      dispatch(fetchDocuments(orderId, ORDER));
  }, [orderId, stateTab, dispatch]);

  useEffect(() => {
    if (orderId && stateTab === DOCUMENTS && businessId) {
      dispatch(getBillDocuments(businessId, orderId));
    }
  }, [orderId, stateTab, dispatch, businessId]);

  useEffect(() => {
    if (orderId && stateTab === BACKUP) {
      dispatch(fetchDocuments(orderId, ORDER, { type: BACKUP }));
    }
  }, [orderId, stateTab, dispatch]);

  useEffect(() => {
    if (orderId && stateTab === BACKUP && businessId)
      dispatch(getBillDocuments(businessId, orderId));
  }, [orderId, stateTab, dispatch, businessId]);

  useEffect(() => {
    if (documentWasCreate) {
      setShowCreateDocumentDialog(false);
      setShowCreateBackupDocumentDialog(false);
    }
  }, [documentWasCreate]);

  useEffect(() => {
    if (orderId && stateTab === ATTRIBUTES) {
      dispatch(getOrCreatePaymentOrderDetails(orderId));
      if (countryFeatures.orderActions.orderLegal.documents.showTab)
        dispatch(verifyStatusDocuments(orderId, ORDER));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, stateTab, loadDocumentsIsLoading]);

  useEffect(() => {
    if (businessId && stateTab === WALLET_STATUS) {
      dispatch(fetchCriticalStatusInfo(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, stateTab]);

  useEffect(() => {
    const operationType = updatedOrder?.orderType || updatedOrder?.type;
    const orderStatus = updatedOrder?.status;
    const { requirements } = countryFeatures.orderActions.orderLegal;
    if (
      requirements.showTab &&
      requirements.availableOperationTypes.includes(operationType) &&
      requirements?.availableOperationStatuses.includes(orderStatus)
    ) {
      setStateTab(REQUIREMENTS);
    } else {
      setStateTab(ATTRIBUTES);
    }
  }, [updatedOrder, countryFeatures.orderActions.orderLegal]);

  const generateDataForTab = value => {
    return {
      id: `wrapped-tab-${value}`,
      'aria-controls': `wrapped-tabpanel-${value}`,
    };
  };

  const getTabs = () => {
    const operationType = updatedOrder?.orderType || updatedOrder?.type;
    const orderStatus = updatedOrder?.status;

    const { requirements, attributtes, documents, walletStatus } =
      countryFeatures.orderActions.orderLegal;
    const tabs = [];

    if (
      requirements?.showTab &&
      requirements?.availableOperationStatuses.includes(orderStatus) &&
      requirements?.availableOperationTypes.includes(operationType)
    ) {
      tabs.push({ label: 'Requisitos', value: REQUIREMENTS });
    }
    if (attributtes.showTab) {
      tabs.push({
        label: 'Atributos',
        value: ATTRIBUTES,
      });
    }
    if (documents.showTab) {
      tabs.push(
        {
          label: 'Documentos Operación',
          value: DOCUMENTS,
        },
        {
          label: 'Documentos de Respaldo',
          value: BACKUP,
        }
      );
    }
    if (walletStatus.showTab) {
      tabs.push({
        label: 'Estado Cartera',
        value: WALLET_STATUS,
      });
    }
    return tabs;
  };

  const getIcons = (status = false) => {
    if (status) {
      return (
        <Box color="success.main">
          <CheckIcon />
        </Box>
      );
    }
    return <CloseIcon color="error" />;
  };

  const handleGetAttributeValue = (key, value) => {
    switch (key) {
      case 'currency':
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <AttributeCurrency>{value}</AttributeCurrency>
            <IconButton onClick={() => setShowPayrollCurrencyDialog(true)}>
              <EditIcon />
            </IconButton>
          </Box>
        );
      case 'operationDocs':
        return (
          <Box display="flex" alignItems="center">
            {getIcons(value)}
            <AttributeRow>Docs. de Operación</AttributeRow>
          </Box>
        );
      case 'invoicesCeded':
        return (
          <Box display="flex" alignItems="center">
            {getIcons(value)}
            <AttributeRow>Facturas cedidas</AttributeRow>
          </Box>
        );
      case 'invoicesVerified':
        return (
          <Box display="flex" alignItems="center">
            {getIcons(value)}
            <AttributeRow>Facturas verificadas</AttributeRow>
          </Box>
        );
      case 'allocatedFund':
        return (
          <Box display="flex" alignItems="center">
            {getIcons(value)}
            <AttributeRow>Fondo asignado</AttributeRow>
          </Box>
        );
      default:
        return null;
    }
  };

  const sectionData = [
    {
      key: 'operation',
      label: 'Operación',
    },
    {
      key: 'funds',
      label: 'Fondos',
    },
  ];

  const handleGetAttributes = section => {
    const attributesData = [
      {
        key: 'currency',
        value: handleGetAttributeValue(
          'currency',
          attributes?.currency === 'USD' ? 'USD' : defaultCurrencyCode
        ),
        isLoading: paymentOrderDetailsIsLoading,
        section: ['operation'],
        country: ['CL', 'MX'],
      },
      {
        key: 'operationDocs',
        value: handleGetAttributeValue('operationDocs', orderVerifyDocuments),
        isLoading: orderVerifyDocumentsLoading,
        section: ['operation'],
        country: ['CL', 'MX'],
      },
      {
        key: 'invoicesCeded',
        value: handleGetAttributeValue('invoicesCeded', !!isCeded),
        isLoading: paymentOrderDetailsIsLoading,
        section: ['operation'],
        country: ['CL'],
      },
      {
        key: 'invoicesVerified',
        value: handleGetAttributeValue('invoicesVerified', !!isVerified),
        isLoading: paymentOrderDetailsIsLoading,
        section: ['operation'],
        country: ['CL'],
      },
      {
        key: 'allocatedFund',
        value: handleGetAttributeValue('allocatedFund', !!hasFundFinancing),
        isLoading: orderIsFullyFinancedLoading,
        section: ['funds'],
        country: ['CL', 'MX'],
      },
    ];

    return attributesData.filter(
      attribute =>
        attribute.country.includes(country) &&
        attribute.section.includes(section)
    );
  };

  const buildBaseDocument = () => {
    return invoiceDocuments.map(invoiceDocument => {
      const { id, type, pdf } = invoiceDocument;
      return {
        action: '',
        active: true,
        approved: true,
        document: pdf,
        documentableId: id,
        documentableType: 'INVOICE_DOCUMENT',
        title: type === 'interest' ? 'Factura Interés' : 'Factura Asesoría',
        hiddenToggleEnableDocument: true,
      };
    });
  };

  const handleShowOperationDebtDialog = (debtType, orderInvoiceIds) => {
    setOrderInvoicesDebtDrawerConfig({ debtType, orderInvoiceIds });
    setShowOperationsDebtDrawer(true);
  };

  const criticalStateBlock = ({
    title,
    total,
    capital = 0,
    interest = 0,
    orderInvoices,
    debtType,
  }) => {
    return (
      <Skeleton
        variant="rect"
        width={140}
        height={19}
        isLoading={criticalStatusInfoIsLoading}
      >
        <>
          <Grid container direction="row" xs={12} spacing={2}>
            <Grid item xs={7}>
              <Typography
                className={classes.criticalStateBlockMain}
                component="div"
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                className={`${classes.criticalStateBlockMain} value`}
                component="div"
                align="right"
                data-cy={`criticalState${debtType}`}
              >
                <CountryFormatHelper
                  value={total}
                  countryId={country}
                  variant="currency"
                />
              </Typography>
            </Grid>
            {total > 0 && (
              <>
                {capital > 0 && (
                  <>
                    <Grid item xs={7}>
                      <Typography
                        className={classes.criticalStateBlockSecondary}
                        component="div"
                      >
                        Mora Capital
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        className={`${classes.criticalStateBlockSecondary} value`}
                        align="right"
                        component="div"
                      >
                        <CountryFormatHelper
                          value={capital}
                          countryId={country}
                          variant="currency"
                        />
                      </Typography>
                    </Grid>
                  </>
                )}
                {interest > 0 && (
                  <>
                    <Grid item xs={7}>
                      <Typography
                        className={classes.criticalStateBlockSecondary}
                        component="div"
                      >
                        Interés por mora
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        className={`${classes.criticalStateBlockSecondary} value`}
                        align="right"
                        component="div"
                      >
                        <CountryFormatHelper
                          value={interest}
                          countryId={country}
                          variant="currency"
                        />
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  {debtType === 'CRITICAL_AMOUNT' && (
                    <Box
                      component="span"
                      display="flex"
                      className={classes.criticalStateBlockPlaceholder}
                    />
                  )}
                  <Link
                    component="button"
                    variant="text"
                    color="primary"
                    className={`${classes.linkShowOperation}`}
                    onClick={() =>
                      handleShowOperationDebtDialog(debtType, orderInvoices)
                    }
                  >
                    Ver operaciones
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </Skeleton>
    );
  };

  const getWalletStatusData = () => {
    const {
      showDebtPaymentsOver5Days,
      showDebtDirectFinancingOver5Days,
      showDebtDirectFinancingOver60Days,
      showDebtDirectFinancingOver15Days,
      showHardCollectionBalance,
      showWalletBalance,
    } = countryFeatures.orderActions.orderLegal.walletStatus;

    const values = [
      {
        id: 1,
        value: criticalStateBlock({
          title: 'Mora Payments sobre 5 días',
          total: criticalStatusInfo?.debtAmountPaymentsOver5Days,
          capital: criticalStatusInfo?.debtAmountPaymentsOver5DaysCapital,
          interest: criticalStatusInfo?.debtAmountPaymentsOver5DaysInterest,
          orderInvoices:
            criticalStatusInfo?.debtAmountPaymentsOver5DaysOrderInvoices,
          debtType: 'PAYMENTS_5DAYS',
        }),
        showByCounty: showDebtPaymentsOver5Days,
      },
      {
        id: 2,
        value: criticalStateBlock({
          title: 'Mora FD sobre 5 días',
          total: criticalStatusInfo?.debtAmountDirectFinancingOver5Days,
          capital:
            criticalStatusInfo?.debtAmountDirectFinancingOver5DaysCapital,
          interest:
            criticalStatusInfo?.debtAmountDirectFinancingOver5DaysInterest,
          orderInvoices:
            criticalStatusInfo?.debtAmountDirectFinancingOver5DaysOrderInvoices,
          debtType: 'FD_5DAYS',
        }),
        showByCounty: showDebtDirectFinancingOver5Days,
      },
      {
        id: 3,
        value: criticalStateBlock({
          title: 'Mora cliente FD sobre 60 días',
          total: criticalStatusInfo?.debtAmountDirectFinancingOver60Days,
          capital:
            criticalStatusInfo?.debtAmountDirectFinancingOver60DaysCapital,
          interest:
            criticalStatusInfo?.debtAmountDirectFinancingOver60DaysInterest,
          orderInvoices:
            criticalStatusInfo?.debtAmountDirectFinancingOver60DaysOrderInvoices,
          debtType: 'FD_60DAYS',
        }),
        showByCounty: showDebtDirectFinancingOver60Days,
      },
      {
        id: 4,
        value: criticalStateBlock({
          title: 'Mora pagador FD sobre 15 días',
          total: criticalStatusInfo?.debtAmountDirectFinancingOver15DaysAsPayer,
          capital:
            criticalStatusInfo?.debtAmountDirectFinancingOver15DaysAsPayer,
          orderInvoices:
            criticalStatusInfo?.debtAmountDirectFinancingOver15DaysAsPayerOrderInvoices,
          debtType: 'FD_15DAYS',
        }),
        showByCounty: showDebtDirectFinancingOver15Days,
      },
      {
        id: 5,
        value: criticalStateBlock({
          title: 'Estado crítico',
          total: criticalStatusInfo?.criticalStateAmount,
          orderInvoices: criticalStatusInfo?.criticalStateOrderInvoices,
          debtType: 'CRITICAL_AMOUNT',
        }),
        showByCounty: showHardCollectionBalance,
      },
      {
        id: 6,
        value: (
          <Skeleton
            variant="rect"
            width={140}
            height={19}
            isLoading={criticalStatusInfoIsLoading}
          >
            <Box component="div" display="block" width="100%">
              <Grid container direction="row" xs={12} spacing={2}>
                <Grid item xs={7}>
                  <Typography
                    className={classes.criticalStateBlockMain}
                    component="div"
                  >
                    Saldo Wallet
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    className={`${classes.criticalStateBlockMain} value`}
                    component="div"
                    align="right"
                    data-cy="criticalStateWallet"
                  >
                    <CountryFormatHelper
                      value={criticalStatusInfo?.accountBalance}
                      countryId={country}
                      variant="currency"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Skeleton>
        ),
        showByCounty: showWalletBalance,
      },
    ];

    return values.filter(({ showByCounty }) => showByCounty);
  };

  return (
    <Panel
      title={null}
      contentPadding="zero"
      titlePadding="zero"
      noBorder
      borderRadius={8}
    >
      <Tabs
        value={stateTab}
        onChange={(e, valueTab) => setStateTab(valueTab)}
        className={classes.tabsStyles}
      >
        {getTabs().map(tab => {
          const { label, value, stylesTab } = tab;
          return (
            <Tab
              label={label}
              value={value}
              className={[classes.tabStylesBase, stylesTab]}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...generateDataForTab(value)}
              key={label}
            />
          );
        })}
        {(stateTab === DOCUMENTS || stateTab === BACKUP) &&
          countryFeatures.orderActions.orderLegal.documents.showTab && (
            <Box className={classes.containerButtonAddDocument}>
              <Button
                variant="text"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() =>
                  stateTab === DOCUMENTS
                    ? setShowCreateDocumentDialog(true)
                    : setShowCreateBackupDocumentDialog(true)
                }
              >
                Nuevo Documento
              </Button>
            </Box>
          )}
      </Tabs>

      <Grid
        role="tabpanel"
        hidden={stateTab !== REQUIREMENTS}
        id={`wrapped-tabpanel-${stateTab}`}
        aria-labelledby={`wrapped-tab-${stateTab}`}
      >
        {stateTab === REQUIREMENTS && <RequirementsTab />}
      </Grid>

      <Grid
        role="tabpanel"
        hidden={stateTab !== ATTRIBUTES}
        id={`wrapped-tabpanel-${stateTab}`}
        aria-labelledby={`wrapped-tab-${stateTab}`}
      >
        <Box display="flex" justifyContent="space-between" padding={5}>
          {sectionData.map(section => {
            const attributesData = handleGetAttributes(section.key);
            if (attributesData.length === 0) return null;
            return (
              <Box key={section.key}>
                <AttributeTitle>{section.label}</AttributeTitle>
                {attributesData.map((attribute, index) => (
                  <ContainerRow
                    isLoading={attribute.isLoading}
                    lastChild={attributesData.length === Number(index + 1)}
                  >
                    <Skeleton
                      isLoading={attribute.isLoading}
                      variant="rect"
                      width={245}
                      height={24}
                    >
                      {attribute.value}
                    </Skeleton>
                  </ContainerRow>
                ))}
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid
        role="tabpanel"
        hidden={stateTab !== BACKUP}
        id={`wrapped-tabpanel-${stateTab}`}
        aria-labelledby={`wrapped-tab-${stateTab}`}
      >
        <Grid item xs={12} className={classes.containerDocuments}>
          <DocumentsTable
            documentableType={ORDER}
            documentableId={orderId}
            documents={[...orderDocuments, ...buildBaseDocument()]}
            documentsAreLoading={
              orderDocumentsIsLoading || fetchInvoiceDocumentsIsLoading
            }
          />
        </Grid>
      </Grid>
      <Grid
        role="tabpanel"
        hidden={stateTab !== DOCUMENTS}
        id={`wrapped-tabpanel-${stateTab}`}
        aria-labelledby={`wrapped-tab-${stateTab}`}
      >
        <Grid item xs={12} className={classes.containerDocuments}>
          <DocumentsTable
            documentableType={ORDER}
            documentableId={orderId}
            documents={[...orderDocuments, ...buildBaseDocument()]}
            documentsAreLoading={
              orderDocumentsIsLoading || fetchInvoiceDocumentsIsLoading
            }
          />
        </Grid>
      </Grid>
      <Grid
        role="tabpanel"
        hidden={stateTab !== WALLET_STATUS}
        id={`wrapped-tabpanel-${stateTab}`}
        aria-labelledby={`wrapped-tab-${stateTab}`}
      >
        <Grid item xs={12} className={classes.container}>
          {getWalletStatusData().map(walletStatusData => (
            <Grid item xs={6} key={walletStatusData.id}>
              <Box display="flex" className={classes.containerItemCollection}>
                {walletStatusData.value}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {showCreateDocumentDialog && (
        <CreateDocumentDialog
          open={showCreateDocumentDialog}
          handleCloseDialog={() => setShowCreateDocumentDialog(false)}
          isLoading={createDocumentIsLoading}
          error={createDocumentError}
          documentableId={orderId}
          documentableType={ORDER}
          showCustomDocument={country === COUNTRY_CODE_MX}
          showDescription={country === COUNTRY_CODE_MX}
        />
      )}
      {showCreateBackupDocumentDialog && (
        <CreateDocumentDialog
          open={showCreateBackupDocumentDialog}
          handleCloseDialog={() => setShowCreateBackupDocumentDialog(false)}
          isLoading={createDocumentIsLoading}
          error={createDocumentError}
          documentableId={orderId}
          documentableType={ORDER}
          customAction={BACKUP}
          showSignatureType={false}
          showInitialStatus={false}
          mimetypes={MIMETYPE_IMG_DOC_XLSX_XML}
        />
      )}
      {showPayrollCurrencyDialog && (
        <PayrollCurrencyDialog
          open={showPayrollCurrencyDialog}
          handleClose={() => setShowPayrollCurrencyDialog(false)}
        />
      )}
      {showOperationsDebtDrawer && (
        <OperationsDebtDrawer
          isOpen={showOperationsDebtDrawer}
          orderInvoicesDebtConfig={orderInvoicesDebtDrawerConfig}
          handleCloseDrawer={() => setShowOperationsDebtDrawer(false)}
        />
      )}
    </Panel>
  );
};

OrderLegal.defaultProps = {
  isCeded: false,
  isVerified: false,
  hasFundFinancing: false,
  orderType: null,
};
OrderLegal.propTypes = {
  isCeded: PropTypes.bool,
  isVerified: PropTypes.bool,
  hasFundFinancing: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
  orderType: PropTypes.arrayOf([ORDER_TYPES_LABEL]),
};

export default OrderLegal;
