import { purgeNestedObj } from '../../../../commons/utils';
import { restFunds } from '../../../../infra/http';
import * as Mapper from './receiver.map';

export const fetchReceivers = filter => {
  const params = purgeNestedObj(Mapper.receiverFilterMap.toPersistence(filter));

  return restFunds()
    .get('v2/receiver', { params: { ...params, opportunity: true } })
    .then(r => r.data)
    .then(Mapper.receiverFilterMap.toDomain);
};

export const fetchReceiver = filter => {
  const params = purgeNestedObj(Mapper.receiverFilterMap.toPersistence(filter));

  return restFunds()
    .get(`receiver/${filter.receiverIdentifier}`, {
      params: { ...params, opportunity: true },
    })
    .then(r => r.data)
    .then(Mapper.receiverMap.toDomain);
};
