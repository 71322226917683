import React from 'react';
import { SecureRoute } from '@okta/okta-react';

import { PayrollDetail } from '../../inProgress/ui/PayrollDetail';
import { FUNDS_SECTION_PERFORM } from '../../../helpers/performsType';
import requireAuth from '../../../components/hoc/require_okta_auth';
import { HomeRouter } from '../../HomeRouter';
import { FUNDS_BASE_PATH } from '../../commons/constants/ui';

export const FundsRoutes = countryId => {
  const baseRefactorPath = `/${countryId}/funds`;

  return [
    <SecureRoute
      key={1}
      exact
      path={`/${countryId}/${FUNDS_BASE_PATH}/payroll/:payrollId`}
      component={requireAuth(PayrollDetail, FUNDS_SECTION_PERFORM)}
    />,
    <SecureRoute key={2} path={baseRefactorPath}>
      <HomeRouter basePath={baseRefactorPath} />
    </SecureRoute>,
  ];
};
