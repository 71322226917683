import { useQuery } from 'react-query';
import { fetchCommentsService } from '../services';

const useFetchComments = (sourceType, sourceId) => {
  const { isLoading, data, isFetching, isRefetching, refetch } = useQuery(
    ['getComments'],
    () => fetchCommentsService(sourceType, sourceId),
    { refetchOnWindowFocus: false, keepPreviousData: true, staleTime: 5000 }
  );

  const { data: comments } = data || { data: { comments: [] } };
  return {
    comments,
    isLoading: isLoading || isFetching || isRefetching,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchComments };
