/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../../helpers/Constants';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';
import { validateIdentifier } from '../../helpers/validation/businessIdentifier';
import { t13s } from '../../translationKeys';

const useStyles = makeStyles({
  rootForm: {
    padding: convertSpacingToCss('sm sm md sm'),
  },
  iconSelect: { marginRight: 10 },
});

const FundFormDialog = ({
  open,
  handleCloseDialog,
  selectedFund,
  handleSubmitNewFund,
  handleSubmitUpdatedFund,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fundForm, setFundForm] = useState({
    name: '',
    rateDirectFinancing: '',
    rateEarlyPayment: '',
    rateConfirming: '',
    advancePercentage: '',
    ...selectedFund,
    address:
      selectedFund?.address ?? selectedFund?.ContactDetail?.address ?? '',
    contactName:
      selectedFund?.contactName ?? selectedFund?.ContactDetail?.name ?? '',
    contactPhone:
      selectedFund?.contactPhone ?? selectedFund?.ContactDetail?.phone ?? '',
    contactEmail:
      selectedFund?.contactEmail ?? selectedFund?.ContactDetail?.email ?? '',
    identifier: selectedFund?.rut ?? selectedFund?.identifier ?? '',
  });

  const { country } = useSelector(state => state.config);

  useEffect(() => {
    ValidatorForm.addValidationRule('validateIdentifier', value =>
      validateIdentifier(value, country)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = event => {
    event.persist();
    const {
      target: { value: valueInput, name: nameInput },
    } = event;
    const value =
      nameInput === 'rate' ? parseFloat(valueInput, 10) : valueInput;
    setFundForm({
      ...fundForm,
      [nameInput]: value,
    });
  };

  const handleRutChange = event => {
    event.persist();
    let input = event.target.value.replace(/[^kK0-9]/g, '');
    input = input.replace(/^(\d{8,9}||\d{7})(\d|[kK]{1})$/, '$1-$2');
    // eslint-disable-next-line no-param-reassign
    event.target.value = input;
    handleInputChange(event);
  };

  const handleIdentifierChange = event => {
    event.persist();
    switch (country) {
      case COUNTRY_CODE_CL:
        return handleRutChange(event);
      case COUNTRY_CODE_MX:
        return handleInputChange(event);
      default:
        return handleInputChange(event);
    }
  };

  const handleSubmit = () => {
    if (!selectedFund) {
      handleSubmitNewFund(fundForm);
    } else {
      handleSubmitUpdatedFund(fundForm);
    }
  };

  const isContactModified =
    fundForm.address ||
    fundForm.contactName ||
    fundForm.contactEmail ||
    fundForm.contactPhone;

  const {
    name,
    identifier,
    rateDirectFinancing,
    rateEarlyPayment,
    rateConfirming,
    address,
    contactName,
    contactPhone,
    contactEmail,
    advancePercentage,
  } = fundForm;

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      title={`${selectedFund ? 'Editar' : 'Nuevo'} Fondo de Inversión`}
    >
      <Grid item xs={12} align="center">
        <ValidatorForm onSubmit={handleSubmit}>
          <TextValidator
            variant="outlined"
            fullWidth
            label="Nombre del Fondo"
            type="string"
            name="name"
            aria-describedby="Nombre Fondo"
            value={name}
            onChange={e => handleInputChange(e)}
            validators={['required']}
            errorMessages={['Campo requerido']}
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Fondo`}
            type="string"
            name="identifier"
            aria-describedby={`${t(t13s.LABEL.BUSINESS_IDENTIFIER)} Fondo`}
            value={identifier}
            onChange={e => handleIdentifierChange(e)}
            validators={['required', 'validateIdentifier']}
            errorMessages={[
              'Campo requerido',
              t(t13s.INPUT_ERROR.BUSINESS_IDENTIFIER),
            ]}
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Dirección"
            type="string"
            name="address"
            aria-describedby="Dirección"
            value={address}
            onChange={e => handleInputChange(e)}
            validators={isContactModified ? ['required'] : []}
            errorMessages={
              isContactModified ? ['Campo de contacto requerido'] : []
            }
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Nombre Contacto"
            type="string"
            name="contactName"
            aria-describedby="Nombre Contacto"
            value={contactName}
            onChange={e => handleInputChange(e)}
            validators={isContactModified ? ['required'] : []}
            errorMessages={
              isContactModified ? ['Campo de contacto requerido'] : []
            }
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Fono Contacto"
            type="string"
            name="contactPhone"
            aria-describedby="Fono Contacto"
            value={contactPhone}
            onChange={e => handleInputChange(e)}
            validators={isContactModified ? ['required'] : []}
            errorMessages={
              isContactModified ? ['Campo de contacto requerido'] : []
            }
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Email Contacto"
            type="string"
            name="contactEmail"
            aria-describedby="Email Contacto"
            value={contactEmail}
            onChange={e => handleInputChange(e)}
            validators={[
              ...(isContactModified && ['required']),
              ...['isEmail'],
            ]}
            errorMessages={[
              ...(isContactModified && ['Campo de contacto requerido']),
              ...['Email inválido'],
            ]}
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Porcentaje de anticipo"
            inputProps={{ min: 0, max: 100, step: 0.05 }}
            type="number"
            name="advancePercentage"
            aria-describedby="Porcentaje de anticipo"
            value={advancePercentage}
            onChange={e => handleInputChange(e)}
            validators={['required', 'isPositive']}
            errorMessages={['Campo requerido', 'Valor debe ser mayor a 0']}
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Tasa Financiamiento Directo"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="rateDirectFinancing"
            aria-describedby="Tasa Financiamiento Directo"
            value={rateDirectFinancing}
            onChange={e => handleInputChange(e)}
            validators={['required', 'isPositive', 'maxFloat:4']}
            errorMessages={[
              'Campo requerido',
              'Valor debe ser mayor a 0',
              'Valor puede contener 4 decimales',
            ]}
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Tasa Pronto Pago"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="rateEarlyPayment"
            aria-describedby="Tasa Pronto Pago"
            value={rateEarlyPayment}
            onChange={e => handleInputChange(e)}
            validators={['required', 'isPositive', 'maxFloat:4']}
            errorMessages={[
              'Campo requerido',
              'Valor debe ser mayor a 0',
              'Valor puede contener 4 decimales',
            ]}
          />
          <TextValidator
            variant="outlined"
            fullWidth
            label="Tasa Payments"
            inputProps={{ min: 0, step: 0.0001 }}
            type="number"
            name="rateConfirming"
            aria-describedby="Tasa Payments"
            value={rateConfirming}
            onChange={e => handleInputChange(e)}
            validators={['required', 'isPositive', 'maxFloat:4']}
            errorMessages={[
              'Campo requerido',
              'Valor debe ser mayor a 0',
              'Valor puede contener 4 decimales',
            ]}
          />
          <Grid className={classes.justifyCenter}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnSpacing}
            >
              Guardar
            </Button>
          </Grid>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

FundFormDialog.defaultProps = {
  selectedFund: undefined,
  open: false,
};

FundFormDialog.propTypes = {
  selectedFund: PropTypes.objectOf(Object),
  handleSubmitNewFund: PropTypes.func.isRequired,
  handleSubmitUpdatedFund: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default FundFormDialog;
