import { useQuery } from 'react-query';
import { fetchOrderResponsiblesCounterService } from '../services';

const useFetchOrderResposiblesCounter = businessIdentifier => {
  const { isLoading, data, refetch } = useQuery(
    ['getOrderResponsiblesCounter'],
    () => fetchOrderResponsiblesCounterService(businessIdentifier),
    { refetchOnWindowFocus: false }
  );

  const responsiblesCount = data?.length ?? 0;

  return {
    responsiblesCount,
    isLoading,
    refetch,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchOrderResposiblesCounter };
