import axios from 'axios';

export const fundsBulkUpdateService = async csvFile => {
  try {
    const formData = new FormData();
    formData.append('csv', csvFile[0]?.file, csvFile[0]?.file?.name);

    const { data } = await axios.post('/api/orderinvoicefund/csv', formData);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default fundsBulkUpdateService;
