import React, { FC, memo } from 'react';
import SearchFilter from '../../../commons/components/SearchFilter';
import {
  SelectFilter,
  FilterField,
} from '../../../commons/interfaces/ARInterfaces';

interface Props {
  filters: SelectFilter[];
  activeFilter: FilterField;
  handler: (value: FilterField) => void;
}

const SearchField: FC<Props> = memo(({ filters, activeFilter, handler }) => {
  const { select, input } = activeFilter;

  return (
    <SearchFilter
      placeholder="Buscar..."
      handleChangeInput={handler}
      selectValue={select}
      inputValue={input}
      options={filters}
    />
  );
});

export default SearchField;
