import axios from 'axios';
import { Dispatch } from 'react';
import {
  FETCH_COMMENTS_START,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  FETCH_LAST_COMMENT_START,
  FETCH_LAST_COMMENT_SUCCESS,
  FETCH_LAST_COMMENT_FAILURE,
  CREATE_COMMENT_START,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  UPDATE_COMMENT_START,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  RESET_COMMENTS,
} from './types';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys';
import { getErrorCode } from '../helpers/handleErrors';
import { CommentAction } from '../collection/commons/interfaces/collection';

export const fetchComments =
  (sourceType: string, sourceId: number) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({
      type: FETCH_COMMENTS_START,
    });

    axios
      .get(`/api/${sourceType}/${sourceId}/comment`)
      .then(({ data }) => {
        dispatch({
          type: FETCH_COMMENTS_SUCCESS,
          payload: { sourceType, comments: data },
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_COMMENTS_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_COMMENTS_FAILURE,
        });
      });
  };

export const fetchLastComment =
  (sourceType: string, sourceId: number) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({
      type: FETCH_LAST_COMMENT_START,
    });

    axios
      .get(`/api/${sourceType}/${sourceId}/comment`)
      .then(({ data }) => {
        const comment = data.length ? data[0] : null;
        dispatch({
          type: FETCH_LAST_COMMENT_SUCCESS,
          payload: { sourceType, comment },
        });
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.FETCH_LAST_COMMENT_FAILURE, {
            variant: 'error',
          })
        );
        dispatch({
          type: FETCH_LAST_COMMENT_FAILURE,
        });
      });
  };

export const createComment =
  (
    sourceType: string,
    sourceId: number,
    { category, comment, username }: CommentAction
  ) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({ type: CREATE_COMMENT_START });
    axios
      .post(`/api/${sourceType}/${sourceId}/comment`, {
        category,
        comment,
        username,
      })
      .then(({ data }) => {
        dispatch({
          type: CREATE_COMMENT_SUCCESS,
          payload: { sourceType, comment: data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.COMMENT_CREATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: CREATE_COMMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updateComment =
  (
    sourceType: string,
    sourceId: number,
    { category, comment, username }: CommentAction
  ) =>
  (dispatch: Dispatch<{ type: string; payload?: any }>) => {
    dispatch({
      type: UPDATE_COMMENT_START,
    });

    axios
      .put(`/api/${sourceType}/${sourceId}/comment`, {
        category,
        comment,
        username,
      })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_COMMENT_SUCCESS,
          payload: { sourceType, comment: data },
        });
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.COMMENTS_SOURCE_UPDATED, {
            variant: 'success',
          })
        );
      })
      .catch(err => {
        const errorCode = getErrorCode(err);
        dispatch({
          type: UPDATE_COMMENT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const resetComments =
() => (dispatch: Dispatch<{ type: string; payload?: any }>) => {
  dispatch({
    type: RESET_COMMENTS,
  });
};
