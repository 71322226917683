import { useMutation, useQuery } from 'react-query';

import * as Repo from './payroll.repo';

const FETCH_FUNDS_KEY = 'opportunities:FETCH_FUNDS_KEY';
const FETCH_PDF_SUMMARY_KEY = 'opportunities:FETCH_PDF_SUMMARY_KEY';

export const useFetchFunds = opts => {
  return useQuery([FETCH_FUNDS_KEY], Repo.fetchFunds, opts);
};

export const useFetchRecommendedRate = opts => {
  return useMutation(req => Repo.fetchRecommendedRate(req), opts);
};

export const useFetchCustomExpirationDate = opts => {
  return useMutation(req => Repo.fetchCustomExpirationDate(req), opts);
};

export const useMakeSimulation = opts => {
  return useMutation(req => Repo.makeSimulation(req), opts);
};

export const useDownloadPayrollDraft = opts => {
  return useMutation(req => Repo.downloadPayrollDraft(req), opts);
};

export const useSendPayroll = opts => {
  return useMutation(req => Repo.sendPayroll(req), opts);
};

export const useFetchPdfSummary = (payrollId, opts) => {
  return useQuery(
    [FETCH_PDF_SUMMARY_KEY],
    () => Repo.fetchPdfSummary(payrollId),
    opts
  );
};

export const useNotifyMissingPdfs = opts => {
  return useMutation(Repo.notifyMissingPdfs, opts);
};

export const useCheckPayrollPdf = opts => {
  return useMutation(payrollId => Repo.checkPayrollPdf(payrollId), opts);
};
