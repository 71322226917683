import axios from 'axios';

const fetchOrder = async orderId => {
  try {
    return await axios.get(`/api/payment/order/${orderId}`);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOrder };
