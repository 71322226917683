import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, CircularProgress } from '@material-ui/core';
import Container from './Container';
import DataList from './DataList';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles({
  containerTitle: {
    marginBottom: convertSpacingToCss('md'),
  },
});

const RatesResume = props => {
  const classes = useStyles();
  const { title, data, button, noDataComponent, fetchingRates } = props;
  return (
    <Grid container direction="column">
      <Grid
        item
        xs={12}
        container
        direction="row"
        className={classes.containerTitle}
      >
        <Grid item xs={6}>
          <Typography variant="subtitle1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {button}
        </Grid>
      </Grid>
      {fetchingRates ? (
        <CircularProgress color="primary" size={25} />
      ) : data.length > 0 ? (
        <Container>
          <DataList
            wide
            placeholder="No hay información disponible"
            data={data}
          />
        </Container>
      ) : (
        noDataComponent
      )}
    </Grid>
  );
};

RatesResume.defaultProps = {
  button: null,
  title: 'Plazos y tasas',
  noDataComponent: null,
  fetchingRates: false,
};

RatesResume.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(Object).isRequired,
  noDataComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  button: PropTypes,
  fetchingRates: PropTypes.bool,
};

export default RatesResume;
