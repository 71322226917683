/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, CircularProgress } from '@material-ui/core';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { wsConnect } from '../../actions/websocket';
import { fetchCessionaries } from '../../actions/cessionAction';
import { settings } from '../../config/settings/index';
import BaseDialog from '../dialogs/BaseDialog';
import MenuItem from './MenuItem';
import CheckButton from './CheckButton';

class FacturasCessionDialog extends Component {
  constructor(props) {
    super(props);
    // autoselect the firstone as default
    this.state = {
      cessionaryId: 2,
      check: false,
    };
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.requestCessionError !== prevProps.requestCessionError &&
      this.props.requestCessionError
    ) {
      this.props.fetchFacturas(this.props.orderId);
      this.handleCloseButtonClick();
    }

    if (
      this.props.cessionSuccess &&
      this.props.cessionSuccess !== prevProps.cessionSuccess
    ) {
      this.props.fetchFacturas(this.props.orderId);
      this.handleCloseButtonClick();
    }
  };

  componentDidMount = () => {
    this.props.fetchCessionaries();
    if (!this.props.wsconnected) {
      this.props.wsConnect();
    }
  };

  handleSelectChange = value => {
    this.setState(prevState => ({
      ...prevState,
      cessionaryId: value,
    }));
  };

  handleSubmit = () => {
    const {
      selectedInvoices,
      handleMakeCession,
      recession,
      country,
      business,
      cessionaries,
    } = this.props;
    const { isApiGlobal } = settings[country];
    const cessionary = cessionaries.filter(val => {
      return val.id === this.state.cessionaryId;
    });

    // Remove "recession" after migration
    handleMakeCession(
      selectedInvoices,
      cessionary[0],
      isApiGlobal ? business.id : recession
    );
  };

  handleCloseButtonClick = () => {
    this.props.handleCloseDialog();
    this.props.resetCessionDialogProps();
  };

  render() {
    const { open, requestingCession, cessionaries } = this.props;
    const { cessionaryId } = this.state;
    return (
      <BaseDialog
        isOpen={open}
        handleClose={() => this.handleCloseButtonClick()}
        title="Cesión de facturas"
      >
        {requestingCession ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <ValidatorForm onSubmit={() => this.handleSubmit()}>
            <SelectValidator
              fullWidth
              variant="outlined"
              value={cessionaryId}
              onChange={e => this.handleSelectChange(e.target.value)}
            >
              {cessionaries &&
                cessionaries.map(({ name, id }) => {
                  return (
                    <MenuItem key={name} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
            </SelectValidator>
            <CheckButton
              check={this.state.check}
              disabled={!cessionaries.length}
              handleCheck={() => {
                this.setState(prevState => ({
                  ...prevState,
                  check: !prevState.check,
                }));
              }}
            />
          </ValidatorForm>
        )}
      </BaseDialog>
    );
  }
}

FacturasCessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  requestCessionError: PropTypes.string.isRequired,
  fetchCessionaries: PropTypes.func.isRequired,
  fetchFacturas: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  cessionSuccess: PropTypes.bool.isRequired,
  wsConnect: PropTypes.func.isRequired,
  wsconnected: PropTypes.string.isRequired,
  cessionaries: PropTypes.arrayOf(Object).isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  resetCessionDialogProps: PropTypes.func.isRequired,
  handleMakeCession: PropTypes.func.isRequired,
  recession: PropTypes.bool.isRequired,
  requestingCession: PropTypes.bool.isRequired,
  selectedInvoices: PropTypes.arrayOf(Object).isRequired,
};

const mapStateToProps = state => {
  return {
    requestingCession: state.cession.requestingCession,
    requestCessionError: state.cession.requestCessionError,
    cessionSuccess: state.cession.cessionSuccess,
    loadingCessionaries: state.cession.loadingCessionaries,
    cessionaries: state.cession.cessionaries,
    cessionariesError: state.cession.cessionariesError,
    updatedOrder: state.order.updatedOrder,
    wsconnected: state.ws.wsconnected,
    country: state.config.country,
    business: state.business.business,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetCessionDialogProps: () =>
      dispatch({ type: 'HANDLE_CLOSE_CESSION_DIALOG' }),
    fetchCessionaries: () => dispatch(fetchCessionaries()),
    wsConnect: () => {
      dispatch(wsConnect());
    },
  };
};

FacturasCessionDialog.propTypes = {
  fetchFacturas: PropTypes.func.isRequired,
  fetchCessionaries: PropTypes.func.isRequired,
  wsConnect: PropTypes.func.isRequired,
  resetCessionDialogProps: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  wsconnected: PropTypes.bool.isRequired,
  handleMakeCession: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  cessionaries: PropTypes.array.isRequired,
  requestingCession: PropTypes.bool.isRequired,
  requestCessionError: PropTypes.bool.isRequired,
  cessionSuccess: PropTypes.bool.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FacturasCessionDialog
);
