import React, { memo, useCallback, useEffect, useMemo } from 'react';
import MaterialTable from '@material-table/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { GET_COLUMNS } from './constants';
import { TABLE_OPTIONS } from '../../../../../commons/constants/ui';
import { usePayrollSelectorController } from '../../../../domains/payroll/controllers';
import { useFetchReceivers } from '../../../../domains/receiver/services';
import { useStyles } from './ReceiversList.styles';
import PaginationMaterialTable from '../../../../../../components/elements/PaginationMaterialTable';
import { useGetCountry } from '../../../../../adapters/store';
import {
  useGetReceiverForm,
  useReceiverActions,
} from '../../../../domains/receiver/state';
import { useDocumentActions } from '../../../../domains/document/state';
import { useOpportunitiesContext } from '../../../../context/OpportunitiesContext';

const ReceiversListComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const country = useGetCountry();
  const css = useStyles();
  const receiverForm = useGetReceiverForm();
  const { addReceiver } = usePayrollSelectorController();
  const { selectReceiver } = useReceiverActions();
  const { saveDocumentForm } = useDocumentActions();
  const opportunitiesCtx = useOpportunitiesContext();
  const { isFetching, receivers, pagination } = useFetchReceivers(
    opportunitiesCtx.reqFilters,
    {
      refetchOnMount: false,
    }
  );

  const changeToDocumentView = useCallback(
    receiver => {
      const { name, payerIdentifier, ...fieldsForDocuments } = receiverForm;
      selectReceiver(receiver);
      saveDocumentForm(fieldsForDocuments);
      history.push(`opportunities/${receiver?.receiverIdentifier}`);
    },
    [history, selectReceiver, receiverForm]
  );

  const columns = useMemo(
    () =>
      GET_COLUMNS({
        changeToDocumentView,
        country,
        css,
        receiverForm: opportunitiesCtx.reqFilters,
        t,
      }),
    [country, css, changeToDocumentView, t, opportunitiesCtx.reqFilters]
  );

  useEffect(() => {
    opportunitiesCtx.setReqFilters(st => ({
      ...receiverForm,
      limit: st.limit,
      orderBy: st.orderBy,
    }));
  }, [receiverForm]);

  return (
    <MaterialTable
      columns={columns}
      data={receivers}
      style={{ width: '100%', boxShadow: 'none' }}
      isLoading={isFetching}
      onOrderChange={(orderedColumnId, orderDirection) => {
        opportunitiesCtx.setReqFilters(st => ({
          ...st,
          orderBy: {
            field: columns[orderedColumnId]?.field,
            direction: orderDirection,
          },
        }));
      }}
      onSelectionChange={selectedRows => addReceiver(selectedRows, receivers)}
      components={{
        Toolbar: () => null,
        Pagination: props => (
          <PaginationMaterialTable
            {...props}
            rowsPerPage={opportunitiesCtx.reqFilters.limit}
            count={pagination?.totalRecords}
            page={pagination?.page - 1}
            onChangeRowsPerPage={(_, meta) => {
              opportunitiesCtx.setReqFilters(st => ({
                ...st,
                limit: meta.props.value,
                page: 1,
              }));
            }}
            onChangePage={(_, page) => {
              opportunitiesCtx.setReqFilters(st => ({
                ...st,
                page: page + 1,
              }));
            }}
          />
        ),
      }}
      options={{
        ...TABLE_OPTIONS,
        selectionProps: sp => {
          return {
            indeterminate: sp.tableData.indeterminate,
          };
        },
      }}
      localization={{
        pagination: {
          labelRowsSelect: 'pagadores',
          labelRowsPerPage: 'Pagadores por página',
          labelDisplayedRows: '{count} pagadores en total',
        },
        body: {
          emptyDataSourceMessage: 'No hay pagadores para mostrar',
        },
      }}
    />
  );
};

export const ReceiversList = memo(ReceiversListComponent);
