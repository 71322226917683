import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { useStyles } from './ReceiverDetailDrawer.styles';
import { Close } from '../../../../../../components/icons';
import { t13s } from '../../../../../../translationKeys';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import { roundToInt } from '../../../../../commons/utils';
import { fixDecimals, percentageFormat } from '../../../../../../utils';
import { useFetchReceiver } from '../../../../domains/receiver/services';
import { useGetCountry } from '../../../../../adapters/store';

export const ReceiverDetailDrawer = ({ onClose, open, receiverIdentifier }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useGetCountry();
  const { isFetching, data: currentReceiver } = useFetchReceiver(
    {
      receiverIdentifier,
    },
    {
      enabled: !!receiverIdentifier,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );

  return (
    <Drawer
      anchor="right"
      width="380"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
      disableEscapeKeyDown
    >
      {isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && currentReceiver && (
        <>
          <AppBar position="static" className={classes.appBar} elevation={0}>
            <Toolbar className={classes.toolBar}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className={classes.navTitle}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={classes.drawerTitle}
                  >
                    Detalle pagador
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={onClose}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <span className={classes.subTitle}>Pagador</span>
              <h4 className={classes.companyName}>{currentReceiver?.name}</h4>
              <p className={classes.companyIdentifier}>
                {t(t13s.LABEL.BUSINESS_IDENTIFIER)}: {receiverIdentifier}
              </p>
              <p>
                Monto bruto:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.amount}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={classes.within}>
                Activo:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.activeAmount}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={classes.within}>
                Cedido:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.noActiveAssignee}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p className={classes.within}>
                No cedido:
                <span>
                  <CountryFormatHelper
                    value={currentReceiver?.noActiveNoAssignee}
                    countryId={country}
                    variant="currency"
                  />
                </span>
              </p>
              <p>
                Nº facturas: <span>{currentReceiver?.documentsNumber}</span>
              </p>
              <p className={classes.within}>
                Financiamiento Directo:
                <span>{currentReceiver?.directFinancing}</span>
              </p>
              <p className={classes.within}>
                Pronto Pago:
                <span>{currentReceiver?.earlyPayment}</span>
              </p>
              <p className={classes.within}>
                Payments Con Cesión:
                <span>{currentReceiver?.paymentsWithCession}</span>
              </p>
              <p className={classes.within}>
                Payments Sin Cesión:
                <span>{currentReceiver?.paymentsWithoutCession}</span>
              </p>
              <p>
                Nº Emisores: <span>{currentReceiver?.issuersNumber}</span>
              </p>
              <p>Plazo:</p>
              <p className={classes.within}>
                Max:
                <span>{currentReceiver?.maxTerm}</span>
              </p>
              <p className={classes.within}>
                Min:
                <span>{currentReceiver?.minTerm}</span>
              </p>
              <p className={classes.within}>
                Ponderado:
                <span>{roundToInt(currentReceiver?.term)}</span>
              </p>
              <p>
                Score Ponderado:
                <span>{fixDecimals(currentReceiver?.score)}</span>
              </p>
              <p>
                % AR de facturas sin Mérito:
                <span>
                  {percentageFormat(currentReceiver?.acknowledgementPercentage)}
                </span>
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </Drawer>
  );
};

ReceiverDetailDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  receiverIdentifier: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};
