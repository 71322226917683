import { makeStyles } from '@material-ui/core';

import { DRAWER_SECONDARY } from '../../../../theme/otherColors';

export const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '15px',
  },
  appBar: {
    padding: '20px',
    color: DRAWER_SECONDARY.headerTextColor,
    backgroundColor: DRAWER_SECONDARY.altBackgroundColor,
    position: 'relative',
  },
  toolBar: {
    minHeight: 'auto',
    padding: 0,
  },
  navTitle: {
    flex: '1',
  },
  drawerTitle: {
    fontSize: 18,
  },
}));
