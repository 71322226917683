import React, { FC } from 'react';
import { Box, Button, Collapse } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { OperationList } from '../../../../commons/interfaces/ARInterfaces';
import { RequirementText, SubTitleRight, TitleRight, useStyles } from './style';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { CURRENCY } from '../../../../../helpers/Constants';
import useGetCountry from '../../../../../hooks/useGetCountry';
import useRequirements from '../../../../commons/components/RequirementsTab/hooks/useRequirements';
import useOperationCard from '../../../hooks/useOperationCard';

interface Props {
  operation: OperationList;
  isExpanded: boolean;
}

const OperationCardSummary: FC<Props> = ({ operation, isExpanded }) => {
  const classes = useStyles();
  const country = useGetCountry();
  const ORDER_DETAILS_PATH = `/${country.toLowerCase()}/orders/${operation.id}`;
  const currency = CURRENCY[country];

  const {
    transformRequirementData,
    handleGetLinearProgressMulticolor,
    handleGetCountRequirements,
  } = useRequirements();
  const { getShowRequirementProgressBar } = useOperationCard();

  const requirementData = transformRequirementData(operation.requirements);
  const { totalRequirements, successRequirements } = handleGetCountRequirements(
    {
      section: 'all',
      rawRequirementData: requirementData,
      operationType: operation.type,
      operationStatus: operation.status,
    }
  );

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box mb={5}>
        <TitleRight>Monto a transferir</TitleRight>
        <SubTitleRight>
          {`${currency.toUpperCase()} `}
          {/* @ts-ignore */}
          <CountryFormatHelper
            value={operation.transfer}
            variant="currency"
            countryId={country}
          />
        </SubTitleRight>
      </Box>

      {getShowRequirementProgressBar(operation) && (
        <Collapse in={isExpanded} timeout="auto">
          <Box mb={2}>
            <RequirementText>{`${successRequirements}/${totalRequirements} Requisitos completados`}</RequirementText>
            <Box my={2}>
              {handleGetLinearProgressMulticolor(
                successRequirements,
                totalRequirements
              )}
            </Box>
          </Box>
        </Collapse>
      )}

      <Button
        className={classes.buttonDetailOperation}
        to={ORDER_DETAILS_PATH}
        component={RouterLink}
        fullWidth
        variant="outlined"
        color="primary"
        data-cy="operationCardButtonDetailOperation"
      >
        Ver detalle
      </Button>
    </Box>
  );
};

export default OperationCardSummary;
