export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';

// WEBSOCKET
export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const CESSION_START = 'CESSION_START';
export const CESSION_UPDATE = 'CESSION_UPDATE';
export const WS_UPDATE_INVOICE = 'WS_UPDATE_INVOICE';
export const WS_UPDATE_BULK_INVOICES = 'WS_UPDATE_BULK_INVOICES';
export const VERIFY_INVOICE = 'VERIFY_INVOICE';
export const WS_UPDATE_FACTURA = 'WS_UPDATE_FACTURA';
export const WS_REGISTER_ROLE = 'WS_REGISTER_ROLE';
export const WS_UPDATE_ROLE = 'WS_UPDATE_ROLE';
export const WS_TAX_SCRAPE_UPDATED_STORE = 'WS_TAX_SCRAPE_UPDATED_STORE';

// EMAILS
export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';
export const SEND_EMAIL_RESET_ERRORS = 'SEND_EMAIL_RESET_ERRORS';
export const SEND_SIMULATION_EMAIL_GLOBAL_START =
  'SEND_SIMULATION_EMAIL_GLOBAL_START';
export const SEND_SIMULATION_EMAIL_GLOBAL_SUCCESS =
  'SEND_SIMULATION_EMAIL_GLOBAL_SUCCESS';
export const SEND_SIMULATION_EMAIL_GLOBAL_FAILURE =
  'SEND_SIMULATION_EMAIL_GLOBAL_FAILURE';
export const SEND_SIMULATION_EMAIL_GLOBAL_RESET_ERRORS =
  'SEND_SIMULATION_EMAIL_GLOBAL_RESET_ERRORS';

// LOGIN
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// LOGOUT
export const LOGOUT_USER = 'LOGOUT_USER';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';

// LOCATION HANDLE
export const CHANGE_LOCATION = 'CHANGE_LOCATION';

// REGISTER
export const REGISTER_SUBMIT = 'REGISTER_SUBMIT';
export const REGISTER_INPUT_CHANGE = 'REGISTER_INPUT_CHANGE';
export const SUBMIT_REGISTER = 'SUBMIT_REGISTER';
export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_BAD_SII_CREDENTIALS = 'REGISTER_BAD_SII_CREDENTIALS';

// RESPONSIBLES

export const FETCH_ALL_RESPONSIBLES_START = 'FETCH_ALL_RESPONSIBLES_START';
export const FETCH_ALL_RESPONSIBLES_SUCCESS = 'FETCH_ALL_RESPONSIBLES_SUCCESS';
export const FETCH_ALL_RESPONSIBLES_FAILURE = 'FETCH_ALL_RESPONSIBLES_FAILURE';
export const FETCH_RESPONSIBLES_START = 'FETCH_RESPONSIBLES_START';
export const FETCH_RESPONSIBLES_SUCCESS = 'FETCH_RESPONSIBLES_SUCCESS';
export const FETCH_RESPONSIBLES_FAILURE = 'FETCH_RESPONSIBLES_FAILURE';
export const CREATE_RESPONSIBLE_START = 'CREATE_RESPONSIBLE_START';
export const CREATE_RESPONSIBLE_SUCCESS = 'CREATE_RESPONSIBLE_SUCCESS';
export const CREATE_RESPONSIBLE_FAILURE = 'CREATE_RESPONSIBLE_FAILURE';
export const DELETE_RESPONSIBLE_START = 'DELETE_RESPONSIBLE_START';
export const DELETE_RESPONSIBLE_SUCCESS = 'DELETE_RESPONSIBLE_SUCCESS';
export const DELETE_RESPONSIBLE_FAILURE = 'DELETE_RESPONSIBLE_FAILURE';

// COMMENTS
export const FETCH_COMMENTS_START = 'FETCH_COMMENTS_START';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';
export const FETCH_LAST_COMMENT_START = 'FETCH_LAST_COMMENT_START';
export const FETCH_LAST_COMMENT_SUCCESS = 'FETCH_LAST_COMMENT_SUCCESS';
export const FETCH_LAST_COMMENT_FAILURE = 'FETCH_LAST_COMMENT_FAILURE';
export const CREATE_COMMENT_START = 'CREATE_COMMENT_START';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';
export const UPDATE_COMMENT_START = 'UPDATE_COMMENT_START';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE';
export const RESET_COMMENTS = 'RESET_COMMENTS';
export const FETCH_COUNT_ORDER_COMMENTS_START =
  'FETCH_COUNT_ORDER_COMMENTS_START';
export const FETCH_COUNT_ORDER_COMMENTS_SUCCESS =
  'FETCH_COUNT_ORDER_COMMENTS_SUCCESS';
export const FETCH_COUNT_ORDER_COMMENTS_FAILURE =
  'FETCH_COUNT_ORDER_COMMENTS_FAILURE';
export const FETCH_COUNT_ORDER_RESPONSIBLES_START =
  'FETCH_COUNT_ORDER_RESPONSIBLES_START';
export const FETCH_COUNT_ORDER_RESPONSIBLES_SUCCESS =
  'FETCH_COUNT_ORDER_RESPONSIBLES_SUCCESS';
export const FETCH_COUNT_ORDER_RESPONSIBLES_FAILURE =
  'FETCH_COUNT_ORDER_RESPONSIBLES_FAILURE';
export const RESET_FETCH_SUMMARY_OPERATION = 'RESET_FETCH_SUMMARY_OPERATION';
export const RESET_FETCH_INVOICES = 'RESET_FETCH_INVOICES';
export const RESET_MATCH_PENDING_INVOICES = 'RESET_MATCH_PENDING_INVOICES';
export const RESET_OPERATION_DETAIL_DATA = 'RESET_OPERATION_DETAIL_DATA';

// REASONS
export const FETCH_REASONS_START = 'FETCH_REASONS_START';
export const FETCH_REASONS_SUCCESS = 'FETCH_REASONS_SUCCESS';
export const FETCH_REASONS_FAILURE = 'FETCH_REASONS_FAILURE';

// BUSINESS
export const RESET_BUSINESS = 'RESET_BUSINESS';
export const FETCH_BUSINESS_BY_IDENTIFIER_START =
  'FETCH_BUSINESS_BY_IDENTIFIER_START';
export const FETCH_BUSINESS_BY_IDENTIFIER_SUCCESS =
  'FETCH_BUSINESS_BY_IDENTIFIER_SUCCESS';
export const FETCH_BUSINESS_BY_IDENTIFIER_FAILURE =
  'FETCH_BUSINESS_BY_IDENTIFIER_FAILURE';
export const FETCH_BUSINESS_NAME_START = 'FETCH_BUSINESS_NAME_START';
export const FETCH_BUSINESS_NAME_SUCCESS = 'FETCH_BUSINESS_NAME_SUCCESS';
export const FETCH_BUSINESS_NAME_FAILURE = 'FETCH_BUSINESS_NAME_FAILURE';
export const FETCH_BUSINESSES_ENROLLED_START =
  'FETCH_BUSINESSES_ENROLLED_START';
export const FETCH_BUSINESSES_ENROLLED_SUCCESS =
  'FETCH_BUSINESSES_ENROLLED_SUCCESS';
export const FETCH_BUSINESSES_ENROLLED_FAILURE =
  'FETCH_BUSINESSES_ENROLLED_FAILURE';
export const UPDATE_BUSINESS_FEES_START = 'UPDATE_BUSINESS_FEES_START';
export const UPDATE_BUSINESS_FEES_SUCCESS = 'UPDATE_BUSINESS_FEES_SUCCESS';
export const UPDATE_BUSINESS_FEES_FAILURE = 'UPDATE_BUSINESS_FEES_FAILURE';
export const RESET_BUSINESS_FEES_START = 'RESET_BUSINESS_FEES_START';
export const RESET_BUSINESS_FEES_SUCCESS = 'RESET_BUSINESS_FEES_SUCCESS';
export const RESET_BUSINESS_FEES_FAILURE = 'RESET_BUSINESS_FEES_FAILURE';
export const RESET_FEES = 'RESET_FEES';
export const DOWNLOAD_TGR_CERTIFICATE_START = 'DOWNLOAD_TGR_CERTIFICATE_START';
export const DOWNLOAD_TGR_CERTIFICATE_SUCCESS =
  'DOWNLOAD_TGR_CERTIFICATE_SUCCESS';
export const DOWNLOAD_TGR_CERTIFICATE_FAILURE =
  'DOWNLOAD_TGR_CERTIFICATE_FAILURE';
export const RESET_DOWNLOAD_TGR_CERTIFICATE = 'RESET_DOWNLOAD_TGR_CERTIFICATE';
export const FETCH_BUSINESS_COMPLIANCE_START =
  'FETCH_BUSINESS_COMPLIANCE_START';
export const FETCH_BUSINESS_COMPLIANCE_SUCCESS =
  'FETCH_BUSINESS_COMPLIANCE_SUCCESS';
export const FETCH_BUSINESS_COMPLIANCE_FAILURE =
  'FETCH_BUSINESS_COMPLIANCE_FAILURE';
export const CONSULT_BUSINESS_COMPLIANCE_START =
  'CONSULT_BUSINESS_COMPLIANCE_START';
export const CONSULT_BUSINESS_COMPLIANCE_SUCCESS =
  'CONSULT_BUSINESS_COMPLIANCE_SUCCESS';
export const CONSULT_BUSINESS_COMPLIANCE_FAILURE =
  'CONSULT_BUSINESS_COMPLIANCE_FAILURE';
export const RESET_BUSINESS_COMPLIANCE = 'RESET_BUSINESS_COMPLIANCE';
export const FETCH_COMPLIANCE_STAKEHOLDER_START =
  'FETCH_COMPLIANCE_STAKEHOLDER_START';
export const FETCH_COMPLIANCE_STAKEHOLDER_SUCCESS =
  'FETCH_COMPLIANCE_STAKEHOLDER_SUCCESS';
export const FETCH_COMPLIANCE_STAKEHOLDER_FAILURE =
  'FETCH_COMPLIANCE_STAKEHOLDER_FAILURE';
export const FETCH_COMPLIANCE_PERSON_START = 'FETCH_COMPLIANCE_PERSON_START';
export const FETCH_COMPLIANCE_PERSON_SUCCESS =
  'FETCH_COMPLIANCE_PERSON_SUCCESS';
export const FETCH_COMPLIANCE_PERSON_FAILURE =
  'FETCH_COMPLIANCE_PERSON_FAILURE';
export const UPDATE_BUSINESS_CONFIRMING_STATUS_START =
  'UPDATE_BUSINESS_CONFIRMING_STATUS_START';
export const UPDATE_BUSINESS_CONFIRMING_STATUS_SUCCESS =
  'UPDATE_BUSINESS_CONFIRMING_STATUS_SUCCESS';
export const UPDATE_BUSINESS_CONFIRMING_STATUS_FAILURE =
  'UPDATE_BUSINESS_CONFIRMING_STATUS_FAILURE';
export const UPDATE_BUSINESS_AUTORATE_START = 'UPDATE_BUSINESS_AUTORATE_START';
export const UPDATE_BUSINESS_AUTORATE_SUCCESS =
  'UPDATE_BUSINESS_AUTORATE_SUCCESS';
export const UPDATE_BUSINESS_AUTORATE_ERROR = 'UPDATE_BUSINESS_AUTORATE_ERROR';
export const FETCH_BUSINESS_PAYER_START = 'FETCH_BUSINESS_PAYER_START';
export const FETCH_BUSINESS_PAYER_SUCCESS = 'FETCH_BUSINESS_PAYER_SUCCESS';
export const FETCH_BUSINESS_PAYER_FAILURE = 'FETCH_BUSINESS_PAYER_FAILURE';
export const FETCH_NOT_ENROLLED_BUSINESS_INVOICES_START =
  'FETCH_NOT_ENROLLED_BUSINESS_INVOICES_START';
export const FETCH_NOT_ENROLLED_BUSINESS_INVOICES_SUCCESS =
  'FETCH_NOT_ENROLLED_BUSINESS_INVOICES_SUCCESS';
export const FETCH_NOT_ENROLLED_BUSINESS_INVOICES_FAILURE =
  'FETCH_NOT_ENROLLED_BUSINESS_INVOICES_FAILURE';
export const FETCH_BUSINESS_INVOICES_START = 'FETCH_BUSINESS_INVOICES_START';
export const FETCH_BUSINESS_INVOICES_SUCCESS =
  'FETCH_BUSINESS_INVOICES_SUCCESS';
export const FETCH_BUSINESS_INVOICES_FAILURE =
  'FETCH_BUSINESS_INVOICES_FAILURE';
export const CALCULATE_BUSINESS_FEES_START = 'CALCULATE_BUSINESS_FEES_START';
export const CALCULATE_BUSINESS_FEES_SUCCESS =
  'CALCULATE_BUSINESS_FEES_SUCCESS';
export const CALCULATE_BUSINESS_FEES_FAILURE =
  'CALCULATE_BUSINESS_FEES_FAILURE';
export const CALCULATE_FEES_BUSINESS_NOT_ENROLLED_START =
  'CALCULATE_FEES_BUSINESS_NOT_ENROLLED_START';
export const CALCULATE_FEES_BUSINESS_NOT_ENROLLED_SUCCESS =
  'CALCULATE_FEES_BUSINESS_NOT_ENROLLED_SUCCESS';
export const CALCULATE_FEES_BUSINESS_NOT_ENROLLED_FAILURE =
  'CALCULATE_FEES_BUSINESS_NOT_ENROLLED_FAILURE';
export const FETCH_BUSINESS_FINDER_INVOICES_START =
  'FETCH_BUSINESS_FINDER_INVOICES_START';
export const FETCH_BUSINESS_FINDER_INVOICES_SUCCESS =
  'FETCH_BUSINESS_FINDER_INVOICES_SUCCESS';
export const FETCH_BUSINESS_FINDER_INVOICES_FAILURE =
  'FETCH_BUSINESS_FINDER_INVOICES_FAILURE';
export const UPDATE_BUSINESS_NAME_START = 'UPDATE_BUSINESS_NAME_START';
export const UPDATE_BUSINESS_NAME_SUCCESS = 'UPDATE_BUSINESS_NAME_SUCCESS';
export const UPDATE_BUSINESS_NAME_FAILURE = 'UPDATE_BUSINESS_NAME_FAILURE';
export const RESET_CREATE_OPERATION_DATA = 'RESET_CREATE_OPERATION_DATA';
export const FETCH_CRITIAL_STATUS_INFO_START =
  'FETCH_CRITIAL_STATUS_INFO_START';
export const FETCH_CRITIAL_STATUS_INFO_SUCCESS =
  'FETCH_CRITIAL_STATUS_INFO_SUCCESS';
export const FETCH_CRITIAL_STATUS_INFO_FAILURE =
  'FETCH_CRITIAL_STATUS_INFO_FAILURE';
export const FETCH_API_CORPS_AMOUNT_START = 'FETCH_API_CORPS_AMOUNT_START';
export const FETCH_API_CORPS_AMOUNT_SUCCESS = 'FETCH_API_CORPS_AMOUNT_SUCCESS';
export const FETCH_API_CORPS_AMOUNT_FAILURE = 'FETCH_API_CORPS_AMOUNT_FAILURE';

// USER ORDERS
export const FETCH_BUSINESS_START = 'FETCH_BUSINESS_START';
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_FAILURE = 'FETCH_BUSINESS_FAILURE';
export const FETCH_BUSINESSES_START = 'FETCH_BUSINESSES_START';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAILURE = 'FETCH_BUSINESSES_FAILURE';
export const RESET_BUSINESSES = 'RESET_BUSINESSES';
export const FETCH_BUSINESS_ORDERS_START = 'FETCH_BUSINESS_ORDERS_START';
export const FETCH_BUSINESS_ORDERS_SUCCESS = 'FETCH_BUSINESS_ORDERS_SUCCESS';
export const FETCH_BUSINESS_ORDERS_FAILURE = 'FETCH_BUSINESS_ORDERS_FAILURE';
export const FETCH_BUSINESS_ORDER_FACTURAS_START =
  'FETCH_BUSINESS_ORDER_FACTURAS_START';
export const FETCH_BUSINESS_ORDER_FACTURAS_SUCCESS =
  'FETCH_BUSINESS_ORDER_FACTURAS_SUCCESS';
export const FETCH_BUSINESS_ORDER_FACTURAS_FAILURE =
  'FETCH_BUSINESS_ORDER_FACTURAS_FAILURE';
export const HANDLE_SELECT_ORDER_CHANGE = 'HANDLE_SELECT_ORDER_CHANGE';
export const RESET_UPDATED_BUSINESS_PROP = 'RESET_UPDATED_BUSINESS_PROP';
export const HANDLE_RESET_PFX = 'HANDLE_RESET_PFX';
export const HANDLE_SELECT_FACTURA_CHANGE = 'HANDLE_SELECT_FACTURA_CHANGE';
export const MANAGE_ORDER_INVOICES_START = 'MANAGE_ORDER_INVOICES_START';
export const MANAGE_ORDER_INVOICES_SUCCESS = 'MANAGE_ORDER_INVOICES_SUCCESS';
export const MANAGE_ORDER_INVOICES_FAILURE = 'MANAGE_ORDER_INVOICES_FAILURE';

// STANDALONE ORDERS
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_START_PERSIST_ORDER =
  'FETCH_ORDERS_START_PERSIST_ORDER';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const FETCH_ORDER_FEES_START = 'FETCH_ORDER_FEES_START';
export const FETCH_ORDER_FEES_SUCCESS = 'FETCH_ORDER_FEES_SUCCESS';
export const FETCH_ORDER_FEES_FAILURE = 'FETCH_ORDER_FEES_FAILURE';
export const FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_START =
  'FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_START';
export const FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_SUCCESS =
  'FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_SUCCESS';
export const FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_FAILURE =
  'FETCH_TOTAL_ORDER_TRANSFERRED_BY_INVOICE_FAILURE';
export const FETCH_FACTURAS_START = 'FETCH_FACTURAS_START';
export const FETCH_FACTURAS_SUCCESS = 'FETCH_FACTURAS_SUCCESS';
export const FETCH_FACTURAS_FAILURE = 'FETCH_FACTURAS_FAILURE';
export const FETCH_FACTURA_START = 'FETCH_FACTURA_START';
export const FETCH_FACTURA_SUCCESS = 'FETCH_FACTURA_SUCCESS';
export const FETCH_FACTURA_FAILURE = 'FETCH_FACTURA_FAILURE';
export const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';
export const UPDATE_ORDER_STATUS_START = 'UPDATE_ORDER_STATUS_START';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE';
export const UPDATE_ORDER_FEES_START = 'UPDATE_ORDER_FEES_START';
export const UPDATE_ORDER_FEES_SUCCESS = 'UPDATE_ORDER_FEES_SUCCESS';
export const UPDATE_ORDER_FEES_FAILURE = 'UPDATE_ORDER_FEES_FAILURE';
export const UPDATE_ORDER_DETAILS_START = 'UPDATE_ORDER_DETAILS_START';
export const UPDATE_ORDER_DETAILS_SUCCESS = 'UPDATE_ORDER_DETAILS_SUCCESS';
export const UPDATE_ORDER_DETAILS_FAILURE = 'UPDATE_ORDER_DETAILS_FAILURE';
export const UPDATE_FACTURA_START = 'UPDATE_FACTURA_START';
export const UPDATE_FACTURA_SUCCESS = 'UPDATE_FACTURA_SUCCESS';
export const UPDATE_FACTURA_FAILURE = 'UPDATE_FACTURA_FAILURE';
export const SET_ORDERS_ACTIVE_FILTERS_BY_DEFAULT =
  'SET_ORDERS_ACTIVE_FILTERS_BY_DEFAULT';
export const SET_RULES = 'SET_RULES';
export const FETCH_SELECTED_ORDER_START = 'FETCH_SELECTED_ORDER_START';
export const FETCH_SELECTED_ORDER_SUCCESS = 'FETCH_SELECTED_ORDER_SUCCESS';
export const FETCH_SELECTED_ORDER_FAILURE = 'FETCH_SELECTED_ORDER_FAILURE';
export const UPDATE_STATUS_ORDER_TABLE = 'UPDATE_STATUS_ORDER_TABLE';
export const UPDATE_SELECTED_BUSINESS_START = 'UPDATE_SELECTED_BUSINESS_START';
export const UPDATE_SELECTED_BUSINESS_SUCCESS =
  'UPDATE_SELECTED_BUSINESS_SUCCESS';
export const UPDATE_SELECTED_BUSINESS_FAILURE =
  'UPDATE_SELECTED_BUSINESS_FAILURE';
export const REGISTER_DEBT_PERIOD_START = 'REGISTER_DEBT_PERIOD_START';
export const REGISTER_DEBT_PERIOD_SUCCESS = 'REGISTER_DEBT_PERIOD_SUCCESS';
export const REGISTER_DEBT_PERIOD_FAILURE = 'REGISTER_DEBT_PERIOD_FAILURE';
export const UPDATE_DEBT_PERIOD_START = 'UPDATE_DEBT_PERIOD_START';
export const UPDATE_DEBT_PERIOD_SUCCESS = 'UPDATE_DEBT_PERIOD_SUCCESS';
export const UPDATE_DEBT_PERIOD_FAILURE = 'UPDATE_DEBT_PERIOD_FAILURE';
export const DELETE_DEBT_PERIOD_START = 'DELETE_DEBT_PERIOD_START';
export const DELETE_DEBT_PERIOD_SUCCESS = 'DELETE_DEBT_PERIOD_SUCCESS';
export const DELETE_DEBT_PERIOD_FAILURE = 'DELETE_DEBT_PERIOD_FAILURE';

export const GET_PENDING_INVOICES_START = 'GET_PENDING_INVOICES_START';
export const GET_PENDING_INVOICES_SUCCESS = 'GET_PENDING_INVOICES_SUCCESS';
export const GET_PENDING_INVOICES_FAILURE = 'GET_PENDING_INVOICES_FAILURE';

export const MATCH_PENDING_INVOICES_START = 'MATCH_PENDING_INVOICES_START';
export const MATCH_PENDING_INVOICES_SUCCESS = 'MATCH_PENDING_INVOICES_SUCCESS';
export const MATCH_PENDING_INVOICES_FAILURE = 'MATCH_PENDING_INVOICES_FAILURE';

export const UPDATE_PENDING_INVOICES_START = 'UPDATE_PENDING_INVOICES_START';
export const UPDATE_PENDING_INVOICES_SUCCESS =
  'UPDATE_PENDING_INVOICES_SUCCESS';
export const UPDATE_PENDING_INVOICES_FAILURE =
  'UPDATE_PENDING_INVOICES_FAILURE';

export const UPDATE_ORDER_ADVANCE_PERCENTAGE_START =
  'UPDATE_ORDER_ADVANCE_PERCENTAGE_START';
export const UPDATE_ORDER_ADVANCE_PERCENTAGE_SUCCESS =
  'UPDATE_ORDER_ADVANCE_PERCENTAGE_SUCCESS';
export const UPDATE_ORDER_ADVANCE_PERCENTAGE_FAILURE =
  'UPDATE_ORDER_ADVANCE_PERCENTAGE_FAILURE';
export const UPDATE_ORDER_TRANSFER_DATE_START =
  'UPDATE_ORDER_TRANSFER_DATE_START';
export const UPDATE_ORDER_TRANSFER_DATE_SUCCESS =
  'UPDATE_ORDER_TRANSFER_DATE_SUCCESS';
export const UPDATE_ORDER_TRANSFER_DATE_FAILURE =
  'UPDATE_ORDER_TRANSFER_DATE_FAILURE';
export const HANDLE_ORDER_SELECTION_CHANGE = 'HANDLE_ORDER_SELECTION_CHANGE';
export const RESET_SELECTED_ORDERS = 'RESET_SELECTED_ORDERS';
export const RESET_STATE_UPDATE_ORDER = 'RESET_STATE_UPDATE_ORDER';
export const RESET_STATE_UPDATE_INVOICE = 'RESET_STATE_UPDATE_INVOICE';
export const RESET_STATE_UPLOADING_INVOICE = 'RESET_STATE_UPLOADING_INVOICE';
export const RESET_ORDER_CREDIT_FORM_DIALOG = 'RESET_ORDER_CREDIT_FORM_DIALOG';
export const REGISTER_ORDER_CREDIT_START = 'REGISTER_ORDER_CREDIT_START';
export const REGISTER_ORDER_CREDIT_SUCCESS = 'REGISTER_ORDER_CREDIT_SUCCESS';
export const REGISTER_ORDER_CREDIT_FAILURE = 'REGISTER_ORDER_CREDIT_FAILURE';
export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const CREATE_ORDER_NOT_ENROLLED_START =
  'CREATE_ORDER_NOT_ENROLLED_START';
export const CREATE_ORDER_NOT_ENROLLED_SUCCESS =
  'CREATE_ORDER_NOT_ENROLLED_SUCCESS';
export const CREATE_ORDER_NOT_ENROLLED_FAILURE =
  'CREATE_ORDER_NOT_ENROLLED_FAILURE';
export const RESET_CREATE_ORDER = 'RESET_CREATE_ORDER';
export const RESET_CREATE_ORDER_ERROR = 'RESET_CREATE_ORDER_ERROR';
export const BULK_INVOICES_UPDATE_CSV_START = 'BULK_INVOICES_UPDATE_CSV_START';
export const BULK_INVOICES_FUNDS_CSV_START = 'BULK_INVOICES_FUNDS_CSV_START';
export const BULK_INVOICES_FUNDS_CSV_SUCCESS =
  'BULK_INVOICES_FUNDS_CSV_SUCCESS';
export const BULK_INVOICES_FUNDS_CSV_FAILURE =
  'BULK_INVOICES_FUNDS_CSV_FAILURE';
export const BULK_INVOICES_UPDATE_CSV_SUCCESS =
  'BULK_INVOICES_UPDATE_CSV_SUCCESS';
export const BULK_INVOICES_UPDATE_CSV_FAILURE =
  'BULK_INVOICES_UPDATE_CSV_FAILURE';
export const FETCH_ORDERS_ID_BY_PAYER_RUT_START =
  'FETCH_ORDERS_ID_BY_PAYER_RUT_START';
export const FETCH_ORDERS_ID_BY_PAYER_RUT_SUCCESS =
  'FETCH_ORDERS_ID_BY_PAYER_RUT_SUCCESS';
export const FETCH_ORDERS_ID_BY_PAYER_RUT_FAILURE =
  'FETCH_ORDERS_ID_BY_PAYER_RUT_FAILURE';
export const RESET_MESSAGES_RESPONSE = 'RESET_MESSAGES_RESPONSE';
export const RESET_SAVING = 'RESET_SAVING';
export const FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_START =
  'FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_START';
export const FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_SUCCESS =
  'FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_SUCCESS';
export const FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_FAILURE =
  'FETCH_ID_AND_FOLIO_FACTURAS_BY_ORDER_ID_FAILURE';
export const RESET_STATE_REPLACED_INVOICE = 'RESET_STATE_REPLACED_INVOICE';
export const FETCH_TRANSFER_AMOUNT_PYME = 'FETCH_TRANSFER_AMOUNT_PYME';
export const ADD_FUND_FINANCING_START = 'ADD_FUND_FINANCING_START';
export const ADD_FUND_FINANCING_SUCCESS = 'ADD_FUND_FINANCING_SUCCESS';
export const ADD_FUND_FINANCING_FAILURE = 'ADD_FUND_FINANCING_FAILURE';
export const RESET_BULK_CSV_FUNDS_ERRORS = 'RESET_BULK_CSV_FUNDS_ERRORS';
export const FETCH_SUMMARY_OPERATION_START = 'FETCH_SUMMARY_OPERATION_START';
export const FETCH_SUMMARY_OPERATION_SUCCESS =
  'FETCH_SUMMARY_OPERATION_SUCCESS';
export const FETCH_SUMMARY_OPERATION_FAILURE =
  'FETCH_SUMMARY_OPERATION_FAILURE';
export const FETCH_FUND_SUMMARY_OPERATION_START =
  'FETCH_FUND_SUMMARY_OPERATION_START';
export const FETCH_FUND_SUMMARY_OPERATION_SUCCESS =
  'FETCH_FUND_SUMMARY_OPERATION_SUCCESS';
export const FETCH_FUND_SUMMARY_OPERATION_FAILURE =
  'FETCH_FUND_SUMMARY_OPERATION_FAILURE';
export const UPDATE_ORDER_INVOICE_FUND_START =
  'UPDATE_ORDER_INVOICE_FUND_START';
export const UPDATE_ORDER_INVOICE_FUND_SUCCESS =
  'UPDATE_ORDER_INVOICE_FUND_SUCCESS';
export const UPDATE_ORDER_INVOICE_FUND_FAILURE =
  'UPDATE_ORDER_INVOICE_FUND_FAILURE';
export const UPLOAD_INVOICES_CSV_TO_ORDER_START =
  'UPLOAD_INVOICES_CSV_TO_ORDER_START';
export const UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS =
  'UPLOAD_INVOICES_CSV_TO_ORDER_SUCCESS';
export const UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE =
  'UPLOAD_INVOICES_CSV_TO_ORDER_FAILURE';
export const INVOICE_BANK_ACCOUNT_SUCCESS = 'INVOICE_BANK_ACCOUNT_SUCCESS';
export const DOWNLOAD_AEC_START = 'DOWNLOAD_AEC_START';
export const DOWNLOAD_AEC_SUCCESS = 'DOWNLOAD_AEC_SUCCESS';
export const DOWNLOAD_AEC_FAILURE = 'DOWNLOAD_AEC_FAILURE';
export const SET_ORDER_CREDIT = 'SET_ORDER_CREDIT';
export const FETCH_ORDER_CREDIT_START = 'FETCH_ORDER_CREDIT_START';
export const FETCH_ORDER_CREDIT_SUCCESS = 'FETCH_ORDER_CREDIT_SUCCESS';
export const FETCH_ORDER_CREDIT_FAILURE = 'FETCH_ORDER_CREDIT_FAILURE';
export const FETCH_INVOICE_DOCUMENTS_START = 'FETCH_INVOICE_DOCUMENTS_START';
export const FETCH_INVOICE_DOCUMENTS_SUCCESS =
  'FETCH_INVOICE_DOCUMENTS_SUCCESS';
export const FETCH_INVOICE_DOCUMENTS_FAILURE =
  'FETCH_INVOICE_DOCUMENTS_FAILURE';
export const DOWNLOAD_INVOICE_DOCUMENTS_START =
  'DOWNLOAD_INVOICE_DOCUMENTS_START';
export const DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS =
  'DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS';
export const DOWNLOAD_INVOICE_DOCUMENTS_FAILURE =
  'DOWNLOAD_INVOICE_DOCUMENTS_FAILURE';

export const DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_START =
  'DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_START';
export const DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS =
  'DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS';
export const DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE =
  'DOWNLOAD_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE';
export const DOWNLOAD_CYA_ORDER_INVOICE_DOCUMENTS_START =
  'DOWNLOAD_CYA_ORDER_INVOICE_DOCUMENTS_START';
export const DOWNLOAD_CYA_ORDER_INVOICE_DOCUMENTS_SUCCESS =
  'DOWNLOAD_CYA_ORDER_INVOICE_DOCUMENTS_SUCCESS';
export const DOWNLOAD_CYA_ORDER_INVOICE_DOCUMENTS_FAILURE =
  'DOWNLOAD_CYA_ORDER_INVOICE_DOCUMENTS_FAILURE';

export const FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_START =
  'FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_START';
export const FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS =
  'FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_SUCCESS';
export const FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE =
  'FETCH_CYA_CREDIT_INVOICE_DOCUMENTS_FAILURE';

export const FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START =
  'FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START';
export const FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS =
  'FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS';
export const FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE =
  'FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE';

export const UPLOAD_ORDER_INVOICE_PDF_START = 'UPLOAD_ORDER_INVOICE_PDF_START';
export const UPLOAD_ORDER_INVOICE_PDF_SUCCESS =
  'UPLOAD_ORDER_INVOICE_PDF_SUCCESS';
export const UPLOAD_ORDER_INVOICE_PDF_FAILURE =
  'UPLOAD_ORDER_INVOICE_PDF_FAILURE';

export const DELETE_ORDER_INVOICE_PDF_START = 'DELETE_ORDER_INVOICE_PDF_START';
export const DELETE_ORDER_INVOICE_PDF_SUCCESS =
  'DELETE_ORDER_INVOICE_PDF_SUCCESS';
export const DELETE_ORDER_INVOICE_PDF_FAILURE =
  'DELETE_ORDER_INVOICE_PDF_FAILURE';

export const RESET_ORDER_INVOICE_PDF_UPLOAD_STATUS =
  'RESET_ORDER_INVOICE_PDF_UPLOAD_STATUS';

export const DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_START =
  'DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_START';
export const DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_SUCCESS =
  'DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_SUCCESS';
export const DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_FAILURE =
  'DOWNLOAD_MARKET_INVOICER_PDF_GLOBAL_CL_FAILURE';
export const UPDATE_ORDER_INVOICE_IS_HOLD_START =
  'UPDATE_ORDER_INVOICE_IS_HOLD_START';
export const UPDATE_ORDER_INVOICE_IS_HOLD_SUCCESS =
  'UPDATE_ORDER_INVOICE_IS_HOLD_SUCCESS';
export const UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE =
  'UPDATE_ORDER_INVOICE_IS_HOLD_FAILURE';
export const RESET_ORDER_INVOICE_IS_HOLD_STORE =
  'RESET_ORDER_INVOICE_IS_HOLD_STORE';

export const MASS_ASSIGNABLE_INVOICE_PDF_START =
  'MASS_ASSIGNABLE_INVOICE_PDF_START';
export const MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS =
  'MASS_ASSIGNABLE_INVOICE_PDF_SUCCESS';
export const MASS_ASSIGNABLE_INVOICE_PDF_FAILURE =
  'MASS_ASSIGNABLE_INVOICE_PDF_FAILURE';

// INVOICE RISK
export const FETCH_ORDER_INVOICE_RISK_START = 'FETCH_ORDER_INVOICE_RISK_START';
export const FETCH_ORDER_INVOICE_RISK_SUCCESS =
  'FETCH_ORDER_INVOICE_RISK_SUCCESS';
export const FETCH_ORDER_INVOICE_RISK_FAILURE =
  'FETCH_ORDER_INVOICE_RISK_FAILURE';
export const UPDATE_INVOICE_RISK = 'UPDATE_INVOICE_RISK';
// CESSION
export const FETCH_CESSION_START = 'FETCH_CESSION_START';
export const FETCH_CESSION_SUCCESS = 'FETCH_CESSION_SUCCESS';
export const FETCH_CESSION_FAILURE = 'FETCH_CESSION_FAILURE';
export const FETCH_CESSIONS_START = 'FETCH_CESSIONS_START';
export const FETCH_CESSIONS_SUCCESS = 'FETCH_CESSIONS_SUCCESS';
export const FETCH_CESSIONS_FAILURE = 'FETCH_CESSIONS_FAILURE';
export const FETCH_CESSIONARY_START = 'FETCH_CESSIONARY_START';
export const FETCH_CESSIONARY_SUCCESS = 'FETCH_CESSIONARY_SUCCESS';
export const FETCH_CESSIONARY_FAILURE = 'FETCH_CESSIONARY_FAILURE';
export const FETCH_CESSIONARIES_START = 'FETCH_CESSIONARIES_START';
export const FETCH_CESSIONARIES_SUCCESS = 'FETCH_CESSIONARIES_SUCCESS';
export const FETCH_CESSIONARIES_FAILURE = 'FETCH_CESSIONARIES_FAILURE';
export const MAKE_CESSION_START = 'MAKE_CESSION_START';
export const MAKE_CESSION_SUCCESS = 'MAKE_CESSION_SUCCESS';
export const MAKE_CESSION_FAILURE = 'MAKE_CESSION_FAILURE';
export const HANDLE_CLOSE_CESSION_DIALOG = 'HANDLE_CLOSE_CESSION_DIALOG';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
export const DOWNLOAD_AEC_INVOICE_START = 'DOWNLOAD_AEC_INVOICE_START';
export const DOWNLOAD_AEC_INVOICE_SUCCESS = 'DOWNLOAD_AEC_INVOICE_SUCCESS';
export const DOWNLOAD_AEC_INVOICE_FAILURE = 'DOWNLOAD_AEC_INVOICE_FAILURE';

// PFX
export const REGISTER_PFX_START = 'REGISTER_PFX_START';
export const REGISTER_PFX_SUCCESS = 'REGISTER_PFX_SUCCESS';
export const REGISTER_PFX_FAILURE = 'REGISTER_PFX_FAILURE';

// SIMULATION
export const DOWNLOAD_SIMULATION_PDF_START = 'DOWNLOAD_SIMULATION_PDF_START';
export const DOWNLOAD_SIMULATION_PDF_SUCCESS =
  'DOWNLOAD_SIMULATION_PDF_SUCCESS';
export const DOWNLOAD_SIMULATION_PDF_FAILURE =
  'DOWNLOAD_SIMULATION_PDF_FAILURE';

// USERS
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const FETCH_PERFORM_START = 'FETCH_PERFORM_START';
export const FETCH_PERFORM_SUCCESS = 'FETCH_PERFORM_SUCCESS';
export const FETCH_PERFORM_FAILURE = 'FETCH_PERFORM_FAILURE';
export const REGISTER_OR_UPDATE_ROLE_START = 'REGISTER_OR_UPDATE_ROLE_START';
export const REGISTER_OR_UPDATE_ROLE_SUCCESS =
  'REGISTER_OR_UPDATE_ROLE_SUCCESS';
export const REGISTER_OR_UPDATE_ROLE_FAILURE =
  'REGISTER_OR_UPDATE_ROLE_FAILURE';
export const DISABLE_ROLE_START = 'DISABLE_ROLE_START';
export const DISABLE_ROLE_SUCCESS = 'DISABLE_ROLE_SUCCESS';
export const DISABLE_ROLE_FAILURE = 'DISABLE_ROLE_FAILURE';
export const SELECT_ROLE = 'SELECT_ROLE';
export const RESET_USER_ERROR = 'RESET_USER_ERROR';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

// AGREEMENTS
export const FETCH_AGREEMENTS_START = 'FETCH_AGREEMENTS_START';
export const FETCH_AGREEMENTS_SUCCESS = 'FETCH_AGREEMENTS_SUCCESS';
export const FETCH_AGREEMENTS_FAILURE = 'FETCH_AGREEMENTS_FAILURE';
export const REGISTER_AGREEMENT_START = 'REGISTER_AGREEMENT_START';
export const REGISTER_AGREEMENT_SUCCESS = 'REGISTER_AGREEMENT_SUCCESS';
export const REGISTER_AGREEMENT_FAILURE = 'REGISTER_AGREEMENT_FAILURE';
export const EDIT_AGREEMENT_START = 'EDIT_AGREEMENT_START';
export const EDIT_AGREEMENT_SUCCESS = 'EDIT_AGREEMENT_SUCCESS';
export const EDIT_AGREEMENT_FAILURE = 'EDIT_AGREEMENT_FAILURE';
export const UPDATE_SELECTED_AGREEMENT = 'UPDATE_SELECTED_AGREEMENT';
export const RESET_AGREEMENT_FORM_DIALOG = 'RESET_AGREEMENT_FORM_DIALOG';
export const RESET_AGREEMENT_ERROR = 'RESET_AGREEMENT_ERROR';

// AGREEMENT RATES
export const FETCH_AGREEMENT_RATES_START = 'FETCH_AGREEMENT_RATES_START';
export const FETCH_AGREEMENT_RATES_SUCCESS = 'FETCH_AGREEMENT_RATES_SUCCESS';
export const FETCH_AGREEMENT_RATES_FAILURE = 'FETCH_AGREEMENT_RATES_FAILURE';

export const LOAD_INVOICE_PDF_START = 'LOAD_INVOICE_PDF_START';
export const LOAD_INVOICE_PDF_SUCCESS = 'LOAD_INVOICE_PDF_SUCCESS';
export const LOAD_INVOICE_PDF_FAILURE = 'LOAD_INVOICE_PDF_FAILURE';

export const FETCH_BUSINESS_FILE_START = 'FETCH_BUSINESS_FILE_START';
export const FETCH_BUSINESS_FILE_SUCCESS = 'FETCH_BUSINESS_FILE_SUCCESS';
export const FETCH_BUSINESS_FILE_FAILURE = 'FETCH_BUSINESS_FILE_FAILURE';
export const DELETE_BUSINESS_FILE_START = 'DELETE_BUSINESS_FILE_START';
export const DELETE_BUSINESS_FILE_SUCCESS = 'DELETE_BUSINESS_FILE_SUCCESS';
export const DELETE_BUSINESS_FILE_FAILURE = 'DELETE_BUSINESS_FILE_FAILURE';

export const FETCH_AGREEMENTS_CONTACTS_START =
  'FETCH_AGREEMENTS_CONTACTS_START';
export const FETCH_AGREEMENTS_CONTACTS_SUCCESS =
  'FETCH_AGREEMENTS_CONTACTS_SUCCESS';
export const FETCH_AGREEMENTS_CONTACTS_FAILURE =
  'FETCH_AGREEMENTS_CONTACTS_FAILURE';
export const REGISTER_AGREEMENTS_CONTACTS_START =
  'REGISTER_AGREEMENTS_CONTACTS_START';
export const REGISTER_AGREEMENTS_CONTACTS_SUCCESS =
  'REGISTER_AGREEMENTS_CONTACTS_SUCCESS';
export const REGISTER_AGREEMENTS_CONTACTS_FAILURE =
  'REGISTER_AGREEMENTS_CONTACTS_FAILURE';
export const DELETE_AGREEMENTS_CONTACTS_START =
  'DELETE_AGREEMENTS_CONTACTS_START';
export const DELETE_AGREEMENTS_CONTACTS_SUCCESS =
  'DELETE_AGREEMENTS_CONTACTS_SUCCESS';
export const DELETE_AGREEMENTS_CONTACTS_FAILURE =
  'DELETE_AGREEMENTS_CONTACTS_FAILURE';
export const EDIT_AGREEMENTS_CONTACTS_START = 'EDIT_AGREEMENTS_CONTACTS_START';
export const EDIT_AGREEMENTS_CONTACTS_SUCCESS =
  'EDIT_AGREEMENTS_CONTACTS_SUCCESS';
export const EDIT_AGREEMENTS_CONTACTS_FAILURE =
  'EDIT_AGREEMENTS_CONTACTS_FAILURE';

// FUNDS
export const FETCH_FUNDS_START = 'FETCH_FUNDS_START';
export const FETCH_FUNDS_SUCCESS = 'FETCH_FUNDS_SUCCESS';
export const FETCH_FUNDS_FAILURE = 'FETCH_FUNDS_FAILURE';
export const EDIT_FUNDS_START = 'EDIT_FUNDS_START';
export const EDIT_FUNDS_SUCCESS = 'EDIT_FUNDS_SUCCESS';
export const EDIT_FUNDS_FAILURE = 'EDIT_FUNDS_FAILURE';
export const REGISTER_FUNDS_START = 'REGISTER_FUNDS_START';
export const REGISTER_FUNDS_SUCCESS = 'REGISTER_FUNDS_SUCCESS';
export const REGISTER_FUNDS_FAILURE = 'REGISTER_FUNDS_FAILURE';
export const FETCH_FUND_RATES_START = 'FETCH_FUND_RATE_START';
export const FETCH_FUND_RATES_SUCCESS = 'FETCH_FUND_RATE_SUCCESS';
export const FETCH_FUND_RATES_FAILURE = 'FETCH_FUND_RATE_FAILURE';
export const EDIT_FUND_RATES_START = 'EDIT_FUND_RATE_START';
export const EDIT_FUND_RATES_SUCCESS = 'EDIT_FUND_RATE_SUCCESS';
export const EDIT_FUND_RATES_FAILURE = 'EDIT_FUND_RATE_FAILURE';
export const REGISTER_FUND_RATES_START = 'REGISTER_FUND_RATE_START';
export const REGISTER_FUND_RATES_SUCCESS = 'REGISTER_FUND_RATE_SUCCESS';
export const REGISTER_FUND_RATES_FAILURE = 'REGISTER_FUND_RATE_FAILURE';
export const UPDATE_SELECTED_FUND = 'UPDATE_SELECTED_FUND';
export const UPDATE_SELECTED_FUND_RATE = 'UPDATE_SELECTED_FUND_RATE';
export const RESET_FUND_FORM_DIALOG = 'RESET_FUND_FORM_DIALOG';
export const DELETE_FUND_RATE_START = 'DELETE_FUND_RATE_START';
export const DELETE_FUND_RATE_SUCCESS = 'DELETE_FUND_RATE_SUCCESS';
export const DELETE_FUND_RATE_FAILURE = 'DELETE_FUND_RATE_FAILURE';
export const FETCH_FUND_START = 'FETCH_FUND_START';
export const FETCH_FUND_SUCCESS = 'FETCH_FUND_SUCCESS';
export const FETCH_FUND_FAILURE = 'FETCH_FUND_FAILURE';
export const FETCH_FUNDRATES_BYFUND_START = 'FETCH_FUNDRATES_BYFUND_START';
export const FETCH_FUNDRATES_BYFUND_SUCCESS = 'FETCH_FUNDRATES_BYFUND_SUCCESS';
export const FETCH_FUNDRATES_BYFUND_FAILURE = 'FETCH_FUNDRATES_BYFUND_FAILURE';
export const FETCH_ORDER_INVOICE_FUND_RATES_START =
  'FETCH_ORDER_INVOICE_FUND_RATES_START';
export const FETCH_ORDER_INVOICE_FUND_RATES_SUCCESS =
  'FETCH_ORDER_INVOICE_FUND_RATES_SUCCESS';
export const FETCH_ORDER_INVOICE_FUND_RATES_FAILURE =
  'FETCH_ORDER_INVOICE_FUND_RATES_FAILURE';
export const FETCH_ORDER_INVOICE_FUND_START = 'FETCH_ORDER_INVOICE_FUND_START';
export const FETCH_ORDER_INVOICE_FUND_SUCCESS =
  'FETCH_ORDER_INVOICE_FUND_SUCCESS';
export const FETCH_ORDER_INVOICE_FUND_FAILURE =
  'FETCH_ORDER_INVOICE_FUND_FAILURE';
export const UPDATE_ORDER_INVOICE_FUND2_START =
  'UPDATE_ORDER_INVOICE_FUND2_START';
export const UPDATE_ORDER_INVOICE_FUND2_SUCCESS =
  'UPDATE_ORDER_INVOICE_FUND2_SUCCESS';
export const UPDATE_ORDER_INVOICE_FUND2_FAILURE =
  'UPDATE_ORDER_INVOICE_FUND2_FAILURE';
export const REGISTER_BANK_ACCOUNT_START = 'REGISTER_BANK_ACCOUNT_START';
export const REGISTER_BANK_ACCOUNT_SUCCESS = 'REGISTER_BANK_ACCOUNT_SUCCESS';
export const REGISTER_BANK_ACCOUNT_FAILURE = 'REGISTER_BANK_ACCOUNT_FAILURE';
export const REGISTER_BANK_ACCOUNT_RESET = 'REGISTER_BANK_ACCOUNT_RESET';
export const DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';
export const SET_DEFAULT_BANK_ACCOUNT_START = 'SET_DEFAULT_BANK_ACCOUNT_START';
export const SET_DEFAULT_BANK_ACCOUNT_SUCCESS =
  'SET_DEFAULT_BANK_ACCOUNT_SUCCESS';
export const SET_DEFAULT_BANK_ACCOUNT_FAILURE =
  'SET_DEFAULT_BANK_ACCOUNT_FAILURE';
export const RESET_BANK_ACCOUNT_DIALOG = 'RESET_BANK_ACCOUNT_DIALOG';
export const SELECT_BANK_ACCOUNT = 'SELECT_BANK_ACCOUNT';
export const RESET_SELECTED_BANK_ACCOUNT = 'RESET_SELECTED_BANK_ACCOUNT';
export const UNLINK_ORDER_INVOICE_FUND_SUCCESS =
  'UNLINK_ORDER_INVOICE_FUND_SUCCESS';
export const FETCH_FUND_PAYROLL_START = 'FETCH_FUND_PAYROLL_START';
export const FETCH_FUND_PAYROLL_SUCCESS = 'FETCH_FUND_PAYROLL_SUCCESS';
export const FETCH_FUND_PAYROLL_FAILURE = 'FETCH_FUND_PAYROLL_FAILURE';
export const RESET_FUND_PAYROLL = 'RESET_FUND_PAYROLL';
export const DELETE_ORDER_INVOICE_FUND_RATE_START =
  'DELETE_ORDER_INVOICE_FUND_RATE_START';
export const DELETE_ORDER_INVOICE_FUND_RATE_SUCCESS =
  'DELETE_ORDER_INVOICE_FUND_RATE_SUCCESS';
export const DELETE_ORDER_INVOICE_FUND_RATE_FAILURE =
  'DELETE_ORDER_INVOICE_FUND_RATE_FAILURE';
export const VERIFY_ORDER_FULLY_FINANCED_START =
  'VERIFY_ORDER_FULLY_FINANCED_START';
export const VERIFY_ORDER_FULLY_FINANCED_SUCCESS =
  'VERIFY_ORDER_FULLY_FINANCED_SUCCESS';
export const VERIFY_ORDER_FULLY_FINANCED_FAILURE =
  'VERIFY_ORDER_FULLY_FINANCED_FAILURE';
export const UPLOAD_CSV_FUNDS_START = 'UPLOAD_CSV_FUNDS_START';
export const UPLOAD_CSV_FUNDS_SUCCESS = 'UPLOAD_CSV_FUNDS_SUCCESS';
export const UPLOAD_CSV_FUNDS_FAILURE = 'UPLOAD_CSV_FUNDS_FAILURE';

// ORDER FACTURAS
export const INVOICES_BULK_UPDATE_START = 'INVOICES_BULK_UPDATE_START';
export const INVOICES_BULK_UPDATE_SUCCESS = 'INVOICES_BULK_UPDATE_SUCCESS';
export const INVOICES_BULK_UPDATE_FAILURE = 'INVOICES_BULK_UPDATE_FAILURE';
export const RESET_STATE_UPDATE_BULK_INVOICES =
  'RESET_STATE_UPDATE_BULK_INVOICES';
export const RESET_STATE_FETCH_ORDER_IDS_BY_PAYER_RUT =
  'RESET_STATE_FETCH_ORDER_IDS_BY_PAYER_RUT';
export const FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_START =
  'FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_START';
export const FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_SUCCESS =
  'FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_SUCCESS';
export const FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_FAILURE =
  'FETCH_AMOUNT_INFO_BY_ORDER_INVOICE_IDS_FAILURE';
export const FETCH_ORDER_INVOICES_ATTRIBUTES_START =
  'FETCH_ORDER_INVOICES_ATTRIBUTES_START';
export const FETCH_ORDER_INVOICES_ATTRIBUTES_SUCCESS =
  'FETCH_ORDER_INVOICES_ATTRIBUTES_SUCCESS';
export const FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE =
  'FETCH_ORDER_INVOICES_ATTRIBUTES_FAILURE';
export const RESET_ORDER_FEES = 'RESET_ORDER_FEES';
export const GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_START =
  'GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_START';
export const GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_SUCCESS =
  'GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_SUCCESS';
export const GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_FAILURE =
  'GET_RESTRICTIVE_INFORMATION_NEW_SIMULATION_FAILURE';

// PAYER BLOCKLIST
export const FETCH_PAYERS_BLOCKLIST_START = 'FETCH_PAYERS_BLOCKLIST_START';
export const FETCH_PAYERS_BLOCKLIST_SUCCESS = 'FETCH_PAYERS_BLOCKLIST_SUCCESS';
export const FETCH_PAYERS_BLOCKLIST_FAILURE = 'FETCH_PAYERS_BLOCKLIST_FAILURE';
export const FETCH_PAYER_BLOCKLIST_START = 'FETCH_PAYER_BLOCKLIST_START';
export const FETCH_PAYER_BLOCKLIST_SUCCESS = 'FETCH_PAYER_BLOCKLIST_SUCCESS';
export const FETCH_PAYER_BLOCKLIST_FAILURE = 'FETCH_PAYER_BLOCKLIST_FAILURE';
export const REGISTER_PAYER_BLOCKLIST_START = 'REGISTER_PAYER_BLOCKLIST_START';
export const REGISTER_PAYER_BLOCKLIST_SUCCESS =
  'REGISTER_PAYER_BLOCKLIST_SUCCESS';
export const REGISTER_PAYER_BLOCKLIST_FAILURE =
  'REGISTER_PAYER_BLOCKLIST_FAILURE';
export const UPDATE_PAYER_BLOCKLIST_START = 'UPDATE_PAYER_BLOCKLIST_START';
export const UPDATE_PAYER_BLOCKLIST_SUCCESS = 'UPDATE_PAYER_BLOCKLIST_SUCCESS';
export const UPDATE_PAYER_BLOCKLIST_FAILURE = 'UPDATE_PAYER_BLOCKLIST_FAILURE';
export const RESET_REGISTER_PAYER_BLOCKLIST_ERROR =
  'RESET_REGISTER_PAYER_BLOCKLIST_ERROR';
export const RESET_SELECTED_PAYER_NAME = 'RESET_SELECTED_PAYER_NAME';
export const CSV_REGISTER_PAYERS_BLOCKLIST_START =
  'CSV_REGISTER_PAYERS_BLOCKLIST_START';
export const CSV_REGISTER_PAYERS_BLOCKLIST_SUCCESS =
  'CSV_REGISTER_PAYERS_BLOCKLIST_SUCCESS';
export const CSV_REGISTER_PAYERS_BLOCKLIST_FAILURE =
  'CSV_REGISTER_PAYERS_BLOCKLIST_FAILURE';
export const RESET_CSV_REGISTER_PAYERS_BLOCKLIST_ERROR =
  'RESET_CSV_REGISTER_PAYERS_BLOCKLIST_ERROR';

// PAYERS
export const RESET_PAYER_NAME = 'RESET_PAYER_NAME';
export const FETCH_PAYERS_START = 'FETCH_PAYERS_START';
export const FETCH_PAYERS_SUCCESS = 'FETCH_PAYERS_SUCCESS';
export const FETCH_PAYERS_FAILURE = 'FETCH_PAYERS_FAILURE';
export const REGISTER_PAYER_START = 'REGISTER_PAYER_START';
export const REGISTER_PAYER_SUCCESS = 'REGISTER_PAYER_SUCCESS';
export const REGISTER_PAYER_FAILURE = 'REGISTER_PAYER_FAILURE';
export const RESET_PAYER_FORM_DIALOG = 'RESET_PAYER_FORM_DIALOG';
export const RESET_PAYER_ERROR = 'RESET_PAYER_ERROR';
export const LOAD_PAYERS_CSV_START = 'LOAD_PAYERS_CSV_START';
export const LOAD_PAYERS_CSV_SUCCESS = 'LOAD_PAYERS_CSV_SUCCESS';
export const LOAD_PAYERS_CSV_FAILURE = 'LOAD_PAYERS_CSV_FAILURE';
export const RESET_BULK_CSV_PAYERS_DIALOG = 'RESET_BULK_CSV_PAYERS_DIALOG';
export const RESET_PAYER_CSV_DATA = 'RESET_PAYER_CSV_DATA';

// RUBROS
export const FETCH_SUBRUBRO_START = 'FETCH_SUBRUBRO_START';
export const FETCH_SUBRUBRO_SUCCESS = 'FETCH_SUBRUBRO_SUCCESS';
export const FETCH_SUBRUBRO_FAILURE = 'FETCH_SUBRUBRO_FAILURE';

// ECONOMIC GROUPS
export const FETCH_ECONOMIC_GROUP_START = 'FETCH_ECONOMIC_GROUP_START';
export const FETCH_ECONOMIC_GROUP_SUCCESS = 'FETCH_ECONOMIC_GROUP_SUCCESS';
export const FETCH_ECONOMIC_GROUP_FAILURE = 'FETCH_ECONOMIC_GROUP_FAILURE';

// DEBT SERVICE
export const HANDLE_SHOW_DICOM_DIALOG = 'HANDLE_SHOW_DICOM_DIALOG';
export const RESET_DICOM_DIALOG = 'RESET_DICOM_DIALOG';
export const CONSULT_DEBT_SERVICE_START = 'CONSULT_DEBT_SERVICE_START';
export const CONSULT_DEBT_SERVICE_SUCCESS = 'CONSULT_DEBT_SERVICE_SUCCESS';
export const CONSULT_DEBT_SERVICE_FAILURE = 'CONSULT_DEBT_SERVICE_FAILURE';
export const RESET_CONSULT_DEBT_SERVICE = 'RESET_CONSULT_DEBT_SERVICE';
export const FETCH_DEBT_SERVICE_START = 'FETCH_DEBT_SERVICE_START';
export const FETCH_DEBT_SERVICE_SUCCESS = 'FETCH_DEBT_SERVICE_SUCCESS';
export const FETCH_DEBT_SERVICE_FAILURE = 'FETCH_DEBT_SERVICE_FAILURE';
export const FETCH_DEBT_SERVICE_BYIDENTIFIER_START =
  'FETCH_DEBT_SERVICE_BYIDENTIFIER_START';
export const FETCH_DEBT_SERVICE_BYIDENTIFIER_SUCCESS =
  'FETCH_DEBT_SERVICE_BYIDENTIFIER_SUCCESS';
export const FETCH_DEBT_SERVICE_BYIDENTIFIER_FAILURE =
  'FETCH_DEBT_SERVICE_BYIDENTIFIER_FAILURE';
export const CONSULT_DEBT_SERVICE_BYIDENTIFIER_START =
  'CONSULT_DEBT_SERVICE_BYIDENTIFIER_START';
export const CONSULT_DEBT_SERVICE_BYIDENTIFIER_SUCCESS =
  'CONSULT_DEBT_SERVICE_BYIDENTIFIER_SUCCESS';
export const CONSULT_DEBT_SERVICE_BYIDENTIFIER_FAILURE =
  'CONSULT_DEBT_SERVICE_BYIDENTIFIER_FAILURE';
export const CONSULT_INVOICE_DEBT_SERVICE_START =
  'CONSULT_INVOICE_DEBT_SERVICE_START';
export const CONSULT_INVOICE_DEBT_SERVICE_SUCCESS =
  'CONSULT_INVOICE_DEBT_SERVICE_SUCCESS';
export const CONSULT_INVOICE_DEBT_SERVICE_FAILURE =
  'CONSULT_INVOICE_DEBT_SERVICE_FAILURE';
export const BULK_FETCH_DEBT_SERVICE_START = 'BULK_FETCH_DEBT_SERVICE_START';
export const BULK_FETCH_DEBT_SERVICE_SUCCESS =
  'BULK_FETCH_DEBT_SERVICE_SUCCESS';
export const BULK_FETCH_DEBT_SERVICE_FAILURE =
  'BULK_FETCH_DEBT_SERVICE_FAILURE';
export const FETCH_DEBT_SUPER_START = 'FETCH_DEBT_SUPER_START';
export const FETCH_DEBT_SUPER_SUCCESS = 'FETCH_DEBT_SUPER_SUCCESS';
export const FETCH_DEBT_SUPER_FAILURE = 'FETCH_DEBT_SUPER_FAILURE';
export const CONSULT_DEBT_SUPER_START = 'CONSULT_DEBT_SUPER_START';
export const CONSULT_DEBT_SUPER_SUCCESS = 'CONSULT_DEBT_SUPER_SUCCESS';
export const CONSULT_DEBT_SUPER_FAILURE = 'CONSULT_DEBT_SUPER_FAILURE';
export const CONSULT_INVOICE_DEBT_SUPER_START =
  'CONSULT_INVOICE_DEBT_SUPER_START';
export const CONSULT_INVOICE_DEBT_SUPER_SUCCESS =
  'CONSULT_INVOICE_DEBT_SUPER_SUCCESS';
export const CONSULT_INVOICE_DEBT_SUPER_FAILURE =
  'CONSULT_INVOICE_DEBT_SUPER_FAILURE';
export const BULK_FETCH_DEBT_SUPER_START = 'BULK_FETCH_DEBT_SUPER_START';
export const BULK_FETCH_DEBT_SUPER_SUCCESS = 'BULK_FETCH_DEBT_SUPER_SUCCESS';
export const BULK_FETCH_DEBT_SUPER_FAILURE = 'BULK_FETCH_DEBT_SUPER_FAILURE';

// BANK
export const FETCH_BANKS_START = 'FETCH_BANKS_START';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILURE = 'FETCH_BANKS_FAILURE';
export const REGISTER_BANK_ACCOUNT_INPUT_CHANGE =
  'REGISTER_BANK_ACCOUNT_INPUT_CHANGE';

// SII
export const FETCH_COMPANY_NAME_BY_RUT_START =
  'FETCH_COMPANY_NAME_BY_RUT_START';
export const FETCH_COMPANY_NAME_BY_RUT_SUCCESS =
  'FETCH_COMPANY_NAME_BY_RUT_SUCCESS';
export const FETCH_COMPANY_NAME_BY_RUT_FAILURE =
  'FETCH_COMPANY_NAME_BY_RUT_FAILURE';
export const CHECK_TAX_START = 'CHECK_TAX_START';
export const CHECK_TAX_SUCCESS = 'CHECK_TAX_SUCCESS';
export const CHECK_TAX_FAILURE = 'CHECK_TAX_FAILURE';

// CREDITS
export const FETCH_CREDITS_START = 'FETCH_CREDITS_START';
export const FETCH_CREDITS_SUCCESS = 'FETCH_CREDITS_SUCCESS';
export const FETCH_CREDITS_FAILURE = 'FETCH_CREDITS_FAILURE';
export const UPDATE_CREDIT_START = 'UPDATE_CREDIT_START';
export const UPDATE_CREDIT_SUCCESS = 'UPDATE_CREDIT_SUCCESS';
export const UPDATE_CREDIT_FAILURE = 'UPDATE_CREDIT_FAILURE';
export const REGISTER_CREDIT_START = 'REGISTER_CREDIT_START';
export const REGISTER_CREDIT_SUCCESS = 'REGISTER_CREDIT_SUCCESS';
export const REGISTER_CREDIT_FAILURE = 'REGISTER_CREDIT_FAILURE';
export const FETCH_FEES_START = 'FETCH_FEES_START';
export const FETCH_FEES_SUCCESS = 'FETCH_FEES_SUCCESS';
export const FETCH_FEES_FAILURE = 'FETCH_FEES_FAILURE';
export const FETCH_SELECTED_CREDIT_START = 'FETCH_SELECTED_CREDIT_START';
export const FETCH_SELECTED_CREDIT_SUCCESS = 'FETCH_SELECTED_CREDIT_SUCCESS';
export const FETCH_SELECTED_CREDIT_FAILURE = 'FETCH_SELECTED_CREDIT_FAILURE';

// LINK FUND
export const CLEAR_LINK_FUND_RATES = 'CLEAR_LINK_FUND_RATES';
export const FETCH_LINK_FUND_RATES_START = 'FETCH_LINK_FUND_RATES_START';
export const FETCH_LINK_FUND_RATES_SUCCESS = 'FETCH_LINK_FUND_RATES_SUCCESS';
export const FETCH_LINK_FUND_RATES_FAILURE = 'FETCH_LINK_FUND_RATES_FAILURE';

export const FETCH_LINK_FUND_START = 'FETCH_LINK_FUND_START';
export const FETCH_LINK_FUND_SUCCESS = 'FETCH_LINK_FUND_SUCCESS';
export const FETCH_LINK_FUND_FAILURE = 'FETCH_LINK_FUND_FAILURE';

export const FETCH_LINK_FUND_SUMMARY_START = 'FETCH_LINK_FUND_SUMMARY_START';
export const FETCH_LINK_FUND_SUMMARY_SUCCESS =
  'FETCH_LINK_FUND_SUMMARY_SUCCESS';
export const FETCH_LINK_FUND_SUMMARY_FAILURE =
  'FETCH_LINK_FUND_SUMMARY_FAILURE';

export const UPDATE_LINK_FUND_START = 'UPDATE_LINK_FUND_START';
export const UPDATE_LINK_FUND_SUCCESS = 'UPDATE_LINK_FUND_SUCCESS';
export const UPDATE_LINK_FUND_FAILURE = 'UPDATE_LINK_FUND_FAILURE';

export const REGISTER_LINK_FUND_START = 'REGISTER_LINK_FUND_START';
export const REGISTER_LINK_FUND_SUCCESS = 'REGISTER_LINK_FUND_SUCCESS';
export const REGISTER_LINK_FUND_FAILURE = 'REGISTER_LINK_FUND_FAILURE';

export const RESET_CREDITS_NOTIFICATIONS = 'RESET_CREDITS_NOTIFICATIONS';
export const UPDATE_FEE_START = 'UPDATE_FEE_START';
export const UPDATE_FEE_SUCCESS = 'UPDATE_FEE_SUCCESS';
export const UPDATE_FEE_FAILURE = 'UPDATE_FEE_FAILURE';
export const REGISTER_FEE_START = 'REGISTER_FEE_START';
export const REGISTER_FEE_SUCCESS = 'REGISTER_FEE_SUCCESS';
export const REGISTER_FEE_FAILURE = 'REGISTER_FEE_FAILURE';
export const RESET_SELECTED_CREDIT = 'RESET_SELECTED_CREDIT';
export const DELETE_FEE_START = 'DELETE_FEE_START';
export const DELETE_FEE_SUCCESS = 'DELETE_FEE_SUCCESS';
export const DELETE_FEE_FAILURE = 'DELETE_FEE_FAILURE';

// INVOICES
export const FETCH_BUSINESS_PAYERS_START = 'FETCH_BUSINESS_PAYERS_START';
export const FETCH_BUSINESS_PAYERS_SUCCESS = 'FETCH_BUSINESS_PAYERS_SUCCESS';
export const FETCH_BUSINESS_PAYERS_FAILURE = 'FETCH_BUSINESS_PAYERS_FAILURE';
export const RESET_BUSINESS_PAYERS = 'RESET_BUSINESS_PAYERS';
export const RESET_INVOICES_CSV_TO_ORDER = 'RESET_INVOICES_CSV_TO_ORDER';
export const RESET_CLIENT_NAME_NOT_ENROLLED = 'RESET_CLIENT_NAME_NOT_ENROLLED';
export const FETCH_PAYER_INVOICES_START = 'FETCH_PAYER_INVOICES_START';
export const FETCH_PAYER_INVOICES_SUCCESS = 'FETCH_PAYER_INVOICES_SUCCESS';
export const FETCH_PAYER_INVOICES_FAILURE = 'FETCH_PAYER_INVOICES_FAILURE';
export const FETCH_AVAILABLE_INVOICES_START = 'FETCH_AVAILABLE_INVOICES_START';
export const FETCH_AVAILABLE_INVOICES_SUCCESS =
  'FETCH_AVAILABLE_INVOICES_SUCCESS';
export const FETCH_AVAILABLE_INVOICES_FAILURE =
  'FETCH_AVAILABLE_INVOICES_FAILURE';
export const FETCH_SII_INVOICES_SUCCESS = 'FETCH_SII_INVOICES_SUCCESS';

// FEES
export const FEES_CALCULATE_START = 'FEES_CALCULATE_START';
export const FEES_CALCULATE_SUCCESS = 'FEES_CALCULATE_SUCCESS';
export const FEES_CALCULATE_ERROR = 'FEES_CALCULATE_ERROR';

// IMPERSONATE USER IN APP
export const IMPERSONATE_USERS_START = 'IMPERSONATE_USERS_START';
export const IMPERSONATE_USERS_SUCCESS = 'IMPERSONATE_USERS_SUCCESS';
export const IMPERSONATE_USERS_FAILURE = 'IMPERSONATE_USERS_FAILURE';

// COLLECTION
export const FETCH_COLLECTION_INVOICES_START =
  'FETCH_COLLECTION_INVOICES_START';
export const FETCH_COLLECTION_INVOICES_SUCCESS =
  'FETCH_COLLECTION_INVOICES_SUCCESS';
export const FETCH_COLLECTION_INVOICES_FAILURE =
  'FETCH_COLLECTION_INVOICES_FAILURE';
export const FETCH_COLLECTION_INVOICE_DETAIL_START =
  'FETCH_COLLECTION_INVOICE_DETAIL_START';
export const FETCH_COLLECTION_INVOICE_DETAIL_SUCCESS =
  'FETCH_COLLECTION_INVOICE_DETAIL_SUCCESS';
export const FETCH_COLLECTION_INVOICE_DETAIL_FAILURE =
  'FETCH_COLLECTION_INVOICE_DETAIL_FAILURE';
export const BULK_UPDATE_COLLECTION_INVOICES_START =
  'BULK_UPDATE_COLLECTION_INVOICES_START';
export const BULK_UPDATE_COLLECTION_INVOICES_SUCCESS =
  'BULK_UPDATE_COLLECTION_INVOICES_SUCCESS';
export const BULK_UPDATE_COLLECTION_INVOICES_FAILURE =
  'BULK_UPDATE_COLLECTION_INVOICES_FAILURE';
export const FETCH_COLLECTION_PAYERS_START = 'FETCH_COLLECTION_PAYERS_START';
export const FETCH_COLLECTION_PAYERS_SUCCESS =
  'FETCH_COLLECTION_PAYERS_SUCCESS';
export const FETCH_COLLECTION_PAYERS_FAILURE =
  'FETCH_COLLECTION_PAYERS_FAILURE';

export const VERIFY_INVOICE_START = 'VERIFY_INVOICE_START';
export const VERIFY_INVOICE_SUCCESS = 'VERIFY_INVOICE_SUCCESS';
export const VERIFY_INVOICE_FAILURE = 'VERIFY_INVOICE_FAILURE';

// ACCOUNT
export const FETCH_ACCOUNT_BALANCE_START = 'FETCH_ACCOUNT_BALANCE_START';
export const FETCH_ACCOUNT_BALANCE_SUCCESS = 'FETCH_ACCOUNT_BALANCE_SUCCESS';
export const FETCH_ACCOUNT_BALANCE_FAILURE = 'FETCH_ACCOUNT_BALANCE_FAILURE';

// BUSINESS
export const UPLOAD_FILE_BUSINESS_START = 'UPLOAD_FILE_BUSINESS_START';
export const UPLOAD_FILE_BUSINESS_SUCCESS = 'UPLOAD_FILE_BUSINESS_SUCCESS';
export const UPLOAD_FILE_BUSINESS_FAILURE = 'UPLOAD_FILE_BUSINESS_FAILURE';
export const FETCH_BUSINESS_USERS_START = 'FETCH_BUSINESS_USERS_START';
export const FETCH_BUSINESS_USERS_SUCCESS = 'FETCH_BUSINESS_USERS_SUCCESS';
export const FETCH_BUSINESS_USERS_FAILURE = 'FETCH_BUSINESS_USERS_FAILURE';

// NOTIFICATIONS
export const SET_LOADING = 'SET_LOADING';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_FIRST_NOTIFICATION = 'REMOVE_FIRST_NOTIFICATION';

// AGREEMENTS GROUPS
export const FETCH_AGREEMENTS_GROUPS_START = 'FETCH_AGREEMENTS_GROUPS_START';
export const FETCH_AGREEMENTS_GROUPS_SUCCESS =
  'FETCH_AGREEMENTS_GROUPS_SUCCESS';
export const FETCH_AGREEMENTS_GROUPS_FAILURE =
  'FETCH_AGREEMENTS_GROUPS_FAILURE';

// TGR AGREEMENTSS
export const FETCH_TGR_AGREEMENTS_START = 'FETCH_TGR_AGREEMENTS_START';
export const FETCH_TGR_AGREEMENTS_SUCCESS = 'FETCH_TGR_AGREEMENTS_SUCCESS';
export const FETCH_TGR_AGREEMENTS_FAILURE = 'FETCH_TGR_AGREEMENTS_FAILURE';

// TGR DEBT
export const FETCH_TGR_DEBT_START = 'FETCH_TGR_DEBT_START';
export const FETCH_TGR_DEBT_SUCCESS = 'FETCH_TGR_DEBT_SUCCESS';
export const FETCH_TGR_DEBT_FAILURE = 'FETCH_TGR_DEBT_FAILURE';

// GET PAYER CONTACTS
export const GET_ALL_CONTACT_PAYER_START = 'GET_ALL_CONTACT_PAYER_START';
export const GET_ALL_CONTACT_PAYER_SUCCESS = 'GET_ALL_CONTACT_PAYER_SUCCESS';
export const GET_ALL_CONTACT_PAYER_FAILURE = 'GET_ALL_CONTACT_PAYER_FAILURE';
export const RESET_PAYER_CONTACTS_CSV_DATA = 'RESET_PAYER_CONTACTS_CSV_DATA';

// CREATE PAYER CONTACT
export const CREATE_CONTACT_PAYER_START = 'CREATE_CONTACT_PAYER_START';
export const CREATE_CONTACT_PAYER_SUCCESS = 'CREATE_CONTACT_PAYER_SUCCESS';
export const CREATE_CONTACT_PAYER_FAILURE = 'CREATE_CONTACT_PAYER_FAILURE';

// EDIT PAYER CONTACT

export const EDIT_CONTACT_PAYER_START = 'EDIT_CONTACT_PAYER_START';
export const EDIT_CONTACT_PAYER_SUCCESS = 'EDIT_CONTACT_PAYER_SUCCESS';
export const EDIT_CONTACT_PAYER_FAILURE = 'EDIT_CONTACT_PAYER_FAILURE';

// DELETE PAYER CONTACT

export const DELETE_CONTACT_PAYER_START = 'DELETE_CONTACT_PAYER_START';
export const DELETE_CONTACT_PAYER_SUCCESS = 'DELETE_CONTACT_PAYER_SUCCESS';
export const DELETE_CONTACT_PAYER_FAILURE = 'DELETE_CONTACT_PAYER_FAILURE';

// BANK ACCOUNTS
export const FETCH_BANK_ACCOUNTS_START = 'FETCH_BANK_ACCOUNTS_START';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'FETCH_BANK_ACCOUNTS_FAILURE';
export const FETCH_BANK_ACCOUNTS_BY_ID_START =
  'FETCH_BANK_ACCOUNTS_BY_ID_START';
export const FETCH_BANK_ACCOUNTS_BY_ID_SUCCESS =
  'FETCH_BANK_ACCOUNTS_BY_ID_SUCCESS';
export const FETCH_BANK_ACCOUNTS_BY_ID_FAILURE =
  'FETCH_BANK_ACCOUNTS_BY_ID_FAILURE';
export const EDIT_BANK_ACCOUNT_START = 'EDIT_BANK_ACCOUNT_START';
export const EDIT_BANK_ACCOUNT_SUCCESS = 'EDIT_BANK_ACCOUNT_SUCCESS';
export const EDIT_BANK_ACCOUNT_FAILURE = 'EDIT_BANK_ACCOUNT_FAILURE';
export const BANK_ACCOUNT_RESET = 'BANK_ACCOUNT_RESET';

// PAYERS (DOWNLOAD CSV)

export const FETCH_INFO_PAYERS_TO_CSV_START = 'FETCH_INFO_PAYERS_TO_CSV_START';
export const FETCH_INFO_PAYERS_TO_CSV_SUCCESS =
  'FETCH_INFO_PAYERS_TO_CSV_SUCCESS';
export const FETCH_INFO_PAYERS_TO_CSV_FAILURE =
  'FETCH_INFO_PAYERS_TO_CSV_FAILURE';

// MIGRACIONCL CONTACTS (DOWNLOAD CSV). Delete after migracion (use contactsActions and contactsReducer for everything contact related)
export const FETCH_CONTACTS_CSV_MIGRACIONCL_START =
  'FETCH_CONTACTS_CSV_MIGRACIONCL_START';
export const FETCH_CONTACTS_CSV_MIGRACIONCL_SUCCESS =
  'FETCH_CONTACTS_CSV_MIGRACIONCL_SUCCESS';
export const FETCH_CONTACTS_CSV_MIGRACIONCL_FAILURE =
  'FETCH_CONTACTS_CSV_MIGRACIONCL_FAILURE';

// LOGS
export const FETCH_LOGS_START = 'FETCH_LOGS_START';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';
export const FETCH_LOGS_FAILURE = 'FETCH_LOGS_FAILURE';

export const REQUEST_DEBT_SUPER_BY_RUT_START =
  'REQUEST_DEBT_SUPER_BY_RUT_START';
export const REQUEST_DEBT_SUPER_BY_RUT_SUCCESS =
  'REQUEST_DEBT_SUPER_BY_RUT_SUCCESS';
export const REQUEST_DEBT_SUPER_BY_RUT_FAILURE =
  'REQUEST_DEBT_SUPER_BY_RUT_FAILURE';
// WALLET
export const SET_WALLET_ACTIVE_FILTERS = 'SET_WALLET_ACTIVE_FILTERS';
export const SET_WALLET_AVAILABLE_FILTERS = 'SET_WALLET_AVAILABLE_FILTERS';
export const FETCH_WALLETS_START = 'FETCH_WALLETS_START';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_FAILURE = 'FETCH_WALLETS_FAILURE';
export const FETCH_TRANSACTIONS_START = 'FETCH_TRANSACTIONS_START';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';
export const FETCH_PENDING_REQUESTS_START = 'FETCH_PENDING_REQUESTS_START';
export const FETCH_ALL_REQUESTS_START = 'FETCH_ALL_REQUESTS_START';
export const FETCH_REQUESTS_SUCCESS = 'FETCH_REQUESTS_SUCCESS';
export const FETCH_REQUESTS_FAILURE = 'FETCH_REQUESTS_FAILURE';
export const FETCH_HTML_REPORT = 'FETCH_HTML_REPORT';
export const FETCH_HTML_REPORT_SUCCESS = 'FETCH_HTML_REPORT_SUCCESS';
export const FETCH_HTML_REPORT_FAILURE = 'FETCH_HTML_REPORT_FAILURE';
export const UPDATE_WALLET_STATUS_START = 'UPDATE_WALLET_STATUS_START';
export const UPDATE_WALLET_STATUS_SUCCESS = 'UPDATE_WALLET_STATUS_SUCCESS';
export const UPDATE_WALLET_STATUS_FAILURE = 'UPDATE_WALLET_STATUS_FAILURE';
export const UPDATE_REQUEST_STATUS_START = 'UPDATE_REQUEST_STATUS_START';
export const UPDATE_REQUEST_STATUS_SUCCESS = 'UPDATE_REQUEST_STATUS_SUCCESS';
export const UPDATE_REQUEST_STATUS_FAILURE = 'UPDATE_REQUEST_STATUS_FAILURE';
export const CREATE_TRANSACTION_START = 'CREATE_TRANSACTION_START';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE';
export const HANDLE_WALLET_SELECTION_CHANGE = 'HANDLE_WALLET_SELECTION_CHANGE';
export const RESET_REQUESTS = 'RESET_REQUESTS';

// EXTERNAL PDF URL
export const LOAD_EXTERNAL_PDF_START = 'LOAD_EXTERNAL_PDF_START';
export const LOAD_EXTERNAL_PDF_SUCCESS = 'LOAD_EXTERNAL_PDF_SUCCESS';
export const LOAD_EXTERNAL_PDF_FAILURE = 'LOAD_EXTERNAL_PDF_FAILURE';

// COPY TO CLIPBOARD
export const COPY_EXTERNAL_PDF_URL_START = 'COPY_EXTERNAL_PDF_URL_START';
export const COPY_EXTERNAL_PDF_URL_END = 'COPY_EXTERNAL_PDF_URL_END';

// UI STATE
export const SET_REMOTE_VERSION = 'SET_REMOTE_VERSION';

// DOCUMENTS
export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const FETCH_DOCUMENTS_TYPES_START = 'FETCH_DOCUMENTS_TYPES_START';
export const FETCH_DOCUMENTS_TYPES_SUCCESS = 'FETCH_DOCUMENTS_TYPES_SUCCESS';
export const FETCH_DOCUMENTS_TYPES_FAILURE = 'FETCH_DOCUMENTS_TYPES_FAILURE';
export const VERIFY_DOCUMENTS_START = 'VERIFY_DOCUMENTS_START';
export const VERIFY_DOCUMENTS_SUCCESS = 'VERIFY_DOCUMENTS_SUCCESS';
export const VERIFY_DOCUMENTS_FAILURE = 'VERIFY_DOCUMENTS_FAILURE';
export const VERIFY_DOCUMENTS_RESET = 'VERIFY_DOCUMENTS_RESET';
export const DOWNLOAD_DOCUMENT_START = 'DOWNLOAD_DOCUMENT_START';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAILURE = 'DOWNLOAD_DOCUMENT_FAILURE';
export const UPDATE_DOCUMENT_START = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';
export const UPDATE_SIGNED_DOCUMENT_START = 'UPDATE_SIGNED_DOCUMENT_START';
export const UPDATE_SIGNED_DOCUMENT_SUCCESS = 'UPDATE_SIGNED_DOCUMENT_SUCCESS';
export const UPDATE_SIGNED_DOCUMENT_FAILURE = 'UPDATE_SIGNED_DOCUMENT_FAILURE';
export const CREATE_DOCUMENT_START = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';
export const APPROVE_REJECT_DOCUMENT_START = 'APPROVE_REJECT_DOCUMENT_START';
export const APPROVE_REJECT_DOCUMENT_SUCCESS =
  'APPROVE_REJECT_DOCUMENT_SUCCESS';
export const APPROVE_REJECT_DOCUMENT_FAILURE =
  'APPROVE_REJECT_DOCUMENT_FAILURE';
export const CANCEL_MIFIEL_DOCUMENT_START = 'CANCEL_MIFIEL_DOCUMENT_START';
export const CANCEL_MIFIEL_DOCUMENT_SUCCESS = 'CANCEL_MIFIEL_DOCUMENT_SUCCESS';
export const CANCEL_MIFIEL_DOCUMENT_FAILURE = 'CANCEL_MIFIEL_DOCUMENT_FAILURE';
export const RESET_DOCUMENT_ERRORS = 'RESET_DOCUMENT_ERRORS';
export const LOAD_DOCUMENTS_START = 'LOAD_DOCUMENTS_START';
export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_START';
export const LOAD_DOCUMENTS_FAILURE = 'LOAD_DOCUMENTS_START';
export const FETCH_DOCUMENT_BASE64_START = 'FETCH_DOCUMENT_BASE64_START';
export const FETCH_DOCUMENT_BASE64_SUCCESS = 'FETCH_DOCUMENT_BASE64_SUCCESS';
export const FETCH_DOCUMENT_BASE64_FAILURE = 'FETCH_DOCUMENT_BASE64_FAILURE';
export const UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_START =
  'UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_START';
export const UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_SUCCESS =
  'UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_SUCCESS';
export const UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_FAILURE =
  'UPDATE_ELECTRONIC_SIGNATURE_DOCUMENT_FAILURE';
export const FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_START =
  'FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_START';
export const FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_SUCCESS =
  'FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_SUCCESS';
export const FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE =
  'FETCH_SIGNATORIES_ELECTRONIC_SIGNATURE_FAILURE';

// STAKEHOLDERS
export const FETCH_STAKEHOLDERS_START = 'FETCH_STAKEHOLDERS_START';
export const FETCH_STAKEHOLDERS_SUCCESS = 'FETCH_STAKEHOLDERS_SUCCESS';
export const FETCH_STAKEHOLDERS_FAILURE = 'FETCH_STAKEHOLDERS_FAILURE';
export const UPDATE_STAKEHOLDER_START = 'UPDATE_STAKEHOLDER_START';
export const UPDATE_STAKEHOLDER_SUCCESS = 'UPDATE_STAKEHOLDER_SUCCESS';
export const UPDATE_STAKEHOLDER_FAILURE = 'UPDATE_STAKEHOLDER_FAILURE';
export const DELETE_STAKEHOLDER_START = 'DELETE_STAKEHOLDER_START';
export const DELETE_STAKEHOLDER_SUCCESS = 'DELETE_STAKEHOLDER_SUCCESS';
export const DELETE_STAKEHOLDER_FAILURE = 'DELETE_STAKEHOLDER_FAILURE';
export const CREATE_STAKEHOLDER_START = 'CREATE_STAKEHOLDER_START';
export const CREATE_STAKEHOLDER_SUCCESS = 'CREATE_STAKEHOLDER_SUCCESS';
export const CREATE_STAKEHOLDER_FAILURE = 'CREATE_STAKEHOLDER_FAILURE';
export const RESET_STAKEHOLDER_ERRORS = 'RESET_STAKEHOLDER_ERRORS';

// GEO
export const FETCH_GEO_MX_STATES_START = 'FETCH_GEO_MX_STATES_START';
export const FETCH_GEO_MX_STATES_SUCCESS = 'FETCH_GEO_MX_STATES_SUCCESS';
export const FETCH_GEO_MX_STATES_FAILURE = 'FETCH_GEO_MX_STATES_FAILURE';
export const FETCH_GEO_MX_MUNICIPALITY_START =
  'FETCH_GEO_MX_MUNICIPALITY_START';
export const FETCH_GEO_MX_MUNICIPALITY_SUCCESS =
  'FETCH_GEO_MX_MUNICIPALITY_SUCCESS';
export const FETCH_GEO_MX_MUNICIPALITY_FAILURE =
  'FETCH_GEO_MX_MUNICIPALITY_FAILURE';
export const FETCH_GEO_MX_COLONY_START = 'FETCH_GEO_MX_COLONY_START';
export const FETCH_GEO_MX_COLONY_SUCCESS = 'FETCH_GEO_MX_COLONY_SUCCESS';
export const FETCH_GEO_MX_COLONY_FAILURE = 'FETCH_GEO_MX_COLONY_FAILURE';

// RATES
export const FETCH_BUSINESS_RATES_START = 'FETCH_BUSINESS_RATES_START';
export const FETCH_BUSINESS_RATES_SUCCESS = 'FETCH_BUSINESS_RATES_SUCCESS';
export const FETCH_BUSINESS_RATES_FAILURE = 'FETCH_BUSINESS_RATES_FAILURE';
export const FETCH_ORDER_INVOICE_BUSINESS_RATES_START =
  'FETCH_ORDER_INVOICE_BUSINESS_RATES_START';
export const FETCH_ORDER_INVOICE_BUSINESS_RATES_SUCCESS =
  'FETCH_ORDER_INVOICE_BUSINESS_RATES_SUCCESS';
export const FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE =
  'FETCH_ORDER_INVOICE_BUSINESS_RATES_FAILURE';
export const ADD_ORDER_INVOICE_BUSINESS_RATES_START =
  'ADD_ORDER_INVOICE_BUSINESS_RATES_START';
export const ADD_ORDER_INVOICE_BUSINESS_RATES_SUCCESS =
  'ADD_ORDER_INVOICE_BUSINESS_RATES_SUCCESS';
export const ADD_ORDER_INVOICE_BUSINESS_RATES_FAILURE =
  'ADD_ORDER_INVOICE_BUSINESS_RATES_FAILURE';
export const UPDATE_ORDER_INVOICE_BUSINESS_RATES_START =
  'UPDATE_ORDER_INVOICE_BUSINESS_RATES_START';
export const UPDATE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS =
  'UPDATE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS';
export const UPDATE_ORDER_INVOICE_BUSINESS_RATES_FAILURE =
  'UPDATE_ORDER_INVOICE_BUSINESS_RATES_FAILURE';
export const DELETE_ORDER_INVOICE_BUSINESS_RATES_START =
  'DELETE_ORDER_INVOICE_BUSINESS_RATES_START';
export const DELETE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS =
  'DELETE_ORDER_INVOICE_BUSINESS_RATES_SUCCESS';
export const DELETE_ORDER_INVOICE_BUSINESS_RATES_FAILURE =
  'DELETE_ORDER_INVOICE_BUSINESS_RATES_FAILURE';
export const RESET_RATES_ACTIONS = 'RESET_RATES_ACTIONS';
export const FETCH_SIMULATION_FOR_ORDER_START =
  'FETCH_SIMULATION_FOR_ORDER_START';
export const FETCH_SIMULATION_FOR_ORDER_SUCCESS =
  'FETCH_SIMULATION_FOR_ORDER_SUCCESS';
export const FETCH_SIMULATION_FOR_ORDER_FAILURE =
  'FETCH_SIMULATION_FOR_ORDER_FAILURE';
// EMAIL VERIFICATION
export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

// DISCOUNTS
export const FETCH_DISCOUNT_START = 'FETCH_DISCOUNT_START';
export const FETCH_DISCOUNT_SUCCESS = 'FETCH_DISCOUNT_SUCCESS';
export const FETCH_DISCOUNT_FAILURE = 'FETCH_DISCOUNT_FAILURE';
export const CREATE_DISCOUNT_START = 'CREATE_DISCOUNT_START';
export const CREATE_DISCOUNT_SUCCESS = 'CREATE_DISCOUNT_SUCCESS';
export const CREATE_DISCOUNT_FAILURE = 'CREATE_DISCOUNT_FAILURE';
export const UPDATE_DISCOUNT_START = 'UPDATE_DISCOUNT_START';
export const UPDATE_DISCOUNT_SUCCESS = 'UPDATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_FAILURE = 'UPDATE_DISCOUNT_FAILURE';
export const DELETE_DISCOUNT_START = 'DELETE_DISCOUNT_START';
export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';
export const DELETE_DISCOUNT_FAILURE = 'DELETE_DISCOUNT_FAILURE';
export const FETCH_REPLACEMENT_INVOICES_START =
  'FETCH_REPLACEMENT_INVOICES_START';
export const FETCH_REPLACEMENT_INVOICES_SUCCESS =
  'FETCH_REPLACEMENT_INVOICES_SUCCESS';
export const FETCH_REPLACEMENT_INVOICES_FAILURE =
  'FETCH_REPLACEMENT_INVOICES_FAILURE';
export const RESET_DISCOUNTS = 'RESET_DISCOUNTS';

// CONTACTS
export const FETCH_CONTACTS_START = 'FETCH_CONTACTS_START';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';
export const CREATE_CONTACT_START = 'CREATE_CONTACT_START';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';
export const UPDATE_CONTACT_START = 'UPDATE_CONTACT_START';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE';
export const DELETE_CONTACT_START = 'DELETE_CONTACT_START';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';
export const FETCH_CONTACTS_CSV_START = 'FETCH_CONTACTS_CSV_START';
export const FETCH_CONTACTS_CSV_SUCCESS = 'FETCH_CONTACTS_CSV_SUCCESS';
export const FETCH_CONTACTS_CSV_FAILURE = 'FETCH_CONTACTS_CSV_FAILURE';
export const LOAD_CONTACTS_CSV_START = 'LOAD_CONTACTS_CSV_START';
export const LOAD_CONTACTS_CSV_SUCCESS = 'LOAD_CONTACTS_CSV_SUCCESS';
export const LOAD_CONTACTS_CSV_FAILURE = 'LOAD_CONTACTS_CSV_FAILURE';
export const RESET_CONTACTS_CSV_ERROR = 'RESET_CONTACTS_CSV_ERROR';
// ENTITY FEATURE
export const FETCH_ENTITY_FEATURES_START = 'FETCH_ENTITY_FEATURES_START';
export const FETCH_ENTITY_FEATURES_SUCCESS = 'FETCH_ENTITY_FEATURES_SUCCESS';
export const FETCH_ENTITY_FEATURES_FAILURE = 'FETCH_ENTITY_FEATURES_FAILURE';
export const CREATE_ENTITY_FEATURE_START = 'CREATE_ENTITY_FEATURE_START';
export const CREATE_ENTITY_FEATURE_SUCCESS = 'CREATE_ENTITY_FEATURE_SUCCESS';
export const CREATE_ENTITY_FEATURE_FAILURE = 'CREATE_ENTITY_FEATURE_FAILURE';
export const UPDATE_ENTITY_FEATURE_START = 'UPDATE_ENTITY_FEATURE_START';
export const UPDATE_ENTITY_FEATURE_SUCCESS = 'UPDATE_ENTITY_FEATURE_SUCCESS';
export const UPDATE_ENTITY_FEATURE_FAILURE = 'UPDATE_ENTITY_FEATURE_FAILURE';

// TAXFOLDER
export const FETCH_TAX_FOLDER_START = 'FETCH_TAX_FOLDER_START';
export const FETCH_TAX_FOLDER_SUCCESS = 'FETCH_TAX_FOLDER_SUCCESS';
export const FETCH_TAX_FOLDER_FAILURE = 'FETCH_TAX_FOLDER_FAILURE';
export const CHECK_TAX_FOLDER_START = 'CHECK_TAX_FOLDER_START';
export const CHECK_TAX_FOLDER_SUCCESS = 'CHECK_TAX_FOLDER_SUCCESS';
export const CHECK_TAX_FOLDER_FAILURE = 'CHECK_TAX_FOLDER_FAILURE';

// FUND INVOICES
export const FETCH_FUND_PAYERS_START = 'FETCH_FUND_PAYERS_START';
export const FETCH_FUND_PAYERS_SUCCESS = 'FETCH_FUND_PAYERS_SUCCESS';
export const FETCH_FUND_PAYERS_FAILURE = 'FETCH_FUND_PAYERS_FAILURE';
export const FETCH_FUND_PAYER_DETAIL_INFO_START =
  'FETCH_FUND_PAYER_DETAIL_INFO_START';
export const FETCH_FUND_PAYER_DETAIL_INFO_SUCCESS =
  'FETCH_FUND_PAYER_DETAIL_INFO_SUCCESS';
export const FETCH_FUND_PAYER_DETAIL_INFO_FAILURE =
  'FETCH_FUND_PAYER_DETAIL_INFO_FAILURE';
export const FETCH_FUND_PAYER_DETAIL_INVOICES_START =
  'FETCH_FUND_PAYER_DETAIL_INVOICES_START';
export const FETCH_FUND_PAYER_DETAIL_INVOICES_SUCCESS =
  'FETCH_FUND_PAYER_DETAIL_INVOICES_SUCCESS';
export const FETCH_FUND_PAYER_DETAIL_INVOICES_FAILURE =
  'FETCH_FUND_PAYER_DETAIL_INVOICES_FAILURE';
export const FETCH_FUNDS_LIST_START = 'FETCH_FUNDS_LIST_START';
export const FETCH_FUNDS_LIST_SUCCESS = 'FETCH_FUNDS_LIST_SUCCESS';
export const FETCH_FUNDS_LIST_FAILURE = 'FETCH_FUNDS_LIST_FAILURE';
export const UPDATE_SELECTED_INVOICES = 'UPDATE_SELECTED_INVOICES';
export const REMOVE_SELECTED_INVOICE = 'REMOVE_SELECTED_INVOICE';
export const HANDLE_SHOW_FUND_MANAGE_INVOICES =
  'HANDLE_SHOW_FUND_MANAGE_INVOICES';
export const SEND_PAYROLL_START = 'SEND_PAYROLL_START';
export const SEND_PAYROLL_SUCCESS = 'SEND_PAYROLL_SUCCESS';
export const SEND_PAYROLL_FAILURE = 'SEND_PAYROLL_FAILURE';
export const RESET_SEND_PAYROLL = 'RESET_SEND_PAYROLL';
export const TOGGLE_INVOICE_DETAIL = 'TOGGLE_INVOICE_DETAIL';
export const TOGGLE_DEBTOR_DETAIL = 'TOGGLE_DEBTOR_DETAIL';
export const FETCH_INVOICE_INFO_START = 'FETCH_INVOICE_INFO_START';
export const FETCH_INVOICE_INFO_SUCCESS = 'FETCH_INVOICE_INFO_SUCCESS';
export const FETCH_INVOICE_INFO_FAILURE = 'FETCH_INVOICE_INFO_FAILURE';
export const FETCH_PAYER_DETAIL_START = 'FETCH_PAYER_DETAIL_START';
export const SET_SELECTED_FUND = 'SET_SELECTED_FUND';
export const UPDATE_RECOMMENDED_RATES = 'UPDATE_RECOMMENDED_RATES';
export const UPDATE_RATES_FAILURE = 'UPDATE_RATES_FAILURE';
export const UPDATE_EXPIRATION_DATES = 'UPDATE_EXPIRATION_DATES';
export const UPDATE_EXPIRATION_DATES_FAILURE =
  'UPDATE_EXPIRATION_DATES_FAILURE';
export const UPDATE_FUNDS_INVOICES_CART = 'UPDATE_FUNDS_INVOICES_CART';
export const HANDLE_SHOW_FUND_SHOPPING_CART = 'HANDLE_SHOW_FUND_SHOPPING_CART';
export const UPDATE_RECEIVER_PAYROLL_RATE = 'UPDATE_RECEIVER_PAYROLL_RATE';
export const UPDATE_RECEIVER_DEBT = 'UPDATE_RECEIVER_DEBT';
export const CLEAR_INVOICE_CART = 'CLEAR_INVOICE_CART';
export const REMOVE_RECEIVER_IN_CART = 'REMOVE_RECEIVER_IN_CART';
export const FUNDS_SIMULATION_START = 'FUNDS_SIMULATION_START';
export const SET_SIMULATION_DATA = 'SET_SIMULATION_DATA';
export const SIMULATION_ERROR = 'SIMULATION_ERROR';
export const TOGGLE_INVOICE_RATE_CHANGE = 'TOGGLE_INVOICE_RATE_CHANGE';
export const UPDATE_INVOICE_FUND_RATE = 'UPDATE_INVOICE_FUND_RATE';
export const TOGGLE_INVOICE_ADVANCE_PERCENTAGE_CHANGE =
  'TOGGLE_INVOICE_ADVANCE_PERCENTAGE_CHANGE';
export const UPDATE_INVOICE_ADVANCE_PERCENTAGE =
  'UPDATE_INVOICE_ADVANCE_PERCENTAGE';
export const TOGGLE_INVOICE_ISSUER_DEBT_CHANGE =
  'TOGGLE_INVOICE_ISSUER_DEBT_CHANGE';
export const UPDATE_INVOICE_ISSUER_DEBT = 'UPDATE_INVOICE_ISSUER_DEBT';
export const BULK_SELECTION_ACTION = 'BULK_SELECTION_ACTION';
export const REMOVE_SELECTED_SUBGROUP = 'REMOVE_SELECTED_SUBGROUP';
export const CLEAR_SELECTED_SUBGROUP = 'CLEAR_SELECTED_SUBGROUP';
export const UPDATE_SUBGROUP_SELECTION = 'UPDATE_SUBGROUP_SELECTION';
export const UPDATE_BULK_SELECTION = 'UPDATE_BULK_SELECTION';
export const TOGGLE_MODAL_CONFIRM_BULK_ACTION =
  'TOGGLE_MODAL_CONFIRM_BULK_ACTION';
export const TOGGLE_MODAL_UPDATE_TERM = 'TOGGLE_MODAL_UPDATE_TERM';
export const UPDATE_INVOICE_TERM = 'UPDATE_INVOICE_TERM';
export const UPDATE_FUNDS_ALL_INVOICES_RATE = 'UPDATE_FUNDS_ALL_INVOICES_RATE';
export const RESET_CLEARING_SUBGROUP = 'RESET_CLEARING_SUBGROUP';

// NOTIFICATIONS
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// PARTIAL PAYMENTS
export const RESET_PARTIAL_PAYMENT_ERRORS = 'RESET_PARTIAL_PAYMENT_ERRORS';
export const FETCH_PARTIAL_PAYMENTS_START = 'FETCH_PARTIAL_PAYMENTS_START';
export const FETCH_PARTIAL_PAYMENTS_SUCCESS = 'FETCH_PARTIAL_PAYMENTS_SUCCESS';
export const FETCH_PARTIAL_PAYMENTS_FAILURE = 'FETCH_PARTIAL_PAYMENTS_FAILURE';
export const ADD_PARTIAL_PAYMENT_START = 'ADD_PARTIAL_PAYMENT_START';
export const ADD_PARTIAL_PAYMENT_SUCCESS = 'ADD_PARTIAL_PAYMENT_SUCCESS';
export const ADD_PARTIAL_PAYMENT_FAILURE = 'ADD_PARTIAL_PAYMENT_FAILURE';
export const UPDATE_PARTIAL_PAYMENT_START = 'UPDATE_PARTIAL_PAYMENT_START';
export const UPDATE_PARTIAL_PAYMENT_SUCCESS = 'UPDATE_PARTIAL_PAYMENT_SUCCESS';
export const UPDATE_PARTIAL_PAYMENT_FAILURE = 'UPDATE_PARTIAL_PAYMENT_FAILURE';
export const DELETE_PARTIAL_PAYMENT_START = 'DELETE_PARTIAL_PAYMENT_START';
export const DELETE_PARTIAL_PAYMENT_SUCCESS = 'DELETE_PARTIAL_PAYMENT_SUCCESS';
export const DELETE_PARTIAL_PAYMENT_FAILURE = 'DELETE_PARTIAL_PAYMENT_FAILURE';
export const BULK_PARTIAL_PAYMENT_REGISTER_START =
  'BULK_PARTIAL_PAYMENT_REGISTER_START';
export const BULK_PARTIAL_PAYMENT_REGISTER_SUCCESS =
  'BULK_PARTIAL_PAYMENT_REGISTER_SUCCESS';
export const BULK_PARTIAL_PAYMENT_REGISTER_FAILURE =
  'BULK_PARTIAL_PAYMENT_REGISTER_FAILURE';
export const CALCULATE_DEBT_PARTIAL_PAYMENT_START =
  'CALCULATE_DEBT_PARTIAL_PAYMENT_START';
export const CALCULATE_DEBT_PARTIAL_PAYMENT_SUCCESS =
  'CALCULATE_DEBT_PARTIAL_PAYMENT_SUCCESS';
export const CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE =
  'CALCULATE_DEBT_PARTIAL_PAYMENT_FAILURE';

// PAYER CONTRIBUTION
export const GET_OR_CREATE_PAYMENT_ORDER_DETAILS_START =
  'GET_OR_CREATE_PAYMENT_ORDER_DETAILS_START';
export const GET_OR_CREATE_PAYMENT_ORDER_DETAILS_SUCCESS =
  'GET_OR_CREATE_PAYMENT_ORDER_DETAILS_SUCCESS';
export const GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE =
  'GET_OR_CREATE_PAYMENT_ORDER_DETAILS_FAILURE';
export const UPDATE_PAYMENT_ORDER_DETAILS_START =
  'UPDATE_PAYMENT_ORDER_DETAILS_START';
export const UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS =
  'UPDATE_PAYMENT_ORDER_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_ORDER_DETAILS_FAILURE =
  'UPDATE_PAYMENT_ORDER_DETAILS_FAILURE';

export const RESET_PAYROLL_DETAILS_STORE = 'RESET_PAYROLL_DETAILS_STORE';

// HOLIDAYS
export const FETCH_HOLIDAYS_START = 'FETCH_HOLIDAYS_START';
export const FETCH_HOLIDAYS_SUCCESS = 'FETCH_HOLIDAYS_SUCCESS';
export const FETCH_HOLIDAYS_FAILURE = 'FETCH_HOLIDAYS_FAILURE';
export const FETCH_RULES = 'FETCH_RULES';
export const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS';
export const FETCH_RULES_ERROR = 'FETCH_RULES_ERROR';

// CONFIG
export const TOGGLE_COLLAPSE_HOME_SIDE_BAR = 'TOGGLE_COLLAPSE_HOME_SIDE_BAR';

// REPORTS
export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';
export const REGISTER_REPORT_START = 'REGISTER_REPORT_START';
export const REGISTER_REPORT_SUCCESS = 'REGISTER_REPORT_SUCCESS';
export const REGISTER_REPORT_FAILURE = 'REGISTER_REPORT_FAILURE';
export const EDIT_REPORT_START = 'EDIT_REPORT_START';
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS';
export const EDIT_REPORT_FAILURE = 'EDIT_REPORT_FAILURE';
export const REPORTS_ADD_PERFORM = 'REPORTS_ADD_PERFORM';
export const EXECUTE_REPORT_START = 'EXECUTE_REPORT_START';
export const EXECUTE_REPORT_SUCCESS = 'EXECUTE_REPORT_SUCCESS';
export const EXECUTE_REPORT_FAILURE = 'EXECUTE_REPORT_FAILURE';
