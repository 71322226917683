import { createActions } from '../../../../commons/utils';

export const CLEAN_RECEIVER_FORM = createActions('CLEAN_RECEIVER_FORM');
export const CLEAN_SELECTED_DRAFT_RECEIVER = createActions(
  'CLEAN_SELECTED_DRAFT_RECEIVER'
);
export const CLEAN_SELECTED_RECEIVER = createActions('CLEAN_SELECTED_RECEIVER');
export const SAVE_RECEIVER_FORM = createActions('SAVE_RECEIVER_FORM');
export const SELECT_DRAFT_RECEIVER = createActions('SELECT_DRAFT_RECEIVER');
export const UPDATE_DRAFT_RECEIVER = createActions('UPDATE_DRAFT_RECEIVER');
export const SELECT_RECEIVER = createActions('SELECT_RECEIVER');
