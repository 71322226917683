import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

import SectionHeader from '../../../../../components/elements/SectionHeader';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';
import { PayersTable } from '../PayersTable';
import { defaultValue } from '../../../../commons/utils';
import { InvoicesTable } from '../InvoicesTable';
import { BtnFilter } from '../../../../commons/components/BtnFilter';
import {
  usePayrollActions,
  useSelectedPayroll,
  usePayrollSelector,
} from '../../../adapters/store/payroll';
import { useStyles } from './PayrollDetailLayout.styles';
import { VIEW_TYPES, usePayrollDetailContext } from '../../../context';
import { getPayrollFields, getCards } from './constants';
import { CessionNotificationDialog } from '../CessionNotificationDialog';
import { ClosePayrollDialog } from '../ClosePayrollDialog';
import { useDownload } from '../../../adapters/hooks/useDownload';

export const PayrollDetailLayout = () => {
  const css = useStyles();
  const history = useHistory();
  const { download } = useDownload();
  const payrollDetailCtx = usePayrollDetailContext();
  const [showClosePayrollModal, setShowClosePayrollModal] = useState(false);
  const [showCessionNotificationModal, setShowCessionNotificationModal] =
    useState(false);
  const [cessionSummary, setCessionSummary] = useState({});
  const selectedPayroll = useSelectedPayroll();
  const { fetchPayroll, fetchCessionSummary, notifyCessions, closePayroll } =
    usePayrollActions();
  const payrollSelector = usePayrollSelector();
  const filterBtn = payrollDetailCtx.filter();

  useEffect(() => {
    if (!selectedPayroll) {
      fetchPayroll({
        onFail: () => {
          history.push(
            `/${payrollDetailCtx.country.toLowerCase()}/funds#in-process`
          );
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPayroll, payrollDetailCtx.payrollId, payrollDetailCtx.country]);

  useEffect(() => {
    fetchCessionSummary(payrollDetailCtx.payrollId).then(response =>
      setCessionSummary(response.payload)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollSelector]);

  const handleDownloadXLSX = () => {
    download({
      fileUrl: `/payroll/${payrollDetailCtx.payrollId}/export`,
      fileName: `Nómina #${payrollDetailCtx.payrollId}.xlsx`,
    });
  };

  return (
    <>
      <Grid container className={css.mainWrapper}>
        <SectionHeader
          title={selectedPayroll?.fund || ''}
          breadCrumbs={[
            {
              title: 'Nóminas en proceso',
              // eslint-disable-next-line max-len
              link: `/${payrollDetailCtx.country.toLowerCase()}/funds/in-process`,
            },
            {
              title: 'Detalle Nómina',
            },
          ]}
          padding="xl lg lg"
          showBreadCrumbs
          mainArea={
            <Box display="flex" alignItems="center">
              {getPayrollFields(selectedPayroll).map(
                ({ label, value }, idx) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    className={css.containerTextMainAreaBold}
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="div"
                      className={css.textMainAreaBold}
                    >
                      <Box fontWeight="fontWeightBold">{label}</Box>
                    </Typography>
                    {selectedPayroll?.isLoading ? (
                      <Skeleton
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        variant="rect"
                        height={12}
                        width={50}
                        className={css.mbCard}
                      />
                    ) : (
                      <Typography variant="body1" color="textPrimary">
                        {defaultValue(value)}
                      </Typography>
                    )}
                  </Box>
                )
              )}
            </Box>
          }
        />
        <Grid container className={css.cardsContainer} spacing={4}>
          {getCards(selectedPayroll).map((c, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={3} key={idx}>
              <Grid container className={css.card}>
                <Grid item>
                  <Typography variant="h6" className={css.labelCard}>
                    {c.label}
                  </Typography>
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                      {selectedPayroll?.isLoading ? (
                        <Skeleton
                          // eslint-disable-next-line react/no-array-index-key
                          key={idx}
                          variant="rect"
                          height={30}
                          width={170}
                          className={css.mbCard}
                        />
                      ) : (
                        <Typography variant="h4" color={c.color}>
                          <CountryFormatHelper
                            countryId={payrollDetailCtx.country}
                            value={c.value}
                            variant={c.variant}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid
          alignItems="flex-end"
          className={css.buttonsContainer}
          container
          justifyContent="flex-end"
          spacing={0}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={css.btnBlue}
              size="small"
              style={{ marginRight: 15 }}
              onClick={handleDownloadXLSX}
            >
              Descargar .xlsx
            </Button>
            {selectedPayroll?.isClosing ? (
              <Button color="primary" size="small" style={{ marginRight: 15 }}>
                <CircularProgress size={30} />
              </Button>
            ) : (
              cessionSummary?.count > 0 &&
              !selectedPayroll?.closedAt && (
                <Button
                  variant="contained"
                  color="primary"
                  className={css.btnBlue}
                  size="small"
                  style={{ marginRight: 15 }}
                  onClick={() => setShowClosePayrollModal(true)}
                  disabled={selectedPayroll?.isLoading}
                >
                  Cerrar nómina
                </Button>
              )
            )}
            {cessionSummary?.count > 0 && selectedPayroll?.closedAt && (
              <Button
                variant="contained"
                color="primary"
                className={css.btnBlue}
                size="small"
                style={{ marginRight: 15 }}
                onClick={() => setShowCessionNotificationModal(true)}
                disabled={selectedPayroll?.isLoading}
              >
                Notificar cesión
              </Button>
            )}
            {showCessionNotificationModal && (
              <CessionNotificationDialog
                title="¿Estás seguro que deseas notificar la cesión al fondo?"
                description="Ten en cuenta que al hacerlo, se le enviará un mail al fondo con los documentos seleccionados y el monto a pagar."
                isOpen={showCessionNotificationModal}
                country={payrollDetailCtx.country}
                summary={cessionSummary}
                handleCloseDialog={() => setShowCessionNotificationModal(false)}
                handleSubmit={() => {
                  notifyCessions(selectedPayroll.id);
                  setShowCessionNotificationModal(false);
                }}
              />
            )}
            {showClosePayrollModal && (
              <ClosePayrollDialog
                title="¿Deseas cerrar la nómina?"
                description="Ten en cuenta que, al cerrarla los documentos cedidos se asignarán al fondo y no estarán disponibles en “Oportunidades”"
                isOpen={showClosePayrollModal}
                country={payrollDetailCtx.country}
                handleCloseDialog={() => setShowClosePayrollModal(false)}
                handleSubmit={() => {
                  closePayroll(selectedPayroll.id);
                  setShowClosePayrollModal(false);
                }}
              />
            )}
          </Grid>
          <Grid item>
            <BtnFilter
              appliedFilters={filterBtn.appliedFilters}
              setShowFilter={filterBtn.setShowFilter}
              showFilter={filterBtn.showFilter}
            />
          </Grid>
        </Grid>
        {payrollDetailCtx.viewType === VIEW_TYPES.RECEIVER && <PayersTable />}
        {payrollDetailCtx.viewType === VIEW_TYPES.INVOICE && <InvoicesTable />}
      </Grid>
    </>
  );
};
