import axios from 'axios';
import {
  FETCH_SUBRUBRO_START,
  FETCH_SUBRUBRO_SUCCESS,
  FETCH_SUBRUBRO_FAILURE,
} from './types';
import { getErrorCode } from '../helpers/handleErrors';
import { enqueueSnackbar } from './notificationAction';
import { t13s } from '../translationKeys/index';

// eslint-disable-next-line import/prefer-default-export
export const fetchSubRubros = () => dispatch => {
  dispatch({ type: FETCH_SUBRUBRO_START });
  axios
    .get('/api/rubro/subrubro')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_SUBRUBRO_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      const errorCode = getErrorCode(err);
      dispatch(
        enqueueSnackbar(t13s.NOTIFICATION.FETCH_SUBRUBRO_FAILURE, {
          variant: 'error',
        })
      );
      dispatch({ type: FETCH_SUBRUBRO_FAILURE, payload: { errorCode } });
    });
};
