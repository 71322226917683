import { useMutation } from 'react-query';
import { CONFIRMING, NONE } from '../../../../helpers/Constants';

import { fetchOrderFees } from '../services';

const parseDataFees = ({
  businessId,
  retentionPct,
  tasaBase,
  issuedDate,
  discount,
  operationCost,
}) => {
  return {
    businessId,
    retentionPct: retentionPct === '' ? 0 : retentionPct,
    rate: tasaBase === '' ? 0 : tasaBase,
    issuedDate,
    operationCost: operationCost === '' ? 0 : operationCost,
    discount: discount === '' ? 0 : discount,
  };
};

const useFetchOrderFees = () => {
  const {
    data,
    mutate: getOrderFees,
    isLoading: fetchOrderFeesLoading,
  } = useMutation(
    ({
      orderId,
      operation = CONFIRMING,
      businessId,
      retentionPct = 0,
      tasaBase = 0,
      issuedDate = null,
      discount = 0,
      operationCost = 0,
    }) => {
      const dataFees = parseDataFees({
        businessId,
        retentionPct,
        tasaBase,
        issuedDate,
        discount,
        operationCost,
      });

      dataFees.autoRate = NONE;

      return fetchOrderFees({ operation, orderId, dataFees });
    }
  );

  const { fees } = data || { fees: {} };

  return {
    fees,
    getOrderFees,
    fetchOrderFeesLoading,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useFetchOrderFees };
