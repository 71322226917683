import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { addPartialPaymentService } from '../services';

export const useAddPartialPayment = ({ sourceType, sourceId }) => {
  const dispatch = useDispatch();

  const {
    mutate: addPartialPayment,
    isLoading: addPartialPaymentIsLoading,
    error: addPartialPaymentError,
    isSuccess: addPartialPaymentSuccess,
    data: addPartialPaymentData,
  } = useMutation(
    ({ amount, paymentDate, comment }) =>
      addPartialPaymentService(sourceType, sourceId, {
        amount,
        paymentDate,
        comment,
      }),
    {
      onSuccess: () => {
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.PARTIAL_PAYMENT_CREATED, {
            variant: 'success',
          })
        );
      },
    }
  );

  return {
    addPartialPayment,
    addPartialPaymentError,
    addPartialPaymentIsLoading,
    addPartialPaymentSuccess,
    addPartialPaymentData,
  };
};

export default useAddPartialPayment;
