import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useBillDocuments,
  useOrder,
  useOrderAttributes,
} from '../infrastructure/store';

const useDocumentsTab = () => {
  const { id: orderId } = useParams();
  const {
    order: { businessId },
  } = useOrder(orderId);
  const {
    orderAttributes,
    isLoading: attributesIsLoading,
    isRefetching: attributesIsRefetching,
  } = useOrderAttributes(orderId);
  const { orderDocuments: documents, backupDocuments } = orderAttributes;
  const orderDocuments = documents.concat(backupDocuments);
  const {
    billDocuments,
    isLoading: billDocumentsIsLoading,
    isRefetching: billDocumentsIsRefetching,
    refetch: fetchDocuments,
  } = useBillDocuments(orderId, businessId);

  const [showNewDocumentDialog, setShowNewDocumentDialog] = useState(false);

  const handleShowNewDocumentDialog = useCallback(() => {
    setShowNewDocumentDialog(true);
  }, [setShowNewDocumentDialog]);

  const handleCloseNewDocumentDialog = useCallback(() => {
    setShowNewDocumentDialog(false);
  }, [setShowNewDocumentDialog]);

  const buildBaseDocument = () => {
    return billDocuments?.map(document => {
      const { id, type, pdf } = document;
      return {
        action: '',
        active: true,
        approved: true,
        document: pdf,
        documentableId: id,
        documentableType: 'INVOICE_DOCUMENT',
        title: type === 'interest' ? 'Factura Interés' : 'Factura Asesoría',
        hiddenToggleEnableDocument: true,
      };
    });
  };

  return {
    documents: [...orderDocuments, ...buildBaseDocument()],
    documentsIsLoading:
      attributesIsLoading ||
      billDocumentsIsLoading ||
      attributesIsRefetching ||
      billDocumentsIsRefetching,
    orderId,
    showNewDocumentDialog,
    fetchDocuments,
    handleShowNewDocumentDialog,
    handleCloseNewDocumentDialog,
  };
};

export default useDocumentsTab;
