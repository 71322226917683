import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CountryFormatHelper from '../../../components/elements/CountryFormatHelper';

const useStyles = makeStyles(theme => ({
  totalAmountColumn: props => ({
    minWidth: 100,
    color: theme.palette.text.primary,
    ...props.styles,
  }),
}));

const fontWeights = {
  bold: 'fontWeightBold',
  medium: 'fontWeightMedium',
  regular: 'fontWeightRegular',
};

const AmountByCountry = React.memo(props => {
  const { text, countryId, variant, color, className, fontWeight } = props;
  const fontWeightSelected = fontWeights[fontWeight] ?? fontWeights.regular;
  const classes = useStyles(props);
  return (
    <Typography
      variant={variant}
      color={color}
      component="div"
      noWrap
      className={clsx(classes.totalAmountColumn, className)}
    >
      <Box fontWeight={fontWeightSelected}>
        <CountryFormatHelper
          value={text}
          variant="currency"
          countryId={countryId}
        />
      </Box>
    </Typography>
  );
});

AmountByCountry.defaultProps = {
  variant: 'body1',
  color: 'textSecondary',
  className: '',
  fontWeight: 'bold',
};

AmountByCountry.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  countryId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
};

export default AmountByCountry;
