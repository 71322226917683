import { isEmptyObj } from '../../../../../commons/utils';

export const getDraftSelectedSummaryUseCase = ({ currentSelectorState }) => {
  if (isEmptyObj(currentSelectorState)) {
    return null;
  }

  const getTotalSelectedDocuments = () =>
    Object.keys(currentSelectorState).reduce(
      (acc, id) => acc + (currentSelectorState[id]?.value.length || 0),
      0
    );

  return {
    totalDocuments: getTotalSelectedDocuments(),
    totalReceivers: Object.keys(currentSelectorState).length,
  };
};
