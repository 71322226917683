import React from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { features } from '../../../../../../config/features';
import {
  useSetAnchorEl,
  useSetSelectedInvoice,
  useSelectedInvoice,
} from '../store';
import {
  BLUE_ROW_COLOR,
  CONFIRMING,
  RED_ROW_COLOR,
  STATUS_FAILED,
  STATUS_REJECTED,
  STATUS_REPLACED,
  STATUS_SUCCESS,
} from '../../../../../../helpers/Constants';
import { useFetchInvoicePdf } from '../../../../infrastructure/store';
import { useGetCountry } from '../../../../../../funds/adapters/store';

const useInvoiceTable = (tableRef, rowsPerPage) => {
  const { id: orderId } = useParams();
  const selectedInvoice = useSelectedInvoice();
  const setSelectedInvoice = useSetSelectedInvoice();
  const setAnchorEl = useSetAnchorEl();
  const country = useGetCountry();
  const countryFeatures = features[country];

  const { gettingURL } = useSelector(state => state.order);

  const { id, folio } = selectedInvoice || {};
  const fileName = `${country}_${orderId}_${folio}`;

  const { fetchInvoicePDFIsLoading } = useFetchInvoicePdf({
    fileName,
    orderType: CONFIRMING,
    country,
    id,
  });

  const disableRowFilter = ({ cessionStatus, status }) => {
    return (
      status === STATUS_REJECTED ||
      (cessionStatus &&
        ![STATUS_FAILED, STATUS_SUCCESS].includes(cessionStatus))
    );
  };

  const getRowColor = rowData => {
    const {
      status,
      SiiFactura,
      isClaimed = false,
      isCanceled = false,
      isHold = false,
      totalAmountCreditNotes,
    } = rowData;
    let color = '';
    if (status === STATUS_REPLACED) {
      color = BLUE_ROW_COLOR;
    }
    if (
      SiiFactura?.SiiNotasCredito ||
      isClaimed ||
      isCanceled ||
      isHold ||
      totalAmountCreditNotes
    ) {
      color = RED_ROW_COLOR;
    }
    return color;
  };

  const handleClickMenu = (event, invoice) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setSelectedInvoice({
      ...invoice,
      tableData: {
        ...invoice.tableData,
      },
    });
  };

  const getActionIcon = () => {
    const loadingActions =
      !gettingURL.indexOf(selectedInvoice?.id) || fetchInvoicePDFIsLoading;
    return loadingActions ? (
      <CircularProgress size={25} />
    ) : (
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  const actions = countryFeatures.orderActions.showInvoicesActionButton
    ? [
        {
          icon: () => getActionIcon(),
          tooltip: 'Más opciones',
          position: 'row',
          onClick: (event, rowData) => handleClickMenu(event, rowData),
        },
        {
          icon: 'refresh',
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => tableRef.current && tableRef.current.onQueryChange(),
        },
      ]
    : [];

  const tableOptions = {
    title: 'Facturas',
    style: {
      display: 'grid',
      width: '100%',
      boxShadow: 'none',
      borderRadius: 17,
      overflow: 'hidden',
    },
    localization: {
      header: {
        actions: 'Acciones',
      },
    },
    actions,
    options: {
      selection: true,
      selectionProps: rowData => {
        const selection = {
          disabled:
            disableRowFilter(rowData) || rowData.status === STATUS_REJECTED,
        };
        if (rowData.status === STATUS_REJECTED) {
          selection.checked = false;
        }
        return selection;
      },
      filtering: false,
      actionsColumnIndex: -1,
      pageSize: rowsPerPage,
      pageSizeOptions: [
        5,
        10,
        20,
        50,
        100,
        500,
        { value: 3000, label: 'Todas' },
      ],
      rowStyle: rowData => ({
        backgroundColor: getRowColor(rowData),
        verticalAlign: 'middle',
      }),
      emptyRowsWhenPaging: false,
      detailPanelType: 'single',
      sorting: false,
    },
  };

  return { tableOptions };
};

export default useInvoiceTable;
