import React from 'react';
import {
  Select,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const MultipleSelect = ({
  options,
  name,
  label,
  value: selected,
  onChange,
  customWidth,
}) => {
  const handleSelect = event => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <FormControl variant="outlined" style={{ width: customWidth }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        name={name}
        label={label}
        onChange={handleSelect}
        renderValue={clicked => clicked.join(', ')}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        multiple
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultipleSelect.defaultProps = {
  value: [],
  customWidth: null,
};

MultipleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  customWidth: PropTypes.number,
};

export default MultipleSelect;
