import React from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OperationSummary from './OperationSummary';
import FundFinancingSummary from './FundFinancingSummary';
import DetailsActions from './DetailsActions';
import StatusBadge from '../../../../components/elements/StatusBadge';
import { usePayrollSummary } from '../../hooks';
import NewSimulationDialog from './NewSimulationDialog';
import {
  DetailsGrid,
  AccordionTitleGrid,
  Accordion,
  AccordionSummary,
  TitleText,
  ActionsGrid,
  // DebtAlertGridContainer,
  CustomAccordionDetails,
} from './styles';
import ChangeStatusOrderModal from './ChangeStatusOrderModal';
// import DebtAlertMessage from './OperationSummary/DebtAlertMessage';

const PayrollSummary = ({ orderSummary, order }) => {
  const {
    showSimulationDialog,
    showOrderStatusDialog,
    orderStatusIsUpdating,
    updateOrderStatusError,
    handleShowSimulationDialog,
    handleCloseSimulationDialog,
    handleShowOrderStatusDialog,
    handleCloseOrderStatusDialog,
    handleDownloadSimulationPdf,
    handleUpdateOrderStatus,
  } = usePayrollSummary();

  const {
    fund: fundSummary,
    operation: operationSummary,
    // totalDebtAmount,
  } = orderSummary;

  const { status: orderStatus } = order;
  const handleSubmitStatusUpdate = (selectedStatus, selectedReason) => {
    handleUpdateOrderStatus(selectedStatus, selectedReason);
  };

  return (
    <DetailsGrid container direction="column">
      {/* USE ANOTHER SIMULATION DIALOG */}
      {showSimulationDialog && (
        <NewSimulationDialog
          open={showSimulationDialog}
          order={order}
          orderSummary={orderSummary}
          handleCloseDialog={handleCloseSimulationDialog}
        />
      )}

      {showOrderStatusDialog && (
        <ChangeStatusOrderModal
          open={showOrderStatusDialog}
          handleCloseDialog={handleCloseOrderStatusDialog}
          handleSubmit={handleSubmitStatusUpdate}
          statusOrder={orderStatus}
          title="Cambiar estado de la operacion"
          error={updateOrderStatusError}
          isLoading={orderStatusIsUpdating}
        />
      )}

      <Grid item xs={12}>
        <Accordion elevation={0} defaultExpanded>
          <Grid container alignItems="center">
            <Grid item>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{ edge: 'start' }}
              >
                <AccordionTitleGrid item>
                  <TitleText fontWeight="bold">
                    Resumen de la operación
                  </TitleText>
                </AccordionTitleGrid>
              </AccordionSummary>
            </Grid>
            <Grid item>
              <StatusBadge
                status={orderStatus}
                handleClick={handleShowOrderStatusDialog}
              />
            </Grid>
          </Grid>
          <CustomAccordionDetails>
            {/* {totalDebtAmount && (
              <DebtAlertGridContainer item container>
                <DebtAlertMessage totalDebtAmount={totalDebtAmount} />
              </DebtAlertGridContainer>
            )} */}
            <OperationSummary operationSummary={operationSummary} />
          </CustomAccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            IconButtonProps={{ edge: 'start' }}
          >
            <TitleText fontWeight="bold">
              Resumen financiamiento fondo
            </TitleText>
          </AccordionSummary>
          <AccordionDetails>
            <FundFinancingSummary fundSummary={fundSummary} />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <ActionsGrid item xs={12}>
        <DetailsActions
          handleShowSimulationDialog={handleShowSimulationDialog}
          handleDownloadSimulationPdf={handleDownloadSimulationPdf}
        />
      </ActionsGrid>
    </DetailsGrid>
  );
};

PayrollSummary.propTypes = {
  orderSummary: PropTypes.shape({
    totalDebtAmount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    fund: PropTypes.shape({
      totalAmount: PropTypes.number,
      baseRate: PropTypes.number,
      fundCost: PropTypes.number,
      amountTransferFund: PropTypes.number,
    }),
    id: PropTypes.number.isRequired,
    operation: PropTypes.shape({
      baseRate: PropTypes.number,
      interest: PropTypes.number,
      operationCost: PropTypes.number,
      totalInvoicesAmount: PropTypes.number,
      totalOperationCost: PropTypes.number,
      totalTransferAmount: PropTypes.number,
      transferDiscount: PropTypes.number,
    }).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  order: PropTypes.shape({
    advance: PropTypes.number.isRequired,
    agreementId: PropTypes.number.isRequired,
    agreementName: PropTypes.string.isRequired,
    baseRate: PropTypes.number.isRequired,
    businessId: PropTypes.number.isRequired,
    businessIdentifier: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired,
    countryId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    daysPassed: PropTypes.number.isRequired,
    finalAmount: PropTypes.number.isRequired,
    hasCesion: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    interest: PropTypes.number.isRequired,
    isFinancing: PropTypes.number.isRequired,
    isLocked: PropTypes.bool.isRequired,
    issuedDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sudoUser: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default PayrollSummary;
