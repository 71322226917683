import { useSelector, RootStateOrAny } from 'react-redux';
import { STATUSES } from '../helpers/Constants';

type EntityType =
  | 'factura'
  | 'facturasBulk'
  | 'order'
  | 'fee'
  | 'credit'
  | 'operationTypes'
  | 'debt'
  | 'payerCategorization';

interface Status {
  key: string;
  label: string;
  group: string;
  country: string[];
}

const useStatuses = () => {
  const country = useSelector((state: RootStateOrAny) => state.config.country);

  const getStatuses = (entityType: EntityType): Status[] => {
    return STATUSES[entityType].filter(status =>
      status.country.includes(country)
    );
  };
  return { getStatuses };
};

export default useStatuses;
