import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MultipleSelect } from '../../../commons/components';

const PaymentCesionFilter = memo(({ activeFilter, filters, handler }) => {
  return (
    <MultipleSelect
      name="cesionType"
      options={filters}
      value={activeFilter}
      label="Tipo payments"
      onChange={handler}
      customWidth={320}
    />
  );
});

PaymentCesionFilter.propTypes = {
  activeFilter: PropTypes.arrayOf(String).isRequired,
  handler: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(String).isRequired,
};

export default PaymentCesionFilter;
