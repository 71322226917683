/* eslint-disable react/jsx-props-no-spreading */
import MomentUtils from '@date-io/moment';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import AlertForm from '../elements/AlertForm';
import {
  DATE_FORMAT,
  CIVILSTATUS,
  COUNTRY_CODE_MX,
} from '../../helpers/Constants';
import { dateWithoutFormatter } from '../../helpers/DateUtils';
import { COUNTRIES } from '../../helpers/validation/addressesHelper';
import { verifyEmail } from '../../helpers/validation/email';
import useGeoMX from '../../hooks/geo';
import CheckButton from '../elements/CheckButton';
import verifyRut from '../../helpers/validation/rut';
import BaseDialog from './BaseDialog';

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  sectionHeader: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 5,
  },
  title: {
    marginLeft: 10,
  },
  inputContainer: {
    maxWidth: 'calc(50% - 10px)',
    flexBasis: 'calc(50% - 10px)',
  },
  formSection: {
    marginLeft: 42,
    width: 'calc(100% - 42px)',
  },
  flag: {
    marginRight: 10,
  },
}));

const StakeholderDialogMX = ({
  selectedStakeholder,
  isOpen,
  handleClose,
  onSubmit,
  editStakeholder,
  loading,
  error,
}) => {
  const classes = useStyles();
  const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
  const [contactInfoOpen, setContactOpen] = useState(false);
  const [addressInfoOpen, setAddressOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [stakeholder, setStakeholder] = useState({
    curp: '',
    postalCode: '',
    state: '',
    municipality: '',
    colony: '',
    city: '',
    street: '',
    outsideNumber: '',
    insideNumber: '',
    name: '',
    fatherLastName: '',
    motherLastName: '',
    identifier: '',
    email: '',
    citizenship: '',
    civilStatus: '',
    profession: '',
    phone: '',
    birthday: '',
    gender: '',
    ...selectedStakeholder,
  });

  const [
    mxStates,
    mxMunicipalities,
    mxColonies,
    selectState,
    selectMunicipality,
  ] = useGeoMX();

  const {
    curp,
    postalCode,
    state,
    municipality,
    colony,
    city,
    street,
    outsideNumber,
    insideNumber,
    name,
    fatherLastName,
    motherLastName,
    identifier,
    email,
    citizenship,
    civilStatus,
    profession,
    phone,
    birthday,
    gender,
  } = stakeholder;

  useEffect(() => {
    if (state) {
      selectState(state);
    }
  }, [stakeholder, selectState, state]);

  useEffect(() => {
    if (municipality) {
      selectMunicipality(municipality);
    }
  }, [stakeholder, municipality, selectMunicipality, state]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidRut', value => verifyRut(value));

    ValidatorForm.addValidationRule('isValidEmail', value =>
      verifyEmail(value)
    );
  }, []);

  const handleInputChange = e => {
    setStakeholder({
      ...stakeholder,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleChangeOtherInput = (name, value) => {
    setStakeholder({
      ...stakeholder,
      [name]: value,
    });
  };

  const countryToFlag = isoCode => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  const header = (title, isOpen, handleOpen) => {
    return (
      <Box
        className={classes.sectionHeader}
        onClick={() => {
          handleOpen(!isOpen);
        }}
      >
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: isOpen,
          })}
          onClick={() => {
            handleOpen(!isOpen);
          }}
          aria-expanded={isOpen}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
      </Box>
    );
  };

  return (
    <BaseDialog
      title="Información Representante Legal"
      description="Aquí puedes ver y editar toda la información correspondiente al Representante Legal."
      isOpen={isOpen}
      handleClose={handleClose}
      size="lg"
    >
      <ValidatorForm onSubmit={() => onSubmit(stakeholder)}>
        {header('Personal', personalInfoOpen, setPersonalInfoOpen)}
        <Collapse in={personalInfoOpen} timeout="auto" unmountOnExit>
          <Grid container className={classes.formSection}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                variant="outlined"
                label="Nombres"
                name="name"
                value={name}
                onChange={handleInputChange}
                validators={['required']}
                errorMessages={['Campo requerido']}
                disabled={!editStakeholder}
              />
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Apellido Paterno"
                  type="string"
                  name="fatherLastName"
                  value={fatherLastName}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Apellido Materno"
                  type="string"
                  name="motherLastName"
                  value={motherLastName}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="RFC"
                  type="string"
                  name="identifier"
                  value={identifier}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="CURP"
                  type="string"
                  name="curp"
                  value={curp}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                  <DatePicker
                    fullWidth
                    autoOk
                    inputVariant="outlined"
                    label="Fecha nacimiento"
                    name="birthday"
                    format={DATE_FORMAT}
                    value={dateWithoutFormatter(birthday)}
                    onChange={date => handleChangeOtherInput('birthday', date)}
                    disabled={!editStakeholder}
                    TextFieldComponent={props => {
                      return (
                        <TextValidator
                          {...props}
                          validators={['required']}
                          errorMessages={['Campo requerido']}
                        />
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <SelectValidator
                  variant="outlined"
                  label="Género"
                  value={gender}
                  name="gender"
                  onChange={handleInputChange}
                  fullWidth
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                >
                  <MenuItem key="F" value="Femenino">
                    Femenino
                  </MenuItem>
                  <MenuItem key="M" value="Masculino">
                    Masculino
                  </MenuItem>
                </SelectValidator>
              </Grid>
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <Autocomplete
                  options={COUNTRIES}
                  autoHighlight
                  value={
                    typeof citizenship === 'string'
                      ? COUNTRIES.find(c => c.label === citizenship)
                      : citizenship
                  }
                  onChange={(e, value) => {
                    handleChangeOtherInput('citizenship', value?.label);
                  }}
                  getOptionLabel={option => option.label}
                  disabled={!editStakeholder}
                  renderOption={option => (
                    <>
                      <span className={classes.flag}>
                        {countryToFlag(option.code)}
                      </span>
                      {option.label}
                    </>
                  )}
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      name="citizenship"
                      label="Nacionalidad"
                      variant="outlined"
                      value={
                        typeof citizenship === 'string'
                          ? COUNTRIES.find(c => c.label === citizenship)
                          : citizenship
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      validators={['required']}
                      errorMessages={['Campo requerido']}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <SelectValidator
                  variant="outlined"
                  label="Estado civil"
                  value={civilStatus}
                  name="civilStatus"
                  onChange={handleInputChange}
                  fullWidth
                  validators={['required']}
                  disabled={!editStakeholder}
                  errorMessages={['Campo requerido']}
                >
                  {CIVILSTATUS.filter(c => c.country === COUNTRY_CODE_MX).map(
                    i => (
                      <MenuItem value={i.value} key={i.label}>
                        {i.label}
                      </MenuItem>
                    )
                  )}
                </SelectValidator>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Profesión"
                  type="string"
                  name="profession"
                  value={profession}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        {header('Contacto', contactInfoOpen, setContactOpen)}
        <Collapse in={contactInfoOpen} timeout="auto" unmountOnExit>
          <Grid container className={classes.formSection}>
            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Teléfono"
                  type="string"
                  name="phone"
                  value={phone}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Email"
                  type="string"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        {header('Domicilio', addressInfoOpen, setAddressOpen)}
        <Collapse in={addressInfoOpen} timeout="auto" unmountOnExit>
          <Grid container className={classes.formSection}>
            <Grid container justify="space-between">
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Calle"
                  type="string"
                  name="street"
                  value={street}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Número exterior"
                  type="string"
                  name="outsideNumber"
                  value={outsideNumber}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  label="Número interior"
                  type="string"
                  name="insideNumber"
                  value={insideNumber}
                  onChange={handleInputChange}
                  validators={['required']}
                  errorMessages={['Campo requerido']}
                  disabled={!editStakeholder}
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={12}>
                {editStakeholder ? (
                  <Autocomplete
                    name="state"
                    options={mxStates}
                    onChange={(e, value) => {
                      setStakeholder({
                        ...stakeholder,
                        state: value,
                        municipality: '',
                        colony: '',
                      });
                      selectState(value);
                    }}
                    value={state}
                    autoHighlight
                    getOptionLabel={option => option}
                    renderOption={option => option}
                    disabled={!editStakeholder}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                        label="Estado"
                        variant="outlined"
                        value={state}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    )}
                  />
                ) : (
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label="Estado"
                    type="string"
                    name="state"
                    value={state}
                    disabled
                  />
                )}
              </Grid>
            </Grid>

            <Grid container justify="space-between">
              <Grid item xs={6} className={classes.inputContainer}>
                {editStakeholder ? (
                  <Autocomplete
                    options={mxMunicipalities}
                    autoHighlight
                    onChange={(e, value) => {
                      setStakeholder({
                        ...stakeholder,
                        municipality: value,
                        colony: '',
                      });
                      selectMunicipality(value);
                    }}
                    value={municipality}
                    getOptionLabel={option => option}
                    renderOption={option => option}
                    disabled={!editStakeholder}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                        value={municipality}
                        name="municipality"
                        label="Municipio"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    )}
                  />
                ) : (
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label="Municipio"
                    type="string"
                    name="municipality"
                    value={municipality}
                    disabled
                  />
                )}
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                {editStakeholder ? (
                  <Autocomplete
                    name="colony"
                    options={mxColonies}
                    onChange={(e, value) => {
                      handleChangeOtherInput('colony', value);
                    }}
                    value={colony}
                    autoHighlight
                    getOptionLabel={option => option}
                    renderOption={option => option}
                    disabled={!editStakeholder}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                        value={colony}
                        label="Colonia"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        validators={['required']}
                        errorMessages={['Campo requerido']}
                      />
                    )}
                  />
                ) : (
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label="Colonia"
                    type="string"
                    name="colony"
                    value={colony}
                    disabled
                  />
                )}
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={6} className={classes.inputContainer}>
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label="Codigo Postal"
                    type="string"
                    name="postalCode"
                    value={postalCode}
                    onChange={handleInputChange}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                    disabled={!editStakeholder}
                  />
                </Grid>
                <Grid item xs={6} className={classes.inputContainer}>
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label="Ciudad"
                    type="string"
                    name="city"
                    value={city}
                    onChange={handleInputChange}
                    validators={['required']}
                    errorMessages={['Campo requerido']}
                    disabled={!editStakeholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        {error && <AlertForm message={error} variant="error" />}
        {editStakeholder && (
          <CheckButton
            check={checked}
            handleCheck={handleCheckboxChange}
            labelButton="Guardar cambios"
            loading={loading}
            type="submit"
          />
        )}
      </ValidatorForm>
    </BaseDialog>
  );
};

StakeholderDialogMX.defaultProps = {
  selectedStakeholder: null,
  editStakeholder: false,
  loading: false,
  error: null,
};

StakeholderDialogMX.propTypes = {
  selectedStakeholder: PropTypes.objectOf(PropTypes.any),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editStakeholder: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default StakeholderDialogMX;
