import React, { FC } from 'react';
import { OperationList } from '../../../../commons/interfaces/ARInterfaces';
import { ContainerTitle, TitleLeft, TotalOrdersTransferred } from './style';

interface Props {
  operation: OperationList;
}

const OperationCardHeader: FC<Props> = ({ operation }) => {
  const totalOrdersTransferred =
    operation?.totalOrdersTransferred > 99
      ? '99+'
      : operation?.totalOrdersTransferred;
  return (
    <ContainerTitle>
      <TotalOrdersTransferred
        totalOrdersTransferred={operation?.totalOrdersTransferred}
      >
        {totalOrdersTransferred}
      </TotalOrdersTransferred>
      <TitleLeft>{operation?.businessName}</TitleLeft>
    </ContainerTitle>
  );
};

export default OperationCardHeader;
