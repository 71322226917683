import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const SignalTooltip = withStyles({
  tooltip: {
    backgroundColor: '#63606D',
    padding: 10,
    fontSize: 12,
    maxWidth: 190,
  },
})(Tooltip);

export default SignalTooltip;
