import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PayerContactForm from './PayerContactForm';
import { fetchContacts } from '../../actions/contactsActions';
import PayerContactContainer from './PayerContactContainer';
import { CONTACT_TYPE } from '../../helpers/Constants';

const useStyles = makeStyles({
  gridContainer: {
    display: 'flex',
    padding: '33px 0px 36px 62px',
  },
  containerContactConfirmation: { width: 300, marginRight: 118 },
  containerContactPayment: { width: 300 },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contactConfirmationTitle: {
    marginRight: '9px',
  },
  contactPaymentTitle: {
    marginRight: '83px',
  },
  displayInLineBlock: {
    display: 'inline-block',
  },
});

const CONTACTABLE_TYPE = 'payer';

const PayerContactDetail = ({ payerId }) => {
  const { CONFIRMATION, PAYMENT } = CONTACT_TYPE;
  const classes = useStyles();
  const { payerContacts } = useSelector(state => state.contacts);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogPayment, setOpenDialogPayment] = useState(false);
  const dispatch = useDispatch();

  const handleCloseConfirmation = () => {
    setOpenDialogConfirmation(false);
  };

  const handleClosePayment = () => {
    setOpenDialogPayment(false);
  };

  const filterContact = type => {
    return payerContacts.filter(item => item.type === type);
  };

  useEffect(() => {
    dispatch(fetchContacts(CONTACTABLE_TYPE, payerId));
  }, [dispatch, payerId]);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item className={classes.containerContactConfirmation}>
        <Grid className={classes.containerTitle}>
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.contactConfirmationTitle}
            component="div"
          >
            <Box fontWeight="fontWeightBold">CONTACTO CONFIRMACIÓN</Box>
          </Typography>
          {!openDialogConfirmation ? (
            <IconButton
              variant="outlined"
              onClick={() => setOpenDialogConfirmation(true)}
            >
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          ) : (
            <PayerContactForm
              open={openDialogConfirmation}
              close={handleCloseConfirmation}
              type={CONFIRMATION}
              payerId={payerId}
            />
          )}
        </Grid>
        {!payerContacts?.length ? (
          <Grid item xs={12}>
            Agrega un contacto para ver su información aquí.
          </Grid>
        ) : (
          <PayerContactContainer
            payerId={payerId}
            type={CONFIRMATION}
            contacts={filterContact(CONFIRMATION)}
          />
        )}
      </Grid>

      <Grid className={classes.containerContactPayment}>
        <Grid className={classes.containerTitle}>
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.contactPaymentTitle}
          >
            <Box fontWeight="fontWeightBold">CONTACTO PAGO</Box>
          </Typography>
          {!openDialogPayment ? (
            <IconButton
              variant="outlined"
              onClick={() => setOpenDialogPayment(true)}
            >
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          ) : (
            <PayerContactForm
              open={openDialogPayment}
              close={handleClosePayment}
              type={PAYMENT}
              payerId={payerId}
            />
          )}
        </Grid>
        {!payerContacts?.length ? (
          <Grid item xs={12}>
            Agrega un contacto para ver su información aquí.
          </Grid>
        ) : (
          <PayerContactContainer
            payerId={payerId}
            type={PAYMENT}
            contacts={filterContact(PAYMENT)}
          />
        )}
      </Grid>
    </Grid>
  );
};

PayerContactDetail.propTypes = {
  payerId: PropTypes.number.isRequired,
};

export default PayerContactDetail;
