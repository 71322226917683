import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Drawer as DrawerUI } from '@material-ui/core';

import { useStyles } from './styles';

const DrawerComponent = ({
  anchor,
  children,
  classes,
  disableEscapeKeyDown,
  onClose,
  open,
}) => {
  const defaultClasses = useStyles();

  return (
    <DrawerUI
      anchor={anchor}
      classes={{ ...defaultClasses, ...classes }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={onClose}
      open={open}
    >
      {children}
    </DrawerUI>
  );
};

DrawerComponent.defaultProps = {
  anchor: 'right',
  children: null,
  classes: {},
  disableEscapeKeyDown: false,
  open: false,
};

DrawerComponent.propTypes = {
  anchor: PropTypes.oneOfType(['bottom', 'left', 'right', 'top']),
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  disableEscapeKeyDown: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
export const Drawer = memo(DrawerComponent);
