import { useParams } from 'react-router-dom';
import {
  useCreateInvoiceDiscount,
  useUpdateInvoiceDiscount,
  useDeleteInvoiceDiscount,
  useOrderSummary,
} from '../../../../infrastructure/store';

import {
  useInvoiceTableReloadRef,
  useSetShowDiscountFormDialog,
} from '../store';

const useDiscount = () => {
  const { id: orderId } = useParams();
  const invoiceTableReloadRef = useInvoiceTableReloadRef();
  const setShowDiscountFormDialog = useSetShowDiscountFormDialog();
  const { refetch: refetchSummary } = useOrderSummary(orderId);
  const { createInvoiceDiscount, createInvoiceDiscountLoading } =
    useCreateInvoiceDiscount();
  const { updateInvoiceDiscount, updateInvoiceDiscountLoading } =
    useUpdateInvoiceDiscount();
  const { deleteInvoiceDiscount, deleteInvoiceDiscountLoading } =
    useDeleteInvoiceDiscount();

  const onSuccessCallback = () => {
    refetchSummary();
    setShowDiscountFormDialog(false);
    invoiceTableReloadRef();
  };

  const handleCreateInvoiceDiscount = ({ sourceId, sourceType, body }) => {
    createInvoiceDiscount({ sourceId, sourceType, body, onSuccessCallback });
  };

  const handleUpdateInvoiceDiscount = ({ sourceId, sourceType, body }) => {
    updateInvoiceDiscount({ sourceId, sourceType, body, onSuccessCallback });
  };

  const handleDeleteInvoiceDiscount = ({ sourceId, sourceType }) => {
    deleteInvoiceDiscount({ sourceId, sourceType, onSuccessCallback });
  };

  return {
    handleCreateInvoiceDiscount,
    handleUpdateInvoiceDiscount,
    handleDeleteInvoiceDiscount,
    createInvoiceDiscountLoading,
    updateInvoiceDiscountLoading,
    deleteInvoiceDiscountLoading,
  };
};

export default useDiscount;
