/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useOktaAuth } from '@okta/okta-react';

import {
  useFetchComments,
  useAddOrderComment,
  useUpdateOrderComment,
  useFetchUsers,
  useDownloadOrderCommentAttachment,
  useUpdateOrderCommentsTags,
} from '../infrastructure/store';
import { dateFormatter } from '../../../helpers/DateUtils';
import { toBase64 } from '../../../helpers/fileToBase64';

const useOrderComments = orderId => {
  const { authState } = useOktaAuth();
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showDropZoneInput, setShowDropZoneInput] = useState(false);
  const [showTagsInput, setShowTagsInput] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [commentIdSelected, setCommentIdSelected] = useState(null);
  const [commentInformation, setCommentInformation] = useState({
    commentData: '',
    attachmentData: [],
    tagsData: [],
  });

  const { users, isLoading: usersIsLoading } = useFetchUsers();
  const { comments, isLoading: commentsIsLoading } = useFetchComments(
    'order',
    orderId
  );

  const {
    isLoading: addOrderCommentIsLoading,
    isSuccess: addOrderCommentIsSuccess,
    addOrderComment,
  } = useAddOrderComment();
  const {
    isLoading: updateOrderCommentIsLoading,
    isSuccess: updateOrderCommentIsSuccess,
    updateOrderComment,
  } = useUpdateOrderComment();
  const {
    isLoading: downloadOrderCommentAttachmentIsLoading,
    downloadOrderCommentAttachment,
  } = useDownloadOrderCommentAttachment();
  const { updateOrderCommentsTags } = useUpdateOrderCommentsTags();

  const handleSetInitialData = useCallback(() => {
    setShowCommentInput(false);
    setCommentInformation({
      commentData: '',
      attachmentData: [],
      tagsData: [],
    });
    setShowTagsInput(false);
    setShowDropZoneInput(false);
  }, [
    setShowCommentInput,
    setCommentInformation,
    setShowTagsInput,
    setShowDropZoneInput,
  ]);

  const handleOpenCommentInput = useCallback(() => {
    setShowCommentInput(true);
  }, [setShowCommentInput]);

  const handleCloseCommentInput = useCallback(() => {
    setShowCommentInput(false);
  }, [setShowCommentInput]);

  const handleToggleDropZoneInput = useCallback(() => {
    setShowDropZoneInput(!showDropZoneInput);
  }, [setShowDropZoneInput, showDropZoneInput]);

  const handleToggleTagsInput = useCallback(() => {
    setShowTagsInput(!showTagsInput);
  }, [setShowTagsInput, showTagsInput]);

  const handleChangeCommentData = useCallback(
    commentData => {
      setCommentInformation({
        ...commentInformation,
        commentData,
      });
    },
    [setCommentInformation, commentInformation]
  );

  const handleChangeDropzone = useCallback(
    (documentFile, status) => {
      const copyCommentInformation = { ...commentInformation };
      const arrayFiles = copyCommentInformation.attachmentData;

      if (status === 'done') {
        arrayFiles.push(documentFile);
        setCommentInformation({
          ...commentInformation,
          attachmentData: arrayFiles,
        });
      } else if (status === 'removed') {
        const newArrayFiles = arrayFiles.filter(
          file => file?.meta?.id !== documentFile?.meta?.id
        );
        setCommentInformation({
          ...commentInformation,
          attachmentData: newArrayFiles,
        });
      }
    },
    [commentInformation, setCommentInformation]
  );

  const handleChangeTagSelector = useCallback(
    (_e, values) => {
      const newValue = values[values.length - 1];
      const oldValues = values.slice(0, values.length - 1);
      //  must check if the user is deselecting the collector.
      const isAlreadySelected = oldValues.find(
        value => value.email === newValue.email
      );
      const tagsData = isAlreadySelected
        ? oldValues.filter(value => value.email !== newValue.email)
        : values;
      setCommentInformation({
        ...commentInformation,
        tagsData,
      });
    },
    [setCommentInformation, commentInformation]
  );

  const handleGetDateTimeCommentFormat = useCallback(date => {
    const today = moment(new Date().toISOString());
    const momentDate = moment(new Date(date).toISOString());

    const diffDays = Math.abs(today.diff(momentDate, 'days'));
    const diffHours = Math.abs(today.diff(momentDate, 'hours'));

    if (diffDays > 1 && diffDays <= 3) {
      return `hace ${diffDays} días`;
    }

    if (diffDays === 1) {
      return `hace ${diffDays} día`;
    }

    if (diffHours > 1 && diffHours <= 12) {
      return `hace ${diffHours} horas`;
    }

    if (diffHours === 1) {
      return `hace ${diffHours} hora`;
    }

    if (diffHours === 0) {
      return 'menos de 1 hora';
    }
    return dateFormatter(date);
  }, []);

  const handleFilterCurrentUser = useCallback(
    userList => {
      const currentUserEmail = authState?.idToken?.claims?.email || '-';
      return userList?.filter(
        ({ email, firstName }) =>
          email !== currentUserEmail && firstName?.trim()
      );
    },
    [authState]
  );

  const handleMaxWords = useCallback((text, maxLength) => {
    if (text?.trim()?.length <= maxLength) return text;
    return `${text?.trim().slice(0, maxLength)}...`;
  }, []);

  const handleGetExtensionFile = useCallback(pathFile => {
    const newArray = pathFile?.split('.');
    return newArray[newArray?.length - 1] || '-';
  }, []);

  const handleSetOrderCommentIdSelected = useCallback(
    commentId => {
      setCommentIdSelected(commentId);
    },
    [setCommentIdSelected]
  );

  const handleAddOrderComment = useCallback(
    async commentUseState => {
      const attachments = [];
      const attachmentsPromises = commentUseState?.attachmentData.map(
        attachment => {
          attachments.push({ fileName: attachment.file.name, file: '' });
          return toBase64(attachment.file);
        }
      );
      const attachmentsResolved = await Promise.all(attachmentsPromises);
      attachmentsResolved.forEach((attachment, index) => {
        attachments[index] = {
          ...attachments[index],
          file: attachment,
        };
      });

      const userEmail = authState?.idToken?.claims?.email || '';
      const userName = authState?.idToken?.claims?.name || '';

      const commentDataToCreate = {
        comment: commentUseState.commentData,
        userEmail,
        userEmails:
          commentUseState?.tagsData?.map(tagData => tagData.email) || [],
        attachments,
        category: 'XEPELIN',
        userName,
      };
      addOrderComment({ orderId, commentDataToCreate });
    },
    [addOrderComment, authState, orderId]
  );

  const handleUpdateOrderComment = useCallback(
    (commentId, commentDataToUpdate) => {
      updateOrderComment({ orderId, commentId, commentDataToUpdate });
    },
    [updateOrderComment, orderId]
  );

  const handleDownloadOrderCommentAttachment = useCallback(
    (fileName, attachmentId) => {
      downloadOrderCommentAttachment({ fileName, attachmentId });
    },
    [downloadOrderCommentAttachment]
  );

  const handleUpdateOrderCommentTags = useCallback(() => {
    const commentIds = comments.map(({ id }) => id);
    const userEmail = authState?.idToken?.claims?.email || '-';
    updateOrderCommentsTags({
      commentIds,
      orderId,
      userEmail,
    });
  }, [updateOrderCommentsTags, orderId, comments, authState]);

  useEffect(() => {
    if (addOrderCommentIsSuccess && !addOrderCommentIsLoading) {
      handleSetInitialData();
    }
  }, [
    addOrderCommentIsLoading,
    addOrderCommentIsSuccess,
    handleSetInitialData,
  ]);

  useEffect(() => {
    if (updateOrderCommentIsSuccess && !updateOrderCommentIsLoading) {
      handleSetInitialData();
    }
  }, [
    updateOrderCommentIsSuccess,
    updateOrderCommentIsLoading,
    handleSetInitialData,
  ]);

  useEffect(
    () => {
      if (
        comments?.length &&
        !commentsIsLoading &&
        authState?.idToken?.claims?.email &&
        isFirstRender
      ) {
        setIsFirstRender(false);
        handleUpdateOrderCommentTags();
      }
    },
    // eslint-disable-next-line
    [comments, authState?.idToken?.claims?.email, commentsIsLoading]
  );

  return {
    showCommentInput,
    showDropZoneInput,
    showTagsInput,
    commentInformation,
    users,
    comments,
    commentIdSelected,
    usersIsLoading,
    commentsIsLoading,
    addOrderCommentIsLoading,
    updateOrderCommentIsLoading,
    downloadOrderCommentAttachmentIsLoading,
    handleOpenCommentInput,
    handleCloseCommentInput,
    handleToggleDropZoneInput,
    handleToggleTagsInput,
    handleChangeCommentData,
    handleChangeDropzone,
    handleChangeTagSelector,
    handleGetDateTimeCommentFormat,
    handleFilterCurrentUser,
    handleMaxWords,
    handleGetExtensionFile,
    handleSetOrderCommentIdSelected,
    handleAddOrderComment,
    handleUpdateOrderComment,
    handleDownloadOrderCommentAttachment,
    handleUpdateOrderCommentTags,
  };
};

export default useOrderComments;
