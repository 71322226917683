import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '../Drawer';
import { InvoiceForm } from './InvoiceForm';

const InvoiceFilterComponent = ({
  fieldsToHide,
  form,
  onClose,
  onSubmit,
  open,
}) => {
  return (
    <Drawer open={open} onClose={onClose}>
      <InvoiceForm
        fieldsToHide={fieldsToHide}
        form={form}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

InvoiceFilterComponent.defaultProps = {
  fieldsToHide: {},
  open: false,
};

InvoiceFilterComponent.propTypes = {
  fieldsToHide: PropTypes.objectOf(Object),
  form: PropTypes.objectOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export const InvoiceFilter = memo(InvoiceFilterComponent);
