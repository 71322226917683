import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const fetchDocumentTypesService = async ({ field, searchInput, active }) => {
  try {
    const { data } = await axios.get('/api/documenttype', {
      params: {
        field,
        searchInput,
        active,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchDocumentTypesService };
