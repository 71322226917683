import { Country } from '../schemas';

export type InputParams = {
  country: Country;
  businessId: string;
};

export type CommercialClassificationData = string;
export type CommercialClassificationError = { message: string };

type JsonResponse = {
  clasificacionComercial?: string | null;
};

const errorTextMessage = 'No se pudo obtener la clasificación comercial';

export const getCommercialClassification = async ({
  businessId,
  country,
}: InputParams): Promise<CommercialClassificationData> => {
  try {
    const queryParams = new URLSearchParams({ country, businessId }).toString();
    const response = await fetch(`${process.env.REACT_APP_COMMERCIALCLASSIFICATION_URL!}?${queryParams}`);

    const { clasificacionComercial } = await response.json() as JsonResponse;

    if (response.ok) {
      return clasificacionComercial || 'Sin Clasificación';
    }

    throw new Error();
  } catch {
    const error: CommercialClassificationError = { message: errorTextMessage };
    return Promise.reject(error);
  }
};
