import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

const usePaymentCardHeader = () => {
  const [showHistoryLogDialog, setShowHistoryLogDialog] = useState(false);

  const { country } = useSelector(state => state.config);
  const isCountryCL = country.toLowerCase() === 'cl';

  const handleShowHistoryLogDialog = useCallback(() => {
    setShowHistoryLogDialog(true);
  }, [setShowHistoryLogDialog]);

  const handleCloseHistoryLogDialog = useCallback(() => {
    setShowHistoryLogDialog(false);
  }, [setShowHistoryLogDialog]);

  return {
    handleCloseHistoryLogDialog,
    handleShowHistoryLogDialog,
    showHistoryLogDialog,
    isCountryCL,
    country,
  };
};

export default usePaymentCardHeader;
