import axios from 'axios';
import { UNKNOWN_ERROR } from '../../../../helpers/Constants';

const uploadOrderInvoicePdfService = async (invoiceId, pdf) => {
  try {
    const formData = new FormData();
    formData.append('pdf', pdf, pdf.name);
    return await axios.post(
      `/api/orderinvoice/${invoiceId}/pdfinvoice`,
      formData
    );
  } catch (error) {
    throw new Error(error?.response?.data?.message ?? UNKNOWN_ERROR);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { uploadOrderInvoicePdfService };
