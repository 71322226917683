import React, { useEffect, useState } from 'react';
import CountryFormatHelper from '../../../../../../components/elements/CountryFormatHelper';
import {
  BLOCKLIST_REASONS,
  BLOCKLIST_REASONS_CL,
  DISCOUNTS,
  LOG_STATUS,
  ORDER_TYPES_LABEL,
  STATUSES_VERIFIED,
} from '../../../../../../helpers/Constants';
import { datetimeFormatter } from '../../../../../../helpers/DateUtils';
import {
  CHANGES_DETAILS_GLOBAL,
  LOGS_MESSAGES,
  SUBCONTEXT_WITH_LABELS,
} from '../../../../../../helpers/userLogsUtils';
import { Text } from '../../../../../commons/formatters';
import { useStyles } from '../dialogs/HistoryLogsDialog/styles';
import useFetchHistoryLogs from '../../../../infrastructure/store/useFetchHistoryLogs';
import { useGetCountry } from '../../../../../../funds/adapters/store';
import useStatusesDetail from '../../../../hooks/useStatusesDetail';

const useHistoryDialog = ({ contextId, contextType }) => {
  const { getStatuses } = useStatusesDetail();
  const [scoreVariablesData, setScoreVariablesData] = useState([]);
  const [stateTab, setStateTab] = useState('changes');
  const [historyLogs, setHistoryLogs] = useState([]);
  const getScoreInfo = type => {
    const [{ pymeData, payerData, invoiceData, relationshipData } = {}] =
      scoreVariablesData;

    switch (type) {
      case 'pyme':
        return [
          {
            title: 'VentasPromedioPyme',
            content: `${pymeData?.monthlyAverageSalesInCLP}`,
          },
          {
            title: 'AñosActivo',
            content: `${pymeData?.activeYears}`,
          },
          {
            title: 'DicomPyme',
            content: `${pymeData?.pymeDicom || ''}`,
          },
          {
            title: 'TuvoPP',
            content: `${pymeData?.operationsPP}`,
          },
          {
            title: 'DeudaTGRPorcentaje',
            content: `${pymeData?.tgr?.debt}`,
          },
          {
            title: 'DeudaTGRConvenio',
            content: `${pymeData?.tgr?.pAgreement}`,
          },
          {
            title: 'DeudaTGRCuotas',
            content: `${pymeData?.tgr?.unpaidFees}`,
          },
        ];
      case 'payer':
        return [
          {
            title: 'RankingPagador',
            content: `${payerData?.payerRanking}`,
          },
          {
            title: 'ConvenioPP',
            content: `${payerData?.hasPayerAnActivePPAgreement}`,
          },
          {
            title: 'VentasPagador',
            content: `${payerData?.salesPayer}`,
          },
          {
            title: 'DicomPagador',
            content: `${payerData?.payerDicom || ''}`,
          },
        ];
      case 'invoice':
        return [
          {
            title: 'DiasEmision',
            content: `${invoiceData?.daysFromIssue}`,
          },
          {
            title: 'MontoFactura',
            content: `${invoiceData?.totalAmount}`,
          },
          {
            title: 'FechaEmision',
            content: invoiceData.issueDate
              ? datetimeFormatter(invoiceData?.issueDate)
              : '',
          },
          {
            title: 'Merito',
            content: `${invoiceData?.hasTheInvoiceMerit}`,
          },
          {
            title: 'MontoSobreVentas',
            content: `${invoiceData?.percentageOfAmountOverMonthlySales}`,
          },
        ];
      case 'relationship':
        return [
          {
            title: 'DiasRelacion',
            content: `${relationshipData?.daysOfRelationshipBetweenThePYMEAndThePayer}`,
          },
          {
            title: 'NCRelacion',
            content: `${relationshipData?.percentageOfCreditNotesOnAverageMonthlySales}`,
          },
          {
            title: 'CesionesRelacion',
            content: `${relationshipData?.percentageOfCessions}`,
          },
          {
            title: 'VentasPromedioRelacion',
            content: `${relationshipData?.averageMonthlySalesRatio}`,
          },
          {
            title: 'Recurrencia',
            content: `${relationshipData?.recurrence}`,
          },
          {
            title: 'NoPrimeraOperacion',
            content: `${relationshipData?.notFirstOperation}`,
          },
          {
            title: 'TendenciaVentas',
            content: `${relationshipData?.salesTrend}`,
          },
        ];
      default:
        return [];
    }
  };

  const classes = useStyles();

  const country = useGetCountry();

  const scoreVars = [
    { label: 'Pyme', type: 'pyme' },
    { label: 'Pagador', type: 'payer' },
    { label: 'Factura', type: 'invoice' },
    { label: 'Relación', type: 'relationship' },
  ];

  const translateStates = (status, type = 'ORDER') => {
    const factura = getStatuses('factura');
    const order = getStatuses('order');
    const logStatuses = LOG_STATUS;
    let newOrder = [...order];

    let statusFound = '-';
    if (type === 'ORDER') {
      if (status === 'BLOCKED') {
        newOrder = [...order, ...logStatuses];
      }
      statusFound = newOrder.find(element => element.key === status);
    }

    if (type === 'ORDER_FACTURAS') {
      statusFound = factura.find(element => element.key === status);
    }
    return statusFound?.label || '-';
  };

  const transformInfoStatusVerified = (status, type = 'ORDER_FACTURAS') => {
    let statusFound = 'Sin verificar';
    if (type === 'ORDER_FACTURAS') {
      statusFound = STATUSES_VERIFIED.find(element => element.key === status);
    }
    // Si entrega un null es un "Sin verificar", la bd no existe sin verificar, solo null
    return statusFound?.label || 'Sin verificar';
  };

  const transformInfoAction = rowData => {
    const {
      action,
      data: { changed },
      contextType,
      subContextType,
    } = rowData;
    const {
      CREATE: actionCreate,
      UPDATE: actionUpdate,
      DESTROY: actionDestroy,
    } = LOGS_MESSAGES;
    let data = '-';

    if (action === 'CREATE') {
      data = (
        <Text color="textPrimary" variant="body2">
          {actionCreate[subContextType ?? contextType]}
        </Text>
      );
    } else if (action === 'UPDATE' && changed) {
      const keysChanged = Object.keys(changed);
      if (!keysChanged) return data;
      const buildMessageActions = keysChanged?.map(element => {
        const elementFound = subContextType
          ? actionUpdate?.[subContextType]?.[element]
          : actionUpdate?.[element];
        if (!elementFound) return null;
        return (
          <Text color="textPrimary" variant="body2">
            {elementFound}
          </Text>
        );
      });

      data = buildMessageActions.length ? buildMessageActions : '-';
    } else if (action === 'DESTROY') {
      data = (
        <Text color="textPrimary" variant="body2">
          {actionDestroy[subContextType ?? contextType]}
        </Text>
      );
    }

    return data;
  };

  const getEventValue = event => {
    switch (Number(event)) {
      case 0:
        return 'Cedida';
      case 1:
        return 'Reclamada';
      case 2:
        return 'Acuse Recibo';
      case 3:
        return 'Contado/Gratuito';
      case 7:
        return 'Anulada';
      default:
        return '';
    }
  };

  const getEventPublisherValue = event => {
    switch (Number(event)) {
      case 1:
        return 'Contado/Gratuito';
      case 2:
        return 'Reclamada';
      case 3:
        return 'Cedida';
      case 4:
        return 'Anulada';
      default:
        return '';
    }
  };

  const transformDate = infoDate => {
    return infoDate ? datetimeFormatter(infoDate) : '-';
  };

  const translateReasons = (context, value) => {
    let reasonStr = '';
    switch (context) {
      case 'DISCOUNT':
        reasonStr =
          DISCOUNTS.find(
            r =>
              r.type === 'BASE_DISCOUNT' &&
              r.country.includes(country) &&
              r.value === value
          )?.label ?? '-';
        break;
      case 'PAYER_BLOCKLIST':
        reasonStr =
          [...BLOCKLIST_REASONS, ...BLOCKLIST_REASONS_CL].find(
            r => r.value === value
          )?.label ?? '-';
        break;
      default:
        break;
    }
    return reasonStr;
  };

  const userLogFunctions = (
    name,
    elementValue,
    contextType,
    subContextType
  ) => {
    const arrayFunction = {
      transformDate: transformDate(elementValue),
      translateStates: translateStates(elementValue, contextType),
      transformInfoStatusVerified: transformInfoStatusVerified(elementValue),
      type: ORDER_TYPES_LABEL[elementValue],
      getEventValue: getEventValue(elementValue),
      getEventPublisherValue: getEventPublisherValue(elementValue),
      translateReasons: translateReasons(
        subContextType ?? contextType,
        elementValue
      ),
      validNumber: typeof elementValue === 'number' ? elementValue : '-',
    };
    return arrayFunction[name];
  };

  const transformInfoStatesInvoices = (rowData, beforeOrAfter) => {
    const { data, contextType, subContextType, action } = rowData;
    const { changed } = data;
    const dataBeforeAfter = data[beforeOrAfter];
    if (!changed) return '-';
    if (contextType === 'AGREEMENT' && subContextType === 'AGREEMENT_RATE') {
      const { rate, days } = dataBeforeAfter;
      if (
        (action === 'CREATE' && beforeOrAfter === 'before') ||
        (action === 'DESTROY' && beforeOrAfter === 'after')
      ) {
        return '-';
      }
      delete changed.id;
      return (
        <>
          <Text color="textPrimary" variant="body2">
            Tasa:&nbsp;
            <CountryFormatHelper
              value={rate || '-'}
              countryId={country}
              variant="percentage"
            />
          </Text>
          <Text color="textPrimary" variant="body2">
            Día(s): {days}
          </Text>
        </>
      );
    }
    const finalData = Object.keys(changed).map(element => {
      let value = '';
      let formated;
      const elementValue = dataBeforeAfter[element];
      const detailType = CHANGES_DETAILS_GLOBAL.find(({ key }) =>
        typeof key == 'string' ? key === element : key.includes(element)
      );
      if (elementValue !== null && elementValue !== undefined && detailType) {
        if (SUBCONTEXT_WITH_LABELS.find(e => e === subContextType)) {
          value = `- ${detailType.label}`;
        }
        switch (detailType.type) {
          case 'text':
            value = `${detailType.label} ${elementValue}
            `;
            break;
          case 'bool':
            value += ` ${
              elementValue || elementValue === 1 ? 'Activo' : 'Inactivo'
            }
            `;
            if (
              ['AGREEMENT_ACTIVATED', 'AGREEMENT_PAUSED'].includes(
                subContextType
              )
            ) {
              value = '-';
            }
            break;
          case 'sino': {
            if (elementValue !== null && elementValue !== undefined) {
              value = elementValue ? 'Si' : 'No';
            }
            break;
          }
          case 'percentage':
          case 'currency': {
            formated = (
              <CountryFormatHelper
                value={elementValue}
                countryId={country}
                variant={detailType.type}
              />
            );
            break;
          }
          case 'stringArray': {
            value =
              typeof elementValue === 'string'
                ? elementValue.split(',')
                : elementValue;
            return (
              <>
                {detailType.helpHeader && (
                  <Text
                    color="textPrimary"
                    variant="body2"
                    className={classes.breakline}
                  >
                    {detailType.helpHeader}
                  </Text>
                )}
                {value.map(elem => (
                  <Text
                    color="textPrimary"
                    variant="body2"
                    className={classes.breakline}
                  >
                    {elem}
                  </Text>
                ))}
              </>
            );
          }
          default: {
            formated = ` ${userLogFunctions(
              detailType.type,
              elementValue,
              contextType,
              subContextType
            )}`;
            break;
          }
        }
      } else {
        value = '';
      }

      return (
        <Text key={element} color="textPrimary" variant="body2">
          {value} {formated || null}
        </Text>
      );
    });
    return finalData.length ? finalData : '-';
  };

  const buildColumnsTableHistoryLogs = () => {
    return [
      {
        title: 'Nombre',
        field: 'firstName',
        type: 'string',
        width: '130px',
        render: ({
          user: { firstName = 'Sistema', lastName = '', name = null },
        }) => (
          <Text color="textPrimary" variant="body2">
            {`${name || firstName} ${lastName}`}
          </Text>
        ),
      },
      {
        title: 'Fecha',
        field: 'actionDate',
        type: 'date',
        width: '150px',
        defaultSort: 'asc',
        render: ({ actionDate }) => (
          <Text color="textPrimary" variant="body2">
            {transformDate(actionDate)}
          </Text>
        ),
      },
      {
        title: 'Cambio',
        field: 'action',
        type: 'string',
        width: '130px',
        render: transformInfoAction,
      },
      {
        title: 'Estado Anterior',
        field: 'status',
        type: 'string',
        render: rowData => transformInfoStatesInvoices(rowData, 'before'),
      },
      {
        title: 'Estado Nuevo',
        field: 'status',
        type: 'string',
        render: rowData => transformInfoStatesInvoices(rowData, 'after'),
      },
    ];
  };

  const {
    fetchHistoryLogsRefetch,
    fetchHistoryLogsData,
    fetchHistoryLogsIsLoading,
    fetchHistoryLogsError,
  } = useFetchHistoryLogs({
    contextId,
    contextType,
  });

  useEffect(() => {
    if (fetchHistoryLogsError) {
      setHistoryLogs([]);
    }
  }, [fetchHistoryLogsError]);

  useEffect(() => {
    setHistoryLogs(fetchHistoryLogsData?.data.logs ?? []);
  }, [fetchHistoryLogsData]);

  useEffect(() => {
    fetchHistoryLogsRefetch();
  }, [contextId, contextType]);

  useEffect(() => {
    const scoreLog = historyLogs.filter(
      change => change?.data?.custom?.scoreVariables
    );
    const lastScoreVariables = scoreLog.sort(
      (a, b) => Date.parse(b.actionDate) - Date.parse(a.actionDate)
    );
    const scoreVariables = lastScoreVariables.map(
      score => score.data?.custom?.scoreVariables
    );

    setScoreVariablesData(scoreVariables);
  }, [historyLogs]);

  return {
    getScoreInfo,
    scoreVariablesData,
    stateTab,
    setStateTab,
    historyLogs,
    fetchHistoryLogsIsLoading,
    fetchHistoryLogsError,
    scoreVars,
    buildColumnsTableHistoryLogs,
  };
};
export default useHistoryDialog;
