import { useQuery } from 'react-query';
import { AR_PRODUCTS, DIRECT_FINANCING } from '../../../../helpers/Constants';
import fetchOperationTotalsService from '../services/fetchOperationTotalsService';

const useOperationTotals = (operationType: string = DIRECT_FINANCING) => {
  const { isLoading, isRefetching, data, refetch } = useQuery(
    ['getOperationTotals', operationType],
    () => fetchOperationTotalsService({ operationType }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 0,
      retry: false,
      enabled: AR_PRODUCTS.includes(operationType),
    }
  );
  const opertationTotals = data || [];

  return {
    isLoading: isLoading || isRefetching,
    opertationTotals,
    refetch,
  };
};

export default useOperationTotals;
