import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { UNKNOWN_ERROR_CODE } from '../../../../helpers/Constants';
import { t13s } from '../../../../translationKeys';
import { createBankAccountService } from '../services';

export const useCreateBankAccount = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    isLoading: bankAccountIsCreating,
    isSuccess: createBankAccountIsSuccess,
    error: createBankAccountError,
    mutate: createBankAccount,
    reset: resetBankAccountCreateData,
  } = useMutation(
    ({
      source,
      sourceId,
      supplierIdentifier,
      payerBusinessId,
      bankAccount,
    }) => {
      return createBankAccountService({
        source,
        sourceId,
        supplierIdentifier,
        payerBusinessId,
        bankAccount,
      });
    },
    {
      onSuccess: (_res, data) => {
        const { onSuccessCallback = () => undefined } = data;
        dispatch(
          enqueueSnackbar(t13s.NOTIFICATION.BANK_ACCOUNT_CREATED, {
            variant: 'success',
          })
        );
        onSuccessCallback();
      },
      onSettled: () => {
        queryClient.invalidateQueries('getBusinessBankAccounts', {
          exact: false,
        });
      },
      onError: error => {
        const errorCode = error?.message ?? UNKNOWN_ERROR_CODE;
        dispatch(
          enqueueSnackbar(t13s.ERROR[errorCode], {
            variant: 'error',
          })
        );
      },
    }
  );

  return {
    bankAccountIsCreating,
    createBankAccountIsSuccess,
    createBankAccountError,
    createBankAccount,
    resetBankAccountCreateData,
  };
};

export default useCreateBankAccount;
