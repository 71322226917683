export const fixNumber = (num, lengthDecimals = 2) => {
  const isINT = num % 1 === 0;
  const formattedNumber = isINT ? num : (+num)?.toFixed(lengthDecimals);

  return formattedNumber > 0 ? Number(formattedNumber) : 0;
};

export const formatCurrencyValue = (value, currency) => {
  let currencyDecimalPoint;

  switch (currency) {
    case 'CLP':
      currencyDecimalPoint = 0;
      break;
    case 'MXN':
    case 'USD':
      currencyDecimalPoint = 2;
      break;
    default:
      currencyDecimalPoint = 0;
      break;
  }

  const decimalScale =
    Number.parseFloat(value) && value % 1 === 0 ? 0 : currencyDecimalPoint;

  return Number.parseFloat(value)
    ? parseFloat(value).toFixed(decimalScale)
    : value;
};
