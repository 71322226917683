/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkAuth } from '../../helpers/validation/auth';

const MAX_DECIMALS = 4;

const ColorlibConnector = withStyles(theme => ({
  root: {
    left: 'calc(-50% + 32px)',
    right: 'calc(50% + 32px)',
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: props =>
      props.fullDisabled ? 'rgba(0, 0, 0, 0.12)' : theme.palette.primary.light,
    borderRadius: 1,
  },
}))(StepConnector);

const textStyle = makeStyles({
  step: {
    flex: 1,
  },
  root: {
    background: '#ECEAEF',
  },
  container: {
    padding: '0px',
    background: 'transparent',
    marginLeft: '-8px',
    width: 550,
  },
});

const ColorlibStepIcon = ({ days, disabled, onClick }) => {
  const text = days ? `${days} días` : 'Inicio';
  return (
    <Button
      color="primary"
      size="small"
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

ColorlibStepIcon.defaultProps = {
  disabled: false,
  onClick: () => {},
};

ColorlibStepIcon.propTypes = {
  days: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const AddStepIcon = ({ disabled, onClick }) => {
  return (
    <Button
      disabled={disabled}
      size="small"
      color="primary"
      variant="outlined"
      onClick={onClick}
    >
      <AddIcon fontSize="small" />
    </Button>
  );
};

AddStepIcon.defaultProps = {
  disabled: false,
  onClick: () => {},
};

AddStepIcon.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const RatesStepper = ({
  fundRates = [],
  handleAddFundRateForm,
  handleClickAction,
  perform,
  user,
  rules,
  disabled,
}) => {
  const classes = textStyle();
  return (
    <>
      {fundRates.length > 0 ? (
        <Stepper
          activeStep={-1}
          alternativeLabel
          className={classes.container}
          connector={<ColorlibConnector fullDisabled={disabled} />}
        >
          {fundRates.map((fundRate, index) => {
            let rateLabel = parseFloat(fundRate.rate);
            const decimals = `${rateLabel}`.substring(2);
            const quantityDecimals =
              decimals.length <= MAX_DECIMALS ? decimals.length : MAX_DECIMALS;
            rateLabel = rateLabel.toFixed(quantityDecimals);
            const labelPeriod = !index ? 'Tasa inicial' : 'Tasa mora';
            const label = (
              <Typography variant="caption">
                <Box fontWeight="fontWeightBold">{labelPeriod}</Box>
                {rateLabel}
              </Typography>
            );
            const stepLabel = (
              <StepLabel
                StepIconComponent={() => (
                  <ColorlibStepIcon
                    days={fundRate.days}
                    disabled={disabled}
                    onClick={() =>
                      handleClickAction && checkAuth(user.roles, perform, rules)
                        ? handleClickAction(fundRate)
                        : null
                    }
                  />
                )}
              >
                {label}
              </StepLabel>
            );
            return (
              <Step
                key={fundRate.id}
                className={classes.step}
                disabled={disabled}
                color="primary"
              >
                {handleClickAction && checkAuth(user.roles, perform, rules) ? (
                  <Tooltip title="Editar/Eliminar">{stepLabel}</Tooltip>
                ) : (
                  stepLabel
                )}
              </Step>
            );
          })}
          {handleAddFundRateForm && checkAuth(user.roles, perform, rules) && (
            <Step>
              <StepLabel
                disabled={disabled}
                StepIconComponent={() => {
                  return (
                    <AddStepIcon
                      disabled={disabled}
                      onClick={operation =>
                        disabled ? () => {} : handleAddFundRateForm(operation)
                      }
                    />
                  );
                }}
              >
                Agregar Más
              </StepLabel>
            </Step>
          )}
        </Stepper>
      ) : (
        'No hay información disponible'
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    rules: state.auth.rules,
  };
};

RatesStepper.defaultProps = {
  perform: null,
  disabled: false,
  handleAddFundRateForm: null,
  handleClickAction: null,
};

RatesStepper.propTypes = {
  fundRates: PropTypes.arrayOf(Object).isRequired,
  handleAddFundRateForm: PropTypes.func,
  handleClickAction: PropTypes.func,
  user: PropTypes.objectOf(Object).isRequired,
  rules: PropTypes.objectOf(Object).isRequired,
  perform: PropTypes.string,
  disabled: PropTypes.bool,
};

export default compose(connect(mapStateToProps))(withTheme(RatesStepper));
