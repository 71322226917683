import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../../actions/notificationAction';
import { t13s } from '../../../../translationKeys';
import { uploadBulkDiscountsService } from '../services';

export const useUploadBulkDiscounts = () => {
  const dispatch = useDispatch();

  const { mutate: uploadBulkDiscounts, isLoading: uploadBulkDiscountsLoading } =
    useMutation(
      ({ formData, orderId }) => {
        return uploadBulkDiscountsService({ formData, orderId });
      },
      {
        onSuccess: (response, data) => {
          const { onSuccessCallback } = data;
          const {
            succesfullyProcessedInvoices,
            notFoundInvoices,
            notAppliedDiscounts,
          } = response.data;
          if (
            succesfullyProcessedInvoices > 0 &&
            notFoundInvoices?.invoices?.length === 0 &&
            notAppliedDiscounts?.invoices?.length === 0
          ) {
            dispatch(
              enqueueSnackbar(t13s.NOTIFICATION.DISCOUNT_CREATED, {
                variant: 'success',
              })
            );
          }

          notFoundInvoices.invoices.map(element => {
            return dispatch(
              enqueueSnackbar(
                `Not found invoices: FOLIO ${element.folio}, ID ${element.issuerId}`,
                { variant: 'error' }
              )
            );
          });

          notAppliedDiscounts.invoices.map(element => {
            return dispatch(
              enqueueSnackbar(
                `Not applied discounts: FOLIO ${element.folio}, ID ${element.issuerId}`,
                { variant: 'error' }
              )
            );
          });

          onSuccessCallback();
        },
        onError: () => {
          dispatch(
            enqueueSnackbar(t13s.ERROR.BULK_DISCOUNT_ERROR, {
              variant: 'error',
            })
          );
        },
      }
    );

  return {
    uploadBulkDiscounts,
    uploadBulkDiscountsLoading,
  };
};

export default useUploadBulkDiscounts;
